// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { Color } from '../../../Graphics';
import { MAMode } from '../IndicatorConstants';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';
import { type iBuildInIndicator } from '../iBuildInIndicator';

export class MAS3 extends IndicatorScriptBase {
    constructor () {
        super();
        this.ProjectName = '3MASignal';
        this.Comments = 'Offers buy and sell signals according to intersections of three moving averages';
        super.SetIndicatorLine('Line 1', Color.Blue, 5, LineStyle.HistogrammChart);
        this.SeparateWindow = true;

        this.ShortMaPeriod = 5;
        super.InputParameter(new InputParameterInteger('ShortMaPeriod', 'Short Moving Average Period', 0, 1, 9999));

        this.MiddleMaPeriod = 10;
        super.InputParameter(new InputParameterInteger('MiddleMaPeriod', 'Middle Moving Average Period', 1, 1, 9999));

        this.LongMaPeriod = 25;
        super.InputParameter(new InputParameterInteger('LongMaPeriod', 'Long Moving Average Period', 2, 1, 9999));

        this.BarsInterval = 1;
        super.InputParameter(new InputParameterInteger('BarsInterval', 'Amount of bars passed before opening position', 3, 1, 999));
    }

    public ShortMaPeriod: number;

    public MiddleMaPeriod: number;

    public LongMaPeriod: number;

    public BarsInterval: number;

    private shortMa: iBuildInIndicator;

    private middleMa: iBuildInIndicator;

    private longMa: iBuildInIndicator;

    private readonly NO_TREND: number = 0;

    private readonly UP_TREND: number = 1;

    private readonly DOWN_TREND: number = -1;

    public Init (): void {
        super.Init();
        super.IndicatorShortName(this.GetIndicatorShortName());
    }

    public override GetIndicatorShortName (): string {
        return 'MAS3(' + this.ShortMaPeriod + ';' + this.MiddleMaPeriod + ';' + this.LongMaPeriod + ')';
    }

    public override createInternalIndicators (): iBuildInIndicator[] {
        this.shortMa = this.Indicators.iMA(this.CurrentData, this.ShortMaPeriod, MAMode.SMA);
        this.middleMa = this.Indicators.iMA(this.CurrentData, this.MiddleMaPeriod, MAMode.SMA);
        this.longMa = this.Indicators.iMA(this.CurrentData, this.LongMaPeriod, MAMode.SMA);

        super.IndicatorShortName(this.GetIndicatorShortName());

        return [this.shortMa, this.middleMa, this.longMa];
    }

    public OnQuote (): void {
        super.OnQuote();
        super.SetValue(0, 0, this.TradeSignal());
    }

    private TradeSignal (): number {
        let state = this.NO_TREND;
        if (this.CurrentData.Count <= this.GetMaxPeriod()) {
            return state;
        }
        // looking for BarsInterval back
        for (let shift = 0; shift < this.BarsInterval; shift++) {
            if (shift === 0) {
                // calculating beginning state
                state = this.CompareMA(this.shortMa.GetValue(0, shift), this.middleMa.GetValue(0, shift), this.longMa.GetValue(0, shift));
            } else {
                // depending on previous value, getting the current trend value
                const curState = this.CompareMA(this.shortMa.GetValue(0, shift), this.middleMa.GetValue(0, shift), this.longMa.GetValue(0, shift));
                if (state === this.UP_TREND) {
                    // up trend is if current state is UP and previous is up also
                    if (curState !== this.UP_TREND) { return this.NO_TREND; }
                }
                if (state === this.DOWN_TREND) {
                    if (curState !== this.DOWN_TREND) { return this.NO_TREND; }
                }
            }
        }

        // returninig current state
        return state;
    }

    private GetMaxPeriod (): number {
        return Math.max(Math.max(this.ShortMaPeriod, this.MiddleMaPeriod), this.LongMaPeriod);
    }

    private CompareMA (shMa: number, midMA: number, lgMa: number): number {
        if ((midMA > lgMa) && (midMA < shMa)) {
            return this.UP_TREND;
        }
        if ((midMA > shMa) && (midMA < lgMa)) {
            return this.DOWN_TREND;
        }
        return this.NO_TREND;
    }
}
