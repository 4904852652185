// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
export enum MarginTypes {
    MARGIN_PFS = 0,
    MARGIN_FIXED = 2,
    MARGIN_FULL = 4,
    MARGIN_PT_RISK_MODULE = 6,
    MARGIN_PRICE_LIMIT = 7,
    MARGIN_STOCK = 12,
    MARGIN_NSE_VAR = 13,
    MARGIN_PT_RISK_MODULE_EXPOSURE = 14,
    MARGIN_NONE = 16,
    MARGIN_CUSTOM_COEF_WITH_FIXED_VALUE = 17, // 82717
    LONG_OPTIONS_FULLY_PAID_MARGIN = 18, // 92923
    TIERED_PRICE_BASED_CUSTOM_COEF = 19, // 94319
    PRICE_BASED_MULTIPLE_COEF = 20, // 112886
    MARGIN_CUSTOM_FIXED = 21, // 116496
    TIERED_PRICE_BASED_CUSTOM_COEF_WITH_RANGES = 22, // 117052
};
