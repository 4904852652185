// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DynProperty } from '../../Commons/DynProperty';
import { Resources } from '../../Commons/properties/Resources';
import { DateTimeUtils } from '../../Utils/Time/DateTimeUtils';
import { QuickTableColumnType, QuickTableEditingInfo } from '../elements/QuickTable/QuickTableMisc';
import { BaseItem, ColumnData } from './BaseItem';
import { ColumnParams } from './ColumnParams';

export class NewsItem extends BaseItem {
    public news: any;

    constructor (news, isViewed) {
        super();

        this.Disabled = isViewed; // просмотрена ли новость

        if (news) {
            this.news = news;
            if (news.contentIsLink) // есть ссылка в TEXT[115] то в качестве контента отображаем кликабельную ссылку.
            {
                this.QuickTableEditingInfoMap[3] = (function () // Content column
                {
                    return new QuickTableEditingInfo(DynProperty.LINK);
                })();
            }
        }
    }

    public override getColumnData (column: number): ColumnData {
        const value = this.getColumnValue(column);
        let formattedValue = null;

        switch (column) {
        case 0:
            formattedValue = value ? DateTimeUtils.formatDate(value, 'DD.MM.YYYY HH:mm:ss') : '';
            break;

        case 6:
            formattedValue = this.getTextPriority(value);
            break;

        default:
            formattedValue = value ? value.toString() : '';
            break;
        }

        return new ColumnData(value, formattedValue);
    }

    public override getColumnValue (column: number): any {
        const news = this.news;

        if (news === null) { return null; }

        switch (column) {
        case 0:
            return news.received;

        case 1:
            return news.source;

        case 2:
            return news.subject;

        case 3:
            return news.content;

        case 4:
            return news.category;

        case 5:
            return news.symbol;

        case 6:
            return news.priority;

        default:
            return null;
        }
    }

    public getLinkInContent (): any {
        const news = this.news;

        if (news === null) { return null; }

        return news.content;
    }

    public getNewsID (): any {
        return this.news ? this.news.GetID() : null;
    }

    public getRouteID (): any {
        return this.news ? this.news.GetRouteID() : null;
    }

    public OnNewsDoubleClicked (): void {
        if (this.news) {
            this.news.OnNewsDoubleClicked();
        }
    }

    public getTextPriority (value: string | null): string {
        if (isNullOrUndefined(value)) { return ''; }

        if (value === '') {
            return Resources.getResource('panel.news.Priority_All');
        } else if (value === '1') {
            return Resources.getResource('panel.news.Priority_1');
        } else if (value === '5') {
            return Resources.getResource('panel.news.Priority_5');
        } else if (value === '10') {
            return Resources.getResource('panel.news.Priority_10');
        }
    }

    public ChangeViewedState (newState: boolean): void {
        this.Disabled = newState;
    }

    public override ColumnCount (): number {
        return NewsItem.columnsParams.length;
    }

    public override GetColumnParams (column: number): ColumnParams {
        const result = NewsItem.columnsParams[column];
        const isHidden = Resources.isHidden(result.HeaderKey);
        result.Hidden = isHidden;
        result.MenuIndex = this.GetMenuIndex(result.HeaderKey);

        return result;
    }

    public GetMenuIndex (headerKey: string): number {
        if (NewsItem.ListMenuIndex.Contains(headerKey)) {
            return NewsItem.ListMenuIndex.indexOf(headerKey);
        } else {
            return 1000;
        }
    }

    /// <summary>
    /// Здесь хранятся настройки колонок (один экземпляр на класс)
    /// </summary>
    public static readonly columnsParams = [
    /* 0 */ new ColumnParams('panel.news.received', 75, QuickTableColumnType.COL_DATE_SORT, false, true, false),
        new ColumnParams('panel.news.source', 120, QuickTableColumnType.COL_DEFAULT, false, true, true),
        new ColumnParams('panel.news.subject', 120, QuickTableColumnType.COL_DEFAULT, false, true, false),
        new ColumnParams('panel.news.context', 75, QuickTableColumnType.COL_DEFAULT, false, false, false),
        new ColumnParams('panel.news.category', 75, QuickTableColumnType.COL_DEFAULT, false, false, false),
        /* 5 */ new ColumnParams('panel.news.symbol', 75, QuickTableColumnType.COL_DEFAULT, false, false, false),
        new ColumnParams('panel.news.priority', 75, QuickTableColumnType.COL_DEFAULT, false, false, false)
    ];

    public static readonly ListMenuIndex =
        [
            'panel.news.received',
            'panel.news.source',
            'panel.news.subject',
            'panel.news.context',
            'panel.news.category',
            'panel.news.symbol',
            'panel.news.priority'
        ];
}
