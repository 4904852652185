// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Rectangle } from "../../../Commons/Geometry.ts";
import { Control } from "../Control.js";
import { TerceraWindowBase } from "../../screen/TerceraWindowBase.js";
import { MainWindowManager } from "../../UtilsClasses/MainWindowManager.ts";
import { TerceraCheckBox } from "../TerceraCheckBox.js";
import { TerceraQuickTree, TerceraQuickTreeEvents } from "../QuickTree/TerceraQuickTree.ts";
import { TerceraTextBox } from "../TerceraTextBox.js";
import { TerceraMenu } from "../TerceraMenu.ts";
import { TerceraButton } from "../TerceraButton.ts";
import { Resources } from "../../../Commons/properties/Resources.ts";
import { QuickTreeNode } from "../QuickTree/QuickTreeNode.ts";

export let TerceraLookupDropDownForm = TerceraWindowBase.extend({
    data: function ()
    {
        return {
            showHeader: false,
            showFooter: false,
            movable: false,
            resizable: false,
            isLookup: true,
            isMultiSelect: false,
            multiSelectText: '',
            addBtnText: '',
            cancelBtnText: '',
            enabledAddBtn: false
        };
    },
    callBack: null,
    closeCallback: null,
    textBox: null,
    quickTree: null,
    items: null,
    currentNameFilter: null,
    oldNameFilter: null,
    asyncPopulateInterval: null,
    selectedItem: null,
    isMultiSelect: false,
    isMultiSelectMode: false,
    autoClose: true,
    forceCloseOnLogout: false,
    exchangeFilterSet: null,
    caller: null
});

TerceraLookupDropDownForm.prototype.getType = function () { return 'TerceraLookupDropDownForm' };

TerceraLookupDropDownForm.prototype.oninit = function ()
{
    TerceraWindowBase.prototype.oninit.call(this)
    this.OnCompliteWaiter = new Promise((resolve, reject) =>
    {
        this._complete_finished = resolve;
    })
}

TerceraLookupDropDownForm.prototype.oncomplete = function ()
{
    TerceraWindowBase.prototype.oncomplete.apply(this);

    this.initializationComponents();
    this.on(Control.Events.LostFocus, function ()
    {
        //remove call stack overflow
        let caller = this.caller;
        this.caller = null
        //TODO unificate  AfterLostFocusWaiting
        if (caller && !this.AfterLostFocusWaiting)
            caller.setFocus()

        if (this.autoClose)      
        {
            if (this.closeCallback)
            {
                this.closeCallback();
                this.closeCallback = null;
            }
            this.set('visible', false);
        }
    }.bind(this));

    if (this.Controls.multiSelect)
        this.Controls.multiSelect.on(TerceraCheckBox.Events.CheckedStateChange,
            function (sender, value)
            {
                if (this.quickTree.quickTree.UseCHB)
                    this.quickTree.quickTree.isMultiSelectCheckboxUsing = value;
                else
                    this.quickTree.quickTree.isMultiSelect = value;
                this.quickTree.quickTree.Draw();
            }.bind(this));

    this.quickTree.on(TerceraQuickTreeEvents.DoubleClick,
        function ()
        {
            if (this.quickTree.quickTree.isMultiSelectCheckboxUsing) return
            this.onAddBtnClicked();    // #84756 (2nd issue)
            this.hide();
        }.bind(this));

    this.quickTree.on(TerceraQuickTreeEvents.EnterPress,
        this.onEnterPressed.bind(this));

    this.textBox.on(TerceraTextBox.Events.TextChanged,
        function (context, value)
        {
            this.currentNameFilter = value === '' ? null : value.toLowerCase();
            this.populateAsync();
        }.bind(this));

    this.quickTree.quickTree.SelectionChanged.Subscribe(this.selectionChanged, this);
    this.selectionChanged();

    this.localize();

    //Promise
    this._complete_finished(this);
    this._complete_finished = null;
    this.OnCompliteWaiter = null;
};

TerceraLookupDropDownForm.prototype.localize = function ()
{
    this.set({
        selectAllText: Resources.getResource('TerceraAccountLookup.Select All'),
        multiSelectText: Resources.getResource('TerceraAccountLookup.Multy select'),
        cancelBtnText: Resources.getResource('panel.TerceraSymbolLookupDropDownForm.Cancel')
    })
};

TerceraLookupDropDownForm.prototype.initializationComponents = function () 
{
    throw new Error('Not implemented');
};

TerceraLookupDropDownForm.prototype.onAddBtnClicked = function (isBtnClick = false)
{
    if (isBtnClick && !this.get("enabledAddBtn"))
        return

    var qt = this.quickTree.quickTree;
    if (this.get('isMultiSelect'))
    {
        if (this.callBack && qt.selectedNodesDict)
        {
            var result = [];
            var dict = qt.selectedNodesDict;
            var keys = Object.keys(dict);
            var len = keys.length;
            for (var i = 0; i < len; i++)
            {
                var curNode = dict[keys[i]];
                if (curNode.childNodes && curNode.childNodes.length > 0)
                    result = result.concat(this.recursionAddCheckedResults(curNode.childNodes));
                else
                    result.push(curNode.tag);
            }
            this.callBack(result);
        }
    }
    else if (this.callBack && qt.selectedNode)
    {
        this.callBack(qt.selectedNode.tag);
    }
    this.close();
};

TerceraLookupDropDownForm.prototype.onEnterPressed = function ()
{
    // TODO.
    if (!this.get('enabledAddBtn'))
        return;

    this.onAddBtnClicked();
};

TerceraLookupDropDownForm.prototype.selectionChanged = function ()
{
    let qt = this.quickTree.quickTree;
    if (this.get('isMultiSelect'))
    {
        let len = qt.selectedNodesDict ? Object.keys(qt.selectedNodesDict).length : 0;
        this.set({
            addBtnText: Resources.getResource('panel.TerceraSymbolLookupDropDownForm.Add') + (len ? '[' + len + ']' : ''),
            enabledAddBtn: this.isButtonAddEnable()
        });
        this.changeCheckedNodes();
    }
    else this.set({
        addBtnText: Resources.getResource('panel.TerceraSymbolLookupDropDownForm.Add'),
        enabledAddBtn: this.isButtonAddEnable()
    });
};

TerceraLookupDropDownForm.prototype.changeCheckedNodes = function ()
{
    let qt = this.quickTree.quickTree;
    let nodesArr = qt.nodeCollection;
    if (nodesArr.length)
    {
        for (let node of nodesArr)
        {
            let childNodesArr = node.childNodes
            if (childNodesArr.length)
            {
                node.checked = true;
                for (let child of childNodesArr)
                {
                    if (qt.selectedNodesDict[child.nodeText + node.nodeText])
                        child.checked = true
                    else
                    {
                        child.checked = false
                        node.checked = false
                    }
                }
            }
        }
    }
};

TerceraLookupDropDownForm.prototype.isButtonAddEnable = function ()
{
    let qt = this.quickTree.quickTree;
    if (this.get('isMultiSelect'))
    {
        let len = qt.selectedNodesDict ? Object.keys(qt.selectedNodesDict).length : 0;
        return !!len;
    }
    else
    {
        return !isNullOrUndefined(qt.selectedNode?.tag)
    }
};

TerceraLookupDropDownForm.prototype.recursionAddCheckedResults = function (nodes)
{
    var result = [];
    var len = nodes.length;
    for (var i = 0; i < len; i++)
    {
        var curNode = nodes[i];
        if (curNode.childNodes.length > 0)
            result = result.concat(this.recursionAddCheckedResults(curNode.childNodes));
        else
            result.push(curNode.tag);
    }
    return result;
};

TerceraLookupDropDownForm.prototype.close = function ()
{
    let qt = this.quickTree.quickTree;
    qt.selectedNodesDict = {};
    qt.selectedNode = null;
    this.autoClose = true;
    this.lostFocus();
    if (this.AfterLostFocusWaiting)
    {
        this.AfterLostFocusWaiting.setFocus();
        this.AfterLostFocusWaiting = null;
    }
};

TerceraLookupDropDownForm.prototype.showForm = async function (parametersStruct)
{
    this.themeChange();

    this.setParentPanel(parametersStruct.parentPanel);
    this.set('visible', true);

    if (parametersStruct.caller)
        await this.set('parentContainerControl', parametersStruct.caller)

    var isMultiSelect = !!parametersStruct.isMultiSelect;
    this.set('isMultiSelect', isMultiSelect);
    if (this.Controls.multiSelect)
        this.Controls.multiSelect.set({
            visible: !!parametersStruct.isMultiSelectMode,
            checked: isMultiSelect
        });

    this.quickTree.setIsMultiSelect(isMultiSelect);
    if (parametersStruct.isMultiSelectCheckboxUsing)
        this.quickTree.quickTree.isMultiSelect = false;
    this.quickTree.quickTree.isMultiSelectCheckboxUsing = !!parametersStruct.isMultiSelectCheckboxUsing;
    this.quickTree.quickTree.UseCHB = !!parametersStruct.isMultiSelectCheckboxUsing;

    this.set('isShowFooterBtn', isMultiSelect || !!parametersStruct.showButtons);

    this.set({ isShowSelectAll: parametersStruct.isShowSelectAll })

    var newCoord = TerceraMenu.CorrectPopupLocation(new Rectangle(parametersStruct.x, parametersStruct.y, this.get('width'), this.get('height')), null);
    this.setLocation(newCoord.newX, newCoord.newY);
    this.callBack = parametersStruct.callBack;
    this.closeCallback = parametersStruct.closeCallback;
    this.items = parametersStruct.items;
    this.selectedItem = parametersStruct.selectedItem;
    this.dataProvider = parametersStruct.dataProvider;
    this.showMAM = parametersStruct.showMAM;
    this.exchangeFilterSet = parametersStruct.exchangeFilterSet;
    this.fillTree();
    this.cachedItems = parametersStruct.items;

    this.textBox.setFocus();
    this.textBox.set({
        text: '',
        placeholder: Resources.getResource('screen.lookup.scripts.watermark')
    });
    this.autoClose = parametersStruct.autoClose;

    if (parametersStruct.caller)
        this.caller = parametersStruct.caller

    if (parametersStruct.isCentered)
        this.center();

    this.localize();
};

TerceraLookupDropDownForm.prototype.hide = function ()
{
    var qt = this.quickTree.quickTree;
    if (this.callBack && qt.selectedNode)
        this.callBack(qt.selectedNode.tag);
    this.currentNameFilter = null;
    this.oldNameFilter = null;
    this.close();
};

TerceraLookupDropDownForm.prototype.populateAsync = function ()
{
    clearTimeout(this.asyncPopulateInterval);
    this.asyncPopulateInterval = null;
    this.asyncPopulateInterval = setTimeout(this.fillTree.bind(this), 300);
};

TerceraLookupDropDownForm.prototype.fillTree = function ()
{
    var items = this.items;
    var selectedItem = this.selectedItem;
    var selectedNode;

    var i;
    var len = items.length;
    var nodeRange = [];
    for (i = 0; i < len; i++)
    {
        var curItem = items[i];

        if (this.currentNameFilter !== null)
        {
            var curItemFindStr = curItem.toString().toLowerCase();
            if (curItemFindStr.indexOf(this.currentNameFilter) === -1)
                continue;
        }
        var node = new QuickTreeNode(curItem.toString())
        node.tag = curItem;
        if (selectedItem === curItem)
        {
            selectedNode = node;
            node.selected = true;
        }
        nodeRange.push(node);
    }


    this.quickTree.quickTree.Clear();
    this.quickTree.quickTree.AddNodeRange(nodeRange);
    this.quickTree.quickTree.setSelectedNode(selectedNode);
    this.quickTree.quickTree.goToSelectedNode();

};

TerceraLookupDropDownForm.prototype.themeChange = function ()
{

};

TerceraLookupDropDownForm.prototype.afterParentPanelTeardownCallback = function ()
{
    this.hide();
};

TerceraLookupDropDownForm.createInstance = function ()
{
    var lookup = new TerceraLookupDropDownForm();
    lookup.setBounds(200, 200, 200, 200);
    lookup.set({
        visible: false
    })
    MainWindowManager.MainWindow.addControl(lookup);
    return lookup;
};