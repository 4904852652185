// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { ErrorInformationStorage } from "../../Commons/ErrorInformationStorage.ts";
import { Color } from "../../Commons/Graphics.ts";
import { KeyCode } from "../../Commons/KeyEventProcessor.ts";
import { ColorPickerTemplate } from "../../templates.js";
import { Control } from "./Control.js";
import { ThemeManager } from "../misc/ThemeManager.ts";
import { CustomEvent } from "../../Utils/CustomEvents.ts";

export let ColorPicker = Control.extend({
    template: ColorPickerTemplate,
    data: function ()
    {
        return {
            mapColor: 'transparent',
            currentColor: 'transparent',
            startColor: 'transparent',
            rainbowSelectorValue: 100,
            pointX: 100,
            pointY: 100,
            pointA: 100,
            additionalStyle: 'js-cp-blackPoint',
            valueR: 0,
            valueG: 0,
            valueB: 0,
            valueA: 1,
            imageforRemoveBtn: "",
            noColorImage: ""
        };
    },
    dataH: 0,
    dataS: 0,
    dataB: 0,
    valueA: 1,
    startValueA: 1,
    mouseEventPart: 0,
    opacityControlWidth: 280,
    onMouseDown: null,
});

ColorPicker.prototype.oninit = function ()
{
    Control.prototype.oninit.apply(this);
    this.onMouseDown = new CustomEvent();
    this.set({ imageforRemoveBtn: ThemeManager.getImageFullUrlNew("components/panels_tabs/14x14_icon_close_default.png") });
    this.set({ noColorImage: ThemeManager.getImageFullUrl("web_only/colorPicker/21x16_colorOpacity0.png") });

    this.on('onRainbowBarMouseDown', function (event)
    {
        this.mouseEventPart = ColorPicker.MouseEventParts.Rainbow;
        this.cpRainbowBarOver(event);
    });
    this.on('onRainbowBarMouseMove', this.cpRainbowBarOver);
    this.on('onRainbowBarMouseUp onRainbowBarMouseLeave onRainbowBarMouseEnte', function ()
    {
        this.mouseEventPart = ColorPicker.MouseEventParts.None;
    });

    this.on('onMapMouseDown', function (event)
    {
        //this.mouseEventPart = ColorPicker.MouseEventParts.Point;
        //this.cpMapOver(event);
        this.onMouseDown.Raise();
    });
    this.on('onMapMouseMove', this.cpMapOver);
    this.on('onMapMouseLeave', function ()
    {
        this.mouseEventPart = ColorPicker.MouseEventParts.None;
        this.setPointMap(this.get('startColor'));
    });

    this.on('onOpacityMouseDown', function (event)
    {
        this.mouseEventPart = ColorPicker.MouseEventParts.Opacity;
        this.cpOpacityOver(event);
    });
    this.on('onOpacityMouseMove', this.cpOpacityOver);
    this.on('onOpacityMouseUp onOpacityMouseLeave onOpacityMouseEnte', function ()
    {
        this.mouseEventPart = ColorPicker.MouseEventParts.None;
    });

    this.on('onRGBValueChange', this.onRGBValueChange);

    this.on('removeColorClick', this.removeColorClick);
};

ColorPicker.prototype.setColor = function (color)
{
    this.setPointRainbowOpacityByColor(color);
};

ColorPicker.prototype.getColor = function (color)
{
    var result = this.get('currentColor');
    if (this.get('startColor') === 'rgba(0,0,0,0)')
    {
        var splitedResult = result.split(',');
        result = splitedResult[0] + ',' + splitedResult[1] + ',' + splitedResult[2] + ',1)';
    }
    return result;
};

//
//над вертикальной полосой
//
ColorPicker.prototype.cpRainbowBarOver = function (event)
{
    if (this.mouseEventPart !== ColorPicker.MouseEventParts.Rainbow)
        return;

    var ev = event.original;

    var posY = ev.offsetY;
    if (ev.target !== ev.currentTarget)
        posY = this.get('rainbowSelectorValue') + posY;

    if (posY < 0) posY = 0;
    if (posY > 255) posY = 255;

    this.dataH = parseInt(360 * (256 - posY) / 256, 10);

    var mapColor = this.getMapByRainbow(this.dataH);

    this.set({
        mapColor: mapColor,
        rainbowSelectorValue: posY - ColorPicker.DISTANCE_TO_SEPARATOR_MIDDLE
    });
};

//
//над картой цветов
//
ColorPicker.prototype.cpMapOver = function (event)
{
    var ev = event.original;

    var posX = ev.offsetX;
    var posY = ev.offsetY;

    if (ev.target !== ev.currentTarget)
    {
        posX = this.get('pointX') + posX;
        posY = this.get('pointY') + posY;
    }

    if (posX < 0) posX = 0;
    if (posX > 255) posX = 255;
    if (posY < 0) posY = 0;
    if (posY > 255) posY = 255;

    this.dataS = parseInt(100 * posX / 256, 10);
    this.dataB = parseInt(100 * (256 - posY) / 256, 10);

    var newColor = this.getColorByPointRainbow();

    this.set({
        currentColor: newColor,
        pointX: posX - ColorPicker.DISTANCE_TO_POINT_MIDDLE,
        pointY: posY - ColorPicker.DISTANCE_TO_POINT_MIDDLE,
    });
    this.changePointColor(this.dataB);
};

//
//над прозрачностью
//
ColorPicker.prototype.cpOpacityOver = function (event)
{
    if (this.mouseEventPart !== ColorPicker.MouseEventParts.Opacity)
        return;

    var ev = event.original;

    var posA = ev.offsetX;

    if (ev.target !== ev.currentTarget)
        posA = this.get('pointA') + posA;

    if (posA < 0) posA = 0;
    if (posA > 280) posA = 280;

    var persent = posA / $(event.node).width();
    this.valueA = persent;

    var newColor = 'rgba(' + this.get('valueR') + ', ' + this.get('valueG') + ', ' + this.get('valueB') + ',' + this.valueA + ')';
    this.set({
        currentColor: newColor,
        valueA: this.valueA,
        pointA: posA - ColorPicker.DISTANCE_TO_SEPARATOR_MIDDLE
    });
};

ColorPicker.prototype.getMapByRainbow = function (h)
{
    var rgbColor = HSB2RGB(parseInt(h, 10), 100, 100);
    return 'rgb(' + rgbColor.r + ', ' + rgbColor.g + ', ' + rgbColor.b + ')';
};

ColorPicker.prototype.changePointColor = function (value)
{
    var newPointColor = 'js-cp-black';
    if (value <= 50) newPointColor = 'js-cp-whitePoint';
    if (value > 50) newPointColor = 'js-cp-blackPoint';

    this.set({ additionalStyle: newPointColor });
};

ColorPicker.prototype.getColorByPointRainbow = function ()
{
    var rgbColor = HSB2RGB(parseInt(this.dataH, 10), parseInt(this.dataS, 10), parseInt(this.dataB, 10));
    return 'rgba(' + rgbColor.r + ', ' + rgbColor.g + ', ' + rgbColor.b + ',' + this.valueA + ')';
};

ColorPicker.prototype.setPointRainbowOpacityByColor = function (rgbColor)
{
    if (rgbColor === undefined) return;
    if (rgbColor === 'transparent' || rgbColor === '') return;
    var H = 1;
    var S = 0;
    var B = 0;
    if (rgbColor.indexOf('rgb') !== 0) rgbColor = this.getRGB(rgbColor);
    var rgb = rgbColor;

    var res = Color.getColorChannels(rgbColor);
    var r = res.r;
    var g = res.g;
    var b = res.b;
    var a = 1;
    if (res.a !== undefined)
        a = res.a;

    this.valueA = a;
    this.startValueA = a;

    var HSB = RGB2HSB(r, g, b);
    H = 256 - parseInt(HSB.h * 256 / 360, 10);
    S = parseInt(HSB.s * 256 / 100, 10);
    B = 256 - parseInt(HSB.b * 256 / 100, 10);

    this.dataS = parseInt(HSB.s, 10);
    this.dataB = parseInt(HSB.b, 10);

    rainbowSelectorValue: H - ColorPicker.DISTANCE_TO_SEPARATOR_MIDDLE
    this.dataH = parseInt(HSB.h, 10);

    var mapColor = this.getMapByRainbow(this.dataH);
    this.set({
        pointX: S - ColorPicker.DISTANCE_TO_POINT_MIDDLE,
        pointY: B - ColorPicker.DISTANCE_TO_POINT_MIDDLE,
        valueR: r,
        valueG: g,
        valueB: b,
        mapColor: mapColor,
        currentColor: rgbColor,
        startColor: rgbColor,
        valueA: this.valueA,
        pointA: this.opacityControlWidth * a - ColorPicker.DISTANCE_TO_SEPARATOR_MIDDLE,
    });

    this.changePointColor(this.dataB);
};

ColorPicker.prototype.setPointMap = function (rgbColor)
{
    if (rgbColor === undefined) return;
    if (rgbColor === 'transparent' || rgbColor === '') return;
    var H = 1;
    var S = 0;
    var B = 0;
    if (rgbColor.indexOf('rgb') !== 0) rgbColor = this.getRGB(rgbColor);
    var rgb = rgbColor;

    var res = Color.getColorChannels(rgbColor);
    var r = res.r;
    var g = res.g;
    var b = res.b;

    var HSB = RGB2HSB(r, g, b);
    H = 256 - parseInt(HSB.h * 256 / 360, 10);
    S = parseInt(HSB.s * 256 / 100, 10);
    B = 256 - parseInt(HSB.b * 256 / 100, 10);

    rainbowSelectorValue: H - ColorPicker.DISTANCE_TO_SEPARATOR_MIDDLE

    var mapColor = this.getMapByRainbow(this.dataH);
    this.set({
        pointX: S - ColorPicker.DISTANCE_TO_POINT_MIDDLE,
        pointY: B - ColorPicker.DISTANCE_TO_POINT_MIDDLE,
        valueR: r,
        valueG: g,
        valueB: b,
        currentColor: 'rgba(' + r + ', ' + g + ', ' + b + ',' + this.valueA + ')'
    });

    this.changePointColor(this.dataB);
};

ColorPicker.prototype.getRGB = function (colorName)
{
    var color = new RGBColor(colorName);
    if (color.ok)
    {
        return 'rgb(' + color.r + ', ' + color.g + ', ' + color.b + ')';
    }
};

ColorPicker.DISTANCE_TO_SEPARATOR_MIDDLE = 4;
ColorPicker.DISTANCE_TO_POINT_MIDDLE = 4;

ColorPicker.MouseEventParts =
{
    None: 0,
    Rainbow: 1,
    Opacity: 2,
    Point: 3
};

ColorPicker.prototype.onRGBValueChange = function (event)
{
    var r = this.get('valueR');
    var g = this.get('valueG');
    var b = this.get('valueB');
    this.set({ currentColor: 'rgba(' + r + ', ' + g + ', ' + b + ',' + this.valueA + ')' });
    if (event.original.keyCode === KeyCode.ENTER)
        this.onMouseDown.Raise();
};

ColorPicker.prototype.removeColorClick = function ()
{
    this.set({ currentColor: 'rgba(0,0,0,0)' });
    this.onMouseDown.Raise();
};

/**
* A class to parse color values
* @author Stoyan Stefanov <sstoo@gmail.com>
* @link   http://www.phpied.com/rgb-color-parser-in-javascript/
* @license Use it if you like it
*/

/*
// How to use (example):
//
var color = new RGBColor('darkblue');
if (color.ok) { // 'ok' is true when the parsing was a success
// alert channels
alert(color.r + ', ' + color.g + ', ' + color.b);
// alert HEX and RGB
alert(color.toHex());
alert(color.toRGB());
}
*/

function RGBColor (color_string)
{
    this.ok = false;
    this.transparent = false;
    if (!color_string)
    {
        return;
    }
    if (color_string == 'transparent')
    {
        this.transparent = true;
        return;
    }
    if (color_string.indexOf('rgb') == 0)
    { // if rgb format
        color_string = color_string.replace(/ /g, '');
        color_string = color_string.split('(')[1].split(')')[0];
        var colors_array = color_string.split(',');
        this.r = colors_array[0];
        this.g = colors_array[1];
        this.b = colors_array[2];
        this.ok = true;
    } else
    {
        // strip any leading #
        if (color_string.charAt(0) == '#')
        { // remove # if any
            color_string = color_string.substr(1, 6);
        }

        color_string = color_string.replace(/ /g, '');
        color_string = color_string.toLowerCase();

        // before getting into regexps, try simple matches
        // and overwrite the input
        var simple_colors = {
            aliceblue: 'f0f8ff',
            antiquewhite: 'faebd7',
            aqua: '00ffff',
            aquamarine: '7fffd4',
            azure: 'f0ffff',
            beige: 'f5f5dc',
            bisque: 'ffe4c4',
            black: '000000',
            blanchedalmond: 'ffebcd',
            blue: '0000ff',
            blueviolet: '8a2be2',
            brown: 'a52a2a',
            burlywood: 'deb887',
            cadetblue: '5f9ea0',
            chartreuse: '7fff00',
            chocolate: 'd2691e',
            coral: 'ff7f50',
            cornflowerblue: '6495ed',
            cornsilk: 'fff8dc',
            crimson: 'dc143c',
            cyan: '00ffff',
            darkblue: '00008b',
            darkcyan: '008b8b',
            darkgoldenrod: 'b8860b',
            darkgray: 'a9a9a9',
            darkgreen: '006400',
            darkkhaki: 'bdb76b',
            darkmagenta: '8b008b',
            darkolivegreen: '556b2f',
            darkorange: 'ff8c00',
            darkorchid: '9932cc',
            darkred: '8b0000',
            darksalmon: 'e9967a',
            darkseagreen: '8fbc8f',
            darkslateblue: '483d8b',
            darkslategray: '2f4f4f',
            darkturquoise: '00ced1',
            darkviolet: '9400d3',
            deeppink: 'ff1493',
            deepskyblue: '00bfff',
            dimgray: '696969',
            dodgerblue: '1e90ff',
            feldspar: 'd19275',
            firebrick: 'b22222',
            floralwhite: 'fffaf0',
            forestgreen: '228b22',
            fuchsia: 'ff00ff',
            gainsboro: 'dcdcdc',
            ghostwhite: 'f8f8ff',
            gold: 'ffd700',
            goldenrod: 'daa520',
            gray: '808080',
            green: '008000',
            greenyellow: 'adff2f',
            honeydew: 'f0fff0',
            hotpink: 'ff69b4',
            indianred: 'cd5c5c',
            indigo: '4b0082',
            ivory: 'fffff0',
            khaki: 'f0e68c',
            lavender: 'e6e6fa',
            lavenderblush: 'fff0f5',
            lawngreen: '7cfc00',
            lemonchiffon: 'fffacd',
            lightblue: 'add8e6',
            lightcoral: 'f08080',
            lightcyan: 'e0ffff',
            lightgoldenrodyellow: 'fafad2',
            lightgrey: 'd3d3d3',
            lightgreen: '90ee90',
            lightpink: 'ffb6c1',
            lightsalmon: 'ffa07a',
            lightseagreen: '20b2aa',
            lightskyblue: '87cefa',
            lightslateblue: '8470ff',
            lightslategray: '778899',
            lightsteelblue: 'b0c4de',
            lightyellow: 'ffffe0',
            lime: '00ff00',
            limegreen: '32cd32',
            linen: 'faf0e6',
            magenta: 'ff00ff',
            maroon: '800000',
            mediumaquamarine: '66cdaa',
            mediumblue: '0000cd',
            mediumorchid: 'ba55d3',
            mediumpurple: '9370d8',
            mediumseagreen: '3cb371',
            mediumslateblue: '7b68ee',
            mediumspringgreen: '00fa9a',
            mediumturquoise: '48d1cc',
            mediumvioletred: 'c71585',
            midnightblue: '191970',
            mintcream: 'f5fffa',
            mistyrose: 'ffe4e1',
            moccasin: 'ffe4b5',
            navajowhite: 'ffdead',
            navy: '000080',
            oldlace: 'fdf5e6',
            olive: '808000',
            olivedrab: '6b8e23',
            orange: 'ffa500',
            orangered: 'ff4500',
            orchid: 'da70d6',
            palegoldenrod: 'eee8aa',
            palegreen: '98fb98',
            paleturquoise: 'afeeee',
            palevioletred: 'd87093',
            papayawhip: 'ffefd5',
            peachpuff: 'ffdab9',
            peru: 'cd853f',
            pink: 'ffc0cb',
            plum: 'dda0dd',
            powderblue: 'b0e0e6',
            purple: '800080',
            red: 'ff0000',
            rosybrown: 'bc8f8f',
            royalblue: '4169e1',
            saddlebrown: '8b4513',
            salmon: 'fa8072',
            sandybrown: 'f4a460',
            seagreen: '2e8b57',
            seashell: 'fff5ee',
            sienna: 'a0522d',
            silver: 'c0c0c0',
            skyblue: '87ceeb',
            slateblue: '6a5acd',
            slategray: '708090',
            snow: 'fffafa',
            springgreen: '00ff7f',
            steelblue: '4682b4',
            tan: 'd2b48c',
            teal: '008080',
            thistle: 'd8bfd8',
            tomato: 'ff6347',
            turquoise: '40e0d0',
            violet: 'ee82ee',
            violetred: 'd02090',
            wheat: 'f5deb3',
            white: 'ffffff',
            whitesmoke: 'f5f5f5',
            yellow: 'ffff00',
            yellowgreen: '9acd32'
        };
        for (var key in simple_colors)
        {
            if (color_string == key)
            {
                color_string = simple_colors[key];
            }
        }
        // emd of simple type-in colors

        // array of color definition objects
        var color_defs = [
            {
                re: /^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/,
                example: ['rgb(123, 234, 45)', 'rgb(255,234,245)'],
                process: function (bits)
                {
                    return [
                        parseInt(bits[1]),
                        parseInt(bits[2]),
                        parseInt(bits[3])
                    ];
                }
            },
            {
                re: /^(\w{2})(\w{2})(\w{2})$/,
                example: ['#00ff00', '336699'],
                process: function (bits)
                {
                    return [
                        parseInt(bits[1], 16),
                        parseInt(bits[2], 16),
                        parseInt(bits[3], 16)
                    ];
                }
            },
            {
                re: /^(\w{1})(\w{1})(\w{1})$/,
                example: ['#fb0', 'f0f'],
                process: function (bits)
                {
                    return [
                        parseInt(bits[1] + bits[1], 16),
                        parseInt(bits[2] + bits[2], 16),
                        parseInt(bits[3] + bits[3], 16)
                    ];
                }
            }
        ];

        // search through the definitions to find a match
        for (var i = 0; i < color_defs.length; i++)
        {
            var re = color_defs[i].re;
            var processor = color_defs[i].process;
            var bits = re.exec(color_string);
            if (bits)
            {
                let channels = processor(bits);
                this.r = channels[0];
                this.g = channels[1];
                this.b = channels[2];
                this.ok = true;
            }

        }

        // validate/cleanup values
        this.r = (this.r < 0 || isNaN(this.r)) ? 0 : ((this.r > 255) ? 255 : this.r);
        this.g = (this.g < 0 || isNaN(this.g)) ? 0 : ((this.g > 255) ? 255 : this.g);
        this.b = (this.b < 0 || isNaN(this.b)) ? 0 : ((this.b > 255) ? 255 : this.b);
    }

    // some getters
    this.toRGB = function ()
    {
        return 'rgb(' + this.r + ', ' + this.g + ', ' + this.b + ')';
    }
    this.toHex = function ()
    {
        var r = this.r.toString(16);
        var g = this.g.toString(16);
        var b = this.b.toString(16);
        if (r.length == 1) r = '0' + r;
        if (g.length == 1) g = '0' + g;
        if (b.length == 1) b = '0' + b;
        return '#' + r + g + b;
    }

    // help
    this.getHelpXML = function ()
    {

        var examples = new Array();
        // add regexps
        for (var i = 0; i < color_defs.length; i++)
        {
            var example = color_defs[i].example;
            for (var j = 0; j < example.length; j++)
            {
                examples[examples.length] = example[j];
            }
        }
        // add type-in colors
        for (var sc in simple_colors)
        {
            examples[examples.length] = sc;
        }

        var xml = document.createElement('ul');
        xml.setAttribute('id', 'rgbcolor-examples');
        for (var i = 0; i < examples.length; i++)
        {
            try
            {
                var list_item = document.createElement('li');
                var list_color = new RGBColor(examples[i]);
                var example_div = document.createElement('div');
                example_div.style.cssText =
                    'margin: 3px; '
                    + 'border: 1px solid black; '
                    + 'background:' + list_color.toHex() + '; '
                    + 'color:' + list_color.toHex()
                    ;
                example_div.appendChild(document.createTextNode('test'));
                var list_item_value = document.createTextNode(
                    ' ' + examples[i] + ' -> ' + list_color.toRGB() + ' -> ' + list_color.toHex()
                );
                list_item.appendChild(example_div);
                list_item.appendChild(list_item_value);
                xml.appendChild(list_item);

            }
            catch (e) 
            {
                ErrorInformationStorage.GetException(e);
            }
        }
        return xml;
    }
};

var RGB2HSB = function (r, g, b)
{
    var colors = {
        r: r / 255,
        g: g / 255,
        b: b / 255
    };
    var hsb = {
        h: 0,
        s: 0,
        b: 0
    };

    colors.max = Math.max(colors.r, colors.g, colors.b);
    colors.min = Math.min(colors.r, colors.g, colors.b);
    colors.delta = colors.max - colors.min;

    if (colors.max != 0)
    {
        hsb.s = ((colors.max - colors.min) / colors.max) * 100;
    } else
    {
        hsb.s = 0;
    }
    hsb.b = colors.max * 100;

    //hsb.h = Math.atan2(Math.sqrt(3) * (colors.g - colors.b), (2 * colors.r - colors.g - colors.b)) * 57.295779513;

    if ((colors.r >= colors.g) && (colors.g >= colors.b))
    {
        hsb.h = 60 * (colors.g - colors.b) / (colors.r - colors.b);
    } else if ((colors.g > colors.r) && (colors.r >= colors.b))
    {
        hsb.h = 60 * (2 - (colors.r - colors.b) / (colors.g - colors.b));
    } else if ((colors.g >= colors.b) && (colors.b > colors.r))
    {
        hsb.h = 60 * (2 + (colors.b - colors.r) / (colors.g - colors.r));
    } else if ((colors.b > colors.g) && (colors.g > colors.r))
    {
        hsb.h = 60 * (4 - (colors.g - colors.r) / (colors.b - colors.r));
    } else if ((colors.b > colors.r) && (colors.r >= colors.g))
    {
        hsb.h = 60 * (4 + (colors.r - colors.g) / (colors.b - colors.g));
    } else if ((colors.r >= colors.b) && (colors.b > colors.g))
    {
        hsb.h = 60 * (6 - (colors.b - colors.g) / (colors.r - colors.g));
    }

    if (isNaN(hsb.h)) hsb.h = 0;

    return hsb;
};

var HSB2RGB = function (h, s, b)
{

    var rgb = { r: 0, g: 0, b: 0 };

    var h = h;
    var s = s;
    var v = b;

    if (s == 0)
    {
        if (v == 0)
        {
            rgb.r = rgb.g = rgb.b = 0;
        } else
        {
            rgb.r = rgb.g = rgb.b = parseInt(v * 255 / 100);
        }
    } else
    {
        if (h == 360)
        {
            h = 0;
        }
        h /= 60;

        // 100 scale
        s = s / 100;
        v = v / 100;

        var i = parseInt(h);
        var f = h - i;
        var p = v * (1 - s);
        var q = v * (1 - (s * f));
        var t = v * (1 - (s * (1 - f)));
        switch (i)
        {
            case 0:
                rgb.r = v;
                rgb.g = t;
                rgb.b = p;
                break;
            case 1:
                rgb.r = q;
                rgb.g = v;
                rgb.b = p;
                break;
            case 2:
                rgb.r = p;
                rgb.g = v;
                rgb.b = t;
                break;
            case 3:
                rgb.r = p;
                rgb.g = q;
                rgb.b = v;
                break;
            case 4:
                rgb.r = t;
                rgb.g = p;
                rgb.b = v;
                break;
            case 5:
                rgb.r = v;
                rgb.g = p;
                rgb.b = q;
                break;
        }

        rgb.r = parseInt(rgb.r * 255);
        rgb.g = parseInt(rgb.g * 255);
        rgb.b = parseInt(rgb.b * 255);
    }
    rgb.hex = '' + (16777216 | rgb.b | (rgb.g << 8) | (rgb.r << 16)).toString(16).slice(1);
    return rgb;
};

