// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
export let es =
{
    "accountDetailsWidget.DropdownButton": "Cuenta",
    "accountDetailsWidget.DropdownButton.Tooltip": "Menú de cuenta",
    "additional.verification": "Se requiere verificación de contraseña adicional.",
    "Alligator": "Alligator",
    "Average Directional Index": "Índice direccional promedio",
    "Average True Range": "Rango verdadero promedio",
    "Bollinger Band": "Banda de Bollinger",
    "Bollinger Bands": "Bandas de Bollinger",
    "bonds.couponCycle.1m": "1 mes",
    "bonds.couponCycle.1y": "1 año",
    "bonds.couponCycle.3m": "3 meses",
    "bonds.couponCycle.6m": "6 meses",
    "BrokerMessage.Cancel": "Cancelar",
    "BrokerMessage.OK": "Okay",
    "BrokerMessage.Text": "Mensaje de agente",
    "BusinessRejectMessage.28": "Contraseña actual incorrecta",
    "BusinessRejectMessage.453": "La orden fue rechazada debido al precio negativo.",
    "Chande Momentum Oscillator": "Chande Momentum Oscillator",
    "chart.agregationType.Button.ToolTip": "Usar tipos de agregación predefinidos",
    "chart.AllowedPeriods.15M": "15m",
    "chart.AllowedPeriods.1D": "1D",
    "chart.AllowedPeriods.1H": "1H",
    "chart.AllowedPeriods.1M": "1m",
    "chart.AllowedPeriods.1S": "1S",
    "chart.AllowedPeriods.1T": "1 tick",
    "chart.AllowedPeriods.1W": "1S",
    "chart.AllowedPeriods.1Y": "1A",
    "chart.AllowedPeriods.30M": "30m",
    "chart.AllowedPeriods.4H": "4H",
    "chart.AllowedPeriods.5M": "5m",
    "chart.AllowedPeriods.Month": "1M",
    "chart.AllowedPeriods.Range": "Distancia",
    "chart.AllowedPeriods.Ticks": "Ticks",
    "chart.ChartPanel.accountLookup.ToolTip": "Seleccionar cuenta",
    "chart.ChartPanel.symbolLookup.ToolTip": "Seleccionar símbolo",
    "chart.chartScroll.toBegin": "Hasta el ultimo precio",
    "chart.chartScroll.zoomInButton": "Acercarse",
    "chart.chartScroll.zoomOutButton": "Alejar",
    "chart.ChartSymbolSelector.addIndicator.ButtonToolTip": "Agregar indicador",
    "chart.ChartSymbolSelector.drawingButton.ToolTip": "Mostrar/Ocultar barra de herramientas de dibujo",
    "chart.ChartSymbolSelector.mouseTradeButton.ToolTip": "Comercio del ratón",
    "chart.ChartSymbolSelector.oeButton": "Orden de entrada",
    "chart.ChartSymbolSelector.oeButton.ToolTip": "Entrada de orden de gráfico",
    "chart.ChartSymbolSelector.OverlayTooltip": "Agregar superposición",
    "Chart.chartWindow.auto": "Auto",
    "Chart.chartWindow.collapsed": "Colapsado",
    "Chart.chartWindow.manual": "Manual",
    "chart.chartWindowManager.SetAuto": "Cambiar a escala automática de precios",
    "chart.chartWindowManager.SetManual": "Cambiar a escala de precios manual",
    "chart.head.addOverlay": "Agregar superposición...",
    "chart.head.charttype": "Estilo",
    "chart.head.line": "Herramientas de dibujo",
    "chart.head.overlayRemoveAll": "Eliminar todo",
    "chart.head.overlays": "Superposiciones",
    "chart.indicator": "Indicador",
    "chart.indicatorAdd": "Agregar indicador",
    "chart.indicatorRemoveAll": "Eliminar todo",
    "chart.indicators": "Indicadores",
    "chart.Infoline.days": "Dias",
    "chart.Infoline.hours": "Horas",
    "chart.Infoline.min": "min",
    "chart.Infoline.price": "precio",
    "chart.Infoline.sec": "segundo",
    "chart.Infoline.time": "hora",
    "chart.infoWindow.Items.Additionalinfo": "información adicional",
    "chart.infoWindow.Items.Ask": "Ask:",
    "chart.infoWindow.Items.Bars": "#Bars:",
    "chart.infoWindow.Items.Bid": "Bid:",
    "chart.infoWindow.Items.Change": "Cambio,%",
    "chart.infoWindow.Items.Close": "Cerrar:",
    "chart.infoWindow.Items.Date": "Fecha:",
    "chart.infoWindow.Items.High": "Alto:",
    "chart.infoWindow.Items.Low": "Bajo:",
    "chart.infoWindow.Items.Open": "Abierto:",
    "chart.infoWindow.Items.Ticks": "Ticks:",
    "chart.infoWindow.Items.Time": "Hora:",
    "chart.infoWindow.Items.Volume": "Volumen:",
    "chart.instrumentsLookup": "Búsqueda de símbolos",
    "chart.layerManager": "Configuraciones...",
    "chart.loading": "Cargando...",
    "chart.loadingData": "Cargando datos para",
    "chart.mainlayer.gridPrice": "Precio de la red",
    "chart.mainlayer.gridTime": "Tiempo de cuadrícula",
    "chart.mainlayer.volume": "Barras de volumen",
    "chart.menu.cancelByAccount": "Cancelar todo por cuenta",
    "chart.menu.cancelByInstrument": "Cancelar todo por símbolo",
    "chart.menu.cancelOrder": "Cancelar orden",
    "chart.menu.close": "Posición cerrada",
    "chart.menu.closeall": "Cerrar todas las posiciones...",
    "chart.menu.InfoWindowDock": "Dock",
    "chart.menu.InfoWindowHide": "Esconder",
    "chart.menu.InfoWindowSettings": "Configuraciones",
    "chart.menu.InfoWindowShortMode": "Modo corto",
    "chart.menu.modify": "Modificar",
    "chart.menu.PlateIndicatorDublicate": "Indicador duplicado",
    "chart.menu.PlateIndicatorRemove": "Cerca",
    "chart.menu.PlateIndicatorSettings": "Configuraciones",
    "chart.menu.PlateIndicatorVisible": "Visible",
    "chart.menu.PlateOverlay.Overlay": "Cubrir",
    "chart.menu.PlateOverlayRemove": "Cerca",
    "chart.menu.PlateOverlaySettings": "Configuraciones",
    "chart.menu.PlateOverlayVisible": "Visible",
    "chart.menu.rollback": "Posición de retroceso",
    "chart.noAvailableData": "No hay datos disponibles para",
    "chart.NoData": "Sin datos",
    "chart.priceScaleContextMenu.AutoScale": "Auto",
    "chart.priceScaleContextMenu.FitDay": "Día de ajuste alto/bajo",
    "chart.priceScaleContextMenu.FitDrawings": "Ajustar dibujos",
    "chart.priceScaleContextMenu.FitIndicators": "Indicadores de ajuste",
    "chart.priceScaleContextMenu.FitOrdersPositions": "Ajustar órdenes / posiciones",
    "chart.priceScaleContextMenu.ManualScale": "Manual",
    "chart.quickTrading.Invalid price": "Precio inválido",
    "chart.refresh": "Refrescar",
    "chart.Remove": "Retirar",
    "chart.shortPeriodName.m": "m",
    "chart.shortPeriodName.ticks": "ticks",
    "chart.ShowHighLow.Close": "Cierre",
    "chart.ShowHighLow.High": "Alto",
    "chart.ShowHighLow.Low": "Bajo",
    "chart.StyleButton.ToolTip": "Estilo",
    "chart.tic.short.15min.name": "15m",
    "chart.tic.short.1day.name": "1D",
    "chart.tic.short.1hour.name": "1H",
    "chart.tic.short.1min.name": "1m",
    "chart.tic.short.1tic.name": "Tick",
    "chart.tic.short.30min.name": "30m",
    "chart.tic.short.4hour.name": "4H",
    "chart.tic.short.5min.name": "5m",
    "chart.tic.short.month.name": "1M",
    "chart.tic.short.range.name": "Distancia",
    "chart.tic.short.second.name": "s",
    "chart.tic.short.week.name": "1S",
    "chart.tic.short.year.name": "1A",
    "chart.timeframeselector.rangeButton.ToolTip": "Seleccionar período de visualización de datos",
    "chart.timeframeselector.time": "Hora",
    "chart.timeScaleContextMenu.ShowAllSessions": "Mostrar sesiones extendidas",
    "chart.timeScaleContextMenu.ShowDaySeparator": "Mostrar días separador",
    "chart.timeScaleContextMenu.ShowEmptyBars": "Mostrar agujeros en el historial",
    "chart.timeScaleContextMenu.ShowMonthSeparator": "Mostrar separador de meses",
    "chart.timeScaleContextMenu.ShowTimePeriodsSeparator": "Mostrar separadores de períodos de tiempo",
    "chart.timeScaleContextMenu.ShowWeeksSeparator": "Mostrar separador de semanas",
    "chart.timeScaleContextMenu.ShowYearSeparator": "Mostrar años separador",
    "chart.tool.bar": "Bar",
    "chart.tool.candle": "Vela",
    "chart.tool.clone": "Clon",
    "chart.tool.Dash": "Guión",
    "chart.tool.delete": "Retirar",
    "chart.tool.deleteAll": "Eliminar todo",
    "chart.tool.dot": "Puntos",
    "chart.tool.dotline": "Linea punteada",
    "chart.tool.DottedLine": "Linea punteada",
    "chart.tool.forest": "Histogram",
    "chart.tool.IsoDot": "Punto iso",
    "chart.tool.line": "Línea",
    "chart.tool.properties": "Configuraciones",
    "chart.tool.ShapedLine": "Forma de la linea",
    "chart.tool.solid": "Zona",
    "chart.tools.ABCPatern": "Patrón ABC",
    "chart.tools.AndrewPitchfork": "Tenedor de lanzamiento de Andrew",
    "chart.tools.backcolor": "Color de fondo",
    "chart.tools.ButterflyGartley": "Mariposa Gartley",
    "chart.tools.Circle": "Circulo",
    "chart.tools.default": "Puntero",
    "chart.tools.Ellipse": "Elipse",
    "chart.tools.eraser": "Borrador",
    "chart.tools.FibArc": "Arco de Fibonacci",
    "chart.tools.FibEllipses": "Elipses de Fibonacci",
    "chart.tools.FibExp": "Expansión de Fibonacci",
    "chart.tools.FibFan": "Fans de Fibonacci",
    "chart.tools.FibPhiChannel": "Canal de fibra de Fibonacci",
    "chart.tools.Fibretr": "Retroceso de Fibonacci",
    "chart.tools.FibSpiral": "Espiral de Fibonacci",
    "chart.tools.FibTga": "Análisis del objetivo de tiempo de Fibonacci",
    "chart.tools.FibTz": "Zona horaria de Fibonacci",
    "chart.tools.GannFan": "Gann fan",
    "chart.tools.GannGrid": "Rejilla de Gann",
    "chart.tools.GannLine": "Línea de Gann",
    "chart.tools.horizontal": "Linea horizontal",
    "chart.tools.infoline": "Gobernante",
    "chart.tools.Line": "Línea",
    "chart.tools.mirrored": "Activar vista espejo",
    "chart.tools.points": "Recuento de puntos",
    "chart.tools.Polygon": "Polígono",
    "chart.tools.PPZ": "PPZ (Precio pivot zona)",
    "chart.tools.PriceChannel": "Canal de precios",
    "chart.tools.Rectangle": "Rectángulo",
    "chart.tools.RegressionChanel": "Canal de regresión",
    "chart.tools.ScalingByRect": "Escalando por rectángulo",
    "chart.tools.selection": "Selección",
    "chart.tools.snapToCandle": "Vela Snapto",
    "chart.tools.StayDrawingMode": "Mantente en modo dibujo",
    "chart.tools.symbol": "Marca",
    "chart.tools.text": "Comentarios",
    "chart.tools.TopBottomPattern": "Patrón superior inferior",
    "chart.tools.Triangle": "Triángulo",
    "chart.tools.vertical": "Linea vertical",
    "chart.view": "Ver",
    "chart.view.analyseGroupMenu": "Herramientas de análisis",
    "chart.view.analysetoolbar": "Herramientas de análisis",
    "chart.view.barsToRight": "Barras a la derecha...",
    "chart.view.colors": "Configuraciones",
    "chart.view.dataInfo": "Ventana de información",
    "chart.view.Day_HighLow": "Día alto/bajo",
    "chart.view.drawingtoolbar": "Barra de herramientas de dibujo",
    "chart.view.font": "Fuente del eje",
    "chart.view.landscapequestion": "¿Usar arreglo de papel de paisaje?",
    "chart.view.merge": "Lineas continuas",
    "chart.view.orderEntry": "Entrada de orden de gráfico",
    "chart.view.periods": "Intervalos...",
    "chart.view.previousCloseLine": "Cierre anterior",
    "chart.view.PrevSettlementPriceLine": "Anterior Precio liquidacion",
    "chart.view.scroller": "Barra de desplazamiento",
    "chart.view.setOrder": "Establecer orden",
    "chart.view.SettlementPriceLine": "Precio liquidacion",
    "chart.view.show_cross": "Seguimiento del cursor",
    "chart.view.show_history": "Mostrar historial comercial",
    "chart.view.show_set_zero": "Mostrar barras vacías",
    "chart.view.showEvents": "Mostrar oficios",
    "chart.view.showNews": "Mostrar noticias",
    "chart.view.showOrders": "Mostrar ordenes",
    "chart.view.showPositions": "Mostrar posiciones",
    "chart.view.source": "Tipo de datos",
    "chart.view.source0": "Predeterminado por símbolo",
    "chart.view.source1": "Bid",
    "chart.view.source1000": "Ask",
    "chart.view.source1001": "Bid/Ask promedio",
    "chart.view.source1002": "Mín (Bid) y Máx (Ask)",
    "chart.view.source4": "Último",
    "chart.view.sourceBID/ASK": "Por Bid/Ask",
    "chart.view.sourceTick1": "Por Bid/Ask",
    "chart.view.TimeToNextBar": "Hora de la próxima barra",
    "chart.view.toolbar": "Barra de herramientas",
    "chart.view.volume": "Mostrar volumen",
    "chart.visualTrading.Invalid price": "Precio inválido",
    "chart.volumeColoringMode.ByBar": "Por bar",
    "chart.volumeColoringMode.ByDifference": "Por diferencia",
    "chart.volumeColoringMode.Fixed": "Fijo",
    "chart.quickTrading.Buy limit": "Compra al limite",
    "chart.quickTrading.Buy stop": "Compra Stop",
    "chart.quickTrading.Invalid price": "Precio inválido",
    "chart.quickTrading.Sell limit": "Límite de venta",
    "chart.quickTrading.Sell stop": "Venta parada",
    "Commodity Channel Index": "Índice de canales de productos básicos",
    "connect.connection.connect.error": "Parámetros de conexiones incorrectas",
    "connect.connection.unknown.error": "¡Error desconocido!",
    "Custom": "Personalizado",
    "Day": "Día",
    "dealticket.RiskRuleWarning.Header": "Advertencia de regla de riesgo",
    "Deutsch": "#hidden#",
    "enum.AccountType.ClosedFund": "Fondo cerrado",
    "enum.AccountType.MultiAsset": "Multi activo",
    "enum.AccountType.OpenedFund": "Fondo abierto",
    "enum.AccountType.SingleCCY": "Moneda única",
    "enum.AssetType.COMMODITIES": "Productos básicos",
    "enum.AssetType.CRYPTO_CCY": "Criptomoneda",
    "enum.AssetType.CURRENCY": "Moneda",
    "enum.AssetType.SHARES": "Acciones",
    "editableComboBox.defaultList": "Lista predeterminada",
    "editableComboBox.CreateNew": "Crear nuevo",
    "ExchangeId.All": "Todos los intercambios",
    "Exponential Moving Average": "Media móvil exponencial",
    "Externalpanels.MultiTimeAndSales.Header": "Multi tiempo y ventas",
    "FOK": "FOK",
    "FullLogs.Cluster": "Servidor",
    "FullLogs.DateTime": "Hora",
    "FullLogs.Fields": "Campos",
    "FullLogs.Message name": "Nombre del mensaje",
    "FullLogs.Session": "Sesión",
    "general.Calculating": "Calculador...",
    "general.connection.restErrorMsg": "En este momento hay un problema con el guardado de configuraciones y espacios de trabajo. Por favor, póngase en contacto con el departamento de soporte",
    "general.CUSTOM": "Personalizado",
    "general.Friday": "Viernes",
    "general.messageBox.cancel": "Cancelar",
    "general.messageBox.no": "No",
    "general.messageBox.ok": "OK",
    "general.messageBox.showNextTime": "Mostrar la próxima vez",
    "general.messageBox.yes": "Sí",
    "general.Monday": "lunes",
    "general.day1.Mo": "Mo",
    "general.day2.Tu": "Tu",
    "general.day3.We": "We",
    "general.day4.Th": "Th",
    "general.day5.Fr": "Fr",
    "general.day6.Sa": "Sa",
    "general.day7.Su": "Su",
    "general.month.1": "Ene",
    "general.month.10": "Oct",
    "general.month.11": "Nov",
    "general.month.12": "Dic",
    "general.month.2": "Feb",
    "general.month.3": "Mar",
    "general.month.4": "Abr",
    "general.month.5": "Mayo",
    "general.month.6": "Jun",
    "general.month.7": "Jul",
    "general.month.8": "Aug",
    "general.month.9": "Sep",
    "general.monthFullName.1": "Enero",
    "general.monthFullName.10": "Octubre",
    "general.monthFullName.11": "Noviembre",
    "general.monthFullName.12": "Diciembre",
    "general.monthFullName.2": "Febrero",
    "general.monthFullName.3": "Marzo",
    "general.monthFullName.4": "Abril",
    "general.monthFullName.5": "Mayo",
    "general.monthFullName.6": "Junio",
    "general.monthFullName.7": "Julio",
    "general.monthFullName.8": "Agosto",
    "general.monthFullName.9": "Septiembre",
    "general.N_A": "N/A",
    "general.NoData...": "No hay datos",
    "general.OvernightMarginNotificationMessage": "Mostrar mensaje de notificación de margen nocturno",
    "general.Saturday": "Sábado",
    "general.Settings": "Configuraciones",
    "general.Sunday": "Domingo",
    "general.Thursday": "Jueves",
    "general.trading.at": "a",
    "general.trading.Buy": "Buy",
    "general.trading.confirmation": "Confirmación",
    "general.trading.confirmation.prefix": "",
    "general.trading.confirmationString": "{1} {2} {3} {4} {5} {6} {7} {8} {9} {10} {14} {13} {14} {15} {11} {12} {16}",
    "general.trading.for": "para",
    "general.trading.limitBuyLessAsk": "El precio orden compra limite es más alto que el precio de venta del mercado, el pedido se completará de inmediato.",
    "general.trading.limitSellMoreBid": "El precio límite de la orden de venta es inferior al precio de oferta del mercado, la orden se completará de inmediato",
    "general.trading.marketCloseFor": "Mercado cerrado para",
    "general.trading.pips": "ticks",
    "general.trading.points": "Puntos",
    "general.trading.position": "Posición",
    "general.trading.position.Buy": "Alcista",
    "general.trading.position.Sell": "Bajista",
    "general.trading.priceNotAvailable": "No disponible",
    "general.trading.refused": "Rechazado",
    "general.trading.Sell": "Sell",
    "general.trading.stopBuyMoreAsk": "El precio de la orden Stop Buy es inferior al precio de venta del mercado, el pedido se completará de inmediato.",
    "general.trading.stopSellLessBid": "El precio de la orden Stop Sell es mayor que el precio de oferta del mercado, el pedido se completará de inmediato.",
    "general.trading.with offset": "con desplazamiento",
    "general.Tuesday": "Мartes",
    "property.general.WarnIfWrongOrderParam": "Avisar si el orden es incorrecto",
    "general.Wednesday": "Мiércoles",
    "GTC": "GTC",
    "helpPage.link": "https://guide.traderevolution.com/project/",
    "homePage.link": "http://traderevolution.com/",
    "Ichimoku": "Ichimoku",
    "Instrument.Futures.contract": "contrato",
    "Instrument.Level1.ExtendedFields.NormalMarketSize": "Tamaño normal del mercado",
    "Instrument.Spreadbet.bet": "apuestas",
    "InstrumentDetailsPanel.(Indicative symbol)": "(Símbolo indicativo)",
    "InstrumentDetailsPanel.1.GeneralInfo": "Información general",
    "InstrumentDetailsPanel.2.TradingInfo": "Información comercial",
    "InstrumentDetailsPanel.3.MarginReg": "Requisitos de margen",
    "InstrumentDetailsPanel.4.Fees": "Matrícula",
    "InstrumentDetailsPanel.5.Rebates": "Reembolsos",
    "InstrumentDetailsPanel.6.SessionInfo": "Calendario indicativo del mercado",
    "InstrumentDetailsPanel.7.Plans": "Planes",
    "InstrumentDetailsPanel.AccruedInterest": "Interés acumulado",
    "InstrumentDetailsPanel.AddLiqudityRebateOrderPerLot": "Agregar solicitud de reembolso de liquidez por lote",
    "InstrumentDetailsPanel.AddLiqudityRebatePerLot": "Agregar solicitud de reembolso de liquidez",
    "InstrumentDetailsPanel.AddLiqudityRebatePerVolume": "Agregar reembolso de liquidez por volumen",
    "InstrumentDetailsPanel.AFTER_MARKET": "Después del mercado",
    "InstrumentDetailsPanel.Allow short positions": "Sí",
    "InstrumentDetailsPanel.AllowedOperations": "Operaciones permitidas",
    "InstrumentDetailsPanel.AllowedOrderTypes": "Tipos de orden permitidos",
    "InstrumentDetailsPanel.AllowShortPositions": "Permitir posiciones cortas",
    "InstrumentDetailsPanel.Amount": "Cant.",
    "InstrumentDetailsPanel.AssetClass": "Tipo de símbolo",
    "InstrumentDetailsPanel.BEFORE_MARKET": "Antes del mercado",
    "InstrumentDetailsPanel.Cash": "Efectivo",
    "InstrumentDetailsPanel.Close": "Cerca",
    "InstrumentDetailsPanel.Closed": "Cerrado",
    "InstrumentDetailsPanel.CloseOutDeadline": "Fecha límite de cierre",
    "InstrumentDetailsPanel.Commisions": "Comisiones",
    "InstrumentDetailsPanel.ContactMonth": "Mes de contrato",
    "InstrumentDetailsPanel.ContractMultiplier": "#hidden#",
    "InstrumentDetailsPanel.ContractSize": "Tamaño del contrato",
    "InstrumentDetailsPanel.CouponCycle": "Ciclo de cupones",
    "InstrumentDetailsPanel.CouponRate": "Tasa de cupón, %",
    "InstrumentDetailsPanel.CurrentSession": "Sesión actual",
    "InstrumentDetailsPanel.DeliveryMethod": "Método de entrega",
    "InstrumentDetailsPanel.DeliveryStatus": "Estado de entrega",
    "InstrumentDetailsPanel.Depend from account": "Depende de la cuenta",
    "InstrumentDetailsPanel.Description": "Descripción",
    "InstrumentDetailsPanel.Exchange": "Intercambiar",
    "InstrumentDetailsPanel.ExchangeTradeSession": "Sesión de intercambio",
    "InstrumentDetailsPanel.Exp1": "Activo",
    "InstrumentDetailsPanel.FaceValue": "Valor nominal",
    "InstrumentDetailsPanel.FirstTradeDate": "Primera fecha de negociación",
    "InstrumentDetailsPanel.Futures": "Futuros",
    "InstrumentDetailsPanel.FuturesClass": "Clase de futuros",
    "InstrumentDetailsPanel.HiLimit": "Limite alto",
    "InstrumentDetailsPanel.Holiday": "Vacaciones",
    "InstrumentDetailsPanel.HolidayDate": "Fecha",
    "InstrumentDetailsPanel.HolidayName": "Nombre de vacaciones",
    "InstrumentDetailsPanel.Holidays list": "Lista de vacaciones",
    "InstrumentDetailsPanel.HolidayType": "Programar",
    "InstrumentDetailsPanel.immediate": "inmediato",
    "InstrumentDetailsPanel.KEYNextHoliday": "Siguientes vacaciones",
    "InstrumentDetailsPanel.LastTradeDate": "Última fecha de negociación",
    "InstrumentDetailsPanel.LotSize": "Tamaño del lote",
    "InstrumentDetailsPanel.LotStep": "Paso de lote",
    "InstrumentDetailsPanel.LowLimit": "Limite bajo",
    "InstrumentDetailsPanel.MAIN": "Principal",
    "InstrumentDetailsPanel.Margin": "Margen requerido",
    "InstrumentDetailsPanel.Margin_ByAccount": "Margen por cuenta",
    "InstrumentDetailsPanel.MarginBuy": "Compra",
    "InstrumentDetailsPanel.MarginDay": "Día",
    "InstrumentDetailsPanel.MarginDayBuy": "Compra del día",
    "InstrumentDetailsPanel.MarginDaySell": "Venta diaria",
    "InstrumentDetailsPanel.MarginInAccountCurrency": "#hidden#",
    "InstrumentDetailsPanel.MarginOvernight": "Durante la noche",
    "InstrumentDetailsPanel.MarginOvernightBuy": "Compra nocturna",
    "InstrumentDetailsPanel.MarginOvernightSell": "Venta nocturna",
    "InstrumentDetailsPanel.MarginSell": "Venta",
    "InstrumentDetailsPanel.MaturityDate": "Fecha de vencimiento",
    "InstrumentDetailsPanel.MaximumLot": "Lote máximo",
    "InstrumentDetailsPanel.MaxPositionQtyPerSymbol": "Cantidad máx. De posición.",
    "InstrumentDetailsPanel.MinimalLot": "Lote mínimo",
    "InstrumentDetailsPanel.NextPaymentDate": "Próximo pago de rendimiento",
    "InstrumentDetailsPanel.No": "No",
    "InstrumentDetailsPanel.non trading day": "Cerca",
    "InstrumentDetailsPanel.Ninguna": "Ninguna",
    "InstrumentDetailsPanel.NormalMarketSize": "#hidden#",
    "InstrumentDetailsPanel.Not allow short positions": "No",
    "InstrumentDetailsPanel.NoticeDate": "Fecha de notificacion",
    "InstrumentDetailsPanel.onlyCloseOrders": "Solo órdenes cercanas",
    "InstrumentDetailsPanel.Open": "Abierto",
    "InstrumentDetailsPanel.OrderPerLot": "Orden por lote",
    "InstrumentDetailsPanel.PerFill": "Por relleno",
    "InstrumentDetailsPanel.PerLot": "Relleno por lote",
    "InstrumentDetailsPanel.PerOrder": "Por orden",
    "InstrumentDetailsPanel.PerOrderVolume": "Volumen de pedido, %",
    "InstrumentDetailsPanel.PerPhoneTransaction": "Por transacción telefónica",
    "InstrumentDetailsPanel.PerTransaction": "Por transacción",
    "InstrumentDetailsPanel.PerVolume": "Volumen de llenado, %",
    "InstrumentDetailsPanel.Physically": "Físicamente",
    "InstrumentDetailsPanel.POSTCLOSE": "Post-mercado",
    "InstrumentDetailsPanel.PREOPEN": "Pre-mercado",
    "InstrumentDetailsPanel.PreviousPaymentDate": "Pago de rendimiento previo",
    "InstrumentDetailsPanel.Price": "Precio",
    "InstrumentDetailsPanel.ProductType": "Tipo de producto",
    "InstrumentDetailsPanel.QuotiongCurrency": "Moneda de cotización",
    "InstrumentDetailsPanel.RemoveLiqudityRebateOrderPerLot": "Eliminar el pedido de reembolso de liquidez por lote",
    "InstrumentDetailsPanel.RemoveLiqudityRebatePerLot": "Eliminar la rebaja de liquidez pe rlot",
    "InstrumentDetailsPanel.RemoveLiqudityRebatePerVolume": "Eliminar el reembolso de liquidez por volumen",
    "InstrumentDetailsPanel.SessionStatus": "Estado de la sesión",
    "InstrumentDetailsPanel.SettlementDate": "Fecha de liquidación",
    "InstrumentDetailsPanel.shortened": "Filtrado por dia",
    "InstrumentDetailsPanel.StrikePrice": "Precio de ejercicio",
    "InstrumentDetailsPanel.SwapBuy": "swap alcista",
    "InstrumentDetailsPanel.SwapSell": "Intercambio corto",
    "InstrumentDetailsPanel.Symbol": "Símbolo",
    "InstrumentDetailsPanel.T+0": "T + 0 (hoy)",
    "InstrumentDetailsPanel.TickCoast": "Valor del Tick",
    "InstrumentDetailsPanel.TickSize": "Tamaño del Tick",
    "InstrumentDetailsPanel.TradindBlockedOnSession": "Trading bloqueado en sesión",
    "InstrumentDetailsPanel.TradingBalance": "Sistema de liquidación",
    "InstrumentDetailsPanel.TradingHalt": "Alto al comercio",
    "InstrumentDetailsPanel.TradingStatus": "Estado del símbolo",
    "InstrumentDetailsPanel.Underlier": "Subyacen",
    "InstrumentDetailsPanel.VAT": "VAT",
    "InstrumentDetailsPanel.Volume": "Volumen de llenado",
    "InstrumentDetailsPanel.VolumeWithMinPD": "Volumen de llenado %, con min p/d",
    "InstrumentDetailsPanel.working": "Abierto",
    "InstrumentDetailsPanel.Yes": "Sí",
    "InstrumentDetailsPanel.Yield": "Rendimiento",
    "InstrumentDetailsPanel.YTM": "YTM, %",
    "InstrumentDetailsPanel.YTM.RefreshLink": "Refrescar",
    "IOC": "IOC",
    "IsAllowedResponceReason.ExecuteAtMarketNotAllowedForBindOrders": "Ejecutar ya que Mercado no está permitido para órdenes vinculadas",
    "IsAllowedResponceReason.ExecuteAtMarketNotAllowedForSlTp": "Ejecutar en el mercado no permitido para SL/TP",
    "IsAllowedResponceReason.ExecuteAtMarketNotAllowedOrderType": "Cambiar tipo de orden o tipo de orden de mercado no está permitido",
    "IsAllowedResponceReason.InstrumentForAccountDelivery": "Instrumento para entrega de cuenta",
    "IsAllowedResponceReason.InstrumentTradingMode": "Comercio de instrumentos no permitido",
    "IsAllowedResponceReason.LockTrading": "Trading bloqueado en la plataforma",
    "IsAllowedResponceReason.LockTradingByPassword": "Ingrese la contraseña de negociación en la parte inferior de la terminal",
    "IsAllowedResponceReason.NoLastQuote": "Sin precio final",
    "IsAllowedResponceReason.NotAllowedByOrderStaus": "Operaciones no permitidas por el estado del pedido",
    "IsAllowedResponceReason.NotAllowedRouteOrderType": "No se puede crear la orden ya que validity y tipo de orden seleccionados no son compatibles.",
    "IsAllowedResponceReason.NotClosablePosition": "Posición no cerrable",
    "IsAllowedResponceReason.NotLoadCache": "La caché no se cargó",
    "IsAllowedResponceReason.NotVisibleInstrumentRoute": "Instrumento o ruta no visible",
    "IsAllowedResponceReason.OperationNotAllowedByOrderType": "La operación no está permitida por tipo de orden",
    "IsAllowedResponceReason.OperationNotAllowedBySession": "La operación no está permitida por sesión",
    "IsAllowedResponceReason.OrderTypeNotAllowedBySession": "El tipo de orden no está permitido por sesión",
    "IsAllowedResponceReason.RouteNotRunning": "Comercio de instrumentos no permitido.",
    "IsAllowedResponceReason.RuleAdministration": "Administración de reglas",
    "IsAllowedResponceReason.RuleClosePositionDisable": "El cierre de posición no está permitido por la regla",
    "IsAllowedResponceReason.RuleFunctionSelfTrading": "Función de regla selftrading",
    "IsAllowedResponceReason.RuleFunctionTrading": "El trading no está permitido por la regla",
    "IsAllowedResponceReason.RuleOCO": "Regla OCO",
    "IsAllowedResponceReason.RuleSlTpNotAllowed": "SL/TP no está permitido por la regla",
    "IsAllowedResponceReason.RuleTrStop": "Regla Tr. stop",
    "IsAllowedResponceReason.Unknown": "Razón desconocida",
    "Keltner Channel": "Canal Keltner",
    "Limit": "Límite",
    "Linear Weighted Moving Average": "Promedio móvil ponderado lineal",
    "LOC": "LOC",
    "LOO": "LOO",
    "Manual": "Manual",
    "Market": "Mercado",
    "MarketDepthPanel.ColoringMethod.ByPriceLVL": "Por nivel de precio",
    "MarketDepthPanel.ColoringMethod.RelativeToVolume": "Relativo al volumen",
    "MarketDepthPanel.ColoringMethod.SizeHistogram": "Histograma de tallas",
    "McGinley Dynamic": "McGinley Dynamic",
    "MDLotsMode.General": "Valores predeterminados generales ({1})",
    "MDLotsMode.Lots": "Lotes",
    "MDLotsMode.RealSize": "Tamaño real",
    "MessageBox.ThemeChanged.Title": "Advertencia",
    "MOC": "MOC",
    "Modified Moving Average": "Media móvil modificada",
    "Momentum": "Impulso",
    "MOO": "MOO",
    "Moving Average Envelope": "Sobre promedio móvil",
    "Moving Average/Convergence Divergence": "Media Móvil / Divergencia de Convergencia",
    "NetTrader.SaveWorkspace.Save": "Guardar espacio de trabajo",
    "NumericComboCtrl.Edit": "Configurar valores predeterminados",
    "OCO": "OCO",
    "On Balance Volume": "En balance de volumen",
    "OnClose": "On Close",
    "OnOpen": "En abierto",
    "Order.ExecutionType.ACCEPTED": "Aceptado",
    "Order.ExecutionType.ACTIVATED": "Activado",
    "Order.ExecutionType.CANCELED": "Cancelado",
    "Order.ExecutionType.FILLED": "Lleno",
    "Order.ExecutionType.NEW": "Creado",
    "Order.ExecutionType.PART_FILLED": "Parcialmente lleno",
    "Order.ExecutionType.REFUSED": "Rechazado",
    "Order.ExecutionType.REPLACED": "Modificado",
    "Order.ExecutionType.RESTATED": "Reestablecido",
    "Order.ExecutionType.UNKNOWN": "Desconocido",
    "OrderEntry.InfoBlock.After trade funds": "Después de los fondos comerciales",
    "OrderEntry.InfoBlock.Availible funds": "Fondos disponibles",
    "OrderEntry.InfoBlock.Balance": "Equilibrar",
    "OrderEntry.InfoBlock.Fee": "Cuota",
    "OrderEntry.InfoBlock.Gross": "Bruto",
    "OrderEntry.InfoBlock.Impact on portfolio": "Impacto en cartera",
    "OrderEntry.InfoBlock.Init. margin": "En eso. margen requerido",
    "OrderEntry.InfoBlock.Maint. margin": "Mante. margen requerido",
    "OrderEntry.InfoBlock.Margin availible": "Margen disponible",
    "OrderEntry.InfoBlock.Net": "Red",
    "OrderEntry.InfoBlock.Spread in loss": "Spread init. loss",
    "OverlayScreen.barsBodyLabel": "Cuerpo",
    "OverlayScreen.barsBorderLabel": "Frontera",
    "OverlayScreen.barsHiLowLabel": "Línea",
    "OverlayScreen.cancelButton": "Cancelar",
    "OverlayScreen.dataLabel": "Tipo de datos",
    "OverlayScreen.dojiLabel": "Doji",
    "OverlayScreen.histogramLineLabel": "Línea de histograma",
    "OverlayScreen.instrumentLabel": "Símbolo",
    "OverlayScreen.Main scale": "Escala principal",
    "OverlayScreen.name": "Cubrir",
    "OverlayScreen.numberOverlaysError": "No puede agregar más de 5 superposiciones.",
    "OverlayScreen.okButton": "OK",
    "OverlayScreen.Overlays": "Superposiciones",
    "OverlayScreen.priceLabel": "Escala de precios",
    "OverlayScreen.priceMarkerCheckbox": "Mostrar marcador de precio",
    "OverlayScreen.Separate scale": "Escala separada",
    "OverlayScreen.solidPriceLabel": "Zona",
    "OverlayScreen.styleLabel": "Estilo",
    "OverlayScreen.wickLabel": "Mecha",
    "panel.accountDetails.Groups.1.General": "General",
    "panel.accountDetails.Groups.2.Margin": "Margen",
    "panel.accountDetails.Groups.3.AccountActivity": "Actividad de la cuenta",
    "panel.accountDetails.Groups.4.TodayResults": "Resultados de hoy",
    "panel.accountDetails.Groups.5.RiskManagement": "Gestión de riesgos",
    "panel.accountDetails.Groups.55.Plans": "Planes",
    "panel.accountDetails.Groups.6.Info": "Informacion",
    "panel.AccountDetailsPanel": "Detalles de la cuenta",
    "panel.AccountDetailsPanel.InfoTooltip": "Cambie los campos en el widget arrastrando y soltando desde la tabla",
    "panel.AccountLink.SymbolLink.ToolTip": "Enlace de símbolo",
    "panel.AccountLink.ToolTip": "Enlace de cuenta",
    "panel.accounts": "Cuentas",
    "panel.accounts.AccountDescription": "#hidden#",
    "panel.accounts.accountDetails.RiskManagement.Trading.Daily loss limit reached": "Se alcanzó el límite de pérdida diaria.",
    "panel.accounts.accountDetails.RiskManagement.Trading.Max day volume  reached": "Volumen máximo del día alcanzado",
    "panel.accounts.accountDetails.RiskManagement.Trading.Order Flow Limit(per day)": "Límite de flujo de pedidos (por día)",
    "panel.accounts.accountDetails.RiskManagement.Trading.State.Disabled": "Deshabilitado",
    "panel.accounts.accountDetails.RiskManagement.Trading.State.Enabled": "Habilitado",
    "panel.accounts.accountDetails.RiskManagement.TradingStatus.Active": "Activo",
    "panel.accounts.accountDetails.RiskManagement.TradingStatus.Auto closed": "Auto cerrado",
    "panel.accounts.accountDetails.RiskManagement.TradingStatus.Closed": "Cerrado",
    "panel.accounts.accountDetails.RiskManagement.TradingStatus.Disabled by risk rules": "Comercio deshabilitado por reglas de riesgo",
    "panel.accounts.accountDetails.RiskManagement.TradingStatus.MarginWarning": "Advertencia sobre posible parada",
    "panel.accounts.accountDetails.RiskManagement.TradingStatus.Max trailing drawdown was reached": "Se alcanzó la reducción máxima final.",
    "panel.accounts.accountDetails.RiskManagement.TradingStatus.Suspend": "Suspender",
    "panel.accounts.accountDetails.RiskManagement.TradingStatus.Unrealized loss limit reached": "Límite de pérdida no realizado alcanzado",
    "panel.accounts.accountDetails.RiskManagement.TradingStatus.Weekly lost limit reached": "Se alcanzó el límite de pérdida semanal",
    "panel.accounts.AccountMode": "Modo cuenta",
    "panel.accounts.AccountType": "Tipo de cuenta",
    "panel.accounts.AccountValue": "Valor de la cuenta",
    "panel.accounts.AllowOvernightTrading": "Permitir comercio nocturno",
    "panel.accounts.AllowTradingPrePost": "Trading en pre/post markets",
    "panel.accounts.Asset": "Activo",
    "panel.accounts.AssetDescription": "Descripción de activos",
    "panel.accounts.AssetType": "Tipo de activo",
    "panel.accounts.AvailableMargin": "Fondos disponibles",
    "panel.accounts.Balance": "Equilibrar",
    "panel.accounts.BalancePlusAllRisks": "Balance y Allrisks",
    "panel.accounts.BeginBalance": "Neto del dia",
    "panel.accounts.BlockedByOrders": "Margen utilizado para pedidos",
    "panel.accounts.BlockedByPamm": "Capital invertido",
    "panel.accounts.BlockedForStocks": "#hidden#",
    "panel.accounts.BlockedForStocksNew": "Bloqueado para acciones",
    "panel.accounts.CashBalance": "Saldo en efectivo",
    "panel.accounts.ClientType": "Tipo de terminal",
    "panel.accounts.Commissions": "Comisiones",
    "panel.accounts.CreditValue": "Valor de crédito",
    "panel.accounts.CurBalance": "Saldo proyectado",
    "panel.accounts.CurMargin": "Margen inicial requerido, %",
    "panel.accounts.CurrentDailyLoss": "Pérdida diaria",
    "panel.accounts.CurrentFundCapital": "Capital de fondo actual",
    "panel.accounts.CurrentStatus": "Estado trading",
    "panel.accounts.CurrentWeeklyLoss": "Pérdida semanal",
    "panel.accounts.Email": "Email",
    "panel.accounts.Estimate value": "Valor estimado",
    "panel.accounts.FundCapitalGain": "Ganancia de capital del fondo",
    "panel.accounts.FundsUsed": "Fondos utilizados",
    "panel.accounts.FundsUsedPercent": "Fondos utilizados, %",
    "panel.accounts.GrossPNL": "Bruto del dia",
    "panel.accounts.InsertOnBalance": "Insertar en equilibrio",
    "panel.accounts.InterestRate": "Tasa de interés, %",
    "panel.accounts.IntradayRiskManagement": "Estado de gestión de riesgos",
    "panel.accounts.Leverage": "Influencia",
    "panel.accounts.LockedBalance": "Saldo bloqueado",
    "panel.accounts.LockedBy": "Bloqueado",
    "panel.accounts.Login": "Cuenta",
    "panel.accounts.MaintMargin": "Mante. margen requerido, %",
    "panel.accounts.MarginAndRisks": "Margen y riesgos",
    "panel.accounts.MarginAvailableReal": "Margen disponible",
    "panel.accounts.MarginDeficiency": "Deficiencia de margen",
    "panel.accounts.MarginForOthers": "#hidden#",
    "panel.accounts.MarginRequirement": "Pos. maint. req.",
    "panel.accounts.MarginStopOut": "Mante. margen requerido",
    "panel.accounts.MarginStopTrade": "Dejar de comerciar",
    "panel.accounts.MarginSurplus": "Margen excedente",
    "panel.accounts.MarginUsedNew": "Margen inicial requerido",
    "panel.accounts.MarginWarning": "Margen de advertencia",
    "panel.accounts.marginWarnPercent": "Nivel de advertencia de margen",
    "panel.accounts.MarketDataCharging": "Carga de datos de mercado",
    "panel.accounts.MaxDailyLossLimit": "Límite de pérdida diaria",
    "panel.accounts.MaxDailyProfit": "Objetivo de beneficio diario",
    "panel.accounts.MaxDayVolume": "#hidden#",
    "panel.accounts.MaximumLot": "Lote máximo",
    "panel.accounts.MaxOrderAmount": "Cantidad de pedido máx.",
    "panel.accounts.MaxOrderCapital": "Capital de pedido máx.",
    "panel.accounts.MaxOrdersPerDay": "# Límite de flujo de pedidos (por día)",
    "panel.accounts.MaxPendingOrders": "# Máx. ordens pendientes",
    "panel.accounts.MaxPendingOrdersValue": "Máx. ordens pendientes valor",
    "panel.accounts.MaxPositionQtyPerSymbol": "Cantidad máx. por simbolo",
    "panel.accounts.MaxPositions": "# Posiciones max.",
    "panel.accounts.MaxWeekLostLimit": "Límite de pérdida semanal",
    "panel.accounts.MinMargin": "Margen utilizado para puestos",
    "panel.accounts.Murkups": "Markups",
    "panel.accounts.N/A_TelephoneNumber": "N/A",
    "panel.accounts.OptionPremiumReq": "Opción premium req.",
    "panel.accounts.OptionValue": "Valor de la opción",
    "panel.accounts.Orders": "# Ordens",
    "panel.accounts.Owner": "Propietario",
    "panel.accounts.Positions": "# Posiciones",
    "panel.accounts.PowerOfAttorney": "Poder legal",
    "panel.accounts.Profit": "Abrir bruto P/L",
    "panel.accounts.ProfitNet": "Red abierta P/L",
    "panel.accounts.Role": "Tipo de fondos",
    "panel.accounts.Spreads": "Diferenciales",
    "panel.accounts.Status": "Estado",
    "panel.accounts.Collateral": "#hidden#",
    "panel.accounts.StocksLiquidity": "Liquidez de acciones",
    "panel.accounts.StocksOrdersReq": "Se requieren ordenes de acciones",
    "panel.accounts.StocksValue": "Valor de las acciones",
    "panel.accounts.stopOutPercent": "Parada fuera de nivel",
    "panel.accounts.stopTradingPercent": "Detener el nivel comercial",
    "panel.accounts.Swap": "Swap",
    "panel.accounts.TelephoneNumber": "Número de teléfono",
    "panel.accounts.TodayFees": "Comisiones de hoy",
    "panel.accounts.TodayRebates": "Retrocesiones de hoy",
    "panel.accounts.TodaySwaps": "#hidden#",
    "panel.accounts.TodayTrades": "# Las operaciones de hoy",
    "panel.accounts.TodayTurnover": "Facturacion hoy",
    "panel.accounts.TodayVolume": "Volumen de hoy",
    "panel.accounts.TotalMaxPositionsQty": "Cantidad máxima total de la posición",
    "panel.accounts.TrailingDrawdownLevel": "Nivel de reducción final",
    "panel.accounts.UnrealizedLoss": "Pérdida no realizada",
    "panel.accounts.UnrealizedLossLimit": "Límite de pérdida no realizada",
    "panel.accounts.UnsettledCash": "Efectivo sin liquidar",
    "panel.accounts.UserGroup": "Grupo de usuario",
    "panel.accounts.UserID": "ID de usuario",
    "panel.accounts.UserLogin": "Inicio de sesión de usuario",
    "panel.accounts.VolumeLimitForEquities": "Límite de volumen de acciones",
    "panel.accounts.VolumeLimitForFutures": "Límite de volumen de futuros",
    "panel.accounts.VolumeLimitForOptions": "Opciones de límite de volumen",
    "panel.accounts.WithdrawalAvailable": "Retiro disponible",
    "panel.assetBalances": "Cuentas",
    "panel.backoffice": "Backoffice",
    "panel.backoffice.You need to add connection!": "Necesitas agregar conexión!",
    "panel.PositionsBalance": "Balance de posiciones",
    "panel.caption.NotAllowedMode": "El panel actual no está permitido en el conjunto de reglas",
    "panel.caption.showHideToolbar": "Mostrar la barra de herramientas",
    "panel.chart": "Gráfico",
    "panel.close": "Cerca",
    "panel.closePosition.Gross_P/L_ToClose": "P/L bruto para cerrar",
    "panel.closePosition.QunatityToClose": "Cantidad a cerrar",
    "panel.copypanel": "Panel duplicado",
    "panel.detail": "Cotización detallada",
    "panel.detail.AuctionEndTime": "#hidden#",
    "panel.detail.CBAuctionStart": "#hidden#",
    "panel.detail.change": "Cambio",
    "panel.detail.changePr": "Cambio, %",
    "panel.detail.CurrentSession": "#hidden#",
    "panel.detail.high": "Alto",
    "panel.detail.last": "Último",
    "panel.detail.Limit.High": "#hidden#",
    "panel.detail.Limit.Low": "#hidden#",
    "panel.detail.low": "Bajo",
    "panel.detail.NormalMarketSize": "#hidden#",
    "panel.detail.OffExchangeValue": "#hidden#",
    "panel.detail.OffExchangeVolume": "#hidden#",
    "panel.detail.open": "Abierto",
    "panel.detail.TradedValue": "#hidden#",
    "panel.detail.TradingStatus": "#hidden#",
    "panel.detail.volume": "Volumen",
    "panel.EventLog.Action": "Evento",
    "panel.EventLog.Date": "Fecha",
    "panel.EventLog.eventType.alerts": "Alertas",
    "screen.Alerts.Ask": "Ask",
    "screen.Alerts.Bid": "Bid",
    "screen.Alerts.Last": "Último",
    "screen.Alerts.ChangePr": "Cambio, %",
    "screen.Alerts.Volume": "Volumen",
    "panel.EventLog.eventType.comment": "Comentario",
    "panel.EventLog.eventType.disconnect": "Desconectar",
    "panel.EventLog.eventType.exception": "Excepción",
    "panel.EventLog.eventType.info": "Info",
    "panel.EventLog.eventType.none": "Ninguna",
    "panel.EventLog.eventType.system": "Sistema",
    "panel.EventLog.eventType.trading": "Trading",
    "panel.EventLog.eventType.updater": "Actualizador",
    "panel.EventLog.menu.Clear": "Claro",
    "panel.EventLog.Message": "Detalles",
    "panel.EventLog.NamePanel": "Registro de eventos",
    "panel.EventLog.Roundtrip": "Roundtrip, ms",
    "panel.EventLog.Type": "Tipo",
    "panel.GridPanel.listButton.ToolTip": "Seleccionar símbolo para el panel",
    "panel.GridPanel.refreshAllCharts.ToolTip": "Actualizar todos los gráficos",
    "panel.GridPanel.resetMI.Text": "Restablecer tamaño de panel",
    "panel.informer": "Lista de vigilancia",
    "panel.instrumentInfo.AvgPrice": "Precio medio:",
    "panel.instrumentInfo.P/L": "P/L:",
    "panel.instrumentInfo.Qty": "Cantidad:",
    "panel.instruments": "Los símbolos",
    "panel.instrumentsInfo": "Información del símbolo",
    "panel.knowledgebase": "Base de conocimientos",
    "panel.level2": "Profundidad del mercado",
    "panel.Level2.Age": "#hidden#",
    "panel.level2.Auction": " AUC",
    "panel.Level2.AvgPrice": "Precio medio",
    "panel.Level2.Contr. CCY Total value": "Valor CCY de Contr",
    "panel.Level2.Contr. CCY value": "Valor CCY de Contr",
    "panel.Level2.DayTradeVolume": "Volumen",
    "panel.Level2.menu.View": "Ver",
    "panel.Level2.menu.View.InstrInfo": "Barra de posición",
    "panel.Level2.menu.View.Level1": "Barra de nivel 1",
    "panel.Level2.menu.View.Order Entry": "Orden de entrada",
    "panel.Level2.MPID": "MPID",
    "panel.Level2.Number": "Orden",
    "panel.Level2.oe.BuyButton": "Compra",
    "panel.Level2.oe.SellButton": "Venta",
    "panel.Level2.Price": "Precio",
    "panel.Level2.Size": "Tamaño",
    "panel.Level2.Source": "Fuente",
    "panel.Level2.Time": "Hora",
    "panel.Level2.TotalVol": "Tamaño total",
    "panel.log": "Registro de eventos",
    "panel.menu.GroupBy": "Agrupar por",
    "panel.menu.new": "Nuevo",
    "panel.menu.Remove": "Retirar",
    "panel.menu.resetToDefault": "Restablecer los valores predeterminados",
    "panel.neworder": "Orden de entrada",
    "panel.newOrderEntry.accountLabel": "Cuenta",
    "panel.newOrderEntry.AddAskButton": "Sell Ask",
    "panel.newOrderEntry.AddBitButton": "Buy Bid",
    "panel.newOrderEntry.amountLabel": "Cantidad",
    "panel.newOrderEntry.BuyAskButton": "Buy Ask",
    "panel.newOrderEntry.BuyMarketButton": "Compra de mercado",
    "panel.newOrderEntry.buySideCheckBox": "Compra",
    "panel.newOrderEntry.instrumentLabel": "Símbolo",
    "panel.newOrderEntry.limitPricePanel": "Precio límite",
    "panel.newOrderEntry.placeOrderButton": "Realizar pedido",
    "panel.newOrderEntry.ProductType": "Tipo de producto",
    "panel.newOrderEntry.RefreshMarginReq": "Haga clic para actualizar los datos de margen o espere 30 segundos.",
    "panel.newOrderEntry.riskDetailsLabel": "Margen",
    "panel.newOrderEntry.SellBidButton": "Sell Bid",
    "panel.newOrderEntry.SellMarketButton": "Venta de mercado",
    "panel.newOrderEntry.sellSideCheckBox": "Venta",
    "panel.newOrderEntry.Side": "Tipo",
    "panel.newOrderEntry.singleOrderRadio": "Simple",
    "panel.newOrderEntry.slPriceRisk": "Precio SL",
    "panel.newOrderEntry.slPriceRiskOffset": "Desplazamiento  SL",
    "panel.newOrderEntry.stopLossCheckBox": "Precio SL",
    "panel.newOrderEntry.stopLossCheckBox.offset": "Desplazamiento  SL",
    "panel.newOrderEntry.stopPricePanel": "Precio de parada",
    "panel.newOrderEntry.takeProfitCheckBox": "Precio TP",
    "panel.newOrderEntry.takeProfitCheckBox.offset": "TP deslizamiento",
    "panel.newOrderEntry.tifPanel": "Validez",
    "panel.newOrderEntry.tpPriceRisk": "Precio TP",
    "panel.newOrderEntry.tpPriceRiskOffset": "TP deslizamiento",
    "panel.newOrderEntry.trstopOffset": "Tr. stop offset",
    "panel.newOrderEntry.typePanel": "Tipo de orden",
    "panel.NewTrades.Account": "Cuenta",
    "panel.NewTrades.Amount": "Cantidad",
    "panel.NewTrades.Bought": "Compró",
    "panel.NewTrades.Commission": "Tasa de ejecución",
    "panel.NewTrades.Commission.InInstrumentQuotingCurrency": "#hidden#",
    "panel.NewTrades.Date": "Fecha y hora",
    "panel.NewTrades.Exchange": "#hidden#",
    "panel.NewTrades.ExpirationDate": "Fecha de exp.",
    "panel.NewTrades.ExternalOrderId": "#hidden#",
    "panel.NewTrades.ExternalPrice": "Precio externo",
    "panel.NewTrades.ExternalTradeId": "ID externo",
    "panel.NewTrades.instrType": "Simb.tipo",
    "panel.NewTrades.Instrument": "Símbolo",
    "panel.NewTrades.Login": "Iniciar sesión",
    "panel.NewTrades.Net_PL": "Neto P/L",
    "panel.NewTrades.Net_PL.InInstrumentQuotingCurrency": "#hidden#",
    "panel.NewTrades.Operation": "Tipo",
    "panel.NewTrades.OrderId": "Solicitar ID",
    "panel.NewTrades.OrderType": "Tipo de orden",
    "panel.NewTrades.Price": "Precio",
    "panel.NewTrades.ProductType": "Tipo de producto",
    "panel.NewTrades.PurchasePrice": "Exposición",
    "panel.NewTrades.RealizedProfitLoss": "P/L Bruto",
    "panel.NewTrades.RealizedProfitLoss.InInstrumentQuotingCurrency": "#hidden#",
    "panel.NewTrades.Rebates": "Reembolsos",
    "panel.NewTrades.Route": "Ruta",
    "panel.NewTrades.Sold": "Vendido",
    "panel.NewTrades.StrikePrice": "Precio de ejercicio",
    "panel.NewTrades.Time": "Hora",
    "panel.NewTrades.TradeID": "Operación ID",
    "panel.NewTrades.TradeIDLabel": "#hidden#",
    "panel.OrderBook.Account": "Cuenta",
    "panel.OrderBook.Action": "Tipo",
    "panel.OrderBook.Bought": "Compro",
    "panel.OrderBook.Date": "Fecha y hora",
    "panel.OrderBook.DislocedQty": "#hidden#",
    "panel.OrderBook.Event": "Evento",
    "panel.OrderBook.ExpirationDate": "Fecha de exp.",
    "panel.OrderBook.ExternalOrderID": "#hidden#",
    "panel.OrderBook.FilledQuantity": "#hidden#",
    "panel.OrderBook.instrType": "Simb.tipo",
    "panel.OrderBook.Instrument": "Símbolo",
    "panel.OrderBook.Login": "Iniciar sesión",
    "panel.OrderBook.Message": "Mensaje",
    "panel.OrderBook.OrderNumber": "Solicitar ID",
    "panel.OrderBook.Price": "Precio",
    "panel.OrderBook.ProductType": "Tipo de producto",
    "panel.OrderBook.Quantity": "Cantidad",
    "panel.OrderBook.ReminingQuantity": "#hidden#",
    "panel.OrderBook.RemoteID": "ID remota",
    "panel.OrderBook.Route": "Ruta",
    "panel.OrderBook.Sold": "Vendido",
    "panel.OrderBook.StopPrice": "Precio de parada",
    "panel.OrderBook.StrikePrice": "Precio de ejercicio",
    "panel.OrderBook.TIF": "Validity",
    "panel.OrderBook.Time": "Hora",
    "panel.OrderBook.TraderID": "Modificado por el usuario",
    "panel.OrderBook.Type": "Tipo",
    "panel.orderEntry": "Orden de entrada",
    "panel.orderHistory": "Historial de pedidos",
    "panel.orders": "Órdenes de trabajo",
    "panel.orders.Account": "Cuenta",
    "panel.orders.action": "Tipo",
    "panel.orders.AvgFilledPrice": "#hidden#",
    "panel.orders.BindTo": "Obligado a",
    "panel.orders.Cancel": "Cancelar",
    "panel.orders.Comment": "#hidden#",
    "panel.orders.Comments": "Comentarios",
    "panel.orders.Ct_Ahead": "Ct. Por delante",
    "panel.orders.CurrentPrice": "Precio actual",
    "panel.orders.date": "Fecha",
    "panel.orders.date_time": "Fecha y hora",
    "panel.orders.disclosedQty": "#hidden#",
    "panel.orders.Event": "Evento",
    "panel.orders.Exchange": "Intercambiar",
    "panel.orders.ExpirationDate": "Fecha de exp.",
    "panel.orders.ExternalOrderId": "#hidden#",
    "panel.orders.ExternalOrderStatus": "#hidden#",
    "panel.orders.GroupId": "ID de grupo MAM",
    "panel.orders.InitReq": "Requisitos iniciales",
    "panel.orders.Instr_Type": "Simb.tipo",
    "panel.orders.Login": "Iniciar sesión",
    "panel.orders.Market": "Mercado",
    "panel.orders.menu.CancelAllByInstrument": "Cancelar todo por el símbolo seleccionado",
    "panel.orders.menu.CancelAllForAccount": "Cancelar todo para la cuenta seleccionada",
    "panel.orders.menu.CancelAllOrders": "Cancelar todos los pedidos",
    "panel.orders.menu.CancelOrder": "Cancelar orden",
    "panel.orders.menu.ExecuteAsMarket": "Cambiar al mercado",
    "panel.orders.menu.ExecuteAsMarket.question": "¿Está seguro de que desea ejecutar la orden a precio de mercado?",
    "panel.orders.menu.ModifyOrder": "Modificar orden...",
    "panel.orders.menu.Remove": "Cancelar orden",
    "panel.orders.OCOGrouID": "OCO grupo ID",
    "panel.orders.price": "Precio",
    "panel.orders.ProductType": "Tipo de producto",
    "panel.orders.quantity": "Cantidad",
    "panel.orders.QuantityFilled": "Cant. lleno",
    "panel.orders.QuantityRemaining": "Cant. restante",
    "panel.orders.Reference": "Solicitar ID",
    "panel.orders.RemoteID": "ID remota",
    "panel.orders.Route": "Ruta",
    "panel.orders.SL": "SL",
    "panel.orders.SL_Offset": "Desplazamiento  SL",
    "panel.orders.SL_Price": "Precio SL",
    "panel.orders.Status": "Estado",
    "panel.orders.StopPrice": "Precio de parada",
    "panel.orders.StrikePrice": "Precio de ejercicio",
    "panel.orders.symbol": "Símbolo",
    "panel.orders.symbolDescription": "Descripción del símbolo",
    "panel.orders.TIF": "Validez",
    "panel.orders.time": "Hora",
    "panel.orders.Token": "Solicitar ID",
    "panel.orders.TP": "TP",
    "panel.orders.TP_Offset": "TP deslizamiento",
    "panel.orders.TP_Price": "Precio TP",
    "panel.orders.TraderID": "Usuario ID",
    "panel.orders.type": "Tipo",
    "panel.orders.UserGroup": "Grupo de usuario",
    "panel.positions": "Posiciones",
    "panel.positions.Account": "Cuenta",
    "panel.positions.AvailableForSell": "#hidden#",
    "panel.positions.Basis": "Precio de apertura",
    "panel.positions.Close": "Cerca",
    "panel.positions.comments": "Comentarios",
    "panel.positions.commissions": "Cuota",
    "panel.positions.commissions.InstrumentCurrency": "#hidden#",
    "panel.positions.confirmation.Reverse": "Marcha atrás",
    "panel.positions.confirmation.ReverseAllPositions": "¿Estás seguro de que quieres invertir todas las posiciones?",
    "panel.positions.confirmation.ReverseByInstrument": "¿Está seguro de que desea invertir todas las posiciones por símbolo y cuenta elegidos?",
    "panel.positions.confirmation.ReverseSelectedPositions": "¿Estás seguro de que deseas invertir las posiciones seleccionadas?",
    "panel.positions.cur_price": "Precio actual",
    "panel.positions.date_time": "Fecha y hora",
    "panel.positions.Delta": "Delta",
    "panel.positions.ExpDate": "Fecha de exp.",
    "panel.positions.exposition_usd": "Pos. exposición",
    "panel.positions.Gamma": "Gamma",
    "panel.positions.GroupId": "MAM grupo ID",
    "panel.positions.InitReq": "Requisitos iniciales",
    "panel.positions.IV": "IV",
    "panel.positions.Login": "Iniciar sesión",
    "panel.positions.MaintReq": "Mantenimiento requerido",
    "panel.positions.MarginSuplus": "Surpl./Def. margin",
    "panel.positions.menu.Close": "Posición cerrada",
    "panel.positions.menu.Modify": "Modificar posición",
    "panel.positions.menu.ShowToolbar": "Mostrar la barra de herramientas",
    "panel.positions.menu.View": "Ver",
    "panel.positions.Net_PL": "Neto P/L",
    "panel.positions.Net_PL.InstrumentCurrency": "#hidden#",
    "panel.positions.Operation": "Tipo",
    "panel.positions.PL_ticks": "P/L, compensar",
    "panel.positions.PL_ticks.points": "Puntos",
    "panel.positions.PL_ticks.ticks": "ticks",
    "panel.positions.pos_number": "ID de posición",
    "panel.positions.PositionValue": "Valor pos.",
    "panel.positions.ProductType": "Tipo de producto",
    "panel.positions.profit_usd": "P/L Bruto",
    "panel.positions.profit_usd.InstrumentCurrency": "#hidden#",
    "panel.positions.Qunatity": "Cantidad",
    "panel.positions.RealizedPnL": "#hidden#",
    "panel.positions.RealizedPnL.InstrumentCurrency": "#hidden#",
    "panel.positions.Rho": "Rho",
    "panel.positions.route": "Ruta",
    "panel.positions.SL": "Precio SL",
    "panel.positions.SL_Value": "SL, Valor",
    "panel.positions.strike": "Precio de ejercicio",
    "panel.positions.swaps": "Swaps",
    "panel.positions.Symbol": "Símbolo",
    "panel.positions.SymbolDescription": "Descripción del símbolo",
    "panel.positions.Theta": "Theta",
    "panel.positions.TP": "Precio TP",
    "panel.positions.type": "Simb.tipo",
    "panel.positions.UsedMargin": "Margen utilizado",
    "panel.positions.UserGroup": "Grupo de usuario",
    "panel.positions.Vega": "Vega",
    "panel.settings.Information": "Información",
    "panel.settings.saveDefault": "¡Guardado exitosamente!",
    "panel.terceraChart": "Gráfico",
    "panel.TerceraSymbolLookupDropDownForm.Add": "Añadir",
    "panel.TerceraSymbolLookupDropDownForm.All": "Todos",
    "panel.TerceraSymbolLookupDropDownForm.Bonds": "Cautiverio",
    "panel.TerceraSymbolLookupDropDownForm.Cancel": "Cancelar",
    "panel.TerceraSymbolLookupDropDownForm.CFDs": "CFDs",
    "panel.TerceraSymbolLookupDropDownForm.collapceAllButton": "Desplegar todo",
    "panel.TerceraSymbolLookupDropDownForm.crypto": "Crypto CCY",
    "panel.TerceraSymbolLookupDropDownForm.Equities": "Acciones",
    "panel.TerceraSymbolLookupDropDownForm.ETFs": "ETFs",
    "panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.All exchanges": "Todos los intercambios",
    "panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.exchanges": "intercambios",
    "panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.No exchanges": "Sin intercambios",
    "panel.TerceraSymbolLookupDropDownForm.expandExchangesButton": "Expandir intercambios",
    "panel.TerceraSymbolLookupDropDownForm.expandTypesButton": "Expandir tipos",
    "panel.TerceraSymbolLookupDropDownForm.Forex": "Forex",
    "panel.TerceraSymbolLookupDropDownForm.Forward": "Adelante",
    "panel.TerceraSymbolLookupDropDownForm.Futures": "Futuros",
    "panel.TerceraSymbolLookupDropDownForm.indices": "Índices",
    "panel.TerceraSymbolLookupDropDownForm.Options": "Opciones",
    "panel.TerceraSymbolLookupDropDownForm.Portfolio": "Sintético",
    "panel.TerceraSymbolLookupDropDownForm.Select": "Seleccionar",
    "panel.TerceraSymbolLookupDropDownForm.Select all": "Seleccionar todo",
    "panel.TerceraSymbolLookupDropDownForm.Show additionl info": "Mostrar información adicional",
    "panel.TerceraSymbolLookupDropDownForm.Spot": "Lugar",
    "panel.TerceraSymbolLookupDropDownForm.Spreadbet": "Apuesta Spread",
    "panel.TerceraSymbolLookupDropDownForm.TBill": "T-bill",
    "panel.TerceraSymbolLookupDropDownForm.typeComboBox.All types": "Todos los tipos",
    "panel.TerceraSymbolLookupDropDownForm.typeComboBox.No types": "Sin tipos",
    "panel.TerceraSymbolLookupDropDownForm.typeComboBox.types": "tipos",
    "panel.timeSales": "Tiempo y ventas",
    "panel.timeSales.Agressor flag": "Tipo",
    "panel.timeSales.AgressorFlag.Auction": "Subasta",
    "panel.timeSales.AgressorFlag.Buy": "Compra",
    "panel.timeSales.AgressorFlag.CrossTrade": "Comercio transfronterizo",
    "panel.timeSales.AgressorFlag.Ninguna": "Ninguna",
    "panel.timeSales.AgressorFlag.Sell": "Venta",
    "panel.timeSales.ask": "Ask",
    "panel.timeSales.AskBidSize": "BidxAsk tamaño",
    "panel.timeSales.AskSource": "Asks MPID",
    "panel.timeSales.bid": "Bid",
    "panel.timeSales.BidSource": "Bids MPID",
    "panel.timeSales.BuyerSource": "Comprador",
    "panel.timeSales.Exchange": "Fuente",
    "panel.timeSales.Instrument": "Símbolo",
    "panel.timeSales.MenuName": "Tiempo y ventas",
    "panel.timeSales.Price": "Precio",
    "panel.timeSales.SellerSource": "Vendedor",
    "panel.timeSales.Size": "Tamaño",
    "panel.timeSales.Time": "Hora",
    "panel.timeSales.Type": "Simb.tipo",
    "panel.tooltiper.System": "Sistema",
    "panel.trades": "Pedidos llenados",
    "panel.watchlist": "Lista de vigilancia",
    "panel.watchlist.Ask": "Ask",
    "panel.watchlist.AskSize": "Ask Tamaño",
    "panel.watchlist.AuctionEndTime": "#hidden#",
    "panel.watchlist.Bid": "Bid",
    "panel.watchlist.BidSize": "Bid Tamaño",
    "panel.watchlist.Buy": "Buy",
    "panel.watchlist.CBAuctionStart": "#hidden#",
    "panel.watchlist.cell.cancel": "Cancelar",
    "panel.watchlist.cell.modify": "Modificación",
    "panel.watchlist.cell.Not allow": "No permitido",
    "panel.watchlist.cell.sending": "Nuevo",
    "panel.watchlist.cell.ticks": "ticks",
    "panel.watchlist.Change": "Cambio",
    "panel.watchlist.ChangeFromOpen": "Cambiar de abierto",
    "panel.watchlist.ChangeFromOpen%": "Cambiar de abierto, %",
    "panel.watchlist.ChangePr": "Cambio, %",
    "panel.watchlist.Close": "Cerrar",
    "panel.watchlist.ClosePostMarket": "Post-mercado  cierre",
    "panel.watchlist.Comment": "Comentario",
    "panel.watchlist.Company": "Empresa",
    "panel.watchlist.ContractSize": "Último tamaño",
    "panel.watchlist.CurrentSession": "Sesión actual",
    "panel.watchlist.Date": "Ultima cita",
    "panel.watchlist.Descr": "Descripción",
    "panel.watchlist.Exchange": "Intercambiar",
    "panel.watchlist.ExpDate": "Fecha de exp.",
    "panel.watchlist.High": "Alto",
    "panel.watchlist.High13": "#hidden#",
    "panel.watchlist.High26": "#hidden#",
    "panel.watchlist.High52": "#hidden#",
    "panel.watchlist.IndicativeAuctionPrice": "Ind. auction pr.",
    "panel.watchlist.Instrument": "Símbolo",
    "panel.watchlist.Last": "Último",
    "panel.watchlist.LastSize": "Último tamaño",
    "panel.watchlist.Limit.High": "#hidden#",
    "panel.watchlist.Limit.Low": "#hidden#",
    "panel.watchlist.Low": "Bajo",
    "panel.watchlist.Low13": "#hidden#",
    "panel.watchlist.Low26": "#hidden#",
    "panel.watchlist.Low52": "#hidden#",
    "panel.watchlist.Main Volume": "Volumen principal",
    "panel.watchlist.Market": "Mercado",
    "panel.watchlist.MarketCap": "Tapa del mercado.",
    "panel.watchlist.Match Volume": "Volumen del partido",
    "panel.watchlist.menu.AddSymbol": "Agrega símbolos...",
    "panel.watchlist.menu.clearAll": "Limpiar todo",
    "panel.watchlist.menu.RemoveInstrument": "Eliminar símbolos seleccionados",
    "panel.watchlist.menu.SymbolInfo": "Información del símbolo",
    "panel.watchlist.NA": "N/A",
    "panel.watchlist.noQuotes": "Última actualización",
    "panel.watchlist.NormalMarketSize": "#hidden#",
    "panel.watchlist.OffExchangeValue": "#hidden#",
    "panel.watchlist.OffExchangeVolume": "#hidden#",
    "panel.watchlist.Open": "Abierto",
    "panel.watchlist.OpenInterest": "#hidden#",
    "panel.watchlist.OpenPreMarket": "Pre-mercado abierto",
    "panel.watchlist.PreMarketChange": "Cambio Pre-mercado",
    "panel.watchlist.PreMarketChange%": "Cambio Pre-mercado, %",
    "panel.watchlist.PrevClose": "Cierre anterior",
    "panel.watchlist.PrevSettlementPrice": "Precio de liquidación anterior",
    "panel.watchlist.ReferencePrice": "Precio de referencia",
    "panel.watchlist.Sell": "Sell",
    "panel.watchlist.SettlementPrice": "Precio liquidacion",
    "panel.watchlist.Spread": "Untado",
    "panel.watchlist.SpreadPr": "Untado, %",
    "panel.watchlist.Ticks": "Ticks",
    "panel.watchlist.TicksPostMarket": "Post-mercado ticks",
    "panel.watchlist.TicksPreMarket": "Pre-mercado ticks",
    "panel.watchlist.Time": "Ultima vez",
    "panel.watchlist.TradedValue": "#hidden#",
    "panel.watchlist.Type": "Simb.tipo",
    "panel.watchlist.Volume": "Volumen",
    "panel.watchlist.VolumePostMarket": "Post-mercado volumen",
    "panel.watchlist.VolumePreMarket": "Pre-mercado volumen",
    "Panels.MarketDepth.CancelAll": "Cancelado todo",
    "panels.orders.buttons.AsMarket": "Cambiar al mercado",
    "panels.orders.buttons.CancelAllButton": "Cancelado todo",
    "panels.orders.buttons.CancelBuyButton": "Cancelar compra",
    "panels.orders.buttons.CancelByInstrumentButton": "Cancelar",
    "panels.orders.buttons.CancelDayButton": "Cancelar días",
    "panels.orders.buttons.CancelGTCButton": "Cancelar GTCs",
    "panels.orders.buttons.CancelLimitsButton": "Cancelar límites",
    "panels.orders.buttons.CancelSelectedButton": "Cancelar seleccionado",
    "panels.orders.buttons.CancelSellButton": "Cancelar venta",
    "panels.orders.buttons.CancelStopsButton": "Cancelar paradas",
    "panels.orders.buttons.ModifyOrder": "Modificar orden",
    "panels.positions.buttons.closeAll": "Cierra todo",
    "panels.positions.buttons.closeForInstrument": "Cerrar",
    "panels.positions.buttons.closeLong": "Cierre largo",
    "panels.positions.buttons.closeNegative": "Cerrar negativo",
    "panels.positions.buttons.closePositive": "Cerrar positivo",
    "panels.positions.buttons.closeSelected": "Cerrar seleccionado",
    "panels.positions.buttons.closeShort": "Cerrar corto",
    "panels.positions.buttons.clxAll": "CLX todos",
    "panels.positions.buttons.clxForInstrument": "CLX",
    "panels.positions.ButtonsPanel.helpLabel": "Haga clic derecho para mostrar/ocultar botones",
    "PanelSettingsScreen.Text": "- Configuraciones",
    "PanelSettingsScreen.TextSmall": "- Configuraciones",
    "Parabolic Time/Price System": "Tiempo parabolic / sistema de precios",
    "period.Day": "Día",
    "period.Days": "Dias",
    "period.Hour": "Hour",
    "period.Hours": "Hours",
    "period.Min": "Min",
    "period.Mins": "Mins",
    "period.Month": "Mes",
    "period.Months": "Meses",
    "period.Week": "Semana",
    "period.Weeks": "Semanas",
    "period.Year": "Año",
    "period.Years": "Años",
    "Pivot Point Moving Average": "Punto de pivote Promedio móvil",
    "PivotPoint": "Punto de pivote",
    "PopupMultiCkeckBox.All": "Todos",
    "Português": "Português (Br)",
    "Price Channel": "Canal de precios",
    "Price Oscillator": "Oscilador de precios",
    "ProductType.CarryForward": "Llevar adelante",
    "ProductType.Delivery": "Entrega",
    "ProductType.General": "General",
    "ProductType.Intraday": "Intradía",
    "property.%": "%",
    "property.Additional": "Adicional",
    "property.AllPrice": "Estadísticas de precios",
    "property.appearance": "Apariencia",
    "property.Ask": "Ask",
    "property.Author": "Autor",
    "property.autosize": "Tamaño automático de columnas",
    "property.autoYScale.Auto": "Auto escala",
    "property.autoYScale.Manual": "Escala manual",
    "property.Average": "Promedio",
    "property.Backgr": "Color de fondo",
    "property.Backgr. top": "Back top color",
    "property.BarPattern": "Patrón de barra",
    "property.barsHiLowColor": "Línea",
    "property.BarStatistics": "Estadísticas de barra",
    "property.Bid": "Bid",
    "property.bodyColor": "Cuerpo",
    "property.borderColor": "Frontera",
    "property.BottomCenter": "Parte inferior central",
    "property.BottomLeft": "Abajo a la izquierda",
    "property.BottomRight": "Abajo a la derecha",
    "property.Browser": "Navegador",
    "property.Buy": "Compra",
    "property.BuyColor": "Compra",
    "property.cAsk": "Ask tamaño",
    "property.cASK": "por Ask",
    "property.cASKHI": "Por encima de Ask",
    "property.cBid": "Bid tamaño",
    "property.cBID": "por Bid",
    "property.cBIDLO": "Debajo de Bid",
    "property.cellscin.useSellBuy": "Botón reverso sorder",
    "property.Change": "Cambio",
    "property.ChangePercent": "Cambio, %",
    "property.Chart": "Gráfico",
    "property.Chart OE": "Entrada de orden de gráfico",
    "property.chart.isBackground": "Mover al fondo",
    "property.chart.line": "Línea",
    "property.chart.ray": "RAY",
    "property.chart.rayStyle": "Estilo Ray",
    "property.chart.showAngle": "Mostrar ángulo",
    "property.chart.showingOE": "Visible",
    "property.chart.stickTo": "Atenerse a",
    "property.chart.tools.back": "Dibujo de relleno",
    "property.chart.tools.fibretr.font": "Fuente de etiqueta",
    "property.chart.tools.fibretr.fontcolor": "Color de fuente",
    "property.ChartAnalyseToolsToolBar": "Barra de herramientas de análisis",
    "property.ChartDrawingToolsToolBar": "Barra de herramientas de dibujo",
    "property.cLimit": "Orden de límite",
    "property.Close": "Cerrar",
    "property.Cluster.ClusterUpDownColors": "Racimo",
    "property.Cluster.ColorScheme": "Esquema de colores",
    "property.Cluster.ColorScheme.ByDelta": "Por delta",
    "property.Cluster.ColorScheme.ByDelta.Colors": "Color",
    "property.Cluster.ColorScheme.ByDelta.Colors.BuyColor": "Buy",
    "property.Cluster.ColorScheme.ByDelta.Colors.SellColor": "Sell",
    "property.Cluster.ColorScheme.ByTrades": "Por oficios",
    "property.Cluster.ColorScheme.ByVolume": "Por volumen",
    "property.Cluster.ColorScheme.Color": "Color",
    "property.Cluster.ColorScheme.Ninguna": "Ninguna",
    "property.Cluster.CustomStep": "Paso personalizado",
    "property.Cluster.Down": "Abajo",
    "property.Cluster.FilterValue": "Valor de filtro",
    "property.Cluster.Font": "Fuente",
    "property.Cluster.FontColor": "Color de fuente",
    "property.Cluster.HighlightMax": "Resaltar máximo",
    "property.Cluster.HighlightMax.Colors": "Colores",
    "property.Cluster.Operator": "Operador",
    "property.Cluster.PriceStep": "Paso de precio",
    "property.Cluster.PriceStep.Custom": "Personalizado",
    "property.Cluster.PriceStep.MinTick": "Garrapata mínima",
    "property.Cluster.ShowOnlySimilarDelta": "Mostrar solo delta similar",
    "property.Cluster.ShowValueFilter": "Filtro de valor",
    "property.Cluster.Up": "Arriba",
    "property.cMIDLO": "Entre la propagación",
    "property.Color": "Color",
    "property.ColoringMode": "Modo de coloración",
    "property.Colors": "Colores",
    "property.colors_param": "Colores",
    "property.colorScheme": "Estilo",
    "property.ColorsSeparatorGroup": "Colores",
    "property.ColouringMode": "Modo de coloración",
    "property.Comments": "Comentarios",
    "property.Company": "Empresa",
    "property.Confirmations": "Confirmaciones",
    "property.connection": "Conexión",
    "property.Connection": "Conexión",
    "property.ContentAlignment": "Alineación",
    "property.Copyrights": "Derechos de autor",
    "property.cQUOTE": "Citas",
    "property.CreateColor": "Crear",
    "property.crossHairFontColor": "Color de fuente",
    "property.cStop": "orden de stop",
    "property.currency": "Moneda",
    "property.CustomYMarkingValue": "Min. grid step",
    "property.Data Box": "Ventana de información",
    "property.Data Style": "Estilo de datos",
    "property.Day": "Día",
    "property.DaySeparatorsVisibility": "Separadores de día",
    "property.dealTickets.openOnWorkspace": "Boletos de ofertas abiertas en el espacio de trabajo",
    "property.DefaultProductType": "Tipo de producto predeterminado",
    "property.Defaults": "Valores predeterminados de trading",
    "property.deviation": "desviación",
    "property.dK": "dK",
    "property.Doji": "Doji",
    "property.Downband deviation in %": "Desviación de la banda descendente en %",
    "property.email": "Direcciones de correo electrónico \ n (Use coma o punto y coma)",
    "property.Emulator": "Emulador",
    "property.enable": "Habilitar",
    "property.Enable Sounds": "Activar sonidos",
    "property.Exponential": "Exponencial",
    "property.fib.Arc": "Fib. Arc",
    "property.Fibonacci": "Fibonacci",
    "property.Filters": "Filtros",
    "property.FitDrawings": "Ajustar dibujos",
    "property.FitHighLow": "Día de ajuste Alto/Bajo",
    "property.FitIndicator": "Indicadores de ajuste",
    "property.FitOrders": "Ajustar órdenes/posiciones",
    "property.FOK": "FOK",
    "property.Font": "Fuente",
    "property.Forex": "Forex",
    "property.Forwards": "Hacia adelante",
    "property.Futures": "Futuros",
    "property.FX Cell": "FX cell",
    "property.G": "G",
    "property.General": "General",
    "property.general.defaultSymbol": "Símbolo",
    "property.general.OvernightMarginNotificationMessage": "#hidden#",
    "property.general.showLotsFX": "Mostrar cantidad en lotes",
    "property.gr_type": "Estilo",
    "property.Gradient": "Gradiente",
    "property.GraphicType.Absolute": "Absoluto",
    "property.GraphicType.Log": "Logarítmico",
    "property.GraphicType.Relative": "Relativo",
    "property.GridPrice": "Precio (horizontal)",
    "property.GridPriceHighLight": "Resaltar color de cuadrícula",
    "property.GridTime": "Tiempo (vertical)",
    "property.GTC": "GTC",
    "property.GTD": "GTD",
    "property.HeaderFont": "Fuente",
    "property.HeaderText": "Texto de cabecera",
    "property.High": "Alto",
    "property.HighlightMarkingsStep": "Destacar paso de cuadrícula",
    "property.HistogramLineColor": "Línea de histograma",
    "property.HistoryType": "Tipo de datos",
    "property.HorizontalLine.LeftRay": "Extender a la izquierda",
    "property.hostName": "Nombre del anfitrión",
    "property.hotkeyManager.ShowVolume": "Mostrar volumen",
    "property.infowindow_font": "Fuente",
    "property.infoWindow_fore_back_color": "Color",
    "property.infowindow_visible": "Mostrar ventana de información",
    "property.Instrument Types": "Ordenar valores predeterminados por tipo",
    "property.Intrinsic": "Intrinsic",
    "property.IOC": "IOC",
    "property.IsBackground": "Fondo del eje",
    "property.IsBarsVisible": "#Bars",
    "property.IsChangeVisible": "Cambio, %",
    "property.IsCloseVisible": "Cerca",
    "property.IsDateVisible": "Fecha",
    "property.IsHighVisible": "Alto",
    "property.IsLowVisible": "Bajo",
    "property.IsOpenVisible": "Abierto",
    "property.IsRoundedAvgPrice": "Precio de apertura promedio redondeado",
    "property.isShowToolTips": "Consejos sobre herramientas",
    "property.isShowToolTipsGrop": "Consejos sobre herramientas",
    "property.IsSyncActive": "Sincronizar con gráficos",
    "property.IsTicksVisible": "Ticks",
    "property.IsTimeVisible": "Hora",
    "property.IsVolumeVisible": "Volumen",
    "property.LabelColors": "Colores de la etiqueta",
    "property.Language": "Idioma",
    "property.Last": "Último",
    "property.lastPrice_AskIndicatorColor": "Ask",
    "property.lastPrice_BidIndicatorColor": "Bid",
    "property.lastPrice_LastIndicatorColor": "Último",
    "property.lastPrice_SpreadIndicatorColor": "Color del área extendida",
    "property.Left ray": "Extender a la izquierda",
    "property.Level": "Nivel",
    "property.Limit": "Límite",
    "property.Line": "Línea",
    "property.line.lineName": "Nombre de línea",
    "property.line.plateVisible": "Mostrar marcador de línea {0}",
    "property.line.timeShift": "Cambio de hora:",
    "property.line.value": "Valor",
    "property.line.Visible": "Visible",
    "property.line.yFactor": "Y factor",
    "property.Linear Weighted": "Ponderado lineal",
    "property.lineProperties": "propiedades",
    "property.LOC": "LOC",
    "property.logBase": "Base de registro",
    "property.LOO": "LOO",
    "property.LotsMode": "Mostrar tamaño en",
    "property.Low": "Bajo",
    "property.Mail Server": "Servidor de correo",
    "property.Manual": "Manual",
    "property.Market": "Mercado",
    "property.MarketCap": "Cap. de Mercado",
    "property.MarketDepth.ColoringMethod": "Método de coloración",
    "property.MarketProfile.ColorScheme": "Esquema de colores",
    "property.MarketProfile.ColorScheme.Color": "Color",
    "property.MarketProfile.ColorScheme.GradientColor": "Zona",
    "property.MarketProfile.ColorScheme.PairColor.Color": "Color",
    "property.MarketProfile.ColorScheme.VolumeColor": "Color",
    "property.MarketProfile.Font": "Fuente",
    "property.MarketProfile.PointOfControl": "Punto de control",
    "property.MarketProfile.PointOfControlStyle": "Estilo de punto de control",
    "property.MarketProfile.ShowSingles": "Mostrar unicamente",
    "property.MarketProfile.SinglesStyle": "Estilo de solteros",
    "property.MarketProfile.ValueArea": "Área de valor",
    "property.MarketProfile.ValueAreaStyleHorisontal": "Estilo de línea horizontal",
    "property.MarketProfile.ValueAreaStyleVertical": "Estilo de línea vertical",
    "property.MarketProfile.ValuePercents": "Valor, %",
    "property.Medium": "Medio",
    "property.message": "Mensaje",
    "property.method": "método",
    "property.MirrorShow": "Vista espejo",
    "property.MOC": "MOC",
    "property.Mode": "Modo",
    "property.Mode.Trades": "Operaciones",
    "property.Modified": "Modificado",
    "property.MonthSeparatorsVisibility": "Separadores de mes",
    "property.MOO": "MOO",
    "property.name": "Nombre",
    "property.Name": "Nombre",
    "property.News": "Noticias",
    "property.Ninguna": "Ninguna",
    "property.Note": "Nota",
    "property.NotSet": "NotSet",
    "property.Number": "Contar",
    "property.OCO": "OCO",
    "property.OE_AddBidAddAsk": "Compra Oferta/Venta Pregunta",
    "property.OE_ShowBuyMarketSellMarket": "Mercado de Compra/Mercado de Venta",
    "property.OE_ShowSellBidBuyASk": "Venta Oferta/Compra Pregunta",
    "property.OE_ShowSLTP": "Cerrar pedidos (SL/TP)",
    "property.OE_Small": "Entrada de orden estrecha",
    "property.OE_Small.ToolTip": "Si está marcado, la entrada del pedido tendrá una vista de flecha",
    "property.oe.confirmClosePosition": "Confirmar cierre de posición",
    "property.oe.confirmOrderCancel": "Confirmar cancelación de pedido",
    "property.oe.confirmOrderChange": "Confirmar pedido/modificación de posiciones",
    "property.oe.confirmOrderCreate": "Confirmar pedido",
    "property.oe.confirmReversePosition": "Confirmar inversión de posición",
    "property.OffsetMode": "Mostrar desplazamiento en",
    "property.OnClose": "On Close",
    "property.OnOpen": "En abierto",
    "property.Open": "Abierto",
    "property.Options": "General",
    "property.Options.ToolTip": "Configuración general",
    "property.Order Entry": "Orden de entrada",
    "property.OrderBuyStyleWidth": "Buy",
    "property.OrderSellStyleWidth": "Sell",
    "property.OverlayVisible": "Superposición visible",
    "property.Panel name": "Nombre del panel",
    "property.panel.accountDetails.Groups.1.General": "General",
    "property.panel.accountDetails.Groups.2.Margin": "Margen",
    "property.panel.accountDetails.Groups.3.AccountActivity": "Actividad de la cuenta",
    "property.panel.accountDetails.Groups.4.TodayResults": "Resultados de hoy",
    "property.panel.accountDetails.Groups.5.RiskManagement": "Gestión de riesgos",
    "property.panel.accountDetails.Groups.6.Info": "Info",
    "property.Panels": "Paneles",
    "property.Parameters": "Parámetros",
    "property.password": "Contraseña",
    "property.period": "período",
    "property.Period of indicator": "Periodo de indicador",
    "property.Period of indicator:": "Periodo de indicador",
    "property.Period of Linear Regression": "Periodo de linear regression",
    "property.Period of MA for envelopes": "Período de adelantos MA",
    "property.Period of MA1": "Período de MA1",
    "property.Period of MA2": "Período de MA2",
    "property.Period of momentum:": "Período de momentum",
    "property.Period of Moving Average": "Periodo de media móvil",
    "property.Period of price channel": "Periodo de canal de precios",
    "property.Period of Simple Moving Average": "Periodo de media móvil simple",
    "property.Period of Smoothed Moving Average": "Periodo de media móvil suavizada",
    "property.Periods": "Períodos",
    "property.pin": "PIN",
    "property.pips": "Pepitas",
    "property.POCTypes.Line": "Línea",
    "property.POCTypes.Marker": "Marcador",
    "property.POCTypes.Ninguna": "Ninguna",
    "property.points": "Puntos",
    "property.Points": "Puntos",
    "property.PositionBuyStyleWidth": "Alcista",
    "property.PositionSellStyleWidth": "Bajista",
    "property.Previous": "Anterior",
    "property.price": "precio",
    "property.Price Scale": "Escala de precios",
    "property.PriceIndicatorDrawingType": "Indicador de precio",
    "property.PriceIndicatorDrawingType.Ninguna": "Ninguna",
    "property.PriceIndicatorDrawingType.ScaleMarker": "Marcador de escala",
    "property.PriceIndicatorDrawingType.ScaleMarkerWithLine": "Marcador de escala con línea",
    "property.PriceScaleAxisColor": "Eje",
    "property.PriceScaleBackColor": "Fondo del eje",
    "property.PriceScaleFont": "Fuente",
    "property.PriceScaleTextColor": "Color de texto",
    "property.PriceType": "Tipo de precio",
    "property.ProfileColoringTypes.DeltaBars": "Barras Delta",
    "property.ProfileColoringTypes.DeltaPrice": "Precio Delta",
    "property.ProfileColoringTypes.DeltaProfile": "Perfil Delta",
    "property.ProfileColoringTypes.Single": "De un solo color",
    "property.ProfileColoringTypes.UpdownBars": "Barras arriba/abajo",
    "property.ProfileColoringTypes.UpdownProfile": "Perfil arriba/abajo",
    "property.ProfileColoringTypes.VolumeBars": "Barras de volumen",
    "property.ProfileColoringTypes.VolumePrice": "Precio por volumen",
    "property.protrader.showOrders": "Ordens",
    "property.Range": "Distancia",
    "property.Ray style": "Estilo Ray",
    "property.Relative": "Relativo",
    "property.relativeCalcType": "Precio de salida",
    "property.RelativeCalculationType.BeginOfTheData": "Comienzo de los datos",
    "property.RelativeCalculationType.BeginOfTheDay": "Comienzo del día",
    "property.RelativeCalculationType.BeginOfTheScreen": "Comienzo de la pantalla",
    "property.relativeScaleType": "Tipo de escala",
    "property.Reserve": "Reserva",
    "property.Reverse": "Marcha atrás",
    "property.Right ray": "Extender a la derecha",
    "property.routing.account": "Cuenta",
    "property.routing.orderType": "Tipo de orden",
    "property.RSI Method:": "RSI Método",
    "property.RSI Period:": "RSI Período",
    "property.SCRIPTS_SUBGROUP": "Guiones",
    "property.ScrollBar": "Barra de desplazamiento",
    "property.secure": "Seguro",
    "property.Secure": "Seguro",
    "property.Selection": "Selección",
    "property.Sell": "Sell",
    "property.SellColor": "Sell",
    "property.SeparatorGroup.ActiveOrders": "Órdenes de trabajo",
    "property.SeparatorGroup.additional": "Adicional",
    "property.SeparatorGroup.Additional": "Adicional",
    "property.SeparatorGroup.Appearance": "Apariencia",
    "property.SeparatorGroup.CrossHair": "Pelo cruzado",
    "property.SeparatorGroup.ExecutedOrders": "Pedidos llenados",
    "property.SeparatorGroup.Grid": "Cuadrícula",
    "property.SeparatorGroup.HighlightDate": "Fecha destacada",
    "property.SeparatorGroup.HighlightPrices": "Destacar precios",
    "property.SeparatorGroup.OpenPositions": "Posiciones abiertas",
    "property.SeparatorGroup.ScaleMarkings": "Marcas de escala",
    "property.SeparatorGroup.ScaleType": "Tipo de escala",
    "property.SeparatorGroup.SeparatorOEWidth": "Ver",
    "property.SeparatorGroup.Show": "Мostrar",
    "property.SeparatorGroup.TimeToNextBar": "Hora de la próxima barra",
    "property.SeparatorGroup.WindowColors": "Colores de fondo",
    "property.SeparatorGroup.Zoom": "Enfocar",
    "property.SeparatorViewButtons": "Comercio rápido",
    "property.Sesonality": "Estacionalidad",
    "property.shift": "Cambio",
    "property.showAllSessions": "Mostrar sesión extendida",
    "property.ShowEmptyBars": "Agujeros en la historia",
    "property.showEvents": "Visible",
    "property.ShowFullArc": "Mostrar arco completo",
    "property.ShowHighLow": "Día Alto/Bajo",
    "property.showOrders": "Visible",
    "property.showPositions": "Visible",
    "property.ShowPrevCloseLine": "Cierre anterior",
    "property.ShowTimeToNextBar": "Visible",
    "property.ShowTotal": "Mostrar total",
    "property.ShowVisualTradingOnLeftSide": "Posiciones/órdenes activas vinculadas al lado izquierdo",
    "property.showVolume": "Visible",
    "property.showVolumeMarker": "Mostrar marcador de valor en escala",
    "property.Simple": "Sencillo",
    "property.sltpOffsetMode": "Establecer valores SL/TP en offset",
    "property.Smoothing": "Suavizado",
    "property.solidPriceColor": "Zona",
    "property.sounds_param": "Suena",
    "property.Sources prices for MA": "Precios de fuentes para MA",
    "property.Specific": "Específico",
    "property.SpreadType": "Tipo de propagación",
    "property.SpreadType.Area": "Zona",
    "property.SpreadType.Line": "Línea",
    "property.SpreadType.LineWithLabel": "Linea con precio",
    "property.SpreadType.Ninguna": "Ninguna",
    "property.Step of parabolic SAR system": "Paso del sistema parabólico SAR",
    "property.Stick to": "Atenerse a",
    "property.stickTo": "Atenerse a:",
    "property.Stocks": "Acciones",
    "property.Stop": "Detener",
    "property.Stop Limit": "Límite de parada",
    "property.Stop-loss": "Detener la pérdida de",
    "property.style": "Estilo:",
    "property.Sum": "Suma",
    "property.Symbol": "Símbolo",
    "property.Symbols": "Los símbolos",
    "property.T": "T",
    "property.Take-profit": "Take profit",
    "property.text": "Texto",
    "property.TIF": "Mercado validity",
    "property.TIF_Limit_And_Stop_Limit": "Límite validity (Stop limit validity)",
    "property.TIF_Stop": "Stop validity",
    "property.Time Scale": "Escala de tiempo",
    "property.TimeLine": "Cronograma",
    "property.TimelinePreview": "Vista previa del gráfico de barras de desplazamiento",
    "property.TimeScaleAxisColor": "Eje",
    "property.TimeScaleBackColor": "Fondo del eje",
    "property.TimeScaleFont": "Fuente",
    "property.TimeScaleImportentFont": "Fuente de fechas importantes",
    "property.TimeScaleTextColor": "Color de fuente",
    "property.TimeToNextBarColor": "Color",
    "property.TimeToNextBarFont": "Fuente",
    "property.TopCenter": "Centro Superior",
    "property.TopLeft": "Arriba a la izquierda",
    "property.TopRight": "Parte superior derecha",
    "property.trackShowCrossHair": "Visible",
    "property.Trading": "Trading",
    "property.Trading Defaults": "Trading",
    "property.Type of moving average": "Tipo de media móvil",
    "property.Type of Moving Average": "Tipo de media móvil",
    "property.Typical": "Típico",
    "property.Upband deviation in %": "Desviación de la banda ascendente en %",
    "property.Update": "Actualizar",
    "property.UseCustomYMarkings": "Cuadrícula personalizada",
    "property.user": "Iniciar sesión",
    "property.useSSL": "Seguro",
    "property.Value of confidence interval": "Valor del intervalo de confianza",
    "property.ValueColors": "Colores de valor",
    "property.VATypes.Horizontal": "Horizontal",
    "property.VATypes.Ninguna": "Ninguna",
    "property.VATypes.ToNextProfile": "Al siguiente perfil",
    "property.VATypes.Vertical": "Vertical",
    "property.vendorName": "Vendedor",
    "property.View": "Ver",
    "property.ViewSeparatorGroup": "Ver",
    "property.Visibility": "Visibilidad",
    "property.VisibilitySeparatorGroup": "Visibilidad",
    "property.Visible": "Visible",
    "property.Visual Trading": "Trading visual",
    "property.Volume": "Barras de volumen",
    "property.Volume.Font": "Fuente",
    "property.Volume.FontColor": "Color de fuente",
    "property.Volume.UseTicksData": "Use un volumen de tick real",
    "property.VolumeColoringMode": "Esquema de coloración del tamaño",
    "property.VolumeDownColor": "Negativo",
    "property.VolumeFixedColor": "Igual",
    "property.VolumeHeightPercent": "Altura, %",
    "property.VolumeMode.BuyVolume": "Comprar volumen",
    "property.VolumeMode.Delta": "Delta",
    "property.VolumeMode.MaxTickVolume": "Volumen máximo de garrapatas",
    "property.VolumeMode.SellVolume": "Volumen de venta",
    "property.VolumeMode.TotalVolume": "Volumen total",
    "property.VolumeMode.Trades": "Operaciones",
    "property.VolumeOpacityPercent": "Transparencia",
    "property.VolumeProfile": "Perfil de volumen",
    "property.VolumeUpColor": "Positivo",
    "property.VWAP": "VWAP",
    "property.Warnings": "Advertencias",
    "property.WatermarkColor": "Marca de agua color",
    "property.WeekSeparatorsVisibility": "Separadores semanales",
    "property.Weighted": "Ponderado",
    "property.wickColor": "Mecha",
    "property.width": "Anchura:",
    "property.Win num": "Win num on gráfico para indicador",
    "property.Win num.addWin": "Subventana",
    "property.Win num.main": "Principal",
    "property.Y factor": "Y factor",
    "property.YearSeparatorsVisibility": "Separadores de año",
    "property.ZeroLine": "Línea cero",
    "property.ZoomValue": "Paso de escala",
    "propery.MainPriceRenderer.Down": "Abajo",
    "propery.MainPriceRenderer.Negative": "Negativo",
    "propery.MainPriceRenderer.Positive": "Positivo",
    "propery.MainPriceRenderer.Up": "Arriba",
    "Relative Strength Index": "Índice de Fuerza Relativa",
    "reports.accounts": "Cuantía(s)",
    "reports.instruments": "Instrumento(s)",
    "reports.fromDate": "De",
    "reports.toDate": "A",
    "reports.asset": "Activo",
    "Reserve": "Reserva",
    "ribbon.enviroment": "Ambiente",
    "ribbon.enviroment.help": "Ayuda",
    "ribbon.enviroment.logout": "Cerrar sesión",
    "ribbon.enviroment.New": "Nuevo espacio de trabajo",
    "ribbon.enviroment.NotificationWindow.lockTrading": "Trading bloqueado",
    "ribbon.enviroment.NotificationWindow.UnlockTrading": "Trading desbloqueado",
    "ribbon.enviroment.Save": "Guardar espacio de trabajo",
    "ribbon.enviroment.settings": "Configuraciones",
    "ribbon.help.about": "Acerca de",
    "ribbon.help.help": "Más",
    "ribbon.help.home": "Página de inicio",
    "ribbon.linking.linkBlue": "Azul",
    "ribbon.linking.linkEmpty": "Ninguna",
    "ribbon.linking.linkGreen": "Verde",
    "ribbon.linking.linkOrange": "Naranja",
    "ribbon.linking.linkPurple": "Púrpura",
    "ribbon.linking.linkRed": "Rojo",
    "ribbon.linking.linkYellow": "Amarillo",
    "ribbon.tools": "Herramientas",
    "ribbon.tools.full_logs": "Mensajes completos del servidor de registro",
    "ribbon.tools.grid": "Cuadrícula",
    "ribbon.tools.withdrawal": "Retirada",
    "ribbon.tools.reports": "Informes",
    "ribbon.tradingTerminal": "Terminal",
    "RiskManagement.Messages.DailyLimit": "Se alcanzó el límite de pérdida diaria.",
    "RiskManagement.Messages.MaxDailyProfit": "Se alcanzó la ganancia diaria",
    "RiskManagement.Messages.MaxTrailingLimit": "Se alcanzó la reducción máxima final.",
    "RiskManagement.Messages.MaxUnrealizedLossLimit": "Se alcanzó el límite de pérdida no realizada",
    "RiskManagement.Messages.WeeklyLimit": "Se alcanzó el límite de pérdida semanal",
    "screen.about.copyright": "© 2017-2024 TraderEvolution Global LTD.",
    "screen.about.dateBuild": "Fecha de construcción:",
    "screen.about.title": "Acerca de",
    "screen.about.titleWeb": "Sobre TraderEvolution",
    "screen.about.versionWeb": "Versión de compilación",
    "screen.additionalVerification.comment": "La conexión ({1}) requirió verificación adicional:",
    "screen.additionalVerification.Need activation": "Necesita activación",
    "screen.additionalVerification.Need activation.comment": "Ingrese el código de activación (enviado a su correo)",
    "screen.additionalVerification.Need activation.watermark": "<Ingrese el código de activación del correo electrónico>",
    "screen.additionalVerification.title": "Verificación adicional",
    "screen.additionalVerification.title.Email": "Verificación adicional (e-mail one-time pass)",
    "screen.additionalVerification.title.Fixed": "Verificación adicional (paso fijo)",
    "screen.additionalVerification.title.Sms": "Verificación adicional (SMS de un solo paso)",
    "screen.additionalVerification.watermark": "<Ingrese contraseña adicional>",
    "screen.changepass.cancel": "Cancelar",
    "screen.changepass.changed": "Contraseña cambiada con éxito",
    "screen.changepass.confirm": "Confirmar nueva contraseña",
    "screen.changepass.new": "Nueva contraseña",
    "screen.changepass.ok": "OK",
    "screen.changepass.old": "Contraseña actual",
    "screen.changepass.title": "Cambia la contraseña",
    "screen.changeTradingPass.title": "Cambiar contraseña comercial",
    "screen.changepass.watermark_confirm": "<Confirmar contraseña>",
    "screen.changepass.watermark_new": "<Nueva contraseña>",
    "screen.changepass.watermark_old": "<Contraseña anterior>",
    "screen.closePosition.cancel": "Cancelar",
    "screen.closePosition.ok": "Cerrar",
    "screen.closePosition.title": "Posición cerrada",
    "screen.error.title": "Error",
    "screen.licensing.emptyFieldErrors.password": "El campo de contraseña está vacío",
    "screen.licensing.statusMessages.connecting": "Conectando...",
    "screen.login.change_pass": "La contraseña se ha restablecido o caducado y debe cambiarse",
    "screen.lookup.scripts.noitems": "No hay elementos coincidentes",
    "screen.lookup.scripts.showing": "Mostrando {1} artículos",
    "screen.lookup.scripts.watermark": "<Buscar>",
    "screen.modifyOrder.amount": "Cantidad:",
    "screen.modifyOrder.bind_to": "Obligado a:",
    "screen.modifyOrder.Change order": "Modificar orden",
    "screen.modifyOrder.created": "Fecha y hora:",
    "screen.modifyOrder.instrument": "Símbolo:",
    "screen.modifyOrder.market_price": "Precio actual:",
    "screen.modifyOrder.modify": "Modificar",
    "screen.modifyOrder.modifyPosition": "Modificar posición",
    "screen.modifyOrder.number": "ID de posición:",
    "screen.modifyOrder.operation": "Tipo:",
    "screen.modifyOrder.orderNumber": "Solicitar ID:",
    "screen.modifyOrder.ordertype": "Tipo de orden:",
    "screen.modifyOrder.price": "Precio de apertura:",
    "screen.modifyOrder.route": "Ruta:",
    "screen.properties.cancel": "Cerrar",
    "screen.properties.cancel.ToolTip": "Cerrar",
    "screen.properties.common": "propiedades",
    "screen.properties.ok": "OK",
    "screen.properties.ok.ToolTip": "OK",
    "screen.properties.title": "Configuración general",
    "screen.properties.unsavedChanges": "Usted tiene cambios no guardados. ¿Salir y perder sus cambios?",
    "screen.LoginScreen.Connecting": "Conectando...",
    "screen.LoginScreen.Error.loginIdIsEmpty": "El campo ID de inicio de sesión está vacío",
    "screen.LoginScreen.Error.passwordIdIsEmpty": "El campo de contraseña está vacío",
    "screen.LoginScreen.ForgotLinkLabel": "Se te olvidó tu contraseña",
    "screen.LoginScreen.ForgotLinkLabel.ToolTip": "Recuperación de contraseña",
    "screen.LoginScreen.LoadingUserData": "Cargando datos del usuario...",
    "screen.LoginScreen.LoginButtonText": "Iniciar sesión",
    "screen.LoginScreen.LoginButtonTooltip": "Inicia sesión en la plataforma",
    "screen.LoginScreen.LoginIDLabel": "Iniciar sesión",
    "screen.LoginScreen.LoginIDTextBox.ToolTip": "Inicio de sesión de usuario",
    "screen.LoginScreen.PasswordLabel": "Contraseña",
    "screen.LoginScreen.PasswordTextBox.ToolTip": "Contraseña de usuario",
    "screen.LoginScreen.RegistrationLinkLabel": "Crear cuenta demo",
    "screen.LoginScreen.RegistrationLinkLabel.ToolTip": "Registro de cuenta demo",
    "screen.LoginScreen.SavePassCheckBox": "Guardar contraseña",
    "screen.LoginScreen.SavePassCheckBox.ToolTip": "Recordar credenciales",
    "screen.LoginScreen.Settings.LanguageLabel": "Idioma",
    "screen.recconection.attemptLabelLabel": "Intentos:",
    "screen.recconection.reconnecting": "Reconectando...",
    "screen.recconection.seconds": "segundos...",
    "screen.recconection.statusLabelLabel": "Estado:",
    "screen.recconection.stop": "Detener",
    "screen.recconection.title": "Gerente de reconexión",
    "screen.recconection.tryNow": "Probar ahora",
    "screen.recconection.waiting": "Esperando",
    "screen.RecoveryPassForm.Cancel": "Cancelar",
    "screen.RecoveryPassForm.Confirm": "Confirmar contraseña",
    "screen.RecoveryPassForm.Email": "Email:",
    "screen.RecoveryPassForm.HaveCode": "Tengo llave",
    "screen.RecoveryPassForm.Key": "Llave",
    "screen.RecoveryPassForm.Login": "Iniciar sesión:",
    "screen.RecoveryPassForm.New password": "Nueva contraseña",
    "screen.RecoveryPassForm.New password contains prohibited symbols": "La nueva contraseña contiene símbolos prohibidos",
    "screen.RecoveryPassForm.Send": "Recuperar",
    "screen.RecoveryPassForm.Title": "Recuperación de contraseña",
    "screen.registrationWizard.confirmPassword": "*Confirmar contraseña:",
    "screen.registrationWizard.confirmPassword.watermark": "<Confirmar la contraseña>",
    "screen.registrationWizard.email": "*Email:",
    "screen.registrationWizard.email.watermark": "<Email>",
    "screen.registrationWizard.Country": "País:",
    "screen.registrationWizard.Password": "*Contraseña:",
    "screen.registrationWizard.Balance": "Equilibrar:",
    "screen.registrationWizard.firstName": "*Nombre:",
    "screen.registrationWizard.firstName.watermark": "<min 2 chars>",
    "screen.registrationWizard.lastName": "*Apellido:",
    "screen.registrationWizard.lastName.watermark": "<min 2 chars>",
    "screen.registrationWizard.login": "Iniciar sesión:",
    "screen.registrationWizard.login.watermark": "<Dejar en blanco para autogeneración>",
    "screen.registrationWizard.password.watermark": "<Introduzca su contraseña>",
    "screen.registrationWizard.phone": "Número de teléfono:",
    "screen.registrationWizard.phoneNumber.watermark": "<(código de país) número de teléfono>",
    "screen.registrationWizard.registrationStatus": "Estado de registro",
    "screen.registrationWizard.successMessage": "¡El registro fue correcto!\nLogin: {1}\nContraseña: {2}",
    "screen.registrationWizard.Header": "Crear cuenta demo",
    "screen.registrationWizard.Register": "Registro",
    "screen.registrationWizard.Cancel": "Cancelar",
    "screen.renameScreen.cancel": "Cancelar",
    "screen.renameScreen.illegalName": "Nombre ilegal!",
    "screen.renameScreen.ok": "OK",
    "screen.ReportMessageTooltip.Account": "Cuenta",
    "screen.ReportMessageTooltip.CreatedAt": "creado en",
    "screen.ReportMessageTooltip.FilledAt": "llenado en",
    "screen.ReportMessageTooltip.MarginCall": "Llamada de margen",
    "screen.ReportMessageTooltip.Market": "Mercado",
    "screen.ReportMessageTooltip.ModifiedAt": "modificado en",
    "screen.ReportMessageTooltip.RemovedAt": "eliminado en",
    "screen.reports.actions_print": "Impresión",
    "screen.reports.address": "Dirección",
    "screen.reports.beginDatePicker.ButtonDropDown.ToolTip": "Haga clic para abrir el calendario.",
    "screen.reports.beginDatePicker.ToolTip": "Seleccionar fecha/hora",
    "screen.reports.brokerzip": "Código postal",
    "screen.reports.button_printer_setup": "Configuracion",
    "screen.reports.byGroup": "Por grupo",
    "screen.reports.byInstrumentGroup": "Por grupo",
    "screen.reports.byInstruments": "Por instrumentos",
    "screen.reports.byUser": "Por cuenta",
    "screen.reports.byUserGroup": "Por grupo",
    "screen.reports.cell_data": "",
    "screen.reports.column.inner_text": "",
    "screen.reports.columns_maneger": "Administrador de columnas...",
    "screen.reports.country": "País",
    "screen.reports.csv": "Exportar a CSV...",
    "screen.reports.customerzip": "Código postal",
    "screen.reports.dataCoverage": "Cobertura de datos",
    "screen.reports.errSaving": "Se produjo un error al guardar el archivo.",
    "screen.reports.excel": "Exportar a Excel...",
    "screen.reports.exportToButton": "Exportar a...",
    "screen.reports.filter": "Administrador de filtros...",
    "screen.reports.historyFor": "Historia para",
    "screen.reports.html": "Exportar a HTML...",
    "screen.reports.info": "Information",
    "screen.reports.error": "Error",
    "screen.reports.Reports": "Informes",
    "screen.reports.InstrumentAndGroupLookup.byGroupCombobox.ToolTip": "Seleccionar grupo de símbolos",
    "screen.reports.InstrumentAndGroupLookup.instrunentLookup.ToolTip": "Seleccionar símbolo",
    "screen.reports.license": "Número de licencia",
    "screen.reports.listViewEx.itemAll": "Todos",
    "screen.reports.manager": "Gerente",
    "screen.reports.minimizeButton.Hide reports parameters": "Ocultar parámetros de informes",
    "screen.reports.minimizeButton.Show reports parameters": "Mostrar parámetros de informes",
    "screen.reports.name": "Nombre",
    "screen.reports.news.daily": "Diario",
    "screen.reports.news.from": "Desde",
    "screen.reports.news.hideACriterias": "Ocultar criterios avanzados",
    "screen.reports.news.invalidDateTime": "Seleccione la fecha / hora válidas 'desde' y 'hasta'",
    "screen.reports.news.monthToDate": "Mes hasta la fecha",
    "screen.reports.news.range": "Distancia",
    "screen.reports.news.showACriterias": "Mostrar criterios avanzados",
    "screen.reports.news.sinceInception": "Desde el inicio",
    "screen.reports.news.till": "Hasta",
    "screen.reports.news.yearToDate": "El año hasta la fecha",
    "screen.reports.noData": "Datos no disponibles",
    "screen.reports.order": "Orden",
    "screen.reports.param.Name": "Nombre",
    "screen.reports.paramOfReport": "Parámetros de informe",
    "screen.reports.pdf": "Exportar a PDF...",
    "screen.reports.phone": "Número de teléfono",
    "screen.reports.pos": "Position",
    "screen.reports.president": "Nombre del presidente",
    "screen.reports.print": "Impresión...",
    "screen.reports.processingRequset": "Cancelar",
    "screen.reports.rangeError": "¡La fecha de inicio no puede ser más que la fecha!",
    "screen.reports.report.button_printer_setup": "Configuracion",
    "screen.reports.reportHeader.accnumber": "Número de cuenta",
    "screen.reports.reportHeader.from": "Desde",
    "screen.reports.reportHeader.Text": "Información del cliente y corredor",
    "screen.reports.reportHeaders.account": "Cuenta:",
    "screen.reports.reportHeaders.currancy": "Moneda:",
    "screen.reports.reportHeaders.date": "Fecha:",
    "screen.reports.reportHeaders.from": "Desde:",
    "screen.reports.reportHeaders.owner": "Propietario:",
    "screen.reports.reportHeaders.to": "A:",
    "screen.reports.reportHeaderText": "Información del cliente y corredor",
    "screen.reports.reportParameters": "Parámetros de informe",
    "screen.reports.reports.Canceled": "La operación fue cancelada",
    "screen.reports.reports.ExpingToCSV": "Exportando datos a documentos CSV...",
    "screen.reports.reports.ExpingToExcel": "Exportando datos a documentos Excel...",
    "screen.reports.reports.ExpingToHTML": "Exportando datos a documentos HTML...",
    "screen.reports.reports.ExpingToPDF": "Exportando datos a documentos PDF...",
    "screen.reports.reports.ExportingPrint": "Exportar datos para imprimir...",
    "screen.reports.reports.ExpPrint": "Los datos se exportaron para imprimir con éxito.",
    "screen.reports.reports.ExpPrintErr": "¡Se produjo un error durante la exportación para imprimir!",
    "screen.reports.reports.ExpToCSV": "Los datos se exportaron a CSV con éxito.",
    "screen.reports.reports.ExpToCSVErr": "¡Se produjo un error durante la exportación a CSV!",
    "screen.reports.reports.ExpToExcel": "Los datos se exportaron a Excel con éxito.",
    "screen.reports.reports.ExpToExcelErr": "¡Se produjo un error durante la exportación a Excel!",
    "screen.reports.reports.ExpToHTML": "Los datos se exportaron a HTML con éxito.",
    "screen.reports.reports.ExpToHTMLErr": "¡Se produjo un error durante la exportación a HTML!",
    "screen.reports.reports.ExpToPDF": "Los datos se exportaron a PDF con éxito.",
    "screen.reports.reports.ExpToPDFErr": "¡Se produjo un error durante la exportación a PDF!",
    "screen.reports.reports.getReport": "Obtener informe",
    "screen.reports.reports.in_separate_window": "El informe se abrió en una ventana separada.",
    "screen.reports.reports.inCurrentWindow": "En la ventana actual",
    "screen.reports.reports.info": "Use el Administrador de informes para ver e imprimir informes de la actividad de la cuenta",
    "screen.reports.reports.inSeparateWindow": "En ventana separada",
    "screen.reports.reports.loading": "Cargando datos para informe...",
    "screen.reports.reports.noData": "Lo sentimos, no hay datos disponibles para esta solicitud. Intente utilizar otros parámetros de informe.",
    "screen.reports.reports.noItemAvailable": "Ningún artículo disponible",
    "screen.reports.reportType": "Tipo de informe",
    "screen.reports.reportTypeCombobox.ToolTip": "Seleccionar tipo de informe",
    "screen.reports.save": "Salvar",
    "screen.reports.showSummary": "Mostrar resumen",
    "screen.reports.sinceInception": "Desde el inicio",
    "screen.reports.summary": "Resumen",
    "screen.reports.table": "Mesa",
    "screen.reports.title": "Gerente de informes",
    "screen.reports.to": "A",
    "screen.reports.trades": "Operaciones",
    "screen.reports.UserAndGroupLookup.byGroupCombobox.ToolTip": "Seleccionar grupo de cuenta",
    "screen.reports.UserAndGroupLookup.usersLookup.ToolTip": "Seleccionar cuenta",
    "screen.TimeZoneSelector.title": "Seleccionar zonas horarias",
    "screen.UnlockTradingScreen.lockedLabel": "Trading bloqueado",
    "screen.UnlockTradingScreen.unlockButton": "Desbloquear",
    "screen.withdrawal.account": "De la cuenta",
    "screen.withdrawal.Asset": "Activo",
    "screen.withdrawal.Availible funds": "Disponible",
    "screen.withdrawal.avaliableNumeric.ToolTip": "Conjunto de capital restante",
    "screen.withdrawal.Cancel": "Cancelar",
    "screen.withdrawal.error.not_allowed": "La operación de cuenta con tipo de Retiro no está permitida para su usuario, comuníquese con su corredor",
    "screen.withdrawal.error.not_enough_balance": "Saldo insuficiente",
    "screen.withdrawal.error.not_enough_blocked": "Сuantía no válida (no se puede desbloquear más que bloqueado)",
    "screen.withdrawal.error.not_enough_margin": "Margen insuficiente",
    "screen.withdrawal.error.UnableToBlock": "¡Error! Incapaz de bloquear.",
    "screen.withdrawal.error.WrongSum": "Error: suma incorrecta.",
    "screen.withdrawal.errorLabel": "No hay suficiente dinero para retirar",
    "screen.withdrawal.Fee": "Comisión por retiro:",
    "screen.withdrawal.from": "de",
    "screen.withdrawal.Remains": "Restos",
    "screen.withdrawal.RequiredAmount": "Cuantía requerida:",
    "screen.withdrawal.ReservedWithdrawal": "Retirada",
    "screen.withdrawal.usersLookup.ToolTip": "Seleccionar cuenta",
    "screen.withdrawal.Withdraw": "Retirar",
    "screen.withdrawal.Withdrawal": "Retirada",
    "screen.withdrawal.withdrawalNumeric.ToolTip": "Establecer retiro de capital",
    "screen.withdrawal.Amount": "Cuantía",
    "Simple Moving Average": "Media móvil simple",
    "SixMonth": "6 Meses",
    "Smoothed Moving Average": "Media móvil suavizada",
    "Standard Deviation": "Desviacion estandar",
    "statusBar.passwordpanel.DetalTicket.Text": "Acceso permitido a la actividad comercial",
    "statusBar.passwordPanel.passwordBox.invalidPass": "La contraseña de trading no es válida.",
    "Stochastic": "Estocástico",
    "Stochastic Slow": "Estocástico Lento",
    "Stop": "Detener",
    "Stop Limit": "Límite de parada",
    "Stop-loss": "Detener la pérdida de",
    "Suomi": "#hidden#",
    "Swing Index": "Índice de Swing",
    "Take-profit": "Take profit",
    "TerceraAccountLookup.AccountNotExist": "La cuenta no existe",
    "TerceraAccountLookup.IncorrectAccount": "Cuenta incorrecta",
    "TerceraAccountLookup.Linked accounts": "Cuentas vinculadas",
    "TerceraAccountLookup.Multy select": "Selección múltiple",
    "TerceraAccountLookup.Select": "Seleccionar",
    "TerceraAccountLookup.Select All": "Seleccionar todo",
    "textBoxWithValidating.ErrorText.NameContainsInvalidChar": "Nombre contiene caracteres inválidos",
    "textBoxWithValidating.ErrorText.NameExist": "Este nombre ya existe",
    "ThreeMonth": "3 Meses",
    "Tr. stop": "Trailing stop",
    "TradeDefaultManager.Set defaults": "Configurar valores predeterminados",
    "TradeDefaultManager.Types manager": "Gerente de tipos",
    "TradeTool.Amount of order": "Ordene la cantidad",
    "TradeTool.Cancel order": "Cancelar orden",
    "TradeTool.Cancel SL": "Cancelar SL",
    "TradeTool.Cancel TP": "Cancelar TP",
    "TradeTool.Close position": "Posición cerrada",
    "TradeTool.Drag to set SL order": "Arrastre para establecer el orden de SL",
    "TradeTool.Drag to set TP order": "Arrastre para establecer el orden de TP",
    "TradeTool.Order": "Orden",
    "TradeTool.Position": "Posición",
    "TradeTool.Position P/L": "P/L Bruto",
    "TradeTool.Tick and P/L": "Ticks y P/L",
    "TradeTool.Trailing Stop": "Trailing stop",
    "TradeTool.View more actions": "Ver más acciones",
    "TTO": "TTO",
    "TypesManagerScreen.DefaultLotsSetup": "Configuración predeterminada de lotes",
    "TypesManagerScreen.NumericLabel.Price increment (arrow), ticks": "Ticks de incremento de precio (flecha)",
    "TypesManagerScreen.NumericLabel.SL default offset, ticks": "Ticks de desplazamiento predeterminados de SL",
    "TypesManagerScreen.NumericLabel.TP default offset, ticks": "Ticks de desplazamiento predeterminados de TP",
    "TypesManagerScreen.NumericLabel.User quantity coefficient": "Coeficiente de cantidad de usuario",
    "TypesManagerScreen.QuickTable.Column.Default lots": "Lotes predeterminados",
    "TypesManagerScreen.QuickTable.Column.Enable": "Habilitar",
    "TypesManagerScreen.Types Manager": "Gerente de tipos",
    "UserControl.TerceraNumeric.ValueGreaterMax": "El valor es mayor que el máximo",
    "UserControl.TerceraNumeric.ValueIsEmpty": "El valor está vacío",
    "UserControl.TerceraNumeric.ValueLessMin": "El valor es menor que el mínimo ",
    "UserControl.TerceraNumeric.ValueNotMultiple": "El valor no es múltiple a min. cambio ",
    "UserControl.TerceraNumeric.ValueNotNumber": "El valor no es un número.",
    "Volume": "Volumen",
    "Volume Weighted Average Price": "Volumen peso ed precio promedio",
    "VWAP": "VWAP",
    "allowCreatePanelsFromPanel": "",
    "allowLinkSystem": "",
    "allowWorkspaceContextMenu": "",
    "allowWorkspaceEdit": "",
    "orders.confirm.all.question1": "Cancelalo todo (",
    "orders.confirm.all.question2": ") ordeness?",
    "orders.confirm.all.title": "Cancelar todos los pedidos",
    "orders.confirm.cancel.question1": "¿Cancelar todos los pedidos para el símbolo seleccionado?",
    "positions.confirm.mutual.question1": "¿Quieres un cierre mutuo seleccionado (",
    "positions.confirm.mutual.question2": ") posiciones?",
    "Rows.firstPart": "El límite de símbolos/filas disponibles en",
    "Rows.secondPart": "ha sido alcanzado. Póngase en contacto con su equipo de soporte para obtener información detallada",
    "general.messageBox.cancel.ToolTip": "Descartar cambios y cerrar",
    "property.Confirmations.ToolTip": "Configuraciones de confirmación",
    "general.messageBox.ok.ToolTip": "Aplicar cambios y cerrar",
    "property.Defaults.ToolTip": "Configuración predeterminada de trading",
    "property.View.ToolTip": "Ver configuraciones",
    "property.Warnings.ToolTip": "Configuraciones de advertencia",
    "Throtling.Count.firstPart": "El límite de disponible",
    "Throtling.Count.secondPart": "paneles ha sido alcanzado. Póngase en contacto con su equipo de soporte para obtener información detallada",
    "workspace.At first you should unlock workspace to rename it": "Al principio, debe desbloquear el espacio de trabajo para cambiarle el nombre.",
    "workspace.Clear": "Claro",
    "workspace.clear_ask": "Espacio de trabajo despejado?",
    "workspace.clear_wsp": "Espacio de trabajo despejado",
    "workspace.Close": "Cerca",
    "workspace.close_wsp": "Cerca workspace",
    "workspace.DefaultNameSpace": "Wrk#",
    "workspace.errorSavingFile": "Se produjo un error al guardar el archivo.",
    "workspace.information": "Información",
    "workspace.saved.Message": "Espacio de trabajo guardado",
    "workspace.Lock": "Bloquear",
    "workspace.Lock.Message": "Espacio de trabajo Bloqueado",
    "workspace.menu.Clone": "Clon",
    "workspace.menu.Close": "Cerrar",
    "workspace.menu.CloseAllButThis": "Cierra todo menos esto",
    "workspace.menu.Lock": "Bloquear",
    "workspace.newWorkspaceName": "Ingrese el nuevo nombre del espacio de trabajo",
    "workspace.numberOfWorkspace": "El número de espacios de trabajo abiertos excede el máximo permitido",
    "workspace.Rename": "Rebautizar",
    "workspace.Unlock": "Desbloquear",
    "workspace.Unlock.Message": "Espacio de trabajo Desbloqueado",
    "InstrumentDetailsPanel.ISIN": "ISIN",
    "IndicatorGroup.Moving Average": "Medias móviles",
    "MD": "MD",
    "REGRESSION": "REGRESION",
    "Regression": "Regresion",
    "SMMA": "SMMA",
    "PPMA": "PPMA",
    "SMA": "SMA",
    "EMA": "EMA",
    "IndicatorGroup.Oscillators": "Osciladores",
    "MACD": "MACD",
    "BB": "BB",
    "BBF": "BBF",
    "IndicatorGroup.Volume": "Volumen",
    "RSI": "RSI",
    "PO": "PO",
    "CCI": "CCI",
    "Aroon": "Aroon",
    "KRI": "KRI",
    "Kairi Relative Index": "Kairi Relative Índices",
    "PPO": "PPO",
    "Percentage Price Oscillator": "Oscilador porcentual de precios",
    "RLW": "RLW",
    "%R Larry Williams": "%R Larry Williams",
    "ROC": "ROC",
    "Rate of Change": "Tasa de variación",
    "QStick": "QStick",
    "TSI": "TSI",
    "True Strength Index": "Índice de fuerza real",
    "Pivot point": "Punto pivote",
    "SI": "SI",
    "ALLIGATOR": "ALLIGATOR",
    "MMA": "MMA",
    "IndicatorGroup.Trend": "Tendencía",
    "STOCHASTIC": "ESTOCÁSTICO",
    "OBV": "OBV",
    "IndicatorGroup.Volatility": "Volatilidad",
    "ATR": "ATR",
    "CMO": "CMO",
    "SD": "SD",
    "SAR": "SAR",
    "ADX": "ADX",
    "IndicatorGroup.Channels": "Canales",
    "KELTNER": "KELTNER",
    "CHANNEL": "CANAL",
    "MAE": "MAE",
    "LWMA": "LWMA",
    "MAS3": "MAS3",
    "IndicatorGroup.Extra": "Extra",
    "Didi": "Didi",
    "HiloEscandina": "HiloEscandina",
    "StopATR": "StopATR",
    "Fractals3": "Fractals3",
    "Fractals5": "Fractals5",
    "Kaufman adaptive moving average": "Kaufman media móvil adaptable",
    "Multi EMA": "Multi EMA",
    "Self-Adjusting Alpha with Fractals Energy": "RSI Alfa autoajustable con energía de fractales",

    "Web.mobile.active.WorkingOrders": "Trabajando",
    "Web.mobile.active.WorkingOrders.EmptyPlaceholder": "Sin órdenes",
    "Web.mobile.active.FilledOrders": "Lleno",
    "Web.mobile.active.HistoryOrders": "Historia",
    "Web.mobile.active.Positions": "Posiciones",
    "Web.mobile.active.Positions.EmptyPlaceholder": "Sin posiciones",
    "Web.mobile.active.Assets": "Activos",
    "Web.mobile.active.Orders": "Órdenes",
    "Web.mobile.active.More": "Más",
    "Web.mobile.active.MamSummary": "Resumen MAM",
    "Web.mobile.active.OptionChain": "Cadena de opciones",
    "Web.mobile.active.Alerts.NoAlerts": "Sin alertas",
    "Web.mobile.active.EventLog.NoEvents": "Sin eventos",
    "Web.mobile.active.EventLog.DateTime": "Fecha y hora",
    "Web.mobile.active.MamSummary.Column.OperationType": "Tipo de operación",
    "Web.mobile.active.MamSummary.Column.Amount": "Cantidad",
    "Web.mobile.active.More.Header": "Más",
    "Web.mobile.active.Settings.Defaults": "Configuración predeterminada",
    "Web.mobile.active.Settings.QuantityInLots": "Cantidad en lotes",
    "Web.mobile.active.Settings.SetSlTpInOffset": "Establecer SL/TP en desplazamiento",
    "Web.mobile.active.Settings.OrderSending": "Envío de órdenes",
    "Web.mobile.active.Settings.OrderModifying": "Modificación de órdenes",
    "Web.mobile.active.Settings.OrderExecuting": "Ejecución de órdenes",
    "Web.mobile.active.Settings.OrderCancelling": "Cancelación de órdenes",
    "Web.mobile.active.Settings.PositionModifying": "Modificación de posición",
    "Web.mobile.active.Table.Total.Profit": "Beneficio total",
    "Web.mobile.active.NewTrades.PnL": "P/L",
    "Web.mobile.active.OrderBook.ExecutionType": "Tipo de ejecución",
    "Web.mobile.active.Assets.EmptyPlaceholder": "Sin activos",
    "Web.mobile.active.Assets.DefSymbol": "Símbolo predeterminado",
    "Web.mobile.active.Assets.SODQuantity": "Cantidad SOD",
    "Web.mobile.active.Assets.CurrentValueForSale": "Valor actual para la venta",
    "Web.mobile.active.Positions.Profit": "P/L",
    "Web.mobile.active.AccountDetails.LinkingTooltip": "Esta función filtra sus posiciones y órdenes por cuenta. Los cambios de cuenta se realizan de forma sincronizada en todos los paneles.",
    "Web.mobile.active.Watchlist.EmptyPlaceholder": "Sin símbolos",
    "Web.mobile.active.Watchlist.ListNamePlaceholder": "Escriba el nombre aquí",
    "Web.mobile.active.Watchlist.Added": "Añadido a la lista de seguimiento",
    "Web.mobile.active.Watchlist.Removed": "Eliminado de la lista de seguimiento",
    "Web.mobile.active.Watchlist.MaxReached": "Máximo alcanzado",
    "Web.mobile.active.ExchangesFilter.Filter": "Filtro",
    "Web.mobile.active.OrderEntry.Send": "Enviar orden",
    "Web.mobile.active.OrderEntry.Price": "Precio",
    "Web.mobile.active.OrderEntry.TrStop": "Stop de seguimiento",
    "Web.mobile.active.OrderEntry.SlPrice": "Precio de stop loss",
    "Web.mobile.active.OrderEntry.SlOffset": "Desplazamiento de stop loss",
    "Web.mobile.active.OrderEntry.TrSlOffset": "Desplazamiento de stop loss en seguimiento",
    "Web.mobile.active.OrderEntry.SllPrice": "Precio de stop loss límite",
    "Web.mobile.active.OrderEntry.SllOffset": "Desplazamiento de stop loss límite",
    "Web.mobile.active.OrderEntry.TpPrice": "Precio de toma de ganancia",
    "Web.mobile.active.OrderEntry.TpOffset": "Desplazamiento de toma de ganancia",
    "Web.mobile.active.Login.ExploreTheApp": "Explorar la aplicación",
    "Web.mobile.active.InstrumentInfo.Price": "Precio",
    "Web.mobile.active.InstrumentInfo.BreakEven": "Punto de equilibrio",
    "Web.mobile.active.Asset.SellExchange": "Intercambio",
    "Web.mobile.active.Asset.StartDayQtyMargin": "Cantidad SOD para MA",
    "Web.mobile.active.Asset.LiquidityRate": "Tasa de liquidez, %",
    "Web.mobile.active.Asset.TodayTradedQty": "Cantidad intradiaria",
    "Web.mobile.active.Asset.AvailableQtySell": "Cantidad disponible para la venta",
    "Web.mobile.active.FilledOrder.TradeVolume": "Volumen de negociación",
    "Web.mobile.active.FilledOrder.Date": "Fecha",
    "Web.mobile.active.HistoryOrder.Date": "Fecha",
    "Web.mobile.active.MamSummary.EmptyPlaceholder": "No tiene historial de cuenta para este período",
    "Web.mobile.active.MamSummary.Account": "Cuenta",
    "Web.mobile.active.MamSummary.OperationId": "ID de operación",
    "Web.mobile.active.MamSummary.InstrumentName": "Nombre del instrumento",
    "Web.mobile.active.MamSummary.OpenPrice": "Precio de apertura",
    "Web.mobile.active.MamSummary.ClosePrice": "Precio de cierre",
    "Web.mobile.active.MamSummary.Side": "Lado",
    "Web.mobile.active.MamSummary.Quantity": "Cantidad",
    "Web.mobile.active.MamSummary.Buy": "Comprar",
    "Web.mobile.active.MamSummary.Sell": "Vender",
    "Web.mobile.active.Position.StopLoss": "Parada de pérdidas",
    "Web.mobile.active.Position.TakeProfit": "Toma de ganancia",
    "Web.mobile.active.Position.Date": "Fecha",
    "Web.mobile.active.Order.StopLoss": "Parada de pérdidas",
    "Web.mobile.active.Order.TrStopOffset": "Trailing SL offset",
    "Web.mobile.active.Order.TrStopLoss": "SL Trailing stop",
    "Web.mobile.active.Order.TakeProfit": "Toma de ganancia",
    "Web.mobile.active.Search": "Buscar",
    "Web.mobile.active.CalendarPeriod.Today": "Hoy",
    "Web.mobile.active.CalendarPeriod.LastWeek": "Semana pasada",
    "Web.mobile.active.CalendarPeriod.Last1M": "Último mes",
    "Web.mobile.active.CalendarPeriod.Last3M": "Últimos 3 meses",
    "Web.mobile.active.CalendarPeriod.Last6M": "Últimos 6 meses",
    "Web.mobile.active.InstrumentDetails.6.SessionInfo": "Horario",
    "Web.mobile.active.OrderModify.Confirmation.Title.Modify": "Modificando orden",
    "Web.mobile.active.OrderModify.Confirmation.Title.Cancel": "Cancelando orden",
    "Web.mobile.active.OrderModify.Confirmation.Title.ExecuteAtMarket": "¿Ejecutar orden en el mercado?",
    "Web.mobile.active.OrderModify.Error.SLSmallerMin": "El Stop Loss es menor que el valor mínimo posible",
    "Web.mobile.active.OrderModify.Error.SLGreaterMax": "El Stop Loss es mayor que el valor máximo posible",
    "Web.mobile.active.OrderModify.Error.TPSmallerMin": "Take Profit es menor que el valor mínimo posible",
    "Web.mobile.active.OrderModify.Error.TPGreaterMax": "Take Profit es mayor que el valor máximo posible",
    "Web.mobile.active.PositionModify.Confirmation.Title": "Modificando posición",
    "Web.mobile.active.PositionClose.Confirmation.Lots": "lote(s)",
    "Web.mobile.active.Lists": "Listas",
    "Web.mobile.active.AccountDetails.Groups.1.AccountSummary": "Resumen de la cuenta",
    "Web.mobile.active.AccountDetails.Groups.4.TodayResult": "Resultado de hoy",
    "Web.mobile.active.AccountDetails.MarginUsedPercent": "Margen inicial requerido %",
    "Web.mobile.active.AccountDetails.OptionPremiumReq": "Requisito de prima de opciones",
    "Web.mobile.active.AccountDetails.OpenGrossPL": "Ganancia/Pérdida bruta abierta",
    "Web.mobile.active.AccountDetails.OpenPostionsNumber": "Posiciones abiertas",
    "Web.mobile.active.AccountDetails.OpenOrdersNumber": "Órdenes de trabajo",
    "Web.mobile.active.AccountDetails.TodaysNetProfit": "Beneficio neto de hoy",
    "Web.mobile.active.Chart.IndicatorsMenu.AddedIndicators": "Indicadores añadidos",
    "Web.mobile.active.Chart.Menu.ChartSyle": "Estilo de gráfico",
    "Web.mobile.active.Chart.Menu.Settings": "Ajustes",
    "Web.mobile.active.Chart.NoDataAvailable": "No hay datos disponibles",
    "Web.mobile.active.TrailingStopLoss": "Tr.",
    "Web.mobile.active.signIn.error.emptyLogin": "el campo de inicio de sesión está vacío",
    "Web.mobile.active.signIn.error.emptyPassword": "El campo de contraseña está vacío",
}