// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DirectMessageBaseClass } from './DirectMessageBase';
import { CrossRateCalculationType } from '../CrossRate/CrossRateCalculationType';
import { Message } from './Message';
import { type CrossRateData } from '../CrossRate/CrossRateData';

export class DirectCrossRateMessage extends DirectMessageBaseClass {
    public CrossRateData: CrossRateData[];
    public CrossRateType = CrossRateCalculationType.BID_ASK_2;

    constructor (crossRateDataArray: CrossRateData[]) {
        super(Message.CODE_CROSSRATE_MESSAGE);

        this.CrossRateData = crossRateDataArray;
    }
}
