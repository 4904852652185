// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts";
import { CustomEvent } from "../../Utils/CustomEvents.ts";
import { TerceraTabContainerTemplate } from "../../templates.js";
import { ContainerControl } from "./ContainerControl.js";

export let TerceraTabContainer = ContainerControl.extend({

    template: TerceraTabContainerTemplate,
    data: function ()
    {
        return {
            tabWidth: 20,
            items: [],
            selectedItem: null,
            selectedCBItem: null,
            cbItems: [],
            tabHeaderKeys: null,
        };
    },
    onTabChange: null,
    onCBItemClickEvent: null
});

TerceraTabContainer.prototype.getType = function () { return 'TerceraTabContainer' };

TerceraTabContainer.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.apply(this);
    
    this.on('tabStripItem', this.tabStripItemChange);
    this.on('onComboItemClicked', this.onComboItemClicked);

    this.onTabChange = new CustomEvent();
    this.onCBItemClickEvent = new CustomEvent();
};

TerceraTabContainer.prototype.oncomplete = function ()
{
    ContainerControl.prototype.oncomplete.apply(this);

    this.populateTabStrip()

    let items = this.get('items')

    if (items)
    {
        items[0].active = true
        this.set({ 
            items: items,
            selectedItem: items[0] 
        });
    }
        
};

TerceraTabContainer.prototype.populateTabStrip = function ()
{
    let tabStrip = this.Controls.tabStrip,
        tabs = this.get('tabHeaderKeys'),
        items = []

    if (!tabs)
        return

    for (var i = 0; i < tabs.length; i++)
    {
        var newItem = new TerceraTabContainerItem();
        newItem.text = Resources.getResource(tabs[i]);
        newItem.data = tabs[i];
        items.push(newItem);
    }

    this.set('items', items);
}

TerceraTabContainer.prototype.tabStripItemChange = function (event, index)
{
    let items = this.get('items');
    let selectedItem = this.get('selectedItem');
    
    let newSelectedItem = items[index];
    if (newSelectedItem === selectedItem)
        return;

    if (selectedItem) 
        selectedItem.active = false;
    
    newSelectedItem.active = true;

    this.set({ 
        items: items, 
        selectedItem: newSelectedItem 
    });
    
    this.onTabChange.Raise(newSelectedItem.data);
};

// TODO: Need to check order of parameters
TerceraTabContainer.prototype.onComboItemClicked = function(context, dataType, selectedItem)
{
    this.set('selectedCBItem', selectedItem)
    this.onCBItemClickEvent.Raise(selectedItem)
}

export let TerceraTabContainerItem = function ()
{
    this.text = "";
    this.data = "";
    this.active = false;
};