/// <summary>
/// Настройки спреда, для конкретного инструмента

import { MathUtils } from '../../Utils/MathUtils';
import { ErrorInformationStorage } from '../ErrorInformationStorage';
import { type Instrument } from './Instrument';
import { SpreadMeasureMode, SpreadPlan } from './SpreadPlan';

/// </summary>
export class SpreadItem {
    public TickSize = 0;
    public SpreadMode: any;
    public BidShift: any;
    public AskShift: any;
    public Spread: any;
    public SpreadCoef: any;
    public SpreadMeasure: any;
    public instrumentName: any;
    public SpreadInstrument: any = null;

    constructor (instrument: Instrument | null) {
        if (instrument !== null) {
            this.TickSize = instrument.PointSize;
            this.SpreadInstrument = instrument;
        }
    }

    public CalcBid (bid, ask, instrument: Instrument | null, round = true): number {
        try {
            // #32479  (Watchlist) некорректные даные в watchlist при использовании спред
            if (isNaN(bid) && isNaN(ask)) {
                return Number.NaN;
            }

            if (isNaN(bid) && (this.SpreadMode == SpreadPlan.DYNAMIC_SPREADMODE || this.SpreadMode == SpreadPlan.BID_SPREADMODE || this.SpreadMode == SpreadPlan.NOT_FIXED_SPREADMODE)) // #45307
            {
                return Number.NaN;
            }

            // if (bid == 0 && ask == 0)
            //     return 0;

            let bidSpread;
            const instr = instrument ?? null;
            const useVariableTickSize = this.SpreadMeasure === SpreadMeasureMode.SPREAD_IN_PIPS && instr != null && instr.VariableTickList.length > 1;

            switch (this.SpreadMode) {
            case SpreadPlan.ASK_SPREADMODE:
                if (useVariableTickSize) {
                    bidSpread = !isNaN(ask) ? instr.CalculatePrice(ask, (this.AskShift - this.Spread)) : bid;
                } else {
                    bidSpread = !isNaN(ask) ? (ask + this.AskShift * this.SpreadCoef) - this.Spread * this.SpreadCoef : bid;
                }
                break;

            case SpreadPlan.BID_ASK_SPREADMODE:
                if (isNaN(ask)) {
                    ask = bid;
                }
                if (isNaN(bid)) {
                    bid = ask;
                }
                if (useVariableTickSize) {
                    const askWithAskShift = instr.CalculatePrice(ask, this.AskShift);
                    const bidWithBidShift = instr.CalculatePrice(bid, this.BidShift);
                    bidSpread = instr.CalculatePrice(((askWithAskShift + bidWithBidShift) / 2), (-this.Spread / 2)); // Spread здесь всегда четный
                } else {
                    bidSpread = ((ask + this.AskShift * this.SpreadCoef + bid + this.BidShift * this.SpreadCoef) - this.Spread * this.SpreadCoef) / 2;
                }
                break;

            case SpreadPlan.LIMEN_SPREADMODE:
                if (isNaN(ask)) {
                    ask = bid;
                }
                if (isNaN(bid)) {
                    bid = ask;
                }

                let newAsk, newBid, delta, resBid;

                if (useVariableTickSize) {
                    newAsk = instr.CalculatePrice(ask, this.AskShift);
                    newBid = instr.CalculatePrice(bid, this.BidShift);
                    const delta_ticks = instr.CalculateTicks(newBid, newAsk - newBid); // считаем в тиках

                    if (delta_ticks < this.Spread) {
                        resBid = instr.CalculatePrice(newBid, ((delta_ticks - this.Spread) / 2));
                    } else {
                        resBid = newBid;
                    }
                } else {
                    newAsk = ask + this.AskShift * this.SpreadCoef;
                    newBid = bid + this.BidShift * this.SpreadCoef;
                    delta = (newAsk - newBid) - this.Spread * this.SpreadCoef;

                    resBid = newBid;
                    // if (delta < 0) neative
                    resBid = newBid + delta / 2;
                }
                bidSpread = resBid;
                break;

            case SpreadPlan.DYNAMIC_SPREADMODE: // вымышленный тип под инструмент в пунктах
                if (useVariableTickSize) {
                    const price = instr.CalculatePrice(bid, (bid * this.BidShift));
                    bidSpread = !isNaN(bid) ? MathUtils.RoundToIncrement(price, instr.FindVariableTick(price).PointSize) : 0;
                } else {
                    const price = bid - bid * this.BidShift * this.SpreadCoef;
                    bidSpread = !isNaN(bid) ? MathUtils.RoundToIncrement(price, instr != null ? instr.FindVariableTick(price).PointSize : this.TickSize) : 0;
                }
                break;

            case SpreadPlan.BID_SPREADMODE:
            case SpreadPlan.NOT_FIXED_SPREADMODE:
            default:
                if (useVariableTickSize) {
                    bidSpread = !isNaN(bid) ? instr.CalculatePrice(bid, this.BidShift) : bid;
                } else {
                    bidSpread = !isNaN(bid) ? bid + this.BidShift * this.SpreadCoef : bid;
                }
                break;
            }

            // negative
            // if (bidSpread < 0)
            //     return Number.NaN;
            // else
            return instr != null && round ? instr.RoundPriceToNearestPointSize(bidSpread) : bidSpread;
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
        }
        return bid;
    }

    public CalcAsk (bid, ask, instrument: Instrument | null, round = true): number {
        try {
            // #32479  (Watchlist) некорректные даные в watchlist при использовании спред
            if (isNaN(bid) && isNaN(ask)) {
                return Number.NaN;
            }

            if (isNaN(ask) && (this.SpreadMode == SpreadPlan.DYNAMIC_SPREADMODE || this.SpreadMode == SpreadPlan.ASK_SPREADMODE || this.SpreadMode == SpreadPlan.NOT_FIXED_SPREADMODE)) // #45307
            {
                return Number.NaN;
            }

            // if (bid == 0 && ask == 0)
            // return 0;

            let askSpread;
            const instr = instrument != null ? instrument : null;
            const useVariableTickSize = this.SpreadMeasure == SpreadMeasureMode.SPREAD_IN_PIPS && instr != null && instr.VariableTickList.length > 1;

            switch (this.SpreadMode) {
            case SpreadPlan.BID_SPREADMODE:
                if (useVariableTickSize) {
                    askSpread = !isNaN(bid) ? instr.CalculatePrice(bid, (this.BidShift + this.Spread)) : ask;
                } else {
                    askSpread = !isNaN(bid) ? (bid + this.BidShift * this.SpreadCoef) + this.Spread * this.SpreadCoef : ask;
                }
                break;

            case SpreadPlan.BID_ASK_SPREADMODE:
                if (isNaN(bid)) {
                    bid = ask;
                }
                if (isNaN(ask)) {
                    ask = bid;
                }

                if (useVariableTickSize) {
                    const askWithAskShift = instr.CalculatePrice(ask, this.AskShift);
                    const bidWithBidShift = instr.CalculatePrice(bid, this.BidShift);
                    askSpread = (instr.CalculatePrice(((askWithAskShift + bidWithBidShift) / 2), (this.Spread / 2))); // Spread здесь всегда четный
                } else {
                    askSpread = ((ask + this.AskShift * this.SpreadCoef + bid + this.BidShift * this.SpreadCoef) + this.Spread * this.SpreadCoef) / 2;
                }
                break;

            case SpreadPlan.LIMEN_SPREADMODE:

                if (isNaN(bid)) {
                    bid = ask;
                }
                if (isNaN(ask)) {
                    ask = bid;
                }

                let newAsk, newBid, delta, resAsk;
                if (useVariableTickSize) {
                    newAsk = instr.CalculatePrice(ask, this.AskShift);
                    newBid = instr.CalculatePrice(bid, this.BidShift);
                    const delta_ticks = instr.CalculateTicks(newBid, newAsk - newBid); // считаем в тиках

                    if (delta_ticks < this.Spread) {
                        resAsk = instr.CalculatePrice(newAsk, (-(delta_ticks - this.Spread) / 2));
                    } else {
                        resAsk = newAsk;
                    }
                } else {
                    newAsk = ask + this.AskShift * this.SpreadCoef;
                    newBid = bid + this.BidShift * this.SpreadCoef;
                    delta = (newAsk - newBid) - this.Spread * this.SpreadCoef;

                    resAsk = newAsk;
                    // if (delta < 0) negative
                    resAsk = newAsk - delta / 2;
                }
                askSpread = resAsk;
                break;

            case SpreadPlan.DYNAMIC_SPREADMODE: // вымышленный тип под инструмент в пунктах
                if (useVariableTickSize) {
                    const price = instr.CalculatePrice(ask, (ask * this.AskShift));
                    askSpread = !isNaN(ask) ? MathUtils.RoundToIncrement(price, instr.FindVariableTick(price).PointSize) : 0;
                } else {
                    const price = ask + ask * this.AskShift * this.SpreadCoef;
                    askSpread = !isNaN(ask) ? MathUtils.RoundToIncrement(price, instr != null ? instr.FindVariableTick(price).PointSize : this.TickSize) : 0;
                }
                break;

            case SpreadPlan.ASK_SPREADMODE:
            case SpreadPlan.NOT_FIXED_SPREADMODE:
            default:
                if (useVariableTickSize) {
                    askSpread = !isNaN(ask) ? instr.CalculatePrice(ask, this.AskShift) : ask;
                } else {
                    askSpread = !isNaN(ask) ? (ask + this.AskShift * this.SpreadCoef) : ask;
                }
                break;
            }

            // negative
            // if (askSpread < 0)
            //     return Number.NaN;
            // else
            return instr != null && round ? instr.RoundPriceToNearestPointSize(askSpread) : askSpread;
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
        }
        return ask;
    }
}
