// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { alertMessageScreenHandler } from "../../Utils/AppHandlers.js";
import { KeyEventProcessor } from "../../Commons/KeyEventProcessor.ts";
import { Resources } from "../../Commons/properties/Resources.ts";
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";
import { TerceraMessageBox } from "./TerceraMessageBox.js";
import { ThemeManager } from "../misc/ThemeManager.ts";

export let AlertMessageBox = TerceraMessageBox.extend({
    data: function ()
    {
        return {
            hideCancel: true,
            okText: Resources.getResource('general.messageBox.ok')
        };
    }
});

AlertMessageBox.prototype.getType = function () { return 'AlertMessageBox' };

AlertMessageBox.prototype.oninit = function ()
{
    TerceraMessageBox.prototype.oninit.apply(this);

    this.observe('messageBoxType', function (newValue)
    {
        let imgUrl = null;
        switch (newValue)
        {
            case AlertMessageBox.msgType.HIGH:
                imgUrl = 'i_messagebox/alert_high_message.png';
                break;
            case AlertMessageBox.msgType.MEDIUM:
                imgUrl = 'i_messagebox/alert_medium_message.png';
                break;
            case AlertMessageBox.msgType.LOW:
                imgUrl = 'i_messagebox/alert_low_message.png';
                break;
        }

        this.set({ pictureUrl: ThemeManager.getImageFullUrlNew(imgUrl) });
    });
};

AlertMessageBox.Show = function (headerText, bodyText, type)
{
    let alertMessageBox = new AlertMessageBox();
    alertMessageBox.set({
        header: headerText,
        messageBoxText: bodyText,
        messageBoxType: type
    });

    MainWindowManager.MainWindow.addControl(alertMessageBox);
    alertMessageBox.setFocus();

    let keyProc = KeyEventProcessor;
    keyProc.OnKeyDown.Subscribe(alertMessageBox.onGlobalKeyDown, alertMessageBox);
}

AlertMessageBox.msgType = {
    HIGH: 1,
    MEDIUM: 5,
    LOW: 10
};

let handler = alertMessageScreenHandler;
alertMessageScreenHandler.Show = AlertMessageBox.Show;