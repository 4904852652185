// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
export class CrossRateData {
    public Symbol: any = null;
    public Exp1: string | null = null;
    public Exp2: string | null = null;
    public Price = 0;
    public IsReverse = false;

    public toString (): string {
        return this.Exp1 + '/' + this.Exp2 + '=' + this.Price;
    }
}
