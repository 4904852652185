// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../Commons/properties/Resources';
import { QuickTableColumnType } from '../elements/QuickTable/QuickTableMisc';
import { BaseItem, ColumnData } from './BaseItem';
import { ColumnParams } from './ColumnParams';
import { ThemeManager } from '../misc/ThemeManager';
import { type SessionSettingsType } from '../../Commons/SessionSettings';

export class PortfolioReturnsItem extends BaseItem {
    public monthReturn: any;

    constructor (monthReturn, sessionSettings: SessionSettingsType) {
        super(sessionSettings);

        this.monthReturn = monthReturn;

        if (!monthReturn) { return; }

        const theme = ThemeManager.CurrentTheme;

        if (monthReturn.percent != 0) {
            this.CellForeColorMap[1] = monthReturn.percent >= 0 ? theme.PortfolioReturnsTableGreen : theme.PortfolioReturnsTableRed;
        }

        this.CellForeColorMap[2] = theme.PortfolioReturnsTableGold;
    }

    public override getColumnData (column: number): ColumnData {
        const value = this.getColumnValue(column);
        const mR = this.monthReturn;
        if (!mR) { return null; }

        let formattedValue = '';

        switch (column) {
        case 0:
            formattedValue = mR.Date;
            break;

        case 1:
            formattedValue = mR.Percent;
            break;

        case 2:
            formattedValue = mR.AbsoluteAmount;
            break;

        default:
            formattedValue =
                value === null ||
                    value === '' ||
                    value === undefined ||
                    isNaN(value)
                    ? Resources.getResource('general.N_A')
                    : value.toString();
        }

        return new ColumnData(value, formattedValue);
    }

    public override getColumnValue (column: number): any {
        const mR = this.monthReturn;
        if (!mR) { return null; }

        switch (column) {
        case 0:
            return mR.date;

        case 1:
            return mR.percent;

        case 2:
            return mR.absoluteAmount;

        default:
            return 0;
        }
    }

    public override ColumnCount (): number {
        return PortfolioReturnsItem.columnsParams.length;
    }

    public override GetColumnParams (column: number): ColumnParams {
        const result = PortfolioReturnsItem.columnsParams[column];

        return result;
    }

    public static readonly columnsParams = [
        new ColumnParams('portfolio.returns.tableHeader.date', 75, QuickTableColumnType.COL_DATE_SORT, false, true, false),
        new ColumnParams('portfolio.returns.tableHeader.return', 100, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false),
        new ColumnParams('portfolio.returns.tableHeader.balance', 100, QuickTableColumnType.COL_MORELESS_NUMERIC, false, true, false)
    ];

    public static readonly ListMenuIndex = [
        'portfolio.returns.tableHeader.date',
        'portfolio.returns.tableHeader.return',
        'portfolio.returns.tableHeader.balance'
    ];
}
