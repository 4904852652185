// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from '../../Utils/CustomEvents';
import { Resources } from '../../Commons/properties/Resources';
import { LinkedSystem } from '../misc/LinkedSystem';
import { MarketConsensusPanelTopTemplate } from '../../templates';
import { MarketConsensusPanelItem } from '../cache/MarketConsensusPanelItem';
import { QuickTableRactive } from '../elements/QuickTable/QuickTableRactive';
import { TerceraLinkControlConstants } from '../UtilsClasses/TerceraLinkControlConstants';
import { TerceraMenu } from '../elements/TerceraMenu';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { ApplicationPanelNew } from './ApplicationPanelNew';
import { OperationType } from '../../Utils/Trading/OperationType';
import { AlgorithmSubscribeType, AlgorithmType } from '../../Commons/cache/Algorithm/Algorithm';
import { KeyEventProcessor } from '../../Commons/KeyEventProcessor';
import { DynProperty } from '../../Commons/DynProperty';
import { IsAllowed } from '../../Commons/IsAllowed';
import { DataCache } from '../../Commons/DataCache';
import { SessionSettings } from '../../Commons/SessionSettings';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { PanelItemsFactory } from './PanelItemsFactory';
import { PanelSettingsScreen } from '../screen/PanelSettingsScreen';

export const MarketConsensusPanel = ApplicationPanelNew.extend(
    {
        topPanelHeight: 25,
        width: 680,
        height: 300,
        data: function () {
            return {
                canLinkByAccount: false,
                isAccountLinkShow: false,
                isSymbolLinkShow: true,
                noDataLong: true,
                noDataShort: true,
                noDataText: '',

                visibleFilter: false,
                arrayFilterMarket: [],
                selectedFilterMarketItem: null,

                leftFilterOffset: 0
            };
        },
        partials: {
            bodyPartial: MarketConsensusPanelTopTemplate
        },
        headerLocaleKey: 'panel.AlgorithmMarketConsensusPanel',
        NeedCalculateRowCount: false,
        OnInstrumentChange: null,

        LongMarketConsensus: null,
        ShortMarketConsensus: null,

        quickTableRactiveLong: null,
        quickTableRactiveShort: null,
        moreThanOneTTKey: 'panel.AlgorithmMarketConsensusPanel.menu.MoreOneSymbolInfoDisabled.tt',
        noOneTTKey: 'panel.AlgorithmMarketConsensusPanel.menu.NoSymbolInfoDisabled.tt',

        TopLongColors: 'rgb(0, 163, 44)',
        TopShortColors: 'rgb(255, 69, 58)'
    });

MarketConsensusPanel.prototype.getType = function () {
    return PanelNames.MarketConsensusPanel;
};

MarketConsensusPanel.prototype.jbInit = function () {
    const panelInfo = PanelItemsFactory.GetPanelItem(this.getType());
    if (panelInfo) {
        const items = new panelInfo();
        this.quickTableRactiveLong = this.createTable(items);
        this.quickTableRactiveShort = this.createTable(items);
    }

    const qtLong = this.quickTableRactiveLong.quickTable;
    const qtShort = this.quickTableRactiveShort.quickTable;

    qtLong.UpdateSortedColumns();
    qtShort.UpdateSortedColumns();

    qtLong.OnColumnResize.Subscribe(
        this.syncColumnsWidth.bind(this, qtLong, qtShort), this);

    qtShort.OnColumnResize.Subscribe(
        this.syncColumnsWidth.bind(this, qtShort, qtLong), this);

    this.OnResize.Subscribe(this.layoutTable, this);

    qtLong.SetScrollHidden(true);

    qtLong.scroll.OnValueChange.Subscribe(this.SynchronizeScroll, this);
    qtShort.scroll.OnValueChange.Subscribe(this.SynchronizeScroll, this);

    qtShort.columns[0].headerKey = 'panel.AlgorithmMarketConsensusPanel.TopShort';

    qtLong.beforeTableContextMenuShowing = this.preparePopup.bind(this);
    qtShort.beforeTableContextMenuShowing = this.preparePopup.bind(this);

    qtLong.ColorHeaderText = this.TopLongColors;
    qtShort.ColorHeaderText = this.TopShortColors;

    this.localize();
    this.layoutTable();
};

MarketConsensusPanel.prototype.oninit = function () {
    ApplicationPanelNew.prototype.oninit.apply(this);

    this.OnInstrumentChange = new CustomEvent();

    this.on('clickLeftArrowFilter', this.clickLeftArrowFilter);
    this.on('clickRightArrowFilter', this.clickRightArrowFilter);
};

MarketConsensusPanel.prototype.oncomplete = function () {
    ApplicationPanelNew.prototype.oncomplete.apply(this);

    this.quickTableRactiveLong.quickTable.OnSelectionChanged.Subscribe(this.selectionLongChange, this);
    this.quickTableRactiveShort.quickTable.OnSelectionChanged.Subscribe(this.selectionShortChange, this);

    this.quickTableRactiveLong.quickTable.onTableMouseDown.Subscribe(this.tableMouseDown, this);
    this.quickTableRactiveShort.quickTable.onTableMouseDown.Subscribe(this.tableMouseDown, this);

    this.PopulateFiltersConcensus();
    this.populateTableContextMenu();
    this.localize();
    this.layoutTable();
};

MarketConsensusPanel.prototype.syncColumnsWidth = function (srcTable, outTable) {
    let changeOccured = false;
    const outColumns = outTable.columns;
    const srcColumns = srcTable.columns;
    const len = srcColumns.length;
    for (let i = 0; i < len; i++) {
        const srcColumn = srcColumns[i];
        const outColumn = outColumns[i];
        if (srcColumn.width !== outColumn.width) {
            outColumn.width = srcColumn.width;
            changeOccured = true;
        }
    }

    if (changeOccured) {
        outTable.needRedrawBackground = true;
        outTable.needRedraw = true;
        outTable.OnColumnResize.Raise();
    }
};

MarketConsensusPanel.prototype.SynchronizeScroll = function (index) {
    const max = Math.max(this.quickTableRactiveLong.quickTable.scroll.scrollElementsCount, this.quickTableRactiveShort.quickTable.scroll.scrollElementsCount);
    this.quickTableRactiveLong.quickTable.scroll.scrollElementsCount = this.quickTableRactiveShort.quickTable.scroll.scrollElementsCount = max;

    this.quickTableRactiveLong.quickTable.scroll.moveScrollToElement(index);
    this.quickTableRactiveShort.quickTable.scroll.moveScrollToElement(index);
};

MarketConsensusPanel.prototype.createTable = function (items) {
    const myQuickTableRactive = new QuickTableRactive();
    this.addControl(myQuickTableRactive, true);

    const qt = myQuickTableRactive.quickTable;
    qt.allowGroupBy = false;
    qt.lockManualSorting = true;
    qt.InitializeDirect(items);
    qt.UpdateSortedColumns();

    return myQuickTableRactive;
};

MarketConsensusPanel.prototype.layoutTable = function () {
    this.NormalizeArrows();
    const marginW = 5;
    const marginH = 25;
    const tableTop = this.get('visibleFilter') ? 30 : 5;

    const tableW = (this.get('width') - marginW * 2) / 2;
    let tableH = this.ContentContainerHeight() - tableTop - marginW;

    const noDataWidth = this.get('width') - 10;
    const noDataHeight = this.ContentContainerHeight() - tableTop - marginW - marginH;
    this.set({
        noDataWidth,
        noDataHeight
    });

    if (tableH < 40) { tableH = 40; }

    if (this.quickTableRactiveLong) { this.quickTableRactiveLong.setBounds(marginW, tableTop, tableW, tableH); }
    if (this.quickTableRactiveShort) { this.quickTableRactiveShort.setBounds(marginW + tableW, tableTop, tableW, tableH); }
};

MarketConsensusPanel.prototype.NormalizeArrows = function () {
    if (!this.getNodes()) { return; }

    let visibleRightArrowFilter = true;

    const avalibleSize = this.marketFilter.offsetWidth;
    const fullSize = this.itemsMarketFilter.offsetWidth;
    const leftFilterOffset = this.itemsMarketFilter.offsetLeft;

    if (avalibleSize + Math.abs(leftFilterOffset) >= fullSize) { visibleRightArrowFilter = false; }

    const visibleLeftArrowFilter = this.itemsMarketFilter.offsetLeft < 0;
    this.set('visibleRightArrowFilter', visibleRightArrowFilter);
    this.set('visibleLeftArrowFilter', visibleLeftArrowFilter);
};

MarketConsensusPanel.prototype.clickLeftArrowFilter = function () {
    if (!this.getNodes()) { return; }

    let leftFilterOffset = this.itemsMarketFilter.offsetLeft;
    leftFilterOffset += MarketConsensusPanel.ARROW_STEP;
    if (leftFilterOffset > 0) { leftFilterOffset = 0; }
    this.set('leftFilterOffset', leftFilterOffset);

    this.NormalizeArrows();
};

MarketConsensusPanel.prototype.clickRightArrowFilter = function () {
    if (!this.getNodes()) { return; }

    const avalibleSize = this.marketFilter.offsetWidth;
    const fullSize = this.itemsMarketFilter.offsetWidth;
    let leftFilterOffset = this.itemsMarketFilter.offsetLeft;
    leftFilterOffset += -MarketConsensusPanel.ARROW_STEP;

    if (avalibleSize + Math.abs(leftFilterOffset) >= fullSize) { leftFilterOffset = avalibleSize - fullSize; }
    this.set('leftFilterOffset', leftFilterOffset);

    this.NormalizeArrows();
};
MarketConsensusPanel.ARROW_STEP = 45;

MarketConsensusPanel.prototype.getNodes = function () {
    if (this.marketFilter && this.itemsMarketFilter) { return true; }

    const el = this.getClientPanel();
    this.marketFilter = el.getElementsByClassName('marketFilter')[0];
    this.itemsMarketFilter = el.getElementsByClassName('itemsMarketFilter')[0];
};

MarketConsensusPanel.prototype.UpdateTable = function () {
    if (!this.quickTableRactiveLong || !this.quickTableRactiveShort) { return; }

    this.PopulateTable(this.quickTableRactiveLong.quickTable, true);
    this.PopulateTable(this.quickTableRactiveShort.quickTable, false);
};

MarketConsensusPanel.prototype.PopulateTable = function (QuickTable_list, isLong) {
    const items = isLong ? this.LongMarketConsensus : this.ShortMarketConsensus;
    const color = isLong ? this.TopLongColors : this.TopShortColors;
    const len = items.length;
    let qItem = null;
    const arr = [];
    const sess = SessionSettings;

    const acc = DataCache.MainAccountNew;
    for (let i = 0; i < len; i++) {
        const item = items[i];
        if (IsAllowed.CheckVisibilityForAccountInstrType(acc, item.InstrumentNameTypeId)) {
            const pr = new Promise((resolve, reject) => {
                DataCache.getInstrumentByInstrumentTradableID_NFL(item.TradableId, item.RouteId).then((instr) => {
                    resolve({ ins: instr, item });
                });
            });
            arr.push(pr);
        }
    }

    if (arr.length === 0) { return; }

    Promise.all(arr).then((results) => {
        const UpdatedMap = {};
        for (let i = 0; i < results.length; i++) {
            const r = results[i];
            const instr = r.ins;
            const item = r.item;
            if (!instr) { continue; }

            const itemId = this.MakeItemId(item);
            const row = QuickTable_list.rows[itemId];
            if (!row) {
                qItem = new MarketConsensusPanelItem(item, isLong, instr, color, sess);
                qItem.ItemId = itemId;
                QuickTable_list.AddItem(qItem);
            } else { row.item.UpdateItemData(item, color); }

            UpdatedMap[itemId] = true;
        }

        if (QuickTable_list.rowsArray.length) { this.set('noData' + (isLong ? 'Long' : 'Short'), false); }

        for (const rowId in QuickTable_list.rows) {
            if (!UpdatedMap[rowId]) { QuickTable_list.RemoveItem(rowId); }
        }

        QuickTable_list.ColorHeaderText = color;
        QuickTable_list.needRedraw = true;
        QuickTable_list.needRedrawBackground = true;
        QuickTable_list.rowsArray.sort(this.Compare);
    });
};

MarketConsensusPanel.prototype.MakeItemId = function (item) {
    if (!item) { return ''; }
    return item.InstrumentName + (item.TradableId || '') + (item.RouteId || '');
};

MarketConsensusPanel.prototype.TickAsync = function () {
    const visible = this.get('visible');
    if (!visible) { return; }

    if (this.quickTableRactiveLong?.quickTable.needRedraw) {
        const qt = this.quickTableRactiveLong.quickTable;
        qt.updateRowsCellsValues();
        qt.Draw();
        qt.needRedraw = false;
    }

    if (this.quickTableRactiveShort?.quickTable.needRedraw) {
        const qt = this.quickTableRactiveShort.quickTable;
        qt.updateRowsCellsValues();
        qt.Draw();
        qt.needRedraw = false;
    }
};

MarketConsensusPanel.prototype.createFilterPanel = function (items) {
    const visibleFilter = items.length > 1;
    items[0].active = true;

    this.set({
        visibleFilter,
        arrayFilterMarket: items,
        selectedFilterMarketItem: items[0]
    });

    this.layoutTable();
    DataCache.AlgorithmCache.SubscriptionHandler(AlgorithmSubscribeType.Subscribe, items[0].Id, this);
};

MarketConsensusPanel.prototype.clickFilterMarketItemChange = function (index) {
    const items = this.get('arrayFilterMarket');
    const selectedItem = this.get('selectedFilterMarketItem');

    const newSelectedItem = items[index];
    if (newSelectedItem === selectedItem) { return; }

    if (selectedItem) { selectedItem.active = false; }

    newSelectedItem.active = true;

    this.set({
        noDataLong: true,
        noDataShort: true,
        arrayFilterMarket: items,
        selectedFilterMarketItem: newSelectedItem
    });

    DataCache.AlgorithmCache.SubscriptionHandler(AlgorithmSubscribeType.Subscribe, newSelectedItem.Id, this);
    DataCache.AlgorithmCache.SubscriptionHandler(AlgorithmSubscribeType.Unsubscribe, selectedItem.Id, this);

    if (!this.quickTableRactiveLong || !this.quickTableRactiveShort) { return; }

    this.quickTableRactiveLong.quickTable.ClearAll();
    this.quickTableRactiveShort.quickTable.ClearAll();
};

MarketConsensusPanel.prototype.PopulateFiltersConcensus = function () {
    const algorithms = DataCache.AlgorithmCache.GetAlgorithmsByAlgorithmType(AlgorithmType.MarketConsensus);
    const arr = [];
    for (let i = 0; i < algorithms.length; i++) { arr.push({ Name: algorithms[i].AlgorithmName, Id: algorithms[i].AlgorithmId }); }

    arr.sort((a, b) => { return a.Name.localeCompare(b.Name); });
    if (arr.length > 0) { this.createFilterPanel(arr); }
};

MarketConsensusPanel.prototype.ProcessAlgorithm = function (msg) {
    this.LongMarketConsensus = msg.TopLongList;
    this.ShortMarketConsensus = msg.TopShortList;

    this.UpdateTable();
};

MarketConsensusPanel.prototype.Compare = function (x, y) {
    if (x.item.Value > y.item.Value) { return -1; } else { return 1; }
};

MarketConsensusPanel.prototype.populateTableContextMenu = function () {
    const longItems = this.MakeItems(true);
    const shortItems = this.MakeItems(false);

    this.menuTagDictLong = TerceraMenu.createTagDictionary(longItems);
    this.menuTagDictShort = TerceraMenu.createTagDictionary(shortItems);

    if (this.quickTableRactiveLong?.quickTable) { this.quickTableRactiveLong.quickTable.setTableContextMenuItems(longItems); }

    if (this.quickTableRactiveShort?.quickTable) { this.quickTableRactiveShort.quickTable.setTableContextMenuItems(shortItems); }
};

MarketConsensusPanel.prototype.MakeItems = function (isLongTable) {
    const items = [];

    items.push(
        {
            locKey: 'panel.neworder',
            enabled: false,
            event: this.openPanel.bind(this, PanelNames.AdvancedOrderEntry),
            tag: MarketConsensusPanel.OPEN_OE,
            isLongTable
        }
    );

    items.push(
        {
            locKey: 'panel.terceraChart',
            enabled: false,
            event: this.openPanel.bind(this, PanelNames.ChartPanel),
            tag: MarketConsensusPanel.OPEN_CHART
        }
    );

    this.AddSymbolInfoContextMenuItemIfNeed(items, false);

    items.push(
        { separator: true },
        {
            text: Resources.getResource('panel.AlgorithmMarketConsensusPanel.Settings.ContextMenu'),
            event: this.ShowProperties.bind(this)
        }
    );

    return items;
};

MarketConsensusPanel.prototype.ShowProperties = function () {
    PanelSettingsScreen.EditProperties(this, null, Resources.getResource('panel.AlgorithmMarketConsensusPanel.Settings.Screen'));
};

MarketConsensusPanel.prototype.openPanel = function (panelName, ev) {
    const ins = this.getSelectedInstrumentID();
    const side = ev.isLongTable ? OperationType.Buy : OperationType.Sell;
    MainWindowManager.Factory.addPanel(panelName, null, function (panel) {
        panel.symbolLink_In(ins);
        if (panel.getType() === PanelNames.AdvancedOrderEntry) { panel.set('side', side); }
    });
};

MarketConsensusPanel.prototype.getSelectedInstrumentID = function () {
    const ins = this.getInstrument();
    let result = '';

    if (ins) { result = ins.GetInteriorID(); }

    return result;
};

MarketConsensusPanel.prototype.getInstrument = function () {
    const qtLong = this.quickTableRactiveLong ? this.quickTableRactiveLong.quickTable : null;
    const qtShort = this.quickTableRactiveShort ? this.quickTableRactiveShort.quickTable : null;

    if (!qtLong || !qtShort) return null;

    const selectedLongRowIds = this.selectedTableRowIds(qtLong);
    const selectedShortRowIds = this.selectedTableRowIds(qtShort);

    const row = selectedLongRowIds || selectedShortRowIds;

    return row?.item ? row.item.GetCurrentInstrument() : null;
};

MarketConsensusPanel.prototype.selectedTableRowIds = function (qt) {
    if (!qt) return null;

    const selectedRowIds = qt.selectedRowIds;
    const row = selectedRowIds?.[0] ? qt.rows[selectedRowIds[0]] : null;

    return row;
};

MarketConsensusPanel.prototype.selectionLongChange = function () {
    const selectedTable = this.quickTableRactiveLong.quickTable;
    this.selectionChange(selectedTable);
};

MarketConsensusPanel.prototype.selectionShortChange = function () {
    const selectedTable = this.quickTableRactiveShort.quickTable;
    this.selectionChange(selectedTable);
};

MarketConsensusPanel.prototype.tableMouseDown = function (hittestInfo) {
    const selectedTable = this.quickTableRactiveShort.quickTable;
};

MarketConsensusPanel.prototype.selectionChange = function (selectedTable) {
    const selectedRowsId = selectedTable.selectedRowIds;

    if (!selectedRowsId) { return; }

    const selectedTableLong = this.quickTableRactiveLong.quickTable;
    const selectedTableShort = this.quickTableRactiveShort.quickTable;

    if (selectedTableLong === selectedTable) {
        if (selectedTableShort.selectedRowIds.length) {
            selectedTableShort.selectedRowIds.length = 0;
            selectedTableShort.needRedraw = true;
            selectedTableShort.needRedrawBackground = true;
        }
    } else {
        if (selectedTableLong.selectedRowIds.length) {
            selectedTableLong.selectedRowIds.length = 0;
            selectedTableLong.needRedraw = true;
            selectedTableLong.needRedrawBackground = true;
        }
    }

    let ins = '';
    const ids = selectedRowsId;
    if (ids.length > 0) {
        const row = selectedTable.rows[ids[0]];
        ins = row.item.InstrumentName();
    }

    this.symbolLink_Out(this.isNewSubscriber(), ins);
};

MarketConsensusPanel.prototype.preparePopup = function () {
    this.updateTagDict(this.menuTagDictLong);
    this.updateTagDict(this.menuTagDictShort);
};

MarketConsensusPanel.prototype.updateTagDict = function (menuTagDict) {
    let menuItem = menuTagDict ? menuTagDict[MarketConsensusPanel.OPEN_OE] : null;
    this.updateMenuItem(menuItem);

    menuItem = menuTagDict ? menuTagDict[MarketConsensusPanel.OPEN_CHART] : null;
    this.updateMenuItem(menuItem);

    menuItem = menuTagDict ? menuTagDict[ApplicationPanelNew.SYMBOLINFOPANEL] : null;
    this.updateMenuItem(menuItem);
};

MarketConsensusPanel.prototype.getQuickTable = function () {
    const qtL = this.quickTableRactiveLong ? this.quickTableRactiveLong.quickTable : null;
    if (!qtL) return null;

    const qtS = this.quickTableRactiveShort ? this.quickTableRactiveShort.quickTable : null;
    if (!qtS) return null;

    const selectedRowIdsL = qtL.selectedRowIds;
    const selectedRowIdsS = qtS.selectedRowIds;

    if (selectedRowIdsS?.length) { return qtS; }

    return qtL;
};

MarketConsensusPanel.prototype.isNewSubscriber = function () {
    const color = this.get('symbolLinkValue');
    if (color === TerceraLinkControlConstants.STATE_NONE) { return false; }

    return !LinkedSystem.getSymbol(color);
};

MarketConsensusPanel.prototype.symbolLink_Out = function (newSubscriber, instrument) {
    if (!instrument) { return; }

    const color = this.get('symbolLinkValue');
    if (color !== TerceraLinkControlConstants.STATE_NONE) { LinkedSystem.setSymbol(color, instrument, newSubscriber); }

    this.OnInstrumentChange.Raise(instrument);
};

MarketConsensusPanel.prototype.localize = function () {
    ApplicationPanelNew.prototype.localize.apply(this);

    this.set('noDataText', Resources.getResource('panel.AlgorithmMarketConsensusPanel.noData'));
    if (this.quickTableRactiveLong?.quickTable) {
        this.repopulatePanelContextMenu(this.quickTableRactiveLong);
        this.quickTableRactiveLong.quickTable.localize();
    }

    if (this.quickTableRactiveShort?.quickTable) {
        this.repopulatePanelContextMenu(this.quickTableRactiveShort);
        this.quickTableRactiveShort.quickTable.localize();
    }
};

MarketConsensusPanel.prototype.repopulatePanelContextMenu = function (table) {
    const contextItems = table.quickTable.tableContextMenuItems;
    if (!contextItems || contextItems.length === 0) { return; }

    for (let i = 0; i < contextItems.length; i++) {
        const item = contextItems[i];
        if (item) {
            if (item.locKey) { item.text = Resources.getResource(item.locKey); }
        }
    }
};

MarketConsensusPanel.prototype.Properties = function () {
    const properties = ApplicationPanelNew.prototype.Properties.call(this);
    let prop = new DynProperty('TopLongColors', this.TopLongColors, DynProperty.COLOR, DynProperty.PARAM_GROUP);
    prop.sortIndex = 1;
    properties.push(prop);

    prop = new DynProperty('TopShortColors', this.TopShortColors, DynProperty.COLOR, DynProperty.PARAM_GROUP);
    prop.sortIndex = 2;
    properties.push(prop);

    return properties;
};

MarketConsensusPanel.prototype.callBack = function (properties) {
    ApplicationPanelNew.prototype.callBack.call(this, properties);
    let dp = DynProperty.getPropertyByName(properties, 'TopLongColors');
    if (dp) this.TopLongColors = dp.value;

    dp = DynProperty.getPropertyByName(properties, 'TopShortColors');
    if (dp) this.TopShortColors = dp.value;

    if (this.quickTableRactiveShort?.quickTable &&
        this.quickTableRactiveLong?.quickTable) {
        this.UpdateTable();
    }
};

MarketConsensusPanel.OPEN_OE = 'OPEN_OE';
MarketConsensusPanel.OPEN_CHART = 'OPEN_CHART';

MarketConsensusPanel.prototype.dispose = function () {
    KeyEventProcessor.OnKeyDown.UnSubscribe(this.onGlobalKeyDown, this);

    const curIt = this.get('selectedFilterMarketItem');
    if (curIt) { DataCache.AlgorithmCache.SubscriptionHandler(AlgorithmSubscribeType.Unsubscribe, curIt.Id, this); }

    this.OnResize.UnSubscribe(this.layoutTable, this);

    ApplicationPanelNew.prototype.dispose.apply(this);
};
