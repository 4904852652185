// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { InputParameterType } from './InputParameterEnum';

export class InputParameterBase {
    public VariableName: string;
    public VariableType: InputParameterType;
    public Comment: string;
    public InputNumber: number;// sort index

    constructor (variableName = 'Empty', variableType = InputParameterType.STRING, comment = '', inputNumber = 0) {
        this.VariableName = variableName;
        this.VariableType = variableType;
        this.Comment = comment;
        this.InputNumber = inputNumber;
    }
}
