// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { ExpandDoubleVector } from './DoubleMatrix';
import { ResVector } from './ResVector';

export class ConstantVector extends ResVector {
    public FVector: any;
    public FValue: any;
    public isConstantVector: boolean;

    constructor (value, rowNumber, timeShift, priceMult) {
        super(rowNumber, timeShift, priceMult, new ExpandDoubleVector());

        this.FVector = new FreezedVector(0);
        this.FValue = value;
        this.isConstantVector = true;
    }

    get Value (): any { return this.FValue; }
    set Value (value) { this.FValue = value; }

    get RowNumber (): number { return this.FRowNumber; }

    // #region IDoubleVector Members
    public get (index): any {
        return this.FValue;
    }

    public set (index, value): void {

    }
    // #endregion
}

export class FreezedVector extends ExpandDoubleVector {
    public FCount: number;

    constructor (count: number) {
        super();
        this.FCount = count || 0;
    }

    public Add (value): void {
        this.FCount++;
    }

    public Clear (): void {
        this.FCount = 0;
    }

    get Length (): number { return this.FCount; }
    set Length (value) { this.FCount = value; }

    public get (index): number {
        return 0;
    }

    public set (index, value): void {

    }
}
