// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type CorporateActionType } from '../../Commons/cache/Position';
import { ProductType } from '../Instruments/ProductType';
import { OperationType } from '../Trading/OperationType';
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectPositionMessage extends DirectMessageBaseClass {
    public OrderWasManual = false;
    public Route: number | null = null;
    public PositionId = '';
    public Account: string | null = null;
    public Symbol: any = null;
    // Под нефикс список, для прочих - бери Symbol и не парься
    public InstrumentID = 0;
    // +++ Это дочерняя позиция - id позиции к которой на привязана
    public MasterID: string | null = null;
    public OperationType = OperationType.Buy;
    public Amount = 0;
    public OpenOrderId: string | null = null;
    public OpenPrice = 0;
    public OpenTime: Date | null = null;
    public CloseOrderId: string | null = null;
    public ClosePrice = 0;
    public CloseTime: Date | null = null;
    public StopLossOrderId = '-1';
    public StopLossLimit = 0;
    public TakeProfitOrderId = '-1';
    public TakeProfitLimit = 0;
    public Swaps: number | null = null;
    public Commission: number | null = null;
    public Comment: string | null = null;
    public OrderGroupId = 0;
    public OpenCrossPrice = 0;
    public InitMargin = 0;
    public MaintMargin = 0;
    public SettlementPrice: number | null = null;
    public SettlementCrossPrice: any = null;
    public TrailingStop = false;
    public TrailingOffset = 0;
    // Управление разрешением SLTP на уровне отдельного ордера
    // заводили под интеграции, нашим сервером пока не используется
    public SLTPAllowed = true;
    public RealizedPL = 0;
    public ProductType = ProductType.General;
    // Используется для передачи  данных от строннего вендора
    // (данные считаются в в поставщике, у нас же в клиенте эти данные являются рассчетными)
    // Это поле используется для того чтобы выводить данные "as is"
    public ServerCalculationCurPriceClose: any = null;
    // Используется для передачи  данных от строннего вендора
    // (данные считаются в в поставщике, у нас же в клиенте эти данные являются рассчетными)
    // Это поле используется для того чтобы выводить данные "as is"
    public ServerCalculationExposure: any = null;
    // Используется для передачи  данных от строннего вендора
    // (данные считаются в в поставщике, у нас же в клиенте эти данные являются рассчетными)
    // Это поле используется для того чтобы выводить данные "as is"
    public ServerCalculationPositionValue: any = null;
    // Используется для передачи  данных от строннего вендора
    // (данные считаются в в поставщике, у нас же в клиенте эти данные являются рассчетными)
    // Это поле используется для того чтобы выводить данные "as is"
    public ServerCalculationNetPL: any = null;
    // Используется для передачи  данных от строннего вендора
    // (данные считаются в в поставщике, у нас же в клиенте эти данные являются рассчетными)
    // Это поле используется для того чтобы выводить данные "as is"
    public ServerCalculationGrossPL: any = null;
    public IsOnlyServerCalculationUpdate = false;
    public InstrumentTradableID: number | null = null;
    public OptionExerciseStatus: any = null;
    public CorporateActionType: CorporateActionType | null = null;
    public StartOfDayAmount: number | null = null;
    public ProductTypeModifiedAmount: number | null = null;
    public Leverage: number | null = null;
    public SuperPositionId: string;
    public AccruedInterest: number | null = null;

    constructor () {
        super(Message.CODE_OPENPOSITION);
    }
}
