// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PFixField } from './PFixField';

export class PFixDateField extends PFixField {
    constructor (fieldId, buffer?) {
        super(fieldId, buffer);
        this.Value = null;
    }

    public override ReadValue (buffer): void {
        this.Value = buffer.ReadDate();
    }

    public override Write (buffer): void {
        buffer.WriteShort(this.FieldId);
        if (!this.Value) {
            this.Value = new Date();
        }

        buffer.WriteDate(this.Value);
    }
}
