// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { Periods } from '../../../Utils/History/TFInfo';
import { ProcessorWorker } from './ProcessorWorker';
import * as vendorMessage from './Messages/MessagesImport';
import * as vendorGroup from './Groups/GroupsImport';
import { FieldsFactory } from './Factory/FieldsFactory';
import { HistoryType } from '../../../Utils/History/HistoryType';
import { Connection } from '../../../Commons/Connection';
import { Message } from '../../../Utils/DirectMessages/Message';
import { BaseInterval } from '../../../Utils/History/BaseInterval';
import { BaseIntervalInputParams } from '../../../Utils/History/BaseIntervalInputParams';
import { ChartDataType } from '../../../Chart/Utils/ChartConstants';
import { type ReloadHistoryParams } from '../../../Utils/History/ReloadHistoryParams';

export class HistoryProcessorWorker extends ProcessorWorker {
    private historyParams: ReloadHistoryParams;
    private PrevBars = [];
    private promiseResolver: any;
    private signal: AbortSignal;
    private supportedAggregations: any[];

    constructor () {
        super(ProcessorWorker.MODE_HISTORYSTREAM);
    }

    async SendRequestToServer (historyParams, routeId, signal) {
        this.signal = signal;
        return await new Promise((resolve, reject) => {
            this.promiseResolver = resolve;

            this.historyParams = historyParams;
            const tfInfo = historyParams.TimeFrameInfo;

            const histReqMessage = new vendorMessage.PFixHistroryRequestMessage();

            ProcessorWorker.AddProtocolVersonAndMaxField(histReqMessage);

            if (historyParams.IsContinious) {
                histReqMessage.setFieldValue(FieldsFactory.FIELD_INSTRUMENT_ID, historyParams.InstrumentID);
            } else {
                histReqMessage.setFieldValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID, historyParams.InstrumentTradableID);
            }

            const aggregateByServer = this.isSupportedByServer(tfInfo);
            historyParams.ShouldCheckAggregatedPeriods = aggregateByServer;
            const period = tfInfo.Periods;
            const basePeriod = aggregateByServer ? period : Periods.GetBasePeriod(period);
            const serverPeriod = Periods.TranslateToServerPeriod(basePeriod);
            histReqMessage.setFieldValue(FieldsFactory.FIELD_HISTORY_PERIOD_TYPE, serverPeriod);
            histReqMessage.setFieldValue(FieldsFactory.FIELD_BARS_TYPE, HistoryType.GetServerHistoryType(tfInfo.HistoryType));

            histReqMessage.setFieldValue(FieldsFactory.FIELD_USER_ID, parseInt(historyParams.userId));
            histReqMessage.setFieldValue(FieldsFactory.FIELD_ACCOUNT_ID, parseInt(historyParams.userPin));
            histReqMessage.setFieldValue(FieldsFactory.FIELD_ROUTE_ID, parseInt(this.FMsgDecoder.GetRouteQuoteId(routeId)));

            // История по дате
            histReqMessage.setFieldValue(FieldsFactory.FIELD_START_TIME, historyParams.FromTime);
            histReqMessage.setFieldValue(FieldsFactory.FIELD_END_TIME, historyParams.ToTime);

            // TODO
            histReqMessage.setFieldValue(FieldsFactory.FIELD_MODE, ProcessorWorker.SOKET_MESSAGE_MODE_NEW_FORMAT);

            const barsCount = this.getRealBarCount(historyParams, aggregateByServer);
            histReqMessage.setFieldValue(FieldsFactory.FIELD_QUOTES_LIMIT, barsCount);
            histReqMessage.setFieldValue(FieldsFactory.FIELD_SORT_ORDER, ProcessorWorker.HistorySortOrder_Descending);

            histReqMessage.setFieldValue(FieldsFactory.FIELD_ACCESS_TOKEN, Connection.ConnectResultData.ConnectParams.accessToken);

            // currently it is applyed only for aggregated history
            const onlyMainSession = tfInfo.SessionInfo?.OnlyMainSession ?? true;
            histReqMessage.setFieldValue(FieldsFactory.FIELD_SHOW_EXTENDED_SESSION, !onlyMainSession);

            const rqi = histReqMessage.getSequance();
            histReqMessage.setRequestId(rqi);
            super.SendMessage(histReqMessage);
        });
    }

    ParseHistory (message, historyParams: ReloadHistoryParams) {
        message = message[0] || message;
        // refused
        if (message.IsDirect && message.Code === Message.CODE_BUSINESS_REJECT_MESSAGE) {
            if (message.BusinessRejectCode === vendorMessage.BusinessRejectMessage.ERROR_SERVER_HISTORY_QUEUE_WAS_OVERFLOWED) { super.Notify(message); }

            this.promiseResolver([]);
            return;
        }

        if (this.signal?.aborted) {
            this.CancelHistoryRequest();
        }

        const respEnd = message.FieldSet.GetField(FieldsFactory.FIELD_RESPONCE_END);
        const end = !!(respEnd?.Value);
        const rqi = message.FieldSet.GetField(FieldsFactory.FIELD_REQUEST_ID);
        const reqID = rqi.Value;

        // TODO TranslateToParsePeriod ?????????
        const bars = this.ReadBarToBaseInterval(
            message.FieldSet.GetGroups(FieldsFactory.QUOTE_BAR_GROUP),
            historyParams);

        let PrevBars = this.PrevBars;

        PrevBars = bars.concat(PrevBars);

        if (end) {
            // зачем сортировать?? сервер возвращает сортированные бары
            // Возможно нужно будет развернуть массив
            // Смотря как запрашивали
            this.promiseResolver(PrevBars.sort(BaseInterval.CompareTo));
        }
        this.PrevBars = PrevBars;
    }

    ReadBarToBaseInterval (list, historyParams: ReloadHistoryParams) {
        const result = [];
        if (list == null) { return result; }

        const tfInfo = historyParams.TimeFrameInfo;
        const historyType: number = historyParams.TimeFrameInfo.HistoryType;
        const aggregateByServer = this.isSupportedByServer(tfInfo);
        const period = aggregateByServer
            ? tfInfo.Periods
            : Periods.TranslateToParsePeriod(tfInfo.Periods);
        // const isNeedCorrectBorders :boolean = aggregateByServer && instrument != null && Periods.IsBarAggregation(period);

        for (let i = 0; i < list.length; i++) {
            if (this.signal?.aborted) {
                this.CancelHistoryRequest();
            }

            const row = new vendorGroup.PfixQuoteBarGroup(list[i]);
            const time = row.getTime();
            const open = row.getOpen();
            const high = row.getHigh();
            const low = row.getLow();
            const close = row.getClose();
            const askPrice = row.getAsk();
            const askVolume = row.getAskVolume();
            const bidPrice = row.getBid();
            const bidVolume = row.getBidVolume();
            const price = row.getPrice();
            const volume = row.getVolume();
            const openInt = row.GetValue(FieldsFactory.FIELD_OPEN_INTEREST);
            const ticks = row.GetValue(FieldsFactory.FIELD_TICKS);
            let sessionType = row.GetValue(FieldsFactory.FIELD_SESSION_PERIOD_TYPE);

            if (sessionType > 128) { sessionType = 256 - sessionType; }; // подробности в #101476

            const LastQuoteId = row.GetLastQuoteID();
            const lastQuoteIdDate = row.GetLastQuoteIdDate();

            const timeTicks = time.getTime();

            {
                const offset = 0;
                // DateTime dtMin = DateTime.MinValue;
                // DateTime dtMax = DateTime.MaxValue;
                //    QRecord record = list[i];
                //    if (record.datetime <= dtMin || record.datetime >= dtMax)
                //        continue;

                const args = new Array(7);
                const biObj = new BaseIntervalInputParams();

                // var leftTime = curGr.getLeftTimeTicks();
                // biObj.LeftTimeTicks = leftTime ? leftTime + self.TIME_UTC_OFFSET : leftTime;
                // var rightTime = curGr.getRightTimeTicks();
                // biObj.FRightTimeTicks = rightTime ? rightTime + self.TIME_UTC_OFFSET : rightTime;
                // biObj.LeftTime = new Date(biObj.LeftTimeTicks);

                if (period == Periods.TIC) {
                    // #region // ticks
                    //        if (onlyMainSession && !TradingSessionBase.IsMainType(record.GetSession()))
                    //            continue;
                    switch (historyType) {
                    case HistoryType.QUOTE_LEVEL1:
                    case HistoryType.QUOTE_ASK:
                        biObj.LeftTimeTicks = timeTicks;
                        biObj.Open = bidPrice;
                        biObj.Close = askPrice;
                        biObj.High = Number.NaN;
                        biObj.Low = askVolume;
                        biObj.Volume = bidVolume;
                        break;
                    case HistoryType.QUOTE_TRADES:
                        biObj.LeftTimeTicks = timeTicks;
                        biObj.Open = price;
                        biObj.Close = price;
                        biObj.High = Number.NaN;
                        biObj.Low = Number.NaN;
                        biObj.Volume = volume;
                        break;
                        // это все режимиы для баров, строящихся по тикам (33тика и т.п)//
                    case HistoryType.QUOTE_ASK:
                        biObj.LeftTimeTicks = timeTicks;
                        biObj.Open = askPrice;
                        biObj.Close = Number.NaN;
                        biObj.High = Number.NaN;
                        biObj.Low = Number.NaN;
                        biObj.Volume = askVolume;
                        break;
                    case HistoryType.QUOTE_BIDASK_AVG:
                        biObj.LeftTimeTicks = timeTicks;
                        biObj.Open =
                                biObj.Close = bidPrice / 2 + askPrice / 2;
                        biObj.High = Number.NaN;
                        biObj.Low =
                                biObj.Volume = askVolume / 2 + bidVolume / 2;
                        break;
                    case HistoryType.QUOTE_BIDASK_SUM:
                        biObj.LeftTimeTicks = timeTicks;
                        biObj.Open =
                                biObj.Close = Math.max(bidPrice, askPrice);
                        biObj.High = Number.NaN;
                        biObj.Low =
                                biObj.Volume = bidVolume;
                        break;
                    }
                    biObj.Ticks = 1;
                    // #endregion
                } else if (period == Periods.MIN) {
                    // #region // 1M

                    //        if (onlyMainSession && !TradingSessionBase.IsMainType(record.GetSession()))
                    //            continue;

                    switch (historyType) {
                    case HistoryType.DEFAULT:
                    case HistoryType.QUOTE_LEVEL1:
                        biObj.LeftTimeTicks = timeTicks;
                        biObj.Open = open;
                        biObj.Close = close;
                        biObj.High = high;
                        biObj.Low = low;
                        biObj.Volume = ticks;
                        break;

                    case HistoryType.QUOTE_ASK:
                        biObj.LeftTimeTicks = timeTicks;
                        biObj.Open = open;
                        biObj.Close = close;
                        biObj.High = high;
                        biObj.Low = low;
                        biObj.Volume = ticks;
                        break;

                    case HistoryType.QUOTE_TRADES:
                        biObj.LeftTimeTicks = timeTicks;
                        biObj.Open = open;
                        biObj.Close = close;
                        biObj.High = high;
                        biObj.Low = low;
                        biObj.Volume = volume;// record.volume;
                        break;

                    case HistoryType.QUOTE_BIDASK_AVG:
                        biObj.LeftTimeTicks = timeTicks;
                        biObj.Open = open / 2 + open / 2;
                        biObj.Close = close / 2 + close / 2;
                        biObj.High = high / 2 + high / 2;
                        biObj.Low = low / 2 + low / 2;
                        biObj.Volume = ticks;
                        break;

                    case HistoryType.QUOTE_BIDASK_SUM:
                        biObj.LeftTimeTicks = timeTicks;
                        biObj.Open = open;
                        biObj.Close = close;
                        biObj.High = Math.max(high, high);
                        biObj.Low = Math.min(low, low);
                        biObj.Volume = ticks;
                        break;
                    }

                    biObj.Ticks = ticks;
                    // #endregion
                } else {
                    // #region // Day total

                    switch (historyType) {
                    case HistoryType.DEFAULT:
                    case HistoryType.QUOTE_LEVEL1:
                        biObj.LeftTimeTicks = timeTicks;
                        biObj.Open = open;
                        biObj.Close = close;
                        biObj.High = high;
                        biObj.Low = low;
                        biObj.Volume = /* record.ticksPre + */ ticks;
                        break;

                    case HistoryType.QUOTE_ASK:
                        biObj.LeftTimeTicks = timeTicks;
                        biObj.Open = open;
                        biObj.Close = close;
                        biObj.High = high;
                        biObj.Low = low;
                        biObj.Volume = /* record.ticksPre + */ ticks;
                        break;

                    case HistoryType.QUOTE_TRADES:
                        biObj.LeftTimeTicks = timeTicks;
                        biObj.Open = open;
                        biObj.Close = close;
                        biObj.High = high;
                        biObj.Low = low;
                        biObj.Volume = volume;// record.volume;record.volumePre + record.volume + record.volumePost;
                        break;

                    case HistoryType.QUOTE_BIDASK_AVG:
                        biObj.LeftTimeTicks = timeTicks;
                        biObj.Open = open / 2 + open / 2;
                        biObj.Close = close / 2 + close / 2;
                        biObj.High = high / 2 + high / 2;
                        biObj.Low = low / 2 + low / 2;
                        biObj.Volume = /* record.ticksPre + */ ticks;
                        break;

                    case HistoryType.QUOTE_BIDASK_SUM:
                        biObj.LeftTimeTicks = timeTicks;
                        biObj.Open = open;
                        biObj.Close = close;
                        biObj.High = Math.max(high, high);
                        biObj.Low = Math.min(low, low);
                        biObj.Volume = /* record.ticksPre + */ ticks;
                        break;
                    }
                    biObj.Ticks = /* record.ticksPre + */ ticks;
                    // #endregion
                }

                // let bi = period === Periods.TIC ? new BaseIntervalTick(args, period, offset) : new BaseInterval(args, period, offset);
                const bi = new BaseInterval(biObj, period/*, offset */);
                if (sessionType !== null) bi.SessionType = sessionType;
                //    bi.Separator = record.GetSeparated();
                //    if (record.tradeTickRow != null)
                // {
                //        bi.AggressorFlag = (Quote3Message.AgressorFlagType)record.tradeTickRow.Aggressor;
                //        bi.Brokers = record.tradeTickRow.Brokers;
                // }
                // else
                //        bi.AggressorFlag = Quote3Message.AgressorFlagType.None;

                bi.LastQuoteId = LastQuoteId;
                bi.LastQuoteIdDate = lastQuoteIdDate;

                // ждем пока сервер поправит агрегацию
                // if (isNeedCorrectBorders)
                // {
                //    (_, bi.FRightTimeTicks) = bi.CorrectBordersForAggregations(
                //        bi.LeftTime,
                //        instrument,
                //        period,
                //        showExtendedSession);
                // }

                if (bi.IsValid()) { result.push(bi); }
            }
        }

        return result;
    }

    MergeBIDASKAVG (bids, asks, historyParams): BaseInterval[] {
        const res: BaseInterval[] = [];

        let askIndex = 0;
        const args = new Array(7);
        const biObj = new BaseIntervalInputParams();

        for (let i = 0; i < bids.length; i++) {
            const bidBi = bids[i];

            if (historyParams.TimeFrameInfo.Periods == Periods.TIC) {
                biObj.LeftTimeTicks = bidBi.FLeftTimeTicks;
                biObj.Open = biObj.Close = bidBi.Data[0] / 2 + bidBi.Data[1] / 2;
                biObj.High = 0;
                biObj.Low = biObj.Volume = bidBi.Volume;
                biObj.Ticks = bidBi.Ticks;
                res.push(new BaseInterval(biObj, historyParams.TimeFrameInfo.Periods));
            } else {
                const askBi = asks[askIndex];
                if (bidBi.FLeftTimeTicks == askBi.FLeftTimeTicks) {
                    biObj.LeftTimeTicks = bidBi.FLeftTimeTicks;
                    biObj.Open = bidBi.Data[BaseInterval.OPEN_INDEX] / 2 + askBi.Data[BaseInterval.OPEN_INDEX] / 2;
                    biObj.Close = bidBi.Data[BaseInterval.CLOSE_INDEX] / 2 + askBi.Data[BaseInterval.CLOSE_INDEX] / 2;
                    biObj.High = bidBi.Data[BaseInterval.HIGH_INDEX] / 2 + askBi.Data[BaseInterval.HIGH_INDEX] / 2;
                    biObj.Low = bidBi.Data[BaseInterval.LOW_INDEX] / 2 + askBi.Data[BaseInterval.LOW_INDEX] / 2;
                    biObj.Volume = bidBi.Volume;
                    res.push(new BaseInterval(biObj, historyParams.TimeFrameInfo.Periods));
                    askIndex++;
                }
            }
        }

        return res;
    }

    Notify (message) {
        this.ParseHistory(message, this.historyParams);
    }

    CancelHistoryRequest () {
        super.Disconnect();
    }

    setSupportedAggregations (supportedAggregations) {
        this.supportedAggregations = supportedAggregations;
    }

    isSupportedByServer (tfInfo) {
        const aggType = this.convertToServerAggregatedType(tfInfo.ChartDataType);
        if (aggType === HistoryAggregatedType.None) return false;

        const [periodType, periodValue] = this.convertToServerAggregatedPeriod(tfInfo.Periods);
        if (periodType == HistoryAggregatedPeriod.None) return false;

        for (const agg of this.supportedAggregations) {
            if (agg.getAggregatedType() === aggType &&
                agg.getAggregatedPeriod() == periodType &&
                agg.getAggregatedValue() == periodValue) {
                return true;
            }
        }

        return false;
    }

    convertToServerAggregatedType (chartDataType) {
        switch (chartDataType) {
        case ChartDataType.Default:
            return HistoryAggregatedType.Time;
        default:
            return HistoryAggregatedType.None;
        }
    }

    convertToServerAggregatedPeriod (period) {
        if (period % Periods.YEAR == 0) { return [HistoryAggregatedPeriod.None, period / Periods.YEAR]; } else if (period % Periods.MONTH == 0) { return [HistoryAggregatedPeriod.PERIOD_MONTH, period / Periods.MONTH]; } else if (period % Periods.WEEK == 0) { return [HistoryAggregatedPeriod.PERIOD_WEEK, period / Periods.WEEK]; } else if (period % Periods.HOUR == 0) { return [HistoryAggregatedPeriod.PERIOD_HOUR, period / Periods.HOUR]; } else if (period % Periods.MIN == 0) { return [HistoryAggregatedPeriod.PERIOD_MINUTE, period / Periods.MIN]; } else { return [HistoryAggregatedPeriod.None, 0]; }
    }

    getRealBarCount (historyParams, isAggregatedByServer) {
        const count = historyParams.count;

        if (!count) {
            return ProcessorWorker.MAX_BARS_TO_READ;
        }

        if (isAggregatedByServer) {
            return count;
        }

        const period = historyParams.TimeFrameInfo.Periods;
        return this.getDownloadBarsCount(count, period);
    }

    getDownloadBarsCount (displayBarCount, period) {
        if (period === Periods.MIN || period === Periods.DAY || period === Periods.TIC) {
            return displayBarCount;
        }

        if (period >= Periods.YEAR && period % Periods.YEAR === 0) {
            return (period / Periods.YEAR) * displayBarCount * 365; // ?
        }
        if (period >= Periods.MONTH && period % Periods.MONTH === 0) {
            return (period / Periods.MONTH) * displayBarCount * 30;// ?
        }
        if (period >= Periods.WEEK && period % Periods.WEEK === 0) {
            return (period / Periods.WEEK) * displayBarCount * 7;
        }
        if (period >= Periods.DAY && period % Periods.DAY === 0) {
            return (period / Periods.DAY) * displayBarCount;
        } else if (period < 0) {
            throw new Error('getDownloadBarsCount::Not implemented for ticks');
        } else if (period % Periods.SECOND === 0) {
            throw new Error('getDownloadBarsCount::Not implemented for seconds');
        } else // minues aggregation
        {
            return period * displayBarCount;
        }
    }
}

const HistoryAggregatedType =
{
    None: -1, // Not from server
    Time: 0 // At the current moment, only the Time (0) aggregation type is supported on the Server side.
};

Object.freeze(HistoryAggregatedType);

const HistoryAggregatedPeriod =
{
    None: -1, // Not from server
    PERIOD_MINUTE: 2,
    PERIOD_HOUR: 3,
    PERIOD_WEEK: 5,
    PERIOD_MONTH: 6
};

Object.freeze(HistoryAggregatedPeriod);
