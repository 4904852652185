// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { Message } from '../../../../Utils/DirectMessages/Message';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';
import { PFixMessage } from './PFixMessage';

export class TradingSignalRequestMessage extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_TRADING_SIGNALS_REQ_MESSAGE, fieldSet);
    }
}
