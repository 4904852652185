// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { PFixField } from './PFixField';
import { DataBuffer } from '../../WebFix/DataBuffer';

export class PFixPFSMessageField extends PFixField {
    constructor (fieldId, buffer?) {
        super(fieldId, buffer);

        this.Value = [];
    }

    public override ReadValue (buffer): void {
    // код мессаги
        const type = buffer.ReadByte();

        // 3 или 4 байта - длина
        const lengthBuffer = buffer.ReadBuffer(2);

        let length = 0;
        for (let i = 0; i < lengthBuffer.Length; i++) {
            length += lengthBuffer.ReadByte() << 8 * i;
        }
        lengthBuffer.Reset();

        const message: Uint8Array = new Uint8Array(length + lengthBuffer.length + 1);
        message[0] = type;
        buffer.ReadBytes(lengthBuffer.Length, message, 0);
        // for (int i = 0; i < lengthBuffer.Length; i++)
        //    message[1 + i] = lengthBuffer.ReadByte();

        // читаем мессагу
        const offset = lengthBuffer.Length + 1;
        buffer.ReadBytes(length, message, offset);
        // for (int i = 0; i < length; i++)
        //    message[offset + i] = buffer.ReadByte();

        this.Value = message;
    }

    public override Write (buffer): void {
        buffer.Write(this.FieldId);
        const db = new DataBuffer(this.Value);
        buffer.Write(db);
    }
}
