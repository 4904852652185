// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { TerceraRadioButtonTemplate } from "../../templates.js";
import { Control } from "./Control.js";

export let TerceraRadioButton = Control.extend({
    template: TerceraRadioButtonTemplate,
    data: function () {
        return {
            checked: false,             
            text: ''
        };
    }
});

//
//
//
TerceraRadioButton.prototype.private_RadioClick = function (me)
{
    if (!me.checked)
        this.set('checked', true);
};

//
// 
//
TerceraRadioButton.prototype.oncomplete = function ()
{
    //this.observe('checked', this.private_OnCheckedChanged);
    //this.on('radioBtnClick', this.private_RadioClick);
};

TerceraRadioButton.prototype.oninit = function ()
{
    Control.prototype.oninit.apply(this);
    this.observe('checked', this.private_OnCheckedChanged);
    this.on('radioBtnClick', this.private_RadioClick);
};

//
// private_OnCheckedChanged
//
TerceraRadioButton.prototype.private_OnCheckedChanged = function (newValue, oldValue, keypath)
{
    if (!newValue && !oldValue)
        return;
    this.fire(TerceraRadioButton.Events.RadioBtnStateChange, this, newValue, oldValue);
};

//
//
//
TerceraRadioButton.Events =
{
    RadioBtnStateChange: 'RBStateChange'
}
Object.freeze(TerceraRadioButton.Events);