// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts";
import { LinkedSystem, LinkedSystemAccLinkingValue } from "../misc/LinkedSystem.ts";
import { FilledOrderItem } from "../cache/FilledOrderItem.ts";
import { ColouringModes } from "../elements/QuickTable/QuickTableColumn.ts";
import { TerceraLinkControlConstants } from "../UtilsClasses/TerceraLinkControlConstants.ts";
import { TerceraMenu } from "../elements/TerceraMenu.ts";
import { ExportScreen } from "../screen/ExportScreen.js";
import { TerceraMessageBox } from "../screen/TerceraMessageBox.js";
import { PanelNames } from "../UtilsClasses/FactoryConstants.ts";
import { ApplicationPanelNew } from "./ApplicationPanelNew.js";
import { DynProperty } from "../../Commons/DynProperty.ts";
import { AllowedReport } from "../../Commons/cache/AllowedReport.ts";
import { DataCache } from "../../Commons/DataCache.ts";
import { SessionSettings } from "../../Commons/SessionSettings.ts";
import { ApplicationInfo } from "../../Commons/ApplicationInfo.ts";

import { FilledOrdersPanelTemplate } from "../../templates.js";

export let FilledOrdersPanel = ApplicationPanelNew.extend(
    {
        data: function ()
        {
            return {
                isAccountLinkShow: true,
                isSymbolLinkShow: true,
                isShowExploreTheAppLink: ApplicationInfo.isExploreMode,
            };
        },

        partials: {
            bodyPartial: FilledOrdersPanelTemplate
        },

        headerLocaleKey: 'panel.trades',
        moreThanOneTTKey: 'panel.orders.menu.MoreOneSymbolInfoDisabled.tt',          // #95439
        noOneTTKey: 'panel.orders.menu.NoSymbolInfoDisabled.tt',
        rangeSelectPanel: null
    });

FilledOrdersPanel.prototype.getType = function () { return PanelNames.FilledOrdersPanel };

FilledOrdersPanel.prototype.oncomplete = function ()
{
    ApplicationPanelNew.prototype.oncomplete.apply(this);

    this.rangeSelectPanel = this.Controls.filledOrdersRangeSelect
    this.rangeSelectPanel.getReportEvent.Subscribe(this.getFilledOrdersReport, this)
    this.rangeSelectPanel.updateSelectedRange();

    this.populateTableContextMenu();

    this.getQuickTable().OnSelectionChanged.Subscribe(this.selectionChange, this);
    DataCache.OnAddNewTradeEvent.Subscribe(this.AddTradeEvent, this);
    DataCache.OnRemovedTradeEvent.Subscribe(this.RemovedTradeEvent, this);

    this.UpdateShowTotalsStateChange(!!this.InitShowTotals);
    if (this.menuTagDict && this.menuTagDict["ShowTotals"])
        this.menuTagDict["ShowTotals"].checked = !!this.InitShowTotals
};

FilledOrdersPanel.prototype.dispose = function ()
{
    this.getQuickTable().OnSelectionChanged.UnSubscribe(this.selectionChange, this);
    this.rangeSelectPanel.getReportEvent.UnSubscribe(this.getFilledOrdersReport, this)
    DataCache.OnAddNewTradeEvent.UnSubscribe(this.AddTradeEvent, this);
    DataCache.OnRemovedTradeEvent.UnSubscribe(this.RemovedTradeEvent, this);

    ApplicationPanelNew.prototype.dispose.apply(this);
};

FilledOrdersPanel.prototype.populateItemsDirect = function ()
{
    let trades = DataCache.newTradesDict;
    let keys = Object.keys(trades);
    let length = keys.length;
    for (let i = 0; i < length; i++)
        this.getQuickTable().AddItem(new FilledOrderItem(trades[keys[i]], SessionSettings));
}

FilledOrdersPanel.prototype.populateTableContextMenu = function ()
{
    var items = [];

    if (!Resources.isHidden('screen.export.visibility'))
        items.push(
            {
                text: Resources.getResource('screen.export.contextMenu'),
                event: ExportScreen.show.bind(null, this)
            }
        );

    if (!Resources.isHidden('panel.trades.print'))
        items.push(
            {
                text: Resources.getResource('screen.reports.print'),
                event: this.printTable.bind(this)
            }
        );

    if (!Resources.isHidden('contextMenu.Totals.visibility'))   // переделать в случае появления новых subitems
        items.push(
            {
                text: Resources.getResource('panel.positions.menu.View'),
                tag: 'View',
                enabled: true,
                subitems: [
                    {
                        text: Resources.getResource('panel.positions.menu.ShowTotals'),
                        tag: "ShowTotals",
                        checked: false,
                        enabled: true,
                        canCheck: true,
                        event: this.ShowTotalsStateChange.bind(this)
                    }
                ]
            }
        );

    this.AddSymbolInfoContextMenuItemIfNeed(items)

    this.getQuickTable().setTableContextMenuItems(items);
    this.menuTagDict = TerceraMenu.createTagDictionary(items);
};

FilledOrdersPanel.prototype.AddTradeEvent = function (dc, trade)
{
    this.getQuickTable().AddItem(new FilledOrderItem(trade, SessionSettings));
};

FilledOrdersPanel.prototype.RemovedTradeEvent = function ()
{
    let qtRactive = this.quickTableRactive
    let qt = qtRactive ? qtRactive.quickTable : null
    if (!qt) return

    qt.ClearAll();
    let len = DataCache.filledOrdersArray.length
    for (let i = 0; i < len; i++)
        qt.AddItem(new FilledOrderItem(DataCache.filledOrdersArray[i], SessionSettings))
};

FilledOrdersPanel.prototype.getFilledOrdersReport = function (startTime, finishTime)
{
    let DC = DataCache,
        self = this

    self.set('loading', true)

    DC.FilledOrdersReportCompleted = false;
    DC.getReport(AllowedReport.REPORT_TRADES, startTime.toString(), finishTime.toString()).then(function (filledOrders)
    {
        let qtRactive = self.quickTableRactive
        let qt = qtRactive ? qtRactive.quickTable : null
        if (!qt) return

        qt.ClearRows();
        let len = filledOrders.length
        for (let i = 0; i < len; i++)
            qt.AddItem(new FilledOrderItem(filledOrders[i], SessionSettings))

        let lS = LinkedSystem

        if (lS.accLinkingActive)
        {
            let accId = lS.accountStorage[Object.keys(lS.accountStorage)]
            qt.filterByAccountNumber(accId)                         // отфильтруем по аккаунту позиции, если раскрылся лишний трейд
        }

    }).finally(function () 
    {
        self.set('loading', false)
    })
}

FilledOrdersPanel.prototype.selectionChange = function ()
{
    let qt = this.getQuickTable()
    let selectedRowsId = qt.selectedRowIds
    if (!selectedRowsId) return

    let insId = ''
    //let accId = ''

    if (selectedRowsId.length > 0)
    {
        let row = qt.rows[selectedRowsId[0]]
        let item = row ? row.item : null
        if (item)
        {
            let ins = item.GetCurrentInstrument()
            if (ins) insId = ins.GetInteriorID()

            //let acc = item.GetCurrentAccount()
            //if (acc) accId = acc.BstrAccount
        }

        if (!insId)
            TerceraMessageBox.Show(Resources.getResource('screen.reports.info'), Resources.getResource('general.InstrumentIsNotAvailableNow'), TerceraMessageBox.msgType.Info, null, null, false, true, null, { cantActionBeforeClose: true });
    }

    this.symbolLink_Out(false, insId)
    //this.accountLink_Out(false, accId)
};

FilledOrdersPanel.prototype.symbolLink_Out = function (newSubscriber, instrument)
{
    if (!instrument)
        return;

    var color = this.get('symbolLinkValue');
    if (color !== TerceraLinkControlConstants.STATE_NONE)
        LinkedSystem.setSymbol(color, instrument, newSubscriber);
};

FilledOrdersPanel.prototype.Properties = function ()
{
    let properties = ApplicationPanelNew.prototype.Properties.call(this);

    properties.push(new DynProperty("ShowTotals", this.getQuickTable().ShowTotals, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));

    return properties;
};

FilledOrdersPanel.prototype.callBack = function (newProperties)
{
    ApplicationPanelNew.prototype.callBack.call(this, newProperties);

    this.InitShowTotals = DynProperty.getPropValue(newProperties, "ShowTotals");
};

FilledOrdersPanel.prototype.SetColumnsDefaultDisplayIndex = function (table)
{
    table.columns[0].displayedIndex = 9;
    table.columns[1].displayedIndex = 10;
    table.columns[2].displayedIndex = 2;
    table.columns[3].displayedIndex = 11;
    table.columns[4].displayedIndex = 3;
    table.columns[5].displayedIndex = 4;
    table.columns[6].displayedIndex = 5;
    table.columns[7].displayedIndex = 7;
    table.columns[8].displayedIndex = 6;
    table.columns[9].displayedIndex = 1;
    table.columns[10].displayedIndex = 0;
    table.columns[11].displayedIndex = 15;
    table.columns[12].displayedIndex = 16;
    table.columns[13].displayedIndex = 17;
    table.columns[14].displayedIndex = 18;
    table.columns[15].displayedIndex = 12;
    table.columns[16].displayedIndex = 13;
    table.columns[17].displayedIndex = 14;
    table.columns[18].displayedIndex = 19;
    table.columns[19].displayedIndex = 8;
}

FilledOrdersPanel.prototype.SetColumnsColouringMode = function (table)
{
    this.ProcessSetColumnsColouringMode(table, [7, 8, 19], ColouringModes.Signed);
}
//Symbol       +
//Side         +
//Quantity     +
//Gross P\L    +
//Net P\L      +
//Execution fee +
//test