// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectEntitlementProductMessage } from '../../../../Utils/DirectMessages/DirectMessagesImport';
import { EntitlementProductDefinitionGroup } from '../Groups/GroupsImport';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class EntitlementProductMessageProcessor extends MessageProcessorBase {
    public override decode (fs: PFixFieldSet): DirectEntitlementProductMessage[] {
        const msg = new DirectEntitlementProductMessage();

        msg.Id = fs.GetValue(FieldsFactory.FIELD_PRODUCT_ID);
        msg.Name = fs.GetValue(FieldsFactory.FIELD_NAME);
        msg.Description = fs.GetValue(FieldsFactory.FIELD_DESCRIPTION);
        msg.Category = fs.GetValue(FieldsFactory.FIELD_PRODUCT_CATEGORY);
        msg.CurrencyId = fs.GetValue(FieldsFactory.FIELD_CURRENCY_ID);
        msg.ProPrice = fs.GetValue(FieldsFactory.FIELD_PRODUCT_PRO_PRICE);
        msg.NonProPrice = fs.GetValue(FieldsFactory.FIELD_PRODUCT_NON_PRO_PRICE);
        msg.HasDocument = fs.GetValue(FieldsFactory.FIELD_PRODUCT_HAS_DOCUMENTS);
        msg.ImageBytes = fs.GetValue(FieldsFactory.FIELD_RAW_BYTES);
        msg.ProductDataType = fs.GetValue(FieldsFactory.FIELD_PRODUCT_TYPE);
        msg.ShowInSettings = fs.GetValue(FieldsFactory.FIELD_SHOW_IN_SETTINGS);
        msg.ColorHEX = fs.GetValue(FieldsFactory.FIELD_COLOR_IN_FRONT);
        msg.IsUnsubscribeAllowed = !fs.GetValue(FieldsFactory.FIELD_PRODUCT_IS_DEFAULT); // #108196 comment
        msg.QuoteDelay = fs.GetValue(FieldsFactory.FIELD_DELAY_PERIOD) ?? 0;

        msg.ProductDefinitions = [];
        msg.IsActive = fs.GetValue(FieldsFactory.FIELD_IS_ACTIVE);
        if (msg.IsActive === null) { msg.IsActive = true; }
        const groups = fs.GetGroups(FieldsFactory.PRODUCT_DEFINITION_GROUP);
        for (let i = 0; i < groups.length; i++) {
            const gr = new EntitlementProductDefinitionGroup(groups[i]);
            msg.ProductDefinitions.push(gr.GetProductDefinition());
        }

        return [msg];
    }
}
