// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DynProperty } from '../../Commons/DynProperty';
import { Resources } from '../../Commons/properties/Resources';
import { DockSystemInstance } from '../DockSystem.js';
import { Factory, FactoryAddPanelFrom } from '../panels/Factory.js';
import { WorkSpaceSerializedPanel } from './WorkSpaceSerializedPanel';
import { GlobalFlags } from '../../Commons/UtilsClasses/GlobalFlags';
import { IsAllowedPanel } from './WorkSpaceUtils';

export class WorkSpace {
    visibleName: string;
    fileName: string;
    locked: boolean;
    isDirty: boolean;

    allPanels: any;
    allPanelsFromContainer: any;
    constructor (name: string, fileName: string) {
        this.visibleName = name;
        this.fileName = fileName;
        this.allPanels = {};
        this.allPanelsFromContainer = {};
        this.locked = Resources.isHidden('allowWorkspaceEdit');
        this.isDirty = false;
    }

    public getPanelsAmount (): number {
        return Object.keys(this.allPanels).length;
    };

    // TODO. Exclusively for Brazil: hidden
    public updateLocked (locked): void {
        if (Resources.isHidden('allowWorkspaceEdit')) { this.locked = true; } else if (locked !== undefined) { this.locked = !!locked; }
    };

    public serializePanels (): any {
        GlobalFlags.InstrumentSaveMode = true;
        const serializedPanelArray = [];
        const allPanels = this.allPanels;
        const insNamesDict = new Object();
        let i = 0;
        for (const key in allPanels) {
            const panel = allPanels[key];
            if (panel.skipOnSave) { continue; }
            panel.setWorkSpaceId(i++);

            const serializedPanel = new WorkSpaceSerializedPanel();
            serializedPanel.name = panel.getType();
            serializedPanel.properties = DynProperty.serialize(panel.Properties());

            const xmlSettingsTemplate = panel.getXmlSettingsTemplate();
            if (xmlSettingsTemplate) { serializedPanel.xmlSettingsTemplate = xmlSettingsTemplate; }

            serializedPanelArray.push(serializedPanel);

            const ins = panel.getInstrument();
            if (ins) { insNamesDict[ins.GetInteriorID()] = 1; }
        }

        GlobalFlags.InstrumentSaveMode = false;
        return {
            panels: serializedPanelArray,
            dockSystemState: DockSystemInstance.getState(),
            insDict: insNamesDict
        };
    };

    public loadPanels (serializedPanelsData): void {
        if (!serializedPanelsData?.dockSystemState ||
            !serializedPanelsData.panels) { return; }

        const dockSystemState = serializedPanelsData.dockSystemState ? JSON.parse(serializedPanelsData.dockSystemState) : null;
        const serializedPanelArray = serializedPanelsData.panels;
        for (let i = 0; i < serializedPanelArray.length; i++) {
            const serializedPanel = serializedPanelArray[i];
            const emptyPanel = Factory.createPanel(serializedPanel.name);
            const properties = DynProperty.deserialize(serializedPanel.properties);

            const validProperties = emptyPanel.validateProperties(properties);
            if (validProperties) {
                const newPanel = Factory.addPanel(serializedPanel.name, FactoryAddPanelFrom.Restore);
                newPanel.callBack(properties);
                newPanel.setXmlSettingsTemplate(serializedPanel.xmlSettingsTemplate);

                DockSystemInstance.addToArrRestore(newPanel);
            } else { DockSystemInstance.deletePanelFromDockSystemState(emptyPanel.GetWSIdFromProperty(properties)); };
        };

        DockSystemInstance.setState(JSON.stringify(dockSystemState));
    };

    public clear (): void {
        const allPanels = this.allPanels;
        for (const panelName in allPanels) { allPanels[panelName].dispose(); }

        this.allPanels = {};
        this.allPanelsFromContainer = {};

        DockSystemInstance.clearDocking();
    };

    // TODO.
    public dispose (): void {
        this.clear();
    };

    public addPanel (panel): void {
        const isAllowedPanel = IsAllowedPanel(panel);

        this.allPanels[panel._guid] = panel;

        if (isAllowedPanel) { panel.populate(true); } else { panel.set('forbiddenPanel', true); }

        this.isDirty = true;
    };

    public addPanelFromContainer (panel): void {
        this.allPanelsFromContainer[panel._guid] = panel;
    }

    // TODO. Confusing name.
    public removePanel (id): void {
        delete this.allPanels[id];
    };

    public removePanelFromContainer (panel): void {
        delete this.allPanelsFromContainer[panel._guid];
    }

    public createPropertiesHash (propArray): any {
        const result = {};
        for (let i = 0, len = propArray.length; i < len; i++) {
            const prop = propArray[i];
            result[prop.name] = prop;
        }
        return result;
    };

    public getElementByWSID (id): any {
        const allPanels = this.allPanels;
        for (const panelName in allPanels) {
            const panel = allPanels[panelName];
            if (panel.get('workSpaceId') === id) { return panel; }
        }
        return null;
    };

    public populate (): void {
        const allPanels = this.allPanels;
        for (const panelName in allPanels) {
            allPanels[panelName].populate();
            this.isDirty = true;
        }
    };

    public TickAsync (): void {
        const allPanels = this.allPanels;
        for (const panelName in allPanels) { allPanels[panelName].TickAsync(); }
    };

    public updateSettings (): void {
        const allPanels = this.allPanels;
        for (const panelName in allPanels) { allPanels[panelName].updateSettings(); }
    };

    public localize (): void {
        const allPanels = this.allPanels;
        for (const panelName in allPanels) { allPanels[panelName].localize(); }
    };

    public RefreshPanelsWithInstrumentDescriptions (insArray): void {
        const allPanels = this.allPanels;
        for (const panelName in allPanels) { allPanels[panelName].RefreshInstrumentDescriptions(insArray); }
    };

    public themeChange (): void {
        const allPanels = this.allPanels;
        for (const panelName in allPanels) { allPanels[panelName].themeChange(); }
    };

    public numberOfPanelByType (panelType): number {
        const allPanels = this.allPanels;
        const allPanelsFromContainer = this.allPanelsFromContainer;
        let result = 0;
        for (const panelName in allPanels) {
            if (allPanels[panelName].getType() === panelType) {
                result++;
            }
        }
        for (const panelName in allPanelsFromContainer) {
            if (allPanelsFromContainer[panelName].getType() === panelType) {
                result++;
            }
        }
        return result;
    };
}
