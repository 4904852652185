// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { MAMode } from '../IndicatorConstants';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';
import { InputParameterCombobox } from '../InputParamaterClasses/InputParameterCombobox';
import { type iBuildInIndicator } from '../iBuildInIndicator';

export class MAE extends IndicatorScriptBase {
    public SourcePrice: number;
    public MAType: any;
    public MAPeriod: number;
    public UpShift: number;
    public DownShift: number;

    private ma: iBuildInIndicator;

    constructor () {
        super();
        this.ProjectName = 'Moving Average Envelope';
        this.Comments = 'Demonstrates a range of the prices discrepancy from a Moving Average';
        this.SetIndicatorLine('lower', Color.Purple, 1, LineStyle.SimpleChart);
        this.SetIndicatorLine('upper', Color.LightSeaGreen, 1, LineStyle.SimpleChart);
        this.SeparateWindow = false;

        this.SourcePrice = PriceType.Close;
        super.InputParameter(new InputParameterCombobox('SourcePrice', 'Sources prices for MA', 1,
            [
                { Close: PriceType.Close },
                { Open: PriceType.Open },
                { High: PriceType.High },
                { Low: PriceType.Low },
                { Typical: PriceType.Typical },
                { Medium: PriceType.Medium },
                { Weighted: PriceType.Weighted }
            ]));

        this.MAType = MAMode.SMA;
        super.InputParameter(new InputParameterCombobox('MAType', 'Type of moving average', 1,
            [
                { Simple: MAMode.SMA },
                { Exponential: MAMode.EMA },
                { Modified: MAMode.SMMA },
                { 'Linear Weighted': MAMode.LWMA }
            ]));

        this.MAPeriod = 10;
        super.InputParameter(new InputParameterInteger('MAPeriod', 'Period of MA for envelopes', 0, 1, 99999));

        this.UpShift = 0.1;
        super.InputParameter(new InputParameterInteger('UpShift', 'Upband deviation in %', 0, 1, 99999));

        this.DownShift = 0.1;
        super.InputParameter(new InputParameterInteger('DownShift', 'Downband deviation in %', 0, 1, 99999));
    }

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
    }

    public override GetIndicatorShortName (): string {
        return 'MAE(' + this.MAPeriod + ';' + this.UpShift + ';' + this.DownShift + ')';
    }

    public override createInternalIndicators (): iBuildInIndicator[] {
        this.ma = this.Indicators.iMA(this.CurrentData, this.MAPeriod, this.MAType, 0, this.SourcePrice);
        return [this.ma];
    }

    public OnQuote (): void {
        super.OnQuote();
        if (this.CurrentData.Count <= this.MAPeriod) { return; }
        const maValue = this.ma.GetValue();
        this.SetValue(0, 0, (1 - this.DownShift * 0.01) * maValue);
        this.SetValue(1, 0, (1 + this.UpShift * 0.01) * maValue);
    }
}
