// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { Resources } from '../../Commons/properties/Resources';
import { DrawPointer, DrawPointerTypeEnum } from '../Utils/DrawPointer';
import { HistoryType } from '../../Utils/History/HistoryType';
import { LayersEnum, TerceraChartBaseRenderer } from './TerceraChartBaseRenderer';
import { TerceraChartCashItemSeriesDataType } from '../Series/TerceraChartCashItemSeriesEnums';
import { Color, Pen, SolidBrush } from '../../Commons/Graphics';
import { TerceraChartAction, TerceraChartActionEnum } from '../TerceraChartAction';
import { ThemeManager } from '../../Controls/misc/ThemeManager';
import { Periods } from '../../Utils/History/TFInfo';
import { PriceFormatter } from '../../Utils/Instruments/PriceFormatter';
import { DynProperty } from '../../Commons/DynProperty';
import { TerceraChartSpreadType } from '../../Utils/History/SpreadType';
import { PriceType } from '../../Utils/History/CashItemUtils';
import { type TerceraChart } from '../TerceraChart';

export class TerceraChartSpreadRenderer extends TerceraChartBaseRenderer<TerceraChart> {
    spreadBrush: SolidBrush | null;
    askPen: Pen | null;
    askBrush: SolidBrush | null;
    bidPen: Pen | null;
    bidBrush: SolidBrush | null;
    lastPen: Pen | null;
    lastBrush: SolidBrush | null;
    SpreadIncorrectBackBrush: any;
    PriceIndicatorDrawingType: TercaraChartPriceIndicatorType;
    TerceraChartSpreadType: number;
    lastPrice_BidIndicatorColor: string;
    lastPrice_AskIndicatorColor: string;
    lastPrice_LastIndicatorColor: string;
    lastPrice_SpreadIndicatorColor: string;
    private readonly PriceScaleFont: any;
    private readonly withAsset: boolean;
    private readonly percentView: boolean;

    constructor (chart: TerceraChart) {
        super(chart);
        this.spreadBrush = null;
        this.askPen = null;
        this.askBrush = null;
        this.bidPen = null;
        this.bidBrush = null;
        this.lastPen = null;
        this.lastBrush = null;
        this.SpreadIncorrectBackBrush = null;
        this.PriceIndicatorDrawingType = TercaraChartPriceIndicatorType.ScaleMarkerWithLine;
        this.TerceraChartSpreadType = TerceraChartSpreadType.None;
        this.lastPrice_BidIndicatorColor = ThemeManager.CurrentTheme.BuyColor;
        this.lastPrice_AskIndicatorColor = ThemeManager.CurrentTheme.SellColor;
        this.lastPrice_LastIndicatorColor = ThemeManager.CurrentTheme.Chart_lastPrice_LastIndicatorColor;
        this.lastPrice_SpreadIndicatorColor = Color.FromArgb(40, ThemeManager.CurrentTheme.Chart_TimeScaleCursorBackColor);
        this.PriceScaleFont = ThemeManager.Fonts.Font_10F_regular.copy();
        this.withAsset = false;
        this.percentView = false;
        this.ThemeChanged();
        this.Localize();
        this.assignLayer = LayersEnum.Quotes;
        this.SetClassName('TerceraChartSpreadRenderer');
    }

    get LastPrice_BidIndicatorColor () {
        return this.lastPrice_BidIndicatorColor;
    }

    set LastPrice_BidIndicatorColor (value: string) {
        this.lastPrice_BidIndicatorColor = value;
        if (this.bidBrush) this.bidBrush.Color = this.lastPrice_BidIndicatorColor;
        if (this.bidPen) this.bidPen.Color = this.lastPrice_BidIndicatorColor;
    }

    get LastPrice_AskIndicatorColor () {
        return this.lastPrice_AskIndicatorColor;
    }

    set LastPrice_AskIndicatorColor (value: string) {
        this.lastPrice_AskIndicatorColor = value;
        if (this.askBrush) this.askBrush.Color = this.lastPrice_AskIndicatorColor;
        if (this.askPen) this.askPen.Color = this.lastPrice_AskIndicatorColor;
    }

    get LastPrice_LastIndicatorColor () {
        return this.lastPrice_LastIndicatorColor;
    }

    set LastPrice_LastIndicatorColor (value: string) {
        this.lastPrice_LastIndicatorColor = value;
        if (this.lastBrush) this.lastBrush.Color = this.lastPrice_LastIndicatorColor;
        if (this.lastPen) this.lastPen.Color = this.lastPrice_LastIndicatorColor;
    }

    get LastPrice_SpreadIndicatorColor () {
        return this.lastPrice_SpreadIndicatorColor;
    }

    set LastPrice_SpreadIndicatorColor (value: string) {
        this.lastPrice_SpreadIndicatorColor = value;
        if (this.spreadBrush) this.spreadBrush.Color = this.lastPrice_SpreadIndicatorColor;
    }

    Draw (gr: any, window: any, windowsContainer: any, advParams: any) {
        if (!this.Visible) { return; }

        const p = advParams;
        if (!p) return;

        const instrument = p.Instrument;
        const acc = p.Account;

        if (!instrument || !p.mainPriceRenderer.Series) { return; }

        const cashItemSeries = p.mainPriceRenderer.Series;

        const tradingDataBid = instrument.Level1.GetBid(acc);
        const tradingDataAsk = instrument.Level1.GetAsk(acc);
        let tradingDataLastPrice = NaN;
        if (cashItemSeries.CashItem.HistoryType === HistoryType.LAST && cashItemSeries.CashItem.Count() > 0) {
            tradingDataLastPrice = cashItemSeries.CashItem.GetByType(cashItemSeries.CashItem.Count() - 1, PriceType.Close);
        } else {
            tradingDataLastPrice = instrument.Level1.GetLastPrice(acc);
        }

        const dataType = this.chart.cashItemSeriesSettings.DataType;
        const lastValueClipRect = this.chart.mainWindow.rightYScaleRenderer.Rectangle;

        const mainClipRect = window.ClientRectangle;
        const mainClipRectR = mainClipRect.X + mainClipRect.Width;

        gr.save();
        gr.beginPath();
        gr.rect(
            mainClipRect.X,
            mainClipRect.Y,
            lastValueClipRect.X + lastValueClipRect.Width - mainClipRect.X,
            lastValueClipRect.Y + lastValueClipRect.Height - mainClipRect.Y
        );
        gr.clip();

        if (this.PriceIndicatorDrawingType !== TercaraChartPriceIndicatorType.None) {
            const tickPeriod = cashItemSeries.CashItem.FPeriod === Periods.TIC;
            const bidPrice = cashItemSeries.CashItem.HistoryType !== HistoryType.ASK;
            const tradePrice = cashItemSeries.CashItem.HistoryType === HistoryType.LAST;

            let curPriceValue = 0;
            let invertCurPriceValue = 0;

            let spreadBid = 0;
            let spreadAsk = 0;
            let spreadLast = 0;
            let spreadBidY = 0;
            let spreadAskY = 0;
            let spreadLastY = 0;

            const pc = window.PointsConverter;

            spreadBid = tradingDataBid;
            spreadAsk = tradingDataAsk;
            spreadBidY = pc.GetScreenY(spreadBid);
            spreadAskY = pc.GetScreenY(spreadAsk);

            spreadLast = tradingDataLastPrice;
            spreadLastY = pc.GetScreenY(spreadLast);

            let spreadlastFormated = '';
            let formatcurPriceValue = '';
            let invertFormatcurPriceValue = '';

            spreadBid = tradingDataBid;
            spreadAsk = tradingDataAsk;
            spreadBidY = pc.GetScreenY(spreadBid);
            spreadAskY = pc.GetScreenY(spreadAsk);

            spreadLast = tradingDataLastPrice;
            spreadLastY = pc.GetScreenY(spreadLast);

            if (this.percentView) {
                spreadlastFormated = PriceFormatter.formatPrice(spreadLast, 2) + ' %';
            } else {
                spreadlastFormated = this.withAsset && acc ? acc.formatPrice(spreadLast) : instrument.formatPrice(spreadLast);
            }

            curPriceValue = bidPrice ? spreadBid : spreadAsk;
            invertCurPriceValue = bidPrice ? spreadAsk : spreadBid;

            formatcurPriceValue = instrument.formatPrice(curPriceValue);
            invertFormatcurPriceValue = instrument.formatPrice(invertCurPriceValue);

            //   if (this.PriceIndicatorDrawingType !== TercaraChartPriceIndicatorType.None) {
            if (!tradePrice) {
                if (tickPeriod) {
                    p.DrawPointers.push(new DrawPointer(DrawPointerTypeEnum.BidAsk, curPriceValue, bidPrice ? this.bidBrush : this.askBrush, formatcurPriceValue));
                    p.DrawPointers.push(new DrawPointer(DrawPointerTypeEnum.BidAsk, invertCurPriceValue, bidPrice ? this.askBrush : this.bidBrush, invertFormatcurPriceValue));
                } else {
                    if (!isNaN(curPriceValue)) { p.DrawPointers.push(new DrawPointer(DrawPointerTypeEnum.BidAsk, curPriceValue, bidPrice ? this.bidBrush : this.askBrush, formatcurPriceValue)); }
                }
            } else {
                curPriceValue = spreadLast;
                if (this.PriceIndicatorDrawingType === TercaraChartPriceIndicatorType.ScaleMarkerWithLine) { gr.DrawLine(this.lastPen, mainClipRect.X + 2, spreadLastY, mainClipRectR - 1, spreadLastY); }

                p.DrawPointers.push(new DrawPointer(DrawPointerTypeEnum.BidAsk, spreadLast, this.lastBrush, spreadlastFormated));
            }

            if (!tickPeriod) {
                let bidLineIsAlreadyDrawn = false;
                let askLineIsAlreadyDrawn = false;
                if (!tradePrice && this.PriceIndicatorDrawingType === TercaraChartPriceIndicatorType.ScaleMarkerWithLine) {
                    if (bidPrice && !isNaN(spreadBid)) {
                        gr.DrawLine(this.bidPen, mainClipRect.X + 2, spreadBidY, mainClipRectR - 1, spreadBidY);
                        bidLineIsAlreadyDrawn = true;
                    } else if (!isNaN(spreadAsk)) {
                        gr.DrawLine(this.askPen, mainClipRect.X + 2, spreadAskY, mainClipRectR - 1, spreadAskY);
                        askLineIsAlreadyDrawn = true;
                    }
                }

                if (this.TerceraChartSpreadType == TerceraChartSpreadType.LinesWithPrices || this.TerceraChartSpreadType == TerceraChartSpreadType.Lines) {
                    if (!bidLineIsAlreadyDrawn && !isNaN(spreadBid)) { gr.DrawLine(this.bidPen, mainClipRect.X + 2, spreadBidY, mainClipRectR - 1, spreadBidY); }
                    if (!askLineIsAlreadyDrawn && !isNaN(spreadAsk)) { gr.DrawLine(this.askPen, mainClipRect.X + 2, spreadAskY, mainClipRectR - 1, spreadAskY); }
                }

                if (this.TerceraChartSpreadType === TerceraChartSpreadType.Area && !isNaN(spreadBid) && !isNaN(spreadAsk)) {
                    if (spreadAskY > spreadBidY) { gr.FillRect(this.SpreadIncorrectBackBrush, mainClipRect.X + 1, spreadBidY, mainClipRect.Width - 1, spreadAskY - spreadBidY); } else { gr.FillRect(this.spreadBrush, mainClipRect.X + 1, spreadAskY, mainClipRect.Width - 1, spreadBidY - spreadAskY); }
                }

                if (this.TerceraChartSpreadType === TerceraChartSpreadType.LinesWithPrices) {
                    let priceText = invertFormatcurPriceValue;
                    const PriceScaleFont = this.PriceScaleFont;
                    const askBrush = this.askBrush;
                    const bidBrush = this.bidBrush;

                    const value = bidPrice ? spreadAsk : spreadBid;

                    if (!isNaN(value)) {
                        gr.DrawString(
                            priceText,
                            PriceScaleFont,
                            bidPrice ? askBrush : bidBrush,
                            mainClipRectR - gr.GetTextWidth(priceText, PriceScaleFont),
                            (bidPrice ? spreadAskY : spreadBidY) + (bidPrice ? -(PriceScaleFont.Height + 2) : 2)
                        );
                    }

                    if (tradePrice) {
                        priceText = this.chart.cashItemSeriesSettings.DataType === TerceraChartCashItemSeriesDataType.Absolute
                            ? instrument.formatPrice(bidPrice ? spreadBid : spreadAsk)
                            : (bidPrice ? spreadBid : spreadAsk).toFixed(2) + '%';

                        gr.DrawString(
                            priceText,
                            PriceScaleFont,
                            bidPrice ? bidBrush : askBrush,
                            mainClipRectR - gr.GetTextWidth(priceText, PriceScaleFont),
                            (bidPrice ? spreadBidY : spreadAskY) + (bidPrice ? 2 : -(PriceScaleFont.Height + 2))
                        );
                    }
                }
                // }
            }
        }

        gr.restore();
    }

    ThemeChanged () {
        this.spreadBrush = new SolidBrush(Color.FromArgb(40, ThemeManager.CurrentTheme.Chart_TimeScaleCursorBackColor));
        this.SpreadIncorrectBackBrush = ThemeManager.CurrentTheme.Chart_SpreadIncorrectBackBrush;
        this.askPen = new Pen(ThemeManager.CurrentTheme.SellColor);
        this.bidPen = new Pen(ThemeManager.CurrentTheme.BuyColor);
        this.lastPen = new Pen(ThemeManager.CurrentTheme.Chart_lastPrice_LastIndicatorColor);
        this.askBrush = new SolidBrush(ThemeManager.CurrentTheme.SellColor);
        this.bidBrush = new SolidBrush(ThemeManager.CurrentTheme.BuyColor);
        this.lastBrush = new SolidBrush(ThemeManager.CurrentTheme.Chart_lastPrice_LastIndicatorColor);
    }

    public Properties () {
        const properties: any[] = [];
        let prop: DynProperty | null = null;
        let SeparatorGroup: string | null = null;

        const state = this.chart.TerceraChartActionProcessor.GetTerceraChartActionState(
            TerceraChartAction.Create(TerceraChartActionEnum.PriceIndicator)
        );

        if (!state.Visible) {
            return properties;
        }

        SeparatorGroup = '#4#' + Resources.getResource('property.SeparatorGroup.HighlightPrices');

        prop = new DynProperty('PriceIndicatorDrawingType', this.PriceIndicatorDrawingType, DynProperty.COMBOBOX_COMBOITEM, DynProperty.PRICE_SCALE_GROUP);
        prop.sortIndex = 10;
        prop.objectVariants = [
            {
                text: Resources.getResource('property.PriceIndicatorDrawingType.ScaleMarker'),
                value: TercaraChartPriceIndicatorType.ScaleMarker
            },
            {
                text: Resources.getResource('property.PriceIndicatorDrawingType.ScaleMarkerWithLine'),
                value: TercaraChartPriceIndicatorType.ScaleMarkerWithLine
            },
            {
                text: Resources.getResource('property.PriceIndicatorDrawingType.None'),
                value: TercaraChartPriceIndicatorType.None
            }
        ];
        prop.COMBOBOX_TYPE = DynProperty.INTEGER;
        prop.separatorGroup = SeparatorGroup;
        properties.push(prop);

        prop = new DynProperty('lastPrice_BidIndicatorColor', this.LastPrice_BidIndicatorColor, DynProperty.COLOR, DynProperty.PRICE_SCALE_GROUP);
        prop.separatorGroup = SeparatorGroup;
        prop.sortIndex = 40;
        properties.push(prop);

        prop = new DynProperty('lastPrice_AskIndicatorColor', this.LastPrice_AskIndicatorColor, DynProperty.COLOR, DynProperty.PRICE_SCALE_GROUP);
        prop.separatorGroup = SeparatorGroup;
        prop.sortIndex = 20;
        properties.push(prop);

        prop = new DynProperty('lastPrice_LastIndicatorColor', this.LastPrice_LastIndicatorColor, DynProperty.COLOR, DynProperty.PRICE_SCALE_GROUP);
        prop.separatorGroup = SeparatorGroup;
        prop.sortIndex = 30;
        properties.push(prop);

        prop = new DynProperty('lastPrice_SpreadIndicatorColor', this.LastPrice_SpreadIndicatorColor, DynProperty.COLOR, DynProperty.PRICE_SCALE_GROUP);
        prop.separatorGroup = SeparatorGroup;
        prop.enabilityValue = TerceraChartSpreadType.Area;
        prop.enabled = this.TerceraChartSpreadType !== TerceraChartSpreadType.None;
        prop.sortIndex = 44;
        properties.push(prop);

        prop = new DynProperty('SpreadType', this.TerceraChartSpreadType, DynProperty.COMBOBOX_COMBOITEM, DynProperty.PRICE_SCALE_GROUP);
        prop.sortIndex = 42;
        // prop.inverse = true;
        prop.assignedProperty = ['lastPrice_SpreadIndicatorColor'];
        prop.objectVariants = [
            { text: Resources.getResource('property.SpreadType.None'), value: TerceraChartSpreadType.None },
            { text: Resources.getResource('property.SpreadType.Area'), value: TerceraChartSpreadType.Area },
            { text: Resources.getResource('property.SpreadType.Line'), value: TerceraChartSpreadType.Lines },
            { text: Resources.getResource('property.SpreadType.LineWithLabel'), value: TerceraChartSpreadType.LinesWithPrices }
        ];
        prop.COMBOBOX_TYPE = DynProperty.INTEGER;
        prop.separatorGroup = SeparatorGroup;
        properties.push(prop);

        return properties;
    }

    public callBack (properties: any[]) {
    // Apply property values from the provided properties array
        let dp = DynProperty.getPropertyByName(properties, 'PriceIndicatorDrawingType');
        if (dp) this.PriceIndicatorDrawingType = dp.value;

        dp = DynProperty.getPropertyByName(properties, 'SpreadType');
        if (dp) this.TerceraChartSpreadType = dp.value;

        dp = DynProperty.getPropertyByName(properties, 'lastPrice_BidIndicatorColor');
        if (dp) this.LastPrice_BidIndicatorColor = dp.value;

        dp = DynProperty.getPropertyByName(properties, 'lastPrice_AskIndicatorColor');
        if (dp) this.LastPrice_AskIndicatorColor = dp.value;

        dp = DynProperty.getPropertyByName(properties, 'lastPrice_LastIndicatorColor');
        if (dp) this.LastPrice_LastIndicatorColor = dp.value;

        dp = DynProperty.getPropertyByName(properties, 'lastPrice_SpreadIndicatorColor');
        if (dp) this.LastPrice_SpreadIndicatorColor = dp.value;
    }
}

// SPREAD INDICATOR
export enum TercaraChartPriceIndicatorType { ScaleMarker, ScaleMarkerWithLine, None };
