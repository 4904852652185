// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DPBoundToControlTemplate } from "../../templates.js"
import { DynPropertyControl } from "../elements/DynPropertyControl.js"

// TODO. Add 'set' functions to 'get-only' computed properties.
export let DPBoundToControl = DynPropertyControl.extend({
    template: DPBoundToControlTemplate,
    data: function ()
    {
        return {
            boundToItem: null
        }
    }
})

DPBoundToControl.prototype.oninit = function ()
{
    DynPropertyControl.prototype.oninit.call(this)
    this.observe('boundToItem', this.onBoundToItem)
}

DPBoundToControl.prototype.onBoundToItem = function (item)
{
    if (!item) return

    this.set('dp.value', item.value)
}