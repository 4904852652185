// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts";
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";
import { HtmlScrollXY } from "../../Commons/HtmlScroll.js";
import { EntitlementSubscribeDocumentScreenTemplate } from "../../templates.js";
import { TerceraWindowBase } from "./TerceraWindowBase.js";
import { entitlementSubscribeDocumentScreenHandler } from "../../Utils/AppHandlers.js";
import { EntitlementManager } from "../../Commons/cache/Entitlement/EntitlementManager.ts";


export let EntitlementSubscribeDocumentScreen = TerceraWindowBase.extend({
    data: function ()
    {
        return {
            movable: true,
            resizable: true,
            showHeader: true,
            showFooter: false,
            focused: true,

            width: 650,
            height: 517,
            minWidth: 650,
            minHeight: 517,
            zIndex: 290,
            progressValue: 0,
            heightContent: 0,

            header: "",
            text_top: "",
            text_middle: "",
            lastindex: 0,
            lengthindex: null,
            canEnabledBTN: false,
            btnTooltip: ""
        };
    },
    ProductID: null,
    DocumentArray: new Array(),
    doneWasClicked: false,
    needSendRequestOnDoneClick: true,
    customDisposeHandler: null,
    partials: { bodyPartial: EntitlementSubscribeDocumentScreenTemplate }
});

EntitlementSubscribeDocumentScreen.prototype.getType = function () { return 'EntitlementSubscribeDocumentScreen' };

EntitlementSubscribeDocumentScreen.prototype.oncomplete = function ()
{
    TerceraWindowBase.prototype.oncomplete.apply(this);

    this.OnResize.Subscribe(this.resizingProcess, this);

    this.on("nextClick", this.nextClick)
    this.on("backClick", this.backClick)
    this.on("doneClick", this.doneClick)
    this.observe('text_middle_obs', function (newValue)
    {
        this.set({ text_middle: newValue })
    })

    this.addScroll();
    this.localize();
    this.updateHeader();
    this.updateProgressBar();
    this.center();
    this.getContentDocument();
    this.getInputDocument();
};

EntitlementSubscribeDocumentScreen.prototype.resizingProcess = function ()
{
    let width = this.get("width");
    let height = this.get("height");
    let middle_height = height - 25 - 48 - 97; //header - js-products-document-top - js-products-document-bottom

    this.set({
        widthPanel: width,
        heightPanel: middle_height
    });

    this.updateProgressBar();
};

EntitlementSubscribeDocumentScreen.prototype.setDocumentParam = function ()
{
    let arr = this.DocumentArray;
    let index = this.get("lastindex");

    this.set({
        lengthindex: arr.length - 1,
        text_top: arr[index].Name,
        text_middle_obs: arr[index].Content
    });
};

EntitlementSubscribeDocumentScreen.prototype.nextClick = function ()
{
    let arr = this.DocumentArray;
    let index = this.get("lastindex");
    ++index;

    this.SaveDocument();
    this.set({
        lastindex: index,
        text_top: arr[index].Name,
        text_middle_obs: arr[index].Content
    });

    this.getContentDocument();
    this.getInputDocument();
    this.updateHeader();
    this.updateProgressBar();
    this.resetScroll();
};

EntitlementSubscribeDocumentScreen.prototype.backClick = function ()
{
    let arr = this.DocumentArray;
    let index = this.get("lastindex");
    index--;

    this.SaveDocument();
    this.set({
        lastindex: index,
        text_top: arr[index].Name,
        text_middle_obs: arr[index].Content
    });

    this.getContentDocument();
    this.getInputDocument();
    this.updateHeader();
    this.updateProgressBar();
    this.resetScroll();
};

EntitlementSubscribeDocumentScreen.prototype.doneClick = function ()
{
    this.doneWasClicked = true
    this.SaveDocument();

    if (this.needSendRequestOnDoneClick)
        EntitlementManager.ProductSubscribeRequest(this.ProductID, this.DocumentArray);
    this.close();
};

EntitlementSubscribeDocumentScreen.prototype.dispose = function ()
{
    if (this.customDisposeHandler)
        this.customDisposeHandler(this.doneWasClicked);

    this.OnResize.UnSubscribe(this.resizingProcess, this);

    TerceraWindowBase.prototype.dispose.apply(this);
};

EntitlementSubscribeDocumentScreen.prototype.SaveDocument = function ()
{
    let arr = this.DocumentArray;
    let index = this.get("lastindex");

    arr[index].Content = this.Controls.windowPanelBody.el.getElementsByClassName("js-label-document-middle")[0].innerHTML;
};

EntitlementSubscribeDocumentScreen.prototype.getContentDocument = function ()
{
    let arr = this.DocumentArray;
    let index = this.get("lastindex");
    let sumH = 0;

    if (arr[index].Content.indexOf("<style>") != -1)
    {
        let iframe = document.createElement("iframe");
        document.body.appendChild(iframe);
        let doc = iframe.contentDocument;
        doc.write(arr[index].Content);
        doc.close();
        let children = doc.body.children;

        for (let i = 0; i < children.length; i++)
        {
            let child = children[i];
            sumH += child.clientHeight;
        }

        document.body.removeChild(iframe);
    }

    this.set({ heightContent: sumH });
};

EntitlementSubscribeDocumentScreen.prototype.addScroll = function ()
{
    HtmlScrollXY.addScroll(this.find(".js-products-document-middle"), 'xy');
};

EntitlementSubscribeDocumentScreen.prototype.removeScroll = function ()
{
    HtmlScrollXY.removeScroll(this.find(".js-products-document-middle"));
};

EntitlementSubscribeDocumentScreen.prototype.resetScroll = function ()
{
    this.removeScroll();
    this.addScroll();
};

EntitlementSubscribeDocumentScreen.prototype.checkNextBTNEnability = function (radio, text, checkbox)
{
    let keys = Object.keys(radio);
    let r = true;
    for (let i = 0; i < keys.length; i++)
    {
        let key = keys[i];
        let arr = radio[key];
        let s_r = false;
        for (let j = 0; j < arr.length; j++)
        {
            if (arr[j].checked)
            {
                s_r = true;
                break;
            }
        }
        r = r && s_r
    }

    let t = true;
    for (let i = 0; i < text.length; i++)
    {
        let value = text[i].value;
        t = t && !!value;
    }

    let c = true;
    for (let i = 0; i < checkbox.length; i++)
    {
        c = c && checkbox[i].checked;
    }

    let enabled = r && t && c;
    let tooltip = this.getBTNTooltip(enabled);

    this.set({
        canEnabledBTN: enabled,
        btnTooltip: tooltip
    })
};

EntitlementSubscribeDocumentScreen.prototype.getBTNTooltip = function (enabled)
{
    if (!enabled)
        return Resources.getResource('screen.documets.Button.ToolTip')
    else
        return ""
};

EntitlementSubscribeDocumentScreen.prototype.getInputDocument = function ()
{
    let me = this;
    let rootElement = this.Controls.windowPanelBody.el.getElementsByClassName("js-label-document-middle")[0];
    if (!rootElement)
        return

    let imputDocument = rootElement.getElementsByTagName("input"); // найти инпуты все
    let comboBoxDocument = rootElement.getElementsByTagName("select"); // найти комбо-боксы
    let linkDocument = rootElement.getElementsByTagName("a"); // найти линки
    let radioInput = new Object();
    let textInput = new Array();
    let checkInput = new Array();

    for (let i = 0; i < imputDocument.length; i++)
    {
        let ipD = imputDocument[i]
        if (ipD.type === "radio")
        {
            ipD.oninput = function ()
            {
                let arr = radioInput[this.name] || [];
                for (i = 0; i < arr.length; i++)
                    arr[i].removeAttribute("checked")

                this.setAttribute("checked", "")
                me.checkNextBTNEnability(radioInput, textInput, checkInput)
            }

            if (!radioInput[ipD.name])
                radioInput[ipD.name] = [];

            radioInput[ipD.name].push(ipD)
        }

        if (ipD.type === "text")
        {
            ipD.oninput = function ()
            {
                this.setAttribute("value", this.value)
                me.checkNextBTNEnability(radioInput, textInput, checkInput)
            }

            textInput.push(ipD)
        }

        if (ipD.type === "checkbox")
        {
            ipD.oninput = function ()
            {
                this.setAttribute("checked", this.checked)
                me.checkNextBTNEnability(radioInput, textInput, checkInput)
            }

            checkInput.push(ipD)
        }
    }

    for (let i = 0; i < comboBoxDocument.length; i++)
    {
        let comboBoxD = comboBoxDocument[i];
        comboBoxD.onchange = function ()
        {
            let option = this.options;
            for (i = 0; i < option.length; i++)
                option[i].removeAttribute("selected")

            this.options[this.selectedIndex].setAttribute("selected", "selected")
        }
    }

    for (let i = 0; i < linkDocument.length; i++)
    {
        let linkD = linkDocument[i];
        linkD.setAttribute("target", "_blank");
        linkD.setAttribute("rel", "noopener noreferrer");
    }

    this.checkNextBTNEnability(radioInput, textInput, checkInput)
};

EntitlementSubscribeDocumentScreen.prototype.updateHeader = function ()
{
    let header = Resources.getResource('screen.documets.Header');
    let currentDoc = this.get("lastindex") + 1;
    let commonDoc = this.DocumentArray.length;
    let createText = header + " " + currentDoc + "/" + commonDoc;

    this.set("header", createText);
};

EntitlementSubscribeDocumentScreen.prototype.updateProgressBar = function ()
{
    let len = this.DocumentArray.length;
    let wBar = this.get("width") - 50;
    let indent = Math.floor(wBar / (len - 1));

    let arr = new Array();
    for (let i = 0; i < len; i++)
    {
        let left = indent * i;
        arr.push(left)
    }

    let index = this.get("lastindex");
    let value = arr[index];
    this.set({
        paramLeft: arr,
        progressValue: value
    })
};

EntitlementSubscribeDocumentScreen.prototype.localize = function ()
{
    this.set({
        nextButtonText: Resources.getResource('pageNavigation.nextButton'),
        backButtonText: Resources.getResource('pageNavigation.backButton'),
        doneButtonText: Resources.getResource('pageNavigation.doneButton'),
    });
};

EntitlementSubscribeDocumentScreen.Show = function (id, data)
{
    let DocumentScreen = new EntitlementSubscribeDocumentScreen();
    DocumentScreen.DocumentArray = data;
    DocumentScreen.ProductID = id;
    DocumentScreen.setDocumentParam();

    MainWindowManager.MainWindow.addControl(DocumentScreen);
    return DocumentScreen;
};

let scrHanl = entitlementSubscribeDocumentScreenHandler;
scrHanl.Show = EntitlementSubscribeDocumentScreen.Show;