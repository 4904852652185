import { ServerMessaeSide } from './ServerMessageSide';

// public static Action<ServerMessaeContainer> ServerMessage;
export class ServerMessaeContainer {
    /// <summary>
    /// /дэйт-тайм мессаджа
    /// </summary>
    public dateTime: any = null;

    /// <summary>
    /// /айди (код) мессаджа
    /// </summary>
    public MessageCode: any = null;
    public MessageName: string = '';
    /// <summary>
    /// Содержимое мессаджа
    /// </summary>
    public Text: string = '';

    /// <summary>
    /// Содержимое мессаджа в читаемом виде
    /// </summary>
    public TextNew: any = null;

    /// <summary>
    /// Содержимое мессаджа в читаемом виде
    /// </summary>
    public DataForTreeView: any = null;

    /// <summary>
    /// Адрес ноды подключения (для кластера)
    /// </summary>
    public NodeAddress = '';

    public Side = ServerMessaeSide.Input;

    public Length: any = null;

    public Mode: any = null;
    public StringMode = '';

    /// <summary>
    /// Сессия (айди подключения)
    /// Может быть пустой (пока сервер не подключил меня)
    /// </summary>
    public Session = '';

    public IsOutgoingMSG: any = null;
}
