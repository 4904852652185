// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

export class ChartMath {
/// <summary>
/// Подсчет КВАДРАТА расстояния от точки до ОТРЕЗКА
/// </summary>
/// <param name="p1">1я точка отрезка</param>
/// <param name="p2">2я точка отрезка</param>
/// <param name="x">точка</param>
/// <param name="y">точка</param>
/// <returns></returns>
    public static CalcDistanceSqrFromPointToSection (p0, p1, x: number, y: number): number {
    // см. CalcDistanceFromPointToSection
        const dx = x - p0[0];
        const dy = y - p0[1];

        const c1 = (p1[0] - p0[0]) * dx + (p1[1] - p0[1]) * dy;
        if (c1 <= 0) {
            return dx * dx + dy * dy;
        }

        const c2 = (p1[0] - p0[0]) * (p1[0] - p0[0]) + (p1[1] - p0[1]) * (p1[1] - p0[1]);
        if (c2 <= c1) {
            return (x - p1[0]) * (x - p1[0]) + (y - p1[1]) * (y - p1[1]);
        }

        return ChartMath.CalcDistanceSqrFromPointToLine(p0, p1, x, y);
    }

    /// <summary>
    /// Подсчет КВАДРАТА расстояния от точки до прямой
    /// </summary>
    /// <param name="p1">1я точка прямой</param>
    /// <param name="p2">2я точка прямой</param>
    /// <param name="x">точка</param>
    /// <param name="y">точка</param>
    /// <returns></returns>
    public static CalcDistanceSqrFromPointToLine (p1, p2, x: number, y: number): number {
    // см. CalcDistanceFromPointToLine
        const A = (p2[1] - p1[1]);
        const B = -(p2[0] - p1[0]);
        const d = (A * (x - p1[0]) + B * (y - p1[1]));
        return (d * d) / Math.abs(A * A + B * B);
    }

    public static CalcDistanceFromPointToPoint (p1, p2): number {
        const yDelta = p1.Y - p2.Y;
        const xDelta = p1.X - p2.X;

        return Math.sqrt(yDelta * yDelta + xDelta * xDelta);
    }

    /// <summary>
    /// Подсчет расстояния от точки до ОТРЕЗКА
    /// </summary>
    /// <param name="p1">1я точка отрезка</param>
    /// <param name="p2">2я точка отрезка</param>
    /// <param name="x">точка</param>
    /// <param name="y">точка</param>
    /// <returns></returns>
    public static CalcDistanceFromPointToSection (p0, p1, x: number, y: number): number {
    // определение расстояния до отрезка можно сделать
    // - подсчитав расстояние до прямой и до каждой точки, и выбрав потом одно. Не понравилось, так как идет тупо грубый перебор

        // - посложнее, но меньше операций sqrt:
        // беру векторы
        // v = p0 - p1
        // и
        // w = P - P0, и считаю скал.произведение. Его знак даст нам представление об угле между ними,
        // по которому мы судим о взаимном расположении прямых

        // v*w
        const dx = x - p0[0];
        const dy = y - p0[1];

        const c1 = (p1[0] - p0[0]) * dx + (p1[1] - p0[1]) * dy;

        if (c1 <= 0)
        // перпеникуляр находится ближе к P0
        // нужно просто взять расстояние между {x;y} и p0
        {
            return Math.sqrt(dx * dx + dy * dy);
        }

        // v*v
        const c2 = (p1[0] - p0[0]) * (p1[0] - p0[0]) + (p1[1] - p0[1]) * (p1[1] - p0[1]);

        if (c2 <= c1)
        // перпеникуляр находится ближе к P1
        // нужно просто взять расстояние между {x;y} и p1
        {
            return Math.sqrt((x - p1[0]) * (x - p1[0]) + (y - p1[1]) * (y - p1[1]));
        }

        // вычисляем перпендикуляр
        return ChartMath.CalcDistanceFromPointToLine(p0, p1, x, y);
    }

    /// <summary>
    /// Подсчет расстояния от точки до прямой
    /// </summary>
    /// <param name="p1">1я точка прямой</param>
    /// <param name="p2">2я точка прямой</param>
    /// <param name="x">точка</param>
    /// <param name="y">точка</param>
    /// <returns></returns>
    public static CalcDistanceFromPointToLine (p1, p2, x: number, y: number): number {
    // для прямой вида Ax + By + C = 0 рассточние до точки {x0;y0} определяется как
    // D = (Ax0 + By0 + C) / sqrt(A*A + B*B)
    // прямая задана как (x - x1)/(x2 - x1) = (y - y1)/(y2 - y1)
    // приведя прямую к каноническому виду, получим:

        // ФОРИМУЛА1
        /* double A = 1D / (p2[0] - p1[0]);
    double B = -1D / (p2[1] - p1[1]);
    return Math.Abs((A * (x - p1[0]) + B * (y - p1[1]))) / Math.Sqrt(A * A + B * B); */

        // ФОРМУЛА2
        // найдена в сети, имеет меньше делений, т.е. быстрее
        /* return Math.Abs((p0[1] - p1[1]) * x + (p1[0] - p0[0]) * y + p0[0] * p1[1] - p0[1] * p1[0]) / Math.Sqrt((p1[0] - p0[0]) * (p1[0] - p0[0]) + (p1[1] - p0[1]) * (p1[1] - p0[1])); */

        // ФОРМУЛА3
        // получена после ухода от деления в формуле1
        /* double A = (p2[1] - p1[1]);
    double B = -(p2[0] - p1[0]);
    return Math.Abs((A * (x - p1[0]) + B * (y - p1[1]))) / Math.Sqrt(A * A + B * B);
    ИЛИ сразу подставив:
    return Math.Abs(((p2[1] - p1[1]) * (x - p1[0]) - (p2[0] - p1[0]) * (y - p1[1]))) / Math.Sqrt((p2[1] - p1[1]) * (p2[1] - p1[1]) + (p2[0] - p1[0]) * (p2[0] - p1[0])); */

        const A = (p2[1] - p1[1]);
        const B = -(p2[0] - p1[0]);
        return Math.abs((A * (x - p1[0]) + B * (y - p1[1]))) / Math.sqrt(A * A + B * B);
    }
}
