// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts";
import { HtmlScroll } from "../../Commons/HtmlScroll.js";
import { ControlsUtils } from "../UtilsClasses/ControlsUtils.ts";
import { NotificationsPanelTemplate } from "../../templates.js";
import { PanelNames } from "../UtilsClasses/FactoryConstants.ts";
import { ApplicationPanelNew } from "./ApplicationPanelNew.js";
import { UrlUtils } from "../../Utils/UrlUtils.ts";
import { BrokerMessageResponseType } from "../../Utils/Notifications/BrokerMessageResponseType.ts";

import { HistoricalBM } from "../../Commons/cache/HistoricalBM.ts";
import { DataCache } from "../../Commons/DataCache.ts";
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";
import { LocalStorage } from "../../Commons/LocalStorage.ts";

export let NotificationsPanel = ApplicationPanelNew.extend({
    data: function ()
    {
        return {
            name: PanelNames.NotificationsPanel + this._guid,   // думаю вона може бути лише в єдиному екземплярі але на всяк +guid
            width: 798, // +2px for border
            height: 498,
            minWidth: 798,
            minHeight: 498,
            dockablePanel: false,
            resizable: true,
            showHeader: true,

            allMessages: null,      // [] of HistoricalBrokerMessageInfo
            cards: null,            // [] of HistoricalBM
            categories: null,       // [] - all
            unreadByCategory: null, // [] - кількість непрочитаних за категоріями (значення у лічильниках у тулбарі) в тому ж порядку 
            visibleCatIndex: null,  // int - first current index of categories that toolbar start with 
            visibleCatNum: null,    // int - number of categories visible in toolbar at the moment
            categoriesColor: null,  // {'categories[i].name': categories[i].colorHEXstr}
            selectedCategory: null, // int
            selectedCard: null,     // int

            customButtonWidth: 280,
            customButtonLeft: 110,
            customButtonTopMargin: 385,

            canLinkByAccount: false,

            noSelectedCardText: null,
            noNotificationsText: null,
            style_addition_header: 'js-PropertySetupScreen-AdditionalHeader'
        }
    },
    partials: { bodyPartial: NotificationsPanelTemplate },
    headerLocaleKey: 'panel.Notifications',
    categoriesNum: null,            // кількість категорій
    onResizeTimeoutHolder: null,
    onResizeTimeoutDelay: 10,       // ms before setTimeout
    customButtonsClickEventHandlers: [],
    NeedCalculateRowCount: false
})

NotificationsPanel.prototype.getType = function () { return PanelNames.NotificationsPanel };

NotificationsPanel.prototype.oninit = function ()
{
    ApplicationPanelNew.prototype.oninit.apply(this)

    this.observe('categories', this.onCategoriesChanged)

    this.on('categoryClick', this.categoryClick)
    this.on('cardClick', this.cardClick)
    this.on('toolbarLeftArrowClick', this.toolbarArrowClick.bind(this, -1))
    this.on('toolbarRightArrowClick', this.toolbarArrowClick.bind(this, +1))
}

NotificationsPanel.prototype.oncomplete = function ()
{
    ApplicationPanelNew.prototype.oncomplete.apply(this)

    this.eventsSubscribe()
    // this.center()

    this.GetBMHistory()

    if (!MainWindowManager.MainWindow.notificationsPanel)     // если панель создается из сеттингов todo подумать как сделать лучше
        MainWindowManager.MainWindow.notificationsPanel = this
    else
        this.center()
}

NotificationsPanel.prototype.eventsSubscribe = function ()
{

    this.OnResize.Subscribe(this.onResize, this)
    DataCache.OnProcessBrokerMessage.Subscribe(this.addBrokerMessage, this)
}

NotificationsPanel.prototype.eventsUnSubscribe = function ()
{

    this.OnResize.UnSubscribe(this.onResize, this)
    DataCache.OnProcessBrokerMessage.UnSubscribe(this.addBrokerMessage, this)
}

NotificationsPanel.prototype.dispose = function ()
{
    this.eventsUnSubscribe()

    ApplicationPanelNew.prototype.dispose.apply(this);
}

NotificationsPanel.prototype.localize = function ()
{
    ApplicationPanelNew.prototype.localize.apply(this);

    this.set({
        noSelectedCardText: Resources.getResource('panel.Notifications.noSelCardTxt'),
        noNotificationsText: Resources.getResource('panel.Notifications.noNotificationsText')
    })
}

NotificationsPanel.prototype.GetBMHistory = function ()
{
    let me = this,
        userID = DataCache.getUserID()

    DataCache.SendBrokerMessageHistoryRequest(userID).then(function (msg)
    {
        this.createAllMessages(msg)
    }.bind(me))
}

NotificationsPanel.prototype.AddBMHistory = function (BMHist)
{
    if (!BMHist)
        return

    let allMsgs = this.get('allMessages')
    if (!allMsgs)
        allMsgs = []    // #110909

    this.setCardLineHeight([BMHist])    // #111241+

    allMsgs = [BMHist].concat(allMsgs)

    this.set('allMessages', allMsgs).then(() => { this.createCategories() })
}

NotificationsPanel.prototype.onResize = function ()
{
    if (this.onResizeTimeoutHolder)
        clearTimeout(this.onResizeTimeoutHolder)

    this.onResizeTimeoutHolder = setTimeout(() =>
    {
        this.updateToolbarCategoriesNum()
        this.updateCustomBtns()
        this.onResizeTimeoutHolder = null
    }, this.onResizeTimeoutDelay)
}

NotificationsPanel.prototype.onCategoriesChanged = function (newValue)
{
    this.categoriesNum = newValue ? newValue.length : null

    this.updateCategoryCounter()
}

NotificationsPanel.prototype.categoryClick = function (event, index)
{
    if (this.get('selectedCategory') !== index)
    {
        this.cardClick(null, null)
        this.selectCategory(index)
    }
}

NotificationsPanel.prototype.updateCustomBtns = function ()
{
    let w = this.get('width'),
        cBtnsW = this.get('customButtonWidth'),
        left = w * NotificationsPanel.LEFT_WIDTH_IN_PERCENT,
        right = w - left,
        btnsLeft = /*left +*/ (right - cBtnsW) / 2

    let cards = this.get('cards'),
        selectedCard = this.get('selectedCard'),
        topMargin = 0

    if (cards && cards[selectedCard] && cards[selectedCard].CustomButtons)
        topMargin = 65 + 35 * cards[selectedCard].CustomButtons.length

    this.set({
        customButtonLeft: btnsLeft,
        customButtonTopMargin: this.get('height') - topMargin
    }).then(function ()
    {
        this.resetScrolls(true)
    }.bind(this))
}

NotificationsPanel.prototype.createCustomButtonsClickEvents = function ()
{
    let clickEvent = function (url, customBtnTitle, clickedIndex)
    {
        UrlUtils.openFixedURL(url)
        this.processResponseCustomButtons(customBtnTitle, clickedIndex)
        // this.close()
    }

    let cards = this.get('cards'),
        selectedCard = this.get('selectedCard')

    this.cancelOldCustomButtonsClickEvent()     // #110916

    let handlers = this.customButtonsClickEventHandlers,
        cbs = cards && cards[selectedCard] ? cards[selectedCard].CustomButtons : null
    if (cbs)
        for (let i = 0; i < cbs.length; i++)
            handlers.push(this.on('onCustomButton' + i + 'Click', clickEvent.bind(this, cbs[i].URL, cbs[i].TitleForResponse, i)))

    // this.resetScrolls(true)
}

NotificationsPanel.prototype.cancelOldCustomButtonsClickEvent = function ()
{
    let handlers = this.customButtonsClickEventHandlers
    if (!handlers) return

    for (let i = 0; i < handlers.length; i++)
        handlers[i].cancel()

    this.customButtonsClickEventHandlers = []
}

NotificationsPanel.prototype.processResponseCustomButtons = function (cBtnTitle, clickedIndex)       // https://tp.traderevolution.com/entity/109652 send BrokerResponseMessage when custom buttons clicked
{
    let cards = this.get('cards')
    if (!cards) return

    let selCard = this.get('selectedCard'),
        card = cards[selCard],
        brokerMsgID = card ? card.ID : null

    let userID = DataCache.getUserID(),
        userName = DataCache.getUserName(),
        value = cBtnTitle.toString()

    DataCache.SendBrokerResponseMessage(brokerMsgID, value, userID, userName, BrokerMessageResponseType.CUSTOM_BTNS)
}

NotificationsPanel.prototype.processWasReadResponse = function (index)  
{
    let cards = this.get('cards'),
        selected = cards ? cards[index] : null

    if (!selected || !selected.IsUnread)
        return

    selected.IsUnread = false
    this.set('cards', cards)

    this.updateCategoryCounter()
    this.prepareCardStyle(index)

    let userID = DataCache.getUserID(),
        userName = DataCache.getUserName(),
        brokerMsgID = selected.ID

    DataCache.SendBrokerResponseMessage(brokerMsgID, '', userID, userName, BrokerMessageResponseType.WAS_READ)
}

NotificationsPanel.prototype.cardClick = function (event, index, ignoreResponse = false)
{
    if (this.get('selectedCard') !== index)
        this.set('selectedCard', index).then(function (index)
        {
            if (!ignoreResponse)
                this.processWasReadResponse(index)
            this.prepareCardStyleAfterSelect()
            this.createCustomButtonsClickEvents()
            this.updateCustomBtns()
            this.cacheSelectedCardNumber(index)
            this.find('iframe').onload = function ()
            {
                let bs = this.contentWindow.document.body.style
                bs.fontFamily = 'Trebuchet MS';
                bs.fontStyle = 'normal';
                bs.color = '#E3E1E2';
            }

        }.bind(this, index))
}

NotificationsPanel.prototype.cacheSelectedCardNumber = function (selectedCardIndex)
{
    let cards = this.get('cards');
    let selected = cards ? cards[selectedCardIndex] : null;

    if (selected)
        LocalStorage.setNotificationPanelSelectedCards(selected.ID);
}

NotificationsPanel.prototype.openCachedSelectedCard = function ()
{
    let selectedCardID = LocalStorage.getNotificationPanelSelectedCards();

    if (selectedCardID)
    {
        let cards = this.get('cards')
        for (let i = 0; i < cards.length; i++)
            if (cards[i].ID == selectedCardID)
            {
                this.cardClick(null, i, true)
                this.scrollToCard(i)
                return
            }
    }
}

NotificationsPanel.prototype.updateCategoryCounter = function ()
{
    let categories = this.get('categories'),
        allMessages = this.get('allMessages'),
        categoriesNum = []

    if (!categories || !allMessages) return

    for (let j = 0; j < categories.length; j++)
    {
        let cat = categories[j],
            cNum = 0

        for (let i = 0; i < allMessages.length; i++)
            if ((allMessages[i].Category == cat.name || j === 0) && allMessages[i].IsUnread)    // у тулбарі в скобках біля категорії показуємо кількість НЕПРОЧИТАНИХ нотіків
                cNum++

        categoriesNum.push(cNum)
    }

    this.set('unreadByCategory', categoriesNum)
}

NotificationsPanel.prototype.selectCategory = function (index)
{
    let categories = this.get('categories')
    if (!categories) return

    let selectedCategory = categories[index],
        selectedCategoryName = selectedCategory.name,
        addAll = index === 0, //selectedCategoryName === 'All',
        allMessages = this.get('allMessages'),
        cards = []

    for (let i = 0; i < allMessages.length; i++)
    {
        let msg = allMessages[i];
        if (addAll || msg.Category == selectedCategoryName)
            cards.push(msg)
    }

    this.set({
        cards: cards,
        selectedCategory: index
    }).then(() => { this.resetScrolls() })
}

NotificationsPanel.prototype.createCategories = function ()
{
    let categories = [{ 'name': 'All', 'colorHEXstr': null, visible: true }],
        allMessages = this.get('allMessages')

    if (!allMessages) return

    let catObj = {}
    let colorSet = [NotificationsPanel.NO_CATEGORY_COLOR]//['#101720'] //<- #110923 // */['#FF2D55', '#FF9500', '#4CD964', '#007AFF', '#EA606F', '#00FFFF', '#FC5BAC', '#33EEAA', '#FF4224', '#5500FF', '#49A191']
    let colorInd = -1
    let hasAnyCardWithoutCategory = false       // чи є BMs без назначеної категорії(продукту)
    for (let i = 0; i < allMessages.length; i++)
    {
        let msg = allMessages[i]
        if (!msg) continue                      // #110909

        let catName = msg.Category              // aka product.Name

        if (!catName)                           // у нотіка нема продукту -> знач в нього нема категорії в тулбарі та сірий колір
            hasAnyCardWithoutCategory = true

        if (catName && !catObj[catName])
        {
            let product = DataCache.EntitlementManager.ProductsCache[msg.ProductID],
                colorInProduct = product ? product.ColorHEX : null,
                validColor = this.isValidHexColor(colorInProduct),
                color = validColor ? colorInProduct : colorSet[++colorInd]

            catObj[catName] = true
            categories.push({ name: catName, colorHEXstr: color, visible: true })

            if (colorInd + 1 == colorSet.length)
                colorInd = -1
        }
    }

    let categoryAllIsVisible = true
    if (categories.length == 2 && !hasAnyCardWithoutCategory)
        categoryAllIsVisible = categories[0].visible = false       // #110919

    this.set('categories', categories).then(() =>
    {
        this.createCategoriesColorObject()
        this.prepareVisibleCategories()

        let selectedCategory = this.get('selectedCategory')
        this.selectCategory(selectedCategory === null ? (categoryAllIsVisible ? 0 : 1) : selectedCategory)          // #110919, #111226

        this.prepareCardStyle()
    })
}

NotificationsPanel.prototype.getToolbarWidth = function ()
{
    let panelWidth = this.get('width'),
        leftWidthConst = 0.4//0.418745

    return panelWidth * leftWidthConst
}

NotificationsPanel.prototype.toolbarArrowClick = function (dx)
{
    let catNum = this.categoriesNum
    if (!catNum)
        return

    let idx = this.get('visibleCatIndex'),
        visibleNum = this.get('visibleCatNum'),
        newVisibleIndex = Math.min(Math.max(0, idx + dx * visibleNum), catNum - 1)

    if (dx < 0)
        this.decreaseToolbar(idx)
    else
        this.set('visibleCatIndex', newVisibleIndex).then(() => { this.updateToolbarCategoriesNum() })
}

NotificationsPanel.prototype.decreaseToolbar = function (lastVisibleCatIndex)
{
    let categories = this.get('categories'),
        width = this.getToolbarWidth(),
        idx = lastVisibleCatIndex || 0,
        curI = idx,
        curW = NotificationsPanel.TOOLBAR_ARROWS_WIDTH

    while (curW < width && curI > 0)
    {
        curI--
        curW += categories[curI] ? NotificationsPanel.GetTextWidth(categories[curI]) : 0
    }

    curI = curI + (curW < width ? 0 : 1)
    let result = Math.max(idx - curI, 1),
        oldCurI = curI
    if (curI + result <= categories.length)
    {
        curI = idx
        while (curW < width && curI < categories.length)
        {
            curW += categories[curI] ? NotificationsPanel.GetTextWidth(categories[curI]) : 0
            if (curW < width)
                result++
            curI++
        }
    }
    this.set({ visibleCatNum: result, visibleCatIndex: oldCurI })
}

NotificationsPanel.prototype.updateToolbarCategoriesNum = function (dx)
{
    let categories = this.get('categories')
    if (!categories) return

    let idx = this.get('visibleCatIndex') || 0,
        width = this.getToolbarWidth(),
        curI = idx,
        curW = NotificationsPanel.TOOLBAR_ARROWS_WIDTH

    while (curW < width && curI < categories.length)
    {
        curW += categories[curI] ? NotificationsPanel.GetTextWidth(categories[curI]) : 0
        curI++
    }

    let result = Math.max(curI - idx + (curW < width ? 0 : -1), 1)
    if (idx + result <= categories.length)
    {
        let oldRes = result
        curI = idx - 1
        while (curW < width && curI >= 0)
        {
            curW += categories[curI] ? NotificationsPanel.GetTextWidth(categories[curI]) : 0

            if (curW < width)
                result++

            curI--
        }
        if (oldRes != result)
            this.set('visibleCatIndex', idx - result + oldRes)
    }
    this.set('visibleCatNum', result)
}

NotificationsPanel.prototype.prepareVisibleCategories = function ()
{
    if (this.get('visibleCatIndex') === null)
        this.set('visibleCatIndex', 0)
    this.updateToolbarCategoriesNum()
}

NotificationsPanel.prototype.createCategoriesColorObject = function ()
{
    let categories = this.get('categories')
    if (!categories) return

    let result = {}
    for (let i = 0; i < categories.length; i++)
    {
        let c = categories[i]
        if (!result[c.name])
            result[c.name] = c.colorHEXstr
    }

    result[''] = NotificationsPanel.NO_CATEGORY_COLOR  // немає прив'язаного продукту

    this.set('categoriesColor', result)
}

NotificationsPanel.prototype.prepareCardStyle = function (index = -1)
{
    let allMessages = this.get('allMessages')
    if (!allMessages) return

    let cc = this.get('categoriesColor')
    for (let i = 0; i < allMessages.length; i++)
    {
        let m = index >= 0 ? this.get('cards')[index] : allMessages[i]

        if (cc[m.Category])
            m.colorHEXstr = cc[m.Category]

        let rgbaStr = this.HEXstrToRGBAstr(m.colorHEXstr),
            // opacity = 'opacity: ' + (m.IsUnread ? '1' : '0.5') + '; ',   //#111225
            borderLeftColor = this.isValidHexColor(m.colorHEXstr) ? 'border-left-color: ' + m.colorHEXstr + '; ' : '',
            borderRightColor = 'border-right-color: ' + '#2A3C53' + '; ',
            background = 'background: linear-gradient(90deg, ' + rgbaStr + ' 0%, rgba(16, 23, 32, 0.2) 100%); ',
            style = /*opacity +*/ borderLeftColor + (m.IsUnread ? background : 'background: #101720;')

        m.cardStyleWithoutSelectedStyle = m.cardStyle = style

        if (index >= 0)
            break
    }

    let needResetScroll = index < 0
    this.set('allMessages', allMessages).then(() =>
    {
        this.set('cards', this.get('cards')).then(() =>
        {
            if (needResetScroll)
            {
                this.resetScrolls()
                this.openCachedSelectedCard()
            }
        })
    })
}

NotificationsPanel.prototype.prepareCardStyleAfterSelect = function ()
{
    let cards = this.get('cards')
    if (!cards) return

    let selectedCard = this.get('selectedCard'),
        selCardColor = NotificationsPanel.SELECTED_CARD_BORDER_COLOR

    for (let i = 0; i < cards.length; i++)
    {
        let m = cards[i],
            addStyle = 'border-right-color: ' + (selectedCard === i ? selCardColor : '#101720') + '; ',
            style = m.cardStyleWithoutSelectedStyle

        if (selectedCard === i)
            addStyle += 'border-top-color: ' + selCardColor + '; ' + 'border-bottom-color: ' + selCardColor + '; '

        m.cardStyle = style + addStyle
    }
    this.set('cards', cards)
}

NotificationsPanel.prototype.setCardLineHeight = function (cards)   // #111241+
{
    if (!cards) return

    for (let i = 0; i < cards.length; i++)
        cards[i].textInLeftLineHeight = 20 //cards[i].Text.indexOf('<p>') == 0 ? 17 : 20    // commented out due to 112820
}

NotificationsPanel.prototype.createAllMessages = function (msg)
{
    if (msg && msg.length)
    {
        msg = msg[0]
        let histBMs = msg.HistoricalBMs

        if (histBMs)
        {       // сортуємо за датою щоб відображати спочатку нові
            histBMs.sort(function (a, b) { return b.TimeStamp.getTime() - a.TimeStamp.getTime() })

            for (let i = 0; i < histBMs.length; i++)
                histBMs[i] = this.createHistoricalBM(histBMs[i]);

            this.setCardLineHeight(histBMs)     // #111241+

            this.set('allMessages', histBMs).then(() => { this.createCategories() })
        }
    }
}

NotificationsPanel.prototype.addScrolls = function (onlyForRightContainer)
{
    if (!onlyForRightContainer)
    {
        let lP = this.find('.cards_container')          // left part with cards
        if (lP)
            HtmlScroll.addScroll(lP)
    }

    let rP = this.find('.right-content')        // right part with opened card
    if (rP)
        HtmlScroll.addScroll(rP)
}

NotificationsPanel.prototype.removeScrolls = function (onlyForRightContainer)
{
    if (!onlyForRightContainer)
    {
        let lP = this.find('.cards_container')          // left part with cards
        if (lP)
            HtmlScroll.removeScroll(lP)
    }

    let rP = this.find('.right-content')        // right part with opened card
    if (rP)
        HtmlScroll.removeScroll(rP)
}

NotificationsPanel.prototype.resetScrolls = function (onlyForRightContainer)
{
    this.removeScrolls(onlyForRightContainer)
    this.addScrolls(onlyForRightContainer)
}

NotificationsPanel.prototype.scrollToCard = function (cardNumber)
{
    let cc = this.find('.cards_container'),
        ccjQ = cc ? $(cc) : null

    if (ccjQ)
        ccjQ.mCustomScrollbar("scrollTo", "#card" + cardNumber);
}

NotificationsPanel.prototype.HEXstrToRGBAstr = function (hexStr, alpha = 0.2)   // move to utils?
{
    if (!hexStr || hexStr.length != 7)
        return 'transparent'

    let r = hexStr[1] + hexStr[2],
        g = hexStr[3] + hexStr[4],
        b = hexStr[5] + hexStr[6]

    return 'rgba(' + parseInt(r, 16) + ', ' + parseInt(g, 16) + ', ' + parseInt(b, 16) + ', ' + alpha + ')'
}

NotificationsPanel.prototype.isValidHexColor = function (hexStr)   // move to utils?
{
    if (!hexStr || hexStr.length != 7 || hexStr[0] != '#')
        return false

    return true
}

NotificationsPanel.prototype.createHistoricalBM = function (histBMInfo)
{
    let result = histBMInfo;
    if (!histBMInfo.InstanceOfHistoricalBM)
    {
        let bm = new HistoricalBM();
        bm.UpdateByInfo(histBMInfo);
        result = bm;
    }
    return result;
}

NotificationsPanel.prototype.addBrokerMessage = function (histBM)
{
    if (histBM.ShowPopUp !== false)
        histBM.IsUnread = false;

    let bmToAdd = this.createHistoricalBM(histBM);

    this.AddBMHistory(bmToAdd);  // #110883 need to add not only BROKER_MESSAGE_NOTIFICATION_TYPE but also BM of other types
}

NotificationsPanel.GetTextWidth = function (category)
{
    if (!category)
        return null

    if (category._cachedTextWidth)
        return category._cachedTextWidth

    return category._cachedTextWidth = (category.colorHEXstr ? 27 : 20) + ControlsUtils.GetTextWidth(category.name, NotificationsPanel.TOOLBAR_FONT) // ширина кружечка з відступами + ширина назви категорії
}

NotificationsPanel.TOOLBAR_ARROWS_WIDTH = 50                            // дві ширини стрілок разом с відступами (ширина тулбару мінус ширина категорій) + приблизна (30) ширина categoryCounter
NotificationsPanel.TOOLBAR_FONT = '12px \"Trebuchet MS\"'
NotificationsPanel.NO_CATEGORY_COLOR = '#505050'                        // колір для карток без категорії (який немає прив'язаного продукту)
NotificationsPanel.LEFT_WIDTH_IN_PERCENT = 0.418745
NotificationsPanel.SELECTED_CARD_BORDER_COLOR = '#70A7E6'