// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { SLTPTrigger } from '../Utils/SlTpTrigger';
import { OrderEditBaseUtils } from '../Utils/Trading/OrderEditBaseUtils';
import { type OrderType } from '../Utils/Trading/OrderType';
import { DynProperty } from './DynProperty';

// TODO. Refactor.
export class SLTPDynProperty extends DynProperty {
    public sl: any;
    public tp: any;
    public trailingStop: any;
    public slProfit: any;
    public tpProfit: any;
    public trailingStopProfit: any;
    public trailingStopAllowed: boolean;
    public sllValueEnabled: boolean;
    public sltpTriggerShort: any;
    public nonEditableSLTPTriggerShort: any;

    public allowed: boolean;
    public allowedSL: boolean;
    public allowedTP: boolean;
    public OrderType: OrderType;

    constructor (name?: string, group?: string, parentGroup?: string) {
        name = name || OrderEditBaseUtils.SLTP_PARAM;

        super(name, undefined, DynProperty.SLTP, group, parentGroup);

        this.sl = {
            increment: 1,
            decimalPlaces: 0,
            minimalValue: 1,
            maximalValue: 999999,
            value: 1,
            enabled: false,
            canEdit: true, // #93540
            error: null,
            inOffset: false
        };

        this.tp = {
            increment: 1,
            decimalPlaces: 0,
            minimalValue: 1,
            maximalValue: 999999,
            value: 1,
            enabled: false,
            error: null,
            inOffset: false
        };

        this.trailingStop = {
            increment: 1,
            decimalPlaces: 0,
            minimalValue: 1,
            maximalValue: 999999,
            value: 1,
            visibleValue: 1,
            enabled: false,
            error: null
        };

        this.slProfit = null;
        this.tpProfit = null;
        this.trailingStopProfit = null;
        this.trailingStopAllowed = false;
        this.sllValueEnabled = false; // #91413
        this.sltpTriggerShort = null; // #109798
        this.nonEditableSLTPTriggerShort = null; // #110640 <- #109798
    }

    public triggersVisibleInOE (): boolean // по общему правилу комбобокса из Settings
    {
        const sV = this.sltpTriggerShort;
        return sV !== null ? SLTPTrigger.CanSelectInOE(sV) : false;
    }

    public triggerVisible (isSL = true): boolean // общее правило + включен ли SL/TP
    {
        return this[isSL ? 'sl' : 'tp'].enabled && this.triggersVisibleInOE();
    }

    public SLTriggerVisible (): boolean {
        return this.triggerVisible(true);
    }

    public TPTriggerVisible (): boolean {
        return this.triggerVisible(false);
    }
}
