// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { Level2Update } from '../../../../Utils/Enums/Constants';
import { DirectQuote2Message } from '../../../../Utils/DirectMessages/DirectQuote2Message';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class Quote2AggregatedMessageProcessor extends MessageProcessorBase {
    public decoder: any;

    constructor (parentDecoder) {
        super();

        this.decoder = parentDecoder;
    }

    public override decode (fieldSet: PFixFieldSet): DirectQuote2Message[] {
        const symbol = '';
        let route = '';
        let InstrumentTradableID: number | null = null;

        let updateMode = Level2Update.FullRefresh;
        const groups: PFixFieldSet[] = [];

        // alexb: оптимизация - заполняем за один проход. Распологать по частоте.
        const fixFields = fieldSet.fieldSet;
        let len = fieldSet.Count();
        for (let i = 0; i < len; i++) {
            const fixField = fixFields[i];
            const fieldId = fixField.FieldId;
            if (fieldId === 0) {
                continue;
            }

            // if (fieldId === FieldsFactory.FIELD_INSTRUMENT_ID)
            // {
            //    let tickId = fixField.Value
            //    let symDecodeMap = decoder.InstrumentDecodeMap
            //    symbol = symDecodeMap[tickId] || ''
            // } else
            if (fieldId === FieldsFactory.FIELD_ROUTE_ID) {
                const routeId = fixField.Value;
                route = routeId;// decoder.GetRouteById(routeId)
            } else if (fieldId === FieldsFactory.FIELD_TYPE) {
                updateMode = fixField.Value;
            } else if (fieldId === FieldsFactory.FIELD_GROUP) {
                groups.push(fixField.Value);
            } else if (fieldId === FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID) {
                InstrumentTradableID = fixField.Value;
            }
        }

        const array = new Array(groups.length);
        // исключительно для перформанса, чтобы потом не ресайзить array
        const array2 = new Array(groups.length > 0 ? groups.length - 1 : 0);

        len = groups.length;
        for (let i = 0; i < len; i++) {
            const q2Message = new DirectQuote2Message();

            q2Message.Symbol = symbol;
            q2Message.Route = route;
            q2Message.Update = updateMode;
            q2Message.InstrumentTradableID = InstrumentTradableID;

            const group = groups[i];
            const groupFields = group.fieldSet;
            const j_len = group.Count();
            for (let j = 0; j < j_len; j++) {
                const fixField = groupFields[j];
                const fieldId = fixField.FieldId;
                if (fieldId === 0) {
                    continue;
                }

                if (fieldId === FieldsFactory.FIELD_SIDE) {
                    q2Message.Side = fixField.Value;
                } else if (fieldId === FieldsFactory.FIELD_QUOTE_ID) {
                    q2Message.MMID = fixField.Value;
                } else if (fieldId === FieldsFactory.FIELD_SOURCE) {
                    q2Message.Source = fixField.Value;
                } else if (fieldId === FieldsFactory.FIELD_PRICE) {
                    q2Message.Price = fixField.Value;
                } else if (fieldId === FieldsFactory.FIELD_VOLUME) {
                    q2Message.Size = fixField.Value;
                } else if (fieldId === FieldsFactory.FIELD_SIZE) {
                    q2Message.Size = fixField.Value;
                } else if (fieldId === FieldsFactory.FIELD_NUMBERS_OF_ORDERS) {
                    q2Message.NumberOfOrders = fixField.Value;
                } else if (fieldId === FieldsFactory.FIELD_DATE) {
                    q2Message.cTime = fixField.Value;
                } else if (fieldId === FieldsFactory.FIELD_DAY_TRADE_VOLUME) {
                    q2Message.DayTradeVolume = fixField.Value;
                } else if (fieldId === FieldsFactory.FIELD_QUOTE_TYPE) {
                    q2Message.QuoteType = fixField.Value;
                }
            }

            array[i] = q2Message;
            if (i !== 0) {
                array2[i - 1] = q2Message;
            }
        }

        if (array.length > 0) {
            const q2Msg = array[0];
            q2Msg.Thail = array2;

            return [q2Msg];
        } else if (updateMode === Level2Update.FullRefresh) {
        // +++ Alexb: нужно чтобы удалялся последний
            const killThemAll = new DirectQuote2Message();
            killThemAll.Symbol = symbol;
            killThemAll.Route = route;
            killThemAll.Side = -1;
            killThemAll.Closed = true;
            killThemAll.Thail = array;
            killThemAll.Update = Level2Update.FullRefresh;
            killThemAll.InstrumentTradableID = InstrumentTradableID;

            return [killThemAll];
        } else {
        // невозможный в принципе случай
            return [];
        }
    }
}
