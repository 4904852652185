// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { type Instrument } from '../../../Commons/cache/Instrument';
import { DataCache } from '../../../Commons/DataCache';
import { OEQuote1ViewerTemplate } from '../../../templates';
import { GeneralSettings } from '../../../Utils/GeneralSettings/GeneralSettings';
import { HistoryType } from '../../../Utils/History/HistoryType';
import { Control } from '../../elements/Control';
import { SnapshotScreen } from '../../screen/SnapshotScreen';
import { ControlsTypes } from '../../UtilsClasses/FactoryConstants';

export class OEQuote1Viewer extends Control.extend({
    template: OEQuote1ViewerTemplate,
    data: function () {
        return {
            bid: '',
            ask: '',
            spread: '',

            quote: null,
            instrument: null,
            account: null,

            delayedVisible: false,
            snapshotVisible: false,
            delayedText: '',
            snapshotText: '',
            snapshotBtnTooltip: '',
            snapshotBtnDisabled: false,

            reverseView: false,
            NA: 'N/A'
        };
    }
}) {
    completed: boolean = false;
    getType (): string { return ControlsTypes.OEQuote1Viewer; }

    oninit (): void {
        super.oninit();

        super.observe('instrument', this.onInstrumentChanged);
        super.observe('account quote', this.updateValues);

        super.on('OnGetSnapshotClick', this.onGetSnapshotClick);

        GeneralSettings.SettingsChanged.Subscribe(this.updateReverseView, this);
        this.updateReverseView();
    }

    oncomplete (): void {
        super.oncomplete();

        this.completed = true;
        this.onInstrumentChanged(super.get('instrument'), null);
    }

    onInstrumentChanged (newInstrument: Instrument | undefined | null, oldInstrument: Instrument | undefined | null): void {
        if (newInstrument == null || newInstrument === oldInstrument || !this.completed) { return; }

        this.unsubscribeQuotes(oldInstrument);
        this.subscribeQuotes(newInstrument);
    }

    updateValues (): void {
        const ins = super.get('instrument');
        const account = super.get('account');
        const quote = super.get('quote');
        const na = super.get('NA');

        if (ins == null || account == null || quote == null) {
            super.set({ bid: na, ask: na, spread: na });
            return;
        }

        const sp = DataCache.GetSpreadPlan(account);
        const bid = quote.BidSpread_SP_Ins(sp, ins);
        const ask = quote.AskSpread_SP_Ins(sp, ins);
        const spread = ins.Level1.GetSpread(account);

        super.set({
            bid: isNaN(bid) ? na : ins.formatPrice(bid),
            ask: isNaN(ask) ? na : ins.formatPrice(ask),
            spread: isNaN(spread) ? na : Math.round(spread).toString()
        });
    }

    dispose (): void {
        GeneralSettings.SettingsChanged.UnSubscribe(this.updateReverseView, this);
        this.unsubscribeQuotes(super.get('instrument'));

        super.dispose();
    }

    subscribeQuotes (instrument: Instrument): void {
        if (instrument == null) { return; }

        this.newQuote(instrument.LastQuote);
        DataCache.FQuoteCache.addListener(instrument, this, HistoryType.QUOTE_LEVEL1);
    }

    unsubscribeQuotes (instrument: Instrument): void {
        if (instrument == null) { return; }

        DataCache.FQuoteCache.removeListener(instrument, this, HistoryType.QUOTE_LEVEL1);
        this.newQuote(null);
    }

    newQuote (quote): void {
        if (quote != null && quote.Type === HistoryType.QUOTE_INSTRUMENT_DAY_BAR) { return; }
        super.set('quote', quote);
    }

    updateReverseView (): void {
        super.set('reverseView', GeneralSettings.View.ReverseButtonsOrder);
    }

    onGetSnapshotClick (): void {
        if (super.get('snapshotBtnDisabled') === true) { return; }

        const ins = super.get('instrument');
        if (ins != null) { SnapshotScreen.SendSnapshotRequestMessage(ins); }
    }
}
