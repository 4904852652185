// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Connection } from '../../Commons/Connection';
import { Resources, CurrentLang, LOCALE_ES } from '../../Commons/properties/Resources';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { ExternalScreenTemplate } from '../../templates.js';
import { DockSystem, DockSystemInstance } from '../DockSystem.js';
import { ApplicationPanelNew } from '../panels/ApplicationPanelNew.js';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { DynProperty } from '../../Commons/DynProperty';
import { externalScreenHandler } from '../../Utils/AppHandlers.js';
import { WorkSpaceManager } from '../WorkSpace/WorkSpaceManager';
import { DataCache } from '../../Commons/DataCache';
import { type ExternalLink } from '../../Commons/cache/ExternalLink';

export class ExternalScreen extends ApplicationPanelNew.extend({
    data: function () {
        return {
            width: 1024,
            height: 600,
            showFooter: false,
            resizable: false,
            url: null,
            headerText: null,
            aliases: null,
            loading: true,
            showHeader: true,
            dockablePanel: false,
            showAttachHeaderButton: true,
            canLinkByAccount: false,
            style_addition_header_button: 'js-button-attach',
            clientTab: '',
            originalUrl: ''
        };
    },
    partials: { bodyPartial: ExternalScreenTemplate }
}) {
    public getRefreshURL: any;
    private ExternalLinkId: number = null;
    private readonly PanelContainer: any = null;

    getType (): PanelNames { return PanelNames.ExternalScreen; };

    oncomplete (): void {
        super.oncomplete();

        const iframe = super.find('iframe');
        if (!isNullOrUndefined(iframe)) {
            iframe.onload = () => {
                super.set('loading', false);
            };
            super.center();
        } else { super.set('loading', false); }

        Resources.onLocaleChange.Subscribe(this.localize, this);

        super.on('attachClick', this.AttachClick);
    };

    dispose (): void {
        Resources.onLocaleChange.UnSubscribe(this.localize, this);

        super.dispose();
    };

    localize (): void {
        const aliases = super.get('aliases');
        const currentLang = CurrentLang;

        if (aliases) {
            for (let i = 0; i < aliases.length; i++) {
                if (aliases[i].lang === currentLang) { super.set('headerText', aliases[i].toolName); }
            }
        }

        super.set('header', super.get('headerText') || Resources.getResource('External panel'));
        super.set('header_button_text', Resources.getResource('panel.external.Attach'));
    };

    AttachClick (): void {
        super.set({ dockablePanel: true, showHeader: false });
        DockSystemInstance.addPanelOnDock(
            this,
            DockSystem.DockPlaceConst.Right,
            false);
        WorkSpaceManager.currentWorkspace.addPanel(this);
    }

    CustomPreparation (): void {
        super.set({ dockablePanel: true, showHeader: false });
    }

    // #region ICaller

    callBack (properties: DynProperty[]): void {
        super.callBack(properties);

        let dp = DynProperty.getPropertyByName(properties, 'headerText');
        if (dp?.value) super.set('headerText', dp.value);

        dp = DynProperty.getPropertyByName(properties, 'clientTab');
        if (dp?.value) super.set('clientTab', dp.value);

        dp = DynProperty.getPropertyByName(properties, 'originalUrl');
        if (dp?.value) super.set('originalUrl', dp.value);

        dp = DynProperty.getPropertyByName(properties, 'ExternalLinkId');
        if (dp?.value) this.ExternalLinkId = dp.value;

        this.UpdateAfterLoad();
    };

    Properties (): DynProperty[] {
        const properties: DynProperty[] = super.Properties();

        const headerText = super.get('headerText');
        if (headerText) properties.push(new DynProperty('headerText', headerText, DynProperty.STRING, DynProperty.HIDDEN_GROUP));

        const clientTab = super.get('clientTab');
        if (clientTab) properties.push(new DynProperty('clientTab', clientTab, DynProperty.STRING, DynProperty.HIDDEN_GROUP));

        const originalUrl = super.get('originalUrl');
        if (originalUrl) properties.push(new DynProperty('originalUrl', originalUrl, DynProperty.STRING, DynProperty.HIDDEN_GROUP));

        const ExternalLinkId = this.ExternalLinkId;
        if (ExternalLinkId != null) properties.push(new DynProperty('ExternalLinkId', ExternalLinkId, DynProperty.STRING, DynProperty.HIDDEN_GROUP));

        return properties;
    };

    // #endregion

    UpdateAfterLoad (): void {
        const headerText = super.get('headerText');
        const clientTab = super.get('clientTab');
        const originalUrl = super.get('originalUrl');

        let tool = null;
        if (headerText && clientTab) { tool = MainWindowManager.MainWindow.ExternalScreenStorage.GetTool(headerText, clientTab); }

        if (tool === null) { tool = DataCache.ExternalLinksCache.GetLinkById(this.ExternalLinkId); }

        if (tool) {
            this.UpdateLink(tool.url, tool.IsNoneLink(), tool);
            super.set({ originalUrl: tool.url });
            MainWindowManager.MainWindow.ExternalScreenStorage.AddScreenItem(tool, this);
        } else if (originalUrl) { this.UpdateLink(originalUrl); }
    }

    webContainerCorrectingActions (tool: ExternalLink): void {
        super.resetPartial('bodyPartial', this.makeNewPartial(tool)).then(() => {
            super.center();

            const attached: boolean = super.get('dockablePanel');
            if (!attached) {
                const ic = super.find('.js-win-innerContainer');
                const divs = ic.getElementsByTagName('div');
                const firstCh = divs[0];
                const contentCh = divs[1];
                setTimeout(() => {
                    if (contentCh.style.width === '100%' && contentCh.style.height === '100%') {
                        firstCh.classList.add('iframe-container-widget');
                        this.set({
                            width: 1024,
                            height: 600,
                            resizable: true
                        });
                    } else { this.set({ width: null, height: null }); }
                }, 500);
            }
            const width = this.PanelContainer?.width;
            const height = this.PanelContainer?.height;
            this.PanelContainer?.resize(width, height);// init resize content
        });
    }

    makeNewPartial (extLink: ExternalLink): string {
        return `<div  {{#if .dockablePanel}} class="iframe-container-widget" {{/if}}>
                ${extLink.getExternalLinkWebContent(true)}
                </div>`;
    }

    UpdateLink (newUrl: string, cleanUrl: boolean = false, tool: ExternalLink = null): void {
        const culture = CurrentLang === LOCALE_ES ? 'es-ES' : 'en-GB';
        let tmp_url = newUrl;
        const token = Connection.vendor.loginParams.accessToken;

        if (super.getRefreshURL) { tmp_url = super.getRefreshURL(); }

        if (!cleanUrl) { tmp_url += '?token=' + token + '&theme=night&language=' + culture + '&platform=TraderEvolution'; }

        if (tool?.IsWebContainerLink()) { this.webContainerCorrectingActions(tool); }

        super.set({ url: tmp_url });

        if (tool) {
            let needLocalize = false;
            const dataToUpdate = {
                clientTab: tool.clientTab,
                id: tool.id,
                headerText: null,
                aliases: null
            };

            if (tool.name) {
                dataToUpdate.headerText = tool.name;
                needLocalize = true;
            }

            if (tool.getAliases()) {
                dataToUpdate.aliases = tool.getAliases();
                needLocalize = true;
            }

            super.set(dataToUpdate);

            if (needLocalize) { this.localize(); }
        }
    }

    // for static remove later
    async set (keypath, value = undefined) {
        return await super.set(keypath, value);
    }

    setFocus () {
        super.setFocus();
    }

    static Show (messageInfo, cleanUrl): ExternalScreen {
        const culture = CurrentLang === LOCALE_ES ? 'es-ES' : 'en-GB';
        let url = messageInfo.Text;
        const getRefreshURLFunc = messageInfo.GetRefreshURL;
        const tool: ExternalLink = messageInfo.Tool;
        const screenHeader = messageInfo.ScreenName;
        const aliases = messageInfo.Aliases;
        const clientTab = messageInfo.ClientTab;
        const token = Connection.vendor.loginParams.accessToken; //  App.Connection.vendor.userSessionId //  #88483

        const isWebContainer = tool.IsWebContainerLink();
        if (!isWebContainer) {
            if (!cleanUrl) { url += '?token=' + token + '&theme=night&language=' + culture + '&platform=TraderEvolution'; }

            if (!messageInfo.ClientUseInternalBrowser) {
                window.open(url);
                return null;
            }
        }

        let scr: ExternalScreen = null;

        if (tool.isAttached()) { scr = MainWindowManager.MainWindow.addAttachedExternalScree(); } else { scr = new ExternalScreen(); }

        scr.set({
            url,
            headerText: screenHeader,
            aliases,
            clientTab,
            originalUrl: messageInfo.Text
        });

        if (isWebContainer) { scr.webContainerCorrectingActions(tool); }

        if (messageInfo.ScreenWidth) { scr.set({ width: messageInfo.ScreenWidth }); }
        if (messageInfo.ScreenHeight) { scr.set({ height: messageInfo.ScreenHeight }); }

        if (!isNullOrUndefined(messageInfo.ResizableScreen)) { scr.set({ resizable: messageInfo.ResizableScreen }); }

        if (getRefreshURLFunc) { scr.getRefreshURL = getRefreshURLFunc; }

        if (isWebContainer) { scr.ExternalLinkId = tool.id; }

        if (!tool.isAttached()) { MainWindowManager.MainWindow.addControl(scr); }
        scr.setFocus();
        scr.localize();

        MainWindowManager.MainWindow.ExternalScreenStorage.AddScreenItem(tool, scr);

        return scr;
    }
}
const externalScrHanl = externalScreenHandler;
externalScrHanl.Show = ExternalScreen.Show;
