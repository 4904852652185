// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';

export class DirectXMLMessage extends DirectMessageBaseClass {
    public XMLText: null;

    constructor (code: number) {
        super(code);

        this.XMLText = null;
    }
}
