// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from "../../Utils/CustomEvents.ts";
import { ControlTemplate, PositionAndVisibleTemplate, VisibleTemplate, VisualRulesTemplate } from "../../templates.js";
import { buttonsPopupHandler, contextMenuHandler, datePickerDropDownHandler, multiComboBoxPopupMenuHandler } from "../../Utils/AppHandlers.js";
import Ractive from "ractive";
import {Control as ControlTS} from "./Control_ts.ts"

export let Control = Ractive.extend({
    template: ControlTemplate,
    data: function ()
    {
        return {
            // у всех наследников
            additionalClass: '',
            width: '',
            height: '',
            top: '',
            bottom: '',
            left: '',
            right: '',
            borderWidth: '',                        //
            visible: true,                          // видимость элемента элемента
            enabled: true,                          // возможность выделения элемента
            name: '',                               // имя элемента внутри контрола родителя - задавать обязательно! по нему рактив контрол попадет в Controls.
            tooltip: '',                            // всплывающая подсказка
            contextMenuItemText: '',                // text for getContextMenuItem
            canSelect: true,                        // может ли контрол принимать фокус
            canFocus: true,
            parentContainerControl: null,           // ссылка на родительский контейнер
            focused: false,                         // состояние фокуса
            onProcessMove: false,
            movable: false,
            resizable: false,
            withOutBorder: false,
            readonly: false,
            PRIVATE: {}
        };
    },
    partials: {
        position: PositionAndVisibleTemplate,
        visibility: VisibleTemplate,
        visualparameters: VisualRulesTemplate
    },
    AfterLostFocusWaiting: null
});

Control.prototype.getType = function () { return 'Control' };

// Описание public метода черех прототип (так нет дублирования в каждом экземпляре)
Control.prototype.oninit = function ()
{

};

Control.prototype.oncomplete = function () { };

Control.prototype.onteardown = function () { };

Control.prototype.setLocation = function (x, y)
{
    this.set({ left: x, top: y });
};

Control.prototype.setBounds = function (x, y, width, height)
{
    this.set({
        left: x,
        top: y,
        width: width,
        height: height
    });
};

Control.prototype.lostFocus = function ()
{
    this.set('focused', false);
    this.fire(Control.Events.LostFocus, this);
};

Control.prototype.gotFocus = function ()
{
    this.set('focused', true);
    //if (this.el)
    //    this.el.focus();
    this.fire(Control.Events.GotFocus, this);
};

Control.prototype.setFocus = function ()
{
    var parentControl = this.get("parentContainerControl");
    if (parentControl) parentControl.processFocus(this);
};

Control.prototype.onMouseDown = function (event)
{
    if (Control.IsEventProcessed(event))
        return;

    // Inform parent control about changes
    var parentControl = this.get("parentContainerControl");
    if (parentControl !== null)
        parentControl.processFocus(this);

    if (!isNullOrUndefined(contextMenuHandler?.isShowed) && contextMenuHandler.isShowed())
        contextMenuHandler.Hide(); 

    if (!isNullOrUndefined(multiComboBoxPopupMenuHandler?.isShowed) && multiComboBoxPopupMenuHandler.isShowed())
        multiComboBoxPopupMenuHandler.ProcessOnMouseDown(this);

    if (datePickerDropDownHandler.get('visible'))
        datePickerDropDownHandler.Hide();

    if (!isNullOrUndefined(buttonsPopupHandler?.get) && buttonsPopupHandler.get('visible'))
        buttonsPopupHandler.Hide();

    // Event was processed - skip next time
    Control.ProcessEvent(event);
};

Control.prototype.onMouseMove = function (event) { };

Control.prototype.onMouseUp = function (event) { };

Control.prototype.onMouseWheel = function (event) { };

Control.prototype.onDoubleClick = function (event) { };

Control.prototype.onClick = function (event) { };

Control.prototype.onMouseEnter = function (event) { };

Control.prototype.onMouseLeave = function (event) { };

Control.prototype.onContextMenuOpen = function (event)
{
    if (Control.IsEventProcessed(event))
        return;

    var myContextMenu = this.get('contextMenu');
    var ev = event.original;
    if (myContextMenu)
        myContextMenu.show(this, ev.pageX, ev.pageY);

    Control.ProcessEvent(event);
};

Control.prototype.getContextMenuItem = function () { 
    return {
        text: this.get('contextMenuItemText'),
        enabled: true,
        canCheck: false,
        checked: false,
        tag: null,
        event: null
    };
};

Control.prototype.getX = function ()
{
    var result = this.get('left');
    var parentContainerControl = this.get('parentContainerControl');
    if (parentContainerControl)
        result += parentContainerControl.get('left');

    var tmpleft = this.el.getBoundingClientRect().left;
    return (result > tmpleft ? result : tmpleft);
};

Control.prototype.getY = function ()
{
    var result = this.get('top');
    var parentContainerControl = this.get('parentContainerControl');
    if (parentContainerControl)
        result += parentContainerControl.get('top');

    var tmptop = this.el.getBoundingClientRect().top;
    return (result > tmptop ? result : tmptop);
};

Control.prototype.dispose = function ()
{
    this.teardown();
};

Control.prototype.getAbsoluteRectangle = function ()
{
    throw new Error('Not implemented')
}

Control.ProcessEvent = function (event)
{
    var processedEvents = ControlTS.processedEvents;
    var ev = event.original;
    // remember event
    processedEvents.push(ev.type + ev.timeStamp);
    // control size
    if (processedEvents.length > 50)
        processedEvents.splice(0, processedEvents.length - 10);
};

Control.IsEventProcessed = function (event)
{
    var ev = event.original;
    return ControlTS.processedEvents.indexOf(ev.type + ev.timeStamp) !== -1;
};

Control.Ticker = new CustomEvent();

Control.Events =
{
    LostFocus: 'LostFocus',
    GotFocus: 'GotFocus'
}