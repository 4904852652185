// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Message } from '../../Utils/DirectMessages/DirectMessagesImport';
import { CustomEvent } from '../../Utils/CustomEvents';
import { ExternalLink, ExternalLinkType } from './ExternalLink';
import { UserWebStorageInstance } from '../../user-web-storage';
import { MainWindowManager } from '../../Controls/UtilsClasses/MainWindowManager';
import { ApplicationInfo } from '../ApplicationInfo';
import { VideoWidgetUtils } from '../../Controls/panels/VideoWidget/VideoWidgetUtils';

export class ExternalLinksCache {
    public AllLinks: Record<string, ExternalLink | null> = {};

    public TradingCentralExternalTool: any = null;
    public TradingCentralExternalToolChanged = new CustomEvent();

    public AutoChartistIFrameExternalTool: any = null;
    public AutoChartistIFrameExternalToolChanged = new CustomEvent();

    public AutoChartistExternalTool: any = null;
    public ITChartAdvancedExternalTool: any = null;

    public CachedYoutubeResponseData: any = null;
    public UpdateYouTubeLIVECounter = new CustomEvent();

    public OnAddLink = new CustomEvent();
    public OnUpdateLink = new CustomEvent();
    public OnDeleteLink = new CustomEvent();

    public NewMessage (message): void {
        if (ApplicationInfo.isExploreMode) {
            return;
        }

        switch (message.Code) {
        case Message.CODE_PFIX_EXTERNAL_LINK_MESSAGE:
            this.ProcessExternalLink(message);
            break;
        case Message.CODE_PFIX_EXTERNAL_LINK_REMOVE_MESSAGE:
            this.ProcessRemoveExternalLink(message);
            break;
        }
    }

    public ProcessExternalLink (message): void {
        const link = new ExternalLink(message);
        let isNewLink = false;

        if (!this.AllLinks[link.id]) {
            isNewLink = true;
        }

        this.AllLinks[link.id] = link;

        if (isNewLink) {
            this.OnAddLink.Raise(link);
        } else {
            this.OnUpdateLink.Raise(link);
        }

        this.prepareVideoWidgetData(link); // #114288 need to be called AFTER this.OnAddLink.Raise
        this.updateTCRPandACRPLink(link);
    }

    public ProcessRemoveExternalLink (msg): void {
        if (!msg || msg.Code != Message.CODE_PFIX_EXTERNAL_LINK_REMOVE_MESSAGE) {
            return;
        }

        const id = msg.Id;

        if (this.AllLinks[id]) {
            const link = this.AllLinks[id].Copy();

            this.AllLinks[id] = null;

            this.OnDeleteLink.Raise(link);
            this.prepareVideoWidgetData(link); // for update VideoWidget button's LIVE (red) state for case when panel has not been opened ever
        }
    }

    public OnLoaded (): void {
        this.prepareVideoWidgetData();

        this.getTCRPandACRPLink();
        if (MainWindowManager.MainWindow?.addTradingToolsMenuItem) {
            MainWindowManager.MainWindow.addTradingToolsMenuItem();
        }
    }

    public getTCRPandACRPLink (): void // #87660 ищет TCRP и ACRP линки среди External links
    {
        const tools = this.AllLinks;

        for (const i in tools) {
            this.updateTCRPandACRPLink(tools[i]);
        }
    }

    public updateTCRPandACRPLink (tool): void // #87660 ищет TCRP и ACRP линки среди External links
    {
        const toolTypes = ExternalLinkType;
        const toolType = tool.externalResource;

        if (toolType === toolTypes.TRADING_CENTRAL) {
            this.TradingCentralExternalTool = tool;
            this.TradingCentralExternalToolChanged.Raise(tool);
        }

        if (toolType === toolTypes.AUTOCHARTIST_RP) {
            this.AutoChartistExternalTool = tool;
        }

        if (toolType === toolTypes.AUTOCHARTIST_IFRAME) {
            this.AutoChartistIFrameExternalTool = tool; // пока обе эти строки бесполезны
            this.AutoChartistIFrameExternalToolChanged.Raise(tool); // но вдруг пригодятся
        }

        if (toolType === toolTypes.ITCHART_ADVANCED) {
            this.ITChartAdvancedExternalTool = tool;
        }
    }

    public Clear (): void {
        this.AllLinks = {};

        this.TradingCentralExternalTool = null;
        this.AutoChartistExternalTool = null;
        this.AutoChartistIFrameExternalTool = null;
        this.ITChartAdvancedExternalTool = null;

        this.TradingCentralExternalToolChanged = new CustomEvent();
        this.AutoChartistIFrameExternalToolChanged = new CustomEvent();
    }

    public prepareVideoWidgetData (externalLink?): void // for VideoWidget: youtube post request and cache response then #114288
    {
        const links = this.getYouTubeExternalLinks(externalLink);

        let cache = this.CachedYoutubeResponseData;
        if (!cache) {
            this.CachedYoutubeResponseData = cache = {};
        }

        let liveNum = 0;

        for (let i = 0; i < links.length; i++) {
            const extLink = links[i];
            const url = extLink.url;
            const videoID = extLink.videoID = VideoWidgetUtils.GetVideoIDFromYouTubeLink(url);

            if (extLink.IsLiveStream) {
                liveNum++;
            }

            if (externalLink && externalLink.id != extLink.id) {
                continue;
            }

            UserWebStorageInstance.youtubePageParser.post(url, videoID).then(function (resp) {
                const data = resp.data;
                cache[videoID] = data;

                const streamHadToBeStartedButItWasNot = !!(data.streamHasNotStartedYet && !data.streamHasBeenAlreadyEnded &&
                (!data.streamStartDateTimeString || new Date(data.streamStartDateTimeString).getTime() < Date.now()));

                if (data.isLIVE || streamHadToBeStartedButItWasNot) {
                    this.UpdateYouTubeLIVECounter.Raise(true);
                } else if (!data.isLIVE && !this.liveStreamsPresentInCachedYoutubeResponseData()) {
                    this.UpdateYouTubeLIVECounter.Raise(false);
                }

                if ((data.streamHasNotStartedYet || data.isLIVE) && !data.streamHasBeenAlreadyEnded) {
                    extLink.setLIVEUpdateTimeout(data.streamHasNotStartedYet, cache[videoID], (eLink) => { this.prepareVideoWidgetData(eLink); });
                }
            }.bind(this));

            if (!externalLink) // for update VideoWidget button's LIVE (red) state for case when the panel has not been opened ever but some links expired
            {
                extLink.onAvailableUntilExpired.Subscribe(this.prepareVideoWidgetData.bind(this, extLink), this);
            }
        }

        if (!liveNum || (links.length === 0)) // for case when VideoWidget panel has not been opened ever but some links expired or were deleted
        {
            this.UpdateYouTubeLIVECounter.Raise(false);
        }
    }

    public getYouTubeExternalLinks (externalLink?): any[] // #114288
    {
        const links = this.AllLinks;
        let result = [];

        for (const ID in links) {
            const link = links[ID];
            if (ExternalLink.IsYouTubeLink(link) && link.IsStillAvailable()) {
                result.push(link);
                this.addDateTimeIfCached(link);
            }
        }

        if (result.length > 0) {
            result = VideoWidgetUtils.SortYouTubeExternalLinks(result);
        }

        return result;
    }

    public addDateTimeIfCached (link): boolean // #114288
    {
        const cache = this.CachedYoutubeResponseData;
        let wasAddedResult = false;

        const videoID = link ? VideoWidgetUtils.GetVideoIDFromYouTubeLink(link.url) : null;
        if (cache?.[videoID]) {
            const v = cache[videoID];
            const dateToSortByStr = v.streamEndDateTimeString || v.streamStartDateTimeString || v.publishedAt; // string suitable as arg for "new Date(<dateToSortByStr>)"

            wasAddedResult = !!(link.DateForSorting = new Date(dateToSortByStr));

            if (v.streamHasNotStartedYet && (link.DateForSorting < Date.now() || !v.streamStartDateTimeString)) // data from youtube parsing when upcoming stream's startDateTime passed but stream was not been started #114790
            {
                v.isLIVE = true; // despite the parsing data, we consider such a video as a live broadcast
                v.streamHasNotStartedYet = false;
                this.UpdateYouTubeLIVECounter.Raise(true);
            }

            link.IsLiveStream = v.isLIVE;
            link.IsUpcomingStream = v.streamHasNotStartedYet;
            link.IsEndedStream = !!v.streamEndDateTimeString;
        }

        return wasAddedResult;
    }

    public liveStreamsPresentInCachedYoutubeResponseData (): boolean {
        if (!this.CachedYoutubeResponseData) {
            return false;
        }

        const cachedData = this.CachedYoutubeResponseData;
        for (const ID in cachedData) {
            const data = cachedData[ID];
            if (data?.isLIVE) {
                return true;
            }
        }

        return false;
    }

    public GetLinkById (id: number): ExternalLink {
        if (id === null) { return null; }
        return this.AllLinks[id];
    }
}
