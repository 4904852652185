// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DirectClosePositionMessage } from '../../../../Utils/DirectMessages/DirectMessagesImport';
import { FieldsFactory } from '../Factory/FieldsFactory';
import { type PFixFieldSet } from '../Fields/FieldsImport';
import { MessageProcessorBase } from './MessageProcessorBase';

export class ClosePositionMessageProcessor extends MessageProcessorBase {
    public decoder: any;

    constructor (parentDecoder) {
        super();

        this.decoder = parentDecoder;
    }

    public override decode (fieldSet: PFixFieldSet): DirectClosePositionMessage[] {
        const message = new DirectClosePositionMessage();
        // Open Order ID
        message.Id = fieldSet.GetValue(FieldsFactory.FIELD_POSITION_ID).toString();
        // Close price
        message.ClosePrice = fieldSet.GetValue(FieldsFactory.FIELD_PRICE);
        // Close time
        message.CloseTime = fieldSet.GetValue(FieldsFactory.FIELD_DATE);
        message.OrderWasManual = fieldSet.GetValue(FieldsFactory.FIELD_ORDER_IS_WAS_MANUAL);

        return [message];
    }
}
