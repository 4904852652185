// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts";
import { HistoryType } from "../../Utils/History/HistoryType.ts";
import { DataSourceWidgetTemplate } from "../../templates.js";
import { Control } from "../elements/Control.js";
import { GeneralSettings } from "../../Utils/GeneralSettings/GeneralSettings.ts";
import { Level1Calculator } from "../../Commons/cache/Level1Calculator.ts";
import { Instrument } from "../../Commons/cache/Instrument.ts";
import { DataCache } from "../../Commons/DataCache.ts";

export let DataSourceWidget = Control.extend({
    template: DataSourceWidgetTemplate,
    data: function ()
    {
        return {
            name: 'DataSourceWidget',

            instrument: null,
            account: null,
            quote: null,

            visible: false,
            opened: false,
            comboBoxButtonType: true,

            priceLabel: '',
            sizeLabel: '',
            venueLabel: '',
            lastLabel: '',
            bidLabel: '',
            askLabel: '',

            askPrice: null,
            askSize: null,
            askVenue: null,
            bidPrice: null,
            bidSize: null,
            bidVenue: null,
            lastPrice: null,
            lastSize: null,
            lastVenue: null,

            buttonTitle: 'Data source',
            openedBtnTooltip: 'Hide data source',
            closedBtnTooltip: 'Show data source',
            disabledTooltip: '',        // используемый в темплейте - может быть общего вида или уточненный по причине отсутствия инфо роута
            delayedTooltip: 'Delayed data',
            delayedVisible: true,

            enabled: false,
            hasData: true,
            noDataText: 'No data to display',
            zIndex: null,
            noDataWidth: 300,       // ширина окна при No data to display
            CustomText: null
        }
    },
    displayAmountInLots: false,
    unsubscribingInstrument: null,
    disabledTooltipCommon: '',
    disabledWhenOnlyTradeRouteTooltip: '',
    strNA: '---',                 // если какое-то значение NaN - вместо него отображается эта локализированная строка
    VenuesCache: {}
})

DataSourceWidget.prototype.getType = function () { return 'DataSourceWidget' };

DataSourceWidget.prototype.oninit = function ()
{
    Control.prototype.oninit.call(this)

    this.observe('instrument', this.onInstrumentChanged)
    this.observe('opened', this.onOpenedChanged)

    this.on('onButtonClick', this.onOpenButtonClick)

    Resources.onLocaleChange.Subscribe(this.localize, this)
    GeneralSettings.SettingsChanged.Subscribe(this.settingsChanged, this)

    DataCache.FQuoteCache.onRequestQuotesSubscription.Subscribe(this.onRequestQuotesSubscription, this)
    DataCache.FQuoteCache.onCancelQuotesSubscription.Subscribe(this.onCancelQuotesSubscription, this)

    DataCache.EntitlementManager.OnAddOrUpdate.Subscribe(this.onEntitlementManagerUpdate, this)

    Control.Ticker.Subscribe(this.TickAsync, this)

    this.localize()
}

DataSourceWidget.prototype.oncomplete = function ()
{
    Control.prototype.oncomplete.call(this)

    if (this.get('visible') && this.get('opened'))
    {
        //resubscribe cheat for stoped ins, if loaded WS and opened
        this.tryUnsubscribeFromQuotes(this.get('instrument'))
        this.trySubscribeToQuotes(this.get('instrument'))
    }

    this.settingsChanged()
}

DataSourceWidget.prototype.dispose = function ()
{
    this.tryUnsubscribeFromQuotes(this.get('instrument'))

    GeneralSettings.SettingsChanged.UnSubscribe(this.settingsChanged, this)
    Resources.onLocaleChange.UnSubscribe(this.localize, this)

    DataCache.FQuoteCache.onRequestQuotesSubscription.UnSubscribe(this.onRequestQuotesSubscription, this)
    DataCache.FQuoteCache.onCancelQuotesSubscription.UnSubscribe(this.onCancelQuotesSubscription, this)

    DataCache.EntitlementManager.OnAddOrUpdate.UnSubscribe(this.onEntitlementManagerUpdate, this)

    Control.Ticker.UnSubscribe(this.TickAsync, this)

    Control.prototype.dispose.call(this)
}

DataSourceWidget.prototype.onInstrumentChanged = function (newInstrument, oldInstrument)
{
    if (!newInstrument || newInstrument === oldInstrument || newInstrument.IsEmpty)
        return

    let opened = this.get('opened'),
        visible = this.updateVisibleState(newInstrument),
        enabled = newInstrument.IsDataSourceEnable()

    this.set('enabled', enabled)

    if (!enabled || !visible)
    {
        this.set('disabledTooltip', newInstrument.IsInfoRouteForDataSourceAvailable() ? this.disabledTooltipCommon : this.disabledWhenOnlyTradeRouteTooltip)

        if (opened)
        {
            this.tryUnsubscribeFromQuotes(oldInstrument)
            opened = false
            this.set('opened', opened)
        }
    }

    let dsIns = DataCache.GetDataSourceInstrument(newInstrument)
    if (dsIns)
        this.set('delayedVisible', dsIns.GetDelay() > 0)

    this.tryUnsubscribeFromQuotes(oldInstrument)


    if (enabled && Resources.IsResourcePresentEN("controls.DataSource.CustomText"))
    {
        this.set("CustomText", Resources.getResource("controls.DataSource.CustomText"))
        this.set('opened', true)
        return;
    }

    if (!opened)
        return

    this.trySubscribeToQuotes(newInstrument)
}

DataSourceWidget.prototype.newQuote = function (quote)
{
    this.set('quote', quote)
}


DataSourceWidget.prototype.TickAsync = function ()
{
    this.set({
        askPrice: this.getPrice(DataSourceWidget.ASK),
        askSize: this.getSize(DataSourceWidget.ASK),
        askVenue: this.getVenue(DataSourceWidget.ASK),

        bidPrice: this.getPrice(DataSourceWidget.BID),
        bidSize: this.getSize(DataSourceWidget.BID),
        bidVenue: this.getVenue(DataSourceWidget.BID),

        lastPrice: this.getPrice(DataSourceWidget.LAST),
        lastSize: this.getSize(DataSourceWidget.LAST),
        lastVenue: this.getVenue(DataSourceWidget.LAST),
    })
}

DataSourceWidget.prototype.trySubscribeToQuotes = function (instrument)
{
    if (!instrument)
        return

    let dsIns = DataCache.GetDataSourceInstrument(instrument)
    if (!dsIns)
        return

    dsIns.RiskSettingsUpdated.Subscribe(this.onUpdateInsRiskSettings, this)

    DataSourceWidget.HISTORY_TYPES_IN_USE.forEach(function (type) { DataCache.FQuoteCache.addListener(dsIns, this, type, true) }.bind(this))
};

DataSourceWidget.prototype.tryUnsubscribeFromQuotes = function (instrument)
{
    if (!instrument)
        return

    let dsIns = DataCache.GetDataSourceInstrument(instrument)
    if (!dsIns)
        return

    this.unsubscribingInstrument = instrument

    dsIns.RiskSettingsUpdated.UnSubscribe(this.onUpdateInsRiskSettings, this)

    DataSourceWidget.HISTORY_TYPES_IN_USE.forEach(function (type) { DataCache.FQuoteCache.removeListener(dsIns, this, type, true); }.bind(this))
};

DataSourceWidget.prototype.onRequestQuotesSubscription = function (instrumentTradableID, route, quoteType, forse, isDataSource)
{
    if (!isDataSource)
        return

    //Небыло фильтрации по инструменту, любой даже не видимый виджет на любую подписку подписывался/отписывался
    let instrument = this.get('instrument')
    if (!instrument || !this.get("visible"))
        return

    let instrumentCaller = DataCache.getInstrumentByTradable_ID(instrumentTradableID, route)
    let dsIns = DataCache.GetDataSourceInstrument(instrument)
    if (dsIns && dsIns === instrumentCaller)
        DataCache.SendDataSourceSubscriptionMessage(instrument, true, quoteType)
}

DataSourceWidget.prototype.onCancelQuotesSubscription = function (instrumentTradableID, route, quoteType, forse, isDataSource)
{
    if (!isDataSource)
        return

    //Небыло фильтрации по инструменту, любой даже не видимый виджет на любую подписку подписывался/отписывался
    let instrument = this.unsubscribingInstrument
    if (!instrument)
        return

    let instrumentCaller = DataCache.getInstrumentByTradable_ID(instrumentTradableID, route)
    let dsIns = DataCache.GetDataSourceInstrument(instrument)
    if (dsIns && dsIns === instrumentCaller)
        DataCache.SendDataSourceSubscriptionMessage(instrument, false, quoteType)
}

DataSourceWidget.prototype.localize = function ()
{
    this.set({
        priceLabel: Resources.getResource('dataSourceWidget.price'),
        sizeLabel: Resources.getResource('dataSourceWidget.size'),
        venueLabel: Resources.getResource('dataSourceWidget.venue'),
        lastLabel: Resources.getResource('dataSourceWidget.last'),
        bidLabel: Resources.getResource('dataSourceWidget.bid'),
        askLabel: Resources.getResource('dataSourceWidget.ask'),
        buttonTitle: Resources.getResource('dataSourceWidget.btnText'),
        openedBtnTooltip: Resources.getResource('dataSourceWidget.openedBtn.tt'),
        closedBtnTooltip: Resources.getResource('dataSourceWidget.closedBtn.tt'),
        delayedTooltip: Resources.getResource('dataSourceWidget.delayed.tt'),
        noDataText: Resources.getResource('dataSourceWidget.noData.text')
    })

    let disabledCommonTTLocalized = Resources.getResource('dataSourceWidget.disabled.tt'),
        disabledTradeRouteTTLocalized = Resources.getResource('dataSourceWidget.disabledWhenOnlyTradeRoute.tt');

    let ins = this.get('instrument')
    if (Instrument.IsWorkingInstrument(ins))
        this.set('disabledTooltip', ins.IsInfoRouteForDataSourceAvailable() ? disabledCommonTTLocalized : disabledTradeRouteTTLocalized)

    this.disabledTooltipCommon = disabledCommonTTLocalized;
    this.disabledWhenOnlyTradeRouteTooltip = disabledTradeRouteTTLocalized;

    this.strNA = Resources.getResource('general.N_A');
}

DataSourceWidget.prototype.settingsChanged = function ()
{
    this.displayAmountInLots = GeneralSettings.View.displayAmountInLots()
}

DataSourceWidget.prototype.onOpenedChanged = function ()
{
    let opened = this.get('opened')
    let ins = this.get('instrument')

    if (opened && Resources.IsResourcePresentEN("controls.DataSource.CustomText"))
        return;
    
    if (opened)
        this.trySubscribeToQuotes(ins)
    else
        this.tryUnsubscribeFromQuotes(ins)
}

DataSourceWidget.prototype.onOpenButtonClick = function ()
{
    if (!this.get('enabled'))
        return

    this.set('opened', !this.get('opened'))
}

DataSourceWidget.prototype.onUpdateInsRiskSettings = function ()
{
    let ins = DataCache.GetDataSourceInstrument(this.get('instrument'))

    if (ins)
        this.set('delayedVisible', ins.GetDelay() > 0)
}

DataSourceWidget.prototype.updateVisibleState = function (ins)
{
    let newVisibleState = DataCache.EntitlementManager.IsDataSourceAvailable(ins)
    this.set('visible', newVisibleState)

    return newVisibleState
}

DataSourceWidget.prototype.onEntitlementManagerUpdate = function ()
{
    this.updateVisibleState(this.get('instrument'))     // ради изменения visible состояния на лету без логина или смены инструмента
}

DataSourceWidget.prototype.getPrice = function (service)
{
    let ins = this.get('instrument'),
        acc = this.get('account'),
        showLots = this.displayAmountInLots,
        level1 = ins && ins.DataSourceLevel1 ? ins.DataSourceLevel1 : null

    let result = level1 ?
        (service == DataSourceWidget.LAST ?
            level1.StrLastPrice(acc, true) :
            level1['str' + service](acc, true))
        : null

    if (!result)
        result = this.strNA

    return result
}

DataSourceWidget.prototype.getSize = function (service)
{
    let ins = this.get('instrument'),
        showLots = this.displayAmountInLots,
        level1 = ins && ins.DataSourceLevel1 ? ins.DataSourceLevel1 : null,
        methodName = 'Str' + service + 'Size'

    let result = level1 ? level1[methodName](showLots) : null

    if (!result)
        result = this.strNA

    return result
}

DataSourceWidget.prototype.getVenue = function (service)
{
    let ins = this.get('instrument'),
        methodName = 'Get' + service + 'SourceName',
        instrumentDayBarMessageUpdateMode = ins ? ins.InstrumentDayBarMessageUpdateMode : null,
        result = ins && ins.DataSourceLevel1 ? ins.DataSourceLevel1[methodName](instrumentDayBarMessageUpdateMode) : null

    if (!result || result == -1)
        result = this.strNA


    if (Level1Calculator.NAString === result && !!this.VenuesCache[methodName])
        result = this.VenuesCache[methodName];
    else this.VenuesCache[methodName] = result

    return result
}

DataSourceWidget.BID = 'Bid';
DataSourceWidget.ASK = 'Ask';
DataSourceWidget.LAST = 'Last';

DataSourceWidget.HISTORY_TYPES_IN_USE = [
    HistoryType.QUOTE_LEVEL1,
    HistoryType.QUOTE_TRADES,
    HistoryType.QUOTE_INSTRUMENT_DAY_BAR
];