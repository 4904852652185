import Ractive from 'ractive';

declare global{
    interface Window {
        Ractive: any
    }
}

Ractive.DEBUG = false;

window.Ractive = Ractive;
