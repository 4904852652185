// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
export let pt =
{
    "AdditionalProperty.Account": "Conta",
    "AdditionalProperty.Reverse": "Inverter",
    "accountDetailsWidget.DropdownButton": "Conta",
    "accountDetailsWidget.DropdownButton.Tooltip": "Menu da conta",
    "additional.verification": "Verificação adicional da senha é necessária.",
    "Alligator": "Alligator",
    "Average Directional Index": "Average Directional Index",
    "Average True Range": "Average True Range",
    "Bollinger Band": "Bollinger Band",
    "Bollinger Bands": "Bollinger Bands",
    "bonds.couponCycle.1m": "1 mês",
    "bonds.couponCycle.1y": "1 ano",
    "bonds.couponCycle.3m": "3 meses",
    "bonds.couponCycle.6m": "6 meses",
    "BrokerMessage.Cancel": "Cancelar",
    "BrokerMessage.OK": "OK",
    "BrokerMessage.Text": "Mensagem da corretora",
    "BusinessRejectMessage.28": "A senha atual está incorreta",
    "Chande Momentum Oscillator": "Chande Momentum Oscillator",
    "chart.agregationType.Button.ToolTip": "Use tipos de agregação predefinidos",
    "chart.AllowedPeriods.15M": "15m",
    "chart.AllowedPeriods.1D": "1D",
    "chart.AllowedPeriods.1H": "1H",
    "chart.AllowedPeriods.1M": "1m",
    "chart.AllowedPeriods.1S": "1s",
    "chart.AllowedPeriods.1T": "1 tick",
    "chart.AllowedPeriods.1W": "1S",
    "chart.AllowedPeriods.1Y": "1A",
    "chart.AllowedPeriods.30M": "30m",
    "chart.AllowedPeriods.4H": "4H",
    "chart.AllowedPeriods.5M": "5m",
    "chart.AllowedPeriods.Month": "1M",
    "chart.AllowedPeriods.Range": "Limites",
    "chart.AllowedPeriods.Ticks": "Ticks",
    "chart.ChartPanel.accountLookup.ToolTip": "Selecionar conta(s)",
    "chart.ChartPanel.symbolLookup.ToolTip": "Selecinar Ativo(s)",
    "chart.chartScroll.toBegin": "Para última cotação",
    "chart.chartScroll.zoomInButton": "(+) Zoom",
    "chart.chartScroll.zoomOutButton": "(-) zoom",
    "chart.ChartSymbolSelector.addIndicator.ButtonToolTip": "Adicionar indicador",
    "chart.ChartSymbolSelector.drawingButton.ToolTip": "Exibir/ocultar barra de ferramenta de desenho",
    "chart.ChartSymbolSelector.mouseTradeButton.ToolTip": "Negociar com o mouse",
    "chart.ChartSymbolSelector.oeButton": "Entrada de ordem",
    "chart.ChartSymbolSelector.oeButton.ToolTip": "Negociar a partir do gráfico",
    "chart.ChartSymbolSelector.OverlayTooltip": "Adicionar sobreposição",
    "Chart.chartWindow.auto": "Auto",
    "Chart.chartWindow.collapsed": "Contraido",
    "Chart.chartWindow.manual": "Manual",
    "chart.chartWindowManager.SetAuto": "Mudar para a escala automática de preço",
    "chart.chartWindowManager.SetManual": "Mudar para a escala manual de preço",
    "chart.head.addOverlay": "Adicionar sobreposição...",
    "chart.head.charttype": "Estilo",
    "chart.head.line": "Ferramentas de desenho",
    "chart.head.overlayRemoveAll": "Remover tudo",
    "chart.head.overlays": "Sobreposições",
    "chart.indicator": "Indicador",
    "chart.indicatorAdd": "Adicionar indicador",
    "chart.indicatorRemoveAll": "Remover tudo",
    "chart.indicators": "Indicadores",
    "chart.Infoline.days": "dias",
    "chart.Infoline.hours": "horas",
    "chart.Infoline.min": "min",
    "chart.Infoline.price": "preço",
    "chart.Infoline.sec": "seg",
    "chart.Infoline.time": "horário",
    "chart.infoWindow.Items.Additionalinfo": "Informações adicinais",
    "chart.infoWindow.Items.Ask": "Ask:",
    "chart.infoWindow.Items.Bars": "Barra Nº:",
    "chart.infoWindow.Items.Bid": "Bid:",
    "chart.infoWindow.Items.Change": "Variação, %",
    "chart.infoWindow.Items.Close": "Fechamento:",
    "chart.infoWindow.Items.Date": "Data:",
    "chart.infoWindow.Items.High": "Máximo:",
    "chart.infoWindow.Items.Low": "Mínimo:",
    "chart.infoWindow.Items.Open": "Abertura:",
    "chart.infoWindow.Items.Ticks": "Ticks:",
    "chart.infoWindow.Items.Time": "Hora:",
    "chart.infoWindow.Items.Volume": "Volume:",
    "chart.instrumentsLookup": "Buscar Ativos",
    "chart.layerManager": "Configurações...",
    "chart.loading": "Carregando...",
    "chart.loadingData": "Carregar dados para",
    "chart.mainlayer.gridPrice": "Grade de preço",
    "chart.mainlayer.gridTime": "Grade de tempo",
    "chart.mainlayer.volume": "Volume das barras",
    "chart.menu.cancelByAccount": "Cancelar todos pela conta",
    "chart.menu.cancelByInstrument": "Cancelar todos pelo Ativo",
    "chart.menu.cancelOrder": "Cancelar ordem",
    "chart.menu.close": "Fechar posição",
    "chart.menu.closeall": "Fechar todas as posições...",
    "chart.menu.InfoWindowDock": "Ancorar",
    "chart.menu.InfoWindowHide": "Esconder",
    "chart.menu.InfoWindowSettings": "Configurações",
    "chart.menu.InfoWindowShortMode": "Modo Venda",
    "chart.menu.modify": "Modificar",
    "chart.menu.PlateIndicatorDublicate": "Duplicar Indicador",
    "chart.menu.PlateIndicatorRemove": "Remover indicador",
    "chart.menu.PlateIndicatorSettings": "Configurarções do Indicador",
    "chart.menu.PlateIndicatorVisible": "Visivel",
    "chart.menu.PlateOverlay.Overlay": "Sobreposição",
    "chart.menu.PlateOverlayRemove": "Remover sobreposição",
    "chart.menu.PlateOverlaySettings": "Configurações da sobreposição",
    "chart.menu.PlateOverlayVisible": "Visivel",
    "chart.menu.rollback": "Inverter posições",
    "chart.noAvailableData": "Sem dados disponíveis para",
    "chart.NoData": "Não existem dados",
    "chart.priceScaleContextMenu.AutoScale": "Automática",
    "chart.priceScaleContextMenu.FitDay": "Ajustar Máximo/Mínimo do dia",
    "chart.priceScaleContextMenu.FitDrawings": "Ajustar desenhos",
    "chart.priceScaleContextMenu.FitIndicators": "Ajustar indicadores",
    "chart.priceScaleContextMenu.FitOrdersPositions": "Ajustar ordens/posições",
    "chart.priceScaleContextMenu.ManualScale": "Manual",
    "chart.refresh": "Atualizar",
    "chart.Remove": "Remover",
    "chart.shortPeriodName.m": "m",
    "chart.shortPeriodName.ticks": "ticks",
    "chart.ShowHighLow.Close": "Fechamento",
    "chart.ShowHighLow.High": "Máximo",
    "chart.ShowHighLow.Low": "Mínimo",
    "chart.StyleButton.ToolTip": "Estilo",
    "chart.templates.tooltip": "Modelos",
    "chart.tic.short.15min.name": "15m",
    "chart.tic.short.1day.name": "1D",
    "chart.tic.short.1hour.name": "1H",
    "chart.tic.short.1min.name": "1m",
    "chart.tic.short.1tic.name": "Tick",
    "chart.tic.short.30min.name": "30m",
    "chart.tic.short.4hour.name": "4H",
    "chart.tic.short.5min.name": "5m",
    "chart.tic.short.month.name": "1M",
    "chart.tic.short.range.name": "Limite",
    "chart.tic.short.second.name": "s",
    "chart.tic.short.week.name": "1S",
    "chart.tic.short.year.name": "1A",
    "chart.timeframeselector.rangeButton.ToolTip": "Escolher período de exibição de dados",
    "chart.timeframeselector.time": "Time",
    "chart.timeScaleContextMenu.ShowAllSessions": "Exibir sessões estendidas",
    "chart.timeScaleContextMenu.ShowDaySeparator": "Mostrar separador de dias",
    "chart.timeScaleContextMenu.ShowEmptyBars": "Exibir buracos no histórico",
    "chart.timeScaleContextMenu.ShowMonthSeparator": "Mostrar separador de meses",
    "chart.timeScaleContextMenu.ShowTimePeriodsSeparator": "Mostrar separador de período de tempo",
    "chart.timeScaleContextMenu.ShowWeeksSeparator": "Mostrar separador de semanas",
    "chart.timeScaleContextMenu.ShowYearSeparator": "Mostrar separador de anos",
    "chart.tool.bar": "Barra",
    "chart.tool.candle": "Candle",
    "chart.tool.clone": "Clonar",
    "chart.tool.Dash": "Traço",
    "chart.tool.delete": "Remover",
    "chart.tool.deleteAll": "Remover tudo",
    "chart.tool.dot": "Pontos",
    "chart.tool.dotline": "Linha pontilhada",
    "chart.tool.DottedLine": "Linha pontilhada",
    "chart.tool.forest": "Histograma",
    "chart.tool.IsoDot": "Ponto Padrão",
    "chart.tool.line": "Linha",
    "chart.tool.properties": "Configurações",
    "chart.tool.ShapedLine": "Linha formatada",
    "chart.tool.solid": "Área",
    "chart.tools.ABCPatern": "Padrão ABC",
    "chart.tools.AndrewPitchfork": "Andrew's Pitchfork",
    "chart.tools.backcolor": "Cor do fundo",
    "chart.tools.ButterflyGartley": "Gartley butterfly",
    "chart.tools.Circle": "Círculo",
    "chart.tools.default": "Ponteiro",
    "chart.tools.ElliottImpulse": "Onda de Elliot Impulse (12345)",
    "chart.tools.ElliottCorrection": "Onda de Elliot Correcao (ABC)",
    "chart.tools.ElliottTriangle": "Onda de Elliot Triangular (ABCDE)",
    "chart.tools.ElliottTripleCombo": "Onda de Elliot Combo Tripla (WXYXZ)",
    "chart.tools.ElliottDoubleCombo": "Onda de Elliot Combo Dupla (WXY)",
    "chart.tools.Ellipse": "Elipse",
    "chart.tools.eraser": "Apagador",
    "chart.tools.FibArc": "Arcos de Fibonacci",
    "chart.tools.FibEllipses": "Elipses de Fibonacci",
    "chart.tools.FibExp": "Expansão de Fibonacci",
    "chart.tools.FibFan": "Fibonacci Fans",
    "chart.tools.FibPhiChannel": "Fibonacci Phi-Channel",
    "chart.tools.Fibretr": "Retração de Fibonacci",
    "chart.tools.FibSpiral": "Espiral de Fibonacci",
    "chart.tools.FibTga": "Análise de Objetivos Temporais de Fibonacci",
    "chart.tools.FibTz": "Zona de Tempo de Fibonacci",
    "chart.tools.GannFan": "Gann fan",
    "chart.tools.GannGrid": "Grade de Gann",
    "chart.tools.GannLine": "Linha de Gann",
    "chart.tools.horizontal": "Linha Horizontal",
    "chart.tools.infoline": "Régua",
    "chart.tools.Line": "Linha",
    "chart.tools.mirrored": "Ativado exibição em espelho",
    "chart.tools.points": "Contagem de pontos",
    "chart.tools.Polygon": "Polígono",
    "chart.tools.PPZ": "ZPP (Zona de Prece de Pivot)",
    "chart.tools.PriceChannel": "Canal de Preço",
    "chart.tools.Rectangle": "Retângulo",
    "chart.tools.RegressionChanel": "Canais de Regressão",
    "chart.tools.ScalingByRect": "Dimensionar pelo retângulo",
    "chart.tools.selection": "Seleção",
    "chart.tools.snapToCandle": "Alinhar ao 'Candle'",
    "chart.tools.StayDrawingMode": "Mantenha-se em modo de desenho",
    "chart.tools.symbol": "Marca",
    "chart.tools.text": "Comentários",
    "chart.tools.TopBottomPattern": "Padrão Topos e Fundos",
    "chart.tools.Triangle": "Triângulo",
    "chart.tools.vertical": "Linha Vertical",
    "chart.tools.ABCPatern.deleteByType": "Todos os \'Padões ABC\'",
    "chart.tools.AndrewPitchfork.deleteByType": "todos os \'Andrew\'s Pitchforks\'",
    "chart.tools.ButterflyGartley.deleteByType": "todos os \'Gartley\'s butterfly\'",
    "chart.tools.Circle.deleteByType": "Todas os \'Círculos\'",
    "chart.tools.Ellipse.deleteByType": "Todas as \'Elipses\'",
    "chart.tools.FibArc.deleteByType": "Todos os \'Arcos de Fibonacci\'",
    "chart.tools.FibEllipses.deleteByType": "Todos as \'Elipses de Fibonacci\'",
    "chart.tools.FibExp.deleteByType": "Todos as \'Expansões de Fibonacci\'",
    "chart.tools.FibFan.deleteByType": "Todos os \'Fibonacci Fans\'",
    "chart.tools.FibPhiChannel.deleteByType": "Todos os \'Fibonacci Phi-Channels\'",
    "chart.tools.Fibretr.deleteByType": "Todos as \'Retrações de Fibonacci\'",
    "chart.tools.FibSpiral.deleteByType": "Todos as \'Espirais de Fibonacci\'",
    "chart.tools.FibTga.deleteByType": "Todos as \'Análises de Objetivos Temporais de Fibonacci\'",
    "chart.tools.FibTz.deleteByType": "Todos as \'Zonas de Tempo de Fibonacci\'",
    "chart.tools.GannFan.deleteByType": "Todos os \'Gann fans\'",
    "chart.tools.GannGrid.deleteByType": "Todos as \'Grade des Gann\'",
    "chart.tools.GannLine.deleteByType": "Todos as \'Linhas de Gann\'",
    "chart.tools.horizontal.deleteByType": "Todos as \'Linhas Horizontais\'",
    "chart.tools.infoline.deleteByType": "Todas as \'Regras\'",
    "chart.tools.Line.deleteByType": "Todos as \'Linhas\'",
    "chart.tools.Polygon.deleteByType": "Todos os \'Poligonos\'",
    "chart.tools.PPZ.deleteByType": "Todos os \'ZPP (Zona de Prece de Pivot)\'",
    "chart.tools.PriceChannel.deleteByType": "Todos os \'Canais de Preço\'",
    "chart.tools.Rectangle.deleteByType": "Todos os \'Retângulos\'",
    "chart.tools.RegressionChanel.deleteByType": "Todos os \'Canais de Regressão\'",
    "chart.tools.symbol.deleteByType": "Todos os Ativos",
    "chart.tools.text.deleteByType": "Todos os comentários",
    "chart.tools.TopBottomPattern.deleteByType": "Todos os \'Padrões Topos e Fundos\'",
    "chart.tools.Triangle.deleteByType": "Todos os \'Triangulos\'",
    "chart.tools.vertical.deleteByType": "Todos as \'Linhas Verticais\'",
    "chart.tools.VolumeProfile.deleteByType": "Todos os \'Perfis de Volume Personalizado\'",
    "chart.view": "Exibir",
    "chart.view.analyseGroupMenu": "Ferramenta de análise",
    "chart.view.analysetoolbar": "Barra de ferramenta de análise",
    "chart.view.barsToRight": "Barras à direita...",
    "chart.view.colors": "Configurações",
    "chart.view.dataInfo": "Janela de informação",
    "chart.view.Day_HighLow": "Máximo/Mínimo do dia",
    "chart.view.drawingtoolbar": "Barra de ferramentas desenho",
    "chart.view.font": "Fonte do eixo",
    "chart.view.landscapequestion": "Usar arranjo de papel em forma paisagem?",
    "chart.view.merge": "Linhas contínuas",
    "chart.view.orderEntry": "Negociação no Gráfico",
    "chart.view.periods": "Intervalos...",
    "chart.view.previousCloseLine": "Linha de fechamento anterior",
    "chart.view.PrevSettlementPriceLine": "Preço de Ajuste Anterior",
    "chart.view.scroller": "Barra de rolagem",
    "chart.view.setOrder": "Configurar ordem",
    "chart.view.SettlementPriceLine": "Preço de Ajuste",
    "chart.view.show_cross": "Cursor de rastreamento",
    "chart.view.show_history": "Exibir histórico de negociações",
    "chart.view.show_set_zero": "Exibir barras vazias",
    "chart.view.showEvents": "Exibir negociações",
    "chart.view.showNews": "Exibir notícias",
    "chart.view.showOrders": "Exibir ordens",
    "chart.view.showPositions": "Exibir posições",
    "chart.view.source": "Tipo de dados",
    "chart.view.source0": "Padrão por Ativo",
    "chart.view.source1": "Bid",
    "chart.view.source1000": "Ask",
    "chart.view.source1001": "Média 'Bid'/'Ask'",
    "chart.view.source1002": "Mínimo ('Bid') e máximo ('Ask')",
    "chart.view.source4": "Último",
    "chart.view.sourceBID/ASK": "Pelo 'Bid'/'Ask'",
    "chart.view.sourceTick1": "Pelo 'Bid'/'Ask'",
    "chart.view.TimeToNextBar": "Tempo para próxima barra",
    "chart.view.toolbar": "Barra de ferramentas",
    "chart.view.volume": "Exibir volume",
    "chart.visualTrading.Invalid price": "Preço inválido",
    "chart.volumeColoringMode.ByBar": "Por barra",
    "chart.volumeColoringMode.ByDifference": "Por diferença",
    "chart.volumeColoringMode.Fixed": "Fixo",
    "chart.volumeColoringMode.ByMovingAverage": "Acima/abaixo da média móvel",
    "chart.quickTrading.Buy limit": "Compra 'Limit'",
    "chart.quickTrading.Buy stop": "Compra 'Stop'",
    "chart.quickTrading.Invalid price": "preço inválido",
    "chart.quickTrading.Sell limit": "Venda 'Limit'",
    "chart.quickTrading.Sell stop": "Venda 'Stop'",
    "Commodity Channel Index": "Commodity Channel Index",
    "connect.connection.connect.error": "Parâmetros de conexão errados",
    "connect.connection.unknown.error": "Erro desconhecido!",
    "Custom": "Personalizado",
    "Day": "Dia",
    "dealticket.RiskRuleWarning.Header": "",
    "Deutsch": "#hidden#",
    "enum.AccountType.ClosedFund": "Fundo fechado",
    "enum.AccountType.MultiAsset": "Ativos múltiplos",
    "enum.AccountType.OpenedFund": "Fundo aberto",
    "enum.AccountType.SingleCCY": "Moeda única",
    "enum.AssetType.COMMODITIES": "Commodities",
    "enum.AssetType.CRYPTO_CCY": "Crypto currency",
    "enum.AssetType.CURRENCY": "Moeda",
    "enum.AssetType.SHARES": "Ações",
    "editableComboBox.defaultList": "Lista padrão",
    "editableComboBox.tooltip": "Listas salvas",
    "ExchangeId.All": "Todos os Ativos",
    "Exponential Moving Average": "Exponential Moving Average",
    "Externalpanels.MultiTimeAndSales.Header": "",
    "FOK": "Tudo ou Nada",
    "FullLogs.Cluster": "Servidor",
    "FullLogs.DateTime": "Tempo",
    "FullLogs.Fields": "Campos",
    "FullLogs.Message name": "Nome da mensagem",
    "FullLogs.Session": "Sessão",
    "general.Calculating": "",
    "general.connection.restErrorMsg": "No momento, ha um problema em salvar configuracoes e espacos de trabalho. Por favor entre em contato com o departamento de suporte.",
    "general.CUSTOM": "Personalizado",
    "general.Friday": "Sexta-feira",
    "general.messageBox.cancel": "Cancelar",
    "general.messageBox.no": "Não",
    "general.messageBox.ok": "OK",
    "general.messageBox.showNextTime": "Exibir próxima vez",
    "general.messageBox.yes": "Sim",
    "general.Monday": "Segunda-feira",
    "general.day1.Mo": "",
    "general.day2.Tu": "",
    "general.day3.We": "",
    "general.day4.Th": "",
    "general.day5.Fr": "",
    "general.day6.Sa": "",
    "general.day7.Su": "",
    "general.month.1": "Jan",
    "general.month.10": "Out",
    "general.month.11": "Nov",
    "general.month.12": "Dez",
    "general.month.2": "Fev",
    "general.month.3": "Mar",
    "general.month.4": "Abr",
    "general.month.5": "Maio",
    "general.month.6": "Jun",
    "general.month.7": "Jul",
    "general.month.8": "Ago",
    "general.month.9": "Set",
    "general.monthFullName.1": "Janeiro",
    "general.monthFullName.10": "Outubro",
    "general.monthFullName.11": "Novembro",
    "general.monthFullName.12": "Dezembro",
    "general.monthFullName.2": "Fevereiro",
    "general.monthFullName.3": "Março",
    "general.monthFullName.4": "Abril",
    "general.monthFullName.5": "Maio",
    "general.monthFullName.6": "Junho",
    "general.monthFullName.7": "Julho",
    "general.monthFullName.8": "Agosto",
    "general.monthFullName.9": "Setembro",
    "general.N_A": "N/A",
    "general.NoData": "Não existem dados",
    "general.OvernightMarginNotificationMessage": "Exibir mensagem de notificação de margem 'Overnight'",
    "general.Saturday": "Sábado",
    "general.Settings": "Configurações",
    "general.Sunday": "Domingo",
    "general.Thursday": "Quinta-feira",
    "general.trading.at": "em",
    "general.trading.Buy": "Compra",
    "general.trading.confirmation": "Confirmação",
    "general.trading.confirmation.prefix": "",
    "general.trading.confirmationString": "{1} {2} {3} {4} {5} {6} {7} {8} {9} {10} {14} {13} {14} {15} {11} {12} {16}",
    "general.trading.for": "para",
    "general.trading.limitBuyLessAsk": "Preço da ordem de Compra 'Limit' é maior que o preço 'Ask', a ordem sera preenchida imediatamente",
    "general.trading.limitSellMoreBid": "Preço da ordem de Venda 'Limit' é menor que o preço 'Bid', a ordem será preenchida imediatamente",
    "general.trading.marketCloseFor": "Mercado fechado para",
    "general.trading.pips": "ticks",
    "general.trading.points": "pontos",
    "general.trading.position": "Posição",
    "general.trading.position.Buy": "Compra",
    "general.trading.position.Sell": "Venda",
    "general.trading.priceNotAvailable": "Não disponível",
    "general.trading.refused": "Recusado",
    "general.trading.Sell": "Venda",
    "general.trading.stopBuyMoreAsk": "Preço da ordem de Compra 'Stop' deve ser maior do que o preço 'Ask'",
    "general.trading.stopSellLessBid": "Preço da ordem de Venda 'Stop' deve ser menor do que o preço 'Bid'",
    "general.trading.with offset": "com deslocamento",
    "general.Tuesday": "Terça-feira",
    "property.general.WarnIfWrongOrderParam": "Avisar se a ordem estiver errada",
    "general.Wednesday": "Quarta-feira",
    "GTC": "Até Cancelar",
    "helpPage.link": "",
    "homePage.link": "",
    "Ichimoku": "Ichimoku",
    "Instrument.Futures.contract": "contrato",
    "Instrument.Level1.ExtendedFields.NormalMarketSize": "",
    "Instrument.Spreadbet.bet": "bets",
    "InstrumentDetailsPanel.(Indicative symbol)": "(Ativo indicativo)",
    "InstrumentDetailsPanel.1.GeneralInfo": "Informações gerais",
    "InstrumentDetailsPanel.2.TradingInfo": "Informação da negociação",
    "InstrumentDetailsPanel.3.MarginReg": "Solicitações de margem",
    "InstrumentDetailsPanel.4.Fees": "Taxas",
    "InstrumentDetailsPanel.5.Rebates": "Descontos",
    "InstrumentDetailsPanel.6.SessionInfo": "Informações da sessão",
    "InstrumentDetailsPanel.7.Plans": "Planos",
    "InstrumentDetailsPanel.AccruedInterest": "Juros acumulados",
    "InstrumentDetailsPanel.AddLiqudityRebateOrderPerLot": "Adicionar desconto de liquidez por lote",
    "InstrumentDetailsPanel.AddLiqudityRebatePerLot": "Adicionar desconto de liquidez por lote",
    "InstrumentDetailsPanel.AddLiqudityRebatePerVolume": "Adicionar desconto de liquidez por volume",
    "InstrumentDetailsPanel.AFTER_MARKET": "After market",
    "InstrumentDetailsPanel.Allow short positions": "Sim",
    "InstrumentDetailsPanel.AllowedOperations": "Operações permitidas",
    "InstrumentDetailsPanel.AllowedOrderTypes": "Tipos de ordens permitidas",
    "InstrumentDetailsPanel.AllowShortPositions": "Permitir posições de Venda",
    "InstrumentDetailsPanel.Amount": "Qtd.",
    "InstrumentDetailsPanel.AssetClass": "Tipo de Ativo",
    "InstrumentDetailsPanel.BEFORE_MARKET": "Before market",
    "InstrumentDetailsPanel.Cash": "Dinheiro",
    "InstrumentDetailsPanel.Close": "Fechar",
    "InstrumentDetailsPanel.Closed": "Fechado",
    "InstrumentDetailsPanel.CloseOutDeadline": "Prazo de fechamento",
    "InstrumentDetailsPanel.Commisions": "Comissões",
    "InstrumentDetailsPanel.ContactMonth": "Mês de contrato",
    "InstrumentDetailsPanel.ContractMultiplier": "#hidden#",
    "InstrumentDetailsPanel.ContractSize": "Tamanho do contrato",
    "InstrumentDetailsPanel.CouponCycle": "ciclo de Cupom",
    "InstrumentDetailsPanel.CouponRate": "Taxa de Coupon, %",
    "InstrumentDetailsPanel.CurrentSession": "Sessão atual",
    "InstrumentDetailsPanel.DeliveryMethod": "Método de entrega",
    "InstrumentDetailsPanel.DeliveryStatus": "Situação da entrega",
    "InstrumentDetailsPanel.Depend from account": "Depende da conta",
    "InstrumentDetailsPanel.Description": "Descrição",
    "InstrumentDetailsPanel.Exchange": "Bolsa",
    "InstrumentDetailsPanel.ExchangeTradeSession": "#hidden#",
    "InstrumentDetailsPanel.Exp1": "Ativo",
    "InstrumentDetailsPanel.FaceValue": "Valor nominal",
    "InstrumentDetailsPanel.FirstTradeDate": "Primeira data de negociação",
    "InstrumentDetailsPanel.Futures": "Futuros",
    "InstrumentDetailsPanel.FuturesClass": "Classe de futuros",
    "InstrumentDetailsPanel.HiLimit": "Limite Máximo",
    "InstrumentDetailsPanel.Holiday": "Feriado",
    "InstrumentDetailsPanel.HolidayDate": "Data",
    "InstrumentDetailsPanel.HolidayName": "Nome do feriado",
    "InstrumentDetailsPanel.Holidays list": "Lista de feriados",
    "InstrumentDetailsPanel.HolidayType": "Agenda",
    "InstrumentDetailsPanel.immediate": "imediato",
    "InstrumentDetailsPanel.KEYNextHoliday": "Próximo feriado",
    "InstrumentDetailsPanel.LastTradeDate": "Última data de negociação",
    "InstrumentDetailsPanel.LotSize": "Tamanho do lote",
    "InstrumentDetailsPanel.LotStep": "Passo do lote",
    "InstrumentDetailsPanel.LowLimit": "Limite Mínimo",
    "InstrumentDetailsPanel.MAIN": "Principal",
    "InstrumentDetailsPanel.Margin": "",
    "InstrumentDetailsPanel.Margin_ByAccount": "Margem por conta",
    "InstrumentDetailsPanel.MarginBuy": "Compra",
    "InstrumentDetailsPanel.MarginDay": "Dia",
    "InstrumentDetailsPanel.MarginDayBuy": "Compra diária",
    "InstrumentDetailsPanel.MarginDaySell": "Venda diária",
    "InstrumentDetailsPanel.MarginInAccountCurrency": "#hidden#",
    "InstrumentDetailsPanel.MarginOvernight": "Overnight",
    "InstrumentDetailsPanel.MarginOvernightBuy": "Compra 'Overnight'",
    "InstrumentDetailsPanel.MarginOvernightSell": "Venda 'Overnight'",
    "InstrumentDetailsPanel.MarginSell": "Venda",
    "InstrumentDetailsPanel.MaturityDate": "Data de vencimento",
    "InstrumentDetailsPanel.MaximumLot": "Lote máximo",
    "InstrumentDetailsPanel.MaxPositionQtyPerSymbol": "Quant. máxima de posições",
    "InstrumentDetailsPanel.MinimalLot": "Lote mínimo",
    "InstrumentDetailsPanel.NextPaymentDate": "Próximo pagamento de rendimento",
    "InstrumentDetailsPanel.No": "Não",
    "InstrumentDetailsPanel.non trading day": "Fechado",
    "InstrumentDetailsPanel.None": "Nenhum",
    "InstrumentDetailsPanel.NormalMarketSize": "",
    "InstrumentDetailsPanel.Not allow short positions": "Não",
    "InstrumentDetailsPanel.NoticeDate": "Data da notificação",
    "InstrumentDetailsPanel.onlyCloseOrders": "Apenas ordens fechadas",
    "InstrumentDetailsPanel.Open": "Aberto",
    "InstrumentDetailsPanel.OrderPerLot": "Ordem por lote",
    "InstrumentDetailsPanel.PerFill": "Por execução",
    "InstrumentDetailsPanel.PerLot": "Preencher por lote",
    "InstrumentDetailsPanel.PerOrder": "Por ordem",
    "InstrumentDetailsPanel.PerOrderVolume": "Volume da ordem, %",
    "InstrumentDetailsPanel.PerPhoneTransaction": "Por transação telefônica",
    "InstrumentDetailsPanel.PerTransaction": "Por transação",
    "InstrumentDetailsPanel.PerVolume": "Volume executado, %",
    "InstrumentDetailsPanel.Physically": "Fisicamente",
    "InstrumentDetailsPanel.POSTCLOSE": "Pós-mercado",
    "InstrumentDetailsPanel.PREOPEN": "Pré-Market",
    "InstrumentDetailsPanel.PreviousPaymentDate": "Pagamento de rendimentos anterior",
    "InstrumentDetailsPanel.Price": "Preço",
    "InstrumentDetailsPanel.ProductType": "Tipo de produto",
    "InstrumentDetailsPanel.QuotiongCurrency": "Moeda da cotação",
    "InstrumentDetailsPanel.RemoveLiqudityRebateOrderPerLot": "Remover a ordem de desconto da liquidez por lote",
    "InstrumentDetailsPanel.RemoveLiqudityRebatePerLot": "Remover desconto de liquidez por lote",
    "InstrumentDetailsPanel.RemoveLiqudityRebatePerVolume": "Remover desconto de liquidez por volume",
    "InstrumentDetailsPanel.SessionStatus": "Situação da sessão",
    "InstrumentDetailsPanel.SettlementDate": "Data de liquidação",
    "InstrumentDetailsPanel.shortened": "Dia encurtado",
    "InstrumentDetailsPanel.StrikePrice": "Preço 'Strike'",
    "InstrumentDetailsPanel.SwapBuy": "'Swap' de Compra",
    "InstrumentDetailsPanel.SwapSell": "'Swap' de Venda",
    "InstrumentDetailsPanel.Symbol": "Ativo",
    "InstrumentDetailsPanel.T+0": "T + 0 (hoje)",
    "InstrumentDetailsPanel.TickCoast": "Custo do 'Tick'",
    "InstrumentDetailsPanel.TickSize": "Tamanho do 'Tick'",
    "InstrumentDetailsPanel.TradindBlockedOnSession": "Negociação bloqueada na sessão",
    "InstrumentDetailsPanel.TradingBalance": "Sistema de liquidação",
    "InstrumentDetailsPanel.TradingHalt": "Parada na negociação",
    "InstrumentDetailsPanel.TradingStatus": "Situação da negociação",
    "InstrumentDetailsPanel.Underlier": "Subjacente",
    "InstrumentDetailsPanel.VAT": "IVA",
    "InstrumentDetailsPanel.Volume": "Volume executado",
    "InstrumentDetailsPanel.VolumeWithMinPD": "Volume executado com mínimo p/d, %",
    "InstrumentDetailsPanel.working": "Abertura",
    "InstrumentDetailsPanel.Yes": "Sim",
    "InstrumentDetailsPanel.Yield": "Rendimento",
    "InstrumentDetailsPanel.YTM": "Ren%",
    "InstrumentDetailsPanel.YTM.RefreshLink": "Atualizar",
    "IOC": "Tudo ou Parcial",
    "IsAllowedResponceReason.ExecuteAtMarketNotAllowedForBindOrders": "Execussão á Mercado não é permitida para ordens comprometidas",
    "IsAllowedResponceReason.ExecuteAtMarketNotAllowedForSlTp": "Execussão á Mercado não permitido para 'Stop Loss'/'Take Profit'",
    "IsAllowedResponceReason.ExecuteAtMarketNotAllowedOrderType": "Alterar o tipo de ordem ou ordem à Mercado não está permitido",
    "IsAllowedResponceReason.InstrumentForAccountDelivery": "Ativos para entrega da conta",
    "IsAllowedResponceReason.InstrumentTradingMode": "Negociação deste Ativo não está permitida",
    "IsAllowedResponceReason.LockTrading": "Negociação bloqueada na plataforma",
    "IsAllowedResponceReason.LockTradingByPassword": "Por favor, digite a senha de negociação na parte inferior do terminal",
    "IsAllowedResponceReason.NoLastQuote": "Não existe última cotação",
    "IsAllowedResponceReason.NotAllowedByOrderStaus": "Operações não permitidas pela situação da ordem",
    "IsAllowedResponceReason.NotAllowedPositionCloseOnly": "Only positions closing is allowed",
    "IsAllowedResponceReason.NotAllowedRouteOrderType": "A Ordem não pode ser criada, uma vez que o tipo de Validade e tipo de ordem não são suportados",
    "IsAllowedResponceReason.NotClosablePosition": "A posição não pode ser fechada",
    "IsAllowedResponceReason.NotLoadCache": "Dados de acesso rápido não foram carregados",
    "IsAllowedResponceReason.NotVisibleInstrumentRoute": "Ativo ou rota não visíveis",
    "IsAllowedResponceReason.OperationNotAllowedByOrderType": "Operação não permitida por este tipo de ordem",
    "IsAllowedResponceReason.OperationNotAllowedBySession": "Operação não permitida para a sessão",
    "IsAllowedResponceReason.OrderTypeNotAllowedBySession": "Tipo de ordem não permitida para a sessão",
    "IsAllowedResponceReason.RouteNotRunning": "A rota não está sendo executada",
    "IsAllowedResponceReason.RuleAdministration": "Regra de administração",
    "IsAllowedResponceReason.RuleClosePositionDisable": "As regras não permitem o fechamento ds posição",
    "IsAllowedResponceReason.RuleFunctionSelfTrading": "Regra de função de auto negociação",
    "IsAllowedResponceReason.RuleFunctionTrading": "As regras não permitem a negociação",
    "IsAllowedResponceReason.RuleOCO": "regra OCO",
    "IsAllowedResponceReason.RuleSlTpNotAllowed": "As regras não perminte 'Stop Loss'/'Take Profit'",
    "IsAllowedResponceReason.RuleTrStop": "Regra Tr. stop",
    "IsAllowedResponceReason.Unknown": "Razão desconhecida",
    "Keltner Channel": "Keltner Channel",
    "Limit": "Limit",
    "Linear Weighted Moving Average": "Linear Weighted Moving Average",
    "LOC": "LOC",
    "LOO": "LOO",
    "Manual": "Manual",
    "Market": "Mercado",
    "MarketDepthPanel.ColoringMethod.ByPriceLVL": "Por nível de preço",
    "MarketDepthPanel.ColoringMethod.RelativeToVolume": "Relativo ao volume",
    "MarketDepthPanel.ColoringMethod.SizeHistogram": "",
    "McGinley Dynamic": "McGinley Dynamic",
    "MDLotsMode.General": "Padrões gerais ({1})",
    "MDLotsMode.Lots": "Lotes",
    "MDLotsMode.RealSize": "Quantidade real",
    "MessageBox.ThemeChanged.Title": "Atenção",
    "MOC": "MOC",
    "Modified Moving Average": "Modified Moving Average",
    "Momentum": "Momentum",
    "MOO": "MOO",
    "Moving Average Envelope": "Moving Average Envelope",
    "Moving Average/Convergence Divergence": "Moving Average/Convergence Divergence",
    "NetTrader.SaveWorkspace.Save": "Salvar área de trabalho",
    "NumericComboCtrl.Edit": "Editar",
    "OCO": "OCO",
    "On Balance Volume": "On Balance Volume",
    "OnClose": "Ao Fechar",
    "OnOpen": "Ao Abrir",
    "Order.ExecutionType.ACCEPTED": "",
    "Order.ExecutionType.ACTIVATED": "",
    "Order.ExecutionType.CANCELED": "",
    "Order.ExecutionType.FILLED": "",
    "Order.ExecutionType.NEW": "",
    "Order.ExecutionType.PART_FILLED": "",
    "Order.ExecutionType.REFUSED": "",
    "Order.ExecutionType.REPLACED": "",
    "Order.ExecutionType.RESTATED": "",
    "Order.ExecutionType.UNKNOWN": "",
    "OrderEntry.InfoBlock.After trade funds": "Fundos após negociação",
    "OrderEntry.InfoBlock.Availible funds": "Fundos disponíveis",
    "OrderEntry.InfoBlock.Balance": "Saldo",
    "OrderEntry.InfoBlock.Fee": "Taxa",
    "OrderEntry.InfoBlock.Gross": "Bruto",
    "OrderEntry.InfoBlock.Impact on portfolio": "Impacto na carteira",
    "OrderEntry.InfoBlock.Init. margin": "Margem inicial",
    "OrderEntry.InfoBlock.Maint. margin": "Manutençao da Margem",
    "OrderEntry.InfoBlock.Margin availible": "Margem disponível",
    "OrderEntry.InfoBlock.Net": "Líquido",
    "OrderEntry.InfoBlock.Spread in loss": "'Spread' perda inicial",
    "OverlayScreen.barsBodyLabel": "Corpo",
    "OverlayScreen.barsBorderLabel": "Borda",
    "OverlayScreen.barsHiLowLabel": "Linha",
    "OverlayScreen.cancelButton": "Cancelar",
    "OverlayScreen.dataLabel": "Tipo de dados",
    "OverlayScreen.dojiLabel": "Doji",
    "OverlayScreen.histogramLineLabel": "Linha do Histograma",
    "OverlayScreen.instrumentLabel": "Ativo",
    "OverlayScreen.Main scale": "Escala principal",
    "OverlayScreen.name": "Sobreposição",
    "OverlayScreen.numberOverlaysError": "Você não pode adicionar mais do que 5 sobreposições.",
    "OverlayScreen.okButton": "OK",
    "OverlayScreen.Overlays": "Sobreposições",
    "OverlayScreen.priceLabel": "Escala de preço",
    "OverlayScreen.priceMarkerCheckbox": "Exibir marcador de preço",
    "OverlayScreen.Separate scale": "Escala separada",
    "OverlayScreen.solidPriceLabel": "Área",
    "OverlayScreen.styleLabel": "Estilo",
    "OverlayScreen.wickLabel": "Pavio",
    "panel.accountDetails.Groups.1.General": "Geral",
    "panel.accountDetails.Groups.2.Margin": "Margem",
    "panel.accountDetails.Groups.3.AccountActivity": "Atividade da conta",
    "panel.accountDetails.Groups.4.TodayResults": "Resultados de hoje",
    "panel.accountDetails.Groups.5.RiskManagement": "Gerenciamento de risco",
    "panel.accountDetails.Groups.55.Plans": "Planos",
    "panel.accountDetails.Groups.6.Info": "Informação",
    "panel.AccountDetailsPanel": "Detalhes da conta",
    "panel.AccountDetailsPanel.InfoTooltip": "Altere os campos da ferramenta, arrastando e soltando da tabela",
    "panel.AccountLink.SymbolLink.ToolTip": "Link do Ativo",
    "panel.AccountLink.ToolTip": "Link da conta",
    "panel.accounts": "Contas",
    "panel.accounts.AccountDescription": "#hidden#",
    "panel.accounts.accountDetails.RiskManagement.Trading.Daily loss limit reached": "Limite de perda diária atingido",
    "panel.accounts.accountDetails.RiskManagement.Trading.Max day volume  reached": "Volume diário máximo atingido",
    "panel.accounts.accountDetails.RiskManagement.Trading.Order Flow Limit(per day)": "Limite de fluxo de ordem (por dia)",
    "panel.accounts.accountDetails.RiskManagement.Trading.State.Disabled": "Desabilitado",
    "panel.accounts.accountDetails.RiskManagement.Trading.State.Enabled": "Habilitado",
    "panel.accounts.accountDetails.RiskManagement.TradingStatus.Active": "Ativo",
    "panel.accounts.accountDetails.RiskManagement.TradingStatus.Auto closed": "Fechado automaticamente",
    "panel.accounts.accountDetails.RiskManagement.TradingStatus.Closed": "Fechado",
    "panel.accounts.accountDetails.RiskManagement.TradingStatus.Disabled by risk rules": "Negociação desativada por regras de risco",
    "panel.accounts.accountDetails.RiskManagement.TradingStatus.MarginWarning": "",
    "panel.accounts.accountDetails.RiskManagement.TradingStatus.Max trailing drawdown was reached": "Máximo rebaixamento atingido",
    "panel.accounts.accountDetails.RiskManagement.TradingStatus.Suspend": "Suspenso",
    "panel.accounts.accountDetails.RiskManagement.TradingStatus.Unrealized loss limit reached": "Limite de perda não realizado atingido",
    "panel.accounts.accountDetails.RiskManagement.TradingStatus.Weekly lost limit reached": "Limite de perda semanal atingido",
    "panel.accounts.AccountMode": "Modo da conta",
    "panel.accounts.AccountType": "Tipo de conta",
    "panel.accounts.AccountValue": "Valor da conta",
    "panel.accounts.AllowOvernightTrading": "Permitir negociação 'overnight'",
    "panel.accounts.AllowTradingPrePost": "Negociação em 'Pré-Market/After-Market'",
    "panel.accounts.Asset": "Ativo",
    "panel.accounts.AssetDescription": "Descrição do Ativo",
    "panel.accounts.AssetType": "Tipo de Ativo",
    "panel.accounts.AvailableMargin": "Fundos disponíveis",
    "panel.accounts.Balance": "Saldo",
    "panel.accounts.BalancePlusAllRisks": "Saldo + Risco total",
    "panel.accounts.BeginBalance": "Líquido de hoje",
    "panel.accounts.BlockedByOrders": "Margem usada pelas ordens",
    "panel.accounts.BlockedByPamm": "Fundo de capital investido",
    "panel.accounts.BlockedForStocks": "#hidden#",
    "panel.accounts.BlockedForStocksNew": "Bloqueado para 'Ações'",
    "panel.accounts.CashBalance": "Saldo em dinheiro",
    "panel.accounts.ClientType": "Tipo de terminal",
    "panel.accounts.Commissions": "Comissões",
    "panel.accounts.CreditValue": "Valor de crédito",
    "panel.accounts.CurBalance": "Saldo projetado",
    "panel.accounts.CurMargin": "Margem Inicial, %",
    "panel.accounts.CurrentDailyLoss": "Perda diária",
    "panel.accounts.CurrentFundCapital": "Fundo de capital atual",
    "panel.accounts.CurrentStatus": "Trading status",
    "panel.accounts.CurrentWeeklyLoss": "Perda semanal",
    "panel.accounts.Email": "E-mail",
    "panel.accounts.Estimate value": "Valor estimado",
    "panel.accounts.FundCapitalGain": "Ganhos dos fundos de capitais",
    "panel.accounts.FundsUsed": "Fundos usados",
    "panel.accounts.FundsUsedPercent": "Fundos usados, %",
    "panel.accounts.GrossPNL": "Bruto de hoje",
    "panel.accounts.InsertOnBalance": "Inserido no saldo",
    "panel.accounts.InterestRate": "Taxa de juros, %",
    "panel.accounts.IntradayRiskManagement": "Situação do gerenciamento de risco",
    "panel.accounts.Leverage": "Alavancagem",
    "panel.accounts.LockedBalance": "Saldo bloqueado",
    "panel.accounts.LockedBy": "Bloqueado",
    "panel.accounts.Login": "Conta",
    "panel.accounts.MaintMargin": "Nível de risco, %",
    "panel.accounts.MarginAndRisks": "Margem e ríscos",
    "panel.accounts.MarginAvailableReal": "Margem disponível",
    "panel.accounts.MarginDeficiency": "Deficiência de margem",
    "panel.accounts.MarginForOthers": "#hidden#",
    "panel.accounts.MarginRequirement": "Solicidado para manutenção de posição",
    "panel.accounts.MarginStopOut": "Manutenção de Margem",
    "panel.accounts.MarginStopTrade": "Parar negociações",
    "panel.accounts.MarginSurplus": "Margem Excedente",
    "panel.accounts.MarginUsedNew": "Margem inicial",
    "panel.accounts.MarginWarning": "Alerta de margem",
    "panel.accounts.marginWarnPercent": "Nível de alerta de margem",
    "panel.accounts.MarketDataCharging": "Dados do mercado carregados",
    "panel.accounts.MaxDailyLossLimit": "Limite diário de perda",
    "panel.accounts.MaxDailyProfit": "Objetivo de lucro diário",
    "panel.accounts.MaxDayVolume": "Volume máximo do dia",
    "panel.accounts.MaximumLot": "Lote máximo",
    "panel.accounts.MaxOrderAmount": "",
    "panel.accounts.MaxOrderCapital": "Capital de ordem máximo",
    "panel.accounts.MaxOrdersPerDay": "Nº Limite de fluxo de ordem (por dia)",
    "panel.accounts.MaxPendingOrders": "Nº máximo de ordens pendentes",
    "panel.accounts.MaxPendingOrdersValue": "Max. valor de ordens pendentes",
    "panel.accounts.MaxPositionQtyPerSymbol": "Quantidade máxima por Ativo",
    "panel.accounts.MaxPositions": "Nº máximo de posições",
    "panel.accounts.MaxWeekLostLimit": "Limite de perda semanal",
    "panel.accounts.MinMargin": "Margem usada para posições",
    "panel.accounts.Murkups": "Correção",
    "panel.accounts.N/A_TelephoneNumber": "N/A",
    "panel.accounts.OptionPremiumReq": "",
    "panel.accounts.OptionValue": "Valor Opção",
    "panel.accounts.Orders": "Nº de ordens",
    "panel.accounts.Owner": "Cliente",
    "panel.accounts.Positions": "Nº de posições",
    "panel.accounts.PowerOfAttorney": "",
    "panel.accounts.Profit": "L/P bruto aberto",
    "panel.accounts.ProfitNet": "L/P líquido aberto",
    "panel.accounts.Role": "Tipo de fundo",
    "panel.accounts.Spreads": "Spreads",
    "panel.accounts.Status": "Situação",
    "panel.accounts.Collateral": "",
    "panel.accounts.StocksLiquidity": "Liquidez de Ações",
    "panel.accounts.StocksOrdersReq": "Ordens de ações",
    "panel.accounts.StocksValue": "Valor da Ação",
    "panel.accounts.stopOutPercent": "Stop out level",
    "panel.accounts.stopTradingPercent": "Nível de parada de negociação",
    "panel.accounts.Swap": "Troca",
    "panel.accounts.TelephoneNumber": "Número do telefone",
    "panel.accounts.TodayFees": "Taxas de hoje",
    "panel.accounts.TodayRebates": "Descontos de hoje",
    "panel.accounts.TodaySwaps": "#hidden#",
    "panel.accounts.TodayTrades": "Nº de negociações de hoje",
    "panel.accounts.TodayTurnover": "Movimento de hoje",
    "panel.accounts.TodayVolume": "Volume de hoje",
    "panel.accounts.TotalMaxPositionsQty": "Quantidade máxima da posição",
    "panel.accounts.TrailingDrawdownLevel": "Modificar nivel de rebaixamento",
    "panel.accounts.UnrealizedLoss": "Perda não realizada",
    "panel.accounts.UnrealizedLossLimit": "Limite de perda não realizado",
    "panel.accounts.UnsettledCash": "Ganho incerto",
    "panel.accounts.UserGroup": "Grupo do usuário",
    "panel.accounts.UserID": "ID do usuário",
    "panel.accounts.UserLogin": "Login do usuário",
    "panel.accounts.VolumeLimitForEquities": "",
    "panel.accounts.VolumeLimitForFutures": "",
    "panel.accounts.VolumeLimitForOptions": "",
    "panel.accounts.WithdrawalAvailable": "Disponível para Retirada",
    "panel.assetBalances": "Contas",
    "panel.backoffice": "Backoffice",
    "panel.backoffice.You need to add connection!": "Você deve adicionar conexão!",
    "panel.positionsBalance": "Saldo das posições",
    "panel.caption.NotAllowedMode": "Nas regras definidas atualmente o painel atual não está permitido",
    "panel.caption.showHideToolbar": "Exibir barra de ferramentas",
    "panel.chart": "Gráfico",
    "panel.close": "Fechar",
    "panel.closePosition.Gross_P/L_ToClose": "L/P bruto para fechar",
    "panel.closePosition.QunatityToClose": "Quantidade para fechar",
    "panel.copypanel": "Duplicar painel",
    "panel.detail": "Cotação detalhada",
    "panel.detail.AuctionEndTime": "",
    "panel.detail.CBAuctionStart": "",
    "panel.detail.change": "Variação",
    "panel.detail.changePr": "Variação, %",
    "panel.detail.CurrentSession": "",
    "panel.detail.high": "Máximo",
    "panel.detail.last": "Último",
    "panel.detail.Limit.High": "",
    "panel.detail.Limit.Low": "",
    "panel.detail.low": "Mínimo",
    "panel.detail.NormalMarketSize": "",
    "panel.detail.OffExchangeValue": "",
    "panel.detail.OffExchangeVolume": "",
    "panel.detail.open": "Abertura",
    "panel.detail.TradedValue": "",
    "panel.detail.TradingStatus": "",
    "panel.detail.volume": "Volume",
    "panel.EventLog.Action": "Evento",
    "panel.EventLog.Date": "Data",
    "panel.EventLog.eventType.alerts": "Alertas",
    "panel.EventLog.eventType.comment": "Comentário",
    "panel.EventLog.eventType.disconnect": "Desconectar",
    "panel.EventLog.eventType.exception": "Exceção",
    "panel.EventLog.eventType.info": "Informações",
    "panel.EventLog.eventType.none": "Nenhum",
    "panel.EventLog.eventType.system": "Sistema",
    "panel.EventLog.eventType.trading": "Negociação",
    "panel.EventLog.eventType.updater": "Atualizador",
    "panel.EventLog.menu.Clear": "Limpar",
    "panel.EventLog.Message": "Mensagem",
    "panel.EventLog.NamePanel": "Log de eventos",
    "panel.EventLog.Roundtrip": "Ida e volta, ms",
    "panel.EventLog.Type": "Tipo",
    "panel.GridPanel.listButton.ToolTip": "Selecionar Ativos para o painel",
    "panel.GridPanel.refreshAllCharts.ToolTip": "Atualizar todos os gráficos",
    "panel.GridPanel.resetMI.Text": "Restaurar tamanho dos painéis",
    "panel.informer": "Cotações",
    "screen.Alerts.Action.Edit": "Editar",
    "screen.Alerts.Action.Popup": "Mostrar o Pop-up",
    "screen.Alerts.Action.Sound": "Som",
    "screen.Alerts.Action.SoundAndPopup": "Som+Pop-up",
    "screen.Alerts.Action.Start": "Começar",
    "screen.Alerts.AddAlert": "Criar um novo",
    "screen.Alerts.AddAlert.Tooltip": "Clique para criar um novo alerta",
    "screen.Alerts.AddAlert.Disabled.Tooltip": "O número máximo de alertas foi excedido",
    "screen.Alerts.AfterExecute.Remove": "Remover",
    "screen.Alerts.AfterExecute.Stop": "Parar",
    "screen.Alerts.Alert": "Alerta",
    "screen.Alerts.Button.Apply.Tooltip": "Clique para aplicar as alterações",
    "screen.Alerts.Button.Create": "Criar",
    "screen.Alerts.Header.Create": "Criar um alerta",
    "screen.Alerts.Header.Edit": "Edição",
    "screen.Alerts.Importance.High": "Máximo",
    "screen.Alerts.Importance.Low": "Mínimo",
    "screen.Alerts.Importance.Medium": "Médio",
    "screen.Alerts.Label.Action": "Açao",
    "screen.Alerts.Label.AfterExecute.Tooltip": "Escolha o comportamento do alerta após a execução",
    "screen.Alerts.Label.AfterExecute": "Após a execução",
    "screen.Alerts.Label.AlertType.Tooltip": "Definir um tipo de alerta",
    "screen.Alerts.Label.AlertType": "Tipo de alerta",
    "screen.Alerts.Label.Condition.Tooltip": "Definir uma condição de alerta",
    "screen.Alerts.Label.Condition": "Condição",
    "screen.Alerts.Label.Importance": "Importância",
    "screen.Alerts.Label.Symbol": "Ativo",
    "screen.Alerts.Label.Value": "Valor",
    "screen.Alerts.Message": "Mensagem de alerta",
    "screen.Alerts.RemoveAlertMessage": "O alerta será removido. Você tem certeza?",
    "screen.Alerts.TableItem.Condition": "Condição",
    "screen.Alerts": "Alertas",
    "screen.Alerts.Ask": "Ask",
    "screen.Alerts.Bid": "Bid",
    "screen.Alerts.Last": "Último",
    "screen.Alerts.ChangePr": "Variação, %",
    "screen.Alerts.Volume": "Volume",
    "panel.instrumentInfo.AvgPrice": "Preço médio:",
    "panel.instrumentInfo.P/L": "L/P:",
    "panel.instrumentInfo.Qty": "Quantidade:",
    "panel.instruments": "Ativos",
    "panel.instrumentsInfo": "Ativo",
    "panel.knowledgebase": "Base de conhecimento",
    "panel.level2": "'Book' de Ofertas",
    "panel.Level2.Age": "#hidden#",
    "panel.level2.Auction": "Lei",
    "panel.Level2.AvgPrice": "Preço médio",
    "panel.Level2.Contr. CCY Total value": "Valor total $$ contrato",
    "panel.Level2.Contr. CCY value": "Valor $$ contrato",
    "panel.Level2.DayTradeVolume": "Volume",
    "panel.Level2.menu.View": "Exibir",
    "panel.Level2.menu.View.InstrInfo": "Barra de posição",
    "panel.Level2.menu.View.Level1": "Barra de ferramenta Nivel 1",
    "panel.Level2.menu.View.Order Entry": "Entrada de ordem",
    "panel.Level2.MPID": "MPID",
    "panel.Level2.Number": "Ordem",
    "panel.Level2.oe.BuyButton": "Compra",
    "panel.Level2.oe.SellButton": "Venda",
    "panel.Level2.Price": "Preço",
    "panel.Level2.Size": "Quantidade",
    "panel.Level2.Source": "Corretora",
    "panel.Level2.Time": "Tempo",
    "panel.Level2.TotalVol": "Quantidade total",
    "panel.log": "Log de eventos",
    "panel.menu.GroupBy": "Agrupar por",
    "panel.menu.new": "Novo",
    "panel.menu.Remove": "Remover",
    "panel.menu.resetToDefault": "Restaurar padões",
    "panel.neworder": "Entrada de ordem",
    "panel.newOrderEntry.accountLabel": "Conta",
    "panel.newOrderEntry.AddAskButton": "Venda 'Ask'",
    "panel.newOrderEntry.AddBitButton": "Compra 'Bid'",
    "panel.newOrderEntry.amountLabel": "Quantidade",
    "panel.newOrderEntry.BuyAskButton": "Compra 'Ask'",
    "panel.newOrderEntry.BuyMarketButton": "C.Mercado",
    "panel.newOrderEntry.buySideCheckBox": "Compra",
    "panel.newOrderEntry.instrumentLabel": "Ativo",
    "panel.newOrderEntry.limitPricePanel": "Preço 'Limit'",
    "panel.newOrderEntry.placeOrderButton": "Colocar ordem",
    "panel.newOrderEntry.ProductType": "Tipo de Produto",
    "panel.newOrderEntry.RefreshMarginReq": "Atualizar requerimento de margem",
    "panel.newOrderEntry.riskDetailsLabel": "Margem",
    "panel.newOrderEntry.SellBidButton": "Venda 'Bid'",
    "panel.newOrderEntry.SellMarketButton": "V.Mercado",
    "panel.newOrderEntry.sellSideCheckBox": "Venda",
    "panel.newOrderEntry.Side": "Lado",
    "panel.newOrderEntry.singleOrderRadio": "Simples",
    "panel.newOrderEntry.slPriceRisk": "Preço SL",
    "panel.newOrderEntry.slPriceRiskOffset": "Distància do SL",
    "panel.newOrderEntry.stopLossCheckBox": "Preço SL",
    "panel.newOrderEntry.stopLossCheckBox.offset": "Distância SL",
    "panel.newOrderEntry.stopPricePanel": "Preço 'Stop'",
    "panel.newOrderEntry.takeProfitCheckBox": "Preço TP",
    "panel.newOrderEntry.takeProfitCheckBox.offset": "Distância TP",
    "panel.newOrderEntry.tifPanel": "Validade",
    "panel.newOrderEntry.tpPriceRisk": "Preço TP",
    "panel.newOrderEntry.tpPriceRiskOffset": "Distância do TP",
    "panel.newOrderEntry.trstopOffset": "Distância TS",
    "panel.newOrderEntry.typePanel": "Tipo de ordem",
    "panel.NewTrades.Account": "Conta",
    "panel.NewTrades.Amount": "Quantidade",
    "panel.NewTrades.Bought": "Comprado",
    "panel.NewTrades.Commission": "Taxa de excução",
    "panel.NewTrades.Commission.InInstrumentQuotingCurrency": "#hidden#",
    "panel.NewTrades.Date": "Data",
    "panel.NewTrades.Exchange": "#hidden#",
    "panel.NewTrades.ExpirationDate": "Vencimento",
    "panel.NewTrades.ExternalOrderId": "#hidden#",
    "panel.NewTrades.ExternalPrice": "Preço externo",
    "panel.NewTrades.ExternalTradeId": "ID externo",
    "panel.NewTrades.instrType": "Tipo de Ativo",
    "panel.NewTrades.Instrument": "Ativo",
    "panel.NewTrades.Login": "Login",
    "panel.NewTrades.Net_PL": "L/P Líquido",
    "panel.NewTrades.Net_PL.InInstrumentQuotingCurrency": "#hidden#",
    "panel.NewTrades.Operation": "Lado",
    "panel.NewTrades.OrderId": "ID da ordem",
    "panel.NewTrades.OrderType": "Tipo de ordem",
    "panel.NewTrades.Price": "Preço",
    "panel.NewTrades.ProductType": "Tipo de produto",
    "panel.NewTrades.PurchasePrice": "Exposição",
    "panel.NewTrades.RealizedProfitLoss": "L/P bruto",
    "panel.NewTrades.RealizedProfitLoss.InInstrumentQuotingCurrency": "#hidden#",
    "panel.NewTrades.Rebates": "Descontos",
    "panel.NewTrades.Route": "Rota",
    "panel.NewTrades.Sold": "Vendido",
    "panel.NewTrades.StrikePrice": "Preço 'Strike'",
    "panel.NewTrades.Time": "Tempo",
    "panel.NewTrades.TradeID": "ID da negociação",
    "panel.NewTrades.TradeIDLabel": "#hidden#",
    "panel.OrderBook.Account": "Conta",
    "panel.OrderBook.Action": "Lado",
    "panel.OrderBook.Bought": "Comprado",
    "panel.OrderBook.Date": "Data",
    "panel.OrderBook.DislocedQty": "#hidden#",
    "panel.OrderBook.Event": "Evento",
    "panel.OrderBook.ExpirationDate": "Data Exp.",
    "panel.OrderBook.ExternalOrderID": "#hidden#",
    "panel.OrderBook.FilledQuantity": "#hidden#",
    "panel.OrderBook.instrType": "Tipo de Ativo",
    "panel.OrderBook.Instrument": "Ativo",
    "panel.OrderBook.Login": "Login",
    "panel.OrderBook.Message": "Mensagem",
    "panel.OrderBook.OrderNumber": "ID da ordem",
    "panel.OrderBook.Price": "Preço",
    "panel.OrderBook.ProductType": "Tipo de produto",
    "panel.OrderBook.Quantity": "Quantidade",
    "panel.OrderBook.ReminingQuantity": "#hidden#",
    "panel.OrderBook.RemoteID": "ID remoto",
    "panel.OrderBook.Route": "Rota",
    "panel.OrderBook.Sold": "Vendido",
    "panel.OrderBook.StopPrice": "Preço 'Stop'",
    "panel.OrderBook.StrikePrice": "Preço 'Strike'",
    "panel.OrderBook.TIF": "Validade",
    "panel.OrderBook.Time": "Tempo",
    "panel.OrderBook.TraderID": "Modificado pelo usuário",
    "panel.OrderBook.Type": "Tipo",
    "panel.orderEntry": "Entrada de ordens",
    "panel.orderHistory": "Histórico de ordens",
    "panel.orders": "Ordens abertas",
    "panel.orders.Account": "Conta",
    "panel.orders.action": "Lado",
    "panel.orders.AvgFilledPrice": "#hidden#",
    "panel.orders.BindTo": "Vinculada a",
    "panel.orders.Cancel": "",
    "panel.orders.Comment": "#hidden#",
    "panel.orders.Comments": "Comentários",
    "panel.orders.Ct_Ahead": "Ct. adiante",
    "panel.orders.CurrentPrice": "Preço atual",
    "panel.orders.date": "Data",
    "panel.orders.date_time": "",
    "panel.orders.disclosedQty": "#hidden#",
    "panel.orders.Event": "Evento",
    "panel.orders.Exchange": "Bolsa",
    "panel.orders.ExpirationDate": "Data Exp.",
    "panel.orders.ExternalOrderId": "#hidden#",
    "panel.orders.ExternalOrderStatus": "#hidden#",
    "panel.orders.GroupId": "ID de grupo MAM",
    "panel.orders.InitReq": "Sol. inicial",
    "panel.orders.Instr_Type": "Tipo de Ativo",
    "panel.orders.Login": "Login",
    "panel.orders.Market": "Mercado",
    "panel.orders.menu.CancelAllByInstrument": "Cancelar tudo pelo Ativo selecionado",
    "panel.orders.menu.CancelAllForAccount": "Cancelar tudo para a conta selecionada",
    "panel.orders.menu.CancelAllOrders": "Cancelar todas as ordens",
    "panel.orders.menu.CancelOrder": "Cancelar ordens",
    "panel.orders.menu.ExecuteAsMarket": "Mudar para à Mercado",
    "panel.orders.menu.ExecuteAsMarket.question": "Tem certeza de que deseja executar ordem ao preço de Mercado?",
    "panel.orders.menu.ModifyOrder": "Modificar ordem...",
    "panel.orders.menu.Remove": "Cancelar ordem",
    "panel.orders.OCOGrouID": "ID do grupo OCO",
    "panel.orders.price": "Preço",
    "panel.orders.ProductType": "Tipo de produto",
    "panel.orders.quantity": "Quantidade",
    "panel.orders.QuantityFilled": "Qtd. executada",
    "panel.orders.QuantityRemaining": "Qtd. restante",
    "panel.orders.Reference": "ID da ordem",
    "panel.orders.RemoteID": "ID remoto",
    "panel.orders.Route": "Rota",
    "panel.orders.SL": "SL",
    "panel.orders.SL_Offset": "Distância SL",
    "panel.orders.SL_Price": "Preço SL",
    "panel.orders.Status": "Situação",
    "panel.orders.StopPrice": "Preço 'Stop'",
    "panel.orders.StrikePrice": "Preço 'Strike'",
    "panel.orders.symbol": "Ativo",
    "panel.orders.symbolDescription": "Descrição do Ativo",
    "panel.orders.TIF": "Validade",
    "panel.orders.time": "Tempo",
    "panel.orders.Token": "ID da ordem",
    "panel.orders.TP": "TP",
    "panel.orders.TP_Offset": "Distância TP",
    "panel.orders.TP_Price": "Preço TP",
    "panel.orders.TraderID": "ID do operador",
    "panel.orders.type": "Tipo",
    "panel.orders.UserGroup": "Grupo do usuário",
    "panel.positions": "Posições",
    "panel.positions.Account": "Conta",
    "panel.positions.AvailableForSell": "",
    "panel.positions.Basis": "Preço de abertura",
    "panel.positions.Close": "",
    "panel.positions.comments": "Comentários",
    "panel.positions.commissions": "Taxa",
    "panel.positions.commissions.InstrumentCurrency": "#hidden#",
    "panel.positions.confirmation.Reverse": "Inverter",
    "panel.positions.confirmation.ReverseAllPositions": "Tem certeza que deseja inverter todas as posições?",
    "panel.positions.confirmation.ReverseByInstrument": "Tem certeza que deseja inverter todas as posições por Ativo e conta selecionada?",
    "panel.positions.confirmation.ReverseSelectedPositions": "Tem certeza que deseja inverter posições selecionadas?",
    "panel.positions.cur_price": "Preço atual",
    "panel.positions.date_time": "Data/hora",
    "panel.positions.Delta": "Delta",
    "panel.positions.ExpDate": "Data de venc.",
    "panel.positions.exposition_usd": "Exposição da posição",
    "panel.positions.Gamma": "Gamma",
    "panel.positions.GroupId": "ID de grupo MAM",
    "panel.positions.InitReq": "Sol. inicial",
    "panel.positions.IV": "IV",
    "panel.positions.Login": "Login",
    "panel.positions.MaintReq": "Sol. de manutenção",
    "panel.positions.MarginSuplus": "Margem supl./def.",
    "panel.positions.menu.Close": "Fechar posição",
    "panel.positions.menu.Modify": "Modificar posições",
    "panel.positions.menu.ShowToolbar": "Exibir barra de ferramentas",
    "panel.positions.menu.ShowTotals": "Exibir totais",
    "panel.positions.menu.View": "Exibir",
    "panel.positions.Net_PL": "L/P Liquido",
    "panel.positions.Net_PL.InstrumentCurrency": "#hidden#",
    "panel.positions.Operation": "Lado",
    "panel.positions.PL_ticks": "L/P, distância",
    "panel.positions.PL_ticks.points": "Pontos",
    "panel.positions.PL_ticks.ticks": "ticks",
    "panel.positions.pos_number": "Posição ID",
    "panel.positions.PositionValue": "Valor da pos.",
    "panel.positions.ProductType": "Tipo de produto",
    "panel.positions.profit_usd": "L/P bruto",
    "panel.positions.profit_usd.InstrumentCurrency": "#hidden#",
    "panel.positions.Qunatity": "Quantidade",
    "panel.positions.RealizedPnL": "#hidden#",
    "panel.positions.RealizedPnL.InstrumentCurrency": "#hidden#",
    "panel.positions.Rho": "Rho",
    "panel.positions.route": "Rota",
    "panel.positions.SL": "Preço SL",
    "panel.positions.SL_Value": "SL, valor",
    "panel.positions.strike": "Preço 'Strike'",
    "panel.positions.swaps": "Swaps",
    "panel.positions.Symbol": "Ativo",
    "panel.positions.SymbolDescription": "Descrição do Ativo",
    "panel.positions.Theta": "Theta",
    "panel.positions.TP": "TP",
    "panel.positions.type": "Tipo de Ativo",
    "panel.positions.UsedMargin": "Margem usada",
    "panel.positions.UserGroup": "Grupo do usuário",
    "panel.positions.Vega": "Vega",
    "panel.settings.Information": "Informações",
    "panel.settings.saveDefault": "Salvo com sucesso!",
    "panel.terceraChart": "Gráfico",
    "panel.TerceraSymbolLookupDropDownForm.Add": "Adicionar",
    "panel.TerceraSymbolLookupDropDownForm.All": "Todos",
    "panel.TerceraSymbolLookupDropDownForm.Bonds": "Títulos",
    "panel.TerceraSymbolLookupDropDownForm.Cancel": "Cancelar",
    "panel.TerceraSymbolLookupDropDownForm.CFDs": "CFDs",
    "panel.TerceraSymbolLookupDropDownForm.collapceAllButton": "Recolher todos",
    "panel.TerceraSymbolLookupDropDownForm.crypto": "Crypto CCY",
    "panel.TerceraSymbolLookupDropDownForm.Equities": "Ações",
    "panel.TerceraSymbolLookupDropDownForm.ETFs": "ETFs",
    "panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.All exchanges": "Todas os Ativos",
    "panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.exchanges": "Ativos",
    "panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.No exchanges": "Sem Ativos",
    "panel.TerceraSymbolLookupDropDownForm.expandExchangesButton": "Mostrar Ativos",
    "panel.TerceraSymbolLookupDropDownForm.expandTypesButton": "Mostrar sub-grupos",
    "panel.TerceraSymbolLookupDropDownForm.Forex": "Forex",
    "panel.TerceraSymbolLookupDropDownForm.Forward": "frente",
    "panel.TerceraSymbolLookupDropDownForm.Futures": "Futuros",
    "panel.TerceraSymbolLookupDropDownForm.indices": "Índices",
    "panel.TerceraSymbolLookupDropDownForm.Options": "Opções",
    "panel.TerceraSymbolLookupDropDownForm.Portfolio": "Sintetizado",
    "panel.TerceraSymbolLookupDropDownForm.Select": "",
    "panel.TerceraSymbolLookupDropDownForm.Select all": "Selecionar todos",
    "panel.TerceraSymbolLookupDropDownForm.Show additionl info": "Mostrar informações adicionais",
    "panel.TerceraSymbolLookupDropDownForm.Spot": "Local",
    "panel.TerceraSymbolLookupDropDownForm.Spreadbet": "Spreadbet",
    "panel.TerceraSymbolLookupDropDownForm.TBill": "T-bill",
    "panel.TerceraSymbolLookupDropDownForm.typeComboBox.All types": "Todos os tipos",
    "panel.TerceraSymbolLookupDropDownForm.typeComboBox.No types": "Sem tipos",
    "panel.TerceraSymbolLookupDropDownForm.typeComboBox.types": "Tipos",
    "panel.timeSales": "Time & Sales",
    "panel.timeSales.Agressor flag": "Lado",
    "panel.timeSales.AgressorFlag.Auction": "Leilão",
    "panel.timeSales.AgressorFlag.Buy": "Compra",
    "panel.timeSales.AgressorFlag.CrossTrade": "Negociação Cruzada",
    "panel.timeSales.AgressorFlag.None": "Nenhum",
    "panel.timeSales.AgressorFlag.Sell": "Venda",
    "panel.timeSales.ask": "Ask",
    "panel.timeSales.AskBidSize": "Quantidade  'Bid' x 'Ask'",
    "panel.timeSales.AskSource": "Asks MPID",
    "panel.timeSales.bid": "Bid",
    "panel.timeSales.BidSource": "Bids MPID",
    "panel.timeSales.BuyerSource": "Comprador",
    "panel.timeSales.Exchange": "Corretora",
    "panel.timeSales.Instrument": "Ativo",
    "panel.timeSales.MenuName": "Time & Sales",
    "panel.timeSales.Price": "Preço",
    "panel.timeSales.SellerSource": "Vendedor",
    "panel.timeSales.Size": "Quantidade",
    "panel.timeSales.Time": "Tempo",
    "panel.timeSales.Type": "Tipo de Ativo",
    "panel.tooltiper.System": "Sistema",
    "panel.trades": "Ordens executadas",
    "panel.watchlist": "Cotações",
    "panel.watchlist.Ask": "Ask",
    "panel.watchlist.AskSize": "Quantidade  'Ask'",
    "panel.watchlist.AuctionEndTime": "",
    "panel.watchlist.Bid": "Bid",
    "panel.watchlist.BidSize": "Quantidade do 'Bid'",
    "panel.watchlist.Buy": "Compra",
    "panel.watchlist.CBAuctionStart": "",
    "panel.watchlist.cell.cancel": "Cancelar",
    "panel.watchlist.cell.modify": "Modificar",
    "panel.watchlist.cell.Not allow": "Não permitir",
    "panel.watchlist.cell.sending": "Enviando",
    "panel.watchlist.cell.ticks": "ticks",
    "panel.watchlist.Change": "Variação",
    "panel.watchlist.ChangeFromOpen": "Mudança desde a abertura",
    "panel.watchlist.ChangeFromOpen%": "Mudança desde a abertura, %",
    "panel.watchlist.ChangePr": "Variação, %",
    "panel.watchlist.Close": "Fechar",
    "panel.watchlist.ClosePostMarket": "Fechamento pós-'Market'",
    "panel.watchlist.Comment": "Comentário",
    "panel.watchlist.Company": "Empresa",
    "panel.watchlist.ContractSize": "Tamanho do lote",
    "panel.watchlist.CurrentSession": "Sessão atual",
    "panel.watchlist.Date": "Última data",
    "panel.watchlist.Descr": "Descriçao",
    "panel.watchlist.Exchange": "Bolsa",
    "panel.watchlist.ExpDate": "Data de venc.",
    "panel.watchlist.High": "Máximo",
    "panel.watchlist.High13": "#hidden#",
    "panel.watchlist.High26": "#hidden#",
    "panel.watchlist.High52": "#hidden#",
    "panel.watchlist.IndicativeAuctionPrice": "Leilão",
    "panel.watchlist.Instrument": "Ativo",
    "panel.watchlist.Last": "Último",
    "panel.watchlist.LastSize": "Última quantidade",
    "panel.watchlist.Limit.High": "",
    "panel.watchlist.Limit.Low": "",
    "panel.watchlist.Low": "Mínimo",
    "panel.watchlist.Low13": "#hidden#",
    "panel.watchlist.Low26": "#hidden#",
    "panel.watchlist.Low52": "#hidden#",
    "panel.watchlist.Main Volume": "Volume principal",
    "panel.watchlist.Market": "Mercado",
    "panel.watchlist.MarketCap": "Cap. Mercado",
    "panel.watchlist.Match Volume": "Volume correspondente",
    "panel.watchlist.menu.AddSymbol": "Adicionar Ativos...",
    "panel.watchlist.menu.clearAll": "Limpar todos",
    "panel.watchlist.menu.SymbolLists": "Listas de Ativos",
    "panel.watchlist.menu.RemoveInstrument": "Remover Ativos selecionados",
    "panel.watchlist.menu.SymbolInfo": "Informações do Ativo",
    "panel.watchlist.NA": "N/A",
    "panel.watchlist.noQuotes": "Última atualização",
    "panel.watchlist.NormalMarketSize": "",
    "panel.watchlist.OffExchangeValue": "",
    "panel.watchlist.OffExchangeVolume": "",
    "panel.watchlist.Open": "Abertura",
    "panel.watchlist.OpenInterest": "#hidden#",
    "panel.watchlist.OpenPreMarket": "Abertura pré-'Market'",
    "panel.watchlist.PreMarketChange": "Mudanças de pré-'Market'",
    "panel.watchlist.PreMarketChange%": "Mudanças de pré-'Market', %",
    "panel.watchlist.PrevClose": "Fechamento anterior",
    "panel.watchlist.PrevSettlementPrice": "Ajuste Anterior",
    "panel.watchlist.ReferencePrice": "",
    "panel.watchlist.Sell": "Venda",
    "panel.watchlist.SettlementPrice": "Ajuste Atual",
    "panel.watchlist.Spread": "Spread",
    "panel.watchlist.SpreadPr": "Spread, %",
    "panel.watchlist.Ticks": "Ticks",
    "panel.watchlist.TicksPostMarket": "Pos-'Market' 'Ticks'",
    "panel.watchlist.TicksPreMarket": "Pré-'Market' 'Ticks'",
    "panel.watchlist.Time": "Ultimo Horário",
    "panel.watchlist.TradedValue": "",
    "panel.watchlist.Type": "Tipo de Ativo",
    "panel.watchlist.Volume": "Volume",
    "panel.watchlist.VolumePostMarket": "Volume pós-'Market'",
    "panel.watchlist.VolumePreMarket": "Volume pré-'Market'",
    "Panels.MarketDepth.CancelAll": "Cancelar todas",
    "panels.orders.buttons.AsMarket": "Executar",
    "panels.orders.buttons.CancelAllButton": "Cancelar todas",
    "panels.orders.buttons.CancelBuyButton": "Cancelar Compra",
    "panels.orders.buttons.CancelByInstrumentButton": "Cancelar",
    "panels.orders.buttons.CancelDayButton": "Cancelar Dias",
    "panels.orders.buttons.CancelGTCButton": "Remover  'Até Cancelar'",
    "panels.orders.buttons.CancelLimitsButton": "Cancelar 'Limits'",
    "panels.orders.buttons.CancelSelectedButton": "Cancelar selecionado",
    "panels.orders.buttons.CancelSellButton": "Cancelar Venda",
    "panels.orders.buttons.CancelStopsButton": "Cancelar 'Stops'",
    "panels.orders.buttons.ModifyOrder": "Modificar ordens",
    "panels.positions.buttons.closeAll": "Fechar todas",
    "panels.positions.buttons.closeForInstrument": "Fechar",
    "panels.positions.buttons.closeLong": "Fechar Compra",
    "panels.positions.buttons.closeNegative": "Fechar negativo",
    "panels.positions.buttons.closePositive": "Fechar positivo",
    "panels.positions.buttons.closeSelected": "Fechar selecionado",
    "panels.positions.buttons.closeShort": "Fechar Venda",
    "panels.positions.buttons.clxAll": "F/C Todos",
    "panels.positions.buttons.clxForInstrument": "Fechar/Cancelar",
    "panels.positions.ButtonsPanel.helpLabel": "Clique do lado direito para exibir/ocultar botões",
    "PanelSettingsScreen.Text": "- Configurações",
    "PanelSettingsScreen.TextSmall": "- configurações",
    "Parabolic Time/Price System": "Parabolic Time/Price System",
    "period.Day": "Dia",
    "period.Days": "Dias",
    "period.Hour": "Hora",
    "period.Hours": "Horas",
    "period.Min": "Mín",
    "period.Mins": "Mins",
    "period.Month": "Mês",
    "period.Months": "Meses",
    "period.Week": "Semana",
    "period.Weeks": "Semanas",
    "period.Year": "Ano",
    "period.Years": "Anos",
    "Pivot Point Moving Average": "Pivot Point Moving Average",
    "PivotPoint": "Pivot Point",
    "PopupMultiCkeckBox.All": "Todos",
    "Português": "Português (Br)",
    "Price Channel": "Price Channel",
    "Price Oscillator": "Price Oscillator",
    "ProductType.CarryForward": "Levar adiante",
    "ProductType.Delivery": "Entrega",
    "ProductType.General": "Geral",
    "ProductType.Intraday": "Intraday",
    "property.%": "%",
    "property.Additional": "Adicional",
    "property.AllPrice": "Estatística de preço",
    "property.appearance": "Aparência",
    "property.Ask": "Ask",
    "property.Author": "Autor",
    "property.autosize": "Autodimensionamento de colunas",
    "property.autoYScale.Auto": "Escala automática",
    "property.autoYScale.Manual": "Escala manual",
    "property.Average": "Média",
    "property.Backgr": "",
    "property.Backgr. top": "Cor superior traseira",
    "property.BarPattern": "Barra de padrão",
    "property.barsHiLowColor": "Linha",
    "property.BarStatistics": "Estatísticas da barra",
    "property.Bid": "Bid",
    "property.bodyColor": "Corpo",
    "property.borderColor": "Borda",
    "property.BottomCenter": "Inferior centralizado",
    "property.BottomLeft": "Inferior esquerdo",
    "property.BottomRight": "Inferior direito",
    "property.Browser": "Navegador",
    "property.Buy": "Compra",
    "property.BuyColor": "Compra",
    "property.cAsk": "Qtd. 'Ask'",
    "property.cASK": "No 'Ask'",
    "property.cASKHI": "Acima do preço 'Ask'",
    "property.cBid": "Qtd. 'Bid'",
    "property.cBID": "No preço 'Bid'",
    "property.cBIDLO": "Abaixo do preço 'Bid'",
    "property.cellscin.useSellBuy": "Botões de ordem inversa",
    "property.Change": "Variação",
    "property.ChangePercent": "Variação, %",
    "property.Chart": "Gráfico",
    "property.Chart OE": "Entrada de ordem de gráfico",
    "property.chart.isBackground": "Mover para o fundo",
    "property.chart.line": "Linha",
    "property.chart.ray": "RAIO",
    "property.chart.rayStyle": "Estilo do raio",
    "property.chart.showAngle": "Mostrar ângulos",
    "property.chart.showingOE": "Mostrar odens de entrada",
    "property.chart.stickTo": "Colar em",
    "property.chart.tools.back": "Desenhar no fundo",
    "property.chart.tools.fibretr.font": "Fonte",
    "property.chart.tools.fibretr.fontcolor": "Cor da fonte",
    "property.ChartAnalyseToolsToolBar": "barra de ferramentas de análise",
    "property.ChartDrawingToolsToolBar": "Barra de ferramentas desenho",
    "property.cLimit": "Ordem 'Limit'",
    "property.Close": "Fechar",
    "property.Cluster.ClusterUpDownColors": "Cluster",
    "property.Cluster.ColorScheme": "Esquema de cores",
    "property.Cluster.ColorScheme.ByDelta": "Pelo Delta",
    "property.Cluster.ColorScheme.ByDelta.Colors": "Cor",
    "property.Cluster.ColorScheme.ByDelta.Colors.BuyColor": "Compra",
    "property.Cluster.ColorScheme.ByDelta.Colors.SellColor": "Venda",
    "property.Cluster.ColorScheme.ByTrades": "Pelas negociações",
    "property.Cluster.ColorScheme.ByVolume": "Pelo volume",
    "property.Cluster.ColorScheme.Color": "Cor",
    "property.Cluster.ColorScheme.None": "Nenhum",
    "property.Cluster.CustomStep": "Passo personalizado",
    "property.Cluster.Down": "Baixa",
    "property.Cluster.FilterValue": "Filtro de volume",
    "property.Cluster.Font": "Fonte",
    "property.Cluster.FontColor": "Cor da fonte",
    "property.Cluster.HighlightMax": "Destacar máximos",
    "property.Cluster.HighlightMax.Colors": "Cores",
    "property.Cluster.Operator": "Operador",
    "property.Cluster.PriceStep": "Nível de preço",
    "property.Cluster.PriceStep.Custom": "Personalizado",
    "property.Cluster.PriceStep.MinTick": "Cotação mínima",
    "property.Cluster.ShowOnlySimilarDelta": "Mostrar somente detas similares",
    "property.Cluster.ShowValueFilter": "Valor do filtro",
    "property.Cluster.Up": "Alta",
    "property.cMIDLO": "Entre 'Spread'",
    "property.Color": "Cor",
    "property.ColoringMode": "Modo de coloração",
    "property.Colors": "Cores",
    "property.colors_param": "Cores",
    "property.colorScheme": "Estilo",
    "property.ColorsSeparatorGroup": "Cores",
    "property.ColouringMode": "Modo de coloração",
    "property.Comments": "Comentários",
    "property.Company": "Empresa",
    "property.Confirmations": "Confirmações",
    "property.connection": "Conexão",
    "property.Connection": "Conexão",
    "property.ContentAlignment": "Alinhamento",
    "property.Copyrights": "Copyrights",
    "property.cQUOTE": "Cotações",
    "property.CreateColor": "Criar",
    "property.crossHairFontColor": "Cor da fonte",
    "property.cStop": "Ordem 'Stop'",
    "property.currency": "Moeda",
    "property.CustomYMarkingValue": "Passo de grade mín.",
    "property.Data Box": "Janela de informação",
    "property.Data Style": "Estilo de dados",
    "property.Day": "Diario",
    "property.DaySeparatorsVisibility": "Separadores de dia",
    "property.DefaultProductType": "Tipo de produto padrão",
    "property.Defaults": "Padrões de negociação",
    "property.deviation": "desvio",
    "property.dK": "dK",
    "property.Doji": "Doji",
    "property.Downband deviation in %": "Desvio 'downband' em %",
    "property.email": "Endereços de e-mail (use vírgula ou ponto e vírgula)",
    "property.Emulator": "Emulador",
    "property.enable": "Habilitado",
    "property.Enable Sounds": "Habilitar sons",
    "property.Exponential": "Exponencial",
    "property.fib.Arc": "Fib. Arco",
    "property.Fibonacci": "Fibonacci",
    "property.Filters": "Filtros",
    "property.FitDrawings": "Ajustar desenhos",
    "property.FitHighLow": "Ajuste Máximo/Mínimo do dia",
    "property.FitIndicator": "Indicadores de ajuste",
    "property.FitOrders": "Encaixar ordens/posições",
    "property.FOK": "Tudo ou Nada",
    "property.Font": "Fonte",
    "property.Forex": "Forex",
    "property.Forwards": "Encaminhamentos",
    "property.Futures": "Futuros",
    "property.FX Cell": "Célula de negociação",
    "property.G": "G",
    "property.General": "Geral",
    "property.general.defaultSymbol": "Ativo",
    "property.general.OvernightMarginNotificationMessage": "",
    "property.general.showLotsFX": "Exibir quantidade em lotes",
    "property.gr_type": "Estilo",
    "property.Gradient": "Gradiente",
    "property.GraphicType.Absolute": "Absoluto",
    "property.GraphicType.Log": "Logarítmica",
    "property.GraphicType.Relative": "Relativo",
    "property.GridPrice": "Preço (horizontal)",
    "property.GridPriceHighLight": "Realçar cor da grade",
    "property.GridTime": "Horário (vertical)",
    "property.GTC": "Até Cancelar",
    "property.GTD": "Até o Dia",
    "property.HeaderFont": "Fonte",
    "property.HeaderText": "Texto de cabeçalho",
    "property.High": "Máximo",
    "property.HighlightMarkingsStep": "Realçar passo da grade",
    "property.HistogramLineColor": "Linha do Histograma",
    "property.HistoryType": "Tipo de dados",
    "property.HorizontalLine.LeftRay": "Estender lado esquedo",
    "property.hostName": "Nome do servidor",
    "property.hotkeyManager.ShowVolume": "Exibir volume",
    "property.infowindow_font": "Fonte",
    "property.infoWindow_fore_back_color": "Cor",
    "property.infowindow_visible": "Exibir janela de informações",
    "property.Instrument Types": "Padrões de ordem por tipo",
    "property.Intrinsic": "Intrínseco",
    "property.IOC": "Tudo ou Parcial",
    "property.IsBackground": "É Fundo",
    "property.IsBarsVisible": "Nº de barras",
    "property.IsChangeVisible": "Variação, %",
    "property.IsCloseVisible": "Fechamento",
    "property.IsDateVisible": "Data",
    "property.IsHighVisible": "Máximo",
    "property.IsLowVisible": "Mínimo",
    "property.IsOpenVisible": "Abertura",
    "property.IsRoundedAvgPrice": "Preço de abertura médio arredondado",
    "property.isShowToolTips": "Dicas",
    "property.isShowToolTipsGrop": "",
    "property.ConfirmationSubgroup.Other": "Outros",
    "property.dealTickets.openOnWorkspace": "Abrir tickets de transação na área de trabalho",
    "property.IsSyncActive": "Sincronizar com os gráficos",
    "property.IsTicksVisible": "Ticks",
    "property.IsTimeVisible": "Time",
    "property.IsVolumeVisible": "Volume",
    "property.LabelColors": "Cores dos rótulos",
    "property.Language": "Idioma",
    "property.Last": "Último",
    "property.lastPrice_AskIndicatorColor": "Ask",
    "property.lastPrice_BidIndicatorColor": "Bid",
    "property.lastPrice_LastIndicatorColor": "Último",
    "property.lastPrice_SpreadIndicatorColor": "Cor da área de 'Spread'",
    "property.Left ray": "Extender lado esquerdo",
    "property.Level": "Nível",
    "property.Limit": "Limit",
    "property.Line": "Linha",
    "property.line.lineName": "Nome da linha",
    "property.line.plateVisible": "Mostrar linha no marcador {0}",
    "property.line.timeShift": "Mudar horário",
    "property.line.value": "Valor",
    "property.line.Visible": "Visível",
    "property.line.yFactor": "Fator Y",
    "property.Linear Weighted": "Ponderado linearmente",
    "property.lineProperties": "propriedades",
    "property.LOC": "LOC",
    "property.logBase": "Base Logaritma",
    "property.LOO": "LOO",
    "property.LotsMode": "Exibir tamanho em",
    "property.Low": "Mínimo",
    "property.Mail Server": "Servidor de e-mail",
    "property.Manual": "Manual",
    "property.Market": "Mercado",
    "property.MarketCap": "Cap. Mercado",
    "property.MarketDepth.ColoringMethod": "Método de coloração",
    "property.MarketProfile.ColorScheme": "Esquema de cores",
    "property.MarketProfile.ColorScheme.Color": "Cor",
    "property.MarketProfile.ColorScheme.GradientColor": "Área",
    "property.MarketProfile.ColorScheme.PairColor.Color": "Cor",
    "property.MarketProfile.ColorScheme.VolumeColor": "Cor",
    "property.MarketProfile.Font": "Fonte",
    "property.MarketProfile.PointOfControl": "Ponto de controle",
    "property.MarketProfile.PointOfControlStyle": "Estido do ponto de controle",
    "property.MarketProfile.ShowSingles": "Mostrar simples",
    "property.MarketProfile.SinglesStyle": "Estilo simples",
    "property.MarketProfile.ValueArea": "Valor da área",
    "property.MarketProfile.ValueAreaStyleHorisontal": "Estilo da linha horizontal",
    "property.MarketProfile.ValueAreaStyleVertical": "Estilo da linha vertical",
    "property.MarketProfile.ValuePercents": "Valor, %",
    "property.Medium": "Médio",
    "property.message": "Mensagem",
    "property.method": "método",
    "property.MirrorShow": "Visão de espelho",
    "property.MOC": "MOC",
    "property.Mode": "Modo",
    "property.Mode.Trades": "Negociações",
    "property.Modified": "Modificado",
    "property.MonthSeparatorsVisibility": "Separadores de mês",
    "property.MOO": "MOO",
    "property.name": "Nome",
    "property.Name": "Nome",
    "property.News": "Notícias",
    "property.None": "Nenhum",
    "property.Note": "Nota",
    "property.NotSet": "Não configurado",
    "property.Number": "Contar",
    "property.OCO": "OCO",
    "property.OE_AddBidAddAsk": "Comprar 'Bid'/Vender 'Ask'",
    "property.OE_ShowBuyMarketSellMarket": "Compra à Mercado/Venda à Mercado",
    "property.OE_ShowSellBidBuyASk": "Venda 'Bid'/Compra 'Ask'",
    "property.OE_ShowSLTP": "Fechar ordens (TP/SL)",
    "property.OE_Small": "Estreitar entrada da ordem",
    "property.OE_Small.ToolTip": "Se selecionada, a entrada da ordem terá uma visão estreita",
    "property.oe.confirmClosePosition": "Confirmar fechamento da posição",
    "property.oe.confirmOrderCancel": "Confirmar cancelamento da ordem",
    "property.oe.confirmOrderChange": "Confirmar modificação da ordem",
    "property.oe.confirmOrderCreate": "Confirmar colocação da ordem",
    "property.oe.confirmOrderCreateAlert": "Confirmar envio de ordem",
    "property.oe.confirmReversePosition": "Confirmar inversão de posição",
    "property.OffsetMode": "Mostrar distância em",
    "property.OnClose": "No fechamento",
    "property.OnOpen": "Na abertura",
    "property.Open": "Abertura",
    "property.Options": "Geral",
    "property.Options.ToolTip": "",
    "property.Order Entry": "Entrada de Ordem",
    "property.OrderBuyStyleWidth": "Compra",
    "property.OrderSellStyleWidth": "Venda",
    "property.OverlayVisible": "Sobreposição visível",
    "property.Panel name": "Nome do painel",
    "property.panel.accountDetails.Groups.1.General": "Geral",
    "property.panel.accountDetails.Groups.2.Margin": "Margem",
    "property.panel.accountDetails.Groups.3.AccountActivity": "Atividade da conta",
    "property.panel.accountDetails.Groups.4.TodayResults": "Resultados de hoje",
    "property.panel.accountDetails.Groups.5.RiskManagement": "Gerenciamento de risco",
    "property.panel.accountDetails.Groups.6.Info": "Informações",
    "property.Panels": "Painéis",
    "property.Parameters": "Parâmetros",
    "property.password": "Senha",
    "property.period": "período",
    "property.Period of indicator": "Período do indicador",
    "property.Period of indicator:": "Período do indicador",
    "property.Period of Linear Regression": "Período para regressão linear",
    "property.Period of MA for envelopes": "Período de MA para envelopes",
    "property.Period of MA1": "Período de MA1",
    "property.Period of MA2": "Período de MA2",
    "property.Period of momentum:": "Período para 'Momentum'",
    "property.Period of Moving Average": "Período para 'Moving Average'",
    "property.Period of price channel": "Período de canal de preço",
    "property.Period of Simple Moving Average": "Período para 'Simple Moving Average'",
    "property.Period of Smoothed Moving Average": "Período para 'Smoothed Moving Average'",
    "property.Periods": "Períodos",
    "property.pin": "PIN",
    "property.pips": "Pips",
    "property.POCTypes.Line": "Linha",
    "property.POCTypes.Marker": "Marcas",
    "property.POCTypes.None": "Nenhum",
    "property.points": "Pontos",
    "property.Points": "Pontos",
    "property.PositionBuyStyleWidth": "Compra",
    "property.PositionSellStyleWidth": "Venda",
    "property.Previous": "Anterior",
    "property.price": "preço",
    "property.Price Scale": "Escala de preço",
    "property.PriceIndicatorDrawingType": "Indicador de preço",
    "property.PriceIndicatorDrawingType.None": "Nenhum",
    "property.PriceIndicatorDrawingType.ScaleMarker": "Marcador de escala",
    "property.PriceIndicatorDrawingType.ScaleMarkerWithLine": "Marcador de escala com linha",
    "property.PriceScaleAxisColor": "Eixo",
    "property.PriceScaleBackColor": "Fundo do eixo",
    "property.PriceScaleFont": "Fonte",
    "property.PriceScaleTextColor": "Cor do texto",
    "property.PriceType": "Tipo de preço",
    "property.ProfileColoringTypes.DeltaBars": "Barra delta",
    "property.ProfileColoringTypes.DeltaPrice": "Preço delta",
    "property.ProfileColoringTypes.DeltaProfile": "Perfil delta",
    "property.ProfileColoringTypes.Single": "Cor simples",
    "property.ProfileColoringTypes.UpdownBars": "Barra sobre/Desce",
    "property.ProfileColoringTypes.UpdownProfile": "Perfil Sobe/desce",
    "property.ProfileColoringTypes.VolumeBars": "Volume das barras",
    "property.ProfileColoringTypes.VolumePrice": "Preço do Volume",
    "property.protrader.showOrders": "Ordens",
    "property.Range": "LImites",
    "property.Ray style": "Estilo do raio",
    "property.Relative": "Relatico",
    "property.relativeCalcType": "Preço inicial",
    "property.RelativeCalculationType.BeginOfTheData": "Início de dados",
    "property.RelativeCalculationType.BeginOfTheDay": "Início do dia",
    "property.RelativeCalculationType.BeginOfTheScreen": "Início de tela",
    "property.relativeScaleType": "Tipo de escala",
    "property.Reserve": "Reservar",
    "property.Reverse": "Inverter",
    "property.Right ray": "Estender lado direito",
    "property.routing.account": "Conta",
    "property.routing.orderType": "Tipo de ordem",
    "property.RSI Method:": "Método RSI",
    "property.RSI Period:": "Período RSI",
    "property.SCRIPTS_SUBGROUP": "Scripts",
    "property.ScrollBar": "Barras de rolagem",
    "property.secure": "Seguro",
    "property.Secure": "Seguro",
    "property.Selection": "Seleção",
    "property.Sell": "Venda",
    "property.SellColor": "Venda",
    "property.SeparatorGroup.ActiveOrders": "Ordens abertas",
    "property.SeparatorGroup.additional": "Adicional",
    "property.SeparatorGroup.Additional": "Adicional",
    "property.SeparatorGroup.Appearance": "Aparência",
    "property.SeparatorGroup.CrossHair": "Ponto de cruzamento",
    "property.SeparatorGroup.ExecutedOrders": "Ordens executadas",
    "property.SeparatorGroup.Grid": "Grade",
    "property.SeparatorGroup.HighlightDate": "Realçar data",
    "property.SeparatorGroup.HighlightPrices": "Realçar preços",
    "property.SeparatorGroup.OpenPositions": "Posições abertas",
    "property.SeparatorGroup.ScaleMarkings": "Marcações da escala",
    "property.SeparatorGroup.ScaleType": "Tipo de escala",
    "property.SeparatorGroup.SeparatorOEWidth": "Exibir",
    "property.SeparatorGroup.Show": "Mostrar",
    "property.SeparatorGroup.TimeToNextBar": "Tempo para a próxima barra",
    "property.SeparatorGroup.WindowColors": "Cores da janela",
    "property.SeparatorGroup.Zoom": "Zoom",
    "property.SeparatorViewButtons": "Botões de acesso",
    "property.Sesonality": "Sazonalidade",
    "property.shift": "Mudar",
    "property.showAllSessions": "Exibir sessões estendidas",
    "property.ShowEmptyBars": "Buracos no histórico",
    "property.showEvents": "Visível",
    "property.ShowFullArc": "Exibir arco completo",
    "property.ShowHighLow": "Linhas Máximo/Mínimo do dia",
    "property.showOrders": "Visível",
    "property.showPositions": "Visível",
    "property.ShowPrevCloseLine": "Linha de fechamento anterior",
    "property.ShowTimeToNextBar": "Visível",
    "property.ShowTotal": "Exibir total",
    "property.ShowVisualTradingOnLeftSide": "Vincular posições/Ordens abertas ao lado esquerdo",
    "property.showVolume": "Visível",
    "property.showVolumeMarker": "Exibir marcador de valor na escala",
    "property.Simple": "Simples",
    "property.sltpOffsetMode": "Configurar SL/TP em distânia",
    "property.Smoothing": "Suavização",
    "property.solidPriceColor": "Área",
    "property.sounds_param": "Sons",
    "property.Sources prices for MA": "Preços fonte para MA",
    "property.Specific": "Específico",
    "property.SpreadType": "Tipo de 'Spread'",
    "property.SpreadType.Area": "Área",
    "property.SpreadType.Line": "Linha",
    "property.SpreadType.LineWithLabel": "Linhas com preços",
    "property.SpreadType.None": "Nenhum",
    "property.Step of parabolic SAR system": "Passo do sistema 'parabolic SAR'",
    "property.Stick to": "Colar em",
    "property.stickTo": "Colar em:",
    "property.Stocks": "Ações",
    "property.Stop": "Stop",
    "property.Stop Limit": "Stop limit",
    "property.Stop-loss": "Stop Loss",
    "property.style": "Estilo:",
    "property.Sum": "Soma",
    "property.Symbol": "Ativo",
    "property.Symbols": "Ativos",
    "property.T": "T",
    "property.Take-profit": "Take Profit",
    "property.text": "Texto",
    "property.TIF": "Validade 'Market'",
    "property.TIF_Limit_And_Stop_Limit": "Validade 'Limit' (Validade 'Stop limit')",
    "property.TIF_Stop": "Validade 'Stop'",
    "property.Time Scale": "Escala de horas",
    "property.TimeLine": "Cronograma",
    "property.TimelinePreview": "Visualização de rolagem do gráfico",
    "property.TimeScaleAxisColor": "Eixo",
    "property.TimeScaleBackColor": "Fundo do eixo",
    "property.TimeScaleFont": "Fonte",
    "property.TimeScaleImportentFont": "Fonte das datas importatens",
    "property.TimeScaleTextColor": "Cor da fonte",
    "property.TimeToNextBarColor": "Cor",
    "property.TimeToNextBarFont": "Fonte",
    "property.TopCenter": "Central inferior",
    "property.TopLeft": "Superior esquerdo",
    "property.TopRight": "Superior direito",
    "property.trackShowCrossHair": "Visível",
    "property.Trading": "Negociação",
    "property.Trading Defaults": "Negociação",
    "property.Type of moving average": "Tipo da 'Moving Average'",
    "property.Type of Moving Average": "Tipo da 'Moving Average'",
    "property.Typical": "Típico",
    "property.Upband deviation in %": "Desvio da banda superior em %",
    "property.Update": "Atualizar",
    "property.UseCustomYMarkings": "Grade personalizada",
    "property.user": "Login",
    "property.useSSL": "Seguro",
    "property.Value of confidence interval": "Valor do intervalo de confiança",
    "property.ValueColors": "Cores do valores",
    "property.VATypes.Horizontal": "Horizontal",
    "property.VATypes.None": "Nenhum",
    "property.VATypes.ToNextProfile": "O mais próximo possível",
    "property.VATypes.Vertical": "Vertical",
    "property.vendorName": "Fornecedor",
    "property.View": "Exibir",
    "property.ViewSeparatorGroup": "Exibir",
    "property.Visibility": "Visibilidade",
    "property.VisibilitySeparatorGroup": "Visibilidade",
    "property.Visible": "Visível",
    "property.Visual Trading": "Negociação visual",
    "property.Volume": "Volume das barras",
    "property.Volume.Font": "Fonte",
    "property.Volume.FontColor": "Cor da fonte",
    "property.Volume.UseTicksData": "Usar 'Tick' real de volume",
    "property.VolumeColoringMode": "Esquema de coloração de tamanho",
    "property.VolumeDownColor": "Negativo",
    "property.VolumeFixedColor": "Igual",
    "property.VolumeHeightPercent": "Altura, %",
    "property.VolumeMode.BuyVolume": "Volume da Compra",
    "property.VolumeMode.Delta": "Variação",
    "property.VolumeMode.MaxTickVolume": "Volume maximo do 'Tick'",
    "property.VolumeMode.SellVolume": "Volume de Venda",
    "property.VolumeMode.TotalVolume": "Volume total",
    "property.VolumeMode.Trades": "Negociações",
    "property.VolumeOpacityPercent": "Transparência",
    "property.VolumeProfile": "Perfil de volume",
    "property.VolumeUpColor": "Positivo",
    "property.VWAP": "VWAP",
    "property.Warnings": "Alertas",
    "property.WatermarkColor": "",
    "property.WeekSeparatorsVisibility": "Separadores de semana",
    "property.Weighted": "Ponderado",
    "property.wickColor": "Pavio",
    "property.width": "Largura:",
    "property.Win num": "Janela do gráfico para indicador",
    "property.Win num.addWin": "Separada",
    "property.Win num.main": "Gráfico",
    "property.Y factor": "Fator Y",
    "property.YearSeparatorsVisibility": "Separadores de ano",
    "property.ZeroLine": "Linha zero",
    "property.ZoomValue": "Variação da escala",
    "propery.MainPriceRenderer.Down": "Baixa",
    "propery.MainPriceRenderer.Negative": "Negativo",
    "propery.MainPriceRenderer.Positive": "Positivo",
    "propery.MainPriceRenderer.Up": "Alta",
    "Relative Strength Index": "Relative Strength Index",
    "reports.#subgroup.Revenue plan.0": "Comissões",
    "reports.#subgroup.Revenue plan.1": "Spreads",
    "reports.#subgroup.Revenue plan.2": "Swaps",
    "reports.#subgroup.Revenue plan.3": "Descontos",
    "reports.#subgroup.Revenue plan.4": "Partes do Ativo",
    "reports.$broker": "corretora",
    "reports.$broker_create_order": "corretora criou a ordem",
    "reports.$broker_refuse_with_answer": "corretor recusou com resposta",
    "reports.$client": "cliente",
    "reports.$client_request": "solicitação do cliente",
    "reports.$close_position": "fechar posição",
    "reports.$confirm_order_removed": "ordem de confirmação removida",
    "reports.$enter_system": "entrar no sistema",
    "reports.$executed": "executada",
    "reports.$leave_system": "sair do sistema",
    "reports.$open_position": "posição aberta",
    "reports.$order": "ordem",
    "reports.$request_order_removed": "ordem de solicitação removida",
    "reports.4CastNews": "4CastNews",
    "reports.Accepted": "Aceitado",
    "reports.accname": "Nome da conta",
    "reports.accoperreqstatus": "Situação",
    "reports.account": "Conta",
    "reports.Account": "Conta",
    "reports.Account Name": "Nome da conta",
    "reports.Account operation confirmation.": "Confirmação de operação na conta",
    "reports.Account Operation Report": "Operação da conta",
    "reports.Account operation report (settled)": "Operações da conta (Liquidados)",
    "reports.Account Operation Report Summary": "Resumo de operação da conta",
    "reports.Account operations report": "Operação da conta",
    "reports.Account Operations Report": "Importar operações da conta",
    "reports.Account Performance Report": "Desempenho da conta",
    "reports.Account Statement": "Extrato da conta",
    "reports.Account statement in defined currency report": "Extrato da conta na moeda definida",
    "reports.Account Statement on chosen moment report": "Extrato da conta na data escolhida",
    "reports.Account Statement Report": "Extrato da conta",
    "reports.account_ID": "ID da conta",
    "reports.account.balance": "Saldo",
    "reports.account.crossinstrument": "Ativo cruzado",
    "reports.account.currency": "Moeda",
    "reports.account.ismaster": "É Mestre",
    "reports.account.mirroraccounts": "Contas espelho",
    "reports.account.name": "Nome da conta",
    "reports.account.status": "Situação",
    "reports.account.type": "Tipo",
    "reports.account.userid": "ID de usuário",
    "reports.account.visibility.editforsuper": "Edição do grupo super",
    "reports.account.visibility.editforthis": "Edição para este grupo",
    "reports.account.visibility.tradeforsuper": "Negociação para grupo super",
    "reports.account.visibility.tradeforthis": "Negociação para este grupo",
    "reports.account.visibility.viewforsuper": "Exibição para grupo super",
    "reports.account.visibility.viewforthis": "Exibição para este grupo",
    "reports.accountcurrency": "Moeda",
    "reports.AccountId": "Conta",
    "reports.accountname": "Conta",
    "reports.AccountOperationAmount": "Quantidade",
    "reports.accountoperationid": "ID da operação da conta",
    "reports.accountPassword": "Senha da conta",
    "reports.accounts": "Conta(s)",
    "reports.accountUsername": "Nome de usuário da conta",
    "reports.AccruedInterest": "Juros acumulados",
    "reports.ACM Feed": "ACM Feed",
    "reports.action": "Lado",
    "reports.actionname": "Nome da Ação",
    "reports.Activated": "",
    "reports.Active Orders Report": "Ordens abertas",
    "reports.actualprice": "Preço atual",
    "reports.actualtime": "Hora atual",
    "reports.addliquidityrebate": "Adicionar desconto de liquidez",
    "reports.address": "Endereço",
    "reports.Adjusment": "Ajuste",
    "reports.Adjustment": "Ajuste",
    "reports.ADMINISTRATION_RULES": "Regras de administração",
    "reports.AK Equity Algo": "Capital Líquido AK Algo",
    "reports.AK Genex Equity": "Capital Líquido AK Genex",
    "reports.AK Genex Turdex": "AK Genex Turdex",
    "reports.alertNotify": "Notificação de alerta",
    "reports.All brokers": "Todos as corretoras",
    "reports.All data received.": "Todos os dados recebidos.",
    "reports.ALLOW_AUTO_APPROVE_TRANSFER_REQUEST": "Permitir solicitação de transferência com aprovação automática",
    "reports.ALLOW_AUTO_ORDER_TYPE": "Configurar tipo de ordem automaticamente ('Limit'/'stop'/à Mercado)",
    "reports.ALLOW_AUTO_RESET_PWD": "Permitir reinicialização automática de senha",
    "reports.ALLOW_CLOSE_POSITIONS_ON_STOP_OUT": "Fechar posição em 'Stop Out'",
    "reports.ALLOW_DEMO_AUTO_REGISTRATION": "Permitir registro automático de usuário de demonstração",
    "reports.ALLOW_GENERATE_L3": "Permitir gerar L3",
    "reports.ALLOW_GENERATE_PRICE_FROM_SPOT": "Permitir a geração de preço a partir de 'Spot'",
    "reports.ALLOW_HEDGE_BY_SPOT": "'Hedge' por 'Spot'",
    "reports.ALLOW_HEDGE_MARGIN": "Permitir margem de 'Hedge'",
    "reports.ALLOW_JOIN_POSITIONS": "Juntar-se a posições de um lado em 'Hedging'",
    "reports.ALLOW_LAZY_LOAD_QUOTES": "Permitir carga de cotações preguiçosas",
    "reports.ALLOW_LIVE_AUTO_REGISTRATION": "Permitir registro automático ao vivo",
    "reports.ALLOW_ONE_BROKER_FOR_ALL_OPERATION": "Permitir uma corretora para todas operações",
    "reports.ALLOW_ORDER_AUTOEXECUTION_IS_MARKET_PRICE": "Executar ordens a preço de Mercado (pelo preço da ordem se falso)",
    "reports.ALLOW_PRICE_EXECUTION_MAPPING": "Mapeamento de execução de preço",
    "reports.ALLOW_PWD_VERIFICATION": "Verificar senha por SMS",
    "reports.ALLOW_REFUSE_IF_BROKER_OFFLINE": "Permitir recusar se a corretora estiver off-line",
    "reports.ALLOW_REMOVE_ORDERS_ON_STOP_OUT": "Remover ordens em 'Stop Out'",
    "reports.ALLOW_SAVE_HISTORY": "Salvar histórico de cotações para o Ativo:",
    "reports.ALLOW_SAVE_QUOTE_LOGS": "Permitir salvar logs de cotações",
    "reports.ALLOW_SAVE_QUOTE_TICKS": "Permitir salvar 'Ticks' de cotações",
    "reports.ALLOW_SAVE_QUOTES": "Permitir salvar cotações",
    "reports.ALLOW_SAVE_TRADE_LOGS": "Permitir salvar logs de negociação",
    "reports.ALLOW_SHORT_POSITIONS": "Permitir posições de Venda",
    "reports.ALLOW_SKIP_REVERSED_PRICE": "Pular todas as cotações \"revertidas\"",
    "reports.ALLOW_SPIKE_FILTER": "Permitir filtro 'Spike'",
    "reports.ALLOW_STOP_AVARE_MARGIN": "Usar margem de 'Stop Aware'",
    "reports.ALLOW_STORE_USERS_FILES": "Permite armazenamento de arquivos de usuários",
    "reports.ALLOW_USE_CERT": "Permite uso de sinal em solicitação",
    "reports.ALLOW_USER_LEVERIGE": "Permitir a alavancagem do usuário",
    "reports.ALLOW_USERS_EMAIL_DUPLICATE": "Permitir duplicação de e-mails para contas demo",
    "reports.ALLOW_VISIBLITY": "Permitir visibilidade",
    "reports.allowLimitOrders": "Permitir ordens 'Limit'",
    "reports.allowStopOrders": "Permitir ordens 'Stop'",
    "reports.already in processing": "já está em processamento",
    "reports.amount": "Quantidade",
    "reports.Amount": "Quantidade",
    "reports.Amount filled": "Quantidade executada",
    "reports.amountperside": "Quantidade por lado",
    "reports.An error occurred during sending mail using user settings. Will use default settings.": "Houve um erro durante o envio de mensagens usando as configurações do usuário. As configurações padrão serão usadas.",
    "reports.Aphelion": "Aphelion",
    "reports.APPLICATION.NAME": "NOME DA APLICAÇÃO",
    "reports.Approved": "Aprovado",
    "reports.asset": "Ativos",
    "reports.AuditReport": "Alterações do 'BackOffice'",
    "reports.Authorization Error: Operation is nor allowed": "Erro de autorização: Operação não permitida",
    "reports.autotrade": "Negociação automática",
    "reports.Average filled price": "Preço",
    "reports.Back Office Changes Report": "Alterações do 'BackOffice'",
    "reports.balance": "Saldo",
    "reports.Balance": "Saldo",
    "reports.Balance by Instrument Report": "Saldo por Ativo",
    "reports.Balance By Instrument Report": "Saldo por Ativo",
    "reports.Balance Operations Report": "Operações de saldo",
    "reports.Balance Report": "Saldo",
    "reports.Balance Report(copy)": "Saldo (cópia)",
    "reports.Balance Summary report": "Resumo de saldo",
    "reports.Balance Summary Report": "Resumo de saldo",
    "reports.balance_after": "Saldo depois",
    "reports.balance_before": "Saldo antes",
    "reports.balance.amount": "Quantidade",
    "reports.balance.buy": "Compra",
    "reports.balance.everbuy": "Compra média",
    "reports.balance.eversell": "Venda média",
    "reports.balance.exposure": "Exposição",
    "reports.balance.exposurenet": "Exposição líquida",
    "reports.balance.grossexposure": "Exposição bruta",
    "reports.balance.grosspl": "L/P bruto",
    "reports.balance.grossvalue": "Valor bruto da posição",
    "reports.balance.net": "Líquido",
    "reports.balance.netexposure": "Exposição líquida",
    "reports.balance.netvalue": "Valor líquido da posição",
    "reports.balance.profit": "Lucro",
    "reports.balance.sell": "Venda",
    "reports.Barclays": "Barclays",
    "reports.basis": "Comentário",
    "reports.Basis": "Detalhes",
    "reports.because Not enoght margin to create Order": "porque não há margem suficiente para criar ordem",
    "reports.because Price for Stop limit order is incorrect.": "Este preço para a ordem 'Stop Limit' é incorreto.",
    "reports.BeginString": "Começar sequência",
    "reports.blocked_funds": "Fundos bloqueados (margem)",
    "reports.blockedbalance": "Saldo bloqueado",
    "reports.Blocking": "Bloqueio",
    "reports.bmr_brokermessageid": "ID da mensagem",
    "reports.bmr_brokermessagename": "Nome da mensagem",
    "reports.bmr_confirmtime": "Horário alvo",
    "reports.bmr_targetuserid": "ID de usuário",
    "reports.bmr_targetuserlogin": "Usuário",
    "reports.Bound To": "Vinculada a",
    "reports.Bound to order": "Obrigado a ordenar",
    "reports.boundtoorderid": "ID de ordem vinculada",
    "reports.BRANDING_ADMIN_HELP": "URL de ajuda para administrador",
    "reports.BRANDING_ANONYM_GROUP": "Coloque usuários anônimos autorregistrados em",
    "reports.BRANDING_ANONYM_GROUP_SUPPORT": "Grupo de apoio para usuários anônimos",
    "reports.BRANDING_CLIENT_MINIMAL_VERSION": "Versão mínima do cliente (para atualização forçada)",
    "reports.BRANDING_COMMON_RULES": "Comum",
    "reports.BRANDING_COMPANY_DESC": "Descritor da idenditade visual da empresa",
    "reports.BRANDING_COMPANY_INFO_RULES": "Informações da empresa",
    "reports.BRANDING_COMPANY_NAME": "Nome da empresa",
    "reports.BRANDING_COMPANY_URL": "URL da empresa",
    "reports.BRANDING_DEMO_GROUP": "Coloque usuários demo autorregistrados em",
    "reports.BRANDING_DEMOREGISTRATION_URL": "Registro de Demo (esvaziar-este servidor; 0-desabilitar; url da página)",
    "reports.BRANDING_DEPOSIT_URL": "URL de depósito (esvaziar para desabilitar, url para a página)",
    "reports.BRANDING_DISCLAMER": "Aviso legal (exibido no login skin 0)",
    "reports.BRANDING_DOWJONES_ENCRYPTED_TOKEN": "Código criptografado Dow Jones",
    "reports.BRANDING_EMAIL": "E-mail da empresa",
    "reports.BRANDING_EXT_LINK_RULES": "Links externos",
    "reports.BRANDING_FORGOTPWD_URL": "URL \"esqueci minha senha\"",
    "reports.BRANDING_HTML_FOOTER": "Imagem do rodapé",
    "reports.BRANDING_HTML_LOGINSCREEN_LOGO": "Imagem da tela de login",
    "reports.BRANDING_HTML_LOGO": "Imagem da logo",
    "reports.BRANDING_HTML_NAME": "Nome do aplicativo",
    "reports.BRANDING_HTML_STATION_RULES": "Backoffice",
    "reports.BRANDING_INFO_MESSAGE": "Mensagem 'PopUp' (aberta na estação depois do login)",
    "reports.BRANDING_KEY": "Chave de identidade visual (usada para ligar instaladores a ela)",
    "reports.BRANDING_KNOWLEDGE_BASE_URL": "URL de base de conhecimento (-1 para desabilitar)",
    "reports.BRANDING_LANG_SET": "Recursos de idioma",
    "reports.BRANDING_LIVE_GROUP": "Coloque usuários ao vivo autorregistrados em",
    "reports.BRANDING_LOGIN_MESSAGE": "Mensagem de atenção da tela de Login",
    "reports.BRANDING_MAIL_CONFIGURATION": "Configuração de correio",
    "reports.BRANDING_MAIL_PATTERN": "Padrão",
    "reports.BRANDING_NET_BACKGROUND": "Imagem do fundo (opcional/.bmp qualquer tamanho)",
    "reports.BRANDING_NET_BANNER": "Imagem do banner (.bmp 500x36)",
    "reports.BRANDING_NET_BROKERIMAGE": "Imagem do relatório (.bmp qualquer tamanho)",
    "reports.BRANDING_NET_CONFIGURAION": "Configuração padrão (arquivo program.ini)",
    "reports.BRANDING_NET_DEFWORKSPACE": "Área de Trabalho padrão (arquivo *.wrk)",
    "reports.BRANDING_NET_FX_STYLE": "Capa de Célula de negociação(digitar código)",
    "reports.BRANDING_NET_HELP": "URL de ajuda",
    "reports.BRANDING_NET_ICON": "Imagem do ícone (.ico 16x16+32x32)",
    "reports.BRANDING_NET_INSTALLER_BANNER_IMAGE": "Imagem do banner do instalador(500*59)",
    "reports.BRANDING_NET_INSTALLER_DIALOG_IMAGE": "Imagem do diálogo do instalador(500*316)",
    "reports.BRANDING_NET_LINK": "Link para o aplicativo",
    "reports.BRANDING_NET_LOADING_IMAGE": "Carregando imagem *.gif",
    "reports.BRANDING_NET_LOGIN_STYLE": "Capa de tela de login (digitar código)",
    "reports.BRANDING_NET_LOGO": "Imagem da logo (.bmp 500x35)",
    "reports.BRANDING_NET_NAME": "Nome do aplicativo (para dados do app, atalhos)",
    "reports.BRANDING_NET_OE_STYLE": "Capa de entrada de ordem (digitar código)",
    "reports.BRANDING_NET_RIBBONICON": "Imagem do ícone da fita (.ico 32x32)",
    "reports.BRANDING_NET_STATION_RULES": "Estação .Net",
    "reports.BRANDING_NET_USE_FINGERPRINT": "Usar impressão digital",
    "reports.BRANDING_PHONE": "Telefone da empresa",
    "reports.BRANDING_POWERED_BY": "Oferecido por (nome da empresa ou indivíduo)",
    "reports.BRANDING_PROPERTIES_RULES": "Propriedades de identidade visual",
    "reports.BRANDING_RULES": "Visibilidade de identidade visual",
    "reports.BRANDING_SCRIPT_URL": "URL de script",
    "reports.BRANDING_SERVER_URL": "URL do servidor da marca (URL ou ip para conectar a primeira página, antes do login)",
    "reports.BRANDING_STATUS_MESSAGE": "Mensagem na barra de situação (mostrada na barra de situação da estação)",
    "reports.BRANDING_THEME": "Aparência e sensação",
    "reports.BRANDING_TOOLS_RULES": "Links da Web externos no menu Ferramentas",
    "reports.BRANDING_TTP_FILE": "Modelos de painel",
    "reports.BRANDING_USERS_RULES": "Regras de usuário",
    "reports.BRANDING_WITHDRAW_URL": "URL de retirada (esvaziar para desabilitar, url para página, 1 -win form)",
    "reports.branding.name": "Nome",
    "reports.Broker Message Report": "Mensagens da corretora",
    "reports.Brokerage": "Corretagem",
    "reports.brokerCancel": "Cancelamento da corretora",
    "reports.brokerlogin": "Login da corretora",
    "reports.Buy": "Compra",
    "reports.buy trade amount": "Quantidade de negociações de Compra",
    "reports.buy trades": "Negociações de Compra",
    "reports.buyselltype": "Tipo Compra/Venda",
    "reports.Can't allocate route for trade using hadget": "Não é possível alocar rota para a negociação usando o dispositivo",
    "reports.Can't close entry": "Não é possível fechar a entrada",
    "reports.Cancel order:": "Cancelar ordem:",
    "reports.Cancel request accepted by broker": "Solicitação de cancelamento aceita pela corretora",
    "reports.Cancel request received by STP": "Solicitação de cancelamento recebida por STP",
    "reports.Canceled": "Cancelado",
    "reports.Canceling": "Cancelado",
    "reports.Cancelled": "Cancelado",
    "reports.Cancelling": "Cancelado",
    "reports.CantorFX": "CantorFX",
    "reports.Cash Deposit And Withdrawal Report": "Depósito e retirada (dinheiro)",
    "reports.cash_posted": "Dinheiro enviado",
    "reports.Champ stats report": "Estatísticas do campeonato",
    "reports.change": "Variação",
    "reports.Change password error, new password must differ from old one": "Erro na alteração da senha, a nova senha deve ser diferente de qualquer uma utilizada anteriormente",
    "reports.CHART_OE": "Gráfico (Construtido no OE)",
    "reports.CHART_VISUAL": "Gráfico (negociação visual)",
    "reports.Chat History Report": "Histórico do bate-papo",
    "reports.CheckCompID": "Verificar ID da competição",
    "reports.CheckLatency": "Verificar latência",
    "reports.ChoiceFX": "ChoiceFX",
    "reports.CIBC": "CIBC",
    "reports.Citi": "Citi",
    "reports.ClearingVmTransfer": "Transferência de compensação VM",
    "reports.clfr_close_date": "Data de fechamento",
    "reports.clfr_fund_name": "Nome do fundo",
    "reports.clientid": "ID do cliente",
    "reports.clienttype": "Tipo de cliente",
    "reports.Close Limit order created": "Ordem de fechamento 'Limit' criada",
    "reports.Close Limit order created is off market": "",
    "reports.Close Limit order created is unplaced": "",
    "reports.Close Limit order received by broker": "Ordem de fechamento 'Limit' recebida pela corretora",
    "reports.Close Market order created": "Ordem de fechamento à Mercado criada",
    "reports.Close Market Order Created": "Ordem de fechamento à Mercado criada",
    "reports.Close Market order created is off market": "",
    "reports.Close Market order created is unplaced": "",
    "reports.Close Market order received by broker": "Ordem de fechamento à Mercado recebida pela corretora",
    "reports.Close OCO order created": "Ordem de fechamento OCO de Mercado criada",
    "reports.Close OCO order created is off market": "",
    "reports.Close OCO order created is unplaced": "",
    "reports.Close OCO order received by broker": "Ordem de fechamento OCO recebida pela corretora",
    "reports.Close order type": "Tipo de ordem de fechamento",
    "reports.Close position:": "Fechar posição:",
    "reports.Close Sell": "Fechar Venda",
    "reports.Close SL order created": "Ordem SL de fechamento criada",
    "reports.Close SL order created is off market": "",
    "reports.Close SL order created is unplaced": "",
    "reports.Close SL order received by broker": "Ordem SL de fechamento recebida pela corretora",
    "reports.Close SL tr. stop order created": "Ordem 'Trailing Stop' SL de fechamento criada",
    "reports.Close SL tr. stop order created is off market": "",
    "reports.Close SL tr. stop order created is unplaced": "",
    "reports.Close SL tr. stop order received by broker": "Ordem 'Trailing Stop' SL recebida pela corretora",
    "reports.Close Stop Limit order created": "Ordem 'Stop Limit' de fechamento criada",
    "reports.Close Stop Limit order created is off market": "",
    "reports.Close Stop Limit order created is unplaced": "",
    "reports.Close Stop Limit order received by broker": "Ordem 'Stop Limit' de fechamento recebida pela corretora",
    "reports.Close Stop order created": "Ordem 'Stop' de fechamento criada",
    "reports.Close Stop order created is off market": "",
    "reports.Close Stop order created is unplaced": "",
    "reports.Close Stop order received by broker": "Ordem 'Stop' de fechamento recebida pela corretora",
    "reports.Close TP order created": "Ordem TP de fechamento criada",
    "reports.Close TP order created is off market": "",
    "reports.Close TP order created is unplaced": "",
    "reports.Close TP order received by broker": "Ordem TP de fechamento recebida pela corretora",
    "reports.Close Tr. stop order created": "Ordem 'Trailing Stop' de fechamento criada",
    "reports.Close Tr. stop order created is off market": "",
    "reports.Close Tr. stop order created is unplaced": "",
    "reports.Close Tr. stop order received by broker": "Ordem 'Trailing Stop' de fechamento recebida pela corretora",
    "reports.CLOSE_ALL": "Fechar todas",
    "reports.CLOSE_ALL_ORDERS": "Cancelar todas as ordens",
    "reports.CLOSE_ALL_POSITIONS": "Fechar todas as posições",
    "reports.CLOSE_FIFO": "Fechar posições enquanto a margem não for suficiente (FIFO)",
    "reports.CLOSE_LAGES": "Fechar posições enquanto a margem não for suficiente (A maior primeiro)",
    "reports.CLOSE_LIFO": "Fechar posições enquanto a margem não for suficiente (LIFO)",
    "reports.closebytradeid": "Fechar por ID de negociação",
    "reports.Closed funds report": "Fundos fechados",
    "reports.Closed Positions": "Posições fechadas",
    "reports.closedat": "Fechar",
    "reports.closedbyorderid": "Fechar por ID de ordem",
    "reports.ClosedResendInterval": "Intervalo de reenvio fechado",
    "reports.CloseOptionPremium": "Fechar Opção Premium",
    "reports.Closeposition": "Fechar posição",
    "reports.closeprice": "Preço de fechamento",
    "reports.closetime": "Hora de fechamento",
    "reports.comission": "Taxa",
    "reports.Comment": "Comentário",
    "reports.comments": "Comentários",
    "reports.comments.CancelOperation": "Cancel operation Nº",
    "reports.COMMISIONSPLAN_RULES": "Visibilidade de planos de receitas",
    "reports.commission": "Comissão",
    "reports.commission_out": "Taxa de saída",
    "reports.commissionpertrade": "Comissão por negociação",
    "reports.commissions": "Comissões",
    "reports.Commissions": "Taxa",
    "reports.Commissions report": "Comissões",
    "reports.Commissions Report": "Comissões",
    "reports.Commissions Report(copy)": "Comissões (cópia)",
    "reports.commissiontotal": "Total de comissão",
    "reports.COMMON_RULES": "Regras comuns",
    "reports.Community": "Comunidade",
    "reports.ConfigFilePath": "Configurar caminho de arquivo",
    "reports.Connected": "Conectado",
    "reports.Connection established": "Conexão estabelecida",
    "reports.Connections Report": "Conexões",
    "reports.ConnectionType": "Tipo de conexão",
    "reports.connrp_conntime": "Tentativa de tempo de conexão",
    "reports.connrp_fullname": "Nome e sobrenome",
    "reports.connrp_ip": "IP",
    "reports.connrp_lastpwdchange": "Última alteração de senha",
    "reports.connrp_login": "Login",
    "reports.connrp_success": "Sucesso da conexão",
    "reports.ContinueInitializationOnError": "Continuar inicialização no erro",
    "reports.Contract Specification Report": "Especificações de contrato",
    "reports.Counter Account Name": "Nome da conta de contrapartida",
    "reports.Counter Operation Id": "ID da operação de contrapartida",
    "reports.COUNTERACCOUNTS_RULES": "Contas de contrapartida",
    "reports.Created": "Criado",
    "reports.Created by": "Criado por",
    "reports.createdat": "Criado em",
    "reports.Creating": "Criado",
    "reports.creator": "Criador",
    "reports.credit": "Crédito",
    "reports.Credit": "Crédito",
    "reports.crossprice": "Preço de cruzamento",
    "reports.crosstype": "Tipo de cruzamento",
    "reports.currency": "Moeda",
    "reports.Currency Exposure Report": "Exposições de moedas",
    "reports.Currency summary exposure": "Resumo de exposição da moeda",
    "reports.currency.balance_interest": "Juros do saldo (%)",
    "reports.currency.interest_buy_rate": "Juros no positivo (%)",
    "reports.currency.interest_sell_rate": "Juros no negativo (%)",
    "reports.currency.leverage_negative": "Exposição de alavancagem negativa",
    "reports.currency.leverage_positive": "Exposição de alavancagem positiva",
    "reports.currency.min_change": "Mudança mínima",
    "reports.currency.name": "Nome",
    "reports.Currenex": "Currenex",
    "reports.CurrenexFIX": "CurrenexFIX",
    "reports.Current Balance": "Saldo atual",
    "reports.currentprice": "Preço atual",
    "reports.CUSTOM_COLUMN_BTN_SCRIPT": "Coluna personalizada (Botao 'Script')",
    "reports.customReport": "Relatórios personalizados",
    "reports.DataDictionary": "Dicionário de dados",
    "reports.DateTime": "Data/Hora",
    "reports.days": "dias",
    "reports.daystillexpiration": "Dias até vencimento",
    "reports.DBFX": "DBFX",
    "reports.dealercommission": "Comissão do operador",
    "reports.debit": "Débido",
    "reports.Default settings failed": "Falha nas configurações padrões",
    "reports.Delayed Limit Order Created": "Ordem 'Limit' pendente criada",
    "reports.Delayed Limit Order Modified": "Ordem 'Limit' pendente modificada",
    "reports.Delayed Limit Order Removed": "Ordem ´Limit' pendente removida",
    "reports.Delayed OCO Order Created": "Ordem OCO pendente criada",
    "reports.Delayed Stop Limit Order Created": "Ordem 'Stop Limit' criada",
    "reports.Delayed Stop Limit Order Modified": "Ordem 'Stop Limit' modificada",
    "reports.Delayed Stop Limit Order Removed": "Ordem 'Stop Limit' removida",
    "reports.Delayed Stop Order Created": "Ordem 'stop' pendente criada",
    "reports.Delayed Stop Order Modified": "Ordem 'Stop' pendente modificada",
    "reports.Delayed Stop Order Removed": "Ordem 'Stop' pendente removida",
    "reports.Delayed Trailing Stop Order Created": "Ordem 'Trailing stop' criada",
    "reports.Delayed Trailing Stop Order Modified": "Ordem 'Trailing stop' modificada",
    "reports.Delayed Trailing Stop Order Removed": "Ordem 'Trailing stop' removida",
    "reports.DelayedCharging": "Carregamento Adiado",
    "reports.DelayedDataCharging": "Carga de dados adiada",
    "reports.delivered": "Entregue",
    "reports.DeliveredForward": "Realizados para entrega futura",
    "reports.Delivery": "Entrega",
    "reports.demoMode": "Modo demonstração",
    "reports.deposit": "Depósito",
    "reports.Deposit": "Depósito",
    "reports.Detail": "Detalhe",
    "reports.Deutsche Bank": "Deutsche Bank",
    "reports.DeutscheBankFIXFeed": "DeutscheBankFIXFeed",
    "reports.DGCX": "DGCX",
    "reports.Dictionary": "Dicionário",
    "reports.discount": "Desconto",
    "reports.dividends": "Dividendos",
    "reports.DO_NOT_CLOSE": "Não fechar posições",
    "reports.DukasCopy": "DukasCopy",
    "reports.DWT": "DWT",
    "reports.email": "Email",
    "reports.EMAIL_NOTIFICATION": "E-Mail padrão de notificação da Visibilidade",
    "reports.EMAIL_RULES": "E-Mail padrão de notificação da Visibilidade",
    "reports.EndDay": "Fim do dia",
    "reports.EndTime": "Hora de término",
    "reports.entityId": "ID da entidade",
    "reports.enum_StopOutType_AutoClose": "Fechamento automático",
    "reports.enum_StopOutType_CloseAllByInstruments": "Fechar todos por Ativos",
    "reports.enum_StopOutType_CloseAllPositions": "Fechar todas as posições",
    "reports.enum_StopOutType_CloseLagestLoss": "Fechar posições enquanto a margem não for suficiente (A maior perda primeiro)",
    "reports.enum_StopOutType_ClosePositionsByMarginExcess": "Fechar posições por excesso de margem",
    "reports.enum_StopOutType_DoNotClosePositions": "Não fechar posições",
    "reports.enum_StopOutType_FIFO": "Fechar posições enquanto a margem não for suficiente (FIFO)",
    "reports.enum_StopOutType_LargestFirst": "Fechar posições enquanto a margem não for suficiente (A maior primeiro)",
    "reports.enum_StopOutType_LIFO": "Fechar posições enquanto a margem não for suficiente (LIFO)",
    "reports.Error occured during downloading update:": "Houve um erro durante o download da atualização:",
    "reports.Error sending email": "Erro no envio de e-mail",
    "reports.Error: Not enough Margin to open position": "Erro: Não há margem suficiente para abrir a posição",
    "reports.error.certificate_corrupted": "Certificado de usuário está corrompido.",
    "reports.error.certificate_not_found": "Certificado de usuário não encontrado.",
    "reports.error.error_during_saving_route": "Houve um erro ao salvar a rota. Por favor, entre em contato com o administrador.",
    "reports.error.fill_mandatory": "Favor preencher todos os campos obrigatórios.",
    "reports.error.name_is_in_use": "Este nome já existe!",
    "reports.error.not_valid_email": "E-mail inválido.",
    "reports.error.not_valid_firstname": "Nome inválido.",
    "reports.error.not_valid_instrumentname_capital": "Nome inválido. Apenas letras maiúsculas são requeridas.",
    "reports.error.not_valid_instrumentname_exist": "Nome inválido. Este nome já existe.",
    "reports.error.not_valid_instrumentname_length": "Tamanho de nome inválido. O nome não pode ter mais de 32 caracteres.",
    "reports.error.not_valid_instrumenttypename": "Tipo de Ativo inválido. Favor especificar tipo de Ativo correto.",
    "reports.error.not_valid_lastname": "Sobrenome inválido.",
    "reports.error.not_valid_login": "Login inválido.",
    "reports.error.not_valid_name": "Nome inválido.",
    "reports.error.not_valid_name_exist": "Nome inválido. Este nome já existe.",
    "reports.error.not_valid_phone_number": "Número de telefone inválido. Insira apenas dados digitais.",
    "reports.error.photo_not_found": "Foto não encontrada",
    "reports.error.too_short_phone_password": "Senha de telefone muito curta",
    "reports.error.wrong_percenage": "Percentual errado",
    "reports.ESignal": "ESignal",
    "reports.event": "Evento",
    "reports.Event History Report": "Histórico de eventos",
    "reports.Event History Report with IP": "",
    "reports.Exception": "Exceção",
    "reports.exchange": "Bolsa",
    "reports.Exchange": "Bolsa",
    "reports.Executed": "Executada",
    "reports.Executed Orders Report": "Ordens executadas",
    "reports.executedat": "Executar",
    "reports.Executing": "Executada",
    "reports.executionfee": "Taxa de excução",
    "reports.executionprice": "Preço de execução",
    "reports.expiredate": "Data de vencimento",
    "reports.expiresat": "Vence",
    "reports.external_order_id": "ID da ordem externa",
    "reports.externalid": "ID externo",
    "reports.externalorderid": "ID da ordem externa",
    "reports.extfield": "Campo est.",
    "reports.EXTFIELDS_RULES": "Visibilidade de campos estendidos",
    "reports.extprice": "Preço ext.",
    "reports.False": "Falso",
    "reports.fee": "Taxa",
    "reports.Fee: [addliquidityrebate]": "Taxa: [AdicionarRebateDeLiquidez]",
    "reports.Fee: [Brokerage]": "Taxa: [Corretagem]",
    "reports.Fee: [Exchange]": "Taxa: [Bolsa]",
    "reports.Fee: [InvestorProtectionLevy]": "Taxa: [ImposiçãoDeProteçãoDoInvestidor]",
    "reports.Fee: [removeliquidityrebate]": "Taxa: [RemoveTaxaDeLiquidez]",
    "reports.Fee: [SecuritiesTransferTax]": "Taxa: [TaxaDeTransfereinciaSegura]",
    "reports.Fee: [SettlementFee]": "Taxa: [TaxaDeLiquidação]",
    "reports.Fee: [ValueAddedTax]": "Taxa: [ImpostoSobreValorAcrescentado]",
    "reports.Feed Data": "Alimentar dados",
    "reports.Fees Report": "Taxas",
    "reports.FFastFillFIXFeed": "FFastFillFIXFeed",
    "reports.fiahr_action": "Ação",
    "reports.fiahr_amount": "Quantidade",
    "reports.fiahr_capital": "Capital",
    "reports.fiahr_count": "Contagem de Ação",
    "reports.fiahr_date": "Data",
    "reports.fiahr_fee": "Recompensa",
    "reports.fiahr_grosspl": "L/P bruto",
    "reports.fiahr_investor_id": "ID do investidor",
    "reports.fiahr_investor_name": "Investidor",
    "reports.fiahr_netpl": "Liquido L/P",
    "reports.fiahr_pamm_id": "ID do fundo",
    "reports.fiahr_pamm_name": "Fundo",
    "reports.fiahr_price": "Preço",
    "reports.fiahr_requestid": "ID da solicitação",
    "reports.Fidessa": "Fidessa",
    "reports.FileIncludeMilliseconds": "Arquivos incluem milissegundos",
    "reports.FileIncludeTimeStampForMessages": "Arquivos incluem registro de tempo das mensagens",
    "reports.FileLogHeartbeats": "Arquivo de log de 'HeartBeats'",
    "reports.Filled": "Executado",
    "reports.Filled close Limit order": "Ordem de fechamento 'Limit' executada",
    "reports.Filled close Limit order (Mutual)": "Ordem de fechamento 'Limit' executada (Múltipla)",
    "reports.Filled close Market order": "Ordem de fechamento à Mercado executada",
    "reports.Filled close Market order (Mutual)": "Ordem de fechamento à Mercado executada (Múltipla)",
    "reports.Filled close OCO order": "Ordem de fechamento OCO executada",
    "reports.Filled close OCO order (Mutual)": "Ordem de fechamento OCO executada (Múltipla)",
    "reports.Filled close SL order": "Ordem de fechamento SL executada",
    "reports.Filled close SL order (Mutual)": "Ordem de fechamento SL executada (Múltipla)",
    "reports.Filled close SL tr. stop order": "Ordem de fechamento 'Trailing Stop' SL executada",
    "reports.Filled close SL tr. stop order (Mutual)": "Ordem de fechamento 'Trailing Stop' SL executada (Múltipla)",
    "reports.Filled close Stop Limit order": "Ordem de fechamento 'Stop Limit' executada",
    "reports.Filled close Stop Limit order (Mutual)": "Ordem de fechamento 'Stop Limit' executada (Múltipla)",
    "reports.Filled close Stop order": "Ordem de fechamento 'Stop' executada",
    "reports.Filled close Stop order (Mutual)": "Ordem de fechamento 'Stop' executada (Múltipla)",
    "reports.Filled close TP order": "Ordem de fechamento TP executada",
    "reports.Filled close TP order (Mutual)": "Ordem de fechamento TP executada (mutuamente)",
    "reports.Filled close Tr. stop loss order": "Executado fechamento pela ordem Trailing Stop",
    "reports.Filled close Tr. stop order": "Ordem de fechamento 'Trailing Stop' executada",
    "reports.Filled close Tr. stop order (Mutual)": "Ordem de fechamento 'Trailing Stop' executada (Múltipla)",
    "reports.Filled Limit order": "Ordem 'Limit' executada",
    "reports.Filled Limit order (Mutual)": "Ordem 'Limit' executada (Múltipla)",
    "reports.Filled Market order": "Ordem à Mercado executada",
    "reports.Filled Market order (Mutual)": "Ordem à Mercado executada (Múltipla)",
    "reports.Filled OCO order": "Ordem OCO executada",
    "reports.Filled OCO order (Mutual)": "Ordem OCO executada (Múltipla)",
    "reports.Filled SL order": "Ordem SL executada",
    "reports.Filled SL order (Mutual)": "Ordem SL executada (Múltipla)",
    "reports.Filled SL tr. stop order": "Ordem 'Trailing Stop' SL executada",
    "reports.Filled SL tr. stop order (Mutual)": "Ordem 'Trailing Stop' SL executada (Múltipla)",
    "reports.Filled Stop Limit order": "Ordem 'Stop Limit' executada",
    "reports.Filled Stop Limit order (Mutual)": "Ordem 'Stop Limit' executada (Múltipla)",
    "reports.Filled Stop order": "Ordem 'Stop' executada",
    "reports.Filled Stop order (Mutual)": "Ordem 'Stop' executada (Múltipla)",
    "reports.Filled TP order": "Ordem TP executada",
    "reports.Filled TP order (Mutual)": "Ordem TP executada (Múltipla)",
    "reports.Filled Tr. stop order": "Ordem 'Trailing Stop' executada",
    "reports.Filled Tr. stop order (Mutual)": "Ordem 'Trailing Stop' executada (Múltipla)",
    "reports.filledamount": "Quantidade executada",
    "reports.fir_exit_capital": "Capital atual",
    "reports.fir_exit_price": "Preço atual da Ação",
    "reports.fir_investor_id": "ID do investidor",
    "reports.fir_investor_name": "Nome do investidor",
    "reports.fir_join_capital": "Capital inicial",
    "reports.fir_join_date": "Data de entrada",
    "reports.fir_join_price": "Preço de adesão",
    "reports.fir_pamm_id": "ID do fundo",
    "reports.fir_pamm_name": "Nome do fundo",
    "reports.fir_reinvest_cycles": "Ciclos de reinvestimento",
    "reports.fir_share_count": "Nº de ações",
    "reports.FirstDerivatives": "Primeiros Derivados",
    "reports.firstname": "Primeiro Nome",
    "reports.FixedFee": "",
    "reports.Foreks": "Foreks",
    "reports.Forex balances report": "Saldos em Forex",
    "reports.Formal Dialog Report": "Diálogos formais",
    "reports.FORTEX": "FORTEX",
    "reports.fpghr_count": "Ações",
    "reports.fpghr_exit_amount": "Quantia de saída",
    "reports.fpghr_exit_price": "Preço de saída",
    "reports.fpghr_fee": "Taxa",
    "reports.fpghr_first_join_date": "Data de entrada",
    "reports.fpghr_grosspl": "L/P bruto",
    "reports.fpghr_investor_id": "ID do investidor",
    "reports.fpghr_investor_name": "Nome do investidor",
    "reports.fpghr_join_amount": "Quantia de adesão",
    "reports.fpghr_join_price": "Preço de adesão",
    "reports.fpghr_last_exit_date": "Data de saída",
    "reports.fpghr_netpl": "Liquido L/P",
    "reports.fpghr_pamm_id": "ID do fundo",
    "reports.fpghr_pamm_name": "Nome do fundo",
    "reports.fpghr_period": "Período",
    "reports.fpghr_period_end_date": "Data de término",
    "reports.fpghr_period_start_date": "Data de início",
    "reports.fprhr_date": "Última atualização",
    "reports.fprhr_details": "Detalhes",
    "reports.fprhr_entryid": "ID da entrada",
    "reports.fprhr_event": "Evento",
    "reports.fprhr_investor_id": "ID do investidor",
    "reports.fprhr_investor_name": "Investidor",
    "reports.fprhr_pamm_id": "ID do fundo",
    "reports.fprhr_pamm_name": "Fundo",
    "reports.fprhr_requestid": "ID da solicitação",
    "reports.from": "De",
    "reports.From.SERVER": "Servidor",
    "reports.fromDate": "De",
    "reports.fromDateConnAttempt": "De",
    "reports.fromDatePwdChange": "De (passo)",
    "reports.FUNCTION_ACCOUNT_GROUP": "Grupo de conta",
    "reports.FUNCTION_ACCOUNT_OPERATIONS": "Visibilidade das operações de financiamento",
    "reports.FUNCTION_ACCOUNTS": "Contas",
    "reports.FUNCTION_ALERTS": "Alertas",
    "reports.FUNCTION_ARBITRAGER": "Arbitrador",
    "reports.FUNCTION_AUTOBROKET_LIST": "Lista de 'AutoBrokers'",
    "reports.FUNCTION_BACK_TESTER": "Backtester",
    "reports.FUNCTION_BARS_FROM": "Criar barras de",
    "reports.FUNCTION_BASE_CURRENCY": "Moeda base do sistema (você pode precisar alterar cruzamentos de Ativos)",
    "reports.FUNCTION_BASKET": "Cesta",
    "reports.FUNCTION_BETTING": "Aposta",
    "reports.FUNCTION_BINDEDORDERS": "Permitir ordens OCO",
    "reports.FUNCTION_BLOCKED_SUMM_FOR_ORDERS": "Fundo bloqueado para ordens",
    "reports.FUNCTION_BRANDING": "Identidades visuais",
    "reports.FUNCTION_BRANDINGADMIN": "Administrador de identidade visual",
    "reports.FUNCTION_BUILDER": "Construtor",
    "reports.FUNCTION_CACHEADMIN": "Administrador de cache",
    "reports.FUNCTION_CHART": "Gráfico",
    "reports.FUNCTION_CHAT": "Bate papo",
    "reports.FUNCTION_CLOSE_POSITIONS_DISABLE": "Não permitir posições fechadas",
    "reports.FUNCTION_COMMUNITY": "Comunidade",
    "reports.FUNCTION_CONFIGADMIN": "Configuração e identidade visual",
    "reports.FUNCTION_CONNECTIONSSADMIN": "Administrador de conexões",
    "reports.FUNCTION_CROSS_PAIR": "Preço de cruzamento",
    "reports.FUNCTION_CUR_EXP": "Exposição de moeda",
    "reports.FUNCTION_DISABLE_BUY": "Desabilitar ordens de Compra",
    "reports.FUNCTION_DISABLE_OPTION_TRADING": "Desabilitar ordens de opção",
    "reports.FUNCTION_DISABLE_SELL": "Desabilitar ordens de Venda",
    "reports.FUNCTION_DO_NOT_MARGIN": "Não usar margem",
    "reports.FUNCTION_EVENT_LOG": "Log de eventos",
    "reports.FUNCTION_FUNDING": "Administrador de operações",
    "reports.FUNCTION_GENERATE_OPTIONS": "Gerar opções a partir do nível 1",
    "reports.FUNCTION_HEDGE_RULE_TYPE": "Lista de regras de 'Hedge':",
    "reports.FUNCTION_INSTRUMENT_LOOKUP": "Pesquisa de Ativo",
    "reports.FUNCTION_INSTRUMENT_PORTFOLIO": "Portfólio",
    "reports.FUNCTION_INSTRUMENTSADMIN": "Adminstrador de Ativos",
    "reports.FUNCTION_INTERSERVER": "Interservidor",
    "reports.FUNCTION_LEVEL2": "Dados e ordens de Nível2",
    "reports.FUNCTION_LEVEL3": "Time & Sales",
    "reports.FUNCTION_LOCKED_BY_BROKER": "Bloqueado pela corretora",
    "reports.FUNCTION_MANUAL": "Permitir ordens manuais",
    "reports.FUNCTION_MARGIN_MODE": "Modo de margem",
    "reports.FUNCTION_MASTER": "Gerenciamento da conta master",
    "reports.FUNCTION_MATRIX": "Matrix",
    "reports.FUNCTION_MULTI_CONNECTION": "Permitir multiconexão",
    "reports.FUNCTION_MUTUAL_CLOSE": "Permitir fechamento mútuo",
    "reports.FUNCTION_NEWS": "Notícias",
    "reports.FUNCTION_NEWSADMIN": "Administrador de notícias",
    "reports.FUNCTION_NOT_ALLOW_OPEN_POSITION": "Não permitir posições abertas",
    "reports.FUNCTION_NOT_ALLOW_SHORT_STOCK": "Não usar Venda em ações",
    "reports.FUNCTION_ONE_COUNTERACCOUNT": "Uma conta de contrapartida",
    "reports.FUNCTION_ONE_POSITION": "Uma posição por Ativo ('Hedging')",
    "reports.FUNCTION_ONE_QUOTECACHECOUNT": "Um tamanho de cache para todos",
    "reports.FUNCTION_OPTIONS": "Opções",
    "reports.FUNCTION_ORDER_BOOK": "Histórico de ordens",
    "reports.FUNCTION_PARTIAL_CLOSE": "Permitir fechamento parcial",
    "reports.FUNCTION_POSITION_BALANCE": "Saldo da pos.",
    "reports.FUNCTION_POSITIONSADMIN": "Administrador de posições",
    "reports.FUNCTION_QUOTE_HISTORY": "Histórico de cotações",
    "reports.FUNCTION_QUOTES": "Cotações",
    "reports.FUNCTION_REPORT": "Relatório",
    "reports.FUNCTION_REPORTADMIN": "Administrador de relatórios",
    "reports.FUNCTION_RESERVER_WITHDRAWAL": "Retirada",
    "reports.FUNCTION_RFQ": "Painel RFQ",
    "reports.FUNCTION_ROLESADMIN": "Administrador de grupos de usuários",
    "reports.FUNCTION_ROUTESADMIN": "Administrador de rotas",
    "reports.FUNCTION_SCHEDULERADMIN": "Administrador do agendador",
    "reports.FUNCTION_SCRIPT_BUILDER": "Construtor de 'Script'",
    "reports.FUNCTION_SERVICESADMIN": "Administrador de servidores",
    "reports.FUNCTION_SETTINGSADMIN": "Administrador de configurações",
    "reports.FUNCTION_SHOW_ORDERS": "Ordens",
    "reports.FUNCTION_SHOW_POSITIONS": "Posições",
    "reports.FUNCTION_SLAVE": "Escravo",
    "reports.FUNCTION_SLTP": "Permitir ordens SL/TP",
    "reports.FUNCTION_SMS_SERVICE_DEST": "Destino do serviço de SMS",
    "reports.FUNCTION_SN": "Número da licença",
    "reports.FUNCTION_STRATEGIESADMIN": "Administrador de estratégias",
    "reports.FUNCTION_SUPPORT_EMAIL": "E-mail da equipe de suporte",
    "reports.FUNCTION_TABLESADMIN": "Administrador de tabelas",
    "reports.FUNCTION_THREADEDSAVER": "Protetor de listagem habilitado",
    "reports.FUNCTION_TRADES": "Ordens executadas",
    "reports.FUNCTION_TRADING": "Negociação",
    "reports.FUNCTION_TRADING_MODE": "Modo de negociação",
    "reports.FUNCTION_TRAILING_STOP": "Permitir 'Trailing Stop'",
    "reports.FUNCTION_USER_CONNECTION_POLICY": "Política de multiconexão de usuário",
    "reports.FUNCTION_USERSADMIN": "Administrador de usuários",
    "reports.FUNCTION_VALIDATE_SSL_CERTIFICATE": "Precisa de validação do certificado SSL",
    "reports.FUNCTION_VENDOR_NAME": "Nome do fornecedor",
    "reports.FUNCTION_WATCHLIST": "Watchlist",
    "reports.Fund Gain History Report": "Histórico de ganhos do fundo",
    "reports.Fund Investments Report": "Fundos de investimentos",
    "reports.Fund Investor Action History Report": "Histórico de ações (investidor do fundo)",
    "reports.Fund Investor History Report": "Histórico de investidor do fundo",
    "reports.Fund Request History Report": "Histórico de solicitações do fundo",
    "reports.Fund Transfer": "Transferência de fundos",
    "reports.fund_type.closed": "Extremidade fechada",
    "reports.fund_type.open": "Extremidade aberta",
    "reports.fundID": "ID do fundo",
    "reports.FUNDING_RULES": "Visibilidade de operações de financiamento",
    "reports.fxintegrate.cert.host.verify": "verificação da integridade do 'Host' de 'fx' de integrado da negociação",
    "reports.fxintegrate.cert.root.ca.file": "arquivo ca de raiz de cert de fx integrado",
    "reports.fxintegrate.host": "'Host' de 'fx' integrado",
    "reports.fxintegrate.logging.file": "arquivo de registro de 'fx' integrado",
    "reports.fxintegrate.logging.maxfilecount": "contagem maxima de arquivo de registro 'fx' integrado",
    "reports.fxintegrate.logging.maxfilesize": "tamanho de arquivo máximo de registro de 'fx' integrado",
    "reports.fxintegrate.logging.output": "saída de registro de 'fx' integrado",
    "reports.fxintegrate.logging.priority": "prioridade de registro integrado de 'fx'",
    "reports.fxintegrate.port": "porta de 'fx' integrado",
    "reports.fxintegrate.protocol": "protocolo de 'fx' integrado",
    "reports.fxintegrate.usessl": "usar 'ssl' na integração de 'fx'",
    "reports.GENERAL_COMMON_RULES": "Regras gerais comuns",
    "reports.GENERAL_USER_RULES": "Regras gerais",
    "reports.GFT": "GFT",
    "reports.gi.available_margin": "Margem disponível",
    "reports.gi.avaliable_funds": "Fundos disponíveis",
    "reports.gi.balance": "Saldo",
    "reports.gi.balance_blocked_by_orders": "Bloqueado por ordens",
    "reports.gi.cur_balance": "Saldo projetado",
    "reports.gi.cur_margin": "Margem atual, %",
    "reports.gi.fund_capital": "Capital de fundo",
    "reports.gi.fund_used": "Fundos usados",
    "reports.gi.maintain_margin": "Margem de manutenção",
    "reports.gi.margin_orders": "Margem para ordens",
    "reports.gi.margin_positions": "Margem para posições",
    "reports.gi.margin_stopout": "Stop out",
    "reports.gi.margin_stoptrade": "Parar negociação",
    "reports.gi.margin_used": "Margem usada",
    "reports.gi.margin_warning": "Alerta de margem",
    "reports.gi.min_margin": "Margem mín.",
    "reports.gi.pos_maintenance_req": "Sol. manut. de pos.",
    "reports.gi.risk_lvl": "Nível de risco",
    "reports.gi.stock_value": "Ações",
    "reports.gi.today_released_pnl": "L/P realizado",
    "reports.gi.withdrawal_available": "Retirada disponível",
    "reports.Goldman Sachs": "Goldman Sachs",
    "reports.grosspl": "L/P bruto",
    "reports.groupid": "ID do grupo",
    "reports.groupname": "Grupo",
    "reports.GTL": "GTL",
    "reports.HeartBtInt": "Intervalo de 'HeartBeat'",
    "reports.hedged": "STP (Straight-Through processing)",
    "reports.helpIndex": "http://protrader.org/en/knowledge-base/pt3/desktop/account/reports",
    "reports.host": "Host",
    "reports.HOT_BUTTON": "Tecla de atalho",
    "reports.HotSpot": "HotSpot",
    "reports.hours": "horas",
    "reports.HTTP.SERVER.HOST": "HTTP SERVER HOST",
    "reports.HTTP.SERVER.PORT": "HTTP SERVER PORT",
    "reports.HTTP.SERVER.PROTOCOL": "HTTP SERVER PROTOCOL",
    "reports.HTTP.URL.HEARTBEAT": "HTTP URL HEARTBEAT",
    "reports.HTTP.URL.LOGIN": "HTTP URL LOGIN",
    "reports.HTTP.URL.LOGOUT": "HTTP URL LOGOUT",
    "reports.HTTP.URL.PASSWORD.VALIDATION": "HTTP URL DE SENHA DE VALIDAÇÃO",
    "reports.HTTP.URL.PRELOGIN": "HTTP URL PRELOGIN",
    "reports.HTTP.URL.QUERY": "HTTP URL DE CONSULTA",
    "reports.HTTP.URL.WORKFLOW": "HTTP URL DE FLUXO DE TRABALHO",
    "reports.IB Trades Report": "Negociações IB",
    "reports.IGIndex": "IGIndex",
    "reports.IndexingQuoteFrequency(sec)": "Indexação de frequência de cotação (seg)",
    "reports.Information": "Informações",
    "reports.Informational message": "Mensagem informativa",
    "reports.Init margin req.": "Margem inic.req.",
    "reports.Init. margin req.": "Margem inic.req.",
    "reports.INSTALLER_SERVICE_URL": "URL do instalador de servidor",
    "reports.InstrRouteMap": "Mapa de rota",
    "reports.instrtype": "Grupo do Ativo",
    "reports.INSTRTYPES_RULES": "Visibilidade de grupos de Ativos",
    "reports.instrument": "Ativo",
    "reports.Instrument": "Ativo",
    "reports.instrument group": "Grupo do Ativo",
    "reports.Instrument Specification": "Especificação de Ativo",
    "reports.Instrument Statistic Report": "Estatísticas do Ativo",
    "reports.Instrument Usage By Signal Report": "Uso de Ativo por relatório de sinal",
    "reports.Instrument Visibility Report": "Visibilidade de Ativo",
    "reports.INSTRUMENT_DERIVATIVES": "Derivativos",
    "reports.INSTRUMENT_GENERAL": "Geral",
    "reports.INSTRUMENT_MARGIN": "Margem",
    "reports.INSTRUMENT_TRADING": "Negociação",
    "reports.INSTRUMENT_TYPE_DERIVATIVES": "Derivativos",
    "reports.instrument.group": "Grupo do Ativo",
    "reports.instrument.name": "Nome do Ativo",
    "reports.instrumentgroup": "Grupo do Ativo",
    "reports.InstrumentId": "Ativo",
    "reports.instrumentname": "Ativo",
    "reports.instruments": "Ativo(s)",
    "reports.instrumenttype": "Tipo de Ativo",
    "reports.instrumenttype.duplicatename": "Grupo de Ativo com este nome já existe",
    "reports.instrumenttype.inheritanceerror": "Erro em tipos de herança",
    "reports.instrumentType.name": "Nome do grupo de Ativos",
    "reports.instrumentType.superId": "Nome do supertipo do Ativo",
    "reports.instrumentVisibilityReport": "Visibilidade de Ativo",
    "reports.Integral": "Integral",
    "reports.IntegralFIX": "IntegralFIX",
    "reports.InteractiveData": "InteractiveData",
    "reports.IntererstOnAvailableFundsAndUnsettledCash": "Juros sobre fundos disponíveis + Lucro Bruto",
    "reports.interest": "Juros",
    "reports.InterestOnAvailableFunds": "Juros sobre fundo disponível",
    "reports.InterestOnCash": "Juros sobre o dinheiro",
    "reports.InterestOnMarginAvailable": "Juros sobre a margem disponível",
    "reports.InterestOnMarginAvailableAndUnsettledCash": "Juros sobre a margem avaliada e dinheiro instável",
    "reports.Invalid installer hash.": "'Hash' do instalador inválido.",
    "reports.Invalid installer size.": "Tamanho de instalador inválido.",
    "reports.Invalid installer URL.": "URL do instalador inválida.",
    "reports.Invalid password:New password should have at least 1 upper case character, 1 lower case character, 1 number, 1 special character (e.g. @#$%) and contain 8 characters at least. Please try again.": "A nova senha deve ter pelo menos 1 caracter maiúsculo, 1 caractere minúsculo, 1 número, 1 caractere especial (por exemplo, @ # $%) e ter pelo menos 8 caracteres. Por favor, tente novamente.",
    "reports.Invalid price for the order.": "Preço inválido para a ordem.",
    "reports.Investor Action History Report": "Histórico de ações do investidor",
    "reports.investorID": "ID do investidor",
    "reports.InvestorProtectionLevy": "Impostos de proteção dos investidor",
    "reports.ip": "IP",
    "reports.IP": "IP",
    "reports.IQFeed": "IQFeed",
    "reports.irdnumber": "Número IR",
    "reports.is out of range": "está fora da faixa",
    "reports.IsOnlyLastPeriod": "Último período",
    "reports.isPriceStream": "é fluxo de preço",
    "reports.isShowLots": "Exibir Qtd. em lotes",
    "reports.IsShowLots": "Exibir Qtd. em lotes",
    "reports.IsShowPamm": "Exibir fundo",
    "reports.issingle": "É único",
    "reports.JdbcDriver": "Driver Jdbc",
    "reports.JdbcPassword": "Senha Jdbc",
    "reports.JdbcSessionIdDefaultPropertyValue": "ID da sessão JDBC da propriedade padrão",
    "reports.JdbcURL": "JdbcURL",
    "reports.JdbcUser": "Usuário Jdbc",
    "reports.JMS.INIT.QUEUE": "JMS INITIAL QUEUE",
    "reports.JMS.SERVER.HOST": "JMS SERVER HOST",
    "reports.JMS.SERVER.PASSWORD": "JMS SERVER PASSWORD",
    "reports.JMS.SERVER.PORT": "JMS SERVER PORT",
    "reports.JMS.SERVER.PROTOCOL": "JMS SERVER PROTOCOL",
    "reports.JMS.SERVER.USER": "JMS SERVER USER",
    "reports.JPMorganTrade": "JPMorganTrade",
    "reports.keystoreAlias": "Alias de armazenamento de chave",
    "reports.keystoreFile": "arquivo de armazenamento de chaves",
    "reports.KyteFIX": "KyteFIX",
    "reports.L1port": "PortaL1",
    "reports.L2port": "PortaL2",
    "reports.LADDER_VIEW": "Super DOM",
    "reports.LADDER_VIEW_OE": "Super DOM (build in OE)",
    "reports.Last fill price": "Último preço de execução",
    "reports.lastname": "Sobrenome",
    "reports.Level1Charging": "CarregandoNível1",
    "reports.Level1DataCharging": "Carga de dados de nível 1",
    "reports.Level2Charging": "CarregandoNível2",
    "reports.Level2DataCharging": "Carga de dados de nível 2",
    "reports.Level3Charging": "CarregandoNível3",
    "reports.Level3DataCharging": "Carga de dados de nível 3",
    "reports.Limit accepted by broker": "Ordem 'Limit' aceita pela corretora",
    "reports.Limit accepted by STP": "Ordem 'Limit' aceita pelo STP",
    "reports.Limit order accepted": "Ordem 'Limit' aceitada",
    "reports.Limit order activated": "",
    "reports.Limit order cancel request": "Solicitação de cancelamento da ordem 'Limit'",
    "reports.Limit order canceled": "Ordem 'Limit' cancelada",
    "reports.Limit order created": "Ordem 'Limit' criada",
    "reports.Limit Order Created": "Ordem 'Limit' criada",
    "reports.Limit order created is off market": "",
    "reports.Limit order created is unplaced": "",
    "reports.Limit order is awaiting market": "Ordens 'Limit' aguardando mercado",
    "reports.Limit Order Modified": "Ordem 'Limit' modificada",
    "reports.Limit order partially reject": "Ordem 'Limit' parcialmente cancelada",
    "reports.Limit order placing request": "Colocado pedido de ordem 'Limit'",
    "reports.Limit order received by broker": "Ordem 'Limit' recebida pela corretora",
    "reports.Limit order rejected": "Ordem 'Limit' rejeitada",
    "reports.Limit Order Removed": "Ordem 'Limit' removida",
    "reports.Limit order replace request": "Pedido de substituição da ordem 'Limit'",
    "reports.Limit order replaced": "Ordem 'Limit' substituída",
    "reports.Limit order restated": "",
    "reports.Linked Users": "Usuários conectados",
    "reports.LMAX": "LMAX",
    "reports.LMAX FIX": "LMAX FIX",
    "reports.Local": "Local",
    "reports.locked": "Bloqueado",
    "reports.LogFileMaxSize": "Tamanho máximo de arquivo de log",
    "reports.login": "Conta(s)",
    "reports.Login": "Login",
    "reports.LOGIN.USER.NAMESPACE": "LOGIN USER NAMESPACE",
    "reports.Login/password combination is not valid": "",
    "reports.LogonTimeout": "Tempo limite de login",
    "reports.Logout": "Sair",
    "reports.Lots to open": "Lotes a serem abertos",
    "reports.lotsize": "Tamanho do lote",
    "reports.Mail Settings": "Configuração de correio",
    "reports.mail.mailsettings": "Configuração de correio",
    "reports.mailPattern.ChatDuplicating": "Duplicar de bate papo",
    "reports.mailPattern.DemoUserNotify": "Notificação de data de vencimento de usuário demo",
    "reports.mailPattern.DemoUserRegistration": "Confirmação de registro de usuário demo",
    "reports.mailPattern.DemoUserRemove": "Confirmação de remoção de usuário demo",
    "reports.mailPattern.DepositWithdrawalNotification": "E-mail de notificação de depósito/retirada",
    "reports.mailPattern.MarginCall": "Notificação de 'Margin call'",
    "reports.mailPattern.NewsCreated": "Notícias Foram criadas",
    "reports.mailPattern.OrderPlaced": "A ordem 'Stop'/'Limit' foi colocada",
    "reports.mailPattern.OrderRemoved": "A ordem 'Stop'/'Limit' foi removida",
    "reports.mailPattern.PositionClosed": "O fechamento da posição foi confirmado",
    "reports.mailPattern.PositionOpened": "A colocação da posição foi confirmada",
    "reports.mailPattern.SignalDuplicating": "Duplicação de sinal",
    "reports.mailPattern.TransactionStatementNotifyBroker": "Relatório diário pela corretora",
    "reports.mailPattern.TransactionStatementNotifyUser": "Relatório diário para usuário",
    "reports.mailPattern.UserEmailConfirmation": "E-mail de confirmação",
    "reports.mailPattern.UserPasswordRecovery": "Recuperação de senha de usuário",
    "reports.mailPattern.UserPasswordReset": "Redefinição de senha de usuário",
    "reports.mailPattern.UserRegistration": "Confirmação de registro de usuário",
    "reports.mailPattern.UserUnlocking": "Desbloqueio do usuário após a 'força bruta'",
    "reports.Main": "Principal",
    "reports.MAM trades report": "Negociações MAM",
    "reports.MAM_OE": "MAM (criado no EO)",
    "reports.MamManagementFee": "Taxa de gerenciamento de MAM",
    "reports.MamPerfomanceFee": "TaxaDePerformaceDaMam",
    "reports.MamPerformanceFee": "Taxa de performace da MAM",
    "reports.Manual order cancel request": "Cancelamento da ordem manual foi requisitada",
    "reports.Manual order placing request": "Colocação da ordem manual foi requisitada",
    "reports.Manual order replace request": "Substituição da ordem manual foi requisitada",
    "reports.ManufacturedDividends": "Dividendos produzidos",
    "reports.margin": "Margem",
    "reports.Margin Call": "Margin call",
    "reports.Margin Call Reached": "'Margin call' foi alcançada",
    "reports.Margin Call Warning.": "Alerta de 'Margin call'.",
    "reports.Margin used": "Margem usada",
    "reports.MARGIN_RULES_ACC": "Regras de margem (conta)",
    "reports.MARGIN_RUSLES": "Regras de margem",
    "reports.Margincall": "Margin call",
    "reports.MarginCallMessage.header.MarginCallReached": "'Margin call' foi alcançada",
    "reports.MarginCallMessage.header.MarginWarning": "Alerta de margem",
    "reports.MarginCallMessage.Message": "Por favor faça um depósito em sua conta",
    "reports.Market accepted by broker": "A ordem à Mercado foi aceita pela corretora",
    "reports.Market accepted by STP": "A ordem à Mercado foi aceita pelo STP",
    "reports.Market Close Order Removed": "A ordem de fechamento à Mercado foi removida",
    "reports.Market data charging report": "Encargos do 'Market Data'",
    "reports.Market order accepted": "A ordem à Mercado foi aceita",
    "reports.Market order activated": "",
    "reports.Market order cancel request": "Pedido de cancelamento da ordem requisitado",
    "reports.Market order canceled": "A ordem à Mercado foi cancelada",
    "reports.Market order created": "A ordem à Mercado foi criada",
    "reports.Market Order Created": "A ordem à Mercado foi criada",
    "reports.Market order created is off market": "",
    "reports.Market order created is unplaced": "",
    "reports.Market Order Modified": "A ordem à Mercado modificada",
    "reports.Market order partially reject": "A ordem à Mercado foi parcialmente cancelada",
    "reports.Market order placing request": "Colocação da ordem à Mercado foi requisitado",
    "reports.Market order received by broker": "A ordem à Mercado foi recebida pela corretora",
    "reports.Market order rejected": "A ordem à Mercado foi rejeitada",
    "reports.Market Order Removed": "A ordem à Mercado foi removida",
    "reports.Market order replace request": "A substituição da ordem à Mercado foi requisitado",
    "reports.Market order replaced": "A ordem à Mercado foi substituída",
    "reports.Market order restated": "",
    "reports.Market price": "Preço de Mercado",
    "reports.MARKETDEPTH": "'Book' de Ofertas",
    "reports.MarketFactory Feed": "MarketFactory Feed",
    "reports.Markup": "Remarcação",
    "reports.MATRIX": "Matrix",
    "reports.MATRIX_OE": "Matrix (criado em EO)",
    "reports.MaxLogsBackups": "Maximo de logs ed Backups",
    "reports.MBT": "MBT",
    "reports.message": "Mensagem",
    "reports.Message": "Mensagem",
    "reports.messageid": "ID da mensagem",
    "reports.MFGlobal": "MFGlobal",
    "reports.MillisecondsinTimeStamp": "Milissegundos no registro de tempo",
    "reports.MillisecondsInTimeStamp": "Milissegundos no registro de tempo",
    "reports.minchange": "Mudança mín.",
    "reports.MinFeeAdjEquity": "Taxa mínima de ajuste do Capital Líquido",
    "reports.Minimum Open Margin": "Margem mínima de abertura",
    "reports.minutes": "minuto",
    "reports.MLFIX": "MLFIX",
    "reports.MOB_CANCEL_ALL_ORDERS": "Mobile (Cancelar todas as ordens)",
    "reports.MOB_CHART_OE": "Gráfico Mobile (Entrada de Ordem)",
    "reports.MOB_CLOSE_ALL_POSITIONS": "Mobile (Fechar todas as pocições)",
    "reports.MOB_MARKETDEPTH": "'Book' de Ofertas Mobile",
    "reports.MOB_ORDERS_HOT_BTN": "Ordens abertas Mobile (Botões rápidos)",
    "reports.MOB_OTHER": "Outros Mobile",
    "reports.MOB_POSITIONS_HOT_BTN": "Posições Mobile (Botões rápidos)",
    "reports.MOB_TAB_CHART_VISUAL_HOT_BTN": "Gráfico Mobile (criado no EO/Tablets)",
    "reports.MOB_WL_OE": "Cotações Mobile (Entrada de Ordem)",
    "reports.Modified": "Modificado",
    "reports.Modifing": "Modificado",
    "reports.Modifyied by": "Modificado por",
    "reports.Modifying": "Modificado",
    "reports.months": "meses",
    "reports.Morgan Stanley": "Morgan Stanley",
    "reports.MorganStanleyCFD": "MorganStanleyCFD",
    "reports.Mutual close request": "Múltiplo fechamento requisitado",
    "reports.name": "Nome",
    "reports.NAMESPACE": "NAMESPACE",
    "reports.NEED_EMAIL_CONFIRM_ON_REGISTRATION": "Confirmação de registro de conta por e-mail",
    "reports.NeoNet": "NeoNet",
    "reports.Net Open Positions": "Liquido em Posições abertas",
    "reports.netpl": "L/P Liquido",
    "reports.New Back Office Changes Report": "Novas alterações do 'BackOffice'",
    "reports.New password must differ from several previous passwords.": "A nova senha deve ser diferente das senhas anteriores utilizadas",
    "reports.New Users Report": "Novos usuários",
    "reports.news.feed.type": "Tipo de alimentação de notícias",
    "reports.news.reciving": "Recebimento de notícias",
    "reports.news.save": "salvar notícias",
    "reports.news.sendEmail": "enviar notícias por e-mail",
    "reports.NewsCharging": "Carga de notícias",
    "reports.NewsFeed": "NewsFeed",
    "reports.newvalue": "Novo valor",
    "reports.no such position, no such order.": "esta posição não existe, esta ordem não existe.",
    "reports.Nomura": "Nomura",
    "reports.Nomura-Lehman": "Nomura-Lehman",
    "reports.None": "Nenhum",
    "reports.Not enoght margin to create Order": "Não há margem suficiente para criar ordem",
    "reports.Not enough margin to open order": "Não há margem suficiente para abrir a ordem",
    "reports.Not enough Margin to open position": "Não há margem suficiente para abrir a posição",
    "reports.Not enough offer": "Não existe ofertas",
    "reports.Not Filled Orders Report": "Ordens não executadas",
    "reports.Not Successful": "Sem sucesso",
    "reports.notShowMam": "Não exibir MAM",
    "reports.NotSuccessful": "Sem sucesso",
    "reports.number": "número",
    "reports.objectid": "ID do objeto",
    "reports.ObjectId": "ID da ordem",
    "reports.ObjectID": "ID do objeto",
    "reports.OCO accepted by broker": "Q ordem OCO foi aceita pela corretora",
    "reports.OCO accepted by STP": "A ordem OCO foi aceita pelo STP",
    "reports.OCO order activated": "",
    "reports.OCO order cancel request": "Cancelamento da ordem OCO foi requisitado",
    "reports.OCO order canceled": "A ordem OCO foi cancelada",
    "reports.OCO order created": "A ordem OCO foi criada",
    "reports.OCO Order Created": "A ordem OCO foi criada",
    "reports.OCO order created is off market": "",
    "reports.OCO order created is unplaced": "",
    "reports.OCO order partially reject": "A ordem OCO foi parcialmente cancelada",
    "reports.OCO order placing request": "A colocação da ordem OCO foi requisitada",
    "reports.OCO order received by broker": "A ordem OCO foi recebida pela corretora",
    "reports.OCO order rejected": "A ordem OCO foi rejeitada",
    "reports.OCO order replace request": "A substituição de ordem OCO foi requisitada",
    "reports.OCO order replaced": "A ordem OCO foi substituída",
    "reports.OCO order restated": "",
    "reports.OE": "EO",
    "reports.Off market": "",
    "reports.ONE_MARGIN_FOR_USER_ACC": "Uma margem para contas de usuários",
    "reports.Open": "Abertura",
    "reports.Open Buy": "Compra Aberta",
    "reports.Open date": "Data de abertura",
    "reports.Open lots": "Lotes Abertos",
    "reports.Open order is forbidden by trade session": "Abertura de ordes esta proibida pela seção de negociação",
    "reports.Open order type": "Tipo de aberetura de ordem",
    "reports.Open Positions By Signal Report": "Posições abertas por sinal",
    "reports.Open Positions Report": "Posições abertas",
    "reports.Open price": "Preço de abertura",
    "reports.Open Sell": "Venda aberta",
    "reports.OPEN_SPOT_ON_OPTION_EXP": "Abrir posição 'Spot' no vencimento da opção",
    "reports.openclosebuysell": "Tipo de operação",
    "reports.opengrosspnl": "L/P bruto de abertura",
    "reports.OpenOptionPremium": "Abrir Opção Premium",
    "reports.openorderid": "ID de ordem aberta",
    "reports.openprice": "Preço de abertura",
    "reports.opentime": "Hora de abertura",
    "reports.operation": "Operação",
    "reports.Operation": "Operação",
    "reports.Operation Id": "ID da operação",
    "reports.Operation type": "Tipo de operação",
    "reports.Operation Type": "Tipo de operação",
    "reports.operation.not_enough_margin": "Não há margem suficiente",
    "reports.operation.wrong_length": "Qtd. muito grande",
    "reports.operation.wrong_length;": "Qtd. muito grande",
    "reports.operation.wrong_sum": "Soma errada",
    "reports.OperationID": "ID da operação",
    "reports.OperationInDisconnectedMode": "Esta operação não está disponível no modo desconectado!",
    "reports.operationtype": "Tipo de operação",
    "reports.OPTION_MASTER": "Mestre de Opções",
    "reports.option_value": "Valor da opção",
    "reports.OrcFIX": "OrcFIX",
    "reports.order": "ordem",
    "reports.Order": "Ordem",
    "reports.Order Book History": "Histórico do livro de ordens",
    "reports.Order Book Report": "Livro de ordens",
    "reports.Order cancel is forbidden by trade session": "Cancelamento de ordem esta proibido pela seção de negoiação",
    "reports.Order cancel request": "O cancelamento da ordem foi requisitado",
    "reports.Order canceled successfully": "A ordem foi cancelada com sucesso",
    "reports.Order cannot be created since order amount is forbidden.": "Ordem não pode ser criada, o valor da quantidade da ordem está incorreto.",
    "reports.Order cannot be created since route is not active.": "Não é possível criar a ordem porque a rota não está ativa",
    "reports.Order cannot be created, because position is in executing": "Não é possível criar a ordem porque a posição está sendo executada",
    "reports.Order cannot be created, because route is not active": "Ordem não pode ser criada, porque a rota não esta ativa",
    "reports.Order cannot be created, because short positions are forbidden. You don't have enough rights.": "Não é possível criar a ordem porque posições de Venda são proibidas. Você não tem direitos suficientes.",
    "reports.Order cannot be created, because short positions are forbidden. You donґt have enough rights.": "Não é possível criar a ordem porque posições de Venda são proibidas. Você não tem direitos suficientes.",
    "reports.Order cannot be created, because short positions are forbidden.Or yor already have Limit/Stop orders for cover all your positions.": "Não é possível criar a ordem porque as posições de Venda são proibidas. Ou você já tem ordens 'Limit'/'Stop' para cobrir todas as suas posições.",
    "reports.Order cannot be placed in non-trading day. Please, check the trading calendar.": "Não é possível colocar a ordem dia de negociação. Por fabor, cheque o calendário de negociação.",
    "reports.Order groupID": "Order groupID",
    "reports.Order History Report": "Histórico de ordens",
    "reports.Order modification is forbidden by trade session": "A modificação de ordens esta proibida pela seção de negociação",
    "reports.Order number": "ID da ordem",
    "reports.Order placing request": "A colocação da ordem foi requisitada",
    "reports.Order price": "Preço da ordem",
    "reports.Order Price": "Preço da ordem",
    "reports.Order received by STP": "Ordem recebida por STP",
    "reports.Order replace request": "A substituição da ordem foi requisitada",
    "reports.Order replacing confirmed": "A substituição de ordem foi confirmada",
    "reports.Order TIF is forbidden by trade session": "As ordens com Validade estão proibidas pela seção de negociação",
    "reports.Order type": "Tipo de ordem",
    "reports.Order type is forbidden by trade session": "O tipo de ordens esta proibido pela seção de negociação",
    "reports.Order was rejected due to invalid price!": "A order foi rejeitada devido ao preço estar inválido!",
    "reports.order_book_account": "Conta",
    "reports.order_book_datetime": "Data",
    "reports.order_book_event": "Evento",
    "reports.order_book_login": "Login",
    "reports.order_book_modified_by": "Modificado pelo usuário",
    "reports.order_book_orderid": "ID da ordem",
    "reports.order_book_price": "Preço",
    "reports.order_book_quantity": "Quantidade",
    "reports.order_book_route": "Rota",
    "reports.order_book_side": "Lado",
    "reports.order_book_stop_price": "Preço 'Stop'",
    "reports.order_book_symbol": "Ativo",
    "reports.order_book_symbol_type": "Tipo de Ativo",
    "reports.order_book_tif": "Validade",
    "reports.order_book_type": "Tipo",
    "reports.orderCreate": "Criação de ordem",
    "reports.orderExecute": "Execução de ordem",
    "reports.OrderExternalConfirm": "Confirmação de ordem externa",
    "reports.orderid": "ID da ordem",
    "reports.orderId": "ID da ordem",
    "reports.OrderId": "ID da ordem",
    "reports.orderModify": "Modificação de ordem",
    "reports.orderMutural": "ordem mútua",
    "reports.orderRemoved": "Remoção de ordem",
    "reports.ORDERS_PANEL_HOT_BTN": "Painel de Ordens ativos (Botoes Rápidos)",
    "reports.ordertype": "Tipo de ordem",
    "reports.Oreder Number": "ID da ordem",
    "reports.originalvalue": "Valor original",
    "reports.OTHER": "Outros",
    "reports.other_account_operations": "Outras operações da conta",
    "reports.Out of session trading is forbidden": "Negociar fora da sessão está proibida",
    "reports.Overnight trading is forbidden for account": "Negociar em 'Overnight' está proibida para esta conta",
    "reports.PAMM account can not be removed during investment period.": "O fundo não pode ser removido durante o período de investimento",
    "reports.PAMM join": "Aderir ao fundo",
    "reports.pamm.OrderByPnL.max_loss": "Perda maior",
    "reports.pamm.OrderByPnL.max_profit": "Lucro maior",
    "reports.pamm.OrderByPnL.min_loss": "Perda menor",
    "reports.pamm.OrderByPnL.min_profit": "Lucro menor",
    "reports.pamm.OrderByPnL.none": "Nenhum",
    "reports.pamm.OrderBySize.max": "Posição maior",
    "reports.pamm.OrderBySize.min": "Posição menor",
    "reports.pamm.OrderByTime.first": "Primeira abertura",
    "reports.pamm.OrderByTime.last": "Ultima abertura",
    "reports.pammState.0": "Novo",
    "reports.pammState.1": "Investimento",
    "reports.pammState.2": "Fim do investimento",
    "reports.pammState.3": "Compensação",
    "reports.pammState.4": "Esperar por investidores",
    "reports.pammState.6": "Início de investimento",
    "reports.pammState.clearing": "Compensação",
    "reports.pammState.confirmed": "confirmado",
    "reports.pammState.end_of_investment": "Fim do investimento",
    "reports.pammState.investment": "Investimento",
    "reports.pammState.Investment": "Investimento",
    "reports.pammState.new": "Novo",
    "reports.pammState.pending": "pendente",
    "reports.pammState.start_of_investment": "Início de investimento",
    "reports.pammState.wait_for_investors": "Esperar por investidores",
    "reports.PAMMTransfer": "Transferência de fundo",
    "reports.Partially filled": "Parcialmente executada",
    "reports.Partially filled close Limit order": "Fechar ordem 'Limit' parcialmente executada",
    "reports.Partially filled Close Limit Order": "Fechar ordem 'Limit' parcialmente executada",
    "reports.Partially filled close Limit order (Mutual)": "Fechar ordem 'Limit' parcialmente executada (Múltipla)",
    "reports.Partially filled close Market order": "Fechar ordem à Mercado parcialmente executada",
    "reports.Partially filled Close Market Order": "Fechar ordem à Mercado parcialmente executada",
    "reports.Partially filled close Market order (Mutual)": "Fechar ordem à Mercado parcialmente executada (Múltipla)",
    "reports.Partially filled close OCO order": "Fechar ordem 'OCO' parcialmente executada",
    "reports.Partially filled close OCO order (Mutual)": "Fechar ordem 'OCO' parcialmente executada (Múltipla)",
    "reports.Partially filled close SL order": "Fechar ordem 'Stop Loss' parcialmente executada",
    "reports.Partially filled close SL order (Mutual)": "Fechar ordem 'Stop Loss' parcialmente executada (Múltipla)",
    "reports.Partially filled close SL tr. stop order": "Fechar ordem 'SL Trailing Stop' parcialmente executada",
    "reports.Partially filled close SL tr. stop order (Mutual)": "Fechar ordem 'SL Trailing Stop' parcialmente executada (Múltipla)",
    "reports.Partially filled close Stop Limit order": "Fechar ordem 'Stop Limit' parcialmente executada",
    "reports.Partially filled close Stop Limit order (Mutual)": "Fechar ordem 'Stop Limit' parcialmente executada (Múltipla)",
    "reports.Partially filled close Stop order": "Fechar ordem 'Stop' parcialmente executada",
    "reports.Partially filled Close Stop Order": "Fechar ordem 'Stop' parcialmente executada",
    "reports.Partially filled close Stop order (Mutual)": "Fechar ordem 'Stop' parcialmente executada (Múltipla)",
    "reports.Partially filled close TP order": "Fechar ordem 'Take Profit' parcialmente executada",
    "reports.Partially filled close TP order (Mutual)": "Fechar ordem 'Take Profit' parcialmente executada (Múltipla)",
    "reports.Partially filled close Tr. stop order": "Fechar ordem 'Trailing Stop' parcialmente executada",
    "reports.Partially filled close Tr. stop order (Mutual)": "Fechar ordem 'Trailing Stop' parcialmente executada (Múltipla)",
    "reports.Partially filled Limit order": "Ordem 'Limit' parcialmente executada",
    "reports.Partially filled Limit order (Mutual)": "Ordem 'Limit' parcialmente executada (Múltipla)",
    "reports.Partially filled Market order": "Ordem à Mercado parcialmente executada",
    "reports.Partially filled Market Order": "Ordem à Mercado parcialmente executada",
    "reports.Partially filled Market order (Mutual)": "Ordem à Mercado parcialmente executada (Múltipla)",
    "reports.Partially filled OCO order": "Ordem 'OCO' parcialmente executada",
    "reports.Partially filled OCO order (Mutual)": "Ordem 'OCO' parcialmente executada (Múltipla)",
    "reports.Partially filled SL order": "Ordem 'Stop Loss' parcialmente executada",
    "reports.Partially filled SL order (Mutual)": "Ordem 'Stop Loss' parcialmente executada (Múltipla)",
    "reports.Partially filled SL tr. stop order": "Ordem 'SL Trailing Stop' parcialmente executada",
    "reports.Partially filled SL tr. stop order (Mutual)": "Ordem 'SL Trailing Stop' parcialmente executada (Múltipla)",
    "reports.Partially filled Stop Limit order": "Ordem 'Stop Limit' parcialmente executada",
    "reports.Partially filled Stop Limit order (Mutual)": "Ordem 'Stop Limit' parcialmente executada (Múltipla)",
    "reports.Partially filled Stop order": "Ordem 'Stop' parcialmente executada",
    "reports.Partially filled Stop order (Mutual)": "Ordem 'Stop' parcialmente executada (Múltipla)",
    "reports.Partially filled TP order": "Ordem 'Take Profit' parcialmente executada",
    "reports.Partially filled TP order (Mutual)": "Ordem 'Take Profit' parcialmente executada (Múltipla)",
    "reports.Partially filled Tr. stop order": "Ordem 'Trailing Stop' parcialmente executada",
    "reports.Partially filled Tr. stop order (Mutual)": "Ordem 'Trailing Stop' parcialmente executada (Múltipla)",
    "reports.Partially filled Traling Stop Order": "Ordem 'Trailing Stop' parcialmente executada",
    "reports.Password required with level - 1": "Senha inválida: A nova senha deve ter pelo menos um caractere em letra maiúscula, um caractere em letra minúscula e um número. Por favor, tente novamente",
    "reports.Password required with level - 2": "Senha inválida: A nova senha deve ter pelo menos um caractere em letra maiúscula, um caractere em letra minúscula, um número e no mínimo 8 caracteres no total. Por favor, tente novamente",
    "reports.Password required with level - 3": "Senha inválida: A nova senha deve ter pelo menos um caractere em letra maiúscula, um caractere em letra minúscula, um número, no mínimo 8 caracteres no total e ao menos um caracter especial. Por favor, tente novamente",
    "reports.PatsystemsFIXFeed": "PatsystemsFIXFeed",
    "reports.Pending": "Pendente",
    "reports.pendingorderscount": "Contagem de ordens pendentes",
    "reports.PeresysFIX": "PeresysFIX",
    "reports.Perfomance By Signal Report": "Desempenho por sinal",
    "reports.Perform": "Desempenho",
    "reports.Performance history report": "Histórico de desempenho",
    "reports.PFixFeed": "PFixFeed",
    "reports.phone": "Telefone",
    "reports.phonepassword": "Senha de telefone",
    "reports.pin": "Pin",
    "reports.pl_unrealized": "L/P não realizado",
    "reports.Placed": "Colocado",
    "reports.Please deposit on your account.": "Por favor, faça um depósito em sua conta",
    "reports.pnl": "L/P",
    "reports.port": "port",
    "reports.posId": "ID da Posição",
    "reports.Position": "Posição",
    "reports.Position close request": "Requisitado fechamento de posições",
    "reports.Position Closed": "Posição fechada",
    "reports.Position Closed (Mutual)": "Posição fechada (Múltipla)",
    "reports.Position Closed (SL)": "Posição fechada (SL)",
    "reports.Position Closed (TP)": "Posição fechada (TP)",
    "reports.Position closed successfully": "Posição foi fechada com sucesso",
    "reports.POSITION MODIFIED": "Posição modificada",
    "reports.Position modify request": "Pedido de modificação da posição",
    "reports.Position number": "ID da Posição",
    "reports.positionOpened": "Posição aberta",
    "reports.Position Opened (Limit Order)": "Posição aberta (ordem 'Limit')",
    "reports.Position Types Ratio By Instrument": "Razão dos tipos de posições por Ativo",
    "reports.Position: {1} closed successfully": "Posição: {1} fechada com sucesso",
    "reports.positionamount": "Quantidade da posição",
    "reports.positionid": "ID da Posição",
    "reports.positionId": "ID da Posição",
    "reports.PositionId": "ID da Posição",
    "reports.Positions History Report": "Histórico de posições",
    "reports.Positions On Chosen Moment Report": "Posições no momento selecionado",
    "reports.POSITIONS_PANEL_HOT_BTN": "Painel de Posições (Botões Rápidos)",
    "reports.positionstatus": "Situação da posição",
    "reports.price": "Preço",
    "reports.Price": "Preço",
    "reports.Price is too close to current Ask price": "",
    "reports.Price is too close to current Bid price": "",
    "reports.priceExchange": "Preço de troca",
    "reports.priceExternal": "Preço externo",
    "reports.product type": "",
    "reports.productType": "Tipo de produto",
    "reports.PROFILE_LANGUAGE": "Perfil do idioma",
    "reports.profit": "Lucro",
    "reports.Projected balance": "Saldo projetado",
    "reports.protocol": "Protocolo",
    "reports.ProTrader": "ProTrader",
    "reports.ProTraderFIX": "ProTraderFIX",
    "reports.Purchase And Sales Report": "Compras e Vendas",
    "reports.purchaseprice": "Exposição",
    "reports.quantity": "Quantidade",
    "reports.QueueMaxSize": "Tamanho máximo da fila",
    "reports.quicktable.noData": "Não há dados para exibir. Por favor, selecione os critérios do relatório necessárias para obter as informações necessárias.",
    "reports.Quote is too old": "A cotação é muito antiga",
    "reports.QuoteDelay(sec)": "Atraso na cotação (seg)",
    "reports.quoteticker": "Ticker de cotação",
    "reports.raor_administrator": "Corretora",
    "reports.raor_approvetime": "Hora da aprovação",
    "reports.raor_balance": "Do saldo da conta",
    "reports.raor_basis": "Comentários",
    "reports.raor_commission_percent": "Comissão em percentual",
    "reports.raor_counterbalance": "Para saldo da conta",
    "reports.raor_countersum": "Para soma da conta",
    "reports.raor_crossprice": "Preço de cruzamento",
    "reports.raor_fromaccount": "Da conta",
    "reports.raor_operationtime": "Hora da operação",
    "reports.raor_operationtype": "Tipo de operação",
    "reports.raor_requestid": "ID da operação",
    "reports.raor_state": "Situação da aprovação",
    "reports.raor_sum": "Da soma da conta",
    "reports.raor_sum_without_commission": "Soma sem comissão",
    "reports.raor_toaccount": "Para conta",
    "reports.rate": "taxa",
    "reports.Rates Report": "Taxas",
    "reports.Realised profit/loss": "Lucro/Prejuízo realizado",
    "reports.Reason for rejection: Trading disabled by risk rules. .": "Motivo da rejeição: negociação desabilitada por regras de risco.",
    "reports.receiveBestBidOfferOnly": "Receber apenas a melhor oferta 'Bid'",
    "reports.recipient": "Destinatário",
    "reports.ReconnectInterval": "Intervalo de reconexão",
    "reports.refreshInterval": "atualizar intervalo",
    "reports.RefreshonLogon": "Atualizar no login",
    "reports.RefreshOnLogon": "Atualizar no login",
    "reports.Refused": "Recusado",
    "reports.Refused Amount": "Quantidade cancelada",
    "reports.Rejected": "Rejeitado",
    "reports.remoteid": "ID remoto",
    "reports.Remove SL for": "Remover SL para",
    "reports.Removed Orders Report": "Ordens removidas",
    "reports.removedtime": "Horário de remoção",
    "reports.removeliquidityrebate": "Remover taxas de liquidez",
    "reports.Replace request received by STP": "Solicitação de substituição recebida por STP",
    "reports.REPORT_RULES": "Visibilidade do relatório",
    "reports.report.currency": "Moeda",
    "reports.report.route": "Rota",
    "reports.reportAccountSummaryReport": "Extrato de conta",
    "reports.reportAllActiveOrdersReport": "Ordens abertas",
    "reports.reportAllNotActiveOrdersReport": "Ordens executadas",
    "reports.reportAllRemovedOrdersReport": "Ordens removidas",
    "reports.reportBalanceByInstrument": "Saldo por Ativo",
    "reports.reportBalanceReport": "Saldo",
    "reports.reportFormalDialogReport": "Diálogos formais",
    "reports.reportInformalDialogReport": "Histórico do bate-papo",
    "reports.reportNewUsersReport": "Novos usuários",
    "reports.reportOpenedPositionReport": "Posições abertas",
    "reports.reportPerfomanceReport": "Desempenho",
    "reports.reportPurchaseAndSalesReport": "Compra e Venda",
    "reports.reportSummaryNewReport": "Relatório resumido",
    "reports.reportSummaryReport": "Relatório resumido de saldo",
    "reports.reportTradeOperationReport": "Operação de negociação",
    "reports.reportTradesReport": "Negociações",
    "reports.reportUsersReport": "Usuários",
    "reports.Request Account Operation Report": "Solicitar relatório de operação de conta",
    "reports.Request is not allowed for this account": "Solicitação não permitida para esta conta",
    "reports.Request not supported": "Solicitação não suportada",
    "reports.RequestAccountOperationReport": "Solicitar relatório de operação de conta",
    "reports.Required Balance": "Saldo requerido",
    "reports.REQUIRED_STRONG_PASSWORDS": "É necessária uma senha forte",
    "reports.ResetOnDisconnect": "Reiniciar ao desconectar",
    "reports.ResetOnLogon": "Reiniciar no login",
    "reports.ResetOnLogout": "Reiniciar no logout",
    "reports.Restated": "",
    "reports.Reuters": "Reuters",
    "reports.Revenue plan": "Plano de receita",
    "reports.REVENUE_PLAN_GENERAL": "Geral",
    "reports.revenue.name": "Nome do plano de receita",
    "reports.revenue.speccrossinstrument": "Especificação de Ativo cruzado",
    "reports.revenue.speccurrency": "Moeda especificada",
    "reports.Reverse": "Inverter",
    "reports.reward_type.capital_percent": "Capital, %",
    "reports.reward_type.fixed": "Fixo",
    "reports.reward_type.fixed_and_percent": "Fixo + crescimento de investimento no período, %",
    "reports.reward_type.no": "Não",
    "reports.reward_type.percent": "Crescimento de investimento no período, %",
    "reports.role": "Função",
    "reports.Rollback": "Inversão",
    "reports.Rollback position": "Posição invertida",
    "reports.Rollback position request": "Requisitada inversão de posição",
    "reports.route": "Rota",
    "reports.Route": "Rota",
    "reports.Route Trading Statistics Report": "Estatísticas das rotas de negociação",
    "reports.Route Visibility": "Visibilidade de rota",
    "reports.Route Visibility Report": "Visibilidade de rota",
    "reports.RouteInstrMap": "mapa de Ativos",
    "reports.routename": "Rota",
    "reports.routes": "Rotas",
    "reports.routes.account": "Conta de 'Feed' conectada",
    "reports.routes.allow_day": "DIA",
    "reports.routes.allow_foc": "Tudo ou Nada",
    "reports.routes.allow_gtc": "Até Cancelar",
    "reports.routes.allow_ioc": "Tudo ou Parcial",
    "reports.routes.allow_market": "Mercado",
    "reports.routes.allow_moc": "Permitir MOC",
    "reports.routes.allow_moo": "Permitir MOO",
    "reports.routes.allow_stop": "Stop",
    "reports.routes.allow_stoplimit": "Limit",
    "reports.routes.allow_stoptolimit": "Stop limit",
    "reports.routes.route_comment": "Comentário",
    "reports.routes.route_external_feed": "Provedor de liquidez",
    "reports.routes.route_isactive": "Esta Ativa",
    "reports.routes.route_isinfo": "Informação Is",
    "reports.routes.route_istrade": "Esta negociando",
    "reports.routes.route_login": "Login",
    "reports.routes.route_name": "Nome",
    "reports.routes.route_password": "Senha",
    "reports.routes.route_saveQuotes": "Salvar histórico separado",
    "reports.routes.route_status": "Situação",
    "reports.ROUTESVIS_RULES": "Visibilidade de rotas",
    "reports.RssNews": "RSSNews",
    "reports.RTS": "RTS",
    "reports.rules.error.restriction.max": "Número deve ser menor",
    "reports.rules.error.restriction.min": "Número deve ser maior",
    "reports.SAVED_ORDERS": "Ordens salvas",
    "reports.saveLogs": "Logs de salvamentos",
    "reports.saveNews": "Salvar notícias",
    "reports.Saxo Bank": "Saxo Bank",
    "reports.SCALPER": "Scalper",
    "reports.Scheduler Log Report": "Relatório do log de planejamento",
    "reports.scheduler.accountoperation_archive": "Arquivo das operações da conta",
    "reports.scheduler.dailyreport": "Enviar relatório",
    "reports.scheduler.dividentsservice": "Executar dividendos na posições",
    "reports.scheduler.lastexcecuted": "Executado em",
    "reports.scheduler.modifyRuleTask": "Modificando tarefa",
    "reports.scheduler.movepositiontask": "Tarefa de mover posição",
    "reports.scheduler.openclosetradingfor": "Abrir/Fechar negociação",
    "reports.scheduler.opentradingfor": "Abrir negociação",
    "reports.scheduler.parameter.backup": "Nível de 'Backup'",
    "reports.scheduler.parameter.calculateByClosePrice": "Usar preço de fechamento atual",
    "reports.scheduler.parameter.calculateStrategy": "Calculando tipo",
    "reports.scheduler.parameter.coeff1": "Parte 1",
    "reports.scheduler.parameter.coeff2": "Parte 2",
    "reports.scheduler.parameter.comment": "Comentário de operação da conta",
    "reports.scheduler.parameter.counteraccount": "Conta de contrapartida",
    "reports.scheduler.parameter.days_before_exp": "Dias antes do vencimento",
    "reports.scheduler.parameter.divident": "Quantidade de dividendos",
    "reports.scheduler.parameter.dividentmode": "Tipo de dividendos",
    "reports.scheduler.parameter.email": "E-mails (separados por vírgula)",
    "reports.scheduler.parameter.inheritUserGroups": "Incluir subgrupos",
    "reports.scheduler.parameter.instrtype": "Grupo do Ativo",
    "reports.scheduler.parameter.instrument": "Ativo",
    "reports.scheduler.parameter.isopen": "Abrir/Fechar negociação",
    "reports.scheduler.parameter.isupdateall": "Atualizar todos os Ativos em grupo",
    "reports.scheduler.parameter.margincoeff": "Coeficiente de margem (baseado em tempo)",
    "reports.scheduler.parameter.max": "Máximo (%)",
    "reports.scheduler.parameter.message": "Mensagem",
    "reports.scheduler.parameter.min": "Mínimo (%)",
    "reports.scheduler.parameter.operations": "Tipo de operação",
    "reports.scheduler.parameter.owner": "Objeto",
    "reports.scheduler.parameter.ownerType": "Tipo de objeto",
    "reports.scheduler.parameter.putinaleerts": "Colocar em alertas",
    "reports.scheduler.parameter.reconciliation.ClientId": "ID do cliente para reconciliação",
    "reports.scheduler.parameter.reconciliation.Direction": "Direção",
    "reports.scheduler.parameter.reconciliation.FromDate": "Iniciar reconciliação na data",
    "reports.scheduler.parameter.reconciliation.Route": "Rota",
    "reports.scheduler.parameter.reconciliation.SendItemName": "Enviando nome de arquivo",
    "reports.scheduler.parameter.reconciliation.Storage": "Tipo de armazenamento",
    "reports.scheduler.parameter.reconciliation.Transport": "Tipo de transporte",
    "reports.scheduler.parameter.reconciliation.transportDestination": "Destino",
    "reports.scheduler.parameter.reconciliation.transportLogin": "Login de autenticação",
    "reports.scheduler.parameter.reconciliation.transportPassword": "Senha de autenticação",
    "reports.scheduler.parameter.reconciliation.Type": "Tipo de reconciliação",
    "reports.scheduler.parameter.reports": "Selecionar relatório",
    "reports.scheduler.parameter.revenueplan": "Selecionar plano de receita",
    "reports.scheduler.parameter.routes": "Cada rota ativa deve ser",
    "reports.scheduler.parameter.routesName": "Incluir rotas",
    "reports.scheduler.parameter.rule": "Regra",
    "reports.scheduler.parameter.ruleType": "Tipo de regra",
    "reports.scheduler.parameter.ruleView": "Valor",
    "reports.scheduler.parameter.sum": "Quantidade na moeda do sistema",
    "reports.scheduler.parameter.swapCoefKey": "Coeficiente de swap",
    "reports.scheduler.parameter.userGroup": "Grupo do usuário",
    "reports.scheduler.parameter.usergroups": "Selecionar grupo de usuários",
    "reports.scheduler.removedo": "Executar serviço diário de remoção de ordem",
    "reports.scheduler.rolloverservice": "Executar 'Roll-Over' de futuros",
    "reports.scheduler.runinterestservice": "Executar serviço de juros em saldo",
    "reports.scheduler.runsignalsubscribtion": "Executar cobrança diária de comissão para o sinal",
    "reports.scheduler.runswaptask": "Executar 'Swap'",
    "reports.scheduler.splitinstrument": "Divisão do Ativo",
    "reports.scheduler.type.accountoperation": "Operação da conta",
    "reports.scheduler.type.backup": "Criar 'BackUp'",
    "reports.scheduler.type.beginbalance": "Saldo inicial",
    "reports.scheduler.type.broadcastmessage": "Mensagem de transmissão on-line",
    "reports.scheduler.type.changemargincoeff": "Coeficiente de margem (baseado em tempo)",
    "reports.scheduler.type.changerevenue": "Mudar plano de receita",
    "reports.scheduler.type.closeoptions": "Ocultar Ativos expirados",
    "reports.scheduler.type.deletedemousers": "Excluir usuários demo expr.",
    "reports.scheduler.type.deletequotes": "Excluir cotações antigas",
    "reports.scheduler.type.fixswapplan": "Calcular plano de 'Swap' fixo",
    "reports.scheduler.type.futuresrollover": "Executar 'Roll-Over' de futuros",
    "reports.scheduler.type.insertlastquotestask": "Inserir últimas cotações",
    "reports.scheduler.type.lockbybroker": "Bloqueado pela corretora",
    "reports.scheduler.type.message": "Mensagem",
    "reports.scheduler.type.modifyobject": "Modificar objeto",
    "reports.scheduler.type.pamm": "Tarefa de fundos",
    "reports.scheduler.type.pendingpayment": "Pagamento pendente",
    "reports.scheduler.type.reconciliation": "Tarefa de reconciliação",
    "reports.scheduler.type.reconnect_routes": "Iniciar/parar rotas ativas",
    "reports.scheduler.type.subscribe": "Obter pagamento de inscrição",
    "reports.scheduler.type.unknown": "Tipo desconhecido",
    "reports.scheduler.type.updatespikefilter": "Atualizar filtro 'Spike'",
    "reports.scheduler.type.updateuserstradinginfo": "Atualizar informação de estatística de negociação do usuário",
    "reports.ScreenLogEvents": "Eventos de log de tela",
    "reports.ScreenLogShowHeartBeats": "Log da tela exibe 'HeartBeats'",
    "reports.ScreenLogShowIncoming": "Log de tela exibe entradas",
    "reports.ScreenLogShowOutgoing": "Log de tela exibe saídas",
    "reports.Script": "Script",
    "reports.SDK": "SDK",
    "reports.seconds": "segundos",
    "reports.SecuritiesTransferTax": "Taxa de transferência de valores",
    "reports.Self trading is not allowed.": "Negociação automática não está permitida",
    "reports.Sell": "Venda",
    "reports.sell trade amount": "Quantidade de negociações de Venda",
    "reports.sell trades": "Negociações de Venda",
    "reports.Send from": "Enviado de",
    "reports.Send_Rej": "Rejeitar",
    "reports.sender": "Remetente",
    "reports.SenderCompID": "ID do computador do remetente",
    "reports.senderip": "IP do remetente",
    "reports.SendResetSeqNumFlag": "Enviar sinalizador de reinicio da sequência de numeração",
    "reports.sendTag200": "enviar Tag200",
    "reports.server": "servidor",
    "reports.server_url": "URL do servidor",
    "reports.SERVER.AUTO.RECONNECT": "RECONECTAR SERVIDOR AUTOMATICAMENTE",
    "reports.SERVER.POLLING.INTERVAL": "INTERVALO DE VERIFICAÇÂO DO SERVIDOR",
    "reports.serviceName": "Nome do serviço",
    "reports.sessionid": "ID da sessão",
    "reports.sessiontype": "Tipo de sessão",
    "reports.SETTINGS_FILTER": "Filtros",
    "reports.SETTINGS_GENERAL": "Geral",
    "reports.SETTINGS_LOCKING": "Bloqueando",
    "reports.SETTINGS_QUOTE_CACHE_SIZE": "Configuração de tamanho de cache de cotação",
    "reports.SETTINGS_QUOTE_SAVE": "Configuração de salvamento de cotação",
    "reports.SETTINGS_QUOTES": "Cotações",
    "reports.SETTINGS_REGISTRATION": "Registro",
    "reports.SETTINGS_TRADING": "Negociação",
    "reports.SettleDate": "Data de vencimento",
    "reports.SettleDateForward": "Valor da data",
    "reports.SettlementFee": "Taxa de liquidação",
    "reports.Sharia Operations Report": "Operações Sharia",
    "reports.signedrequest": "Solicitação de sinal",
    "reports.sizeForQuoteSubscription": "tamanho da subscrição da cotação",
    "reports.SL": "Stop Loss",
    "reports.SL accepted by broker": "Ordem SL foi aceita pela corretora",
    "reports.SL accepted by STP": "Ordem SL foi aceita por STP",
    "reports.SL order accepted": "Ordem SL foi aceita",
    "reports.SL order cancel request": "Requisitada cancelamento da ordem SL",
    "reports.SL order canceled": "Ordem SL foi cancelada",
    "reports.SL order created": "Ordem SL foi criada",
    "reports.SL Order Created": "Ordem SL foi criada",
    "reports.SL order created is off market": "",
    "reports.SL order created is unplaced": "",
    "reports.SL Order Modified": "Ordem SL foi modificada",
    "reports.SL order partially reject": "Ordem SL foi parcialmente cancelada",
    "reports.SL order placing request": "Requisitada colocação de ordem SL",
    "reports.SL order received by broker": "Ordem SL foi recebida pela corretora",
    "reports.SL order rejected": "Ordem SL foi rejeitada",
    "reports.SL Order Removed": "Ordem SL foi removida",
    "reports.SL order replace request": "Requisitada substituição de ordem SL",
    "reports.SL order replaced": "Ordem SL foi substituída",
    "reports.SL price": "Preço SL",
    "reports.SL tr. stop": "'Stop loss' 'Trailing Stop'",
    "reports.SL tr. stop accepted by broker": "Ordem 'Trailng Stop' SL foi aceita pela corretora",
    "reports.SL tr. stop accepted by STP": "Ordem 'Trailng Stop' SL foi aceita por STP",
    "reports.SL tr. stop order accepted": "Ordem 'Trailng Stop' SL foi aceita",
    "reports.SL tr. stop order canceled": "Ordem 'Trailng Stop' SL foi cancelada",
    "reports.SL tr. stop order created": "Ordem 'Trailng Stop' SL foi criada",
    "reports.SL tr. stop order created is off market": "",
    "reports.SL tr. stop order created is unplaced": "",
    "reports.SL tr. stop order partially reject": "Ordem 'Trailng Stop' SL foi parcialmente canceçada",
    "reports.SL tr. stop order received by broker": "Ordem 'Trailng Stop' SL foi recebida pela corretora",
    "reports.SL tr. stop order rejected": "Ordem 'Trailng Stop' SL foi rejeitada",
    "reports.SL tr. stop order replaced": "Ordem 'Trailng Stop' SL foi substituída",
    "reports.SmartRoute(aggregator)": "SmartRoute (agregador)",
    "reports.Sms generation error": "Erro na geração do SMS",
    "reports.SMTP server required a secure connection, or identity of the client has not been established.": "Um servidor de SMTP necessário para uma conexão segura, ou a identidade do cliente não foi estabelecida.",
    "reports.SOAP API methods are not available for execution for this user": "Os métodos da API SOAP não estão disponíveis para execução neste usuário",
    "reports.SocketConnectHost": "Socket connectHost",
    "reports.SocketConnectPort": "'Socket' conecta a porta",
    "reports.SocketKeyStore": "Armazenamento da chave de 'Socket'",
    "reports.SocketKeyStorePassword": "Senha de armazenamento da chave de 'Socket'",
    "reports.SocketUseSSL": "'Socket' usa SSL",
    "reports.SplitOrders": "Dividir ordens",
    "reports.spread": "Spread",
    "reports.Spread Amount Report": "Quantidades de 'Spread'",
    "reports.spreadpnl": "L/P de 'Spread'",
    "reports.Start receiving data from server...": "Começar a receber dados do servidor ...",
    "reports.StartDay": "Dia de início",
    "reports.StartTime": "Hora de início",
    "reports.STATIONS_RULES": "Estações",
    "reports.Statistic Report": "Estatística",
    "reports.STATUS_BLOCKED": "Bloqueado",
    "reports.stock_req": "Ordem Ação requerida",
    "reports.stock_value": "Valor da Ação",
    "reports.StockOperation": "Operação",
    "reports.stocks_liquidity": "Liquidação de Ações",
    "reports.Stop accepted by broker": "Ordem 'Stop' foi aceita pela corretora",
    "reports.Stop accepted by STP": "Ordem 'Stop' foi aceita pelo STP",
    "reports.Stop Limit accepted by broker": "Ordem 'Stop Limit' foi aceita pela corretora",
    "reports.Stop Limit accepted by STP": "Ordem 'Stop Limit' foi aceita pelo STP",
    "reports.Stop Limit order accepted": "Ordem 'Stop Limit' foi aceita",
    "reports.Stop Limit order activated": "",
    "reports.Stop limit order cancel request": "Requisitado o cancelamento da ordem 'Stop Limit'",
    "reports.Stop limit order canceled": "Ordem 'Stop Limit' foi cancelada",
    "reports.Stop Limit order canceled": "Ordem 'Stop Limit' foi cancelada",
    "reports.Stop Limit order created": "Ordem 'Stop Limit' foi criada",
    "reports.Stop Limit Order Created": "Ordem 'Stop Limit' foi criada",
    "reports.Stop Limit order created is off market": "",
    "reports.Stop Limit order created is unplaced": "",
    "reports.Stop limit order is awaiting market": "Ordens 'Stop Limit' aguardando mercado",
    "reports.Stop Limit Order Modified": "Ordem 'Stop Limit' foi modificada",
    "reports.Stop Limit order partially reject": "Ordem 'Stop Limit' foi parcialmente cancelada",
    "reports.Stop limit order placing request": "Requisitada colocação de ordem 'Stop Limit'",
    "reports.Stop Limit order received by broker": "Ordem 'Stop Limit' foi recebida pla corretora",
    "reports.Stop Limit order rejected": "Ordem 'Stop Limit' foi rejeitada",
    "reports.Stop Limit Order Removed": "Ordem 'Stop Limit' foi removida",
    "reports.Stop limit order replace request": "Requisitada substituição da ordem 'Stop Limit'",
    "reports.Stop limit order replaced": "Odem 'Stop Limit' modificado",
    "reports.Stop Limit order replaced": "Ordem 'Stop Limit' foi substituída",
    "reports.Stop Limit order restated": "",
    "reports.Stop loss order canceled": "Ordem de stop loss cancelada",
    "reports.Stop Loss price is too big": "Preço SL inválido para a ordem",
    "reports.Stop order accepted": "Ordem 'Stop' foi aceita",
    "reports.Stop order activated": "",
    "reports.Stop order cancel request": "Requisitada cancelamento da ordem 'Stop'",
    "reports.Stop order canceled": "Ordem 'Stop' foi cancelada",
    "reports.Stop order created": "Ordem 'Stop' foi criada",
    "reports.Stop Order Created": "Ordem 'stop' foi criada",
    "reports.Stop order created is off market": "",
    "reports.Stop order created is unplaced": "",
    "reports.Stop order is awaiting market": "Ordens 'Stop' aguardando mercado",
    "reports.Stop Order Modified": "Ordem 'Stop' foi modificada",
    "reports.Stop order partially reject": "Ordem 'Stop' foi parcialmente cancelada",
    "reports.Stop order placing request": "Requisitada colocação de ordem 'Stop'",
    "reports.Stop order received by broker": "Ordem 'Stop' foi recebida pela corretora",
    "reports.Stop order rejected": "Ordem 'Stop' foi rejeitada",
    "reports.Stop Order Removed": "Ordem 'Stop' foi removida",
    "reports.Stop order replace request": "Requisitada substituição de ordem 'Stop'",
    "reports.Stop order replaced": "Ordem 'Stop' substituída",
    "reports.Stop order restated": "",
    "reports.Stop price": "Preço 'Stop'",
    "reports.stoploss": "SL",
    "reports.StopOutType": "Tipo 'Stop Out'",
    "reports.stopprice": "Preço 'Stop'",
    "reports.STP_Route_Change": "Configurar nome original da rota na ordem",
    "reports.Strike Price": "Preço de 'Strike'",
    "reports.submitted": "Submetido",
    "reports.Subscribe": "Assinar",
    "reports.SUBSCRIBE_RULES": "Planos de assinaturas",
    "reports.success": "Sucesso",
    "reports.Successful": "Bem sucedido",
    "reports.sum": "Soma",
    "reports.Sum": "Soma",
    "reports.Summary Report": "Relatório resumido",
    "reports.SunGard": "SunGard",
    "reports.SUSPEND_MARGINCALL_ON_CLOSE_MARKET": "Suspender assistente 'Margin Call' para mercados fechados",
    "reports.swap": "Swap",
    "reports.swapbuy": "'Swap' de Compra",
    "reports.swapplan": "Plano 'Swap'",
    "reports.swaps": "Swaps",
    "reports.swaps_in": "'Swap' de entrada",
    "reports.swaps_out": "'Swap' de saída",
    "reports.swapsell": "'Swap' de Venda",
    "reports.symbol": "Ativo",
    "reports.Symbol": "Ativo",
    "reports.symbol_type": "Tipo de Ativo",
    "reports.System": "Sistema",
    "reports.system_currency": "Moeda do sistema",
    "reports.systemMonitor.instruments": "Ativos",
    "reports.systemMonitor.onlineConnections": "Conexões on-line",
    "reports.systemMonitor.positions": "Posições abertas",
    "reports.systemMonitor.regusers": "Usuários registrados",
    "reports.systemMonitor.runningRoutes": "Rotas iniciadas a partir de rotas ativas",
    "reports.systemMonitor.totalQueueSize": "Filas em serviços",
    "reports.TABLE_ALERT": "Tabela de alertas",
    "reports.tablename": "Nome da tabela",
    "reports.TableName": "Nome da tabela",
    "reports.Take profit order canceled": "Ordem TP foi substituída",
    "reports.Take Profit price is too big": "Preço de 'Take Profit' é muito grande",
    "reports.takeprofit": "TP",
    "reports.TargetCompID": "ID do computador alvo",
    "reports.task.comment": "Comentário",
    "reports.task.day": "Dia",
    "reports.task.hour": "Hora",
    "reports.task.minute": "Minuto",
    "reports.task.month": "Mês",
    "reports.task.timezone": "Deslocamento de fuso horário",
    "reports.task.usedst": "DST de uso",
    "reports.task.year": "Ano",
    "reports.TempFilePath": "Caminho de arquivo temporário",
    "reports.Tenfore": "Tenfore",
    "reports.terminal": "Terminal",
    "reports.Terminal": "Terminal",
    "reports.TERMINAL_API": "API",
    "reports.TERMINAL_COMMUNITY": "Comunidade",
    "reports.TERMINAL_FIX": "Conexão FIX",
    "reports.TERMINAL_HTML": "HTML",
    "reports.TERMINAL_JAVA": "Java",
    "reports.TERMINAL_MOBILE": "Celular",
    "reports.TERMINAL_NET": ".Net",
    "reports.TERMINAL_POCKETPC": "Pocket PC",
    "reports.TERMINAL_WEB": "WebStation",
    "reports.terminaltype": "Tipo de terminal",
    "reports.TFDS Quote Receiver": "Receptor de cotação TFDS",
    "reports.tif": "Validade",
    "reports.TIF": "Validade",
    "reports.time": "Tempo",
    "reports.Time": "Data/Hora",
    "reports.to": "Até",
    "reports.toDate": "Até",
    "reports.toDateConnAttempt": "Até",
    "reports.toDatePwdChange": "Até (passo)",
    "reports.tooLargeData": "Dados do relatório são muito grandes! Exibe {1} de {2} linhas",
    "reports.total": "Total",
    "reports.total traded amount": "Valor total negociado",
    "reports.totalfee": "Total de taxas",
    "reports.TP": "Take Profit",
    "reports.TP accepted by broker": "Ordem TP foi aceita pela corretora",
    "reports.TP accepted by STP": "Ordem TP foi aceita pelo STP",
    "reports.TP order accepted": "Ordem TP foi aceita",
    "reports.TP order cancel request": "Ordem TP foi parcialmente cancelada",
    "reports.TP order canceled": "Ordem TP foi cancelada",
    "reports.TP order created": "Ordem TP foi criada",
    "reports.TP Order Created": "Ordem TP foi criada",
    "reports.TP order created is off market": "",
    "reports.TP order created is unplaced": "",
    "reports.TP Order Modified": "Ordem TP foi modificada",
    "reports.TP order partially reject": "Ordem TP foi parcialmente cancelada",
    "reports.TP order placing request": "Requisitada colocação de Ordem TP",
    "reports.TP order received by broker": "Ordem TP foi recebida por corretor",
    "reports.TP order rejected": "Ordem TP foi rejeitada",
    "reports.TP Order Removed": "Ordem TP foi removida",
    "reports.TP order replace request": "Requisitada substituição de ordem TP",
    "reports.TP order replaced": "Ordem TP foi substituída",
    "reports.Tr stop loss order cancel request": "Ordem 'Trailing Stop Loss' foi parcialmente cancelada",
    "reports.Tr stop loss order placing request": "Ordem 'Trailing Stop Loss' foi parcialmente cancelada",
    "reports.Tr stop loss order replace request": "Requisitada colocação de Ordem 'Trailing Stop Loss'",
    "reports.tr. stop": "",
    "reports.Tr. stop accepted by broker": "Ordem 'Trailing Stop' foi aceita pela corretora",
    "reports.Tr. stop accepted by STP": "Ordem 'Trailing Stop' foi aceita pelo STP",
    "reports.Tr. stop is too big": "Requisitada substituição de ordem 'Trailing Stop Loss'",
    "reports.Tr. stop loss order accepted": "Ordem 'Trailing Stop Loss' foi aceita",
    "reports.Tr. stop loss order canceled": "O 'Trailing Stop' da ordem foi cancelado",
    "reports.Tr. stop loss order created": "Ordem 'Trailing Stop Loss' foi criada",
    "reports.Tr. stop loss order created is off market": "",
    "reports.Tr. stop loss order created is unplaced": "",
    "reports.Tr. stop loss order replaced": "Ordem 'Trailing Stop Loss' foi modificada",
    "reports.Tr. stop order accepted": "Ordem 'Trailing Stop' foi aceita",
    "reports.Tr. stop order activated": "",
    "reports.Tr. stop order canceled": "Ordem 'Trailing Stop' foi cancelada",
    "reports.Tr. stop order created": "Ordem 'Trailing Stop' foi criada",
    "reports.Tr. stop order created is off market": "",
    "reports.Tr. stop order created is unplaced": "",
    "reports.Tr. stop order is awaiting market": "Ordens 'Trailing Stop' aguardando mercado",
    "reports.Tr. stop order partially reject": "Ordem 'Trailing Stop Loss' foi parcialmente cancelada",
    "reports.Tr. stop order received by broker": "Ordem 'Trailing Stop' foi recebida pela corretora",
    "reports.Tr. stop order rejected": "Ordem 'Trailing Stop' foi rejeitada",
    "reports.Tr. stop order replaced": "Ordem 'Trailing Stop' foi substituída",
    "reports.Tr. stop order restated": "",
    "reports.Tr.SL Order Removed": "Ordem 'Trailing Stop' foi removida",
    "reports.Trade executed": "Negociação executada",
    "reports.Trade executed (Mutual)": "Negociação executada (Múltipla)",
    "reports.Trade executed (SL)": "Negociação executada (SL)",
    "reports.Trade Operations Report": "Operações de negociações",
    "reports.Trade Periods By Signal Report": "Períodos de negociação por sinal",
    "reports.trade.BeginString": "Sequência de início de negociação",
    "reports.trade.CheckCompID": "ID do computador de verificação de negociação",
    "reports.trade.CheckLatency": "Verificação de latência de negociação",
    "reports.trade.ClosedResendInterval": "Negociações fechadas intervalo de reenvio",
    "reports.trade.ConnectionType": "Tipo de conexão de negociação",
    "reports.trade.ContinueInitializationOnError": "Continuar inicialização de negociação no erro",
    "reports.trade.DataDictionary": "Dicionário de dados de negociação",
    "reports.trade.EndDay": "Dia final de negociação",
    "reports.trade.EndTime": "Hora de encerramento da negociação",
    "reports.trade.entityId": "ID da entidade de negociação",
    "reports.trade.FileIncludeMilliseconds": "Arquivos de negociação incluem milissegundos",
    "reports.trade.FileIncludeTimeStampForMessages": "Arquivos de negociação incluem registro de horário das mensagens",
    "reports.trade.FileLogHeartbeats": "Arquivo de log de 'HeartBeats' da negociação",
    "reports.trade.fxintegrate.cert.host.verify": "Verificar o servidor de negociação 'FX' integrado",
    "reports.trade.fxintegrate.cert.root.ca.file": "Arquivo raiz 'ca' da certificação de negociação 'FX' integrado",
    "reports.trade.fxintegrate.host": "Servidor de negociação 'FX' integrado",
    "reports.trade.fxintegrate.logging.file": "Arquivo de registro de negociação 'FX' integrado",
    "reports.trade.fxintegrate.logging.maxfilecount": "Contagem máxima de arquivo de Log de negociação 'FX' integrado",
    "reports.trade.fxintegrate.logging.output": "Saída de registro de negociação 'FX' integrado",
    "reports.trade.fxintegrate.logging.priority": "Prioridade de registrode  negociação 'FX' integrado",
    "reports.trade.fxintegrate.port": "Porta de negociação 'FX' integrado",
    "reports.trade.fxintegrate.protocol": "Protocolo de negociação 'FX' integrado",
    "reports.trade.fxintegrate.usessl": "SSL de uso de negociação 'FX' integrado",
    "reports.trade.HeartBtInt": "Negociação 'HeartBeat' Integrado",
    "reports.trade.JdbcDriver": "Driver de negociação Jdbc",
    "reports.trade.JdbcPassword": "Senha de Jdbc de negociação",
    "reports.trade.JdbcURL": "URL Jdbc de negociação",
    "reports.trade.JdbcUser": "usuário de negociação Jdbc",
    "reports.trade.keystoreAlias": "Alias de armazenamento de chave de negociação",
    "reports.trade.keystoreFile": "Arquivo de armazenamento de chave de negociação",
    "reports.trade.LogFileMaxSize": "Tamanho máximo de arquivo de log de negociação",
    "reports.trade.login": "Login de negociação",
    "reports.trade.MaxLogsBackups": "'Backups' de logs de negociação máximos",
    "reports.trade.MillisecondsinTimeStamp": "Milissegundos no registro de tempo",
    "reports.trade.MillisecondsInTimeStamp": "Milissegundos da negociação no registro de tempo",
    "reports.trade.password": "Senha de negociação",
    "reports.trade.ReconnectInterval": "Intervalo de reconexão de negociação",
    "reports.trade.RefreshonLogon": "Atualizar no login",
    "reports.trade.RefreshOnLogon": "Atualização de negociação no login",
    "reports.trade.ResetOnDisconnect": "Reiniciar negociação ao desconectar",
    "reports.trade.ResetOnLogon": "Reiniciar negociação no login",
    "reports.trade.ResetOnLogout": "reinicialização de negociação no logout",
    "reports.trade.saveLogs": "Salvar 'Logs' negociação",
    "reports.trade.ScreenLogEvents": "Eventos de 'Log' de tela de negociação",
    "reports.trade.ScreenLogShowHeartBeats": "'Log' da tela de negociação exibe 'HeartBeats'",
    "reports.trade.ScreenLogShowIncoming": "'Log' de tela de negociação exibe entrada",
    "reports.trade.ScreenLogShowOutgoing": "'Log' de tela de negociação exibe saída",
    "reports.trade.SenderCompID": "ID do computador do remetente da negociação",
    "reports.trade.SendResetSeqNumFlag": "Negociação enviar sinalizador de reinicialização sa sequência de numeração",
    "reports.trade.SocketConnectHost": "Servidor de conexão do 'Socket' de negociação",
    "reports.trade.SocketConnectPort": "Porta de conexão do 'Socket' de negociação",
    "reports.trade.SocketKeyStore": "Chave de armazenamento do 'Socket' de negociação",
    "reports.trade.SocketKeyStorePassword": "Senha de armazenamento da chave do 'Socket' de negociação",
    "reports.trade.SocketUseSSL": "SSL de uso do 'Socket' de negociação",
    "reports.trade.StartDay": "Dia de início da negociação",
    "reports.trade.StartTime": "Hora de início da negociação",
    "reports.trade.TargetCompID": "ID do computador destino de negociação",
    "reports.trade.UseDataDictionary": "Negociação usa dicionário de dados",
    "reports.trade.ValidateFieldsHaveValues": "Campos de validação de negociação têm valores",
    "reports.trade.ValidateFieldsOutOfOrder": "Validar campos de negociação fora de serviço",
    "reports.trade.ValidateUserDefinedFields": "Validar negociação campos definidos pelo usuário",
    "reports.tradeamount": "Quantidade de negociação",
    "reports.tradeid": "ID da negociação",
    "reports.tradePassword": "Senha de negociação",
    "reports.trader": "Operador",
    "reports.Trades by Signal": "Negociações por sinal",
    "reports.Trades Report": "Negociações",
    "reports.Trades report by instrument": "Negociações por Ativo",
    "reports.trades_bought": "Negociações compradas",
    "reports.trades_sold": "Negociações vendidas",
    "reports.tradevolume": "Volume de negociação",
    "reports.trading": "Negociação",
    "reports.Trading": "Negociação",
    "reports.Trading disabled by risk rules": "Negociação desativada pelas regras de risco",
    "reports.TRADING_IDEA_CARD": "Carteira do Trading idea",
    "reports.trading_in": "Negociação de entrada",
    "reports.trading_out": "Negociação de saída",
    "reports.TRADING_RULES_ACC": "Regras de negociação (conta)",
    "reports.TRADING_RUSLES": "Regras de negociação",
    "reports.TRADINGSYSTEMLIST": "Lista de Sistemas de Negociação",
    "reports.Traiding out of Main session is forbidden for this account": "Negociações fora da sessão principal estão proibidas nesta conta",
    "reports.Trailing stop order cancel request": "Requisitado cancelamento da Ordem 'Trailing Stop'",
    "reports.Trailing Stop Order Created": "Ordem 'Trailing Stop' foi criada",
    "reports.Trailing Stop Order Modified": "Ordem 'Trailing Stop' foi modificada",
    "reports.Trailing stop order placing request": "Ordem 'Trailing Stop' foi colocada",
    "reports.Trailing Stop Order Removed": "Ordem 'Trailing Stop' foi removida",
    "reports.Trailing stop order replace request": "Resuisitado substituição da ordem 'Trailing Stop'",
    "reports.TrailingStop": "Trailing stop",
    "reports.Transfer": "Tranferência",
    "reports.TransferFee": "Taxa de transferência",
    "reports.True": "Verdadeiro",
    "reports.type": "Tipo",
    "reports.Type": "Tipo",
    "reports.Types": "Tipos",
    "reports.UBSFIXFeed": "UBSFIXFeed",
    "reports.Unable to cancel the order since route is not active.": "Não é possível cancelar a ordem, uma vez que a rota não esta ativa.",
    "reports.Unblocking": "Desbloqueado",
    "reports.Unplaced": "",
    "reports.unrealized p/l": "L/P não realizado",
    "reports.unrealizedpnl": "L/P não realizado",
    "reports.unsettled_cash": "Dinheiro instável",
    "reports.updatedtime": "Horário atualizado",
    "reports.Updater": "Atualizador",
    "reports.URL": "URL",
    "reports.usage": "Utilização",
    "reports.USE_OPEN_PRICE_IN_MARGIN": "Usar preço de abertura",
    "reports.UseDataDictionary": "Usar dicionário de dados",
    "reports.usePrevQuotedOrders": "usar ordens previamente cotadas",
    "reports.UsePriority": "Usar prioridade",
    "reports.user": "Usuário",
    "reports.User": "Usuário",
    "reports.User Authorization Error": "Erro de autorização de usuário",
    "reports.user group": "Grupo do usuário",
    "reports.User Previledges Report": "Privilégios de usuário",
    "reports.user.acc_4_signals": "Conta para sinais",
    "reports.user.address": "Endereço",
    "reports.user.amount": "Quantidade",
    "reports.user.amountType": "Tipo de quantidade",
    "reports.user.birthday": "Data de nascimento",
    "reports.user.certificate": "Certificado de usuário",
    "reports.user.chatOnEmail": "Enviar bate papo para o e-mail",
    "reports.user.city": "Cidade",
    "reports.user.comment": "Comentário",
    "reports.user.commission_type": "Comissão por",
    "reports.user.email": "Email",
    "reports.user.extaccount": "Conta externa",
    "reports.user.firstname": "Nome",
    "reports.user.gender": "Sexo",
    "reports.user.group": "Grupo",
    "reports.user.how_handle": "Como manusear os sinais",
    "reports.user.introducebroker": "Apresentando conta do corretor",
    "reports.user.language": "Idioma",
    "reports.user.lastname": "Sobrenome",
    "reports.user.linkedUsers": "Usuários conectados",
    "reports.user.login": "Login",
    "reports.user.middlename": "Nome do meio",
    "reports.user.password": "Nova senha",
    "reports.user.phonenumber": "Número do telefone",
    "reports.user.phonepassword": "Senha de telefone",
    "reports.user.photo": "Foto",
    "reports.user.pin": "Código 'PIN'",
    "reports.user.showInSearch": "Exibir na busca",
    "reports.user.showMyContacts": "Exibir meus contatos",
    "reports.user.signalsOnEmail": "Sinais por e-mail",
    "reports.user.state": "Estado",
    "reports.user.status": "Situação",
    "reports.user.subscribeplan": "Assinar plano",
    "reports.user.website": "Site",
    "reports.user.zipcode": "CEP",
    "reports.usergroup.group": "Super Grupo",
    "reports.usergroup.name": "Grupo do usuário",
    "reports.USERGROUPSVIS_RULES": "Visibilidade de grupos de usuários",
    "reports.userlogin": "Login do usuário",
    "reports.UserLogin": "Login do usuário",
    "reports.username": "Usuário",
    "reports.Users Report": "Usuários",
    "reports.Users Report(copy)": "Relatórios do usuário (cópia)",
    "reports.users_num": "Nº do usuário",
    "reports.useSSL": "Usar SSL",
    "reports.ValidateFieldsHaveValues": "Campos de validação têm valores",
    "reports.ValidateFieldsOutOfOrder": "Validar campos fora da faixa",
    "reports.ValidateUserDefinedFields": "Validar campos definidos pelo usuário",
    "reports.value": "Valor",
    "reports.VALUE_ADDRESS": "Endereço",
    "reports.VALUE_BASE_CURRENCY": "Parte do Ativo base",
    "reports.VALUE_BRUTEFORCE_LOCK_MINUTS": "Valor de força bruta bloqueio em minutos",
    "reports.VALUE_CITY": "Cidade",
    "reports.VALUE_COMMISSION_ACCOUNT": "Conta de contrapartida de comissão",
    "reports.VALUE_COMMISSION_FOR_TRANSFER": "Comissão para transferência",
    "reports.VALUE_COMMISSION_ID": "Plano de recompensas",
    "reports.VALUE_COUNTER_CURRENCY": "Contador de parte de Ativo",
    "reports.VALUE_COUNTRY": "País",
    "reports.VALUE_DAY_BEGIN": "Começo do dia",
    "reports.VALUE_DAY_END": "Termino do dia",
    "reports.VALUE_DAYS_TILL_EXP": "Dias até vencimento",
    "reports.VALUE_DEFAULT_BROKER": "Corretora padrão",
    "reports.VALUE_DEFAULT_LANG": "Idioma padrão",
    "reports.VALUE_DERIVATIVE_TYPE": "Tipo de derivado",
    "reports.VALUE_HEDGE_COEFFICIENT": "Coeficiente de 'Hedge'",
    "reports.VALUE_L2QUOTEGROUP_DEPTH": "Profundidade de grupo nível 2",
    "reports.VALUE_L2QUOTEGROUP_INTERVAL": "Intervalo de grupo nível 2",
    "reports.VALUE_LEVERAGE": "Alavancagem",
    "reports.VALUE_LOT_SIZE": "Tamanho do lote",
    "reports.VALUE_LOTSTEP": "Passo do lote",
    "reports.VALUE_MARGIN_COEFFICIENT": "Coeficiente de margem (baseado em tempo)",
    "reports.VALUE_MARGINCALL": "Margem de compra, %",
    "reports.VALUE_MARGINTRADING": "Nível 'Stop' de negociação, %",
    "reports.VALUE_MARKET_RANGE": "Faixa de mercado, 'Ticks'",
    "reports.VALUE_MAX_DELTA_QUOTES": "Diferença máxima admissível de preço entre duas cotações subsequentes ('Ticks')",
    "reports.VALUE_MAX_DELTA_TRADES": "Diferença máxima admissível de preço entre duas negociações subsequentes",
    "reports.VALUE_MAXIMUM_CACHE_SIZE": "Tamanho máximo de cache",
    "reports.VALUE_MAXIMUM_FILTER": "Valor máximo de 'Spike'",
    "reports.VALUE_MAXIMUMLOT": "Lote máximo",
    "reports.VALUE_MIN_OPTION_INTERVAL": "Diferença de tempo mínimo permitida entre duas cotações de opções",
    "reports.VALUE_MIN_PRICE_DIFF_FOR_MARGIN": "Difereça de preço mínima para margem",
    "reports.VALUE_MINIMALLOT": "Lote mínimo",
    "reports.VALUE_MINIMUM_FILTER": "Valor mínimo de 'Spike'",
    "reports.value_nav": "Valor/Nav",
    "reports.VALUE_NO_QUOTES_MAXTIME": "Tempo máximo sem cotações",
    "reports.VALUE_NUMBER_OF_SHARES": "Número de ações",
    "reports.VALUE_ONE_COUNTER_ACCOUNT": "Conta de contrapartida",
    "reports.VALUE_PIP_SIZE": "Tamanho do 'Tick' (mudança mínima)",
    "reports.VALUE_PnL_ACCOUNT": "L/P da conta de contrapartida",
    "reports.VALUE_POST_SESSION_END": "Fim da pós-sessão",
    "reports.VALUE_PRE_SESSION_BEGIN": "Início de pré-sessão",
    "reports.VALUE_PRICE_GENERATION_MODEL": "Modelo de geração de preço",
    "reports.VALUE_PWD_VERIFICATION_LEN": "Verificar comprimento de senha",
    "reports.VALUE_QUOTE_CACHE_15MIN_SIZE": "Tamanho de cache de cotação de 15 min",
    "reports.VALUE_QUOTE_CACHE_1MIN_SIZE": "Tamanho de cache de cotação de 1 min",
    "reports.VALUE_QUOTE_CACHE_30MIN_SIZE": "Tamanho de cache de cotação de 30 min",
    "reports.VALUE_QUOTE_CACHE_4HOUR_SIZE": "Tamanho de cache de cotação de 4 horas",
    "reports.VALUE_QUOTE_CACHE_5MIN_SIZE": "Tamanho de cache de cotação de 5 min",
    "reports.VALUE_QUOTE_CACHE_DAY_SIZE": "Tamanho de cache de cotação diária",
    "reports.VALUE_QUOTE_CACHE_HOUR_SIZE": "Tamanho de cache de cotação horária",
    "reports.VALUE_QUOTE_CACHE_MONTH_SIZE": "Tamanho de cache de cotação mensal",
    "reports.VALUE_QUOTE_CACHE_SIZE": "Tamanho de cache de cotação",
    "reports.VALUE_QUOTE_CACHE_TIC_SIZE": "Tamanho de cache de 'Tick'",
    "reports.VALUE_QUOTE_CACHE_WEEK_SIZE": "Tamanho de cache de cotação semanal",
    "reports.VALUE_QUOTE_CACHE_YEAR_SIZE": "Tamanho de cache de cotação anual",
    "reports.VALUE_QUOTE_PATH": "Caminho para o cache de cotações",
    "reports.VALUE_QUOTES_FILE_SIZE": "Tamanho de arquivo de cotações",
    "reports.VALUE_SLIPPAGE": "Deslizamento",
    "reports.VALUE_SMS_PATTERN": "Padrão de SMS",
    "reports.VALUE_SPOT_INSTRUMENT": "Ativo local",
    "reports.VALUE_SPREAD_ACCOUNT": "'Spread' da conta de contrapartida",
    "reports.VALUE_STATE": "Estado",
    "reports.VALUE_STOPOUT": "Nível de 'Stop Out', %",
    "reports.VALUE_SWAP_ACCOUNT": "'Swap' da conta de contrapartida",
    "reports.VALUE_TEMPLATE_NUMERIC": "Modelos de e-mails",
    "reports.VALUE_TICK_COAST": "Custo de 'Ticks'",
    "reports.VALUE_TIMEZONE_OFFSET": "Deslocamento de fuso horário",
    "reports.VALUE_UNREPEAT_PASSWORD": "Número de senhas não repetidas",
    "reports.VALUE_WL_ACCOUNT": "Conta de contrapartida de depósito/retirada",
    "reports.VALUE_WRONG_PASSWORDS_BEFORE_LOCK": "Número de senhas erradas permitido",
    "reports.VALUE_ZIP": "CEP",
    "reports.ValueAddedTax": "Taxa de valor agregado",
    "reports.valuedate": "Data da avaliação",
    "reports.Visibility": "Visibilidade",
    "reports.VISIBILITY_BRANDING": "Visibilidade de identidade visual",
    "reports.VISIBILITY_COMMISSIONSPLAN": "Visibilidade de planos de recompensas",
    "reports.VISIBILITY_EXTFIELDS": "Visibilidade de campos estendidos",
    "reports.VISIBILITY_INSTRUMENT_TYPE": "Visibilidade do tipo de Ativo",
    "reports.VISIBILITY_ROUTE": "Visibilidade de rotas",
    "reports.VISIBILITY_SUBSCRIBE_PLAN": "Assinar planos",
    "reports.VISIBILITY_USERGROUP": "Visibilidade de grupos de usuários",
    "reports.Wait confirm for cancel": "Esperando confirmação para cancelamento",
    "reports.Wait confirm for execution": "Esperando confirmação da execução",
    "reports.Wait confirm for modify": "Esperando confirmar a modificação",
    "reports.Waiting market": "Aguardando mercado",
    "reports.WEB_CHART_OE": "Gráfico de Web (criado em EO)",
    "reports.WEB_CHART_VISUAL": "Gráfico de Web (Negociação Visual)",
    "reports.WEB_MARKETDEPTH": "Web 'Book' de Ofertas",
    "reports.WEB_OE": "Web EO",
    "reports.WEB_OPTION_MASTER": "Cadeia de Opções Web",
    "reports.WEB_ORDERS_PANEL_HOT_BTN": "Painel de Ordens ativos web (Botões Rápidos)",
    "reports.WEB_OTHER": "Outras Web",
    "reports.WEB_POSITIONS_PANEL_HOT_BTN": "Painel de posições web (Botões Rápidos)",
    "reports.weeks": "semanas",
    "reports.Widgets Instrument Statistics Report": "Estatísticas de Ativos",
    "reports.widgets_isr_buytrades": "Negociações de Compra",
    "reports.widgets_isr_commission": "Taxa",
    "reports.widgets_isr_instrumentname": "Ativo",
    "reports.widgets_isr_netpl": "L/P Líquido",
    "reports.widgets_isr_plticks": "L/P 'Ticks'",
    "reports.widgets_isr_selltrades": "Negociações de Venda",
    "reports.widgets_isr_swap": "Swap",
    "reports.widgets_isr_totaltrades": "Total de negociações",
    "reports.widgets_isr_trades": "Negociações",
    "reports.widgets_isr_tradevolume": "Volume",
    "reports.widgets_isr_winningtrades": "Negociações com ganho",
    "reports.widthdraw": "Retirada",
    "reports.Withdraw": "Retirada",
    "reports.Withdrawal": "Retirada",
    "reports.withdrawalfee": "Taxa de retirada",
    "reports.Wrong account operation summ.": "Soma de operação erradas na conta",
    "reports.Wrong recovery key": "Chave de recuperação errada",
    "reports.years": "anos",
    "reports.yes": "Sim",
    "reports.You can't trade with this account.": "Você não pode negociar com esta conta.",
    "Reserve": "Reservar",
    "ribbon.enviroment": "Ambiente",
    "ribbon.enviroment.help": "Ajuda",
    "ribbon.enviroment.logout": "Sair",
    "ribbon.enviroment.New": "Nova Área de Trabalho",
    "ribbon.enviroment.NotificationWindow.lockTrading": "Negociações bloqueadas",
    "ribbon.enviroment.NotificationWindow.UnlockTrading": "Negociações desbloqueadas",
    "ribbon.enviroment.Save": "Salvar Área de Trabalho",
    "ribbon.enviroment.settings": "Configurações gerais...",
    "ribbon.help.about": "Sobre",
    "ribbon.help.onboarding": "Integração",
    "ribbon.help.help": "Mais",
    "ribbon.help.home": "Página inicial",
    "ribbon.linking.linkBlue": "Azul",
    "ribbon.linking.linkEmpty": "Nenhum",
    "ribbon.linking.linkGreen": "Verde",
    "ribbon.linking.linkOrange": "Laranjado",
    "ribbon.linking.linkPurple": "Roxo",
    "ribbon.linking.linkRed": "Vermelho",
    "ribbon.linking.linkYellow": "Amarelo",
    "ribbon.tools": "Ferramentas",
    "ribbon.tools.full_logs": "Mensagens do servidor de login completas",
    "ribbon.tools.grid": "Grade",
    "ribbon.tools.withdrawal": "Retirada",
    "ribbon.tools.reports": "Relatórios",
    "ribbon.tradingTerminal": "Terminal",
    "RiskManagement.Messages.DailyLimit": "Limite de perda diária foi alcançado",
    "RiskManagement.Messages.MaxDailyProfit": "O lucro diário foi atingido",
    "RiskManagement.Messages.MaxTrailingLimit": "Maximo rebaixamento foi Atingido",
    "RiskManagement.Messages.MaxUnrealizedLossLimit": "O limite de perda não realizado foi atingido",
    "RiskManagement.Messages.WeeklyLimit": "Limite de perda semanal foi Atingido",
    "screen.about.copyright": "© 2017-2024 TraderEvolution Global LTD.",
    "screen.about.dateBuild": "Compilado em:",
    "screen.about.title": "Sobre",
    "screen.about.titleWeb": "",
    "screen.about.versionWeb": "",
    "screen.additionalVerification.comment": "Conexão ({1}) verificação adicional necessária:",
    "screen.additionalVerification.Need activation": "Necessita ativação",
    "screen.additionalVerification.Need activation.comment": "Por favor informar com o código de ativação (enviado a você por email)",
    "screen.additionalVerification.Need activation.watermark": "<Inserir código de ativação do Email>",
    "screen.additionalVerification.title": "Verificação adicional",
    "screen.additionalVerification.title.Email": "Verificação adicional (e-mail em um único passo)",
    "screen.additionalVerification.title.Fixed": "Verificação adicionar (passo fixo)",
    "screen.additionalVerification.title.Sms": "Verificação adicionar (SMS em um único passo)",
    "screen.additionalVerification.watermark": "<Digite senha adicional>",
    "screen.changepass.cancel": "Cancelar",
    "screen.changepass.changed": "Senha alterada com sucesso!",
    "screen.changepass.confirm": "Confirmar a nova senha:",
    "screen.changepass.new": "Nova senha:",
    "screen.changepass.ok": "OK",
    "screen.changepass.old": "Senha atual:",
    "screen.changepass.title": "Alterar senha",
    "screen.changeTradingPass.title": "Mudar a senha de negociação",
    "screen.changepass.watermark_confirm": "<Confirmar a nova senha>",
    "screen.changepass.watermark_new": "<Nova senha>",
    "screen.changepass.watermark_old": "<Senha atual>",
    "screen.closePosition.cancel": "Cancelar",
    "screen.closePosition.ok": "Fechar",
    "screen.closePosition.title": "Fechar posição",
    "screen.error.title": "Erro",
    "screen.licensing.emptyFieldErrors.password": "O campo de senha está vazio",
    "screen.licensing.statusMessages.connecting": "Conectando...",
    "screen.login.change_pass": "A senha foi redefinida ou expirou e precisa ser alterada",
    "screen.lookup.scripts.noitems": "Não há itens correspondentes",
    "screen.lookup.scripts.showing": "Mostrando {1} itens",
    "screen.lookup.scripts.watermark": "<Buscar>",
    "screen.modifyOrder.amount": "Quantidade:",
    "screen.modifyOrder.bind_to": "Vinculada a:",
    "screen.modifyOrder.Change order": "Modificar ordem",
    "screen.modifyOrder.created": "Data/Hora:",
    "screen.modifyOrder.instrument": "Ativo:",
    "screen.modifyOrder.market_price": "Preço atual:",
    "screen.modifyOrder.modify": "Modificar",
    "screen.modifyOrder.modifyPosition": "Modificar posição",
    "screen.modifyOrder.number": "ID da Posição:",
    "screen.modifyOrder.operation": "Lado:",
    "screen.modifyOrder.orderNumber": "ID da ordem:",
    "screen.modifyOrder.ordertype": "Tipo de ordem:",
    "screen.modifyOrder.price": "Preço de abertura:",
    "screen.modifyOrder.route": "Rota:",
    "screen.properties.cancel": "Fechar",
    "screen.properties.cancel.ToolTip": "",
    "screen.properties.common": "propriedades",
    "screen.properties.ok": "OK",
    "screen.properties.ok.ToolTip": "",
    "screen.properties.title": "Configurações gerais",
    "screen.properties.unsavedChanges": "Existem alterações que não foram salvas. Você deseja sair e perder alterações?",
    "screen.LoginScreen.Connecting": "Conectando...",
    "screen.LoginScreen.Error.loginIdIsEmpty": "O campo Login está vazio",
    "screen.LoginScreen.Error.passwordIdIsEmpty": "Campo Senha está vazio",
    "screen.LoginScreen.ForgotLinkLabel": "Recuperar",
    "screen.LoginScreen.ForgotLinkLabel.ToolTip": "Recuperação de senha",
    "screen.LoginScreen.LoadingUserData": "",
    "screen.LoginScreen.LoginButtonText": "Conectar",
    "screen.LoginScreen.LoginButtonTooltip": "Fazer login na plataforma",
    "screen.LoginScreen.LoginIDLabel": "Login do Usuário",
    "screen.LoginScreen.LoginIDTextBox.ToolTip": "Informe o login do usuário",
    "screen.LoginScreen.ChatLink.label": "Atendimento - Chat",
    "screen.LoginScreen.PasswordLabel": "Senha",
    "screen.LoginScreen.PasswordTextBox.ToolTip": "Informe a senha do usuário",
    "screen.LoginScreen.RegistrationLinkLabel": "Criar conta Demo",
    "screen.LoginScreen.RegistrationLinkLabel.ToolTip": "Registro de conta Demo",
    "screen.LoginScreen.SavePassCheckBox": "Salvar senha",
    "screen.LoginScreen.SavePassCheckBox.ToolTip": "Lembrar automaticamente as credenciais para este usuário no próximo Login",
    "screen.LoginScreen.Settings.LanguageLabel": "Idioma",
    "screen.recconection.attemptLabelLabel": "Tentativas:",
    "screen.recconection.reconnecting": "Reconectando...",
    "screen.recconection.seconds": "segundos...",
    "screen.recconection.statusLabelLabel": "Situação",
    "screen.recconection.stop": "Pare",
    "screen.recconection.title": "Gerenciador de reconexão",
    "screen.recconection.tryNow": "Tentar agora",
    "screen.recconection.waiting": "Aguardando",
    "screen.RecoveryPassForm.Cancel": "Cancelar",
    "screen.RecoveryPassForm.Confirm": "Confirmar Senha",
    "screen.RecoveryPassForm.Email": "E-mail:",
    "screen.RecoveryPassForm.HaveCode": "Eu tenho a 'Chave de Verificação'",
    "screen.RecoveryPassForm.Key": "Infomar a 'Chave de Verificação'",
    "screen.RecoveryPassForm.Login": "Login:",
    "screen.RecoveryPassForm.New password": "Nova Senha",
    "screen.RecoveryPassForm.New password contains prohibited symbols": "A 'Nova Senha' contém caracteres proibidos",
    "screen.RecoveryPassForm.Send": "Recuperar",
    "screen.RecoveryPassForm.Title": "Recuperar senha do Usuário",
    "screen.RecoveryPassForm.Your request for password recovering has sent successfully. Please check your email!": "Seu pedido de recuperação de senha foi enviado com sucesso. Por favor verifique seu email!",
    "screen.registrationWizard.confirmPassword": "*Confirmar senha:",
    "screen.registrationWizard.confirmPassword.watermark": "<Confirme sua senha>",
    "screen.registrationWizard.email": "*E-mail:",
    "screen.registrationWizard.email.watermark": "<E-mail>",
    "screen.registrationWizard.Country": "",
    "screen.registrationWizard.Password": "",
    "screen.registrationWizard.Balance": "",
    "screen.registrationWizard.firstName": "*Nome:",
    "screen.registrationWizard.firstName.watermark": "<mín 2 carac>",
    "screen.registrationWizard.lastName": "*Sobrenome:",
    "screen.registrationWizard.lastName.watermark": "<mín 2 carac>",
    "screen.registrationWizard.login": "Login:",
    "screen.registrationWizard.login.watermark": "<Deixe em branco para autogeração>",
    "screen.registrationWizard.password.watermark": "<Digite sua senha>",
    "screen.registrationWizard.phone": "Número do telefone:",
    "screen.registrationWizard.phoneNumber.watermark": "<(código do país) número do telefone>",
    "screen.registrationWizard.registrationStatus": "Situação do registro",
    "screen.registrationWizard.successMessage": "O registro foi bem sucedido! Login: {1}, Senha: {2}",
    "screen.registrationWizard.Header": "",
    "screen.registrationWizard.Register": "",
    "screen.registrationWizard.Cancel": "",
    "screen.renameScreen.cancel": "Cancelar",
    "screen.renameScreen.illegalName": "Nome ilegal!",
    "screen.renameScreen.ok": "OK",
    "screen.renameScreen.newTemplate.header": "Nome do modelo",
    "screen.renameScreen.newTemplate.placeholder": "Novo modelo",
    "screen.ReportMessageTooltip.Account": "Conta",
    "screen.ReportMessageTooltip.CreatedAt": "criado em",
    "screen.ReportMessageTooltip.FilledAt": "executada em",
    "screen.ReportMessageTooltip.MarginCall": "Margin call",
    "screen.ReportMessageTooltip.Market": "Mercado",
    "screen.ReportMessageTooltip.ModifiedAt": "modificado em",
    "screen.ReportMessageTooltip.RemovedAt": "removido em",
    "screen.reports.actions_print": "Imprimir",
    "screen.reports.address": "Endereço",
    "screen.reports.beginDatePicker.ButtonDropDown.ToolTip": "Clique para abrir calendário",
    "screen.reports.beginDatePicker.ToolTip": "Selecionar data/hora",
    "screen.reports.brokerzip": "CEP",
    "screen.reports.button_printer_setup": "Configurar",
    "screen.reports.byGroup": "Por grupo",
    "screen.reports.byInstrumentGroup": "Por grupo",
    "screen.reports.byInstruments": "Por Ativos",
    "screen.reports.byUser": "Por conta",
    "screen.reports.byUserGroup": "Por grupo",
    "screen.reports.cell_data": "",
    "screen.reports.column.inner_text": "",
    "screen.reports.columns_maneger": "Gerenciador de colunas...",
    "screen.reports.country": "País",
    "screen.reports.csv": "",
    "screen.reports.customerzip": "Cidade, CEP",
    "screen.reports.dataCoverage": "Cobertura de dados",
    "screen.reports.errSaving": "Houve um erro ao salvar o arquivo.",
    "screen.reports.excel": "Exportar para Excel...",
    "screen.reports.exportToButton": "",
    "screen.reports.filter": "Gerenciador de filtro...",
    "screen.reports.historyFor": "Histórico para",
    "screen.reports.html": "Exportar para HTML...",
    "screen.reports.info": "Informações",
    "screen.reports.error": "",
    "screen.reports.Reports": "",
    "screen.reports.InstrumentAndGroupLookup.byGroupCombobox.ToolTip": "Selecionar grupo de Ativos",
    "screen.reports.InstrumentAndGroupLookup.instrunentLookup.ToolTip": "Selecinar Ativo",
    "screen.reports.license": "Número da licença",
    "screen.reports.listViewEx.itemAll": "Todos",
    "screen.reports.manager": "Gerente",
    "screen.reports.minimizeButton.Hide reports parameters": "Ocultar parâmetros do relatório",
    "screen.reports.minimizeButton.Show reports parameters": "Exibir parâmetros do relatório",
    "screen.reports.name": "Nome",
    "screen.reports.news.daily": "Diário",
    "screen.reports.news.from": "De",
    "screen.reports.news.hideACriterias": "Ocultar critério avançado",
    "screen.reports.news.invalidDateTime": "Por favor, selecione data/hora \"de\" e \"até\" válidos",
    "screen.reports.news.monthToDate": "Último mês",
    "screen.reports.news.range": "Limites",
    "screen.reports.news.showACriterias": "Exibir critério avançado",
    "screen.reports.news.sinceInception": "Desde o início",
    "screen.reports.news.till": "Até",
    "screen.reports.news.yearToDate": "Ano até a data",
    "screen.reports.noData": "Nenhum dado disponível",
    "screen.reports.order": "Ordem",
    "screen.reports.param.Name": "Nome",
    "screen.reports.paramOfReport": "Parâmetros do relatório",
    "screen.reports.pdf": "Exportar para PDF...",
    "screen.reports.phone": "Número do telefone",
    "screen.reports.pos": "Posição",
    "screen.reports.president": "Nome do presidente",
    "screen.reports.print": "Imprimir...",
    "screen.reports.processingRequset": "Cancelar",
    "screen.reports.rangeError": "A data \"De\" pode estar após a data \"Até\"!",
    "screen.reports.report.button_printer_setup": "Configuração",
    "screen.reports.reportHeader.accnumber": "Número da conta",
    "screen.reports.reportHeader.from": "De",
    "screen.reports.reportHeader.Text": "Informações sobre o cliente e a corretora",
    "screen.reports.reportHeaders.account": "Conta:",
    "screen.reports.reportHeaders.currancy": "Moeda:",
    "screen.reports.reportHeaders.date": "Data:",
    "screen.reports.reportHeaders.from": "De:",
    "screen.reports.reportHeaders.owner": "Cliente:",
    "screen.reports.reportHeaders.to": "Até:",
    "screen.reports.reportHeaderText": "Informações sobre o cliente e a corretora",
    "screen.reports.reportParameters": "Parâmetros do relatório",
    "screen.reports.reports.Canceled": "A operação foi cancelada",
    "screen.reports.reports.ExpingToCSV": "",
    "screen.reports.reports.ExpingToExcel": "Exportando dados para documento Excel...",
    "screen.reports.reports.ExpingToHTML": "Exportando dados para o documento HTML...",
    "screen.reports.reports.ExpingToPDF": "Exportando dados para documento PDF...",
    "screen.reports.reports.ExportingPrint": "Exportando dados para impressão...",
    "screen.reports.reports.ExpPrint": "Os dados para impressão foram exportados com sucesso.",
    "screen.reports.reports.ExpPrintErr": "Houve um erro durante a exportação para impressão!",
    "screen.reports.reports.ExpToCSV": "",
    "screen.reports.reports.ExpToCSVErr": "",
    "screen.reports.reports.ExpToExcel": "Os dados para Excel foram exportados com sucesso.",
    "screen.reports.reports.ExpToExcelErr": "Houve um erro durante exportação para Excel!",
    "screen.reports.reports.ExpToHTML": "Os dados para dovumento HTML foram exportados com sucesso.",
    "screen.reports.reports.ExpToHTMLErr": "Houve um erro durante exportação para o documento HTML!",
    "screen.reports.reports.ExpToPDF": "Os dados para documento PDF foram exportados com sucesso.",
    "screen.reports.reports.ExpToPDFErr": "Houve um erro durante exportação para o documento PDF!",
    "screen.reports.reports.getReport": "Obter relatório",
    "screen.reports.reports.in_separate_window": "Relatório foi aberto em janela separada",
    "screen.reports.reports.inCurrentWindow": "Na janela atual",
    "screen.reports.reports.info": "Usar o gerenciador de relatório para visualizar e imprimir relatórios de atividade da conta",
    "screen.reports.reports.inSeparateWindow": "Em janela separada",
    "screen.reports.reports.loading": "Carregando dados para relatório...",
    "screen.reports.reports.noData": "Desculpe, não há dados disponíveis para esta solicitação. Tente usar outros parâmetros para o relatório.",
    "screen.reports.reports.noItemAvailable": "Nenhum item disponível",
    "screen.reports.reportType": "Relatório",
    "screen.reports.reportTypeCombobox.ToolTip": "Selecionar o tipo de relatório",
    "screen.reports.save": "Salvar",
    "screen.reports.showSummary": "Exibir resumo",
    "screen.reports.sinceInception": "Desde o início",
    "screen.reports.summary": "Resumo",
    "screen.reports.table": "Tabela",
    "screen.reports.title": "Gerenciador de relatório",
    "screen.reports.to": "Até",
    "screen.reports.trades": "Negociações",
    "screen.reports.UserAndGroupLookup.byGroupCombobox.ToolTip": "Selecionar grupo de contas",
    "screen.reports.UserAndGroupLookup.usersLookup.ToolTip": "Selecionar conta",
    "screen.TimeZoneSelector.title": "Selecionar fusos horários",
    "screen.UnlockTradingScreen.lockedLabel": "",
    "screen.UnlockTradingScreen.unlockButton": "",
    "Simple Moving Average": "Simple Moving Average",
    "SixMonth": "6 meses",
    "Smoothed Moving Average": "Smoothed Moving Average",
    "Standard Deviation": "Standard Deviation",
    "statusBar.passwordpanel.DetalTicket.Text": "Acesso permitido à atividade de negociação",
    "statusBar.passwordPanel.passwordBox.invalidPass": "A senha de negociação não é válida.",
    "Stochastic": "Stochastic",
    "Stochastic Slow": "Stochastic Slow",
    "Stop": "Stop",
    "Stop Limit": "Stop Limit",
    "Stop-loss": "Stop Loss",
    "Suomi": "#hidden#",
    "Swing Index": "Índice 'Swing'",
    "Take-profit": "Take Profit",
    "TerceraAccountLookup.AccountNotExist": "Conta não existe",
    "TerceraAccountLookup.IncorrectAccount": "Conta incorreta",
    "TerceraAccountLookup.Linked accounts": "Contas ligadas",
    "TerceraAccountLookup.Multy select": "Múltiplas",
    "TerceraAccountLookup.Select": "Selecionar",
    "TerceraAccountLookup.Select All": "Selecionar todos",
    "textBoxWithValidating.ErrorText.NameContainsInvalidChar": "",
    "textBoxWithValidating.ErrorText.NameExist": "",
    "ThreeMonth": "3 meses",
    "Tr. stop": "Trailing Stop",
    "TradeDefaultManager.Set defaults": "Definir padrões",
    "TradeDefaultManager.Types manager": "Gerenciamento de tipos:",
    "TradeTool.Amount of order": "Quantidade de ordem",
    "TradeTool.Cancel order": "Cancelar ordens",
    "TradeTool.Cancel SL": "Cancelar SL",
    "TradeTool.Cancel TP": "Cancelar TP",
    "TradeTool.Close position": "Fechar posição",
    "TradeTool.Drag to set SL order": "Arraste para configurar ordem SL",
    "TradeTool.Drag to set TP order": "Arraste para configurar ordem TP",
    "TradeTool.Order": "Ordem",
    "TradeTool.Position": "Posição",
    "TradeTool.Position P/L": "L/P bruto",
    "TradeTool.Tick and P/L": "'Ticks' e L/P",
    "TradeTool.Trailing Stop": "Trailing Stop",
    "TradeTool.View more actions": "Ver mais ações",
    "TTO": "TTO",
    "TypesManagerScreen.DefaultLotsSetup": "Configuração lotes padrão",
    "TypesManagerScreen.NumericLabel.Price increment (arrow), ticks": "incremento de preços (seta), 'Ticks'",
    "TypesManagerScreen.NumericLabel.SL default offset, ticks": "Distância padrão do SL, 'Ticks'",
    "TypesManagerScreen.NumericLabel.TP default offset, ticks": "Distância padrão do TP, 'Ticks'",
    "TypesManagerScreen.NumericLabel.User quantity coefficient": "Coeficiente de quantidadesdo usuário",
    "TypesManagerScreen.QuickTable.Column.Default lots": "Lotes padões",
    "TypesManagerScreen.QuickTable.Column.Enable": "Habilitado",
    "TypesManagerScreen.Types Manager": "Gerente de tipos",
    "UserControl.TerceraNumeric.ValueGreaterMax": "O valor é maior do que o máximo ",
    "UserControl.TerceraNumeric.ValueIsEmpty": "O valor está vazio",
    "UserControl.TerceraNumeric.ValueLessMin": "O valor é menor do que o mínimo ",
    "UserControl.TerceraNumeric.ValueNotMultiple": "O valor não é múltiplo de mudança mínima",
    "UserControl.TerceraNumeric.ValueNotNumber": "O valor não é um número",
    "Volume": "Volume",
    "Volume Weighted Average Price": "Volume Weighted Average Price",
    "VWAP": "VWAP",
    "allowCreatePanelsFromPanel": "",
    "allowLinkSystem": "",
    "allowWorkspaceContextMenu": "",
    "allowWorkspaceEdit": "",
    "orders.confirm.all.question1": "",
    "orders.confirm.all.question2": "",
    "orders.confirm.all.title": "",
    "orders.confirm.cancel.question1": "",
    "positions.confirm.mutual.question1": "",
    "positions.confirm.mutual.question2": "",
    "Rows.firstPart": "",
    "Rows.secondPart": "",
    "general.messageBox.cancel.ToolTip": "",
    "property.Confirmations.ToolTip": "",
    "general.messageBox.ok.ToolTip": "",
    "property.Defaults.ToolTip": "",
    "property.View.ToolTip": "",
    "property.Warnings.ToolTip": "",
    "Throtling.Count.firstPart": "",
    "Throtling.Count.secondPart": "",
    "workspace.At first you should unlock workspace to rename it": "Em primeiro lugar você deve desbloquear a Área de Trabalho para depois renomeá-la.",
    "workspace.Clear": "Limpar",
    "workspace.clear_ask": "Limpar Área de Trabalho?",
    "workspace.clear_wsp": "Limpar Área de Trabalho",
    "workspace.Close": "Fechar",
    "workspace.close_wsp": "Fechar Área de Trabalho",
    "workspace.DefaultNameSpace": "Novo",
    "workspace.errorSavingFile": "Houve um erro ao salvar o arquivo.",
    "workspace.information": "Informações",
    "workspace.saved.Message": "",
    "workspace.Lock": "Bloquear",
    "workspace.Lock.Message": "A Área de Trabalho está bloqueada",
    "workspace.menu.Clone": "Clonar",
    "workspace.menu.Close": "Fechar",
    "workspace.menu.CloseAllButThis": "Fechar todos menos este",
    "workspace.menu.Lock": "Bloquear",
    "workspace.newWorkspaceName": "Digite o novo nome da Área de Trabalho",
    "workspace.numberOfWorkspace": "O número de Áreas de Trabalho abertos excede o máximo permitido",
    "workspace.Rename": "Renomear",
    "workspace.Unlock": "Desbloquear",
    "workspace.Unlock.Message": "A Área de Trabalho foi desbloqueada",
    "InstrumentDetailsPanel.ISIN": "",
    "panel.accountDetails.Groups.2.Margin": "Margem",
    "panel.accountDetails.Groups.3.AccountActivity": "Atividade da conta",
    "panel.accountDetails.Groups.4.TodayResults": "Resultados de hoje",
    "panel.accountDetails.Groups.5.RiskManagement": "Gerenciamento de risco",
    "panel.accountDetails.Groups.55.Plans": "Planos",
    "panel.accountDetails.Groups.6.Info": "Informação",
    "panel.accountDetails.Groups.Rico risks": "Detalhes da conta",
    "panel.accountDetails.Groups.AccountActivity": "2. Atividade da conta",
    "panel.accountDetails.Groups.General": "1. Geral",
    "panel.accountDetails.Groups.Info": "5. Informações",
    "panel.accountDetails.Groups.RiskManagement": "4. Gerenciamento de risco",
    "panel.accountDetails.Groups.TodayResults": "3. Resultados de hoje",
    "panel.accountDetails.Rico risks.AVLM": "Margem disponível",
    "panel.accountDetails.Rico risks.BCKA": "Quantidade bloqueada",
    "panel.accountDetails.Rico risks.BMBM": "Margem BMF bloqueada",
    "panel.accountDetails.Rico risks.BMFL": "Limite BMF",
    "panel.accountDetails.Rico risks.BOPT": "Saldo em Opções",
    "panel.accountDetails.Rico risks.BUOP": "Saldo descoberto em operações",
    "panel.accountDetails.Rico risks.CED0": "Crédito no dia 0",
    "panel.accountDetails.Rico risks.CED1": "Crédito no dia 1",
    "panel.accountDetails.Rico risks.CED2": "Crédito no dia 2",
    "panel.accountDetails.Rico risks.CED3": "Crédito no dia 3",
    "panel.accountDetails.Rico risks.CRLM": "Limite de crédito",
    "panel.accountDetails.Rico risks.CRLO": "Limite de crédito opt",
    "panel.accountDetails.Rico risks.D1PB": "Saldo em D0",
    "panel.accountDetails.Rico risks.D2PB": "Saldo em D1",
    "panel.accountDetails.Rico risks.D3PB": "Saldo em D2",
    "panel.accountDetails.Rico risks.DED0": "Débito no dia 0",
    "panel.accountDetails.Rico risks.DED1": "Débito no dia 1",
    "panel.accountDetails.Rico risks.DED2": "Débito no dia 2",
    "panel.accountDetails.Rico risks.DED3": "Débito no dia 3",
    "panel.accountDetails.Rico risks.FNBL": "Saldo final",
    "panel.accountDetails.Rico risks.FNLE": "Alavancagem financeira",
    "panel.accountDetails.Rico risks.INBA": "Saldo inicial",
    "panel.accountDetails.Rico risks.LEVE": "Alavancagem",
    "panel.accountDetails.Rico risks.LILO": "Limite de alavancagem em Opções",
    "panel.accountDetails.Rico risks.LVLM": "Limite de alavancagem",
    "panel.accountDetails.Rico risks.OPBL": "Saldo de opções",
    "panel.accountDetails.Rico risks.OVLT": "Limite saque descoberto",
    "panel.accountDetails.Rico risks.POVA": "Quantidade potencial descoberta",
    "panel.accountDetails.Rico risks.PRBA": "Saldo projetado",
    "panel.accountDetails.Rico risks.SKBL": "Saldo de Ações",
    "panel.accountDetails.Rico risks.WDBL": "Disponível para retirada",
    "panel.accountDetails.Groups.1.General": "Geral",
    "panel.accountDetails.Groups.Cedro risks": "Detalhes da conta",
    "panel.accountDetails.Cedro risks.AVBA": "Saldo Disponível",
    "panel.accountDetails.Cedro risks.DAY_1_CREDITS": "D1",
    "panel.accountDetails.Cedro risks.DAY_2_CREDITS": "D2",
    "panel.accountDetails.Cedro risks.PRBA": "Saldo Projetado",
    "panel.accountDetails.Cedro risks.TOTL": "Patrimonio",
    "panel.accountDetails.Cedro risks.LMAL": "Limite Total (Day Trade)",
    "panel.accountDetails.Cedro risks.LMDI": "Saldo total (Day Trade)",
    "panel.accountDetails.Cedro risks.SLDTL": "Saldo total (Position)",
    "panel.accountDetails.Cedro risks.UNQL": "Limite total (Position)",
    "Order.OrderStatus.STATUS_UNKNOWN": "Unknown",
    "Order.OrderStatus.STATUS_NEW": "Criado",
    "Order.OrderStatus.STATUS_ACCEPTED": "Aceitado",
    "Order.OrderStatus.STATUS_PENDING_EXECUTION": "Executando",
    "Order.OrderStatus.STATUS_REPLACED": "Modificado",
    "Order.OrderStatus.STATUS_PENDING_CANCEL": "Cancelando",
    "Order.OrderStatus.STATUS_PART_FILLED": "Parte executada",
    "Order.OrderStatus.STATUS_FILLED": "executado",
    "Order.OrderStatus.STATUS_PENDING_REPLACE": "Modificando",
    "Order.OrderStatus.STATUS_WAITING_MARKET": "Aguardando mercado",
    "Order.OrderStatus.STATUS_OFF_MARKET": "Fora do mercado",
    "Order.OrderStatus.STATUS_OFF_MARKET": "Não colocado",
    "pageNavigation.backButton": "Voltar",
    "pageNavigation.nextButton": "Avançar",
    "pageNavigation.doneButton": "Finalizado",
    "onBoarding.slide1.header1": "Controle de bloqueio de área de trabalho",
    "onBoarding.slide1.text1": "Se a área de trabalho estiver bloqueada, o usuário não pode fechar os painéis, adicione novos painéis ou mova para onde desejar",
    "onBoarding.slide2.header1": "Ver mais ações de controle",
    "onBoarding.slide2.text1": "Clonar – duplica sua área de trabalho Renomear – permite renomear sua área de trabalho",
    "onBoarding.slide2.text2": "Você pode renomear a área de trabalho dando um duplo-clique no botão da área de trabalho",
    "onBoarding.slide3.header1": "Controle de bloqueio de àrea de trabalho",
    "onBoarding.slide3.header2": "Fechando àrea de trabalho",
    "onBoarding.slide3.header3": "Criar área de trabalho",
    "onBoarding.slide3.text1": "Se a área de trabalho estiver bloqueada, o usuário não pode fechar os painéis, adicione novos painéis ou mova para onde desejar",
    "onBoarding.slide3.text2": "Você pode fechar a área de trabalho clicando em “Fechar”",
    "onBoarding.slide3.text3": "Clicando em “Criar”, você cria uma nova área de trabalho",
    "onBoarding.slide4.header1": "Ligação de painéis por ativo",
    "onBoarding.slide4.text1": "Esse recurso permite alternar o ativo automaticamente em todos os painéis quando você altera o ativo em um painel",
    "onBoarding.slide5.header1": "Operação pelo Mouse",
    "onBoarding.slide5.text1": "Usando a negociação com o mouse você pode colocar ordens, stop, limit ou OCO",
    "onBoarding.slide5.text2": "Quantidade e validade são definidas a partir da quantidade e validade são definidos no widget ao usar o mouse.",
    "onBoarding.slide5.text3": "Usando o clique&arraste você pode modificar ordens e posições no gráfico, também pode adicionar SL/TP ou modificar a quantidade",
    "onBoarding.slide6.header1": "Margem de risco",
    "onBoarding.slide6.text1": "No painel de “Entrada de ordem”, você pode\nvisualizar as informações adicionais sobre o\nrisco antes de colocar uma ordem",
    "onBoarding.slide6.text2": "Clique na opção Esconder/Mostrar",
    "onBoarding.slide7.header1": "Sistema de ancoragem",
    "onBoarding.slide7.text1": "Você pode mover os painéis na área de trabalho usando a função arrastar&soltar",
    "onBoarding.slide8.header1": "Informação de ativo",
    "onBoarding.slide8.text1": "Ao clicar no ícone da série do ativo, você pode visualizar as \"informações do mesmo\"",
    "onBoarding.slide9.header1": "Ferramentas de detalhes da conta",
    "onBoarding.slide9.text1": "Você pode customizar o campo de ferramentas",
    "onBoarding.slide10.header1": "Conexão de painéis por contas ",
    "onBoarding.slide10.text1": "Você pode habilitar a conexão de painéis por contas",
    "onBoarding.slide10.text2": "Você pode habilitar a filtragem de ordens e posições por conta",
    "onBoarding.slide11.header1": "Ajuda",
    "onBoarding.slide11.header2": "Configurações gerais",
    "onBoarding.slide11.text1": "Para usar o Manual, clique no ícone “Ajuda”",
    "onBoarding.slide11.text2": "Você pode abrir o menu de configurações gerais clicando na opção “Configurações”",
    "onBoarding.slide12.header1": "Finalizado!",
    "IndicatorGroup.Moving Average": "Médias Móveis",
    "MD": "MD",
    "REGRESSION": "REGRESSION",
    "Regression": "Regression",
    "SMMA": "SMMA",
    "PPMA": "PPMA",
    "SMA": "SMA",
    "EMA": "EMA",
    "IndicatorGroup.Oscillators": "Osciladores",
    "MACD": "MACD",
    "BB": "BB",
    "BBF": "BBF",
    "IndicatorGroup.Volume": "Volume",
    "RSI": "RSI",
    "PO": "PO",
    "CCI": "CCI",
    "Aroon": "Aroon",
    "KRI": "KRI",
    "Kairi Relative Index": "Kairi Relative Index",
    "PPO": "PPO",
    "Percentage Price Oscillator": "Percentage Price Oscillator",
    "RLW": "RLW",
    "%R Larry Williams": "%R Larry Williams",
    "ROC": "ROC",
    "Rate of Change": "Rate of Change",
    "QStick": "QStick",
    "TSI": "TSI",
    "True Strength Index": "True Strength Index",
    "SI": "SI",
    "ALLIGATOR": "ALLIGATOR",
    "MMA": "MMA",
    "IndicatorGroup.Trend": "Seguidores de Tendência",
    "STOCHASTIC": "STOCHASTIC",
    "OBV": "OBV",
    "IndicatorGroup.Volatility": "Volatilidade",
    "ATR": "ATR",
    "CMO": "CMO",
    "SD": "SD",
    "SAR": "SAR",
    "ADX": "ADX",
    "IndicatorGroup.Channels": "Canais",
    "KELTNER": "KELTNER",
    "CHANNEL": "CHANNEL",
    "MAE": "MAE",
    "LWMA": "LWMA",
    "MAS3": "MAS3",
    "IndicatorGroup.Extra": "Extra",
    "Didi": "Didi",
    "HiloEscandina": "HiloEscandina",
    "StopATR": "StopATR",
    "Fractals3": "Fractals3",
    "Fractals5": "Fractals5",
    "Kaufman adaptive moving average": "Kaufman adaptive moving average",
    "Multi EMA": "Multi EMA",
    "Self-Adjusting Alpha with Fractals Energy": "RSI Self-Adjusting Alpha with Fractals Energy",
    "panel.positions.confirmation.ModifyProductType": "Alterar o tipo de produto de {0} para {1} para {2}, {3}, {4}?",

    "Web.mobile.active.WorkingOrders": "Em aberto",
    "Web.mobile.active.WorkingOrders.EmptyPlaceholder": "Sem pedidos",
    "Web.mobile.active.FilledOrders": "Executada",
    "Web.mobile.active.HistoryOrders": "Histórico",
    "Web.mobile.active.Positions": "Posições",
    "Web.mobile.active.Positions.EmptyPlaceholder": "Sem posições",
    "Web.mobile.active.Assets": "Ativos",
    "Web.mobile.active.Orders": "Ordens",
    "Web.mobile.active.More": "Mais",
    "Web.mobile.active.MamSummary": "Resumo MAM",
    "Web.mobile.active.OptionChain": "Cadeia de Opções",
    "Web.mobile.active.Alerts.NoAlerts": "Sem alertas",
    "Web.mobile.active.EventLog.NoEvents": "Sem eventos",
    "Web.mobile.active.EventLog.DateTime": "Data e Hora",
    "Web.mobile.active.MamSummary.Column.OperationType": "Tipo de Operação",
    "Web.mobile.active.MamSummary.Column.Amount": "Valor",
    "Web.mobile.active.More.Header": "Mais",
    "Web.mobile.active.Settings.Defaults": "Padrões",
    "Web.mobile.active.Settings.QuantityInLots": "Quantidade em Lotes",
    "Web.mobile.active.Settings.SetSlTpInOffset": "Definir SL/TP com Offset",
    "Web.mobile.active.Settings.OrderSending": "Envio de Ordem",
    "Web.mobile.active.Settings.OrderModifying": "Modificação de Ordem",
    "Web.mobile.active.Settings.OrderExecuting": "Execução de Ordem",
    "Web.mobile.active.Settings.OrderCancelling": "Cancelamento de Ordem",
    "Web.mobile.active.Settings.PositionModifying": "Modificação de posição",
    "Web.mobile.active.Table.Total.Profit": "Lucro Total",
    "Web.mobile.active.NewTrades.PnL": "L/P",
    "Web.mobile.active.OrderBook.ExecutionType": "Tipo de Execução",
    "Web.mobile.active.Assets.EmptyPlaceholder": "Sem ativos",
    "Web.mobile.active.Assets.DefSymbol": "Símbolo Padrão",
    "Web.mobile.active.Assets.SODQuantity": "Quantidade SOD",
    "Web.mobile.active.Assets.CurrentValueForSale": "Valor Atual para Venda",
    "Web.mobile.active.Positions.Profit": "P/L",
    "Web.mobile.active.AccountDetails.LinkingTooltip": "Esta função filtra suas posições e ordens por conta. As mudanças de conta são sincronizadas em todos os painéis.",
    "Web.mobile.active.Watchlist.EmptyPlaceholder": "Nenhum símbolo",
    "Web.mobile.active.Watchlist.ListNamePlaceholder": "Digite o nome aqui",
    "Web.mobile.active.Watchlist.Added": "Adicionado à lista de observação",
    "Web.mobile.active.Watchlist.Removed": "Removido da lista de observação",
    "Web.mobile.active.Watchlist.MaxReached": "Número máximo atingido",
    "Web.mobile.active.ExchangesFilter.Filter": "Filtro",
    "Web.mobile.active.OrderEntry.Send": "Enviar Ordem",
    "Web.mobile.active.OrderEntry.Price": "Preço",
    "Web.mobile.active.OrderEntry.TrStop": "Stop Trailing",
    "Web.mobile.active.OrderEntry.SlPrice": "Preço do Stop Loss",
    "Web.mobile.active.OrderEntry.SlOffset": "Offset do Stop Loss",
    "Web.mobile.active.OrderEntry.TrSlOffset": "Offset do Stop Loss Trailing",
    "Web.mobile.active.OrderEntry.SllPrice": "Preço do Limite do Stop Loss",
    "Web.mobile.active.OrderEntry.SllOffset": "Offset do Limite do Stop Loss",
    "Web.mobile.active.OrderEntry.TpPrice": "Preço do Take Profit",
    "Web.mobile.active.OrderEntry.TpOffset": "Offset do Take Profit",
    "Web.mobile.active.Login.ExploreTheApp": "Explorar o Aplicativo",
    "Web.mobile.active.InstrumentInfo.Price": "Preço",
    "Web.mobile.active.InstrumentInfo.BreakEven": "Break-Even",
    "Web.mobile.active.Asset.SellExchange": "Exchange",
    "Web.mobile.active.Asset.StartDayQtyMargin": "Quantidade SOD para MA",
    "Web.mobile.active.Asset.LiquidityRate": "Taxa de Liquidez, %",
    "Web.mobile.active.Asset.TodayTradedQty": "Quantidade Intraday",
    "Web.mobile.active.Asset.AvailableQtySell": "Quantidade Disponível para Venda",
    "Web.mobile.active.FilledOrder.TradeVolume": "Volume de Negociação",
    "Web.mobile.active.FilledOrder.Date": "Data",
    "Web.mobile.active.FilledOrder.GrossPnL": "L/P bruto",
    "Web.mobile.active.HistoryOrder.Date": "Data",
    "Web.mobile.active.MamSummary.EmptyPlaceholder": "Você não possui histórico de conta para este período",
    "Web.mobile.active.MamSummary.Account": "Conta",
    "Web.mobile.active.MamSummary.OperationId": "ID da Operação",
    "Web.mobile.active.MamSummary.InstrumentName": "Nome do Instrumento",
    "Web.mobile.active.MamSummary.OpenPrice": "Preço de Abertura",
    "Web.mobile.active.MamSummary.ClosePrice": "Preço de Fechamento",
    "Web.mobile.active.MamSummary.Side": "Lado",
    "Web.mobile.active.MamSummary.Quantity": "Quantidade",
    "Web.mobile.active.MamSummary.Buy": "Compra",
    "Web.mobile.active.MamSummary.Sell": "Venda",
    "Web.mobile.active.Position.StopLoss": "Stop Loss",
    "Web.mobile.active.Position.TakeProfit": "Take Profit",
    "Web.mobile.active.Position.Date": "Data",
    "Web.mobile.active.Order.StopLoss": "Stop Loss",
    "Web.mobile.active.Order.TrStopOffset": "Trailing SL offset",
    "Web.mobile.active.Order.TrStopLoss": "SL Trailing stop",
    "Web.mobile.active.Order.TakeProfit": "Take Profit",
    "Web.mobile.active.Order.QuantityFilled": "Qty. filled",
    "Web.mobile.active.Search": "Pesquisar",
    "Web.mobile.active.CalendarPeriod.Today": "Hoje",
    "Web.mobile.active.CalendarPeriod.LastWeek": "Semana Passada",
    "Web.mobile.active.CalendarPeriod.Last1M": "Último 1M",
    "Web.mobile.active.CalendarPeriod.Last3M": "Último 3M",
    "Web.mobile.active.CalendarPeriod.Last6M": "Último 6M",
    "Web.mobile.active.InstrumentDetails.6.SessionInfo": "Agenda",
    "Web.mobile.active.OrderModify.Confirmation.Title.Modify": "Modificando Ordem",
    "Web.mobile.active.OrderModify.Confirmation.Title.Cancel": "Cancelando Ordem",
    "Web.mobile.active.OrderModify.Confirmation.Title.ExecuteAtMarket": "Executar Ordem no Mercado?",
    "Web.mobile.active.OrderModify.Error.SLSmallerMin": "O Stop Loss está menor do que o valor mínimo possível",
    "Web.mobile.active.OrderModify.Error.SLGreaterMax": "O Stop Loss está maior do que o valor máximo possível",
    "Web.mobile.active.OrderModify.Error.TPSmallerMin": "O Take Profit está menor do que o valor mínimo possível",
    "Web.mobile.active.OrderModify.Error.TPGreaterMax": "O Take Profit está maior do que o valor máximo possível",
    "Web.mobile.active.PositionModify.Confirmation.Title": "Modificando Posição",
    "Web.mobile.active.PositionClose.Confirmation.Lots": "lote(s)",
    "Web.mobile.active.Lists": "Listas",
    "Web.mobile.active.AccountDetails.Groups.1.AccountSummary": "Resumo da conta",
    "Web.mobile.active.AccountDetails.Groups.4.TodayResult": "Resultado de hoje",
    "Web.mobile.active.AccountDetails.MarginUsedPercent": "Requisito de margem inicial %",
    "Web.mobile.active.AccountDetails.OptionPremiumReq": "Requisito de prêmio de opções",
    "Web.mobile.active.AccountDetails.OpenGrossPL": "Lucro/Perda bruto aberto",
    "Web.mobile.active.AccountDetails.OpenPostionsNumber": "Posições abertas",
    "Web.mobile.active.AccountDetails.OpenOrdersNumber": "Ordens de trabalho",
    "Web.mobile.active.AccountDetails.TodaysNetProfit": "Benefício líquido de hoje",
    "Web.mobile.active.Chart.IndicatorsMenu.AddedIndicators": "Indicadores adicionados",
    "Web.mobile.active.Chart.Menu.ChartSyle": "Estilo do gráfico",
    "Web.mobile.active.Chart.Menu.Settings": "Configurações",
    "Web.mobile.active.Chart.NoDataAvailable": "Nenhum dado disponível",
    "Web.mobile.active.TrailingStopLoss": "Tr.",
    "Web.mobile.active.Position.GrossPnL": "L/P bruto",
    "Web.mobile.active.signIn.error.emptyLogin": "Login está vazio",
    "Web.mobile.active.signIn.error.emptyPassword": "A senha está vazia",
    "Web.mobile.active.Table.Total.GrossPnL": "Total Gross P/L",
}