// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Color, Pen } from '../../Graphics';
import { CloudVector } from './CloudVector';
import { ReloadHistoryParams } from '../../../Utils/History/ReloadHistoryParams';
import { CustomErrorClass, ErrorInformationStorage } from '../../ErrorInformationStorage';
import { LevelLine } from './LevelLine';
import { HistoricalData } from './HistoricalData';
import { LineStyleUtils } from '../../UtilsClasses/LineStyleUtils';
import { DynProperty } from '../../DynProperty';
import { CashItem } from '../History/CashItem';
import { LineStyle } from './IndicatorScriptBaseEnums';
import { type MarkersVector } from './MarkersVector';
import { type DoubleMatrix } from './DoubleMatrix';
import { type HistoricalDataRequest } from './HistoricalDataRequest';
import { type InputParameterBase } from './InputParamaterClasses/InputParameterBase';
import { InputParameterInteger } from './InputParamaterClasses/InputParameterInteger';
import { InputParameterDouble } from './InputParamaterClasses/InputParameterDouble';
import { InputParameterCombobox } from './InputParamaterClasses/InputParameterCombobox';
import { iBuildInIndicator } from './iBuildInIndicator';

// WorkItem
export class IndicatorScriptBase {
    public ProjectName: string = '';
    public Comments: string = '';
    public Author: string = '';
    public Company: string = '';
    public Copyrights: string = '';
    public Version: string = '';
    public DateOfCreation: string = '';

    public LinesCount: number = 0;

    public Data: DoubleMatrix | null = null;

    public FSeparateWindow: boolean = false;
    public FValuesTable: any = {};

    public onlyDefaultline: boolean = true;

    public LevelLines = new Array<any>();
    public ShortName: string = '';
    public FInputProperties = new Array<any>();
    public InputParametersStorage: any = {};

    public CurrentData: HistoricalData;

    public builtInIndicatorArray: any[] | null = [];

    public Indicators: any = null;

    public DrawBegin = new Array<any>();

    public _IndParameterValueMap: any = {};

    // FCloudBuffer, LineMarkers та UpdateIndicatorLevelLineValueHandler присвоюються в Indicator, як поля раніше створювалися там
    // public FCloudBuffer: HashtableLightCount;
    public FCloudBuffer: any;
    public LineMarkers: MarkersVector[];
    public UpdateIndicatorLevelLineValueHandler: any;

    public Count: number; // Хз з якого місця присвоюється

    constructor () {
        this.SetIndicatorLine('Line', Color.Wheat, 1, LineStyle.SimpleChart);
    }

    get SeparateWindow (): boolean { return this.FSeparateWindow; }
    set SeparateWindow (value: boolean) {
        this.FSeparateWindow = value;
        this.SetValues('separateWindow', value);
    }

    public SetIndicatorLine (name: string, color: any, width: number, style: LineStyle): void {
        if (name == null) { name = ''; }

        if (width === 0) { width = 1; } else { width = Math.abs(width); }

        if (this.onlyDefaultline) {
            this.onlyDefaultline = false;
            this.LinesCount--;
        }

        const prefix = 'line' + (++this.LinesCount) + '.';
        this.SetValues(prefix + 'Name', name);
        this.SetValues(prefix + 'Color', color);
        this.SetValues(prefix + 'Width', width);
        this.SetValues(prefix + 'Style', style);
        this.SetValues(prefix + 'Visible', true);

        this.SetValues('linesCount', this.LinesCount);
    };

    // Access to the level lines - through the LevelLines collection
    // Property "Level" of object level line is available
    // Sets the horizontal line at the level of the Y-axis.
    public SetLevelLine (name: string, level: number, color: any, width: number, style: LineStyle): void {
        const ll = new LevelLine(name, level, color, width, style);
        ll.LineNumber = this.LinesCount + this.LevelLines.length;
        this.LevelLines.push(ll);
    };

    public setHistoricalData (data: HistoricalData): void {
        this.CurrentData = data;
        this.initInternalIndicators();
    };

    public SetIndexDrawBegin (index: number, value: any): void {
        this.DrawBegin[index] = value;
    };

    public IndicatorShortName (name): void {
        this.ShortName = name;
    };

    public SetValues (key, value): void {
        this.FValuesTable[key] = value;
    };

    public GetValues (): any {
        return this.FValuesTable;
    };

    public SetValue (par0: any = undefined, par1: any = undefined, par2: any = undefined): void {
        let line = 0;
        let offset = 0;
        let value = 0;

        if (par0 !== undefined && par1 !== undefined && par2 === undefined) {
            line = par0;
            value = par1;
        } else if (par0 !== undefined && par1 !== undefined && par2 !== undefined) {
            line = par0;
            offset = par1;
            value = par2;
        }

        if (line < 0 || line >= this.Data?.Length) { throw new Error('line'); }

        const vector = this.Data?.get(line);

        if (offset < 0 || offset >= vector.Length) { throw new Error('offset'); }

        if (isNaN(value)) { value = Number.MAX_VALUE; }

        vector.set(vector.Length - 1 - offset, value);
    };

    public GetValue (line, offset): any {
        if (line < 0 || line >= this.Data?.Length) { throw new Error('line'); }

        const vector = this.Data?.get(line);

        if (offset < 0 || offset >= vector.Length) { throw new Error('offset'); }

        return vector.get(vector.Length - 1 - offset);
    };

    public SetLineShift (line, shift): void {
        const vector = this.Data?.get(line);
        if (!vector) { return; }
        vector.FTimeShift[line] = shift;
    };

    public SetMarker (par0: any = undefined, par1: any = undefined, par2: any = undefined): void {
        let line = 0;
        let offset = 0;
        let color = null;

        if (par0 !== undefined && par1 !== undefined && par2 === undefined) {
            line = par0;
            color = par1;
        } else if (par0 !== undefined && par1 !== undefined && par2 !== undefined) {
            line = par0;
            offset = par1;
            color = par2;
        }
        // check line index
        if (line < 0 || line >= this.Data?.Length) { throw new Error('line'); }

        // check offset
        const markersBuffer = this.LineMarkers[line];
        if (offset < 0 || offset >= markersBuffer.Count) { throw new Error('offset'); }

        //
        markersBuffer.SetMarker(markersBuffer.Count - 1 - offset, color);
    };

    /// <summary>
    /// Sets the value of indicator's cloud into internal buffer;
    /// </summary>
    public SetCloud (buferLine, index, what, value, color, gradientMethod, offset): void {
        this.SetCloudBuferValue(buferLine, index, what, value, color, gradientMethod, offset, null);
    };

    private SetCloudBuferValue (buferLine, index, value1, value2, color1, color2, gradientMethod, timeShift): void {
        let vector = this.FCloudBuffer.Get(buferLine);
        if (vector == null) {
            vector = this.FCloudBuffer.Set(buferLine, vector = new CloudVector(this.Count, color1, color2, gradientMethod));
            vector.Get(0).Add(0.0);
            vector.Get(1).Add(0.0);
        }

        vector.Get(0).set(this.CurrentData.Count - 1 - index, value1);
        vector.Get(1).set(this.CurrentData.Count - 1 - index, value2);

        vector.FirstLineColor = color1;
        vector.SecondLineColor = color2;
        vector.Shift = timeShift;
    };

    /// <summary>
    /// Removes redrawn parts of indicator's line within the interval set by offset
    /// </summary>
    public RemoveMarker (line, offset): void {
    // check line index
        if (line < 0 || line >= this.Data?.Length) { throw new Error('line'); }

        // check offset
        const markersBuffer = this.LineMarkers[line];
        if (offset < 0 || offset >= markersBuffer.Count) { throw new Error('offset'); }

        //
        markersBuffer.RemoveMarker(markersBuffer.Count - 1 - offset);
    };

    /// <summary>
    /// Fully clears markers from line
    /// </summary>
    public ClearMarkers (line): void {
    // check line index
        if (line < 0 || line >= this.Data?.Length) { throw new Error('line'); }

        // check offset
        const markersBuffer = this.LineMarkers[line];
        markersBuffer.ClearMarkers();
    };

    public InputParameter<T extends InputParameterBase>(InputParameters: T): void {
    // feel empty fields
        // InputParameters = new InputParameterClass(InputParameters);
        const name = InputParameters.Comment || InputParameters.VariableName;
        let value = '';
        if (Object.hasOwnProperty.call(this, InputParameters.VariableName)) { value = this[InputParameters.VariableName]; }

        const type = InputParameters.VariableType;

        this._IndParameterValueMap[name] = InputParameters.VariableName;

        const dp = new DynProperty(name, value, type, DynProperty.SCRIPT_PARAMETERS_GROUP);
        // if (  type === DynProperty.INTEGER || type === DynProperty.DOUBLE) {
        if (InputParameters instanceof InputParameterInteger || InputParameters instanceof InputParameterDouble) {
            dp.increment = InputParameters.Step;
            dp.decimalPlaces = InputParameters.Precision;
            dp.minimalValue = InputParameters.Minimum;
            dp.maximalValue = InputParameters.Maximum;
        }

        if (InputParameters instanceof InputParameterInteger)// принудительно сбрасываем в 0 для инта
        {
            dp.decimalPlaces = 0;
        }

        if (InputParameters instanceof InputParameterCombobox && InputParameters.Variants.length > 0) {
            const variantsArr: any[] = [];
            let i = 0;
            const len = InputParameters.Variants.length;
            for (i; i < len; i++) {
                const curVariant = InputParameters.Variants[i];
                const newItemName = Object.keys(curVariant)[0];
                const newItemValue = curVariant[newItemName];

                const newItem =
                {
                    value: newItemValue,
                    text: newItemName
                };
                variantsArr.push(newItem);
            }
            dp.objectVariants = variantsArr;
        }

        dp.sortIndex = InputParameters.InputNumber;

        this.FInputProperties.push(dp);
    };

    // TODO.
    public Properties (): any[] {
        const len = this.FInputProperties.length;
        for (let i = 0; i < len; i++) {
            const myProp = this.FInputProperties[i];
            const myPropName = myProp.name;
            const myValue = this._IndParameterValueMap[myPropName];
            myProp.value = this[myValue];
        }
        return this.FInputProperties;
    };

    // TODO.
    public callBack (props): boolean {
        let NeedRecalculate = false;
        const len = this.FInputProperties.length;
        for (let i = 0; i < len; i++) {
            const myProp = this.FInputProperties[i];
            const myPropName = myProp.name;
            const myValue = this._IndParameterValueMap[myPropName];

            const dp = DynProperty.getPropertyByName(props, myPropName);
            if (dp) {
                NeedRecalculate = (this[myValue] !== dp.value) || NeedRecalculate; // Було так: NeedRecalculate |= this[myValue] !== dp.value;
                this[myValue] = dp.value;
            }
        }

        if (NeedRecalculate) {
            const indArr = this.builtInIndicatorArray;
            if (indArr) { this.initInternalIndicators(); };

            this.IndicatorShortName(this.GetIndicatorShortName());
            this.UpdateIndexDrawBegin();
        // this.ParametersRecalculationCallBack();
        }

        return NeedRecalculate;
    };

    public GetIndicatorShortName (): string {
        return 'empty';
    };

    public UpdateIndexDrawBegin (): void {
    };

    public ParametersRecalculationCallBack (): void {
    };

    // TODO умнее пока названия не придумал
    public initInternalIndicators (): void {
        this.clearInternalIndicators();
        this.builtInIndicatorArray = this.createInternalIndicators() || [];

        const ci = this.CurrentData ? this.CurrentData.cashItem : null;
        const indArr = this.builtInIndicatorArray;

        if (!indArr || !ci) { return; };

        const symbol = ci.Instrument.GetInteriorID();
        const tfInfo = ci.TimeFrameInfo;

        // TODO. Built-in indicator init.
        for (let i = 0, len = indArr.length; i < len; i++) {
        // Taken from PTLIndicator.InitInternalIndicator
            const ind = indArr[i];
            ind.Parent = ci;
            ind.Symbol = symbol;
            ind.TimeFrameInfo = tfInfo.Copy();
        // TODO.
        // for the range calculation
        // indicator.MainChart = MainChart;
        // indicator.OnError += new ExceptionDelegate(SubIndicatorError);
        // ind.Refresh(ci.Count(), false)
        }
    };

    public AppendInternalIndicators (indicator, historicalData): void {
        const ci = historicalData ? historicalData.cashItem : null;

        const symbol = ci.Instrument.GetInteriorID();
        const tfInfo = ci.TimeFrameInfo;

        indicator.Parent = ci;
        indicator.Symbol = symbol;
        indicator.TimeFrameInfo = tfInfo.Copy();

        this.builtInIndicatorArray?.push(indicator);
    };

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
    };

    // MUST return an array of created indicators.
    public createInternalIndicators (): iBuildInIndicator[] {
        return [];
    };

    public NextBar (): void {
        const indArr = this.builtInIndicatorArray;
        if (!indArr) { return; }

        for (let i = 0, len = indArr.length; i < len; i++) {
            const ind = indArr[i];
            if (ind.NextBar) { ind.NextBar(); };
        }
    };

    public OnQuote (): void {
        const indArr = this.builtInIndicatorArray;
        if (!indArr) { return; }

        const ci = this.CurrentData ? this.CurrentData.cashItem : null;

        for (let i = 0, len = indArr.length; i < len; i++) {
            const ind = indArr[i];
            if (ind.OnQuote) { ind.OnQuote(ci); };
        }
    };

    // TODO. Refactor.
    public async RefreshPromise (): Promise<any> {
        const indArr = this.builtInIndicatorArray;
        if (!indArr) { await Promise.resolve(); return; }

        for (let i = 0, len = indArr.length; i < len; i++) {
            const ind = indArr[i];
            ind.FData.Clear();
        }

        for (let i = 0; i < this.FCloudBuffer.Count; i++) {
            this.FCloudBuffer.Clear(i);
        }

        await Promise.resolve();
    };

    public async AfterRefreshPromise (): Promise<void> {
        await Promise.resolve();
    };

    public Dispose (): void {
        this.Indicators = null;
        this.clearInternalIndicators();
    };

    public clearInternalIndicators (): void {
        const indArr = this.builtInIndicatorArray;
        if (indArr) {
            this.builtInIndicatorArray = null;
            for (let i = 0, len = indArr.length; i < len; i++) {
                const ind = indArr[i];
                if (ind.Dispose) { ind.Dispose(); };
            }
        }
    };

    // TODO. IMPORTANT. unsubscribe cash item logic for chart/indicators. HistoricalData disposal.
    public async GetHistoricalDataPromise (request: HistoricalDataRequest): Promise<any> {
        if (!this.CurrentData) { return await Promise.reject(); }
        const CurrentData = this.CurrentData;
        const ci = CurrentData ? CurrentData.cashItem : null;
        if (!ci) { return await Promise.reject(); }

        let tfInfo = ci.TimeFrameInfo;

        const additionalKey = request.GetAdditionalData() || tfInfo.AdditionalKey;

        tfInfo = tfInfo.Copy();
        tfInfo.Periods = request.Period * request.PeriodValue;
        tfInfo.HistoryType = request.DataType;
        tfInfo.ChartDataType = request.HistoryType;
        tfInfo.AdditionalKey = additionalKey;

        // TODO. UGLY.
        const ins = request.Instrument;
        const acc = CurrentData.Account;

        const historyParams = new ReloadHistoryParams();

        historyParams.updateWithProps({
            account: acc,
            instrument: ins,
            TimeFrameInfo: tfInfo,
            FromTime: request.fromUTC,
            ToTime: request.toUTC,
            UseDefaultInstrumentHistoryType: CurrentData.UseDefaultInstrumentHistoryType
        });

        const dataCache = ci.Parent.DataCache;
        if (!dataCache) { return await Promise.reject(); }

        try {
            const cashItem = await CashItem.CreateWithHistory(ins, historyParams);
            return cashItem
                ? HistoricalData.HistoricalDataFactory(this.CurrentData._holder, ins, cashItem)
                : null;
        } catch (error) {
            const ex = new CustomErrorClass('IndicatorScriptBase error', 'IndicatorScriptBase.GetHistoricalDataPromise', 'GetHistoricalDataPromise -> CashItem.CreateWithHistory');
            ErrorInformationStorage.GetException(ex);
            return null;
        }
    };

    public static GetLineTypesArray (): Array<{ value: number, style: string }> {
        const arr = LineStyleUtils.getCBItems();

        arr.push({ value: Pen.csHistogrammChart, style: 'js-StyleLine-histogramm' });
        // arr.push({ value: Pen.csArrowChart, style: "js-StyleLine-arrow" }); // пока не добавляем
        arr.push({ value: Pen.csHorisontalLineChart, style: 'js-StyleLine-horizontal_line' });
        arr.push({ value: Pen.csLadder, style: 'js-StyleLine-ladder' });

        return arr;
    };
}
