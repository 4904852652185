import { Control } from '../../elements/Control';
import { HeatmapFilterTemplate } from '../../../templates';

export class HeatmapFilter extends Control.extend({
    template: HeatmapFilterTemplate,
    data: function () {
        return {
            intervals: [],
            scaleMultiplierItems: [
                { text: 'x0.1', value: 0.1 },
                { text: 'x0.2', value: 0.2 },
                { text: 'x0.25', value: 0.25 },
                { text: 'x0.5', value: 0.5 },
                { text: 'x1', value: 1 },
                { text: 'x2', value: 2 },
                { text: 'x3', value: 3 },
                { text: 'x5', value: 5 },
                { text: 'x10', value: 10 }
            ],
            scaleMultiplierSelectedItem: null,
            scaleMultiplier: undefined,
            foregroundColor: ''
        };
    }
}) {
    oninit (): void {
        super.oninit();
        super.observe('scaleMultiplier', this.onScaleMultiplierChanged);
        super.on('scaleMultiplierSelectedItemChanged', this.onScaleMultiplierSelectedItemChanged);
    }

    private onScaleMultiplierChanged (): void {
        const items = super.get('scaleMultiplierItems');
        const scaleMultiplier = super.get('scaleMultiplier');
        for (let i = 0; i < items.length; i++) {
            if (items[i].value === scaleMultiplier) {
                super.set('scaleMultiplierSelectedItem', items[i]);
                break;
            }
        }
    }

    private onScaleMultiplierSelectedItemChanged (): void {
        const scaleMultiplierCombobox = super.findComponent('terceraComboBox');
        if (!isNullOrUndefined(scaleMultiplierCombobox)) {
            const selectedItem = scaleMultiplierCombobox.get('selectedItem');
            super.fire('scaleMultiplierChanged', selectedItem.value);
        }
    }
}
