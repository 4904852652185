export class PartialInterval {
    public readonly LeftBorder: number;
    public readonly RightBorder: number;
    public LeftColor: string;
    public IsSelected: boolean = true;

    constructor (leftBorder: number, rightBorder: number) {
        this.LeftBorder = leftBorder;
        this.RightBorder = rightBorder;
    }

    public get text (): string {
        let value: number;
        if (this.RightBorder === Number.POSITIVE_INFINITY) {
            value = this.LeftBorder * 1.2;
        } else if (this.LeftBorder === Number.NEGATIVE_INFINITY) {
            value = this.RightBorder * 1.2;
        } else {
            value = (this.LeftBorder + this.RightBorder) / 2;
        }
        return value.toString() + '%';
    }

    public contains (value: number): boolean {
        if (Math.abs(this.RightBorder) === Math.abs(this.LeftBorder)) {
            return value > this.LeftBorder && value < this.RightBorder;
        }
        if (value > 0) {
            return value >= this.LeftBorder && value < this.RightBorder;
        }
        return value > this.LeftBorder && value <= this.RightBorder;
    }

    public toString (): string {
        return `${this.LeftBorder} - ${this.RightBorder}:${this.LeftColor}`;
    }

    public equals (other: PartialInterval): boolean {
        if (other === null) {
            return false;
        }

        if (this === other) {
            return true;
        }

        return this.LeftBorder === other.LeftBorder && this.RightBorder === other.RightBorder;
    }
}
