// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
// https://tp.traderevolution.com/entity/109798

import { Resources } from "../../Commons/properties/Resources.ts"
import { MathUtils } from "../../Utils/MathUtils.ts"
import { SLTPTrigger } from "../../Utils/SlTpTrigger.ts"
import { SLTPTriggerControlTemplate } from "../../templates.js"
import { ControlsTypes } from "../UtilsClasses/FactoryConstants.ts"
import { ContainerControl } from "./ContainerControl.js"

export let SLTPTriggerControl = ContainerControl.extend({
    data: function ()
    {
        return {
            left: 224,
            right: 50,
            width: 130,

            triggersVisible: false,
            isForOE: false,      // if not it's control from TypesManager
            isForSL: null,       // trigger can be for SL or TP,  
            side: null,          // also it have to be one of OperationType (Buy or Sell)
            labelText: null,
            value: null,         // текущее значение определяющее состояния радиобатонов
            dynProperty: null,   // SLTPDynProperty when in OE
            itemSettings: null   // работает с TypesManager-ом, по сути itemSettings.sltpTriggerShort тоже что и value
        }
    },
    template: SLTPTriggerControlTemplate
})

SLTPTriggerControl.prototype.getType = function () { return ControlsTypes.SLTPTriggerControl; }

SLTPTriggerControl.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.apply(this);
    //dynProperty.sltpTriggerShort
    this.observe('value itemSettings.sltpTriggerShort', this.setRadioItemsFromSLTPTrigger)
    this.observe('side', this.onSideChange)
}

SLTPTriggerControl.prototype.oncomplete = function ()
{
    ContainerControl.prototype.oninit.apply(this);

    let rbGroup = this.Controls ? this.Controls.radioComboItemGroup : null
    if (rbGroup)
        rbGroup.SelectedValueChanged.Subscribe(this.onRadioButtonStateChanged, this)

    this.localize()
}

SLTPTriggerControl.prototype.dispose = function ()
{
    let rbGroup = this.Controls ? this.Controls.radioComboItemGroup : null
    if (rbGroup)
        rbGroup.SelectedValueChanged.UnSubscribe(this.onRadioButtonStateChanged, this)

    ContainerControl.prototype.dispose.apply(this);
}

SLTPTriggerControl.prototype.setRadioItemsFromSLTPTrigger = function (triggerShort)
{
    if (MathUtils.IsNullOrUndefined(triggerShort))
        return

    if (!this.trigger)
        this.trigger = new SLTPTrigger()

    let trigger = this.trigger
    trigger.FillFromShort(triggerShort)

    let rbGroup = this.Controls ? this.Controls.radioComboItemGroup : null
    if (rbGroup)
    {
        let propName = this.convertFromControlToPropertyName(),
            byBid = trigger[propName]   // true(byBid) or false(byAsk) of SLTPTrigger[propName]

        if (MathUtils.IsNullOrUndefined(byBid))     // цього не повинно статися, але залишу навсяк випадок
            return console.log('Wrong SLTPTrigger property name in SLTPTriggerControl.prototype.onRadioButtonStateChanged')

        rbGroup.setItems(+byBid, SLTPTriggerControl.CreateRBObjVars(byBid))
    }
}
SLTPTriggerControl.prototype.onRadioButtonStateChanged = function (rbGroup, val)
{
    if (!rbGroup) return

    let isForOE = this.get('isForOE'),
        outItem = this.get(isForOE ? 'dynProperty' : 'itemSettings'),
        short = outItem ? outItem.sltpTriggerShort : null

    if (MathUtils.IsNullOrUndefined(short))
        return

    if (!this.trigger)
        this.trigger = new SLTPTrigger()

    let tr = this.trigger.FillFromShort(short),
        propName = this.convertFromControlToPropertyName()

    if (MathUtils.IsNullOrUndefined(tr[propName]))      // цього не повинно статися, але залишу навсяк випадок
        return console.log('Wrong SLTPTrigger property name in SLTPTriggerControl.prototype.onRadioButtonStateChanged')

    tr[propName] = !!val

    let newValue = tr.GetShort()
    outItem.sltpTriggerShort = newValue
    this.set('value', newValue)
}

SLTPTriggerControl.prototype.onSideChange = function (side) 
{
    if (MathUtils.IsNullOrUndefined(side))
        return

    this.set('value', null)             // При смене значения Side сбрасываем выбранные ранее значения SL trigger\TP trigger на дефолтовые для данной стороны
}

SLTPTriggerControl.prototype.convertFromControlToPropertyName = function () // возвращает соответствующее контролу свойство из SLTPTrigger обьекта 
{
    return SLTPTrigger.GetPropertyName(this.get('side'), this.get('isForSL'))
}

SLTPTriggerControl.prototype.localize = function ()
{
    this.set('labelText', Resources.getResource(this.getLabelTextKey()))
}

SLTPTriggerControl.prototype.getLabelTextKey = function ()  // возвращает одну из строк: "property.OETPTrigger", "property.OESLTrigger" <- если контрол из OrderEntry
{                                                           // либо одну из "property.BuyTPTrigger", "property.BuySLTrigger", "property.SellTPTrigger", "property.SellSLTrigger" <- если контрол из TypesManagerScreen
    let isForOE = this.get('isForOE'),
        sideStr = this.getSideStr()

    let labelTextKey = 'property.' + (isForOE ? 'OE' : sideStr) + this.getSLTPStr() + 'Trigger'
    return labelTextKey
}

SLTPTriggerControl.prototype.getSLTPStr = function () { return SLTPTrigger.GetSLTPStr(this.get('isForSL')) }     // returns "SL" or "TP" depending on isForSL value
SLTPTriggerControl.prototype.getSideStr = function () { return SLTPTrigger.GetSideStr(this.get('side')) }        // returns "Buy" or "Sell" depending on the opposite side of order side

SLTPTriggerControl.CreateRBObjVars = function (byBid)
{
    let result = [
        { text: Resources.getResource("property.Bid"), value: 1, checked: false },
        { text: Resources.getResource("property.Ask"), value: 0, checked: false }
    ]

    if (!MathUtils.IsNullOrUndefined(byBid))
    {
        result[0].checked = !!byBid
        result[1].checked = !byBid
    }

    return result
}