// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';

import { DirectAccountOperationMessage } from '../../../../Utils/DirectMessages/DirectMessagesImport';
import { generateReportMessageHandler } from '../../../../Utils/AppHandlers.js';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class AccountOperationMessageProcessor extends MessageProcessorBase {
    public override decode (fs: PFixFieldSet): any[] {
        const message = new DirectAccountOperationMessage();

        //
        message.OperationID = fs.GetValue(FieldsFactory.FIELD_ACCOUNT_OPERATION_ID).toString();
        const value = fs.GetValue(FieldsFactory.FIELD_OPERATION_TYPE2);
        if (value) {
            message.OperationType = value;
        } else {
            message.OperationType = fs.GetValue(FieldsFactory.FIELD_OPERATION_TYPE);
        }

        message.CounterID = fs.GetValue(FieldsFactory.FIELD_COUNTER_ID);
        if (message.CounterID) {
            message.CounterID = message.CounterID.toString();
        }

        message.AccountID = fs.GetValue(FieldsFactory.FIELD_ACCOUNT_ID);
        if (message.AccountID) {
            message.AccountID = message.AccountID.toString();
        }

        message.Amount = fs.GetValue(FieldsFactory.FIELD_AMOUNT);
        message.Balance = fs.GetValue(FieldsFactory.FIELD_BALANCE);
        message.CreatedAt = fs.GetValue(FieldsFactory.FIELD_CREATED_AT);
        message.SettlementDate = fs.GetValue(FieldsFactory.FIELD_SETTLEMENT_DATE);
        message.Currency = fs.GetValue(FieldsFactory.FIELD_CURRENCY);
        message.Comment = fs.GetValue(FieldsFactory.FIELD_COMMENT);

        message.InstrumentName = fs.GetValue(FieldsFactory.FIELD_INSTRUMENT_NAME);
        message.InstrumentTradableId = fs.GetValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID);
        message.Quantity = fs.GetValue(FieldsFactory.FIELD_TOTAL_QUANTITY);
        message.OpenPrice = fs.GetValue(FieldsFactory.FIELD_OPEN_PRICE);
        message.ClosePrice = fs.GetValue(FieldsFactory.FIELD_PRICE);
        message.IsBuy = fs.GetValue(FieldsFactory.FIELD_IS_BUY);
        message.IsRemoved = fs.GetValue(FieldsFactory.FIELD_IS_REMOVED);

        return [generateReportMessageHandler.GenerateReportMessage(message)];// EventLog, DealTicket ;
    }
}
