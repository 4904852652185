// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

export enum DataCacheToolRayType {
    NoRay = 0,
    DoubleRay = 2,
    LeftRay = 3,
    RightRay = 4,
    Ray = 1
}

export enum DataCacheToolType {
    None = 46,
    TradingTool = 0,
    HorizontalLine = 1,
    VerticalLine = 2,
    Line = 3,
    Rectangle = 4,
    Triangle = 5,
    Polygon = 6,
    Circle = 7,
    Ellipse = 8,
    AndrewsPitchFork = 9,
    PriceChannel = 10,
    FibonacciRetracement = 11,
    FibonacciExpansion = 12,
    FibonacciArc = 13,
    FibonacciSpiral = 14,
    FibonacciEllipse = 15,
    FibonacciFans = 16,
    FibonacciPhiChannel = 17,
    FibonacciTimeGoal = 18,
    FibonacciTimeZone = 19,
    GannLine = 20,
    GannFan = 21,
    GannGrid = 22,
    InfoLine = 23,
    Symbol = 24,
    Text = 25,
    Arrow = 26,
    Uncpecified = 27,
    Label = 28,
    LineByAngle = 29,
    RegressionChanel = 30,
    ABCPatern = 31,
    PPZ = 32,
    TopBottomPattern = 33,
    ButterflyGartley = 34,
    ElliottImpulse = 35, // Elliott Waves #105458
    ElliottCorrection = 36,
    ElliottTriangle = 37,
    ElliottTripleCombo = 38,
    ElliottDoubleCombo = 39,
    FibonacciTools = 40,
    GannTools = 41,
    ElliottTools = 42,
    GeometryTools = 43,
    TrendTools = 44,
    SecondaryTools = 45
};

export const DataCacheToolTypeName = Object.values(DataCacheToolType);

export const GetToolTypesByGroup = (tooltype: DataCacheToolType): DataCacheToolType[] => {
    const T = DataCacheToolType;
    switch (tooltype) {
    case T.TrendTools:
        return [T.HorizontalLine, T.VerticalLine, T.Line, T.PriceChannel, T.AndrewsPitchFork];

    case T.GeometryTools:
        return [T.Triangle, T.Rectangle, T.Circle, T.Ellipse, T.Polygon];

    case T.FibonacciTools:
        return [T.FibonacciRetracement, T.FibonacciExpansion, T.FibonacciArc, T.FibonacciEllipse, T.FibonacciSpiral, T.FibonacciFans, T.FibonacciPhiChannel, T.FibonacciTimeGoal, T.FibonacciTimeZone];

    case T.ElliottTools:
        return [T.ElliottImpulse, T.ElliottCorrection, T.ElliottTriangle, T.ElliottTripleCombo, T.ElliottDoubleCombo];

    case T.GannTools:
        return [T.GannLine, T.GannFan, T.GannGrid];

    case T.SecondaryTools:
        return [T.InfoLine];

    default:
        return [];
    }
};

export const GetGroupByToolType = (tooltype: DataCacheToolType): DataCacheToolType => {
    switch (tooltype) {
    case DataCacheToolType.Line:
    case DataCacheToolType.VerticalLine:
    case DataCacheToolType.HorizontalLine:
    case DataCacheToolType.AndrewsPitchFork:
    case DataCacheToolType.PriceChannel:
        return DataCacheToolType.TrendTools;

    case DataCacheToolType.Triangle:
    case DataCacheToolType.Rectangle:
    case DataCacheToolType.Polygon:
    case DataCacheToolType.Circle:
    case DataCacheToolType.Ellipse:
        return DataCacheToolType.GeometryTools;

    case DataCacheToolType.FibonacciRetracement:
    case DataCacheToolType.FibonacciExpansion:
    case DataCacheToolType.FibonacciArc:
    case DataCacheToolType.FibonacciEllipse:
    case DataCacheToolType.FibonacciSpiral:
    case DataCacheToolType.FibonacciFans:
    case DataCacheToolType.FibonacciPhiChannel:
    case DataCacheToolType.FibonacciTimeGoal:
    case DataCacheToolType.FibonacciTimeZone:
        return DataCacheToolType.FibonacciTools;

    case DataCacheToolType.ElliottCorrection:
    case DataCacheToolType.ElliottImpulse:
    case DataCacheToolType.ElliottDoubleCombo:
    case DataCacheToolType.ElliottTripleCombo:
    case DataCacheToolType.ElliottTriangle:
        return DataCacheToolType.ElliottTools;

    case DataCacheToolType.GannFan:
    case DataCacheToolType.GannGrid:
    case DataCacheToolType.GannLine:
        return DataCacheToolType.GannTools;

    case DataCacheToolType.SecondaryTools:
        return DataCacheToolType.InfoLine;

    default:
        return DataCacheToolType.None;
    }
};

export enum SelectionModeEnum {
    All = 0,
    X = 1,
    Y = 2
};
