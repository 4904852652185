// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectExternalLinkRemoveMessage extends DirectMessageBaseClass {
    public Id: number | null = null;

    constructor () {
        super(Message.CODE_PFIX_EXTERNAL_LINK_REMOVE_MESSAGE);
    }
}
