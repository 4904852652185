// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
// TODO. IMPORTANT. Refactor all that shit.
// I don't like it anymore.
// SOLUTION. MOVE TO INOTIFYPROPERTYCHANGED PATTERN LIKE IN C# MVVM.
//
// There should be some sort of DEPENDENCY LOGIC BETWEEN ORDEREDIT'S PARAMETERS AND TRADINGDATA.
// IMPORTANT. Make "Trading Data" fields (instrument, account, quote, side, etc)
// become dynamic parameters of OrderEdit object,
// for instance like "limit price", "stop price" in StopLimitOrderEdit.
// (refactor it, i don't like logic of setTradingData(), updateParameters()).
//
// IMPORTANT. Come up with methods for quick assigning parameter's value
// without going through all that updateParameters() shit.

import { HistoryType } from '../../../../Utils/History/HistoryType';
import { TimeSpanPeriods } from '../../../../Utils/Time/TimeSpan';
import { Intervals } from '../../../../Utils/Instruments/Intervals';
import { Resources } from '../../../properties/Resources';
import { CustomEvent } from '../../../../Utils/CustomEvents';
import { OperationType } from '../../../../Utils/Trading/OperationType';
import { OrderType } from '../../../../Utils/Trading/OrderType';
import { Account } from '../../../../Commons/cache/Account';
import { MathUtils } from '../../../../Utils/MathUtils';
import { GeneralSettings } from '../../../../Utils/GeneralSettings/GeneralSettings';
import { OrderUtils } from '../../../../Utils/Trading/OrderUtils';
import { NumericUtils } from '../../../../Utils/NumericUtils';
import { Instrument } from '../../Instrument';
import { TradingLockUtils } from '../../../../Utils/TradingLockUtils';
import { IsAllowed } from '../../../IsAllowed';
import { OrderEditUpdateData } from '../../../../Utils/Trading/OrderEditUpdateData';
import { OrderEditBaseUtils, OrderEditRequestData } from '../../../../Utils/Trading/OrderEditBaseUtils';
import { InstrumentTypes } from '../../../../Utils/Instruments/InstrumentTypes';
import { OrderTif } from '../../../../Utils/Trading/OrderTifEnum';
import { PositionSizingEdit } from '../PositionSizingEdit';

// TODO. Use DynProperty.sortIndex for ordering.
// TODO. Throw exception (or something)
// if there's insufficient data for creating an order request:
// instrument, quote and whatnot is missing, for instance.
export class OrderEditBase {
    public instrument: Instrument | null = null;
    public account: Account | null = null;
    public side: any = null;
    public tif: any = null;
    public quantity: any = null;
    public disclosedQuantity: any = null;
    public quote: any = null;
    public placedFrom: any = null;
    public sltp: any = null;
    public productType: any = null;
    public leverageValue: any = null;
    public positionSizing: PositionSizingEdit;
    public customCSS: any = null;
    public parameterNameArray: any;
    public parameterNameSet: any;
    public parameterUpdateHandlerDict: any;
    public parameterDynPropertyGeneratorDict: any;
    public parameterRawValueGeneratorDict: any;
    public errorDict: any;
    public warningDict: any;
    public hasAnySMFlagsWarning: boolean = false; // Surveillance Measure warning #114474
    public hasWarnOnIdenticalOrder: boolean = false;// для метода needToShowWarningForcefully сохраняем есть ли у текущего ордера предупреждение об идентичности  (#110331)
    public previousOrderJSON: any = null; // #94309
    public previousOrderDate: any = null; // value of last order's Date.now() (ticks)
    public sessionSettings: any;
    public quoteCache: any;
    public orderEditValid: any = null;
    public ParametersChanged: CustomEvent;
    public ValidStateChanged: CustomEvent;
    public QuantityChanged: CustomEvent;
    public skipSessionSettingsChanged: boolean = false;
    public AlerEdit: boolean = false;
    public comment: any = null;
    public limitPrice: any = null;
    public stopPrice: any = null;
    public trailingStop: any = null;
    public order: any = null;
    public position: any = null;

    constructor (data) {
        this.positionSizing = new PositionSizingEdit();

        this.parameterNameArray = this.getParameterNameArray();
        this.parameterNameSet = this.getParameterNameSet();

        this.parameterUpdateHandlerDict = this.initParameterUpdateHandlerDict();
        this.parameterDynPropertyGeneratorDict = this.initParameterDynPropertyGeneratorDict();
        this.parameterRawValueGeneratorDict = this.initParameterRawValueGeneratorDict();

        this.errorDict = {}; // <parameter_name:error>
        this.warningDict = {}; // <parameter_name:warning>

        this.sessionSettings = GeneralSettings;
        GeneralSettings.SettingsChanged.Subscribe(this.sessionSettingsChanged, this);

        this.quoteCache = data.dataCache.FQuoteCache;

        this.ParametersChanged = new CustomEvent();
        this.ValidStateChanged = new CustomEvent();
        this.QuantityChanged = new CustomEvent();

        TradingLockUtils.TradingLock.TradingLockChanged.Subscribe(this.tryUpdateValidState, this);
        Account.TradeStatusChanged.Subscribe(this.tryUpdateValidState, this);
    }

    public static isDisclosedQuantityNeed (ins: Instrument | null, orderType: OrderType, tif): boolean {
        const t = ins?.InstrType;
        const allTypes = InstrumentTypes;
        const insSuitable = t === allTypes.EQUITIES || // #87051
                (ins?.TradingExchange === 'MCX' && (t === allTypes.FUTURES || t === allTypes.CFD_FUTURES || t === allTypes.OPTIONS)); // #107847

        const orderTypes = OrderType;
        const orderTypeSuitable = orderType === orderTypes.Market || orderType === orderTypes.Limit;

        const orderTifSuitable = tif.type === OrderTif.Day;

        return insSuitable && orderTypeSuitable && orderTifSuitable;
    }

    // TODO. Remove?
    public setBasePrice (price, isLimitModify: any = null, noNeedToSetDefaultOffset: any = null): any {

    }

    // TODO. Remove?
    public getBasePrice (): any {

    }

    public dispose (): void {
        GeneralSettings.SettingsChanged.UnSubscribe(this.sessionSettingsChanged, this);
        this.sessionSettings = null;

        TradingLockUtils.TradingLock.TradingLockChanged.UnSubscribe(this.tryUpdateValidState, this);
        Account.TradeStatusChanged.UnSubscribe(this.tryUpdateValidState, this);

        this.unsubscribeQuotes(this.instrument);
        this.quoteCache = null;

        this.errorDict = null;
        this.warningDict = null;

        this.account = null;
        this.instrument = null;
        this.quote = null;
        this.side = null;
        this.tif = null;
        this.quantity = null;
        this.comment = null;
    }

    public initParameterUpdateHandlerDict (): any {
        const resDict = {};

        for (const paramName in this.parameterNameSet) {
            const handlerFunctionName = 'update_' + paramName;
            const handler = this[handlerFunctionName];
            if (typeof (handler) !== 'function') {
                throw new Error(handlerFunctionName + ' function is not implemented.');
            }

            resDict[paramName] = handler;
        }

        return resDict;
    }

    public initParameterDynPropertyGeneratorDict (): any {
        const resDict = {};

        for (const paramName in this.parameterNameSet) {
            const handlerFunctionName = 'toDynProperty_' + paramName;
            const handler = this[handlerFunctionName];
            if (typeof (handler) !== 'function') {
                throw new Error(handlerFunctionName + ' function is not implemented.');
            }

            resDict[paramName] = handler;
        }

        return resDict;
    }

    public initParameterRawValueGeneratorDict (): any {
        const resDict = {};

        for (const paramName in this.parameterNameSet) {
            const handlerFunctionName = 'toRawValue_' + paramName;
            const handler = this[handlerFunctionName];
            if (typeof (handler) !== 'function') { throw new Error(handlerFunctionName + ' function is not implemented.'); };

            resDict[paramName] = handler;
        }

        return resDict;
    }

    // TODO. IMPORTANT. Refactor. setTradingData() - i don't like it.
    // Sets External data for trading (Instrument, account, etc).
    // Returns updated fields in a dictionary.
    public setTradingData (tradingDataDict): any {
        if (!tradingDataDict) return null;

        const newTradingDataDict: any = {};
        let updated = false;

        // TODO. Refactor. Details are at the top of the file.
        if ('instrument' in tradingDataDict) {
            let completed = true;
            if ('completed' in tradingDataDict) {
                completed = tradingDataDict.completed;
            }

            const eqIns = Instrument.IsEqualInstrument(this.instrument, tradingDataDict.instrument);
            if (!eqIns && completed || !this.quote) { this.unsubscribeQuotes(this.instrument); };

            this.instrument = tradingDataDict.instrument;
            this.quote = this.instrument?.LastQuote || this.instrument?.LastInvalidQuote;

            newTradingDataDict.instrument = this.instrument;
            newTradingDataDict.quote = this.quote;
            if (!eqIns && completed || !this.quote) { this.subscribeQuotes(this.instrument); };

            updated = true;
        }

        // TODO. Refactor. Details are at the top of the file.
        if ('quote' in tradingDataDict) {
            this.quote = tradingDataDict.quote;
            newTradingDataDict.quote = this.quote;
            updated = true;
        }

        if ('account' in tradingDataDict) {
            this.account = tradingDataDict.account;
            newTradingDataDict.account = this.account;
            updated = true;
        }

        if ('side' in tradingDataDict) {
            this.side = tradingDataDict.side;
            newTradingDataDict.side = this.side;
            updated = true;
        }

        if ('tif' in tradingDataDict) {
            this.tif = tradingDataDict.tif;
            newTradingDataDict.tif = this.tif;
            updated = true;
        }

        if ('quantity' in tradingDataDict) {
            this.quantity = tradingDataDict.quantity;
            newTradingDataDict.quantity = this.quantity;
            updated = true;
        }

        if ('placedFrom' in tradingDataDict) {
            this.placedFrom = tradingDataDict.placedFrom;
            newTradingDataDict.placedFrom = this.placedFrom;
            updated = true;
        }

        if ('productType' in tradingDataDict) {
            this.productType = tradingDataDict.productType;
            newTradingDataDict.productType = this.productType;
            updated = true;
        }

        if ('leverageValue' in tradingDataDict) {
            this.leverageValue = tradingDataDict.leverageValue;
            newTradingDataDict.leverageValue = this.leverageValue;
            updated = true;
        }

        if ('disclosedQuantity' in tradingDataDict) {
            this.disclosedQuantity = tradingDataDict.disclosedQuantity;
            newTradingDataDict.disclosedQuantity = this.disclosedQuantity;
            updated = true;
        }

        if ('positionSizingChecked' in tradingDataDict) {
            const pS = this.positionSizing;
            pS.isVisible = tradingDataDict.positionSizingChecked;
            newTradingDataDict.positionSizingChecked = pS.isVisible;
            updated = true;
        }

        if ('comment' in tradingDataDict) {
            this.comment = tradingDataDict.comment;
            newTradingDataDict.comment = this.comment;
            updated = true;
        }

        return updated
            ? newTradingDataDict
            : null;
    }

    // TODO. IMPORTANT. Refactor. setTradingData() - i don't like it.
    // Details are at the top of the file.
    // IMPORTANT. The only method for a consistent object update.
    public updateParameters (orderEditUpdateData): void {
        const newTradingDataDict = this.setTradingData(orderEditUpdateData.tradingDataDict);
        const sessChanged = orderEditUpdateData.sessionSettingsChanged;
        const newQuoteReceived = newTradingDataDict && 'quote' in newTradingDataDict;
        const dpDict = orderEditUpdateData.dpDict;
        // TODO. Refactor. Why only these fields? Rename variable.
        let validate = !!(newTradingDataDict || sessChanged || newQuoteReceived);

        const updatedParameterNameSet = {};
        for (const paramName in this.parameterNameSet) {
            const dp = dpDict ? dpDict[paramName] : null;

            const paramData = dp || validate
                ? new OrderEditParamUpdateData(dp, newTradingDataDict, sessChanged, orderEditUpdateData.placedFrom)
                : null;

            if (paramData && this.updateParameter(paramName, paramData)) {
                updatedParameterNameSet[paramName] = true;
                validate = true;
            }
        }

        // Nothing has changed - skipping validation step.
        if (!validate) return;

        // Merging updated parameters' names after validation.
        for (const paramName in this.validateParameters()) {
            updatedParameterNameSet[paramName] = true;
        };

        updatedParameterNameSet[OrderEditBaseUtils.SLTP_PARAM] = true;

        // Sending updates back to subscribers.
        const updatedParameterDict = {};
        for (const paramName in updatedParameterNameSet) {
            updatedParameterDict[paramName] = this.createDynPropertyFromParameter(paramName);
            const intervals = Intervals.GetIntervalsFromInstrument(this.instrument, NumericUtils.MAXVALUE);
            if (intervals) {
                updatedParameterDict[paramName].Intervals = intervals;
            };
        }
        this.ParametersChanged.Raise(updatedParameterDict);

        this.tryUpdateValidState();
    }

    public tryUpdateValidState (): void {
        const newOrderEditValid = this.valid();
        const newOrderEditValidSLTP = this.validSLTP();
        // if (this.account.AccountType !== AccountType.MultiAssetu)
        //     newOrderEditValid = newOrderEditValid && newOrderEditValidSLTP;
        if (this.orderEditValid === null || this.orderEditValid !== (newOrderEditValid && newOrderEditValidSLTP)) {
            this.orderEditValid = newOrderEditValid && newOrderEditValidSLTP;
            this.ValidStateChanged.Raise(this.orderEditValid);
        }
    }

    public updateParameter (paramName, paramUpdateData: OrderEditParamUpdateData | null): any {
        return this.parameterUpdateHandlerDict[paramName].call(this, paramUpdateData);
    }

    public createDynPropertyFromParameter (paramName): any {
        const dp = this.parameterDynPropertyGeneratorDict[paramName]?.call(this);
        if (!dp) {
            return null;
        }

        dp.name = paramName;
        if (!this.skipParamForFlashing(paramName)) {
            dp.customCSS = this.customCSS;
        };

        return dp;
    }

    // TODO. Refactor. Rename?
    public getParameterRawValue (paramName): any {
        return this.parameterRawValueGeneratorDict[paramName].call(this);
    }

    // Returns a set of updated parameters' names after validation.
    // TODO. Rename? I'm not sure "validate" is a right word.
    // Method is supposed to check and fix relationships
    // between parameters and trading data
    // ONCE PARAMETERS ARE IN AN UPDATED/ACTUAL STATE.
    // It's more like a second pass, ultimate update ALL parameters call once again.
    public validateParameters (): any {
        return {};
    }

    public getParameterNameSet (): any {
        const paramNameArr = this.getParameterNameArray();
        const paramNameSet = {};

        for (let i = 0, len = paramNameArr.length; i < len; i++) {
            paramNameSet[paramNameArr[i]] = true;
        }

        return paramNameSet;
    }

    public getParameterNameArray (): any[] {
        throw new Error('Not implemented.');
    }

    // TODO. Refactor. Same as OrderTypeBase.Id function.
    public getOrderTypeId (): OrderType {
        throw new Error('Not implemented.');
    }

    public getOrderTypeIdValidation (): OrderType {
        return this.getOrderTypeId();
    }

    public getConfirmationData (): OrderEditConfirmationData {
        const data = new OrderEditConfirmationData();
        data.text = this.getConfirmationText();
        data.text += this.account?.GetCommissionInfo(this.instrument); // #111865

        data.warningArray = this.getWarningArray();
        data.needShowWarningForce = this.needToShowWarningForcefully(); // проверяем есть ли там такие ворнинги, что должны игнорировать откл.настройку "Warn if wrong order" и отображать предупреждение #110331
        return data;
    }

    public getConfirmationText (): string {
        throw new Error('Not implemented.');
    }

    public isPosition (): boolean {
        return false;
    }

    // Combine with tradingAllowed()?
    public valid (): boolean {
        return !TradingLockUtils.TradingLock.tradingLocked &&
            this.tradingAllowed() &&
            (Object.keys(this.errorDict).length === 0);
    }

    public validSLTP (): boolean {
        return this.sltp ? this.sltp.valid() : false;
    }

    public getRawSLTP (): any {
        return this.sltp.getRawValue(this.getTradingData());
    }

    public getLeverageValue (): any {
        return this.leverageValue;
    }

    // TODO.
    public tradingAllowed (): boolean {
        const acc = this.account;
        const ins = this.instrument;

        if (!acc || !ins) { return false; }

        return IsAllowed.IsTradingAllowed([acc], ins, this.getOrderTypeIdValidation(), null, null, this.isPosition()).Allowed;
    }

    // error: null - removes error.
    // returns true - if error is new/changed/removed.
    public setParameterError (paramName, error): boolean {
        const errorDict = this.errorDict;

        const oldError = errorDict.hasOwnProperty(paramName)
            ? errorDict[paramName]
            : null;

        if (error === oldError) {
            return false;
        }

        if (error === null) {
            delete errorDict[paramName];
        } else {
            errorDict[paramName] = error;
        }

        return true;
    }

    public getParameterError (paramName): any {
        return this.errorDict[paramName] || null;
    }

    // warning: null - removes warning.
    // returns true - if warning is new/changed/removed.
    public setParameterWarning (paramName, warning): any {
        const warningDict = this.warningDict;

        const oldWarning = warningDict.hasOwnProperty(paramName)
            ? warningDict[paramName]
            : null;

        if (warning === oldWarning) {
            return false;
        }

        if (warning === null) {
            delete warningDict[paramName];
        } else {
            warningDict[paramName] = warning;
        }

        return true;
    }

    public getParameterWarning (paramName): any {
        return this.warningDict[paramName] || null;
    }

    public needToShowWarningForcefully (): any { // some warnings ignore the warnOnWrongOrder setting #110331
        return this.hasWarnOnIdenticalOrder || !!this.warningDict[OrderEditBaseUtils.LIMIT_PRICE_PARAM_CHANGE_FROM_LAST_PRICE] ||
            this.hasAnySMFlagsWarning;
    }

    public getWarningArray (): any[] {
        const warningArr: any[] = [];

        const warningDict = this.warningDict;
        const paramNameArr = this.parameterNameArray;
        const warnOnWrongOrder = GeneralSettings.Warnings?.needToShowWarning(); // #110331 не добавляем warning в warningArray, если настройка warnOnWrongOrder отключена, но предупреждение отобразятся, поскольку есть другие типы предупреждений, игнорирующие настройку

        for (let i = 0, len = paramNameArr.length; i < len; i++) {
            const paramName = paramNameArr[i];
            const warning = warningDict[paramName];
            const needToAdd = warnOnWrongOrder || paramName == OrderEditBaseUtils.LIMIT_PRICE_PARAM_CHANGE_FROM_LAST_PRICE;/* || тут должны быть и другие типы игнорирующие настройку warnOnWrongOrder но пока таких нет (не считая isIdenticalWithPrevious - отсутствующий в parameterNameArray и учитываемый в this.hasWarnOnIdenticalOrder) */
            if (warning && needToAdd) {
                warningArr.push(warning);
            }
        }

        if (this.isIdenticalWithPrevious()) {
            warningArr.push(this.getTextForIdenticalOrderWarning());
            this.hasWarnOnIdenticalOrder = true;
        } else {
            this.hasWarnOnIdenticalOrder = false;
        }
        if (this.hasSMFlagsWarning()) {
            warningArr.push(this.getSMFlagsWarningText());
            this.hasAnySMFlagsWarning = true;
        } else {
            this.hasAnySMFlagsWarning = false;
        }

        return warningArr;
    }

    public getSMFlagsWarning (): string {
        const ins = this.instrument;
        const flags = ins ? ins.GetExtSMFlags() : {};

        let result = '';
        for (const flag in flags) {
            const flagValue = ins?.GetExtSMFlagByKey(flag);

            if (Instrument.ShownSMFlagValue(flags[flag], flag)) {
                result += (result ? ', ' : '') + flagValue;
            }
        }

        return result;
    }

    public hasSMFlagsWarning (): boolean {
        return !!this.getSMFlagsWarning();
    }

    public getSMFlagsWarningText (): string {
        const result = this.getSMFlagsWarning();

        if (!result) {
            return '';
        }

        const textToInsert = ' - ' + result;
        const text = Resources.getResource('FlagSM.Message');
        return text.replace('{0}', textToInsert);
    }

    public getTextForIdenticalOrderWarning (): string {
        const text = Resources.getResource('general.WarnIfIdenticalOrdertText');

        return text.replace('{1}', GeneralSettings.Warnings?.WarnIfIdenticalOrderTime.toString());
    }

    public isIdenticalWithPrevious (): boolean {
        const warningSessionSettings = GeneralSettings.Warnings;

        if (!warningSessionSettings?.WarnIfIdenticalSubsequentOrderIsPlaced) {
            return false;
        };

        const prevOrder = this.previousOrderJSON;
        const thisOrder = this.getBaseCopyInJson();
        const thisOrderTime = Date.now();
        const timeLessToWarn = this.previousOrderDate &&
                (thisOrderTime - this.previousOrderDate < warningSessionSettings.WarnIfIdenticalOrderTime * TimeSpanPeriods.TicksPerSecond);

        const needToShowWarning = prevOrder == thisOrder && timeLessToWarn;

        this.previousOrderJSON = thisOrder;
        this.previousOrderDate = thisOrderTime;

        return needToShowWarning;
    }

    public getBaseCopyInJson (): string {
        const baseOrderEditObj =
        {
            account: this.account ? this.account.AcctNumber : null,
            instrument: this.instrument ? this.instrument.GetInteriorID() : null,
            orderType: this.getOrderTypeId(),
            side: this.side,
            quantity: this.quantity,
            comment: this.comment
        };

        return JSON.stringify(baseOrderEditObj);
    }

    public createDynPropertyArrayFromParameters (): any {
        const self = this;
        return self.parameterNameArray.map(function (paramName) {
            return self.createDynPropertyFromParameter(paramName);
        });
    }

    // TODO. Rename.
    public getDataForRequest (): OrderEditRequestData {
        const res = new OrderEditRequestData();

        res.orderTypeId = this.getOrderTypeId();
        res.instrument = this.instrument;
        res.account = this.account;
        res.quote = this.quote;
        res.side = this.side;
        res.tif = this.tif;
        res.quantity = this.quantity;
        res.parameterDict = this.getRawValueDictFromParameters();
        res.placedFrom = this.placedFrom;
        res.productType = this.productType;
        res.leverageValue = this.leverageValue;

        // if (OrderEditBase.isDisclosedQuantityNeed(res.instrument, res.orderTypeId, res.tif)) {
        res.disclosedQuantity = this.disclosedQuantity ?? this.quantity;
        // }

        res.comment = this.comment;

        return res;
    }

    // <parameter name, parameter value>
    public getRawValueDictFromParameters (): any {
        const resDict = {};

        for (const paramName in this.parameterNameSet) {
            resDict[paramName] = this.getParameterRawValue(paramName);
        };

        return resDict;
    }

    public sessionSettingsChanged (): void {
        if (this.skipSessionSettingsChanged) {
            return;
        }

        this.updateParameters(new OrderEditUpdateData(null, null, true));
    }

    // #region Quote

    // TODO. Refactor. Details are at the top of the file.
    public newQuote (quote): void {
        if (quote && quote.Type === HistoryType.QUOTE_INSTRUMENT_DAY_BAR) {
            return;
        }
        this.updateParameters(new OrderEditUpdateData(null, { quote }, false));
    }

    // TODO. Refactor.
    public subscribeQuotes (instrument: Instrument | null): void {
        if (!instrument) return;
        this.quoteCache.addListener(instrument, this, HistoryType.QUOTE_LEVEL1);
    }

    // TODO. Refactor.
    public unsubscribeQuotes (instrument) {
        if (!instrument) return;
        this.quoteCache.removeListener(instrument, this, HistoryType.QUOTE_LEVEL1);
    }

    // #endregion Quote

    public buy (): boolean {
        return this.side === OperationType.Buy;
    }

    // TODO. Refactor.
    public getTradingData (): any {
        return {
            instrument: this.instrument,
            account: this.account,
            quote: this.quote,
            side: this.side,
            tif: this.tif,
            quantity: this.quantity,
            sessionSettings: this.sessionSettings,
            limitPrice: this.limitPrice,
            stopPrice: this.stopPrice,
            trailingStop: this.trailingStop,
            disclosedQuantity: this.disclosedQuantity,
            leverageValue: this.leverageValue,
            productType: this.productType,
            OrderType: this.getOrderTypeId(),
            comment: this.comment,
            isPosition: this.isPosition()
        };
    }

    // Ugly.
    public fireParameterChanged (paramName): void {
        const dp = this.createDynPropertyFromParameter(paramName);
        if (!dp) return;

        const dict = {};
        dict[paramName] = dp;

        this.ParametersChanged.Raise(dict);
    }

    public setBreakevenPrice (price): void // вызывается при нажатии на стрелочку-кнопку QuotePricePicker a.k.a Breakeven
    {
        this.setBasePrice(price);
    }

    public getLimitPriceWithDefaultOffset (price, forceIsBuy: boolean | undefined = undefined): any // forceIsBuy - for OCOCustomOrdersEdit
    {
        const trSet = GeneralSettings.TradingDefaults;
        const ins = this.instrument;
        const isBuy = forceIsBuy ?? this.buy();
        const sign = isBuy ? 1 : -1;
        let ticks = trSet?.LimitOffsetTicks;

        if (GeneralSettings.TradingDefaults?.IsTicksFractionalForForex()) {
            ticks = OrderUtils.ConvertTickOffset(ins, trSet?.ShowOffsetIn, price, ticks) * Math.sign(ticks);
        }

        return ins?.CalculatePrice(price, sign * ticks);
    }

    public onUpdateAccount (acc: Account): void {
        const posSizing = this.positionSizing;
        if (!posSizing?.isVisible) {
            return;
        }

        this.recalcQtyForPositionSizing();

        this.updateParameters(posSizing.getAvailableFundsUpdateData()); // #100986
    }

    //                  POSITION SIZING REGION
    public update_positionSizing (updateData): boolean {
        const updated = this.positionSizing.update(updateData);

        if (updated) {
            this.recalcQtyForPositionSizing();
        }

        return updated;
    }

    public recalcQtyForPositionSizing (): void {
        const trData = this.getTradingData();
        const ins = trData.instrument;
        const isBuy = trData.side === OperationType.Buy;
        const qty = trData ? trData.quantity : null;
        const price = this.getBasePrice();
        const sltp = this.sltp;
        if (qty && sltp) {
            const sl = sltp.sl; let slPrice = 0; let offsetType;
            if (sltp.useTrailingStop()) {
                slPrice = sltp.trailingStop.value;
                offsetType = sltp.trailingStop.offsetType;
            } else if (sl) {
                slPrice = sl.value;
                offsetType = sl.offsetType;
                if (GeneralSettings.TradingDefaults?.UseStopLimitInsteadStop) {
                    slPrice = offsetType != null ? (sl.value + sl.sllValue) : sl.sllValue;
                }
            }

            const newQtyValue = this.positionSizing.calculateQty(price, slPrice, qty.inLots, isBuy, offsetType, this.getOrderTypeId() === OrderType.Market, this.getOrderTypeId() === OrderType.TrailingStop);

            if (newQtyValue !== null && MathUtils.TruncateDouble(newQtyValue, 2) != qty.value) {
                this.QuantityChanged.Raise(newQtyValue);
            }
        }
    }

    public toDynProperty_positionSizing (): any {
        return this.positionSizing.getDynProperty();
    }

    public toRawValue_positionSizing (): any {
        return this.positionSizing.getRawValue();
    }

    public toDynProperty_sltp (): any {
        return this.sltp.getDynProperty(this.getTradingData());
    }

    public toRawValue_sltp (): any {
        return this.sltp.getRawValue(this.getTradingData());
    }

    public update_sltp (updateData): any {
        return this.sltp.update(updateData, this.getTradingData());
    }

    public enableSL (): void // принудительно включить SL (например при включении positionSizing)
    {
        const sltp = this.sltp;
        if (!sltp) return;
        sltp.enableSL();

        this.fireParameterChanged(OrderEditBaseUtils.SLTP_PARAM);
    }

    public skipParamForFlashing (paramName): boolean // при включенном positionSizing надо ли DynPropertyControl c paramName пропускать при подсветке лейблов (к примеру как Stop price не подсвечиваем у Stop limit-a)
    {
        return false;
    }

    public getWarningNumeric (): any // for numeric focusing when it has warning after click button in confirmation screen of place/modify order
    {
        const warningDict = this.warningDict;
        let numericLinkKey: any = null; let dpControlName: any = null;

        if (!warningDict) {
            return;
        }

        if (warningDict[OrderEditBaseUtils.LIMIT_PRICE_PARAM] ||
            warningDict[OrderEditBaseUtils.LIMIT_PRICE_PARAM_CHANGE_FROM_LAST_PRICE]) {
            numericLinkKey = NumericLinks.LimitPrice;
            dpControlName = OrderEditBaseUtils.LIMIT_PRICE_PARAM;
        }

        if (warningDict[OrderEditBaseUtils.STOP_PRICE_PARAM]) // если warning в обоих фокус установится на stop поскольку он всюду идет раньше limit
        {
            numericLinkKey = NumericLinks.StopPrice;
            dpControlName = OrderEditBaseUtils.STOP_PRICE_PARAM;
        }

        return {
            numericLinkKey,
            dpControlName
        };
    }

    public Copy () {
        const dc = this.instrument.DataCache;
        const type = dc.OrderParameterContainer.GetOrderType(this.getOrderTypeId());
        const newobj = type.createOrderEditObject({ dataCache: dc });
        newobj.setTradingData(this.getTradingData());

        Object.assign(newobj, this);

        return newobj;
    }
}

export enum NumericLinks {
    LimitPrice = 'PriceNumericLink',
    StopPrice = 'StopPriceNumericLink',
    Quantity = 'QNumericLink',
    StopLoss = 'SLNumericLink',
    TakeProfit = 'TPNumericLink',
    ProductType = 'MQNumericLink'
}

class OrderEditParamUpdateData {
    public dp: any;
    public newTradingDataDict: any;
    public sessionSettingsChanged: any;
    public placedFrom: any;

    constructor (dp, newTradingDataDict, sessionSettingsChanged, placedFrom) {
        this.dp = dp;
        this.newTradingDataDict = newTradingDataDict;
        this.sessionSettingsChanged = sessionSettingsChanged;
        this.placedFrom = placedFrom;
    }
}

class OrderEditConfirmationData {
    public text: any;
    public warningArray: any;
    public needShowWarningForce: boolean;

    constructor () {
        this.text = null;
        this.warningArray = null;
        this.needShowWarningForce = false;
    }
}
