// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type ClusterNodeGroup } from '../Groups/ClusterNodeGroup';

export class ClusterNodeGroupContainer {
    private readonly _clusterNodeGroups: ClusterNodeGroup[];
    constructor (clusterNodeGroups: ClusterNodeGroup[]) {
        this._clusterNodeGroups = clusterNodeGroups;
    }

    public getClusterNodeGroupsByMode (mode: number): ClusterNodeGroup[] {
        return this._clusterNodeGroups.filter((clusterNodeGroup: ClusterNodeGroup) => {
            return clusterNodeGroup.getConnectionMode() === mode;
        });
    }

    public getClusterNodeGroupsById (mode: number, id: number): ClusterNodeGroup | undefined {
        const groupsByMode = this.getClusterNodeGroupsByMode(mode);
        if (groupsByMode.length === 0) return undefined;
        return this._clusterNodeGroups.find((clusterNodeGroup: ClusterNodeGroup) => clusterNodeGroup.getNodeID() === id);
    }
}
