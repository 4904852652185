import { type Point } from '../../Commons/Geometry';
import { type Font } from '../../Commons/Graphics';
import { type Account } from '../../Commons/cache/Account';
import { type Instrument } from '../../Commons/cache/Instrument';
import { type TerceraChartBase } from '../TerceraChartBase';
import { type LayersEnum } from './LayersEnum';
import { type TerceraChartPriceScaleLayoutInfo } from './TerceraChartPriceScaleLayoutInfo';

export class TerceraChartAdvancedParams {
    Font: Font;
    CursorPosition: Point;
    HoverWindowXValue: number;
    HoverWindowYValue: number;
    TerceraChart: TerceraChartBase;
    DrawPointers: any[] = [];
    LayoutInfo: TerceraChartPriceScaleLayoutInfo;
    layers: any;
    Tag: any;
    layerId: LayersEnum;
    exitAfterFirst: boolean | null;
    isNeedConnectFirstAndLastPoints: boolean;

    Instrument: Instrument;
    Account: Account;
    mainPriceRenderer: any;
}
