// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { OEQuantityTemplate } from "../../../templates.js";
import { ContainerControl } from "../../elements/ContainerControl.js";
import { Resources } from "../../../Commons/properties/Resources.ts";
import { Quantity } from "../../../Utils/Trading/Quantity.ts";
import { ControlsTypes } from "../../UtilsClasses/FactoryConstants.ts";

export let OEQuantity = ContainerControl.extend({
    template: OEQuantityTemplate,
    data: function ()
    {
        return {
            label: '',
            instrument: null,
            account: null,
            value: null,
            inLots: false,
            quantity: null,
            // TODO. Ugly. Remove.
            defaultQuantity: null,

            showLabel: true,
            noArrowCB: false,
            positionSizingVisible: false,
            positionSizingChecked: false
        }
    }
})

OEQuantity.prototype.getType = function () { return ControlsTypes.OEQuantity; };

OEQuantity.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.call(this)

    this.observe('inLots value', this.updateQuantity)

    Resources.onLocaleChange.Subscribe(this.localize, this)
    this.localize()
}

OEQuantity.prototype.dispose = function ()
{
    Resources.onLocaleChange.UnSubscribe(this.localize, this)

    ContainerControl.prototype.dispose.call(this)
}

OEQuantity.prototype.setFocus = function ()
{
    ContainerControl.prototype.setFocus.apply(this);
    this.Controls.QN.setFocus()
};

OEQuantity.prototype.updateQuantity = function ()
{
    let value = this.get('value')
    let inLots = this.get('inLots')
    this.set('quantity', new Quantity(value, inLots))
}

OEQuantity.prototype.localize = function ()
{
    this.set('label', Resources.getResource('panel.newOrderEntry.amountLabel'))
}