// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DataCacheToolType } from '../DataCacheToolEnums';
import { DataCacheTool } from '../DataCacheTool';
import { CustomEvent } from '../../../Utils/CustomEvents';

/**
 * базовая для всех торговых
 */
export class TradingToolBaseDataCacheTool extends DataCacheTool {
    OnUpdate: CustomEvent;

    constructor () {
        super();
        this.ToolType = DataCacheToolType.TradingTool;
        this.OnUpdate = new CustomEvent();
        this.InitPoints(1);
    }

    /**
     * Gets the minimum points count required for the tool
     * @returns {number} - Minimum points count
     */
    MinPointsCount (): number {
        return 1;
    }
}
