// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { PFixFieldSet } from '../Fields/FieldsImport';
import { FieldsFactory } from '../Factory/FieldsFactory';

export class WarningForChangeFromLastPriceGroup extends PFixFieldSet {
    constructor (pfixFieldSet) {
        super(FieldsFactory.WARNING_FOR_CHANGE_FROM_LAST_PRICE_GROUP, null, pfixFieldSet);
    }

    public getPriceLowLimit (): number {
        return this.GetValue(FieldsFactory.FIELD_PRICE_LOW_LIMIT) || 0;
    }

    public getLastChange (): number {
        return this.GetValue(FieldsFactory.FIELD_LAST_CHANGE);
    }
}
