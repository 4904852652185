// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomErrorClass, ErrorInformationStorage } from "../../Commons/ErrorInformationStorage.ts";
import { Resources } from "../../Commons/properties/Resources.ts";
import { HistoryType } from "../../Utils/History/HistoryType.ts";
import { LayersEnum } from "../../Chart/Renderers/TerceraChartBaseRenderer.ts";
import { DataCacheToolType } from "../../Commons/cache/DataCacheToolEnums.ts";
import { TerceraMessageBox } from "../screen/TerceraMessageBox.js";
import { TerceraChartAction, TerceraChartActionEnum, TerceraChartToolbarsEnum } from "../../Chart/TerceraChartAction.ts";
import { LinkedSystem, LinkedSystemAccLinkingValue } from "../misc/LinkedSystem.ts";
import { ModelDataType, TerceraChartMVCCommand } from "../../Chart/TerceraChartMVC.ts";
import { ChartHistoryType, TerceraChartDrawingType, TerceraChartTradingOperation } from "../../Chart/Utils/ChartConstants.ts";
import { TerceraChartUtils, TerceraChartHistoryType } from "../../Chart/TerceraChartUtils.ts";
import { KeyCode, KeyEventProcessor } from "../../Commons/KeyEventProcessor.ts";
import { contextMenuHandler } from "../../Utils/AppHandlers.js";
import { TerceraIndicatorLookupDropDownForm } from "../elements/Lookup/TerceraIndicatorLookupDropDownForm.js";
import { ChartPanelTemplate } from "../../templates.js";
import { Control } from "../elements/Control.js";
import { TerceraLinkControlConstants } from "../UtilsClasses/TerceraLinkControlConstants.ts";
import { TerceraQuantityNumeric } from "../elements/TerceraQuantityNumeric.js";
import { TerceraVisualTradingWidget } from "../elements/TerceraVisualTradingWidget.js";
import { EditPositionScreen } from "../screen/EditPositionScreen.js";
import { ModifyOrderScreen } from "../screen/ModifyOrderScreen.js";
import { TerceraAddOverlayScreen } from "../screen/TerceraAddOverlayScreen.js";
import { PanelNames } from "../UtilsClasses/FactoryConstants.ts";
import { LookupDropDownShowParams } from ".././UtilsClasses/LookupDropDownShowParams.js";
import { WINDOW_SIDE_BORDERS_WIDTH } from "../UtilsClasses/SizeConstants.ts";
import { ApplicationPanelNew } from "./ApplicationPanelNew.js";
import { CustomEvent } from "../../Utils/CustomEvents.ts";
import { Periods } from "../../Utils/History/TFInfo.ts";
import { OperationType } from "../../Utils/Trading/OperationType.ts";
import { OrderType } from "../../Utils/Trading/OrderType.ts";
import { SlTpPriceType } from "../../Utils/Enums/Constants.ts";
import { PlacedFrom } from "../../Utils/Trading/PlacedFrom.ts";
import { DynProperty } from "../../Commons/DynProperty.ts";
import { IndicatorManager } from "../../Commons/cache/indicators/IndicatorManager.ts";
import { SLTPTriggerUtils } from "../../Commons/cache/OrderParams/SLTPTriggerUtils.ts";
import { GeneralSettings } from "../../Utils/GeneralSettings/GeneralSettings.ts";
import { OrderUtils } from "../../Utils/Trading/OrderUtils.ts";
import { TradingLockUtils } from "../../Utils/TradingLockUtils.ts";
import { Quantity } from "../../Utils/Trading/Quantity.ts";
import { InstrumentUtils } from "../../Utils/Instruments/InstrumentUtils.ts";
import { DataCache } from "../../Commons/DataCache.ts";
import { SessionSettings } from "../../Commons/SessionSettings.ts";
import { PositionEdit } from "../../Commons/cache/OrderParams/PositionEdit.ts";
import { TradingNumericErrorChecker } from "../../Commons/Trading/TradingNumericErrorChecker.ts";
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";
import { ITerceraChartShowScreenType } from "../../Chart/ITerceraChartShowScreenType.ts";
import { TerceraChartActionProcessor } from "../../Chart/TerceraChartActionProcessor/TerceraChartActionProcessor.ts";
import { PanelSettingsScreen } from "../screen/PanelSettingsScreen.js";
import { ChartTradingCore } from "../../Utils/Trading/ChartTradingCore.ts";
import { TerceraChartToolsPanel } from "../elements/TerceraChartToolsPanel.ts";
import { AccountMenuItemsHelper } from '../../Commons/AccountWidget/AccountMenuItemsHelper.ts';
import { FullScreenManager } from "../UtilsClasses/FullScreenManager.ts";

export let ChartPanel = ApplicationPanelNew.extend({
    Name: 'ChartPanel',
    data: function ()
    {
        return {
            showFullscreenModeButton: true,
            isAccountLinkShow: true,
            isSymbolLinkShow: true,
            instrumentItem: null,
            accountItem: null,

            isShowOE: false,
            oeSmall: true,

            showToolsPanel: false,
            showMainToolBarPanel: true,
            showAnalyzeToolsPanel: false,
            showAccountLookUp: true,
            isVisibleMainToolBar: true,
            tradingLocked: false,

            canFilterByAccount: false,

            showOverlayButton: true,

            dataSourceOpen: false,
            dataSourceVisible: true,    // true to enable data source
            dataSourceBottom: 5,

            hasCorrectData: false,

            verticalToolsPanel: true,
            leftOrTopToolsPanel: true,

            addWidgets: true,
            minSizeForToolBars: 200
        };
    },
    computed: {
        terceraChartPanelContext: {
            get: function () { return this; },
            set: function (value) { }
        }
    },
    partials: {
        bodyPartial: ChartPanelTemplate
    },
    terceraChartRactive: null,
    chartOE: null,
    needUpdateLimitStopNumerics: false,
    ModelChanged: null,
    myVisualWidget: null,
    myTradeNumeric: null,                                           // нумерик для редактирования quantity размещенных на Chart-e ордеров
    myTradeNumericPromise: null,
    initialTemplateSelected: false,
    _terceraChart: null,
    isOpenedTradingWidget: false
});

ChartPanel.prototype.getType = function () { return PanelNames.ChartPanel };

ChartPanel.prototype.oninit = function ()
{
    ApplicationPanelNew.prototype.oninit.apply(this);

    this.on('indicatorButton_btnClick', this.onIndicatorBtnClick);
    this.on('mouseTradeButton_btnClick', this.mouseTradeButton_btnClick);

    this.observe('instrumentItem', this.onInstrumentItemChanged);
    this.observe("accountItem", this.onAccountItemChanged);

    this.on("timeFrameComboBox_ComboItemClicked", this.onTimeFrameComboBox_ComboItemClicked);
    this.on('drawingStyleComboBox_ComboItemClicked', this.onDrawingStyleComboBox_ComboItemClicked);
    this.on("loadRangeComboBox_ComboItemClicked", this.onLoadRangeComboBox_ComboItemClicked);

    this.observe('showToolsPanel isShowOE oeSmall',
        this.layoutTable,
        { oninit: false });
    this.observe('showMainToolBarPanel',
        (newValue) =>
        {
            const lastVisible = this.get('isVisibleMainToolBar');
            this.set({ isVisibleMainToolBar: lastVisible && newValue });
            this.layoutTable();
        },
        { oninit: false });

    this.on('overlay_btnClick', this.overlay_btnClick);

    this.ModelChanged = new CustomEvent();

    //TODO
    //DataCache.isAllowCache.GlobalWebRulesChanged.Subscribe(this.updateTimeFrameComboBox, this);

    let hideOE = Resources.isHidden('chart.ChartSymbolSelector.oeButton');
    this.set('showOverlayButton', !Resources.isHidden('chart.ChartSymbolSelector.OverlayTooltip'));
    this.set('isHiddenMouseTradeButton', !Resources.isHidden('chart.ChartSymbolSelector.mouseTradeButton'));
    this.set('isHiddenOEButton', !hideOE);

    // У когото может быть открыто ОЕ до его скрытия, нужно его принудительно закрыть
    if (hideOE)
        this.set('isShowOE', false);

    this.myVisualWidget = new TerceraVisualTradingWidget();
    this.myTradeNumeric = new TerceraQuantityNumeric();             // нумерик для редактирования quantity размещенных на Chart-e ордеров
    this.myTradeNumeric.set('isPosAbsolute', true);
    this.myTradeNumeric.set("visible", false);
};

ChartPanel.prototype.oncomplete = function ()
{
    ApplicationPanelNew.prototype.oncomplete.apply(this);

    this.initChart();

    this.chartOE = this.Controls.oe;

    this.OnResize.Subscribe(this.layoutTable, this);
    TradingLockUtils.TradingLock.TradingLockChanged.Subscribe(this.updateTradingLockState, this);

    this.MouseTradingEnabledSet(false);
    if (this.myVisualWidget)
    {
        this.myVisualWidget.OnVisualTradingOrderTypeChange.Subscribe(function (isSingle)
        {
            this.terceraChartRactive.terceraChart.setIsOCOMode(!isSingle);
        }, this);
        this.myVisualWidget.Placed = false;
        this.myVisualWidget.showChartSetting = this.ShowScreen.bind(this, ITerceraChartShowScreenType.ChartSettings, { PanelSettingsDefaultPage: DynProperty.VISUAL_TRADING_GROUP });
    }

    // Ugly. http://tp.pfsoft.lan/entity/61292
    // TODO. Implement "Reset layout" feature or whatever it's called.
    this.themeChange();

    if (this.deferredCallbackProps)
    {
        this.callBack(this.deferredCallbackProps);
        delete this.deferredCallbackProps;
    }
    if (this.deferredCallbackXml)
    {
        this.setXmlSettingsTemplate(this.deferredCallbackXml);
        delete this.deferredCallbackXml;
    }
    this.terceraChartRactive.terceraChart.chartController.SuspendRefreshChart = false;
    this.terceraChartRactive.terceraChart.RefreshChart();
    if (DataCache.getNumberOfAccounts() === 1)
        this.set({ showAccountLookUp: false });


    this.terceraChartRactive.terceraChart.model.ModelItemChanged.Subscribe(function ()
    {
        this.ModelChanged.Raise();
    }, this);

    this.terceraChartRactive.terceraChart.onHasCorrectDataChanged.Subscribe(this.onHasCorrectDataChanged, this);

    //SessionSettings.timeZoneChanged.Subscribe(this.terceraChartRactive.terceraChart.RefreshChart, this.terceraChartRactive.terceraChart);

    KeyEventProcessor.OnKeyDown.Subscribe(this.onGlobalKeyDown, this);
    KeyEventProcessor.OnKeyUp.Subscribe(this.onGlobalKeyUp, this);

    this.myToolsPanel = this.Controls.toolsPanel;
    this.observe('isShowOE', (chartOEVisible) =>
    {
        this.myToolsPanel.set('chartOEVisible', chartOEVisible);
        this.myToolsPanel.recalculateLocation();
    });

    this.myToolsPanel.observe('isVertical', (val) => { this.set('verticalToolsPanel', val); });
    this.myToolsPanel.observe('isLeftOrTop', (val) => { this.set('leftOrTopToolsPanel', val); });
    this.myToolsPanel.observe('onProcessMove', (moving) =>
    {
        if (!moving)
        {
            this.layoutTable();
        }
    });
    this.myToolsPanel.set(
        'actionProcessor',
        this.terceraChartRactive.terceraChart.TerceraChartActionProcessor);

    this.layoutTable();
    this.localize();
    if (this.get("addWidgets"))
    {
        this.addControl(this.myVisualWidget);
        this.addControl(this.myTradeNumeric);
    }
    this.mouseMoveHandler = this.mouseMoveHandler.bind(this);
    if (this.myTradeNumeric)
        this.myTradeNumeric.on(Control.Events.LostFocus, this.onEnterPressed.bind(this));

    var editableList = this.Controls.editableList
    if (editableList)
    {
        editableList.set('visible', !Resources.isHidden('chart.templates.visibility'))
        editableList.OnItemSave.Subscribe(this.onSaveTemplate, this)
        editableList.OnSelectList.Subscribe(this.onSelectTemplate, this)
        this.selectInitialTemplate()
    }

    let newIns = this.get("instrumentItem");
    this.subscribe(newIns)
    this.initChartTradingCore();
};

ChartPanel.prototype.initChartTradingCore = function ()
{
    let ctc = new ChartTradingCore();
    this.ChartTradingCore = ctc;

    ctc.myTradeNumeric = this.myTradeNumeric
    ctc.myVisualWidget = this.myVisualWidget
    ctc.chartOE = this.chartOE
    ctc.terceraChartRactive = this.terceraChartRactive
    ctc.TradingNumericErrorChecker = TradingNumericErrorChecker;
    ctc.EditPositionScreen = EditPositionScreen;
    ctc.ModifyOrderScreen = ModifyOrderScreen;
    ctc.TerceraMessageBox = TerceraMessageBox;
    ctc.contextMenuHandler = contextMenuHandler;
    ctc.MainWindowManager = MainWindowManager;
}

ChartPanel.prototype.updateTradingLockState = function ()
{
    let tradingLocked = TradingLockUtils.TradingLock.tradingLocked;
    this.set('tradingLocked', tradingLocked);

    if (tradingLocked)
    {
        let chart = this.terceraChartRactive.terceraChart;
        let ttRenderer = chart.TerceraChartTradingToolsRenderer;
        this.isOpenedTradingWidget = ttRenderer.MouseTradeEnabled;
        this.MouseTradingEnabledSet(false)
    }
    else
    {
        this.MouseTradingEnabledSet(this.isOpenedTradingWidget);
    }

};

ChartPanel.prototype.onInstrumentItemChanged = function (newIns, oldIns)
{
    if (!newIns || newIns === oldIns)
        return;

    if (this.myVisualWidget)
        this.myVisualWidget.set('instrumentItem', newIns)

    this.symbolLink_Out(false, newIns);

    if (!this.completed)
        return

    let chart = this.terceraChartRactive ? this.terceraChartRactive.terceraChart : null
    if (chart)
    {
        chart.unsubscribeTrades()
        chart.chartController.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.Instrument), newIns);
        this.unsubscribe(oldIns)
        this.subscribe(newIns)
    }
};

ChartPanel.prototype.onAccountItemChanged = function (newAcc, oldAcc)
{
    if (!newAcc || newAcc === oldAcc)
        return;

    if (this.myVisualWidget)
        this.myVisualWidget.set('accountItem', newAcc)

    if (this.myTradeNumeric)
        this.myTradeNumeric.settingsUpdate()

    if (this.terceraChartRactive && this.terceraChartRactive.terceraChart)
    {
        this.terceraChartRactive.terceraChart.chartController.ExecuteCommand(
            new TerceraChartMVCCommand(ModelDataType.Account),
            newAcc);
    }

    this.accountLink_Out(false, newAcc);
};

// TODO. Refactor.
ChartPanel.prototype.subscribe = function (instrument)
{
    if (!instrument)
        return

    let qc = DataCache.FQuoteCache
    let chart = this.terceraChartRactive.terceraChart

    qc.addListener(instrument, chart, HistoryType.QUOTE_LEVEL1)
}

// TODO. Refactor.
ChartPanel.prototype.unsubscribe = function (instrument)
{
    if (!instrument)
        return

    let qc = DataCache.FQuoteCache
    let chart = this.terceraChartRactive.terceraChart

    qc.removeListener(instrument, chart, HistoryType.QUOTE_LEVEL1)
    // qc.removeListener(instrument, chart, HistoryType.QUOTE_TRADES)
}

ChartPanel.prototype.onEnterPressed = function ()
{
    if (!this.myTradeNumeric)
        return;

    this.myTradeNumeric.set("visible", false);
    let value = this.myTradeNumeric.get("value");
    if (this.myTradeNumericPromise)
        this.myTradeNumericPromise(value);
}

ChartPanel.prototype.repopulate = function ()
{
    ApplicationPanelNew.prototype.repopulate.apply(this);
    this.terceraChartRactive.terceraChart.repopulate();
    this.set({ instrumentItem: this.get("instrumentItem") });
    this.set({ accountItem: this.get("accountItem") });
};

ChartPanel.prototype.layoutTable = function ()
{
    let chartRactive = this.terceraChartRactive;
    if (!chartRactive)
        return;

    this.layoutTableResize(chartRactive);

    const minSizeForToolBars = this.get('minSizeForToolBars');
    const panelSize = this.getCorrectChartPanelSize();
    if ((panelSize.width <= minSizeForToolBars || panelSize.height <= minSizeForToolBars) && !FullScreenManager.IsFullScreenMode)
    {
        this.set({ isVisibleMainToolBar: false });
    }
    else if (!this.get('isVisibleMainToolBar'))
    {
        this.set({ isVisibleMainToolBar: true })
    }

    if (this.get("showToolsPanel"))
        this.myToolsPanel.onResize();
};

ChartPanel.prototype.updatePanelHeader = function ()
{
    var header = Resources.getResource('panel.terceraChart') + ' ';

    var chart = this.terceraChartRactive ?
        this.terceraChartRactive.terceraChart :
        null;

    if (chart)
    {
        var ins = chart.Instrument();
        if (ins && !ins.IsEmpty) header += ins.DisplayName() + ' ';

        var tfi = chart.TimeFrameInfo();
        if (tfi)
        {
            header +=
                Resources.getResource('chart.timeframeselector.time') +
                ' - ' +
                Periods.ToLocalizedShortPeriod(tfi.Periods);
        }
    }

    this.set('header', header);
};

ChartPanel.prototype.localize = function ()
{
    ApplicationPanelNew.prototype.localize.apply(this);

    var addOverlayBtn = this.Controls.overlayButton;
    if (addOverlayBtn)
    {
        const addOverlayText = Resources.getResource('chart.ChartSymbolSelector.OverlayTooltip');
        addOverlayBtn.set('contextMenuItemText', addOverlayText)
        addOverlayBtn.set('tooltip', addOverlayText);
    }

    var timeFrameCB = this.Controls.timeFrameComboBox;
    if (timeFrameCB)
    {
        timeFrameCB.set('contextMenuItemText', Resources.getResource('chart.agregationType.Button'));
        timeFrameCB.set('tooltip', Resources.getResource('chart.agregationType.Button.ToolTip'));
    }

    var drawingStyleCB = this.Controls.drawingStyleComboBox;
    if (drawingStyleCB)
    {
        const styleText = Resources.getResource('chart.StyleButton.ToolTip');
        drawingStyleCB.set('contextMenuItemText', styleText);
        drawingStyleCB.set('tooltip', styleText);
    }

    var loadRangeCB = this.Controls.loadRangeComboBox;
    if (loadRangeCB)
    {
        loadRangeCB.set('contextMenuItemText', Resources.getResource('chart.timeframeselector.rangeButton'));
        loadRangeCB.set('tooltip', Resources.getResource('chart.timeframeselector.rangeButton.ToolTip'));
    }

    var toolsButton = this.Controls.toolsButton;
    if (toolsButton)
    {
        toolsButton.set('contextMenuItemText', Resources.getResource('chart.ChartSymbolSelector.drawingButton'));
        toolsButton.set('tooltip', Resources.getResource('chart.ChartSymbolSelector.drawingButton.ToolTip'));
    }

    var indicatorButton = this.Controls.indicatorButton;
    if (indicatorButton)
    {
        const indicatorText = Resources.getResource('chart.ChartSymbolSelector.addIndicator.ButtonToolTip');
        indicatorButton.set('contextMenuItemText', indicatorText);
        indicatorButton.set('tooltip', indicatorText);
    }

    const templatesEditable = this.Controls.editableList;
    if (templatesEditable)
    {
        const templatesText = Resources.getResource('chart.templates.tooltip');
        templatesEditable.set('contextMenuItemText', templatesText);
        templatesEditable.set('tooltip', templatesText);
    }

    const mouseTradeButton = this.Controls.mouseTradeButton;
    if (mouseTradeButton)
    {
        const mouseTradingButtonText = Resources.getResource('chart.ChartSymbolSelector.mouseTradeButton.ToolTip');
        mouseTradeButton.set('contextMenuItemText', mouseTradingButtonText);
        mouseTradeButton.set('tooltip', mouseTradingButtonText);
    }

    const oeButton = this.Controls.oeButton;
    if (oeButton)
    {
        const oeButtonText = Resources.getResource('chart.ChartSymbolSelector.oeButton.ToolTip');
        oeButton.set('contextMenuItemText', oeButtonText);
        oeButton.set('tooltip', oeButtonText);
    }

    this.updateTimeFrameComboBox();
    this.updateLoadRangeComboBox();
    this.updateDrawingStyleComboBox();

    if (this.terceraChartRactive)
        this.terceraChartRactive.localize();

    if (this.chartOE)
        this.chartOE.localize();

    var toolsPanel = this.Controls.toolsPanel;
    if (toolsPanel)
        toolsPanel.localize();

    var editableCB = this.Controls.editableList;
    if (editableCB)
        editableCB.localize();
};

ChartPanel.GetContextMenuItems = function ()
{
    var menuItems = ChartPanel.ContextMenuItemsInstance;
    if (menuItems) return menuItems;

    menuItems = [];

    // 2. Style
    menuItems.push({
        tag: TerceraChartAction.Create(TerceraChartActionEnum.Style),
        subitems: [
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.Renko) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.LinesBreak) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.Line) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.Bar) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.Candle) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.Dot) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.DotLine) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.Forest) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.Solid) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.Kagi) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.Profile) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.TicTac) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.Cluster) }
        ]
    });

    /*
    var editBars = new ChartContextMenuItem(TerceraChartAction.Create(TerceraChartActionEnum.EditBars));
    editBars.SubItems.Add(new ChartContextMenuItem(TerceraChartAction.Create(TerceraChartActionEnum.EditBars, TerceraChartEditBarsEnum.EditBar)));
    editBars.SubItems.Add(new ChartContextMenuItem(TerceraChartAction.Create(TerceraChartActionEnum.EditBars, TerceraChartEditBarsEnum.RemoveBar)));
    editBars.SubItems.Add(new ChartContextMenuItem(TerceraChartAction.Create(TerceraChartActionEnum.EditBars, TerceraChartEditBarsEnum.ResetAllChanges)));
    //editBars.SubItems.Add(new ChartContextMenuItem(TerceraChartAction.Create(TerceraChartActionEnum.EditBars, TerceraChartEditBarsEnum.FinishEditing)));
    menuItems.Add(editBars);
    */

    /*
    // 3. Interval
    menuItems.push({ tag: TerceraChartAction.Create(TerceraChartActionEnum.Agregation) });
    */

    // 4. DataType
    menuItems.push({
        tag: TerceraChartAction.Create(TerceraChartActionEnum.DataType),
        subitems: [
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.DataType, new TerceraChartHistoryType(ChartHistoryType.Default)) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.DataType, new TerceraChartHistoryType(ChartHistoryType.ByBid)) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.DataType, new TerceraChartHistoryType(ChartHistoryType.ByBidAsk)) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.DataType, new TerceraChartHistoryType(ChartHistoryType.ByTrades)) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.DataType, new TerceraChartHistoryType(ChartHistoryType.ByASk)) }
        ]
    });

    menuItems.push({ separator: true });

    // Overlays
    menuItems.push({
        tag: TerceraChartAction.Create(TerceraChartActionEnum.Overlay),
        subitems: [
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.Overlay, ITerceraChartShowScreenType.OverlayScreen) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.Overlay, true) },
        ]
    });

    // Indicators
    menuItems.push({
        tag: TerceraChartAction.Create(TerceraChartActionEnum.Indicator),
        subitems: [
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.Indicator, ITerceraChartShowScreenType.ScriptLookup) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.Indicator, true) }
        ]
    });

    // Tools.
    var toolsMI = {
        tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool),
        subitems: [
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.ToolSelector) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.Eraser) },

            { separator: true },

            {
                tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.TrendTools),
                subitems: [
                    { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.HorizontalLine) },
                    { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.VerticalLine) },
                    { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.Line) },
                    { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.PriceChannel) },
                    { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.AndrewsPitchFork) },
                ]
            },
            {
                tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.GeometryTools),
                subitems: [
                    { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.Triangle) },
                    { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.Rectangle) },
                    { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.Circle) },
                    { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.Ellipse) },
                    { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.Polygon) },
                ]
            },
            {
                tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.FibonacciTools),
                subitems: [
                    { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.FibonacciRetracement) },
                    { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.FibonacciExpansion) },
                    { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.FibonacciArc) },
                    { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.FibonacciEllipse) },
                    { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.FibonacciSpiral) },
                    { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.FibonacciFans) },
                    { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.FibonacciPhiChannel) },
                    { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.FibonacciTimeGoal) },
                    { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.FibonacciTimeZone) },
                ]
            },
            {
                tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.GannTools),
                subitems: [
                    { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.GannLine) },
                    { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.GannFan) },
                    { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.GannGrid) },
                ]
            },
            {
                tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.ElliottTools),
                subitems: [
                    { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.ElliottImpulse) },
                    { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.ElliottCorrection) },
                    { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.ElliottTriangle) },
                    { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.ElliottTripleCombo) },
                    { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.ElliottDoubleCombo) },
                ]
            },
            {
                tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.SecondaryTools),
                subitems: [
                    { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.InfoLine) },
                ]
            },
            //{ tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.Symbol) }
        ]
    };

    // Delete tools.
    toolsMI.subitems.push({
        tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType),
        subitems: [
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.HorizontalLine) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.VerticalLine) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.Line) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.PriceChannel) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.AndrewsPitchFork) },

            { separator: true },

            { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.Triangle) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.Rectangle) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.Circle) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.Ellipse) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.Polygon) },

            { separator: true },

            { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.FibonacciRetracement) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.FibonacciExpansion) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.FibonacciArc) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.FibonacciEllipse) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.FibonacciSpiral) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.FibonacciFans) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.FibonacciPhiChannel) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.FibonacciTimeGoal) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.FibonacciTimeZone) },

            { separator: true },

            { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.GannLine) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.GannFan) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.GannGrid) },

            { separator: true },

            { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.ElliottImpulse) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.ElliottCorrection) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.ElliottTriangle) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.ElliottDoubleCombo) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.ElliottTripleCombo) },

            { separator: true },

            { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.InfoLine) },
            //{ tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.Symbol) },

            { separator: true },

            { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteAllTools) }
        ]
    });

    menuItems.push(toolsMI);

    if (!Resources.isHidden('allowCreatePanelsFromPanel'))
    {
        //New
        let subitems = []

        if (!Resources.isHidden('chart.new.oe'))
            subitems.push({ tag: TerceraChartAction.Create(TerceraChartActionEnum.NewPanel, PanelNames.AdvancedOrderEntry) })
        if (!Resources.isHidden('chart.new.level2'))
            subitems.push({ tag: TerceraChartAction.Create(TerceraChartActionEnum.NewPanel, PanelNames.MarketDepthPanel) })

        if (subitems.length)
            menuItems.push({
                tag: TerceraChartAction.Create(TerceraChartActionEnum.NewPanel),
                subitems: subitems
            });
    }

    //// View
    var viewMI = {
        tag: TerceraChartAction.Create(TerceraChartActionEnum.View, null, false),
        subitems: [
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.PreviousCloseLine, false) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.TimeToNextBar, false) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.DayHighLow, false) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.InfoVindow, false) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.Volume, false) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.GridTime, false) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.GridPrice, false) },
            { separator: true },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.AnalyseToolBar, false) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.DrawingToolBar, false) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.MainToolBar, false) }
        ]
    };

    viewMI.subitems.push({ tag: TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.OrderEntry, false) });
    viewMI.subitems.push({ tag: TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.ScrollBar, false) });
    menuItems.push(viewMI);

    menuItems.push({ separator: true });

    menuItems.push({ tag: TerceraChartAction.Create(TerceraChartActionEnum.Refresh) });
    //Settings
    menuItems.push({ tag: TerceraChartAction.Create(TerceraChartActionEnum.Settings) });

    ChartPanel.ContextMenuItemsInstance = menuItems;
    return menuItems;
};

//#region ICaller

ChartPanel.prototype.Properties = function (skipBaseProperties)
{
    var properties = [];
    if (!skipBaseProperties)
        properties = ApplicationPanelNew.prototype.Properties.apply(this);

    var propsTCh = this.terceraChartRactive ? this.terceraChartRactive.Properties() : [];
    properties = properties.concat(propsTCh);

    var prop = null;

    //Additional
    var SeparatorGroup = "#3#" + Resources.getResource("property.SeparatorGroup.Additional");

    var terceraChart = this.terceraChartRactive ? this.terceraChartRactive.terceraChart : null;

    var mainToolBarAction = TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.MainToolBar);
    if (terceraChart && terceraChart.TerceraChartActionProcessor.GetTerceraChartActionState(mainToolBarAction).Visible)
    {
        prop = new DynProperty("panel.caption.showHideToolbar", this.get('showMainToolBarPanel'), DynProperty.BOOLEAN, DynProperty.VISUAL_GROUP);
        prop.separatorGroup = SeparatorGroup;
        prop.sortIndex = 3;
        properties.push(prop);
    }

    prop = new DynProperty("ChartDrawingToolsToolBar", this.get('showToolsPanel'), DynProperty.BOOLEAN, DynProperty.VISUAL_GROUP);
    prop.separatorGroup = SeparatorGroup;
    prop.sortIndex = 0;
    properties.push(prop);

    var analyzeToolBarAction = TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.AnalyseToolBar)
    if (terceraChart && terceraChart.TerceraChartActionProcessor.GetTerceraChartActionState(analyzeToolBarAction).Visible)
    {
        prop = new DynProperty("ChartAnalyseToolsToolBar", this.get('showAnalyzeToolsPanel'), DynProperty.BOOLEAN, DynProperty.VISUAL_GROUP);
        prop.separatorGroup = SeparatorGroup;
        prop.sortIndex = 1;
        properties.push(prop);
    }

    var dataSourceOpen = this.get('dataSourceOpen');
    properties.push(new DynProperty("dataSourceOpen", dataSourceOpen, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));

    // OE
    var oePanel = this.chartOE;
    if (oePanel)
    {
        var isShowOE = this.get('isShowOE');

        SeparatorGroup = "#0#" + Resources.getResource("property.VisibilitySeparatorGroup");

        prop = new DynProperty("property.chart.showingOE", isShowOE, DynProperty.BOOLEAN, DynProperty.CHART_OE_GROUP);
        prop.sortIndex = 0;
        prop.separatorGroup = SeparatorGroup;
        prop.assignedProperty = [
            "OE_ShowBuyMarketSellMarket",
            "OE_ShowSellBidBuyASk",
            "OE_AddBidAddAsk"
            // "OE_Small",
            // "OE_ShowSLTP"
        ];
        properties.push(prop);

        SeparatorGroup = "#2#" + Resources.getResource("property.SeparatorViewButtons");

        prop = new DynProperty("OE_ShowBuyMarketSellMarket", oePanel.get('_userPreferredShowBuySellMarket'), DynProperty.BOOLEAN, DynProperty.CHART_OE_GROUP);
        prop.sortIndex = 1;
        prop.separatorGroup = SeparatorGroup;
        prop.enabled = isShowOE;
        properties.push(prop);

        let btn_gr = Resources.isHidden("panel.newOrderEntry.LimitOrderButtons.Visibility") ? DynProperty.HIDDEN_GROUP : DynProperty.CHART_OE_GROUP

        prop = new DynProperty("OE_ShowSellBidBuyASk", oePanel.get('_userPreferredShowSellBidBuyAsk'), DynProperty.BOOLEAN, btn_gr);
        prop.sortIndex = 2;
        prop.separatorGroup = SeparatorGroup;
        prop.enabled = isShowOE;
        properties.push(prop);

        prop = new DynProperty("OE_AddBidAddAsk", oePanel.get('_userPreferredShowAddBidAsk'), DynProperty.BOOLEAN, btn_gr);
        prop.sortIndex = 3;
        prop.separatorGroup = SeparatorGroup;
        prop.enabled = isShowOE;
        properties.push(prop);

        /*SeparatorGroup = "#1#" + Resources.getResource("property.SeparatorGroup.SeparatorOEWidth");

        prop = new DynProperty("OE_Small", this.get('oeSmall'), DynProperty.BOOLEAN, DynProperty.CHART_OE_GROUP);
        prop.tooltip = Resources.getResource("property.OE_Small.ToolTip");
        prop.sortIndex = 4;
        prop.separatorGroup = SeparatorGroup;
        prop.enabled = isShowOE;
        properties.push(prop);*/

        //#45682 - здесь для сохранения нужно использовать пременную oE_ShowSLTP, а не свойство OE_ShowSLTP
        //var vis = DataCache.isAllowCache.GlobalWebRules[IsAllowed.RequestsTypes.isAllowedForMyUser_FUNCTION_SLTP];
        //prop = new DynProperty("OE_ShowSLTP", oePanel.OE_ShowSLTP, DynProperty.BOOLEAN, vis ? DynProperty.CHART_OE_GROUP : DynProperty.HIDDEN_GROUP);
        //prop.sortIndex = 5;
        //prop.separatorGroup = SeparatorGroup;
        //prop.enabled = this.ShowingOE;
        //prop.visible = DataCache.isAllowCache.GlobalWebRules[IsAllowed.RequestsTypes.isAllowedForMyUser_FUNCTION_SLTP];
        //properties.push(prop);
    }

    // Hidden
    if (!skipBaseProperties)
    {
        var ins = this.get('instrumentItem');
        if (ins) properties.push(new DynProperty("symbol", ins.GetInteriorID(), DynProperty.STRING, DynProperty.HIDDEN_GROUP));

        var acc = this.get('accountItem');
        if (acc) properties.push(new DynProperty("account", acc.AcctNumber, DynProperty.STRING, DynProperty.HIDDEN_GROUP));
    }

    if (this.Controls.editableList)
    {
        var tmpItem = this.Controls.editableList.get('selectedItem'),
            tmp = tmpItem ? tmpItem.text : null
        if (tmp) properties.push(new DynProperty("selectedTemplate", tmp, DynProperty.STRING, DynProperty.HIDDEN_GROUP));
    }

    return properties;
};

ChartPanel.prototype.callBack = function (properties, skipBaseProperties)
{
    ApplicationPanelNew.prototype.callBack.call(this, properties);

    if (!this.chartOE || !this.terceraChartRactive)
    {
        this.deferredCallbackProps = properties;
        return;
    }

    var dp = DynProperty.getPropertyByName(properties, "panel.caption.showHideToolbar");
    if (dp) this.set('showMainToolBarPanel', dp.value);

    dp = DynProperty.getPropertyByName(properties, "ChartDrawingToolsToolBar");
    if (dp) this.set('showToolsPanel', dp.value);

    dp = DynProperty.getPropertyByName(properties, "ChartAnalyseToolsToolBar");
    if (dp) this.set('showAnalyzeToolsPanel', dp.value);

    var oePanel = this.chartOE;
    if (oePanel)
    {
        // У когото может быть открыто ОЕ до его скрытия, нужно его принудительно закрыть
        let hideOE = Resources.isHidden('chart.ChartSymbolSelector.oeButton');

        if (hideOE)
            this.set('isShowOE', false);
        else 
        {
            dp = DynProperty.getPropertyByName(properties, "property.chart.showingOE");
            if (dp) this.set('isShowOE', dp.value);
        }

        // dp = DynProperty.getPropertyByName(properties, "OE_Small");
        // if (dp) this.set('oeSmall', dp.value);

        // dp = DynProperty.getPropertyByName(properties, "OE_ShowSLTP");
        // if (dp) oePanel.OE_ShowSLTP = dp.value;

        dp = DynProperty.getPropertyByName(properties, "OE_ShowBuyMarketSellMarket");
        if (dp) oePanel.set('_userPreferredShowBuySellMarket', dp.value);

        dp = DynProperty.getPropertyByName(properties, "OE_ShowSellBidBuyASk");
        if (dp) oePanel.set('_userPreferredShowSellBidBuyAsk', dp.value);

        dp = DynProperty.getPropertyByName(properties, "OE_AddBidAddAsk");
        if (dp) oePanel.set('_userPreferredShowAddBidAsk', dp.value);
    }

    if (this.terceraChartRactive)
        this.terceraChartRactive.callBack(properties);

    if (skipBaseProperties === undefined)
    {
        // Hidden
        this.set('instrumentItem', this.getCallBackInstrument(properties, 'symbol'));

        dp = DynProperty.getPropertyByName(properties, 'account');
        if (dp && dp.value && !LinkedSystem.accLinkingActive)
        { // #119833
            this.set('accountItem', SessionSettings.getDefValueFromObj(DataCache.Accounts[dp.value], DataCache.Accounts));
        }

        dp = DynProperty.getPropertyByName(properties, 'selectedTemplate');
        var cb = this.Controls.editableList
        if (dp && dp.value && cb)
        {
            cb.restoreCorrectSelectItem(dp.value);
            this.initialTemplateSelected = true;
        }
    }

    dp = DynProperty.getPropertyByName(properties, 'dataSourceOpen');
    if (dp) this.set('dataSourceOpen', dp.value);
};

//#endregion

ChartPanel.prototype.themeChange = function ()
{
    var Controls = this.Controls;

    if (this.terceraChartRactive)
        this.terceraChartRactive.themeChange();

    var toolsPanel = Controls.toolsPanel;
    if (toolsPanel) toolsPanel.themeChange();
};

ChartPanel.prototype.onTimeFrameComboBox_ComboItemClicked = function (context, newVal)
{
    var model = this.terceraChartRactive.terceraChart.model;
    var oldTFInfo = model.GetTimeFrameInfo();
    var controller = this.terceraChartRactive.terceraChart.chartController;
    controller.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.TFI), oldTFInfo.Copy({ period: newVal.tag }));
};

ChartPanel.prototype.onDrawingStyleComboBox_ComboItemClicked = function (context, newVal)
{
    this.terceraChartRactive.terceraChart.chartController.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.Style), newVal.tag);
};

ChartPanel.prototype.onLoadRangeComboBox_ComboItemClicked = function (context, newVal)
{
    this.terceraChartRactive.terceraChart.chartController.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.Range), newVal.tag);
};

ChartPanel.prototype.updateTimeFrameComboBox = function ()
{
    if (!this.terceraChartRactive || !this.terceraChartRactive.terceraChart)
        return;

    var cBox = this.Controls.timeFrameComboBox;

    var newItems = [
        { text: Periods.ToLocalizedShortPeriod(Periods.TIC), value: Periods.TIC },
        { text: Periods.ToLocalizedShortPeriod(Periods.MIN), value: Periods.MIN },
        { text: Periods.ToLocalizedShortPeriod(Periods.MIN5), value: Periods.MIN5 },
        { text: Periods.ToLocalizedShortPeriod(Periods.MIN15), value: Periods.MIN15 },
        { text: Periods.ToLocalizedShortPeriod(Periods.MIN30), value: Periods.MIN30 },
        { text: Periods.ToLocalizedShortPeriod(Periods.HOUR), value: Periods.HOUR },
        { text: Periods.ToLocalizedShortPeriod(Periods.HOUR4), value: Periods.HOUR4 },
        { text: Periods.ToLocalizedShortPeriod(Periods.DAY), value: Periods.DAY },
        { text: Periods.ToLocalizedShortPeriod(Periods.WEEK), value: Periods.WEEK },
        { text: Periods.ToLocalizedShortPeriod(Periods.MONTH), value: Periods.MONTH },
        { text: Periods.ToLocalizedShortPeriod(Periods.YEAR), value: Periods.YEAR }
    ];

    if (Resources.isHidden('chart.AllowedPeriods.1T'))
        // || DataCache.isAllowed(IsAllowed.RequestsTypes.isAllowed_PERIOD_TICK) )
        newItems.splice(0, 1);

    if (cBox)
        cBox.set('items', newItems);
    var tf = this.terceraChartRactive.terceraChart.model.GetTimeFrameInfo();
    if (tf && cBox)
        cBox.setItembyValue(tf.Periods);
};

ChartPanel.prototype.updateDrawingStyleComboBox = function ()
{
    if (!this.terceraChartRactive || !this.terceraChartRactive.terceraChart)
        return;

    var chart = this.terceraChartRactive.terceraChart;
    var allowedStyles = chart.model.CurrentAllowedStyles;
    var len = allowedStyles.length;
    var items = [];
    for (var i = 0; i < len; i++)
    {
        var style = allowedStyles[i];
        items.push({ style: "js-drawing-style" + style, text: TerceraChartUtils.ToLocalizedTerceraChartDrawingType(style), value: style });
    }
    var cBox = this.Controls.drawingStyleComboBox;
    if (cBox)
    {
        cBox.set({ listWidth: 120, usePNGView: true, showArrow: false, items: items, enabled: len > 1 });
        cBox.setItembyValue(chart.model.GetChartDrawingType());
    }
};

ChartPanel.prototype.updateLoadRangeComboBox = function ()
{
    if (!this.terceraChartRactive || !this.terceraChartRactive.terceraChart)
        return;

    var model = this.terceraChartRactive.terceraChart.model;

    var periods = model.CurrentAllowedPeriods;
    var len = periods.length;
    var cBoxItems = [];

    var selItem = null;
    var curPeriodDesc = model.GetPeriodDesc();

    for (var i = 0; i < len; i++)
    {
        var p = periods[i];
        cBoxItems.push({ text: p.toString(), value: p });

        if (curPeriodDesc.equals(p)) selItem = p;
    }
    var cBox = this.Controls.loadRangeComboBox;
    if (cBox)
    {
        cBox.set('items', cBoxItems);
        cBox.setItembyValue(selItem);
    }
};

ChartPanel.prototype.initChart = function ()
{
    this.terceraChartRactive = this.Controls.chart;
    if (!this.terceraChartRactive)
        return;

    this.terceraChartRactive.terceraChart.ContextMenuItems = ChartPanel.GetContextMenuItems();

    var model = this.terceraChartRactive.terceraChart.model;
    model.ModelItemChanged.Subscribe(this.MVCModelItemChanged, this);

    var controller = this.terceraChartRactive.terceraChart.chartController;

    controller.ExecuteCommand(
        new TerceraChartMVCCommand(ModelDataType.Instrument),
        this.get('instrumentItem'));

    controller.ExecuteCommand(
        new TerceraChartMVCCommand(ModelDataType.Account),
        this.get('accountItem'));
};

ChartPanel.prototype.MVCModelItemChanged = function (item)
{
    var chart = this.terceraChartRactive.terceraChart;
    switch (item.ItemType)
    {
        case ModelDataType.Style:
            this.updateDrawingStyleComboBox();
            break;
        case ModelDataType.Instrument:
            this.updatePanelHeader();
            break;
        case ModelDataType.TFI:
            this.updateTimeFrameComboBox();
            this.updatePanelHeader();
            break;
        case ModelDataType.Range:
            this.updateLoadRangeComboBox();
            break;
    }
};

ChartPanel.prototype.GetTerceraChart = function ()
{
    if (this._terceraChart)
        return this._terceraChart;

    if (this.terceraChartRactive && this.terceraChartRactive.terceraChart)
        this._terceraChart = this.terceraChartRactive.terceraChart;

    return this._terceraChart;
}
ChartPanel.prototype.TickAsync = function ()
{
    let chart = this.GetTerceraChart();
    if (chart && chart.needRedraw)
    {
        chart.needRedraw = false;
        chart.Draw();
        chart.AddForceUpdate();
    }
}

ChartPanel.prototype.addind = function ()
{

};

ChartPanel.prototype.indPropSuccess = function ()
{

};

ChartPanel.prototype.overlay_btnClick = function ()
{
    TerceraAddOverlayScreen.addNewOverlay(this.terceraChartRactive.terceraChart);
};

ChartPanel.prototype.onIndicatorBtnClick = function ()
{
    var params = new LookupDropDownShowParams();
    params.callBack = this.indicatorLookupCB.bind(this);
    TerceraIndicatorLookupDropDownForm.ShowForm(params);
};
ChartPanel.prototype.indicatorLookupCB = function (selectedItem)
{
    if (typeof selectedItem === 'object' && selectedItem.FullName)
        this.terceraChartRactive.terceraChart.AddIndicator(IndicatorManager.GetIndicator(selectedItem.FullName));
};
//#region ITerceraChartPanelContext

ChartPanel.prototype.ShowScreen = function (type, parameters)
{
    switch (type)
    {
        case ITerceraChartShowScreenType.ChartSettings:
            if (this.AllowSettings)
            {
                var headerString = Resources.getResource('panel.terceraChart') +
                    Resources.getResource('PanelSettingsScreen.TextSmall');

                if (parameters && parameters["PanelSettingsDefaultPage"])
                    PanelSettingsScreen.EditProperties(this, parameters["PanelSettingsDefaultPage"], headerString);
                else
                    PanelSettingsScreen.EditProperties(this, DynProperty.DATA_STYLE_GROUP, headerString);
            }
            break;
        case ITerceraChartShowScreenType.Properties:
            var caller = parameters.caller;
            var toolStateInfoDict = TerceraChartActionProcessor.ToolStateInfoDict;
            var toolType = caller.dataCacheTool.ToolType;
            var headerString =
                Resources.getResource(toolStateInfoDict[toolType].locKey) + ' ' +
                Resources.getResource("screen.properties.common");
            PanelSettingsScreen.EditProperties(caller, null, headerString, this);
            return null;
        case ITerceraChartShowScreenType.UIManipulation:
            var isSet = parameters["isSet"];
            var toolBarType = parameters["toolBarType"];
            switch (toolBarType)
            {
                case TerceraChartToolbarsEnum.MainToolBar:
                    if (isSet) this.toggle('showMainToolBarPanel');
                    return this.get('showMainToolBarPanel');
                case TerceraChartToolbarsEnum.DrawingToolBar:
                    if (isSet) this.toggle('showToolsPanel');
                    return this.get('showToolsPanel');
                case TerceraChartToolbarsEnum.AnalyseToolBar:
                    if (isSet) this.toggle('showAnalyzeToolsPanel');
                    return this.get('showAnalyzeToolsPanel');
                case TerceraChartToolbarsEnum.OrderEntry:
                    if (isSet) this.toggle('isShowOE');
                    return this.get('isShowOE');
                case TerceraChartToolbarsEnum.AllPricePanel:
                    return {};
                /* TODO.
                if (isSet)
                {
                    allpricePanelSettings.Visible = !allpricePanelSettings.Visible;
                    UpdateChildPanelsState();
                }
                return new object[] { allpricePanelSettings.Visible, allpricePanelSettings.Visible && allPricePanel != null ? allPricePanel.quickTable.RealRowsCount > 0 : false };
                */
                case TerceraChartToolbarsEnum.TimeAndSalesPanel:
                    return {};
                /* TODO.
                if (isSet)
                {
                    tsPanelSettings.Visible = !tsPanelSettings.Visible;
                    UpdateChildPanelsState();
                }
                return new object[] { tsPanelSettings.Visible, tsPanelSettings.Visible && tsPanel != null ? tsPanel.quickTable.RealRowsCount > 0 : false };
                */
            }
            break;
        case ITerceraChartShowScreenType.OverlayScreen:
            TerceraAddOverlayScreen.addNewOverlay(this.terceraChartRactive.terceraChart);
            return null;
        case ITerceraChartShowScreenType.ScriptLookup:
            this.onIndicatorBtnClick();
            return null;
        case ITerceraChartShowScreenType.NewPanel:
            var accItem = this.get('accountItem');
            var insItem = this.get('instrumentItem');
            MainWindowManager.Factory.addPanel(
                parameters.panelName,
                null,
                function (panel)
                {
                    if (accItem) panel.accountLink_In(accItem.AcctNumber);
                    if (insItem) panel.symbolLink_In(insItem.GetInteriorID());
                });
            return null;
        case ITerceraChartShowScreenType.ChartTrading:
            if (parameters && parameters.hasOwnProperty('tradingFlag'))
                this.MouseTradingEnabledSet(parameters.tradingFlag);
            else
                this.MouseTradingEnabledSet(!this.MouseTradingEnabledGet());
            break;
    }
    return null;
};

//#endregion

ChartPanel.prototype.MouseTradingEnabledGet = function ()
{
    var chart = this.terceraChartRactive.terceraChart;
    return chart.TerceraChartTradingToolsRenderer.MouseTradeEnabled &&
        !TradingLockUtils.TradingLock.tradingLocked;
};

ChartPanel.prototype.MouseTradingEnabledSet = function (value)
{
    var chart = this.terceraChartRactive.terceraChart;
    chart.TerceraChartTradingToolsRenderer.MouseTradeEnabled = value;
    this.activateCtrlMouseTrading(value);
    this.UpdateMouseTradingButton();
};

ChartPanel.prototype.CtrlMouseTradingEnabledGet = function ()
{
    var chart = this.terceraChartRactive.terceraChart;
    return chart.TerceraChartTradingToolsRenderer.CtrlMouseTradeEnabled &&
        !TradingLockUtils.TradingLock.tradingLocked;
};

ChartPanel.prototype.CtrlMouseTradingEnabledSet = function (value)
{
    var chart = this.terceraChartRactive.terceraChart;
    chart.TerceraChartTradingToolsRenderer.CtrlMouseTradeEnabled = value;
    this.UpdateMouseTradingButton();
};

ChartPanel.prototype.UpdateMouseTradingButton = function ()
{
    var mouseTradeButton = this.Controls.mouseTradeButton;
    if (!mouseTradeButton) return;

    var checked = this.MouseTradingEnabledGet() || this.CtrlMouseTradingEnabledGet();

    mouseTradeButton.set('checked', checked);
    this.terceraChartRactive.terceraChart.ForceUpdateCursor();

    if (!this.myVisualWidget)
        return;

    this.myVisualWidget.show(checked).then(() =>
    {
        if (!checked)
            return

        let mL = this.myVisualWidget.get('left');
        let mV_Width_C = this.myVisualWidget.get('width');
        let mV_Width = this.terceraChartRactive.get('width');
        //TODO сделать по нормальному наверное
        if (!this.myVisualWidget.Placed || mL < 0 || mL + mV_Width_C > mV_Width + 10/*небольшое окно на полгрешность справа*/)
        {
            let mV_Left = this.terceraChartRactive.getX();
            let mV_Top = this.terceraChartRactive.getY();
            this.myVisualWidget.set({ left: mV_Width / 2 - mV_Width_C / 2, top: 31 })
            this.myVisualWidget.Placed = true;
        }
    });
};

ChartPanel.prototype.mouseTradeButton_btnClick = function ()
{
    this.terceraChartRactive.terceraChart.TerceraChartActionProcessor.ProcessTerceraChartAction(
        TerceraChartAction.Create(TerceraChartActionEnum.ChartTrading));
};

ChartPanel.prototype.ChartVisualTrading = function (order, newData)
{
    return this.ChartTradingCore.ChartVisualTrading(order, newData, this.get('instrumentItem'))
}

// TODO. Ugly.
ChartPanel.prototype.changeOrderSLTP = function (order, newData)
{
    let action = newData.action
    let modifyOrdObj = this.createModifyOrderObject(order)
    modifyOrdObj.placedFrom = newData.placedFrom;
    // TODO. Ugly. Method is not base,
    // it works just cuz JS doesn't give a dayum.
    let sltpHolder = modifyOrdObj.getRawSLTP()

    let trSett = GeneralSettings.TradingDefaults;
    let ins = order.Instrument
    let orderPrice = order.Price

    let newPrice = newData.price
    let newIsTrStop = newData.ts
    let isBuy = order.BuySell === OperationType.Buy

    switch (action)
    {
        case TerceraChartTradingOperation.PlaceTP:
        case TerceraChartTradingOperation.ModifyTP:
            sltpHolder.TakeProfitPriceType = SlTpPriceType.Absolute
            sltpHolder.TakeProfitPriceValue = newPrice
            sltpHolder.OpenLossPriceValue = order.StopLossPriceValue
            break

        case TerceraChartTradingOperation.PlaceSL:
        case TerceraChartTradingOperation.ModifySL:
            let wasTrStop = sltpHolder.StopLossPriceType == SlTpPriceType.TrOffset

            sltpHolder.StopLossPriceType =
                newIsTrStop ? SlTpPriceType.TrOffset : SlTpPriceType.Offset

            let offsetValue = InstrumentUtils.getPriceDifferenceInTicks(orderPrice, newPrice, ins)
            sltpHolder.StopLossPriceValue = offsetValue
            sltpHolder.OpenLossPriceValue = offsetValue

            if (newIsTrStop)
                sltpHolder.StopLossLimitPriceValue = null
            else
            {
                let priceToCalcFrom = trSett.SetSlTpValuesInOffset ? newPrice : orderPrice

                sltpHolder.StopLossLimitPriceValue = sltpHolder.StopLossLimitPriceValue != null ?
                    InstrumentUtils.getPriceDifferenceInTicks(priceToCalcFrom, newData.sllPrice, ins) * (isBuy ? 1 : -1) * Math.sign(priceToCalcFrom - newData.sllPrice)
                    : (action == TerceraChartTradingOperation.PlaceSL || wasTrStop ? trSett.LimitOffsetTicks : null)

            }
            break

        case TerceraChartTradingOperation.PlaceSLL:
        case TerceraChartTradingOperation.ModifySLL:
            let slPrice = order.GetStopLossInPriceValue(),
                sign = (isBuy ? 1 : -1) * Math.sign(slPrice - newPrice)

            sltpHolder.StopLossLimitPriceValue = sltpHolder.StopLossPriceType === SlTpPriceType.Absolute ?
                newPrice : InstrumentUtils.getPriceDifferenceInTicks(slPrice, newPrice, ins) * sign
            break


        case TerceraChartTradingOperation.RemoveTP:
            sltpHolder.TakeProfitPriceValue = NaN
            break
        case TerceraChartTradingOperation.RemoveSL:
        case TerceraChartTradingOperation.RemoveSLL:
            sltpHolder.StopLossPriceValue = NaN
            sltpHolder.StopLossLimitPriceValue = null
            break
    }

    if (sltpHolder.TakeProfitPriceValue || sltpHolder.StopLossPriceValue)
        sltpHolder.SLTPTriggerShortValue = SLTPTriggerUtils.GetShortByOrder(order)

    // TODO. Ugly. Method is not base,
    // it works just cuz JS doesn't give a dayum.
    modifyOrdObj.setSLTP(sltpHolder)

    DataCache.FOrderExecutor.modifyOrderPromise(modifyOrdObj)
        .then(function (confirmed)
        {
            if (newData.CancelCallback && !confirmed)
                newData.CancelCallback()
        })
        .catch(function ()
        {
            let ex = new CustomErrorClass("ChangeOrderSLTP error", "ChartPanel.changeOrderSLTP", "changeOrderSLTP -> modifyOrderPromise");
            ErrorInformationStorage.GetException(ex);

            if (newData.CancelCallback)
                newData.CancelCallback()
        })
        .finally(function ()
        {
            // TODO. Refactor.
            modifyOrdObj.dispose()
        })
}

// TODO. Ugly.
// IMPORTANT SL trailing stop mustn't change when position's tp is modified
ChartPanel.prototype.changePositionSLTP = function (position, newData)
{
    let action = newData.action
    let modifyPosObj = new PositionEdit({
        dataCache: DataCache,
        position: position,
        placedFrom: newData.placedFrom
    })
    // TODO. Ugly. Method is not base,
    // it works just cuz JS doesn't give a dayum.
    let sltpHolder = modifyPosObj.getRawSLTP()

    let newPrice = newData.price
    let newIsTrStop = newData.ts
    let ins = position.Instrument

    switch (action)
    {
        case TerceraChartTradingOperation.PlaceTP:
        case TerceraChartTradingOperation.ModifyTP:
            sltpHolder.TakeProfitPriceType = SlTpPriceType.Absolute
            sltpHolder.TakeProfitPriceValue = newPrice

            if (position.SLOrder)
                sltpHolder.OpenLossPriceValue =
                    InstrumentUtils.getPriceDifferenceInTicks(position.OpenPrice, position.SLOrder.Price, ins)
            break

        case TerceraChartTradingOperation.PlaceSL:
        case TerceraChartTradingOperation.ModifySL:
            let roundedOpenPrice = ins.RoundPriceToNearestPointSize(position.OpenPrice),
                roundedPrice = ins.RoundPriceToNearestPointSize(position.Price),
                roundedNewPrice = ins.RoundPriceToNearestPointSize(newPrice)

            let sign = (position.BuySell ? -1 : 1) * Math.sign(roundedPrice - roundedNewPrice);
            sltpHolder.StopLossPriceType =
                newIsTrStop ? SlTpPriceType.TrOffset : SlTpPriceType.Offset
            sltpHolder.StopLossPriceValue =
                InstrumentUtils.getPriceDifferenceInTicks(roundedOpenPrice, roundedNewPrice, ins) * sign;
            sltpHolder.OpenLossPriceValue =
                InstrumentUtils.getPriceDifferenceInTicks(roundedOpenPrice, roundedNewPrice, ins) * sign;

            if (sltpHolder.StopLossPriceType != SlTpPriceType.TrOffset)
            {
                sltpHolder.StopLossLimitPriceValue = newData.sllPrice ?
                    InstrumentUtils.getPriceDifferenceInTicks(roundedPrice, newData.sllPrice, ins) * (position.BuySell ? -1 : 1) * Math.sign(roundedPrice - newData.sllPrice) :
                    sltpHolder.OpenLossPriceValue + GeneralSettings.TradingDefaults.LimitOffsetTicks    // default offset if place SL
            }


            break

        case TerceraChartTradingOperation.RemoveTP:
            sltpHolder.TakeProfitPriceValue = NaN
            break
        case TerceraChartTradingOperation.RemoveSL:
        case TerceraChartTradingOperation.RemoveSLL:
            sltpHolder.StopLossPriceValue = NaN
            sltpHolder.StopLossLimitPriceValue = null
            break

        case TerceraChartTradingOperation.PlaceSLL:
        case TerceraChartTradingOperation.ModifySLL:
            if (newIsTrStop)
            {
                sltpHolder.StopLossPriceType = SlTpPriceType.TrOffset
                sltpHolder.StopLossPriceValue = InstrumentUtils.getPriceDifferenceInTicks(position.CurPriceClose, newPrice, ins)
            }
            else
                sltpHolder.StopLossLimitPriceValue =
                    sltpHolder.StopLossPriceType === SlTpPriceType.Absolute ?
                        newPrice : InstrumentUtils.getPriceDifferenceInTicks(position.StopLimit, newPrice, ins)

            break

    }

    if (sltpHolder.TakeProfitPriceValue || sltpHolder.StopLossPriceValue)
        sltpHolder.SLTPTriggerShortValue = SLTPTriggerUtils.GetShortByOrder(position)

    // TODO. Ugly. Method is not base,
    // it works just cuz JS doesn't give a dayum.
    modifyPosObj.setSLTP(sltpHolder)
    // Trailing stop implicit modification fix.
    // For instance: dragging of tp order causes tr stop order change as well
    // due to setting up trailing stop value in offset ticks. 
    // Server treats it as an offset relative to current price...
    // Closing trailing stop order receives price updates from a server,
    // but Order.TrStopOffset field isn't recalculated accordingly.
    // Whatever. Refactor this pile of shit.
    modifyPosObj.setCanEditSLOrTrailingStop(
        action === TerceraChartTradingOperation.PlaceSL ||
        action === TerceraChartTradingOperation.ModifySL ||
        action === TerceraChartTradingOperation.RemoveSL ||
        action === TerceraChartTradingOperation.PlaceSLL ||
        action === TerceraChartTradingOperation.ModifySLL ||
        action === TerceraChartTradingOperation.RemoveSLL)
    modifyPosObj.setCanEditTP(
        action === TerceraChartTradingOperation.PlaceTP ||
        action === TerceraChartTradingOperation.ModifyTP ||
        action === TerceraChartTradingOperation.RemoveTP)

    DataCache.FOrderExecutor.modifyPositionPromise(modifyPosObj)
        .then(function (confirmed)
        {
            if (newData.CancelCallback && !confirmed)
                newData.CancelCallback()
        })
        .catch(function ()
        {
            let ex = new CustomErrorClass("ChangePositionSLTP error", "ChartPanel.changePositionSLTP", "changePositionSLTP -> modifyPositionPromise");
            ErrorInformationStorage.GetException(ex);

            if (newData.CancelCallback)
                newData.CancelCallback()
        })
        .finally(function ()
        {
            // TODO. Refactor.
            modifyPosObj.dispose()
        })
}

// TODO. Very Ugly.
ChartPanel.prototype.changeQuantity = function (order, newData)
{
    let quant = newData.quantity;
    let ins = order.Instrument;

    let modifyOrdObj = this.createModifyOrderObject(order);

    modifyOrdObj.setTradingData({ quantity: quant });

    DataCache.FOrderExecutor.modifyOrderPromise(modifyOrdObj)
        .then(function (confirmed)
        {
            if (newData.CancelCallback && !confirmed)
                newData.CancelCallback()
        })
        .catch(function ()
        {
            let ex = new CustomErrorClass("ChangeQuantity error", "ChartPanel.changeQuantity", "changeQuantity -> modifyOrderPromise");
            ErrorInformationStorage.GetException(ex);

            if (newData.CancelCallback)
                newData.CancelCallback()
        })
        .finally(function ()
        {
            // TODO. Refactor.
            modifyOrdObj.dispose()
        })
}

// TODO. Ugly.
ChartPanel.prototype.moveOrder = function (order, newData)
{
    let newPrice = newData.price
    let ins = order.Instrument
    let isLimitModify = newData.isLimitModify

    let modifyOrdObj = this.createModifyOrderObject(order)

    if (modifyOrdObj === null)
        if (newData.CancelCallback)
        {
            newData.CancelCallback();
            return;
        }
    // Moving sl/tp along with order's price change.
    // TODO. UGLY as fook boi.
    // Trailing stop's sl/tp values are always in offset,
    // thus there's no need to change sl/tp.
    let ordTypes = OrderType
    switch (order.OrderType)
    {
        case ordTypes.Stop:
        case ordTypes.StopLimit:
        case ordTypes.Limit:
            // TODO. UGLY. It works just cuz JS doesn't give a dayum.
            let oldBasePrice = modifyOrdObj.getBasePrice()
            let tmp_nP = newPrice;
            if (order.OrderType === ordTypes.StopLimit)
            {
                modifyOrdObj.setBasePrice(newPrice, isLimitModify, true)
                tmp_nP = modifyOrdObj.getBasePrice()
            }
            let priceDifferenceInTicks = InstrumentUtils.getPriceDifferenceInTicks(oldBasePrice, tmp_nP, ins)

            if (priceDifferenceInTicks === 0)
                break
            // Ewww.
            priceDifferenceInTicks *= tmp_nP > oldBasePrice ? 1 : -1
            let sltpHolder = modifyOrdObj.getRawSLTP()

            if (!isNaN(sltpHolder.StopLossPriceValue) &&
                (sltpHolder.StopLossPriceType === SlTpPriceType.Offset || sltpHolder.StopLossPriceType === SlTpPriceType.TrOffset))
                sltpHolder.OpenLossPriceValue = order.StopLossPriceValue

            // Ignore if value is already in offset.
            if (!isNaN(sltpHolder.StopLossPriceValue) &&
                sltpHolder.StopLossPriceType === SlTpPriceType.Absolute)
                sltpHolder.StopLossPriceValue = OrderUtils.ConvertTickOffset(
                    ins,
                    null,
                    sltpHolder.StopLossPriceValue,
                    priceDifferenceInTicks)

            // Ignore if value is already in offset.
            if (sltpHolder.StopLossLimitPriceValue !== null &&
                sltpHolder.StopLossPriceType === SlTpPriceType.Absolute)
                sltpHolder.StopLossLimitPriceValue = OrderUtils.ConvertTickOffset(
                    ins,
                    null,
                    sltpHolder.StopLossLimitPriceValue,
                    priceDifferenceInTicks)

            // Ignore if value is already in offset.
            if (!isNaN(sltpHolder.TakeProfitPriceValue) &&
                sltpHolder.TakeProfitPriceType === SlTpPriceType.Absolute)
                sltpHolder.TakeProfitPriceValue = OrderUtils.ConvertTickOffset(
                    ins,
                    null,
                    sltpHolder.TakeProfitPriceValue,
                    priceDifferenceInTicks)

            // TODO. Ugly. Method is not base,
            // it works just cuz JS doesn't give a dayum.
            modifyOrdObj.setSLTP(sltpHolder)
            break
    }

    modifyOrdObj.setBasePrice(newPrice, isLimitModify, true)
    modifyOrdObj.placedFrom = newData.placedFrom

    DataCache.FOrderExecutor.modifyOrderPromise(modifyOrdObj)
        .then(function (confirmed)
        {
            if (newData.CancelCallback && !confirmed)
                newData.CancelCallback()
        })
        .catch(function ()
        {
            let ex = new CustomErrorClass("MoveOrder error", "ChartPanel.moveOrder", "moveOrder -> modifyOrderPromise");
            ErrorInformationStorage.GetException(ex);

            if (newData.CancelCallback)
                newData.CancelCallback()
        })
        .finally(function ()
        {
            // TODO. Refactor.
            modifyOrdObj.dispose()
        })
}

ChartPanel.prototype.placeNewOrderFromVisualTrading = function (placeOrderParams)
{
    if (!this.myVisualWidget || TradingNumericErrorChecker.HasErrors(this.myVisualWidget))
        return

    placeOrderParams["fromChart"] = true;
    placeOrderParams["place"] = true;
    placeOrderParams["lTif"] = this.myVisualWidget.get("selectedItem").value;

    placeOrderParams["expireTime"] = this.myVisualWidget.get("gtdDate");

    placeOrderParams["quantity"] = this.myVisualWidget.get("quantity");
    placeOrderParams["placedFrom"] = PlacedFrom.WEB_CHART_VISUAL;

    let chartOE = this.chartOE
    placeOrderParams["productType"] = chartOE.get('productType')
    placeOrderParams["leverageValue"] = chartOE.get('leverageValue')

    try
    {
        let ins = this.get('instrumentItem'),
            route = DataCache.getRouteByName(ins.Route),
            isAllowedByTIFandOrderType = route.IsAllowableTif(placeOrderParams.lTif, placeOrderParams.lOrdType)

        if (!isAllowedByTIFandOrderType)
        {
            TerceraMessageBox.Show(Resources.getResource('screen.error.title'), Resources.getResource('IsAllowedResponceReason.NotAllowedRouteOrderType'), TerceraMessageBox.msgType.Error, null, null, false, true);
            return
        }
    }
    catch (err) { console.error(err) }

    chartOE.OrderParamsSet(placeOrderParams);
}

// TODO. Ugly.
ChartPanel.prototype.createModifyOrderObject = function (order)
{
    let orderTypeObj = DataCache.OrderParameterContainer.GetOrderType(order.OrderType)
    return orderTypeObj.createModifyOrderObject({
        dataCache: DataCache,
        order: order
    })
    return orderTypeObj
}

ChartPanel.prototype.updateSettings = function ()
{
    ApplicationPanelNew.prototype.updateSettings.apply(this);
    this.chartOE.updateTradingAllowedStuff();
};

ChartPanel.prototype.onGlobalKeyDown = function (btn, modificators)
{
    if (!this.isPanelOrChartFocused())
        return

    if (this.myTradeNumeric && this.myTradeNumeric.get("visible") && btn === KeyCode.ENTER)
        this.onEnterPressed();

    this.activateCtrlMouseTrading(
        btn === KeyCode.CTRL &&
        !modificators.ALT &&
        !modificators.SHIFT);
};

ChartPanel.prototype.onGlobalKeyUp = function (btn, modificators)
{
    if (!this.isPanelOrChartFocused())
    {
        var chart = this.terceraChartRactive.terceraChart;
        var ttRenderer = chart.TerceraChartTradingToolsRenderer;
        if (!ttRenderer.CtrlMouseTradeEnabled)
            return;
    }

    this.activateCtrlMouseTrading(false);
};

ChartPanel.prototype.isPanelOrChartFocused = function ()
{
    return this.get('focused') ||
        this.terceraChartRactive.get('focused');
};

// TODO.
ChartPanel.prototype.lostFocus = function ()
{
    ApplicationPanelNew.prototype.lostFocus.apply(this);
    this.activateCtrlMouseTrading(false);
};

ChartPanel.prototype.activateCtrlMouseTrading = function (activate)
{
    if (!this.terceraChartRactive)
        return;

    // http://tp.pfsoft.net/entity/56578
    if (this.MouseTradingEnabledGet())
        return;

    let hideOE = Resources.isHidden('chart.ChartSymbolSelector.oeButton');
    if (hideOE)
        return;

    var chart = this.terceraChartRactive.terceraChart;
    var ttRenderer = chart.TerceraChartTradingToolsRenderer;

    // http://tp.pfsoft.net/entity/56570
    if (ttRenderer.CtrlMouseTradeEnabled === activate)
        return;

    ttRenderer.CtrlMouseTradeEnabled = activate;
    this.UpdateMouseTradingButton();
    chart.IsDirty(LayersEnum.Tools);
};

ChartPanel.prototype.dispose = function ()
{
    KeyEventProcessor.OnKeyDown.UnSubscribe(this.onGlobalKeyDown, this)
    KeyEventProcessor.OnKeyUp.UnSubscribe(this.onGlobalKeyUp, this)

    if (this.Controls.editableList)
    {
        this.Controls.editableList.OnItemSave.UnSubscribe(this.onSaveTemplate, this)
        this.Controls.editableList.OnSelectList.UnSubscribe(this.onSelectTemplate, this)
    }

    //SessionSettings.timeZoneChanged.UnSubscribe(this.terceraChartRactive.terceraChart.RefreshChart, this.terceraChartRactive.terceraChart);
    this.unsubscribe(this.get('instrumentItem'))
    this.terceraChartRactive.terceraChart.Dispose()
    if (this.myVisualWidget)
        this.myVisualWidget.dispose();
    if (this.myTradeNumeric)
        this.myTradeNumeric.dispose();

    this.OnResize.UnSubscribe(this.layoutTable, this);

    ApplicationPanelNew.prototype.dispose.apply(this)
    TradingLockUtils.TradingLock.TradingLockChanged.UnSubscribe(this.updateTradingLockState, this);
};

ChartPanel.prototype.getXmlSettingsTemplate = function ()
{
    var result = {};
    if (this.terceraChartRactive && this.terceraChartRactive.terceraChart)
    {
        result.indicatorsObj = this.terceraChartRactive.terceraChart.SaveIndicators();
        result.overlaysObj = this.terceraChartRactive.terceraChart.SaveOverlays();
    }
    return result;
};

ChartPanel.prototype.setXmlSettingsTemplate = function (value)
{
    if (!this.chartOE || !this.terceraChartRactive)
    {
        this.deferredCallbackXml = value;
        return;
    }
    this.terceraChartRactive.terceraChart.LoadIndicators(value ? value.indicatorsObj : {});
    this.terceraChartRactive.terceraChart.LoadOverlays(value ? value.overlaysObj : {});
};

ChartPanel.prototype.accountLink_In = function (accId, fromLinkedSystem)
{
    if (fromLinkedSystem && !this.OnAccountLinking()) { return; }

    if (DataCache.Accounts[accId])
        this.set('accountItem', DataCache.Accounts[accId]);

    this.localizeAccountLinkTooltip()
};

ChartPanel.prototype.symbolLink_In = function (symbolName)
{
    var currentInstrument = this.get('instrumentItem');

    let newInstr = DataCache.getInstrumentByName(symbolName);
    if (newInstr)
    {
        //убираем не нужный апдейт
        if (currentInstrument && currentInstrument.GetInteriorID() === symbolName)
            return;
        this.set('instrumentItem', newInstr);
    }
};

ChartPanel.prototype.symbolLink_Out = function (newSubscriber, instrument)
{
    if (!instrument)
    {
        var ins = this.get('instrumentItem');
        if (!ins) return;
        instrument = ins;
    }
    var color = this.get('symbolLinkValue');
    if (color !== TerceraLinkControlConstants.STATE_NONE)
        LinkedSystem.setSymbol(color, instrument.GetInteriorID(), newSubscriber);
};

ChartPanel.prototype.workWithToolBar = function (showHide)
{
    this.set({ showMainToolBarPanel: showHide });
}

ChartPanel.prototype.getInstrument = function ()
{
    return this.get("instrumentItem");
}

ChartPanel.prototype.onSaveTemplate = function (element)
{
    // console.log(this._guid + " onSaveTemplate")
    let editableCB = this.Controls.editableList
    if (!editableCB || !editableCB.get('selectedItem'))
        return

    let currentList = editableCB.get('selectedItem'),
        allLists = editableCB.get('valueOfLists'),
        list = allLists[element.tag]

    let props = DynProperty.serialize(this.Properties(true)),
        xmlSet = this.getXmlSettingsTemplate()

    list.itemsStr = JSON.stringify({ properties: props, xmlSettings: xmlSet });

    editableCB.updateListsValue(null, false, null, true)
}

ChartPanel.prototype.onSelectTemplate = function (propJSON)
{
    // console.log(this._guid + " onSelectTemplate")
    if (!propJSON)
        return

    let chartTemplate = JSON.parse(propJSON),
        properties = DynProperty.deserialize(chartTemplate.properties),
        xmlSet = chartTemplate.xmlSettings

    this.terceraChartRactive.terceraChart.RemoveAllIndicators()

    this.callBack(properties, true);
    this.setXmlSettingsTemplate(xmlSet);
}

ChartPanel.prototype.selectInitialTemplate = function ()
{
    var editableList = this.Controls.editableList

    if (this.initialTemplateSelected)
        return

    var items = editableList.menuItems,      //#87095
        selItem = editableList.get('selectedItem')

    if (!selItem || selItem.value === undefined)
        return

    if (items[selItem.value])
        items[selItem.value].checked = false
    editableList.set('selectedItem', { separator: true })
    // editableList.set('showLastValue', true )

    return

    // применение стартового темплейта для здоровых людей 
    let templates = editableList.get('valueOfLists'),
        defaultTemplate = templates.length ? templates[0].itemsStr : null

    if (defaultTemplate)
        this.onSelectTemplate(defaultTemplate)
}
//setcursor settings to div
ChartPanel.prototype.applyCursor = function (cursor)
{
    if (this.terceraChartRactive)
        this.terceraChartRactive.setCursor(cursor);
};


ChartPanel.prototype.RefreshInstrumentDescriptions = function (insArray)
{
    ApplicationPanelNew.prototype.RefreshInstrumentDescriptions.call(this);
    if (insArray.indexOf(this.get("instrumentItem")) === -1)
        return;
    let tCh = this.terceraChartRactive;
    if (!tCh)
        return;
    tCh = tCh.terceraChart;
    if (!tCh)
        return;
    tCh.RefreshChart();
}

ChartPanel.prototype.onHasCorrectDataChanged = function (hasCorrectData) 
{
    this.set('dataSourceBottom', hasCorrectData ? 42 : 5)
}

//TODO перенести в один обработчик
ChartPanel.prototype.onMouseMove = function (event)
{
    ApplicationPanelNew.prototype.onMouseMove.call(this, event);
    this.lastMouseMoveEvent = event;
    // In order to increase smoothness of panel's movement.
    cancelAnimationFrame(this.mouseMoveAnimationFrameId);
    this.mouseMoveAnimationFrameId = requestAnimationFrame(this.mouseMoveHandler.bind(this));
};

ChartPanel.prototype.mouseMoveHandler = function ()
{
    if (!this.get)
        return;
    var activeControl = this.get('activeControl');
    if (!activeControl) return;

    var event = this.lastMouseMoveEvent;
    if (!event) return;

    if (activeControl.get('onProcessMove') === true)
    {
        var myParams = activeControl.movingParameters;
        var ev = event.original;
        var X_Shift = myParams.startMoveCoordX - ev.pageX;
        var Y_Shift = myParams.startMoveCoordY - ev.pageY;

        var lastMouseMoveX = myParams.startMyX - X_Shift;
        var lastMouseMoveY = myParams.startMyY - Y_Shift;

        var windowSideBordersW = WINDOW_SIDE_BORDERS_WIDTH;
        const panelSize = this.getCorrectChartPanelSize();
        // New window left.
        var activeControlW = activeControl.get('width') + windowSideBordersW;
        var mainWindowW = panelSize.width;

        if (lastMouseMoveX + activeControlW > mainWindowW)
            lastMouseMoveX = mainWindowW - activeControlW;

        if (lastMouseMoveX < 0)
            lastMouseMoveX = 0;

        // New window top.
        var activeControlH = activeControl.get('height') + windowSideBordersW;
        var topWindowsMargin = 31;
        var bottomWindowsMargin = panelSize.height;

        if (lastMouseMoveY + activeControlH > bottomWindowsMargin)
            lastMouseMoveY = bottomWindowsMargin - activeControlH;

        if (lastMouseMoveY < topWindowsMargin)
            lastMouseMoveY = topWindowsMargin;

        this.lastMouseMoveX = lastMouseMoveX;
        this.lastMouseMoveY = lastMouseMoveY;

        // Workaround to avoid "heavy" ractive code.
        //var rootPanelDiv = this.activePanelRootDiv;
        activeControl.set({ left: lastMouseMoveX, top: lastMouseMoveY })
        //rootPanelDiv.style.left = lastMouseMoveX + 'px';
        //rootPanelDiv.style.top = lastMouseMoveY + 'px';
    }

    if (activeControl.onProcessResize)
        activeControl.onResizing(event);
};

ChartPanel.prototype.getCorrectChartPanelSize = function ()
{
    var mainWindowW = this.get('width');
    if (mainWindowW === 0 || mainWindowW === '')
    {
        mainWindowW = this.el.offsetWidth;
    }
    var mainWindowH = this.get('height');
    if (mainWindowH === 0 || mainWindowH === '')
    {
        mainWindowH = this.el.offsetHeight;
    }

    return { width: mainWindowW, height: mainWindowH }
};