// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { AlgorithmDataRange } from '../../../../../Commons/cache/Algorithm/Algorithm';
import { DirectAlgorithmsAvailableMessage } from '../../../../../Utils/DirectMessages/DirectMessagesImport';
import { FieldsFactory } from '../../Factory/FieldsFactory';
import { type PFixFieldSet } from '../../Fields/PFixFieldSet';
import { AlgorithmGainersLosersRangeGroup } from '../../Groups/Algorithm/GainersLosers/AlgorithmGainersLosersRangeGroup';
import { MessageProcessorBase } from '../MessageProcessorBase';

export class AlgorithmsAvailableMessageProcessor extends MessageProcessorBase {
    public override decode (fs: PFixFieldSet): DirectAlgorithmsAvailableMessage[] {
        const msg = new DirectAlgorithmsAvailableMessage();
        const ff = FieldsFactory;

        msg.AlgorithmId = fs.GetValue(ff.FIELD_ALGORITHM_ID);
        msg.AlgorithmType = fs.GetValue(ff.FIELD_ALGORITHM_TYPE);
        msg.AlgorithmName = fs.GetValue(ff.FIELD_ALGORITHM_NAME);
        msg.AlgorithmStatus = fs.GetValue(ff.FIELD_ALGORITHM_STATUS);

        msg.AlgorithmContainerType = fs.GetValue(ff.FIELD_ALGORITHM_CONTAINER_TYPE); // 852 (int)  0 - NONE, 1 - COUNTRY, 2 - CUSTOM
        msg.AlgorithmFilterName = fs.GetValue(ff.FIELD_ALGORITHM_FILTER_NAME); // 853(String) - если в 852=2, имя кастомного фильтра для фильтра в панели(Investing).
        msg.InstrumentGroupIDs = fs.GetValue(ff.FIELD_INSTRUMENT_GROUP_IDS); // 797 - список инструмент групп для фильтра в панели(.Net\MAC). Приходит только для  695 =  0 - HIGH_LOW
        msg.RouteIDs = fs.GetValue(ff.FIELD_ROUTE_IDS); // 678, список info routes для фильтра в панели (.Net\MAC). Приходит только для  695 =  0 - HIGH_LOW

        msg.CountryId = fs.GetValue(ff.FIELD_INSTRUMENT_COUNTRY);

        const rangeGroups = fs.GetGroups(ff.ALGORITHM_HISTORICAL_GAINER_LOSER_TIME_RANGE_GROUP, AlgorithmGainersLosersRangeGroup);
        for (const rangeGroup of rangeGroups) {
            msg.AvailableRanges.push(new AlgorithmDataRange(rangeGroup.RangeType, rangeGroup.RangeValue));
        }

        return [msg];
    }
}
