// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectUserGroup } from '../../../../Utils/DirectMessages/DirectUserGroup';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class UserGroupMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): DirectUserGroup[] {
        const message = new DirectUserGroup();

        //* 1x*/ login
        message.GroupName = fieldSet.GetValue(FieldsFactory.FIELD_NAME);

        //  id
        message.GroupId = fieldSet.GetValue(FieldsFactory.FIELD_GROUP_ID);

        // super id
        message.SuperGroupId = fieldSet.GetValue(FieldsFactory.FIELD_MASTER_ID);

        return [message];
    }
}
