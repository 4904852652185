// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DataCache } from "../../Commons/DataCache.ts";
import { TradingNumericErrorChecker } from "../../Commons/Trading/TradingNumericErrorChecker.ts";
import { MainWindowManager } from "../../Controls/UtilsClasses/MainWindowManager.ts";
import { ContainerControl } from "../../Controls/elements/ContainerControl.js";
import { EditPositionScreen } from "../../Controls/screen/EditPositionScreen.js";
import { ModifyOrderScreen } from "../../Controls/screen/ModifyOrderScreen.js";
import { TerceraMessageBox } from "../../Controls/screen/TerceraMessageBox.js";
import { TradingViewToolsContainerTemplate } from "../../templates.js";
import { contextMenuHandler } from "../AppHandlers.js";
import { ChartTradingCore } from "../Trading/ChartTradingCore.ts";
import { TradingViewAlertTool } from "./TradingViewAlertTool.js";
import { TradingViewOrderTool } from "./TradingViewOrderTool.js";
import { TradingViewPositionTool } from "./TradingViewPositionTool.js";
import { TradingViewBaseTool } from "./TradingViewBaseTool.js";

export class TradingViewToolsContainer extends ContainerControl.extend({
    data: function ()
    {
        return {
            panelWidth: null,
            account: null,          // account from accountDetails even if account linking turned off
            instrument: null
        }
    },
    ChartTradingCore: null,
    currentDraggingToolID: null,    // id of moving tool (to not conflict with others on intersection)
    tools: {},
    template: TradingViewToolsContainerTemplate
})
{
    oninit ()
    {
        this.initChartTradingCore();
        this.on('containerMouseUp', this.onMouseUp);
    }

    oncomplete ()
    {
        ContainerControl.prototype.oncomplete.apply(this);

        this.observe('instrument', this.onInstrumentChanged);
        this.observe('account', this.onAccountChanged);

        this.observe('panelWidth', (panelWidth) =>
        {
            for (let tID in this.tools)
                this.tools[tID].set('containerWidth', panelWidth);
        });

        this.toolsUpdateEventsSubscribe();
    }

    TickAsync ()
    {
        const zeroPriceCoord = this.priceToCoordinate(0);
        if (this.lastZeroPriceCoord === null || this.lastZeroPriceCoord != zeroPriceCoord)  // need update tools in this case
        {
            this.lastZeroPriceCoord = zeroPriceCoord;

            const tools = this.getTools();
            for (let tID in tools)
            {
                const tool = tools[tID];
                tool.set('top', this.getToolsTop(tool));
            }
        }
    }

    onMouseMove (event)
    {
        if (this.currentDraggingToolID)
        {
            const tool = this.tools[this.currentDraggingToolID];
            const offset = 84;

            const y = event?.original?.clientY - offset;
            const newPrice = this.coordinateToPrice(y - 0.4 * offset);

            tool.set({ top: y, priceValue: newPrice, price: tool.getInstrument().formatPrice(newPrice) });
        }
    }

    onMouseUp (event)
    {
        if (this.currentDraggingToolID)
        {
            const tool = this.tools[this.currentDraggingToolID];
            tool.fire('dragend');
        }
    }

    initChartTradingCore ()
    {
        let ctc = new ChartTradingCore();
        this.ChartTradingCore = ctc;

        ctc.myTradeNumeric = this.myTradeNumeric
        ctc.myVisualWidget = this.myVisualWidget
        ctc.chartOE = this.chartOE
        ctc.terceraChartRactive = this.terceraChartRactive
        ctc.TradingNumericErrorChecker = TradingNumericErrorChecker;
        ctc.EditPositionScreen = EditPositionScreen;
        ctc.ModifyOrderScreen = ModifyOrderScreen;
        ctc.TerceraMessageBox = TerceraMessageBox;
        ctc.contextMenuHandler = contextMenuHandler;
        ctc.MainWindowManager = MainWindowManager;
    }

    toolsUpdateEventsSubscribe ()
    {   //alerts
        DataCache.AlertManager.OnAlertAdded.Subscribe(this.addTool, this);
        DataCache.AlertManager.OnAlertUpdated.Subscribe(this.resetTools, this);
        DataCache.AlertManager.OnAlertRemoved.Subscribe(this.resetTools, this);
        //orders
        DataCache.OnAddOrder.Subscribe(this.addTool, this);
        DataCache.OnRemoveOrder.Subscribe(this.resetTools, this);
        // DataCache.OnAddSLOrderToPosition.Subscribe(this.AddSLOrderToPositionEvent, this);
        // DataCache.OnAddTPOrderToPosition.Subscribe(this.AddTPOrderToPositionEvent, this);
        // DataCache.OnRemoveSLOrderFromPosition.Subscribe(this.RemoveSLOrderFromPositionEvent, this);
        // DataCache.OnRemoveTPOrderFromPosition.Subscribe(this.RemoveTPOrderFromPositionEvent, this);
        //positions
        DataCache.OnAddPosition.Subscribe(this.addTool, this);
        DataCache.OnUpdatePosition.Subscribe(this.resetTools, this);
        DataCache.OnRemovePosition.Subscribe(this.resetTools, this);
        //CorporateAction
        DataCache.OnAddCorporateAction.Subscribe(this.addTool, this);
        DataCache.OnUpdateCorporateAction.Subscribe(this.resetTools, this);
        DataCache.OnRemoveCorporateAction.Subscribe(this.resetTools, this);
    }

    toolsUpdateEventsUnSubscribe ()
    {   //alerts
        DataCache.AlertManager.OnAlertAdded.UnSubscribe(this.addTool, this);
        DataCache.AlertManager.OnAlertUpdated.UnSubscribe(this.resetTools, this);
        DataCache.AlertManager.OnAlertRemoved.UnSubscribe(this.resetTools, this);
        //orders
        DataCache.OnAddOrder.UnSubscribe(this.addTool, this);
        DataCache.OnRemoveOrder.UnSubscribe(this.resetTools, this);
        // DataCache.OnAddSLOrderToPosition.UnSubscribe(this.AddSLOrderToPositionEvent, this);
        // DataCache.OnAddTPOrderToPosition.UnSubscribe(this.AddTPOrderToPositionEvent, this);
        // DataCache.OnRemoveSLOrderFromPosition.UnSubscribe(this.RemoveSLOrderFromPositionEvent, this);
        // DataCache.OnRemoveTPOrderFromPosition.UnSubscribe(this.RemoveTPOrderFromPositionEvent, this);
        //positions
        DataCache.OnAddPosition.UnSubscribe(this.addTool, this);
        DataCache.OnUpdatePosition.UnSubscribe(this.resetTools, this);
        DataCache.OnRemovePosition.UnSubscribe(this.resetTools, this);
        //CorporateAction
        DataCache.OnAddCorporateAction.UnSubscribe(this.addTool, this);
        DataCache.OnUpdateCorporateAction.UnSubscribe(this.resetTools, this);
        DataCache.OnRemoveCorporateAction.UnSubscribe(this.resetTools, this);
    }

    dispose ()
    {
        this.toolsUpdateEventsUnSubscribe();
        ContainerControl.prototype.dispose.apply(this);
    }

    getTools () { return this.tools; }

    addTool (dataObject)
    {
        const tool = this.createTypedTool(dataObject);
        tool.set({ dataObject: dataObject, chartTradingCoreRef: this.ChartTradingCore });

        const toolID = tool.id();

        if (!this.tools[toolID])
        {
            const top = this.getToolsTop(tool);
            tool.set({ left: 70, top: top, containerWidth: this.get('panelWidth') });

            this.tools[toolID] = tool;
            this.addControl(tool);

            tool.toolsContainerRef = this;

            return tool;
        }

        return null;
    }

    removeTool (toolToRemove)
    {
        const toolID = toolToRemove.id();
        const tool = this.tools[toolID];
        if (tool)
        {
            this.removeControl(tool);
            this.tools[toolID] = null;
        }
    }

    clearTools ()
    {
        for (const toolID in this.tools)
            this.removeTool(this.tools[toolID]);
        this.tools = {};
    }

    resetTools ()
    {
        this.clearTools();
        this.addAllTools();
    }

    createTypedTool (dataObject)
    {
        let tool = null;
        if (dataObject.AlertId)
            tool = new TradingViewAlertTool();
        else
            tool = dataObject.isPosition ? new TradingViewPositionTool() : new TradingViewOrderTool();

        return tool;
    }

    onInstrumentChanged ()
    {
        this.resetTools();
    }

    onAccountChanged (acc)
    {
        if (!acc) { return };
        this.resetTools();
    }

    addAllTools (instrument)
    {
        const ins = this.get('instrument');
        const acc = this.get('account');
        if (ins && acc)
        {
            this.addAlertsTools(ins, acc);
            this.addPositionTools(ins, acc);
            this.addOrderTools(ins, acc);
        }
    }

    addAlertsTools (instrument, account)
    {
        const alerts = DataCache.AlertManager.GetFilteredAlerts(instrument, account);
        for (let i = 0; i < alerts.length; i++)
            this.addTool(alerts[i]);
    }

    addPositionTools (instrument, account)
    {
        const positions = DataCache.getPositionsByInstrumentAndAccount(instrument, account);
        for (let posID in positions)
            this.addTool(positions[posID]);
    }

    addOrderTools (instrument, account)
    {
        const orders = DataCache.getOrdersByInstrumentAndAccount(instrument, account);
        for (let ordID in orders)
            this.addTool(orders[ordID]);
    }

    getToolsTop (tool)
    {
        return this.priceToCoordinate(tool.getPrice()) + tool.getSelfHeightOffset();
    }

    priceToCoordinate (price)
    {
        const pane = this.getPane();
        const priceScale = pane ? pane.getMainSourcePriceScale()._priceScale : null;
        return priceScale ? priceScale.priceToCoordinate(price) : null;
    }

    coordinateToPrice (coordY)
    {
        const pane = this.getPane();
        const priceScale = pane ? pane.getRightPriceScales() : null;
        return priceScale && priceScale[0] ? priceScale[0].coordinateToPrice(coordY) : null;
    }

    getPane ()
    {
        const TVwidget = this.parent?.tvWidget;
        const TVchart = TVwidget?._innerAPI() ? TVwidget.chart() : null;
        if (TVchart)
        {
            const panes = TVchart.getPanes();
            return panes && panes[0] ? panes[0] : null;
        }

        return null;
    }
    // getClientPanel () { return this.el?.children?.toolsContainer; }
}

const TOOLS_CONTAINER_CLASS_NAME = "tv-tools-container";