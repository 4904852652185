// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Point } from '../../Commons/Geometry';
import { ChartMath } from '../Utils/ChartMath';
import { TerceraChartCashItemSeriesDataType } from '../Series/TerceraChartCashItemSeriesEnums';
import { CurveToolView } from './CurveToolView';
import { SelectionState } from './Selection';
import { ToolViewUtils } from './Utils/ToolViewUtils';
import { type FibonacciArcDataCacheTool } from '../../Commons/cache/Tools/FibonacciArcDataCacheTool';
import { type TerceraChartCashItemSeries } from '../Series/TerceraChartCashItemSeries';

export class FibonacciArcToolView extends CurveToolView<FibonacciArcDataCacheTool> {
    public radius = 0;

    public DrawFibArc (gr, ww, highlight): void {
        const dataCacheTool = this.dataCacheTool;
        const fibLevel = dataCacheTool.fibLevel;
        // const points = dataCacheTool.Points;
        const pen = highlight ? dataCacheTool.PenHighlight : dataCacheTool.Pen;
        // const font = dataCacheTool.font;
        // const fontColor = dataCacheTool.FontColor;
        const showFullArc = dataCacheTool.showFullArc;

        const screenPoints = this.screenPoints;

        const scrP0 = screenPoints[0];
        const scrP0X = scrP0[0];
        const scrP0Y = scrP0[1];

        const scrP1 = screenPoints[1];
        const scrP1X = scrP1[0];
        const scrP1Y = scrP1[1];

        const dY = scrP1Y - scrP0Y;
        const dX = scrP1X - scrP0X;

        gr.DrawLine(pen, scrP0X, scrP0Y, scrP1X, scrP1Y);

        const fibLevel_len = fibLevel.length;
        for (let i = 0; i < fibLevel_len; i++) {
            const fibLvl = fibLevel[i];
            if (fibLvl < 1) continue;

            const r = Math.sqrt(dY * dY + dX * dX);
            if (r > 0) {
                let startAngle = 0;
                let endAngle = 0;

                if (showFullArc) {
                    startAngle = 0;
                    endAngle = 2 * Math.PI;
                } else if (scrP0Y > scrP1Y) {
                    startAngle = 0;
                    endAngle = Math.PI;
                } else {
                    startAngle = Math.PI;
                    endAngle = 2 * Math.PI;
                }

                gr.DrawArc(pen, scrP1X, scrP1Y, r * fibLvl / 100, startAngle, endAngle);
            }
        }
    }

    public override Draw (gr, ww, param): void {
        if (isNullOrUndefined(this.lastCurvePoints)) return;

        const screenPoints = this.screenPoints;

        const scrP0 = screenPoints[0];
        const scrP0X = scrP0[0];
        const scrP0Y = scrP0[1];

        const scrP1 = screenPoints[1];
        const scrP1X = scrP1[0];
        const scrP1Y = scrP1[1];

        //
        // блок для сброса искажения. рисеут эллипс каким он станет в случае сброса масштаба, в случае смещения точки
        // выделена точка, рисуем контур модифая!
        const newRadius = ChartMath.CalcDistanceFromPointToPoint(
            new Point(scrP1X, scrP1Y),
            new Point(scrP0X, scrP0Y));

        const CurrentSelection = this.CurrentSelection;

        if (CurrentSelection.SelectedPointNo >= 0) {
            if (Math.abs(newRadius - this.radius) >= 0.2) // разница есть подсветим контур
            { this.DrawFibArc(gr, ww, false); }
        }

        const dataCacheTool = this.dataCacheTool;
        const fibLevel = dataCacheTool.fibLevel;
        const points = dataCacheTool.Points;
        const pen = CurrentSelection.CurrentState !== SelectionState.None ? dataCacheTool.PenHighlight : dataCacheTool.Pen;
        const font = dataCacheTool.font;
        const fontBrush = dataCacheTool.FontBrush;
        const showFullArc = dataCacheTool.showFullArc;
        const instrument = dataCacheTool.Instrument;

        // const dY = scrP1Y - scrP0Y;
        // const dX = scrP1X - scrP0X;

        gr.DrawLine(pen, scrP0X, scrP0Y, scrP1X, scrP1Y);

        /* TODO.
    // Ловим ошибки
    if (lastCurvePoints[0].X >= ProMath.infinity || lastCurvePoints[0].Y >= ProMath.infinity || lastCurvePoints[0].X <= ProMath.infinityMinus || lastCurvePoints[0].Y <= ProMath.infinityMinus)
    { }
    else */
        {
            const fibLevel_len = fibLevel.length;
            for (let i = 0; i < fibLevel_len; i++) {
                const fibLvl = fibLevel[i];
                if (isNullOrUndefined(fibLvl) || fibLvl < 1) continue;

                // TODO. Check.
                const startIdx = i * 40;
                const endIdx = startIdx + (showFullArc ? 40 : 21);

                const temp = this.lastCurvePoints.slice(startIdx, endIdx);
                if (showFullArc) {
                // рисуем эллипс по точкам
                    gr.DrawClosedCurve(dataCacheTool.Pen, temp);
                } else {
                // рисуем эллипс по точкам
                    gr.DrawCurve(dataCacheTool.Pen, temp);
                }

                if (newRadius > 10) {
                    const text = ToolViewUtils.FormatFibonacciText(fibLvl, points, instrument);
                    gr.DrawString(text, font, fontBrush,
                        scrP1X + (newRadius * fibLvl / 100 + 2),
                        scrP1Y - font.Height);
                }
            }
        }

        super.Draw(gr, ww, param);
    }

    public override UpdateScreenPoints (ww, cashItemSeries: TerceraChartCashItemSeries): void {
        super.UpdateScreenPoints(ww, cashItemSeries);

        const dataCacheTool = this.dataCacheTool;
        if (dataCacheTool.forceUpdateAdditionalPoints) {
            dataCacheTool.forceUpdateAdditionalPoints = false;
            this.UpdateAdditionalPoints(ww, -1, cashItemSeries);
        }
    }

    public override UpdateAdditionalPoints (ww, movePointNo, cashItemSeries: TerceraChartCashItemSeries): void {
    // только 2 базовые точки
        this.UpdateScreenPointsALL(ww, cashItemSeries, true);

        const newPts = this.getCirclePoints(ww, cashItemSeries);
        const newPts_len = newPts.length;

        const dataCacheTool = this.dataCacheTool;

        const temp = dataCacheTool.CopyPoints();
        const temp_2 = temp[2];
        const temp_2_0 = temp_2[0];
        const temp_2_1 = temp_2[1];
        const temp_len = temp.length - 2;
        for (let i = 0; i < temp_len; i++) {
            temp[2 + i] = i < newPts_len
                ? newPts[i]
                : [temp_2_0, temp_2_1];
        }

        dataCacheTool.Points = temp;
        this.UpdateScreenPointsALL(ww, cashItemSeries, false);
    }

    public getCirclePoints (ww, cashItemSeries: TerceraChartCashItemSeries): number[][] {
        const pc = ww.PointsConverter;
        const dataCacheTool = this.dataCacheTool;

        const newAddPoints: number[][] = [];
        const screenPoints = this.screenPoints;

        const scrP0 = screenPoints[0];
        const scrP0X = scrP0[0];
        const scrP0Y = scrP0[1];

        const scrP1 = screenPoints[1];
        const scrP1X = scrP1[0];
        const scrP1Y = scrP1[1];

        const centerX = scrP1X;
        const centerY = scrP1Y;
        /// !!! Будем рисовать эллипс по точкам. точек будем искать много чтоб не страдала точность прорисовки кривой по точкам.
        /// http://stackoverflow.com/questions/4467121/solutions-for-y-for-a-rotated-ellipse
        /// уравнение эллипса  x = a*cos(t)*cos(w) - b*sin(t)*sin(w),  y = a*cos(t)*sin(w) + b*sin(t)*cos(w)
        /// где а и b - большая и меньшая оси эллипса соответственно,
        /// w - угол поворота эллипса, t - направление на точку в полярных координатах
        /// х0 и у0 - центр эллипса.
        ///
        const w = 0;
        let t = 0;

        const radius = ChartMath.CalcDistanceFromPointToPoint(
            new Point(scrP1X, scrP1Y),
            new Point(scrP0X, scrP0Y));

        this.radius = radius;

        const incr = 6.28 / 40;

        const fibLevel = dataCacheTool.fibLevel;
        const fibLevel_len = fibLevel.length;

        for (let fibl = 0; fibl < fibLevel_len; fibl++) {
            const fibLvl = fibLevel[fibl];

            const a = radius * fibLvl / 100;
            const b = radius * (scrP1Y > scrP0Y ? -1 : 1) * fibLvl / 100;

            if (a && b) {
                for (t = 0; t < 6.28; t += incr)// fix 6,28 чтоб точки не накладывались в петельку
                {
                    const xt = a * Math.cos(t) * Math.cos(w) - b * Math.sin(t) * Math.sin(w);
                    const yt = b * Math.cos(w) * Math.sin(t) + a * Math.cos(t) * Math.sin(w);
                    // relative
                    if (cashItemSeries && cashItemSeries.settings.DataType === TerceraChartCashItemSeriesDataType.Relative) {
                        newAddPoints.push([
                            pc.GetDataX(xt + centerX),
                            cashItemSeries.settings.relativeDataConverter.Revert(pc.GetDataY(centerY + yt))
                        ]);
                    }
                    // log
                    else if (cashItemSeries && cashItemSeries.settings.DataType === TerceraChartCashItemSeriesDataType.Log) {
                        newAddPoints.push([
                            pc.GetDataX(xt + centerX),
                            cashItemSeries.settings.logDataConverter.Revert(pc.GetDataY(centerY + yt))
                        ]);
                    }
                    // absolute
                    else {
                        newAddPoints.push([pc.GetDataX(xt + centerX), pc.GetDataY(centerY + yt)]);
                    }
                }
            }
        }

        return newAddPoints;
    }

    // #region ICaller

    public override callBack (properties): void {
        super.callBack(properties);
        // force redraw
        this.radius = 0;
    }

    // #endregion
}
