// Copyright TraderEvolution Global LTD. © 2017-2023. All rights reserved.

import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';

export class ADL extends IndicatorScriptBase {
    constructor () {
        super();
        this.ProjectName = 'Accumulation Distribution Line';
        this.Comments = 'Accumulation Distribution Line';
        super.SetIndicatorLine('adl', Color.Orange, 1, LineStyle.SimpleChart);
        this.SeparateWindow = true;
        this.adl = [];
    }

    public Init (): void {
        this.IndicatorShortName('ADL');
    };

    public OnQuote (): void {
        super.OnQuote();
        if (this.CurrentData.Count - 1 < 1) { return; };
        this.SetValue(0, this.calcADL());
    };

    public NextBar (): void {
        this.adl.push(0.0);
        super.NextBar();
    }

    private adl: number[];

    private calcADL () {
        const calcPriceDiff = this.priceDiff(PriceType.Close, PriceType.Low) - this.priceDiff(PriceType.High, PriceType.Close);
        const oldValue = this.adl[this.adl.length - 2];
        const varifiedOldResult = Number.isNaN(oldValue) ? 0 : oldValue;
        const clv = calcPriceDiff / this.priceDiff(PriceType.High, PriceType.Low);
        this.adl[this.adl.length - 1] = clv * this.CurrentData.Volume(0) + varifiedOldResult;
        return this.adl[this.adl.length - 1];
    }

    private priceDiff (priceType1: number, priceType2: number): number {
        return this.CurrentData.GetPrice(priceType1) - this.CurrentData.GetPrice(priceType2);
    }
}
