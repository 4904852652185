// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts";
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";
import { KeyCode, KeyEventProcessor } from "../../Commons/KeyEventProcessor.ts";
import { TerceraInfoPlusMessageFormTemplate } from "../../templates.js";
import { TerceraButton } from "../elements/TerceraButton.ts";
import { TerceraWindowBase } from "./TerceraWindowBase.js";
import { ThemeManager } from "../misc/ThemeManager.ts";
import { GeneralSettings } from "../../Utils/GeneralSettings/GeneralSettings.ts";
import { SessionSettings } from "../../Commons/SessionSettings.ts";
import { TerceraButtonStyle } from "../../Utils/Enums/ButtonEnums.ts";

export let TerceraInfoPlusMessageForm = TerceraWindowBase.extend({
    data: function ()
    {
        return {
            zIndex: 300,
            height: 400,
            width: 485,
            errorTop: 0,
            header: "",
            resizable: false,
            showFooter: false,
            pictureUrl: "",
            showNextTimeText: "Show next time",
            showNextTime: true,
            showNextTimeChB: false,
            okText: "Ok",
            btnStyle: TerceraButtonStyle.Standard,

            fieldsList: [],
            redText: "",
            message: "",
            caption: ""


        };
    },
    partials: { bodyPartial: TerceraInfoPlusMessageFormTemplate }
});

TerceraInfoPlusMessageForm.prototype.getType = function () { return 'TerceraInfoPlusMessageForm' };

TerceraInfoPlusMessageForm.prototype.oninit = function ()
{
    TerceraWindowBase.prototype.oninit.apply(this);
    this.on('okClick', this.okClick);
};

TerceraInfoPlusMessageForm.prototype.oncomplete = function ()
{
    TerceraWindowBase.prototype.oncomplete.apply(this);
    this.center();
};

TerceraInfoPlusMessageForm.Show = function (fieldsList, redText, message, caption)
{
    var tercerainfoplusmessageform = new TerceraInfoPlusMessageForm();

    var height = 80 + (fieldsList.length * 20) + 18 + 18 + 65;

    tercerainfoplusmessageform.set({
        header: caption,
        redText: redText,
        pictureUrl: ThemeManager.getImageFullUrl('i_messagebox/32x32_icon_warning.png'),
        message: message,
        height: height,
        fieldsList: fieldsList,

        showNextTimeChB: GeneralSettings.Warnings.OvernightMarginNotificationMessage,
        showNextTimeText: Resources.getResource('general.messageBox.showNextTime'),
        okText: Resources.getResource('general.messageBox.ok'),
    });

    MainWindowManager.MainWindow.addControl(tercerainfoplusmessageform);

    //if (addData)
    //{
    //    if (addData.hasOwnProperty('forceCloseOnLogout'))
    //        TerceraInfoPlusMessageForm.forceCloseOnLogout = addData.forceCloseOnLogout;
    //    if (addData.cancelText)
    //        TerceraInfoPlusMessageForm.set({ cancelText: addData.cancelText });
    //    if (addData.okText)
    //        TerceraInfoPlusMessageForm.set({ okText: addData.okText });
    //    if (addData.closeButtonCallBack)
    //        TerceraInfoPlusMessageForm.set('closeButtonCallBack', addData.closeButtonCallBack);
    //}

    tercerainfoplusmessageform.setFocus();

    var keyProc = KeyEventProcessor;
    keyProc.OnKeyDown.Subscribe(TerceraInfoPlusMessageForm.onGlobalKeyDown, TerceraInfoPlusMessageForm);
};

TerceraInfoPlusMessageForm.prototype.onGlobalKeyDown = function (code)
{
    if (this.get('focused'))
    {
        if (code === KeyCode.ENTER)
            this.okClick();
    }
};

TerceraInfoPlusMessageForm.prototype.onteardown = function ()
{
    var keyProc = KeyEventProcessor;
    keyProc.OnKeyDown.UnSubscribe(this.onGlobalKeyDown, this);
}

TerceraInfoPlusMessageForm.prototype.okClick = function ()
{
    SessionSettings.overnightMarginNotificationMessage(this.get('showNextTime'));
    this.close();
};


// Close button (cross icon).
TerceraInfoPlusMessageForm.prototype.onCloseButtonClick = function ()
{
    if (this.canCloseFromButton())
        this.close();
};
