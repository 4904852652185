// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../Commons/properties/Resources';
import { Rectangle, Size } from '../../Commons/Geometry';
import { Pen } from '../../Commons/Graphics';
import { ToolView } from './ToolView';
import { ToolViewUtils } from './Utils/ToolViewUtils';
import { TimeSpan } from '../../Utils/Time/TimeSpan';
import { type InfoLineDataCacheTool } from '../../Commons/cache/Tools/InfoLineDataCacheTool';
import moment from 'moment';

export class InfoLineToolView extends ToolView<InfoLineDataCacheTool> {
    // #region Properties

    public labelsSize = new Size();
    public valuesSize = new Size();

    public daysInfo = '';
    public hoursInfo = '';
    public minInfo = '';
    public secInfo = '';

    public lblValueChanges = '';
    public lblTimeSpan = '';

    // #endregion
    constructor (tool) {
        super(tool);

        this.Localize();
    }

    public DrawInfoLine (gr, ww, highlight): void {
        const dataCacheTool = this.dataCacheTool;

        const pen = highlight ? dataCacheTool.PenHighlight : dataCacheTool.Pen;

        const screenPoints = this.screenPoints;
        const scrP0 = screenPoints[0];
        const scrP1 = screenPoints[1];

        const p0 = [scrP0[0], scrP0[1]];
        const p1 = [scrP1[0], scrP1[1]];
        const P1 = dataCacheTool.Points[1]; // P1 - 2-я точка - абсолютные данные

        const x0 = Math.round(p0[0]);
        const x1 = Math.round(p1[0]);
        const y0 = Math.round(p0[1]);
        const y1 = Math.round(p1[1]);

        gr.DrawLine(pen, x0, y0, x1, y1);
        this.DrawNotches(gr, p0, p1, pen);

        if (highlight && (scrP0[0] !== scrP1[0] || scrP0[1] !== scrP1[1])) {
            this.DrawInfoRegion(gr, p0, p1, P1);
        }
    }

    public DrawNotches (gr, p0, p1, pen): void {
        const dx = p1[0] - p0[0];
        const dy = p1[1] - p0[1];
        // угол, гипотенуза
        let angle = 0;
        let c = 0;

        if (dx) {
            angle = Math.atan(dy / dx);
            c = dx / Math.cos(angle);
        } else {
            c = dy;
            angle = 0.5 * Math.PI;
        }

        const xi = c > 0 ? 10 : -10;

        gr.save();

        gr.translate(p0[0], p0[1]);
        gr.rotate(angle);

        gr.DrawLine(pen, 0, -3, 0, 3);

        for (let x = xi; Math.abs(x) < Math.abs(c); x += xi) {
            gr.DrawLine(pen, x, -2, x, 2);
        }

        gr.DrawLine(pen, c, -3, c, 3);

        gr.restore();
    }

    // P1 - данные абсолютные 2-й  точки
    public DrawInfoRegion (gr, p0, p1, P1): void {
        let Pt0 = null;
        let Pt1 = null;

        const dataCacheTool = this.dataCacheTool;

        if (dataCacheTool.Points[0][0] < P1[0]) {
            Pt0 = dataCacheTool.Points[0];
            Pt1 = P1;
        } else {
            Pt0 = P1;
            Pt1 = dataCacheTool.Points[0];
        }

        const leftTime = moment(Pt0[0]);
        const rightTime = moment(Pt1[0]);
        const tSpan = TimeSpan.MomentDurationToTimeSpan(moment.duration(rightTime.diff(leftTime)));

        let strTimeSpan = '';

        if (tSpan.Days > 0) {
            strTimeSpan += tSpan.Days + ' ' + this.daysInfo + ' ';
        }
        if (tSpan.Hours > 0) {
            strTimeSpan += tSpan.Hours + ' ' + this.hoursInfo + ' ';
        }
        if (tSpan.Minutes > 0) {
            strTimeSpan += tSpan.Minutes + ' ' + this.minInfo + ' ';
        }
        if (tSpan.Seconds > 0) {
            strTimeSpan += tSpan.Seconds + ' ' + this.secInfo + ' ';
        }

        if (strTimeSpan.length > 0) {
            strTimeSpan = strTimeSpan.slice(0, -1);
        }

        const svalues = ToolViewUtils.FormatPrice(dataCacheTool.Instrument, Math.abs(Pt1[1] - Pt0[1]));
        const change = this.getChange(dataCacheTool.Points[0][1], dataCacheTool.Points[1][1]);
        const priceText = svalues + ' (' + change + '%)';
        this.labelsSize.Width = this.valuesSize.Width = 0;

        this.RedefineSizesFromArray(gr, this.labelsSize, [this.lblTimeSpan, this.lblValueChanges], '');
        this.RedefineSizesFromArray(gr, this.valuesSize, [strTimeSpan, priceText], '00');

        const rect = new Rectangle(0, 0, this.labelsSize.Width + this.valuesSize.Width, this.labelsSize.Height * 2 + 2);

        const p2 = this.FindThirdPoint(p0, p1, rect.Width, rect.Height);
        rect.X += p2[0];
        rect.Y += p2[1];

        gr.RoundRectangleFilter(rect, 5, dataCacheTool.BackgroundBrush, new Pen(dataCacheTool.BackgroundBrush.Color), false, false, false);

        const labX = rect.X + 7;
        const valX = labX + this.labelsSize.Width + 3;

        let labY = rect.Y + 1;
        let valY = labY;

        gr.DrawString(this.lblTimeSpan, dataCacheTool.font, dataCacheTool.FontBrush, labX, labY, 'left', 'top');
        gr.DrawString(strTimeSpan, dataCacheTool.font, dataCacheTool.FontBrush, valX, valY, 'left', 'top');

        labY += this.labelsSize.Height + 1;
        valY += this.valuesSize.Height + 1;

        gr.DrawString(this.lblValueChanges, dataCacheTool.font, dataCacheTool.FontBrush, labX, labY, 'left', 'top');
        gr.DrawString(priceText, dataCacheTool.font, dataCacheTool.FontBrush, valX, valY, 'left', 'top');
    }

    public RedefineSizesFromArray (gr, sz, arstrings, adds): void {
        const len = arstrings.length;
        for (let i = 0; i < len; i++) {
            this.RedefineSize(gr, sz, arstrings[i], adds);
        }
    }

    public getChange (point1, point2): number {
        let value = null;
        if (point1 === 0 && point2 === 0) {
            value = 0;
        } else if (point1 === 0) {
            value = ((point2 - point1) / point2) * 100;
        } else {
            value = ((point2 - point1) / point1) * 100;
        }

        return Math.abs(value.toFixed(2));
    }

    public RedefineSize (gr, rsz, s, adds): boolean {
        const font = this.dataCacheTool.font;
        const measuredWidth = gr.GetTextWidth(s + '0', font);
        if (measuredWidth > rsz.Width) {
            rsz.Width = gr.GetTextWidth(s + adds, font);
            rsz.Height = font.Height;
            return true;
        }
        return false;
    }

    public FindThirdPoint (p0, p1, infWidth, infHeight): number[] {
        const p0X = p0[0];
        const p0Y = p0[1];

        const p1X = p1[0];
        const p1Y = p1[1];

        const Minx = Math.min(p0X, p1X);
        const Miny = Math.min(p0Y, p1Y);
        const W = Math.abs(p1X - p0X);
        const H = Math.abs(p1Y - p0Y);

        return [
            Minx + W / 2 - infWidth / 2,
            Miny + H / 2 - infHeight / 2
        ];
    }

    public override Draw (gr, ww, param): void {
        this.DrawInfoLine(gr, ww, false);
        super.Draw(gr, ww, param);
    }

    public override DrawSelection (gr, ww): void {
        const screenPoints = this.screenPoints;
        const scrP0 = screenPoints[0];
        const scrP1 = screenPoints[1];

        this.DrawInfoLine(gr, ww, true);
        this.DrawSelectedPoint(gr, ww, scrP0[0], scrP0[1]);
        this.DrawSelectedPoint(gr, ww, scrP1[0], scrP1[1]);
    }

    // #region Theme Changed, Localize etc...

    public override Localize (): void {
        super.Localize();

        this.daysInfo = Resources.getResource('chart.Infoline.days');
        this.hoursInfo = Resources.getResource('chart.Infoline.hours');
        this.minInfo = Resources.getResource('chart.Infoline.min');
        this.secInfo = Resources.getResource('chart.Infoline.sec');

        this.lblValueChanges = '\u0394 ' + Resources.getResource('chart.Infoline.price');
        this.lblTimeSpan = '\u0394 ' + Resources.getResource('chart.Infoline.time');
    }

// #endregion
}
