// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PriceType } from "../../../../../Utils/History/CashItemUtils.ts";
import { iBuildInIndicator } from "../../iBuildInIndicator.ts";

export let iMomEx = function (momPeriod, priceType)
{
    iBuildInIndicator.call(this, 1);
    this.FPriceType = priceType || PriceType.Close;
    this.FMomPeriod = momPeriod;
}

iMomEx.prototype = Object.create(iBuildInIndicator.prototype)

Object.defineProperty(iMomEx.prototype, 'Name',
    {
        get: function () { return 'iMomEx' }
    })

Object.defineProperty(iMomEx.prototype, 'Key',
    {
        get: function () { return this.DefaultKey + this.FMomPeriod + this.FPriceType; }
    })

iMomEx.prototype.OnQuote = function (ci, callBound, callFromRefresh)
{
    let Parent = this.Parent
    if (Parent != null || this.FCount > this.FMomPeriod)
        this.SetValue(this.GetPrice(this.FPriceType, 0) * 100 / this.GetPrice(this.FPriceType, this.FMomPeriod));
}

export let iMom = function (momPeriod, priceType)
{
    iMomEx.call(this, momPeriod, priceType)
}
iMom.prototype = Object.create(iMomEx.prototype);

Object.defineProperty(iMom.prototype, 'Name',
    {
        get: function () { return 'iMom' },
        set: function () { }
    })