// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
'use strict';
import { PFixFieldSet } from '../Fields/FieldsImport';
import { FieldsFactory } from '../Factory/FieldsFactory';
import { SubscribeType, SubscriptionSource } from '../../../../Utils/Enums/Constants';

export class SubscribeGroup extends PFixFieldSet {
    // Запоминаем символ, чтобы после прихода респонс
    // мессаджа получить ссылку на инструмент.
    public Symbol = null;
    // Запоминаем на что мы подписывались,
    // чтобы после прихода респонс мессаджа понять, подписаны мы или нет.
    public Service = 0;
    public InstrumentTradableID: number;
    public Route: any;

    constructor (pfixFieldSet?) {
        super(FieldsFactory.SUBSCRIBE_GROUP_NAME, null, pfixFieldSet);
    }

    public getInstrumentId (): number {
        return this.GetValue(FieldsFactory.FIELD_INSTRUMENT_ID) || 0;
    }

    public setInstrumentId (id: number): void {
        this.SetField(FieldsFactory.FIELD_INSTRUMENT_ID, id);
    }

    public getTradableInstrumentId (): number {
        return this.GetValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID) || 0;
    }

    public setTradableInstrumentId (id: number): void {
        this.SetField(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID, id);
    }

    public getRouteId (): number {
        return this.GetValue(FieldsFactory.FIELD_ROUTE_ID) || 0;
    }

    public setRouteId (id: number): void {
        this.SetField(FieldsFactory.FIELD_ROUTE_ID, id);
    }

    public getSubscriptionType (): number {
        return this.GetValue(FieldsFactory.FIELD_SUBSCRIPTION_TYPE) || 0;
    }

    public getErrorCode (): number {
        return this.GetValue(FieldsFactory.FIELD_ERROR_CODE);
    }

    public setSubscriptionType (type: number): void {
        this.SetField(FieldsFactory.FIELD_SUBSCRIPTION_TYPE, type);
    }

    public getOptionType (): number {
        return this.GetValue(FieldsFactory.FIELD_OPTION_TYPE) || 0;
    }

    public setOptionType (type: number): void {
        this.SetField(FieldsFactory.FIELD_OPTION_TYPE, type);
    }

    public isLight (): boolean {
        return this.GetValue(FieldsFactory.FIELD_LIGHT_SUBS) || false;
    }

    public setLight (light: boolean): void {
        this.SetField(FieldsFactory.FIELD_LIGHT_SUBS, light);
    }

    public setGroupID (groupID: number): void {
        this.SetField(FieldsFactory.FIELD_GROUP_ID, groupID);
    }

    public getGroupID (): number {
        return this.GetValue(FieldsFactory.FIELD_GROUP_ID) || 0;
    }

    public setSubscriptionPeriodicity (value): void {
        this.SetField(
            FieldsFactory.FIELD_SUBSCRIPTION_PERIODICITY,
            value);
    }

    public setDataSource (source: SubscriptionSource): void {
        if (source !== SubscriptionSource.DATASOURCE) {
            return;
        }

        this.SetField(FieldsFactory.FIELD_IS_DATA_SOURCE, true);
    }

    public setTargetTradableId (targetTradableId: number, source: SubscriptionSource, subscribeType: SubscribeType): void {
        if (source !== SubscriptionSource.DATASOURCE) {
            return;
        }

        if (subscribeType !== SubscribeType.SUBSCRIBE) {
            return;
        }

        if (!targetTradableId) {
            return;
        }

        this.SetField(FieldsFactory.FIELD_TARGET_TR_INSTR_ID, targetTradableId);
    }
}
