// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DataCacheToolType } from '../DataCacheToolEnums';
import { BaseGannDataCacheTool } from './BaseGannDataCacheTool';
import { DynProperty } from '../../DynProperty';

export class GannLineDataCacheTool extends BaseGannDataCacheTool {
    /**
     * Constructor for GannLineDataCacheTool
     */
    constructor () {
        super();
        this.ToolType = DataCacheToolType.GannLine;
        this.InitPoints(1);
    }

    /**
     * Gets the maximum points count required for the tool
     * @returns {number} - Maximum points count
     */
    MaxPointsCount (): number {
        return 1;
    }

    /**
     * Gets the minimum points count required for the tool
     * @returns {number} - Minimum points count
     */
    MinPointsCount (): number {
        return 1;
    }

    /**
     * Gets the properties of the tool
     * @returns {DynProperty[]} - Array of dynamic properties
     */
    Properties (): DynProperty[] {
        const props: DynProperty[] = super.Properties();
        props.push(new DynProperty('chart.tools.mirrored', this.mirrored, DynProperty.BOOLEAN, DynProperty.VISUAL_GROUP));
        return props;
    }

    /**
     * Callback function for handling property changes
     * @param {DynProperty[]} properties - Array of dynamic properties
     */
    callBack (properties: DynProperty[]): void {
        super.callBack(properties);
        const prop: DynProperty | undefined = DynProperty.getPropertyByName(properties, 'chart.tools.mirrored');
        if (prop != null) this.mirrored = prop.value;
    }
}
