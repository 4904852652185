export class TerceraChartPriceScaleLayoutInfo {
    public PreferredWidthScales = 0;
    public PreferredWidthScalesLeft = 0;

    /// <summary>
    /// Эти условия подразумевают добававление правой шкалы
    /// </summary>
    public HasOverlays = false;
    public HasDoubledIndicator = false;
}
