// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { PriceLimitMeasure } from './PriceLimitMeasure';

export class StrikePriceSettings {
    public LowLimitPut = 0;
    public LowLimitCall = 0;
    public HighLimitPut = 0;
    public HighLimitCall = 0;
    public minLotPut = -1;
    public minLotCall = -1;
    public maxLotPut = -1;
    public maxLotCall = -1;
    public PriceLimitMeasurePut = PriceLimitMeasure.DISABLE;
    public PriceLimitMeasureCall = PriceLimitMeasure.DISABLE;
    public DescriptionPut: any = null;
    public DescriptionCall: any = null;
    public ExtFieldsPut: any = null;
    public ExtFieldsCall: any = null;
    public TradeSessionStatusIdPut: any = null;
    public TradeSessionStatusIdCall: any = null;
    public ExchangeSessionNamePut: any = null;
    public ExchangeSessionNameCall: any = null;
    public ContractIdPut: any = null;
    public InstrumentTradableIDPut: any = null;
    public ContractIdCall: any = null;
    public InstrumentTradableIDCall: any = null;
    public ISINPut = '';
    public ISINCall = '';
    public StrikePrice: any;
    public PutEnabled: any;
    public CallEnabled: any;
    public PutTicker: any;
    public CallTicker: any;

    constructor (strikePrice, putEnabled, callEnabled, putTicker: any = undefined, callTicker: any = undefined) {
        this.StrikePrice = strikePrice;
        this.PutEnabled = putEnabled;
        this.CallEnabled = callEnabled;
        this.PutTicker = putTicker === undefined ? null : putTicker;
        this.CallTicker = callTicker === undefined ? null : callTicker;
    }
}
