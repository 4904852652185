// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectNewsMessage } from '../../../../Utils/DirectMessages/DirectNewsMessage';
import { NewsGroup } from '../Groups/GroupsImport';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class NewsMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): DirectNewsMessage[] {
        const message = new DirectNewsMessage();

        const newsGroups = fieldSet.GetGroups(FieldsFactory.NEWS_GROUP);
        if (newsGroups) {
            for (let i = 0; i < newsGroups.length; i++) {
                const newsGroup = new NewsGroup(newsGroups[i]);

                message.NewsGroupsArr.push(newsGroup);
            }
        }

        message.RequestID = fieldSet.GetValue(FieldsFactory.FIELD_REQUEST_ID);
        return [message];
    }
}
