// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { TerceraSeparatorTemplate } from "../../templates.js";
import { Control } from "./Control.js";


export let TerceraSeparator = Control.extend({

    template: TerceraSeparatorTemplate,
    data: function () { return { isVertical: false }; }
});

TerceraSeparator.prototype.getType = function () { return 'TerceraSeparator' };