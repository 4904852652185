import { OptionAnalyzerChart } from '../../../Chart/OptionAnalyzerChart';
import { type DynProperty } from '../../../Commons/DynProperty';
import { type OptionTrader } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionTrader';
import { BaseChartRactive } from './BaseChartRactive';

export class OptionAnalyzerChartRactive extends BaseChartRactive<OptionAnalyzerChart> {
    public override getType (): string { return 'OptionAnalyzerChart'; }
    public override onteardown (): void {
        this.terceraChart.detachOptionTrader();
        super.onteardown();
    }

    public override createChart (): OptionAnalyzerChart {
        return new OptionAnalyzerChart(this.context, super.get('terceraChartPanelContext'));
    }

    public setOptionTrader (optionTrader: OptionTrader): void {
        this.terceraChart.attachOptionTrader(optionTrader);
    }

    public refreshChart (): void {
        this.terceraChart.fullRefresh();
    }

    public refreshVolatilityLines (): void {
        this.terceraChart.refreshVolatilityLines();
    }

    public refreshProbabilityLines (): void {
        this.terceraChart.refreshProbabilityLines();
    }

    public refreshProbabilityValues (): void {
        void this.terceraChart.refreshProbabilityValuesAsync();
    }

    public override Properties (): DynProperty[] {
        return this.terceraChart.Properties();
    }

    public override callBack (properties: DynProperty[]): void {
        this.terceraChart.callBack(properties);
    }
}
