// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { MAMode } from '../IndicatorConstants';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { type iBuildInIndicator } from '../iBuildInIndicator';
import { IndicatorFunctions } from '../IndicatorFunctions';
import { ExpandDoubleVector } from '../DoubleMatrix';
import { Color } from '../../../Graphics';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { InputParameterCombobox } from '../InputParamaterClasses/InputParameterCombobox';

export class TRIX extends IndicatorScriptBase {
    constructor () {
        super();
        this.ProjectName = 'TRIX';
        this.Comments = 'TRIX';
        super.SetIndicatorLine('trix', Color.Red, 1, LineStyle.SimpleChart);
        this.SeparateWindow = true;

        this.PeriodMA = 18;
        super.InputParameter(new InputParameterInteger('PeriodMA', 'Period of EMA', 0, 1, 9999));

        this.SourcePrice = PriceType.Close;
        super.InputParameter(new InputParameterCombobox('SourcePrice', 'Sources prices for MA', 1,
            [
                { Close: PriceType.Close },
                { Open: PriceType.Open },
                { High: PriceType.High },
                { Low: PriceType.Low },
                { Typical: PriceType.Typical },
                { Medium: PriceType.Medium },
                { Weighted: PriceType.Weighted }
            ]));

        this.emaSeries = new ExpandDoubleVector();
        this.ema1Series = new ExpandDoubleVector();
        this.ema2Series = new ExpandDoubleVector();
    }

    public PeriodMA: number;

    public SourcePrice: number;

    private ema: iBuildInIndicator;

    private emaSeries: ExpandDoubleVector;

    private ema1Series: ExpandDoubleVector;

    private ema2Series: ExpandDoubleVector;

    public Init (): void {
        super.Init();
        super.IndicatorShortName(this.GetIndicatorShortName());
    }

    public override GetIndicatorShortName (): string {
        return 'TRIX(' + this.PeriodMA + ')';
    }

    public NextBar (): void {
        this.emaSeries.Add(0.0);
        this.ema1Series.Add(0.0);
        this.ema2Series.Add(0.0);
        super.NextBar();
    }

    public override createInternalIndicators (): iBuildInIndicator[] {
        this.ema = this.Indicators.iMA(this.CurrentData, this.PeriodMA, MAMode.EMA, 0, this.SourcePrice);

        super.IndicatorShortName(this.GetIndicatorShortName());

        return [this.ema];
    }

    public OnQuote (): void {
        super.OnQuote();

        if (this.CurrentData.Count < this.PeriodMA) {
            return;
        }

        this.setEMA();
        this.setEMA1();
        this.setEMA2();

        super.SetValue(0, 0, this.getTRIX());
    }

    private setEMA (): void {
        this.emaSeries[this.emaSeries.Length - 1] = this.ema.getValue();
    }

    private setEMA1 (): void {
        const result = IndicatorFunctions.CallMovingFunction(MAMode.EMA, this.emaSeries, this.PeriodMA, 1);
        this.ema1Series[this.ema1Series.Length - 1] = Number.isNaN(result) ? 0 : result;
    }

    private setEMA2 (): void {
        const result = IndicatorFunctions.CallMovingFunction(MAMode.EMA, this.ema1Series, this.PeriodMA, 1);
        this.ema2Series[this.ema2Series.Length - 1] = Number.isNaN(result) ? 0 : result;
    }

    private getTRIX (): number {
        const result = 100 * (this.ema2Series[this.ema2Series.Length - 1] - this.ema2Series[this.ema2Series.Length - 2]) / this.ema2Series[this.ema2Series.Length - 2];
        return result;
    }
}
