// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { MathUtils } from '../MathUtils';

export class PriceFormatter {
    public static NumberFormatters = {};

    public static getNumberFormatter (point): any {
        if (!PriceFormatter.NumberFormatters[point]) {
            PriceFormatter.NumberFormatters[point] = new Intl.NumberFormat(undefined, {
                minimumFractionDigits: point,
                maximumFractionDigits: point
            });
        }

        return PriceFormatter.NumberFormatters[point];
    }

    public static formatPrice (price, point, withRounding: any = undefined): string {
        if (isNaN(price)) {
            price = 0;
        }

        if (!withRounding) {
            price = MathUtils.TruncateDouble(price, point);
        } // для предотвращения округления в форматере

        const formatter = PriceFormatter.getNumberFormatter(point);

        return formatter.format(price);
    }
}
