// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { AssetBalanceMessage, AssetInfoDataMessage, DirectAccountStatusMessage, type DirectMessageBaseClass } from '../../../../Utils/DirectMessages/DirectMessagesImport';
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { AccountAdditionalInfoItem, AccountAdditionalInfoDestinationPanel } from '../../../../Utils/Account/AdditionalInfoItem';

import { BuyingPowerAvailableFundsForEquitiesGroup, BuyingPowerAvailableFundsForOptionsBuyGroup, StatisticsGroup } from '../Groups/GroupsImport';
import { generateReportMessageHandler } from '../../../../Utils/AppHandlers.js';
import { BuyingPowerAvailableFund } from '../../../../Utils/Asset/BuyingPowerAvailableFund';
import { AccountAdditionalInfoItemFormatingType } from '../../../../Utils/Account/AccountAdditionalInfoItemFormatingType';
import { AccountType } from '../../../../Utils/Account/AccountType';
import { AccountTradeStatus } from '../../../../Utils/Account/AccountTradeStatus';
import { QuickTableComparingType } from '../../../../Utils/QuickTableMisc/QuickTableComparingType';
import { type PFixFieldSet } from '../Fields/FieldsImport';
import { StatisticsGroupMessage } from '../../../../Utils/DirectMessages/StatisticsGroupMessage';
import { AccountOperationStatisticMessage } from '../../../../Utils/DirectMessages/AccountOperationStatisticMessage';

export class AccountStatusMessageProcessor extends MessageProcessorBase {
    public override decode (fs: PFixFieldSet): DirectMessageBaseClass[] {
        const accMsg = new DirectAccountStatusMessage();

        accMsg.Pin = fs.GetValue(FieldsFactory.FIELD_ACCOUNT_ID).toString();
        accMsg.BstrAccount = accMsg.Pin;

        accMsg.GroupId = fs.GetValue(FieldsFactory.FIELD_GROUP_ID);

        accMsg.Login = fs.GetValue(FieldsFactory.FIELD_NAME);
        accMsg.ClientType = fs.GetValue(FieldsFactory.FIELD_CLIENT_TYPE);

        accMsg.AccountTradeStatus = fs.GetValue(FieldsFactory.FIELD_ACCOUNT_TRADE_STATUS);
        accMsg.StopTradingReason = fs.GetValue(FieldsFactory.FIELD_STOP_TRADING_REASON);

        accMsg.CommisionId = fs.GetValue(FieldsFactory.FIELD_COMMISION_PLAN_ID);
        accMsg.SpreadPlanId = fs.GetValue(FieldsFactory.FIELD_SPREAD_PLAN_ID);
        accMsg.CrossratesPlanId = fs.GetValue(FieldsFactory.FIELD_CROSS_PLAN_ID);
        accMsg.RiskPlanId = fs.GetValue(FieldsFactory.FIELD_RISK_PLAN_ID);
        accMsg.CustodialPlanId = fs.GetValue(FieldsFactory.FIELD_CUSTODIAL_PLAN_ID);
        accMsg.SwapPlanId = fs.GetValue(FieldsFactory.FIELD_SWAP_PLAN_ID);
        accMsg.ChargingPlanId = fs.GetValue(FieldsFactory.FIELD_CHARGING_PLAN_ID);
        accMsg.FundingRateMarkupPlanId = fs.GetValue(FieldsFactory.FIELD_FUNDING_RATE_MARKUP_PLAN_ID);

        accMsg.TradingLevel = fs.GetValue(FieldsFactory.FIELD_TRADING_LEVEL);
        accMsg.WarningLevel = fs.GetValue(FieldsFactory.FIELD_WARNING_LEVEL);
        accMsg.MarginLevel = fs.GetValue(FieldsFactory.FIELD_MARGIN_LEVEL);
        accMsg.WaivedMargin = fs.GetValue(FieldsFactory.FIELD_WAIVED_MARGIN);

        const userId = fs.GetValue(FieldsFactory.FIELD_USER_ID);
        accMsg.UserID = userId ? userId.toString() : null;

        accMsg.UserLogin = fs.GetValue(FieldsFactory.FIELD_LOGIN);

        accMsg.LockedBy = fs.GetValue(FieldsFactory.FIELD_BROCKER_ID);

        accMsg.AllowedRoutes = fs.GetValue(FieldsFactory.FIELD_TRADE_ROUTE_LIST);

        accMsg.AccountMode = fs.GetValue(FieldsFactory.FIELD_TYPE);
        accMsg.AccountVisibilityFlag = fs.GetValue(FieldsFactory.FIELD_ACCOUNT_VISIBILITY_FLAG);
        accMsg.MaxRelativeDrawDownLevel = fs.GetValue(FieldsFactory.FIELD_CALCULATED_DRAWDOWN);

        const assetSharesNames = fs.GetValue(FieldsFactory.FIELD_ASSET_SHARES_NAMES);
        if (assetSharesNames !== null) {
            accMsg.AssetSharesNames = assetSharesNames.split(',');
        }

        accMsg.AccountType = AccountStatusMessageProcessor.getAccountType(fs);
        accMsg.IsMasterAccount = AccountStatusMessageProcessor.isMasterAccount(fs);
        accMsg.FundType = AccountStatusMessageProcessor.getFundType(fs);

        accMsg.AssetInfoData = AccountStatusMessageProcessor.getAssetInfoData(fs);
        accMsg.AssetBalances = AccountStatusMessageProcessor.getAssetBalanceArray(fs);

        accMsg.AccountAdditionalInfo = null; // AccountStatusMessageProcessor.getAdditionalInfo(fs)

        accMsg.IsSigned = fs.GetValue(FieldsFactory.FIELD_IS_SIGNED);

        accMsg.Role = 3;

        accMsg.Status = fs.GetValue(FieldsFactory.FIELD_ACC_STATE);

        accMsg.PreferredInstrumentType = fs.GetValue(FieldsFactory.FIELD_PREFERRED_INSTRUMENT_TYPE);

        accMsg.TodayVolume = fs.GetValue(FieldsFactory.FIELD_AMOUNT);
        accMsg.TodayTrades = fs.GetValue(FieldsFactory.FIELD_TRADE_COUNT);

        accMsg.Email = fs.GetValue(FieldsFactory.FIELD_EMAIL);
        accMsg.FirstName = fs.GetValue(FieldsFactory.FIELD_FIRSTNAME);
        accMsg.MiddleName = fs.GetValue(FieldsFactory.FIELD_MIDDLENAME);
        accMsg.LastName = fs.GetValue(FieldsFactory.FIELD_LASTNAME);
        accMsg.TelephoneNumber = fs.GetValue(FieldsFactory.FIELD_PHONE);

        accMsg.IntradayRiskManagement = fs.GetValue(FieldsFactory.FIELD_INTRADAY_RISK_MANAGEMENT);
        // #67869
        // accMsg.AllowTradingOnPrepostMarket = fs.GetValue(FieldsFactory.FIELD_ALLOW_TRADING_ON_PREPOST_MARKET);
        accMsg.MaxDailyLossLimit = fs.GetValue(FieldsFactory.FIELD_MAX_DAILY_LOSS_LIMIT);
        // accMsg.MaxDayVolume = fs.GetValue(FieldsFactory.FIELD_MAX_DAY_VOLUME);
        // accMsg.MaxOrdersPerDay = fs.GetValue(FieldsFactory.FIELD_MAX_ORDERS_PER_DAY);
        accMsg.MaxPositions = fs.GetValue(FieldsFactory.FIELD_MAX_POSITIONS);
        accMsg.MaxPendingOrders = fs.GetValue(FieldsFactory.FIELD_MAX_PENDING_ORDERS);
        accMsg.MaxPendingOrdersValue = fs.GetValue(FieldsFactory.FIELD_MAX_PENDING_ORDERS_VALUE);
        accMsg.MaxOrderCapital = fs.GetValue(FieldsFactory.FIELD_MAX_ORDER_CAPITAL);
        // accMsg.AllowOvernightTrading = fs.GetValue(FieldsFactory.FIELD_ALLOW_OVERNIGHT_TRADING);
        accMsg.InterestsOnBalancePlanID = fs.GetValue(FieldsFactory.FIELD_INTERESTS_ON_BALANCE_PLAN_ID);

        accMsg.MaxWeeklyLossLimit = fs.GetValue(FieldsFactory.FIELD_MAX_WEEKLY_LOSS_LIMIT);
        accMsg.TotalMaxPositionsQty = fs.GetValue(FieldsFactory.FIELD_TOTAL_MAX_POSITION_QTY);
        accMsg.MaxUrealizedLossLimit = fs.GetValue(FieldsFactory.FIELD_MAX_UNREALIZED_LOSS_LIMIT);
        accMsg.MaxOrderAmount = fs.GetValue(FieldsFactory.FIELD_MAX_ORDER_AMOUNT);

        accMsg.LossLimitPerTrade = fs.GetValue(FieldsFactory.FIELD_LOSS_LIMIT_PER_TRADE);

        // custom fields from server on User
        const userAdditionalInfoGroups = fs.GetGroups(FieldsFactory.KEY_VALUE_GROUP);
        if (userAdditionalInfoGroups.length > 0) {
            accMsg.UserAdditionalInfo = {};
            for (let i = 0; i < userAdditionalInfoGroups.length; i++) {
                const key = userAdditionalInfoGroups[i].GetValue(FieldsFactory.FIELD_KEY);
                const value = userAdditionalInfoGroups[i].GetValue(FieldsFactory.FIELD_VALUE);
                accMsg.UserAdditionalInfo[key] = value;
            }
        }

        const statisticsGroup: StatisticsGroup = fs.GetGroups(FieldsFactory.STATISTICS_GROUP, StatisticsGroup)[0];
        if (!isNullOrUndefined(statisticsGroup)) {
            accMsg.StatisticsGroup = new StatisticsGroupMessage();
            accMsg.StatisticsGroup.Type = statisticsGroup.getType();
            accMsg.StatisticsGroup.BeginProjectedBalance = statisticsGroup.getBeginProjectedBalance();
            const accountOperationStatisticGroups = statisticsGroup.getAccountOperationStatisticGroups();
            const accountOperationStatisticMessage: AccountOperationStatisticMessage[] = [];
            accountOperationStatisticGroups.forEach(group => {
                const accountOperationMsg = new AccountOperationStatisticMessage();
                accountOperationMsg.OperationType = group.getOperationType();
                accountOperationMsg.Amount = group.getAmount();
                accountOperationStatisticMessage.push(accountOperationMsg);
            });
            accMsg.StatisticsGroup.AccountOperationStatistic = accountOperationStatisticMessage;
        }

        accMsg.DayTraderPattern = fs.GetValue(FieldsFactory.FIELD_DAY_TRADER_PATTERN);
        accMsg.DayTraderPatternProtection = fs.GetValue(FieldsFactory.FIELD_DAY_TRADER_PATTERN_PROTECTION);
        accMsg.AvailableDayTrades = fs.GetValue(FieldsFactory.FIELD_AVAILABLE_DAY_TRADES);

        let extendedFieldsGroup = fs.GetGroups(FieldsFactory.KEY_VALUE_GROUP);
        if (extendedFieldsGroup) {
            for (let i = 0; i < extendedFieldsGroup.length; i++) {
                const groupItem = extendedFieldsGroup[i];
                const value = groupItem.GetValue(FieldsFactory.FIELD_VALUE);
                const key = groupItem.GetValue(FieldsFactory.FIELD_KEY);

                if (value && key === 'InterTrader(ID)') {
                    accMsg.ExtendedFields.ITChartAdvanced = value;
                }
            }
        }

        extendedFieldsGroup = fs.GetGroups(FieldsFactory.EXT_FIELD_GROUP);
        if (extendedFieldsGroup) {
            for (let i = 0; i < extendedFieldsGroup.length; i++) {
                const extField = extendedFieldsGroup[i];
                accMsg.ExtendedFields[extField.GetValue(FieldsFactory.FIELD_NAME)] = extField.GetValue(FieldsFactory.FIELD_VALUE);
            }
        }

        if (accMsg.AccountTradeStatus === AccountTradeStatus.TRADING_DISABLED_RISK_STATUS &&
        accMsg.StopTradingReason !== null) {
            const reportMsg = generateReportMessageHandler.GenerateReportMessage(accMsg);
            if (reportMsg) {
                return [reportMsg, accMsg];
            }
        }

        return [accMsg];
    }

    public static isMasterAccount (fs): boolean | null {
        let isMasterAccount = null;

        const accType = AccountStatusMessageProcessor.getAccountType(fs);
        if (accType === null) {
            isMasterAccount = fs.GetValue(FieldsFactory.FIELD_IS_MASTER);
        } else {
            switch (accType) {
            case AccountType.SingleCCY:
            case AccountType.MultiAsset:
                isMasterAccount = false;
                break;
            case AccountType.OpenedFund:
            case AccountType.ClosedFund:
                isMasterAccount = true;
                break;
            }
        }

        return isMasterAccount;
    }

    public static getFundType (fs: PFixFieldSet): number {
        let fundType = -1;

        const accType = AccountStatusMessageProcessor.getAccountType(fs);
        if (accType === null) {
            fundType = fs.GetValue(FieldsFactory.FIELD_FUND_TYPE);
        } else {
            switch (accType) {
            case AccountType.OpenedFund:
                fundType = 1;
                break;
            case AccountType.ClosedFund:
                fundType = 0;
                break;
            }
        }

        return fundType;
    }

    public static getAccountType (fs: PFixFieldSet): AccountType {
        return fs.GetValue(FieldsFactory.FIELD_ACCOUNT_TYPE);
    }

    public static getAssetInfoData (fs: PFixFieldSet): AssetInfoDataMessage[] {
        const assetBalanceGroups = fs.GetGroups(FieldsFactory.ASSET_BALANCE_GROUP);
        if (!assetBalanceGroups) {
            return [];
        }

        return assetBalanceGroups.map(function (group) {
            const res = new AssetInfoDataMessage();

            res.Name = group.GetValue(FieldsFactory.FIELD_NAME);
            res.CalculationMethod = group.GetValue(FieldsFactory.FIELD_CALCULATION_METHOD);
            res.TodayTradedQty = group.GetValue(FieldsFactory.FIELD_TODAY_TRADED_QTY);
            res.TotalQuantity = group.GetValue(FieldsFactory.FIELD_TOTAL_QUANTITY);
            res.TotalQuantityForMarginAvailable = group.GetValue(FieldsFactory.FIELD_TOTAL_QUANTITY_FOR_MARGIN_AVAILABLE);
            res.AssetTradingMode = group.GetValue(FieldsFactory.FIELD_ASSET_TRADING_MODE);

            return res;
        });
    }

    public static getAssetBalanceArray (fs: PFixFieldSet): AssetBalanceMessage[] {
        const assetGroupArr = fs.GetGroups(FieldsFactory.ASSET_ACCOUNT_GROUP);
        if (!assetGroupArr) {
            return [];
        }

        const assetBalanceMessageArray: AssetBalanceMessage[] = [];

        for (let i = 0, len = assetGroupArr.length; i < len; i++) {
            const msg = AccountStatusMessageProcessor.tryCreateAssetBalanceMessage(assetGroupArr[i]);
            if (msg) {
                assetBalanceMessageArray.push(msg);
            }
        }

        return assetBalanceMessageArray;
    }

    public static tryCreateAssetBalanceMessage (fs: PFixFieldSet): AssetBalanceMessage {
        const assetId = fs.GetValue(FieldsFactory.FIELD_ASSET_ID);
        if (assetId === null) {
            return null;
        }

        const balance = new AssetBalanceMessage();

        balance.AssetId = assetId;
        balance.IsEmpty = true;

        let val = fs.GetValue(FieldsFactory.FIELD_BALANCE);
        if (val !== null) {
            balance.Balance = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_LOCKED_FOR_ORDERS);
        if (val !== null) {
            balance.BlockedForOrders = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_TODAY_FEES);
        if (val !== null) {
            balance.TodayFees = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_TODAY_REBATES);
        if (val !== null) {
            balance.TodayRebates = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_CASH_BALANCE);
        if (val !== null) {
            balance.CashBalance = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_RESERVED_BALANCE);
        if (val !== null) {
            balance.ReservedBalance = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_CREDIT_VALUE);
        if (val !== null) {
            balance.CreditValue = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_USED_MARGIN);
        if (val !== null) {
            balance.UsedMargin = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_AVAILABLE_MARGIN);
        if (val !== null) {
            balance.AvailableMargin = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_MAINTANCE_MARGIN);
        if (val !== null) {
            balance.MaintanceMargin = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_DEFICIENCY_MARGIN);
        if (val !== null) {
            balance.MarginDeficiency = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_SURPLUS_MARGIN);
        if (val !== null) {
            balance.MarginSurplus = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_INITIAL_MARGIN_WITHOUT_WAIVED);
        if (val !== null) {
            balance.InitialMarginWithoutWaived = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_BEGIN_BALANCE);
        if (val !== null) {
            balance.BeginBalance = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_PNL);
        if (val !== null) {
            balance.RealizedPnl = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_BLOCKED_SUM);
        if (val !== null) {
            balance.BlockedSum = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_TURNOVER);
        if (val !== null) {
            balance.TodayTurnover = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_TRADE_COUNT);
        if (val !== null) {
            balance.TodayTrades = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_AMOUNT);
        if (val !== null) {
            balance.TodayVolume = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_UNSETTLED_PROFIT);
        if (val !== null) {
            balance.UnsettledProfit = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_UNSETTLED_LOSS);
        if (val !== null) {
            balance.UnsettledLoss = val;
            balance.IsEmpty = false;
        }
        val = fs.GetValue(FieldsFactory.FIELD_UNSETTLED_DEPOSIT);
        if (val !== null) {
            balance.UnsettledDeposit = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_CURRENT_DAILY_LOSS);
        if (val !== null) {
            balance.CurrentDailyLoss = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_CURRENT_WEEKLY_LOSS);
        if (val !== null) {
            balance.CurrentWeeklyLoss = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_TRAILING_DRAWDOWN_LEVEL);
        if (val !== null) {
            balance.TrailingDrawdownLevel = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_STOCK_REQ);
        if (val !== null) {
            balance.StockOrdersReq = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_USED_UNSETTLED_NEGATIVE_PREMIUM);
        if (val !== null) {
            balance.UsedUnsettledNegativePremium = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_UNUSED_UNSETTLED_NEGATIVE_PREMIUM);
        if (val !== null) {
            balance.UnusedUnsettledNegativePremium = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_UNSETTLED_POSITIVE_PREMIUM);
        if (val !== null) {
            balance.UnsettledPositivePremium = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_UNSETTLED_PREMIUM_FROM_OPEN_SELL);
        if (val !== null) {
            balance.UnsettledPremiumFromOpenSell = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_USED_PREMIUM_FROM_OPEN_SELL);
        if (val !== null) {
            balance.UsedPremiumFromOpenSell = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_UNUSED_PREMIUM_FROM_OPEN_SELL);
        if (val !== null) {
            balance.UnusedPremiumFromOpenSell = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_USED_UNSETTLED_NEGATIVE_CASH_FOR_STOCKS);
        if (val !== null) {
            balance.UsedUnsettledNegativeCashForStocks = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_UNUSED_UNSETTLED_NEGATIVE_CASH_FOR_STOCKS);
        if (val !== null) {
            balance.UnusedUnsettledNegativeCashForStocks = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_UNSETTLED_POSITIVE_CASH_FOR_STOCKS);
        if (val !== null) {
            balance.UnsettledPositiveCashForStocks = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_STOCKS_LIQUIDITY);
        if (val !== null) {
            balance.StocksLiquidity = val;
            balance.IsEmpty = false;
        }
        val = fs.GetValue(FieldsFactory.FIELD_OPTION_PREMIUM_REQ);
        if (val !== null) {
            balance.OptionPremiumReq = val;
            balance.IsEmpty = false;
        }
        val = fs.GetValue(FieldsFactory.FIELD_UNSETTLED_LOSS_AVAILABLE_FOR_WITHDRAWAL);
        if (val !== null) {
            balance.UnsettledLossAvailableForWithdrawal = val;
            balance.IsEmpty = false;
        }
        val = fs.GetValue(FieldsFactory.FIELD_WITHDRAWABLE_CASH_FOR_STOCKS);
        if (val !== null) {
            balance.WithdrawableCashForStocks = val;
            balance.IsEmpty = false;
        }
        val = fs.GetValue(FieldsFactory.FIELD_WITHDRAWABLE_UNSETTLED_PNL);
        if (val !== null) {
            balance.WithdrawableUnsettledPnL = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_UNSETTLED_COLLATERAL);
        if (val !== null) {
            balance.UnsettledCollateral = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_WITHDRAWABLE_UNSETTLED_COLLATERAL);
        if (val !== null) {
            balance.WithdrawableUnsettledCollateral = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_COLLATERAL);
        if (val !== null) {
            balance.Collateral = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_UNUSED_SETTLED_CASH_FOR_STOCKS);
        if (val !== null) {
            balance.UnusedSettledCashForStocks = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_UNUSED_SETTLED_COLLATERAL);
        if (val !== null) {
            balance.UnusedSettledCollateral = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_UNUSED_INTRADAY_INITIAL_MARGIN);
        if (val !== null) {
            balance.UnusedIntradayInitialMargin = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_USED_OVERNIGHT_INITIAL_MARGIN_FOR_INTRADAY);
        if (val !== null) {
            balance.UsedOvernightInitialMarginForIntraday = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_ACCRUED_DIVIDENDS);
        if (val !== null) {
            balance.AccruedDividends = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_WARNING_MARGIN);
        if (val !== null) {
            balance.WarningMarginRequirement = val;
            balance.IsEmpty = false;
        }
        val = fs.GetValue(FieldsFactory.FIELD_MARGIN_BEFORE_WARNING);
        if (val !== null) {
            balance.MarginBeforeWarning = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_WITHDRAWABLE_OPTIONS_PREMIUM);
        if (val !== null) {
            balance.WithdrawableOptionsPremium = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_WITHDRAWAL_AVAILABLE);
        if (val !== null) {
            balance.WithdrawalAvailable = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_MAINTENANCE_MARGIN_REQ_PERCENT);
        if (val !== null) {
            balance.MaintenanceMarginReqPercent = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_INITIAL_MARGIN_REQ_PERCENT);
        if (val !== null) {
            balance.InitialMarginReqPercent = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_WARNING_MARGIN_REQ_PERCENT);
        if (val !== null) {
            balance.WarningMarginReqPercent = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_STOP_OUT_VALUE);
        if (val !== null) {
            balance.StopOutValue = val;
            balance.IsEmpty = false;
        }

        val = fs.GetValue(FieldsFactory.FIELD_EOD_TRAILING_DRAWDOWN_LEVEL);
        if (val !== null) {
            balance.EODTrailingDrawdownLevel = val;
            balance.IsEmpty = false;
        }

        const additionalDataGroups = fs.GetGroups(FieldsFactory.KEY_VALUE_GROUP_BEAN);
        if (additionalDataGroups.length > 0) {
            let index = 2000;
            let grIndex = 2000;
            const isPresent = {};
            balance.AssetAdditionalInfo = {};// new Dictionary<string, AccountAdditionalInfoItem>();
            for (let gr_i = 0; gr_i < additionalDataGroups.length; gr_i++) {
                const additionalDataGroup = additionalDataGroups[gr_i];
                const groupName = additionalDataGroup.GetValue(FieldsFactory.FIELD_NAME);
                const gr = additionalDataGroup.GetGroups(FieldsFactory.KEY_VALUE_GROUP);

                if (!isPresent[groupName]) {
                    isPresent[groupName] = grIndex++;
                }

                for (let i = 0; i < gr.length; i++) {
                    const keyValueGroup = gr[i];
                    const key = 'panel.accountDetails.' + groupName + '.' + keyValueGroup.GetValue(FieldsFactory.FIELD_KEY);
                    const value = keyValueGroup.GetValue(FieldsFactory.FIELD_VALUE);

                    const item = new AccountAdditionalInfoItem();
                    item.APIKey = key;
                    item.GroupInfo = 'panel.accountDetails.Groups.' + groupName;
                    item.GroupIndex = grIndex;
                    item.SortIndex = index;
                    item.NameKey = key;
                    item.ToolTipKey = key;
                    item.Value = value;
                    item.DataType = QuickTableComparingType.String;
                    item.Visible = true;
                    balance.AssetAdditionalInfo[key] = item;
                    index++;
                }
            }
        }

        AccountStatusMessageProcessor.TryAddBuyingPowerAvailableFunds(fs, balance); // New margin reqirements for Indian market #106952

        return balance;
    }

    public static TryAddBuyingPowerAvailableFunds (fs: PFixFieldSet, balance: AssetBalanceMessage): boolean // #106952
    {
        if (!balance) {
            return false;
        }

        if (!balance.BuyingPowerAvailableFunds) {
            balance.BuyingPowerAvailableFunds = [];
        }

        let groups = fs.GetGroups(FieldsFactory.BUYING_POWER_AVAILABLE_FUNDS_FOR_EQUITIES);
        for (let i = 0; i < groups.length; i++) {
            const group = new BuyingPowerAvailableFundsForEquitiesGroup(groups[i]);
            if (group) {
                balance.BuyingPowerAvailableFunds.push(new BuyingPowerAvailableFund(group.getBuyingPowerName(), group.getAvailableMargin()));
            }
        }

        groups = fs.GetGroups(FieldsFactory.BUYING_POWER_AVAILABLE_FUNDS_FOR_OPTIONS_BUY);
        for (let i = 0; i < groups.length; i++) {
            const group = new BuyingPowerAvailableFundsForOptionsBuyGroup(groups[i]);
            if (group) {
                balance.BuyingPowerAvailableFunds.push(new BuyingPowerAvailableFund(group.getBuyingPowerName(), group.getAvailableMargin()));
            }
        }

        return AccountStatusMessageProcessor.AddBuyingPowerAvailableFundsToAssetAdditionalInfo(balance);
    }

    public static AddBuyingPowerAvailableFundsToAssetAdditionalInfo (balance: AssetBalanceMessage): boolean // #106952
    {
        let result = false;
        if (!balance) {
            return result;
        }

        const funds = balance.BuyingPowerAvailableFunds;
        if (funds?.length) {
            if (!balance.AssetAdditionalInfo) {
                balance.AssetAdditionalInfo = {};
            }

            for (let i = 0; i < funds.length; i++) {
                const fund = funds[i]; const key = fund.Name; const val = fund.AvailableMargin;
                const addInfo = balance.AssetAdditionalInfo[key] = new AccountAdditionalInfoItem();

                addInfo.Update({
                    APIKey: key,
                    NameKey: key,
                    ToolTipKey: key,
                    FormatingType: AccountAdditionalInfoItemFormatingType.AssetBalance,
                    GroupInfo: 'panel.accountDetails.Groups.1.General',
                    GroupIndex: 2000,
                    SortIndex: i + 32,
                    Value: val,
                    DataType: QuickTableComparingType.Double, // #113408
                    Destination: AccountAdditionalInfoDestinationPanel.AllPanels,
                    UseColor: true,
                    Visible: true
                });
            }

            result = true;
        }

        return result;
    }
}
