// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
const CONST_THEME_NAME = 'themeName';
const CONST_VIEWED_NEWS = 'viewedNews';
const CONST_LAST_CONNECTION_NAME = 'lastConnectionName';
const CONST_TOKEN_FOR_PORTFOLIO = 'TokenForPortfolio';
const CONST_LOCAL_STORAGE_SELECTED_CARD = 'notifPanelSelCardID';
const CONST_LANGUAGE = 'language';
const CONST_CONNECTION_SETTINGS = 'cs';
const CONST_WEB_MOBILE_WATCHLIST_NAME = 'webMobileWatchlistName';
const CONST_WEB_MOBILE_SELECTED_ACCOUNT = 'webMobileSelectedAccount';
const CONST_WEB_MOBILE_SELECTED_CHART_PERIOD = 'webMobileSelectedChartPeriod';
const CONST_WEB_MOBILE_CHART_SETTINGS = 'webMobileChartSettings';
const CONST_WEB_MOBILE_SELECTED_CHART_STYLE = 'webMobileSelectedChartStyle';
const CONST_WEB_MOBILE_CHART_INDICATORS_SETTINGS = 'webMobileChartIndicatorsSavedSettings';
const CONST_WEB_MOBILE_INDICIES_ACTIVE_HISTORY_PERIOD = 'webMobileIndiciesActiveHistoryPeriod';
const CONST_WEB_MOBILE_IS_ACCOUNT_LINKING_ACTIVE = 'IsAccountLinkingActive';
const CONST_WEB_MOBILE_FOOTER_ACTIVE_PANEL_TYPE = 'webMobileFooterActivePanelType';
const CONST_WEB_MOBILE_MORE_ACTIVE_PANEL_TYPE = 'webMobileMoreActivePanelType';

class _LocalStorage {
    credentialsStorage: any = {};
    webMobileDetailsGroupItemStateMap = new Map<number, boolean>();

    getThemeName (): string {
        return localStorage.getItem(CONST_THEME_NAME);
    }

    setThemeName (themeName): void {
        localStorage.setItem(CONST_THEME_NAME, themeName);
    }

    getViewedNews (): string {
        return localStorage.getItem(CONST_VIEWED_NEWS);
    }

    setViewedNews (jsonViewedNews): void {
        localStorage.setItem(CONST_VIEWED_NEWS, jsonViewedNews);
    }

    getLastConnectionName (): string {
        return localStorage.getItem(CONST_LAST_CONNECTION_NAME);
    }

    setLastConnectionName (connectionName): void {
        localStorage.setItem(CONST_LAST_CONNECTION_NAME, connectionName);
    }

    setTokenForPortfolio (token): void {
        localStorage.setItem(CONST_TOKEN_FOR_PORTFOLIO, token);
    }

    getTokenForPortfolio (): string {
        return localStorage.getItem(CONST_TOKEN_FOR_PORTFOLIO);
    }

    removeTokenForPortfolio (): void {
        localStorage.removeItem(CONST_TOKEN_FOR_PORTFOLIO);
    }

    getNotificationPanelSelectedCards (): string {
        return localStorage.getItem(CONST_LOCAL_STORAGE_SELECTED_CARD);
    }

    setNotificationPanelSelectedCards (selectedId): void {
        localStorage.setItem(CONST_LOCAL_STORAGE_SELECTED_CARD, selectedId);
    }

    getLanguage (): string {
        return localStorage.getItem(CONST_LANGUAGE);
    }

    setLanguage (language): void {
        localStorage.setItem(CONST_LANGUAGE, language);
    }

    getSelectedWebMobileWatchlistName (): string {
        return localStorage.getItem(CONST_WEB_MOBILE_WATCHLIST_NAME);
    }

    setSelectedWebMobileWatchlistName (watchlistName): void {
        localStorage.setItem(CONST_WEB_MOBILE_WATCHLIST_NAME, watchlistName);
    }

    getSelectedWebMobileAccount (): string {
        return localStorage.getItem(CONST_WEB_MOBILE_SELECTED_ACCOUNT);
    }

    setSelectedWebMobileAccount (accountName): void {
        localStorage.setItem(CONST_WEB_MOBILE_SELECTED_ACCOUNT, accountName);
    }

    getSelectedWebMobileChartPeriod (): string {
        return localStorage.getItem(CONST_WEB_MOBILE_SELECTED_CHART_PERIOD);
    }

    setSelectedWebMobileChartPeriod (period): void {
        localStorage.setItem(CONST_WEB_MOBILE_SELECTED_CHART_PERIOD, period);
    }

    getSelectedWebMobileChartStyle (): string {
        return localStorage.getItem(CONST_WEB_MOBILE_SELECTED_CHART_STYLE);
    }

    setSelectedWebMobileChartStyle (style): void {
        localStorage.setItem(CONST_WEB_MOBILE_SELECTED_CHART_STYLE, style);
    }

    getWebMobileChartSettings (): string {
        return localStorage.getItem(CONST_WEB_MOBILE_CHART_SETTINGS);
    }

    setWebMobileChartSettings (settings): void {
        localStorage.setItem(CONST_WEB_MOBILE_CHART_SETTINGS, settings);
    }

    getWebMobileChartIndicatorsSettings (): string {
        return localStorage.getItem(CONST_WEB_MOBILE_CHART_INDICATORS_SETTINGS);
    }

    setWebMobileChartIndicatorsSettings (settings): void {
        localStorage.setItem(CONST_WEB_MOBILE_CHART_INDICATORS_SETTINGS, settings);
    }

    getWebMobileIndiciesActiveHistoryPeriod (): string {
        return localStorage.getItem(CONST_WEB_MOBILE_INDICIES_ACTIVE_HISTORY_PERIOD);
    }

    setWebMobileIndiciesActiveHistoryPeriod (historyPeriod): void {
        localStorage.setItem(CONST_WEB_MOBILE_INDICIES_ACTIVE_HISTORY_PERIOD, historyPeriod);
    }

    getIsAccountLinkingActive (): boolean {
        return localStorage.getItem(CONST_WEB_MOBILE_IS_ACCOUNT_LINKING_ACTIVE) === 'true';
    }

    setIsAccountLinkingActive (isActive: boolean): void {
        localStorage.setItem(CONST_WEB_MOBILE_IS_ACCOUNT_LINKING_ACTIVE, isActive.toString());
    }

    getWebMobileFooterActivePanelType (): number {
        const localSave = +localStorage.getItem(CONST_WEB_MOBILE_FOOTER_ACTIVE_PANEL_TYPE);
        return localSave;
    }

    setWebMobileFooterActivePanelType (activePanelType): void {
        localStorage.setItem(CONST_WEB_MOBILE_FOOTER_ACTIVE_PANEL_TYPE, activePanelType);
    }

    getWebMobileMoreActivePanelType (): number {
        const localSave = localStorage.getItem(CONST_WEB_MOBILE_MORE_ACTIVE_PANEL_TYPE);
        if (!isValidString(localSave)) {
            return null;
        } else {
            return +localSave;
        }
    }

    setWebMobileMoreActivePanelType (activePanelType): void {
        let param = activePanelType;
        if (isNullOrUndefined(activePanelType)) { param = ''; }
        localStorage.setItem(CONST_WEB_MOBILE_MORE_ACTIVE_PANEL_TYPE, param);
    }

    getWebMobileDetailsGroupItemState (): Map<number, boolean> {
        return this.webMobileDetailsGroupItemStateMap;
    }

    setWebMobileDetailsGroupItemState (stateMap: Map<number, boolean>): void {
        this.webMobileDetailsGroupItemStateMap = stateMap;
    }

    setConnectionSettings (connectionName: string, login: string, pass: string, customerNo: string): void {
        const objTsave: any = {};
        if (login) {
            objTsave.u = login;
        }

        if (pass != null) {
            objTsave.ep = pass;
        }

        if (customerNo) {
            objTsave.cN = customerNo;
        }

        this.credentialsStorage[connectionName] = objTsave;
        localStorage.setItem(CONST_CONNECTION_SETTINGS, JSON.stringify(this.credentialsStorage));
    }

    getConnectionSettings (name: string): [string, string | null, string] {
        const c = this.credentialsStorage[name];
        if (c != null) {
            const login = c.u || '';
            const encryptedPass = null; // c.ep ?? null; // commented out due to fix already saved passwords #122310 + #122295
            const customerNo = c.cN || '';
            return [login, encryptedPass, customerNo];
        }
        return ['', null, ''];
    }

    loadConnectionSettings (): void {
        try {
            const cs = localStorage.getItem(CONST_CONNECTION_SETTINGS);
            if (!cs) {
                const lcn = localStorage.getItem(CONST_LAST_CONNECTION_NAME);
                if (lcn) {
                    const u = localStorage.getItem('user') || '';
                    const cN = localStorage.getItem('customerNo') || '';
                    const nD = {};
                    const objts: any = { u };
                    if (cN) {
                        objts.cN = cN;
                    }

                    nD[lcn] = objts;
                    this.credentialsStorage = nD;
                }
                localStorage.removeItem('user');
                localStorage.removeItem('customerNo');
                localStorage.setItem('cs', JSON.stringify(this.credentialsStorage));
            } else {
                this.credentialsStorage = JSON.parse(cs);
            }

            localStorage.removeItem('pwd');
        } catch (ex) {

        }
    }
}

export const LocalStorage = new _LocalStorage();
