// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts";
import { TerceraPopupBaloonTemplate } from "../../templates.js";
import { Control } from "./Control.js";
import { ThemeManager } from "../misc/ThemeManager.ts";

export let TerceraPopupBaloon = Control.extend({
    template: TerceraPopupBaloonTemplate,
    data: function ()
    {
        return {
            text: '',
            iconUrl: ''
        };
    }
});

TerceraPopupBaloon.prototype.show = function (data)
{
    this.set({
        text: Resources.getResource(data.locKey),
        iconUrl: ThemeManager.getImageFullUrl(data.iconUrl)
    });

    var $dom = $(this.find('div'));
    $dom.stop().fadeIn(1000, function () { $dom.fadeOut(1000); });
};

TerceraPopupBaloon.States = {
    WorkspaceLocked: {
        locKey: 'workspace.Lock.Message',
        iconUrl: 'o_notification/48x48_icon_locked.png'
    },
    WorkspaceUnlocked: {
        locKey: 'workspace.Unlock.Message',
        iconUrl: 'o_notification/48x48_icon_unlocked.png'
    },
    WorkspaceSaved: {
        locKey: 'workspace.saved.Message',
        iconUrl: 'o_notification/48x48_icon_succeeded.png'
    },
    TradingLocked: {
        locKey: 'ribbon.enviroment.NotificationWindow.lockTrading',
        iconUrl: 'o_notification/48x48_icon_locked.png'
    },
    TradingUnlocked: {
        locKey: 'ribbon.enviroment.NotificationWindow.UnlockTrading',
        iconUrl: 'o_notification/48x48_icon_unlocked.png'
    }
};

Object.freeze(TerceraPopupBaloon.States);