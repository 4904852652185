// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectFundingRateMarkupPlanMessage } from '../../../../Utils/DirectMessages/DirectFundingRateMarkupPlanMessage';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class FundingRateMarkupPlanMessageProcessor extends MessageProcessorBase {
    public override decode (fs: PFixFieldSet): DirectFundingRateMarkupPlanMessage[] {
        const msg = new DirectFundingRateMarkupPlanMessage();

        msg.Id = fs.GetValue(FieldsFactory.FIELD_FUNDING_RATE_MARKUP_PLAN_ID);
        msg.Name = fs.GetValue(FieldsFactory.FIELD_NAME);

        const groups = fs.GetGroups(FieldsFactory.FUNDING_RATE_MARKUP_ENTRY_GROUP);
        for (let i = 0; i < groups.length; i++) {
            const gr = groups[i];
            const data: any = {};

            data.MarkupType = gr.GetValue(FieldsFactory.FIELD_MARKUP_TYPE);
            data.MarkupValue = gr.GetValue(FieldsFactory.FIELD_MARKUP_VALUE);

            data.InstrumentId = gr.GetValue(FieldsFactory.FIELD_INSTRUMENT_ID);
            data.InstrumentGroupId = gr.GetValue(FieldsFactory.FIELD_INSTRUMENTTYPE_ID);

            msg.Data.push(data);
        }

        return [msg];
    }
}
