import { type HeatmapData } from '../../../Commons/Heatmap/Models/HeatmapData';
import { type Instrument } from '../../../Commons/cache/Instrument';
import { ImagesManager } from '../../misc/ImagesManager';
import { EventEmitter } from 'events';

export class _HeatmapLogoProvider {
    private readonly _eventEmitter: EventEmitter = new EventEmitter();

    private logoCache: any = {};

    loadLogos (heatmapData: HeatmapData[]): void {
        if (!isValidArray(heatmapData)) {
            return;
        }

        const data = [...heatmapData];

        new Promise<void>((resolve) => {
            for (let i = 0; i < data.length; i++) {
                const logoAddress = data[i].Instrument.LogoAddress;
                if (isValidString(logoAddress) && !this.isLogoExist(logoAddress)) {
                    ImagesManager.GetSvg(logoAddress).then(logo => {
                        if (!isNullOrUndefined(logo)) {
                            const image = new Image();
                            image.src = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(logo);
                            image.onload = () => {
                                this.logoCache[logoAddress] = image;
                                this._eventEmitter.emit('load', data[i].Instrument);
                            };
                        } else {
                            this.logoCache[logoAddress] = null;
                        }
                    }).catch(() => {
                        this.logoCache[logoAddress] = null;
                    });
                }
            }
            resolve();
        }).then(() => {
            // Success
        }).catch(() => {
            // Error
        });
    }

    isLogoExist (logoAddress: string): boolean {
        if (!isValidString(logoAddress)) {
            return false;
        }
        const logo = this.logoCache[logoAddress];
        return !isNullOrUndefined(logo);
    }

    getLogo (logoAddress: string): any {
        if (!isValidString(logoAddress)) {
            return null;
        }
        return this.logoCache[logoAddress];
    }

    public subscribeOnLoad (callback: (instrument: Instrument) => void): void {
        this._eventEmitter.on('load', callback);
    }

    public unsubscribeOnLoad (callback: (instrument: Instrument) => void): void {
        this._eventEmitter.off('load', callback);
    }
}

export const HeatmapLogoProvider = new _HeatmapLogoProvider();
