// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { PFixFieldSet } from '../Fields/FieldsImport';
import { FieldsFactory } from '../Factory/FieldsFactory';
export class SupportedAggregationsGroup extends PFixFieldSet {
    constructor (pfixFieldSet) {
        super(FieldsFactory.SUPPORTED_AGGREGATIONS_GROUP, null, pfixFieldSet);
    }

    public getAggregatedType (): number {
        return super.GetValue(FieldsFactory.FIELD_AGG_TYPE);
    }

    public getAggregatedPeriod (): number {
        return super.GetValue(FieldsFactory.FIELD_AGG_PERIOD);
    }

    public getAggregatedValue (): number {
        return super.GetValue(FieldsFactory.FIELD_AGG_VALUE);
    }
}
