// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { ModifyLimitOrder } from '../order-modify/ModifyLimitOrder';
import { OrderTypeBase } from './OrderTypeBase';
import { OrderType } from '../../../../Utils/Trading/OrderType';
import { LimitOrderEdit } from '../order-edit/LimitOrderEdit';

export class LimitOrderType extends OrderTypeBase {
    constructor () {
        super(LimitOrderEdit, ModifyLimitOrder);
    }

    public override id (): OrderType {
        return OrderType.Limit;
    }
}
