// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { Message } from '../../../../Utils/DirectMessages/Message';
import { FieldsFactory } from '../Factory/FieldsFactory';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';
import { PFixMessage } from './PFixMessage';

export class MarginRequestMessage extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_MARGIN_REQ, fieldSet);
    }

    public setType (type: number = 0): void {
        this.FieldSet.SetField(FieldsFactory.FIELD_TYPE, type);
    }
}
