// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PFixFieldSet } from '../../../Fields/FieldsImport';
import { FieldsFactory } from '../../../Factory/FieldsFactory';

export class AlgorithmTopLongShortInstrumentGroup extends PFixFieldSet {
    constructor (pfixFieldSet: PFixFieldSet) {
        super(FieldsFactory.ALGORITHM_TOP_LONG_SHORT_INSTRUMENT_GROUP, null, pfixFieldSet);
    }

    GetGroupContent = (): {
        TradableId: number
        RouteId: number
        InstrumentName: string
        InstrumentNameTypeId: number
        Percent: number
        MarketConsensusPercentType: number
        IsExpertChoice: boolean
    } => {
        const result = {
            TradableId: super.GetValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID),
            RouteId: super.GetValue(FieldsFactory.FIELD_TRADE_ROUTE_ID),
            InstrumentName: super.GetValue(FieldsFactory.FIELD_INSTRUMENT_NAME),
            InstrumentNameTypeId: super.GetValue(FieldsFactory.FIELD_INSTRUMENTTYPE_ID),
            Percent: super.GetValue(FieldsFactory.FIELD_PERCENT),
            MarketConsensusPercentType: super.GetValue(FieldsFactory.FIELD_MARKET_CONSENSUS_PERCENT_TYPE),
            IsExpertChoice: super.GetValue(FieldsFactory.FIELD_ALGORITHM_TOP_LONG_TOP_SHORT_EXPERT_CHOICE)
        };

        return result;
    };
}
