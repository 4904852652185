// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { AlertUtils } from "../../../Utils/Alert/AlertUtils.ts";
import { CreateAlertConditionSelectorTemplate } from "../../../templates.js";
import { ContainerControl } from "../../elements/ContainerControl.js";
import { Resources } from "../../../Commons/properties/Resources.ts";
import { AlertCondition } from "../../../Utils/Alert/AlertConstants.ts";

export let CreateAlertConditionSelector = ContainerControl.extend({
    template: CreateAlertConditionSelectorTemplate,
    data: function ()
    {
        return {
            label: '',
            instrument: null,

            items: null,
            selectedItem: null,
            value: -1,

            width: 198
        }
    }
})

CreateAlertConditionSelector.prototype.getType = function () { return 'CreateAlertConditionSelector' };

CreateAlertConditionSelector.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.call(this)

    this.repopulate();
    this.observe('selectedItem', this.updateAlertCondition);
    this.observe('value', this.updateAlertConditionValue);

    Resources.onLocaleChange.Subscribe(this.localize, this);
    this.localize();
}

CreateAlertConditionSelector.prototype.updateAlertCondition = function (selectedItem)
{
    if (selectedItem)
        this.set({ value: selectedItem.value })
}

CreateAlertConditionSelector.prototype.updateAlertConditionValue = function (newV, oldV)
{
    let items = this.get("items")
    if (!items)
        return;

    for (let i = 0; i < items.length; i++)
        if (items[i].value === newV)
        {
            this.set({ selectedItem: items[i] })
            break;
        }
}

CreateAlertConditionSelector.prototype.dispose = function ()
{
    Resources.onLocaleChange.UnSubscribe(this.localize, this)

    ContainerControl.prototype.dispose.call(this)
}

CreateAlertConditionSelector.prototype.repopulate = function ()
{
    let item = AlertUtils.GetAlertConditionItems();
    this.set({ items: item, selectedItem: item[AlertCondition.MORE] });
}

CreateAlertConditionSelector.prototype.localize = function ()
{
    this.set('label', Resources.getResource('screen.Alerts.Label.Condition'))
}