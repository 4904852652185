// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";
import { TerceraImagePreviewScreenTemplate } from "../../templates.js";
import { TerceraWindowBase } from "./TerceraWindowBase.js";

// TODO. Duplicated code/template taken from TerceraPictureBox.js

export let TerceraImagePreviewScreen = TerceraWindowBase.extend({
    data: function ()
    {
        return {
            okText: "OK",
            imageUrl: '',
            movable: false,
            resizable: false,
            showFooter: false,
            visible: false,
            zIndex: 1000
        };
    },
    partials: { bodyPartial: TerceraImagePreviewScreenTemplate }
});

let instance = null;

TerceraImagePreviewScreen.prototype.close = function ()
{
    this.set('visible', false);
};

TerceraImagePreviewScreen.prototype.oninit = function ()
{
    TerceraWindowBase.prototype.oninit.apply(this);
    this.on('imageLoaded', this.layout);
};

TerceraImagePreviewScreen.prototype.layout = function ()
{
    var img = this.find('img');
    if (!img) return;

    var margin = 5;
    var headerH = 20;

    this.set({
        width: (img.naturalWidth || 118) + 2 * margin,
        height: (img.naturalHeight || 98) + 2 * margin + headerH
    });

    this.center();
};

TerceraImagePreviewScreen.show = function (imageUrl, imageName)
{
    var scr = TerceraImagePreviewScreen.getInstance();
    scr.set({
        imageUrl: imageUrl,
        header: imageName,
        visible: true
    });
    // If image is loaded already event won't fire, thus make a forced call.
    scr.layout();
    scr.setFocus();
};

TerceraImagePreviewScreen.getInstance = function ()
{
    if (!instance)
    {
        var scr = new TerceraImagePreviewScreen();
        instance = scr;
        MainWindowManager.MainWindow.addControl(scr);
    }

    return instance;
};