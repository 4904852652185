// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type NewsGroup } from '../../Vendors/PFSVendor/WebFix/Groups/GroupsImport';
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectNewsMessage extends DirectMessageBaseClass {
    public NewsGroupsArr: NewsGroup[] = [];
    public RequestID: number;

    constructor () {
        super(Message.CODE_NEWS_MESSAGE);
    }
}
