// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';
export class DirectExternalLinkMessage extends DirectMessageBaseClass {
    public Id: any;
    public Name: any;
    public Url: any;
    public UseInternalBrowser: any;
    public ExternalResource: any;
    public ExternalLinkParameters: any;
    public ExternalLinkWebContentDark: string;
    public ExternalLinkWebContentLight: string;

    constructor () {
        super(Message.CODE_PFIX_EXTERNAL_LINK_MESSAGE);

        this.Id = null;
        this.Name = null;

        this.Url = null;
        this.UseInternalBrowser = null;

        this.ExternalResource = null;
        this.ExternalLinkParameters = null;
        this.ExternalLinkWebContentDark = null;
        this.ExternalLinkWebContentLight = null;
    }
}
