// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type HistoryMergerInputParams } from '../../../../Utils/History/HistoryMergerInputParams';
import { Periods } from '../../../../Utils/History/TFInfo';
import { DateFormater } from '../../../../Utils/Time/DateFormater';
import { HistoryMergerBasedByDay } from './HistoryMergerBasedByDay';
import { type DateTime } from 'luxon';

export class HistoryMergerWeek extends HistoryMergerBasedByDay {
    private readonly firstDayOfWeek: number = 1; // Monday

    constructor (inputParams: HistoryMergerInputParams) {
        super(inputParams);
        this.firstDayOfWeek = this.defaultPeriod.FirstDayOfWeek;
    }

    protected override GetDaysInPeriod (dateTime: DateTime): number {
        let result = 0;

        for (let i = 0; i < this.GetCount(); i++) { result += 7; }

        return result;
    }

    protected override IsStartPeriodDate (dateTime: DateTime): boolean {
        // id:118400  - first day of week is from server
        return dateTime.weekday === this.firstDayOfWeek; // Monday
    }

    protected override GetBasePeriod (): number {
        return Periods.WEEK;
    }
}
