// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomErrorClass, ErrorInformationStorage } from "../../Commons/ErrorInformationStorage.ts";
import { Resources } from "../../Commons/properties/Resources.ts";
import { HistoryType } from "../../Utils/History/HistoryType.ts";
import { Pen, SolidBrush } from "../../Commons/Graphics.ts";
import { ModelDataType, TerceraChartMVCCommand } from "../../Chart/TerceraChartMVC.ts";
import { TerceraChartDrawingType } from "../../Chart/Utils/ChartConstants.ts";
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";
import { HtmlScroll } from "../../Commons/HtmlScroll.js";
import { KeyCode, KeyEventProcessor } from "../../Commons/KeyEventProcessor.ts";
import { IdeasPanelTemplate, IdeaDescriptionTemplate } from "../../templates.js";
import { TerceraMessageBox } from "../screen/TerceraMessageBox.js";
import { PanelNames } from "../UtilsClasses/FactoryConstants.ts";
import { ApplicationPanelNew } from "./ApplicationPanelNew.js";
import { ThemeManager } from "../misc/ThemeManager.ts";
import { Periods } from "../../Utils/History/TFInfo.ts";
import { OrderType } from "../../Utils/Trading/OrderType.ts";
import { ProductType } from "../../Utils/Instruments/ProductType.ts";
import { SlTpPriceType } from "../../Utils/Enums/Constants.ts";
import { PlacedFrom } from "../../Utils/Trading/PlacedFrom.ts";
import { InstrumentTypes, InstrumentTypesImageFileNameMap } from "../../Utils/Instruments/InstrumentTypes.ts";
import { AccountType } from "../../Utils/Account/AccountType.ts";
import { TIF } from "../../Utils/Trading/OrderTif.ts";
import { GeneralSettings } from "../../Utils/GeneralSettings/GeneralSettings.ts";
import { OrderUtils } from "../../Utils/Trading/OrderUtils.ts";
import { ProfitCalculator } from "../../Commons/cache/ProfitCalculator.ts";
import { InstrumentUtils } from "../../Utils/Instruments/InstrumentUtils.ts";
import { IsAllowed } from "../../Commons/IsAllowed.ts";
import { Quantity } from "../../Utils/Trading/Quantity.ts";
import { SlTpHolder } from "../../Utils/Trading/SlTpHolder.ts";
import { OrderEditUpdateData } from "../../Utils/Trading/OrderEditUpdateData.ts";
import { DataCache } from "../../Commons/DataCache.ts";
import { SessionSettings } from "../../Commons/SessionSettings.ts";
import { TradingNumericErrorChecker } from "../../Commons/Trading/TradingNumericErrorChecker.ts";

export let IdeasPanel = ApplicationPanelNew.extend(
    {
        partials: {
            bodyPartial: IdeasPanelTemplate,
            ideaDescriptionPartial: IdeaDescriptionTemplate
        },
        data: function ()
        {
            return {
                width: 460,
                height: 658,
                canLinkByAccount: false,
                isAccountLinkShow: false,
                isSymbolLinkShow: false,
                dockablePanel: false,
                showHeader: true,
                showFooter: false,
                resizable: false,
                movable: false,
                closeBtnVisible: false,
                style_addition_header: 'js-PropertySetupScreen-AdditionalHeader',

                types: [],          // instrument types for horizontal scroller (filtering)
                ideas: [],          // ideas to show in panel

                allIdeas: [],       // all available ideas
                quantity: null,
                quantityLabel: '',
                descriptionOpenedTitle: '',
                descriptionIsOpen: false,

                instrumentItem: null,
                account: null,
                singleAccount: true,
                productType: null,
                productTypeLabel: '',
                productTypeShow: false,
                leverageShow: false,
                tradingAllowed: true,
                tradingForbiddenReason: '',

                takeProfitTitle: '',
                limitPriceTitle: '',
                stopLossTitle: '',
                descrBtnVisible: false,

                dataSourceOpen: false,
                dataSourceVisible: false,

                attachmentsFile: '',
                attachmentsScreen: '',
                orderButtonTextBuy: '',
                orderButtonTextSell: '',

                currentRiskDisclosureTradingSystemID: null,
                allRiskDisclosuresAccepted: true,
                textAgreeButton: 'panel.Ideas.riskDisclosure.agreeBtnTxt'
            }
        },
        computed: {
            terceraChartPanelContext: {
                get: function () { return this; },
                set: function (value) { }
            }
        },
        headerLocaleKey: 'panel.Ideas',
        NeedCalculateRowCount: false,
        lifeTimeIntervalId: null,
        terceraChartRactive: null,
        openIdeaId: null,
        productTypeChangeConfirmationIsInFocus: false,
        chartInited: false,
        skipOnSave: true,
        needUpdateScroll: false,
        filteringAvailableByKey: false,
        filteringIsEnabled: false,      // может быть включен ключом, но отсутствовать если идеи только по одному instrType
        selectedTypeIndex: null     // выбранный тип фильтрации
    });

IdeasPanel.prototype.getType = function () { return PanelNames.IdeasPanel };

IdeasPanel.prototype.oninit = function ()
{
    ApplicationPanelNew.prototype.oninit.apply(this)

    this.observe('visible', this.ideasPanelVisibilityChanged)
    this.observe('productType', this.onProductTypeChanged)
    this.observe('instrumentItem', this.onInstrumentChanged)
    this.observe('account', this.onAccountChanged)
    this.observe('quantity', this.recountLossAndProfit)

    this.on('agreeBtnClick', this.agreeBtnClick)
    this.on('ideaClick', this.ideaSelect)
    this.on('ideaClose', this.ideaHide)
    this.on('descriptionChangeOpenState', this.descriptionOpenOrClose)
    this.on('placeOrder', this.placeOrder)

    this.on('onDescrOver', this.onDescrOver)
    this.on('onDescrOut', this.onDescrOut)

    this.on('fileClick', this.attachmentClick.bind(this, false))
    this.on('screenClick', this.attachmentClick.bind(this, true))

    this.on("timeFrameComboBox_ComboItemClicked", this.onTimeFrameComboBox_ComboItemClicked);

    this.on('selectTypeToFilter', this.selectTypeToFilter)
    this.on('typesScrollToRight', this.typesScrollTo.bind(this, true))
    this.on('typesScrollToLeft', this.typesScrollTo.bind(this, false))

    this.set('quantityVisible', !Resources.isHidden('panel.Ideas.quantityVisiblility'))
    this.set('orderButtonVisibility', !Resources.isHidden('panel.Ideas.orderButtonVisibility'))

    this.set('srcFromLogoBytes', (logoBytes) => { return this.srcFromLogoBytes(logoBytes) })
}

IdeasPanel.prototype.oncomplete = function ()
{
    ApplicationPanelNew.prototype.oncomplete.apply(this)

    this.eventsSubscribe()

    this.showRiskDisclosureIfNeed()

    this.loadAllIdeas()

    this.repopulateAccounts()

    this.onInstrumentChanged(this.get('instrument'), null)
}

IdeasPanel.prototype.dispose = function ()
{
    this.eventsUnSubscribe()

    let instrument = this.get('instrumentItem');
    if (instrument)
    {
        instrument.RiskSettingsUpdated.UnSubscribe(this.updateTradingAllowedStuff, this)
        this.unsubscribe(instrument)
    }

    let ractiveChart = this.terceraChartRactive
    if (ractiveChart && ractiveChart.terceraChart)
        ractiveChart.terceraChart.Dispose()

    ApplicationPanelNew.prototype.dispose.apply(this);
};


IdeasPanel.prototype.eventsSubscribe = function ()
{
    DataCache.OnTradingIdeaAdd.Subscribe(this.addIdea, this)
    DataCache.OnTradingIdeaCancel.Subscribe(this.cancelIdea, this)
    DataCache.OnTradingSystemsChange.Subscribe(this.showRiskDisclosureIfNeed, this)

    DataCache.OnTradingSystemUnsubscribe.Subscribe(this.onTradingSystemUnsubscribe, this)

    KeyEventProcessor.OnKeyDown.Subscribe(this.onGlobalKeyDown, this);
}

IdeasPanel.prototype.eventsUnSubscribe = function ()
{
    DataCache.OnTradingIdeaAdd.UnSubscribe(this.addIdea, this)
    DataCache.OnTradingIdeaCancel.UnSubscribe(this.cancelIdea, this)
    DataCache.OnTradingSystemsChange.UnSubscribe(this.showRiskDisclosureIfNeed, this)

    DataCache.OnTradingSystemUnsubscribe.UnSubscribe(this.onTradingSystemUnsubscribe, this)

    KeyEventProcessor.OnKeyDown.UnSubscribe(this.onGlobalKeyDown, this);
}

IdeasPanel.prototype.onGlobalKeyDown = function ()
{
    var keyProc = KeyEventProcessor;
    if (keyProc.currentButton === KeyCode.ESC)   // #103388
        this.set('visible', false)
}

IdeasPanel.prototype.updateSettings = function ()
{
    ApplicationPanelNew.prototype.updateSettings.apply(this);

    let ins = this.get('instrumentItem'), acc = this.get('account')
    if (!ins || !acc)
        return
    this.set('tradingAllowed', IsAllowed.IsTradingAllowed([acc], ins, OrderType.Limit).Allowed)
};

IdeasPanel.prototype.localize = function ()
{
    ApplicationPanelNew.prototype.localize.apply(this);

    this.set({
        textAgreeButton: Resources.getResource('panel.Ideas.riskDisclosure.agreeBtnTxt'),
        quantityLabel: Resources.getResource('panel.newOrderEntry.amountLabel') + ':',
        descriptionOpenedTitle: Resources.getResource('InstrumentDetailsPanel.Description') + ':',
        takeProfitTitle: Resources.getResource('property.Take-profit') + ':',
        limitPriceTitle: Resources.getResource('panel.newOrderEntry.limitPricePanel') + ':',
        stopLossTitle: Resources.getResource('property.Stop-loss') + ':',
        attachmentsFile: Resources.getResource('panel.Ideas.AttachmentFile') + ': ',
        attachmentsScreen: Resources.getResource('panel.Ideas.AttachmentScreen') + ': ',
        orderButtonTextBuy: Resources.getResource('panel.Ideas.orderButtonTextBuy'),
        orderButtonTextSell: Resources.getResource('panel.Ideas.orderButtonTextSell')
    })

    var timeFrameCB = this.Controls.timeFrameComboBox;
    if (timeFrameCB)
        timeFrameCB.set('tooltip', Resources.getResource('chart.agregationType.Button.ToolTip'));

    this.updateTimeFrameComboBox();

    this.localizeInstrumentTypes()
};

IdeasPanel.prototype.lostFocus = function ()
{
    ApplicationPanelNew.prototype.lostFocus.apply(this)

    if (!this.productTypeChangeConfirmationIsInFocus)
        this.close();
}

IdeasPanel.prototype.close = function ()
{
    if (MainWindowManager.TerceraAccountLookupDropDownForm && MainWindowManager.TerceraAccountLookupDropDownForm.get('visible'))
        return

    this.set({ visible: false });
}

IdeasPanel.prototype.onProductTypeChanged = function (newValue, oldValue)
{
    if (!oldValue || !newValue)
        return

    let ideas = this.get('ideas'),
        idea = ideas[this.openIdeaId]

    if (!idea) return

    let ideaProductType = ideas[this.openIdeaId].productTypeAnal,
        availableProductTypes = InstrumentUtils.getAllowedProductTypeDict(GeneralSettings.TradingDefaults.Symbol)

    if (!ideaProductType || availableProductTypes.indexOf(ideaProductType.toString()) < 0) return

    if (ideaProductType != newValue)
    {
        let self = this,
            msgText = Resources.getResource(newValue == ProductType.Intraday ? 'panel.Ideas.productTypeChangeToIntraday' : 'panel.Ideas.productTypeChangeToDelivery')

        this.productTypeChangeConfirmationIsInFocus = true

        TerceraMessageBox.Show(
            Resources.getResource('general.trading.confirmation'),
            msgText,
            TerceraMessageBox.msgType.Question,
            function ()
            {       // Yes CALLBACK
                self.productTypeChangeConfirmationIsInFocus = false;
                self.setFocus()
            },
            function ()
            {       // No CALLBACK
                self.set('productType', oldValue);
                self.productTypeChangeConfirmationIsInFocus = false;
                self.setFocus()
            })
    }
}

IdeasPanel.prototype.ideasPanelVisibilityChanged = function (value)
{
    let ideasBtn = MainWindowManager.MainWindow.Controls.ideasBtn;

    if (!ideasBtn)
        return

    ideasBtn.set("focused", value)

    this.initLifeTimeTimer(!value)

    if (!value && this.openIdeaId !== null)     // #103388
    {
        this.needUpdateScroll = true                // нужно обновить общий скролл иначе после скрытия панели карточка идеи исчезает при переоткрытии
        this.closeOpenIdea()
    }

    if (value && this.needUpdateScroll)
    {
        this.needUpdateScroll = false;
        this.allIdeasScrollAdd()
    }

    // if (value && this.openIdeaId !== null)   // если была открыта карточка идеи, после переоткрытия панели скролится к открытой карточке здесь
    // {
    //     this.setPricesToShow()

    //     setTimeout(function () { this.allIdeasScrollTo() }.bind(this), 0)           // scroll to opened idea
    // }
}

// Ideas Manangement

IdeasPanel.prototype.initLifeTimeTimer = function (onlyClear)
{
    onlyClear = onlyClear || false

    if (this.lifeTimeIntervalId)
        clearInterval(this.lifeTimeIntervalId)

    if (onlyClear)
        return

    this.ideasLifeTimeUpdate()      // чтобы при открытии уже были правильные лайфтаймы, а не через секунду

    this.lifeTimeIntervalId = setInterval(this.ideasLifeTimeUpdate.bind(this), 1000)
}

IdeasPanel.prototype.ideasLifeTimeUpdate = function ()
{
    let ideas = this.get('ideas')

    if (!ideas.length) return

    for (let i = 0; i < ideas.length; i++)
    {
        let lifeTimeTicks = ideas[i].expireTime - Date.now()
        ideas[i].lifeTime = lifeTimeTicks

        if (ideas[i].isOpen && lifeTimeTicks <= 0)
        {
            this.set('tradingAllowed', false)
            this.set('tradingForbiddenReason', 'Lifetime expired')
        }
    }

    this.set('ideas', ideas)
}

IdeasPanel.prototype.loadAllIdeas = function ()
{
    let tradingIdeas = DataCache.tradingIdeas,
        ideaIDs = Object.keys(tradingIdeas)

    let allIdeas = []

    this.openIdeaId = null

    for (let i = ideaIDs.length - 1; i >= 0; i--)
    {
        let id = ideaIDs[i],
            idea = tradingIdeas[id]

        allIdeas.push(idea)
    }

    this.set('allIdeas', allIdeas)

    this.initTypes()

    this.initLifeTimeTimer()

    this.allIdeasScrollAdd()
}

IdeasPanel.prototype.addIdea = function (idea)
{
    let allIdeas = this.get('allIdeas')

    this.closeOpenIdea()

    let indexToInsert = 0
    for (let i = 0; i < allIdeas.length; i++)
        if (allIdeas[i].createTime > idea.createTime)
            indexToInsert++
        else
            break

    allIdeas.splice(indexToInsert, 0, idea)

    this.set('allIdeas', allIdeas)

    if (this.selectedTypeIndex === null)
        this.set('ideas', allIdeas)

    this.addIdeaToTypes(idea)

    this.allIdeasScrollAdd()
}

IdeasPanel.prototype.cancelIdea = function (idea)
{
    let ideas = this.get('allIdeas'),
        index = ideas.indexOf(idea)

    if (this.openIdeaId === index)  //#90817
        this.openIdeaId = null

    ideas.splice(index, 1)

    this.deleteIdeaFromTypes(idea)

    this.set('allIdeas', ideas)

    if (this.selectedTypeIndex === null)
        this.set('ideas', ideas)
}

IdeasPanel.prototype.ideaSelect = function (context, index)
{
    if (this.openIdeaId !== null && this.openIdeaId == index)
        return

    let ideas = this.get('ideas')
    if (!ideas.length) return

    let idea = ideas[index],
        opening = !idea.isOpen

    this.closeOpenIdea(index)

    this.openIdeaId = index
    idea.isOpen = true

    if (idea.wasNotRead)
    {
        idea.wasNotRead = false
        let tradingSignalId = idea.tradingSignalId

        DataCache.SendTradingSignalSeenRequest(tradingSignalId)
    }

    this.set('ideas', ideas)

    let ins = idea.instrument

    this.initChart(ins)

    let productType = idea.productTypeAnal
    if (productType)
        this.set('productType', productType)

    let acc = this.get('account')

    this.set({
        instrumentItem: ins,
        productTypeShow: ins.isProductTypeVisible(acc), //&& productType,  // есть баг видимости productTypeSelector для кейса : аналитику доступен только General, а подписчику Intraday/Delivery, так что тут судя по всему лучше устанавливать видимость только по инструменту  
        tradingAllowed: true
    })

    this.setPricesToShow()

    this.checkDescrBtnVisibility()

    this.allIdeasScrollTo()
}

IdeasPanel.prototype.ideaHide = function (context, index)
{
    let ideas = this.get('ideas')
    if (!ideas.length || ideas.length <= index) return

    ideas[index].isOpen = false
    this.openIdeaId = null

    this.unsubscribe(this.get('instrumentItem'))

    this.set('ideas', ideas)

    this.set({
        descriptionIsOpen: false,
        dataSourceOpen: false   // #103389
    })

    if (ideas.length == index + 1)      // только при скрытии последней идеи
        this.allIdeasScrollAdd()        // нужно обновить общий скролл иначе после скрытия последней идеи она исчезает (выходит из div-а со скролом)
}

IdeasPanel.prototype.closeOpenIdea = function (exceptIndex)     // закрывает текущую открытую идею если эта не идея с индексом равным аргументу функции
{
    if (this.openIdeaId !== null && this.openIdeaId != exceptIndex)
        this.ideaHide(null, this.openIdeaId)
}

IdeasPanel.prototype.descriptionOpenOrClose = function (context, index)
{
    let isOpen = this.get('descriptionIsOpen')

    this.set('descriptionIsOpen', !isOpen)
}

IdeasPanel.prototype.setPricesToShow = function ()
{
    let ideaID = this.openIdeaId

    if (ideaID === null) return

    let tradingSettings = GeneralSettings.TradingDefaults,
        offsetMode = tradingSettings.ShowOffsetIn,
        sltpOffsetMode = tradingSettings.SetSlTpValuesInOffset,
        ideas = this.get('ideas'),
        idea = ideas[ideaID],
        ins = idea.instrument,
        isBuy = idea.buySide,
        price = idea.limitPrice,
        slPrice = idea.slPrice,
        sllPrice = idea.sllPrice,
        slType = idea.slPriceType,
        tpPrice = idea.tpPrice,
        tpType = idea.tpPriceType

    let slPriceToShow = slPrice,
        sllPriceToShow = sllPrice,
        tpPriceToShow = tpPrice

    let slInOffset = slType != SlTpPriceType.Absolute,
        tpInOffset = tpType != SlTpPriceType.Absolute,
        slNeedToConvert = slInOffset != sltpOffsetMode,
        tpNeedToConvert = tpInOffset != sltpOffsetMode

    if (slType == SlTpPriceType.TrOffset)
        slNeedToConvert = false

    if (slNeedToConvert)
    {
        let slvalue = slPrice * (isBuy ? -1 : 1),
            sllvalue = sllPrice ? (sllPrice + slPrice) * (isBuy ? -1 : 1) : null

        slPriceToShow = slInOffset ? ins.CalculatePrice(price, slvalue) : ins.CalculateTicks(price, slPrice - price)
        if (sllPrice != null)
            sllPriceToShow = slInOffset ? ins.CalculatePrice(price, sllvalue) : ins.CalculateTicks(price, sllPrice - price)
    }

    if (tpNeedToConvert)
    {
        let tpvalue = tpPrice * (isBuy ? 1 : -1)

        tpPriceToShow = tpInOffset ? ins.CalculatePrice(price, tpvalue) : ins.CalculateTicks(price, tpPrice - price)
    }

    if (GeneralSettings.TradingDefaults.IsTicksFractionalForForex())
    {
        slPriceToShow = slInOffset ? OrderUtils.ConvertTickOffset(ins, offsetMode, null, slPriceToShow) : slPriceToShow
        sllPriceToShow = slInOffset ? OrderUtils.ConvertTickOffset(ins, offsetMode, null, sllPriceToShow) * Math.sign(sllPriceToShow) : sllPriceToShow
        tpPriceToShow = tpInOffset ? OrderUtils.ConvertTickOffset(ins, offsetMode, null, tpPriceToShow) : tpPriceToShow
    }

    idea.slPriceToShow = idea.slPriceToShowFormatted = slPriceToShow
    idea.tpPriceToShow = idea.tpPriceToShowFormatted = tpPriceToShow
    idea.sllPriceToShow = sllPriceToShow

    if (sltpOffsetMode)
    {
        idea.slPriceToShowFormatted = ins.formatOffset(slPriceToShow)

        if (sllPrice != null)
            idea.slPriceToShowFormatted += '/' + ins.formatOffset(sllPriceToShow)

        idea.tpPriceToShowFormatted = ins.formatOffset(tpPriceToShow)
    }
    else
    {
        idea.slPriceToShowFormatted = slType == SlTpPriceType.TrOffset ? ins.formatOffset(slPriceToShow) : ins.formatPrice(slPriceToShow)

        if (sllPrice != null && slType != SlTpPriceType.TrOffset)
            idea.slPriceToShowFormatted += '/' + ins.formatPrice(sllPriceToShow)

        idea.tpPriceToShowFormatted = ins.formatPrice(tpPriceToShow)
    }

    idea.limitPriceToShow = ins.formatPrice(price)

    let locKey = idea.sllPriceToShow != null ? 'property.Stop-loss-SLL' : 'property.Stop-loss'

    this.set('stopLossTitle', Resources.getResource(locKey) + ':')

    this.set('ideas', ideas)
}

IdeasPanel.prototype.recountLossAndProfit = function ()
{
    let ideaID = this.openIdeaId,
        qtyObj = this.get('quantity')

    if (ideaID === null || !qtyObj || !qtyObj.value)
        return

    let ideas = this.get('ideas'),
        idea = ideas[ideaID]

    if (!idea)
        return

    let price = idea.limitPrice,
        slPrice = idea.slPrice,
        sllPrice = idea.sllPrice,
        tpPrice = idea.tpPrice,
        ins = idea.instrument,
        acc = this.get('account'),
        isBuy = idea.buySide,
        crossPrice = DataCache.CrossRateCache.GetCrossPriceExp1Exp2(ins.Exp2, acc.assetBalanceDefault.Asset.Name),
        offsetMode = GeneralSettings.TradingDefaults.ShowOffsetIn

    if (GeneralSettings.TradingDefaults.IsTicksFractionalForForex())
    {
        let slInOffset = idea.slPriceType != SlTpPriceType.Absolute,
            tpInOffset = idea.tpPriceType != SlTpPriceType.Absolute

        slPrice = slInOffset ? OrderUtils.ConvertTickOffset(ins, offsetMode, null, slPrice) : slPrice
        sllPrice = slInOffset ? OrderUtils.ConvertTickOffset(ins, offsetMode, null, sllPrice) * Math.sign(sllPrice) : sllPrice
        tpPrice = tpInOffset ? OrderUtils.ConvertTickOffset(ins, offsetMode, null, tpPrice) : tpPrice
    }

    if (idea.slPriceType != SlTpPriceType.Absolute)
    {
        if (sllPrice != null)
            slPrice += sllPrice //* (isBuy ? 1 : -1)

        let slvalue = slPrice * (isBuy ? -1 : 1)

        // if (offsetMode == Utils.OffsetModeViewEnum.Points)       // убрал в связи с 92654, возможно можно удалить
        //     slvalue = ins.CalculateTicks(0, slvalue) * (isBuy ? -1 : 1)

        slPrice = ins.CalculatePrice(price, slvalue);
    }
    else
        if (sllPrice != null)
            slPrice = sllPrice

    if (idea.tpPriceType != SlTpPriceType.Absolute)
    {
        let tpvalue = tpPrice * (isBuy ? 1 : -1)

        // if (offsetMode == Utils.OffsetModeViewEnum.Points)       // убрал в связи с 92654, возможно можно удалить
        //     tpvalue = ins.CalculateTicks(0, tpvalue) * (isBuy ? 1 : -1)

        tpPrice = ins.CalculatePrice(price, tpvalue);
    }

    let qtyInLots = Quantity.toLots(qtyObj, ins)

    const slProfit = acc.formatPrice(ProfitCalculator.CalculateSLTP(qtyInLots, slPrice, price, ins, acc, isBuy, crossPrice, null));
    const tpProfit = acc.formatPrice(ProfitCalculator.CalculateSLTP(qtyInLots, tpPrice, price, ins, acc, isBuy, crossPrice, null));

    ideas[ideaID].slProfit = slProfit
    ideas[ideaID].tpProfit = tpProfit

    this.set('ideas', ideas)
}

IdeasPanel.prototype.placeOrder = function (context, index)
{
    if (TradingNumericErrorChecker.HasErrors(this))
        return

    let ideas = this.get('ideas'),
        quantity = this.get('quantity')

    if (!ideas || ideas.length <= index || !quantity) return

    let idea = ideas[index]

    let orderType = OrderType.Limit
    let orderTypeObj = DataCache.OrderParameterContainer.OrderTypes[orderType]

    let orderEditCtorData = {
        dataCache: DataCache,
        forceSLTPOffset: false
    }

    let orderEdit = orderTypeObj.createOrderEditObject(orderEditCtorData),
        instrument = this.get('instrumentItem')

    orderEdit.updateParameters(new OrderEditUpdateData(
        null,
        {
            account: this.get('account'),
            instrument: instrument,
            side: idea.side,
            quantity: quantity,
            tif: new TIF(idea.tif, idea.tifExpireAt),
            productType: this.get('productType'),
            leverageValue: idea.leverageValue,
            placedFrom: PlacedFrom.TRADING_IDEA_CARD
        }, SessionSettings))

    orderEdit.setLimitPrice(idea.limitPrice)

    let sltpHolder = new SlTpHolder()

    let offsetType = GeneralSettings.TradingDefaults.SetSlTpValuesInOffset ? SlTpPriceType.Offset : SlTpPriceType.Absolute

    sltpHolder.StopLossPriceValue = idea.slPriceToShow
    sltpHolder.StopLossPriceType = idea.slPriceType == SlTpPriceType.TrOffset ? idea.slPriceType : offsetType
    if (idea.sllPriceToShow != null)
        sltpHolder.StopLossLimitPriceValue = idea.sllPriceToShow

    sltpHolder.TakeProfitPriceValue = idea.tpPriceToShow
    sltpHolder.TakeProfitPriceType = offsetType

    orderEdit.setSLTP(sltpHolder)

    return DataCache.FOrderExecutor.placeOrderPromise(orderEdit, null, null, this.reOpenIdeaAfterNoConfirmClick.bind(this, this.openIdeaId))
        .catch(function () 
        {
            let ex = new CustomErrorClass("IdeasPanel error", "IdeasPanel.placeOrder", "placeOrder -> placeOrderPromise");
            ErrorInformationStorage.GetException(ex);
        })
        .finally(function ()
        {
            // TODO. Refactor.
            orderEdit.dispose()
        })
}

IdeasPanel.prototype.onInstrumentChanged = function (instrument, lastInstrument)
{
    if (!instrument || instrument === lastInstrument || !this.completed)
        return

    this.subscribeRiskSettingsUpdate(instrument, lastInstrument)

    this.instrumentSettingsUpdate()
}

IdeasPanel.prototype.onAccountChanged = function (acc, lastAcc)
{
    if (!acc || acc === lastAcc || !this.completed)
        return

    this.instrumentSettingsUpdate()

    let ins = this.get('instrumentItem')
    if (ins)
        this.set('productTypeShow', ins.isProductTypeVisible(acc))

    if (this.terceraChartRactive && this.terceraChartRactive.terceraChart) {
        this.terceraChartRactive.terceraChart.chartController.ExecuteCommand(
            new TerceraChartMVCCommand(ModelDataType.Account),
            acc);
    }
}

IdeasPanel.prototype.instrumentSettingsUpdate = function ()
{
    let instrument = this.get('instrumentItem'),
        account = this.get('account');

    if (!instrument)
        return

    if (account)
        this.set('tradingAllowed', IsAllowed.IsTradingAllowed([account], instrument, OrderType.Limit).Allowed)
}

IdeasPanel.prototype.subscribeRiskSettingsUpdate = function (instrument, lastInstrument)
{
    if (lastInstrument)
        lastInstrument.RiskSettingsUpdated.UnSubscribe(this.instrumentSettingsUpdate, this)

    if (instrument)
        instrument.RiskSettingsUpdated.Subscribe(this.instrumentSettingsUpdate, this)
}

IdeasPanel.prototype.onTradingSystemUnsubscribe = function (deletedTrSystemID)
{
    this.closeOpenIdea()

    let ideas = this.get('ideas'),
        ideasLeft = []

    for (let i = 0; i < ideas.length; i++)
    {
        let idea = ideas[i]

        if (idea.tradingSystemId != deletedTrSystemID)
            ideasLeft.push(idea)
    }

    this.set('ideas', ideasLeft)
}

/// Risk Disclosure
IdeasPanel.prototype.showRiskDisclosureIfNeed = function (onlyForTrSystemID)
{
    let riskDisclosureData = DataCache.tradingSystems

    if (!riskDisclosureData) return

    this.set('allRiskDisclosuresAccepted', false)

    let tradingSystems = Object.keys(riskDisclosureData)

    if (onlyForTrSystemID)
        tradingSystems = [onlyForTrSystemID]

    for (let i = 0; i < tradingSystems.length; i++)
    {
        let trSystemID = tradingSystems[i],
            trSystem = riskDisclosureData[trSystemID]

        if (trSystem && !trSystem.accepted)
        {
            this.showRiskDisclosureByTradingSystemID(trSystemID)

            return
        }
    }

    this.set('allRiskDisclosuresAccepted', true)

    this.allIdeasScrollAdd()
}

IdeasPanel.prototype.showRiskDisclosureByTradingSystemID = function (tradingSystemID)
{
    let riskDisclosureData = DataCache.tradingSystems

    if (!riskDisclosureData || !riskDisclosureData[tradingSystemID])
        return

    let div = this.find('.js-IdeasPanel-RiskDisclosureText')
    if (!div) return

    HtmlScroll.removeScroll(div)

    div.innerText = '\n' + Resources.getResource('panel.Ideas.riskDisclosure')                  // TITLE: 'RISK DISCLOSURE'

    let riskDisclosureText = riskDisclosureData[tradingSystemID].riskDisclosureText
    div.innerText += '\n' + riskDisclosureText                                                  // Add Disclosure text

    HtmlScroll.addScroll(div);                                                            // Vertical scroll add   

    this.set('currentRiskDisclosureTradingSystemID', tradingSystemID)                           // save ID for agree btn
}

IdeasPanel.prototype.agreeBtnClick = function ()
{
    let tradingSystemID = this.get('currentRiskDisclosureTradingSystemID')

    if (!tradingSystemID)
        return

    let riskDisclosureData = DataCache.tradingSystems

    if (!riskDisclosureData || !riskDisclosureData[tradingSystemID])
        return

    riskDisclosureData[tradingSystemID].accepted = true

    DataCache.SendSubscriptionStrategyRequest(tradingSystemID)

    this.showRiskDisclosureIfNeed()
}

IdeasPanel.prototype.checkDescrBtnVisibility = function ()
{
    let descrDiv = document.getElementsByClassName('descriptionOpened')[0];

    this.set('descriptionIsOpen', false);
    this.set('descrBtnVisible', descrDiv.scrollHeight > 135)
}

IdeasPanel.prototype.srcFromLogoBytes = function (logoBytes)
{
    let logo = ThemeManager.CurrentTheme.IdeasPanelDefaultAnalystLogo;// DEFAULT IMG
    try
    {
        if (logoBytes.length)
            logo = "data:image/png;base64," + btoa(String.fromCharCode.apply(null, new Uint8Array(logoBytes)));   // IMG SRC FROM BYTES
    }
    catch (ex)
    {
        ErrorInformationStorage.GetException(ex);
    }

    return logo;

}

IdeasPanel.prototype.onDescrOver = function (context, id)
{
    let descr = document.getElementById('descr' + id)
    if (descr)
        IdeasPanel.DescriptionScroll.addScroll(descr)
}

IdeasPanel.prototype.onDescrOut = function (context, id)
{
    let descr = document.getElementById('descr' + id)
    if (descr)
        IdeasPanel.DescriptionScroll.removeScroll(descr)
}

IdeasPanel.prototype.allIdeasScrollAdd = function ()
{
    let allIdeas = document.getElementsByClassName('js-allIdeas')[0]
    if (allIdeas)
    {
        HtmlScroll.removeScroll(allIdeas)
        HtmlScroll.addScroll(allIdeas)
    }
}

IdeasPanel.prototype.allIdeasScrollTo = function ()
{
    let allIdeas = document.getElementsByClassName('js-allIdeas')[0]
    if (allIdeas)
    {
        HtmlScroll.addScroll(allIdeas);
        $(allIdeas).mCustomScrollbar("scrollTo", "#opened");
    }
}

IdeasPanel.DescriptionScroll = {
    addScroll: function (node)
    {
        $(node).mCustomScrollbar({
            theme: "light-thin",
            scrollButtons: {
                enable: true,
                scrollType: "stepless"
            },
            mouseWheel: { enable: true },
            keyboard: { enable: true },
            advanced: { updateOnContentResize: true }
        });
    },
    removeScroll: function (node)
    {
        $(node).mCustomScrollbar("destroy");
    }
};

// Chart region
IdeasPanel.prototype.subscribe = function (instrument)
{
    if (!instrument)
        return

    let qc = DataCache.FQuoteCache
    let chart = this.terceraChartRactive.terceraChart

    qc.addListener(instrument, chart, HistoryType.QUOTE_LEVEL1)
}

IdeasPanel.prototype.unsubscribe = function (instrument)
{
    if (!instrument)
        return

    let qc = DataCache.FQuoteCache
    let chart = this.terceraChartRactive.terceraChart

    qc.removeListener(instrument, chart, HistoryType.QUOTE_LEVEL1)

    chart.unsubscribeTrades()
    chart.unsubscribeCashItemFromInstrument()
}

IdeasPanel.prototype.initChart = function (instrument)
{
    if (this.terceraChartRactive === null)
        this.terceraChartRactive = this.Controls.chart;

    var terceraChart = this.terceraChartRactive.terceraChart,
        controller = terceraChart.chartController;

    if (terceraChart)
    {
        let oldIns = this.get('instrumentItem')
        if (oldIns)
            this.unsubscribe(oldIns)
    }

    this.subscribe(instrument)

    controller.ExecuteCommand(
        new TerceraChartMVCCommand(ModelDataType.Instrument),
        instrument);

    const acc = this.get('account') || DataCache.getPrimaryAccount();
    controller.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.Account), acc);

    let openIdeaDiv = this.find('.js-IdeasPanel-chart-container'),
        chartDiv = this.find('.js-IdeasPanel-Chart')

    if (!chartDiv || !openIdeaDiv)
        return

    openIdeaDiv.appendChild(chartDiv)

    terceraChart.chartController.SuspendRefreshChart = false;
    terceraChart.RefreshChart();

    if (!this.chartInited)
    {
        terceraChart.GetContextMenu = function () { }

        this.initChartTheme()

        this.setChartSizes()

        this.updateTimeFrameComboBox()
        this.chartInited = true
    }
};

IdeasPanel.prototype.initChartTheme = function ()
{
    if (!this.terceraChartRactive || !this.terceraChartRactive.terceraChart) return

    let theme = ThemeManager.CurrentTheme,
        terceraChart = this.terceraChartRactive.terceraChart,
        controller = terceraChart.chartController;

    controller.terceraChart.model.SetChartDrawingType(TerceraChartDrawingType.Solid) // Line style

    terceraChart.TerceraChartWatermarkRenderer.ForeBrush = new SolidBrush(theme.ChartWatermarkColor)

    terceraChart.TerceraChartFont.Height = 10

    let tradingToolsRenderer = terceraChart.TerceraChartTradingToolsRenderer

    if (tradingToolsRenderer)
    {
        tradingToolsRenderer.ShowOrders = false;
        tradingToolsRenderer.ShowPositions = false;
        tradingToolsRenderer.ShowVisualTradingOnLeftSide = false;
        tradingToolsRenderer.ShowEvents = false;
        tradingToolsRenderer.ShowAlerts = false;
    }

    let yScaleRendererSettings = terceraChart.yScaleRendererSettings,
        readIdeaBackGroundColor = theme.scrollBackgroundColorRGB

    if (yScaleRendererSettings)
    {
        yScaleRendererSettings.ScaleGridVisibility = false
        yScaleRendererSettings.scaleTextBrush = new SolidBrush(readIdeaBackGroundColor)
        yScaleRendererSettings.ScaleBackColor = readIdeaBackGroundColor
        yScaleRendererSettings.scaleAxisPen.Color = readIdeaBackGroundColor
        yScaleRendererSettings.AutoScaleSwitcherVisible = false
    }

    let xScaleRendererSettings = terceraChart.xScaleRendererSettings

    if (xScaleRendererSettings)
    {
        xScaleRendererSettings.ScaleGridVisibility = false
        xScaleRendererSettings.scaleTextBrush = new SolidBrush(readIdeaBackGroundColor)
        xScaleRendererSettings.ScaleBackColor = readIdeaBackGroundColor
        xScaleRendererSettings.scaleAxisPen.Color = readIdeaBackGroundColor
    }

    if (terceraChart.TerceraChartBordersRenderer)
        terceraChart.TerceraChartBordersRenderer.timeScaleAxisPen = terceraChart.TerceraChartBordersRenderer.priceScaleAxisPen = new Pen(readIdeaBackGroundColor, 1)

    if (terceraChart.TerceraChartInfoWindowRenderer)
        terceraChart.TerceraChartInfoWindowRenderer.Visible = false
    if (terceraChart.TerceraChartNewAlertRenderer)
        terceraChart.TerceraChartNewAlertRenderer.Visible = false

    if (terceraChart.windowsContainer && terceraChart.windowsContainer.scrollerRenderer)
        terceraChart.windowsContainer.scrollerRenderer.Visible = false

    this.terceraChartRactive.set('backgroundColorBottom', readIdeaBackGroundColor)
}

IdeasPanel.prototype.setChartSizes = function ()
{
    this.terceraChartRactive.setSizes();
}

IdeasPanel.prototype.updateTimeFrameComboBox = function ()
{
    if (!this.terceraChartRactive || !this.terceraChartRactive.terceraChart)
        return;

    var cBox = this.Controls.timeFrameComboBox;

    var newItems = [
        { text: Periods.ToLocalizedShortPeriod(Periods.MIN), value: Periods.MIN },
        { text: Periods.ToLocalizedShortPeriod(Periods.MIN5), value: Periods.MIN5 },
        { text: Periods.ToLocalizedShortPeriod(Periods.MIN15), value: Periods.MIN15 },
        { text: Periods.ToLocalizedShortPeriod(Periods.MIN30), value: Periods.MIN30 },
        { text: Periods.ToLocalizedShortPeriod(Periods.HOUR), value: Periods.HOUR },
        { text: Periods.ToLocalizedShortPeriod(Periods.HOUR4), value: Periods.HOUR4 },
        { text: Periods.ToLocalizedShortPeriod(Periods.DAY), value: Periods.DAY },
        { text: Periods.ToLocalizedShortPeriod(Periods.WEEK), value: Periods.WEEK },
        { text: Periods.ToLocalizedShortPeriod(Periods.MONTH), value: Periods.MONTH }
    ];

    if (Resources.isHidden('chart.AllowedPeriods.1T'))
        // || DataCache.isAllowed(IsAllowed.RequestsTypes.isAllowed_PERIOD_TICK) )
        newItems.splice(0, 1);

    cBox.set('items', newItems);
    var tf = this.terceraChartRactive.terceraChart.model.GetTimeFrameInfo();
    if (tf)
        cBox.setItembyValue(tf.Periods);
};

IdeasPanel.prototype.onTimeFrameComboBox_ComboItemClicked = function (context, newVal)
{
    var model = this.terceraChartRactive.terceraChart.model;
    var oldTFInfo = model.GetTimeFrameInfo();
    var controller = this.terceraChartRactive.terceraChart.chartController;
    controller.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.TFI), oldTFInfo.Copy({ period: newVal.tag }));
};

IdeasPanel.prototype.TickAsync = function ()
{
    if (this.terceraChartRactive && this.terceraChartRactive.terceraChart && this.terceraChartRactive.terceraChart.needRedraw)
    {
        this.terceraChartRactive.terceraChart.needRedraw = false;
        this.terceraChartRactive.terceraChart.Draw();
    }
}

IdeasPanel.prototype.applyCursor = function (cursor)
{
    if (this.terceraChartRactive)
        this.terceraChartRactive.setCursor(cursor);
};

IdeasPanel.prototype.repopulateAccounts = function ()    // #90740
{
    let accounts = DataCache.Accounts,
        login = DataCache.UserLogin,
        accountToSet = {}

    for (let accID in accounts)
    {
        let account = accounts[accID]

        if (account.AccountType != AccountType.MultiAsset /* && !account.isLinked */)   // commented out according to https://tp.traderevolution.com/entity/120061
            accountToSet[accID] = account
    }

    let accLookup = this.Controls.accounts
    if (accLookup)
    {
        accLookup.setItems(accountToSet)

        this.set('singleAccount', Object.keys(accountToSet).length < 2)
    }
}

IdeasPanel.prototype.attachmentClick = function (isScreenShot, context, index)
{
    let ideas = this.get('ideas')
    if (!ideas.length || index > ideas.length)
        return

    let idea = ideas[index]

    if (!idea) return

    let url = isScreenShot ? idea.AttachmentScreenPath : idea.AttachmentFilePath

    if (url)
        window.open(url)
}

IdeasPanel.getInstrumentTypeColor = function (type)     // TODO: move to InstrumentTypes.ts
{
    switch (type)
    {
        case InstrumentTypes.FUTURES:
            return '#FFA531';
        case InstrumentTypes.FOREX:
            return '#329AE1';
        case InstrumentTypes.EQUITIES:
            return '#FF6767';
        case InstrumentTypes.OPTIONS:
            return '#FFFFFF';
        case InstrumentTypes.CFD_FUTURES:
        case InstrumentTypes.EQUITIES_CFD:
            return '#DA80FF';
        case InstrumentTypes.INDICIES:
            return '#FFFD3B';
        case InstrumentTypes.CRYPTO:
            return '#55EFDB';
        // case Instrument.PORTFOLIO:
        //     return 'FFFD3B';
        // case Instrument.INS_HISTORY:
        //     return '#FFFD3B';
        case InstrumentTypes.SPREADBET:
            return '#A8DF00';
        case InstrumentTypes.BOND:
            return '#3DA646';
        case InstrumentTypes.ETF:
            return '#96D3FF';
        case InstrumentTypes.TBILL:
            return '#D0D0D0';
        case InstrumentTypes.SPOT:
            return '#26A69A';
        case InstrumentTypes.FORWARD:
            return '#0ED92B';
        case InstrumentTypes.CORPORATE:
            return '#5677FC';
        default:
            return "white";
    }
}

// Horizontal types scroller

IdeasPanel.prototype.initTypes = function ()
{
    this.filteringIsEnabled = this.filteringAvailableByKey = !Resources.isHidden('panel.Ideas.FilteringByInstrumentTypes')

    let allIdeas = this.get('allIdeas')
    this.set('ideas', allIdeas)                                             // по умолчанию выбран All

    if (!this.filteringIsEnabled)
        return

    this.selectedTypeIndex = 0                                              // по умолчанию выбран All - нулевой элемент

    let typesObj = {},
        localizedWordAll = Resources.getResource('panel.Ideas.FilteringAllTypes'),
        typesArr = [{ text: localizedWordAll, ideas: allIdeas, isSelected: true, color: IdeasPanel.getInstrumentTypeColor() }]

    for (let i = 0; i < allIdeas.length; i++)
    {
        let idea = allIdeas[i],
            tID = idea.instrument.InstrType

        if (InstrumentUtils.UseFuturesInsteadSpot() && tID === InstrumentTypes.SPOT)
            tID = InstrumentTypes.FUTURES;

        let color = IdeasPanel.getInstrumentTypeColor(tID),
            text = InstrumentUtils.getInstrumentTypeStringLocalized(tID)

        if (!typesObj[tID])
        {
            typesObj[tID] = {
                ideas: [],
                instrType: tID,
                text: text,
                imgFileName: InstrumentTypesImageFileNameMap[tID],
                isSelected: false,
                color: color,
                arrIndex: typesArr.length
            }
            typesArr.push(typesObj[tID])
        }

        let idx = typesObj[tID].arrIndex

        typesArr[idx].ideas.push(idea)
        typesArr[idx].text = text + ' (' + typesArr[idx].ideas.length + ')'
    }

    this.set('types', typesArr)

    this.sortTypes()

    this.typesScrollAdd()

    this.filteringIsEnabled = typesArr.length > 2
}

IdeasPanel.prototype.selectTypeToFilter = function (e, typeI)
{
    if (this.selectedTypeIndex == typeI)
        return

    this.closeOpenIdea()

    this.set('types[' + this.selectedTypeIndex + '].isSelected', false)
    this.set('types[' + typeI + '].isSelected', true)

    this.selectedTypeIndex = typeI

    this.set('ideas', this.get('types[' + typeI + '].ideas'))
    this.ideasLifeTimeUpdate()      // чтобы при открытии уже были правильные лайфтаймы, а не через секунду
    this.allIdeasScrollAdd()
}

IdeasPanel.prototype.addIdeaToTypes = function (idea)
{
    if (!this.filteringAvailableByKey)
        return

    let typesArr = this.get('types')

    let instrType = idea.instrument.InstrType

    if (InstrumentUtils.UseFuturesInsteadSpot() && instrType === InstrumentTypes.SPOT)
        instrType = InstrumentTypes.FUTURES;

    let color = IdeasPanel.getInstrumentTypeColor(instrType),
        text = InstrumentUtils.getInstrumentTypeStringLocalized(instrType),
        lowerText = text.toLowerCase()

    let arrInd
    for (arrInd = 1; arrInd < typesArr.length; arrInd++)         // ищем нужный instrType (начинаем с 1 потому что в нулевом All)
        if (typesArr[arrInd].instrType == instrType)
            break

    let newTypeWasAdded = false
    if (arrInd == typesArr.length)                               // такого типа инструмента еще не было, надо создать
    {
        for (let i = 0; i <= typesArr.length; i++)               // ищем правильное место для вставки чтобы сохранить алфавитный порядок
        {
            let s = i < typesArr.length ? typesArr[i].text.toLowerCase() : ''
            if (i == typesArr.length || s > lowerText)
            {
                typesArr.splice(i, 0, {
                    ideas: [],
                    instrType: instrType,
                    text: text,
                    color: color,
                    isSelected: false
                })

                if (i <= this.selectedTypeIndex)                // новую идею вставили перед selected типом -> индекс изменился
                    this.selectedTypeIndex++

                arrInd = i
                break
            }
        }
        newTypeWasAdded = true
    }

    let byTypeObj = typesArr[arrInd],
        indexToInsert = 0

    for (let i = 0; i < byTypeObj.ideas.length; i++)
        if (byTypeObj.ideas[i].createTime > idea.createTime)    // для надежности ищем правильное место идеи (чем новее тем выше), на практике покидаем цикл после первого if
            indexToInsert++
        else
            break

    byTypeObj.ideas.splice(indexToInsert, 0, idea)
    byTypeObj.text = text + ' (' + byTypeObj.ideas.length + ')'

    if (newTypeWasAdded)
        this.set('types', typesArr)

    this.set('types[' + arrInd + '].ideas', byTypeObj.ideas)
    this.set('types[' + arrInd + '].text', byTypeObj.text)


    if (this.selectedTypeIndex == arrInd)
        this.set('ideas', byTypeObj.ideas)                      // если новая идея по открытому типу надо обновить массив чтоб идея появилась

    if (this.selectedTypeIndex === 0)
        this.set('ideas', typesArr[0].ideas)

    if (newTypeWasAdded)
        this.typesScrollAdd()                                   // скролльчик подрос

    this.filteringIsEnabled = typesArr.length > 2
}

IdeasPanel.prototype.deleteIdeaFromTypes = function (idea)
{
    if (!this.filteringAvailableByKey)
        return

    let typesArr = this.get('types')

    let instrType = idea.instrument.InstrType

    if (InstrumentUtils.UseFuturesInsteadSpot() && instrType === InstrumentTypes.SPOT)
        instrType = InstrumentTypes.FUTURES;

    let text = InstrumentUtils.getInstrumentTypeStringLocalized(instrType)

    let arrInd
    for (arrInd = 1; arrInd < typesArr.length; arrInd++)    // ищем нужный instrType (начинаем с 1 потому что в нулевом All)
        if (typesArr[arrInd].instrType == instrType)
            break

    let byTypeObj = typesArr[arrInd],
        index = byTypeObj.ideas.indexOf(idea)

    byTypeObj.ideas.splice(index, 1)

    let typeWasDelete = false

    if (byTypeObj.ideas.length == 0)
    {
        typesArr.splice(arrInd, 1)                          // идей по такой группе больше нет - удаляем ее

        typeWasDelete = true
    }
    else                                                    // иначе только обновляем текст
        byTypeObj.text = text + ' (' + byTypeObj.ideas.length + ')'

    if (this.selectedTypeIndex == arrInd || typesArr.length == 1)                   // если удаленная идея по открытому типу надо обновить массив чтоб идея исчезла
    {
        if (!byTypeObj.ideas.length)
        {
            this.selectedTypeIndex = 0                          // состояние selected перемещаем на All согласно комментарию в #96602
            typesArr[0].isSelected = true
            this.set('ideas', typesArr[0].ideas)
        }
        else
            this.set('ideas', byTypeObj.ideas)

        if (this.openIdeaId === index)  //#90817
            this.openIdeaId = null
    }
    else
        if (!byTypeObj.ideas.length && this.selectedTypeIndex > arrInd)                // какой-то тип удалили перед selected типом -> индекс изменился
            this.selectedTypeIndex--

    this.set('types', typesArr)

    if (typeWasDelete)
        this.typesScrollAdd()                               // скролл усох - надо обновить

    this.filteringIsEnabled = typesArr.length > 2

    this.allIdeasScrollAdd()
}

IdeasPanel.prototype.localizeInstrumentTypes = function ()
{
    let types = this.get('types')
    if (!types.length) return

    for (let i = 0; i < types.length; i++)
    {
        let insType = types[i].instrType
        types[i].text = insType ? InstrumentUtils.getInstrumentTypeStringLocalized(insType) : Resources.getResource('panel.Ideas.FilteringAllTypes')
    }
    this.set('types', types)

    this.sortTypes()
}

IdeasPanel.prototype.sortTypes = function ()
{
    let typesArr = this.get('types')
    if (!typesArr.length) return

    typesArr.sort(function (a, b)           // сортируем типы в алфавитном порядке их названий
    {
        let s1 = a.text.toLowerCase(),      // переводим в нижний регистр для правильного порядка (иначе, например ETFs и Equities сортируется неверно)
            s2 = b.text.toLowerCase()

        if (s1 == s2)
            return 0

        if (!a.instrType) return -1         // instrType отсутствует у элемента All
        if (!b.instrType) return 1          // чтобы All всегда оставался в начале в независимости от локализации

        return s1 < s2 ? -1 : 1
    })

    this.set('types', typesArr)
}

IdeasPanel.prototype.reOpenIdeaAfterNoConfirmClick = function (ideaID)
{
    MainWindowManager.MainWindow.showIdeasPanel()

    this.ideaSelect(null, ideaID)
}

IdeasPanel.HorizontalScroll = {
    addScroll: function (node)
    {
        let self = this
        self.leftArrow = document.getElementById("leftArrow")
        self.rightArrow = document.getElementById("rightArrow")
        let mCS = $(node).mCustomScrollbar({
            axis: "x",
            theme: "minimal",
            scrollButtons: {
                enable: true
            },
            mouseWheel: { enable: true },
            keyboard: { enable: true },
            callbacks: {
                whileScrolling: function ()
                {    // onScroll
                    self.leftArrow.style.visibility = this.mcs.leftPct > 0 ? 'visible' : 'hidden'   // TODO: something has been broken here (both arrows dissapear when back scroll from end to begin)
                    self.rightArrow.style.visibility = this.mcs.leftPct < 100 && this.mcs.leftPct > 0 ? 'visible' : 'hidden'
                },
            },
            advanced: { autoExpandHorizontalScroll: true }
        });
                                                    // TODO: something has been broken here (arrow to left is visible at the start)
        self.leftArrow.style.visibility = 'hidden'  // при создании скролла его положение в нуле -> стрелка влево не нужна
        self.rightArrow.style.visibility = mCS[0].lastChild.style.display != 'none' ? 'visible' : 'hidden'
    },
    removeScroll: function (node)
    {
        $(node).mCustomScrollbar("destroy");
    }
};

IdeasPanel.prototype.typesScrollAdd = function ()
{
    let allTypes = document.getElementsByClassName('js-IdeasPanel-TypesVerticalBar')[0]
    if (allTypes)
    {
        IdeasPanel.HorizontalScroll.removeScroll(allTypes)
        IdeasPanel.HorizontalScroll.addScroll(allTypes)
    }
}

IdeasPanel.prototype.typesScrollTo = function (scrollToRight)
{
    let allTypes = document.getElementsByClassName('js-IdeasPanel-TypesVerticalBar')[0]
    if (allTypes)
    {
        IdeasPanel.HorizontalScroll.addScroll(allTypes);
        let scrollTo = scrollToRight ? "right" : "left";
        $(allTypes).mCustomScrollbar("scrollTo", scrollTo);
    }
}