// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

// TODO. Refactor.
// Base class for binding orderEdit object and user input.

import { DynProperty } from "../../Commons/DynProperty.ts"
import { Resources } from "../../Commons/properties/Resources.ts"
import { TerceraMessageBox } from "../screen/TerceraMessageBox.js"
import { ApplicationPanelNew } from "./ApplicationPanelNew.js";
import { InstrumentTypes } from "../../Utils/Instruments/InstrumentTypes.ts"
import { OrderTif } from "../../Utils/Trading/OrderTifEnum.ts"
import { OrderType } from "../../Utils/Trading/OrderType.ts"
import { GeneralSettings } from "../../Utils/GeneralSettings/GeneralSettings.ts"
import { Quantity } from "../../Utils/Trading/Quantity.ts"
import { OrderEditUpdateData } from "../../Utils/Trading/OrderEditUpdateData.ts"
import { OrderEditBase } from "../../Commons/cache/OrderParams/order-edit/OrderEditBase.ts"

export let OrderEditViewBase = ApplicationPanelNew.extend({
    data: {
        orderEditParameterArray: null,
        quantity: null,
        tradingNumericText: '',
        OffsetTradingNumericText: 0,
        tradingAllowed: false,
        isAllowedResponce: null,    // backup result of calling IsAllowed.IsTradingAllowed - IsAllowedResponce type object 
        tradingForbiddenReason: ''
    },
    orderEdit: null,
    parameterObserverHandler: null,
    // TODO. Remove?
    NeedCalculateRowCount: false,
})

OrderEditViewBase.prototype.getType = function ()
{
    throw new Error('Not implemented')
}

OrderEditViewBase.prototype.dispose = function ()
{
    this.disposeOrderEdit()
    ApplicationPanelNew.prototype.dispose.call(this)
}

OrderEditViewBase.prototype.disposeOrderEdit = function ()
{
    let parameterObserverHandler = this.parameterObserverHandler

    if (parameterObserverHandler)
        parameterObserverHandler.cancel()

    this.parameterObserverHandler = null

    this.set('orderEditParameterArray', null)

    let orderEdit = this.orderEdit
    if (!orderEdit) return

    this.orderEdit = null

    orderEdit.ParametersChanged.UnSubscribe(
        this.onOrderEditParametersChanged,
        this)

    orderEdit.ValidStateChanged.UnSubscribe(
        this.onValidStateChanged,
        this)

    orderEdit.QuantityChanged.UnSubscribe(this.onQuantityChanged, this)

    orderEdit.dispose()
}

// TODO. Refactor.
OrderEditViewBase.prototype.setOrderEdit = function (orderEdit)
{
    if (!orderEdit)
        return

    this.disposeOrderEdit()

    this.orderEdit = orderEdit

    this.set(
        'orderEditParameterArray',
        orderEdit.createDynPropertyArrayFromParameters()).then(function ()
        {
            this.onrender();
        }.bind(this));

    orderEdit.ParametersChanged.Subscribe(
        this.onOrderEditParametersChanged,
        this)

    orderEdit.ValidStateChanged.Subscribe(
        this.onValidStateChanged,
        this)

    // TODO. Refactor.
    this.onValidStateChanged(orderEdit.valid())

    this.parameterObserverHandler = this.observe(
        'orderEditParameterArray.*',
        this.onOrderEditParameterChangedByUser,
        { init: false })

    orderEdit.QuantityChanged.Subscribe(this.onQuantityChanged, this)

    // TODO. UGLY. Refactor.
    this.deferLayout()
}

OrderEditViewBase.prototype.onQuantityChanged = function (qtyValue)
{

}

OrderEditViewBase.prototype.setDPTradingNumericText = function (newOrderType)
{
    if (!newOrderType || !newOrderType.id) return;

    let orderTypeId = newOrderType.id()
    if (orderTypeId === OrderType.Limit)
    {
        this.set('tradingNumericText', 'LMT:');
        this.set('OffsetTradingNumericText', 34);
    }
    else if (orderTypeId === OrderType.TrailingStop)
    {
        this.set('tradingNumericText', 'Offset:');
        this.set('OffsetTradingNumericText', 45);
    }
    else
    {
        this.set('tradingNumericText', 'STP:');
        this.set('OffsetTradingNumericText', 34);
    }
}

OrderEditViewBase.prototype.onValidStateChanged = function (valid)
{
    this.set('tradingAllowed', valid)
}

// orderEdit parameters' -> parameters' controls.
OrderEditViewBase.prototype.onOrderEditParametersChanged = function (dpDict)
{
    let orderEdit = this.orderEdit
    let orderEditParameterArray = this.get('orderEditParameterArray')

    if (!dpDict || !orderEdit || !orderEditParameterArray)
        return

    for (let len = orderEditParameterArray.length - 1, i = len; i >= 0; i--)
    {
        let newDP = dpDict[orderEditParameterArray[i].name]
        if (newDP)
            this.set('orderEditParameterArray.' + i, newDP)
    }

    // TODO. UGLY. Refactor.
    this.deferLayout()
}

// parameters' controls -> orderEdit parameters'.
OrderEditViewBase.prototype.onOrderEditParameterChangedByUser = function (dp)
{
    let orderEdit = this.orderEdit
    if (!orderEdit || !dp) return

    let dpDict = {}
    dpDict[dp.name] = dp

    orderEdit.updateParameters(new OrderEditUpdateData(dpDict))

    if (this.get('positionSizingChecked'))
        orderEdit.recalcQtyForPositionSizing()
}

OrderEditViewBase.prototype.deferLayout = function ()
{
    this.needLayout = true
}

OrderEditViewBase.prototype.TickAsync = function ()
{
    if (!this.needLayout)
        return

    this.needLayout = false
    this.layout()
    this.activateNumeric_proc();
}

OrderEditViewBase.prototype.activateNumeric = function (numericLinkName)
{
    let observerName = 'observerOf' + numericLinkName
    this[observerName] = this.observe(numericLinkName, function (link, obsName, n)
    {
        if (n)
        {
            this.numericToActivate = n;
            this.obsName = obsName
        }
    }.bind(this, numericLinkName, observerName))
}

OrderEditViewBase.prototype.activateNumeric_proc = function ()
{
    if (this.numericToActivate)
    {
        this.numericToActivate.setFocus(true, true)
        this.numericToActivate = null;

        let oName = this.obsName
        if (this[oName])
        {
            this[oName].cancel()
            this[oName] = null
            this.obsName = null
        }
    }
}

OrderEditViewBase.prototype.layout = function () { }

// TODO. Refactor. Remove?
OrderEditViewBase.prototype.updateOrderEditParametersVisibility = function ()
{
    let orderEditParameterArray = this.get('orderEditParameterArray');
    if (!orderEditParameterArray) return 

    for (let i = 0, len = orderEditParameterArray.length; i < len; i++)
    {
        let dp = orderEditParameterArray[i]
        if (dp && dp.visible)
            this.getOrderEditParameterVisibility(dp)
    }
}

// TODO. Refactor. Remove?
OrderEditViewBase.prototype.getOrderEditParameterVisibility = function (dp)
{
    let result = false;

    switch (dp.type)
    {
        case DynProperty.DOUBLE:
            result = true;
            break
        case DynProperty.POSITION_SIZING:
            if (dp.positionSizingIsVisible)
                result = true;
            break
        case DynProperty.SLTP:
            if (dp.allowedSL)
                result = true;
            if (dp.allowedTP)
                result = true;
            if (dp.sl.enabled)
                result = true;
            if (dp.tp.enabled)
                result = true;
            if (dp.SLTriggerVisible())   // #109798
                result = true;
            if (dp.TPTriggerVisible())   // #109798
                result = true;
            break
        case DynProperty.COMBOBOX_COMBOITEM:
            result = true;
            break

        case DynProperty.PRODUCT_TYPE_AND_MQ:
            if (dp.visible)
                result = true;
            break;

        case DynProperty.ATTENTION_MSG:
            if (dp.visible)
                result = true;
            break
    }

    return result
}

OrderEditViewBase.prototype.focusWarningNumeric = function ()   // for numeric focusing when it has warning after click button in confirmation screen of place/modify order
{
    let warningNumericData = null
    if (this.orderEdit)
        warningNumericData = this.orderEdit.getWarningNumeric()

    if (!warningNumericData)
        return

    let numericLinkKey = warningNumericData.numericLinkKey,
        dpControlName = warningNumericData.dpControlName

    if (numericLinkKey)
    {
        this.activateNumeric(numericLinkKey)
        this.set(numericLinkKey, this.tryGetDynPropertyControlTradingNumeric(dpControlName))
        this.deferLayout()
    }
}

OrderEditViewBase.prototype.tryGetDynPropertyControlTradingNumeric = function (dpControlName)
{
    let dpControl = this.Controls ? this.Controls[dpControlName] : null
    if (dpControl)
        return dpControl.Controls ? dpControl.Controls.TradingNumeric : null

    return null
}

OrderEditViewBase.prototype.addDisclosedQuantityIfNeed = function (key)
{
    let instrument = this.get('instrument'),
        orderEdit = this.orderEdit,
        tif = this.get('tif')

    if (!instrument || !orderEdit || !tif)
        return

    let needToAdd = OrderEditBase.isDisclosedQuantityNeed(instrument, orderEdit.getOrderTypeId(), tif)

    this.set('disclosedQuantityShow', needToAdd && !Resources.isHidden('panel.newOrderEntry.disclosedLabel'))

    // if (needToAdd)
        this.setDisclosedQuantityParams(key)
}

OrderEditViewBase.prototype.setDisclosedQuantityParams = function (key)
{
    let inLots = GeneralSettings.View.displayAmountInLots()

    let oldDQ = this.get('disclosedQuantity'),
        quantity = this.get('quantity'),
        ins = this.get('instrument')

    if (quantity && quantity.value)
    {
        let decimalPlaces = ins.getAmountPrecision(),
            increment = inLots ? ins.LotStep : ins.LotStep * ins.LotSize,
            disclosedQuantityMinCoefficient = ins.GetDisclosedQuantityMinCoefficient(),
            minValue = disclosedQuantityMinCoefficient ? quantity.value * disclosedQuantityMinCoefficient : increment,
            minValueDeFacto = parseFloat(minValue.toFixed(decimalPlaces))

        if (minValueDeFacto < minValue)
            minValueDeFacto += increment

        this.set({
            dqMinValue: minValueDeFacto,
            dqMaxValue: quantity.value
        })

    }

    if (oldDQ && oldDQ.value && key != 'quantity')
        return

    let order = this.get('order')
    let val = order && order.DisclosedQty && key != 'quantity'
        ? Quantity.convertQuantityValue(new Quantity(order.DisclosedQty, true), ins, inLots)
        : quantity.value

    this.set('disclosedQuantity', new Quantity(val, inLots))
}

OrderEditViewBase.CheckNumericsErrors = function (numericsErrors)
{
    var err = '';
    var keys = Object.keys(numericsErrors);
    var len = keys.length;
    for (var i = 0; i < len; i++)
    {
        var numericValidationStateInfo = numericsErrors[keys[i]];
        if (numericValidationStateInfo &&
            numericValidationStateInfo.enabled &&
            numericValidationStateInfo.errorText)
        {
            TerceraMessageBox.Show(
                Resources.getResource('screen.error.title'),
                numericValidationStateInfo.errorText,
                TerceraMessageBox.msgType.Info,
                null, null, false, true);

            return false;
        }
    }

    return true;
};