// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type Instrument } from '../Commons/cache/Instrument';
import { GeneralSettings } from './GeneralSettings/GeneralSettings';
import { Intervals } from './Instruments/Intervals';
import { OffsetModeViewEnum } from './Trading/OffsetModeViewEnum';

export class NumericUtils {
    public static MINVALUE = -999999999;
    public static MAXVALUE = 999999999;

    public static getNumericsOffsetModeViewParams (instrument: Instrument | null, isOffset, isTrStrop?) {
        const offsetMode = GeneralSettings.TradingDefaults.ShowOffsetIn;

        let step = 0;
        let numericsPrec = 0;
        let ifIsTrStopStep = 0;
        if (!instrument) {
            return { step, numericsPrec, ifIsTrStopStep };
        }

        let useFract = false;
        if (offsetMode === OffsetModeViewEnum.TicksFractionalForForex) {
            useFract = instrument.AllowUseFractinalTicksForForex();
        }

        // todo priceIncrement  , now using PointSize
        if (!isOffset) {
            step = instrument.GetPointSize(null);
            numericsPrec = instrument.Precision;
        } else {
            if (offsetMode === OffsetModeViewEnum.Points) {
                step = instrument.GetPointSize(null);
                numericsPrec = instrument.Precision;
                const intervals = Intervals.GetIntervalsFromInstrument(instrument, NumericUtils.MAXVALUE);
                if (intervals && (intervals.length > 0)) {
                    step = intervals[0].LeftValue;
                    numericsPrec = intervals[0].DecimalPlaces;
                }
            } else {
                step = 1 / (useFract ? 10 : 1);
                step = isOffset ? step : instrument.GetPointSize(null);
                if (isOffset) {
                    numericsPrec = useFract ? 1 : 0;
                } else {
                    numericsPrec = instrument.Precision;
                }
            }
        }

        ifIsTrStopStep = 1 / (useFract ? 10 : 1);
        if (offsetMode === OffsetModeViewEnum.Points) {
            ifIsTrStopStep = instrument.GetPointSize(null);
        } else {
            ifIsTrStopStep = isTrStrop ? ifIsTrStopStep : instrument.GetPointSize(null);
        }

        return { step, numericsPrec, ifIsTrStopStep };
    }
}
