// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts"
import { MathUtils } from "../../Utils/MathUtils.ts"
import { contextMenuHandler } from "../../Utils/AppHandlers.js"
import { DPPositionSizingControlsTemplate } from "../../templates.js";
import { DynPropertyControl } from "../elements/DynPropertyControl.js";

export let DPPositionSizingControls = DynPropertyControl.extend({
    template: DPPositionSizingControlsTemplate,
    data: function ()
    {
        return {
            positionSizingIsVisible: false, // скрываем через эту переменную, а не visible потому что для корректной работы нужно чтобы контролы были созданы
            riskModeBtnText: '%',           // текст в кнопке у нумерика (% или валюта)
            riskLabel: '',                  // подпись второй строки

            showLabel: true,
            width: 176
        }
    },
    computed: {
        AccountRisk: {
            get: function ()
            {
                return this.get('dp.value')
            },
            set: function (value)
            {
                this.set('dp.value', value)
            }
        },
        OtherRisk: {
            get: function ()
            {
                return this.get('dp.riskValueFormatted')
            },
            set: function (value)
            {
                this.set('dp.riskValueFormatted', value)
            }
        },
        Currency: {
            get: function ()
            {
                return this.get('dp.currencyRiskModeSuffix')
            },
            set: function (value)
            {
                this.set('dp.currencyRiskModeSuffix', value)
            }
        }
    },
    selectedAccRiskModeTag: null
})

DPPositionSizingControls.prototype.getType = function () { return 'DPPositionSizingControls' };


DPPositionSizingControls.prototype.oninit = function ()
{
    DynPropertyControl.prototype.oninit.call(this)

    this.on('fixedOrPercentageBtnClick', this.fixedOrPercentageBtnClick)
}

DPPositionSizingControls.prototype.oncomplete = function () 
{
    DynPropertyControl.prototype.oncomplete.call(this)

    this.selectAccRiskMode(DPPositionSizingControls.PercentTag, null, true)
    this.localize()
}

DPPositionSizingControls.prototype.selectAccRiskMode = function (tag, item, fromOnComplete)
{
    if (this.selectedAccRiskModeTag == tag)
        return

    this.selectedAccRiskModeTag = tag

    let accCur = this.get('Currency'),
        isPercent = this.isPercentMode(),
        valueToShowInBtn = isPercent ? '%' : accCur

    if (!fromOnComplete)
    {
        let newV = this.get('dp.riskValue') 
        this.set('AccountRisk', isPercent ? MathUtils.TruncateDouble(newV, 2) : newV)
    }

    this.set('dp.riskModeBtnText', valueToShowInBtn)
    this.set({
        riskModeBtnText: valueToShowInBtn,
        riskLabel: Resources.getResource('positionSizeCalculator.' + (isPercent ? 'cashRiskLabel' : 'percentRiskLabel'))
    })
}

DPPositionSizingControls.prototype.fixedOrPercentageBtnClick = function ()
{
    if (!this.get('enabled'))
        return

    let items = this.getPercentCashItems()
    if (items)
    {
        let pos = $(this.find('.js-button-fixedOrPercentMode')).offset()
        contextMenuHandler.Show(items, pos.left, pos.top + 21, { width: 111 })
    }
}

DPPositionSizingControls.prototype.getPercentCashItems = function ()
{
    let isPercentMode = this.isPercentMode()

    let items = [{
        text: Resources.getResource("positionSizeCalculator.FixedMenuItem"),
        canCheck: true,
        checked: !isPercentMode,
        tag: DPPositionSizingControls.FixedTag,
        enabled: true,
        event: this.selectAccRiskMode.bind(this, DPPositionSizingControls.FixedTag)
    }, {
        text: Resources.getResource("positionSizeCalculator.PercentMenuItem"),
        canCheck: true,
        checked: isPercentMode,
        tag: DPPositionSizingControls.PercentTag,
        enabled: true,
        event: this.selectAccRiskMode.bind(this, DPPositionSizingControls.PercentTag)
    }];

    return items
}

DPPositionSizingControls.prototype.isPercentMode = function () { return this.selectedAccRiskModeTag == DPPositionSizingControls.PercentTag }

DPPositionSizingControls.prototype.localize = function ()
{
    DynPropertyControl.prototype.localize.call(this)

    this.set({
        accountRiskLabel: Resources.getResource('positionSizeCalculator.accountRisk'),
        accountRiskTT: Resources.getResource('positionSizeCalculator.accountRisk.tt'),
        riskLabel: Resources.getResource('positionSizeCalculator.cashRiskLabel')
    });
};

DPPositionSizingControls.PercentTag = 'percent'
DPPositionSizingControls.FixedTag = 'fixed'