// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { MathUtils } from '../../../Utils/MathUtils';
import { Intervals } from '../../../Utils/Instruments/Intervals';
import { OperationType } from '../../../Utils/Trading/OperationType';
import { OrderType } from '../../../Utils/Trading/OrderType';
import { Resources } from '../../properties/Resources';
import { SlTpPriceType } from '../../../Utils/Enums/Constants';
import { RulesSet } from '../../../Utils/Rules/RulesSet';
import { SLTPTriggerUtils } from './SLTPTriggerUtils';
import { SLTPTrigger } from '../../../Utils/SlTpTrigger';
import { OffsetModeViewEnum } from '../../../Utils/Trading/OffsetModeViewEnum';
import { GeneralSettings } from '../../../Utils/GeneralSettings/GeneralSettings';
import { OrderUtils } from '../../../Utils/Trading/OrderUtils';
import { ProfitCalculator } from '../ProfitCalculator';
import { NumericUtils } from '../../../Utils/NumericUtils';
import { IsAllowed } from '../../IsAllowed';
import { Quantity } from '../../../Utils/Trading/Quantity';
import { SlTpHolder } from '../../../Utils/Trading/SlTpHolder';
import { SLTPDynProperty } from '../../SLTPDynProperty';
import { InstrumentUtils } from '../../../Utils/Instruments/InstrumentUtils';
import { type Instrument } from '../Instrument';
import { SLTPError } from './SLTPError';

export class SLTPEdit {
    public DataCache: any;
    public sl: any;
    public tp: any;
    public trailingStop: any;
    public trailingStopAllowed: boolean = false;
    public allowedSL: boolean = true;
    public allowedTP: boolean = true;
    public needSetDefaultSLTP: boolean = false;
    public forceOffset: boolean;
    public sllValueEnabled: boolean = false; // #91413
    public sltpTriggerShort: any = null; // #109798
    public nonEditableSLTPTriggerShort: any = null; // #110640 <- #109798
    public SkipChange: boolean = false;
    IsActivated: any;

    constructor (dataCache, forceOffset: any = undefined) {
        this.DataCache = dataCache;

        this.sl = {
            // null - value awaits for recalculation
            value: null,
            // _value:null,
            sllValue: null, // #91413
            // null - absolute value
            offsetType: null,
            enabled: false,
            error: null
        };

        this.tp = {
            // null - value awaits for recalculation
            value: null,
            // null - absolute value
            offsetType: null,
            enabled: false,
            error: null
        };

        this.trailingStop = {
            // null - value awaits for recalculation
            value: null,
            // Ticks only (tick , fractional, point view etc)
            offsetType: null,
            enabled: false,
            error: null,
            RealTrStopPrice: null
        };

        this.getSLError = this.getSLError.bind(this);
        this.getTPError = this.getTPError.bind(this);

        this.forceOffset = false || forceOffset;

        // TODO. Refactor. Ugly.
        this.updateOffsetTypes();
    }

    public valid (): any {
        const slErr = this.sl.enabled && this.sl.error && !this.IsActivated; // #93540 SL может быть disabled при этом являтся валидным
        const tpErr = this.tp.enabled && this.tp.error;

        const trailingStopErr =
            this.sl.enabled &&
            this.trailingStopAllowed &&
            this.trailingStop.enabled &&
            this.trailingStop.error;

        return /* this.allowed && */ !slErr && !tpErr && !trailingStopErr;
    }

    public AdditionalIntervalVerification (): boolean {
        return false;
    }

    // TODO. Rename?
    public useTrailingStop (): boolean {
        return this.allowedSL &&
            this.sl.enabled &&
            this.trailingStopAllowed && this.trailingStop.enabled;
    }

    // TODO. Rename?
    public useSL (): boolean {
        return this.allowedSL &&
            this.sl.enabled &&
            (!this.trailingStopAllowed || !this.trailingStop.enabled);
    }

    // TODO. Rename?
    public useTP (): boolean {
        return this.allowedTP && this.tp.enabled;
    }

    // #region DynProperty

    public getDynProperty (tradingData): any {
        const ins: Instrument = tradingData.instrument;

        const dp = new SLTPDynProperty();

        dp.trailingStopAllowed = this.trailingStopAllowed;
        dp.allowed = this.allowedSL || this.allowedTP;
        dp.visible = this.allowedSL || this.allowedTP;

        dp.allowedSL = this.allowedSL;
        dp.allowedTP = this.allowedTP;

        dp.sl = this.getDynProperty_SLTPPart(this.sl, false, ins);
        dp.tp = this.getDynProperty_SLTPPart(this.tp, false, ins);
        dp.trailingStop = this.getDynProperty_SLTPPart(this.trailingStop, true, ins);

        if (dp.trailingStop && tradingData.trailingStop) {
            dp.trailingStop.offsetValue = tradingData.trailingStop.value;
        }

        if (!dp.sl.enabled && !dp.tp.enabled) {
            return dp;
        }

        let Qty = 0;
        let OpenPrice = NaN;
        const account = tradingData.account;
        const quote = tradingData.quote;
        const isBuy = tradingData.side === OperationType.Buy;
        const orderObject = tradingData.position || tradingData.order;
        dp.OrderType = tradingData.OrderType;

        dp.sllValueEnabled = orderObject // если есть orderObject -> то это modify, смотрим по наличию значения, иначе это OE -> по настройке из сеттингов
            ? (orderObject.isPosition && orderObject.SLOrder ? orderObject.SLOrder.StopLimit > 0 : dp.sl.sllValue != null)
            : GeneralSettings.TradingDefaults.UseStopLimitInsteadStop;

        if (!dp.sllValueEnabled) dp.sl.sllValue = null;

        if (this.DataCache.AllowCustomSLTPTriggerPriceForUser()) {
            if (orderObject) // если есть orderObject -> то это modify
            {
                dp.nonEditableSLTPTriggerShort = SLTPTriggerUtils.GetShortByOrder(orderObject) || // #110640 <- #109798
                    SLTPTriggerUtils.GetTriggersShortForInstrument(ins);
            } // #110651 <- #109798
            else {
                const canSelectInOE = this.CanSelectSLTPTriggerInOE();
                if (canSelectInOE) {
                    dp.sltpTriggerShort = this.sltpTriggerShort !== null
                        ? this.sltpTriggerShort
                        : SLTPTriggerUtils.GetTriggersShortForInstrument(ins);
                } else if (canSelectInOE !== null) // если === null -> не разрешено на юзере
                {
                    dp.nonEditableSLTPTriggerShort = SLTPTriggerUtils.GetTriggersShortForInstrument(ins);
                }
            }
        }

        if (orderObject?.SLOrder) {
            const slOrder = orderObject.SLOrder;
            const activated = slOrder.IsActivated && !dp.trailingStop.enabled;

            this.IsActivated = activated;
            dp.sl.canEdit = !activated; // #93540
            // dp.sl.error = null            // #93646  //#111002
        }

        if (tradingData.limitPrice) {
            OpenPrice = tradingData.limitPrice;
        } else if (tradingData.stopPrice) {
            OpenPrice = tradingData.stopPrice;
        } else if (orderObject && orderObject.OrderType === OrderType.TrailingStop) {
            OpenPrice = orderObject.CurPriceOpen;
        } else if (orderObject) {
            OpenPrice = orderObject.Price;
        }

        if (tradingData.quantity !== null) {
            Qty = Quantity.toLots(tradingData.quantity, ins);
        }

        if (ins !== null && account !== null && Qty !== null) {
            const sp = this.DataCache.GetSpreadPlan(account);
            if (isNaN(OpenPrice) && quote !== null) {
                OpenPrice = isBuy ? quote.AskSpread_SP_Ins(sp, ins) : quote.BidSpread_SP_Ins(sp, ins);
            }

            if (isNaN(OpenPrice)) {
                OpenPrice = 0;
            }

            // if (isNaN(OpenPrice))
            //     return dp

            const crossPrice = this.DataCache.CrossRateCache.GetCrossPriceExp1Exp2(ins.Exp2, account.assetBalanceDefault.Asset.Name);
            const openCross = orderObject?.isPosition ? orderObject.OpenCrossPrice : crossPrice; // #107999

            if (dp.sl.enabled) {
                this.SLPart(dp, ins, account, OpenPrice, Qty, isBuy, crossPrice, openCross, orderObject);
            }

            if (dp.tp.enabled) {
                this.TPPart(dp, ins, account, OpenPrice, Qty, isBuy, crossPrice, openCross, orderObject);
            }
        }

        return dp;
    }

    public CanSelectSLTPTriggerInOE (): any {
        return this.DataCache.AllowCustomSLTPTriggerPriceForUser() ? GeneralSettings.TradingDefaults.SlTpTriggers.SelectSLTPTriggerInOE : null;
    }

    public SLPart (dp, ins, account, OpenPrice, Qty, isBuy, crossPrice, openCross, orderObject): void {
        const inPoints = GeneralSettings.TradingDefaults.ShowOffsetIn == OffsetModeViewEnum.Points;
        const trStopEnabled: boolean = dp.trailingStop.enabled;
        let dpSLValue = dp.sl.value;
        if (dp.sl.sllValue != null) {
            dpSLValue = dp.sl.sllValue + (dp.sl.inOffset ? dp.sl.value : 0);
        }
        const SLoffset = dpSLValue - OpenPrice;
        const SLoffsetTicks = ins.CalculateTicks(OpenPrice, SLoffset);

        let SLClose = dpSLValue;
        let slvalue = SLClose * (isBuy ? -1 : 1);
        if (dp.sl.inOffset) {
            if (inPoints) {
                slvalue = ins.CalculateTicks(0, slvalue) * (isBuy ? -1 : 1);

                if (dp.sl.sllValue != null) {
                    slvalue = slvalue * Math.sign(SLClose);
                }
            }

            SLClose = ins.CalculatePrice(OpenPrice, slvalue);
        }

        const slProfitValue = ProfitCalculator.CalculateSLTP(Qty, SLClose, OpenPrice, ins, account, isBuy, openCross, orderObject?.ProductType);
        const slProfit = account.formatPrice(slProfitValue);

        if (dp.sl.inOffset && dp.trailingStop.offsetValue) {
            slvalue = (dp.trailingStop.offsetValue - dp.sl.value) * (isBuy ? 1 : -1);

            if (inPoints) {
                slvalue = ins.CalculateTicks(0, slvalue) * (isBuy ? 1 : -1);
            }

            SLClose = ins.CalculatePrice(OpenPrice, slvalue);
        }

        let trStopClose = '';
        let trStopProfitValue = 0;
        let trStopProfit = '';
        if (trStopEnabled) {
            const trValue = dp.trailingStop.value / (inPoints ? dp.trailingStop.increment : 1);

            if (dp.OrderType === OrderType.PositionEdit && orderObject?.isPosition) // закомментил в связи с ОР 92605   // расскоментил в связи с комментом в 92908
            {
                trStopClose = ins.CalculatePrice(orderObject.CurPriceClose, trValue * (isBuy ? -1 : 1));
            } else {
                trStopClose = ins.CalculatePrice(OpenPrice, trValue * (isBuy ? -1 : 1));
            }

            trStopProfitValue = ProfitCalculator.CalculateSLTP(Qty, trStopClose, OpenPrice, ins, account, isBuy, openCross, orderObject?.ProductType);
            trStopProfit = account.formatPrice(trStopProfitValue);
        }

        let SL_relative = 0;
        let trStop_relative = 0;
        const ProjectBalance = account.Profit + account.assetBalanceDefault.Balance;
        if (ProjectBalance != 0) {
            SL_relative = MathUtils.Round((slProfitValue / ProjectBalance) * 100, 2);
            if (trStopEnabled) {
                trStop_relative = MathUtils.Round((trStopProfitValue / ProjectBalance) * 100, 2);
            }
        }

        const slProfitText = slProfit + ' ' + '(' + SL_relative + '%)';
        let trStopProfitText = '';
        if (trStopEnabled) {
            trStopProfitText = trStopProfit + ' ' + '(' + trStop_relative + '%)';
        }

        dp.slLabel = dp.sl.inOffset && !trStopEnabled
            ? ins.formatPrice(SLClose)
            : (trStopEnabled
                ? (dp.trailingStop.offsetValue
                    ? ins.formatPrice(SLClose)
                    : ins.formatPrice(trStopClose))
                : ins.formatOffset(SLoffsetTicks));

        dp.slProfit = trStopEnabled ? trStopProfitText : slProfitText;
        dp.slProfitValue = trStopEnabled ? trStopProfitValue : slProfitValue;

        if (dp.OrderType === OrderType.OCO) {
            dp.slLabel = '';
            dp.slProfit = slProfit + '/' + slProfit;
        }
    }

    public TPPart (dp, ins, account, OpenPrice, Qty, isBuy, crossPrice, openCross, orderObject): void {
        const inPoints = GeneralSettings.TradingDefaults.ShowOffsetIn == OffsetModeViewEnum.Points;
        const TPoffset = dp.tp.value - OpenPrice;
        const TPoffsetTicks = ins.CalculateTicks(OpenPrice, TPoffset);

        let TPClose = dp.tp.value;
        let tpvalue = dp.tp.value * (isBuy ? 1 : -1);
        if (dp.tp.inOffset) {
            if (inPoints) {
                tpvalue = ins.CalculateTicks(0, tpvalue) * (isBuy ? 1 : -1);
            }

            TPClose = ins.CalculatePrice(OpenPrice, tpvalue);
        }

        const tpProfitValue = ProfitCalculator.CalculateSLTP(Qty, TPClose, OpenPrice, ins, account, isBuy, openCross, orderObject?.ProductType);
        const tpProfit = account.formatPrice(tpProfitValue);

        if (dp.sl.inOffset && dp.trailingStop.offsetValue) {
            tpvalue = (dp.trailingStop.offsetValue + dp.tp.value) * (isBuy ? 1 : -1);

            if (inPoints) {
                tpvalue = ins.CalculateTicks(0, tpvalue) * (isBuy ? 1 : -1);
            }

            TPClose = ins.CalculatePrice(OpenPrice, tpvalue);
        }

        let TP_relative = 0;
        const ProjectBalance = account.Profit + account.assetBalanceDefault.Balance;
        if (ProjectBalance != 0) {
            TP_relative = MathUtils.Round((tpProfitValue / ProjectBalance) * 100, 2);
        }

        const tpProfitText = tpProfit + ' ' + '(' + TP_relative + '%)';

        dp.tpLabel = dp.tp.inOffset ? ins.formatPrice(TPClose) : ins.formatOffset(TPoffsetTicks);
        dp.tpProfit = tpProfitText;
        dp.tpProfitValue = tpProfitValue;

        if (dp.OrderType === OrderType.OCO) {
            dp.tpLabel = '';
            dp.tpProfit = tpProfit + '/' + tpProfit;
        }
    }

    // TODO. Rename.
    public getDynProperty_SLTPPart (srcPart, isTrStop, instrument: Instrument): any {
        const dest: any = {};

        dest.enabled = srcPart.enabled;
        dest.error = srcPart.error;

        const res = NumericUtils.getNumericsOffsetModeViewParams(
            instrument, srcPart.offsetType !== null, isTrStop);
        const intrvls = Intervals.GetIntervalsFromInstrument(instrument, NumericUtils.MAXVALUE);

        dest.decimalPlaces = res.numericsPrec;
        dest.increment =
            isTrStop
                ? res.ifIsTrStopStep
                : res.step;

        // используем интервал когда:
        // слтп не в офсете и выставлены они были не в офсете
        // когда позиция, отвечает AdditionalIntervalVerification

        let useInterval = !GeneralSettings.TradingDefaults.SetSlTpValuesInOffset && srcPart.offsetType === null;

        useInterval = this.AdditionalIntervalVerification() || useInterval;

        dest.minimalValue = intrvls && (intrvls.length > 0) && useInterval ? intrvls[0].LeftValue : dest.increment;
        dest.maximalValue = NumericUtils.MAXVALUE;

        if (intrvls && !isTrStop && useInterval) {
            dest.Intervals = intrvls;
        }

        dest.value = srcPart.value;
        if (dest.value === null) {
            dest.value = dest.minimalValue;
        }

        dest.sllValue = srcPart.sllValue;

        dest.canEdit = true;

        if (!isTrStop) {
            dest.inOffset = srcPart.offsetType !== null;
        }

        return dest;
    }

    // #endregion DynProperty

    // TODO. Refactor.
    public getRawValue (tradingData): SlTpHolder {
        const ins = tradingData.instrument;
        const sltp = new SlTpHolder();

        if (this.useSL()) {
            const offsetType = this.sl.offsetType;
            const value = this.sl.value;
            const absolute = offsetType === null;

            sltp.StopLossPriceType = absolute
                ? SlTpPriceType.Absolute
                : SlTpPriceType.Offset;

            sltp.StopLossPriceValue = absolute
                ? value
                : OrderUtils.toRawTicks(value, offsetType, ins);

            const sllValue = this.sl.sllValue;
            if (sllValue != null) {
                sltp.StopLossLimitPriceValue = absolute
                    ? sllValue
                    : OrderUtils.toRawTicks(sllValue, offsetType, ins);
            };

            sltp.SLTPTriggerShortValue = this.sltpTriggerShort || this.nonEditableSLTPTriggerShort;
        }

        if (this.useTrailingStop()) {
            const value = this.trailingStop.value;
            const offsetType = this.trailingStop.offsetType;
            sltp.StopLossPriceType = SlTpPriceType.TrOffset;
            sltp.StopLossPriceValue = OrderUtils.toRawTicks(value, offsetType, ins);

            this.trailingStop.RealTrStopPrice = null;

            const ordType = tradingData.OrderType;
            const tq = tradingData.quote;
            const spP = this.DataCache.GetSpreadPlan(tradingData.account);
            const isBuy = tradingData.side === OperationType.Buy;
            let OpenPrice = NaN;
            // if (ordType === OrderType.Market)
            //     OpenPrice = isBuy ? tq.AskSpread_SP_Ins(spP, ins) : tq.BidSpread_SP_Ins(spP, ins)
            if (ordType === OrderType.PositionEdit && tradingData.isPosition) {
                OpenPrice = (isBuy ? tq.BidSpread_SP_Ins(spP, ins) : tq.AskSpread_SP_Ins(spP, ins)) || tradingData.position.CurPriceClose;
            }
            if (!isNaN(OpenPrice) && this.DataCache.isAllowedForMainAccount(RulesSet.FUNCTION_TRAILING_STOP_BY_PRICE)) {
                // Посчитали истинные тики, соответсвенно потом в расчёте цены мы игнорим фракционы
                const SLoffset = OrderUtils.toRawTicks(this.trailingStop.value, this.trailingStop.offsetType, ins) * (isBuy ? -1 : 1);
                sltp.RealTrStopPrice = ins.roundPrice(ins.CalculatePrice(OpenPrice, SLoffset, true));
                this.trailingStop.RealTrStopPrice = sltp.RealTrStopPrice;
            }
        }

        if (this.useTP()) {
            const offsetType = this.tp.offsetType;
            const value = this.tp.value;
            const absolute = offsetType === null;

            sltp.TakeProfitPriceType = absolute
                ? SlTpPriceType.Absolute
                : SlTpPriceType.Offset;

            sltp.TakeProfitPriceValue = absolute
                ? value
                : OrderUtils.toRawTicks(value, offsetType, ins);

            sltp.SLTPTriggerShortValue = this.sltpTriggerShort || this.nonEditableSLTPTriggerShort;
        }

        return sltp;
    }

    // TODO. UGLY. Refactor.
    // Details are at the top of OrderEditBase.ts.
    // TODO. Absolute price conversion to ticks and vice versa.
    public createDynPropertyForRawSLTP (sltpHolder, tradingData, slBasePriceGetter, tpBasePriceGetter): any {
        if (!sltpHolder || !tradingData?.instrument) {
            return null;
        }

        const ins = tradingData.instrument;
        const buy = tradingData.side === OperationType.Buy;

        const newTpVal = sltpHolder.TakeProfitPriceValue;
        const newSlOrTrStopVal = sltpHolder.StopLossPriceValue;
        const newSlOrTrOpenVal = sltpHolder.OpenLossPriceValue;

        const newTpAbsolute = sltpHolder.TakeProfitPriceType === SlTpPriceType.Absolute;
        const newSlAbsolute = sltpHolder.StopLossPriceType === SlTpPriceType.Absolute;

        const newTpEnabled = !isNaN(newTpVal);
        const newSlEnabled = !isNaN(newSlOrTrStopVal);
        const newTrStopEnabled =
            newSlEnabled &&
            sltpHolder.StopLossPriceType === SlTpPriceType.TrOffset;

        const dp = this.getDynProperty(tradingData);

        dp.tp.enabled = newTpEnabled;
        dp.sl.enabled = newSlEnabled;
        dp.trailingStop.enabled = newTrStopEnabled;

        if (newSlEnabled || newTrStopEnabled || newTpEnabled) {
            dp.sltpTriggerShort = sltpHolder.SLTPTriggerShortValue;
        }

        if (newTpEnabled) {
            const tpBasePrice = tpBasePriceGetter ? tpBasePriceGetter() : 0;
            const resOffsetType = this.tp.offsetType;
            dp.tp.value = resOffsetType === null && newTpAbsolute
                ? newTpVal
                : OrderUtils.ConvertTickOffset(
                    ins,
                    resOffsetType,
                    tpBasePrice,
                    (newTpAbsolute ? InstrumentUtils.getPriceDifferenceInTicks(tpBasePrice, newTpVal, ins) : newTpVal) * (buy ? 1 : -1));
        }

        if (newTrStopEnabled) {
            const resOffsetType = this.trailingStop.offsetType;
            dp.trailingStop.value = OrderUtils.ConvertTickOffset(ins, resOffsetType, 0, newSlOrTrStopVal);
            this.trailingStop.visibleValue = OrderUtils.ConvertTickOffset(ins, resOffsetType, 0, newSlOrTrOpenVal || newSlOrTrStopVal);
        }
        if (newSlEnabled) {
            const slBasePrice = slBasePriceGetter ? slBasePriceGetter() : 0;
            const resOffsetType = this.sl.offsetType;
            dp.sl.value = resOffsetType === null && newSlAbsolute
                ? newSlOrTrStopVal
                : OrderUtils.ConvertTickOffset(
                    ins,
                    resOffsetType,
                    slBasePrice,
                    (newSlAbsolute ? InstrumentUtils.getPriceDifferenceInTicks(slBasePrice, newSlOrTrStopVal, ins) : newSlOrTrStopVal) * (buy ? -1 : 1));

            if (/* tradingSettings.useStopLimitInsteadStop && */ sltpHolder.StopLossLimitPriceValue && !newTrStopEnabled) {
                const sllValue = sltpHolder.StopLossLimitPriceValue;

                if (resOffsetType === null && newSlAbsolute) {
                    dp.sl.sllValue = sllValue;
                } else {
                    const sllTickOffset = newSlAbsolute ? InstrumentUtils.getPriceDifferenceInTicks(sllValue, newSlOrTrStopVal, ins) : sllValue;
                    const priceToCalcFrom = tradingData.position ? tradingData.position.Price : slBasePrice;
                    let sign = 1;

                    if (resOffsetType !== null && sllTickOffset < 0) sign = -1;

                    dp.sl.sllValue = OrderUtils.ConvertTickOffset(ins, resOffsetType, priceToCalcFrom, sllTickOffset * (buy ? -1 : 1)) * sign;
                }

                this.sl.stillDefaultSLL = false; // #112707
            }
            // else    // #97323 при смене на Tr сброс sll для activated ордера приводит к неправильному подсчету офсета
            //     dp.sl.sllValue = null
        }

        return dp;
    }

    public update (updateData, tradingData): boolean {
        let parameterChanged = false;

        const dp = updateData.dp;
        if (dp) {
            parameterChanged = this.updateFromDynProperty(dp, tradingData) || parameterChanged;
        }

        const sessionSettingsChanged = updateData.sessionSettingsChanged;
        if (sessionSettingsChanged) {
            this.updateOffsetTypes();
            parameterChanged = true || parameterChanged;
        }

        let newInstrument = null;
        let newAccount = null;
        let newQuote = null;

        const newTradingDataDict = updateData.newTradingDataDict;
        if (newTradingDataDict) {
            newInstrument = newTradingDataDict.instrument;
            newAccount = newTradingDataDict.account;
            newQuote = newTradingDataDict.quote;
        }

        if (newInstrument || newAccount) {
            parameterChanged = this.updateAllowedRules(
                tradingData.instrument,
                tradingData.account,
                tradingData.order || tradingData.position) || parameterChanged; // if modify for #92505
        }

        if (sessionSettingsChanged || newInstrument) {
            this.sl.value = this.sl.sllValue = this.tp.value = this.trailingStop.value = this.sltpTriggerShort = null;
            this.needSetDefaultSLTP = true;
            parameterChanged = true || parameterChanged;
        }

        const instrument = tradingData.instrument;
        const quote = newQuote || (tradingData ? tradingData.quote : null);
        if (this.needSetDefaultSLTP && quote && instrument) {
            this.needSetDefaultSLTP = false;
            parameterChanged = this.setDefaultSLTP(tradingData) || parameterChanged;
        }

        return parameterChanged;
    }

    // TODO. UGLY. Refactor. Rename.
    public updateOffsetTypes (): void {
        const trading = GeneralSettings.TradingDefaults;

        const sltpOffsetMode = this.forceOffset || trading.SetSlTpValuesInOffset;
        const offsetViewMode = trading.ShowOffsetIn;

        this.sl.offsetType = this.tp.offsetType =
            sltpOffsetMode ? offsetViewMode : null;

        this.trailingStop.offsetType = offsetViewMode;
    }

    // TODO. Rename?
    public updateAllowedRules (instrument, account, modifyObj): boolean {
        let parameterChanged = false;

        let newAllowedSL = IsAllowed.IsSLTPAllowed(instrument, account, true).Allowed;

        if (modifyObj) {
            if (modifyObj.isPosition) {
                if (modifyObj.SLOrder !== null) // try modify position with enabled SL
                { newAllowedSL = IsAllowed.IsPositionModifyingAllowed(modifyObj).Allowed; }; // SLAllowed if modifying allowed #92505
            } else
                if (!isNaN(modifyObj.StopLossPriceValue)) // try modify order with enabled SL
                {
                    newAllowedSL = IsAllowed.IsOrderModifyingAllowed(modifyObj).Allowed;
                } // SLAllowed if modifying allowed #92505
        }

        if (this.allowedSL !== newAllowedSL) {
            this.allowedSL = newAllowedSL;
            parameterChanged = true || parameterChanged;
        }

        const newAllowedTP = IsAllowed.IsSLTPAllowed(instrument, account, false).Allowed;
        if (this.allowedTP !== newAllowedTP) {
            this.allowedTP = newAllowedTP;
            parameterChanged = true || parameterChanged;
        }

        const newTrailingStopAllowed = IsAllowed.IsTrailingStopForSLAllowed(instrument, account, modifyObj ? modifyObj.isPosition : false).Allowed;
        if (this.trailingStopAllowed !== newTrailingStopAllowed) {
            this.trailingStopAllowed = newTrailingStopAllowed;
            parameterChanged = true || parameterChanged;
        }

        return parameterChanged;
    }

    public updateFromDynProperty (dp, tradingData): boolean // TODO: разобраться что тут происходит, дописать комменты
    {
        let parameterChanged = false;

        let ch = false;
        if (!dp.trailingStop.enabled || dp.sl.enabled != this.sl.enabled) {
            ch = this.updateSLTPartFromDynProperty(dp.sl, this.sl);
        }

        const trCh = dp.trailingStop.enabled !== this.trailingStop.enabled;
        // поменялся sl, обнови trailingStop или произошло ебаное переключение
        if (ch || trCh) {
            if (dp.sl.inOffset && !this.SkipChange) {
                if (dp.trailingStop.enabled) {
                    dp.trailingStop.value = dp.sl.value;
                } else if (trCh) {
                    this.sl.value = dp.trailingStop.value; // #92893
                    if (this.sl.sllValue !== null && this.sl.stillDefaultSLL) // ##112707
                    {
                        this.sl.stillDefaultSLL = false;
                        this.sl.sllValue = this.sl.value;
                    }
                }
            } else if (!this.SkipChange) {
                const td = tradingData;
                let OpenPrice = td.limitPrice || td.stopPrice;

                if (td.quote) {
                    const sp = this.DataCache.GetSpreadPlan(td.account);
                    if (OrderType.Market === td.OrderType) {
                        OpenPrice = td.side === OperationType.Buy ? td.quote.AskSpread_SP_Ins(sp, td.instrument) : td.quote.BidSpread_SP_Ins(sp, td.instrument);
                    }

                    if (td.OrderType === OrderType.PositionEdit && td.isPosition) {
                        OpenPrice = (td.side === OperationType.Buy ? td.quote.BidSpread_SP_Ins(sp, td.instrument) : td.quote.AskSpread_SP_Ins(sp, td.instrument)) || td.position.CurPriceClose;
                    }
                }

                if (!OpenPrice && td.position) {
                    OpenPrice = td.position.Price;
                }

                // При выключении нужно зафигачить
                if (trCh && this.trailingStop.enabled) {
                    let SLoffset = dp.trailingStop.value * (td.side === OperationType.Buy ? -1 : 1);
                    if (OpenPrice) {
                        // Сюда могут насыпать поинты или фракционы, нам нужны тики
                        // Посчитали истинные тики, соответсвенно потом в расчёте цены мы игнорим фракционы
                        SLoffset = OrderUtils.toRawTicks(SLoffset, this.trailingStop.offsetType, tradingData.instrument);
                        dp.sl.value = td.instrument.CalculatePrice(OpenPrice, SLoffset, true);
                        // Применяем новые значения
                        this.updateSLTPartFromDynProperty(dp.sl, this.sl);
                    }
                }
                // #98353
                const slValue = dp.sl.sllValue != null && !dp.trailingStop.enabled ? dp.sl.sllValue : dp.sl.value;

                const SLoffset = slValue - OpenPrice;
                if (OpenPrice) {
                    const tiks = td.instrument.CalculateTicks(OpenPrice, SLoffset, true) * Math.sign(SLoffset);
                    dp.trailingStop.value = OrderUtils.ConvertTickOffset(tradingData.instrument, GeneralSettings.TradingDefaults.ShowOffsetIn, null, tiks);
                }

                if (trCh && this.trailingStop.enabled && this.sl.stillDefaultSLL && // При выключении нужно зафигачить sll = sl  //#112707, 101756 - ок,проверил
                    dp.sllValueEnabled) // #114935: но еще необходимо проверить что sll в принципе включен
                {
                    this.sl.sllValue = dp.sl.value;
                }

                // if (trCh)                                            // с этим if возникает баг #101756
                //     this.sl.sllValue = dp.sl.value      // #99816    // <- этот тикет какой-то отмороженный
            }

            // Убрать нельзя, мы поменяли значение в dp.trailingStop их нужно применить на this.trailingStop, это происходит при смене SL для синхоронизации значений.
            // строчка ниже выполняется при смене значений в самом dp.trailingStop и просмотра что там поменялось и применении на this.trailingStop
            // а также для запуска синхронизации обратной при смене trailingStop менять значения sl
            // ось така херня малята
            this.updateSLTPartFromDynProperty(dp.trailingStop, this.trailingStop); // убрать нельзя а то #93230 но зачем тогда такой же вызов через 7 строчек и последующий за ним if

            parameterChanged = trCh || parameterChanged;
        }

        parameterChanged = ch || parameterChanged;
        parameterChanged = this.updateSLTPartFromDynProperty(dp.tp, this.tp) || parameterChanged;

        // поменялся trailingStop, обнови sl
        ch = this.updateSLTPartFromDynProperty(dp.trailingStop, this.trailingStop);
        // #103417 нужно
        if (ch) {
            if (dp.sl.inOffset && !this.SkipChange) {
                dp.sl.value = dp.trailingStop.value;
            } else if (!this.SkipChange) {
                const td = tradingData;
                let OpenPrice = td.limitPrice || td.stopPrice;

                const sp = this.DataCache.GetSpreadPlan(td.account);
                if (OrderType.Market === td.OrderType && td.quote) {
                    OpenPrice = td.side === OperationType.Buy ? td.quote.AskSpread_SP_Ins(sp, td.instrument) : td.quote.BidSpread_SP_Ins(sp, td.instrument);
                }

                if (!OpenPrice && td.position) {
                    OpenPrice = td.position.Price;
                }

                let needRecalc = true;
                const SLoffset = dp.trailingStop.value * (td.side === OperationType.Buy ? -1 : 1);
                if (td.OrderType === OrderType.PositionEdit && td.isPosition) {
                    // ЕБУЧАЯ ХУЙНЯ при модификации перетягивании на чарте у нас тики trailingStop передаются от position.Price
                    // нужно скоректировать на расчёт от position.CurPriceClose
                    // корректировка происходит в случае модификации позиции
                    OpenPrice = td.position.CurPriceClose;

                    // ну как же мы без поинтов и фракционов?
                    // спасибо  Utils.toRawTicks ))
                    // dp.trailingStop.offsetType и this.trailingStop.offsetType, offsetType в dp.trailingStop нету
                    const checkSLoffset = OrderUtils.toRawTicks(SLoffset, this.trailingStop.offsetType, tradingData.instrument);

                    const slValue = dp.sl.sllValue != null && !dp.trailingStop.enabled ? dp.sl.sllValue : dp.sl.value;
                    const SLoffsetDiff = slValue - td.position.Price;
                    const ticks = td.instrument.CalculateTicks(OpenPrice, SLoffsetDiff, true) * Math.sign(SLoffsetDiff);
                    // при перетаскивании мы получаем одинаковое количество тиков если их отложить между position.Price и slValue
                    // тогда мы не меняем slValue, а вот trailingStop нужно отодвинуть относительно position.CurPriceClose
                    // очередное говно!!!
                    needRecalc = ticks !== checkSLoffset;
                    if (OpenPrice && !needRecalc) {
                        const SLoffsetDiff2 = slValue - OpenPrice;
                        const tiks = td.instrument.CalculateTicks(OpenPrice, SLoffsetDiff2, true) * Math.sign(SLoffsetDiff2);
                        dp.trailingStop.value = OrderUtils.ConvertTickOffset(tradingData.instrument, GeneralSettings.TradingDefaults.ShowOffsetIn, null, tiks);
                        this.updateSLTPartFromDynProperty(dp.trailingStop, this.trailingStop);
                    }
                }
                if (OpenPrice && needRecalc) {
                    // Как оказалось поинты сюда ненужно передавать, а SLoffset Utils.toRawTicks, т.е норм тики
                    // поэтому, да прибудут тики с магической функцией Utils.toRawTicks
                    const tiksCalc = OrderUtils.toRawTicks(SLoffset, this.trailingStop.offsetType, tradingData.instrument);
                    dp.sl.value = td.instrument.CalculatePrice(OpenPrice, tiksCalc);
                }
            }

            // Применяем новые значения
            this.updateSLTPartFromDynProperty(dp.sl, this.sl);
        }

        parameterChanged = ch || parameterChanged;

        if (!parameterChanged) {
            const td = tradingData;
            if (td.OrderType === OrderType.PositionEdit && td.isPosition && this.trailingStop.enabled) {
                const price = td.position.CurPriceClose;
                let SLoffset = dp.trailingStop.value;
                if (GeneralSettings.TradingDefaults.ShowOffsetIn == OffsetModeViewEnum.Points) {
                    SLoffset = OrderUtils.toRawTicks(SLoffset, this.trailingStop.offsetType, tradingData.instrument);
                } // переводим оффсет из поинтов в тики #92893 UPD да, именно тики, toRawTicks в помощь
                SLoffset *= td.side === OperationType.Buy ? -1 : 1;

                if (price) {
                    dp.sl.value = td.instrument.CalculatePrice(price, SLoffset);
                }

                parameterChanged = trCh || parameterChanged;
            }
        }

        parameterChanged = this.updateSLTPTriggersPartFromDynProperty(dp) || parameterChanged;

        return parameterChanged;
    }

    public updateSLTPTriggersPartFromDynProperty (dp): boolean // значення, що повертається - чи відбулася зміна знач. парам. sltpTriggerShort
    {
        if (dp.nonEditableSLTPTriggerShort !== null) {
            this.nonEditableSLTPTriggerShort = dp.nonEditableSLTPTriggerShort;
        }

        if (this.sltpTriggerShort !== dp.sltpTriggerShort) {
            this.sltpTriggerShort = dp.sltpTriggerShort;
            return true;
        }

        return false;
    }

    // TODO. Rename. Refactor.
    public updateSLTPartFromDynProperty (src, dest): boolean {
        let parameterChanged = false;

        if (dest.enabled !== src.enabled) {
            dest.enabled = src.enabled;
            parameterChanged = true || parameterChanged;
        }

        if (!(isNaN(dest.value) && isNaN(src.value)) &&
            dest.value !== src.value) {
            dest.value = src.value;
            parameterChanged = true || parameterChanged;
        }

        if (!(isNaN(dest.sllValue) && isNaN(src.sllValue)) &&
            dest.sllValue != null && src.sllValue !== null &&
            dest.sllValue !== src.sllValue) {
            dest.sllValue = src.sllValue;
            parameterChanged = true || parameterChanged;
        }

        return parameterChanged;
    }

    // TODO. Refactor. UGLY.
    public setDefaultSLTP (tradingData, setSL: any = undefined, setTP: any = undefined, setTrailingStop: any = undefined): boolean {
        setSL = setSL === undefined || setSL;
        setTP = setTP === undefined || setTP;
        setTrailingStop = setTrailingStop === undefined || setTrailingStop;

        const instrument = tradingData.instrument;
        const quote = tradingData.quote;
        const buy = tradingData.side === OperationType.Buy;

        let parameterChanged = false;

        const insDefSettings = GeneralSettings.InsDefSettingsStorage.GetInstrumentSettings(instrument);

        let basePrice;
        if (isValidNumber(tradingData.limitPrice)) {
            basePrice = tradingData.limitPrice;
        } else if (isValidNumber(tradingData.stopPrice)) {
            basePrice = tradingData.stopPrice;
        } else {
            basePrice = quote.BidSpread_SP_Ins(this.DataCache.GetSpreadPlan(tradingData.account), instrument);
        }

        if (setSL) {
            const slTickOffset =
                insDefSettings ? insDefSettings.SLDefaultOffsetTicksDecimal : 1;

            parameterChanged = SLTPEdit.setDefault_SLTPPart(
                this.sl, instrument, basePrice, slTickOffset * (buy ? -1 : 1)) || parameterChanged;

            this.sl.sllValue = this.sl.value && GeneralSettings.TradingDefaults.UseStopLimitInsteadStop ? this.sl.value : null;
            this.sl.stillDefaultSLL = true;
        }

        if (setTP) {
            const tpTickOffset =
                insDefSettings ? insDefSettings.TPDefaultOffsetTicksDecimal : 1;

            parameterChanged = SLTPEdit.setDefault_SLTPPart(
                this.tp, instrument, basePrice, tpTickOffset * (buy ? 1 : -1)) || parameterChanged;
        }

        if (setTrailingStop) {
            const trailingStopTickOffset = insDefSettings ? insDefSettings.SLDefaultOffsetTicksDecimal : 1;

            parameterChanged = SLTPEdit.setDefault_SLTPPart(
                this.trailingStop, instrument, basePrice, trailingStopTickOffset * (buy ? -1 : 1)) || parameterChanged;
        }

        return parameterChanged;
    }

    public static setDefault_SLTPPart (slTpPart, instrument, basePrice, tickOffset): boolean {
        let parameterChanged = false;

        const newValue = OrderUtils.ConvertTickOffset(
            instrument, slTpPart.offsetType, basePrice, tickOffset);

        if (slTpPart.value !== newValue) {
            slTpPart.value = newValue;
            parameterChanged = true || parameterChanged;
        }

        return parameterChanged;
    }

    // #region Validation

    public validate (slBasePriceGetter, tpBasePriceGetter, tradingData): boolean {
        let parameterChanged = false;

        const buy = tradingData.side === OperationType.Buy;
        const instrument = tradingData.instrument;

        parameterChanged = this.validate_SLTPPart(
            this.sl,
            this.getSLError,
            // TODO. Refactor.
            slBasePriceGetter ? slBasePriceGetter() : 0,
            buy,
            instrument,
            this.useSL()
        ) || parameterChanged;

        parameterChanged = this.validate_SLTPPart(
            this.tp,
            this.getTPError,
            // TODO. Refactor.
            tpBasePriceGetter ? tpBasePriceGetter() : 0,
            buy,
            instrument,
            this.useTP()
        ) || parameterChanged;

        return parameterChanged;
    }

    // TODO. Refactor. Rename.
    public validate_SLTPPart (sltpPartSource, sltpPartValidator, basePrice, buy, instrument: Instrument, useSLTPPart): boolean {
        let parameterChanged = false;
        const sltpPartInAbsolute = sltpPartSource.offsetType === null;
        const newErr = useSLTPPart && sltpPartInAbsolute
            ? sltpPartValidator(basePrice, buy, instrument)
            : null;

        const oldErr = sltpPartSource.error;

        // TODO. Refactor.
        if (
            (!newErr && oldErr) ||
            (newErr && !oldErr) ||
            (newErr && !newErr.equals(oldErr))
        ) {
            sltpPartSource.error = newErr;
            parameterChanged = true || parameterChanged;
        }

        return parameterChanged;
    }

    // TODO. Refactor.
    public getSLError (basePrice, buy, instrument: Instrument): any {
        const slPrice = this.sl.value;
        let error = null;

        if (buy && slPrice >= basePrice) {
            error = new SLTPError(
                'UserControl.TerceraNumeric.ValueGreaterMax',
                instrument.formatPrice(basePrice - instrument.GetPointSize(slPrice - MathUtils.MATH_ROUND_EPSILON))
            );
        } else if (!buy && slPrice <= basePrice) {
            error = new SLTPError(
                'UserControl.TerceraNumeric.ValueLessMin',
                instrument.formatPrice(basePrice + instrument.GetPointSize(slPrice + MathUtils.MATH_ROUND_EPSILON))
            );
        }

        return error;
    }

    // TODO. Refactor.
    public getTPError (basePrice, buy, instrument: Instrument): any {
        const tpPrice = this.tp.value;
        let error = null;

        if (buy && tpPrice <= basePrice) {
            error = new SLTPError(
                'UserControl.TerceraNumeric.ValueLessMin',
                instrument.formatPrice(basePrice + instrument.GetPointSize(tpPrice + MathUtils.MATH_ROUND_EPSILON))
            );
        } else if (!buy && tpPrice >= basePrice) {
            error = new SLTPError(
                'UserControl.TerceraNumeric.ValueGreaterMax',
                instrument.formatPrice(basePrice - instrument.GetPointSize(tpPrice - MathUtils.MATH_ROUND_EPSILON))
            );
        }

        return error;
    }

    // #endregion Validation

    // TODO. Refactor.
    public getConfirmationText (tradingData): string {
        let result = '';
        let insertSemicolon = false;
        const ins = tradingData.instrument;

        const offsetViewModes = OffsetModeViewEnum;

        if (this.useTrailingStop()) {
            const sltpPart = this.trailingStop;
            let sltpValue = sltpPart.visibleValue || sltpPart.value;
            if (sltpPart.offsetType === offsetViewModes.Points) {
                sltpValue = sltpValue / ins.GetPointSize(null);
            }

            result += Resources.getResource('Tr. stop') + ': ';

            if (this.trailingStop.RealTrStopPrice) {
                result += ins.formatPrice(this.trailingStop.RealTrStopPrice);
            } else {
                result += ins.formatOffset(sltpValue) +
                    ' ' +
                    Resources.getResource(
                        sltpPart.offsetType === offsetViewModes.Points
                            ? 'general.trading.points'
                            : 'general.trading.pips');
            }

            insertSemicolon = true;
        }

        if (this.useSL()) {
            const sltpPart = this.sl;
            const slVal = sltpPart.value;
            const sllVal = sltpPart.sllValue;
            const absolute = sltpPart.offsetType === null;

            result +=
                Resources.getResource(absolute
                    ? 'panel.newOrderEntry.slPriceRisk'
                    : 'panel.newOrderEntry.slPriceRiskOffset') +
                ': ';

            result += absolute ? ins.formatPrice(slVal) : (sltpPart.offsetType === offsetViewModes.Points ? ins.formatPrice(slVal, false) : slVal);

            if (sllVal != null) {
                result += '/' + (absolute ? ins.formatPrice(sllVal) : (sltpPart.offsetType === offsetViewModes.Points ? ins.formatPrice(sllVal, false) : sllVal));
            }

            if (!absolute) {
                result +=
                    ' ' +
                    Resources.getResource(
                        sltpPart.offsetType === offsetViewModes.Points
                            ? 'general.trading.points'
                            : 'general.trading.pips');
            }

            insertSemicolon = true;
        }

        const sltpTrShortToShow = this.sltpTriggerShort || this.nonEditableSLTPTriggerShort;
        if (!MathUtils.IsNullOrUndefined(sltpTrShortToShow) && insertSemicolon) {
            result += ' (' + Resources.getResource('property.OESLTrigger') + ' ' +
                SLTPTrigger.GetTextValue(sltpTrShortToShow, tradingData.side, true) + ')';
        }

        if (this.useTP()) {
            const TP = this.tp;
            const value = TP.value;
            const offsetType = TP.offsetType;
            const absolute = offsetType === null;

            if (insertSemicolon) {
                result += '; ';
            }

            result +=
                Resources.getResource(absolute
                    ? 'panel.newOrderEntry.tpPriceRisk'
                    : 'panel.newOrderEntry.tpPriceRiskOffset') +
                ': ';
            result += absolute ? ins.formatPrice(value) : (offsetType === offsetViewModes.Points ? ins.formatPrice(value, false) : value);

            if (!absolute) {
                result +=
                    ' ' +
                    Resources.getResource(
                        offsetType === offsetViewModes.Points
                            ? 'general.trading.points'
                            : 'general.trading.pips');
            }

            if (!MathUtils.IsNullOrUndefined(sltpTrShortToShow)) {
                result += ' (' + Resources.getResource('property.OETPTrigger') + ' ' +
                    SLTPTrigger.GetTextValue(sltpTrShortToShow, tradingData.side, false) + ')';
            }
        }

        return result;
    }

    public enableSL (): void {
        this.sl.enabled = true;
    }

    public getSLTPTriggerStr (isSL, isBuy): any // helps to pick trigger price #109798 docs(3.3.2) Trading logic)
    {
        const SLOrTP = this[isSL ? 'sl' : 'tp'];
        const triggerShort = this.sltpTriggerShort || this.nonEditableSLTPTriggerShort;

        if (SLOrTP.enabled) {
            return SLTPEdit.GetStrByShort(triggerShort, isSL, isBuy);
        }

        return null;
    }

    public static GetStrByShort (short, isSL, isBuy): string | null // helps to pick trigger price #109798 docs(3.3.2) Trading logic)
    {
        if (short === null) {
            return null;
        }

        const side = OperationType[isBuy ? 'Buy' : 'Sell'];
        const bidOrAskStr = SLTPTrigger.GetTextValue(short, side, isSL);

        if (bidOrAskStr) {
            // let compareWith = isSL ? 'Bid' : 'Ask'
            return bidOrAskStr; //= = 'Bid' ? (isBuy ? 'Bid' : 'Ask') : (isBuy ? 'Ask' : 'Bid')
        }

        return null;
    }

    public toJSON (): any {
        return {
            sl: this.sl,
            tp: this.tp,
            trailingStop: this.trailingStop,
            sltpTriggerShort: this.sltpTriggerShort,
            sllValueEnabled: this.sllValueEnabled
        };
    }
}
