// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectExternalLinkMessage } from '../../../../Utils/DirectMessages/DirectMessagesImport';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class ExternalLinkMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): DirectExternalLinkMessage[] {
        const message = new DirectExternalLinkMessage();

        const factory = FieldsFactory;
        const len = fieldSet.Count();

        for (let i = 0; i < len; i++) {
            const fixField = fieldSet.fieldSet[i];
            if (fixField.FieldId === 0) { continue; }

            if (fixField.FieldId === factory.FIELD_ID) {
                message.Id = fixField.Value;
            } else if (fixField.FieldId === factory.FIELD_NAME) {
                message.Name = fixField.Value;
            } else if (fixField.FieldId === factory.FIELD_EXTERNAL_LINK_URL) {
                message.Url = fixField.Value;
            } else if (fixField.FieldId === factory.FIELD_EXTERNAL_LINK_CLIENT_USE_INTERNAL_BROWSER) {
                message.UseInternalBrowser = fixField.Value;
            } else if (fixField.FieldId === factory.FIELD_EXTERNAL_RESOURCE) {
                message.ExternalResource = fixField.Value;
            } else if (fixField.FieldId === factory.FIELD_EXTERNAL_LINK_PARAMETERS) {
                message.ExternalLinkParameters = fixField.Value;
            } else if (fixField.FieldId === factory.FIELD_EXTERNAL_LINK_WEBCON_DARK) {
                message.ExternalLinkWebContentDark = fixField.Value;
            } else if (fixField.FieldId === factory.FIELD_EXTERNAL_LINK_WEBCON_LIGHT) {
                message.ExternalLinkWebContentLight = fixField.Value;
            }
        }

        return [message];
    }
}
