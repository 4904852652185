import { Control } from '../../elements/Control';
import { HeatmapSymbolInfoTemplate } from '../../../templates';
import { Resources } from '../../../Commons/properties/Resources';
import { HeatmapLogoProvider } from './HeatmapLogoProvider';
import { DataCache } from '../../../Commons/DataCache';
import { type Instrument } from '../../../Commons/cache/Instrument';
import { type HeatmapData } from '../../../Commons/Heatmap/Models/HeatmapData';

export class HeatmapSymbolInfo extends Control.extend({
    template: HeatmapSymbolInfoTemplate,
    data: function () {
        return {
            heatmapData: null,
            symbol: '',
            symbolLabel: '',
            lastPrice: '',
            lastPriceLabel: '',
            change: '',
            changeLabel: '',
            changePercent: '',
            changePercentLabel: '',
            marketCap: '',
            marketCapLabel: ''
        };
    },
    computed: {
        logoImage: function () {
            const heatmapData = this.get('heatmapData');
            if (!isNullOrUndefined(heatmapData) && isValidString(heatmapData.logoAddress)) {
                const logoImage = HeatmapLogoProvider.getLogo(heatmapData.logoAddress);
                return !isNullOrUndefined(logoImage) ? logoImage.src : null;
            } else {
                return null;
            }
        }
    }
}) {
    getType (): string { return 'HeatmapSymbolInfo'; }
    oninit (): void {
        super.oninit();
        this.onUpdateInstrument = this.onUpdateInstrument.bind(this);
        DataCache.OnUpdateInstrument.Subscribe(this.onUpdateInstrument, this);
        HeatmapLogoProvider.subscribeOnLoad(this.onUpdateInstrument);
    }

    onteardown (): void {
        DataCache.OnUpdateInstrument.UnSubscribe(this.onUpdateInstrument, this);
        HeatmapLogoProvider.unsubscribeOnLoad(this.onUpdateInstrument);
        super.onteardown();
    }

    localize (): void {
        super.set('lastPriceLabel', Resources.getResource('property.Last'));
        super.set('changeLabel', Resources.getResource('property.Change'));
        super.set('changePercentLabel', Resources.getResource('property.ChangePercent'));
        super.set('marketCapLabel', Resources.getResource('property.MarketCap'));
    }

    private onUpdateInstrument (instrument: Instrument): void {
        const heatmapData: HeatmapData = super.get('heatmapData');
        if (!isNullOrUndefined(heatmapData) && heatmapData.Instrument === instrument) {
            super.update();
        }
    }
}
