import { type Instrument } from '../../Instrument';

export class Option {
    public readonly strikePrice: number;
    public readonly put: Instrument;
    public readonly call: Instrument;

    constructor (strikePrice: number, put: Instrument, call: Instrument) {
        this.strikePrice = strikePrice;
        this.put = put;
        this.call = call;
    }
}
