// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
// TODO. Move.

import { TimeZoneInfo } from "./TimeZoneInfo.ts";

// Key - utc offset in millis 
export let TimeZoneInfoSet = {};
(function ()
{
    let len = TimeZoneJsonArray.length;
    for (let i = 0; i < len; i++)
    {
        let tzInfo = new TimeZoneInfo(TimeZoneJsonArray[i]);
        TimeZoneInfoSet[tzInfo.id] = tzInfo;
    }

    let localOffset = (new Date()).getTimezoneOffset() / -60;
    TimeZoneInfoSet[TimeZoneInfo.LOCAL] = new TimeZoneInfo({ value: TimeZoneInfo.LOCAL, offset: localOffset });
    TimeZoneInfoSet[TimeZoneInfo.SERVER] = new TimeZoneInfo({ value: TimeZoneInfo.SERVER, offset: localOffset });
})();