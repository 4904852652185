// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

export class UTCOffset {
    public positive: boolean;
    public hours: number;
    public minutes: number;

    constructor (offsetHours) {
        this.positive = offsetHours >= 0;

        const absOffsetHours = Math.abs(offsetHours);
        this.hours = Math.trunc(absOffsetHours);
        this.minutes = absOffsetHours % 1 * 60;
    }

    public static padNumber (num, size): string {
        let str = num.toString();
        const positive = num >= 0;

        if (!positive) str = str.substring(1);

        while (str.length < size) str = '0' + str;

        return positive ? str : '-' + str;
    }

    public compareTo (utcOffset): number {
        return this.totalMinutes() - utcOffset.totalMinutes();
    }

    public totalMinutes (): number {
        const absMinutes = this.hours * 60 + this.minutes;
        return this.positive ? absMinutes : -absMinutes;
    }

    public toString (): string {
        const positive = this.positive;
        const hours = this.hours;
        const minutes = this.minutes;

        if (!hours && !minutes) return '';

        return (positive ? '+' : '-') +
            UTCOffset.padNumber(hours, 2) + ':' +
            UTCOffset.padNumber(minutes, 2);
    }
}
