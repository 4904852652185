// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CreateAlertAccountLookupTemplate } from '../../../templates';
import { ContainerControl } from '../../elements/ContainerControl_ts';
import { Resources } from '../../../Commons/properties/Resources';
import { ControlsTypes } from '../../UtilsClasses/FactoryConstants';

export class CreateAlertAccountLookup extends ContainerControl {
    constructor () { super(); }
    getType (): string { return ControlsTypes.CreateAlertAccountLookup; }

    oninit (): void {
        super.oninit();
        Resources.onLocaleChange.Subscribe(this.localize, this);
        this.localize();
    }

    dispose (): void {
        Resources.onLocaleChange.UnSubscribe(this.localize, this);
        super.dispose();
    }

    localize (): void {
        void this.set('label', Resources.getResource('screen.Alerts.Label.Account'));
    }
}

ContainerControl.extendWith(CreateAlertAccountLookup, {
    template: CreateAlertAccountLookupTemplate,
    data: function () {
        return {
            label: '',
            name: ControlsTypes.CreateAlertAccountLookup,
            account: null
        };
    }
});
