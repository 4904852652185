// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class SnapshotRequestMessage extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_GET_SNAPSHOT_REQUEST, fieldSet);
    }

    public getInstrumentTradableID (): number {
        return this.getFieldValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID);
    }

    public setInstrumentTradableID (id: number): void {
        this.setFieldValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID, id);
    }

    public getRouteId (): number {
        return this.getFieldValue(FieldsFactory.FIELD_ROUTE_ID);
    }

    public setRouteId (id: number): void {
        this.setFieldValue(FieldsFactory.FIELD_ROUTE_ID, id);
    }
}
