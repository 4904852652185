// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Color } from '../../../Graphics';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';

export class VOLUME extends IndicatorScriptBase {
    public ExpirationDate: number;

    constructor () {
        super();
        this.Comments = 'Volume';
        this.Company = 'TraderEvolution';
        this.Copyrights = '(c) TraderEvolution';
        this.ProjectName = 'Volume';
        this.ExpirationDate = 0;

        this.SetIndicatorLine('Volume', Color.Green, 2, LineStyle.HistogrammChart);
        this.SeparateWindow = true;
    }

    public Init (): void {
        this.IndicatorShortName('VOL');
    }

    public OnQuote (): void {
        super.OnQuote();

        const CurrentData = this.CurrentData;
        const count = CurrentData.Count;
        const currVol = CurrentData.Volume(0);
        const prevVol = count > 1 ? CurrentData.Volume(1) : 0;
        this.SetValue(0, currVol);

        if (prevVol < currVol) {
            this.SetMarker(0, Color.Green);
        } else if (prevVol > currVol) {
            this.SetMarker(0, Color.Red);
        } else {
            this.SetMarker(0, Color.Gray);
        }
    }
}
