// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectReportResponseMessage extends DirectMessageBaseClass {
    public xmlString: any;

    constructor () {
        super(Message.CODE_PFIX_XML_MESSAGE);

        this.xmlString = null;
    }
}
