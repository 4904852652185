import { type IOptionCalculator } from './IOptionCalculator';
import { OptionCalculatorModel } from './OptionCalculatorModel';
import { BlackSholesModel } from './Models/BlackSholes';

class _OptionCalculator {
    getCalculator (model: OptionCalculatorModel): IOptionCalculator {
        switch (model) {
        case OptionCalculatorModel.BlackSholes:
            return BlackSholesModel;
        default:
            return undefined;
        }
    }
}

export const OptionCalculator = new _OptionCalculator();
