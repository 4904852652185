// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

export class HistoricalBrokerMessageInfo {
    public ID: number | null = null;
    public TimeStamp: Date | null = null;
    public ProductID: number | null = null;
    public Subject: string | null = null;
    public Message: string | null = null;
    public IsUnread: boolean | null = null;
    public ShowPopUp: any = null;
    public ResponseCases: string[] | null = null;
    public CustomButtons: any = null;
    public ClusterNode: any = null;
    public DisplayMethod: number | null = null;
}
