// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { ContainerControl } from '../../elements/ContainerControl_ts';
import { NewAccountDetailsPanelTemplate } from '../../../templates';
import { ControlsTypes } from '../../UtilsClasses/FactoryConstants';

export class NewAccountDetailsPanel extends ContainerControl {
    public getType (): ControlsTypes {
        return ControlsTypes.NewAccountDetailsPanel;
    };

    constructor () {
        super();
    }

    oncomplete (): void {
        super.oncomplete();
    }
}

ContainerControl.extendWith(NewAccountDetailsPanel,
    {
        template: NewAccountDetailsPanelTemplate,
        data: function () {
            return {

            };
        }
    });
