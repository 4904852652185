// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { MathUtils } from '../../../Utils/MathUtils';
import { OrderEditUpdateData } from '../../../Utils/Trading/OrderEditUpdateData';
import { TIF } from '../../../Utils/Trading/OrderTif';
import { OrderType } from '../../../Utils/Trading/OrderType';
import { OrderUtils } from '../../../Utils/Trading/OrderUtils';
import { PlacedFrom } from '../../../Utils/Trading/PlacedFrom';
import { Quantity } from '../../../Utils/Trading/Quantity';
import { SlTpHolder } from '../../../Utils/Trading/SlTpHolder';
import { DataCache } from '../../DataCache';
import { ErrorInformationStorage } from '../../ErrorInformationStorage';
import { TradingOrderStatus } from '../../Trading/TradingOrderStatus';
import { type Instrument } from '../Instrument';
import { type OrderEditBase } from '../OrderParams/order-edit/OrderEditBase';
import { SavedOrder } from './SavedOrder';
import { UUID } from '../../../Utils/UUID';
import { OrderExecutor } from '../../Trading/OrderExecutor';
import { OrderConfrimation } from '../../Trading/OrderConfirmation';
import { GeneralSettings } from '../../../Utils/GeneralSettings/GeneralSettings';
import { SlTpPriceType } from '../../../Utils/Enums/Constants';
import { OrderStatus } from '../../../Utils/Trading/OrderStatus';

class _SavedOrdersController {
    async placeOrders (savedOrders: SavedOrder[]): Promise<void> {
        if (MathUtils.IsNullOrUndefined(savedOrders) || savedOrders.length === 0) {
            return;
        }

        for (let i = 0; i < savedOrders.length; i++) {
            const order = savedOrders[i];
            const orderEdit = this.createOrderEdit(order, false);
            try {
                const isConfirmed = await OrderConfrimation.processPlaceOrderConfrimationAsync(orderEdit);
                if (!isConfirmed) {
                    order.Status = TradingOrderStatus.CancelledByUser;
                } else {
                    await this.placeSavedOrderAsync(order, orderEdit);
                }
            } catch (exception) {
                switch (exception) {
                case OrderExecutor.BAD_PARAMETERS:
                    order.Status = TradingOrderStatus.IllegalParameters;
                    break;
                default:
                    order.Status = TradingOrderStatus.Error;
                    order.StatusError = exception;
                    break;
                }
                ErrorInformationStorage.GetException(exception);
            } finally {
                orderEdit.dispose();
            }
        }
    }

    createSavedOrder (orderEdit: OrderEditBase): SavedOrder {
        const savedOrder = new SavedOrder(UUID.generateUUIDv5());
        this.updateByOrderEdit(savedOrder, orderEdit);
        return savedOrder;
    }

    createOrderEdit (savedOrder: SavedOrder, isModify: boolean): OrderEditBase {
        const orderEdit = DataCache.OrderParameterContainer.OrderTypes[savedOrder.OrderType].createOrderEditObject({
            dataCache: DataCache
        });

        orderEdit.needSetDefaultStopPrice = false;
        orderEdit.needSetDefaultLimitPrice = false;
        orderEdit.needSetDefaultPrice = false;
        orderEdit.sltp.needSetDefaultSLTP = false;
        orderEdit.updateParameters(new OrderEditUpdateData(null, {
            placedFrom: PlacedFrom.WEB_SAVED_ORDERS,
            account: savedOrder.Account,
            instrument: savedOrder.Instrument,
            side: savedOrder.Operation,
            tif: new TIF(savedOrder.TIF, savedOrder.TIFExpiration),
            quantity: new Quantity(savedOrder.QuantityLots, true),
            productType: savedOrder.ProductType,
            leverageValue: savedOrder.Leverage,
            disclosedQuantity: new Quantity(savedOrder.DisclosedQuantityLots, true)
        }));
        const sltpHolder: SlTpHolder = new SlTpHolder();
        sltpHolder.copyFrom(savedOrder.SLTPHolder);
        if (isModify && !sltpHolder.isTrailingStop()) {
            if (GeneralSettings.TradingDefaults.SetSlTpValuesInOffset) {
                sltpHolder.StopLossPriceType = SlTpPriceType.Offset;
                sltpHolder.TakeProfitPriceType = SlTpPriceType.Offset;
                sltpHolder.StopLossPriceValue = savedOrder.SlOffset;
                sltpHolder.StopLossLimitPriceValue = savedOrder.SllOffset;
                sltpHolder.TakeProfitPriceValue = savedOrder.TpOffset;
            } else {
                sltpHolder.StopLossPriceType = SlTpPriceType.Absolute;
                sltpHolder.TakeProfitPriceType = SlTpPriceType.Absolute;
                sltpHolder.StopLossPriceValue = savedOrder.SlPrice;
                sltpHolder.StopLossLimitPriceValue = savedOrder.SllPrice;
                sltpHolder.TakeProfitPriceValue = savedOrder.TpPrice;
            }
        }
        orderEdit.setSLTP(sltpHolder);
        orderEdit.comment = `SavedOrder.${savedOrder.Id}`;

        if (savedOrder.OrderType === OrderType.TrailingStop) {
            const marketPrice = savedOrder.Instrument.GetMarketPrice(savedOrder.Account, savedOrder.Operation);
            const trailingStopTicks = savedOrder.StopPrice;
            const calculatedPrice = savedOrder.Instrument.CalculatePrice(marketPrice, trailingStopTicks);
            orderEdit.setBasePrice(calculatedPrice);
        } else {
            orderEdit.setBasePrice(savedOrder.Price);
        }

        if (orderEdit.setStopPrice !== undefined) {
            orderEdit.setStopPrice(savedOrder.StopPrice);
        }
        return orderEdit;
    }

    updateByOrderEdit (savedOrder: SavedOrder, orderEdit: OrderEditBase): void {
        const tradingData = orderEdit.getTradingData();
        const instrument: Instrument = tradingData.instrument;
        savedOrder.Account = tradingData.account;
        savedOrder.Instrument = instrument;
        savedOrder.Operation = tradingData.side;
        savedOrder.QuantityLots = Quantity.toLots(tradingData.quantity, instrument);
        savedOrder.DisclosedQuantityLots = Quantity.toLots(tradingData.disclosedQuantity, instrument);
        savedOrder.OrderType = tradingData.OrderType;
        savedOrder.TIF = tradingData.tif.type;
        savedOrder.TIFExpiration = tradingData.tif.expirationTime;
        if (!MathUtils.IsNullOrUndefined(tradingData.trailingStop)) {
            savedOrder.StopPrice = OrderUtils.toRawTicks(tradingData.trailingStop.value, tradingData.trailingStop.offsetViewMode, instrument);
        } else {
            savedOrder.StopPrice = tradingData.stopPrice;
        }
        savedOrder.Price = tradingData.limitPrice;
        const sltpHolder: SlTpHolder = orderEdit.sltp.getRawValue(orderEdit.getTradingData());
        savedOrder.SLTPHolder.copyFrom(sltpHolder);
        savedOrder.ProductType = Number(tradingData.productType);
        savedOrder.Leverage = tradingData.leverageValue;
    }

    private async placeSavedOrderAsync (savedOrder: SavedOrder, orderEdit: OrderEditBase): Promise<void> {
        savedOrder.Status = TradingOrderStatus.Placing;
        const response = await DataCache.FOrderExecutor.placeOrderAsync(orderEdit);
        if (!isNullOrUndefined(response) && response.reject) {
            savedOrder.Status = TradingOrderStatus.Error;
            savedOrder.StatusError = response.Data[0][1];
        } else if (response.Status === OrderStatus.REFUSED) {
            savedOrder.Status = TradingOrderStatus.Error;
            savedOrder.StatusError = response.Comment;
        }
    }
}

export const SavedOrdersController = new _SavedOrdersController();
