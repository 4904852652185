// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Rectangle } from '../../../Commons/Geometry';
import { LayersEnum } from '../../Renderers/TerceraChartBaseRenderer';
import { type Font, Pen, SolidBrush } from '../../../Commons/Graphics';
import { ToolView } from '../ToolView';
import { TradingToolBaseDataCacheTool } from '../../../Commons/cache/Tools/TradingToolBaseDataCacheTool';
import { Resources } from '../../../Commons/properties/Resources';
import { ThemeManager } from '../../../Controls/misc/ThemeManager';
import { OperationType } from '../../../Utils/Trading/OperationType';
import { ProfitCalculator } from '../../../Commons/cache/ProfitCalculator';
import { Selection, ToolSelectionType } from '../Selection';
import { type Order } from '../../../Commons/cache/Order';
import { type Position } from '../../../Commons/cache/Position';
import { type Cursors } from '../../../Commons/Cursors';

export abstract class TradingToolViewBase extends ToolView {
    public myRenderer: any;

    // #region properties
    public iconRect = new Rectangle(0, 0, 20, 22);
    public imageRect = Rectangle.Empty();
    public infoRect = Rectangle.Empty();
    public leverageRect = Rectangle.Empty();
    public actionButton = Rectangle.Empty();
    public removeButton = Rectangle.Empty();
    public trailingButton = Rectangle.Empty();
    // #endregion
    // To get rid of instanceof operator.
    public isTradingTool = true;
    public MainOrderVisibility = false;
    public ToolViewType = TradingToolViewType.TradingToolViewBase;

    public collapsed: boolean;

    constructor () {
        super(new TradingToolBaseDataCacheTool());// пустышка в базовый обьект
    }

    get ID (): string { return ''; }

    get Price (): number { return -1; }

    get PriceMin (): number { return this.Price; }

    get PriceMax (): number { return this.Price; }

    public DescriptionText (): string {
        return '';
    }

    public Updated (): void { }
    public CancelMoving (): void { }

    public GetCurrentSelection (x: number, y: number): ToolSelectionType {
        return ToolSelectionType.None;
    }

    public ResetMouse (): void { }

    get Collapsed (): boolean { return false; }
    set Collapsed (value) { }

    public static orderGrayPen: Pen;

    public static grayBrush: SolidBrush;
    public static buyBrush: SolidBrush;
    public static sellBrush: SolidBrush;

    // #region images

    public static tradeFont: Font;//= App.ThemeManager.CurrentTheme.Font_10F_regular;

    public static buyTradeImageActive;
    public static sellTradeImageActive;
    public static buyTradeImage;
    public static sellTradeImage;

    public static sell_open_position_defaultImage;
    public static sell_open_position_hoverImage;
    public static sell_close_position_defaultImage;
    public static sell_close_position_hoverImage;

    public static buy_open_position_defaultImage;
    public static buy_open_position_hoverImage;
    public static buy_close_position_defaultImage;
    public static buy_close_position_hoverImage;

    public static gray_open_positionImage;
    public static gray_close_positionImage;

    public static buyOrderImageActive;
    public static sellOrderImageActive;
    public static buyOrderImage;
    public static sellOrderImage;

    public static slGrayImage;
    public static tpGrayImage;

    public static slGrayImageActive;
    public static tpGrayImageActive;

    public static slBuyImageActive;
    public static slSellImageActive;
    public static tpBuyImageActive;
    public static tpSellImageActive;

    // buyCancel
    public static buy_cancel_defaultImage;
    public static buy_cancel_hoverImage;
    // sellCancel
    public static sell_cancel_defaultImage;
    public static sell_cancel_hoverImage;
    // mineCancelHover
    public static buy_cancel_mine_hoverImage;
    public static sell_cancel_mine_hoverImage;
    // moveOrderCancel
    public static moveOrder_cancelImage;
    // orderSL
    public static duy_SL_close_defaultImage;
    public static duy_SL_close_hoverImage;
    public static duy_SL_open_defaultImage;
    public static duy_SL_open_hoverImage;
    public static sell_SL_close_defaultImage;
    public static sell_SL_close_hoverImage;
    public static sell_SL_open_defaultImage;
    public static sell_SL_open_hoverImage;
    // SLL (stop lose limit)
    public static buy_SLL_close_defaultImage;
    public static buy_SLL_close_hoverImage;
    public static buy_SLL_open_defaultImage;
    public static buy_SLL_open_hoverImage;
    public static sell_SLL_close_defaultImage;
    public static sell_SLL_close_hoverImage;
    public static sell_SLL_open_defaultImage;
    public static sell_SLL_open_hoverImage;
    // move SLL (stop lose limit)
    public static close_SLL_gray_defaultImage;
    public static open_SLL_gray_defaultImage;
    public static close_SLL_gray_hoverImage;
    public static open_SLL_gray_hoverImage;
    // orderTP
    public static buy_TP_close_defaultImage;
    public static buy_TP_close_hoverImage;
    public static buy_TP_open_defaultImage;
    public static buy_TP_open_hoverImage;
    public static sell_TP_close_defaultImage;
    public static sell_TP_close_hoverImage;
    public static sell_TP_open_defaultImage;
    public static sell_TP_open_hoverImage;
    // TSL
    public static buy_TSL_defaultImage;
    public static buy_TSL_hoverImage;
    public static buy_TSL_mine_hoverImage;
    public static sell_TSL_defaultImage;
    public static sell_TSL_hoverImage;
    public static sell_TSL_mine_hoverImage;
    public static TSL_grayImage;
    public static TSL_gray_hoverImage;
    public static TSL_gray_mine_hoverImage;
    // MoveTPSL
    public static close_SL_grayImage;
    public static open_SL_grayImage;
    public static close_TP_grayImage;
    public static open_TP_grayImage;
    // LMT-buy
    public static buy_LMT_close_defaultImage;
    public static buy_LMT_close_hoverImage;
    public static buy_LMT_open_defaultImage;
    public static buy_LMT_open_hoverImage;
    public static buy_LMT_link_close_defaultImage;
    public static buy_LMT_link_close_hoverImage;
    public static buy_LMT_link_open_defaultImage;
    public static buy_LMT_link_open_hoverImage;
    // LMTsell
    public static sell_LMT_close_defaultImage;
    public static sell_LMT_close_hoverImage;
    public static sell_LMT_open_defaultImage;
    public static sell_LMT_open_hoverImage;
    public static sell_LMT_link_close_defaultImage;
    public static sell_LMT_link_close_hoverImage;
    public static sell_LMT_link_open_defaultImage;
    public static sell_LMT_link_open_hoverImage;
    // STP-buy
    public static buy_STP_close_defaultImage;
    public static buy_STP_close_hoverImage;
    public static buy_STP_open_defaultImage;
    public static buy_STP_open_hoverImage;
    public static buy_STP_link_close_defaultImage;
    public static buy_STP_link_close_hoverImage;
    public static buy_STP_link_open_defaultImage;
    public static buy_STP_link_open_hoverImage;
    // STPsell
    public static sell_STP_close_defaultImage;
    public static sell_STP_close_hoverImage;
    public static sell_STP_open_defaultImage;
    public static sell_STP_open_hoverImage;
    public static sell_STP_link_close_defaultImage;
    public static sell_STP_link_close_hoverImage;
    public static sell_STP_link_open_defaultImage;
    public static sell_STP_link_open_hoverImage;
    // MKT-buy
    public static buy_MKT_close_defaultImage;
    public static buy_MKT_close_hoverImage;
    public static buy_MKT_open_defaultImage;
    public static buy_MKT_open_hoverImage;
    // MKTsell
    public static sell_MKT_close_defaultImage;
    public static sell_MKT_close_hoverImage;
    public static sell_MKT_open_defaultImage;
    public static sell_MKT_open_hoverImage;
    // Trs_buy
    public static buy_Trs_close_defaultImage;
    public static buy_Trs_close_hoverImage;
    public static buy_Trs_open_defaultImage;
    public static buy_Trs_open_hoverImage;
    public static buy_Trs_link_close_defaultImage;
    public static buy_Trs_link_close_hoverImage;
    public static buy_Trs_link_open_defaultImage;
    public static buy_Trs_link_open_hoverImage;
    // Trs_sell
    public static sell_Trs_close_defaultImage;
    public static sell_Trs_close_hoverImage;
    public static sell_Trs_open_defaultImage;
    public static sell_Trs_open_hoverImage;
    public static sell_Trs_link_close_defaultImage;
    public static sell_Trs_link_close_hoverImage;
    public static sell_Trs_link_open_defaultImage;
    public static sell_Trs_link_open_hoverImage;
    // MoveOrder
    public static move_LMT_closeImage;
    public static move_LMT_openImage;
    public static move_STP_closeImage;
    public static move_STP_openImage;
    public static move_MKT_closeImage;
    public static move_MKT_openImage;
    public static move_Trs_closeImage;
    public static move_Trs_openImage;
    // MoveOrderLink
    public static move_LMT_link_closeImage;
    public static move_LMT_link_openImage;
    public static move_STP_link_closeImage;
    public static move_STP_link_openImage;
    // MoveAlert
    public static gray_alertImage;
    public static gray_alert_editImage;
    public static gray_alert_closeImage;
    // HighAlert
    public static red_alert_defaultImage;
    public static red_alert_hoverImage;
    public static red_alert_edit_defaultImage;
    public static red_alert_edit_hoverImage;
    public static red_alert_close_defaultImage;
    public static red_alert_close_hoverImage;
    // MediumAlert
    public static yellow_alert_defaultImage;
    public static yellow_alert_hoverImage;
    public static yellow_alert_edit_defaultImage;
    public static yellow_alert_edit_hoverImage;
    public static yellow_alert_close_defaultImage;
    public static yellow_alert_close_hoverImage;
    // LowAlert
    public static green_alert_defaultImage;
    public static green_alert_hoverImage;
    public static green_alert_edit_defaultImage;
    public static green_alert_edit_hoverImage;
    public static green_alert_close_defaultImage;
    public static green_alert_close_hoverImage;
    // ThreeDots ContextMenu ChangeToMarket #101864
    public static threeDots_buy_defaultImage;
    public static threeDots_buy_hoverImage;
    public static threeDots_buy_activeImage;
    public static threeDots_sell_activeImage;
    public static threeDots_sell_defaultImage;
    public static threeDots_sell_hoverImage;
    public static threeDots_gray_Image;
    // Кнопки закрытия ордеров без обрезанного левого края (при наличии меню ThreeDots)
    public static buy_cancel_defaultImage_full;
    public static buy_cancel_hoverImage_full;
    public static sell_cancel_defaultImage_full;
    public static sell_cancel_hoverImage_full;
    public static buy_cancel_mine_hoverImage_full;
    public static sell_cancel_mine_hoverImage_full;
    public static moveOrder_cancelImage_full;

    public static profitBackgroundPen: Pen;
    public static profitBackgroundBrush: SolidBrush;

    public static lossBackgroundPen: Pen;
    public static lossBackgroundBrush: SolidBrush;

    public static buyBackgroundActivePen: Pen;
    public static buyBackgroundActiveBrush: SolidBrush;

    public static sellBackgroundActivePen: Pen;
    public static sellBackgroundActiveBrush: SolidBrush;

    public static grayBackgroundPen: Pen;
    public static grayBackgroundBrush: SolidBrush;

    public static buyBackgroundPen: Pen;
    public static buyBackgroundBrush: SolidBrush;

    public static sellBackgroundPen: Pen;
    public static sellBackgroundBrush: SolidBrush;

    // Alert
    public static redAlertBackgroundPen: Pen;
    public static yellowAlertBackgroundPen: Pen;
    public static greenAlertBackgroundPen: Pen;

    public static redAlertBackgroundBrush: SolidBrush;
    public static yellowAlertBackgroundBrush: SolidBrush;
    public static greenAlertBackgroundBrush: SolidBrush;

    public static linkedStyleHoverBrush: SolidBrush;
    public static linkedStyleDefaultBrush: SolidBrush;

    // СтопЛимит за границами
    public static link_out_LMT_default_full;
    public static link_out_LMT_hover_clip;
    public static link_out_LMT_default_clip;
    public static link_out_LMT_hover_full;
    public static link_out_STP_default_clip;
    public static link_out_STP_default_full;
    public static link_out_STP_hover_clip;
    public static link_out_STP_hover_full;

    public static cancel_Out_hover;
    public static cancel_Out_default;
    public static threeDotsOutHover;
    public static threeDotsOutDefault;
    // #endregion

    // static TradingToolViewBase()
    // {
    //    ThemeChanged(true);
    //    App.ThemeManager.RegisterThemeObject(new ThemeObjectHandler(ThemeChanged));
    // }

    public CreateSelection (): OrderToolSelection {
        return new OrderToolSelection();
    }

    public static ThemeChanged (): void {
        TradingToolViewBase.tradeFont = ThemeManager.Fonts.Font_14F_regular;
        TradingToolViewBase.orderGrayPen = new Pen('gray');
        TradingToolViewBase.grayBrush = new SolidBrush('gray');
        TradingToolViewBase.buyBrush = new SolidBrush(ThemeManager.CurrentTheme.BuyColor);
        TradingToolViewBase.sellBrush = new SolidBrush(ThemeManager.CurrentTheme.SellColor);

        // sltp
        TradingToolViewBase.slBuyImageActive = ThemeManager.CurrentTheme.slBuyImage;
        TradingToolViewBase.slSellImageActive = ThemeManager.CurrentTheme.slSellImage;
        TradingToolViewBase.tpBuyImageActive = ThemeManager.CurrentTheme.tpBuyImage;
        TradingToolViewBase.tpSellImageActive = ThemeManager.CurrentTheme.tpSellImage;
        TradingToolViewBase.slGrayImageActive = ThemeManager.CurrentTheme.slGrayImageActive;
        TradingToolViewBase.tpGrayImageActive = ThemeManager.CurrentTheme.tpGrayImageActive;

        TradingToolViewBase.slGrayImage = ThemeManager.CurrentTheme.slGrayImage;
        TradingToolViewBase.tpGrayImage = ThemeManager.CurrentTheme.tpGrayImage;

        // trades
        TradingToolViewBase.buyTradeImageActive = ThemeManager.CurrentTheme.buyTradeImage;
        TradingToolViewBase.sellTradeImageActive = ThemeManager.CurrentTheme.sellTradeImage;

        TradingToolViewBase.buyTradeImage = ThemeManager.CurrentTheme.buyTradeImageTransparent;
        TradingToolViewBase.sellTradeImage = ThemeManager.CurrentTheme.sellTradeImageTransparent;

        // positions
        TradingToolViewBase.sell_open_position_defaultImage = ThemeManager.CurrentTheme.sell_open_position_default;
        TradingToolViewBase.sell_open_position_hoverImage = ThemeManager.CurrentTheme.sell_open_position_hover;
        TradingToolViewBase.sell_close_position_defaultImage = ThemeManager.CurrentTheme.sell_close_position_default;
        TradingToolViewBase.sell_close_position_hoverImage = ThemeManager.CurrentTheme.sell_close_position_hover;

        TradingToolViewBase.buy_open_position_defaultImage = ThemeManager.CurrentTheme.buy_open_position_default;
        TradingToolViewBase.buy_open_position_hoverImage = ThemeManager.CurrentTheme.buy_open_position_hover;
        TradingToolViewBase.buy_close_position_defaultImage = ThemeManager.CurrentTheme.buy_close_position_default;
        TradingToolViewBase.buy_close_position_hoverImage = ThemeManager.CurrentTheme.buy_close_position_hover;

        TradingToolViewBase.gray_open_positionImage = ThemeManager.CurrentTheme.gray_open_position;
        TradingToolViewBase.gray_close_positionImage = ThemeManager.CurrentTheme.gray_close_position;

        // orders
        TradingToolViewBase.buyOrderImageActive = ThemeManager.CurrentTheme.buyOrderImage;
        TradingToolViewBase.sellOrderImageActive = ThemeManager.CurrentTheme.sellOrderImage;

        TradingToolViewBase.buyOrderImage = ThemeManager.CurrentTheme.buyOrderImage;
        TradingToolViewBase.sellOrderImage = ThemeManager.CurrentTheme.sellOrderImage;

        // background
        TradingToolViewBase.profitBackgroundPen = ThemeManager.CurrentTheme.profitBackgroundPen;
        TradingToolViewBase.profitBackgroundBrush = ThemeManager.CurrentTheme.profitBackgroundBrush;

        TradingToolViewBase.lossBackgroundPen = ThemeManager.CurrentTheme.lossBackgroundPen;
        TradingToolViewBase.lossBackgroundBrush = ThemeManager.CurrentTheme.lossBackgroundBrush;

        TradingToolViewBase.buyBackgroundActivePen = ThemeManager.CurrentTheme.buyBackgroundActivePen;
        TradingToolViewBase.buyBackgroundActiveBrush = ThemeManager.CurrentTheme.buyBackgroundActiveBrush;

        TradingToolViewBase.sellBackgroundActivePen = ThemeManager.CurrentTheme.sellBackgroundActivePen;
        TradingToolViewBase.sellBackgroundActiveBrush = ThemeManager.CurrentTheme.sellBackgroundActiveBrush;

        TradingToolViewBase.grayBackgroundPen = ThemeManager.CurrentTheme.grayBackgroundPen;
        TradingToolViewBase.grayBackgroundBrush = ThemeManager.CurrentTheme.grayBackgroundBrush;

        TradingToolViewBase.buyBackgroundPen = ThemeManager.CurrentTheme.buyBackgroundPen;
        TradingToolViewBase.buyBackgroundBrush = ThemeManager.CurrentTheme.buyBackgroundBrush;

        TradingToolViewBase.sellBackgroundPen = ThemeManager.CurrentTheme.sellBackgroundPen;
        TradingToolViewBase.sellBackgroundBrush = ThemeManager.CurrentTheme.sellBackgroundBrush;

        // Alert
        TradingToolViewBase.redAlertBackgroundPen = ThemeManager.CurrentTheme.redAlertBackgroundPen;
        TradingToolViewBase.yellowAlertBackgroundPen = ThemeManager.CurrentTheme.yellowAlertBackgroundPen;
        TradingToolViewBase.greenAlertBackgroundPen = ThemeManager.CurrentTheme.greenAlertBackgroundPen;

        TradingToolViewBase.redAlertBackgroundBrush = ThemeManager.CurrentTheme.redAlertBackgroundBrush;
        TradingToolViewBase.yellowAlertBackgroundBrush = ThemeManager.CurrentTheme.yellowAlertBackgroundBrush;
        TradingToolViewBase.greenAlertBackgroundBrush = ThemeManager.CurrentTheme.greenAlertBackgroundBrush;

        TradingToolViewBase.linkedStyleHoverBrush = ThemeManager.CurrentTheme.ChartVisualTrading_linkingBackgroundHoverBrush;
        TradingToolViewBase.linkedStyleDefaultBrush = ThemeManager.CurrentTheme.ChartVisualTrading_linkingBackgroundDefaultBrush;

        // buyCancel
        TradingToolViewBase.buy_cancel_defaultImage = ThemeManager.CurrentTheme.buy_cancel_default;
        TradingToolViewBase.buy_cancel_hoverImage = ThemeManager.CurrentTheme.buy_cancel_hover;
        // sellCancel
        TradingToolViewBase.sell_cancel_defaultImage = ThemeManager.CurrentTheme.sell_cancel_default;
        TradingToolViewBase.sell_cancel_hoverImage = ThemeManager.CurrentTheme.sell_cancel_hover;
        // mineCancelHover
        TradingToolViewBase.buy_cancel_mine_hoverImage = ThemeManager.CurrentTheme.buy_cancel_mine_hover;
        TradingToolViewBase.sell_cancel_mine_hoverImage = ThemeManager.CurrentTheme.sell_cancel_mine_hover;
        // moveOrderCancel
        TradingToolViewBase.moveOrder_cancelImage = ThemeManager.CurrentTheme.moveOrder_cancel;

        // orderSL
        TradingToolViewBase.duy_SL_close_defaultImage = ThemeManager.CurrentTheme.duy_SL_close_default;
        TradingToolViewBase.duy_SL_close_hoverImage = ThemeManager.CurrentTheme.duy_SL_close_hover;
        TradingToolViewBase.duy_SL_open_defaultImage = ThemeManager.CurrentTheme.duy_SL_open_default;
        TradingToolViewBase.duy_SL_open_hoverImage = ThemeManager.CurrentTheme.duy_SL_open_hover;
        TradingToolViewBase.sell_SL_close_defaultImage = ThemeManager.CurrentTheme.sell_SL_close_default;
        TradingToolViewBase.sell_SL_close_hoverImage = ThemeManager.CurrentTheme.sell_SL_close_hover;
        TradingToolViewBase.sell_SL_open_defaultImage = ThemeManager.CurrentTheme.sell_SL_open_default;
        TradingToolViewBase.sell_SL_open_hoverImage = ThemeManager.CurrentTheme.sell_SL_open_hover;
        // SLL (stop lose limit)
        TradingToolViewBase.buy_SLL_close_defaultImage = ThemeManager.CurrentTheme.buy_SLL_close_default;
        TradingToolViewBase.buy_SLL_close_hoverImage = ThemeManager.CurrentTheme.buy_SLL_close_hover;
        TradingToolViewBase.buy_SLL_open_defaultImage = ThemeManager.CurrentTheme.buy_SLL_open_default;
        TradingToolViewBase.buy_SLL_open_hoverImage = ThemeManager.CurrentTheme.buy_SLL_open_hover;
        TradingToolViewBase.sell_SLL_close_defaultImage = ThemeManager.CurrentTheme.sell_SLL_close_default;
        TradingToolViewBase.sell_SLL_close_hoverImage = ThemeManager.CurrentTheme.sell_SLL_close_hover;
        TradingToolViewBase.sell_SLL_open_defaultImage = ThemeManager.CurrentTheme.sell_SLL_open_default;
        TradingToolViewBase.sell_SLL_open_hoverImage = ThemeManager.CurrentTheme.sell_SLL_open_hover;
        // move SLL (stop lose limit)
        TradingToolViewBase.close_SLL_gray_defaultImage = ThemeManager.CurrentTheme.close_SLL_gray_default;
        TradingToolViewBase.open_SLL_gray_defaultImage = ThemeManager.CurrentTheme.open_SLL_gray_default;
        TradingToolViewBase.close_SLL_gray_hoverImage = ThemeManager.CurrentTheme.close_SLL_gray_hover;
        TradingToolViewBase.open_SLL_gray_hoverImage = ThemeManager.CurrentTheme.open_SLL_gray_hover;
        // orderTP
        TradingToolViewBase.buy_TP_close_defaultImage = ThemeManager.CurrentTheme.buy_TP_close_default;
        TradingToolViewBase.buy_TP_close_hoverImage = ThemeManager.CurrentTheme.buy_TP_close_hover;
        TradingToolViewBase.buy_TP_open_defaultImage = ThemeManager.CurrentTheme.buy_TP_open_default;
        TradingToolViewBase.buy_TP_open_hoverImage = ThemeManager.CurrentTheme.buy_TP_open_hover;
        TradingToolViewBase.sell_TP_close_defaultImage = ThemeManager.CurrentTheme.sell_TP_close_default;
        TradingToolViewBase.sell_TP_close_hoverImage = ThemeManager.CurrentTheme.sell_TP_close_hover;
        TradingToolViewBase.sell_TP_open_defaultImage = ThemeManager.CurrentTheme.sell_TP_open_default;
        TradingToolViewBase.sell_TP_open_hoverImage = ThemeManager.CurrentTheme.sell_TP_open_hover;
        // TSL
        TradingToolViewBase.buy_TSL_defaultImage = ThemeManager.CurrentTheme.buy_TSL_default;
        TradingToolViewBase.buy_TSL_hoverImage = ThemeManager.CurrentTheme.buy_TSL_hover;
        TradingToolViewBase.buy_TSL_mine_hoverImage = ThemeManager.CurrentTheme.buy_TSL_mine_hover;
        TradingToolViewBase.sell_TSL_defaultImage = ThemeManager.CurrentTheme.sell_TSL_default;
        TradingToolViewBase.sell_TSL_hoverImage = ThemeManager.CurrentTheme.sell_TSL_hover;
        TradingToolViewBase.sell_TSL_mine_hoverImage = ThemeManager.CurrentTheme.sell_TSL_mine_hover;
        TradingToolViewBase.TSL_grayImage = ThemeManager.CurrentTheme.TSL_gray;
        TradingToolViewBase.TSL_gray_hoverImage = ThemeManager.CurrentTheme.TSL_gray_hover;
        TradingToolViewBase.TSL_gray_mine_hoverImage = ThemeManager.CurrentTheme.TSL_gray_mine_hover;
        // MoveTPSL
        TradingToolViewBase.close_SL_grayImage = ThemeManager.CurrentTheme.close_SL_gray;
        TradingToolViewBase.open_SL_grayImage = ThemeManager.CurrentTheme.open_SL_gray;
        TradingToolViewBase.close_TP_grayImage = ThemeManager.CurrentTheme.close_TP_gray;
        TradingToolViewBase.open_TP_grayImage = ThemeManager.CurrentTheme.open_TP_gray;
        // LMT-buy
        TradingToolViewBase.buy_LMT_close_defaultImage = ThemeManager.CurrentTheme.buy_LMT_close_default;
        TradingToolViewBase.buy_LMT_close_hoverImage = ThemeManager.CurrentTheme.buy_LMT_close_hover;
        TradingToolViewBase.buy_LMT_open_defaultImage = ThemeManager.CurrentTheme.buy_LMT_open_default;
        TradingToolViewBase.buy_LMT_open_hoverImage = ThemeManager.CurrentTheme.buy_LMT_open_hover;
        TradingToolViewBase.buy_LMT_link_close_defaultImage = ThemeManager.CurrentTheme.buy_LMT_link_close_default;
        TradingToolViewBase.buy_LMT_link_close_hoverImage = ThemeManager.CurrentTheme.buy_LMT_link_close_hover;
        TradingToolViewBase.buy_LMT_link_open_defaultImage = ThemeManager.CurrentTheme.buy_LMT_link_open_default;
        TradingToolViewBase.buy_LMT_link_open_hoverImage = ThemeManager.CurrentTheme.buy_LMT_link_open_hover;
        // LMTsell
        TradingToolViewBase.sell_LMT_close_defaultImage = ThemeManager.CurrentTheme.sell_LMT_close_default;
        TradingToolViewBase.sell_LMT_close_hoverImage = ThemeManager.CurrentTheme.sell_LMT_close_hover;
        TradingToolViewBase.sell_LMT_open_defaultImage = ThemeManager.CurrentTheme.sell_LMT_open_default;
        TradingToolViewBase.sell_LMT_open_hoverImage = ThemeManager.CurrentTheme.sell_LMT_open_hover;
        TradingToolViewBase.sell_LMT_link_close_defaultImage = ThemeManager.CurrentTheme.sell_LMT_link_close_default;
        TradingToolViewBase.sell_LMT_link_close_hoverImage = ThemeManager.CurrentTheme.sell_LMT_link_close_hover;
        TradingToolViewBase.sell_LMT_link_open_defaultImage = ThemeManager.CurrentTheme.sell_LMT_link_open_default;
        TradingToolViewBase.sell_LMT_link_open_hoverImage = ThemeManager.CurrentTheme.sell_LMT_link_open_hover;
        // STP-buy
        TradingToolViewBase.buy_STP_close_defaultImage = ThemeManager.CurrentTheme.buy_STP_close_default;
        TradingToolViewBase.buy_STP_close_hoverImage = ThemeManager.CurrentTheme.buy_STP_close_hover;
        TradingToolViewBase.buy_STP_open_defaultImage = ThemeManager.CurrentTheme.buy_STP_open_default;
        TradingToolViewBase.buy_STP_open_hoverImage = ThemeManager.CurrentTheme.buy_STP_open_hover;
        TradingToolViewBase.buy_STP_link_close_defaultImage = ThemeManager.CurrentTheme.buy_STP_link_close_default;
        TradingToolViewBase.buy_STP_link_close_hoverImage = ThemeManager.CurrentTheme.buy_STP_link_close_hover;
        TradingToolViewBase.buy_STP_link_open_defaultImage = ThemeManager.CurrentTheme.buy_STP_link_open_default;
        TradingToolViewBase.buy_STP_link_open_hoverImage = ThemeManager.CurrentTheme.buy_STP_link_open_hover;
        // STPsell
        TradingToolViewBase.sell_STP_close_defaultImage = ThemeManager.CurrentTheme.sell_STP_close_default;
        TradingToolViewBase.sell_STP_close_hoverImage = ThemeManager.CurrentTheme.sell_STP_close_hover;
        TradingToolViewBase.sell_STP_open_defaultImage = ThemeManager.CurrentTheme.sell_STP_open_default;
        TradingToolViewBase.sell_STP_open_hoverImage = ThemeManager.CurrentTheme.sell_STP_open_hover;
        TradingToolViewBase.sell_STP_link_close_defaultImage = ThemeManager.CurrentTheme.sell_STP_link_close_default;
        TradingToolViewBase.sell_STP_link_close_hoverImage = ThemeManager.CurrentTheme.sell_STP_link_close_hover;
        TradingToolViewBase.sell_STP_link_open_defaultImage = ThemeManager.CurrentTheme.sell_STP_link_open_default;
        TradingToolViewBase.sell_STP_link_open_hoverImage = ThemeManager.CurrentTheme.sell_STP_link_open_hover;
        // MKT-buy
        TradingToolViewBase.buy_MKT_close_defaultImage = ThemeManager.CurrentTheme.buy_MKT_close_default;
        TradingToolViewBase.buy_MKT_close_hoverImage = ThemeManager.CurrentTheme.buy_MKT_close_hover;
        TradingToolViewBase.buy_MKT_open_defaultImage = ThemeManager.CurrentTheme.buy_MKT_open_default;
        TradingToolViewBase.buy_MKT_open_hoverImage = ThemeManager.CurrentTheme.buy_MKT_open_hover;
        // MKTsell
        TradingToolViewBase.sell_MKT_close_defaultImage = ThemeManager.CurrentTheme.sell_MKT_close_default;
        TradingToolViewBase.sell_MKT_close_hoverImage = ThemeManager.CurrentTheme.sell_MKT_close_hover;
        TradingToolViewBase.sell_MKT_open_defaultImage = ThemeManager.CurrentTheme.sell_MKT_open_default;
        TradingToolViewBase.sell_MKT_open_hoverImage = ThemeManager.CurrentTheme.sell_MKT_open_hover;
        // Trs_buy
        TradingToolViewBase.buy_Trs_close_defaultImage = ThemeManager.CurrentTheme.buy_Trs_close_default;
        TradingToolViewBase.buy_Trs_close_hoverImage = ThemeManager.CurrentTheme.buy_Trs_close_hover;
        TradingToolViewBase.buy_Trs_open_defaultImage = ThemeManager.CurrentTheme.buy_Trs_open_default;
        TradingToolViewBase.buy_Trs_open_hoverImage = ThemeManager.CurrentTheme.buy_Trs_open_hover;
        TradingToolViewBase.buy_Trs_link_close_defaultImage = ThemeManager.CurrentTheme.buy_Trs_link_close_default;
        TradingToolViewBase.buy_Trs_link_close_hoverImage = ThemeManager.CurrentTheme.buy_Trs_link_close_hover;
        TradingToolViewBase.buy_Trs_link_open_defaultImage = ThemeManager.CurrentTheme.buy_Trs_link_open_default;
        TradingToolViewBase.buy_Trs_link_open_hoverImage = ThemeManager.CurrentTheme.buy_Trs_link_open_hover;
        // Trs_sell
        TradingToolViewBase.sell_Trs_close_defaultImage = ThemeManager.CurrentTheme.sell_Trs_close_default;
        TradingToolViewBase.sell_Trs_close_hoverImage = ThemeManager.CurrentTheme.sell_Trs_close_hover;
        TradingToolViewBase.sell_Trs_open_defaultImage = ThemeManager.CurrentTheme.sell_Trs_open_default;
        TradingToolViewBase.sell_Trs_open_hoverImage = ThemeManager.CurrentTheme.sell_Trs_open_hover;
        TradingToolViewBase.sell_Trs_link_close_defaultImage = ThemeManager.CurrentTheme.sell_Trs_link_close_default;
        TradingToolViewBase.sell_Trs_link_close_hoverImage = ThemeManager.CurrentTheme.sell_Trs_link_close_hover;
        TradingToolViewBase.sell_Trs_link_open_defaultImage = ThemeManager.CurrentTheme.sell_Trs_link_open_default;
        TradingToolViewBase.sell_Trs_link_open_hoverImage = ThemeManager.CurrentTheme.sell_Trs_link_open_hover;
        // MoveOrder
        TradingToolViewBase.move_LMT_closeImage = ThemeManager.CurrentTheme.move_LMT_close;
        TradingToolViewBase.move_LMT_openImage = ThemeManager.CurrentTheme.move_LMT_open;
        TradingToolViewBase.move_STP_closeImage = ThemeManager.CurrentTheme.move_STP_close;
        TradingToolViewBase.move_STP_openImage = ThemeManager.CurrentTheme.move_STP_open;
        TradingToolViewBase.move_MKT_closeImage = ThemeManager.CurrentTheme.move_MKT_close;
        TradingToolViewBase.move_MKT_openImage = ThemeManager.CurrentTheme.move_MKT_open;
        TradingToolViewBase.move_Trs_closeImage = ThemeManager.CurrentTheme.move_Trs_close;
        TradingToolViewBase.move_Trs_openImage = ThemeManager.CurrentTheme.move_Trs_open;
        // MoveOrderLink
        TradingToolViewBase.move_LMT_link_closeImage = ThemeManager.CurrentTheme.move_LMT_link_close;
        TradingToolViewBase.move_LMT_link_openImage = ThemeManager.CurrentTheme.move_LMT_link_open;
        TradingToolViewBase.move_STP_link_closeImage = ThemeManager.CurrentTheme.move_STP_link_close;
        TradingToolViewBase.move_STP_link_openImage = ThemeManager.CurrentTheme.move_STP_link_open;
        // MoveAlert
        TradingToolViewBase.gray_alertImage = ThemeManager.CurrentTheme.gray_alert;
        TradingToolViewBase.gray_alert_editImage = ThemeManager.CurrentTheme.gray_alert_edit;
        TradingToolViewBase.gray_alert_closeImage = ThemeManager.CurrentTheme.gray_alert_close;
        // HighAlert
        TradingToolViewBase.red_alert_defaultImage = ThemeManager.CurrentTheme.red_alert_default;
        TradingToolViewBase.red_alert_hoverImage = ThemeManager.CurrentTheme.red_alert_hover;
        TradingToolViewBase.red_alert_edit_defaultImage = ThemeManager.CurrentTheme.red_alert_edit_default;
        TradingToolViewBase.red_alert_edit_hoverImage = ThemeManager.CurrentTheme.red_alert_edit_hover;
        TradingToolViewBase.red_alert_close_defaultImage = ThemeManager.CurrentTheme.red_alert_close_default;
        TradingToolViewBase.red_alert_close_hoverImage = ThemeManager.CurrentTheme.red_alert_close_hover;
        // MediumAlert
        TradingToolViewBase.yellow_alert_defaultImage = ThemeManager.CurrentTheme.yellow_alert_default;
        TradingToolViewBase.yellow_alert_hoverImage = ThemeManager.CurrentTheme.yellow_alert_hover;
        TradingToolViewBase.yellow_alert_edit_defaultImage = ThemeManager.CurrentTheme.yellow_alert_edit_default;
        TradingToolViewBase.yellow_alert_edit_hoverImage = ThemeManager.CurrentTheme.yellow_alert_edit_hover;
        TradingToolViewBase.yellow_alert_close_defaultImage = ThemeManager.CurrentTheme.yellow_alert_close_default;
        TradingToolViewBase.yellow_alert_close_hoverImage = ThemeManager.CurrentTheme.yellow_alert_close_hover;
        // LowAlert
        TradingToolViewBase.green_alert_defaultImage = ThemeManager.CurrentTheme.green_alert_default;
        TradingToolViewBase.green_alert_hoverImage = ThemeManager.CurrentTheme.green_alert_hover;
        TradingToolViewBase.green_alert_edit_defaultImage = ThemeManager.CurrentTheme.green_alert_edit_default;
        TradingToolViewBase.green_alert_edit_hoverImage = ThemeManager.CurrentTheme.green_alert_edit_hover;
        TradingToolViewBase.green_alert_close_defaultImage = ThemeManager.CurrentTheme.green_alert_close_default;
        TradingToolViewBase.green_alert_close_hoverImage = ThemeManager.CurrentTheme.green_alert_close_hover;
        // ThreeDots ContextMenu ChangeToMarket #101864
        TradingToolViewBase.threeDots_buy_defaultImage = ThemeManager.CurrentTheme.threeDotsBuyDef;
        TradingToolViewBase.threeDots_buy_hoverImage = ThemeManager.CurrentTheme.threeDotsBuyHover;
        TradingToolViewBase.threeDots_buy_activeImage = ThemeManager.CurrentTheme.threeDotsBuyActive;
        TradingToolViewBase.threeDots_sell_activeImage = ThemeManager.CurrentTheme.threeDotsSellActive;
        TradingToolViewBase.threeDots_sell_defaultImage = ThemeManager.CurrentTheme.threeDotsSellDef;
        TradingToolViewBase.threeDots_sell_hoverImage = ThemeManager.CurrentTheme.threeDotsSellHover;
        TradingToolViewBase.threeDots_gray_Image = ThemeManager.CurrentTheme.threeDotsGray;
        // Кнопки закрытия ордеров без обрезанного левого края (при наличии меню ThreeDots)
        TradingToolViewBase.buy_cancel_defaultImage_full = ThemeManager.CurrentTheme.buy_cancel_default_full;
        TradingToolViewBase.buy_cancel_hoverImage_full = ThemeManager.CurrentTheme.buy_cancel_hover_full;
        TradingToolViewBase.sell_cancel_defaultImage_full = ThemeManager.CurrentTheme.sell_cancel_default_full;
        TradingToolViewBase.sell_cancel_hoverImage_full = ThemeManager.CurrentTheme.sell_cancel_hover_full;
        TradingToolViewBase.buy_cancel_mine_hoverImage_full = ThemeManager.CurrentTheme.buy_cancel_mine_hover_full;
        TradingToolViewBase.sell_cancel_mine_hoverImage_full = ThemeManager.CurrentTheme.sell_cancel_mine_hover_full;
        TradingToolViewBase.moveOrder_cancelImage_full = ThemeManager.CurrentTheme.moveOrder_cancel_full;

        // СтопЛимит за границами
        TradingToolViewBase.link_out_LMT_default_full = ThemeManager.CurrentTheme.link_out_LMT_default_full;
        TradingToolViewBase.link_out_LMT_hover_clip = ThemeManager.CurrentTheme.link_out_LMT_hover_clip;
        TradingToolViewBase.link_out_LMT_default_clip = ThemeManager.CurrentTheme.link_out_LMT_default_clip;
        TradingToolViewBase.link_out_LMT_hover_full = ThemeManager.CurrentTheme.link_out_LMT_hover_full;
        TradingToolViewBase.link_out_STP_default_clip = ThemeManager.CurrentTheme.link_out_STP_default_clip;
        TradingToolViewBase.link_out_STP_default_full = ThemeManager.CurrentTheme.link_out_STP_default_full;
        TradingToolViewBase.link_out_STP_hover_clip = ThemeManager.CurrentTheme.link_out_STP_hover_clip;
        TradingToolViewBase.link_out_STP_hover_full = ThemeManager.CurrentTheme.link_out_STP_hover_full;

        TradingToolViewBase.cancel_Out_hover = ThemeManager.CurrentTheme.cancel_Out_hover;
        TradingToolViewBase.cancel_Out_default = ThemeManager.CurrentTheme.cancel_Out_default;
        TradingToolViewBase.threeDotsOutHover = ThemeManager.CurrentTheme.threeDotsOutHover;
        TradingToolViewBase.threeDotsOutDefault = ThemeManager.CurrentTheme.threeDotsOutDefault;
    }

    // #region service

    /// <summary>
    /// какая точка тулзы выделена (у нас всегда 1 точка так что, а когда двигаем сл - )
    /// </summary>
    public override FindSelectedPoint (x: number, y: number): 0 | -1 {
        if (this.IsSelectCheck(x, y)) {
            return 0;
        }

        return -1;
    }

    /// <summary>
    /// For futures sl/tp in currenccy
    /// </summary>
    public static FuturesSlTp (order: Order | Position, offset): number {
        let result = 0;
        const ins = order.Instrument;
        const account = order.Account;
        const Qty = order.Amount;
        const isBuy = order.BuySell === OperationType.Buy;

        const CurPriceClose = ins.RoundPriceToNearestPointSize(order.Price + offset);
        const OpenPrice = order.Price;

        const crossPrice = ins.DataCache.CrossRateCache.GetCrossPriceExp1Exp2(ins.Exp2, account.assetBalanceDefault.Asset.Name);
        const openCross = order.isPosition ? (order as Position).OpenCrossPrice : crossPrice;

        result = ProfitCalculator.CalculateSLTP(Qty, CurPriceClose, OpenPrice, ins, account, isBuy, openCross, order.ProductType);

        return result;
    }
    // #endregion

    // #region mouse

    public MinDistanceForStartMoving (): number {
        return 5;
    }

    /// <summary>
    /// тулза выделяется (активируется). будет ли перемещение потом - неизвестно
    /// </summary>
    public override ProcessActivate (x: number, y: number): boolean {
        // мауздаун пришелся по тулзе?
        if (super.ProcessActivate(x, y)) {
            this.CurrentSelection.MouseDownHover = this.GetCurrentSelection(x, y);
            this.CurrentMovement.PrevY = y;
            return true;
        }
        // нет
        return false;
    }

    public override OnMouseEnter (e): void {
        const newType = this.GetCurrentSelection(e.Location.X, e.Location.Y);
        if (this.CurrentSelection.CurrentHover !== newType) {
            this.CurrentSelection.CurrentHover = newType;
            e.NeedRedraw = LayersEnum.Tools;
        }
    }

    public override OnMouseMove (e): void {
        const newType = this.GetCurrentSelection(e.Location.X, e.Location.Y);
        if (this.CurrentSelection.CurrentHover !== newType) {
            this.CurrentSelection.CurrentHover = newType;
            e.NeedRedraw = LayersEnum.Tools;
        }
    }

    public override OnMouseLeave (e): void {
        if (this.CurrentSelection.CurrentHover !== ToolSelectionType.None) {
            this.CurrentSelection.CurrentHover = ToolSelectionType.None;
            e.NeedRedraw = LayersEnum.Tools;
        }
        // this.ResetMouse();
    }

    public GetCursor (e): Cursors {
        return null;
    }

    public GetTooltip (e): string | null {
        switch (this.CurrentSelection.CurrentHover) {
        case ToolSelectionType.Action:
            return Resources.getResource('TradeTool.View more actions');
        case ToolSelectionType.Trailing:
            return Resources.getResource('TradeTool.Trailing Stop');
        case ToolSelectionType.SL:
            return Resources.getResource('TradeTool.Drag to set SL order');
        case ToolSelectionType.TP:
            return Resources.getResource('TradeTool.Drag to set TP order');
        case ToolSelectionType.Line:
        case ToolSelectionType.None:
        default:
            return null;
        }
    }

    // #endregion

    public override Dispose (): void {
        this.myRenderer = null;
    }
}

export enum TradingToolViewType {
    TradingToolViewBase = 0,
    OrderToolView = 1,
    PositionToolView = 2,
    SLTPOrderToolView = 3,
    NewTradeToolView = 4,
    StopLimitOrderToolView = 5,
    AlertToolView = 6,
    StopLossLimitToolView = 7,
    NewPlacingOrderToolView = 8,
    ICON_RECT_WIDTH = 55,
    ICON_RECT_HEIGHT = 23,
    GRIP_RECT_WIDTH = 11,
    GRIP_RECT_HEIGHT = 24
}

class OrderToolSelection extends Selection {
    public MouseDownHover = ToolSelectionType.None;
    public CurrentHover = ToolSelectionType.None;
}
