import { PriceFormatter } from '../Utils/Instruments/PriceFormatter';
import { TerceraChartNumberHorizontalScaleRenderer } from './Renderers/Scales/TerceraChartNumberHorizontalScaleRenderer';
import { TerceraChartNumberScaleRendererSettings } from './Renderers/Scales/TerceraChartNumberScaleRenderer';
import { TerceraChartNumberVerticalScaleRenderer } from './Renderers/Scales/TerceraChartNumberVerticalScaleRenderer';
import { TerceraChartStyledLineRenderer } from './Renderers/TerceraChartStyledLineRenderer';
import { TerceraChartXYScrollerRenderer } from './Renderers/TerceraChartXYScrollerRenderer';
import { TerceraChartZoomRenderer } from './Renderers/TerceraChartZoomRenderer';
import { RendererDocking } from './Renderers/Utils/RendererDocking';
import { TerceraChartAggregatedXYSeries, XYSeriesData } from './Series/TerceraChartXYSeries';
import { TerceraChartBaseActionProcessor } from './TerceraChartActionProcessor/TerceraChartBaseActionProcessor';
import { TerceraChartBase } from './TerceraChartBase';
import { TerceraChartXYWindow } from './Windows/TerceraChartXYWindow';
import { TerceraChartWindowsContainer } from './TerceraChartWindowsContainer';

export class TerceraXYChart extends TerceraChartBase<TerceraChartXYWindow> {
    private _series: TerceraChartAggregatedXYSeries = new TerceraChartAggregatedXYSeries();
    private readonly _lineRenderers: TerceraChartStyledLineRenderer[] = [];

    constructor (ctx: CanvasRenderingContext2D, terceraChartPanelContext) {
        super(ctx, terceraChartPanelContext);
        this.TerceraChartActionProcessor = new TerceraChartBaseActionProcessor(this);
        this.xScaleRendererSettings = new TerceraChartNumberScaleRendererSettings();
        this.yScaleRendererSettings = new TerceraChartNumberScaleRendererSettings();
    }

    public override CreateWindowsContainer (): TerceraChartWindowsContainer {
        const scrollerRenderer = new TerceraChartXYScrollerRenderer(this);
        const xScaleRenderer = new TerceraChartNumberHorizontalScaleRenderer(this.xScaleRendererSettings, this);
        const zoomRenderer = new TerceraChartZoomRenderer(this, this.TerceraChartActionProcessor);
        return new TerceraChartWindowsContainer(scrollerRenderer, xScaleRenderer, zoomRenderer);
    }

    public override CreateMainWindow (): TerceraChartXYWindow {
        const leftYScaleRenderer = new TerceraChartNumberVerticalScaleRenderer(this.yScaleRendererSettings, this);
        leftYScaleRenderer.Docking = RendererDocking.Left;
        const rightYScaleRenderer = new TerceraChartNumberVerticalScaleRenderer(this.yScaleRendererSettings, this);
        rightYScaleRenderer.Docking = RendererDocking.Right;
        return new TerceraChartXYWindow(rightYScaleRenderer, leftYScaleRenderer);
    }

    public override InitializeRenderers (): void {

    }

    public override BarsCount (): number {
        if (isNullOrUndefined(this._series)) {
            return 0;
        }
        let maxBarsCount = 0;
        for (let i = 0; i < this._series.Count(); i++) {
            maxBarsCount = Math.max(maxBarsCount, this._series.data[i].Count());
        }
        return maxBarsCount;
    }

    public override FormatYScaleValue (value: number): string {
        return PriceFormatter.formatPrice(value, 3);
    }

    public override moveScreenByX (newI1: number, allowChangeBarsToRight?: boolean): boolean {
        const xyWindow = this.mainWindow;
        return xyWindow.MoveX((newI1 - xyWindow.FmaxFloatX) * xyWindow.XScale);
    }

    public override calulateNewI1FromDelta (deltaX: number): number {
        const scrollerRenderer = this.windowsContainer.scrollerRenderer;
        return scrollerRenderer.calci1(this.lastMouseDownState.X - scrollerRenderer.lastMouseDownDeltaX - deltaX);
    }

    public setSeries (series: TerceraChartAggregatedXYSeries): void {
        this.removeLineRenderers();
        this._series.Dispose();
        this._series = series;
        this.addLineRenderers();
    }

    protected getLineRendererByIndex (index: number): TerceraChartStyledLineRenderer {
        if (index >= 0 && index < this._lineRenderers.length) {
            return this._lineRenderers[index];
        }
    }

    protected addLineRenderers (): void {
        for (let i = 0; i < this._series.Count(); i++) {
            const seriesData = this._series.data[i];
            const styleLine = new TerceraChartStyledLineRenderer(this);
            styleLine.Series = seriesData;
            this._lineRenderers.push(styleLine);
        }
        this.mainWindow.Renderers.push(...this._lineRenderers);
    }

    protected removeLineRenderers (): void {
        for (let i = 0; i < this._lineRenderers.length; i++) {
            const rendererToRemove = this._lineRenderers[i];
            const rendererToRemoveIndex = this.mainWindow.Renderers.indexOf(rendererToRemove);
            this.mainWindow.Renderers.splice(rendererToRemoveIndex, 1);
            rendererToRemove.Dispose();
        }
        this._lineRenderers.splice(0, this._lineRenderers.length);
    }

    protected updateMinMaxXScaleBorders (): void {
        let minX = Number.MAX_VALUE;
        let maxX = -Number.MAX_VALUE;
        if (this._series.data.length > 0) {
            for (let i = 0; i < this._series.data.length; i++) {
                const seriesData = this._series.data[i];
                if (seriesData.Count() === 0) {
                    continue;
                }
                minX = Math.min(minX, seriesData.GetValue(0, XYSeriesData.X_INDEX));
                maxX = Math.max(maxX, seriesData.GetValue(seriesData.Count() - 1, XYSeriesData.X_INDEX));
            }
        }
        this.mainWindow.SetMinMaxXScale(minX, maxX);
    }
}
