// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DataCache } from '../../../Commons/DataCache';
import { Account } from '../../../Commons/cache/Account';
import { type Alert } from '../../../Commons/cache/Alert';
import { Instrument } from '../../../Commons/cache/Instrument';
import { type Order } from '../../../Commons/cache/Order';
import { type Position } from '../../../Commons/cache/Position';

export class TerceraChartTradingToolsRenderer_DataCacheDataProvider {
    public storage: any;
    public Instrument: Instrument | null = null;
    public Account: Account | null = null;

    constructor (storage) {
        this.storage = storage;
    }

    public Dispose (): void {
        this.Unsubscribe();

        this.Instrument = null;
        this.Account = null;
    }

    public FilterEntity (o): boolean {
        return o != null && Instrument.IsEqualInstrument(o.Instrument, this.Instrument) && Account.IsEqualAccount(o.Account, this.Account);
    // return o.Instrument === this.Instrument && o.Account == this.Account;
    }

    public IsReady (): boolean {
        return this.Instrument !== null || this.Account !== null;
    }

    public Resubscribe (): void {
        this.Unsubscribe();
        this.Subscribe();
    }

    public Subscribe (): void {
        DataCache.OnAddOrder.Subscribe(this.MultiDataCache_AddOrderPositionEvent, this);
        DataCache.OnUpdateOrder.Subscribe(this.MultiDataCache_AddOrderPositionEvent, this);
        DataCache.OnRemoveOrder.Subscribe(this.MultiDataCache_RemoveOrderPositionEvent, this);
        DataCache.OnAddPosition.Subscribe(this.MultiDataCache_AddOrderPositionEvent, this);
        DataCache.OnUpdatePosition.Subscribe(this.MultiDataCache_AddOrderPositionEvent, this);
        DataCache.OnRemovePosition.Subscribe(this.MultiDataCache_RemoveOrderPositionEvent, this);

        // DataCache.UpdatePositionInfoEvent += MultiDataCache_UpdatePositionInfoEvent;

        DataCache.OnAddSLOrderToPosition.Subscribe(this.BaseDataCache_AddSLOrderEvent, this);
        DataCache.OnAddTPOrderToPosition.Subscribe(this.BaseDataCache_AddTPOrderEvent, this);
        /// /BaseApplication.App.MultiDataCache.OrderStatusChangedEvent += new OnAddOrderDelegate(MultiDataCache_OrderStatusChangedEvent);

        DataCache.OnRemoveSLOrderFromPosition.Subscribe(this.BaseDataCache_RemoveSLOrderEvent, this);
        DataCache.OnRemoveTPOrderFromPosition.Subscribe(this.BaseDataCache_RemoveTPOrderEvent, this);

        DataCache.OnAddNewTradeEvent.Subscribe(this.MultiDataCache_AddNewTradeEvent, this);

        // alert
        DataCache.AlertManager.OnAlertAdded.Subscribe(this.MultiDataCache_AddNewAlertEvent, this);
        DataCache.AlertManager.OnAlertUpdated.Subscribe(this.MultiDataCache_AddNewAlertEvent, this);
        DataCache.AlertManager.OnAlertRemoved.Subscribe(this.MultiDataCache_RemoveAlertEvent, this);
    }

    public Unsubscribe (): void {
        DataCache.OnAddOrder.UnSubscribe(this.MultiDataCache_AddOrderPositionEvent, this);
        DataCache.OnUpdateOrder.UnSubscribe(this.MultiDataCache_AddOrderPositionEvent, this);
        DataCache.OnRemoveOrder.UnSubscribe(this.MultiDataCache_RemoveOrderPositionEvent, this);
        DataCache.OnAddPosition.UnSubscribe(this.MultiDataCache_AddOrderPositionEvent, this);
        DataCache.OnUpdatePosition.UnSubscribe(this.MultiDataCache_AddOrderPositionEvent, this);
        DataCache.OnRemovePosition.UnSubscribe(this.MultiDataCache_RemoveOrderPositionEvent, this);

        // DataCache.UpdatePositionInfoEvent -= MultiDataCache_UpdatePositionInfoEvent;

        DataCache.OnAddSLOrderToPosition.UnSubscribe(this.BaseDataCache_AddSLOrderEvent, this);
        DataCache.OnAddTPOrderToPosition.UnSubscribe(this.BaseDataCache_AddTPOrderEvent, this);

        /// /BaseApplication.App.MultiDataCache.OrderStatusChangedEvent -= new OnAddOrderDelegate(MultiDataCache_OrderStatusChangedEvent);

        DataCache.OnRemoveSLOrderFromPosition.UnSubscribe(this.BaseDataCache_RemoveSLOrderEvent, this);
        DataCache.OnRemoveTPOrderFromPosition.UnSubscribe(this.BaseDataCache_RemoveTPOrderEvent, this);

        DataCache.OnAddNewTradeEvent.UnSubscribe(this.MultiDataCache_AddNewTradeEvent, this);

        // alert
        DataCache.AlertManager.OnAlertAdded.UnSubscribe(this.MultiDataCache_AddNewAlertEvent, this);
        DataCache.AlertManager.OnAlertUpdated.UnSubscribe(this.MultiDataCache_AddNewAlertEvent, this);
        DataCache.AlertManager.OnAlertRemoved.UnSubscribe(this.MultiDataCache_RemoveAlertEvent, this);
    }

    public BaseDataCache_RemoveTPOrderEvent (order): void {
        this.storage.RemoveTPOrder(order);
    }

    public BaseDataCache_RemoveSLOrderEvent (order): void {
        this.storage.RemoveSLOrder(order);
    }

    public BaseDataCache_AddTPOrderEvent (order): void {
        if (!this.FilterEntity(order)) {
            return;
        }

        this.storage.AddTPOrder(order);
    }

    public BaseDataCache_AddSLOrderEvent (order): void {
        if (!this.FilterEntity(order)) {
            return;
        }

        this.storage.AddSLOrder(order);
    }

    public MultiDataCache_RemoveOrderPositionEvent (order): void {
        this.storage.RemoveOrderPosition(order);
    }

    public MultiDataCache_AddOrderPositionEvent (order): void {
        if (!this.FilterEntity(order)) {
            return;
        }

        this.storage.AddOrderPosition(order);
    }

    public MultiDataCache_AddNewTradeEvent (dc, trade): void {
        if (!this.FilterEntity(trade)) {
            return;
        }

        this.storage.AddNewTrade(trade);
    }

    public MultiDataCache_AddNewAlertEvent (alert): void {
        if (!this.FilterEntity(alert)) {
            return;
        }

        this.storage.AddNewAlert(alert);
    }

    public MultiDataCache_RemoveAlertEvent (alert): void {
        this.storage.RemoveAlert(alert);
    }

    public GetPositions (): Position[] {
        return DataCache.PositionListForAccountInstrument(this.Instrument, this.Account);
    }

    public GetOrders (): Order[] {
        return DataCache.OrdersListForAccountInstrument(this.Instrument, this.Account, null, false); // sl/tp NOT included
    }

    public GetAlerts (): Alert[] {
        return DataCache.AlertManager.GetFilteredAlerts(this.Instrument, this.Account);
    }

    public GetTrades (): Order[] {
        return DataCache.GetFilteredTrades(this.Instrument, this.Account);
    }
}
