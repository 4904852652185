import { type AuthenticationResponseType } from './AuthenticationResponseType';

export class AuthenticationResponseResult {
    public readonly ResponseType: AuthenticationResponseType;
    public readonly Result: string;

    constructor (responseType: AuthenticationResponseType, result: string) {
        this.ResponseType = responseType;
        this.Result = result;
    }
}
