// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../../Commons/properties/Resources';
import { DataCacheToolType } from '../DataCacheToolEnums';
import { BaseFibonacciDataCacheTool } from './BaseFibonacciDataCacheTool';
import { DynProperty } from '../../DynProperty';

export class FibonacciFansDataCacheTool extends BaseFibonacciDataCacheTool {
    /**
     * Constructor for FibonacciFansDataCacheTool
     */
    constructor () {
        super();
        this.ToolType = DataCacheToolType.FibonacciFans;
        this.InitPoints(2);
    }

    /**
     * Initializes the block for the tool
     */
    protected InitBlock (): void {
        super.InitBlock();
        this.fibLevel = [38.2, 50, 61.8];
    }

    /**
     * Gets the maximum points count required for the tool
     * @returns {number} - Maximum points count
     */
    MaxPointsCount (): number {
        return 2;
    }

    /**
     * Gets the minimum points count required for the tool
     * @returns {number} - Minimum points count
     */
    MinPointsCount (): number {
        return 2;
    }

    /**
     * Properties for FibonacciFansDataCacheTool
     * @returns {DynProperty[]} - Array of dynamic properties
     */
    Properties (): DynProperty[] {
        const props = super.Properties();

        let prop = new DynProperty(Resources.getResource('EnableNegativeFibonacciValue'), true, DynProperty.BOOLEAN, /* DynProperty.FIBONACCI_PARAMETERS_GROUP */DynProperty.HIDDEN_GROUP);
        props.push(prop);

        prop = new DynProperty('chart.tools.fibretr.fontcolor', this.FontColor, DynProperty.COLOR, DynProperty.VISUAL_GROUP);
        props.push(prop);

        return props;
    }

    /**
     * Callback function for handling dynamic property changes
     * @param {DynProperty[]} properties - Array of dynamic properties
     */
    callBack (properties: DynProperty[]): void {
        super.callBack(properties);
        const prop = DynProperty.getPropertyByName(properties, 'chart.tools.fibretr.fontcolor');
        if (prop != null) this.FontColor = prop.value;
    }
}
