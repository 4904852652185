import { TerceraButtonsPopup } from '../Controls/elements/TerceraButtonsPopup';
import { TerceraMenu } from '../Controls/elements/TerceraMenu';
import { TerceraMenuWithOver } from '../Controls/elements/TerceraMenuWithOver';
import { PopupErrorScreen } from '../Controls/screen/PopupErrorScreen.js';

export class ApplicationHandlersInitializer {
    public static Initialize (): void {
        PopupErrorScreen.PopupErrorHandlerInitialize();
        TerceraButtonsPopup.ButtonsPopupHandlerInitialize();
        TerceraMenu.ContextMenuHandlerInitialize();
        TerceraMenuWithOver.ContextMenuHandlerInitialize();
    }
}
