// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { terceraCheckBoxButtonTemplate } from "../../templates.js";
import { TerceraCheckBox } from "./TerceraCheckBox.js";

export let TerceraCheckBoxButton = TerceraCheckBox.extend({
    template: terceraCheckBoxButtonTemplate,
    data: function ()
    {
        return {
            checked: false,                 //checked или нет        
            isSL: false,
            text: ""
        };
    }
});