// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type OrderType } from '../../../../Utils/Trading/OrderType';
import { type OperationType } from '../../../../Utils/Trading/OperationType';
import { type ProductType } from '../../../../Utils/Instruments/ProductType';
import { type PFixFieldSet } from '../Fields/FieldsImport';
import { type PlacedFrom } from '../../../../Utils/Trading/PlacedFrom';

export class OrderReplaceMessage extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_REPLACE_ORDER_MESSAGE, fieldSet);
    }

    public getOrderId (): bigint {
        return this.getFieldValue(FieldsFactory.FIELD_ORDER_ID);
    }

    public setOrderId (orderId: bigint): void {
        this.setFieldValue(FieldsFactory.FIELD_ORDER_ID, orderId);
    }

    public getComment (): string {
        return this.getFieldValue(FieldsFactory.FIELD_COMMENT);
    }

    public setComment (comment: string): void {
        this.setFieldValue(FieldsFactory.FIELD_COMMENT, comment);
    }

    public getOrderType (): OrderType {
        return this.getFieldValue(FieldsFactory.FIELD_ORDER_TYPE);
    }

    public setOrderType (type: OrderType): void {
        this.setFieldValue(FieldsFactory.FIELD_ORDER_TYPE, type);
    }

    public getOperationType (): OperationType {
        return this.getFieldValue(FieldsFactory.FIELD_OPERATION_TYPE);
    }

    public setOperationType (type: OperationType): void {
        this.setFieldValue(FieldsFactory.FIELD_OPERATION_TYPE, type);
    }

    public getPrice (): number {
        return this.getFieldValue(FieldsFactory.FIELD_PRICE);
    }

    public setPrice (price: number): void {
        this.setFieldValue(FieldsFactory.FIELD_PRICE, price);
    }

    public getStopPrice (): number {
        return this.getFieldValue(FieldsFactory.FIELD_STOP_PRICE);
    }

    public setStopPrice (price: number): void {
        this.setFieldValue(FieldsFactory.FIELD_STOP_PRICE, price);
    }

    public setTIFExpireDate (time: Date): void {
        this.setFieldValue(FieldsFactory.FIELD_EXPIRE_AT, time);
    }

    public getAmount (): number {
        return this.getFieldValue(FieldsFactory.FIELD_AMOUNT);
    }

    public setAmount (amount: number): void {
        this.setFieldValue(FieldsFactory.FIELD_AMOUNT, amount);
    }

    public getDisclosedQty (): number {
        return this.getFieldValue(FieldsFactory.FIELD_DISCLOSED_QUANTITY);
    }

    public setDisclosedQty (disclosedQty: number): void {
        this.setFieldValue(FieldsFactory.FIELD_DISCLOSED_QUANTITY, disclosedQty);
    }

    public getValidity (): any {
        return this.getFieldValue(FieldsFactory.FIELD_VALIDITY);
    }

    public setValidity (validity): void {
        this.setFieldValue(FieldsFactory.FIELD_VALIDITY, validity);
    }

    public getOnBehalfId (): number {
        return this.getFieldValue(FieldsFactory.FIELD_ON_BEHALF_USER_ID);
    }

    public setOnBehalfId (onBehalfId: number): void {
        this.setFieldValue(FieldsFactory.FIELD_ON_BEHALF_USER_ID, onBehalfId);
    }

    public getBoundTo (): number {
        return this.getFieldValue(FieldsFactory.FIELD_BOUNDTOORDERID);
    }

    public setBoundTo (id: number): void {
        this.setFieldValue(FieldsFactory.FIELD_BOUNDTOORDERID, id);
    }

    public getInstrumentId (): number {
        return this.getFieldValue(FieldsFactory.FIELD_INSTRUMENT_ID);
    }

    public setInstrumentId (id: number): void {
        this.setFieldValue(FieldsFactory.FIELD_INSTRUMENT_ID, id);
    }

    public getInstrumentTradableID (): number {
        return this.getFieldValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID);
    }

    public setInstrumentTradableID (id: number): void {
        this.setFieldValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID, id);
    }

    public getRouteId (): number {
        return this.getFieldValue(FieldsFactory.FIELD_ROUTE_ID);
    }

    public setRouteId (id: number): void {
        this.setFieldValue(FieldsFactory.FIELD_ROUTE_ID, id);
    }

    public getAccountId (): number {
        return this.getFieldValue(FieldsFactory.FIELD_ACCOUNT_ID);
    }

    public setAccountId (id: number): void {
        this.setFieldValue(FieldsFactory.FIELD_ACCOUNT_ID, id);
    }

    public getProductType (): ProductType {
        return this.getFieldValue(FieldsFactory.FIELD_PRODUCT_TYPE);
    }

    public setProductType (type: ProductType): void {
        this.setFieldValue(FieldsFactory.FIELD_PRODUCT_TYPE, type);
    }

    public setPowerOfAttorneyUserId (userId): void {
        this.setFieldValue(FieldsFactory.FIELD_POA_USER_ID, userId);
    }

    public setReceivedBy (receivedBy: number): void {
        this.setFieldValue(FieldsFactory.FIELD_ORDER_RECEIVED_BY, receivedBy);
    }

    public setPlacedFrom (placedFrom: PlacedFrom): void {
        this.setFieldValue(FieldsFactory.FIELD_CLIENT_PANEL, placedFrom);
    }

    public setSLLimitPrice (price: number): void {
        this.setFieldValue(FieldsFactory.FIELD_SL_LIMIT_PRICE, price);
    }

    public setSLTriggerPrice (price): void {
        this.setFieldValue(FieldsFactory.FIELD_SL_TRIGGER_PRICE, price);
    }

    public setTPTriggerPrice (price): void {
        this.setFieldValue(FieldsFactory.FIELD_TP_TRIGGER_PRICE, price);
    }

    public setSLTPTriggerPrice (price): void {
        this.setFieldValue(FieldsFactory.FIELD_SL_TP_TRIGGER_PRICE, price);
    }
}
