// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from "../../Utils/CustomEvents.ts";
import { Resources } from "../../Commons/properties/Resources.ts";
import { TerceraRadioButtonComponent } from "./TerceraRadioButtonComponent.js";

export let WinNum = { winAdd: -1, Chart: 0, win1: 1, win2: 2, win3: 3, win4: 4 };

/// <summary>
/// Определение окон на чарте для размещения индикаторов
/// </summary>
export let ChartWindow = TerceraRadioButtonComponent.extend({
    data: function ()
    {
        return { isHorisontal: true }
    },
    minimum: 0,
    maximum: 0,
    /// <summary>
    /// Максимально доступное кол-во окон на чарте
    /// </summary>
    maximumAvailable: 4,


    /// <summary>
    /// значение текущего окна(DynProperty)
    /// </summary>
    value: 0,

    /// <summary>
    /// изменение значения - выбор окна
    /// </summary>
    ValueChanged: null,
});

ChartWindow.prototype.getType = function () { return 'ChartWindow' };

ChartWindow.prototype.oninit = function ()
{
    TerceraRadioButtonComponent.prototype.oninit.apply(this);
    this.ValueChanged = new CustomEvent();
};

ChartWindow.prototype.onRbchange = function (event, rindex)
{
    TerceraRadioButtonComponent.prototype.onRbchange.call(this, event, rindex);
    this.ValueChanged.Raise(this, rindex);
};

ChartWindow.prototype.MaximumAvailable = function ()
{
    return this.maximumAvailable;
}

ChartWindow.prototype.GetMaximum = function ()
{
    return this.maximum;
}
ChartWindow.prototype.SetMaximum = function (value)
{
    if (value <= this.maximumAvailable)
        this.maximum = value;
    else
        this.maximum = this.maximumAvailable;

    this.PopulateBtns();
}

ChartWindow.prototype.PopulateBtns = function ()
{
    var result = [];
    //кнопка "Chart" - для размещения на чарте
    //createRadioButton(WinNum.Chart);
    result.push({ text: this.getButtonText(WinNum.Chart), checked: true, type: WinNum.Chart });

    //кнопки 1,2,3... - для задания номера окна
    for (var i = 1; i < this.maximum; i++)
        result.push({ text: this.getButtonText(i), checked: false, type: i });

    //кнопка "Add Win" - для создания нового окна
    if (this.maximum != this.MaximumAvailable())
        result.push({ text: this.getButtonText(WinNum.winAdd), checked: false, type: WinNum.winAdd });

    this.set({ radioItems: result });
}

//ChartWindow.prototype.PopulateBtns = function ()
//{

//}

ChartWindow.prototype.GetMinimum = function ()
{
    return this.minimum;
}
ChartWindow.prototype.SetMinimum = function (value)
{
    if (value >= 0)
        this.minimum = value;
}

ChartWindow.prototype.GetValue = function ()
{
    return this.value;
}
ChartWindow.prototype.SetValue = function (value)
{
    this.value = value;

    //флаг установки значения
    var isSet = false;

    //выделяем по заданному значению
    var myItems = this.get('radioItems');
    var rbChartWinNum = null;
    var clickedItem = value;
    var len = myItems.length;
    var i;
    for (i = 0; i < len; i++)
    {
        if (clickedItem === i)
        {
            myItems[i].checked = true;
            isSet = true;
        }
        else
            myItems[i].checked = false;

        if (myItems[i].type === WinNum.Chart)
            rbChartWinNum = myItems[i];
    }

    //если не нашли - устанавливаем по умолчанию
    if (!isSet && rbChartWinNum !== null)
    {
        rbChartWinNum.checked = true;
    }

    this.set({ radioItems: myItems });
}

/// <summary>
/// получение title для кнопки по заданному индексу
/// </summary>
/// <param name="num"></param>
/// <returns></returns>
ChartWindow.prototype.getButtonText = function (num)
{
    switch (num)
    {
        case WinNum.Chart:
            return Resources.getResource("property.Win num.main"); // "Chart"
        case WinNum.winAdd:
            return Resources.getResource("property.Win num.addWin");
        case WinNum.win1:
            return "1";
        case WinNum.win2:
            return "2";
        case WinNum.win3:
            return "3";
        case WinNum.win4:
            return "4";

        default:
            return "";
    }
}

/// <summary>
/// событие click кнопки - выбор окна
/// </summary>
/// <param name="sender"></param>
/// <param name="e"></param>
//private void rb_Click(object sender, EventArgs e)
//{
//    //снимаем ранние выдиления
//    //SelectAllButton(Color.Empty);

//    //выделяем кнопку
//    //SelectButton((A)sender, colorSel);
//    if (sender == null)
//return;

////идентификатор кнопки
//WinNum num = (WinNum)((RadioButton)sender).Tag;

//switch (num)
//{
//    case WinNum.Chart:
//        value = 0;
//        break;
//    case WinNum.win1:
//        value = 1;
//        break;
//    case WinNum.win2:
//        value = 2;
//        break;
//    case WinNum.win3:
//        value = 3;
//        break;
//    case WinNum.win4:
//        value = 4;
//        break;
//    case WinNum.winAdd:
//        value = maximum;
//        break;
//}
//if(ValueChanged != null)
//    ValueChanged(this, e);

//}
