// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { Rectangle } from "../../Commons/Geometry.ts";
import { TerceraPopupErrorScreen } from "../../templates.js";
import { Control } from "../elements/Control.js";
import { popupErrorHandler } from '../../Utils/AppHandlers.js';

export let PopupErrorScreen = Control.extend({
    // template
    template: TerceraPopupErrorScreen,
    el: '#popupErrorScreenContainer',
    data: function ()
    {
        return {
            visible: false,
            errorText: '',
            headerText: 'Error',
            headerVisible: true,
            width: 153,
            height: 0,
            elementStyle: "js-win-popupError-top"
        };
    },
    isShowed: false,
    owner: null
});
PopupErrorScreen.prototype.getType = function () { return 'PopupErrorScreen' }

// TODO. Ugly. Refactor.
PopupErrorScreen.prototype.Show = function (owner, errText, headerText, isDown)
{
    if (typeof this.el !== 'object')
    {
        this.el = document.getElementById(this.el)
        this.render()
    }

    this.set({
        headerText: headerText || 'Error',
        headerVisible: true,
        errorText: errText,
        visible: true
    })

    this.isShowed = true
    this.owner = owner

    let errLabel = this.find('.js-tercera-label:last-child')
    if (!errLabel) return

    let $errLabel = $(errLabel)

    let bordersSize = 2
    let errHeaderH = 28
    let bottomFreeSpace = 16;
    let errH = $errLabel.height() + bordersSize + errHeaderH + bottomFreeSpace;

    this.set('height', errH)

    let ownerRectangle = owner.getAbsoluteRectangle()

    let errRect = PopupErrorScreen.getErrorAbsoluteRectangle(
        ownerRectangle,
        this.get('width'),
        errH)

    isDown = isDown || ownerRectangle.Y > errRect.Y

    this.set({
        left: errRect.Left(),
        top: errRect.Top(),
        elementStyle: (isDown ? "js-win-popupError-bottom" : "js-win-popupError-top")
    })
}

// TODO. Ugly.
PopupErrorScreen.getErrorAbsoluteRectangle = function (ownerAbsRect, errW, errH)
{
    // 52, 32 are top and bottom strips' heights.
    let scrRect = new Rectangle(0, 40, window.innerWidth, window.innerHeight - 52 - 32)

    let errRect = new Rectangle(ownerAbsRect.Left(), ownerAbsRect.Bottom(), errW, errH)

    if (scrRect.ContainsRectangle(errRect))
        return errRect

    let freeSpaceRect = null

    let bottomFreeSpaceRect = new Rectangle(
        scrRect.Left(),
        ownerAbsRect.Bottom(),
        scrRect.Width,
        scrRect.Bottom() - ownerAbsRect.Bottom())

    if (bottomFreeSpaceRect.CanFit(errW, errH))
    {
        errRect.Y = ownerAbsRect.Bottom()
        freeSpaceRect = bottomFreeSpaceRect
    }
    else
    {
        let topFreeSpaceRect = new Rectangle(
            scrRect.Left(),
            scrRect.Top(),
            scrRect.Width,
            ownerAbsRect.Top() - scrRect.Top())

        if (topFreeSpaceRect.CanFit(errW, errH))
        {
            errRect.Y = ownerAbsRect.Top() - errH
            freeSpaceRect = topFreeSpaceRect
        }
    }

    if (freeSpaceRect)
    {
        errRect.X =
            ownerAbsRect.Left() + errW < freeSpaceRect.Right()
                ? ownerAbsRect.Left()
                : scrRect.Right() - errW - 1
    }

    return errRect
}

PopupErrorScreen.prototype.Hide = function (owner)
{
    if (!this.isShowed)
        return;

    if (owner !== this.owner)
        return;

    this.set({
        visible: false
    });
    this.isShowed = false;
};

PopupErrorScreen.PopupErrorHandlerInitialize = function() {
    const _popupError = new PopupErrorScreen();
    popupErrorHandler.setHandler(_popupError);
    popupErrorHandler.Show = _popupError.Show.bind(_popupError);
    popupErrorHandler.Hide = _popupError.Hide.bind(_popupError);
    popupErrorHandler.set = (key) => { void _popupError.set(key); };
    // popupErrorHandler.on = _popupError.on;
    // popupErrorHandler.off = _popupError.off;

    popupErrorHandler.isShowed = () => { return _popupError.isShowed; };
}