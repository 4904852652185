// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { TimeSpanPeriods } from '../Time/TimeSpan';
import { Periods } from './TFInfo';

export class CashItemUtils {
    static FindStartData (time, newPeriod, sessionBeginOffset) {
        sessionBeginOffset = sessionBeginOffset || 0;
        const data = time.getTime() - new Date(1970, 1, 1).getTime() - sessionBeginOffset;
        const periodsCount = data / (newPeriod * TimeSpanPeriods.TicksPerSecond * 60);

        const trunc = periodsCount - Math.trunc(periodsCount);
        let dx = trunc * newPeriod; // разница по времени между началом бара и указанной точкой
        if (dx < 0.99) dx = 0; // заплатка на случй мерджа данных из тиков, когда время тика = N минут 31 и более секунд. тогда округление приведет к тому, что будет вставлена лишняя минута
        const minutesToAdd = Math.round(dx); // тут раунд, а не транк, так как есть вариант, что получим 13.9999 минут для вставки вместо 14

        // если смещение меньше базового периода, т.е. минимальной величины, добавлять ничего не надо
        // заплатка для приколов со сменой стартдей дневной истории
        // if (Math.Abs(newPeriod - minutesToAdd) >= basePeriod)
        time = new Date(time.setMinutes(time.getMinutes() - minutesToAdd));
        // else
        // {
        // }

        return time;
    };
}

export const PriceType =
{
    /// <summary>
    /// Цена открытия
    /// </summary>
    Open: 0,
    /// <summary>
    /// Цена закрытия
    /// </summary>
    Close: 1,
    /// <summary>
    /// Максимальная цена
    /// </summary>
    High: 2,
    /// <summary>
    /// Минимальная цена
    /// </summary>
    Low: 3,
    /// <summary>
    /// Средняя цена
    /// </summary>
    Medium: 4,
    /// <summary>
    /// Типичная цена
    /// </summary>
    Typical: 5,
    /// <summary>
    /// Средневзвешенная цена
    /// </summary>
    Weighted: 6
};
Object.freeze(PriceType);

export const PriceTypeMap = Object.fromEntries(Object.entries(PriceType).map(([k, v]) => [v, k]));
Object.freeze(PriceTypeMap);
