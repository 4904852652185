// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { MathUtils } from '../../Utils/MathUtils';
import { type Instrument } from './Instrument';

export class Asset {
    DataCache: any;
    public Id: number; // int
    public Type: AssetType; // AssetType
    public Name: string;
    public Description: string;
    public MinChange: number; // double
    /// <summary>
    /// Рассчетное поле на основании мин чейнджа
    /// </summary>
    public Point = 2; // int
    public InstrumentId = -1; // int
    public RouteId = -1; // int
    public CreditForSell = null; // double?
    public LiquidityRate = null; // double?
    public InstrumentTradableID = -1;
    private readonly _instrument: Instrument | null = null;
    private readonly _sellExchanges = null;

    public _insForTading = null;

    public floatDecimalFormatStatic = null;
    public instrPr: any;

    constructor (dataCache, assetMessage) {
        this.DataCache = dataCache;
        this.FillByMessage(assetMessage);
        this.CreateFormatter();
    }

    public toJSON () {
        return {
            Id: this.Id,
            Type: this.Type,
            Name: this.Name
        };
    }

    public FillByMessage (message): void {
        this.Id = message.Id;
        this.Name = message.Name;
        this.Type = message.Type;
        this.Description = message.Description;
        this.MinChange = message.MinChange;
        if (this.MinChange > 0) {
            this.Point = MathUtils.GetDecimalPlaces(this.MinChange, 8);
        }
        if (message.InstrumentId !== null) {
            this.InstrumentId = message.InstrumentId;
        }
        if (message.RouteId !== null) {
            this.RouteId = message.RouteId;
        }

        this.CreditForSell = message.CreditForSell;
        this.LiquidityRate = message.LiquidityRate;
        this.InstrumentTradableID = message.InstrumentTradableID;
    }

    public toString (): string {
        return this.Name;
    }

    public formatPrice (value, noCurrency = false): string {
        if (isNaN(value)) {
            value = 0;
        }

        value = MathUtils.TruncateDouble(value, this.Point); // для предотвращения округления в форматере

        if (noCurrency) {
            return this.floatDecimalFormatStatic.format(value);
        }

        return this.floatDecimalFormatStatic.format(value) + ' ' + this.Name;
    }

    get Instrument (): Instrument | null {
        if (this._instrument && !this.instrPr) {
            return this._instrument;
        }

        if (this.instrPr) {
            return null;
        }
        this.instrPr = this.GetInstrumentAsync().then(function (instrument) {
            this._instrument = instrument;
            this.instrPr = null;
        }.bind(this));

        return null;
    }

    public async GetInstrumentAsync (): Promise<Instrument | null> {
        if (this.InstrumentTradableID !== -1 && this.RouteId !== -1) {
            return this.DataCache.getInstrumentByInstrumentTradableID_NFL(this.InstrumentTradableID, this.RouteId).then(function (instrument) {
                if (!instrument) {
                    return null;
                }

                if (instrument.TradableRoutes.indexOf(instrument.Route) === -1 && instrument.TradableRoutes.length) {
                    return this.DataCache.getInstrumentByInstrumentTradableID_NFL(this.InstrumentTradableID, instrument.TradableRoutes[0]);
                }

                return instrument;
            }.bind(this));
            // var list = BaseApplication.App.MultiDataCache.Instruments.GetNonFixedInstrumentsByTradableID(TradableId);
            // if (list.Count(x => x.RouteId == this.RouteId) != 0 && list.Count == 2) // кейс, когда схлопываются роуты
            //     return list.Where(x => x.RouteId != this.RouteId).FirstOrDefault();
        }

        return await Promise.resolve(null);
    }

    get SellExchanges (): any {
        if (this._sellExchanges) {
            return this._sellExchanges;
        }

        this.UpdateSellExchanges();

        return null;
    }

    public GetPreferedInstrumentInterriorIdByExchange (exchange): any {
        if (!this._insForTading) {
            return null;
        }
        const arr = this._insForTading[exchange];
        if (!arr) {
            return null;
        }
        return arr[0] || null;
    }

    public UpdateSellExchanges (): any {
        return this.DataCache.GetNonFixedInstrumentListByAssetName(this.Name)
            .then(function (instruments) {
                const sellExchangesObj = {};
                const insForTading = {};
                for (let i = 0; i < instruments.length; i++) {
                    const ins = instruments[i];
                    sellExchangesObj[ins.TradingExchange] = true;
                    if (!insForTading[ins.TradingExchange]) {
                        insForTading[ins.TradingExchange] = [];
                    }
                    insForTading[ins.TradingExchange].push(ins.GetInteriorID());
                }
                this._insForTading = insForTading;
                const sellExchangesArray = Object.keys(sellExchangesObj);
                sellExchangesArray.sort();

                if (!this._instrument) {
                    return this.GetInstrumentAsync()
                        .then(function (instrument) {
                            this._instrument = instrument;
                            return sellExchangesArray;
                        }.bind(this));
                }

                return sellExchangesArray;
            }.bind(this))
            .then(function (sellExchangesArray) {
                if (!this._instrument) {
                    return sellExchangesArray;
                }

                const index = sellExchangesArray.indexOf(this._instrument.TradingExchange);

                sellExchangesArray.splice(index, 1);
                sellExchangesArray.unshift(this._instrument.TradingExchange);

                this._sellExchanges = sellExchangesArray;
                return this._sellExchanges;
            }.bind(this));
    }

    public CreateFormatter (): void {
        // nicky
        // при форматировании цены применялись точности из ассета (Point)
        // и при параллельных запросах очень часто возникал баз из-за
        // использования несинхронизированного доступа к форматтеру из инструмента.
        //
        // чтобы не ухудшать перфоманс локом, вынес каждому ассету личный форматтер
        //
        this.floatDecimalFormatStatic = new Intl.NumberFormat(undefined, {
            minimumFractionDigits: this.Point,
            maximumFractionDigits: this.Point
        });
    }

    public IsEqualAsset (assetToCheckEqualWith: Asset): boolean {
        return Asset.IsEqualAsset(this, assetToCheckEqualWith);
    }

    public static IsEqualAsset (a1: Asset, a2: Asset): boolean {
        if (a1 == null || a2 == null) {
            return false;
        }
        return a1.Id === a2.Id;
    }
}

export enum AssetType {
    CURRENCY = 0,
    SHARES = 1,
    COMMODITIES = 2,
    CRYPTO_CCY = 3
}
