// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

export class BrowserUtils {
    public static getBrowserVersion (): string {
        if (typeof navigator === 'undefined') {
            return 'Unknown browser';
        }

        const ua = navigator.userAgent;
        let tem;
        let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE ' + (tem[1] || '');
        }

        if (M[1] === 'Chrome') {
            tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem != null) {
                return tem.slice(1).join(' ').replace('OPR', 'Opera');
            }
        }

        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) {
            M.splice(1, 1, tem[1]);
        }

        return M.join(' ');
    }

    public static isChrome = (function () {
        const browser = BrowserUtils.getBrowserVersion();
        return !(!browser.includes('Chrome') && !browser.includes('chrome'));
    }());

    public static isSafari = (function () {
        const browser = BrowserUtils.getBrowserVersion();
        return browser.includes('Safari');
    }());

    public static isEdgeOrIEBrowser = (function () {
        const browser = BrowserUtils.getBrowserVersion();
        return !(!browser.includes('IE') && !browser.includes('Edge'));
    }());

    public static isIE (): boolean {
        const ua = window.navigator.userAgent;
        const msie = ua.indexOf('MSIE ');

        return msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
    }

    public static RemoveHTMLTags (html): string // #112820 + #114020
    {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        doc.querySelectorAll('style, script').forEach(el => { el.remove(); });
        return doc.body.innerText.trim();
    }

    public static getUrlVars (url): any {
        const vars: any = {};
        const parts = url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
            vars[key] = value;
        });
        return vars;
    }

    public static isEmail (email): boolean {
        const regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        return regex.test(email);
    }

    public static getCallerName (): string {
        try {
            throw new Error();
        } catch (e) {
            const stackLines = e.stack.split('at ');

            return stackLines[3].split('\n')[0];
        }
    }

    public static getMethodName (callerName): string {
        if (!callerName) {
            return '';
        }

        const caller = callerName.split(' (')[0];
        const splitByPoint = caller.split('.');

        return splitByPoint[splitByPoint.length - 1];
    }

    public static isDebug (): boolean {
        return window && window.location && window.location.href == 'http://localhost:3000/';
    }

    // BrowserUtils.log  (logOptions = {})
    // {
    //     const {
    //         text = '',
    //         color = BrowserUtils.LOGS_DEFAULT_TEXT_COLOR,
    //         background = BrowserUtils.LOGS_DEFAULT_BACKGROUND_COLOR,
    //         objectToLog,
    //         objectToLogDescription,
    //         showObjectToLogAsTable = false,
    //         needTraceCallStack = false,
    //         debugMode = BrowserUtils.isDebug()
    //     } = logOptions;

    //     if (!debugMode)
    //         return;

    //     // const ticker = this.getTVSymbol();

    //     // const panelIns = this.GetInstrument();
    //     // const panelInsName = panelIns ? panelIns.DisplayName() : 'INSTRUMENT_IS_ABSENT';

    //     // const cISeries = this.cashItemSeries;
    //     // const cashItemIns = cISeries ? cISeries.CashItem.Instrument : null;
    //     // const cashItemInsName = cashItemIns ? cashItemIns.DisplayName() : 'CASH_ITEM_SERIES_IS_ABSENT';

    //     const callerFull = BrowserUtils.getCallerName();
    //     const callerMethod = BrowserUtils.getMethodName(callerFull);

    //     const generalLogs = callerFull;

    //     const noMainText = !text || text.lastIndexOf(':') == text.length - 1;
    //     const logsText = `[${callerMethod}]: ` + text + (noMainText ? generalLogs : '');

    //     const seemsToBeAllright = true //panelIns && cashItemIns;

    //     const logsBackgroundColor = seemsToBeAllright ? background : 'red';
    //     const logsTextColor = seemsToBeAllright ? color : 'white';
    //     const logsStyle = 'background: ' + logsBackgroundColor + '; color: ' + logsTextColor + ';';

    //     // if (!seemsToBeAllright)
    //     //     console.error('NO INSTRUMENT IN ' + (panelIns ? 'CASHITEM' : 'PANEL'));

    //     console.groupCollapsed('%c' + logsText, logsStyle);       // MainText

    //     if (!noMainText)
    //         console.log('%c' + generalLogs, logsStyle);  // General for all logs text

    //     if (objectToLogDescription)
    //         console.log('%c', logsStyle, objectToLogDescription);
    //     if (objectToLog)
    //     {
    //         if (showObjectToLogAsTable)
    //             console.table(objectToLog);
    //         else
    //             console.log('%c', logsStyle, objectToLog);
    //     }

    //     if (needTraceCallStack)
    //     {
    //         console.groupCollapsed('%cCall stack', logsStyle);
    //         console.trace();
    //         console.groupEnd();
    //     }

    //     console.groupEnd();
    // }

    public static readonly LOGS_DEFAULT_TEXT_COLOR = '#e8eaed';
    public static readonly LOGS_DEFAULT_BACKGROUND_COLOR = '#171616'; /* Chrome's default console background color=#222 */

    public static readonly regexpSymbol = /[\<\>\|\:\/\"\*\?\\]/;
    public static readonly regexpEnd = /[\.\s]$/;
    public static readonly regexpText = /\b(COM|LPT)[1-9]|(CON|PRN|AUX|NUL)\b/i;
}
