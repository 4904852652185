// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type Account } from '../../cache/Account';
import { type QuoteData, type IDatafeedQuotesApi, type QuotesCallback, type QuotesErrorCallback } from '../charting_library';
import { TvQuoteSubscriptionItem } from '../Containers/TvQuoteSubscriptionItem';
import { TvQuoteSubscriptionsContainer } from '../Containers/TvQuoteSubscriptionsContainer';

export class TvQuoteManager implements IDatafeedQuotesApi {
    private readonly subscriptionContainer = new TvQuoteSubscriptionsContainer();
    private curAccount: Account;

    public getQuotes (symbols: string[], onDataCallback: QuotesCallback, onErrorCallback: QuotesErrorCallback): void {
        setTimeout(() => {
            const data: QuoteData[] = TvQuoteSubscriptionItem.getQuoteDataArray(symbols, this.curAccount);
            onDataCallback(data);
        }, 0);
    }

    public subscribeQuotes (symbols: string[], fastSymbols: string[], onRealtimeCallback: QuotesCallback, listenerGUID: string): void {
        this.subscriptionContainer.subscribeQuotes(symbols, fastSymbols, onRealtimeCallback, listenerGUID);
    }

    public unsubscribeQuotes (listenerGUID: string): void {
        this.subscriptionContainer.unsubscribeQuotes(listenerGUID);
    }

    public setCurrentAccount (account: Account): void {
        this.curAccount = account;
        this.subscriptionContainer.changeCurrentAccount();
    }
}
