// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../../Commons/properties/Resources.ts";
import { MathUtils } from "../../../Utils/MathUtils.ts";
import { Intervals } from "../../../Utils/Instruments/Intervals.ts";
import { CreateAlertValueSelectorTemplate } from "../../../templates.js";
import { ContainerControl } from "../../elements/ContainerControl.js";
import { TerceraNumeric } from "../../elements/TerceraNumeric.js";
import { AlertType } from "../../../Utils/Alert/AlertConstants.ts";
import { NumericUtils } from "../../../Utils/NumericUtils.ts";
import { GeneralSettings } from "../../../Utils/GeneralSettings/GeneralSettings.ts";
import { SessionSettings } from "../../../Commons/SessionSettings.ts";
import { Quantity } from "../../../Utils/Trading/Quantity.ts";

export let CreateAlertValueSelector = ContainerControl.extend({
    template: CreateAlertValueSelectorTemplate,
    data: function ()
    {
        return {
            label: '',
            instrument: null,
            width: 200,

            numericValue: 0.01,
            minValue: 0,
            maxValue: TerceraNumeric.NUMERIC_MAXVALUE,
            step: 0.01,
            decimalPrecision: 2,

            allowMinus: false
        }
    }
})

CreateAlertValueSelector.prototype.getType = function () { return 'CreateAlertValueSelector' };

CreateAlertValueSelector.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.call(this)

    this.observe('instrument selectedItem', this.onInstrumentChanged)
    Resources.onLocaleChange.Subscribe(this.localize, this)
    this.localize()
}

CreateAlertValueSelector.prototype.onInstrumentChanged = function (n, o)
{
    let instrument = this.get('instrument');
    if (!instrument)
        return;

    let Level1 = instrument.Level1;
    if (!Level1)
        return;

    let selectedItem = this.get('selectedItem');
    let account = this.get('account');
    let settings = NumericUtils.getNumericsOffsetModeViewParams(instrument)
    let value = null;
    let allowMinus = true;
    let minValue = settings.step;
    let isTradingNumeric = true;
    let step = settings.step
    let decimalPrecision = settings.numericsPrec

    let intervals = Intervals.GetIntervalsFromInstrument(instrument, TerceraNumeric.NUMERIC_MAXVALUE)
    if (intervals)
        minValue = intervals[0].Increment

    if (AlertType.ASK === selectedItem.value)
    {
        value = Level1.GetAsk(account)
        if (isNaN(value))
            value = instrument.PointSize

        minValue = -TerceraNumeric.NUMERIC_MAXVALUE
    }

    if (AlertType.BID === selectedItem.value)
    {
        value = Level1.GetBid(account)
        if (isNaN(value))
            value = instrument.PointSize

        minValue = -TerceraNumeric.NUMERIC_MAXVALUE
    }

    if (AlertType.LAST === selectedItem.value)
    {
        value = Level1.GetLastPrice(account)
        if (isNaN(value))
            value = instrument.PointSize

        minValue = -TerceraNumeric.NUMERIC_MAXVALUE
    }

    if (AlertType.CHANGE === selectedItem.value)
    {
        value = Level1.GetChangePercent(account)
        if (!value || isNaN(value))
            value = 0.01

        step = 0.01
        decimalPrecision = 2
        minValue = -TerceraNumeric.NUMERIC_MAXVALUE
        intervals = null
        isTradingNumeric = false;
    }

    if (AlertType.VOLUME === selectedItem.value)
    {
        value = instrument.TotalVolume
        let inLots = GeneralSettings.View.displayAmountInLots();
        let qtyNumericSettings = Quantity.getQuantityNumericSettings(instrument, inLots, account);
        step = qtyNumericSettings.step;//instrument.LotStep
        minValue = qtyNumericSettings.step;//minValue = 0;
        decimalPrecision = MathUtils.getPrecision(step);
        intervals = null;
        allowMinus = false;
        if (isNaN(value))
            value = minValue
    }

    this.set({
        numericValue: value,
        step: step,
        decimalPrecision: decimalPrecision,
        allowMinus: allowMinus,
        minValue: minValue,
        intervals: intervals,
        isTradingNumeric: isTradingNumeric
    })
}

CreateAlertValueSelector.prototype.dispose = function ()
{
    Resources.onLocaleChange.UnSubscribe(this.localize, this)

    ContainerControl.prototype.dispose.call(this)
}

CreateAlertValueSelector.prototype.localize = function ()
{
    this.set('label', Resources.getResource('screen.Alerts.Label.Value'))
}