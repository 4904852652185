// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { MAMode } from '../IndicatorConstants';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { IndicatorFunctions } from '../IndicatorFunctions';
import { ExpandDoubleVector } from '../DoubleMatrix';
import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';

export class TSI extends IndicatorScriptBase {
    constructor () {
        super();
        this.ProjectName = 'True Strength Index';
        this.Comments = 'Is a variation of the Relative Strength Indicator which uses a ' +
            'doubly-smoothed exponential moving average of price momentum to eliminate choppy price changes and spot trend changes';
        super.SetIndicatorLine('TSI', Color.Red, 2, LineStyle.SimpleChart);
        super.SetLevelLine('50', -50, Color.Gray, 1, LineStyle.SimpleChart);
        super.SetLevelLine('Zero', 0, Color.Gray, 1, LineStyle.SimpleChart);
        super.SetLevelLine('-5', 50, Color.Gray, 1, LineStyle.SimpleChart);
        this.SeparateWindow = true;

        this.First_R = 5;
        super.InputParameter(new InputParameterInteger('First_R', 'First_R', 0, 1, 9999));

        this.Second_S = 8;
        super.InputParameter(new InputParameterInteger('Second_S', 'Second_S', 1, 1, 9999));

        this.emaMtm = new ExpandDoubleVector();
        this.emaAbsmtm = new ExpandDoubleVector();
        this.ema2Mtm = [];
        this.ema2Absmtm = [];
    }

    public First_R: number;

    public Second_S: number;

    private emaMtm: ExpandDoubleVector;

    private emaAbsmtm: ExpandDoubleVector;

    private ema2Mtm: number[];

    private ema2Absmtm: number[];

    public Init (): void {
        super.Init();
        super.IndicatorShortName(this.GetIndicatorShortName());
    }

    public override GetIndicatorShortName (): string {
        return 'TSI(' + this.First_R + ',' + this.Second_S + ')';
    }

    public NextBar (): void {
        this.emaMtm.Add(0.0);
        this.emaAbsmtm.Add(0.0);
        this.ema2Mtm.push(0);
        this.ema2Absmtm.push(0);
        super.NextBar();
    }

    public OnQuote (): void {
        super.OnQuote();
        if (this.CurrentData.Count < this.Second_S) {
            this.emaMtm[this.emaMtm.Length - 1] = this.CurrentData.GetPrice(PriceType.Close);
            return;
        }

        this.getMTM();
        this.getAbsMTM();
        this.getEMAMTM();
        this.getAbsEMAMTM();

        const ema2Mtm = this.ema2Mtm[this.ema2Mtm.length - 1];
        const ema2Absmtm = this.ema2Absmtm[this.ema2Absmtm.length - 1];

        super.SetValue(0, 0, 100 * ema2Mtm / ema2Absmtm);
    }

    public getMTM (): void {
        this.emaMtm[this.emaMtm.Length - 1] = this.CurrentData.GetPrice(PriceType.Close) - this.CurrentData.GetPrice(PriceType.Close, 1);
    }

    public getAbsMTM (): void {
        this.emaAbsmtm[this.emaAbsmtm.Length - 1] = Math.abs(this.emaMtm[this.emaMtm.Length - 1]);
    }

    public getEMAMTM (): void {
        this.ema2Mtm[this.ema2Mtm.length - 1] = IndicatorFunctions.CallMovingFunction(MAMode.EMA, this.emaMtm, this.Second_S, 1);
    }

    public getAbsEMAMTM (): void {
        this.ema2Absmtm[this.ema2Absmtm.length - 1] = IndicatorFunctions.CallMovingFunction(MAMode.EMA, this.emaAbsmtm, this.Second_S, 1);
    }
}
