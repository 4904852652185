import { PanelNames } from '../../UtilsClasses/FactoryConstants';
import { ApplicationPanelWithTable } from '../ApplicationPanelWithTable';
import { OptionPaperPositionsPanelTemplate } from '../../../templates';
import { type QuickTableRactive } from '../../elements/QuickTable/QuickTableRactive';
import { type QuickTable } from '../../elements/QuickTable/QuickTable';
import { type QuickTableRow } from '../../elements/QuickTable/QuickTableRow';
import { PaperPositionItem } from '../../cache/PaperPositionItem';
import { type OptionTrader } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionTrader';
import { Resources } from '../../../Commons/properties/Resources';
import { SessionSettings } from '../../../Commons/SessionSettings';
import { Quantity } from '../../../Utils/Trading/Quantity';
import { OrderType } from '../../../Utils/Trading/OrderType';
import { OrderUtils } from '../../../Utils/Trading/OrderUtils';
import { GeneralSettings } from '../../../Utils/GeneralSettings/GeneralSettings';
import { SlTpPriceType } from '../../../Utils/Enums/Constants';
import { type PaperPosition } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionPaperPosition/PaperPosition';
import { LinkedPriceType } from '../../../Commons/cache/SavedOrders/SavedOrderEnums';
import { OperationType } from '../../../Utils/Trading/OperationType';
import { SavedOrderValidator } from '../../../Commons/cache/SavedOrders/SavedOrderValidator';
import { ThemeManager } from '../../misc/ThemeManager';
import { SavedOrdersController } from '../../../Commons/cache/SavedOrders/SavedOrdersController';
import { type OrderEditBase } from '../../../Commons/cache/OrderParams/order-edit/OrderEditBase';
import { MainWindowManager } from '../../UtilsClasses/MainWindowManager';
import { IsAllowed } from '../../../Commons/IsAllowed';

export class OptionPaperPositionsPanel extends ApplicationPanelWithTable<PaperPositionItem> {
    private _isInitialized: boolean = false;
    private _quickTable: QuickTable<PaperPositionItem>;
    private _optionTrader: OptionTrader;

    constructor () {
        super();
        this.Name = 'OptionPaperPositionsPanel';
        this.headerLocaleKey = 'panel.optionPaperPositions';
    }

    public override getType (): PanelNames { return PanelNames.OptionPaperPositionsPanel; }

    public override oninit (): void {
        super.oninit();
        this.populateTable = this.populateTable.bind(this);
        this.onAfterEditItem = this.onAfterEditItem.bind(this);
        this.onUpdatePaperPosition = this.onUpdatePaperPosition.bind(this);
        this.processEditRow = this.processEditRow.bind(this);
        this.onAddUnderlierBtnClicked = this.onAddUnderlierBtnClicked.bind(this);
        this.onCancelAllBtnClicked = this.onCancelAllBtnClicked.bind(this);
        this.onPlaceSelectedBtnClicked = this.onPlaceSelectedBtnClicked.bind(this);
        super.observe('visible', this.onVisibleChanged);
        super.on('onAddUnderlierBtnClick', this.onAddUnderlierBtnClicked);
        super.on('onCancelAllBtnClick', this.onCancelAllBtnClicked);
        super.on('onPlaceSelectedBtnClick', this.onPlaceSelectedBtnClicked);
    }

    public override onteardown (): void {
        if (!isNullOrUndefined(this._optionTrader)) {
            this._optionTrader.unsubscribeOnInstrumentChanged(this.populateTable);
            this._optionTrader.unsubscribeOnCreatePaperPosition(this.populateTable);
            this._optionTrader.unsubscribeOnRemovePaperPosition(this.populateTable);
            this._optionTrader.unsubscribeOnClearPaperPositions(this.populateTable);
            this._optionTrader.unsubscribeOnUpdatePaperPosition(this.onUpdatePaperPosition);
        }
        if (!isNullOrUndefined(this._quickTable)) {
            this._quickTable.AfterEditItem.UnSubscribe(this.onAfterEditItem, this);
            this._quickTable.OnPaintedPictureButtonClick.UnSubscribe(this.onTableRowButtonClicked, this);
        }
        super.onteardown();
    }

    public override jbInit (): void {
        this.initializeVariables();
        super.jbInit();
        this._quickTable.InitializeDirect(new PaperPositionItem(null, null, null));
        this._quickTable.UpdateSortedColumns();
        this._quickTable.AfterEditItem.Subscribe(this.onAfterEditItem, this);
        this._quickTable.OnPaintedPictureButtonClick.Subscribe(this.onTableRowButtonClicked, this);
    }

    public override getQuickTable (): QuickTable { return this._quickTable; }

    public override layoutTable (): void {
        super.layoutTable();
        for (const table of super.findAllComponents('quickTableRactive')) {
            super.layoutTableResize(table);
        }
    }

    public override localize (): void {
        super.localize();
        if (!this._isInitialized) {
            return;
        }
        this._quickTable.localize();
        super.set('addUnderlierButtonText', Resources.getResource('panel.optionPaperPositions.addUnderlier'));
        super.set('cancelAllButtonText', Resources.getResource('panel.optionPaperPositions.cancelAll'));
        super.set('placeSelectedButtonText', Resources.getResource('panel.optionPaperPositions.placeSelected'));
    }

    public override themeChange (): void {
        super.themeChange();
        if (!this._isInitialized) {
            return;
        }
        this._quickTable.themeChange();
    }

    public override TickAsync (): void {
        super.TickAsync();
        this.getQuickTable().needRedrawBackground = true;
    }

    public override repopulate (): void {
        super.repopulate();
        this.populateTable();
    }

    public override updateSettings (): void {
        super.updateSettings();
        const rows: QuickTableRow[] = super.getQuickTable().rowsArray;
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].isGroupRow) {
                continue;
            }
            const item: PaperPositionItem = rows[i].item;
            item.updateEditingInfo();
            this.processEditRow(rows[i]);
        }
    }

    public setOptionTrader (optionTrader: OptionTrader): void {
        this._optionTrader = optionTrader;
        this._optionTrader.subscribeOnInstrumentChanged(this.populateTable);
        this._optionTrader.subscribeOnCreatePaperPosition(this.populateTable);
        this._optionTrader.subscribeOnRemovePaperPosition(this.populateTable);
        this._optionTrader.subscribeOnClearPaperPositions(this.populateTable);
        this._optionTrader.subscribeOnUpdatePaperPosition(this.onUpdatePaperPosition);
    }

    private populateTable (): void {
        this._quickTable.ClearAll();
        const paperPositions = this._optionTrader.getPaperPositions();
        for (let i = 0; i < paperPositions.length; i++) {
            const row = this._quickTable.AddItem(new PaperPositionItem(this._optionTrader, paperPositions[i], SessionSettings));
            this.processEditRow(row);
        }
        this.updateButtonsEnability();
    }

    private initializeVariables (): void {
        const quickTableRactive = super.findAllComponents('quickTableRactive')[0] as QuickTableRactive;
        this._quickTable = quickTableRactive.quickTable;
        this._isInitialized = true;
    }

    private onVisibleChanged (): void {
        if (!this._isInitialized) {
            return;
        }
        this.layoutTable();
    }

    private onUpdatePaperPosition (paperPosition: PaperPosition): void {
        for (let i = 0; i < this._quickTable.rowsArray.length; i++) {
            const row = this._quickTable.rowsArray[i];
            if (row.isGroupRow) {
                continue;
            }
            const item: PaperPositionItem = row.item;
            if (item.savedOrder === paperPosition) {
                this.processEditRow(row);
                row.FillRowByItem(item, true);
                this._quickTable.redraw();
                break;
            }
        }
    }

    private onAfterEditItem (data): void {
        const tableItem: PaperPositionItem = data.row.item;
        switch (data.realColumnIndex) {
        case PaperPositionItem.STRIKE_COL_INDEX:
            this._optionTrader.changeInstrumentForPaperPosition(tableItem.savedOrder.Instrument, data.newValue);
            break;
        case PaperPositionItem.QUANTITY_COL_INDEX:
            if (data.newValue === 0) {
                this._optionTrader.removePaperPosition(tableItem.savedOrder.Instrument);
            } else {
                const sign = Math.sign(data.newValue);
                const lots = Quantity.convertAmountToLots(Math.abs(data.newValue), tableItem.savedOrder.Instrument);
                tableItem.savedOrder.QuantityLots = lots;
                tableItem.savedOrder.Operation = sign > 0 ? OperationType.Buy : OperationType.Sell;
                if (tableItem.savedOrder.OrderType === OrderType.Limit) {
                    tableItem.savedOrder.Price = tableItem.savedOrder.Bid;
                }
            }
            break;
        case PaperPositionItem.ORDER_TYPE_COL_INDEX:
            tableItem.savedOrder.OrderType = data.newValue;
            tableItem.savedOrder.setDefaultPrices();
            tableItem.savedOrder.setLinkedPrice();
            break;
        case PaperPositionItem.PRICE_COL_INDEX:
            tableItem.savedOrder.Price = data.newValue;
            break;
        case PaperPositionItem.STOP_PRICE_COL_INDEX:
            if (tableItem.savedOrder.OrderType === OrderType.TrailingStop) {
                tableItem.savedOrder.StopPrice = OrderUtils.toRawTicks(data.newValue, GeneralSettings.TradingDefaults.ShowOffsetIn, tableItem.savedOrder.Instrument);
            } else {
                tableItem.savedOrder.StopPrice = data.newValue;
            }
            break;
        case PaperPositionItem.TIF_COL_INDEX:
            tableItem.savedOrder.TIF = data.newValue.type;
            tableItem.savedOrder.TIFExpiration = data.newValue.expirationTime;
            break;
        case PaperPositionItem.SL_PRICE_COL_INDEX:
            tableItem.savedOrder.SLTPHolder.StopLossPriceType = SlTpPriceType.Absolute;
            tableItem.savedOrder.SLTPHolder.StopLossPriceValue = data.newValue === 0 ? NaN : data.newValue;
            break;
        case PaperPositionItem.SLL_PRICE_COL_INDEX:
            tableItem.savedOrder.SLTPHolder.StopLossPriceType = SlTpPriceType.Absolute;
            tableItem.savedOrder.SLTPHolder.StopLossLimitPriceValue = data.newValue === 0 ? NaN : data.newValue;
            break;
        case PaperPositionItem.TP_PRICE_COL_INDEX:
            tableItem.savedOrder.SLTPHolder.TakeProfitPriceType = SlTpPriceType.Absolute;
            tableItem.savedOrder.SLTPHolder.TakeProfitPriceValue = data.newValue === 0 ? NaN : data.newValue;
            break;

        case PaperPositionItem.SL_OFFSET_COL_INDEX:
            tableItem.savedOrder.SLTPHolder.StopLossPriceType = SlTpPriceType.Offset;
            tableItem.savedOrder.SLTPHolder.StopLossPriceValue = data.newValue === 0 ? NaN : OrderUtils.toRawTicks(data.newValue, GeneralSettings.TradingDefaults.ShowOffsetIn, tableItem.savedOrder.Instrument);
            break;
        case PaperPositionItem.SLL_OFFSET_COL_INDEX:
            tableItem.savedOrder.SLTPHolder.StopLossPriceType = SlTpPriceType.Offset;
            tableItem.savedOrder.SLTPHolder.StopLossLimitPriceValue = data.newValue === 0 ? NaN : OrderUtils.toRawTicks(data.newValue, GeneralSettings.TradingDefaults.ShowOffsetIn, tableItem.savedOrder.Instrument);
            break;
        case PaperPositionItem.TP_OFFSET_COL_INDEX:
            tableItem.savedOrder.SLTPHolder.TakeProfitPriceType = SlTpPriceType.Offset;
            tableItem.savedOrder.SLTPHolder.TakeProfitPriceValue = data.newValue === 0 ? NaN : OrderUtils.toRawTicks(data.newValue, GeneralSettings.TradingDefaults.ShowOffsetIn, tableItem.savedOrder.Instrument);
            break;
        case PaperPositionItem.LINKED_PRICE_OFFSET_COL_INDEX:
            tableItem.savedOrder.LinkedPriceOffset = OrderUtils.toRawTicks(data.newValue, GeneralSettings.TradingDefaults.ShowOffsetIn, tableItem.savedOrder.Instrument);
            tableItem.savedOrder.setLinkedPrice();
            break;
        case PaperPositionItem.LINK_COL_INDEX:
            tableItem.savedOrder.LinkedPriceType = data.newValue;
            tableItem.savedOrder.setLinkedPrice();
            break;

        case PaperPositionItem.LEVERAGE_COL_INDEX:
            tableItem.savedOrder.Leverage = data.newValue;
            break;

        case PaperPositionItem.SEND_COL_INDEX:
            tableItem.savedOrder.Active = !tableItem.savedOrder.Active;
            break;
        case PaperPositionItem.ANALYSE_COL_INDEX:
            tableItem.savedOrder.Analyse = !tableItem.savedOrder.Analyse;
            break;
        }
        this._optionTrader.updatePaperPosition(tableItem.savedOrder.Instrument);
        this.processEditRow(data.row);
        data.row.FillRowByItem(tableItem, true);
        this.updateButtonsEnability();
        this._quickTable.redraw();
    };

    private processEditRow (row: QuickTableRow): void {
        const savedOrder: PaperPosition = row.item.savedOrder;
        for (let i = 0; i < row.cells.length; i++) {
            switch (i) {
            case PaperPositionItem.STRIKE_COL_INDEX:
                row.cells[i].ReadOnly = !savedOrder.Instrument.isOptionSymbol;
                break;
            case PaperPositionItem.PRICE_COL_INDEX:
                if (savedOrder.LinkedPriceType !== LinkedPriceType.None) {
                    row.cells[i].ReadOnly = true;
                } else {
                    row.cells[i].ReadOnly = savedOrder.OrderType !== OrderType.Limit && savedOrder.OrderType !== OrderType.StopLimit && savedOrder.OrderType !== OrderType.OCO;
                }
                break;
            case PaperPositionItem.STOP_PRICE_COL_INDEX:
                if (savedOrder.LinkedPriceType !== LinkedPriceType.None) {
                    row.cells[i].ReadOnly = true;
                } else {
                    row.cells[i].ReadOnly = savedOrder.OrderType !== OrderType.Stop && savedOrder.OrderType !== OrderType.StopLimit && savedOrder.OrderType !== OrderType.TrailingStop && savedOrder.OrderType !== OrderType.OCO;
                }
                break;
            case PaperPositionItem.LINK_COL_INDEX:
                row.cells[i].ReadOnly = savedOrder.OrderType !== OrderType.Limit && savedOrder.OrderType !== OrderType.Stop;
                break;
            case PaperPositionItem.LINKED_PRICE_OFFSET_COL_INDEX:
                row.cells[i].ReadOnly = savedOrder.LinkedPriceType === LinkedPriceType.None || (savedOrder.OrderType !== OrderType.Limit && savedOrder.OrderType !== OrderType.Stop);
                break;
            case PaperPositionItem.LEVERAGE_COL_INDEX:
                row.cells[i].ReadOnly = !isNullOrUndefined(savedOrder.Instrument) && !savedOrder.Instrument.isLeverageVisible(savedOrder.Account, savedOrder.ProductType);
                break;
            case PaperPositionItem.OPERATION_COL_INDEX:
                row.cells[i].ForeColor = savedOrder.Operation === OperationType.Buy ? ThemeManager.CurrentTheme.TableValueUpForeColor : ThemeManager.CurrentTheme.TableValueDownForeColor;
                break;

            case PaperPositionItem.SL_PRICE_COL_INDEX:
            case PaperPositionItem.SL_OFFSET_COL_INDEX:
                row.cells[i].ReadOnly = isNaN(savedOrder.SlPrice) && !SavedOrderValidator.isAllowSl(savedOrder);
                break;

            case PaperPositionItem.SLL_PRICE_COL_INDEX:
            case PaperPositionItem.SLL_OFFSET_COL_INDEX:
                row.cells[i].ReadOnly = isNaN(savedOrder.SllPrice) && (!SavedOrderValidator.isAllowSl(savedOrder) || !GeneralSettings.TradingDefaults.UseStopLimitInsteadStop);
                break;

            case PaperPositionItem.TP_PRICE_COL_INDEX:
            case PaperPositionItem.TP_OFFSET_COL_INDEX:
                row.cells[i].ReadOnly = isNaN(savedOrder.TpPrice) && !SavedOrderValidator.isAllowTp(savedOrder);
                break;
            }
        }
    }

    private onTableRowButtonClicked (data): void {
        const tableItem: PaperPositionItem = data.row.item;

        switch (data.realColumnIndex) {
        case PaperPositionItem.REMOVE_COL_INDEX:
            this._optionTrader.removePaperPosition(tableItem.savedOrder.Instrument);
            break;
        }
    }

    private async updateButtonsEnability (): Promise<void> {
        const rows = this._quickTable.rowsArray;
        let haveActiveRows = false;
        let isTradingAllowed = true;
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].isGroupRow) {
                continue;
            }
            const item: PaperPositionItem = rows[i].item;
            if (!item.savedOrder.Active) {
                continue;
            }
            haveActiveRows = true;
            isTradingAllowed = IsAllowed.IsTradingAllowed([item.savedOrder.Account], item.savedOrder.Instrument, item.savedOrder.OrderType).Allowed;
            if (!isTradingAllowed) {
                break;
            }
        }
        await super.set('isPlaceSelectedEnabled', haveActiveRows && isTradingAllowed);
        await super.set('isCancelAllEnabled', this._quickTable.rowsArray.length > 0);
    }

    private onAddUnderlierBtnClicked (): void {
        MainWindowManager.Factory?.addPanel(PanelNames.AdvancedOrderEntry, null, this.onOpenOrderEntryPanel);
    }

    private onCancelAllBtnClicked (): void {
        this._optionTrader.clearPaperPositions();
    }

    private async onPlaceSelectedBtnClicked (): Promise<void> {
        const rows: QuickTableRow[] = this._quickTable.rowsArray;
        const ordersForPlace: PaperPosition[] = [];
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].isGroupRow) {
                continue;
            }
            const item: PaperPositionItem = rows[i].item;
            if (item.savedOrder.Active) {
                ordersForPlace.push(item.savedOrder);
            }
        }
        await SavedOrdersController.placeOrders(ordersForPlace);
    }

    private readonly onOpenOrderEntryPanel = (panel: any): void => {
        panel.OnClose.Subscribe(this.onCloseOrderEntryPanel, this);
        panel.set({
            placeButtonAdditionalClass: 'alertStyle',
            placeButtonLocalizationKey: 'panel.newOrderEntry.AddPaperPosition',
            accountVisible: false,
            isEnabledInstrumentLookup: false,
            isEnabledAccountLookup: false,
            isCreateOrderParmeters: true,
            showCustomFullscreenCloud: true,
            account: this._optionTrader.account,
            instrument: this._optionTrader.underlier
        });
        panel.NewParametrsHandler = this.onCreateSavedOrder;
    };

    private readonly onCreateSavedOrder = (orderEdit: OrderEditBase): void => {
        const paperPosition = this._optionTrader.createPaperPositionFromOrderEdit(orderEdit);
        const row = this._quickTable.AddItem(new PaperPositionItem(this._optionTrader, paperPosition, SessionSettings));
        this.processEditRow(row);
        orderEdit.dispose();
    };

    private readonly onCloseOrderEntryPanel = (panel: any): void => {
        panel.OnClose.UnSubscribe(this.onCloseOrderEntryPanel, this);
        panel.NewParametrsHandler = null;
    };
}

ApplicationPanelWithTable.extendWith(OptionPaperPositionsPanel, {
    template: OptionPaperPositionsPanelTemplate,
    data: function () {
        return {
            addUnderlierButtonText: '',
            cancelAllButtonText: '',
            placeSelectedButtonText: '',
            isPlaceSelectedEnabled: false,
            isCancelAllEnabled: false
        };
    }
});
