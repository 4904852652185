// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectMarginCallMessage } from '../../../../Utils/DirectMessages/DirectMarginCallMessage';
import { generateReportMessageHandler } from '../../../../Utils/AppHandlers.js';
import { type DirectReportMessage } from '../../../../Utils/DirectMessages/DirectMessagesImport';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class MarginCallMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): DirectReportMessage[] {
        const msg = new DirectMarginCallMessage();

        msg.UserId = fieldSet.GetValue(FieldsFactory.FIELD_ACCOUNT_ID);
        msg.AssetId = fieldSet.GetValue(FieldsFactory.FIELD_ASSET_ID);
        msg.StopOutType = fieldSet.GetValue(FieldsFactory.FIELD_STOPOUT_TYPE);
        msg.Balance = fieldSet.GetValue(FieldsFactory.FIELD_BALANCE);
        msg.UsedMargin = fieldSet.GetValue(FieldsFactory.FIELD_USED_MARGIN);
        msg.IsMarginCall = fieldSet.GetValue(FieldsFactory.FIELD_IS_MARGINCALL);

        return [generateReportMessageHandler.GenerateReportMessage(msg)]; // [msg];
    }
}
