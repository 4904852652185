import { DataCache } from '../DataCache';
import { type Account } from '../cache/Account';
import { AccountMenuItem } from './AccountMenuItem';

class _AccountMenuItemsHelper {
    public GetDataToFillOut (selectedAccount: Account): AccountMenuItemsHelperResult {
        const accItems: AccountMenuItem[] = [];
        const accounts = DataCache.getAccounts();
        let isAddType = false;
        let accCounter = 0;
        const arrAccounts: string[] = [];
        const mapAccounts: object = {};
        for (const accID in accounts) {
            const account: Account = accounts[accID];
            const accMenuItem = new AccountMenuItem(account);
            accMenuItem.Selected = account === selectedAccount;
            const accUserLogin = account.userLogin;
            accItems.push(accMenuItem);
            if (isNullOrUndefined(mapAccounts[accUserLogin])) {
                mapAccounts[accUserLogin] = accUserLogin;
                arrAccounts.push(accUserLogin);
            }
            isAddType = isAddType || (account.PreferredInstrumentType !== -1);
            accCounter++;
        }

        return new AccountMenuItemsHelperResult(accItems, isAddType, arrAccounts.length === 1, accCounter === 1);
    }
}

export const AccountMenuItemsHelper = new _AccountMenuItemsHelper();

export class AccountMenuItemsHelperResult {
    public Items: AccountMenuItem[] = [];
    public IsAddType: boolean = false;
    public IsOnlyOwnAccounts: boolean = false;
    public IsSingleAccount: boolean = false;

    constructor (Items: AccountMenuItem[], IsAddType: boolean, IsOnlyOwnAccounts: boolean, IsSingleAccount: boolean) {
        this.Items = Items;
        this.IsAddType = IsAddType;
        this.IsOnlyOwnAccounts = IsOnlyOwnAccounts;
        this.IsSingleAccount = IsSingleAccount;
    }
}
