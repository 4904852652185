// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { OEProductTypeSelectorTemplate } from "../../../templates.js";
import { ContainerControl } from "../../elements/ContainerControl.js";
import { Resources } from "../../../Commons/properties/Resources.ts";
import { ProductType } from "../../../Utils/Instruments/ProductType.ts";
import { GeneralSettings } from "../../../Utils/GeneralSettings/GeneralSettings.ts";
import { InstrumentUtils } from "../../../Utils/Instruments/InstrumentUtils.ts";

export let OEProductTypeSelector = ContainerControl.extend({
    template: OEProductTypeSelectorTemplate,
    data: function ()
    {
        return {
            label: '',

            instrument: null,
            account: null,

            items: null,
            selectedItem: null,
            defaultValue: null,

            productType: null,
            showLastValue: false,

            visible: false,
            enabled: true,

            showLabel: true,

            width: 198,
            tooltip: 'OEProductTypeSelector.tooltip'
        }
    }
})

OEProductTypeSelector.prototype.getType = function () { return 'OEProductTypeSelector' };

OEProductTypeSelector.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.call(this)
    this.observe('selectedItem', this.updateProductType)
    this.observe('productType', this.onProductTypeChange)
    this.observe('instrument account', this.repopulate)
    Resources.onLocaleChange.Subscribe(this.localize, this)
    this.localize()
}

OEProductTypeSelector.prototype.dispose = function ()
{
    Resources.onLocaleChange.UnSubscribe(this.localize, this)

    ContainerControl.prototype.dispose.call(this)
}

OEProductTypeSelector.prototype.localize = function ()
{
    this.set('label', Resources.getResource('panel.newOrderEntry.ProductType'))
}

OEProductTypeSelector.prototype.updateProductType = function (selectedItem)
{
    this.set('productType', selectedItem ? selectedItem.value : null)
}

OEProductTypeSelector.prototype.onProductTypeChange = function (value)
{
    let selItem = this.get('selectedItem'),
        items = this.get('items')

    if (items && selItem && selItem.value != value)
        for (let i = 0; i < items.length; i++)
            if (items[i].value == value)
                this.set('selectedItem', items[i])
}

OEProductTypeSelector.prototype.repopulate = function ()
{
    let instrument = this.get('instrument')
    let account = this.get('account')
    let insID = instrument ? instrument.GetInteriorID() : null
    let dict = account && account.RiskPlan ? account.RiskPlan.GetRisksForInstrument(insID).availableProductTypes : InstrumentUtils.getAllowedProductTypeDict(instrument)

    if (!instrument || !account || instrument.IsEmpty || !dict.length)
    {
        this.set('visible', false)
        return
    }

    let items = dict.map(function (productType)
    {
        return {
            value: productType,
            text: InstrumentUtils.GetLocalizedProductType(instrument, productType)
        }
    })

    let visible = (items[0].value == ProductType.General) ? false : true,           // при ProductType.General скрываем комбобокс
        enabled = (items.length === 1) ? false : true                               // при единственном ProductType делаем комбобокс enabled

    this.set({
        defaultValue: GeneralSettings.TradingDefaults.ProductType ? GeneralSettings.TradingDefaults.ProductType.toString() : items[0].value,
        items: items,
        visible: visible,
        enabled: enabled
    })
}