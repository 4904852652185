// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from "../../Utils/CustomEvents.ts";
import { HtmlScroll } from "../../Commons/HtmlScroll.js";
import { TerceraCheckBoxGroupTemplate } from "../../templates.js";
import { ContainerControl } from "./ContainerControl.js";

export let TerceraCheckBoxGroup = ContainerControl.extend({
    data: function ()
    {
        return {
            width: 198,
            height: 50,
            dataArray: null
        };
    },
    template: TerceraCheckBoxGroupTemplate
});

TerceraCheckBoxGroup.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.call(this);
    this.DataArrayChanged = new CustomEvent();
    this.observe('dataArray', this.onDataArrayChanged);
};

TerceraCheckBoxGroup.prototype.oncomplete = function ()
{
    ContainerControl.prototype.oncomplete.call(this);
    HtmlScroll.addScroll(this.find('ul'));
};

TerceraCheckBoxGroup.prototype.dispose = function ()
{
    HtmlScroll.removeScroll(this.find('ul'));
    ContainerControl.prototype.dispose.call(this);
};

TerceraCheckBoxGroup.prototype.onDataArrayChanged = function (newVal)
{
    this.DataArrayChanged.Raise(this, newVal);
};