// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
export let ru =
{
    "AdditionalProperty.Account": "Счет",
    "accountDetailsWidget.DropdownButton": "",
    "accountDetailsWidget.DropdownButton.Tooltip": "",
    "additional.verification": "Требуется дополнительная верификация пароля",
    "AdditionalProperty.Message": "Сообщение",
    "AdditionalProperty.Position closed because the loss limit was reached": "Позиция {0} будет закрыта по причине достижения допустимого лимита потери",
    "AdditionalProperty.Reverse": "Реверс",
    "Alligator": "",
    "Average Directional Index": "",
    "Average True Range": "",
    "Bollinger Band": "",
    "Bollinger Bands": "",
    "bonds.couponCycle.1m": "",
    "bonds.couponCycle.1y": "",
    "bonds.couponCycle.3m": "",
    "bonds.couponCycle.6m": "",
    "BrokerMessage.Cancel": "Отмена",
    "BrokerMessage.OK": "ОК",
    "BrokerMessage.Text": "Сообщение брокера",
    "BusinessRejectMessage.28": "Неверный текущий пароль",
    "BusinessRejectMessage.436": "Ошибка подписки на продукт {0}: невозможно подписаться поскольку нет активных учетных счетов для взимания платы за использование продукта.",
    "BusinessRejectMessage.437": "Ошибка подписки на продукт {0}: недостаточно средств для оплаты комиссии.",
    "BusinessRejectMessage.453": "Ордер не может быть создан с отрицательной ценой.",
    "Chande Momentum Oscillator": "",
    "chart.agregationType.Button.ToolTip": "Использовать предопределенные типы агрегации",
    "chart.AllowedPeriods.15M": "15м",
    "chart.AllowedPeriods.1D": "1д",
    "chart.AllowedPeriods.1H": "1 ч",
    "chart.AllowedPeriods.1M": "1м",
    "chart.AllowedPeriods.1S": "1с",
    "chart.AllowedPeriods.1T": "1 тик",
    "chart.AllowedPeriods.1W": "1н",
    "chart.AllowedPeriods.1Y": "1г",
    "chart.AllowedPeriods.30M": "30м",
    "chart.AllowedPeriods.4H": "4ч",
    "chart.AllowedPeriods.5M": "5м",
    "chart.AllowedPeriods.Month": "1Месяц",
    "chart.AllowedPeriods.Range": "Период",
    "chart.AllowedPeriods.Ticks": "Тики",
    "chart.ChartPanel.accountLookup.ToolTip": "Выберите счет",
    "chart.ChartPanel.symbolLookup.ToolTip": "Выберите инструмент",
    "chart.chartScroll.toBegin": "К послед. котировке",
    "chart.chartScroll.zoomInButton": "Увеличить",
    "chart.chartScroll.zoomOutButton": "Уменьшить",
    "chart.ChartSymbolSelector.addIndicator.ButtonToolTip": "Добавить индикатор",
    "chart.ChartSymbolSelector.drawingButton.ToolTip": "Показать/Скрыть панель инструментов рисования",
    "chart.ChartSymbolSelector.mouseTradeButton.ToolTip": "Торговля с помощью мыши",
    "chart.ChartSymbolSelector.oeButton": "Создать ордер",
    "chart.ChartSymbolSelector.oeButton.ToolTip": "Торговля из графика",
    "chart.ChartSymbolSelector.OverlayTooltip": "Добавить наложение",
    "Chart.chartWindow.auto": "Авто",
    "Chart.chartWindow.collapsed": "Свернуто",
    "Chart.chartWindow.manual": "Ручное",
    "chart.chartWindowManager.SetAuto": "Переключить на автомасштабирование",
    "chart.chartWindowManager.SetManual": "Переключить на ручное масштабирование",
    "chart.head.addOverlay": "Добавить наложение...",
    "chart.head.charttype": "Стиль",
    "chart.head.line": "Инструменты рисования",
    "chart.head.overlayRemoveAll": "Удалить все",
    "chart.head.overlays": "Наложения",
    "chart.indicator": "Индикатор",
    "chart.indicatorAdd": "Добавить индикатор",
    "chart.indicatorRemoveAll": "Удалить все",
    "chart.indicators": "Индикаторы",
    "chart.Infoline.days": "дней",
    "chart.Infoline.hours": "часов",
    "chart.Infoline.min": "мин",
    "chart.Infoline.price": "цена",
    "chart.Infoline.sec": "сек",
    "chart.Infoline.time": "время",
    "chart.infoWindow.Items.Additionalinfo": "Доп. инфо",
    "chart.infoWindow.Items.Ask": "Аск:",
    "chart.infoWindow.Items.Bars": "Бар №:",
    "chart.infoWindow.Items.Bid": "Бид:",
    "chart.infoWindow.Items.Change": "Изменение,%",
    "chart.infoWindow.Items.Close": "Закрытие:",
    "chart.infoWindow.Items.Date": "Дата:",
    "chart.infoWindow.Items.High": "Макс.:",
    "chart.infoWindow.Items.Low": "Мин.:",
    "chart.infoWindow.Items.Open": "Открытие:",
    "chart.infoWindow.Items.Ticks": "Тики:",
    "chart.infoWindow.Items.Time": "Время:",
    "chart.infoWindow.Items.Volume": "Объем:",
    "chart.instrumentsLookup": "Поиск инструментов",
    "chart.layerManager": "Настройки...",
    "chart.loading": "Загрузка...",
    "chart.loadingData": "Загрузка данных для",
    "chart.mainlayer.gridPrice": "Сетка по цене",
    "chart.mainlayer.gridTime": "Сетка по времени",
    "chart.mainlayer.volume": "Бары объемов",
    "chart.menu.cancelByAccount": "Отменить все по счету",
    "chart.menu.cancelByInstrument": "Отменить все по инструменту",
    "chart.menu.cancelOrder": "Отменить ордер",
    "chart.menu.close": "Закрыть позицию",
    "chart.menu.closeall": "Закрыть все позиции...",
    "chart.menu.InfoWindowDock": "Стыковать",
    "chart.menu.InfoWindowHide": "Скрыть",
    "chart.menu.InfoWindowSettings": "Настройки",
    "chart.menu.InfoWindowShortMode": "Коротк. режим",
    "chart.menu.modify": "Редактировать",
    "chart.menu.PlateIndicatorDublicate": "Дублировать индикатор",
    "chart.menu.PlateIndicatorRemove": "Удалить индикатор",
    "chart.menu.PlateIndicatorSettings": "Настройки индикатора",
    "chart.menu.PlateIndicatorVisible": "Видимый",
    "chart.menu.PlateOverlay.Overlay": "Наложение",
    "chart.menu.PlateOverlayRemove": "Удалить наложение",
    "chart.menu.PlateOverlaySettings": "Настройки наложения",
    "chart.menu.PlateOverlayVisible": "Видимый",
    "chart.menu.rollback": "Вернуть позицию",
    "chart.noAvailableData": "Нет доступных данных для",
    "chart.NoData": "Нет данных",
    "chart.priceScaleContextMenu.AutoScale": "Авто масштабирование",
    "chart.priceScaleContextMenu.FitDay": "Вписывать Макс./Мин. за день",
    "chart.priceScaleContextMenu.FitDrawings": "Вписывать рисунки",
    "chart.priceScaleContextMenu.FitIndicators": "Вписывать индикаторы",
    "chart.priceScaleContextMenu.FitOrdersPositions": "Вписывать ордера/позиции",
    "chart.priceScaleContextMenu.ManualScale": "Ручное масштабирование",
    "chart.refresh": "Обновить",
    "chart.Remove": "Удалить",
    "chart.shortPeriodName.m": "м",
    "chart.shortPeriodName.ticks": "тик",
    "chart.ShowHighLow.Close": "Закр.",
    "chart.ShowHighLow.High": "Макс.",
    "chart.ShowHighLow.Low": "Мин.",
    "chart.StyleButton.ToolTip": "Стиль",
    "chart.templates.tooltip": "Шаблоны",
    "chart.tic.short.15min.name": "15м",
    "chart.tic.short.1day.name": "1д",
    "chart.tic.short.1hour.name": "1ч",
    "chart.tic.short.1min.name": "1м",
    "chart.tic.short.1tic.name": "Тик",
    "chart.tic.short.30min.name": "30м",
    "chart.tic.short.4hour.name": "4ч",
    "chart.tic.short.5min.name": "5м",
    "chart.tic.short.month.name": "Мес",
    "chart.tic.short.range.name": "Диапазон",
    "chart.tic.short.second.name": "cек",
    "chart.tic.short.week.name": "1нед",
    "chart.tic.short.year.name": "Год",
    "chart.timeframeselector.rangeButton.ToolTip": "Выберите период отображения данных",
    "chart.timeframeselector.time": "Время",
    "chart.timeScaleContextMenu.ShowAllSessions": "Показать дополнительные сессии",
    "chart.timeScaleContextMenu.ShowDaySeparator": "Показать дневной разделитель",
    "chart.timeScaleContextMenu.ShowEmptyBars": "Показывать промежутки в истории",
    "chart.timeScaleContextMenu.ShowMonthSeparator": "Показать месячный разделитель",
    "chart.timeScaleContextMenu.ShowTimePeriodsSeparator": "Показать разделители врем. периодов",
    "chart.timeScaleContextMenu.ShowWeeksSeparator": "Показать неделный разделитель",
    "chart.timeScaleContextMenu.ShowYearSeparator": "Показать годовой разделитель",
    "chart.tool.bar": "Бар",
    "chart.tool.candle": "Свеча",
    "chart.tool.clone": "Клонировать",
    "chart.tool.Dash": "Штрих",
    "chart.tool.delete": "Удалить",
    "chart.tool.deleteAll": "Удалить все",
    "chart.tool.dot": "Точки",
    "chart.tool.dotline": "Пунктир",
    "chart.tool.DottedLine": "Пунктир",
    "chart.tool.forest": "Гистограмма",
    "chart.tool.IsoDot": "Точка",
    "chart.tool.line": "Линия",
    "chart.tool.properties": "Настройки",
    "chart.tool.ShapedLine": "Тире",
    "chart.tool.solid": "Область",
    "chart.tools.ABCPatern": "ABC паттерн",
    "chart.tools.AndrewPitchfork": "Вилы Эндрюса",
    "chart.tools.backcolor": "Цвет фона",
    "chart.tools.ButterflyGartley": "Бабочка Гартли",
    "chart.tools.Circle": "Круг",
    "chart.tools.default": "Стрелка",
    "chart.tools.ElliottImpulse": "Импульсная волна Эллиотта (12345)",
    "chart.tools.ElliottCorrection": "Коррекционная волна Эллиотта (ABC)",
    "chart.tools.ElliottTriangle": "Боковая волна (ABCDE)",
    "chart.tools.ElliottTripleCombo": "Тройная комбинация Эллиотта (WXYXZ)",
    "chart.tools.ElliottDoubleCombo": "Двойная комбинация Эллиотта (WXY)",
    "chart.tools.Ellipse": "Овал",
    "chart.tools.eraser": "Ластик",
    "chart.tools.FibArc": "Дуги Фибоначчи",
    "chart.tools.FibEllipses": "Эллипсы Фибоначчи",
    "chart.tools.FibExp": "Расширение Фибоначчи",
    "chart.tools.FibFan": "Веер Фибоначчи",
    "chart.tools.FibPhiChannel": "Фи-канал Фибоначчи",
    "chart.tools.Fibretr": "Коррекционные уровни Фибоначчи",
    "chart.tools.FibSpiral": "Спираль Фибоначчи",
    "chart.tools.FibTga": "Временные цели Фибоначчи",
    "chart.tools.FibTz": "Временные интервалы  Фибоначчи",
    "chart.tools.GannFan": "Веер Ганна",
    "chart.tools.GannGrid": "Сетка Ганна",
    "chart.tools.GannLine": "Линия Ганна",
    "chart.tools.horizontal": "Горизонтальная линия",
    "chart.tools.infoline": "Линейка",
    "chart.tools.Line": "Линия",
    "chart.tools.mirrored": "Зеркально",
    "chart.tools.points": "Счетчик точек",
    "chart.tools.Polygon": "Многоугольник",
    "chart.tools.PPZ": "PPZ (Зона реакции цены)",
    "chart.tools.PriceChannel": "Канал цен",
    "chart.tools.Rectangle": "Прямоугольник",
    "chart.tools.RegressionChanel": "Канал регрессии",
    "chart.tools.ScalingByRect": "Масштабирование прямоугольником",
    "chart.tools.selection": "Выделение",
    "chart.tools.snapToCandle": "Привязка к графику",
    "chart.tools.StayDrawingMode": "Оставаться в режиме рисования",
    "chart.tools.symbol": "Метка",
    "chart.tools.text": "Комментарий",
    "chart.tools.TopBottomPattern": "Паттерн Вершина-Дно",
    "chart.tools.Triangle": "Треугольник",
    "chart.tools.vertical": "Вертикальная линия",
    "chart.tools.Fibretr.deleteByType": "Все коррекционные уровни Фибоначчи",
    "chart.tools.vertical.deleteByType": "Все вертикальные линии",
    "chart.tools.FibFan.deleteByType": "Все веера Фибоначчи",
    "chart.tools.PriceChannel.deleteByType": "Все ценовые каналы",
    "chart.tools.symbol.deleteByType": "Все инструменты",
    "chart.tools.GannGrid.deleteByType": "Все сетки Ганна",
    "chart.tools.infoline.deleteByType": "Все линейки",
    "chart.tools.ABCPatern.deleteByType": "Все ABC паттерны",
    "chart.tools.FibArc.deleteByType": "Все дуги Фибоначчи",
    "chart.tools.Triangle.deleteByType": "Все треугольники",
    "chart.tools.GannFan.deleteByType": "Все веера Ганна",
    "chart.tools.ButterflyGartley.deleteByType": "Все бабочки Гартли",
    "chart.tools.Rectangle.deleteByType": "Все прямоугольники",
    "chart.tools.Polygon.deleteByType": "Все многоугольники",
    "chart.tools.FibPhiChannel.deleteByType": "Все фи-каналы Фибоначчи",
    "chart.tools.FibTz.deleteByType": "Все временные интервалы Фибоначчи",
    "chart.tools.GannLine.deleteByType": "Все линии Ганна",
    "chart.tools.AndrewPitchfork.deleteByType": "Все вилы Эндрюса",
    "chart.tools.FibSpiral.deleteByType": "Все спирали Фибоначчи",
    "chart.tools.VolumeProfile.deleteByType": "Все польз. профили объемов",
    "chart.tools.FibExp.deleteByType": "Все расширения Фибоначчи",
    "chart.tools.RegressionChanel.deleteByType": "Все каналы регрессии",
    "chart.tools.Line.deleteByType": "Все линии",
    "chart.tools.FibEllipses.deleteByType": "Все эллипсы Фибоначчи",
    "chart.tools.FibTga.deleteByType": "Все временные цели анализа Фибоначчи",
    "chart.tools.Circle.deleteByType": "Все круги",
    "chart.tools.TopBottomPattern.deleteByType": "Все паттерны Вершина-Дно",
    "chart.tools.horizontal.deleteByType": "Все горизонтальные линии",
    "chart.tools.text.deleteByType": "Весь текст",
    "chart.tools.PPZ.deleteByType": "Все PPZ",
    "chart.tools.Ellipse.deleteByType": "Все овалы",
    "chart.view": "Вид",
    "chart.view.analyseGroupMenu": "Инструменты анализа",
    "chart.view.analysetoolbar": "Панель инструм. для анализа",
    "chart.view.barsToRight": "Отступ справа...",
    "chart.view.colors": "Настройки",
    "chart.view.dataInfo": "Информационное окно",
    "chart.view.Day_HighLow": "Дневной Макс./Мин.",
    "chart.view.drawingtoolbar": "Меню рисования",
    "chart.view.font": "Шрифт осей",
    "chart.view.landscapequestion": "Расположить лист горизонтально",
    "chart.view.merge": "Непрерывные линии",
    "chart.view.orderEntry": "Торговля из графика",
    "chart.view.periods": "Периоды...",
    "chart.view.previousCloseLine": "Линия предыдущего закрытия",
    "chart.view.PrevSettlementPriceLine": "Prev. settlement price",
    "chart.view.scroller": "Прокрутка",
    "chart.view.setOrder": "Установить ордер",
    "chart.view.SettlementPriceLine": "Settlement price",
    "chart.view.show_cross": "Отслеживать курсор",
    "chart.view.show_history": "Отображать торговую историю",
    "chart.view.show_set_zero": "Показывать пустые бары",
    "chart.view.showEvents": "Показывать сделки",
    "chart.view.showNews": "Показывать новости",
    "chart.view.showOrders": "Показывать ордера",
    "chart.view.showPositions": "Показывать позиции",
    "chart.view.source": "Тип данных",
    "chart.view.source0": "По инструменту",
    "chart.view.source1": "Бид",
    "chart.view.source1000": "Аск",
    "chart.view.source1001": "По усредненной цене",
    "chart.view.source1002": "Мин(Бид) и Макс(Аск)",
    "chart.view.source4": "По сделкам",
    "chart.view.sourceBID/ASK": "По Бид/Аск",
    "chart.view.sourceTick1": "По Бид/Аск",
    "chart.view.TimeToNextBar": "Время до следующего бара",
    "chart.view.toolbar": "Панель инструментов",
    "chart.view.volume": "Показать объемы",
    "chart.visualTrading.Invalid price": "Неверная цена",
    "chart.volumeColoringMode.ByBar": "По барам",
    "chart.volumeColoringMode.ByDifference": "По разнице",
    "chart.volumeColoringMode.Fixed": "Фиксированная",
    "chart.volumeColoringMode.ByMovingAverage": "Выше/ниже скользящей средней",
    "chart.quickTrading.Invalid price": "неверная цена",
    "chart.quickTrading.Buy stop": "Купить Стоп",
    "chart.quickTrading.Buy stop limit": "Купить Стоп лимит",
    "chart.quickTrading.Buy limit": "Купить Лимит",
    "chart.quickTrading.Sell stop": "Продать Стоп",
    "chart.quickTrading.Sell stop limit": "Продать Стоп лимит",
    "chart.quickTrading.Sell limit": "Продать Лимит",
    "Commodity Channel Index": "",
    "connect.connection.connect.error": "Неправильные парам. соединения",
    "connect.connection.unknown.error": "Неизвестная ошибка!",
    "Custom": "",
    "dataSourceWidget.btnText": "Источник данных",
    "dataSourceWidget.openedBtn.tt": "Скрыть источник данных",
    "dataSourceWidget.closedBtn.tt": "Показать источник данных",
    "dataSourceWidget.delayed.tt": "Задержанные данные",
    "dataSourceWidget.disabled.tt": "Данные источника данных не разрешены для этого инструмента",
    "dataSourceWidget.disabledWhenOnlyTradeRoute.tt": "Данные источника данных не разрешены для торгового маршрута",
    "dataSourceWidget.noData.text": "Нет данных для отображения",
    "dataSourceWidget.price": "Цена",
    "dataSourceWidget.size": "Размер",
    "dataSourceWidget.venue": "Источник",
    "dataSourceWidget.last": "Последняя",
    "dataSourceWidget.bid": "Бид",
    "dataSourceWidget.ask": "Аск",
    "dayTimeLabel.days": "дней",
    "dayTimeLabel.hours": "часов",
    "dayTimeLabel.minutes": "минут",
    "dayTimeLabel.seconds": "секунд",
    "dayTimeLabel.day": "день",
    "dayTimeLabel.hour": "час",
    "dayTimeLabel.minute": "минута",
    "dayTimeLabel.second": "секунда",
    "dayTimeLabel.lifeTime": "Срок принятия идеи",
    "dayTimeLabel.lifeTimeExpired": "Срок принятия идеи истёк",
    "Day": "Дневной",
    "dealticket.RiskRuleWarning.Header": "Предупреждение по рискам",
    "dealticket.RiskRuleWarning.AccountName": "Счет",
    "dealticket.RiskRuleWarning.UserName": "Пользователь",
    "Deutsch": "",
    "enum.AccountType.ClosedFund": "",
    "enum.AccountType.MultiAsset": "",
    "enum.AccountType.OpenedFund": "",
    "enum.AccountType.SingleCCY": "",
    "enum.AssetType.COMMODITIES": "",
    "enum.AssetType.CRYPTO_CCY": "",
    "enum.AssetType.CURRENCY": "",
    "enum.AssetType.SHARES": "",
    "editableComboBox.CreateNew": "Создать новый",
    "editableComboBox.CreateNewTemplate": "Создать шаблон",
    "editableComboBox.CreateNew.tooltip": "Кликните для создания нового списка инструментов",
    "editableComboBox.CreateNewTemplate.tooltip": "Кликните чтобы создать новый шаблон",
    "editableComboBox.defaultList": "Список по умолчанию",
    "editableComboBox.tooltip": "Сохраненные списки",
    "ExchangeId.All": "Все биржи",
    "Exponential Moving Average": "",
    "Externalpanels.MultiTimeAndSales.Header": "",
    "FOK": "FOK",
    "FullLogs.Cluster": "",
    "FullLogs.DateTime": "",
    "FullLogs.Fields": "",
    "FullLogs.Message name": "",
    "FullLogs.Session": "",
    "general.Instrument": "Инструмент",
    "general.Calculating": "",
    "general.connection.restErrorMsg": "",
    "general.CUSTOM": "Пользовательский",
    "general.Friday": "Пятница",
    "general.messageBox.cancel": "Отмена",
    "general.messageBox.no": "Нет",
    "general.messageBox.ok": "ОК",
    "general.messageBox.showNextTime": "Показывать далее",
    "general.messageBox.yes": "Да",
    "general.Monday": "Понедельник",
    "general.day1.Mo": "",
    "general.day2.Tu": "",
    "general.day3.We": "",
    "general.day4.Th": "",
    "general.day5.Fr": "",
    "general.day6.Sa": "",
    "general.day7.Su": "",
    "general.month.1": "Янв",
    "general.month.10": "Окт",
    "general.month.11": "Ноя",
    "general.month.12": "Дек",
    "general.month.2": "Фев",
    "general.month.3": "Мар",
    "general.month.4": "Апр",
    "general.month.5": "Май",
    "general.month.6": "Июн",
    "general.month.7": "Июл",
    "general.month.8": "Авг",
    "general.month.9": "Сен",
    "general.monthFullName.1": "Январь",
    "general.monthFullName.10": "Октябрь",
    "general.monthFullName.11": "Ноябрь",
    "general.monthFullName.12": "Декабрь",
    "general.monthFullName.2": "Февраль",
    "general.monthFullName.3": "Март",
    "general.monthFullName.4": "Апрель",
    "general.monthFullName.5": "Май",
    "general.monthFullName.6": "Июнь",
    "general.monthFullName.7": "Июль",
    "general.monthFullName.8": "Август",
    "general.monthFullName.9": "Сентябрь",
    "general.N_A": "Н/Д",
    "general.NoData...": "Нет данных...",
    "general.NoDataMatchingYourCriteria": "Нет данных, соответствующих вашим критериям",
    "general.OvernightMarginNotificationMessage": "Уведомление о марже при переходе через ночь",
    "general.Saturday": "Суббота",
    "general.Settings": "Настройки",
    "general.Sunday": "Воскресенье",
    "general.Thursday": "Четверг",
    "general.trading.at": "по цене",
    "general.trading.Buy": "Купить",
    "general.trading.confirmation": "Подтверждение",
    "general.trading.confirmation.prefix": "",
    "general.trading.confirmationString": "{1} {2} {3} {4} {5} {6} {7} {8} {9} {10} {14} {11} {12} {13}",
    "general.trading.for": "по счету",
    "general.trading.limitBuyLessAsk": "Цена Лимит ордера на покупку выше чем рыночная цена Аск, ордер будет мгновенно заполнен.",
    "general.trading.limitSellMoreBid": "Цена Лимит ордера на продажу ниже чем рыночная цена Бид, ордер будет мгновенно заполнен.",
    "general.trading.limitBuyLessAllowableFromLast": "Цена Лимит ордера ниже допустимого % от Последней цены {1}, Вы уверены в подаче ордера?",
    "general.trading.limitBuyLessAllowableFromBase": "Цена Лимит ордера ниже допустимого % от Базовой цены {1}, Вы уверены в подаче ордера?",
    "general.trading.limitSellMoreAllowableFromLast": "Цена Лимит ордера выше допустимого % от Последней цены {1}, Вы уверены в подаче ордера?",
    "general.trading.limitSellMoreAllowableFromBase": "Цена Лимит ордера выше допустимого % от Базовой цены {1}, Вы уверены в подаче ордера?",
    "general.trading.marketCloseFor": "Рынок закрыт для",
    "general.trading.pips": "тики",
    "general.trading.points": "пункты",
    "general.trading.position": "Позиция",
    "general.trading.position.Buy": "Длинная",
    "general.trading.position.Sell": "Короткая",
    "general.trading.priceNotAvailable": "Не доступен",
    "general.trading.refused": "Отказано",
    "general.trading.Sell": "Продать",
    "general.trading.stopBuyMoreAsk": "Цена Стоп ордера на покупку ниже чем рыночная цена Аск, ордер будет мгновенно заполнен.",
    "general.trading.stopSellLessBid": "Цена Стоп ордера на продажу выше чем рыночная цена Бид, ордер будет мгновенно заполнен.",
    "general.trading.with offset": "со смещением",
    "general.Tuesday": "Вторник",
    "property.general.WarnIfWrongOrderParam": "",
    "property.general.WarnIfQtyToCloseMoreThanMaxLot": "Если объем к закрытию больше Макс лота",
    "general.Wednesday": "Среда",
    "GTC": "GTC",
    "helpPage.link": "",
    "homePage.link": "",
    "Ichimoku": "",
    "Instrument.Futures.contract": "",
    "Instrument.Level1.ExtendedFields.NormalMarketSize": "",
    "Instrument.Spreadbet.bet": "",
    "Instrument.ExerciseStyle.EUROPEAN": "Европейский",
    "Instrument.ExerciseStyle.AMERICAN": "Американский",
    "InstrumentDetailsPanel.(Indicative symbol)": "(Индикативный инструмент)",
    "InstrumentDetailsPanel.1.GeneralInfo": "Общая информация",
    "InstrumentDetailsPanel.2.TradingInfo": "Торговля",
    "InstrumentDetailsPanel.3.MarginReg": "Маржинальные требования",
    "InstrumentDetailsPanel.4.Fees": "Комиссия",
    "InstrumentDetailsPanel.5.Rebates": "Выплаты",
    "InstrumentDetailsPanel.6.SessionInfo": "Информация по сессиям",
    "InstrumentDetailsPanel.7.Plans": "Планы",
    "InstrumentDetailsPanel.AccruedInterest": "Начисление по процентам",
    "InstrumentDetailsPanel.AddLiqudityRebateOrderPerLot": "Add liquidity rebate order per lot",
    "InstrumentDetailsPanel.AddLiqudityRebatePerLot": "За добавление ликвидности, за лот",
    "InstrumentDetailsPanel.AddLiqudityRebatePerVolume": "За добавление ликвидности, за объем",
    "InstrumentDetailsPanel.AFTER_MARKET": "After market",
    "InstrumentDetailsPanel.Allow short positions": "Да",
    "InstrumentDetailsPanel.AllowedOperations": "Допустимые операции",
    "InstrumentDetailsPanel.AllowedOrderTypes": "Допустимые типы ордеров",
    "InstrumentDetailsPanel.AllowShortPositions": "Разрешить короткие позиции",
    "InstrumentDetailsPanel.Amount": "Обм.",
    "InstrumentDetailsPanel.AssetClass": "Тип инструмента",
    "InstrumentDetailsPanel.BEFORE_MARKET": "Before market",
    "InstrumentDetailsPanel.Cash": "Расчетная",
    "InstrumentDetailsPanel.Close": "Закрытие",
    "InstrumentDetailsPanel.Closed": "закрыта",
    "InstrumentDetailsPanel.CloseOutDeadline": "Дата принудительного закрытия",
    "InstrumentDetailsPanel.Commisions": "Комиссии",
    "InstrumentDetailsPanel.ContactMonth": "Дата исполнения контракта",
    "InstrumentDetailsPanel.ContractMultiplier": "#hidden#",
    "InstrumentDetailsPanel.ContractSize": "Размер контракта",
    "InstrumentDetailsPanel.CouponCycle": "Период выплаты дохода",
    "InstrumentDetailsPanel.CouponRate": "Купонная ставка, %",
    "InstrumentDetailsPanel.CurrentSession": "Текущая сессия",
    "InstrumentDetailsPanel.DeliveryMethod": "Тип поставки",
    "InstrumentDetailsPanel.DeliveryStatus": "Статус поставки",
    "InstrumentDetailsPanel.Depend from account": "Зависит от счета",
    "InstrumentDetailsPanel.Description": "Описание",
    "InstrumentDetailsPanel.Exchange": "Биржа",
    "InstrumentDetailsPanel.ExchangeTradeSession": "#hidden#",
    "InstrumentDetailsPanel.ExerciseStyle": "Стиль исполнения",
    "InstrumentDetailsPanel.ExerciseStyle.descr": "Стиль исполнения опциона",
    "InstrumentDetailsPanel.Exp1": "Актив",
    "InstrumentDetailsPanel.FaceValue": "Номинальная стоимость",
    "InstrumentDetailsPanel.FirstTradeDate": "Дата первой сделки",
    "InstrumentDetailsPanel.Futures": "Фьючерсы",
    "InstrumentDetailsPanel.FuturesClass": "Фьючерс",
    "InstrumentDetailsPanel.HiLimit": "Верхний лимит",
    "InstrumentDetailsPanel.Holiday": "Праздник",
    "InstrumentDetailsPanel.HolidayDate": "Дата",
    "InstrumentDetailsPanel.HolidayName": "Название праздника",
    "InstrumentDetailsPanel.Holidays list": "Список праздников",
    "InstrumentDetailsPanel.HolidayType": "График торговли",
    "InstrumentDetailsPanel.immediate": "немедленно",
    "InstrumentDetailsPanel.KEYNextHoliday": "Ближайший праздник",
    "InstrumentDetailsPanel.LastTradeDate": "Дата последней сделки",
    "InstrumentDetailsPanel.LiquidationOnly": "Запрещено открывать позиции",
    "InstrumentDetailsPanel.LotSize": "Размер лота",
    "InstrumentDetailsPanel.LotStep": "Шаг лота",
    "InstrumentDetailsPanel.LowLimit": "Нижний лимит",
    "InstrumentDetailsPanel.MAIN": "Основная",
    "InstrumentDetailsPanel.Margin": "Инициализации/Поддерживающая",
    "InstrumentDetailsPanel.Margin_ByAccount": "Маржа по счету",
    "InstrumentDetailsPanel.MarginBuy": "Длинная",
    "InstrumentDetailsPanel.MarginDay": "Внутридневная",
    "InstrumentDetailsPanel.MarginDayBuy": "Внутридневная длинная",
    "InstrumentDetailsPanel.MarginDaySell": "Внутридневная короткая",
    "InstrumentDetailsPanel.MarginInAccountCurrency": "#hidden#",
    "InstrumentDetailsPanel.MarginOvernight": "Перенос позиции",
    "InstrumentDetailsPanel.MarginOvernightBuy": "Перенос длинной позиции",
    "InstrumentDetailsPanel.MarginOvernightSell": "Перенос короткой позиции",
    "InstrumentDetailsPanel.MarginSell": "Короткая",
    "InstrumentDetailsPanel.MaturityDate": "Дата погашения",
    "InstrumentDetailsPanel.MaximumLot": "Максимальный лот",
    "InstrumentDetailsPanel.MaxPositionQtyPerSymbol": "Макс. объем позиции",
    "InstrumentDetailsPanel.MinimalLot": "Минимальный лот",
    "InstrumentDetailsPanel.NextPaymentDate": "Следующая выплата дохода",
    "InstrumentDetailsPanel.No": "Нет",
    "InstrumentDetailsPanel.non trading day": "Закрыта",
    "InstrumentDetailsPanel.None": "Нет",
    "InstrumentDetailsPanel.NormalMarketSize": "",
    "InstrumentDetailsPanel.Not allow short positions": "Нет",
    "InstrumentDetailsPanel.NoticeDate": "Дата оповещения",
    "InstrumentDetailsPanel.onlyCloseOrders": "только закр. ордера",
    "InstrumentDetailsPanel.Open": "Открытие",
    "InstrumentDetailsPanel.OrderPerLot": "За лот ордера",
    "InstrumentDetailsPanel.PerFill": "За заполнение",
    "InstrumentDetailsPanel.PerLot": "За лот",
    "InstrumentDetailsPanel.PerOrder": "За ордер",
    "InstrumentDetailsPanel.PerOrderVolume": "Объем ордера, %",
    "InstrumentDetailsPanel.PerPhoneTransaction": "За транзакцию по тел.",
    "InstrumentDetailsPanel.PerTransaction": "За транзакцию",
    "InstrumentDetailsPanel.PerVolume": "Объем, %",
    "InstrumentDetailsPanel.Physically": "Физическая",
    "InstrumentDetailsPanel.POSTCLOSE": "Постмаркет",
    "InstrumentDetailsPanel.PREOPEN": "Премаркет",
    "InstrumentDetailsPanel.PreviousPaymentDate": "Последняя выплата дохода",
    "InstrumentDetailsPanel.Price": "Цена",
    "InstrumentDetailsPanel.ProductType": "Тип продукта",
    "InstrumentDetailsPanel.QuotiongCurrency": "Котировочная валюта",
    "InstrumentDetailsPanel.RemoveLiqudityRebateOrderPerLot": "Remove liquidity rebate order per lot",
    "InstrumentDetailsPanel.RemoveLiqudityRebatePerLot": "За снятие ликвидности, за лот",
    "InstrumentDetailsPanel.RemoveLiqudityRebatePerVolume": "За снятие ликвидности, за объем",
    "InstrumentDetailsPanel.SessionStatus": "Статус сессии",
    "InstrumentDetailsPanel.SettlementDate": "Расчетный день",
    "InstrumentDetailsPanel.shortened": "Сокращ. день",
    "InstrumentDetailsPanel.ShortCommissionInfo": "Информация по комиссии",
    "InstrumentDetailsPanel.StrikePrice": "Цена страйка",
    "InstrumentDetailsPanel.SwapBuy": "Длинный своп",
    "InstrumentDetailsPanel.SwapSell": "Короткий своп",
    "InstrumentDetailsPanel.Latest historical long swap": "Последний исторический длинный своп",
    "InstrumentDetailsPanel.Latest historical short swap": "Последний исторический короткий своп",
    "InstrumentDetailsPanel.Historical swap last updated on": "Последнее обновление свопов",
    "InstrumentDetailsPanel.Symbol": "Инструмент",
    "InstrumentDetailsPanel.T+0": "T + 0 (сегодня)",
    "InstrumentDetailsPanel.TickCoast": "Цена тика",
    "InstrumentDetailsPanel.TickSize": "Размер тика",
    "InstrumentDetailsPanel.TradindBlockedOnSession": "Торговля заблок. в сессии",
    "InstrumentDetailsPanel.TradingBalance": "Система расчетов",
    "InstrumentDetailsPanel.TradingHalt": "Остановка торговли",
    "InstrumentDetailsPanel.TradingStatus": "Статус инструмента",
    "InstrumentDetailsPanel.Underlier": "Базовый актив",
    "InstrumentDetailsPanel.VAT": "НДС",
    "InstrumentDetailsPanel.Volume": "Объем",
    "InstrumentDetailsPanel.VolumeWithMinPD": "Объем с мин. за день, %",
    "InstrumentDetailsPanel.working": "Открыта",
    "InstrumentDetailsPanel.Yes": "Да",
    "InstrumentDetailsPanel.Yield": "Доход",
    "InstrumentDetailsPanel.YTM": "Процентная ставка, %",
    "InstrumentDetailsPanel.YTM.RefreshLink": "Обновить",
    "InstrumentDetailsPanel.HistoricalSwap.points": "пунктов",
    "InstrumentDetailsPanel.HighLimitWarning": "Предупреждение верхнего лимита",
    "InstrumentDetailsPanel.HighLimitWarning.descr": "Верхний предел допустимого коридора последней цены с последним изменением %, выше которого по лимитным ордерам поступает предупреждение",
    "InstrumentDetailsPanel.LowLimitWarning": "Предупреждение нижнего лимита",
    "InstrumentDetailsPanel.LowLimitWarning.descr": "Нижний предел допустимого коридора последней цены с последним изменением %, ниже которого по лимитным ордерам поступает предупреждение",
    "IOC": "IOC",
    "IsAllowedResponceReason.ExecuteAtMarketNotAllowedForBindOrders": "",
    "IsAllowedResponceReason.ExecuteAtMarketNotAllowedForSlTp": "",
    "IsAllowedResponceReason.ExecuteAtMarketNotAllowedOrderType": "",
    "IsAllowedResponceReason.InstrumentForAccountDelivery": "",
    "IsAllowedResponceReason.InstrumentTradingMode": "",
    "IsAllowedResponceReason.LockTrading": "",
    "IsAllowedResponceReason.LockTradingByPassword": "",
    "IsAllowedResponceReason.NoLastQuote": "",
    "IsAllowedResponceReason.NotAllowedByOrderStaus": "",
    "IsAllowedResponceReason.NotAllowedMaxLotHasBeenReached": "Достигнут максимальный объем позиции",
    "IsAllowedResponceReason.NotAllowedRouteOrderType": "",
    "IsAllowedResponceReason.NotClosablePosition": "",
    "IsAllowedResponceReason.NotLoadCache": "",
    "IsAllowedResponceReason.NotVisibleInstrumentRoute": "",
    "IsAllowedResponceReason.OperationNotAllowedByOrderType": "",
    "IsAllowedResponceReason.OperationNotAllowedBySession": "",
    "IsAllowedResponceReason.OrderTypeNotAllowedBySession": "",
    "IsAllowedResponceReason.RouteNotRunning": "",
    "IsAllowedResponceReason.RuleAdministration": "",
    "IsAllowedResponceReason.RuleClosePositionDisable": "",
    "IsAllowedResponceReason.RuleFunctionSelfTrading": "",
    "IsAllowedResponceReason.RuleFunctionTrading": "",
    "IsAllowedResponceReason.RuleOCO": "",
    "IsAllowedResponceReason.RuleSlTpNotAllowed": "",
    "IsAllowedResponceReason.RuleTrStop": "",
    "IsAllowedResponceReason.Unknown": "",
    "Keltner Channel": "",
    "Limit": "Лимит",
    "Linear Weighted Moving Average": "",
    "LOC": "",
    "LOO": "",
    "Manual": "",
    "Market": "Рыночный",
    "MarketDepthPanel.ColoringMethod.ByPriceLVL": "По уровням цены",
    "MarketDepthPanel.ColoringMethod.RelativeToVolume": "По объему",
    "MarketDepthPanel.ColoringMethod.SizeHistogram": "",
    "McGinley Dynamic": "",
    "MDLotsMode.General": "",
    "MDLotsMode.Lots": "",
    "MDLotsMode.RealSize": "",
    "MessageBox.ThemeChanged.Title": "",
    "MOC": "",
    "Modified Moving Average": "",
    "Momentum": "",
    "MOO": "",
    "Moving Average Envelope": "",
    "Moving Average/Convergence Divergence": "",
    "NetTrader.SaveWorkspace.Save": "",
    "NumericComboCtrl.Edit": "Редактировать",
    "OCO": "OCO",
    "OEProductTypeSelector.tooltip": "Установить тип продукта",
    "OELeverageSelector.tooltip": "Выберите кредитное плечо",
    "On Balance Volume": "",
    "OnClose": "К закрытию",
    "OnOpen": "К открытию",
    "Order.ExecutionType.ACCEPTED": "",
    "Order.ExecutionType.ACTIVATED": "",
    "Order.ExecutionType.CANCELED": "",
    "Order.ExecutionType.FILLED": "",
    "Order.ExecutionType.NEW": "",
    "Order.ExecutionType.PART_FILLED": "",
    "Order.ExecutionType.REFUSED": "",
    "Order.ExecutionType.REPLACED": "",
    "Order.ExecutionType.RESTATED": "",
    "Order.ExecutionType.UNKNOWN": "",
    "OrderEntry.InfoBlock.After trade funds": "",
    "OrderEntry.InfoBlock.Availible funds": "",
    "OrderEntry.InfoBlock.Balance": "",
    "OrderEntry.InfoBlock.Fee": "",
    "OrderEntry.InfoBlock.Gross": "",
    "OrderEntry.InfoBlock.Impact on portfolio": "",
    "OrderEntry.InfoBlock.Init. margin": "",
    "OrderEntry.InfoBlock.Maint. margin": "",
    "OrderEntry.InfoBlock.Margin availible": "",
    "OrderEntry.InfoBlock.Net": "",
    "OrderEntry.InfoBlock.Spread in loss": "",
    "OverlayScreen.barsBodyLabel": "Тело",
    "OverlayScreen.barsBorderLabel": "Граница",
    "OverlayScreen.barsHiLowLabel": "Линия",
    "OverlayScreen.cancelButton": "Отмена",
    "OverlayScreen.dataLabel": "Тип данных",
    "OverlayScreen.dojiLabel": "Doji",
    "OverlayScreen.histogramLineLabel": "Histogram Line",
    "OverlayScreen.instrumentLabel": "Инструмент",
    "OverlayScreen.Main scale": "Основная шкала",
    "OverlayScreen.name": "Наложение",
    "OverlayScreen.numberOverlaysError": "Вы можете добавлять не более 5 наложений.",
    "OverlayScreen.okButton": "Ok",
    "OverlayScreen.Overlays": "Наложения",
    "OverlayScreen.priceLabel": "Масштаб цены",
    "OverlayScreen.priceMarkerCheckbox": "Показывать маркер цены",
    "OverlayScreen.Separate scale": "Отдельная шкала",
    "OverlayScreen.solidPriceLabel": "Область",
    "OverlayScreen.styleLabel": "Стиль",
    "OverlayScreen.wickLabel": "Фитиль",
    "panel.accountDetails.Groups.1.General": "Общее",
    "panel.accountDetails.Groups.2.Margin": "Маржа",
    "panel.accountDetails.Groups.3.AccountActivity": "Активность по счету",
    "panel.accountDetails.Groups.4.TodayResults": "Результаты за день",
    "panel.accountDetails.Groups.5.RiskManagement": "Риск менеджмент",
    "panel.accountDetails.Groups.55.Plans": "Планы",
    "panel.accountDetails.Groups.6.Info": "Информация",
    "panel.AccountDetailsPanel": "Состояние счета",
    "panel.AccountDetailsPanel.InfoTooltip": "",
    "panel.AccountLink.SymbolLink.ToolTip": "Связь по инструменту",
    "panel.AccountLink.ToolTip": "Связь по счету",
    "panel.AccountLinkButton.ToolTip": "Данная функция фильтрует ваши позиции и ордера по аккаунту. Аккаунт переключается синхронно во всех панелях",
    "panel.accounts": "Счета",
    "panel.accounts.AccountDescription": "#hidden#",
    "panel.accounts.accountDetails.RiskManagement.Trading.Daily loss limit reached": "Дневной лимит убытков достигнут",
    "panel.accounts.accountDetails.RiskManagement.Trading.Max day volume  reached": "Достигнут Макс. дневной объем",
    "panel.accounts.accountDetails.RiskManagement.Trading.Order Flow Limit(per day)": "Лимит потока ордеров(за день)",
    "panel.accounts.accountDetails.RiskManagement.Trading.State.Disabled": "Выключена",
    "panel.accounts.accountDetails.RiskManagement.Trading.State.Enabled": "Включена",
    "panel.accounts.accountDetails.RiskManagement.TradingStatus.Active": "Активный",
    "panel.accounts.accountDetails.RiskManagement.TradingStatus.Auto closed": "Закрыт брокером",
    "panel.accounts.accountDetails.RiskManagement.TradingStatus.Closed": "Закрытый",
    "panel.accounts.accountDetails.RiskManagement.TradingStatus.Disabled by risk rules": "Trading disabled by risk rules",
    "panel.accounts.accountDetails.RiskManagement.TradingStatus.MarginWarning": "",
    "panel.accounts.accountDetails.RiskManagement.TradingStatus.Max trailing drawdown was reached": "Максимально допустимая просадка по аккаунту достигнута",
    "panel.accounts.accountDetails.RiskManagement.TradingStatus.Suspend": "Приостановить",
    "panel.accounts.accountDetails.RiskManagement.TradingStatus.Unrealized loss limit reached": "Лимит нереализованных убытков достигнут",
    "panel.accounts.accountDetails.RiskManagement.TradingStatus.Weekly lost limit reached": "Недельный лимит убытков достигнут",
    "panel.accounts.AccountMode": "Режим счета",
    "panel.accounts.AccountType": "Тип счета",
    "panel.accounts.AccountValue": "Текущие активы",
    "panel.accounts.AllowOvernightTrading": "Разрешить перенос позиций",
    "panel.accounts.AllowTradingPrePost": "Торговля на пре/пост сессиях",
    "panel.accounts.Asset": "Актив",
    "panel.accounts.AssetDescription": "Описание актива",
    "panel.accounts.AssetType": "Тип актива",
    "panel.accounts.AvailableMargin": "Доступные средства",
    "panel.accounts.Balance": "Баланс",
    "panel.accounts.BalancePlusAllRisks": "Баланс&Все риски",
    "panel.accounts.BeginBalance": "Чистая прибыль за день",
    "panel.accounts.BlockedByOrders": "Маржа для ордеров",
    "panel.accounts.BlockedByPamm": "Инвестированный в фонд капитал",
    "panel.accounts.BlockedForStocks": "#hidden#",
    "panel.accounts.BlockedForStocksNew": "Заблокировано по Стокам",
    "panel.accounts.CashBalance": "Наличные средства",
    "panel.accounts.ClientType": "Тип терминала",
    "panel.accounts.Commissions": "Комиссии",
    "panel.accounts.CreditValue": "Заемные средства",
    "panel.accounts.CurBalance": "Прогнозируемый баланс",
    "panel.accounts.CurMargin": "Начальная маржа, %",
    "panel.accounts.CurrentDailyLoss": "Убыток за день",
    "panel.accounts.CurrentFundCapital": "Текущий капитал фонда",
    "panel.accounts.CurrentStatus": "Торговый статус",
    "panel.accounts.CurrentWeeklyLoss": "Убыток за неделю",
    "panel.accounts.Email": "Эл. почта",
    "panel.accounts.Estimate value": "Расчетная стоимость",
    "panel.accounts.FundCapitalGain": "Рост фонда",
    "panel.accounts.FundsUsed": "Используемые средства",
    "panel.accounts.FundsUsedPercent": "Используемые средства, %",
    "panel.accounts.GrossPNL": "Валовой доход за день",
    "panel.accounts.InsertOnBalance": "Процентные ставки",
    "panel.accounts.InterestRate": "Процентная ставка, %",
    "panel.accounts.IntradayRiskManagement": "Статус риск менеджмента",
    "panel.accounts.InitialMarginWithoutWaived": "Нач.маржа без уступок",
    "panel.accounts.InitialMarginWithoutWaived.descr": "Начальная маржа по счету без уступок",
    "panel.accounts.Leverage": "Кредитное плечо",
    "panel.accounts.LockedBalance": "Заблокированный баланс",
    "panel.accounts.LockedBy": "Заблокировано",
    "panel.accounts.Login": "Счет",
    "panel.accounts.MaintMargin": "Маржа поддержки, %",
    "panel.accounts.MarginAndRisks": "Маржа и риски",
    "panel.accounts.MarginAvailableReal": "Доступная маржа",
    "panel.accounts.MarginDeficiency": "Дефицит маржи",
    "panel.accounts.MarginForOthers": "#hidden#",
    "panel.accounts.MarginRequirement": "Треб. поддерж. поз.",
    "panel.accounts.MarginStopOut": "Маржа поддержки",
    "panel.accounts.MarginStopTrade": "Остановка торговли",
    "panel.accounts.MarginSurplus": "Профицит маржи",
    "panel.accounts.MarginUsedNew": "Начальная маржа",
    "panel.accounts.MarginWarning": "Предупреждающая маржа",
    "panel.accounts.marginWarnPercent": "Уровень марж. предупреждения",
    "panel.accounts.MarketDataCharging": "Выплаты за рыночные данные",
    "panel.accounts.MaxDailyLossLimit": "Дневной лимит убытков",
    "panel.accounts.MaxDailyProfit": "",
    "panel.accounts.MaxDayVolume": "Макс. дневной объем",
    "panel.accounts.MaximumLot": "Макс. объем ордера",
    "panel.accounts.MaxOrderAmount": "",
    "panel.accounts.MaxOrderCapital": "Макс. капитал ордера",
    "panel.accounts.MaxOrdersPerDay": "Максимальное количество ордеров в день",
    "panel.accounts.MaxOrdersPerDay.descr": "Ограничивает количество ордеров, которые пользователь может разместить за день",
    "panel.accounts.MaxPendingOrders": "# Макс. отложенных ордеров",
    "panel.accounts.MaxPendingOrdersValue": "Макс. величина ожидающих ордеров",
    "panel.accounts.MaxPositionQtyPerSymbol": "Макс. объем поз. по инстр.",
    "panel.accounts.MaxPositions": "# Макс. позиций",
    "panel.accounts.MaxWeekLostLimit": "Недельный лимит убытков",
    "panel.accounts.MinMargin": "Маржа для позиций",
    "panel.accounts.Murkups": "Наценки",
    "panel.accounts.N/A_TelephoneNumber": "Н/Д",
    "panel.accounts.OptionPremiumReq": "",
    "panel.accounts.OptionValue": "Стоимость опционов",
    "panel.accounts.Orders": "# Ордера",
    "panel.accounts.Owner": "Владелец",
    "panel.accounts.Positions": "# Позиции",
    "panel.accounts.PowerOfAttorney": "",
    "panel.accounts.Profit": "Нереализованная валовая прибыль/убыток",
    "panel.accounts.ProfitNet": "Нереализованная чистая прибыль/убыток",
    "panel.accounts.Role": "Тип фонда",
    "panel.accounts.Spreads": "Спреды",
    "panel.accounts.Status": "Статус",
    "panel.accounts.Collateral": "",
    "panel.accounts.StocksLiquidity": "Stocks liquidity",
    "panel.accounts.StocksOrdersReq": "Маржа за орд. по акциям",
    "panel.accounts.StocksValue": "Стоимость акций",
    "panel.accounts.stopOutPercent": "#hidden#",
    "panel.accounts.stopTradingPercent": "Уровень остановки торговли",
    "panel.accounts.Swap": "Свопы",
    "panel.accounts.TelephoneNumber": "Номер телефона",
    "panel.accounts.TodayFees": "Дневные платежи",
    "panel.accounts.TodayRebates": "Выплаты за день",
    "panel.accounts.TodaySwaps": "#hidden#",
    "panel.accounts.TodayTrades": "# Сделок за день",
    "panel.accounts.TodayTurnover": "Оборот за день",
    "panel.accounts.TodayVolume": "Объем за день",
    "panel.accounts.TotalMaxPositionsQty": "Общий максимальный объем по позициям",
    "panel.accounts.TrailingDrawdownLevel": "Скользящий лимит убытков",
    "panel.accounts.UnrealizedLoss": "Нереализованный убыток",
    "panel.accounts.UnrealizedLossLimit": "Лимит нереализованных убытков",
    "panel.accounts.UnsettledCash": "Непоставленные средства",
    "panel.accounts.UserGroup": "Пользов. группа",
    "panel.accounts.UserID": "ID пользователя",
    "panel.accounts.UserLogin": "Логин пользователя",
    "panel.accounts.VolumeLimitForEquities": "",
    "panel.accounts.VolumeLimitForFutures": "",
    "panel.accounts.VolumeLimitForOptions": "",
    "panel.accounts.WithdrawalAvailable": "Доступно для вывода",
    "panel.accounts.WaivedMargin": "Маржинальные уступки",
    "panel.accounts.WaivedMargin.descr": "Маржинальные уступки по счету",
    "panel.accounts.WarningMarginReq": "Маржа предупреждения",
    "panel.accounts.WarningMarginReq.descr": "Требования по марже предупреждения для позиций и ордеров",
    "panel.accounts.WarningMarginReqPercent": "Маржа предупреждения %",
    "panel.accounts.WarningMarginReqPercent.descr": "Отношение маржи предупреждения к Баланс&Все риски",
    "panel.accounts.MarginBeforeWarning": "Маржа до предупреждения",
    "panel.accounts.MarginBeforeWarning.descr": "Маржа оставшаяся до получения предупреждения",
    "panel.accounts.LossLimitPerTrade": "Макс.убыток за трейд",
    "panel.accounts.LossLimitPerTrade.descr": "Показывает максимально допустимое значение убытков по одному трейду",
    "panel.assetBalances": "Счета",
    "panel.backoffice": "Бэк-Офис",
    "panel.backoffice.You need to add connection!": "Вам нужно добавить соединение!",
    "panel.caption.NotAllowedMode": "Эта панель недоступна в текущем наборе правил",
    "panel.caption.showHideToolbar": "Показать панель инструментов",
    "panel.chart": "График",
    "panel.close": "Закрыть",
    "panel.closePosition.Gross_P/L_ToClose": "Вал. П/У для закр.",
    "panel.closePosition.QunatityToClose": "Объем для закрытия",
    "panel.copypanel": "Копия панели",
    "panel.detail": "Детали котировок",
    "panel.detail.AuctionEndTime": "",
    "panel.detail.CBAuctionStart": "",
    "panel.detail.change": "Изменение",
    "panel.detail.changePr": "Изменение, %",
    "panel.detail.CurrentSession": "",
    "panel.detail.high": "Максимум",
    "panel.detail.last": "Последняя",
    "panel.detail.Limit.High": "",
    "panel.detail.Limit.Low": "",
    "panel.detail.low": "Минимум",
    "panel.detail.NormalMarketSize": "",
    "panel.detail.OffExchangeValue": "",
    "panel.detail.OffExchangeVolume": "",
    "panel.detail.open": "Открытие",
    "panel.detail.TradedValue": "",
    "panel.detail.TradingStatus": "",
    "panel.detail.volume": "Объем",
    "panel.detail.BidSource": "Источник Бид",
    "panel.detail.AskSource": "Источник Аск",
    "panel.detail.LastSource": "Источник последней",
    "panel.detail.OpenInterest": "Открытый интерес",
    "panel.watchlist.BidSource": "Источник Бид",
    "panel.watchlist.AskSource": "Источник Аск",
    "panel.watchlist.LastSource": "Источник последней",
    "panel.EventLog.Action": "Событие",
    "panel.EventLog.Date": "Дата",
    "panel.EventLog.eventType.alerts": "Алерты",
    "panel.EventLog.eventType.comment": "Комментарий",
    "panel.EventLog.eventType.disconnect": "Отключиться",
    "panel.EventLog.eventType.exception": "Исключение",
    "panel.EventLog.eventType.info": "Инфо",
    "panel.EventLog.eventType.none": "Пусто",
    "panel.EventLog.eventType.system": "Система",
    "panel.EventLog.eventType.trading": "Торговля",
    "panel.EventLog.eventType.updater": "Обновление",
    "panel.EventLog.menu.Clear": "Очистить",
    "panel.EventLog.Message": "Сообщение",
    "panel.EventLog.NamePanel": "Журнал событий",
    "panel.EventLog.Roundtrip": "Задержка, мс",
    "panel.EventLog.Type": "Тип",
    "panel.GridPanel.listButton.ToolTip": "Выберите инструмент для панели",
    "panel.GridPanel.recreateButton.ToolTip": "Укажите количество панелей",
    "panel.GridPanel.refreshAllCharts.ToolTip": "Обновить все графики",
    "panel.GridPanel.resetMI.Text": "Восстановить размер панелей",
    "panel.HeatmapPanel": "Тепловая карта",
    "panel.HeatmapPanel.Settings.Header": "Тепловая карта - настройки",
    "panel.HeatmapPanel.Settings.GradationScale": "Шкала градаций",
    "panel.HeatmapPanel.Settings.IndustryLevel": "Уровень отрасли",
    "panel.HeatmapPanel.Settings.SectorLevel": "Уровень сектора",
    "panel.HeatmapPanel.Settings.SymbolLevel": "Уровень инструмента",
    "panel.informer": "Список наблюдения",
    "panel.Ideas": "Идеи",
    "panel.Ideas.riskDisclosure": "УВЕДОМЛЕНИЕ О РИСКАХ",
    "panel.Ideas.riskDisclosure.agreeBtnTxt": "Я согласен",
    "panel.Ideas.productTypeChangeToIntraday": "Идея была предложена с типом продукта - поставочный. Вы изменили тип продукта на внутридневной. Вы уверены?",
    "panel.Ideas.productTypeChangeToDelivery": "Идея была предложена с типом продукта - внутридневной. Вы изменили тип продукта на поставочный. Вы уверены?",
    "panel.Ideas.FilteringAllTypes": "Все",
    "panel.AlgorithmMarketConsensusPanel": "Рыночный консенсус",
    "screen.Alerts.Action.Edit": "Редактировать",
    "screen.Alerts.Action.Popup": "Окно",
    "screen.Alerts.Action.Sound": "Звук",
    "screen.Alerts.Action.SoundAndPopup": "Окно+Звук",
    "screen.Alerts.Action.PlaceOrder": "Разместить заказ + Уведомление",
    "screen.Alerts.Action.Notify": "Уведомление",
    "screen.Alerts.Action.Start": "Старт",
    "screen.Alerts.AddAlert": "Создать новый",
    "screen.Alerts.AddAlert.Tooltip": "Кликните чтобы создать новый алерт",
    "screen.Alerts.AddAlert.Disabled.Tooltip": "Превышено максимальное количество алертов.",
    "screen.Alerts.AfterExecute.Remove": "Удалить",
    "screen.Alerts.AfterExecute.Stop": "Стоп",
    "screen.Alerts.Alert": "Оповещение",
    "screen.Alerts.Button.Apply.Tooltip": "Кликните чтобы применить изменения",
    "screen.Alerts.Button.Create": "Создать",
    "screen.Alerts.Button.Create.Tooltip": "Поле не может быть пустым",
    "screen.Alerts.Header.Create": "Создать алерт",
    "screen.Alerts.Header.Edit": "Редактирование",
    "screen.Alerts.Importance.High": "Высокая",
    "screen.Alerts.Importance.Low": "Низкая",
    "screen.Alerts.Importance.Medium": "Средняя",
    "screen.Alerts.Label.Action": "Действие",
    "screen.Alerts.Label.OrderParameters": "Параметры ордера",
    "screen.Alerts.Label.OrderParameters.InfoTooltip": "Кликните чтобы выбрать параметры ордера",
    "screen.Alerts.Label.AfterExecute.Tooltip": "Выберите поведение алерта",
    "screen.Alerts.Label.AfterExecute": "Поведение",
    "screen.Alerts.Label.AlertType.Tooltip": "Установите тип алерта",
    "screen.Alerts.Label.AlertType": "Тип алерта",
    "screen.Alerts.Label.Condition.Tooltip": "Установите условие алерта",
    "screen.Alerts.Label.Condition": "Условие",
    "screen.Alerts.Label.Importance": "Важность",
    "screen.Alerts.Label.Expiration": "Дата окончания",
    "screen.Alerts.Label.Expiration.Tooltip": "Дата истечения срока действия алерта.",
    "screen.Alerts.Label.Expiration.InfoTooltip": "",
    "screen.Alerts.Label.Symbol": "Инструмент",
    "screen.Alerts.Label.Value": "Значение",
    "screen.Alerts.Message": "Текст алерта",
    "screen.Alerts.RemoveAlertMessage": "Алерт будет удален. Вы согласны?",
    "screen.Alerts.TableItem.Condition": "Условие",
    "screen.Alerts": "Алерты",
    "screen.Alerts.Ask": "Аск",
    "screen.Alerts.Bid": "Бид",
    "screen.Alerts.Last": "Последняя",
    "screen.Alerts.ChangePr": "Изменение, %",
    "screen.Alerts.Volume": "Объем",
    "screen.withdrawal.Withdrawal": "Вывести",
    "panel.instrumentInfo.AvgPrice": "Ср.цена:",
    "panel.instrumentInfo.P/L": "П/У:",
    "panel.instrumentInfo.Qty": "Объем:",
    "panel.instruments": "Инструменты",
    "panel.instrumentsInfo": "Параметры инструмента",
    "panel.knowledgebase": "База знаний",
    "panel.level2": "Стакан заявок",
    "panel.Level2.Age": "#hidden#",
    "panel.level2.Auction": " AUC",
    "panel.Level2.AvgPrice": "Сред. цена",
    "panel.Level2.Contr. CCY Total value": "Общее знач. в валюте кот.",
    "panel.Level2.Contr. CCY value": "Знач. в валюте кот.",
    "panel.Level2.DayTradeVolume": "Дневн.объем",
    "panel.Level2.menu.View": "Вид",
    "panel.Level2.menu.View.InstrInfo": "Бар позиций",
    "panel.Level2.menu.View.Level1": "Меню Уровень 1",
    "panel.Level2.menu.View.Order Entry": "Панель Создать ордер",
    "panel.Level2.MPID": "MPID",
    "panel.Level2.Number": "Ордер",
    "panel.Level2.oe.BuyButton": "Купить",
    "panel.Level2.oe.SellButton": "Продать",
    "panel.Level2.Price": "Цена",
    "panel.Level2.Size": "Объем",
    "panel.Level2.Source": "Источник",
    "panel.Level2.Time": "Время",
    "panel.Level2.TotalVol": "Общий объем",
    "panel.log": "Протокол событий",
    "panel.menu.GroupBy": "Сгруппировать по",
    "panel.menu.new": "Новый",
    "panel.menu.Remove": "Убрать",
    "panel.menu.resetToDefault": "",
    "panel.menu.renameList": "Кликните для переименования списка инструментов",
    "panel.menu.removeList": "Кликните чтобы удалить список инструментов",
    "panel.menu.renameTemplate": "Кликните чтобы переименовать шаблон",
    "panel.menu.removeTemplate": "Кликните чтобы удалить шаблон",
    "panel.menu.saveList": "Кликните чтобы пересохранить шаблон",
    "panel.neworder": "Создать ордер",
    "panel.newOrderEntry.accountLabel": "Счет",
    "panel.newOrderEntry.AddAskButton": "Продать Аск",
    "panel.newOrderEntry.AddBitButton": "Купить Бид",
    "panel.newOrderEntry.amountLabel": "Объем",
    "panel.newOrderEntry.BuyAskButton": "Купить Аск",
    "panel.newOrderEntry.BuyMarketButton": "Купить",
    "panel.newOrderEntry.buySideCheckBox": "Купить",
    "panel.newOrderEntry.instrumentLabel": "Инструмент",
    "panel.newOrderEntry.Leverage": "Леверидж",
    "panel.newOrderEntry.limitPricePanel": "Цена Лимит",
    "panel.newOrderEntry.placeOrderButton": "Отправить ордер",
    "panel.newOrderEntry.ProductType": "Тип продукта",
    "panel.newOrderEntry.RefreshMarginReq": "Обновить марж.треб.",
    "panel.newOrderEntry.riskDetailsLabel": "Маржа",
    "panel.newOrderEntry.SellBidButton": "Продать Бид",
    "panel.newOrderEntry.SellMarketButton": "Продать",
    "panel.newOrderEntry.sellSideCheckBox": "Продать",
    "panel.newOrderEntry.Side": "Направление",
    "panel.newOrderEntry.singleOrderRadio": "Один ор.",
    "panel.newOrderEntry.slPriceRisk": "СЛ цена/риск",
    "panel.newOrderEntry.slPriceRiskOffset": "СЛ смещ./риск",
    "panel.newOrderEntry.snapshotText": "Получить снимок",
    "panel.newOrderEntry.snapshotBtn.enabled.tt": "Получить снимок данных в реальном времени",
    "panel.newOrderEntry.snapshotBtn.disabled.tt": "Лимит запросов на снимок исчерпан",
    "panel.newOrderEntry.stopLossCheckBox": "Стоп лосс",
    "panel.newOrderEntry.stopLossCheckBox.offset": "Смещ. СЛ",
    "panel.newOrderEntry.stopPricePanel": "Цена Стоп",
    "panel.newOrderEntry.stopLimitLimitPrice": "СЛ Лимит цена",
    "panel.newOrderEntry.stopLimitLimitPrice.tt": "Установить Лимит цену для СЛ ордера",
    "panel.newOrderEntry.stopLimitLimitOffset": "СЛ Лимит cмещ.",
    "panel.newOrderEntry.stopLimitLimitOffset.tt": "Установить смещение Лимита для СЛ ордера",
    "panel.newOrderEntry.takeProfitCheckBox": "Тейк профит",
    "panel.newOrderEntry.takeProfitCheckBox.offset": "Смещ. ТП",
    "panel.newOrderEntry.tifPanel": "Срок действия",
    "panel.newOrderEntry.tpPriceRisk": "ТП цена/риск",
    "panel.newOrderEntry.tpPriceRiskOffset": "ТП смещ./риск",
    "panel.newOrderEntry.trstopOffset": "Смещ. Тр. стоп",
    "panel.newOrderEntry.typePanel": "Тип ордера",
    "panel.NewTrades.Account": "Счет",
    "panel.NewTrades.Amount": "Объем",
    "panel.NewTrades.Bought": "Куплено",
    "panel.NewTrades.Commission": "Комиссия за исполнение",
    "panel.NewTrades.Commission.InInstrumentQuotingCurrency": "#hidden#",
    "panel.NewTrades.Date": "Дата",
    "panel.NewTrades.Exchange": "#hidden#",
    "panel.NewTrades.ExpirationDate": "Дата эксп.",
    "panel.NewTrades.ExternalOrderId": "#hidden#",
    "panel.NewTrades.ExternalPrice": "Внешняя цена",
    "panel.NewTrades.ExternalTradeId": "Внешний ID",
    "panel.NewTrades.instrType": "Тип инстр.",
    "panel.NewTrades.Instrument": "Инструмент",
    "panel.NewTrades.Login": "Логин",
    "panel.NewTrades.Net_PL": "Чистая прибыль/убыток",
    "panel.NewTrades.Net_PL.InInstrumentQuotingCurrency": "#hidden#",
    "panel.NewTrades.Operation": "Направление",
    "panel.NewTrades.OrderId": "ID ордера",
    "panel.NewTrades.OrderType": "Тип ордера",
    "panel.NewTrades.Price": "Цена",
    "panel.NewTrades.ProductType": "Тип продукта",
    "panel.NewTrades.PurchasePrice": "Экспозиция",
    "panel.NewTrades.RealizedProfitLoss": "Валовая прибыль/убыток",
    "panel.NewTrades.RealizedProfitLoss.InInstrumentQuotingCurrency": "#hidden#",
    "panel.NewTrades.Rebates": "Выплаты",
    "panel.NewTrades.Route": "Маршрут",
    "panel.NewTrades.Sold": "Продано",
    "panel.NewTrades.StrikePrice": "Цена страйк",
    "panel.NewTrades.Time": "Время",
    "panel.NewTrades.TradeID": "ID cделки",
    "panel.NewTrades.TradeIDLabel": "#hidden#",
    "panel.NewTrades.Description": "Описание",
    "panel.OrderBook.Account": "Счет",
    "panel.OrderBook.Action": "Направление",
    "panel.OrderBook.Bought": "Куплено",
    "panel.OrderBook.Date": "Дата",
    "panel.OrderBook.DislocedQty": "#hidden#",
    "panel.OrderBook.Event": "Событие",
    "panel.OrderBook.ExpirationDate": "Дата эксп.",
    "panel.OrderBook.ExternalOrderID": "#hidden#",
    "panel.OrderBook.FilledQuantity": "#hidden#",
    "panel.OrderBook.instrType": "Тип инстр.",
    "panel.OrderBook.Instrument": "Инструмент",
    "panel.OrderBook.Login": "Логин",
    "panel.OrderBook.Message": "Сообщение",
    "panel.OrderBook.OrderNumber": "ID ордера",
    "panel.OrderBook.Price": "Цена",
    "panel.OrderBook.ProductType": "Тип продукта",
    "panel.OrderBook.Quantity": "Объем",
    "panel.OrderBook.ReminingQuantity": "#hidden#",
    "panel.OrderBook.RemoteID": "Удаленный идентификатор",
    "panel.OrderBook.Route": "Маршрут",
    "panel.OrderBook.Sold": "Продано",
    "panel.OrderBook.StopPrice": "Цена Стоп",
    "panel.OrderBook.StrikePrice": "Цена страйка",
    "panel.OrderBook.TIF": "Срок действия",
    "panel.OrderBook.Time": "Время",
    "panel.OrderBook.TraderID": "Изменен пользователем",
    "panel.OrderBook.Type": "Тип",
    "panel.OrderBook.Description": "Описание",
    "panel.orderEntry": "Создать ордер",
    "panel.orderHistory": "История ордеров",
    "panel.orders": "Рабочие ордера",
    "panel.orders.Account": "Счет",
    "panel.orders.action": "Направление",
    "panel.orders.AvgFilledPrice": "#hidden#",
    "panel.orders.BindTo": "Привязан к",
    "panel.orders.Cancel": "",
    "panel.orders.Comment": "#hidden#",
    "panel.orders.Comments": "Комментарии",
    "panel.orders.Ct_Ahead": "Центы впереди",
    "panel.orders.CurrentPrice": "Текущая цена",
    "panel.orders.date": "Дата",
    "panel.orders.date_time": "",
    "panel.orders.disclosedQty": "#hidden#",
    "panel.orders.Event": "Событие",
    "panel.orders.Exchange": "Биржа",
    "panel.orders.ExpirationDate": "Дата эксп.",
    "panel.orders.ExternalOrderId": "#hidden#",
    "panel.orders.ExternalOrderStatus": "#hidden#",
    "panel.orders.GroupId": "ID MAM группы",
    "panel.orders.InitReq": "Нач. маржа",
    "panel.orders.Instr_Type": "Тип инструмента",
    "panel.orders.Login": "Логин",
    "panel.orders.Market": "Рыночный",
    "panel.orders.menu.CancelAllByInstrument": "Отменить все по выбр. инструменту",
    "panel.orders.menu.CancelAllForAccount": "Отменить все по выбр. счету",
    "panel.orders.menu.CancelAllOrders": "Отменить все ордера",
    "panel.orders.menu.CancelOrder": "Отменить ордер",
    "panel.orders.menu.ExecuteAsMarket": "Изменить на рыночный",
    "panel.orders.menu.ExecuteAsMarket.question": "Вы уверены, что хотите выполнить ордер по рыночной цене?",
    "panel.orders.menu.ModifyOrder": "Редактировать ордер...",
    "panel.orders.menu.Remove": "Отменить ордер",
    "panel.orders.OCOGrouID": "ID OCO группы",
    "panel.orders.price": "Цена",
    "panel.orders.ProductType": "Тип продукта",
    "panel.orders.quantity": "Объем",
    "panel.orders.QuantityFilled": "Заполненный объем",
    "panel.orders.QuantityRemaining": "Незаполненный объем",
    "panel.orders.Reference": "ID ордера",
    "panel.orders.RemoteID": "Удаленный ID",
    "panel.orders.Route": "Маршрут",
    "panel.orders.SL": "СЛ",
    "panel.orders.SL_Offset": "SL offset",
    "panel.orders.SL_Price": "SL price",
    "panel.orders.SLL_Price": "СЛ Лимит цена",
    "panel.orders.SLL_Offset": "СЛ Лимит cмещ.",
    "panel.orders.Status": "Статус",
    "panel.orders.StopPrice": "Цена Стоп",
    "panel.orders.StrikePrice": "Цена страйка",
    "panel.orders.symbol": "Инструмент",
    "panel.orders.symbolDescription": "Описание инструмента",
    "panel.orders.TIF": "Срок действия",
    "panel.orders.time": "Время",
    "panel.orders.Token": "ID ордера",
    "panel.orders.TP": "ТП",
    "panel.orders.TP_Offset": "ТП смещение",
    "panel.orders.TP_Price": "TP price",
    "panel.orders.TraderID": "ID трейдера",
    "panel.orders.type": "Тип",
    "panel.orders.UserGroup": "Пользов. группа",
    "panel.portfolioSummary": "Сводка",
    "panel.positions": "Позиции",
    "panel.positions.Account": "Счет",
    "panel.positions.AssetName": "Имя актива",
    "panel.positions.AvailableForSell": "",
    "panel.positions.Basis": "Цена открытия",
    "panel.positions.Close": "Закрыть",
    "panel.positions.comments": "Комментарии",
    "panel.positions.commissions": "Комиссия",
    "panel.positions.commissions.InstrumentCurrency": "#hidden#",
    "panel.positions.confirmation.Reverse": "Реверс",
    "panel.positions.confirmation.ReverseAllPositions": "Вы уверены, что хотите сделать реверс всех позиций?",
    "panel.positions.confirmation.ReverseByInstrument": "Вы уверены, что хотите сделать реверс всех позиций по инструменту и счету?",
    "panel.positions.confirmation.ReverseSelectedPositions": "Вы уверены, что хотите сделать реверс выбранных позиций?",
    "panel.positions.cur_price": "Текущая цена",
    "panel.positions.date_time": "Дата/Время",
    "panel.positions.Delta": "Delta",
    "panel.positions.ExpDate": "Дата экспирации",
    "panel.positions.exposition_usd": "Экспозиция по поз.",
    "panel.positions.Gamma": "Gamma",
    "panel.positions.GroupId": "ID MAM группы",
    "panel.positions.InitReq": "Нач. треб.",
    "panel.positions.IV": "IV",
    "panel.positions.Login": "Логин",
    "panel.positions.MaintReq": "Треб. поддержки",
    "panel.positions.MarginSuplus": "Проф./Деф. маржи",
    "panel.positions.menu.Close": "Закрыть позицию",
    "panel.positions.menu.Modify": "Редактировать позицию",
    "panel.positions.menu.ShowToolbar": "",
    "panel.positions.menu.DisplayTrades": "Отобразить трейды",
    "panel.positions.menu.View": "Вид",
    "panel.positions.Net_PL": "Чистая прибыль",
    "panel.positions.Net_PL.InstrumentCurrency": "#hidden#",
    "panel.positions.Operation": "Направление",
    "panel.positions.PL_ticks": "П/У, смещение",
    "panel.positions.PL_ticks.points": "пункты",
    "panel.positions.PL_ticks.ticks": "тики",
    "panel.positions.pos_number": "ID позиции",
    "panel.positions.PositionValue": "Величина поз.",
    "panel.positions.ProductType": "Тип продукта",
    "panel.positions.profit_usd": "Валовая прибыль",
    "panel.positions.profit_usd.InstrumentCurrency": "#hidden#",
    "panel.positions.Qunatity": "Объем",
    "panel.positions.RealizedPnL": "#hidden#",
    "panel.positions.RealizedPnL.InstrumentCurrency": "#hidden#",
    "panel.positions.Rho": "Rho",
    "panel.positions.route": "Маршрут",
    "panel.positions.SL": "СЛ",
    "panel.positions.SL_Value": "Величина СЛ",
    "panel.positions.SLL": "СЛ Лимит цена",
    "panel.positions.strike": "Цена страйка",
    "panel.positions.swaps": "Свопы",
    "panel.positions.Symbol": "Инструмент",
    "panel.positions.SymbolDescription": "Описание инструмента",
    "panel.positions.Theta": "Theta",
    "panel.positions.TP": "ТП",
    "panel.positions.type": "Тип инструмента",
    "panel.positions.UsedMargin": "Используемая маржа",
    "panel.positions.UserGroup": "Пользов. группа",
    "panel.positions.Vega": "Vega",
    "panel.positionsBalance": "Баланс по позициям",
    "panel.positionsBalance.Symbol": "Инструмент",
    "panel.positionsBalance.NetPositionQty": "Чистый объем поз.",
    "panel.positionsBalance.BreakEvenPoint": "Точка безубыточности",
    "panel.positionsBalance.BreakEven": "Break-even",
    "panel.positionsBalance.CurrentPrice": "Текущая цена",
    "panel.positionsBalance.GrossPL": "Валовая прибыль/убыток",
    "panel.positionsBalance.LongQty": "Объем длинных",
    "panel.positionsBalance.AverageLong": "Средняя длинная",
    "panel.positionsBalance.ShortQty": "Объем коротких",
    "panel.positionsBalance.AverageShort": "Средняя короткая",
    "panel.positionsBalance.GrossExposure": "Валовая экспозиция",
    "panel.positionsBalance.NetExposure": "Чистая экспозиция",
    "panel.positionsBalance.StrikePrice": "Цена страйка",
    "panel.positionsBalance.ExpDate": "Дата эксп.",
    "panel.positionsBalance.PositionValue": "Величина позиции",
    "panel.positionsBalance.SymbType": "Тип инструмент",
    "panel.positionsBalance.ProductType": "Тип продукта",
    "panel.positionsBalance.Symbol.tt": "Название инструмента.",
    "panel.positionsBalance.NetPositionQty.tt": "Чистый объем открытых позиций (Объем длинных - Объем коротких)",
    "panel.positionsBalance.BreakEvenPoint.tt": "Точка безубыточности",
    "panel.positionsBalance.CurrentPrice.tt": "Текущая рыночная цена",
    "panel.positionsBalance.GrossPL.tt": "Прибыль/убыток в валюте счета",
    "panel.positionsBalance.LongQty.tt": "Сумма по длинным позициям",
    "panel.positionsBalance.AverageLong.tt": "Средняя цена по длинным позициям",
    "panel.positionsBalance.ShortQty.tt": "Объем коротких позиций",
    "panel.positionsBalance.AverageShort.tt": "Средняя цена по коротким позициям",
    "panel.positionsBalance.GrossExposure.tt": "Валовая экспозиция открытых позиций",
    "panel.positionsBalance.NetExposure.tt": "Чистая экспозиция открытых позиций",
    "panel.positionsBalance.StrikePrice.tt": "Цена страйка",
    "panel.positionsBalance.ExpDate.tt": "Дата экспирации",
    "panel.positionsBalance.PositionValue.tt": "Величина позиции",
    "panel.positionsBalance.SymbType.tt": "Тип инструмента (рынок/категория)",
    "panel.positionsBalance.menu.MoreOneSymbolInfoDisabled.tt": "Выбрано более одного инструмента",
    "panel.positionsBalance.menu.NoSymbolInfoDisabled.tt": "Нет выбранных инструментов",
    "panel.settings.Information": "Информация",
    "panel.settings.saveDefault": "Сохранение успешно!",
    "panel.terceraChart": "График",
    "panel.TerceraSymbolLookupDropDownForm.Add": "Добавить",
    "panel.TerceraSymbolLookupDropDownForm.All": "Все",
    "panel.TerceraSymbolLookupDropDownForm.Bonds": "Облигации",
    "panel.TerceraSymbolLookupDropDownForm.Cancel": "Отмена",
    "panel.TerceraSymbolLookupDropDownForm.CFDs": "CFD",
    "panel.TerceraSymbolLookupDropDownForm.collapceAllButton": "Свернуть все",
    "panel.TerceraSymbolLookupDropDownForm.crypto": "Крипто валюта",
    "panel.TerceraSymbolLookupDropDownForm.Equities": "Акции",
    "panel.TerceraSymbolLookupDropDownForm.ETFs": "ETF'ы",
    "panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.All exchanges": "Все биржи",
    "panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.exchanges": "биржи",
    "panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.No exchanges": "Нет бирж",
    "panel.TerceraSymbolLookupDropDownForm.expandExchangesButton": "Развернуть биржи",
    "panel.TerceraSymbolLookupDropDownForm.expandTypesButton": "Развернуть типы",
    "panel.TerceraSymbolLookupDropDownForm.Forex": "Форекс",
    "panel.TerceraSymbolLookupDropDownForm.Forward": "",
    "panel.TerceraSymbolLookupDropDownForm.Futures": "Фьючерсы",
    "panel.TerceraSymbolLookupDropDownForm.indices": "Индексы",
    "panel.TerceraSymbolLookupDropDownForm.Options": "Опционы",
    "panel.TerceraSymbolLookupDropDownForm.Portfolio": "Портфолио",
    "panel.TerceraSymbolLookupDropDownForm.Select": "",
    "panel.TerceraSymbolLookupDropDownForm.Select all": "Выбрать все",
    "panel.TerceraSymbolLookupDropDownForm.Show additionl info": "Показывать доп. информацию",
    "panel.TerceraSymbolLookupDropDownForm.Spot": "Spot",
    "panel.TerceraSymbolLookupDropDownForm.Spreadbet": "Спредбет",
    "panel.TerceraSymbolLookupDropDownForm.TBill": "T-bill",
    "panel.TerceraSymbolLookupDropDownForm.typeComboBox.All types": "Все типы",
    "panel.TerceraSymbolLookupDropDownForm.typeComboBox.No types": "Нет типов",
    "panel.TerceraSymbolLookupDropDownForm.typeComboBox.types": "типы",
    "panel.timeSales": "Лента сделок",
    "panel.timeSales.Agressor flag": "Направление",
    "panel.timeSales.AgressorFlag.Auction": "Auction",
    "panel.timeSales.AgressorFlag.Buy": "Покупка",
    "panel.timeSales.AgressorFlag.CrossTrade": "Cross Trade",
    "panel.timeSales.AgressorFlag.None": "Нет",
    "panel.timeSales.AgressorFlag.Sell": "Продажа",
    "panel.timeSales.ask": "Аск",
    "panel.timeSales.AskBidSize": "Размер Бид/Аск",
    "panel.timeSales.AskSource": "Аск MPID",
    "panel.timeSales.bid": "Бид",
    "panel.timeSales.BidSource": "Бид MPID",
    "panel.timeSales.BuyerSource": "Купил",
    "panel.timeSales.Exchange": "Источник",
    "panel.timeSales.Instrument": "Инструмент",
    "panel.timeSales.MenuName": "Лента сделок",
    "panel.timeSales.Price": "Цена",
    "panel.timeSales.SellerSource": "Продал",
    "panel.timeSales.Size": "Объем",
    "panel.timeSales.Time": "Время",
    "panel.timeSales.Type": "Тип инстр.",
    "panel.tooltiper.System": "Система",
    "panel.trades": "Сделки",
    "panel.watchlist": "Список наблюдения",
    "panel.watchlist.Ask": "Аск",
    "panel.watchlist.AskSize": "Размер Аск",
    "panel.watchlist.AuctionEndTime": "",
    "panel.watchlist.Bid": "Бид",
    "panel.watchlist.BidSize": "Размер Бид",
    "panel.watchlist.Buy": "Покупка",
    "panel.watchlist.CBAuctionStart": "",
    "panel.watchlist.cell.cancel": "Отмена",
    "panel.watchlist.cell.modify": "Изменение",
    "panel.watchlist.cell.Not allow": "Не разрешено",
    "panel.watchlist.cell.sending": "Отправка",
    "panel.watchlist.cell.ticks": "тики",
    "panel.watchlist.Change": "Изменение",
    "panel.watchlist.ChangeFromOpen": "Изменение с откр.",
    "panel.watchlist.ChangeFromOpen%": "Изменение с откр., %",
    "panel.watchlist.ChangePr": "Изменение, %",
    "panel.watchlist.Close": "Осн. закрытие",
    "panel.watchlist.ClosePostMarket": "Цена закр. постмаркета",
    "panel.watchlist.Comment": "Комментарий",
    "panel.watchlist.Company": "Компания",
    "panel.watchlist.ContractSize": "Размер лота",
    "panel.watchlist.CurrentSession": "Текущая сессия",
    "panel.watchlist.Date": "Дата",
    "panel.watchlist.Descr": "Описание",
    "panel.watchlist.Exchange": "Биржа",
    "panel.watchlist.ExpDate": "Дата экспирации",
    "panel.watchlist.High": "Максимум",
    "panel.watchlist.High13": "#hidden#",
    "panel.watchlist.High26": "#hidden#",
    "panel.watchlist.High52": "#hidden#",
    "panel.watchlist.IndicativeAuctionPrice": "Инд. аукцион. цена",
    "panel.watchlist.Instrument": "Инструмент",
    "panel.watchlist.Last": "Последняя",
    "panel.watchlist.LastSize": "Объем последней сделки",
    "panel.watchlist.Limit.High": "",
    "panel.watchlist.Limit.Low": "",
    "panel.watchlist.Low": "Минимум",
    "panel.watchlist.Low13": "#hidden#",
    "panel.watchlist.Low26": "#hidden#",
    "panel.watchlist.Low52": "#hidden#",
    "panel.watchlist.Main Volume": "Объем глав. сессии",
    "panel.watchlist.Market": "Рынок",
    "panel.watchlist.MarketCap": "Рыночная кап.",
    "panel.watchlist.Match Volume": "Сведенный объем",
    "panel.watchlist.menu.AddSymbol": "Добавить инструменты...",
    "panel.watchlist.menu.clearAll": "Очистить все",
    "panel.watchlist.menu.RemoveInstrument": "Удалить выбранные инструменты",
    "panel.watchlist.menu.SymbolInfo": "Информация по инструменту",
    "panel.watchlist.menu.SymbolLists": "Список инструментов",
    "panel.watchlist.NA": "Н/Д",
    "panel.watchlist.noQuotes": "Последнее обновление",
    "panel.watchlist.NormalMarketSize": "",
    "panel.watchlist.OffExchangeValue": "",
    "panel.watchlist.OffExchangeVolume": "",
    "panel.watchlist.Open": "Открытие",
    "panel.watchlist.OpenInterest": "#hidden#",
    "panel.watchlist.OpenPreMarket": "Цена откр. премаркета",
    "panel.watchlist.Order": "Ордер",
    "panel.watchlist.Order.tt": "Создать ордер",
    "panel.watchlist.Trade": "Сделка",
    "panel.watchlist.Trade.tt": "Создать рыночный ордер",
    "panel.watchlist.PreMarketChange": "Изменение премаркета",
    "panel.watchlist.PreMarketChange%": "Изменение премаркета, %",
    "panel.watchlist.PrevClose": "Цена закрытия",
    "panel.watchlist.PrevSettlementPrice": "Пред. расчет. цена",
    "panel.watchlist.ReferencePrice": "Справочная цена",
    "panel.watchlist.Sell": "Продажа",
    "panel.watchlist.SettlementPrice": "Расчетная цена",
    "panel.watchlist.Spread": "Спред",
    "panel.watchlist.SpreadPr": "Спред, %",
    "panel.watchlist.Ticks": "Тики",
    "panel.watchlist.TicksPostMarket": "Постмаркет,тики",
    "panel.watchlist.TicksPreMarket": "Премаркет,тики",
    "panel.watchlist.Time": "Время",
    "panel.watchlist.TradedValue": "",
    "panel.watchlist.Type": "Тип инструмента",
    "panel.watchlist.Volume": "Объем",
    "panel.watchlist.VolumePostMarket": "Объем постмаркета",
    "panel.watchlist.VolumePreMarket": "Объем премаркета",
    "Panels.MarketDepth.CancelAll": "",
    "panels.orders.buttons.AsMarket": "Изменить на рыночный",
    "panels.orders.buttons.CancelAllButton": "Отменить все",
    "panels.orders.buttons.CancelBuyButton": "Отменить покупку",
    "panels.orders.buttons.CancelByInstrumentButton": "Отменить",
    "panels.orders.buttons.CancelDayButton": "Отменить дневные",
    "panels.orders.buttons.CancelGTCButton": "Отменить GTCs",
    "panels.orders.buttons.CancelLimitsButton": "Отменить Лимит",
    "panels.orders.buttons.CancelSelectedButton": "Отменить выбранные",
    "panels.orders.buttons.CancelSellButton": "Отменить продажу",
    "panels.orders.buttons.CancelStopsButton": "Отменить Стоп",
    "panels.orders.buttons.ModifyOrder": "Редактировать ордер",
    "panels.positions.buttons.closeAll": "Закрыть все",
    "panels.positions.buttons.closeForInstrument": "Закрыть",
    "panels.positions.buttons.closeLong": "Закрыть длинные",
    "panels.positions.buttons.closeNegative": "Закрыть убыточные",
    "panels.positions.buttons.closePositive": "Закрыть прибыльные",
    "panels.positions.buttons.closeSelected": "Закрыть выбранные",
    "panels.positions.buttons.closeShort": "Закрыть короткие",
    "panels.positions.buttons.clxAll": "CLX для всех",
    "panels.positions.buttons.clxForInstrument": "",
    "panels.positions.ButtonsPanel.helpLabel": "Правый клик, чтобы показать/скрыть кнопки",
    "property.panel.accountDetails.Groups.6.Info": "Информация",
    "panel.accountDetails.Rico risks.OVLT": "Лимит на превышение заемных средств.",
    "property.panel.accountDetails.Groups.5.RiskManagement": "Управление рисками",
    "panel.accountDetails.Rico risks.POVA": "Потенциальный объем превышения звемных средств",
    "panel.accountDetails.Groups.6.Info": "Информация",
    "panel.accountDetails.Rico risks.LVLM": "Лимит по долговой нагрузке",
    "panel.accountDetails.Rico risks.CED2": "Кредитование, день 2",
    "panel.accountDetails.Groups.3.AccountActivity": "Активность по счету",
    "panel.accountDetails.Rico risks.WDBL": "Баланс дрступный для вывода",
    "panel.accountDetails.Rico risks.DED0": "Дебетование, день 0",
    "panel.accountDetails.Rico risks.BCKA": "Заблокированный объем",
    "panel.accountDetails.Groups.55.Plans": "Планы",
    "panel.accountDetails.Groups.AccountActivity": "2. Активность по счету",
    "panel.accountDetails.Rico risks.FNLE": "История заемных средств",
    "panel.accountDetails.Rico risks.DED3": "Дебетование, день 3",
    "property.panel.accountDetails.Groups.3.AccountActivity": "Активность счета",
    "panel.accountDetails.Groups.2.Margin": "Маржа",
    "panel.accountDetails.Groups.Rico risks": "Rico risksOverdraft",
    "panel.accountDetails.Rico risks.BUOP": "Баланс по непокрытой операции",
    "panel.accountDetails.Rico risks.BMBM": "BMF заблокированная маржа",
    "panel.accountDetails.Rico risks.CED1": "Кредитование, день 1",
    "panel.accountDetails.Rico risks.FNBL": "Конечный баланс",
    "panel.accountDetails.Groups.General": "1. Общее",
    "panel.accountDetails.Rico risks.CRLM": "Лимит на заемные средства",
    "panel.accountDetails.Rico risks.PRBA": "Прогнозируемый баланс",
    "panel.accountDetails.Rico risks.CED3": "Кредитование, день 3",
    "panel.accountDetails.Groups.1.General": "Общее",
    "panel.accountDetails.Groups.TodayResults": "3. Результаты за день",
    "panel.accountDetails.Groups.Info": "5. Информация",
    "panel.accountDetails.Rico risks.BMFL": "BMF лимит",
    "panel.accountDetails.Rico risks.AVLM": "Доступная маржа",
    "panel.accountDetails.Rico risks.CED0": "Кредитование, день 0",
    "panel.accountDetails.Groups.4.TodayResults": "Результаты за день",
    "panel.accountDetails.Groups.RiskManagement": "4. Риск менеджмент",
    "panel.accountDetails.Rico risks.DED2": "Дебетование, день 2",
    "property.panel.accountDetails.Groups.2.Margin": "Маржа",
    "panel.accountDetails.Rico risks.SKBL": "Баланс по стокам",
    "panel.accountDetails.Rico risks.CRLO": "Лимит на заем опционов",
    "panel.accountDetails.Rico risks.BOPT": "Баланс по опционам",
    "panel.accountDetails.Rico risks.INBA": "Баланс инициализации",
    "panel.AccountDetailsPanel": "Состояние счета",
    "panel.accountDetails.Rico risks.LILO": "Лимит на долговую нагрузку по опционам",
    "panel.accountDetails.Rico risks.D3PB": "D3 прогнозируемый баланс",
    "property.panel.accountDetails.Groups.1.General": "Общие",
    "panel.accountDetails.Rico risks.LEVE": "Долговая нагрузка",
    "panel.accountDetails.Groups.5.RiskManagement": "Риск менеджмент",
    "panel.accountDetails.Rico risks.DED1": "Дебетование, день 1",
    "panel.accountDetails.Rico risks.D1PB": "D1 прогнозируемый баланс",
    "panel.accountDetails.Rico risks.OPBL": "Баланс по опционам",
    "property.panel.accountDetails.Groups.4.TodayResults": "Результаты за сегодня",
    "PanelSettingsScreen.Text": "",
    "PanelSettingsScreen.TextSmall": "- настройки",
    "Parabolic Time/Price System": "",
    "period.Day": "день",
    "period.Days": "д.",
    "period.Hour": "ч.",
    "period.Hours": "Час.",
    "period.Min": "мин.",
    "period.Mins": "Мин.",
    "period.Month": "мес.",
    "period.Months": "Мес.",
    "period.Week": "нед.",
    "period.Weeks": "Нед.",
    "period.Year": "Год",
    "period.Years": "г.",
    "Pivot Point Moving Average": "",
    "PivotPoint": "",
    "PopupMultiCkeckBox.All": "Все",
    "Português": "",
    "portfolio.returns.tableHeader.date": "Период",
    "portfolio.returns.tableHeader.return": "Доходность портфеля",
    "portfolio.returns.tableHeader.balance": "Стоимость портфеля",
    "portfolio.returns.StatisticSwitcher.Portfolio": "Статистика доходности портфеля",
    "portfolio.returns.StatisticSwitcher.Asset": "Статистика доходности активов",
    "portfolio.DiagramSettings.header": "Диаграммы распределений - настройки",
    "portfolioReturns.AssetReturnText": "Доходность",
    "Price Channel": "",
    "Price Oscillator": "",
    "ProductType.CarryForward": "",
    "ProductType.Delivery": "",
    "ProductType.General": "Общий",
    "ProductType.Intraday": "",
    "property.%": "%",
    "property.Additional": "Дополнительно",
    "property.AllPrice": "Статистика по ценам",
    "property.appearance": "Вид",
    "property.Ask": "Аск",
    "property.Author": "Автор",
    "property.autosize": "Авторазмер столбцов",
    "property.autoYScale.Auto": "Авто",
    "property.autoYScale.Manual": "Ручное",
    "property.Average": "Средняя",
    "property.Backgr": "",
    "property.Backgr. top": "Цвет фона сверху",
    "property.BarPattern": "Баровый паттерн",
    "property.barsHiLowColor": "Линия",
    "property.BarStatistics": "Статистика по барам",
    "property.Bid": "Бид",
    "property.bodyColor": "Тело",
    "property.borderColor": "Граница",
    "property.BottomCenter": "Внизу в центре",
    "property.BottomLeft": "Внизу слева",
    "property.BottomRight": "Внизу справа",
    "property.Browser": "Браузер",
    "property.Buy": "Купить",
    "property.BuyColor": "Покупка",
    "property.cAsk": "Размер Аск",
    "property.cASK": "По Аск",
    "property.cASKHI": "Выше Аск",
    "property.cBid": "Размер Бид",
    "property.cBID": "По Бид",
    "property.cBIDLO": "Ниже Бид",
    "property.cellscin.useSellBuy": "Поменять кнопки местами",
    "property.Change": "Изменение",
    "property.ChangePercent": "Изменение, %",
    "property.Chart": "График",
    "property.Chart OE": "Создать ордер на Графике",
    "panel.accountDetails.Rico risks.D2PB": "D2 прогнозируемый баланс",
    "property.chart.isBackground": "Фон",
    "property.chart.line": "Линия",
    "property.chart.ray": "Луч",
    "property.chart.rayStyle": "Стиль луча",
    "property.chart.showAngle": "Показать угол",
    "property.chart.showingOE": "Показать панель Создать ордер",
    "property.chart.stickTo": "Прикрепить к",
    "property.chart.tools.back": "Закрасить фигуру",
    "property.chart.tools.fibretr.font": "Шрифт надписи",
    "property.chart.tools.fibretr.fontcolor": "Цвет шрифта",
    "property.ChartAnalyseToolsToolBar": "Панель инсрументов для анализа",
    "property.ChartDrawingToolsToolBar": "Панель инсрументов рисования",
    "property.cLimit": "Лимит ордер",
    "property.Close": "Закрытие",
    "property.Cluster.ClusterUpDownColors": "Кластер",
    "property.Cluster.ColorScheme": "Раскраска",
    "property.Cluster.ColorScheme.ByDelta": "По дельте",
    "property.Cluster.ColorScheme.ByDelta.Colors": "Цвет",
    "property.Cluster.ColorScheme.ByDelta.Colors.BuyColor": "Покупка",
    "property.Cluster.ColorScheme.ByDelta.Colors.SellColor": "Продажа",
    "property.Cluster.ColorScheme.ByTrades": "По сделкам",
    "property.Cluster.ColorScheme.ByVolume": "По объему",
    "property.Cluster.ColorScheme.Color": "Цвет",
    "property.Cluster.ColorScheme.None": "Нет",
    "property.Cluster.CustomStep": "Польз. шаг",
    "property.Cluster.Down": "Низ",
    "property.Cluster.FilterValue": "Значение фильтра",
    "property.Cluster.Font": "Шрифт",
    "property.Cluster.FontColor": "Цвет шрифта",
    "property.Cluster.HighlightMax": "Подсвечивать максимум",
    "property.Cluster.HighlightMax.Colors": "Цвета",
    "property.Cluster.Operator": "Оператор",
    "property.Cluster.PriceStep": "Шаг цены",
    "property.Cluster.PriceStep.Custom": "Пользовательский",
    "property.Cluster.PriceStep.MinTick": "Минимальный тик",
    "property.Cluster.ShowOnlySimilarDelta": "Показывать только одинак. дельты",
    "property.Cluster.ShowValueFilter": "Фильтр значений",
    "property.Cluster.Up": "Вверх",
    "property.cMIDLO": "В спреде",
    "property.Color": "Цвет",
    "property.ColorBy": "Цвет",
    "property.ColoringMode": "Цветовой режим",
    "property.Colors": "Цвета",
    "property.colors_param": "Цвета",
    "property.colorScheme": "Стиль",
    "property.ColorsSeparatorGroup": "Цвета",
    "property.ColouringMode": "Цветовой режим",
    "property.Comments": "Комментарии",
    "property.Company": "Компания",
    "property.Confirmations": "Подтверждения",
    "property.connection": "Подключение",
    "property.Connection": "Подключение",
    "property.ContentAlignment": "Выравнивание",
    "property.Copyrights": "Авторские права",
    "property.cQUOTE": "Котировки",
    "property.CreateColor": "Создать",
    "property.crossHairFontColor": "Цвет шрифта",
    "property.cStop": "Стоп ордер",
    "property.currency": "Валюта",
    "property.CustomYMarkingValue": "Мин. шаг сетки",
    "property.Data Box": "Информационное окно",
    "property.Data Style": "Стиль данных",
    "property.Day": "Дневной",
    "property.DaySeparatorsVisibility": "Дневные разделители",
    "property.DefaultProductType": "Тип продукта по умолчанию",
    "property.Defaults": "Торговые",
    "property.Description": "Описание",
    "property.deviation": "отклонение",
    "property.dealTickets.showDealTickets": "Показывать оповещения в строке состояния приложения",
    "property.dealTickets.showDealTickets.ToolTip": "Определяет, будут ли оповещения отображаться в строке состояния приложения",
    "property.dK": "множитель станд.отклонения",
    "property.Doji": "Doji",
    "property.Downband deviation in %": "Отклонение для downBand в %",
    "property.email": "Адреса эл. почты (используйте \",\" как разделитель)",
    "property.Emulator": "Эмулятор",
    "property.enable": "Разрешить",
    "property.Enable Sounds": "Включить звук",
    "property.Example": "Пример",
    "property.Exponential": "Экспоненциальный",
    "property.fib.Arc": "Дуги Фиб.",
    "property.Fibonacci": "Фибоначчи",
    "property.Filters": "Фильтры",
    "property.FirstValue": "Первое значение",
    "property.FitDrawings": "Вписывать рисунки",
    "property.FitHighLow": "Вписывать дневной Макс./Мин.",
    "property.FitIndicator": "Вписывать индикаторы",
    "property.FitOrders": "Вписывать ордера/позиции",
    "property.FOK": "FOK",
    "property.Font": "Шрифт",
    "property.FontColor": "Цвет текста",
    "property.Forex": "Форекс",
    "property.Forwards": "Форварды",
    "property.Futures": "Фьючерсы",
    "property.FX Cell": "FX ячейка",
    "property.G": "G:",
    "property.General": "Общие",
    "property.general.defaultSymbol": "Инструмент",
    "property.general.OvernightMarginNotificationMessage": "",
    "property.general.showLotsFX": "Отображать объем в лотах",
    "property.gr_type": "Стиль",
    "property.Gradient": "Градиент",
    "property.GraphicType.Absolute": "Абсолютная",
    "property.GraphicType.Log": "Логарифмическая",
    "property.GraphicType.Relative": "Относительная",
    "property.GridPrice": "Цена (горизонтально)",
    "property.GridPriceHighLight": "Цвет и стиль сетки",
    "property.GridTime": "Время (вертикально)",
    "property.GTC": "GTC",
    "property.GTD": "GTD",
    "property.HeaderFont": "Шрифт",
    "property.HeaderText": "Текст заголовка",
    "property.High": "Максимум",
    "property.HighlightMarkingsStep": "Подсвечивать шаг сетки",
    "property.HistogramLineColor": "Histogram Line",
    "property.HistoryType": "Тип данных",
    "property.HorizontalLine.LeftRay": "Левый луч",
    "property.hostName": "Имя хоста",
    "property.hotkeyManager.ShowVolume": "Показать/Спрятать объем",
    "property.infowindow_font": "Шрифт",
    "property.infoWindow_fore_back_color": "Цвет",
    "property.infowindow_visible": "Показывать информационное окно",
    "property.Instrument Types": "Ордера по умолчанию по типу",
    "property.Intrinsic": "Внутренняя стоимость",
    "property.IOC": "IOC",
    "property.IsBackground": "Фон",
    "property.OnTop": "Поверх графика",
    "property.IsBarsVisible": "# Бары",
    "property.IsChangeVisible": "Изменение",
    "property.IsCloseVisible": "Закрытие",
    "property.IsDateVisible": "Дата",
    "property.IsHighVisible": "Макс.",
    "property.IsLowVisible": "Мин.",
    "property.IsOpenVisible": "Открытие",
    "property.IsRoundedAvgPrice": "Округленная средняя цена открытия",
    "property.isShowToolTips": "Всплывающие подсказки",
    "property.isShowToolTipsGrop": "",
    "property.IsSyncActive": "Синхронизировать с графиками",
    "property.IsTicksVisible": "Тики",
    "property.IsTimeVisible": "Время",
    "property.IsVolumeVisible": "Объем",
    "property.LabelColors": "Цвета ярлыков",
    "property.Language": "Язык",
    "property.Last": "Последняя",
    "property.LastPrice": "Последняя цена",
    "property.lastPrice_AskIndicatorColor": "Аск",
    "property.lastPrice_BidIndicatorColor": "Бид",
    "property.lastPrice_LastIndicatorColor": "Последняя",
    "property.lastPrice_SpreadIndicatorColor": "Цвет области спреда",
    "property.Left ray": "Левый луч",
    "property.Level": "Уровень",
    "property.Limit": "Лимит",
    "property.limitOffsetTicks": "Смещение лимит, тики",
    "property.Line": "Линия",
    "property.line.lineName": "Имя линии",
    "property.line.plateVisible": "Показать маркер линии {0}",
    "property.line.timeShift": "Смещение по времени",
    "property.line.value": "Значение",
    "property.line.Visible": "Видимость",
    "property.line.yFactor": "фактор",
    "property.Linear Weighted": "Линейно-взвешенный",
    "property.lineProperties": "Свойства",
    "property.LOC": "LOC",
    "property.logBase": "База логов",
    "property.Logo": "Лого",
    "property.LOO": "LOO",
    "property.LotsMode": "Показать размер в",
    "property.Low": "Минимум",
    "property.Mail Server": "Почтовый сервер",
    "property.Manual": "Ручной",
    "property.Market": "Рыночный",
    "property.MarketCap": "Рыночная кап.",
    "property.MarketDepth.ColoringMethod": "Метод раскраски",
    "property.MarketProfile.ColorScheme": "Раскраска",
    "property.MarketProfile.ColorScheme.Color": "Цвет",
    "property.MarketProfile.ColorScheme.GradientColor": "Область",
    "property.MarketProfile.ColorScheme.PairColor.Color": "Цвет",
    "property.MarketProfile.ColorScheme.VolumeColor": "Цвет",
    "property.MarketProfile.Font": "Шрифт",
    "property.MarketProfile.PointOfControl": "Управляющая точка",
    "property.MarketProfile.PointOfControlStyle": "Стиль управляющей точки",
    "property.MarketProfile.ShowSingles": "Показать одиночные",
    "property.MarketProfile.SinglesStyle": "Стиль одиночных",
    "property.MarketProfile.ValueArea": "Область значений",
    "property.MarketProfile.ValueAreaStyleHorisontal": "Стиль горизонтальной линии",
    "property.MarketProfile.ValueAreaStyleVertical": "Стиль вертикальной линии",
    "property.MarketProfile.ValuePercents": "Значение, %",
    "property.Medium": "Средний",
    "property.message": "Сообщение",
    "property.method": "метод",
    "property.MirrorShow": "Зеркальный вид",
    "property.MOC": "MOC",
    "property.Mode": "Режим",
    "property.Mode.Trades": "Сделки",
    "property.Modified": "Модифицированный",
    "property.MonthSeparatorsVisibility": "Месячные разделители",
    "property.MOO": "MOO",
    "property.name": "Имя",
    "property.Name": "Имя",
    "property.News": "Новости",
    "property.None": "Нет",
    "property.Note": "Пометка",
    "property.Notifications": "Уведомления",
    "property.NotSet": "Не установлено",
    "property.Number": "Количество",
    "property.OCO": "OCO",
    "property.OE_AddBidAddAsk": "Купить по Бид/Продать по Аск",
    "property.OE_ShowBuyMarketSellMarket": "Купить по рынку/Продать по рынку",
    "property.OE_ShowSellBidBuyASk": "Продать по Бид/Купить по Аск",
    "property.OE_ShowSLTP": "Закр. ордера (СЛ/ТП)",
    "property.OE_Small": "Показывать узкую панель",
    "property.OE_Small.ToolTip": "Если отмечено, будет использоваться узкий вид панели",
    "property.oe.confirmClosePosition": "Подтверждать закрытие позиции",
    "property.oe.confirmOrderCancel": "Подтверждать отмену ордера",
    "property.oe.confirmOrderChange": "Подтверждать редактирование ордера",
    "property.oe.confirmOrderCreate": "Подтверждать размещение ордера",
    "property.oe.confirmReversePosition": "Подтверждать реверс позиций",
    "property.OffsetMode": "Показать смещение в",
    "property.OnClose": "На Закрытие",
    "property.OnOpen": "На Открытие",
    "property.Open": "Открытие",
    "property.Options": "Общие",
    "property.Options.ToolTip": "",
    "property.Order Entry": "Создание ордера",
    "property.OrderBuyStyleWidth": "Покупка",
    "property.OrderSellStyleWidth": "Продажа",
    "property.OverlayVisible": "Включить Поводырь",
    "property.Panel name": "Название панели",
    "property.panel.accountDetails.Groups.1.General": "Общие",
    "property.panel.accountDetails.Groups.2.Margin": "Маржа",
    "property.panel.accountDetails.Groups.3.AccountActivity": "Активность счета",
    "property.panel.accountDetails.Groups.4.TodayResults": "Результаты за сегодня",
    "property.panel.accountDetails.Groups.5.RiskManagement": "Управление рисками",
    "property.panel.accountDetails.Groups.6.Info": "Информация",
    "property.Panels": "Панели",
    "property.Parameters": "Параметры",
    "property.password": "Пароль",
    "property.period": "период",
    "property.Period of indicator": "Период индикатора:",
    "property.Period of indicator:": "Период индикатора",
    "property.Period of Linear Regression": "Период линейной регрессии",
    "property.Period of MA for envelopes": "Период скольз.сред. для конвертов",
    "property.Period of MA1": "Период скольз.ср.1:",
    "property.Period of MA2": "Период скольз.ср.2:",
    "property.Period of momentum:": "Период моментума",
    "property.Period of Moving Average": "Период скользящего среднего",
    "property.Period of price channel": "Период канала цен",
    "property.Period of Simple Moving Average": "Период простого ск.ср.",
    "property.Period of Smoothed Moving Average": "Период сглаженного скользящего среднего",
    "property.Periods": "Периоды",
    "property.Calсulation cycle, bars": "Расчетный цикл, бары",
    "property.Calculation price": "Расчетная цена",
    "property.pin": "PIN",
    "property.pips": "Пипсы",
    "property.POCTypes.Line": "Линия",
    "property.POCTypes.Marker": "Маркер",
    "property.POCTypes.None": "Нет",
    "property.points": "Точки",
    "property.Points": "Точки",
    "property.PositionBuyStyleWidth": "Длинная",
    "property.PositionSellStyleWidth": "Короткая",
    "property.Previous": "Прошлый",
    "property.price": "цена",
    "property.Price Scale": "Шкала цены",
    "property.PriceIndicatorDrawingType": "Индикатор цены",
    "property.PriceIndicatorDrawingType.None": "Нет",
    "property.PriceIndicatorDrawingType.ScaleMarker": "Метка на шкале",
    "property.PriceIndicatorDrawingType.ScaleMarkerWithLine": "Метка на шкале с линией",
    "property.PriceScaleAxisColor": "Ось",
    "property.PriceScaleBackColor": "Фон оси",
    "property.PriceScaleFont": "Шрифт",
    "property.PriceScaleTextColor": "Цвет текста",
    "property.PriceType": "Тип цены",
    "property.ProfileColoringTypes.DeltaBars": "По дельте баров",
    "property.ProfileColoringTypes.DeltaPrice": "По дельте цен",
    "property.ProfileColoringTypes.DeltaProfile": "По дельте профиля",
    "property.ProfileColoringTypes.Single": "Одиночный цвет",
    "property.ProfileColoringTypes.UpdownBars": "Бары Вверх/Вниз",
    "property.ProfileColoringTypes.UpdownProfile": "Профиль Вверх/Вниз",
    "property.ProfileColoringTypes.VolumeBars": "По объему баров",
    "property.ProfileColoringTypes.VolumePrice": "По объему цен",
    "property.protrader.showOrders": "Ордера",
    "property.Range": "Период",
    "property.Ray style": "Стиль луча",
    "property.Relative": "Relative",
    "property.relativeCalcType": "Начальная цена",
    "property.RelativeCalculationType.BeginOfTheData": "Начало данных",
    "property.RelativeCalculationType.BeginOfTheDay": "Начало дня",
    "property.RelativeCalculationType.BeginOfTheScreen": "Начало экрана",
    "property.relativeScaleType": "Тип шкалы",
    "property.Reserve": "Резерв",
    "property.Reverse": "Реверс",
    "property.Right ray": "Правый луч",
    "property.routing.account": "Счет",
    "property.routing.orderType": "Тип ордера по умолчанию",
    "property.RSI Method:": "RSI Метод",
    "property.RSI Period:": "RSI Период",
    "property.SCRIPTS_SUBGROUP": "Скрипты",
    "property.ScrollBar": "Полоса прокрутки",
    "property.SecondValue": "Второе значение",
    "property.secure": "Безопасность",
    "property.Secure": "Безопасность",
    "property.Selection": "Выбор",
    "property.Sell": "продать",
    "property.SellColor": "Продажа",
    "property.SeparatorGroup.ActiveOrders": "Рабочие ордера",
    "property.SeparatorGroup.additional": "Дополнительно",
    "property.SeparatorGroup.Additional": "Дополнительно",
    "property.SeparatorGroup.Appearance": "Внешний вид",
    "property.SeparatorGroup.CrossHair": "Перекрестие курсора",
    "property.SeparatorGroup.ExecutedOrders": "Сделки",
    "property.SeparatorGroup.Grid": "Сетка",
    "property.SeparatorGroup.HighlightDate": "Подсвечивать дату",
    "property.SeparatorGroup.HighlightPrices": "Подсвечивать цены",
    "property.SeparatorGroup.OpenPositions": "Открытые позиции",
    "property.SeparatorGroup.ScaleMarkings": "Метки на шкале",
    "property.SeparatorGroup.ScaleType": "Тип шкалы",
    "property.SeparatorGroup.SeparatorOEWidth": "Вид",
    "property.SeparatorGroup.Show": "Показать",
    "property.SeparatorGroup.TimeToNextBar": "Время до следующего бара",
    "property.SeparatorGroup.WindowColors": "Цвета окна",
    "property.SeparatorGroup.Zoom": "Масштабирование",
    "property.SeparatorViewButtons": "Показывать кнопки",
    "property.Sesonality": "Сезонность",
    "property.shift": "смещение",
    "property.showAllSessions": "Показать расширенную сессию",
    "property.ShowEmptyBars": "Промежутки в истории",
    "property.showEvents": "Видимые",
    "property.ShowFullArc": "Показывать всю дугу",
    "property.ShowHighLow": "Дневные макс./мин.",
    "property.showOrders": "Видимые",
    "property.showPositions": "Видимые",
    "property.ShowPrevCloseLine": "Линия пред. закрытия",
    "property.ShowTimeToNextBar": "Видимый",
    "property.ShowTotal": "Показать итог",
    "property.ShowVisualTradingOnLeftSide": "Показывать активные позиции/ордера возле левой границы",
    "property.showVolume": "Видимый",
    "property.showVolumeMarker": "Показать маркер значения на шкале",
    "property.Simple": "Простой",
    "property.SizeBy": "Размер",
    "property.sltpOffsetMode": "Показать цену СЛ/ТП со смещением",
    "property.Smoothing": "Сглаживание",
    "property.solidPriceColor": "Область",
    "property.sounds_param": "Звуки",
    "property.Sources prices for MA": "Источник цен для скольз.сред.",
    "property.Specific": "Специфический",
    "property.SpreadType": "Тип спреда",
    "property.SpreadType.Area": "Область",
    "property.SpreadType.Line": "Линия",
    "property.SpreadType.LineWithLabel": "Линия с ценой",
    "property.SpreadType.None": "Никакой",
    "property.Step of parabolic SAR system": "Шаг параболической SAR системы:",
    "property.Stick to": "Приклеить к",
    "property.stickTo": "Прикрепить к:",
    "property.Stocks": "Акции",
    "property.Stop": "Стоп",
    "property.Stop Limit": "Стоп лимит",
    "property.Stop-loss": "Стоп лосс",
    "property.style": "Стиль:",
    "property.subscriptions.confirmSubscribeAndUnsubscribe": "Показать сообщение о подписке/отказе от подписки",
    "property.Sum": "Сумма",
    "property.Symbol": "Инструмент",
    "property.Symbols": "Инструменты",
    "property.T": "T:",
    "property.Take-profit": "Тейк профит",
    "property.text": "Текст",
    "property.TIF": "Срок действия Рыночного ордера",
    "property.TIF_Limit_And_Stop_Limit": "Срок действия Лимит (Стоп лимит)",
    "property.TIF_Stop": "Срок действия Стоп ордера",
    "property.Time Scale": "Шкала времени",
    "property.TimeLine": "Временная стоимость",
    "property.TimelinePreview": "Предпросмотр графика на полосе прокрутки",
    "property.TimeScaleAxisColor": "Ось",
    "property.TimeScaleBackColor": "Фон оси",
    "property.TimeScaleFont": "Шрифт",
    "property.TimeScaleImportentFont": "Шрифт важных дат",
    "property.TimeScaleTextColor": "Цвет шрифта",
    "property.TimeToNextBarColor": "Цвет",
    "property.TimeToNextBarFont": "Шрифт",
    "property.Title": "Заголовок",
    "property.TopCenter": "Вверху в центре",
    "property.TopLeft": "Вверху слева",
    "property.TopRight": "Вверху справа",
    "property.Tr. stop": "Тр. стоп",
    "property.trackShowCrossHair": "Видимый",
    "property.Trading": "Торговля",
    "property.Trading Defaults": "Торговля",
    "property.Type of moving average": "Тип скользящего среднего",
    "property.Type of Moving Average": "Тип скользящего среднего",
    "property.Typical": "Стандартный",
    "property.Upband deviation in %": "Отклонение для UpBand в %",
    "property.Update": "Обновление",
    "property.UseCustomYMarkings": "Пользовательская сетка",
    "property.useStopLimitInsteadStop": "Использовать Стоп лимит вместо Стоп",
    "property.user": "Логин",
    "property.useSSL": "Secure",
    "property.Value of confidence interval": "Значение доверительных интервалов:",
    "property.ValueColors": "Цвета значений",
    "property.VATypes.Horizontal": "Горизонтальная",
    "property.VATypes.None": "Нет",
    "property.VATypes.ToNextProfile": "До след. профиля",
    "property.VATypes.Vertical": "Вертикальная",
    "property.vendorName": "Вендор",
    "property.View": "Вид",
    "property.ViewSeparatorGroup": "Вид",
    "property.Visibility": "Видимость",
    "property.VisibilitySeparatorGroup": "Видимость",
    "property.Visible": "Видимый",
    "property.Visual Trading": "Визуальная торговля",
    "property.Volume": "Объем",
    "property.Volume.Font": "Шрифт",
    "property.Volume.FontColor": "Цвет шрифта",
    "property.Volume.UseTicksData": "Использовать реальный тиковый объем",
    "property.VolumeColoringMode": "Цветовая схема индикаторов объема",
    "property.VolumeDownColor": "Отрицательный",
    "property.VolumeFixedColor": "Равный",
    "property.VolumeHeightPercent": "Высота, %",
    "property.VolumeMode.BuyVolume": "Объем покупок",
    "property.VolumeMode.Delta": "Дельта",
    "property.VolumeMode.MaxTickVolume": "Макс. объем тика",
    "property.VolumeMode.SellVolume": "Объем продаж",
    "property.VolumeMode.TotalVolume": "Суммарный объем",
    "property.VolumeMode.Trades": "Сделки",
    "property.VolumeOpacityPercent": "Прозрачность",
    "property.VolumeProfile": "Профиль объема",
    "property.VolumeUpColor": "Положительный",
    "property.VWAP": "Индикатор VWAP",
    "property.Warnings": "Предупреждения",
    "property.WatermarkColor": "",
    "property.WeekSeparatorsVisibility": "Недельные разделители",
    "property.Weighted": "Взвешенный",
    "property.wickColor": "Фитиль",
    "property.width": "Ширина:",
    "property.Win num": "Окно графика с индикатором",
    "property.Win num.addWin": "Новое",
    "property.Win num.main": "Главное",
    "property.Y factor": "Y фактор:",
    "property.YearSeparatorsVisibility": "Годовые разделители",
    "property.ZeroLine": "Линия нуля",
    "property.ZoomValue": "Шаг шкалы",
    "propery.MainPriceRenderer.Down": "",
    "propery.MainPriceRenderer.Negative": "",
    "propery.MainPriceRenderer.Positive": "",
    "propery.MainPriceRenderer.Up": "",
    "Relative Strength Index": "",
    "reports.#subgroup.Revenue plan.0": "Комиссия",
    "reports.#subgroup.Revenue plan.1": "Спреды",
    "reports.#subgroup.Revenue plan.2": "Свопы",
    "reports.#subgroup.Revenue plan.3": "Распределение",
    "reports.#subgroup.Revenue plan.4": "Части инструмента",
    "reports.$broker": "брокер",
    "reports.$broker_create_order": "брокер создал ордер",
    "reports.$broker_refuse_with_answer": "брокер отказался отвечать",
    "reports.$client": "клиент",
    "reports.$client_request": "запрос клиента",
    "reports.$close_position": "закрыть позицию",
    "reports.$confirm_order_removed": "подтвердить открытие ордера",
    "reports.$enter_system": "войти в систему",
    "reports.$executed": "выполнен",
    "reports.$leave_system": "выйти из системы",
    "reports.$open_position": "открыть позицию",
    "reports.$order": "ордер",
    "reports.$request_order_removed": "запрошенныйордер удален",
    "reports.4CastNews": "4CastNews",
    "reports.Accepted": "Принят",
    "reports.accname": "Имя счета",
    "reports.accoperreqstatus": "Статус",
    "reports.account": "Счет",
    "reports.Account": "Счет",
    "reports.Account Name": "Имя счета",
    "reports.Account operation confirmation.": "Подтверждение операции со счетом",
    "reports.Account Operation Report": "Отчет об операциях по счету",
    "reports.Account operation report (settled)": "Отчет об операциях по счету (поставленных)",
    "reports.Account Operation Report Summary": "Итоговый отчет по операциям по счету",
    "reports.Account operations report": "Отчет об операциях по счету",
    "reports.Account Operations Report": "Отчет по операциям по счету",
    "reports.Account Performance Report": "Отчет по производительности счета",
    "reports.Account Statement": "Отчет о статусе счета",
    "reports.Account statement in defined currency report": "Отчет о состоянии счета в заданной валюте",
    "reports.Account Statement on chosen moment report": "Отчет о состоянии счета в данный момент времени",
    "reports.Account Statement Report": "Отчет о статусе счета",
    "reports.account_ID": "ID счета",
    "reports.account.balance": "Баланс",
    "reports.account.crossinstrument": "Кросс-инструмент",
    "reports.account.currency": "Валюта",
    "reports.account.ismaster": "Является Мастером",
    "reports.account.mirroraccounts": "Зеркальные счета",
    "reports.account.name": "Имя счета",
    "reports.account.status": "Статус",
    "reports.account.type": "Тип",
    "reports.account.userid": "ID пользователя",
    "reports.account.visibility.editforsuper": "Редактировать супер-группу",
    "reports.account.visibility.editforthis": "Редактировать эту группу",
    "reports.account.visibility.tradeforsuper": "Сделки для супер-группы",
    "reports.account.visibility.tradeforthis": "Сделки для этой группы",
    "reports.account.visibility.viewforsuper": "Показать супер-группу",
    "reports.account.visibility.viewforthis": "Посмотреть эту группу",
    "reports.accountcurrency": "Валюта",
    "reports.AccountId": "Счет",
    "reports.accountname": "Счет",
    "reports.AccountOperationAmount": "Объем",
    "reports.accountoperationid": "ID операции по счету",
    "reports.accountPassword": "Пароль счета",
    "reports.accounts": "Счет(а)",
    "reports.accountUsername": "Имя счета",
    "reports.AccruedInterest": "Начисление по процентам",
    "reports.ACM Feed": "ACM Feed",
    "reports.action": "Направление",
    "reports.actionname": "Имя действия",
    "reports.Activated": "",
    "reports.Active Orders Report": "Отчет об активных ордерах",
    "reports.actualprice": "Актуальная цена",
    "reports.actualtime": "Актуальное время",
    "reports.addliquidityrebate": "Добавление ликвидности",
    "reports.address": "Адрес",
    "reports.Adjusment": "Согласование",
    "reports.Adjustment": "Корректировка",
    "reports.ADMINISTRATION_RULES": "Правила администрирования",
    "reports.AK Equity Algo": "AK Equity Algo",
    "reports.AK Genex Equity": "AK Genex Equity",
    "reports.AK Genex Turdex": "AK Genex Turdex",
    "reports.alertNotify": "Алерт",
    "reports.All brokers": "Все брокеры",
    "reports.All data received.": "Все данные получены.",
    "reports.ALLOW_AUTO_APPROVE_TRANSFER_REQUEST": "Разрешить авто подтверждение запросов на перевод",
    "reports.ALLOW_AUTO_ORDER_TYPE": "Автоматически устанавливать тип ордера (Лимит, Стоп, Рыночный)",
    "reports.ALLOW_AUTO_RESET_PWD": "Разрешить автосброс пароля",
    "reports.ALLOW_CLOSE_POSITIONS_ON_STOP_OUT": "Закрыть позиции по Стоп аут",
    "reports.ALLOW_DEMO_AUTO_REGISTRATION": "Разрешить автоматическую регистрацию демо пользователей",
    "reports.ALLOW_GENERATE_L3": "Разрешить генерацию L3",
    "reports.ALLOW_GENERATE_PRICE_FROM_SPOT": "Разрешить генерацию цен в зависимости от спот",
    "reports.ALLOW_HEDGE_BY_SPOT": "Хеджирование по споту",
    "reports.ALLOW_HEDGE_MARGIN": "Разрешить маржу хеджирования",
    "reports.ALLOW_JOIN_POSITIONS": "Хеджировать односторонние позиции",
    "reports.ALLOW_LAZY_LOAD_QUOTES": "Отложенная загрузка котировок",
    "reports.ALLOW_LIVE_AUTO_REGISTRATION": "Разрешить автоматическую регистрацию",
    "reports.ALLOW_ONE_BROKER_FOR_ALL_OPERATION": "Один брокер для всех операций",
    "reports.ALLOW_ORDER_AUTOEXECUTION_IS_MARKET_PRICE": "Выполнять ордера по рыночной цене (если цена ордера не определена)",
    "reports.ALLOW_PRICE_EXECUTION_MAPPING": "Отображение исполнения цен",
    "reports.ALLOW_PWD_VERIFICATION": "Проверять пароль по СМС",
    "reports.ALLOW_REFUSE_IF_BROKER_OFFLINE": "Отказывать, если брокер не в сети",
    "reports.ALLOW_REMOVE_ORDERS_ON_STOP_OUT": "Удалить ордера по Стоп аут",
    "reports.ALLOW_SAVE_HISTORY": "Сохранить историю котировок инструмента",
    "reports.ALLOW_SAVE_QUOTE_LOGS": "Сохранять журнал котировок",
    "reports.ALLOW_SAVE_QUOTE_TICKS": "Разрешить сохранение тиков котировок",
    "reports.ALLOW_SAVE_QUOTES": "Разрешить сохранение котировок",
    "reports.ALLOW_SAVE_TRADE_LOGS": "Сохранять торговый журнал",
    "reports.ALLOW_SHORT_POSITIONS": "Разрешить короткие позиции",
    "reports.ALLOW_SKIP_REVERSED_PRICE": "Пропустить все 'обратные' котировки",
    "reports.ALLOW_SPIKE_FILTER": "Разрешить спайк фильтр",
    "reports.ALLOW_STOP_AVARE_MARGIN": "Использовать стоп для маржи",
    "reports.ALLOW_STORE_USERS_FILES": "Разрешить сохранять файлы пользователей",
    "reports.ALLOW_USE_CERT": "Разрешить использоваться подписи в запросах",
    "reports.ALLOW_USER_LEVERIGE": "Разрешить кредитное плечо",
    "reports.ALLOW_USERS_EMAIL_DUPLICATE": "Разрешить дублирование почтовых ящиков для демо аккаунтов",
    "reports.ALLOW_VISIBLITY": "Разрешить видимость",
    "reports.allowLimitOrders": "Разрешить Лимит ордера",
    "reports.allowStopOrders": "Разрешить Стоп ордера",
    "reports.already in processing": "все еще в обработке",
    "reports.amount": "Объем",
    "reports.Amount": "Объем",
    "reports.Amount filled": "Заполненный объем",
    "reports.amountperside": "Сумма",
    "reports.An error occurred during sending mail using user settings. Will use default settings.": "Возникла ошибка при отправке почты, используя настройки пользователя. Будут использованы настройки по умолчанию.",
    "reports.Aphelion": "Aphelion",
    "reports.APPLICATION.NAME": "ИМЯ ПРИЛОЖЕНИЯ",
    "reports.Approved": "Подтвержденный",
    "reports.asset": "Актив",
    "reports.AuditReport": "Отчет об изменеиях в бэк-офисе",
    "reports.Authorization Error: Operation is nor allowed": "Ошибка авторизации: Операция запрещена",
    "reports.autotrade": "Автоторговля",
    "reports.Average filled price": "Средняя цена",
    "reports.Back Office Changes Report": "Отчет изменений в Бэк-Офисе",
    "reports.balance": "Баланс",
    "reports.Balance": "Баланс",
    "reports.Balance by Instrument Report": "Отчет о балансе по инструменту",
    "reports.Balance By Instrument Report": "Отчет баланса по инсчтрументам",
    "reports.Balance Operations Report": "Отчет балансовых операций",
    "reports.Balance Report": "Отчет о балансе",
    "reports.Balance Report(copy)": "Отчет о балансе(копия)",
    "reports.Balance Summary report": "Отчет о суммарном балансе",
    "reports.Balance Summary Report": "Отчет о суммарном балансе",
    "reports.balance_after": "Баланс после",
    "reports.balance_before": "Баланс до",
    "reports.balance.amount": "Объем",
    "reports.balance.buy": "Покупка",
    "reports.balance.everbuy": "Средняя покупка",
    "reports.balance.eversell": "Средняя продажа",
    "reports.balance.exposure": "Экспозиция",
    "reports.balance.exposurenet": "Чистая экспозиция",
    "reports.balance.grossexposure": "Валовая экспозиция",
    "reports.balance.grosspl": "Валовая П/У",
    "reports.balance.grossvalue": "Валовая стоимость позиции",
    "reports.balance.net": "Чистый прибыль",
    "reports.balance.netexposure": "Чистая экспозиция",
    "reports.balance.netvalue": "Чистая стоимость позиции",
    "reports.balance.profit": "Прибыль",
    "reports.balance.sell": "Покупка",
    "reports.Barclays": "Barclays",
    "reports.basis": "Комментарий",
    "reports.Basis": "Базис",
    "reports.because Not enoght margin to create Order": "потому что не достаточно маржи для создания ордера.",
    "reports.because Price for Stop limit order is incorrect.": "так как неправильная цена для Стоп лимит ордера.",
    "reports.BeginString": "Начать строку",
    "reports.blocked_funds": "Заблок. средства(маржа)",
    "reports.blockedbalance": "Заблокированный баланс",
    "reports.Blocking": "Блокировка",
    "reports.bmr_brokermessageid": "ID Сообщения",
    "reports.bmr_brokermessagename": "Имя сообщения",
    "reports.bmr_confirmtime": "Заданное время",
    "reports.bmr_targetuserid": "ID Пользователя",
    "reports.bmr_targetuserlogin": "Пользователь",
    "reports.Bound To": "Связать с",
    "reports.Bound to order": "Связан с ордером",
    "reports.boundtoorderid": "Привязать к ордеру ID",
    "reports.BRANDING_ADMIN_HELP": "Адрес справки для администратора",
    "reports.BRANDING_ANONYM_GROUP": "Поместить автоматически зарегистрированных анонимных пользователей в",
    "reports.BRANDING_ANONYM_GROUP_SUPPORT": "Служба поддержки для анонимных пользователей",
    "reports.BRANDING_CLIENT_MINIMAL_VERSION": "Минимальная версия клиента (для обновления)",
    "reports.BRANDING_COMMON_RULES": "Общий",
    "reports.BRANDING_COMPANY_DESC": "Описание брендинга компании",
    "reports.BRANDING_COMPANY_INFO_RULES": "О компании",
    "reports.BRANDING_COMPANY_NAME": "Название компании",
    "reports.BRANDING_COMPANY_URL": "Адрес сайта компании",
    "reports.BRANDING_DEMO_GROUP": "Поместить автоматически зарегистрированных демо пользователей в",
    "reports.BRANDING_DEMOREGISTRATION_URL": "Демо регистрация (пусто - этот сервер; 0 - блокирован; адрес сайта - адрес страницы)",
    "reports.BRANDING_DEPOSIT_URL": "Адрес для ввода средств (пусто - если нет, адрес - для страницы)",
    "reports.BRANDING_DISCLAMER": "Текст отказа (показывается в при стиле входа - 0)",
    "reports.BRANDING_DOWJONES_ENCRYPTED_TOKEN": "Шифрованный признак Dow Jones",
    "reports.BRANDING_EMAIL": "Эл. почта компании",
    "reports.BRANDING_EXT_LINK_RULES": "Внешние ссылки",
    "reports.BRANDING_FORGOTPWD_URL": "Адрес страницы для тех, кто забыл пароль",
    "reports.BRANDING_HTML_FOOTER": "Картинка для нижнего колонтитула",
    "reports.BRANDING_HTML_LOGINSCREEN_LOGO": "Картинка для окна привествия",
    "reports.BRANDING_HTML_LOGO": "Картинка логотипа",
    "reports.BRANDING_HTML_NAME": "Имя приложения",
    "reports.BRANDING_HTML_STATION_RULES": "Бэк-Офис",
    "reports.BRANDING_INFO_MESSAGE": "Всплывающее сообщение (открывается в станции после входа в систему)",
    "reports.BRANDING_KEY": "Ключ брендинга (используется для прилинковки инсталлятора)",
    "reports.BRANDING_KNOWLEDGE_BASE_URL": "Адрес Базы знаний(-1 если отсуствует)",
    "reports.BRANDING_LANG_SET": "Языки",
    "reports.BRANDING_LIVE_GROUP": "Поместить автоматически зарегистрированных пользователей в",
    "reports.BRANDING_LOGIN_MESSAGE": "Сообщение для окна логина",
    "reports.BRANDING_MAIL_CONFIGURATION": "Настройки почты",
    "reports.BRANDING_MAIL_PATTERN": "Шаблон",
    "reports.BRANDING_NET_BACKGROUND": "Фоновая картинка (необязательна/.bmp любого размера)",
    "reports.BRANDING_NET_BANNER": "Баннер (.bmp 500x36)",
    "reports.BRANDING_NET_BROKERIMAGE": "Картинка отчета (.bmp любого размера)",
    "reports.BRANDING_NET_CONFIGURAION": "Конфигурация по умолчанию (program.ini файл)",
    "reports.BRANDING_NET_DEFWORKSPACE": "Рабочее пространство по умолчанию (*.wrk файл)",
    "reports.BRANDING_NET_FX_STYLE": "Скин для панели FX ячейка  (введите код)",
    "reports.BRANDING_NET_HELP": "Адрес справки",
    "reports.BRANDING_NET_ICON": "Иконка (.ico 16x16+32x32)",
    "reports.BRANDING_NET_INSTALLER_BANNER_IMAGE": "Картинка баннера инсталлятора (500*59)",
    "reports.BRANDING_NET_INSTALLER_DIALOG_IMAGE": "Картинка диалога инсталлятора (500*316)",
    "reports.BRANDING_NET_LINK": "Ссылка приложения",
    "reports.BRANDING_NET_LOADING_IMAGE": "Загрузка картинки .gif",
    "reports.BRANDING_NET_LOGIN_STYLE": "Скин для страницы входа (введите код)",
    "reports.BRANDING_NET_LOGO": "Картинка логотипа (.bmp 500x35)",
    "reports.BRANDING_NET_NAME": "Имя приложения (комбинация клавиш для быстрого вызова)",
    "reports.BRANDING_NET_OE_STYLE": "Скин для панели Создать ордер (введите код)",
    "reports.BRANDING_NET_RIBBONICON": "Иконка ленточного меню (.ico 32x32)",
    "reports.BRANDING_NET_STATION_RULES": ".Net Станция",
    "reports.BRANDING_NET_USE_FINGERPRINT": "Использовать отпечаток пальца",
    "reports.BRANDING_PHONE": "Телефон компании",
    "reports.BRANDING_POWERED_BY": "Управляется (компании или частное лицо)",
    "reports.BRANDING_PROPERTIES_RULES": "Брендинг",
    "reports.BRANDING_RULES": "Видимость брендинга",
    "reports.BRANDING_SCRIPT_URL": "Адрес скриптов",
    "reports.BRANDING_SERVER_URL": "Адрес сервера (адрес или IP для первой страницы, открывающейся перед входом в систему)",
    "reports.BRANDING_STATUS_MESSAGE": "Сообщения строки состояния (открывается в строке состояния)",
    "reports.BRANDING_THEME": "Шаблоны",
    "reports.BRANDING_TOOLS_RULES": "внешняя ссылка на меню инструментария",
    "reports.BRANDING_TTP_FILE": "Шаблоны панелей",
    "reports.BRANDING_USERS_RULES": "Правила для пользователей",
    "reports.BRANDING_WITHDRAW_URL": "Адрес для вывода средств (пусто - если нет, адрес - для страницы, 1 - для окна)",
    "reports.branding.name": "Имя",
    "reports.Broker Message Report": "Отчет по сообщениям брокера",
    "reports.Brokerage": "Комиссия брокера",
    "reports.brokerCancel": "Отмен.брокером",
    "reports.brokerlogin": "Логин брокера",
    "reports.Buy": "Покупка",
    "reports.buy trade amount": "Объем покупок",
    "reports.buy trades": "Покупки",
    "reports.buyselltype": "Тип Купить/Продать",
    "reports.Can't allocate route for trade using hadget": "Невозможно выделить маршрут для торговли через устройство",
    "reports.Can't close entry": "Невозможно закрыть вход",
    "reports.Cancel order:": "Отменить ордер:",
    "reports.Cancel request accepted by broker": "Запрос на отмену принят брокером",
    "reports.Cancel request received by STP": "Запрос на отмену получен STP",
    "reports.Canceled": "Отменённый",
    "reports.Canceling": "Отменен",
    "reports.Cancelled": "Отмененный",
    "reports.Cancelling": "Отменен",
    "reports.CantorFX": "CantorFX",
    "reports.Cash Deposit And Withdrawal Report": "Отчет о вводе и выводе средств",
    "reports.cash_posted": "Размещ. средства",
    "reports.Champ stats report": "Отчет о статистике по чемпионату",
    "reports.change": "Изменить",
    "reports.Change password error, new password must differ from old one": "Ошибка смены пароля, новый пароль должен отличаться от старого",
    "reports.CHART_OE": "График (встроен. СО)",
    "reports.CHART_VISUAL": "График (визуальная торговля)",
    "reports.Chat History Report": "Отчет по истории чата",
    "reports.CheckCompID": "Проверить ID компьютера",
    "reports.CheckLatency": "Проверить задержку",
    "reports.ChoiceFX": "ВыборFX",
    "reports.CIBC": "CIBC",
    "reports.Citi": "Citi",
    "reports.ClearingVmTransfer": "Начисление ВМ",
    "reports.clfr_close_date": "Дата закрытия",
    "reports.clfr_fund_name": "Имя фонда",
    "reports.clientid": "ID клиента",
    "reports.clienttype": "Тип клиента",
    "reports.Close Limit order created": "Закр. лимит ордер создан",
    "reports.Close Limit order created is off market": "",
    "reports.Close Limit order created is unplaced": "",
    "reports.Close Limit order received by broker": "Закр. лимит ордер получен брокером",
    "reports.Close Market order created": "Закр. рыночный ордер создан",
    "reports.Close Market Order Created": "Закрывающий Рыночный ордер создан",
    "reports.Close Market order created is off market": "",
    "reports.Close Market order created is unplaced": "",
    "reports.Close Market order received by broker": "Закр. рыночный ордер получен брокером",
    "reports.Close OCO order created": "Закр. OCO ордер создан",
    "reports.Close OCO order created is off market": "",
    "reports.Close OCO order created is unplaced": "",
    "reports.Close OCO order received by broker": "Закр. OCO ордер получен брокером",
    "reports.Close order type": "Тип закрытия ордера",
    "reports.Close position:": "Закрыть позицию:",
    "reports.Close Sell": "Закрытие Продажа",
    "reports.Close SL order created": "Закр. СЛ ордер создан",
    "reports.Close SL order created is off market": "",
    "reports.Close SL order created is unplaced": "",
    "reports.Close SL order received by broker": "Закр. СЛ ордер получен брокером",
    "reports.Close SL tr. stop order created": "Закр. СЛ Тр. стоп ордер создан",
    "reports.Close SL tr. stop order created is off market": "",
    "reports.Close SL tr. stop order created is unplaced": "",
    "reports.Close SL tr. stop order received by broker": "Закр. СЛ Тр. стоп ордер получен брокером",
    "reports.Close Stop Limit order created": "Закр. стоп лимит ордер создан",
    "reports.Close Stop Limit order created is off market": "",
    "reports.Close Stop Limit order created is unplaced": "",
    "reports.Close Stop Limit order received by broker": "Закр. стоп лимит ордер получен брокером",
    "reports.Close Stop order created": "Закр. стоп ордер создан",
    "reports.Close Stop order created is off market": "",
    "reports.Close Stop order created is unplaced": "",
    "reports.Close Stop order received by broker": "Закр. стоп ордер получен брокером",
    "reports.Close TP order created": "Закр. ТП ордер создан",
    "reports.Close TP order created is off market": "",
    "reports.Close TP order created is unplaced": "",
    "reports.Close TP order received by broker": "Закр. ТП ордер получен брокером",
    "reports.Close Tr. stop order created": "Закр. Тр. стоп ордер создан",
    "reports.Close Tr. stop order created is off market": "",
    "reports.Close Tr. stop order created is unplaced": "",
    "reports.Close Tr. stop order received by broker": "Закр. Тр. стоп ордер получен брокером",
    "reports.CLOSE_ALL": "Закрыть все",
    "reports.CLOSE_ALL_ORDERS": "Отменить все ордера",
    "reports.CLOSE_ALL_POSITIONS": "Закрыть все позиции",
    "reports.CLOSE_FIFO": "Закрыть позиции когда недостаточно маржи (FIFO)",
    "reports.CLOSE_LAGES": "Закрыть позиции когда недостаточно маржи (Largest first)",
    "reports.CLOSE_LIFO": "Закрыть позиции когда недостаточно маржи (LIFO)",
    "reports.closebytradeid": "Закрыть по ID сделки",
    "reports.Closed funds report": "Отчет по закрытым фондам",
    "reports.Closed Positions": "Отчет по закрытым позициям",
    "reports.closedat": "Закрыть",
    "reports.closedbyorderid": "Закрыть по ордеру ID",
    "reports.ClosedResendInterval": "Интервал закрытия",
    "reports.CloseOptionPremium": "Close option premium",
    "reports.Closeposition": "Закрыть позицию",
    "reports.closeprice": "Цена закрытия",
    "reports.closetime": "Время закрытия",
    "reports.comission": "Комиссия",
    "reports.Comment": "Комментарий",
    "reports.comments": "Комментарии",
    "reports.comments.CancelOperation": "Отменить операцию #",
    "reports.COMMISIONSPLAN_RULES": "Видимость планирования доходов",
    "reports.commission": "Комиссия",
    "reports.commission_out": "Комиссия",
    "reports.commissionpertrade": "Комиссия за сделку",
    "reports.commissions": "Комиссия",
    "reports.Commissions": "Комиссия",
    "reports.Commissions report": "Отчет по комиссиям",
    "reports.Commissions Report": "Отчет по комиссиям",
    "reports.Commissions Report(copy)": "Отчет по комиссиям(копия)",
    "reports.commissiontotal": "Итоговая комиссия",
    "reports.COMMON_RULES": "Общие правила",
    "reports.Community": "Сообщество",
    "reports.ConfigFilePath": "Настроить путь к файлу",
    "reports.Connected": "Подключено",
    "reports.Connection established": "Соединение установлено",
    "reports.Connections Report": "Отчет по соединениям",
    "reports.ConnectionType": "Тип соединения",
    "reports.connrp_conntime": "Время попытки соединения",
    "reports.connrp_fullname": "Имя и фамилия",
    "reports.connrp_ip": "IP",
    "reports.connrp_lastpwdchange": "Последнее изменение пароля",
    "reports.connrp_login": "Вход",
    "reports.connrp_success": "Успешное соединение",
    "reports.ContinueInitializationOnError": "Продолжить инициализацию при ошибке",
    "reports.Contract Specification Report": "Отчет контрактной спецификации",
    "reports.Counter Account Name": "Имя контр счета",
    "reports.Counter Operation Id": "ID контр операции",
    "reports.COUNTERACCOUNTS_RULES": "Обратные счета",
    "reports.Created": "Созданный",
    "reports.Created by": "Создано",
    "reports.createdat": "Создать",
    "reports.Creating": "Создан",
    "reports.creator": "Создатель",
    "reports.credit": "Кредит",
    "reports.Credit": "Кредит",
    "reports.crossprice": "Кросс-курс",
    "reports.crosstype": "Тип кросса",
    "reports.currency": "Валюта",
    "reports.Currency Exposure Report": "Отчет по экспозиции валют",
    "reports.Currency summary exposure": "Сводка по денежным рискам",
    "reports.currency.balance_interest": "Балансовы интерес (%)",
    "reports.currency.interest_buy_rate": "Интерес на повышение (%)",
    "reports.currency.interest_sell_rate": "Интерес на понижение (%)",
    "reports.currency.leverage_negative": "Негативное кредитное плечо",
    "reports.currency.leverage_positive": "Положительное кредитное плечо",
    "reports.currency.min_change": "Мин. изменение",
    "reports.currency.name": "Имя",
    "reports.Currenex": "Currenex",
    "reports.CurrenexFIX": "CurrenexFIX",
    "reports.Current Balance": "Текущий баланс",
    "reports.currentprice": "Текущая цена",
    "reports.CUSTOM_COLUMN_BTN_SCRIPT": "Польз. столбец (кнопка скрипт)",
    "reports.customReport": "Пользовательские отчеты",
    "reports.DataDictionary": "Словарь данных",
    "reports.DateTime": "Дата/Время",
    "reports.days": "дней",
    "reports.daystillexpiration": "Дней до экспирации",
    "reports.DBFX": "DBFX",
    "reports.dealercommission": "Комиссия дилера",
    "reports.debit": "Дебет",
    "reports.Default settings failed": "Неудачные настройки по умолчанию",
    "reports.Delayed Limit Order Created": "Отложенный Лимит ордер создан",
    "reports.Delayed Limit Order Modified": "Отложенный Лимит ордер изменен",
    "reports.Delayed Limit Order Removed": "Отложенный Лимит ордер удален",
    "reports.Delayed OCO Order Created": "Отложенный OCO ордер создан",
    "reports.Delayed Stop Limit Order Created": "Стоп лимит ордер создан",
    "reports.Delayed Stop Limit Order Modified": "Стоп лимит ордер изменен",
    "reports.Delayed Stop Limit Order Removed": "Стоп лимит ордер удален",
    "reports.Delayed Stop Order Created": "Отложенный Стоп ордер создан",
    "reports.Delayed Stop Order Modified": "Отложенный Стоп ордер изменен",
    "reports.Delayed Stop Order Removed": "Отложенный Стоп ордер удален",
    "reports.Delayed Trailing Stop Order Created": "Трейлинг стоп ордер создан",
    "reports.Delayed Trailing Stop Order Modified": "Трейлинг стоп ордер изменен",
    "reports.Delayed Trailing Stop Order Removed": "Трейлинг стоп ордер удален",
    "reports.DelayedCharging": "Плата за задержанные данные",
    "reports.DelayedDataCharging": "Плата за задержанные данные",
    "reports.delivered": "Доставлено",
    "reports.DeliveredForward": "Поставка Форварда",
    "reports.Delivery": "Поставка",
    "reports.demoMode": "Демо режим",
    "reports.deposit": "Депозит",
    "reports.Deposit": "Депозит",
    "reports.Detail": "Детально",
    "reports.Deutsche Bank": "Deutsche Bank",
    "reports.DeutscheBankFIXFeed": "DeutscheBankFIXFeed",
    "reports.DGCX": "DGCX",
    "reports.Dictionary": "Словарь",
    "reports.discount": "Дисконт",
    "reports.dividends": "Дивиденды",
    "reports.DO_NOT_CLOSE": "Не закрывать позиции",
    "reports.DukasCopy": "DukasCopy",
    "reports.DWT": "DWT",
    "reports.email": "Эл. почта",
    "reports.EMAIL_NOTIFICATION": "Видимость шаблонов для почтовых сообщений",
    "reports.EMAIL_RULES": "Видимость шаблонов для почтовых сообщений",
    "reports.EndDay": "Дата окончания",
    "reports.EndTime": "Время окончания",
    "reports.entityId": "Entity Id",
    "reports.enum_StopOutType_AutoClose": "Автозакрытие",
    "reports.enum_StopOutType_CloseAllByInstruments": "Закрыть все по инструментам",
    "reports.enum_StopOutType_CloseAllPositions": "Закрыть все позиции",
    "reports.enum_StopOutType_CloseLagestLoss": "Закрывать позиции при недостатке маржи (Largest loss first)",
    "reports.enum_StopOutType_ClosePositionsByMarginExcess": "Закрывать позиции при избытке маржи",
    "reports.enum_StopOutType_DoNotClosePositions": "Не закрывать позиции",
    "reports.enum_StopOutType_FIFO": "Закрывать позиции при недостатке маржи (FIFO)",
    "reports.enum_StopOutType_LargestFirst": "Закрывать позиции при недостатке маржи (Largest first)",
    "reports.enum_StopOutType_LIFO": "Закрывать позиции при недостатке маржи (LIFO)",
    "reports.Error occured during downloading update:": "Произошла ошибка во время загрузки обновлений:",
    "reports.Error sending email": "Ошибка при отправке почты",
    "reports.Error: Not enough Margin to open position": "Ошибка: Недостаточно маржи для открытия позиции",
    "reports.error.certificate_corrupted": "Сертификат пользователя поврежден.",
    "reports.error.certificate_not_found": "Сертификат пользователя не найден.",
    "reports.error.error_during_saving_route": "Произошла ошибка во время сохранения маршрута. Пожалуйста, свяжитесь с администратором.",
    "reports.error.fill_mandatory": "Пожалуйста, заполните обязательные поля.",
    "reports.error.name_is_in_use": "Такое имя уже существует!",
    "reports.error.not_valid_email": "Недопустимый адрес эл. почты.",
    "reports.error.not_valid_firstname": "Недопустимое имя.",
    "reports.error.not_valid_instrumentname_capital": "Недопустимое имя. Используйте только заглавные буквы.",
    "reports.error.not_valid_instrumentname_exist": "Недопустимое имя. Такое имя уже существует.",
    "reports.error.not_valid_instrumentname_length": "Недопустимое имя. Имя не может содержать больше 32 символов.",
    "reports.error.not_valid_instrumenttypename": "Недопустимый тип инструмента. Пожалуйста, введите правильный тип.",
    "reports.error.not_valid_lastname": "Недопустимая фамилия.",
    "reports.error.not_valid_login": "Недопустимый логин.",
    "reports.error.not_valid_name": "Недопустимое имя.",
    "reports.error.not_valid_name_exist": "Недопустимое имя. Такое имя уже существует.",
    "reports.error.not_valid_phone_number": "Недопустимый номер телефона. Введите только числовые данные.",
    "reports.error.photo_not_found": "Фото не найдено.",
    "reports.error.too_short_phone_password": "Слишком короткий телефонный пароль.",
    "reports.error.wrong_percenage": "Неправильные проценты",
    "reports.ESignal": "ESignal",
    "reports.event": "Событие",
    "reports.Event History Report": "Отчет по истории событий",
    "reports.Event History Report with IP": "отчет об истории событий с IP",
    "reports.Exception": "Исключение",
    "reports.exchange": "Биржа",
    "reports.Exchange": "Обмен",
    "reports.Executed": "Выполнен",
    "reports.Executed Orders Report": "Отчет об исполненных ордерах",
    "reports.executedat": "Выполнить",
    "reports.Executing": "Заполнен",
    "reports.executionfee": "Комиссия за исп.",
    "reports.executionprice": "Цена исполнения",
    "reports.expiredate": "Дата экспирации",
    "reports.expiresat": "Закончился",
    "reports.external_order_id": "External order id",
    "reports.externalid": "Внешний ID",
    "reports.externalorderid": "Внешний ID ордера",
    "reports.extfield": "Дополнительное поле",
    "reports.EXTFIELDS_RULES": "Видимость дополнительных полей",
    "reports.extprice": "Внешняя цена",
    "reports.False": "Ложь",
    "reports.fee": "Комиссия",
    "reports.Fee: [addliquidityrebate]": "Комиссия: [За добавление ликвидности]",
    "reports.Fee: [Brokerage]": "Комиссия:[Брокера]",
    "reports.Fee: [Exchange]": "Комиссия:[Биржи]",
    "reports.Fee: [InvestorProtectionLevy]": "Комиссия: [Сбор на защиту инвесторов]",
    "reports.Fee: [removeliquidityrebate]": "Комиссия:[За снятие ликвидности]",
    "reports.Fee: [SecuritiesTransferTax]": "Комиссия: [Налог на передачу ценных бумаг]",
    "reports.Fee: [SettlementFee]": "Комиссия: [За поставку]",
    "reports.Fee: [ValueAddedTax]": "Комиссия:[НДС]",
    "reports.Feed Data": "Фид данных",
    "reports.Fees Report": "Отчет по комиссиям",
    "reports.FFastFillFIXFeed": "FFastFillFIXFeed",
    "reports.fiahr_action": "Действие",
    "reports.fiahr_amount": "Объем",
    "reports.fiahr_capital": "Капитал",
    "reports.fiahr_count": "Кол. паев",
    "reports.fiahr_date": "Дата",
    "reports.fiahr_fee": "Вознаграждение",
    "reports.fiahr_grosspl": "Валовая прибыль/убыток",
    "reports.fiahr_investor_id": "ID инвестора",
    "reports.fiahr_investor_name": "Инвестор",
    "reports.fiahr_netpl": "Чистая прибыль/убыток",
    "reports.fiahr_pamm_id": "ID фонда",
    "reports.fiahr_pamm_name": "Фонд",
    "reports.fiahr_price": "Цена",
    "reports.fiahr_requestid": "ID запроса",
    "reports.Fidessa": "Fidessa",
    "reports.FileIncludeMilliseconds": "Время в миллисекундах",
    "reports.FileIncludeTimeStampForMessages": "В файле логов торговли показывается время сообщений",
    "reports.FileLogHeartbeats": "Мгновенный файл логов",
    "reports.Filled": "Заполнен",
    "reports.Filled close Limit order": "Закр. лимит ордер заполнен",
    "reports.Filled close Limit order (Mutual)": "Закр. лимит ордер заполнен (вручную)",
    "reports.Filled close Market order": "Закр. рыночный ордер заполнен",
    "reports.Filled close Market order (Mutual)": "Закр. рыночный ордер заполнен (вручную)",
    "reports.Filled close OCO order": "Закр. OCO ордер заполнен",
    "reports.Filled close OCO order (Mutual)": "Закр. OCO ордер заполнен (вручную)",
    "reports.Filled close SL order": "Закр. СЛ ордер заполнен",
    "reports.Filled close SL order (Mutual)": "Закр. СЛ ордер заполнен (вручную)",
    "reports.Filled close SL tr. stop order": "Закр. СЛ Тр. стоп ордер заполнен",
    "reports.Filled close SL tr. stop order (Mutual)": "Закр. СЛ Тр. стоп ордер заполнен (вручную)",
    "reports.Filled close Stop Limit order": "Закр. стоп лимит ордер заполнен",
    "reports.Filled close Stop Limit order (Mutual)": "Закр. стоп лимит ордер заполнен (вручную)",
    "reports.Filled close Stop order": "Закр. стоп ордер заполнен",
    "reports.Filled close Stop order (Mutual)": "Закр. стоп ордер заполнен (вручную)",
    "reports.Filled close TP order": "Закр. ТП ордер заполнен",
    "reports.Filled close TP order (Mutual)": "Закр. ТП ордер заполнен (вручную)",
    "reports.Filled close Tr. stop loss order": "Filled close Tr. stop loss order",
    "reports.Filled close Tr. stop order": "Закр. Тр. стоп ордер заполнен",
    "reports.Filled close Tr. stop order (Mutual)": "Закр. Тр. стоп ордер заполнен (вручную)",
    "reports.Filled Limit order": "Лимит ордер заполнен",
    "reports.Filled Limit order (Mutual)": "Лимит ордер заполнен (вручную)",
    "reports.Filled Market order": "Рыночный ордер заполнен",
    "reports.Filled Market order (Mutual)": "Рыночный ордер заполнен (вручную)",
    "reports.Filled OCO order": "OCO ордер заполнен",
    "reports.Filled OCO order (Mutual)": "OCO ордер заполнен (вручную)",
    "reports.Filled SL order": "SL ордер заполнен",
    "reports.Filled SL order (Mutual)": "СЛ ордер заполнен (вручную)",
    "reports.Filled SL tr. stop order": "СЛ Тр. стоп ордер заполнен",
    "reports.Filled SL tr. stop order (Mutual)": "СЛ Тр. стоп ордер заполнен (вручную)",
    "reports.Filled Stop Limit order": "Стоп лимит ордер заполнен",
    "reports.Filled Stop Limit order (Mutual)": "Стоп лимит ордер заполнен (вручную)",
    "reports.Filled Stop order": "Стоп ордер заполнен",
    "reports.Filled Stop order (Mutual)": "Стоп ордер заполнен (вручную)",
    "reports.Filled TP order": "TP ордер заполнен",
    "reports.Filled TP order (Mutual)": "ТП ордер заполнен (вручную)",
    "reports.Filled Tr. stop order": "ТР. стоп ордер заполнен",
    "reports.Filled Tr. stop order (Mutual)": "Тр. стоп ордер заполнен (вручную)",
    "reports.filledamount": "Заполненный объем",
    "reports.fir_exit_capital": "Текущ. капитал",
    "reports.fir_exit_price": "Текущ. стоимость пая",
    "reports.fir_investor_id": "ID инвестора",
    "reports.fir_investor_name": "Имя инвестора",
    "reports.fir_join_capital": "Нач. капитал",
    "reports.fir_join_date": "Дата вступления",
    "reports.fir_join_price": "Цена вступления",
    "reports.fir_pamm_id": "ID фонда",
    "reports.fir_pamm_name": "Имя фонда",
    "reports.fir_reinvest_cycles": "Циклы реинвестирования",
    "reports.fir_share_count": "#Паи",
    "reports.FirstDerivatives": "FirstDerivatives",
    "reports.firstname": "Имя",
    "reports.FixedFee": "",
    "reports.Foreks": "Foreks",
    "reports.Forex balances report": "Отчет по балансам Forex",
    "reports.Formal Dialog Report": "Отчет формальных диалогов",
    "reports.FORTEX": "FORTEX",
    "reports.fpghr_count": "Паи",
    "reports.fpghr_exit_amount": "Объем выхода",
    "reports.fpghr_exit_price": "Цена выхода",
    "reports.fpghr_fee": "Комиссия",
    "reports.fpghr_first_join_date": "Дата вступления",
    "reports.fpghr_grosspl": "Валовая прибыль/убыток",
    "reports.fpghr_investor_id": "ID инвестора",
    "reports.fpghr_investor_name": "Имя инвестора",
    "reports.fpghr_join_amount": "Объем вступления",
    "reports.fpghr_join_price": "Цена вступления",
    "reports.fpghr_last_exit_date": "Дата выхода",
    "reports.fpghr_netpl": "Чистая прибыль/убыток",
    "reports.fpghr_pamm_id": "ID фонда",
    "reports.fpghr_pamm_name": "Имя фонда",
    "reports.fpghr_period": "Период",
    "reports.fpghr_period_end_date": "Дата окончания",
    "reports.fpghr_period_start_date": "Дата начала",
    "reports.fprhr_date": "Последнее обновление",
    "reports.fprhr_details": "Детали",
    "reports.fprhr_entryid": "ID вступления",
    "reports.fprhr_event": "Событие",
    "reports.fprhr_investor_id": "ID инвестора",
    "reports.fprhr_investor_name": "Инвестор",
    "reports.fprhr_pamm_id": "ID фонда",
    "reports.fprhr_pamm_name": "Фонд",
    "reports.fprhr_requestid": "Request#",
    "reports.from": "С",
    "reports.From.SERVER": "Сервер",
    "reports.fromDate": "От",
    "reports.fromDateConnAttempt": "От",
    "reports.fromDatePwdChange": "От(Путь)",
    "reports.FUNCTION_ACCOUNT_GROUP": "Группа счетов",
    "reports.FUNCTION_ACCOUNT_OPERATIONS": "Видимость фондовых операций",
    "reports.FUNCTION_ACCOUNTS": "Счета",
    "reports.FUNCTION_ALERTS": "Алерты",
    "reports.FUNCTION_ARBITRAGER": "Арбитражер",
    "reports.FUNCTION_AUTOBROKET_LIST": "Список автоброкеров",
    "reports.FUNCTION_BACK_TESTER": "Бэктестер",
    "reports.FUNCTION_BARS_FROM": "Сделать бары из",
    "reports.FUNCTION_BASE_CURRENCY": "Базовая валюта системы (может потребоваться смена кросс-курса)",
    "reports.FUNCTION_BASKET": "Корзина",
    "reports.FUNCTION_BETTING": "Ставки",
    "reports.FUNCTION_BINDEDORDERS": "Разрешить ОСО ордера",
    "reports.FUNCTION_BLOCKED_SUMM_FOR_ORDERS": "Блокированные фонды для ордеров",
    "reports.FUNCTION_BRANDING": "Брендинг",
    "reports.FUNCTION_BRANDINGADMIN": "Админ по брендингу",
    "reports.FUNCTION_BUILDER": "Конструктор",
    "reports.FUNCTION_CACHEADMIN": "Администратор кэш-памяти",
    "reports.FUNCTION_CHART": "График",
    "reports.FUNCTION_CHAT": "Чат",
    "reports.FUNCTION_CLOSE_POSITIONS_DISABLE": "Не разрешать закрывать позиции",
    "reports.FUNCTION_COMMUNITY": "Сообщество",
    "reports.FUNCTION_CONFIGADMIN": "Конфигурация и брендинг",
    "reports.FUNCTION_CONNECTIONSSADMIN": "Администратор соединений",
    "reports.FUNCTION_CROSS_PAIR": "Кросс-курс",
    "reports.FUNCTION_CUR_EXP": "Экспозиция валют",
    "reports.FUNCTION_DISABLE_BUY": "Запретить ордера на покупку",
    "reports.FUNCTION_DISABLE_OPTION_TRADING": "Запретить опциональные ордера",
    "reports.FUNCTION_DISABLE_SELL": "Запретить ордера на продажу",
    "reports.FUNCTION_DO_NOT_MARGIN": "Не использовать маржу",
    "reports.FUNCTION_EVENT_LOG": "Протокол событий",
    "reports.FUNCTION_FUNDING": "Администратор действий",
    "reports.FUNCTION_GENERATE_OPTIONS": "Генерировать опционы из котировок первого уровня",
    "reports.FUNCTION_HEDGE_RULE_TYPE": "Правила хеджирования:",
    "reports.FUNCTION_INSTRUMENT_LOOKUP": "Поиск инструмента",
    "reports.FUNCTION_INSTRUMENT_PORTFOLIO": "Портфолио",
    "reports.FUNCTION_INSTRUMENTSADMIN": "Администратор инструментов",
    "reports.FUNCTION_INTERSERVER": "Интерсервер",
    "reports.FUNCTION_LEVEL2": "Данные Уровень2 и ордера",
    "reports.FUNCTION_LEVEL3": "Лента сделок",
    "reports.FUNCTION_LOCKED_BY_BROKER": "Привязан к брокеру",
    "reports.FUNCTION_MANUAL": "Разрешить взаимные ордера",
    "reports.FUNCTION_MARGIN_MODE": "Режим маржи",
    "reports.FUNCTION_MASTER": "Управление счетами",
    "reports.FUNCTION_MATRIX": "Матрица",
    "reports.FUNCTION_MULTI_CONNECTION": "Разрешить несколько соединений",
    "reports.FUNCTION_MUTUAL_CLOSE": "разрешить взаимное закрытие",
    "reports.FUNCTION_NEWS": "Новости",
    "reports.FUNCTION_NEWSADMIN": "Администратор новостей",
    "reports.FUNCTION_NOT_ALLOW_OPEN_POSITION": "Запрещено открывать позиции",
    "reports.FUNCTION_NOT_ALLOW_SHORT_STOCK": "Не разрешать короткие позиции",
    "reports.FUNCTION_ONE_COUNTERACCOUNT": "Один контр счет",
    "reports.FUNCTION_ONE_POSITION": "Одна позиция на инструмент (хеджирование)",
    "reports.FUNCTION_ONE_QUOTECACHECOUNT": "Единый размер кэша для всех",
    "reports.FUNCTION_OPTIONS": "Опционы",
    "reports.FUNCTION_ORDER_BOOK": "История ордеров",
    "reports.FUNCTION_PARTIAL_CLOSE": "Разрешить частичное закрытие",
    "reports.FUNCTION_POSITION_BALANCE": "Баланс по поз.",
    "reports.FUNCTION_POSITIONSADMIN": "Администратор позиций",
    "reports.FUNCTION_QUOTE_HISTORY": "История котировок",
    "reports.FUNCTION_QUOTES": "Котировки",
    "reports.FUNCTION_REPORT": "Отчет",
    "reports.FUNCTION_REPORTADMIN": "Администратор отчетов",
    "reports.FUNCTION_RESERVER_WITHDRAWAL": "Вывод средств",
    "reports.FUNCTION_PRODUCTS": "Продукты",
    "reports.FUNCTION_RFQ": "Запрос котировок",
    "reports.FUNCTION_ROLESADMIN": "Администратор пользовательских групп",
    "reports.FUNCTION_ROUTESADMIN": "Администратор маршрутов",
    "reports.FUNCTION_SCHEDULERADMIN": "Админ по планированию",
    "reports.FUNCTION_SCRIPT_BUILDER": "Скрипт билдер",
    "reports.FUNCTION_SERVICESADMIN": "Администратор сервисов",
    "reports.FUNCTION_SETTINGSADMIN": "Администратор настроек",
    "reports.FUNCTION_SHOW_ORDERS": "Ордера",
    "reports.FUNCTION_SHOW_POSITIONS": "Позиции",
    "reports.FUNCTION_SLAVE": "Управляемый",
    "reports.FUNCTION_SLTP": "Разрешить СЛ/ТП ордера",
    "reports.FUNCTION_SMS_SERVICE_DEST": "SMS адресат",
    "reports.FUNCTION_SN": "Номер лицензии",
    "reports.FUNCTION_STRATEGIESADMIN": "Администратор стратегий",
    "reports.FUNCTION_SUPPORT_EMAIL": "Написать письмо в службу поддержки",
    "reports.FUNCTION_TABLESADMIN": "Администратор таблиц",
    "reports.FUNCTION_THREADEDSAVER": "Потоковое сохранение",
    "reports.FUNCTION_TRADES": "Сделки",
    "reports.FUNCTION_TRADING": "Торговля",
    "reports.FUNCTION_TRADING_MODE": "Режим торговли",
    "reports.FUNCTION_TRAILING_STOP": "Разрешить Трейлинг стоп",
    "reports.FUNCTION_USER_CONNECTION_POLICY": "Политика нескольких соединений",
    "reports.FUNCTION_USERSADMIN": "Администратор пользователей",
    "reports.FUNCTION_VALIDATE_SSL_CERTIFICATE": "Необходма проверка SSL сертификата",
    "reports.FUNCTION_VENDOR_NAME": "Имя вендора",
    "reports.FUNCTION_WATCHLIST": "Список наблюдения",
    "reports.Fund Gain History Report": "Отчет по истории роста фондов",
    "reports.Fund Investments Report": "Отчет об инвестициях в фонд",
    "reports.Fund Investor Action History Report": "Отчет по истории действий инвестора фонда",
    "reports.Fund Investor History Report": "Отчет по истории инвестиций в фонд",
    "reports.Fund Request History Report": "Отчет по истории запросов в фонд",
    "reports.Fund Transfer": "Фонд-трансфер",
    "reports.fund_type.closed": "Закрытый",
    "reports.fund_type.open": "Открытый",
    "reports.fundID": "ID фонда",
    "reports.Funding": "Финансирование",
    "reports.FUNDING_RULES": "Видимость операций со средствами",
    "reports.fxintegrate.cert.host.verify": "Проверка хоста fx интеграции",
    "reports.fxintegrate.cert.root.ca.file": "Проверка файла fx интеграции",
    "reports.fxintegrate.host": "fx интегрированный хост",
    "reports.fxintegrate.logging.file": "Файл fx интегрированного логирования",
    "reports.fxintegrate.logging.maxfilecount": "Макс. кол файлов fx интегрированного логирования",
    "reports.fxintegrate.logging.maxfilesize": "Максимальный размер файла логирования fx интеграции",
    "reports.fxintegrate.logging.output": "Вывод fx интегрированного логирования",
    "reports.fxintegrate.logging.priority": "Приоритет логирования fx интеграции",
    "reports.fxintegrate.port": "fx интегрированный порт",
    "reports.fxintegrate.protocol": "Протокол fx интеграции",
    "reports.fxintegrate.usessl": "fx интеграция использует ssl",
    "reports.GENERAL_COMMON_RULES": "Общие правила",
    "reports.GENERAL_USER_RULES": "Общие правила",
    "reports.GFT": "GFT",
    "reports.gi.available_margin": "Дост. маржа",
    "reports.gi.avaliable_funds": "Доступные средства",
    "reports.gi.balance": "Баланс",
    "reports.gi.balance_blocked_by_orders": "Заблокировано ордерами",
    "reports.gi.cur_balance": "Текущий баланс",
    "reports.gi.cur_margin": "Тек. маржа, %",
    "reports.gi.fund_capital": "Капитал фонда",
    "reports.gi.fund_used": "Исп. средства",
    "reports.gi.maintain_margin": "Маржа поддержки",
    "reports.gi.margin_orders": "Маржа для ордеров",
    "reports.gi.margin_positions": "Маржа для позиций",
    "reports.gi.margin_stopout": "Стоп аут",
    "reports.gi.margin_stoptrade": "Остановка торговли",
    "reports.gi.margin_used": "Исп.маржа",
    "reports.gi.margin_warning": "Марж. предупреждение",
    "reports.gi.min_margin": "Мин. маржа",
    "reports.gi.pos_maintenance_req": "Треб. поддерж. позиций",
    "reports.gi.risk_lvl": "Уровень риска",
    "reports.gi.stock_value": "Акция",
    "reports.gi.today_released_pnl": "Реализованная прибыль/убытки",
    "reports.gi.withdrawal_available": "Доступно для вывода",
    "reports.Goldman Sachs": "Goldman Sachs",
    "reports.grosspl": "Валовая прибыль/убыток",
    "reports.groupid": "ID группы",
    "reports.groupname": "Группа",
    "reports.GTL": "GTL",
    "reports.HeartBtInt": "Минимальный интервал",
    "reports.hedged": "STP (Сквозная обработка транзакций)",
    "reports.helpIndex": "http://protrader.org/en/knowledge-base/pt3/desktop/account/reports",
    "reports.host": "Хост",
    "reports.HOT_BUTTON": "Горячая клавиша",
    "reports.HotSpot": "HotSpot",
    "reports.hours": "часов",
    "reports.HTTP.SERVER.HOST": "HTTP SERVER HOST",
    "reports.HTTP.SERVER.PORT": "HTTP SERVER PORT",
    "reports.HTTP.SERVER.PROTOCOL": "HTTP SERVER PROTOCOL",
    "reports.HTTP.URL.HEARTBEAT": "HTTP URL HEARTBEAT",
    "reports.HTTP.URL.LOGIN": "HTTP URL LOGIN",
    "reports.HTTP.URL.LOGOUT": "HTTP URL LOGOUT",
    "reports.HTTP.URL.PASSWORD.VALIDATION": "HTTP URL PASSWORD VALIDATION",
    "reports.HTTP.URL.PRELOGIN": "HTTP URL PRELOGIN",
    "reports.HTTP.URL.QUERY": "HTTP URL QUERY",
    "reports.HTTP.URL.WORKFLOW": "HTTP URL WORKFLOW",
    "reports.IB Trades Report": "Отчет по торговле Представляющего брокера",
    "reports.IGIndex": "IGIndex",
    "reports.IndexingQuoteFrequency(sec)": "Частота поступления котировок (сек)",
    "reports.Information": "Информация",
    "reports.Informational message": "Информационное сообщение",
    "reports.Init margin req.": "Нач. марж. треб.",
    "reports.Init. margin req.": "Нач. треб. маржи",
    "reports.INSTALLER_SERVICE_URL": "Адрес инсталлятора",
    "reports.InstrRouteMap": "Карта маршрутов",
    "reports.instrtype": "Группа инструментов",
    "reports.INSTRTYPES_RULES": "Видимость групп инструментов",
    "reports.instrument": "Инструмент",
    "reports.Instrument": "Инструмент",
    "reports.instrument group": "Группа инструмента",
    "reports.Instrument Specification": "Спецификация по инструментам",
    "reports.Instrument Statistic Report": "Отчет по статистике инструментов",
    "reports.Instrument Usage By Signal Report": "Отчет использования инструментов по сигналам",
    "reports.Instrument Visibility Report": "Отчет видимости инструментов",
    "reports.INSTRUMENT_DERIVATIVES": "Деривативы",
    "reports.INSTRUMENT_GENERAL": "Общее",
    "reports.INSTRUMENT_MARGIN": "Маржа",
    "reports.INSTRUMENT_TRADING": "Торговля",
    "reports.INSTRUMENT_TYPE_DERIVATIVES": "Деривативы",
    "reports.instrument.group": "Группа инструментов",
    "reports.instrument.name": "Имя инструмента",
    "reports.instrumentgroup": "Группа инструмента",
    "reports.InstrumentId": "Инструмент",
    "reports.instrumentname": "Инструмент",
    "reports.instruments": "Инструмент(ы)",
    "reports.instrumenttype": "Тип инструмента",
    "reports.instrumenttype.duplicatename": "Группа инструментов с таким именем уже существует",
    "reports.instrumenttype.inheritanceerror": "Ошибка в типе наследования",
    "reports.instrumentType.name": "Имя группы инструментов",
    "reports.instrumentType.superId": "Имя супер-типа инструментов",
    "reports.instrumentVisibilityReport": "Отчет видимости инструментов",
    "reports.Integral": "Integral",
    "reports.IntegralFIX": "IntegralFIX",
    "reports.InteractiveData": "InteractiveData",
    "reports.IntererstOnAvailableFundsAndUnsettledCash": "Interest on Available funds+Unsettled cash",
    "reports.interest": "Интерес",
    "reports.InterestOnAvailableFunds": "Interest on Available funds",
    "reports.InterestOnCash": "Процент на Средства",
    "reports.InterestOnMarginAvailable": "Процент на Доступ. маржу",
    "reports.InterestOnMarginAvailableAndUnsettledCash": "Процент на Доступ. маржу и Непостав. средства",
    "reports.Invalid installer hash.": "Неправильный хэш инсталлятора.",
    "reports.Invalid installer size.": "Неправильный размер инсталлятора.",
    "reports.Invalid installer URL.": "Неправильный URL инсталлятора.",
    "reports.Invalid password:New password should have at least 1 upper case character, 1 lower case character, 1 number, 1 special character (e.g. @#$%) and contain 8 characters at least. Please try again.": "New password should have at least 1 upper case character, 1 lower case character, 1 number, 1 special character (e.g. @#$%) and contain 8 characters at least. Please try again.",
    "reports.Invalid price for the order.": "Неверная цена для ордера.",
    "reports.Investor Action History Report": "Отчет по истории действий инвесторов",
    "reports.investorID": "ID инвестора",
    "reports.InvestorProtectionLevy": "Сбор на защиту инвесторов",
    "reports.ip": "IP",
    "reports.IP": "IP",
    "reports.IQFeed": "IQFeed",
    "reports.irdnumber": "Номер IR",
    "reports.is out of range": "за пределами интервала",
    "reports.IsOnlyLastPeriod": "Последний период",
    "reports.isPriceStream": "Поток цен",
    "reports.isShowLots": "Отображать объем в лотах",
    "reports.IsShowLots": "Отображать объем в лотах",
    "reports.IsShowPamm": "Показать фонд",
    "reports.issingle": "Одиночный",
    "reports.JdbcDriver": "Драйвер Jdbc",
    "reports.JdbcPassword": "Jdbc пароль",
    "reports.JdbcSessionIdDefaultPropertyValue": "Значение по умолч. для ID сессии JDBC",
    "reports.JdbcURL": "JdbcURL (адрес БД)",
    "reports.JdbcUser": "Jdbc пользователь",
    "reports.JMS.INIT.QUEUE": "JMS INITIAL QUEUE",
    "reports.JMS.SERVER.HOST": "JMS SERVER HOST",
    "reports.JMS.SERVER.PASSWORD": "JMS SERVER PASSWORD",
    "reports.JMS.SERVER.PORT": "JMS SERVER PORT",
    "reports.JMS.SERVER.PROTOCOL": "JMS SERVER PROTOCOL",
    "reports.JMS.SERVER.USER": "JMS SERVER USER",
    "reports.JPMorganTrade": "JPMorganTrade",
    "reports.keystoreAlias": "Имя ключа",
    "reports.keystoreFile": "файл ключа",
    "reports.KyteFIX": "KyteFIX",
    "reports.L1port": "Ур1Порт",
    "reports.L2port": "Ур2Порт",
    "reports.LADDER_VIEW": "Super DOM",
    "reports.LADDER_VIEW_OE": "Super DOM (build in OE)",
    "reports.Last fill price": "Последняя цена",
    "reports.lastname": "Фамилия",
    "reports.Level1Charging": "Плата за данные 1 уровня",
    "reports.Level1DataCharging": "Плата за данные 1 уровня",
    "reports.Level2Charging": "Плата за данные 2 уровня",
    "reports.Level2DataCharging": "Плата за данные 2 уровня",
    "reports.Level3Charging": "Плата за данные 3 уровня",
    "reports.Level3DataCharging": "Плата за данные 3 уровня",
    "reports.Limit accepted by broker": "Лимит ордер принят брокером",
    "reports.Limit accepted by STP": "Лимит ордер принят STP",
    "reports.Limit order accepted": "Лимит ордер принят",
    "reports.Limit order activated": "",
    "reports.Limit order cancel request": "Запрос на отмену Лимит ордера",
    "reports.Limit order canceled": "Лимит ордер отменен",
    "reports.Limit order created": "Лимит ордер создан",
    "reports.Limit Order Created": "Лимит ордер создан",
    "reports.Limit order created is off market": "",
    "reports.Limit order created is unplaced": "",
    "reports.Limit order is awaiting market": "Limit order is awaiting market",
    "reports.Limit Order Modified": "Лимит ордер изменен",
    "reports.Limit order partially reject": "Лимит ордер частично отменен",
    "reports.Limit order placing request": "Запрос на размещение Лимит ордера",
    "reports.Limit order received by broker": "Лимит ордер получен брокером",
    "reports.Limit order rejected": "Лимит ордер отклонен",
    "reports.Limit Order Removed": "Лимит ордер удален",
    "reports.Limit order replace request": "Запрос на замену Лимит ордера",
    "reports.Limit order replaced": "Лимит ордер заменен",
    "reports.Limit order restated": "",
    "reports.Linked Users": "Привязанные пользователи",
    "reports.LMAX": "LMAX",
    "reports.LMAX FIX": "LMAX FIX",
    "reports.Local": "Локальный",
    "reports.locked": "Заблокирован",
    "reports.LogFileMaxSize": "Макс. размер файла логов",
    "reports.login": "Счет(а)",
    "reports.Login": "Войти",
    "reports.LOGIN.USER.NAMESPACE": "LOGIN USER NAMESPACE",
    "reports.Login/password combination is not valid": "",
    "reports.LogonTimeout": "Таймаут",
    "reports.Logout": "Выйти",
    "reports.Lots to open": "Лоты для открытия",
    "reports.lotsize": "Размер лота",
    "reports.Mail Settings": "Настройки почты",
    "reports.mail.mailsettings": "Настройки почты",
    "reports.mailPattern.ChatDuplicating": "Дублирование чата",
    "reports.mailPattern.DemoUserNotify": "Уведомление об окончании действия демо-пользователя",
    "reports.mailPattern.DemoUserRegistration": "Подтверждение регистрации демо-пользователя",
    "reports.mailPattern.DemoUserRemove": "Подтверждение удаления демо-пользователя",
    "reports.mailPattern.DepositWithdrawalNotification": "Уведомление о депозите/выводе на эл. почту",
    "reports.mailPattern.MarginCall": "Уведомление о требовании маржи",
    "reports.mailPattern.NewsCreated": "Новости были созданы",
    "reports.mailPattern.OrderPlaced": "Стоп/Лимит ордер был размещен",
    "reports.mailPattern.OrderRemoved": "Стоп/Лимит ордер был удален",
    "reports.mailPattern.PositionClosed": "Подтверждение о закрытии позиции",
    "reports.mailPattern.PositionOpened": "Подтверждение о размещении позиции",
    "reports.mailPattern.SignalDuplicating": "Дублирование сигнала",
    "reports.mailPattern.TransactionStatementNotifyBroker": "Суточный отчет для брокера",
    "reports.mailPattern.TransactionStatementNotifyUser": "Суточный отчет для пользователя",
    "reports.mailPattern.UserEmailConfirmation": "Сообщение с подтверждением по эл. почте",
    "reports.mailPattern.UserPasswordRecovery": "Восстановление пользовательского пароля",
    "reports.mailPattern.UserPasswordReset": "Сброс пользовательского пароля",
    "reports.mailPattern.UserRegistration": "Подтверждение регистрации пользователя",
    "reports.mailPattern.UserUnlocking": "Разблокировка пользователя",
    "reports.Main": "Главная",
    "reports.MAM trades report": "Отчет о сделках по MAM",
    "reports.MAM_OE": "МАМ (встроен. СО)",
    "reports.MamManagementFee": "Комиссия за управление MAM",
    "reports.MamPerfomanceFee": "Комиссия за производительность МАМ",
    "reports.MamPerformanceFee": "Комиссия за производительность MAM",
    "reports.Manual order cancel request": "Запрос на отмену Мануального ордера",
    "reports.Manual order placing request": "Запрос на размещение Мануального ордера",
    "reports.Manual order replace request": "Запрос на замену Мануального ордера",
    "reports.ManufacturedDividends": "Промышленные дивиденды",
    "reports.margin": "Маржа",
    "reports.Margin Call": "Марж. требование",
    "reports.Margin Call Reached": "Требование поддержки маржи выполнено",
    "reports.Margin Call Warning.": "Предупреждение о требовании поддержки маржи",
    "reports.Margin used": "Исп. маржа",
    "reports.MARGIN_RULES_ACC": "Правила маржи(счет)",
    "reports.MARGIN_RUSLES": "Правила маржи",
    "reports.Margincall": "Марж. требование",
    "reports.MarginCallMessage.header.MarginCallReached": "Маржин-колл достигнут",
    "reports.MarginCallMessage.header.MarginWarning": "Марж. предупреждение",
    "reports.MarginCallMessage.Message": "Пожалуйста, внесите деньги на счет",
    "reports.Market accepted by broker": "Рыночный ордер принят брокером",
    "reports.Market accepted by STP": "Рыночный ордер принят STP",
    "reports.Market Close Order Removed": "Закрывающий рыночный ордер удален",
    "reports.Market data charging report": "Отчет о платежах за рыночные данные",
    "reports.Market order accepted": "Рыночный ордер принят",
    "reports.Market order activated": "",
    "reports.Market order cancel request": "Запрос на отмену Рыночного ордера",
    "reports.Market order canceled": "Рыночный ордер отменен",
    "reports.Market order created": "Рыночный ордер создан",
    "reports.Market Order Created": "Рыночный ордер создан",
    "reports.Market order created is off market": "",
    "reports.Market order created is unplaced": "",
    "reports.Market Order Modified": "Рыночный ордер изменен",
    "reports.Market order partially reject": "Рыночный ордер частично отменен",
    "reports.Market order placing request": "Запрос на размещение Рыночного ордера",
    "reports.Market order received by broker": "Рыночный ордер получен брокером",
    "reports.Market order rejected": "Рыночный ордер отклонен",
    "reports.Market Order Removed": "Рыночный ордер удален",
    "reports.Market order replace request": "Запрос на замену Рыночного ордера",
    "reports.Market order replaced": "Рыночный ордер заменен",
    "reports.Market order restated": "",
    "reports.Market price": "Рыночная цена",
    "reports.MARKETDEPTH": "Стакан заявок",
    "reports.MarketFactory Feed": "MarketFactory Feed",
    "reports.Markup": "Наценка",
    "reports.MATRIX": "Матрица",
    "reports.MATRIX_OE": "Матрица (встроен. СО)",
    "reports.MaxLogsBackups": "Макс. резервное копирование логов",
    "reports.MBT": "MBT",
    "reports.message": "Сообщение",
    "reports.Message": "Сообщение",
    "reports.messageid": "ID сообщения",
    "reports.MFGlobal": "MFGlobal",
    "reports.MillisecondsinTimeStamp": "Миллисекунды во времени",
    "reports.MillisecondsInTimeStamp": "Миллисекунды в отметке времени",
    "reports.minchange": "Мин. изменение",
    "reports.MinFeeAdjEquity": "Сумма до мин. комиссии",
    "reports.Minimum Open Margin": "Мин. маржа для открытия",
    "reports.minutes": "минут",
    "reports.MLFIX": "MLFIX",
    "reports.MOB_CANCEL_ALL_ORDERS": "Mobile (Отменить все ордера)",
    "reports.MOB_CHART_OE": "Mobile График (Создать ордер)",
    "reports.MOB_CLOSE_ALL_POSITIONS": "Mobile (Закрыть все позиции)",
    "reports.MOB_MARKETDEPTH": "Mobile Стакан заявок",
    "reports.MOB_ORDERS_HOT_BTN": "Mobile Рабочие ордера (горячие клавиши)",
    "reports.MOB_OTHER": "Mobile другое",
    "reports.MOB_POSITIONS_HOT_BTN": "Mobile Позиции (горячие клавиши)",
    "reports.MOB_TAB_CHART_VISUAL_HOT_BTN": "Mobile График (встроен. панель СО/планшеты)",
    "reports.MOB_WL_OE": "Mobile Рынок (Создать ордер)",
    "reports.Modified": "Изменен",
    "reports.Modifing": "Изменен",
    "reports.Modifyied by": "Изменен",
    "reports.Modifying": "Изменен",
    "reports.months": "месяцев",
    "reports.Morgan Stanley": "Morgan Stanley",
    "reports.MorganStanleyCFD": "MorganStanleyCFD",
    "reports.Mutual close request": "Запрос на взаимное закрытие",
    "reports.name": "Имя",
    "reports.NAMESPACE": "NAMESPACE",
    "reports.NEED_EMAIL_CONFIRM_ON_REGISTRATION": "Подтверждение регистрации по эл. почте",
    "reports.NeoNet": "NeoNet",
    "reports.Net Open Positions": "Чистый объем открытых позиций",
    "reports.netpl": "Чистая прибыль/убыток",
    "reports.New Back Office Changes Report": "Отчет о новых изменениях в Backoffice",
    "reports.New password must differ from several previous passwords.": "Новый пароль должен отличаться от нескольких предыдущих паролей",
    "reports.New Users Report": "Отчет о новых пользователях",
    "reports.news.feed.type": "Тип новостного фида",
    "reports.news.reciving": "Получение новостей",
    "reports.news.save": "сохранить новость",
    "reports.news.sendEmail": "отправить новость по эл. почте",
    "reports.NewsCharging": "Плата за новости",
    "reports.NewsFeed": "NewsFeed",
    "reports.newvalue": "Новое значение",
    "reports.no such position, no such order.": "нет такой позиции, нет такого ордера.",
    "reports.Nomura": "Nomura",
    "reports.Nomura-Lehman": "Nomura-Lehman",
    "reports.None": "Пусто",
    "reports.Not enoght margin to create Order": "Не досточно маржи для создания ордера",
    "reports.Not enough margin to open order": "Недостаточно маржи для открытия ордера",
    "reports.Not enough Margin to open position": "Не хватает маржи для открытия позиции",
    "reports.Not enough offer": "недостаточно предложения",
    "reports.Not Filled Orders Report": "Отчет по незаполненным ордерам",
    "reports.Not Successful": "Не успешно",
    "reports.notShowMam": "Не показывать МАМ",
    "reports.NotSuccessful": "Не успешно",
    "reports.number": "номер",
    "reports.objectid": "ID объекта",
    "reports.ObjectId": "ID ордера",
    "reports.ObjectID": "ID объекта",
    "reports.OCO accepted by broker": "OCO ордер принят брокером",
    "reports.OCO accepted by STP": "OCO ордер принят STP",
    "reports.OCO order activated": "",
    "reports.OCO order cancel request": "Запрос на отмену OCO ордера",
    "reports.OCO order canceled": "OCO ордер отменен",
    "reports.OCO order created": "OCO ордер создан",
    "reports.OCO Order Created": "OCO ордер создан",
    "reports.OCO order created is off market": "",
    "reports.OCO order created is unplaced": "",
    "reports.OCO order partially reject": "OCO ордер частично отменен",
    "reports.OCO order placing request": "Запрос на размещение OCO ордера",
    "reports.OCO order received by broker": "OCO ордер получен брокером",
    "reports.OCO order rejected": "OCO ордер отклонен",
    "reports.OCO order replace request": "Запрос на замену OCO ордера",
    "reports.OCO order replaced": "OCO ордер заменен",
    "reports.OCO order restated": "",
    "reports.OE": "СО",
    "reports.Off market": "",
    "reports.ONE_MARGIN_FOR_USER_ACC": "Одна маржа для всех счетов пользователя",
    "reports.Open": "Открыть",
    "reports.Open Buy": "Открытие Покупка",
    "reports.Open date": "Дата открытия",
    "reports.Open lots": "Открыте лоты",
    "reports.Open order is forbidden by trade session": "Открывающий ордер запрещен торговой сессией",
    "reports.Open order type": "Тип открывающего ордера",
    "reports.Open Positions By Signal Report": "Отчет об открытых позициях по сигналам",
    "reports.Open Positions Report": "Отчет по открытым позициям",
    "reports.Open price": "Цена открытия",
    "reports.Open Sell": "Открытие Продажа",
    "reports.OPEN_SPOT_ON_OPTION_EXP": "Открыть Стоп позиции для опционов",
    "reports.openclosebuysell": "Тип операции",
    "reports.opengrosspnl": "Откр. валовая П/У",
    "reports.OpenOptionPremium": "Open option premium",
    "reports.openorderid": "ID открывающего ордера",
    "reports.openprice": "Цена открытия",
    "reports.opentime": "Время открытия",
    "reports.operation": "Операция",
    "reports.Operation": "Операция",
    "reports.Operation Id": "ID операции",
    "reports.Operation type": "Тип операции",
    "reports.Operation Type": "Тип операции",
    "reports.operation.not_enough_margin": "Недостаточно маржи",
    "reports.operation.wrong_length": "Слишком большой объем",
    "reports.operation.wrong_length;": "Слишком большой объем",
    "reports.operation.wrong_sum": "Неправильная сумма",
    "reports.OperationID": "ID операции",
    "reports.OperationInDisconnectedMode": "данная операция не доступна в автономном режиме!",
    "reports.operationtype": "Тип операции",
    "reports.OPTION_MASTER": "Опционный мастер",
    "reports.option_value": "Option value",
    "reports.OrcFIX": "OrcFIX",
    "reports.order": "Ордер",
    "reports.Order": "Ордер",
    "reports.Order Book History": "История по ордерам",
    "reports.Order Book Report": "Отчет об ордерах",
    "reports.Order cancel is forbidden by trade session": "Отмена ордера запрещена торговой сессией",
    "reports.Order cancel request": "Запрос на отмену ордера",
    "reports.Order canceled successfully": "Ордер успешно отменен",
    "reports.Order cannot be created since order amount is forbidden.": "Ордер не может быть создан, так как объем ордера запрещен.",
    "reports.Order cannot be created since route is not active.": "Ордер не может быть создан так как маршрут не активен.",
    "reports.Order cannot be created, because position is in executing": "Ордер не может быть создан, так как позиция выполняется",
    "reports.Order cannot be created, because route is not active": "Невозможно создать ордер, так как маршрут не активен.",
    "reports.Order cannot be created, because short positions are forbidden. You don't have enough rights.": "Ордер не может быть создан, так как запрещены короткие позиции. У Вас нет достаточно прав.",
    "reports.Order cannot be created, because short positions are forbidden. You donґt have enough rights.": "Ордер не может быть создан, так как короткие позиции запрещены. У вас не хватает прав.",
    "reports.Order cannot be created, because short positions are forbidden.Or yor already have Limit/Stop orders for cover all your positions.": "Ордер не может быть создан, так как короткие позиции запрещены, или у вас уже есть Лимит или Стоп ордера, которые покрывают данную позицию.",
    "reports.Order cannot be placed in non-trading day. Please, check the trading calendar.": "Ордер не может быть отправлен в неторговый день. Пожалуйста, проверьте торговый календарь.",
    "reports.Order groupID": "ID группы ордеров",
    "reports.Order History Report": "Отчет по истории ордеров",
    "reports.Order modification is forbidden by trade session": "Изменение ордера запрещено торговой сессией",
    "reports.Order number": "ID ордера",
    "reports.Order placing request": "Запрос на размещение ордера",
    "reports.Order price": "Цена ордера",
    "reports.Order Price": "Цена ордера",
    "reports.Order received by STP": "Ордер получен STP",
    "reports.Order replace request": "Запрос на замену ордера",
    "reports.Order replacing confirmed": "Замена ордера подтверждена",
    "reports.Order TIF is forbidden by trade session": "Срок действия ордера запрещен торгововй сессией",
    "reports.Order type": "Тип ордера",
    "reports.Order type is forbidden by trade session": "Тип ордера запрещен торговой сессией",
    "reports.Order was rejected due to invalid price!": "Ордер отклонен из-за неверной цены.",
    "reports.order_book_account": "Счет",
    "reports.order_book_datetime": "Дата",
    "reports.order_book_event": "Событие",
    "reports.order_book_login": "Логин",
    "reports.order_book_modified_by": "Изменено пользователем",
    "reports.order_book_orderid": "ID ордера",
    "reports.order_book_price": "Цена",
    "reports.order_book_quantity": "Объем",
    "reports.order_book_route": "Маршрут",
    "reports.order_book_side": "Направление",
    "reports.order_book_stop_price": "Цена Стоп",
    "reports.order_book_symbol": "Инструмент",
    "reports.order_book_symbol_type": "Тип инструмента",
    "reports.order_book_tif": "Срок действия",
    "reports.order_book_type": "Тип",
    "reports.orderCreate": "Созд.орд.",
    "reports.orderExecute": "Испол.орд.",
    "reports.OrderExternalConfirm": "Внешн. подтв. ордера",
    "reports.orderid": "ID ордера",
    "reports.orderId": "ID ордера",
    "reports.OrderId": "ID ордера",
    "reports.orderModify": "Редакт.орд.",
    "reports.orderMutural": "Взаимн.орд.",
    "reports.orderRemoved": "Удал.орд.",
    "reports.ORDERS_PANEL_HOT_BTN": "Панель Раб. ордера (горячие клавиши)",
    "reports.ordertype": "Тип ордера",
    "reports.Oreder Number": "ID ордера",
    "reports.originalvalue": "Оригинальное значение",
    "reports.OTHER": "Другое",
    "reports.other_account_operations": "Другие оперции по счету",
    "reports.Out of session trading is forbidden": "Торговля вне сессии запрещена",
    "reports.Overnight trading is forbidden for account": "Перенос позиций через ночь запрещен для счета",
    "reports.PAMM account can not be removed during investment period.": "Не возможно удалить фонд во время инвестиционного периода",
    "reports.PAMM join": "Вступление в фонд",
    "reports.pamm.OrderByPnL.max_loss": "Наибольший убыток",
    "reports.pamm.OrderByPnL.max_profit": "Наибольшая прибыль",
    "reports.pamm.OrderByPnL.min_loss": "Наименьший убыток",
    "reports.pamm.OrderByPnL.min_profit": "Наименьшая прибыль",
    "reports.pamm.OrderByPnL.none": "Нет",
    "reports.pamm.OrderBySize.max": "Наибольшая позиция",
    "reports.pamm.OrderBySize.min": "Наименьшая позиция",
    "reports.pamm.OrderByTime.first": "Первый открыв.",
    "reports.pamm.OrderByTime.last": "Последний открыв.",
    "reports.pammState.0": "Новый",
    "reports.pammState.1": "Инструмент",
    "reports.pammState.2": "Конец инвестирования",
    "reports.pammState.3": "Клиринг",
    "reports.pammState.4": "Ожидает инвесторов",
    "reports.pammState.6": "Начало инвестирования",
    "reports.pammState.clearing": "Клиринг",
    "reports.pammState.confirmed": "подтвержденный",
    "reports.pammState.end_of_investment": "Окончание инвестирования",
    "reports.pammState.investment": "Инвестирование",
    "reports.pammState.Investment": "Инвестирование",
    "reports.pammState.new": "Новый",
    "reports.pammState.pending": "ожидающий",
    "reports.pammState.start_of_investment": "Начало инвестирования",
    "reports.pammState.wait_for_investors": "Ожидание инвесторов",
    "reports.PAMMTransfer": "Перевод средств",
    "reports.Partially filled": "Частично заполнен",
    "reports.Partially filled close Limit order": "Закр. лимит ордер частично заполнен",
    "reports.Partially filled Close Limit Order": "Закр. лимит ордер частично заполнен",
    "reports.Partially filled close Limit order (Mutual)": "Закр. лимит ордер частично заполнен (вручную)",
    "reports.Partially filled close Market order": "Закр. рыночный ордер частично заполнен",
    "reports.Partially filled Close Market Order": "Закр. рыночный ордер частично заполнен",
    "reports.Partially filled close Market order (Mutual)": "Закр. рыночный ордер частично заполнен (вручную)",
    "reports.Partially filled close OCO order": "Закр. OCO ордер частично заполнен",
    "reports.Partially filled close OCO order (Mutual)": "Закр. OCO ордер частично заполнен (вручную)",
    "reports.Partially filled close SL order": "Закр. СЛ ордер частично заполнен",
    "reports.Partially filled close SL order (Mutual)": "Закр. СЛ ордер частично заполнен (вручную)",
    "reports.Partially filled close SL tr. stop order": "Закр. СЛ Тр. стоп ордер частично заполнен",
    "reports.Partially filled close SL tr. stop order (Mutual)": "Закр. СЛ Тр. стоп ордер частично заполнен (вручную)",
    "reports.Partially filled close Stop Limit order": "Закр. стоп лимит ордер частично заполнен",
    "reports.Partially filled close Stop Limit order (Mutual)": "Закр. стоп лимит ордер частично заполнен (вручную)",
    "reports.Partially filled close Stop order": "Закр. стоп ордер частично заполнен",
    "reports.Partially filled Close Stop Order": "Закр. стоп ордер частично заполнен",
    "reports.Partially filled close Stop order (Mutual)": "Закр. стоп ордер частично заполнен (вручную)",
    "reports.Partially filled close TP order": "Закр. ТП ордер частично заполнен",
    "reports.Partially filled close TP order (Mutual)": "Закр. ТП ордер частично заполнен (вручную)",
    "reports.Partially filled close Tr. stop order": "Закр. Тр. стоп ордер частично заполнен",
    "reports.Partially filled close Tr. stop order (Mutual)": "Закр. Тр. стоп ордер частично заполнен (вручную)",
    "reports.Partially filled Limit order": "Лимит ордер частично заполнен",
    "reports.Partially filled Limit order (Mutual)": "Лимит ордер частично заполнен (вручную)",
    "reports.Partially filled Market order": "Рыночный ордер частично заполнен",
    "reports.Partially filled Market Order": "Рыночный ордер частично заполнен",
    "reports.Partially filled Market order (Mutual)": "Рыночный ордер частично заполнен(вручную)",
    "reports.Partially filled OCO order": "OCO ордер частично заполнен",
    "reports.Partially filled OCO order (Mutual)": "OCO ордер частично заполнен (вручную)",
    "reports.Partially filled SL order": "СЛ ордер частично заполнен",
    "reports.Partially filled SL order (Mutual)": "СЛ ордер частично заполнен (вручную)",
    "reports.Partially filled SL tr. stop order": "СЛ Тр. стоп ордер частично заполнен",
    "reports.Partially filled SL tr. stop order (Mutual)": "СЛ Тр. стоп ордер частично заполнен (вручную)",
    "reports.Partially filled Stop Limit order": "Стоп лимит ордер частично заполнен",
    "reports.Partially filled Stop Limit order (Mutual)": "Стоп лимит ордер частично заполнен (вручную)",
    "reports.Partially filled Stop order": "Стоп ордер частично заполнен",
    "reports.Partially filled Stop order (Mutual)": "Стоп ордер частично заполнен (вручную)",
    "reports.Partially filled TP order": "ТП ордер частично заполнен",
    "reports.Partially filled TP order (Mutual)": "ТП ордер частично заполнен (вручную)",
    "reports.Partially filled Tr. stop order": "Тр. стоп ордер частично заполнен",
    "reports.Partially filled Tr. stop order (Mutual)": "Тр. стоп ордер частично заполнен (вручную)",
    "reports.Partially filled Traling Stop Order": "Трейлинг стоп ордер частично заполнен",
    "reports.Password required with level - 1": "Неправильный пароль: Новый пароль должен иметь минимум один символ верхнего регистра, 1 символ нижнего регистра. Попробуйте, пожалуйста, еще раз.",
    "reports.Password required with level - 2": "Неправильный пароль: Новый пароль должен иметь минимум один символ верхнего регистра, 1 символ нижнего регистра, 1 цифру и длинну не менее 8 символов. Попробуйте, пожалуйста, еще раз.",
    "reports.Password required with level - 3": "Неправильный пароль: Новый пароль должен иметь минимум один символ верхнего регистра, 1 символ нижнего регистра, 1 цифру, 1 специальнывй символ и длинну не менее 8 символов. Попробуйте, пожалуйста, еще раз.",
    "reports.PatsystemsFIXFeed": "PatsystemsFIXFeed",
    "reports.Pending": "Ожидающий",
    "reports.pendingorderscount": "Количество ожидающих ордеров",
    "reports.PeresysFIX": "PeresysFIX",
    "reports.Perfomance By Signal Report": "Отчет исполнения по сигналам",
    "reports.Perform": "Выполнить",
    "reports.Performance history report": "Отчет по истории производительности",
    "reports.PFixFeed": "PFixFeed",
    "reports.phone": "Телефон",
    "reports.phonepassword": "Телефонный пароль",
    "reports.pin": "Пин",
    "reports.pl_unrealized": "Нереализ. П/У",
    "reports.Placed": "Размещен",
    "reports.Please deposit on your account.": "Пожалуйста, положите деньги на счет",
    "reports.pnl": "Прибыль/убыток",
    "reports.port": "порт",
    "reports.posId": "ID позиции",
    "reports.Position": "Позиция",
    "reports.Position close request": "Запрос на закрытие позиции",
    "reports.Position Closed": "Позиция закрыта",
    "reports.Position Closed (Mutual)": "Позиция закрыта (взаимно)",
    "reports.Position Closed (SL)": "Позиция закрыта (СЛ)",
    "reports.Position Closed (TP)": "Позиция закрыта (ТП)",
    "reports.Position closed successfully": "Позиция закрыта успешно",
    "reports.POSITION MODIFIED": "Позиция изменена",
    "reports.Position modify request": "Запрос на изменение позиции",
    "reports.Position number": "ID позиции",
    "reports.positionOpened": "Позиция открыта",
    "reports.Position Opened (Limit Order)": "Позиция открыта (Лимит ордер)",
    "reports.Position Types Ratio By Instrument": "Типы позиций по отношению к инструментам",
    "reports.Position: {1} closed successfully": "Позиция: {1} закрыта успешно",
    "reports.positionamount": "Объем позиции",
    "reports.positionid": "ID позиции",
    "reports.positionId": "ID позиции",
    "reports.PositionId": "ID позиции",
    "reports.Positions History Report": "Отчет по истории позиций",
    "reports.Positions On Chosen Moment Report": "Отчет по позициям в выбранный момент времени",
    "reports.POSITIONS_PANEL_HOT_BTN": "Панель Позиции (горячие клавиши)",
    "reports.positionstatus": "Статус позиции",
    "reports.price": "Цена",
    "reports.Price": "Цена",
    "reports.Price is too close to current Ask price": "",
    "reports.Price is too close to current Bid price": "",
    "reports.priceExchange": "Цена обмена",
    "reports.priceExternal": "Внеш.цена",
    "reports.product type": "",
    "reports.productType": "Тип продукта",
    "reports.PROFILE_LANGUAGE": "Язык профайла",
    "reports.profit": "Прибыль",
    "reports.Projected balance": "Прогноз. баланс",
    "reports.protocol": "Протокол",
    "reports.ProTrader": "Protrader",
    "reports.ProTraderFIX": "ProTraderFIX",
    "reports.Purchase And Sales Report": "Отчет по покупкам и продажам",
    "reports.purchaseprice": "Экспозиция",
    "reports.quantity": "Объем",
    "reports.QueueMaxSize": "Макс. размер очереди",
    "reports.quicktable.noData": "Нет данных для отображения. Пожалуйста, выберите необходимые критерии отчетов для получения необходимой информации.",
    "reports.Quote is too old": "Котировки слишком старые",
    "reports.QuoteDelay(sec)": "Задержка котировок (сек)",
    "reports.quoteticker": "Тикер котировок",
    "reports.raor_administrator": "Брокер",
    "reports.raor_approvetime": "Время подтверждения",
    "reports.raor_balance": "С баланса счета",
    "reports.raor_basis": "Комментарии",
    "reports.raor_commission_percent": "Комиссия в %",
    "reports.raor_counterbalance": "На баланс счета",
    "reports.raor_countersum": "На сумму счета",
    "reports.raor_crossprice": "Кросс цена",
    "reports.raor_fromaccount": "Со счета",
    "reports.raor_operationtime": "Время операции",
    "reports.raor_operationtype": "Тип операции",
    "reports.raor_requestid": "ID операции",
    "reports.raor_state": "Статус подтверждения",
    "reports.raor_sum": "С суммы счета",
    "reports.raor_sum_without_commission": "Сумма без комиссии",
    "reports.raor_toaccount": "На счет",
    "reports.rate": "ставка",
    "reports.Rates Report": "Отчет по курсам",
    "reports.Realised profit/loss": "Реализованная прибыль/убытки",
    "reports.Reason for rejection: Trading disabled by risk rules. .": "Reason for rejection: Trading disabled by risk rules. .",
    "reports.receiveBestBidOfferOnly": "Получать только наилучший бид",
    "reports.recipient": "Получатель",
    "reports.ReconnectInterval": "Интервал пересоединения",
    "reports.refreshInterval": "обновить интервал",
    "reports.RefreshonLogon": "Обновить при входе",
    "reports.RefreshOnLogon": "Обновить при входе в систему",
    "reports.Refused": "Отказавшийся",
    "reports.Refused Amount": "Отклоненный объем",
    "reports.Rejected": "Отклонен",
    "reports.remoteid": "Удаленный ID",
    "reports.Remove SL for": "Удалить СЛ для",
    "reports.Removed Orders Report": "Отчет об удаленных ордерах",
    "reports.removedtime": "Время удаления",
    "reports.removeliquidityrebate": "Удаление ликвидности",
    "reports.Replace request received by STP": "Запрос на замену получен STP",
    "reports.REPORT_RULES": "Видимость отчетов",
    "reports.report.currency": "Валюта",
    "reports.report.route": "Маршрут",
    "reports.reportAccountSummaryReport": "Отчет о статусе счета",
    "reports.reportAllActiveOrdersReport": "Отчет об активных ордерах",
    "reports.reportAllNotActiveOrdersReport": "Отчет о исполненных ордерах",
    "reports.reportAllRemovedOrdersReport": "Отчет об удаленных ордерах",
    "reports.reportBalanceByInstrument": "Отчет баланса по инструментам",
    "reports.reportBalanceReport": "Отчет баланса",
    "reports.reportFormalDialogReport": "Отчет о формальном диалоге",
    "reports.reportInformalDialogReport": "Отчет по истории чата",
    "reports.reportNewUsersReport": "Отчет о новых пользователях",
    "reports.reportOpenedPositionReport": "Отчет об открытых позициях",
    "reports.reportPerfomanceReport": "Отчет о результатах",
    "reports.reportPurchaseAndSalesReport": "Отчет о покупках и продажах",
    "reports.reportSummaryNewReport": "Итоговый отчет",
    "reports.reportSummaryReport": "Отчет о суммарном балансе",
    "reports.reportTradeOperationReport": "Отчет о торговой операции",
    "reports.reportTradesReport": "Отчет по сделкам",
    "reports.reportUsersReport": "Отчет о пользователях",
    "reports.Request Account Operation Report": "Отчет по запросам операций по счету",
    "reports.Request is not allowed for this account": "Запрос не разрешен для данного счета",
    "reports.Request not supported": "Запрос не поддерживается",
    "reports.RequestAccountOperationReport": "Запрос отчета по операциям по счету",
    "reports.Required Balance": "Требуемый баланс",
    "reports.REQUIRED_STRONG_PASSWORDS": "Требуется надежный пароль",
    "reports.ResetOnDisconnect": "Перегрузить при выходе из системы",
    "reports.ResetOnLogon": "Перегрузить при входе в систему",
    "reports.ResetOnLogout": "Перегрузить при выходе из системы",
    "reports.Restated": "",
    "reports.Reuters": "Reuters",
    "reports.Revenue plan": "План доходов",
    "reports.REVENUE_PLAN_GENERAL": "Общее",
    "reports.revenue.name": "Название плана доходов",
    "reports.revenue.speccrossinstrument": "Опред. кросс-инструмент",
    "reports.revenue.speccurrency": "Опред. валюту",
    "reports.Reverse": "Реверс",
    "reports.reward_type.capital_percent": "Капитал, %",
    "reports.reward_type.fixed": "Фиксированное",
    "reports.reward_type.fixed_and_percent": "Фикс. + Рост инвест периода, %",
    "reports.reward_type.no": "Нет",
    "reports.reward_type.percent": "Рост инвест периода, %",
    "reports.role": "Роль",
    "reports.Rollback": "Отмена",
    "reports.Rollback position": "Откат позиции",
    "reports.Rollback position request": "Запрос на откат позиции",
    "reports.route": "Маршрут",
    "reports.Route": "Маршрут",
    "reports.Route Trading Statistics Report": "Отчет о торговой статистике по маршруту",
    "reports.Route Visibility": "Отчет по видимости маршрутов",
    "reports.Route Visibility Report": "Отчет по видимости маршрутов",
    "reports.RouteInstrMap": "Карта инструментов",
    "reports.routename": "Маршрут",
    "reports.routes": "Маршруты",
    "reports.routes.account": "Счет фида",
    "reports.routes.allow_day": "Дневной",
    "reports.routes.allow_foc": "FOK",
    "reports.routes.allow_gtc": "GTC",
    "reports.routes.allow_ioc": "IOC",
    "reports.routes.allow_market": "Рыночный",
    "reports.routes.allow_moc": "Разрешить MOC",
    "reports.routes.allow_moo": "Разрешить MOO",
    "reports.routes.allow_stop": "Стоп",
    "reports.routes.allow_stoplimit": "Лимит",
    "reports.routes.allow_stoptolimit": "Стоп лимит",
    "reports.routes.route_comment": "Комментарий",
    "reports.routes.route_external_feed": "Поставщик ликвидности",
    "reports.routes.route_isactive": "Активен",
    "reports.routes.route_isinfo": "Информационный",
    "reports.routes.route_istrade": "Торговый",
    "reports.routes.route_login": "Логин",
    "reports.routes.route_name": "Имя",
    "reports.routes.route_password": "Пароль",
    "reports.routes.route_saveQuotes": "Сохранить отдельную историю",
    "reports.routes.route_status": "Статус",
    "reports.ROUTESVIS_RULES": "Видимость маршрутов",
    "reports.RssNews": "RssNews",
    "reports.RTS": "RTS",
    "reports.rules.error.restriction.max": "Число должно быть меньше",
    "reports.rules.error.restriction.min": "Число должно быть больше",
    "reports.SAVED_ORDERS": "Сохраненные ордера",
    "reports.saveLogs": "Сохранить журнал",
    "reports.saveNews": "Сохранить новости",
    "reports.Saxo Bank": "Saxo Bank",
    "reports.SCALPER": "Скальпер",
    "reports.Scheduler Log Report": "Отчет по журналу планировщика",
    "reports.scheduler.accountoperation_archive": "Архив операций по счету",
    "reports.scheduler.dailyreport": "Отправить отчет",
    "reports.scheduler.dividentsservice": "Выполнить предварительные дивиденты позиций",
    "reports.scheduler.lastexcecuted": "Выполняется",
    "reports.scheduler.modifyRuleTask": "Редактирование задания",
    "reports.scheduler.movepositiontask": "Убрать задание по позициям",
    "reports.scheduler.openclosetradingfor": "Открыть/Закрыть торговлю",
    "reports.scheduler.opentradingfor": "Открытая торговля",
    "reports.scheduler.parameter.backup": "Уровень резервного копирования",
    "reports.scheduler.parameter.calculateByClosePrice": "Использовать текущую цену закрытия",
    "reports.scheduler.parameter.calculateStrategy": "Тип вычсилений",
    "reports.scheduler.parameter.coeff1": "Часть 1",
    "reports.scheduler.parameter.coeff2": "Часть 2",
    "reports.scheduler.parameter.comment": "Комментарий к операции по счету",
    "reports.scheduler.parameter.counteraccount": "Обратный счет",
    "reports.scheduler.parameter.days_before_exp": "Дней до экспирации",
    "reports.scheduler.parameter.divident": "Сумма дивидендов",
    "reports.scheduler.parameter.dividentmode": "Тип дивидендов",
    "reports.scheduler.parameter.email": "Адреса эл. почты (разделенные запятой)",
    "reports.scheduler.parameter.inheritUserGroups": "Включать подгруппы",
    "reports.scheduler.parameter.instrtype": "Группа инструментов",
    "reports.scheduler.parameter.instrument": "Инструмент",
    "reports.scheduler.parameter.isopen": "Открыть/Закрыть торговлю",
    "reports.scheduler.parameter.isupdateall": "Обновить все инструменты в группе",
    "reports.scheduler.parameter.margincoeff": "Маржинальный коэффициент (зависит от времени)",
    "reports.scheduler.parameter.max": "Максимум (%)",
    "reports.scheduler.parameter.message": "Сообщение",
    "reports.scheduler.parameter.min": "Минимум (%)",
    "reports.scheduler.parameter.operations": "Тип операции",
    "reports.scheduler.parameter.owner": "Объект",
    "reports.scheduler.parameter.ownerType": "Тип объекта",
    "reports.scheduler.parameter.putinaleerts": "Добавить в Алерты",
    "reports.scheduler.parameter.reconciliation.ClientId": "Ид. клиента для взаимодействия",
    "reports.scheduler.parameter.reconciliation.Direction": "Направление",
    "reports.scheduler.parameter.reconciliation.FromDate": "Начать взаимодействие от даты",
    "reports.scheduler.parameter.reconciliation.Route": "Маршрут",
    "reports.scheduler.parameter.reconciliation.SendItemName": "Отправка имени файла",
    "reports.scheduler.parameter.reconciliation.Storage": "Тип сохранения",
    "reports.scheduler.parameter.reconciliation.Transport": "Тип транспортировки",
    "reports.scheduler.parameter.reconciliation.transportDestination": "Направление",
    "reports.scheduler.parameter.reconciliation.transportLogin": "Логин аутентификации",
    "reports.scheduler.parameter.reconciliation.transportPassword": "Пароль аутентификации",
    "reports.scheduler.parameter.reconciliation.Type": "Тип взаиморасчетов",
    "reports.scheduler.parameter.reports": "Выбрать отчет",
    "reports.scheduler.parameter.revenueplan": "Выбрать план доходов",
    "reports.scheduler.parameter.routes": "Каждый активный маршрут должен быть",
    "reports.scheduler.parameter.routesName": "Включить маршруты",
    "reports.scheduler.parameter.rule": "Правило",
    "reports.scheduler.parameter.ruleType": "Тип правила",
    "reports.scheduler.parameter.ruleView": "Значение",
    "reports.scheduler.parameter.sum": "Объем в системной валюте",
    "reports.scheduler.parameter.swapCoefKey": "Своп коэффициент",
    "reports.scheduler.parameter.userGroup": "Группа польз.",
    "reports.scheduler.parameter.usergroups": "Выбрать группу пользователей",
    "reports.scheduler.removedo": "Запустить удаление дневных ордеров",
    "reports.scheduler.rolloverservice": "Запустить ролловер фьючерсов",
    "reports.scheduler.runinterestservice": "Запустить интерес на сервис баланса",
    "reports.scheduler.runsignalsubscribtion": "Снять дневную комиссию по сигналам",
    "reports.scheduler.runswaptask": "Запустить своп",
    "reports.scheduler.splitinstrument": "Дробление Инструмента",
    "reports.scheduler.type.accountoperation": "Оцерации по счету",
    "reports.scheduler.type.backup": "Резервная копия",
    "reports.scheduler.type.beginbalance": "Начальный баланс",
    "reports.scheduler.type.broadcastmessage": "Онлайн сообщение",
    "reports.scheduler.type.changemargincoeff": "Коэф. маржи (зависит от времени)",
    "reports.scheduler.type.changerevenue": "Изменить план доходов",
    "reports.scheduler.type.closeoptions": "Скрыть истекшие инстр.",
    "reports.scheduler.type.deletedemousers": "Удалить демо-польз.",
    "reports.scheduler.type.deletequotes": "Удалить старые котировки",
    "reports.scheduler.type.fixswapplan": "Вычислить план по свопам",
    "reports.scheduler.type.futuresrollover": "Выполнить ролловер фьючерсов",
    "reports.scheduler.type.insertlastquotestask": "Вставить последние котировки",
    "reports.scheduler.type.lockbybroker": "Заблокирован брокером",
    "reports.scheduler.type.message": "Сообщение",
    "reports.scheduler.type.modifyobject": "Редактировать объект",
    "reports.scheduler.type.pamm": "Задача по фондам",
    "reports.scheduler.type.pendingpayment": "Незавершенный платеж",
    "reports.scheduler.type.reconciliation": "Согласование",
    "reports.scheduler.type.reconnect_routes": "Активные маршруты старт/стоп",
    "reports.scheduler.type.subscribe": "Получить платежи по подписке",
    "reports.scheduler.type.unknown": "Неизвестный тип",
    "reports.scheduler.type.updatespikefilter": "Обновить спайк фильтр",
    "reports.scheduler.type.updateuserstradinginfo": "Обновить статистику",
    "reports.ScreenLogEvents": "Экран протокола событий",
    "reports.ScreenLogShowHeartBeats": "Экран логов показывает хартбиты",
    "reports.ScreenLogShowIncoming": "Экран логов показывает доходы",
    "reports.ScreenLogShowOutgoing": "Экран логов показывает платежи",
    "reports.Script": "Скрипт",
    "reports.SDK": "SDK",
    "reports.seconds": "секунд",
    "reports.SecuritiesTransferTax": "Налог на передачу ценных бумаг",
    "reports.Self trading is not allowed.": "Самостоятельная торговля запрещена.",
    "reports.Sell": "Продажа",
    "reports.sell trade amount": "Объем продаж",
    "reports.sell trades": "Продажи",
    "reports.Send from": "Отправлено из",
    "reports.Send_Rej": "Отклонить",
    "reports.sender": "Отправитель",
    "reports.SenderCompID": "ID компьютера отправителя",
    "reports.senderip": "IP отправителя",
    "reports.SendResetSeqNumFlag": "Отправит метку сброса порядковых номеров",
    "reports.sendTag200": "отправить Tag200",
    "reports.server": "сервер",
    "reports.server_url": "Адрес сервера",
    "reports.SERVER.AUTO.RECONNECT": "SERVER AUTO RECONNECT",
    "reports.SERVER.POLLING.INTERVAL": "SERVER POLLING INTERVAL",
    "reports.serviceName": "Имя сервиса",
    "reports.sessionid": "ID сессии",
    "reports.sessiontype": "Тип сессии",
    "reports.SETTINGS_FILTER": "Фильтры",
    "reports.SETTINGS_GENERAL": "Основной",
    "reports.SETTINGS_LOCKING": "Блокировка",
    "reports.SETTINGS_QUOTE_CACHE_SIZE": "Настройка размера кэша котировок",
    "reports.SETTINGS_QUOTE_SAVE": "Настройки сохранения котировок",
    "reports.SETTINGS_QUOTES": "Котировки",
    "reports.SETTINGS_REGISTRATION": "Регистрация",
    "reports.SETTINGS_TRADING": "Торговля",
    "reports.SettleDate": "Дата экспирации",
    "reports.SettleDateForward": "Дата валютирования",
    "reports.SettlementFee": "Комиссия за поставку",
    "reports.Sharia Operations Report": "Отчет о \"Шариа\" своп",
    "reports.signedrequest": "Подписанный ответ",
    "reports.sizeForQuoteSubscription": "размер подписки",
    "reports.SL": "Стоп лосс",
    "reports.SL accepted by broker": "СЛ ордер принят брокером",
    "reports.SL accepted by STP": "СЛ ордер принят STP",
    "reports.SL order accepted": "СЛ ордер принят",
    "reports.SL order cancel request": "Запрос на отмену СЛ ордера",
    "reports.SL order canceled": "СЛ ордер отменен",
    "reports.SL order created": "СЛ ордер создан",
    "reports.SL Order Created": "СЛ ордер создан",
    "reports.SL order created is off market": "",
    "reports.SL order created is unplaced": "",
    "reports.SL Order Modified": "СЛ ордер изменен",
    "reports.SL order partially reject": "СЛ ордер частично отменен",
    "reports.SL order placing request": "Запрос на размещение СЛ ордера",
    "reports.SL order received by broker": "СЛ ордер получен брокером",
    "reports.SL order rejected": "СЛ ордер отклонен",
    "reports.SL Order Removed": "СЛ ордер удален",
    "reports.SL order replace request": "Запрос на замену СЛ ордера",
    "reports.SL order replaced": "СЛ ордер заменен",
    "reports.SL price": "Цена СЛ",
    "reports.SL tr. stop": "Стоп лосс тр. стоп",
    "reports.SL tr. stop accepted by broker": "СЛ Тр. стоп ордер принят брокером",
    "reports.SL tr. stop accepted by STP": "СЛ Тр. стоп ордер принят STP",
    "reports.SL tr. stop order accepted": "СЛ Тр. стоп ордер принят",
    "reports.SL tr. stop order canceled": "СЛ Тр. стоп ордер отменен",
    "reports.SL tr. stop order created": "СЛ Тр. стоп ордер создан",
    "reports.SL tr. stop order created is off market": "",
    "reports.SL tr. stop order created is unplaced": "",
    "reports.SL tr. stop order partially reject": "СЛ Тр. стоп ордер частично отменен",
    "reports.SL tr. stop order received by broker": "СЛ Тр. стоп ордер получен брокером",
    "reports.SL tr. stop order rejected": "СЛ Тр. стоп ордер отклонен",
    "reports.SL tr. stop order replaced": "СЛ Тр. стоп ордер заменен",
    "reports.SmartRoute(aggregator)": "SmartRoute (агрегатор)",
    "reports.Sms generation error": "Ошибка генерации Sms",
    "reports.SMTP server required a secure connection, or identity of the client has not been established.": "SMTP сервер запрашивает защищенное соединение, или подлинность клиента не установлена.",
    "reports.SOAP API methods are not available for execution for this user": "SOAP API methods are not available for execution for this user",
    "reports.SocketConnectHost": "Хост для сокета",
    "reports.SocketConnectPort": "Сокет соединяется с портом",
    "reports.SocketKeyStore": "Ключ сокета",
    "reports.SocketKeyStorePassword": "Пароль и ключ сокета",
    "reports.SocketUseSSL": "Сокет использует SSL",
    "reports.SplitOrders": "Разделенные ордера",
    "reports.spread": "Спред",
    "reports.Spread Amount Report": "Отчет по объемам спредов",
    "reports.spreadpnl": "П/У по спреду",
    "reports.Start receiving data from server...": "Начать принимать данные от сервера...",
    "reports.StartDay": "День начала",
    "reports.StartTime": "Начальное время",
    "reports.STATIONS_RULES": "Станция",
    "reports.Statistic Report": "Отчет по статистике",
    "reports.STATUS_BLOCKED": "Блокирован",
    "reports.stock_req": "Stock orders req",
    "reports.stock_value": "Stock value",
    "reports.StockOperation": "Сток операция",
    "reports.stocks_liquidity": "Stocks liquidity ",
    "reports.Stop accepted by broker": "Стоп ордер принят брокером",
    "reports.Stop accepted by STP": "Стоп ордер принят STP",
    "reports.Stop Limit accepted by broker": "Стоп лимит ордер принят брокером",
    "reports.Stop Limit accepted by STP": "Стоп лимит ордер принят STP",
    "reports.Stop Limit order accepted": "Стоп лимит ордер принят",
    "reports.Stop Limit order activated": "",
    "reports.Stop limit order cancel request": "Запрос на отмену Стоп лимит ордера",
    "reports.Stop limit order canceled": "Стоп лимит ордер отменен",
    "reports.Stop Limit order canceled": "Стоп лимит ордер отменен",
    "reports.Stop Limit order created": "Стоп лимит ордер создан",
    "reports.Stop Limit Order Created": "Стоп лимит ордер создан",
    "reports.Stop Limit order created is off market": "",
    "reports.Stop Limit order created is unplaced": "",
    "reports.Stop limit order is awaiting market": "Stop limit order is awaiting market",
    "reports.Stop Limit Order Modified": "Стоп лимит ордер изменен",
    "reports.Stop Limit order partially reject": "Стоп лимит ордер частично отменен",
    "reports.Stop limit order placing request": "Запрос на размещение Стоп лимит ордера",
    "reports.Stop Limit order received by broker": "Стоп лимит ордер получен брокером",
    "reports.Stop Limit order rejected": "Стоп лимит ордер отклонен",
    "reports.Stop Limit Order Removed": "Стоп лимит ордер удален",
    "reports.Stop limit order replace request": "Запрос на замену Стоп лимит ордера",
    "reports.Stop limit order replaced": "Stop limit order modified",
    "reports.Stop Limit order replaced": "Стоп ордер заменен",
    "reports.Stop Limit order restated": "",
    "reports.Stop loss order canceled": "Стоп лосс  ордер отменен",
    "reports.Stop Loss price is too big": "Неверная СЛ цена для ордера",
    "reports.Stop order accepted": "Стоп ордер принят",
    "reports.Stop order activated": "",
    "reports.Stop order cancel request": "Запрос на отмену Стоп ордера",
    "reports.Stop order canceled": "Стоп ордер отменен",
    "reports.Stop order created": "Стоп ордер создан",
    "reports.Stop Order Created": "Стоп ордер создан",
    "reports.Stop order created is off market": "",
    "reports.Stop order created is unplaced": "",
    "reports.Stop order is awaiting market": "Stop order is awaiting market",
    "reports.Stop Order Modified": "Стоп ордер изменен",
    "reports.Stop order partially reject": "Стоп ордер частично отменен",
    "reports.Stop order placing request": "Запрос на размещение Стоп ордера",
    "reports.Stop order received by broker": "Стоп ордер получен брокером",
    "reports.Stop order rejected": "Стоп ордер отклонен",
    "reports.Stop Order Removed": "Стоп ордер удален",
    "reports.Stop order replace request": "Запрос на замену Стоп ордера",
    "reports.Stop order replaced": "Стоп ордер заменен",
    "reports.Stop order restated": "",
    "reports.Stop price": "Цена стоп",
    "reports.stoploss": "СЛ",
    "reports.StopOutType": "Тип стоп аута",
    "reports.stopprice": "Цена Стоп",
    "reports.STP_Route_Change": "Показать оригинальное имя маршрута в ордере",
    "reports.Strike Price": "Цена страйка",
    "reports.submitted": "Подтвежден",
    "reports.Subscribe": "Подписка",
    "reports.SUBSCRIBE_RULES": "Подписки",
    "reports.success": "Успешно",
    "reports.Successful": "Успешно",
    "reports.sum": "Сумма",
    "reports.Sum": "Сумма",
    "reports.Summary Report": "Итоговый отчет",
    "reports.SunGard": "SunGard",
    "reports.SUSPEND_MARGINCALL_ON_CLOSE_MARKET": "Приостановить требование дополнительной маржи для закрывающих рыночных ордеров",
    "reports.swap": "Своп",
    "reports.swapbuy": "Длинный своп",
    "reports.swapplan": "План свопа",
    "reports.swaps": "Свопы",
    "reports.swaps_in": "Своп (приход)",
    "reports.swaps_out": "Своп (расход)",
    "reports.swapsell": "Короткий своп",
    "reports.symbol": "Инструмент",
    "reports.Symbol": "Инструмент",
    "reports.symbol_type": "Symbol type",
    "reports.System": "Система",
    "reports.system_currency": "Системная валюта",
    "reports.systemMonitor.instruments": "Инструменты",
    "reports.systemMonitor.onlineConnections": "Онлайновые соединения",
    "reports.systemMonitor.positions": "Открытые позиции",
    "reports.systemMonitor.regusers": "Зарегистрированные пользователи",
    "reports.systemMonitor.runningRoutes": "Начать маршруты с активных",
    "reports.systemMonitor.totalQueueSize": "Очереди в сервисах",
    "reports.TABLE_ALERT": "Табличный алерт",
    "reports.tablename": "Имя таблицы",
    "reports.TableName": "Имя таблицы",
    "reports.Take profit order canceled": "Тейк профит ордер отменен",
    "reports.Take Profit price is too big": "Цена Тейк профит слишком высокая",
    "reports.takeprofit": "ТП",
    "reports.TargetCompID": "ID целевого компьтера",
    "reports.task.comment": "Комментарий",
    "reports.task.day": "День",
    "reports.task.hour": "Час",
    "reports.task.minute": "Минута",
    "reports.task.month": "Месяц",
    "reports.task.timezone": "Смещение временной зоны",
    "reports.task.usedst": "Использовать летнее время",
    "reports.task.year": "Год",
    "reports.TempFilePath": "Путь к временным файлам",
    "reports.Tenfore": "Tenfore",
    "reports.terminal": "Терминал",
    "reports.Terminal": "Терминал",
    "reports.TERMINAL_API": "API",
    "reports.TERMINAL_COMMUNITY": "Сообщество",
    "reports.TERMINAL_FIX": "FIX Соединение",
    "reports.TERMINAL_HTML": "HTML",
    "reports.TERMINAL_JAVA": "Java",
    "reports.TERMINAL_MOBILE": "Мобильный",
    "reports.TERMINAL_NET": ".Net",
    "reports.TERMINAL_POCKETPC": "Карманный ПК",
    "reports.TERMINAL_WEB": "Web-станция",
    "reports.terminaltype": "Тип терминала",
    "reports.TFDS Quote Receiver": "TFDS Quote Receiver",
    "reports.tif": "Срок действия",
    "reports.TIF": "Срок действия",
    "reports.time": "Время",
    "reports.Time": "Время",
    "reports.to": "До",
    "reports.toDate": "До",
    "reports.toDateConnAttempt": "До",
    "reports.toDatePwdChange": "До(Путь)",
    "reports.tooLargeData": "Данные для отчета слишком большие! Показывается {1} из {2} строк",
    "reports.total": "Общий",
    "reports.total traded amount": "Общий объем торговли",
    "reports.totalfee": "Суммарная комиссия",
    "reports.TP": "Тейк профит",
    "reports.TP accepted by broker": "ТП ордер принят брокером",
    "reports.TP accepted by STP": "ТП ордер принят STP",
    "reports.TP order accepted": "ТП ордер принят",
    "reports.TP order cancel request": "Запрос на отмену ТП ордера",
    "reports.TP order canceled": "ТП ордер отменен",
    "reports.TP order created": "ТП ордер создан",
    "reports.TP Order Created": "ТП ордер создан",
    "reports.TP order created is off market": "",
    "reports.TP order created is unplaced": "",
    "reports.TP Order Modified": "ТП ордер изменен",
    "reports.TP order partially reject": "ТП ордер частично отменен",
    "reports.TP order placing request": "Запрос на размещение ТП ордера",
    "reports.TP order received by broker": "ТП ордер получен брокером",
    "reports.TP order rejected": "ТП ордер отклонен",
    "reports.TP Order Removed": "ТП ордер удален",
    "reports.TP order replace request": "Запрос на замену ТП ордера",
    "reports.TP order replaced": "ТП ордер заменен",
    "reports.Tr stop loss order cancel request": "Запрос на отмену Тр. стоп ордера",
    "reports.Tr stop loss order placing request": "Запрос на размещение Тр. стоп ордера",
    "reports.Tr stop loss order replace request": "Запрос на замену Тр. стоп ордера",
    "reports.tr. stop": "",
    "reports.Tr. stop accepted by broker": "Тр. стоп ордер принят брокером",
    "reports.Tr. stop accepted by STP": "Тр. стоп ордер принят STP",
    "reports.Tr. stop is too big": "Неверное смещение Трейлинг стоп для ордера",
    "reports.Tr. stop loss order accepted": "Тр. стоп лосс ордер принят",
    "reports.Tr. stop loss order canceled": "Трелинг стоп лосс ордер отменен",
    "reports.Tr. stop loss order created": "Тр. стоп лосс ордер создан",
    "reports.Tr. stop loss order created is off market": "",
    "reports.Tr. stop loss order created is unplaced": "",
    "reports.Tr. stop loss order replaced": "Тр. стоп лосс ордер заменен",
    "reports.Tr. stop order accepted": "Тр. стоп ордер принят",
    "reports.Tr. stop order activated": "",
    "reports.Tr. stop order canceled": "Тр. стоп ордер отменен",
    "reports.Tr. stop order created": "Тр. стоп ордер создан",
    "reports.Tr. stop order created is off market": "",
    "reports.Tr. stop order created is unplaced": "",
    "reports.Tr. stop order is awaiting market": "Tr. stop order is awaiting market",
    "reports.Tr. stop order partially reject": "Тр. стоп ордер частично отменен",
    "reports.Tr. stop order received by broker": "Тр. стоп ордер получен брокером",
    "reports.Tr. stop order rejected": "Тр. стоп ордер отклонен",
    "reports.Tr. stop order replaced": "Тр. стоп ордер заменен",
    "reports.Tr. stop order restated": "",
    "reports.Tr.SL Order Removed": "Ордер Трейлинг стоп удален",
    "reports.Trade executed": "Сделка выполнена",
    "reports.Trade executed (Mutual)": "Сделка выполнена (вручную)",
    "reports.Trade executed (SL)": "Сделка выполнена (СЛ)",
    "reports.Trade Operations Report": "Отчет по торговым операциям",
    "reports.Trade Periods By Signal Report": "Отчет торговых периодов по сигналам",
    "reports.trade.BeginString": "Начальная торговая строка",
    "reports.trade.CheckCompID": "Проверка ID торгового компьютера",
    "reports.trade.CheckLatency": "Проверить задержку торговли",
    "reports.trade.ClosedResendInterval": "Торговый интервал закрытия",
    "reports.trade.ConnectionType": "Тип торгового соединения",
    "reports.trade.ContinueInitializationOnError": "Продолжить торговую инициализацию при ошибке",
    "reports.trade.DataDictionary": "Словарь торговых данных",
    "reports.trade.EndDay": "День окончания торговли",
    "reports.trade.EndTime": "Конец торгового времени",
    "reports.trade.entityId": "Trade entity Id",
    "reports.trade.FileIncludeMilliseconds": "Файлы торговли с включением миллисекунд",
    "reports.trade.FileIncludeTimeStampForMessages": "В файле логов торговли показывается время сообщений",
    "reports.trade.FileLogHeartbeats": "Мгновенный файл логов торговли",
    "reports.trade.fxintegrate.cert.host.verify": "Проверка хоста торговой fx интеграции",
    "reports.trade.fxintegrate.cert.root.ca.file": "Проверка файла торговой fx интеграции",
    "reports.trade.fxintegrate.host": "fx интегрированный хост",
    "reports.trade.fxintegrate.logging.file": "Файл логирования fx интеграции",
    "reports.trade.fxintegrate.logging.maxfilecount": "Макс. кол файлов торгового fx интегрированного логирования",
    "reports.trade.fxintegrate.logging.output": "Вывод торгового fx интегрированного логирования",
    "reports.trade.fxintegrate.logging.priority": "Приоритет торгового fx интегрированного логирования",
    "reports.trade.fxintegrate.port": "Торговый fx интегрированный порт",
    "reports.trade.fxintegrate.protocol": "Протокол торговой fx интеграции",
    "reports.trade.fxintegrate.usessl": "Торговая fx интеграция исп. ssl",
    "reports.trade.HeartBtInt": "Интервал торговых хартбитов",
    "reports.trade.JdbcDriver": "Торговый драйвер Jdbc",
    "reports.trade.JdbcPassword": "Торговый пароль Jdbc",
    "reports.trade.JdbcURL": "Торговый адрес Jdbc",
    "reports.trade.JdbcUser": "Jdbc трейдер",
    "reports.trade.keystoreAlias": "Имя торгового ключа",
    "reports.trade.keystoreFile": "Файл торгового ключа",
    "reports.trade.LogFileMaxSize": "Максимальный размер файла логов торговли",
    "reports.trade.login": "Лоигин для торговли",
    "reports.trade.MaxLogsBackups": "Макс. резервное копирование логов",
    "reports.trade.MillisecondsinTimeStamp": "Миллисекунды во времени",
    "reports.trade.MillisecondsInTimeStamp": "Миллисекунды в отметке времени для торговли",
    "reports.trade.password": "Пароль для торговли",
    "reports.trade.ReconnectInterval": "Интвервал пересоединения для торговли",
    "reports.trade.RefreshonLogon": "Обновить при выходе",
    "reports.trade.RefreshOnLogon": "Обновление торговли при выходе из системы",
    "reports.trade.ResetOnDisconnect": "перегрузить торговлю во время выхода из системы",
    "reports.trade.ResetOnLogon": "Перезагрузка торговли после выхода из системы",
    "reports.trade.ResetOnLogout": "Перегрузить торговлю при выходе из системы",
    "reports.trade.saveLogs": "Сохранить журнал торговли",
    "reports.trade.ScreenLogEvents": "Экран логов показывает события",
    "reports.trade.ScreenLogShowHeartBeats": "Экран логов показывает торговые хартбиты",
    "reports.trade.ScreenLogShowIncoming": "Экран логов показывает доходы",
    "reports.trade.ScreenLogShowOutgoing": "Экран торговых логов показывает платежи",
    "reports.trade.SenderCompID": "ID торгового отправителя",
    "reports.trade.SendResetSeqNumFlag": "Перегрузить следующий номер флага для торговли",
    "reports.trade.SocketConnectHost": "Торговый хост для сокета",
    "reports.trade.SocketConnectPort": "Порт для соединения",
    "reports.trade.SocketKeyStore": "Ключ торгового сокета",
    "reports.trade.SocketKeyStorePassword": "Пароль и ключ торгового сокета",
    "reports.trade.SocketUseSSL": "Торговый сокет использует SSL",
    "reports.trade.StartDay": "Начало торгового дня",
    "reports.trade.StartTime": "Время начала торговли",
    "reports.trade.TargetCompID": "ID целевого торгового компьютера",
    "reports.trade.UseDataDictionary": "Использовать данные словаря для торговли",
    "reports.trade.ValidateFieldsHaveValues": "Поля содержат данные",
    "reports.trade.ValidateFieldsOutOfOrder": "Подтвердить неупорядоченные торговые поля",
    "reports.trade.ValidateUserDefinedFields": "Подтвердить данные, введенные трейдером",
    "reports.tradeamount": "Торговый объем",
    "reports.tradeid": "ID сделки",
    "reports.tradePassword": "Торговый пароль",
    "reports.trader": "Трейдер",
    "reports.Trades by Signal": "Отчет о торговых сигналах",
    "reports.Trades Report": "Отчет по торговле",
    "reports.Trades report by instrument": "Отчет о сделках по инструменту",
    "reports.trades_bought": "Trades bought",
    "reports.trades_sold": "Trades sold",
    "reports.tradevolume": "Объем торговли",
    "reports.trading": "Торговля",
    "reports.Trading": "Торговля",
    "reports.Trading disabled by risk rules": "Торговля закрыта по риск правилу",
    "reports.TRADING_IDEA_CARD": "Trading idea card",
    "reports.trading_in": "Покупать",
    "reports.trading_out": "Продавать",
    "reports.TRADING_RULES_ACC": "Торговые правила",
    "reports.TRADING_RUSLES": "Торговые правила",
    "reports.TRADINGSYSTEMLIST": "Торговые системы",
    "reports.Traiding out of Main session is forbidden for this account": "Торговля вне главной сессии запрещена для этого счета",
    "reports.Trailing stop order cancel request": "Запрос на отмену Трейлинг стоп ордера",
    "reports.Trailing Stop Order Created": "Трейлинг стоп ордер создан",
    "reports.Trailing Stop Order Modified": "Трейлинг стоп ордер изменен",
    "reports.Trailing stop order placing request": "Запрос на размещение Трейлинг стоп ордера",
    "reports.Trailing Stop Order Removed": "Трейлинг стоп ордер удален",
    "reports.Trailing stop order replace request": "Запрос на замену Трейлинг стоп ордера",
    "reports.TrailingStop": "Трейлинг стоп",
    "reports.Transfer": "Трансфер",
    "reports.TransferFee": "Transfer Fee",
    "reports.True": "Правда",
    "reports.type": "Тип",
    "reports.Type": "Тип",
    "reports.Types": "Типы",
    "reports.UBSFIXFeed": "UBSFIXFeed",
    "reports.Unable to cancel the order since route is not active.": "Невозможно отклонить ордер, так как маршрут не активен.",
    "reports.Unblocking": "Разблокировка",
    "reports.Unplaced": "",
    "reports.unrealized p/l": "Нереализ. прибыль/убыток",
    "reports.unrealizedpnl": "Нереализ приб./уб.",
    "reports.unsettled_cash": "Unsettled cash",
    "reports.updatedtime": "Обновленное время",
    "reports.Updater": "Обновление",
    "reports.URL": "Адрес",
    "reports.usage": "Использование",
    "reports.USE_OPEN_PRICE_IN_MARGIN": "Использовать цену открытия",
    "reports.UseDataDictionary": "Использовать словарь данных",
    "reports.usePrevQuotedOrders": "исп. ордера с предв. котировками",
    "reports.UsePriority": "Использовать приоритет",
    "reports.user": "Пользователь",
    "reports.User": "Пользователь",
    "reports.User Authorization Error": "Ошибка при авторизации пользователя",
    "reports.user group": "Группа пользователя",
    "reports.User Previledges Report": "Отчет по привилегиям пользователей",
    "reports.user.acc_4_signals": "Счет для сигналов",
    "reports.user.address": "Адрес",
    "reports.user.amount": "Сумма",
    "reports.user.amountType": "Тип суммы",
    "reports.user.birthday": "Дата рождения",
    "reports.user.certificate": "Пользовательский сертификат",
    "reports.user.chatOnEmail": "Отправить чат по эл. почте",
    "reports.user.city": "Город",
    "reports.user.comment": "Комментарий",
    "reports.user.commission_type": "Комиссия по",
    "reports.user.email": "Эл. почта",
    "reports.user.extaccount": "Внешний счет",
    "reports.user.firstname": "Имя",
    "reports.user.gender": "Пол",
    "reports.user.group": "Группа",
    "reports.user.how_handle": "Как управлять сигналами",
    "reports.user.introducebroker": "Аккаунт Представляющего брокера",
    "reports.user.language": "Язык",
    "reports.user.lastname": "Фамилия",
    "reports.user.linkedUsers": "Привязанные пользователи",
    "reports.user.login": "Логин",
    "reports.user.middlename": "Отчество",
    "reports.user.password": "Новый пароль",
    "reports.user.phonenumber": "Номер телефона",
    "reports.user.phonepassword": "Телефонный пароль",
    "reports.user.photo": "Фото",
    "reports.user.pin": "Пин код",
    "reports.user.showInSearch": "показать в поиске",
    "reports.user.showMyContacts": "Показать мои контакты",
    "reports.user.signalsOnEmail": "Сигналы по эл. почте",
    "reports.user.state": "Штат",
    "reports.user.status": "Статус",
    "reports.user.subscribeplan": "Подписка",
    "reports.user.website": "Сайт",
    "reports.user.zipcode": "Код",
    "reports.usergroup.group": "Супер-группа",
    "reports.usergroup.name": "Группа пользователей",
    "reports.USERGROUPSVIS_RULES": "Видимость групп пользователей",
    "reports.userlogin": "Логин пользователя",
    "reports.UserLogin": "Логин пользователя",
    "reports.username": "Пользователь",
    "reports.Users Report": "Отчет по пользователям",
    "reports.Users Report(copy)": "Отчет по пользователям(копия)",
    "reports.users_num": "Кол. пользователей",
    "reports.useSSL": "использовать SSL",
    "reports.ValidateFieldsHaveValues": "Подтвержденные поля содержат значения",
    "reports.ValidateFieldsOutOfOrder": "Подтвердить неупорядоченные поля",
    "reports.ValidateUserDefinedFields": "Подтвердить данные, введенные пользователем",
    "reports.value": "Значение",
    "reports.VALUE_ADDRESS": "Адрес",
    "reports.VALUE_BASE_CURRENCY": "Базовая часть инструмента",
    "reports.VALUE_BRUTEFORCE_LOCK_MINUTS": "Значение жестко блокирует минуты",
    "reports.VALUE_CITY": "Город",
    "reports.VALUE_COMMISSION_ACCOUNT": "Обратный счет комиссий",
    "reports.VALUE_COMMISSION_FOR_TRANSFER": "Комиссия за трансфер",
    "reports.VALUE_COMMISSION_ID": "Планирование доходов",
    "reports.VALUE_COUNTER_CURRENCY": "Котируемая часть инстр.",
    "reports.VALUE_COUNTRY": "Страна",
    "reports.VALUE_DAY_BEGIN": "День начала",
    "reports.VALUE_DAY_END": "Дата окончания",
    "reports.VALUE_DAYS_TILL_EXP": "Дней до экспирации",
    "reports.VALUE_DEFAULT_BROKER": "Брокер по умолчанию",
    "reports.VALUE_DEFAULT_LANG": "Язык по умолчанию",
    "reports.VALUE_DERIVATIVE_TYPE": "Тип дериватива",
    "reports.VALUE_HEDGE_COEFFICIENT": "Коэффициент хеджирования",
    "reports.VALUE_L2QUOTEGROUP_DEPTH": "Глубина Уровня 2",
    "reports.VALUE_L2QUOTEGROUP_INTERVAL": "Интервал Уровня 2",
    "reports.VALUE_LEVERAGE": "Кредитное плечо",
    "reports.VALUE_LOT_SIZE": "Размер лота",
    "reports.VALUE_LOTSTEP": "Шаг лота",
    "reports.VALUE_MARGIN_COEFFICIENT": "Маржинальный коэффициент (зависит от времени)",
    "reports.VALUE_MARGINCALL": "Марж. требование,  %",
    "reports.VALUE_MARGINTRADING": "Уровень остановки торговли, %",
    "reports.VALUE_MARKET_RANGE": "Ширина рынка, тики",
    "reports.VALUE_MAX_DELTA_QUOTES": "Макс. допустимая разница между двумя идущими подряд котировками (ticks)",
    "reports.VALUE_MAX_DELTA_TRADES": "Макс. допустимая разница между двумя идущими подряд сделками",
    "reports.VALUE_MAXIMUM_CACHE_SIZE": "Максимальный размер кэш",
    "reports.VALUE_MAXIMUM_FILTER": "Макс. значение спайка",
    "reports.VALUE_MAXIMUMLOT": "Мак. лот",
    "reports.VALUE_MIN_OPTION_INTERVAL": "Мин. допустимая разница между двумя идущими подряд котировками",
    "reports.VALUE_MIN_PRICE_DIFF_FOR_MARGIN": "Минимальная разница цен для маржи",
    "reports.VALUE_MINIMALLOT": "Мин. лот",
    "reports.VALUE_MINIMUM_FILTER": "Мин. значение спайка",
    "reports.value_nav": "Значение/Нав.",
    "reports.VALUE_NO_QUOTES_MAXTIME": "Макс. время без котировок",
    "reports.VALUE_NUMBER_OF_SHARES": "Кол-во акций",
    "reports.VALUE_ONE_COUNTER_ACCOUNT": "Контр счет",
    "reports.VALUE_PIP_SIZE": "Размер тика (мин. изменение)",
    "reports.VALUE_PnL_ACCOUNT": "Контр счет для прибыли/убытков",
    "reports.VALUE_POST_SESSION_END": "Конец пост. сессии",
    "reports.VALUE_PRE_SESSION_BEGIN": "Начало пред. сессии",
    "reports.VALUE_PRICE_GENERATION_MODEL": "Модель ценообразования",
    "reports.VALUE_PWD_VERIFICATION_LEN": "Проверять длину пароля",
    "reports.VALUE_QUOTE_CACHE_15MIN_SIZE": "Размер кэша котировок за 15мин",
    "reports.VALUE_QUOTE_CACHE_1MIN_SIZE": "Размер кэша котировок за 1мин",
    "reports.VALUE_QUOTE_CACHE_30MIN_SIZE": "Размер кэша котировок за 30мин",
    "reports.VALUE_QUOTE_CACHE_4HOUR_SIZE": "Размер кэша котировок за 4ч",
    "reports.VALUE_QUOTE_CACHE_5MIN_SIZE": "Размер кэша котировок за 5мин",
    "reports.VALUE_QUOTE_CACHE_DAY_SIZE": "Размер кэша котировок за день",
    "reports.VALUE_QUOTE_CACHE_HOUR_SIZE": "Размер кэша котировок за 1ч",
    "reports.VALUE_QUOTE_CACHE_MONTH_SIZE": "Размер кэша котировок за месяц",
    "reports.VALUE_QUOTE_CACHE_SIZE": "Размер кэша котировок",
    "reports.VALUE_QUOTE_CACHE_TIC_SIZE": "Размер кэша котировок",
    "reports.VALUE_QUOTE_CACHE_WEEK_SIZE": "Размер кэша котировок за неделю",
    "reports.VALUE_QUOTE_CACHE_YEAR_SIZE": "Размер кэша котировок за год",
    "reports.VALUE_QUOTE_PATH": "Путь для кэша котировок",
    "reports.VALUE_QUOTES_FILE_SIZE": "Размер файла котировок",
    "reports.VALUE_SLIPPAGE": "Проскальзывание",
    "reports.VALUE_SMS_PATTERN": "SMS шаблон",
    "reports.VALUE_SPOT_INSTRUMENT": "Спот инструмент",
    "reports.VALUE_SPREAD_ACCOUNT": "Контр счет для спреда",
    "reports.VALUE_STATE": "Область (Штат)",
    "reports.VALUE_STOPOUT": "Уровень Стоп аут, %",
    "reports.VALUE_SWAP_ACCOUNT": "Контр счет для свопов",
    "reports.VALUE_TEMPLATE_NUMERIC": "Шаблоны для писем",
    "reports.VALUE_TICK_COAST": "Стоимость тика",
    "reports.VALUE_TIMEZONE_OFFSET": "Смещение часового пояса",
    "reports.VALUE_UNREPEAT_PASSWORD": "Количество неповторяющихся паролей",
    "reports.VALUE_WL_ACCOUNT": "Контр счет для ввода/вывода средств",
    "reports.VALUE_WRONG_PASSWORDS_BEFORE_LOCK": "Допустимое количество неправильныых паролей",
    "reports.VALUE_ZIP": "Индекс",
    "reports.ValueAddedTax": "НДС",
    "reports.valuedate": "Дата валютирования",
    "reports.Visibility": "Видимость",
    "reports.VISIBILITY_BRANDING": "Видимость брендинга",
    "reports.VISIBILITY_COMMISSIONSPLAN": "Видимость планирования доходов",
    "reports.VISIBILITY_EXTFIELDS": "Видимость дополнительных полей",
    "reports.VISIBILITY_INSTRUMENT_TYPE": "Видимость типов инструментов",
    "reports.VISIBILITY_ROUTE": "Видимость маршрутов",
    "reports.VISIBILITY_SUBSCRIBE_PLAN": "Подписки",
    "reports.VISIBILITY_USERGROUP": "Видимость групп пользователей",
    "reports.Wait confirm for cancel": "Ожидает подтверждения для отмены",
    "reports.Wait confirm for execution": "Ожидает подтверждения для исполнения",
    "reports.Wait confirm for modify": "Ожидает подтверждения для изменения",
    "reports.Waiting market": "В ожидании открытия рынка",
    "reports.WEB_CHART_OE": "Web график (встроен. СО)",
    "reports.WEB_CHART_VISUAL": "Web график (визуальная торговля)",
    "reports.WEB_MARKETDEPTH": "Web Стакан заявок",
    "reports.WEB_OE": "Web СО",
    "reports.WEB_OPTION_MASTER": "Web Опционы",
    "reports.WEB_ORDERS_PANEL_HOT_BTN": "Web панель Раб. ордера (горячие клавиши)",
    "reports.WEB_OTHER": "Web другое",
    "reports.WEB_POSITIONS_PANEL_HOT_BTN": "Web панель Позиции (горячие клавиши)",
    "reports.weeks": "недель",
    "reports.Widgets Instrument Statistics Report": "Отчет по статистике виджетов по инструменту",
    "reports.widgets_isr_buytrades": "Сделки на покупку",
    "reports.widgets_isr_commission": "Комиссия",
    "reports.widgets_isr_instrumentname": "Инструмент",
    "reports.widgets_isr_netpl": "Чистая П/У",
    "reports.widgets_isr_plticks": "П/У, тики",
    "reports.widgets_isr_selltrades": "Сделки на продажу",
    "reports.widgets_isr_swap": "Своп",
    "reports.widgets_isr_totaltrades": "Общие сделки",
    "reports.widgets_isr_trades": "Сделки",
    "reports.widgets_isr_tradevolume": "Объем",
    "reports.widgets_isr_winningtrades": "Выигрышные сделки",
    "reports.widthdraw": "Вывод средств",
    "reports.Withdraw": "Вывод",
    "reports.Withdrawal": "Вывод средств",
    "reports.withdrawalfee": "Комиссия за вывод средств",
    "reports.Wrong account operation summ.": "Неправильная сумма операций по счету",
    "reports.Wrong recovery key": "Неверный код восстановления",
    "reports.years": "лет",
    "reports.yes": "Да",
    "reports.You can't trade with this account.": "Вы не можете торговать этим счетом.",
    "Reserve": "",
    "closeAccount.dealticket.0": "Ваш запрос успешно отправлен. Брокер свяжется с Вами как можно скорее.",
    "closeAccount.dealticket.1": "Ваш запрос принят. В ближайшее время мы выполним необходимые действия, и счет будет закрыт.",
    "closeAccount.dealticket.2": "Ваш запрос отклонен. Для получения более подробной информации свяжитесь с вашим брокером.",
    "closeAccount.dealticket.3": "Ваш запрос отменен.",
    "ribbon.closeAccount": "Закрыть счет",
    "screen.closeAccount.header": "Подтверждение о закрытии счета",
    "screen.closeAccount.okBtnText": "Отправить запрос",
    "screen.closeAccount.closeBtnText": "Закрыть",
    "screen.closeAccount.confirmText": "Вы уверены, что хотите закрыть Ваш счет?",
    "screen.closeAccount.cancelText": "Отменить запрос",
    "screen.closeAccount.approveText": "Ваш запрос принят. В ближайшее время мы выполним необходимые действия, и счет будет закрыт.",
    "screen.closeAccount.submitText": "Ваш запрос успешно отправлен. Брокер свяжется с Вами как можно скорее.\n\nПожалуйста, имейте в виду, что запрос не будет обработан немедленно, и Вы по-прежнему сможете пользоваться своим счетом. Однако, как только вы закроете свой счет, он больше не будет доступен.",
    "ribbon.enviroment": "Среда",
    "ribbon.enviroment.help": "",
    "ribbon.enviroment.logout": "Выйти",
    "ribbon.enviroment.New": "Новое",
    "ribbon.enviroment.NotificationWindow.lockTrading": "Торговля заблокирована",
    "ribbon.enviroment.NotificationWindow.UnlockTrading": "Торговля разблокирована",
    "ribbon.enviroment.Save": "Сохранить",
    "ribbon.enviroment.settings": "Настройки",
    "ribbon.enviroment.settings.disabled.tt": "Невозможно изменять настройки при отсутствии соединения!",
    "ribbon.help.about": "О программе",
    "ribbon.help.help": "Ещё",
    "ribbon.help.home": "Домашняя страница",
    "ribbon.linking.linkBlue": "Синий",
    "ribbon.linking.linkEmpty": "Нет",
    "ribbon.linking.linkGreen": "Зеленый",
    "ribbon.linking.linkOrange": "Оранжевый",
    "ribbon.linking.linkPurple": "Фиолетовый",
    "ribbon.linking.linkRed": "Красный",
    "ribbon.linking.linkYellow": "Желтый",
    "ribbon.tools": "Инструментарий",
    "ribbon.tools.full_logs": "Полное логгирование сообщений сервера",
    "ribbon.tools.grid": "Сетка",
    "ribbon.tools.reports": "Отчеты",
    "ribbon.tools.products": "Подписки",
    "ribbon.tools.portfolio": "Мой портфель",
    "ribbon.tradingTerminal": "Терминал",
    "RiskManagement.Messages.DailyLimit": "Дневной лимит убытков достигнут",
    "RiskManagement.Messages.MaxDailyProfit": "Дневной лимит прибыли достигнут",
    "RiskManagement.Messages.MaxTrailingLimit": "Максимальная просадка по счету достигнута",
    "RiskManagement.Messages.MaxUnrealizedLossLimit": "Лимит нереализованных убытков достигнут",
    "RiskManagement.Messages.WeeklyLimit": "Недельный лимит убытков достигнут",
    "screen.about.copyright": "© 2017-2024 TraderEvolution Global LTD.",
    "screen.about.dateBuild": "Дата сборки:",
    "screen.about.title": "О программе",
    "screen.about.titleWeb": "",
    "screen.about.versionWeb": "",
    "screen.additionalVerification.comment": "Соединение ({1}) требует дополнительной верификации:",
    "screen.additionalVerification.Need activation": "Нужна активация",
    "screen.additionalVerification.Need activation.comment": "Пожалуйста, введите код активации (отосланный вам на почту)",
    "screen.additionalVerification.Need activation.watermark": "<Введите код активации из E-mail>",
    "screen.additionalVerification.title": "Дополнительная верификация",
    "screen.additionalVerification.title.Email": "Дополнительная проверка (одноразовый пароль в e-mail)",
    "screen.additionalVerification.title.Fixed": "Дополнительная проверка (фиксированный пароль)",
    "screen.additionalVerification.title.Sms": "Дополнительная проверка (одноразовый пароль в SMS)",
    "screen.additionalVerification.watermark": "<Введите дополнительный пароль>",
    "screen.changepass.cancel": "Отмена",
    "screen.changepass.changed": "Пароль успешно изменен",
    "screen.changepass.confirm": "Подтвердите новый пароль",
    "screen.changepass.new": "Новый пароль",
    "screen.changepass.ok": "ОК",
    "screen.changepass.old": "Текущий пароль",
    "screen.changepass.title": "Смена пароля",
    "screen.changepass.watermark_confirm": "<Подтвердите пароль>",
    "screen.changepass.watermark_new": "<Новый пароль>",
    "screen.changepass.watermark_old": "<Старый пароль>",
    "screen.changepass.wrongVerification": "Неверный пароль верификации",
    "screen.closePosition.cancel": "",
    "screen.closePosition.ok": "",
    "screen.closePosition.title": "",
    "screen.closePosition.maxLotWarning": "Объем больше, чем Максимум лот. Будет сгенерировано несколько ордеров.",
    "screen.editPosition.header": "Изменить позицию",
    "screen.error.title": "Ошибка",
    "screen.export.selectFormat.tt": "Выберите тип файла",
    "screen.licensing.emptyFieldErrors.password": "Password field is empty",
    "screen.licensing.statusMessages.connecting": "",
    "screen.login.change_pass": "Пароль был изменен или истек срок действия, необходимо изменить",
    "screen.lookup.scripts.noitems": "Нет совпадающих элементов",
    "screen.lookup.scripts.showing": "Показано {1} элемента(ов)",
    "screen.lookup.scripts.watermark": "<Поиск>",
    "screen.modifyOrder.amount": "Объем:",
    "screen.modifyOrder.bind_to": "Привязать к:",
    "screen.modifyOrder.Change order": "Изменить ордер",
    "screen.modifyOrder.created": "Созданный",
    "screen.modifyOrder.instrument": "Инструмент:",
    "screen.modifyOrder.market_price": "Рыночная цена:",
    "screen.modifyOrder.modify": "Редактировать",
    "screen.modifyOrder.modifyPosition": "Редактировать позицию",
    "screen.modifyOrder.number": "ID позиции:",
    "screen.modifyOrder.operation": "Направление:",
    "screen.modifyOrder.orderNumber": "ID ордера:",
    "screen.modifyOrder.ordertype": "Тип ордера:",
    "screen.modifyOrder.price": "Цена открытия:",
    "screen.modifyOrder.route": "Маршрут:",
    "screen.properties.cancel": "Отмена",
    "screen.properties.cancel.ToolTip": "",
    "screen.properties.common": "- свойства",
    "screen.properties.ok": "ОК",
    "screen.properties.ok.ToolTip": "",
    "screen.properties.title": "Настройки",
    "screen.properties.unsavedChanges": "У вас есть несохраненные изменения. Выйти без сохранения?",
    "positionSizeCalculator.header": "Калькулятор размера позиции",
    "positionSizeCalculator.infoText": "Расчет объема на основе максимально допустимого процентного (или фиксированного) убытка для счета . Калькулятор размера позиции - это инструмент управления рисками, который позволяет рассчитать объем на основе максимально допустимого процентного (или фиксированного денежного) убытка для счета. Укажите риск счета и стоп-лосс для сделки. Расчет будет выполнен автоматически.",
    "positionSizeCalculator.infoText.linkPart": " Для получения дополнительной информации, пожалуйста, перейдите по ссылке {0}",
    "positionSizeCalculator.infoText": "Калькулятор помогает определить рекомендуемый объем позиции, исходя из риска, на который вы готовы пойти.",
    "positionSizeCalculator.infoText.linkPart": "Подробнее в статье {0}Position sizing{0}.",
    "positionSizeCalculator.helpLink": "",
    "positionSizeCalculator.accountRisk": "Риск счета",
    "positionSizeCalculator.accountRisk.tt": "Если вы хотите рискнуть определенным процентом ваших Доступных средств, введите этот процент. Или измените единицу измерения риска на фиксированную и введите объем средств, которой вы хотите рискнуть",
    "positionSizeCalculator.cashRiskLabel": "Денежный риск",
    "positionSizeCalculator.percentRiskLabel": "Процентный риск",
    "positionSizeCalculator.PercentMenuItem": "Процентный",
    "positionSizeCalculator.FixedMenuItem": "Фиксированный",
    "positionSizeCalculator.UnableToCalc": "Невозможно рассчитать значение",
    "positionSizeCalculator.button.tt": "Включает дополнительные поля для автоматического расчета объема",
    "positionSizeCalculator.buttonDisabled.tt": "Невозможно использовать калькулятор размера позиции, потому что SL запрещен",
    "positionSizeCalculator.OEButtonDisabled.less": "Объем меньше минимально допустимого ({0})",
    "positionSizeCalculator.OEButtonDisabled.more": "Объем больше максимально допустимого ({0})",
    "screen.LoginScreen.Connecting": "Соединение...",
    "screen.LoginScreen.Error.loginIdIsEmpty": "Поле Логин пустое",
    "screen.LoginScreen.Error.passwordIdIsEmpty": "Поле Пароль пустое",
    "screen.LoginScreen.ForgotLinkLabel": "Восстановить",
    "screen.LoginScreen.ForgotLinkLabel.ToolTip": "Восстановить пароль",
    "screen.LoginScreen.LoadingUserData": "Загрузка данных пользователя...",
    "screen.LoginScreen.LoginButtonText": "Вход",
    "screen.LoginScreen.LoginButtonTooltip": "Войти в платформу",
    "screen.LoginScreen.LoginIDLabel": "Логин:",
    "screen.LoginScreen.LoginIDTextBox.ToolTip": "Логин пользователя",
    "screen.LoginScreen.PasswordLabel": "Пароль:",
    "screen.LoginScreen.PasswordTextBox.ToolTip": "Пароль пользователя",
    "screen.LoginScreen.RegistrationLinkLabel": "Регистрация",
    "screen.LoginScreen.RegistrationLinkLabel.ToolTip": "Регистрация демо аккаунта",
    "screen.LoginScreen.SavePassCheckBox": "",
    "screen.LoginScreen.SavePassCheckBox.ToolTip": "",
    "screen.LoginScreen.Settings.LanguageLabel": "Язык",
    "screen.recconection.attemptLabelLabel": "Попытки:",
    "screen.recconection.reconnecting": "Пересоединение...",
    "screen.recconection.seconds": "секунд...",
    "screen.recconection.statusLabelLabel": "Статус:",
    "screen.recconection.stop": "Стоп",
    "screen.recconection.title": "Диспетчер повторного подключения",
    "screen.recconection.tryNow": "Соединиться",
    "screen.recconection.waiting": "Ожидание",
    "screen.RecoveryPassForm.Cancel": "Отмена",
    "screen.RecoveryPassForm.Confirm": "Подтвердить",
    "screen.RecoveryPassForm.Email": "Эл. почта:",
    "screen.RecoveryPassForm.HaveCode": "У меня есть ключ",
    "screen.RecoveryPassForm.Key": "Ключ",
    "screen.RecoveryPassForm.Login": "Логин:",
    "screen.RecoveryPassForm.New password": "Новый пароль",
    "screen.RecoveryPassForm.New password contains prohibited symbols": "Новый пароль содержит запрещенные символы",
    "screen.RecoveryPassForm.Send": "Восстановить",
    "screen.RecoveryPassForm.Title": "Восстановить пароль",
    "screen.registrationWizard.confirmPassword": "",
    "screen.registrationWizard.confirmPassword.watermark": "",
    "screen.registrationWizard.email": "",
    "screen.registrationWizard.email.watermark": "",
    "screen.registrationWizard.Country": "",
    "screen.registrationWizard.Password": "",
    "screen.registrationWizard.Balance": "",
    "screen.registrationWizard.firstName": "",
    "screen.registrationWizard.firstName.watermark": "",
    "screen.registrationWizard.lastName": "",
    "screen.registrationWizard.lastName.watermark": "",
    "screen.registrationWizard.login": "",
    "screen.registrationWizard.login.watermark": "",
    "screen.registrationWizard.password.watermark": "",
    "screen.registrationWizard.phone": "",
    "screen.registrationWizard.phoneNumber.watermark": "",
    "screen.registrationWizard.registrationStatus": "",
    "screen.registrationWizard.successMessage": "",
    "screen.registrationWizard.Header": "",
    "screen.registrationWizard.Register": "",
    "screen.registrationWizard.Cancel": "",
    "screen.renameScreen.cancel": "Отмена",
    "screen.renameScreen.illegalName": "Неправильное имя!",
    "screen.renameScreen.ok": "ОК",
    "screen.renameScreen.newList.header": "Имя списка",
    "screen.renameScreen.newList.placeholder": "Новый список",
    "screen.renameScreen.rename.header": "Переименовать",
    "screen.rename.alreadyExist.firstPart": "Имя ",
    "screen.rename.alreadyExist.secondPart": " уже существует.",
    "screen.remove.lastlist": "Должен существовать хотя бы один список",
    "screen.remove.title": "Удалить",
    "screen.remove.confirmText.firstPart": "Вы уверены, что хотите удалить ",
    "screen.remove.confirmText.secondPart": " список?",
    "screen.remove.confirmText.secondPart.templates": " шаблон?",
    "screen.ReportMessageTooltip.Account": "Счет",
    "screen.ReportMessageTooltip.CreatedAt": "создан в",
    "screen.ReportMessageTooltip.FilledAt": "заполнен в",
    "screen.ReportMessageTooltip.MarginCall": "Требование гарантийного взноса",
    "screen.ReportMessageTooltip.Market": "Рыночный",
    "screen.ReportMessageTooltip.ModifiedAt": "изменен в",
    "screen.ReportMessageTooltip.RemovedAt": "убран в",
    "screen.reports.actions_print": "",
    "screen.reports.address": "",
    "screen.reports.beginDatePicker.ButtonDropDown.ToolTip": "",
    "screen.reports.beginDatePicker.ToolTip": "",
    "screen.reports.brokerzip": "",
    "screen.reports.button_printer_setup": "",
    "screen.reports.byGroup": "",
    "screen.reports.byInstrumentGroup": "",
    "screen.reports.byInstruments": "",
    "screen.reports.byUser": "",
    "screen.reports.byUserGroup": "",
    "screen.reports.cell_data": "",
    "screen.reports.column.inner_text": "",
    "screen.reports.columns_maneger": "",
    "screen.reports.country": "",
    "screen.reports.csv": "",
    "screen.reports.customerzip": "",
    "screen.reports.dataCoverage": "",
    "screen.reports.errSaving": "",
    "screen.reports.excel": "",
    "screen.reports.exportToButton": "",
    "screen.reports.filter": "",
    "screen.reports.historyFor": "",
    "screen.reports.html": "",
    "screen.reports.info": "",
    "screen.reports.error": "",
    "screen.reports.Reports": "",
    "screen.reports.InstrumentAndGroupLookup.byGroupCombobox.ToolTip": "",
    "screen.reports.InstrumentAndGroupLookup.instrunentLookup.ToolTip": "",
    "screen.reports.license": "",
    "screen.reports.listViewEx.itemAll": "",
    "screen.reports.manager": "",
    "screen.reports.minimizeButton.Hide reports parameters": "",
    "screen.reports.minimizeButton.Show reports parameters": "",
    "screen.reports.name": "",
    "screen.reports.news.daily": "",
    "screen.reports.news.from": "",
    "screen.reports.news.hideACriterias": "",
    "screen.reports.news.invalidDateTime": "",
    "screen.reports.news.monthToDate": "",
    "screen.reports.news.range": "",
    "screen.reports.news.showACriterias": "",
    "screen.reports.news.sinceInception": "",
    "screen.reports.news.till": "",
    "screen.reports.news.yearToDate": "",
    "screen.reports.noData": "",
    "screen.reports.order": "",
    "screen.reports.param.Name": "",
    "screen.reports.paramOfReport": "",
    "screen.reports.pdf": "",
    "screen.reports.phone": "",
    "screen.reports.pos": "",
    "screen.reports.president": "",
    "screen.reports.print": "",
    "screen.reports.processingRequset": "",
    "screen.reports.rangeError": "",
    "screen.reports.report.button_printer_setup": "",
    "screen.reports.reportHeader.accnumber": "",
    "screen.reports.reportHeader.from": "",
    "screen.reports.reportHeader.Text": "",
    "screen.reports.reportHeaders.account": "",
    "screen.reports.reportHeaders.currancy": "",
    "screen.reports.reportHeaders.date": "",
    "screen.reports.reportHeaders.from": "",
    "screen.reports.reportHeaders.owner": "",
    "screen.reports.reportHeaders.to": "",
    "screen.reports.reportHeaderText": "",
    "screen.reports.reportParameters": "",
    "screen.reports.reports.Canceled": "",
    "screen.reports.reports.ExpingToCSV": "",
    "screen.reports.reports.ExpingToExcel": "",
    "screen.reports.reports.ExpingToHTML": "",
    "screen.reports.reports.ExpingToPDF": "",
    "screen.reports.reports.ExportingPrint": "",
    "screen.reports.reports.ExpPrint": "",
    "screen.reports.reports.ExpPrintErr": "",
    "screen.reports.reports.ExpToCSV": "",
    "screen.reports.reports.ExpToCSVErr": "",
    "screen.reports.reports.ExpToExcel": "",
    "screen.reports.reports.ExpToExcelErr": "",
    "screen.reports.reports.ExpToHTML": "",
    "screen.reports.reports.ExpToHTMLErr": "",
    "screen.reports.reports.ExpToPDF": "",
    "screen.reports.reports.ExpToPDFErr": "",
    "screen.reports.reports.getReport": "",
    "screen.reports.reports.in_separate_window": "",
    "screen.reports.reports.inCurrentWindow": "",
    "screen.reports.reports.info": "",
    "screen.reports.reports.inSeparateWindow": "",
    "screen.reports.reports.loading": "",
    "screen.reports.reports.noData": "",
    "screen.reports.reports.noItemAvailable": "",
    "screen.reports.reportType": "",
    "screen.reports.reportTypeCombobox.ToolTip": "",
    "screen.reports.save": "",
    "screen.reports.showSummary": "",
    "screen.reports.sinceInception": "",
    "screen.reports.summary": "",
    "screen.reports.table": "",
    "screen.reports.title": "",
    "screen.reports.to": "",
    "screen.reports.trades": "",
    "screen.reports.UserAndGroupLookup.byGroupCombobox.ToolTip": "",
    "screen.reports.UserAndGroupLookup.usersLookup.ToolTip": "",
    "screen.snapshot.header": "Снимок",
    "screen.snapshot.lastLabel": "Последняя",
    "screen.snapshot.bidLabel": "Бид",
    "screen.snapshot.askLabel": "Аск",
    "screen.snapshot.priceLabel": "Цена:",
    "screen.snapshot.sizeLabel": "Размер:",
    "screen.snapshot.changeLabel": "Изменение:",
    "screen.snapshot.highLabel": "Максимум:",
    "screen.snapshot.lowLabel": "Минимум:",
    "screen.snapshot.changePercentLabel": "Изменение, %:",
    "screen.snapshot.volumeLabel": "Объем:",
    "screen.snapshot.getSnapshotBtnText": "Получить снимок",
    "screen.snapshot.getSnapshotBtnText.minusOne.tt": "Получить снимок",
    "screen.snapshot.getSnapshotBtnText.Zero.tt": "Лимит запросов на снимок исчерпан",
    "screen.snapshot.getSnapshotBtnText.moreZero.tt": "Получить снимок данных в реальном времени",
    "screen.snapshot.closeBtnText": "Отмена",
    "screen.PanelSettingsScreen.applyButton": "Применить",
    "screen.TimeZoneSelector.title": "Выберите часовой пояс",
    "screen.UnlockTradingScreen.lockedLabel": "",
    "screen.UnlockTradingScreen.unlockButton": "",
    "Simple Moving Average": "",
    "SixMonth": "",
    "Smoothed Moving Average": "",
    "Standard Deviation": "",
    "statusBar.passwordpanel.DetalTicket.Text": "",
    "statusBar.passwordPanel.passwordBox.invalidPass": "Trading password is not valid.",
    "Stochastic": "",
    "Stochastic Slow": "",
    "Stop": "Стоп",
    "Stop Limit": "Стоп лимит",
    "Stop-loss": "Стоп лосс",
    "Suomi": "",
    "Swing Index": "",
    "Take-profit": "",
    "TerceraAccountLookup.AccountNotExist": "Счет не существует",
    "TerceraAccountLookup.IncorrectAccount": "Неверный счет",
    "TerceraAccountLookup.Linked accounts": "Связанные счета",
    "TerceraAccountLookup.Multy select": "Несколько",
    "TerceraAccountLookup.Select": "Выбрать",
    "TerceraAccountLookup.Select All": "Выбрать все",
    "TerceraInstrumentLookup.InstrTypeImg.tt": "Кликните чтобы открыть панель Информация по инструменту",
    "TerceraInstrumentLookup.DelayedIco.tt": "Задержка на {0} мин.",
    "textBoxWithValidating.ErrorText.NameContainsInvalidChar": "",
    "textBoxWithValidating.ErrorText.NameExist": "",
    "ThreeMonth": "",
    "Tr. stop": "Тр. стоп",
    "TradeDefaultManager.Set defaults": "Установить по умол.",
    "TradeDefaultManager.Types manager": "Менеджер типов",
    "TradeTool.Amount of order": "",
    "TradeTool.Cancel order": "",
    "TradeTool.ChangeToMarket": "Изменить тип ордера на рыночный",
    "TradeTool.Cancel SL": "",
    "TradeTool.Cancel TP": "",
    "TradeTool.Close position": "",
    "TradeTool.Drag to set SL order": "",
    "TradeTool.Drag to set TP order": "",
    "TradeTool.Order": "",
    "TradeTool.Position": "",
    "TradeTool.Position P/L": "",
    "TradeTool.Tick and P/L": "",
    "TradeTool.Trailing Stop": "",
    "TradeTool.View more actions": "",
    "TTO": "",
    "TypesManagerScreen.DefaultLotsSetup": "Настройка лот по умолчанию",
    "TypesManagerScreen.NumericLabel.Price increment (arrow), ticks": "Прирост цены (стрелка), тики",
    "TypesManagerScreen.NumericLabel.SL default offset, ticks": "СЛ смещение по умол., тики",
    "TypesManagerScreen.NumericLabel.TP default offset, ticks": "ТП смещение по умол., тики",
    "TypesManagerScreen.NumericLabel.User quantity coefficient": "Множитель минимального шага",
    "TypesManagerScreen.QuickTable.Column.Default lots": "Лоты по умолчанию",
    "TypesManagerScreen.QuickTable.Column.Enable": "Включить",
    "TypesManagerScreen.Types Manager": "Менеджер типов",
    "UserControl.TerceraNumeric.ValueGreaterMax": "",
    "UserControl.TerceraNumeric.ValueIsEmpty": "",
    "UserControl.TerceraNumeric.ValueLessMin": "",
    "UserControl.TerceraNumeric.ValueNotMultiple": "",
    "UserControl.TerceraNumeric.ValueNotNumber": "",
    "UserControl.TerceraSLTPNumeric.ChartOffsetWarning": "Обратите внимание, задается смещение от цены ордера",
    "Volume": "",
    "Volume Weighted Average Price": "Средневзвешенная цена объёма",
    "VWAP": "",
    "VWAP.DaysNumber": "Число дней",
    "VWAP.StartEndDayTime": "Время начала/окончания дня",
    "allowCreatePanelsFromPanel": "",
    "allowLinkSystem": "",
    "allowWorkspaceContextMenu": "",
    "allowWorkspaceEdit": "",
    "orders.confirm.all.question1": "",
    "orders.confirm.all.question2": "",
    "orders.confirm.all.title": "",
    "orders.confirm.cancel.question1": "",
    "positions.confirm.mutual.question1": "",
    "positions.confirm.mutual.question2": "",
    "Rows.firstPart": "",
    "Rows.secondPart": "",
    "general.messageBox.cancel.ToolTip": "",
    "property.Confirmations.ToolTip": "",
    "general.messageBox.ok.ToolTip": "",
    "property.Defaults.ToolTip": "",
    "property.View.ToolTip": "",
    "property.Warnings.ToolTip": "",
    "Throtling.Count.firstPart": "",
    "Throtling.Count.secondPart": "",
    "workspace.At first you should unlock workspace to rename it": "",
    "workspace.Clear": "Очистить",
    "workspace.clear_ask": "Очистить рабочее пространство?",
    "workspace.clear_wsp": "Очистить раб. пространство",
    "workspace.Close": "Закрыть",
    "workspace.close_wsp": "Закрыть рабочее пространство",
    "workspace.DefaultNameSpace": "Новое",
    "workspace.errorSavingFile": "",
    "workspace.information": "Информация",
    "workspace.saved.Message": "",
    "workspace.Lock": "",
    "workspace.Lock.Message": "",
    "workspace.menu.Clone": "Клонировать",
    "workspace.menu.Close": "",
    "workspace.menu.CloseAllButThis": "Закрыть все, кроме этого",
    "workspace.menu.Lock": "Блокировать",
    "workspace.newWorkspaceName": "Введите имя рабочего пространства",
    "workspace.numberOfWorkspace": "Количество открытых рабочих пространств достигло максимума",
    "workspace.Rename": "Переименовать",
    "workspace.Unlock": "",
    "workspace.Unlock.Message": "",
    "InstrumentDetailsPanel.ISIN": "ISIN",
    "InstrumentDetailsPanel.Industry": "Индустрия",
    "InstrumentDetailsPanel.Industry.descr": "Индустрия",
    "InstrumentDetailsPanel.Sector": "Сектор",
    "InstrumentDetailsPanel.Sector.descr": "Сектор",
    "Order.OrderStatus.STATUS_UNKNOWN": "Unknown",
    "Order.OrderStatus.STATUS_PENDING_NEW": "Ожидает открытия",
    "Order.OrderStatus.STATUS_NEW": "Создан",
    "Order.OrderStatus.STATUS_ACCEPTED": "Принят",
    "Order.OrderStatus.STATUS_PENDING_EXECUTION": "Ожидает исполнения",
    "Order.OrderStatus.STATUS_REPLACED": "Modified",
    "Order.OrderStatus.STATUS_PENDING_CANCEL": "Ожидает отмены",
    "Order.OrderStatus.STATUS_PART_FILLED": "Частично заполнен",
    "Order.OrderStatus.STATUS_FILLED": "Заполнен",
    "Order.OrderStatus.STATUS_PENDING_REPLACE": "Ожидает модификации",
    "Order.OrderStatus.STATUS_WAITING_MARKET": "Ожидание открытия рынка",
    "Order.OrderStatus.STATUS_OFF_MARKET": "Вне рынка",
    "Order.OrderStatus.STATUS_UNPLACED": "Деактивирован",
    "IndicatorGroup.Moving Average": "Moving Average",
    "MD": "MD",
    "REGRESSION": "REGRESSION",
    "Regression": "Regression",
    "SMMA": "SMMA",
    "PPMA": "PPMA",
    "SMA": "SMA",
    "EMA": "EMA",
    "IndicatorGroup.Oscillators": "Oscillators",
    "MACD": "MACD",
    "BB": "BB",
    "BBF": "BBF",
    "IndicatorGroup.Volume": "Volume",
    "RSI": "RSI",
    "PO": "PO",
    "CCI": "CCI",
    "Aroon": "Aroon",
    "KRI": "KRI",
    "Kairi Relative Index": "Kairi Relative Index",
    "PPO": "PPO",
    "Percentage Price Oscillator": "Percentage Price Oscillator",
    "RLW": "RLW",
    "%R Larry Williams": "%R Larry Williams",
    "ROC": "ROC",
    "Rate of Change": "Rate of Change",
    "QStick": "QStick",
    "TSI": "TSI",
    "True Strength Index": "True Strength Index",
    "Pivot point": "Pivot point",
    "SI": "SI",
    "ALLIGATOR": "ALLIGATOR",
    "MMA": "MMA",
    "IndicatorGroup.Trend": "Trend",
    "STOCHASTIC": "STOCHASTIC",
    "OBV": "OBV",
    "IndicatorGroup.Volatility": "Volatility",
    "ATR": "ATR",
    "CMO": "CMO",
    "SD": "SD",
    "SAR": "SAR",
    "ADX": "ADX",
    "IndicatorGroup.Channels": "Channels",
    "KELTNER": "KELTNER",
    "CHANNEL": "CHANNEL",
    "MAE": "MAE",
    "LWMA": "LWMA",
    "MAS3": "MAS3",
    "Didi": "Didi",
    "HiloEscandina": "HiloEscandina",
    "StopATR": "StopATR",
    "Fractals3": "Fractals3",
    "Fractals5": "Fractals5",
    "Kaufman adaptive moving average": "Kaufman adaptive moving average",
    "Multi EMA": "Multi EMA",
    "Self-Adjusting Alpha with Fractals Energy": "RSI Self-Adjusting Alpha with Fractals Energy",
    "alert.specifyOrder.Error": "Укажите параметры ордера, чтобы создать алерт с Action = Place order  +notify.",
    "alert.specifyAlertType.Error": "Для инструментов с типом Форекс, нельзя чтобы \"Тип алерта = Объем\". Укажите другой параметр",
    "alert.specifyExpirationTime.Error": "Время жизни алерта меньше текущего времени",
    "alert.specifyAlertPanelBtn.Error": "Достигнуто максимальное количество алертов для пользователя.",
    "AdditionalProperty.Unsubscribe": "Отписка",
    "screen.products.ButtonSubscribe": "Подписаться",
    "screen.products.ButtonSubscribed": "Вы подписаны",
    "screen.products.ButtonUnsubscribe": "Отписаться",
    "screen.products.Products": "Подписки",
    "screen.products.Categories": "Подписки",
    "screen.products.Categories.NoData": "Нет подписок",
    "screen.products.toolbar.filterCB.All": "Все",
    "screen.products.toolbar.filterCB.Subscribed": "Подписанные",
    "screen.products.toolbar.filterCB.AvailableForSubscription": "Доступные для подписки",
    "screen.products.toolbar.filterCB.Processing": "В обработке",
    "screen.products.SubscribeRequestConfirmScreen.header": "Подписка",
    "screen.products.SubscribeRequestConfirmScreen.text": "Вы подаете заявку на подписку.\nОбработка запроса может занять некоторое время, если потребуется проверка документов.\nТекущий статус вашего запроса можно проверить на вкладке «Запросы».\nЕсли запрос обработан успешно, кнопка на карточке изменится на Подписан",
    "screen.products.Request": "Запросы",
    "screen.products.Request.ToolTip": "Содержит исходящие запросы на подписку и отписку",
    "screen.documets.Header": "Подписать документы",
    "screen.documets.Button.ToolTip": "Не заполнены обязательные поля",
    "screen.products.ButtonUnsubscribe.Click": "Как только запрос будет обработан - вы будете отписаны немедленно, не дожидаясь окончания периода, за который была снята комиссия.",
    "screen.products.CancelSubscribeRequestButton.text": "Отмена запроса",
    "screen.products.CancelSubscribeRequestButton.tt": "Запрос <date/time> <requestType> <productName> обрабатывается. Нажмите кнопку, чтобы отменить запрос",
    "panel.Products.Unsubscribe.PopupMessage": "Вы подаете заявку на отказ от подписки.\nОбработка запроса может занять некоторое время.\nВо время обработки подписка остается активной, и вы можете использовать ее преимущества.\nТекущий статус вашего запроса можно проверить на вкладке «Запросы».\nВы хотите продолжить?",
    "panel.product.Comment": "Комментарий",
    "panel.product.ProductStatus.Value.None": "",
    "panel.product.ProductStatus.Value.Processing": "Обрабатывается",
    "panel.product.ProductStatus.Value.Approved": "",
    "panel.product.ProductStatus.Value.Rejected": "",
    "panel.product.ProductStatus.Value.Canceled": "",
    "InstrumentDetailsPanel.OrderPerLot.descr": "Комиссия, уплачиваемая за каждый проторгованный лот ордера",
    "InstrumentDetailsPanel.OrderPerLot": "За лот ордера",
    "InstrumentDetailsPanel.OrderPerLotBUY.descr": "Комиссия, уплачиваемая за каждый проторгованный лот ордера (Покупка)",
    "InstrumentDetailsPanel.OrderPerLotBUY": "За лот ордера (Покупка)",
    "InstrumentDetailsPanel.OrderPerLotCLOSE.descr": "Комиссия, подсчитанная на основе объема каждой сделки (Закрытие)",
    "InstrumentDetailsPanel.OrderPerLotCLOSE": "За лот ордера (Закрытие)",
    "InstrumentDetailsPanel.OrderPerLotCLOSEBUY.descr": "Комиссия, уплачиваемая за каждый проторгованный лот ордера (Закр. Покупка)",
    "InstrumentDetailsPanel.OrderPerLotCLOSEBUY": "За лот ордера (Закр. Покупка)",
    "InstrumentDetailsPanel.OrderPerLotCLOSESELL.descr": "Комиссия, уплачиваемая за каждый проторгованный лот ордера (Закр. Продажа)",
    "InstrumentDetailsPanel.OrderPerLotCLOSESELL": "За лот ордера (Закр. Продажа)",
    "InstrumentDetailsPanel.OrderPerLotOPEN.descr": "Комиссия, подсчитанная на основе объема каждой сделки (Открытие)",
    "InstrumentDetailsPanel.OrderPerLotOPEN": "За лот ордера (Открытие)",
    "InstrumentDetailsPanel.OrderPerLotOPENBUY.descr": "Комиссия, уплачиваемая за каждый проторгованный лот ордера (Откр. Покупка)",
    "InstrumentDetailsPanel.OrderPerLotOPENBUY": "За лот ордера (Откр. Покупка)",
    "InstrumentDetailsPanel.OrderPerLotOPENSELL.descr": "Комиссия, уплачиваемая за каждый проторгованный лот ордера (Откр. Продажа)",
    "InstrumentDetailsPanel.OrderPerLotOPENSELL": "За лот ордера (Откр. Продажа)",
    "InstrumentDetailsPanel.OrderPerLotOPENSHORT.descr": "Комиссия, уплачиваемая за каждый проторгованный лот ордера (Откр. Короткая)",
    "InstrumentDetailsPanel.OrderPerLotOPENSHORT": "За лот ордера (Откр. Короткая)",
    "InstrumentDetailsPanel.OrderPerLotSELL.descr": "Комиссия, уплачиваемая за каждый проторгованный лот ордера (Продажа)",
    "InstrumentDetailsPanel.OrderPerLotSELL": "За лот ордера (Продажа)",
    "InstrumentDetailsPanel.OrderPerLotSHORT": "За лот ордера (Короткая)",
    "InstrumentDetailsPanel.OrderVolume.descr": "Комиссия, уплачеваемая за каждую часть объема ордера, которая была заполнена",
    "InstrumentDetailsPanel.OrderVolume": "Объем ордера",
    "InstrumentDetailsPanel.PerFill.descr": "Комиссия, уплачиваемая за каждое заполнение ордера",
    "InstrumentDetailsPanel.PerFill": "За заполнение",
    "InstrumentDetailsPanel.PerLot.descr": "Комиссия, уплачиваемая за каждый проторгованный лот",
    "InstrumentDetailsPanel.PerLot": "За лот",
    "InstrumentDetailsPanel.PerLotBUY.descr": "Комиссия, уплачиваемая за каждый проторгованный лот (Покупка)",
    "InstrumentDetailsPanel.PerLotBUY": "За лот (Покупка)",
    "InstrumentDetailsPanel.PerLotCLOSE.descr": "Комиссия рассчитывается на основе объема каждой сделки (Закрытие)",
    "InstrumentDetailsPanel.PerLotCLOSE": "За лот (Закр.)",
    "InstrumentDetailsPanel.PerLotCLOSEBUY.descr": "Комиссия, уплачиваемая за каждый проторгованный лот (Закрытие Покупка)",
    "InstrumentDetailsPanel.PerLotCLOSEBUY": "За лот (Закр. Покупка)",
    "InstrumentDetailsPanel.PerLotCLOSESELL.descr": "Комиссия, уплачиваемая за каждый проторгованный лот (Закрытие Продажа)",
    "InstrumentDetailsPanel.PerLotCLOSESELL": "За лот (Закр. Продажа)",
    "InstrumentDetailsPanel.PerLotOPEN.descr": "Комиссия рассчитывается на основе объема каждой сделки (Открытие)",
    "InstrumentDetailsPanel.PerLotOPEN": "За лот (Откр.)",
    "InstrumentDetailsPanel.PerLotOPENBUY.descr": "Комиссия, уплачиваемая за каждый проторгованный лот (Открытие Покупка)",
    "InstrumentDetailsPanel.PerLotOPENBUY": "За лот (Откр. Покупка)",
    "InstrumentDetailsPanel.PerLotOPENSELL.descr": "Комиссия, уплачиваемая за каждый проторгованный лот (Открытие Продажа)",
    "InstrumentDetailsPanel.PerLotOPENSELL": "За лот (Откр. Продажа)",
    "InstrumentDetailsPanel.PerLotOPENSHORT.descr": "Комиссия, уплачиваемая за каждый проторгованный лот (Открытие Короткая)",
    "InstrumentDetailsPanel.PerLotOPENSHORT": "За лот (Откр. Короткая)",
    "InstrumentDetailsPanel.PerLotSELL.descr": "Комиссия, уплачиваемая за каждый проторгованный лот (Продажа)",
    "InstrumentDetailsPanel.PerLotSELL": "За лот (Продажа)",
    "InstrumentDetailsPanel.PerLotSHORT.descr": "Комиссия, уплачиваемая за каждый проторгованный лот (Короткая)",
    "InstrumentDetailsPanel.PerLotSHORT": "За лот (Короткая)",
    "InstrumentDetailsPanel.PerOrder": "За ордер",
    "InstrumentDetailsPanel.PerOrderVolume.descr": "Комиссия, уплачеваемая за каждую часть объема ордера, которая была заполнена",
    "InstrumentDetailsPanel.PerOrderVolume": "Объем ордера, %",
    "InstrumentDetailsPanel.PerOrderVolumeBUY.descr": "Комиссия, уплачеваемая за каждую часть объема ордера, которая была заполнена (Покупка), %",
    "InstrumentDetailsPanel.PerOrderVolumeBUY": "Объем ордера (Покупка), %",
    "InstrumentDetailsPanel.PerOrderVolumeCLOSE.descr": "Комиссия, уплачеваемая за каждую часть объема ордера, которая была заполнена (Закрытие), %",
    "InstrumentDetailsPanel.PerOrderVolumeCLOSE": "Объем ордера (Закр.), %",
    "InstrumentDetailsPanel.PerOrderVolumeCLOSEBUY.descr": "Комиссия, уплачеваемая за каждую часть объема ордера, которая была заполнена (Закрытие Покупка), %",
    "InstrumentDetailsPanel.PerOrderVolumeCLOSEBUY": "Объем ордера (Закр. Покупка), %",
    "InstrumentDetailsPanel.PerOrderVolumeCLOSESELL.descr": "Комиссия, уплачеваемая за каждую часть объема ордера, которая была заполнена (Закрытие Продажа), %",
    "InstrumentDetailsPanel.PerOrderVolumeCLOSESELL": "Объем ордера (Закр. Продажа), %",
    "InstrumentDetailsPanel.PerOrderVolumeOPEN.descr": "Комиссия, уплачеваемая за каждую часть объема ордера, которая была заполнена (Открытие), %",
    "InstrumentDetailsPanel.PerOrderVolumeOPEN": "Объем ордера (Откр.), %",
    "InstrumentDetailsPanel.PerOrderVolumeOPENBUY.descr": "Комиссия, уплачеваемая за каждую часть объема ордера, которая была заполнена (Открытие Покупка), %",
    "InstrumentDetailsPanel.PerOrderVolumeOPENBUY": "Объем ордера (Откр. Покупка), %",
    "InstrumentDetailsPanel.PerOrderVolumeOPENSELL.descr": "Комиссия, уплачеваемая за каждую часть объема ордера, которая была заполнена (Открытие Продажа), %",
    "InstrumentDetailsPanel.PerOrderVolumeOPENSELL": "Объем ордера (Откр. Продажа), %",
    "InstrumentDetailsPanel.PerOrderVolumeOPENSHORT.descr": "Комиссия, уплачеваемая за каждую часть объема ордера, которая была заполнена (Открытие Короткая), %",
    "InstrumentDetailsPanel.PerOrderVolumeOPENSHORT": "Объем ордера (Откр. Короткая), %",
    "InstrumentDetailsPanel.PerOrderVolumeSELL.descr": "Комиссия, уплачеваемая за каждую часть объема ордера, которая была заполнена (Продажа), %",
    "InstrumentDetailsPanel.PerOrderVolumeSELL": "Объем ордера (Продажа), %",
    "InstrumentDetailsPanel.PerOrderVolumeSHORT.descr": "Комиссия, уплачеваемая за каждую часть объема ордера, которая была заполнена (Короткая), %",
    "InstrumentDetailsPanel.PerOrderVolumeSHORT": "Объем ордера (Короткая), %",
    "InstrumentDetailsPanel.PerPhoneTransaction.descr": "Комиссия, уплачиваемая за каждую транзакцию, совершенную Телефонным дилером",
    "InstrumentDetailsPanel.PerPhoneTransaction": "За транзакцию по тел.",
    "InstrumentDetailsPanel.PerTransaction.descr": "Комиссия за каждую транзакцию. Транзакции: размещение ордера, отмена ордера, и изменение ордера",
    "InstrumentDetailsPanel.PerTransaction": "За транзакцию",
    "InstrumentDetailsPanel.PerVolume.descr": "Комиссия рассчитывается на основе объема каждой сделки, %",
    "InstrumentDetailsPanel.PerVolume": "Объем, %",
    "InstrumentDetailsPanel.PerVolumeBUY.descr": "Комиссия рассчитывается на основе объема каждой сделки (Покупка), %",
    "InstrumentDetailsPanel.PerVolumeBUY": "Объем (Покупка), %",
    "InstrumentDetailsPanel.PerVolumeCLOSE.descr": "Комиссия рассчитывается на основе объема каждой сделкиe (Закрытие), %",
    "InstrumentDetailsPanel.PerVolumeCLOSE": "Объем (Закр.), %",
    "InstrumentDetailsPanel.PerVolumeCLOSEBUY.descr": "Комиссия рассчитывается на основе объема каждой сделки (Закрытие Покупка), %",
    "InstrumentDetailsPanel.PerVolumeCLOSEBUY": "Объем (Закр. Покупка), %",
    "InstrumentDetailsPanel.PerVolumeCLOSESELL.descr": "Комиссия рассчитывается на основе объема каждой сделки (Закрытие Продажа), %",
    "InstrumentDetailsPanel.PerVolumeCLOSESELL": "Объем (Закр. Продажа), %",
    "InstrumentDetailsPanel.PerVolumeOPEN.descr": "Комиссия рассчитывается на основе объема каждой сделки (Открытие), %",
    "InstrumentDetailsPanel.PerVolumeOPEN": "Объем (Откр.), %",
    "InstrumentDetailsPanel.PerVolumeOPENBUY.descr": "Комиссия рассчитывается на основе объема каждой сделки (Открытие Покупка), %",
    "InstrumentDetailsPanel.PerVolumeOPENBUY": "Объем (Откр. Покупка), %",
    "InstrumentDetailsPanel.PerVolumeOPENSELL.descr": "Комиссия рассчитывается на основе объема каждой сделки (Открытие Продажа), %",
    "InstrumentDetailsPanel.PerVolumeOPENSELL": "Объем (Откр. Продажа), %",
    "InstrumentDetailsPanel.PerVolumeOPENSHORT.descr": "Комиссия рассчитывается на основе объема каждой сделки (Открытие Короткая), %",
    "InstrumentDetailsPanel.PerVolumeOPENSHORT": "Объем (Откр. Короткая), %",
    "InstrumentDetailsPanel.PerVolumeSELL.descr": "Комиссия рассчитывается на основе объема каждой сделки (Продажа), %",
    "InstrumentDetailsPanel.PerVolumeSELL": "Объем (Продажа), %",
    "InstrumentDetailsPanel.PerVolumeSHORT.descr": "Комиссия рассчитывается на основе объема каждой сделки (Короткая), %",
    "InstrumentDetailsPanel.PerVolumeSHORT": "Объем (Короткая), %",
    "dynProperty.inactive.tooltip": "Нельзя изменить состояние настройки без подключения!",
    "system.ChangePassLow": "Новый пароль должен содержать не менее {0} символов и не должен содержать символа (&). Пожалуйста, попробуйте еще раз.",
    "system.ChangePassBase": "Неверный пароль: новый пароль должен содержать не менее {0} символов, 1 символ верхнего регистра, 1 символ нижнего регистра, 1 число и не должен содержать символа (&). Пожалуйста, попробуйте еще раз.",
    "system.ChangePassHigh": "Новый пароль должен содержать не менее {0} символов, 1 символ верхнего регистра, 1 символ нижнего регистра, 1 число, 1 специальный символ (например, @ # S%) и не должен содержать символа (&). Пожалуйста, попробуйте еще раз.",
    "screen.changepass.changed.login": "Пароль успешно изменен. Пожалуйста, можете войти в приложение.",
    "InstrumentDetailsPanel.ETB": "Легкость заимствования(ETB)",
    "panel.detail.ETB": "Легк. заимствования(ETB)",
    "panel.accounts.MaxRelativeDrawDownLevel": "Максимальный уровень относительной просадки",
    "panel.accounts.MaxRelativeDrawDownLevel.descr": "Показывает минимальное допустимое значение расчетного баланса счета (рассчитанное относительно текущей величины баланса)",
    "panel.watchlist.NextFundingSettlement": "До расчета",
    "property.IsCountdownVisible": "Осталось",
    "property.IsFundingVisible": "Ставка",
    "panel.watchlist.CurrentFundingRate.descr": "Положительное значение ставки означает, что лонги должны платить шортам, а отрицательное значение указывает, что шорты должны платить лонгам",
    "panel.watchlist.CurrentFundingRate": "Ставка",
    "chart.infoWindow.Items.Funding": "Ставка:",
    "chart.infoWindow.Items.Countdown": "Осталось:",
    "panel.detail.Countdown": "Осталось",
    "panel.detail.Funding": "Ставка",

    "Web.mobile.active.WorkingOrders": "Активные заявки",
    "Web.mobile.active.WorkingOrders.EmptyPlaceholder": "Нет заявок",
    "Web.mobile.active.FilledOrders": "Исполненные заявки",
    "Web.mobile.active.HistoryOrders": "История",
    "Web.mobile.active.Positions": "Позиции",
    "Web.mobile.active.Positions.EmptyPlaceholder": "Нет позиций",
    "Web.mobile.active.Assets": "Активы",
    "Web.mobile.active.Orders": "Заявки",
    "Web.mobile.active.More": "Ещё",
    "Web.mobile.active.MamSummary": "MAM-сводка",
    "Web.mobile.active.OptionChain": "Цепь опционов",
    "Web.mobile.active.Alerts.NoAlerts": "Нет оповещений",
    "Web.mobile.active.EventLog.NoEvents": "Нет событий",
    "Web.mobile.active.EventLog.DateTime": "Дата и время",
    "Web.mobile.active.MamSummary.Column.OperationType": "Тип операции",
    "Web.mobile.active.MamSummary.Column.Amount": "Сумма",
    "Web.mobile.active.More.Header": "Ещё",
    "Web.mobile.active.Settings.Defaults": "Настройки по умолчанию",
    "Web.mobile.active.Settings.QuantityInLots": "Количество в лотах",
    "Web.mobile.active.Settings.SetSlTpInOffset": "Установить SL/TP с отклонением",
    "Web.mobile.active.Settings.OrderSending": "Отправка заявки",
    "Web.mobile.active.Settings.OrderModifying": "Модификация заявки",
    "Web.mobile.active.Settings.OrderExecuting": "Исполнение заявки",
    "Web.mobile.active.Settings.OrderCancelling": "Отмена заявки",
    "Web.mobile.active.Settings.PositionModifying": "Изменение позиции",
    "Web.mobile.active.Table.Total.Profit": "Общая прибыль",
    "Web.mobile.active.NewTrades.PnL": "Прибыль/Убыток",
    "Web.mobile.active.OrderBook.ExecutionType": "Тип исполнения",
    "Web.mobile.active.Assets.EmptyPlaceholder": "Нет активов",
    "Web.mobile.active.Assets.DefSymbol": "Символ по умолчанию",
    "Web.mobile.active.Assets.SODQuantity": "Кол-во на начало дня",
    "Web.mobile.active.Assets.CurrentValueForSale": "Текущая стоимость для продажи",
    "Web.mobile.active.Positions.Profit": "P/L",
    "Web.mobile.active.AccountDetails.LinkingTooltip": "Эта функция фильтрует ваши позиции и заявки по счету. Переключение счета синхронизируется во всех панелях.",
    "Web.mobile.active.Watchlist.EmptyPlaceholder": "Нет символов",
    "Web.mobile.active.Watchlist.ListNamePlaceholder": "Введите название здесь",
    "Web.mobile.active.Watchlist.Added": "Добавлено в список наблюдения",
    "Web.mobile.active.Watchlist.Removed": "Удалено из списка наблюдения",
    "Web.mobile.active.Watchlist.MaxReached": "Достигнут максимум",
    "Web.mobile.active.ExchangesFilter.Filter": "Фильтр",
    "Web.mobile.active.OrderEntry.Send": "Отправить заявку",
    "Web.mobile.active.OrderEntry.Price": "Цена",
    "Web.mobile.active.OrderEntry.TrStop": "Следящий стоп",
    "Web.mobile.active.OrderEntry.SlPrice": "Цена стоп-лосса",
    "Web.mobile.active.OrderEntry.SlOffset": "Отклонение стоп-лосса",
    "Web.mobile.active.OrderEntry.TrSlOffset": "Отклонение трейлинг-стопа",
    "Web.mobile.active.OrderEntry.SllPrice": "Цена лимита стоп-лосса",
    "Web.mobile.active.OrderEntry.SllOffset": "Отклонение лимита стоп-лосса",
    "Web.mobile.active.OrderEntry.TpPrice": "Цена тейк-профита",
    "Web.mobile.active.OrderEntry.TpOffset": "Отклонение тейк-профита",
    "Web.mobile.active.Login.ExploreTheApp": "Изучить приложение",
    "Web.mobile.active.InstrumentInfo.Price": "Цена",
    "Web.mobile.active.InstrumentInfo.BreakEven": "Брейк-ивен",
    "Web.mobile.active.Asset.SellExchange": "Биржа",
    "Web.mobile.active.Asset.StartDayQtyMargin": "Кол-во на начало дня для MA",
    "Web.mobile.active.Asset.LiquidityRate": "Ставка ликвидности, %",
    "Web.mobile.active.Asset.TodayTradedQty": "Кол-во интрадей",
    "Web.mobile.active.Asset.AvailableQtySell": "Доступное кол-во для продажи",
    "Web.mobile.active.FilledOrder.TradeVolume": "Объем сделки",
    "Web.mobile.active.FilledOrder.Date": "Дата",
    "Web.mobile.active.FilledOrder.GrossPnL": "Валовая П/У",
    "Web.mobile.active.HistoryOrder.Date": "Дата",
    "Web.mobile.active.MamSummary.EmptyPlaceholder": "У вас нет истории счета за этот период",
    "Web.mobile.active.MamSummary.Account": "Счет",
    "Web.mobile.active.MamSummary.OperationId": "ID операции",
    "Web.mobile.active.MamSummary.InstrumentName": "Наименование инструмента",
    "Web.mobile.active.MamSummary.OpenPrice": "Цена открытия",
    "Web.mobile.active.MamSummary.ClosePrice": "Цена закрытия",
    "Web.mobile.active.MamSummary.Side": "Сторона",
    "Web.mobile.active.MamSummary.Quantity": "Количество",
    "Web.mobile.active.MamSummary.Buy": "Покупка",
    "Web.mobile.active.MamSummary.Sell": "Продажа",
    "Web.mobile.active.Position.StopLoss": "Стоп-лосс",
    "Web.mobile.active.Position.TakeProfit": "Тейк-профит",
    "Web.mobile.active.Position.Date": "Дата",
    "Web.mobile.active.Order.StopLoss": "Стоп-лосс",
    "Web.mobile.active.Order.TrStopOffset": "Trailing SL offset",
    "Web.mobile.active.Order.TrStopLoss": "SL Trailing stop",
    "Web.mobile.active.Order.TakeProfit": "Тейк-профит",
    "Web.mobile.active.Order.QuantityFilled": "Qty. filled",
    "Web.mobile.active.Search": "Поиск",
    "Web.mobile.active.CalendarPeriod.Today": "Сегодня",
    "Web.mobile.active.CalendarPeriod.LastWeek": "Прошлая неделя",
    "Web.mobile.active.CalendarPeriod.Last1M": "Последний месяц",
    "Web.mobile.active.CalendarPeriod.Last3M": "Последние 3 месяца",
    "Web.mobile.active.CalendarPeriod.Last6M": "Последние 6 месяцев",
    "Web.mobile.active.InstrumentDetails.6.SessionInfo": "Расписание",
    "Web.mobile.active.OrderModify.Confirmation.Title.Modify": "Изменение заявки",
    "Web.mobile.active.OrderModify.Confirmation.Title.Cancel": "Отмена заявки",
    "Web.mobile.active.OrderModify.Confirmation.Title.ExecuteAtMarket": "Выполнить заявку на рынке?",
    "Web.mobile.active.OrderModify.Error.SLSmallerMin": "Стоп-лосс меньше минимально возможной величины",
    "Web.mobile.active.OrderModify.Error.SLGreaterMax": "Стоп-лосс больше максимально возможной величины",
    "Web.mobile.active.OrderModify.Error.TPSmallerMin": "Тейк-профит меньше минимально возможной величины",
    "Web.mobile.active.OrderModify.Error.TPGreaterMax": "Тейк-профит больше максимально возможной величины",
    "Web.mobile.active.PositionModify.Confirmation.Title": "Изменение позиции",
    "Web.mobile.active.PositionClose.Confirmation.Lots": "лот(ы)",
    "Web.mobile.active.Lists": "Списки",
    "Web.mobile.active.AccountDetails.Groups.1.AccountSummary": "Сводка по счету",
    "Web.mobile.active.AccountDetails.Groups.4.TodayResult": "Результат за сегодня",
    "Web.mobile.active.AccountDetails.MarginUsedPercent": "Требуемый начальный маржинальный залог %",
    "Web.mobile.active.AccountDetails.OptionPremiumReq": "Требуется премия по опционам",
    "Web.mobile.active.AccountDetails.OpenGrossPL": "Открытая валовая прибыль/убыток",
    "Web.mobile.active.AccountDetails.OpenPostionsNumber": "Открытые позиции",
    "Web.mobile.active.AccountDetails.OpenOrdersNumber": "Рабочие ордера",
    "Web.mobile.active.AccountDetails.TodaysNetProfit": "Чистая прибыль за сегодня",
    "Web.mobile.active.Chart.IndicatorsMenu.AddedIndicators": "Добавленные индикаторы",
    "Web.mobile.active.Chart.Menu.ChartSyle": "Стиль графика",
    "Web.mobile.active.Chart.Menu.Settings": "Настройки",
    "Web.mobile.active.Chart.NoDataAvailable": "Нет данных",
    "Web.mobile.active.TrailingStopLoss": "Tr.",
    "Web.mobile.active.Position.GrossPnL": "Валовая П/У",
    "Web.mobile.active.Table.Total.GrossPnL": "Total Gross P/L",
}