import { DataCache } from '../../../Commons/DataCache';
import { MarginInfoParameters } from '../../../Commons/UtilsClasses/MarginInfo/MarginInfoParameters';
import { Account } from '../../../Commons/cache/Account';
import { Greeks } from '../../../Commons/cache/OptionMaster/OptionTrader/Greeks';
import { GreeksFormatter } from '../../../Commons/cache/OptionMaster/OptionTrader/GreeksFormatter';
import { type OptionTrader } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionTrader';
import { Resources } from '../../../Commons/properties/Resources';
import { AccountFeature } from '../../../Utils/Account/AccountFeature';
import { type IMarginResponse } from '../../../Utils/Interfaces/Response/IMarginResponse';
import { OperationType } from '../../../Utils/Trading/OperationType';
import { InfoControl, InfoData, InfoDataItem } from '../../elements/InfoControl';

enum GroupType {
    CurrentPortfolio = 0,
    PaperPositions = 1,
    Greeks = 2
}
enum ItemType {
    AvailableFunds = 0,
    InitialRequired = 1,
    MarginAvailable = 2,
    MaintenanceRequired = 3,
    Delta = 4,
    Gamma = 5,
    Vega = 6,
    Theta = 7
}

export class OptionPortfolioControl extends InfoControl {
    private readonly DEFATULT_STR: string = '---';

    private _isRequestMargin: boolean = true;
    private _serverMargin: IMarginResponse | null | undefined;
    private _optionTrader: OptionTrader;
    private _timer500ms: NodeJS.Timeout;
    private _timer30s: NodeJS.Timeout;

    constructor () {
        super();
        this.updateDataAsync = this.updateDataAsync.bind(this);
        this.onTimer500msTick = this.onTimer500msTick.bind(this);
        this.onTimer30sTick = this.onTimer30sTick.bind(this);
        this.updateDataWithRequest = this.updateDataWithRequest.bind(this);
        this.updateDataWithoutRequest = this.updateDataWithoutRequest.bind(this);
    }

    public override getType (): string { return 'OptionPortfolioControl'; }
    public override oninit (): void {
        super.oninit();
        const map = new Map<GroupType, ItemType[]>([
            [GroupType.CurrentPortfolio, [ItemType.AvailableFunds, ItemType.InitialRequired, ItemType.MarginAvailable, ItemType.MaintenanceRequired]],
            [GroupType.PaperPositions, [ItemType.AvailableFunds, ItemType.InitialRequired, ItemType.MarginAvailable, ItemType.MaintenanceRequired]],
            [GroupType.Greeks, [ItemType.Delta, ItemType.Gamma, ItemType.Vega, ItemType.Theta]]
        ]);
        const data: InfoData[] = [];
        map.forEach((items: ItemType[], group: GroupType) => {
            data.push(new InfoData(group.toString(), this.getGroupTitle(group), this.initializeItems(items)));
        });
        void super.set('data', data);
    }

    public override oncomplete (): void {
        super.observe('visible', this.onVisibleChanged);
    }

    public override onteardown (): void {
        this._optionTrader.unsubscribeOnAccountChanged(this.updateDataWithRequest);
        this._optionTrader.unsubscribeOnInstrumentChanged(this.updateDataWithRequest);
        this._optionTrader.unsubscribeOnClearPaperPositions(this.updateDataWithRequest);
        this._optionTrader.unsubscribeOnCreatePaperPosition(this.updateDataWithRequest);
        this._optionTrader.unsubscribeOnRemovePaperPosition(this.updateDataWithRequest);
        this._optionTrader.unsubscribeOnUpdatePaperPosition(this.updateDataWithRequest);
        this._optionTrader.unsubscribeOnChangeInstrumentForPaperPosition(this.updateDataWithoutRequest);
        clearInterval(this._timer500ms);
        clearInterval(this._timer30s);
        super.onteardown();
    }

    public setOptionTrader (optionTrader: OptionTrader): void {
        this._optionTrader = optionTrader;
        this._optionTrader.subscribeOnAccountChanged(this.updateDataWithRequest);
        this._optionTrader.subscribeOnInstrumentChanged(this.updateDataWithRequest);
        this._optionTrader.subscribeOnClearPaperPositions(this.updateDataWithRequest);
        this._optionTrader.subscribeOnCreatePaperPosition(this.updateDataWithRequest);
        this._optionTrader.subscribeOnRemovePaperPosition(this.updateDataWithRequest);
        this._optionTrader.subscribeOnUpdatePaperPosition(this.updateDataWithRequest);
        this._optionTrader.subscribeOnChangeInstrumentForPaperPosition(this.updateDataWithoutRequest);
        this._timer500ms = setInterval(this.onTimer500msTick, 500);
        this._timer30s = setInterval(this.onTimer30sTick, 30 * 1000);
    }

    private updateDataWithRequest (): void {
        this._isRequestMargin = true;
        void this.updateDataAsync();
    }

    private updateDataWithoutRequest (): void {
        void this.updateDataAsync();
    }

    private async updateDataAsync (): Promise<void> {
        const isVisible: boolean = await super.get('visible');
        if (!isVisible) {
            return;
        }
        await this.updateMarginAsync();
        await this.updateGreeksDataAsync();
        await super.update();
    }

    private async updateMarginAsync (): Promise<void> {
        if (this._isRequestMargin) {
            this._isRequestMargin = false;
            this._serverMargin = await this.requestMarginAsync();
        }
        const account = this._optionTrader.account;
        const availableFundsValue = Account.GetAccountFeature(AccountFeature.AvailableFunds, account, account.assetBalanceDefault);
        const availableFundsStr = Account.GetAccountFeatureString(availableFundsValue, AccountFeature.AvailableFunds, account, account.assetBalanceDefault);
        const marginAvailableValue = Account.GetAccountFeature(AccountFeature.MarginAvailable, account, account.assetBalanceDefault);
        const marginAvailableStr = Account.GetAccountFeatureString(marginAvailableValue, AccountFeature.MarginAvailable, account, account.assetBalanceDefault);
        const marginUsedValue = Account.GetAccountFeature(AccountFeature.MarginUsed, account, account.assetBalanceDefault);
        const marginUsedStr = Account.GetAccountFeatureString(marginUsedValue, AccountFeature.MarginUsed, account, account.assetBalanceDefault);
        const totalMaintReqValue = Account.GetAccountFeature(AccountFeature.TotalMaintReq, account, account.assetBalanceDefault);
        const totalMaintReqStr = Account.GetAccountFeatureString(totalMaintReqValue, AccountFeature.TotalMaintReq, account, account.assetBalanceDefault);

        const hasMargin = !isNullOrUndefined(this._serverMargin);
        const availableFundsWithPortfolioValue = hasMargin ? availableFundsValue - this._serverMargin.InitMarginBuy + this._serverMargin.InitMarginSell : NaN;
        const availableFundsWithPortfolioStr = isValidNumber(availableFundsWithPortfolioValue) ? Account.GetAccountFeatureString(availableFundsWithPortfolioValue, AccountFeature.AvailableFunds, account, account.assetBalanceDefault) : this.DEFATULT_STR;
        const marginAvailableWithPortfolioValue = hasMargin ? marginAvailableValue - this._serverMargin.MaintMarginBuy + this._serverMargin.MaintMarginSell : NaN;
        const marginAvailableWithPortfolioStr = isValidNumber(marginAvailableWithPortfolioValue) ? Account.GetAccountFeatureString(marginAvailableWithPortfolioValue, AccountFeature.MarginAvailable, account, account.assetBalanceDefault) : this.DEFATULT_STR;
        const marginUsedWithPortfolioValue = hasMargin ? this._serverMargin.InitMarginBuy : NaN;
        const marginUsedWithPortfolioStr = isValidNumber(marginUsedWithPortfolioValue) ? Account.GetAccountFeatureString(marginUsedWithPortfolioValue, AccountFeature.MarginUsed, account, account.assetBalanceDefault) : this.DEFATULT_STR;
        const totalMaintReqWithPortfolioValue = hasMargin ? this._serverMargin.MaintMarginBuy : NaN;
        const totalMaintReqWithPortfolioStr = isValidNumber(totalMaintReqWithPortfolioValue) ? Account.GetAccountFeatureString(totalMaintReqWithPortfolioValue, AccountFeature.TotalMaintReq, account, account.assetBalanceDefault) : this.DEFATULT_STR;

        const data: InfoData[] = await super.get('data');
        for (let i = 0; i < data.length; i++) {
            const infoData = data[i];
            if (infoData.id !== GroupType.CurrentPortfolio.toString() && infoData.id !== GroupType.PaperPositions.toString()) {
                continue;
            }
            const isPaperPositions = infoData.id === GroupType.PaperPositions.toString();
            for (let j = 0; j < infoData.items.length; j++) {
                const infoDataItem = infoData.items[j];
                switch (infoDataItem.id) {
                case ItemType.AvailableFunds.toString():
                    infoDataItem.value = isPaperPositions ? availableFundsWithPortfolioStr : availableFundsStr;
                    break;
                case ItemType.MarginAvailable.toString():
                    infoDataItem.value = isPaperPositions ? marginAvailableWithPortfolioStr : marginAvailableStr;
                    break;
                case ItemType.InitialRequired.toString():
                    infoDataItem.value = isPaperPositions ? marginUsedWithPortfolioStr : marginUsedStr;
                    break;
                case ItemType.MaintenanceRequired.toString():
                    infoDataItem.value = isPaperPositions ? totalMaintReqWithPortfolioStr : totalMaintReqStr;
                    break;
                }
            }
        }
    }

    private async updateGreeksDataAsync (): Promise<void> {
        const aggregatedGreeks = new Greeks();
        const paperPositions = this._optionTrader.getPaperPositions();
        for (let i = 0; i < paperPositions.length; i++) {
            const paperPosition = paperPositions[i];
            if (!paperPosition.Analyse) {
                continue;
            }
            if (!paperPosition.Instrument.isOptionSymbol) {
                continue;
            }
            if (aggregatedGreeks.isEmpty()) {
                aggregatedGreeks.delta = 0;
                aggregatedGreeks.gamma = 0;
                aggregatedGreeks.vega = 0;
                aggregatedGreeks.theta = 0;
            }
            const greeks = this._optionTrader.getGreeks(paperPosition.Instrument.PutCall, paperPosition.Instrument, paperPosition.Price);
            aggregatedGreeks.delta += greeks.delta * paperPosition.QuantityLots;
            aggregatedGreeks.gamma += greeks.gamma * paperPosition.QuantityLots;
            aggregatedGreeks.vega += greeks.vega * paperPosition.QuantityLots;
            aggregatedGreeks.theta += greeks.theta * paperPosition.QuantityLots;
        }
        const data: InfoData[] = await super.get('data');
        for (let i = 0; i < data.length; i++) {
            const infoData = data[i];
            if (infoData.id !== GroupType.Greeks.toString()) {
                continue;
            }
            for (let j = 0; j < infoData.items.length; j++) {
                const infoDataItem = infoData.items[j];
                switch (infoDataItem.id) {
                case ItemType.Delta.toString():
                    infoDataItem.value = GreeksFormatter.formatGreek(aggregatedGreeks.delta);
                    break;
                case ItemType.Gamma.toString():
                    infoDataItem.value = GreeksFormatter.formatGreek(aggregatedGreeks.gamma);
                    break;
                case ItemType.Vega.toString():
                    infoDataItem.value = GreeksFormatter.formatGreek(aggregatedGreeks.vega);
                    break;
                case ItemType.Theta.toString():
                    infoDataItem.value = GreeksFormatter.formatGreek(aggregatedGreeks.theta);
                    break;
                }
            }
        }
    }

    private async requestMarginAsync (): Promise<IMarginResponse | undefined | null> {
        const paperPositions = this._optionTrader.getPaperPositions();
        if (paperPositions.length === 0) {
            return undefined;
        }
        const parametersArray = [];
        for (let i = 0; i < paperPositions.length; i++) {
            const paperPosition = paperPositions[i];
            const margininfoParameters = new MarginInfoParameters();
            margininfoParameters.account = paperPosition.Account;
            margininfoParameters.instrument = paperPosition.Instrument;
            margininfoParameters.orderType = paperPosition.OrderType;
            margininfoParameters.amountLots = paperPosition.QuantityLots;
            margininfoParameters.limitPrice = paperPosition.Price;
            margininfoParameters.stopPrice = paperPosition.StopPrice;
            margininfoParameters.isLong = paperPosition.Operation === OperationType.Buy;
            margininfoParameters.productType = paperPosition.ProductType;
            margininfoParameters.leverageValue = paperPosition.Leverage;
            parametersArray.push(margininfoParameters);
        }
        return await DataCache.SendMultiMarginRequestAsync(parametersArray, 1);
    }

    private initializeItems (items: ItemType[]): InfoDataItem[] {
        return items.map((item: ItemType) => { return new InfoDataItem(item.toString(), this.getItemTitle(item), this.DEFATULT_STR); });
    }

    private getGroupTitle (data: GroupType): string {
        switch (data) {
        case GroupType.CurrentPortfolio:
            return Resources.getResource('panel.optionMaster.optionTraderInfoControl.currentPortfolio');
        case GroupType.PaperPositions:
            return Resources.getResource('panel.optionMaster.optionTraderInfoControl.paperPositions');
        case GroupType.Greeks:
            return Resources.getResource('panel.optionMaster.optionTraderInfoControl.greeks');
        default:
            return '';
        }
    }

    private getItemTitle (item: ItemType): string {
        switch (item) {
        case ItemType.AvailableFunds:
            return Resources.getResource('panel.accounts.AvailableMargin');
        case ItemType.InitialRequired:
            return Resources.getResource('panel.positions.InitReq');
        case ItemType.MarginAvailable:
            return Resources.getResource('panel.accounts.MarginAvailableReal');
        case ItemType.MaintenanceRequired:
            return Resources.getResource('panel.positions.MaintReq');
        case ItemType.Delta:
            return Resources.getResource('panel.optionPaperPositions.Delta');
        case ItemType.Gamma:
            return Resources.getResource('panel.optionPaperPositions.Gamma');
        case ItemType.Vega:
            return Resources.getResource('panel.optionPaperPositions.Vega');
        case ItemType.Theta:
            return Resources.getResource('panel.optionPaperPositions.Theta');
        default:
            return '';
        }
    }

    private onVisibleChanged (): void {
        const isVisible: boolean = super.get('visible');
        if (isVisible) { this.updateDataWithRequest(); }
    }

    private onTimer500msTick (): void { this.updateDataWithoutRequest(); }
    private onTimer30sTick (): void { this.updateDataWithRequest(); }
}
