// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DateTimeUtils } from "../../Utils/Time/DateTimeUtils.ts";
import { Resources } from "../../Commons/properties/Resources.ts";
import { TimeZoneInfo } from "../../Utils/Time/TimeZoneInfo.ts";
import { TimeZoneInfoSet } from "../../Utils/Time/TimeZoneInfoSet.js";
import { contextMenuHandler } from "../../Utils/AppHandlers.js";
import { TerceraTimeZoneSelectorTemplate } from "../../templates.js";
import { ControlsTypes } from "../UtilsClasses/FactoryConstants.ts";
import { PropertySetupScreen } from "../screen/PropertySetupScreen.js";
import { Control } from "./Control.js";
import { DynProperty } from "../../Commons/DynProperty.ts";
import { SessionSettings } from "../../Commons/SessionSettings.ts";
import { DateTimeConvertor } from "../../Utils/Time/DateTimeConvertor.ts";


export let TerceraTimeZoneSelector = Control.extend({
    template: TerceraTimeZoneSelectorTemplate,
    data: function () { return { currentTime: '' }; }
});

TerceraTimeZoneSelector.prototype.getType = function ()
{
    return ControlsTypes.TerceraTimeZoneSelector;
};

TerceraTimeZoneSelector.prototype.oninit = function ()
{
    Control.prototype.oninit.apply(this);
    // #75789.
    //this.on('click', this.showMenu);
    this.menuItems = [];
    Control.Ticker.Subscribe(this.updateTime, this);
};

TerceraTimeZoneSelector.prototype.showMenu = function ()
{
    var offset = $(this.find('*')).offset();
    var posY = offset.top - $(window).scrollTop();
    var posX = offset.left - $(window).scrollLeft();

    this.menuItems = this.getMenuItems();

    var menuMaxHeight = 300;
    var itemHeight = 20;
    var menuHeight = this.menuItems.length * itemHeight;
    if (menuHeight > menuMaxHeight)
        menuHeight = menuMaxHeight;

    contextMenuHandler.Show(this.menuItems, posX - 237, posY - menuHeight - 2, { width: 342 });
};

TerceraTimeZoneSelector.prototype.getMenuItems = function ()
{
    let resourceKeyPrefix = 'screen.TimeZoneSelector.';
    let callback = TerceraTimeZoneSelector.updateCurrentTimeZone;

    let items = [
        {
            text: Resources.getResource(resourceKeyPrefix + 'TIMEZONE_LOCAL'),
            tag: TimeZoneInfo.LOCAL,
            event: callback
        },
        {
            text: Resources.getResource(resourceKeyPrefix + 'TIMEZONE_SERVER'),
            tag: TimeZoneInfo.SERVER,
            event: callback
        }
    ];

    items.push.apply(items, this.getFavoriteTimeZoneMenuItems());

    items.push({
        text: Resources.getResource(resourceKeyPrefix + 'Customize'),
        event: this.showCustomizeScreen.bind(this)
    });

    return items;
};

TerceraTimeZoneSelector.prototype.getFavoriteTimeZoneMenuItems = function ()
{
    let favoriteTZIArray = [];
    for (let key in SessionSettings.favoriteTimeZoneInfoIdsSet)
        favoriteTZIArray.push(TimeZoneInfoSet[key]);

    favoriteTZIArray.sort(TimeZoneInfo.compare);

    var callback = TerceraTimeZoneSelector.updateCurrentTimeZone;
    var menuItems = [];

    for (let i = 0, len = favoriteTZIArray.length; i < len; i++)
    {
        var tzi = favoriteTZIArray[i];

        menuItems.push({
            text: tzi.toLocalizedString(),
            tag: tzi.id,
            event: callback
        });
    }

    return menuItems;
};

TerceraTimeZoneSelector.prototype.showCustomizeScreen = function ()
{
    PropertySetupScreen.editProperty(this, 'screen.TimeZoneSelector.title');
};

TerceraTimeZoneSelector.prototype.updateTime = function ()
{
    let sessionSettings = SessionSettings;
    let currentTZInfoId = DateTimeConvertor.currentTimeZoneInfoId.value;

    let tzInfo = TimeZoneInfoSet[currentTZInfoId];
    if (!tzInfo) return;

    let now = DateTimeUtils.DateTimeUtcNow();

    let userUtcOffset = tzInfo.baseUtcOffset;
    let utcOffsetDeltaMillis = (userUtcOffset.totalMinutes() + now.getTimezoneOffset()) * 60 * 1000;

    now.setTime(now.getTime() + utcOffsetDeltaMillis);

    let utcOffsetString = userUtcOffset.toString();
    let utcString = utcOffsetString ? ' (UTC' + utcOffsetString + ')' : ' (UTC)';

    this.set('currentTime', DateTimeUtils.formatDate(now, "HH:mm:ss") + utcString);
};

//#region ICaller

TerceraTimeZoneSelector.prototype.Properties = function ()
{
    let sess = SessionSettings;

    let curTZInfoId = DateTimeConvertor.currentTimeZoneInfoId.value;
    let favTZInfoIdsSet = sess.favoriteTimeZoneInfoIdsSet;

    let props = [];
    let tzInfoSet = TimeZoneInfoSet;
    for (let key in tzInfoSet)
    {
        let tzInfo = tzInfoSet[key];
        if (tzInfo.id === TimeZoneInfo.LOCAL || tzInfo.id === TimeZoneInfo.SERVER)
            continue;

        let isFavTZ = favTZInfoIdsSet.hasOwnProperty(tzInfo.id);

        let dp = new DynProperty(tzInfo.id, isFavTZ, DynProperty.BOOLEAN, DynProperty.VIEW_GROUP);
        dp.localizationKey = tzInfo.toLocalizedString();
        dp.enabled = curTZInfoId !== tzInfo.id;
        dp.sortIndex = tzInfo.sortIndex;

        props.push(dp);
    }

    return props;
};

TerceraTimeZoneSelector.prototype.callBack = function (props)
{
    var favIdsSet = {};
    var len = props.length;
    for (var i = 0; i < len; i++)
    {
        var dp = props[i];
        if (dp.value) favIdsSet[dp.name] = true;
    }
    SessionSettings.updateFavoriteTimeZoneInfoIdsSet(favIdsSet);
};

//#endregion

TerceraTimeZoneSelector.updateCurrentTimeZone = function (menuItem)
{
    SessionSettings.updateCurrentTimeZoneInfoId(menuItem.tag);
};