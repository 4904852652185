// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class LogonMessage extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_PFIX_LOGON_MESSAGE, fieldSet);
    }

    // +++ new
    public getLogin (): string {
        return this.getFieldValue(FieldsFactory.FIELD_LOGIN);
    }

    // +++ new
    public setLogin (login: string): void {
        this.setFieldValue(FieldsFactory.FIELD_LOGIN, login);
    }

    // +++ new
    public getPassword (): string {
        return this.getFieldValue(FieldsFactory.FIELD_PASSWORD);
    }

    // +++ new
    public setPassword (password: string): void {
        this.setFieldValue(FieldsFactory.FIELD_PASSWORD, password);
    }

    // +++ new
    public getConnectionMode (): number {
        return this.getFieldValue(FieldsFactory.FIELD_CONNECTION_MODE);
    }

    // +++ new
    public setConnectionMode (mode: number): void {
        this.setFieldValue(FieldsFactory.FIELD_CONNECTION_MODE, mode);
    }

    // +++ new
    public getClientType (): number {
        return this.getFieldValue(FieldsFactory.FIELD_CLIENT_TYPE);
    }

    // +++ new
    public setClientType (ctype: number): void {
        this.setFieldValue(FieldsFactory.FIELD_CLIENT_TYPE, ctype);
    }

    // +++ new
    public getVerificationPassword (): string {
        return this.getFieldValue(FieldsFactory.FIELD_VERIFICATION_PASSWORD);
    }

    // +++ new
    public setVerificationPassword (verificationPassword: string): void {
        this.setFieldValue(FieldsFactory.FIELD_VERIFICATION_PASSWORD, verificationPassword);
    }

    // +++ new
    public getProtocolVersion (): string {
        return this.getFieldValue(FieldsFactory.FIELD_PROTOCOL_ID);
    }

    // +++ new
    public setProtocolVersion (version: string): void {
        this.setFieldValue(FieldsFactory.FIELD_PROTOCOL_ID, version);
    }

    // +++ new
    public getMaxFieldNumber (): number {
        const FIELD_MAX_PFIX_FIELD_INDEX = this.FieldSet.GetField(FieldsFactory.FIELD_MAX_PFIX_FIELD_INDEX);
        if (FIELD_MAX_PFIX_FIELD_INDEX) {
            return FIELD_MAX_PFIX_FIELD_INDEX.Value;
        } else {
            return 2147483647;
        }// int.MaxValue;
    }

    // +++ new
    public setMaxFieldNumber (number: number): void {
        this.setFieldValue(FieldsFactory.FIELD_MAX_PFIX_FIELD_INDEX, number);
    }

    // +++ new
    public getCanISendGetInitDataRequest (): boolean {
        return this.getFieldValue(FieldsFactory.FIELD_NONE) === 1;
    }

    // +++ new
    public setCanISendGetInitDataRequest (value): void {
        this.setFieldValue(FieldsFactory.FIELD_NONE, value ? 1 : 0);
    }

    // +++ new
    public getIsUnfixedList (): boolean {
        return this.getFieldValue(FieldsFactory.FIELD_IS_UNFIXED_LIST);
    }

    public setDateOfBuild (value: string): void {
        this.setFieldValue(FieldsFactory.FIELD_DATE_OF_BUILD, value);
    }

    public getSessionId (): string {
        return this.getFieldValue(FieldsFactory.FIELD_SESSIONID);
    }

    public getTradingUnlockLogic (): any {
        return this.getFieldValue(FieldsFactory.FIELD_TRADING_UNLOCK_LOGIC);
    }

    public getVerificationMode (): any {
        return this.getFieldValue(FieldsFactory.FIELD_2FA_TYPE);
    }

    public setExternalResourse (extResId): void {
        this.setFieldValue(FieldsFactory.FIELD_EXT_RESOURCE, extResId);
    }

    public setTokenID (tokenID: string): void {
        this.setFieldValue(FieldsFactory.FIELD_TOKEN_ID, tokenID);
    }

    public isPasswordExpired (): boolean {
        return this.getFieldValue(FieldsFactory.FIELD_PWD_EXPIRED);
    }

    // version 1.9

    // +++ new
    public setAccessToken (accessToken: string): void {
        this.setFieldValue(FieldsFactory.FIELD_ACCESS_TOKEN, accessToken);
    }

    // +++ new
    public GetAccessToken (): string {
        return this.getFieldValue(FieldsFactory.FIELD_ACCESS_TOKEN);
    }

    // +++ new
    public GetAccessTokenLifeTime (): number {
        return this.getFieldValue(FieldsFactory.FIELD_ACCESS_TOKEN_LIFE_TIME);
    }

    // +++ new
    public GetRefreshToken (): string {
        return this.getFieldValue(FieldsFactory.FIELD_REFRESH_TOKEN);
    }

    // +++ new
    public GetRefreshTokenLifeTime (): number {
        return this.getFieldValue(FieldsFactory.FIELD_REFRESH_TOKEN_LIFE_TIME);
    }

    // brandingKey

    public setBrandingKey (brandingKey: string): void {
        this.setFieldValue(FieldsFactory.FIELD_BRANDING_KEY, brandingKey);
    }

    public SetCustomerNo (customerNo: string): void {
        this.setFieldValue(FieldsFactory.FIELD_EXTERNAL_ACCOUNT, customerNo);
    }

    // IP
    public setIP (IP: string): void {
        this.setFieldValue(FieldsFactory.FIELD_SOURCE, IP);
    }

    // https://tp.traderevolution.com/entity/113979
    public SetAppInstallId (appInstallId: string): void {
        this.setFieldValue(FieldsFactory.FIELD_APP_INSTALL_ID, appInstallId);
    }
}
