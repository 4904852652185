// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts";
import { ContainerControl } from "./ContainerControl.js";

export let DynPropertyControl = ContainerControl.extend({
    data: function ()
    {
        return {
            label: '',
            dp: null,
            SLNumericLink: null,
            TPNumericLink: null,
            PriceNumericLink: null,
            StopPriceNumericLink: null
        }
    }
})

DynPropertyControl.prototype.getType = function () { return 'DynPropertyControl' };

DynPropertyControl.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.call(this)

    this.observe('dp.localizationKey', this.localize)
    this.observe('dp.customCSS', this.onCustomCSSChanged, { init: false })

    Resources.onLocaleChange.Subscribe(this.localize, this)
}

DynPropertyControl.prototype.dispose = function ()
{
    Resources.onLocaleChange.UnSubscribe(this.localize, this)

    ContainerControl.prototype.dispose.call(this)
}

DynPropertyControl.prototype.localize = function ()
{
    let locKey = this.get('dp.localizationKey')
    this.set('label', Resources.getResource(locKey))
}

DynPropertyControl.prototype.onCustomCSSChanged = function ()
{
    let style = this.get('dp.customCSS')

    if (style !== undefined)
        this.set('additionalClassName', style)
}
