// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { LinkedSystem, LinkedSystemAccLinkingValue } from '../misc/LinkedSystem';
import { TerceraLinkControlConstants } from '../UtilsClasses/TerceraLinkControlConstants';
import { TerceraWindowBase } from '../screen/TerceraWindowBase_ts';
import { type PanelNames, PanelsWithAccountLookup } from '../UtilsClasses/FactoryConstants';
import { Resources } from '../../Commons/properties/Resources';
import { DynProperty } from '../../Commons/DynProperty';
import { DataCache } from '../../Commons/DataCache';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { WorkSpaceManager } from '../WorkSpace/WorkSpaceManager';
import { ApplicationPanelNewTemplate } from '../../templates.js';
import { type Account } from '../../Commons/cache/Account';
import { type Instrument } from '../../Commons/cache/Instrument';

export class ApplicationPanel extends TerceraWindowBase {
    public Name: string;
    public topPanelHeight: number;
    public margin: number;

    public noSymbolWasFoundTooltip: string;

    public AllowSettings;
    public headerLocaleKey: string;
    public skipOnSave: boolean;
    public skipSomeProperties: boolean;
    public completed: boolean;
    public forceUpdate: boolean;
    public dockSpawnElementsToRemove: any[]; // remove elements of dock system when panel destroing. fix events leak
    public deferredCallbackProps: any;
    public containerSetFocus: any;
    public deferredXmlSettingsTemplate: any;

    constructor () {
        super();

        this.Name = 'app';
        this.topPanelHeight = 0;
        this.margin = 5;

        this.noSymbolWasFoundTooltip = '';

        this.AllowSettings = true;
        this.headerLocaleKey = '';
        this.skipOnSave = false;
        this.skipSomeProperties = false;
        this.completed = false;
        this.forceUpdate = false;
        this.dockSpawnElementsToRemove = []; // remove elements of dock system when panel destroing. fix events leak
        this.deferredCallbackProps = null;
    }

    public override oninit (): void {
        super.oninit();

        this.observe('symbolLinkValue', () => {
            this.symbolLink_Out(true);
        });
        this.observe('accountLinkValue', () => {
            this.accountLink_Out(true);
        });

        this.observe('isSymbolLinkShow isAccountLinkShow', this.LinkSystemHandler.bind(this), { init: false });

        this.observe('header', (newVal) => {
            if (newVal && this.setTitle) {
                this.setTitle(newVal);
            }
        });

        if (Resources.isHidden('allowLinkSystem')) {
            void this.set({
                isAccountLinkShow: false,
                isSymbolLinkShow: false
            });
        }
    }

    public setTitle: (title: string) => void = null;

    public override getType (): PanelNames { return null; }

    public ContentContainerHeight (): number {
        let contentContainerH = this.get('height');
        const nonDockablePanelWithHeader = !this.get('dockablePanel') && this.get('showHeader');
        if (nonDockablePanelWithHeader) {
            contentContainerH -= 20;
        } // header height;

        if (this.isShowExploreTheAppLink()) {
            contentContainerH -= 25;
        }

        return contentContainerH;
    }

    public override getClientPanel (customPlace = false): HTMLElement {
        if (!customPlace) {
            return super.getClientPanel();
        } else {
            if (!isNullOrUndefined(this.Controls.windowPanelBodyQT)) {
                return this.Controls.windowPanelBodyQT.getClientPanel();
            } else {
                return super.getClientPanel();
            }
        }
    }

    public hasAccountLookup (): boolean {
        return PanelsWithAccountLookup.includes(this.getType());
    }

    public LinkSystemHandler (newVal, oldVal, key): void {
        this.updateLinkVisible();

        if (!newVal && key === 'isAccountLinkShow') {
            if (!this.hasAccountLookup() && this.get('account') == null) {
                void
                this.set('account', DataCache.getPrimaryAccount());
            }
        }
    }

    public override oncomplete (): void {
        super.oncomplete();

        if (DataCache.getNumberOfAccounts() === 1) {
            void this.set({ isAccountLinkShow: false });
        }

        this.accLinkingActiveUpdate();

        if (this.get('isSetFocusOnComplete')) {
            this.setFocus();
        }

        this.completed = true;
    }

    public accLinkingActiveUpdate (): void {
        LinkedSystem.accLinkingActive ? this.enableAccountLinking() : this.disableAccountLinking();
    }

    public setSize (w: number, h: number): void {
        void this.set({ width: w, height: h });
    }

    public override gotFocus (): void {
        super.gotFocus();
        if (this.get('dockablePanel')) {
            if (this.containerSetFocus) {
                this.containerSetFocus(true);
            }
        }
    }

    public override lostFocus (): void {
        super.lostFocus();
        const t = this.get('dockablePanel');
        if (this.get('dockablePanel')) {
            if (this.containerSetFocus) {
                this.containerSetFocus(false);
            }
        }
    }

    public override localize (): void {
        super.localize();

        this.updatePanelHeader();
        void this.set({
            banText: Resources.getResource('panel.caption.NotAllowedMode'),
            symbolLinkTooltip: Resources.getResource('panel.AccountLink.SymbolLink.ToolTip')

        });

        this.noSymbolWasFoundTooltip = Resources.getResource('panel.menu.symbolWasNotFound');
        this.localizeAccountLinkTooltip();
    }

    public localizeAccountLinkTooltip (): void {
        void this.set({ accountLinkTooltip: this.tooltipForAccountLink() });
    }

    public dispose (): void {
        const toDisp = this.dockSpawnElementsToRemove;
        if (toDisp) {
            for (let i = 0; i < toDisp.length; i++) {
                toDisp[i].dispose();
            }
        }

        if (WorkSpaceManager.currentWorkspace) {
            WorkSpaceManager.currentWorkspace.removePanel(this._guid);
        }

        super.dispose();
    }

    public Properties (): DynProperty[] {
        const properties: DynProperty[] = [];

        properties.push(new DynProperty('X_Position', this.get('left'), DynProperty.INTEGER, DynProperty.HIDDEN_GROUP));
        properties.push(new DynProperty('Y_Position', this.get('top'), DynProperty.INTEGER, DynProperty.HIDDEN_GROUP));
        properties.push(new DynProperty('Width', this.get('width'), DynProperty.INTEGER, DynProperty.HIDDEN_GROUP));
        properties.push(new DynProperty('Height', this.get('height'), DynProperty.INTEGER, DynProperty.HIDDEN_GROUP));
        properties.push(new DynProperty('Name', this.getType(), DynProperty.STRING, DynProperty.HIDDEN_GROUP));
        properties.push(new DynProperty('WSId', this.get('workSpaceId'), DynProperty.INTEGER, DynProperty.HIDDEN_GROUP));

        if (this.get('isAccountLinkShow')) {
            properties.push(new DynProperty('accountLinkValue', this.get('accountLinkValue'), DynProperty.INTEGER, DynProperty.HIDDEN_GROUP));
        }

        if (this.get('isSymbolLinkShow')) {
            properties.push(new DynProperty('symbolLinkValue', this.get('symbolLinkValue'), DynProperty.INTEGER, DynProperty.HIDDEN_GROUP));
        }

        return properties;
    }

    public callBack (newProperties): void {
        const newX = DynProperty.getPropValue(newProperties, 'X_Position');
        const newY = DynProperty.getPropValue(newProperties, 'Y_Position');
        const newWidth = DynProperty.getPropValue(newProperties, 'Width');
        const newHeight = DynProperty.getPropValue(newProperties, 'Height');
        const wsId = DynProperty.getPropValue(newProperties, 'WSId');

        if (this.get('isAccountLinkShow')) {
            const dpVal = DynProperty.getPropValue(newProperties, 'accountLinkValue');
            if (dpVal !== null) {
                void this.set({ accountLinkValue: dpVal });
            }
        }
        if (this.get('isSymbolLinkShow')) {
            const dpVal = DynProperty.getPropValue(newProperties, 'symbolLinkValue');
            if (dpVal !== null) {
                void this.set({ symbolLinkValue: dpVal });
            }
        }

        if (newX !== null && newY !== null && newWidth !== null && newHeight !== null) {
            this.setBounds(newX, newY, newWidth, newHeight);
        }

        if (wsId !== null) {
            this.setWorkSpaceId(wsId);
        }
    }

    public updateSettings (): void { this.forceUpdate = true; }

    public symbolLink_Out (newSubscriber, instrument?): void {
        // throw new Error("Method doesn't implemented");
    }

    public symbolLink_In (symbolName): void {
        // throw new Error("Method doesn't implemented");
    }

    public accountLink_Out (newSubscriber: boolean, account?: Account): void {
        const completedAndHasNoDeferredCallbackProps = this.completed && this.deferredCallbackProps == null;

        if (isNullOrUndefined(account)) {
            const acc = this.get('account') || this.get('accountItem');
            if (acc == null && completedAndHasNoDeferredCallbackProps) {
                return;
            }
            account = acc;
        }

        if (completedAndHasNoDeferredCallbackProps) {
            LinkedSystem.setAccount(LinkedSystemAccLinkingValue, account.BstrAccount);
        } else if (LinkedSystem.accLinkingActive) {
            this.accountLink_In(LinkedSystem.getAccount(LinkedSystemAccLinkingValue));
        }
    }

    public accountLink_In (accId, fromLinkedSystem = false): boolean {
        if (!this.get('canLinkByAccount')) {
            return false;
        }

        if (fromLinkedSystem && !this.OnAccountLinking()) {
            return false;
        }

        const acc = DataCache.Accounts[accId];
        if (!isNullOrUndefined(acc)) {
            void this.set('account', acc);
        }

        this.localizeAccountLinkTooltip();

        return true;
    }

    public updateLinkVisible (): any { }

    public setWorkSpaceId (id): void {
        void this.set({ workSpaceId: id });
    }

    public getWorkSpaceId (): any {
        return this.get('workSpaceId');
    }

    public updatePanelHeader (): void {
        void this.set({ header: Resources.getResource(this.headerLocaleKey) });
    }

    public tooltipForAccountLink (): string {
        let tooltip = Resources.getResource('panel.AccountLink.CanFilterToolTip');

        if (!this.get('canFilterByAccount')) {
            tooltip = Resources.getResource('panel.AccountLink.ToolTip');
            const acc = this.get('accountItem') || this.get('account');
            if (acc != null) {
                tooltip += ' : ' + acc.FullAccString;
            } else {
                tooltip = Resources.getResource('panel.accounts.AccountLink.ToolTip'); // AssetBalancesPanel
            }
        }

        return tooltip;
    }

    public enableAccountLinking (accId?: string): void {
        if (!this.get('canLinkByAccount')) {
            return;
        }

        accId = accId || LinkedSystem.getAccount(LinkedSystemAccLinkingValue);
        this.updateAccountLinkVisible(true);
    }

    public disableAccountLinking (): void {
        if (!this.get('canLinkByAccount')) {
            return;
        }

        this.updateAccountLinkVisible(false);
    }

    public updateAccountLinkVisible (visible: boolean, force: boolean = false): void {
        if (!(this.get('canLinkByAccount') as boolean) && !force) {
            return;
        }

        void this.set('isAccountLinkShow', visible);
        this.updateLinkVisible();
    }

    public getIsAccountLinkShow (): boolean {
        return (this.get('isAccountLinkShow') as boolean) && !DataCache.EnableForceLinkingByAccount();
    }

    public OnAccountLinking (): boolean { return this.get('isAccountLinkShow'); }

    public setSettings (json): void {
        this.callBack(DynProperty.deserialize(JSON.parse(json)));
    }

    public getSettings (): string {
        return JSON.stringify(DynProperty.serialize(this.Properties()));
    }

    public workWithToolBar (): any { }

    public override onKeyDown (event): boolean {
        super.onKeyDown(event);
        const original = event.original;
        if (original.keyCode < 48 || original.keyCode > 90 ||
        original.altKey || original.ctrlKey || original.shiftKey) {
            return true;
        }

        const activeCtrl = this.getActiveControl();
        if (activeCtrl) {
            const actType = activeCtrl.getType();
            if (ApplicationPanel._striplostFocusTypes.includes(actType)) {
                return true;
            }
        }
        this._onKeyDownProcess(event);
    }

    private getActiveControl (): any {
        let activeCtrl = this.get('activeControl');
        if (isNullOrUndefined(activeCtrl)) {
            return null;
        }
        while (activeCtrl instanceof ApplicationPanel) {
            activeCtrl = activeCtrl.get('activeControl');
        }
        return activeCtrl;
    }

    public _onKeyDownProcess (event): void {
        let controls = this.Controls;
        if (controls) {
            let lookup = controls.TerceraInstrumentLookup;
            if (!lookup) {
                if (controls.topPanel) {
                    controls = controls.topPanel.Controls;
                }
            }

            if (controls) {
                lookup = controls.TerceraInstrumentLookup;
            }

            if (!lookup) {
                return;
            }

            if (!lookup.get('focused')) {
                if (lookup.textBox !== null) {
                    lookup.textBox.set({ text: '', skipfocus: true });
                    lookup.setFocus();
                    lookup.onKeyDown(event);
                }
            }
        }
    }

    public static readonly _striplostFocusTypes = ['TerceraAccountLookup', 'TerceraNumeric', 'TerceraTextBox', 'TerceraQuantityNumeric', 'TerceraSLTPNumeric', 'NAME_ORDERCOMPOSIT_PANEL', 'DynPropertyControl', 'OEQuantity', 'AdvancedChartOrderEntry', 'TerceraVisualTradingWidget'];

    public setVisibility (visible): void {
        void this.set({ visible });
    }

    /// <summary>
    /// Аккаунт
    /// </summary>
    public getAccount (): Account {
        let account = this.get('account') || this.get('accountItem');
        if (account.isHide) {
            account = DataCache.getPrimaryAccount();
        }

        return account;
    }

    public getSelectedInstrumentID (): string { return ''; }

    public getInstrument (): Instrument | null {
        return null;
    }

    public openPanel (panelName): void {
        const ins = this.getSelectedInstrumentID();
        MainWindowManager.Factory.addPanel(panelName, null, function (panel) { panel.symbolLink_In(ins); });
    }

    public TickAsync (): void { }

    public populate (addPanel: boolean): void { }

    public themeChange (): void { }

    public CustomPreparation (): any { }

    // help in migration process: checking if properties for callback are correct and suitable for current version
    public validateProperties (properties): boolean { return true; }

    // help in migration process: get lost WSid
    public GetWSIdFromProperty (properties): any {
        return DynProperty.getPropValue(properties, 'WSId');
    }

    public RefreshInstrumentDescriptions (insArray): any { }

    public getXmlSettingsTemplate (): any {
        return {};
    }

    public setXmlSettingsTemplate (value): void {
        this.deferredXmlSettingsTemplate = value;
    }
}

TerceraWindowBase.extendWith(ApplicationPanel, {
    data: function () {
        return {
            symbolLinkValue: TerceraLinkControlConstants.ColorConstants.NONE,
            accountLinkValue: TerceraLinkControlConstants.ColorConstants.NONE,
            isAccountLinkShow: false,
            isSymbolLinkShow: false,
            resizable: false,
            showFooter: false,
            top: 0,
            left: 0,
            dockablePanel: true,
            workSpaceId: null,
            showHeader: false,

            account: null,
            canLinkByAccount: true,
            canFilterByAccount: true,
            isHeaderMenuButtonShow: false,
            // canSelect: false,
            loading: false,
            isSetFocusOnComplete: true
        };
    },
    partials: {
        bodyPartial: ApplicationPanelNewTemplate
    }
});
