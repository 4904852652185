// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
export class BuyingPowerAvailableFund // #106952
{
    public Name: any;
    public AvailableMargin: any;

    constructor (name, availableMargin) {
        this.Name = name || null;
        this.AvailableMargin = availableMargin || null;
    }
}
