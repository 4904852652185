import { type Interval } from '../../../../Utils/History/Interval';
import { DateTimeUtils } from '../../../../Utils/Time/DateTimeUtils';
import { HistoryMergerBasedByMin } from './HistoryMergerBasedByMin';

export class HistoryMergerExtraday extends HistoryMergerBasedByMin {
    protected override GetStartPoint (dateTimeUtc: Date): Interval {
        // aggregation from start point Jan 1 1970
        const unixEpoch: Date = DateTimeUtils._ZeroTime;
        const borders: Interval = this.GetPropagatedBarBorders(dateTimeUtc, unixEpoch, this.newPeriod);
        return borders;
    }
}
