// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
// TODO. Duplicate. Almost the same as StopOrderEdit.

import { Intervals } from '../../../../Utils/Instruments/Intervals';
import { OrderType } from '../../../../Utils/Trading/OrderType';
import { Resources } from '../../../properties/Resources';
import { DynProperty } from '../../../DynProperty';
import { NumericUtils } from '../../../../Utils/NumericUtils';
import { OrderExecutorUtils } from '../../../Trading/OrderExecutorUtils';
import { OrderEditUpdateData } from '../../../../Utils/Trading/OrderEditUpdateData';
import { GeneralSettings } from '../../../../Utils/GeneralSettings/GeneralSettings';
import { SLTPEdit } from '../SLTPEdit';
import { OrderEditBaseUtils } from '../../../../Utils/Trading/OrderEditBaseUtils';
import { OrderEditBase } from './OrderEditBase';

export class LimitOrderEdit extends OrderEditBase {
    public needSetDefaultPrice: boolean;

    constructor (data) {
        super(data);

        this.limitPrice = null;
        this.sltp = new SLTPEdit(data.dataCache, data.forceSLTPOffset);
        this.sltpBasePriceGetter = this.sltpBasePriceGetter.bind(this);

        this.needSetDefaultPrice = false;
    }

    public override getParameterNameArray (): string[] {
        return [
            OrderEditBaseUtils.LIMIT_PRICE_PARAM,
            OrderEditBaseUtils.LIMIT_PRICE_PARAM_CHANGE_FROM_LAST_PRICE,
            OrderEditBaseUtils.POSITION_SIZING_PARAM,
            OrderEditBaseUtils.SLTP_PARAM
        ];
    }

    // TODO. Refactor. Same as OrderTypeBase.Id function.
    public override getOrderTypeId (): OrderType {
        return OrderType.Limit;
    }

    // TODO. Refactor. Use error/warning dicts for sltp.
    // public valid ()
    // {
    //     return OrderEditBase.prototype.valid.call(this) &&
    //         this.sltp.valid()
    // }

    // #region Update Parameter Handlers

    public update_limitPrice (updateData): boolean {
        let parameterChanged = false;

        const dp = updateData.dp;
        if (dp && this.limitPrice !== dp.value && !(isNaN(this.limitPrice) && isNaN(dp.value))) {
            this.limitPrice = dp.value;
            parameterChanged = true || parameterChanged;
        }

        let newInstrument: any = null;
        let newQuote: any = null;
        let sideChanged = false;

        const newTradingDataDict = updateData.newTradingDataDict;
        if (newTradingDataDict) {
            newInstrument = newTradingDataDict.instrument;
            newQuote = newTradingDataDict.quote;
            sideChanged = 'side' in newTradingDataDict;
        }

        if (newInstrument) {
            this.limitPrice = null;
            this.needSetDefaultPrice = true;
            parameterChanged = true || parameterChanged;
        }

        if (this.needSetDefaultPrice && newQuote) {
            this.needSetDefaultPrice = false;
            this.limitPrice = newQuote.BidSpread_SP_Ins(this.instrument?.DataCache.GetSpreadPlan(this.account), this.instrument);
            parameterChanged = true || parameterChanged;
        }

        if (newQuote || sideChanged || parameterChanged) {
            parameterChanged = this.tryUpdateWarning_limitPrice(
                this.limitPrice,
                this.quote) || parameterChanged;

            parameterChanged = this.tryUpdateWarningForChangeFromLastPrice(this.limitPrice) || parameterChanged;
        }

        return parameterChanged;
    }

    public tryUpdateWarning_limitPrice (limitPrice, quote): boolean {
        if (!quote) {
            return false;
        }

        const sp = this.instrument?.DataCache.GetSpreadPlan(this.account);
        const ask = quote.AskSpread_SP_Ins(sp, this.instrument);
        const bid = quote.BidSpread_SP_Ins(sp, this.instrument);

        const buy = this.buy();

        const newWarning =
            buy && limitPrice >= ask
                ? 'general.trading.limitBuyLessAsk'
                : !buy && limitPrice <= bid
                    ? 'general.trading.limitSellMoreBid'
                    : null;

        return this.setParameterWarning(OrderEditBaseUtils.LIMIT_PRICE_PARAM, newWarning);
    }

    public tryUpdateWarningForChangeFromLastPrice (limitPrice): any {
        const ins = this.instrument;
        if (!ins) return;

        if (!ins.UseWarningForChangeFromLastPrice()) {
            return false;
        }

        let lastPrice: number | null = ins.Level1.GetLastPrice(this.account);
        let noLastPrice = false;
        let newWarning: any = null;

        if (!lastPrice) // 4.2.1 about Reference price instead of Last price
        {
            noLastPrice = true;
            lastPrice = ins.Level1 ? ins.Level1.getReferencePrice : null;
        }

        const lastChange = ins.GetChangeByPrice(lastPrice);

        if (this.buy()) {
            if (lastPrice !== null && limitPrice <= lastPrice - lastChange * lastPrice / 100) {
                newWarning = noLastPrice
                    ? 'general.trading.limitBuyLessAllowableFromBase'
                    : 'general.trading.limitBuyLessAllowableFromLast';
            }
        } else {
            if (lastPrice !== null && limitPrice >= lastPrice + lastChange * lastPrice / 100) {
                newWarning = noLastPrice
                    ? 'general.trading.limitSellMoreAllowableFromBase'
                    : 'general.trading.limitSellMoreAllowableFromLast';
            }
        }

        if (newWarning) // пока думаю что кешировать Resources.getResource(newWarning) нецелесообразно ведь помимо проверки не поменялся ли ключ необходимо будет следить не поменялся ли язьік приложения, не факт что от кеширования по итогу можно будет вьіиграть
        {
            newWarning = Resources.getResource(newWarning).replace('{1}', lastPrice.toString());
        }

        return this.setParameterWarning(OrderEditBaseUtils.LIMIT_PRICE_PARAM_CHANGE_FROM_LAST_PRICE, newWarning);
    }

    public update_limitPriceChangeFromLast (): any { };
    public toDynProperty_limitPriceChangeFromLast (): DynProperty { return new DynProperty(); };
    public toRawValue_limitPriceChangeFromLast (): any { };

    // #endregion Update Parameter Handlers

    // #region Parameters' DynProperty Generators

    public toDynProperty_limitPrice (): DynProperty {
        const dp = new DynProperty(OrderEditBaseUtils.LIMIT_PRICE_PARAM);
        dp.type = DynProperty.DOUBLE;

        const ins = this.instrument;
        const intrvls = Intervals.GetIntervalsFromInstrument(ins, NumericUtils.MAXVALUE);
        if (ins) {
            dp.minimalValue = intrvls && (intrvls.length > 0) ? intrvls[0].LeftValue : ins.PointSize;
            dp.maximalValue = 999999999;
            dp.decimalPlaces = ins.Precision;
            dp.increment = ins.PointSize;
        }

        if (intrvls) {
            dp.Intervals = intrvls;
        };

        const insDefSettings = GeneralSettings.InsDefSettingsStorage.GetInstrumentSettings(ins);
        if (insDefSettings) {
            dp.increment = ins?.PointSize * insDefSettings.PriceIncrementTicks;
        }

        dp.value = this.limitPrice;
        if (dp.value === null || isNaN(dp.value)) {
            dp.value = dp.minimalValue;
        }

        dp.localizationKey = 'panel.newOrderEntry.limitPricePanel';
        return dp;
    }

    // #endregion Parameters' DynProperty Generators

    // #region Raw Values

    public toRawValue_limitPrice (): any {
        return this.limitPrice;
    }

    // #endregion Raw Values

    public validateParameters (): any {
        const updatedParamNameDict = {};

        if (this.sltp.validate(
            this.sltpBasePriceGetter,
            this.sltpBasePriceGetter,
            this.getTradingData())) {
            updatedParamNameDict[OrderEditBaseUtils.SLTP_PARAM] = true;
        }

        updatedParamNameDict[OrderEditBaseUtils.POSITION_SIZING_PARAM] = true;

        return updatedParamNameDict;
    }

    // #region SLTP Price Comparer

    public sltpBasePriceGetter (): any {
        return this.limitPrice;
    }

    // #endregion SLTP Price Comparer

    public override getConfirmationText (): string {
        return OrderExecutorUtils.buildOrderEditConfirmationText(
            'Limit', // TODO.
            this.tif,
            this.account,
            this.instrument,
            this.quantity,
            this.buy(),
            [this.limitPrice],
            this.sltp.getConfirmationText(this.getTradingData()),
            null,
            this.productType,
            OrderType.Limit,
            null,
            this.getLeverageValue());
    }

    // TODO. UGLY. Refactor. details are at the top OrderEditBase
    public setSLTP (sltpHolder): void {
        const dp = this.sltp.createDynPropertyForRawSLTP(
            sltpHolder,
            this.getTradingData(),
            this.sltpBasePriceGetter,
            this.sltpBasePriceGetter);

        this.updateParameters(new OrderEditUpdateData({ sltp: dp }));
    }

    // Returns SlTpHolder instance.
    // TODO. UGLY. Refactor. details are at the top OrderEditBase
    public getRawSLTP (): any {
        return this.sltp.getRawValue(this.getTradingData());
    }

    // TODO. UGLY. Refactor. details are at the top OrderEditBase
    public setLimitPrice (price): void {
        const dp = this.createDynPropertyFromParameter(OrderEditBaseUtils.LIMIT_PRICE_PARAM);
        dp.value = price;

        this.updateParameters(new OrderEditUpdateData({ limitPrice: dp }));
    }

    // TODO. Refactor. Details are in OrderEditBase.
    public setBasePrice (price): void {
        const dp = this.createDynPropertyFromParameter(OrderEditBaseUtils.LIMIT_PRICE_PARAM);
        dp.value = price;

        this.updateParameters(new OrderEditUpdateData({ limitPrice: dp }));
    }

    // TODO. Refactor. Details are in OrderEditBase.
    public getBasePrice (): any {
        return this.limitPrice;
    }
}
