// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DirectAlgorithmMessageBase } from './DirectAlgorithmMessageBase';
import { Message } from '../Message';
import { AlgorithmDataRange } from '../../../Commons/cache/Algorithm/Algorithm';

export class DirectAlgorithmsAvailableMessage extends DirectAlgorithmMessageBase {
    constructor () {
        super(Message.CODE_PFIX_ALGORITHMS_AVAILABLE_MESSAGE);
    }

    // AlgorithmId = -1;           // 694 - creating in DirectAlgorithmMessageBase
    // AlgorithmName = null;       // 696 - creating in DirectAlgorithmMessageBase
    AlgorithmType: number | null = null; // 695 (int) 0 - HIGH_LOW, 1 - GAINERS_LOSERS, 3 - TOP_VOLUME
    AlgorithmStatus: number | null = null; // 697 (int) 0 - RUNNING, 1 - STOPPED, 2 - INACTIVE

    AlgorithmContainerType: number = -1; // 852 (int)  0 - NONE, 1 - COUNTRY, 2 - CUSTOM
    AlgorithmFilterName: string | null = null; // 853 (String) - если в 852=2, имя кастомного фильтра для фильтра в панели(Investing).

    InstrumentGroupIDs: [] | null = null; // 797 - список инструмент групп для фильтра в панели(.Net\MAC). Приходит только для  695 =  0 - HIGH_LOW
    RouteIDs: [] | null = null; // 678, список info routes для фильтра в панели (.Net\MAC). Приходит только для  695 =  0 - HIGH_LOW

    CountryId: number | null = null; // 725 (int) - если в 852=1, страна для которой создан алгоритм для фильтра в панели(Investing).

    AvailableRanges: AlgorithmDataRange[] = []; // data from ALGORITHM_HISTORICAL_GAINER_LOSER_TIME_RANGE_GROUP
}
