// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts";
import { EditPositionScreenTemplate } from "../../templates.js";
import { TerceraTabStripItem } from "../elements/TerceraTabStrip.js";
import { ScreensNames } from "../UtilsClasses/FactoryConstants.ts";
import { TerceraWindowBase } from "./TerceraWindowBase.js";
import { IsAllowed } from "../../Commons/IsAllowed.ts";
import { editPositionScreenHandler } from "../../Utils/AppHandlers.js";
import { DataCache } from "../../Commons/DataCache.ts";
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";


export let EditPositionScreen = TerceraWindowBase.extend({
    data: function ()
    {
        return {
            resizable: false,
            width: 340,
            dockablePanel: false,
            showHeader: true,
            showFooter: true,
            items: null,
            header: 'Edit position',
            position: null,
            isModifyTabActive: null,
            placedFrom: null
        };
    },

    template: EditPositionScreenTemplate,
    childScreen: null
});

EditPositionScreen.prototype.getType = function () { return 'EditPositionScreen' };

EditPositionScreen.prototype.oninit = function ()
{
    TerceraWindowBase.prototype.oninit.apply(this);

    Resources.onLocaleChange.Subscribe(this.localize, this)
}

EditPositionScreen.prototype.oncomplete = function ()
{
    TerceraWindowBase.prototype.oncomplete.apply(this);

    this.populateTabStrip()
    this.setInitialTab()
    this.Controls.tabStrip.onTabChange.Subscribe(this.selectTab, this);

    this.localize()
};

EditPositionScreen.prototype.localize = function ()
{
    this.set('header', Resources.getResource('screen.editPosition.header'))

    let items = this.get('items')
    if (items)
    {
        for (let i = 0; i < items.length; i++)
            items[i].text = Resources.getResource(items[i].data);

        this.set('items', items)
    }

    if (this.childScreen)
        this.childScreen.localize()
}

EditPositionScreen.prototype.dispose = function ()
{
    if (this.Controls.tabStrip)
        this.Controls.tabStrip.onTabChange.UnSubscribe(this.selectTab, this);

    Resources.onLocaleChange.UnSubscribe(this.localize, this)

    TerceraWindowBase.prototype.dispose.call(this)
}

EditPositionScreen.prototype.populateTabStrip = function ()
{
    let tabStrip = this.Controls.tabStrip,
        tabs = EditPositionScreen.tabHeaderKeys,
        items = []

    for (var i = 0; i < tabs.length; i++)
    {
        var newItem = new TerceraTabStripItem();
        newItem.text = Resources.getResource(tabs[i]);
        newItem.data = tabs[i];
        newItem.visible = true;
        newItem.enabled = this.isActionAllowed(tabs[i]);
        items.push(newItem);
    }

    this.set('items', items);

    tabStrip.updateView();
}

EditPositionScreen.prototype.selectTab = function (dontChangeTabType, numericLinkName, notSkipNextTime)
{
    let pos = this.get('position'),
        placedFrom = this.get('placedFrom'),
        isModify = !this.get('isModifyTabActive'),
        location = { x: this.get('left'), y: this.get('top') }

    if (dontChangeTabType === true)
        isModify = !isModify

    this.close()

    EditPositionScreen.show(pos.PositionId, placedFrom, numericLinkName, isModify, location, notSkipNextTime)
}

EditPositionScreen.prototype.setInitialTab = function ()
{
    let screenType = this.get('isModifyTabActive') ? ScreensNames.ModifyPositionScreen : ScreensNames.ClosePositionScreen

    let tabStrip = this.Controls.tabStrip,
        tabIndex = EditPositionScreen.tabScreenKeys.indexOf(screenType)

    tabStrip.selectItem(EditPositionScreen.tabHeaderKeys[tabIndex]);
}

EditPositionScreen.prototype.isActionAllowed = function (tab)
{
    let pos = this.get('position')

    if (tab == EditPositionScreen.tabHeaderKeys[0])
        return IsAllowed.IsPositionClosingAllowed(pos).Allowed
    else
        return IsAllowed.IsPositionModifyingAllowed(pos).Allowed
}

EditPositionScreen.show = function (positionID, placedFrom, numericLinkName, isModify, location, notSkipNextTime)
{
    let position = DataCache.getPositionById(positionID)

    let allowed = isModify ? IsAllowed.IsPositionModifyingAllowed(position).Allowed
        : IsAllowed.IsPositionClosingAllowed(position).Allowed

    if (!allowed)
        return

    let screen = EditPositionScreen.getScreenByPositionID(positionID)

    notSkipNextTime = notSkipNextTime || false

    if (screen)     // only one screen for one position 
    {
        screen.selectTab(isModify == screen.get('isModifyTabActive'), numericLinkName, screen.NotSkipNextTime)
        screen.setFocus()
        return
    }

    let parentScreen = MainWindowManager.Factory.addScreen(ScreensNames.EditPositionScreen);
    let childScreenType = isModify ? ScreensNames.ModifyPositionScreen : ScreensNames.ClosePositionScreen;
    let childScreen = MainWindowManager.Factory.addScreen(childScreenType);

    childScreen.set({
        position: position,
        placedFrom: placedFrom
    })

    if (numericLinkName)
        childScreen.activateNumeric(numericLinkName)

    parentScreen.set({
        position: position,
        placedFrom: placedFrom,
        isModifyTabActive: isModify
    })
    parentScreen.addControl(childScreen)
    parentScreen.childScreen = childScreen

    if (location)
        parentScreen.setLocation(location.x, location.y)
    else
        parentScreen.center()

    parentScreen.NotSkipNextTime = notSkipNextTime              // TODO костыль ебаный
}

EditPositionScreen.getScreenByPositionID = function (positionID)
{
    let keys = Object.keys(MainWindowManager.MainWindow.Controls),
        EditPositionScreenType = EditPositionScreen.prototype.getType()

    for (let i = keys.length - 1; i >= 0; i--)
    {
        let control = MainWindowManager.MainWindow.Controls[keys[i]]

        if (control.getType() == EditPositionScreenType)
        {
            let pos = control.get('position')

            if (pos && pos.PositionId == positionID)
                return control
        }
    }

    return null
}

EditPositionScreen.tabHeaderKeys = ["screen.closePosition.ok", "screen.modifyOrder.modify"]
EditPositionScreen.tabScreenKeys = [ScreensNames.ClosePositionScreen, ScreensNames.ModifyPositionScreen]

let handl = editPositionScreenHandler;
handl.show = EditPositionScreen.show;