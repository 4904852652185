// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

export enum PortfolioRequestType // описание: https://docs.google.com/document/d/1eD8P5Ck4ZN6zmikSFYK3YpyAUdF09pR6w6hqS84y6aY
{
    PORTFOLIO_GROWTH = 0,
    DAILY_PORTFOLIO_RETURN = 1,
    MONTHLY_PORTFOLIO_RETURN = 2,
    MONTHLY_STATISTICS = 3,
    DAILY_INSTRUMENT_RETURN = 4,
    DAILY_CASH_RETURN = 5,
    MONTHLY_INSTRUMENT_RETURN = 6,
    MONTHLY_CASH_RETURN = 7,
    DRAWDOWNS = 8,
    MAX_DRAWDOWN = 9,
    MONTHLY_BEST_WORST_INSTRUMENT_RETURN = 10,
    MONTHLY_BEST_WORST_CASH_RETURN = 11,
    INVESTED_AMOUNT = 12,
    PORTFOLIO_ALLOCATION = 13, // нельзя использовать в запросе, это только описание констант для “реалтайм” данных
    TOTAL_RETURN = 14 // нельзя использовать в запросе, это только описание констант для “реалтайм” данных
}
