// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

export class HighLowData {
    public InstrumentName: string;
    public TradableId: number;
    public InstrumentId: number;
    public InstrumentGroupId: number;
    public TradingExchange: string;
    public RouteId: number;
    public High: number;
    public Low: number;
    public InterestChange: number;
    public AbsoluteChange: number;
    public LastPrice: number;
    public LastSize: number;
    public LastTradeDate: Date;
    public IsDelayed: boolean;
    public EventType: HighLowEventType;
    public MarketDataExchange: string;
    public Volume: number;

    constructor (type: HighLowEventType) {
        this.EventType = type;
    }
}

export const IsHigh = (type: HighLowEventType): boolean => {
    return type === HighLowEventType.TouchHigh || type === HighLowEventType.BreakingHigh;
};

export enum HighLowEventType { TouchHigh = 1, TouchLow = 2, BreakingHigh = 3, BreakingLow = 4 }
