// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type OptionContract } from '../Instruments/OptionContract';
import { type StrikePriceSettings } from '../Instruments/StrikePriceSettings';
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectNonFixedInstumentStrikesResponseMessage extends DirectMessageBaseClass {
    public instrID: any;
    public instrName: any;
    public strikePricesList: StrikePriceSettings[];
    public optionContractGroup: OptionContract[];
    public PointSize: any;
    // когда имя фьючерса (и каждого страйка) может прис
    public FutureAliasName: string | null = null;
    public useAliasName = false;

    constructor () {
        super(Message.CODE_INSTRUMENT_STRIKES_RESPONSE);
    }
}
