import { type TerceraChartXYWindow } from '../../Windows/TerceraChartXYWindow';
import { type IPointsConverter } from './IPointsConverter';

export class XYPointsConverter implements IPointsConverter {
    private readonly window: TerceraChartXYWindow;
    constructor (window: TerceraChartXYWindow) {
        this.window = window;
    }

    public GetDataX (screenX: number): number {
        if (this.window.XScale === 0) {
            return 0;
        }
        return this.window.FminFloatX + (screenX - this.window.ClientRectangle.X) / this.window.XScale;
    }

    public GetDataY (screenY: number): number {
        if (this.window.YScale === 0) {
            return 0;
        }
        return (this.window.ClientRectangle.Bottom() - screenY) / this.window.YScale + this.window.FminFloatY;
    }

    public GetScreenX (dataX: number): number {
        return this.window.ClientRectangle.X + (dataX - this.window.FminFloatX) * this.window.XScale;
    }

    public GetScreenY (dataY: number): number {
        return this.window.ClientRectangle.Bottom() - (dataY - this.window.FminFloatY) * this.window.YScale;
    }

    public GetDataIndexByX (screenX: number): number {
        return -1;
    }
}
