import { ColumnParams } from './ColumnParams';
import { QuickTableColumnType, QuickTableEditingInfo } from '../elements/QuickTable/QuickTableMisc';
import { DataCache } from '../../Commons/DataCache';
import { RulesSet } from '../../Utils/Rules/RulesSet';
import { RiskPlan } from '../../Commons/cache/RiskPlan';
import { SavedOrderItemBase } from './SavedOrderItemsBase';
import { Quantity } from '../../Utils/Trading/Quantity';
import { OrderUtils } from '../../Utils/Trading/OrderUtils';
import { GeneralSettings } from '../../Utils/GeneralSettings/GeneralSettings';
import { OrderType } from '../../Utils/Trading/OrderType';
import { OffsetModeViewEnum } from '../../Utils/Trading/OffsetModeViewEnum';
import { Resources } from '../../Commons/properties/Resources';
import { DynProperty } from '../../Commons/DynProperty';
import { LinkedPriceType } from '../../Commons/cache/SavedOrders/SavedOrderEnums';
import { type OptionTrader } from '../../Commons/cache/OptionMaster/OptionTrader/OptionTrader';
import { type SessionSettings } from '../../Commons/SessionSettings';
import { GreeksFormatter } from '../../Commons/cache/OptionMaster/OptionTrader/GreeksFormatter';
import Decimal from 'decimal.js';
import { type PaperPosition } from '../../Commons/cache/OptionMaster/OptionTrader/OptionPaperPosition/PaperPosition';
import { OperationType } from '../../Utils/Trading/OperationType';
import { OptionPutCall } from '../../Utils/Instruments/OptionPutCall';
import { OptionTraderUtils } from '../../Commons/cache/OptionMaster/OptionTrader/OptionTraderUtils';
import { ColumnData } from './BaseItem';
import { type Instrument } from '../../Commons/cache/Instrument';

export class PaperPositionItem extends SavedOrderItemBase<PaperPosition> {
    private readonly _optionTrader: OptionTrader;

    constructor (optionTrader: OptionTrader, paperPosition: PaperPosition, sessionSettings: typeof SessionSettings) {
        super(paperPosition, sessionSettings);
        this._optionTrader = optionTrader;
    }

    protected override updateAmountNumeric (index: number): void {
        super.updateAmountNumeric(index);
        this.QuickTableEditingInfoMap[index].Min = -this.QuickTableEditingInfoMap[index].Max;
    }

    public initEditingInfo (): void {
        const strikePriceCombobox = new QuickTableEditingInfo(DynProperty.COMBOBOX);
        strikePriceCombobox.GetDataHandler = this.getStrikePriceComboboxItems.bind(this);
        this.QuickTableEditingInfoMap[PaperPositionItem.STRIKE_COL_INDEX] = strikePriceCombobox;

        const orderTypeCombobox = new QuickTableEditingInfo(DynProperty.COMBOBOX);
        orderTypeCombobox.GetDataHandler = this.getOrderTypeComboboxItems;
        this.QuickTableEditingInfoMap[PaperPositionItem.ORDER_TYPE_COL_INDEX] = orderTypeCombobox;

        const tifCombobox = new QuickTableEditingInfo(DynProperty.COMBOBOX_COMBOITEM_TIF);
        tifCombobox.GetDataHandler = this.getTifComboboxItems;
        this.QuickTableEditingInfoMap[PaperPositionItem.TIF_COL_INDEX] = tifCombobox;

        const productTypeCombobox = new QuickTableEditingInfo(DynProperty.COMBOBOX);
        productTypeCombobox.GetDataHandler = this.getProductTypeComboboxItems;
        this.QuickTableEditingInfoMap[PaperPositionItem.PRODUCT_TYPE_COL_INDEX] = productTypeCombobox;

        const linkedPriceTypeCombobox = new QuickTableEditingInfo(DynProperty.COMBOBOX);
        linkedPriceTypeCombobox.GetDataHandler = this.getLinkedPriceTypeComboboxItems;
        this.QuickTableEditingInfoMap[PaperPositionItem.LINK_COL_INDEX] = linkedPriceTypeCombobox;

        this.QuickTableEditingInfoMap[PaperPositionItem.QUANTITY_COL_INDEX] = new QuickTableEditingInfo(DynProperty.DOUBLE);

        this.QuickTableEditingInfoMap[PaperPositionItem.PRICE_COL_INDEX] = new QuickTableEditingInfo(DynProperty.DOUBLE);
        this.QuickTableEditingInfoMap[PaperPositionItem.STOP_PRICE_COL_INDEX] = new QuickTableEditingInfo(DynProperty.DOUBLE);
        this.QuickTableEditingInfoMap[PaperPositionItem.SL_PRICE_COL_INDEX] = new QuickTableEditingInfo(DynProperty.DOUBLE);
        this.QuickTableEditingInfoMap[PaperPositionItem.SLL_PRICE_COL_INDEX] = new QuickTableEditingInfo(DynProperty.DOUBLE);
        this.QuickTableEditingInfoMap[PaperPositionItem.TP_PRICE_COL_INDEX] = new QuickTableEditingInfo(DynProperty.DOUBLE);

        this.QuickTableEditingInfoMap[PaperPositionItem.LINKED_PRICE_OFFSET_COL_INDEX] = new QuickTableEditingInfo(DynProperty.DOUBLE);
        this.QuickTableEditingInfoMap[PaperPositionItem.TRAILING_STOP_COL_INDEX] = new QuickTableEditingInfo(DynProperty.BOOLEAN);
        this.QuickTableEditingInfoMap[PaperPositionItem.SL_OFFSET_COL_INDEX] = new QuickTableEditingInfo(DynProperty.DOUBLE);
        this.QuickTableEditingInfoMap[PaperPositionItem.SLL_OFFSET_COL_INDEX] = new QuickTableEditingInfo(DynProperty.DOUBLE);
        this.QuickTableEditingInfoMap[PaperPositionItem.TP_OFFSET_COL_INDEX] = new QuickTableEditingInfo(DynProperty.DOUBLE);

        const leverageCombobox = new QuickTableEditingInfo(DynProperty.COMBOBOX);
        leverageCombobox.GetDataHandler = this.getLeverageComboboxItems;
        this.QuickTableEditingInfoMap[PaperPositionItem.LEVERAGE_COL_INDEX] = leverageCombobox;

        this.QuickTableEditingInfoMap[PaperPositionItem.ANALYSE_COL_INDEX] = new QuickTableEditingInfo(DynProperty.BOOLEAN);
        this.QuickTableEditingInfoMap[PaperPositionItem.SEND_COL_INDEX] = new QuickTableEditingInfo(DynProperty.BOOLEAN);
        this.QuickTableEditingInfoMap[PaperPositionItem.REMOVE_COL_INDEX] = new QuickTableEditingInfo(DynProperty.CLOSE_BUTTON);
    }

    public updateEditingInfo (): void {
        this.updatePriceNumeric(PaperPositionItem.PRICE_COL_INDEX);
        if (this.savedOrder.OrderType === OrderType.TrailingStop) {
            this.updateOffsetNumeric(PaperPositionItem.STOP_PRICE_COL_INDEX, false, true);
        } else {
            this.updatePriceNumeric(PaperPositionItem.STOP_PRICE_COL_INDEX);
        }
        this.updatePriceNumeric(PaperPositionItem.SL_PRICE_COL_INDEX, 0);
        this.updatePriceNumeric(PaperPositionItem.SLL_PRICE_COL_INDEX, 0);
        this.updatePriceNumeric(PaperPositionItem.TP_PRICE_COL_INDEX, 0);

        this.updateOffsetNumeric(PaperPositionItem.LINKED_PRICE_OFFSET_COL_INDEX, true);
        this.updateOffsetNumeric(PaperPositionItem.SL_OFFSET_COL_INDEX, false, false, 0);
        this.updateOffsetNumeric(PaperPositionItem.SLL_OFFSET_COL_INDEX, true, false, 0);
        this.updateOffsetNumeric(PaperPositionItem.TP_OFFSET_COL_INDEX, false, false, 0);

        this.updateAmountNumeric(PaperPositionItem.QUANTITY_COL_INDEX);
    }

    private getStrikePriceComboboxItems (): Array<{ text: string, value: Instrument }> {
        const items = [];
        const instrument = this.savedOrder.Instrument;
        if (!instrument.isOptionSymbol) {
            return items;
        }
        const options = this._optionTrader.options;
        for (let i = 0; i < options.length; i++) {
            const option = options[i];
            if (!isNullOrUndefined(option.put)) {
                items.push({ text: `${option.strikePrice} P`, value: option.put });
            }
            if (!isNullOrUndefined(option.call)) {
                items.push({ text: `${option.strikePrice} C`, value: option.call });
            }
        }
        return items;
    }

    public ColumnCount (): number {
        return PaperPositionItem.columnsParams.length;
    }

    public GetColumnParams (index: number): any {
        const columnParams = PaperPositionItem.columnsParams[index];
        switch (index) {
        case PaperPositionItem.TRAILING_STOP_COL_INDEX:
            columnParams.Hidden = !DataCache.isAllowedForMyUser(RulesSet.FUNCTION_TRAILING_STOP);
            break;
        case PaperPositionItem.PRODUCT_TYPE_COL_INDEX:
            columnParams.Hidden = !RiskPlan.showProductType;
            break;
        }
        return columnParams;
    }

    public getColumnData (index: number): ColumnData {
        const value = this.getColumnValue(index);
        let formattedValue: string = '';
        switch (index) {
        case PaperPositionItem.OPERATION_COL_INDEX:
            formattedValue = this.savedOrder.OperationStr;
            break;
        case PaperPositionItem.QUANTITY_COL_INDEX:
            {
                const amount = this.savedOrder.formatAmount(Math.abs(value));
                formattedValue = `${this.savedOrder.Operation === OperationType.Buy ? '+' : '-'}${amount}`;
            }
            break;
        case PaperPositionItem.ORDER_TYPE_COL_INDEX:
            formattedValue = this.savedOrder.OrderTypeStr;
            break;
        case PaperPositionItem.PRODUCT_TYPE_COL_INDEX:
            formattedValue = this.savedOrder.ProductTypeStr;
            break;
        case PaperPositionItem.LINKED_PRICE_OFFSET_COL_INDEX:
            if ((this.savedOrder.OrderType === OrderType.Limit || this.savedOrder.OrderType === OrderType.Stop) && this.savedOrder.LinkedPriceType !== LinkedPriceType.None) {
                formattedValue = this.savedOrder.formatOffset(this.savedOrder.LinkedPriceOffset);
            } else {
                formattedValue = '';
            }
            break;
        case PaperPositionItem.PRICE_COL_INDEX:
        case PaperPositionItem.CURRENT_PRICE_COL_INDEX:
        case PaperPositionItem.SL_PRICE_COL_INDEX:
        case PaperPositionItem.TP_PRICE_COL_INDEX:
        case PaperPositionItem.SLL_PRICE_COL_INDEX:
            formattedValue = this.savedOrder.formatPrice(value);
            break;
        case PaperPositionItem.LINK_COL_INDEX:
            if (this.savedOrder.OrderType === OrderType.Limit || this.savedOrder.OrderType === OrderType.Stop) {
                formattedValue = SavedOrderItemBase.LocalizeLinkPriceType(this.savedOrder.LinkedPriceType);
            } else {
                formattedValue = '';
            }
            break;
        case PaperPositionItem.STOP_PRICE_COL_INDEX:
            if (this.savedOrder.OrderType === OrderType.TrailingStop) {
                formattedValue = `${this.savedOrder.formatOffset(this.savedOrder.StopPrice)} (${Resources.getResource('panel.savedOrders.Trailing')})`;
            } else {
                formattedValue = this.savedOrder.formatPrice(this.savedOrder.StopPrice);
            }
            break;
        case PaperPositionItem.SL_OFFSET_COL_INDEX:
            formattedValue = this.savedOrder.formatOffset(this.savedOrder.SlOffset);
            break;
        case PaperPositionItem.TP_OFFSET_COL_INDEX:
            formattedValue = this.savedOrder.formatOffset(this.savedOrder.TpOffset);
            break;

        case PaperPositionItem.DELTA_COL_INDEX:
        case PaperPositionItem.GAMMA_COL_INDEX:
        case PaperPositionItem.VEGA_COL_INDEX:
        case PaperPositionItem.THETA_COL_INDEX:
        case PaperPositionItem.RHO_COL_INDEX:
            formattedValue = GreeksFormatter.formatGreek(value);
            break;
        case PaperPositionItem.IV_COL_INDEX:
            formattedValue = GreeksFormatter.formatIVPercent(value);
            break;
        case PaperPositionItem.FEE_COL_INDEX:
            formattedValue = value <= 0 ? '' : this.savedOrder.formatPrice(value);
            break;
        case PaperPositionItem.UNDERLIER_DATE_COL_INDEX:
            formattedValue = isNullOrUndefined(value) ? '' : (value as Date).toLocaleDateString();
            break;
        case PaperPositionItem.SLL_OFFSET_COL_INDEX:
            formattedValue = this.savedOrder.formatOffset(this.savedOrder.SllOffset);
            break;
        case PaperPositionItem.LEVERAGE_COL_INDEX:
            formattedValue = this.savedOrder.LegerageStr;
            break;

        default:
            formattedValue = value.toString();
            break;
        }

        return new ColumnData(value, formattedValue);
    }

    public getColumnValue (index: number): any {
        switch (index) {
        case PaperPositionItem.OPERATION_COL_INDEX:
            return this.savedOrder.Operation;
        case PaperPositionItem.SYMBOL_COL_IDNEX:
            return this.savedOrder.InstrumentStr;
        case PaperPositionItem.STRIKE_COL_INDEX:
            return this.savedOrder.StrikePriceStr;
        case PaperPositionItem.QUANTITY_COL_INDEX:
        {
            const amount = Quantity.convertLotsToAmount(this.savedOrder.QuantityLots, this.savedOrder.Instrument);
            return this.savedOrder.Operation === OperationType.Buy ? amount : -amount;
        }
        case PaperPositionItem.ORDER_TYPE_COL_INDEX:
            return this.savedOrder.OrderType;
        case PaperPositionItem.PRODUCT_TYPE_COL_INDEX:
            return this.savedOrder.ProductType;
        case PaperPositionItem.LINKED_PRICE_OFFSET_COL_INDEX:
            if (!isNullOrUndefined(this.savedOrder.Instrument)) {
                return OrderUtils.ConvertTickOffset(this.savedOrder.Instrument,
                    GeneralSettings.TradingDefaults.ShowOffsetIn,
                    this.savedOrder.Instrument.GetMarketPrice(this.savedOrder.Account, this.savedOrder.Operation),
                    this.savedOrder.LinkedPriceOffset,
                    true);
            } else {
                return this.savedOrder.LinkedPriceOffset;
            }
        case PaperPositionItem.PRICE_COL_INDEX:
            return this.savedOrder.Price;
        case PaperPositionItem.LINK_COL_INDEX:
            return this.savedOrder.LinkedPriceType;
        case PaperPositionItem.STOP_PRICE_COL_INDEX:
            if (this.savedOrder.OrderType === OrderType.TrailingStop && !isNullOrUndefined(this.savedOrder.Instrument)) {
                return OrderUtils.ConvertTickOffset(this.savedOrder.Instrument,
                    GeneralSettings.TradingDefaults.ShowOffsetIn,
                    this.savedOrder.Instrument.GetMarketPrice(this.savedOrder.Account, this.savedOrder.Operation),
                    this.savedOrder.StopPrice);
            } else {
                return this.savedOrder.StopPrice;
            }
        case PaperPositionItem.CURRENT_PRICE_COL_INDEX:
            return this.savedOrder.CurrentClosePrice;
        case PaperPositionItem.TIF_COL_INDEX:
            return this.savedOrder.TifStr;
        case PaperPositionItem.SL_OFFSET_COL_INDEX:
            if (!isNullOrUndefined(this.savedOrder.Instrument)) {
                return OrderUtils.ConvertTickOffset(this.savedOrder.Instrument,
                    GeneralSettings.TradingDefaults.ShowOffsetIn,
                    this.savedOrder.Instrument.GetMarketPrice(this.savedOrder.Account, this.savedOrder.Operation),
                    this.savedOrder.SlOffset);
            } else {
                return this.savedOrder.SlOffset;
            }
        case PaperPositionItem.SL_PRICE_COL_INDEX:
            return this.savedOrder.SlPrice;
        case PaperPositionItem.TP_OFFSET_COL_INDEX:
            if (!isNullOrUndefined(this.savedOrder.Instrument)) {
                return OrderUtils.ConvertTickOffset(this.savedOrder.Instrument,
                    GeneralSettings.TradingDefaults.ShowOffsetIn,
                    this.savedOrder.Instrument.GetMarketPrice(this.savedOrder.Account, this.savedOrder.Operation),
                    this.savedOrder.TpOffset);
            } else {
                return this.savedOrder.TpOffset;
            }
        case PaperPositionItem.TP_PRICE_COL_INDEX:
            return this.savedOrder.TpPrice;
        case PaperPositionItem.DELTA_COL_INDEX:
            if (this.savedOrder.Instrument.PutCall !== OptionPutCall.OPTION_NONE) {
                return new Decimal(this._optionTrader.getGreeks(this.savedOrder.Instrument.PutCall, this.savedOrder.Instrument, this.savedOrder.Price).delta).mul(this.savedOrder.QuantityLots).toNumber();
            } else {
                return NaN;
            }
        case PaperPositionItem.TRAILING_STOP_COL_INDEX:
            return this.savedOrder.SLTPHolder.isTrailingStop();
        case PaperPositionItem.GAMMA_COL_INDEX:
            if (this.savedOrder.Instrument.PutCall !== OptionPutCall.OPTION_NONE) {
                return new Decimal(this._optionTrader.getGreeks(this.savedOrder.Instrument.PutCall, this.savedOrder.Instrument, this.savedOrder.Price).gamma).mul(this.savedOrder.QuantityLots).toNumber();
            } else {
                return NaN;
            }
        case PaperPositionItem.VEGA_COL_INDEX:
            if (this.savedOrder.Instrument.PutCall !== OptionPutCall.OPTION_NONE) {
                return new Decimal(this._optionTrader.getGreeks(this.savedOrder.Instrument.PutCall, this.savedOrder.Instrument, this.savedOrder.Price).vega).mul(this.savedOrder.QuantityLots).toNumber();
            } else {
                return NaN;
            }
        case PaperPositionItem.THETA_COL_INDEX:
            if (this.savedOrder.Instrument.PutCall !== OptionPutCall.OPTION_NONE) {
                return new Decimal(this._optionTrader.getGreeks(this.savedOrder.Instrument.PutCall, this.savedOrder.Instrument, this.savedOrder.Price).theta).mul(this.savedOrder.QuantityLots).toNumber();
            } else {
                return NaN;
            }
        case PaperPositionItem.IV_COL_INDEX:
            if (this.savedOrder.Instrument.PutCall !== OptionPutCall.OPTION_NONE) {
                return new Decimal(this._optionTrader.getGreeks(this.savedOrder.Instrument.PutCall, this.savedOrder.Instrument, this.savedOrder.Price).iv).mul(this.savedOrder.QuantityLots).toNumber();
            } else {
                return NaN;
            }
        case PaperPositionItem.FEE_COL_INDEX:
            return this.savedOrder.Fee;
        case PaperPositionItem.UNDERLIER_DATE_COL_INDEX:
            return !isNullOrUndefined(this._optionTrader.underlier) && this._optionTrader.underlier.isFuturesSymbol ? this._optionTrader.underlier.ExpDate : undefined;
        case PaperPositionItem.TYPE_COL_INDEX:
            return this.savedOrder.PaperPositionType;
        case PaperPositionItem.DAYS_TO_EXPIRE_COL_INDEX:
            return OptionTraderUtils.getDaysToExpirationForInstrument(this.savedOrder.Instrument);
        case PaperPositionItem.DATE_COL_INDEX:
            return this.savedOrder.ExpirityDateStr;
        case PaperPositionItem.RHO_COL_INDEX:
            if (this.savedOrder.Instrument.PutCall !== OptionPutCall.OPTION_NONE) {
                return new Decimal(this._optionTrader.getGreeks(this.savedOrder.Instrument.PutCall, this.savedOrder.Instrument, this.savedOrder.Price).rho).mul(this.savedOrder.QuantityLots).toNumber();
            } else {
                return NaN;
            }
        case PaperPositionItem.SLL_OFFSET_COL_INDEX:
            if (!isNullOrUndefined(this.savedOrder.Instrument)) {
                return OrderUtils.ConvertTickOffset(this.savedOrder.Instrument,
                    this.savedOrder.SLTPHolder.isTrailingStop() && !GeneralSettings.TradingDefaults.SetSlTpValuesInOffset ? OffsetModeViewEnum.Ticks : GeneralSettings.TradingDefaults.ShowOffsetIn,
                    this.savedOrder.Instrument.GetMarketPrice(this.savedOrder.Account, this.savedOrder.Operation),
                    this.savedOrder.SllOffset,
                    true);
            } else {
                return this.savedOrder.SllOffset;
            }
        case PaperPositionItem.SLL_PRICE_COL_INDEX:
            return this.savedOrder.SllPrice;
        case PaperPositionItem.LEVERAGE_COL_INDEX:
            return this.savedOrder.Leverage;
        case PaperPositionItem.ANALYSE_COL_INDEX:
            return this.savedOrder.Analyse;
        case PaperPositionItem.SEND_COL_INDEX:
            return this.savedOrder.Active;

        default:
            return '';
        }
    }

    static readonly OPERATION_COL_INDEX = 0;
    static readonly SYMBOL_COL_IDNEX = 1;
    static readonly STRIKE_COL_INDEX = 2;
    static readonly QUANTITY_COL_INDEX = 3;
    static readonly ORDER_TYPE_COL_INDEX = 4;
    static readonly PRODUCT_TYPE_COL_INDEX = 5;
    static readonly LINKED_PRICE_OFFSET_COL_INDEX = 6;
    static readonly PRICE_COL_INDEX = 7;
    static readonly LINK_COL_INDEX = 8;
    static readonly STOP_PRICE_COL_INDEX = 9;
    static readonly CURRENT_PRICE_COL_INDEX = 10;
    static readonly TIF_COL_INDEX = 11;
    static readonly SL_OFFSET_COL_INDEX = 12;
    static readonly SL_PRICE_COL_INDEX = 13;
    static readonly TP_OFFSET_COL_INDEX = 14;
    static readonly TP_PRICE_COL_INDEX = 15;
    static readonly DELTA_COL_INDEX = 16;
    static readonly TRAILING_STOP_COL_INDEX = 17;
    static readonly GAMMA_COL_INDEX = 18;
    static readonly VEGA_COL_INDEX = 19;
    static readonly THETA_COL_INDEX = 20;
    static readonly IV_COL_INDEX = 21;
    static readonly FEE_COL_INDEX = 22;
    static readonly UNDERLIER_DATE_COL_INDEX = 23;
    static readonly TYPE_COL_INDEX = 24;
    static readonly DAYS_TO_EXPIRE_COL_INDEX = 25;
    static readonly DATE_COL_INDEX = 26;
    static readonly RHO_COL_INDEX = 27;
    static readonly SLL_OFFSET_COL_INDEX = 28;
    static readonly SLL_PRICE_COL_INDEX = 29;
    static readonly LEVERAGE_COL_INDEX = 30;
    static readonly ANALYSE_COL_INDEX = 31;
    static readonly SEND_COL_INDEX = 32;
    static readonly REMOVE_COL_INDEX = 33;

    static columnsParams: ColumnParams[] =
        [
            new ColumnParams('panel.optionPaperPositions.Side', 40, QuickTableColumnType.COL_DEFAULT, false, true, true),
            new ColumnParams('panel.optionPaperPositions.Symbol', 40, QuickTableColumnType.SYMBOL, false, true, true),
            new ColumnParams('panel.optionPaperPositions.Strike', 40, QuickTableColumnType.COL_DEFAULT, false, true, true),
            new ColumnParams('panel.optionPaperPositions.Quantity', 40, QuickTableColumnType.QUANTITY, false, true, true),
            new ColumnParams('panel.optionPaperPositions.OrderType', 40, QuickTableColumnType.COL_DEFAULT, false, true, false),
            new ColumnParams('panel.optionPaperPositions.ProductType', 40, QuickTableColumnType.COL_DEFAULT, false, false, false),
            new ColumnParams('panel.optionPaperPositions.Offset', 40, QuickTableColumnType.COL_DEFAULT, false, false, false),
            new ColumnParams('panel.optionPaperPositions.Price', 40, QuickTableColumnType.COL_DEFAULT, false, true, false),
            new ColumnParams('panel.optionPaperPositions.Link', 40, QuickTableColumnType.COL_DEFAULT, false, false, false),
            new ColumnParams('panel.optionPaperPositions.StopPrice', 60, QuickTableColumnType.COL_DEFAULT, false, true, false),
            new ColumnParams('panel.optionPaperPositions.CurrentPrice', 40, QuickTableColumnType.COL_DEFAULT, false, true, false),
            new ColumnParams('panel.optionPaperPositions.TIF', 40, QuickTableColumnType.COL_DEFAULT, false, true, false),
            new ColumnParams('panel.optionPaperPositions.SL_Offset', 40, QuickTableColumnType.COL_DEFAULT, false, false, false),
            new ColumnParams('panel.optionPaperPositions.SL_Price', 70, QuickTableColumnType.COL_DEFAULT, false, false, false),
            new ColumnParams('panel.optionPaperPositions.TP_Offset', 40, QuickTableColumnType.COL_DEFAULT, false, false, false),
            new ColumnParams('panel.optionPaperPositions.TP_Price', 70, QuickTableColumnType.COL_DEFAULT, false, false, false),
            new ColumnParams('panel.optionPaperPositions.Delta', 40, QuickTableColumnType.COL_DEFAULT, true, true, false),
            new ColumnParams('panel.optionPaperPositions.TrailingStop', 70, QuickTableColumnType.COL_DEFAULT, false, false, false),
            new ColumnParams('panel.optionPaperPositions.Gamma', 40, QuickTableColumnType.COL_DEFAULT, true, true, false),
            new ColumnParams('panel.optionPaperPositions.Vega', 40, QuickTableColumnType.COL_DEFAULT, true, true, false),
            new ColumnParams('panel.optionPaperPositions.Theta', 40, QuickTableColumnType.COL_DEFAULT, true, true, false),
            new ColumnParams('panel.optionPaperPositions.IV', 40, QuickTableColumnType.COL_DEFAULT, false, true, false),
            new ColumnParams('panel.optionPaperPositions.Fee', 40, QuickTableColumnType.COL_DEFAULT, false, false, false),
            new ColumnParams('panel.optionPaperPositions.UnderlierDate', 40, QuickTableColumnType.COL_DEFAULT, false, true, false),
            new ColumnParams('panel.optionPaperPositions.Type', 40, QuickTableColumnType.COL_DEFAULT, false, true, false),
            new ColumnParams('panel.optionPaperPositions.DaysToExpire', 40, QuickTableColumnType.COL_DEFAULT, false, false, false),
            new ColumnParams('panel.optionPaperPositions.Date', 40, QuickTableColumnType.COL_DEFAULT, false, true, true),
            new ColumnParams('panel.optionPaperPositions.Rho', 40, QuickTableColumnType.COL_DEFAULT, true, false, false),
            new ColumnParams('panel.optionPaperPositions.SLL_Offset', 40, QuickTableColumnType.COL_DEFAULT, false, false, false),
            new ColumnParams('panel.optionPaperPositions.SLL_Price', 70, QuickTableColumnType.COL_DEFAULT, false, false, false),
            new ColumnParams('panel.optionPaperPositions.Leverage', 40, QuickTableColumnType.COL_DEFAULT, false, false, true),
            new ColumnParams('panel.optionPaperPositions.Analyse', 40, QuickTableColumnType.COL_DEFAULT, false, true, false),
            new ColumnParams('panel.optionPaperPositions.Send', 40, QuickTableColumnType.COL_DEFAULT, false, true, false),
            new ColumnParams('panel.optionPaperPositions.Remove', 40, QuickTableColumnType.COL_DEFAULT, false, true, false)
        ];
}
