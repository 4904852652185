// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts";
import { TerceraBaseWindowTemplate, TerceraClosePositionBodyTemplate, PositionExerciseRequestScreenFooterTemplate } from "../../templates.js";
import { Control } from "../elements/Control.js";
import { ScreensNames } from "../UtilsClasses/FactoryConstants.ts";
import { TerceraClosePositionScreen } from "../screen/TerceraClosePositionScreen.js";
import { TerceraWindowBase } from "./TerceraWindowBase.js";
import { positionExerciseRequestScreenHandler } from "../../Utils/AppHandlers.js";
import { DataCache } from "../../Commons/DataCache.ts";
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";
import { PositionExerciseOptionValidator } from "../../Commons/UtilsClasses/PositionExerciseOptionValidator.ts";


export let PositionExerciseRequestScreen = TerceraClosePositionScreen.extend({
    data: function ()
    {
        return {
            width: 340,
            position: null,
            movable: true,
            showHeader: true,
            showBorder: true,
            placedFrom: null
        };
    },
    template: TerceraBaseWindowTemplate,
    partials: {
        bodyPartial: TerceraClosePositionBodyTemplate,
        footerPartial: PositionExerciseRequestScreenFooterTemplate
    }
});

PositionExerciseRequestScreen.prototype.getType = function () { return ScreensNames.PositionExerciseRequestScreen };

PositionExerciseRequestScreen.show = function (position, placedFrom) 
{
    var screen = MainWindowManager.Factory.addScreen(ScreensNames.PositionExerciseRequestScreen);

    screen.set('position', position)
    screen.set('placedFrom', placedFrom);

    screen.center()
};

PositionExerciseRequestScreen.prototype.oncomplete = function ()
{
    // TerceraClosePositionScreen.prototype.oncomplete.apply(this); // переопределим complete без вызова базового чтоб не создавать лишние контролы

    this.FPosition = null;
    TerceraWindowBase.prototype.oncomplete.apply(this);
    this.onrender();

    this.on('exerciseBtnClick', this.exerciseBtnClick);
    this.on('cancelBtnClick', this.cancelBtnClick);

    var position = this.get("position");
    this.fillByPosition(position);
    this.FPosition = position;
    this.exerciseValidator = new PositionExerciseOptionValidator(position);
    Control.Ticker.Subscribe(this.TickAsync, this);

    this.set({ 
        partialCloseAllowed: false, // скрытие нумерика и лейбла Quantity to close
        exerciseBtnEnabled: true,
        exerciseBtnTooltip: ''
    });            
};

PositionExerciseRequestScreen.prototype.oninit = function ()
{
    TerceraClosePositionScreen.prototype.oninit.apply(this);

    this.localize();
};

PositionExerciseRequestScreen.prototype.exerciseBtnClick = function ()
{
    let pos = this.get('position'),
        placedFrom = this.get('placedFrom');

    if (pos)
        DataCache.ExerciseOptionsByPosition(pos, placedFrom);

    this.close();
};

PositionExerciseRequestScreen.prototype.cancelBtnClick = function ()
{
    this.close();
};

PositionExerciseRequestScreen.prototype.localize = function ()
{
    TerceraClosePositionScreen.prototype.localize.apply(this);

    this.set({
        header: Resources.getResource('screen.PositionExerciseRequestScreen.title'),
        exerciseBtnText: Resources.getResource('screen.PositionExerciseRequestScreen.exercise'),
        cancelBtnText: Resources.getResource('screen.closePosition.cancel')
    });

    this.exerciseError = Resources.getResource('screen.PositionExerciseRequestScreen.exerciseError');
};

PositionExerciseRequestScreen.prototype.TickAsync = function ()
{
    TerceraClosePositionScreen.prototype.TickAsync.apply(this);
    const isExerciseBtnEnabled = this.get("exerciseBtnEnabled");
    const isAllowExercise = this.exerciseValidator.isAllowExercise();
    if (isExerciseBtnEnabled !== isAllowExercise)
    {
        const exercisetooltip = isAllowExercise ? '' : this.exerciseError;
        this.set({
            exerciseBtnEnabled: isAllowExercise,
            exerciseBtnTooltip: exercisetooltip
        });
    }
}

PositionExerciseRequestScreen.prototype.dispose = function ()
{
    this.exerciseValidator.dispose();
    Control.Ticker.UnSubscribe(this.TickAsync, this);

    TerceraWindowBase.prototype.dispose.apply(this);

    // TerceraClosePositionScreen.prototype.dispose.apply(this);    // поскольку oncomplete свой то и dispose также
};

let handl = positionExerciseRequestScreenHandler;
handl.show = PositionExerciseRequestScreen.show;