// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../../Commons/properties/Resources';
import { DataCacheToolRayType, DataCacheToolType } from '../DataCacheToolEnums';
import { Color } from '../../../Commons/Graphics';
import { DataCacheTool } from '../DataCacheTool';
import { DynProperty, ColorStyleWidth } from '../../DynProperty';

/**
 * Line Data Cache Tool
 */
export class LineDataCacheTool extends DataCacheTool {
    private showAngle: boolean;

    /**
     * Constructor for LineDataCacheTool
     */
    constructor () {
        super();
        this.showAngle = false;
        this.ToolType = DataCacheToolType.Line;
        this.InitPoints(2);
        this.InitBlock();
    }

    /**
     * Initializes the block for the tool
     */
    protected InitBlock (): void {
        super.InitBlock();
        this.showAngle = true;
    }

    /**
     * Gets the minimum points count required for the tool
     * @returns {number} - Minimum points count
     */
    MinPointsCount (): number {
        return 2;
    }

    /**
     * Checks if the angle should be shown
     * @returns {boolean} - Whether to show the angle or not
     */
    ShowAngle (): boolean {
        return this.showAngle;
    }

    /**
     * Gets the properties of the tool
     * @returns {DynProperty[]} - Array of dynamic properties
     */
    Properties (): DynProperty[] {
        const props: DynProperty[] = super.Properties();

        const leftRayProp = new DynProperty('Left ray', this.RayType === DataCacheToolRayType.LeftRay || this.RayType === DataCacheToolRayType.DoubleRay, DynProperty.BOOLEAN, DynProperty.VISUAL_GROUP);
        leftRayProp.sortIndex = 0;
        props.push(leftRayProp);

        const rightRayProp = new DynProperty('Right ray', this.RayType === DataCacheToolRayType.RightRay || this.RayType === DataCacheToolRayType.DoubleRay, DynProperty.BOOLEAN, DynProperty.VISUAL_GROUP);
        rightRayProp.sortIndex = 1;
        props.push(rightRayProp);

        const colorStyleWidthRays = new ColorStyleWidth(Color.Empty, this.styleRay, 1);
        colorStyleWidthRays.enableStyleOnly();
        props.push(new DynProperty('Ray style', colorStyleWidthRays, DynProperty.COLOR_STYLE_WIDTH, DynProperty.VISUAL_GROUP));

        const angleProp = new DynProperty('Angle', this.ShowAngle(), DynProperty.BOOLEAN, DynProperty.VISUAL_GROUP);
        angleProp.propertyComment = Resources.getResource('property.chart.showAngle');
        props.push(angleProp);

        return props;
    }

    /**
     * Callback function for handling property changes
     * @param {DynProperty[]} properties - Array of dynamic properties
     */
    callBack (properties: DynProperty[]): void {
        super.callBack(properties);

        const styleProp = DynProperty.getPropertyByName(properties, 'Ray style');
        if (styleProp != null) this.StyleRay = styleProp.value.Style;

        const angleProp = DynProperty.getPropertyByName(properties, 'Angle');
        if (angleProp != null) this.showAngle = angleProp.value;

        this.RayType = DataCacheTool.GetRayType(properties);
    }
}
