// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Connection } from "../../Commons/Connection.ts";
import { ConnectionStates, ConectionResultEnum } from "../../Commons/ConnectionEnums.ts";
import { CustomEvent } from "../../Utils/CustomEvents.ts";
import { Resources } from "../../Commons/properties/Resources.ts";
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";
import { TerceraBaseWindowTemplate, TerceraReconnectScreenTemplate } from "../../templates.js";
import { TerceraButton } from "../elements/TerceraButton.ts";
import { AdditionalVerificationScreen } from "./AdditionalVerificationScreen.js";
import { TerceraMessageBox } from "./TerceraMessageBox.js";
import { TerceraWindowBase } from "./TerceraWindowBase.js";

export let TerceraReconnectScreen = TerceraWindowBase.extend({     // Был screen-ом раньше, теперь это Manager. 
    template: TerceraBaseWindowTemplate,      // Процесс реконнекта происходит в методе tryNowBtnClick
    data: function ()
    {
        return {
            textAttemptLabel: 'Attempts:',
            textStatusLabel: 'Status:',
            textTryNowButton: 'Try Now',
            textCancelButton: 'Cancel',
            header: 'Reconnect...',
            visible: false,
        };
    },
    partials: { bodyPartial: TerceraReconnectScreenTemplate },
    forceCloseOnLogout: false,
    timeout: null,
    lastWorkedVendorState: null
});

TerceraReconnectScreen.prototype.getType = function () { return 'TerceraReconnectScreen'; };

//more than 1 instance is prohibited
TerceraReconnectScreen.instance = null;

TerceraReconnectScreen.prototype.oncomplete = function ()
{
    TerceraWindowBase.prototype.oncomplete.apply(this);
    Connection.onConnectStateChange.Subscribe(this.connectionStateChange, this);

    var myWidth = this.get('width');

    this.tryNowBtn = new TerceraButton();
    this.tryNowBtn.setLocation(myWidth - 2 * 10 - 2 * 100, 10, 100, 20);
    this.tryNowBtn.set({
        name: 'tryNowBtn',
        text: Resources.getResource("screen.recconection.tryNow")
    });
    var tryNowEvent = new CustomEvent();
    tryNowEvent.Subscribe(this.tryNowBtnClick, this);
    this.tryNowBtn.onClick = tryNowEvent;

    this.cancelBtn = new TerceraButton();
    this.cancelBtn.setLocation(myWidth - 10 - 100, 10, 100, 20);
    this.cancelBtn.set({
        name: 'cancelBtn',
        text: Resources.getResource("screen.recconection.stop")
    });
    var cancelEvent = new CustomEvent();
    cancelEvent.Subscribe(this.cancelBtnClick, this);
    this.cancelBtn.onClick = cancelEvent;

    this.Controls.windowPanelFooter.addControl(this.tryNowBtn);
    this.Controls.windowPanelFooter.addControl(this.cancelBtn);

    this.tryNowBtnClick();
    this.center();
    this.localiize();
};

TerceraReconnectScreen.prototype.localiize = function ()
{
    this.set({
        textAttemptLabel: Resources.getResource("screen.recconection.attemptLabelLabel"),
        textStatusLabel: Resources.getResource("screen.recconection.statusLabelLabel"),
        textTryNowButton: Resources.getResource("screen.recconection.tryNow"),
        textCancelButton: Resources.getResource("screen.recconection.stop"),
        header: Resources.getResource("screen.recconection.title")
    });
}

TerceraReconnectScreen.prototype.tryNowBtnClick = function ()
{
    //логинюсь, если я в разрешенном для логина состоянии
    var connection = Connection;
    var state = connection.getState();
    if (state != ConnectionStates.CONNECTION_LOST && state != ConnectionStates.DISCONNECTED)
        return;

    var connectData = MainWindowManager.MainWindow.Controls.loginScreen.get('connectionParams');
    if (!connectData || connectData === null)
        return;
    connectData.ServerAdressesArray = connectData.ServerAdress.split(",");
    for (let i = 0, len = connectData.ServerAdressesArray.length; i < len; i++)
        if (connectData.ServerAdressesArray[i])
            connectData.ServerAdressesArray[i] = connectData.ServerAdressesArray[i].trim();
    this.set('textStatus', Resources.getResource("screen.recconection.reconnecting"));
    this.tryNowBtn.set('enabled', false);
    connectData.reconnect = true;
    connection.connect(connectData, true).then(function (answers)
    {
        let connected = !!answers[0]
        let tryAgain = !!answers[1]
        if (!connected && tryAgain)
            setTimeout(this.tryNowBtnClick.bind(this), TerceraReconnectScreen.RECONNECTTIME)
    }.bind(this));
};
TerceraReconnectScreen.RECONNECTTIME = 5000;
TerceraReconnectScreen.prototype.cancelBtnClick = function ()
{
    this.close();
};

TerceraReconnectScreen.prototype.close = function ()
{
    //сбрасываю состояние на дисконнект
    var connection = Connection;
    var state = connection.getState();
    if (state == ConnectionStates.CONNECTION_LOST || state == ConnectionStates.CONNECTING)
        Connection.disconnect();
}

TerceraReconnectScreen.prototype.dispose = function ()
{
    var connection = Connection;
    connection.onConnectStateChange.UnSubscribe(this.connectionStateChange, this);
    TerceraReconnectScreen.instance = null;
    TerceraWindowBase.prototype.dispose.apply(this);
}

TerceraReconnectScreen.prototype.connectionStateChange = function ()
{
    var state = Connection.getState();
    // досрочно прерываю скрин
    if (state == ConnectionStates.CONNECTED || state == ConnectionStates.DISCONNECTED)
        this.close();

    var lastResp = Connection.lastConnectionAttemptResponse;
    if (state !== ConnectionStates.CONNECTING && lastResp.connectReason === ConectionResultEnum.AdditionalVerification)
    {
        clearTimeout(this.timeout);
        var connectData = MainWindowManager.MainWindow.Controls.loginScreen.get('reconnectConnectionParams');
        if (!connectData || connectData === null)
            return;
        var okCallBack = function (verificPass)
        {
            var connection = Connection;
            connectData.verificationPassword = verificPass;
            connectData.reconnect = true;
            connection.connect(connectData, true);
            connectData.verificationPassword = undefined;
        }.bind(this);
        var cancelCallBack = function () { this.close() }.bind(this);
        AdditionalVerificationScreen.Show(lastResp, okCallBack, cancelCallBack, connectData);
    }
    else if (state === ConnectionStates.CONNECTION_LOST && lastResp.connectReason === ConectionResultEnum.Error && lastResp.text !== "connect.connection.connect.error")
    {
        TerceraMessageBox.Show(
            Resources.getResource("screen.additionalVerification.title"),
            Connection.getLocalizedConnectionErrorString(),
            TerceraMessageBox.msgType.Info,
            this.close.bind(this),
            null, false, true);
    }
    // else if (state === Connection.ConnectionStates.DISCONNECTED)
    // {
    //     this.tryNowBtnClick();
    // }
};

TerceraReconnectScreen.startReconnection = function ()
{
    if (TerceraReconnectScreen.instance != null)
    {
        TerceraReconnectScreen.instance.tryNowBtnClick()
        return;
    }

    var scr = new TerceraReconnectScreen();
    scr.setBounds(0, 0, 400, 160);
    MainWindowManager.MainWindow.addControl(scr);
    TerceraReconnectScreen.instance = scr;
}