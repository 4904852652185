import { type ColorStyleWidth } from '../../Commons/DynProperty';
import { Point, Rectangle } from '../../Commons/Geometry';
import { Pen } from '../../Commons/Graphics';
import { XYSeriesData, type TerceraChartXYSeries } from '../Series/TerceraChartXYSeries';
import { type TerceraChartBase } from '../TerceraChartBase';
import { type TerceraChartWindow } from '../Windows/TerceraChartWindow';
import { TerceraChartBaseRenderer } from './TerceraChartBaseRenderer';
import { type ISeriesRenderer } from './Utils/ISeriesRenderer';

export class TerceraChartStyledLineRenderer extends TerceraChartBaseRenderer implements ISeriesRenderer<TerceraChartXYSeries> {
    public ColorStyleWidth: ColorStyleWidth;

    public Series: TerceraChartXYSeries;
    public isCurve: boolean = true;
    constructor (chart: TerceraChartBase) {
        super(chart);
        this.UseInAutoscale = true;
    }

    public override Draw (gr: CanvasRenderingContext2D, window: TerceraChartWindow, windowsContainer: any, advParams: any): void {
        if (!this.Visible) {
            return;
        }

        if (isNullOrUndefined(this.ColorStyleWidth)) {
            return;
        }

        if (isNullOrUndefined(this.Series) || this.Series.Count() === 0) {
            return;
        }

        const points = this.getPoints(window);
        if (points.length === 0) {
            return;
        }
        gr.save();
        const clientRect = window.ClientRectangle;
        const rect = new Path2D();
        rect.rect(clientRect.X, clientRect.Y, clientRect.Width, clientRect.Height);
        gr.clip(rect);
        const pen = new Pen(this.ColorStyleWidth.Color, this.ColorStyleWidth.Width);
        Pen.ProcessPen(pen, this.ColorStyleWidth.Style);
        if (this.isCurve) {
            gr.DrawCurve(pen, points);
        } else {
            gr.DrawLines(pen, points);
        }
        gr.restore();
    }

    public override FindMinMax (minMax: { tMin: number, tMax: number }, window: TerceraChartWindow): boolean {
        if (isNullOrUndefined(this.Series) || this.Series.Count() === 0) {
            return super.FindMinMax(minMax, window);
        }
        let min = Number.MAX_VALUE;
        let max = -Number.MAX_VALUE;
        for (let i = 0; i < this.Series.Count(); i++) {
            const y = this.Series.GetValue(i, XYSeriesData.Y_INDEX);
            min = Math.min(min, y);
            max = Math.max(max, y);
        }
        minMax.tMin = min;
        minMax.tMax = max;
        return true;
    }

    protected getPoints (window: TerceraChartWindow): Point[] {
        const clientRectangle = window.ClientRectangle;
        const maxDelta = 10000;
        const maxRect = new Rectangle(clientRectangle.X - maxDelta, clientRectangle.Y - maxDelta, clientRectangle.Width + 2 * maxDelta, clientRectangle.Height + 2 * maxDelta);

        const points: Point[] = [];
        for (let i = 0; i < this.Series.Count(); i++) {
            const x = this.Series.GetValue(i, XYSeriesData.X_INDEX);
            const y = this.Series.GetValue(i, XYSeriesData.Y_INDEX);
            const point = new Point(window.PointsConverter.GetScreenX(x), window.PointsConverter.GetScreenY(y));
            if (maxRect.Contains(point.X, point.Y)) {
                points.push(point);
            }
        }

        return points;
    }
}
