// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type Account } from '../../Commons/cache/Account';
import { AccountUtils } from '../../Commons/cache/AccountUtils';
import { type Instrument } from '../../Commons/cache/Instrument';
import { type SpreadItem } from '../../Commons/cache/SpreadItem';
import { Resources } from '../../Commons/properties/Resources';
import { DirectInstrumentDayBarMessage } from '../DirectMessages/DirectInstrumentDayBarMessage';
import { HistoryType } from '../History/HistoryType';
import { DateTimeUtils } from '../Time/DateTimeUtils';

export class InstrumentDayInfo {
    public NAString: string;
    public instrument: Instrument;

    public bidOpen: number = NaN;
    public askOpen: number = NaN;
    public tradeOpen: number = NaN;

    private bidHigh: number = NaN;
    private askHigh: number = NaN;
    private tradeHigh: number = NaN;

    private bidLow: number = NaN;
    private askLow: number = NaN;
    private tradeLow: number = NaN;

    private bidPrevClose: number = NaN;
    private askPrevClose: number = NaN;
    private tradePrevClose: number = NaN;

    private bidMainClose: number = NaN;
    private askMainClose: number = NaN;
    private tradeMainClose: number = NaN;
    private lastPrice: number = NaN;

    public askVenue: string;
    public bidVenue: string;
    public tradeVenue: string;

    private bidTicks: number = 0;
    private askTicks: number = 0;
    private tradeTicks: number = 0;

    public bidTotalvolume: number = NaN;
    public askTotalvolume: number = NaN;
    public tradeTotalvolume: number = NaN;

    public get OpenInterest (): number { return this.openInterest; }
    private openInterest: number;

    public get SettlementPrice (): number { return this.settlementPrice; }
    private settlementPrice: number = NaN;

    public get PrevSettlementPrice (): number { return this.prevSettlementPrice; }
    private prevSettlementPrice: number = NaN;

    public get FiftyTwoWeekHighPrice (): number { return this.fiftyTwoWeekHighPrice; }
    private set FiftyTwoWeekHighPrice (value: number) { this.fiftyTwoWeekHighPrice = value; }
    private fiftyTwoWeekHighPrice: number = NaN;

    public get strFiftyTwoWeekHighPrice (): string {
        return this.FormatPrice(this.FiftyTwoWeekHighPrice);
    }

    public get FiftyTwoWeekLowPrice (): number { return this.fiftyTwoWeekLowPrice; }
    private set FiftyTwoWeekLowPrice (value: number) { this.fiftyTwoWeekLowPrice = value; }
    private fiftyTwoWeekLowPrice: number = NaN;

    public get strFiftyTwoWeekLowPrice (): string {
        return this.FormatPrice(this.FiftyTwoWeekLowPrice);
    }

    public get High13Week (): number { return this.high13Week; }
    private set High13Week (value: number) { this.high13Week = value; }
    private high13Week: number = NaN;

    public get Low13Week (): number { return this.low13Week; }
    private set Low13Week (value: number) { this.low13Week = value; }
    private low13Week: number = NaN;

    public get High26Week (): number { return this.high26Week; }
    private set High26Week (value: number) { this.high26Week = value; }
    private high26Week: number = NaN;

    public get Low26Week (): number { return this.low26Week; }
    private set Low26Week (value: number) { this.low26Week = value; }
    private low26Week: number = NaN;

    public get ReferencePrice (): number { return this.referencePrice; }
    private set ReferencePrice (value: number) { this.referencePrice = value; }
    private referencePrice: number = NaN;

    public strReferencePrice (): string {
        return this.FormatPrice(this.ReferencePrice);
    }

    public get IsSnapshot (): boolean { return this.isSnapshot; }
    private set IsSnapshot (value: boolean) { this.isSnapshot = value; }
    private isSnapshot: boolean;

    public get IsAllowSubscribeL1 (): boolean { return this.isAllowSubscribeL1; }
    private set IsAllowSubscribeL1 (value: boolean) { this.isAllowSubscribeL1 = value; }
    private isAllowSubscribeL1: boolean;

    public get InstrumentDayBarMessageUpdateMode (): boolean { return this.instrumentDayBarMessageUpdateMode; }
    public set InstrumentDayBarMessageUpdateMode (value: boolean) { this.instrumentDayBarMessageUpdateMode = value; }
    private instrumentDayBarMessageUpdateMode: boolean;

    constructor (instrument: Instrument) {
        this.instrument = instrument;
        this.Localize();
    }

    public Localize (): void {
        this.NAString = Resources.getResource('general.N_A');
    }

    public FormatPrice (price): string {
        return !isNaN(price) ? this.instrument.formatPrice(price) : this.NAString;
    }

    public NewQuote (mess): void {
        switch (mess.Type) {
        case HistoryType.QUOTE_LEVEL1:
            this.NewQuote1Message(mess);
            break;
        case HistoryType.QUOTE_TRADES:
            this.NewQuote3Message(mess);
            break;
        case HistoryType.QUOTE_INSTRUMENT_DAY_BAR:
            this.NewIdbmMessage(mess);
            break;
        }
    }

    private NewQuote1Message (q1Mess): void {
        this.askVenue = q1Mess.AskVenue;
        this.bidVenue = q1Mess.BidVenue;

        if (this.InstrumentDayBarMessageUpdateMode) { return; }

        if (isNaN(this.bidOpen)) { this.bidOpen = q1Mess.Bid; }

        if (isNaN(this.askOpen)) { this.askOpen = q1Mess.Ask; }

        this.bidHigh = this.UpdateHigh(this.bidHigh, q1Mess.Bid);
        this.bidLow = this.UpdateLow(this.bidLow, q1Mess.Bid);
        this.askHigh = this.UpdateHigh(this.askHigh, q1Mess.Ask);
        this.askLow = this.UpdateLow(this.askLow, q1Mess.Ask);

        if (!isNaN(q1Mess.Ask)) { this.askTicks++; }

        if (!isNaN(q1Mess.Bid)) { this.bidTicks++; }

        if (isNaN(this.tradeOpen)) { this.tradeOpen = this.bidOpen; }

        if (isNaN(this.tradePrevClose)) { this.tradePrevClose = this.bidPrevClose; }
    }

    private NewQuote3Message (q3Mess): void {
        if (q3Mess.OpenInterest) { this.openInterest = q3Mess.OpenInterest; }

        if (this.InstrumentDayBarMessageUpdateMode) { return; }

        this.tradeVenue = q3Mess.Exchange;

        this.tradeTicks++;

        if (isNaN(this.tradeTotalvolume)) { this.tradeTotalvolume = q3Mess.Size; } else { this.tradeTotalvolume += q3Mess.Size; }

        this.tradeHigh = this.UpdateHigh(this.tradeHigh, q3Mess.Price);
        this.tradeLow = this.UpdateLow(this.tradeLow, q3Mess.Price);

        if (isNaN(this.tradeOpen)) { this.tradeOpen = q3Mess.Price; }
    }

    private NewIdbmMessage (idbmMess): void {
        if (idbmMess.InstrumentBarType === HistoryType.QUOTE_TRADES) {
            if (idbmMess.Open) { this.tradeOpen = idbmMess.Open; }

            if (idbmMess.High) { this.tradeHigh = idbmMess.High; }

            if (idbmMess.Low) { this.tradeLow = idbmMess.Low; }

            if (idbmMess.PreviousClosePrice) { this.tradePrevClose = idbmMess.PreviousClosePrice; }

            if (idbmMess.TodayClosePrice) {
                this.tradeMainClose = idbmMess.TodayClosePrice;
            } else if (idbmMess.LastPrice && !isNaN(this.tradeOpen)) {
                this.tradeMainClose = idbmMess.LastPrice;
            }

            if (idbmMess.Ticks) { this.tradeTicks = idbmMess.Ticks; }

            if (idbmMess.Volume) { this.tradeTotalvolume = idbmMess.Volume; }

            if (idbmMess.LastPrice && !isNaN(idbmMess.LastPrice.Value)) { this.lastPrice = idbmMess.LastPrice.Value; }

            if (idbmMess.LastVenue) { this.tradeVenue = idbmMess.LastVenue; }
        } else if (idbmMess.InstrumentBarType === HistoryType.QUOTE_ASK) {
            if (idbmMess.Open) { this.askOpen = idbmMess.Open; }

            if (idbmMess.High) { this.askHigh = idbmMess.High; }

            if (idbmMess.Low) { this.askLow = idbmMess.Low; }

            if (idbmMess.PreviousClosePrice) { this.askPrevClose = idbmMess.PreviousClosePrice; }

            if (idbmMess.TodayClosePrice) { this.askMainClose = idbmMess.TodayClosePrice; }

            if (idbmMess.Ticks) { this.askTicks = idbmMess.Ticks; }

            if (idbmMess.Volume) { this.askTotalvolume = idbmMess.Volume; }
        } else {
            if (idbmMess.Open) { this.bidOpen = idbmMess.Open; }

            if (idbmMess.High) { this.bidHigh = idbmMess.High; }

            if (idbmMess.Low) { this.bidLow = idbmMess.Low; }

            if (idbmMess.PreviousClosePrice) { this.bidPrevClose = idbmMess.PreviousClosePrice; }

            if (idbmMess.TodayClosePrice) { this.bidMainClose = idbmMess.TodayClosePrice; }

            if (idbmMess.Ticks) { this.bidTicks = idbmMess.Ticks; }

            if (idbmMess.Volume) { this.bidTotalvolume = idbmMess.Volume; }
        }

        if (idbmMess.InstrumentBarType === this.instrument.HistoryType) {
            if (idbmMess.OpenInterest) { this.openInterest = idbmMess.OpenInterest; }

            if (idbmMess.SettlementPrice) { this.settlementPrice = idbmMess.SettlementPrice; }

            if (idbmMess.PrevSettlementPrice) { this.prevSettlementPrice = idbmMess.PrevSettlementPrice; }

            if (idbmMess.FiftyTwoWeekHighPrice) { this.FiftyTwoWeekHighPrice = idbmMess.FiftyTwoWeekHighPrice; }

            if (idbmMess.FiftyTwoWeekLowPrice) { this.FiftyTwoWeekLowPrice = idbmMess.FiftyTwoWeekLowPrice; }

            if (idbmMess.High13Week) { this.High13Week = idbmMess.High13Week; }

            if (idbmMess.Low13Week) { this.Low13Week = idbmMess.Low13Week; }

            if (idbmMess.High26Week) { this.High26Week = idbmMess.High26Week; }

            if (idbmMess.Low26Week) { this.Low26Week = idbmMess.Low26Week; }

            if (idbmMess.ReferencePrice) { this.ReferencePrice = idbmMess.ReferencePrice; }
        }
    }

    public Ticks (): number {
        return this.getTicks(this.instrument.HistoryType);
    }

    public strTicks (): string {
        const ticks = this.Ticks();
        return this.FormatPrice(ticks);
    }

    public getOpen (account: Account): number { return this.OpenSpreadStatic(account, this.instrument); }
    public strOpen (account: Account): string { return this.FormatPrice(this.getOpen(account)); }

    public getHigh (account: Account): number { return this.HighSpreadStatic(account, this.instrument); }
    public strHigh (account: Account): string { return this.FormatPrice(this.getHigh(account)); }

    public getLow (account: Account): number { return this.LowSpreadStatic(account, this.instrument); }
    public strLow (account: Account): string { return this.FormatPrice(this.getLow(account)); }

    public getPrevClose (account: Account): number { return this.PrevCloseSpreadStatic(account, this.instrument); }
    public strPrevClose (account: Account): string { return this.FormatPrice(this.getPrevClose(account)); }

    public getMainClose (account: Account): number { return this.MainCloseSpreadStatic(account, this.instrument); }

    public getClose (account: Account): number {
        const last = this.instrument.Level1.GetLastPrice(account);
        return !isNaN(last) ? last : this.getMainClose(account);
    }

    public strClose (account: Account): string {
        return this.FormatPrice(this.getClose(account));
    }

    public TotalVolume (): number {
        return this.getTotalVolume(this.instrument.HistoryType);
    }

    public getTotalVolume (type: number): number {
        switch (type) {
        case HistoryType.QUOTE_LEVEL1:
            return this.bidTotalvolume;
        case HistoryType.QUOTE_ASK:
            return this.askTotalvolume;
        case HistoryType.QUOTE_TRADES:
            return this.tradeTotalvolume;
        default:
            return this.tradeTotalvolume;
        }
    }

    public getTicks (type: number): number {
        switch (type) {
        case HistoryType.QUOTE_LEVEL1:
            return this.bidTicks;
        case HistoryType.QUOTE_ASK:
            return this.askTicks;
        case HistoryType.QUOTE_TRADES:
            return this.tradeTicks;
        default:
            return this.tradeTicks;
        }
    }

    private OpenSpreadStatic (acc: Account, instr: Instrument): number {
        if (instr === null) { return NaN; }
        return instr.InstrumentDayInfo.OpenSpread(this.GetSpreadItem(acc, instr), instr.HistoryType);
    }

    public OpenSpread (spreadItem: SpreadItem, type: number): number {
        return this.GetSpreadValue(this.instrument, spreadItem, type, this.bidOpen, this.askOpen, this.tradeOpen);
    }

    private HighSpreadStatic (acc: Account, instr: Instrument): number {
        if (instr === null) { return NaN; }
        return instr.InstrumentDayInfo.HighSpread(this.GetSpreadItem(acc, instr), instr.HistoryType);
    }

    public HighSpread (spreadItem: SpreadItem, type: number): number {
        return this.GetSpreadValue(this.instrument, spreadItem, type, this.bidHigh, this.askHigh, this.tradeHigh);
    }

    private LowSpreadStatic (acc: Account, instr: Instrument): number {
        if (instr === null) { return NaN; }
        return instr.InstrumentDayInfo.LowSpread(this.GetSpreadItem(acc, instr), instr.HistoryType);
    }

    public LowSpread (spreadItem: SpreadItem, type: number): number {
        return this.GetSpreadValue(this.instrument, spreadItem, type, this.bidLow, this.askLow, this.tradeLow);
    }

    public PrevCloseSpread (spreadItem: SpreadItem, type: number): number {
        return this.GetSpreadValue(this.instrument, spreadItem, type, this.bidPrevClose, this.askPrevClose, this.tradePrevClose);
    }

    private PrevCloseSpreadStatic (acc: Account, instr: Instrument): number {
        if (instr === null) { return NaN; }
        return instr.InstrumentDayInfo.PrevCloseSpread(this.GetSpreadItem(acc, instr), instr.HistoryType);
    }

    public PrevCloseSpreadForMainAccount (instr: Instrument): number {
        return this.PrevCloseSpreadStatic(AccountUtils.getSavedAccount(), instr);
    }

    private MainCloseSpreadStatic (acc: Account, instr: Instrument): number {
        if (instr === null) { return NaN; }
        return instr.InstrumentDayInfo.MainCloseSpread(this.GetSpreadItem(acc, instr), instr.HistoryType);
    }

    public MainCloseSpread (spreadItem: SpreadItem, type: number): number {
        return this.GetSpreadValue(this.instrument, spreadItem, type, this.bidMainClose, this.askMainClose, this.tradeMainClose);
    }

    public GetSpreadValue (instr: Instrument, spreadItem: SpreadItem, type: number, bid: number, ask: number, trade: number): number {
        if (spreadItem === null) { return this.GetNonSpreadValue(type, bid, ask, trade); }

        switch (type) {
        case HistoryType.QUOTE_LEVEL1:
            return spreadItem.CalcBid(bid, ask, instr);
        case HistoryType.QUOTE_ASK:
            return spreadItem.CalcAsk(bid, ask, instr);
        case HistoryType.QUOTE_TRADES:
            return trade;
        case HistoryType.QUOTE_BIDASK_AVG:
            return !isNaN(bid) && !isNaN(ask)
                ? spreadItem.CalcBid(bid, ask, instr) / 2 + spreadItem.CalcAsk(bid, ask, instr) / 2
                : NaN;
        default:
            return trade;
        }
    }

    public GetNonSpreadValue (type, bid, ask, trade): number {
        switch (type) {
        case HistoryType.QUOTE_LEVEL1:
            return bid;
        case HistoryType.QUOTE_ASK:
            return ask;
        case HistoryType.QUOTE_TRADES:
            return trade;
        case HistoryType.QUOTE_BIDASK_AVG:
            return !isNaN(bid) && !isNaN(ask) ? (bid + ask) / 2 : NaN;
        default:
            return trade;
        }
    }

    public GetSpreadItem (acc: Account, instr: Instrument): SpreadItem {
        const spreadPlan = instr.DataCache.GetSpreadPlan(acc);
        if (isNullOrUndefined(spreadPlan)) { return null; }

        const ID = instr.GetInteriorID();
        return spreadPlan.IsNotEmptyPlan(ID) ? spreadPlan.GetItem(ID) : null;
    }

    private UpdateHigh (high: number, curPrice: number): number {
        if (!isNaN(curPrice) && (curPrice > high || isNaN(high))) { return curPrice; };

        return high;
    }

    private UpdateLow (low: number, curPrice: number): number {
        if (!isNaN(curPrice) && (curPrice < low || isNaN(low))) { return curPrice; };

        return low;
    }

    public GetBidVenue (): string { return this.bidVenue ? this.bidVenue : this.NAString; }
    public GetAskVenue (): string { return this.askVenue ? this.askVenue : this.NAString; }
    public GetLastVenue (): string { return this.tradeVenue ? this.tradeVenue : this.NAString; }

    public GeneratedInstrumentDayBarMessages (): DirectInstrumentDayBarMessage[] {
        const now = DateTimeUtils.DateTimeNow();
        const tradableId = this.instrument.InstrumentTradableID;
        const routeId = this.instrument.Route;
        const i_id = this.instrument.GetInteriorID();

        // по трейдам
        const idbmTrade = new DirectInstrumentDayBarMessage();
        idbmTrade.InstrumentBarType = HistoryType.QUOTE_TRADES;
        idbmTrade.TradableId = tradableId;
        idbmTrade.QuoteRouteId = routeId;
        idbmTrade.cTime = now;
        idbmTrade.TargetInstrumentName = i_id;

        if (!isNaN(this.tradeOpen)) { idbmTrade.Open = this.tradeOpen; }

        if (!isNaN(this.tradeHigh)) { idbmTrade.High = this.tradeHigh; }

        if (!isNaN(this.tradeLow)) { idbmTrade.Low = this.tradeLow; }

        if (!isNaN(this.tradePrevClose)) { idbmTrade.PreviousClosePrice = this.tradePrevClose; }

        if (!isNaN(this.tradeMainClose)) { idbmTrade.TodayClosePrice = this.tradeMainClose; }

        if (this.tradeTicks !== 0) { idbmTrade.Ticks = this.tradeTicks; }

        if (!isNaN(this.tradeTotalvolume)) { idbmTrade.Volume = this.tradeTotalvolume; }

        idbmTrade.LastTime = this.instrument.Level1.getLastMessageTime;

        const last = this.instrument.Level1.GetLastPrice(null);
        if (!isNaN(this.lastPrice) && !isNaN(last)) { idbmTrade.LastPrice = last; }

        const lastSize = this.instrument.Level1.GetLastSize(false);
        if (!isNaN(lastSize)) { idbmTrade.LastSize = lastSize; }

        if (this.tradeVenue) { idbmTrade.LastVenue = Number(this.tradeVenue); }

        // по аскам
        const idbmAsk = new DirectInstrumentDayBarMessage();
        idbmAsk.InstrumentBarType = HistoryType.QUOTE_ASK;
        idbmAsk.TradableId = tradableId;
        idbmAsk.QuoteRouteId = routeId;
        idbmAsk.cTime = now;
        idbmAsk.TargetInstrumentName = i_id;

        if (!isNaN(this.askOpen)) { idbmAsk.Open = this.askOpen; }

        if (!isNaN(this.askHigh)) { idbmAsk.High = this.askHigh; }

        if (!isNaN(this.askLow)) { idbmAsk.Low = this.askLow; }

        if (!isNaN(this.askPrevClose)) { idbmAsk.PreviousClosePrice = this.askPrevClose; }

        if (!isNaN(this.askMainClose)) { idbmAsk.TodayClosePrice = this.askMainClose; }

        if (this.askTicks !== 0) { idbmAsk.Ticks = this.askTicks; }

        if (!isNaN(this.askTotalvolume)) { idbmAsk.Volume = this.askTotalvolume; }

        // по бидам
        const idbmBid = new DirectInstrumentDayBarMessage();
        idbmBid.InstrumentBarType = HistoryType.QUOTE_LEVEL1;
        idbmBid.TradableId = tradableId;
        idbmBid.QuoteRouteId = routeId;
        idbmBid.cTime = now;
        idbmBid.TargetInstrumentName = i_id;

        if (!isNaN(this.bidOpen)) { idbmBid.Open = this.bidOpen; }

        if (!isNaN(this.bidHigh)) { idbmBid.High = this.bidHigh; }

        if (!isNaN(this.bidLow)) { idbmBid.Low = this.bidLow; }

        if (!isNaN(this.bidPrevClose)) { idbmBid.PreviousClosePrice = this.bidPrevClose; }

        if (!isNaN(this.bidMainClose)) { idbmBid.TodayClosePrice = this.bidMainClose; }

        if (this.bidTicks !== 0) { idbmBid.Ticks = this.bidTicks; }

        if (!isNaN(this.bidTotalvolume)) { idbmBid.Volume = this.bidTotalvolume; }

        let mainIDBM: DirectInstrumentDayBarMessage;
        if (this.instrument.HistoryType === HistoryType.QUOTE_TRADES) {
            mainIDBM = idbmTrade;
        } else if (this.instrument.HistoryType === HistoryType.QUOTE_ASK) {
            mainIDBM = idbmAsk;
        } else {
            mainIDBM = idbmBid;
        }

        mainIDBM.OpenInterest = this.openInterest;
        mainIDBM.SettlementPrice = this.settlementPrice;
        mainIDBM.PrevSettlementPrice = this.prevSettlementPrice;
        mainIDBM.FiftyTwoWeekHighPrice = this.FiftyTwoWeekHighPrice;
        mainIDBM.FiftyTwoWeekLowPrice = this.FiftyTwoWeekLowPrice;
        mainIDBM.High13Week = this.High13Week;
        mainIDBM.Low13Week = this.Low13Week;
        mainIDBM.High26Week = this.High26Week;
        mainIDBM.Low26Week = this.Low26Week;
        mainIDBM.ReferencePrice = this.ReferencePrice;

        return [idbmAsk, idbmBid, idbmTrade];
    }
}
