// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { CrossRateData } from '../../../../Utils/CrossRate/CrossRateData';
import { DirectCrossRateMessage } from '../../../../Utils/DirectMessages/DirectMessagesImport';
import { FieldsFactory } from '../Factory/FieldsFactory';
import { type PFixFieldSet } from '../Fields/FieldsImport';
import { MessageProcessorBase } from './MessageProcessorBase';

export class CrossRateMessageProcessor extends MessageProcessorBase {
    public decoder: any;

    constructor (parentDecoder) {
        super();

        this.decoder = parentDecoder;
    }

    public override decode (fieldSet: PFixFieldSet): DirectCrossRateMessage[] {
        const AssetDecodeMap = this.decoder.AssetDecodeMap;

        const crossRateGroups = fieldSet.GetGroups(FieldsFactory.CROSS_GROUP);
        const len = crossRateGroups.length;
        const res = [];

        for (let i = 0; i < len; i++) {
            const crg = crossRateGroups[i];
            const crd = new CrossRateData();
            res.push(crd);

            const asset2Id = crg.GetValue(FieldsFactory.FIELD_ASSET_ID2);
            if (asset2Id === null) {
                crd.Exp1 = crg.GetValue(FieldsFactory.FIELD_NAME_EXP1);
                crd.Exp2 = crg.GetValue(FieldsFactory.FIELD_NAME_EXP2);
            } else {
            // новая схема, когда вместо строки передается ID
                const asset1Id = crg.GetValue(FieldsFactory.FIELD_ASSET_ID);
                crd.Exp1 = AssetDecodeMap[asset1Id] || null;
                crd.Exp2 = AssetDecodeMap[asset2Id] || null;
            }

            crd.Price = crg.GetValue(FieldsFactory.FIELD_PRICE);
        }

        return [new DirectCrossRateMessage(res)];
    }
}
