// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { QuickTree } from './QuickTree';
import { QuickTreeNode } from './QuickTreeNode';
import { ThemeManager } from '../../misc/ThemeManager';
import { type Rectangle } from '../../../Commons/Geometry.js';
import { FontUtils } from '../../UtilsClasses/FontUtils';

export class InstrumentLookupQuickTreeNode extends QuickTreeNode {
    public InstrumentFont = '12px Trebuchet MS';
    public InstrumentFontBold: string;
    public InstrumentDrawingFont: string;
    public InstrumentDescriptionFont = '10px Trebuchet MS';

    public InstrumentNameColor = '#E3E1E2';
    public InstrumentNameText = '';
    public InstrumentNameTextWidth = 0;

    public InstrumentDescriptionColor = '#818181';
    public InstrumentTradingExchangeText = '';
    public InstrumentTradingExchangeTextWidth = 0;

    public InstrumentTradingExchangeColor = '#828F9D';
    public InstrumentDescriptionText = '';
    public InstrumentDescriptionTextWidth = 0;

    public FlagImg: any = null;
    public InstrumentTypeImg: any = null;

    public FilterNameText: any = null;
    public FilterNamePos: any = null;
    public FilterDescrText: any = null;
    public FilterDescrPos: any = null;

    public SpecificTag = '';
    public FullContainsCount = 0;
    public SkipRequest = false;
    public sortingStop = false;

    constructor (text: string) {
        super(text);

        this.InstrumentDrawingFont = this.InstrumentFont;

        this.InstrumentFontBold = FontUtils.makeCorrectBoldFont(this.InstrumentFont);
    }

    public static readonly NODE_HEIGHT = 35;

    // уровень элемента +14
    // 1 - 10
    // 2 - 27
    // 3 - 41
    // 4 - 55
    // 5 - 69

    public Draw (ctx: CanvasRenderingContext2D, curY: number, qtWidth: number, isHoveredNode: boolean, needRightMargin: boolean): void {
        const curFont = ThemeManager.CurrentTheme.quickTreeNodeFont.copy();
        curFont.FontWeight = this.nodeLevel === 1 ? 'bold' : 'normal';
        ctx.font = curFont.toString();

        const rightMargin = needRightMargin ? 5 : 0;

        let yOffset = 4;
        if (!isValidString(this.InstrumentDescriptionText)) {
            yOffset = 9;
        }

        const curNameExchageY = curY + yOffset + 4;
        const curDescrY = curNameExchageY + 14;

        let collapserStart = 10;
        if (this.nodeLevel > 1) {
            collapserStart = collapserStart + 14 * (this.nodeLevel - 1);
        }

        let leftOffset = this.nodeLevel * QuickTree.Level_PADDING - 12;

        let dispRectX;
        let dispRectWidth = 0;

        let withCollapse = true;
        if (this.hasVisibleChild && this.HasChildNodes()) {
            if (dispRectX === undefined) {
                dispRectX = leftOffset;
            }

            this.CollapseRectangle.X = collapserStart;
            this.CollapseRectangle.Y = curY + yOffset + 4;
            this.CollapseRectangle.Width = QuickTree.COLLAPSE_ICON_SIZE;
            this.CollapseRectangle.Height = QuickTree.COLLAPSE_ICON_SIZE;

            dispRectWidth += this.CollapseRectangle.Width;
        } else {
            this.CollapseRectangle.Empty();
            withCollapse = false;
        }

        leftOffset += QuickTree.COLLAPSE_ICON_SIZE;

        if (this.InstrumentTypeImg !== null) {
            if (dispRectX === undefined) {
                dispRectX = leftOffset;
            }

            leftOffset += 3;

            this.ImgRectangle.X = leftOffset + 3;
            this.ImgRectangle.Y = curY + yOffset + 2;
            this.ImgRectangle.Width = QuickTree.IMG_WIDTH;
            this.ImgRectangle.Height = QuickTree.IMG_HEIGHT;

            leftOffset += this.ImgRectangle.Width;

            dispRectWidth += 3 + this.ImgRectangle.Width;
        } else {
            this.ImgRectangle.Empty();
        }

        if (dispRectX === undefined) {
            dispRectX = leftOffset;
        }

        leftOffset += 3;

        this.TextRectangle.X = leftOffset + 4;
        this.TextRectangle.Y = curNameExchageY;
        if (!isValidString(this.InstrumentNameText)) {
            this.TextRectangle.Width = ctx.GetTextWidth(this.nodeText, this.font || curFont) + 8;
            this.TextRectangle.Height = InstrumentLookupQuickTreeNode.NODE_HEIGHT;

            dispRectWidth += 3 + this.TextRectangle.Width;
        } else {
            dispRectWidth += 3 + qtWidth - this.TextRectangle.X;
        }
        this.DisplayRectangle.X = dispRectX;
        this.DisplayRectangle.Y = curY;
        this.DisplayRectangle.Width = dispRectWidth;
        this.DisplayRectangle.Height = InstrumentLookupQuickTreeNode.NODE_HEIGHT;

        if (this.selected) {
            ctx.FillRect(ThemeManager.CurrentTheme.TreeSelectionBrush, 0, this.DisplayRectangle.Y, qtWidth, this.DisplayRectangle.Height);
        }

        if (this.InstrumentTypeImg !== null) {
            ctx.drawImage(this.InstrumentTypeImg, this.ImgRectangle.X, this.ImgRectangle.Y);
        }

        if (withCollapse) {
            this.drawCollapseIcon(ctx, isHoveredNode);
        }

        ctx.fillStyle = this.selected ? ThemeManager.CurrentTheme.Tree_TextSelectColor : ThemeManager.CurrentTheme.Tree_TextColor;

        ctx.textBaseline = 'top';
        if (isValidString(this.InstrumentNameText) && isValidNumber(this.InstrumentNameTextWidth)) {
            ctx.font = this.InstrumentDrawingFont;
        } else if (this.font) {
            ctx.font = this.font;
        }

        ctx.fillStyle = this.InstrumentNameColor;
        ctx.fillText(this.InstrumentNameText || this.nodeText, this.TextRectangle.X, this.TextRectangle.Y);
        if (this.FilterNameText) {
            ctx.fillStyle = '#FFB300';
            ctx.fillText(this.FilterNameText, this.TextRectangle.X + this.FilterNamePos, this.TextRectangle.Y);
        }

        if (isValidString(this.InstrumentTradingExchangeText)) {
            ctx.fillStyle = this.InstrumentTradingExchangeColor;
            const x_ofset = qtWidth - 37 - this.InstrumentTradingExchangeTextWidth - rightMargin;
            ctx.font = this.InstrumentFont;
            ctx.fillText(this.InstrumentTradingExchangeText, x_ofset + 4, this.TextRectangle.Y);
        }

        if (isValidString(this.InstrumentDescriptionText)) {
            ctx.fillStyle = this.InstrumentDescriptionColor;
            ctx.font = this.InstrumentDescriptionFont;
            ctx.fillText(this.InstrumentDescriptionText, this.TextRectangle.X, curDescrY);
            if (this.FilterDescrText) {
                ctx.fillStyle = '#FFB300';
                ctx.fillText(this.FilterDescrText, this.TextRectangle.X + this.FilterDescrPos, curDescrY);
            }
        }

        if (this.FlagImg) {
            const x_ofset = qtWidth - 27 - rightMargin;
            ctx.drawImage(this.FlagImg, x_ofset, this.ImgRectangle.Y);
        }

    // if (this.textPattern)
    // {
    //    let patternW = QuickTableUtils.GetWidth(ctx, this.textPattern) + 2,
    //        indexT = this.nodeText.toLowerCase().indexOf(this.textPattern.toLowerCase())
    //    if (indexT != -1)
    //    {
    //        let textOffset = QuickTableUtils.GetWidth(ctx, this.nodeText.substring(0, indexT))
    //        let oldFillStyle = ctx.fillStyle;
    //        ctx.fillStyle = App.ThemeManager.CurrentTheme.qtTextPatternColor;
    //        ctx.fillRect(this.TextRectangle.X + 4 + textOffset, this.TextRectangle.Y, patternW, this.TextRectangle.Height - 10)
    //        ctx.fillStyle = oldFillStyle;
    //    }
    // }
    }

    public drawCollapseIcon (ctx: CanvasRenderingContext2D, hovered: boolean): void {
        InstrumentLookupQuickTreeNode.DrawCollapseIcon(ctx, hovered, this.collapsed, this.CollapseRectangle);
    }

    public static DrawCollapseIcon (ctx: CanvasRenderingContext2D, hovered: boolean, collapsed: boolean, rect: Rectangle): void {
        const img = collapsed
            ? ThemeManager.CurrentTheme[hovered ? 'treeCloseHoverImage' : 'treeCloseDefaultImage']
            : ThemeManager.CurrentTheme[hovered ? 'treeOpenHoverImage' : 'treeOpenDefaultImage'];

        if (img && !isNullOrUndefined(ctx) && !isNullOrUndefined(rect)) {
            ctx.drawImage(img, rect.X, rect.Y);
        }
    }
}
