// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';
import { InputParameterCombobox } from '../InputParamaterClasses/InputParameterCombobox';

export class SMMA extends IndicatorScriptBase {
    MAPeriod: number;
    sourcePrices: number;
    constructor () {
        super();
        // #region Initialization
        this.ProjectName = 'Smoothed Moving Average';
        this.Comments = 'Smoothed Moving Average';
        // #endregion

        this.SetIndicatorLine('SMMA', Color.Red, 1, LineStyle.SimpleChart);
        this.SeparateWindow = false;

        this.MAPeriod = 2;
        super.InputParameter(new InputParameterInteger('MAPeriod', 'Period of Smoothed Moving Average:', 0, 1, 99999, 0, 1));

        this.sourcePrices = PriceType.Close;
        super.InputParameter(new InputParameterCombobox('sourcePrices', 'Sources prices for MA', 0,
            [
                { Close: PriceType.Close },
                { Open: PriceType.Open },
                { High: PriceType.High },
                { Low: PriceType.Low },
                { Typical: PriceType.Typical },
                { Medium: PriceType.Medium },
                { Weighted: PriceType.Weighted }
            ]));
    }

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
    };

    public override GetIndicatorShortName (): string {
        return 'SMMA(' + this.MAPeriod + ')';
    };

    public OnQuote (): void {
        super.OnQuote();

        const count = this.CurrentData.Count;

        if (count <= this.MAPeriod) { return; };

        if (count === (this.MAPeriod + 1)) {
            let sum = 0;
            for (let i = 0; i < this.MAPeriod; i++) { sum += this.CurrentData.GetPrice(this.sourcePrices, i); }

            this.SetValue(0, 0, sum / this.MAPeriod);
        } else {
            const prevMa = this.GetValue(0, 1);

            this.SetValue(0, 0, (prevMa * (this.MAPeriod - 1) + this.CurrentData.GetPrice(this.sourcePrices, 0)) / this.MAPeriod);
        }
    };
}
