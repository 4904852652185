// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { TerceraButton } from "../elements/TerceraButton.ts"
import { MDButton } from "./MDButton.js"
import { SessionOperations } from "../../Utils/Enums/Constants.ts"
import { PlacedFrom } from "../../Utils/Trading/PlacedFrom.ts"
import { TradingLockUtils } from "../../Utils/TradingLockUtils.ts"
import { IsAllowed } from "../../Commons/IsAllowed.ts"
import { DataCache } from "../../Commons/DataCache.ts"
import { TerceraButtonEvents, TerceraButtonStyle } from "../../Utils/Enums/ButtonEnums.ts";

export let CancelOrdersMDButton = MDButton.extend({
    data: function ()
    {
        return {
            locKey: 'Panels.MarketDepth.CancelAll',
            terceraButtonStyle: TerceraButtonStyle.Standard,
            instrument: null,
            account: null
        }
    },

    lastUpdateTradingStatusId: null
})

CancelOrdersMDButton.prototype.oninit = function ()
{
    MDButton.prototype.oninit.call(this)
    let dc = DataCache
    dc.OnAddOrder.Subscribe(this.deferUpdateTradingAllowedStatus, this)
    dc.OnRemoveOrder.Subscribe(this.deferUpdateTradingAllowedStatus, this)
    dc.OnAddSLOrderToPosition.Subscribe(this.deferUpdateTradingAllowedStatus, this)
    dc.OnRemoveSLOrderFromPosition.Subscribe(this.deferUpdateTradingAllowedStatus, this)
    dc.OnAddTPOrderToPosition.Subscribe(this.deferUpdateTradingAllowedStatus, this)
    dc.OnRemoveTPOrderFromPosition.Subscribe(this.deferUpdateTradingAllowedStatus, this)
    this.observe('instrument account', this.deferUpdateTradingAllowedStatus)

    TradingLockUtils.TradingLock.TradingLockChanged.Subscribe(
        this.deferUpdateTradingAllowedStatus, this)

    this.on(TerceraButtonEvents.onClick, this.cancelAllOrders)
}

CancelOrdersMDButton.prototype.dispose = function ()
{
    MDButton.prototype.dispose.call(this)
    let dc = DataCache
    dc.OnAddOrder.UnSubscribe(this.deferUpdateTradingAllowedStatus, this)
    dc.OnRemoveOrder.UnSubscribe(this.deferUpdateTradingAllowedStatus, this)
    dc.OnAddSLOrderToPosition.UnSubscribe(this.deferUpdateTradingAllowedStatus, this)
    dc.OnRemoveSLOrderFromPosition.UnSubscribe(this.deferUpdateTradingAllowedStatus, this)
    dc.OnAddTPOrderToPosition.UnSubscribe(this.deferUpdateTradingAllowedStatus, this)
    dc.OnRemoveTPOrderFromPosition.UnSubscribe(this.deferUpdateTradingAllowedStatus, this)

    TradingLockUtils.TradingLock.TradingLockChanged.UnSubscribe(
        this.deferUpdateTradingAllowedStatus, this)

    if (this.lastUpdateTradingStatusId)
    {
        clearTimeout(this.lastUpdateTradingStatusId)
        this.lastUpdateTradingStatusId = null
    }
}

// TODO. Optimization workaround - deferred execution.
CancelOrdersMDButton.prototype.deferUpdateTradingAllowedStatus = function ()
{
    if (this.lastUpdateTradingStatusId)
        clearTimeout(this.lastUpdateTradingStatusId)

    let self = this

    this.lastUpdateTradingStatusId = setTimeout(
        function () { self.updateTradingAllowedStatus() },
        500)
}

CancelOrdersMDButton.prototype.updateTradingAllowedStatus = function ()
{
    let dc = DataCache, ins = this.get('instrument'), acc = this.get('account')
    let orderDict = dc.getOrdersByInstrumentAndAccount(ins, acc)
    const isTradingAllowed = IsAllowed.IsTradingAllowed([acc], ins, -1, SessionOperations.Cancel, null, true);
    this.set('tradingAllowed', !!(orderDict && Object.keys(orderDict).length && isTradingAllowed.Allowed));
    this.set('tradingForbiddenReason', isTradingAllowed.ReasonText);
}

// TODO. Optimize. UpdateTradingAllowedStatus after every OnAddOrder/OrRemoveOrder events.
CancelOrdersMDButton.prototype.cancelAllOrders = function ()
{
    let dc = DataCache
    let orderDict = dc.getOrdersByInstrumentAndAccount(
        this.get('instrument'), this.get('account'))

    dc.FOrderExecutor.cancelOrdersByIdPromise(Object.keys(orderDict), true, PlacedFrom.WEB_MARKETDEPTH)
}