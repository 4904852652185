// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { ReportMessageImportanceLevel, ReportMessageSource } from '../../Utils/Enums/Constants';
import { Message } from './Message';
import { type Instrument } from '../../Commons/cache/Instrument';
import { type TooltipType } from '../../Utils/ReportMessageTooltip';

export class DirectReportMessage extends DirectMessageBaseClass {
    public Name: any;
    public Data: any[];
    public RawMessage: any;
    public Roundtrip = 0;
    public Instrument: Instrument | null = null;
    public ImportanceLevel = ReportMessageImportanceLevel.Normal;
    // TODO. UGLY. Refactor. Remove when BusinessRejectMessage is treated correctly.
    public reject = false;

    public SkipOnTicketViewer = false;
    public TypeId: any;
    public BusinessRejectCode: number;
    public XMLText: string;
    public tooltipDrawType: TooltipType;
    public source = ReportMessageSource.Unknown;

    // TODO. ReportMessage: XMLMessage
    constructor (name?, data?, rawMessage: any = undefined) {
        super(Message.CODE_BUSINESS_REJECT_MESSAGE);

        this.Name = name;
        this.Data = data;
        this.RawMessage = rawMessage;
    }
}
