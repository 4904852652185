// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { TimeSpanPeriods } from "../../Utils/Time/TimeSpan.ts";
import { Resources, CurrentLang, LOCALE_ES, LOCALE_EN, LOCALE_RU, LOCALE_PT, LOCALE_AR, LOCALE_CH_SM, LOCALE_CH_TR, LOCALE_DE } from "../../Commons/properties/Resources.ts";
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";
import { TradingToolsScreenTemplate } from "../../templates.js";
import { TerceraWindowBase } from "./TerceraWindowBase.js";
import { DataCache } from "../../Commons/DataCache.ts";
import { Md5 } from "ts-md5";


export let TradingToolsScreen = TerceraWindowBase.extend({
    partials: { bodyPartial: TradingToolsScreenTemplate },
    data: function ()
    {
        return {
            showHeader: true,
            showFooter: false,
            resizable: false,
            alwaysMiddle: false,
            showBorder: true,
            width: 309,
            height: 207,
            tradingCentralTool: null,
            autochartistTool: null,
            style_addition_body: 'tradingTools-AdditionBody',
            style_addition_header: 'tradingTools-AdditionHeader',
        };
    },
});

let instance = null;

TradingToolsScreen.prototype.getType = function () { return 'TradingToolsScreen' };

TradingToolsScreen.prototype.oncomplete = function ()
{
    TerceraWindowBase.prototype.oncomplete.apply(this);

    this.getLinks()

    this.on('tradingCentralLinkClick', this.tradingCentralLinkClick)
    this.on("autochartistLinkClick", this.autochartistLinkClick)

    this.center()
    this.localize()
};

TradingToolsScreen.prototype.getLinks = function ()
{
    this.set('tradingCentralTool', DataCache.ExternalLinksCache.TradingCentralExternalTool)
    this.set('autochartistTool', DataCache.ExternalLinksCache.AutoChartistExternalTool)
}

TradingToolsScreen.getTradingCentralLink = function ()
{
    let tool = DataCache.ExternalLinksCache.TradingCentralExternalTool

    if (!tool)
        return null

    let culture = CurrentLang === LOCALE_ES ? 'es-ES' : 'en-US',
        userID = DataCache.getUserID(),
        POSIXtimestamp = Math.round(Date.now() / TimeSpanPeriods.TicksPerSecond)

    let url = tool.tcrpUrl,
        key = window.atob(tool.tcrpEncryptionKey),
        initialToken = tool.tcrpPartnerID + ',' + userID + ',' + culture + ',' + POSIXtimestamp,
        token = blowfish.encrypt(initialToken, key, { cipherMode: 0, outputType: 0 });
    //#89126
    return url + encodeURIComponent(token)
}

TradingToolsScreen.prototype.getAutochartistLink = function ()
{
    let tool = this.get('autochartistTool')

    if (!tool)
        return null

    let url = tool.url + (tool.url.indexOf('?') == -1 ? '?' : '')        // #92836

    let indexToInsert = url.indexOf('://') + 3

    url = url.substring(0, indexToInsert) + tool.ssoDNS + '.' + url.substring(indexToInsert)                    // insert ssoDNS

    url = url.substr(0, url.indexOf('?')) + tool.ssoEndPoint + '?'                                              // insert ssoEndPoint

    let culture = TradingToolsScreen.getCultureForACRP(),
        userID = DataCache.getUserID(),
        POSIXtimestamp = Math.round(Date.now() / TimeSpanPeriods.TicksPerSecond),
        accTypeForToken = tool.accountType == 'LIVE' ? '0|' : '1',
        expiry = tool.accountType == 'LIVE' ? POSIXtimestamp : '',
        token = Md5.hashStr(userID + '|' + accTypeForToken + expiry + tool.secretKey),
        expireStr = tool.accountType == 'LIVE' ? '&expire=' + (POSIXtimestamp) : ''

    url += 'username=' + userID + '&account_type=' + tool.accountType + expireStr + '&logintoken=' + token + '&locale=' + culture

    return url
}

TradingToolsScreen.getAutochartist_iFrameLink = function (tool)
{
    if (!tool)
        return null

    let url = tool.url + (tool.url.indexOf('?') == -1 ? '?' : '')   // #92836

    let culture = TradingToolsScreen.getCultureForACRP(),
        userID = DataCache.getUserID(),
        POSIXtimestamp = Math.round(Date.now() / TimeSpanPeriods.TicksPerSecond),
        accTypeForToken = tool.accountType == 'LIVE' ? '0|' : '1',
        expiry = tool.accountType == 'LIVE' ? (POSIXtimestamp + 86400) : '',
        token = Md5.hashStr(userID + '|' + accTypeForToken + expiry + tool.secretKey)

    url += 'broker_id=' + tool.brokerId + '&account_type=' + tool.accountType + '&user=' + userID + '&token=' + token +
        '&locale=' + culture + '&trade_now=n&layout=horizontal&css=https://www.autochartist.com/components-css/traderevolution-to.css' + '&expire=' + expiry

    return url
}

TradingToolsScreen.prototype.localize = function ()
{
    this.set({
        header: Resources.getResource('screen.tradingTools.header'),
    });
};

TradingToolsScreen.prototype.autochartistLinkClick = function ()
{
    window.open(this.getAutochartistLink())
};

TradingToolsScreen.prototype.tradingCentralLinkClick = function ()
{
    window.open(TradingToolsScreen.getTradingCentralLink())
};

TradingToolsScreen.show = function ()
{
    if (!instance)
    {
        instance = new TradingToolsScreen();
        instance.on('teardown', function ()
        {
            instance = null;
        });
        MainWindowManager.MainWindow.addControl(instance);
    }

    instance.setFocus();
};

TradingToolsScreen.getCultureForACRP = function ()
{
    let appLang = CurrentLang

    switch (appLang)
    {
        case LOCALE_EN:
            return 'en_GB'
        case LOCALE_RU:
            return 'ru_RU'
        case LOCALE_PT:
            return 'pt_PT'
        case LOCALE_AR:
            return 'ar_AE'
        case LOCALE_CH_SM:
        case LOCALE_CH_TR:
            return 'zh_CN'
        case LOCALE_ES:
            return 'es_ES'
        case LOCALE_DE:
            return 'de_DE'
        default:
            return 'en_GB'
    }
}