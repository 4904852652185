// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

export enum TerceraChartCashItemSeriesCacheScreenDataDateChangeType {
    None = 0,
    Day = 1,
    Week = 2,
    Month = 3,
    Year = 4
}

export enum TerceraChartCashItemSeriesDataType {
    Absolute = 0,
    Relative = 1,
    Log = 2
}

export enum TerceraChartCashItemSeriesDataTypeBasisType {
    BeginOfScreen = 0,
    BeginOfData = 1,
    BeginOfDay = 2
}
