import { Cursors } from '../../Commons/Cursors';
import { Point, Rectangle, Size } from '../../Commons/Geometry';
import { Pen, SolidBrush, type Font } from '../../Commons/Graphics';
import { ThemeManager } from '../../Controls/misc/ThemeManager';
import { type TerceraChartBase } from '../TerceraChartBase';
import { type TerceraChartWindowsContainer } from '../TerceraChartWindowsContainer';
import { type TerceraChartMouseEventArgs } from '../Utils/TerceraChartMouseEventArgs';
import { type TerceraChartWindowBase } from '../Windows/TerceraChartWindowBase';
import { TerceraChartBaseRenderer } from './TerceraChartBaseRenderer';

export class DateRangeBorder {
    public isVisible: boolean = true;
    public isCollapsed: boolean = false;
    public Label: string = '';
    public AdditionalLabel: string = '';
    public Value: number = NaN;
    public GetText (): string {
        return this.Label + (isValidString(this.AdditionalLabel) ? this.AdditionalLabel : '');
    }
}

export class TerceraChartRangeRenderer<Chart extends TerceraChartBase = TerceraChartBase> extends TerceraChartBaseRenderer<Chart> {
    private _font: Font = ThemeManager.Fonts.Font_11F25_regular;
    private _isHovered: boolean = false;
    private _isMoving: boolean = false;
    private _isCollapseButtonHovered: boolean = false;
    private _hoveredBorder: DateRangeBorder = null;
    private _movingBorder: DateRangeBorder = null;

    public foregroundColor: string = '';
    public backgroundColor: string = '';

    public readonly leftBorder: DateRangeBorder = new DateRangeBorder();
    public readonly rightBorder: DateRangeBorder = new DateRangeBorder();

    constructor (chart: Chart) {
        super(chart);
        this.UseInAutoscale = false;
    }

    public override Draw (gr: CanvasRenderingContext2D, window: TerceraChartWindowBase, windowsContainer: TerceraChartWindowsContainer, advParams: any): void {
        if (!this.Visible) {
            return;
        }

        const windowClientRectangle = window.ClientRectangle;
        if (this.leftBorder.isVisible && !isNaN(this.leftBorder.Value)) {
            const screenX = window.PointsConverter.GetScreenX(this.leftBorder.Value);
            const isVisible = windowClientRectangle.Contains(screenX, windowClientRectangle.Y);
            if (isVisible) {
                this.DrawLine(gr, windowClientRectangle, screenX, this.leftBorder.isCollapsed, this.leftBorder.GetText(), true);
                this.DrawPlate(gr, window, windowsContainer, this.leftBorder.Value);
            }
        }
        if (this.rightBorder.isVisible && !isNaN(this.rightBorder.Value)) {
            const screenX = window.PointsConverter.GetScreenX(this.rightBorder.Value);
            const isVisible = windowClientRectangle.Contains(screenX, windowClientRectangle.Y);
            if (isVisible) {
                this.DrawLine(gr, windowClientRectangle, screenX, this.rightBorder.isCollapsed, this.rightBorder.GetText(), false);
                this.DrawPlate(gr, window, windowsContainer, this.rightBorder.Value);
            }
        }
    }

    private DrawLine (gr: CanvasRenderingContext2D, windowClientRectangle: Rectangle, screenX: number, isCollapsed: boolean, text: string, isLeftBorder: boolean): void {
        const textWidth = 88;
        const collapseButtonRectangle = new Rectangle(screenX - this._font.Height / 2 - 1, windowClientRectangle.Top() + 35 + (isCollapsed ? 0 : textWidth), this._font.Height + 2, 15);
        const plateRectangle = new Rectangle(collapseButtonRectangle.X, windowClientRectangle.Top(), collapseButtonRectangle.Width, collapseButtonRectangle.Bottom() - windowClientRectangle.Top());
        const backgroundColor = this.backgroundColor;
        const transparentBackgroundColor = `${backgroundColor}C8`;
        const haloPenColor = `${backgroundColor}${this._isHovered ? '96' : '32'}`;
        const backgroundBrush = new SolidBrush(backgroundColor);
        const transparentBackgroundBrush = new SolidBrush(transparentBackgroundColor);
        const linePen = new Pen(backgroundColor, 1);
        const haloPen = new Pen(haloPenColor, 7);

        gr.DrawLine(haloPen, screenX, plateRectangle.Bottom(), screenX, windowClientRectangle.Bottom());
        gr.DrawLine(linePen, screenX, plateRectangle.Bottom(), screenX, windowClientRectangle.Bottom());
        gr.RoundRectangle(plateRectangle, 2, transparentBackgroundBrush, linePen, undefined, undefined);
        gr.RoundRectangle(collapseButtonRectangle, 2, backgroundBrush, linePen, undefined, undefined);

        const foregroundBrush = new SolidBrush(this.foregroundColor);
        const foregroundPen = new Pen(this.foregroundColor, 1);
        const arrowSize = new Size(3, 5);
        const arrowRect = new Rectangle(plateRectangle.X + (plateRectangle.Width - arrowSize.Width) / 2, plateRectangle.Y + 15, arrowSize.Width, arrowSize.Height);
        const dotsRect = new Rectangle(collapseButtonRectangle.X + 1, collapseButtonRectangle.Y + 2, collapseButtonRectangle.Width, collapseButtonRectangle.Height / 2);
        const arrowPoints: Point[] = [];
        if (isLeftBorder) {
            arrowPoints.push(new Point(arrowRect.X, arrowRect.Y));
            arrowPoints.push(new Point(arrowRect.X + arrowRect.Width, arrowRect.Y + arrowRect.Height / 2));
            arrowPoints.push(new Point(arrowRect.X, arrowRect.Y + arrowRect.Height));
        } else {
            arrowPoints.push(new Point(arrowRect.X + arrowRect.Width, arrowRect.Y));
            arrowPoints.push(new Point(arrowRect.X, arrowRect.Y + arrowRect.Height / 2));
            arrowPoints.push(new Point(arrowRect.X + arrowRect.Width, arrowRect.Y + arrowRect.Height));
        }

        gr.DrawLines(foregroundPen, arrowPoints);
        gr.DrawStringInRect('..', this._font, foregroundBrush, dotsRect, false);

        if (!isCollapsed && isValidString(text)) {
            gr.save();
            gr.translate(plateRectangle.X + plateRectangle.Width / 2, plateRectangle.Y + plateRectangle.Height / 2);
            gr.rotate(Math.PI / 2 * -1);
            gr.translate(-(plateRectangle.X + plateRectangle.Width / 2), -(plateRectangle.Y + plateRectangle.Height / 2));
            gr.DrawString(text, this._font, foregroundBrush, plateRectangle.X - plateRectangle.Height / 2 + collapseButtonRectangle.Height + 15, plateRectangle.Y + Math.floor(plateRectangle.Height / 2) + 2, 'left', 'middle');
            gr.restore();
        }
    }

    private DrawPlate (gr: CanvasRenderingContext2D, window: TerceraChartWindowBase, windowsContainer: TerceraChartWindowsContainer, dataX: number): void {
        if (isNullOrUndefined(windowsContainer.xScaleRenderer) || !windowsContainer.xScaleRenderer.Visible) {
            return;
        }
        const screenX = window.PointsConverter.GetScreenX(dataX);
        const formattedDataX = windowsContainer.xScaleRenderer.FormatValue(dataX);
        const textWidth = gr.GetTextWidth(formattedDataX, windowsContainer.xScaleRenderer.settings.ScaleFont);
        const xScaleRect = windowsContainer.xScaleRenderer.Rectangle;
        const xScaleFont = windowsContainer.xScaleRenderer.settings.ScaleFont;
        const xPlateRect = new Rectangle(screenX - (textWidth / 2) - 3, xScaleRect.Y, textWidth + 6, xScaleRect.Height);
        if (xPlateRect.Left() >= xScaleRect.Left() && xPlateRect.Right() <= xScaleRect.Right()) {
            const backgroundBrush = new SolidBrush(`${this.backgroundColor}C8`);
            const foregroundBrush = new SolidBrush(this.foregroundColor);
            gr.FillRect(backgroundBrush, xPlateRect.X, xPlateRect.Y, xPlateRect.Width, xPlateRect.Height);
            gr.DrawStringInRect(formattedDataX, xScaleFont, foregroundBrush, xPlateRect, false);
        }
    }

    public override ThemeChanged (): void {
        super.ThemeChanged();
        this.foregroundColor = ThemeManager.CurrentTheme.Chart_RangeRendererForeColor;
        this.backgroundColor = ThemeManager.CurrentTheme.Chart_RangeRendererBackColor;
    }

    public override Localize (): void {
        super.Localize();
        this._font = ThemeManager.Fonts.Font_11F25_regular;
    }

    public override ProcessMouseDown (e: TerceraChartMouseEventArgs): boolean {
        if (!this.Visible) {
            return false;
        }
        let processed = super.ProcessMouseMove(e);
        if (isNullOrUndefined(e.window)) {
            return processed;
        }
        if (this._isCollapseButtonHovered) {
            this.leftBorder.isCollapsed = !this.leftBorder.isCollapsed;
            this.rightBorder.isCollapsed = !this.rightBorder.isCollapsed;
            this._isCollapseButtonHovered = false;
            processed = true;
        } else if (this._isHovered) {
            this._isMoving = true;
            this._movingBorder = this._hoveredBorder;
            processed = true;
        }
        if (processed) {
            e.NeedRedraw = true;
        }
        return processed;
    }

    public override ProcessMouseUp (e: TerceraChartMouseEventArgs): boolean {
        if (!this.Visible) {
            return false;
        }
        let processed = super.ProcessMouseMove(e);
        if (isNullOrUndefined(e.window)) {
            return processed;
        }

        if (this._isMoving) {
            this._isMoving = false;
            this._movingBorder = null;
            processed = true;
        }
        return processed;
    }

    public override ProcessMouseMove (e: TerceraChartMouseEventArgs): boolean {
        if (!this.Visible) {
            return false;
        }
        let processed = super.ProcessMouseMove(e);
        if (isNullOrUndefined(e.window)) {
            return processed;
        }
        let isHovered: boolean = false;
        let isCollapserHovered: boolean = false;
        let hoveredBorder: DateRangeBorder = null;
        let isMoved: boolean = false;
        const hoverOffset = (this._font.Height + 2 / 2);
        if (this.leftBorder.isVisible && !isNaN(this.leftBorder.Value)) {
            const screenX = e.window.PointsConverter.GetScreenX(this.leftBorder.Value);
            if (e.Location.X >= screenX - hoverOffset && e.Location.X <= screenX + hoverOffset) {
                isHovered = true;
                hoveredBorder = this.leftBorder;
                const collapseButtonRectangle = new Rectangle(screenX - this._font.Height / 2 - 1, e.window.ClientRectangle.Top() + 35 + (this.leftBorder.isCollapsed ? 0 : 88), this._font.Height + 2, 15);
                if (collapseButtonRectangle.Contains(e.Location.X, e.Location.Y)) {
                    isCollapserHovered = true;
                }
            }
            if (this._isMoving && this._movingBorder === this.leftBorder) {
                this.leftBorder.Value = e.window.PointsConverter.GetDataX(e.Location.X);
                isMoved = true;
            }
        }
        if (this.rightBorder.isVisible && !isNaN(this.rightBorder.Value)) {
            const screenX = e.window.PointsConverter.GetScreenX(this.rightBorder.Value);
            if (e.Location.X >= screenX - hoverOffset && e.Location.X <= screenX + hoverOffset) {
                isHovered = true;
                hoveredBorder = this.rightBorder;
                const collapseButtonRectangle = new Rectangle(screenX - this._font.Height / 2 - 1, e.window.ClientRectangle.Top() + 35 + (this.rightBorder.isCollapsed ? 0 : 88), this._font.Height + 2, 15);
                if (collapseButtonRectangle.Contains(e.Location.X, e.Location.Y)) {
                    isCollapserHovered = true;
                }
            }
            if (this._isMoving && this._movingBorder === this.rightBorder) {
                this.rightBorder.Value = e.window.PointsConverter.GetDataX(e.Location.X);
                isMoved = true;
            }
        }
        this._hoveredBorder = hoveredBorder;
        if (this._isHovered !== isHovered) {
            this._isHovered = isHovered;
            processed = true;
        }
        if (isMoved) {
            processed = true;
        }
        if (this._isCollapseButtonHovered !== isCollapserHovered) {
            this._isCollapseButtonHovered = isCollapserHovered;
            processed = true;
        }
        e.NeedRedraw = processed;
        return processed;
    }

    public override GetCursor (e: TerceraChartMouseEventArgs): Cursors | null {
        let cursor = super.GetCursor(e);
        if (this._isCollapseButtonHovered) {
            cursor = Cursors.Hand;
        } else if (this._isHovered || this._isMoving) {
            cursor = Cursors.VSplit;
        }
        return cursor;
    }
}
