// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { HistoryType } from '../../Utils/History/HistoryType';
import { CustomEvent } from '../../Utils/CustomEvents';
import { InstrumentSpecificType } from '../../Utils/Instruments/InstrumentSpecificType';
import { QuoteSubscriptionItem } from './QuoteSubscriptionItem';
import { type Instrument } from './Instrument';
import { type ReloadHistoryParams } from '../../Utils/History/ReloadHistoryParams';

export class QuoteCache {
    public DataCache: any;
    public subsDict: any = {};
    public onRequestQuotesSubscription = new CustomEvent();
    public onCancelQuotesSubscription = new CustomEvent();
    public serverTimeZone = 0;
    public onReloadHistory: any = null;
    public SubsRoutesMap: any = {};

    constructor (dataCache) {
        this.DataCache = dataCache;
    }

    public async callOnReloadHistory (historyParams: ReloadHistoryParams, signal: AbortSignal): Promise<any> {
        if (!this.onReloadHistory) {
            return [];
        }

        const intervals = await this.onReloadHistory(this, historyParams, signal);
        return intervals;
    }

    public newQuoteMessage (msg): void {
        let subsItem = null;

        subsItem = this.getSubscriptionItemByMsg(msg);

        const SubsItems = this.SubsRoutesMap[msg.Route];
        if (SubsItems) {
            const Items = SubsItems.GetItems();
            for (let i = 0, len = Items.length; i < len; i++) {
                msg.Route = Items[i];
                this.newQuoteMessage(msg);
            }
        }

        if (!subsItem) {
            return;
        }

        if (subsItem.HasContiniousContractItem) {
            msg.TargetInstrumentName = subsItem.InstrumentContiniousContractItem.instrument.GetInteriorID();
            subsItem.InstrumentContiniousContractItem.newQuote(msg);
        }
        msg.TargetInstrumentName = subsItem.instrument.GetInteriorID();
        subsItem.newQuote(msg);
    }

    public addListener (instrument: Instrument, listenerObject, quoteType, isDataSource: boolean = undefined): void {
        if (!this.DataCache.Loaded) {
            return;
        }

        const subsRoute = this.DataCache.getRouteByName(instrument.Route);

        if (subsRoute && subsRoute.QuoteRouteId !== subsRoute.RouteId) {
            const quoteSubsRoute = this.DataCache.getRouteById(subsRoute.QuoteRouteId);
            if (quoteSubsRoute) {
                if (instrument.RoutesSupportedArray.indexOf(quoteSubsRoute.RouteId) === -1) {
                    return;
                }

                let ItemSubs = this.SubsRoutesMap[quoteSubsRoute.RouteId];
                if (!ItemSubs) {
                    ItemSubs = new SubscriptionMapItem(quoteSubsRoute.RouteId);
                    this.SubsRoutesMap[quoteSubsRoute.RouteId] = ItemSubs;
                }
                ItemSubs.Add(subsRoute.RouteId);
            }
        }

        if (subsRoute && !subsRoute.CanSubscribe()) {
            return;
        }

        let delayQuotes = [];
        if (instrument.InstrumentSpecificType === InstrumentSpecificType.ContiniousContract) {
            const subIns = this.DataCache.getInstrument(instrument.InstrumentTradableID.toString(), instrument.Route);
            if (subIns) {
                this.addListener(subIns, listenerObject, quoteType);

                const subInsSubsItem = this.getSubscriptionItem(subIns);
                // if subIns already subscribed. Send all quotes
                if (subInsSubsItem?.lastQuoteDict) {
                    for (const q in subInsSubsItem.lastQuoteDict) {
                        delayQuotes.push(subInsSubsItem.lastQuoteDict[q]);
                    }
                }
                delayQuotes = delayQuotes.concat(subIns.GeneratedInstrumentDayBarMessages());
            }
        }

        const subsItem = this.getSubscriptionItem(instrument);
        const listenersArray = subsItem.getListenerArray(quoteType);

        for (const q of delayQuotes) {
            subsItem.newQuote(q);
        }

        const isSubscribed = listenersArray.indexOf(listenerObject) >= 0;

        const needSubscribeMsg = listenersArray.length === 0 && (quoteType != HistoryType.QUOTE_INSTRUMENT_DAY_BAR || isDataSource);

        if (!isSubscribed) {
            subsItem.addListener(listenerObject, quoteType);
        }

        if (needSubscribeMsg) {
            this.onRequestQuotesSubscription.Raise(instrument.InstrumentTradableID, instrument.Route, quoteType, false, isDataSource || false);
        }

        if (quoteType != HistoryType.QUOTE_INSTRUMENT_DAY_BAR && !isDataSource) // подписка на IDBM сопровождает любую другую подписку (кроме подписки такого же типа или из дата сорс)
        {
            this.addListener(instrument, listenerObject, HistoryType.QUOTE_INSTRUMENT_DAY_BAR, isDataSource);
        } // потому даже если меседж на подписку не нужен (его успел раньше отправить DataSource), надо все равно зарегистрировать слушателя как подписанного на IDBM (иначе при закрытии дата сорса произойдет отписка от IDBM)
    }

    public removeListener (instrument: Instrument, listenerObject, quoteType, isDataSource: boolean = undefined): void {
        if (instrument.InstrumentSpecificType === InstrumentSpecificType.ContiniousContract) {
            const subIns = this.DataCache.getInstrument(instrument.InstrumentTradableID.toString(), instrument.Route);
            if (subIns) {
                this.removeListener(subIns, listenerObject, quoteType);
            }

            const subsItem = this.getSubscriptionItem(instrument, false);
            if (!subsItem) {
                return;
            }
            const oldListenersCount = subsItem.getListenerCount(quoteType);

            subsItem.removeListener(listenerObject, quoteType);

            const newListenersCount = subsItem.getListenerCount(quoteType);
            const wasRemovedListener = oldListenersCount != newListenersCount;
            if (quoteType != HistoryType.QUOTE_INSTRUMENT_DAY_BAR && !isDataSource && wasRemovedListener) {
                this.removeListener(instrument, listenerObject, HistoryType.QUOTE_INSTRUMENT_DAY_BAR, isDataSource);
            }

            return;
        }

        const subsItem = this.getSubscriptionItem(instrument, false);
        if (!subsItem) {
            return;
        }
        let unsubscribe = true;
        const oldListenersCount = subsItem.getListenerCount(quoteType);

        subsItem.removeListener(listenerObject, quoteType);

        const newListenersCount = subsItem.getListenerCount(quoteType);
        const wasRemovedListener = oldListenersCount != newListenersCount;

        unsubscribe = wasRemovedListener && newListenersCount === 0 && (quoteType != HistoryType.QUOTE_INSTRUMENT_DAY_BAR || isDataSource);

        if (unsubscribe) {
            this.onCancelQuotesSubscription.Raise(instrument.InstrumentTradableID, instrument.Route, quoteType, false, isDataSource || false);
        }

        if (quoteType != HistoryType.QUOTE_INSTRUMENT_DAY_BAR && !isDataSource && wasRemovedListener) {
            this.removeListener(instrument, listenerObject, HistoryType.QUOTE_INSTRUMENT_DAY_BAR, isDataSource);
        }
    }

    public reSubscribe (subscribe, instrumentList): void {
        if (!instrumentList.length) {
            return;
        }

        const allSubsItems = this.getAllSubscriptionItemArray();

        for (let i = 0; i < allSubsItems.length; i++) {
            const si = allSubsItems[i];
            const ins = si.instrument;
            if (instrumentList.indexOf(ins) === -1) {
                continue;
            }

            const ld = si.listenerDict;

            for (const l in ld) {
                const arr = ld[l];
                if (!arr.length) {
                    continue;
                }

                if (subscribe) {
                    this.onRequestQuotesSubscription.Raise(ins.InstrumentTradableID, ins.Route, parseInt(l), true);
                } else {
                    this.onCancelQuotesSubscription.Raise(ins.InstrumentTradableID, ins.Route, parseInt(l), true);
                }
            }
        }
    }

    public getAllSubscriptionItemArray (): any[] {
        const result = [];
        for (const sdi in this.subsDict) {
            for (const qsi in this.subsDict[sdi]) {
                result.push(this.subsDict[sdi][qsi]);
            }
        }
        return result;
    }

    // TODO. Rename. Creates subs item as well if doesn't exist.
    public getSubscriptionItem (instrument: Instrument | null, createSubItem = true): any {
        if (!instrument) return null;

        // идентификаторы
        const route = instrument.Route;
        const tradableId = instrument.InstrumentTradableID;

        let subsItem = null;
        let routeItem = this.subsDict[route];

        // let routeObj = DataCache.getRouteByName(route);
        // if (!routeObj)
        //    routeObj = DataCache.getInfoRouteByName(route);
        // if (routeObj && routeObj.QuoteRouteId !== route)
        //    route = routeObj.QuoteRouteId;

        const isContinious = instrument.InstrumentSpecificType === InstrumentSpecificType.ContiniousContract;
        // subsDict структура object[route] => object[tradableId]  =>  item
        if (!routeItem) {
            routeItem = new Object();
            this.subsDict[route] = routeItem;
        }

        subsItem = routeItem[tradableId];

        if (!subsItem && createSubItem) {
            subsItem = new QuoteSubscriptionItem(instrument, this);
            this.subsDict[route][tradableId] = subsItem;
        }

        if (!subsItem) {
            return null;
        }

        if (isContinious) {
            if (!subsItem.HasContiniousContractItem) {
                subsItem.InstrumentContiniousContractItem = new QuoteSubscriptionItem(instrument, this);
                subsItem.HasContiniousContractItem = true;
            }
            subsItem = subsItem.InstrumentContiniousContractItem;
        }

        return subsItem;
    }

    public getSubscriptionItemByMsg (msg): any {
        if (!msg) {
            return null;
        }

        const routeItem = this.subsDict[msg.Route];
        if (!routeItem) {
            return null;
        }

        const subsItem = routeItem[msg.InstrumentTradableID];
        if (!subsItem) {
            return null;
        }

        return subsItem;
    }

    public resubscribe (): any {
        // TODO.
    }

    public clear (): void {
        const subsDict = this.subsDict;
        for (const key in subsDict) {
            const subsItem = subsDict[key];
            for (const s in subsItem) {
                subsItem[s].dispose();
            }
        }
        this.subsDict = {};
        this.SubsRoutesMap = {};
    }

    public GetLevel2Cash (instrument: Instrument): any {
        const subsItem = this.getSubscriptionItem(instrument);
        if (subsItem) {
            return subsItem.lvl2CashItem;
        }

        return null;
    }
}

class SubscriptionMapItem {
    public RouteIdMap = {};
    public RouteIdArray = [];
    public RouteId: any;

    constructor (RouteId) {
        this.RouteId = RouteId;
    }

    public Add (routeId): void {
        if (this.RouteIdMap[routeId]) {
            return;
        }

        this.RouteIdMap[routeId] = true;
        this.RouteIdArray.push(routeId);
    }

    public GetItems (): any[] {
        return this.RouteIdArray;
    }
}
