// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
export enum Cursors {
    SizeNS = 'ns-resize;',
    Hand = 'pointer',
    SizeWE = 'ew-resize',
    SizeAll = 'move',
    HSplit = 'row-resize',
    VSplit = 'col-resize',
    Default = 'default',
    Cross = 'crosshair',
    eraserCursor = 'url("current_theme/light/h_chart/cursors/tool_eraser.png") 6 4, auto',
    No = 'not-allowed',
    NoMoveVert = 'ns-resize',
    buySellCursor = 'url("current_theme/dark/darkNew/h_chart/cursors/buySellCursor.png") 6 12, auto',
}
