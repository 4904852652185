// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { messageBoxHandler } from "../../../Utils/AppHandlers.js";
import { PositionSizeButtonTemplate } from "../../../templates.js";
import { ContainerControl } from "../../elements/ContainerControl.js";
import { LOCALE_EN, Resources } from "../../../Commons/properties/Resources.ts";
import { AccountType } from "../../../Utils/Account/AccountType.ts";
import { RulesSet } from "../../../Utils/Rules/RulesSet.ts";
import { GeneralSettings } from "../../../Utils/GeneralSettings/GeneralSettings.ts";
import { IsAllowed } from "../../../Commons/IsAllowed.ts";
import { ConfirmationTypesEnum } from "../../../Utils/Trading/ConfirmationTypesEnum.ts";
import { DataCache } from "../../../Commons/DataCache.ts";

export let PositionSizeButton = ContainerControl.extend({
    template: PositionSizeButtonTemplate,
    data: function ()
    {
        return {
            checked: false,
            instrument: null,
            account: null,
            positionSizingEnabled: true
        }
    }
})

PositionSizeButton.prototype.getType = function () { return 'PositionSizeButton' };

PositionSizeButton.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.call(this)

    this.on('onClick', this.onClick)
    Resources.onLocaleChange.Subscribe(this.localize, this)
    DataCache.OnTradingSessionStatusUpdated.Subscribe(this.updateEnability, this)
    GeneralSettings.SettingsChanged.Subscribe(this.updateEnability, this)

    this.observe('account instrument', this.updateEnability)

    this.localize()
}

PositionSizeButton.prototype.onClick = function ()
{
    if (!this.get('positionSizingEnabled'))
        return

    let checked = this.get('checked'),
        newCheckedState = !checked

    this.set('checked', newCheckedState)

    let confirmationType = ConfirmationTypesEnum.PositionSizeCalculatorInfoWindow
    if (newCheckedState && GeneralSettings.Confirmations.useConfirmation(confirmationType))
    {
        let OkCb = function (showNextTime)
        {
            GeneralSettings.Confirmations.updateConfirmation(confirmationType, showNextTime)
        }

        let infoText = Resources.getResource('positionSizeCalculator.infoText')

        if (!Resources.isHidden('positionSizeCalculator.helpLink'))
        {
            let linkTxt = Resources.getResource('positionSizeCalculator.infoText.linkPart')
            let linkUrl = Resources.getResourceLang('positionSizeCalculator.helpLink', LOCALE_EN)
            let linkPartSplitted = linkTxt.split('{0}')
            infoText += ' ' + (linkPartSplitted[0] ? linkPartSplitted[0] : '') +
                // '<div class="link-container-in-pos-sizing-info">' + (linkPartSplitted[0] ? linkPartSplitted[0] : '') +  // https://tp.traderevolution.com/entity/107977 не удалял насовсем вариант с переносом на новую строку ибо он мне нравится больше, пускай хоть комментарием останется :)
                '<a target="_blank" class="link-in-pos-sizing-info" rel="noopener noreferrer" href="' + linkUrl + '">' +
                (linkPartSplitted[1] ? linkPartSplitted[1] : '') +
                '</a>' + (linkPartSplitted[2] ? linkPartSplitted[2] : '')
            // + '</div>'
        }

        messageBoxHandler.Show(Resources.getResource('positionSizeCalculator.header'),
            infoText,
            messageBoxHandler.msgType.Info, OkCb, null, true, true, null);
    }
}

PositionSizeButton.prototype.dispose = function ()
{
    Resources.onLocaleChange.UnSubscribe(this.localize, this)
    DataCache.OnTradingSessionStatusUpdated.UnSubscribe(this.updateEnability, this)
    GeneralSettings.SettingsChanged.UnSubscribe(this.updateEnability, this)

    ContainerControl.prototype.dispose.call(this)
}

PositionSizeButton.prototype.localize = function ()
{
    let tooltipLocKey = this.isAllowedToUse() ?
        'positionSizeCalculator.button.tt' :
        'positionSizeCalculator.buttonDisabled.tt'

    this.set('tooltip', Resources.getResource(tooltipLocKey))
}


PositionSizeButton.prototype.updateEnability = function ()
{
    let enabled = this.isAllowedToUse()
    this.set('positionSizingEnabled', enabled)

    if (!enabled)
        this.set('checked', false)

    this.localize()
}

PositionSizeButton.prototype.isAllowedToUse = function ()
{
    let acc = this.get('account')
    if (!acc) return false

    let isSLAllowed = DataCache.isAllowedForAccount(RulesSet.FUNCTION_SLTP, acc)
        && IsAllowed.IsSLTPAllowed(this.get('instrument'), acc, true).Allowed

    if (acc.AccountType === AccountType.MultiAsset)
        isSLAllowed = false

    return isSLAllowed
}