// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { SlTpPriceType } from '../../Utils/Enums/Constants';
import { Message } from './Message';
import { ProductType } from '../Instruments/ProductType';
import { OperationType } from '../Trading/OperationType';
import { type OrderStatus } from '../Trading/OrderStatus';
import { type OrderExecutionType } from '../Trading/OrderExecutionType';

export class DirectOpenOrderMessage extends DirectMessageBaseClass {
    // значение sl в цене или тиках в зависимости от SlPriceType
    public StopLossPriceValue = NaN;
    // значение tp в цене или тиках в зависимости от TPPriceType
    public TakeProfitPriceValue = NaN;
    public StopLossPriceType = SlTpPriceType.Absolute;
    public TakeProfitPriceType = SlTpPriceType.Absolute;

    public OrderWasManual: boolean | null = null;
    public Account: string | null = null;
    public BuySell: OperationType = OperationType.Buy;
    public OrderNumber: string | null = null;
    public OrderType: number | null = 0;
    public Price = 0;
    public LastPrice: number | null = null;
    public Quantity = 0;
    public LastAmount: number | null = null;
    public RemoteID: string | null = null;
    public Replaceable = false;
    public AutoTradeOrder = false;
    public Route: number | null = null;
    public SharesFilled = 0;
    public EverageFilledPrice = 0;
    public StopLimit = 0;
    public Symbol: any = null;
    // Под нефикс список, для прочих - бери Symbol и не парься
    public InstrumentID = 0;
    public UTCDateTime: Date | null = null;
    public TimeInForce = 0;
    public Token = '-1';
    public ExecutionType: OrderExecutionType | null = null;
    public OrderStatus: OrderStatus | null = null;
    public PositionId = '-1';

    // для GTD
    public ExpireTime: Date | null = null;
    public ProductType = ProductType.General;

    private boundToOrderId = '-1';

    public OCOGroupID: any = null;

    public IsActivated = false; // #92963
    private active = false;
    // признак что ордер открывающий
    public IsOpen = false;
    // #34674
    private realactive = false;

    public OrderGroupId = 0;

    public Exchange: any = null;
    public InitMargin = 0;
    public Roundtrip = 0;

    public TriggerSL: any = null; // #109798
    public TriggerTP: any = null;
    public TriggerSLTP: any = null;

    public TradingSignalId = 0;
    // Управление разрешением SLTP на уровне отдельного ордера
    // заводили под интеграции, нашим сервером пока не используется
    public SLTPAllowed = true;

    // Все параметры ордера
    public AdvancedOrderTypeParameters: any = null;

    public InstrumentTradableID: number | null = null;
    public ClientOrderId: string;
    public ExternalTradeId: string;
    public Leverage: number;

    // TODO. Refactor? Remove?
    private stopLossLimit = 0; // #91413
    private takeProfitLimit = 0;

    private disClosedQuantity: number | null = null;

    private comment: string | null = null;
    private lackOfMargin: number | null = null; // #94155

    private FMasterID: string | null = null;

    private FOrderCreatedByUser: string | null = null;

    private FLastUpdateTime: Date | null = null;
    private trStopOffset = 0;

    private externalOrderId: string | null = null;
    private externalStatus = 0;
    // Панель с которой был отправлен Ордер
    private FClientPanelId = 0;

    constructor () {
        super(Message.CODE_OPENORDER);
    }

    // TODO. Refactor? Remove?
    get StopLossLimit (): number {
        return this.stopLossLimit;
    }

    set StopLossLimit (val) {
        this.stopLossLimit = val;
    }

    // TODO. Refactor? Remove?
    get TakeProfitLimit (): number {
        return this.takeProfitLimit;
    }

    set TakeProfitLimit (val) {
        this.takeProfitLimit = val;
    }

    get Comment (): string | null {
        return this.comment;
    }

    set Comment (val) {
        this.comment = val;
    }

    // #94155
    get LackOfMargin (): number | null {
        return this.lackOfMargin;
    }

    set LackOfMargin (val) {
        this.lackOfMargin = val;
    }

    get BoundToOrderId (): string {
        return this.boundToOrderId;
    }

    set BoundToOrderId (val) {
        this.boundToOrderId = val;
    }

    get Active (): boolean {
        return this.active;
    }

    set Active (val) {
        this.active = val;
    }

    // #34674
    get RealActive (): boolean {
        return this.realactive;
    }

    set RealActive (val) {
        this.realactive = val;
    }

    get MasterID (): string | null {
        return this.FMasterID;
    }

    set MasterID (val) {
        this.FMasterID = val;
    }

    get OrderCreatedByUser (): string | null {
        return this.FOrderCreatedByUser;
    }

    set OrderCreatedByUser (val) {
        this.FOrderCreatedByUser = val;
    }

    get LastUpdateTime (): Date | null {
        return this.FLastUpdateTime;
    }

    set LastUpdateTime (val) {
        this.FLastUpdateTime = val;
    }

    // Офсет (только для трейлинг стопа)
    get TrStopOffset (): number {
        return this.trStopOffset;
    }

    set TrStopOffset (val) {
        this.trStopOffset = val;
    }

    get ExternalOrderId (): string | null {
        return this.externalOrderId;
    }

    set ExternalOrderId (val) {
        this.externalOrderId = val;
    }

    get ExternalStatus (): number {
        return this.externalStatus;
    }

    set ExternalStatus (val) {
        this.externalStatus = val;
    }

    // Панель с которой был отправлен Ордер
    get ClientPanelId (): number {
        return this.FClientPanelId;
    }

    set ClientPanelId (val) {
        this.FClientPanelId = val;
    }

    get DisClosedQuantity (): number | null {
        return this.disClosedQuantity;
    }

    set DisClosedQuantity (val) {
        this.disClosedQuantity = val;
    }
}
