// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type NewTrade } from '../NewTrade';
import { DateTimeUtils } from '../../../Utils/Time/DateTimeUtils';
import { OperationType } from '../../../Utils/Trading/OperationType';
import { GeneralSettingsWrapper } from '../../../WebMobileReact/Models/GeneralSettingsWrapper';
import { DataCache } from '../../DataCache';
import { Resources } from '../../properties/Resources';
import { AccountType } from '../../../Utils/Account/AccountType';
import { InstrumentDeliveryType } from '../../../Utils/Instruments/InstrumentDelivertyType';
import { InstrumentTradingBalance } from '../../../Utils/Instruments/InstrumentTradingBalance';
import { type ProductType, getProductTypeKey } from '../../../Utils/Instruments/ProductType';
import { InstrumentUtils } from '../../../Utils/Instruments/InstrumentUtils';
import { CurrencyUtils } from '../../../Utils/Asset/CurrencyUtils';
import { type Instrument } from '../Instrument';
import { type Account } from '../../../Commons/cache/Account';
import { type Asset } from '../../../Commons/cache/Asset';
import { BoughtSoldUtils } from '../../../Utils/Instruments/BoughtSoldUtils';

export class FilledOrderFormatter {
    static TradeId (trade: NewTrade): number { return parseInt(trade.TradeId.split('-')[0]); }
    static TradeIdStr (trade: NewTrade): string { return trade.TradeId; }

    static IdStr (trade: NewTrade): string { return trade.OrderId; }

    static SymbolStr (trade: NewTrade): string {
        const instrument: Instrument | null | undefined = trade.Instrument;
        const instrumentStr: string | null | undefined = trade.InstrumentStr;
        if (instrument !== null && instrument !== undefined) {
            return instrument.DisplayShortName();
        } else if (instrumentStr !== null && instrumentStr !== undefined) {
            return trade.InstrumentStr;
        } else {
            return '';
        }
    }

    static AccountStr (trade: NewTrade): string { return trade.Account?.toString() ?? ''; }

    static IsLong (trade: NewTrade): boolean { return trade.BuySell === OperationType.Buy; }

    static OrderTypeStr (trade: NewTrade): string {
        const orderTypeObj = DataCache.OrderParameterContainer.GetOrderType(trade.OrderType);
        return orderTypeObj ? Resources.getResource('property.' + orderTypeObj.localizationKey()) : '';
    }

    static Quantity (trade: NewTrade): number {
        if (GeneralSettingsWrapper.isQuantityInLots) {
            return trade.Amount;
        } else {
            const instrument: Instrument | null | undefined = trade.Instrument;
            if (instrument !== null && instrument !== undefined) {
                return trade.Amount * instrument.LotSize;
            } else {
                return trade.Amount;
            }
        }
    }

    static QuantityStr (trade: NewTrade): string {
        const amount = this.Quantity(trade);
        let amountStr = amount.toString();
        if (trade.Instrument !== null) { amountStr = DataCache.formatVolume(trade.Instrument, Math.abs(amount), GeneralSettingsWrapper.isQuantityInLots, trade.ProductType); }
        return amountStr;
    }

    static Price (trade: NewTrade): number { return trade.Price; }
    static PriceStr (trade: NewTrade): string {
        const price = this.Price(trade);
        const instrument: Instrument | null | undefined = trade.Instrument;
        if (instrument !== null && instrument !== undefined) {
            return instrument.formatPricePrecision(price, false, false);
        } else {
            return price.toString();
        }
    }

    static GrossPnL (trade: NewTrade): number {
        return trade.PnL * trade.OpenCrossPrise;
    }

    static GrossPnLStr (trade: NewTrade): string {
        const profit = this.GrossPnL(trade);
        if (profit === Number.MAX_VALUE) { return Resources.getResource('general.N_A'); } else { return this.FormatPriceAtCorrectAsset(trade, profit); }
    }

    static NetPnL (trade: NewTrade): number {
        const netPnL = trade.PnL - trade.Comission + trade.Swap;
        return netPnL * trade.OpenCrossPrise;
    }

    static NetPnLStr (trade: NewTrade): string {
        const profit = this.NetPnL(trade);
        if (profit === Number.MAX_VALUE) { return Resources.getResource('general.N_A'); } else { return this.FormatPriceAtCorrectAsset(trade, profit); }
    }

    static ExecutionFee (trade: NewTrade): number {
        if (trade.Comission === Number.MAX_VALUE) {
            return trade.Comission;
        }
        let comission = -trade.Comission;
        if (comission === 0) {
            comission = 0;
        }; // prevent minus zero
        return comission * this.CrossPrice(trade);
    }

    static ExecutionFeeStr (trade: NewTrade): string {
        const executionFee = this.ExecutionFee(trade);
        if (executionFee === Number.MAX_VALUE) { return Resources.getResource('general.N_A'); } else { return this.FormatPriceAtCorrectAsset(trade, executionFee); }
    }

    static GetBought (trade: NewTrade): number {
        return BoughtSoldUtils.Bought(trade);
    }

    static GetSold (trade: NewTrade): number {
        return BoughtSoldUtils.Sold(trade);
    }

    static GetBoughtStr (trade: NewTrade): string {
        return BoughtSoldUtils.BoughtStr(trade);
    }

    static GetSoldStr (trade: NewTrade): string {
        return BoughtSoldUtils.SoldStr(trade);
    }

    static Date (trade: NewTrade): Date { return trade.Time; }
    static DateStr (trade: NewTrade): string {
        return DateTimeUtils.formatDate(this.Date(trade), 'DD.MM.YYYY  HH:mm:ss');
    }

    static TradeVolume (trade: NewTrade): number { return trade.getTradeVolume(); }
    static TradeVolumeStr (trade: NewTrade): string {
        const tradeVolume = this.TradeVolume(trade);
        return this.FormatPriceAtCorrectAsset(trade, tradeVolume);
    }

    static Exposure (trade: NewTrade): number {
        const instrument: Instrument | null | undefined = trade.Instrument;
        const cross = this.CrossPrice(trade);
        if (instrument !== null && instrument !== undefined) {
            return (trade.Price * trade.Amount * instrument.LotSize * instrument.GetTickCost()) * cross;
        } else if (trade.Exposure > 0) {
            return trade.Exposure * cross;
        } else {
            return (trade.Price * trade.Amount) * cross;
        }
    }

    static ExposureStr (trade: NewTrade): string {
        const exposure = this.Exposure(trade);
        const account: Account | null | undefined = trade.Account;
        if (account !== null && account !== undefined) {
            let formattedPrice: string = '';
            let currency: string = '';
            const instrument: Instrument | null | undefined = trade.Instrument;
            if (trade.Account.AccountType === AccountType.MultiAsset && instrument !== null && instrument !== undefined) {
                const exp2Asset: Asset | null | undefined = trade.Instrument.DataCache.GetAssetByName(instrument.Exp2);
                if (exp2Asset !== null && exp2Asset !== undefined) {
                    formattedPrice = exp2Asset.formatPrice(exposure, true);
                    currency = exp2Asset.Name;
                } else {
                    formattedPrice = exposure.toString();
                    currency = trade.Instrument.Exp2;
                }
            } else {
                currency = trade.Account.assetBalanceDefault?.Asset?.Name;
                formattedPrice = trade.Account.formatPrice(exposure, true);
            }
            const [currencyStr, hasSymbol] = CurrencyUtils.GetCurrecySymbol(currency);
            if (hasSymbol) { return `${currencyStr} ${formattedPrice}`; } else {
                return `${formattedPrice} ${currencyStr}`;
            }
        } else {
            return '';
        }
    }

    static ProductType (trade: NewTrade): ProductType { return trade.ProductType; }
    static ProductTypeStr (trade: NewTrade): string {
        const instrument: Instrument | null | undefined = trade.Instrument;
        if (instrument !== null && instrument !== undefined) {
            return InstrumentUtils.GetLocalizedProductType(instrument, trade.ProductType);
        } else {
            return Resources.getResource('ProductType.' + getProductTypeKey(trade.ProductType));
        }
    }

    static TradingExchangeStr (trade: NewTrade): string { return trade.TradingExchange; }

    private static InBaseCurrency (): boolean {
        return DataCache.filledOrdersCurrency === DataCache.baseCurrency;
    }

    private static CrossPrice (trade: NewTrade): number {
        if (this.InBaseCurrency()) {
            const instrument = trade.Instrument;
            if (!isNullOrUndefined(instrument)) {
                return trade.Instrument.DataCache.CrossRateCache.GetCrossPriceIns(instrument);
            } else {
                return 1;
            }
        } else {
            return trade.OpenCrossPrise;
        }
    }

    private static FormatPriceAtCorrectAsset (trade: NewTrade, price: number): string {
        const acc: Account | null | undefined = trade.Account;
        const instrument: Instrument | null | undefined = trade.Instrument;
        if (acc !== null && acc !== undefined) {
            let formattedPrice: string;
            let currency: string;
            if (acc.AccountType === AccountType.MultiAsset && instrument !== null && instrument !== undefined) {
                currency = this.GetTargetCurrency(trade);
                const asset: Asset | null | undefined = acc.GetAssetBalanceByName(currency)?.Asset;
                formattedPrice = asset !== null && asset !== undefined ? asset.formatPrice(price, true) : price.toString();
            } else {
                currency = acc.assetBalanceDefault?.Asset?.Name;
                formattedPrice = acc.formatPrice(price, true);
            }
            const [currencyStr, hasSymbol] = CurrencyUtils.GetCurrecySymbol(currency);
            if (hasSymbol) {
                return `${currencyStr} ${formattedPrice}`;
            } else {
                return `${formattedPrice} ${currencyStr}`;
            }
        } else { return price.toString(); }
    }

    private static GetTargetCurrency (trade: NewTrade): string {
        const account = trade.Account;
        const instrument = trade.Instrument;
        const isBuy = this.IsLong(trade);
        if (account.AccountType === AccountType.MultiAsset) {
            if (instrument.DeliveryMethod === InstrumentDeliveryType.Delivery_Physical && instrument.TradingBalance === InstrumentTradingBalance.SETTLEMENT_IMMEDIATE) {
                return isBuy ? instrument.Exp1 : instrument.Exp2;
            }
            return instrument.Exp2;
        }
        return instrument.Exp2;
    }
}
