// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts"
import { TerceraButton } from "../elements/TerceraButton.ts"

export let MDButton = TerceraButton.extend({
    data: function ()
    {
        return {
            left: 0,
            right: 0,
            height: 25,
            tradingAllowed: false,
            tradingForbiddenReason: '',
            locKey: '',
            style: ''
        }
    }
})

MDButton.prototype.oninit = function ()
{
    TerceraButton.prototype.oninit.call(this)

    this.observe(
        'tradingAllowed tradingForbiddenReason',
        this.onTradingRulesChanged)

    this.observe('locKey', this.localize)
    Resources.onLocaleChange.Subscribe(this.localize, this)
    this.localize()
}

MDButton.prototype.dispose = function ()
{
    Resources.onLocaleChange.UnSubscribe(this.localize, this)

    TerceraButton.prototype.dispose.call(this)
}

MDButton.prototype.localize = function ()
{
    this.set('text', Resources.getResource(this.get('locKey')))
}

MDButton.prototype.onTradingRulesChanged = function ()
{
    let tradingAllowed = this.get('tradingAllowed')
    let tradingForbiddenReason = this.get('tradingForbiddenReason')

    this.set({
        enabled: tradingAllowed,
        tooltip:
            tradingAllowed || !tradingForbiddenReason
                ? ''
                : tradingForbiddenReason
    })
}