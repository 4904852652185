// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { TerceraButton } from "../elements/TerceraButton.ts"
import { MDButton } from "./MDButton.js";
import { OperationType } from "../../Utils/Trading/OperationType.ts"
import { TerceraButtonStyle } from "../../Utils/Enums/ButtonEnums.ts";

export let BuySellMDButton = MDButton.extend({
    data: function ()
    {
        return {
            side: null
        }
    }
})

BuySellMDButton.prototype.oninit = function ()
{
    MDButton.prototype.oninit.call(this)
    this.observe('side', this.onSideChanged)
}

BuySellMDButton.prototype.onSideChanged = function (side)
{
    let buy = side === OperationType.Buy
    this.set(
        {
            terceraButtonStyle: TerceraButtonStyle[buy ? 'Buy' : 'Sell'],
            locKey: buy ? 'panel.Level2.oe.BuyButton' : 'panel.Level2.oe.SellButton'
        })
}