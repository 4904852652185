export class VariableTick {
    public LeftBorder: number;
    public RightBorder: number;
    public IncludeLeftBorder: any;
    public PointSize: any;
    public TickCost: any;

    constructor (dataObj) {
        this.LeftBorder = dataObj.lowLimit || 0;
        this.RightBorder = dataObj.highLimit || 0;
        this.IncludeLeftBorder = dataObj.allowLimit;
        this.PointSize = dataObj.pointSize;
        this.TickCost = dataObj.tickCost;
    }

    /// <summary>
    /// проверка на вхождение
    /// </summary>
    public CheckPrice (price): boolean {
        if (isNaN(price)) // если цена NaN - не находит никакой интервал
        {
            price = -1;
        }; // пока преобразовываем в -1 для проверки, чтоб было по старой схеме. TODO: подумать над решением лучше

        return (price > this.LeftBorder && price < this.RightBorder) ||
        (price === this.LeftBorder && this.IncludeLeftBorder) ||
        (price === this.RightBorder && !this.IncludeLeftBorder);
    }

    public toString (): string {
        return this.LeftBorder + ((this.IncludeLeftBorder && this.LeftBorder !== Number.POSITIVE_INFINITY) ? '<= ' : '< ') + 'price' +
        ((!this.IncludeLeftBorder && this.RightBorder !== Number.NEGATIVE_INFINITY) ? '<= ' : '< ') + this.RightBorder;
    }
}
