// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { SLTPEdit } from './SLTPEdit';
import { InstrumentUtils } from '../../../Utils/Instruments/InstrumentUtils';
import { OperationType } from '../../../Utils/Trading/OperationType';
import { OrderType } from '../../../Utils/Trading/OrderType';
import { OrderUtils } from '../../../Utils/Trading/OrderUtils';

// TODO. Refactor. Duplicate of OrderSLTPEdit.
export class PositionSLTPEdit extends SLTPEdit {
    public AdditionalIntervalVerification (): boolean {
        return true;
    }

    // TODO. Refactor. Rename.
    public override updateOffsetTypes (): void {
        super.updateOffsetTypes();
        // Always absolute prices.
        this.sl.offsetType = this.tp.offsetType = null;
    }

    // TODO. Refactor. Ugly.
    public override update (updateData, tradingData): boolean {
        let parameterChanged = super.update(updateData, tradingData);

        const newTradingDataDict = updateData.newTradingDataDict;
        if (!newTradingDataDict) {
            return parameterChanged;
        }

        // Init position sl/tp values.
        const position = newTradingDataDict.position;
        if (!position) {
            return parameterChanged;
        }

        const tpOrder = position.TPOrder;
        if (tpOrder) {
            this.tp.enabled = true;
            // SL/TP are always in absolute for position.
            this.tp.value = tpOrder.Price;
            parameterChanged = true || parameterChanged;
        }

        const slOrder = position.SLOrder;
        if (!slOrder) {
            return parameterChanged;
        }

        // TODO. Ugly.
        this.sl.enabled = true;

        const isTrStop = slOrder.OrderType === OrderType.TrailingStop;
        if (isTrStop) {
            this.trailingStop.enabled = true;

            const quotePrice = this.getQuotePriceForCalculatingOffset(
                tradingData.instrument,
                tradingData.account,
                tradingData.side,
                tradingData.quote);

            const newTicks = InstrumentUtils.getPriceDifferenceInTicks(
                slOrder.Price, quotePrice, tradingData.instrument);

            this.trailingStop.value = OrderUtils.ConvertTickOffset(
                tradingData.instrument,
                this.trailingStop.offsetType,
                0,
                newTicks);
        } else {
            // SL/TP are always in absolute for position.
            this.sl.value = slOrder.getPriceForStopLoss();
            if (slOrder.StopLimit > 0) {
                this.sl.sllValue = slOrder.Price;
            }
        }

        parameterChanged = true || parameterChanged;

        return parameterChanged;
    }

    // TODO. Refactor. Ugly.
    public override setDefaultSLTP (tradingData): boolean {
        const position = tradingData.position;
        if (!position) {
            return super.setDefaultSLTP(tradingData);
        }

        const orderTypes = OrderType;

        const slOrder = position.SLOrder;
        const tpOrder = position.TPOrder;

        return super.setDefaultSLTP(
            tradingData,
            !slOrder || slOrder.OrderType === orderTypes.TrailingStop,
            !tpOrder,
            !slOrder || slOrder.OrderType !== orderTypes.TrailingStop);
    }

    public getQuotePriceForCalculatingOffset (instrument, account, side, quote): number {
        if (!quote) {
            return 0;
        }

        const sp = this.DataCache.GetSpreadPlan(account);

        return side === OperationType.Buy
            ? quote.BidSpread_SP_Ins(sp, instrument)
            : quote.AskSpread_SP_Ins(sp, instrument);
    }
}
