// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CreateMapByObject } from '../../../Utils/CreateMapByObjectUtils';
import { type DirectEntitlementProductMessage, DirectEntitlementProductSubscriptionResponseMessage } from '../../../Utils/DirectMessages/DirectMessagesImport';
import { type EntitlmentProductDefinition } from './EntitlmentProductDefinition';

export class EntitlementHistory {
    public Id: any;
    public Name: any;

    public ReqType: any = null;
    public ReqStatus: any = null;
    public ReqTime: any = null;
    public Comment: any = null;

    constructor (id, name) {
        this.Id = id;
        this.Name = name;
    }

    public UpdateHistoryStatus (responseMessage): void {
        this.ReqType = responseMessage.ReqType;
        this.ReqStatus = responseMessage.ReqStatus;
        this.ReqTime = responseMessage.ReqTime;
        this.Comment = responseMessage.Comment;
    }
}

export class EntitlementProduct {
    public Id: any = null;
    public Name: any = null;
    public Description: any = null;
    public Category: any = null;
    public CurrencyId: any = null;
    public ProPrice: any = null;
    public NonProPrice: any = null;
    public HasDocument: any = null;
    public ImageBytes: any = null;
    public DocumentArray: any = null; // save here last documents when subscribe via settings panel (#109924)
    public ProductDataType: any = null; // value from EntitlementProductDataType
    public ShowInSettings: any = null; // only for products with ProductDataType = EntitlementProductDataType.Notifications
    public ColorHEX: any = null; // color for Notifications panel

    public ReqType = EntitlementProductRequestType.None;
    public ReqStatus = EntitlementProductRequestStatus.None;
    public ReqTime: any = null;
    public ReqTimeCreated: any = null;

    public isNewProduct = false;
    public isSubscribeActionDone = false;
    public isWaitForUnsubscribe = false;
    public isUnsubscribeActionDone = false;
    public isWaitForSubscribe = false;

    public StatusUpdateHandler: any = null;

    public IsUnsubscribeAllowed: any = null;
    public IsActive = true;

    public Comment: any;
    public ProductDefinitions: EntitlmentProductDefinition [];
    public QuoteDelay: number = 0;

    constructor (message: DirectEntitlementProductMessage) {
        this.UpdateProduct(message);
    }

    public UpdateProduct (message: DirectEntitlementProductMessage): void {
        this.Id = message.Id;
        this.Name = message.Name;
        this.Description = message.Description;
        this.Category = message.Category;
        this.CurrencyId = message.CurrencyId;
        this.ProPrice = message.ProPrice;
        this.NonProPrice = message.NonProPrice;
        this.HasDocument = message.HasDocument;
        this.ImageBytes = message.ImageBytes;
        this.ProductDefinitions = message.ProductDefinitions;
        this.IsUnsubscribeAllowed = message.IsUnsubscribeAllowed;
        this.IsActive = message.IsActive;
        this.ProductDataType = message.ProductDataType;
        this.ShowInSettings = message.ShowInSettings;
        this.ColorHEX = message.ColorHEX;
        this.QuoteDelay = message.QuoteDelay;
    }

    public UpdateProductStatus (message): void {
        this.ReqType = message.ReqType;
        this.ReqStatus = message.ReqStatus;
        this.ReqTime = message.ReqTime;
        this.ReqTimeCreated = new Date(message.ReqTime);
        this.Comment = message.Comment;

        this.isNewProduct = this.ReqStatus === EntitlementProductRequestStatus.None && this.ReqType === EntitlementProductRequestType.None;
        if (!this.isSubscribeActionDone) { this.isSubscribeActionDone = this.ReqStatus === EntitlementProductRequestStatus.Approved && this.ReqType === EntitlementProductRequestType.Subscribe; }
        this.isWaitForUnsubscribe = this.ReqStatus === EntitlementProductRequestStatus.Processing && this.ReqType === EntitlementProductRequestType.Unsubscribe;
        this.isUnsubscribeActionDone = this.ReqStatus === EntitlementProductRequestStatus.Approved && this.ReqType === EntitlementProductRequestType.Unsubscribe;
        this.isWaitForSubscribe = this.ReqStatus === EntitlementProductRequestStatus.Processing && this.ReqType === EntitlementProductRequestType.Subscribe;

        if (this.isUnsubscribeActionDone) {
            this.isSubscribeActionDone = this.ReqStatus === EntitlementProductRequestStatus.Approved && this.ReqType === EntitlementProductRequestType.Subscribe;
        } // что-то не так с этим if-ом (во-первых, он такой же как на несколько строк выше. Во-вторых, сюда можно попасть только если isUnsubscribeActionDone == true но тогда внутри if-а всегда будет сетится false )

        this.isSubscribeActionDone = this.isSubscribeActionDone || // а вот такое условие ИМХО стоит добавить иначе продукт не считается Subscribed во время ожидания обработки на отписку (отправили запрос на отписку, но отписка еще не совершена -> но подписка же была когда-то выполнена)
        (this.ReqStatus === EntitlementProductRequestStatus.Processing && this.ReqType === EntitlementProductRequestType.Unsubscribe);

        if (this.StatusUpdateHandler) {
            this.StatusUpdateHandler();
        }
    }

    public IsSubscribed (): boolean {
        return this.isSubscribeActionDone;
    }

    public IsProcessing (): boolean {
        return this.isWaitForSubscribe || this.isWaitForUnsubscribe;
    }

    public IsAvailableForSubscription (): boolean {
        return !this.IsSubscribed(); // #108196
    }

    public IsBelongToFilter (entitlementProductFilter): boolean {
        if (!this.IsActive) {
            return false;
        }

        switch (entitlementProductFilter) {
        case EntitlementProductFilter.All:
            return true; // is correct statement?

        case EntitlementProductFilter.Processing:
            return this.IsProcessing();

        case EntitlementProductFilter.Subscribed:
            return this.IsSubscribed();

        case EntitlementProductFilter.AvailableForSubscription:
            return this.IsAvailableForSubscription();

        case EntitlementProductFilter.SubscribedAndProcessing:
            return this.isWaitForUnsubscribe; // или можно иначе: this.IsSubscribed() && this.IsProcessing()

        case EntitlementProductFilter.NotSubscribedButProcessing:
            return this.isWaitForSubscribe; // или можно иначе: !this.IsSubscribed() && this.IsProcessing()
        }

        return false;
    }

    public GenerateSubscriptionResponseMessage (): DirectEntitlementProductSubscriptionResponseMessage {
        const message = new DirectEntitlementProductSubscriptionResponseMessage();

        message.Id = this.Id;
        message.ReqStatus = this.ReqStatus;
        message.ReqType = this.ReqType;
        message.ReqTime = this.ReqTime;
        message.Comment = this.Comment;

        return message;
    }
}

export enum EntitlementProductDataType {
    Market = 0,
    Fundamental = 1,
    Notifications = 2
}

export enum EntitlementProductRequestType {
    None = -1,
    Subscribe = 0,
    Unsubscribe = 1,
    Cancel = 2
};
export const EntitlementProductRequestTypeMap = CreateMapByObject(EntitlementProductRequestType);

export enum EntitlementProductRequestStatus {
    None = -1,
    Processing = 0,
    Approved = 1,
    Rejected = 2,
    Canceled = 3
};

export const EntitlementProductRequestStatusMap = CreateMapByObject(EntitlementProductRequestStatus);

export enum EntitlementProductFilter // по этим значениям можно посчитать кол-во (EntitlementManager.prototype.GetProductCountByFilter) или отфильтровать продукты используя public IsBelongToFilter(<в качестве аргумента передается значение из этого объекта>)
{
    All = -1,
    Processing = 0,
    Subscribed = 1,
    AvailableForSubscription = 2,
    SubscribedAndProcessing = 3, // waiting for unsubscribe approve (not in combobox's variants, but may be usefull)
    NotSubscribedButProcessing = 4 // waiting for   subscribe approve (not in combobox's variants, but may be usefull)
};

export const EntitlementProductFilterMap = CreateMapByObject(EntitlementProductFilter);

export enum EntitlementProductFilterComboBoxVariants // combobox's variants for ProductsPanel's toolbar filter (in correct order: {All, Subscribed, Available for subscription, Processing} )
{
    All = EntitlementProductFilter.All, // -1
    Subscribed = EntitlementProductFilter.Subscribed, // 1
    AvailableForSubscription = EntitlementProductFilter.AvailableForSubscription, // 2
    Processing = EntitlementProductFilter.Processing // 0
};

export const EntitlementProductFilterComboBoxVariantsMap = CreateMapByObject(EntitlementProductFilterComboBoxVariants);
