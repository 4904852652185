// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { ErrorInformationStorage } from '../../../Commons/ErrorInformationStorage';
import { LayersEnum, TerceraChartBaseRenderer } from '../TerceraChartBaseRenderer';
import { SelectionState } from '../../Tools/Selection';
import { ToolViewFactory } from '../../Tools/Utils/ToolViewFactory';
import { SelectionModeEnum } from '../../../Commons/cache/DataCacheToolEnums';
import { DataCacheToolFactory } from '../../../Commons/cache/DataCacheToolFactory';
import { ToolsCache } from '../../../Commons/cache/ToolsCache';
import { MouseButtons } from '../../../Controls/UtilsClasses/ControlsUtils';
import { TerceraChartRendererDrawingAdvancedParamsEnum, TerceraChartToolRendererDrawingType } from '../../Utils/ChartConstants';
import { TerceraChartAction, TerceraChartActionEnum } from '../../TerceraChartAction';
import { TerceraChartUtils } from '../../TerceraChartUtils';
import { ScreenPointsConverterFactory } from '../../Utils/IScreenPointsConverter';
import { CustomEvent } from '../../../Utils/CustomEvents';
import type TerceraChartNewToolRenderer from './TerceraChartNewToolRenderer';
import { type TerceraChart } from '../../TerceraChart';
import { type ToolView } from '../../Tools/ToolView';
import { type TradingToolViewBase } from '../../Tools/TradingTools/TradingToolViewBase';

export class TerceraChartToolRenderer extends TerceraChartBaseRenderer<TerceraChart> {
    public instrument: any;
    public screenPoinsConverter: any;
    public tools: ToolView[];
    public hoverTool: any;
    public movingTools: any[];
    public newToolRenderer: TerceraChartNewToolRenderer;

    public SelectedToolsListChanged: CustomEvent;
    public ToolHovered: CustomEvent;
    public SupportDataConverter: boolean;
    public chartID: number;
    public InstanceWindow: any;

    constructor (chartID: number, chart: TerceraChart, newToolRenderer: TerceraChartNewToolRenderer) {
        super(chart);

        this.SupportDataConverter = true;
        this.chartID = chartID;
        this.newToolRenderer = newToolRenderer;
        this.screenPoinsConverter = null;

        this.SelectedToolsListChanged = new CustomEvent();

        this.tools = [];
        this.hoverTool = null;
        this.movingTools = [];
        this.instrument = null;

        this.ApplyNewSnapMode(false);
        this.Subscribe();

        this.ToolHovered = new CustomEvent();
        this.assignLayer = LayersEnum.Tools;
        this.SetClassName('TerceraChartToolRenderer');
    }

    get Instrument (): any {
        return this.instrument;
    }

    set Instrument (value: any) {
        this.instrument = value;
        this.RepopulateTools();
    }

    HoverToolSet (value: any): void {
        if (this.hoverTool == value) { return; }

        this.hoverTool = value;
        this.OnToolHovered();
    }

    HoverToolGet (): any {
        return this.hoverTool;
    }

    OnToolHovered (): void {
        this.ToolHovered.Raise(this.hoverTool);
    }

    Subscribe (): void {
        ToolsCache.OnAddTool.Subscribe(this.ToolsCache_OnAddTool, this);
        ToolsCache.OnRemoveTool.Subscribe(this.ToolsCache_OnRemoveTool, this);
    }

    ToolsCache_OnAddTool (tool: any): void {
        if (this.instrument == null) { return; }

        if (ToolsCache.CheckToolEnabilityAndUpdateInstrument(tool, this.instrument)) {
            const toolview = ToolViewFactory.CreateToolView(tool);
            this.tools.push(toolview);
        }
    }

    ToolsCache_OnRemoveTool (tool: any): void {
        if (this.instrument == null) { return; }

        const toRemove = this.GetToolViewByDataCacheTool(tool);
        if (toRemove != null) {
            const remIndex = this.tools.indexOf(toRemove);
            this.tools.splice(remIndex, 1);
            if (this.hoverTool === toRemove) { this.HoverToolSet(null); }
            if (toRemove.CurrentSelection.CurrentState == SelectionState.Selected) { this.OnSelectedToolsListChanged(); }

            toRemove.Dispose();
        }
    }

    GetToolViewByDataCacheTool (tool: any): any {
        for (let i = 0; i < this.tools.length; i++) {
            if (this.tools[i].dataCacheTool === tool) { return this.tools[i]; }
        }
        return null;
    }

    RemoveSelectedAndHoverTools (): void {
        const tools = this.tools;
        let i = tools.length;
        while (i--) {
            const tool = tools[i];
            if (tool && tool.CurrentSelection.CurrentState !== SelectionState.None) { this.RemoveTool(tool); }
        }
    }

    RemoveToolsByType (type: any): void {
        const tools = this.tools;
        let i = tools.length;
        while (i--) {
            const tool = tools[i];
            if (tool && tool.dataCacheTool.ToolType === type) { this.RemoveTool(tool); }
        }
    }

    RemoveAllTools (): void {
        const tools = this.tools;
        let i = tools.length;
        while (i--) this.RemoveTool(tools[i]);
    }

    RepopulateTools (): void {
        const filteredTools = ToolsCache.GetTools(this.instrument);
        const newToolsView: any = [];
        for (let i = 0; i < filteredTools.length; i++) {
            newToolsView.push(ToolViewFactory.CreateToolView(filteredTools[i], true));
        }

        this.tools = newToolsView;
        this.OnSelectedToolsListChanged();
    }

    Unsubscribe (): void {
        ToolsCache.OnAddTool.UnSubscribe(this.ToolsCache_OnAddTool, this);
        ToolsCache.OnRemoveTool.UnSubscribe(this.ToolsCache_OnRemoveTool, this);
    }

    Draw (gr: any, window: any, windowsContainer: any, advParams: any = null): void {
        const param = advParams;
        const cashItemSeries = param.mainPriceRenderer.Series;
        const clientRect = window.ClientRectangle;

        gr.save();
        gr.beginPath();
        gr.rect(clientRect.X, clientRect.Y, clientRect.Width, clientRect.Height);
        gr.clip();

        this.DrawAllTools(gr, window, param);

        gr.restore();
    }

    DrawAllTools (gr: any, window: any, param: any): void {
        let curDrawing = TerceraChartToolRendererDrawingType.Background;
        if (param.Tag[TerceraChartRendererDrawingAdvancedParamsEnum.CurrentDrawingType] !== undefined) { curDrawing = param.Tag[TerceraChartRendererDrawingAdvancedParamsEnum.CurrentDrawingType]; }

        const cashItemSeries = param.TerceraChart.mainPriceRenderer.Series;

        for (let i = 0; i < this.tools.length; i++) {
            const tool = this.tools[i];

            const stickToPeriod = tool.dataCacheTool.StickToPeriod;
            if (!stickToPeriod?.length || stickToPeriod.includes(cashItemSeries.CashItem.FPeriod)) {
                tool.UpdateScreenPoints(window, cashItemSeries);
                tool.Draw(gr, window, param);
            }
        }
    }

    Dispose (): void {
        this.Unsubscribe();
        this.chart = null;
        super.Dispose();
    }

    static CheckValidRange (window: any, x: number, y: number): boolean {
        if (window === null) { return false; }

        const rect = window.ClientRectangle;
        return (x >= rect.X && x < (rect.X + rect.Width) && y >= rect.Y && y < (rect.Y + rect.Height));
    }

    CheckValidRange (e: any, x: number, y: number): boolean {
        if (!e) return false;
        const window = e.window;
        if (!window) return false;
        const instanceWindow = this.InstanceWindow;
        if (instanceWindow && window !== instanceWindow) { return false; }
        return TerceraChartToolRenderer.CheckValidRange(window, x, y);
    }

    OnSelectedToolsListChanged (): void {
        this.SelectedToolsListChanged.Raise();
    }

    GetHoverAndSelectedToolsList (): ToolView[] {
        const selectedToolsList: ToolView[] = [];
        const tools = this.tools;
        const len = tools.length;
        for (let i = 0; i < len; i++) {
            const tv = tools[i];
            if (tv && tv.CurrentSelection.CurrentState !== SelectionState.None) {
                selectedToolsList.push(tv);
            }
        }
        return selectedToolsList;
    }

    ApplyNewSnapMode (useSnap: boolean): void {
        this.screenPoinsConverter = ScreenPointsConverterFactory.GetScreenPointsConverter(useSnap);
    }

    IsMyTool (tool: any): boolean {
        return this.tools.includes(tool);
    }

    CloneTool (tool: any, ww: any): void {
        if (!ww) return;
        const clonedDCTool = DataCacheToolFactory.Clone(tool.dataCacheTool);
        TerceraChartUtils.ShiftTool(clonedDCTool, ww);
        ToolsCache.AddTool(clonedDCTool);
    }

    IsNewToolRendererActive (e: any): boolean {
        return (this.newToolRenderer?.CanProcessAction(e.window, e.Location));
    }

    ProcessMouseMove (e: any): boolean {
        try {
            if (this.IsNewToolRendererActive(e)) { return false; }

            const x = e.Location.X;
            const y = e.Location.Y;

            if (this.movingTools.length > 0) {
                if (this.CheckValidRange(e, x, y)) { this.ProcessMovement(e, x, y); }
            } else {
                this.ProcessHoverTool(e, x, y);
            }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            console.log(ex);
        }
        return false;
    }

    ProcessMouseUp (e: any): boolean {
        let result = false;
        try {
            if (this.IsNewToolRendererActive(e)) { return false; }

            if (e.Button == MouseButtons.Left) { result = this.ProcessLeftButtonUp(e); }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            console.log(ex);
        }
        return result;
    }

    ProcessMouseDown (e: any): boolean {
        if (this.IsNewToolRendererActive(e)) { return false; }

        if (e.Button == MouseButtons.Left) {
            try {
                const x = e.Location.X;
                const y = e.Location.Y;

                this.ProcessSelectionList(x, y);

                for (let i = 0; i < this.tools.length; i++) {
                    const tool = this.tools[i];
                    if (tool.ProcessActivate(x, y) && ((tool as TradingToolViewBase).MainOrderVisibility || !(tool as TradingToolViewBase).isTradingTool)) {
                        this.movingTools.push(tool);
                        return true;
                    }
                }
            } catch (ex) {
                ErrorInformationStorage.GetException(ex);
                console.log(ex);
            }
        } else if (e.Button == MouseButtons.Right && (this.movingTools.length > 0)) {
            try {
                for (let i = 0; i < this.movingTools.length; i++) {
                    const tradeTool = this.movingTools[i];
                    tradeTool.CancelMoving();
                    tradeTool.Updated(tradeTool.Order);
                    tradeTool.ProcessDeactivate();
                    const remIndex = this.movingTools.indexOf(tradeTool);
                    this.movingTools.splice(remIndex, 1);
                }
            } catch (ex) {
                ErrorInformationStorage.GetException(ex);
            }
            return true;
        }
        return false;
    }

    ProcessMouseLeave (e: any): void {

    }

    ProcessSelectionList (x: number, y: number): void {
        let raiseEvent = false;
        const controlPressed = false;

        let checkedTool = null;
        for (let i = 0; i < this.tools.length; i++) {
            const tool = this.tools[i];
            if (tool.IsSelectCheck(x, y)) {
                checkedTool = tool;
                if (tool.CurrentSelection.CurrentState == SelectionState.Selected && controlPressed) {
                    tool.CurrentSelection.ClearSelection();
                    tool.ProcessMoveFinish();
                } else {
                    tool.CurrentSelection.CurrentState = SelectionState.Selected;
                    raiseEvent = true;
                }
                break;
            }
        }

        if (checkedTool == null || !controlPressed) {
            for (let i = 0; i < this.tools.length; i++) {
                const tool = this.tools[i];
                if (tool == checkedTool) { continue; }
                if (tool.CurrentSelection.CurrentState == SelectionState.Selected) {
                    tool.CurrentSelection.ClearSelection();
                    tool.ProcessMoveFinish();
                    raiseEvent = true;
                }
            }
        }

        if (raiseEvent) { this.OnSelectedToolsListChanged(); }
    }

    ProcessLeftButtonUp (e: any): boolean {
        const x = e.Location.X;
        const y = e.Location.Y;
        if (this.movingTools.length > 0) { return this.ProcessStopMoving(e); } else { return false; }
    }

    ProcessStopMoving (e?: any): boolean {
        let processed = false;
        for (let i = 0; i < this.movingTools.length; i++) {
            const tool = this.movingTools[i];
            if (tool.CurrentMovement.IsMovingRightNow) {
                tool.ProcessMoveFinish();
                processed = true;
            } else { tool.ProcessClick(e); }

            tool.ProcessDeactivate();
            this.movingTools.splice(i, 1);
        }

        return processed;
    }

    public ProcessMovement (e: any, x: number, y: number): void {
        for (let i = 0; i < this.movingTools.length; i++) {
            const tool = this.movingTools[i];
            const movement = tool.CurrentMovement;

            let screenX = x;
            let screenY = y;
            const refXY: { screenX: number, screenY: number } = { screenX, screenY };
            this.screenPoinsConverter.Convert(e.window, refXY);
            screenX = refXY.screenX;
            screenY = refXY.screenY;

            if (!movement.IsMovingRightNow && tool.CheckMinDistanceForStartMoving(x, y)) {
                // тулза не двигалась - начинаем двигать
                tool.ProcessMoveStart(screenX, screenY);
            }

            if (movement.IsMovingRightNow) {
                // тулза двигается - следим за перемещением
                tool.ProcessNewPosition(e.window, screenX, screenY, this.SupportDataConverter && this.chart != null ? this.chart.mainPriceRenderer.Series : null);
                e.NeedRedraw = LayersEnum.Tools;
            }
        }
    }

    public ProcessHoverTool (e: any, x: number, y: number): boolean {
        let result = false;
        for (let i = 0; i < this.tools.length; i++) {
            const tool = this.tools[i];
            if (tool.IsSelect(x, y) && tool.IsSelectCheck(x, y)) {
                result = true;
                this.ProcessMouseEvents(e, tool, x, y);
                this.HoverToolSet(tool);
                // Обрабатываем только первую попавшуюся тулзу, иначе они сбрасывают друг друга (???)
                break;
            }
        }

        if (!result) {
            this.ProcessMouseEvents(e, null, x, y);
            this.HoverToolSet(null);
        }

        return result;
    }

    public ProcessMouseEvents (e: any, tool: any, x: number, y: number): void {
        if (this.hoverTool == null && tool == null) {
            // ничего не делаю
        } else if (this.hoverTool == null && tool != null) {
            // ховерится новая тулза
            tool.OnMouseEnter(e);
            e.NeedRedraw = LayersEnum.Tools;
        } else if (this.hoverTool != null && tool == null) {
            // убирается ховер с тулзы
            this.hoverTool.OnMouseLeave(e);
            e.NeedRedraw = LayersEnum.Tools;
        } else if (this.hoverTool == tool) {
            // вожу по ховернутой тулзе
            tool.OnMouseMove(e);
        } else {
            // ховер перепрыгнул с тулзы на тулзу
            this.hoverTool.OnMouseLeave(e);
            tool.OnMouseEnter(e);
            e.NeedRedraw = LayersEnum.Tools;
        }
    }

    // Rest of the class members and methods...

    // Context Menu
    public GetContextMenu (e: any, chart: any): any {
        const hoverTool = this.hoverTool;

        if (!hoverTool) {
            return super.GetContextMenu(e, chart);
        }

        // Cancel moving before showing context menu
        this.ProcessStopMoving();
        // Init structure for menu by TerceraChartAction
        const menuItems = [
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.HoverAndSelectedToolSettings, hoverTool) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.CloneTool, hoverTool) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteHoverAndSelectedTools) }
        ];
        // Create items using common functions
        return chart.TerceraChartActionProcessor.CreateMenu(menuItems);
    }

    ProcessMouseDoubleClick (e: any): boolean {
        if (this.IsNewToolRendererActive(e)) {
            return false;
        }

        const chart = this.chart;
        const hoverTool = this.hoverTool;
        if (chart && hoverTool && !hoverTool.isTradingTool) {
            const action = TerceraChartAction.Create(
                TerceraChartActionEnum.HoverAndSelectedToolSettings,
                hoverTool
            );

            chart.TerceraChartActionProcessor.ProcessTerceraChartAction(action);

            return true;
        }

        return false;
    }

    /**
       * Selection of tools by their screenPoints.
       * @param rect The rectangle to select tools.
       */
    SelectToolsByRect (rect: any): void {
        if (!this.tools) return;

        let raiseEvent = false;

        for (let i = 0; i < this.tools.length; i++) {
            const tool = this.tools[i];
            tool.CurrentSelection.ClearSelection();

            for (let j = 0; j < tool.screenPoints.length; j++) {
                const screenPoint = tool.screenPoints[j];
                const scrP0 = screenPoint[0];
                const scrP1 = screenPoint[1];

                let containsPoints = false;

                switch (tool.dataCacheTool.SelectionMode) {
                case SelectionModeEnum.All:
                    containsPoints = rect.Contains(scrP0, scrP1);
                    break;
                case SelectionModeEnum.X:
                    containsPoints = rect.X < scrP0 && rect.X + rect.Width > scrP0;
                    break;
                case SelectionModeEnum.Y:
                    containsPoints = rect.Y < scrP1 && rect.Y + rect.Height > scrP1;
                    break;
                }

                if (containsPoints) {
                    tool.CurrentSelection.CurrentState = SelectionState.Selected;
                    raiseEvent = true;
                    break;
                }
            }
        }

        if (raiseEvent) {
            this.OnSelectedToolsListChanged();
        }
    }

    SelectAllTools (): void {
        const tools = this.tools;
        const len = tools.length;

        for (let i = 0; i < len; i++) {
            const tool = tools[i];
            tool.CurrentSelection.CurrentState = SelectionState.Selected;
        }

        this.OnSelectedToolsListChanged();
    }

    RemoveTool (toolView: any): void {
        if (toolView?.dataCacheTool) {
            ToolsCache.RemoveTool(toolView.dataCacheTool);
        }
    }

    FindMinMax (out_minMax: any, window: any): boolean {
        let min = Number.MAX_VALUE;
        let max = -Number.MAX_VALUE;

        for (let i = 0; i < this.tools.length; i++) {
            const toolView = this.tools[i];
            if (toolView?.dataCacheTool != null) {
                const activeConverter = this.chart.cashItemSeriesSettings.ActiveConverter;

                for (let j = 0; j < toolView.dataCacheTool.Points.length; j++) {
                    let value = toolView.dataCacheTool.Points[j][1];

                    if (activeConverter != null) {
                        value = activeConverter.Calculate(value);
                    }

                    if (value > max) {
                        max = value;
                    }
                    if (value < min) {
                        min = value;
                    }
                }
            }
        }
        out_minMax.tMin = min;
        out_minMax.tMax = max;
        return true;
    }
}
