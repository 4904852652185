// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class PfixOneTimePasswordMessage extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_ONETIME_PASSWORD_MESSAGE, fieldSet);
    }

    public GetPassword (): string {
        return this.getFieldValue(FieldsFactory.FIELD_PASSWORD);
    }

    public GetId (): number {
        return this.getFieldValue(FieldsFactory.FIELD_ID);
    }

    public EncryptionType (): number {
        const field = this.getFieldValue(FieldsFactory.FIELD_ID);
        return field !== null ? field : 0;
    }
}
