// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from "../../Utils/CustomEvents.ts";
import { TerceraColorStyleWidthPickerTemplate } from "../../templates.js";
import { ContainerControl } from "./ContainerControl.js";

export let TerceraColorStyleWidthPicker = ContainerControl.extend({
    data: function ()
    {
        return {
            height: 25,
            labelText: '',
            colorStyleWidth: null,
            selectedLineStyleItem: null,
            selectedLineWidthItem: null,
            defaultLineStyle: null,
            defaultLineWidth: null,
            styleItems: [],
            useStyleProperties: false
        };
    },
    template: TerceraColorStyleWidthPickerTemplate
});

TerceraColorStyleWidthPicker.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.apply(this);
    this.ColorStyleWidthChanged = new CustomEvent();
    this.observe('selectedLineStyleItem selectedLineWidthItem', this.updateLineStyleAndWidth);
    this.observe('colorStyleWidth', this.onColorStyleWidthChanged);
    this.observe('colorStyleWidth', this.onColorStyleWidthChanged);
};

TerceraColorStyleWidthPicker.prototype.updateLineStyleAndWidth = function ()
{
    var colorStyleWidth = this.get('colorStyleWidth');
    if (!colorStyleWidth) return;

    var selectedLineStyleItem = this.get('selectedLineStyleItem');
    if (selectedLineStyleItem)
        this.set('colorStyleWidth.Style', selectedLineStyleItem.value);

    var selectedLineWidthItem = this.get('selectedLineWidthItem');
    if (selectedLineWidthItem)
        this.set('colorStyleWidth.Width', selectedLineWidthItem.value);
};

TerceraColorStyleWidthPicker.prototype.onColorStyleWidthChanged = function (newCSW, oldCSW, keyPath)
{
    if (newCSW && newCSW !== oldCSW)
    {
        this.set({
            defaultLineStyle: newCSW.Style,
            defaultLineWidth: newCSW.Width
        });
    }

    this.ColorStyleWidthChanged.Raise(this, newCSW);
};