// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { OrderTypeBase } from "../../../Commons/cache/OrderParams/order-type/OrderTypeBase.ts";
import { OEOrderTypeSelectorTemplate } from "../../../templates.js";
import { ContainerControl } from "../../elements/ContainerControl.js";
import { Resources, CurrentLang, LOCALE_JA, LOCALE_ES } from "../../../Commons/properties/Resources.ts";
import { OrderType } from "../../../Utils/Trading/OrderType.ts";
import { GeneralSettings } from "../../../Utils/GeneralSettings/GeneralSettings.ts";
import { StopLimitOrderType } from "../../../Commons/cache/OrderParams/order-type/StopLimitOrderType.ts";
import { StopOrderType } from "../../../Commons/cache/OrderParams/order-type/StopOrderType.ts";
import { DataCache } from "../../../Commons/DataCache.ts";
import { OrderExecutorUtils } from "../../../Commons/Trading/OrderExecutorUtils.ts";

export let OEOrderTypeSelector = ContainerControl.extend({
    template: OEOrderTypeSelectorTemplate,
    data: function ()
    {
        return {
            label: '',

            instrument: null,
            account: null,

            items: null,
            selectedItem: null,
            defaultValue: null,

            orderType: null,

            showLabel: true,
            visibleInfoBtn: false,
            infoBtnTooltip: '',
            infoBtnOffset: 0,
            width: 198
        }
    },
    useStopLimitInsteadStop: false
})

OEOrderTypeSelector.prototype.getType = function () { return 'OEOrderTypeSelector' };

OEOrderTypeSelector.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.call(this)
    this.observe('selectedItem', this.updateOrderType)
    this.observe('instrument account', this.repopulate)
    Resources.onLocaleChange.Subscribe(this.localize, this)
    GeneralSettings.SettingsChanged.Subscribe(this.updateUseStopLimitInsteadStopSetting, this)
    this.localize()
}

OEOrderTypeSelector.prototype.dispose = function ()
{
    Resources.onLocaleChange.UnSubscribe(this.localize, this)
    GeneralSettings.SettingsChanged.UnSubscribe(this.updateUseStopLimitInsteadStopSetting, this)

    ContainerControl.prototype.dispose.call(this)
}

OEOrderTypeSelector.prototype.localize = function ()
{
    this.set('label', Resources.getResource('panel.newOrderEntry.typePanel'))

    let items = this.get('items')
    let changes = {}

    for (let i = 0, len = items ? items.length : 0; i < len; i++)
    {
        changes['items.' + i + '.text'] = Resources.getResource(items[i].value.localizationKey())
    }

    this.set(changes)


    this.set('infoBtnTooltip', Resources.getResource('panel.newOrderEntry.stopInfoBtn.tt'))

    this.calcTextWidthForInfoBtnOffset()
}

OEOrderTypeSelector.prototype.updateOrderType = function (selectedItem)
{
    if (!selectedItem)
    {
        this.set('orderType', null)

        return
    }

    let orderType = selectedItem.value

    this.set('orderType', orderType)

    this.updateUseStopLimitInsteadStopSetting()
}

OEOrderTypeSelector.prototype.repopulate = function ()
{
    let instrument = this.get('instrument')
    let account = this.get('account')
    if (!instrument || !account || instrument.IsEmpty)
        return

    let orderTypeDict = OrderExecutorUtils.getAllowedOrderTypeDict(
        account,
        instrument,
        null,
        GeneralSettings.TradingDefaults.OrderType,
        false
    )

    let orderTypeSortedArr =
        OEOrderTypeSelector.createSortedOrderTypeArray(orderTypeDict)

    // TODO. Common method?
    let items = orderTypeSortedArr.map(function (orderType)
    {
        return {
            value: orderType,
            text: Resources.getResource(orderType.localizationKey())
        }
    })

    this.set({
        defaultValue: orderTypeDict[GeneralSettings.TradingDefaults.OrderType],
        items: items
    })
}

OEOrderTypeSelector.prototype.updateUseStopLimitInsteadStopSetting = function ()    // #91413 вызовы методов апдейта
{
    let selectedItem = this.get('selectedItem'),
        orderType = selectedItem ? selectedItem.value : null

    if (!orderType) return

    this.useStopLimitInsteadStop = GeneralSettings.TradingDefaults.UseStopLimitInsteadStop

    let stopOrderTypeSelected = orderType.id() == OrderType.Stop,
        needUseStopLimitInsteadStop = this.useStopLimitInsteadStop && stopOrderTypeSelected

    if (stopOrderTypeSelected && needUseStopLimitInsteadStop)
        this.calcTextWidthForInfoBtnOffset()    // подсчитать отступ для инфо кнопки

    this.updateInfoBtnVisibility(needUseStopLimitInsteadStop)   // обновление видимости инфо кнопки

    this.updateOrderTypeIfUseStopLimitInsteadStop(stopOrderTypeSelected, needUseStopLimitInsteadStop)   // наперсточник 
}

OEOrderTypeSelector.prototype.updateInfoBtnVisibility = function (visible)                 // изменение видимости инфо иконки #91413
{
    this.set('visibleInfoBtn', visible)
}

OEOrderTypeSelector.prototype.updateOrderTypeIfUseStopLimitInsteadStop = function (stopOrderTypeSelected, needUseStopLimitInsteadStop) // #91413
{
    if (needUseStopLimitInsteadStop)
        this.set('orderType', new StopLimitOrderType())         // подмена orderType в случае включения/выключения настройки useStopLimitInsteadStop или смены orderType при вкл.настройке
    else
        if (stopOrderTypeSelected)
            this.set('orderType', new StopOrderType())              // снятие настройки UseStopLimitInsteadStop при выбранном Stop ордере -> он был подменен на StopLimit нужно подменить обратно
}

OEOrderTypeSelector.prototype.calcTextWidthForInfoBtnOffset = function ()  // #91413 подсчитывает отступ для инфо кнопки в orderType комбобоксе
{
    let res = 40  // значение по умолчанию

    let currentLang = CurrentLang
    if (currentLang === LOCALE_JA || currentLang === LOCALE_ES)      // пока вот так вот всрато :( извините...
        res = 60

    this.set('infoBtnOffset', res)
}

// TODO. Sorting rule.
OEOrderTypeSelector.createSortedOrderTypeArray = function (orderTypeDict)
{
    let arr = [];
    if (!orderTypeDict) return arr;

    let sortArr = [1, 3, 2, 4, 5, 250];
    for (let i = 0; i < sortArr.length; i++)
    {
        if (orderTypeDict[sortArr[i]])
        {
            let orderType = orderTypeDict[sortArr[i]];
            arr.push(orderType);
        }
    }

    return arr;
}