import { type IOptionCalculator } from '../../OptionCalculator/IOptionCalculator';
import { type Greeks } from '../Greeks';
import { type PaperPosition } from '../OptionPaperPosition/PaperPosition';

export class AnalyzerChartDataInputParameters {
    public readonly underlierPrice: number;
    public readonly interestRate: number;
    public readonly paperPositionsInfo: AnalyzerChartDataPaperPositionInfo[];
    public readonly optionCalculator: IOptionCalculator;

    constructor (unerlierPrice: number, interestRate: number, paperPositionsInfo: AnalyzerChartDataPaperPositionInfo[], optionCalculator: IOptionCalculator) {
        this.underlierPrice = unerlierPrice;
        this.interestRate = interestRate;
        this.paperPositionsInfo = paperPositionsInfo;
        this.optionCalculator = optionCalculator;
    }
}

export class AnalyzerChartDataPaperPositionInfo {
    public readonly paperPosition: PaperPosition;
    public readonly greeks: Greeks;
    constructor (paperPosition: PaperPosition, greeks: Greeks) {
        this.paperPosition = paperPosition;
        this.greeks = greeks;
    }
}
