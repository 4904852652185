// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { TerceraComboBox } from './TerceraComboBox';
import { LineStyleUtils } from '../../Commons/UtilsClasses/LineStyleUtils';

export class TerceraLineStyleComboBox extends TerceraComboBox {
    constructor () {
        super();
    }

    public getType (): string { return 'TerceraLineStyleComboBox'; }

    public oninit (): void {
        super.oninit();

        if (this.get('items').length) {
            if (this.get('useStyleProperties')) {
                this.setData(null);
                this.menuItems = this.createMenuItems(this.get('selectedItem'));
            }
            return;
        }

        const arr = LineStyleUtils.getCBItems();
        this.setData(arr);
    }

    public setData (arr): void {
        void this.set({ listWidth: 150, usePNGView: true, imagewidth: 150, updateComboBoxClass: 'upClassLineStyleComboBox' });
        if (arr) {
            void this.set({ items: arr });
        }
    }
}

TerceraComboBox.extendWith(TerceraLineStyleComboBox, {
    data: function () {
        return {
            showLastValue: true,
            useStyleProperties: false
        };
    }
});
