// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../../Commons/properties/Resources';
import { DataCacheToolRayType, DataCacheToolType } from '../DataCacheToolEnums';
import { Color, Pen } from '../../../Commons/Graphics';
import { DataCacheTool } from '../DataCacheTool';
import { DynProperty, ColorStyleWidth } from '../../DynProperty';

export class AndrewsPitchforkDataCacheTool extends DataCacheTool {
    private dashedPen: Pen;

    constructor () {
        super();
        this.InitBlock();
    }

    get DashedPen (): Pen {
        return this.dashedPen;
    }

    protected InitBlock (): void {
        super.InitBlock();

        this.ToolType = DataCacheToolType.AndrewsPitchFork;
        this.InitPoints(3);
        this.RayType = DataCacheToolRayType.Ray;

        this.dashedPen = this.Pen.copy();
        Pen.ProcessPen(this.dashedPen, Pen.csDotChart);
    }

    MinPointsCount (): number {
        return 3;
    }

    MaxPointsCount (): number {
        return 3;
    }

    Properties (): DynProperty[] {
        const props: DynProperty[] = super.Properties();

        const colorStyleWidthRays = new ColorStyleWidth(Color.Empty, this.styleRay, 1);
        colorStyleWidthRays.enableStyleOnly();
        const prop = new DynProperty('Ray style', colorStyleWidthRays, DynProperty.COLOR_STYLE_WIDTH, DynProperty.VISUAL_GROUP);
        prop.propertyComment = Resources.getResource('property.chart.rayStyle');
        props.push(prop);

        return props;
    }

    callBack (properties: DynProperty[]): void {
        super.callBack(properties);

        const prop = DynProperty.getPropertyByName(properties, 'Ray style');
        if (prop != null) this.StyleRay = prop.value.Style;
    }
}
