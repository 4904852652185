// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { PFixFieldSet } from '../../../Fields/FieldsImport';
import { FieldsFactory } from '../../../Factory/FieldsFactory';

export class AlgorithmHighLowInstrumentGroup extends PFixFieldSet {
    constructor (pfixFieldSet: PFixFieldSet) {
        super(FieldsFactory.ALGORITHM_HIGH_LOW_INSTRUMENT_GROUP, null, pfixFieldSet);
    }

    get RouteId (): number { return super.GetValue(FieldsFactory.FIELD_ROUTE_ID); }
    get TradableId (): number { return super.GetValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID); }
    get InstrumentId (): number { return super.GetValue(FieldsFactory.FIELD_INSTRUMENT_ID); }
    get InstrumentName (): string { return super.GetValue(FieldsFactory.FIELD_NAME); }
    get InstrumentGroupId (): number { return super.GetValue(FieldsFactory.FIELD_INSTRUMENTTYPE_ID); }
    get InterestChange (): number { return super.GetValue(FieldsFactory.FIELD_INTEREST_CHANGE); }
    get AbsoluteChange (): number { return super.GetValue(FieldsFactory.FIELD_ABSOLUTE_CHANGE); }
    get LastPrice (): number { return super.GetValue(FieldsFactory.FIELD_LAST_PRICE); }
    get LastSize (): number { return super.GetValue(FieldsFactory.FIELD_LAST_SIZE); }
    get LastTradeDate (): number { return super.GetValue(FieldsFactory.FIELD_LAST_TRADE_DATE); }
    get MarketDataExchange (): string { return super.GetValue(FieldsFactory.FIELD_MD_EXCHANGE_NAME); }
    get TradingExchange (): string { return super.GetValue(FieldsFactory.FIELD_TRADING_EXCHANGE); }
    get Volume (): number { return super.GetValue(FieldsFactory.FIELD_VOLUME_TOTAL); }
    get HighLowEvent (): number { return super.GetValue(FieldsFactory.FIELD_HIGH_LOW_EVENT); }
    get High (): number { return super.GetValue(FieldsFactory.FIELD_HIGH); }
    get Low (): number { return super.GetValue(FieldsFactory.FIELD_LOW); }
}
