// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from "../../Utils/CustomEvents.ts";
import { Resources } from "../../Commons/properties/Resources.ts";
import { TerceraImageUploaderTemplate } from "../../templates.js";
import { ContainerControl } from "./ContainerControl.js";
import { ThemeManager } from "../misc/ThemeManager.ts";
import { TerceraImagePreviewScreen } from "../screen/TerceraImagePreviewScreen.js";

//Ніде не створюється
export let TerceraImageUploader = ContainerControl.extend({
    data: function () {
        return {
            width: 200,
            height: 20,
            btnPreviewImg: '',
            filter: 'image/png',
            imgFile: null,
            selectedImageBase64: null,
            defaultImageBase64: null
        }
    },
    computed: {
        previewEnabled: {
            get: function ()
            {
                if (this.get('imgFile'))
                    return !!this.get('selectedImageBase64');
                else
                    return !!this.get('defaultImageBase64');
            },
            set: function (value) { }
        }
    },
    template: TerceraImageUploaderTemplate,
    fileReader: null
});

TerceraImageUploader.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.apply(this);
    this.set('btnPreviewImg', ThemeManager.getImageFullUrl('j_algostudio/16x16_icon_search.png'));
    this.initFileReader();
    this.on('showPreviewClick', this.onShowPreviewClicked);
    this.observe('imgFile', this.onImgFileChanged);
    this.observe('defaultImageBase64 selectedImageBase64', this.onCurrentImageBase64Changed);
    this.CurrentImageBase64Changed = new CustomEvent();
};

TerceraImageUploader.prototype.initFileReader = function ()
{
    var fr = new FileReader();
    fr.onloadend = this.onFileLoadEnd.bind(this);
    this.fileReader = fr;
};

TerceraImageUploader.prototype.onFileLoadEnd = function ()
{
    var fr = this.fileReader;
    if (!fr) return;

    this.set('selectedImageBase64',
        fr.readyState === FileReader.DONE ?
        fr.result : null);
};

TerceraImageUploader.prototype.dispose = function ()
{
    ContainerControl.prototype.dispose.apply(this);
    var fr = this.fileReader;
    
    if (fr.readyState === FileReader.LOADING)
        fr.abort();
    
    this.fileReader = null;
};

TerceraImageUploader.prototype.onImgFileChanged = function (imgFile)
{
    this.set('selectedImageBase64', null);
    if (!imgFile || !this.fileReader) return;
    this.fileReader.readAsDataURL(imgFile);
};

TerceraImageUploader.prototype.onCurrentImageBase64Changed = function ()
{
    if (this.CurrentImageBase64Changed)
        this.CurrentImageBase64Changed.Raise(this, this.getCurrentImageBase64());
};

TerceraImageUploader.prototype.onShowPreviewClicked = function ()
{
    TerceraImagePreviewScreen.show(
        this.getCurrentImageBase64(),
        this.get('imgFile.name') || Resources.getResource('Default image'));
};

TerceraImageUploader.prototype.getCurrentImageBase64 = function ()
{
    return this.get('selectedImageBase64') || this.get('defaultImageBase64');
};