// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../../Commons/properties/Resources';
import { HistoryType } from '../../../Utils/History/HistoryType';
import { CreateAlertWidgetTemplate } from '../../../templates';
import { Control } from '../../elements/Control';
import { ControlsTypes } from '../../UtilsClasses/FactoryConstants';
import { ThemeManager } from '../../misc/ThemeManager';
import { DataCache } from '../../../Commons/DataCache';
import { type Instrument } from '../../../Commons/cache/Instrument';
import { Color } from '../../../Commons/Graphics';

export class CreateAlertWidget extends Control.extend({
    template: CreateAlertWidgetTemplate,
    data: function () {
        return {
            quote: null,
            instrument: null,
            account: null,

            bidValue: NaN,
            askValue: NaN,
            lastValue: NaN,
            changeValue: NaN,
            volumeValue: NaN,

            bid_str_value: '',
            ask_str_value: '',
            last_str_value: '',
            change_str_value: '',
            volume_str_value: '',

            coloringBid: Color.White,
            coloringAsk: Color.White,
            coloringLast: Color.White,
            coloringChange: Color.White,
            coloringVolume: Color.White
        };
    }
}) {
    getType (): string { return ControlsTypes.CreateAlertWidget; }

    oninit (): void {
        super.oninit();

        Resources.onLocaleChange.Subscribe(this.localize, this);
        this.localize();

        super.observe('instrument', this.onInstrumentChanged);
        super.observe('account', this.updateValues);
    }

    dispose (): void {
        Resources.onLocaleChange.UnSubscribe(this.localize, this);
        this.unsubscribeQuotes(super.get('instrument'));

        super.dispose();
    }

    onInstrumentChanged (instrument: Instrument, lastInstrument): void {
        super.set({ bidValue: -1, askValue: -1, lastValue: -1, changeValue: -1, volumeValue: -1 });

        if (instrument == null || instrument === lastInstrument) { return; }

        this.unsubscribeQuotes(lastInstrument);
        this.subscribeQuotes(instrument);
    }

    updateValues (): void {
        const instrument = super.get('instrument');
        if (instrument == null) { return; }

        const Level1 = instrument.Level1;
        if (Level1 == null) { return; }

        const acc = super.get('account');

        const bid = Level1.GetBid(acc);
        const ask = Level1.GetAsk(acc);
        const last = Level1.GetLastPrice(acc);
        const change = Level1.GetChangePercent(acc);
        const volume = Level1.GetVolume();// instrument.TotalVolume;

        const bidValue = super.get('bidValue');
        this.setColouring(bid, bidValue, 'coloringBid');

        const askValue = super.get('askValue');
        this.setColouring(ask, askValue, 'coloringAsk');

        const lastValue = super.get('lastValue');
        this.setColouring(last, lastValue, 'coloringLast');

        const changeValue = super.get('changeValue');
        this.setColouring(change, changeValue, 'coloringChange');

        const volumeValue = super.get('volumeValue');
        this.setColouring(volume, volumeValue, 'coloringVolume');

        super.set({ bidValue: bid, askValue: ask, lastValue: last, changeValue: change, volumeValue: volume });
        super.set({
            bid_str_value: Level1.getFormatPrice(bid),
            ask_str_value: Level1.getFormatPrice(ask),
            last_str_value: Level1.getFormatPrice(last),
            change_str_value: Level1.formatChangePercentTostr(null, change),
            volume_str_value: Level1.strVolume(false)
        });
    }

    subscribeQuotes (instrument: Instrument): void {
        if (instrument == null) { return; }

        this.newQuote(instrument.LastQuote);
        DataCache.FQuoteCache.addListener(instrument, this, HistoryType.QUOTE_LEVEL1);
        DataCache.FQuoteCache.addListener(instrument, this, HistoryType.QUOTE_TRADES);
    }

    unsubscribeQuotes (instrument: Instrument): void {
        if (instrument == null) { return; }

        DataCache.FQuoteCache.removeListener(instrument, this, HistoryType.QUOTE_LEVEL1);
        DataCache.FQuoteCache.removeListener(instrument, this, HistoryType.QUOTE_TRADES);
        this.newQuote(null);
    }

    newQuote (quote): void {
        if (quote != null && quote.Type === HistoryType.QUOTE_INSTRUMENT_DAY_BAR) { return; }
        this.updateValues();
    }

    setColouring (value, lastValue, coloringMode): void {
        const up = ThemeManager.CurrentTheme.TableValueUpForeColor;
        const down = ThemeManager.CurrentTheme.TableValueDownForeColor;
        const white = Color.White;

        if (isNaN(lastValue)) {
            super.set(coloringMode, white);
        } else if (value > lastValue) {
            super.set(coloringMode, up);
        } else if (value < lastValue) {
            super.set(coloringMode, down);
        }
    }

    localize (): void {
        super.set('bidLabel', Resources.getResource('panel.watchlist.Bid'));
        super.set('askLabel', Resources.getResource('panel.watchlist.Ask'));
        super.set('lastLabel', Resources.getResource('panel.watchlist.Last'));
        super.set('changeLabel', Resources.getResource('panel.watchlist.ChangePr'));
        super.set('volumeLabel', Resources.getResource('panel.watchlist.Volume'));
    }
}
