// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { TerceraChartHistoryType } from '../../Chart/TerceraChartUtils.ts';
import { TradingToolViewBase } from '../../Chart/Tools/TradingTools/TradingToolViewBase.ts';
import { TerceraChartTradingOperation } from '../../Chart/Utils/ChartConstants.ts';
import { AlertCondition } from '../Alert/AlertConstants.ts';
import { AlertImportance } from '../Alert/AlertConstants.ts';
import { AlertUtils } from '../Alert/AlertUtils.ts';
import { OperationType } from '../Trading/OperationType.ts';
import { TradingViewBaseTool } from './TradingViewBaseTool.js';

export class TradingViewAlertTool extends TradingViewBaseTool.extend(
    {
        data: function ()
        {
            return {
                className: 'tradingView-alert-tool',
                useTextForAction: false,        // text description with quantity for Order, PnL for Position but image (useTextForAction=false) for action section in Alert tools 
                mainCursor: 'grab',//'ns-resize',
                actionCursor: 'pointer',
                closeCursor: 'pointer',
                height: TradingToolViewBase.yellow_alert_defaultImage.height
            }
        },
    })
{
    getType () { return 'TradingViewAlertTool'; }

    getPrice ()
    {
        const alert = this.get('dataObject');
        return alert ? alert.Value : null;
    }

    id ()
    {
        const alert = this.get('dataObject');
        return alert ? alert.AlertId : null;
    }

    oninit ()
    {
        TradingViewBaseTool.prototype.oninit.apply(this);

        this.observe('dataObject dragging hovered', this.updateToolImage);
        this.observe('dragging', (v) => { this.set('mainCursor', v ? 'grabbing' : 'grab') });

        this.on('handleActionImageClick', (e) => { this.onEditOrCloseClick() });
        this.on('handleCancelImageClick', (e) => { this.onEditOrCloseClick(true) });

        this.on('dragend', this.onMoveAlertToolFinished);
    }

    onEditOrCloseClick (isCloseClick = false)
    {
        const updateData = { action: TerceraChartTradingOperation[isCloseClick ? 'RemoveAlert' : 'EditAlert'] }     // <- TODO EditAlert Will not work after master merge!!! EditAlert updated in ver.111: https://gerrit.traderevolution.com/c/NodeWeb/+/90907/1/client/scripts/Utils/Trading/ChartTradingCore.ts#168
        const alert = this.get('dataObject');
        const chartTradingCore = this.get('chartTradingCoreRef');

        if (chartTradingCore && alert)
            chartTradingCore.ChartVisualTrading(alert, updateData)
    }

    updateToolImage ()
    {
        const hovered = this.get('hovered');
        const alert = this.get('dataObject');
        if (!alert) return;

        let mainImgHovered = this.get('mainImageHovered');
        let actionButtonImgHovered = this.get('actionImageHovered');
        let cancelButtonImgHovered = this.get('cancelImageHovered');

        let mainImg = null;
        let actionButtonImg = null;
        let cancelButtonImg = null;

        let penLine = null;
        let backgroundPen = null;
        let backgroundBrush = null;

        if (this.get('dragging'))
        {
            mainImg = TradingToolViewBase.gray_alertImage;
            actionButtonImg = TradingToolViewBase.gray_alert_editImage;
            cancelButtonImg = TradingToolViewBase.gray_alert_closeImage;

            penLine = TradingToolViewBase.orderGrayPen;
            backgroundPen = TradingToolViewBase.grayBackgroundPen;
            backgroundBrush = TradingToolViewBase.grayBrush;
        }
        else
            if (alert.Importance === AlertImportance.HIGH)
            {
                mainImg = mainImgHovered ? TradingToolViewBase.red_alert_hoverImage : TradingToolViewBase.red_alert_defaultImage;
                actionButtonImg = actionButtonImgHovered ? TradingToolViewBase.red_alert_edit_hoverImage : TradingToolViewBase.red_alert_edit_defaultImage;
                cancelButtonImg = cancelButtonImgHovered ? TradingToolViewBase.red_alert_close_hoverImage : TradingToolViewBase.red_alert_close_defaultImage;

                // penLine = this.myRenderer.alertRedPen;
                backgroundPen = TradingToolViewBase.redAlertBackgroundPen;
                backgroundBrush = TradingToolViewBase.redAlertBackgroundBrush;
            }
            else if (alert.Importance === AlertImportance.MEDIUM)
            {
                mainImg = mainImgHovered ? TradingToolViewBase.yellow_alert_hoverImage : TradingToolViewBase.yellow_alert_defaultImage;
                actionButtonImg = actionButtonImgHovered ? TradingToolViewBase.yellow_alert_edit_hoverImage : TradingToolViewBase.yellow_alert_edit_defaultImage;
                cancelButtonImg = cancelButtonImgHovered ? TradingToolViewBase.yellow_alert_close_hoverImage : TradingToolViewBase.yellow_alert_close_defaultImage;

                // penLine = this.myRenderer.alertYellowPen;
                backgroundPen = TradingToolViewBase.yellowAlertBackgroundPen;
                backgroundBrush = TradingToolViewBase.yellowAlertBackgroundBrush;
            }
            else if (alert.Importance === AlertImportance.LOW)
            {
                mainImg = mainImgHovered ? TradingToolViewBase.green_alert_hoverImage : TradingToolViewBase.green_alert_defaultImage;
                actionButtonImg = actionButtonImgHovered ? TradingToolViewBase.green_alert_edit_hoverImage : TradingToolViewBase.green_alert_edit_defaultImage;
                cancelButtonImg = cancelButtonImgHovered ? TradingToolViewBase.green_alert_close_hoverImage : TradingToolViewBase.green_alert_close_defaultImage;

                // penLine = this.myRenderer.alertGreenPen;
                backgroundPen = TradingToolViewBase.greenAlertBackgroundPen;
                backgroundBrush = TradingToolViewBase.greenAlertBackgroundBrush;
            }

        this.set({
            mainImg: mainImg,
            actionImg: actionButtonImg,
            cancelImg: cancelButtonImg,

            mainSrc: mainImg.src,
            actionSrc: actionButtonImg.src,
            cancelSrc: cancelButtonImg.src,

            pricePlateColor: backgroundBrush.Color,
            priceLineColor: backgroundPen.Color
        });
    }

    onMoveAlertToolFinished ()
    {
        const ins = this.getInstrument();
        const priceValue = this.get('priceValue');
        const newPrice = ins !== null ? ins.roundPrice(priceValue) : priceValue;

        const ht = TerceraChartHistoryType.GetOriginalHistoryType(this.toolsContainerRef?.parent?.GetHistoryType(), ins)
        const valueFromInstrument = AlertUtils.GetCorrectPriceForAlertByInstrument(ins, ht);
        let condition = null;

        if (!isNaN(valueFromInstrument))
            condition = newPrice > valueFromInstrument ? AlertCondition.MORE_EQUAL : AlertCondition.LESS_EQUAL;

        this.lastValidPriceValue = newPrice;

        const core = this.get('chartTradingCoreRef');
        if (core)
            core.ChartVisualTrading(
                this.get('dataObject'),
                {
                    Value: newPrice,
                    Condition: condition,
                    MoveChart: true,
                    action: TerceraChartTradingOperation.AlertReplacement
                })
    }
}