// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectInstrumentMessage, WarningForChangeFromLastPriceRange } from '../../../../Utils/DirectMessages/DirectMessagesImport';
import { KeyValueGroup, OptionContractGroup, AliasesGroup, WarningForChangeFromLastPriceGroup, VariableTickGroup } from '../Groups/GroupsImport';
import { type PFixFieldSet } from '../Fields/FieldsImport';

import { Enum } from '../../../../Utils/Enum';

import { TimeZoneInfo } from '../../../../Utils/Time/TimeZoneInfo';
import { MarginCoefficients } from '../../../../Utils/RiskPlan/MarginCoefficients';
import { InstrDateSettings } from '../../../../Utils/Instruments/InstrDateSettings';
import { QuotingType } from '../../../../Utils/Instruments/QuotingType';
import { OptionTradingStyle } from '../../../../Utils/Instruments/OptionTradingStyle';
import { VolumeHistoryMode } from '../../../../Utils/Volume/VolumeConstantsAndEnums';
import { InstrumentTypes } from '../../../../Utils/Instruments/InstrumentTypes';

export class InstrumentMessageProcessor extends MessageProcessorBase {
    public decoder: any;
    public ServerTimeZone: any = null;
    // кеш когда baseforwrad instrument приходит позже опциона, для которого он базовый
    private readonly _cache: any = {};

    constructor (parentDecoder) {
        super();

        this.decoder = parentDecoder;
    }

    public static CreateTimeZone (): any {
        return TimeZoneInfo.Local;
    }

    public override decode (fs: PFixFieldSet): DirectInstrumentMessage[] {
        const msg = new DirectInstrumentMessage();

        const instId = fs.GetValue(FieldsFactory.FIELD_INSTRUMENT_ID);
        msg.Id = instId.toString();

        const baseInstrId = fs.GetValue(FieldsFactory.FIELD_BASE_INSTRUMENT_ID);

        msg.LotSize = fs.GetValue(FieldsFactory.FIELD_LOTSIZE);
        msg.LotStep = fs.GetValue(FieldsFactory.FIELD_LOT_STEP);
        // msg.ContractSize = fs.GetValue(FieldsFactory.FIELD_CONTRACT_SIZE)
        msg.PointSize = fs.GetValue(FieldsFactory.FIELD_POINTSIZE);
        msg.Name = fs.GetValue(FieldsFactory.FIELD_NAME);
        msg.Exp1 = fs.GetValue(FieldsFactory.FIELD_NAME_EXP1);
        msg.Exp2 = fs.GetValue(FieldsFactory.FIELD_NAME_EXP2);
        msg.AssetName = fs.GetValue(FieldsFactory.FIELD_ASSET_NAME);
        msg.FuturesTickCoast = fs.GetValue(FieldsFactory.FIELD_TICK_COAST);
        msg.TradingMode = fs.GetValue(FieldsFactory.FIELD_TRADE_MODE);
        msg.TradingPositionType = fs.GetValue(FieldsFactory.FIELD_TRADING_POSITION_TYPE);
        msg.Descr = fs.GetValue(FieldsFactory.FIELD_DESCRIPTION);
        msg.UnderlierDescription = msg.Descr;

        msg.HistoryType = DirectInstrumentMessage.GetLocalHistoryType(
            fs.GetValue(FieldsFactory.FIELD_BARS_TYPE));

        const barsTypeChart = fs.GetValue(FieldsFactory.FIELD_BARS_TYPE_CHART);

        msg.DefaultChartHistoryType =
        barsTypeChart === null
            ? msg.HistoryType
            : DirectInstrumentMessage.GetLocalHistoryType(barsTypeChart);

        msg.TypeId = fs.GetValue(FieldsFactory.FIELD_INSTRUMENTTYPE_ID);
        msg.RoutesSupported = fs.GetValue(FieldsFactory.FIELD_ROUTE_IDS);
        msg.TradableRoutes = fs.GetValue(FieldsFactory.FIELD_TRADE_ROUTE_IDS);
        msg.SwapBuy = fs.GetValue(FieldsFactory.FIELD_SWAP_BUY);
        msg.SwapSell = fs.GetValue(FieldsFactory.FIELD_SWAP_SELL);
        msg.SwapType = fs.GetValue(FieldsFactory.FIELD_SWAP_MEASURE);
        msg.InstrType = fs.GetValue(FieldsFactory.FIELD_TYPE);
        msg.LockedBy = fs.GetValue(FieldsFactory.FIELD_BROCKER_IDS);
        msg.TradingBalance = fs.GetValue(FieldsFactory.FIELD_VALUED_DATE_BASIS);

        msg.DeliveryMethod = fs.GetValue(FieldsFactory.FIELD_DELIVERY_METHOD_ID);

        const exerciseStyle = fs.GetValue(FieldsFactory.FIELD_EXERCISE_STYLE);
        if (exerciseStyle !== null) {
            msg.ExerciseStyle = exerciseStyle;
        }

        const priceTypeForRisk = fs.GetValue(FieldsFactory.FIELD_PRICE_TYPE_FOR_RISK);
        if (priceTypeForRisk !== null) {
            msg.curPriceSource = priceTypeForRisk;
        }

        msg.ExchangeId = fs.GetValue(FieldsFactory.FIELD_EXCHANGE_ID);

        msg.TradingExchange = fs.GetValue(FieldsFactory.FIELD_TRADING_EXCHANGE);
        msg.MarketDataExchange = fs.GetValue(FieldsFactory.FIELD_MARKET_DATA_EXCHANGE);

        msg.CountryId = fs.GetValue(FieldsFactory.FIELD_INSTRUMENT_COUNTRY);

        msg.UseOptionDescription = fs.GetValue(FieldsFactory.FIELD_OPTIONS_USE_DESC);

        msg.LastTradeDate = fs.GetValue(FieldsFactory.FIELD_LAST_TRADE_DATE_VALUE);
        msg.MaturityDate = fs.GetValue(FieldsFactory.FIELD_MATURITY_DATE_VALUE);
        msg.FaceValue = fs.GetValue(FieldsFactory.FIELD_FACE_VALUE);
        msg.CouponRate = fs.GetValue(FieldsFactory.FIELD_COUPON_RATE_VALUE);
        msg.CouponCycle = fs.GetValue(FieldsFactory.FIELD_COUPON_CYCLE_VALUE);
        msg.YieldRate = fs.GetValue(FieldsFactory.FIELD_YIELD_RATE_VALUE);
        msg.AccruedInterest = fs.GetValue(FieldsFactory.FIELD_ACCRUED_INTEREST);
        msg.IssueDate = fs.GetValue(FieldsFactory.FIELD_ISSUE_DATE);
        msg.DaysPerMonth = fs.GetValue(FieldsFactory.FIELD_DAYS_PER_MONTH);
        msg.DaysPerYear = fs.GetValue(FieldsFactory.FIELD_DAYS_PER_YEAR);

        const quotingType = fs.GetValue(FieldsFactory.FIELD_QUOTING_TYPE);
        msg.QuotingType = quotingType === null ? QuotingType.None : quotingType;

        const optionTradingStyle = fs.GetValue(FieldsFactory.FIELD_OPTION_TRADING_STYLE);
        msg.OptionTradingStyle =
        optionTradingStyle === null
            ? OptionTradingStyle.Undefined
            : optionTradingStyle;

        msg.VolumeHistoryMode =
        msg.InstrType === InstrumentTypes.FOREX ||
            msg.InstrType === InstrumentTypes.EQUITIES_CFD ||
            msg.InstrType === InstrumentTypes.CFD_FUTURES ||
            msg.InstrType === InstrumentTypes.SPREADBET
            ? VolumeHistoryMode.Ticks
            : VolumeHistoryMode.Trades;

        msg.TradeSessionStatusId = fs.GetValue(FieldsFactory.FIELD_TRADE_SESSION_STATUS_ID);
        msg.ExchangeSessionName = fs.GetValue(FieldsFactory.FIELD_EXCHANGE_SESSION_NAME);

        msg.LanguageAliases = AliasesGroup.GetFromFieldSet(fs);

        msg.LowLimit = fs.GetValue(FieldsFactory.FIELD_PRICE_LOW_LIMIT);
        msg.HightLimit = fs.GetValue(FieldsFactory.FIELD_PRICE_HI_LIMIT);

        msg.ISIN = fs.GetValue(FieldsFactory.FIELD_ISIN);
        msg.InstrumentTradableID = fs.GetValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID);
        msg.LogoAddress = fs.GetValue(FieldsFactory.FIELD_INSTRUMENT_LOGO_ADDRESS);
        msg.MarketCap = fs.GetValue(FieldsFactory.FIELD_MARKET_CAP);
        msg.Industry = fs.GetValue(FieldsFactory.FIELD_VALUE_INSTRUMENT_INDUSTRY);
        msg.Sector = fs.GetValue(FieldsFactory.FIELD_VALUE_INSTRUMENT_SECTOR);

        msg.SectorCustomListId = fs.GetValue(FieldsFactory.FIELD_VALUE_INSTRUMENT_SECTOR_CUSTOM_LIST_ID);
        msg.IndustryCustomListId = fs.GetValue(FieldsFactory.FIELD_VALUE_INSTRUMENT_INDUSTRY_CUSTOM_LIST_ID);

        msg.ShortPositionInterest = fs.GetValue(FieldsFactory.FIELD_SHORT_POSITION_INTEREST);
        msg.DailyAvailableForShort = fs.GetValue(FieldsFactory.FIELD_DAILY_AVAILABLE_FOR_SHORT);

        msg.UseSessionPeriodForIntradayChart = fs.GetValue(FieldsFactory.FIELD_USE_SESSION_PERIOD);

        msg.DataSourceTradableId = fs.GetValue(FieldsFactory.FIELD_DATA_SOURCE_TR_INSTR_ID);
        msg.DataSourceRouteId = fs.GetValue(FieldsFactory.FIELD_DATA_SOURCE_ROUTE_ID);

        msg.IsAllowCustomSLTPTriggerPrice = fs.GetValue(FieldsFactory.FIELD_ALLOW_CUSTOM_SL_TP_TRIGGER_PRICE);
        msg.IsAllowExerciseForItmOption = fs.GetValue(FieldsFactory.FIELD_ALLOW_EXERCISE_FOR_ITM_OPTION);

        // <-- тут важно не затереть -1 по умолчанию
        const priceLimitMeasure = fs.GetValue(FieldsFactory.FIELD_PRICE_LIMIT_MESURE);
        if (priceLimitMeasure !== null) {
            msg.PriceLimitMeasure = priceLimitMeasure;
        }

        msg.IsHighLimitFrontEndValidationEnabled = fs.GetValue(FieldsFactory.FIELD_HI_PRICE_LIMIT_ENABLED);
        msg.IsLowLimitFrontEndValidationEnabled = fs.GetValue(FieldsFactory.FIELD_LOW_PRICE_LIMIT_ENABLED);

        const tradeSessionId = fs.GetValue(FieldsFactory.FIELD_TRADE_SESSION_ID);
        if (tradeSessionId !== null) {
            msg.TradingSessionsId = tradeSessionId;
        }

        // #region Exp dates

        if (this.ServerTimeZone === null) {
            this.ServerTimeZone = InstrumentMessageProcessor.CreateTimeZone();
        }

        msg.InstrDateSettingsList = msg.InstrDateSettingsList.concat(InstrumentMessageProcessor.ParseGroupsFromMessage(
            fs,
            this.ServerTimeZone,
            msg.PointSize));

        OptionContractGroup.MergeOptionContractGroup(fs, msg.InstrDateSettingsList);

        // #endregion Exp dates

        // #region tmp

        const margins = fs.GetGroups(FieldsFactory.MARGIN_COEFFICIENT_GROUP);
        if (margins?.length) {
            for (let i = 0, len = margins.length; i < len; i++) {
                const marginGroup = margins[i];
                // TODO. C# struct. Important?
                const fields = new MarginCoefficients();
                fields.FuturesExpDay = marginGroup.GetValue(FieldsFactory.FIELD_EXP_DAY); // - может отсутствовать
                fields.FuturesExpMonth = marginGroup.GetValue(FieldsFactory.FIELD_EXP_MONTH); // - может отсутствовать
                fields.FuturesExpYear = marginGroup.GetValue(FieldsFactory.FIELD_EXP_YEAR); // - может отсутствовать

                fields.Futures_HOLD_MARGINGSIZE = marginGroup.GetValue(FieldsFactory.FIELD_HOLD_MARGINGSIZE);
                fields.Futures_INIT_MARGINGSIZE = marginGroup.GetValue(FieldsFactory.FIELD_INIT_MARGINGSIZE);
                fields.Futures_INIT_MARGINGSIZE_OVERNIGHT = marginGroup.GetValue(FieldsFactory.FIELD_INIT_MARGINGSIZE_OVERNIGHT);
                fields.Futures_HOLD_MARGINGSIZE_OVERNIGHT = marginGroup.GetValue(FieldsFactory.FIELD_HOLD_MARGINGSIZE_OVERNIGHT);
                fields.Futures_INIT_MARGINGSIZE_SHORT = marginGroup.GetValue(FieldsFactory.FIELD_INIT_MARGINGSIZE_SHORT);
                fields.Futures_HOLD_MARGINGSIZE_SHORT = marginGroup.GetValue(FieldsFactory.FIELD_HOLD_MARGINGSIZE_SHORT);
                fields.Futures_INIT_MARGINGSIZE_OVERNIGHT_SHORT = marginGroup.GetValue(FieldsFactory.FIELD_INIT_MARGINGSIZE_OVERNIGHT_SHORT);
                fields.Futures_HOLD_MARGINGSIZE_OVERNIGHT_SHORT = marginGroup.GetValue(FieldsFactory.FIELD_HOLD_MARGINGSIZE_OVERNIGHT_SHORT);

                msg.MarginCoeficientList.push(fields);
            }
        }

        msg.CalculateMarginType = fs.GetValue(FieldsFactory.FIELD_MARGIN_TYPE);
        msg.MarginMode = fs.GetValue(FieldsFactory.FIELD_MARGIN_MODE);

        msg.UseSameCrossPriceForOpenClose =
        fs.GetValue(FieldsFactory.FIELD_USE_SAME_CROSSPRICEFOR_OPEN_CLOSE);

        const fieldIsHedgedMarginSize = fs.GetValue(FieldsFactory.FIELD_IS_HEDGED_MARGIN_SIZE);
        if (fieldIsHedgedMarginSize !== null) {
            msg.HedgedMarginCallSize = fieldIsHedgedMarginSize === 1 ? 1 : -1;
        }

        msg.UseOvernightMargin = fs.GetValue(FieldsFactory.FIELD_USE_OVERNIGHT_MARGIN);
        msg.UseLongShortMargin = fs.GetValue(FieldsFactory.FIELD_USE_LONGSHORT_MARGIN);
        msg.IsShortable = fs.GetValue(FieldsFactory.FIELD_IS_SHORTABLE);
        msg.MaxLot = fs.GetValue(FieldsFactory.FIELD_MAX_LOT);
        msg.MinimalLot = fs.GetValue(FieldsFactory.FIELD_MINIMAL_LOT);
        msg.MarginCoefficient = fs.GetValue(FieldsFactory.FIELD_MARGIN_COEF);
        msg.UseOpenPriceInMargin = fs.GetValue(FieldsFactory.FIELD_IS_OPEN_PRICE_IN_MARGIN);

        // #endregion tmp

        const variableTickGroups = fs.GetGroups(FieldsFactory.VARIABLE_TICK_GROUP, VariableTickGroup);
        msg.VariableTickList = VariableTickGroup.ImproveTicksIntervals(variableTickGroups, msg.PointSize);

        const extFields = fs.GetGroups(FieldsFactory.KEY_VALUE_GROUP, KeyValueGroup);
        if (extFields.length > 0) {
            const extFieldsDictionary = msg.AdditionalFields;
            for (let i = 0, len = extFields.length; i < len; i++) {
                const kv = extFields[i];
                extFieldsDictionary[kv.getKey()] = kv.getValue();
            }
        }

        msg.WarningForChangeFromLastPriceRanges = InstrumentMessageProcessor.getWarningForChangeFromLastPriceRanges(fs);

        // #region TODO. Obsolete? Remove?

        msg.ForwardBaseInstrument = this.decoder.GetSymbolById(baseInstrId);
        msg.ForwardBaseInstrumentID = baseInstrId;

        const symDecodeMap = this.decoder.InstrumentDecodeMap;
        symDecodeMap[instId] = msg.Name;

        const idDecodeMap = this.decoder.SymbolDecodeMap;
        idDecodeMap[msg.Name] = instId;

        const _cache = this._cache;
        if (_cache.hasOwnProperty(instId)) {
        // пришел базвый инструмент.
        // выгребаем все от него зависимые и присваиваем им текущее имя
        // (в GetSymbolById нет необходимости)
            const messages = _cache[instId];
            for (let i = 0, len = messages.length; i < len; i++) {
                messages[i].ForwardBaseInstrument = msg.Name;
                messages[i].ForwardBaseInstrumentID = instId;
            }
            // вставляем базовый обязательно первым! а то не создастся в клиенте
            messages.splice(0, 0, msg);
            delete _cache[instId];
            // alexb: Нам надо занулить ReqerenceId, а оно ставится снаружи.
            // Временное решение, пока не переделаем на отдельный запрос опциона и андерлаера
            // msg.SkipReferenceId = true

            return messages;
        }

        if (
            baseInstrId >= 0 &&
        //! msg.ForwardBaseInstrument &&    // https://tp.traderevolution.com/entity/80783
        baseInstrId !== instId &&
        msg.InstrType === InstrumentTypes.OPTIONS
        ) {
        // alexb: у нас в таком случае потеряется ReqerenceId,
        // а ведь на него waiter повешан - вычитываем
            msg.ReqerenceId = fs.GetValue(FieldsFactory.FIELD_REQUEST_ID) || 0;
            // у нас инструмент, у которого есть базовый, но базовый еще не пришел. помещаем в кеш
            if (_cache.hasOwnProperty(baseInstrId)) {
                _cache[baseInstrId].push(msg);
            } else {
                _cache[baseInstrId] = [msg];
            }
            // !!! считаю что НЕЛЬЗЯ показывать опцион, если его базовый инструмент не придет на клиент.
            // сразу тогда будем видеть проблему, яснее и четче для нас и клиентов.
            msg.ForwardBaseInstrumentID = baseInstrId; // для запроса
        }

        if (msg.InstrType === InstrumentTypes.OPTIONS && baseInstrId === -1) {
        // базовый инструмент НИКОГДА не придет на клиент - он не настроен (в БО не выбран).
        // Втопку такой мессадж.
            return [];
        }

        if (!Enum.IsDefined(InstrumentTypes, msg.InstrType)) {
        // Клиент не поддерживает данный тип инструмента
            console.log('Not supported instrument: ' + msg.Id);
            return [];
        }

        return [msg];
    }

    public static getTimeOfDayUtcMillis (d): number {
        const dt = new Date(d);
        dt.setUTCFullYear(1970, 0, 1);
        return dt.getTime();
    }

    public static ParseGroupsFromMessage (fs, serverTimeZone, insPointSize): InstrDateSettings[] {
        const resArr: InstrDateSettings[] = [];

        const expDateGroups = fs.GetGroups(FieldsFactory.EXPIRATION_DATE_GROUP_NAME);
        const len = expDateGroups ? expDateGroups.length : 0;
        for (let i = 0; i < len; i++) {
            const ds = InstrumentMessageProcessor.parseGroupFromMessage(
                expDateGroups[i],
                fs,
                serverTimeZone,
                insPointSize);

            if (ds) {
                resArr.push(ds);
            }
        }

        return resArr;
    }

    public static getWarningForChangeFromLastPriceRanges (fs): WarningForChangeFromLastPriceRange[] {
        if (!fs) {
            return null;
        }

        const result: WarningForChangeFromLastPriceRange[] = [];
        const groups = fs.GetGroups(FieldsFactory.WARNING_FOR_CHANGE_FROM_LAST_PRICE_GROUP, WarningForChangeFromLastPriceGroup);

        for (let i = 0; i < groups.length; i++) {
            const g = groups[i];

            result.push(new WarningForChangeFromLastPriceRange(g.getLastChange(), g.getPriceLowLimit()));
        }

        return result;
    }

    // TODO. Rename params.
    public static parseGroupFromMessage (group, parentFS, serverTimeZone, insPointSize): InstrDateSettings {
        const ds = new InstrDateSettings();
        ds.DeliveryStatus = group.GetValue(FieldsFactory.FIELD_CONTRACT_DELIVERY_STATUS);

        const showDaysForFutures = group.GetValue(FieldsFactory.FIELD_USE_EXPIRED_DATE_ON_TICKER);
        ds.ShowDayForFutures = showDaysForFutures;

        const fieldContractMonthDate = group.GetValue(FieldsFactory.FIELD_CONTRACT_MONTH_DATE);
        const fieldMaturityDate = group.GetValue(FieldsFactory.FIELD_MATURITY_DATE);

        if (fieldMaturityDate === null) {
            ds.MaturityDate =
            ds.ExpirationDate =
            ds.ContractMonthDate =
            TimeZoneInfo.ConvertTimeFromUtc(fieldContractMonthDate, serverTimeZone);
        } else {
            ds.MaturityDate = TimeZoneInfo.ConvertTimeFromUtc(fieldMaturityDate, serverTimeZone);
            ds.ExpirationDate = TimeZoneInfo.ConvertTimeFromUtc(fieldContractMonthDate, serverTimeZone);

            if (showDaysForFutures) {
                const dayDelta = ds.ExpirationDate.getUTCDate() - ds.MaturityDate.getUTCDate();
                const cmdt = new Date(ds.MaturityDate);
                cmdt.setUTCDate(cmdt.getUTCDate() + dayDelta);
                ds.ContractMonthDate = cmdt;
            } else {
                ds.ContractMonthDate = ds.MaturityDate;
            }
        }

        ds.LastTradeDate = TimeZoneInfo.ConvertTimeFromUtc(group.GetValue(FieldsFactory.FIELD_LAST_TRADE_DATE), serverTimeZone);
        ds.SettlementDate = TimeZoneInfo.ConvertTimeFromUtc(group.GetValue(FieldsFactory.FIELD_SETTLEMENT_DATE), serverTimeZone);
        ds.NoticeDate = TimeZoneInfo.ConvertTimeFromUtc(group.GetValue(FieldsFactory.FIELD_NOTICE_DATE), serverTimeZone);
        ds.FirstTradeDate = TimeZoneInfo.ConvertTimeFromUtc(group.GetValue(FieldsFactory.FIELD_FIRST_TRADE_DATE), serverTimeZone);
        ds.AutoCloseDate = TimeZoneInfo.ConvertTimeFromUtc(group.GetValue(FieldsFactory.FIELD_AUTO_CLOSE_DATE), serverTimeZone);
        ds.UnderlierDate = TimeZoneInfo.ConvertTimeFromUtc(group.GetValue(FieldsFactory.FIELD_UNDERLIER_DATE), serverTimeZone);

        const UnderlierTradableId = group.GetValue(FieldsFactory.FIELD_UNDERLIER_CONTRACT_TRADABLE_INSTRUMENT_ID);
        if (UnderlierTradableId) {
            ds.UnderlierTradableId = UnderlierTradableId;
        }

        ds.IsContinious = group.GetValue(FieldsFactory.FIELD_IS_CONTINIOUS_CONTRACT);

        // #31009 Price limit - для контракта приходит всегда,
        // если переопределено что не присылась не должно брать с родителя
        const fieldLowLimit = group.GetValue(FieldsFactory.FIELD_PRICE_LOW_LIMIT);
        ds.LowLimit = fieldLowLimit === null ? NaN : group.GetValue(FieldsFactory.FIELD_PRICE_LOW_LIMIT);
        const fieldHiLimit = group.GetValue(FieldsFactory.FIELD_PRICE_HI_LIMIT);
        ds.HightLimit = fieldHiLimit === null ? NaN : group.GetValue(FieldsFactory.FIELD_PRICE_HI_LIMIT);

        const fieldPriceLimitMeasure = group.GetValue(FieldsFactory.FIELD_PRICE_LIMIT_MESURE);
        if (fieldPriceLimitMeasure !== null) {
            ds.PriceLimitMeasure = fieldPriceLimitMeasure;
        }

        ds.IsHighLimitFrontEndValidationEnabled = group.GetValue(FieldsFactory.FIELD_HI_PRICE_LIMIT_ENABLED);
        ds.IsLowLimitFrontEndValidationEnabled = group.GetValue(FieldsFactory.FIELD_LOW_PRICE_LIMIT_ENABLED);

        const groupFieldTradeMode = group.GetValue(FieldsFactory.FIELD_TRADE_MODE);
        ds.TradingMode =
        groupFieldTradeMode === null
            ? parentFS.GetValue(FieldsFactory.FIELD_TRADE_MODE)
            : groupFieldTradeMode;

        const fieldCheckIntrinsicValue = group.GetValue(FieldsFactory.FIELD_CHECK_INTRINSIC_VALUE);
        if (fieldCheckIntrinsicValue !== null) {
            ds.CheckIntrinsic = fieldCheckIntrinsicValue;
        }

        ds.FutureAliasName = group.GetValue(FieldsFactory.FIELD_NAME);
        ds.Underlier = group.GetValue(FieldsFactory.FIELD_UNDERLIER);

        ds.StrikePricesList = OptionContractGroup.GetOldOptionContractGroup(group);
        /// new field for NonFixedList
        ds.ContractID = group.GetValue(FieldsFactory.FIELD_CONTRACT_ID);

        ds.minLot = group.GetValue(FieldsFactory.FIELD_MINIMAL_LOT);
        ds.maxLot = group.GetValue(FieldsFactory.FIELD_MAX_LOT);
        ds.LotSize = group.GetValue(FieldsFactory.FIELD_LOTSIZE);
        ds.PointSize = group.GetValue(FieldsFactory.FIELD_POINTSIZE);
        ds.LotStep = group.GetValue(FieldsFactory.FIELD_LOT_STEP);
        ds.FuturesTickCoast = group.GetValue(FieldsFactory.FIELD_TICK_COAST);

        ds.Description = group.GetValue(FieldsFactory.FIELD_DESCRIPTION);

        ds.TradeSessionStatusId = group.GetValue(FieldsFactory.FIELD_TRADE_SESSION_STATUS_ID);
        ds.ExchangeSessionName = group.GetValue(FieldsFactory.FIELD_EXCHANGE_SESSION_NAME);

        const tickGroups = group.GetGroups(FieldsFactory.VARIABLE_TICK_GROUP);
        ds.VariableTicks = tickGroups.length
            ? VariableTickGroup.ImproveTicksIntervals(
                tickGroups,
                ds.PointSize === null ? insPointSize : ds.PointSize)
            : null;

        let correctPointSizeInVariableTicks = true;
        if (ds.VariableTicks) {
            const variableTicks = ds.VariableTicks;
            const len = variableTicks.length;
            for (let i = 0; i < len; i++) {
                const vTick = variableTicks[i];
                if (vTick.PointSize < 0) {
                    correctPointSizeInVariableTicks = false;
                    break;
                }
            }
        }

        const extFields = group.GetGroups(FieldsFactory.KEY_VALUE_GROUP, KeyValueGroup);
        if (extFields.length) {
            ds.AdditionalFields = {};
            for (let i = 0, len = extFields.length; i < len; i++) {
                const kv = extFields[i];
                ds.AdditionalFields[kv.getKey()] = kv.getValue();
            }
        }

        ds.ISIN = group.GetValue(FieldsFactory.FIELD_ISIN);
        ds.InstrumentTradableID = group.GetValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID);
        ds.LogoAddress = group.GetValue(FieldsFactory.FIELD_INSTRUMENT_LOGO_ADDRESS);
        ds.MarketCap = group.GetValue(FieldsFactory.FIELD_MARKET_CAP);

        return correctPointSizeInVariableTicks ? ds : null;
    }
}
