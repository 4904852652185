// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { OrderType } from '../../Utils/Trading/OrderType';
import { ErrorInformationStorage } from '../ErrorInformationStorage';
import { EventCache } from './EventCache';

export class Route {
    public Name = '';
    public Exchanges = '';
    public AllowTypeModify = false;
    public AllowAmountModify = false;
    public AllowTifModify = false;
    public AllowPriceModify = false;
    public AllowOperationTypeModify = false;
    public AllowExpirationDateModify = false;
    public DictOrderTypeTifs: any = {};
    public InstrumentDayBarMessageUpdateMode = false;
    public ShowMode = Route.ClientShowMode.Source;
    public RouteId = 0;
    public QuoteRouteId = 0;
    public IsTradable = false;
    public IsInfo = false;
    public RouteStatus = 5; // по умолчанию 5 - т.е. active & started
    public QuoteDelay: any = null;
    public IsNewsRoute = false;

    constructor (mess) {
        this.UpdateByMessage(mess);
    }

    public UpdateByMessage (mess): void {
        this.Name = mess.Name;
        this.Exchanges = mess.Exchanges;
        this.AllowTypeModify = mess.AllowTypeModify;
        this.AllowAmountModify = mess.AllowAmountModify;
        this.AllowTifModify = mess.AllowTifModify;
        this.AllowPriceModify = mess.AllowPriceModify;
        this.AllowOperationTypeModify = mess.AllowOperationTypeModify;
        this.AllowExpirationDateModify = mess.AllowExpirationDateModify;
        this.DictOrderTypeTifs = mess.DictOrderTypeTifs;

        if (mess.hasOwnProperty('RouteStatus')) {
            this.RouteStatus = mess.RouteStatus;
        }

        if (mess.hasOwnProperty('QuoteDelay')) {
            this.QuoteDelay = mess.QuoteDelay;
        }

        this.InstrumentDayBarMessageUpdateMode = mess.InstrumentDayBarMessageUpdateMode;
        this.ShowMode = mess.ClientShowMode === 0 ? Route.ClientShowMode.Source : Route.ClientShowMode.BuyerSeller;

        // FHolidays = new List<DateTime>();
        // string holidays = mess.Holidays;
        // if (!string.IsNullOrEmpty(holidays))
        // {
        //    string[] dates = holidays.Split(';');
        //    for (int i = 0; i < dates.Length; i++)
        //    {
        //        DateTime dt;
        //        if (DateTime.TryParseExact(dates[i], "dd-MM-yyyy", null, System.Globalization.DateTimeStyles.None, out dt))
        //            FHolidays.Add(dt);
        //    }
        // }

        this.RouteId = mess.RouteId;
        this.QuoteRouteId = mess.QuoteRouteId;
        this.IsTradable = mess.IsTradable;
        this.IsInfo = mess.IsInfo;
        this.IsNewsRoute = mess.IsNewsRoute;
    }

    /// <summary>
    /// Проверить доступность OrderType на роуте
    /// </summary>
    public IsAllowableOrderType (lOrdType): boolean {
        return this.DictOrderTypeTifs.hasOwnProperty(lOrdType);
    }

    /// <summary>
    /// Проверить доступность TIF на роуте для (для определенного orderType)
    /// </summary>
    public IsAllowableTif (lTif, orderType): boolean {
        try {
            if (orderType == OrderType.SLTPLimit) {
                orderType = OrderType.Limit;
            } else if (orderType == OrderType.SLTPStop) {
                orderType = OrderType.Stop;
            } else if (orderType == OrderType.TRStop) {
                orderType = OrderType.TrailingStop;
            }

            if (!this.DictOrderTypeTifs.hasOwnProperty(orderType)) {
                return false;
            }

            let isAllowable = false;
            const listTifs = this.DictOrderTypeTifs[orderType];
            if (listTifs != null && listTifs.indexOf(lTif) !== -1) {
                isAllowable = true;
            }

            return isAllowable;
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            EventCache
                .log(ex);
            return false;
        }
    }

    public CanSubscribe (): boolean {
        if (!this.IsInfo) {
            return this.RouteId != this.QuoteRouteId;
        }

        return this.IsInfo;
    }

    public static ClientShowMode = {
        Source: 0,
        BuyerSeller: 1
    };

    // TODO. Use instead of Const.ROUTE_Running - remove it.
    public static ROUTE_RUNNING = 5;
}
