// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PriceType } from "../../../../../Utils/History/CashItemUtils.ts";
import { MAMode, MODE_MAIN, MODE_SIGNAL } from "../../IndicatorConstants.ts";
import { ExpandDoubleVector } from "../../DoubleMatrix.ts";
import { IndicatorFunctions } from "../../IndicatorFunctions.ts";
import { iBuildInIndicator } from "../../iBuildInIndicator.ts";


export let iSTO = function (kPeriod, dPeriod, slowing, maMode, priceField)
{
    iBuildInIndicator.call(this, 2)
    this.FHighBuffer = new ExpandDoubleVector();
    this.FLowBuffer = new ExpandDoubleVector();
    this.FSignalBuffer = new ExpandDoubleVector();

    this.FKPeriod = kPeriod;
    this.FDPeriod = dPeriod;
    this.FSlowing = slowing;
    this.FMaMode = maMode;
    this.FPriceField = priceField;
    this.begin1
    this.begin2

    if (this.FMaMode == 1)
    {
        this.begin1 = this.FKPeriod + this.FSlowing; //?
        this.begin2 = this.FKPeriod + this.FSlowing; //?
    }
    else
    {
        this.begin1 = this.FKPeriod + this.FSlowing;
        this.begin2 = this.FKPeriod + this.FSlowing + this.FDPeriod - 1;
    }
}
iSTO.prototype = Object.create(iBuildInIndicator.prototype)

Object.defineProperty(iSTO.prototype, 'Name',
    {
        get: function () { return 'iSTO' }
    })

Object.defineProperty(iSTO.prototype, 'Key',
    {
        get: function () { return this.DefaultKey + this.FKPeriod + this.FDPeriod + this.FSlowing + this.FMaMode }
    })

iSTO.prototype.OnQuote = function (ci, callBound, callFromRefresh)
{
    this.FHighBuffer[this.FHighBuffer.Length - 1] = 0;
    this.FLowBuffer[this.FLowBuffer.Length - 1] = 0;
    this.FSignalBuffer[this.FSignalBuffer.Length - 1] = 0;;
    if (this.FCount < this.FKPeriod)
        return;

    let high = -1.0;
    let low = 10000000.0;
    if (this.FPriceField == 0)
        for (let i = 0; i < this.FKPeriod; i++)
        {
            let price = this.GetPrice(PriceType.High, i);
            if (price > high)
                high = price;
            price = this.GetPrice(PriceType.Low, i);
            if (price < low)
                low = price;
        }
    else
        for (let i = 0; i < this.FKPeriod; i++)
        {
            let price = this.GetPrice(PriceType.Close, i);
            if (price > high)
                high = price;
            if (price < low)
                low = price;
        }

    this.FLowBuffer[this.FLowBuffer.Length - 1] = this.GetPrice(PriceType.Close, 0) - low;
    this.FHighBuffer[this.FHighBuffer.Length - 1] = high - low;


    // Calculation of smoothed curve
    if (this.FCount > this.begin1)
    {
        let sumhigh = IndicatorFunctions.CallMovingFunction(MAMode.SMA, this.FHighBuffer, this.FCount - 1);
        let sumlow = IndicatorFunctions.CallMovingFunction(MAMode.SMA, this.FLowBuffer, this.FCount - 1);

        let value = sumhigh == 0 ? 100 : sumlow / sumhigh * 100;
        this.SetValue(MODE_MAIN, 0, value);
        this.FSignalBuffer[this.FSignalBuffer.Length - 1] = value;
    }

    if (this.FCount > this.begin2)
    {
        let signal = IndicatorFunctions.CallMovingFunction(this.FMaMode, this.FSignalBuffer, this.FCount - 1, this.begin2);
        this.SetValue(MODE_SIGNAL, 0, signal);
    }
}

iSTO.prototype.NextBar = function (callBound)
{
    iBuildInIndicator.prototype.NextBar.call(this, callBound)
    this.FHighBuffer.Add(0.0);
    this.FLowBuffer.Add(0.0);
    this.FSignalBuffer.Add(0.0);
}

iSTO.prototype.Refresh = function (count, newThread)
{
    this.FHighBuffer.Dispose();
    this.FLowBuffer.Dispose();
    this.FSignalBuffer.Dispose();
    this.FHighBuffer = new ExpandDoubleVector();
    this.FLowBuffer = new ExpandDoubleVector();
    this.FSignalBuffer = new ExpandDoubleVector();
    iBuildInIndicator.prototype.Refresh.call(this, count, newThread)
}