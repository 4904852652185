// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type Factory } from '../panels/Factory.js';
import { type TerceraAccountLookupDropDownForm } from '../elements/Lookup/TerceraAccountLookupDropDownForm.js';
import { type TerceraInstrumentLookupDropDownForm } from '../elements/Lookup/TerceraInstrumentLookupDropDownForm.js';
import { type FullLogsPanel } from '../panels/FullLogsPanel.js';
import { type TerceraChangePasswordScreen } from '../screen/TerceraChangePasswordScreen.js';
import { type TypesManagerScreen } from '../screen/TypesManagerScreen.js';
import { type TerceraBrokerMessageScreen } from '../screen/TerceraBrokerMessageScreen.js';
import { type AccountDetailsPanel } from '../panels/AccountDetailsPanel';
import { type TerceraWindowBase as TerceraWindowBase_ts } from '../screen/TerceraWindowBase_ts';
import { type TerceraWindowBase } from '../screen/TerceraWindowBase.js';
import { FullScreenManager } from './FullScreenManager';
import { ControlsUtils } from './ControlsUtils';
class _MainWindowManager {
    public Factory: null | Factory = null;
    public FullLogPanel: FullLogsPanel | null = null;
    public TerceraChangePasswordScreen: TerceraChangePasswordScreen | null = null;
    public TypesManagerScreen: TypesManagerScreen | null = null;
    public AsyncCloseObject: TerceraBrokerMessageScreen | null = null;
    public AccountDetailsPanel: AccountDetailsPanel | null = null;
    public portfolioWindow: WindowProxy | null = null;

    private mainWindow: null | TerceraWindowBase | TerceraWindowBase_ts = null;
    private terceraAccountLookupDropDownForm: null | TerceraAccountLookupDropDownForm = null;
    private terceraInstrumentLookupDropDownForm: null | TerceraInstrumentLookupDropDownForm = null;

    public constructor () {
        document.addEventListener('fullscreenchange', (event: Event) => {
            FullScreenManager.HandleFullscreenChange(event);
        });
    }

    // eslint-disable-next-line @typescript-eslint/dot-notation
    public get MainWindow (): TerceraWindowBase | TerceraWindowBase_ts | null {
        return FullScreenManager.IsFullScreenMode
            ? ControlsUtils.getRactiveFromHTMLElement(document.fullscreenElement as HTMLElement)
            : this.mainWindow;
    }

    public set MainWindow (value: TerceraWindowBase | TerceraWindowBase_ts | null) { this.mainWindow = value; }

    public get TerceraAccountLookupDropDownForm (): TerceraAccountLookupDropDownForm | null {
        return FullScreenManager.IsFullScreenMode ? FullScreenManager.AccountLookupDropDownForm : this.terceraAccountLookupDropDownForm;
    }

    public set TerceraAccountLookupDropDownForm (form: TerceraAccountLookupDropDownForm | null) {
        this.terceraAccountLookupDropDownForm = form;
    }

    public get TerceraInstrumentLookupDropDownForm (): TerceraInstrumentLookupDropDownForm | null {
        return FullScreenManager.IsFullScreenMode ? FullScreenManager.InstrumentLookupDropDownForm : this.terceraInstrumentLookupDropDownForm;
    }

    public set TerceraInstrumentLookupDropDownForm (form: TerceraInstrumentLookupDropDownForm | null) {
        this.terceraInstrumentLookupDropDownForm = form;
    }
}

export const MainWindowManager = new _MainWindowManager();
