// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PriceType } from "../../../../../Utils/History/CashItemUtils.ts";
import { iBuildInIndicator } from "../../iBuildInIndicator.ts";


export let iWPR = function (period)
{
    iBuildInIndicator.call(this, 1)
    this.FRPeriod = period

}
iWPR.prototype = Object.create(iBuildInIndicator.prototype)

Object.defineProperty(iWPR.prototype, 'Name',
    {
        get: function () { return 'iWPR' }
    })

Object.defineProperty(iWPR.prototype, 'Key',
    {
        get: function () { return this.DefaultKey + this.FRPeriod }
    })


iWPR.prototype.OnQuote = function (ci, callBound, callFromRefresh)
{
    if (this.Parent === null || this.FCount < this.FRPeriod)
        return;

    let high = 0.0;
    let low = 10000000.0;
    for (let period = 0; period < this.FRPeriod; period++)
    {
        let price = this.GetPrice(PriceType.High, period);
        if (price > high)
            high = price;
        price = this.GetPrice(PriceType.Low, period);
        if (price < low)
            low = price;
    }

    // %R = (HIGH(i-n)-CLOSE)/(HIGH(i-n)-LOW(i-n))*100
    if (high > 0.0 && (high - low) > 0.0)
        this.SetValue(-100 * (high - this.GetPrice(PriceType.Close, 0)) / (high - low));
    else
        this.SetValue(0.0);
}