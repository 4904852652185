// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PriceType } from "../../../../../Utils/History/CashItemUtils.ts";
import { MAMode } from "../../IndicatorConstants.ts";
import { IndicatorFunctions } from "../../IndicatorFunctions.ts";
import { iBearsPower } from "./iBearsPower.js";

export let iBullsPower = function (maPeriod, priceType)
{
    iBearsPower.call(this, maPeriod, priceType)
}
iBullsPower.prototype = Object.create(iBearsPower.prototype);

Object.defineProperty(iBullsPower.prototype, 'Name',
    {
        get: function () { return 'iBullsPower' },
        set: function () { }
    })

iBearsPower.prototype.OnQuote = function (ci, callBound, callFromRefresh)
{
    let price = this.GetPrice(this.FPriceType, 0)
    this.FEMA[this.FEMA.Length - 1] = price;
    let Parent = this.Parent

    if (!Parent)
        return
    if (this.FMaPeriod > this.Count - 1)
        return;

    this.SetValue(this.GetPrice(PriceType.High, 0) - IndicatorFunctions.CallMovingFunction(MAMode.EMA, this.FEMA, this.FMaPeriod, 1));
}