// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { OELeverageSelectorTemplate } from "../../../templates.js";
import { ContainerControl } from "../../elements/ContainerControl.js";
import { Resources } from "../../../Commons/properties/Resources.ts";
import { OrderUtils } from "../../../Utils/Trading/OrderUtils.ts";
import { ProductType } from "../../../Utils/Instruments/ProductType.ts";

export let OELeverageSelector = ContainerControl.extend({
    template: OELeverageSelectorTemplate,
    data: function ()
    {
        return {
            label: '',

            instrument: null,
            account: null,

            items: null,
            selectedItem: null,
            defaultValue: null,

            leverageValue: null,
            productType: null,
            showLastValue: false,

            visible: false,
            enabled: true,

            showLabel: true,

            tooltip: 'OELeverageSelector.tooltip'
        }
    }
})

OELeverageSelector.prototype.getType = function () { return 'OELeverageSelector' };

OELeverageSelector.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.call(this)
    this.observe('selectedItem', this.updateLeverageValue)
    this.observe('leverageValue', this.updateSelectedItemByLeverage)
    this.observe('instrument account productType', this.repopulate)
    Resources.onLocaleChange.Subscribe(this.localize, this)
    this.localize()
}

OELeverageSelector.prototype.dispose = function ()
{
    Resources.onLocaleChange.UnSubscribe(this.localize, this)

    ContainerControl.prototype.dispose.call(this)
}

OELeverageSelector.prototype.localize = function ()
{
    this.set('label', Resources.getResource('panel.newOrderEntry.Leverage'))
}

OELeverageSelector.prototype.updateLeverageValue = function (selectedItem)
{
    this.set('leverageValue', selectedItem ? selectedItem.value : null)
}

OELeverageSelector.prototype.updateSelectedItemByLeverage = function (leverage)     // need for CreateAlertPanel to change ComboBox selected item by custom leverageValue
{
    let currentSelectedItem = this.get('selectedItem')
    if (currentSelectedItem && currentSelectedItem.value != leverage)
    {
        let items = this.get('items')
        for (let i = 0; i < items.length; i++)
            if (items[i].value == leverage)
            {
                this.set('selectedItem', items[i])
                return
            }
    }
}

OELeverageSelector.prototype.repopulate = function (newV, oldV)
{
    if (newV && newV == oldV)
        return

    let instrument = this.get('instrument')
    let account = this.get('account')
    let productType = this.get('productType') || ProductType.General;
    let dict = instrument ? instrument.getLeverages(account, productType) : null;

    if (!instrument || !account || instrument.IsEmpty || !dict || !dict.length || !instrument.isHedgingNettingType())
        return this.set({
            leverageValue: null,    //#113886
            visible: false
        })

    let items = dict.map(function (leverage)
    {
        return {
            value: leverage,
            text: OrderUtils.GetFormattedLeverage(leverage)
        }
    })

    let visible = true,//(items[0].value == ProductType.General) ? false : true,           // при ProductType.General скрываем комбобокс
        enabled = (items.length === 1) ? false : true                               // при единственном ProductType делаем комбобокс enabled

    this.set({
        defaultValue: items.length ? items[items.length - 1].value : null,
        items: items,
        visible: visible,
        enabled: enabled
    })
}