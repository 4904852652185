// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { AlertUtils } from "../../../Utils/Alert/AlertUtils.ts";
import { CreateAlertNotificationSelectorTemplate } from "../../../templates.js";
import { ContainerControl } from "../../elements/ContainerControl.js";
import { Resources } from "../../../Commons/properties/Resources.ts";
import { CustomEvent } from "../../../Utils/CustomEvents.ts";
import { AlertNotification } from "../../../Utils/Alert/AlertConstants.ts";

export let CreateAlertNotificationSelector = ContainerControl.extend({
    template: CreateAlertNotificationSelectorTemplate,
    data: function ()
    {
        return {
            label: '',
            instrument: null,

            items: null,
            selectedValue: AlertNotification.SHOW_POP_UP,//1+2+4+8=15
            width: 198,
            //впадлу чёт думать
            additionalLeftCB: 0// для PropertySetupScreen нужно комбобокс отодвинуть на 21 пиксель
        }
    },
    ValueChanged: null
})

CreateAlertNotificationSelector.prototype.getType = function () { return 'CreateAlertNotificationSelector' };

CreateAlertNotificationSelector.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.call(this)
    this.ValueChanged = new CustomEvent();
    // this.repopulate();
    this.observe('selectedValue', this.repopulate);//, { init: false });
    this.on("selectionValueChange", this.McbValueChanged);

    Resources.onLocaleChange.Subscribe(this.localize, this);
    this.localize();
}

CreateAlertNotificationSelector.prototype.dispose = function ()
{
    Resources.onLocaleChange.UnSubscribe(this.localize, this)

    ContainerControl.prototype.dispose.call(this)
}

CreateAlertNotificationSelector.prototype.repopulate = function ()
{
    let value = this.get("selectedValue");
    let item = AlertUtils.GetAlertNotificationItems(value);

    this.set({ items: item });
}

CreateAlertNotificationSelector.prototype.McbValueChanged = function ()
{
    let items = this.get("items");
    let res = 0;
    for (let i = 0; i < items.length; i++)
        if (items[i].checked)
            res += items[i].value
    this.set({ selectedValue: (res || AlertNotification.SHOW_POP_UP) })
    if (!res)
        this.repopulate()

    this.ValueChanged.Raise(this, res || AlertNotification.SHOW_POP_UP);
}

CreateAlertNotificationSelector.prototype.localize = function ()
{
    this.set({
        label: Resources.getResource('screen.Alerts.Label.Notify'),
        // infoBtnTooltip: Resources.getResource('screen.Alerts.Label.Notify.InfoTooltip')
        allItemsSelectedText: Resources.getResource('screen.Alerts.Notify.All'),
        noItemsSelectedText: "No notifications",//Resources.getResource('panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.No exchanges'),
        fewItemsSelectedPostfixText: Resources.getResource('screen.Alerts.Notify.Few')
    });
}