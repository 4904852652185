// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from "../../Utils/CustomEvents.ts";
import { contextMenuHandler } from "../../Utils/AppHandlers.js";
import { TerceraTabStripTemplate } from "../../templates.js";
import { ContainerControl } from "./ContainerControl.js";

export let TerceraTabStrip = ContainerControl.extend({

    template: TerceraTabStripTemplate,
    data: function ()
    {
        return {
            items: [],
            selectedItem: null,
            maxElementCount: -1,
            allowUserEdit: true,
            allowMoreActionMenu: false,
            allowCloseTab: true,
            allowAddNewTab: true,
            showMoreItem: false,/*todo to false*/

            poweredByText: null,       // #88323
            poweredByURL: null,
            poweredByVisible: false
        };
    },
    onTabChange: null,
    onTabRemove: null,
    onTabCreate: null,
    onTabLock: null,
    onTabRemovePrepare: null,
    timerId: null
});

TerceraTabStrip.prototype.getType = function () { return 'TerceraTabManagerRactive' };

TerceraTabStrip.prototype.oninit = function (options)
{
    ContainerControl.prototype.oninit.apply(this, [options]);
    this.on('tabStripItem', this.tabStripItemChange);
    this.on('tabStripNewItem', this.createNewTabStripItem);
    this.on('tabStripShowMore', this.tabStripShowMoreAction);
    this.on('tabStripDeleteItem', this.deletTabStripItemPrepare);
    this.on('tabStripLockItem', this.lockTabStripItem);
    this.on('onContextMenu', this.onContextMenu);
    this.on('tabStripShowMoreActionMenu', this.tabStripShowMoreActionMenu);
    this.on('tabStripDoubleClick', this.tabStripDoubleClick);
    this.onTabChange = new CustomEvent();
    this.onTabRemove = new CustomEvent();
    this.onTabCreate = new CustomEvent();
    this.onTabLock = new CustomEvent();
    this.onTabRemovePrepare = new CustomEvent();
    this.onContextMenuShow = new CustomEvent();
    this.onTabStripShowMoreActionMenu = new CustomEvent();
    this.onTabStripDoubleClick = new CustomEvent();

    this.updateView = this.updateView.bind(this);
    this.delayUpdateView = this.delayUpdateView.bind(this);

    $(window).on('resize', this.delayUpdateView);
    this.observe('items selectedItem', this.delayUpdateView, { init: false, defer: true });
};

TerceraTabStrip.prototype.deletTabStripItemPrepare = function (context, index)
{
    if (this.onTabRemovePrepare.Subscribers.length < 0)
        this.deletTabStripItem(event, index, true);
    else
    {
        var items = this.get('items');
        var activeItem = this.get('selectedItem');
        var curItem = items[index];
        this.onTabRemovePrepare.Raise(this.deletTabStripItem.bind(this, context, index, true), curItem.data);
    }
};

TerceraTabStrip.prototype.deletTabStripItem = function (context, index, skipConfirm)
{
    var items = this.get('items');
    var activeItem = this.get('selectedItem');
    var curItem = items[index];
    items.splice(index, 1);
    if (curItem === activeItem && items.length !== 0)
    {
        var newIndex = index;
        if (index !== 0 && items.length >= index)
            newIndex = index - 1;
        var newActiveItem = items[newIndex];
        newActiveItem.active = true;
        this.set({ items: items, selectedItem: newActiveItem });
    }
    else
        this.set({ items: items });
    this.onTabRemove.Raise(curItem.data, skipConfirm);
};

TerceraTabStrip.prototype.lockTabStripItem = function (context, index)
{
    var activeItem = this.get('selectedItem');
    var items = this.get('items');
    activeItem.locked = !activeItem.locked;
    this.onTabLock.Raise(items[index].data);
};

TerceraTabStrip.prototype.tabStripItemChange = function (context, index)
{
    let items = this.get('items');
    let selectedItem = this.get('selectedItem');

    let newSelectedItem = items[index];
    if (newSelectedItem === selectedItem || !newSelectedItem.enabled)
        return;

    if (selectedItem)
        selectedItem.active = false;

    newSelectedItem.active = true;

    this.set({
        items: items,
        selectedItem: newSelectedItem
    });

    this.onTabChange.Raise(newSelectedItem.data);
};

TerceraTabStrip.prototype.onContextMenu = function (context, index)
{
    this.tabStripItemChange(context, index);
    this.onContextMenuShow.Raise(context);
};

TerceraTabStrip.prototype.tabStripShowMoreActionMenu = function (context, index)
{
    this.onTabStripShowMoreActionMenu.Raise(context);
};

TerceraTabStrip.prototype.tabStripDoubleClick = function (context, index)
{
    this.onTabStripDoubleClick.Raise(context);
};

TerceraTabStrip.prototype.createNewTabStripItem = function (context, index)
{
    var selectedItem = this.get('selectedItem');
    if (selectedItem)
        selectedItem.active = false;
    var items = this.get('items');
    var maxElementCount = this.get('maxElementCount');
    if (maxElementCount !== -1 && items.length >= maxElementCount)
    {
        this.fire('maxElementsCountReached');
        return;
    }
    var intitValue = new TerceraTabStripItem();
    intitValue.active = true;
    items.push(intitValue);
    this.set({ items: items, selectedItem: items[items.length - 1] });
    this.onTabCreate.Raise();
};

TerceraTabStrip.prototype.tabStripShowMoreAction = function (context, index)
{
    var items = this.get('items');
    var i;
    var len = items.length;
    var hiddenArray = [];
    for (i = 0; i < len; i++)
    {
        if (!items[i].visible)
            hiddenArray.push({
                text: items[i].text,
                tag: i,
                enabled: true,
                event: this.showMoreContextMenuItemSelect.bind(this)
            });
    }
    var offset = $(this.find('div')).children('.tabStrip-showMoreItem').offset();
    var posY = offset.top;//- $(window).scrollTop() + 19;
    var posX = offset.left;// - $(window).scrollLeft() - 2;
    contextMenuHandler.Show(hiddenArray, posX, posY);
};
TerceraTabStrip.prototype.showMoreContextMenuItemSelect = function (data)
{
    this.tabStripItemChange(null, data.tag);
}

TerceraTabStrip.prototype.selectItem = function (data)
{
    var items = this.get('items');
    var i;
    var len = items.length;
    for (i = 0; i < len; i++)
    {
        var curItem = items[i];
        if (curItem.data === data)
        {
            curItem.active = true;
            this.set({ items: items, selectedItem: curItem });
            break;
        }
    }
};

TerceraTabStrip.prototype.setLockedStateForSelectedItem = function (locked)
{
    var selectedItem = this.get('selectedItem');
    var items = this.get('items');
    if (!selectedItem || !items) return;

    var selectedItemIdx = items.indexOf(selectedItem);
    if (selectedItemIdx === -1) return;

    this.set('items.' + selectedItemIdx + '.locked', locked);
}

TerceraTabStrip.prototype.setTabNameByData = function (searchData, newName)
{
    var items = this.get('items');
    var len = items.length;
    for (var i = 0; i < len; i++)
    {
        var item = items[i];
        if (item.hasOwnProperty('data') && item.data === searchData)
        {
            this.set('items.' + i + '.text', newName);
            return;
        }
    }
};

TerceraTabStrip.prototype.oncomplete = function ()
{
    ContainerControl.prototype.oncomplete.apply(this);

    let items = this.get('items')

    if (items)
        this.set({ selectedItem: items[0] });
};

TerceraTabStrip.prototype.dispose = function ()
{
    $(window).off('resize', this.delayUpdateView);
    clearTimeout(this.timerId);
};

TerceraTabStrip.prototype.delayUpdateView = function ()
{
    if (this.updateNOW)
        return;
    //clearTimeout(this.timerId);
    this.updateView();
    //this.timerId = setTimeout(this.updateView, 50);
};

TerceraTabStrip.prototype.getActiveElementSizeParams = function ()
{
    let strip = $(this.find('div'));
    let items = $(strip.children('.tabStrip-item'));
    let offset;
    let width;
    let height;
    for (let i = 0; i < items.length; i++)
    {
        let currentElement = items[i]
        if (currentElement.getAttribute("active") !== null)
        {
            offset = $(currentElement).offset();
            width = $(currentElement).width()
            height = $(currentElement).height()
            break;
        }
    }
    return { offset: offset, width: width, height: height };
}

TerceraTabStrip.prototype.updateView = function ()
{
    this.updateNOW = true;
    if (!this.get('items'))
    {
        this.updateNOW = false;
        this.hasHidden = false;
        this.set({ showMoreItem: false/*todo to false*/ });
        return;
    }

    var items = this.get('items');
    var itemsLength = items.length;
    var selItem = this.get('selectedItem');
    var selItemIdx = items.indexOf(selItem);
    var visibleHideMap = [];

    var $root = $(this.find('div'));
    var $tItems = $root.children('.tabStrip-item');

    var tabStripW = $root.width();
    if (tabStripW === 0)
    {
        setTimeout(this.updateView, 150);
        return;
    }
    //else
    //return;

    var totalTItemsW = 40;//40pixels for selection
    var margin = 2;
    var narrowBtnW = margin + 20;

    var poweredByLinkMargin = 0, poweredByLinkMarginPlus = 0
    if (this.get('poweredByVisible'))       // # 88323
    {
        poweredByLinkMargin = poweredByLinkMarginPlus = 130
        poweredByLinkMarginPlus++
    }


    // "+" button.
    totalTItemsW += narrowBtnW;
    // "Show all items" button.
    totalTItemsW += narrowBtnW;
    var indexOfLastVisible = 0;
    for (var i = 0; i < itemsLength; i++)
    {
        totalTItemsW += margin + $tItems.eq(i).outerWidth();
        if (totalTItemsW >= tabStripW - poweredByLinkMargin)
        {
            indexOfLastVisible = i - 1;
            break;
        }
        indexOfLastVisible = itemsLength - 1;
    }

    if (totalTItemsW <= tabStripW - poweredByLinkMarginPlus)
    {
        for (var i = 0; i < itemsLength; i++)
            items[i].visible = true;
        this.set({ items: items, showMoreItem: false/*todo to false*/ });
        this.updateNOW = false;
        this.hasHidden = false;
        return;
    }

    var startIndex = (selItemIdx > indexOfLastVisible) ? (selItemIdx - indexOfLastVisible) : 0;
    var endIndex = (selItemIdx > indexOfLastVisible) ? selItemIdx : indexOfLastVisible;

    for (var i = 0; i < itemsLength; i++)
    {
        if (startIndex <= i && i <= endIndex)
            visibleHideMap.push(true);
        else
            visibleHideMap.push(false);
    }

    for (var i = 0; i < itemsLength; i++)
        items[i].visible = visibleHideMap[i];

    this.set({ items: items, showMoreItem: true });
    this.hasHidden = true;
    this.updateNOW = false;
};

export let TerceraTabStripItem = function ()
{
    this.text = "";
    this.data = "";
    this.locked = false;
    this.visible = true;
    this.active = false;
    this.enabled = true;
};

TerceraTabStrip.prototype.setPoweredByTextAndLink = function (text, url)
{
    this.set({
        poweredByText: text,
        poweredByVisible: !!text,
        poweredByURL: url
    });
}