// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { ErrorInformationStorage } from '../../Commons/ErrorInformationStorage';
import { Resources } from '../../Commons/properties/Resources';
import { Color } from '../../Commons/Graphics';
import { AssetBalanceItem } from '../cache/AssetBalanceItem';
import { VerticalApplicationPanelNew } from '../cache/VerticalPanel/VerticalApplicationPanelNew';
import { VerticalPanelDataProvider } from '../cache/VerticalPanel/VerticalPanelDataProvider';
import { VerticalPanelDataProviderItem } from '../cache/VerticalPanel/VerticalPanelDataProviderItem';
import { QuickTableRow } from '../elements/QuickTable/QuickTableRow';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { ThemeManager } from '../misc/ThemeManager';
import { AccountType } from '../../Utils/Account/AccountType';
import { QuickTableComparingType } from '../../Utils/QuickTableMisc/QuickTableComparingType';
import { AccountAdditionalInfoDestinationPanel } from '../../Utils/Account/AdditionalInfoItem';
import { type Account } from '../../Commons/cache/Account';
import { DynProperty } from '../../Commons/DynProperty';
import { DataCache } from '../../Commons/DataCache';
import { SessionSettings } from '../../Commons/SessionSettings';
import { TerceraBaseWindowTemplate, AccountDetailsPanelTemplate } from '../../templates';
import { AccountWidgetAssetsController } from '../../Commons/AccountWidget/AccountWidgetAssetsController';
import { AccountWidgetAccountsController } from '../../Commons/AccountWidget/AccountWidgetAccountsController';
import { RulesSet } from '../../Utils/Rules/RulesSet';
import { ExportScreen } from '../screen/ExportScreen.js';
import { Control } from '../elements/Control_ts';
import { AccountWidgetAccountDetailsPanelController } from '../../Commons/AccountWidget/AccountWidgetAccountDetailsPanelController';
import { ControlsUtils } from '../UtilsClasses/ControlsUtils';

export class AccountDetailsPanel extends VerticalApplicationPanelNew {
    myAccount: any;
    myAccLookup: any;
    assetComboBox: any;
    quickTableRactive: any;
    deferredLinkInAccId: any;
    deferredAssetId: any;
    Controls: any;
    VerticalPanelDataProvider: any;
    myAccLinkBtn: any;
    sett: any;
    accountDinamicProperties: any = {};
    skipOnSave: boolean = true;
    NeedCalculateRowCount: boolean = false;
    headerLocaleKey: string = 'panel.AccountDetailsPanel';

    private mouseOnPanel: boolean = false;
    private mousePressedPanel: boolean = false;

    public getType (): PanelNames { return PanelNames.AccountDetailsPanel; };

    public oncomplete (): void {
        super.oncomplete();

        /// <summary>
        /// Кэшируем динамические строки
        /// </summary>

        this.quickTableRactive.setShowColumnHeaders(false);
        const qt = super.getQuickTable();
        qt.allowGroupBy = false;
        qt.GroupIndexSorting = true;
        qt.useVerticalSeparatorForResizing = true;
        qt.FormatGroup = this.quickTable_FormatGroup.bind(this);

        super.observe('selectedAsset', this.onSelectedAssetChanged);
        const items = AccountWidgetAssetsController.AssetsArray;
        const assetsVisible = items.length > 1;
        const height = assetsVisible ? 546 : 505;
        const [assetsItems, width] = this.toComboBoxItems(items);
        void super.set({ height, assetsVisible, assetsItems, listWidth: width });
        this.initAsset();
        this.initQt();
        AccountWidgetAccountsController.subscribeOnAccountChanged(this.onAccountChanged);
        this.onAccountChanged(AccountWidgetAccountsController.Account, null);
        AccountWidgetAccountDetailsPanelController.onAccountLinkOut.Subscribe(this.accountLink_Out, this);
        AccountWidgetAccountDetailsPanelController.onAssetSet.Subscribe(this.selectAsset, this);
        AccountWidgetAccountDetailsPanelController.HideAccountDetailsPanel.Subscribe(this.hide, this);

        Control.Ticker.Subscribe(super.TickAsync, this);

        this.on('panelOver', this.mouseOver);
        this.on('panelLeave', this.mouseLeave);
    }

    private initQt (): void {
        const qt = super.getQuickTable();
        qt.onTableMouseUP.Subscribe(this.onTableMouseUP, this);
        qt.onTableMouseDown.Subscribe(this.onTableMouseDown, this);
        qt.onTableMouseMove.Subscribe(AccountWidgetAccountDetailsPanelController.onTableMouseMove, this);

        const items = [];
        if (!Resources.isHidden('screen.export.visibility')) {
            items.push(
                {
                    text: Resources.getResource('screen.export.contextMenu'),
                    event: ExportScreen.show.bind(null, this)
                }
            );
        }
        qt.setTableContextMenuItems(items);
    }

    private onTableMouseUP (hittestInfo): void {
        this.mousePressedPanel = false;
        AccountWidgetAccountDetailsPanelController.onTableMouseUP(hittestInfo);
    };

    private onTableMouseDown (hittestInfo): void {
        this.mousePressedPanel = true;
        AccountWidgetAccountDetailsPanelController.onTableMouseDown(hittestInfo);
    };

    private readonly onAccountChanged = (newAccount: Account, oldAccount: Account): void => {
        if (isNullOrUndefined(newAccount)) return;
        this.SetSource(DataCache, newAccount.BstrAccount);
        this.myAccount = newAccount;
        this.PopulateTable();
        AccountWidgetAssetsController.Account = newAccount;
        const items = AccountWidgetAssetsController.AssetsArray;
        const assetsVisible = items.length > 1;
        const height = assetsVisible ? 546 : 505;
        const [assetsItems, width] = this.toComboBoxItems(items);
        void super.set({ height, assetsVisible, assetsItems, listWidth: width });
    };

    public PopulateTable (): void {
        super.PopulateTable();
        AccountWidgetAccountDetailsPanelController.raiseonRePopulatedPanel();
    }

    private onSelectedAssetChanged (selectedItem): void {
        if (isNullOrUndefined(selectedItem?.value)) return;

        const account = AccountWidgetAccountsController.Account;
        let asset = null;
        if (AccountWidgetAssetsController.AssetsArray.length > 1) { asset = account.assetBalances[selectedItem.text]; }
        if (!isNullOrUndefined(asset)) {
            this.VerticalPanelDataProvider.Source[0].AssetBalance = asset;
            AccountWidgetAssetsController.SelectedAsset = selectedItem.value;
            super.PopulateTable();
        }
    }

    hide (): void {
        AccountWidgetAccountDetailsPanelController.onPanelClose();
        void super.set({ visible: false, showModalMask: false });
    }

    show (): void {
        AccountWidgetAccountDetailsPanelController.onPanelOpen();
        void super.set({ visible: true, showModalMask: true });
    }

    isShowed (): boolean {
        return this.get('visible');
    }

    isMouseOnPanel (): boolean {
        return this.mouseOnPanel || this.mousePressedPanel;
    }

    private mouseOver (containerContext): void { this.mouseOnPanel = true; }
    private mouseLeave (containerContext): void { this.mouseOnPanel = false; }

    private initAsset (): void {
        if (DataCache.isAllowedForMyUser(RulesSet.FUNCTION_EXT_RISK) && super.get('items').length > 1) {
            if (this.setAssetItems(super.get('assetsItems'), AccountWidgetAssetsController.Account, super.get('selectedAsset'))) {
                const item = super.get('selectedItem');
                const asset = item.value.Name;

                const dp = this.VerticalPanelDataProvider;
                const accItems = dp.Source;
                const assetBalanceItem = accItems[0];

                const keys = Object.keys(AccountWidgetAssetsController.Account.assetBalances[asset].AssetAdditionalInfo);
                if (keys.length <= 0) { return; }
                SessionSettings.save();
            }
        }
    }

    setAssetItems (items, account, assetProp): boolean {
        if (assetProp) {
            if (account.assetBalances[assetProp.text] && Object.keys(account.assetBalances[assetProp.text].AssetAdditionalInfo).length !== 0) {
                this.selectAsset(account.assetBalances[assetProp.text].Asset.Id);
                return true;
            }
        }

        for (let i = 0; i < items.length; i++) {
            if (Object.keys(account.assetBalances[items[i].text].AssetAdditionalInfo).length !== 0) {
                this.selectAsset(account.assetBalances[items[i].text].Asset.Id);
                return true;
            }
        }
        return false;
    }

    toComboBoxItems (assetIds): any[] {
        const arr = [];
        let width = 0;
        for (let i = 0; i < assetIds.length; i++) {
            const asset = assetIds[i];
            arr.push({ text: asset.Name, value: asset });
            width = Math.max(width, ControlsUtils.GetTextWidth(asset.Name, '12px \"Trebuchet MS\"') + 20);
        }
        const funcSort = function (a, b): number {
            if (a.text.toLowerCase() > b.text.toLowerCase()) { return 1; } else { return -1; }
        };
        arr.sort(funcSort);

        return [arr, width];
    };

    accountLink_Out (newSubscriber: boolean, account?: Account, sameAccs?: boolean): void {
        if (!account) {
            if (!AccountWidgetAssetsController.Account) { return; }
            account = AccountWidgetAssetsController.Account;
        }
        if (!this.myAccLinkBtn) { return; }

        super.accountLink_Out(newSubscriber, account);

        if (!sameAccs) { SessionSettings.save(); }
    };

    accountLink_In (accId): boolean {
        AccountWidgetAccountsController.Account = DataCache.GetAccountById(accId);
        return true;
    };

    selectAsset (assetId): void {
        if (!this.assetComboBox) {
            this.deferredAssetId = assetId;
            return;
        }

        const items = this.assetComboBox.get('items');
        const len = items ? items.length : 0;
        for (let i = 0; i < len; i++) {
            const item = items[i];
            if (item.value.Id === assetId) {
                this.assetComboBox.set('selectedItem', item);
                return;
            }
        }
    };

    Properties (): DynProperty[] {
        const properties = super.Properties();
        properties.push(new DynProperty('AccountId', AccountWidgetAccountsController.Account.AcctNumber, DynProperty.STRING, DynProperty.HIDDEN_GROUP));

        return properties;
    };

    callBack (newProperties: DynProperty[]): void {
        super.callBack(newProperties);
        const AccountId = DynProperty.getPropValue(newProperties, 'AccountId');
        if (!isNullOrUndefined(AccountId)) { this.accountLink_In(AccountId); }
    };

    repopulate (): void {
        super.repopulate();
        const singleAccount = DataCache.getNumberOfAccounts() === 1;

        if (this.myAccLinkBtn) { this.myAccLinkBtn.set({ visible: !singleAccount, enabled: !DataCache.EnableForceLinkingByAccount() }); }

        if (this.assetComboBox) {
            const acc = AccountWidgetAssetsController.Account;
            let visible = false;
            if (acc) { visible = Object.keys(acc.assetBalances).length > 1; }
            this.assetComboBox.set({ visible });
        }
        this.InitializeDataProvider();
    };

    /// <summary>
    /// вызов из конструктора баз. класса
    /// </summary>
    InitializeDataProvider (): void {
        this.VerticalPanelDataProvider = new VerticalPanelDataProvider(this.sett);

        this.VerticalPanelDataProvider.items = this.getDataItems(this.VerticalPanelDataProvider, this.sett);

        //
        this.VerticalPanelDataProvider.GetVerticalPanelValueHandler = this.GetDataValue.bind(this);
        this.VerticalPanelDataProvider.GetItemsIDHandler = this.GetItemsID.bind(this);
        this.VerticalPanelDataProvider.DisposeHandler = this.DisposeSource.bind(this);
        //
        this.VerticalPanelDataProvider.GetDynamicItemsHandler = this.GetDynamicItems.bind(this);
    };

    quickTable_FormatGroup (groupValue): string {
        return Resources.getResource(groupValue);
    };

    localize (): void {
        super.localize();

        if (!isNullOrUndefined(this.VerticalPanelDataProvider)) { this.VerticalPanelDataProvider.Localize(); }
    };

    getDataItems (VerticalPanelDataProvider, sett): any[] {
    // #22483 Admin и видимость полей
    // Data items
        const dataItems = [];

        let assetBalanceItem = new AssetBalanceItem(null, sett);
        const columnCount = assetBalanceItem.ColumnCount();
        for (let i = 0; i < columnCount; i++) {
        // problem: на Reinitialize сбрасывалась настроенная видимость полей, и цвета. а регенерация нужна т.к. админ/не адмир разные поля можно
            const visibilityRestore = null;// VerticalPanelDataProvider.items === null || VerticalPanelDataProvider.items.length === 0 ? null : VerticalPanelDataProvider.items.Find(m => m.Id === i.toString());

            const columnParams = assetBalanceItem.GetColumnParams(i);
            const group = AccountDetailsPanel.GetGroupForKey(columnParams.HeaderKey);
            if (VerticalPanelDataProvider.Source instanceof Array && VerticalPanelDataProvider.Source.length > 1 && group === 'panel.accountDetails.Groups.4.RiskManagement' || group === 'panel.accountDetails.Groups.6.Info') { continue; }

            const newItem = new VerticalPanelDataProviderItem();
            newItem.Id = i.toString();
            newItem.Hidden = columnParams.Hidden;
            newItem.visible = !columnParams.Hidden && (AccountDetailsPanel.TableItemVisible(i) || (visibilityRestore === null || visibilityRestore.visible));
            newItem.LocalizationKey = columnParams.HeaderKey;
            newItem.tooltipKey = Resources.GetToolTipKey(columnParams.HeaderKey);
            newItem.Group = group;
            newItem.SortIndex = AccountDetailsPanel.GetSortIndexByIndex(i);

            newItem.ValueBackColor = visibilityRestore === null ? Color.Empty : visibilityRestore.ValueBackColor;
            newItem.ValueForeColor = visibilityRestore === null ? Color.Empty : visibilityRestore.ValueForeColor;
            newItem.HeaderBackColor = visibilityRestore === null ? Color.Empty : visibilityRestore.HeaderBackColor;
            newItem.HeaderForeColor = visibilityRestore === null ? Color.Empty : visibilityRestore.HeaderForeColor;
            dataItems.push(newItem);
        }
        assetBalanceItem.Dispose();
        assetBalanceItem = null;

        dataItems.sort(function (a, b) {
            return a.SortIndex - b.SortIndex;
        });

        return dataItems;
    };

    static GetGroupForKey (headerKey: string): string {
        switch (headerKey) {
        case 'panel.accounts.Balance':
        case 'panel.accounts.CurBalance':
        case 'panel.accounts.BalancePlusAllRisks':
        case 'panel.accounts.AccountValue':
        case 'panel.accounts.CreditValue':
        case 'panel.accounts.AvailableMargin':
        case 'panel.accounts.LockedBalance':
        case 'panel.accounts.CashBalance':
        case 'panel.accounts.WithdrawalAvailable':
        case 'panel.accounts.InterestRate':
        case 'panel.accounts.Estimate value':
        case 'panel.accounts.UnsettledCash':
        case 'panel.accounts.IncomingFunds':
        case 'panel.accounts.StocksValue':
        case 'panel.accounts.OptionValue':
        case 'panel.accounts.StocksLiquidity':
        case 'panel.accounts.Collateral':
        case 'panel.accounts.BuyingPower':
        case 'panel.accounts.GivenLeverage':
        case 'panel.accounts.OpenBalance':
            return 'panel.accountDetails.Groups.1.General';

        case 'panel.accounts.FundsUsed':
        case 'panel.accounts.FundsUsedPercent':
        case 'panel.accounts.MarginUsedNew':
        case 'panel.accounts.stopOutPercent':
        case 'panel.accounts.CurMargin':
        case 'panel.accounts.MaintMargin':
        case 'panel.accounts.Leverage':
        case 'panel.accounts.MarginAvailableReal':
        case 'panel.accounts.marginWarnPercent':
        case 'panel.accounts.MarginWarning':
        case 'panel.accounts.MarginRequirement':
        case 'panel.accounts.MinMargin':
        case 'panel.accounts.BlockedByOrders':
        case 'panel.accounts.MarginStopOut':
        case 'panel.accounts.StocksOrdersReq':
        case 'panel.accounts.MarginDeficiency':
        case 'panel.accounts.MarginSurplus':
        case 'panel.accounts.BlockedForStocks':
        case 'panel.accounts.MarginForOthers':
        case 'panel.accounts.BlockedForStocksNew':
        case 'panel.accounts.OptionPremiumReq':
        case 'panel.accounts.WaivedMargin':
        case 'panel.accounts.WarningMarginReq':
        case 'panel.accounts.WarningMarginReqPercent':
        case 'panel.accounts.MarginBeforeWarning':
        case 'panel.accounts.InitialMarginWithoutWaived':
        case 'panel.accounts.StopOut':
        case 'panel.accounts.LiveLeverage':
            return 'panel.accountDetails.Groups.2.Margin';

        case 'panel.accounts.CurrentFundCapital':
        case 'panel.accounts.FundCapitalGain':
        case 'panel.accounts.Profit':
        case 'panel.accounts.ProfitNet':
        case 'panel.accounts.Positions':
        case 'panel.accounts.Orders':// Unsettled cash -  not found
        case 'panel.accounts.BlockedByPamm':
            return 'panel.accountDetails.Groups.3.AccountActivity';

        case 'panel.accounts.GrossPNL':
        case 'panel.accounts.BeginBalance':
        case 'panel.accounts.TodayFees':
        case 'panel.accounts.TodayVolume':
        case 'panel.accounts.TodayTrades':
        case 'panel.accounts.TodayTurnover':
        case 'panel.accounts.TodayRebates':
        case 'panel.accounts.TodaySwaps':
            return 'panel.accountDetails.Groups.4.TodayResults';

        case 'panel.accounts.CurrentStatus':
        case 'panel.accounts.AllowTradingPrePost':
        case 'panel.accounts.MaxDailyLossLimit': // case "panel.accounts.MaxDayVolume":
        case 'panel.accounts.MaxOrdersPerDay':
        case 'panel.accounts.MaxPositions':
        case 'panel.accounts.MaxPendingOrders':
        case 'panel.accounts.MaxPendingOrdersValue':
        case 'panel.accounts.MaxOrderCapital':
        case 'panel.accounts.MaximumLot':
        case 'panel.accounts.MaxPositionQtyPerSymbol':
        case 'panel.accounts.AllowOvernightTrading':
        case 'panel.accounts.TrailingDrawdownLevel':
        case 'panel.accounts.TotalMaxPositionsQty':
        case 'panel.accounts.MaxWeekLostLimit':
        case 'panel.accounts.CurrentDailyLoss':
        case 'panel.accounts.CurrentWeeklyLoss':
        case 'panel.accounts.UnrealizedLoss':
        case 'panel.accounts.UnrealizedLossLimit':
        case 'panel.accounts.MaxDailyProfit':
        case 'panel.accounts.VolumeLimitForALL':
        case 'panel.accounts.VolumeLimitForFOREX':
        case 'panel.accounts.VolumeLimitForEQUITIES':
        case 'panel.accounts.VolumeLimitForFUTURES':
        case 'panel.accounts.VolumeLimitForOPTIONS':
        case 'panel.accounts.VolumeLimitForEQUITIES_CFD':
        case 'panel.accounts.VolumeLimitForFORWARD':
        case 'panel.accounts.VolumeLimitForCFD_FUTURES':
        case 'panel.accounts.VolumeLimitForINDICIES':
        case 'panel.accounts.VolumeLimitForCRYPTO':
        case 'panel.accounts.VolumeLimitForSPREADBET':
        case 'panel.accounts.VolumeLimitForBOND':
        case 'panel.accounts.VolumeLimitForETF':
        case 'panel.accounts.VolumeLimitForTBILL':
        case 'panel.accounts.VolumeLimitForSPOT':
        case 'panel.accounts.VolumeLimitForCORPORATE':
        case 'panel.accounts.MaxOrderAmount':
        case 'panel.accounts.DayTraderPatternProtection':
        case 'panel.accounts.AvailableDayTrades':
        case 'panel.accounts.MaxRelativeDrawDownLevel':
        case 'panel.accounts.LossLimitPerTrade':
        case 'panel.accounts.EODTrailingDrawdownLevel':
            return 'panel.accountDetails.Groups.5.RiskManagement';

        case 'panel.accounts.Commissions':
        case 'panel.accounts.Swap':
        case 'panel.accounts.Spreads':
        case 'panel.accounts.Murkups':
        case 'panel.accounts.MarketDataCharging':
        case 'panel.accounts.MarginAndRisks':
        case 'panel.accounts.InsertOnBalance':
            return 'panel.accountDetails.Groups.55.Plans';

        default:
            return 'panel.accountDetails.Groups.6.Info';
        }
    };

    static TableItemVisible (key): boolean {
    // switch default visibility by id
        try {
            switch (key) {
            // 1. General
            case 2:
            case 6:
            case 15:
            case 20:
            case 24:
            case 35:
            case 42:
            case 44:
            case 45:
            case 46:
            case 58:
            // 2. Account activity
            case 4:
            case 5:
            case 10:
            case 11:
            case 12:
            case 13:
            // 3. Today results
            case 29:
            case 30:
            case 31:
            case 32:
            case 71:
            // 4. Risk management  // по умолч. всё включено
            case 47:
            case 48:
            case 49:
            case 50:
            case 51:
            case 52:
            case 53:
            case 54:
            case 55:
            case 56:
            case 57:
            case 62:
            case 85:
            // 5. Info
            case 0:
            case 1:
            case 7:
            case 63:
                return true;
            default:
                return false;
            }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            return false;
        }
    };

    static KEY_GENERAL_GROUP = 1;
    static KEY_MARGIN_GROUP = 200;
    static KEY_ACCOUNTACTIVITY_GROUP = 300;
    static KEY_TODAYRESULTS_GROUP = 400;
    static KEY_RISKMANAGEMENT_GROUP = 500;
    static KEY_PLANS_GROUP = 600;
    static KEY_INFO_GROUP = 700;
    static GetSortIndexByIndex (columnIndex): number {
        switch (columnIndex) {
        // panel.accountDetails.Groups.1.General
        case 2:// panel.accounts.Balance
            return AccountDetailsPanel.KEY_GENERAL_GROUP + 5;
        case 6: // panel.accounts.CurBalance
            return AccountDetailsPanel.KEY_GENERAL_GROUP + 10;
        case 43:// panel.accounts.BalancePlusAllRisks
            return AccountDetailsPanel.KEY_GENERAL_GROUP + 15;
        case 35:// panel.accounts.AccountValue
            return AccountDetailsPanel.KEY_GENERAL_GROUP + 20;
        case 61: // panel.accounts.CreditValue
            return AccountDetailsPanel.KEY_GENERAL_GROUP + 25;
        case 20:// panel.accounts.AvailableMargin
            return AccountDetailsPanel.KEY_GENERAL_GROUP + 30;
        case 3: // panel.accounts.LockedBalance
            return AccountDetailsPanel.KEY_GENERAL_GROUP + 40;
        case 36:// panel.accounts.CashBalance
            return AccountDetailsPanel.KEY_GENERAL_GROUP + 45;
        case 84: // panel.accounts.UnsettledCash
            return AccountDetailsPanel.KEY_GENERAL_GROUP + 46;
        case 117: // panel.accounts.IncomingFunds
            return AccountDetailsPanel.KEY_GENERAL_GROUP + 47;
        case 24:// panel.accounts.WithdrawalAvailable
            return AccountDetailsPanel.KEY_GENERAL_GROUP + 50;
        case 70:// panel.accounts.InterestRate
            return AccountDetailsPanel.KEY_GENERAL_GROUP + 55;
        case 66: // panel.accounts.Estimate value
            return AccountDetailsPanel.KEY_GENERAL_GROUP + 60;
        case 34: // panel.accounts.StocksValue
            return AccountDetailsPanel.KEY_GENERAL_GROUP + 70;
        case 92: // panel.accounts.OptionValue
            return AccountDetailsPanel.KEY_GENERAL_GROUP + 75;
        case 93: // panel.accounts.StocksLiquidity
            return AccountDetailsPanel.KEY_GENERAL_GROUP + 80;
        case 103: // panel.accounts.Collateral
            return AccountDetailsPanel.KEY_GENERAL_GROUP + 85;

            // panel.accountDetails.Groups.2.Margin

        case 59: //  panel.accounts.FundsUsed
            return AccountDetailsPanel.KEY_MARGIN_GROUP + 5;
        case 60: //  panel.accounts.FundsUsedPercent
            return AccountDetailsPanel.KEY_MARGIN_GROUP + 10;
        case 42: //  panel.accounts.MarginUsedNew
            return AccountDetailsPanel.KEY_MARGIN_GROUP + 15;
        case 14: //  panel.accounts.CurMargin
            return AccountDetailsPanel.KEY_MARGIN_GROUP + 20;
        case 108://  panel.accounts.InitialMarginWithoutWaived
            return AccountDetailsPanel.KEY_MARGIN_GROUP + 21;
        case 15: //  panel.accounts.MaintMargin
            return AccountDetailsPanel.KEY_MARGIN_GROUP + 25;
        case 16: //  panel.accounts.Leverage
            return AccountDetailsPanel.KEY_MARGIN_GROUP + 30;
        case 58: //  panel.accounts.MarginAvailableReal
            return AccountDetailsPanel.KEY_MARGIN_GROUP + 35;
        case 17: //  panel.accounts.marginWarnPercent
            return AccountDetailsPanel.KEY_MARGIN_GROUP + 40;
        case 21: //  panel.accounts.MarginWarning
            return AccountDetailsPanel.KEY_MARGIN_GROUP + 45;
        case 23: //  panel.accounts.MarginStopOut
            return AccountDetailsPanel.KEY_MARGIN_GROUP + 50;
        case 44: //  panel.accounts.MarginRequirement
            return AccountDetailsPanel.KEY_MARGIN_GROUP + 55;
        case 12: //  panel.accounts.MinMargin
            return AccountDetailsPanel.KEY_MARGIN_GROUP + 60;
        case 13: //  panel.accounts.BlockedByOrders
            return AccountDetailsPanel.KEY_MARGIN_GROUP + 65;
        case 45: //  panel.accounts.MarginDeficiency
            return AccountDetailsPanel.KEY_MARGIN_GROUP + 70;
        case 46: //  panel.accounts.MarginSurplus
            return AccountDetailsPanel.KEY_MARGIN_GROUP + 75;
        case 72: //  panel.accounts.BlockedForStocks
            return AccountDetailsPanel.KEY_MARGIN_GROUP + 80;
        case 73: //  panel.accounts.MarginForOthers
            return AccountDetailsPanel.KEY_MARGIN_GROUP + 85;
        case 74: //  panel.accounts.MarginForOthers
            return AccountDetailsPanel.KEY_MARGIN_GROUP + 90;
        case 91: // panel.accounts.StocksOrdersReq
            return AccountDetailsPanel.KEY_MARGIN_GROUP + 95;
        case 19: // panel.accounts.stopOutPercent
            return AccountDetailsPanel.KEY_MARGIN_GROUP + 100;
        case 102: // panel.accounts.OptionPremiumReq
            return AccountDetailsPanel.KEY_MARGIN_GROUP + 101;
        case 104: // panel.accounts.WaivedMargin
            return AccountDetailsPanel.KEY_MARGIN_GROUP + 110;
        case 105: // panel.accounts.WarningMarginReq
            return AccountDetailsPanel.KEY_MARGIN_GROUP + 120;
        case 106: // panel.accounts.WarningMarginReqPercent
            return AccountDetailsPanel.KEY_MARGIN_GROUP + 130;
        case 107: // panel.accounts.MarginBeforeWarning
            return AccountDetailsPanel.KEY_MARGIN_GROUP + 140;
        case 115: // panel.accounts.StopOut
            return AccountDetailsPanel.KEY_MARGIN_GROUP + 150;

            // panel.accountDetails.Groups.3.AccountActivity

        case 39: //   panel.accounts.CurrentFundCapital
            return AccountDetailsPanel.KEY_ACCOUNTACTIVITY_GROUP + 5;
        case 40: //   panel.accounts.FundCapitalGain
            return AccountDetailsPanel.KEY_ACCOUNTACTIVITY_GROUP + 10;
        case 5: //   panel.accounts.Profit
            return AccountDetailsPanel.KEY_ACCOUNTACTIVITY_GROUP + 15;
        case 4: //   panel.accounts.ProfitNet
            return AccountDetailsPanel.KEY_ACCOUNTACTIVITY_GROUP + 20;
        case 11: //   panel.accounts.Positions
            return AccountDetailsPanel.KEY_ACCOUNTACTIVITY_GROUP + 25;
        case 10: //   panel.accounts.Orders
            return AccountDetailsPanel.KEY_ACCOUNTACTIVITY_GROUP + 30;
        case 37: //   panel.accounts.BlockedByPamm
            return AccountDetailsPanel.KEY_ACCOUNTACTIVITY_GROUP + 35;

        // panel.accountDetails.Groups.4.TodayResults
        case 29: //  panel.accounts.GrossPNL
            return AccountDetailsPanel.KEY_TODAYRESULTS_GROUP + 5;
        case 31: //  panel.accounts.BeginBalance
            return AccountDetailsPanel.KEY_TODAYRESULTS_GROUP + 10;
        case 30: //  panel.accounts.TodayFees
            return AccountDetailsPanel.KEY_TODAYRESULTS_GROUP + 15;
        case 32: //  panel.accounts.TodayVolume
            return AccountDetailsPanel.KEY_TODAYRESULTS_GROUP + 20;
        case 33: //  panel.accounts.TodayTrades
            return AccountDetailsPanel.KEY_TODAYRESULTS_GROUP + 25;
        case 68: //  panel.accounts.TodayTurnover
            return AccountDetailsPanel.KEY_TODAYRESULTS_GROUP + 30;
        case 71: //  panel.accounts.TodayRebates
            return AccountDetailsPanel.KEY_TODAYRESULTS_GROUP + 35;

            // panel.accountDetails.Groups.5.RiskManagement

        case 48: // panel.accounts.CurrentStatus
            return AccountDetailsPanel.KEY_RISKMANAGEMENT_GROUP + 5;
        case 49: // panel.accounts.AllowTradingPrePost
            return AccountDetailsPanel.KEY_RISKMANAGEMENT_GROUP + 10;
        case 50: // panel.accounts.MaxDailyLossLimit
            return AccountDetailsPanel.KEY_RISKMANAGEMENT_GROUP + 15;
        case 89: // panel.accounts.MaxDailyLossLimit
            return AccountDetailsPanel.KEY_RISKMANAGEMENT_GROUP + 16;
        case 88: // panel.accounts.MaxWeekLostLimit
            return AccountDetailsPanel.KEY_RISKMANAGEMENT_GROUP + 17;
        case 90: // panel.accounts.MaxDailyLossLimit
            return AccountDetailsPanel.KEY_RISKMANAGEMENT_GROUP + 18;
        case 86: // panel.accounts.TrailingDrawdownLevel
            return AccountDetailsPanel.KEY_RISKMANAGEMENT_GROUP + 19;
        case 87: // panel.accounts.TotalMaxPositionsQty
            return AccountDetailsPanel.KEY_RISKMANAGEMENT_GROUP + 20;
        // case 51: // panel.accounts.MaxDayVolume
        //    return AccountDetailsPanel.KEY_RISKMANAGEMENT_GROUP + 21;
        case 52: // panel.accounts.MaxOrdersPerDay
            return AccountDetailsPanel.KEY_RISKMANAGEMENT_GROUP + 25;
        case 53: // panel.accounts.MaxPositions
            return AccountDetailsPanel.KEY_RISKMANAGEMENT_GROUP + 30;
        case 54: // panel.accounts.MaxPendingOrders
            return AccountDetailsPanel.KEY_RISKMANAGEMENT_GROUP + 35;
        case 55: // panel.accounts.MaxOrderCapital
            return AccountDetailsPanel.KEY_RISKMANAGEMENT_GROUP + 40;
        case 56: // panel.accounts.MaximumLot
            return AccountDetailsPanel.KEY_RISKMANAGEMENT_GROUP + 45;
        case 57: // panel.accounts.MaxPositionQtyPerSymbol
            return AccountDetailsPanel.KEY_RISKMANAGEMENT_GROUP + 50;
        case 62: // panel.accounts.AllowOvernightTrading
            return AccountDetailsPanel.KEY_RISKMANAGEMENT_GROUP + 55;
        case 85: // panel.accounts.MaxPendingOrders
            return AccountDetailsPanel.KEY_RISKMANAGEMENT_GROUP + 60;

        // VolumeLimitFor.. :
        case 97:
            return AccountDetailsPanel.KEY_RISKMANAGEMENT_GROUP + 69; // EQUITIES
        case 98:
            return AccountDetailsPanel.KEY_RISKMANAGEMENT_GROUP + 74; // FUTURES
        case 99:
            return AccountDetailsPanel.KEY_RISKMANAGEMENT_GROUP + 77; // OPTIONS
        case 118:
            return AccountDetailsPanel.KEY_RISKMANAGEMENT_GROUP + 65; // ALL
        case 119:
            return AccountDetailsPanel.KEY_RISKMANAGEMENT_GROUP + 72; // FOREX
        case 120:
            return AccountDetailsPanel.KEY_RISKMANAGEMENT_GROUP + 70; // EQUITIES CFD
        case 121:
            return AccountDetailsPanel.KEY_RISKMANAGEMENT_GROUP + 73; // FORWARD
        case 122:
            return AccountDetailsPanel.KEY_RISKMANAGEMENT_GROUP + 75; // CFD FUTURES
        case 123:
            return AccountDetailsPanel.KEY_RISKMANAGEMENT_GROUP + 76; // INDICIES
        case 124:
            return AccountDetailsPanel.KEY_RISKMANAGEMENT_GROUP + 68; // CRYPTO
        case 125:
            return AccountDetailsPanel.KEY_RISKMANAGEMENT_GROUP + 79; // SPREADBET
        case 126:
            return AccountDetailsPanel.KEY_RISKMANAGEMENT_GROUP + 66; // BONDS
        case 127:
            return AccountDetailsPanel.KEY_RISKMANAGEMENT_GROUP + 71; // ETF
        case 128:
            return AccountDetailsPanel.KEY_RISKMANAGEMENT_GROUP + 80; // TBILL
        case 129:
            return AccountDetailsPanel.KEY_RISKMANAGEMENT_GROUP + 78; // SPOT
        case 130:
            return AccountDetailsPanel.KEY_RISKMANAGEMENT_GROUP + 67; // CORPORATE

        case 114:
            return AccountDetailsPanel.KEY_RISKMANAGEMENT_GROUP + 85;

            // panel.accountDetails.Groups.7.Plans #43336

        case 75: // panel.accounts.Commissions
            return AccountDetailsPanel.KEY_PLANS_GROUP + 5;
        case 76: // panel.accounts.Swap
            return AccountDetailsPanel.KEY_PLANS_GROUP + 10;
        case 77: // panel.accounts.Spreads
            return AccountDetailsPanel.KEY_PLANS_GROUP + 15;
        case 78: // panel.accounts.Murkups
            return AccountDetailsPanel.KEY_PLANS_GROUP + 20;
        case 79: // panel.accounts.MarketDataCharging
            return AccountDetailsPanel.KEY_PLANS_GROUP + 25;
        case 80: // panel.accounts.MarginAndRisks
            return AccountDetailsPanel.KEY_PLANS_GROUP + 30;
        case 81: // panel.accounts.MarginAndRisks
            return AccountDetailsPanel.KEY_PLANS_GROUP + 35;

            // panel.accountDetails.Groups.6.Info

        case 27: // panel.accounts.Owner
            return AccountDetailsPanel.KEY_INFO_GROUP + 5;
        case 25: // panel.accounts.UserID
            return AccountDetailsPanel.KEY_INFO_GROUP + 10;
        case 26: // panel.accounts.UserLogin
            return AccountDetailsPanel.KEY_INFO_GROUP + 15;
        case 38: // panel.accounts.UserGroup
            return AccountDetailsPanel.KEY_INFO_GROUP + 20;
        case 28: // panel.accounts.Email
            return AccountDetailsPanel.KEY_INFO_GROUP + 25;
        case 1:// panel.accounts.Login
            return AccountDetailsPanel.KEY_INFO_GROUP + 30;
        case 67: // panel.accounts.AccountType
            return AccountDetailsPanel.KEY_INFO_GROUP + 35;
        case 7:// panel.accounts.LockedBy
            return AccountDetailsPanel.KEY_INFO_GROUP + 40;
        case 63: // panel.accounts.AccountMode
            return AccountDetailsPanel.KEY_INFO_GROUP + 45;
        case 9:// panel.accounts.ClientType
            return AccountDetailsPanel.KEY_INFO_GROUP + 50;
        case 0:// panel.accounts.Status
            return AccountDetailsPanel.KEY_INFO_GROUP + 55;
        case 41: // panel.accounts.TelephoneNumber
            return AccountDetailsPanel.KEY_INFO_GROUP + 60;
        case 64: // panel.accounts.Asset
            return AccountDetailsPanel.KEY_INFO_GROUP + 65;
        case 65:// panel.accounts.AssetDescription
            return AccountDetailsPanel.KEY_INFO_GROUP + 70;
        case 69: // panel.accounts.AssetType
            return AccountDetailsPanel.KEY_INFO_GROUP + 75;
        case 100:
            return AccountDetailsPanel.KEY_INFO_GROUP + 80;

        case 8:// panel.accounts.Role
        case 18: // panel.accounts.stopTradingPercent
        case 22://  panel.accounts.MarginStopTrade
        case 47:// panel.accounts.IntradayRiskManagement
            return AccountDetailsPanel.KEY_INFO_GROUP + 85;

        default:
            return 1000;
        }
    };

    static GetSortIndex (assetBalanceItem, headerKey) {
        if (assetBalanceItem === null) { return -1; }

        const index = assetBalanceItem.FindColumnIndex(headerKey);

        if (index != -1) { return AccountDetailsPanel.GetSortIndexByIndex(index); } else {
            if (assetBalanceItem.Account?.AccountAdditionalInfo?.[headerKey]) { return assetBalanceItem.Account.AccountAdditionalInfo[headerKey].SortIndex; } else if (assetBalanceItem.AssetBalance?.AssetAdditionalInfo?.[headerKey]) { return assetBalanceItem.AssetBalance.AssetAdditionalInfo[headerKey].SortIndex; } else { return -1; }
        }
    };

    GetDynamicItems (VerticalPanelDataProvider, sett): any[] {
        return this.GetDynamicItemsStatic(VerticalPanelDataProvider, sett, this.accountDinamicProperties);
    };

    GetDynamicItemsStatic (VerticalPanelDataProvider, sett, accountDinamicProperties): any[] {
        const res = [];

        const accItems = VerticalPanelDataProvider.Source;
        if (accItems === null) { return null; }

        //
        // One account case
        //
        if (accItems.length === 1) {
            const account = accItems[0].Account;
            if (account === null) { return null; }

            for (let accountAdditionalInfoItem in account.AccountAdditionalInfo) {
                accountAdditionalInfoItem = account.AccountAdditionalInfo[accountAdditionalInfoItem];
                const item = AccountDetailsPanel.GetAdditinalInfoItem(accountAdditionalInfoItem, accountDinamicProperties);
                if (item !== null) { res.push(item); }
            }
            const accI_0_AB = accItems[0].AssetBalance;
            if (accI_0_AB?.AssetAdditionalInfo != null) {
                for (let assetAdditionalInfoItem in accI_0_AB.AssetAdditionalInfo) {
                    assetAdditionalInfoItem = accI_0_AB.AssetAdditionalInfo[assetAdditionalInfoItem];
                    const item = AccountDetailsPanel.GetAdditinalInfoItem(assetAdditionalInfoItem, accountDinamicProperties);
                    if (item != null) { res.push(item); }
                }
            }
        }
        //
        // All accounts or AccountGroup
        //
        else { return null; }

        res.sort(function (a, b) { return a.SortIndex - b.SortIndex; });

        return res;
    };

    static GetAdditinalInfoItem (accountAdditionalInfoItem, accountDinamicProperties): VerticalPanelDataProviderItem {
    // accountAdditionalInfoItem = account.AccountAdditionalInfo[accountAdditionalInfoItem];
        if (accountAdditionalInfoItem.Destination === AccountAdditionalInfoDestinationPanel.AllPanels ||
        accountAdditionalInfoItem.Destination === AccountAdditionalInfoDestinationPanel.AccountsDetails) {
            const item = new VerticalPanelDataProviderItem();
            item.Group = accountAdditionalInfoItem.GroupInfo;
            item.GroupIndex = accountAdditionalInfoItem.GroupIndex;
            item.SortIndex = accountAdditionalInfoItem.SortIndex; // sort index inside group
            item.LocalizationKey = accountAdditionalInfoItem.NameKey;
            item.Id = accountAdditionalInfoItem.APIKey;
            item.tooltipKey = accountAdditionalInfoItem.ToolTipKey;
            item.visible = accountAdditionalInfoItem.Visible;

            if (!isNullOrUndefined(accountDinamicProperties)) {
            // copy properties
                const baseSesionItem = accountDinamicProperties[accountAdditionalInfoItem.APIKey];
                if (!isNullOrUndefined(baseSesionItem)) { item.Apply(baseSesionItem); }

                accountDinamicProperties[accountAdditionalInfoItem.APIKey] = item;
            }
            return item;
        }
    };

    GetDataValue (key, VerticalPanelDataProvider, sett, rowId): string {
        try {
            const qt = super.getQuickTable();
            const accItems = VerticalPanelDataProvider.Source;// as List<AssetBalanceItem>;

            const id = parseInt(key);
            const useAssetBalanceItem = !isNaN(id);

            //
            // One account case
            //
            if (accItems !== null && accItems.length === 1) {
                const assetBalanceItem = accItems[0];
                const row = qt.rows[rowId];
                const hideRowThenUpdateQTScrollAndReturnEmptyString = (qt, row) => { // #114083
                    const needUpdateScrollElementsCount = row.visible;
                    row.visible = false;
                    if (needUpdateScrollElementsCount) { qt.updateScrollElementsCount(); }
                    return '';
                };

                if (useAssetBalanceItem) {
                    const VP = assetBalanceItem.getColumnData(id);
                    const FormattedValue = VP.FormattedValue;
                    const type = assetBalanceItem.GetDataType(id);

                    if (row && FormattedValue == '') { return hideRowThenUpdateQTScrollAndReturnEmptyString(qt, row); }

                    if (row && (type === QuickTableComparingType.Double || type === QuickTableComparingType.Int)) {
                        const v = QuickTableRow.ColorBySign(row.cells[1], row.table.columns[1], VP.Value);
                        row.cells[1].ForeColor = v.newForeColor;
                    }

                    return FormattedValue;
                } else if (assetBalanceItem.Account !== null && assetBalanceItem.Account.AccountAdditionalInfo !== null) {
                    let item = assetBalanceItem.Account.AccountAdditionalInfo[key];
                    if (item) { return item.GetFormatValue(assetBalanceItem.AssetBalance); }

                    if (assetBalanceItem.AssetBalance !== null && assetBalanceItem.AssetBalance.AssetAdditionalInfo !== null) {
                        item = assetBalanceItem.AssetBalance.AssetAdditionalInfo[key];
                        if (item) {
                            if (row?.item && !row.visible && !row.cells[1].value) {
                            // Not collapse empty fields
                                const gr_row = qt.groupStorageDict[row.item.GroupStr];
                                if (!gr_row.groupHeaderRow.collapsed) { row.visible = true; }
                            }

                            const type = assetBalanceItem.GetDataType(rowId);
                            if (row && (type === QuickTableComparingType.Double || type === QuickTableComparingType.Int)) {
                                const v = QuickTableRow.ColorBySign(row.cells[1], row.table.columns[1], item.Value);
                                row.cells[1].ForeColor = v.newForeColor; // #113408
                            }

                            return item.GetFormatValue(assetBalanceItem.AssetBalance);
                        }
                    }

                    if (row) { return hideRowThenUpdateQTScrollAndReturnEmptyString(qt, row); }
                }
            }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
        }
        return '';
    };

    GetItemsID (VerticalPanelDataProvider, sett) {
        const accItems = VerticalPanelDataProvider.Source;// as List<AssetBalanceItem>;

        if (accItems !== null && accItems.length === 1) {
            if (accItems[0].Account === null) { return null; }

            const acc = accItems[0].Account;
            // groups
            const idsColumn = [];
            const activeItemsIndexes = AccountDetailsPanel.GetActiveItemsIndexes(accItems[0]);
            for (let i = 0; i < activeItemsIndexes.length; i++) { idsColumn.push(activeItemsIndexes[i].toString()); }

            const account: Account = accItems[0].Account;
            if (account !== null && account.AccountAdditionalInfo !== null) {
                for (const key in account.AccountAdditionalInfo) {
                    const accountAdditionalInfoItem = account.AccountAdditionalInfo[key];
                    if (accountAdditionalInfoItem.Destination === AccountAdditionalInfoDestinationPanel.AllPanels ||
                    accountAdditionalInfoItem.Destination === AccountAdditionalInfoDestinationPanel.AccountsDetails) { idsColumn.push(accountAdditionalInfoItem.APIKey); }
                }
            }

            const asset = accItems[0].AssetBalance;
            if (asset !== null) {
                if (asset.AssetAdditionalInfo !== null) {
                    for (const key in asset.AssetAdditionalInfo) {
                        const AssetAdditionalInfo = asset.AssetAdditionalInfo[key];
                        if (AssetAdditionalInfo.Destination === AccountAdditionalInfoDestinationPanel.AllPanels ||
                        AssetAdditionalInfo.Destination === AccountAdditionalInfoDestinationPanel.AccountsDetails) { idsColumn.push(AssetAdditionalInfo.APIKey); }
                    }
                }
            }
            return idsColumn;
        } else {
            let assetBalanceItem = new AssetBalanceItem(null, sett);
            // groups
            const idsForAll = [];
            for (let i = 0; i < assetBalanceItem.ColumnCount(); i++) {
            // remove for "All" -> Leverage, Margin Warning Level, Stop Trading Level, Stop Out Level
                if (i === 16 || i === 17 || i === 18 || i === 19 || i === 37 || i === 39 || i === 40 || (i >= 47 && i <= 57) || i === 63 || i === 67 || i === 69 || i === 12 || i === 13 || i === 44) { continue; }

                const dataType = assetBalanceItem.GetDataType(i);
                if (dataType === QuickTableComparingType.DateTime || dataType === QuickTableComparingType.String) { continue; }
                idsForAll.push(i.toString());
            }
            assetBalanceItem.Dispose();
            assetBalanceItem = null;
            return idsForAll;
        }
    };

    /// <summary>
    /// на смену Source отписка и пр.
    /// </summary>
    DisposeSource (VerticalPanelDataProvider): void {
    // очищаем список айтемов
        let processedAccounts = VerticalPanelDataProvider.Source;// as List<AssetBalanceItem>;
        if (processedAccounts !== null) {
            for (let i = 0; i < processedAccounts.length; i++) { processedAccounts[i].Dispose(); }
            // processedAccounts.Clear();
            processedAccounts = [];
        }
    };

    static GetActiveItemsIndexes (assetItem, hideInfoBlock = false): any[] {
        const result = [];

        if (assetItem !== null) {
            for (let i = 0; i < assetItem.ColumnCount(null); i++) {
                if (assetItem.GetColumnParams(i).Hidden) { continue; }

                if (i >= 48 && i <= 57 && i !== 55 && i !== 50) {
                    const val = assetItem.getColumnValue(i);
                    if (val === 0 || val === -1) { continue; }
                }
                if ((i === 50 || i === 55 || i === 86 || i === 87 || i === 88) && assetItem.getColumnValue(i) === -1) { continue; }

                if (i === 68 && assetItem.Account.AccountType !== AccountType.MultiAsset)// #33209, #33495
                { continue; }

                if (i === 85 && assetItem.getColumnValue(i) === -1) { continue; }

                if (hideInfoBlock && (i === 1 || i >= 25 && i <= 28 || i === 41 || i >= 63 && i <= 65 || i === 67 || i === 69)) // info
                { continue; }

                if (i === 89 && assetItem.Account.MaxDailyLossLimit === -1) { continue; }

                if (i === 90 && assetItem.Account.MaxWeeklyLossLimit === -1) { continue; }

                if ((i === 94 || i === 95) && assetItem.Account.MaxUrealizedLossLimit === -1) { continue; }

                if ((i === 97 || i === 98 || i === 99) && assetItem.getColumnValue(i) === 0) // VolumeLimit
                { continue; }

                if (i === 100 && !assetItem.getColumnValue(i)) { continue; }

                if (i === 101 && assetItem.getColumnValue(i) === -1) { continue; }
                // #88247
                if (i === 104 && (assetItem.Account.WaivedMargin === null || assetItem.Account.AccountType === AccountType.MultiAsset)) { continue; }

                if (i === 108 && assetItem.Account.AccountType === AccountType.MultiAsset) { continue; }

                // if (i === 109 && assetItem.getColumnValue(i) < 0)   // commented out because of #108607 <- #108921
                //     continue;

                if (i === 114 && assetItem.Account.MaxRelativeDrawDownLevel !== null || assetItem.Account.MaxRelativeDrawDownLevel === -1) { continue; }

                if (i === 116 && assetItem.Account.LossLimitPerTrade === null) { continue; }

                result.push(i);
            }
        }

        return result;
    };

    SetColumnsColouringMode (table): void {
        const up = ThemeManager.CurrentTheme.TableValueUpForeColor;
        const down = ThemeManager.CurrentTheme.TableValueDownForeColor;
        const column = table.columns[1];
        column.ValueUpForeColor = up;
        column.ValueDownForeColor = down;
    };
}

VerticalApplicationPanelNew.extendWith(AccountDetailsPanel,
    {
        Name: 'AccountDetailsPanel',
        data: function () {
            return {
                zIndex: 1300,
                showHeader: false,
                showFooter: false,
                dockablePanel: false,
                resizable: false,
                width: 350,
                height: 505,
                closeBtnVisible: false,
                assetsVisible: false,
                assetsItems: [],
                selectedAsset: null,
                visible: false,
                style_addition_jsWin: 'accountDetailsShadow'
            };
        },
        template: TerceraBaseWindowTemplate,
        partials: {
            bodyPartial: AccountDetailsPanelTemplate
        }
    });
