// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DateTimeUtils } from '../../Utils/Time/DateTimeUtils';
import { Color } from '../../Commons/Graphics';
import { Resources } from '../properties/Resources';
import { ThemeManager } from '../../Controls/misc/ThemeManager';
import { EventType, EventSource, TradingEventType } from './EventConstants';
import { EventDescriptionItem } from './EventDescriptionItem';
import { type Account } from './Account';
import { type Instrument } from './Instrument';

export class Event {
    public Account: Account | null;
    public Action: any;
    public EventType: EventType;
    public Source: EventSource;
    public DateTime: any;
    public Id: number;
    public Instrument: Instrument | null;
    public Roundtrip: any;
    public DescriptionItems: EventDescriptionItem[] = [];
    public CustomEventId: any;
    public BusinessRejectCode: number;

    constructor (params) {
        this.Account = params.Account || '';
        this.Action = params.Action || '';
        this.EventType = params.EventType || EventType.None;
        this.Source = params.EventSource || EventSource.None;
        this.DateTime = params.DateTime || DateTimeUtils.DateTimeUtcNow();

        this.Id = Event.GeneratedId++;

        if (this.EventType === EventType.Comment) {
            this.CustomEventId = DateTimeUtils.DateTimeUtcNow().getTime();
        }

        this.Instrument = params.Instrument || null;
        this.Roundtrip = params.Roundtrip || 0;
        this.BusinessRejectCode = params.BusinessRejectCode || -1;

        this.ParseEventDescriptionItems(params.Description);
    }

    public static GeneratedId = 0;

    public ParseEventDescriptionItems (msg): void {
        if (!msg) return;

        this.DescriptionItems = [];

        if (this.EventType === EventType.Comment) {
            this.DescriptionItems.push(new EventDescriptionItem(msg, ''));
            return;
        }

        if (this.EventType === EventType.Trading && this.Source === EventSource.Refused) // Alert Event Msg Parsing
        {
            this.DescriptionItems.push(new EventDescriptionItem('Refused', msg.split('Error:')[1].split(';')[0]));
            return;
        }

        const ins = this.Instrument;
        const hideRoute = ins ? ins.isHideRouteMode : false;

        const splits = msg.split(';');
        const len = splits.length;
        for (let i = 0; i < len; i++) {
            const split = splits[i];
            if (!split) continue;

            const item = new EventDescriptionItem();

            const headerValueArr = split.split(':');

            item.Header = headerValueArr[0];
            item.Value = headerValueArr[1] || '';
            for (let j = 2; j < headerValueArr.length; j++) { item.Value += ':' + headerValueArr[j]; }

            if (item.Header === 'Route' && hideRoute) { continue; }

            // Тики в строку
            switch (item.Header) {
            case 'Time':
            case 'DateTime':
            case 'SettleDate':
                const millis = parseFloat(item.Value);
                item.Value = new Date(isNaN(millis) ? item.Value : millis);
                break;
            }

            this.DescriptionItems.push(item);
        }
    }

    public EventTypeStr (): string {
        switch (this.EventType) {
        case EventType.None:
            return Resources.getResource('panel.EventLog.eventType.none');
        case EventType.Info:
            return Resources.getResource('panel.EventLog.eventType.info');
        case EventType.Exception:
            return Resources.getResource('panel.EventLog.eventType.exception');
        case EventType.Trading:
            return Resources.getResource('panel.EventLog.eventType.trading');
        case EventType.System:
            return Resources.getResource('panel.EventLog.eventType.system');
        case EventType.Comment:
            return Resources.getResource('panel.EventLog.eventType.comment');
        case EventType.Updater:
            return Resources.getResource('panel.EventLog.eventType.updater');
        case EventType.Alerts:
            return Resources.getResource('panel.EventLog.eventType.alerts');
        default:
            return null;
        }
    }

    public GetFormattedDescription (): string {
        let resultStr = '';

        const descriptionItems = this.DescriptionItems;
        const len = descriptionItems.length;
        for (let i = 0; i < len; i++) {
            const descriptionItem = descriptionItems[i];

            const localizedHeader = descriptionItem.Header;
            const localizedValue =
            descriptionItem.Value
                ? descriptionItem.Value.toString()
                : '';

            if (localizedValue) {
                resultStr += localizedHeader + ':' + localizedValue + '; ';
            } else {
                resultStr += localizedHeader;
            }
        }

        return resultStr;
    }

    public GetColorRow (eventColoring): any {
        let fore = Color.Empty;
        let back = Color.Empty;

        const tm = ThemeManager.CurrentTheme;

        switch (this.GetTradingEventType()) {
        case TradingEventType.MarginCall:
            back = eventColoring ? eventColoring.MarginCallBackColor : tm.EventLog_MarginCallBackColor;
            fore = eventColoring ? eventColoring.MarginCallForeColor : tm.EventLog_MarginCallForeColor;
            break;

        case TradingEventType.Reject:
            back = eventColoring ? eventColoring.RefusedBackColor : tm.EventLog_RefusedBackColor;
            fore = eventColoring ? eventColoring.RefusedForeColor : tm.EventLog_RefusedForeColor;
            break;

        case TradingEventType.Fill:
            back = eventColoring ? eventColoring.FilledBackColor : tm.EventLog_FilledBackColor;
            fore = eventColoring ? eventColoring.FilledForeColor : tm.EventLog_FilledForeColor;
            break;

        case TradingEventType.Create:
            back = eventColoring ? eventColoring.CreateBackColor : tm.EventLog_CreateBackColor;
            fore = eventColoring ? eventColoring.CreateForeColor : tm.EventLog_CreateForeColor;
            break;

        case TradingEventType.Modify:
            back = eventColoring ? eventColoring.ModifyBackColor : tm.EventLog_ModifyBackColor;
            fore = eventColoring ? eventColoring.ModifyForeColor : tm.EventLog_ModifyForeColor;
            break;

        case TradingEventType.Remove:
            back = eventColoring ? eventColoring.RemoveBackColor : tm.EventLog_RemoveBackColor;
            fore = eventColoring ? eventColoring.RemoveForeColor : tm.EventLog_RemoveForeColor;
            break;

        case TradingEventType.Request:
            back = eventColoring ? eventColoring.RequestBackColor : tm.EventLog_RequestBackColor;
            fore = eventColoring ? eventColoring.RequestForeColor : tm.EventLog_RequestForeColor;
            break;
        }

        return { ForeColor: fore, BackColor: back };
    }

    public GetTradingEventType (): TradingEventType {
        const action = this.Action.toLowerCase();

        if (this.Source === EventSource.MarginCall) {
            return TradingEventType.MarginCall;
        } else if (action.indexOf('request') !== -1) {
            return TradingEventType.Request;
        } else if (
            this.Source === EventSource.Refused ||
        action.indexOf('refuse') !== -1 ||
        action.indexOf('reject') !== -1 ||
        action.indexOf('trading disabled') !== -1) {
            return TradingEventType.Reject;
        } else if (action.indexOf('fill') !== -1) {
            return TradingEventType.Fill;
        } else if (action.indexOf('create') !== -1) {
            return TradingEventType.Create;
        } else if (action.indexOf('modif') !== -1) // modify/modified
        {
            return TradingEventType.Modify;
        } else if (action.indexOf('remove') !== -1) {
            return TradingEventType.Remove;
        }
        // все остальные трейдинговые - это реквесты, все вопросы по раскраске к андрею
        else if (this.EventType === EventType.Trading) {
            return TradingEventType.Request;
        }

        return TradingEventType.NotTrading;
    }
}
