// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CreateAlertInstrumentLookupTemplate } from "../../../templates.js";
import { ContainerControl } from "../../elements/ContainerControl.js";
import { Resources } from "../../../Commons/properties/Resources.ts";

export let CreateAlertInstrumentLookup = ContainerControl.extend({
    template: CreateAlertInstrumentLookupTemplate,
    data: function ()
    {
        return {
            label: '',
            isSymbolInfoOpened: false,
            instrument: null,
            account: null,
            width: 198
        }
    }
})

CreateAlertInstrumentLookup.prototype.getType = function () { return 'CreateAlertInstrumentLookup' };

CreateAlertInstrumentLookup.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.call(this)
    Resources.onLocaleChange.Subscribe(this.localize, this)
    this.localize()
}

CreateAlertInstrumentLookup.prototype.dispose = function ()
{
    Resources.onLocaleChange.UnSubscribe(this.localize, this)

    ContainerControl.prototype.dispose.call(this)
}

CreateAlertInstrumentLookup.prototype.localize = function ()
{
    this.set('label', Resources.getResource('screen.Alerts.Label.Symbol'))
}