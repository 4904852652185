// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type Bar } from '../charting_library';
export class TvBar implements Bar {
    time: number = 0;
    volume: number = 0;
    open: number = 0;
    close: number = 0;
    high: number = 0;
    low: number = 0;

    date = new Date(); // for help with debugging
}
