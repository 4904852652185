// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DataCache } from "../Commons/DataCache.ts";
import { OrderTypeBase } from "../Commons/cache/OrderParams/order-type/OrderTypeBase.ts";
import { OrderTypeBaseParameters } from "../Commons/cache/OrderParams/order-type/OrderTypeBaseParameters.ts";
import { ApplicationPanelNew } from "./panels/ApplicationPanelNew.js";

export let OrderEntryBase = ApplicationPanelNew.extend({});
export let NewOrderParameters = function () { };

OrderEntryBase.prototype.PlaceOrder = function ()
{
    let placeOrderData = new NewOrderParameters();
    placeOrderData.Account = this.GetAccount();
    placeOrderData.Instrument = this.GetInstrument();
    placeOrderData.Side = this.GetSide();
    placeOrderData.TimeInForce = this.GetTimeInForce();
    placeOrderData.ExpirationTime = this.GetExpirationTime();
    placeOrderData.OrderType = this.GetOrderType();
    placeOrderData.Price = this.GetPrice();
    placeOrderData.StopPrice = this.GetStopPrice();
    placeOrderData.Amount = this.GetQuantity();
    placeOrderData.SlTpHolder = this.GetSlTpHolder();
    DataCache.FOrderExecutor.SubmitOrder(placeOrderData);
};

//#region    collect Data methods
OrderEntryBase.prototype.GetAccount = function ()
{
    return null;
};
OrderEntryBase.prototype.GetInstrument = function ()
{
    return null;
};
OrderEntryBase.prototype.GetSide = function ()
{
    return null;
};
OrderEntryBase.prototype.GetTimeInForce = function ()
{
    return null;
};
OrderEntryBase.prototype.GetExpirationTime = function ()
{
    return null;
};
OrderEntryBase.prototype.GetOrderType = function ()
{
    return null;
};
OrderEntryBase.prototype.GetPrice = function ()
{
    return null;
};
OrderEntryBase.prototype.GetStopPrice = function ()
{
    return null;
};
OrderEntryBase.prototype.GetQuantity = function ()
{
    return null;
};
OrderEntryBase.prototype.GetSlTpHolder = function ()
{
    return null;
};

//#endregion

OrderEntryBase.PopulateOrderTypes = function (account, instrument, forbiddenOrderTypes, lastSelectedOrderType, mamMode)
{
    mamMode = !!mamMode;
    let allowedOrderTypes = new Array();

    if (DataCache.OrderParameterContainer === null)
        return allowedOrderTypes;

    let orderTypes = DataCache.OrderParameterContainer.OrderTypes;
    let defOrderType = null;

    let supportedParamObj = new OrderTypeBaseParameters(instrument, account);

    for (let key in orderTypes)
    {
        let orderType = orderTypes[key]
        let id = orderType.id()

        if (forbiddenOrderTypes && forbiddenOrderTypes[id])
            continue

        if (!orderType.IsSupported(supportedParamObj))
            continue

        allowedOrderTypes.push(id)
    }

    return allowedOrderTypes;
}

OrderEntryBase.PopulateTifs = function (orderType, instrument)
{
    let tifs = new Array();
    if (DataCache.OrderParameterContainer === null)
        return tifs;

    let supportedParamObj = new OrderTypeBaseParameters(instrument);

    let currentOrderType = DataCache.OrderParameterContainer.GetOrderType(orderType)
    if (!currentOrderType || !currentOrderType.IsSupported(supportedParamObj))
        return tifs

    let route = instrument.DataCache.getRouteByName(instrument.Route)
    let allowedTifs = route.DictOrderTypeTifs[orderType]
    if (allowedTifs)
        tifs = allowedTifs

    return tifs
}
