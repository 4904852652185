// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { ReportGroupResponseMessage } from '../Messages/ReportGroupResponseMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class ReportGroupResponseMessageProcessor extends MessageProcessorBase {
    public MsgArr: ReportGroupResponseMessage[] = [];

    public override decode (fieldSet: PFixFieldSet): ReportGroupResponseMessage[] {
        const msg = new ReportGroupResponseMessage(fieldSet);

        this.MsgArr.push(msg);

        const responseEnd = fieldSet.GetField(FieldsFactory.FIELD_RESPONCE_END);
        if (responseEnd) {
            msg.RelatedMessages = this.MsgArr;

            this.MsgArr = [];
        }

        return responseEnd ? [msg] : [];
    }
}
