// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

// Описание редактировния
export class QuickTableEditingInfo {
    // for table Combobox if was something changed turn to true.
    public wasChanged = false;
    // не рисуем картинку на кнопке, а лишь картинки и БЕЗ текста
    public OnlyImageForButton = false;
    // не рисуем картинку на кнопке, а лишь картинки и ЕСЛИ ТЕКС НЕ ПУСТ
    public OnlyImage_IfTextNotEmpty_ForButton = false;
    // отображаем чекбокс как радио баттон
    public DrawCheckBoxAsRadioButton = false;
    // Тип контрола
    public ControlType: number;
    // Набор значений для комбобокса
    public Values = null;
    //
    public ValuesObject = null;
    // Параметры нумерика
    public Min = 0;
    public Max = 10000000;
    public Inc = 1;
    public DecimalPlaces = 0;
    public IntervalsListForNumeric = null;
    //
    public IsDrawControl = true;
    //
    public EditingCell = null;
    public Table = null;
    // +++yura - для UsersLookup, SymbolsLookup
    public AddAll = true;
    // +++yura - MAM для UsersLookup, SymbolsLookup
    public AddMAM = true;
    // +++yura - флаг добавления Portfolio
    public AddPortfolio = true;
    //
    public AddForwards = true;
    public AddOptions = true;
    // Автозаполнение для текстовых полей
    public AutoCompleteSource = null;
    // +++denis - флаг добавления кастом-инструментов
    public allowInstrumentsHistories = false;
    // image ячейки
    public ImageCell = null;
    // image hover ячейки
    public hoverImageCell = null;
    // image для Remove Buttons
    public ImageForRemoveButtonCells = null;
    // кастомный метод для получения данных
    public GetDataHandler = null;
    public IsEditable: boolean = true;

    constructor (controlType) {
        this.ControlType = controlType;
    }
}

export class QuickTableColumnType {
    // #region Consts
    public static readonly COL_DEFAULT = 0;
    /// <summary>
    /// Для ячеек этой колонки применять раскраску увеличения и уменьшения значения
    /// плюс числовую сортировку
    /// </summary>
    public static readonly COL_UPDOWN_NUMERIC = 1;
    /// <summary>
    /// Для ячеек этой колонки применять раскраску значений больше/меньше/равно нулю
    /// плюс числовую сортировку
    /// </summary>
    public static readonly COL_MORELESS_NUMERIC = 2;
    /// <summary>
    /// Для ячеек этой колонки применять просто числовую сортировку
    /// </summary>
    public static readonly COL_SIMPLE_NUMERIC = 3;
    /// <summary>
    /// Для ячеек этой колонки применять раскраску ON/OFF и обычную сортировку
    /// </summary>
    public static readonly COL_ONOFF = 5;
    /// <summary>
    /// Для ячеек этой колонки применять сортировку по дате
    /// </summary>
    public static readonly COL_DATE_SORT = 10;

    /// <summary>
    /// Для ячеек, которые содержат название инструмента будет применена
    /// сортировка по ID инструмента
    /// </summary>
    public static readonly COL_INSTR_SORT = 11;

    /// <summary>
    /// Предполагается, что в этой ячейке время в формате чч:мм:сс.
    /// </summary>
    public static readonly COL_TIME_SORT = 12;

    public static readonly COL_RED_NUMERIC = 100;

    public static readonly COL_GREEN_NUMERIC = 101;

    public static readonly COL_ARROWS = 102;

    public static readonly COL_BOOLEAN = 120;

    public static readonly COL_BOOLEAN_INVERSE = 121;

    public static readonly SYMBOL = 122;
    public static readonly QUANTITY = 123;

// #endregion
}
