// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { HistoryType } from '../../Utils/History/HistoryType';
import { MathUtils } from '../../Utils/MathUtils';
import { ErrorInformationStorage } from '../ErrorInformationStorage';
import { type Instrument } from './Instrument';
import { SpreadItem } from './SpreadItem';

export class SpreadPlan {
    public Id: any;
    public Name: any;
    public itemsByType: any = {};// new Dictionary<int, RevenueSpreadItem>();
    public itemsBySymbol: any = {};// new Dictionary<string, RevenueSpreadItem>();

    /// <summary>
    /// List of spread settings for each instrument
    /// </summary>

    public items: any = {};// new Dictionary<string, SpreadItem>();

    public InitBy (message, dc): void {
        this.Id = message.Id.toString();
        this.Name = message.Name;
        const listOfTyped = [];// new List<RevenueSpreadItem>();
        const copyByType = {};// new Dictionary<int, RevenueSpreadItem>();
        const copyBySymbol = {};// new Dictionary<string, RevenueSpreadItem>();

        let len = message.SpreadItems.length;

        for (let i = 0; i < len; i++) {
            const si = message.SpreadItems[i];
            if (!si.InstrumentId) {
                listOfTyped.push(si);
            } else {
                copyBySymbol[si.InstrumentId] = si;
            }
        }

        // TODO IMPLEMENT
        // listOfTyped.Sort(new TypeHierarchyComparer<RevenueSpreadItem>(dc));

        len = listOfTyped.length;
        for (let i = 0; i < len; i++) {
            const si = listOfTyped[i];
            if (si.InstrumentTypeId == -1) { copyByType[-1] = si; } else {
                const list = [];
                dc.FindInstrumentTypes(si.InstrumentTypeId, list);
                const len_j = list.length;
                for (let j = 0; j < len_j; j++) {
                    copyByType[list[j]] = si;
                }
            }
        }

        this.itemsBySymbol = copyBySymbol;
        this.itemsByType = copyByType;

        this.items = new Object();
        this.ApplyForInstruments(dc.getCachedInstruments());
    }

    public ApplyForInstruments (instruments: Instrument[]): void {
        const len = instruments.length;
        for (let i = 0; i < len; i++) {
            this.ApplyForInstrument(instruments[i]);
        }
    }

    public ApplyForInstrument (instrument: Instrument): void {
        try {
            if (this.itemsBySymbol != null) {
                let item = this.itemsBySymbol[instrument.Id];
                if (!item) // by symbol
                {
                    if (this.itemsByType != null)// by type
                    {
                        item = this.itemsByType[instrument.TypeId];
                        if (!item) {
                            item = this.itemsByType[-1];
                            if (!item) // default
                            {
                                return;
                            }
                        }
                    }
                }

                const si = SpreadPlan.CreateSpreadItem(item, instrument);
                this.items[instrument.GetInteriorID()] = si;
            }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            // Utils.log(ex);
        }
    }

    public GetItem (name): SpreadItem | null {
        return this.items[name] || null;
    }

    public IsNotEmptyPlan (name): boolean {
        // if (Object.keys(this.items).length == 0)
        //    return false;

        const item = this.GetItem(name);
        if (item == null) {
            return false;
        }

        if (item.SpreadMode != SpreadPlan.NOT_FIXED_SPREADMODE || item.BidShift != 0 || item.AskShift != 0) {
            return true;
        } else {
            return false;
        }
    }

    /// <summary>
    /// Расчёт цен
    /// </summary>
    public CalcBid (bid, ask, ins, round = true): any {
        // No spread rules for this instrument?
        const spreadItem = this.items[ins.GetInteriorID()];
        if (!spreadItem) {
            return bid;
        }

        return spreadItem.CalcBid(bid, ask, ins, round);
    }

    public CalcAsk (bid, ask, ins, round = true): any {
        // No spread rules for this instrument?
        const spreadItem = this.items[ins.GetInteriorID()];
        if (!spreadItem) {
            return ask;
        }

        return spreadItem.CalcAsk(bid, ask, ins, round);
    }

    public static CreateSpreadItem (item, instrument: Instrument | null): SpreadItem {
        const si = new SpreadItem(instrument);
        si.AskShift = item.AskShift;
        si.BidShift = item.BidShift;
        si.Spread = item.Spread;
        si.SpreadCoef = SpreadPlan.getSpreadCoef(instrument, item.SpreadMeasure);
        si.SpreadMode = (item.SpreadMode == SpreadPlan.NOT_FIXED_SPREADMODE &&
            (item.SpreadMeasure == SpreadMeasureMode.SPREAD_IN_BASIC_POINTS || item.SpreadMeasure == SpreadMeasureMode.SPREAD_IN_BASIC_POINTS_POST_EXECUTION))
            ? SpreadPlan.DYNAMIC_SPREADMODE
            : item.SpreadMode;
        si.SpreadMeasure = item.SpreadMeasure;
        return si;
    }

    public static getSpreadCoef (inst: Instrument, spreadMeasure): any {
        switch (spreadMeasure) {
        case SpreadMeasureMode.SPREAD_IN_PIPS:
            return inst.PointSize;

        case SpreadMeasureMode.SPREAD_IN_BASIC_POINTS:
        case SpreadMeasureMode.SPREAD_IN_BASIC_POINTS_POST_EXECUTION:
            return 0.0001;

        case SpreadMeasureMode.SPREAD_IN_CURRENCY:
        case SpreadMeasureMode.SPREAD_IN_CURRENCY_POST_EXECUTION:
        default:
            return 1;
        }
    }

    public static NOT_FIXED_SPREADMODE = 0;
    public static ASK_SPREADMODE = 1;
    public static BID_SPREADMODE = 2;
    public static BID_ASK_SPREADMODE = 3;
    public static LIMEN_SPREADMODE = 4;
    public static DYNAMIC_SPREADMODE = 1000; // вымышленный тип под динамический спред в базовых пунктах.

    public IsNeedLoadHistoryForSpread (instrDescr, historyType): boolean {
        // дополнительные проверки, нужно ли закачивать историю
        const item = this.GetItem(instrDescr);

        if (item.SpreadMode == SpreadPlan.ASK_SPREADMODE && historyType == HistoryType.QUOTE_ASK) {
            return false;
        }

        if (item.SpreadMode == SpreadPlan.BID_SPREADMODE && historyType == HistoryType.QUOTE_LEVEL1) {
            return false;
        }

        if (item.SpreadMode == SpreadPlan.DYNAMIC_SPREADMODE) {
            return false;
        }

        if (item.SpreadMode == SpreadPlan.NOT_FIXED_SPREADMODE) {
            return false;
        }

        return true;
    }
}

export enum SpreadMeasureMode {
    SPREAD_IN_PIPS = 0,
    SPREAD_IN_CURRENCY = 1,
    SPREAD_IN_BASIC_POINTS = 2,
    SPREAD_IN_BASIC_POINTS_POST_EXECUTION = 3,
    SPREAD_IN_CURRENCY_POST_EXECUTION = 4
}
