// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CrossRateCalculationType } from '../../Utils/CrossRate/CrossRateCalculationType';
import { CustomEvent } from '../../Utils/CustomEvents';

export class CrossRateCache {
    // Здесь храним цены
    public CrossRateDict: any = {};
    // Теперь храним цены по разным типам котировок
    public CrossRateAskDict: any = {};
    public CrossRateBidDict: any = {};

    public ReverseСrossDict: any = {};

    public CrossRatesCountMap: any = {};

    public OnUpdate = new CustomEvent();
    public dataCache: any;

    constructor (dataCache) {
        this.dataCache = dataCache;
    }

    // #region Constants

    public static NO_CROSS_PRICE = 1;
    public static CROSS_DELIMITER = '/';

    // #endregion Constants

    public Update (msg): void {
        const crossRateDict = this.GetCrossRateStorage(msg.CrossRateType);
        const reverseCrossDict = this.ReverseСrossDict;

        const crossRateDataArr = msg.CrossRateData;
        const len = crossRateDataArr.length;
        for (let i = 0; i < len; i++) {
            const crd = crossRateDataArr[i];

            // если ВДРУГ сервер шлёт лажу, не применяем её.
            if (!crd.Price) continue;

            const crossString = crd.Exp1 + CrossRateCache.CROSS_DELIMITER + crd.Exp2;

            crossRateDict[crossString] = crd.Price;
            reverseCrossDict[crossString] = crd.IsReverse;
        }

        const element = this.CrossRatesCountMap[msg.CrossRateType];
        if (!element || element < len) {
            this.CrossRatesCountMap[msg.CrossRateType] = len;
        }

        this.OnUpdate.Raise();
    }

    public Clear (): void {
        this.CrossRateDict = {};
        this.CrossRateAskDict = {};
        this.CrossRateBidDict = {};

        this.ReverseСrossDict = {};

        this.CrossRatesCountMap = {};

        this.OnUpdate.Raise();
    }

    public GetCrossRateStorage (crossRateCalcType): any {
        switch (crossRateCalcType) {
        case CrossRateCalculationType.ASK:
            return this.CrossRateAskDict;
        case CrossRateCalculationType.BID:
            return this.CrossRateBidDict;
        case CrossRateCalculationType.BID_ASK_2:
        default:
            return this.CrossRateDict;
        }
    }

    public static FillCrossRateGraph (w, r): void {
    // TODO. Workaround.
        if (!w.length) return;

        // Алгоритм Флойда — Уоршелла
        // (кубическая сложность, но можно улучшить)
        // немного адаптирован, так как у нас расстояние между вершинами определяется не суммированием, а умножением
        const len = w[0].length;
        for (let k = 0; k < len; k++) {
            for (let i = 0; i < len; i++) {
                for (let j = 0; j < len; j++) {
                    if (w[i][j] === 0 && w[i][k] !== 0 && w[k][j] !== 0) {
                        w[i][j] = w[i][k] * w[k][j];
                        r[i][j] = r[i][k] || r[k][j];
                    }
                }
            }
        }
    }

    public GetCrossPriceExp1Exp2 (exp1, exp2, crosstype = CrossRateCalculationType.BID_ASK_2, correctTypeByReverse = true): any {
        const crossString = exp1 + CrossRateCache.CROSS_DELIMITER + exp2;

        if (correctTypeByReverse) {
            crosstype = this.CorrectCalcTypeByReverse(crosstype, crossString);
        }

        const crossRatesDictionary = this.GetNotEmptyCrossRatesStorage(crosstype);

        const res = crossRatesDictionary[crossString];
        if (res) {
            return res;
        } else {
            return CrossRateCache.NO_CROSS_PRICE;
        }
    }

    public GetCrossPriceExp1 (exp1, crosstype = CrossRateCalculationType.BID_ASK_2): any {
        const crossString = exp1 + CrossRateCache.CROSS_DELIMITER + this.dataCache.baseCurrency;

        crosstype = this.CorrectCalcTypeByReverse(crosstype, crossString);

        const crossRatesDictionary = this.GetNotEmptyCrossRatesStorage(crosstype);

        const res = crossRatesDictionary[crossString];
        if (res) {
            return res;
        } else {
            return CrossRateCache.NO_CROSS_PRICE;
        }
    }

    public GetCrossPriceIns (ins, crosstype = CrossRateCalculationType.BID_ASK_2): any {
        const crossString = ins.Exp2 + CrossRateCache.CROSS_DELIMITER + this.dataCache.baseCurrency;

        crosstype = this.CorrectCalcTypeByReverse(crosstype, crossString);

        const crossRatesDictionary = this.GetNotEmptyCrossRatesStorage(crosstype);

        if (ins == null) {
            return CrossRateCache.NO_CROSS_PRICE;
        }

        const res = crossRatesDictionary[crossString];
        if (res) {
            return res;
        } else {
            return CrossRateCache.NO_CROSS_PRICE;
        }
    }

    public GetNotEmptyCrossRatesStorage (crosstype): any {
        const crossRatesDictionary = this.GetCrossRateStorage(crosstype);
        const crossRatesDictionaryCount = this.CrossRatesCountMap[crosstype];
        const isCrossRatesDictWithValues = crossRatesDictionaryCount && crossRatesDictionaryCount > 0;
        return crossRatesDictionary != null && isCrossRatesDictWithValues ? crossRatesDictionary : this.CrossRateDict;
    }

    public CorrectCalcTypeByReverse (crosstype, crossString): any {
        // ReverseСross может быть null на старой базе, т.к. CrossRatesCache не создаеться а десериализуется

        if (this.ReverseСrossDict !== null) {
            const isRC = this.ReverseСrossDict[crossString];
            if (isRC) {
                if (crosstype == CrossRateCalculationType.ASK) {
                    crosstype = CrossRateCalculationType.BID;
                } else if (crosstype == CrossRateCalculationType.BID) {
                    crosstype = CrossRateCalculationType.ASK;
                }
            }
        }
        return crosstype;
    }
}
