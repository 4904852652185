// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts";
import { HistoryType } from "../../Utils/History/HistoryType.ts";
import { Pen } from "../../Commons/Graphics.ts";
import { TerceraChartDrawingType } from "../../Chart/Utils/ChartConstants.ts";
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";
import { TerceraAddOverlayScreenTemplate } from "../../templates.js";
import { TerceraButton } from "../elements/TerceraButton.ts";
import { TerceraMessageBox } from "./TerceraMessageBox.js";
import { TerceraWindowBase } from "./TerceraWindowBase.js";
import { CustomEvent } from "../../Utils/CustomEvents.ts";
import { ThemeManager } from "../misc/ThemeManager.ts";
import { Periods } from "../../Utils/History/TFInfo.ts";
import { PairColor } from "../../Commons/DynProperty.ts";
import { DataCache } from "../../Commons/DataCache.ts";
import { addOverlayScreenHandler } from "../../Utils/AppHandlers.js";

export let TerceraAddOverlayScreen = TerceraWindowBase.extend({
    data: function ()
    {
        return {
            width: 322,
            resizable: false,
            // For using in templates.
            Resources: Resources,
            showFooter: false
        };
    },
    partials: { bodyPartial: TerceraAddOverlayScreenTemplate },
    callback: null
});

let instance = null;

TerceraAddOverlayScreen.prototype.oninit = function ()
{
    TerceraWindowBase.prototype.oninit.apply(this);

    this.set('header', Resources.getResource('OverlayScreen.name'));
    this.on('okEvent', this.okClick);
    this.on('cancelEvent', this.close);
    //this.Icon = Resources.getIcon("resources.Panels.chartPanel.ico");
};

TerceraAddOverlayScreen.prototype.oncomplete = function ()
{
    TerceraWindowBase.prototype.oncomplete.apply(this);

    this.Controls.barsBodyPicker.set('pairColor', new PairColor(
        ThemeManager.CurrentTheme.Chart_BarsUpColor,
        ThemeManager.CurrentTheme.Chart_BarsDownColor));

    this.Controls.barsBorderPicker.set('pairColor', new PairColor(
        ThemeManager.CurrentTheme.Chart_BarsUpBorderColor,
        ThemeManager.CurrentTheme.Chart_BarsDownBorderColor));

    this.Controls.wickPicker.set('pairColor', new PairColor(
        ThemeManager.CurrentTheme.Chart_WickUpBorderColor,
        ThemeManager.CurrentTheme.Chart_WickDownBorderColor));

    this.Controls.dojiPicker.set('coloringButtonColor',
        ThemeManager.CurrentTheme.Chart_BarsHiLowColor);

    this.Controls.histogramPicker.set('coloringButtonColor',
        ThemeManager.CurrentTheme.Chart_HistogramLineColor);

    this.Controls.linePicker.set('coloringButtonColor',
        ThemeManager.CurrentTheme.Chart_SolidPriceColor);

    this.Controls.solidPricePicker.set('coloringButtonColor',
        ThemeManager.CurrentTheme.Chart_SolidPriceColor);

    this.populateSymbols();
    this.Controls.insLookup.onChangeValue.Subscribe(this.onInstrumentChanged, this);

    var styleCombo = this.Controls.chartStyleComboBox;
    let arr = [];
    if (!Resources.isHidden('chart.tool.line'))
        arr.push({ text: Resources.getResource("chart.tool.line"), value: TerceraChartDrawingType.Line });
    //---------------------------------------------------------------------------------------------      
    if (!Resources.isHidden('chart.tool.bar'))
        arr.push({ text: Resources.getResource("chart.tool.bar"), value: TerceraChartDrawingType.Bar });
    //---------------------------------------------------------------------------------------------       
    if (!Resources.isHidden('chart.tool.candle'))
        arr.push({ text: Resources.getResource("chart.tool.candle"), value: TerceraChartDrawingType.Candle });
    //---------------------------------------------------------------------------------------------  
    if (!Resources.isHidden('chart.tool.dot'))
        arr.push({ text: Resources.getResource("chart.tool.dot"), value: TerceraChartDrawingType.Dot });
    //---------------------------------------------------------------------------------------------  
    if (!Resources.isHidden('chart.tool.dotline'))
        arr.push({ text: Resources.getResource("chart.tool.dotline"), value: TerceraChartDrawingType.DotLine });
    //---------------------------------------------------------------------------------------------  
    if (!Resources.isHidden('chart.tool.forest'))
        arr.push({ text: Resources.getResource("chart.tool.forest"), value: TerceraChartDrawingType.Forest });
    //---------------------------------------------------------------------------------------------  
    if (!Resources.isHidden('chart.tool.solid'))
        arr.push({ text: Resources.getResource("chart.tool.solid"), value: TerceraChartDrawingType.Solid });

    styleCombo.set('items', arr);
    /*{ text: Resources.getResource("chart.tool.line"), value: TerceraChartDrawingType.Line },
    { text: Resources.getResource("chart.tool.bar"), value: TerceraChartDrawingType.Bar },
    { text: Resources.getResource("chart.tool.candle"), value: TerceraChartDrawingType.Candle },
    { text: Resources.getResource("chart.tool.dot"), value: TerceraChartDrawingType.Dot },
    { text: Resources.getResource("chart.tool.dotline"), value: TerceraChartDrawingType.DotLine },
    { text: Resources.getResource("chart.tool.forest"), value: TerceraChartDrawingType.Forest },
    { text: Resources.getResource("chart.tool.solid"), value: TerceraChartDrawingType.Solid }*/
    styleCombo.setItembyValue(TerceraChartDrawingType.Line);

    var priceScalingCombo = this.Controls.priceScalingComboBox;
    priceScalingCombo.set('items', [
        { text: Resources.getResource("OverlayScreen.Main scale"), value: false },
        { text: Resources.getResource("OverlayScreen.Separate scale"), value: true },
    ]);
    priceScalingCombo.setItembyValue(true);
    this.center();
    this.localize();
};

TerceraAddOverlayScreen.prototype.localize = function ()
{
    this.set({
        okButtonText: Resources.getResource('OverlayScreen.okButton'),
        cancelButtonText: Resources.getResource('OverlayScreen.cancelButton')
    });
};

TerceraAddOverlayScreen.prototype.dispose = function ()
{
    TerceraWindowBase.prototype.dispose.apply(this);
    var insLookup = this.Controls.insLookup;
    if (insLookup) insLookup.onChangeValue.UnSubscribe(this.onInstrumentChanged, this);
    this.callback = null;
};

TerceraAddOverlayScreen.prototype.okClick = function ()
{

    if (this.callback)
    {
        this.callback({
            instrument: this.Controls.insLookup.get('selectedItem'),
            chartDrawingType: this.Controls.chartStyleComboBox.get('selectedItem').value,
            priceScaling: this.Controls.priceScalingComboBox.get('selectedItem').value,
            historyType: this.Controls.sourceTypeComboBox.get('selectedItem').value,
            showPriceMarker: this.Controls.showPriceMarkerCheckBox.get('checked'),
            barsBodyPairColor: this.Controls.barsBodyPicker.get('pairColor'),
            barsBorderPairColor: this.Controls.barsBorderPicker.get('pairColor'),
            wickPairColor: this.Controls.wickPicker.get('pairColor'),
            solidPriceColor: this.Controls.solidPricePicker.get('coloringButtonColor'),
            lineColor: this.Controls.linePicker.get('coloringButtonColor'),
            HistogramLineColor: this.Controls.histogramPicker.get('coloringButtonColor'),
            Doji: this.Controls.dojiPicker.get('coloringButtonColor')
        });
    }

    this.close();
};

// TODO.
TerceraAddOverlayScreen.prototype.populateSymbols = function ()
{
    /*symbolsLookup.SetPopulationFlags(false, true, true, true);
    symbolsLookup.Populate();*/
};

TerceraAddOverlayScreen.prototype.onInstrumentChanged = function (newIns)
{
    if (!newIns) return;

    var sourceTypeCombo = this.Controls.sourceTypeComboBox;
    sourceTypeCombo.setItembyValue(newIns.DefaultChartHistoryType);
};

TerceraAddOverlayScreen.prototype.populateSourceType = function (chart)
{
    if (!chart) return;

    var period = chart.TimeFrameInfo().Periods; //текущий период

    var types = DataCache.GetAllowedHistoryTypesByPeriod(period);
    types.sort(TerceraAddOverlayScreen.historyTypeComparer.compare);
    var types_len = types.length;

    var sourceTypeItems = [];

    for (var i = 0; i < types_len; i++)
    {
        var type = types[i];

        sourceTypeItems.push({

            text: ((period === Periods.TIC || period < 0) && type === 1) ?
                Resources.getResource("chart.view.sourceBID/ASK") :
                Resources.getResource("chart.view.source" + type),

            value: type
        });
    }

    var sourceTypeCombo = this.Controls.sourceTypeComboBox;
    sourceTypeCombo.set('items', sourceTypeItems);
    sourceTypeCombo.setItembyValue(sourceTypeItems[0].value);
};

TerceraAddOverlayScreen.prototype.populate = function (overlay)
{
    if (overlay.ActiveInstrument)
        this.Controls.insLookup.set('selectedItem', overlay.ActiveInstrument);

    this.Controls.sourceTypeComboBox.setItembyValue(overlay.HistoryType);
    this.Controls.chartStyleComboBox.setItembyValue(overlay.ChartDrawingTypeGet());
    this.Controls.priceScalingComboBox.setItembyValue(overlay.separateScale);

    var mainPriceRenderer = overlay.mainPriceRenderer;

    this.Controls.barsBodyPicker.set('pairColor', new PairColor(
        mainPriceRenderer.barsUpColorBrush.Color,
        mainPriceRenderer.barsDownColorBrush.Color));

    this.Controls.barsBorderPicker.set('pairColor', new PairColor(
        mainPriceRenderer.barsUpBorderColorPen.Color,
        mainPriceRenderer.barsDownBorderColorPen.Color));

    this.Controls.wickPicker.set('pairColor', new PairColor(
        mainPriceRenderer.wickUpBorderColorPen.Color,
        mainPriceRenderer.wickDownBorderColorPen.Color));

    this.Controls.dojiPicker.set('coloringButtonColor',
        mainPriceRenderer.barsHiLowColorPen.Color);

    this.Controls.histogramPicker.set('coloringButtonColor',
        mainPriceRenderer.histogramLineColorPen.Color);

    this.Controls.linePicker.set('coloringButtonColor',
        overlay.MarkerAndLineColorGet());

    this.Controls.solidPricePicker.set('coloringButtonColor',
        mainPriceRenderer.SolidPriceBrushColor);

    this.Controls.showPriceMarkerCheckBox.set('checked', overlay.ShowMarker);
};

TerceraAddOverlayScreen.addNewOverlay = function (chart)
{

    if (chart && chart.Overlays.length > 4)
    {
        TerceraMessageBox.Show(
            Resources.getResource("OverlayScreen.Overlays"),
            Resources.getResource("OverlayScreen.numberOverlaysError"),
            null, null, null, false, true);
        return;
    }

    var callback = TerceraAddOverlayScreen.onAddNewOverlay.bind(chart);
    TerceraAddOverlayScreen.show(chart, callback);
};

TerceraAddOverlayScreen.onAddNewOverlay = function (params)
{

    var instrument = params.instrument;
    var chartDrawingType = params.chartDrawingType;
    var historyType = params.historyType;
    var showPriceMarker = params.showPriceMarker;
    var priceScaling = params.priceScaling;
    var barsBodyPairColor = params.barsBodyPairColor;
    var barsBorderPairColor = params.barsBorderPairColor;
    var wickPairColor = params.wickPairColor;
    var solidPriceColor = params.solidPriceColor;
    var lineColor = params.lineColor;
    var HistogramLineColor = params.HistogramLineColor;
    var Doji = params.Doji;

    var chart = this;

    var overlay = chart.AddOverlayInstrument(
        instrument.GetInteriorID(),
        true,
        chartDrawingType,
        historyType)

    if (!overlay) return;

    var mainPriceRenderer = overlay.mainPriceRenderer;

    mainPriceRenderer.barsUpColorBrush.Color = barsBodyPairColor.Color1;
    mainPriceRenderer.barsDownColorBrush.Color = barsBodyPairColor.Color2;
    mainPriceRenderer.barsUpBorderColorPen.Color = barsBorderPairColor.Color1;
    mainPriceRenderer.barsDownBorderColorPen.Color = barsBorderPairColor.Color2;
    mainPriceRenderer.wickUpBorderColorPen.Color = wickPairColor.Color1;
    mainPriceRenderer.wickDownBorderColorPen.Color = wickPairColor.Color2;
    mainPriceRenderer.SolidPriceBrushColor = solidPriceColor;
    mainPriceRenderer.histogramLineColorPen.Color = HistogramLineColor;
    mainPriceRenderer.barsHiLowColorPen.Color = Doji;
    overlay.MarkerAndLineColorSet(lineColor);
    overlay.ShowMarker = showPriceMarker;
    overlay.separateScale = priceScaling;
};

TerceraAddOverlayScreen.editOverlay = function (chart, overlay)
{
    var callback = TerceraAddOverlayScreen.onEditOverlay.bind({ chart: chart, overlay: overlay });
    TerceraAddOverlayScreen.show(chart, callback);
    instance.on('complete', function ()
    {
        instance.populate(overlay);
    });
};

TerceraAddOverlayScreen.onEditOverlay = function (params)
{

    var instrument = params.instrument;
    var chartDrawingType = params.chartDrawingType;
    var historyType = params.historyType;
    var showPriceMarker = params.showPriceMarker;
    var priceScaling = params.priceScaling;
    var barsBodyPairColor = params.barsBodyPairColor;
    var barsBorderPairColor = params.barsBorderPairColor;
    var wickPairColor = params.wickPairColor;
    var solidPriceColor = params.solidPriceColor;
    var lineColor = params.lineColor;
    var HistogramLineColor = params.HistogramLineColor;
    var Doji = params.Doji;

    var overlay = this.overlay;

    overlay.Instrument = instrument.GetInteriorID()
    overlay.HistoryType = historyType;
    overlay.ChartDrawingTypeSet(chartDrawingType);

    var mainPriceRenderer = overlay.mainPriceRenderer;

    mainPriceRenderer.barsUpColorBrush.Color = barsBodyPairColor.Color1;
    mainPriceRenderer.barsDownColorBrush.Color = barsBodyPairColor.Color2;
    mainPriceRenderer.barsUpBorderColorPen.Color = barsBorderPairColor.Color1;
    mainPriceRenderer.barsDownBorderColorPen.Color = barsBorderPairColor.Color2;
    mainPriceRenderer.wickUpBorderColorPen.Color = wickPairColor.Color1;
    mainPriceRenderer.wickDownBorderColorPen.Color = wickPairColor.Color2;
    mainPriceRenderer.SolidPriceBrushColor = solidPriceColor;
    mainPriceRenderer.histogramLineColorPen.Color = HistogramLineColor;
    mainPriceRenderer.barsHiLowColorPen.Color = Doji;
    overlay.MarkerAndLineColorSet(lineColor);
    overlay.ShowMarker = showPriceMarker;
    overlay.separateScale = priceScaling;

    this.chart.UpdateOverlay();
};

TerceraAddOverlayScreen.show = function (chart, callback)
{
    if (!instance)
    {
        instance = new TerceraAddOverlayScreen();
        instance.on('teardown', function ()
        {
            instance = null;
        });
        MainWindowManager.MainWindow.addControl(instance);
    }

    instance.callback = callback;
    instance.populateSourceType(chart);

    instance.setParentPanel(chart.terceraChartPanelContext);
    instance.setFocus();
};

TerceraAddOverlayScreen.historyTypeComparer = {

    compare: function (t1, t2)
    {
        var cmpObj = TerceraAddOverlayScreen.historyTypeComparer;
        return cmpObj.getValue(t1) - cmpObj.getValue(t2);
    },

    getValue: function (historyType)
    {
        switch (historyType)
        {
            case HistoryType.DEFAULT: // default by instrument
                return -1;
            case HistoryType.BID:
                return 0;
            case HistoryType.ASK:
                return 1;
            case HistoryType.BIDASK_AVG:
                return 2;
            case HistoryType.BIDASK_SUM:
                return 3;
            case HistoryType.LAST:
                return 4;
        }
        return 0;
    }
};

let scrHanlder = addOverlayScreenHandler;
scrHanlder.editOverlay = TerceraAddOverlayScreen.editOverlay;