// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Rectangle } from '../../Commons/Geometry';
import { type SymbolDataCacheTool } from '../../Commons/cache/Tools/SymbolDataCacheTool';
import { ToolView } from './ToolView';

export class SymbolToolView extends ToolView<SymbolDataCacheTool> {
    public override IsSelectCheck (x: number, y: number): boolean {
        const symbolBmp = this.dataCacheTool.SymbolBmp;
        if (!symbolBmp) {
            return false;
        }

        const p = this.screenPoints[0];

        const rect = new Rectangle(
            p[0] - symbolBmp.width / 2,
            p[1] - symbolBmp.height / 2,
            symbolBmp.width,
            symbolBmp.height);

        return rect.Contains(x, y);
    }

    public DrawSymbol (gr): void {
        const symbolBmp = this.dataCacheTool.SymbolBmp;
        if (!symbolBmp) return;

        const p = this.screenPoints[0];

        gr.drawImage(symbolBmp,
            p[0] - symbolBmp.width / 2,
            p[1] - symbolBmp.height / 2);
    }

    public override Draw (gr, ww, param): void {
        super.Draw(gr, ww, param);
        this.DrawSymbol(gr);
    }

    public override DrawSelection (gr, ww): void {
        const symbolBmp = this.dataCacheTool.SymbolBmp;
        if (!symbolBmp) return;

        const p = this.screenPoints[0];
        const x = p[0];
        const y = p[1];

        const widthHalf = symbolBmp.width / 2;
        const heightHalf = symbolBmp.height / 2;

        this.DrawSelectedPoint(gr, ww, x - widthHalf, y - heightHalf);
        this.DrawSelectedPoint(gr, ww, x + widthHalf, y - heightHalf);
        this.DrawSelectedPoint(gr, ww, x - widthHalf, y + heightHalf);
        this.DrawSelectedPoint(gr, ww, x + widthHalf, y + heightHalf);
    }
}
