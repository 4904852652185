// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PropertySetupScreen } from "./PropertySetupScreen.js";

export let PanelSettingsScreen = {};

PanelSettingsScreen.EditProperties = function (caller, defaultPage, headerString, parentPanel)
{
    PropertySetupScreen.editProperty(caller, headerString, parentPanel);
    caller.propertiesScreen.selectPage(defaultPage);
};

let PanelSettingsDefaultPage = {
    General: 0,
    Filters: 1,
    Coloring: 2,
    Alerts: 3,
    DataBox: 8,
    AllPrice: 9,
    VolumeProfile: 10,
    BarStatistics: 11,
    VolumeBars: 12,
    Sesonality: 13,
    TerceraChartTimeAndSales: 14,
    BarPattern: 15
};