// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

export function CreateMapByObject (obj): any {
    const res = {};

    for (const prop in obj) { res[obj[prop]] = prop; };

    return res;
}

export function CreateMapByObj (obj): any // делает тоже что и CreateMapByObject но с использованием Object.keys и обычного цикла
{
    const res = {};
    const keys = Object.keys(obj);
    const keysLen = keys.length;
    for (let i = 0; i < keysLen; i++) { res[obj[keys[i]]] = keys[i]; }

    return res;
}
