// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DirectAlgorithmMarketConsensusMessage, MarketConsensusData, MarketConsensusPercentType } from '../../../../../Utils/DirectMessages/DirectMessagesImport';
import { FieldsFactory } from '../../Factory/FieldsFactory';
import { AlgorithmTopLongShortInstrumentGroup, type PFixFieldSet } from '../../Groups/GroupsImport';
import { MessageProcessorBase } from '../MessageProcessorBase';

export class AlgorithmMarketConsensusMessageProcessor extends MessageProcessorBase {
    public override decode (fs: PFixFieldSet): DirectAlgorithmMarketConsensusMessage[] {
        const msg = new DirectAlgorithmMarketConsensusMessage();
        const ff = FieldsFactory;

        msg.AlgorithmId = fs.GetValue(ff.FIELD_ALGORITHM_ID);
        msg.AlgorithmName = fs.GetValue(ff.FIELD_ALGORITHM_NAME);

        const groups = fs.GetGroups(ff.ALGORITHM_TOP_LONG_SHORT_INSTRUMENT_GROUP);
        for (let i = 0; i < groups.length; i++) {
            const gr = new AlgorithmTopLongShortInstrumentGroup(groups[i]);
            const marketConsensusData = new MarketConsensusData(gr.GetGroupContent());

            if (marketConsensusData.MarketConsensusPercentType === MarketConsensusPercentType.LONG) {
                msg.TopLongList.push(marketConsensusData);
            } else {
                msg.TopShortList.push(marketConsensusData);
            }
        }

        return [msg];
    }
}
