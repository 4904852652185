// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Point } from '../../Commons/Geometry';
import { type GannFanDataCacheTool } from '../../Commons/cache/Tools/GannFanDataCacheTool';
import { ChartMath } from '../Utils/ChartMath';
import { ToolView } from './ToolView';
import { ToolViewUtils } from './Utils/ToolViewUtils';

export class GannFanToolView extends ToolView<GannFanDataCacheTool> {
    public override IsSelectCheck (x: number, y: number): boolean {
        const POINT_DX = ToolView.POINT_DX;
        const scrP = this.screenPoints[0];

        return ChartMath.CalcDistanceFromPointToPoint(
            new Point(x, y),
            new Point(scrP[0], scrP[1])) <= POINT_DX * 2;
    }

    public DrawGannFan (gr, ww, highlight): void {
        const cacheTool = this.dataCacheTool;
        const font = cacheTool.font;
        const fontColor = cacheTool.FontColor;
        const fontBrush = cacheTool.FontBrush;
        const pen = highlight ? cacheTool.PenHighlight : cacheTool.Pen;
        const clientRect = ww.ClientRectangle;
        const h = clientRect.Height;
        const ch_ww = clientRect.X + clientRect.Width;
        const mirrored = cacheTool.mirrored;

        const scrP = this.screenPoints[0];
        const x = scrP[0];
        const y = scrP[1];

        if (mirrored) {
        // #region TEXT to the BOTTOM
            if (y < h) {
                ToolViewUtils.DrawFibonacciText(gr, ww, x + (h - y) / Math.tan(Math.atan(1 / 4)), h, '1/4', font, fontColor);
                ToolViewUtils.DrawFibonacciText(gr, ww, x + (h - y) / Math.tan(Math.atan(1 / 3)), h, '1/3', font, fontColor);
                ToolViewUtils.DrawFibonacciText(gr, ww, x + (h - y) / Math.tan(Math.atan(1 / 2)), h, '1/2', font, fontColor);
                ToolViewUtils.DrawFibonacciText(gr, ww, x + (h - y) / Math.tan(Math.atan(1)), h, '1/1', font, fontColor);
                ToolViewUtils.DrawFibonacciText(gr, ww, x + (h - y) / Math.tan(Math.atan(2)), h, '2/1', font, fontColor);
                ToolViewUtils.DrawFibonacciText(gr, ww, x + (h - y) / Math.tan(Math.atan(3)), h, '3/1', font, fontColor);
                ToolViewUtils.DrawFibonacciText(gr, ww, x + (h - y) / Math.tan(Math.atan(4)), h, '4/1', font, fontColor);
            }
            // #endregion

            // #region TEXT to the RIGHT
            if (x < ch_ww) {
                gr.DrawString('1/4', font, fontBrush, ch_ww, (y + (ch_ww - x) * Math.tan(Math.atan(1 / 4))), 'right', 'bottom');
                gr.DrawString('1/3', font, fontBrush, ch_ww, (y + (ch_ww - x) * Math.tan(Math.atan(1 / 3))), 'right', 'bottom');
                gr.DrawString('1/2', font, fontBrush, ch_ww, (y + (ch_ww - x) * Math.tan(Math.atan(1 / 2))), 'right', 'bottom');
                gr.DrawString('1/1', font, fontBrush, ch_ww, (y + (ch_ww - x) * Math.tan(Math.atan(1))), 'right', 'bottom');
                gr.DrawString('2/1', font, fontBrush, ch_ww, (y + (ch_ww - x) * Math.tan(Math.atan(2))), 'right', 'bottom');
                gr.DrawString('3/1', font, fontBrush, ch_ww, (y + (ch_ww - x) * Math.tan(Math.atan(3))), 'right', 'bottom');
                gr.DrawString('3/1', font, fontBrush, ch_ww, (y + (ch_ww - x) * Math.tan(Math.atan(4))), 'right', 'bottom');
            }
            // #endregion

            gr.DrawLine(pen, x, y, x + (h - y) / Math.tan(Math.atan(1 / 4)), h);
            gr.DrawLine(pen, x, y, x + (h - y) / Math.tan(Math.atan(1 / 3)), h);
            gr.DrawLine(pen, x, y, x + (h - y) / Math.tan(Math.atan(1 / 2)), h);

            gr.DrawLine(pen, x, y, x + (h - y) / Math.tan(Math.atan(1)), h);

            gr.DrawLine(pen, x, y, x + (h - y) / Math.tan(Math.atan(2)), h);
            gr.DrawLine(pen, x, y, x + (h - y) / Math.tan(Math.atan(3)), h);
            gr.DrawLine(pen, x, y, x + (h - y) / Math.tan(Math.atan(4)), h);
        } else {
        // #region TEXT to the TOP
            if (y > 0) {
                ToolViewUtils.DrawFibonacciText(gr, ww, (x + ((y) / Math.tan(Math.atan(1 / 4)))), 14, '1/4', font, fontColor);
                ToolViewUtils.DrawFibonacciText(gr, ww, (x + ((y) / Math.tan(Math.atan(1 / 3)))), 14, '1/3', font, fontColor);
                ToolViewUtils.DrawFibonacciText(gr, ww, (x + ((y) / Math.tan(Math.atan(1 / 2)))), 14, '1/2', font, fontColor);
                ToolViewUtils.DrawFibonacciText(gr, ww, (x + ((y) / Math.tan(Math.atan(1)))), 14, '1/1', font, fontColor);
                ToolViewUtils.DrawFibonacciText(gr, ww, (x + ((y) / Math.tan(Math.atan(2)))), 14, '2/1', font, fontColor);
                ToolViewUtils.DrawFibonacciText(gr, ww, (x + ((y) / Math.tan(Math.atan(3)))), 14, '3/1', font, fontColor);
                ToolViewUtils.DrawFibonacciText(gr, ww, (x + ((y) / Math.tan(Math.atan(4)))), 14, '4/1', font, fontColor);
            }
            // #endregion

            // #region TEXT to the RIGHT
            if (x < ch_ww) {
                gr.DrawString('1/4', font, fontBrush, ch_ww, (y - (ch_ww - x) * Math.tan(Math.atan(1 / 4))), 'right', 'bottom');
                gr.DrawString('1/3', font, fontBrush, ch_ww, (y - (ch_ww - x) * Math.tan(Math.atan(1 / 3))), 'right', 'bottom');
                gr.DrawString('1/2', font, fontBrush, ch_ww, (y - (ch_ww - x) * Math.tan(Math.atan(1 / 2))), 'right', 'bottom');
                gr.DrawString('1/1', font, fontBrush, ch_ww, (y - (ch_ww - x) * Math.tan(Math.atan(1))), 'right', 'bottom');
                gr.DrawString('2/1', font, fontBrush, ch_ww, (y - (ch_ww - x) * Math.tan(Math.atan(2))), 'right', 'bottom');
                gr.DrawString('3/1', font, fontBrush, ch_ww, (y - (ch_ww - x) * Math.tan(Math.atan(3))), 'right', 'bottom');
                gr.DrawString('3/1', font, fontBrush, ch_ww, (y - (ch_ww - x) * Math.tan(Math.atan(4))), 'right', 'bottom');
            }
            // #endregion

            gr.DrawLine(pen, x, y, x + y / Math.tan(Math.atan(1 / 4)), 0);
            gr.DrawLine(pen, x, y, x + y / Math.tan(Math.atan(1 / 3)), 0);
            gr.DrawLine(pen, x, y, x + y / Math.tan(Math.atan(1 / 2)), 0);

            gr.DrawLine(pen, x, y, x + y / Math.tan(Math.atan(1)), 0);

            gr.DrawLine(pen, x, y, x + y / Math.tan(Math.atan(2)), 0);
            gr.DrawLine(pen, x, y, x + y / Math.tan(Math.atan(3)), 0);
            gr.DrawLine(pen, x, y, x + y / Math.tan(Math.atan(4)), 0);
        }
    }

    public override Draw (gr, ww, param): void {
        this.DrawGannFan(gr, ww, false);
        super.Draw(gr, ww, param);
    }

    public override DrawSelection (gr, ww): void {
        this.DrawGannFan(gr, ww, true);
        super.DrawSelection(gr, ww);
    }
}
