// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DirectAlgorithmMessageBase } from './DirectAlgorithmMessageBase';
import { Message } from '../Message';

export class DirectAlgorithmMarketConsensusMessage extends DirectAlgorithmMessageBase {
    constructor () {
        super(Message.CODE_PFIX_ALGORITHM_MARKET_CONSENSUS_MESSAGE);
    }

    TopLongList: MarketConsensusData[] = [];
    TopShortList: MarketConsensusData[] = [];
}

export class MarketConsensusData {
    TradableId: number;
    RouteId: number;
    InstrumentName: string;
    InstrumentNameTypeId: number;
    Percent: number;
    MarketConsensusPercentType: number;
    IsExpertChoice: boolean;

    constructor (data: {
        TradableId: number
        RouteId: number
        InstrumentName: string
        InstrumentNameTypeId: number
        Percent: number
        MarketConsensusPercentType: number
        IsExpertChoice: boolean
    }) {
        this.TradableId = data.TradableId;
        this.RouteId = data.RouteId;
        this.InstrumentName = data.InstrumentName;
        this.InstrumentNameTypeId = data.InstrumentNameTypeId;
        this.Percent = data.Percent;
        this.MarketConsensusPercentType = data.MarketConsensusPercentType;
        this.IsExpertChoice = data.IsExpertChoice;
    }
}

export const MarketConsensusPercentType = {
    LONG: 0,
    SHORT: 1
};
