// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts";
import { EntitlementHistory, EntitlementProductFilterComboBoxVariants, EntitlementProductRequestStatus, EntitlementProductRequestType } from "../../Commons/cache/Entitlement/EntitlementPrimitives.ts";
import { EntitlementCategoryProduct, NO_NAME_CATEGORY } from "../../Commons/cache/Entitlement/EntitlementCategoryProduct.ts";
import { HtmlScroll } from "../../Commons/HtmlScroll.js";
import { TerceraProductsPanelTemplate } from "../../templates.js";
import { EntitlementProductsItem } from "../cache/EntitlementProductsItem.ts";
import { DockSystem, DockSystemInstance } from "../DockSystem.js";
import { TerceraHeaderMenuControl } from "../elements/TerceraHeaderMenuControl.js";
import { PanelNames } from "../UtilsClasses/FactoryConstants.ts";
import { ApplicationPanelNew } from "./ApplicationPanelNew.js";
import { RangeSelectPanel } from "./RangeSelectPanel.js";
import { ThemeManager } from "../misc/ThemeManager.ts";
import { DynProperty } from "../../Commons/DynProperty.ts";
import { EntitlementManager } from "../../Commons/cache/Entitlement/EntitlementManager.ts";
import { DataCache } from "../../Commons/DataCache.ts";
import { SessionSettings } from "../../Commons/SessionSettings.ts";
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";
import { WorkSpaceManager } from "../WorkSpace/WorkSpaceManager.ts";
import { Enum } from "../../Utils/Enum.ts";
import $ from 'jquery';
import 'jquery-mousewheel';
import 'malihu-custom-scrollbar-plugin';

export let ProductsPanel = ApplicationPanelNew.extend({
    data: function ()
    {
        return {
            width: 653,
            height: 480,

            movable: true,
            resizable: false,
            showHeader: true,
            showFooter: false,
            focused: true,
            dockablePanel: false,
            // isHeaderMenuButtonShow: true,

            canLinkByAccount: false,

            heightSubscriptionsPanel: null,
            heightRequestTopPanel: ProductsPanel.HEIGHT_REQUEST_TOP_PANEL,

            categoriesText: '',       // локазирированное название вкладки Subscriptions
            categoriesTextNum: '',    // общее кол-во продуктов в заглавии вкладки с учетом фильтра вместе со скобками

            checkedCategories: true,
            checkedRequest: false,
            categories: new Object()
        };
    },
    NeedCalculateRowCount: false,
    headerLocaleKey: 'screen.products.Products',
    rangeSelectPanel: null,
    toolbarSelectedFilter: -1,
    cachedAllProductCount: null,
    myMsgBox: null,
    partials: { bodyPartial: TerceraProductsPanelTemplate }
});

ProductsPanel.prototype.getType = function () { return PanelNames.ProductsPanel };

ProductsPanel.prototype.oncomplete = function ()
{
    ApplicationPanelNew.prototype.oncomplete.call(this)

    this.addRangeSelectPanel();

    this.on('clickCheck', this.clickCheck);
    // this.on("headerMenuClick", this.headerMenuClick);
    this.on("scrolling_left_Click", this.scrollingLeftClick)
    this.on("scrolling_right_Click", this.scrollingRightClick)
    this.observe('checkedCategories', this.observeActive);
    this.observe('checkedRequest', this.observeRequest);
    // this.observe('categories', this.sortingProductCatalog);   // вот тут точно что то сломаю но так часто сортировать нельзя
    this.on("onCollapserClick", this.onCollapserClick)

    this.setProductsData();
    this.addScroll();

    this.createToolbar()
    this.localize();
    this.layoutTable();
    this.center();

    DataCache.EntitlementManager.OnAddOrUpdate.Subscribe(this.OnAddOrUpdateProduct, this);

    const qt = this.getQuickTable();
    qt.AddToEnd = false;
    qt.OnPaintedPictureButtonClick.Subscribe(this.CancelBTNClick, this);
};

ProductsPanel.prototype.addRangeSelectPanel = function ()
{
    let topPanel = this.Controls.productPanelRangeSelect;
    topPanel.getReportEvent.Subscribe(this.getProductRequests, this);
    topPanel.updateSelectedRange();
    this.rangeSelectPanel = topPanel;
};

ProductsPanel.prototype.layoutTable = function (noChanges)
{
    if (noChanges)
        return;
    let showHeader = this.get("showHeader");
    let heightHeader = showHeader ? ProductsPanel.HEADER_PANEL : 0;

    let heightPanel = this.get("height");
    let height = heightPanel - heightHeader - ProductsPanel.SWITCH_MENU - ProductsPanel.TOOLBAR_HEIGHT;
    this.set({ heightSubscriptionsPanel: height });

    if (this.quickTableRactive === null)
        return;

    let topMargin = this.topPanelHeight + ProductsPanel.SWITCH_MENU + ProductsPanel.HEIGHT_REQUEST_TOP_PANEL;
    let scrWidth = this.get('width');
    let scrHeight = this.get('height') + 1;

    this.quickTableRactive.setBounds(
        0,
        0,
        Math.abs(scrWidth),
        Math.abs(scrHeight - topMargin - heightHeader));

    let categories = this.get("categories");

    if (categories)
    {
        for (let object in categories)
            categories[object].SetAvaliableWidth(this.get('width') - 30 - 10);

        this.set({
            categories: categories
        });
    }
};

ProductsPanel.prototype.setProductsData = function ()
{
    let arr = DataCache.EntitlementManager.Products;
    let categories = new Object();

    for (let i = 0; i < arr.length; i++)
    {
        let prod = arr[i];
        let wObj = categories;
        this.AddToProductsCatalog(prod, wObj);
    }
    categories = this.sortingProductCatalog(categories, null, 'categories')

    let needCollapse = true
    for (let object in categories)
    {
        let c = categories[object]
        if (c.Array.length && needCollapse)
        {
            c.IsHide = false
            needCollapse = false
        }
        c.SetAvaliableWidth(this.get('width') - 30);
    }

    this.set({
        categories: categories
    });

    this.UpdateCategoriesTextNum()
};

ProductsPanel.prototype.UpdateCategoriesTextNum = function ()
{
    let newValue = this.GetCachedProductCount(true),
        newTextValue = ' (' + newValue + ')'
    this.set({
        categoriesTextNum: newTextValue,
        noData: newValue == 0
    });
}

ProductsPanel.prototype.GetCachedProductCount = function (needRecalc)
{
    if (needRecalc || this.cachedAllProductCount === null)
        this.cachedAllProductCount = DataCache.EntitlementManager.GetProductCountByFilter(this.toolbarSelectedFilter)

    return this.cachedAllProductCount
}

ProductsPanel.prototype.OnAddOrUpdateProduct = function (product, needHistItem)
{
    let categories = this.get("categories"),
        updateScroll = false,
        belongToCurrentFilter = product.IsBelongToFilter(this.toolbarSelectedFilter)
    if (belongToCurrentFilter)
        updateScroll = this.AddToProductsCatalog(product, categories);
    else
        updateScroll = this.RemoveFromProductsCatalog(product, categories);

    this.set('categories', categories)

    if (updateScroll)
        this.UpdateCategoriesTextNum()

    if (needHistItem)
        this.AddHistoryItem(product);
}

ProductsPanel.prototype.sortingProductCatalog = function (catalog, old, ev)
{
    let keys = Object.keys(catalog);
    if (!keys.length)
        return

    let keysIndexesBeforeUpperCase = new Object(),
        keysUpperCase = keys.map((key) => key.toUpperCase());    // устраняем неалфавитную сортировку в случае различных регистров символов

    for (let i = 0; i < keys.length; i++)
        keysIndexesBeforeUpperCase[keysUpperCase[i]] = i

    keysUpperCase.sort();

    let newCatalog = new Object()
    for (let k = 0; k < keys.length; k++)
    {
        let indexInKeys = keysIndexesBeforeUpperCase[keysUpperCase[k]],
            key = keys[indexInKeys]

        newCatalog[key] = catalog[key];
    }

    this.set(ev, newCatalog);

    return newCatalog
}

ProductsPanel.prototype.AddToProductsCatalog = function (product, catalog)
{
    if (product.ShowInSettings)  // #110443 (docs paragraph 3)
        return false

    let hasNewCategory = false;
    let prodCategory = product.Category || NO_NAME_CATEGORY

    if (!catalog[prodCategory])
    {
        catalog[prodCategory] = new EntitlementCategoryProduct(prodCategory);
        hasNewCategory = true;
    }

    let filter = this.toolbarSelectedFilter
    if (!product.IsBelongToFilter(filter))
        return false

    let catalogTmp = catalog[prodCategory];
    if (!catalogTmp.IsExists(product))
    {
        catalogTmp.AddProduct(product);
        this.UpdateCategoriesTextNum()
    }

    return hasNewCategory;
}

ProductsPanel.prototype.RemoveFromProductsCatalog = function (product, catalog)
{
    let prodCategory = product.Category || NO_NAME_CATEGORY
    let category = catalog[prodCategory]
    if (category)
    {
        if (category.IsExists(product))
        {
            category.RemoveProduct(product)
            this.UpdateCategoriesTextNum()
        }
        // if (category.IsEmpty())
        //     delete catalog[prodCategory];
    }
}

ProductsPanel.prototype.localize = function ()
{
    ApplicationPanelNew.prototype.localize.call(this)

    this.set({
        categoriesText: Resources.getResource('screen.products.Categories'),
        // categoriesTextToolTip: Resources.getResource('screen.products.Categories.ToolTip'),
        requestText: Resources.getResource('screen.products.Request'),
        requestTextToolTip: Resources.getResource('screen.products.Request.ToolTip'),
        noDataText: Resources.getResource('screen.products.Categories.NoData')
    });

    this.localizeFilterComboBox()
};

ProductsPanel.prototype.clickCheck = function (sender, data)
{
    this.set("checked" + data, true);
    this.addScroll();
};

ProductsPanel.prototype.observeActive = function (n, o)
{
    if (!n)
        return

    this.set({
        checkedRequest: false
    });
};

ProductsPanel.prototype.observeRequest = function (n, o)
{
    if (this.quickTableRactive)
    {
        this.quickTableRactive.set("visible", n)
        this.rangeSelectPanel.set("visible", n)
    }

    if (!n)
    {
        this.set('loading', false)
        return
    }

    this.set({
        checkedCategories: false
    });
};

ProductsPanel.prototype.AddHistoryItem = function (product)
{
    let qt = this.getQuickTable()
    if (!qt) return

    let historyItem = new EntitlementHistory(product.Id, product.Name);
    let productRequestItem = product.GenerateSubscriptionResponseMessage();
    historyItem.UpdateHistoryStatus(productRequestItem);
    let colors = this.GetColorRow(productRequestItem)
    let row = qt.AddItem(new EntitlementProductsItem(historyItem, SessionSettings, colors));
}

ProductsPanel.prototype.GetColorRow = function (productRequestItem)
{
    let result = { "1": null, "2": null };

    if (!productRequestItem)
        return result;

    switch (productRequestItem.ReqType)
    {
        case EntitlementProductRequestType.Subscribe:
            result["1"] = ThemeManager.CurrentTheme.ProductHistoryItemSubscribeForegroundColor;
            break;
    }
    switch (productRequestItem.ReqStatus)
    {
        case EntitlementProductRequestStatus.Approved:
            result["2"] = ThemeManager.CurrentTheme.ProductHistoryItemApprovedForegroundColor;
            break;
        case EntitlementProductRequestStatus.Rejected:
            result["2"] = ThemeManager.CurrentTheme.ProductHistoryItemRejectedForegroundColor;
            break;
        case EntitlementProductRequestStatus.Canceled:
            result["2"] = ThemeManager.CurrentTheme.ProductHistoryItemCancelledForegroundColor;
            break;
    }
    return result;
}

ProductsPanel.prototype.CancelBTNClick = function (data)
{
    if (!data || !data.row || !data.row.item || !data.row.item.requestData)
        return

    if (this.myMsgBox)
        return

    this.myMsgBox = EntitlementManager.GetMessageBoxForProductCancelRequest(this, data.row.item.requestData);
};

ProductsPanel.prototype.getProductRequests = function (startTime, finishTime)
{
    let qtRactive = this.quickTableRactive
    let qt = qtRactive ? qtRactive.quickTable : null
    if (!qt) return

    let rq = this.get("checkedRequest");
    if (rq)
        this.set('loading', true)

    EntitlementManager.SendSubscriptionHistoryReques(new Date(startTime), new Date(finishTime))
        .then(function (msgs)
        {
            if (rq)
                this.set('loading', false)
            qt.ClearAll();

            if (!msgs.length)
                return

            let fsort = function (a, b) { return a.ReqTime - b.ReqTime }

            msgs.sort(fsort)
            for (let i = 0; i < msgs.length; i++)
            {
                let requestData = msgs[i]
                let product = DataCache.EntitlementManager.ProductsCache[requestData.Id];
                if (!product)
                    continue

                let historyItem = new EntitlementHistory(product.Id, product.Name);
                historyItem.UpdateHistoryStatus(requestData);
                let colors = this.GetColorRow(requestData)
                let row = qt.AddItem(new EntitlementProductsItem(historyItem, SessionSettings, colors));
            }
        }.bind(this));
}

ProductsPanel.prototype.onCollapserClick = function (sender, categoryName)
{
    let cat = this.get("categories"),
        c = cat[categoryName]

    if (c)
        c.IsHide = !c.IsHide;

    this.set("categories", cat);

    if (!c.IsHide)
        this.scrollTo(c.Name)
}

ProductsPanel.prototype.scrollingLeftClick = function (sender, categoryName)
{
    let cat = this.get("categories");

    if (cat[categoryName])
        cat[categoryName].LeftArrowClick();

    this.set("categories", cat);
}

ProductsPanel.prototype.scrollingRightClick = function (sender, categoryName)
{
    let cat = this.get("categories");

    if (cat[categoryName])
        cat[categoryName].RightArrowClick();

    this.set("categories", cat);
}

ProductsPanel.prototype.createToolbar = function ()
{
    this.fillFilterComboBox()
    this.on('onSelectedFilterChange', this.onSelectedFilterChange)
};

ProductsPanel.prototype.onSelectedFilterChange = function (context, cbItem)
{
    let value = cbItem.value ? cbItem.value : cbItem
    this.toolbarSelectedFilter = value

    this.setProductsData()
    this.resetScroll();
};

ProductsPanel.prototype.localizeFilterComboBox = function ()
{
    let filterComboBox = this.Controls ? this.Controls.filterComboBox : null
    if (!filterComboBox) return

    let items = filterComboBox.get('items'),
        selectedCBItem = null;
    for (let i = 0; i < items.length; i++)
    {
        let item = items[i]
        item.text = Resources.getResource(item.key)

        if (this.toolbarSelectedFilter === item.value)
            selectedCBItem = item
    }

    filterComboBox.set({ items: items });

    if (selectedCBItem)
        filterComboBox.set('selectedItem', selectedCBItem)
}

ProductsPanel.prototype.fillFilterComboBox = function ()
{
    let filterVariants = Enum.TakeKeysFromEnum(EntitlementProductFilterComboBoxVariants),
        items = []

    for (let filterVariant of filterVariants)
    {
        let value = EntitlementProductFilterComboBoxVariants[filterVariant],
            cbItem = { key: 'screen.products.toolbar.filterCB.' + filterVariant, value: value }

        items.push(cbItem)
    }

    let filterComboBox = this.Controls ? this.Controls.filterComboBox : null
    if (!filterComboBox) return

    filterComboBox.set({ items: items });

    this.localizeFilterComboBox()
}

ProductsPanel.prototype.dispose = function ()
{
    DataCache.EntitlementManager.OnAddOrUpdate.UnSubscribe(this.OnAddOrUpdateProduct, this);
    this.rangeSelectPanel.getReportEvent.UnSubscribe(this.getProductRequests, this);
    ApplicationPanelNew.prototype.dispose.call(this);
};

ProductsPanel.prototype.headerMenuClick = function (clickedItem)
{
    if (this.Dettach && clickedItem.type === TerceraHeaderMenuControl.DETTACH)
    {
        this.Dettach()
        var newPanel = MainWindowManager.Factory.createPanel(PanelNames.ProductsPanel);
        newPanel.set({ dockablePanel: false, showHeader: true, resizable: true });

        MainWindowManager.Factory.dockSystemOperations(newPanel, false);
        MainWindowManager.Factory.addPanelToWs(newPanel);
    }
    if (!this.get("dockablePanel") && clickedItem.type === TerceraHeaderMenuControl.ATTACH)
    {
        this.set({ dockablePanel: true, showHeader: false, resizable: false })
        DockSystemInstance.addPanelOnDock(this, DockSystem.DockPlaceConst.Down, false)
        WorkSpaceManager.currentWorkspace.addPanel(this)
    }
};

ProductsPanel.prototype.addScroll = function ()
{
    HtmlScroll.addScroll(this.find(".js-products-main"));
};

ProductsPanel.prototype.removeScroll = function ()
{
    HtmlScroll.removeScroll(this.find(".js-products-main"));
};

ProductsPanel.prototype.resetScroll = function ()
{
    this.removeScroll();
    this.addScroll();
}

ProductsPanel.prototype.scrollTo = function (elementID)
{
    if (!elementID || elementID == NO_NAME_CATEGORY)
        return

    $(this.find(".js-products-main")).mCustomScrollbar("scrollTo", "#" + elementID);
}


ProductsPanel.prototype.Properties = function ()
{
    let properties = ApplicationPanelNew.prototype.Properties.call(this);
    // properties.push(new DynProperty("attached", this.get("dockablePanel"), DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));
    properties.push(new DynProperty("toolbarSelectedFilter", this.toolbarSelectedFilter, DynProperty.INTEGER, DynProperty.HIDDEN_GROUP));

    return properties;
};

ProductsPanel.prototype.validateProperties = function (properties) // help in migration process: checking if properties for callback are correct and suitable for current version
{
    if (DynProperty.getPropValue(properties, "attached") !== null)       // old version of panel
    {
        MainWindowManager.Factory.addPanel(PanelNames.ProductsPanel);
        this.close()
        return false
    }

    return true
}

ProductsPanel.prototype.callBack = function (newProperties)
{
    ApplicationPanelNew.prototype.callBack.call(this, newProperties);

    this.toolbarSelectedFilter = DynProperty.getPropValue(newProperties, "toolbarSelectedFilter");
    // let attached = DynProperty.getPropValue(newProperties, "attached");
    // this.set({ dockablePanel: attached, showHeader: !attached })
    // if (!attached)
    // {
    //     this.unrender()
    //     Factory.dockSystemOperations(this, false);
    //     Factory.addPanelToWs(this);
    // }
};

ProductsPanel.HEADER_PANEL = 25;
ProductsPanel.SWITCH_MENU = 35;
ProductsPanel.TOOLBAR_HEIGHT = 32;
ProductsPanel.HEIGHT_REQUEST_TOP_PANEL = 40;