// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Control } from "../../Controls/elements/Control.js";
import { PortfolioMetricsItemBaseTemplate } from "../../templates.js";

export let PortfolioMetricsItemBase = Control.extend({
    data: function ()
    {
        return {
            metricData: null,
            type: null,
            name: null,
            value: null,
            visible: null
        };
    },
    template: PortfolioMetricsItemBaseTemplate
});

PortfolioMetricsItemBase.prototype.oncomplete = function ()
{
    Control.prototype.oncomplete.call(this)
    this.observe("metricData", this.initialization)
};

PortfolioMetricsItemBase.prototype.initialization = function ()
{
    let data = this.get("metricData")

    if (!data)
    {
        this.set({ visible: false })
        return
    }

    let name = data.Name ? data.Name : "N/A";
    let value = data.Value ? data.Value : "N/A";

    this.set({
        type: data.Type,
        name: name,
        value: value,
        visible: data.Visible
    })
};