// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type AssetBalance } from '../../Commons/cache/AssetBalance';
import { type QuickTableComparingType } from '../QuickTableMisc/QuickTableComparingType';
import { AccountAdditionalInfoItemFormatingType } from './AccountAdditionalInfoItemFormatingType';

export class AdditionalInfoItem {
    public GroupInfo: string;
    public SortIndex: number;
    public NameKey: string;
    public ToolTipKey: string;
    public DataType: QuickTableComparingType;
    public Value: number;
    public Visible: boolean;
    /// <summary>
    /// ключ для апи, обязательно для заполнения и должен быть уникальным
    /// </summary>
    public APIKey: string;
    // +++
    public UseColor = false;

    public Update (item): void {
        this.GroupInfo = item.GroupInfo;
        this.SortIndex = item.SortIndex;
        this.NameKey = item.NameKey;
        this.ToolTipKey = item.ToolTipKey;
        this.DataType = item.DataType;
        this.Value = item.Value;
        this.Visible = item.Visible;
        this.UseColor = item.UseColor;
        this.APIKey = item.APIKey;
    }
}

export class InstrumentAdditionalInfoItem extends AdditionalInfoItem {

}

export class AccountAdditionalInfoItem extends AdditionalInfoItem {
    public FormatingType: AccountAdditionalInfoItemFormatingType;
    public Destination = AccountAdditionalInfoDestinationPanel.AllPanels;
    /// <summary>
    /// ипользуется в связске с  AccountAdditionalInfoItemFormatingType.CustomAsset для форматирования в заданном ассете
    /// </summary>
    public CustomAssetID: any = null;
    public GroupIndex: number | null = null;

    public Update (item): void {
        super.Update(item);

        if (item) {
            this.FormatingType = item.FormatingType;
            this.Destination = item.Destination;
            this.CustomAssetID = item.CustomAssetID;
            this.GroupIndex = item.GroupIndex;
        }
    }

    public GetFormatValue (assetBalance: AssetBalance): string {
        let val = this.Value;
        if (!val) {
            val = 0;
        } // #113807

        // +++ support formating for custom data
        switch (this.FormatingType) {
        case AccountAdditionalInfoItemFormatingType.AssetBalance:
            if (assetBalance) {
                return assetBalance.formatPriceExactly(val);
            } else {
                return val.toString();
            }
        default:
            return val.toString();
        }
    }
}

export enum AccountAdditionalInfoDestinationPanel {
    AccountsDetails = 1,
    OERiskDetails = 2,
    AllPanels = 3
}
