// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts";
import { HtmlScrollXY } from "../../Commons/HtmlScroll.js";
import { TerceraBaseWindowTemplate, TerceraBrokerInfoScreenTemplate } from "../../templates.js";
import { TerceraWindowBase } from "./TerceraWindowBase.js";
import { DataCache } from "../../Commons/DataCache.ts";

export let TerceraBrokerInfoScreen = TerceraWindowBase.extend({
    template: TerceraBaseWindowTemplate,
    data: function ()
    {
        return {
            movable: false,
            showHeader: true,
            showFooter: false,
            resizable: false,
            canSelect: false,
            alwaysMiddle: true,
            showFullscreenCloud: true,
            focused: true,
            width: 309,
            height: 439,
            style_addition_body: 'brokerinfo-screen-addition-body',
            style_addition_header: 'brokerinfo-screen-addition-header'
        };
    },
    partials: { bodyPartial: TerceraBrokerInfoScreenTemplate },
});

TerceraBrokerInfoScreen.prototype.getType = function () { return 'TerceraBrokerInfoScreen' };

TerceraBrokerInfoScreen.prototype.oncomplete = function ()
{
    TerceraWindowBase.prototype.oncomplete.apply(this);

    this.fillWithBrokerInfo();
    this.updateBrandingImage();
    this.localize();
};

TerceraBrokerInfoScreen.prototype.fillWithBrokerInfo = function ()
{
    let info = DataCache.BrokerInformation,
    container = this.find('.js-broker-info-text');

    if (!info || !container)
        return

    let infoArr = info.split(';'),
        rowNum = 0

    for (let i = 0; i < infoArr.length; i++)
    {
        var d = document.createElement('div');
        d.innerText += infoArr[i] + '\n'
        d.style.width='309px';
        container.appendChild(d);
        
        if (infoArr[i])
            rowNum++
    }

    if (rowNum < 5) 
    {
        HtmlScrollXY.addScroll(container, 'x');
        container.style.lineHeight = (container.offsetHeight / rowNum) + 'px'
    }  
    else
        HtmlScrollXY.addScroll(container, 'xy');
};

TerceraBrokerInfoScreen.prototype.localize = function ()
{
    this.set({ header: Resources.getResource('screen.brokerInfo.header') });
};