// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type HistoryMergerInputParams } from '../../../../Utils/History/HistoryMergerInputParams';
import { HistoryMergerBasedByMin } from './HistoryMergerBasedByMin';
import { Interval } from '../../../../Utils/History/Interval';
import { TradingSessionBase } from '../../../../Utils/Session/Sessions';

export class HistoryMergerIntrdayBySession extends HistoryMergerBasedByMin {
    private readonly showExtendedSession: boolean;

    constructor (inputParams: HistoryMergerInputParams) {
        super(inputParams);
        this.showExtendedSession = inputParams.ShowExtendedSession;
    }

    protected override GetStartPoint (date: Date): Interval {
        const [startSession, endSession] = TradingSessionBase.GetSessionPeriodRange(date,
            this.instrument.TradingSessionsList,
            this.showExtendedSession);
        if (startSession.getTime() === 0 || endSession.getTime() === 0) {
            return Interval.GetInvalidInterval();
        }

        const interval = new Interval();
        interval.From = startSession;
        interval.To = endSession;
        return interval;
    }
}
