// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class TradingUnlockMessageResponse extends PFixMessage {
    constructor (fieldSet: PFixFieldSet) {
        super(Message.CODE_TRADING_UNLOCK_MESSAGE_RESPONSE, fieldSet);
    }

    public getTradingUnlocked (): boolean {
        return this.getFieldValue(FieldsFactory.FIELD_TRADING_UNLOCK_MESSAGE_RESPONSE);
    }

    public setTradingUnlocked (unlock: boolean): void {
        this.setFieldValue(FieldsFactory.FIELD_TRADING_UNLOCK_MESSAGE_RESPONSE, unlock);
    }
}
