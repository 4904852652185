import { TvInteriorIdCache } from '../Caches/TvInteriorIdCache';
import { type DOMCallback, type DOMData, type DOMLevel } from '../charting_library';
import { DataCache } from '../../DataCache';
import { HistoryType } from '../../../Utils/History/HistoryType';
import { type DirectQuoteMessage } from '../../../Utils/DirectMessages/DirectQuoteMessage';
import { type Account } from '../../cache/Account';
import { TvAccountsManager } from './TvAccountsManager';

export class TvDomManager {
    private domCallback: DOMCallback;
    private curAccount: Account;

    public subscribeDepth (symbol: string, callback: DOMCallback): string {
        this.domCallback = callback;
        const interiorId = TvInteriorIdCache.getInteriorId(symbol);
        const instrument = DataCache.getInstrumentByName(interiorId);
        if (!isNullOrUndefined(instrument)) {
            DataCache.FQuoteCache.addListener(instrument, this, HistoryType.QUOTE_LEVEL2);
        }
        return interiorId;
    }

    public unsubscribeDepth (subscriberUID: string): void {
        const interiorId = TvInteriorIdCache.getInteriorId(subscriberUID);
        const instrument = DataCache.getInstrumentByName(interiorId);
        if (!isNullOrUndefined(instrument)) {
            DataCache.FQuoteCache.removeListener(instrument, this, HistoryType.QUOTE_LEVEL2);
        }
    }

    public setCurrentAccount (account: Account): void {
        this.curAccount = account;
    }

    public newQuote (msg: DirectQuoteMessage): void {
        if (msg.Type === HistoryType.QUOTE_INSTRUMENT_DAY_BAR) return;

        if (!this.domCallback) return;

        const [askLevel, bidLevel] = this.getDOMData(msg);

        const data: DOMData = {
            snapshot: true,
	        asks: askLevel,
	        bids: bidLevel
        };

        this.domCallback(data);
    }

    private getDOMData (msg: DirectQuoteMessage): [DOMLevel[], DOMLevel[]] {
        const interiorId = msg.TargetInstrumentName;
        const instrument = DataCache.getInstrumentByName(interiorId);
        if (isNullOrUndefined(instrument)) {
            console.warn(`getDOMData::Instrument ${interiorId} not found`);
            console.warn(msg);
            return [[], []];
        }

        const lvl2 = instrument.DataCache.FQuoteCache.GetLevel2Cash(instrument);
        const lotSize = instrument.LotSize;

        if (isNullOrUndefined(lvl2)) return [[], []];

        const account = this.curAccount;
        const sp = DataCache.GetSpreadPlan(account);
        const bidsCollection = lvl2.GetBids(sp);
        const asksCollection = lvl2.GetAsks(sp);

        const bids = this.getDomArray(bidsCollection, true, lotSize);
        const asks = this.getDomArray(asksCollection, false, lotSize);

        return [asks, bids];
    }

    // TODO: Нужно запустить таймер и обновлять данные каждые по времени (как в МД)
    private getDomArray (l2Collection, isBid: boolean, lotSize: number): DOMLevel[] {
        // const keys = Object.keys(l2Collection.items);
        const items = l2Collection.GetSortedList(isBid);

        const domArray: DOMLevel[] = [];

        for (const item of items) {
            const l2 = {
                price: item.Quote.Price,
                volume: item.Quote.Size / lotSize
            };
            domArray.push(l2);
        }
        return domArray;
    }
}
