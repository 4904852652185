import { CreateMapByObj } from '../CreateMapByObjectUtils';

// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
export enum AccountType {
    SingleCCY = 0,
    MultiAsset = 1,
    ClosedFund = 2,
    OpenedFund = 3
}

export const AccountTypeMap = CreateMapByObj(AccountType);

export function getAccountTypeStr (value): string {
    return AccountTypeMap[value] || AccountTypeMap[AccountType.SingleCCY];
}
