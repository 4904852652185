// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';

export class PVT extends IndicatorScriptBase {

    constructor () {
        super();
        this.ProjectName = 'PVT';
        this.Comments = 'Price Volume Trend';
        super.SetIndicatorLine('pvt', Color.Blue, 1, LineStyle.SimpleChart);
        this.SeparateWindow = true;
    }

    private cummulativeDelta = 0;

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
    };
    public override GetIndicatorShortName (): string {
        return 'PVT';
    };

    private getPVT (): number {
        return this.CurrentData.Volume(1) + (this.CurrentData.Volume() * (this.CurrentData.GetPrice(PriceType.Close) - this.CurrentData.GetPrice(PriceType.Close, 1)) / this.CurrentData.GetPrice(PriceType.Close, 1));
    }

    public OnQuote (): void {
        super.OnQuote();
    };

    public NextBar (): void {
        if(this.CurrentData.Count - 1 > 0){
            var delta = this.getPVT() - this.CurrentData.Volume(1);
            this.cummulativeDelta += delta * 1000;           
            super.SetValue(0, 0, this.cummulativeDelta / 1000);
        }
        super.NextBar();
    }
}
