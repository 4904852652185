// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type Account } from '../../cache/Account';
import { type Instrument } from '../../cache/Instrument';
import { type OrderType } from '../../../Utils/Trading/OrderType';

export class MarginInfoParameters {
    account: Account;
    instrument: Instrument;
    orderType: OrderType;
    amountLots: number;
    limitPrice: number;
    stopPrice: number;
    isLong: boolean;

    productType: any;
    leverageValue: any;
}
