// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { NewNetTrader } from '../Controls/screen/NewNetTrader';

import { WorkSpaceManager } from '../Controls/WorkSpace/WorkSpaceManager';
import { DockSystem } from '../Controls/DockSystem.js';
import { MainWindowManager } from '../Controls/UtilsClasses/MainWindowManager';
import { ThemeManager } from '../Controls/misc/ThemeManager';

import { WebTraderTemplate } from '../../scripts/templates.js';
import { DataCache } from './DataCache';
import { Connection, ConnectionInstanceCreator } from './Connection';
import { SessionSettings } from './SessionSettings';
import { NewNetTraderMode } from '../Controls/screen/NewNetTraderMode';
import { ApplicationLoader } from './ApplicationLoader';
import { ApplicationInfo, CreateApplicationInfo } from './ApplicationInfo';
import { ApplicationHandlersInitializer } from './ApplicationHandlersInitializer';

let fromUnload = null;
class Application {
    public Templates = {};
    public mainWindow = null;

    // TODO. Refactor. Ugly.
    public Initialize (): void {
        CreateApplicationInfo();
        this.InitializeDivConstants();
        ApplicationHandlersInitializer.Initialize();

        const self = this;
        this.InitializeWebtrader()
            .then(function () {
                ConnectionInstanceCreator(DataCache, () => { Application.disconnectPortfolioSelfCalledTab(); });
                self.InitMainWindowAndContextMenu();
                MainWindowManager.MainWindow.Controls.loginScreen.localize();
                DockSystem.InitSystem('dockMainContainer');
            });
    }

    public InitializeDivConstants (): void {
        const resStr = WebTraderTemplate;
        document.body.innerHTML += resStr;
    }

    public InitStorages (skip): void {
        if (!skip) {
            ConnectionInstanceCreator(DataCache, () => { Application.disconnectPortfolioSelfCalledTab(); });
        }

        ThemeManager.onThemeChange.Subscribe(() => { SessionSettings.updateCurrentTheme(ThemeManager.currentThemeCode); }, this);
    }

    // TODO. Refactor.
    public InitializeWebtrader (): any {
        this.InitStorages(true);

        fromUnload = null; // при событии закрытия вкладки с приложением перед дисконнектом этот флажок становится true
        MainWindowManager.portfolioWindow = null; // тут лежит ссылка на окно портфолио, если такое было вызвано с основного приложения

        window.onbeforeunload = function (e) {
            if (NewNetTraderMode.WorkMode === MainWindowManager.MainWindow.get('netTraderState')) {
                WorkSpaceManager.saveWorkSpace();
            }
        };

        window.onunload = function () {
            fromUnload = true; // doc. 3.1).3  https://docs.google.com/document/d/1Tnb1LqXehcolTHU2ZEOywAuHLcYOXWA3Grsjddu0C-E/edit#heading=h.9o7dj749n3zi

            if (Connection.disconnect) {
                Connection.disconnect();
            }
        };

        return ApplicationLoader();
    }

    public InitMainWindowAndContextMenu (): void {
        if (ApplicationInfo.myportfolio) {
            // new PortfolioManagement();
        } else {
            new NewNetTrader();
        }

        document.addEventListener('contextmenu', function (e) {
            e.preventDefault();
        });
    }

    public static disconnectPortfolioSelfCalledTab (): void // закрытие вкладки с портфолио если она была вызвана при данном коннекшне doc. 3.1)  https://docs.google.com/document/d/1Tnb1LqXehcolTHU2ZEOywAuHLcYOXWA3Grsjddu0C-E/edit#heading=h.9o7dj749n3zi
    {
        if (MainWindowManager.portfolioWindow && !fromUnload) {
            MainWindowManager.portfolioWindow.close();
            MainWindowManager.portfolioWindow = null;
        }
        fromUnload = null;
    }
}

export const App = new Application();
