// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
export class Level1CalculatorUtils {
    public static getLastColoring (prevLastPrice: number, curLastPrice: number): ColoringEnum {
        if (isNaN(prevLastPrice) || isNaN(curLastPrice)) {
            return ColoringEnum.Neitral;
        }

        if (curLastPrice > prevLastPrice) {
            return ColoringEnum.Positive;
        } else if (curLastPrice < prevLastPrice) {
            return ColoringEnum.Negative;
        }

        return ColoringEnum.Previous;
    }

    public static getChangeColoring (curChange: number): ColoringEnum {
        if (isNaN(curChange)) {
            return ColoringEnum.Neitral;
        }

        if (curChange > 0) {
            return ColoringEnum.Positive;
        } else if (curChange < 0) {
            return ColoringEnum.Negative;
        }

        return ColoringEnum.Neitral;
    }
}

export enum ColoringEnum {
    Neitral,
    Positive,
    Negative,
    Previous,
}
