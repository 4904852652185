import { type ISeries } from './ISeries';

export class XYSeriesData {
    public static readonly X_INDEX = 0;
    public static readonly Y_INDEX = 1;

    public x: number;
    public y: number;
    public xStr: string;
    public yStr: string;

    constructor (x: number, y: number, xStr: string = '', yStr: string = '') {
        this.x = x;
        this.y = y;
        this.xStr = xStr;
        this.yStr = yStr;
    }
}

export class TerceraChartXYSeries implements ISeries {
    public readonly data: XYSeriesData[] = [];

    public GetValue (index: number, level: number): number {
        if (index >= this.Count() || index < 0) {
            return 0;
        }
        switch (level) {
        case XYSeriesData.X_INDEX:
            return this.data[index].x;
        case XYSeriesData.Y_INDEX:
            return this.data[index].y;
        default:
            return 0;
        }
    }

    public Count (): number {
        return this.data.length;
    }

    public Dispose (): void {

    }
}

export class TerceraChartAggregatedXYSeries implements ISeries<TerceraChartXYSeries> {
    public readonly data: TerceraChartXYSeries[] = [];

    public GetValue (index: number, level: number): TerceraChartXYSeries {
        if (index >= this.Count() || index < 0) {
            return null;
        }
        return this.data[index];
    }

    public Count (): number {
        return this.data.length;
    }

    public Dispose (): void {
        for (let i = 0; i < this.data.length; i++) {
            this.data[i].Dispose();
        }
    }
}
