import { ChartDataType } from '../../../Chart/Utils/ChartConstants';
import { HistoryType } from '../../../Utils/History/HistoryType';
import { Periods } from '../../../Utils/History/TFInfo';

// Period of data.
export const Period =
{
    // Tick Period.
    Tick: Periods.TIC,
    // 1 Minute Period.
    Min: Periods.MIN,
    // 5 Minute Period.
    Min5: Periods.MIN5,
    // 15 Minute Period.
    Min15: Periods.MIN15,
    // 30 Minute Period.
    Min30: Periods.MIN30,
    // 1 Hour Period.
    Hour: Periods.HOUR,
    // 4 Hour Period.
    Hour4: Periods.HOUR4,
    // 1 Day Period.
    Day: Periods.DAY,
    // 1 Week Pertiod.
    Week: Periods.WEEK,
    // 1 Monthl Period.
    Month: Periods.MONTH,
    // 1 Year Period.
    Year: Periods.YEAR,
    // 1 Second Period.
    Second: Periods.SECOND,
    // Price range period
    Range: Periods.RANGE
};

// Provide access to different history types.
export enum IndicatorHistoryType {
    // Simple bars objects represent the Time, Tick, Price Range bars of data.
    Simple = ChartDataType.Default,
    // Represent access to Renko bars objects.
    Renko = ChartDataType.RencoNew,
    // Represent access to Kagi bars objects.
    Kagi = ChartDataType.Kagi,
    // Represent access to Line Break bars objects.
    LineBreak = ChartDataType.ThreeLinesBreak,
    // Represent access to PnF bars objects.
    PnF = ChartDataType.TicTac
}

// Style of bars aggregations.
// ByWicks and Advanced use only for Renko chart,
// ByHighLow use for Renko and PnF charts.
export enum IndicatorBarsAggregations {
    // This value mean no, or classic aggregations type.
    None = 0,
    // This value will result in creating a renko data with wicks,
    // which will provide more information to the trader than the standard renko bricks do.
    ByWicks = 1,
    // Use high/low method, for Renko and PnF.
    ByHighLow = 2,
    // Use Renko Advanced mode
    Advanced = 4,
    // Use Renko Advanced high/low mode
    AdvancedHighLow = 8
}

// Supported history data types.
export enum IndicatorDataType {
    // Quote history with Bid as source.
    Bid = HistoryType.QUOTE_LEVEL1,
    // Quote history with Ask as source.
    Ask = HistoryType.QUOTE_ASK,
    // History is building by trades.
    Trade = HistoryType.QUOTE_TRADES,
    // Quote history building by average between Asks and Bids.
    Average = HistoryType.QUOTE_BIDASK_AVG,
    // Quote history building by summa of max and min from Asks and Bids.
    Sum = HistoryType.QUOTE_BIDASK_SUM,
}
