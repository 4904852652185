// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { TrailingStopOrderEdit } from '../order-edit/TrailingStopOrderEdit';
import { InstrumentUtils } from '../../../../Utils/Instruments/InstrumentUtils';
import { OrderEditUpdateData } from '../../../../Utils/Trading/OrderEditUpdateData';
import { TIF } from '../../../../Utils/Trading/OrderTif';
import { OrderUtils } from '../../../../Utils/Trading/OrderUtils';
import { Quantity } from '../../../../Utils/Trading/Quantity';
import { OrderExecutorUtils } from '../../../Trading/OrderExecutorUtils';
import { BoundToEdit } from '../BoundToEdit';
import { OrderSLTPEdit } from '../OrderSLTPEdit';
import { type DynProperty } from '../../../DynProperty';
import { IsAllowed } from '../../../IsAllowed';

// TODO. Ugly. Refactor. Lots of duplicated pieces of code
// (sltp, boundTo parameters and whatnot).
export class ModifyTrailingStopOrder extends TrailingStopOrderEdit {
    public order: any;
    public boundToEdit: BoundToEdit;

    constructor (data) {
        super(data);

        const order = data.order;

        this.sltpBasePriceGetter = this.sltpBasePriceGetter.bind(this);

        this.order = order;
        this.sltp = new OrderSLTPEdit(data, true);
        this.boundToEdit = new BoundToEdit();

        this.updateParameters(new OrderEditUpdateData(
            null,
            {
                instrument: order.Instrument,
                account: order.Account,
                side: order.BuySell,
                quantity: new Quantity(order.Amount, true),
                tif: new TIF(order.TimeInForce, new Date(order.ExpireAt)),
                order
            }
        ));
    }

    public getParameterNameArray (): string[] {
        const paramNameArr = super.getParameterNameArray();
        paramNameArr.push('boundTo');
        return paramNameArr;
    }

    public override dispose (): void {
        super.dispose();
        this.order = null;
    }

    // TODO. Refactor.
    public override setTradingData (tradingDataDict) {
        const newTradingDataDict = super.setTradingData(tradingDataDict);

        if (!newTradingDataDict) { return newTradingDataDict; };

        if ('order' in tradingDataDict) {
            this.order = tradingDataDict.order;
            newTradingDataDict.order = this.order;
        }

        return newTradingDataDict;
    }

    // TODO. Refactor base class, trading data. Ugly.
    public override getTradingData (): any {
        const tradingDataDict = super.getTradingData();
        tradingDataDict.order = this.order;
        return tradingDataDict;
    }

    // TODO. Refactor base class, trading data. Ugly.
    public override getDataForRequest (): any {
        const reqData = super.getDataForRequest();
        if (reqData) {
            reqData.order = this.order;
        }

        return reqData;
    }

    public override update_trailingStop (updateData): boolean {
        let parameterChanged = super.update_trailingStop(updateData);

        const newTradingDataDict = updateData.newTradingDataDict;
        if (!newTradingDataDict) {
            return parameterChanged;
        }

        const order = newTradingDataDict.order;
        if (!order) {
            return parameterChanged;
        }

        const newTicks = InstrumentUtils.getPriceDifferenceInTicks(
            order.Price,
            this.getQuotePriceForCalculatingOffset(),
            this.instrument);

        const newTrStopVal = OrderUtils.ConvertTickOffset(
            this.instrument,
            this.trailingStop.offsetViewMode,
            0,
            newTicks);

        if (newTrStopVal !== this.trailingStop.value) {
            this.trailingStop.value = newTrStopVal;
            parameterChanged = true || parameterChanged;
        }

        return parameterChanged;
    }

    public update_boundTo (updateData): boolean {
        return this.boundToEdit.update(updateData);
    }

    public toDynProperty_boundTo (): DynProperty {
        return this.boundToEdit.getDynProperty();
    }

    public toRawValue_boundTo (): string {
        return this.boundToEdit.getRawValue();
    }

    // TODO. UGLY. Refactor. details are at the top OrderEditBase.ts
    public setSLTP (sltpHolder): void {
        const dp = this.sltp.createDynPropertyForRawSLTP(
            sltpHolder,
            this.getTradingData(),
            this.sltpBasePriceGetter,
            this.sltpBasePriceGetter);

        this.updateParameters(new OrderEditUpdateData({ sltp: dp }));
    }

    public sltpBasePriceGetter (): number {
        return this.order ? this.order.Price : 0;
    }

    public getLeverageValue (): any {
        return this.order ? this.order.Leverage : null;
    }

    // TODO. Ugly. Duplication.
    public override getConfirmationText (onlyParams = false): string {
        const confrimationText = super.getConfirmationText();
        if (onlyParams) {
            return confrimationText;
        } else {
            return OrderExecutorUtils.getModifyOrderEditConfirmation(
                this.order,
                confrimationText
            );
        }
    }

    public override tradingAllowed (): boolean {
        return IsAllowed.IsOrderModifyingAllowed(this.order).Allowed;
    }
}
