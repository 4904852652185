// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type HistoryMergerInputParams } from '../../../../Utils/History/HistoryMergerInputParams';
import { Periods } from '../../../../Utils/History/TFInfo';
import { HistoryMergerBasedByDay } from './HistoryMergerBasedByDay';
import { type DateTime } from 'luxon';

export class HistoryMergerMonth extends HistoryMergerBasedByDay {
    constructor (inputParams: HistoryMergerInputParams) {
        super(inputParams);
    }

    protected override GetDaysInPeriod (dateTime: DateTime): number {
        let result = 0;

        let currentDate: DateTime = dateTime;
        for (let i = 0; i < this.GetCount(); i++) {
            result += currentDate.daysInMonth;
            currentDate = currentDate.plus({ months: 1 });
        }

        return result;
    };

    protected override IsStartPeriodDate (dateTime: DateTime): boolean {
        return dateTime.day === 1;
    };

    protected override GetBasePeriod (): number {
        return Periods.MONTH;
    }
}
