// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PriceType } from "../../../../../Utils/History/CashItemUtils.ts";
import { MAMode } from "../../IndicatorConstants.ts";
import { ExpandDoubleVector } from "../../DoubleMatrix.ts";
import { IndicatorFunctions } from "../../IndicatorFunctions.ts";
import { iBuildInIndicator } from "../../iBuildInIndicator.ts";


export let iBearsPower = function (maPeriod, priceType)
{
    iBuildInIndicator.call(this, 1)
    this.FMaPeriod = maPeriod
    this.FPriceType = priceType
    this.FEMA = new ExpandDoubleVector();
}

iBearsPower.prototype = Object.create(iBuildInIndicator.prototype)

Object.defineProperty(iBearsPower.prototype, 'Name',
    {
        get: function () { return 'iBearsPower' }
    })

Object.defineProperty(iBearsPower.prototype, 'Key',
    {
        get: function () { return this.DefaultKey + this.FMaPeriod + this.FPriceType }
    })

iBearsPower.prototype.Refresh = function (count, newThread)
{
    this.FEMA.Dispose()
    this.FEMA = new ExpandDoubleVector();
    iBuildInIndicator.prototype.Refresh.call(this, count, newThread)
}

iBearsPower.prototype.NextBar = function (callBound)
{
    this.FEMA.Add(0.0)
    iBuildInIndicator.prototype.NextBar.call(this, callBound)
}

iBearsPower.prototype.OnQuote = function (ci, callBound, callFromRefresh)
{
    let price = this.GetPrice(this.FPriceType, 0)
    this.FEMA[this.FEMA.Length - 1] = price;
    let Parent = this.Parent
    if (!Parent)
        return
    if (this.FMaPeriod > this.Count - 1)
        return;

    this.SetValue(this.GetPrice(PriceType.Low, 0) - IndicatorFunctions.CallMovingFunction(MAMode.EMA, this.FEMA, this.FMaPeriod, 1));

}