// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Rectangle } from "../../Commons/Geometry.ts";
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";
import { TerceraBaseWindowTemplate, ColorPickerScreenTemplate } from "../../templates.js";
import { ContainerControl } from "../elements/ContainerControl.js";
import { TerceraMenu } from "../elements/TerceraMenu.ts";
import { TerceraWindowBase } from "./TerceraWindowBase.js";

export let ColorPickerScreen = TerceraWindowBase.extend({
    template: TerceraBaseWindowTemplate,
    data: function ()
    {
        return {
            movable: false,
            showHeader: false,
            showFooter: false,
            resizable: false,
            focused: true
        };
    },
    partials: { bodyPartial: ColorPickerScreenTemplate }
});

ColorPickerScreen.ShowScreen = function (x, y, curColor, callBack)
{
    if (curColor === undefined)
        return;
    var a = new ColorPickerScreen();
    var r = new Rectangle(x, y, 300, 330);
    var newLoc = TerceraMenu.CorrectPopupLocation(r);
    a.setBounds(newLoc.newX, newLoc.newY, r.Width, r.Height);
    MainWindowManager.MainWindow.addControl(a);
    a.Controls.cpControl.setColor(curColor);
    a.callBack = callBack;
    a.Controls.cpControl.onMouseDown.Subscribe(a.runCallBack, a);
    a.setFocus();
};

ColorPickerScreen.prototype.lostFocus = function ()
{
    var controol = this.Controls.cpControl;
    if (controol)
    {
        if (controol.startValueA !== controol.valueA)
        {
            var newColor = controol.getColor();
            if (this.callBack)
                this.callBack(newColor);
        }
    }
    ContainerControl.prototype.lostFocus.apply(this);
    this.dispose();
};

ColorPickerScreen.prototype.runCallBack = function ()
{
    var newColor = this.Controls.cpControl.getColor();
    if (this.callBack)
        this.callBack(newColor);
    this.lostFocus();
};