// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

export enum ClientTypeEnum {
    WebDesktop = 15,
    WebInvesting = 25,
    WebMobile = 27,
    WebTvTradingPlatform = 28,
}

class _ClientType {
    private currentClientType = ClientTypeEnum.WebDesktop;

    public getClientType (): ClientTypeEnum {
        return this.currentClientType;
    }

    public setIsInvesting (): void {
        this.setClientType(ClientTypeEnum.WebInvesting);
    }

    public setIsWebMobile (): void {
        this.setClientType(ClientTypeEnum.WebMobile);
    }

    public setIsTvTradingPlatform (): void {
        this.setClientType(ClientTypeEnum.WebTvTradingPlatform);
    }

    private setClientType (clientType: ClientTypeEnum): void {
        this.currentClientType = clientType;
    }

    public IsWebDesktop (): boolean {
        return this.currentClientType === ClientTypeEnum.WebDesktop;
    }
}

export const ClientType = new _ClientType();
