// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectPortfolioModelMessage extends DirectMessageBaseClass {
    public ModelID: number | null = null; // PFixIntField FIELD_PORTFOLIO_MODEL_ID = new PFixIntField(728); - идентификатор модели
    public PortfolioSystemID: number | null = null; // PFixIntField FIELD_PORTFOLIO_SYSTEM_ID = new PFixIntField(727); - идентификатор портфолио системы, которой принадлежит модель
    public ModelName: string | null = null; // PFixStringField FIELD_NAME = new PFixStringField(22); - имя модели
    public Description: string | null = null; // PFixStringField FIELD_DESCRIPTION = new PFixStringField(28); - описание модели
    public DiagramPercent: number | null = null; // PFixDoubleField FIELD_PERCENT = new PFixDoubleField(729); - Cash. Здесь указывается доля средств, которая будет оставаться на балансе пользователя. Используем для отображения доли Cash на карточке

    public ByInstrumentPercent: Record<string, number | null> = {}; // группа с распределениями по инструмент ID

    constructor () {
        super(Message.CODE_PFIX_PORTFOLIO_MODEL_MESSAGE);
    }
}
