import { Md5 } from 'ts-md5';

export class WorkSpaceUserKey {
    private readonly Login: string = '';
    private readonly ConnectionName: string = '';
    private readonly UserStorrageType: WorkSpaceUserStorageType = WorkSpaceUserStorageType.TE;

    constructor (Login: string, ConnectionName: string, UserStorrageType: WorkSpaceUserStorageType = WorkSpaceUserStorageType.TE) {
        this.Login = Login;
        this.ConnectionName = ConnectionName;
        this.UserStorrageType = UserStorrageType;
    }

    public getKey (): string {
        return Md5.hashStr(`${this.Login}-${this.ConnectionName}-${this.UserStorrageType}`);
    }
}

export enum WorkSpaceUserStorageType {
    TE = 0,
    TVTradingPlatform = 1
}
