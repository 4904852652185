// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { type SolidBrush } from '../../../Commons/Graphics';

export class QuickTableCell {
    // Cell data
    public value: any = null;
    public formattedValue = '';

    public groupByValue = ''; // The context menu action "Group by" is processed using this value, e.g., #114165.

    // Colors
    public BackColor: string | null = null;
    public ForeColor: string | null = null;

    public BackColorBrush: SolidBrush | null = null;
    public ForeColorBrush: SolidBrush | null = null;

    public BackColorRecover: string | null = null;
    public ForeColorRecover: string | null = null;
    public isDirty = true;
    /// Произошла раскраска ячейки - через определленое время нужно сбросить цвет
    public ColoringOccured = -1;

    public textPattern: any = null;

    public CustomDrawingHandler: any = null;
    public CustomDrawingHandlerParameters: any = null;

    public TooltipKey = '';

    public TruncateTextOverflowAddingEllipsis = false; // нужно ли обрезать не влезающий в ячейку текст добавляя троеточие
    public QuickTableEditingInfo: any;
    public ReadOnly: boolean;
    public LastColoring: number;
    public FlagImg: any;
    public InstrumentTradingExchangeText: any;
    public InstrumentDescriptionText: string;
    public ToolTip: string;

    public xPadding: number = 0;
}
