// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts";
import { HistoryType } from "../../Utils/History/HistoryType.ts";
import { QuotePricePickerTemplate } from "../../templates.js";
import { ContainerControl } from "../elements/ContainerControl.js";
import { ThemeManager } from "../misc/ThemeManager.ts";
import { OperationType } from "../../Utils/Trading/OperationType.ts";

export let QuotePricePicker = ContainerControl.extend({
    template: QuotePricePickerTemplate,
    data: function ()
    {
        return {
            label: '',

            account: null,
            instrument: null,
            side: null,
            quote: null,

            price: null,

            fPrice: '',
            sideColor: ''
        }
    }
})

QuotePricePicker.prototype.getType = function () { return 'QuotePricePicker' };

QuotePricePicker.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.call(this)
    this.observe('instrument', this.onInstrumentChanged)
    this.observe('quote side account instrument', this.updatePrice)
    this.observe('price', this.onPriceChanged)
    this.observe('side', this.onSideChanged)
    this.on('click', this.pickPrice)
    Resources.onLocaleChange.Subscribe(this.localize, this)
    this.localize()
}

QuotePricePicker.prototype.dispose = function ()
{
    Resources.onLocaleChange.UnSubscribe(this.localize, this)
    this.unsubscribeQuotes(this.get('instrument'))

    ContainerControl.prototype.dispose.call(this)
}

QuotePricePicker.prototype.localize = function ()
{
    this.set('label', Resources.getResource('panel.orders.CurrentPrice'))
}

QuotePricePicker.prototype.onInstrumentChanged = function (newInstument, oldInstrument)
{
    if (!newInstument || newInstument === oldInstrument)
        return

    this.unsubscribeQuotes(oldInstrument)
    this.subscribeQuotes(newInstument)
}

QuotePricePicker.prototype.subscribeQuotes = function (instrument)
{
    if (!instrument)
        return

    // TODO. Refactor.
    this.newQuote(instrument.LastQuote)
    instrument.DataCache.FQuoteCache.addListener(instrument, this, HistoryType.QUOTE_LEVEL1)
}

QuotePricePicker.prototype.unsubscribeQuotes = function (instrument)
{
    if (!instrument)
        return

    // TODO. Refactor.
    instrument.DataCache.FQuoteCache.removeListener(instrument, this, HistoryType.QUOTE_LEVEL1)
    this.newQuote(null)
}

QuotePricePicker.prototype.newQuote = function (quote)
{
    if (quote && quote.Type === HistoryType.QUOTE_INSTRUMENT_DAY_BAR)
        return;
    this.set('quote', quote)
}

QuotePricePicker.prototype.updatePrice = function ()
{
    let ins = this.get('instrument')
    let acc = this.get('account')
    let side = this.get('side')
    let quote = this.get('quote')

    if (!quote || !ins || !acc)
    {
        this.set('price', null)
        return
    }

    let price = null
    if (side === OperationType.Buy)
        price = quote.AskSpread_SP_Ins(ins.DataCache.GetSpreadPlan(acc), ins)
    else if (side === OperationType.Sell)
        price = quote.BidSpread_SP_Ins(ins.DataCache.GetSpreadPlan(acc), ins)

    this.set('price', price)
}

QuotePricePicker.prototype.onSideChanged = function (side)
{
    let buy = side === OperationType.Buy
    this.set('sideColor', ThemeManager.CurrentTheme[buy ? 'SellColor' : 'BuyColor'])
}

QuotePricePicker.prototype.onPriceChanged = function (price)
{
    let ins = this.get('instrument')

    this.set(
        'fPrice',
        ins && price !== null
            ? ins.formatPrice(price)
            : Resources.getResource('general.N_A'))
}

QuotePricePicker.prototype.pickPrice = function ()
{
    this.fire('pickPrice', this.get('price'))
}