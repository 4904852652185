// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
/// <summary>
/// Рендерер для раздела Returns Portfolio Management-a 
/// </summary>

import { Resources } from "../../Commons/properties/Resources.ts";
import { Rectangle } from "../../Commons/Geometry.ts";
import { LayersEnum } from '../../Chart/Renderers/TerceraChartBaseRenderer.ts';
import { Color, LinearGradientBrush, Pen, PolyRect, SolidBrush } from "../../Commons/Graphics.ts";
import { TerceraChartDrawingType } from "../../Chart/Utils/ChartConstants.ts";
import { TerceraChartMainPriceRenderer } from '../../Chart/Renderers/TerceraChartMainPriceRenderer.ts';
import { CustomEvent } from "../../Utils/CustomEvents.ts";
import { ThemeManager } from "../../Controls/misc/ThemeManager.ts";
import { DynProperty } from "../../Commons/DynProperty.ts";
import { DataCache } from "../../Commons/DataCache.ts";



export let PortfolioChartReturnsRenderer = function (terceraChart)
{
//   TerceraChartMainPriceRenderer.call(this, terceraChart);
    this.terceraChart = terceraChart
    this.ChartDrawingType = TerceraChartDrawingType.Line;

    this.UseInProperties = false;
    this.Visible = false;

    this.UseInAutoscale = true;
    this.ThemeChanged(true);

    this.barsClusterHoveredIndex = null;        // индекс ховер совокупности баров по инструментам
    this.barsClusterSelectedIndex = null;       // выбранный индекс совокупности инструментов (выбран месяц)
    this.oneInstrumentBarHoveredIndex = null;   // если ховер по строке в таблице инструментов

    this.barsClusterHoveredIndexCached = null;  // запоминаем hover/selected index-ы чтобы вычислять изменения в них и менять стиль отрисовки
    this.barsClusterSelectedIndexCached = null;
    this.oneInstrumentBarHoveredIndexCached = null;

    this.allIns = null;                    // interiorID всех инструментов участвующих в портфолио
    this.skipBarsByIns = null;                  // кол-во баров (от начала) по инструментам которые мы не рисуем т.к. они не влазят в область видимости 

    this.barsClusterHoverIndexChanged = new CustomEvent();
    this.barsClusterSelectedIndexChanged = new CustomEvent();
    this.assignLayer = LayersEnum.Tools;
    this.SetClassName("PortfolioChartReturnsRenderer");

    this.assetTexts = null;
    this.assetFont = ThemeManager.Fonts.Font_12F_regular;
    this.assetColorGreen = null;                // цвет цифр на графике под столбцами при режиме Asset returns statistics
    this.assetColorRed = null;
};
PortfolioChartReturnsRenderer.prototype = Object.create(TerceraChartMainPriceRenderer.prototype);

Object.defineProperty(PortfolioChartReturnsRenderer.prototype, 'SolidPriceBrushColor', {
    get: function () { return this.solidPriceColorBrush.ColorStart; },
    set: function (value)
    {
        this.solidPriceColorBrush = new LinearGradientBrush(
            0, 0, 1, 1,
            Color.FromArgb(127, value),
            Color.FromArgb(0, value));
    }
});

PortfolioChartReturnsRenderer.prototype.Draw = function (gr, window, windowsContainer, advParams = null)
{
    if (!this.Visible)
        return;

    var clientRect = window.ClientRectangle;

    gr.save();
    gr.beginPath();
    gr.rect(clientRect.X, clientRect.Y, clientRect.Width, clientRect.Height);
    gr.clip();

    this.RecreateBarsIfNeed(window.PointsConverter)

    let allBars = this.allBars,
        hoveredBars = this.hoveredBars,
        selectedBars = this.selectedBars

    let allIns = this.allIns
    if (!allIns) return

    for (let i = 0; i < allIns.length; i++)
    {
        if (allBars[allIns[i]])
        {
            let someBarIsSelected = this.barsClusterSelectedIndex !== null,
                pen = someBarIsSelected ? this.pensWithOpacityStorage[i] : this.pensStorage[i],
                brush = someBarIsSelected ? this.brushesWithOpacityStorage[i] : this.brushesStorage[i]

            gr.DrawPolyRect(brush, allBars[allIns[i]], pen);
        }

        if (hoveredBars[allIns[i]])
        {
            let pen = this.pensStorage[i],
                brush = this.brushesWithOpacityStorage[i]

            gr.DrawPolyRect(brush, hoveredBars[allIns[i]], pen);
        }

        if (selectedBars[allIns[i]])
        {
            let pen = this.pensStorage[i],
                brush = this.brushesStorage[i]

            gr.DrawPolyRect(brush, selectedBars[allIns[i]], pen);
        }
    }

    if (this.terceraChart.PortfolioAssetReturnVisible)
        this.DrawAssetReturns(gr)

    gr.restore();
}


PortfolioChartReturnsRenderer.prototype.DrawAssetReturns = function (gr)
{
    if (this.terceraChart.mainWindow.CanZoomIn())
        return

    let data, w = this.terceraChart.w, h = this.terceraChart.h - 50
    for (let i = 0; i < this.assetTexts.length; i++)
    {
        data = this.assetTexts[i]
        gr.DrawString(data.text, this.assetFont, data.brush, data.x, data.y, 'center', 'middle');   // проценты assetReturns

        gr.DrawLine(data.linePen, data.lineX1, data.lineY, data.lineX1, data.y + 5);    // вертикальные линии - границы таблицы assetReturns
        gr.DrawLine(data.linePen, data.lineX2, data.lineY, data.lineX2, data.y + 5);
    }
    gr.DrawLine(data.linePen, 0, h, w, h);  // горизонтальная линия над процентами
}

PortfolioChartReturnsRenderer.prototype.PrepareBars = function (pConverter)
{
    var pCache = DataCache.PortfolioCache,
        data = pCache.instrumentReturnsByMonth,
        len = pCache.monthReturns ? pCache.monthReturns.length || 0 : 0

    if (!data) return

    if (!this.allIns)
        this.allIns = pCache.allInstrumentUsedInPortfolio

    let y0 = pConverter.GetScreenY(0),
        clientRectangleWidth = pConverter.ww.ClientRectangle.Width

    let barSpaceWidth = pConverter.GetScreenX(1) - pConverter.GetScreenX(0)

    let barSpacePadding = barSpaceWidth * 0.1

    barSpaceWidth -= barSpacePadding * 2

    let skipBarsByIns = {},
        allBars = {},
        hoveredBars = {},
        selectedBars = {};

    let assetTexts = [];

    for (let i = 0; i < len; i++)
    {
        let monthKey = pCache.GetKeyByMonthReturnsIndex(i)
        let insReturns = data[monthKey]

        if (!insReturns) continue

        let barSpaceX = pConverter.GetScreenX(i + 1) + barSpacePadding

        if (barSpaceX + barSpaceWidth < 0)
        {
            for (let j = 0; j < insReturns.length; j++)
            {
                let ins = insReturns[j],
                    key = ins.interiorID

                if (!skipBarsByIns[key])
                    skipBarsByIns[key] = 0

                skipBarsByIns[key]++
            }
            continue
        }


        if (barSpaceX > clientRectangleWidth)
            continue

        let barWidth = barSpaceWidth / insReturns.length,
            barPadding = 0.2 * barWidth

        let isHoveredBars = (i === this.barsClusterHoveredIndex);
        let isSelectedBars = (i === this.barsClusterSelectedIndex);

        for (let j = 0; j < insReturns.length; j++)
        {
            let ins = insReturns[j],
                key = ins.interiorID,
                x = barSpaceX + j * barWidth + barPadding,
                y = y0,
                h = pConverter.GetScreenY(ins.percent) - y,
                w = barWidth - barPadding

            let rect = new Rectangle(x, y, w, h)

            if (isSelectedBars && !isHoveredBars)
            {
                if (this.oneInstrumentBarHoveredIndex === key)
                    this.CreateOrAddToPolyRect(hoveredBars, key, rect)
                else
                    this.CreateOrAddToPolyRect(selectedBars, key, rect)
            }
            else if (!isHoveredBars)
                this.CreateOrAddToPolyRect(allBars, key, rect)
            else
                this.CreateOrAddToPolyRect(hoveredBars, key, rect)

            this.AddAssetText(assetTexts, rect, ins)
        }
    }

    this.allBars = allBars
    this.hoveredBars = hoveredBars
    this.selectedBars = selectedBars
    this.assetTexts = assetTexts
    this.skipBarsByIns = skipBarsByIns
}

PortfolioChartReturnsRenderer.prototype.AddAssetText = function (assetTexts, rect, data)
{
    if (!rect)
        return

    let value = data.percent,
        textColor = Color.White

    if (value > 0)
        textColor = this.assetColorGreen
    else
        if (value < 0)
            textColor = this.assetColorRed

    let brush
    if (!this.assetBrushes)
        this.assetBrushes = {}

    if (this.assetBrushes[textColor])
        brush = this.assetBrushes[textColor]
    else
    {
        brush = this.assetBrushes[textColor] = new SolidBrush(textColor)
    }

    if (!this.assetPen)
        this.assetPen = new Pen(ThemeManager.CurrentTheme.PortfolioReturnsChartAssetLine, 1)

    let result = {
        x: rect.X + rect.Width / 2,
        y: this.terceraChart.height - 42,
        brush: brush,   // for text
        text: data.Percent,

        lineX1: rect.X,
        lineX2: rect.X + rect.Width,
        lineY: Math.max(rect.Y, rect.Y + rect.Height),
        linePen: this.assetPen
    }

    assetTexts.push(result)
}

PortfolioChartReturnsRenderer.prototype.RecreateBarsIfNeed = function (converter)    // return true if bars were changed
{
    let newZeroX = converter.GetScreenX(0),
        newZeroY = converter.GetScreenY(0)

    if (this.zeroXCached !== newZeroX || this.zeroYCached !== newZeroY)     // поменялся масштаб графика или точка обзора - перестраиваем бары по новой
    {
        this.PrepareBars(converter)

        this.zeroXCached = newZeroX
        this.zeroYCached = newZeroY

        return true
    }

    let selectedClusterIndexChanged = this.barsClusterSelectedIndexCached !== this.barsClusterSelectedIndex,
        hoveredClusterIndexChanged = this.barsClusterHoveredIndexCached !== this.barsClusterHoveredIndex,
        hoveredOneInstrumentIndexChanged = this.oneInstrumentBarHoveredIndexCached !== this.oneInstrumentBarHoveredIndex,
        anyHoverSelectedIndexChanged = selectedClusterIndexChanged || hoveredClusterIndexChanged || hoveredOneInstrumentIndexChanged

    if (hoveredClusterIndexChanged)
    {
        this.HoveredClusterIndexChanged(this.barsClusterHoveredIndexCached, this.barsClusterHoveredIndex)

        this.barsClusterHoveredIndexCached = this.barsClusterHoveredIndex
    }

    if (selectedClusterIndexChanged)
    {
        this.barsClusterSelectedIndexCached = this.barsClusterSelectedIndex
        this.PrepareBars(converter)
    }

    if (hoveredOneInstrumentIndexChanged)
    {
        this.OneInstrumentHoveredIndexChanged(this.oneInstrumentBarHoveredIndexCached, this.oneInstrumentBarHoveredIndex)

        this.oneInstrumentBarHoveredIndexCached = this.oneInstrumentBarHoveredIndex
    }

    return anyHoverSelectedIndexChanged
}

PortfolioChartReturnsRenderer.prototype.CreateOrAddToPolyRect = function (barsObj, key, rect)
{
    if (!barsObj[key])
        barsObj[key] = new PolyRect();

    barsObj[key].rects.push(rect);
}

PortfolioChartReturnsRenderer.prototype.HoveredClusterIndexChanged = function (oldIndex, newIndex)
{
    var pCache = DataCache.PortfolioCache

    let insKey = null,
        selectedIndex = this.barsClusterSelectedIndex

    if (oldIndex !== null)
    {
        let monthKey = pCache.GetKeyByMonthReturnsIndex(oldIndex),
            realBarIndexes = pCache.instrumentRealBarIndexByMonth[monthKey],
            isSelected = pCache.GetKeyByMonthReturnsIndex(this.barsClusterSelectedIndex) === monthKey

        for (insKey in this.hoveredBars)
        {
            if (this.hoveredBars[insKey] === null) continue

            let skipBarN = this.skipBarsByIns[insKey] || 0

            if (isSelected)
                this.selectedBars[insKey] = this.hoveredBars[insKey]
            else
            {
                if (!this.allBars[insKey])
                    this.allBars[insKey] = new PolyRect()

                let indexToInsert = realBarIndexes[insKey] - skipBarN
                if (selectedIndex != null && oldIndex > selectedIndex && this.selectedBars[insKey])
                    indexToInsert--

                if (realBarIndexes[insKey] !== null)
                    this.allBars[insKey].rects.splice(indexToInsert, 0, this.hoveredBars[insKey].rects[0])
            }
        }

        this.hoveredBars = {}
    }

    if (newIndex !== null)
    {
        let monthKey = pCache.GetKeyByMonthReturnsIndex(newIndex),
            realBarIndexes = pCache.instrumentRealBarIndexByMonth[monthKey]

        if (selectedIndex === newIndex)
        {
            this.hoveredBars = this.selectedBars
            this.selectedBars = {}
            return
        }

        for (insKey in this.allBars)
        {
            let skipBarN = this.skipBarsByIns[insKey] || 0,
                realIndex = realBarIndexes[insKey]
            if (realIndex != null)
            {
                realIndex -= skipBarN

                if (selectedIndex != null && newIndex > selectedIndex && this.selectedBars[insKey])
                    realIndex--

                if (realIndex < 0) continue

                let rect = this.allBars[insKey].rects[realIndex]
                if (rect)
                {
                    this.hoveredBars[insKey] = new PolyRect()
                    this.hoveredBars[insKey].rects.push(rect)
                    this.allBars[insKey].rects.splice(realIndex, 1)
                }
            }
        }
    }
}

PortfolioChartReturnsRenderer.prototype.OneInstrumentHoveredIndexChanged = function (oldIndex, newIndex)
{
    let key = null

    if (oldIndex !== null)
    {
        for (key in this.hoveredBars)
            if (this.hoveredBars[key] != null)
                break

        this.selectedBars[key] = this.hoveredBars[key]

        this.hoveredBars[oldIndex] = null
    }

    if (newIndex !== null)
    {
        if (this.selectedBars[newIndex])
        {
            this.hoveredBars[newIndex] = this.selectedBars[newIndex]
            this.selectedBars[newIndex] = null
        }
        else
        {
            for (key in this.hoveredBars)
                if (key != newIndex)
                {
                    this.selectedBars[key] = this.hoveredBars[key]
                    this.hoveredBars[key] = null
                }
        }
    }
}

PortfolioChartReturnsRenderer.prototype.ProcessMouseDown = function (e)
{
    if (!this.Visible)
        return false;

    let barsClusterHoveredIndex = this.barsClusterHoveredIndex

    if (barsClusterHoveredIndex !== this.barsClusterSelectedIndex && barsClusterHoveredIndex !== null)
    {
        this.barsClusterSelectedIndex = barsClusterHoveredIndex
        this.barsClusterSelectedIndexChanged.Raise(barsClusterHoveredIndex)
    }
    else if (this.barsClusterSelectedIndex != null)
    {
        this.barsClusterSelectedIndex = null
        this.barsClusterSelectedIndexChanged.Raise(null)
    }

    return false;
}

PortfolioChartReturnsRenderer.prototype.ProcessMouseMove = function (e)
{
    if (!this.Visible)
        return false;

    let chart = this.terceraChart
    if (!chart || !chart.mainWindow) return false

    let pConverter = chart.mainWindow.PointsConverter
    if (!pConverter) return false

    let indexChanged = false,
        clientRect = chart.mainWindow.ClientRectangle,
        barsClusterHoveredIndex = Math.floor(pConverter.GetDataIndexByX(e.X)) - 1

    if (e.Y > clientRect.Height || e.X > clientRect.Width || barsClusterHoveredIndex < 0)
    {
        indexChanged = this.barsClusterHoveredIndex !== null
        this.barsClusterHoveredIndex = null
    }

    if (!indexChanged && barsClusterHoveredIndex >= 0)
    {
        let pCache = DataCache.PortfolioCache

        let key = pCache.GetKeyByMonthReturnsIndex(barsClusterHoveredIndex),
            minMaxObj = pCache.instrumentReturnsMinMaxByMonth[key],
            yData = pConverter.GetDataY(e.Y)        // значение процента (цены)

        if (!minMaxObj || yData > minMaxObj.max || yData < minMaxObj.min)   // примечание по первому условию: нету инструментов в кластере по ховер индексу - такой ховер нам не нужен, но старый снимем
        {
            indexChanged = this.barsClusterHoveredIndex !== null
            this.barsClusterHoveredIndex = null
        }
        else if (this.barsClusterHoveredIndex != barsClusterHoveredIndex)
        {
            this.barsClusterHoveredIndex = barsClusterHoveredIndex
            indexChanged = true
        }
    }

    if (indexChanged)
    {
        this.barsClusterHoverIndexChanged.Raise(this.barsClusterHoveredIndex)
        chart.IsDirty(this.assignLayer)
    }

    return false
}

PortfolioChartReturnsRenderer.prototype.ProcessMouseLeave = function (e)
{
    this.barsClusterHoveredIndex = null
    this.barsClusterHoverIndexChanged.Raise(this.barsClusterHoveredIndex)
}

PortfolioChartReturnsRenderer.prototype.CreatePensAndBrushes = function ()
{
    if (!DataCache || !DataCache.PortfolioCache) return

    let cache = DataCache.PortfolioCache,
        colors = cache.instrumentColor,
        colorsWithOpacity = cache.instrumentColorRGBA

    if (!colors || !colorsWithOpacity) return

    let pens = [], pensWithOpacity = [],
        brushes = [], brushesWithOpacity = []

    for (let i = 0; i < colors.length; i++)
    {
        let color = colors[i],
            colorWithOpacity = colorsWithOpacity[i]

        pens.push(new Pen(color))
        brushes.push(new SolidBrush(color))

        pensWithOpacity.push(new Pen(colorWithOpacity))
        brushesWithOpacity.push(new SolidBrush(colorWithOpacity))
    }

    this.pensStorage = pens
    this.pensWithOpacityStorage = pensWithOpacity
    this.brushesStorage = brushes
    this.brushesWithOpacityStorage = brushesWithOpacity
}

PortfolioChartReturnsRenderer.prototype.Properties = function ()
{
    var properties = [];

    if (!this.UseInProperties)
        return properties

    let prop = new DynProperty("AssetReturnVisibility", this.Visible, DynProperty.BOOLEAN, DynProperty.ASSET_RETURN_GROUP);
    prop.sortIndex = 0;
    properties.push(prop);

    prop = new DynProperty("FitMinMax", this.UseInAutoscale, DynProperty.BOOLEAN, DynProperty.PERCENT_SCALE_GROUP);
    prop.sortIndex = 1;
    prop.separatorGroup = "#1#" + Resources.getResource("property.SeparatorGroup.Zoom")
    properties.push(prop);

    return properties;
}

PortfolioChartReturnsRenderer.prototype.callBack = function (properties)
{
    if (!this.UseInProperties)
        return

    let p = DynProperty.getPropertyByName(properties, "AssetReturnVisibility");
    if (p) this.Visible = p.value

    p = DynProperty.getPropertyByName(properties, "FitMinMax");
    if (p) this.UseInAutoscale = p.value
}

PortfolioChartReturnsRenderer.prototype.ThemeChanged = function (apply) 
{
    let theme = ThemeManager.CurrentTheme

    this.infoWindowBackgroundBrush = new SolidBrush(theme.PortfolioReturnsChartInfoWindowColorDefault);

    this.assetColorGreen = theme.PortfolioReturnsChartTextGreen
    this.assetColorRed = theme.PortfolioReturnsChartTextRed

    this.CreatePensAndBrushes()
}

PortfolioChartReturnsRenderer.prototype.IsNeedDraw = function (numberOfLayer)
{
    return this.assignLayer === numberOfLayer;
}

PortfolioChartReturnsRenderer.prototype.FindMinMax = function (minMaxResult, window)
{
    let pCache = DataCache.PortfolioCache,
        minMaxByMonth = pCache.instrumentReturnsMinMaxByMonth,
        result = true

    minMaxResult.tMin = Number.MAX_VALUE;
    minMaxResult.tMax = -Number.MAX_VALUE;

    let from = Math.floor(window.i1 - window.im());
    let to = window.i1;

    for (let i = from; i <= to; i++)
    {
        let minMaxObj = minMaxByMonth[pCache.GetKeyByMonthReturnsIndex(i)]
        if (minMaxObj)
        {
            minMaxResult.tMin = Math.min(minMaxResult.tMin, minMaxObj.min)
            minMaxResult.tMax = Math.max(minMaxResult.tMax, minMaxObj.max)
        }
    }

    return result
}