// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DynProperty } from '../../../DynProperty';

export enum InputParameterType {
    STRING = DynProperty.STRING,
    INTEGER = DynProperty.INTEGER,
    DOUBLE = DynProperty.DOUBLE,
    COMBOBOX = DynProperty.COMBOBOX,
    BOOLEAN = DynProperty.BOOLEAN,
    INSTRUMENT = DynProperty.INSTRUMENT,
    ACCOUNT = DynProperty.ACCOUNT,
    SEPARATOR = DynProperty.GROUP_SEPARATOR,
    RANGECONTROL = DynProperty.RANGECONTROL
}
