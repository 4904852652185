// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from '../../Utils/CustomEvents';
import { Rectangle } from '../../Commons/Geometry';
import { MenuTemplate } from '../../templates.js';
import { Control } from './Control_ts';
import { ContainerControl } from './ContainerControl_ts';
import { BOTTOM_WINDOWS_MARGIN, TOP_WINDOWS_MARGIN } from '../UtilsClasses/SizeConstants';
import { contextMenuHandler } from '../../Utils/AppHandlers.js';
import { HtmlScroll } from '../../Commons/HtmlScroll.js';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import $ from 'jquery';

let maxHeigth = 300;

export class TerceraMenu extends ContainerControl {
    private static contextMenuContainer: HTMLElement;
    private callerControl: Control = null;

    public multiClick: boolean = false;
    public openedSubMenu: any = null;
    public parent: any = null;
    public scrollIsShown: boolean = false;
    public CloseMenu = new CustomEvent();
    protected _isShowed: boolean = false;

    get isShowed (): boolean {
        return this._isShowed;
    }

    set isShowed (value: boolean) {
        this._isShowed = value;
    }

    public isRender: boolean;

    protected menuHandler = contextMenuHandler;

    public getType (): string { return 'TerceraMenu'; }

    public oninit (): void {
        super.oninit();

        this.observe('menuitems', this.updateVisibleSeparatorsDict);
    }

    public updateVisibleSeparatorsDict (menuitems): void {
        const dict = TerceraMenu.getVisibleSeparatorsDict(menuitems);
        if (dict !== null && Object.keys(dict).length > 0) {
            void this.set('visibleSeparatorsDict', dict);
        }
    }

    // #region private service methods

    public static createTagDictionary (newValues): any {
        const res = {};
        TerceraMenu.processTagDictCreation(newValues, res);
        return res;
    }

    public static processTagDictCreation (items, resultArray): void {
        const len = items.length;
        for (let i = 0; i < len; i++) {
            const curItem = items[i];
            resultArray[curItem.tag] = curItem;
            if (curItem.subitems?.length > 0) {
                TerceraMenu.processTagDictCreation(curItem.subitems, resultArray);
            }
        }
    }

    //
    // ItemClick
    //
    public private_onItemClick (event, sender): void {
        if (!Control.IsEventProcessed(event)) {
            Control.ProcessEvent(event);
            if (sender.enabled !== undefined && !sender.enabled || sender.subitems && sender.subitems.length > 0) {
                return;
            }

            // TODO. Remove multiClick mechanism, leave only CloseOnClick.
            if ((sender.closeOnClick === undefined && !this.multiClick) || sender.closeOnClick) {
                this.menuHandler.Hide();
            }

            if (sender.canCheck) {
                let needChangeCheckState = true;
                if (this.get('isEditableListComboBoxMenu')) {
                    if (sender.tag == -1 || sender.checked) // cant check Create New li but same style as canCheck
                    {
                        needChangeCheckState = false;
                    }

                    const lastChecked = this.get('lastChecked');
                    if (needChangeCheckState && lastChecked && lastChecked.tag != sender.tag) // dont uncheck selected item if Create New clicked or lastChecked clicked
                    {
                        lastChecked.checked = false;
                    }
                }

                if (needChangeCheckState) {
                    sender.checked = !sender.checked;
                }

                void this.set('menuitems', this.get('menuitems'));

                void this.set({ lastChecked: sender });
            }

            // Make sure callback has been bound already to the specific context.
            const callback = sender.event;
            if (callback) {
                callback(sender);
            }
        }
    }

    public private_Hover (obj): void {
        void this.set('initialSelectedValue', null);

        if (obj.get('subitems')) {
            if (!obj.get('enabled')) {
                return;
            }

            if (!isNullOrUndefined(this.openedSubMenu)) {
                if (this.openedSubMenu.get('menuitems') === obj.get('subitems')) {
                    return;
                } else {
                    this.RemoveSubMenu(this);
                }
            }

            this.openedSubMenu = new TerceraMenu();

            const $li = $(obj.node);
            const $parent = $li.parents('.js-tercera-menu-container');
            const x = $parent.offset().left + $parent.outerWidth() - 1;
            const y = $li.offset().top - 1;
            this.openedSubMenu.parent = this;

            const advParams = obj.get('advParams') || {};

            const subitems = obj.get('subitems');
            const visibleSeparatorsDict = TerceraMenu.getVisibleSeparatorsDict(subitems);
            advParams.width = TerceraMenu.getItemsMaxWidth(subitems);
            advParams.visibleSeparatorsDict = visibleSeparatorsDict;

            this.openedSubMenu.Show(subitems, x, y, advParams);
        } else {
            this.RemoveSubMenu(this);
        }
    }

    public private_onSmallBtnClick (event, sender, key): void // on Editable List Small Btn Click
    {
        const callback = sender.smallEvent;
        if (callback) {
            callback(sender, key);
        }

        event.original.stopPropagation();
    }

    // #endregion

    // #region public instance methods

    //
    // Show menu new version
    //
    // in addParamsObj can contains
    // isMultiClick
    // width
    // isComboboxMenu
    // isEditableListComboBoxMenu
    public Show (items: any[], posX: number, posY: number, addParamsObj?): void {
        if (isNullOrUndefined(items)) {
            return;
        }

        void this.set({ menuitems: [] });

        if (addParamsObj === undefined) {
            addParamsObj = {};
        }

        const visibleSeparatorsDict =
        addParamsObj.visibleSeparatorsDict ||
        TerceraMenu.getVisibleSeparatorsDict(items);

        this.correctEnabledItemsState(items);

        const isComboboxMenu: boolean = addParamsObj.isComboboxMenu ? addParamsObj.isComboboxMenu : false;
        const isEditableListComboBoxMenu: boolean = addParamsObj.isEditableListComboBoxMenu ? addParamsObj.isEditableListComboBoxMenu : false;
        const isTemplateMenu: boolean = addParamsObj.isTemplateMenu;
        const newWidth = isComboboxMenu ? addParamsObj.width : addParamsObj.width + 15 || TerceraMenu.DEFAULT_WIDTH;

        const newHeight = TerceraMenu.getItemsMaxHeight(items, visibleSeparatorsDict, isComboboxMenu);
        const newCoord = TerceraMenu.CorrectPopupLocation(new Rectangle(
            posX,
            posY,
            newWidth,
            newHeight),
        this.parent);

        if (isEditableListComboBoxMenu) {
            for (let i = 0; i < items.length; i++) // lastChecked initialization for EditableListComboBox
            {
                if (items[i].checked) {
                    void this.set({ lastChecked: items[i] });
                }
            }

            const renameTT = isTemplateMenu ? 'panel.menu.renameTemplate' : 'panel.menu.renameList';
            const removeTT = isTemplateMenu ? 'panel.menu.removeTemplate' : 'panel.menu.removeList';

            void this.set({
                tooltipRenameBtn: renameTT,
                tooltipRemoveBtn: removeTT
            });
        }

        this.append = true;
        void this.set({
            left: newCoord.newX,
            top: newCoord.newY,
            menuitems: items,
            initialSelectedValue: addParamsObj.initialSelectedValue,
            width: newWidth,
            height: newHeight, /* #120201 */
            isComboboxMenu,
            isEditableListComboBoxMenu
        });

        if (!this.isRender) {
            void this.render(this.getMenuContainer());
            this.isRender = true;
        }
        this.isShowed = true;
        this.insert(this.getMenuContainer());
        this.multiClick = !!addParamsObj.isMultiClick;
        if (!isNullOrUndefined(this.callerControl)) {
            this.callerControl.gotFocus();
        }
        this.setFocus();
        void this.update();

        this.addScroll(newHeight, isComboboxMenu);
    }

    public addScroll (height: number, isComboboxMenu = false): void {
        let max = maxHeigth;
        if (isComboboxMenu) {
            max = TerceraMenu.MAX_HEIGHT;
        }

        if (height < max && !this.scrollIsShown) return;

        this.scrollIsShown = true;
        const ul = this.find('ul');
        const scroll = HtmlScroll.addScroll(ul);
        scroll.mCustomScrollbar('scrollTo', '#initialSelectedValue');
    }

    // обновляет enable для родителя если все дети не активны
    public correctEnabledItemsState (items: any[]): boolean {
        const len = items.length;
        let result = false;
        for (let i = 0; i < len; i++) {
            result = items[i].enabled || result;
            if (!isNullOrUndefined(items[i].subitems)) {
                items[i].enabled = this.correctEnabledItemsState(items[i].subitems);
            }
        }
        return !!result;
    }

    // Hide menu
    public Hide (): void {
        this.RemoveSubMenu(this);
        this.isShowed = false;

        if (this.scrollIsShown) {
            HtmlScroll.removeScroll(this.find('ul'));
            this.scrollIsShown = false;
        }

        this.lostFocus();
        this.detach();
        if (!isNullOrUndefined(this.callerControl)) {
            this.callerControl.lostFocus();
            this.callerControl = null;
        }

        this.CloseMenu.Raise(this);
    }

    public RemoveSubMenu (menu): void {
        if (!isNullOrUndefined(menu.openedSubMenu)) {
            menu.openedSubMenu.Hide();
            menu.openedSubMenu.dispose();
            menu.openedSubMenu = null;
        }
    }

    // Caller Control
    public SetCallerControl (control: Control): void {
        this.callerControl = control;
    }
    // #endregion

    public static getItemsMaxWidth (items: any[]): number {
        // let visibleMenuItemsCount = 0;
        let width = 0;
        const len = items.length;
        for (let i = 0; i < len; i++) {
            const item = items[i];
            if (item.separator || item.visible === false) {
                continue;
            }

            // visibleMenuItemsCount++;

            const text = items[i].text;
            if (!isValidString(text)) continue;

            const koef = items[i].hasImages ? 12 : 6;

            width = Math.max(width, text.length * koef);
        }

        width += TerceraMenu.LEFT_IMAGE_WIDTH + TerceraMenu.SUBITEM_IMAGE_WIDTH;

        return width;
    }

    public static getItemsMaxHeight (items: any[], visibleSeparatorsDict, isComboboxMenu = false): number {
        let visibleMenuItemsCount = 0;
        const len = items.length;
        for (let i = 0; i < len; i++) {
            const item = items[i];
            if (item.separator || item.visible === false) {
                continue;
            }

            visibleMenuItemsCount++;
        }

        let height =
        visibleMenuItemsCount * TerceraMenu.DEFAULT_ROW_HEIGHT +
        TerceraMenu.BORDER_WIDTH * 2;

        if (!isNullOrUndefined(visibleSeparatorsDict)) {
            const visibleSeparatorsLen = Object.keys(visibleSeparatorsDict).length;
            height += visibleSeparatorsLen * TerceraMenu.SEPARATOR_HEIGHT;
        }

        maxHeigth = MainWindowManager.MainWindow.height - TOP_WINDOWS_MARGIN - BOTTOM_WINDOWS_MARGIN;

        if (isComboboxMenu) {
            return Math.min(TerceraMenu.MAX_HEIGHT, height);
        }

        return Math.min(maxHeigth, height);
    }

    // TODO. Common method for TerceraToolStrip and TerceraMenu.
    public static getVisibleSeparatorsDict (menuitems: any[]): any {
        const visibleSeparatorsDict = {};
        if ((menuitems?.length) === 0) {
            return visibleSeparatorsDict;
        }

        let lastVisibleItemIdx;
        let lastSeparatorIdx;
        const len = menuitems.length;
        for (let i = 0; i < len; i++) {
            const item = menuitems[i];
            if (item.separator) {
                if ((lastVisibleItemIdx !== undefined && lastSeparatorIdx === undefined) ||
                (lastVisibleItemIdx > lastSeparatorIdx && lastVisibleItemIdx < i)) {
                    visibleSeparatorsDict[i] = true;
                    lastSeparatorIdx = i;
                }
            } else if (item.visible === undefined || item.visible) {
                lastVisibleItemIdx = i;
            }
        }
        if (lastSeparatorIdx > lastVisibleItemIdx) {
            delete visibleSeparatorsDict[lastSeparatorIdx];
        }

        return visibleSeparatorsDict;
    }

    public static CorrectPopupLocation (bounds: Rectangle, parent?): { newX: number, newY: number } {
        let X = bounds.X;
        let Y = bounds.Y;
        const screenB = window.innerHeight - BOTTOM_WINDOWS_MARGIN;
        let screenR = window.innerWidth;
        const screenRect = new Rectangle(0, 0, screenR, screenB);
        let bR = bounds.X + bounds.Width;
        let bB = bounds.Y + bounds.Height;
        if (!screenRect.Contains(bR, bB)) {
        // need to move our rectangle
            if (bR > screenR) {
                X -= bR - screenR;
                if (!isNullOrUndefined(parent)) {
                    screenR = parent.get('left');
                }
            }
            if (bounds.X < 0) {
                X = 0;
            }
            if (bB > screenB) {
                Y -= bB - screenB;
            }
            if (Y < 0) {
                Y = 0;
            }
        }

        if (!isNullOrUndefined(parent)) {
            bR = X + bounds.Width;
            bB = Y + bounds.Height;

            // need to move our rectangle
            if (bR > screenR) {
                X -= bR - screenR;
            }
            if (bounds.X < 0) {
                X = 0;
            }
            if (bB > screenB) {
                Y -= bB - screenB;
            }
            if (Y < 0) {
                Y = 0;
            }
        }

        return { newX: X, newY: Y };
    }

    public static readonly MAX_HEIGHT = 302;
    public static readonly DEFAULT_ROW_HEIGHT = 25;
    public static readonly SEPARATOR_HEIGHT = 2;
    public static readonly DEFAULT_WIDTH = 200;
    public static readonly SCROLL_WIDTH = 10;
    public static readonly LEFT_IMAGE_WIDTH = 27;
    public static readonly SUBITEM_IMAGE_WIDTH = 20;
    public static readonly BORDER_WIDTH = 1;

    public getMenuContainer (): any {
        if (isNullOrUndefined(TerceraMenu.contextMenuContainer)) {
            TerceraMenu.contextMenuContainer = document.getElementById('contextMenuContainer');
        }

        return TerceraMenu.contextMenuContainer;
    }

    public static ContextMenuHandlerInitialize (contextMenu = contextMenuHandler, _contextMenu = new TerceraMenu()): void {
        contextMenu.setHandler(_contextMenu);
        contextMenu.Show = _contextMenu.Show.bind(_contextMenu);
        contextMenu.Hide = _contextMenu.Hide.bind(_contextMenu);
        contextMenu.SetCallerControl = _contextMenu.SetCallerControl.bind(_contextMenu);
        contextMenu.CloseMenu = _contextMenu.CloseMenu;
        contextMenu.on = _contextMenu.on.bind(_contextMenu);
        contextMenu.off = _contextMenu.off.bind(_contextMenu);

        contextMenu.isShowed = () => { return _contextMenu.isShowed; };
    }
}

ContainerControl.extendWith(TerceraMenu, {
    template: MenuTemplate,
    data: function () {
        return {
            initialSelectedValue: null,
            menuitems: [],
            visibleSeparatorsDict: {},
            isComboboxMenu: false,
            isEditableListComboBoxMenu: false,
            lastChecked: null,
            tooltipRenameBtn: 'panel.menu.renameList',
            tooltipRemoveBtn: 'panel.menu.removeList',
            tooltipSaveBtn: 'panel.menu.saveList'
        };
    }
});

declare global{
    interface Window {
        contextMenu: any
    }
}

// window HACK
window.contextMenu = contextMenuHandler;
