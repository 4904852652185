// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../../Commons/properties/Resources.ts";
import { TerceraInstrumentLookupTextBoxTemplate } from "../../../templates.js";
import { PanelNames } from "../../UtilsClasses/FactoryConstants.ts";
import { TerceraTextBox } from "../TerceraTextBox.js";
import { CustomerAccess } from "../../../Commons/CustomerAccess/CustomerAccess.ts";
import { MainWindowManager } from "../../UtilsClasses/MainWindowManager.ts";
import { InstrumentLookupManager } from "./InstrumentLookupManager.js";
export let TerceraInstrumentLookupTextBox = TerceraTextBox.extend({
    template: TerceraInstrumentLookupTextBoxTemplate,
    data: function ()
    {
        return {
            instrTypeImgConst: 0,
            instrTypeSpriteID: '',
            currentInstrument: null,
            selectedAccount: null,
            showInsTypeImg: false,
            hasDelay: false,    //#112155
            delayedTooltip: '',
            insTypeImgTT: ''
        };
    },
    symbolInfoAllowed: false
});

TerceraInstrumentLookupTextBox.prototype.getType = function () { return "TerceraInstrumentLookupTextBox" };

TerceraInstrumentLookupTextBox.prototype.oninit = function ()
{
    TerceraTextBox.prototype.oninit.apply(this);

    this.on('onInsTypeImgMouseDown', this.onInsTypeImgMouseDown);
    this.observe('currentInstrument', this.onInstrumentChanged);
    this.observe('instrTypeImgConst', this.onInstrTypeImgConstChanged);
    Resources.onLocaleChange.Subscribe(this.localize, this);
    this.localize();

    this.symbolInfoAllowed = CustomerAccess.panelAllowed(PanelNames.SymbolInfoPanel);
}

TerceraInstrumentLookupTextBox.prototype.localize = function ()
{
    this.set('insTypeImgTT', Resources.getResource('TerceraInstrumentLookup.InstrTypeImg.tt'));

    this.updateDelayTooltip()
}

TerceraInstrumentLookupTextBox.prototype.dispose = function ()
{
    Resources.onLocaleChange.UnSubscribe(this.localize, this);

    TerceraTextBox.prototype.dispose.apply(this);
}

TerceraInstrumentLookupTextBox.prototype.onInsTypeImgMouseDown = function ()
{
    const instrument = this.get('currentInstrument');
    if (!instrument) return;

    this.set('isSymbolInfoOpened', true);
    const onSymbolInfoCloseCallback = () => { 
        this.set('isSymbolInfoOpened', false); 
    }
    MainWindowManager.MainWindow.ShowSymbolInfo(instrument, this.get('selectedAccount'), onSymbolInfoCloseCallback);
}

TerceraInstrumentLookupTextBox.prototype.onInstrumentChanged = function (instrument, lastInstrument)
{
    if (instrument != lastInstrument)
        this.subscribeRiskSettingsUpdate(instrument, lastInstrument);

    this.updateDelayTooltip();

    this.set('showInsTypeImg', this.symbolInfoAllowed && instrument?.isHide == null);
}

TerceraInstrumentLookupTextBox.prototype.updateDelayTooltip = function ()
{
    let instrument = this.get('currentInstrument')
    if (!instrument || instrument.isHide) 
        return

    this.set({
        hasDelay: instrument.HasDelay(),
        delayedTooltip: instrument.GetDelayedIconTooltip()
    })
}

TerceraInstrumentLookupTextBox.prototype.subscribeRiskSettingsUpdate = function (instrument, lastInstrument)
{
    if (lastInstrument && !lastInstrument.isHide)
        lastInstrument.RiskSettingsUpdated.UnSubscribe(this.instrumentSettingsUpdate, this)

    if (instrument && !instrument.IsEmpty && !instrument.isHide)
        instrument.RiskSettingsUpdated.Subscribe(this.instrumentSettingsUpdate, this)
}

TerceraInstrumentLookupTextBox.prototype.instrumentSettingsUpdate = function ()
{
    let instrument = this.get('currentInstrument')
    if (!instrument)
        return

    this.onInstrumentChanged(instrument, instrument)    // for hasDelay/delayedTooltip update
}

TerceraInstrumentLookupTextBox.prototype.onInstrTypeImgConstChanged = function (value)
{
    if (value > 0) {
        const imgIdInSprite = InstrumentLookupManager.getInstrumentTypeImageFileName(value, this.get('currentInstrument').CFD);
        if (imgIdInSprite) {
            this.set('instrTypeSpriteID', imgIdInSprite); 
        }
    }
}