import { PanelNames } from '../../UtilsClasses/FactoryConstants';
import { type OptionTrader } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionTrader';
import { OrdersPanel } from '../OrdersPanel';

export class OptionOrdersPanel extends OrdersPanel {
    private _optionTrader: OptionTrader;

    constructor () {
        super();
        this.Name = 'OptionOrdersPanel';
        this.headerLocaleKey = 'panel.optionOrders';
        this.AddOrderEvent = this.AddOrderEvent.bind(this);
        this.RemoveOrderEvent = this.RemoveOrderEvent.bind(this);
        this.populateItemsDirect = this.populateItemsDirect.bind(this);
    }

    public override oninit (): void {
        void super.set('isRelativeStyle', true);
        void super.set('isAllowPadding', false);
        super.observe('visible', this.onVisibleChanged);
    }

    public override getType (): PanelNames { return PanelNames.OptionOrdersPanel; }

    public override repopulate (): void {
        super.repopulate();
        this.populateItemsDirect();
    }

    public override TickAsync (): void {
        super.TickAsync();
        this.getQuickTable().needRedrawBackground = true;
    }

    public override populateItemsDirect (): void {
        const quickTable = this.getQuickTable();
        quickTable.ClearAll();
        if (isNullOrUndefined(quickTable)) {
            return;
        }
        if (isNullOrUndefined(this._optionTrader)) {
            return;
        }
        const orders = this._optionTrader.orders;
        for (let i = 0; i < orders.length; i++) {
            this.AddOrderEvent(orders[i]);
        }
    }

    public setOptionTrader (optionTrader: OptionTrader): void {
        this._optionTrader = optionTrader;
        this.DCEventsSubscribe();
    }

    public override DCEventsSubscribe (): void {
        if (isNullOrUndefined(this._optionTrader)) {
            return;
        }
        this._optionTrader.subscribeOnAddOrder(this.AddOrderEvent);
        this._optionTrader.subscribeOnRemoveOrder(this.RemoveOrderEvent);
        this._optionTrader.subscribeOnInstrumentChanged(this.populateItemsDirect);
        this._optionTrader.subscribeOnAccountChanged(this.populateItemsDirect);
    }

    public override DCEventsUnSubscribe (): void {
        if (isNullOrUndefined(this._optionTrader)) {
            return;
        }
        this._optionTrader.unsubscribeOnAddOrder(this.AddOrderEvent);
        this._optionTrader.unsubscribeOnAddOrder(this.RemoveOrderEvent);
        this._optionTrader.unsubscribeOnInstrumentChanged(this.populateItemsDirect);
        this._optionTrader.unsubscribeOnAccountChanged(this.populateItemsDirect);
    }

    private onVisibleChanged (): void {
        this.layoutTable();
    }
}
