// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PriceType } from "../../../../../Utils/History/CashItemUtils.ts";
import { MAMode } from "../../IndicatorConstants.ts";
import { ExpandDoubleVector } from "../../DoubleMatrix.ts";
import { IndicatorFunctions } from "../../IndicatorFunctions.ts";
import { iBuildInIndicator } from "../../iBuildInIndicator.ts";

export let iDeMarker = function (maPeriod)
{
    iBuildInIndicator.call(this, 1)
    this.FMaPeriod = maPeriod
    this.FDeMax = new ExpandDoubleVector();
    this.FDeMin = new ExpandDoubleVector();
}
iDeMarker.prototype = Object.create(iBuildInIndicator.prototype)

Object.defineProperty(iDeMarker.prototype, 'Name',
    {
        get: function () { return 'iDeMarker' }
    })

Object.defineProperty(iDeMarker.prototype, 'Key',
    {
        get: function () { return this.DefaultKey + this.FMFIPeriod }
    })

iDeMarker.prototype.NextBar = function (callBound)
{
    iBuildInIndicator.prototype.NextBar.call(this, callBound)
    this.FDeMax.Add(0.0);
    this.FDeMin.Add(0.0);
}

iDeMarker.prototype.OnQuote = function (ci, callBound, callFromRefresh)
{
    this.FDeMax[this.FDeMax.Length - 1] = 0;
    this.FDeMin[this.FDeMin.Length - 1] = 0;
    let Parent = this.Parent
    if (!Parent && this.FCount == 1)
        return

    let deMax = this.GetPrice(PriceType.High, 0) - this.GetPrice(PriceType.High, 1);
    this.FDeMax[this.FDeMax.Length - 1] = deMax > 0.0 ? deMax : 0.0;

    let deMin = this.GetPrice(PriceType.Low, 1) - this.GetPrice(PriceType.Low, 0);
    this.FDeMin[this.FDeMin.Length - 1] = deMin > 0.0 ? deMin : 0.0;
    if (this.FCount >= this.FMaPeriod)
    {
        let smaDeMax = IndicatorFunctions.CallMovingFunction(MAMode.SMA, this.FDeMax, this.FMaPeriod, 1);
        let smaDeMin = IndicatorFunctions.CallMovingFunction(MAMode.SMA, this.FDeMin, this.FMaPeriod, 1);
        let sumDeMax = smaDeMax + smaDeMin;
        if (sumDeMax != 0.0)
            this.SetValue(smaDeMax / sumDeMax);
    }
}
iDeMarker.prototype.Refresh = function (count, newThread)
{
    this.FDeMax.Dispose();
    this.FDeMin.Dispose();
    this.FDeMax = new ExpandDoubleVector();
    this.FDeMin = new ExpandDoubleVector();
    iBuildInIndicator.prototype.Refresh.call(this, count, newThread)
}