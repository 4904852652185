// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts";
import { popupErrorHandler } from "../../Utils/AppHandlers.js";
import { DPProductTypeAndModifyQuantityTemplate } from "../../templates.js";
import { DynPropertyControl } from "../elements/DynPropertyControl.js";

export let DPProductTypeAndModifyQuantity = DynPropertyControl.extend({
    template: DPProductTypeAndModifyQuantityTemplate,
    data: function ()
    {
        return {
            prTypeLabel: "Target product type",
            mqLabel: "Modify Quantity",
            MQNumericLink: null,
            valueGreaterMaxLocalKey: "general.trading.qtyMoreTodayTradedValue",
            valueLessMinLocalKey: "UserControl.TerceraNumeric.ValueLessMin"
        }
    }
})
DPProductTypeAndModifyQuantity.prototype.getType = function () { return 'DPProductTypeAndModifyQuantity' };

DPProductTypeAndModifyQuantity.prototype.oninit = function ()
{
    DynPropertyControl.prototype.oninit.call(this)
    this.on('terceraNumericInputClick', this.onInputClick)
}

DPProductTypeAndModifyQuantity.prototype.oncomplete = function () 
{
    DynPropertyControl.prototype.oncomplete.call(this)
    this.set("MQNumericLink", this.Controls.MQNumeric);
    this.observe('dp.customLess', (n, o) =>
    {
        let key = n ? "general.trading.noQuantityAvailable" : "UserControl.TerceraNumeric.ValueLessMin";
        this.set("valueLessMinLocalKey", key).then(() =>
        {
            if (this.Controls.MQNumeric)
                this.Controls.MQNumeric.onNeedUpdateValidationStateInfo()
        })

    })
}

DPProductTypeAndModifyQuantity.prototype.lostFocus = function ()
{
    if (this.Controls.MQNumeric)
        if (popupErrorHandler.isShowed())
            popupErrorHandler.Hide(this.Controls.MQNumeric);
    DynPropertyControl.prototype.lostFocus.apply(this);
};

DPProductTypeAndModifyQuantity.prototype.onInputClick = function ()
{
    if (!this.get('dp.enabled'))
        this.set('dp.enabled', true)
}

DPProductTypeAndModifyQuantity.prototype.localize = function ()
{
    let locKey_t = this.get('dp.localizationKey_type')
    let locKey_q = this.get('dp.localizationKey_qty')
    this.set({ prTypeLabel: Resources.getResource(locKey_t), mqLabel: Resources.getResource(locKey_q) })
}