// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts"
import { OrderInfoViewTemplate } from "../../templates.js"
import { Control } from "./Control.js"
import { OperationType } from "../../Utils/Trading/OperationType.ts"
import { ThemeManager } from "../misc/ThemeManager.ts"
import { DataCache } from "../../Commons/DataCache.ts"
import { SessionSettings } from "../../Commons/SessionSettings.ts"
import { OrderUtils } from "../../Utils/Trading/OrderUtils.ts"

export let OrderInfoView = Control.extend({
    template: OrderInfoViewTemplate,
    data: function ()
    {
        return {
            order: null,
            showRoute: false,

            sideColor: '',
            orderIdLabel: '',
            fOrderId: '',
            instrumentLabel: '',
            fInstrumentName: '',
            routeLabel: '',
            fRoute: '',
            orderTypeLabel: '',
            fOrderType: '',
            sideLabel: '',
            fSide: '',
            leverageLabel: '',
            fLeverage: '',
        }
    }
})

OrderInfoView.prototype.oninit = function ()
{
    Control.prototype.oninit.call(this)
    this.observe('order', this.updateFields)
    Resources.onLocaleChange.Subscribe(this.localize, this)
    this.localize()
}

OrderInfoView.prototype.dispose = function ()
{
    Resources.onLocaleChange.UnSubscribe(this.localize, this)

    Control.prototype.dispose.call(this)
}

OrderInfoView.prototype.updateFields = function (order)
{
    if (!order) return

    let sess = SessionSettings
    let ins = order.Instrument
    let buy = order.BuySell === OperationType.Buy

    let orderTypeDict = DataCache.OrderParameterContainer.OrderTypes
    let orderType = orderTypeDict[order.OrderType]

    this.set({
        showRoute: !ins.isHideRouteMode,
        fOrderId: order.OrderNumber,
        fSide: Resources.getResource('general.trading.' + (buy ? 'Buy' : 'Sell')),
        fLeverage: OrderUtils.GetFormattedLeverage(order.Leverage),
        fInstrumentName: ins.DisplayName(),
        fRoute: ins.getRoute(),
        fOrderType: Resources.getResource(orderType.localizationKey()),
        sideColor: ThemeManager.CurrentTheme[buy ? 'BuyColor' : 'SellColor'],
        height: (ins.isHideRouteMode ? 80 : 100) + (order.Leverage ? 20 : 0)
    })
}

OrderInfoView.prototype.localize = function ()
{
    this.set({
        orderIdLabel: Resources.getResource('screen.modifyOrder.orderNumber'),
        instrumentLabel: Resources.getResource('screen.modifyOrder.instrument'),
        routeLabel: Resources.getResource('screen.modifyOrder.route'),
        leverageLabel: Resources.getResource('screen.modifyOrder.leverage'),
        sideLabel: Resources.getResource('screen.modifyOrder.operation'),
        orderTypeLabel: Resources.getResource('screen.modifyOrder.ordertype'),
    })

    this.updateFields(this.get('order'))
}