import { type PartialInterval } from './PartialInterval';
import { PartialIntervalCalculator } from './PartialIntervalCalculator';

export class PartialIntervalManager {
    private minColor: string = '#D50000';
    private midColor: string = '#BFBFBF';
    private maxColor: string = '#008BFF';
    private stepMultiplier: number = 1.0;
    private partialIntervals: PartialInterval[] = [];

    public get (index: number): PartialInterval {
        return this.partialIntervals[index];
    }

    public get count (): number {
        return this.partialIntervals.length;
    }

    public setStepMultiplier (stepMultiplier: number): void {
        this.stepMultiplier = stepMultiplier;
    }

    public populateIntervals (): void {
        this.partialIntervals = PartialIntervalCalculator.recalculate(this.minColor, this.midColor, this.maxColor, this.stepMultiplier);
    }

    public applyColors (minColor: string, midColor: string, maxColor: string): void {
        this.minColor = minColor;
        this.midColor = midColor;
        this.maxColor = maxColor;

        PartialIntervalCalculator.populateColors(this.partialIntervals, this.minColor, this.midColor, this.maxColor);
    }

    public getStepMultiplier (): number {
        return this.stepMultiplier;
    }

    public getIntervals (): PartialInterval[] {
        return this.partialIntervals;
    }

    public getSelectedIntervals (): PartialInterval[] {
        return this.partialIntervals.filter(x => x.IsSelected);
    }

    getDefaultColor (): string { return this.midColor; }
}
