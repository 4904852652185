// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { TerceraChartCashItemSeriesDataType } from '../Series/TerceraChartCashItemSeriesEnums';
import { ToolsCache } from '../../Commons/cache/ToolsCache';
import { CancelActionContext } from '../TerceraChartAction';
import { ScreenPointsConverterFactory } from '../Utils/IScreenPointsConverter';
import { type TerceraChart } from '../TerceraChart';

/// <summary>
/// Создание тулзовин кликами
/// </summary>
export class NewToolClickStrategy {
    public ToolBuilder: any;
    public Points = [];
    public chart: TerceraChart;
    public screenPoinsConverter;
    public ToolEditing;
    public currentTool;
    public toolWindow;
    public CurrentType;

    constructor (toolBuilder, chart: TerceraChart) {
    // билдер тулз
        this.ToolBuilder = toolBuilder;
        this.ApplyNewSnapMode(false);
        // кеш точек

        this.chart = chart;
    }

    public Finished (): boolean {
        return this.Points.length === this.currentTool.MaxPointsCount();
    }

    public Clear (): void {
        this.Points = [];
        this.toolWindow = null;
        this.currentTool = null;
    }

    /// <summary>
    /// Добавить готовую тлзу в кеш, начать создавать новую
    /// </summary>
    public Finish (): boolean {
        if (this.IsNeedToEdit()) {
            return false;
        }

        this.AddCompletedTool();
        return true;
    }

    public AddCompletedTool (): void {
        if (this.currentTool == null) {
            return;
        }

        this.currentTool.completed = true;

        const windowToolsRenderer = this.toolWindow != null ? this.toolWindow.GetMainThreadRenderer('TerceraChartToolInstanceRenderer') : null;
        if (windowToolsRenderer != null) {
            // локальная тулза
            windowToolsRenderer.AddTool(this.currentTool);
        } else {
            // тулза в кеше
            ToolsCache.AddTool(this.currentTool);
        }
        this.Clear();
    }

    public IsNeedToEdit (): boolean {
        const newToolRenderer = this.toolWindow != null ? this.toolWindow.GetMainThreadRenderer('TerceraChartNewToolRenderer') : null;
        if (newToolRenderer == null) {
            return false;
        }

        const view = newToolRenderer.ToolView();
        const editableTool = view;

        if (editableTool != null) {
            if (this.toolWindow != null) {
                view.UpdateScreenPoints(this.toolWindow, this.chart.MainCashItemSeries);
            }
            this.ToolEditing = true;
        }

        return false;
    }

    public ToolEdited (): void {
        if (this.ToolEditing) {
            this.ToolEditing = false;
            this.AddCompletedTool();
            this.GetNextTool();
        }
    }

    /// <summary>
    /// Модификация координт тулзы
    /// </summary>
    /// <param name="window"></param>
    /// <param name="clickPoint"></param>
    /// <param name="offset"></param>
    public InitTool (window, clickPoint, offset: number): void {
        // добавить новые точки к динамически расширямому полигону
        this.AddNewPoint(offset);

        const dataType = window == this.chart.mainWindow ? this.chart.cashItemSeriesSettings.DataType : TerceraChartCashItemSeriesDataType.Absolute;

        for (let i = offset; i < this.currentTool.PointLevel(); i++) {
            const point = this.currentTool.Points[i];

            let x = clickPoint.X;
            let y = clickPoint.Y;
            const refXY = { screenX: x, screenY: y };
            this.screenPoinsConverter.Convert(window, refXY);
            x = refXY.screenX;
            y = refXY.screenY;

            let price = window.PointsConverter.GetDataY(y);
            switch (dataType) {
            case TerceraChartCashItemSeriesDataType.Relative:
                price = this.chart.cashItemSeriesSettings.relativeDataConverter.Revert(price);
                break;
            case TerceraChartCashItemSeriesDataType.Log:
                price = this.chart.cashItemSeriesSettings.logDataConverter.Revert(price);
                break;
            }

            point[0] = window.PointsConverter.GetDataX(x);
            point[1] = price;
        }
    }

    public AddNewPoint (offset: number): void {
        if (offset == this.currentTool.PointLevel() && (offset + 1) < this.currentTool.MaxPointsCount()) {
            this.currentTool.AddMorePoints(1);
        }
    }

    // #region INewToolStrategy Members

    public CurrentTool (): any {
        return this.currentTool;
    }

    public ToolWindow (): any {
        return this.toolWindow;
    }

    public AddPoint (e, stayInDrawingMode = true): boolean {
        const window = e.window;
        if (!window?.ClientRectangle.Contains(e.Location.X, e.Location.Y)) {
            return false;
        }

        const clickPoint = e.Location;

        this.InitTool(window, clickPoint, this.Points.length);

        this.toolWindow = window;
        this.Points.push(clickPoint);
        this.CurrentTool().addedPointsNum = this.Points.length;

        if (this.Finished() && this.Finish()) {
            if (stayInDrawingMode) {
                this.GetNextTool();
            } else {
                this.chart.CancelAction(CancelActionContext.RightButton);
            }
        }

        return true;
    }

    public GetNextTool (): void {
        this.currentTool = this.ToolBuilder(this.CurrentType);
    }

    public ProcessPosition (e): boolean {
        const window = e.window;
        if (window == null) {
            return false;
        }

        const clickPoint = e.Location;

        this.InitTool(window, clickPoint, this.Points.length);

        return true;
    }

    public Cancel (): boolean {
        if (this.ToolEditing) {
            this.ToolEditing = false;
            this.AddCompletedTool();
            return true;
        }

        if (this.currentTool == null) {
            return false;
        }

        if (this.Points.length >= this.currentTool.MinPointsCount()) {
            // тулза считается законченной
            this.currentTool.RemoveLastPoints(this.currentTool.PointLevel() - this.Points.length);
            this.Finish();
        } else
        // тулза неполноценна и удаляется
        {
            this.Clear();
        }

        return true;
    }

    public Reset (type): void {
        this.CurrentType = type;
        this.currentTool = this.ToolBuilder(type);
    }

    // #endregion

    // #region IDisposable Members

    public Dispose (): void {
        this.chart = null;
        this.Clear();
        this.ToolBuilder = null;
    }

    // #endregion

    // #region ISnapToCandle Members

    public ApplyNewSnapMode (useSnap): void {
        this.screenPoinsConverter = ScreenPointsConverterFactory.GetScreenPointsConverter(useSnap);
    }

    // #endregion
}
