import { Rectangle } from '../../Geometry';
import { type HeatmapData } from '../Models/HeatmapData';
import { BaseMap } from './BaseMap';
import { SymbolMapItem } from './SymbolMapItem';

export class IndustryMap extends BaseMap<SymbolMapItem> {
    private static readonly CONST_HEADER_HEIGHT: number = 12;
    private static readonly BORDER: number = 2;

    public readonly industryName: string;
    public headerRectangle: Rectangle = Rectangle.Empty();
    public bodyRectangle: Rectangle = Rectangle.Empty();
    public readonly parentSectorMap: /* SectorMap */any;

    public isSelected: boolean = false;
    public isHidden: boolean = false;

    constructor (parentSectorMap: /* SectorMap */any, industryName: string, data: HeatmapData[]) {
        super();
        this.parentSectorMap = parentSectorMap;
        this.industryName = industryName;
        data.forEach(x => { this.addItem(new SymbolMapItem(this, x)); });
    }

    public get Visible (): boolean {
        return !this.isHidden && this.isVisible();
    }

    public get VisibleCount (): number {
        return this.getVisibleCount();
    }

    public getSymbolBackgroundColor (intervalDot: number): string {
        return this.parentSectorMap.getSymbolBackgroundColor(intervalDot);
    }

    public recalculateArea (): void {
        const headerHeight = this.isSelected ? 0 : IndustryMap.CONST_HEADER_HEIGHT;
        const rect = new Rectangle(this.Rectangle.X + IndustryMap.BORDER, this.Rectangle.Y + IndustryMap.BORDER, this.Rectangle.Width - 2 * IndustryMap.BORDER, this.Rectangle.Height - 2 * IndustryMap.BORDER);
        this.bodyRectangle = new Rectangle(rect.X, rect.Y + headerHeight, rect.Width, rect.Height - headerHeight);
        this.headerRectangle = new Rectangle(rect.X, rect.Y, rect.Width, headerHeight);
        this.recalculateTreeMap(this.bodyRectangle);
    }
}
