// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type PFixFieldSet } from '../Fields/PFixFieldSet';
import { PFixHistoryResponseMessage } from '../Messages/PFixHistoryResponseMessage';
import { MessageProcessorBase } from './MessageProcessorBase';

export class PFixHistoryMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): PFixHistoryResponseMessage[] {
        const message = new PFixHistoryResponseMessage(fieldSet);
        return [message];
    }
}
