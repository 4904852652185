// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { ExpandDoubleVector } from '../DoubleMatrix';
import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';

export class UO extends IndicatorScriptBase {
    constructor () {
        super();
        this.ProjectName = 'UO';
        this.Comments = 'Ultimate Oscillator';
        super.SetIndicatorLine('uo', Color.Blue, 2, LineStyle.SimpleChart);
        this.SeparateWindow = true;

        this.FastInterval = 7;
        super.InputParameter(new InputParameterInteger('FastInterval', 'Fast interval', 0, 1, 9999));

        this.MiddleInterval = 14;
        super.InputParameter(new InputParameterInteger('MiddleInterval', 'Middle interval', 0, 1, 9999));

        this.SlowInterval = 28;
        super.InputParameter(new InputParameterInteger('SlowInterval', 'Slow interval', 0, 1, 9999));

        this.bp = new ExpandDoubleVector();
        this.tr = new ExpandDoubleVector();
        this.avarage = new ExpandDoubleVector();
        this.avarageMiddle = new ExpandDoubleVector();
        this.avarageSlow = new ExpandDoubleVector();
    }

    public FastInterval: number;
    public MiddleInterval: number;
    public SlowInterval: number;

    private bp: ExpandDoubleVector;

    private tr: ExpandDoubleVector;

    private avarage: ExpandDoubleVector;

    private avarageMiddle: ExpandDoubleVector;

    private avarageSlow: ExpandDoubleVector;

    public Init (): void {
        super.Init();
        super.IndicatorShortName(this.GetIndicatorShortName());
    }

    public override GetIndicatorShortName (): string {
        return 'UO(' + this.FastInterval + ',' + this.MiddleInterval + ',' + this.SlowInterval + ')';
    }

    public NextBar (): void {
        this.bp.Add(0.0);
        this.tr.Add(0.0);
        this.avarage.Add(0.0);
        this.avarageMiddle.Add(0.0);
        this.avarageSlow.Add(0.0);
        super.NextBar();
    }

    public OnQuote (): void {
        super.OnQuote();

        if (this.CurrentData.Count - 1 <= 1) {
            return;
        }
        this.setBP();
        this.setTR();
        this.setAvarage();
        this.setAvarageMiddle();
        this.setAvarageSlow();

        super.SetValue(0, 0, this.getUO());
    }

    private setBP (): void {
        this.bp[this.bp.Length - 1] = this.CurrentData.GetPrice(PriceType.Close) - Math.min(this.CurrentData.GetPrice(PriceType.Close, 1), this.CurrentData.GetPrice(PriceType.Low));
    }

    private setTR (): void {
        this.tr[this.tr.Length - 1] = Math.max(this.CurrentData.GetPrice(PriceType.Close, 1), this.CurrentData.GetPrice(PriceType.High)) - this.bp[this.bp.Length - 1];
    }

    private setAvarage (): void {
        this.avarage[this.avarage.Length - 1] = this.getAvarage(this.FastInterval);
    }

    private setAvarageMiddle (): void {
        this.avarageMiddle[this.avarageMiddle.Length - 1] = this.getAvarage(this.MiddleInterval);
    }

    private setAvarageSlow (): void {
        this.avarageSlow[this.avarageSlow.Length - 1] = this.getAvarage(this.SlowInterval);
    }

    private getAvarage (interval: number): number {
        let sumBP = 0;
        let sumTR = 0;

        for (let index = 0; index < interval; index++) {
            sumBP += this.bp[this.bp.Length - 1 - index];
            sumTR += this.tr[this.tr.Length - 1 - index];
        }

        return sumBP / sumTR;
    }

    private getUO (): number {
        const result = 100 * (this.avarage[this.avarage.Length - 1] * 4 + this.avarageMiddle[this.avarageMiddle.Length - 1] * 2 + this.avarageSlow[this.avarageSlow.Length - 1]) / 7;
        return result;
    }
}
