// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DirectBrandingRulesMessage } from '../../../../Utils/DirectMessages/DirectMessagesImport';
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class BrandingRulesMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): DirectBrandingRulesMessage[] {
        const message = new DirectBrandingRulesMessage();

        const groups = fieldSet.GetGroups(FieldsFactory.KEY_VALUE_GROUP);
        const len = groups.length;
        for (let i = 0; i < len; i++) {
            const ruleValue = groups[i].GetValue(FieldsFactory.FIELD_VALUE);
            const ruleName = groups[i].GetValue(FieldsFactory.FIELD_KEY);

            if (ruleName === 'BRANDING_TOOLS') {
                message.Tools = ruleValue;
            }

            if (ruleName === 'BRANDING_COMPANY_NAME') {
                message.CompanyName = ruleValue;
            }
            if (ruleName === 'BRANDING_BROKER_INFORMATION') {
                message.BrokerInformation = ruleValue;
            }
            if (ruleName === 'BRANDING_POWERED_BY_NAME') {
                message.PoweredByName = ruleValue;
            }
            if (ruleName === 'BRANDING_POWERED_BY_URL') {
                message.PoweredByURL = ruleValue;
            }
            if (ruleName === 'BRANDING_CLIENT_SESSION_TIMEOUT_IN_MINUTES') {
                message.BrandingClientSessionTimeoutInMinutes = parseInt(ruleValue);
            }
            if (ruleName === 'BRANDING_DEMOREGISTRATION_URL') {
                message.BrandingDemoregistrationURL = ruleValue;
            }
            if (ruleName === 'BRANDING_FORGOTPWD_URL') {
                message.BrandingForgotpwdURL = ruleValue;
            }
            if (ruleName === 'BRANDING_VISIBLE_INSTRUMENT_LOGO') {
                message.BrandingVisibleInstrumentLogo = ruleValue;
            }
            if (ruleName === 'BRANDING_SERVER_URL') {
                message.BrandingServerURL = ruleValue;
            }
            if (ruleName === 'BRANDING_IP_SERVICE_URL') {
                message.BrandingIpServicesURL = ruleValue;
            }
            // added in #115286 Web:
            if (ruleName === 'BRANDING_WEB_NAME') {
                message.BrandingWebName = ruleValue;
            }
            if (ruleName === 'BRANDING_WEB_LOGIN_BANNER') {
                message.BrandingWebLoginBanner = ruleValue;
            }
            if (ruleName === 'BRANDING_WEB_SPLASH_SCREEN') {
                message.BrandingWebSplashScreen = ruleValue;
            }
            if (ruleName === 'BRANDING_WEB_HEADER') {
                message.BrandingWebHeader = ruleValue;
            }
            if (ruleName === 'BRANDING_WEB_FAVICON') {
                message.BrandingWebFavicon = ruleValue;
            }
            if (ruleName === 'BRANDING_EXTERNAL_AUTHENTICATION') {
                message.BrandingExternalAuthenticationType = ruleValue;
            }
            if (ruleName === 'BRANDING_EXTERNAL_AUTHENTICATION_URL') {
                message.BrandingExternalAuthenticationUrl = ruleValue;
            }
            if (ruleName === 'BRANDING_EXTERNAL_AUTHENTICATION_WEB_CLIENTID') {
                message.BrandingExternalAuthenticationWebClientId = ruleValue;
            }
            if (ruleName === 'BRANDING_EXTERNAL_AUTHENTICATION_WEBMOBILE_CLIENTID') {
                message.BrandingExternalAuthenticationWebMobileClientId = ruleValue;
            }

            if (ruleName === 'BRANDING_ALLOW_PASSWORD_PREVIEW') {
                message.BrandingAllowPasswordPreview = ruleValue;
            }
            if (ruleName === 'BRANDING_ALLOW_SAVE_PASSWORD') {
                message.BrandingAllowSavePassword = ruleValue;
            }

            // Web Mobile:
            if (ruleName === 'BRANDING_MOBILE_ACTIVE_WEB_LOGIN_BANNER') {
                message.BrandingWMLoginBanner = ruleValue;
            }
            if (ruleName === 'BRANDING_MOBILE_WEB_ACTIVE_BACKGROUND_IMAGE') {
                message.BrandingWMBackgroundImage = ruleValue;
            }
            if (ruleName === 'BRANDING_MOBILE_ACTIVE_WEB_FAVICON') {
                message.BrandingWMFavicon = ruleValue;
            }

            // Investing:
            if (ruleName === 'BRANDING_CUSTOM_SCHEME_INVESTING_WEB') {
                message.BrandingInvestingCustomScheme = ruleValue;
            }
            if (ruleName === 'BRANDING_INVESTING_WEB_FAVICON') {
                message.BrandingInvestingFavicon = ruleValue;
            }
            if (ruleName === 'BRANDING_INVESTING_WEB_SPLASH_SCREEN') {
                message.BrandingInvestingSplashScreen = ruleValue;
            }
            if (ruleName === 'BRANDING_INVESTING_WEB_LOGIN_BANNER') {
                message.BrandingInvestingLoginBanner = ruleValue;
            }
            if (ruleName === 'BRANDING_INVESTING_WEB_HEADER') {
                message.BrandingInvestingWebHeader = ruleValue;
            }
            // Localizations:
            if (ruleName === 'BRANDING_WEB_LANG_FILE') {
                message.BrandingWebLangFiles = ruleValue;
            }
        }

        return [message];
    }
}
