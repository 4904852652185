// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type PFixFieldSet, SubscribeGroup } from '../Groups/GroupsImport';
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectSubscribeResponseMessage } from '../../../../Utils/DirectMessages/DirectSubscribeResponseMessage';

export class SubscribeMessageProcessor extends MessageProcessorBase {
    public decoder: any;

    constructor (parentDecoder) {
        super();

        this.decoder = parentDecoder;
    }

    public override decode (fieldSet: PFixFieldSet): DirectSubscribeResponseMessage[] {
        const delay = fieldSet.GetValue(FieldsFactory.FIELD_DELAY_PERIOD);
        const requestId = fieldSet.GetValue(FieldsFactory.FIELD_REQUEST_ID);
        //   let isDataSource = fieldSet.GetValue(FieldsFactory.FIELD_IS_DATA_SOURCE)

        const groups: SubscribeGroup[] = fieldSet.GetGroups(FieldsFactory.SUBSCRIBE_GROUP_NAME, SubscribeGroup);
        const messages: DirectSubscribeResponseMessage[] = [];

        const len = groups.length;
        for (let i = 0; i < len; i++) {
            const group = groups[i];
            const message = new DirectSubscribeResponseMessage();
            messages.push(message);

            const fieldGroupId = group.GetValue(FieldsFactory.FIELD_GROUP_ID);
            message.GroupID = fieldGroupId === null ? -1 : fieldGroupId;
            message.Service = group.getSubscriptionType();
            message.Delay = delay;
            message.CanGetSnapshot = group.GetValue(FieldsFactory.FIELD_IS_SNAPSHOT);

            // пока точно не понятно где будет приходить флажок, сервер морозится
            const isDataSource = group.GetValue(FieldsFactory.FIELD_IS_DATA_SOURCE);
            if (isDataSource != null) {
                message.IsDataSource = isDataSource;
            } // group.GetValue(FieldsFactory.FIELD_IS_DATA_SOURCE)

            message.RequestID = requestId === null ? -1 : requestId;
            message.ErrorCode = group.getErrorCode();
        }

        return messages;
    }
}
