// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../Commons/properties/Resources.ts';
import { DPAttentionMsgTemplate } from '../../templates.js';
import { DynPropertyControl } from '../elements/DynPropertyControl.js';
import { ThemeManager } from '../misc/ThemeManager.ts';

export let DPAttentionMsg = DynPropertyControl.extend({
    template: DPAttentionMsgTemplate,
    data: function ()
    {
        return {
            messageBoxText: ''
        }
    }
})

DPAttentionMsg.prototype.oninit = function ()
{
    DynPropertyControl.prototype.oninit.call(this)
}

DPAttentionMsg.prototype.oncomplete = function () 
{
    DynPropertyControl.prototype.oncomplete.call(this)
}

DPAttentionMsg.prototype.localize = function ()
{
    let locKey = this.get('dp.localizationKey')
    this.set('messageBoxText', Resources.getResource(locKey))
}