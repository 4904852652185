// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { Resources } from '../../Commons/properties/Resources';
import { RulesSet } from '../Rules/RulesSet';
import { AlertAction, AlertNotification, AlertImportance, AlertAfterExecute, AlertCondition, AlertType, AlertConditionMap, AlertTypeMap } from './AlertConstants';
import { HistoryType } from '../History/HistoryType';
import { type Instrument } from '../../Commons/cache/Instrument';
import { type Account } from '../../Commons/cache/Account';

export class AlertUtils {
    public static DataCache = null;

    public static AllowPlaceOrder (): boolean {
        const hideOE = Resources.isHidden('screen.Alerts.Action.PlaceOrder');
        return !hideOE && this.DataCache.isAllowedForMyUser(RulesSet.FUNCTION_ALERTS_PLACE_ORDER);
    }

    public static GetAlertTypeItems (isDEFAULT) {
        const item = [];

        // CERFFFFFFFFFFFFFFF
        // if (isDEFAULT)
        //     item.push({ value: AlertType.DEFAULT, text: Resources.getResource('chart.view.source0') })

        item.push(
            { value: AlertType.ASK, text: Resources.getResource('screen.Alerts.Ask') },
            { value: AlertType.BID, text: Resources.getResource('screen.Alerts.Bid') },
            { value: AlertType.LAST, text: Resources.getResource('screen.Alerts.Last') },
            { value: AlertType.CHANGE, text: Resources.getResource('screen.Alerts.ChangePr') },
            { value: AlertType.VOLUME, text: Resources.getResource('screen.Alerts.Volume') }
        );

        return item;
    }

    public static GetAlertConditionItems () {
        const item = [
            { value: AlertCondition.MORE, text: Resources.getResource('screen.Alerts.Condition.Greater') },
            { value: AlertCondition.LESS, text: Resources.getResource('screen.Alerts.Condition.Less') },
            { value: AlertCondition.MORE_EQUAL, text: Resources.getResource('screen.Alerts.Condition.Greater or equal') },
            { value: AlertCondition.LESS_EQUAL, text: Resources.getResource('screen.Alerts.Condition.Less or equal') }
        ];
        return item;
    }

    public static GetAlertAfterExecuteItems () {
        const item = [
            { value: AlertAfterExecute.STOP, text: Resources.getResource('screen.Alerts.AfterExecute.Stop') },
            { value: AlertAfterExecute.REMOVE, text: Resources.getResource('screen.Alerts.AfterExecute.Remove') }
        ];
        return item;
    }

    public static GetAlertActionItems () {
        const item = [];
        if (this.AllowPlaceOrder()) {
            item.push({ value: AlertAction.PLACE_ORDER_NOTIFY, text: Resources.getResource('screen.Alerts.Action.PlaceOrder') });
        }

        item.push({ value: AlertAction.NOTIFY, text: Resources.getResource('screen.Alerts.Action.Notify') });

        return item;
    }

    public static GetAlertNotificationItems (value) {
        const item = [];
        if (this.DataCache.isAllowedForMyUser(RulesSet.FUNCTION_ALERTS_EMAIL)) {
            item.push({ value: AlertNotification.EMAIL, text: Resources.getResource('screen.Alerts.Notify.Email'), checked: value & AlertNotification.EMAIL });
        }

        if (this.DataCache.isAllowedForMyUser(RulesSet.FUNCTION_ALERTS_PUSH)) {
            item.push({ value: AlertNotification.PUSH, text: Resources.getResource('screen.Alerts.Notify.Push'), checked: value & AlertNotification.PUSH });
        }

        item.push({ value: AlertNotification.SHOW_POP_UP, text: Resources.getResource('screen.Alerts.Notify.Popup'), checked: value & AlertNotification.SHOW_POP_UP });
        item.push({ value: AlertNotification.SOUND, text: Resources.getResource('screen.Alerts.Notify.Sound'), checked: value & AlertNotification.SOUND });
        return item;
    }

    public static VerifyNotificationValue (value): number {
        let verified = 0;
        if (this.DataCache.isAllowedForMyUser(RulesSet.FUNCTION_ALERTS_EMAIL) && (value & AlertNotification.EMAIL)) {
            verified += AlertNotification.EMAIL;
        }

        if (this.DataCache.isAllowedForMyUser(RulesSet.FUNCTION_ALERTS_PUSH) && (value & AlertNotification.PUSH)) {
            verified += AlertNotification.PUSH;
        }

        if (value & AlertNotification.SHOW_POP_UP) {
            verified += AlertNotification.SHOW_POP_UP;
        }

        if (value & AlertNotification.SOUND) {
            verified += AlertNotification.SOUND;
        }

        return verified;
    }

    public static GetAlertImportanceItems () {
        const item = [
            { value: AlertImportance.HIGH, text: Resources.getResource('screen.Alerts.Importance.High') },
            { value: AlertImportance.MEDIUM, text: Resources.getResource('screen.Alerts.Importance.Medium') },
            { value: AlertImportance.LOW, text: Resources.getResource('screen.Alerts.Importance.Low') }
        ];
        return item;
    }

    public static GetDefaultAlertTypeByInstrument (instrument: Instrument): AlertType {
        switch (instrument.HistoryType) {
        case HistoryType.BID:
            return AlertType.BID;
        case HistoryType.QUOTE_TRADES:
            return AlertType.LAST;
        case HistoryType.ASK:
            return AlertType.ASK;
        }
    }

    public static GetCorrectPriceForAlertByInstrument (instrument: Instrument | null, historyType): number {
        if (!instrument) {
            return NaN;
        }

        const Level1 = instrument.Level1;
        if (!Level1) {
            return NaN;
        }

        switch (historyType) {
        case HistoryType.BID:
            return Level1.GetBid();
        case HistoryType.ASK:
            return Level1.GetAsk();
        case HistoryType.LAST:
            return Level1.GetLastPrice();
        default:
            return Level1.GetBid();
        }
    }

    public static GetValuesAndStates (value): any {
        const res = {};
        if (this.DataCache.isAllowedForMyUser(RulesSet.FUNCTION_ALERTS_EMAIL)) {
            res[AlertNotification.EMAIL] = value & AlertNotification.EMAIL;
        }

        if (this.DataCache.isAllowedForMyUser(RulesSet.FUNCTION_ALERTS_PUSH)) {
            res[AlertNotification.PUSH] = value & AlertNotification.PUSH;
        }

        res[AlertNotification.SHOW_POP_UP] = value & AlertNotification.SHOW_POP_UP;
        res[AlertNotification.SOUND] = value & AlertNotification.SOUND;
        return res;
    }

    public static GetMessageText (instrument: Instrument, alertType: AlertType, condition: AlertCondition, valueFormatted: string, account?: Account): string {
        const insName = instrument.DisplayName();
        const alertTypeText = Resources.getResource(`panel.watchlist.${AlertTypeMap[alertType]}`);
        const conditionText = Resources.getResource(`screen.Alerts.Condition.${AlertConditionMap[condition]}`);
        const accountText = `${Resources.getResource('general.trading.for')} ${account?.toString()}`;

        return `${insName} ${alertTypeText} ${conditionText} ${valueFormatted} ${accountText}`;
    }
}
