// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Point } from '../../Commons/Geometry';
import { type PolygonDataCacheTool } from '../../Commons/cache/Tools/PolygonDataCacheTool';
import { type TriangleDataCacheTool } from '../../Commons/cache/Tools/TriangleDataCacheTool';
import { ChartMath } from '../Utils/ChartMath';
import { ToolView } from './ToolView';

export class PolygonToolView extends ToolView<PolygonDataCacheTool | TriangleDataCacheTool> {
    public points: any[];

    constructor (tool) {
        super(tool);

        this.points = new Array(tool.PointLevel());
    }

    public override Draw (gr, ww, param): void {
        if (this.points.length !== this.screenPoints.length) {
            this.points = new Array(this.screenPoints.length);
        }

        for (let i = 0; i < this.screenPoints.length; i++) {
            this.points[i] = new Point(this.screenPoints[i][0], this.screenPoints[i][1]);
        }

        const cacheTool = this.dataCacheTool;
        if (cacheTool.fill) {
            gr.FillPolygon(cacheTool.FillBrush, this.points);
        }

        gr.DrawPolygon(cacheTool.Pen, this.points);

        super.Draw(gr, ww, param);
    }

    public override DrawSelection (gr, ww): void {
        gr.DrawPolygon(this.dataCacheTool.PenHighlight, this.points);

        const scrPoints = this.screenPoints;
        const len = scrPoints.length;

        for (let i = 0; i < len; i++) {
            const scrP = scrPoints[i];
            super.DrawSelectedPoint(gr, ww, scrP[0], scrP[1]);
        }
    }

    // Является ли тулза выделенной мышью (сейчас по линиям, но можно и весь полигон)
    public override IsSelectCheck (x: number, y: number): boolean {
        const TOOL_DX = ToolView.TOOL_DX;
        const scrPoints = this.screenPoints;
        const len = scrPoints.length;

        for (let i = 0; i < len; i++) {
            const sec = i + 1 < len ? i + 1 : 0;

            if (ChartMath.CalcDistanceSqrFromPointToSection(scrPoints[i], scrPoints[sec], x, y) <= TOOL_DX) {
                return true;
            }
        }
        return false;
    }
}
