// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { PFixFieldSet } from '../Fields/FieldsImport';
import { DataBuffer } from '../DataBuffer';
import { ErrorInformationStorage } from '../../../../Commons/ErrorInformationStorage';
import { type DirectMessageBaseClass } from '../../../../Utils/DirectMessages/DirectMessagesImport';

export class CompressedMessageProcessor extends MessageProcessorBase {
    public decoder: any;

    constructor (parentDecoder) {
        super();

        this.decoder = parentDecoder;
    }

    public override decode (fieldSet: PFixFieldSet): DirectMessageBaseClass[] {
        const fixField = fieldSet.GetField(FieldsFactory.FIELD_COMPRESSED_DATA);
        // в этом массиве у нас лежат уже распакованные мессадж (и)
        const uncompressed = fixField.Value;

        return this.GetMessages(uncompressed);
    }

    // Этот метод - кусок метода OnAppendData() из класса SecureSocketStream
    // не стал делать общего метода, в SecureSocketStream важна скорость обработки мессаджей
    public GetMessages (data): any[] {
        const decoder = this.decoder;

        const list = [];
        let currIndex = 0;
        const len_data = data.length;
        while (currIndex < len_data) {
        // длина мессаджа - 4 байта
            if ((len_data - currIndex) < 4) {
                break;
            }

            const length = data[currIndex + 3] | data[currIndex + 2] << 8 | data[currIndex + 1] << 16 | data[currIndex] << 24;
            currIndex += 4;

            // Копируем из массива данных бинарное сообщение и обправляем его
            const res = this.CopyData(data, currIndex, length);
            currIndex = res.curIndex;
            const msg = this.GetFieldSet(res.msg);

            if (msg) {
                if (this.decoder.parent.FullLogHandler) {
                    this.decoder.parent.FullLogs(msg, false, res.msg.length);
                }

                const messages = decoder.Decode(msg);
                if (messages) {
                    for (let i = 0; i < messages.length; i++) {
                        list.push(messages[i]);
                    }
                }
            } else {
            // в буфере не осталось достаточно символов - выходим
                currIndex -= 4;
                break;
            }
        }

        return list;
    }

    public GetFieldSet (msg): PFixFieldSet {
        const fieldSet = new PFixFieldSet();
        const buffer = new DataBuffer(msg);
        fieldSet.Read(buffer);
        return fieldSet;
    }

    // TODO.
    public CopyData (data, curIndex, count): any {
        const res: any = {};

        try {
            if (curIndex + count <= data.length) {
                const newData = new Array(count);
                for (let i = 0; i < count; i++) {
                    newData[i] = data[curIndex++];
                }
                res.msg = newData;
            }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            console.log('PFS Copy Data Error! {0} ', ex.Message);
        }

        res.curIndex = curIndex;
        return res;
    }
}
