export class InfoWindowItem {
    public readonly id: number = -1;
    public isVisible: boolean = true;
    public label: string = '';
    public formattedValues: string[] = [''];

    constructor (id: number) {
        this.id = id;
    }
}
