// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class BusinessRejectMessage extends PFixMessage {
    constructor (fieldSet: PFixFieldSet) {
        super(Message.CODE_BUSINESS_REJECT_MESSAGE, fieldSet);
    }

    public getReferanceMessageId (): number {
        return this.getFieldValue(FieldsFactory.FIELD_ID);
    }

    public setReferanceMessageId (id): void {
        this.setFieldValue(FieldsFactory.FIELD_ID, id);
    }

    public getComment (): string {
        return this.getFieldValue(FieldsFactory.FIELD_COMMENT);
    }

    public setComment (comment: string): void {
        this.setFieldValue(FieldsFactory.FIELD_COMMENT, comment);
    }

    public getErrorCode (): number {
        return this.getFieldValue(FieldsFactory.FIELD_ERROR_CODE);
    }

    public static readonly REQUEST_CODE_IS_NOT_SUPPORTED = 39;
    public static readonly NO_PRICE_AVAILABLE = 141;
    public static readonly ERROR_SERVER_HISTORY_QUEUE_WAS_OVERFLOWED = 414;
    public static readonly NO_TRADES_OCCURING_CURRENT_DAY = 452;
    public static readonly OPTION_EXERCISE_ONLY_ITM = 475;
}
