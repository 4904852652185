// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { PFixFieldSet } from '../../../Fields/FieldsImport';
import { FieldsFactory } from '../../../Factory/FieldsFactory';

export class AlgorithmHeatmapInstrumentGroup extends PFixFieldSet {
    constructor (groupId: number, pfixFieldSet: PFixFieldSet) {
        super(groupId, null, pfixFieldSet);
    }

    get TradableId (): number { return super.GetValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID); }
    get RouteID (): number { return super.GetValue(FieldsFactory.FIELD_ROUTE_ID); }
    get LastPrice (): number { return super.GetValue(FieldsFactory.FIELD_LAST_PRICE); }
    get PercentChange (): number { return super.GetValue(FieldsFactory.FIELD_INTEREST_CHANGE); }
    get Change (): number { return super.GetValue(FieldsFactory.FIELD_ABSOLUTE_CHANGE); }
}
