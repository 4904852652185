export {};

declare global {
    function isValidString (value: string): boolean;
    function isValidNumber (value: number): boolean;
    function isNullOrUndefined (value: any): boolean;
    function isValidArray (value: any []): boolean;

    interface String {
        startsWith: (searchString: string, position?: number) => boolean
        endsWith: (searchString: string, length?: number) => boolean
        isEmpty: () => boolean
    }

    interface StringConstructor {
        CompareOrdinal: (str1: string, str2: string) => number
        StringJoin: (separator: string, strArr: string[]) => string
    }

    function arraysIntersection<T> (a: T[], b: T[]): T[];

    interface ObjectConstructor {
        getKeyByValue: (obj: any, val: any) => string | null
    }

    interface Array<T> {
        Contains: (obj: T) => boolean
    }
}

let _global: any;

if (typeof window !== 'undefined') { // Для браузерной среды
    _global = window;
} else if (typeof global !== 'undefined') { // Для среды Node.js
    _global = global;
} else {
    throw new Error('No global object found');
}

_global.isNullOrUndefined = function (value: any): boolean {
    if (typeof value === 'undefined' || value === null) {
        return true;
    } else {
        return false;
    }
};

_global.isValidString = function (value: string): boolean {
    if (typeof value !== 'string' || value === '') {
        return false;
    } else {
        return true;
    }
};

_global.isValidNumber = function (value: number): boolean {
    if (typeof value !== 'number' || isNaN(value)) {
        return false;
    } else {
        return true;
    }
};

_global.isValidArray = function (value: []): boolean {
    if (typeof value === 'undefined' || value === null || value.length === 0) {
        return false;
    } else {
        return true;
    }
};

String.prototype.startsWith = String.prototype.startsWith || function (searchString: string, position?: number): boolean {
    return this.substr(position || 0, searchString.length) === searchString;
};

String.prototype.endsWith = String.prototype.endsWith || function (searchString: string, this_len?: number): boolean {
    if (this_len === undefined || this_len > this.length) {
        this_len = this.length;
    }
    return this.substring(this_len - searchString.length, this_len) === searchString;
};

String.prototype.isEmpty = function (): boolean {
    if (isNullOrUndefined(this) || this.trim() === '') {
        return true;
    }

    return false;
};

String.CompareOrdinal = String.CompareOrdinal || function (str1: string, str2: string): number {
    if (str1 === str2) {
        return 0;
    }
    return str1 > str2 ? 1 : -1;
};

String.StringJoin = String.StringJoin || function (separator: string, strArr: string[]): string {
    let res = strArr[0];
    for (let i = 1, len = strArr.length; i < len; i++) {
        res += separator + strArr[i];
    }
    return res;
};

_global.arraysIntersection = function<T> (a: T[], b: T[]): T[] {
    let t: T[];
    // indexOf to loop over shorter
    if (b.length > a.length) {
        t = b;
        b = a;
        a = t;
    }
    return a.filter(function (e: T) {
        return b.includes(e);
    });
};

Object.getKeyByValue = Object.getKeyByValue || function (obj: any, val: any): string | null {
    for (const key in obj) {
        if (obj[key] === val) {
            return key;
        }
    }
    return null;
};

Array.prototype.Contains = Array.prototype.Contains || function<T>(obj: T): boolean {
    return this.indexOf(obj) !== -1;
};
