// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { SolidBrush } from '../../../Commons/Graphics';
import { type Instrument } from '../../../Commons/cache/Instrument';

export class ToolViewUtils {
    public static DrawFibonacciText (gr, ww, x: number, y: number, text: string, font, color): void {
        const clientRect = ww.ClientRectangle;
        y = y - font.Height - 1;
        if (x < clientRect.X + clientRect.Width) {
            const br = new SolidBrush(color);
            gr.DrawString(text, font, br, x, y);
        }
    };

    public static FormatFibonacciText (fibLevel, points, instrument: Instrument): string {
        const fibText = fibLevel.toString();

        const p1Y = points[1][1];

        const price = p1Y + fibLevel / 100 * (points[0][1] - p1Y);
        return fibText.replace('%$', instrument.formatPrice(price));
    }

    public static FormatPrice (instrument: Instrument, price: number): string {
        return instrument.formatPrice(price);
    }
}
