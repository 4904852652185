// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts";
import { LinkedSystem, LinkedSystemAccLinkingValue } from "../misc/LinkedSystem.ts";
import { AssetBalanceItem } from "../cache/AssetBalanceItem.ts";
import { QuickTableRactive, QuickTableRactiveEvents } from "../elements/QuickTable/QuickTableRactive.ts";
import { PanelNames } from "../UtilsClasses/FactoryConstants.ts";
import { ApplicationPanelNew } from "./ApplicationPanelNew.js";
import { AccountType } from "../../Utils/Account/AccountType.ts";
import { DataCache } from "../../Commons/DataCache.ts";
import { SessionSettings } from "../../Commons/SessionSettings.ts";
import { CustomerAccess } from "../../Commons/CustomerAccess/CustomerAccess.ts";
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";


export let AssetBalancesPanel = ApplicationPanelNew.extend(
    {
        data: function () { return { isAccountLinkShow: true, canFilterByAccount: false }; },
        headerLocaleKey: 'panel.assetBalances'
    });

AssetBalancesPanel.prototype.getType = function () { return PanelNames.AssetBalancesPanel };

AssetBalancesPanel.prototype.oncomplete = function ()
{
    ApplicationPanelNew.prototype.oncomplete.apply(this);
    const qt = this.getQuickTable();
    qt.OnSelectionChanged.Subscribe(this.onSelectionChanged, this);
    qt.skipAccountFiltration = true;    // #120237
    this.quickTableRactive.on(QuickTableRactiveEvents.DoubleClick, this.onDoubleClick.bind(this));
};

AssetBalancesPanel.prototype.createContextMenu = function (selectedAccountId)
{
    // if (!selectedAccountId) 
    return [];
};

AssetBalancesPanel.prototype.onSelectionChanged = function ()
{
    const accountID = this.getSelectedAccountId();
    this.getQuickTable().setTableContextMenuItems(this.createContextMenu(accountID));
    if (accountID)
        this.accountLink_Out(false, DataCache.GetAccountById(accountID));
};

AssetBalancesPanel.prototype.onViewReportClicked = function ()
{
    var selAccId = this.getSelectedAccountId();
    MainWindowManager.Factory.addPanel(
        PanelNames.ReportsPanel,
        null,
        function (panel) { panel.accountLink_In(selAccId); });
};

AssetBalancesPanel.prototype.onViewAccDetailsClicked = function ()
{
    this.openAccDetails();
};

AssetBalancesPanel.prototype.openAccDetails = function ()
{
    if (!CustomerAccess.panelAllowed(PanelNames.AccountDetailsPanel))
        return;

    var data = this.getSelectedAccountAndAssetId();
    if (!data) return;

    MainWindowManager.Factory.addPanel(
        PanelNames.AccountDetailsPanel,
        null,
        function (panel)
        {
            panel.accountLink_In(data.accountId);
            panel.selectAsset(data.assetId);
        });
};

AssetBalancesPanel.prototype.getSelectedAccountAndAssetId = function ()
{
    var qt = this.getQuickTable();
    var selectedRowsIds = qt.selectedRowIds;
    if (!selectedRowsIds) return null;

    var firstSelectedRowId = selectedRowsIds[0];
    if (firstSelectedRowId === undefined || firstSelectedRowId === null)
        return null;

    var row = qt.rows[firstSelectedRowId];

    return {
        accountId: row.account,
        assetId: parseInt(row.assetId)
    };
};

AssetBalancesPanel.prototype.getSelectedAccountId = function ()
{
    let qt = this.getQuickTable();
    let selectedRowsId = qt.selectedRowIds;
    if (!selectedRowsId) return;

    let accId = '';

    if (selectedRowsId.length > 0)
    {
        let row = qt.rows[selectedRowsId[0]]
        let item = row ? row.item : null
        if (item)
        {
            let acc = item.GetCurrentAccount()
            if (acc) accId = acc.BstrAccount
        }
    }

    return accId;
};

AssetBalancesPanel.prototype.localize = function ()
{
    ApplicationPanelNew.prototype.localize.apply(this);
    this.getQuickTable().setTableContextMenuItems(
        this.createContextMenu(this.getSelectedAccountId()));
};


AssetBalancesPanel.prototype.populateItemsDirect = function ()
{
    ApplicationPanelNew.prototype.populateItemsDirect.apply(this);
    var accs = DataCache.Accounts;
    var accsKeys = Object.keys(accs);
    for (var i = 0; i < accsKeys.length; i++)
    {
        var acc = accs[accsKeys[i]];
        for (var key in acc.assetBalances)
        {
            var asd = acc.assetBalances[key];
            if (acc.AccountType !== AccountType.MultiAsset || !acc.assetBalanceDefault.IsEmpty)
                this.getQuickTable().AddItem(new AssetBalanceItem(acc, SessionSettings, asd, asd.Asset));
        }
    }

    DataCache.OnAddAccount.Subscribe(this.DataCacheOnAddAccountEvent, this);
    DataCache.OnRemoveAccount.Subscribe(this.DataCacheOnRemoveAccountEvent, this);
}

AssetBalancesPanel.prototype.SetColumnsDefaultDisplayIndex = function (table)
{
    table.columns[1].displayedIndex = 0;
    table.columns[64].displayedIndex = 1;
    table.columns[2].displayedIndex = 2;
    table.columns[20].displayedIndex = 3;
    table.columns[42].displayedIndex = 4;
    table.columns[32].displayedIndex = 5;
    table.columns[33].displayedIndex = 6;
    table.columns[67].displayedIndex = 7;
    table.columns[24].displayedIndex = 8;
}

AssetBalancesPanel.prototype.updatePanelHeader = function ()
{
    this.set({ header: Resources.getResource(this.headerLocaleKey) + (this.NeedCalculateRowCount ? ('  (' + this.rowCount + ')') : '') });
};

AssetBalancesPanel.prototype.filterByAccountNumber = function ()
{

};

//
//
//
AssetBalancesPanel.prototype.dispose = function ()
{
    DataCache.OnAddAccount.UnSubscribe(this.DataCacheOnAddAccountEvent, this);
    DataCache.OnRemoveAccount.UnSubscribe(this.DataCacheOnRemoveAccountEvent, this);
    ApplicationPanelNew.prototype.dispose.apply(this);
};

AssetBalancesPanel.prototype.DataCacheOnAddAccountEvent = function (acc)
{
    if (!acc || !this.quickTableRactive || !this.getQuickTable())
        return;

    let quickTable = this.getQuickTable();
    for (let key in acc.assetBalances)
    {
        let row = quickTable.getItemById(acc.AcctNumber + ":" + key);
        if (row != null)
            row.updateItem(row.item);
        else
        {
            let aBal = acc.assetBalances[key];
            if (!aBal.IsEmpty)
                quickTable.AddItem(new AssetBalanceItem(acc, SessionSettings, aBal, aBal.Asset));
        }
    }
}

AssetBalancesPanel.prototype.DataCacheOnRemoveAccountEvent = function (acc)
{
    this.getQuickTable().RemoveItem(acc.userID);
}