// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
export enum GeneralSymbolInfoFeatureEnum {
    SymbolName,
    SymbolDescription,
    TradingExchage,
    MarketDataExchange,
    SymbolType,
    Underlier,
    DeliveryMethod,
    ContactMonth,
    FirstTradeDate,
    LastTradeDate,
    MaturityDate,
    NoticeDate,
    SettlementDate,
    TradingBalance,
    Isin,
    StrikePrice,
    ExerciseStyle,
    FaceValue,
    CouponCycle,
    CouponRate,
    Ytm,
    AccruedInterest,
    PrevPaymentDate,
    NextPaymentDate,
    Yield,
    Asset,
    Industry,
    Sector,
    ExtSmFlag,
    DaysPerMonth,
    DaysPerYear
}

export enum TradinInfoSymbolInfoFeatureEnum {
    TradingStatus,
    AllowedOperations,
    AllowedOrderTypes,
    ProductType,
    DeliveryStatus,
    CurrentSession,
    SessionStatus,
    NextHoliday,
    QuotingCurrency,
    LotStep,
    HighLimit,
    LowLimit,
    TradindBlockedOnSession,
    NormalMarketSize,
    LotSize,
    ContractMultiplier,

    AllowOvernightTrading,
    Etb,
    EXTFIELD_TRADINGUNIT,
    EXTFIELD_PRICEQUOTEUNIT,
    EXTFIELD_DELIVERYUNIT,
    EXTFIELD_LOTSIZE,
    EXTFIELD_TENDERPERIODSTARTDATE,
    EXTFIELD_TENDERPERIODENDDATE,

    // group items
    MinimalLot, // addMinimalLotItems
    MaximumLot, // addMaximalLotItems
    MaxPositionQuantity, // addMaxPositionQtyItems
    AllowShortPositions, // addMaxPositionQtyItems
    TickSizeTickCoast, // addTickSizeTickCoastItems

    HighLimitWarning,
    LowLimitWarning,
}

export enum InstrumentFeatureEnum {
    SymbolName = 1,
    SymbolDescription = 2,
    TradingExchage = 3,
    MarketDataExchange = 4,
    AssetClass = 5,
    Underlier = 6,
    DeliveryMethod = 7,
    ContactMonth = 8,
    FirstTradeDate = 9,
    LastTradeDate = 10,
    NoticeDate = 11,
    SettlementDate = 12,
    StrikePrice = 13,
    TradingBalance = 14,
    MaturityDate = 15,
    FaceValue = 16,
    CouponRate = 17,
    CouponCycle = 18,
    YTM = 19,
    AccruedInterest = 20,
    PrevPaymentDate = 21,
    NextPaymentDate = 22,
    Exp1 = 23,
    Yield = 24, // ?
    Isin = 25,
    Industry = 26,
    Sector = 27,
    ExerciceStyle = 28,
    GsmFlag = 29,
    EsmFlag = 30,
    AsmFlagLong = 31,
    AsmFlagShort = 32,
    TradingStatus = 33,
    AllowedOperations = 34,
    AllowedOrderTypes = 35,
    ProductType = 36,
    DeliveryStatus = 37,
    CurrentSession = 38,
    SessionStatus = 39,
    NextHoliday = 40,
    QuotingCurrency = 41,
    LotSize = 42,
    ContractMultiplier = 43,
    MinimalLotIntraday = 44,
    MinimalLotDelivery = 45,
    MaximumLot = 46,
    MaximumLotIntraday = 47,
    MaximumLotDelivery = 48,
    MaxPositionQuantity = 49,
    MaxPositionQuantityIntraday = 50,
    MaxPositionQuantityDelivery = 51,
    LotStep = 52,
    HighLimit = 53,
    LowLimit = 54,
    HiLimitFromPriceLimitsMsg = 55,
    SwapBuy = 56,
    SwapSell = 57,
    AllowShortPositions = 58,
    Etb = 59,
    AllowShortPositionsIntraday = 60,
    AllowShortPositionsDelivery = 61,
    AllowOvernightTrading = 62,
    NormalMarketSize = 63,
    EXTFIELD_TRADINGUNIT = 64,
    EXTFIELD_PRICEQUOTEUNIT = 65,
    EXTFIELD_DELIVERYUNIT = 66,
    EXTFIELD_LOTSIZE = 67,
    EXTFIELD_TENDERPERIODSTARTDATE = 68,
    EXTFIELD_TENDERPERIODENDDATE = 69,

    MinimalLot = 70,
    TradindBlockedOnSession = 71,

    TickSizeTickCoast = 72,
    // marig
    // fees
    // rebates
    // variable tick size
    // session info
}

// 119214: one enum instead of DaysPerMonthType & DaysPerYearType:
export enum DaysPerType {
    Default = 0, // 30 for DaysPerMonthType, 360 for DaysPerYearType
    Actual = 1, // from 28 to 31 for DaysPerMonthType, 365 or 366 for DaysPerYearType
}
