// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from '../../Utils/CustomEvents';
import { ControlsUtils } from '../UtilsClasses/ControlsUtils';
import { contextMenuHandler } from '../../Utils/AppHandlers.js';
import { TerceraBaseWindowTemplate, TerceraAlwaysMiddlePartPartial } from '../../templates.js';
import { ContainerControl } from '../elements/ContainerControl_ts';
import { BOTTOM_WINDOWS_MARGIN, TOP_WINDOWS_MARGIN, WINDOW_SIDE_BORDERS_WIDTH } from '../UtilsClasses/SizeConstants';
import { BrandingUtils } from '../../Commons/UtilsClasses/BrandingUtils';
import { ApplicationInfo } from '../../Commons/ApplicationInfo';
import { Resources } from '../../Commons/properties/Resources';
import $ from 'jquery';
import { TerceraButtonEvents } from '../../Utils/Enums/ButtonEnums';
import { TerceraLinkControlConstants } from '../UtilsClasses/TerceraLinkControlConstants';

export class TerceraWindowBase extends ContainerControl {
    public OnResize = new CustomEvent();
    public OnClose = new CustomEvent();
    public OnComplete = new CustomEvent();

    public forceCloseOnLogout = true;

    public allowResizers = {
        left: true,
        top: true,
        right: true,
        bottom: true
    };

    public captured: any;
    public parentPanelTeardownCancelObj: any;

    public override oninit (): void {
        super.oninit();
        this.OnResize = new CustomEvent();
        this.OnClose = new CustomEvent();
        this.OnComplete = new CustomEvent();
    }

    public override getType (): string { return 'TerceraWindowBase'; }
    // #region private service methods

    //
    // on complete -> subscribe for value changed, if necessary
    //
    public override oncomplete (): void {
        super.oncomplete();

        this.observe('header', (newValue) => {
            void this.set({ headerInnerHTML: newValue });
        }); // для возможности сетить в хедер html элементы (например если часть текста должна быть другого цвета как в #100287)
        this.observe('isAccountLinkShow', (newValue: boolean) => {
            const newState = newValue ? TerceraLinkControlConstants.STATE_ALL : TerceraLinkControlConstants.STATE_NONE;
            void this.set({ accountLinkValue: newState, isAccountLinkShowState: this.getIsAccountLinkShow() });
        });
        const me = this;
        if (me.Controls?.closeCrossBtn) {
            me.Controls.closeCrossBtn.on(
                TerceraButtonEvents.onClick,
                this.onCloseButtonClick.bind(this));
        }

        const self = this.getControl();
        const fullscreenModeBtn = self.getElementsByClassName('terceraButton js-button-fullscreen')[0];
        if (fullscreenModeBtn != null) {
            fullscreenModeBtn.addEventListener('click', this.toogleFullscreenMode.bind(this));
        }
        this.on('fullscreenModeClick', this.toogleFullscreenMode);

        this.OnComplete.Raise(this);
    }

    getIsAccountLinkShow (): boolean {
        return false;
    }

    public toogleFullscreenMode (): void {
        /* eslint-disable @typescript-eslint/dot-notation */
        const self = this.getControl();
        if (self.requestFullscreen != null) {
            void self.requestFullscreen();
        } else if (self['mozRequestFullScreen'] != null) { // Firefox
            void self['mozRequestFullScreen']();
        } else if (self['webkitRequestFullscreen'] != null) { // Chrome, Safari, Opera
            void self['webkitRequestFullscreen']();
        } else if (self['msRequestFullscreen'] != null) { // IE/Edge
            void self['msRequestFullscreen']();
        }
    }

    // Close button (cross icon).
    public onCloseButtonClick (): void {
        if (this.canCloseFromButton()) {
            this.close();
        }
    }

    public canCloseFromButton (): boolean {
        return true;
    }

    public close (): void {
        this.dispose();
        this.OnClose.Raise(this);
    }

    //
    // Раскладываем контролы по id' шкам
    //
    public override onrender (): void {
    // вызов бэйс - обязательно!
        super.onrender();
    }
    // #endregion

    public override getClientPanel (): HTMLElement {
        return this.Controls.windowPanelBody.getClientPanel();
    }

    public getElementRect (element) {
        if (!element) {
            return;
        }

        const rect = element.getBoundingClientRect();
        const top = rect.top + window.scrollY;
        const left = rect.left + window.scrollX;

        return {
            top,
            left,
            right: left + rect.width,
            bottom: top + rect.height,
            width: rect.width,
            height: rect.height
        };
    }

    public override onMouseMove (event): void {
        if (typeof event.get === 'function' && event.get('resizable')) {
            this.onResizingBoundsVisible(event);
        }
    }

    public override onMouseDown (event, isHeader: boolean): void {
        if (document.fullscreenElement != null) { return; }
    // dockspawn TODO подумать о более коректном пропуске события или привязки хедера доксистемы к нашей системе фокуса панелей.
    // останавливает событие если был клик по хедеру докконтейнера устанавлиывается в dockspawn
        if (event.original.target.skipMouseDown) {
            if (!event.original.target.skipMouseDownCloseContextMenu && contextMenuHandler?.isShowed()) {
                contextMenuHandler.Hide();
            }
            delete event.original.target.skipMouseDown;
            delete event.original.target.skipMouseDownCloseContextMenu;
            return;
        }
        super.onMouseDown(event, isHeader);
        if (this.onProcessResize) {
            ControlsUtils.Capture.SetCapture(this, event.original);
        }
    }

    public onResizing (event): void {
        if (!this.captured) return;

        const x = this.getPageX(event.original);
        const y = this.getPageY(event.original);
        const resizeParams = this.resizeParameters;
        const dx = resizeParams.down.x - x;
        const dy = resizeParams.down.y - y;
        const myRect = resizeParams.rect;
        const parentOffset = this.getParentOffset(this.el);
        const resizers = resizeParams.resizer;

        const minHeight = this.get('minHeight');
        const maxHeight = this.get('maxHeight');
        const minWidth = this.get('minWidth');
        const maxWidth = this.get('maxWidth');

        const topWindowMargin = TOP_WINDOWS_MARGIN;
        const bottomWindowMargin = BOTTOM_WINDOWS_MARGIN;
        const sideWindowMargin = WINDOW_SIDE_BORDERS_WIDTH;

        if (resizers.top) {
            if (myRect.height > minHeight && myRect.height + dy > minHeight && myRect.height + dy < maxHeight) {
                void this.set({
                    top: y > topWindowMargin ? myRect.top - dy - parentOffset.top : topWindowMargin,
                    height: y > topWindowMargin ? myRect.height + dy - sideWindowMargin / 2 : myRect.bottom - topWindowMargin - sideWindowMargin / 2
                });
            }
        }

        if (resizers.bottom) {
            if (myRect.height - dy > minHeight && myRect.height - dy < maxHeight) {
                void this.set({
                    height: y < parentOffset.height - bottomWindowMargin
                        ? myRect.height - dy
                        : parentOffset.height - myRect.top - bottomWindowMargin - sideWindowMargin
                });
            }
        }

        if (resizers.left) {
            if (myRect.height > minHeight && myRect.width + dx > minWidth && myRect.width + dx < maxWidth) {
                void this.set({
                    width: myRect.width + dx - sideWindowMargin / 2,
                    left: myRect.left - dx - parentOffset.left
                });
            }
        }

        if (resizers.right) {
            if (myRect.width - dx > minWidth && myRect.width - dx < maxWidth) {
                void this.set({ width: myRect.width - dx });
            }
        }

        // call event
        const needRaiseEvent = !resizers.noresizer;
        if (needRaiseEvent) {
            this.OnResize.Raise();
        }
    }

    public onResizingBoundsVisible (event): void {
        const resize = this.getResizer(event.original, event.node);
        let cursor = '';
        if ((resize.top && resize.left) || (resize.bottom && resize.right)) {
            cursor = 'nwse-resize';
        } else if ((resize.top && resize.right) || (resize.bottom && resize.left)) {
            cursor = 'nesw-resize';
        } else if (resize.top || resize.bottom) {
            cursor = 'ns-resize';
        } else if (resize.left || resize.right) {
            cursor = 'ew-resize';
        }

        event.node.style.cursor = cursor;
    }

    public getResizer (event, node) {
        const x = this.getPageX(event);
        const y = this.getPageY(event);
        const precision = 5;
        const rect = this.getElementRect(node);
        const limits = y > rect.top - precision && y < rect.bottom + precision && x > rect.left - precision && x < rect.right + precision;
        const resizer = {
            noresizer: true,
            left: false,
            right: false,
            top: false,
            bottom: false
        };

        if (limits) {
            if (y < rect.top + precision && y > rect.top - precision && this.allowResizers.top) {
                resizer.top = true;
            }

            if (y < rect.bottom + precision && y > rect.bottom - precision && this.allowResizers.bottom) {
                resizer.bottom = true;
            }

            if (x < rect.left + precision && x > rect.left - precision && this.allowResizers.left) {
                resizer.left = true;
            }

            if (x < rect.right + precision && x > rect.right - precision && this.allowResizers.right) {
                resizer.right = true;
            }
        }

        if (resizer.left || resizer.right || resizer.bottom || resizer.top) {
            resizer.noresizer = false;
        }

        return resizer;
    }

    public getMousePosition (property, alternative, additionalOffset) {
        return function (event) {
            if (event[property] !== undefined) {
                return event[property];
            } else if (event[alternative] !== undefined) {
                return event[alternative] + document.body[additionalOffset] + document.documentElement[additionalOffset];
            }

            throw new Error('Pointer position not found');
        };
    }

    public getPageX (event): number {
        return this.getMousePosition('pageX', 'clientX', 'scrollLeft')(event);
    }

    public getPageY (event): number {
        return this.getMousePosition('pageY', 'clientY', 'scrollTop')(event);
    }

    public getParentOffset (node): any {
        return node.offsetParent === document.body ? { left: 0, top: 0 } : this.getElementRect(node.offsetParent);
    }

    public center (oninit = false): void {
        const control = this.getControl();
        const width = control.offsetWidth;
        const height = control.offsetHeight;

        const mWindow = $(window);
        const wWidth = mWindow.width();
        const wHeight = mWindow.height();

        const left = wWidth > width ? (wWidth - width) / 2 : 0;
        const top = wHeight > height ? (wHeight - height) / 2 : 0;

        void this.set({ left, top });
    }

    public getControl (): HTMLElement {
        return this.find('*');
    }

    public Properties (): any[] {
        return [];
    }

    public override dispose (): void {
        this.clearParentPanelTeardownData();
        super.dispose();
    }

    public localize (): void {
        const exploreTheAppText = Resources.getResource('panel.General.ExploreTheApp.Text');
        const exploreTheAppLinkPartText = Resources.getResource('panel.General.ExploreTheApp.LinkPartText');
        const linkPartIndex = exploreTheAppText.indexOf(exploreTheAppLinkPartText);
        if (exploreTheAppText !== '' && linkPartIndex > 0) {
            const exploreTheAppStart = exploreTheAppText.substring(0, exploreTheAppText.indexOf(exploreTheAppLinkPartText));
            const exploreTheAppEnd = exploreTheAppText.substring(exploreTheAppText.indexOf(exploreTheAppLinkPartText) + exploreTheAppLinkPartText.length);
            void this.set('exploreTheAppStart', exploreTheAppStart);
            void this.set('exploreTheAppLinkPartText', exploreTheAppLinkPartText);
            void this.set('exploreTheAppEnd', exploreTheAppEnd);
        } else {
            void this.set('exploreTheAppStart', '');
            void this.set('exploreTheAppLinkPartText', '');
            void this.set('exploreTheAppEnd', '');
        }
    }

    // For closing screens opened from panels.
    public setParentPanel (parentPanel): void {
        if (!parentPanel?.teardown) {
            return;
        }

        this.clearParentPanelTeardownData();
        this.parentPanelTeardownCancelObj = parentPanel.on(
            'teardown',
            this.onParentPanelTeardown.bind(this));
    }

    public onParentPanelTeardown (): void {
        this.clearParentPanelTeardownData();
        this.afterParentPanelTeardownCallback();
    }

    public afterParentPanelTeardownCallback (): void {
        this.dispose();
    }

    public clearParentPanelTeardownData (): void {
        if (this.parentPanelTeardownCancelObj) {
            this.parentPanelTeardownCancelObj.cancel();
            delete this.parentPanelTeardownCancelObj;
        }
    }

    public onKeyDown (event): any {

    }

    public onMouseEnter (event): any {

    }

    public onMouseLeave (event): any {

    }

    public updateBrandingImage (): void // for About and BrokerInfo screen #117995
    {
        const imgURL = BrandingUtils.GetLastWebLoginBanner();
        const imgDIV = document.getElementsByClassName('js-brand-image')[0] as HTMLElement;
        if (imgDIV) {
            imgDIV.style.backgroundImage = imgURL
                ? `background-image: url("${imgURL}");`
                : '';
        }
    }

    public isShowExploreTheAppLink (): boolean {
        return this.get('isShowExploreTheAppLink');
    }
}

// #region enums
export enum TerceraWindowBaseEvents {
    OnClosed = 'OnClosed'

}
// #endregion

ContainerControl.extendWith(TerceraWindowBase, {
    template: TerceraBaseWindowTemplate,
    data: function () {
        return {
            isRelativeStyle: false,
            zIndex: 200,
            showHeader: true,
            showFooter: true,
            movable: true,
            resizable: true,
            header: '',
            headerInnerHTML: '',
            header_button_text: '',
            alwaysMiddle: false,
            showModalMask: false,
            isLookup: false,
            showOverflow: false,
            minWidth: 100,
            minHeight: 100,
            maxWidth: 5000,
            maxHeight: 5000,
            showBorder: true,
            showAttachHeaderButton: false,
            showFullscreenModeButton: false,
            forbiddenPanel: false,
            banText: '',
            symbolLinkTooltip: 'Symbol link',
            accountLinkTooltip: 'Account link',
            fullscreenModeBtnTooltip: '',
            style_addition_jsWin: '',
            style_addition_body: '',
            style_addition_header: '',
            style_addition_footer: '',
            style_addition_header_button: '',
            footer_height: 47,
            closeBtnVisible: true,
            isShowExploreTheAppLink: false,
            exploreTheAppLink: ApplicationInfo.exploreTheAppLink,
            exploreTheAppStart: '',
            exploreTheAppLinkPartText: '',
            exploreTheAppEnd: '',
            widthInPercent: null,
            heightInPercent: null,
            isAccountLinkShowState: false,
            isAccountLinkShow: false
        };
    },
    partials: {
        alwaysMiddlePart: TerceraAlwaysMiddlePartPartial
    }
});
