// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { MAMode } from '../IndicatorConstants';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { type iBuildInIndicator } from '../iBuildInIndicator';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';

export class PPO extends IndicatorScriptBase {
    public fastEmaPeriod: number;
    public slowEmaPeriod: number;
    public signalEmaPeriod: number;

    private FastEMA: iBuildInIndicator;
    private SlowEMA: iBuildInIndicator;

    constructor () {
        super();
        this.ProjectName = 'Percentage Price Oscillator';
        this.Comments = 'Percentage Price Oscillator is a momentum indicator. Signal line is EMA of PPO. Formula: (FastEMA-SlowEMA)/SlowEMA';
        this.SetIndicatorLine('PPO', Color.SkyBlue, 2, LineStyle.SimpleChart);
        this.SetIndicatorLine('Signal', Color.Red, 1, LineStyle.SimpleChart);
        this.SetLevelLine('Zero', 0, Color.Gray, 1, LineStyle.SimpleChart);
        this.SeparateWindow = true;

        this.fastEmaPeriod = 12;
        super.InputParameter(new InputParameterInteger('fastEmaPeriod', 'Fast EMA Period', 0, 1, 99999));

        this.slowEmaPeriod = 26;
        super.InputParameter(new InputParameterInteger('slowEmaPeriod', 'Slow EMA Period', 1, 1, 99999));

        this.signalEmaPeriod = 9;
        super.InputParameter(new InputParameterInteger('signalEmaPeriod', 'Signal EMA Period', 2, 1, 99999));
    }

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
    };

    public override GetIndicatorShortName (): string {
        return 'PPO(' + this.fastEmaPeriod + ', ' + this.slowEmaPeriod + ', ' + this.signalEmaPeriod + ')';
    };

    public override createInternalIndicators (): iBuildInIndicator[] {
        this.FastEMA = this.Indicators.iMA(this.CurrentData, this.fastEmaPeriod, MAMode.EMA, 0, PriceType.Close);
        this.SlowEMA = this.Indicators.iMA(this.CurrentData, this.slowEmaPeriod, MAMode.EMA, 0, PriceType.Close);
        return [this.FastEMA, this.SlowEMA];
    };

    public OnQuote (): void {
        super.OnQuote();
        this.GetPPO();
        this.GetSignalPPO();
    };

    public GetPPO (): void {
        const maxPPO = Math.max(this.fastEmaPeriod, this.slowEmaPeriod);

        if (this.CurrentData.Count < maxPPO) { return; }

        const fastEMA = this.FastEMA.GetValue();
        const slowEMA = this.SlowEMA.GetValue();

        const ppo = slowEMA !== 0 ? ((fastEMA - slowEMA) / slowEMA) : 0;
        this.SetValue(0, 0, ppo);
    };

    public GetSignalPPO (): void {
        const maxPPO = Math.max(this.fastEmaPeriod, this.slowEmaPeriod);
        const maxSignalPPO = maxPPO + this.signalEmaPeriod - 1;
        const count = this.CurrentData.Count;

        if (count < maxSignalPPO) {} else if (count === maxSignalPPO) {
            let sumPrice = 0;
            for (let i = 0; i < this.signalEmaPeriod; i++) { sumPrice += this.GetValue(0, i); }

            this.SetValue(1, 0, sumPrice / this.signalEmaPeriod);
        } else {
            const prevEMA = this.GetValue(1, 1);
            const k = 2.0 / (this.signalEmaPeriod + 1);
            const price = this.GetValue(0, 0);
            this.SetValue(1, 0, k * price + (1 - k) * prevEMA);
        }
    };
};
