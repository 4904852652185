// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Connection } from "../../Commons/Connection.ts";
import { Resources } from "../../Commons/properties/Resources.ts";
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";
import { TerceraBaseWindowTemplate, TerceraChangePasswordScreenTemplate } from "../../templates.js";
import { TerceraButton } from "../elements/TerceraButton.ts";
import { AdditionalVerificationScreen } from "../screen/AdditionalVerificationScreen.js";
import { TerceraMessageBox } from "./TerceraMessageBox.js";
import { TerceraWindowBase } from "./TerceraWindowBase.js";
import { DataCache } from "../../Commons/DataCache.ts";
import { TerceraButtonStyle } from "../../Utils/Enums/ButtonEnums.ts";

export let TerceraChangePasswordScreen = TerceraWindowBase.extend({
    template: TerceraBaseWindowTemplate,
    data: function ()
    {
        return {
            movable: false,
            showFooter: false,
            resizable: false,
            alwaysMiddle: true,
            focused: true,

            left: 0,
            top: 0,
            width: 240,
            height: 256,

            curPwd: '',
            newPwd: '',
            newPwdConfirm: '',
            allowPreviewPassword: false,
            zIndex: 299,

            btnOKStyle: TerceraButtonStyle.Blue,
            btnCancelStyle: TerceraButtonStyle.Standard
        };
    },
    partials: {
        bodyPartial: TerceraChangePasswordScreenTemplate
    },
    sessionId: '',
    forceCloseOnLogout: false
});

TerceraChangePasswordScreen.prototype.oninit = function ()
{
    TerceraWindowBase.prototype.oninit.apply(this);

    this.set({

        curPwdLabel: Resources.getResource('screen.changepass.old'),
        newPwdLabel: Resources.getResource('screen.changepass.new'),
        newPwdConfirmLabel: Resources.getResource('screen.changepass.confirm'),

        curPwdPlaceholder: Resources.getResource('screen.changepass.watermark_old'),
        newPwdPlaceholder: Resources.getResource('screen.changepass.watermark_new'),
        newPwdConfirmPlaceholder: Resources.getResource('screen.changepass.watermark_confirm'),

        okText: Resources.getResource('screen.changepass.ok'),
        cancelText: Resources.getResource('screen.changepass.cancel'),

        allowPreviewPassword: DataCache.AllowPreviewPassword
    });

    this.on('ok', this.onInputDataConfirmed);
    this.on('cancel', this.close);
};

TerceraChangePasswordScreen.prototype.oncomplete = function ()
{
    TerceraWindowBase.prototype.oncomplete.apply(this);
    this.center();
};

TerceraChangePasswordScreen.prototype.onInputDataConfirmed = function ()
{
    let curPwd = this.get('curPwd'),
        newPwd = this.get('newPwd'),
        newPwdConfirm = this.get('newPwdConfirm')

    let incorrectCurrentPassword = !curPwd

    if (!this.tradingPassword)                                                          // проверка совпадения реального и введенного паролей без участия сервера (до отправки запроса на смену пароля) делается только для пароля входа (см.коммент ниже)
        incorrectCurrentPassword = curPwd != Connection.vendor.loginParams.pass     // #114012 (отмечу комментарием странность: такой же кейс для trading pass даст AR тикета (в том числе на десктопе) - вероятно поскольку без участия сервера сравнить значение в поле Current trading password и реально торгового пароля несколько(вероятно в бесконечно раз) сложнее чем провернуть то же самое для пароля входа. ИМХО правильнее бьіло б не делать сравнение с реал.паролем до отправки на сервер, а изменить для описанной ситуации текст ошибки на "New password cannot be empty" вьіделив в отдельн.кейс сообщения пользователю и приведя к одному поведению для пароля входа и торгового, но ето куча работьі, не только (веб, десктоп) девов а еще и локализаторов, так что пока (в настоящее время и так пребьівая в огромной куче работьі) вместо поднятия шумихи ограничусь текущим комментарием :)

    if (incorrectCurrentPassword)
    {
        this.ShowMsg(Resources.getResource("screen.changepass.wrongOldOrNewPassword"), () => this.clearFields());
        return;
    }

    if (!newPwd)
    {
        this.ShowMsg(Resources.getResource("BusinessRejectMessage.203"), () => this.clearFields());
        return;
    }
    if (newPwd !== newPwdConfirm)
    {
        this.ShowMsg(Resources.getResource("screen.changepass.alert_not_match"), () => this.clearFields());
        return;
    }

    this.tradingPassword ? this.changeTradingPassword() : this.changePassword()
};

TerceraChangePasswordScreen.prototype.changePassword = function ()
{
    Connection.ChangePassword(this.get('curPwd'), this.get('newPwd'), this.UserID, /*verificationPassword*/ null)
        .then(this.changePasswordRusult.bind(this));
}

TerceraChangePasswordScreen.prototype.changePasswordRusult = function (result)
{
    if (result === null)
        return;

    if (result.RejectCode)
    {
        let rjKeY = "BusinessRejectMessage." + result.RejectCode;
        let msg = result.errText;
        if (Resources.IsResourcePresentEN(rjKeY))
            msg = Resources.getResource(rjKeY);

        this.ShowMsg(msg, () => this.clearFields());
        return;
    }

    let status = result.Status;
    switch (status)
    {
        case ChangePasswordStatusEnum.STATUS_ACCEPTED:
            this.ShowMsg(Resources.getResource("screen.changepass.changed.login"));

            //TODO Ugly
            Connection.lastConnectionAttemptResponse = { text: "" };

            this.close(this, true);
            Connection.disconnect();
            return;

        case ChangePasswordStatusEnum.STATUS_NEED_VERIFICATION:
            var okCallBack = function (verificationPassword)
            {
                Connection.ChangePassword(this.get('curPwd'), this.get('newPwd'), this.UserID, verificationPassword)
                    .then(this.changePasswordRusult.bind(this));
            }.bind(this);

            var cancelCallBack = function () { this.close() }.bind(this);
            AdditionalVerificationScreen.Show(null, okCallBack, cancelCallBack, { allowPreviewPassword: this.get('allowPreviewPassword') });
            return

        case ChangePasswordStatusEnum.STATUS_WRONG_VERIFICATION:
            this.ShowMsg(Resources.getResource("screen.changepass.wrongVerification"));
            this.close();
            // Connection.disconnect();    // #99859 (commented out due to #115329)
            return

        case ChangePasswordStatusEnum.STATUS_STRONG_PAS_LOW:
            let MsgLow = Resources.getResource("system.ChangePassLow").replace("{0}", result.MinLength)
            this.ShowMsg(MsgLow, () => this.clearFields());
            return;

        case ChangePasswordStatusEnum.STATUS_STRONG_PAS_BASE:
            let MsgBase = Resources.getResource("system.ChangePassBase").replace("{0}", result.MinLength)
            this.ShowMsg(MsgBase, () => this.clearFields());
            return;

        case ChangePasswordStatusEnum.STATUS_STRONG_PAS_HIGH:
            let MsgHigh = Resources.getResource("system.ChangePassHigh").replace("{0}", result.MinLength)
            this.ShowMsg(MsgHigh, () => this.clearFields());
            return;

        case ChangePasswordStatusEnum.STATUS_LOCKED_AFTER_BRUT_FORCE:
            this.ShowMsg(Resources.getResource("screen.changepass.lockedAfterBrut"), () => this.clearFields());
            return;

        case ChangePasswordStatusEnum.STATUS_WRONG_OLD_OR_NEW_PASSWORD:
            this.ShowMsg(Resources.getResource("screen.changepass.wrongOldOrNewPassword"), () => this.clearFields());
            return;

        case ChangePasswordStatusEnum.STATUS_PWD_MUST_DE_DIFFERENT:
            this.ShowMsg(Resources.getResource("screen.changepass.pwdMustBeDifferent"), () => this.clearFields());
            return;

        case ChangePasswordStatusEnum.STATUS_WRONG_MAX_LENGTH:
            this.ShowMsg(Resources.getResource("system.TooLongPass").replace("{0}", result.MaxLength), () => this.clearFields());
            return;

        case ChangePasswordStatusEnum.STATUS_PWD_IS_TOO_EASY:
            this.ShowMsg(Resources.getResource("screen.changepass.tooeasy"), () => this.clearFields());
            return;
    }
}

TerceraChangePasswordScreen.prototype.clearFields = function ()
{
    this.set({
        curPwd: "",
        newPwd: "",
        newPwdConfirm: ""
    })
}

TerceraChangePasswordScreen.prototype.changeTradingPassword = function ()
{
    Connection.ChangeTradingPassword(this.get('curPwd'), this.get('newPwd'))
        .then((result) =>
        {
            this.clearFields();

            if (result === null)
            {
                this.close(this, true);
                return
            }

            if (result.RejectCode)
            {
                this.ShowMsg(Resources.getResource("BusinessRejectMessage." + result.RejectCode));
                return;
            }

            if (result.Status === ChangePasswordStatusEnum.STATUS_ACCEPTED)
                this.ShowMsg(Resources.getResource("screen.changepass.changed"));

            if (result.Status === ChangePasswordStatusEnum.STATUS_WRONG_MAX_LENGTH)
                this.ShowMsg(Resources.getResource("system.TooLongPass").replace("{0}", result.MaxLength));

            this.close(this, true);
        });
}

TerceraChangePasswordScreen.prototype.close = function (sender, fromOk)
{
    if (!fromOk && this.cancelCallBack)
        this.cancelCallBack();
    TerceraWindowBase.prototype.close.call(this);
}
TerceraChangePasswordScreen.show = function (UserID, OldPass, cancelCB, isTradingPassword)
{
    if (MainWindowManager.TerceraChangePasswordScreen)
        MainWindowManager.TerceraChangePasswordScreen.setFocus();
    else
    {
        var scr = new TerceraChangePasswordScreen();
        MainWindowManager.TerceraChangePasswordScreen = scr;

        scr.on('teardown', function ()
        {
            MainWindowManager.TerceraChangePasswordScreen = null;
        });

        scr.set({
            header: isTradingPassword ?
                Resources.getResource('screen.changeTradingPass.title') :
                Resources.getResource('screen.changepass.title')
        })

        let mainAcc = DataCache.MainAccountNew;
        if (!UserID && mainAcc)
            UserID = mainAcc.userID

        scr.UserID = UserID || DataCache.getUserID();
        scr.OldPass = OldPass;
        scr.cancelCallBack = cancelCB;
        scr.tradingPassword = isTradingPassword || false
        MainWindowManager.MainWindow.addControl(scr);
    }
};

TerceraChangePasswordScreen.prototype.ShowMsg = function (text, closeHandler)
{
    if (!closeHandler)
        closeHandler = null;

    const additionalData = { okText: Resources.getResource('general.messageBox.ok') };

    TerceraMessageBox.Show(this.get('header'), text, TerceraMessageBox.msgType.Info, closeHandler, closeHandler, false, true, null, additionalData, true);
}

export let ChangePasswordStatusEnum =
{
    STATUS_UNKNOWN_ERROR: -1,
    STATUS_ACCEPTED: 0, // "accepted"
    STATUS_NEED_VERIFICATION: 1, //needVerification 
    STATUS_WRONG_VERIFICATION: 2, //wrongVerification
    STATUS_STRONG_PAS_LOW: 3, // Character register and number required
    STATUS_STRONG_PAS_BASE: 4, // Character register and number required + number from server(819 field) char at least
    STATUS_STRONG_PAS_HIGH: 5, // Character register and number required + number from server(819 field) char at least + special char requirement
    STATUS_LOCKED_AFTER_BRUT_FORCE: 6,
    STATUS_WRONG_OLD_OR_NEW_PASSWORD: 7,
    STATUS_WRONG_KEY: 8,
    STATUS_PWD_MUST_DE_DIFFERENT: 9,
    STATUS_ERROR_EMAIL: 10,
    STATUS_WRONG_LOGIN: 11,
    STATUS_WRONG_MAX_LENGTH: 12,
    STATUS_PWD_IS_TOO_EASY: 13      // #112524 -> #113076
}