// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class RequestRecoveryPassword extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_REQUEST_RECOVERY_PASSWORD_MESSAGE, fieldSet);
    }

    public setLogin (login: string): void {
        this.setFieldValue(FieldsFactory.FIELD_LOGIN, login);
    }

    public setEmail (email: string): void {
        this.setFieldValue(FieldsFactory.FIELD_EMAIL, email);
    }
}
