// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { ErrorInformationStorage } from '../../../Commons/ErrorInformationStorage';
import { Rectangle } from '../../../Commons/Geometry';
import { Color } from '../../../Commons/Graphics';
import { QuickTableCell } from './QuickTableCell';
import { ColouringModes } from './QuickTableColumn';
import { type QuickTable } from './QuickTable';
import { type BaseItem } from '../../cache/BaseItem';

// #region
export class QuickTableRow<ItemType extends BaseItem = any> {
    public table: QuickTable<ItemType>;
    public item: ItemType;
    public cells: QuickTableCell[] = [];

    public BackColor: string | null = null;
    public ForeColor: string | null = null;

    public visible: boolean = true;
    public collapsedFIFO: boolean = true; // for SuperPosition and its trades (FIFO) #80010
    public sortIndex: number;
    public displayRectangle: Rectangle = new Rectangle();
    public id: any;
    public isGroupRow: boolean;

    constructor (item?: ItemType, table?: QuickTable<ItemType>) {
        this.table = table;
        this.item = item;

        if (isNullOrUndefined(table)) { return; }
        if (isNullOrUndefined(item)) { return; }

        const cells = this.cells;
        const len = table.columns.length;
        try {
            for (let i = 0; i < len; i++) {
                const cc = new QuickTableCell();

                const data = item.getColumnData(i);
                cc.value = data.Value;
                cc.formattedValue = data.FormattedValue;
                cc.groupByValue = data.GroupByValue || data.FormattedValue; // The context menu action "Group by" is processed using this value, e.g., #114165.
                cc.QuickTableEditingInfo = item.GetQuickTableEditingInfo(i);
                cc.ReadOnly = !cc.QuickTableEditingInfo;
                cc.ForeColor = item.GetCellForeColorMap(i);
                cc.BackColor = item.GetCellBackColorMap(i);
                cells.push(cc);
                if (this.table.columns[i].ColouringMode !== ColouringModes.None) {
                    this.ColorCells(i, cc.value, 0);
                }
            }
            this.id = item.ItemId;
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            console.log('Creare QuickTableRow throw an exception = ' + ex.message);
        }
    }

    public Dispose (): void {
        if (!isNullOrUndefined(this.item)) {
            this.item.Dispose();
        }
    }

    public FillRowByItem (item, forceUpdate?): void {
        const indexes = [];
        //            List<int> indexes = new List<int>();
        // for (int i = 0; i < cells.Count; i++)
        // {
        //    if ((table.columns[i].visible && !table.columns[i].Hidden && !table.columns[i].IsCustom) || table.forceUpdateColumns.Contains(i))
        //        indexes.Add(i);
        // }

        // TODO
        for (let i = 0; i < this.cells.length; i++) {
            indexes.push(i);
        }

        forceUpdate = item.isNeedFireUpdate || forceUpdate;
        item.isNeedFireUpdate = false;

        this.FillRowByItemColumnsToUpdate(item, indexes, forceUpdate);
    }

    public FillRowByItemColumnsToUpdate (item, columnsToUpdate, forceUpdate): void {
        for (let i = 0; i < columnsToUpdate.length; i++) {
            const columnIndex = columnsToUpdate[i];
            if (columnIndex >= this.table.columns.length /* || this.table.columns[columnIndex].IsCustom */) {
                continue;
            }

            // TODO
            if (!this.table.columns[i].visible) {
                continue;
            }

            let val;
            const prevVal = this.cells[columnIndex].value;

            let newFormatValue;
            try {
                const value = item.getColumnValue(i);
                // TODO isNAN
                if (prevVal === value && !forceUpdate /* || isNaN(value) */) {
                    continue;
                }

                const data = item.getColumnData(i);

                val = data.Value;
                newFormatValue = data.FormattedValue;

                this.cells[columnIndex].isDirty = newFormatValue !== this.cells[columnIndex].formattedValue || item.NoIgnoreForceUpdateMapIndex[i] && forceUpdate;

                this.cells[columnIndex].formattedValue = newFormatValue;
                this.cells[columnIndex].value = val;
                this.cells[columnIndex].groupByValue = data.GroupByValue || data.FormattedValue;

                if (this.table.columns[columnIndex].ColouringMode !== ColouringModes.None) {
                    this.ColorCells(columnIndex, val === null ? 0 : val, prevVal === null ? 0 : prevVal);
                }
            // this.ColorCells(columnIndex, this.cells[i].value, 0);
            } catch (e) {
                ErrorInformationStorage.GetException(e);
            }
        }
    }

    public ColorCells (columnIndex, value, prevValue, recolor?): void {
        let newBackColor = Color.Empty;
        let newForeColor = Color.Empty;

        // Recoloring cells
        if (recolor) {
            if (this.table.columns[columnIndex].ColouringMode === ColouringModes.Signed) {
                if (this.cells[columnIndex].LastColoring > 0) {
                    newBackColor = this.table.columns[columnIndex].ValueUpBackColor;
                    newForeColor = this.table.columns[columnIndex].ValueUpForeColor;
                } else if (this.cells[columnIndex].LastColoring < 0) {
                    newBackColor = this.table.columns[columnIndex].ValueDownBackColor;
                    newForeColor = this.table.columns[columnIndex].ValueDownForeColor;
                } else {
                // Color by default rules
                    const v = QuickTableRow.ColorBySign(this.cells[columnIndex], this.table.columns[columnIndex], value);
                    newBackColor = v.newBackColor;
                    newForeColor = v.newForeColor;
                }
            } else if (this.table.columns[columnIndex].ColouringMode === ColouringModes.Previous) {
                if (this.cells[columnIndex].LastColoring > 0) {
                    newBackColor = this.table.columns[columnIndex].ValueUpBackColor;
                    newForeColor = this.table.columns[columnIndex].ValueUpForeColor;
                } else if (this.cells[columnIndex].LastColoring < 0) {
                    newBackColor = this.table.columns[columnIndex].ValueDownBackColor;
                    newForeColor = this.table.columns[columnIndex].ValueDownForeColor;
                }
            }
        } else {
            this.cells[columnIndex].LastColoring = 0;

            if (this.table.columns[columnIndex].ColouringMode === ColouringModes.Signed) {
                const v = QuickTableRow.ColorBySign(this.cells[columnIndex], this.table.columns[columnIndex], value);
                newBackColor = v.newBackColor;
                newForeColor = v.newForeColor;
            } else if (this.table.columns[columnIndex].ColouringMode === ColouringModes.Previous) {
                if (!prevValue) {
                    return; // https://tp.traderevolution.com/entity/116914
                }

                if (value > prevValue) {
                    newBackColor = this.table.columns[columnIndex].ValueUpBackColor;
                    newForeColor = this.table.columns[columnIndex].ValueUpForeColor;
                    this.cells[columnIndex].ColoringOccured = 60;
                    this.cells[columnIndex].LastColoring = 1;
                } else if (value < prevValue) {
                    newBackColor = this.table.columns[columnIndex].ValueDownBackColor;
                    newForeColor = this.table.columns[columnIndex].ValueDownForeColor;
                    this.cells[columnIndex].ColoringOccured = 60;
                    this.cells[columnIndex].LastColoring = -1;
                }
            }
        }

        // +++ http://tp.pfsoft.net/entity/27781
        if (recolor) {
            this.cells[columnIndex].BackColorRecover = newBackColor;
            this.cells[columnIndex].ForeColorRecover = newForeColor;
        }
        // +++ http://tp.pfsoft.lan/Project/QA/Bug/View.aspx?BugID=17115&acid=74FA48A38CBEE17F6445F76C9BEC721B
        else {
            if (newBackColor) // +++ нужно сбрасывать цвета если recolor режим
            {
                this.cells[columnIndex].BackColor = newBackColor;
            }
            if (newForeColor) // +++ нужно сбрасывать цвета если recolor режим
            {
                this.cells[columnIndex].ForeColor = newForeColor;
            }
        }
    }

    public static ColorBySign (cell, column, value): { newBackColor, newForeColor } {
        let newBackColor = Color.Empty;
        let newForeColor = Color.Empty;
        if (column.EmptyValue != value && value > 0) {
            newBackColor = column.ValueUpBackColor;
            newForeColor = column.ValueUpForeColor;
            cell.LastColoring = 1;
        } else if (column.EmptyValue != value && value < 0) {
            newBackColor = column.ValueDownBackColor;
            newForeColor = column.ValueDownForeColor;
            cell.LastColoring = -1;
        } else {
            newBackColor = Color.Empty;
            newForeColor = Color.Empty;

            // Не сбрасывался цвет в ноле
            // +++ http://tp.pfsoft.net/entity/26545
            cell.BackColor = Color.Empty;
            cell.ForeColor = Color.Empty;
        }
        return { newBackColor, newForeColor };
    }

    public updateItem (item): void {
        this.FillRowByItem(item);
    }
}
