import { type SessionSettings } from '../../../Commons/SessionSettings';
import { GreeksFormatter } from '../../../Commons/cache/OptionMaster/OptionTrader/GreeksFormatter';
import { type OptionTrader } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionTrader';
import { type Position } from '../../../Commons/cache/Position';
import { type ColumnData } from '../BaseItem';
import { type ColumnParams } from '../ColumnParams';
import { PositionItem } from '../PositionItem';
import { Decimal } from 'decimal.js';

export class OptionPositionItem extends PositionItem {
    private readonly _optionTrader: OptionTrader;
    constructor (optionTrader: OptionTrader, position: Position, sessionSettings: typeof SessionSettings, isNetQty?: boolean) {
        super(position, sessionSettings, isNetQty);
        this._optionTrader = optionTrader;
    }

    public override GetColumnParams (column: number): ColumnParams {
        const columnParams = super.GetColumnParams(column);
        switch (column) {
        case PositionItem.DELTA_COL_INDEX:
        case PositionItem.GAMMA_COL_INDEX:
        case PositionItem.VEGA_COL_INDEX:
        case PositionItem.THETA_COL_INDEX:
        case PositionItem.IV_COL_INDEX:
        case PositionItem.RHO_COL_INDEX:
            columnParams.Hidden = false;
            break;
        }
        return columnParams;
    }

    public override getColumnValue (column: any): any {
        let value = super.getColumnValue(column);
        switch (column) {
        case PositionItem.DELTA_COL_INDEX:
            value = new Decimal(this._optionTrader.getGreeks(this.FPosition.Instrument.PutCall, this.FPosition.Instrument).delta).mul(this.FPosition.Amount).toNumber();
            break;
        case PositionItem.GAMMA_COL_INDEX:
            value = new Decimal(this._optionTrader.getGreeks(this.FPosition.Instrument.PutCall, this.FPosition.Instrument).gamma).mul(this.FPosition.Amount).toNumber();
            break;
        case PositionItem.VEGA_COL_INDEX:
            value = new Decimal(this._optionTrader.getGreeks(this.FPosition.Instrument.PutCall, this.FPosition.Instrument).vega).mul(this.FPosition.Amount).toNumber();
            break;
        case PositionItem.THETA_COL_INDEX:
            value = new Decimal(this._optionTrader.getGreeks(this.FPosition.Instrument.PutCall, this.FPosition.Instrument).theta).mul(this.FPosition.Amount).toNumber();
            break;
        case PositionItem.IV_COL_INDEX:
            value = new Decimal(this._optionTrader.getGreeks(this.FPosition.Instrument.PutCall, this.FPosition.Instrument).iv).mul(this.FPosition.Amount).toNumber();
            break;
        case PositionItem.RHO_COL_INDEX:
            value = new Decimal(this._optionTrader.getGreeks(this.FPosition.Instrument.PutCall, this.FPosition.Instrument).rho).mul(this.FPosition.Amount).toNumber();
            break;
        }
        return value;
    }

    public override getColumnData (column: number): ColumnData {
        const data = super.getColumnData(column);
        switch (column) {
        case PositionItem.DELTA_COL_INDEX:
        case PositionItem.GAMMA_COL_INDEX:
        case PositionItem.VEGA_COL_INDEX:
        case PositionItem.THETA_COL_INDEX:
        case PositionItem.RHO_COL_INDEX:
            data.FormattedValue = GreeksFormatter.formatGreek(data.Value);
            break;

        case PositionItem.IV_COL_INDEX:
            data.FormattedValue = GreeksFormatter.formatIVPercent(data.Value);
            break;
        }
        return data;
    }
}
