import { SectorMap } from '../../../../Commons/Heatmap/Maps/SectorMap';
import { HeatmapAreaBaseRenderer } from './HeatmapAreaBaseRenderer';
import { type Point, Rectangle } from '../../../../Commons/Geometry';
import { Pen, SolidBrush, RectangleEdgeFilter } from '../../../../Commons/Graphics';
import { type IHeatmapViewSettings } from '../../../../Commons/Heatmap/Settings/IHeatmapViewSettings';
import { ThemeManager } from '../../../misc/ThemeManager';

export class HeatmapSectorRenderer extends HeatmapAreaBaseRenderer {
    private readonly _allText: string = 'All';
    private readonly _arrowText: string = '>';
    private readonly _undefinedIndustryName: string = 'Undefined industry';
    private readonly _undefinedSectorName: string = 'Undefined sector';
    public static readonly HOVERWIDTH: number = 2;

    private readonly sectorMap: SectorMap;

    private headerAllRectangle: Rectangle = Rectangle.Empty();
    private headerAllSignRectangle: Rectangle = Rectangle.Empty();
    private headerIndustryTextRectangle: Rectangle = Rectangle.Empty();

    constructor (sectorMap: SectorMap, settings: IHeatmapViewSettings) {
        super();
        this.sectorMap = sectorMap;
        this.areaName = isValidString(sectorMap.sectorName) ? sectorMap.sectorName.toUpperCase() : this._undefinedSectorName.toUpperCase();

        this.settings = settings;
    }

    public draw (gr: CanvasRenderingContext2D, mousePoint: Point): void {
        if (!this.sectorMap.Visible) {
            return;
        }

        this.setRectangles(gr);
        const backgroundBrush = new SolidBrush(this.settings.sectorLevelBackgroundColor);
        gr.FillRectWithRect(backgroundBrush, this.sectorMap.Rectangle);

        if (!this.headerAllRectangle.IsEmpty()) {
            this.drawHeaderSelector(gr, mousePoint);
        } else {
            const font = ThemeManager.Fonts.Font_14F_regular;
            const headerBackgroundBrush = this.isHeaderHovered(mousePoint) ? new SolidBrush(ThemeManager.CurrentTheme.HeatmapPanelHeaderHoveredColor) : new SolidBrush(this.settings.sectorLevelBackgroundColor);
            const headerForegroundBrush = new SolidBrush(this.settings.sectorLevelFontColor);
            const sectorName = this.areaName;
            gr.FillRectWithRect(headerBackgroundBrush, this.sectorMap.headerRectangle);
            gr.DrawStringInRect(sectorName, font, headerForegroundBrush, this.headerTextRectangle, false);
            gr.DrawStringInRect(this._arrowText, font, headerForegroundBrush, this.selectorSignRectangle, false);
        }
    }

    public drawSelection (gr: CanvasRenderingContext2D, mousePoint: Point): void {
        if (!this.sectorMap.Visible) {
            return;
        }

        if (this.isHeaderHovered(mousePoint)) {
            const rect = this.sectorMap.Rectangle;
            const strokeLineWidth = HeatmapSectorRenderer.HOVERWIDTH;
            gr.strokeStyle = ThemeManager.CurrentTheme.HeatmapPanelHeaderHoveredColor;
            gr.lineWidth = strokeLineWidth;
            gr.strokeRect(rect.X + 1 + SectorMap.BORDER, rect.Y + SectorMap.BORDER + 1, rect.Width - strokeLineWidth - 2 * SectorMap.BORDER, rect.Height - strokeLineWidth - 2 * SectorMap.BORDER);
        }
    }

    private drawHeaderSelector (gr: CanvasRenderingContext2D, mousePoint: Point): void {
        const font = ThemeManager.Fonts.Font_14F_regular;
        const foregroundDefaultBrush = new SolidBrush(this.settings.sectorLevelFontColor);
        const foregroundHoveredBrush = new SolidBrush(ThemeManager.CurrentTheme.HeatmapPanelNavigationHoveredForegroundColor);
        const backgroundHoveredBrush = new SolidBrush(ThemeManager.CurrentTheme.HeatmapPanelNavigationHoveredBackgroundColor);
        const borderHoveredPen = new Pen(ThemeManager.CurrentTheme.HeatmapPanelNavigationHoveredBorderColor);

        if (this.isAllHovered(mousePoint)) {
            const allRect = this.headerAllRectangle;
            gr.RoundRectangle(allRect, 2, backgroundHoveredBrush, borderHoveredPen, false, RectangleEdgeFilter.All);
            gr.DrawStringInRect(this._allText, font, foregroundHoveredBrush, allRect, false);
        } else {
            gr.DrawStringInRect(this._allText, font, foregroundDefaultBrush, this.headerAllRectangle, false);
        }

        gr.DrawStringInRect(this._arrowText, font, foregroundDefaultBrush, this.headerAllSignRectangle, false);

        const sectorName = this.areaName;
        if (this.isSectorNameHovered(mousePoint)) {
            const sectorRect = this.headerTextRectangle;
            gr.RoundRectangle(sectorRect, 2, backgroundHoveredBrush, borderHoveredPen, false, RectangleEdgeFilter.All);
            gr.DrawStringInRect(sectorName, font, foregroundHoveredBrush, this.headerTextRectangle, false);
        } else {
            gr.DrawStringInRect(sectorName, font, foregroundDefaultBrush, this.headerTextRectangle, false);
        }

        if (!this.selectorSignRectangle.IsEmpty()) {
            gr.DrawStringInRect(this._arrowText, font, foregroundDefaultBrush, this.selectorSignRectangle, false);
        }

        const selectedIndustry = this.sectorMap.selectedIndustry;
        if (!this.headerIndustryTextRectangle.IsEmpty() && !isNullOrUndefined(selectedIndustry)) {
            gr.DrawStringInRect(this.getSelectedIndustryName(), font, foregroundDefaultBrush, this.headerIndustryTextRectangle, false);
        }
    }

    private isHeaderHovered (mousePoint: Point): boolean {
        return !mousePoint.IsEmpty() && this.sectorMap.headerRectangle.Contains(mousePoint.X, mousePoint.Y);
    }

    public isAllHovered (mousePoint: Point): boolean {
        return !mousePoint.IsEmpty() && this.headerAllRectangle.Contains(mousePoint.X, mousePoint.Y);
    }

    public isSectorNameHovered (mousePoint: Point): boolean {
        return !mousePoint.IsEmpty() && !this.headerAllRectangle.IsEmpty() && this.headerTextRectangle.Contains(mousePoint.X, mousePoint.Y);
    }

    private setRectangles (gr: CanvasRenderingContext2D): void {
        const font = ThemeManager.Fonts.Font_14F_regular;
        const headerRectangle = this.sectorMap.headerRectangle;
        const leftOffset = 5;
        const selectedIndustry = this.sectorMap.selectedIndustry;
        if (this.sectorMap.isSelected || !isNullOrUndefined(selectedIndustry)) {
            const allSize = gr.GetTextSize('All', font, true);
            allSize.width += 8;
            allSize.height += 10;
            const signWidth = gr.GetTextSize('>', font, true).width;
            this.headerAllRectangle = new Rectangle(headerRectangle.X + leftOffset, Math.round(headerRectangle.Y + headerRectangle.Height / 2 - allSize.height / 2), allSize.width, allSize.height);

            const headerTextSize = gr.GetTextSize(this.areaName, font, true);
            headerTextSize.width += 8;
            headerTextSize.height += 10;
            this.headerAllSignRectangle = new Rectangle(this.headerAllRectangle.Right() + leftOffset, headerRectangle.Y, signWidth, headerRectangle.Height);
            this.headerTextRectangle = new Rectangle(this.headerAllSignRectangle.Right() + leftOffset, Math.round(headerRectangle.Y + headerRectangle.Height / 2 - headerTextSize.height / 2), headerTextSize.width, headerTextSize.height);
            if (!isNullOrUndefined(selectedIndustry)) {
                const industryWidth = gr.GetTextSize(this.getSelectedIndustryName(), font, true).width;
                this.selectorSignRectangle = new Rectangle(this.headerTextRectangle.Right() + leftOffset, headerRectangle.Y, signWidth, headerRectangle.Height);
                this.headerIndustryTextRectangle = new Rectangle(this.selectorSignRectangle.Right() + leftOffset, headerRectangle.Y, industryWidth, headerRectangle.Height);
            } else {
                this.selectorSignRectangle = Rectangle.Empty();
                this.headerIndustryTextRectangle = Rectangle.Empty();
            }
        } else {
            this.headerAllRectangle = Rectangle.Empty();
            this.headerAllSignRectangle = Rectangle.Empty();
            this.headerIndustryTextRectangle = Rectangle.Empty();

            const textWidth = gr.GetTextSize(this.areaName, font, true).width;
            const signWidth = gr.GetTextSize('>', font, true).width;
            this.headerTextRectangle = new Rectangle(headerRectangle.X + leftOffset, headerRectangle.Y + 1, Math.min(textWidth, headerRectangle.Width - leftOffset - signWidth - leftOffset), headerRectangle.Height - 1); // We add 1 to Y and minus 1 from Height so that the stroke does not overlap the text
            this.selectorSignRectangle = new Rectangle(this.headerTextRectangle.Right() + leftOffset, headerRectangle.Y, signWidth, headerRectangle.Height);
        }
    }

    private getSelectedIndustryName (): string {
        const selectedIndustry = this.sectorMap.selectedIndustry;
        if (isNullOrUndefined(selectedIndustry)) {
            return '';
        }
        return isValidString(selectedIndustry.industryName) ? selectedIndustry.industryName.toUpperCase() : this._undefinedIndustryName.toUpperCase();
    }
}
