// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PFixFieldSet } from '../../../Fields/FieldsImport';
import { FieldsFactory } from '../../../Factory/FieldsFactory';

export class AlgorithmGainersLosersExchangeGroup extends PFixFieldSet {
    constructor (pfixFieldSet: PFixFieldSet) {
        super(FieldsFactory.ALGORITHM_GAINERS_LOSERS_EXCHANGE_GROUP, null, pfixFieldSet);
    }

    get ExchangeName (): string { return super.GetValue(FieldsFactory.FIELD_MD_EXCHANGE_NAME); }
}
