// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { ContainerControl } from '../ContainerControl_ts';
import { DropDownSideBarTemplate } from '../../../templates.js';
import { Resources } from '../../../Commons/properties/Resources';
import { PanelNames } from '../../UtilsClasses/FactoryConstants';
import { CustomEvent } from '../../../Utils/CustomEvents';
import { AlertSideBarController } from '../../../Commons/SideBarController/AlertSideBarController';

export class DropDownSideBar extends ContainerControl {
    public static ClosePanel = new CustomEvent();

    constructor () {
        super();
        // this.setFocus();
    }

    public getType (): string {
        return 'DropDownSideBar';
    }

    oninit (): void {
        super.oninit();
        super.on('onCloseClick', this.hide);
        super.on('onUpdateHeader', this.localizeHeader);
        super.observe('activeSidePanel', this.changePanel);
    }

    changePanel (): void {
        this.setVisibleActivePanel();
        this.localizeHeader();
    }

    hide (): void {
        DropDownSideBar.ClosePanel.Raise();
    }

    setVisibleActivePanel (): void {
        const activeSide = super.get('activeSidePanel');
        const whichPanel = {
            Alert: false,
            Ideas: false,
            Notifications: false,
            VideoWidget: false
        };

        switch (activeSide) {
        case PanelNames.AlertPanel:
            whichPanel.Alert = true;
            break;
        case PanelNames.IdeasPanel:
            whichPanel.Ideas = true;
            break;
        case PanelNames.NotificationsPanel:
            whichPanel.Notifications = true;
            break;
        case PanelNames.VideoWidget:
            whichPanel.VideoWidget = true;
            break;
        };

        void this.set({ whichPanel });
    }

    localizeHeader (): void {
        const activeSide = super.get('activeSidePanel');

        switch (activeSide) {
        case PanelNames.AlertPanel:
            void super.set({ headerLocalText: Resources.getResource('screen.Alerts') + ('  (' + AlertSideBarController.getCountAlerts() + ')') });
            break;
        case PanelNames.IdeasPanel:
            void super.set('headerLocalText', Resources.getResource('panel.Ideas'));
            break;
        case PanelNames.NotificationsPanel:
            void super.set('headerLocalText', Resources.getResource('panel.Notifications'));
            break;
        case PanelNames.VideoWidget:
            void super.set('headerLocalText', Resources.getResource('panel.VideoWidget'));
            break;
        }
    }

    public onMouseDown (event): void {
        this.get('SideBarPanelRef')?.onMouseDown(event, false);
        super.onMouseDown(event, false);
    }
}

ContainerControl.extendWith(DropDownSideBar,
    {
        template: DropDownSideBarTemplate,
        data: function () {
            return {
                activeSidePanel: null,
                headerLocalText: '',
                SideBarPanelRef: null
            };
        }
    });
