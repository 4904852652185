// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { HistoryType } from '../../../Utils/History/HistoryType';
import { ChartDataType, ChartHistoryType } from '../../../Chart/Utils/ChartConstants';
import { type Instrument } from '../Instrument';
import { type CashItem } from '../History/CashItem';

// TODO. Refactor. UGLY.
export class HistoricalData {
    public _holder: any;
    public cashItem: CashItem;
    public Instrument: Instrument;
    public GetCashItemCount: (ci: CashItem) => number;

    constructor (holder, customInstrument?: Instrument, customCashItem?: CashItem) {
        this._holder = holder;
        this.cashItem = customCashItem || holder.FParent;
        this.Instrument = customInstrument || holder.MainChart?.Instrument();

        // Taken from HistoricalData.cs Apply() method.
        this.GetCashItemCount =
            this.cashItem === holder.FParent
                ? this.BarsCountParent
                : this.BarsCountAny;
    }

    get Indicator () {
        return this._holder;
    }

    get Count (): number {
        return this.GetCashItemCount(this.cashItem);
    }

    get HistoryCount (): number {
        return this.cashItem ? this.cashItem.Count() : 0;
    }

    get Period (): number {
        return this.cashItem.FPeriod;
    }

    // TODO. Refactor. UGLY.
    get Account () {
        return this._holder.MainChart.model.GetAccount();
    }

    get DataType (): number {
        return this.cashItem.HistoryType;
    }

    // TODO. Refactor. UGLY.
    get UseDefaultInstrumentHistoryType (): boolean {
        return this._holder.MainChart.ChartHistoryType() ===
        ChartHistoryType.Default;
    }

    // TODO. Refactor. UGLY.
    get FirstBarOpenTime (): Date {
        const time = this.cashItem.GetOpenTime(0);
        return new Date(time || 0);
    }

    public Time (offset = 0): Date {
        const count = this.GetCashItemCount(this.cashItem);
        if (!count) {
            throw new Error('Cannot get the value from the empty data');
        }

        const time = this.cashItem.GetOpenTime(count - 1 - offset);
        return new Date(time || 0);
    }

    public BarsCountParent (ci: CashItem): number {
        return this._holder.FCount;
    }

    public BarsCountAny (ci: CashItem): number {
        return ci ? ci.Count() : 0;
    }

    public GetPrice (par0, par1?): number {
        if (par0 !== undefined && par1 !== undefined) {
            return this.getPriceByTypeAndOffset(par0, par1);
        }

        if (par0 !== undefined && par1 === undefined) {
            return this.getPriceByType(par0);
        }
    }

    public getPriceByType (priceType): number {
        const ci = this.cashItem;
        const count = this.GetCashItemCount(ci);
        return ci.GetByType(
            count - 1,
            ci.Period !== 0 ? priceType : 2/* bid */);
    }

    public getPriceByTypeAndOffset (priceType, offset: number): number {
        const ci = this.cashItem;
        const count = this.GetCashItemCount(ci);
        if (offset < 0 || offset >= count) {
            throw new Error('offset ' + offset + ' should be non-negative and smaller than Count');
        }

        return ci.GetByType(
            count - 1 - offset,
            ci.Period !== 0 || ci.ChartDataType !== ChartDataType.Default ? priceType : 2/* bid */);
    }

    public Volume (offset?: number): number {
        offset = offset || 0;
        const ci = this.cashItem;
        const count = this.GetCashItemCount(ci);
        if (!count) {
            throw new Error('Cannot get the value from the empty data');
        }

        if (offset < 0 || offset >= count) {
            throw new Error('offset ' + offset + ' should be non-negative and smaller than Count');
        }

        return ci.GetVolume(count - 1 - offset);
    }

    public static HistoricalDataFactory (holder, instrument: Instrument, cashItem: CashItem): TradeData | TickData | BarData {
    // #65320 для агрегаций мы должны использовать BarData
        if (cashItem.Period === 0 && cashItem.ChartDataType === ChartDataType.Default) {
            if (cashItem.HistoryType === HistoryType.QUOTE_TRADES) {
                return new TradeData(holder, instrument, cashItem);
            } else {
                return new TickData(holder, instrument, cashItem);
            }
        } else {
            return new BarData(holder, instrument, cashItem);
        }
    }

    public FindInterval (par0, par1, par2) {
        if (par0 !== undefined && par1 !== undefined && par2 !== undefined) {
            return this.cashItem.FindInterval(par0, par1, par2);
        }

        if (par0 !== undefined && par1 === undefined && par2 === undefined) {
            return this.cashItem.FindIntervalByTime(par0);
        }
    }
}

// #region TradeData

export class TradeData extends HistoricalData {
    constructor (holder, instrument: Instrument, cashItem: CashItem) {
        super(holder, instrument, cashItem);

        throw new Error('Not implemented');
    }
}

// #endregion TradeData

// #region TickData

export class TickData extends HistoricalData {
    constructor (holder, instrument: Instrument, cashItem: CashItem) {
        super(holder, instrument, cashItem);

        throw new Error('Not implemented');
    }
}

// #endregion TickData

// #region BarData

export class BarData extends HistoricalData {

}
// #endregion BarData
