// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { ApplicationPanelNew } from './ApplicationPanelNew';
import { PanelLocKeys, PanelNames } from '../UtilsClasses/FactoryConstants';
import { SavedOrdersPanelTopTemplate } from '../../templates';
import { Resources } from '../../Commons/properties/Resources';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { type OrderEditBase } from '../../Commons/cache/OrderParams/order-edit/OrderEditBase';
import { type SavedOrder } from '../../Commons/cache/SavedOrders/SavedOrder';
import { LinkedPriceType } from '../../Commons/cache/SavedOrders/SavedOrderEnums';
import { SavedOrderItem } from '../cache/SavedOrderItem';
import { SessionSettings } from '../../Commons/SessionSettings';
import { type QuickTableRow } from '../elements/QuickTable/QuickTableRow';
import { MathUtils } from '../../Utils/MathUtils';
import { SlTpPriceType } from '../../Utils/Enums/Constants';
import { DataCache } from '../../Commons/DataCache';
import { OrderType } from '../../Utils/Trading/OrderType';
import { OrderUtils } from '../../Utils/Trading/OrderUtils';
import { GeneralSettings } from '../../Utils/GeneralSettings/GeneralSettings';
import { Quantity } from '../../Utils/Trading/Quantity';
import { TerceraMenu } from '../elements/TerceraMenu';
import { Serialize, DeserializeAsync } from '../../Commons/cache/SavedOrders/SavedOrdersSerializer';
import { SavedOrdersController } from '../../Commons/cache/SavedOrders/SavedOrdersController';
import { SavedOrderValidator } from '../../Commons/cache/SavedOrders/SavedOrderValidator';
import { Color } from '../../Commons/Graphics';
import { ThemeManager } from '../misc/ThemeManager';
import { type Order } from '../../Commons/cache/Order';
import { Position } from '../../Commons/cache/Position';
import { TradingOrderStatus } from '../../Commons/Trading/TradingOrderStatus';
import { ExportScreen } from '../screen/ExportScreen';
import { OperationType } from '../../Utils/Trading/OperationType';
import { messageBoxHandler } from '../../Utils/AppHandlers';

export class SavedOrdersPanel extends ApplicationPanelNew.extend(
    {
        Name: 'SavedOrdersPanel',
        topPanelHeight: 27,
        data: function () {
            return {
                isToolbarVisible: true,
                isEnabledAddButton: true,
                isEnabledCombobox: true,
                isEnabledPlaceButton: true,
                isOeOpened: false,
                editableCBtooltip: '',
                placeButtonText: ''

            };
        },
        partials: {
            bodyPartial: SavedOrdersPanelTopTemplate
        },
        headerLocaleKey: 'panel.savedOrders'
    }) {
    private static readonly EDIT_ORDER: string = 'EDIT_ORDER';
    private static readonly REMOVE_ORDER: string = 'REMOVE_ORDER';

    private _orderEntry: any;

    private _savedOrderForEdit: SavedOrder;

    private abortController: AbortController;

    public quickTableRactive: any;

    public Controls: { editableCB: any };

    public menuTagDict: [];
    public topPanelHeight: number;

    getType (): string { return PanelNames.SavedOrdersPanel; }

    repopulate (): void {
        super.repopulate();
        this.resetList();
    }

    layoutTable (): void {
        super.layoutTable();
    }

    TickAsync (): void {
        super.TickAsync();
    }

    themeChange (): void {
        super.themeChange();
    }

    localize (): void {
        super.localize();
        const placeButtonText = Resources.getResource('panel.savedOrders.placeButtonText');
        super.set('placeButtonText', placeButtonText);

        const contextItems = super.getQuickTable()?.tableContextMenuItems;
        if (!MathUtils.IsNullOrUndefined(contextItems) && contextItems.length > 0) {
            for (let i = 0; i < contextItems.length; i++) {
                const item = contextItems[i];
                if (!MathUtils.IsNullOrUndefined(item?.locKey)) {
                    item.text = Resources.getResource(item.locKey);
                };
            }
        }
        this.Controls.editableCB.localize();
    }

    updateSettings (): void {
        super.updateSettings();
        const rows: QuickTableRow[] = super.getQuickTable().rowsArray;
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].isGroupRow) {
                continue;
            }
            const item: SavedOrderItem = rows[i].item;
            item.updateEditingInfo();
            this.processEditRow(rows[i]);
        }
    }

    oncomplete (): void {
        super.oncomplete();
        super.on('onAddButtonClick', this.onAddSavedOrderClicked);
        super.on('onPlaceButtonClick', this.onPlaceActiveButtonClick);
        super.observe('isToolbarVisible', this.onToolbarVisibleChanged);

        const table = super.getQuickTable();
        if (MathUtils.IsNullOrUndefined(table)) {
            return;
        }

        this.populateTableContextMenu();
        this.Controls.editableCB.OnSelectList.Subscribe(this.onSelectedListChanged, this);
        table.OnPaintedPictureButtonClick.Subscribe(this.onTableRowButtonClicked, this);
        table.AfterEditItem.Subscribe(this.onAfterEditItem, this);
        this.Controls.editableCB.resetList();

        DataCache.OnAddOrder.Subscribe(this.onProcessOpenOrder, this);
        DataCache.OnAddPosition.Subscribe(this.onProcessOpenOrder, this);
    }

    dispose (): void {
        DataCache.OnAddOrder.UnSubscribe(this.onProcessOpenOrder, this);
        DataCache.OnAddPosition.UnSubscribe(this.onProcessOpenOrder, this);

        super.off('onAddButtonClick', this.onAddSavedOrderClicked);
        super.off('onPlaceButtonClick', this.onPlaceActiveButtonClick);

        const table = super.getQuickTable();
        if (MathUtils.IsNullOrUndefined(table)) {
            return;
        }

        this.Controls.editableCB.OnSelectList.UnSubscribe(this.onSelectedListChanged, this);
        table.OnPaintedPictureButtonClick.UnSubscribe(this.onTableRowButtonClicked, this);
        table.AfterEditItem.UnSubscribe(this.onAfterEditItem, this);
        super.dispose();
    }

    preparePopup (): void {
        super.preparePopup();
        const qt = super.getQuickTable();
        const selectedItems: SavedOrderItem[] = qt?.getSelectedTableItems() ?? [];
        for (let i = 0; i < qt.tableContextMenuItems.length; i++) {
            const menuItem: { tag: string, enabled: boolean, tooltip: string, style: string } = qt.tableContextMenuItems[i];
            switch (menuItem.tag) {
            case SavedOrdersPanel.EDIT_ORDER:
                menuItem.enabled = selectedItems.length === 1;
                if (!menuItem.enabled) {
                    menuItem.tooltip = Resources.getResource(selectedItems.length === 0 ? 'panel.orders.menu.NoSymbolInfoDisabled.tt' : 'panel.orders.menu.MoreOneSymbolInfoDisabled.tt');
                } else {
                    menuItem.tooltip = '';
                }
                menuItem.style = menuItem.enabled ? '' : 'js-context-menu-error';
                break;
            case ApplicationPanelNew.SYMBOLINFOPANEL:
            case SavedOrdersPanel.REMOVE_ORDER:
                menuItem.enabled = selectedItems.length > 0;
                menuItem.tooltip = menuItem.enabled ? '' : Resources.getResource('panel.orders.menu.NoSymbolInfoDisabled.tt');
                menuItem.style = menuItem.enabled ? '' : 'js-context-menu-error';
                break;
            }
        }
    }

    private readonly populateTableContextMenu = (): void => {
        const items: object[] = [];

        items.push({
            text: Resources.getResource('panel.savedOrders.menu.AddOrder'),
            locKey: 'panel.savedOrders.menu.AddOrder',
            event: this.onAddSavedOrderClicked
        });
        items.push({
            text: Resources.getResource('panel.savedOrders.menu.EditOrder'),
            locKey: 'panel.savedOrders.menu.EditOrder',
            tag: SavedOrdersPanel.EDIT_ORDER,
            event: this.onEditSavedOrderClicked
        });
        items.push({
            text: Resources.getResource('panel.savedOrders.menu.RemoveOrder'),
            locKey: 'panel.savedOrders.menu.RemoveOrder',
            tag: SavedOrdersPanel.REMOVE_ORDER,
            event: this.onRemoveSavedOrderClicked
        });
        items.push({
            text: Resources.getResource('panel.savedOrders.menu.RemoveAll'),
            locKey: 'panel.savedOrders.menu.RemoveAll',
            event: this.onRemoveAllSavedOrdersClicked
        });

        const viewItem = {
            text: Resources.getResource('panel.positions.menu.View'),
            locKey: 'panel.positions.menu.View',
            tag: 'View',
            enabled: true,
            subitems: []
        };
        viewItem.subitems.push({
            text: Resources.getResource('panel.savedOrders.menu.ShowToolbar'),
            tag: 'ShowToolbar',
            checked: super.get('isToolbarVisible'),
            visible: !Resources.isHidden('panel.savedOrders.menu.ShowToolbar'),
            enabled: true,
            canCheck: true,
            event: this.onShowToolbarMenuItemCheckedChanged
        });

        if (!Resources.isHidden('contextMenu.Totals.visibility')) {
            viewItem.subitems.push({
                text: Resources.getResource('panel.savedOrders.menu.ShowTotals'),
                tag: 'ShowTotals',
                checked: false,
                enabled: true,
                canCheck: true,
                event: super.ShowTotalsStateChange.bind(this)
            });
        }
        items.push(viewItem);
        items.push({ separator: true });
        items.push({
            text: Resources.getResource('panel.savedOrders.menu.SavedOrdersLists'),
            locKey: 'panel.savedOrders.menu.SavedOrdersLists',
            subitems: this.Controls.editableCB.menuItems,
            advParams: { isComboboxMenu: true, isEditableListComboBoxMenu: true }
        });
        super.AddSymbolInfoContextMenuItemIfNeed(items, true);

        if (!Resources.isHidden('screen.export.visibility')) {
            items.push(
                {
                    text: Resources.getResource('screen.export.contextMenu'),
                    locKey: 'screen.export.contextMenu',
                    event: ExportScreen.show.bind(null, this)
                }
            );
        }

        this.menuTagDict = TerceraMenu.createTagDictionary(items);
        super.getQuickTable().setTableContextMenuItems(items);
    };

    private readonly addItem = (savedOrder: SavedOrder): void => {
        const table = super.getQuickTable();
        if (MathUtils.IsNullOrUndefined(table)) {
            return;
        }

        const rowsCount = table.rowsArray.filter((row: QuickTableRow) => !row.isGroupRow).length;
        const allowedRowsCount = MainWindowManager.Factory.GetThrottlingOperationValue(this.getType(), true).rowsNumber;
        if (allowedRowsCount !== -1 && rowsCount >= allowedRowsCount) {
            const title: string = Resources.getResource('workspace.information');
            const message: string = `${Resources.getResource('Rows.firstPart')} ${Resources.getResource(PanelLocKeys[this.getType()])} ${Resources.getResource('Rows.secondPart')}`;
            messageBoxHandler.Show(title, message, messageBoxHandler.msgType.Info, null, null, false, true);
            return;
        }

        const item: SavedOrderItem = new SavedOrderItem(savedOrder, SessionSettings);
        item.subsribeOnUpdate(this.onUpdateItem);
        const row: QuickTableRow = table.AddItem(item);
        this.processEditRow(row);
        this.processErrorRow(row);
        this.updatePlaceButtonEnability();
        this.updateFilterByAccount();
    };

    private readonly updateFilterByAccount = (): void => { // TODO: make union with LinkedSystem.accLinkingActive ? me.enableAccountLinking() : me.disableAccountLinking() from ApplicationPanelNew.oncomplete
        if (super.OnAccountLinking() === true) { super.enableAccountLinking(); } else { super.disableAccountLinking(); }
    };

    private readonly removeItem = (item: SavedOrderItem): void => {
        item.unsubsribeOnUpdate(this.onUpdateItem);
        super.getQuickTable().RemoveItem(item.ItemId);
        this.updatePlaceButtonEnability();
    };

    private readonly clearTable = (): void => {
        const table = super.getQuickTable();
        if (MathUtils.IsNullOrUndefined(table)) {
            return;
        }
        const rows = table.rowsArray;
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].isGroupRow) {
                continue;
            }
            const item: SavedOrderItem = rows[i].item;
            item.unsubsribeOnUpdate(this.onUpdateItem);
        }
        table.ClearRows();
        this.updatePlaceButtonEnability();
    };

    private readonly deserializeSavedOrdersAsync = async (serializedData: string, signal: AbortSignal): Promise<void> => {
        const serializedItems: string[] = serializedData.split(';');
        for (let i = 0; i < serializedItems.length; i++) {
            if (signal.aborted) {
                return;
            }
            const serializedItem: string = serializedItems[i];
            if (serializedItem === '') {
                continue;
            }
            DeserializeAsync(serializedItem)
                .then(savedOrder => {
                    if (signal.aborted) {
                        return;
                    }
                    this.addItem(savedOrder);
                })
                .catch(error => { console.log(error); });
        }
    };

    private readonly processEditRow = (row: QuickTableRow): void => {
        const savedOrder: SavedOrder = row.item.savedOrder;
        for (let i = 0; i < row.cells.length; i++) {
            switch (i) {
            case SavedOrderItem.ACCOUNT_COL_INDEX:
                row.cells[i].ReadOnly = DataCache.getNumberOfAccounts() === 1;
                break;
            case SavedOrderItem.PRICE_COL_INDEX:
                if (savedOrder.LinkedPriceType !== LinkedPriceType.None) {
                    row.cells[i].ReadOnly = true;
                } else {
                    row.cells[i].ReadOnly = savedOrder.OrderType !== OrderType.Limit && savedOrder.OrderType !== OrderType.StopLimit && savedOrder.OrderType !== OrderType.OCO;
                }
                break;
            case SavedOrderItem.STOP_PRICE_COL_INDEX:
                if (savedOrder.LinkedPriceType !== LinkedPriceType.None) {
                    row.cells[i].ReadOnly = true;
                } else {
                    row.cells[i].ReadOnly = savedOrder.OrderType !== OrderType.Stop && savedOrder.OrderType !== OrderType.StopLimit && savedOrder.OrderType !== OrderType.TrailingStop && savedOrder.OrderType !== OrderType.OCO;
                }
                break;
            case SavedOrderItem.LINK_COL_INDEX:
                row.cells[i].ReadOnly = savedOrder.OrderType !== OrderType.Limit && savedOrder.OrderType !== OrderType.Stop;
                break;
            case SavedOrderItem.LINKED_PRICE_OFFSET_COL_INDEX:
                row.cells[i].ReadOnly = savedOrder.LinkedPriceType === LinkedPriceType.None || (savedOrder.OrderType !== OrderType.Limit && savedOrder.OrderType !== OrderType.Stop);
                break;
            case SavedOrderItem.LEVERAGE_COL_INDEX:
                row.cells[i].ReadOnly = !isNullOrUndefined(savedOrder.Instrument) && !savedOrder.Instrument.isLeverageVisible(savedOrder.Account, savedOrder.ProductType);
                break;
            case SavedOrderItem.OPERATION_COL_INDEX:
                row.cells[i].ForeColor = savedOrder.Operation === OperationType.Buy ? ThemeManager.CurrentTheme.TableValueUpForeColor : ThemeManager.CurrentTheme.TableValueDownForeColor;
                break;

            case SavedOrderItem.SL_PRICE_COL_INDEX:
            case SavedOrderItem.SL_OFFSET_COL_INDEX:
                row.cells[i].ReadOnly = isNaN(savedOrder.SlPrice) && !SavedOrderValidator.isAllowSl(savedOrder);
                break;

            case SavedOrderItem.SLL_PRICE_COL_INDEX:
            case SavedOrderItem.SLL_OFFSET_COL_INDEX:
                row.cells[i].ReadOnly = isNaN(savedOrder.SllPrice) && (!SavedOrderValidator.isAllowSl(savedOrder) || !GeneralSettings.TradingDefaults.UseStopLimitInsteadStop);
                break;

            case SavedOrderItem.TP_PRICE_COL_INDEX:
            case SavedOrderItem.TP_OFFSET_COL_INDEX:
                row.cells[i].ReadOnly = isNaN(savedOrder.TpPrice) && !SavedOrderValidator.isAllowTp(savedOrder);
                break;
            }
        }
    };

    private readonly processErrorRow = (row: QuickTableRow): void => {
        const savedOrderItem: SavedOrderItem = row.item;
        for (let i = 0; i < row.cells.length; i++) {
            const savedOrder: SavedOrder = savedOrderItem.savedOrder;
            let validationResult: { isValid: boolean, error: string } = { isValid: true, error: '' };
            switch (i) {
            case SavedOrderItem.ACCOUNT_COL_INDEX:
                validationResult = SavedOrderValidator.isValidAccount(savedOrder);
                break;
            case SavedOrderItem.INSTRUMENT_COL_INDEX:
                validationResult = SavedOrderValidator.isValidInstrument(savedOrder);
                break;

            case SavedOrderItem.QUANTITY_COL_INDEX:
            case SavedOrderItem.DISCLOSED_QUANTITY_COL_INDEX:
                validationResult = SavedOrderValidator.isValidQuantity(savedOrder);
                break;

            case SavedOrderItem.PRICE_COL_INDEX:
                validationResult = SavedOrderValidator.isValidPrice(savedOrder);
                break;
            case SavedOrderItem.STOP_PRICE_COL_INDEX:
                validationResult = SavedOrderValidator.isValidStopPrice(savedOrder);
                break;

            case SavedOrderItem.SL_PRICE_COL_INDEX:
            case SavedOrderItem.SL_OFFSET_COL_INDEX:
                validationResult = SavedOrderValidator.isValidSl(savedOrder);
                break;

            case SavedOrderItem.SLL_PRICE_COL_INDEX:
            case SavedOrderItem.SLL_OFFSET_COL_INDEX:
                validationResult = SavedOrderValidator.isValidSll(savedOrder);
                break;

            case SavedOrderItem.TP_PRICE_COL_INDEX:
            case SavedOrderItem.TP_OFFSET_COL_INDEX:
                validationResult = SavedOrderValidator.isValidTp(savedOrder);
                break;
            }
            row.cells[i].BackColor = validationResult.isValid ? Color.Empty : ThemeManager.CurrentTheme.TableErrorBackgroundColor;
            row.cells[i].TooltipKey = validationResult.error;
        }
    };

    private readonly onUpdateItem = (item: SavedOrderItem): void => {
        const row = super.getQuickTable().getItemById(item.ItemId);
        if (!MathUtils.IsNullOrUndefined(row)) {
            this.processErrorRow(row);
        }
    };

    private readonly onSelectedListChanged = (serializedData: string): void => {
        const table = super.getQuickTable();
        if (MathUtils.IsNullOrUndefined(table)) {
            return;
        }

        this.abortController?.abort();
        this.abortController = new AbortController();

        this.clearTable();
        super.updatePanelHeader();

        this.deserializeSavedOrdersAsync(serializedData, this.abortController.signal).catch(error => {
            console.log(error);
        });
    };

    private readonly onAddSavedOrderClicked = (): void => {
        this._orderEntry = MainWindowManager.Factory?.addPanel(PanelNames.AdvancedOrderEntry, null, this.onOpenOrderEntryPanel);
    };

    private readonly onEditSavedOrderClicked = (): void => {
        const selectedItems: SavedOrderItem[] = super.getQuickTable().getSelectedTableItems();
        if (selectedItems.length > 0) {
            this._savedOrderForEdit = selectedItems[0].savedOrder;
            this.onAddSavedOrderClicked();
        }
    };

    private readonly onRemoveSavedOrderClicked = (): void => {
        super.getQuickTable().RemoveSelectedItems();
        this.saveList();
    };

    private readonly onRemoveAllSavedOrdersClicked = (): void => {
        super.getQuickTable().ClearRows();
        this.saveList();
    };

    private readonly onOpenOrderEntryPanel = (panel: any): void => {
        const isEdit = !isNullOrUndefined(this._savedOrderForEdit);
        panel.OnClose.Subscribe(this.onCloseOrderEntryPanel, this);
        panel.set({
            isCreateOrderParmeters: true,
            placeButtonAdditionalClass: 'alertStyle',
            placeButtonLocalizationKey: isEdit ? 'panel.newOrderEntry.SaveParams' : 'panel.newOrderEntry.AddSavedOrder',
            showCustomFullscreenCloud: true
        });
        if (isEdit) {
            panel.fillByOrderEdit(SavedOrdersController.createOrderEdit(this._savedOrderForEdit, true));
        }
        panel.NewParametrsHandler = this.onCreateSavedOrder;
    };

    private readonly onCloseOrderEntryPanel = (panel: any): void => {
        panel.OnClose.UnSubscribe(this.onCloseOrderEntryPanel, this);
        panel.NewParametrsHandler = null;
        this._orderEntry = undefined;
        this._savedOrderForEdit = undefined;
    };

    private readonly onCreateSavedOrder = (orderEdit: OrderEditBase): void => {
        if (!isNullOrUndefined(this._savedOrderForEdit)) {
            SavedOrdersController.updateByOrderEdit(this._savedOrderForEdit, orderEdit);
        } else {
            const savedOrder = SavedOrdersController.createSavedOrder(orderEdit);
            this.addItem(savedOrder);
        }
        this.saveList();
        orderEdit.dispose();
    };

    private readonly onTableRowButtonClicked = async (data): Promise<any> => {
        const tableItem: SavedOrderItem = data.row.item;

        switch (data.realColumnIndex) {
        case SavedOrderItem.REMOVE_ORDER_COL_INDEX:
            this.removeItem(tableItem);
            break;
        case SavedOrderItem.SUBMIT_COL_INDEX:
            await SavedOrdersController.placeOrders([tableItem.savedOrder]);
            break;
        default:
            return await Promise.resolve(true);
        }

        this.saveList();
    };

    private readonly onAfterEditItem = (data): void => {
        let needResort = false;
        const tableItem: SavedOrderItem = data.row.item;
        switch (data.realColumnIndex) {
        case SavedOrderItem.ACTIVE_COL_INDEX:
            tableItem.savedOrder.Active = !tableItem.savedOrder.Active;
            break;

        case SavedOrderItem.ACCOUNT_COL_INDEX:
            tableItem.savedOrder.Account = data.newValue;
            break;

        case SavedOrderItem.INSTRUMENT_COL_INDEX:
            tableItem.savedOrder.Instrument = data.newValue;
            tableItem.savedOrder.setDefaultPrices();
            tableItem.savedOrder.setLinkedPrice();
            break;

        case SavedOrderItem.OPERATION_COL_INDEX:
            tableItem.savedOrder.Operation = data.newValue;
            break;
        case SavedOrderItem.ORDER_TYPE_COL_INDEX:
            tableItem.savedOrder.OrderType = data.newValue;
            tableItem.savedOrder.setDefaultPrices();
            tableItem.savedOrder.setLinkedPrice();
            break;

        case SavedOrderItem.PRODUCT_TYPE_COL_INDEX:
            tableItem.savedOrder.ProductType = data.newValue;
            break;

        case SavedOrderItem.TIF_COL_INDEX:
            tableItem.savedOrder.TIF = data.newValue.type;
            tableItem.savedOrder.TIFExpiration = data.newValue.expirationTime;
            break;

        case SavedOrderItem.QUANTITY_COL_INDEX:
            tableItem.savedOrder.QuantityLots = Quantity.convertAmountToLots(data.newValue, tableItem.savedOrder.Instrument);
            break;
        case SavedOrderItem.DISCLOSED_QUANTITY_COL_INDEX:
            tableItem.savedOrder.DisclosedQuantityLots = Quantity.convertAmountToLots(data.newValue, tableItem.savedOrder.Instrument);
            break;

        case SavedOrderItem.PRICE_COL_INDEX:
            tableItem.savedOrder.Price = data.newValue;
            break;
        case SavedOrderItem.STOP_PRICE_COL_INDEX:
            if (tableItem.savedOrder.OrderType === OrderType.TrailingStop) {
                tableItem.savedOrder.StopPrice = OrderUtils.toRawTicks(data.newValue, GeneralSettings.TradingDefaults.ShowOffsetIn, tableItem.savedOrder.Instrument);
            } else {
                tableItem.savedOrder.StopPrice = data.newValue;
            }
            break;
        case SavedOrderItem.TRAILING_STOP_COL_INDEX:
            if (tableItem.savedOrder.SLTPHolder.isTrailingStop()) {
                tableItem.savedOrder.SLTPHolder.StopLossPriceType = SlTpPriceType.Offset;
                tableItem.savedOrder.SLTPHolder.TakeProfitPriceType = SlTpPriceType.Offset;
            } else {
                tableItem.savedOrder.SLTPHolder.StopLossPriceType = SlTpPriceType.TrOffset;
            }
            break;
        case SavedOrderItem.SL_PRICE_COL_INDEX:
            tableItem.savedOrder.SLTPHolder.StopLossPriceType = SlTpPriceType.Absolute;
            tableItem.savedOrder.SLTPHolder.StopLossPriceValue = data.newValue === 0 ? NaN : data.newValue;
            break;
        case SavedOrderItem.SLL_PRICE_COL_INDEX:
            tableItem.savedOrder.SLTPHolder.StopLossPriceType = SlTpPriceType.Absolute;
            tableItem.savedOrder.SLTPHolder.StopLossLimitPriceValue = data.newValue === 0 ? NaN : data.newValue;
            break;
        case SavedOrderItem.TP_PRICE_COL_INDEX:
            tableItem.savedOrder.SLTPHolder.TakeProfitPriceType = SlTpPriceType.Absolute;
            tableItem.savedOrder.SLTPHolder.TakeProfitPriceValue = data.newValue === 0 ? NaN : data.newValue;
            break;

        case SavedOrderItem.SL_OFFSET_COL_INDEX:
            tableItem.savedOrder.SLTPHolder.StopLossPriceType = SlTpPriceType.Offset;
            tableItem.savedOrder.SLTPHolder.StopLossPriceValue = data.newValue === 0 ? NaN : OrderUtils.toRawTicks(data.newValue, GeneralSettings.TradingDefaults.ShowOffsetIn, tableItem.savedOrder.Instrument);
            break;
        case SavedOrderItem.SLL_OFFSET_COL_INDEX:
            tableItem.savedOrder.SLTPHolder.StopLossPriceType = SlTpPriceType.Offset;
            tableItem.savedOrder.SLTPHolder.StopLossLimitPriceValue = data.newValue === 0 ? NaN : OrderUtils.toRawTicks(data.newValue, GeneralSettings.TradingDefaults.ShowOffsetIn, tableItem.savedOrder.Instrument);
            break;
        case SavedOrderItem.TP_OFFSET_COL_INDEX:
            tableItem.savedOrder.SLTPHolder.TakeProfitPriceType = SlTpPriceType.Offset;
            tableItem.savedOrder.SLTPHolder.TakeProfitPriceValue = data.newValue === 0 ? NaN : OrderUtils.toRawTicks(data.newValue, GeneralSettings.TradingDefaults.ShowOffsetIn, tableItem.savedOrder.Instrument);
            break;

        case SavedOrderItem.LINKED_PRICE_OFFSET_COL_INDEX:
            tableItem.savedOrder.LinkedPriceOffset = OrderUtils.toRawTicks(data.newValue, GeneralSettings.TradingDefaults.ShowOffsetIn, tableItem.savedOrder.Instrument);
            tableItem.savedOrder.setLinkedPrice();
            break;
        case SavedOrderItem.LINK_COL_INDEX:
            tableItem.savedOrder.LinkedPriceType = data.newValue;
            tableItem.savedOrder.setLinkedPrice();
            break;

        case SavedOrderItem.LEVERAGE_COL_INDEX:
            tableItem.savedOrder.Leverage = data.newValue;
            needResort = true;
            break;
        }

        this.processEditRow(data.row);
        this.processErrorRow(data.row);
        if (needResort) {
            super.getQuickTable().updateRowsCellsValues(true);
        } else {
            data.row.FillRowByItem(tableItem, true);
        }
        this.updatePlaceButtonEnability();
        this.quickTableRactive.redraw();
        this.saveList();
    };

    private readonly onPlaceActiveButtonClick = async (): Promise<void> => {
        const rows: QuickTableRow[] = this.quickTableRactive.quickTable.rowsArray;
        const ordersForPlace: SavedOrder[] = [];
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].isGroupRow) {
                continue;
            }
            const item: SavedOrderItem = rows[i].item;
            if (item.savedOrder.Active) {
                ordersForPlace.push(item.savedOrder);
            }
        }
        await SavedOrdersController.placeOrders(ordersForPlace);
        this.saveList();
    };

    private readonly onProcessOpenOrder = (orderOrPosition: Order | Position): void => {
        const comment: string | null = orderOrPosition.Comment;
        if (comment !== null && comment.startsWith('SavedOrder.')) {
            const id = comment.substring('SavedOrder.'.length);
            const rows = super.getQuickTable().rowsArray;
            for (let i = 0; i < rows.length; i++) {
                if (rows[i].isGroupRow) {
                    continue;
                }
                const savedOrder: SavedOrder = rows[i].item.savedOrder;
                if (savedOrder.Id === id) {
                    if (orderOrPosition instanceof Position) {
                        savedOrder.OpenedId = orderOrPosition.PositionId;
                        savedOrder.Status = TradingOrderStatus.Filled;
                    } else {
                        savedOrder.OpenedId = orderOrPosition.OrderNumber;
                        savedOrder.Status = TradingOrderStatus.Opened;
                    }
                    this.saveList();
                    break;
                }
            }
        }
    };

    private readonly onShowToolbarMenuItemCheckedChanged = (menuItem: { checked: boolean }): void => {
        super.set('isToolbarVisible', menuItem.checked);
    };

    private readonly onToolbarVisibleChanged = (newValue: boolean, oldValue: boolean): void => {
        this.topPanelHeight = newValue ? 27 : 0;
        super.layoutTable();
    };

    private readonly saveList = (): void => {
        const editableCB = this.Controls.editableCB;
        const rows = super.getQuickTable().rowsArray;
        if (MathUtils.IsNullOrUndefined(editableCB)) {
            return;
        };
        const currentList = editableCB.get('selectedItem');
        if (MathUtils.IsNullOrUndefined(currentList)) {
            return;
        };
        const allLists = editableCB.get('valueOfLists');
        const list = allLists[currentList.value];

        list.itemsStr = '';
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].isGroupRow) {
                continue;
            }
            const item: SavedOrderItem = rows[i].item;
            list.itemsStr += Serialize(item.savedOrder) + ';';
        }

        editableCB.updateListsValue(null, false);
    };

    private readonly resetList = (): void => {
        const editableCB = this.Controls.editableCB;
        if (MathUtils.IsNullOrUndefined(editableCB)) {
            return;
        };
        editableCB.resetList();
    };

    private readonly updatePlaceButtonEnability = (): void => {
        const rows = super.getQuickTable().rowsArray;
        let enabled = false;
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].isGroupRow) {
                continue;
            }
            const item: SavedOrderItem = rows[i].item;

            if (item.savedOrder.Active) {
                enabled = true;
                break;
            }
        }
        super.set('isEnabledPlaceButton', enabled);
    };
}
