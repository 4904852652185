// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { CustomEvent } from '../../Utils/CustomEvents';
import { Resources, CurrentLang, LOCALE_PT } from '../../Commons/properties/Resources';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { TerceraBaseWindowTemplate, TerceraOnboardingScreenTemplate, TerceraOnboardingScreenEnTemplate, TerceraOnboardingScreenPtTemplate } from '../../templates';
import { GeneralSettings } from '../../Utils/GeneralSettings/GeneralSettings';
import { SessionSettings } from '../../Commons/SessionSettings';
import { TerceraWindowBase } from './TerceraWindowBase_ts';
import $ from 'jquery';

export class TerceraOnboardingScreen extends TerceraWindowBase {
    lastindex: number = 1;

    public getType (): string {
        return 'TerceraOnboardingScreen';
    };

    static MAX_SLIDE_COUNT_POSTFIX = 12;
    static MAX_SLIDE_COUNT_POSTFIX_BRAZILIAN = 9;

    constructor () {
        super();
    }

    oncomplete (): void {
        super.oncomplete();

        if (CurrentLang === LOCALE_PT) {
            void this.set({ LOCALE_Pt: true, brazilian: '_Brazilian', counterText: '1/' + TerceraOnboardingScreen.MAX_SLIDE_COUNT_POSTFIX_BRAZILIAN });
        }

        $(window).on('resize', this.updateSize.bind(this));
        this.updateSize();

        this.on('nextClick', this.nextClick);
        this.on('backClick', this.backClick);
        this.on('doneClick', this.doneClick);
        this.localize();
    };

    nextClick (): void {
        this.SwapActiveSlide(this.lastindex + 1);
        const el = document.getElementById('slider_' + (this.lastindex));
        if (el) {
            el.click();
        } else { this.lastindex--; }

        this.setParamOnboarding();
    };

    backClick (): void {
        this.SwapActiveSlide(this.lastindex - 1);
        const el = document.getElementById('slider_' + this.lastindex);
        if (el) { el.click(); } else { this.lastindex++; }

        this.setParamOnboarding();
    };

    inputClick (index): void {
        this.SwapActiveSlide(index);

        this.setParamOnboarding();
    };

    SwapActiveSlide (index): void {
        const lastSlide = this.find('#slide_number_' + this.lastindex);
        const newSlide = this.find('#slide_number_' + index);

        if (lastSlide) { lastSlide.classList.remove('active-slide'); }

        if (newSlide) { newSlide.classList.add('active-slide'); }

        this.lastindex = index;
    };

    doneClick (): void {
        this.close();
    };

    dispose (): void {
        GeneralSettings.General.OnboardInFirstTimeShowed = true;
        SessionSettings.save();
        this.raiseOnBoardingShowed();
        super.dispose();
    };

    setParamOnboarding (): void {
        const maxCount = CurrentLang === LOCALE_PT
            ? TerceraOnboardingScreen.MAX_SLIDE_COUNT_POSTFIX_BRAZILIAN
            : TerceraOnboardingScreen.MAX_SLIDE_COUNT_POSTFIX;

        void this.set({ counterText: this.lastindex + '/' + maxCount, showButton: this.lastindex });

        if (this.lastindex === maxCount) {
            void this.set({ style_addition_body: 'onboarding-screen-addition-body-no-bg', closeBtnVisible: false });
        } else {
            void this.set({ style_addition_body: 'onboarding-screen-addition-body', closeBtnVisible: true });
        }
    };

    localize (): void {
        void this.set({
            nextButtonText: Resources.getResource('pageNavigation.nextButton'),
            backButtonText: Resources.getResource('pageNavigation.backButton'),
            doneButtonText: Resources.getResource('pageNavigation.doneButton'),
            slide1_header1: Resources.getResource('onBoarding.slide1.header1'),
            slide1_text1: Resources.getResource('onBoarding.slide1.text1'),
            slide2_header1: Resources.getResource('onBoarding.slide2.header1'),
            slide2_text1: Resources.getResource('onBoarding.slide2.text1'),
            slide2_text2: Resources.getResource('onBoarding.slide2.text2'),
            slide3_header1: Resources.getResource('onBoarding.slide3.header1'),
            slide3_header2: Resources.getResource('onBoarding.slide3.header2'),
            slide3_header3: Resources.getResource('onBoarding.slide3.header3'),
            slide3_text1: Resources.getResource('onBoarding.slide3.text1'),
            slide3_text2: Resources.getResource('onBoarding.slide3.text2'),
            slide3_text3: Resources.getResource('onBoarding.slide3.text3'),
            slide4_header1: Resources.getResource('onBoarding.slide4.header1'),
            slide4_text1: Resources.getResource('onBoarding.slide4.text1'),
            slide5_header1: Resources.getResource('onBoarding.slide5.header1'),
            slide5_text1: Resources.getResource('onBoarding.slide5.text1'),
            slide5_text2: Resources.getResource('onBoarding.slide5.text2'),
            slide5_text3: Resources.getResource('onBoarding.slide5.text3'),
            slide6_header1: Resources.getResource('onBoarding.slide6.header1'),
            slide6_text1: Resources.getResource('onBoarding.slide6.text1'),
            slide6_text2: Resources.getResource('onBoarding.slide6.text2'),
            slide7_header1: Resources.getResource('onBoarding.slide7.header1'),
            slide7_text1: Resources.getResource('onBoarding.slide7.text1'),
            slide8_header1: Resources.getResource('onBoarding.slide8.header1'),
            slide8_text1: Resources.getResource('onBoarding.slide8.text1'),
            slide9_header1: Resources.getResource('onBoarding.slide9.header1'),
            slide9_text1: Resources.getResource('onBoarding.slide9.text1'),
            slide10_header1: Resources.getResource('onBoarding.slide10.header1'),
            slide10_text1: Resources.getResource('onBoarding.slide10.text1'),
            slide10_text2: Resources.getResource('onBoarding.slide10.text2'),
            slide11_header1: Resources.getResource('onBoarding.slide11.header1'),
            slide11_header2: Resources.getResource('onBoarding.slide11.header2'),
            slide11_text1: Resources.getResource('onBoarding.slide11.text1'),
            slide11_text2: Resources.getResource('onBoarding.slide11.text2'),
            slide12_header1: Resources.getResource('onBoarding.slide12.header1')
        });
    };

    updateSize (): void {
        const $window = $(window);
        const WinWidth = $window.outerWidth();
        const WinHeight = $window.outerHeight();

        if (WinWidth <= 1330 || WinHeight <= 850) {
            void this.set('width', 860);
            void this.set('height', 520);
            void this.set('small', '_small');
        }

        if (WinWidth >= 1330 && WinHeight >= 850) {
            void this.set('width', 1240);
            void this.set('height', 750);
            void this.set('small', '');
        }

        const widthControls = this.get('width');
        const heightControls = this.get('height') + 95;

        const offsetW = (WinWidth - widthControls);
        const offsetH = (WinHeight - heightControls);

        const leftOffset = offsetW > 0 ? offsetW / 2 : offsetW;
        const topOffset = offsetH > 0 ? offsetH / 2 : 1;

        void this.set({ left: leftOffset, top: topOffset });
    };

    raiseOnBoardingShowed (): void // #110922 вызвать и затем отписаться от методов подписанных на событие срабатывающее после показа OnBoarding
    {
        TerceraOnboardingScreen.ReleaseSubscription();
    }

    static ReleaseSubscription (): void {
        TerceraOnboardingScreen.OnBoardingShowed.Raise();
        const handlers = TerceraOnboardingScreen.OnShowedSubscriptionHandlers;
        for (let i = 0; i < handlers.length; i++) {
            TerceraOnboardingScreen.OnBoardingShowed.UnSubscribe(handlers[i], null);
        }

        TerceraOnboardingScreen.OnShowedSubscriptionHandlers = [];
    }

    static AddOnShownSubscribe (caller): void // #110922 добавляет подписку на event по окончанию показа OnBoarding
    {
        TerceraOnboardingScreen.OnShowedSubscriptionHandlers.push(caller);
        TerceraOnboardingScreen.OnBoardingShowed.Subscribe(caller, null);
    }

    static OnBoardingShowed = new CustomEvent(); // событие срабатывающее по окончанию показа OnBoarding в TerceraOnboardingScreen.prototype.dispose
    static OnShowedSubscriptionHandlers = []; // функции подписанные на event по окончанию показа OnBoarding

    static show (): void {
        const screen = new TerceraOnboardingScreen();
        MainWindowManager.MainWindow.addControl(screen);
    };
}

TerceraWindowBase.extendWith(TerceraOnboardingScreen,
    {
        template: TerceraBaseWindowTemplate,
        data: function () {
            return {
                movable: false,
                showHeader: true,
                showFooter: false,
                resizable: false,
                canSelect: false,
                alwaysMiddle: false,
                focused: true,
                showBorder: false,
                showFullscreenCloud: false,
                showModalMask: true,
                width: 1240,
                height: 750,
                top: 60,
                left: 340,
                style_addition_body: 'onboarding-screen-addition-body onboarding-screen-addition-body-no-bg',
                style_addition_header: 'onboarding-screen-addition-header',
                btnOnboardingNextStyle: 'js-button-onboarding-next',
                showButton: 1,
                LOCALE_Pt: false,
                counterText: '1/' + TerceraOnboardingScreen.MAX_SLIDE_COUNT_POSTFIX,
                small: '',
                brazilian: ''
            };
        },
        partials: {
            bodyPartial: TerceraOnboardingScreenTemplate,
            EnPartial: TerceraOnboardingScreenEnTemplate,
            PtPartial: TerceraOnboardingScreenPtTemplate
        }
    });
