// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from '../Utils/CustomEvents';
import { Resources } from '../Commons/properties/Resources';
import { ChartDataType, TerceraChartDrawingType, ChartHistoryType } from './Utils/ChartConstants';
import { TerceraChartActionState } from './TerceraChartActionState';
import { TerceraChartUtils, TerceraChartHistoryType } from './TerceraChartUtils';
import { ChartAvailablePeriods, PeriodDesc, Periods, TFInfo, TradeAnalysisType } from '../Utils/History/TFInfo';
import { SessionInfo } from '../Utils/History/SessionInfo';
import { DynProperty, DynPropertyRelationType } from '../Commons/DynProperty';
import { type Instrument } from '../Commons/cache/Instrument';
import { type Account } from '../Commons/cache/Account';
import { type TerceraChart } from './TerceraChart';
import { Enum } from '../Utils/Enum';

export class TerceraChartMVCModel {
    public DataCache: any;
    public CurrentAllowedStyles: TerceraChartDrawingType[] = [];
    public CurrentAllowedPeriods: PeriodDesc[] = [];
    /// <summary>
    /// readonly!! use SelectHistoryPeriod(lastPeriodDesc); to set data
    /// </summary>
    public HistoryFrom;
    public CurrentTFTypeSelectedStyle = {};// new Dictionary<string, TerceraChartDrawingType>();
    public ModelItemChanged = new CustomEvent();
    public skipUpdateTimeFrameInfoDuringUpdateProperties = false;

    public prevInstrument;
    /// <summary>
    /// readonly!! use Instrument to set data
    /// </summary>
    private instrument: Instrument | null;
    /// <summary>
    /// readonly!! use Account to set data
    /// </summary>
    private account: Account;

    private timeFrameInfo: TFInfo = new TFInfo();

    private chartDrawingType = TerceraChartDrawingType.Candle;

    private chartHistoryType = ChartHistoryType.Default;

    private historyTo = new Date();
    private lastPeriodDesc;
    private showZeroes = false;
    private showAllSessions = true;

    constructor (dataCache) {
        this.DataCache = dataCache;
    }

    /// <summary>
    /// Активный инструмент
    /// </summary>
    public SetInstrument (value: Instrument): void {
        this.prevInstrument = this.instrument;

        this.instrument = value;
        // http://tp.pfsoft.net/entity/57686
        if (this.chartHistoryType === ChartHistoryType.Default) {
            if (this.instrument !== null && this.timeFrameInfo !== null) {
                this.timeFrameInfo.HistoryType = this.instrument.DefaultChartHistoryType;
            }
        }

        this.UpdateTFRelation();

        this.OnModelItemChanged(new TerceraChartMVCModelItem(ModelDataType.Instrument));
    }

    public GetInstrument (): Instrument | null {
        if (this.instrument) {
            return this.instrument;
        } else {
            return null;
        }
    }

    /// // <summary>
    /// // #42695
    /// // чтобы при измении настроек ChartDrawingType через другие контролы при открытом окне PropertiesScreen не применялись настройки из PropertiesScreen, потом при сохранении
    /// // </summary>
    // int? remeberChartDrawingType = null;

    /// <summary>
    /// Активный аккаунт
    /// </summary>
    public SetAccount (value: Account): void {
        this.account = value;

        this.UpdateTFRelation();
        this.OnModelItemChanged(new TerceraChartMVCModelItem(ModelDataType.Account));
    }

    public GetAccount (): Account {
        return this.account;
    }

    /// <summary>
    /// TODO: обновлять на смену всего что влияет: инструмент/аккаунт/historyType/...
    /// </summary>
    public SetTimeFrameInfo (value: TFInfo): void {
    /// tmp

        let periodWasChanged = false;
        if (this.timeFrameInfo && value && value.Periods !== this.timeFrameInfo.Periods) {
            periodWasChanged = true;
        }

        //    // refresh chart when timeFrameInfo changed: timeFrameInfo, Plan, HistoryType,
        //    if (value != null && (value != timeFrameInfo || ((instrument != null && account != null) && (timeFrameInfo.Plan != account.SpreadPlan || timeFrameInfo.HistoryType != instrument.HistoryType || timeFrameInfo.SessionInfo.OnlyMainSession == ShowAllSessions))))
        // {
        //        if ((!BaseApplication.App.MultiDataCache.isAllowedForMyUser(RulesSet.FUNCTION_CHART_CLUSTER) && value.TradeAnalysisType == TradeAnalysisType.Cluster) ||
        //       (!BaseApplication.App.MultiDataCache.isAllowedForMyUser(RulesSet.FUNCTION_CHART_MARKET_PROFILE) && value.TradeAnalysisType == TradeAnalysisType.MarketProfile))
        //            return;

        //        var needToSetDefaultHistoryType = timeFrameInfo != null && timeFrameInfo.TradeAnalysisType == TradeAnalysisType.Cluster;

        //        var spreadPlan = value.Plan;
        //        if (account != null)
        //            spreadPlan = account.SpreadPlan;
        //        else if (BaseApplication.App.Settings != null)
        //            spreadPlan = BaseApplication.App.Settings.SpreadPlan;

        //        if (instrument != null)
        //            // todo !!!! HistoryType
        //            timeFrameInfo = value.Copy(TerceraChartHistoryType.GetOriginalHistoryType(chartHistoryType, instrument), spreadPlan, new SessionInfo(!showAllSessions));
        //        else
        //            timeFrameInfo = value;

        //        //

        this.timeFrameInfo = value;

        this.timeFrameInfo.Plan = this.DataCache.GetSpreadPlan(this.account);

        this.OnModelItemChanged(new TerceraChartMVCModelItem(ModelDataType.TFI));

        //        //
        this.UpdateStylesRelation();
        this.UpdateHistoryTypeRelation();
        //        // For cluster chart - only by trades (last) is allowed.
        //        if (timeFrameInfo.TradeAnalysisType == TradeAnalysisType.Cluster)
        //        {
        //            if (ChartHistoryType != TerceraChartHistoryTypeEnum.ByTrades)
        //                ChartHistoryType = TerceraChartHistoryTypeEnum.ByTrades;
        //        }
        //        else if(needToSetDefaultHistoryType)
        //        {
        //            ChartHistoryType = TerceraChartHistoryTypeEnum.Default;
        //        }
        // }
        //    // всегда фром дейт
        this.UpdateRangeRelation(periodWasChanged);
    }

    public GetTimeFrameInfo (): TFInfo | null {
        return this.timeFrameInfo;
    }

    public UpdateTFRelation (): void {
        if (this.instrument == null) {
            return;
        }

        // first init
        if (this.timeFrameInfo === null) {
        // default on open new chart 1 minute
            const tfInfo = new TFInfo();
            tfInfo.HistoryType = this.instrument.DefaultChartHistoryType;
            tfInfo.SessionInfo = new SessionInfo(!this.showAllSessions);
            this.SetTimeFrameInfo(tfInfo);
        } else {
            if (!isNullOrUndefined(this.timeFrameInfo.SessionInfo)) {
                this.timeFrameInfo.SessionInfo.OnlyMainSession = !this.showAllSessions;
            }
            this.SetTimeFrameInfo(this.timeFrameInfo); // update if need
        }
    }

    public SetChartDrawingType (value: TerceraChartDrawingType): void {
        this.chartDrawingType = value;
        // save selected style
        const key = this.GetTFTypeKey(this.timeFrameInfo);
        if (key != null) {
            this.CurrentTFTypeSelectedStyle[key] = this.chartDrawingType;
        }

        this.UpdateStylesRelation();
    }

    public GetChartDrawingType (): TerceraChartDrawingType {
        return this.chartDrawingType;
    }

    public GetAllowedStyles (currentSelection): TerceraChartDrawingType[] {
        if (!currentSelection) {
            return [];
        }

        const result: TerceraChartDrawingType[] = [];

        switch (currentSelection.ChartDataType) {
        case ChartDataType.Default:
        // Cluster.
            if (currentSelection.TradeAnalysisType() === TradeAnalysisType.Cluster) {
                result.push(TerceraChartDrawingType.Cluster);
            }
            // Market profile.
            else if (currentSelection.TradeAnalysisType() === TradeAnalysisType.MarketProfile) {
                result.push(TerceraChartDrawingType.Profile);
            } else if (currentSelection.Periods === 0)// 1 Tick
            {
                result.push(TerceraChartDrawingType.Line);
            } else if (currentSelection.Periods % Periods.RANGE === 0) // Range
            {
                result.push(TerceraChartDrawingType.Line);
                result.push(TerceraChartDrawingType.Bar);
                result.push(TerceraChartDrawingType.Candle);
                result.push(TerceraChartDrawingType.Dot);
                result.push(TerceraChartDrawingType.DotLine);
                result.push(TerceraChartDrawingType.Forest);
                result.push(TerceraChartDrawingType.Solid);
            } else {
                result.push(TerceraChartDrawingType.Line);
                result.push(TerceraChartDrawingType.Bar);
                result.push(TerceraChartDrawingType.Candle);
                result.push(TerceraChartDrawingType.Dot);
                result.push(TerceraChartDrawingType.DotLine);
                result.push(TerceraChartDrawingType.Forest);
                result.push(TerceraChartDrawingType.Solid);
            }
            break;

        case ChartDataType.Renco:
            result.push(TerceraChartDrawingType.Renko);
            result.push(TerceraChartDrawingType.Line);
            result.push(TerceraChartDrawingType.Dot);
            result.push(TerceraChartDrawingType.DotLine);
            result.push(TerceraChartDrawingType.Forest);
            result.push(TerceraChartDrawingType.Solid);
            break;

        case ChartDataType.Kagi:
            result.push(TerceraChartDrawingType.Kagi);
            break;

        case ChartDataType.ThreeLinesBreak:
            result.push(TerceraChartDrawingType.LinesBreak);
            result.push(TerceraChartDrawingType.Line);
            result.push(TerceraChartDrawingType.Dot);
            result.push(TerceraChartDrawingType.DotLine);
            result.push(TerceraChartDrawingType.Forest);
            result.push(TerceraChartDrawingType.Solid);
            break;

        case ChartDataType.TicTac:
            result.push(TerceraChartDrawingType.TicTac);
            result.push(TerceraChartDrawingType.Candle);
            break;
        // case ChartDataType.Volume:
        default:
            result.push(TerceraChartDrawingType.Candle);
            break;
        }

        // TODO. Refactor.
        const filteredResult = [];

        const len = result.length;
        for (let i = 0; i < len; i++) {
            const style = result[i];

            const locKey = TerceraChartUtils.getChartDrawingTypeLocKey(style);
            if (Resources.isHidden(locKey)) continue;

            filteredResult.push(style);
        }

        return filteredResult;
    }

    public UpdateStylesRelation (): void {
        const currentSelection = this.timeFrameInfo;
        const CurrentAllowedStyles = this.GetAllowedStyles(currentSelection);
        this.CurrentAllowedStyles = CurrentAllowedStyles;
        const CurrentTFTypeSelectedStyle = this.CurrentTFTypeSelectedStyle;
        // restore selected style
        const key = this.GetTFTypeKey(currentSelection);

        const currentTFTypeSelectedStyleItem = CurrentTFTypeSelectedStyle[key];

        if (CurrentAllowedStyles.includes(currentTFTypeSelectedStyleItem)) {
            this.chartDrawingType = currentTFTypeSelectedStyleItem;
        } else if (CurrentAllowedStyles.includes(TerceraChartDrawingType.Candle) && currentSelection.ChartDataType !== ChartDataType.TicTac) {
            this.chartDrawingType = TerceraChartDrawingType.Candle;
        } else {
            this.chartDrawingType = CurrentAllowedStyles[0];
        }

        this.OnModelItemChanged(new TerceraChartMVCModelItem(ModelDataType.Style));
    }

    public UpdateHistoryTypeRelation (): void {
    // if (this.instrument == null || this.timeFrameInfo == null)
    //    return;

        // var lSourceTypes = new List < int > (instrument.DataCache.GetAllowedHistoryTypesByPeriod(timeFrameInfo.Period));
        // var ok = false;
        /// / Visibility
        // if (ChartHistoryType == TerceraChartHistoryTypeEnum.Default)
        //    ok = true;
        // else if (ChartHistoryType == TerceraChartHistoryTypeEnum.ByBid)

        //    ok = lSourceTypes.Contains(timeFrameInfo.HistoryType) && timeFrameInfo.Period != Periods.TIC;

        // else if (ChartHistoryType == TerceraChartHistoryTypeEnum.ByBidAsk)
        //    ok = lSourceTypes.Contains(timeFrameInfo.HistoryType) && timeFrameInfo.Period == Periods.TIC;
        // else
        //    ok = lSourceTypes.Contains(timeFrameInfo.HistoryType);

    // if (!ok)
    //    ChartHistoryType = TerceraChartHistoryTypeEnum.Default;
    }

    public GetTFTypeKey (currentSelection): string {
        let keyToSaveSelectedStyle = null;
        if (currentSelection != null) {
            switch (currentSelection.ChartDataType) {
            case ChartDataType.Default:
                if (currentSelection.Periods === 0)// 1 Tick
                {
                    keyToSaveSelectedStyle = '1Tick';
                } else if (currentSelection.Periods % Periods.RANGE === 0) // Range
                {
                    keyToSaveSelectedStyle = 'Range';
                } else {
                    keyToSaveSelectedStyle = 'Time';
                }
                break;
            case ChartDataType.Renco:
            case ChartDataType.Kagi:
            case ChartDataType.ThreeLinesBreak:
            case ChartDataType.TicTac:
                // case ChartDataType.Volume:
            default:
                keyToSaveSelectedStyle = currentSelection.ChartDataType.toString();
                break;
            }
        }
        return keyToSaveSelectedStyle;
    }

    public GetPeriodDesc (): any {
        return this.lastPeriodDesc;
    }

    public SetPeriodDesc (value): void {
        if (this.lastPeriodDesc != value) {
            this.lastPeriodDesc = value;

            this.SelectHistoryPeriod(this.lastPeriodDesc);
            this.OnModelItemChanged(new TerceraChartMVCModelItem(ModelDataType.Range));
        }
    }

    public UpdateRangeRelation (resetToDefault: boolean /* = false */): void {
        if (!this.timeFrameInfo) {
            return;
        }

        const CurrentAllowedPeriods = ChartAvailablePeriods.GetAvaialbleCounts(this.timeFrameInfo.Periods);
        this.CurrentAllowedPeriods = CurrentAllowedPeriods;

        let containCurrentPeriodDesc = false;
        const len = CurrentAllowedPeriods.length;
        const lastPeriodDesc = this.GetPeriodDesc();
        for (let i = 0; i < len; i++) {
            if (lastPeriodDesc?.equals(this.CurrentAllowedPeriods[i])) {
                containCurrentPeriodDesc = true;
                break;
            }
        }

        // Current value of PeriodDesc is inccorect for current TifeFrameInfo
        if (resetToDefault || !containCurrentPeriodDesc) {
            this.SetPeriodDesc(ChartAvailablePeriods.GetDefaultValueOfPeriod(this.timeFrameInfo.Periods));
        } else {
            this.SelectHistoryPeriod(lastPeriodDesc);
        }
        // Check whether default PeriodDesc contains in CurrentAllowedPeriods ???

        //
        this.OnModelItemChanged(new TerceraChartMVCModelItem(ModelDataType.Range));
    }

    /// <summary>
    /// Временные границы истории. (UTC)
    /// </summary>
    public GetHistoryTo (): Date {
    // if (RangeMode == TerceraChartHistoryRangeMode.Custom)
    //    return this.historyTo;
    // else
    // {
        const now = new Date();
        this.historyTo = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);

        return this.historyTo;
    // }
    }

    public SetHistoryTo (value: Date): void {
        this.historyTo = value;
    }

    /// <summary>
    /// Тип временного интервала - от текущий даты, кастомный интервал (HDM например)
    /// </summary>
    // public RangeMode = TerceraChartHistoryRangeMode.Last;

    public SelectHistoryPeriod (descr): void {
    // DateTime now = HistoryTo;

        // var dataCache = instrument != null && instrument.DataCache != null ? instrument.DataCache : BaseApplication.App.MultiDataCache.DCache;

        // !for hdm
        // const HistoryDataMode = false;
        // bool customPeriodMode = false;
        // DateTime CustomModeSpecifiedDate = DateTime.MinValue;
        /// / !for hdm
        // if (customPeriodMode && CustomModeSpecifiedDate != DateTime.MinValue) // надо сравнивать также с мин валуе!
        //    now = CustomModeSpecifiedDate;

        // var from = BaseApplication.App.Settings.ConvertSelectedTimeZoneToUTCTime(HistoryTo, dataCache);
        const from = this.GetHistoryTo();
        let fromDateNumber: number;
        if (descr.Period === Periods.MIN) {
        // if (!customPeriodMode)
            // {
            // nicky fix for tick-based history
            // Для тиков специально, чтобы отсчитывали не от 12 ночи, а от текущего времени
            // а то чувк просит 5 минут, это с без пяти полночь до полуночи, и получает болт
            const now = new Date();
            fromDateNumber = now.setMinutes(now.getMinutes() - descr.Koef);

            /// / ? а оно нам еще надо ?
            /// /+++yura - корректировка интервала времени для HDM(и для кастом инстр.) - 2.пункт: http://tp.pfsoft.net/Project/QA/Bug/View.aspx?BugID=18896&acid=74FA48A38CBEE17F6445F76C9BEC721B
            // if (HistoryDataMode || this.Instrument is InstrumentHistory)
            // {
            //    now = Utils.DateTimeUtcNow;
            //    //корректируем время на локальное смещение(UTC), потому что в "DataExtender::RecalcIndices" происходит преобразование к времени UTC
            //    TimeSpan sp = System.TimeZone.CurrentTimeZone.GetUtcOffset(Utils.DateTimeNow);
            //    now = now.AddHours(sp.Hours);
            //    from = from.AddHours(sp.Hours);
            // }
            // }
        // else
        //    from = from.AddMinutes(-descr.Koef);
        } else if (descr.Period === Periods.DAY) {
        // Учитываем выходные
            if (true) {
            //    ProcessWeekendDays(out DateTime from, DateTime to, int daysCount)
            // {
                const daysCount = descr.Koef;

                let minCount = 0;
                while (minCount < daysCount) {
                    from.setDate(from.getDate() - 1);
                    if (from.getDay() !== 0 && from.getDay() !== 6) {
                        minCount++;
                    }
                }

                fromDateNumber = from.getTime();
            // }
            } else {
                fromDateNumber = from.setDate(from.getDate() - descr.Koef);
            }
        } else if (descr.Period === Periods.MONTH) {
            fromDateNumber = from.setMonth(from.getMonth() - descr.Koef);
        } else if (descr.Period === Periods.YEAR) {
            fromDateNumber = from.setFullYear(from.getFullYear() - descr.Koef);
        }

        // HistoryTo = now;
        this.HistoryFrom = new Date(fromDateNumber);
    }

    /// <summary>
    /// History type
    /// </summary>
    public GetChartHistoryType (): ChartHistoryType {
        return this.chartHistoryType;
    }

    public SetChartHistoryType (value: ChartHistoryType): void {
        this.chartHistoryType = value;

        const timeFrameInfo = this.GetTimeFrameInfo();
        if (timeFrameInfo) {
            if (!timeFrameInfo.SessionInfo) // https://tp.traderevolution.com/entity/103886 из callback приходил TFInfo не содержащий SessionInfo и переписывающий тот в котором SessionInfo было
            {
                timeFrameInfo.SessionInfo = new SessionInfo(!this.GetShowAllSessions());
            }

            this.SetTimeFrameInfo(timeFrameInfo.Copy({
                period: timeFrameInfo.Periods,
                historyType: TerceraChartHistoryType.GetOriginalHistoryType(this.chartHistoryType, this.instrument)
            }));
        }

        this.OnModelItemChanged(new TerceraChartMVCModelItem(ModelDataType.HistoryType));
    }

    /// <summary>
    /// Учитывать дырки при индексации
    /// </summary>
    public GetShowZeroes (): boolean {
        return this.showZeroes;
    }

    public SetShowZeroes (value: boolean): void {
        this.showZeroes = value;
        this.OnModelItemChanged(new TerceraChartMVCModelItem(ModelDataType.ShowEmptyBars));
    }

    public GetShowAllSessions (): boolean {
        return this.showAllSessions;
    }

    public SetShowAllSessions (value: boolean): void {
        this.showAllSessions = value;
        this.UpdateTFRelation();
        this.OnModelItemChanged(new TerceraChartMVCModelItem(ModelDataType.ShowAllSession));
    }

    public Dispose (): void {
        this.account = null;
        this.instrument = null;
        this.prevInstrument = null;
    }

    public OnModelItemChanged (item: TerceraChartMVCModelItem): void {
        this.ModelItemChanged.Raise(item);
    }

    // #region ICaller

    public Properties (): DynProperty[] {
        const properties: DynProperty[] = [];
        let prop: DynProperty = null;
        const timeFrameInfo = this.GetTimeFrameInfo();
        const chartDrawingType = this.GetChartDrawingType();
        const chartHistoryType = this.GetChartHistoryType();
        const instrument = this.GetInstrument();

        if (timeFrameInfo) {
            const tradeAnalysisType = timeFrameInfo.TradeAnalysisType();
            if (tradeAnalysisType !== TradeAnalysisType.Cluster) {
                if (tradeAnalysisType !== TradeAnalysisType.MarketProfile) {
                    prop = new DynProperty('gr_type', chartDrawingType, DynProperty.COMBOBOX_COMBOITEM, DynProperty.DATA_STYLE_GROUP);
                    const objVariants = [];
                    const allowedStyles = this.GetAllowedStyles(timeFrameInfo);
                    const allowedStyles_len = allowedStyles.length;
                    for (let i = 0; i < allowedStyles_len; i++) {
                        const allowedStyle = allowedStyles[i];
                        objVariants.push({
                            text: TerceraChartUtils.ToLocalizedTerceraChartDrawingType(allowedStyle),
                            value: allowedStyle
                        });
                    }
                    prop.objectVariants = objVariants;
                    prop.assignedProperty = [
                        'bodyColor',
                        'borderColor',
                        'wickColor',
                        'barsHiLowColor',
                        'solidPriceColor',
                        'HistogramLineColor',
                        'Doji'
                    ];
                    prop.DynPropertyRelationType = DynPropertyRelationType.Visibility;
                    prop.COMBOBOX_TYPE = DynProperty.INTEGER;
                    prop.sortIndex = 0;
                    properties.push(prop);
                }

                prop = new DynProperty('HistoryType', chartHistoryType, DynProperty.COMBOBOX_COMBOITEM, DynProperty.DATA_STYLE_GROUP);
                const historyTypeArray = [];
                if (timeFrameInfo) {
                    const dataTypes_keys = Enum.TakeKeysFromEnum(ChartHistoryType);
                    const dataTypes_keys_len = dataTypes_keys.length;
                    for (let i = 0; i < dataTypes_keys_len; i++) {
                        const dt = ChartHistoryType[dataTypes_keys[i]];
                        const state = TerceraChartActionState.GetHistoryTypeActionState(
                            new TerceraChartHistoryType(dt), timeFrameInfo.Periods, instrument, chartHistoryType);
                        if (state.Visible) {
                            historyTypeArray.push({ text: Resources.getResource(state.locKey), value: dt });
                        }
                    }
                }
                prop.objectVariants = historyTypeArray;
                prop.sortIndex = 1;
                prop.COMBOBOX_TYPE = DynProperty.INTEGER;
                properties.push(prop);
            }
        }
        // Show
        const SeparatorGroup = '#2#' + Resources.getResource('property.SeparatorGroup.Show');

        prop = new DynProperty('ShowEmptyBars', this.GetShowZeroes(), DynProperty.BOOLEAN, DynProperty.TIME_SCALE_GROUP);
        prop.separatorGroup = SeparatorGroup;
        prop.sortIndex = 1;
        properties.push(prop);

        prop = new DynProperty('showAllSessions', this.GetShowAllSessions(), DynProperty.BOOLEAN, DynProperty.TIME_SCALE_GROUP);
        prop.separatorGroup = SeparatorGroup;
        prop.sortIndex = 2;
        properties.push(prop);

        // TFI
        if (timeFrameInfo) {
            prop = new DynProperty('timeFrameInfo', timeFrameInfo.Serialize(), DynProperty.STRING, DynProperty.HIDDEN_GROUP);
            prop.sortIndex = 2;
            properties.push(prop);
        }

        // PeriodDesc
        const lastPeriodDesc = this.GetPeriodDesc();
        if (lastPeriodDesc) {
            properties.push(new DynProperty('HistoryPeriod', lastPeriodDesc.Period, DynProperty.INTEGER, DynProperty.HIDDEN_GROUP));
            properties.push(new DynProperty('HistoryKoef', lastPeriodDesc.Koef, DynProperty.INTEGER, DynProperty.HIDDEN_GROUP));
        }

        return properties;
    }

    public callBack (properties): void {
        let needRecreateCacheItem = false;
        //
        // from model
        //
        let dp = DynProperty.getPropertyByName(properties, 'HistoryType');
        // + проверяет что изменилось чтоб зря не перегружать чарт
        if (dp && this.GetChartHistoryType() !== dp.value) {
            this.SetChartHistoryType(dp.value);
            needRecreateCacheItem = true;
        }

        dp = DynProperty.getPropertyByName(properties, 'showAllSessions');
        // + проверяет что изменилось чтоб зря не перегружать чарт
        if (dp && this.GetShowAllSessions() !== dp.value) {
            this.SetShowAllSessions(dp.value);
            needRecreateCacheItem = true;
        }

        // + проверяет что изменилось чтоб зря не перегружать чарт
        dp = DynProperty.getPropertyByName(properties, 'ShowEmptyBars');
        if (dp && this.GetShowZeroes() !== dp.value) {
            this.SetShowZeroes(dp.value);
            needRecreateCacheItem = true;
        }

        // TFI
        dp = DynProperty.getPropertyByName(properties, 'timeFrameInfo');
        if (dp && typeof (dp.value) === 'string') {
            this.SetTimeFrameInfo(TFInfo.Parse(dp.value));
            needRecreateCacheItem = false;
        }

        // PeriodDesc
        let historyPeriod = -1;
        let historyKoef = -1;

        dp = DynProperty.getPropertyByName(properties, 'HistoryPeriod');
        if (dp) historyPeriod = dp.value;

        dp = DynProperty.getPropertyByName(properties, 'HistoryKoef');
        if (dp) historyKoef = dp.value;

        if (historyPeriod >= 0 && historyKoef >= 0) {
            this.SetPeriodDesc(new PeriodDesc(historyKoef, historyPeriod));
        }

        dp = DynProperty.getPropertyByName(properties, 'gr_type');
        if (dp) this.SetChartDrawingType(dp.value);

        // + проверяет что изменилось чтоб зря не перегружать чарт
        if (needRecreateCacheItem && !this.skipUpdateTimeFrameInfoDuringUpdateProperties) {
            this.SetTimeFrameInfo(this.GetTimeFrameInfo());
        }
    }

// #endregion
}

export class TerceraChartMVCModelItem {
    public ItemType: ModelDataType;

    constructor (type) {
        this.ItemType = type;
    }
}

export enum ModelDataType {
    TFI = 0,
    Range = 1,
    Style = 2,
    Instrument = 3,
    Account = 4,
    HistoryType = 5,
    ShowAllSession = 6,
    ShowEmptyBars = 7,
    LastDate = 8
};

// #region Controller

export class TerceraChartMVCController {
    public ActiveCommand = null;
    public SuspendRefreshChart = false;
    public terceraChart: TerceraChart;

    constructor (chart: TerceraChart) {
        this.terceraChart = chart;
    }

    public ExecuteCommand (command, value): void {
        // busy now
        if (this.ActiveCommand) return;
        try {
            this.ActiveCommand = command;
            this.ExecuteCommandInner(command, value);
        } finally {
            this.ActiveCommand = null;
        }
    }

    public ExecuteCommandInner (command, value): void {
        const terceraChart = this.terceraChart;

        switch (command.ModelItem.ItemType) {
        case ModelDataType.Instrument:
            terceraChart.model.SetInstrument(value);
            break;
        case ModelDataType.Account:
            terceraChart.model.SetAccount(value);
            break;
        case ModelDataType.TFI:
            terceraChart.model.SetTimeFrameInfo(value);
            break;
        case ModelDataType.Style:
            terceraChart.model.SetChartDrawingType(value);
            break;
        case ModelDataType.Range:
            terceraChart.model.SetPeriodDesc(value);
            break;
        case ModelDataType.ShowAllSession:
            terceraChart.model.SetShowAllSessions(value);
            break;
        case ModelDataType.ShowEmptyBars:{
            terceraChart.model.SetShowZeroes(value);
            // Перезагрузка не нужна, достаточно пересчитать индексы
            if (terceraChart.MainCashItemSeries()) {
                terceraChart.MainCashItemSeries().RecalcIndices();
            }
            // забыли оверлеи
            const len = terceraChart.Overlays.length;
            for (let i = 0; i < len; i++) {
                const ser = terceraChart.Overlays[i].mainPriceRenderer.Series;
                if (ser) ser.RecalcIndices();
            }
            break;
        }
        case ModelDataType.HistoryType:
            terceraChart.model.SetChartHistoryType(value);
            break;
        case ModelDataType.LastDate:
            terceraChart.model.SetHistoryTo(value);
            break;
        }

        if (command.NeedRefreshChart()) {
            if (!this.SuspendRefreshChart) {
                terceraChart.RefreshChart();
            } else {
                terceraChart.CallOnRefreshIsRequired();
            }
        }
    }

    public Dispose (): void {
        this.terceraChart = null;
    }
}
// #endregion

// #region TerceraChartMVCCommand

export class TerceraChartMVCCommand {
    public ModelItem: TerceraChartMVCModelItem;

    constructor (itemType) {
        this.ModelItem = new TerceraChartMVCModelItem(itemType);
    }

    public NeedRefreshChart (): boolean {
        switch (this.ModelItem.ItemType) {
        case ModelDataType.Account:
        case ModelDataType.Instrument:
        case ModelDataType.TFI:
        case ModelDataType.Range:
        case ModelDataType.ShowAllSession:
        case ModelDataType.HistoryType:
        case ModelDataType.LastDate:
            return true;
        }
        return false;
    }
}

// #endregion
