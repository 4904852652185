// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PriceType } from "../../../../../Utils/History/CashItemUtils.ts";
import { MAMode } from "../../IndicatorConstants.ts";
import { ExpandDoubleVector } from "../../DoubleMatrix.ts";
import { IndicatorFunctions } from "../../IndicatorFunctions.ts";
import { iBuildInIndicator } from "../../iBuildInIndicator.ts";


export let iCCIEx = function (maPeriod, maType, priceType)
{
    iBuildInIndicator.call(this, 1);
    this.FMaPeriod = maPeriod;
    this.FMaType = maType | MAMode.SMA;
    this.FPriceType = priceType;
    this.ma = new ExpandDoubleVector();
}

iCCIEx.prototype = Object.create(iBuildInIndicator.prototype)

Object.defineProperty(iCCIEx.prototype, 'Name',
    {
        get: function () { return 'iCCIEx' }
    })

Object.defineProperty(iCCIEx.prototype, 'Key',
    {
        get: function () { return this.DefaultKey + this.FMaPeriod + this.FMaType; }
    })

iCCIEx.prototype.NextBar = function (callBound)
{
    iBuildInIndicator.prototype.NextBar.call(this, callBound)
    this.ma.Add(0.0);
}
iCCIEx.prototype.Refresh = function (count, newThread)
{
    this.ma.Dispose()
    this.ma = new ExpandDoubleVector();
    iBuildInIndicator.prototype.Refresh.call(this, count, newThread)
}
iCCIEx.prototype.OnQuote = function (ci, callBound, callFromRefresh)
{
    this.ma[this.ma.Length - 1] = this.GetPrice(this.FPriceType, 0);
    let Parent = this.Parent
    if (!Parent || this.FCount < this.FMaPeriod || this.FMaPeriod == 0)
        return;
    let d = 0.0;
    let maValue = IndicatorFunctions.CallMovingFunction(this.FMaType, this.ma, this.FMaPeriod, 1);
    for (let i = 0; i < this.FMaPeriod; i++)
        d += Math.abs(this.GetPrice(this.FPriceType, i) - maValue);

    d = 0.015 * (d / this.FMaPeriod);
    if (d != 0.0)
        this.SetValue(0, 0, (this.GetPrice(this.FPriceType, 0) - maValue) / d);
}


export let iCCI = function (maPeriod, maType)
{
    iCCIEx.call(this, maPeriod, maType, PriceType.Typical)
}
iCCI.prototype = Object.create(iCCIEx.prototype)

Object.defineProperty(iCCI.prototype, 'Name',
    {
        get: function () { return 'iCCI' },
        set: function () { }
    })