// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts";
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";
import { TerceraBaseWindowTemplate, TerceraRenameScreenTemplate, TerceraRenameScreenFooterTemplate } from "../../templates.js";
import { TerceraButton } from "../elements/TerceraButton.ts";
import { TerceraMessageBox } from "./TerceraMessageBox.js";
import { TerceraWindowBase } from "./TerceraWindowBase.js";

export let TerceraRenameScreen = TerceraWindowBase.extend({
    template: TerceraBaseWindowTemplate,
    data: function () {
        return {
            resizable: false,
            zIndex: 2000,
            width: 240,
            height: 112,
            currentName: '',
            style_addition_body: 'tercera-rename-innerContainer'
        };
    },
    partials: { 
        bodyPartial: TerceraRenameScreenTemplate,
        footerPartial: TerceraRenameScreenFooterTemplate
    }
});

TerceraRenameScreen.prototype.oncomplete = function ()
{
    TerceraWindowBase.prototype.oncomplete.apply(this);

    this.set({
        okBtnText: Resources.getResource('screen.renameScreen.ok'),
        cancelBtnText: Resources.getResource('screen.renameScreen.cancel')
    });

    this.Controls.textBox.setFocus();
};

TerceraRenameScreen.prototype.onOKBtnClicked = function ()
{
    var newName = this.get('currentName');
    if (!newName)
    {
        TerceraMessageBox.Show(
            Resources.getResource('screen.error.title'),
            Resources.getResource('screen.renameScreen.illegalName'),
            TerceraMessageBox.msgType.Info,
            null, null, false, true, '');
    }
    else if (this.callBack)
    {
        this.callBack(newName);
        this.close();
    }
};

TerceraRenameScreen.prototype.onCanceBtnlClick = function ()
{
    this.close();
};

TerceraRenameScreen.show = function (currentName, callBack, header)
{
    var scr = new TerceraRenameScreen();
    scr.set('header', header);
    scr.set('currentName', currentName);
    scr.callBack = callBack;
    MainWindowManager.MainWindow.addControl(scr);
    scr.center();
};