import { type IndustryMap } from '../../../../Commons/Heatmap/Maps/IndustryMap';
import { HeatmapAreaBaseRenderer } from './HeatmapAreaBaseRenderer';
import { type Point, Rectangle } from '../../../../Commons/Geometry';
import { SolidBrush } from '../../../../Commons/Graphics';
import { type IHeatmapViewSettings } from '../../../../Commons/Heatmap/Settings/IHeatmapViewSettings';
import { ThemeManager } from '../../../misc/ThemeManager';

export class HeatmapIndustryRenderer extends HeatmapAreaBaseRenderer {
    private readonly industryMap: IndustryMap;
    private readonly _undefinedSectorName: string = 'Undefined industry';

    constructor (industryMap: IndustryMap, settings: IHeatmapViewSettings) {
        super();
        this.industryMap = industryMap;
        this.areaName = isValidString(industryMap.industryName) ? industryMap.industryName.toUpperCase() : this._undefinedSectorName.toUpperCase();
        this.settings = settings;
    }

    public draw (gr: CanvasRenderingContext2D, mousePoint: Point): void {
        if (!this.industryMap.Visible || this.industryMap.isSelected) {
            return;
        }

        const font = ThemeManager.Fonts.Font_10F_regular;

        this.setRectangles(gr);

        const isHovered = this.isHovered(mousePoint);
        const headerBackgroundBrush = new SolidBrush(isHovered ? ThemeManager.CurrentTheme.HeatmapPanelHeaderHoveredColor : this.settings.industryLevelBackgroundColor);
        const headerForegroundBrush = new SolidBrush(this.settings.industryLevelFontColor);
        const backgroundBrush = new SolidBrush(this.settings.industryLevelBackgroundColor);
        gr.FillRectWithRect(backgroundBrush, this.industryMap.Rectangle);
        gr.FillRectWithRect(headerBackgroundBrush, this.industryMap.headerRectangle);
        gr.DrawStringInRect(this.areaName, font, headerForegroundBrush, this.headerTextRectangle, true);
        gr.DrawStringInRect(HeatmapAreaBaseRenderer.SELECTOR_SIGN, font, headerForegroundBrush, this.selectorSignRectangle, false);

        if (isHovered) {
            const headerRect = this.industryMap.headerRectangle;
            gr.strokeStyle = ThemeManager.CurrentTheme.HeatmapPanelHeaderHoveredColor;
            gr.lineWidth = 3;
            gr.strokeRect(headerRect.X - 0.5, headerRect.Y - 1, headerRect.Width + 1, headerRect.Height);
        }
    }

    public drawSelection (gr: CanvasRenderingContext2D, mousePoint: Point): void {
        if (!this.industryMap.Visible || this.industryMap.isSelected) {
            return;
        }
        if (this.isHovered(mousePoint) || this.isParentSectorHovered(mousePoint)) {
            const rect = this.industryMap.bodyRectangle;
            const strokeLineWidth = 2;
            gr.strokeStyle = ThemeManager.CurrentTheme.HeatmapPanelHeaderHoveredColor;
            gr.lineWidth = strokeLineWidth;
            gr.strokeRect(rect.X - 1, rect.Y, rect.Width + strokeLineWidth, rect.Height + 1);
        }
    }

    protected setRectangles (gr: CanvasRenderingContext2D): void {
        const headerRectangle = this.industryMap.headerRectangle;

        const font = ThemeManager.Fonts.Font_10F_regular;
        const leftOffset = 5;
        const textWidth = gr.GetTextSize(this.areaName, font, true).width;
        const signWidth = gr.GetTextSize(HeatmapAreaBaseRenderer.SELECTOR_SIGN, font, true).width;

        this.headerTextRectangle = new Rectangle(headerRectangle.X + leftOffset, headerRectangle.Y, Math.min(textWidth, headerRectangle.Width - leftOffset - signWidth - leftOffset), headerRectangle.Height);
        this.selectorSignRectangle = new Rectangle(this.headerTextRectangle.Right() + leftOffset, headerRectangle.Y, signWidth, headerRectangle.Height);
    }

    private isParentSectorHovered (mousePoint: Point): boolean {
        return !mousePoint.IsEmpty() && this.industryMap.parentSectorMap.headerRectangle.Contains(mousePoint.X, mousePoint.Y);
    }

    private isHovered (mousePoint: Point): boolean {
        return !mousePoint.IsEmpty() && (this.industryMap.headerRectangle.Contains(mousePoint.X, mousePoint.Y) || this.industryMap.bodyRectangle.Contains(mousePoint.X, mousePoint.Y));
    }
}
