// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DataCache } from '../../Commons/DataCache';
import { type Account } from '../../Commons/cache/Account';
import { type Instrument } from '../../Commons/cache/Instrument';
import { PositionInfo } from '../../Commons/cache/PositionInfo';
import { PositionInfoItem, type IPositionInfoBackColorClasses } from '../../Commons/cache/PositionInfoItem';
import { PositionInfoType } from '../../Commons/cache/PositionInfoType';
import { Resources } from '../../Commons/properties/Resources';
import { TradingPositionType } from '../../Utils/Instruments/TradingPositionType';
import { InstrumentTotalInfoTemplate } from '../../templates.js';
import { ControlsTypes } from '../UtilsClasses/FactoryConstants';
import { Control } from './Control_ts';

export class InstrumentTotalInfo extends Control {
    public InstrumentForData: Instrument = null;
    public PositionInfo: PositionInfo = new PositionInfo();
    public infoItems: Record<number, PositionInfoItem> = {};

    public override getType (): ControlsTypes { return ControlsTypes.InstrumentTotalInfo; }

    public override oninit (): void {
        super.oninit();
        Control.Ticker.Subscribe(this.TickAsync, this);
        Resources.onLocaleChange.Subscribe(this.localize, this);
        this.localize();
        this.observe('instrument', this.CorrectingSourceObject);

        this.SetDefaultInfoItems();
    }

    private SetDefaultInfoItems (): void {
        const colors = new PositionInfoBackColorClasses();
        this.infoItems[PositionInfoType.AverageOpenPrice] = new PositionInfoItem(PositionInfoType.AverageOpenPrice, this.PositionInfo, colors);
        this.infoItems[PositionInfoType.BreakEven] = new PositionInfoItem(PositionInfoType.BreakEven, this.PositionInfo, colors);
        this.infoItems[PositionInfoType.NetQTY] = new PositionInfoItem(PositionInfoType.NetQTY, this.PositionInfo, colors);
        this.infoItems[PositionInfoType.LongQty] = new PositionInfoItem(PositionInfoType.LongQty, this.PositionInfo, colors);
        this.infoItems[PositionInfoType.ShortQty] = new PositionInfoItem(PositionInfoType.ShortQty, this.PositionInfo, colors);
        this.infoItems[PositionInfoType.PLByCurrency] = new PositionInfoItem(PositionInfoType.PLByCurrency, this.PositionInfo, colors);
    }

    public override dispose (): void {
        Control.Ticker.UnSubscribe(this.TickAsync, this);
        Resources.onLocaleChange.UnSubscribe(this.localize, this);

        super.dispose();
    }

    // TODO fucking shit
    public CorrectingSourceObject (newInstrument: Instrument, oldInstrument): void {
        if (!isNullOrUndefined(newInstrument)) {
            if (!newInstrument.IsContiniousContract && newInstrument.isFuturesSymbol) {
                const x = DataCache.getInstrumentByTradable_ID(newInstrument.InstrumentTradableID, newInstrument.Route);
                if (!isNullOrUndefined(x) && x.IsContiniousContract && x.isFuturesSymbol) {
                    this.InstrumentForData = x;
                } else {
                    this.InstrumentForData = newInstrument;
                }
            } else {
                this.InstrumentForData = newInstrument;
            }
        }

        const isHedging = this.InstrumentForData?.TradingPositionType === TradingPositionType.MultiPosition;
        void this.set({ isHedging });
    }

    public TickAsync (): void {
        const instrument = this.InstrumentForData;
        const account: Account = this.get('account');
        if (isNullOrUndefined(instrument) || isNullOrUndefined(account)) {
            return;
        }

        this.PositionInfo.Recalculate(instrument, account);
        for (const key in this.infoItems) {
            this.infoItems[key]?.Update();
        }

        void this.set({ items: this.infoItems });
    }

    public localize (): void {
        void this.set({
            quantityText: Resources.getResource('panel.instrumentInfo.Qty'),
            avgPriceText: Resources.getResource('panel.instrumentInfo.Price'),
            plText: Resources.getResource('panel.instrumentInfo.P/L'),
            breakEvenText: Resources.getResource('panel.instrumentInfo.BEven')
        });
    }
}

class PositionInfoBackColorClasses implements IPositionInfoBackColorClasses {
    None = 'bg-black';
    Red = 'bg-red';
    Blue = 'bg-blue';
    Purple = 'bg-purple';
    Green = 'bg-green';
}

Control.extendWith(InstrumentTotalInfo, {
    template: InstrumentTotalInfoTemplate,
    data: function () {
        return {
            account: null,
            instrument: null,

            breakEvenText: '',
            quantityText: '',
            avgPriceText: '',
            plText: '',
            items: {},
            isHedging: false
        };
    }
});
