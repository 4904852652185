// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { NumericLinks } from "../../Commons/cache/OrderParams/order-edit/OrderEditBase.ts"
import { OrderEditBaseUtils } from "../../Utils/Trading/OrderEditBaseUtils.ts";
import { DPTradingNumericTemplate } from "../../templates.js";
import { DynPropertyControl } from "../elements/DynPropertyControl.js"

// TODO. Mousewheel increment bug, value validation bug.
export let DPTradingNumeric = DynPropertyControl.extend({
  template: DPTradingNumericTemplate,
  data: function () {
      return {
        showLabel: true,
        PriceNumericLink: null,
        StopPriceNumericLink: null,
        width: 200,
        additionalClassName: ''
      }
  }
})

DPTradingNumeric.prototype.oncomplete = function () 
{
    DynPropertyControl.prototype.oncomplete.call(this)

    let numericLinkKey = this.get('name') === OrderEditBaseUtils.LIMIT_PRICE_PARAM ? NumericLinks.LimitPrice : NumericLinks.StopPrice

    this.set(numericLinkKey , this.Controls.TradingNumeric);
}