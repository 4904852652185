// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { HistoryType } from '../../Utils/History/HistoryType';
import { type Instrument } from './Instrument';
import { type QuoteCache } from './QuoteCache';
import { Level2CashItem } from './Level2CashItem';
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
export class QuoteSubscriptionItem {
    instrument: Instrument | null;
    quoteCache: QuoteCache | null;
    lvl2CashItem: Level2CashItem | null;
    listenerDict: Record<number, any[]>;
    lastQuoteDict: Record<number, any>;
    HasContiniousContractItem: boolean;
    InstrumentContiniousContractItem: any;

    constructor (instrument: Instrument, quoteCache: QuoteCache) {
        this.instrument = instrument;
        this.quoteCache = quoteCache;
        this.lvl2CashItem = new Level2CashItem(instrument, quoteCache);
        this.listenerDict = {};
        this.lastQuoteDict = {};
        this.HasContiniousContractItem = false;
        this.InstrumentContiniousContractItem = null;
    }

    addListener (listener: any, quoteType: number): void {
        if (!listener?.newQuote || quoteType === undefined) {
            throw new Error('arguments are undefined');
        }

        const arr = this.getListenerArray(quoteType);
        arr.push(listener);

        if (quoteType !== HistoryType.QUOTE_INSTRUMENT_DAY_BAR || this.instrument === null) {
            const lastQuote = this.lastQuoteDict[quoteType];
            if (lastQuote) listener.newQuote(lastQuote);
        } else {
            const idms = this.instrument.GeneratedInstrumentDayBarMessages();
            for (const value of idms) {
                listener.newQuote(value);
            }
        }
    }

    removeListener (listener: any, quoteType: number): void {
        if (!listener?.newQuote || quoteType === undefined) {
            throw new Error('arguments are undefined');
        }

        const arr = this.getListenerArray(quoteType);
        const idx = arr.indexOf(listener);
        if (idx >= 0) arr.splice(idx, 1);
    }

    getLastQuote (quoteType: number): any {
        return this.lastQuoteDict[quoteType] || null;
    }

    getListenerArray (quoteType: number): any[] {
        let arr = this.listenerDict[quoteType];
        if (!arr) {
            arr = [];
            this.listenerDict[quoteType] = arr;
        }
        return arr;
    }

    getListenerCount (quoteType: number): number {
        const listenerArr = this.getListenerArray(quoteType);
        return listenerArr.length;
    }

    newQuote (quote: any): void {
        const type = quote.Type;
        this.lastQuoteDict[type] = quote;
        this.setTradingSessionForQuoteMessage(quote);
        this.instrument?.newQuote(quote);

        if (type === HistoryType.QUOTE_LEVEL2) {
            this.lvl2CashItem?.newQuote(quote);
        }

        for (const listener of this.getListenerArray(type)) {
            listener?.newQuote(quote);
        }
    }

    setTradingSessionForQuoteMessage (msg: any): void {
        if (!this.instrument) {
            msg.TradingSession = null;
            return;
        }

        const currentSession = this.instrument.FindSession(msg.cTime);
        if (currentSession) msg.SessionFlag = currentSession.MainType;

        msg.TradingSession = currentSession;
    }

    dispose (): void {
        this.instrument = null;
        this.quoteCache = null;
        this.lvl2CashItem = null;
        this.listenerDict = {};
        this.lastQuoteDict = {};
    }
}
