// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { OperationType } from '../../../../Utils/Trading/OperationType';
import { OrderType } from '../../../../Utils/Trading/OrderType';
import { RulesSet } from '../../../../Utils/Rules/RulesSet';
import { DynProperty } from '../../../DynProperty';
import { OrderUtils } from '../../../../Utils/Trading/OrderUtils';
import { NumericUtils } from '../../../../Utils/NumericUtils';
import { OrderExecutorUtils } from '../../../Trading/OrderExecutorUtils';
import { OrderEditUpdateData } from '../../../../Utils/Trading/OrderEditUpdateData';
import { InstrumentUtils } from '../../../../Utils/Instruments/InstrumentUtils';
import { SLTPEdit } from '../SLTPEdit';
import { OrderEditBaseUtils } from '../../../../Utils/Trading/OrderEditBaseUtils';
import { OrderEditBase } from './OrderEditBase';
import { GeneralSettings } from '../../../../Utils/GeneralSettings/GeneralSettings';

// TODO. Refactor. Come up with an offset numeric parameter
// for TrailingStopOrderEdit and SLTPEdit.
export class TrailingStopOrderEdit extends OrderEditBase {
    constructor (data) {
        super(data);

        this.sltp = new SLTPEdit(data.dataCache, true);
        // TODO. Refactor.
        this.trailingStop =
        {
            // null - value awaits for recalculation
            value: null,
            // Ticks only (tick , fractional, point view etc)
            offsetViewMode: null
        };
        this.tryUpdateTrailingStop(null, false);
    }

    public override getParameterNameArray (): string[] {
        return [
            OrderEditBaseUtils.TRAILING_STOP_PARAM,
            OrderEditBaseUtils.POSITION_SIZING_PARAM,
            OrderEditBaseUtils.SLTP_PARAM,
            OrderEditBaseUtils.REAL_TRSTOP_PRICE
        ];
    }

    // TODO. Refactor. Same as OrderTypeBase.Id function.
    public override getOrderTypeId (): OrderType {
        return OrderType.TrailingStop;
    }

    // TODO. Refactor. Use error/warning dicts for sltp.
    // public valid ()
    // {
    //     return OrderEditBase.prototype.valid.call(this) &&
    //         this.sltp.valid()
    // }

    // #region Update Parameter Handlers

    // TODO. Rename. Refactor.
    public tryUpdateTrailingStop (dp, sessionSettingsChanged): boolean {
        const ins = this.instrument;
        const trStopObj = this.trailingStop;

        if ((sessionSettingsChanged || trStopObj.value === null) && ins) {
            const offsetViewMode = GeneralSettings.TradingDefaults.ShowOffsetIn;
            let ticks = null;

            if (trStopObj.value) {
                ticks = OrderUtils.toRawTicks(trStopObj.value, trStopObj.offsetViewMode, ins);
            }

            trStopObj.offsetViewMode = offsetViewMode;
            trStopObj.value = OrderUtils.ConvertTickOffset(ins, offsetViewMode, null, ticks || 1);

            return true;
        }

        if (dp && trStopObj.value !== dp.value) {
            trStopObj.value = dp.value;

            return true;
        }

        return false;
    }

    public update_trailingStop (updateData): boolean {
        return this.tryUpdateTrailingStop(
            updateData.dp,
            updateData.sessionSettingsChanged);
    }

    // #endregion Update Parameter Handlers

    // #region Parameters' DynProperty Generators

    public toDynProperty_trailingStop (): DynProperty {
        const data = NumericUtils.getNumericsOffsetModeViewParams(this.instrument, true, true);

        const dp = new DynProperty(OrderEditBaseUtils.TRAILING_STOP_PARAM);
        dp.type = DynProperty.DOUBLE;
        dp.decimalPlaces = data.numericsPrec;
        dp.increment = data.ifIsTrStopStep;
        dp.minimalValue = dp.increment;
        dp.maximalValue = 999999999;
        dp.value = this.trailingStop.value;
        dp.localizationKey = 'panel.newOrderEntry.trstopOffset';

        return dp;
    }

    // #endregion Parameters' DynProperty Generators

    // #region Raw Values

    public toRawValue_trailingStop (): number {
        const trStop = this.trailingStop;
        return OrderUtils.toRawTicks(trStop.value, trStop.offsetViewMode, this.instrument);
    }

    public toRawValue_RealTrStopPrice (): number {
        if (!this.instrument.DataCache.isAllowedForMainAccount(RulesSet.FUNCTION_TRAILING_STOP_BY_PRICE) || this.AlerEdit) {
            return null;
        }

        const openPrice = this.getQuotePriceForCalculatingOffset();

        const trStop = this.trailingStop;
        const sideSign = this.side === OperationType.Buy ? 1 : -1;
        // Посчитали истинные тики, соответсвенно потом в расчёте цены мы игнорим фракционы
        const ticks = OrderUtils.toRawTicks(trStop.value, trStop.offsetViewMode, this.instrument) * sideSign;
        return this.instrument.roundPrice(this.instrument.CalculatePrice(openPrice, ticks, true));
    }

    public update_RealTrStopPrice (): boolean {
        return false;
    }

    public toDynProperty_RealTrStopPrice (): any {
        return {};
    }
    // #endregion Raw Values

    public validateParameters (): any {
        const updatedParamNameDict = {};

        if (this.sltp.validate(null, null, this.getTradingData())) {
            updatedParamNameDict[OrderEditBaseUtils.SLTP_PARAM] = true;
        }

        updatedParamNameDict[OrderEditBaseUtils.POSITION_SIZING_PARAM] = true;

        return updatedParamNameDict;
    }

    public override getConfirmationText (): string {
        return OrderExecutorUtils.buildOrderEditConfirmationText(
            'Tr. stop',
            this.tif,
            this.account,
            this.instrument,
            this.quantity,
            this.buy(),
            [this.trailingStop.value],
            this.sltp.getConfirmationText(this.getTradingData()),
            true,
            this.productType,
            null,
            this.toRawValue_RealTrStopPrice(),
            this.leverageValue
        );
    }

    // TODO. UGLY. Refactor. details are at the top OrderEditBase.ts
    public setSLTP (sltpHolder): void {
        const dp = this.sltp.createDynPropertyForRawSLTP(
            sltpHolder,
            this.getTradingData(),
            null,
            null);

        this.updateParameters(new OrderEditUpdateData({ sltp: dp }));
    }

    // Returns SlTpHolder instance.
    // TODO. UGLY. Refactor. details are at the top OrderEditBase.ts
    public getRawSLTP (): any {
        return this.sltp.getRawValue(this.getTradingData());
    }

    // TODO. Refactor. Rename. UGLY. Details are in OrderEditBase.ts.
    public setBasePrice (price): void {
        const dp = this.createDynPropertyFromParameter(OrderEditBaseUtils.TRAILING_STOP_PARAM);

        let newTicks = InstrumentUtils.getPriceDifferenceInTicks(
            price,
            this.getQuotePriceForCalculatingOffset(),
            this.instrument);

        // TODO. Ugly.
        if (newTicks <= 0) {
            newTicks = 1;
        }

        // TODO. Works but meh...
        dp.value = OrderUtils.ConvertTickOffset(
            this.instrument,
            this.trailingStop.offsetViewMode,
            0,
            newTicks);

        this.updateParameters(new OrderEditUpdateData({ trailingStop: dp }));
    }

    // TODO. Rename. Refactor.
    public getQuotePriceForCalculatingOffset (): any {
        const quote = this.quote;
        if (!quote) {
            return 0;
        }

        const sp = this.instrument.DataCache.GetSpreadPlan(this.account);
        return this.side === OperationType.Buy
            ? quote.AskSpread_SP_Ins(sp, this.instrument)
            : quote.BidSpread_SP_Ins(sp, this.instrument);
    };

    public getBasePrice (): any {
        return this.trailingStop.value;
    }
}
