// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PriceType } from "../../../../../Utils/History/CashItemUtils.ts";
import { MODE_UPPER, MODE_LOWER } from "../../IndicatorConstants.ts";
import { ExpandDoubleVector } from "../../DoubleMatrix.ts";
import { IndicatorFunctions } from "../../IndicatorFunctions.ts";
import { iBuildInIndicator } from "../../iBuildInIndicator.ts";

export let iBandsEx = function (maPeriod, maMode, deviation, bandsShift, priceType)
{
    iBuildInIndicator.call(this, 3);
    this.FMaPeriod = maPeriod;
    this.FMaMode = maMode;
    this.FDeviation = deviation;
    this.FPriceType = priceType;
    this.ma = new ExpandDoubleVector();
    this.FBegin = maMode == 1 ? 1 : maPeriod;
    this.FData.FArray[0].FShift = bandsShift;
    this.FData.FArray[1].FShift = bandsShift;
    this.FData.FArray[2].FShift = bandsShift;
}

iBandsEx.prototype = Object.create(iBuildInIndicator.prototype)

Object.defineProperty(iBandsEx.prototype, 'Name',
    {
        get: function () { return 'iBandsEx' }
    })

Object.defineProperty(iBandsEx.prototype, 'Key',
    {
        get: function () { return this.DefaultKey + this.FMaPeriod + this.FMaMode + this.FDeviation + this.FPriceType; }
    })

iBandsEx.prototype.NextBar = function (callBound)
{
    iBuildInIndicator.prototype.NextBar.call(this, callBound)
    this.ma.Add(0.0);
}
iBandsEx.prototype.Refresh = function (count, newThread)
{
    this.ma.Dispose()
    this.ma = new ExpandDoubleVector();
    iBuildInIndicator.prototype.Refresh.call(this, count, newThread)
}
iBandsEx.prototype.OnQuote = function (ci, callBound, callFromRefresh)
{
    let price = this.GetPrice(this.FPriceType, 0)
    this.ma[this.ma.Length - 1] = price;
    let Parent = this.Parent
    if (!Parent)
        return
    if (this.FCount < this.FBegin)
        return;

    let maValue = IndicatorFunctions.CallMovingFunction(this.FMaMode, this.ma, this.FMaPeriod, 1);
    let summa = 0.0;
    for (let i = 0; i < this.FMaPeriod; i++)
    {
        let add = this.GetPrice(this.FPriceType, i) - maValue;
        summa += (add * add);
    }
    // Calculation of deviation value
    summa = this.FDeviation * Math.sqrt(summa / this.FMaPeriod);

    // Setting value
    this.SetValue(MODE_UPPER, 0, maValue + summa);
    this.SetValue(MODE_LOWER, 0, maValue - summa);
    this.SetValue(2, 0, maValue);
}

export let iBands = function (maPeriod, maMode, deviation, bandsShift)
{
    iBandsEx.call(this, maPeriod, maMode, deviation, bandsShift, PriceType.Close)
}
iBands.prototype = Object.create(iBandsEx.prototype);

Object.defineProperty(iBands.prototype, 'Name',
    {
        get: function () { return 'iBands' },
        set: function () { }
    })