// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Connection } from './Commons/Connection';
import { Resources } from './Commons/properties/Resources.js';
import { messageBoxHandler } from './Utils/AppHandlers.js';
import { BrowserUtils } from './Commons/UtilsClasses/BrowserUtils';
import { Md5 } from 'ts-md5';

class BaseRoute {
    protected createRequestPromise: any;
    constructor (createRequestPromise) {
        this.createRequestPromise = createRequestPromise;
    }
}

class Settings extends BaseRoute {
    constructor (createRequestPromise) {
        super(createRequestPromise);
    }

    public get () {
        return this.createRequestPromise('GET', 'settings').catch(async function (e) {
            if (e === 400) { UserWebStorage.ErrorRest(); }
            return await Promise.reject(e);
        });
    }

    // // TODO. Ugly.
    // UserWebStorage.Settings.prototype.default = function ()
    // {
    //     return this.createRequestPromise('GET', 'default/settings').catch(function (e)
    //     {
    //         if (e === 400)
    //             UserWebStorage.ErrorRest();
    //         return Promise.reject(e);
    //     })
    // }

    public post (settings) {
        if (!UserWebStorage.authData) { return Promise.resolve(); }

        return this.createRequestPromise('POST', 'settings', settings).catch(async function (e) {
            if (e === 400) { UserWebStorage.ErrorRest(); }
            return await Promise.reject(e);
        });
    }

    public getPortfolioSettings () {
        return this.createRequestPromise('GET', 'settings/portfolio').catch(async function (e) {
            if (e === 400) { UserWebStorage.ErrorRest(); }
            return await Promise.reject(e);
        });
    }

    public postPortfolioSettings (settings) {
        if (!UserWebStorage.authData) { return Promise.resolve(); }

        return this.createRequestPromise('POST', 'settings/portfolio', settings).catch(async function (e) {
            if (e === 400) { UserWebStorage.ErrorRest(); }
            return await Promise.reject(e);
        });
    }
}

class Workspaces extends BaseRoute {
    constructor (createRequestPromise) {
        super(createRequestPromise);
    }

    public getInitData () {
        return this.createRequestPromise('GET', 'workspaces/init-data').catch(async function (e) {
            if (e === 400) { UserWebStorage.ErrorRest(); }
            return await Promise.reject(e);
        });
    }

    public getDefaultData () {
        return this.createRequestPromise('GET', 'workspaces/default').catch(async function (e) {
            if (e === 400) { UserWebStorage.ErrorRest(); }
            return await Promise.reject(e);
        });
    }

    public get (id) {
        return this.createRequestPromise('GET', 'workspaces/' + id).catch(async function (e) {
            if (e === 400) { UserWebStorage.ErrorRest(); }
            return await Promise.reject(e);
        });
    }

    public update (id, obj) {
        return this.createRequestPromise('PUT', 'workspaces/' + id, obj).catch(async function (e) {
            if (e === 400) { UserWebStorage.ErrorRest(); }
            return await Promise.reject(e);
        });
    }

    public create (id, obj) {
        return this.createRequestPromise('POST', 'workspaces/' + id, obj).catch(async function (e) {
            if (e === 400) { UserWebStorage.ErrorRest(); }
            return await Promise.reject(e);
        });
    }

    public clone (srcId, destId) {
        return this.createRequestPromise('POST', 'workspaces/' + srcId + '/' + destId).catch(async function (e) {
            if (e === 400) { UserWebStorage.ErrorRest(); }
            return await Promise.reject(e);
        });
    }

    public setActiveWorkspaceId (ws_id) {
        return this.createRequestPromise('PUT', 'workspaces/active/' + ws_id).catch(async function (e) {
            if (e === 400) { UserWebStorage.ErrorRest(); }
            return await Promise.reject(e);
        });
    }

    public delete (ids) {
        return this.createRequestPromise('DELETE', 'workspaces/', ids).catch(async function (e) {
            if (e === 400) { UserWebStorage.ErrorRest(); }
            return await Promise.reject(e);
        });
    }
}

class TvWorkspaces extends BaseRoute {
    public get (id) {
        return this.createRequestPromise('GET', 'tv-workspaces/' + id).catch(async function (e) {
            if (e === 204) {
                // first logon no workspace
                return await Promise.resolve(null);
            }
            if (e === 400) { UserWebStorage.ErrorRest(); }
            return await Promise.reject(e);
        });
    }

    public put (id, obj) {
        return this.createRequestPromise('PUT', 'tv-workspaces/' + id, obj).catch(async function (e) {
            if (e === 400) { UserWebStorage.ErrorRest(); }
            return await Promise.reject(e);
        });
    }
}

class Branding extends BaseRoute {
    constructor (createRequestPromise) {
        super(createRequestPromise);
    }

    public locales (connectionName) {
        return this.createRequestPromise('GET', 'branding/locales/' + connectionName);
    }

    public investingCustomScheme (connectionName) {
        return this.createRequestPromise('GET', 'branding/investingCustomScheme/' + connectionName);
    }
}

class Connections extends BaseRoute {
    constructor (createRequestPromise) {
        super(createRequestPromise);
    }

    public get () {
        return this.createRequestPromise('GET', 'connections');
    }
}
/**
 * https://tp.traderevolution.com/entity/86122
 */
class externalResourse extends BaseRoute {
    constructor (createRequestPromise) {
        super(createRequestPromise);
    }

    public post (url) {
        const encodedURL = encodeURIComponent(url);
        return this.createRequestPromise('POST', 'external-resourse', { encodedURL }).catch(async function (e) {
            if (e === 400) { UserWebStorage.ErrorRest(); }
            return await Promise.reject(e);
        });
    }
}

/**
 * https://tp.traderevolution.com/entity/114288
 */
class youtubePageParser extends BaseRoute {
    constructor (createRequestPromise) {
        super(createRequestPromise);
    }

    public post (url, videoId) {
        return this.createRequestPromise('POST', 'youtube-parser', { url, videoId }).catch(async function (e) {
            if (e === 400) { UserWebStorage.ErrorRest(); }
            return await Promise.reject(e);
        });
    }
}

/* ================== TODO. Refactor. Rename. ================== */

export class UserWebStorage {
    static RestApiNotWork: boolean = false;
    static authData: any = null;

    public settings: Settings;
    public workspaces: Workspaces;
    public readonly tvWorkspaces: TvWorkspaces;
    public branding: Branding;
    public connections: Connections;
    public externalResourse: externalResourse;
    public youtubePageParser: youtubePageParser;
    public urlRoot: string | null;

    constructor () {
        const createRequestPromise = this.createRequestPromise.bind(this);
        this.settings = new Settings(createRequestPromise);
        this.workspaces = new Workspaces(createRequestPromise);
        this.tvWorkspaces = new TvWorkspaces(createRequestPromise);
        this.branding = new Branding(createRequestPromise);
        this.connections = new Connections(createRequestPromise);
        this.externalResourse = new externalResourse(createRequestPromise); // https://tp.traderevolution.com/entity/86122
        this.youtubePageParser = new youtubePageParser(createRequestPromise); // https://tp.traderevolution.com/entity/114288
        this.urlRoot = null;

        UserWebStorage.authData = null;
        UserWebStorage.RestApiNotWork = false; // if 400 responce
    }

    public reinit (userSessionId, connectionName) {
        if (!userSessionId || !connectionName) { throw Error('Insufficient auth data.'); }

        // let connInfo = App.Connection.GetFirstTradeStreamData()
        // if (!connInfo)
        //     throw Error('Insufficient auth data.');

        // let authJsonString = JSON.stringify({
        //     connectionName: connectionName
        //     // isSSL: connInfo.isSSL,
        //     // url: connInfo.url
        // })

        // let encrypt = new JSEncrypt();
        // encrypt.setPublicKey(App.e)
        // authJsonString = encrypt.encrypt(authJsonString)
        UserWebStorage.authData = Md5.hashStr(connectionName);
    }

    public clear () {
        UserWebStorage.authData = null;
    }

    public async createRequestPromise (type, url, body) {
        const authData = UserWebStorage.authData;
        const urlRoot = this.urlRoot;
        const contentTypeJson = 'application/json';
        let token = '';
        if (Connection?.ConnectResultData) { token = Connection.ConnectResultData.ConnectResultData.accessToken; }

        return await new Promise(function (resolve, reject/*, onCancel */) {
            const xhr = new XMLHttpRequest();
            // onCancel(function () { xhr.abort(); });

            let resUrl = urlRoot ? urlRoot + url : url;

            // IE ignores etag and forcibly caches get requests. Screw ie.
            if (BrowserUtils.isIE() && type === 'GET') { resUrl += '?req_id=' + new Date().getTime(); }

            xhr.open(type, resUrl);

            xhr.setRequestHeader('Auth-Data', authData);
            if (token) { xhr.setRequestHeader('Auth-Token', token); }
            xhr.setRequestHeader('Content-Type', contentTypeJson);

            xhr.onreadystatechange = function () {
                if (this.readyState !== /* DONE */4) { return; }

                if (this.status !== 200) { reject(this.status); return; }

                const contentType = this.getResponseHeader('Content-Type') || '';
                resolve(contentType.includes(contentTypeJson)
                    ? JSON.parse(this.responseText)
                    : null);
            };

            xhr.send(body ? JSON.stringify(body) : null);
        });
    }

    public static ErrorRest () {
        // if (!UserWebStorage.RestApiNotWork)
        //     messageBoxHandler.Show(Resources.getResource('MessageBox.ThemeChanged.Title'), Resources.getResource('general.connection.restErrorMsg'), messageBoxHandler.msgType.Warning, null, null, false, true);
        // UserWebStorage.RestApiNotWork = true;
    }
}

export const UserWebStorageInstance = new UserWebStorage();
