// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectQuote3Message } from '../../../../Utils/DirectMessages/DirectQuote3Message';
import { AgressorFlagType } from '../../../../Utils/Enums/Constants';
import { Brokers } from '../../../../Utils/Quotes/Brokers';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class Quote3MessageProcessor extends MessageProcessorBase {
    public decoder: any;

    constructor (parentDecoder) {
        super();

        this.decoder = parentDecoder;
    }

    public override decode (fieldSet: PFixFieldSet): DirectQuote3Message[] {
        const decoder = this.decoder;
        const symDecodeMap = decoder.InstrumentDecodeMap;

        const message = new DirectQuote3Message();
        message.LType = AgressorFlagType.None;

        let routeId = -1;

        const len = fieldSet.Count();
        const fixFields = fieldSet.fieldSet;
        for (let i = 0; i < len; i++) {
            const fixField = fixFields[i];
            const fieldId = fixField.FieldId;

            if (fieldId === 0) {
                continue;
            }
            // symbol
            if (fieldId === FieldsFactory.FIELD_INSTRUMENT_ID) {
                const tickId = fixField.Value;
                message.Symbol = symDecodeMap[tickId] || '';
            } else if (fieldId === FieldsFactory.FIELD_ROUTE_ID) {
                routeId = fixField.Value;
                message.Route = routeId.toString();// decoder.GetRouteById(routeId)
            } else if (fieldId === FieldsFactory.FIELD_PRICE) {
                message.Price = fixField.Value;
            } else if (fieldId === FieldsFactory.FIELD_VOLUME) {
                message.Size = fixField.Value;
            } else if (fieldId === FieldsFactory.FIELD_SIZE) {
                message.Size = fixField.Value;
            } else if (fieldId === FieldsFactory.FIELD_TYPE) {
                message.LType = fixField.Value;
            } else if (fieldId === FieldsFactory.FIELD_DATE) {
                message.cTime = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_SUB_ID) {
                message.QuoteUniqueID = fixField.Value;
            } else if (fieldId === FieldsFactory.FIELD_SOURCE_BUYER_SELLER_ID) {
            // hsa здесь может быть засада, если филд route стоит после этого филда
                const fieldSourceId = fixField.Value;
                const brokers = new Brokers(fieldSourceId);
                message.BuyerSourceId = brokers.BuyerId;
                message.SellerSourceId = brokers.SellerId;
                message.BuyerSource = decoder.GetSourceById(routeId, brokers.BuyerId);
                message.SellerSource = decoder.GetSourceById(routeId, brokers.SellerId);
            } else if (fieldId === FieldsFactory.FIELD_EXCHANGE) {
                message.Exchange = fixField.Value;
            } else if (fieldId === FieldsFactory.FIELD_OPEN_INTEREST) {
                message.OpenInterest = fixField.Value;
            } else if (fieldId === FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID) {
                message.InstrumentTradableID = fixField.Value;
            } else if (fieldId === FieldsFactory.FIELD_VENUE) {
                message.LastVenue = fixField.Value;
            }
        }

        return [message];
    }
}
