// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type StrategySubscriptionStatus } from '../TradingIdeas/StrategySubscriptionStatus';
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectSubscriptionStrategyMessage extends DirectMessageBaseClass {
    public TradingSystemID: number | null = null;
    public StrategySubscriptionStatus: StrategySubscriptionStatus | null = null;

    constructor () {
        super(Message.CODE_SUBSCRIPTION_STRATEGY_MESSAGE);
    }
}
