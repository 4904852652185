// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectExternalLinkRemoveMessage } from '../../../../Utils/DirectMessages/DirectMessagesImport';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class ExternalLinkRemoveMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): DirectExternalLinkRemoveMessage[] {
        const message = new DirectExternalLinkRemoveMessage();

        const len = fieldSet.Count();

        for (let i = 0; i < len; i++) {
            const fixField = fieldSet.fieldSet[i];
            if (fixField.FieldId === 0) {
                continue;
            }

            if (fixField.FieldId === FieldsFactory.FIELD_ID) {
                message.Id = fixField.Value;
            }
        }

        return [message];
    }
}
