// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { MathUtils } from '../../../Utils/MathUtils';
import { Intervals } from '../../../Utils/Instruments/Intervals';
import { Resources } from '../../properties/Resources';
import { QuotingType } from '../../../Utils/Instruments/QuotingType';
import { PriceFormatter } from '../../../Utils/Instruments/PriceFormatter';
import { Account } from '../Account';
import { AccountFeature } from '../../../Utils/Account/AccountFeature';
import { DynProperty } from '../../DynProperty';
import { OffsetModeViewEnum } from '../../../Utils/Trading/OffsetModeViewEnum';
import { InstrumentUtils } from '../../../Utils/Instruments/InstrumentUtils';
import { NumericUtils } from '../../../Utils/NumericUtils';
import { OrderEditBaseUtils } from '../../../Utils/Trading/OrderEditBaseUtils';
import { messageBoxHandler } from '../../../Utils/AppHandlers.js';
import { GeneralSettings } from '../../../Utils/GeneralSettings/GeneralSettings';
import { type Instrument } from '../Instrument';

export class PositionSizingEdit {
    public account: Account | null = null; // аккаунт панели для определения валюты и AvailableFunds
    public instrument: Instrument | null = null; // инструмент для настройки нумерика
    public isVisible: boolean = null; // отображаются ли контролы PositionSizing-а (включена ли кнопка калькулятора)
    public isPercentMode: boolean = true; // в контроле Account risk отображаются проценты, иначе Cash
    public errorMsgShowing: boolean = false;// отображается ли окно с ошибкой по поводу некорректных параметров
    public userValue: any = null; // значение в контроле Account risk
    public riskValue: any = null; // значение в строке Cash / Percentage risk
    public isMarketOrder: any = null; // true, если OrderType == 1 (Market)
    public userRiskValue: any;

    private slvalue: number = null; // значение в SL нумерике
    private price: number = null; // цена (лимит, маркет, стоп и тд)
    private isBuy: boolean = null; // сторона ордера
    private offsetMode: any;
    private isTrStop: boolean;

    get Price (): any {
        if (this.isMarketOrder) {
            return this.instrument.Level1[this.IsBuy ? 'GetAsk' : 'GetBid'](this.account);
        } else if (this.IsTrStop) {
            return this.price * this.instrument.GetPointSize(null);
        } else {
            return this.price;
        }
    }

    set Price (price) {
        this.price = price;
    }

    get SLValue (): any {
        return this.slvalue;
    }

    set SLValue (slvalue) {
        this.slvalue = slvalue;
    }

    get SLOffset (): any {
        if (this.OffsetMode === null) {
            return this.instrument.CalculateTicks(this.Price, this.SLValue - this.Price);
        } else {
            return this.SLValue;
        }
    }

    get SLPrice (): any {
        // #115352
        const ins = this.instrument;
        if (this.OffsetMode !== null /* && this.SLTPinOffset */ && !this.IsTrStop) {
            const tickSign = this.IsBuy ? -1 : 1;
            let sl = this.SLValue;

            if (this.OffsetModeInPoints) {
                sl = InstrumentUtils.ConvertPointsToTicks(ins, sl);
            } // #114393

            return ins.CalculatePrice(this.Price, sl * tickSign);
        }
        if (this.IsTrStop) {
            return ins.CalculatePrice(this.Price, this.SLValue, true);
        } else {
            return this.SLValue;
        }
    }

    get OffsetMode (): any {
        return this.offsetMode;
    }

    set OffsetMode (offsetMode) {
        this.offsetMode = offsetMode;
    }

    get SLTPinOffset (): any {
        return GeneralSettings.TradingDefaults.SetSlTpValuesInOffset;
    }

    get OffsetModeInPoints (): boolean {
        return GeneralSettings.TradingDefaults.ShowOffsetIn === OffsetModeViewEnum.Points;
    }

    get RiskPerShare (): number {
        let result = Math.abs(this.IsBuy ? this.Price - this.SLPrice : this.SLPrice - this.Price);
        const ins = this.instrument;
        const isTickCost_TickSize = ins.QuotingType === QuotingType.TickCost_TickSize;

        if (isTickCost_TickSize) {
            result *= ins.FuturesTickCoast;

            // if (!this.SLTPinOffset || this.OffsetModeInPoints || (this.SLTPinOffset && !this.OffsetModeInPoints))    // #114384 || #114387 || (#116846) <= always true
            // {
            // const pointSize = ins.GetPointSize(this.SLPrice);
            // result /= pointSize;
            // }
        }

        return result;
    }

    get IsBuy (): boolean {
        return this.isBuy;
    }

    set IsBuy (isBuy: boolean) {
        this.isBuy = isBuy;
    }

    get IsTrStop (): boolean {
        return this.isTrStop;
    }

    set IsTrStop (isTrStop: boolean) {
        this.isTrStop = isTrStop;
    }

    public update (updateData): boolean {
        let parameterChanged = false;

        const dp = updateData.dp;
        if (dp) {
            parameterChanged = this.updateFromDynProperty(dp) || parameterChanged;
        }

        const dataDict = updateData.newTradingDataDict;
        if (!dataDict) {
            return parameterChanged;
        }

        const acc = dataDict.account;
        if (acc && (!this.account || this.account.AcctNumber != acc.AcctNumber)) {
            this.account = acc;
            this.riskValue = this.recalcOtherRisk();
            parameterChanged = true || parameterChanged;
        }

        const ins = dataDict.instrument;
        if (ins && (!this.instrument || this.instrument.GetInteriorID() != ins.GetInteriorID())) {
            this.instrument = ins;
            parameterChanged = true || parameterChanged;
        }

        return parameterChanged;
    }

    public updateFromDynProperty (dp): boolean {
        let parameterChanged = false;

        let newVal = dp.positionSizingIsVisible;
        if (newVal !== this.isVisible) {
            this.isVisible = newVal;
            parameterChanged = true || parameterChanged;
        }

        newVal = dp.riskModeBtnText == '%';
        if (newVal !== this.isPercentMode) {
            this.isPercentMode = newVal;
            parameterChanged = true || parameterChanged;
        }

        newVal = dp.value;
        if (newVal !== this.userValue) {
            this.userValue = newVal;
            this.riskValue = this.recalcOtherRisk();
            parameterChanged = true || parameterChanged;
        }

        if (dp.availableFundsChanged) {
            parameterChanged = true || parameterChanged;
        }

        return parameterChanged;
    }

    public getRawValue (): any {
        return this.userRiskValue;
    }

    public getDynProperty (): DynProperty {
        const dp = new DynProperty(OrderEditBaseUtils.POSITION_SIZING_PARAM);

        dp.localizationKey = 'positionSizeCalculator.accountRisk';
        dp.type = DynProperty.POSITION_SIZING;

        dp.positionSizingIsVisible = this.isVisible || false;

        const acc = this.account;
        const currency = acc ? acc.BaseCurrency : '';
        let riskMode = '%';
        const isPercent = this.isPercentMode;
        if (acc && !isPercent) {
            riskMode = currency;
        }
        dp.riskModeBtnText = riskMode;
        dp.currencyRiskModeSuffix = currency;

        const ins = this.instrument;
        const assetBalance = acc ? acc.GetAssetBalanceCorrect(ins || null, this.isBuy) : null;
        const availableFunds = isPercent ? null : Account.GetAccountFeature(AccountFeature.AvailableFunds, acc, assetBalance);
        const asset = assetBalance ? assetBalance.Asset : null;
        const intrvls = Intervals.GetIntervalsFromInstrument(ins, NumericUtils.MAXVALUE);
        if (ins) {
            dp.increment = dp.minimalValue =
            isPercent ? 0.01 : asset.MinChange;
            dp.maximalValue = isPercent ? 100 : availableFunds;
            dp.decimalPlaces = isPercent ? 2 : asset.Point;
        }

        if (intrvls) {
            dp.Intervals = intrvls;
        }

        dp.value = this.userValue || 2;
        if (dp.value === null || isNaN(dp.value)) {
            dp.value = dp.minimalValue;
        }

        const value = this.riskValue;
        const formattedValue = isPercent && acc ? acc.formatPrice(value) : PriceFormatter.formatPrice(value, 2);
        dp.riskValue = value;
        dp.riskValueFormatted = formattedValue + (isPercent ? '' : ' %'); // second row calculated risk

        return dp;
    }

    public recalcOtherRisk (): number {
        const accRiskValue = this.userValue;
        const isPercentMode = this.isPercentMode;
        const acc = this.account;

        if (!acc) return NaN;

        const availableFunds = Account.GetAccountFeature(AccountFeature.AvailableFunds, acc, acc.assetBalanceDefault);
        let value: number;

        if (isPercentMode) {
            value = availableFunds * accRiskValue / 100;
        } else {
            value = availableFunds != 0 ? accRiskValue * 100 / availableFunds : NaN;
        }

        return value;
    }

    public calculateQty (price: number, slPrice: number, inLots: boolean, isBuy: boolean, offsetMode, isMarketOrder: boolean, isTrStop: boolean): any // Calculated quantity field value
    {
        if (!this.isVisible) {
            return null;
        }

        if (isNaN(price)) {
            price = 0;
        }

        const acc = this.account;
        const ins = this.instrument;
        if (!acc || !ins) return null;

        this.riskValue = this.recalcOtherRisk();

        const crossPrice = this.getCrossPrice();

        this.isMarketOrder = isMarketOrder;
        this.Price = price;
        this.SLValue = slPrice;
        this.OffsetMode = offsetMode;
        this.IsBuy = isBuy;
        this.IsTrStop = isTrStop;

        const lotSize = ins.getLotSize();
        const riskPerTrade = this.getUserValueInCash() * crossPrice;
        const riskPerShare = this.RiskPerShare;
        let value = (riskPerShare ? MathUtils.TruncateDouble(riskPerTrade / riskPerShare, InstrumentUtils.MAX_PRECISION_ON_SERVER) : NaN);

        if (inLots) {
            value = MathUtils.TruncateDouble(value / lotSize, ins.getLotStepPrecision());
        } else {
            const p = ins.LotStep * lotSize;
            const d = p * Math.floor(value / p);
            if (!MathUtils.equalToEpsilon(d, value)) {
                value -= value % p;
            }
        }

        if (isNaN(value) && !this.errorMsgShowing && this.isVisible) {
            this.errorMsgShowing = true;
            messageBoxHandler.Show(Resources.getResource('screen.error.title'), Resources.getResource('positionSizeCalculator.UnableToCalc'), messageBoxHandler.msgType.Error, function () { this.errorMsgShowing = false; }.bind(this), null, false, true);
        }

        return value;
    }

    public getUserValueInCash (): number | null // переводит Account risk в деньги если задано в процентах
    {
        const acc = this.account;
        if (!acc) return null;

        let result = this.userValue;

        if (this.isPercentMode) {
            const availableFunds = Account.GetAccountFeature(AccountFeature.AvailableFunds, acc, acc.assetBalanceDefault);

            result = (availableFunds * this.userValue) / 100;
        }

        return result;
    }

    public getCrossPrice (): any // кросс курс из валюты аккаунта в валюту котирования инструмента;
    {
        const acc = this.account;
        const ins = this.instrument;
        if (!acc || !ins) return null;

        const asset = acc.assetBalanceDefault.Asset;
        const crossPrice = acc.DataCache.CrossRateCache.GetCrossPriceExp1Exp2(asset.Name, ins.Exp2);

        return crossPrice;
    }

    public getAvailableFundsUpdateData (): any {
        const dp = this.getDynProperty();
        const updateData = {};

        dp.availableFundsChanged = true;
        updateData[OrderEditBaseUtils.POSITION_SIZING_PARAM] = dp;

        return { dpDict: updateData };
    }
}

// неправильный подход взятый из таблицы расписанной в документации (https://docs.google.com/document/d/1xViY9yArfT-Cn-POlfuWT9T6V6ZoUFxZ3gr912OGexo п.5)
// -> не работает при переходе в разные интервалы переменного тик сайза
// этот метод пока оставлю, но удалим если новых подход (всегда считать RiskPerShare в абсолютных прайсах) себя оправдает
// public getRiskPerShare ()
// {
//     let acc = this.account,
//         ins = this.instrument

//     if (!acc || !ins) return

//     let isBuy = this.IsBuy,
//         price = this.Price,
//         sl = this.SLPrice,
//         trSett = GeneralSettings.TradingDefaults,
//         offsetMode = trSett.ShowOffsetIn,
//         sltpOffsetMode = trSett.SetSlTpValuesInOffset,
//         inPoints = offsetMode === OffsetModeViewEnum.Points,
//         isTickCost_TickSize = ins.QuotingType === QuotingType.TickCost_TickSize,
//         slPrice = sltpOffsetMode ? ins.CalculatePrice(price, sl * (isBuy ? -1 : 1)) : sl,
//         pointSize = ins.GetPointSize(slPrice),
//         result = 0

//     if (sltpOffsetMode)                                         // При Set SL/TP values in offset = true
//     {
//         if (inPoints)                                           // и Show offset in = Points:
//         {
//             result = sl
//             if (isTickCost_TickSize)
//                 result = sl * ins.FuturesTickCoast / pointSize  // Risk per share = SL offset*tickCost/tickSize
//         }
//         else                                                    // иначе если в тиках
//         {
//             if (trSett.IsTicksFractionalForForex())    // Для типа котирования tickCost/tickSze также как и для оффсета в тиках
//                 result = sl * 10 * pointSize                    // Risk per share = SL offset * 10 * tick size или Risk per share = (SL offset + SL limit offset) * 10 * tick size если используется Use Stop limit instead of Stop
//             else
//                 result = sl * (isTickCost_TickSize ?            // Для типа котирования tickCost/tickSze: Risk per share = SL offset * tickCost
//                     ins.FuturesTickCoast :                      // При Set SL/TP values in offset = true и Show offset in = Ticks:
//                     pointSize)                                  // Risk per share = SL offset * tick size или Risk per share = (SL offset + SL limit offset) * tick size если используется Use Stop limit instead of Stop
//         }
//     }
//     else
//     {                                                           // При Set SL/TP values in offset = false:
//         result = Math.abs(price - sl)                           // Risk per share = abs(Price - SL price) или Risk per share = abs(Price - SL limit price) если используется Use Stop limit instead of Stop
//         if (isTickCost_TickSize)                                // Для типа котирования tickCost/tickSze:
//             result *= ins.FuturesTickCoast / pointSize          // Risk per share = abs(Price - SL price)*tickCost/tickSize
//     }

//     return result
// }
