// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
let Handler = function ()
{
    this._Handler = null;
}

Handler.prototype.setHandler = function (handler)
{
    this._Handler = handler
}

Handler.prototype.getHandler = function ()
{
    return this._Handler
}

export let contextMenuHandler = new Handler();

export let contextMenuWithOverHandler = new Handler();

export let datePickerDropDownHandler = new Handler();

export let buttonsPopupHandler = new Handler();

export let popupErrorHandler = new Handler();

export let messageBoxHandler = new Handler();

export let generateReportMessageHandler = new Handler();

export let riskWarningMessageScreenHandler = new Handler();

export let snapshotScreenHandler = new Handler();

export let brokerMessageScreenHandler = new Handler();

export let alertMessageScreenHandler = new Handler();

export let workspaceManagerHandler = new Handler();

export let positionExerciseRequestScreenHandler = new Handler();

export let positionsMutualCloseScreenHandler = new Handler();

export let modifyOrderScreenHandler = new Handler();

export let editPositionScreenHandler = new Handler();

export let externalScreenHandler = new Handler();

export let entitlementSubscribeDocumentScreenHandler = new Handler();

export let terceraNumericHandler = new Handler();

export let propertySetupScreenForIndicatorHandler = new Handler();

export let addOverlayScreenHandler = new Handler();

export let tooltipHandler = new Handler();

export let multiComboBoxPopupMenuHandler = new Handler();