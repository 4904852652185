// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { MathUtils } from "../../Utils/MathUtils.ts";
import { TerceraLabelTemplate, TerceraMultiLineLabelTemplate } from "../../templates.js";
import { Control } from "./Control.js";

export let TerceraLabel = Control.extend({
    template: TerceraLabelTemplate,
    data: function ()
    {
        return {
            text: '',
            innerHTML: "",
            style: '',
            additionalClass: ''
        };
    }
});

TerceraLabel.prototype.realRelativeBottom = function ()
{
    var $root = $(this.find('div'));
    return this.get('top') + $root.height();
};

TerceraLabel.prototype.oncomplete = function ()
{
    Control.prototype.oncomplete.call(this);

    this.observe('text', function (newValue)
    {
        this.set({ innerHTML: newValue })
    })
};

//Multi Line Label
export let TerceraMultiLineLabel = TerceraLabel.extend({
    template: TerceraMultiLineLabelTemplate,
    data: function ()
    {
        return {
            text: '',
            lines: []
        };
    }
});


TerceraMultiLineLabel.prototype.oncomplete = function ()
{
    TerceraLabel.prototype.oncomplete.call(this);

    this.observe('text', function (newValue)
    {
        this.set({ showTooltip: false });
        if (!newValue)
        {
            this.set({ lines: [] });
            return;
        }

        var tmpdiv = document.createElement("div")

        tmpdiv.innerHTML = newValue;
        tmpdiv.style.height = 'auto';
        tmpdiv.style.width = 'auto';
        tmpdiv.style.whiteSpace = 'nowrap';
        tmpdiv.style.display = 'none';
        tmpdiv.classList.add('js-tercera-label');
        document.body.appendChild(tmpdiv);
        var myWidth = this.get('width');
        var startWidth = $(tmpdiv).width();
        var resultArr = [];
        if (myWidth < startWidth)
        {
            var lineCount = MathUtils.trunc(this.get('height') / $(tmpdiv).height());
            var parsedLines = 0;
            var splitedMsg = newValue.split(' ');
            var i;
            var len = splitedMsg.length;

            var tmpLineSring = '';
            tmpdiv.innerHTML = '';
            for (i = 0; i < len; i++)
            {
                tmpLineSring = splitedMsg[i];
                tmpdiv.innerHTML += splitedMsg[i];
                if (myWidth < $(tmpdiv).width())
                {
                    resultArr.push(tmpLineSring);
                    if (resultArr.length === lineCount)
                    {
                        tmpLineSring = newValue.substring(newValue.indexOf(tmpLineSring));
                        resultArr[lineCount - 1] = tmpLineSring;
                        this.set({ showTooltip: true });
                        break;
                    }
                    tmpdiv.innerHTML = splitedMsg[i];
                    parsedLines++;
                }
                tmpLineSring = tmpdiv.innerHTML;
                tmpdiv.innerHTML += ' ';
            }
            if (resultArr.indexOf(tmpLineSring) === -1)
                resultArr.push(tmpLineSring);
        }
        else
            resultArr.push(newValue);
        document.body.removeChild(tmpdiv);
        this.set({ lines: resultArr });
    });
}