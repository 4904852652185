// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type BrokerMessageCustomButton } from '../Notifications/BrokerMessageCustomButton';
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectBrokerMessage extends DirectMessageBaseClass {
    public ID: number | null = null;
    public Text: string | null = null;
    public TextMessageType: number | null = null;
    public SenderID: number | null = null;
    public TargetID: number | null = null;
    public Date: Date | null = null;
    public Name: string | null = null;
    public ColorHEX: number | null = null;
    public ProductID: number | null = null;
    public ShowPopUp: boolean | null = null;
    public IsUnread: boolean | null = null;
    public ResponseCases: string[] | null = null;
    public CustomButtons: BrokerMessageCustomButton[] | null = null;
    public DisplayMethod = TextDisplayMethod.Text;

    constructor () {
        super(Message.CODE_TEXT_MESSAGE);
    }

    public AddCustomButton (brokerMessageCustomButton: BrokerMessageCustomButton): void {
        if (!brokerMessageCustomButton) {
            return;
        }

        if (!this.CustomButtons) {
            this.CustomButtons = [];
        }

        this.CustomButtons.push(brokerMessageCustomButton);
    }

    public IsHTMLDisplayMethod (): boolean {
        return this.DisplayMethod === TextDisplayMethod.Html;
    }
}

enum TextDisplayMethod {
    Text = 0,
    Html = 1
}
