// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { MODE_UPPER, MODE_LOWER } from "../../IndicatorConstants.ts";
import { ExpandDoubleVector } from "../../DoubleMatrix.ts";
import { IndicatorFunctions } from "../../IndicatorFunctions.ts";
import { iBuildInIndicator } from "../../iBuildInIndicator.ts";

export let iGator = function (jawPeriod, jawShift,
    teethPeriod, teethShift,
    lipsPeriod, lipsShift,
    maMode, priceType)
{
    iBuildInIndicator.call(this, 3)
    this.FJawPeriod = jawPeriod
    this.FTeethPeriod = teethPeriod
    this.FLipsPeriod = lipsPeriod

    this.FJawShiftPlus = jawShift > 0 ? jawShift : 0;
    this.FTeethShiftPlus = teethShift > 0 ? teethShift : 0;
    this.FLipsShiftPlus = lipsShift > 0 ? lipsShift : 0;
    this.FJawShiftMinus = jawShift < 0 ? -jawShift : 0;
    this.FTeethShiftMinus = teethShift < 0 ? -teethShift : 0;
    this.FLipsShiftMinus = lipsShift < 0 ? -lipsShift : 0;

    this.FMaMode = maMode
    this.FPriceType = priceType;
    this.FJawMA = new ExpandDoubleVector(0, this.FJawShiftPlus - this.FJawShiftMinus);
    this.FTeethMA = new ExpandDoubleVector(0, this.FTeethShiftPlus - this.FTeethShiftMinus);
    this.FLipsMA = new ExpandDoubleVector(0, this.FLipsShiftPlus - this.FLipsShiftMinus);

    this.Line1Offset = Math.max(this.FJawShiftMinus, this.FTeethShiftMinus);
    this.Line2Offset = Math.max(this.FLipsShiftMinus, this.FTeethShiftMinus);
}
iGator.prototype = Object.create(iBuildInIndicator.prototype)

Object.defineProperty(iGator.prototype, 'Name',
    {
        get: function () { return 'iGator' }
    })

iGator.prototype.Refresh = function (count, newThread)
{
    this.FJawMA.Dispose()
    this.FJawMA = new ExpandDoubleVector();
    this.FTeethMA.Dispose()
    this.FTeethMA = new ExpandDoubleVector();
    this.FLipsMA.Dispose()
    this.FLipsMA = new ExpandDoubleVector();
    iBuildInIndicator.prototype.Refresh.call(this, count, newThread)
}

Object.defineProperty(iGator.prototype, 'Key',
    {
        get: function ()
        {
            return this.DefaultKey + this.FJawPeriod + this.FJawShift +
                this.FTeethPeriod + this.FTeethShift +
                this.FLipsPeriod + this.FLipsShift +
                this.FMaMode + this.FPriceType
        }
    })
iGator.prototype.NextBar = function (callBound)
{
    iBuildInIndicator.prototype.NextBar.call(this, callBound)
    this.FJawMA.Add(0.0);
    this.FTeethMA.Add(0.0);
    this.FLipsMA.Add(0.0);
}

iGator.prototype.OnQuote = function (ci, callBound, callFromRefresh)
{

    let price = this.GetPrice(this.FPriceType, 0);
    this.FJawMA[this.FJawMA.Length - 1] = price;
    this.FTeethMA[this.FTeethMA.Length - 1] = price;
    this.FLipsMA[this.FLipsMA.Length - 1] = price;

    let Parent = this.Parent
    if (!Parent)
        return

    let start = Math.max(this.FJawPeriod, Math.max(this.FLipsPeriod, this.FTeethPeriod));
    if (start > this.Count - 1)
        return;

    let jaw1 = IndicatorFunctions.CallMovingFunction(this.FMaMode, this.FJawMA, this.FJawPeriod, this.Line1Offset - this.FJawShiftMinus + this.FJawShiftPlus);
    let teeth1 = IndicatorFunctions.CallMovingFunction(this.FMaMode, this.FTeethMA, this.FTeethPeriod, this.Line1Offset - this.FTeethShiftMinus + this.FTeethShiftPlus);

    if (jaw1 > 0 && teeth1 > 0)
    {
        let upper = Math.abs(jaw1 - teeth1);
        this.SetValue(MODE_UPPER, this.Line1Offset, upper);
    }

    let teeth2 = IndicatorFunctions.CallMovingFunction(this.FMaMode, this.FTeethMA, this.FTeethPeriod, this.Line2Offset - this.FTeethShiftMinus + this.FTeethShiftPlus);
    let lips2 = IndicatorFunctions.CallMovingFunction(this.FMaMode, this.FLipsMA, this.FLipsPeriod, this.Line2Offset - this.FLipsShiftMinus + this.FLipsShiftPlus);

    if (teeth2 > 0 && lips2 > 0)
    {
        let lower = -Math.abs(teeth2 - lips2);
        this.SetValue(MODE_LOWER, this.Line2Offset, lower);
    }
}