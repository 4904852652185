// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { TerceraLinkLabelTemplate } from "../../templates.js";
import { Control } from "./Control.js";

export let TerceraLinkLabel = Control.extend({
    template: TerceraLinkLabelTemplate,
    data: function ()
    {
        return {
            text: 'test',
            linkHref: ""
        };
    }
});

//
//
//
TerceraLinkLabel.prototype.private_LinkClick = function (sender)
{
    this.fire(TerceraLinkLabel.Events.Click, sender);
}

TerceraLinkLabel.prototype.oninit = function ()
{
    this.on('linkClick', this.private_LinkClick);
};

//
//
//
TerceraLinkLabel.Events =
{
    Click: 'onClick'
}
Object.freeze(TerceraLinkLabel.Events);