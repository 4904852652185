// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CreateAlertOrderParametrsSelectorTemplate } from "../../../templates.js";
import { ContainerControl } from "../../elements/ContainerControl.js";
import { Resources } from "../../../Commons/properties/Resources.ts";

export let CreateAlertOrderParametrsSelector = ContainerControl.extend({
    template: CreateAlertOrderParametrsSelectorTemplate,
    data: function ()
    {
        return {
            label: '',
            buttonText: '',
            btnTooltip: '',

            instrument: null,

            items: null,
            selectedItem: null,
            defaultValue: null,
            enabled: false,
            panelLink: null,
            infoBtnTooltip: "",

            width: 177
        }
    }
})

CreateAlertOrderParametrsSelector.prototype.getType = function () { return 'CreateAlertOrderParametrsSelector' };

CreateAlertOrderParametrsSelector.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.call(this)

    this.observe('infoBtnTooltip', this.updateInfoText)
    Resources.onLocaleChange.Subscribe(this.localize, this);
    this.localize();
}

CreateAlertOrderParametrsSelector.prototype.updateInfoText = function (newValue)
{
    this.set({ enabled: !!newValue })
}

CreateAlertOrderParametrsSelector.prototype.dispose = function ()
{
    Resources.onLocaleChange.UnSubscribe(this.localize, this)

    ContainerControl.prototype.dispose.call(this)
}

CreateAlertOrderParametrsSelector.prototype.localize = function ()
{
    this.set({
        buttonText: Resources.getResource('panel.orderEntry'),
        label: Resources.getResource('screen.Alerts.Label.OrderParameters'),
        btnTooltip: Resources.getResource('screen.Alerts.Label.OrderParameters.Tooltip')
    });
}