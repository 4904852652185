// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class TradingUnlockMessage extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_TRADING_UNLOCK_MESSAGE, fieldSet);
    }

    public SetPassword (password: string): void {
        this.setFieldValue(FieldsFactory.FIELD_PASSWORD, password);
    }

    public GetPassword (): string {
        return this.getFieldValue(FieldsFactory.FIELD_PASSWORD);
    }

    public setExternalResourse (extResId): void {
        this.setFieldValue(FieldsFactory.FIELD_EXT_RESOURCE, extResId);
    }
}
