// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from "../../Utils/CustomEvents.ts";
import { ControlsUtils } from "../UtilsClasses/ControlsUtils.ts";
import { contextMenuHandler } from "../../Utils/AppHandlers.js";
import { TerceraBaseWindowTemplate, TerceraAlwaysMiddlePartPartial } from "../../templates.js";
import { ContainerControl } from "../elements/ContainerControl.js";
import { TerceraButton } from "../elements/TerceraButton.ts";
import { BOTTOM_WINDOWS_MARGIN, TOP_WINDOWS_MARGIN, WINDOW_SIDE_BORDERS_WIDTH } from "../UtilsClasses/SizeConstants.ts";
import { BrandingUtils } from "../../Commons/UtilsClasses/BrandingUtils.ts";
import { ApplicationInfo } from "../../Commons/ApplicationInfo.ts";
import { Resources } from "../../Commons/properties/Resources.ts";
import { TerceraButtonEvents } from "../../Utils/Enums/ButtonEnums.ts";
import { TerceraLinkControlConstants } from "../UtilsClasses/TerceraLinkControlConstants.ts";

export let TerceraWindowBase = ContainerControl.extend({
    template: TerceraBaseWindowTemplate,
    data: function ()
    {
        return {
            isRelativeStyle: false,
            zIndex: 200,
            showHeader: true,
            showFooter: true,
            movable: true,
            resizable: true,
            header: '',
            headerInnerHTML: '',
            header_button_text: '',
            alwaysMiddle: false,
            showModalMask: false,
            isLookup: false,
            showOverflow: false,
            minWidth: 100,
            minHeight: 100,
            maxWidth: 5000,
            maxHeight: 5000,
            showBorder: true,
            showAttachHeaderButton: false,
            showFullscreenModeButton: false,
            forbiddenPanel: false,
            banText: '',
            symbolLinkTooltip: 'Symbol link',
            accountLinkTooltip: 'Account link',
            fullscreenModeBtnTooltip: '',
            style_addition_jsWin: '',
            style_addition_body: '',
            style_addition_header: '',
            style_addition_footer: '',
            style_addition_header_button: '',
            footer_height: 47,
            closeBtnVisible: true,
            isShowExploreTheAppLink: false,
            exploreTheAppLink: ApplicationInfo.exploreTheAppLink,
            exploreTheAppStart: '',
            exploreTheAppLinkPartText: '',
            exploreTheAppEnd: '',
            widthInPercent: null,
            heightInPercent: null,
            isAccountLinkShowState: false,
            isAccountLinkShow: false
        };
    },
    partials: {
        alwaysMiddlePart: TerceraAlwaysMiddlePartPartial
    },
    OnResize: new CustomEvent(),
    OnClose: new CustomEvent(),
    OnComplete: new CustomEvent(),

    forceCloseOnLogout: true,

    allowResizers: {
        left: true,
        top: true,
        right: true,
        bottom: true
    }
});

TerceraWindowBase.prototype.getType = function () { return 'TerceraWindowBase' };
//#region private service methods

TerceraWindowBase.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.apply(this);
    this.OnResize = new CustomEvent();
    this.OnClose = new CustomEvent();
    this.OnComplete = new CustomEvent();
}

//
// on complete -> subscribe for value changed, if necessary
//
TerceraWindowBase.prototype.oncomplete = function ()
{
    ContainerControl.prototype.oncomplete.apply(this);

    this.observe('header', function (newValue) { this.set({ headerInnerHTML: newValue }) })  // для возможности сетить в хедер html элементы (например если часть текста должна быть другого цвета как в #100287)
    this.observe('isAccountLinkShow', (newValue) =>
    {
        const newState = newValue ? TerceraLinkControlConstants.STATE_ALL : TerceraLinkControlConstants.STATE_NONE;
        void this.set({ accountLinkValue: newState, isAccountLinkShowState: this.getIsAccountLinkShow() });
    });

    var me = this;
    if (me.Controls && me.Controls.closeCrossBtn)
    {
        me.Controls.closeCrossBtn.on(
            TerceraButtonEvents.onClick,
            this.onCloseButtonClick.bind(this));
    }

    const self = this.getControl();
    const fullscreenModeBtn = self.getElementsByClassName("terceraButton js-button-fullscreen")[0];
    if (fullscreenModeBtn != null)
    {
        fullscreenModeBtn.addEventListener("click", this.toogleFullscreenMode.bind(this));
    }
    this.on('fullscreenModeClick', this.toogleFullscreenMode);

    this.OnComplete.Raise(this);
};

TerceraWindowBase.prototype.getIsAccountLinkShow = function ()
{
    return false;
}

TerceraWindowBase.prototype.toogleFullscreenMode = function ()
{
    const self = this.getControl();
    if (self.requestFullscreen)
    {
        self.requestFullscreen();
    } else if (self.mozRequestFullScreen)
    { // Firefox
        self.mozRequestFullScreen();
    } else if (self.webkitRequestFullscreen)
    { // Chrome, Safari, Opera
        self.webkitRequestFullscreen();
    } else if (self.msRequestFullscreen)
    { // IE/Edge
        self.msRequestFullscreen();
    }
}

// Close button (cross icon).
TerceraWindowBase.prototype.onCloseButtonClick = function ()
{
    if (this.canCloseFromButton())
        this.close(true);
};

TerceraWindowBase.prototype.canCloseFromButton = function ()
{
    return true;
}

TerceraWindowBase.prototype.close = function ()
{
    this.dispose();
    this.OnClose.Raise(this);
}

//
// Раскладываем контролы по id' шкам
//
TerceraWindowBase.prototype.onrender = function ()
{
    // вызов бэйс - обязательно!
    ContainerControl.prototype.onrender.apply(this);
}
//#endregion


//#region enums

//
// Events
//
TerceraWindowBase.Events =
{
    OnClosed: 'OnClosed',

}
Object.freeze(TerceraWindowBase.Events);

//#endregion

TerceraWindowBase.prototype.getClientPanel = function ()
{
    return this.Controls.windowPanelBody.getClientPanel();
}

TerceraWindowBase.prototype.getElementRect = function (element)
{
    if (!element)
    {
        return;
    }

    var rect = element.getBoundingClientRect(),
        top = rect.top + window.pageYOffset,
        left = rect.left + window.pageXOffset;

    return {
        top: top,
        left: left,
        right: left + rect.width,
        bottom: top + rect.height,
        width: rect.width,
        height: rect.height
    };
};

TerceraWindowBase.prototype.onMouseMove = function (event)
{
    if (typeof event.get === "function" && event.get('resizable'))
        this.onResizingBoundsVisible(event);
};

TerceraWindowBase.prototype.onMouseDown = function (event, isHeader)
{
    if (document.fullscreenElement != null) { return; }
    //dockspawn TODO подумать о более коректном пропуске события или привязки хедера доксистемы к нашей системе фокуса панелей.
    //останавливает событие если был клик по хедеру докконтейнера устанавлиывается в dockspawn
    if (event.original.target.skipMouseDown)
    {
        if (!event.original.target.skipMouseDownCloseContextMenu && contextMenuHandler && contextMenuHandler.isShowed())
            contextMenuHandler.Hide();
        delete event.original.target.skipMouseDown;
        delete event.original.target.skipMouseDownCloseContextMenu;
        return;
    }
    ContainerControl.prototype.onMouseDown.apply(this, [event, isHeader]);
    if (this.onProcessResize)
        ControlsUtils.Capture.SetCapture(this, event.original);
};

TerceraWindowBase.prototype.onResizing = function (event)
{
    if (!this.captured) return;

    var x = this.getPageX(event.original);
    var y = this.getPageY(event.original);
    var resizeParams = this.resizeParameters;
    var dx = resizeParams.down.x - x;
    var dy = resizeParams.down.y - y;
    var myRect = resizeParams.rect;
    var parentOffset = this.getParentOffset(this.el);
    var resizers = resizeParams.resizer;

    var minHeight = this.get('minHeight');
    var maxHeight = this.get('maxHeight');
    var minWidth = this.get('minWidth');
    var maxWidth = this.get('maxWidth');

    var topWindowMargin = TOP_WINDOWS_MARGIN;
    var bottomWindowMargin = BOTTOM_WINDOWS_MARGIN;
    var sideWindowMargin = WINDOW_SIDE_BORDERS_WIDTH;

    if (resizers.top)
    {
        if (myRect.height > minHeight && myRect.height + dy > minHeight && myRect.height + dy < maxHeight)
        {

            this.set({
                top: y > topWindowMargin ? myRect.top - dy - parentOffset.top : topWindowMargin,
                height: y > topWindowMargin ? myRect.height + dy - sideWindowMargin / 2 : myRect.bottom - topWindowMargin - sideWindowMargin / 2
            });
        }
    }

    if (resizers.bottom)
    {
        if (myRect.height - dy > minHeight && myRect.height - dy < maxHeight)
        {
            this.set({
                height: y < parentOffset.height - bottomWindowMargin ?
                    myRect.height - dy :
                    parentOffset.height - myRect.top - bottomWindowMargin - sideWindowMargin
            });
        }
    }

    if (resizers.left)
    {
        if (myRect.height > minHeight && myRect.width + dx > minWidth && myRect.width + dx < maxWidth)
        {
            this.set({
                width: myRect.width + dx - sideWindowMargin / 2,
                left: myRect.left - dx - parentOffset.left
            });
        }
    }

    if (resizers.right)
    {
        if (myRect.width - dx > minWidth && myRect.width - dx < maxWidth)
        {
            this.set({ width: myRect.width - dx });
        }
    }

    // call event
    const needRaiseEvent = !resizers.noresizer;
    if (needRaiseEvent)
    {
        this.OnResize.Raise();
    }
};
TerceraWindowBase.prototype.onResizingBoundsVisible = function (event)
{
    var resize = this.getResizer(event.original, event.node);
    var cursor = "";
    if ((resize.top && resize.left) || (resize.bottom && resize.right))
    {
        cursor = "nwse-resize";
    } else if ((resize.top && resize.right) || (resize.bottom && resize.left))
    {
        cursor = "nesw-resize";
    } else if (resize.top || resize.bottom)
    {
        cursor = "ns-resize";
    } else if (resize.left || resize.right)
    {
        cursor = "ew-resize";
    }

    event.node.style.cursor = cursor;
};

TerceraWindowBase.prototype.getResizer = function (event, node)
{
    var x = this.getPageX(event),
        y = this.getPageY(event),
        precision = 5,
        rect = this.getElementRect(node),
        limits = y > rect.top - precision && y < rect.bottom + precision && x > rect.left - precision && x < rect.right + precision,
        resizer = {
            noresizer: true,
            left: false,
            right: false,
            top: false,
            bottom: false
        };

    if (limits)
    {
        if (y < rect.top + precision && y > rect.top - precision && this.allowResizers.top)
        {
            resizer.top = true;
        }

        if (y < rect.bottom + precision && y > rect.bottom - precision && this.allowResizers.bottom)
        {
            resizer.bottom = true;
        }

        if (x < rect.left + precision && x > rect.left - precision && this.allowResizers.left)
        {
            resizer.left = true;
        }

        if (x < rect.right + precision && x > rect.right - precision && this.allowResizers.right)
        {
            resizer.right = true;
        }
    }

    if (resizer.left || resizer.right || resizer.bottom || resizer.top)
    {
        resizer.noresizer = false;
    }

    return resizer;
};
TerceraWindowBase.prototype.getMousePosition = function (property, alternative, additionalOffset)
{
    return function (event)
    {
        if (event[property] !== undefined)
        {
            return event[property];
        } else if (event[alternative] !== undefined)
        {
            return event[alternative] + document.body[additionalOffset] + document.documentElement[additionalOffset];
        }

        throw new Error("Pointer position not found");
    };
};
TerceraWindowBase.prototype.getPageX = function (event)
{
    return this.getMousePosition("pageX", "clientX", "scrollLeft")(event);
};
TerceraWindowBase.prototype.getPageY = function (event)
{
    return this.getMousePosition("pageY", "clientY", "scrollTop")(event);
};
TerceraWindowBase.prototype.getParentOffset = function (node)
{
    return node.offsetParent === document.body ? { left: 0, top: 0 } : this.getElementRect(node.offsetParent);
}

TerceraWindowBase.prototype.center = function (oninit = false)
{
    let control = this.getControl();
    let width = control.offsetWidth;
    let height = control.offsetHeight;

    let mWindow = $(window);
    let wWidth = mWindow.width();
    let wHeight = mWindow.height();

    let left = wWidth > width ? (wWidth - width) / 2 : 0;
    let top = wHeight > height ? (wHeight - height) / 2 : 0;

    this.set({ left: left, top: top });
};

TerceraWindowBase.prototype.getControl = function ()
{
    return this.find('*');
}

TerceraWindowBase.prototype.Properties = function ()
{
    return [];
};

TerceraWindowBase.prototype.dispose = function ()
{
    this.clearParentPanelTeardownData();
    ContainerControl.prototype.dispose.apply(this);
};
TerceraWindowBase.prototype.localize = function ()
{
    this.set('fullscreenModeBtnTooltip', Resources.getResource('panel.General.FullscreenModeBtn.Tooltip'));

    const exploreTheAppText = Resources.getResource('panel.General.ExploreTheApp.Text');
    const exploreTheAppLinkPartText = Resources.getResource('panel.General.ExploreTheApp.LinkPartText');
    const linkPartIndex = exploreTheAppText.indexOf(exploreTheAppLinkPartText);
    if (exploreTheAppText !== '' && linkPartIndex > 0)
    {
        const exploreTheAppStart = exploreTheAppText.substring(0, exploreTheAppText.indexOf(exploreTheAppLinkPartText));
        const exploreTheAppEnd = exploreTheAppText.substring(exploreTheAppText.indexOf(exploreTheAppLinkPartText) + exploreTheAppLinkPartText.length);
        this.set('exploreTheAppStart', exploreTheAppStart);
        this.set('exploreTheAppLinkPartText', exploreTheAppLinkPartText);
        this.set('exploreTheAppEnd', exploreTheAppEnd);
    }
    else
    {
        this.set('exploreTheAppStart', '');
        this.set('exploreTheAppLinkPartText', '');
        this.set('exploreTheAppEnd', '');
    }
}

// For closing screens opened from panels.
TerceraWindowBase.prototype.setParentPanel = function (parentPanel)
{
    if (!parentPanel || !parentPanel.teardown)
        return;

    this.clearParentPanelTeardownData();
    this.parentPanelTeardownCancelObj = parentPanel.on(
        'teardown',
        this.onParentPanelTeardown.bind(this));
};

TerceraWindowBase.prototype.onParentPanelTeardown = function ()
{
    this.clearParentPanelTeardownData();
    this.afterParentPanelTeardownCallback();
};

TerceraWindowBase.prototype.afterParentPanelTeardownCallback = function ()
{
    this.dispose();
};

TerceraWindowBase.prototype.clearParentPanelTeardownData = function ()
{
    if (this.parentPanelTeardownCancelObj)
    {
        this.parentPanelTeardownCancelObj.cancel();
        delete this.parentPanelTeardownCancelObj;
    }
};

TerceraWindowBase.prototype.onKeyDown = function (event)
{

};

TerceraWindowBase.prototype.onMouseEnter = function (event)
{

};

TerceraWindowBase.prototype.onMouseLeave = function (event)
{

};

TerceraWindowBase.prototype.updateBrandingImage = function ()   // for About and BrokerInfo screen #117995
{
    const imgURL = BrandingUtils.GetLastWebLoginBanner();
    const imgDIV = document.getElementsByClassName('js-brand-image')[0];
    if (imgDIV)
    {
        imgDIV.style = imgURL ?
            `background-image: url("${imgURL}");`
            : ``;
    }
}

TerceraWindowBase.prototype.isShowExploreTheAppLink = function ()
{
    return this.get('isShowExploreTheAppLink');
}