// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { type TerceraChartCashItemSeries } from '../../Series/TerceraChartCashItemSeries';
import { type TerceraChartWindowBase } from '../../Windows/TerceraChartWindowBase';
import { type IPointsConverter } from './IPointsConverter';

/// <summary>
/// Дефолтовый преобразователь координат - на основании кешайтема
/// </summary>
export class CashItemPointsCoverter implements IPointsConverter {
    public ww: TerceraChartWindowBase;
    public series: TerceraChartCashItemSeries;

    constructor (ww: TerceraChartWindowBase, series: TerceraChartCashItemSeries) {
        this.ww = ww;
        this.series = series;
    }

    /// <summary>
    /// Получить индекс бара по координате X
    /// </summary>
    public GetDataIndexByX (x: number): number {
        if (this.series == null) {
            return 0;
        }

        // Bar index from screen coordinates
        const r = this.ww.ClientRectangle;
        const barIndex = this.ww.i1 - (r.X + r.Width - x) / (this.ww.XScale) + 1;

        return barIndex;
    }

    /// <summary>
    /// Получить значение времени по координате X
    /// </summary>
    public GetDataX (x: number): number {
        if (this.series == null) {
            return 0;
        }

        return this.series.FindTimeExactly(this.GetDataIndexByX(x));
    }

    /// <summary>
    /// Получить значение цены по координате Y
    /// </summary>
    public GetDataY (y: number): number {
        const ww = this.ww;
        const YScale = ww.YScale;

        if (!YScale) return 0;

        const CR = ww.ClientRectangle;

        return (CR.Y + CR.Height - y) / YScale + ww.FminFloatY;
    }

    /// <summary>
    /// Получить значение координаты Y по цене
    /// </summary>
    public GetScreenY (dataX: number): number {
        if (this.ww.YScale == 0) {
            return 0;
        }

        // Avoid calling .bottom()
        return (this.ww.ClientRectangle.Y + this.ww.ClientRectangle.Height) - (dataX - this.ww.FminFloatY) * this.ww.YScale/* + ww.PaddingTop */;
    }

    /// <summary>
    /// Получить индекс бара по времени
    /// </summary>
    public GetBarIndex (timeX): number {
        return this.series.FindIntervalExactly(timeX);
    }

    public GetScreenX (barIndex): number {
        // Bar index to screen coordinates
        const r = this.ww.ClientRectangle;
        return (r.X + r.Width - (this.ww.i1 - barIndex + 1) * this.ww.XScale);
    }

    /// <summary>
    ///
    /// </summary>
    public GetScreenXbyTime (timeX, settings = null): number {
        if (this.series == null) {
            return 0;
        }

        // Calculate bar index
        const barIndex = this.series.FindIntervalExactly(timeX/*, settings */);

        // Bar index to screen coordinates
        const r = this.ww.ClientRectangle;
        return r.X + r.Width - (this.ww.i1 - barIndex + 1) * this.ww.XScale;
    }
}
