import { type ContextHelper } from 'ractive';
import { AccountWidgetItemTemplate } from '../../../templates';
import { Control } from './../Control_ts';
import { Resources } from '../../../Commons/properties/Resources';
import { type VerticalPanelDataProvider } from '../../cache/VerticalPanel/VerticalPanelDataProvider';
import { type QuickTableRow } from '../QuickTable/QuickTableRow';
import { DynProperty } from '../../../Commons/DynProperty';

export class AccountWidgetItem extends Control {
    private _DataProvider: VerticalPanelDataProvider = null;
    private RowId: number = null;
    private RowIdClicked: number | string = null;
    private Parameters: any = null;
    private ValueSkip: boolean = false;
    private readonly WhiteCoLor: string = '#E4E2E2';
    constructor () { super(); }

    setRowId (value: number): void {
        this.RowId = value;
    }

    set DataProvider (value: VerticalPanelDataProvider) {
        this._DataProvider = value;
        if (!isNullOrUndefined(value)) { this.RowIdClicked = this.findRowId(this.RowId, this.DataProvider); }
    }

    get DataProvider (): VerticalPanelDataProvider {
        return this._DataProvider;
    }

    public getType (): string { return 'AccountWidgetItem'; };

    public oncomplete (): void {
        super.oncomplete();
        this.on('DragNDropEnd', this.DragNDropEnd);
        this.on('accWidgetTileOver', this.accWidgetTileOver);
        this.on('accWidgetTileLeave', this.accWidgetTileLeave);
    }

    private accWidgetTileOver (containerContext): void {
        void this.set({ hovered: true });
    }

    private accWidgetTileLeave (containerContext): void {
        void this.set({ hovered: false });
    }

    private findRowId (id: number, dp: VerticalPanelDataProvider): number {
        const items = dp.generatedTableItems;
        const strId = id.toString();
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (item.itemId === strId) { return item.ItemId; }
        }
        return -1;
    }

    public UpdateData (rowsGetterHandler: (index: number | string) => QuickTableRow): void {
        if (isNullOrUndefined(this.DataProvider)) { return; }
        const value = this.DataProvider.GetVerticalPanelValueHandler(this.RowId, this.DataProvider, this.DataProvider.sett, this.RowIdClicked);
        let color: string;
        const row = rowsGetterHandler(this.RowIdClicked);
        if (!isNullOrUndefined(row)) { color = row.cells[1].ForeColor; }
        if (isNullOrUndefined(color)) { color = this.WhiteCoLor; }
        void this.set({ value, color });
    }

    private getPropKey (): string {
        return `${this.get('itemKey')}RowId`;
    }

    public Properties (): DynProperty[] {
        const properties: DynProperty[] = [];
        properties.push(new DynProperty(this.getPropKey(), this.RowId, DynProperty.STRING, DynProperty.HIDDEN_GROUP));
        return properties;
    }

    public callBack (newProperties: DynProperty[]): void {
        this.RowId = DynProperty.getPropValue(newProperties, this.getPropKey()) ?? this.RowId;
    }

    private DragNDropEnd (sender: ContextHelper): void {
        void this.set({ hovered: false });
        this.fire('itemDragNDropEnd');
    }

    public SetDataToWork (dragedData): void {
        if (isNullOrUndefined(this.DataProvider)) return;
        this.RowId = dragedData.Id;
        this.RowIdClicked = this.findRowId(this.RowId, this.DataProvider);
        this.Parameters = dragedData.Parameters;
        let color = dragedData.color;
        if (isNullOrUndefined(color)) { color = this.WhiteCoLor; }
        void this.set({
            color,
            header: Resources.getResource(this.Parameters.HeaderKey)?.toUpperCase(),
            value: this.DataProvider.GetVerticalPanelValueHandler(this.RowId, this.DataProvider, this.DataProvider.sett),
            tooltip: Resources.IsResourcePresent(this.Parameters.TooltipKey) ? Resources.getResource(this.Parameters.TooltipKey) : Resources.getResource(this.Parameters.HeaderKey)
        });
    }

    public Validate (getFirstVisibleRowWithItemHandler: () => number | string, getDragedRowIdHandler: (itemId: number | string) => any): void {
        if (isNullOrUndefined(this.DataProvider)) return;
        if (isNullOrUndefined(this.DataProvider.GetVerticalPanelValueHandler(this.RowId, this.DataProvider, this.DataProvider.sett, this.RowIdClicked))) {
            // при смене аккаунта значение в левом Info AccountWidget-а отсутствует (скрыто для выбранного ака) -> сменим на первую видимую строку с данными
            const itemId = getFirstVisibleRowWithItemHandler();
            const dragedData = getDragedRowIdHandler(itemId);
            this.RowId = dragedData.Id;
            this.Parameters = dragedData.Parameters;
            this.ValueSkip = this.Parameters.Hidden;
            this.RowIdClicked = itemId;
            void this.set({
                header: Resources.getResource(this.Parameters.HeaderKey)?.toUpperCase(),
                tooltip: Resources.IsResourcePresent(this.Parameters.TooltipKey) ? Resources.getResource(this.Parameters.TooltipKey) : Resources.getResource(this.Parameters.HeaderKey)
            });
        }
        // } else { this.RowIdClicked = this.findRowId(this.RowId, this.DataProvider); }
    }

    public UpdateRelations (assetBalanceItem): void {
        if (!isNullOrUndefined(this.RowId) && !isNaN(this.RowId)) {
            let tmpParams = assetBalanceItem.GetColumnParams(this.RowId);
            if (tmpParams.Hidden === true && !isNullOrUndefined(this.DataProvider)) {
                const allcurentvisible = this.DataProvider.GetItemsIDHandler(this.DataProvider, this.DataProvider.sett);
                if (allcurentvisible.length > 0) {
                    const newId = parseInt(allcurentvisible[0]);
                    this.RowId = newId;
                    tmpParams = assetBalanceItem.GetColumnParams(newId);
                }
            }
            this.setStartData(tmpParams);
        } else {
            const tmpRowKey = this.GetSortHeaderKey(assetBalanceItem, this.RowId);
            if (isNullOrUndefined(tmpRowKey) && !isNullOrUndefined(this.DataProvider)) {
                const allcurentvisible = this.DataProvider.GetItemsIDHandler(this.DataProvider, this.DataProvider.sett);
                if (allcurentvisible.length > 0) {
                    const newId = parseInt(allcurentvisible[0]);
                    this.RowId = newId;
                    this.setStartData(assetBalanceItem.GetColumnParams(newId));
                }
            } else {
                this.ValueSkip = false;
                void this.set({
                    header: Resources.getResource(tmpRowKey)?.toUpperCase(),
                    tooltip: Resources.getResource(tmpRowKey)
                });
            }
        }
    }

    private GetSortHeaderKey (assetBalanceItem, headerKey): string {
        if (assetBalanceItem === null) { return ''; }

        if (!isNullOrUndefined(assetBalanceItem.Account) && assetBalanceItem.Account.AccountAdditionalInfo !== null &&
            !isNullOrUndefined(assetBalanceItem.Account.AccountAdditionalInfo[headerKey])) { return assetBalanceItem.Account.AccountAdditionalInfo[headerKey].NameKey; } else if (!isNullOrUndefined(assetBalanceItem.AssetBalance) && assetBalanceItem.AssetBalance.AssetAdditionalInfo !== null &&
                !isNullOrUndefined(assetBalanceItem.AssetBalance.AssetAdditionalInfo[headerKey])) { return assetBalanceItem.AssetBalance.AssetAdditionalInfo[headerKey].NameKey; } else { return ''; }
    }

    private setStartData (Parameters): void {
        this.Parameters = Parameters;
        this.ValueSkip = this.Parameters.Hidden;
        void this.set({
            header: Resources.getResource(this.Parameters.HeaderKey)?.toUpperCase(),
            tooltip: Resources.IsResourcePresent(this.Parameters.TooltipKey) ? Resources.getResource(this.Parameters.TooltipKey) : Resources.getResource(this.Parameters.HeaderKey)
        });
    }
}

Control.extendWith(AccountWidgetItem, {
    template: AccountWidgetItemTemplate,
    data: function () {
        return {
            header: '',
            value: '',
            color: '',
            tooltip: '',
            itemKey: 'AWI',
            DragNDropInProcess: false,
            hovered: false
        };
    }
});
