// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { Resources } from '../../Commons/properties/Resources';
import { NotificationsCounterChangedEvent } from '../../Utils/Notifications/NotificationsCounterChangedEvent';
import { DataCache } from '../../Commons/DataCache';
import { TerceraSideBarButton } from './SideBar/TerceraSideBarButton';
import { ControlsTypes } from '../UtilsClasses/FactoryConstants';

export class NotificationsButton extends TerceraSideBarButton {
    private readonly tooltipKey: string = 'panel.Notifications.tt';
    private isUnreadIDs = null;

    constructor () {
        super();
    }

    getType (): ControlsTypes {
        return ControlsTypes.NotificationsButton;
    }

    oncomplete (): void {
        super.oncomplete();

        DataCache.OnBMCounterMsgReceived.Subscribe(this.OnBMCounterMsgReceived, this);

        this.localize();
    }

    dispose (): void {
        DataCache.OnBMCounterMsgReceived.UnSubscribe(this.OnBMCounterMsgReceived, this);

        super.dispose();
    }

    private localize (): void {
        void this.set('tooltip', Resources.getResource(this.tooltipKey));
    }

    private onReadMsg (bmID): void {
        if (this.isUnreadIDs[bmID]) {
            this.isUnreadIDs[bmID] = false;
            this.decrementCounter();
        }
    }

    private OnBMCounterMsgReceived (bmIDs, action = NotificationsCounterChangedEvent.NewArr): void {
        if (!bmIDs) {
            return;
        }

        if (action === NotificationsCounterChangedEvent.RemoveOne) { // only one BM id with RemoveOne action means that BM was read
            this.onReadMsg(bmIDs);
            return;
        }

        if (action === NotificationsCounterChangedEvent.AddOne) { // only one BM id with AddOne action means that new unread BM has come
            if (this.isUnreadIDs && !this.isUnreadIDs[bmIDs]) {
                this.isUnreadIDs[bmIDs] = true;
                void this.set('counter', (this.get('counter') || 0) + 1);
            }
            return;
        }

        const result = {}; let count = 0;

        for (let i = 0; i < bmIDs.length; i++) {
            if (!result[bmIDs[i]]) {
                count++;
                result[bmIDs[i]] = true;
            }
        }

        this.isUnreadIDs = result;
        this.onCounterNumberChanged(count);
    }

    private onCounterNumberChanged (unreadQty): void {
        // if (unreadQty > 0) {
        //     this.startCounterBlinking();
        // }

        void this.set('counter', unreadQty);
    }

    private decrementCounter (): void {
        const oldValue = this.get('counter');
        if (!oldValue) { // can't be less than zero
            return;
        }

        void this.set('counter', oldValue - 1);
    }
}

TerceraSideBarButton.extendWith(NotificationsButton, {});
