// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CrossRateCache } from './CrossRateCache';
import { MarkupFormula } from './MarkupFormula';
import { MarkupValue } from './MarkupValue';

export class CrossratesPlan {
    public Name: any = null; // string
    public Description: any = null; // string
    public CounterAccountID: any = null; // int

    public nonTradableCrossRates = new MarkupValue();
    public markupCache = {}; // Dictionary<int, MarkupValue>
    public DCache: any;
    public Id: any;

    constructor (id, dCache) {
        this.DCache = dCache;

        this.Id = id; // long
    }

    public Update (message): void {
        this.Name = message.Name;
        this.Description = message.Description;
        this.CounterAccountID = message.CounterAccountID;

        this.FillNonTradableMarkups(message);
        this.FillMarkups(message);
    }

    public GetNonTradableMarkup (exp1, exp2, crossprice): number // non-tradable markup
    {
        const res = this.nonTradableCrossRates.CrossRates[exp1 + CrossRateCache.CROSS_DELIMITER + exp2] || 0;
        return crossprice * res / 100;
    }

    public GetMarkup (exp1, exp2, crossprice, tradeInstrument, forBuy): any // markup for pnl calc
    {
        const result = { markup: 0, formula: MarkupFormula.None };
        let value = null;
        if (tradeInstrument) {
            const markUpByType = this.markupCache[tradeInstrument.TypeId];
            value = markUpByType || this.markupCache[-1]; // дефолтовый если неопределенно для конкретного типа инструмента
        }

        const res = value ? value.CrossRates[exp1 + CrossRateCache.CROSS_DELIMITER + exp2] : null;
        if (res) {
            result.markup = (forBuy ? 1 : -1) * (crossprice * res / 100);
            result.formula = value.Formula;
        }

        return result;
    }

    public FillNonTradableMarkups (message): void {
        const table = this.ParseDatas(message.NonTradableMarkups);
        this.nonTradableCrossRates = new MarkupValue(table);
    }

    public FillMarkups (message): void {
        const dCache = this.DCache;
        message.MarkupData.sort(function (x, y) // сортируем от общих групп - к конкретным, для корректной перезаписи
        {
            const xt = x.TypeId; const yt = y.TypeId;

            if (xt == yt) {
                return 0;
            } // планы равносильны

            if (xt == -1) // дефолтовый - самый первый
            {
                return -1;
            }
            if (yt == -1) {
                return 1;
            }

            if (dCache) {
                let list = []; // все типы, для которых x - родительский
                dCache.FindInstrumentTypes(xt, list);

                if (list.includes(yt)) // если x стоит выше в иерархии, чем y
                {
                    return -1;
                }

                list = []; // наоборот
                dCache.FindInstrumentTypes(yt, list);
                if (list.includes(xt)) {
                    return 1;
                }
            }

            return xt - yt; //  <- типы равносильны. чтобы была определенность
        });

        const copy = {}; // Dictionary < int, MarkupValue > copy = new Dictionary < int, MarkupValue > ();
        for (const markup of message.MarkupData) {
            const formula = markup.Formula;
            const prices = this.ParseDatas(markup.Markups);
            const markupValue = new MarkupValue(prices, formula);

            if (markup.TypeId == -1) {
                copy[markup.TypeId] = markupValue;
            } else {
                const list = [];
                dCache.FindInstrumentTypes(markup.TypeId, list);
                for (const subtype of list) {
                    copy[subtype] = markupValue;
                }
            }
        }

        this.markupCache = copy;
    }

    public ParseDatas (datas): any // private static Dictionary<string, double> ParseDatas(CrossRateData[] datas)
    {
        const table = {}; // new Dictionary < string, double> ();
        for (const data of datas) {
            if (data.Price == 0) {
                continue;
            }

            table[data.Exp1 + CrossRateCache.CROSS_DELIMITER + data.Exp2] = table[data.Exp2 + CrossRateCache.CROSS_DELIMITER + data.Exp1] = data.Price;
        }
        return table;
    }
}
