// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { FieldsFactory } from '../../../Factory/FieldsFactory';
import { PFixFieldSet } from '../../GroupsImport';

export class AlgorithmHistoricalGainersLosersTopsDelayDataGroup extends PFixFieldSet {
    constructor (pfixFieldSet: any) {
        super(FieldsFactory.ALGORITHM_HISTORICAL_GAINER_LOSER_DELAY_GROUP, null, pfixFieldSet);
    }
}
