// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DirectBrokerMessage } from '../../../../Utils/DirectMessages/DirectMessagesImport';
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { BrokerMessageCustomButton } from '../../../../Utils/Notifications/BrokerMessageCustomButton';
import { AliasesGroup, CustomButtonsGroup } from '../Groups/GroupsImport';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class BrokerMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): DirectBrokerMessage[] {
        const msg = new DirectBrokerMessage();

        const len = fieldSet.Count();
        for (let i = 0; i < len; i++) {
            const fixField = fieldSet.fieldSet[i];
            if (fixField.FieldId === 0) {
                continue;
            }

            if (fixField.FieldId === FieldsFactory.FIELD_ID) {
                msg.ID = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_TEXT) {
                msg.Text = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_TEXT_MESSGE_TYPE) {
                msg.TextMessageType = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_SENDER_ID) {
                msg.SenderID = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_TARGET_ID) {
                msg.TargetID = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_NAME) {
                msg.Name = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_COLOR_IN_FRONT) {
                msg.ColorHEX = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_SHOW_POPUP) {
                msg.ShowPopUp = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_PRODUCT_ID) {
                msg.ProductID = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_IS_UNREAD) {
                msg.IsUnread = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_DATE) {
                msg.Date = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_BROKER_MESSAGE_RESPONSE_CASES) {
                msg.ResponseCases = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_GROUP) {
                const group = fixField.Value;
                if (group.GroupId === FieldsFactory.CUSTOM_BUTTONS_GROUP) {
                    const customBtn = BrokerMessageProcessor.CreateCustomButton(group);
                    if (customBtn) {
                        msg.AddCustomButton(customBtn);
                    }
                }
            } else if (fixField.FieldId === FieldsFactory.FIELD_DISPLAY_METHOD) {
                msg.DisplayMethod = fixField.Value;
            }
        }

        return [msg];
    }

    public static CreateCustomButton (group): BrokerMessageCustomButton {
        if (!group) {
            return null;
        }

        const bmcb = new BrokerMessageCustomButton();

        const cbg = new CustomButtonsGroup(group);
        if (cbg) {
            bmcb.fTitle = cbg.getTitle();
            bmcb.fTooltip = cbg.getTooltip();
            bmcb.fURL = cbg.getURL();
            bmcb.fCustomButtonNumber = cbg.getCustomButtonNumber(); // for 470 response
        }

        bmcb.fAliases = AliasesGroup.GetFromFieldSet(group);

        return bmcb;
    }
}
