import { type OptionDataSeries } from './OptionDataSeries';

export class AnalyzerChartData {
    public readonly basePriceLineSeries: OptionDataSeries[];
    public readonly zeroLineSeries: OptionDataSeries[];
    public readonly timeLineSeries: OptionDataSeries[];
    public readonly intrinsicSeries: OptionDataSeries[];
    public readonly volatilitySeries: OptionDataSeries[][];

    constructor (basePriceLineSeries: OptionDataSeries[], zeroLineSeries: OptionDataSeries[], timeLineSeries: OptionDataSeries[], intrinsicSeries: OptionDataSeries[], volatilitySeries: OptionDataSeries[][]) {
        this.basePriceLineSeries = basePriceLineSeries;
        this.zeroLineSeries = zeroLineSeries;
        this.timeLineSeries = timeLineSeries;
        this.intrinsicSeries = intrinsicSeries;
        this.volatilitySeries = volatilitySeries;
    }
}
