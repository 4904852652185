// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
export enum OrderExecutionType {
    // UNKNOWN - внутренний тип для обработки ошибок, сервер его не передает
    UNKNOWN = 0,
    // событие запроса создания ордера в нашей системе
    PENDING_NEW = 1,
    // событие выполнения условий ордера на исполнение
    PENDING_EXECUTION = 2,
    // событие запроса отмены ордера
    PENDING_CANCEL = 3,
    // событие запроса на модификацию ордера
    PENDING_REPLACE = 4,
    // модификация неактивного ордера
    PENDING_REPLACE_NOT_ACTIVE = 5,
    // событие создания ордера в нашей системе
    NEW = 10,
    // событие создания ордера, который неактивен до выполнения некоторых условий
    ACCEPTED = 11,
    // событие модификации ордера
    REPLACED = 20,
    // событие частичного заполнения ордера
    PART_FILLED = 30,
    // событие полного исполнения ордера
    FILLED = 31,
    // событие отмены ордера
    CANCELED = 40,
    // событие отклонения ордера
    REFUSED = 41,
    // событие смены статуса ордера, которое не покрывается описанными событиями
    RESTATED = 42,
    // событие активации ордера. Пример - достижение стоп прайса стоп-лимит ордера;
    ACTIVATED = 43,
    // удаляем ордер из панелей
    REMOVED = 80
}
