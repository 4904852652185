// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type SessionSettingsType } from '../../Commons/SessionSettings';
import { type Account } from '../../Commons/cache/Account';
import { type Instrument } from '../../Commons/cache/Instrument';
import { MathUtils } from '../../Utils/MathUtils';
import { QuickTableComparingType } from '../../Utils/QuickTableMisc/QuickTableComparingType';
import { type ColumnParams } from './ColumnParams';

export abstract class BaseItem {
    /// <summary>
    /// Здесь хранятся настройки колонок (один экземпляр на класс)
    /// </summary>
    public static columnsParams = [];

    public static AutoGeneratedId = 0;

    public Visible = true;
    public IsGroupItem = false;
    public QuickTableRow = null;
    public sessionSettings: SessionSettingsType;
    public CustomColoring = false;
    public QuickTableEditingInfoMap = {};
    public CellForeColorMap = {};
    public CellBackColorMap = {};
    /// <summary>
    /// +++ Цвета ячеек итема
    /// </summary>
    public CellsColor: Record<number, any> = {};// new Dictionary<int, Color>();

    /// <summary>
    /// +++ Цвета ячеек итема
    /// </summary>
    public CellsForeColor: any = {};

    public Disabled = false; // цвет текста ячеек disabled item-ов серый + др.особенности. Пример: PositionItem с OptionExerciseStatus = Pending exercise #93127

    // TODO. Bullshit.
    protected itemId: number | string;
    public superItemId = null; // ItemId of super item (for FIFO) #80010
    public isSubItem = false; // ItemId != superItemId(for FIFO) #80010
    public isNeedFireUpdate = false;
    public NoIgnoreForceUpdateMapIndex = {}; // index = true
    public totalCurrency: string | null;

    constructor (sessionSettings?) {
        this.sessionSettings = sessionSettings || null;

        this.itemId = BaseItem.AutoGeneratedId++;
    }

    get ItemId (): number | string {
        return this.itemId;
    }

    set ItemId (id: number | string) {
        this.itemId = id;
    }

    public ColumnCount (): number {
        return BaseItem.columnsParams.length;
    }

    public isModifyAllowed (columnIndex: number): boolean {
        return true;
    }

    public getColumnData (column: number): ColumnData {
        return new ColumnData();
    }

    public abstract getColumnValue (column: number): any;

    public abstract GetColumnParams (column: number): ColumnParams;

    public GetQuickTableEditingInfo (column: number): any {
        return this.QuickTableEditingInfoMap[column] || null;
    }

    public GetCellForeColorMap (column: number): any {
        return this.CellForeColorMap[column] || null;
    }

    public GetCellBackColorMap (column: number): any {
        return this.CellBackColorMap[column] || null;
    }

    public fireUpdate (): void {

    }

    public GetCurrentAccount (): Account {
        return null;
    }

    public GetCurrentInstrument (): Instrument {
        return null;
    }

    public InstrumentName (saveMode?): string {
        return null;
    }

    public Dispose (): void {
        this.sessionSettings = null;
    }

    public GetDataType (columnIndex: number): QuickTableComparingType {
        return QuickTableComparingType.String;
    }

    public getValueForTotalCalculation (column, value): number // truncate for total summing #108095
    {
        const point = this.getFormatTotalValue(column, value, true);
        return MathUtils.TruncateDouble(value, point);
    }

    public currentSideIsBuy (): boolean { return true; } // side of item if valid

    public updateTotalCurrencyWith (assetName: string): string | null // total currency for item and argument (~assetname) #110253
    {
        const acc = this.GetCurrentAccount();
        // const ins = this.GetCurrentInstrument() || null;
        // DC = ins ? ins.DataCache : DataCache,                               // пока хз надо ли
        // assetBalance = DC.GetAssetBalanceCorrect(acc, ins, this.currentSideIsBuy()),
        const itemCurrency = acc ? acc.BaseCurrency : null; // (assetBalance ? assetBalance.toString() : null)

        this.totalCurrency = itemCurrency // total Currency After Filtration
            ? (!assetName || itemCurrency == assetName
                ? itemCurrency
                : assetName)
            : null;

        return this.totalCurrency;
    }

    // precisionMode нужен для точности обрезания перед суммированием в тотал строке и используется в getValueForTotalCalculation
    public getFormatTotalValue (column, value, precisionMode = false, totalCurrencyAfterFiltration: string = null): any {
        if (precisionMode) {
            return 2;
        }

        return value;
    }

    public NeedSortMenu (): boolean {
        return false;
    }
}

export class ColumnData {
    public Value: any;
    public FormattedValue: any;
    public GroupByValue: any;

    constructor (Value: any = null, FormattedValue: any = null, GroupByValue?: any) {
        this.Value = Value;
        this.FormattedValue = FormattedValue;
        this.GroupByValue = GroupByValue;
    }
}
