// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { UrlUtils } from '../../Utils/UrlUtils';

export class News {
    public received = '';
    public source = '';
    public subject = '';

    public contentIsLink = false;
    public category = '';
    public symbol = '';
    public priority = '';

    public routeID = '';
    public newsID = '';

    public text = '';
    public allLines = '';
    public line0 = '';
    public line2 = '';
    public content = '';

    constructor (newsGroup: any = undefined) {
        if (newsGroup) {
            this.UpdateByMsg(newsGroup);
        }
    }

    public UpdateByMsg (newsGroup): void {
        this.received = newsGroup.getDate();
        this.source = newsGroup.getSource();
        this.subject = newsGroup.getTheme();

        let content = newsGroup.getContent(); // newsGroup.getAllLines()
        this.contentIsLink = content.isUrl;
        if (content.isUrl) {
            content = content.url;
        }

        this.content = content;

        this.category = newsGroup.getCategory();
        this.symbol = newsGroup.getSymbol();
        this.priority = newsGroup.getPriority();

        this.routeID = newsGroup.getRouteID();
        this.newsID = newsGroup.getNewsID();

        this.text = newsGroup.getText();
        this.allLines = newsGroup.getAllLines();
        this.line0 = newsGroup.getContentByLineNumber(0);
        this.line2 = newsGroup.getContentByLineNumber(2);
    }

    public GetID (): string {
        return this.newsID;
    }

    public GetRouteID (): string {
        return this.routeID;
    }

    public OnNewsDoubleClicked (): any {
        if (UrlUtils.isUrl(this.line2)) {
            return UrlUtils.openFixedURL(this.line2);
        } // Если в NEWS_MESSAGE(52) в поле TEXT[115] с LINE_NUMBER[118]=2 - пришла линка, то по дабл клику на новость открываем новость в новой вкладке браузера.

        const url = News.getLinkFromText(this.text); // Дополнительно делаем проверку: если в теге выше не пришла ссылка, то проверяем наличие ссылки в теге TEXT[115], тег может содержать только ссылку, либо ссылка может быть с текстом.
        if (UrlUtils.isUrl(url)) {
            return UrlUtils.openFixedURL(url);
        }

        const newWin = window.open(''); // Если в NEWS_MESSAGE(52) в поле TEXT[115] с LINE_NUMBER[118]=2 - не пришла линка, то по дабл клику на новость открываем в новой вкладке. Во вкладке браузера отображаем контент из тега TEXT[115] с LINE_NUMBER[118]=0.
        const doc = newWin.document;
        doc.open();
        if (!this.contentIsLink) {
            doc.write(this.content);
        } // Всратое гавно https://tp.traderevolution.com/entity/101031
        else {
            doc.write(this.line0);
        }

        doc.close();
    }

    public static getLinkFromText (text): any {
        const urls = UrlUtils.ParseTextForURL(text, true);

        for (let i = 0; i < urls.length; i++) {
            let url = urls[i];
            if (!url.isText) {
                url = url.value.replace('"', '');
            }

            if (UrlUtils.isUrl(url)) {
                return url;
            }
        }

        return null;
    }
}
