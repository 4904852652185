// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../Commons/properties/Resources';
import { Point, Rectangle } from '../../Commons/Geometry';
import { LayersEnum, TerceraChartBaseRenderer } from './TerceraChartBaseRenderer';
import { Cursors } from '../../Commons/Cursors';
import { TerceraChartCashItemSeriesDataType } from '../Series/TerceraChartCashItemSeriesEnums';
import { Color, Pen, RectangleEdgeFilter, SolidBrush, Brushes, Pens } from '../../Commons/Graphics';
import { MathUtils } from '../../Utils/MathUtils';
import { ChartDataType } from '../Utils/ChartConstants';
import { TerceraChartAction, TerceraChartActionEnum, TerceraChartToolbarsEnum } from '../TerceraChartAction';
import { TerceraChartMainPriceRenderer } from './TerceraChartMainPriceRenderer';
import { ThemeManager } from '../../Controls/misc/ThemeManager';
import { TradeAnalysisType } from '../../Utils/History/TFInfo';
import { ColorStyleWidth, DynProperty } from '../../Commons/DynProperty';
import { type TerceraChartBase } from '../TerceraChartBase';

export class TerceraChartDayHighLowRenderer extends TerceraChartBaseRenderer {
    ShowHighLow_High: string = '';
    ShowHighLow_Low: string = '';
    ShowHighLow_Close: string = '';

    highLowBrush: SolidBrush | null = null;
    highLowPen: Pen | null = null;
    highLowStyle: number = Pen.csShapedChart;
    highLowWidth: number = 1;

    prevCloseBrush: SolidBrush | null = null;
    prevClosePen: Pen | null = null;
    prevCloseStyle: number = Pen.csShapedChart;
    prevCloseWidth: number = 1;

    allHighLow: DayHighLowItem[] = [];

    isHighHovered: boolean = false;
    isLowHovered: boolean = false;

    isLineHighVisible: boolean = true;
    isLineLowVisible: boolean = true;

    mousePosition: Point = Point.Empty();

    _Visible: boolean = false;
    prevCloseVisible: boolean = false;

    chart: any; // Replace with the actual type of chart

    constructor (chart: TerceraChartBase) {
        super(chart);

        this.ThemeChanged();
        this.Localize();

        this.assignLayer = LayersEnum.Quotes;
        this.SetClassName('TerceraChartDayHighLowRenderer');
    }

    Enabled (): boolean {
        const chart = this.chart;
        if (!chart) return false;

        const tfi = chart.TimeFrameInfo();
        if (!tfi) return false;

        const isChartDataTypeOk =
            tfi.ChartDataType === ChartDataType.Default ||
            tfi.ChartDataType === ChartDataType.HeikinAshi;

        return isChartDataTypeOk && tfi.TradeAnalysisType() === TradeAnalysisType.None;
    }

    PrevCloseVisibleGet (): boolean {
        return this.prevCloseVisible && this.Enabled();
    }

    VisibleGet (): boolean {
        return this.Visible && this.Enabled();
    }

    Localize (): void {
        this.ShowHighLow_High = Resources.getResource('chart.ShowHighLow.High');
        this.ShowHighLow_Low = Resources.getResource('chart.ShowHighLow.Low');
        this.ShowHighLow_Close = Resources.getResource('chart.ShowHighLow.Close');
    }

    ThemeChanged (): void {
        this.highLowBrush = new SolidBrush(ThemeManager.CurrentTheme.Chart_HighLow);
        this.highLowPen = new Pen(ThemeManager.CurrentTheme.Chart_HighLow);

        Pen.ProcessPen(this.highLowPen, this.highLowStyle);

        this.prevCloseBrush = new SolidBrush(ThemeManager.CurrentTheme.PrevCloseLineColor);
        this.prevClosePen = new Pen(ThemeManager.CurrentTheme.PrevCloseLineColor);

        Pen.ProcessPen(this.prevClosePen, this.prevCloseStyle);
    }

    ProcessMouseMove (e: any): boolean {
        if (!this.VisibleGet() && !this.PrevCloseVisibleGet()) {
            return super.ProcessMouseMove(e);
        }

        this.mousePosition = new Point(e.X, e.Y);

        // TODO. Dictionary of layers to redraw?
        // e.NeedRedraw = true;

        return super.ProcessMouseMove(e);
    }

    ProcessMouseDown (e: any): boolean {
        if (this.isHighHovered) {
            this.isLineHighVisible = !this.isLineHighVisible;
        }
        if (this.isLowHovered) {
            this.isLineLowVisible = !this.isLineLowVisible;
        }

        // TODO. Dictionary of layers to redraw?
        // e.NeedRedraw = true;

        return super.ProcessMouseDown(e);
    }

    GetCursor (e: any): string {
        if (this.isHighHovered || this.isLowHovered) {
            return Cursors.Hand;
        }

        return super.GetCursor(e);
    }

    FindMinMax (out_minMax: any, window: any): boolean {
        const chart = this.chart;
        const cashItemSeries = chart.MainCashItemSeries();
        const cashItem = cashItemSeries._cashItem;
        const dataType = chart.model.timeFrameInfo.HistoryType;
        const ins = cashItem.Instrument;

        if (!ins) return false;

        switch (chart.cashItemSeriesSettings.DataType) {
        case TerceraChartCashItemSeriesDataType.Absolute:
            out_minMax.tMin = ins.GetLow(dataType);
            out_minMax.tMax = ins.GetHigh(dataType);
            break;
        /*
                case TerceraChartCashItemSeriesDataType.Relative:
                    out_minMax.min = chart.cashItemSeriesSettings.relativeDataConverter.Calculate(chart.mainPriceRenderer.DayLow);
                    out_minMax.max = chart.cashItemSeriesSettings.relativeDataConverter.Calculate(chart.mainPriceRenderer.DayHigh);
                    break;

                case TerceraChartCashItemSeriesDataType.Log:
                    out_minMax.min = chart.cashItemSeriesSettings.logDataConverter.Calculate(chart.mainPriceRenderer.DayLow);
                    out_minMax.max = chart.cashItemSeriesSettings.logDataConverter.Calculate(chart.mainPriceRenderer.DayHigh);
                    break;
                */
        default:
            out_minMax.tMin = ins.GetLow(dataType) || cashItem.dayLow || ins.Low;
            out_minMax.tMax = ins.GetHigh(dataType) || cashItem.dayHigh || ins.High;
            break;
        }

        return true;
    }

    Dispose (): void {
        this.chart = null;
        super.Dispose();
    }

    IsCanPlaced (lastX: number, item: DayHighLowItem): boolean {
        if (item.Type == HighLowType.PrevClose) {
            const allHighLow = this.allHighLow;
            const len = allHighLow.length;
            for (let j = 0; j < len; j++) {
                const i = allHighLow[j];
                if (item.RightBorder > i.LeftBorder - 5) {
                    if (item.GetYTop() > i.GetYTop() && item.GetYTop() < i.GetYBottom()) {
                        item.Y += i.GetYBottom() - item.GetYTop();
                        return true;
                    } else if (item.GetYBottom() > i.GetYTop() && item.GetYBottom() < i.GetYBottom()) {
                        item.Y -= item.GetYBottom() - i.GetYTop();
                        return true;
                    }
                }
            }
            item.isShown = true;
            return true;
        }
        // lastX == -1 - first plate
        if (lastX == -1 || (item.RightBorder < lastX - 5)) {
            item.isShown = true;
            return true;
        }
        item.isShown = false;
        return false;
    }

    IsItemHovered (item: DayHighLowItem, x: number, y: number): boolean {
        if (x <= item.RightBorder &&
            x >= item.LeftBorder &&
            y >= item.GetYTop() &&
            y <= item.GetYBottom()) {
            switch (item.Type) {
            case HighLowType.High:
                this.isHighHovered = true;
                break;
            case HighLowType.Low:
                this.isLowHovered = true;
                break;
            case HighLowType.PrevClose:
                break;
            }
            return true;
        }
        return false;
    }

    GetDataWithTextFromCashItemSeries (cashItemSeries: any, price: number, priceStr: string): { price: number, priceStr: string } {
        switch (this.chart.cashItemSeriesSettings.DataType) {
        case TerceraChartCashItemSeriesDataType.Absolute:
        // price = Math.round(price, this.chart.Instrument().getPrecision());
            priceStr = this.chart.Instrument().formatPrice(price);
            break;
        /*
                case TerceraChartCashItemSeriesDataType.Relative:
                    price = cashItemSeries.settings.relativeDataConverter.Calculate(price);
                    priceStr = price.ToString("N2") + "%";
                    break;

                case TerceraChartCashItemSeriesDataType.Log:
                    price = cashItemSeries.settings.logDataConverter.Calculate(price);
                    priceStr = chart.Instrument.formatPrice(cashItemSeries.settings.logDataConverter.Revert(price));
                    break;
                */
        default:
            priceStr = '';
            break;
        }

        return { price, priceStr };
    }

    GetDataFromCashItemSeries (cashItemSeries: any, price: number): { price: number } {
        return this.GetDataWithTextFromCashItemSeries(cashItemSeries, price, '');
    }

    DrawRectangle (gr: any, item: DayHighLowItem, font: any): void {
        const transparent =
            this.IsItemHovered(item, this.mousePosition.X, this.mousePosition.Y)
                ? 255
                : 179;

        const highLowBrush = this.highLowBrush;
        const prevCloseBrush = this.prevCloseBrush;

        if (highLowBrush !== null) {
            highLowBrush.Color = Color.FromArgb(transparent, highLowBrush.Color);
        }

        if (prevCloseBrush !== null) {
            prevCloseBrush.Color = Color.FromArgb(transparent, prevCloseBrush.Color);
        }

        let selectedBrush;
        let selectedRectFilter;

        const x = item.LeftBorder + 1;
        const w = item.RightBorder - item.LeftBorder + 10;
        const h = item.Height + 2;
        let y = 0;

        switch (item.Type) {
        case HighLowType.High:
            selectedBrush = highLowBrush;
            selectedRectFilter = RectangleEdgeFilter.All;
            y = item.Y - item.Height - item.deltaY - 5;
            break;
        case HighLowType.Low:
            selectedBrush = highLowBrush;
            selectedRectFilter = RectangleEdgeFilter.All;
            y = item.Y + item.deltaY;
            break;
        case HighLowType.PrevClose:
            selectedBrush = prevCloseBrush;
            selectedRectFilter = RectangleEdgeFilter.All;
            y = item.Y - item.Height - item.deltaY - 5;
            break;
        }

        const rectToDraw = new Rectangle(x, y, w, h);

        gr.RoundRectangle(rectToDraw, 3, selectedBrush, Pens.Transparent, false, selectedRectFilter);
        gr.DrawString(item.Text, font, Brushes.White,
            rectToDraw.X + 2 + rectToDraw.Width / 2,
            rectToDraw.Y + 2 + rectToDraw.Height / 2,
            'center',
            'middle');
    }

    Draw (gr: any, window: any, windowsContainer: any, advParams: any): void {
        if (!this.VisibleGet() && !this.PrevCloseVisibleGet()) {
            return;
        }

        const p = advParams;
        if (!p) return;

        this.isHighHovered = false;
        this.isLowHovered = false;

        const chart = this.chart;

        const dataType = chart.model.timeFrameInfo.HistoryType;
        const cashItemSeries = chart.MainCashItemSeries();

        if (!p.Instrument || !cashItemSeries) return;

        const cashItem = cashItemSeries._cashItem;
        const dayHigh = p.Instrument.GetHigh(dataType);
        const dayLow = p.Instrument.GetLow(dataType);

        const clipRect = window.ClientRectangle;
        this.allHighLow = [];
        const allHighLow = this.allHighLow;

        const scX = window.XScale;
        const curX = clipRect.X + clipRect.Width - scX / 2;
        const screenData = cashItemSeries.ChartScreenData;
        const screenDataStorage = screenData.Storage;
        let leftBorder = 0;
        let barW = 1;
        const result = TerceraChartMainPriceRenderer.ProcessBarWidth(window);
        if (result) {
            leftBorder = result.leftBorder;
            barW = result.barW;
        }

        gr.save();
        gr.beginPath();
        gr.rect(clipRect.X, clipRect.Y, clipRect.Width, clipRect.Height);
        gr.clip();

        if (this.VisibleGet()) {
            // Draw the Low line
            if (this.isLineLowVisible && dayLow !== undefined && dayLow !== -1) {
                let lowLinePrice = dayLow;
                const result = this.GetDataFromCashItemSeries(cashItemSeries, lowLinePrice);
                if (result) lowLinePrice = result.price;

                const low = window.PointsConverter.GetScreenY(lowLinePrice);
                gr.DrawLine(this.highLowPen, clipRect.X + 1, low, clipRect.X + clipRect.Width, low);
            }
            // Draw the High line
            if (this.isLineHighVisible && dayHigh !== undefined && dayHigh !== -1) {
                let highLinePrice = dayHigh;
                const result = this.GetDataFromCashItemSeries(cashItemSeries, highLinePrice);
                if (result) highLinePrice = result.price;

                const high = window.PointsConverter.GetScreenY(highLinePrice);
                gr.DrawLine(this.highLowPen, clipRect.X + 1, high, clipRect.X + clipRect.Width, high);
            }

            let screenDataStorageItem = screenData.MaxDayHighIndex >= 0 ? screenDataStorage[screenData.MaxDayHighIndex] : null;

            if (screenDataStorageItem && MathUtils.equalToEpsilon(screenDataStorageItem.High, dayHigh)) {
                const price = screenDataStorageItem.High;
                let strHigh = chart.Instrument().formatPrice(price);
                strHigh = this.ShowHighLow_High + ': ' + strHigh;

                const x = window.PointsConverter.GetScreenXbyTime(screenDataStorageItem.Time) + barW / 2 + leftBorder;
                const y = window.PointsConverter.GetScreenY(price);
                const w = gr.GetTextWidth(strHigh, p.Font);

                const dayHLItem = new DayHighLowItem(HighLowType.High, x, x + w, strHigh, y);
                dayHLItem.Height = p.Font.Height;

                allHighLow.push(dayHLItem);
            }

            screenDataStorageItem = screenData.MinDayLowIndex >= 0 ? screenDataStorage[screenData.MinDayLowIndex] : null;

            if (screenDataStorageItem && MathUtils.equalToEpsilon(screenDataStorageItem.Low, dayLow)) {
                const price = screenDataStorageItem.Low;
                let strLow = chart.Instrument().formatPrice(price);
                strLow = this.ShowHighLow_Low + ': ' + strLow;

                const x = window.PointsConverter.GetScreenXbyTime(screenDataStorageItem.Time) + barW / 2 + leftBorder;
                const y = window.PointsConverter.GetScreenY(price);
                const w = gr.GetTextWidth(strLow, p.Font);

                const dayHLItem = new DayHighLowItem(HighLowType.Low, x, x + w, strLow, y);
                dayHLItem.Height = p.Font.Height;

                allHighLow.push(dayHLItem);
            }
        }

        // Draw Close
        let prevClose = p.Instrument.Close;
        if (this.PrevCloseVisibleGet() && prevClose) {
            let prevCloseStr = '';
            const result = this.GetDataWithTextFromCashItemSeries(cashItemSeries, prevClose, prevCloseStr);
            if (result) {
                prevClose = result.price;
                prevCloseStr = result.priceStr;
            }

            prevCloseStr = this.ShowHighLow_Close + ': ' + prevCloseStr;

            const y = window.PointsConverter.GetScreenY(prevClose);
            const width = gr.GetTextWidth(prevCloseStr, p.Font);

            const dayHLItem = new DayHighLowItem(HighLowType.PrevClose, clipRect.X, clipRect.X + width, prevCloseStr, y);
            dayHLItem.Height = p.Font.Height;

            allHighLow.push(dayHLItem);

            gr.DrawLine(this.prevClosePen, clipRect.X + 1, y, clipRect.X + clipRect.Width, y);
        }

        let lastHighX = -1; // Store the X-coordinates of the last High tooltip drawing
        let lastLowX = -1; // Store the X-coordinates of the last Low tooltip drawing

        // Draw
        const len = allHighLow.length;
        for (let i = 0; i < len; i++) {
            let canPlaced = false;
            switch (allHighLow[i].Type) {
            case HighLowType.High:
                canPlaced = this.IsCanPlaced(lastHighX, allHighLow[i]);
                break;
            case HighLowType.Low:
                canPlaced = this.IsCanPlaced(lastLowX, allHighLow[i]);
                break;
            case HighLowType.PrevClose:
                canPlaced = this.IsCanPlaced(lastHighX, allHighLow[i]) && this.IsCanPlaced(lastLowX, allHighLow[i]);
                break;
            }

            if (canPlaced) {
                this.DrawRectangle(gr, allHighLow[i], p.Font);
                if (allHighLow[i].Type === HighLowType.High) { lastHighX = allHighLow[i].LeftBorder; } else if (allHighLow[i].Type === HighLowType.Low) { lastLowX = allHighLow[i].LeftBorder; }
            }

            if (allHighLow[i].Type !== HighLowType.PrevClose) { gr.FillEllipse(this.highLowBrush, allHighLow[i].LeftBorder - 3, allHighLow[i].Y - 3, 6, 6); }
        }

        // Reset the clip.
        gr.restore();
    }

    // #region ICaller

    Properties () {
        const properties = super.Properties();

        const state = this.chart.TerceraChartActionProcessor.GetTerceraChartActionState(
            TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.DayHighLow)
        );

        if (state.Visible) {
            // Highlight Prices
            const SeparatorGroup = '#4#' + Resources.getResource('property.SeparatorGroup.HighlightPrices');
            const colorStyleWidth = new ColorStyleWidth(this.highLowPen?.Color, this.highLowStyle, this.highLowWidth); // #42690
            colorStyleWidth.CheckBoxVisible = true;
            colorStyleWidth.Checked = this.VisibleGet();

            const prop = new DynProperty('ShowHighLow', colorStyleWidth, DynProperty.COLOR_STYLE_WIDTH, DynProperty.PRICE_SCALE_GROUP);
            prop.enabled = state.Enabled;
            prop.separatorGroup = SeparatorGroup;
            prop.sortIndex = 60;
            properties.push(prop);
        }

        const statePrevClose = this.chart.TerceraChartActionProcessor.GetTerceraChartActionState(
            TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.PreviousCloseLine)
        );

        if (statePrevClose.Visible) {
            const SeparatorGroup = '#4#' + Resources.getResource('property.SeparatorGroup.HighlightPrices');
            const colorStyleWidth = new ColorStyleWidth(this.prevClosePen?.Color, this.prevCloseStyle, this.prevCloseWidth);
            colorStyleWidth.CheckBoxVisible = true;
            colorStyleWidth.Checked = statePrevClose.Active; // this.Visible;  #47366

            const prop = new DynProperty('ShowPrevCloseLine', colorStyleWidth, DynProperty.COLOR_STYLE_WIDTH, DynProperty.PRICE_SCALE_GROUP);
            prop.enabled = statePrevClose.Enabled;
            prop.separatorGroup = SeparatorGroup;
            prop.sortIndex = 50;
            properties.push(prop);
        }

        return properties;
    }

    callBack (properties: any) {
        let prop = DynProperty.getPropertyByName(properties, 'ShowHighLow');
        if (prop) {
            const csw = prop.value;
            this.highLowBrush = new SolidBrush(csw.Color);
            this.highLowStyle = csw.Style; // #42690
            this.highLowWidth = csw.Width;

            const pen = new Pen(csw.Color, this.highLowWidth);
            this.highLowPen = Pen.ProcessPen(pen, this.highLowStyle);

            this.Visible = csw.Checked;
        }

        prop = DynProperty.getPropertyByName(properties, 'ShowPrevCloseLine');
        if (prop) {
            const csw = prop.value;
            this.prevCloseBrush = new SolidBrush(csw.Color);
            this.prevCloseStyle = csw.Style; // #42690
            this.prevCloseWidth = csw.Width;

            const pen = new Pen(csw.Color, this.prevCloseWidth);
            this.prevClosePen = Pen.ProcessPen(pen, this.prevCloseStyle);

            this.prevCloseVisible = csw.Checked;
        }
    }
}

export enum HighLowType {
  None = 0,
  High = 1,
  Low = 2,
  PrevClose = 3
}

export class DayHighLowItem {
    IsHover: boolean = false;
    Height: number = 0;
    deltaY: number = 5;
    isShown: boolean = false;
    Type: HighLowType;
    LeftBorder: number;
    RightBorder: number;
    Text: string;
    Y: number;

    constructor (type: HighLowType, LeftBorder: number, RightBorder: number, Text: string, y: number) {
        this.Type = type;
        this.LeftBorder = LeftBorder;
        this.RightBorder = RightBorder;
        this.Text = Text;
        this.Y = y;
    }

    GetYTop (): number {
        let y = 0;
        switch (this.Type) {
        case HighLowType.High:
        case HighLowType.PrevClose:
            y = this.Y - this.deltaY - this.Height;
            break;
        case HighLowType.Low:
            y = this.Y + this.deltaY;
            break;
        }
        return y;
    }

    GetYBottom (): number {
        let y = 0;
        switch (this.Type) {
        case HighLowType.High:
        case HighLowType.PrevClose:
            y = this.Y - this.deltaY;
            break;
        case HighLowType.Low:
            y = this.Y + this.deltaY + this.Height;
            break;
        }
        return y;
    }
}
