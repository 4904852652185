// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PriceType } from "../../../../../Utils/History/CashItemUtils.ts";
import { iBuildInIndicator } from "../../iBuildInIndicator.ts";

export let iAD = function ()
{
    iBuildInIndicator.call(this, 1)
}
iAD.prototype = Object.create(iBuildInIndicator.prototype)

Object.defineProperty(iAD.prototype, 'Name',
    {
        get: function () { return 'iAD' }
    })

iAD.prototype.OnQuote = function (ci, callBound, callFromRefresh)
{
    let Parent = this.Parent
    if (!Parent)
        return

    let close = this.GetPrice(PriceType.Close, 0)
    let high = this.GetPrice(PriceType.High, 0)
    let low = this.GetPrice(PriceType.Low, 0)
    let volume = this.GetVolume(0)

    if (high !== low)
    {
        this.SetValue(0, 0, (2 * close - high - low) * volume / (high - low) + this._GetValue(0, 1))
    }
}