import { Control } from '../Control';
import { CanvasRactiveTemplate } from '../../../templates.js';
import { type ICanvasDrawer } from './ICanvasDrawer';

export abstract class CanvasRactive extends Control.extend(
    {
        template: CanvasRactiveTemplate,
        data: function () {
            return {
                id: 'canvas-id'
            };
        }
    }) {
    static staticId: number = 0;

    private _drawer: ICanvasDrawer;
    private _canvasContext: CanvasRenderingContext2D;
    private _observer: ResizeObserver;

    public getType (): string { return 'CanvasRactive'; }

    // #region Ractive lifecycle
    public oninit (): void {
        super.oninit();
        CanvasRactive.staticId++;
        super.set('id', 'canvas-id-' + CanvasRactive.staticId);
    }

    public oncomplete (): void {
        super.oncomplete();
        this.subscribe();
    }

    public onteardown (): void {
        this.unsubscribe();
        super.onteardown();
    }

    public onrender (): void {
        const canvas = this.getCanvas();
        const ctx = canvas.getContext('2d');
        if (ctx !== null) {
            this._canvasContext = ctx;
        }
        this.themeChanged();
    }
    // #endregion

    // #region Virtual functions
    protected setDrawer (drawer: ICanvasDrawer): void {
        this._drawer = drawer;
    }

    public getDrawer (): ICanvasDrawer {
        return this._drawer;
    }

    protected tickAsync (): void {
        if (!isNullOrUndefined(this._drawer) && !isNullOrUndefined(this._canvasContext)) {
            this._drawer.Draw(this._canvasContext);
        }
    }

    protected themeChanged (): void {
        if (!isNullOrUndefined(this._drawer)) {
            this._drawer.ThemeChanged();
        }
    }
    // #endregion

    // #region Eventhandlers
    private onResize (): void {
        const canvas = this.getCanvas();
        if (isNullOrUndefined(canvas)) {
            return;
        }

        const parentDiv = canvas.parentElement;
        if (isNullOrUndefined(parentDiv)) {
            return;
        }
        const devicePixelRatio = window.devicePixelRatio ? window.devicePixelRatio : 1;

        const width = parentDiv.clientWidth;
        const height = parentDiv.clientHeight;

        canvas.width = width * devicePixelRatio;
        canvas.height = height * devicePixelRatio;
        this._canvasContext.scale(devicePixelRatio, devicePixelRatio);
        this._drawer.onSizeChanged(width, height);
    }

    public onMouseDown (event): void {
        super.onMouseDown(event);
        if (!isNullOrUndefined(this._drawer)) {
            this._drawer.onMouseDown(event.original);
        }
    }

    public onMouseUp (event): void {
        super.onMouseUp(event);
        if (!isNullOrUndefined(this._drawer)) {
            this._drawer.onMouseUp(event.original);
        }
    }

    public onMouseMove (event): void {
        super.onMouseMove(event);
        if (!isNullOrUndefined(this._drawer)) {
            this._drawer.onMouseMove(event.original);
        }
    }

    public onMouseLeave (event): void {
        super.onMouseLeave(event);
        if (!isNullOrUndefined(this._drawer)) {
            this._drawer.onMouseLeave(event.original);
        }
    }

    public onClick (event): void {
        super.onClick(event);
        if (!isNullOrUndefined(this._drawer)) {
            this._drawer.onClick(event.original);
        }
    }
    // #endregion

    // #region Technical functions
    private subscribe (): void {
        Control.Ticker.Subscribe(this.tickAsync, this);
        this._observer = new ResizeObserver(() => { this.onResize(); });
        const canvas = this.getCanvas();
        if (!isNullOrUndefined(canvas)) {
            this._observer.observe(canvas);
        }
    }

    private unsubscribe (): void {
        Control.Ticker.UnSubscribe(this.tickAsync, this);
        const canvas = this.getCanvas();
        if (!isNullOrUndefined(canvas)) {
            this._observer.unobserve(canvas);
        }
    }

    private getCanvas (): HTMLCanvasElement {
        const elementId = super.get('id');
        try {
            const element = super.find('#' + elementId);
            return element as HTMLCanvasElement;
        } catch (e) {
            return null;
        }
    }
    // #endregion
}
