// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CreateAlertExpirationSelectorTemplate } from "../../../templates.js";
import { ContainerControl } from "../../elements/ContainerControl.js";
import { Resources } from "../../../Commons/properties/Resources.ts";

export let CreateAlertExpirationSelector = ContainerControl.extend({
    template: CreateAlertExpirationSelectorTemplate,
    data: function ()
    {
        return {
            label: '',
            btnTooltip: '',
            visibleInfoBtn: true,
            expirationTime: null,
            width: 177
        }
    }
})

CreateAlertExpirationSelector.prototype.getType = function () { return 'CreateAlertExpirationSelector' };

CreateAlertExpirationSelector.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.call(this)

    this.repopulate();
    Resources.onLocaleChange.Subscribe(this.localize, this);
    this.localize();
}

CreateAlertExpirationSelector.prototype.repopulate = function ()
{
    let DataTime = new Date();
    DataTime.setHours(23);
    DataTime.setMinutes(59);
    DataTime.setSeconds(0);
    DataTime.setDate(DataTime.getDate() + 30);

    this.set("expirationTime", DataTime);
}

CreateAlertExpirationSelector.prototype.dispose = function ()
{
    Resources.onLocaleChange.UnSubscribe(this.localize, this)

    ContainerControl.prototype.dispose.call(this)
}

CreateAlertExpirationSelector.prototype.localize = function ()
{
    this.set({
        label: Resources.getResource('screen.Alerts.Label.Expiration'),
        infoBtnTooltip: Resources.getResource('screen.Alerts.Label.Expiration.InfoTooltip')
    });
}