import { type StatisticType } from '../Enums/StatisticType';
import { type AccountOperationStatisticMessage } from './AccountOperationStatisticMessage';

export class StatisticsGroupMessage {
    // обозначает тип статистики, на данный момент поддерживается один тип - "DAILY" значение = 0
    public Type: StatisticType;

    // значение ProjectedBalance на начало периода (дня)
    public BeginProjectedBalance: number;

    // группа с итогами по акк. операциям
    public AccountOperationStatistic: AccountOperationStatisticMessage[];
}
