// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { HistoricalBrokerMessageInfo } from '../../../../Utils/HistoricalBrokerMessageInfo';
import { DirectBMHistoryResponseMessage } from '../../../../Utils/DirectMessages/DirectMessagesImport';
import { FieldsFactory } from '../Factory/FieldsFactory';
import { HistoricalBrokerMessageGroup } from '../Groups/HistoricalBrokerMessageGroup';
import { MessageProcessorBase } from './MessageProcessorBase';
import { BrokerMessageProcessor } from './BrokerMessageProcessor';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class BMHistoryResponseMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): DirectBMHistoryResponseMessage[] {
        const msg = new DirectBMHistoryResponseMessage();

        const len = fieldSet.Count();
        for (let i = 0; i < len; i++) {
            const fixField = fieldSet.fieldSet[i];
            if (fixField.FieldId === 0) {
                continue;
            }

            if (fixField.FieldId === FieldsFactory.FIELD_GROUP) {
                const group = fixField.Value;
                if (group.GroupId === FieldsFactory.HISTORICAL_BROKER_MESSAGE_GROUP) {
                    this.AddHistoricalBrokerMessage(msg, group);
                }
            }
        }

        return [msg];
    }

    public AddHistoricalBrokerMessage (bmHistoryRespMsg: DirectBMHistoryResponseMessage, historicalBMGroupFieldSet): void {
        if (!historicalBMGroupFieldSet) {
            return;
        }

        const histBM = new HistoricalBrokerMessageInfo();

        const historicalBMGroup = new HistoricalBrokerMessageGroup(historicalBMGroupFieldSet);
        if (historicalBMGroup) {
            histBM.ID = historicalBMGroup.getMessageID();
            histBM.TimeStamp = historicalBMGroup.getTimeStamp();
            histBM.ProductID = historicalBMGroup.getProductID();
            histBM.Subject = historicalBMGroup.getSubject();
            histBM.Message = historicalBMGroup.getMessage();
            histBM.IsUnread = historicalBMGroup.getIsUnread();
            histBM.ResponseCases = historicalBMGroup.getResponseCases();
            histBM.DisplayMethod = historicalBMGroup.getDisplayMethod() || 0;

            const customBtnsGroups = historicalBMGroup.getCustomButtonsGroups();
            for (let i = 0; i < customBtnsGroups.length; i++) {
                if (!histBM.CustomButtons) {
                    histBM.CustomButtons = [];
                }

                histBM.CustomButtons.push(BrokerMessageProcessor.CreateCustomButton(customBtnsGroups[i]));
            }
        }

        if (!bmHistoryRespMsg.HistoricalBMs) {
            bmHistoryRespMsg.HistoricalBMs = [];
        }

        bmHistoryRespMsg.HistoricalBMs.push(histBM);
    }
}
