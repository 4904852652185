// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Connection } from "../../Commons/Connection.ts";
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";
import { FullLogsItem } from "../cache/FullLogsItem.ts";
import { ContainerControl } from "../elements/ContainerControl.js";
import { TerceraQuickTree, TerceraQuickTreeEvents } from "../elements/QuickTree/TerceraQuickTree.ts";
import { TerceraButton } from "../elements/TerceraButton.ts";
import { TerceraComboBox } from "../elements/TerceraComboBox.ts";
import { TerceraTextBox } from "../elements/TerceraTextBox.js";
import { QuickTreeViewScreen } from "../screen/QuickTreeViewScreen.js";
import { PanelNames } from "../UtilsClasses/FactoryConstants.ts";
import { ApplicationPanelNew } from "./ApplicationPanelNew.js";
import { FullLogPanelTemplate } from "../../templates.js";
export let FullLogsPanel = ApplicationPanelNew.extend(
    {
        Name: 'FullLogsPanel',
        data: function ()
        {
            return {
                zIndex: 300,
                showHeader: true,
                showFooter: false,
                dockablePanel: false,
                resizable: true,
                width: 850,
                height: 300,
                canLinkByAccount: false,
            };
        },
        partials: {
            bodyPartial: FullLogPanelTemplate
        },
        headerLocaleKey: 'ribbon.tools.full_logs',
        loggingStarted: false,
        codesTB: null,
        codesCB: null,
        startLogginBtn: null,
        addBtn: null,
        Filter: null,
        forceCloseOnLogout: false
    });

FullLogsPanel.prototype.getType = function () { return PanelNames.FullLogsPanel };

FullLogsPanel.prototype.jbInit = function ()
{
    ApplicationPanelNew.prototype.jbInit.apply(this);

    this.startLogginBtn = this.Controls.startLoggin;

    this.codesCB = this.Controls.codesCBFullLog;

    this.addBtn = this.Controls.add;

    this.codesTB = this.Controls.codesTBFullLog;
}

FullLogsPanel.prototype.oncomplete = function ()
{
    ApplicationPanelNew.prototype.oncomplete.apply(this)

    this.center();

    this.FillCB();

    this.loggingStarted = false;

    this.Filter = new Object();

    this.localize();

    this.getQuickTable().AddToEnd = false;
    this.quickTableRactive.on(TerceraQuickTreeEvents.DoubleClick, this.ShowMoreInformation.bind(this));
}

FullLogsPanel.prototype.setEnable = function (enable)
{
    //this.startLogginBtn.set({ enabled: enable });
    this.codesCB.set({ enabled: enable });
    this.addBtn.set({ enabled: enable });
    this.codesTB.set({ enabled: enable });
}

FullLogsPanel.prototype.OnNewMessageHandler = function (info)
{
    if (this.Filter.FiltersCount !== 0)
        if (!this.Filter[info.MessageCode])
            return;
    info.dateTime = new Date();
    this.getQuickTable().AddItem(new FullLogsItem(info));
}

FullLogsPanel.prototype.StartStopLogging = function ()
{
    if (!this.loggingStarted)
    {
        let text = this.codesTB.get('text');
        let fArr = text.split(",");
        for (let i = 0; i < fArr.length; i++)
            if (fArr[i].trim() !== "")
                this.Filter[fArr[i].trim()] = true;

        this.Filter.FiltersCount = Object.keys(this.Filter).length;

        Connection.SubscribeOnFullLoging(this.OnNewMessageHandler.bind(this));
        this.startLogginBtn.set({ text: "Stop logging" });
        this.setEnable(false);
    }
    else
    {
        this.Filter = new Object();
        Connection.UnSubscribeOnFullLoging();
        this.startLogginBtn.set({ text: "Start logging" });
        this.setEnable(true);
    }
    this.loggingStarted = !this.loggingStarted;
}

FullLogsPanel.prototype.ShowMoreInformation = function ()
{
    let qt = this.getQuickTable();
    let selectedRows = qt.selectedRowIds;
    if (selectedRows.length === 0)
        return;
    let selectedRow = qt.rows[selectedRows[0]];
    if (!selectedRow)
        return;
    QuickTreeViewScreen.Show(selectedRow.item.info.DataForTreeView);
}

FullLogsPanel.prototype.AddMsgCodeForLogging = function ()
{
    let CBItem = this.codesCB.get('selectedItem');

    let value = CBItem.value;

    let text = this.codesTB.get('text');

    text += value + ", ";

    this.codesTB.set('text', text);
}

FullLogsPanel.prototype.ClearTable = function ()
{
    this.getQuickTable().ClearRows();
}

FullLogsPanel.prototype.FillCB = function ()
{
    let msgDict = Connection.GetMessagesList();

    let items = [];

    let objectKeys = Object.keys(msgDict);

    for (let i = 0; i < objectKeys.length; i++)
        items.push({ text: msgDict[objectKeys[i]] + "(" + objectKeys[i] + ")", value: objectKeys[i] });

    this.codesCB.set({ items: items });
}

FullLogsPanel.prototype.dispose = function ()
{
    Connection.UnSubscribeOnFullLoging();

    ContainerControl.prototype.dispose.apply(this);
    //ApplicationPanelNew.prototype.dispose.apply(this);

    MainWindowManager.FullLogPanel = null;
}

let ShowFullLogPanel = function ()
{
    if (MainWindowManager.FullLogPanel)
        return

    let newPanel = new FullLogsPanel();
    MainWindowManager.MainWindow.addControl(newPanel);
    MainWindowManager.FullLogPanel = newPanel;
}

export let logs = function ()
{
    if (!MainWindowManager.FullLogPanel)
        ShowFullLogPanel();
}