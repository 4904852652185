// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectReportTypeMessage } from '../../../../Utils/DirectMessages/DirectReportTypeMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class ReportTypeMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): DirectReportTypeMessage[] {
        const msg = new DirectReportTypeMessage();

        msg.name = fieldSet.GetValue(FieldsFactory.FIELD_NAME);
        msg.id = fieldSet.GetValue(FieldsFactory.FIELD_ID);
        msg.accessType = fieldSet.GetValue(FieldsFactory.FIELD_TYPE);

        const groups = fieldSet.GetGroups(FieldsFactory.REPORT_PARAM_GROUP);
        const len = groups.length;
        for (let i = 0; i < len; i++) {
            const group = groups[i];

            const parameter = {
                name: group.GetValue(FieldsFactory.FIELD_NAME),
                type: group.GetValue(FieldsFactory.FIELD_TYPE),
                params: group.GetValue(FieldsFactory.FIELD_PARAMS)
            };

            msg.parameters.push(parameter);
        }

        return [msg];
    }
}
