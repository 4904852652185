// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
/// <summary>
/// KeyValueAggregateGroup - аггрегирующий KeyValueGroup
/// используется для передачи в SOAP реквесте параметров большой длины
/// </summary>
import { PFixFieldSet } from '../Fields/FieldsImport';
import { FieldsFactory } from '../Factory/FieldsFactory';

export class KeyValueAggregateGroup extends PFixFieldSet {
    constructor (pfixFieldSet?: PFixFieldSet) {
        super(FieldsFactory.KEY_VALUE_AGGREGATE_GROUP_BEAN, null, pfixFieldSet);
    }

    public getKey (): string {
        return this.GetValue(FieldsFactory.FIELD_KEY);
    }

    public setKey (key: string): void {
        this.SetField(FieldsFactory.FIELD_KEY, key);
    }

    public getValue (): string {
        return this.GetValue(FieldsFactory.FIELD_VALUE);
    }

    public setValue (val: string): void {
        this.SetField(FieldsFactory.FIELD_VALUE, val);
    }
}
