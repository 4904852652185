// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type Account } from './Account';
import { DataCache } from '../DataCache';
import { LocalStorage } from '../LocalStorage';
import { EventEmitter } from 'events';
import { AccountSeal } from './AccountEnums';
import { InstrumentTypesColor, InstrumentTypesShortName } from '../../Utils/Instruments/InstrumentTypes';

class AccountUtilsClass {
    public readonly OnSavedAccountChanged: EventEmitter = new EventEmitter();
    public getAccountsList (): Account[] {
        const accountList = new Array<Account>();
        const accounts = DataCache.getAccounts();
        for (const accId in accounts) {
            accountList.push(accounts[accId]);
        }

        return accountList;
    }

    public getSortedAccountList (): Account[] {
        const accounts = this.getAccountsList();

        return accounts.sort((a, b) => {
            return String.CompareOrdinal(a.FullAccString, b.FullAccString);
        });
    }

    public getSavedAccount (): Account | undefined {
        const accName = LocalStorage.getSelectedWebMobileAccount();
        let acc: Account | undefined;
        if (accName) {
            acc = DataCache.GetAccountByIdOrName(accName);
        }

        if (!acc) {
            acc = DataCache.getUserByLogin(DataCache.UserLogin).Accounts[0];
        }
        return acc;
    }

    public setSavedAccount (account: Account): void {
        if (isNullOrUndefined(account)) { return; }
        LocalStorage.setSelectedWebMobileAccount(account.FullAccString);

        this.OnSavedAccountChanged.emit('OnSavedAccountChanged', account);
    }

    public getIsAccountLinkingActive (): boolean {
        return LocalStorage.getIsAccountLinkingActive();
    }

    public setIsAccountLinkingActive (isActive: boolean): void {
        LocalStorage.setIsAccountLinkingActive(isActive);
    }

    public GetAccountModeText (account: Account): string {
        switch (account.AccountMode) {
        case AccountSeal.Demo:
            return 'Demo';
        case AccountSeal.Live:
            return 'Live';
        case AccountSeal.Contest:
            return 'Contest';
        }

        return 'Demo';
    }

    public getInsTypeShortName (account: Account): string {
        return InstrumentTypesShortName[account.PreferredInstrumentType];
    }

    public getInstrumentColor (account: Account): string {
        return InstrumentTypesColor[account.PreferredInstrumentType];
    }

    public needAddType (account: Account): boolean {
        return account.PreferredInstrumentType !== -1;
    }
}

export const AccountUtils = new AccountUtilsClass();
