// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type CrossRateData } from '../CrossRate/CrossRateData';
import { type MarkupData } from '../CrossRate/MarkupData';
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectCrossratesPlanMessage extends DirectMessageBaseClass {
    public NonTradableMarkups: CrossRateData[] = [];
    public MarkupData: MarkupData[] = [];
    public Id = 0;
    public Name: string | null = null;
    public Description: string | null = null;
    public CounterAccountID = 0;
    public MarkupMeasure = 0;

    constructor () {
        super(Message.CODE_CROSSRATES_PLAN_MESSAGE);
    }
}
