// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomErrorClass, ErrorInformationStorage } from "../../Commons/ErrorInformationStorage.ts"
import { Resources } from "../../Commons/properties/Resources.ts"
import { HistoryType } from "../../Utils/History/HistoryType.ts"
import { SolidBrush } from "../../Commons/Graphics.ts"
import { LinkedSystem, LinkedSystemAccLinkingValue } from "../misc/LinkedSystem.ts"
import { ApplicationPanelNew } from "./ApplicationPanelNew.js";
import { MarketDepthOETemplate } from "../../templates.js"
import { Level2ItemQ, MDLotsMode } from "../cache/Level2ItemQ.ts"
import { QuickTableRactive } from "../elements/QuickTable/QuickTableRactive.ts"
import { TerceraLinkControlConstants } from "../UtilsClasses/TerceraLinkControlConstants.ts"
import { TerceraMenu } from "../elements/TerceraMenu.ts"
import { PanelNames } from "../UtilsClasses/FactoryConstants.ts"
import { OrderEditViewBase } from "./OrderEditViewBase.js"
import { ThemeManager } from "../misc/ThemeManager.ts"
import { OperationType } from "../../Utils/Trading/OperationType.ts"
import { Leve2QuoteType } from "../../Utils/Enums/Constants.ts"
import { PlacedFrom } from "../../Utils/Trading/PlacedFrom.ts"
import { Account } from "../../Commons/cache/Account.ts"
import { DynProperty } from "../../Commons/DynProperty.ts"
import { GeneralSettings } from "../../Utils/GeneralSettings/GeneralSettings.ts"
import { Instrument } from "../../Commons/cache/Instrument.ts"
import { IsAllowed } from "../../Commons/IsAllowed.ts"
import { Quantity } from "../../Utils/Trading/Quantity.ts"
import { OrderEditUpdateData } from "../../Utils/Trading/OrderEditUpdateData.ts"
import { DataCache } from "../../Commons/DataCache.ts"
import { SessionSettings } from "../../Commons/SessionSettings.ts"
import { TradingNumericErrorChecker } from "../../Commons/Trading/TradingNumericErrorChecker.ts"
import { PanelItemsFactory } from "./PanelItemsFactory.js"
import { Level1ItemType } from '../elements/TerceraLevel1Panel.ts'
import { AccountMenuItemsHelper } from "../../Commons/AccountWidget/AccountMenuItemsHelper.ts"

export let MarketDepthPanel = OrderEditViewBase.extend({
    data: function ()
    {
        return {
            width: 760,
            height: 400,
            posPanelTop: 100,

            isAccountLinkShow: true,
            isSymbolLinkShow: true,

            instrument: null,
            account: null,
            quantity: null, // QuantityObject
            tif: null, // TIF object
            productType: null,
            orderType: null,
            // TODO. UGLY.
            defaultQuantity: null,
            mirror: true,
            oeTop: 0,
            canFilterByAccount: false,

            reverseOrderButtons: false,

            _OperationType: OperationType,

            panelsVisibility: {
                lvl1: true,
                oe: true,
                oeCompact: true,
                pos: true,
                productType: false,
                leverage: false,
                dataSource: true, // true to enable data source
                dataSourceOpened: false
            },
            level1Items: [
                Level1ItemType.Last,
                Level1ItemType.ChangePercent,
                Level1ItemType.Volume,
                Level1ItemType.OpenInterest,
                Level1ItemType.Open,
                Level1ItemType.High,
                Level1ItemType.Low,
                Level1ItemType.CurrentSession,
                Level1ItemType.TradingStatus,
                Level1ItemType.TradedValue,
                Level1ItemType.OffExchangeVolume,
                Level1ItemType.OffExchangeValue,
                Level1ItemType.NormalMarketSize,
                Level1ItemType.LimitHigh,
                Level1ItemType.LimitLow,
                Level1ItemType.AuctionStart,
                Level1ItemType.AuctionEnd,
                Level1ItemType.RemainingQty,
                Level1ItemType.RemainingQtySide,
                Level1ItemType.TotalBuyQty,
                Level1ItemType.TotalSellQty,
                Level1ItemType.AvgTradedPrice,
                Level1ItemType.LastTradedTime,
                Level1ItemType.Countdown,
                Level1ItemType.Funding,
                Level1ItemType.FiftyTwoWeekHighPrice,
                Level1ItemType.FiftyTwoWeekLowPrice,
                Level1ItemType.NSEValue,
                Level1ItemType.DPR,
                Level1ItemType.LastUpdateTime,
                Level1ItemType.BidSource,
                Level1ItemType.AskSource,
                Level1ItemType.LastSource
            ]
        }
    },
    partials: {
        bodyPartial: MarketDepthOETemplate
    },

    Name: 'MarketDepthPanel',

    quickTableRactiveBID: null,
    quickTableRactiveASK: null,

    NeedCalculateRowCount: false,

    headerLocaleKey: 'panel.level2',

    FLayerColors: [],
    FLayerColorsForeground: [],

    MostSaturatedAskForeColor: '',
    MostSaturatedAskBackColor: '',
    MostSaturatedBidForeColor: '',
    MostSaturatedBidBackColor: '',

    ColoringMethod: 0
    // BidVisibleRowsCount: 0
    // AskVisibleRowsCount: 0
})

MarketDepthPanel.MarketDepthStackSizeLimit = -1;

MarketDepthPanel.prototype.getType = function ()
{
    return PanelNames.MarketDepthPanel
}

MarketDepthPanel.prototype.updatePanelHeader = function ()
{
    let ins = this.get('instrument')
    this.set({
        header:
            Resources.getResource(this.headerLocaleKey) +
            (ins && !ins.IsEmpty ? (' ' + ins.DisplayName()) : '')
    })
}

MarketDepthPanel.prototype.layoutTable = function ()
{
    let tableBID = this.quickTableRactiveBID;
    let tableASK = this.quickTableRactiveASK;

    if (tableBID)
        this.layoutTableResize(tableBID);

    if (tableASK)
        this.layoutTableResize(tableASK);
}


MarketDepthPanel.prototype.SetBackgroundColorForQuickTables = function ()
{
    this.quickTableRactiveBID.quickTable.BackColor = this.TableMDBackgroundColor;
    this.quickTableRactiveASK.quickTable.BackColor = this.TableMDBackgroundColor;
    this.quickTableRactiveBID.quickTable.gridColor = this.TableMDBackgroundColor;
    this.quickTableRactiveASK.quickTable.gridColor = this.TableMDBackgroundColor;
}

MarketDepthPanel.prototype.oninit = function ()
{
    OrderEditViewBase.prototype.oninit.call(this)

    this.FLayerColors = new Array(5)
    this.FLayerColorsForeground = new Array(5)

    this.MostSaturatedAskForeColor = 'rgb(0,0,0)'
    this.MostSaturatedAskBackColor = 'rgb(0,0,0)'
    this.MostSaturatedBidForeColor = 'rgb(0,0,0)'
    this.MostSaturatedBidBackColor = 'rgb(0,0,0)'
    this.ColoringMethod = MarketDepthPanel.ColoringMethod.SizeHistogram

    this.observe('panelsVisibility.*', this.layoutTable)
    this.observe('instrument', this.onInstrumentChanged)
    this.observe('account', this.onAccountChanged)

    this.observe(
        'orderType',
        this.onOrderTypeChanged)

    this.observe(
        'instrument account quantity side tif productType',
        this.onTradingDataChanged,
        { init: false })

    this.on('placeOrder', this.placeOrder)

    this.updateSettings()

    this.observe('mirror', this.onMirrorChanged)

    DataCache.OnUpdateAccount.Subscribe(this.onUpdateAccountData, this)
    DataCache.OnUpdateInstrument.Subscribe(this.onUpdateInstrument, this);
}

MarketDepthPanel.prototype.oncomplete = function ()
{
    OrderEditViewBase.prototype.oncomplete.call(this)
    this.themeChange()
    this.localize()

    this.onInstrumentChanged(this.getInstrument(), null)
}

MarketDepthPanel.prototype.dispose = function ()
{
    this.Unsubscribe(this.getInstrument())

    DataCache.OnUpdateAccount.UnSubscribe(this.onUpdateAccountData, this)
    DataCache.OnUpdateInstrument.UnSubscribe(this.onUpdateInstrument, this);

    OrderEditViewBase.prototype.dispose.call(this)
}

MarketDepthPanel.prototype.jbInit = function ()
{
    var me = this

    me.askStartIndex = MarketDepthPanel.MarketDepthStackSizeLimit * 2

    var panelInfo = PanelItemsFactory.GetPanelItem(me.getType());
    if (panelInfo)
    {
        var items = new panelInfo();
        me.quickTableRactiveBID = this.createTable(this, items)
        me.quickTableRactiveASK = this.createTable(this, items)
    }

    let qtBid = me.quickTableRactiveBID.quickTable
    if (me.deferredBIDXmlTemplate)
    {
        qtBid.xmlSettingsTemplate = me.deferredBIDXmlTemplate
        delete me.deferredBIDXmlTemplate
    }

    let qtAsk = me.quickTableRactiveASK.quickTable
    if (me.deferredASKXmlTemplate)
    {
        qtAsk.xmlSettingsTemplate = me.deferredASKXmlTemplate
        delete me.deferredASKXmlTemplate
    }

    this.SetColumnsDefaultDisplayIndex(me.quickTableRactiveASK.quickTable);
    this.SetColumnsDefaultDisplayIndex(me.quickTableRactiveBID.quickTable);

    qtBid.UpdateSortedColumns();
    qtAsk.UpdateSortedColumns();
    this.onMirrorChanged();

    qtBid.OnVisibleColumnChanged.Subscribe(this.visibleColumnChanged, this)
    qtAsk.OnVisibleColumnChanged.Subscribe(this.visibleColumnChanged, this)

    qtBid.OnVisibleColumnChanged.Subscribe(
        this.syncColumnsVisibility.bind(this, qtBid, qtAsk), this)

    qtAsk.OnVisibleColumnChanged.Subscribe(
        this.syncColumnsVisibility.bind(this, qtAsk, qtBid), this)

    qtBid.OnColumnResize.Subscribe(
        this.syncColumnsWidth.bind(this, qtBid, qtAsk), this)

    qtAsk.OnColumnResize.Subscribe(
        this.syncColumnsWidth.bind(this, qtAsk, qtBid), this)

    qtBid.ColumnDisplayIndexChanged.Subscribe(
        this.syncColumnsIndex.bind(this, qtBid, qtAsk), this)

    qtAsk.ColumnDisplayIndexChanged.Subscribe(
        this.syncColumnsIndex.bind(this, qtAsk, qtBid), this)

    qtBid.ClickableCellsIndexesAndEvents['1'] =
        qtAsk.ClickableCellsIndexesAndEvents['1'] =
        this.setPriceFromTable.bind(this)

    qtBid.ClickableCellsIndexesAndEvents['2'] =
        qtAsk.ClickableCellsIndexesAndEvents['2'] =
        this.setAmountAndPriceFromTable.bind(this)

    me.OnResize.Subscribe(function () { me.layoutTable(me) }, me)

    qtBid.SetScrollHidden(true);

    qtAsk.scroll.OnValueChange.Subscribe(this.SynchronizeScroll, this);
    qtBid.scroll.OnValueChange.Subscribe(this.SynchronizeScroll, this);

    me.quickTableRactiveASK.quickTable.isRowSeparated = true;
    me.quickTableRactiveBID.quickTable.isRowSeparated = true;
    me.quickTableRactiveASK.quickTable.borderWidth = 0;
    me.quickTableRactiveBID.quickTable.borderWidth = 0;

    me.localize()
    me.layoutTable()

    this.visibleColumnChanged(qtBid.getVisibleColumn())
}

MarketDepthPanel.prototype.SynchronizeScroll = function (index)
{
    // this.BidVisibleRowsCount;
    // this.AskVisibleRowsCount;

    let max = Math.max(this.quickTableRactiveBID.quickTable.scroll.scrollElementsCount, this.quickTableRactiveASK.quickTable.scroll.scrollElementsCount)
    this.quickTableRactiveBID.quickTable.scroll.scrollElementsCount = this.quickTableRactiveASK.quickTable.scroll.scrollElementsCount = max;

    this.quickTableRactiveBID.quickTable.scroll.moveScrollToElement(index);
    this.quickTableRactiveASK.quickTable.scroll.moveScrollToElement(index);
}

MarketDepthPanel.prototype.onOrderTypeChanged = function (newOrderType, oldOrderType)
{
    if (!newOrderType || newOrderType === oldOrderType)
        return

    let orderEdit = newOrderType.createOrderEditObject({
        dataCache: DataCache,
        // TODO. Ugly.
        // http://tp.pfsoft.net/entity/76728
        // Remove https://tp.traderevolution.com/entity/82704
        // fullRangeForLimitPrice: true
    })

    this.setOrderEdit(orderEdit)
    this.setDPTradingNumericText(newOrderType)

    orderEdit.updateParameters(new OrderEditUpdateData(
        null,
        this.getAllTradingDataDict()))
}

// TODO. Rename; Parameters' constants.
MarketDepthPanel.prototype.onTradingDataChanged = function (newVal, oldVal, key)
{
    let orderEdit = this.orderEdit
    if (!orderEdit) return

    let tradingDataDict = {}
    tradingDataDict[key] = newVal

    tradingDataDict.completed = this.completed

    orderEdit.updateParameters(new OrderEditUpdateData(
        null,
        tradingDataDict))

    if (key == 'instrument')
        this.symbolLink_Out(false, newVal);     // #115325 <- can't call it in onInstrumentChanged because in that case when linking is on there left fakeNonFixedInstrument in this.orderEdit.instrument
}

// TODO. Rename; Parameters' constants.
MarketDepthPanel.prototype.getAllTradingDataDict = function ()
{
    let tradingDataDict = {}

    let instrument = this.get('instrument')
    if (instrument) tradingDataDict.instrument = instrument

    let account = this.get('account')
    if (account) tradingDataDict.account = account

    let quantity = this.get('quantity')
    if (quantity) tradingDataDict.quantity = quantity

    let tif = this.get('tif')
    if (tif) tradingDataDict.tif = tif

    let productType = this.get('productType')
    if (productType) tradingDataDict.productType = productType

    tradingDataDict.completed = this.completed

    // TODO. UGLY. Default side.
    tradingDataDict.side = OperationType.Buy

    return tradingDataDict
}

MarketDepthPanel.prototype.placeOrder = function (sender)
{
    if (TradingNumericErrorChecker.HasErrors(this))
        return

    let side = sender.get('side')
    let orderEdit = this.orderEdit
    if (!orderEdit) return

    orderEdit.updateParameters(new OrderEditUpdateData(
        null,
        { side: side, placedFrom: PlacedFrom.WEB_MARKETDEPTH, leverageValue: this.get('leverageValue') }
    ))

    DataCache.FOrderExecutor
        .placeOrderPromise(orderEdit, null, null, this.focusWarningNumeric.bind(this))
        .catch(function ()
        {
            let ex = new CustomErrorClass("MarketDepthPanel error", "MarketDepthPanel.placeOrder.", "placeOrder -> placeOrderPromise");
            ErrorInformationStorage.GetException(ex);
        })
        .finally(function ()
        {
            // TODO. Ugly. Back to default side.
            orderEdit.updateParameters(new OrderEditUpdateData(
                null,
                { side: OperationType.Buy }))
        })
}

MarketDepthPanel.prototype.localize = function ()
{
    ApplicationPanelNew.prototype.localize.call(this);

    if (this.quickTableRactiveBID && this.quickTableRactiveBID.quickTable)
        this.quickTableRactiveBID.quickTable.localize();

    if (this.quickTableRactiveASK && this.quickTableRactiveASK.quickTable)
        this.quickTableRactiveASK.quickTable.localize();


    this.repopulatePanelContextMenu();
};

MarketDepthPanel.prototype.repopulatePanelContextMenu = function ()
{
    this.menuItems = this.createPanelMenuItems();
    this.menuTagDict = TerceraMenu.createTagDictionary(this.menuItems);

    if (this.quickTableRactiveBID && this.quickTableRactiveBID.quickTable &&
        this.quickTableRactiveASK && this.quickTableRactiveASK.quickTable)
    {
        this.quickTableRactiveBID.quickTable.setTableContextMenuItems(this.menuItems);
        this.quickTableRactiveASK.quickTable.setTableContextMenuItems(this.menuItems);
    }
};


MarketDepthPanel.prototype.createTable = function (me, items)
{
    let myQuickTableRactive = new QuickTableRactive()
    me.addControl(myQuickTableRactive, true)

    let qt = myQuickTableRactive.quickTable
    qt.allowGroupBy = false
    qt.lockManualSorting = true
    qt.InitializeDirect(items)
    qt.UpdateSortedColumns()

    return myQuickTableRactive
}

MarketDepthPanel.prototype.coloringRowsMethod = function (coloringMethod, askRows, bidRows)
{
    if (MarketDepthPanel.ColoringMethod.ByPriceLVL === coloringMethod)
    {
        this.coloringRowsMethodByPriceLVL(askRows)
        this.coloringRowsMethodByPriceLVL(bidRows)
    }

    if (MarketDepthPanel.ColoringMethod.RelativeToVolume === coloringMethod)
    {
        this.coloringRowsMethodRelativeToVolume(askRows, false)
        this.coloringRowsMethodRelativeToVolume(bidRows, true)
    }

    if (MarketDepthPanel.ColoringMethod.SizeHistogram === coloringMethod)
    {
        this.coloringRowsMethodSizeHistogram(askRows, false)
        this.coloringRowsMethodSizeHistogram(bidRows, true)
    }
}

MarketDepthPanel.prototype.coloringRowsMethodByPriceLVL = function (rows)
{
    let len = rows.length
    let depth = 0
    for (let i = 0; i < len; i++)
    {
        let row = rows[i];
        if (!row.visible)
            break;
        row.BackColor = this.FLayerColors[depth]
        row.ForeColor = this.FLayerColorsForeground[depth]
        row.cells[2].ForeColor = null;
        depth++

        if (depth > 4)
            depth = 0
    }
}

MarketDepthPanel.prototype.coloringRowsMethodRelativeToVolume = function (rows, isBidTable)
{
    let maxVolume = 0
    let minVolume = rows.length != 0 ? rows[0].item.quoteSize : 0
    let len = rows.length
    for (let i = 0; i < len; i++)
    {
        let row = rows[i]
        if (!row.visible)
            break;
        maxVolume = row.item.quoteSize > maxVolume
            ? row.item.quoteSize
            : maxVolume

        minVolume = row.item.quoteSize < minVolume
            ? row.item.quoteSize
            : minVolume
    }

    let totalVolume = maxVolume - minVolume
    for (let i = 0; i < len; i++)
    {
        let row = rows[i];
        if (!row.visible)
            break;
        row.BackColor = MarketDepthPanel.RelativeToVolumeBackColoring(
            this.MostSaturatedBidBackColor,
            this.MostSaturatedAskBackColor,
            isBidTable,
            minVolume,
            totalVolume,
            row.item.quoteSize)

        row.ForeColor = isBidTable
            ? this.MostSaturatedBidForeColor
            : this.MostSaturatedAskForeColor
        row.cells[2].ForeColor = null;
    }
}

MarketDepthPanel.prototype.coloringRowsMethodSizeHistogram = function (rows, isBidTable)
{
    let maxVolume = 0
    let minVolume = rows.length != 0 ? rows[0].item.quoteSize : 0
    let len = rows.length
    for (let i = 0; i < len; i++)
    {
        let row = rows[i];
        if (!row.visible)
            break;
        maxVolume = row.item.quoteSize > maxVolume
            ? row.item.quoteSize
            : maxVolume
    }

    for (let i = 0; i < len; i++)
    {
        let row = rows[i];
        if (!row.visible)
            break;
        row.BackColor = isBidTable ? this.TableBIDSizeHistogram : this.TableASKSizeHistogram

        let size = row.item.quoteSize
        let coefVolume = size / maxVolume;
        row.cells[2].CustomDrawingHandlerParameters = { koef: coefVolume, isBidTable: isBidTable };

        row.ForeColor = this.TableAllHistogramTextColor;
        row.cells[2].ForeColor = this.TableMDSizeHistogramTextColor;
    }
}
MarketDepthPanel.prototype.CustomDrawingHandler = function (context, cell, cellX, cellY, cellW, cellH)
{
    if (this.ColoringMethod !== MarketDepthPanel.ColoringMethod.SizeHistogram)
        return false;
    if (!cell.CustomDrawingHandlerParameters)
        return false;

    if (cell.CustomDrawingHandlerParameters.isBidTable)
        context.FillRect(this.TableMDSizeHistogramBrush, cellX + cellW - cell.CustomDrawingHandlerParameters.koef * cellW, cellY, cellW, cellH);
    else
        context.FillRect(this.TableMDSizeHistogramBrush, cellX, cellY, cell.CustomDrawingHandlerParameters.koef * cellW, cellH);

    return true;
}

MarketDepthPanel.RelativeToVolumeBackColoring = function (
    MostSaturatedBidBackColor,
    MostSaturatedAskBackColor,
    isBidTable,
    minVolume,
    deltaVolume,
    size)
{
    let mostSaturatedColor =
        isBidTable
            ? MostSaturatedBidBackColor
            : MostSaturatedAskBackColor

    // соотношение объема котировки к минимальному объему
    let coefVolume = size - minVolume

    let opacity = 1 * (coefVolume / deltaVolume)
    opacity = opacity >= 0 ? (opacity <= 1 ? opacity : 1) : 1

    // если StepToMaxVolume меньше, чем самое маленькое значение в таблице
    opacity = deltaVolume < 0 ? 1 : opacity

    let color = mostSaturatedColor.replace('rgb', 'rgba').replace(')', ',' + opacity + ')')

    return color
}

MarketDepthPanel.prototype.TickAsync = function ()
{
    OrderEditViewBase.prototype.TickAsync.call(this)

    if (this.quickTableRactiveBID && this.quickTableRactiveBID.quickTable.needRedraw)
    {
        this.quickTableRactiveBID.quickTable.Draw()
        this.quickTableRactiveBID.quickTable.needRedraw = false
    }

    if (this.quickTableRactiveASK && this.quickTableRactiveASK.quickTable.needRedraw)
    {
        this.quickTableRactiveASK.quickTable.Draw()
        this.quickTableRactiveASK.quickTable.needRedraw = false
    }
}

MarketDepthPanel.prototype.onInstrumentChanged = function (instrument, lastInstrument)
{
    if (!instrument || instrument === lastInstrument || !this.completed)
        return

    if (Instrument.IsEqualInstrument(instrument, lastInstrument))
        return

    this.Unsubscribe(lastInstrument);
    this.UpdateTable();
    this.Subscribe();
    this.updateTradingAllowed();
    this.updateLeverageVisibility();
    // this.symbolLink_Out(false, instrument);  // #115325 <- need to call it in onTradingDataChanged otherwise when linking is on there left fakeNonFixedInstrument in this.orderEdit.instrument
    this.updatePanelHeader()
}

MarketDepthPanel.prototype.updateTradingAllowed = function ()
{
    const account = this.get('account');
    const instrument = this.get('instrument');
    const ordertype = this.get("orderType");
    if (instrument && account && ordertype)
    {
        const isTradingAllowed = IsAllowed.IsTradingAllowed([account], instrument, ordertype.id());
        this.set('tradingAllowed', isTradingAllowed.Allowed);
        this.set('tradingForbiddenReason', isTradingAllowed.ReasonText);
    }
}

MarketDepthPanel.prototype.updateLeverageVisibility = function ()
{
    let instrument = this.get('instrument'),
        account = this.get('account'),
        productType = this.get('productType'),
        visible = false

    if (instrument)
        visible = instrument.isLeverageVisible(account, productType)

    this.set('panelsVisibility.leverage', visible)
}

MarketDepthPanel.prototype.onUpdateAccountData = function ()
{
    this.updateSettings(false);
}

MarketDepthPanel.prototype.onUpdateInstrument = function (instrument)
{
    let myInstrument = this.get('instrument');
    if (!isNullOrUndefined(myInstrument) && myInstrument === instrument)
    {
        this.updateTradingAllowed();
    }
}

MarketDepthPanel.prototype.onAccountChanged = function (account, lastAccount)
{
    if (!account)
        return

    this.accountLink_Out(false, account);
    this.updateTradingAllowed();
    this.updateLeverageVisibility();
}

MarketDepthPanel.prototype.themeChange = function ()
{
    OrderEditViewBase.prototype.themeChange.call(this)

    if (this.quickTableRactiveBID)
        this.quickTableRactiveBID.themeChange()

    if (this.quickTableRactiveASK)
        this.quickTableRactiveASK.themeChange()

    let theme = ThemeManager.CurrentTheme

    this.FLayerColors[0] = theme.MarketDepth_Layer0_BackColor
    this.FLayerColors[1] = theme.MarketDepth_Layer1_BackColor
    this.FLayerColors[2] = theme.MarketDepth_Layer2_BackColor
    this.FLayerColors[3] = theme.MarketDepth_Layer3_BackColor
    this.FLayerColors[4] = theme.MarketDepth_Layer4_BackColor

    this.FLayerColorsForeground[0] = theme.MarketDepth_Layer0_ForeColor
    this.FLayerColorsForeground[1] = theme.MarketDepth_Layer1_ForeColor
    this.FLayerColorsForeground[2] = theme.MarketDepth_Layer2_ForeColor
    this.FLayerColorsForeground[3] = theme.MarketDepth_Layer3_ForeColor
    this.FLayerColorsForeground[4] = theme.MarketDepth_Layer4_ForeColor

    this.MostSaturatedAskForeColor = theme.MostSaturatedAskForeColor
    this.MostSaturatedAskBackColor = theme.MostSaturatedAskBackColor
    this.MostSaturatedBidForeColor = theme.MostSaturatedBidForeColor
    this.MostSaturatedBidBackColor = theme.MostSaturatedBidBackColor

    this.TableMDSizeHistogram = theme.TableMDSizeHistogram;
    this.TableBIDSizeHistogram = theme.TableBIDSizeHistogram;
    this.TableASKSizeHistogram = theme.TableASKSizeHistogram;
    this.TableMDSizeHistogramBrush = new SolidBrush(this.TableMDSizeHistogram);
    this.TableAllHistogramTextColor = theme.TableAllHistogramTextColor;
    this.TableMDSizeHistogramTextColor = theme.TableMDSizeHistogramTextColor;
    this.TableMDBackgroundColor = theme.TableMDBackgroundColor;

    this.SetBackgroundColorForQuickTables();
}

//#region Link

MarketDepthPanel.prototype.symbolLink_Out = function (newSubscriber, instrument)
{
    if (!instrument)
    {
        let ins = this.get('instrument')
        if (!ins) return
        instrument = ins
    }

    let color = this.get('symbolLinkValue')
    if (color !== TerceraLinkControlConstants.STATE_NONE)
        LinkedSystem.setSymbol(color, instrument.GetInteriorID(), newSubscriber)
}

MarketDepthPanel.prototype.symbolLink_In = function (symbolName)
{
    let newInstr = DataCache.getInstrumentByName(symbolName)
    if (newInstr)
        this.set('instrument', newInstr)
}

//#endregion Link

MarketDepthPanel.prototype.createPanelMenuItems = function ()
{
    let onPanelMenuItemClicked = this.onPanelMenuItemClicked.bind(this);

    let menuItems = [
        {
            text: Resources.getResource('panel.Level2.menu.View'),
            enabled: true,
            subitems: [
                {
                    text: Resources.getResource('panel.Level2.menu.View.Order Entry'),
                    tag: 'oe',
                    enabled: true,
                    checked: this.get('panelsVisibility.oe'),
                    canCheck: true,
                    event: onPanelMenuItemClicked
                },
                // {
                //     text: Resources.getResource('md.context.oeExtended'),
                //     tag: 'oeCompact',
                //     enabled: this.get('panelsVisibility.oe'),
                //     checked: this.get('panelsVisibility.oeCompact'),
                //     canCheck: true,
                //     event: onPanelMenuItemClicked
                // },
                {
                    text: Resources.getResource('panel.Level2.menu.View.Level1'),
                    tag: 'lvl1',
                    enabled: true,
                    checked: this.get('panelsVisibility.lvl1'),
                    canCheck: true,
                    event: onPanelMenuItemClicked
                },
                {
                    text: Resources.getResource('panel.Level2.menu.View.InstrInfo'),
                    tag: 'pos',
                    enabled: true,
                    checked: this.get('panelsVisibility.pos'),
                    canCheck: true,
                    event: onPanelMenuItemClicked
                },
                {
                    text: Resources.getResource('property.MirrorShow'),
                    tag: 'mirror',
                    enabled: true,
                    checked: this.get('mirror'),
                    canCheck: true,
                    event: onPanelMenuItemClicked
                }
            ]
        },
        {
            text: Resources.getResource('property.MarketDepth.ColoringMethod'),
            enabled: true,
            subitems: [
                {
                    text: Resources.getResource('MarketDepthPanel.ColoringMethod.RelativeToVolume'),
                    tag: 'coloringVolume',
                    enabled: true,
                    checked: this.ColoringMethod === MarketDepthPanel.ColoringMethod.RelativeToVolume,
                    canCheck: true,
                    event: onPanelMenuItemClicked
                },
                {
                    text: Resources.getResource('MarketDepthPanel.ColoringMethod.ByPriceLVL'),
                    tag: 'coloringPrice',
                    enabled: true,
                    checked: this.ColoringMethod === MarketDepthPanel.ColoringMethod.ByPriceLVL,
                    canCheck: true,
                    event: onPanelMenuItemClicked
                },
                {
                    text: Resources.getResource('MarketDepthPanel.ColoringMethod.SizeHistogram'),
                    tag: 'sizeHistogram',
                    enabled: true,
                    checked: this.ColoringMethod === MarketDepthPanel.ColoringMethod.SizeHistogram,
                    canCheck: true,
                    event: onPanelMenuItemClicked
                }
            ]
        }
    ]

    return menuItems
}

MarketDepthPanel.prototype.onPanelMenuItemClicked = function (menuItem)
{
    let tag = menuItem.tag
    let checked = menuItem.checked

    switch (tag)
    {
        case 'mirror':
            this.set('mirror', checked)
            break
        case 'lvl1':
        case 'oe':
        case 'oeCompact':
        case 'pos':
            this.set('panelsVisibility.' + tag, checked)
            break
        case 'coloringVolume':
            this.menuTagDict['coloringPrice'].checked = false
            this.menuTagDict['sizeHistogram'].checked = false
            this.ColoringMethod = MarketDepthPanel.ColoringMethod.RelativeToVolume
            break
        case 'coloringPrice':
            this.menuTagDict['coloringVolume'].checked = false
            this.menuTagDict['sizeHistogram'].checked = false
            this.ColoringMethod = MarketDepthPanel.ColoringMethod.ByPriceLVL
            break
        case 'sizeHistogram':
            this.menuTagDict['coloringPrice'].checked = false
            this.menuTagDict['coloringVolume'].checked = false
            this.ColoringMethod = MarketDepthPanel.ColoringMethod.SizeHistogram
            break;
    }
    // if (tag === 'oe')
    //     this.menuTagDict['oeCompact'].enabled = checked

    this.UpdateTable();
}

MarketDepthPanel.prototype.setPriceFromTable = function (table, cell, rowIndex)
{
    let item = table.rows[rowIndex].item
    let price = item.getColumnValue(1)
    let orderEdit = this.orderEdit
    if (orderEdit)
        orderEdit.setBasePrice(price)


}

MarketDepthPanel.prototype.setAmountAndPriceFromTable = function (table, cell, rowIndex)
{
    let item = table.rows[rowIndex].item
    let price = item.getColumnValue(1)
    let qty = new Quantity(
        item.getColumnValue(2),
        GeneralSettings.View.displayAmountInLots())

    let orderEdit = this.orderEdit
    if (orderEdit)
        orderEdit.setBasePrice(price)

    // TODO. Quantity. UGLY.
    //this.set('defaultQuantity', qty)      // закомментировал в связи с 94331
}

//#region Table synchronization

MarketDepthPanel.prototype.onMirrorChanged = function (mirror)
{
    if (!this.quickTableRactiveBID || !this.quickTableRactiveASK)
        return

    this.syncColumnsIndex(
        this.quickTableRactiveBID.quickTable,
        this.quickTableRactiveASK.quickTable)
};

MarketDepthPanel.prototype.syncColumnsVisibility = function (srcTable, outTable)
{
    let changeOccured = false
    let outColumns = outTable.columns
    let srcColumns = srcTable.columns
    let len = srcColumns.length
    for (let i = 0; i < len; i++)
    {
        let srcColumn = srcColumns[i]
        let outColumn = outColumns[i]
        if (srcColumn.visible !== outColumn.visible)
        {
            outColumn.visible = srcColumn.visible
            changeOccured = true
        }
    }

    if (changeOccured)
    {
        outTable.UpdateSortedColumns()
        outTable.needRedrawBackground = true
        outTable.needRedraw = true
        outTable.populateHeaderContextMenu(outColumns)
        outTable.OnVisibleColumnChanged.Raise(outTable.getVisibleColumn())
    }
}

MarketDepthPanel.prototype.SetColumnsDefaultDisplayIndex = function (table)
{
    table.columns[0].displayedIndex = 9;
    table.columns[1].displayedIndex = 8;
    table.columns[2].displayedIndex = 7;
    table.columns[3].displayedIndex = 6;
    table.columns[4].displayedIndex = 5;
    table.columns[5].displayedIndex = 4;
    table.columns[7].displayedIndex = 3;
    table.columns[9].displayedIndex = 2;
    table.columns[10].displayedIndex = 1;
}

MarketDepthPanel.prototype.syncColumnsWidth = function (srcTable, outTable)
{
    let changeOccured = false
    let outColumns = outTable.columns
    let srcColumns = srcTable.columns
    let len = srcColumns.length
    for (let i = 0; i < len; i++)
    {
        let srcColumn = srcColumns[i]
        let outColumn = outColumns[i]
        if (srcColumn.width !== outColumn.width)
        {
            outColumn.width = srcColumn.width
            changeOccured = true
        }
    }

    if (changeOccured)
    {
        outTable.needRedrawBackground = true
        outTable.needRedraw = true
        outTable.OnColumnResize.Raise()
    }
}

MarketDepthPanel.prototype.syncColumnsIndex = function (srcTable, outTable)
{
    if (!srcTable || !outTable)
        return

    let changeOccured = false
    let mirror = this.get('mirror')
    let outColumns = outTable.columns
    let srcColumns = srcTable.columns
    let len = srcColumns.length
    if (mirror)
    {
        srcTable.CustomTextAlign = 'end';
        outTable.CustomTextAlign = 'start';
    }
    else
    {
        srcTable.CustomTextAlign = 'end';
        outTable.CustomTextAlign = 'end';
    }
    for (let i = 0; i < len; i++)
    {
        let srcColumn = srcColumns[i]
        let outColumn = outColumns[i]

        let srcDisplayedIdx = srcColumn.displayedIndex
        let outDisplayedIdx = mirror
            ? len - srcDisplayedIdx - 1
            : srcDisplayedIdx

        if (outDisplayedIdx !== outColumn.displayedIndex)
        {
            outColumn.displayedIndex = outDisplayedIdx
            changeOccured = true
        }
    }

    if (changeOccured)
    {
        outTable.UpdateSortedColumns()
        outTable.needRedrawBackground = true
        outTable.needRedraw = true
    }
}

//#endregion

// TODO.
MarketDepthPanel.prototype.repopulate = function ()
{
    OrderEditViewBase.prototype.repopulate.call(this)

    let lvl1Panel = this.Controls.lvl1Panel
    if (lvl1Panel)
        lvl1Panel.repopulate()

    this.set('account', this.get('account'))
    this.set('instrument', this.get('instrument'))

    if (this.quickTableRactiveBID && this.quickTableRactiveBID.quickTable)
        this.visibleColumnChanged(this.quickTableRactiveBID.quickTable.getVisibleColumn())
}

MarketDepthPanel.ColoringMethod =
{
    ByPriceLVL: 0,
    RelativeToVolume: 1,
    StepToMaxVolume: 2,
    ByUpdateTime: 3,
    SizeHistogram: 4,
    None: 5
}

//#region Subscribe Unsubscribe newQuote
MarketDepthPanel.prototype.Subscribe = function ()
{
    let value = this.get('instrument');

    if (!value || value.IsEmpty)
        return

    if (value.DataCache != null)
    {
        if (this.quickTableRactiveBID && this.quickTableRactiveBID.quickTable)
            this.quickTableRactiveBID.quickTable.needRedrawBackground = true;
        if (this.quickTableRactiveASK && this.quickTableRactiveASK.quickTable)
            this.quickTableRactiveASK.quickTable.needRedrawBackground = true;

        value.DataCache.FQuoteCache.addListener(value, this, HistoryType.QUOTE_TRADES);
        value.DataCache.FQuoteCache.addListener(value, this, HistoryType.QUOTE_LEVEL2);
        value.DataCache.FQuoteCache.addListener(value, this, HistoryType.QUOTE_LEVEL1);
        //this.newQuote(value.GetLastQuote(QuoteValid.Last));
    }

    value.RiskSettingsUpdated.Subscribe(this.updateSettings, this)
}

MarketDepthPanel.prototype.Unsubscribe = function (lastInstrument)
{
    if (lastInstrument == null || lastInstrument.IsEmpty)
        return;

    lastInstrument.RiskSettingsUpdated.UnSubscribe(this.updateSettings, this)

    if (lastInstrument.DataCache != null)
    {
        lastInstrument.DataCache.FQuoteCache.removeListener(lastInstrument, this, HistoryType.QUOTE_TRADES);
        lastInstrument.DataCache.FQuoteCache.removeListener(lastInstrument, this, HistoryType.QUOTE_LEVEL2);
        lastInstrument.DataCache.FQuoteCache.removeListener(lastInstrument, this, HistoryType.QUOTE_LEVEL1);
    }
}

MarketDepthPanel.prototype.newQuote = function (message)
{
    if (message && message.Type === HistoryType.QUOTE_INSTRUMENT_DAY_BAR)
        return;

    this.UpdateTable();
}

//#endregion

MarketDepthPanel.prototype.UpdateTable = function ()
{
    //    if (SuspendUpdateTable)
    //        return;
    if (!this.quickTableRactiveBID || !this.quickTableRactiveASK)
        return;

    let instrument = this.get('instrument');
    if (!instrument || instrument.IsEmpty)
        return;
    let lvl2 = instrument.DataCache.FQuoteCache.GetLevel2Cash(instrument);

    if (!lvl2)
        return;

    let acc = this.get('account');
    let sp = DataCache.GetSpreadPlan(acc);
    let bids = lvl2.GetBids(sp);
    let asks = lvl2.GetAsks(sp);

    this.PopulateTable(this.quickTableRactiveBID.quickTable, bids, null, true);
    this.PopulateTable(this.quickTableRactiveASK.quickTable, asks, null, false);
    this.coloringRowsMethod(this.ColoringMethod, this.quickTableRactiveASK.quickTable.rowsArray, this.quickTableRactiveBID.quickTable.rowsArray);
}

MarketDepthPanel.prototype.PopulateTable = function (QuickTable_list, Level2Collection_lev2Collect, currentOrdersInfo, isBid)
{
    var Instrument = this.get('instrument');

    let keys = Object.keys(Level2Collection_lev2Collect.items);
    let items = Level2Collection_lev2Collect.GetSortedList(isBid);
    let aggregatedVolume = 0;
    let prevAggregatedVolume = 0;
    let avgPrice = 0;
    let lastAvgPrice = 0;
    let depth = -1;
    let price = Number.NaN;
    let count = 0;
    let row;
    let countOfQuotes = items.length;

    let len = items.length;

    for (let i = 0; i < len; i++)
    {
        let item = items[i];

        let quoteMessage = item.Quote;
        let MMPD = quoteMessage.MMkey;
        //TODO
        let mdLotsMode = MDLotsMode.General;
        let roundPrecisionValue = 0;
        let roundPrecision = false;

        let row = QuickTable_list.rows[count];
        let qItem;
        if (!row)
        {
            qItem = new Level2ItemQ(SessionSettings);
            qItem.id = i;
        }
        else
            qItem = row.item;


        // Подсчет Aggregated Volume
        if (quoteMessage.QuoteType == Leve2QuoteType.None && !isNaN(quoteMessage.Price))
        {
            aggregatedVolume += quoteMessage.Size;
            // Подсчет VMA Price
            avgPrice = (lastAvgPrice * prevAggregatedVolume + quoteMessage.Price * quoteMessage.Size) / (prevAggregatedVolume + quoteMessage.Size);
        }

        item.AggregatedVolume = aggregatedVolume;

        item.VWAPrice = avgPrice;

        // запоминаем значения
        lastAvgPrice = avgPrice;
        prevAggregatedVolume = aggregatedVolume;


        qItem.SetQuoteMessage(quoteMessage);
        qItem.count = count;
        qItem.VWAP = item.VWAPrice;
        qItem.SetInstrument(Instrument);
        qItem.MMPD = MMPD;
        qItem.aggregatedVolume = item.AggregatedVolume;
        qItem.mdLotsMode = mdLotsMode;
        qItem.RoundedPrecisionValue = roundPrecisionValue;
        qItem.RoundedPrecision = roundPrecision;

        qItem.ItemId = count;
        if (!row)
        {
            row = QuickTable_list.AddItem(qItem);
            row.cells[2].CustomDrawingHandler = this.CustomDrawingHandler.bind(this);
        }
        else
            row.updateItem(qItem);

        qItem.Visible = true;
        if (row)
            row.visible = true;
        count++;
    }

    if (QuickTable_list.scroll)
        QuickTable_list.scroll.setScrollElementsCount(count);

    // if (isBid)
    //     this.BidVisibleRowsCount = count;
    // else
    //     this.AskVisibleRowsCount = count;

    // Скрываем лишнее
    for (let i = count; i < QuickTable_list.rowsArray.length; i++)
    {
        QuickTable_list.rowsArray[i].item.Visible = false;
        QuickTable_list.rowsArray[i].visible = false;
        //QuickTable_list.rowsArray[i].SkipAlertFilter = true;
    }

    QuickTable_list.needRedraw = true;
    QuickTable_list.needRedrawBackground = true;
}



MarketDepthPanel.prototype.getInstrument = function ()
{
    return this.get("instrument");
}

//#region ICaller

MarketDepthPanel.prototype.callBack = function (properties)
{
    ApplicationPanelNew.prototype.callBack.call(this, properties);

    this.set('instrument', this.getCallBackInstrument(properties, 'symbol'));

    let dp = DynProperty.getPropertyByName(properties, 'account');
    if (dp && dp.value) this.set('account', SessionSettings.getDefValueFromObj(DataCache.Accounts[dp.value], DataCache.Accounts));

    dp = DynProperty.getPropertyByName(properties, 'mirror');
    if (dp) this.set('mirror', dp.value);

    dp = DynProperty.getPropertyByName(properties, 'colorMetod');
    if (dp) this.ColoringMethod = dp.value;

    var panelsVisibility = this.get('panelsVisibility');
    for (let key in panelsVisibility)
    {
        let propName = 'panelsVisibility.' + key
        dp = DynProperty.getPropertyByName(properties, propName);
        if (dp) this.set(propName, dp.value)
    }
};

MarketDepthPanel.prototype.Properties = function ()
{
    var properties = ApplicationPanelNew.prototype.Properties.call(this);
    var ins = this.get('instrument');
    if (ins) properties.push(new DynProperty("symbol", ins.GetInteriorID(), DynProperty.STRING, DynProperty.HIDDEN_GROUP));

    var acc = this.get('account');
    if (acc) properties.push(new DynProperty("account", acc.AcctNumber, DynProperty.STRING, DynProperty.HIDDEN_GROUP));

    var mirror = this.get('mirror');
    properties.push(new DynProperty("mirror", mirror, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));

    var colorMetod = this.ColoringMethod;
    properties.push(new DynProperty("colorMetod", colorMetod, DynProperty.INTEGER, DynProperty.HIDDEN_GROUP));

    var panelsVisibility = this.get('panelsVisibility');
    for (let key in panelsVisibility)
        properties.push(new DynProperty("panelsVisibility." + key, panelsVisibility[key], DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));

    return properties;
};

//#endregion

MarketDepthPanel.prototype.getXmlSettingsTemplate = function ()
{
    var xml = {};

    var qtRactive = this.quickTableRactiveBID;  // симметрична с quickTableRactiveASK
    var qt = qtRactive ? qtRactive.quickTable : null;
    if (qt) xml = qt.xmlSettingsTemplate;

    return xml;
};

MarketDepthPanel.prototype.setXmlSettingsTemplate = function (value)
{
    var qtRactive = this.quickTableRactiveBID;
    var qt = qtRactive ? qtRactive.quickTable : null;
    if (qt)
    {
        qt.xmlSettingsTemplate = value;
        this.quickTableRactiveASK.quickTable.xmlSettingsTemplate = value
    }
    else
    {
        this.deferredBIDXmlTemplate = value;
        this.deferredASKXmlTemplate = value;
    }
};

MarketDepthPanel.prototype.updateSettings = function (needRepopulate = true)
{
    this.set('reverseOrderButtons', GeneralSettings.View.ReverseButtonsOrder)

    this.UpdateTable()

    if (needRepopulate)
    {
        this.repopulate(); // обновляет comboBox-ы (OrderType и Tif) у MarketDepthPanel и AdvancedOrderEntry
    }
    this.updateTradingAllowed();
}
