// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../Commons/properties/Resources';
import { QuickTableColumnType } from '../elements/QuickTable/QuickTableMisc';
import { BaseItem, ColumnData } from './BaseItem';
import { ColumnParams } from './ColumnParams';
import { OperationType } from '../../Utils/Trading/OperationType';
import { ThemeManager } from '../misc/ThemeManager';
import { QuotingType } from '../../Utils/Instruments/QuotingType';
import { InstrumentTypes } from '../../Utils/Instruments/InstrumentTypes';
import { AccountType } from '../../Utils/Account/AccountType';
import { DateTimeUtils } from '../../Utils/Time/DateTimeUtils';
import { InstrumentTradingBalance } from '../../Utils/Instruments/InstrumentTradingBalance';
import { GeneralSettings } from '../../Utils/GeneralSettings/GeneralSettings';
import { RiskPlan } from '../../Commons/cache/RiskPlan';
import { Instrument } from '../../Commons/cache/Instrument';
import { InstrumentUtils } from '../../Utils/Instruments/InstrumentUtils';
import { DataCache } from '../../Commons/DataCache';
import { DateTimeConvertor } from '../../Utils/Time/DateTimeConvertor';
import { OrderUtils } from '../../Utils/Trading/OrderUtils';
import { type SessionSettingsType } from '../../Commons/SessionSettings';
import { type NewTrade } from '../../Commons/cache/NewTrade';
import { type Asset } from '../../Commons/cache/Asset';
import { type Account } from '../../Commons/cache/Account';
import { FilledOrderFormatter } from '../../Commons/cache/Formatters/FilledOrderFormatter';
export class FilledOrderItem extends BaseItem {
    public trade: NewTrade;
    public crossInstrumentUSD: any;
    public Exp1_Asset: Asset | null;
    public Exp2_Asset: Asset | null;
    public useInstrumentCurrencyForExposition: boolean = false;

    private readonly _trade: any = null;

    constructor (trade: NewTrade, sessionSettings: SessionSettingsType) {
        super(sessionSettings);

        // this.sessionSettings = sessionSettings;
        this.trade = trade;
        if (trade) {
            this.crossInstrumentUSD = this.trade.Instrument === null ? 1 : this.trade.Instrument.DataCache.CrossRateCache.GetCrossPriceIns(this.trade.Instrument);

            if (trade.InstrumentStr) {
                const insSplit = trade.InstrumentStr.split(':');
                if (insSplit.length) {
                    trade.InstrumentStr = insSplit[0];
                }
            }
        }

        this.Exp1_Asset = trade && trade.Instrument ? trade.Instrument.DataCache.GetAssetByName(trade.Instrument.Exp1) : null;
        this.Exp2_Asset = trade && trade.Instrument ? trade.Instrument.DataCache.GetAssetByName(trade.Instrument.Exp2) : null;

        const tm = ThemeManager.CurrentTheme;
        this.CellForeColorMap[2] = tm.TableGoldColor;// "#EDC234";
        this.CellForeColorMap[5] = tm.TableGoldColor;// "#EDC234";
        if (trade) {
            this.CellForeColorMap[4] = trade.BuySell === OperationType.Buy ? tm.TableLongBuyColor : tm.TableShortSellColor;
        }// "#EDC234";
    }

    public override ColumnCount (): number {
        return FilledOrderItem.columnsParams.length;
    }

    public override getColumnData (column: number): ColumnData {
        let value = null;
        let formattedValue = null;

        if (this.trade === null) {
            value = null;
            formattedValue = '';
        }

        value = this.getColumnValue(column);

        const crossInstrumentUSD = this.crossInstrumentUSD;
        let valueCrossPr = null;

        switch (column) {
        case 0:
            formattedValue = this.trade.TradeId;
            break;
        case 4:
            if (this.trade.BuySell === OperationType.Buy) {
                formattedValue = Resources.getResource('panel.watchlist.Buy');
            } else if (this.trade.BuySell === OperationType.Sell) {
                formattedValue = Resources.getResource('panel.watchlist.Sell');
            } else
            //    formattedValue =  Const.getBuySellStr(this.trade.BuySell);
            // TODO
            {
                formattedValue = this.trade.BuySell;
            }
            break;
        case 5:
            formattedValue = value.toString();
            break;
        //
        // Price
        //
        case 6:{
            const ins = this.trade.Instrument;
            if (ins !== null) {
                formattedValue = ins.formatPricePrecision(value);
            } else {
                formattedValue = value.toString();
            }
            break;
        }
        case 7:
            formattedValue = FilledOrderFormatter.ExecutionFeeStr(this.trade);
            break;
        case 8:
            formattedValue = FilledOrderFormatter.GrossPnLStr(this.trade);
            break;
        case 9:
            // TODO
            formattedValue = DateTimeUtils.FormatToTime(value);// .ToLongTimeString();
            break;
        case 10:
            // TODO
            formattedValue = DateTimeUtils.formatDate(value, 'DD.MM.YYYY  HH:mm:ss');// .ToShortDateString();
            break;
        case 14:{
            const extPrice = value;

            if (isNaN(extPrice)) {
                formattedValue = '';
            } else {
                const ins = this.trade.Instrument;
                if (ins !== null) {
                    formattedValue = ins.formatPrice(extPrice);
                } else {
                    // TODO
                    if (value) {
                        formattedValue = value.toString();
                    } else {
                        formattedValue = '';
                    }
                }
            }
            break;
        }

        //
        // Purchase price
        //
        case 15:
            valueCrossPr = value * (this.InBaseCurrency() ? this.trade.OpenCrossPrise : crossInstrumentUSD);
            if (this.trade?.Account !== null) {
                if (this.trade.Account.AccountType === AccountType.MultiAsset && this.trade.Instrument !== null) {
                    formattedValue = this.Exp2_Asset !== null ? this.Exp2_Asset.formatPrice(valueCrossPr) : value.toString() + ' ' + this.trade.Instrument.Exp2;
                } else {
                    if (this.useInstrumentCurrencyForExposition && this.Exp2_Asset !== null) {
                        formattedValue = this.Exp2_Asset.formatPrice(valueCrossPr);
                    } else {
                        formattedValue = this.trade.Account.formatPrice(valueCrossPr);
                    }
                }
            } else {
                formattedValue = '';
            }
            break;
        case 19:
            formattedValue = FilledOrderFormatter.NetPnLStr(this.trade);
            break;
        case 20:
            formattedValue = FilledOrderFormatter.GetBoughtStr(this.trade);
            break;
        case 21:
            formattedValue = FilledOrderFormatter.GetSoldStr(this.trade);
            break;
        case 22:
            valueCrossPr = (value) / crossInstrumentUSD * this.trade.OpenCrossPrise;
            formattedValue = this.FormatPriceAtCorrectAsset(valueCrossPr);
            break;
        case 23:
            if (value > 0) {
                if (this.trade?.Instrument !== null) {
                    formattedValue = this.trade.Instrument.formatPrice(value);
                } else {
                    formattedValue = value.toString();
                }
            } else {
                formattedValue = '';
            }
            break;
        case 24:
            if (value && value.getTime() > 0) {
                formattedValue = DateTimeUtils.FormatToDate(value);
            } else {
                formattedValue = '';
            }
            break;
        case 26:
            formattedValue = this.trade.TradeIdLabel || this.trade.TradeId || '';
            break;
        case 27:
        case 28:
        case 29:{
            valueCrossPr = value;

            if (column === 27 && this.trade.AdvancedTradeParams !== null && (this.trade.AdvancedTradeParams.CommissionAssetID !== undefined && this.trade.AdvancedTradeParams.CommissionAssetID !== null)) {
                const asset = this.trade.Instrument !== null ? this.trade.Instrument.DataCache.GetAssetById(this.trade.AdvancedTradeParams.CommissionAssetID.Value) : null;
                formattedValue = asset !== null ? asset.formatPrice(valueCrossPr) : valueCrossPr.toString();
            }

            const instrumnet = this.trade.Instrument; // DataCache.GetAccountByNumber(this.trade.AccountId.toString());
            if (instrumnet !== null) {
                if (value) {
                    formattedValue = this.Exp2_Asset !== null ? this.Exp2_Asset.formatPrice(valueCrossPr) : (value.toString() + ' ' + this.trade.Instrument.Exp2);
                } else {
                    formattedValue = this.trade.Instrument.Exp2;
                }
            } else {
                // TODO
                if (value) {
                    formattedValue = value.toString();
                } // TODO// System.String.Format("{0:N2}", value);
                else {
                    formattedValue = '';
                }
            }

            break;
        }

        case 31:
            if (this.trade.ExecutionVenue) {
                formattedValue = this.trade.ExecutionVenue;
            } else {
                if (this.trade.Instrument?.TradingExchange) // #89156
                {
                    formattedValue = this.trade.Instrument.TradingExchange;
                } else {
                    formattedValue = '';
                }
            }
            break;
        case 33:
            formattedValue = OrderUtils.GetFormattedLeverage(value, true) || Resources.getResource('general.N_A');
            break;
        default:
            if (value) {
                formattedValue = value.toString();
            } else {
                formattedValue = '';
            }
            break;
        }

        return new ColumnData(value, formattedValue);
    }

    public override getColumnValue (column: number): any {
    // +++
    // if (column >= columnsParams.Length && customColumnValues.ContainsKey(column))
    //    return customColumnValues[column];

        if (this.trade === null) {
            return null;
        }

        const orderParameterContainer = DataCache.OrderParameterContainer;

        let ins = null;

        const crossInstrumentUSD = this.crossInstrumentUSD;
        switch (column) {
        case 0:
        {
            const trId = parseInt(this.trade.TradeId.split('-')[0]);
            return trId;
        }

        case 1:
            return this.trade.OrderId;

        case 2:
            return this.trade.Instrument
                ? this.trade.Instrument.DisplayShortName()
                : this.trade.InstrumentStr || '';
        case 3:{
            const acc = this.trade.Account; // this.trade.DataCache.GetAccountByNumber(this.trade.AccountId.toString());
            if (acc !== null) {
                return acc.toString();
            } else {
                return '';
            }
        }

        case 4:
            if (this.trade.BuySell === OperationType.Buy) {
                return Resources.getResource('panel.watchlist.Buy');
            } else if (this.trade.BuySell === OperationType.Sell) {
                return Resources.getResource('panel.watchlist.Sell');
            }
            // else
            // TODO
            //    return Const.getBuySellStr(this.trade.BuySell);
            return this.trade.BuySell;
        case 5:{
            let sign = 1;
            // if (this.trade.BuySell !== OperationType.Buy && this.sessionSettings.ShowAmountSign)
            //     sign = -1;
            if (this.trade.Amount < 0 && sign < 0) {
                sign = 1;
            }

            if (GeneralSettings.View.DisplayQuantityInLots) {
                return this.trade.Amount * sign;
            } else {
                ins = this.trade.Instrument;
                if (ins !== null) {
                    return this.trade.Amount * ins.LotSize * sign;
                } else {
                    return this.trade.Amount * sign;
                }
            }
        }

        case 6:
            return this.trade.Price;

        case 7:
            return FilledOrderFormatter.ExecutionFee(this.trade);

        case 8:
            return FilledOrderFormatter.GrossPnL(this.trade);

        case 9:
            return this.trade.Time;
            // TODO
            // return DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(this.trade.Time, BaseApplication.App.MultiDataCache !== null ? BaseApplication.App.MultiDataCache.DCache : this.trade.DataCache);

        case 10:
            return this.trade.Time;
            // TODO
            // return DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(this.trade.Time, BaseApplication.App.MultiDataCache !== null ? BaseApplication.App.MultiDataCache.DCache : this.trade.DataCache);

        case 11:
            return this.trade.ExternalTradeId;

        case 12:
            return this.trade.Login;

        case 13:
            return this.trade.RouteID;

        case 14:
            return this.trade.ExternalPrice;

        case 15:{
            ins = this.trade.Instrument;
            const cross = this.InBaseCurrency() ? 1 : this.trade.OpenCrossPrise;
            if (ins !== null) {
                return (this.trade.Price * this.trade.Amount * ins.LotSize * ins.GetTickCost()) * cross;
            } else if (this.trade.Exposure > 0) {
                return this.trade.Exposure * cross;
            } else {
                return (this.trade.Price * this.trade.Amount) * cross;
            }
        }

        case 16:
            if (this.trade.Instrument) {
                return this.trade.Instrument.TradingExchange;
            } else if (this.trade.TradingExchange) {
                return this.trade.TradingExchange;
            }
            return '';

        case 17:{
            const orderTypeObj = orderParameterContainer.GetOrderType(this.trade.OrderType);
            return orderTypeObj
                ? Resources.getResource('property.' + orderTypeObj.localizationKey())
                : '';
        }

        case 18:
            if (this.trade !== null && this.trade.Instrument !== null && this.trade.DataCache !== null) {
                return InstrumentUtils.getInstrumentTypeStringLocalized(this.trade.Instrument.InstrType);
            } else if (this.trade?.SymbolType) {
                return this.trade.SymbolType;
            }
            return '';

        case 19:
            return FilledOrderFormatter.NetPnL(this.trade);

        case 20:
            if (this.trade && this.trade.Instrument) {
                return FilledOrderFormatter.GetBought(this.trade);
            } else {
                return 0;
            }

        case 21:
            if (this.trade && this.trade.Instrument) {
                return FilledOrderFormatter.GetSold(this.trade);
            } else {
                return 0;
            }

        case 22:
            return this.trade.Rebates * crossInstrumentUSD; // TODO Rebates = undefined

        case 23:
            if (this.trade !== null && this.trade.StrikePrice > 0) {
                return this.trade.StrikePrice;
            } else {
                return 0;
            }

        case 24:
            if (this.trade?.Instrument !== null && this.trade.Instrument.ExpDateReal.getFullYear() > 2000) {
                return DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(this.trade.Instrument.ExpDateReal);
            } else {
                return DateTimeUtils._ZeroTime;
            }

        case 25:
            return this.trade !== null ? this.trade.ExternalOrderId : '';

        case 26:
        {
            let trId;
            if (this.trade.TradeIdLabel) {
                trId = parseInt(this.trade.TradeId.split('-')[0]);
            } else {
                trId = parseInt(this.trade.TradeIdLabel?.split('-')[0]);
            }

            return trId;
        }
        case 27:
            return -this.trade.ComissionInInstrumentQuotingCurrency; // +++yura - знак коммиссии переворачиваем
        case 28:
            return this.trade.PnLInInstrumentQuotingCurrency;
        case 29:
            if (this.trade !== null) {
                return ((this.trade.PnLInInstrumentQuotingCurrency - this.trade.ComissionInInstrumentQuotingCurrency + this.trade.Swap));
            } else {
                return 0;
            }
        case 30:
            if (this.trade.Instrument !== null) {
                return InstrumentUtils.GetLocalizedProductType(this.trade.Instrument, this.trade.ProductType);
            }
            return '';
        case 32:{
            const trade = this.trade;
            let descr = trade.Description;

            ins = trade.Instrument;

            if (!descr && ins) {
                descr = ins.DescriptionValue();
            }

            return descr;
        }

        case 33:
            return this.trade ? this.trade.Leverage : null;

        default:
            return null;
        }
    }

    public InBaseCurrency (): boolean {
        const instrument = this.GetCurrentInstrument();
        const DC = instrument ? instrument.DataCache : DataCache;

        return DC.filledOrdersCurrency == DC.baseCurrency;
    }

    public override getFormatTotalValue (column: number, value, precisionMode = false, totalCurrencyAfterFiltration = null): string | number {
        let formattedValue = '';
        const dc = DataCache;
        const asset = dc.GetAssetByName(totalCurrencyAfterFiltration || dc.filledOrdersCurrency);

        switch (column) {
        case 5:
            // case 20:
            // case 21:
            if (precisionMode) {
                return 2;
            }
            formattedValue = value.toFixed(2);
            break;

        case 7:
        case 8:
        case 19:
        case 15:
        case 22:
            if (precisionMode) {
                return asset.Point;
            }
            formattedValue = asset.formatPrice(value);
            break;
        }

        return formattedValue;
    }

    public override GetColumnParams (column: number): ColumnParams {
        const result = FilledOrderItem.columnsParams[column];
        const isHidden = Resources.isHidden(result.HeaderKey);
        result.Hidden = isHidden;
        result.MenuIndex = this.GetMenuIndex(result.HeaderKey);

        if (column === 9 || column === 13 || column === 14 || column === 31) {
            result.Hidden = true;
        }
        // res.MenuIndex = GetMenuIndex(res.HeaderKey);

        // if (i === 14)
        // {
        //    let u = BaseApplication.App.MultiDataCache.ActiveUser;
        //    res.Hidden = u === null || !u.IsRoleBroker() /*.Role !== 2*/;

        // }

        // роут
        // if (i === 13)
        // {
        //    //res.Hidden = BaseApplication.App.MultiDataCache.IsHideRouteMode;
        //    //res.Hidden = !BaseApplication.App.MultiDataCache.isAllowed(RulesSet.FUNCTION_POSITIONSADMIN, null);
        // }

        // External ID
        // if (i === 11)
        // {
        //    let checkRuleUser = null;
        //    if (this.sessionSettings === null)
        //        checkRuleUser = BaseApplication.App.MultiDataCache.DCache.LoginUser;
        //    else
        //        checkRuleUser = this.sessionSettings.LoginUser;

        //    let isUSERSADMIN = BaseApplication.App.MultiDataCache.isAllowedForUser(RulesSet.FUNCTION_USERSADMIN, checkRuleUser);

        //    res.Hidden = !isUSERSADMIN;
        // }

        // Product type
        if (column === 30) {
            result.Hidden = !RiskPlan.showProductType;
        }

        return result;
    }

    public override fireUpdate (): void {

    }

    public override GetCurrentAccount (): Account {
        if (this.trade !== null) {
            return this.trade.Account;
        } else return null;
    }

    public override GetCurrentInstrument (): Instrument {
        if (this.trade !== null) {
            return this.trade.Instrument;
        } else return null;
    }

    public override InstrumentName (): string {
        if (this.trade !== null) {
            return this.trade.Instrument.GetInteriorID();
        } else return null;
    }

    /// <summary>
    /// Здесь хранятся настройки колонок (один экземпляр на класс)
    /// </summary>
    public static readonly columnsParams = [
    //                     Header Name                               AdvLabel                                       Width     ColumnType               Total Visible AllowGrouiping
        /* 0 */ new ColumnParams('panel.NewTrades.TradeID', 75, 0, false, false, false),
        new ColumnParams('panel.NewTrades.OrderId', 75, 0, false, false, false),
        new ColumnParams('panel.NewTrades.Instrument', 75, 0, false, true, true),
        new ColumnParams('panel.NewTrades.Account', 75, 0, false, false, true),
        new ColumnParams('panel.NewTrades.Operation', 75, 0, false, true, true),
        new ColumnParams('panel.NewTrades.Amount', 75, 3, true, true, false),

        /* 6 */ new ColumnParams('panel.NewTrades.Price', 75, 3, false, true, false),
        new ColumnParams('panel.NewTrades.Commission', 75, 3, true, true, false),
        new ColumnParams('panel.NewTrades.RealizedProfitLoss', 75, 3, true, false, false),
        new ColumnParams('panel.NewTrades.Time', 75, QuickTableColumnType.COL_DATE_SORT, false, true, false),
        /* 10 */ new ColumnParams('panel.NewTrades.Date', 70, QuickTableColumnType.COL_DATE_SORT, false, true, true),
        new ColumnParams('panel.NewTrades.ExternalTradeId', 75, 0, false, false, false),

        new ColumnParams('panel.NewTrades.Login', 75, 0, false, false, false),
        new ColumnParams('panel.NewTrades.Route', 75, 0, false, false, false),
        new ColumnParams('panel.NewTrades.ExternalPrice', 75, 3, false, false, false),
        /* 15 */ new ColumnParams('panel.NewTrades.PurchasePrice', 75, 3, true, false, false),
        new ColumnParams('panel.NewTrades.ExchangeTrading', 75, 0, false, true),
        new ColumnParams('panel.NewTrades.OrderType', 65, 0, false, false, true),
        new ColumnParams('panel.NewTrades.instrType', 70, 0, false, true, true),
        new ColumnParams('panel.NewTrades.Net_PL', 75, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, true, false),
        /* 20 */ new ColumnParams('panel.NewTrades.Bought', 75, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
        new ColumnParams('panel.NewTrades.Sold', 75, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
        new ColumnParams('panel.NewTrades.Rebates', 75, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        new ColumnParams('panel.NewTrades.StrikePrice', 75, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
        new ColumnParams('panel.NewTrades.ExpirationDate', 70, QuickTableColumnType.COL_DATE_SORT, false, false, false),
        /* 25 */ new ColumnParams('panel.NewTrades.ExternalOrderId', 75, QuickTableColumnType.COL_DEFAULT, false, false, false),
        new ColumnParams('panel.NewTrades.TradeIDLabel', 75, 0, false, true, false),
        new ColumnParams('panel.NewTrades.Commission.InInstrumentQuotingCurrency', 75, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false),
        new ColumnParams('panel.NewTrades.RealizedProfitLoss.InInstrumentQuotingCurrency', 75, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false),
        new ColumnParams('panel.NewTrades.Net_PL.InInstrumentQuotingCurrency', 75, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false),
        /* 30 */ new ColumnParams('panel.NewTrades.ProductType', 75, QuickTableColumnType.COL_DEFAULT, false, false, false),
        new ColumnParams('panel.NewTrades.ExecutionVenue', 75, 0, false, false, false),
        new ColumnParams('panel.NewTrades.Description', 70, 0, false, false, false),
        new ColumnParams('panel.NewTrades.Leverage', 70, 0, false, false, true)
    ];

    public override NeedSortMenu (): boolean {
        return true;
    }

    public GetMenuIndex (headerKey: string): number {
        if (FilledOrderItem.ListMenuIndex.Contains(headerKey)) {
            return FilledOrderItem.ListMenuIndex.indexOf(headerKey);
        } else {
            return 1000;
        }
    }

    public static readonly ListMenuIndex =
        [
            'panel.NewTrades.Account',
            'panel.NewTrades.Login',
            'panel.NewTrades.Route',
            'panel.NewTrades.Instrument',
            'panel.NewTrades.instrType',
            'panel.NewTrades.TradeID',
            'panel.NewTrades.OrderId',
            'panel.NewTrades.ExternalTradeId',
            'panel.NewTrades.Operation',
            'panel.NewTrades.OrderType',
            'panel.NewTrades.Price',
            'panel.NewTrades.Amount',
            'panel.NewTrades.Date',
            'panel.NewTrades.Time',
            'panel.NewTrades.Commission',
            'panel.NewTrades.Rebates',
            'panel.NewTrades.RealizedProfitLoss',
            'panel.NewTrades.Net_PL',
            'panel.NewTrades.PurchasePrice',
            'panel.NewTrades.Bought',
            'panel.NewTrades.Sold',
            'panel.NewTrades.StrikePrice',
            'panel.NewTrades.ExpirationDate',
            'panel.NewTrades.ExternalOrderId'
        ];

    public FormatPriceAtCorrectAsset (valueCrossPr: number): string {
        let formattedValue = '';
        const acc = this.trade.Account;// DataCache.GetAccountByNumber(this.trade.AccountId.toString());
        if (acc !== null) {
            if (acc.AccountType === AccountType.MultiAsset && this.trade.Instrument !== null) {
                const assetName = FilledOrderItem.GetTargetCurrency(acc, this.trade.Instrument, this.trade.BuySell === OperationType.Buy);
                const assetRes = acc.GetAssetBalanceByName(assetName);
                let asset = null;
                if (assetRes) {
                    asset = assetRes.Asset;
                }
                formattedValue = asset != null ? asset.formatPrice(valueCrossPr) : valueCrossPr.toString();// string.Format("{0:N2}", valueCrossPr);
            }
            // formattedValue = this.Exp2_Asset !== null ? this.Exp2_Asset.formatPrice(valueCrossPr) : (value.toString() + " " + this.trade.Instrument.Exp2);
            else {
                formattedValue = acc.formatPrice(valueCrossPr);
            }
        } else {
            formattedValue = valueCrossPr.toString();
        }// TODO//System.String.Format("{0:N2}", value);

        return formattedValue;
    }

    public static GetTargetCurrency (account: Account, instrument: Instrument, isBuy: boolean): string {
        if (account.AccountType === AccountType.MultiAsset) {
            if (instrument.DeliveryMethod === Instrument.Delivery_Physical && instrument.TradingBalance === InstrumentTradingBalance.SETTLEMENT_IMMEDIATE) {
                return isBuy ? instrument.Exp1 : instrument.Exp2;
            }

            // todo
            return instrument.Exp2;// CommissionItem.GetCommissionCurrency(account, instrument);
        }
        return instrument.Exp2;
    }
}
