// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { VideoWidgetCardTemplate } from "../../../templates.js";
import { ContainerControl } from "../../elements/ContainerControl.js";
import { MainWindowManager } from "../../UtilsClasses/MainWindowManager.ts";
import { CustomEvent } from "../../../Utils/CustomEvents.ts";
import { Resources } from "../../../Commons/properties/Resources.ts";
import { DateTimeUtils } from "../../../Utils/Time/DateTimeUtils.ts";
import { ExternalLink } from "../../../Commons/cache/ExternalLink.ts";
import { VideoWidgetUtils } from "./VideoWidgetUtils.ts";
import { UserWebStorageInstance } from "../../../user-web-storage.ts";
import { externalScreenHandler } from "../../../Utils/AppHandlers.js";

export let VideoWidgetCard = ContainerControl.extend({
    template: VideoWidgetCardTemplate,
    data: function ()
    {
        return {
            visible: false,
            externalLink: null,                                        // This variable holds a reference to the ExternalLink object created in the BackOffice and sent to the client through the CODE_PFIX_EXTERNAL_LINK_MESSAGE[492] message
            title: null,                                               // The name or title of the YouTube video in the 'Link *' field of the BackOffice external link can be obtained using either the YouTube Data API or a noembed.com proxy. (!)This is important to conserve the daily quota of YouTube Data API requests

            videoLink: null,                                           // YouTube video URL string, e.g. https://youtu.be/j4W5EY2S6X4
            videoID: null,                                             // A string with Video's ID, e.g. j4W5EY2S6X4
            videoPreviewURL: null,                                     // A string with URL of a video preview image

            channelName: null,                                         // Author - the name of the YouTube channel that owns the video in the 'Link *' field of the BackOffice external link. The available methods to obtain this information are both YouTube Data API or a noembed.com proxy
            channelID: null,                                           // The YouTube Author's ID. Please note that this information can only be obtained using the YouTube Data API, and we require it to obtain the channelPic.
            channelPic: null,                                          // A string representing the URL to the YouTube channel's user picture. Please note that this information can only be obtained using the YouTube Data API

            isLive: null,                                              // This variable indicates whether the video is currently being broadcasted live or not. The value is obtained by checking with the YouTube Data API based on the data sent by the server
            isLiveFromBO: null,                                        // isLive bool value from Back Office
            externalLinkName: null,                                    // Tool name from Back Office's External Link creation
            externalLinkID: null,                                      // And some more fields from the BO with status "purpose remains unclear"
            useInternalBrowser: null,
            externalLinkAvailableUntil: null,
            expired: null,                                             // = Date.now() > externalLinkAvailableUntil

            isStream: null,                                            // The boolean value 'true' determines whether the video is an upcoming, live, or already ended stream, while 'false' identifies it as a regular uploaded YouTube video.
            streamStartDate: null,                                     // Available only if isStream == true
            streamEndDate: null,                                       // Available if isStream == true && streamHasBeenAlreadyEnded == true
            streamHasNotStartedYet: null,                              // The video is an upcoming stream
            streamHasBeenAlreadyEnded: null,                           // The video is an already ended stream
            parsedSuccessfully: null                                   // Got at least title => link is valid and video still exists
        }
    },

    externalScreenInitWidth: 545,                                      // Defines the width of the default screen that opens when a card is clicked, if the associated external link uses the internal browser
    externalScreenInitHeight: 343,                                     // Defines the height of the default screen that opens when a card is clicked, if the associated external link uses the internal browser 
    locKeyOfLIVELabel: 'panel.VideoWidget.TimeDateLabelTextForLIVE',   // Key of text's localization that is showing near red LIVE icon when stream is broadcasting 
    gotFetchedResponse: false,                                         // was parsing completed
    PublishingDate: null,                                              // Sort cards' order by this Date field 
    OnFullDataFetched: null,                                           // CustomEvent .Raise(card) when card received whole need data from API
    OnIsLIVEChanged: null                                              // CustomEvent .Raise(card) when the card's 'isLive' status changes.
})

VideoWidgetCard.prototype.getType = function () { return 'VideoWidgetCard'; }

VideoWidgetCard.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.apply(this);

    this.OnFullDataFetched = new CustomEvent();
    this.OnIsLIVEChanged = new CustomEvent();

    this.localize();

    let videoWidget = this.parent;
    if (videoWidget)
        videoWidget.subscribeWidgetToCardEvents(this);

    this.on('onCardClick', this.onCardClick);
    this.observe('externalLink', this.updateByExternalLink);
    this.observe('expired', (isExpired) => { if (isExpired) this.updateLIVE(); });
}

VideoWidgetCard.prototype.localize = function ()
{
    this.set('localizedLIVE', Resources.getResource(this.locKeyOfLIVELabel));
}

VideoWidgetCard.prototype.updateByExternalLink = function (eL, oldEL, key, onLinkUpdate = false)                                                                                          // This method retrieves video information such as the title, channel name, publishing date, etc. by utilizing either the YouTube Data API or a proxy
{
    if (oldEL && oldEL != eL && !onLinkUpdate)
    {
        this.set('externalLink', oldEL);
        return;
    }

    let URL = ExternalLink.IsYouTubeLink(eL) ? eL.url : null;
    if (!URL)
    {
        this.ParsedSuccessfully = false;
        return;
    }

    let oldLinkValue = this.Link;
    if (!oldLinkValue || onLinkUpdate)
    {
        this.Link = URL;

        this.ExternalLinkName = eL.name;
        this.ExternalLinkID = eL.id;
        this.UseInternalBrowser = eL.useInternalBrowser;
        this.ExternalLinkAvailableUntil = eL.availableUntil;
        this.IsLiveFromBO = eL.liveStream;
    }
}

VideoWidgetCard.prototype.onCardClick = function ()                                                                                          // This method retrieves video information such as the title, channel name, publishing date, etc. by utilizing either the YouTube Data API or a proxy
{
    if (this.UseInternalBrowser)
    {
        const screenInitData = {
            ClientUseInternalBrowser: this.UseInternalBrowser,
            Text: VideoWidgetUtils.GetEmbedYouTubeLinkByVideoID(this.VideoID),
            Tool: this.get('externalLink'),
            ResizableScreen: true,
            ScreenWidth: this.externalScreenInitWidth,
            ScreenHeight: this.externalScreenInitHeight,
            ScreenName: this.Title
        };

        const scr = externalScreenHandler.Show(screenInitData, true);
    }
    else
        window.open(this.Link);
}

VideoWidgetCard.prototype.fetchVideoData = function ()                                                                                          // This method retrieves video information such as the title, channel name, publishing date, etc. by utilizing either the YouTube Data API or a proxy
{
    UserWebStorageInstance.youtubePageParser.post(this.Link, this.VideoID).then((data) => { this.fetchResponseProcess(data) });
}

VideoWidgetCard.prototype.fetchResponseProcess = function (resp)    
{
    if (!resp)
        return;

    let data = resp.data;

    this.Title = data.title;
    this.PreviewImageSource = data.thumbnail;                                                                         // One more way to get Video image

    this.ChannelID = data.channelId;
    this.ChannelName = data.author;
    this.ChannelPic = data.authorThumbnail;

    this.IsStream = data.isStream;
    this.StreamStartDate = data.streamStartDateTimeString;
    this.StreamEndDate = data.streamEndDateTimeString;

    const upcoming = data.streamHasNotStartedYet;
    this.StreamHasNotStartedYet = upcoming;
    
    if (upcoming && Date.now() > this.get('streamStartDate'))   //#114917
    {
        this.StreamHasNotStartedYet = false
        data.isLIVE = true
    }

    this.StreamHasBeenAlreadyEnded = data.streamHasBeenAlreadyEnded;
    this.ParsedSuccessfully = !!data.title;
    this.PublishingDate = data.publishedAt;                                                                                           // Note: This also initializes the PublishingDateFormatted property. This variable is initialized with a string argument that is suitable for the new Date(<argument>) constructor. However, accessing this variable as a property will return a Date object.

    this.updateLIVE(data.isLIVE)

    this.gotFetchedResponse = true;
    this.OnFullDataFetched.Raise(this);
}
/**
 * Update the value of IsLIVE with the provided argument and raise the OnIsLIVEChanged event if necessary.
 * 
 * @param {boolean} [newIsLIVE=false] - The new value for IsLIVE. Default is false.
 */
VideoWidgetCard.prototype.updateLIVE = function (newIsLIVE = false)
{
    const oldIsLIVE = Boolean(this.IsLIVE);
    this.IsLIVE = Boolean(newIsLIVE);

    if (this.IsLIVE !== oldIsLIVE)
        this.OnIsLIVEChanged.Raise(this);
}


VideoWidgetCard.prototype.dispose = function ()
{
    let W = this.parent;
    if (W)
    {
        W.unsubscribeWidgetFromCardEvents(this);

        let cards = W.get('cards');
        cards[this.ExternalLinkID] = null;
        W.set('cards', cards);
    }

    ContainerControl.prototype.dispose.apply(this);
}

Object.defineProperty(VideoWidgetCard.prototype, 'ChannelID', {
    get: function () { return this.get('channelID'); },
    set: function (value) { this.set('channelID', value); }                // Only by request to the YouTube Data API or via parsing.
});

Object.defineProperty(VideoWidgetCard.prototype, 'ChannelName', {
    get: function () { return this.get('channelName'); },
    set: function (value) { this.set('channelName', value); }              // Set by making a request to the YouTube Data API or via parsing or the noembed.com proxy.
});

Object.defineProperty(VideoWidgetCard.prototype, 'ChannelPic', {
    get: function () { return this.get('channelPic'); },
    set: function (value) { this.set('channelPic', value); }               // Can be set only by making a request to the YouTube Data API or via parsing
});

Object.defineProperty(VideoWidgetCard.prototype, 'Title', {
    get: function () { return this.get('title'); },
    set: function (value) { this.set('title', value); }                    // Set by making a request to the YouTube Data API or the noembed.com proxy or via parsing.
});

Object.defineProperty(VideoWidgetCard.prototype, 'Link', {                // The URL of the YouTube video assigned to the ExternalLink object in the BackOffice.
    get: function () { return this.get('videoLink'); },
    set: function (value)
    {
        if (value)
        {
            this.set('videoLink', value);
            this.VideoID = VideoWidgetUtils.GetVideoIDFromYouTubeLink(value);
        }
    }
});

Object.defineProperty(VideoWidgetCard.prototype, 'VideoID', {
    get: function () { return this.get('videoID'); },
    set: function (value)
    {
        if (value)
        {
            this.set('videoID', value);
            this.fetchVideoData();
        }
    }
});

Object.defineProperty(VideoWidgetCard.prototype, 'PreviewImageSource', {
    get: function () { return this.get('videoPreviewURL'); },
    set: function (value) { this.set('videoPreviewURL', value); }
});

Object.defineProperty(VideoWidgetCard.prototype, 'ParsedSuccessfully', {                                                  // data from parsing
    get: function () { return this.get('parsedSuccessfully'); },
    set: function (value) { this.set('parsedSuccessfully', value); }
});

Object.defineProperty(VideoWidgetCard.prototype, 'IsStream', {                                                  // is it is a stream (maybe StreamHasNotStartedYet)
    get: function () { return this.get('isStream'); },
    set: function (value) { this.set('isStream', value); }
});

Object.defineProperty(VideoWidgetCard.prototype, 'StreamStartDate', {                                           // get: string formatted value, set: by string that suits Date() constructor, data from parsing
    get: function () { return this.get('streamStartDateFormatted'); },
    set: function (value)
    {
        if (value)
        {
            let date = new Date(value);
            this.set({ 'streamStartDate': date, 'streamStartDateFormatted': DateTimeUtils.formatDate(date, "DD.MM.YYYY HH:mm") });
        }
    }
});

Object.defineProperty(VideoWidgetCard.prototype, 'StreamEndDate', {                                             // end of the live stream - Date() object
    get: function () { return this.get('streamEndDate'); },
    set: function (value) { this.set('streamEndDate', value); }
});

Object.defineProperty(VideoWidgetCard.prototype, 'StreamHasNotStartedYet', {                                    // data from parsing
    get: function () { return this.get('streamHasNotStartedYet'); },
    set: function (value) { this.set('streamHasNotStartedYet', value); }
});

Object.defineProperty(VideoWidgetCard.prototype, 'StreamHasBeenAlreadyEnded', {                                 // data from parsing
    get: function () { return this.get('streamHasBeenAlreadyEnded'); },
    set: function (value) { this.set('streamHasBeenAlreadyEnded', value); }
});

Object.defineProperty(VideoWidgetCard.prototype, 'IsLIVE', {                                                    // is broadcasting LIVE on air right now
    get: function () { return this.get('isLive'); },
    set: function (value) { this.set('isLive', value); }
});

Object.defineProperty(VideoWidgetCard.prototype, 'IsLiveFromBO', {                                              // bool from Back Office
    get: function () { return this.get('isLiveFromBO'); },
    set: function (value) { this.set('isLiveFromBO', value); }
});

Object.defineProperty(VideoWidgetCard.prototype, 'ExternalLinkName', {                                          // purpose remains unclear
    get: function () { return this.externalLinkName; },
    set: function (value) { this.externalLinkName = value; }
});

Object.defineProperty(VideoWidgetCard.prototype, 'ExternalLinkID', {                                            // purpose remains unclear
    get: function () { return this.externalLinkID; },
    set: function (value) { this.externalLinkID = value; }
});

Object.defineProperty(VideoWidgetCard.prototype, 'ExternalLinkAvailableUntil', {                                // purpose remains unclear
    get: function () { return this.externalLinkAvailableUntil; },
    set: function (value) { this.externalLinkAvailableUntil = value; }
});

Object.defineProperty(VideoWidgetCard.prototype, 'UseInternalBrowser', {
    get: function () { return this.useInternalBrowser; },
    set: function (value) { this.useInternalBrowser = value; }
});