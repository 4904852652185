// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts";
import { Rectangle } from "../../Commons/Geometry.ts";
import { HtmlScroll } from "../../Commons/HtmlScroll.js";
import { ControlsUtils } from "../UtilsClasses/ControlsUtils.ts";
import { TerceraMultiComboBoxTemplate, TerceraMultiComboBoxPopupTemplate } from "../../templates.js";
import { Control } from "./Control.js";
import { ContainerControl } from "./ContainerControl.js";
import { TerceraCheckBox } from "./TerceraCheckBox.js";
import { TerceraMenu } from "./TerceraMenu.ts";
import { multiComboBoxPopupMenuHandler } from "../../Utils/AppHandlers.js";

export let TerceraMultiComboBox = Control.extend({
    data: function ()
    {
        return {
            items: null,
            allItemsSelectedText: '',
            noItemsSelectedText: '',
            noItemsText: '',
            fewItemsSelectedPostfixText: '',
            hideAll: false,
            useLastItem: false,
        };
    },
    computed: {
        selectionText: {
            get: function ()
            {
                var items = this.get('items');
                if (!items || items.length === 0) { return this.get('noItemsText') };

                var lastCheckedIdx = -1;
                var checkedCount = 0;
                var len = items.length;

                for (var i = 0; i < len; i++)
                {
                    if (!items[i].checked) continue;
                    lastCheckedIdx = i;
                    checkedCount++;
                }

                if (checkedCount === len)
                    return this.get('allItemsSelectedText');
                else if (checkedCount === 0)
                    return this.get('noItemsSelectedText');
                else if (checkedCount === 1)
                    return items[lastCheckedIdx].text;
                else
                    return checkedCount + '/' + len + ' ' +
                        this.get('fewItemsSelectedPostfixText');
            },
            set: function () { }
        }
    },
    template: TerceraMultiComboBoxTemplate,
    scrollIsShown: false,
    c: false
});

TerceraMultiComboBox.prototype.getType = function () { return "TerceraMultiComboBox" }

TerceraMultiComboBox.prototype.oninit = function ()
{
    Control.prototype.oninit.apply(this);
    this.on('showPopup', this.showPopup);
    this.popupCallBack = this.popupCallBack.bind(this);
    this.observe('items', this.onItemsChanged);
};

TerceraMultiComboBox.prototype.onItemsChanged = function ()
{
    this.fire(TerceraMultiComboBox.Events.ValueChange);
};

TerceraMultiComboBox.prototype.lostFocus = function ()
{
    Control.prototype.lostFocus.apply(this);
    this.isPopupShown = false;
    this.getPopup().Hide();
};

TerceraMultiComboBox.prototype.setBooleans = function (boolArr)
{
    let i = -1;
    this.set('items', this.get('items').map(element =>
    {
        element.checked = boolArr[i++]
        return element;
    }));
};

TerceraMultiComboBox.prototype.showPopup = function ()
{
    var items = this.get('items');
    if (!this.get('enabled') || !items || !items.length)
        return;

    if (this.isPopupShown)
    {
        this.isPopupShown = false;
        this.getPopup().Hide();
        return;
    }

    var loc = ControlsUtils.getAbsoluteLocation(this);

    this.isPopupShown = true;
    this.getPopup().Show(
        items,
        loc.X,
        loc.Y + 26,
        this.find("*").offsetWidth,
        this.popupCallBack,
        this.get("hideAll"),
        this.get("useLastItem"));
};

TerceraMultiComboBox.prototype.popupCallBack = function (popupItems)
{
    this.isPopupShown = false;

    let popupItemsByText = {};
    if (popupItems)
    {   // converting array to object for instant item search
        popupItems.forEach(pI => { if (pI.text) { popupItemsByText[pI.text] = pI; } });
    }

    let items = this.get('items');
    for (let i = 0; i < items.length; i++)
    {
        const popupItem = popupItemsByText[items[i].text];
        if (popupItem != null)
        {    // popupItem is user's selection, so we should use its value
            items[i].checked = popupItem.checked;
        }
    }

    this.set('items', items); // this.update('items');
};

TerceraMultiComboBox.prototype.getPopup = function ()
{
    return multiComboBoxPopupMenuHandler
}

// Events
TerceraMultiComboBox.Events = { ValueChange: 'ValueChange' };
Object.freeze(TerceraMultiComboBox.Events);

//#region TerceraMultiComboBoxPopup

export let TerceraMultiComboBoxPopup = ContainerControl.extend({
    el: '#multiComboBoxContainer',
    data: function ()
    {
        return {
            allText: '',
            items: null,
            visible: false,
            useLastItem: false,
            checkBoxCheckedState: TerceraCheckBox.TerceraCheckBoxCheckedStates.None
        }
    },
    template: TerceraMultiComboBoxPopupTemplate,
    lastIndex: null
});

TerceraMultiComboBoxPopup.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.call(this)
    this.observe('items', this.onItemsChanged)
    this.observe('checkBoxCheckedState', this.onCheckBoxCheckedStateChanged)
}

TerceraMultiComboBoxPopup.prototype.onItemsChanged = function (items)
{
    if (!items)
    {
        this.set('checkBoxCheckedState', TerceraCheckBox.TerceraCheckBoxCheckedStates.None)
        return
    }

    let checkedCount = 0
    let len = items.length
    let lastIndex = null;
    for (let i = 0; i < len; i++)
    {
        let item = items[i]
        if (item.checked)
        {
            checkedCount++
            lastIndex = i
        }
    }
    if (!checkedCount && this.lastIndex != null && this.get("useLastItem"))
    {
        items[this.lastIndex].checked = true
        this.set("items", items)
        return
    }
    this.lastIndex = lastIndex;

    if (checkedCount === 0)
        this.set('checkBoxCheckedState', TerceraCheckBox.TerceraCheckBoxCheckedStates.None)
    else if (checkedCount === len)
        this.set('checkBoxCheckedState', TerceraCheckBox.TerceraCheckBoxCheckedStates.Checked)
    else
        this.set('checkBoxCheckedState', TerceraCheckBox.TerceraCheckBoxCheckedStates.Indeterminate)
}

TerceraMultiComboBoxPopup.prototype.onCheckBoxCheckedStateChanged = function (val)
{
    if (val === TerceraCheckBox.TerceraCheckBoxCheckedStates.Indeterminate)
        return

    let items = this.get('items')
    if (!items)
        return

    let check = val === TerceraCheckBox.TerceraCheckBoxCheckedStates.Checked

    let valueChanged = false

    for (let i = 0, len = items.length; i < len; i++)
    {
        let item = items[i]
        if (!item || item.checked === check)
            continue

        item.checked = check
        valueChanged = true
    }

    if (valueChanged)
        this.update('items')
}

TerceraMultiComboBoxPopup.prototype.Hide = function ()
{
    this.lastIndex = null;
    if (this.callBack)
    {
        this.callBack(this.get('items'));
        delete this.callBack;
    }

    this.set('items', null)
    this.set('visible', false)
    this.set('hideAll', false)
    this.set('useLastItem', false)

    if (this.scrollIsShown)
    {
        HtmlScroll.removeScroll(this.find('.items-container'));
        this.scrollIsShown = false;
    }
};

TerceraMultiComboBoxPopup.prototype.Show = function (items, x, y, width, callBack, hideAll, useLastItem)
{
    this.localize();
    this.set('items', items);
    this.set('hideAll', hideAll)
    this.set('useLastItem', useLastItem)
    this.callBack = callBack;
    this.set('visible', true);

    var height = items ? (items.length + 1) * 25 + 4 : 0;
    if (hideAll)
        height -= 25;
    if (height > 280)
    {
        height = 280;
        this.scrollIsShown = true;
    }

    var newLoc = TerceraMenu.CorrectPopupLocation(
        new Rectangle(x, y, width, height));

    this.setBounds(newLoc.newX, newLoc.newY, width, height);

    if (this.scrollIsShown)
        HtmlScroll.addScroll(this.find('.items-container'));
};

TerceraMultiComboBoxPopup.prototype.localize = function ()
{
    this.set('allText', Resources.getResource('PopupMultiCkeckBox.All'));
};

TerceraMultiComboBoxPopup.prototype.ProcessOnMouseDown = function (caller)
{
    let callerParent = caller.parent

    if (callerParent !== this)
        this.Hide();
};

let multiComboBoxPopup = multiComboBoxPopupMenuHandler;
multiComboBoxPopup.Init = () =>
{
    let _multiComboBoxPopup = new TerceraMultiComboBoxPopup()
    multiComboBoxPopup.Show = _multiComboBoxPopup.Show.bind(_multiComboBoxPopup);
    multiComboBoxPopup.Hide = _multiComboBoxPopup.Hide.bind(_multiComboBoxPopup);
    multiComboBoxPopup.ProcessOnMouseDown = _multiComboBoxPopup.ProcessOnMouseDown.bind(_multiComboBoxPopup);
    multiComboBoxPopup.isShowed = () => { return _multiComboBoxPopup.get("visible") };
}

//#endregion