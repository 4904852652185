// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { ErrorInformationStorage } from '../../../Commons/ErrorInformationStorage';
import { Point } from '../../../Commons/Geometry';
import { LayersEnum, TerceraChartBaseRenderer } from '../TerceraChartBaseRenderer';
import { ProMath } from '../../Tools/ToolView';
import { SelectionState } from '../../Tools/Selection';
import { ToolViewFactory } from '../../Tools/Utils/ToolViewFactory';
import { MouseButtons } from '../../../Controls/UtilsClasses/ControlsUtils';
import { TerceraChartToolRenderer } from './TerceraChartToolRenderer';
import { type TerceraChartBase } from '../../TerceraChartBase';

export class TerceraChartNewToolRenderer extends TerceraChartBaseRenderer {
    public StayInDrawingMode: boolean = false;

    private toolView: any;
    private readonly NewToolStrategy: any;
    private lastAddedPoint: any;
    private windowUnderCursor: any;
    assignLayer: LayersEnum;

    static NEW_POINT_DX: number = 2;

    constructor (chart: TerceraChartBase, strategy: any) {
        super(chart);
        this.NewToolStrategy = strategy;
        this.lastAddedPoint = undefined;
        this.windowUnderCursor = undefined;
        this.assignLayer = LayersEnum.Tools;
        this.SetClassName('TerceraChartNewToolRenderer');
    }

    ToolView () {
        return this.toolView;
    }

    CanProcessAction (window: any, mouseLocation: Point) {
        if (this.NewToolStrategy.CurrentTool() == null || window == null) {
            return false;
        }

        const w = this.NewToolStrategy.ToolWindow() || this.windowUnderCursor;
        if (w !== window) {
            return false;
        }

        if (!mouseLocation.IsEmpty() && !window.ClientRectangle.Contains(mouseLocation.X, mouseLocation.Y)) {
            return false;
        }

        return true;
    }

    Draw (gr: any, window: any, windowsContainer: any, advParams: any = null) {
        if (!this.CanProcessAction(window, Point.Empty())) {
            return;
        }

        const param = advParams;
        const cashItemSeries = param.mainPriceRenderer.Series;
        if (cashItemSeries == null) {
            return;
        }

        if (this.toolView == null || this.toolView.dataCacheTool != this.NewToolStrategy.CurrentTool() || this.toolView.screenPoints.length < this.toolView.dataCacheTool.PointLevel()) {
            this.toolView = ToolViewFactory.CreateToolView(this.NewToolStrategy.CurrentTool());
            this.toolView.CurrentSelection.CurrentState = SelectionState.Hovered;
        }

        const clientRect = window.ClientRectangle;

        gr.save();
        gr.beginPath();
        gr.rect(clientRect.X, clientRect.Y, clientRect.Width, clientRect.Height);
        gr.clip();

        try {
            this.toolView.UpdateScreenPoints(window, param.TerceraChart.mainWindow == window ? cashItemSeries : null);

            if (
                this.toolView.screenPoints[0][0] >= ProMath.infinity ||
                this.toolView.screenPoints[0][1] >= ProMath.infinity ||
                this.toolView.screenPoints[0][0] <= ProMath.infinityMinus ||
                this.toolView.screenPoints[0][1] <= ProMath.infinityMinus ||
                this.toolView.dataCacheTool.Points[0][0] <= 0
            ) {
                // точка далеко вне, не вызываем рисование
            } else {
                this.toolView.Draw(gr, window, param);
            }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
        }

        gr.restore();
    }

    ProcessMouseUp (e: any) {
        if (!this.CanProcessAction(e.window, e.Location)) {
            return false;
        }

        if (e.Button == MouseButtons.Left) {
            const curPoint = e.Location;
            const tool = this.NewToolStrategy.CurrentTool();
            if (
                Math.abs(curPoint.X - this.lastAddedPoint.X) + Math.abs(curPoint.Y - this.lastAddedPoint.Y) > TerceraChartNewToolRenderer.NEW_POINT_DX ||
                (tool != null && tool.MaxPointsCount() == 1)
            ) {
                this.NewToolStrategy.AddPoint(e, this.StayInDrawingMode);
            }
            return true;
        } else if (e.Button == MouseButtons.Right) {
            this.NewToolStrategy.Cancel();
            return true;
        }
        return false;
    }

    ProcessMouseDown (e: any) {
        if (!this.CanProcessAction(e.window, e.Location)) {
            return false;
        }

        if (e.Button == MouseButtons.Left) {
            const tool = this.NewToolStrategy.CurrentTool();
            if ((tool != null && tool.MaxPointsCount() == 1) || this.NewToolStrategy.AddPoint(e, this.StayInDrawingMode)) {
                this.lastAddedPoint = e.Location;
                if (this.ToolView() != null && e.window != null) {
                    const mpr = e.window.GetMainThreadRenderer('TerceraChartMainPriceRenderer');
                    this.ToolView().UpdateAdditionalPoints(e.window, -1, mpr != null ? mpr.Series : null);
                }
                return true;
            }
        } else if (e.Button == MouseButtons.Right && this.NewToolStrategy.CurrentTool() != null) {
            return true;
        }
        return false;
    }

    ProcessMouseMove (e: any) {
        this.windowUnderCursor = e.window;

        if (!this.CanProcessAction(this.windowUnderCursor, e.Location)) {
            return false;
        }

        const current = e.Location;
        if (!TerceraChartToolRenderer.CheckValidRange(this.windowUnderCursor, current.X, current.Y)) {
            return false;
        }

        if (!this.NewToolStrategy.ProcessPosition(e)) {
            return false;
        } else if (this.windowUnderCursor != null && this.ToolView() != null) {
            const mpr = this.windowUnderCursor.GetMainThreadRenderer('TerceraChartMainPriceRenderer');
            this.ToolView().UpdateAdditionalPoints(this.windowUnderCursor, -1, mpr != null ? mpr.Series : null);
        }
        e.NeedRedraw = LayersEnum.Tools;
        return true;
    }

    ApplyNewSnapMode (useSnap: boolean) {
        const snapable = this.NewToolStrategy;
        if (snapable != null) {
            snapable.ApplyNewSnapMode(useSnap);
        }
    }
}

export default TerceraChartNewToolRenderer;
