// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { type PFixFieldSet } from '../../Fields/PFixFieldSet';
import { FieldsFactory } from '../../Factory/FieldsFactory';
import { GainerLoserData } from '../../../../../Commons/cache/Algorithm/GainersLosers/GainerLoserData';
import { DirectAlgorithmGainersLosersMessage } from '../../../../../Utils/DirectMessages/DirectMessagesImport';
import { AlgorithmGainersLosersDelayDataGroup, AlgorithmGainersLosersExchangeGroup, AlgorithmGainersLosersInstrumentGroup } from '../../Groups/GroupsImport';
import { MessageProcessorBase } from '../MessageProcessorBase';

export class AlgorithmGainersLosersMessageProcessor extends MessageProcessorBase {
    public override decode (fs: PFixFieldSet): DirectAlgorithmGainersLosersMessage[] {
        const msg = new DirectAlgorithmGainersLosersMessage();
        const ff = FieldsFactory;

        msg.AlgorithmId = fs.GetValue(ff.FIELD_ALGORITHM_ID);
        msg.AlgorithmName = fs.GetValue(ff.FIELD_ALGORITHM_NAME);
        msg.AlgorithmTop = fs.GetValue(ff.FIELD_ALGORITHM_TOP);

        msg.AlgorithmGainersLosersExchangeGroups = fs.GetGroups(ff.ALGORITHM_GAINERS_LOSERS_EXCHANGE_GROUP, AlgorithmGainersLosersExchangeGroup);
        msg.AlgorithmGainersLosersDelayDataGroups = fs.GetGroups(ff.ALGORITHM_GAINERS_LOSERS_DELAY_DATA_GROUP, AlgorithmGainersLosersDelayDataGroup);

        for (const gainersLosersEchangeGroup of msg.AlgorithmGainersLosersExchangeGroups) {
            for (const gainersLosersInstrumentGroup of gainersLosersEchangeGroup.GetGroups(ff.ALGORITHM_GAINERS_LOSERS_INSTRUMENT_GROUP, AlgorithmGainersLosersInstrumentGroup)) {
                msg.GainersLosersData.push(this.CreateGainersLosersData(gainersLosersEchangeGroup.ExchangeName, gainersLosersInstrumentGroup, false));
            }
        }

        const delayedGroup: AlgorithmGainersLosersDelayDataGroup = msg.AlgorithmGainersLosersDelayDataGroups[0];
        if (delayedGroup != null) {
            for (const gainersLosersDelayExchangeGroup of delayedGroup.DelayExchangeGroups) {
                for (const gainerLoserDelayData of gainersLosersDelayExchangeGroup.GetGroups(ff.ALGORITHM_GAINERS_LOSERS_INSTRUMENT_GROUP, AlgorithmGainersLosersInstrumentGroup)) {
                    msg.GainersLosersData.push(this.CreateGainersLosersData(gainersLosersDelayExchangeGroup.ExchangeName, gainerLoserDelayData, true));
                }
            }
        }

        return [msg];
    }

    private CreateGainersLosersData (marketDataExchange: string, group: AlgorithmGainersLosersInstrumentGroup, isDelayed: boolean): GainerLoserData {
        const data = new GainerLoserData(group.Behavior);

        data.InstrumentName = group.InstrumentName;
        data.TradableId = group.TradableInsID;
        data.InstrumentId = group.InstrumentId;
        data.InstrumentGroupId = group.InstrumentTypeId;
        data.MarketDataExchange = marketDataExchange;
        data.TradingExchange = group.TradingExchange;
        data.RouteId = group.RouteID;
        data.InterestChange = group.InterestChange;
        data.LastPrice = group.LastPrice;
        data.IsDelayed = isDelayed;
        data.Volume = group.Volume;

        return data;
    }
}
