// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DataCacheToolType } from '../DataCacheToolEnums';
import { FillableDataCacheTool } from './FillableDataCacheTool';

export class RectangleDataCacheTool extends FillableDataCacheTool {
    constructor () {
        super();
        this.ToolType = DataCacheToolType.Rectangle;
        this.InitPoints(2);
    }

    /**
     * Gets the minimum points count required for the tool
     * @returns {number} - Minimum points count
     */
    MinPointsCount (): number {
        return 2;
    }

    /**
     * Gets the maximum points count required for the tool
     * @returns {number} - Maximum points count
     */
    MaxPointsCount (): number {
        return 2;
    }
}
