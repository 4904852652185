// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { AlgorithmGainersLosersExchangeGroup, PFixFieldSet } from '../../GroupsImport';
import { FieldsFactory } from '../../../Factory/FieldsFactory';

export class AlgorithmGainersLosersDelayDataGroup extends PFixFieldSet {
    constructor (pfixFieldSet: PFixFieldSet) {
        super(FieldsFactory.ALGORITHM_GAINERS_LOSERS_DELAY_DATA_GROUP, null, pfixFieldSet);
    }

    get DelayExchangeGroups (): AlgorithmGainersLosersExchangeGroup[] {
        return super.GetGroups(FieldsFactory.ALGORITHM_GAINERS_LOSERS_EXCHANGE_GROUP, AlgorithmGainersLosersExchangeGroup);
    }
}
