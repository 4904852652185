// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PriceType } from "../../../../../Utils/History/CashItemUtils.ts";
import { MAMode, MODE_MAIN, MODE_MINUSDI, MODE_PLUSDI } from "../../IndicatorConstants.ts";
import { ExpandDoubleVector } from "../../DoubleMatrix.ts";
import { IndicatorFunctions } from "../../IndicatorFunctions.ts";
import { iBuildInIndicator } from "../../iBuildInIndicator.ts";

export let iADXEx = function (maPeriod, priceType, maMode)
{
    iBuildInIndicator.call(this, 3);
    this.FPriceType = priceType;
    this.FMaMode = maMode;
    this.FMaPeriod = maPeriod;
    this.FDrawBegin = (maMode == MAMode.EMA) ? 1 : this.FMaPeriod;
    this.ARRAY_PDM = new ExpandDoubleVector();
    this.ARRAY_MDM = new ExpandDoubleVector();
    this.ARRAY_DX = new ExpandDoubleVector();
}

iADXEx.prototype = Object.create(iBuildInIndicator.prototype)

Object.defineProperty(iADXEx.prototype, 'Name',
    {
        get: function () { return 'iADXEx' }
    })

Object.defineProperty(iADXEx.prototype, 'Key',
    {
        get: function () { return this.DefaultKey + this.FMaPeriod + this.FMAMethod + this.FPriceType; }
    })

iADXEx.prototype.NextBar = function (callBound)
{
    iBuildInIndicator.prototype.NextBar.call(this, callBound)
    this.ARRAY_PDM.Add(0.0);
    this.ARRAY_MDM.Add(0.0);
    this.ARRAY_DX.Add(0.0);
}
iADXEx.prototype.Refresh = function (count, newThread)
{
    this.ma.Dispose()
    this.ma = new ExpandDoubleVector();
    iBuildInIndicator.prototype.Refresh.call(this, count, newThread)
}

iADXEx.prototype.OnQuote = function (ci, callBound, callFromRefresh)
{
    this.ARRAY_PDM[this.ARRAY_PDM.Length - 1] = 0;
    this.ARRAY_MDM[this.ARRAY_MDM.Length - 1] = 0;
    this.ARRAY_DX[this.ARRAY_DX.Length - 1] = 0;
    let Parent = this.Parent
    if (!Parent)
        return
    if (Parent == null || this.FCount == 1)
        return;

    let hi = this.GetPrice(PriceType.High, 0);
    let lo = this.GetPrice(PriceType.Low, 0);

    // Вычисление направления движения (DM)
    let plusDM = hi - this.GetPrice(PriceType.High, 1);
    if (plusDM < 0.0)
        plusDM = 0.0;
    let minusDM = this.GetPrice(PriceType.Low, 1) - lo;
    if (minusDM < 0.0)
        minusDM = 0.0;
    if (plusDM == minusDM)
        plusDM = minusDM = 0.0;
    if (plusDM > minusDM)
        minusDM = 0.0;
    else
        plusDM = 0.0;


    // Вычисление индексов направлений DI
    let prevClose = this.GetPrice(this.FPriceType, 1);
    let TR = Math.max(hi - lo, Math.max(Math.abs(prevClose - hi), Math.abs(prevClose - lo)));
    if (TR != 0.0)
    {
        this.ARRAY_PDM[this.ARRAY_PDM.Length - 1] = 100 * plusDM / TR;
        this.ARRAY_MDM[this.ARRAY_MDM.Length - 1] = 100 * minusDM / TR;
    }

    if (this.FCount < this.FDrawBegin)
        return;
    let plusDI = IndicatorFunctions.CallMovingFunction(this.FMaMode, this.ARRAY_PDM, this.FMaPeriod, 1);
    let minusDI = IndicatorFunctions.CallMovingFunction(this.FMaMode, this.ARRAY_MDM, this.FMaPeriod, 1);
    // Вычисление индексе движения DX (ADX)
    let sumDI = plusDI + minusDI;
    if (sumDI != 0)
        this.ARRAY_DX[this.ARRAY_DX.Length - 1] = 100.0 * Math.abs((plusDI - minusDI) / sumDI);

    let adx = IndicatorFunctions.CallMovingFunction(this.FMaMode, this.ARRAY_DX, this.FMaPeriod, 1);
    // Setting values
    this.SetValue(MODE_MAIN, 0, adx);
    this.SetValue(MODE_MINUSDI, 0, minusDI);
    this.SetValue(MODE_PLUSDI, 0, plusDI);
}

export let iADX = function (maPeriod, maMode)
{
    iADXEx.call(this, maPeriod, PriceType.Close, maMode)
}
iADX.prototype = Object.create(iADXEx.prototype)

Object.defineProperty(iADX.prototype, 'Name',
    {
        get: function () { return 'iADX' },
        set: function () { }
    })


export let iADXEma = function (maPeriod, maMode)
{
    iADXEx.call(this, maPeriod, PriceType.Close, MAMode.EMA)
    this.K = 2.0 / (maPeriod + 1.0);
    this.adx = 0;
    this.plusDI = 0;
    this.minusDI = 0;
}
iADXEma.prototype = Object.create(iADXEx.prototype);

Object.defineProperty(iADXEma.prototype, 'Name',
    {
        get: function () { return 'iADXEma' },
        set: function () { }
    })
iADXEma.prototype.Refresh = function (count, newThread)
{
    this.adx = 0;
    this.plusDI = 0;
    this.minusDI = 0;
    iBuildInIndicator.prototype.Refresh.call(this, count, newThread)
}

iADXEma.prototype.OnQuote = function (ci, callBound, callFromRefresh)
{
    let Parent = this.Parent
    if (!Parent)
        return
    if (Parent == null || this.FCount == 1)
        return;

    let hi = this.GetPrice(PriceType.High, 0);
    let lo = this.GetPrice(PriceType.Low, 0);

    // Вычисление направления движения (DM)
    let plusDM = hi - this.GetPrice(PriceType.High, 1);
    if (plusDM < 0.0)
        plusDM = 0.0;
    let minusDM = this.GetPrice(PriceType.Low, 1) - lo;
    if (minusDM < 0.0)
        minusDM = 0.0;
    if (plusDM == minusDM)
        plusDM = minusDM = 0.0;
    if (plusDM > minusDM)
        minusDM = 0.0;
    else
        plusDM = 0.0;


    // Вычисление индексов направлений DI
    let prevClose = this.GetPrice(this.FPriceType, 1);
    let TR = Math.max(hi - lo, Math.max(Math.abs(prevClose - hi), Math.abs(prevClose - lo)));
    if (TR != 0.0)
    {
        this.plusDI += this.K * (100 * plusDM / TR - this.plusDI);
        this.minusDI += this.K * (100 * minusDM / TR - this.minusDI);
    }
    else
    {
        this.plusDI += this.K * (- this.plusDI);
        this.minusDI += this.K * (- this.minusDI);
    }

    // Вычисление индексе движения DX (ADX)
    let sumDI = this.plusDI + this.minusDI;
    if (sumDI != 0)
        this.adx += this.K * (100.0 * Math.abs((this.plusDI - this.minusDI) / sumDI) - this.adx);
    else
        this.adx += this.K * (-this.adx);

    // Setting values
    this.SetValue(MODE_MAIN, 0, this.adx);
    this.SetValue(MODE_PLUSDI, 0, this.plusDI);
    this.SetValue(MODE_MINUSDI, 0, this.minusDI);
}