// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DirectControlAlertCommandMessage } from '../../../../Utils/DirectMessages/DirectMessagesImport';
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class ControlAlertCommandMessageProcessor extends MessageProcessorBase {
    public override decode (fs: PFixFieldSet): DirectControlAlertCommandMessage[] {
        const msg = new DirectControlAlertCommandMessage();

        msg.AlertId = fs.GetValue(FieldsFactory.FIELD_ALERT_ID);
        msg.AlertCommand = fs.GetValue(FieldsFactory.FIELD_ALERT_COMMAND);

        return [msg];
    }
}
