// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts";
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";
import { HolidayItem } from "../cache/HolidayItem.ts";
import { Control } from "../elements/Control.js";
import { TerceraMenu } from "../elements/TerceraMenu.ts";
import { PanelNames } from "../UtilsClasses/FactoryConstants.ts";
import { ApplicationPanelNew } from "./ApplicationPanelNew.js";
import { Rectangle } from "../../Commons/Geometry.ts";
import { DateTimeUtils } from "../../Utils/Time/DateTimeUtils.ts";
import { DayType } from "../../Utils/Session/Sessions.ts";

export let InstrumentHolidaysScreen = ApplicationPanelNew.extend({
    data: function ()
    {
        return {
            showHeader: true,
            showFooter: false,
            movable: false,
            resizable: false,
            dockablePanel: false,
            width: 296,
            height: 220,
            zIndex: 1301,
            instrument: null
        };
    }
});

InstrumentHolidaysScreen.prototype.getType = function ()
{
    return PanelNames.InstrumentHolidaysScreen;
};

InstrumentHolidaysScreen.prototype.updatePanelHeader = function ()
{

};

InstrumentHolidaysScreen.prototype.oninit = function ()
{
    ApplicationPanelNew.prototype.oninit.apply(this);
    this.observe('visible', this.onVisibleChanged);
    this.observe('instrument', this.populateHolidayList, { init: false });
};

InstrumentHolidaysScreen.prototype.oncomplete = function ()
{
    ApplicationPanelNew.prototype.oncomplete.apply(this);
    this.populateHolidayList();
};

InstrumentHolidaysScreen.prototype.layoutTable = function ()
{
    if (!this.quickTableRactive === null)
        return;

    this.quickTableRactive.setBounds(
        0,
        0,
        this.get('width'),
        this.get('height') - 20);
};

InstrumentHolidaysScreen.prototype.onVisibleChanged = function (visible)
{
    if (visible)
        Control.Ticker.Subscribe(this.onControlGlobalTick, this);
    else
        Control.Ticker.UnSubscribe(this.onControlGlobalTick, this);
};

InstrumentHolidaysScreen.prototype.onControlGlobalTick = function ()
{
    this.TickAsync();
};

InstrumentHolidaysScreen.prototype.lostFocus = function ()
{
    ApplicationPanelNew.prototype.lostFocus.apply(this);
    this.set('visible', false);
};

InstrumentHolidaysScreen.prototype.onCloseButtonClick = function ()
{
    this.set('visible', false);
};

InstrumentHolidaysScreen.show = function (instrument, x, y)
{
    var scr = InstrumentHolidaysScreen.getInstance();
    scr.themeChange();
    scr.localize();
    scr.set({
        header: Resources.getResource("InstrumentDetailsPanel.Holidays list"),
        instrument: instrument
    });

    var newLocation = TerceraMenu.CorrectPopupLocation(new Rectangle(
        x,
        y,
        scr.get('width'),
        scr.get('height')));

    scr.setLocation(newLocation.newX, newLocation.newY);

    scr.set('visible', true);
    scr.setFocus();
};

InstrumentHolidaysScreen.prototype.populateHolidayList = function ()
{
    var scr = InstrumentHolidaysScreen.getInstance(),
        instrument = scr.get('instrument');

    if (scr.quickTableRactive)
        scr.quickTableRactive.quickTable.ClearAll();

    let ts = instrument ? instrument.GetZeroTradingSession() : null
    if (!ts)
        return;

    let holiday = null;
    let std = new Date(ts.ServerTodayDateCorrected);
    std.setHours(0);
    const holidaysList = ts.getSortedHolidays();
    for (let i = 0; i < holidaysList.length; i++)
    {
        let dt = new Date(holidaysList[i].Date);
            dt.setHours(0)
            
        if (+dt >= +std)
        {
            holiday = holidaysList[i];
            holiday.formatDate = DateTimeUtils.formatDate(holiday.Date, "DD.MM.YYYY");
            holiday.shedule = InstrumentHolidaysScreen.holidayTypeToShedule(holiday.HolidayType);

            if (scr.quickTableRactive)
                scr.quickTableRactive.quickTable.AddItem(new HolidayItem(holiday.formatDate, holiday.Name, holiday.shedule));
        }
    }
};

InstrumentHolidaysScreen.holidayTypeToShedule = function (holidayType)
{
    switch (holidayType)
    {
        case DayType.NOT_WORKING:
            return Resources.getResource('InstrumentDetailsPanel.non trading day')
        case DayType.SHORTED:
            return Resources.getResource('InstrumentDetailsPanel.shortened')
        case DayType.WORKING:
        default:
            return Resources.getResource('InstrumentDetailsPanel.working')
    }
}

InstrumentHolidaysScreen.prototype.dispose = function ()
{
    ApplicationPanelNew.prototype.dispose.apply(this);
    Control.Ticker.UnSubscribe(this.onControlGlobalTick, this);
    instance = null;
};

let instance = null;
InstrumentHolidaysScreen.getInstance = function ()
{
    if (instance)
        return instance;

    var scr = new InstrumentHolidaysScreen();
    scr.set('visible', false);
    MainWindowManager.MainWindow.addControl(scr);

    instance = scr;
    return instance;
};