// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { TerceraNumeric } from "./TerceraNumeric.js";

export class QuickTableNumeric extends TerceraNumeric {
    constructor(data, callback, rect) {
        super();

        const editingInfo = data.cell.QuickTableEditingInfo;
        this.set({
            minValue: editingInfo.Min,
            maxValue: editingInfo.Max,
            step: editingInfo.Inc,
            decimalPrecision: editingInfo.DecimalPlaces
        });

        this.tag = data;
        this.callBack = callback;

        this.set('value', data.cell.value);
        this.setBounds(rect.X, rect.Y, rect.Width, rect.Height);
        super.set('isPosAbsolute', true);
        this.set('zIndex', 200);    // #118800
    }

    oncomplete() {
        super.oncomplete();
        this.setFocus();
    }

    processEnterKeyDown() {
        this.lostFocus();
    }

    lostFocus() {
        super.lostFocus();
        this.callBack(this.get('value'), this.tag);
    }
}