// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { Resources } from './properties/Resources';
import { Color, Font } from '../Commons/Graphics';
import { ErrorInformationStorage } from './ErrorInformationStorage';
import { TerceraLineStyleComboBoxUtils } from './UtilsClasses/TerceraTIFComboBoxUtils';
import { type ProductType } from '../Utils/Instruments/ProductType';

//
// Constructor
//
// #region
export class DynProperty {
    // Public fields main
    public name: string;
    public localizationKey: string;
    public value: any;
    public type: number;
    public group: string;
    public parentGroup: string;

    //
    // Ractive control
    //
    public AssignedControl: any = null;
    public Intervals: any = null;

    // Public fields optional
    public enabled: boolean;
    public visible: boolean;
    public tooltip: any;
    public sortIndex: number;
    public DynPropertyRelationType = DynPropertyRelationType.Enability;
    public enabilityValue: any;
    public assignedProperty: any[];
    public separatorGroup: string;
    public increment: number;
    public decimalPlaces: number;
    public minimalValue: number;
    public maximalValue: number;
    public objectVariants: any;
    public COMBOBOX_TYPE: number;
    public propertyComment: string;
    public coefficient: number;
    public error: any;
    public customCSS: any;

    public BeforeChangeEvent: any;
    public positionSizingIsVisible: boolean;
    public riskModeBtnText: string;
    public currencyRiskModeSuffix: string;
    public riskValue: any;
    public riskValueFormatted: string;
    public availableFundsChanged: boolean;

    public productType: ProductType;
    public selectedValue: { value: string, text: string };
    public hasError: any;
    public customLess: boolean;
    public customError: string;
    public localizationKey_type: string;
    public localizationKey_qty: string;

    public minimal: number;

    public callback: any;

    // Private fields (прячем в специальном объекте)
    private readonly PRIVATE: any = {};

    constructor (name?: string, value?, type?: number, group?: string, parentGroup?: string) {
        this.name = name;

        if (Resources.IsResourcePresent(name)) {
            this.localizationKey = name;
        } else {
            const locKey = 'property.' + name;
            if (Resources.IsResourcePresent(locKey)) {
                this.localizationKey = locKey;
            } else {
                this.localizationKey = name;
            }
        }

        this.value = value;
        this.type = (!!type || type === 0) ? type : DynProperty.UNKNOWN;
        this.group = group || '';
        this.parentGroup = parentGroup || DynProperty.PARAM_GROUP;

        this.populatePublicFieldsOptional();
    }

    public populatePublicFieldsOptional (): void {
    // Public fields optional
        this.enabled = true;
        this.visible = true;
        this.tooltip = undefined;
        this.sortIndex = 100;
        this.DynPropertyRelationType = DynPropertyRelationType.Enability;
        this.enabilityValue = null;
        this.assignedProperty = [];
        this.separatorGroup = '';
        this.increment = 1;
        this.decimalPlaces = 0;
        this.minimalValue = 0;
        this.maximalValue = 100;
        this.objectVariants = null;
        this.COMBOBOX_TYPE = -1;
        this.propertyComment = '';
        this.coefficient = -1;
        this.error = null;
        this.customCSS = null;

        this.BeforeChangeEvent = null;
    }

    // #endregion

    public static fromJson (json): DynProperty {
        try {
            const FType = parseInt(json.type);
            // Вытягиваем основные параметры
            const FName = json.name;
            const FGroup = json.group;
            const Value = json.value;
            let FValue = Value;
            const dp = new DynProperty(FName, Value, FType, FGroup);

            if (json.hasOwnProperty('sortIndex')) {
                const sortIndex = parseInt(json.sortIndex);
                if (!isNaN(sortIndex)) dp.sortIndex = sortIndex;
            }

            // Присваиваем параметр Value и подгружаем дополнительные параметры
            switch (FType) {
            case DynProperty.COLOR:
                FValue = DynProperty.colorInt32ToRGB(Value);
                break;
            case DynProperty.CSSTYLE:
            case DynProperty.LINEWIDTH:
            case DynProperty.LINESTICK:
            case DynProperty.SYMBOL:
            case DynProperty.CHART_WINDOW:
                FValue = parseInt(Value);
                FValue = !isNaN(FValue) ? FValue : 0;
                break;
            case DynProperty.STRING:
                FValue = Value;
                break;
            case DynProperty.INTEGER:
                FValue = parseInt(Value);
                FValue = !isNaN(FValue) ? FValue : 0;
                if (json.min !== undefined) {
                    dp.minimalValue = parseFloat(json.min);
                }
                if (json.max !== undefined) {
                    dp.maximalValue = parseFloat(json.max);
                }
                break;

            case DynProperty.DOUBLE:
            case DynProperty.DOUBLE_WITH_REFRESH_BUTTON:
                FValue = parseFloat(Value);
                FValue = !isNaN(FValue) ? FValue : 0;
                if (json.min !== undefined) {
                    dp.minimalValue = parseFloat(json.min);
                }
                if (json.max !== undefined) {
                    dp.maximalValue = parseFloat(json.max);
                }
                break;
            case DynProperty.BOOLEAN:
            case DynProperty.BOOLEAN_SOUND:
                if (!Value?.toLowerCase) {
                    FValue = Value;
                } else {
                    FValue = Value.toLowerCase() === 'true';
                }
                break;
            case DynProperty.COLOR_STYLE_WIDTH:
                if (Value != null) {
                    let c = Color.Empty;
                    let styleIndex = 0;
                    let widthIndex = 0;
                    let CheckBoxVisible = false;
                    let Checked = true;

                    const valuesStr = Value.split(';');
                    if (valuesStr.length > 0) {
                        CheckBoxVisible = (valuesStr[0]).toLowerCase() === 'true';
                        Checked = (valuesStr[1]).toLowerCase() === 'true';
                        c = DynProperty.colorInt32ToRGB(valuesStr[2]);
                        styleIndex = parseInt(valuesStr[3]);
                        widthIndex = parseInt(valuesStr[4]);
                    }

                    FValue = new ColorStyleWidth(c, styleIndex, widthIndex);
                    FValue.CheckBoxVisible = CheckBoxVisible;
                    FValue.Checked = Checked;
                }
                break;
            case DynProperty.COMBOBOX_COMBOITEM:
                FValue = json.valueType == DynProperty.INTEGER ? parseInt(Value) : Value;
                break;
            case DynProperty.COMBOBOX_COMBOITEM_TIF:{
                const _ar = Value.split(';');
                const _t = parseInt(_ar[0]);
                const _d = parseInt(_ar[1]);
                FValue = TerceraLineStyleComboBoxUtils.cretaComboItem(_t, new Date(_d));
                break;
            }
            case DynProperty.FONT:{
                const nameb = Value.indexOf('Name=') + 5;
                const namee = Value.indexOf(',', nameb);
                const fontName = Value.substring(nameb, namee);

                const sizeb = Value.indexOf('Size=') + 5;
                const sizee = Value.indexOf(',', sizeb);
                const fontHeight = Value.substring(sizeb, sizee);

                const fontWeight = json.bold === 'True' ? 'bold' : 'normal';

                FValue = new Font(parseFloat(fontHeight), fontName, fontWeight);

                break;
            }
            case DynProperty.ACCOUNT:
                FValue = Value;
                break;
            case DynProperty.INSTRUMENT:
                FValue = Value;
                break;
            case DynProperty.BOOL_NUMERIC:
                FValue = new BoolNumeric(json.checked == '1', parseFloat(Value));
                break;
            case DynProperty.PAIR_COLOR:
                FValue = new PairColor(
                    DynProperty.colorInt32ToRGB(Value.Color1),
                    DynProperty.colorInt32ToRGB(Value.Color2),
                    Value.ColorText1,
                    Value.ColorText2);
                FValue.DisableColor1 = Value.DisableColor1 === 'true';
                FValue.DisableColor2 = Value.DisableColor2 === 'true';
                break;
            case DynProperty.TRIO_COLOR:
            case DynProperty.TRIO_COLOR_GRADIENT:
                FValue = new TrioColor(
                    DynProperty.colorInt32ToRGB(Value.Color1),
                    DynProperty.colorInt32ToRGB(Value.Color2),
                    DynProperty.colorInt32ToRGB(Value.Color3),
                    Value.ColorText1,
                    Value.ColorText2,
                    Value.ColorText3);
                FValue.DisableColor1 = Value.DisableColor1 === 'true';
                FValue.DisableColor2 = Value.DisableColor2 === 'true';
                FValue.DisableColor3 = Value.DisableColor3 === 'true';
                break;
            case DynProperty.RADIOBUTTON_COMBOITEM:
            case DynProperty.RADIOBUTTON_COMBOITEM_WITH_LABEL:{
                const valueType = json.valueType;
                if (valueType == 'boolean') {
                    FValue = Value === '1';
                } else if (valueType == 'number') {
                    FValue = parseFloat(Value);
                } else {
                    FValue = Value;
                }
                break;
            }
            case DynProperty.INSTRUMENTS_DEFAULTS:
                FValue = Value;
                break;
            default:
                FValue = Value;
                break;
            }

            if (json.decimalPlaces !== undefined) {
                dp.decimalPlaces = parseInt(json.decimalPlaces);
            }

            if (json.increment !== undefined) {
                dp.increment = parseFloat(json.increment);
            }

            dp.value = FValue;

            // For indicators only.
            const variants = json.variants;
            if (variants) {
                const keys = Object.keys(variants);
                const len = keys.length;
                const result = [];
                for (let i = 0; i < len; i++) {
                    const val = variants[keys[i]];
                    result.push({ value: val, text: val });
                }
                dp.objectVariants = result;
            }

            if (json.comment) {
                dp.propertyComment = json.comment;
            }

            return dp;
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            console.log('DP err');
        }
    }

    // Константы класса
    // Переносить с КЛИЕНТА номера, новые начинаем с 1000
    // #region
    public static readonly UNKNOWN = -1; // Неизвестные параметр
    public static readonly INTEGER = 0; // тип - целое (int)
    public static readonly DOUBLE = 1; // тип - вещественное (double)
    public static readonly COLOR = 2; // тип - цвет (string)
    public static readonly STRING = 3; // тип - строка (string)
    public static readonly BOOLEAN = 4; // тип - булево (bool)
    public static readonly FONT = 26; // тип - шрифт (string)
    public static readonly COMBOBOX = 27; // тип - набор объектов в комбобоксе?
    public static readonly DATETIME = 5; // тип - датавремя (double)
    public static readonly SYMBOL = 6; // тип - символ (картинка) (int)
    public static readonly DATE = 7; // тип - дата (double)
    public static readonly TIME = 8; // тип - время (double)
    public static readonly CSSTYLE = 11; // тип - вид линии (int)
    public static readonly LINEWIDTH = 12; // тип - толщина линии (int)
    public static readonly LINESTICK = 14; // тип - виды прилипания (high,low,open,close) (int)
    public static readonly PASSWORD = 50; // тип - пароль
    public static readonly LINK = 51; // тип - ссылка
    public static readonly LINK_WITH_TEXT = 52; // тип - текст и ссылка (кнопка refresh)
    public static readonly BOOLEAN_TEXT = 53;// тип - boolean c текстом
    public static readonly INSTRUMENT = 55; // тип - выбор инструмента
    public static readonly ACCOUNT = 57; // тип - выбор акаунта
    public static readonly FILENAME = 17; // тип - Имя файла
    public static readonly FOLDERNAME = 170; // тип - Имя директории
    public static readonly CELLBUTTON = 174; // кнопка в таблице QuickTable
    public static readonly CHART_WINDOW = 180; // тип - user-control для индексации окон на чарт-панели
    public static readonly FIBLEVEL = 182; // тип - для установки значенийи видимости уровней фибоначчи
    public static readonly PAIR_COLOR = 183; // тип - 2 колор кнопки
    public static readonly COMBOBOX_COMBOITEM = 185; // комбобокс работающий с Utils.ComboItem
    public static readonly BOOL_NUMERIC = 186; // нумерик с чекбоксом
    public static readonly COLOR_STYLE_WIDTH = 187; // тип - установка цвета, стиля и размера линии
    public static readonly RADIOBUTTON_COMBOITEM = 188; // radiobutton работающий с Utils.ComboItem
    public static readonly RADIOBUTTON_COMBOITEM_WITH_LABEL = 189; // radiobuttons c label
    public static readonly BUTTON = 202; // кнопка для открытия скринов из скрина настроек
    public static readonly IMAGE_WEB = 209;
    public static readonly RANGECONTROL = 210; // контрол определяет время начала/конца
    public static readonly SPARKLINE = 211; // тип - кривая графика
    public static readonly SYMBOL_WITH_IMGS = 212; // тип - имя инструмента + цветной кружок + иконка инструмента (портфолио)
    public static readonly MARKET_CONSENSUS = 213; // тип - имя инструмента + проценты + цветная линия
    public static readonly TRIO_COLOR = 214; // тип - 3 колор кнопки
    public static readonly TRIO_COLOR_GRADIENT = 215;
    public static readonly DOUBLE_WITH_REFRESH_BUTTON = 216;

    /// <summary>
    /// prop.ObjectVariants = new object[] {
    /// new Utils.ComboItem(Resources.getResource("text1"), (int)enum.Value1),
    /// new Utils.ComboItem(Resources.getResource("text2"),  (int)enum.Value2),};
    /// </summary>

    public static readonly BOOLEAN_SOUND = 1000;//
    public static readonly COMBOBOX_COMBOITEM_TIF = 1001;//
    public static readonly INSTRUMENTS_DEFAULTS = 1002;
    public static readonly SLTP = 1003;

    public static readonly SEPARATOR = 1009;
    public static readonly GROUP_SEPARATOR = 1010;
    public static readonly ALERT_NOTIFICATION_SELECTOR = 1011;
    public static readonly BOOLEAN_EVT = 1012;

    public static readonly TRUNCATE_TEXT_ADDING_ELLIPSIS = 2000;

    public static readonly INFO_PICTURE = 2001;
    public static readonly INFO_PICTURE_RIGHT_AND_TEXT = 2002;
    public static readonly CLOSE_BUTTON = 2003;
    public static readonly EXPAND_SUPER_POSITION_BUTTON_AND_TEXT = 2004; // (FIFO) #80010
    public static readonly COMPONENT_OF_SUPER_POSITION_TEXT = 2005; // (FIFO) #80010
    public static readonly ORDER_BUTTON = 2006; // #86679
    public static readonly TRADE_BUTTON = 2007; // #87442
    public static readonly TRADING_CENTRAL_ITEM = 2008; // #86776
    public static readonly ITCHART_ADVANCED = 2009; // #87796
    public static readonly ALERT_BUTTONS_GROUP = 2010;
    public static readonly SELL_ASSET_BUTTON = 2011;
    public static readonly POSITION_SIZING = 2012; // #97813
    public static readonly PRODUCT_TYPE_AND_MQ = 2013; // #101641
    public static readonly ATTENTION_MSG = 2014; // #101641
    public static readonly DELAYED_PICTURE_RIGHT_AND_TEXT = 2015; // #112155
    public static readonly PLACE_BUTTON = 2016; // #117256

    public static readonly PARAM_GROUP = 'General';
    public static readonly VIEW_GROUP = 'View';
    public static readonly SOUNDS_GROUP = 'Sounds';
    public static readonly TRADING_DEFAULTS_GROUP = 'Trading Defaults';
    public static readonly CONFIRMATIONS_GROUP = 'Confirmations';
    public static readonly WARNINGS_GROUP = 'Warnings';
    public static readonly NOTIFICATIONS_GROUP = 'Notifications';
    public static readonly SCRIPT_PARAMETERS_GROUP = 'Parameters';
    public static readonly GENERAL_GROUP = 'General';
    public static readonly OPTION_CHAIN_GROUP = 'OptionChain';
    public static readonly ANALYZER_GROUP = 'Analyzer';
    public static readonly ANALYZER_INFO_WINDOW_GROUP = 'AnalyzerInfo';

    public static readonly NUMERICAL_PARAMETRS_GROUP = 'Points';

    public static readonly VISUAL_TRADING_GROUP = 'Visual Trading';
    public static readonly TIME_SCALE_GROUP = 'Time Scale';
    public static readonly PRICE_SCALE_GROUP = 'Price Scale';
    public static readonly DATA_BOX_GROUP = 'Data Box';
    public static readonly DATA_STYLE_GROUP = 'Data Style';
    public static readonly CHART_OE_GROUP = 'Chart OE';
    public static readonly FXCELL_GROUP = 'FX Cell';
    public static readonly ALERTS_GROUP = 'Alerts';
    public static readonly VOLUME_GROUP = 'Volume';
    public static readonly BRANDING_GROUP = 'Branding';

    public static readonly VISUAL_GROUP = 'View';
    // public static readonly SOUNDS_GROUP = "sounds_param";
    public static readonly COLORS_GROUP = 'colors_param';
    public static readonly HIDDEN_GROUP = 'hidden';
    public static readonly CONNECTION_GROUP = 'Mail Server';
    public static readonly INSTRUMENT_GROUP = 'Instrument Types';
    public static readonly SEPARATOR_GROUP1 = ' ';
    public static readonly SEPARATOR_GROUP2 = '  ';
    public static readonly SEPARATOR_GROUP3 = '   ';

    public static readonly COLUMNS = 'Columns';

    public static readonly PORTFOLIO_RETURN_GROUP = 'Portfolio return';
    public static readonly ASSET_RETURN_GROUP = 'Asset return';
    public static readonly PERCENT_SCALE_GROUP = 'Percent scale';
    public static readonly PORTFOLIO_BALANCE_SCALE = 'Portfolio balance scale';

    // #region
    public GetLocalizedName (): string {
        return this.propertyComment ? this.propertyComment : Resources.getResource(this.localizationKey);
    }

    // Получение глубокой копии
    public GetClone (): DynProperty {
        const clone = new DynProperty(this.name, this.value, this.type, this.group, this.parentGroup);

        clone.localizationKey = this.localizationKey;

        clone.objectVariants = this.objectVariants;
        clone.increment = this.increment;
        clone.decimalPlaces = this.decimalPlaces;
        clone.minimalValue = this.minimalValue;
        clone.maximalValue = this.maximalValue;

        clone.sortIndex = this.sortIndex;
        clone.tooltip = this.tooltip;
        clone.enabled = this.enabled;
        clone.visible = this.visible;
        clone.DynPropertyRelationType = this.DynPropertyRelationType;

        clone.enabilityValue = this.enabilityValue;
        clone.assignedProperty = this.assignedProperty;
        clone.separatorGroup = this.separatorGroup;
        clone.propertyComment = this.propertyComment;
        clone.coefficient = this.coefficient;
        clone.BeforeChangeEvent = this.BeforeChangeEvent;
        clone.callback = this.callback;

        return clone;
    }

    public serialize (): any {
        const result: any = {};
        result.name = this.name;
        result.type = this.type;
        result.group = this.group;

        const value = this.value;

        switch (this.type) {
        case DynProperty.COLOR_STYLE_WIDTH:
            result.value = value.CheckBoxVisible + ';' + value.Checked + ';' + DynProperty.colorRGBToInt32(value.Color) + ';' + value.Style + ';' + value.Width;
            break;
        case DynProperty.COLOR:
            result.value = DynProperty.colorRGBToInt32(value);
            break;
        case DynProperty.ACCOUNT:
            result.value = value;
            break;
        case DynProperty.INSTRUMENT:
            result.value = value;
            break;
        case DynProperty.BOOL_NUMERIC:
            result.value = value.Value;
            result.checked = value.Checked ? '1' : '0';
            break;
        case DynProperty.RADIOBUTTON_COMBOITEM:
        case DynProperty.RADIOBUTTON_COMBOITEM_WITH_LABEL:{
            const typeOfValue = typeof value;
            result.valueType = typeOfValue;
            if (typeOfValue == 'boolean') {
                result.value = value ? '1' : '0';
            } else {
                result.value = value;
            }
            break;
        }
        case DynProperty.COMBOBOX_COMBOITEM:
            result.valueType = this.COMBOBOX_TYPE;
            result.value = value;
            break;
        case DynProperty.COMBOBOX_COMBOITEM_TIF:
            result.valueType = this.COMBOBOX_TYPE;
            if (!isNullOrUndefined(value.date)) {
                result.value = value.tif.value + ';' + value.date.getTime();
            } else {
                result.value = value.tif.value + ';0';
            }
            break;
        case DynProperty.BUTTON:
            return null;

        case DynProperty.FONT:
            // [Font: Name=Tahoma, Size=11.25, Units=2, GdiCharSet=1, GdiVerticalFont=False]
            result.value = '[Font: Name=' + value.Family + ', Size=' + value.Height + ', Units=2, GdiCharSet=1, GdiVerticalFont=False]';
            result.bold = value.FontWeight === 'bold' ? 'True' : 'False';
            result.italic = 'False';
            break;

        case DynProperty.INSTRUMENTS_DEFAULTS:
            result.value = value;
            break;

        default:
            result.value = value;
            break;
        }

        return result;
    }

    // #endregion

    // Static method
    // #region

    // getPropValue (object or null)
    public static getPropValue (properties: any[], name: string): any {
        const dProp = DynProperty.getPropertyByName(properties, name);
        return (dProp != null) ? dProp.value : null;
    }

    public static getPropertyByName (properties: DynProperty[], name: string): DynProperty | null {
        const prop = properties.find(prop => !isNullOrUndefined(prop) && prop.name === name);
        if (isNullOrUndefined(prop)) {
            return null;
        }
        return prop;
    }

    public static getPropertyByGroupAndName (properties: DynProperty[], group: string, name: string): DynProperty | null {
        return properties.find(prop => !isNullOrUndefined(prop) && prop.group === group && prop.name === name);
    }

    public static getPropertyIndex (dpArray, name): number {
        if (!dpArray || !name) {
            return -1;
        }

        for (let i = 0, len = dpArray.length; i < len; i++) {
            const dp = dpArray[i];
            if (dp.name === name) {
                return i;
            }
        }

        return -1;
    }

    public static isCorrect (dynProperty: DynProperty): boolean {
        return dynProperty !== null && dynProperty.value !== null && dynProperty.value !== undefined;
    }

    // Обновить связанные проперти. Вызывается после изменения значения каждого DynProperty (внешне)
    // return - надо или нет перезаполнить свойства
    public static UpdatePropertyRelation (props, mainProp): boolean {
        const assignedProps = mainProp.assignedProperty;
        // в mainProp никто не заинтересован
        if (!assignedProps.length) return false;

        const mainPropEnabled = mainProp.enabled;

        let mainPropRelationVal;
        if (mainProp.type === DynProperty.COLOR_STYLE_WIDTH ||
        mainProp.type === DynProperty.BOOL_NUMERIC) {
            mainPropRelationVal = !!(mainProp.value.Checked && mainPropEnabled);
        } else {
            mainPropRelationVal = mainProp.value;
        }

        const len = assignedProps.length;
        for (let i = 0; i < len; i++) {
            const prop = DynProperty.getPropertyByGroupAndName(props, mainProp.group, assignedProps[i]);
            if (!prop) continue;
            let newRelationVal = false;
            const propEnabilityVal = prop.enabilityValue;

            if (Array.isArray(propEnabilityVal)) {
                newRelationVal = propEnabilityVal.includes(mainPropRelationVal);
            } else {
                newRelationVal = propEnabilityVal === null || propEnabilityVal === undefined
                    ? mainPropRelationVal
                    : mainPropRelationVal === propEnabilityVal;
            }

            const control = prop.AssignedControl;
            switch (mainProp.DynPropertyRelationType) {
            case DynPropertyRelationType.Enability:
                prop.enabled = newRelationVal;
                if (control) {
                    control.set('enabled', newRelationVal);
                }
                break;
            case DynPropertyRelationType.Visibility:
                prop.visible = newRelationVal;
                if (control) {
                    control.set('visible', newRelationVal);
                }
                break;
            case DynPropertyRelationType.Value:
                if (control) {
                    control.Tag.value = newRelationVal;
                    control.set('value', newRelationVal);
                }
                break;
            }

            // рекурсивно проверяем, если есть AssignedProperty у текущей temp
            if (prop?.assignedProperty.length) {
                DynProperty.UpdatePropertyRelation(props, prop);
            }
        }

        return true;
    }

    // static Клонировать массив свойст
    public static CloneDynProperties (array: any[]): any[] {
        const result = [];
        for (let i = 0, max = array.length; i < max; i++) {
            if (array[i]?.GetClone) {
                result.push((array[i]).GetClone());
            }
        }
        return result;
    }

    public static getLocalizedGroupStrings (group): { name: string, tooltip: string } {
        switch (group) {
        case DynProperty.PARAM_GROUP:
            return {
                name: Resources.getResource('property.Options'),
                tooltip: Resources.getResource('property.Options.ToolTip')
            };
        case DynProperty.VIEW_GROUP:
            return {
                name: Resources.getResource('property.View'),
                tooltip: Resources.getResource('property.View.ToolTip')
            };
        // case DynProperty.SOUNDS_GROUP:
        //     return {
        //         name: Resources.getResource("settings.sound"),
        //         tooltip: Resources.getResource("settings.sound.tt")
        //     };
        case DynProperty.TRADING_DEFAULTS_GROUP:
            return {
                name: Resources.getResource('property.Defaults'),
                tooltip: Resources.getResource('property.Defaults.ToolTip')
            };
        case DynProperty.CONFIRMATIONS_GROUP:
            return {
                name: Resources.getResource('property.Confirmations'),
                tooltip: Resources.getResource('property.Confirmations.ToolTip')
            };
        case DynProperty.WARNINGS_GROUP:
            return {
                name: Resources.getResource('property.Warnings'),
                tooltip: Resources.getResource('property.Warnings.ToolTip')
            };
        // case DynProperty.BRANDING_GROUP:
        //     return {
        //         name: Resources.getResource("OnlineUsers.settings.branding"),
        //         tooltip: Resources.getResource("OnlineUsers.settings.branding")
        //     };
        default: {
            if (group.indexOf('#') > -1) {
                // #97150
                group = group.replace(' # ', ' ');
                return {
                    name: group,
                    tooltip: group
                };
            }
            const locKey = 'property.' + group;
            const locString = Resources.getResource(locKey);
            return {
                name: locString,
                tooltip: locString
            };
        }
        }
    }

    // #endregion

    public static colorRGBToInt32 (stringColor): any {
        if (!stringColor) {
            stringColor = 'rgb(0,0,0)';
        }

        const data = stringColor.substring(stringColor.indexOf('('), stringColor.length).replace(/[()]/g, '').split(',');
        if (data.length === 3) {
            return (255 << 24) | (data[0] << 16) | (data[1] << 8) | data[2];
        } else if (data.length === 4) {
            const alpfa = parseFloat(data[3]) * 255;
            return (alpfa << 24) | (data[0] << 16) | (data[1] << 8) | data[2];
        }
        return stringColor;
    }

    public static colorInt32ToRGB (intColor): any {
        const data = parseInt(intColor);
        if (isNaN(data)) {
            return intColor;
        }

        const Blue = data & 255;
        const Green = (data >> 8) & 255;
        const Red = (data >> 16) & 255;
        const Alpha = (data >> 24) & 255;

        if (Alpha === 255) {
            return 'rgb(' + Red + ',' + Green + ',' + Blue + ')';
        } else {
            return 'rgba(' + Red + ',' + Green + ',' + Blue + ',' + (Alpha / 255) + ')';
        }
    }

    public static deserialize (serializedDynPropertyArray): any {
        return serializedDynPropertyArray.map(function (obj) {
            return DynProperty.fromJson(obj);
        });
    }

    public static serialize (dynPropertyArray): any {
        return dynPropertyArray.map(function (prop) {
            return prop.serialize();
        });
    }
}

export enum DynPropertyRelationType {
    Enability = 'ENABILITY',
    Visibility = 'VISIBILITY',
    Value = 'VALUE'
}

// window HACK
declare global {
    interface Window {
        DynProperty: any
    }
}

window.DynProperty = {};
window.DynProperty.DOUBLE = DynProperty.DOUBLE;
window.DynProperty.DOUBLE = DynProperty.DOUBLE;
window.DynProperty.POSITION_SIZING = DynProperty.POSITION_SIZING;
window.DynProperty.SLTP = DynProperty.SLTP;
window.DynProperty.COMBOBOX_COMBOITEM = DynProperty.COMBOBOX_COMBOITEM;
window.DynProperty.PRODUCT_TYPE_AND_MQ = DynProperty.PRODUCT_TYPE_AND_MQ;
window.DynProperty.ATTENTION_MSG = DynProperty.ATTENTION_MSG;

export class PairColor {
    public Color1: any;
    public Color2: any;
    public ColorText1: string;
    public ColorText2: string;
    public DisableColor1 = false;
    public DisableColor2 = false;

    constructor (color1, color2, colorText1?: string, colorText2?: string) {
        this.Color1 = color1;
        this.Color2 = color2;
        this.ColorText1 = colorText1 || '';
        this.ColorText2 = colorText2 || '';
    }
}

export class TrioColor {
    public Color1: any;
    public Color2: any;
    public Color3: any;
    public ColorText1: string;
    public ColorText2: string;
    public ColorText3: string;
    public DisableColor1 = false;
    public DisableColor2 = false;
    public DisableColor3 = false;

    constructor (color1, color2, color3, colorText1?: string, colorText2?: string, colorText3?: string) {
        this.Color1 = color1;
        this.Color2 = color2;
        this.Color3 = color3;
        this.ColorText1 = colorText1 || '';
        this.ColorText2 = colorText2 || '';
        this.ColorText3 = colorText3 || '';
    }
}

// -----------------

export class BoolNumeric {
    public Checked: any;
    public Value: any;

    constructor (checked, value) {
        this.Checked = checked;
        this.Value = value;
    }

    public toString (): string {
        return this.Checked.toString() + ';' + this.Value.toString();
    }
}

// -----------------

export class ColorStyleWidth {
    public Color: any;
    public Style: any;
    public Width: number;
    public Checked = true;
    public DisableColor = false;
    public DisableStyle = false;
    public DisableWidth = false;
    public CheckBoxVisible = false;

    constructor (color, style, width: number) {
        this.Color = color;
        this.Style = style;
        this.Width = width || 1;
    }

    public enableStyleOnly (): void {
        this.DisableColor = true;
        this.DisableWidth = true;
    }
}
