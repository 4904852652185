import { AuthenticationType } from './AuthenticationType';
import { type IAuthenticationService } from './IAuthenticationService';
import { VelocityAuthenticationService } from './Velocity/VelocityAuthenticationService';

class _AuthenticationFactory {
    create (type: number, url: string, clientId: string): IAuthenticationService | undefined {
        switch (type) {
        case AuthenticationType.Velocity:
            return new VelocityAuthenticationService(url, clientId);
        default:
            return undefined;
        }
    }
}

export const AuthenticationFactory = new _AuthenticationFactory();
