// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { type DirectMessageBaseClass } from '../../../../Utils/DirectMessages/DirectMessagesImport';
import { type PFixFieldSet } from '../Fields/FieldsImport';
import { MessageProcessorBase } from './MessageProcessorBase';

export class BrokerMessageResponseCaseMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): DirectMessageBaseClass[] {
        return [];
    }
}
