// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { Rectangle } from '../../Commons/Geometry';
import { Cursors } from '../../Commons/Cursors';
import { LayersEnum, TerceraChartBaseRenderer } from './TerceraChartBaseRenderer';
import { AlertUtils } from '../../Utils/Alert/AlertUtils';
import { TerceraChartTradingOperation } from '../Utils/ChartConstants';
import { TerceraChartHistoryType } from '../TerceraChartUtils';
import { ThemeManager } from '../../Controls/misc/ThemeManager';
import { DynProperty } from '../../Commons/DynProperty';
import { AlertNotification, AlertImportance, AlertAfterExecute } from '../../Utils/Alert/AlertConstants';
import { type TerceraChart } from '../TerceraChart';
import { type TerceraChartMouseEventArgs } from '../Utils/TerceraChartMouseEventArgs';
import { Resources } from '../../Commons/properties/Resources';

export class TerceraChartNewAlertRenderer extends TerceraChartBaseRenderer<TerceraChart> {
    alertRect: Rectangle;
    selectionRect: Rectangle;
    curInstrument: any;
    chartHistoryType: any;
    selectedPrice: number;
    assignLayer: LayersEnum;
    NotificationVariable: number;
    ImportanceVariable: number;
    AfterExecuteVariable: number;

    static chartNewAlertDefaultImage: any;
    static chartNewAlertHoverImage: any;
    static chartNewAlertDisabledImage: any;

    constructor (chart: TerceraChart) {
        super(chart);
        this.alertRect = Rectangle.Empty();
        this.selectionRect = Rectangle.Empty();
        this.curInstrument = null;
        this.chartHistoryType = null;
        this.selectedPrice = NaN;

        // bool AllowAddAlert => AlertsManager.Instance.Count < 100;
        this.assignLayer = LayersEnum.CrossHair;
        this.NotificationVariable = AlertNotification.SHOW_POP_UP + AlertNotification.SOUND;
        this.ImportanceVariable = AlertImportance.MEDIUM;
        this.AfterExecuteVariable = AlertAfterExecute.REMOVE;
        this.SetClassName('TerceraChartNewAlertRenderer');
    }

    Draw (gr: any, window: any, windowsContainer: any, advParams: any): void {
        if (this.chart == null || advParams == null) { return; }

        if (!this.Visible || window == null) {
            this.alertRect = Rectangle.Empty();
            return;
        }

        if (!this.chart.TerceraChartTradingToolsRenderer.ShowAlerts) { return; }

        this.curInstrument = advParams.Instrument;
        this.chartHistoryType = advParams.TerceraChart.ChartHistoryType();

        const coordinates = advParams.CursorPosition;
        const windowClientRect = window.ClientRectangle;

        const alertWidth = 16;
        const alertWithRightPadding = alertWidth + 1;
        const newAlertRectX = windowClientRect.X + windowClientRect.Width - alertWithRightPadding;
        let selectionRect = this.selectionRect;
        selectionRect = new Rectangle(newAlertRectX, windowClientRect.Y, alertWithRightPadding + window.PaddingRight, windowClientRect.Height - 7);

        // //корректируем отрисовку с поправкой на toBeginRenderer
        if (this.chart.toBeginRenderer.isToBeginActive) {
            selectionRect.Height -= this.chart.toBeginRenderer.toBeginRect.Height + alertWidth / 2;
        }

        if (coordinates != null) {
            if (selectionRect.Contains(coordinates.X, coordinates.Y)) {
                let alertImage = TerceraChartNewAlertRenderer.chartNewAlertDefaultImage;
                if (!this.isExistPrice()) {
                    alertImage = TerceraChartNewAlertRenderer.chartNewAlertDisabledImage;
                } else if (this.alertRect.Contains(coordinates.X, coordinates.Y)) {
                    alertImage = TerceraChartNewAlertRenderer.chartNewAlertHoverImage;
                }

                this.selectedPrice = this.curInstrument.RoundPriceToNearestPointSize(advParams.HoverWindowYValue);
                const rectX = windowClientRect.X + windowClientRect.Width - alertWithRightPadding - 1;
                const rectY = coordinates.Y - alertWidth / 2;

                this.alertRect = new Rectangle(rectX + 3, rectY, alertImage.width, alertImage.height);
                if (alertImage != null) gr.drawImage(alertImage, this.alertRect.X, this.alertRect.Y);
            } else {
                this.alertRect = Rectangle.Empty();
                this.selectedPrice = NaN;
            }
        }
    }

    ProcessMouseDown (e: any): boolean {
        if (!this.alertRect.Contains(e.Location.X, e.Location.Y) || this.curInstrument === null) // || this.selectedPrice < this.curInstrument.PointSize)
        { return false; }

        const ht = TerceraChartHistoryType.GetOriginalHistoryType(this.chartHistoryType, this.curInstrument);
        const valueFromInstrument = AlertUtils.GetCorrectPriceForAlertByInstrument(this.curInstrument, ht);

        if (isNaN(valueFromInstrument)) { return false; }

        const historyType = TerceraChartHistoryType.GetOriginalHistoryType(this.chartHistoryType, this.curInstrument);
        this.chart.terceraChartPanelContext.ChartVisualTrading(null, {
            action: TerceraChartTradingOperation.ChartNewAlert,
            Account: this.chart.Account(),
            instrument: this.curInstrument,
            value: this.selectedPrice,
            historyType,
            Importance: this.ImportanceVariable,
            AfterExecute: this.AfterExecuteVariable,
            // AlertAction: this.ActionVariable,
            Notification: AlertUtils.VerifyNotificationValue(this.NotificationVariable)
        });

        return true;
    }

    Properties (): DynProperty[] {
        const properties = super.Properties();

        let prop = new DynProperty('Notification', this.NotificationVariable, DynProperty.ALERT_NOTIFICATION_SELECTOR, DynProperty.ALERTS_GROUP);
        prop.sortIndex = 2;
        prop.COMBOBOX_TYPE = DynProperty.INTEGER;
        properties.push(prop);

        prop = new DynProperty('Importance', this.ImportanceVariable, DynProperty.COMBOBOX, DynProperty.ALERTS_GROUP);
        prop.sortIndex = 3;
        prop.objectVariants = AlertUtils.GetAlertImportanceItems();
        prop.COMBOBOX_TYPE = DynProperty.INTEGER;
        properties.push(prop);

        prop = new DynProperty('AfterExecute', this.AfterExecuteVariable, DynProperty.COMBOBOX, DynProperty.ALERTS_GROUP);
        prop.sortIndex = 4;
        prop.objectVariants = AlertUtils.GetAlertAfterExecuteItems();
        prop.COMBOBOX_TYPE = DynProperty.INTEGER;
        properties.push(prop);

        return properties;
    }

    callBack (properties: DynProperty[]): void {
        super.callBack(properties);

        let dp = DynProperty.getPropertyByName(properties, 'Notification');
        if (dp != null) { this.NotificationVariable = dp.value; }

        dp = DynProperty.getPropertyByName(properties, 'AfterExecute');
        if (dp != null) { this.AfterExecuteVariable = dp.value; }

        dp = DynProperty.getPropertyByName(properties, 'Importance');
        if (dp != null) { this.ImportanceVariable = dp.value; }
    }

    ThemeChanged (): void {
        super.ThemeChanged();
        TerceraChartNewAlertRenderer.ThemeChangedStatic();
    }

    static ThemeChangedStatic (): void {
        TerceraChartNewAlertRenderer.chartNewAlertDefaultImage = ThemeManager.CurrentTheme.chartNewAlertDefaultImage;
        TerceraChartNewAlertRenderer.chartNewAlertHoverImage = ThemeManager.CurrentTheme.chartNewAlertHoverImage;
        TerceraChartNewAlertRenderer.chartNewAlertDisabledImage = ThemeManager.CurrentTheme.chartNewAlertDisabledImage;
    }

    // TerceraChartNewAlertRenderer.prototype.GetTooltip = function (AdvancedChartMouseEventArgs e)
    // {
    //     // if (e != null and CursorInAlertRect(e.Location))
    //     // {
    //     //     string tooltip;
    //     //     if (!this.AllowAddAlert)
    //     //         tooltip = Resources.getResource(LocalizationHelper.SCREEN_ALERTS_ADD_ALERT_DISABLED_TOOLTIP);
    //     //     else if (selectedPrice < curInstrument.PointSize)
    //     //         tooltip = Resources.getResource("chart.visualTrading.Invalid price");
    //     //     else
    //     //         tooltip = Resources.getResource("chart.NewAlertRenderer.ClickToCreate");
    //     //     return new TooltipParams(tooltip, TooltipDirection.Left, alertRect);
    //     // }
    //     // else
    //     //     return base.GetTooltip(e);
    // }

    GetCursor (e: any): Cursors | null {
        if (this.alertRect.Contains(e.Location.X, e.Location.Y)) { return Cursors.Hand; }

        return null;
    }

    ProcessMouseMove (e: any): boolean {
    // if (this.alertRect.Contains(e.Location.X, e.Location.Y))     // commented out due to the issue described in #114239
        e.NeedRedraw = [LayersEnum.Tools, LayersEnum.CrossHair];

        return super.ProcessMouseMove(e);
    }

    public override GetTooltip (e: TerceraChartMouseEventArgs): string {
        const tooltip = super.GetTooltip(e);
        if (tooltip != null) {
            return tooltip;
        }
        if (!this.alertRect.Contains(e.Location.X, e.Location.Y)) {
            return null;
        }
        if (!this.isExistPrice()) {
            return Resources.getResource('property.NoPriceForCurrentAlertType');
        }
        return null;
    }

    private isExistPrice (): boolean {
        const historyType = TerceraChartHistoryType.GetOriginalHistoryType(this.chartHistoryType, this.curInstrument);
        const valueFromInstrument = AlertUtils.GetCorrectPriceForAlertByInstrument(this.curInstrument, historyType);
        return isValidNumber(valueFromInstrument);
    }
}
