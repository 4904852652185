// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from "../../Utils/CustomEvents.ts";
import { Resources } from "../../Commons/properties/Resources.ts";
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";
import { TerceraBaseWindowTemplate, TypesManagerScreenTemplate, TypesManagerScreenFooterTemplate } from "../../templates.js";
import { DefaultLotTableItem } from "../cache/DefaultLotTableItem.ts";
import { Control } from "../elements/Control.js";
import { QuickTableEditingInfo } from "../elements/QuickTable/QuickTableMisc.ts";
import { TerceraButton } from "../elements/TerceraButton.ts";
import { TerceraWindowBase } from "./TerceraWindowBase.js";
import { ThemeManager } from "../misc/ThemeManager.ts";
import { DynProperty } from "../../Commons/DynProperty.ts";
import { SLTPTriggerUtils} from "../../Commons/cache/OrderParams/SLTPTriggerUtils.ts"
import { SLTPTrigger } from "../../Utils/SlTpTrigger.ts";
import { Instrument } from "../../Commons/cache/Instrument.ts";
import { DataCache } from "../../Commons/DataCache.ts";
import { SessionSettings } from "../../Commons/SessionSettings.ts";
import { HtmlScroll } from "../../Commons/HtmlScroll.js";

export let TypesManagerScreen = TerceraWindowBase.extend({
    data: function ()
    {
        return {
            width: 653,
            height: 480,
            resizable: false,

            allSettings: null,
            itemSettings: null,
            triggersVisible: false,
            sltpStep: 1,
            sltpMinValue: 1,
            sltpDecimalPrecision: 0,

            style_addition_body: 'js-types-ManagerScreen-AdditionalBody',
            style_addition_footer: 'js-types-ManagerScreen-AdditionalFooter'
        };
    },
    template: TerceraBaseWindowTemplate,
    partials: { 
        bodyPartial: TypesManagerScreenTemplate,
        footerPartial: TypesManagerScreenFooterTemplate,
    }
});

TypesManagerScreen.prototype.getType = function () { return 'TypesManagerScreen'; };

TypesManagerScreen.prototype.oninit = function ()
{
    TerceraWindowBase.prototype.oninit.apply(this);
    this.observe('itemSettings', this.onItemSettingsChanged);
};

TypesManagerScreen.prototype.oncomplete = function ()
{
    TerceraWindowBase.prototype.oncomplete.apply(this);

    var qtRactive = this.Controls.quickTableRactive;
    this.quickTableRactive = qtRactive;

    this.layoutTable();

    let columnsInfo = new DefaultLotTableItem(null)

    let qt = qtRactive.quickTable
    qt.canShowHeaderMenu = false
    qt.allowGroupBy = false
    qt.lockManualSorting = true
    qt.InitializeDirect(columnsInfo)
    qt.UpdateSortedColumns();
    qt.AfterEditItem.Subscribe(this.onAfterEditItem, this);

    var myWidth = this.get('width');

    this.localize();
    this.themeChange();

    ThemeManager.onThemeChange.Subscribe(this.themeChange, this);
    Control.Ticker.Subscribe(this.TickAsync, this);

    qt.needRedrawBackground = true;
    qt.needRedraw = true;

    if (this.deferredInstrumentToAdd)
    {
        this.Controls.lookup.AddInstrument(this.deferredInstrumentToAdd);
        delete this.deferredInstrumentToAdd;
    }
};

TypesManagerScreen.prototype.dispose = function ()
{
    Control.Ticker.UnSubscribe(this.TickAsync, this);
    ThemeManager.onThemeChange.UnSubscribe(this.themeChange, this);

    delete this.caller;
    delete this.callBack;
    delete this.deferredInstrumentToAdd;

    // TODO.
    MainWindowManager.TypesManagerScreen = null;

    TerceraWindowBase.prototype.dispose.apply(this);
};

TypesManagerScreen.prototype.onItemSettingsChanged = function (itemSettings)
{
    this.repopulateDefaultLots(itemSettings)

    this.repopulateSLTPTriggers(itemSettings)
};

TypesManagerScreen.prototype.repopulateDefaultLots = function (itemSettings)
{
    let qt = this.quickTableRactive ? this.quickTableRactive.quickTable : null
    if (!qt) return

    qt.ClearAll()
    qt.BeginUpdate()

    let defaultLots = itemSettings.DefaultLots
    let len = defaultLots.length
    for (let i = 0; i < len; i++)
    {
        let defLot = defaultLots[i]
        let row = qt.AddItem(new DefaultLotTableItem(defLot, i))
        this.ProcessRow(row, defLot)
    }

    qt.EndUpdate()
    qt.needRedraw = true
};

TypesManagerScreen.prototype.ProcessRow = function (row, defaultLot)
{
    if (!row) return

    let cells = row.cells

    let selectedLotCell = cells[0]
    if (!selectedLotCell.QuickTableEditingInfo)
    {
        let info = new QuickTableEditingInfo(DynProperty.BOOLEAN)
        selectedLotCell.QuickTableEditingInfo = info
        selectedLotCell.ReadOnly = false
    }

    selectedLotCell.value = defaultLot.Selected

    let lotValueCell = cells[1]
    if (!lotValueCell.QuickTableEditingInfo)
    {
        let info = new QuickTableEditingInfo(DynProperty.DOUBLE)
        info.Inc = info.Min = defaultLot.Increment
        info.Max = 9999999
        info.DecimalPlaces = defaultLot.Digits
        lotValueCell.QuickTableEditingInfo = info
        lotValueCell.ReadOnly = false
    }

    let lots = defaultLot.Lots
    lotValueCell.value = defaultLot.Lots
    lotValueCell.formattedValue = TypesManagerScreen.getFormattedLotValue(lots)
}

TypesManagerScreen.getFormattedLotValue = function (value)
{
    return value.toString();
};

TypesManagerScreen.prototype.onAfterEditItem = function (params)
{
    let row = params.row
    if (!row) return

    let itemSettings = this.get('itemSettings')

    let defaultLot = itemSettings.DefaultLots[row.id]

    let qt = this.quickTableRactive.quickTable
    let colIdx = params.columnIndex
    switch (colIdx)
    {
        // Selected lot.
        case 0:
            defaultLot.Selected = !defaultLot.Selected
            break
        // Lot value.
        case 1:
            defaultLot.Lots = params.newValue
            break
    }

    this.ProcessRow(row, defaultLot)
};

TypesManagerScreen.prototype.TickAsync = function ()
{
    var qtRactive = this.quickTableRactive;
    if (qtRactive && qtRactive.quickTable.needRedraw)
    {
        var qt = qtRactive.quickTable;
        qt.needRedraw = false;
        qt.Draw();
    }
};


TypesManagerScreen.prototype.layoutTable = function ()
{
    if (this.quickTableRactive != null) { this.layoutTableResize(this.quickTableRactive); }
}

TypesManagerScreen.prototype.layoutTableResize = function (table)
{
    table.resetSizes().then(() => { table.setSizes() });
}

TypesManagerScreen.prototype.localize = function ()
{
    TerceraWindowBase.prototype.localize.apply(this);

    this.quickTableRactive?.quickTable?.localize();

    this.set('header', Resources.getResource('TypesManagerScreen.Types Manager'));

    this.set({
        okBtnText: Resources.getResource('general.messageBox.ok'),
        okBtnTooltip: Resources.getResource('general.messageBox.ok.ToolTip'),
        cancelBtnText: Resources.getResource('general.messageBox.cancel'),
        cancelBtnTooltip: Resources.getResource('general.messageBox.cancel.ToolTip'),

        slDefaultText: Resources.getResource('TypesManagerScreen.NumericLabel.SL default offset, ticks'),
        tpDefaultText: Resources.getResource('TypesManagerScreen.NumericLabel.TP default offset, ticks'),
        usrQtyText: Resources.getResource('TypesManagerScreen.NumericLabel.User quantity coefficient'),
        priceIncrementText: Resources.getResource('TypesManagerScreen.NumericLabel.Price increment (arrow), ticks'),
        defLotsSetupText: Resources.getResource('TypesManagerScreen.DefaultLotsSetup')
    });

    if (this.quickTableRactive)
        this.quickTableRactive.quickTable.localize();
};

TypesManagerScreen.prototype.themeChange = function ()
{
    if (this.quickTableRactive)
        this.quickTableRactive.themeChange();

    var lookup = this.Controls.lookup;
    if (lookup) lookup.themeChange();
};

TypesManagerScreen.prototype.okClick = function ()
{
    if (this.callBack)
        this.callBack(this.get('allSettings'));

    this.close();
};

TypesManagerScreen.prototype.onCloseButtonClick = function ()
{
    if (this.callBack)
        this.callBack(null);

    this.close();
};

TypesManagerScreen.prototype.AddInstrument = function (instrument)
{
    if (!instrument || !(instrument instanceof Instrument))
        return;

    var lookup = this.Controls.lookup;
    if (lookup) lookup.AddInstrument(instrument);
    else
    {
        var scr = this;
        scr.deferredInstrumentToAdd = instrument;
    }
};

TypesManagerScreen.prototype.repopulateSLTPTriggers = function (itemSettings)
{
    if (!itemSettings)
        return

    let DC = DataCache

    let ins = DC.getInstrumentByName(itemSettings.InstrumentName),
        insRule = ins ? ins.IsAllowCustomSLTPTriggerPrice
            : DC.AllowCustomSLTPTriggerPriceForInstrumentType(itemSettings.InstrumentType)

    let userRule = DC.AllowCustomSLTPTriggerPriceForUser(),
        triggersVisible = userRule && insRule
    this.set('triggersVisible', triggersVisible)

    if (triggersVisible){
        this.resetScrolls();
    } else { this.removeScrolls(); }
    this.layoutTable(); //#120102
    if (triggersVisible && !itemSettings.sltpTriggerShort && itemSettings.sltpTriggerShort !== 0)
    {
        let tr = new SLTPTrigger()

        if (this.caller && this.caller.ownerPanel && this.caller.ownerPanel.arrayProperties)
            SLTPTriggerUtils.ApplyProperties(tr, this.caller.ownerPanel.arrayProperties)  // если поменяли перед открітіем тайм менеджера но не сохранили
        else
            tr.CopyValueFrom(SessionSettings.GetTriggers())

        this.set('itemSettings.sltpTriggerShort', tr.GetShort())
    }
}

TypesManagerScreen.prototype.addScrolls = function ()
{
    if (!this.append) { return; }
    let rP = this.find('.js-right-panel')
    if (rP != null) { HtmlScroll.addScroll(rP); }
}

TypesManagerScreen.prototype.removeScrolls = function ()
{
    if (!this.append) { return; }
    let rP = this.find('.js-right-panel')
    if (rP != null) { HtmlScroll.removeScroll(rP); }
}

TypesManagerScreen.prototype.resetScrolls = function ()
{
    this.removeScrolls()
    this.addScrolls()
}

TypesManagerScreen.show = function (caller, instrumentToAdd, callBack)
{
    var scr = TypesManagerScreen.getInstance();
    if (scr) scr.setFocus();

    var oldCaller = scr.caller;
    if (oldCaller !== caller)
    {
        scr.caller = caller;
        scr.set('allSettings',
            caller.getAllInstrumentsDefaultSettings ?
                caller.getAllInstrumentsDefaultSettings() :
                null);
    }

    scr.callBack = callBack;
    scr.AddInstrument(instrumentToAdd);
};

TypesManagerScreen.getInstance = function ()
{
    var instance = MainWindowManager.TypesManagerScreen;
    if (instance) return instance;

    var scr = new TypesManagerScreen();
    MainWindowManager.MainWindow.addControl(scr);
    scr.center();

    MainWindowManager.TypesManagerScreen = scr;
    return scr;
};