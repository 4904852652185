// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { LinkedSystem, LinkedSystemAccLinkingValue } from '../misc/LinkedSystem.ts';
import { ContainerControl } from '../elements/ContainerControl.js';
import { QuickTableRactive } from '../elements/QuickTable/QuickTableRactive.ts';
import { TerceraLinkControlConstants } from '../UtilsClasses/TerceraLinkControlConstants.ts';
import { TerceraWindowBase } from '../screen/TerceraWindowBase.js';
import { ControlsTypes, PanelLocKeys, PanelNames, PanelsWithAccountLookup } from '../UtilsClasses/FactoryConstants.ts';
import { BrowserUtils } from '../../Commons/UtilsClasses/BrowserUtils.ts';
import { ErrorInformationStorage } from '../../Commons/ErrorInformationStorage.ts';
import { Resources } from '../../Commons/properties/Resources.ts';
import { ThemeManager } from '../misc/ThemeManager.ts';
import { DynProperty } from '../../Commons/DynProperty.ts';
import { DataCache } from '../../Commons/DataCache.ts';
import { CustomerAccess } from '../../Commons/CustomerAccess/CustomerAccess.ts';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager.ts';
import { PanelItemsFactory } from './PanelItemsFactory.js';
import { WorkSpaceManager } from '../WorkSpace/WorkSpaceManager.ts';
import { ApplicationPanelNewTemplate } from '../../templates.js';
import { DateTimeUtils } from '../../Utils/Time/DateTimeUtils.ts';
import { SessionSettings } from '../../Commons/SessionSettings.ts';

export let ApplicationPanelNew = TerceraWindowBase.extend(
    {
        data: function ()
        {
            return {
                symbolLinkValue: TerceraLinkControlConstants.ColorConstants.NONE,
                accountLinkValue: TerceraLinkControlConstants.ColorConstants.NONE,
                isAccountLinkShow: false,
                isSymbolLinkShow: false,
                resizable: false,
                showFooter: false,
                top: 0,
                left: 0,
                dockablePanel: true,
                workSpaceId: null,
                showHeader: false,

                account: null,
                canLinkByAccount: true,
                canFilterByAccount: true,
                isHeaderMenuButtonShow: false,
                //canSelect: false,
                loading: false,
                isSetFocusOnComplete: true
            }
        },
        partials: {
            bodyPartial: ApplicationPanelNewTemplate
        },
        quickTableRactive: null,
        Name: 'app',
        topPanelHeight: 0,
        margin: 5,

        menuTagDict: [],        // контекстное меню quickTable
        moreThanOneTTKey: '',
        noOneTTKey: '',
        noSymbolWasFoundTooltip: '',

        AllowSettings: true,
        NeedCalculateRowCount: true,
        headerLocaleKey: '',
        rowCount: 0,
        skipOnSave: false,
        skipSomeProperties: false,
        completed: false,
        forceUpdate: false,
        dockSpawnElementsToRemove: new Array() // remove elements of dock system when panel destroing. fix events leak
    });

ApplicationPanelNew.prototype.getType = function () { return PanelNames.ApplicationPanelNew; }

// Init QuickTableRactive
ApplicationPanelNew.prototype.jbInit = function ()
{
    try
    {
        const panelInfo = PanelItemsFactory.GetPanelItem(this.getType());
        if (panelInfo)
        {
            const myQuickTableRactive = new QuickTableRactive();
            this.addControl(myQuickTableRactive, true);
            const qt = myQuickTableRactive.quickTable;

            //TODO
            qt.InitializeDirect(new panelInfo());
            this.SetColumnsDefaultDisplayIndex(qt);
            this.SetColumnsColouringMode(qt);
            qt.UpdateSortedColumns();
            myQuickTableRactive.enableRowHoverWithBorder(true);
            this.quickTableRactive = myQuickTableRactive;

            if (this.deferredXmlSettingsTemplate)
            {
                qt.xmlSettingsTemplate = this.deferredXmlSettingsTemplate;
                this.visibleColumnChanged(qt.getVisibleColumn());
                delete this.deferredXmlSettingsTemplate;
            }

            this.layoutTable();
            this.OnResize.Subscribe(this.layoutTable, this);

            qt.OnVisibleColumnChanged.Subscribe(this.visibleColumnChanged, this);
            qt.beforeTableContextMenuShowing = this.preparePopup.bind(this);
        }

        this.localize();
        this.populateItemsDirect();
    }
    catch (ex) { ErrorInformationStorage.GetException(ex); }
}

ApplicationPanelNew.prototype.getQuickTable = function ()
{
    return this.quickTableRactive?.quickTable ?? null;
}

ApplicationPanelNew.prototype.populateItemsDirect = function () { }

ApplicationPanelNew.prototype.SetColumnsDefaultDisplayIndex = function (table) { }

ApplicationPanelNew.prototype.SetColumnsColouringMode = function (table) { }

ApplicationPanelNew.prototype.ProcessSetColumnsColouringMode = function (table, arr, type, upCustomColor, downCustomColor)
{
    const theme = ThemeManager.CurrentTheme;
    const up = upCustomColor || theme.TableValueUpForeColor;
    const down = downCustomColor || theme.TableValueDownForeColor;
    for (let i = 0; i < arr.length; i++)
    {
        const column = table.columns[arr[i]];
        column.ColouringMode = type;
        column.ValueUpForeColor = up;
        column.ValueDownForeColor = down;
    }
}

ApplicationPanelNew.prototype.ContentContainerHeight = function ()
{
    let contentContainerH = this.get('height');
    const nonDockablePanelWithHeader = !this.get('dockablePanel') && this.get('showHeader');
    if (nonDockablePanelWithHeader) { contentContainerH -= 20; } //header height;

    if (this.isShowExploreTheAppLink()) { contentContainerH -= 25; }

    return contentContainerH;
}

ApplicationPanelNew.prototype.ShowTotalsStateChange = function (menuItem)
{
    this.UpdateShowTotalsStateChange(menuItem.checked);
}

ApplicationPanelNew.prototype.UpdateShowTotalsStateChange = function (state)
{
    const qt = this.getQuickTable();
    if (qt == null) { return; }

    qt.ShowTotals = state;
    qt.onResize();
}

ApplicationPanelNew.prototype.UpdateGroupByState = function (InitGroupByColumnIndex)
{
    const qt = this.getQuickTable();
    if (qt == null) { return; }

    const menu = qt.groupByContextMenuItems;
    if (menu == null) { return; }

    let groupByMenuItem = null;

    for (let i = 0; i < menu.subitems.length; i++)
    {
        let menuItem = menu.subitems[i];

        if (menuItem.tag.PRIVATE.index == InitGroupByColumnIndex)
        {
            groupByMenuItem = menuItem;
            break;
        }
    }

    if (groupByMenuItem != null)
    {
        groupByMenuItem.checked = true;
        qt.onGroupByMenuItemClicked(groupByMenuItem);
    }
}

ApplicationPanelNew.prototype.layoutTable = function ()
{
    if (this.quickTableRactive != null) { this.layoutTableResize(this.quickTableRactive); }
}

ApplicationPanelNew.prototype.layoutTableResize = function (table)
{
    table.resetSizes().then(() => { table.setSizes() });
}

ApplicationPanelNew.prototype.getClientPanel = function (customPlace = false)
{
    if (!customPlace)
        return TerceraWindowBase.prototype.getClientPanel.apply(this)
    else
    {
        if (this.Controls.windowPanelBodyQT)
            return this.Controls.windowPanelBodyQT.getClientPanel();
        else
            return TerceraWindowBase.prototype.getClientPanel.apply(this)
    }
}

ApplicationPanelNew.prototype.hasAccountLookup = function () { return PanelsWithAccountLookup.includes(this.getType()); }

ApplicationPanelNew.prototype.oninit = function ()
{
    TerceraWindowBase.prototype.oninit.apply(this);

    this.observe('symbolLinkValue', function (newVal, oldVal, key)
    {
        this.symbolLink_Out(true);
    });
    this.observe('accountLinkValue', function (newVal, oldVal, key)
    {
        this.accountLink_Out(true);
    });

    this.observe('isSymbolLinkShow isAccountLinkShow', function (newVal, oldVal, key)
    {
        this.updateLinkVisible();

        if (!newVal && key == 'isAccountLinkShow')
        {
            this.filterByAccountNumber();
            if (!this.hasAccountLookup() && this.get('account') == null) { this.set('account', DataCache.getPrimaryAccount()); }
        }
    });

    this.observe('header', function (newVal)
    {
        if (newVal && this.setTitle)
            this.setTitle(newVal);
    });

    if (Resources.isHidden('allowLinkSystem'))
    {
        this.set({
            isAccountLinkShow: false,
            isSymbolLinkShow: false,
        });
    }
}

ApplicationPanelNew.prototype.oncomplete = function ()
{
    var me = this;
    TerceraWindowBase.prototype.oncomplete.apply(me);
    me.jbInit();

    me.layoutTable();
    if (DataCache.getNumberOfAccounts() === 1)
    {
        me.set({ isAccountLinkShow: false });
    }

    LinkedSystem.accLinkingActive ? me.enableAccountLinking() : me.disableAccountLinking()

    if (this.get('isSetFocusOnComplete'))
        me.setFocus();

    if (this.InitGroupByColumnIndex !== null)
        this.UpdateGroupByState(this.InitGroupByColumnIndex);

    const qt = this.getQuickTable();
    qt?.OnAddItem.Subscribe(this.OnAddItemToQuickTable, this);

    me.completed = true;
}

ApplicationPanelNew.prototype.OnAddItemToQuickTable = function (row)
{
    if (!LinkedSystem.accLinkingActive) { return; }

    row.visible = this.isRowVisibleOnAccountLinking(row);
}

ApplicationPanelNew.prototype.gotFocus = function ()
{
    TerceraWindowBase.prototype.gotFocus.apply(this);
    if (this.get('dockablePanel'))
        if (this.containerSetFocus)
            this.containerSetFocus(true);
}

ApplicationPanelNew.prototype.lostFocus = function ()
{
    TerceraWindowBase.prototype.lostFocus.apply(this);
    if (this.get('dockablePanel'))
        if (this.containerSetFocus)
            this.containerSetFocus(false)
}

ApplicationPanelNew.prototype.visibleColumnChanged = function (columnIndexesArray)
{
    var msgData = {}
    msgData.columnIndexesArray = columnIndexesArray;
}

ApplicationPanelNew.prototype.clearTable = function ()
{
    const qt = this.getQuickTable();
    if (qt != null) { qt.ClearAll(); }
}

ApplicationPanelNew.prototype.preparePopup = function ()
{
    this.updateSymbolInfoMenuItem()
}

//
//
//
ApplicationPanelNew.prototype.populate = function (addPanel)
{
    if (!addPanel && WorkSpaceManager.needRestoreWS)
        this.repopulate();
}

//
//
//
ApplicationPanelNew.prototype.repopulate = function ()
{
    const qt = this.getQuickTable();

    if (qt != null)
    {
        qt.ClearAll();
        this.visibleColumnChanged(qt.getVisibleColumn());
    }

    this.Controls?.TerceraInstrumentLookup?.repopulate(true);
}

//
//
//
ApplicationPanelNew.prototype.setSize = function (w, h)
{
    let isEq = this.get('width') === w && this.get('height') === h;
    this.set({ width: w, height: h });

    this.layoutTable(isEq);
}

//
//
//
ApplicationPanelNew.prototype.themeChange = function ()
{
    if (this.quickTableRactive)
        this.quickTableRactive.themeChange();
}

//
//
//
ApplicationPanelNew.prototype.localize = function ()
{
    TerceraWindowBase.prototype.localize.apply(this);

    this.getQuickTable()?.localize();

    this.updatePanelHeader();
    this.set({
        banText: Resources.getResource('panel.caption.NotAllowedMode'),
        symbolLinkTooltip: Resources.getResource('panel.AccountLink.SymbolLink.ToolTip')

    });

    this.noSymbolWasFoundTooltip = Resources.getResource('panel.menu.symbolWasNotFound');
    this.localizeAccountLinkTooltip()
}

ApplicationPanelNew.prototype.localizeAccountLinkTooltip = function ()
{
    this.set({ accountLinkTooltip: this.tooltipForAccountLink() });
}

//
//
//
ApplicationPanelNew.prototype.dispose = function ()
{
    const qt = this.getQuickTable();
    qt?.OnAddItem.UnSubscribe(this.OnAddItemToQuickTable, this);

    let toDisp = this.dockSpawnElementsToRemove;
    if (toDisp)
        for (let i = 0; i < toDisp.length; i++)
            toDisp[i].dispose()

    if (WorkSpaceManager.currentWorkspace)
        WorkSpaceManager.currentWorkspace.removePanel(this._guid);
    
    this.OnResize.UnSubscribe(this.layoutTable, this);

    TerceraWindowBase.prototype.dispose.apply(this);
}

//
//
//
ApplicationPanelNew.prototype.Properties = function ()
{
    var properties = [];

    properties.push(new DynProperty('X_Position', this.get('left'), DynProperty.INTEGER, DynProperty.HIDDEN_GROUP));
    properties.push(new DynProperty('Y_Position', this.get('top'), DynProperty.INTEGER, DynProperty.HIDDEN_GROUP));
    properties.push(new DynProperty('Width', this.get('width'), DynProperty.INTEGER, DynProperty.HIDDEN_GROUP));
    properties.push(new DynProperty('Height', this.get('height'), DynProperty.INTEGER, DynProperty.HIDDEN_GROUP));
    properties.push(new DynProperty('Name', this.getType(), DynProperty.STRING, DynProperty.HIDDEN_GROUP));
    properties.push(new DynProperty('WSId', this.get('workSpaceId'), DynProperty.INTEGER, DynProperty.HIDDEN_GROUP));

    if (this.get('isAccountLinkShow'))
        properties.push(new DynProperty('accountLinkValue', this.get('accountLinkValue'), DynProperty.INTEGER, DynProperty.HIDDEN_GROUP));
    if (this.get('isSymbolLinkShow'))
        properties.push(new DynProperty('symbolLinkValue', this.get('symbolLinkValue'), DynProperty.INTEGER, DynProperty.HIDDEN_GROUP));

    if (this.quickTableRactive)
        properties.push(new DynProperty('GroupByColumnIndex', this.getQuickTable().groupByColumnIndex, DynProperty.INTEGER, DynProperty.HIDDEN_GROUP));

    return properties;
}

//
//
//
ApplicationPanelNew.prototype.callBack = function (newProperties)
{
    const newX = DynProperty.getPropValue(newProperties, 'X_Position');
    const newY = DynProperty.getPropValue(newProperties, 'Y_Position');
    const newWidth = DynProperty.getPropValue(newProperties, 'Width');
    const newHeight = DynProperty.getPropValue(newProperties, 'Height');
    const wsId = DynProperty.getPropValue(newProperties, 'WSId');

    if (this.get('isAccountLinkShow'))
    {
        const dpVal = DynProperty.getPropValue(newProperties, 'accountLinkValue');
        if (dpVal !== null)
            this.set({ accountLinkValue: dpVal });
    }
    if (this.get('isSymbolLinkShow'))
    {
        const dpVal = DynProperty.getPropValue(newProperties, 'symbolLinkValue');
        if (dpVal !== null)
            this.set({ symbolLinkValue: dpVal });
    }

    if (newX !== null && newY !== null && newWidth !== null && newHeight !== null)
        this.setBounds(newX, newY, newWidth, newHeight);
    if (wsId !== null)
        this.setWorkSpaceId(wsId);

    this.InitGroupByColumnIndex = DynProperty.getPropValue(newProperties, 'GroupByColumnIndex');
}

ApplicationPanelNew.prototype.updateSettings = function () { this.forceUpdate = true; }

ApplicationPanelNew.prototype.TickAsync = function ()
{
    /*if (this.quickTableRactive && this.getQuickTable().needRedraw)*/
    const qt = this.getQuickTable();
    if (qt == null) { return; }

    if (qt.rowCountChanged)
    {
        this.rowCount = qt.getDataRowsCount();
        qt.rowCountChanged = false;
        this.updatePanelHeader();
    }

    if (!this.get('visible')) { return; }

    qt.updateRowsCellsValues(this.forceUpdate);
    qt.needRedraw = false;
    qt.Draw();
    this.forceUpdate = false;
}

ApplicationPanelNew.prototype.symbolLink_Out = function (newSubscriber, instrument) { }
ApplicationPanelNew.prototype.symbolLink_In = function (symbolName) { }

ApplicationPanelNew.prototype.accountLink_Out = function (newSubscriber, account)
{
    const completedAndHasNoDeferredCallbackProps = this.completed && this.deferredCallbackProps == null;

    if (!account)
    {
        const acc = this.get('account') || this.get('accountItem');
        if (acc == null && completedAndHasNoDeferredCallbackProps) { return; }
        account = acc;
    }

    if (completedAndHasNoDeferredCallbackProps)
    {
        LinkedSystem.setAccount(LinkedSystemAccLinkingValue, account.BstrAccount, newSubscriber);
    }
    else if (LinkedSystem.accLinkingActive)
    {
        this.accountLink_In(LinkedSystem.getAccount(LinkedSystemAccLinkingValue));
    }
}

ApplicationPanelNew.prototype.accountLink_In = function (accId, fromLinkedSystem = false)
{
    if (!this.get('canLinkByAccount')) { return; }

    if (fromLinkedSystem && !this.OnAccountLinking()) { return; }

    const acc = DataCache.Accounts[accId];
    if (acc) { this.set('account', acc); }
    this.filterByAccountNumber(accId)
    this.localizeAccountLinkTooltip()
}

ApplicationPanelNew.prototype.updateLinkVisible = function () { }

ApplicationPanelNew.prototype.setWorkSpaceId = function (id)
{
    this.set({ workSpaceId: id });
}

ApplicationPanelNew.prototype.getWorkSpaceId = function ()
{
    return this.get('workSpaceId');
}

ApplicationPanelNew.prototype.updatePanelHeader = function ()
{
    const qt = this.getQuickTable();

    let subItemsCount = qt != null ? qt.getSubItemsRowsCount(false) : 0;
    let rowNumSt = this.rowCount - subItemsCount;        // - (all FIFO sub items) #80010

    if (LinkedSystem.accLinkingActive && !isNullOrUndefined(this.quickTableRactive) && this.get('canLinkByAccount'))
    {
        subItemsCount = qt.getSubItemsRowsCount(true);
        const visibleRowCount = qt.visibleRowCount() - subItemsCount; // - (visible FIFO sub items) #80010
        if (DataCache.EnableForceLinkingByAccount()) { rowNumSt = `${visibleRowCount}`; } else { rowNumSt = `${visibleRowCount}/${rowNumSt}`; }
    }
    void this.set({ header: Resources.getResource(this.headerLocaleKey) + (this.NeedCalculateRowCount ? (`  (${rowNumSt})`) : '') });
}

ApplicationPanelNew.prototype.tooltipForAccountLink = function ()
{
    let tooltip = Resources.getResource('panel.AccountLink.CanFilterToolTip');

    if (!this.get('canFilterByAccount'))
    {
        tooltip = Resources.getResource('panel.AccountLink.ToolTip');
        const acc = this.get('accountItem') || this.get('account');
        if (acc != null)
        {
            tooltip += ' : ' + acc.FullAccString;
        }
        else
        {
            tooltip = Resources.getResource('panel.accounts.AccountLink.ToolTip'); // AssetBalancesPanel
        }
    }

    return tooltip;
}

ApplicationPanelNew.prototype.isRowVisibleOnAccountLinking = function (row)
{
    if (!this.get('canFilterByAccount') || !this.get('canLinkByAccount')) { return true; }

    const linkAccId = LinkedSystem.getAccount(LinkedSystemAccLinkingValue);
    const rowAcc = row?.item?.GetCurrentAccount();

    return !rowAcc || rowAcc?.AcctNumber == linkAccId;
}

ApplicationPanelNew.prototype.enableAccountLinking = function ()
{
    if (!this.get('canLinkByAccount')) { return; }

    const accId = LinkedSystem.getAccount(LinkedSystemAccLinkingValue);
    this.updateAccountLinkVisible(true);

    this.filterByAccountNumber(accId);
}

ApplicationPanelNew.prototype.disableAccountLinking = function ()
{
    if (!this.get('canLinkByAccount')) { return; }

    this.updateAccountLinkVisible(false);

    this.getQuickTable()?.setAllRowsVisibility(true);
}

ApplicationPanelNew.prototype.filterByAccountNumber = function (accId = '')
{
    const qt = this.getQuickTable();
    if (!this.get('canLinkByAccount') || qt == null) { return; }

    qt.filterByAccountNumber(accId);
}

ApplicationPanelNew.prototype.updateAccountLinkVisible = function (visible, force = false)
{
    if (!this.get('canLinkByAccount') && !force) { return; }

    this.set('isAccountLinkShow', visible)
    this.updateLinkVisible()
}

ApplicationPanelNew.prototype.getIsAccountLinkShow = function ()
{
    return this.get('isAccountLinkShow') && !DataCache.EnableForceLinkingByAccount();
}

ApplicationPanelNew.prototype.OnAccountLinking = function () { return this.get('isAccountLinkShow'); }

ApplicationPanelNew.prototype.getXmlSettingsTemplate = function ()
{
    const qt = this.getQuickTable();
    return qt != null ? qt.xmlSettingsTemplate : {}
}

ApplicationPanelNew.prototype.setXmlSettingsTemplate = function (value)
{
    const qt = this.getQuickTable();
    if (qt != null)
    {
        qt.xmlSettingsTemplate = value;
        this.visibleColumnChanged(qt.getVisibleColumn());
    }
    else { this.deferredXmlSettingsTemplate = value; }
}

ApplicationPanelNew.prototype.setSettings = function (json)
{
    this.callBack(DynProperty.deserialize(JSON.parse(json)));
}

ApplicationPanelNew.prototype.getSettings = function ()
{
    return JSON.stringify(DynProperty.serialize(this.Properties()));
}

ApplicationPanelNew.prototype.setVisibleColumns = function (arr)
{
    const qt = this.getQuickTable();
    if (qt == null) { return; }

    qt.setVisibleColumn(arr);
}

ApplicationPanelNew.prototype.workWithToolBar = function () { }

ApplicationPanelNew.prototype.onKeyDown = function (event)
{
    TerceraWindowBase.prototype.onKeyDown.call(this, event);
    const original = event.original;
    if (original.keyCode < 48 || original.keyCode > 90 ||
        original.altKey || original.ctrlKey || original.shiftKey) { return true; }

    const activeCtrl = this.get('activeControl');
    if (activeCtrl)
    {
        const actType = activeCtrl.getType();
        if (ApplicationPanelNew._striplostFocusTypes.indexOf(actType) !== -1)
        {
            return true;
        }
    }
    this._onKeyDownProcess(event);
}

ApplicationPanelNew.prototype._onKeyDownProcess = function (event)
{
    var controls = this.Controls
    if (controls)
    {
        var lookup = controls.TerceraInstrumentLookup;
        if (!lookup)
            if (controls.topPanel)
                controls = controls.topPanel.Controls;
        if (controls)
            lookup = controls.TerceraInstrumentLookup;
        if (!lookup)
            return;

        if (!lookup.get('focused'))
        {
            if (lookup.textBox !== null)
            {
                lookup.textBox.set({ text: '', skipfocus: true })
                lookup.setFocus();
                lookup.onKeyDown(event);
            }
        }
    }
}

ApplicationPanelNew._striplostFocusTypes = ['TerceraAccountLookup', 'TerceraNumeric', 'TerceraTextBox', 'TerceraQuantityNumeric', 'TerceraSLTPNumeric', 'NAME_ORDERCOMPOSIT_PANEL', 'DynPropertyControl', 'OEQuantity', 'AdvancedChartOrderEntry', 'TerceraVisualTradingWidget'];

ApplicationPanelNew.prototype.setVisibility = function (visible)
{
    this.set({ visible: visible });
}

/// <summary>
/// Инструмент выбранный в таблице (первый если их несколько)
/// </summary>
ApplicationPanelNew.prototype.getInstrument = function ()
{
    const qt = this.getQuickTable();
    if (!qt || !qt.selectedRowIds || !qt.selectedRowIds[0]) { return null; }

    const row = qt.rows[qt.selectedRowIds[0]];
    return row?.item?.GetCurrentInstrument() ?? null;
}

/// <summary>
/// Аккаунт
/// </summary>
ApplicationPanelNew.prototype.getAccount = function ()
{
    let account = this.get('account') || this.get('accountItem');
    if (account.isHide) { account = DataCache.getPrimaryAccount(); }

    return account;
}

ApplicationPanelNew.prototype.openSymbolInfo = function ()
{
    const ins = this.getInstrument();
    if (!ins) { return; }

    MainWindowManager.MainWindow.ShowSymbolInfo(ins, this.getAccount());
}

ApplicationPanelNew.prototype.AddSymbolInfoContextMenuItemIfNeed = function (items, addSeparator)
{
    if (CustomerAccess.panelAllowed(PanelNames.SymbolInfoPanel))
    {
        if (addSeparator !== false)
            items.push({ separator: true })

        let key = 'panel.watchlist.menu.SymbolInfo'

        items.push({
            locKey: key,
            text: Resources.getResource(key),
            event: this.openSymbolInfo.bind(this),
            enabled: false,
            disabledReason: null,
            tag: ApplicationPanelNew.SYMBOLINFOPANEL
        })
    }
}

ApplicationPanelNew.prototype.AddOpeningPanelsCM = function (items)       
{
    let lokKeys = this.GetOpeningPanelsCMLocKeysSet();
    let lK = lokKeys[ApplicationPanelNew.OPEN_CHART];
    let newItems = []

    if (!Resources.isHidden(lK))
        newItems.push({
            locKey: lK,
            event: this.openPanel.bind(this, PanelNames.ChartPanel),
            enabled: false,
            tag: ApplicationPanelNew.OPEN_CHART
        })

    lK = lokKeys[ApplicationPanelNew.OPEN_MD];
    if (!Resources.isHidden(lK))
        newItems.push({
            locKey: lK,
            event: this.openPanel.bind(this, PanelNames.MarketDepthPanel),
            enabled: false,
            tag: ApplicationPanelNew.OPEN_MD
        })

    lK = lokKeys[ApplicationPanelNew.OPEN_TS];
    if (!Resources.isHidden(lK))
        newItems.push({
            locKey: lK,
            event: this.openPanel.bind(this, PanelNames.TSPanel),
            enabled: false,
            tag: ApplicationPanelNew.OPEN_TS
        })

    lK = lokKeys[ApplicationPanelNew.OPEN_OE];
    if (!Resources.isHidden(lK))
        newItems.push({
            locKey: lK,
            event: this.openPanel.bind(this, PanelNames.AdvancedOrderEntry),
            enabled: false,
            tag: ApplicationPanelNew.OPEN_OE
        })

    if (newItems.length)
    {
        newItems.unshift({ separator: true })
        newItems.push({ separator: true })
    }

    return items.concat(newItems)
}

ApplicationPanelNew.prototype.SetOpeningPanelsCMEnability = function (menuTagDict, enabled)
{
    if (menuTagDict[ApplicationPanelNew.OPEN_CHART])
        menuTagDict[ApplicationPanelNew.OPEN_CHART].enabled = enabled;
    if (menuTagDict[ApplicationPanelNew.OPEN_MD])
        menuTagDict[ApplicationPanelNew.OPEN_MD].enabled = enabled;
    if (menuTagDict[ApplicationPanelNew.OPEN_TS])
        menuTagDict[ApplicationPanelNew.OPEN_TS].enabled = enabled;
    if (menuTagDict[ApplicationPanelNew.OPEN_OE])
        menuTagDict[ApplicationPanelNew.OPEN_OE].enabled = enabled;
}

ApplicationPanelNew.prototype.getSelectedInstrumentID = function () { return '' }

ApplicationPanelNew.prototype.GetOpeningPanelsCMLocKeysSet = function () { return {}; }

ApplicationPanelNew.prototype.openPanel = function (panelName)
{
    var ins = this.getSelectedInstrumentID();
    MainWindowManager.Factory.addPanel(panelName, null, function (panel) { panel.symbolLink_In(ins); });
}

ApplicationPanelNew.OPEN_CHART = 'openChart';
ApplicationPanelNew.OPEN_MD = 'openMD';
ApplicationPanelNew.OPEN_TS = 'openTS';
ApplicationPanelNew.OPEN_OE = 'openOE';

ApplicationPanelNew.SYMBOLINFOPANEL = 'SymbolInfoPanel';

ApplicationPanelNew.prototype.getSelectedItemsNumber = function ()                  
{
    let qt = this.getQuickTable();
    if (!qt) return 0

    let selectedRowIds = qt.selectedRowIds
    if (selectedRowIds && selectedRowIds.length)
        return selectedRowIds.length

    return 0
}

ApplicationPanelNew.prototype.getSelectedDifferentItemsNumber = function ()
{
    const qt = this.getQuickTable();
    if (!qt) { return 0; }

    const selRowIds = qt.selectedRowIds;
    if (!selRowIds || selRowIds.length === 0) { return 0; }

    const firstItem = this.getInstrument();
    if (!firstItem) { return 0; }

    const firstID = firstItem.GetInteriorID();
    for (let i = 1; i < selRowIds.length; i++)           // может быть выбрано несколько ордеров(позиций) одного и того же инструмента
    {
        const selRowID = selRowIds[i];
        const row = selRowID ? qt.rows[selRowID] : null;
        const ins = row?.item?.GetCurrentInstrument() ?? null;

        if (!ins || ins.GetInteriorID() != firstID)      // выбраны разные инструменты 
        {
            return selRowIds.length;                     // пока нам достаточно знать, что выбрано хотя бы 2 разных инструмента
        }
    }

    return 1;                                            // нулевой выбранный совпадает с остальными выбранными - это один и тот же инструмент
}

ApplicationPanelNew.prototype.updateSymbolInfoMenuItem = function ()
{
    let menuArr = this.menuTagDict;
    let menuItem = menuArr ? menuArr[ApplicationPanelNew.SYMBOLINFOPANEL] : null;
    this.updateMenuItem(menuItem)
}

ApplicationPanelNew.prototype.updateMenuItem = function (menuItem)
{
    if (!menuItem)
        return

    let moreThanOneTTKey = this.moreThanOneTTKey,
        noOneTTKey = this.noOneTTKey

    let ins = this.getInstrument(),
        selInsNum = this.getSelectedDifferentItemsNumber(),
        enabled = !!(selInsNum == 1 && ins),
        tooltip = enabled ? '' : Resources.getResource(selInsNum ? moreThanOneTTKey : noOneTTKey)

    if (!ins && this.getSelectedItemsNumber() > 0) // выбранного инструмента нет у нас в Instruments (к примеру строка по скрытому или удаленному инструменту в Orders history)
        tooltip = this.noSymbolWasFoundTooltip

    menuItem.enabled = enabled;
    menuItem.tooltip = tooltip;
    menuItem.style = enabled ? '' : 'js-context-menu-error';
}

ApplicationPanelNew.prototype.CustomPreparation = function () { }

// help in migration process: checking if properties for callback are correct and suitable for current version
ApplicationPanelNew.prototype.validateProperties = function (properties) { return true; }

// help in migration process: get lost WSid
ApplicationPanelNew.prototype.GetWSIdFromProperty = function (properties) 
{
    return DynProperty.getPropValue(properties, 'WSId');
}

ApplicationPanelNew.prototype.RefreshInstrumentDescriptions = function (insArray) { }

ApplicationPanelNew.tableToHtml = function (cols, rows, tableTitle)
{
    const visibleColumns = cols.filter(col => col.visible && !col.hidden);
    const tableHeader = visibleColumns.map(col => `<th style="border-collapse:collapse;border:thin solid black;">${col.headerText}</th>`).join('');
    const visibleColumnsID = visibleColumns.map(col => col.PRIVATE.index);

    const tableRows = rows.filter(row => row.visible).map(row =>
    {
        const cells = visibleColumnsID.map(col => `<td style="border:none" align="center">${row.cells[col].formattedValue}</td>`);
        return `<tr>${cells.join('')}</tr>`;
    }).join('');

    const currentDate = DateTimeUtils.formatDate(new Date(), 'DD.MM.YYYY HH:mm');
    const acc = DataCache.getPrimaryAccount();

    const additionalInfo = `
        <h3>${DataCache.CompanyName || ''}</h3>
        <div style="position:absolute;right:24;width:242;">
            <table width="100%" style="border:none">
                <tr><td>${Resources.getResource('panel.OrderBook.Date')}:</td><td align="right">${currentDate}</td></tr>
                <tr><td>${Resources.getResource('panel.OrderBook.Login')}:</td><td align="right">${DataCache.UserLogin}</td></tr>
                <tr><td>${Resources.getResource('panel.accounts.Owner')}:</td><td align="right">${acc.FirstName} ${acc.LastName}</td></tr>
                <tr><td>${Resources.getResource('property.currency')}:</td><td align="right">${DataCache.getUserBaseCurrency()}</td></tr>
            </table>
        </div><br><br><br><br><br>`;

    return `${additionalInfo}<table width="100%"><caption><h4>${tableTitle}</h4></caption><tr>${tableHeader}</tr>${tableRows}</table>`;
}

ApplicationPanelNew.prototype.printTable = function ()
{
    const qt = this.getQuickTable();
    const title = Resources.getResource(PanelLocKeys[this.getType()]);
    const printWin = window.open('', '', 'width=' + screen.availWidth + ',height=' + screen.availHeight);
    const doc = printWin.document;

    doc.open();
    doc.write(ApplicationPanelNew.tableToHtml(qt.sortedColumns, qt.rowsArray, title));
    doc.close();

    printWin.print();

    const closePrintWin = () => { printWin.close(); };
    if (BrowserUtils.isChrome)
    {
        printWin.onafterprint = closePrintWin;
    }
    else { closePrintWin(); }
}

ApplicationPanelNew.prototype.getCallBackInstrument = function (properties, propName)
{
    let dp = DynProperty.getPropertyByName(properties, propName);
    let symbol = null;
    if (dp && dp.value)
        symbol = DataCache.getInstrumentByName(dp.value);
    if (!symbol)
        symbol = SessionSettings.getFirstTradableInstrument();
    return symbol;
}