// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../properties/Resources';
import { DataCacheToolType } from '../DataCacheToolEnums';
import { BaseFibonacciDataCacheTool } from './BaseFibonacciDataCacheTool';
import { DynProperty } from '../../DynProperty';

export class FibonacciArcDataCacheTool extends BaseFibonacciDataCacheTool {
    showFullArc: boolean;

    /**
     * Constructor for FibonacciArcDataCacheTool
     */
    constructor () {
        super();
        this.showFullArc = false;
        this.ToolType = DataCacheToolType.FibonacciArc;
        this.InitPoints(402); // hard code na 10 urovney
    }

    /**
     * Initializes the block for the tool
     */
    protected InitBlock (): void {
        super.InitBlock();
        this.fibLevel = [38.2, 50, 61.8];
        this.showFullArc = false;
    }

    /**
     * Gets the maximum points count required for the tool
     * @returns {number} - Maximum points count
     */
    MaxPointsCount (): number {
        return 2;
    }

    /**
     * Gets the minimum points count required for the tool
     * @returns {number} - Minimum points count
     */
    MinPointsCount (): number {
        return 2;
    }

    /**
     * Gets the additional points count required for the tool
     * @returns {number} - Additional points count
     */
    AdditionalPointsCount (): number {
        return 400; // hard code na 10 urovney
    }

    /**
     * Properties for FibonacciArcDataCacheTool
     * @returns {DynProperty[]} - Array of dynamic properties
     */
    Properties (): DynProperty[] {
        const props = super.Properties();

        let prop = new DynProperty(Resources.getResource('Show Full Arc'), this.showFullArc, DynProperty.BOOLEAN, DynProperty.VISUAL_GROUP);
        prop.propertyComment = Resources.getResource('property.ShowFullArc');
        props.push(prop);

        prop = new DynProperty(Resources.getResource('EnableNegativeFibonacciValue'), false, DynProperty.BOOLEAN, /* DynProperty.FIBONACCI_PARAMETERS_GROUP */DynProperty.HIDDEN_GROUP);
        props.push(prop);

        prop = new DynProperty('chart.tools.fibretr.fontcolor', this.FontColor, DynProperty.COLOR, DynProperty.VISUAL_GROUP);
        props.push(prop);

        return props;
    }

    /**
     * Callback function for handling dynamic property changes
     * @param {DynProperty[]} properties - Array of dynamic properties
     */
    callBack (properties: DynProperty[]): void {
        super.callBack(properties);

        let prop = DynProperty.getPropertyByName(properties, 'Show Full Arc');
        if (prop != null) this.showFullArc = prop.value;

        prop = DynProperty.getPropertyByName(properties, 'chart.tools.fibretr.fontcolor');
        if (prop != null) this.FontColor = prop.value;
    }
}
