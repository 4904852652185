// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { NotificationsPanelSideBarTemplate } from '../../../../templates.js';
import { DataCache } from '../../../../Commons/DataCache';
import { HistoricalBM } from '../../../../Commons/cache/HistoricalBM';
import { ControlsTypes } from '../../../UtilsClasses/FactoryConstants';
import { HtmlScroll, HtmlScrollXY } from '../../../../Commons/HtmlScroll';
import { LocalStorage } from '../../../../Commons/LocalStorage';
import { type NotificationsItemSideBar } from '../SideBarItems/NotificationsItemSideBar';
import { BrokerMessageResponseType } from '../../../../Utils/Notifications/BrokerMessageResponseType';
import { UrlUtils } from '../../../../Utils/UrlUtils';
import { PanelSideBarBase } from './PanelSideBarBase';
import { Resources } from '../../../../Commons/properties/Resources';

export class NotificationsPanelSideBar extends PanelSideBarBase {
    static NO_CATEGORY_COLOR: string = '#505050';
    private customButtonsClickEventHandlers = [];

    constructor () { super(); }

    oncomplete (): void {
        super.oncomplete();

        this.eventsSubscribe();
        this.GetBMHistory();
        this.scrollCardAdd();

        this.on('onCardClick', this.cardClick);
        this.on('categoryClick', this.categoryClick);
        this.observe('categories', this.onCategoriesChanged);
    }

    onteardown (): void {
        this.eventsUnSubscribe();
    };

    private cardClick (event, item: NotificationsItemSideBar): void {
        if (isNullOrUndefined(item)) {
            return;
        }

        const selectedCardID: number = this.get('selectedCardID');
        if (selectedCardID !== item.ItemID) {
            this.changeSelectedCard(false);
            void this.set('selectedCardID', item.ItemID)
                .then(() => {
                    this.processWasReadResponse();
                    this.createCustomButtonsClickEvents();
                    this.changeSelectedCard(true);
                    this.cacheSelectedCardNumber();
                });
        } else {
            this.changeSelectedCard(false);
        }
    }

    private cacheSelectedCardNumber (): void {
        const cards = this.get('cards');
        const selected = this.getSelectedCard(cards);

        if (!isNullOrUndefined(selected)) {
            LocalStorage.setNotificationPanelSelectedCards(selected.ID);
        }
    };

    private eventsSubscribe (): void {
        DataCache.OnProcessBrokerMessage.Subscribe(this.addBrokerMessage, this);
    }

    private eventsUnSubscribe (): void {
        DataCache.OnProcessBrokerMessage.UnSubscribe(this.addBrokerMessage, this);
    }

    private GetBMHistory (): void {
        const userID = DataCache.getUserID();
        DataCache.SendBrokerMessageHistoryRequest(userID).then((msg) => {
            this.createAllMessages(msg);
        });
    }

    private addBrokerMessage (histBM): void {
        if (histBM.ShowPopUp !== false) {
            histBM.IsUnread = false;
        }

        const bmToAdd = this.createHistoricalBM(histBM);

        this.AddBMHistory(bmToAdd); // #110883 need to add not only BROKER_MESSAGE_NOTIFICATION_TYPE but also BM of other types
    };

    private AddBMHistory (BMHist): void {
        if (!BMHist) {
            return;
        }

        let allMsgs = this.get('allMessages');
        if (!allMsgs) { allMsgs = []; } // #110909

        // this.setCardLineHeight([BMHist]); // #111241+

        allMsgs = [BMHist].concat(allMsgs);

        void this.set('allMessages', allMsgs).then(() => {
            this.createCategories();
        });
    };

    private createAllMessages (msg): void {
        if (msg?.length) {
            msg = msg[0];
            const histBMs = msg.HistoricalBMs;

            if (histBMs) { // сортуємо за датою щоб відображати спочатку нові
                histBMs.sort(function (a, b) {
                    return b.TimeStamp.getTime() - a.TimeStamp.getTime();
                });

                for (let i = 0; i < histBMs.length; i++) {
                    histBMs[i] = this.createHistoricalBM(histBMs[i]);
                }

                void this.set('allMessages', histBMs).then(() => {
                    this.createCategories();
                });
            }
        }
    }

    private createHistoricalBM (histBMInfo): HistoricalBM {
        let result = histBMInfo;
        if (!histBMInfo.InstanceOfHistoricalBM) {
            const bm = new HistoricalBM();
            bm.UpdateByInfo(histBMInfo);
            result = bm;
        }

        return result;
    }

    private createCategories (): void {
        const categories = [];
        const allMessages = this.get('allMessages');

        if (!allMessages) {
            return;
        }

        const catObj = {};
        const colorSet = [NotificationsPanelSideBar.NO_CATEGORY_COLOR];// ['#101720'] //<- #110923 // */['#FF2D55', '#FF9500', '#4CD964', '#007AFF', '#EA606F', '#00FFFF', '#FC5BAC', '#33EEAA', '#FF4224', '#5500FF', '#49A191']
        let colorInd = -1;
        let hasAnyCardWithoutCategory = false; // чи є BMs без назначеної категорії(продукту)
        for (let i = 0; i < allMessages.length; i++) {
            const msg = allMessages[i];
            if (!msg) continue; // #110909

            const catName = msg.Category; // aka product.Name

            if (!catName) { // у нотіка нема продукту -> знач в нього нема категорії в тулбарі та сірий колір
                hasAnyCardWithoutCategory = true;
            }

            if (catName && !catObj[catName]) {
                const product = DataCache.EntitlementManager.ProductsCache[msg.ProductID];
                const colorInProduct = product ? product.ColorHEX : null;
                const validColor = this.isValidHexColor(colorInProduct);
                const color = validColor ? colorInProduct : colorSet[++colorInd];

                catObj[catName] = true;
                categories.push(new NotificationCategory(catName, color, true));

                if (colorInd + 1 === colorSet.length) { colorInd = -1; }
            }
        }
        categories.sort(NotificationCategory.sorting);
        if (hasAnyCardWithoutCategory) {
            categories.unshift(new NotificationCategory('All', NotificationsPanelSideBar.NO_CATEGORY_COLOR, true));
        } // #110919

        void this.set('categories', categories).then(() => {
            this.createCategoriesColorObject();
            this.prepareVisibleCategories();

            const selectedCategory = this.get('selectedCategory');
            this.selectCategory(selectedCategory === null ? 0 : selectedCategory); // #110919, #111226
            this.prepareCardStyle();
        });
    };

    private categoryClick (event, index): void {
        if (this.get('selectedCategory') !== index) {
            this.changeSelectedCard(false);
            this.selectCategory(index);
        }
    }

    private changeSelectedCard (param: boolean): void {
        const cards = this.get('cards');
        const selected = this.getSelectedCard(cards);
        if (isNullOrUndefined(selected)) {
            return;
        }

        selected.IsSelected = param;
        void this.set('cards', cards);

        if (param) {
            void this.set('selectedCard', selected);
            this.createCustomButtonsClickEvents();
            this.find('iframe').onload = () => {
                const iframe: HTMLIFrameElement = this.find('iframe');
                const iframeDocument = iframe.contentWindow.document;
                const iframeBody = iframeDocument.body;
                iframeBody.style.fontFamily = 'Trebuchet MS';
                iframeBody.style.fontStyle = 'normal';
                iframeBody.style.color = '#E3E1E2';
            };
        } else {
            void this.set({
                selectedCard: null,
                selectedCardID: null
            });
        }
    }

    private selectCategory (index): void {
        const categories = this.get('categories');
        if (isNullOrUndefined(categories) || categories.length === 0) {
            return;
        };

        const selectedCategory = categories[index];
        const selectedCategoryName = selectedCategory.name;
        const addAll = index === 0; // selectedCategoryName === 'All',
        const allMessages = this.get('allMessages');
        const cards = [];

        for (let i = 0; i < allMessages.length; i++) {
            const msg = allMessages[i];
            if (addAll || msg.Category === selectedCategoryName) { cards.push(msg); }
        }

        void this.set({
            cards,
            selectedCategory: index
        });
    }

    private prepareVisibleCategories (): void {
        if (this.get('visibleCatIndex') === null) {
            void this.set('visibleCatIndex', 0);
        }

        this.updateToolbarCategoriesNum();
    }

    private updateToolbarCategoriesNum (): void {
        const categories = this.get('categories');
        if (isNullOrUndefined(categories)) {
            return;
        }

        void this.set('visibleCatNum', true);
    }

    private isValidHexColor (hexStr): boolean {
        if (!hexStr || hexStr.length !== 7 || hexStr[0] !== '#') {
            return false;
        }

        return true;
    }

    private createCategoriesColorObject (): void {
        const categories = this.get('categories');
        if (isNullOrUndefined(categories)) {
            return;
        }

        const result = {};
        for (let i = 0; i < categories.length; i++) {
            const c = categories[i];
            if (!result[c.name]) { result[c.name] = c.colorHEXstr; }
        }

        result[''] = NotificationsPanelSideBar.NO_CATEGORY_COLOR; // немає прив'язаного продукту

        void this.set('categoriesColor', result);
    };

    private prepareCardStyle (): void {
        const allMessages = this.get('allMessages');
        if (isNullOrUndefined(allMessages)) {
            return;
        };

        const categoriesColor = this.get('categoriesColor');
        for (const card of allMessages) {
            if (categoriesColor[card.Category]) {
                card.colorHEXstr = categoriesColor[card.Category];
            }
        }

        void this.set('allMessages', allMessages).then(() => {
            void this.set('cards', this.get('cards')).then(() => {
                // this.openCachedSelectedCard();
            });
        });
    }

    // private openCachedSelectedCard (): void {
    //     const selectedCardID = +LocalStorage.getNotificationPanelSelectedCards();

    //     if (selectedCardID) {
    //         void this.set('selectedCardID', selectedCardID);
    //         const cards = this.get('cards');
    //         for (let i = 0; i < cards.length; i++) {
    //             const card = cards[i];
    //             if (card.ID === selectedCardID) {
    //                 this.changeSelectedCard(true);
    //                 return;
    //             }
    //         }
    //     }
    // }

    private onCategoriesChanged (newValue): void {
        this.updateCategoryCounter();
    }

    private processWasReadResponse (): void {
        const cards = this.get('cards');
        const selected = this.getSelectedCard(cards);

        if (isNullOrUndefined(selected) && !selected.IsUnread) {
            return;
        }

        selected.IsUnread = false;
        void this.set('cards', cards);

        this.updateCategoryCounter();
        this.prepareCardStyle();

        const userID = DataCache.getUserID();
        const userName = DataCache.getUserName();
        const brokerMsgID = selected.ID;
        const clusterNode = selected.ClusterNode;

        DataCache.SendBrokerResponseMessage(brokerMsgID, '', userID, userName, BrokerMessageResponseType.WAS_READ, clusterNode);
    }

    private updateCategoryCounter (): void {
        const categories = this.get('categories');
        const allMessages = this.get('allMessages');
        const categoriesNum = [];

        if (isNullOrUndefined(categories) || isNullOrUndefined(allMessages)) {
            return;
        }

        for (let j = 0; j < categories.length; j++) {
            const cat = categories[j];
            let cNum = 0;

            for (let i = 0; i < allMessages.length; i++) {
                if ((allMessages[i].Category === cat.name || j === 0) && allMessages[i].IsUnread) { // у тулбарі в скобках біля категорії показуємо кількість НЕПРОЧИТАНИХ нотіків
                    cNum++;
                }
            }

            categoriesNum.push(cNum);
        }

        void this.set('unreadByCategory', categoriesNum);
    }

    private getSelectedCard (cards: HistoricalBM[]): HistoricalBM {
        const selectedCardID = this.get('selectedCardID');
        let selected = null;
        for (const card of cards) {
            if (card.ID === selectedCardID) {
                selected = card;
                break;
            }
        }

        return selected;
    }

    private createCustomButtonsClickEvents (): void {
        const clickEvent = (url, customBtnTitle, clickedIndex): void => {
            UrlUtils.openFixedURL(url);
            this.processResponseCustomButtons(customBtnTitle, clickedIndex);
        };

        const cards = this.get('cards');
        const selectedCard = this.getSelectedCard(cards);

        this.cancelOldCustomButtonsClickEvent(); // #110916

        const handlers = this.customButtonsClickEventHandlers;
        const cbs = !isNullOrUndefined(selectedCard) ? selectedCard.CustomButtons : null;
        if (!isNullOrUndefined(cbs)) {
            for (let i = 0; i < cbs.length; i++) {
                handlers.push(this.on('onCustomButton' + i + 'Click', clickEvent.bind(this, cbs[i].URL, cbs[i].TitleForResponse, i)));
            }
        }
    }

    private cancelOldCustomButtonsClickEvent (): void {
        const handlers = this.customButtonsClickEventHandlers;
        if (!handlers) {
            return;
        };

        for (let i = 0; i < handlers.length; i++) {
            handlers[i].cancel();
        }

        this.customButtonsClickEventHandlers = [];
    }

    processResponseCustomButtons (cBtnTitle, clickedIndex): void { // https://tp.traderevolution.com/entity/109652 send BrokerResponseMessage when custom buttons clicked
        const cards = this.get('cards');
        if (!cards) {
            return;
        };

        const selCard = this.get('selectedCard');
        const brokerMsgID = selCard ? selCard.ID : null;

        const userID = DataCache.getUserID();
        const userName = DataCache.getUserName();
        const value = cBtnTitle.toString();
        const clusterNode = selCard.ClusterNode;

        DataCache.SendBrokerResponseMessage(brokerMsgID, value, userID, userName, BrokerMessageResponseType.CUSTOM_BTNS, clusterNode);
    }

    public getType (): ControlsTypes {
        return ControlsTypes.NotificationsPanelSideBar;
    };

    private scrollCardAdd (): void {
        const cardsItemContainer = this.find('.notificationsPanelSideBar-container-body-left');
        if (!isNullOrUndefined(cardsItemContainer)) {
            HtmlScroll.addScroll(cardsItemContainer);
        }
    };

    public localize (): void {
        void this.set({
            noDataText: Resources.getResource('panel.Notifications.noNotificationsText')
        });
    };
};

PanelSideBarBase.extendWith(NotificationsPanelSideBar,
    {
        template: NotificationsPanelSideBarTemplate,
        data: function () {
            return {
                allMessages: null, // [] of HistoricalBrokerMessageInfo
                cards: null, // [] of HistoricalBM
                categories: null, // [] - all
                unreadByCategory: null, // [] - кількість непрочитаних за категоріями (значення у лічильниках у тулбарі) в тому ж порядку
                visibleCatIndex: null, // int - first current index of categories that toolbar start with
                visibleCatNum: null, // int - number of categories visible in toolbar at the moment
                categoriesColor: null, // {'categories[i].name': categories[i].colorHEXstr}
                selectedCategory: null, // int
                selectedCardID: null, // int
                selectedCard: null, // object card
                noDataImg: 'notifications'
            };
        },
        computed: {
            headerBodyRightOpened:
            {
                get: function () {
                    return this.get('selectedCard') === null ? '' : 'notificationsPanelSideBar-container-header-opened';
                }
            }
        }
    });

class NotificationCategory {
    name: string = 'All';
    colorHEXstr: string = NotificationsPanelSideBar.NO_CATEGORY_COLOR;
    visible: boolean = true;
    constructor (name, color, visible) {
        this.name = name;
        this.colorHEXstr = color;
        this.visible = visible;
    }

    public static sorting (a: NotificationCategory, b: NotificationCategory): number {
        return a.name.localeCompare(b.name);
    }
}
