// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { ExpandDoubleVector } from "../../DoubleMatrix.ts";
import { IndicatorFunctions } from "../../IndicatorFunctions.ts";
import { iBuildInIndicator } from "../../iBuildInIndicator.ts";

export let iForce = function (maPeriod, maMethod, priceType)
{
    iBuildInIndicator.call(this, 1)
    this.FMaPeriod = maPeriod;
    this.FMaMethod = maMethod;
    this.FPriceType = priceType;

    this.ma = new ExpandDoubleVector();
    this.maValuePrev = 0;
}
iForce.prototype = Object.create(iBuildInIndicator.prototype)

Object.defineProperty(iForce.prototype, 'Name',
    {
        get: function () { return 'iForce' }
    })
iForce.prototype.NextBar = function (callBound)
{
    iBuildInIndicator.prototype.NextBar.call(this, callBound)
    this.ma.Add(0.0);
}
iForce.prototype.Refresh = function (count, newThread)
{
    this.ma.Dispose()
    this.ma = new ExpandDoubleVector();
    iBuildInIndicator.prototype.Refresh.call(this, count, newThread)
}
iForce.prototype.OnQuote = function (ci, callBound, callFromRefresh)
{
    let price = this.GetPrice(this.FPriceType, 0)
    this.ma[this.ma.Length - 1] = price;
    let Parent = this.Parent
    if (!Parent || this.FCount < this.FMaPeriod)
        return
    let maValue = IndicatorFunctions.CallMovingFunction(this.FMaMethod, this.ma, this.FMaPeriod, 1);
    this.SetValue(Parent.GetVolume(0) * (maValue - this.maValuePrev));
    this.maValuePrev = maValue;
}