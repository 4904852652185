// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
// TODO. Duplicate. Almost the same as LimitOrderEdit.ts.
import { Intervals } from '../../../../Utils/Instruments/Intervals';
import { OrderType } from '../../../../Utils/Trading/OrderType';
import { DynProperty } from '../../../DynProperty';
import { NumericUtils } from '../../../../Utils/NumericUtils';
import { OrderExecutorUtils } from '../../../Trading/OrderExecutorUtils';
import { OrderEditUpdateData } from '../../../../Utils/Trading/OrderEditUpdateData';
import { GeneralSettings } from '../../../../Utils/GeneralSettings/GeneralSettings';
import { SLTPEdit } from '../SLTPEdit';
import { OrderEditBaseUtils } from '../../../../Utils/Trading/OrderEditBaseUtils';
import { OrderEditBase } from './OrderEditBase';

export class StopOrderEdit extends OrderEditBase {
    public needSetDefaultPrice: boolean;

    constructor (data) {
        super(data);

        this.stopPrice = null;
        this.sltp = new SLTPEdit(data.dataCache, data.forceSLTPOffset);
        this.sltpBasePriceGetter = this.sltpBasePriceGetter.bind(this);

        this.needSetDefaultPrice = false;
    }

    public override getParameterNameArray (): string[] {
        return [
            OrderEditBaseUtils.STOP_PRICE_PARAM,
            OrderEditBaseUtils.POSITION_SIZING_PARAM,
            OrderEditBaseUtils.SLTP_PARAM
        ];
    }

    // TODO. Refactor. Same as OrderTypeBase.Id function.
    public override getOrderTypeId (): OrderType {
        return OrderType.Stop;
    }

    // TODO. Refactor. Use error/warning dicts for sltp.
    // public valid ()
    // {
    //     return OrderEditBase.prototype.valid.call(this) &&
    //         this.sltp.valid()
    // }

    // #region Update Parameter Handlers

    public update_stopPrice (updateData): boolean {
        let parameterChanged = false;

        const dp = updateData.dp;
        if (dp && this.stopPrice !== dp.value && !(isNaN(this.stopPrice) && isNaN(dp.value))) {
            this.stopPrice = dp.value;
            parameterChanged = true || parameterChanged;
        }

        let newInstrument = null;
        let newQuote = null;
        let sideChanged = false;

        const newTradingDataDict = updateData.newTradingDataDict;
        if (newTradingDataDict) {
            newInstrument = newTradingDataDict.instrument;
            newQuote = newTradingDataDict.quote;
            sideChanged = 'side' in newTradingDataDict;
        }

        if (newInstrument) {
            this.stopPrice = null;
            this.needSetDefaultPrice = true;
            parameterChanged = true || parameterChanged;
        }

        if (this.needSetDefaultPrice && newQuote) {
            this.needSetDefaultPrice = false;
            this.stopPrice = newQuote.BidSpread_SP_Ins(this.instrument.DataCache.GetSpreadPlan(this.account), this.instrument);
            parameterChanged = true || parameterChanged;
        }

        if (newQuote || sideChanged || parameterChanged) {
            parameterChanged = this.tryUpdateWarning_stopPrice(
                this.stopPrice,
                this.quote) || parameterChanged;
        }

        return parameterChanged;
    }

    public tryUpdateWarning_stopPrice (stopPrice, quote): any {
        if (!quote) {
            return false;
        }
        const sp = this.instrument.DataCache.GetSpreadPlan(this.account);
        const ask = quote.AskSpread_SP_Ins(sp, this.instrument);
        const bid = quote.BidSpread_SP_Ins(sp, this.instrument);

        const buy = this.buy();

        const newWarning =
            buy && stopPrice <= ask
                ? 'general.trading.stopBuyMoreAsk'
                : !buy && stopPrice >= bid
                    ? 'general.trading.stopSellLessBid'
                    : null;

        return this.setParameterWarning(OrderEditBaseUtils.STOP_PRICE_PARAM, newWarning);
    }

    // #endregion Update Parameter Handlers

    // #region Parameters' DynProperty Generators

    public toDynProperty_stopPrice (): DynProperty {
        const dp = new DynProperty(OrderEditBaseUtils.STOP_PRICE_PARAM);
        dp.type = DynProperty.DOUBLE;

        const ins = this.instrument;
        const intrvls = Intervals.GetIntervalsFromInstrument(ins, NumericUtils.MAXVALUE);
        if (ins) {
            dp.minimalValue = intrvls && (intrvls.length > 0) ? intrvls[0].LeftValue : ins.PointSize;
            dp.maximalValue = 999999999;
            dp.decimalPlaces = ins.Precision;
            dp.increment = ins.PointSize;
        }

        const insDefSettings = GeneralSettings.InsDefSettingsStorage.GetInstrumentSettings(ins);
        if (insDefSettings) {
            dp.increment = ins.PointSize * insDefSettings.PriceIncrementTicks;
        }

        if (intrvls) { dp.Intervals = intrvls; };

        dp.value = this.stopPrice;
        if (dp.value === null || isNaN(dp.value)) {
            dp.value = dp.minimalValue;
        }

        dp.localizationKey = 'panel.newOrderEntry.stopPricePanel';
        return dp;
    }

    // #endregion Parameters' DynProperty Generators

    // #region Raw Values

    public toRawValue_stopPrice (): any {
        return this.stopPrice;
    }

    // #endregion Raw Values

    public validateParameters (): any {
        const updatedParamNameDict = {};

        if (this.sltp.validate(
            this.sltpBasePriceGetter,
            this.sltpBasePriceGetter,
            this.getTradingData())) {
            updatedParamNameDict[OrderEditBaseUtils.SLTP_PARAM] = true;
        }

        updatedParamNameDict[OrderEditBaseUtils.POSITION_SIZING_PARAM] = true;

        return updatedParamNameDict;
    }

    // #region SLTP Price Comparer

    public sltpBasePriceGetter (): any {
        return this.stopPrice;
    }

    // #endregion SLTP Price Comparer

    public override getConfirmationText (): string {
        return OrderExecutorUtils.buildOrderEditConfirmationText(
            'Stop', // TODO.
            this.tif,
            this.account,
            this.instrument,
            this.quantity,
            this.buy(),
            [this.stopPrice],
            this.sltp.getConfirmationText(this.getTradingData()),
            null,
            this.productType,
            OrderType.Stop,
            null,
            this.getLeverageValue());
    }

    // TODO. UGLY. Refactor. details are at the top OrderEditBase.ts
    public setSLTP (sltpHolder): void {
        const dp = this.sltp.createDynPropertyForRawSLTP(
            sltpHolder,
            this.getTradingData(),
            this.sltpBasePriceGetter,
            this.sltpBasePriceGetter);

        this.updateParameters(new OrderEditUpdateData({ sltp: dp }));
    }

    // Returns SlTpHolder instance.
    // TODO. UGLY. Refactor. details are at the top OrderEditBase.ts
    public getRawSLTP (): any {
        return this.sltp.getRawValue(this.getTradingData());
    }

    // TODO. UGLY. Refactor. details are at the top OrderEditBase.ts
    public setStopPrice (price): void {
        const dp = this.createDynPropertyFromParameter(OrderEditBaseUtils.STOP_PRICE_PARAM);
        dp.value = price;

        this.updateParameters(new OrderEditUpdateData({ stopPrice: dp }));
    }

    // TODO. Refactor. Details are in OrderEditBase.ts.
    public setBasePrice (price): void {
        const dp = this.createDynPropertyFromParameter(OrderEditBaseUtils.STOP_PRICE_PARAM);
        dp.value = price;

        this.updateParameters(new OrderEditUpdateData(
            { stopPrice: dp }));
    }

    // TODO. Refactor. Details are in OrderEditBase.ts.
    public getBasePrice (): any {
        return this.stopPrice;
    }
}
