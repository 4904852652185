// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { MessageProcessorBase } from './MessageProcessorBase';
import { FieldsFactory } from '../Factory/FieldsFactory';
import { DirectAccountOperationInfoMessage } from '../../../../Utils/DirectMessages/DirectMessagesImport';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class AccountOperationInfoMessageProcessor extends MessageProcessorBase {
    public override decode (fs: PFixFieldSet): DirectAccountOperationInfoMessage[] {
        const accMsg = new DirectAccountOperationInfoMessage();

        accMsg.ReqerenceId = fs.GetValue(FieldsFactory.FIELD_REQUEST_ID);
        accMsg.RequestId = fs.GetValue(FieldsFactory.FIELD_ORDER_ID).toString();
        accMsg.AccountId = fs.GetValue(FieldsFactory.FIELD_ACCOUNT_ID).toString();

        accMsg.CounterId = fs.GetValue(FieldsFactory.FIELD_COUNTER_ID);
        if (accMsg.CounterId) {
            accMsg.CounterId = accMsg.CounterId.toString();
        }

        accMsg.CrossPrice = fs.GetValue(FieldsFactory.FIELD_CROSS_PRICE);
        accMsg.Amount = fs.GetValue(FieldsFactory.FIELD_AMOUNT);
        accMsg.Mode = fs.GetValue(FieldsFactory.FIELD_MODE);

        accMsg.LastUpdateTime = fs.GetValue(FieldsFactory.FIELD_DATE);

        const value = fs.GetValue(FieldsFactory.FIELD_OPERATION_TYPE2);
        if (value) {
            accMsg.OperationType = value;
        } else {
            accMsg.OperationType = fs.GetValue(FieldsFactory.FIELD_OPERATION_TYPE);
        }

        accMsg.AssetId = fs.GetValue(FieldsFactory.FIELD_ASSET_ID);
        accMsg.Comment = fs.GetValue(FieldsFactory.FIELD_COMMENT);

        return [accMsg];
    }
}
