// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectFundingRateMarkupPlanMessage extends DirectMessageBaseClass {
    public Id: number | null = null;
    public Name: string | null = null;
    public Data: any[] = []; // Example of item is lying in FundingRateMarkupPlanMessageProcessor.ts

    constructor () {
        super(Message.CODE_PFIX_FUNDING_RATE_MARKUP_PLAN_MESSAGE);
    }
}
