// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { ToolViewFactory } from '../../Tools/Utils/ToolViewFactory';
import { DataCacheToolFactory } from '../../../Commons/cache/DataCacheToolFactory';
import { TerceraChartUtils } from '../../TerceraChartUtils';
import { TerceraChartToolRenderer } from './TerceraChartToolRenderer';
import { ArrayUtils } from '../../../Utils/ArrayUtils';

export class TerceraChartToolInstanceRenderer extends TerceraChartToolRenderer {
    constructor (chartID: any, chart: any) {
        super(chartID, chart, null);
        this.SupportDataConverter = true;
        this.SetClassName('TerceraChartToolInstanceRenderer');
    }

    Subscribe (): void {
    // Implement Subscribe method.
    }

    Unsubscribe (): void {
    // Implement Unsubscribe method.
    }

    RepopulateTools (): void {
    // Implement RepopulateTools method.
    }

    AddTool (tool: any): boolean {
        const toolview = ToolViewFactory.CreateToolView(tool, !!this.chartID);
        this.tools.push(toolview);
        // this.callAddedTool(toolview);
        return true;
    }

    RemoveTool (toolView: any): boolean {
    // this.callRemovedTool(toolView);
        super.RemoveTool.call(this, toolView);
        const tV = this.GetToolViewByDataCacheTool(toolView.dataCacheTool);
        if (tV === null) return false;
        tV.Dispose();
        return ArrayUtils.RemoveElementFromArray(this.tools, tV);
    }

    ClearTools (): void {
        this.tools.length = 0;
    }

    DrawAllTools (gr: any, window: any, param: any): void {
        for (let i = 0; i < this.tools.length; i++) {
            const tool = this.tools[i];
            tool.UpdateScreenPoints(window);
            tool.Draw(gr, window, param);
        }
    }

    CloneTool (tool: any, ww: any): void {
        const clonedDCTool = DataCacheToolFactory.Clone(tool.dataCacheTool);
        if (ww !== null) {
            TerceraChartUtils.ShiftTool(clonedDCTool, ww);
            // локальная тулза
            this.AddTool(clonedDCTool);
        }
    }
}
