// Copyright TraderEvolution Global LTD. © 2017-2023. All rights reserved.
import { SideBarButtonTemplate } from '../../../templates';
import { ControlsTypes } from '../../UtilsClasses/FactoryConstants';
import { TerceraButton } from '../TerceraButton';

export class TerceraSideBarButton extends TerceraButton {
    constructor () { super(); }

    public getType (): ControlsTypes {
        return ControlsTypes.TerceraSideBarButton;
    }

    public onMouseDown (event): void {
        super.onMouseDown(event, false);
    }
}

TerceraButton.extendWith(TerceraSideBarButton, {
    template: SideBarButtonTemplate,
    data: function () {
        return {
        };
    }
});
