import { Rectangle } from '../../Geometry';
import { type HeatmapData } from '../Models/HeatmapData';
import { BaseMap } from './BaseMap';
import { IndustryMap } from './IndustryMap';

export class SectorMap extends BaseMap<IndustryMap> {
    private static readonly CONST_HEADER_HEIGHT: number = 16;
    private static readonly CONST_SELECTED_HEADER_HEIGHT = 30;
    public static readonly BORDER: number = 1;

    public sectorName: string;
    public headerRectangle: Rectangle = Rectangle.Empty();
    private readonly parentMapContainer: /* MapContainer */any;

    public isHidden: boolean = false;
    public isSelected: boolean = false;

    public get selectedIndustry (): IndustryMap {
        for (const item of this.items) {
            if (item.isSelected) {
                return item;
            }
        }
        return undefined;
    }

    constructor (parentMapContainer: /* MapContainer */any, sectorName: string, data: HeatmapData[]) {
        super();
        this.parentMapContainer = parentMapContainer;
        this.sectorName = sectorName;
        const groupedData = this.groupByIndustry(data);
        groupedData.forEach(group => {
            this.addItem(new IndustryMap(this, group.key, group.items));
        });
    }

    public get Visible (): boolean {
        return !this.isHidden && this.isVisible();
    }

    public get VisibleCount (): number {
        return this.items.reduce((sum, item) => sum + item.VisibleCount, 0);
    }

    public recalculateArea (): void {
        const headerHeight = this.isSelected || this.items.some(item => item.isSelected) ? SectorMap.CONST_SELECTED_HEADER_HEIGHT : SectorMap.CONST_HEADER_HEIGHT;
        const rect = new Rectangle(this.Rectangle.X + SectorMap.BORDER, this.Rectangle.Y + SectorMap.BORDER, this.Rectangle.Width - 2 * SectorMap.BORDER, this.Rectangle.Height - 2 * SectorMap.BORDER);
        const bodyRect = new Rectangle(rect.X, rect.Y + headerHeight, rect.Width, rect.Height - headerHeight);
        this.headerRectangle = new Rectangle(rect.X, rect.Y, rect.Width, headerHeight);
        this.recalculateTreeMap(bodyRect);
        this.items.filter(y => y.Visible).forEach(x => { x.recalculateArea(); });
    }

    public getSymbolBackgroundColor (intervalDot: number): string {
        return this.parentMapContainer.getSymbolBackgroundColor(intervalDot);
    }

    private groupByIndustry (data: HeatmapData[]): Array<{ key: string, items: HeatmapData[] }> {
        const groups: Record<string, HeatmapData[]> = {};
        data.forEach(item => {
            if (!groups[item.industry]) {
                groups[item.industry] = [];
            }
            groups[item.industry].push(item);
        });
        return Object.keys(groups).map(key => ({ key, items: groups[key] }));
    }
}
