// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

export class EntitlmentDocument {
    public Id: any;
    public Name: any;
    public Content: any;

    constructor (id, name, content) {
        this.Id = id;
        this.Name = name;
        this.Content = content;
    }
}
