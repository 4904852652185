// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts"
import { Control } from "./Control.js"
import { popupErrorHandler } from "../../Utils/AppHandlers.js"
import { TerceraSLTPNumericTemplate } from "../../templates.js"
import { ContainerControl } from "./ContainerControl.js"

export let TerceraSLTPNumeric = ContainerControl.extend({
    data: function ()
    {
        return {
            customErrorText: null,
            forChart: false,        // #107212
            canEdit: true
        }
    },
    warnPopupTimeout: null,
    template: TerceraSLTPNumericTemplate
})

TerceraSLTPNumeric.prototype.getType = function () { return 'TerceraSLTPNumeric' }

TerceraSLTPNumeric.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.call(this)
    this.observe('value', this.onValueChanged)
    this.observe('SLTPChecked', this.onSLTPChecked)
    this.observe('trStopChecked', this.onTrailingStopChecked)
    this.on('terceraNumericInputClick', this.onInputClick);   // #86098

    this.set('customErrorText', Resources.getResource("UserControl.TerceraSLTPNumeric.ValueIsEmpty"))
}

TerceraSLTPNumeric.prototype.GetInterval = function (value, forDecimalPlaces)
{
    if (!this.Controls || !this.Controls.terceraNumeric)
    {
        return null
    }

    return this.Controls.terceraNumeric.GetInterval(value, forDecimalPlaces)
}

TerceraSLTPNumeric.prototype.onValueChanged = function (value)
{
    this.fire(TerceraSLTPNumeric.Events.ValueChanged, this, value)
}

TerceraSLTPNumeric.prototype.onSLTPChecked = function (value)
{
    this.fire(TerceraSLTPNumeric.Events.SLTPChecked, this, value)
}

TerceraSLTPNumeric.prototype.onTrailingStopChecked = function (value)
{
    this.fire(TerceraSLTPNumeric.Events.TrailingStopChecked, this, value)
}

TerceraSLTPNumeric.prototype.setFocus = function (changeEnableState, needActivate)
{
    ContainerControl.prototype.setFocus.apply(this);
    this.Controls.terceraNumeric.setFocus(changeEnableState)
    if (needActivate)
        this.onInputClick();
}

TerceraSLTPNumeric.prototype.gotFocus = function ()
{
    Control.prototype.gotFocus.apply(this)

    if (this.get('forChart'))   // #107212
        this.showPopupWarning()
}

TerceraSLTPNumeric.prototype.onInputClick = function ()
{
    if (!this.get('SLTPChecked'))       // #86098
        this.set('SLTPChecked', true)
}

TerceraSLTPNumeric.prototype.showPopupWarning = function () // #107212
{
    let cntrl = this.Controls.terceraNumeric
    if (!cntrl) return

    if (this.warnPopupTimeout)
        clearTimeout(this.warnPopupTimeout)

    this.warnPopupTimeout = setTimeout(() =>
    {
        let popup = popupErrorHandler
        popup.Show(cntrl, Resources.getResource('UserControl.TerceraSLTPNumeric.ChartOffsetWarning'))
        popup.set({
            'elementStyle': TerceraSLTPNumeric.WARNING_CHART_OFFSET_STYLE,
            'headerVisible': false
        })

        this.warnPopupTimeout = setTimeout(() => { popup.Hide(cntrl) }, TerceraSLTPNumeric.WARNING_HIDE_TIME_MS)
    }, 10)
}

TerceraSLTPNumeric.WARNING_HIDE_TIME_MS = 3000
TerceraSLTPNumeric.WARNING_CHART_OFFSET_STYLE = 'js-win-popupWarning'

TerceraSLTPNumeric.Events = {
    ValueChanged: 'ValueChanged',
    SLTPChecked: 'SLTPChecked',
    TrailingStopChecked: 'TrailingStopChecked'
}