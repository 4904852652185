// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { CustodialPlan } from './CustodialPlan';
import { CustomEvent } from '../../Utils/CustomEvents';
import { type Instrument } from './Instrument';

export class CustodialPlans {
    public UpdateEvent = new CustomEvent();
    public _fCustodialPlans: any = {};

    public GetPlanById (id): any {
        return this._fCustodialPlans[id] || null;
    }

    public Add (message): void {
        let plan = this.GetPlanById(message.Id);
        if (!plan) {
            plan = this._fCustodialPlans[message.Id] = new CustodialPlan(message.Id);
        }
        plan.Update(message);
        this.UpdateEvent.Raise(plan);
    }

    public GetCustodialDataByInstrument (planId, instr: Instrument | null): any {
        if (instr === null || planId === null) { return null; }

        const plan = this.GetPlanById(planId);
        return plan != null && !plan.IsZero() ? plan.GetData(instr) : null;
    }

    public Clear (): void {
        this._fCustodialPlans = {};
    }
}
