// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DateTimeUtils } from "../../Utils/Time/DateTimeUtils.ts";
import { Resources } from "../../Commons/properties/Resources.ts";
import { SoundManager } from "../../Utils/SoundManager.ts";
import { TicketViewerTemplate, TerceraToastTemplate } from "../../templates.js";
import { ControlsTypes } from "../UtilsClasses/FactoryConstants.ts";
import { TerceraTicketScreen } from "../screen/TerceraTicketScreen.js";
import { Control } from "./Control.js";
import { Event } from "../../Commons/cache/Event.ts";
import { EventType } from "../../Commons/cache/EventConstants.ts";
import { EventCache } from "../../Commons/cache/EventCache.ts";
import { GeneralSettings } from "../../Utils/GeneralSettings/GeneralSettings.ts";
import { TicketData } from "../UtilsClasses/TicketData.js";
import { DataCache } from "../../Commons/DataCache.ts";
import { SessionSettings } from "../../Commons/SessionSettings.ts";
import { TooltipType, ReportMessageTooltip, TooltipTypeName } from "../../Utils/ReportMessageTooltip.ts";

// TODO. Refactor.
export let TerceraTicketViewer = Control.extend({
    template: TicketViewerTemplate,
    data: function ()
    {
        return {
            image: '',
            header: '',
            text: '',
            headerClass: 'js-TicketViewer-header',
            imageClass: 'js-TicketViewer-image',
            dealTicketTooltip: '',

            headerIdea: '',         // все что с приставкой Idea относится к дополнительным дилтикетам показываемым левее основных если они есть; пока используем только для дилтикетов торговых идей
            textIdea: '',
            visibleIdea: false,
            imageClassIdea: '',
            headerClassIdea: '',
            dealTicketTooltipIdea: '',
            lastIdeaTicketSetTime: null,
            visible: false,
        };
    },
    ticketData: null,
    queue: [],
    ticketLifetime: 5000, // 5 seconds showing dealticket
    needInit: true
})

TerceraTicketViewer.prototype.oncomplete = function ()
{
    Control.prototype.oncomplete.apply(this);
    this.on('OnClick', this.openTicket);
    this.on('OnClickIdea', this.openLefterTicket);        // additional lefter dealticket
}

TerceraTicketViewer.prototype.getType = function ()
{
    return ControlsTypes.TerceraTicketViewer
}

TerceraTicketViewer.prototype.tryRegisterUpdates = function ()
{
    if (!this.needInit)
        return

    this.needInit = false
    DataCache.OnReportMessage.Subscribe(this.onReportMessage, this)
    DataCache.OnMessageForTicketViewer.Subscribe(this.ProcessMessageForTicketViewer, this)
}

// TODO. Refactor. UGLY.
TerceraTicketViewer.prototype.onReportMessage = function (reportMsg)
{
    // if (!SessionSettings.view.showDealTickets &&
    //     !SessionSettings.sound.enableSound)
    //     return
    if (!DataCache.Loaded)
        return;

    if (!GeneralSettings.Confirmations.ShowDialTicketsInApplicationTray)
        return;

    if (reportMsg.SkipOnTicketViewer)
        return;

    let reportTooltip = new ReportMessageTooltip(reportMsg)
    let reportEvent = EventCache.FromReportMessage(reportMsg)

    let ticket = TerceraTicketScreen.TicketFromEvent(reportEvent)

    ticket.header = reportTooltip.GetHeader()
    ticket.text = reportTooltip.GetText()
    ticket.soundKey = reportTooltip.soundKey;
    ticket.tooltipDrawType = reportTooltip.TooltipDrawType
    ticket.dealTicketTooltip = reportTooltip.dealTicketTooltip ? reportTooltip.dealTicketTooltip : ''
    ticket.showNextTime = GeneralSettings.Confirmations.OpenDialTicketsOnWorkspace;

    ReportMessageTooltip.fixEntries(
        ticket.sortedEntries,
        SessionSettings,
        reportMsg.Instrument)

    ReportMessageTooltip.localizeEntries(ticket.sortedEntries)

    if (ticket.isTrade && ticket.showNextTime)
        TerceraTicketScreen.show(ticket)
    else
        this.showTicket(ticket)
}

TerceraTicketViewer.prototype.ProcessMessageForTicketViewer = function (eventData)
{
    let ticket = new TicketData()
    ticket.header = "";
    ticket.isTrade = true;
    ticket.header = Resources.getResource("reports.FUNCTION_PRODUCTS_FAILURE");
    ticket.tooltipDrawType = TooltipType.AccountOperation;
    ticket.text = Resources.getResource("BusinessRejectMessage." + eventData.ErrorCode)
    let product = DataCache.EntitlementManager.ProductsCache[eventData.ReqProductId];
    let productName = ""
    if (product)
        productName = product.Name;
    ticket.text = ticket.text.replace("{0}", productName)
    ticket.sortedEntries = [{
        caption: "Error",
        text: ticket.text
    }]
    ticket.dealTicketTooltip = ticket.text;
    ticket.showNextTime = GeneralSettings.Confirmations.OpenDialTicketsOnWorkspace;

    let ev = new Event({
        DateTime: DateTimeUtils.DateTimeUtcNow(),
        Action: ticket.header,
        Description: ticket.text,
        EventType: EventType.Trading
    });
    //GOVNO
    if (ev.DescriptionItems[0])
        ev.DescriptionItems[0].Value = ticket.text;
    EventCache.Add(ev);

    if (!GeneralSettings.Confirmations.ShowDialTicketsInApplicationTray)
        return;

    if (ticket.isTrade && ticket.showNextTime)
        TerceraTicketScreen.show(ticket)
    else
        this.showTicket(ticket)
}

TerceraTicketViewer.prototype.showTicketWork = function ()
{
    if (this.queue.length)
    {
        this.prepareTicket(this.queue.shift());
        setTimeout(this.showTicketWork.bind(this), this.ticketLifetime);
    }
    else
    {
        let lastIdeaTicketSetTime = this.get('lastIdeaTicketSetTime'),
            leftTime = lastIdeaTicketSetTime ? this.ticketLifetime - Date.now() + lastIdeaTicketSetTime : 0

        if (leftTime > 0)
            setTimeout(this.hideAllTickets.bind(this), leftTime)    // время жизни основного тикета закончилось но еще висит тикет идеи
        else
            this.hideAllTickets()
    }
}

TerceraTicketViewer.prototype.ClearQueue = function ()
{
    this.queue.length = 0;
    this.hideAllTickets()
}

TerceraTicketViewer.prototype.prepareTicket = function (ticketData)
{
    let needToAddToTheLeft = this.needAddToTheLeftTicket(ticketData)

    this.hideCorrectTicket(needToAddToTheLeft)

    this.setStyleClasses(ticketData)

    needToAddToTheLeft ? this.setDataToLeftTicket(ticketData) : this.setDataToTicket(ticketData)
}

TerceraTicketViewer.prototype.showTicket = function (ticketData)
{
    if (!ticketData) return

    let visible = this.get('visible');
    if (!visible)
    {
        this.prepareTicket(ticketData);
        setTimeout(this.showTicketWork.bind(this), this.ticketLifetime);
    }
    else
    {
        if (ticketData.tooltipDrawType === TooltipType.Idea)
            this.prepareTicket(ticketData);
        else
            this.queue.push(ticketData);
    }

    this.tryPlaySound(ticketData);

    return;
}

TerceraTicketViewer.prototype.openTicket = function ()
{
    TerceraTicketScreen.show(this.ticketData)
}

TerceraTicketViewer.prototype.openLefterTicket = function ()    // событие по нажатию на msg левее основного 
{
    // return // открытие дилтикета с таким же содержимым как в тултипе вероятно и не нужно на десктопе нет
    TerceraTicketScreen.show(this.lefterTicketData)
}

TerceraTicketViewer.prototype.setStyleClasses = function (ticketData)
{
    let isIdeaDealTicket = TerceraTicketViewer.isIdeaDealTicket(ticketData),
        isLefterTicket = this.needAddToTheLeftTicket(ticketData)

    if (isIdeaDealTicket)
    {
        this.set(isLefterTicket ? 'headerClassIdea' : 'headerClass', 'js-idea-header')
        this.set(isLefterTicket ? 'imageClassIdea' : 'imageClass', 'js-idea-image')

    }
    else
        switch (ticketData.tooltipDrawType)
        {
            case TooltipType.CAInfo:
            case TooltipType.CAAccepted:
            case TooltipType.CARejected:
            case TooltipType.CACanceled:
                this.set({
                    headerClass: TooltipTypeName[ticketData.tooltipDrawType], // better to use TooltipType instead of TooltipTypeName
                    imageClass: TooltipTypeName[ticketData.tooltipDrawType]
                });
                break;
            case TooltipType.Trade:
                this.set({
                    headerClass: 'js-TicketViewer-header',
                    imageClass: 'js-TicketViewer-image'
                })
                break
            case TooltipType.AccountOperation:
                this.set({
                    headerClass: 'js-account-operation-header',
                    imageClass: 'js-account-operation-image'
                })
                break
            default:
                this.set({
                    headerClass: 'js-refuse-header',
                    imageClass: 'js-refuse-image'
                })
                break
        }

}

TerceraTicketViewer.prototype.setDataToTicket = function (ticketData)
{
    this.set({
        header: ticketData.header,
        text: ticketData.text,
        dealTicketTooltip: ticketData.dealTicketTooltip ? ticketData.dealTicketTooltip : '',
        visible: true
    });

    this.ticketData = ticketData;
}

TerceraTicketViewer.prototype.setDataToLeftTicket = function (ticketData)
{
    this.set({
        headerIdea: ticketData.header,
        textIdea: ticketData.text,
        dealTicketTooltipIdea: ticketData.dealTicketTooltip ? ticketData.dealTicketTooltip : '',
        visibleIdea: true,
        lastIdeaTicketSetTime: Date.now()
    });

    this.lefterTicketData = ticketData;
}

TerceraTicketViewer.prototype.hideAllTickets = function ()
{
    this.set({ visible: false, visibleIdea: false })
}

TerceraTicketViewer.prototype.hideCorrectTicket = function (needToAddToTheLeft)
{
    let lastIdeaTicketSetTime = this.get('lastIdeaTicketSetTime')
    if (lastIdeaTicketSetTime && Date.now() - lastIdeaTicketSetTime > this.ticketLifetime)      // левый дилтикет не становится в общую очередь, а имеет свой таймер 
        this.set({
            visibleIdea: false,
            lastIdeaTicketSetTime: null
        })

    this.set(needToAddToTheLeft ? 'visibleIdea' : 'visible', false)     // чтобы новый дилтикет выезжал заново а не подменял старый
}

// TODO. IMPORTANT. Move to another place.
TerceraTicketViewer.prototype.tryPlaySound = function (ticketData)
{
    let sndKeys = SoundManager.SoundKeys
    let key = ticketData.soundKey
    if (!key)
    {
        SoundManager.tryPlaySound(sndKeys.OrderRejected)
        return
    }

    key = key.toLowerCase()
    if (key.indexOf('refused') !== -1)
        SoundManager.tryPlaySound(sndKeys.OrderRejected)
    else if (key.indexOf('order created') !== -1)
        SoundManager.tryPlaySound(sndKeys.OrderCreated)
    else if (key.indexOf('order canceled') !== -1)
        SoundManager.tryPlaySound(sndKeys.OrderCanceled)
    else if (key.indexOf('order replaced') !== -1)
        SoundManager.tryPlaySound(sndKeys.OrderReplaced)
    else if (key.indexOf('order rejected') !== -1)
        SoundManager.tryPlaySound(sndKeys.OrderRejected)
    else if (key.indexOf('modified') !== -1)
        SoundManager.tryPlaySound(sndKeys.Modify)
    else if (key.indexOf('margin warning') !== -1)
        SoundManager.tryPlaySound(sndKeys.MarginWarning)
    else if (key.indexOf('order') !== -1 && key.indexOf('filled') !== -1)
        SoundManager.tryPlaySound(sndKeys.OrderFilled)
    else if (key.indexOf('market') !== -1 && key.indexOf('order') !== -1 && key.indexOf('filled') !== -1)
        SoundManager.tryPlaySound(sndKeys.PostionClose)
}

TerceraTicketViewer.prototype.createHtml = function (header, text, isTrade)
{
    let str = TerceraToastTemplate

    return str.replace('{{.header}}', header)
        .replace('{{.text}}', text)
        .replace('{{.imageClass}}', isTrade ? '' : 'js-refuse')
        .replace('{{.headerClass}}', isTrade ? '' : 'js-refuse')
}

TerceraTicketViewer.prototype.needAddToTheLeftTicket = function (ticketData)    // если основное место занято и там не дилтикет идей добавляем новый левее
{
    return TerceraTicketViewer.isIdeaDealTicket(ticketData) && this.get('visible') && this.ticketData && this.ticketData.tooltipDrawType !== TooltipType.Idea
}

TerceraTicketViewer.isIdeaDealTicket = function (ticketData)
{
    return ticketData && ticketData.tooltipDrawType === TooltipType.Idea
}