// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { GeneralSettings } from '../../Utils/GeneralSettings/GeneralSettings';
import { ProductType } from '../../Utils/Instruments/ProductType';
import { OperationType } from '../../Utils/Trading/OperationType';
import { TIF } from '../../Utils/Trading/OrderTif';
import { OrderTifMap } from '../../Utils/Trading/OrderTifEnum';
import { OrderType } from '../../Utils/Trading/OrderType';
import { OrderUtils } from '../../Utils/Trading/OrderUtils';
import { Quantity } from '../../Utils/Trading/Quantity';
import { InstrumentUtils } from '../../Utils/Instruments/InstrumentUtils';
import { Resources } from '../properties/Resources';
import { OrderTypeBaseParameters } from '../../Commons/cache/OrderParams/order-type/OrderTypeBaseParameters';
import { DataCache } from '../../Commons/DataCache';
import { type Account } from '../cache/Account';
import { type Instrument } from '../cache/Instrument';
import { type Order } from '../cache/Order';
import { type Position } from '../cache/Position';

export class OrderExecutorUtils {
// TODO. Remove? Refactor. Lots of params.
    public static buildOrderEditConfirmationText (
        orderTypeKey,
        tif,
        account: Account | null,
        instrument: Instrument,
        quantity,
        buy,
        priceArray,
        sltpText,
        priceInOffset,
        productType,
        orderType,
        RealTrStopPrice,
        leverage): string {
        const isMarket = orderType === OrderType.Market;
        const priceInOffsetText = (isMarket || RealTrStopPrice) ? '' : (Resources.getResource(priceInOffset ? 'general.trading.with offset' : 'general.trading.at') + ' ');

        const tifKey = OrderTifMap[tif.type];
        let result =
        Resources.getResource(
            buy
                ? 'general.trading.Buy'
                : 'general.trading.Sell') +
        ' ' +
        Resources.getResource(orderTypeKey) +
        '(' + Resources.getResource(tifKey) +
        (productType && productType != ProductType.General ? ', ' + InstrumentUtils.GetLocalizedProductType(instrument, productType) : '') +
        ')' +
        (leverage && isMarket ? (' ' + OrderUtils.GetFormattedLeverage(leverage)) : '') +
        ' ' +
        InstrumentUtils.formatAmountValue(quantity.value, instrument) +
        ' ' +
        InstrumentUtils.getInstrumentTypeStringLocalized(instrument.InstrType) +
        ' ' +
        instrument.DisplayName() +
        ' ' +
        '(' + instrument.TradingExchange + ')' +
        (leverage && !isMarket ? (' ' + OrderUtils.GetFormattedLeverage(leverage)) : '') +
        ' ' + priceInOffsetText;

        if (!isMarket) {
            for (let i = 0, len = priceArray.length; i < len; i++) {
                const last = i === len - 1;
                if (orderTypeKey === 'Tr. stop' && !RealTrStopPrice) {
                // сюда должны прийти тики в нормальном виде Utils.toRawTicks
                    let offsetValue = priceArray[i];
                    const offsetMode = GeneralSettings.TradingDefaults.ShowOffsetIn;
                    offsetValue = OrderUtils.toRawTicks(offsetValue, offsetMode, instrument);
                    if (GeneralSettings.TradingDefaults.IsTicksFractionalForForex()) // фракционы нужно будет сконвертить потому что instrument.formatOffset их не конвертирует во время форматирования, мудила ждёт именно фракционы
                    {
                        offsetValue = OrderUtils.ConvertTickOffset(instrument, offsetMode, null, offsetValue);
                    }

                    result += instrument.formatOffset(offsetValue);
                } else if (RealTrStopPrice) {
                    result += instrument.formatPrice(RealTrStopPrice);
                } else {
                    result += instrument.formatPrice(priceArray[i]);
                }

                if (!last) result += ' / ';
            }
        }

        if (sltpText) {
            result += '\n' + sltpText;
        }

        if (account) {
            result +=
            ' ' +
            Resources.getResource('general.trading.for') +
            ' ' +
            account.toString();
        }

        result += ' ?';

        return result;
    }

    // TODO. Refactor. Duplicate of Utils.buildOrderEditConfirmationText.
    public static buildCancelOrderConfirmationText (order): string {
        const dc = order.DataCache;
        const orderTypeObj = dc.OrderParameterContainer.GetOrderType(order.OrderType);

        // UGLY.
        const inLots = GeneralSettings.View.displayAmountInLots();
        const qty = new Quantity(
            Quantity.convertQuantityValue(new Quantity(order.Amount, true), order.Instrument, inLots),
            inLots);

        const ordType = order.OrderType;
        const ordTypes = OrderType;

        let priceArr = null;
        switch (ordType) {
        case ordTypes.TrailingStop:
            priceArr = [order.TrStopOffset];
            break;
        case ordTypes.StopLimit:
            priceArr = [order.StopLimit, order.Price];
            break;
        default:
            priceArr = [order.Price];
        }

        return Resources.getResource('panel.orders.menu.CancelOrder') +
        ' ' +
        OrderExecutorUtils.buildOrderEditConfirmationText(
            orderTypeObj.localizationKey(),
            new TIF(order.TimeInForce, new Date(order.ExpireAt)),
            order.Account,
            order.Instrument,
            qty,
            order.BuySell === OperationType.Buy,
            priceArr,
            null,
            ordType === ordTypes.TrailingStop,
            order.ProductType,
            null,
            order.getRealTrStopPrice(),
            order.Leverage);
    }

    // TODO. Refactor.
    public static getModifyOrderEditConfirmation (order: Order, confirmationText): string {
        return Resources.getResource('screen.modifyOrder.Change order') +
        ' #' + order.OrderNumber +
        ' ' + confirmationText;
    }

    // TODO. Refactor.
    public static buildPositionEditConfirmationText (
        position: Position, sltpText): string {
        return Resources.getResource('screen.modifyOrder.modifyPosition') +
        ' #' + position.PositionId +
        ' ' + sltpText +
        '?';
    }

    public static buildPositionModifyProductTypeConfirmationText (pos: Position, newPType, quantity): string {
        if (!pos) {
            return 'An error has occurred: no position';
        }

        const instrument = pos.Instrument;
        const productType = pos.ProductType;

        if (!instrument || productType === undefined) {
            return 'An error has occurred: no instrument or(and) productType';
        }

        const currentProductType = InstrumentUtils.GetLocalizedProductType(instrument, productType);
        const newProductType = InstrumentUtils.GetLocalizedProductType(instrument, newPType);

        const price = pos.Price;
        const instrumentDisplayName = instrument.DisplayName ? instrument.DisplayName() : '';

        if (!quantity || !price) {
            return 'An error has occurred: no amount or(and) price';
        }

        const inLots = GeneralSettings.View.displayAmountInLots();
        const qty = new Quantity(Quantity.convertQuantityValue(new Quantity(quantity, true), instrument, inLots), inLots);
        const qtyStr = (qty?.value?.toString) ? qty.value.toString() : '';

        return Resources.getResource('panel.positions.confirmation.ModifyProductType')
            .replace('{0}', currentProductType)
            .replace('{1}', newProductType)
            .replace('{2}', instrumentDisplayName)
            .replace('{3}', qtyStr)
            .replace('{4}', price.toString());
    }

    public static getAllowedTIFArray (orderTypeId, instrument: Instrument | null): any[] {
        if (!DataCache.OrderParameterContainer) {
            return [];
        }

        if (!instrument) {
            return [];
        }

        const route = instrument.DataCache.getRouteByName(instrument.Route);
        if (!route) {
            return [];
        }

        return route.DictOrderTypeTifs[orderTypeId] || [];
    }

    public static getAllowedOrderTypeDict (
        account: Account,
        instrument: Instrument,
        forbiddenOrderTypes?,
        lastSelectedOrderType?,
        mamMode?): any {
        mamMode = !!mamMode;
        const allowedOrderTypeDict = {};

        if (!DataCache.OrderParameterContainer) {
            return allowedOrderTypeDict;
        }

        const orderTypeDict = DataCache.OrderParameterContainer.OrderTypes;

        const supportedParamObj = new OrderTypeBaseParameters(instrument, account);

        for (const key in orderTypeDict) {
            const ordType = orderTypeDict[key];
            let ordTypeToCheck = ordType;
            const id = ordType.id();

            if (GeneralSettings.TradingDefaults.UseStopLimitInsteadStop) // 3.2) Логика при Use stop limit instead of stop = true https://docs.google.com/document/d/11893XluvGdsld2lwMx4H0h94xROrkMWmFzLqAHJ6YNE
            {
                const stopLimitOrderType = orderTypeDict[OrderType.StopLimit];
                if (id === OrderType.OCO) {
                    if (orderTypeDict[OrderType.Limit].IsSupported(supportedParamObj) && stopLimitOrderType.IsSupported(supportedParamObj)) // Если Stop limit недоступен, то скрываем возможность установить OCO.
                    {
                        allowedOrderTypeDict[id] = ordType;
                    } // TODO forbiddenOrderTypes слегка костыльно из-за 92559

                    continue;
                }

                if (id === OrderType.Stop && ordType.IsSupported(supportedParamObj)) // Если Stop ордер недоступен, то он не должен появляться даже если доступен Stop limit.
                {
                    ordTypeToCheck = stopLimitOrderType;
                } // Если Stop ордер доступен, то он должен скрываться, если не доступен Stop limit
            }

            if (forbiddenOrderTypes?.[id]) {
                continue;
            }

            if (!ordTypeToCheck.IsSupported(supportedParamObj)) {
                continue;
            }

            allowedOrderTypeDict[id] = ordType;
        }

        return allowedOrderTypeDict;
    }
}
