// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectControlAlertCommandMessage extends DirectMessageBaseClass {
    public AlertId = -1;
    public AlertCommand: number | null = null;

    constructor () {
        super(Message.CODE_CONTROL_ALERT_COMMAND_MESSAGE);
    }
}
