// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { TerceraRadioButtonComponentTemplate } from "../../templates.js";
import { ControlsTypes } from "../UtilsClasses/FactoryConstants.ts";
import { ContainerControl } from "./ContainerControl.js";

export let TerceraRadioButtonComponent = ContainerControl.extend({

    template: TerceraRadioButtonComponentTemplate,
    data: function ()
    {
        return {
            radioItems: [],
            groupName: '',
            isHorisontal: false
        };
    }
});

TerceraRadioButtonComponent.prototype.getType = function () { return ControlsTypes.TerceraRadioButtonComponent; };

TerceraRadioButtonComponent.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.apply(this);
    this.on('radioBtnChange', this.onRbchange);
};

TerceraRadioButtonComponent.prototype.onRbchange = function (context, rindex)
{
    var clickedItemIdx = rindex;
    var len = this.get('radioItems').length;
    for (var i = 0; i < len; i++)
        this.set('radioItems.' + i + '.checked', clickedItemIdx === i);
   
    this.fire(TerceraRadioButtonComponent.Events.StateChange, clickedItemIdx);
}

TerceraRadioButtonComponent.Events =
{
    StateChange: 'StateChange'
}
Object.freeze(TerceraRadioButtonComponent.Events);