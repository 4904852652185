// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { LayersEnum, TerceraChartBaseRenderer } from './TerceraChartBaseRenderer';
import { Pen } from '../../Commons/Graphics';
import { ThemeManager } from '../../Controls/misc/ThemeManager';
import { DynProperty } from '../../Commons/DynProperty';
import { type TerceraChartBase } from '../TerceraChartBase';

export class TerceraChartBordersRenderer extends TerceraChartBaseRenderer {
    axisBackColor: string;
    axisBackPen: Pen;
    priceScaleAxisColor: string;
    priceScaleAxisPen: Pen;
    timeScaleAxisColor: string;
    timeScaleAxisPen: Pen;

    constructor (chart: TerceraChartBase) {
        super(chart);

        this.axisBackColor = ThemeManager.CurrentTheme.Chart_AxisColorContour;
        this.axisBackPen = new Pen(this.axisBackColor);
        this.setPriceScaleAxisColor(ThemeManager.CurrentTheme.Chart_PriceScaleAxisColor);
        this.setTimeScaleAxisColor(ThemeManager.CurrentTheme.Chart_TimeScaleAxisColor);
        this.assignLayer = LayersEnum.Tools;
        this.SetClassName('TerceraChartBordersRenderer');
    }

    Draw (gr, window, windowsContainer, advParams = null) {
        const Rect = windowsContainer.Rectangle;
        const rectX = Rect.X;
        const rectY = Rect.Y;
        const rectWidth = Rect.Width;
        const rectHeight = Rect.Height;
        const crossHeight = windowsContainer.crosshairHeight;
        const clientRect = window.ClientRectangle;
        const clientX = clientRect.X;
        const clientWidth = clientRect.Width;
        gr.DrawRect(this.priceScaleAxisPen, clientX, rectY, clientWidth, crossHeight);
        gr.DrawLine(this.timeScaleAxisPen, clientX, crossHeight, clientX + clientWidth, crossHeight);
        gr.DrawRect(this.axisBackPen, rectX, rectY, rectWidth - 1, rectHeight - 1);
    }

    callBack (properties) {
        let dp = DynProperty.getPropertyByName(properties, 'PriceScaleAxisColor');
        if (dp) {
            this.setPriceScaleAxisColor(dp.value);
        }
        dp = DynProperty.getPropertyByName(properties, 'TimeScaleAxisColor');
        if (dp) {
            this.setTimeScaleAxisColor(dp.value);
        }
    }

    setPriceScaleAxisColor (color) {
        this.priceScaleAxisColor = color;
        this.priceScaleAxisPen = new Pen(this.priceScaleAxisColor);
    }

    setTimeScaleAxisColor (color) {
        this.timeScaleAxisColor = color;
        this.timeScaleAxisPen = new Pen(this.timeScaleAxisColor);
    }
}
