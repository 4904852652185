// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { HighLowData } from '../../../../../Commons/cache/Algorithm/HighLow/HighLowData';
import { DirectAlgorithmHighLowMessage } from '../../../../../Utils/DirectMessages/DirectMessagesImport';
import { FieldsFactory } from '../../Factory/FieldsFactory';
import { type PFixFieldSet } from '../../Fields/PFixFieldSet';
import { AlgorithmHighLowDelayDataGroup, AlgorithmHighLowInstrumentGroup } from '../../Groups/GroupsImport';
import { MessageProcessorBase } from '../MessageProcessorBase';

export class AlgorithmHighLowMessageProcessor extends MessageProcessorBase {
    public override decode (fs: PFixFieldSet): DirectAlgorithmHighLowMessage[] {
        const msg = new DirectAlgorithmHighLowMessage();
        const ff = FieldsFactory;

        msg.AlgorithmId = fs.GetValue(ff.FIELD_ALGORITHM_ID);
        msg.AlgorithmName = fs.GetValue(ff.FIELD_ALGORITHM_NAME);

        for (const highLowGroup of fs.GetGroups(ff.ALGORITHM_HIGH_LOW_INSTRUMENT_GROUP, AlgorithmHighLowInstrumentGroup)) {
            msg.HighLowItems.push(this.CreateHighLowData(highLowGroup, false));
        }

        const delayedGroup = fs.GetGroups(ff.ALGORITHM_HIGH_LOW_DELAY_DATA_GROUP, AlgorithmHighLowDelayDataGroup)[0];
        if (delayedGroup != null) {
            for (const highLowGroup of delayedGroup.InstrumentGroups) {
                msg.HighLowItems.push(this.CreateHighLowData(highLowGroup, true));
            }
        }

        return [msg];
    }

    private CreateHighLowData (highLowGroup: AlgorithmHighLowInstrumentGroup, isDelayed: boolean): HighLowData {
        const data = new HighLowData(highLowGroup.HighLowEvent);

        data.TradableId = highLowGroup.TradableId;
        data.InstrumentId = highLowGroup.InstrumentId;
        data.InstrumentGroupId = highLowGroup.InstrumentGroupId;
        data.InstrumentName = highLowGroup.InstrumentName;
        data.RouteId = highLowGroup.RouteId;
        data.High = highLowGroup.High;
        data.Low = highLowGroup.Low;
        data.LastPrice = highLowGroup.LastPrice;
        data.LastSize = highLowGroup.LastSize;
        data.LastTradeDate = new Date(highLowGroup.LastTradeDate);
        data.InterestChange = highLowGroup.InterestChange;
        data.AbsoluteChange = highLowGroup.AbsoluteChange;
        data.MarketDataExchange = highLowGroup.MarketDataExchange;
        data.TradingExchange = highLowGroup.TradingExchange;
        data.Volume = highLowGroup.Volume;
        data.IsDelayed = isDelayed;

        return data;
    }
}
