import { type Account } from './Account';

// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
export class User {
    public Pin: any;
    public RoleName: string;
    public ClientType: any;
    public Role: any;
    public Status: any;
    public Login: string;
    public GroupId: number;
    public Email: string;
    public UserAdditionalInfo: any;
    public Accounts: Account[] = [];

    constructor (pin, role, status, login: string, clientType, roleName: string, groupId: number) {
        this.Pin = pin;
        this.RoleName = roleName;
        this.ClientType = clientType;
        this.Role = role;
        this.Status = status;
        this.Login = login;
        this.GroupId = groupId;
        this.Email = roleName;
        this.UserAdditionalInfo = null;
    }
}
