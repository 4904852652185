// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type TradingSession } from '../Session/Sessions';
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectSessionMessage extends DirectMessageBaseClass {
    public Id = 0;
    public Name = '';
    public ServerBeginDayDate: Date | null = null;
    public Sessions: TradingSession[] | null = null;
    public CurrentSessionId = 0;
    public UseIntradayMarginCoef = false;
    public TimeZoneOffset = 0;
    public BlockTrading = false;
    public FirstDayOfWeek = 1;
    public TimeZoneID: string;
    public TimeOffset: number;

    constructor () {
        super(Message.CODE_SESSION_MESSAGE);
    }
}
