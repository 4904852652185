// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts";
import { KeyCode, KeyEventProcessor } from "../../Commons/KeyEventProcessor.ts";
import { SoundManager } from "../../Utils/SoundManager.ts";
import { TerceraMessageBoxTemplate } from "../../templates.js";
import { TerceraButton } from "../elements/TerceraButton.ts";
import { TerceraWindowBase } from "./TerceraWindowBase.js";
import { ThemeManager } from "../misc/ThemeManager.ts";
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";
import { messageBoxHandler } from "../../Utils/AppHandlers.js"
import { TerceraButtonStyle } from "../../Utils/Enums/ButtonEnums.ts";

export let TerceraMessageBox = TerceraWindowBase.extend({
    data: function ()
    {
        return {
            zIndex: 300,
            width: 485,
            errorTop: 0,
            header: "",
            resizable: false,
            showFooter: false,
            pictureUrl: "",
            messageBoxText: "",
            showNextTimeText: "Show next time",
            showNextTime: true,
            showNextTimeChB: false,
            okText: "Yes",
            cancelText: "No",
            btnOKStyle: TerceraButtonStyle.Standard,
            btnCancelStyle: TerceraButtonStyle.Standard,
            messageBoxType: null,
            okCallBack: null,
            cancelCallBack: null,
            closeButtonCallBack: null,
            hideCancel: false,
            allowKeyboardConfirmation: true,
            showFullscreenCloud: false, //TODO 83829
            closeBtnVisible: true,
            errorMode: false,
            errorText: "",
            pictureErrorUrl: "",
            style_addition_header: 'js-PropertySetupScreen-AdditionalHeader'
        };
    },
    partials: { bodyPartial: TerceraMessageBoxTemplate },
    customDisposeHandler: null,
    OK_Handler: null,
    NO_Handler: null
});

TerceraMessageBox.prototype.getType = function () { return 'TerceraMessageBox' };

TerceraMessageBox.prototype.oninit = function ()
{
    TerceraWindowBase.prototype.oninit.apply(this);
    this.set({ pictureErrorUrl: ThemeManager.getImageFullUrlNew("i_messagebox/30x30_icon_warning.png") });
    this.on('okClick', this.okClick);
    this.on('cancelClick', this.cancelClick);
    this.observe('messageBoxType', function (newValue)
    {
        var imgUrl = 'darkNew/i_messagebox/30x30_icon_info.png';
        switch (newValue)
        {
            case TerceraMessageBox.msgType.Error:
                imgUrl = 'i_messagebox/30x30_icon_error.png';
                break;
            case TerceraMessageBox.msgType.Info:
                imgUrl = 'i_messagebox/30x30_icon_info.png';
                break;
            case TerceraMessageBox.msgType.Question:
                imgUrl = 'i_messagebox/30x30_icon_question.png';
                break;
            case TerceraMessageBox.msgType.Warning:
                imgUrl = 'i_messagebox/30x30_icon_warning.png';
                break;
            case TerceraMessageBox.msgType.Approve:
                imgUrl = 'i_messagebox/30x30_icon_approve.svg';
                break;
            case TerceraMessageBox.msgType.YellowInfo:
                imgUrl = 'i_messagebox/30x30_icon_yellow_info.svg';
                break;
        }
        this.set({ pictureUrl: ThemeManager.getImageFullUrlNew(imgUrl) });
    });
};

TerceraMessageBox.prototype.oncomplete = function ()
{
    TerceraWindowBase.prototype.oncomplete.apply(this);
    this.arrangeControls();
    this.center();
};

TerceraMessageBox.prototype.arrangeControls = function ()
{
    var controls = this.Controls;

    var mainTextLabel = controls.mainTextLabel;
    var y = mainTextLabel.realRelativeBottom();

    var mainImg = controls.mainImg;

    var mainTextTop = mainTextLabel.get('top');
    var mainTextMiddle = mainTextTop + (y - mainTextTop) / 2;

    mainImg.set('top', mainTextMiddle - mainImg.get('height') / 2);

    y += 17;

    var errorMode = this.get('errorMode');
    if (errorMode)
    {
        this.set('errorTop', y);

        var errorTextLabel = controls.errorTextLabel;
        var errorBottom = errorTextLabel.realRelativeBottom();

        var errorImg = controls.errorImg;

        var errorTextTop = errorTextLabel.get('top');
        var errorTextMiddle = errorTextTop + (errorBottom - errorTextTop) / 2;

        errorImg.set('top', errorTextMiddle - errorImg.get('height') / 2);

        y += errorBottom + 17;
    }

    this.set('height', null)
};

TerceraMessageBox.Show = function (headerText, bodyText, type, okCallBack, cancelCallBack, showNextTimeChB, hideCancel, errorText, addData, skipOnTeardown)
{
    var terceraMessageBox = new TerceraMessageBox();
    terceraMessageBox.set({
        messageBoxType: type,
        header: headerText,
        messageBoxText: bodyText,
        okCallBack: okCallBack,
        cancelCallBack: cancelCallBack,
        showNextTimeChB: showNextTimeChB,
        hideCancel: hideCancel,
        showNextTimeText: Resources.getResource('general.messageBox.showNextTime'),
        okText: Resources.getResource('general.messageBox.yes'),
        cancelText: Resources.getResource('general.messageBox.no')
    });

    if (errorText)
        terceraMessageBox.set({
            errorMode: true,
            errorText: errorText,
            showNextTimeChB: !!showNextTimeChB
        });
    MainWindowManager.MainWindow.addControl(terceraMessageBox);

    if (addData)
    {
        if (addData.hasOwnProperty('forceCloseOnLogout'))
            terceraMessageBox.forceCloseOnLogout = addData.forceCloseOnLogout;
        if (addData.cancelText)
            terceraMessageBox.set({ cancelText: addData.cancelText });
        if (addData.okText)
            terceraMessageBox.set({ okText: addData.okText });
        if (addData.closeButtonCallBack)
            terceraMessageBox.set('closeButtonCallBack', addData.closeButtonCallBack);
        if (addData.hasOwnProperty('allowKeyboardConfirmation'))
            terceraMessageBox.set('allowKeyboardConfirmation', addData.allowKeyboardConfirmation);
        if (addData.hasOwnProperty('cantActionBeforeClose'))
            terceraMessageBox.set('showFullscreenCloud', !!addData.cantActionBeforeClose);
        if (addData.hasOwnProperty('hideCloseBtn'))
            terceraMessageBox.set('closeBtnVisible', !!!addData.hideCloseBtn);
        if (addData.hasOwnProperty('zIndex'))
            terceraMessageBox.set('zIndex', addData.zIndex);
        if (addData.hasOwnProperty('btnOKStyle'))
            terceraMessageBox.set('btnOKStyle', addData.btnOKStyle);
        if (addData.hasOwnProperty('btnCancelStyle'))
            terceraMessageBox.set('btnCancelStyle', addData.btnCancelStyle);
        if (addData.hasOwnProperty('style_addition_header'))
            terceraMessageBox.set('style_addition_header', addData.style_addition_header);
    }

    var soundKey = null;

    switch (type)
    {
        case TerceraMessageBox.msgType.Error:
        case TerceraMessageBox.msgType.Warning:
        case TerceraMessageBox.msgType.Info:
        case TerceraMessageBox.msgType.Question:
            soundKey = SoundManager.SoundKeys.Warning;
            break;
        default:
            soundKey = SoundManager.SoundKeys.Confirmation;
    }
    terceraMessageBox.setFocus();

    var keyProc = KeyEventProcessor;
    keyProc.OnKeyDown.Subscribe(terceraMessageBox.onGlobalKeyDown, terceraMessageBox);
    terceraMessageBox.forceCloseOnLogout = !skipOnTeardown;
    SoundManager.tryPlaySound(soundKey);

    terceraMessageBox.OK_Handler = new Promise(function (resolve) { terceraMessageBox.OkClicked = resolve }.bind(terceraMessageBox));
    terceraMessageBox.NO_Handler = new Promise(function (resolve) { terceraMessageBox.CancelClicked = resolve }.bind(terceraMessageBox));

    return terceraMessageBox;
};

TerceraMessageBox.prototype.onGlobalKeyDown = function (code)
{
    if (this.get('focused'))
    {
        if (code === KeyCode.ESC)
            this.cancelClick();
        if (code === KeyCode.ENTER && this.get('allowKeyboardConfirmation'))
            this.okClick();
    }
};

TerceraMessageBox.prototype.onteardown = function ()
{
    var keyProc = KeyEventProcessor;
    keyProc.OnKeyDown.UnSubscribe(this.onGlobalKeyDown, this);
}

TerceraMessageBox.prototype.okClick = function ()
{
    var okCallBack = this.get('okCallBack');
    if (okCallBack)
        okCallBack(this.get('showNextTimeChB') ? this.get('showNextTime') : undefined);

    //new Way
    if (this.OkClicked)
        this.OkClicked()

    this.close();
};

TerceraMessageBox.prototype.cancelClick = function ()
{
    var cancelCallBack = this.get('cancelCallBack');
    if (cancelCallBack)
        cancelCallBack(this.get('showNextTimeChB') ? this.get('showNextTime') : undefined);

    if (this.CancelClicked)
        this.CancelClicked()

    this.close();
};

// Close button (cross icon).
TerceraMessageBox.prototype.onCloseButtonClick = function ()
{
    if (this.canCloseFromButton())
    {
        this.close();
        var closeButtonCallBack = this.get('closeButtonCallBack') || this.get('cancelCallBack');
        if (closeButtonCallBack)
            closeButtonCallBack(this.get('showNextTimeChB') ? this.get('showNextTime') : undefined);
    }
};

TerceraMessageBox.prototype.dispose = function ()
{
    if (this.customDisposeHandler)
        this.customDisposeHandler();

    TerceraWindowBase.prototype.dispose.apply(this);
};

TerceraMessageBox.msgType =
{
    Error: 1,
    Info: 2,
    Question: 3,
    Warning: 4,
    Approve: 5,
    YellowInfo: 6
};
Object.freeze(TerceraMessageBox.msgType);


let msgBoxHanl = messageBoxHandler;
msgBoxHanl.Show = TerceraMessageBox.Show;
msgBoxHanl.msgType = TerceraMessageBox.msgType;
