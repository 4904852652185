// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { ProductType } from '../Instruments/ProductType';
import { OperationType } from '../Trading/OperationType';
import { OrderExecutionType } from '../Trading/OrderExecutionType';
import { OrderStatus } from '../Trading/OrderStatus';
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectOrderHistoryMessage extends DirectMessageBaseClass {
    public ClientOrderId = '';
    public Comment = '';
    public PutCall = 0;
    public StrikePrice = 0;
    public Status = OrderStatus.UNKNOWN;
    public ProductType = ProductType.General;
    public InstrumentTradableID: number;

    private FAccount = '';
    private FBuySell: OperationType = OperationType.Buy;
    private FOrderNumber = '';
    private FOrderType = 0;
    private FPrice = 0;
    private FQuantity = 0;
    private FRoute: number | null = null;
    private FRemoteId = '';
    private FStopLimit = 0;
    private FSymbol = '';
    private FUTCDateTime: Date | null = null;
    private FTimeInForce = 0;
    private expDate: any = null;
    private externalOrderID: any = null;
    private externalTradeID: any = null;
    private FLastUpdateTime: Date | null = null;
    private active = false;
    private boundToOrderId: string = '-1';
    private FOrderCreatedByUser: string | null = null;
    private FDisClosedQuantity = 0;
    private FFilledQuantity = 0;
    private FRemainingQuantity = 0;
    private eventType: OrderExecutionType = OrderExecutionType.UNKNOWN;
    private leverage: number | null = null;
    private FPositionId: string = '-1';

    constructor () {
        super(Message.CODE_ORDER_HISTORY);
    }

    get Account (): string {
        return this.FAccount;
    }

    set Account (val) {
        this.FAccount = val;
    }

    get BuySell (): OperationType {
        return this.FBuySell;
    }

    set BuySell (val) {
        this.FBuySell = val;
    }

    get OrderNumber (): string {
        return this.FOrderNumber;
    }

    set OrderNumber (val) {
        this.FOrderNumber = val;
    }

    get OrderType (): number {
        return this.FOrderType;
    }

    set OrderType (val) {
        this.FOrderType = val;
    }

    get Price (): number {
        return this.FPrice;
    }

    set Price (val) {
        this.FPrice = val;
    }

    get Quantity (): number {
        return this.FQuantity;
    }

    set Quantity (val) {
        this.FQuantity = val;
    }

    get Route (): number | null {
        return this.FRoute;
    }

    set Route (val) {
        this.FRoute = val;
    }

    get RemoteId (): string {
        return this.FRemoteId;
    }

    set RemoteId (val) {
        this.FRemoteId = val;
    }

    get StopLimit (): number {
        return this.FStopLimit;
    }

    set StopLimit (val) {
        this.FStopLimit = val;
    }

    get Symbol (): string {
        return this.FSymbol;
    }

    set Symbol (val) {
        this.FSymbol = val;
    }

    get UTCDateTime (): Date | null {
        return this.FUTCDateTime;
    }

    set UTCDateTime (val) {
        this.FUTCDateTime = val;
    }

    get TimeInForce (): number {
        return this.FTimeInForce;
    }

    set TimeInForce (val) {
        this.FTimeInForce = val;
    }

    get ExpDate (): any {
        return this.expDate;
    }

    set ExpDate (val) {
        this.expDate = val;
    }

    get LastUpdateTime (): Date | null {
        return this.FLastUpdateTime;
    }

    set LastUpdateTime (val) {
        this.FLastUpdateTime = val;
    }

    get Active (): boolean {
        return this.active;
    }

    set Active (val) {
        this.active = val;
    }

    get BoundToOrderId (): string {
        return this.boundToOrderId;
    }

    set BoundToOrderId (val) {
        this.boundToOrderId = val;
    }

    get OrderCreatedByUser (): string | null {
        return this.FOrderCreatedByUser;
    }

    set OrderCreatedByUser (val) {
        this.FOrderCreatedByUser = val;
    }

    get DisClosedQuantity (): number {
        return this.FDisClosedQuantity;
    }

    set DisClosedQuantity (val) {
        this.FDisClosedQuantity = val;
    }

    get FilledQuantity (): number {
        return this.FFilledQuantity;
    }

    set FilledQuantity (val) {
        this.FFilledQuantity = val;
    }

    get RemainingQuantity (): number {
        return this.FRemainingQuantity;
    }

    set RemainingQuantity (val) {
        this.FRemainingQuantity = val;
    }

    get EventType (): OrderExecutionType {
        return this.eventType;
    }

    set EventType (val: OrderExecutionType) {
        this.eventType = val;
    }

    get ExternalOrderId (): any {
        return this.externalOrderID;
    }

    set ExternalOrderId (val) {
        this.externalOrderID = val;
    }

    get ExternalTradeId (): any {
        return this.externalTradeID;
    }

    set ExternalTradeId (val) {
        this.externalTradeID = val;
    }

    get Leverage (): number | null {
        return this.leverage;
    }

    set Leverage (val) {
        this.leverage = val;
    }

    get PositionId (): string {
        return this.FPositionId;
    }

    set PositionId (val) {
        this.FPositionId = val;
    }
}
