// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.


import { Resources } from "../../Commons/properties/Resources.ts"
import { PositionInfoViewTemplate } from "../../templates.js"
import { PositionItem } from "../cache/PositionItem.ts"
import { Control } from "./Control.js"
import { OperationType } from "../../Utils/Trading/OperationType.ts"
import { ThemeManager } from "../misc/ThemeManager.ts"
import { DateTimeUtils } from "../../Utils/Time/DateTimeUtils.ts"
import { InstrumentUtils } from "../../Utils/Instruments/InstrumentUtils.ts"
import { SessionSettings } from "../../Commons/SessionSettings.ts"
import { DateTimeConvertor } from "../../Utils/Time/DateTimeConvertor.ts"
import { OrderUtils } from "../../Utils/Trading/OrderUtils.ts"

// TODO. Refactor. View template duplicate of OrderInfoView.
// I'm not using computed properties here and other cool stuff cuz of bugs.
export let PositionInfoView = Control.extend({
    template: PositionInfoViewTemplate,
    data: function ()
    {
        return {
            positionIdLabel: '',
            instrumentLabel: '',
            routeLabel: '',
            sideLabel: '',
            leverageLabel: '',
            productTypeLabel: '',
            amountLabel: '',
            marketPriceLabel: '',
            openPriceLabel: '',
            dateTimeLabel: '',

            position: null,

            hideRoute: false,
            showProductType: false,

            fPositionId: '',
            fMarketPrice: '',
            fOpenPrice: '',
            fDateTime: '',
            fAmount: '',
            fSide: '',
            fLeverage: '',
            fInstrumentName: '',
            fRoute: '',
            fProductType: '',

            sideColor: '',

            height: 0
        }
    }
})

PositionInfoView.prototype.oninit = function ()
{
    Control.prototype.oninit.call(this)
    this.observe('position', this.updateFields)
    Resources.onLocaleChange.Subscribe(this.localize, this)
    Control.Ticker.Subscribe(this.tickAsync, this)
    this.localize()
}

PositionInfoView.prototype.dispose = function ()
{
    Control.Ticker.UnSubscribe(this.tickAsync, this)
    Resources.onLocaleChange.UnSubscribe(this.localize, this)

    Control.prototype.dispose.call(this)
}

PositionInfoView.prototype.updateFields = function (pos)
{
    if (!pos) return

    let sess = SessionSettings
    let ins = pos.Instrument
    let buy = pos.BuySell === OperationType.Buy

    this.set({
        hideRoute: ins.isHideRouteMode,
        fPositionId: pos.PositionId,
        fSide: Resources.getResource('general.trading.position.' + (buy ? 'Buy' : 'Sell')),
        // TODO. Ugly, i know.
        fAmount: PositionItem.GetPositionAmountText(pos),
        fOpenPrice: ins.formatPrice(pos.Price),
        fDateTime: DateTimeUtils.FormatToDateAndTime(DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(pos.OpenTime, pos.DataCache)),
        fInstrumentName: ins.DisplayName(),
        fRoute: ins.getRoute(),
        sideColor: ThemeManager.CurrentTheme[buy ? 'BuyColor' : 'SellColor'],
        fLeverage: OrderUtils.GetFormattedLeverage(pos.Leverage),
        showProductType: ins.isProductTypeVisible(),
        fProductType: InstrumentUtils.GetLocalizedProductType(ins, pos.ProductType),
        height: (ins.isHideRouteMode ? 140 : 160) + (pos.Leverage ? 20 : 0)
    })
}

PositionInfoView.prototype.tickAsync = function ()
{
    let pos = this.get('position')
    if (!pos) return

    let ins = pos.Instrument
    this.set('fMarketPrice', ins.formatPrice(pos.CurPriceClose))
}

PositionInfoView.prototype.localize = function ()
{
    this.set({
        positionIdLabel: Resources.getResource('screen.modifyOrder.number'),
        instrumentLabel: Resources.getResource('screen.modifyOrder.instrument'),
        routeLabel: Resources.getResource('screen.modifyOrder.route'),
        sideLabel: Resources.getResource('screen.modifyOrder.operation'),
        amountLabel: Resources.getResource('screen.modifyOrder.amount'),
        marketPriceLabel: Resources.getResource('screen.modifyOrder.market_price'),
        openPriceLabel: Resources.getResource('screen.modifyOrder.price'),
        dateTimeLabel: Resources.getResource('screen.modifyOrder.created'),
        leverageLabel: Resources.getResource('screen.modifyOrder.leverage'),
        productTypeLabel: Resources.getResource('screen.modifyOrder.productType')
    })

    this.updateFields(this.get('position'))
}