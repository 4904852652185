// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { ContainerControlTemplate } from '../../templates.js';
import { Control } from './Control_ts';

export class ContainerControl extends Control {
    public lostFocusOnOutsideClick = false;
    public movingParameters: any;
    public resizeParameters: any;
    public onProcessResize: boolean;

    public override getType (): string { return 'ContainerControl'; }

    // Раскладываем контролы по name
    // при овверайде вызов бэйс - обязательно!
    public override onrender (): void {
    // TODO. oninit, onrender.
        this.Controls = this.Controls || {};
        this.registerAllControl();
    }

    public registerAllControl (): void {
        const allRactiveObject = this.findAllComponents();
        // если чтото есть, или надо перезатереть контролы которых не стало
        const len = allRactiveObject.length;
        for (let i = 0; i < len; i++) {
            const ractiveObj = allRactiveObject[i];
            if (ractiveObj.parent === this) // только своих детей
            {
                this.registerControl(ractiveObj);
            }
        }
    }

    public override oninit (): void {
        super.oninit();

        // TODO. oninit, onrender.
        this.Controls = this.Controls || {};
    }

    // управление фокусом на контейнере
    public processFocus (control): void {
        const myActiveControl = this.get('activeControl');
        if (control === myActiveControl) {
            return;
        }

        if (myActiveControl) {
            myActiveControl.lostFocus();
        }

        void this.set('activeControl', control);

        if (control) {
            if (!control.get('canSelect') && !control.get('canFocus')) {
                return;
            }

            control.gotFocus();

            const parentElement = this.get('parentContainerControl');
            if (parentElement) {
                parentElement.processFocus(this);
            }
        }

        // вдруг при потере фокуса нам нужно вернуться в панель определённую( только часть АДА !!! ахахахахахаха)
        if (myActiveControl) {
            if (myActiveControl.AfterLostFocusWaiting) {
                myActiveControl.AfterLostFocusWaiting.setFocus();
            }

            myActiveControl.AfterLostFocusWaiting = null;
        // console.log(myActiveControl.getType());
        }
    }

    public isActiveControlClicked (event): boolean {
        let activeControl = this.get('activeControl');
        if (!activeControl) {
            return false;
        }

        if (!activeControl.lostFocusOnOutsideClick) {
            while (activeControl instanceof ContainerControl) {
                const childControl = activeControl.get('activeControl');
                if (isNullOrUndefined(childControl)) {
                    break;
                }
                activeControl = childControl;
            }
        }
        // TODO: activeControl may be instanceof DocumentFragment in some cases
        if (activeControl instanceof Control && activeControl.el instanceof Element) {
            const controlRect = activeControl.el.getBoundingClientRect();
            const controlX = controlRect.left;
            const controlY = controlRect.top;
            const controlWidth = controlRect.width;
            const controlHeight = controlRect.height;

            const clickX = event.original.clientX;
            const clickY = event.original.clientY;

            if (clickX >= controlX && clickX <= controlX + controlWidth &&
            clickY >= controlY && clickY <= controlY + controlHeight) {
                return true; // Intersection detected
            } else {
                return false; // No intersection
            }
        } else {
            return false;
        }
    }

    public override lostFocus (): void {
        super.lostFocus();
        this.processFocus(null);
    }

    public resetFocus (): void {
        this.processFocus(null);
    }

    // динамическое добавление контролов
    public addControl (control: Control, customPlace = false): void {
        if (!control) return;

        control.append = true;
        void control.render(this.getClientPanel(customPlace));
        this.registerControl(control);
    }

    public removeControl (control: Control, name: string = undefined): void {
        if (!control) return;

        let key = name;
        if (key === undefined) key = control.get('name');
        if (key === '') key = control._guid;

        const controls = this.Controls;
        if (!controls.hasOwnProperty(key)) return;

        delete controls[key];
        control.dispose();
    }

    public removeAllControls (exceptKeyDict?: Record<string, any>): void {
        exceptKeyDict = exceptKeyDict || {};
        const controls = this.Controls;
        for (const key in controls) {
            if (exceptKeyDict[key]) {
                continue;
            }

            this.removeControl(controls[key], key);
        }
    }

    public removeActiveControl (): void {
        void this.set('activeControl', null);
    }

    public removeParentActiveControl (): void {
        const parentElement = this.get('parentContainerControl');
        if (parentElement) {
            parentElement.removeActiveControl();
        }
    }

    // часть контейнера куда будет добавляться контрол
    public getClientPanel (customPlace?): HTMLElement {
        return this.el;
    }

    public registerControl (control): void {
        const name = control.get('name');
        const key = name || name === 0 ? name : control._guid;
        const controls = this.Controls;
        controls[key] = control;
        control.set('parentContainerControl', this);
        control.on('teardown', function () { delete controls[key]; });
    }

    public onResizeBegin (): void {
        const controls = this.Controls;
        for (const key in controls) {
            const control = controls[key];
            if (control.onResizeBegin) {
                control.onResizeBegin();
            }
        }
    }

    public onResizeEnd (): void {
        const controls = this.Controls;
        for (const key in controls) {
            const control = controls[key];
            if (control.onResizeEnd) {
                control.onResizeEnd();
            }
        }
    }

    public override onMouseDown (event, isHeader: boolean): void {
        if (Control.IsEventProcessed(event)) {
            return;
        }

        if (!this.isActiveControlClicked(event)) {
        // Reset current active control
            this.processFocus(null);
        }
        // Call base method
        super.onMouseDown(event, isHeader);

        if (isHeader) {
            if (this.get('movable') && this.get('focused')) {
                const myParams: any = {};
                myParams.startMoveCoordX = event.original.pageX;
                myParams.startMoveCoordY = event.original.pageY;
                myParams.startMyX = this.get('left') || 0;
                myParams.startMyY = this.get('top') || 0;
                void this.set('onProcessMove', true);
                this.movingParameters = myParams;
            }
        }
        if (event.get('resizable') && this.get('onProcessMove') === false) {
            const myResizeParams: any = {};
            myResizeParams.rect = this.getElementRect(event.node);
            myResizeParams.down = {
                x: this.getPageX(event.original),
                y: this.getPageY(event.original)
            };
            myResizeParams.resizer = this.getResizer(event.original, event.node);
            this.resizeParameters = myResizeParams;
            this.onProcessResize = true;
            // Inform controls about start resizing
            this.onResizeBegin();
        }
    }

    // TODO: Maybe need transfered this methods from TerceraWindowBase
    // #region
    public getPageX (event): number {
        return null;
    }

    public getPageY (event): number {
        return null;
    }

    public getElementRect (element) {
        return null;
    }

    public getResizer (event, node) {
        return null;
    }
    // #endregion

    public dispose (): void {
        const controls = this.Controls;
        if (controls) {
            for (const key in controls) {
                const control = controls[key];
                if (control.dispose) {
                    control.dispose();
                }
            }
        }
        // Object to prevent null reference exceptions.
        this.Controls = {};
        void this.teardown();
    }
}

Control.extendWith(ContainerControl, {
    template: ContainerControlTemplate,
    data: function () {
        return {
            activeControl: null
        };
    }
});
