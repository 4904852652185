// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { TerceraCheckBoxTemplate } from "../../templates.js";
import { ControlsTypes } from '../../Controls/UtilsClasses/FactoryConstants.ts';
import { Control } from "./Control.js";

export let TerceraCheckBox = Control.extend({

    template: TerceraCheckBoxTemplate,
    data: function ()
    {
        return {
            checked: false,                 //checked или нет        
            checkBoxCheckedState: 'none',   //тип checked
            text: '',
            tag: null
        };
    }
});

TerceraCheckBox.prototype.getType = function () { return ControlsTypes.TerceraCheckBox; };

TerceraCheckBox.prototype.oninit = function ()
{
    Control.prototype.oninit.apply(this);
    this.observe('checked', this.onCheckedChanged);
    this.observe('checkBoxCheckedState', this.onCheckBoxCheckedStateChanged);
};

TerceraCheckBox.prototype.onCheckedChanged = function (checked)
{
    var statesEnum = TerceraCheckBox.TerceraCheckBoxCheckedStates;

    if (this.get('checkBoxCheckedState') !== statesEnum.Indeterminate)
        this.set('checkBoxCheckedState', checked ? statesEnum.Checked : statesEnum.None);

    this.fire(TerceraCheckBox.Events.CheckedStateChange, checked);
};

TerceraCheckBox.prototype.onCheckBoxCheckedStateChanged = function (state)
{
    var statesEnum = TerceraCheckBox.TerceraCheckBoxCheckedStates;

    this.set('checked',
        state === statesEnum.Indeterminate ||
        state === statesEnum.Checked);
};

TerceraCheckBox.prototype.private_CheckBoxClick = function (sender)
{
    if (!this.get('enabled'))
        return;

    this.fire(TerceraCheckBox.Events.CheckBoxClicked, sender);

    var statesEnum = TerceraCheckBox.TerceraCheckBoxCheckedStates;
    var curState = this.get('checkBoxCheckedState');
    if (curState === statesEnum.Indeterminate)
        this.set('checkBoxCheckedState', statesEnum.Checked);
    else
        this.toggle('checked');
};

TerceraCheckBox.TerceraCheckBoxCheckedStates =
{
    None: 'none',
    Indeterminate: 'indeterminate',
    Checked: 'checked'
};
Object.freeze(TerceraCheckBox.TerceraCheckBoxCheckedStates);

TerceraCheckBox.Events =
{
    CheckedStateChange: 'CheckedStateChange',
    CheckBoxClicked: 'CheckBoxClicked'
};
Object.freeze(TerceraCheckBox.Events);
