// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectTradingSignalCancelMessage extends DirectMessageBaseClass {
    public TradingSignalId: number | null = null;

    constructor () {
        super(Message.CODE_CANCEL_TRADING_SIGNAL_MESSAGE);
    }
}
