// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectRiskWarningMessage } from '../../../../Utils/DirectMessages/DirectRiskWarningMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class RiskWarningMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): DirectRiskWarningMessage[] {
        const msg = new DirectRiskWarningMessage();

        const len = fieldSet.Count();
        for (let i = 0; i < len; i++) {
            const fixField = fieldSet.fieldSet[i];
            if (fixField.FieldId === 0) {
                continue;
            }

            if (fixField.FieldId === FieldsFactory.FIELD_ACCOUNT_ID) {
                msg.AccountID = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_TEXT) {
                msg.Text = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_TYPE) {
                msg.RiskRuleType = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_RISK_WARN_LEVEL) {
                msg.RiskWarnLevel = fixField.Value;
            }
        }

        return [msg];
    }
}
