// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { FieldsFactory } from '../Factory/FieldsFactory';

export class CloseAccountCancelRequestMessage extends PFixMessage {
    constructor () {
        super(Message.CODE_PFIX_CLOSE_ACCOUNT_CANCEL_REQUEST_MESSAGE);
    }

    public setCloseAccountRequestID (reqID: number): void {
        this.FieldSet.SetField(FieldsFactory.FIELD_CLOSE_ACCOUNT_REQUEST_ID, reqID);
    }
}
