// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { CashItem } from "../../../History/CashItem";
import { iBuildInIndicator } from "../../iBuildInIndicator";
import { iMAEx } from "./iMAEx";

export class iStdDevEx extends iBuildInIndicator
{
    constructor(period: number, maMethod: number, maShift: number, priceType: number)
    {
        super(1);
        this.FMaPeriod = period;
        this.FMAMethod = maMethod;
        this.FPriceType = priceType;
        this.ma = new iMAEx(period, maMethod, 0, priceType);
        this.ma.Parent = this.Parent;
        this.ma.PriceData = this.PriceData;
        this.FMaShift = maShift;

        this.Data.FArray[0].Shift = this.FMaShift;
    }

    public FMAMethod: number;
    public FMaPeriod: number;
    public FMaShift: number;
    public ma: iBuildInIndicator;

    public get Name(): string
    {
        return 'iStdDevEx'
    }

    public get Key(): string
    {
        return this.DefaultKey + this.FMaPeriod + this.FMAMethod + this.FPriceType
    }

    public NextBar(callBound): void
    {
        this.ma.NextBar(callBound);
        super.NextBar(callBound);
    }

    public get PriceData(): any
    {
        return super.PriceData;
    }

    public set PriceData(value: any)
    {
        super.PriceData = value;
        this.ma.PriceData = value;
    }

    public get Parent(): CashItem
    {
        return super.Parent;
    }

    public set Parent(value: CashItem)
    {
        super.Parent = value;
        this.ma.Parent = value;
    }

    public OnQuote(ci, callBound, callFromRefresh = false): void
    {
        this.ma.OnQuote(ci, callBound);
        if (this.FCount < this.FMaPeriod) {
            return;
        }

        let dAmount = 0;
        let dMovingAverage = this.ma.GetValue(0);
        for (let j = 0; j < this.FMaPeriod; j++) {
            let dAPrice = this.GetPrice(this.FPriceType, j);
            dAmount += (dAPrice - dMovingAverage) * (dAPrice - dMovingAverage);
        }
        this.SetValue(0, 0, Math.sqrt(dAmount / this.FMaPeriod));
    }

    public Dispose(): void
    {
        this.ma.Dispose();
        super.Dispose();
    }

    public SetOffset(offset: number): void
    {
        this.ma.SetOffset(offset);
        super.SetOffset(offset);
    }

    public Refresh(count: number, newThread: boolean): void
    {
        this.ma.Dispose();
        this.ma = new iMAEx(this.FMaPeriod, this.FMAMethod, 0, this.FPriceType);
        this.ma.Parent = this.Parent;
        this.ma.PriceData = this.PriceData;
        super.Refresh(count, newThread);
    }
}