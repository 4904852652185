// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type PFixFieldSet } from '../../GroupsImport';
import { FieldsFactory } from '../../../Factory/FieldsFactory';
import { AlgorithmHeatmapInstrumentGroup } from './AlgorithmHeatmapInstrumentGroup';

export class AlgorithmHeatmapRtDataGroup extends AlgorithmHeatmapInstrumentGroup {
    constructor (pfixFieldSet: PFixFieldSet) {
        super(FieldsFactory.ALGORITHM_HEATMAP_RT_GROUP, pfixFieldSet);
    }
}
