import { Resources } from '../../../Commons/properties/Resources';
import { type BaseItem } from '../../cache/BaseItem';
import { type QuickTable } from './QuickTable';
import { QuickTableCell } from './QuickTableCell';
import { QuickTableRow } from './QuickTableRow';

export class QuickTableGroupRow<ItemType extends BaseItem = any> extends QuickTableRow<ItemType> {
    public isGroupRow = true;
    public collapsed = false;

    public GroupValue = '';

    public sortIndex;

    public infoIconTooltip = ''; // draw (if it is not empty string) info icon with tooltip text = infoIconTooltip

    public groupStr = '';

    public isDirty = true;

    constructor (table: QuickTable<ItemType>) {
        super(null, table);

        const columnsCount = table.columns.length;

        const cells = [];
        for (let i = 0; i < columnsCount; i++) {
            cells.push(new QuickTableCell());
        }

        this.cells = cells;

        this.localize();
    }

    public localize (): void {
        this.groupStr = Resources.getResource(this.GroupValue);
        this.isDirty = true;
    }
}
