// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DISPLAY_QUANTITY_IN_LOTS_V as DISPLAY_QUANTITY_IN_LOTS } from '../Utils/GeneralSettings/SubSettings/ViewSett';

export class SessionSettingsConst {
// Вкладка Trading defaults:
    public static readonly SET_SLTP_VALUES_IN_OFFSET = 'sltpOffsetMode';
    public static readonly SHOW_OFFSET_IN = 'property.OffsetMode';
    public static readonly USE_STOP_LIMIT_INSTEAD_OF_STOP = 'property.useStopLimitInsteadStop';
    // Вкладка Confirmations:
    public static readonly CONFIRM_ORDER_PLACEMENT = 'property.oe.confirmOrderCreate';
    public static readonly CONFIRM_ORDER_CANCELLATION = 'property.oe.confirmOrderCancel';
    public static readonly CONFIRM_ORDER_OR_POSITION_MODIFICATION = 'property.oe.confirmOrderChange';
    public static readonly CONFIRM_POSITION_CLOSING = 'property.oe.confirmClosePosition';
    public static readonly CONFIRM_ALERT_REMOVE = 'property.oe.confirmAlertRemove';
    public static readonly SHOW_DEAL_TICKETS = 'property.dealTickets.showDealTickets';
    // Вкладка View:
    public static readonly IS_ROUNDED_AVERAGE_OPEN_PRICE = 'property.IsRoundedAvgPrice';
    public static readonly DISPLAY_QUANTITY_IN_LOTS = DISPLAY_QUANTITY_IN_LOTS;
    public static readonly REVERSE_BUTTONS_ORDER = 'property.cellscin.useSellBuy';
    public static readonly TOOLTIPS = 'property.isShowToolTips';
    // Types manager
    public static readonly TYPES_MANAGER = 'instruments_defaults';
}
