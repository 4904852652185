// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Connection } from "../../Commons/Connection.ts";
import { Resources } from "../../Commons/properties/Resources.ts";
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";
import { TerceraMessageBoxTemplate } from "../../templates.js";
import { TerceraMessageBox } from "./TerceraMessageBox.js";
import { TerceraWindowBase } from "./TerceraWindowBase.js";

export let TerceraTimeoutMessageBox = TerceraMessageBox.extend({
    data: function ()
    {
        return {

        };
    },
    partials: { bodyPartial: TerceraMessageBoxTemplate },
    forceCloseOnLogout: false,
    OK_Handler: null,
    NO_Handler: null,
    countingTimer: null
});

TerceraTimeoutMessageBox.Show = function ()
{
    var mBox = new TerceraTimeoutMessageBox();
    mBox.set({
        messageBoxType: TerceraMessageBox.msgType.Warning,
        header: Resources.getResource("disconnectMessageBox.Header"),
        messageBoxText: Resources.getResource("disconnectMessageBox.Text").replace("{0}", "30"),
        showNextTimeChB: false,
        okText: Resources.getResource("disconnectMessageBox.logoutButton"),
        cancelText: Resources.getResource("disconnectMessageBox.cancelButton"),
        okToolTop: Resources.getResource("disconnectMessageBox.logoutButton.descr"),
        cancelToolTop: Resources.getResource("disconnectMessageBox.cancelButton.descr")
    });

    MainWindowManager.MainWindow.addControl(mBox);
    mBox.OK_Handler = new Promise(function (resolve) { mBox.OkClicked = resolve }.bind(mBox));
    mBox.NO_Handler = new Promise(function (resolve) { mBox.CancelClicked = resolve }.bind(mBox));
    mBox.StartCounting();
    return mBox;
}

TerceraTimeoutMessageBox.prototype.oncomplete = function ()
{
    TerceraWindowBase.prototype.oncomplete.apply(this);
    this.arrangeControls();
    this.center();
};

TerceraTimeoutMessageBox.prototype.StartCounting = function ()
{
    this.startTime = new Date();
    this.countingTimer = setInterval(this._Counting.bind(this), 200);
}

TerceraTimeoutMessageBox.prototype._Counting = function ()
{
    if (!Connection.isConnectionCorrect())    // #93043
    {
        this.cancelClick()
        return;
    }

    let dif = 30 - new Date(new Date() - this.startTime).getSeconds();
    if (dif < 0)
    {
        this.okClick();
        return;
    }
    let locVal = Resources.getResource("disconnectMessageBox.Text");
    let Text = locVal.replace('{0}', dif);
    this.set({ messageBoxText: Text })
}

TerceraTimeoutMessageBox.prototype.StopCounting = function ()
{
    clearInterval(this.countingTimer);
}

TerceraTimeoutMessageBox.prototype.okClick = function ()
{
    if (this.OkClicked)
        this.OkClicked()
    this.StopCounting();
    this.close();
};

TerceraTimeoutMessageBox.prototype.cancelClick = function ()
{
    if (this.CancelClicked)
        this.CancelClicked()
    this.StopCounting();
    this.close();
};