// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DataCacheToolType } from '../DataCacheToolEnums';
import { FillableDataCacheTool } from './FillableDataCacheTool';

export class CircleDataCacheTool extends FillableDataCacheTool {
    /**
     * Constructor for CircleDataCacheTool
     */
    constructor () {
        super();
        this.ToolType = DataCacheToolType.Circle;
        this.InitPoints(82);
    }

    /**
     * Gets the minimum points count required for the tool
     * @returns {number} - Minimum points count
     */
    MinPointsCount (): number {
        return 2;
    }

    /**
     * Gets the maximum points count required for the tool
     * @returns {number} - Maximum points count
     */
    MaxPointsCount (): number {
        return 2;
    }

    /**
     * Gets the additional points count required for the tool
     * @returns {number} - Additional points count
     */
    AdditionalPointsCount (): number {
        return 80;
    }
}
