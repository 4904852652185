// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { datePickerDropDownHandler } from '../../Utils/AppHandlers.js';
import { TerceraTIFComboBoxTemplate } from '../../templates.js';
import { TerceraComboBox, TerceraComboBoxEvents } from './TerceraComboBox';
import { OrderTif } from '../../Utils/Trading/OrderTifEnum';
import { TerceraLineStyleComboBoxUtils } from '../../Commons/UtilsClasses/TerceraTIFComboBoxUtils';
import { DateTimeUtils } from '../../Utils/Time/DateTimeUtils';
import { SessionSettings } from '../../Commons/SessionSettings';
import { TerceraTIFData } from './TerceraTIFData';
import { TIF } from '../../Utils/Trading/OrderTif';
import $ from 'jquery';

export class TerceraTIFComboBox extends TerceraComboBox {
    constructor () {
        super();
    }

    public override getType (): string { return 'TerceraTIFComboBox'; }

    public oninit (): void {
        super.oninit();
        this.onGTDDateChangedFromDropDown = this.onGTDDateChangedFromDropDown.bind(this);

        this.observe('gtdDate selectedItem', this.onGTDDateChanged);
        this.observe('instrument orderType', this.trySetDefaultGTDDate);
    }

    // TODO. Refactor. Modify order screen doesn't set default gtd date because 'orderType' isn't bound to tifComboBox.
    public trySetDefaultGTDDate (): void {
        const ins = this.get('instrument');
        if (ins === null || ins === undefined) return;

        const ordType = this.get('orderType');
        if (ordType === null || ordType === undefined) return;

        // TODO. Refactor. Remove? UGLY.
        const gtdDate = this.get('gtdDate');
        // Date is set - skip. UGLY. Remove. Only for modify order.
        if (gtdDate && gtdDate - DateTimeUtils._ZeroTime.getTime() !== 0) {
            return;
        }

        const defTif = SessionSettings.getDefaultTifForOrderType(ordType);
        void this.set('gtdDate',
            defTif === OrderTif.GTD
                ? TIF.getDefaultExpireTimeForOrderType(ordType, ins)
                : new Date());
    }

    public override private_OnMenuItemSelected (menuItem): void {
        super.private_OnMenuItemSelected(menuItem);

        const selectedItem = this.get('selectedItem');
        if (!selectedItem) return;

        const tif = selectedItem.value;
        if (tif !== OrderTif.GTD) return;

        const $root = $(this.find('div'));
        const $window = $(window);

        const offset = $root.offset();
        const posY = offset.top - $window.scrollTop() + 19;
        const posX = offset.left - $window.scrollLeft() - 2;

        const curDate = this.get('gtdDate') || new Date();

        const additionalParams: any = {};

        const minDate = new Date();
        additionalParams.minDate = minDate;

        const ins = this.get('instrument');
        if (ins?.isFuturesSymbol) {
            additionalParams.maxDate = ins.lastTradeDate;
        }
        // else if (RulesSet.MAX_GTD_DURATION)
        // {
        //     var maxDate = new Date();
        //     maxDate.setTime(minDate.getTime());
        //     maxDate.setDate(maxDate.getDate() + RulesSet.MAX_GTD_DURATION);
        //     additionalParams.maxDate = maxDate;
        // }

        datePickerDropDownHandler.Show(
            curDate,
            this.onGTDDateChangedFromDropDown,
            posX,
            posY,
            additionalParams);
    }

    public override onSelectedItemChanged (newValue, oldValue): void {
        if (!isNullOrUndefined(newValue) && !isNullOrUndefined(oldValue) && (newValue.value === oldValue.value)) {
            return;
        }
        super.updateSelection(newValue);
        this.fire(TerceraComboBoxEvents.ValueChange, new TerceraTIFData(this.get('gtdDate'), this.get('selectedItem')));
    }

    public onGTDDateChangedFromDropDown (date): void {
        const now = new Date();
        date.setHours(now.getHours());
        date.setMinutes(now.getMinutes());
        date.setSeconds(now.getSeconds());
        void this.set('gtdDate', date);
    }

    public onGTDDateChanged (): void {
        this.updateTIFMenuItems(this.menuItems);
        this.fire(TerceraComboBoxEvents.ValueChange, new TerceraTIFData(this.get('gtdDate'), this.get('selectedItem')));
    }

    public override createMenuItems (selectedItem): any[] {
        const menuItems = super.createMenuItems(selectedItem);
        this.updateTIFMenuItems(menuItems);
        return menuItems;
    }

    public updateTIFMenuItems (menuItems): void {
        if (!menuItems) return;

        const gtdDate = this.get('gtdDate');
        if (!gtdDate) return;

        const len = menuItems.length;
        for (let i = 0; i < len; i++) {
            const menuItem = menuItems[i];

            const tif = menuItem.tag;
            menuItem.text = TerceraLineStyleComboBoxUtils.getLocalizedTIF(
                tif,
                tif === OrderTif.GTD ? gtdDate : undefined);
        }
    };
}

TerceraComboBox.extendWith(TerceraTIFComboBox, {
    template: TerceraTIFComboBoxTemplate,
    data: function () {
        return {
            gtdDate: null,
            instrument: null,
            orderType: null,
            isAllowEmpty: false
        };
    },
    computed: {
        selectedTIFItem: {
            get: function () {
                const selectedItem = this.get('selectedItem');
                if (!selectedItem) return '';

                return TerceraLineStyleComboBoxUtils.getLocalizedTIF(
                    selectedItem.value,
                    this.get('gtdDate'));
            }
        }
    }
});
