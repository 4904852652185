// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { AlertPanelSideBarTemplate } from '../../../../templates';
import { ControlsTypes, PanelNames } from '../../../UtilsClasses/FactoryConstants';
import { type AlertSubImage } from '../../../../Utils/Alert/AlertConstants';
import { type Alert } from '../../../../Commons/cache/Alert';
import { AlertSideBarController } from '../../../../Commons/SideBarController/AlertSideBarController';
import { type AlertItemSideBar } from '../SideBarItems/AlertItemSideBar';
import { Resources } from '../../../../Commons/properties/Resources';
import { MainWindowManager } from '../../../UtilsClasses/MainWindowManager';
import { HtmlScroll } from '../../../../Commons/HtmlScroll';
import { GeneralSettings } from '../../../../Utils/GeneralSettings/GeneralSettings';
import { RulesSet } from '../../../../Utils/Rules/RulesSet';
import { DataCache } from '../../../../Commons/DataCache';
import { PanelSideBarBase } from './PanelSideBarBase';
import { PanelSettingsScreen } from '../../../screen/PanelSettingsScreen';
import { MouseButtons } from '../../../UtilsClasses/ControlsUtils';
import { SessionSettings } from '../../../../Commons/SessionSettings';
import { type DynProperty } from '../../../../Commons/DynProperty';
import { contextMenuHandler } from '../../../../Utils/AppHandlers';

export class AlertPanelSideBar extends PanelSideBarBase {
    private _alertSideBarController: AlertSideBarController = null;
    private _alertsArray: Alert[] = [];
    private createAlertPanelOpened = false;
    private createAlertPanelRef = null;
    private itemsContextMenu: any[] = [];

    constructor () { super(); }

    oncomplete (): void {
        super.oncomplete();
        this._alertSideBarController = new AlertSideBarController();

        this.subscription();
        this.populateItems();
        this.populateContextMenu();
        void this.set({ canAddAlert: this.canAddRow() });
        this.addScroll();

        this.on('onAlertAction', this.onAlertAction);
        this.on('onAddClick', this.onAddClick);
    };

    onMouseDown (event): void {
        super.onMouseDown(event);

        if (event.original.button === MouseButtons.Right) {
            contextMenuHandler.Show(this.itemsContextMenu, event.original.pageX, event.original.pageY);
        }
    }

    private addScroll (): void {
        HtmlScroll.addScroll(this.find('.alertPanelSideBar-body'));
    };

    private populateItems (): void {
        const arr = this._alertSideBarController.getAlerts();
        this._alertsArray = arr;

        void this.set({
            alertsArray: arr,
            canAddAlert: this.canAddRow()
        });
    };

    public getType (): ControlsTypes {
        return ControlsTypes.AlertPanelSideBar;
    };

    private onAddClick (): void {
        this.onAddCreateAlertPanel();
    };

    private readonly onAddCreateAlertPanel = async (alert?: Alert): Promise<void> => {
        const panel = MainWindowManager.Factory.addPanel(PanelNames.CreateAlertPanel);
        const element = document.getElementById('sideBarPanelContainer');
        const height = element.offsetHeight - 2;
        const top = element.offsetTop + 1;
        const left = element.offsetLeft;

        if (!isNullOrUndefined(alert)) { panel.setUpdateParams(alert); }
        panel.set({ left, top, height });
        panel.CallerPanel = this;
        panel.OnCloseHandler = () => { this.createAlertPanelOpened = false; };

        await panel.set('parentContainerControl', this);
        panel.setFocus();
        this.createAlertPanelRef = panel;
        this.createAlertPanelOpened = true;
    };

    public lostFocus (): void {
        if (!this.createAlertPanelOpened) { super.lostFocus(); }
    }

    private subscription (): void {
        this._alertSideBarController.subscribe();
        this._alertSideBarController.subscribeToAdd(this.onAddAlert);
        this._alertSideBarController.subscribeToRemove(this.onRemoveAlert);
        this._alertSideBarController.subscribeToUpdate(this.onUpdateAlert);
        this._alertSideBarController.subscribeToEdit(this.onAddCreateAlertPanel);
    }

    private readonly onAddAlert = (alert: Alert): void => {
        this._alertsArray.push(alert);
        this._alertsArray = this._alertSideBarController.sortAlerts(this._alertsArray);

        void this.set({
            alertsArray: this._alertsArray,
            canAddAlert: this.canAddRow()
        });

        this.fire('updateHeader');
    };

    private readonly onRemoveAlert = (alert: Alert): void => {
        for (let i = 0; i < this._alertsArray.length; i++) {
            if (this._alertsArray[i].AlertId === alert.AlertId) {
                this._alertsArray.splice(i, 1);
            }
        }

        this._alertsArray = this._alertSideBarController.sortAlerts(this._alertsArray);
        void this.set({
            alertsArray: this._alertsArray,
            canAddAlert: this.canAddRow()
        });

        this.fire('updateHeader');
    };

    private readonly onUpdateAlert = (alert: Alert): void => {
        void this.update();
    };

    public onAlertAction (event, item: AlertItemSideBar, action: AlertSubImage): void {
        const alert = item.Alert;
        this._alertSideBarController.onAlertActionButtonClick(alert, action);
    };

    public localize (): void {
        void this.set({
            btnText: Resources.getResource('screen.Alerts.AddAlert'),
            btnTooltip: Resources.getResource('screen.Alerts.AddAlert.Tooltip'),
            btnDisabledTooltip: Resources.getResource('screen.Alerts.AddAlert.Disabled.Tooltip'),
            noDataText: Resources.getResource('screen.Alerts.noDataText')
        });
    };

    onteardown (): void {
        this._alertSideBarController.unsubscribe();
        this._alertSideBarController.unsubscribeToAdd(this.onAddAlert);
        this._alertSideBarController.unsubscribeToRemove(this.onRemoveAlert);
        this._alertSideBarController.unsubscribeToUpdate(this.onUpdateAlert);
        this._alertSideBarController.unsubscribeToEdit(this.onAddCreateAlertPanel);
    };

    getProperties (): object {
        const { NotificationVariable, AlertTypeVariable, ConditionVariable, ImportanceVariable, AfterExecuteVariable } = GeneralSettings.Alert;
        return { NotificationVariable, AlertTypeVariable, ConditionVariable, ImportanceVariable, AfterExecuteVariable };
    };

    canAddRow (): boolean {
        const arr = this.get('alertsArray');
        const count = DataCache.getRuleNumberValueForMyUser(RulesSet.VALUE_TOTAL_MAX_ALERTS);
        if (count === -1) {
            return true;
        }

        if (count <= arr.length) {
            return false;
        }

        return true;
    }

    public isLostFocusForbidden (): boolean {
        return this.createAlertPanelOpened;
    }

    public customLostFocus (parentPanel): boolean {
        if (super.customLostFocus(parentPanel)) return true;
        if (this.isLostFocusForbidden() && this.createAlertPanelRef?.checkPossibilityClosing()) {
            this.processFocus(null); // call automatically this.createAlertPanelRef?.lostFocus();
            this.createAlertPanelOpened = false;
            return true;
        }
        if (!isNullOrUndefined(this.createAlertPanelRef)) { this.createAlertPanelRef.afterSetFocus = () => { parentPanel.setFocus(); }; }
        return false;
    }

    private populateContextMenu (): void {
        const items = [];
        items.push(
            {
                text: Resources.getResource('screen.Alerts.Settings.ContextMenu'),
                event: this.ShowProperties.bind(this)
            }
        );

        this.itemsContextMenu = items;
    };

    ShowProperties (): void {
        PanelSettingsScreen.EditProperties(this, null, Resources.getResource('screen.Alerts.Settings.Screen'));
    }

    Properties (): DynProperty[] {
        return SessionSettings.GetAlertPanelProperties();
    }

    callBack (newProperties): void {
        SessionSettings.AlertPanelCallBack(newProperties);
        SessionSettings.save();
    }
}

PanelSideBarBase.extendWith(AlertPanelSideBar,
    {
        template: AlertPanelSideBarTemplate,
        data: function () {
            return {
                alertsArray: [],
                btnText: '',
                btnTooltip: '',
                btnDisabledTooltip: '',
                canAddAlert: true,
                noDataImg: 'alerts'
            };
        }
    });
