// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";
import { KeyCode } from "../../Commons/KeyEventProcessor.ts";
import { TerceraLabel } from "../elements/TerceraLabel.js";
import { TerceraWindowBase } from "./TerceraWindowBase.js";
import { TerceraLookupy, TerceraLookupEvents } from "../elements/Lookup/TerceraLookup.ts";
import { TerceraInstrumentLookup } from "../elements/Lookup/TerceraInstrumentLookup.ts";

export let TerceraQuickInstrumentLookupScreen = TerceraWindowBase.extend({
    data: function ()
    {
        return {
            zIndex: 300,
            height: TerceraQuickInstrumentLookupScreen.HEIGHT,
            width: TerceraQuickInstrumentLookupScreen.WIDTH,
            header: "",
            resizable: false,
            showFooter: false,
            movable: false,
            showHeader: false,
        }
    },
});
//такое же как и в конструкторе
TerceraQuickInstrumentLookupScreen.WIDTH = 270;
TerceraQuickInstrumentLookupScreen.HEIGHT = 70;
TerceraQuickInstrumentLookupScreen.prototype.getType = function () { return "TerceraQuickInstrumentLookupScreen" };

TerceraQuickInstrumentLookupScreen.Show = function (posX, posY, event, callBackForInstrumentSelection, functionForOpenQuickTree, parent)
{
    var terceraquickinstrumentlookupscreen = new TerceraQuickInstrumentLookupScreen();
    terceraquickinstrumentlookupscreen.setLocation(posX, posY);
    MainWindowManager.MainWindow.addControl(terceraquickinstrumentlookupscreen);

    terceraquickinstrumentlookupscreen.callBackForInstrumentSelection = callBackForInstrumentSelection;
    terceraquickinstrumentlookupscreen.functionForOpenQuickTree = functionForOpenQuickTree;
    terceraquickinstrumentlookupscreen.parentFocus = parent;

    var lookup = new TerceraInstrumentLookup();
    lookup.oninit();
    lookup.setLocation(10, 30);
    lookup.set({ width: 255, isPosAbsolute: true });
    lookup.off('lookupBtnClick');
    lookup.on('lookupBtnClick', function ()
    {
        if (this.functionForOpenQuickTree)
            this.functionForOpenQuickTree();
        this.close();
    }.bind(terceraquickinstrumentlookupscreen));
    lookup.on(TerceraLookupEvents.SelectedItemChanged, function (sender, newInstrument)
    {
        if (this.callBackForInstrumentSelection)
            this.callBackForInstrumentSelection([newInstrument]);
        this.close();
    }.bind(terceraquickinstrumentlookupscreen));
    lookup.on("complete", function (event)
    {
        this.set({ selectedItem: null });
        this.selectedItem = null;
        this.textBox.set({ text: "" })
        this.selectedItemIndex = -1;
        setTimeout(function ()
        {
            this.textBox.set({ text: event.original.key, skipfocus: true });
            this.textBox.setFocus();
        }.bind(this), 10)
    }.bind(lookup, event));

    //lookup.moveToItem = function () { };
    terceraquickinstrumentlookupscreen.addControl(lookup);
    lookup.CanMoveToNextIfHaveError = false;

    var label = new TerceraLabel();
    label.setLocation(10, 10);
    label.set({ text: lookup.getButtonTooltip(), isPosAbsolute: true });
    terceraquickinstrumentlookupscreen.addControl(label);
    return terceraquickinstrumentlookupscreen;
};

TerceraQuickInstrumentLookupScreen.prototype.lostFocus = function ()
{
    TerceraWindowBase.prototype.lostFocus.apply(this);
    this.close();
};
TerceraQuickInstrumentLookupScreen.prototype.onKeyDown = function (event)
{
    if (event.original.keyCode === KeyCode.ESC)
    {
        this.close();
        return true;
    }
};
TerceraQuickInstrumentLookupScreen.prototype.close = function ()
{
    if (this.parentFocus)
    {
        var func = function ()
        {
            this.setFocus();
            this.find('div').focus();
        }.bind(this.parentFocus);
        delete this.parentFocus;
        func();
    }
    TerceraWindowBase.prototype.close.call(this)
};