// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DirectAlgorithmMessageBase } from './DirectAlgorithmMessageBase';
import { Message } from '../Message';

export class DirectAlgorithmTopVolumeMessage extends DirectAlgorithmMessageBase {
    constructor () {
        super(Message.CODE_PFIX_ALGORITHM_TOP_VOLUME_MESSAGE);
    }

    // AlgorithmId = -1;                               // 694 - creating in DirectAlgorithmMessageBase
    // AlgorithmName = null;                           // 696 - creating in DirectAlgorithmMessageBase
    AlgorithmTop: number | null = null; // 700 - (int)

    AlgorithmTopVolumeDataGroups: any[] | null = null; // 104 - группа инструментов для юзеров с RT датой.
    AlgorithmTopVolumeDelayDataGroups: any [] | null = null; // 105 - группа инструментов для юзеров с Delay data.
    AlgorithmTopVolumeInstrumentGroups: any[] | null = null; // 106
}
