// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { Resources } from '../../Commons/properties/Resources';
import { Rectangle } from '../../Commons/Geometry';
import { LayersEnum, TerceraChartBaseRenderer } from './TerceraChartBaseRenderer';
import { MouseButtons } from '../../Controls/UtilsClasses/ControlsUtils';
import { TerceraChartAction, TerceraChartActionEnum } from '../TerceraChartAction';
import { ThemeManager } from '../../Controls/misc/ThemeManager';
import { type TerceraChartBase } from '../TerceraChartBase';

export class TerceraChartToBeginRenderer extends TerceraChartBaseRenderer {
    public toBeginRect: Rectangle = new Rectangle();
    public isHovered: boolean = false;
    public isToBeginActive: boolean = false;

    public static toBeginDefaultImage: any;
    public static toBeginHoverImage: any;
    public static toBeginDisableImage: any;

    constructor (chart: TerceraChartBase) {
        super(chart);

        this.assignLayer = LayersEnum.CrossHair;

        this.ThemeChanged();
        this.SetClassName('TerceraToBeginRenderer');
    }

    public override Draw (gr: any, window: any): void {
        if (!this.Visible) return;

        this.toBeginRect.X = window.ClientRectangle.X + window.ClientRectangle.Width - 16;
        this.toBeginRect.Y = window.ClientRectangle.Y + window.ClientRectangle.Height - 16;
        this.toBeginRect.Width = 13;
        this.toBeginRect.Height = 13;

        const state = this.chart.TerceraChartActionProcessor.GetTerceraChartActionState(TerceraChartAction.Create(TerceraChartActionEnum.ToBegin, this));
        this.isToBeginActive = state.Enabled;

        let im = null;
        if (state.Enabled) {
            if (this.isHovered) {
                im = ThemeManager.CurrentTheme.tobeginHoverImage;
            } else {
                im = TerceraChartToBeginRenderer.toBeginDefaultImage;
            }
        }

        if (!isNullOrUndefined(im)) gr.drawImage(im, this.toBeginRect.X, this.toBeginRect.Y);
    }

    public override ThemeChanged (): void {
        super.ThemeChanged();

        TerceraChartToBeginRenderer.toBeginDefaultImage = ThemeManager.CurrentTheme.tobeginDefaultImage;
        TerceraChartToBeginRenderer.toBeginHoverImage = ThemeManager.CurrentTheme.tobeginHoverImage;
        TerceraChartToBeginRenderer.toBeginDisableImage = ThemeManager.CurrentTheme.tobeginDisableImage;
    }

    public override ProcessMouseDown (e: any): boolean {
        if (e.Button !== MouseButtons.Left) return false;
        const isHovered = this.isButtonHovered(e.Location);
        if (!isHovered) return false;
        return this.chart.TerceraChartActionProcessor.ProcessTerceraChartAction(TerceraChartAction.Create(TerceraChartActionEnum.ToBegin, this));
    }

    public override ProcessMouseMove (e: any): boolean {
        const isHovered = this.isButtonHovered(e.Location);
        if (isHovered !== this.isHovered) {
            e.NeedRedraw = true;
        }

        this.isHovered = isHovered;

        return super.ProcessMouseMove(e);
    }

    public isButtonHovered (pt: any): boolean {
        return this.toBeginRect.Contains(pt.X, pt.Y);
    }

    public override ProcessMouseLeave (e: any): void {
        super.ProcessMouseLeave(e);
        this.isHovered = false;
        e.NeedRedraw = true;
    }

    public override GetTooltip (e: any): string | null {
        if (!this.Visible || !this.isHovered) return null;

        const state = this.chart.TerceraChartActionProcessor.GetTerceraChartActionState(TerceraChartAction.Create(TerceraChartActionEnum.ToBegin, this));
        if (state?.ToolTiplocKey != null) {
            return Resources.getResource(state.ToolTiplocKey);
        }
        return null;
    }
}
