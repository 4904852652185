// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { OrderParamsContainer } from './cache/OrderParams/OrderParamsContainer';
import { PanelNames } from '../Controls/UtilsClasses/FactoryConstants';
import { CustomErrorClass, ErrorInformationStorage } from './ErrorInformationStorage';
import { CustomEvent } from '../Utils/CustomEvents';
import { Connection } from './Connection';
import { FINISH_BLOCK_TRANSFER } from '../Utils/CommonConst';
import { Periods } from '../Utils/History/TFInfo';
import { AssetTradingMode } from '../Utils/Asset/AssetConst';
import { OptionPutCall } from '../Utils/Instruments/OptionPutCall';
import { HistoricalBM } from './cache/HistoricalBM';
import { type DirectMamGroupMessage, DirectInstrumentMessage, type DirectOrderDialogMessage, DirectQuoteMessage, DirectReportMessage, DirectRiskWarningMessage, Message, type DirectInstrumentGroupMessage, type DirectYTMMessage, type DirectNonFixedInstumentStrikesResponseMessage } from '../Utils/DirectMessages/DirectMessagesImport';
import { OptionContract } from '../Utils/Instruments/OptionContract';
import { OperationType } from '../Utils/Trading/OperationType';
import { OrderType } from '../Utils/Trading/OrderType';
import { ProductType } from '../Utils/Instruments/ProductType';
import { MarginTypes } from '../Utils/Instruments/MarginTypes';
import { PriceLimitMeasure } from '../Utils/Instruments/PriceLimitMeasure';
import { InstrDateSettings } from '../Utils/Instruments/InstrDateSettings';
import { VariableTick } from '../Utils/Instruments/VariableTick';
import { OptionTradingStyle } from '../Utils/Instruments/OptionTradingStyle';
import { CloseAccountResponseStatus, DirectPropertyOwner, SubscribeType } from '../Utils/Enums/Constants';
import { TIF } from '../Utils/Trading/OrderTif';
import { type OrderTif, OrderTifMap } from '../Utils/Trading/OrderTifEnum';
import { InstrumentTypes } from '../Utils/Instruments/InstrumentTypes';
import { OrderStatus } from '../Utils/Trading/OrderStatus';
import { AccountType } from '../Utils/Account/AccountType';
import { EventType } from './cache/EventConstants';
import { NewTrade } from './cache/NewTrade';
import { HistoryType } from '../Utils/History/HistoryType';
import { MathUtils } from '../Utils/MathUtils';
import { Statement } from './cache/Statement';
import { AccountOperationReport } from './cache/AccountOperationReport';
import { CommissionPlan } from './cache/Commissions/CommissionPlan';
import { News } from './cache/News';
import { OrderHistory } from './cache/OrderHistory';
import { BrokerMessageResponseType } from '../Utils/Notifications/BrokerMessageResponseType';
import { Idea } from './cache/Idea';
import { TradingSystem } from './cache/TradingSystem';
import { StrategySubscriptionStatus } from '../Utils/TradingIdeas/StrategySubscriptionStatus';
import { NotificationsCounterChangedEvent } from '../Utils/Notifications/NotificationsCounterChangedEvent';
import { SpreadPlan } from './cache/SpreadPlan';
import { EventCache } from './cache/EventCache';
import { Route } from './cache/Route';
import { InstrumentBalancer, UserBalancer, UserBalancesAssetBalanceFields } from './InstrumentBalancer';
import { SourceCollection } from './cache/SourceCollection';
import { SessionsContainer, TradeSessionStatus } from '../Utils/Session/Sessions';
import { InstrumentGroup } from './cache/InstrumentGroup';
import { CrossratesPlans } from './cache/CrossratesPlans';
import { CustodialPlans } from './cache/CustodialPlans';
import { CrossRateCache } from './cache/CrossRateCache';
import { CountryCache } from './cache/CountryCache';
import { Resources } from './properties/Resources';
import { QuickTableComparingType } from '../Utils/QuickTableMisc/QuickTableComparingType';
import { RulesSet } from '../Utils/Rules/RulesSet';
import { PriceFormatter } from '../Utils/Instruments/PriceFormatter';
import { Asset, AssetType } from './cache/Asset';
import { Account } from './cache/Account';
import { User } from './cache/User';
import { AlgorithmCache } from './cache/AlgorithmCache';
import { HeatmapCache } from './cache/Algorithm/Heatmap/HeatmapCache';
import { InstrumentSpecificType } from '../Utils/Instruments/InstrumentSpecificType';
import { QuoteCache } from './cache/QuoteCache';
import { GeneralSettings } from '../Utils/GeneralSettings/GeneralSettings';
import { Order } from './cache/Order';
import { Position, ServerCalculation } from './cache/Position';
import { RiskPlan } from './cache/RiskPlan';
import { InstrumentUtils } from '../Utils/Instruments/InstrumentUtils';
import { Instrument } from './cache/Instrument';
import { AllowedReport } from './cache/AllowedReport';
import { AccessType } from './cache/AllowedReportConstants';
import { brokerMessageScreenHandler, messageBoxHandler, riskWarningMessageScreenHandler, snapshotScreenHandler, workspaceManagerHandler } from '../Utils/AppHandlers.js';
import { PortfolioCache } from '../Portfolio/PortfolioCache';
import { TradingLockUtils } from '../Utils/TradingLockUtils';
import { OrderExecutor } from './Trading/OrderExecutor';
import { ExternalLinksCache } from './cache/ExternalLinksCache';
import { EntitlementManager } from './cache/Entitlement/EntitlementManager';
import { AlertManager } from './cache/AlertManager';
import { TextMessageType } from '../Utils/Notifications/TextMessageType';
import { RulesCache } from './cache/RulesCache';
import { ApplicationInfo } from './ApplicationInfo';
import { IsAllowed } from './IsAllowed';
import { LocalStorage } from './LocalStorage';
import { PlacedFrom } from '../Utils/Trading/PlacedFrom';
import { FundingRateMarkupPlans } from './cache/FundingRateMarkupPlans';
import { Md5 } from 'ts-md5';
import { type CrossratesPlan } from './cache/CrossratesPlan';
import { type AssetBalance } from './cache/AssetBalance';
import { MarginCounterLocal } from './MarginCounterLocal';
import { ArrayUtils } from '../Utils/ArrayUtils';
import { OptionsCache } from './cache/OptionsCache';
import { OrderHistoryCache } from './cache/OrderHistoryCache';
import { DateTimeUtils } from '../Utils/Time/DateTimeUtils';
import { type MarginInfoParameters } from './UtilsClasses/MarginInfo/MarginInfoParameters';
import { type IMarginResponse } from '../Utils/Interfaces/Response/IMarginResponse';
import { MamGroupsCache } from './cache/MamGroupsCache';

class _DataCache {
// Events
    public OnAddAccount = new CustomEvent();
    public OnUpdateAccount = new CustomEvent();
    public OnRemoveAccount = new CustomEvent();

    public OnAddInstrument = new CustomEvent();
    public OnUpdateInstrument = new CustomEvent();

    public OnAddPosition = new CustomEvent();
    public OnUpdatePosition = new CustomEvent();
    public OnRemovePosition = new CustomEvent();
    public OnPositionExerciseStatusChanged = new CustomEvent();

    // CorporateAction
    public OnAddCorporateAction = new CustomEvent();
    public OnUpdateCorporateAction = new CustomEvent();
    public OnRemoveCorporateAction = new CustomEvent();

    public OnAddOrder = new CustomEvent();
    public OnUpdateOrder = new CustomEvent();
    public OnRemoveOrder = new CustomEvent();
    public OnActivateOrder = new CustomEvent();

    public OnAddSLOrderToPosition = new CustomEvent();
    public OnRemoveSLOrderFromPosition = new CustomEvent();

    public OnAddTPOrderToPosition = new CustomEvent();
    public OnRemoveTPOrderFromPosition = new CustomEvent();

    public OnUpdateAssetEvent = new CustomEvent();

    public OnAddNewTradeEvent = new CustomEvent();
    public OnRemovedTradeEvent = new CustomEvent();

    // TODO. Rename if necessary.
    public OnReportMessage = new CustomEvent();
    public OnMessageForTicketViewer = new CustomEvent();

    public AddOrderHistoryEvent = new CustomEvent();
    public RemovedOrderHistoryEvent = new CustomEvent();

    public OrderHistoryReportCompleted = false;

    public filledOrdersArray = [];
    public FilledOrdersReportCompleted = false;

    public StatementArray = [];
    public StatementReportCompleted = false;

    public MamSummaryArray = [];
    public MamSummaryReportCompleted = false;

    public IgnoreTradeSession = false;

    public realTimeFilledOrdersArray = [];

    public ExistingInstrumentTypesSet: any = {};

    public reportDict: any = {};
    public OrderDict: any = {};
    public PositionDict: any = {};
    public PositionDictCorporateAction: any = {};
    public Instruments: Record<any, Instrument> = {};
    public InstrumentTradableIDChache: any = {};
    public InstrumentTradableIDChacheContiniousContract: any = {};
    public InstrumentsArray = [];
    public InstrumentsByTradableIdCache: any = {};
    public InstrumentsTableByIDs: any = {};
    public InstrumentsContiniousContractIDS: any = {};
    public InstrumentsByTradingSessionsId: any = {}; // {ID:[...]}
    public InstrumentsBySourceRouteIdTradableId: any = {}; // список инструментов для которых инструмент с TradableId:RouteId является DataSource инструментом
    public Accounts: Record<string, Account> = {};

    private _ownedAccounts: Record<string, Account> = {}; // user's owned accounts
    public SessionDict: any = {};
    public Loaded = false;
    // +++++ нужно поправить
    public FAllowedHistoryTypes = [HistoryType.BID, HistoryType.LAST, HistoryType.ASK];
    public CrossRateCache = new CrossRateCache(this);

    public BrendingRules: any = null;

    public assetCollection: any = {};

    // chart place order default settings
    public TIF = [];
    public TIF_Limit_And_Stop_Limit = [];
    public TIF_Stop = [];
    // chart place order default settings

    // TODO. cleanup.
    public UserLogin = '';

    public NonFixedList = true;
    public OrderParameterContainer = new OrderParamsContainer();

    /// <summary>
    /// Кеш истории
    /// </summary>
    private readonly FOrderHistoryCache = new OrderHistoryCache(this);

    /// <summary>
    /// Кеш котировок
    /// </summary>
    public FQuoteCache = new QuoteCache(this);

    // for order placing etc
    public FOrderExecutor: OrderExecutor;

    /// <summary>
    /// ONLY TRADABLE routes
    /// </summary>
    public routes: Record<string, Route> = {};
    /// <summary>
    /// ONLY INFO routes
    /// </summary>
    public infoRoutes: any = {};

    public newTradesDict: Record<string, NewTrade> = {};
    public Exchanges: any = {};
    public TradingExchanges: string[] = [];
    public tradeSessionStatusCache: any = {};

    public riskPlanDict = {};
    public customListsDict = {};
    public lastRiskPlanItem: any = null;
    public instrTypes = {};

    public deferredMessages = {};
    public deferredInsReq = {};

    //
    public OnReintialize = new CustomEvent();

    public instrNames: any = {};

    public InstrumentBalancerTable: Record<string, InstrumentBalancer> = {};
    // public userBalanserTable : any = {};

    public baseCurrency = 'USD'; // валюта сервера
    public userBaseCurrency = 'USD'; // валюта юзера
    public filledOrdersCurrency = 'USD'; // валюта юзера для панели filldOrder

    public _userHasSameCurrencyOnAllAccounts: any = null;

    public LoadedResolve: any = null;

    public EmptyUserBalancer = new UserBalancer(null);

    // TODO?
    public ContractsCache: any = {};
    public _cachedInsListByContractID: any = {};

    public InstrumentsByIdCache: Map<string, Instrument[]> = new Map<string, Instrument[]>();

    public SpreadPlans: any = {};
    public SpreadPlan: any = null;

    public FLogin: any = null;
    public LoginUser: any = null;

    public AggregatedInstruments: any = {};
    // public cachedInstrumentsWR : any = null;

    public PortfolioCache = new PortfolioCache(this);
    public AlgorithmCache = new AlgorithmCache(this);
    public HeatmapCache = new HeatmapCache(this);
    public OptionsCache = new OptionsCache(this);

    public CommissionPlans: any = {};
    public OnCommissionPlanUpdate = new CustomEvent();

    public CrossratesPlans = new CrossratesPlans(this); // #101199

    public RulesCache = new RulesCache(this);

    public CompanyName: any = null;
    public BrokerInformation: any = null;
    public PoweredByName: any = null;
    public PoweredByURL: any = null;
    public BrandingVisibleInstrumentLogo = false;
    public AllowPreviewPassword: boolean = false;

    public ExternalLinksCache = new ExternalLinksCache();

    public SubscribedToNewsRoute: any = null;
    public News: any = {};
    public OnAddNewsEvent = new CustomEvent();
    public OnRemoveNewsEvent = new CustomEvent();
    public userAndConnectionNameEncryptedByMD5: any = null;

    public tradingSystems: any = {};
    public tradingIdeas: any = {};
    public OnTradingIdeaAdd = new CustomEvent();
    public OnTradingIdeaCancel = new CustomEvent();
    public OnTradingIdeaFirstOpen = new CustomEvent();
    public OnTradingSignalRequestSend = new CustomEvent();
    public OnTradingSystemsChange = new CustomEvent();
    public OnTradingSystemUnsubscribe = new CustomEvent();

    public OnSnapshotResponseReceived = new CustomEvent();

    public OnProcessBrokerMessage = new CustomEvent();

    public OnBMCounterMsgReceived = new CustomEvent();

    public CountryCache = new CountryCache();

    public AlertManager = new AlertManager(this);
    public EntitlementManager = new EntitlementManager(this);

    public CustodialPlans = new CustodialPlans();
    public ActiveUser: any = null;// TODO без понятия что с этим делать
    public _userGroups: any = {};
    public Users: any = {};

    public FundingRateMarkupPlans = new FundingRateMarkupPlans();

    public OnTradingSessionStatusUpdated = new CustomEvent();
    public productTypeEnabilityCache: any = {};

    public SourceCollection = new SourceCollection();

    public OnRecalculatedBalances = new CustomEvent();

    // TODO  MainAccountNew
    public MainAccountNew: any = null;

    public closeAccountApproved = false;
    public closeAccountRequestID: any = null;
    public OnChangeCloseAccountRequestID = new CustomEvent();
    private _accountsCount: number;
    public BrandingClientSessionTimeoutInMinutes: number;
    public FirstNewsSubscription: any[];
    public currentWorkspaceInsDict: any;
    private _userHasSameCommissionAndSwapPlanOnAllAccounts: boolean | null = null;

    constructor () {
        new IsAllowed(this);

        ErrorInformationStorage.SetDataCache(this);

        this.FQuoteCache.onReloadHistory = this.quoteCache_OnReload.bind(this);

        this.FOrderExecutor = new OrderExecutor(this);

        this.FOrderHistoryCache.AddOrderHistoryEvent.Subscribe((orderHistory) => { this.AddOrderHistoryEvent.Raise(orderHistory); }, this);
        this.FOrderHistoryCache.RemovedOrderHistoryEvent.Subscribe((orderHistory) => { this.AddOrderHistoryEvent.Raise(orderHistory); }, this);

        RiskPlan.showProductType = false;
    }

    public cleanup (): void {
    // Data start to load
        this.Loaded = false;

        this.CustodialPlans.Clear();
        this.AlertManager.Clear(); // тут должна произойти отписка алертами от котировок, это должно случиться раньше чем будут очищены инструменты иначе не удастся отправить корректный запрос на отписку
        this.EntitlementManager.Clear();
        this.FundingRateMarkupPlans.Clear();

        // lost clean
        this.UserLogin = '';
        this.instrNames = {};
        this.InstrumentsByIdCache.clear();

        this.FLogin = null;
        this.LoginUser = null;

        this.FOrderHistoryCache.Clear();
        this.OrderHistoryReportCompleted = false;
        this.filledOrdersArray = [];
        this.FilledOrdersReportCompleted = false;
        this.StatementArray = [];
        this.StatementReportCompleted = false;
        this.MamSummaryArray = [];
        this.MamSummaryReportCompleted = false;
        this.realTimeFilledOrdersArray = [];

        this.ExistingInstrumentTypesSet = {};
        this.reportDict = {};
        this.OrderDict = {};
        this.newTradesDict = {};
        this.PositionDict = {};
        this.PositionDictCorporateAction = {};
        this.Instruments = {};
        this.InstrumentTradableIDChache = {};
        this.InstrumentTradableIDChacheContiniousContract = {};
        this.InstrumentsByTradableIdCache = {};
        this.InstrumentsArray = [];
        this.InstrumentsTableByIDs = {};
        this.InstrumentsContiniousContractIDS = {};
        this.InstrumentsByTradingSessionsId = {};
        this.InstrumentsBySourceRouteIdTradableId = {};
        this.Accounts = {};
        MamGroupsCache.clear();
        this.SessionDict = {};
        // +++++ нужно поправить
        this.FAllowedHistoryTypes = [HistoryType.BID, HistoryType.LAST, HistoryType.ASK];
        this.TIF = [];
        this.TIF_Limit_And_Stop_Limit = [];
        this.TIF_Stop = [];

        this.CrossRateCache.Clear();
        EventCache.Clear();
        this.FQuoteCache.clear();
        this.PortfolioCache.Clear();
        this.AlgorithmCache.Clear();
        this.HeatmapCache.Clear();
        this.RulesCache.Clear();
        this.OptionsCache.clear();

        this.routes = {};
        this.infoRoutes = {};
        this.Exchanges = {};
        this.TradingExchanges.length = 0;
        this.tradeSessionStatusCache = {};

        this.assetCollection = {};

        this.riskPlanDict = {};
        this.customListsDict = {};
        this.lastRiskPlanItem = null;
        RiskPlan.showProductType = false;
        this.deferredMessages = {};
        this.deferredInsReq = {};

        this.instrTypes = {};
        //
        // this.OnReintialize = new CustomEvent();

        this.InstrumentBalancerTable = {};
        // this.userBalanserTable = {};

        this.ContractsCache = {};
        this._cachedInsListByContractID = {};

        this.BrendingRules = null;
        this.SpreadPlans = {};
        this.SpreadPlan = null;

        this.AggregatedInstruments = {};
        // this.cachedInstrumentsWR = null;

        this.CommissionPlans = {};

        this.CrossratesPlans.Clear();

        this.CompanyName = null;
        this.BrokerInformation = null;
        this.PoweredByName = null;
        this.PoweredByURL = null;
        this.BrandingVisibleInstrumentLogo = false;
        this.AllowPreviewPassword = false;

        this.ExternalLinksCache.Clear();

        this.News = {};
        this.SubscribedToNewsRoute = null;
        this.userAndConnectionNameEncryptedByMD5 = null;

        this.tradingSystems = {};
        this.tradingIdeas = {};

        this._userGroups = {};
        this.Users = {};
        this.productTypeEnabilityCache = {};

        this._userHasSameCurrencyOnAllAccounts = null;
        this._userHasSameCommissionAndSwapPlanOnAllAccounts = null;

        this.closeAccountRequestID = null;
        this.closeAccountApproved = false;
    }

    /// +++++++
    public getInstrumentByName (name): Instrument | null {
        if (!name) {
            return null;
        }

        let filter = name.toUpperCase();

        const ans = this.Instruments[filter];
        if (ans) {
            return ans;
        }

        // For ContiniousContract searching
        const splited = filter.split('|');
        if (splited.length > 1) {
            filter = splited[0];

            const answear = this.Instruments[filter];
            if (answear) {
                return answear;
            }

            const splitedFilter = filter.split(InstrumentUtils.SEPARATOR);
            if (splitedFilter.length > 1) {
                const Id = splited[1];
                const Route = splitedFilter[1];
                return this.getInstrumentByInstrumentIdAndRoute(Id, Route);
            }
        }
        return null;
    }

    public getInstrumentByInstrumentIdAndRoute (Id: number, Route: number): Instrument | null {
        let tmp = this.InstrumentsContiniousContractIDS[Id];
        if (!tmp) {
            return null;
        }

        tmp = tmp[Route];
        if (!tmp) {
            return null;
        }

        return tmp.CurrentContiniousContract;
    }

    // Direct vendor scheme.
    public getInstrument (symbol, route?): Instrument | null {
        return this.getInstrumentByName(InstrumentUtils.GetFullName(symbol, route));
    }

    public getInstrumentByTradable_ID (Tradable_ID, route): Instrument | null {
        const routeGroup = this.InstrumentsTableByIDs[route];
        if (routeGroup) {
            return routeGroup[Tradable_ID] || null;
        } else {
            return null;
        }
    }

    public getInstrumentByTradable_ID_first (Tradable_ID): Instrument | null {
    // Сначала ищем торговый
        for (const insK in this.InstrumentsTableByIDs) {
            const route = this.getRouteById(insK);
            if (route.IsTradable) {
                const ins = this.InstrumentsTableByIDs[insK][Tradable_ID];
                if (ins) {
                    return ins;
                }
            }
        }
        // Сначала ищем потом инфо
        for (const insK in this.InstrumentsTableByIDs) {
            const route = this.getRouteById(insK);
            if (route.IsInfo) {
                const ins = this.InstrumentsTableByIDs[insK][Tradable_ID];
                if (ins) {
                    return ins;
                }
            }
        }
        return null;
    }

    public getCachedInstrumentDict (): any {
        return this.Instruments;
    }

    public getInstrumentsByTradableID (tradableID): any {
        if (!tradableID || !this.InstrumentsByTradableIdCache[tradableID]) {
            return [];
        }

        return this.InstrumentsByTradableIdCache[tradableID];
    }

    public getAnyInstrument (): Instrument {
        let interiorID = null;

        for (interiorID in this.Instruments) {
            break;
        }

        return this.Instruments[interiorID];
    }

    public getAccounts (): any {
        return this.Accounts;
    }

    /** Retrieves the accounts owned by the user. @returns {Record<string, Account>} An object containing the user's owned accounts, where the keys are account IDs and the values are corresponding Account objects. */
    public getOwnedAccounts (): Record<string, Account> { return this._ownedAccounts; }

    /** Updates the user's owned accounts based on the current state of all accounts. This method filters the accounts to include only those owned by the user. @private @returns {void} */
    private updateOwnedAccounts (): void {
        this._ownedAccounts = Object.fromEntries(Object.entries(this.Accounts).filter(([, account]) => {
            return account.IsOwnedByUser && this.UserLogin === account.userLogin;
        }));
    }

    /** Retrieves the total number of accounts, including those that available but not owned by the user (e.g., linked accounts). @param {boolean} [recalc=false] - Flag indicating whether to recalculate the count. @returns {number} The total number of accounts. */
    public getNumberOfAccounts (recalc = false): number {
        if (recalc || this._accountsCount == null) {
            this._accountsCount = Object.values(this.Accounts).length;
        }
        return this._accountsCount;
    }

    public GetAccountById (id: string): Account | null {
        if (!this.Accounts || !id) {
            return null;
        }

        if (this.Accounts[id]) {
            return this.Accounts[id];
        }

        // TODO remove mb
        const keys = Object.keys(this.Accounts);
        let i;
        const len = keys.length;

        for (i = 0; i < len; i++) {
            const key = keys[i];
            if (key.toUpperCase() === id.toUpperCase()) {
                return this.Accounts[key];
            }
        }
        return null;
    }

    public GetAccountByIdOrName (fullAccString): Account | null {
        if (!this.Accounts || !fullAccString) {
            return null;
        }

        const byId = this.GetAccountById(fullAccString);
        if (byId) {
            return byId;
        }

        const keys = Object.keys(this.Accounts);
        let i;
        const len = keys.length;

        const filter = fullAccString.toUpperCase();
        for (i = 0; i < len; i++) {
            const key = keys[i];
            const acc = this.Accounts[key];
            if (acc.FullAccString.toUpperCase() === filter) {
                return acc;
            }
        }

        return null;
    }

    public GetAccountByNumber (fullAccString): Account | null {
        if (!this.Accounts || !fullAccString) {
            return null;
        }

        let acc = this.Accounts[fullAccString];

        if (!acc) {
            acc = null;
        }

        return acc;
    }

    public GetAssetById (id): Asset | null {
        return this.assetCollection[id] || null;
    }

    public GetAssetByName (name): Asset | null {
        const list = Object.keys(this.assetCollection);

        for (let i = 0, len = list.length; i < len; i++) {
            const value = this.assetCollection[list[i]];
            if (name === value.Name) {
                return value;
            }
        }
        return null;
    }

    public getAssetDict (): any {
        return this.assetCollection;
    }

    // #region Direct

    public NewMessage (message): void {
        switch (message.Code) {
        case Message.CODE_ORDER_HISTORY:
            this.handleOrderHistoryMessage(message);
            break;
        case Message.CODE_OPENORDER:
            this.HandleOpenOrderMessageDirect(message);
            break;
        case Message.CODE_ACCOUNTSTATUS:
            this.HandleAccountStatusMessageDirect(message);
            break;
        case Message.CODE_PFIX_PROPERTY_MESSAGE:
            this.HandlePropertyMessageDirect(message);
            break;
        case Message.CODE_INSTRUMENT:
            this.HandleInstrumentMessageDirect(message);
            break;
        case Message.CODE_OPENPOSITION:
            this.HandlePositionMessageDirect(message);
            break;
        case Message.CODE_CLOSEPOSITION:
            this.HandleClosePositionMessageDirect(message);
            break;
        case Message.CODE_REPLACE_ORDER_MESSAGE:
            this.TryRemoveOrder(message.OrderId);
            break;
        case Message.CODE_CANCEL_ORDER_MESSAGE:
            this.HandleCancelOrderMessageDirect(message);
            break;
        case Message.CODE_SESSION_MESSAGE:
            this.HandleSessionMessageDirect(message);
            break;
        case Message.CODE_ASSET_TYPE_MESSAGE:
            this.HandleDirectAssetMessage(message);
            break;
        case Message.CODE_CROSSRATE_MESSAGE:
            this.HandleCrossRateMessageDirect(message);
            break;
        case Message.CODE_ROUTE:
            this.AddRoute(message);
            break;
        case Message.CODE_BUSINESS_REJECT_MESSAGE:
            this.HandleReportMessageDirect(message);
            break;
        case Message.CODE_REPORT_TYPE_MESSAGE:
            this.handleReportTypeMessage(message);
            break;
        case Message.CODE_QUOTE:
        case Message.CODE_QUOTE2:
        case Message.CODE_QUOTE3:
        case Message.CODE_INSTRUMENT_DAY_BAR:
        case Message.CODE_INSTRUMENT_PRICES:
            this.handleQuoteMessage(message);
            break;
        case Message.CODE_TRADE:
            this.AddNewTrade(message);
            break;
        case Message.CODE_YTM_RESPONSE:
            this.handleYTMMessage(message);
            break;
        case Message.CODE_PFIX_RISK_PLAN_MESSAGE:
            this.addRiskPlan(message);
            break;
        case Message.CODE_TRADE_SESSION_STATUS_MESSAGE:
            this.processTradeSessionStatusMessage(message);
            break;
        case Message.CODE_CROSSRATES_PLAN_MESSAGE:
            this.ProcessCrossratesPlanMessage(message);
            break;
        case Message.CODE_INSTRUMENT_TYPE:
            this.AddInstrumentType(message);
            break;
        case Message.CODE_SPREAD_PLAN_MESSAGE:
            this.AddSpreadPlan(message);
            break;
        case Message.CODE_PFIX_TRADE_INSTRUMENT:
            this.ProcessInstrumentUpdateMessage(message);
            break;
        case Message.CODE_PFIX_TRADE_SESSION_SWITCH:
            this.ProcessTradeSessionSwitchMessage(message);
            break;
        case Message.CODE_TEXT_MESSAGE:
            this.ProcessBrokerMessage(message);
            break;
        case Message.CODE_COMMISSION_PLAN_MESSAGE:
            this.ProcessCommissionPlanMessage(message);
            break;
        case Message.CODE_TRADING_SYSTEM_MESSAGE:
            this.ProcessTradingSystemMessage(message);
            break;
        case Message.CODE_TRADING_SYSTEM_REMOVE_MESSAGE:
            this.ProcessTradingSystemRemoveMessage(message);
            break;
        case Message.CODE_TRADING_SIGNAL_MESSAGE:
            this.ProcessTradingSignalMessage(message);
            break;
        case Message.CODE_TRADING_SIGNAL_REQ_SEEN_MESSAGE:
            this.ProcessTradingSignalSeenMessage(message);
            break;
        case Message.CODE_CANCEL_TRADING_SIGNAL_MESSAGE:
            this.ProcessTradingSignalCancelMessage(message);
            break;
        case Message.CODE_SUBSCRIPTION_STRATEGY_MESSAGE:
            this.ProcessSubscriptionStrategyMessage(message);
            break;
        case Message.CODE_PFIX_EXTERNAL_LINK_MESSAGE:
        case Message.CODE_PFIX_EXTERNAL_LINK_REMOVE_MESSAGE:
            this.ExternalLinksCache.NewMessage(message);
            break;
        case Message.CODE_PFIX_CUSTODIAL_PLAN_MESSAGE:
            this.ProcessCustodialPlanMessage(message);
            break;
        case Message.CODE_RISK_WARN_MESSAGE:
            this.ProcessRiskRuleWarningMessge(message);
            break;

        case Message.CODE_ALERT_MESSAGE:
        case Message.CODE_REPLACE_ALERT_MESSAGE:
        case Message.CODE_CONTROL_ALERT_COMMAND_MESSAGE:
            this.AlertManager.NewMessage(message);
        case Message.CODE_NEWS_SUBSCRIBE_RESPONSE:
            this.ProcessNewsSubscribeResponse(message);
            break;
        case Message.CODE_NEWS_MESSAGE:
            this.ProcessNewsMessage(message);
            break;

        case Message.CODE_USER_GROUP:
            this.handleUserGroupMSG(message);
            break;
        case Message.CODE_GET_SNAPSHOT_RESPONSE:
            this.ProcessSnapshotResponse(message);
            break;
        case Message.CODE_SUBSCRIBE_RESPONSE_MESSAGE:
            this.ProcesSubscribeResponseMessage(message);
            break;
        case Message.CODE_PFIX_PRODUCT_MESSAGE:
        case Message.CODE_PFIX_PRODUCT_DOCUMENT_RESP:
        case Message.CODE_PFIX_PRODUCT_SUBSCRIPTION_MANAGEMENT_RESP:
            this.EntitlementManager.NewMessage(message);
            break;
        case Message.CODE_SOURCE_LIST:
            this.ProcessSourceMappingMessage(message);
            break;
        case Message.CODE_PFIX_PORTFOLIO_STATISTICS_RESPONSE:
        case Message.CODE_PFIX_PORTFOLIO_MODEL_MESSAGE:
        case Message.CODE_PFIX_PORTFOLIO_SYSTEM_MESSAGE:
        case Message.CODE_PFIX_PORTFOLIO_INVESTOR_MESSAGE:
        case Message.CODE_PFIX_PORTFOLIO_REALTIME_STATISTICS:
            if (this.PortfolioCache) {
                this.PortfolioCache.NewMessage(message);
            }
            break;
        case Message.CODE_PFIX_ALGORITHM_SUBSCRIBE_MESSAGE:
        case Message.CODE_PFIX_ALGORITHMS_AVAILABLE_MESSAGE:
        case Message.CODE_PFIX_ALGORITHM_MARKET_CONSENSUS_MESSAGE:
        case Message.CODE_PFIX_ALGORITHM_GAINERS_LOSERS_MESSAGE:
        case Message.CODE_PFIX_ALGORITHM_HIGH_LOW_MESSAGE:
        case Message.CODE_PFIX_ALGORITHM_TOP_VOLUME_MESSAGE:
        case Message.CODE_PFIX_ALGORITHM_HISTORICAL_GAINER_LOSER_TOPS_MESSAGE:
        case Message.CODE_PFIX_ALGORITHM_HEATMAP_MESSAGE:
            if (this.AlgorithmCache) {
                this.AlgorithmCache.NewMessage(message);
            }
            break;
        case Message.BM_COUNTER_MESSAGE:
            this.ProcessBMCounterMessage(message);
            break;
        case Message.CODE_PRICE_LIMITS_MESSAGE:
            this.ProcessPriceLimitsMessage(message);
            break;

        case Message.CUSTOM_LIST_MESSAGE:
            this.processCustomLists(message);
            break;

        case Message.CODE_PFIX_FUNDING_RATE_MARKUP_PLAN_MESSAGE:
            this.ProcessFundingRateMarkupPlanMessage(message);
            break;

        case Message.CODE_PFIX_CLOSE_ACCOUNT_RESPONSE_MESSAGE:
            this.ProcessCloseAccountResponseMessage(message);
            break;

        case Message.CODE_PFIX_MAM_GROUP_MESSAGE:
            this.UpdateMamGroups(message);
            break;
        }
    }

    public UpdateMamGroups (message: DirectMamGroupMessage): void {
        MamGroupsCache.addGroup(message);
    }

    public processCustomLists (message): void {
        if (!message) {
            return;
        }

        const dict = this.customListsDict;
        dict[message.Id] = message;
    }

    public GetCustomListsByType (type): any {
        const arrCustomListsDict = [];
        const dict = this.customListsDict;
        for (const key in dict) {
            const customListMessage = dict[key];

            if (customListMessage.ListType === type) {
                arrCustomListsDict.push(customListMessage);
            }
        }

        return arrCustomListsDict;
    }

    public addRiskPlan (msg): void {
        const id = msg.Id;
        const riskPlanDict = this.riskPlanDict;
        let riskPlan = null;
        if (!riskPlanDict.hasOwnProperty(id)) {
            riskPlan = riskPlanDict[id] = new RiskPlan();
        } else {
            riskPlan = riskPlanDict[id];
        }

        if (riskPlan) {
            riskPlan.update(this, msg);
        } else {
            console.log('no riskPlan');
        }

        this.lastRiskPlanItem = riskPlan;

        for (let i = 0; i < msg.Data.length; i++) {
            this.productTypeEnabilityCache[msg.Data[i].Item.ProductType] = true;
        }
    }

    public AddInstrumentType (msg: DirectInstrumentGroupMessage): void {
        const type = new InstrumentGroup(msg, this);
        this.instrTypes[type.TypeId] = type;
    }

    public getInstrumentTypeById (typeId): InstrumentGroup | null {
        const type = this.instrTypes[typeId];
        return type || null;
    }

    public FindInstrumentTypes (typeId, list): void {
        list.push(typeId);
        try {
            for (const type in this.instrTypes) {
                const instrumentType: InstrumentGroup = this.instrTypes[type];
                if (instrumentType.SuperTypeId == typeId) {
                    this.FindInstrumentTypes(instrumentType.Id, list);
                }
            }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
        }
    }

    public processTradeSessionStatusMessage (msg): void {
        const tradeSessionStatusCache = this.tradeSessionStatusCache;
        let sessionStatus = tradeSessionStatusCache[msg.Id];
        if (sessionStatus) {
            sessionStatus.update(msg);

            this.OnTradingSessionStatusUpdated.Raise(sessionStatus);
        } else {
            sessionStatus = new TradeSessionStatus(msg);
            tradeSessionStatusCache[sessionStatus.Id] = sessionStatus;
        }
    }

    private handleYTMMessage (message: DirectYTMMessage): void {
        if (isNullOrUndefined(message.InstrumentID)) {
            return;
        }

        const instruments = this.GetInstrumentsByInstrumentId(message.InstrumentID.toString());
        if (!isValidArray(instruments)) {
            return;
        }

        instruments.forEach(ins => { ins.YieldRate = message.YieldRate; });
    }

    public handleQuoteMessage (msg): void {
        this.FQuoteCache.newQuoteMessage(msg);
    }

    // TODO. Rename.
    public HandleReportMessageDirect (msg) {
        if (Resources.isHidden('reports.' + msg.Name)) {
            return;
        }

        const event = EventCache.FromReportMessage(msg);
        if (EventCache.isDailyLimitExist(event)) { return; }

        this.OnReportMessage.Raise(msg);
        EventCache.Add(event);
    }

    public HandleDirectAssetMessage (assetMessage): void {
        let asset = null;
        if (this.assetCollection.hasOwnProperty(assetMessage.Id)) {
            asset = this.assetCollection[assetMessage.Id];
            asset.FillByMessage(assetMessage);
        } else {
            asset = new Asset(this, assetMessage);
            this.assetCollection[assetMessage.Id] = asset;
        }

        this.OnUpdateAssetEvent.Raise(this, asset);
    }

    public HandleCrossRateMessageDirect (msg): void {
        this.CrossRateCache.Update(msg);
    }

    public HandleSessionMessageDirect (msg): void {
        const sessContainer = this.GetSession(msg.Id) || this.AddSession(msg);
        if (!sessContainer) return;

        sessContainer.CurrentSessionId = msg.CurrentSessionId;
        sessContainer.UseIntradayMarginCoef = msg.UseIntradayMarginCoef;
        sessContainer.TimeZoneOffset = msg.TimeZoneOffset;
        sessContainer.BlockTrading = msg.BlockTrading;
        sessContainer.ServerBeginDayDate = msg.ServerBeginDayDate;
        sessContainer.TimeOffset = msg.TimeOffset;

        if (msg.Sessions) {
            sessContainer.Sessions = msg.Sessions;
        }

        if (this.Loaded) {
            const arr = this.Instruments;
            for (const key in arr) { // Apply session to instruments
                const item = arr[key];
                if (item.TradingSessionsId == sessContainer.Id) {
                    item.TradingSessionsList = sessContainer.Sessions;
                    item.CurrentTradingSession = sessContainer.GetSessionByID(sessContainer.CurrentSessionId);
                    item.BlockTradingBySession = sessContainer.BlockTrading;
                    this.OnUpdateInstrument.Raise(item);
                }
            }

            GeneralSettings.SettingsChangedRaise();
        }
    }

    public handleReportTypeMessage (msg): void {
        if (msg.accessType & AccessType.User) {
            this.createAllowedReport(
                AllowedReport.REPORT_KEY_PREFIX + msg.name, msg.id, msg.parameters);
        }

        if (msg.accessType & AccessType.Technical) {
            this.createAllowedReport(
                AllowedReport.TECHNICAL_REPORT_KEY_PREFIX + msg.name, msg.id, msg.parameters);
        }
    }

    // TODO.
    public HandlePropertyMessageDirect (propertyMessage): void {
        if (propertyMessage.Name === FINISH_BLOCK_TRANSFER) {
            if (this.NeedRedirectToPortfolio()) // doc. раздел 3) -> третий пункт с белым кружочко:  https://docs.google.com/document/d/1Tnb1LqXehcolTHU2ZEOywAuHLcYOXWA3Grsjddu0C-E/edit#heading=h.9o7dj749n3zi
            {
                return;
            }

            if (Connection.vendor) // эта проверка необходима для работы Unit-тестов
            {
                this.FLogin = Connection.vendor.login;
                this.UserLogin = Connection.vendor.login;
            }

            this.LoginUser = this.getUserByLogin(this.FLogin);
            // TODO  MainAccountNew
            if (this.LoginUser?.Accounts.length) {
                this.MainAccountNew = this.LoginUser.Accounts[0];
            } else {
                this.MainAccountNew = null;
            }

            this.Loaded = true;

            this.AlertManager.AlertsVisible = !Resources.isHidden('screen.Alerts.visibility') && this.isAllowedForMyUser(RulesSet.FUNCTION_ALERTS);
            this.AlertManager.ProcessDeferredMessages();

            this.updateOwnedAccounts();
            // кешируем инструменты в массив.

            if (!this.NonFixedList) {
                const keys = Object.keys(this.Instruments);
                this.InstrumentsArray = keys.map(function (value) {
                    return this.Instruments[value];
                }.bind(this));
            }

            this.ExternalLinksCache.OnLoaded();

            this.LinkPlansAndAccounts();

            this.OnReintialize.Raise();

            this.ReSubscribe();

            this.ProcessDeferredMessages(null);

            if (this.LoadedResolve) {
                this.LoadedResolve();
            }

            this.LoadedResolve = null;

            this.cacheUserHasSameCurrencyOnAllAccounts();
            this.userBaseCurrency = this.getUserBaseCurrency(); // TODO нужно вызывать после кадждого открытия/закрытия позиций для выполнения пункта 4 из правил получения валюты аккаунта https://ibb.co/HVtD0gw
        }

        if (propertyMessage.Name === RulesSet.FUNCTION_NON_FIXED_LIST) {
            this.NonFixedList = propertyMessage.Value === 'true';
        } else if (propertyMessage.Name === RulesSet.AVAILABLE_NUMBER_OF_SNAPSHOT_REQUESTS ||
        propertyMessage.Name === RulesSet.VALIDATE_MAX_LOT_CLOSE_ORDER) {
        // вероятно выглядет немного костыльно, но спасибо серверу решили выделить
        // AVAILABLE_NUMBER_OF_SNAPSHOT_REQUESTS от остальных
        // и это не ложится на нашу схему с рулами
        // потому ручками чуть правим Name и Value под общую схему
            this.RulesCache.AddRule(propertyMessage.OwnerType, propertyMessage.OwnerID,
                propertyMessage.Name, propertyMessage.Value);
        } else if (propertyMessage.OwnerID == DirectPropertyOwner.FeautreFlags) {
            this.RulesCache.AddFeatureFlag(propertyMessage.Name, propertyMessage.Value);
        } else if (propertyMessage.Name === 'RULE') {
        // раздельный ресурсоемкий рул
            const ownerID = propertyMessage.OwnerID;
            const ownerType = propertyMessage.OwnerType;
            let ruleKey = propertyMessage.Value;
            let ruleValue = '1';

            const parts = ruleKey.split('=');
            if (parts.length === 2) {
                ruleKey = parts[0];
                ruleValue = parts[1];
            }

            if (ruleKey == RulesSet.FUNCTION_BASE_CURRENCY) {
                this.baseCurrency = ruleValue;
            } else if (ruleKey === RulesSet.VALUE_RISK_ID && this.MainAccountNew) {
                if (this.MainAccountNew.AcctNumber === ownerID.toString()) {
                    this.lastRiskPlanItem = this.TryGetRiskPlan(ruleValue);
                    this.lastRiskPlanItem.applyToInstrument();
                }
            } else {
                this.RulesCache.AddRule(ownerType, ownerID, ruleKey, ruleValue);
            }

        // TODO.
        // needCallIsAllowedChanged = true
        } else if (propertyMessage.Name === RulesSet.FUNCTION_USE_ONE_TIME_PASSWORD) {
            TradingLockUtils.TradingLock.LockTradingByPassword = true;
            TradingLockUtils.TradingLock.updateTradingLock(true);
        }
    }

    public createAllowedReport (name, id, parameters): void {
        const report = new AllowedReport(name, id, parameters);
        this.reportDict[name] = report;
    }

    public UpdateAccountsCommissionPlan (): void {
        for (const acc in this.Accounts) {
            const account = this.Accounts[acc];
            account.CommissionPlan = this.TryGetCommissionPlan(account.CommisionId);
        }
    }

    public LinkPlansAndAccounts (): void {
        const mainAccount = this.LoginUser != null ? this.LoginUser.Accounts[0] : null;
        for (const acc in this.Accounts) {
            const account = this.Accounts[acc];
            account.SpreadPlan = this.TryGetSpreadPlan(account.SpreadPlanId);
            if (account.CommissionPlan === null) {
                account.CommissionPlan = this.TryGetCommissionPlan(account.CommisionId);
            }

            if (account.CrossratesPlan === null) {
                account.CrossratesPlan = this.TryGetCrossratesPlan(account.CrossratesPlanId);
            }

            if (account.RiskPlan === null) {
                account.RiskPlan = this.TryGetRiskPlan(account.RiskPlanId);
            }

            if (account.SwapPlan === null) {
                account.SwapPlan = this.TryGetSwapPlan(account.SwapPlanId);
            }

            if (account.ChargingPlan === null) {
                account.ChargingPlan = this.TryGetChargingPlan(account.ChargingPlanId);
            }
        }

        if (mainAccount?.RiskPlan) {
            this.ApplyRisksForInstrument(mainAccount.RiskPlan);
        }
    }

    public ApplyRisksForInstrument (rplan): void {
        try {
            const mass = this.getCachedInstruments();
            for (let i = 0; i < mass.length; i++) {
                const instrument: Instrument = mass[i];
                instrument.RiskSettings.RiskPlanSettings = rplan.GetRisksForInstrument(instrument.GetInteriorID());
            }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            console.log(ex);
        }
    }

    public async StartLoadingWaiting (): Promise<any> {
        return await new Promise(function (resolve, reject) {
            this.LoadedResolve = resolve;
        }.bind(this));
    }

    private HandleCancelOrderMessageDirect (msg: DirectOrderDialogMessage): void {
        const orderId = msg.OrderId;

        const order: Order = this.OrderDict[orderId];
        if (!order) return;

        order.Status = msg.OrderStatus;
        order.Comment = msg.Comment;
        if (msg.LastAmount != null) { // hsa: I am not sure about it
            order.Amount = msg.LastAmount;
        } else if (msg.Quantity != null) {
            order.Amount = msg.Quantity;
        }

        if (msg.DisClosedQuantity != null) {
            order.DisclosedQty = msg.DisClosedQuantity;
        }

        if (msg.SharesFilled != null) {
            order.SharesFilled = msg.SharesFilled;
        }

        if (msg.CancelTime != null) {
            order.UTCDateTime = msg.CancelTime;
        }
        // Call update order event?
        this.TryRemoveOrder(orderId);
    }

    public HandleOpenOrderMessageDirect (msg): void {
        this.TryRemoveFilledOrder(msg) ||
        this.TryAddOrderFromMessage(msg) ||
        this.TryUpdateOrderFromMessage(msg);
    }

    public TryRemoveOrder (orderNumber): any {
        if (!orderNumber) return false;

        const order = this.OrderDict[orderNumber];
        if (!order) return;

        this.TryRemoveClosingOrderFromPosition(order);

        order.Dispose();
        delete this.OrderDict[orderNumber];

        this.OnRemoveOrder.Raise(order);
    };

    // TODO. Rename.
    public TryRemoveFilledOrder (openOrderMessage): boolean {
        if (openOrderMessage.OrderStatus === OrderStatus.REMOVED) {
            const removedOrder = this.getOrderById(openOrderMessage.OrderNumber);
            let removedPosition = false;
            if (removedOrder) {
                this.TryRemoveOrder(removedOrder.OrderNumber);
            } else {
                this.RemovedTradeHistory(openOrderMessage.OrderNumber);
                this.OnRemovedTradeEvent.Raise();
                removedPosition = true;
            }

            return !!removedOrder || removedPosition;
        }

        if (
            openOrderMessage.Quantity <= 0 ||
        openOrderMessage.Quantity !== openOrderMessage.SharesFilled) {
            return false;
        }

        // ордер полностью заполнен - удаляем
        const filledOrder = this.getOrderById(openOrderMessage.OrderNumber);
        if (!filledOrder) {
            return true;
        }

        filledOrder.LastUpdateTime = new Date(openOrderMessage.LastUpdateTime);
        if (openOrderMessage.OrderStatus !== null) {
            filledOrder.Status = openOrderMessage.OrderStatus;
        }

        // TODO. Wrong method call?
        this.TryRemoveOrder(filledOrder.OrderNumber/*, true, filledOrder.LastUpdateTime */);
        return true;
    }

    public TryAddOrderFromMessage (msg): boolean {
        const orderKey = msg.OrderNumber;

        if (this.OrderDict[orderKey]) {
            return false;
        }

        const instrument = this.getInstrumentByTradable_ID(msg.InstrumentTradableID, msg.Route);
        const account = this.GetAccountById(msg.Account);
        if (!instrument)// || !account)
        {
            this.AddDeferredMessage(msg);
            return false;
        }

        if (!account) {
            return false;
        }

        const order = new Order(
            this,
            this.FQuoteCache,
            {
                OrderNumber: msg.OrderNumber,
                OrderType: msg.OrderType,
                Amount: msg.Quantity,
                Price: msg.Price,
                BuySell: msg.BuySell,
                TimeInForce: msg.TimeInForce,
                UTCDateTime: msg.UTCDateTime,
                Active: msg.Active,
                Route: msg.Route,
                Account: account,
                Instrument: instrument,
                ProductType: msg.ProductType,
                DisclosedQty: msg.DisClosedQuantity,
                Leverage: msg.Leverage,
                ServerCalculation: null
            });

        if (msg.StopLimit != null) {
            order.StopLimit = msg.StopLimit;
        }

        order.ExpireAt = msg.ExpireTime;
        order.SharesFilled = msg.SharesFilled;
        order.EverageFilledPrice = msg.EverageFilledPrice;
        order.OrderGroupId = msg.OrderGroupId;
        order.PositionId = msg.PositionId;
        order.Exchange = msg.Exchange;
        order.TrStopOffset = msg.TrStopOffset;

        order.BoundTo = msg.boundToOrderId;

        if (msg.OrderStatus !== null) {
            order.Status = msg.OrderStatus;
        }

        order.RealActive = msg.RealActive;

        order.TakeProfitPriceValue = msg.TakeProfitPriceValue;
        order.StopLossPriceValue = msg.StopLossPriceValue;
        order.StopLossLimitPriceValue = isNaN(order.StopLossPriceValue) ? null : msg.StopLossLimit;

        order.TakeProfitPriceType = msg.TakeProfitPriceType;
        order.StopLossPriceType = msg.StopLossPriceType;

        order.TriggerSL = msg.TriggerSL;
        order.TriggerTP = msg.TriggerTP;
        order.TriggerSLTP = msg.TriggerSLTP;

        order.Replaceable = msg.Replaceable;

        order.ExternalOrderId = msg.ExternalOrderId;
        order.ExternalStatus = msg.ExternalStatus;

        this.OrderDict[orderKey] = order;

        order.IsActivated = msg.IsActivated;

        order.Comment = msg.Comment;

        if (msg.ExecutionType !== null) {
            order.ExecutionType = msg.ExecutionType;
        }

        if (order.Active) {
            this.OnAddOrder.Raise(order);
        } else {
            this.TryAssignClosingOrderToPosition(order);
        }

        return true;
    }

    public getFirstTradableInstrument (): Instrument {
        const instruments = this.Instruments;

        for (const ins_id in instruments) {
            const ins: Instrument = instruments[ins_id];
            if (ins.RouteIsTradable()) {
                return ins;
            }
        }

        return null;
    }

    public TryUpdateOrderFromMessage (msg): boolean {
        const order: Order = this.OrderDict[msg.OrderNumber];
        if (!order) return false;

        if (msg.Quantity != null) {
            order.Amount = msg.Quantity;
        }

        if (msg.DisClosedQuantity != null) {
            order.DisclosedQty = msg.DisClosedQuantity;
        }

        if (msg.TimeInForce != null) {
            order.TimeInForce = msg.TimeInForce;
        }

        if (msg.Price != null) {
            order.Price = msg.Price;
        }

        if (msg.OrderType != null) {
            order.OrderType = msg.OrderType;
        }

        if (msg.UTCDateTime != null) {
            order.UTCDateTime = msg.UTCDateTime;
        }

        if (msg.LastUpdateTime != null) {
            order.LastUpdateTime = msg.LastUpdateTime;
        }

        if (msg.SharesFilled != null) {
            order.SharesFilled = msg.SharesFilled;
        }

        if (msg.EverageFilledPrice != null) {
            order.EverageFilledPrice = msg.EverageFilledPrice;
        }

        if (msg.ExpireTime != null) {
            order.ExpireAt = msg.ExpireTime;
        }

        if (msg.TrStopOffset != null) {
            order.TrStopOffset = msg.TrStopOffset;
        }

        if (msg.StopLimit != null) {
            order.StopLimit = msg.StopLimit;
        }

        if (msg.TakeProfitPriceValue != null) {
            order.TakeProfitPriceValue = msg.TakeProfitPriceValue;
        }

        if (msg.StopLossPriceValue != null) {
            order.StopLossPriceValue = msg.StopLossPriceValue;
        }
        order.StopLossLimitPriceValue = isNaN(order.StopLossPriceValue) ? null : msg.StopLossLimit;

        if (msg.TakeProfitPriceType != null) {
            order.TakeProfitPriceType = msg.TakeProfitPriceType;
        }

        if (msg.StopLossPriceType != null) {
            order.StopLossPriceType = msg.StopLossPriceType;
        }

        // if (msg.TriggerSL != null)   // можна скинути існуючі тригери видаляючи SL/TP у ордера
        order.TriggerSL = msg.TriggerSL;
        // if (msg.TriggerTP != null)
        order.TriggerTP = msg.TriggerTP;
        // if (msg.TriggerSLTP != null)
        order.TriggerSLTP = msg.TriggerSLTP;

        if (msg.ExternalOrderId != null) {
            order.ExternalOrderId = msg.ExternalOrderId;
        }

        if (msg.ExternalStatus != null) {
            order.ExternalStatus = msg.ExternalStatus;
        }

        if (msg.PositionId != null) {
            order.PositionId = msg.PositionId;
        }
        if (msg.boundToOrderId != null) {
            order.BoundTo = msg.boundToOrderId;
        }

        if (msg.OrderStatus !== null) {
            order.Status = msg.OrderStatus;
        }

        if (msg.Leverage) {
            order.Leverage = msg.Leverage;
        }

        order.IsActivated = msg.IsActivated;

        if (msg.ExecutionType !== null) {
            order.ExecutionType = msg.ExecutionType;
        }

        if (order.IsActivated) {
            this.OnActivateOrder.Raise(order);
        }

        if (order.Active) {
            this.OnUpdateOrder.Raise(order);
        } else {
            this.TryAssignClosingOrderToPosition(order);
        }

        return true;
    }

    public HandleAccountStatusMessageDirect (accountStatusMessage): void {
        const accKey = accountStatusMessage.BstrAccount;
        const accFromStorage = this.Accounts[accKey];
        if (accountStatusMessage.Status === 2) {
            if (accFromStorage) {
                delete this.Accounts[accKey];
                this.updateOwnedAccounts();
                this.OnRemoveAccount.Raise(accFromStorage);
            }
        } else {
            if (accFromStorage) {
                accFromStorage.FillAccount(accountStatusMessage);
                this.OnUpdateAccount.Raise(accFromStorage);
            } else {
                const newAcc = new Account(this, accountStatusMessage);
                this.Accounts[accKey] = newAcc;
                this.updateOwnedAccounts();
                this.OnAddAccount.Raise(newAcc);
            }
        }

        this.getNumberOfAccounts(true); // to update this._accountsCount
    }

    public handleUserGroupMSG (mess): void {
        this._userGroups[mess.GroupId] = mess;
    }

    public GetUserGroupById (id): any {
        return this._userGroups[id] || null;
    }

    public CreateOrUpdateUser (mess, acc: Account): void {
        let user = acc.User;
        if (user === null) {
            user = this.getUserByUserId(mess.UserID);
            // Не было юзера
            if (user === null) {
            // fixed by nicky
            // старый сервер, такой как интеграл.нет не присылает UserLogin и UserID
            // валится огромное число обработанных и нет нуллреференсов
                const login = mess.UserLogin;
                const userPin = mess.UserID !== null ? mess.UserID : mess.Pin;
                user = new User(userPin, mess.Role, mess.Status, login, mess.ClientType, mess.Email, mess.GroupId);
                this.Users[mess.UserID] = user;
            }
            acc.User = user;
        } else {
        // user.LockedByLogin = lockedBy;
            if (mess.Status !== null) {
                user.Status = mess.Status;
            }

            if (mess.ClientType !== null) {
                user.ClientType = mess.ClientType;
            }

            if (mess.GroupId !== null) {
                user.GroupId = mess.GroupId;
            }
        }
    }

    public getUserByUserId (id): User {
        return this.Users[id] || null;
    }

    // TODO. Refactor.
    public HandleInstrumentMessageDirect (mess): void {
        if (!mess?.Name || !mess.RoutesSupported) {
            console.log('Error creating instrument from empty instrument message.');
            return;
        }

        this.AddExchangeToCache(mess.TradingExchange);

        const hasExp = mess.InstrDateSettingsList?.length;
        if (!hasExp &&
        (mess.InstrType === InstrumentTypes.OPTIONS ||
            mess.InstrType === InstrumentTypes.FUTURES ||
            mess.InstrType === InstrumentTypes.CFD_FUTURES)) {
            return;
        }

        this.cacheInstrument(mess);

        // создаем список всех роутов
        const infroutesList = mess.RoutesSupported;// Instrument.getSupportedRoutes(mess.RoutesSupported);
        // получаем список торговых роутов
        const tradableRoutes = mess.TradableRoutes;// Instrument.getSupportedRoutes(mess.TradableRoutes);

        // сначала торговые
        for (let i = 0; i < tradableRoutes.length; i++) {
            const route = tradableRoutes[i];
            const instruments = this.GenerateInstruments(mess, route);
            // TODO
            if (instruments) {
                for (let j = 0; j < instruments.length; j++) {
                    this.AddOrUpdateInstrumentFromMessage(instruments[j], mess);
                }
            }
        }

        // потом только информационные
        for (let i = 0; i < infroutesList.length; i++) {
            const route = infroutesList[i];
            if (tradableRoutes.indexOf(route) !== -1) {
                continue;
            }

            const instruments = this.GenerateInstruments(mess, route);
            // TODO
            if (instruments) {
                for (let j = 0; j < instruments.length; j++) {
                    this.AddOrUpdateInstrumentFromMessage(instruments[j], mess);
                }
            }
        }
    }

    // TODO.
    public HandleClosePositionMessageDirect (message): void {
        const id = message.Id;
        this.RemovePosition(id);
    }

    public RemovePosition (id): void {
        const closedPosition: Position | null = this.PositionDict[id] || this.PositionDictCorporateAction[id];
        if (!closedPosition) return;

        // TODO Performance think !!!
        const cpabc = closedPosition.Account.BaseCurrency;

        // TODO. Necessary?
        // TODO REALY NECESSARY!!!
        const slOrder = closedPosition.SLOrder;
        if (slOrder) this.TryRemoveOrder(slOrder.OrderNumber);

        const tpOrder = closedPosition.TPOrder;
        if (tpOrder) this.TryRemoveOrder(tpOrder.OrderNumber);

        closedPosition.Dispose();

        if (closedPosition.ProductType === ProductType.CorporateAction) {
            delete this.PositionDictCorporateAction[id];
            this.OnRemoveCorporateAction.Raise(closedPosition);
        } else {
            delete this.PositionDict[id];
            this.OnRemovePosition.Raise(closedPosition);
        }

        if (cpabc !== this.userBaseCurrency) {
            this.userBaseCurrency = this.getUserBaseCurrency();
        }
    }

    // TODO.
    public HandlePositionMessageDirect (message): void {
        this.TryAddPositionFromMessage(message) ||
        this.TryUpdatePositionFromMessage(message);
    }

    // TODO.
    // window.testCounter = 0;
    public TryAddPositionFromMessage (message): boolean {
        const positionDict = this.PositionDict;
        const positionDictCorporateAction = this.PositionDictCorporateAction;

        let position: Position | null = positionDict[message.PositionId] || positionDictCorporateAction[message.PositionId];

        if (message.Amount === 0 && position && this.Loaded && message.ProductTypeModifiedAmount !== null) {
            this.FOrderExecutor.createEventMessageProductTypeModified(position);
        }//, message.ProductTypeModifiedAmount)

        if (position) return false;

        // https://tp.traderevolution.com/entity/80132
        if (message.Amount === 0) {
            return false;
        }

        const instrument = this.getInstrumentByTradable_ID(message.InstrumentTradableID, message.Route);
        const account = this.GetAccountById(message.Account);
        if (!instrument)// || !account)
        {
            this.AddDeferredMessage(message);
            return false;
        }

        if (!account) {
            return false;
        }

        position = new Position(
            this,
            this.FQuoteCache,
            {
                PositionId: message.PositionId,
                SuperPositionId: message.SuperPositionId, // (FIFO) #80010
                Account: account,
                Instrument: instrument,
                Amount: message.Amount,
                OpenPrice: message.OpenPrice,
                OperationType: message.OperationType,
                OpenTime: message.OpenTime,
                OpenOrderId: message.OpenOrderId,
                Route: message.Route,
                RealizedPL: message.RealizedPL,
                ProductType: message.ProductType,
                Leverage: message.Leverage,
                AccruedInterest: message.AccruedInterest,
                ServerCalculation: new ServerCalculation(
                    message.ServerCalculationCurPriceClose,
                    message.ServerCalculationExposure,
                    message.ServerCalculationPositionValue,
                    message.ServerCalculationNetPL,
                    message.ServerCalculationGrossPL)
            });

        position.OpenCrossPrice = message.OpenCrossPrice;
        position.Commission = message.Commission;
        position.Swaps = message.Swaps;
        position.StartOfDayAmount = message.StartOfDayAmount;

        position.OptionExerciseStatus = message.OptionExerciseStatus;
        position.CorporateActionType = message.CorporateActionType;

        position.Comment = message.Comment;

        if (position.ProductType === ProductType.CorporateAction) {
            positionDictCorporateAction[message.PositionId] = position;
        } else {
            positionDict[message.PositionId] = position;
        }

        if (Object.keys(positionDict).length === 1) {
            this.userBaseCurrency = this.getUserBaseCurrency();
        }

        const pabc = position.Account.BaseCurrency;
        if (pabc !== this.userBaseCurrency) {
            this.userBaseCurrency = this.baseCurrency;
        }

        position.RecalcAmounts();

        if (position.ProductType === ProductType.CorporateAction) {
            this.OnAddCorporateAction.Raise(position);
        } else {
            this.OnAddPosition.Raise(position);
        }

        if (this.Loaded && message.ProductTypeModifiedAmount !== null) {
            this.FOrderExecutor.createEventMessageProductTypeModified(position, message.ProductTypeModifiedAmount);
        }

        return true;
    }

    public TryUpdatePositionFromMessage (message): boolean {
        const positionDict = this.PositionDict;
        const positionDictCorporateAction = this.PositionDictCorporateAction;

        const position: Position | null = positionDict[message.PositionId] || positionDictCorporateAction[message.PositionId];
        if (!position) return false;

        if (message.Amount === 0) {
            this.RemovePosition(message.PositionId);
            return true;
        }
        position.UpdateByMessage(message);

        position.RecalcAmounts();

        if (position.ProductType === ProductType.CorporateAction) {
            this.OnUpdateCorporateAction.Raise(position);
        } else {
            this.OnUpdatePosition.Raise(position);
        }

        if (this.Loaded && message.ProductTypeModifiedAmount !== null) {
            this.FOrderExecutor.createEventMessageProductTypeModified(position, message.ProductTypeModifiedAmount);
        }

        return true;
    }

    /* TODO */
    public cacheInstrument (message): any {

    }

    public AddOrUpdateInstrumentFromMessage (ins: Instrument, mess): void {
        this.tryUpdateExistingInstrumentTypes(ins);
        void this.CountryCache.fetchFlagIfNotAlready(ins.CountryId);
        const instruments = this.Instruments;

        const id = ins.InstrumentTradableID;
        if (id) {
            let idStr = id.toString();
            const Route_U = ins.Route;
            const i_contains = !!this.InstrumentsTableByIDs[Route_U];

            const isContiniousContract = ins.InstrumentSpecificType === InstrumentSpecificType.ContiniousContract;
            if (isContiniousContract) {
                idStr += '_C';
            }

            if (!i_contains) {
                this.InstrumentsTableByIDs[Route_U] = {};
            }

            const exist = this.InstrumentsTableByIDs[Route_U][idStr];
            if (!exist) {
                this.InstrumentsTableByIDs[Route_U][idStr] = ins;
            }

            if (ins.isFuturesSymbol && isContiniousContract) {
                if (!this.InstrumentsContiniousContractIDS[ins.Id]) {
                    this.InstrumentsContiniousContractIDS[ins.Id] = {};
                }

                if (!this.InstrumentsContiniousContractIDS[ins.Id][Route_U]) {
                    this.InstrumentsContiniousContractIDS[ins.Id][Route_U] = {};
                }

                if (!this.InstrumentsContiniousContractIDS[ins.Id][Route_U].CurrentContiniousContract) {
                    this.InstrumentsContiniousContractIDS[ins.Id][Route_U].CurrentContiniousContract = ins;
                }
            }
        }

        const InsInteriorId = ins.GetInteriorID();
        const ContiniousContract = ins.InstrumentSpecificType === InstrumentSpecificType.ContiniousContract;
        const itidccc = this.InstrumentTradableIDChacheContiniousContract;
        const itidc = this.InstrumentTradableIDChache;
        const hasNewCach = ContiniousContract ? itidccc.hasOwnProperty(InsInteriorId) : itidc.hasOwnProperty(InsInteriorId);

        if (instruments.hasOwnProperty(InsInteriorId) && hasNewCach) {
            const updateMessage = mess?.InstrDateSettingsList?.length ? ins.CreateInstrumentMessage() : mess; // возникло в связи с комментом в #88334 youbanoe gouno над которым надо хорошенько подумать!!!
            ins = instruments[InsInteriorId];
            if (updateMessage) {
                ins.UpdateByMessage(updateMessage);
                ins.UpdateHideRouteMode();

                if (this.Loaded) {
                    this.CorrectlyAddNonFixInstrument(ins);
                }
            }
            this.OnUpdateInstrument.Raise(ins);
        } else {
            if (ins.InstrumentSpecificType === InstrumentSpecificType.ContiniousContract) {
                this.InstrumentTradableIDChacheContiniousContract[InsInteriorId] = ins;
            } else {
                this.InstrumentTradableIDChache[InsInteriorId] = ins;
            }

            instruments[InsInteriorId] = ins;

            const t_id = ins.TradingSessionsId;
            if (!this.InstrumentsByTradingSessionsId[t_id]) {
                this.InstrumentsByTradingSessionsId[t_id] = [];
            }

            this.InstrumentsByTradingSessionsId[t_id].push(ins);

            if (this.lastRiskPlanItem) // #89151
            {
                this.lastRiskPlanItem.processOneInstrument(ins);
            }
            this.AddAggregatedInstrument(ins);
            ins.UpdateHideRouteMode();

            let instrumentsByIdArray = this.InstrumentsByIdCache.get(ins.Id);
            if (isNullOrUndefined(instrumentsByIdArray)) {
                instrumentsByIdArray = [];
                this.InstrumentsByIdCache.set(ins.Id, instrumentsByIdArray);
            }
            instrumentsByIdArray.push(ins);

            if (!this.InstrumentsByTradableIdCache[id]) {
                this.InstrumentsByTradableIdCache[id] = [];
            }

            this.InstrumentsByTradableIdCache[id].push(ins);

            if (this.Loaded) {
                this.CorrectlyAddNonFixInstrument(ins);

                this.ProcessDeferredMessages(ins);
            }

            this.OnAddInstrument.Raise(ins);
        }

        const dsTrID = ins.GetDataSourceTradableId();
        if (dsTrID) {
            const dsIns = this.GetDataSourceInstrument(ins);
            if (!dsIns) {
                return;
            }

            const dsInteriorID = dsIns.GetInteriorID();

            if (!this.InstrumentsBySourceRouteIdTradableId[dsInteriorID]) {
                this.InstrumentsBySourceRouteIdTradableId[dsInteriorID] = [];
            }

            if (this.InstrumentsBySourceRouteIdTradableId[dsInteriorID].indexOf(ins) == -1) {
                this.InstrumentsBySourceRouteIdTradableId[dsInteriorID].push(ins);
            }
        }
    }

    public CorrectlyAddNonFixInstrument (instrument: Instrument): void {
        if (this.SpreadPlan) {
            this.SpreadPlan.ApplyForInstrument(instrument);
        }
    }

    public tryUpdateExistingInstrumentTypes (instrument: Instrument | null): void {
        if (!instrument) {
            return;
        }

        const insType = instrument.InstrType;
        if (insType === InstrumentTypes.INDICIES) {
            return;
        }

        this.ExistingInstrumentTypesSet[instrument.CFD ? InstrumentTypes.EQUITIES_CFD : insType] = true;
    }

    public FindParentForOrder (order: Order | null): Position | null {
        if (!order) return null;

        const pos: Position = this.PositionDict[order.PositionId];
        return pos || null;
    }

    public TryAssignClosingOrderToPosition (order: Order | null): boolean {
        if (!order) return false;

        const pos = this.FindParentForOrder(order);
        if (!pos) return false;

        const orderType = order.OrderType;
        const OrderTypes = OrderType;

        const isSL = orderType === OrderTypes.Stop || orderType === OrderTypes.StopLimit || orderType === OrderTypes.TrailingStop;
        if (isSL) {
            pos.SLOrder = order;
            this.OnAddSLOrderToPosition.Raise(pos);
            return true;
        }

        const isTP = orderType === OrderTypes.Limit;
        if (isTP) {
            pos.TPOrder = order;
            this.OnAddTPOrderToPosition.Raise(pos);
            return true;
        }

        const isMarket = orderType === OrderTypes.Market; // #94085
        if (isMarket) {
            this.OnAddOrder.Raise(order); // #94085

            return true;
        }

        return false;
    }

    public TryRemoveClosingOrderFromPosition (order: Order | null): boolean {
        if (!order) return false;

        const pos = this.FindParentForOrder(order);
        if (!pos) return false;

        const orderNumber = order.OrderNumber;

        const slOrder = pos.SLOrder;
        if (slOrder && slOrder.OrderNumber === orderNumber) {
            this.OnRemoveSLOrderFromPosition.Raise(pos);
            pos.SLOrder = null;
            return true;
        }

        const tpOrder = pos.TPOrder;
        if (tpOrder && tpOrder.OrderNumber === orderNumber) {
            this.OnRemoveTPOrderFromPosition.Raise(pos);
            pos.TPOrder = null;
            return true;
        }

        return false;
    }

    public AddRoute (message): void {
        if (!message.RouteId) {
            return;
        }

        let isUpdateRouteMessage = false;
        isUpdateRouteMessage = this.routes.hasOwnProperty(message.RouteId);

        if (!isUpdateRouteMessage && message.IsInfo && !message.IsTradable) {
            isUpdateRouteMessage = this.infoRoutes.hasOwnProperty(message.RouteId);

            if (isUpdateRouteMessage) {
                this.infoRoutes[message.RouteId].UpdateByMessage(message);
            } else {
                var route = new Route(message);
                this.infoRoutes[message.RouteId] = route;
                this.UpdateInstrumentForInfoRoute(route);
            }

            return;
        }

        if (isUpdateRouteMessage) {
            this.routes[message.RouteId].UpdateByMessage(message);
        } else {
        // Если пришел хотябы один роут - удаляем дефолтовый
        // TODO хз, что за и нужно ли нам?
        // if (this.routes.hasOwnProperty("DEFAULT"))
        //    delete this.routes["DEFAULT"];
            var route = new Route(message);
            this.routes[message.RouteId] = route;

            /// /////updateNames
            if (this.InstrumentsTableByIDs[message.RouteId]) {
                const ins = this.InstrumentsTableByIDs[message.RouteId];
                const instrs = Object.keys(ins);
                for (let i = 0; i < instrs.length; i++) {
                    const c_i = ins[instrs[i]];
                    c_i.RouteName = message.Name;
                }
            }
        }
    }

    public UpdateInstrumentForInfoRoute (route): void {
        const routeName = route.RouteId;

        const ins: Instrument | null = this.InstrumentsTableByIDs[routeName];
        if (!ins) {
            return;
        }

        const instrs = Object.keys(ins);

        for (let i = 0; i < instrs.length; i++) {
            const instr = ins[instrs[i]];
            const newInstr = this.getInstrument(instr.ShortName, routeName);
            if (newInstr === null) {
                continue;
            }

            // if (newInstr.InvisibleByUpdateHideRouteMode) //роуты сливаются - мы должны засетить основной инструмент
            instr.InstrumentDayBarMessageUpdateMode = route.InstrumentDayBarMessageUpdateMode;
            // else //инструмент видим отдельно
            newInstr.InstrumentDayBarMessageUpdateMode = route.InstrumentDayBarMessageUpdateMode;
        }
    }

    public isAllowedForAccount (name, account?: Account | null): boolean {
        return this.RulesCache.IsAllowedForAccount(name, account);
    }

    public isAllowedForMainAccount (name): boolean {
        return this.RulesCache.IsAllowedForAccount(name, null); // main account
    }

    public isAllowedForMyUser (ruleKey): boolean {
        return this.RulesCache.IsAllowedForMyUser(ruleKey);
    }

    public isAllowedForUser (name, u: User): boolean {
        return this.RulesCache.IsAllowedForUser(name, u);
    }

    public isAllowedFeatureFlag (ruleName): boolean {
        return this.RulesCache.IsAllowedFeatureFlag(ruleName);
    }

    public getRuleStringValueForAccount (ruleName, account?: Account, defaultValue?): string {
        const ruleValue = this.RulesCache.GetRuleValue(ruleName, account);
        if (!ruleValue) {
            return defaultValue;
        }
        return ruleValue;
    }

    public getRuleNumberValueForAccount (ruleName, account: Account, defaultValue): number {
        return +this.getRuleStringValueForAccount(ruleName, account, defaultValue);
    }

    public getRuleStringValueForMyUser (ruleName, defaultValue): string {
        const ruleValue = this.RulesCache.GetRuleValueForMyUser(ruleName);
        if (!ruleValue) {
            return defaultValue;
        }

        return ruleValue;
    }

    public getRuleNumberValueForMyUser (ruleName, defaultValue?): number {
        return +this.getRuleStringValueForMyUser(ruleName, defaultValue);
    }

    /**
* Retrieves the boolean value for the rule named VALIDATE_MAX_LOT_CLOSE_ORDER from the DataCache.
* This rule is stored in RulesCache.#GlobalRules as either 'true' or 'false'.
* returns {boolean} - The boolean value for the specified rule.
*/
    public getValidateMaxLotCloseOrderBoolRule (): boolean {
        return this.getRuleStringValueForAccount(RulesSet.VALIDATE_MAX_LOT_CLOSE_ORDER) === 'true';
    }

    public getAllOrders (): any {
        return this.OrderDict;
    }

    public getOrderById (id): Order | null {
        return this.OrderDict[id];
    }

    // TODO. Refactor. Come up with 1 filter method.
    public getOrdersById (idArray): any {
        const orderDict = {};
        for (let i = 0, len = idArray.length; i < len; i++) {
            const id = idArray[i];
            const ord = this.getOrderById(id);
            if (ord) orderDict[id] = ord;
        }
        return orderDict;
    }

    // TODO. Refactor. Come up with 1 filter method.
    public getOrdersByAccount (account: Account | null): any {
        if (!account) return null;

        const orderDict = this.OrderDict;
        const resDict: any = {};

        const accId = account.BstrAccount;

        for (const key in orderDict) {
            const order = orderDict[key];
            // TODO.
            if (order.Account.BstrAccount === accId) {
                resDict[key] = order;
            }
        }

        return resDict;
    }

    // TODO. Refactor. Come up with 1 filter method.
    public getOrdersByInstrument (instrument: Instrument | null): any {
        if (!instrument) return null;

        const orderDict = this.OrderDict;
        const resDict = {};

        const insId = instrument.GetInteriorID();

        for (const key in orderDict) {
            const order = orderDict[key];
            if (order.Instrument.GetInteriorID() === insId) {
                resDict[key] = order;
            }
        }

        return resDict;
    }

    // TODO. Refactor. Come up with 1 filter method.
    public getOrdersBySide (side): any {
        const orderDict = this.OrderDict;
        const resDict = {};

        for (const key in orderDict) {
            const order = orderDict[key];
            if (order.BuySell === side) {
                resDict[key] = order;
            }
        }

        return resDict;
    }

    // TODO. Refactor. Come up with 1 filter method.
    public getPositionsBySide (side): any {
        const posDict = this.PositionDict;
        const resDict = {};

        for (const key in posDict) {
            const pos = posDict[key];
            if (pos.BuySell === side) {
                resDict[key] = pos;
            }
        }

        return resDict;
    }

    public getPositionsByNetPL (isPositive: boolean): any {
        const posDict = this.PositionDict;
        const posResDict = {};
        const negResDict = {};
        for (const key in posDict) {
            const pos = posDict[key];
            pos.PnLCalculator.NetPL > 0 ? posResDict[key] = pos : negResDict[key] = pos;
        }
        return isPositive ? posResDict : negResDict;
    }

    // TODO. Refactor. Come up with 1 filter method.
    public getOrdersByTIF (tif: OrderTif): any {
        const orderDict = this.OrderDict;
        const resDict = {};

        for (const key in orderDict) {
            const order = orderDict[key];
            if (order.TimeInForce === tif) {
                resDict[key] = order;
            }
        }

        return resDict;
    }

    // TODO. Refactor. Come up with 1 filter method.
    public getOrdersByType (orderType: OrderType): any {
        const orderDict = this.OrderDict;
        const resDict = {};

        for (const key in orderDict) {
            const order: Order = orderDict[key];
            if (order.OrderType === orderType) {
                resDict[key] = order;
            }
        }

        return resDict;
    }

    // TODO. Optimize.
    public getOrdersByInstrumentAndAccount (instrument: Instrument | null, account: Account | null): any {
        if (!instrument || !account) {
            return null;
        }

        const resOrderDict = {};
        const orderDict = this.OrderDict;
        for (const key in orderDict) {
            const order = orderDict[key];
            if (Instrument.IsEqualInstrument(order.Instrument, instrument) && order.Account === account) {
                resOrderDict[key] = order;
            }
        }
        return resOrderDict;
    }

    public getPositionById (id): Position {
        return this.PositionDict[id];
    }

    public getPositionsCorporateActionById (id): any {
        return this.PositionDictCorporateAction[id];
    }

    public getAllPositions (): any {
        return this.PositionDict;
    }

    public getAllPositionsCorporateAction (): any {
        return this.PositionDictCorporateAction;
    }

    public getPositionsById (idArray): any {
        const posDict = {};
        for (let i = 0, len = idArray.length; i < len; i++) {
            const id = idArray[i];
            const pos = this.getPositionById(id);
            if (pos) posDict[id] = pos;
        }
        return posDict;
    }

    public getPositionsByAccount (account: Account | null, isCorporateAction?): any {
        if (!account) return null;

        const posDict = !isCorporateAction ? this.PositionDict : this.PositionDictCorporateAction;
        const resDict = {};

        const accId = account.BstrAccount;

        for (const key in posDict) {
            const pos: Position = posDict[key];
            // TODO.
            if (pos.Account.BstrAccount === accId) {
                resDict[key] = pos;
            }
        }

        return resDict;
    }

    public getPositionsByInstrument (instrument: Instrument | null, isCorporateAction): any {
        if (!instrument) return null;

        const posDict = !isCorporateAction ? this.PositionDict : this.PositionDictCorporateAction;
        const resDict = {};

        const insId = instrument.GetInteriorID();

        for (const key in posDict) {
            const pos: Position = posDict[key];
            if (pos.Instrument.GetInteriorID() === insId) {
                resDict[key] = pos;
            }
        }

        return resDict;
    }

    // TODO. Optimize.
    public getPositionsByInstrumentAndAccount (instrument: Instrument | null, account: Account | null): any {
        if (!instrument || !account) {
            return null;
        }

        const resPosDict = {};
        const positionDict = this.PositionDict;
        for (const key in positionDict) {
            const pos: Position = positionDict[key];
            if (pos.Instrument === instrument && pos.Account === account) {
                resPosDict[key] = pos;
            }
        }
        return resPosDict;
    }

    public ActiveOrdersList (addSLTP) {
        return [];
    }

    public AddNewTrade (tradeMSg): void {
        let trade = this.newTradesDict[tradeMSg.TradeId];

        if (tradeMSg.Route == '' || !tradeMSg.InstrumentTradableID) {
            return;
        }

        const ins = this.getInstrumentByTradable_ID(tradeMSg.InstrumentTradableID, tradeMSg.Route);
        if (!ins) {
            this.AddDeferredMessage(tradeMSg);
            return;
        }

        // Обновляем информацию по объемам по паре аккаунт/инструмент для ассетов
        this.ProcessVolumeQuote(tradeMSg);

        if (!trade) {
            trade = new NewTrade(this, tradeMSg);
            this.newTradesDict[trade.TradeId] = trade;
            this.realTimeFilledOrdersArray.push(trade);
            this.filledOrdersArray.push(trade);
            // TODO костыль ебанный
            if (Object.keys(this.newTradesDict).length === 1) {
            // this.filledOrdersArray.push(trade)
                this.filledOrdersCurrency = this.getFilledOrdersCurrency();
            }

            const tabc = trade.Account.BaseCurrency;
            if (tabc !== this.filledOrdersCurrency) {
                this.filledOrdersCurrency = this.baseCurrency;
            }
        } else {
            trade.UpdateByMessage(tradeMSg);
        }

        if (this.Loaded) {
            this.OnAddNewTradeEvent.Raise(this, trade);
        }
    }

    public ProcessVolumeQuote (message): void {
    // При расчете Today traded qty учитываем значение настройки Asset trading mode. Если Delivery, то в данном показателе учитываем
    // только трейды c Trading mode = Delivery, если General, то учитываем только трейды с Trading mode = General.
        if (!this.Loaded || message == null) { return; }

        const symbol = this.getInstrumentByTradable_ID(message.InstrumentTradableID, message.Route);

        if (symbol == null) { return; }

        const assetName = symbol.AssetName;

        if (!assetName) { return; }

        if (!this.IsSharedAsset(assetName)) { return; }

        const account = this.GetAccountByIdOrName(message.AccountId);

        if (account?.AssetSharesNames == null) { return; }

        if (account.AssetSharesNames.indexOf(assetName) === -1) { return; }

        const assetDataInfo = account.GetAssetDataInfoByName(assetName);

        const assetType = assetDataInfo.AssetTradingMode;
        const tradeType = message.ProductType;

        if ((assetType === AssetTradingMode.General && tradeType === ProductType.General) ||
        (assetType === AssetTradingMode.Delivery && tradeType === ProductType.Delivery) ||
        (assetType === AssetTradingMode.All)) {
            const amount = message.Amount * symbol.LotSize;
            assetDataInfo.TodayTradedQty += message.BuySell === OperationType.Buy ? amount : -amount;
        }
    }

    public IsSharedAsset (name): boolean {
        const asset = this.GetAssetByName(name);
        return asset != null && asset.Type == AssetType.SHARES;
    }

    public AddExchangeToCache (exchange): void {
        if (exchange && !this.TradingExchanges.includes(exchange)) {
            this.TradingExchanges.push(exchange);
        }
    }

    // TODO Optimization
    public FindInstrumentByTradingExchange (exchange): Instrument | null {
        const keys = Object.keys(this.Instruments);
        const res = {};
        for (let i = 0; i < keys.length; i++) {
            const ins: Instrument = this.Instruments[keys[i]];
            if (ins.TradingExchange === exchange) {
                res[ins.DisplayName()] = ins.GetInteriorID();
            }
        }
        const resKeys = Object.keys(res);
        resKeys.sort();
        const resId = res[resKeys[0]];
        return this.Instruments[resId] || null;
    }

    // #endregion Direct

    public GetSession (id): SessionsContainer {
        return this.SessionDict[id];
    }

    public AddSession (msg): SessionsContainer {
        const sessContainer = new SessionsContainer(msg.Id, msg.Name);
        this.SessionDict[sessContainer.Id] = sessContainer;

        return sessContainer;
    }

    public getRouteByName (name): Route {
        if (name === null || !this.routes.hasOwnProperty(name)) {
            return null;
        } else {
            return this.routes[name];
        }
    }

    public getRouteByTextName (name): Route {
        for (const r in this.routes) {
            if (this.routes[r].Name === name) {
                return this.routes[r];
            }
        }

        return null;
    }

    public getInfoRouteByName (name): any {
        if (name === null || !this.infoRoutes.hasOwnProperty(name)) {
            return null;
        } else {
            return this.infoRoutes[name];
        }
    }

    public getRouteById (id): Route {
        let route = this.routes[id];
        if (route) {
            return route;
        }

        route = this.infoRoutes[id];
        if (route) {
            return route;
        }

        return null;
    }

    public getNewsRoutesIDs (): any[] {
        const newsRoutesIDs = [];
        let routes = this.routes;

        for (const id in routes) {
            if (routes[id].IsNewsRoute) {
                newsRoutesIDs.push(routes[id].RouteId);
            }
        }

        routes = this.infoRoutes;

        for (const id in routes) {
            if (routes[id].IsNewsRoute) {
                newsRoutesIDs.push(routes[id].RouteId);
            }
        }

        return newsRoutesIDs;
    }

    public setNewsAsViewed (newsID): void {
        if (!newsID) {
            return;
        }

        if (ApplicationInfo.isExploreMode) {
            return;
        }

        const viewedNews = LocalStorage.getViewedNews();
        const idEncrypt = this.EncryptUserAndConnectionNameByMD5();

        const viewedNewsObject = viewedNews ? JSON.parse(viewedNews) : {};

        if (!viewedNewsObject[idEncrypt]) {
            viewedNewsObject[idEncrypt] = [];
        }

        if (!viewedNewsObject[idEncrypt].includes(newsID)) {
            viewedNewsObject[idEncrypt].push(newsID);
        }

        LocalStorage.setViewedNews(JSON.stringify(viewedNewsObject));
    }

    public EncryptUserAndConnectionNameByMD5 (): string {
        if (!this.userAndConnectionNameEncryptedByMD5) {
            const connectionNameEncrypt = Md5.hashStr(LocalStorage.getLastConnectionName());
            const userNameEncrypt = Md5.hashStr(Connection.vendor.GetLogin());

            this.userAndConnectionNameEncryptedByMD5 = Md5.hashStr(userNameEncrypt + connectionNameEncrypt);
        }

        return this.userAndConnectionNameEncryptedByMD5;
    }

    public IsNewsViewed (newsID): boolean {
        if (!newsID) {
            return;
        }

        let viewedNews = LocalStorage.getViewedNews();
        const idEncrypt = this.EncryptUserAndConnectionNameByMD5();

        if (!viewedNews) {
            return false;
        }

        viewedNews = JSON.parse(viewedNews);

        if (!viewedNews[idEncrypt]?.includes(newsID)) {
            return false;
        }

        return true;
    }

    public PositionListForAccountInstrument (instrument: Instrument | null, account: Account | null): Position[] {
        const positions = [];
        if (instrument !== null && account !== null) {
        //
        //
        // если передали базовый опцион то по нему надо все дочерние добавлять
        // TODO ???
        // bool isBaseOptionSymbol = instrument.isOptionSymbol && instrument.ForwardBaseInstruments != null;
            const list = this.PositionDict;
            if (Object.keys(this.PositionDict).length > 0) {
                for (const pos in list) {
                    if (list[pos].Account === account &&
                    // list[pos].Instrument === instrument &&
                    Instrument.IsEqualInstrument(list[pos].Instrument, instrument) &&
                    list[pos].Instrument.Route === instrument.Route)
                    // (BaseDataCache.IsEqualInstrument(pos.instrument, instrument) ||
                    // (isBaseOptionSymbol && ((pos.instrument.SourceName == instrument.ShortName ||
                    // (util.Utils.OptionsWorkingMode == OptionsWorkingMode.Direct && pos.instrument.ForwardBaseInstrument != null && instrument.ForwardBaseInstrument != null && pos.instrument.ForwardBaseInstrument.ShortName == instrument.ForwardBaseInstrument.ShortName))
                    // && pos.instrument.getRoute() == instrument.getRoute()))))//роут тоже учитываем
                    {
                        positions.push(list[pos]);
                    }
                }
            }
        }
        return positions;
    }

    public OrdersListForAccountInstrument (instrument: Instrument | null, account: Account | null, buySell = null, addSLTP = true): Order[] {
        const orders = [];
        if (instrument != null && account != null) {
        //
        // если передали базовый опцион то по нему надо все дочерние добавлять
        // bool isBaseOptionSymbol = instrument.isOptionSymbol && instrument.ForwardBaseInstruments != null;

            // var list = this.ActiveOrdersList(addSLTP);
            const list = this.OrderDict;
            if (Object.keys(this.OrderDict).length > 0) {
                for (const ord in list) {
                    if (list[ord].Account === account &&
                    // list[ord].Instrument === instrument &&
                    Instrument.IsEqualInstrument(list[ord].Instrument, instrument) &&
                    list[ord].Instrument.Route === instrument.Route &&
                    list[ord].Active)
                    // (BaseDataCache.IsEqualInstrument(ord.instrument, instrument) || (isBaseOptionSymbol && (ord.instrument.SourceName == instrument.ShortName))) && (!buySell!==null|| buySell  == ord.BuySell) ||
                    // (util.Utils.OptionsWorkingMode == OptionsWorkingMode.Direct && ord.instrument.ForwardBaseInstrument != null && instrument.ForwardBaseInstrument != null && ord.instrument.ForwardBaseInstrument.ShortName == instrument.ForwardBaseInstrument.ShortName))
                    {
                        orders.push(list[ord]);
                    }
                }
            }
        }
        return orders;
    }

    public GetFilteredTrades (instrument: Instrument | null, account: Account | null): Order[] {
        const orders = [];
        if (instrument != null && account != null) {
            const list = this.realTimeFilledOrdersArray;

            for (let i = 0; i < list.length; i++) {
                if (list[i].Account === account &&
                Instrument.IsEqualInstrument(list[i].Instrument, instrument) &&
                list[i].Instrument.Route === instrument.Route) {
                    orders.push(list[i]);
                }
            }
        }
        return orders;
    }

    public GetTradeSessionStatus (id): any {
        const sessionStatus = this.tradeSessionStatusCache[id];
        if (sessionStatus) {
            return sessionStatus;
        }

        return null;
    }

    public getUserByLogin (login): any {
    // TODO
        const user: any = {};
        user.Accounts = [];
        let acc = null;
        const keys = Object.keys(this.Accounts);
        for (let i = 0; i < keys.length; i++) {
            if (this.Accounts[keys[i]].userLogin === login) {
                acc = this.Accounts[keys[i]];
            }
        }
        if (!acc) {
            acc = this.Accounts[keys[0]];
        }
        if (acc) {
            user.Accounts.push(acc);
        }

        return user;
    }

    /** Retrieves the primary account of the user. @documentation [User Primary Account](https://docs.google.com/document/d/1guol3zJqsotNQ8jTVB1VbVt1j4Pggl8E7DeAYFzHRYc/edit#bookmark=id.b188g5i9xrs7) @returns {Account | null} The user's primary account, or null if none is found. */
    public getPrimaryAccount (): Account | null {
        const accs = this.Accounts;
        for (const accID in accs) {
            const acc: Account = accs[accID];
            if (acc.IsOwnedByUser) { return acc; }
        }

        return null;
    }

    public getUserID (): any {
        const acc = this.getPrimaryAccount();
        return acc ? acc.userID : null;
    }

    public getUserName (): string {
        return this.UserLogin;
    }

    public getAllowedReportDict (): any {
        return this.reportDict;
    }

    public getCachedInstruments (): any {
    // TODO бля потом переделывать 100%

        return Object.keys(this.Instruments).map(function (value) {
            return this.Instruments[value];
        }.bind(this));

        /// / Тут я Никиту не понял ???
        // if (this.NonFixedList)
        //    return getSortedInstruments();

        // if (this.cachedInstrumentsWR == null || this.cachedInstrumentsWR.length == 0)
        // {
        //    //на всякий
        //    //TODO IMPLEMENT
        //    //this.GenerateOptions();
        //    this.cachedInstrumentsWR = this.getSortedInstruments();
        // }

    // return this.cachedInstrumentsWR;
    }

    public ProcessTradeSessionSwitchMessage (msg): void {
        const instruments = this.GetInstrumentsBySessionID(msg.SessionId);

        if (instruments.length === 0) {
            console.log('TradeSessionSwitchMessage', "DataCache::ProcessTradeSessionSwitchMessage::Can't find instruments by session id:" + msg.SessionId, EventType.Exception);
            return;
        }

        for (let i = 0; i < instruments.length; i++) {
            instruments[i].TradeSessionStatusId = msg.TradeSessionStatusId;
            if (msg.ExchangeSessionName) { instruments[i].ExchangeSessionName = msg.ExchangeSessionName; }
        }
    }

    public GetInstrumentsBySessionID (sessionId): any[] {
        return this.InstrumentsByTradingSessionsId[sessionId] || [];
    }

    public ProcessInstrumentUpdateMessage (message, continiouseContractSearch = false): void {
        const ai = this.GetAggregatedInstrument(message.Name);
        if (ai === null) {
            if (!continiouseContractSearch) {
                console.log('InstrumentUpdateMessage', "DataCache::ProcessInstrumentUpdateMessage::Can't find AggregatedInstrument for symbol: " + message.Name + ' instrument id: ' + message.InstrumentID + (message.ContractID !== null ? ' contract id: ' + message.ContractID : ''), EventType.Exception);
            }
            return;
        }

        let isFutures = false;
        for (let i = 0; i < ai.length; i++) {
            const inst = ai[i];
            if (message.TradeSessionStatusId !== null && message.TradeSessionStatusId !== undefined) {
                inst.TradeSessionStatusId = message.TradeSessionStatusId;
            }

            if (message.ExchangeSessionName) {
                inst.ExchangeSessionName = message.ExchangeSessionName;
            }

            if (inst.InstrType == InstrumentTypes.FUTURES) {
                isFutures = true;
            }
        }

        if (isFutures && !continiouseContractSearch) {
            message.Name = '[' + message.Name + ']';
            this.ProcessInstrumentUpdateMessage(message, true);
        }
    }

    public AddAggregatedInstrument (ins: Instrument): void {
        if (!this.AggregatedInstruments[ins.ShortName]) {
            this.AggregatedInstruments[ins.ShortName] = [];
        }

        if (ins.SourceName) {
            if (!this.AggregatedInstruments[ins.SourceName]) {
                this.AggregatedInstruments[ins.SourceName] = [];
            }
            this.AggregatedInstruments[ins.SourceName].push(ins);
        }

        this.AggregatedInstruments[ins.ShortName].push(ins);
    }

    public GetAggregatedInstrument (name): any {
        return this.AggregatedInstruments[name] || null;
    }

    public AddSpreadPlan (message): void {
    //
    // Spread plan
    //
        try {
            if (!this.Loaded) {
            // стадия загрузки. сохраняем только непустые планы
            // теперь сохраняем все планы

                const plan = new SpreadPlan();
                plan.InitBy(message, this);
                this.SpreadPlans[message.Id] = plan;
                this.SpreadPlan = plan;
            } else {
            // мы подконнекчены, пришел спредплан - значит, его поменяли, и нам нужно подхватить апдейт

                let plan = this.SpreadPlans[message.Id];
                if (!plan) {
                // не было такого плана еще
                    this.SpreadPlans[message.Id] = plan = new SpreadPlan();
                    plan.InitBy(message, this);

                    const acc = Object.keys(this.Accounts);
                } else {
                    plan.InitBy(message, this);
                }
            }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            console.log(ex);
        }
    }

    public GetSpreadPlan (account: Account | null): SpreadPlan {
        let id = null;
        if (account) {
            id = account.SpreadPlanId;
        } else if (this.MainAccountNew) {
            id = this.MainAccountNew.SpreadPlanId;
        }

        return this.SpreadPlans[id];
    }

    // получение массива HistoryType по периоду(для тиков берутся Trades и Ask или Bid)
    public GetAllowedHistoryTypesByPeriod (period): any[] {
        const listHistoryType = [];

        const allowedHistoryTypes = this.AllowedHistoryTypes;
        let isTickQuote = false;

        const len = allowedHistoryTypes.length;
        for (let i = 0; i < len; i++) {
            const allowedHistoryType = allowedHistoryTypes[i];
            // +++denis http://tp.pfsoft.net/Project/QA/Bug/View.aspx?BugID=16422&acid=74FA48A38CBEE17F6445F76C9BEC721B&list=STATE_0_ctl00_mainArea_projectCatalogZone1_ctl00_tdL_gA&pageIndex=1
            if (period === Periods.TIC) {
                if (!isTickQuote && (allowedHistoryType === HistoryType.BID || allowedHistoryType === HistoryType.ASK)) {
                    listHistoryType.push(allowedHistoryType);
                    isTickQuote = true;
                } else if (allowedHistoryType === HistoryType.LAST) {
                    listHistoryType.push(allowedHistoryType);
                }
            } else listHistoryType.push(allowedHistoryType);
        }
        return listHistoryType;
    }

    get AllowedHistoryTypes (): number[] {
        return this.FAllowedHistoryTypes ? this.FAllowedHistoryTypes.slice() : [];
    }

    // From MultiDataCache.cs
    public formatPrice (symbol, price): string {
    /*
    DecimalFormat ft = new DecimalFormat();
    ft.GroupingUsed = true;
    ft.setMaximumFractionDigits(getPrecision(symbol));
    return ft.FormatDouble(price);
    */

        // TODO. Replace with code from above.
        const ins = this.getInstrumentByName(symbol);
        if (ins) return ins.formatPrice(price);
    }

    public quoteCache_OnReload = async function (quoteController, historyParams, signal): Promise<any> {
        const conn = Connection;
        if (!conn) {
            return [];
        }

        const intervals = await conn.GetHistoryPromise(historyParams, signal);
        return intervals;
    };

    // IInstrument instr, double volume, bool showLots, ProductType productType = ProductType.General
    public formatVolume (instr: Instrument | null, volume: number, showLots: boolean, productType?): string {
        let roundedValue = volume;
        let precision = showLots ? 3 : 0;

        precision = instr?.getAmountPrecision(showLots);

        roundedValue = PriceFormatter.getNumberFormatter(precision).format(roundedValue);

        return roundedValue.toString();
    }

    // TODO. Refactor.
    public getBindToOrderIdArray (srcOrder): any[] {
        const arr = [];

        const orderDict = this.OrderDict;
        for (const key in orderDict) {
            const bindToOrder = orderDict[key];
            if (_DataCache.canBindTo(srcOrder, bindToOrder)) {
                arr.push(bindToOrder.OrderNumber);
            }
        }
        return arr;
    }

    public static canBindTo (srcOrder, bindToOrder): boolean {
        if (!srcOrder ||
        !bindToOrder ||
        srcOrder.Instrument !== bindToOrder.Instrument ||
        srcOrder.Account !== bindToOrder.Account ||
        srcOrder.SharesFilled !== 0 ||
        bindToOrder.SharesFilled !== 0 ||
        !srcOrder.Active ||
        !bindToOrder.Active) {
            return false;
        }

        const orderTypes = OrderType;

        const srcOrdType = srcOrder.OrderType;
        const bindToOrdType = bindToOrder.OrderType;

        if (srcOrdType === orderTypes.Market ||
        bindToOrdType === orderTypes.Market) {
            return false;
        }

        const srcBuy = srcOrder.BuySell;
        const bindToBuy = bindToOrder.BuySell;

        const isSrcLimit = srcOrdType === orderTypes.Limit;
        const isSrcStop =
        srcOrdType === orderTypes.Stop ||
        srcOrdType === orderTypes.StopLimit ||
        srcOrdType === orderTypes.TrailingStop;

        const isBindToLimit = bindToOrdType === orderTypes.Limit;
        const isBindToStop =
        bindToOrdType === orderTypes.Stop ||
        bindToOrdType === orderTypes.StopLimit ||
        bindToOrdType === orderTypes.TrailingStop;

        // Ордера с одинаковым типом, но с разным направлением можно линковать.
        if (srcOrdType === bindToOrdType) {
            return srcBuy !== bindToBuy;
        }

        // Ордера с разным типом и с одинаковым направлением можно линковать,
        // тип имеет значение.
        return srcBuy === bindToBuy &&
        // один должен быть limit, а второй или Stop, или StopLimit, или TrailingStop
        (isSrcLimit && isBindToStop || isSrcStop && isBindToLimit);
    }

    public async GetInstrumentList (patern, exchangeIDs, instrumentTypes: InstrumentTypes[], generateStrikes, searchInDescription, aliasLanguage, oldLookupType): Promise<Instrument[]> {
        if (!this.NonFixedList) {
            return await Promise.resolve(this.InstrumentsArray);
        }

        return await Connection.vendor.GetInstrumentList(patern, exchangeIDs, instrumentTypes, generateStrikes, searchInDescription, aliasLanguage, oldLookupType)
            .then(this.ResponseGenerationFakeInstruments.bind(this, generateStrikes));
    }

    public ResponseGenerationFakeInstruments (generateStrikes, msg): any[] {
        let result = [];
        const ansverList = msg[0].NonFixedInstrumentInfoList;
        for (let i = 0; i < ansverList.length; i++) {
        // тут надо по всем правилам сгенерить фэйк-инструменты по тому что прислал сервер
            const entry = ansverList[i];
            const im = new DirectInstrumentMessage();
            im.Name = entry.Name;
            im.ExchangeId = entry.ExchangeId;
            im.TradingExchange = entry.TradingExchange;
            im.MarketDataExchange = entry.MarketDataExchange;
            im.InstrumentTradableID = entry.InstrumentTradableID;
            im.InstrType = entry.InstrType;
            im.TypeId = entry.GroupId;
            im.Id = entry.ID;
            im.ForwardBaseInstrument = entry.Underlier;
            im.ForwardBaseInstrumentUnderlier = entry.UnderlierUnderlier;
            im.ForwardBaseInstrumentDescr = entry.UnderlierDescr;
            im.ForwardBaseInstrumentCountryId = entry.UnderlierCountryId;
            im.ForwardBaseInstrumentTradingExchange = entry.UnderlierTradingExchange;
            // НУЖЕН норм. признак для формы лукапа
            im.Exp2 = Instrument.FAKE_NONFIXED_RESPONSE;
            im.InstrDateSettingsList = entry.InstrDateSettingsList;
            im.UseOptionDescription = entry.UseOptionDescription;
            im.AllExpDateLoaded = entry.AllExpDateLoaded;
            im.AllContractsLoaded = entry.AllContractsLoaded;
            im.SeriesGroupName = entry.SerieName;
            im.Descr = entry.Description;
            im.UnderlierDescription = entry.Underlier;

            if (entry.LanguageAliases != null) {
                im.LanguageAliases = Instrument.CloneLangAliases(entry.LanguageAliases);
            }

            const pointSize = entry.PointSize;
            const routesSupported = [];
            const routesTradable = [];

            this.AddExchangeToCache(entry.TradingExchange);

            im.CountryId = entry.CountryId;

            for (let j = 0; j < entry.Routes.length; j++) {
                const routeId = entry.Routes[j];
                const rroute = this.getRouteById(routeId);
                if (rroute?.IsTradable) {
                    im.TradableRoutes.push(routeId);// += rroute.Name + ";";
                    routesTradable.push(routeId);
                }
                if (rroute !== null && rroute.IsInfo) {
                    im.RoutesSupported.push(routeId);// += rroute.Name + ";";
                    routesSupported.push(routeId);
                }
            }

            /* логика схлопывания роутов когда инфо+трейд. */
            let showInfoRoutes = true;
            if (routesTradable.length === 1 && routesSupported.length === 1) {
                showInfoRoutes = false;
            } else {
                showInfoRoutes = true;
            }

            for (let j = 0; j < entry.Routes.length; j++) {
                const routeID = entry.Routes[j];
                const rr = this.getRouteById(routeID);
                const route = rr != null ? rr.Name : /* тут не должно заходить */'ROUTE_ID_' + routeID;

                // if (!rr)
                //    continue; //TODO

                if (!showInfoRoutes && rr.IsInfo && !rr.IsTradable) // если надо прятать инфо
                {
                    continue;
                } // вообще не добавляем инфошные если их надо прятать(схлопывать!)

                if (im.InstrType === InstrumentTypes.OPTIONS) {
                    for (let z = 0; z < im.InstrDateSettingsList.length; z++) {
                        const ds = im.InstrDateSettingsList[z];
                        const tmp = {};
                        for (let p = 0; p < ds.StrikePricesList.length; p++) {
                            tmp[ds.StrikePricesList[p].StrikePrice] = ds.StrikePricesList[p];
                        }
                        ds.StrikePricesListDict = tmp;
                        this.GenerateFakeOptionPair(result, im, ds, routeID, pointSize, generateStrikes/*, cancellationToken */);
                    }
                } else {
                // все неопцоны - обрабатываются тут
                    const fakeInstruments = this.GenerateInstruments(im, routeID/*, cancellationToken, GenerateInstrumentContext.IntellisenseList */);
                    result = result.concat(fakeInstruments);
                }
            }
        }
        return result;
    }

    public async getInstrumentById (instrumentID): Promise<any> {
        if (!this.NonFixedList) {
            return await Promise.resolve(false);
        }

        const msg = await Connection.vendor.getInstrumentById(instrumentID);
        return this._gIBId(msg);
    }

    // the same
    public updateInstrumentById = this.getInstrumentById;

    public _gIBId (msg): boolean {
        if (!msg.length) {
            return false;
        }

        const _msg = msg[0];

        if (_msg.Code !== Message.CODE_INSTRUMENT) {
            return false;
        }

        this.NewMessage(_msg);
        return true;
    }

    public async getInstrumentByNameNFL (instrumentName): Promise<Instrument> {
        if (!this.NonFixedList) {
            return await Promise.resolve(this.getInstrumentByName(instrumentName));
        }

        return await Connection.vendor.getInstrumentByNameNFL(InstrumentUtils.RemoveRouteName(instrumentName))
            .then((msg) => {
                if (!msg) {
                    return null;
                }

                if (!msg.length) {
                    return null;
                }

                if (msg[0].Code !== Message.CODE_INSTRUMENT) {
                    return null;
                }

                for (let i = 0; i < msg.length; i++) {
                    if (msg[i].Code === Message.CODE_INSTRUMENT) {
                        this.NewMessage(msg[i]);
                    }
                }
                const ins = this.getInstrumentByName(instrumentName);
                if (!ins) {
                    return null;
                }
                const rpKeys = Object.keys(this.riskPlanDict);
                for (let i = 0, len = rpKeys.length; i < len; i++) {
                    this.riskPlanDict[rpKeys[i]].processOneInstrument(ins, this.riskPlanDict[rpKeys[i]].items);
                }

                return ins;
            });
    }

    public async getInstrumentByInstrumentTradableID_NFL (InstrumentTradableID: number, Route: number, InteriorID: string | null = null, RouteValidationIgnore = false): Promise<Instrument | null> {
        if (isNaN(InstrumentTradableID)) {
            return await Promise.resolve(null);
        }

        if (!this.getRouteById(Route) && !RouteValidationIgnore) {
            return await Promise.resolve(null);
        }

        const mb_we_have_instrument = this.getInstrumentByTradable_ID(InstrumentTradableID, Route);
        if (mb_we_have_instrument) {
            return await Promise.resolve(mb_we_have_instrument);
        }

        // #98849 возможен фикс
        if (!this.NonFixedList) {
            if (RouteValidationIgnore) {
                const mb_we_have_instrument_by_t_id = this.getInstrumentByTradable_ID_first(InstrumentTradableID);
                return await Promise.resolve(mb_we_have_instrument_by_t_id);
            } else {
                return await Promise.resolve(null);
            }
        }

        return await Connection.vendor.getInstrumentByInstrumentTradableID_NFL(InstrumentTradableID)
            .then((msg) => {
                if (!msg) {
                    return null;
                }

                if (!msg.length) {
                    return null;
                }

                if (msg[0].Code !== Message.CODE_INSTRUMENT) {
                    return null;
                }

                for (let i = 0; i < msg.length; i++) {
                    if (msg[i].Code === Message.CODE_INSTRUMENT) {
                        this.NewMessage(msg[i]);
                    }
                }
                let ins = null;

                if (!InteriorID) {
                    ins = this.getInstrumentByTradable_ID(InstrumentTradableID, Route);
                } else {
                    ins = this.getInstrumentByName(InteriorID);
                }

                if (!ins) {
                    if (RouteValidationIgnore) {
                        ins = this.getInstrumentByTradable_ID_first(InstrumentTradableID);
                    }

                    if (!ins) {
                        return null;
                    }
                }
                const rpKeys = Object.keys(this.riskPlanDict);
                for (let i = 0, len = rpKeys.length; i < len; i++) {
                    this.riskPlanDict[rpKeys[i]].processOneInstrument(ins, this.riskPlanDict[rpKeys[i]].items);
                }

                return ins;
            });
    }

    public GetNonFixedInstrumentListByAssetName (AsssetName): any {
        return Connection.vendor.GetNonFixedInstrumentListByAssetName(AsssetName)
            .then(this.ResponseGenerationFakeInstruments.bind(this, false));
    }

    public async GetNonFixedInstrumentStrikes (instrument: Instrument): Promise<Instrument[]> {
        return await Connection.vendor.GetNonFixedInstrumentStrikes(instrument.ContractID)
            .then((msg: DirectNonFixedInstumentStrikesResponseMessage[]) => {
                const im = instrument.CreateInstrumentMessage();
                const result: Instrument[] = [];
                const findedStrikes = msg[0];
                // TODO КОСТЫЛЬ переделать запрос, изменить instrument передавать контракт
                im.Descr = '';
                for (let i = 0, len = im.InstrDateSettingsList.length; i < len; i++) {
                    const ds = im.InstrDateSettingsList[i];
                    if (findedStrikes != null) {
                        if (+ds.ContractMonthDate === +instrument.ExpDate) {
                            ds.StrikePricesList = findedStrikes.strikePricesList;
                            // Todo
                            const tmp = new Object();
                            for (let j = 0, len_j = ds.StrikePricesList.length; j < len_j; j++) {
                                tmp[ds.StrikePricesList[j].StrikePrice] = ds.StrikePricesList[j];
                            }

                            ds.StrikePricesListDict = tmp;
                            OptionContract.MergeOptionContractGroup(ds, findedStrikes.optionContractGroup);
                            this.GenerateFakeOptionPair(result, im, ds, instrument.Route, findedStrikes.PointSize, true);
                        }
                    }
                }
                return result;
            });
    }

    public GenerateInstruments (mess: DirectInstrumentMessage, route): any[] {
        const instruments = [];
        const isFuture = mess.InstrType === InstrumentTypes.FUTURES || mess.InstrType === InstrumentTypes.CFD_FUTURES;
        const isOption = mess.InstrType === InstrumentTypes.OPTIONS;

        if (isFuture) {
        // фьючерс детектед
        // New schema
            const list = mess.InstrDateSettingsList;
            for (let i = 0; i < list.length; i++) {
                const ds = list[i];
                if (ds.ContractMonthDate.getFullYear() <= 1970) {
                    continue;
                }

                const futureInstrument = this.GenerateFutureInstrument(mess, ds, route, false);
                instruments.push(futureInstrument);

                if (ds.IsContinious) {
                    futureInstrument.IsContiniousContract = true; // по этому инструменту есть cont.contract
                    futureInstrument.HaveContiniousContract = true;
                    if (!this.ContractsCache[route]) {
                        this.ContractsCache[route] = {};
                    }
                    if (mess.Id) {
                        this.ContractsCache[route][mess.Id] = futureInstrument;
                    }

                    instruments.push(this.GenerateFutureInstrument(mess, ds, route, true));
                }
            }
        } else if (isOption) {
            const len = mess.InstrDateSettingsList.length;
            for (let i = 0; i < len; i++) {
                const ds = mess.InstrDateSettingsList[i];
                const forwardBaseInstrumentID = !isNullOrUndefined(mess.ForwardBaseInstrumentID) ? mess.ForwardBaseInstrumentID.toString() : '';
                const underlierTradableId = !isNullOrUndefined(ds.UnderlierTradableId) ? ds.UnderlierTradableId.toString() : '';
                const baseInstr = this.FindUnderlier(forwardBaseInstrumentID, route, underlierTradableId);
                if (baseInstr == null) {
                    return null;
                }

                for (let j = 0, len_j = ds.StrikePricesList.length; j < len_j; j++) {
                    const sett = ds.StrikePricesList[j];
                    if (ds.ContractMonthDate.getFullYear() <= 1970) {
                        continue;
                    }

                    if (sett.CallEnabled) {
                        const optionInstr = this.GenerateOptionInstrument(mess, ds, route, OptionPutCall.OPTION_CALL_VANILLA, baseInstr, sett);
                        if (optionInstr != null) {
                            instruments.push(optionInstr);
                        }
                    }
                    if (sett.PutEnabled) {
                        const optionInstr = this.GenerateOptionInstrument(mess, ds, route, OptionPutCall.OPTION_PUT_VANILLA, baseInstr, sett);
                        if (optionInstr != null) {
                            instruments.push(optionInstr);
                        }
                    }
                }
                this.OptionsCache.add(mess, route, baseInstr);
            }
        } else {
            const instr = new Instrument(this, mess, route);
            // TODO UNIC names

            instruments.push(instr);
        }

        return instruments;
    }

    public GenerateFutureInstrument (mess, ds, route, isContinious): Instrument {
        let name = mess.Name;

        if (ds.FutureAliasName) {
            name = ds.FutureAliasName;
        }

        const instrument = this.GenerateInstrument((isContinious ? ('[' + name + ']') : name), mess, ds, route);
        if (isContinious) {
            instrument.InstrumentSpecificType = InstrumentSpecificType.ContiniousContract;
            instrument.ContiniousContractName = name;
        }

        return instrument;
    }

    public GenerateInstrument (name, mess: DirectInstrumentMessage, ds, route, forseCreateFake?): Instrument {
        if (!forseCreateFake) {
            forseCreateFake = false;
        }

        const copy = mess.Clone();

        copy.Name = name;

        // TODO UNIC names

        // let useInstrumentFromCache = !!instrNames[fullName] && !forseCreateFake;
        const useInstrumentFromCache = false;
        // let der_instr = useInstrumentFromCache ? instrNames[fullName] : new Instrument(this, copy, route);
        const der_instr = new Instrument(this, copy, route);

        // +++ nfrolov: Если инструмента в кэше еще нет, то можем спокойно (и должны) установить все поля из мессэджа
        if (/* context == GenerateInstrumentContext.Normal || */ !useInstrumentFromCache) {
            der_instr.SourceName = mess.Name;

            der_instr.SeriesGroupName = mess.SeriesGroupName;

            der_instr.SourceDescription = mess.Descr;

            this.UpdateDerInstr(der_instr, ds, mess);

            if (mess.MarginCoeficientList.length > 0) {
                let futCoef = mess.MarginCoeficientList[0];
                for (const fc of mess.MarginCoeficientList) {
                    if (fc.FuturesExpYear == ds.ContractMonthDate.Year && fc.FuturesExpMonth == ds.ContractMonthDate.Month && fc.FuturesExpDay == ds.ContractMonthDate.Day) {
                        futCoef = fc;
                        break;
                    }
                }

                der_instr.RiskSettings.MarginCoefficients = futCoef;
            }
        }

        return der_instr;
    }

    public async GetBrandingRules (brandingKey: string): Promise<number> {
        const msgArr = await Connection.vendor.GetBrandingRules(brandingKey);
        const msg = msgArr[0];
        if (!msgArr.length || msg.Code !== Message.CODE_PFIX_BRANDING_RULES_RESP) { return -1; }

        if (msg.CompanyName) { this.CompanyName = msg.CompanyName; } // #85820
        if (msg.BrokerInformation) { this.BrokerInformation = msg.BrokerInformation; } // #87238
        if (msg.PoweredByName) { this.PoweredByName = msg.PoweredByName; } // #88323
        if (msg.PoweredByURL) { this.PoweredByURL = msg.PoweredByURL; } // #88323
        if (msg.BrandingClientSessionTimeoutInMinutes) { this.BrandingClientSessionTimeoutInMinutes = parseInt(msg.BrandingClientSessionTimeoutInMinutes); }
        if (msg.BrandingVisibleInstrumentLogo) { this.BrandingVisibleInstrumentLogo = msg.BrandingVisibleInstrumentLogo === '1'; }

        this.AllowPreviewPassword = msg.BrandingAllowPasswordPreview === '1'; // #98204

        return this.BrandingClientSessionTimeoutInMinutes;
    }

    public GetYieldRate (instrument: Instrument): any {
        const instrumentID = instrument.Id;
        const routeID = this.getRouteByName(instrument.getRoute()).RouteId;

        return Connection.vendor.GetYieldRate(instrumentID, routeID)
            .then(function (msg) {
                if (!msg.length || msg[0].Code !== Message.CODE_YTM_RESPONSE) {
                    return false;
                }

                if (msg[0].YieldRate) {
                    instrument.YieldRate = msg[0].YieldRate;
                }

                return true;
            });
    }

    public AddDeferredMessage (msg): void {
        const route = msg.Route;
        const insTradableID = msg.InstrumentTradableID;

        if (!route || !insTradableID) return;

        const key = insTradableID + '::' + route;
        if (!this.deferredInsReq[key]) {
            this.deferredInsReq[key] = true;
            this.getInstrumentByInstrumentTradableID_NFL(insTradableID, route)
                .then(function (instrument) {
                    this.deferredInsReq[key] = false;
                    if (!instrument) {
                        return;
                    }

                    this.ProcessDeferredMessages(instrument);
                }.bind(this));
        }

        if (!this.deferredMessages[route]) {
            this.deferredMessages[route] = {};
        }

        const deferredByRoute = this.deferredMessages[route];

        if (!deferredByRoute[insTradableID]) {
            deferredByRoute[insTradableID] = [];
        }

        deferredByRoute[insTradableID].push(msg);
    }

    public ProcessDeferredMessages (instrument: Instrument | null): void {
        let deferredMsgArr = [];

        // this.SkipNewDefer = true;
        if (instrument === null) {
            const routes = Object.keys(this.deferredMessages);
            for (let i = 0; i < routes.length; i++) {
                const byRoute = this.deferredMessages[routes[i]];
                const byIns = Object.keys(byRoute);

                for (let j = 0; j < byIns.length; j++) {
                    deferredMsgArr = deferredMsgArr.concat(byRoute[byIns[j]]);
                }
            }

            this.deferredMessages = {};
        } else {
            const route = instrument.Route;
            const insTradableID = instrument.InstrumentTradableID;

            if (!route || !insTradableID) return;

            const deferredByRoute = this.deferredMessages[route];

            if (!deferredByRoute) return;

            const deferredByInstrument = deferredByRoute[insTradableID];

            if (!deferredByInstrument?.length) return;

            deferredMsgArr = deferredByRoute[insTradableID].slice(0);

            delete deferredByRoute[insTradableID];

            if (Object.keys(deferredByRoute).length === 0) {
                delete this.deferredMessages[route];
            }
        }

        for (let i = 0; i < deferredMsgArr.length; i++) {
        // TODO заебашить норм в 3.99, то херь дикая как по мне 18.05.2021
        // переделать на менеджер этого всего
            let resMsgs = [];
            const msg = deferredMsgArr[i];
            if (msg.CPHFD) {
                resMsgs = msg.CPHFD(msg);
            } else {
                resMsgs = [msg];
            }

            for (let j = 0; j < resMsgs.length; j++) {
                this.NewMessage(resMsgs[j]);
            }
        }
    }

    public GenerateFakeOptionPair (result: Instrument[], im, ds, route, pointSize, generateStrikes): void {
    // TODO: как только сервер дошлёт заменить на реальный пресижн
        const baseInstrPrecision = MathUtils.GetDecimalPlaces(pointSize, 20);

        const strikes = generateStrikes ? ds.StrikePricesList : [];

        // TODO его у нас нет
        if (strikes.length == 0)
        // для запроса опшионмастера
        {
            result.push(this.GenerateFakeOptionInstrument(im, ds, route, -1, -1, baseInstrPrecision, true));
        }

        for (let i = 0; i < strikes.length; i++) {
            const strike = strikes[i];
            const strikePrice = strike.StrikePrice;

            if (strike.CallEnabled) {
                const optionInstr = this.GenerateFakeOptionInstrument(im, ds, route, strikePrice, OptionPutCall.OPTION_CALL_VANILLA, baseInstrPrecision);
                if (optionInstr != null) {
                    result.push(optionInstr);
                }
            }
            if (strike.PutEnabled) {
                const optionInstr = this.GenerateFakeOptionInstrument(im, ds, route, strikePrice, OptionPutCall.OPTION_PUT_VANILLA, baseInstrPrecision);
                if (optionInstr != null) {
                    result.push(optionInstr);
                }
            }
        }
    }

    public GenerateFakeOptionInstrument (mess, ds, route, strikeprice, putcall, baseinstrumentPrecision, OptionMasterLookupMode?): Instrument {
        if (!strikeprice) {
            strikeprice = -1;
        }

        if (putcall === undefined || putcall === null) {
            putcall = -1;
        }

        if (!baseinstrumentPrecision) {
            baseinstrumentPrecision = 0;
        }

        if (!OptionMasterLookupMode) {
            OptionMasterLookupMode = false;
        }
        /// baseinstrumentPrecision ! Женя: в этом методе и выше (GenerateOptionInstrument) надо брать вариабл тик сайз если он установлен. когда протягивать то для интелисенса надо тоже!

        let underlierRoute = route;

        //
        // Fake
        const underlierName = ds.Underlier || mess.ForwardBaseInstrument;
        const underlierDescription = mess.ForwardBaseInstrumentDescr;
        const underlierCountryId = mess.ForwardBaseInstrumentCountryId;
        const underlierTradingExchange = mess.ForwardBaseInstrumentTradingExchange;

        // TODO NOrmal logic
        // if (ds.UnderlierDate)
        //     underlierName = ds.FutureAliasName;

        let baseInstr = this.getInstrumentByTradable_ID(ds.UnderlierTradableId, route);
        if (baseInstr == null) {
            const im_forBase = new DirectInstrumentMessage();
            im_forBase.Name = mess.ForwardBaseInstrument;
            im_forBase.RoutesSupported = mess.RoutesSupported; // route <- закомментил, а то IsHideRouteMode неправильно применялся
            im_forBase.TradableRoutes = mess.TradableRoutes; // #44590
            im_forBase.Exp2 = Instrument.FAKE_NONFIXED_RESPONSE;
            im_forBase.InstrType = ds.UnderlierDate ? InstrumentTypes.FUTURES : InstrumentTypes.INDICIES;
            im_forBase.SeriesGroupName = mess.SeriesGroupName;
            if (underlierDescription) {
                im_forBase.Descr = underlierDescription;
            }

            if (underlierCountryId) {
                im_forBase.CountryId = underlierCountryId;
            }

            if (underlierTradingExchange) {
                im_forBase.TradingExchange = underlierTradingExchange;
            }

            // #57604 - hsa: ранее мы использовали роут опциона при генерации андерлаера, но по факту это делать нельзя, т.к. у андерлаера может быть другой роут
            // нельзя генерировать фейковый андерлаер по неправильному роуту, если реальный андерлаер уже находится в агрегированных инструментах, т.к. в методе UpdateHideRouteMode апдейтится видимость инструментов на агрегированных инструментах
            const agrIns = this.getInstrumentByName(underlierName);
            // let agrIns = BaseApplication.App.MultiDataCache.GetAggregatedInstrument(underlierName);
            if (agrIns != null) {
                if (!agrIns.TradableRoutes.includes(underlierRoute) && !agrIns.RoutesSupported.includes(underlierRoute)) {
                // не нашли нужный нам роут, берем первый (!) торговый (или первый информационный), это должно покрыть кейс, когда андерлаер и опцион находятся на разных роутах
                    if (agrIns.TradableRoutes.length > 0) {
                        underlierRoute = agrIns.TradableRoutes[0];
                    } else if (agrIns.RoutesSupported.length > 0) {
                        underlierRoute = agrIns.RoutesSupported[0];
                    }
                }

                // нужно зааффектить InstrumentMessage, иначе неверно отработает метод UpdateHideRouteMode на инструменте
                im_forBase.RoutesSupported = agrIns.RoutesSupported.join(';');
                im_forBase.TradableRoutes = agrIns.TradableRoutes.join(';');
            }

            const baseInstrDs = new InstrDateSettings();
            if (ds.UnderlierTradableId) {
                baseInstrDs.InstrumentTradableID = ds.UnderlierTradableId;
            }

            if (im_forBase.InstrType == InstrumentTypes.FUTURES) {
                baseInstrDs.ContractMonthDate = ds.UnderlierDate;
                baseInstr = this.GenerateInstrument(underlierName, im_forBase, baseInstrDs, underlierRoute, true);
            } else {
                baseInstr = this.GenerateInstrument(underlierName, im_forBase, baseInstrDs, underlierRoute, true);
            }

            if (baseInstr == null) {
                return null;
            }

            // +++
            baseInstr.SourceName = mess.ForwardBaseInstrument;
        }
        // 3  кейса:
        // 1. опцион без страйков для начального заполнения лукапа
        // 2. заполнение опциона со страйками для StrikesResponse
        // 3. OptionMasterLookupMode - имя как у андерлаера
        let name;

        // if (OptionMasterLookupMode)
        //    name = mess.Name;
        // else
        // {
        let aliasName = null;
        const optGenName = mess.Name;
        if (ds.useAliasName) {
            const s = ds.StrikePricesListDict[strikeprice];
            // let strikesList = ds.StrikePricesList != null ? ds.StrikePricesList.Where(x => (double)x.StrikePrice == strikeprice).ToList<StrikePriceSettings>() : new List<StrikePriceSettings>();
            // for (var s in strikesList)
            {
                if (putcall == OptionPutCall.OPTION_CALL_VANILLA && s.CallEnabled) { aliasName = s.CallTicker; } else if (putcall == OptionPutCall.OPTION_PUT_VANILLA && s.PutEnabled) {
                    aliasName = s.PutTicker;
                }
            }
        }

        if (aliasName) {
            name = aliasName;
        } else {
            name = optGenName;
        }
        // }

        const cur_route = OptionMasterLookupMode ? underlierRoute : route;

        const instrument = this.GenerateInstrument(name, mess, ds, cur_route, true);
        instrument.StrikePrice = strikeprice;
        instrument.PutCall = putcall;

        const s = ds.StrikePricesListDict[strikeprice];

        let description = '';
        if (s && putcall == OptionPutCall.OPTION_CALL_VANILLA && s.CallEnabled) {
            instrument.InstrumentTradableID = s.InstrumentTradableIDCall;
            description = s.DescriptionCall || '';
        } else if (s && putcall == OptionPutCall.OPTION_PUT_VANILLA && s.PutEnabled) {
            instrument.InstrumentTradableID = s.InstrumentTradableIDPut;
            description = s.DescriptionPut || '';
        }

        if (mess.Descr) {
            instrument.Description = instrument.SeriesDescription = mess.Descr;
        }

        if (ds.Description) {
            instrument.Description = instrument.SeriesDescription = ds.Description;
        }

        if (description) {
            instrument.Description = description;
        }

        instrument.ForwardBaseInstrument = baseInstr;
        instrument.ForwardBaseInstruments = new Array(0);

        return instrument;
    }

    /// <summary>
    /// внутри обновляются инструмент поля
    /// </summary>
    public UpdateDerInstr (der_instr, ds, mess): void {
        if (der_instr == null || ds == null) {
            return;
        }

        der_instr.ContractID = ds.ContractID;

        der_instr.ExpDate = ds.ContractMonthDate;
        der_instr.ExpDateReal = ds.ExpirationDate;

        der_instr.DeliveryStatus = ds.DeliveryStatus;
        der_instr.ContractMonthDate = ds.ContractMonthDate;
        der_instr.LastTradeDate = ds.LastTradeDate;
        der_instr.SettlementDate = ds.SettlementDate;
        der_instr.NoticeDate = ds.NoticeDate;
        der_instr.FirstTradeDate = ds.FirstTradeDate;
        der_instr.AutoCloseDate = ds.AutoCloseDate;

        der_instr.Limits.HightLimit = ds.HightLimit;
        der_instr.Limits.LowLimit = ds.LowLimit;
        der_instr.Limits.PriceLimitMeasure = ds.PriceLimitMeasure;

        der_instr.Limits.IsHighLimitFrontEndValidationEnabled = ds.IsHighLimitFrontEndValidationEnabled;
        der_instr.Limits.IsLowLimitFrontEndValidationEnabled = ds.IsLowLimitFrontEndValidationEnabled;

        if (mess.Descr) {
            der_instr.Description = der_instr.SeriesDescription = mess.Descr;
        }

        if (ds.Description) {
            der_instr.Description = der_instr.SeriesDescription = ds.Description;
        }

        //
        // Trading halt
        //
        der_instr.TradingMode = ds.TradingMode;

        // #53213 Tick size/Tick cost/Lot step on contact level
        if (ds.LotSize !== undefined) {
            der_instr.LotSize = ds.LotSize;
        }

        if (ds.PointSize !== undefined) {
            der_instr.PointSize = ds.PointSize;
        }

        if (ds.LotStep !== undefined) {
            der_instr.LotStep = ds.LotStep;
        }

        if (ds.FuturesTickCoast !== undefined) {
            der_instr.FuturesTickCoast = ds.FuturesTickCoast;
        }

        if (ds.VariableTicks != null) {
            der_instr.VariableTickList = ds.VariableTicks;
        } else if (ds.PointSize && ds.FuturesTickCoast) // не пришел переменный тиксайз, зато пришел тиксайз и тиккост, настроенный на серии, мы не имеем права брать переменный тиксайз из IM
        {
            der_instr.VariableTickList = [new VariableTick({ lowLimit: Number.NEGATIVE_INFINITY, highLimit: Number.POSITIVE_INFINITY, allowLimit: true, pointSize: ds.PointSize, tickCost: ds.FuturesTickCoast })];
        }

        der_instr.AdditionalFields = ds.AdditionalFields;

        // hsa. мы должны дополнительно еще задать точность
        if (ds.PointSize !== undefined || ds.VariableTicks !== null) {
            const prec = Instrument.CalculatePrecision(ds.PointSize !== undefined ? ds.PointSize : 0, ds.VariableTicks !== null ? ds.VariableTicks : der_instr.VariableTickList);
            der_instr.Precision = prec;
        }

        der_instr.TradeSessionStatusId = ds.TradeSessionStatusId || -1;
        der_instr.ExchangeSessionName = ds.ExchangeSessionName;

        der_instr.InstrumentTradableID = ds.InstrumentTradableID;

        der_instr.MinLot = ds.minLot;
        der_instr.MaxLot = ds.maxLot;

        der_instr.ISIN = ds.ISIN;
    }

    public GenerateOptionInstrument (mess, ds, route, putcall, baseInstr, strikeSettings): Instrument {
        const strikePrice = strikeSettings.StrikePrice;
        let optTicker = '';
        let lowLimit = 0;
        let highLimit = 0;
        let highLimitFrontEndValidationEnabled = null; let lowLimitFrontEndValidationEnabled = null;
        let minLot = -1;
        let maxLot = -1;
        let priceLimitMeasure = PriceLimitMeasure.DISABLE;
        let description = '';
        let extFields = null;
        let tradeSessionStatusId;
        let exchangeSessionName;
        let InstrumentTradableID;
        let ISIN = '';

        if (putcall === OptionPutCall.OPTION_PUT_VANILLA) {
            optTicker = strikeSettings.PutTicker;
            lowLimit = strikeSettings.LowLimitPut;
            highLimit = strikeSettings.HighLimitPut;
            lowLimitFrontEndValidationEnabled = strikeSettings.IsLowLimitFrontEndValidationEnabledPut;
            highLimitFrontEndValidationEnabled = strikeSettings.IsHighLimitFrontEndValidationEnabledPut;
            minLot = strikeSettings.minLotPut;
            maxLot = strikeSettings.maxLotPut;
            priceLimitMeasure = strikeSettings.PriceLimitMeasurePut;
            description = strikeSettings.DescriptionPut;
            extFields = strikeSettings.ExtFieldsPut;
            tradeSessionStatusId = strikeSettings.TradeSessionStatusIdPut;
            exchangeSessionName = strikeSettings.ExchangeSessionNamePut;
            InstrumentTradableID = strikeSettings.InstrumentTradableIDPut;
            ISIN = strikeSettings.ISINPut;
        } else {
            optTicker = strikeSettings.CallTicker;
            lowLimit = strikeSettings.LowLimitCall;
            highLimit = strikeSettings.HighLimitCall;
            lowLimitFrontEndValidationEnabled = strikeSettings.IsLowLimitFrontEndValidationEnabledCall;
            highLimitFrontEndValidationEnabled = strikeSettings.IsHighLimitFrontEndValidationEnabledCall;
            minLot = strikeSettings.minLotCall;
            maxLot = strikeSettings.maxLotCall;
            priceLimitMeasure = strikeSettings.PriceLimitMeasureCall;
            description = strikeSettings.DescriptionCall;
            extFields = strikeSettings.ExtFieldsCall;
            tradeSessionStatusId = strikeSettings.TradeSessionStatusIdCall;
            exchangeSessionName = strikeSettings.ExchangeSessionNameCall;
            InstrumentTradableID = strikeSettings.InstrumentTradableIDCall;
            ISIN = strikeSettings.ISINCall;
        }

        const instrument = this.GenerateInstrument(optTicker, mess, ds, route);

        instrument.StrikePrice = strikePrice;
        instrument.PutCall = putcall;
        instrument.Limits.LowLimit = lowLimit;
        instrument.Limits.HightLimit = highLimit;
        instrument.Limits.IsHighLimitFrontEndValidationEnabled = highLimitFrontEndValidationEnabled;
        instrument.Limits.IsLowLimitFrontEndValidationEnabled = lowLimitFrontEndValidationEnabled;
        instrument.MinLot = minLot;
        instrument.MaxLot = maxLot;
        instrument.Limits.PriceLimitMeasure = priceLimitMeasure;
        if (description) {
            instrument.Description = description;
        }

        instrument.AdditionalFields = extFields;
        // указываю явно базовый инструмент, поскольку для случая, когда фьючерсов больше 1го,
        // инструмент сам определиться не может - какой именно ему нужен как базовый (не знает конкретную ds.ContractMonthDate)
        instrument.ForwardBaseInstrument = baseInstr;
        instrument.ForwardBaseInstruments = new Array(0); // если потребуется поменять, не забудь пофиксить ParseOptionName, иначе подписка накроется
        instrument.TradeSessionStatusId = tradeSessionStatusId | -1;
        instrument.ExchangeSessionName = exchangeSessionName;
        instrument.InstrumentTradableID = InstrumentTradableID;
        instrument.ISIN = ISIN;
        instrument.TradingMode = strikeSettings.TradingMode; // #115179

        return instrument;
    }

    public RecalculateBalances (): void {
        if (!this.Loaded) {
            return;
        }

        const orderDict = this.OrderDict;
        const prevTable = this.InstrumentBalancerTable;
        this.InstrumentBalancerTable = {};
        const userBalanserTable = {};

        const posKeys = Object.keys(this.PositionDict);

        for (let i = 0, len = posKeys.length; i < len; i++) {
            const pos = this.PositionDict[posKeys[i]];

            const InsFullName = pos.Instrument.GetInteriorID();
            let pBal = this.InstrumentBalancerTable[InsFullName];
            if (!pBal) {
                pBal = new InstrumentBalancer(pos.Instrument);
                this.InstrumentBalancerTable[InsFullName] = pBal;
            }

            if (pos.Account != null) {
                let uBal = userBalanserTable[pos.Account.AcctNumber];
                if (!uBal) {
                    uBal = new UserBalancer(pos.Account);
                    userBalanserTable[pos.Account.AcctNumber] = uBal;
                }

                if (pos.lastPriceUpdated && !pos.isTradePosition) // #49087 - не считаем профит если не было котировок; #97626 - не учитываем трейдовую позицию для профита
                {
                    const profit = pos.getGrossPnL(true);
                    uBal.Profit += (pos.Instrument.OptionTradingStyle !== OptionTradingStyle.MarkToMarket) ? profit : 0;

                    const netProfitInAccCur = pos.getNetPnL(true);
                    if (netProfitInAccCur !== null && uBal.ProfitNet !== null) {
                        uBal.ProfitNet += netProfitInAccCur;
                    } else {
                        uBal.ProfitNet = null;
                    }

                    // uBal.ProfitForMargin += MarginCounterLocal.GetProfitForMargin(pos, profit);
                    const onlyProfit = MarginCounterLocal.GetProfitLossWithUnsettledProfitProperties(pos, profit > 0 ? profit : 0);
                    uBal.ProfitForUnsettled += onlyProfit;
                    const rs = pos.Instrument.RiskSettings;
                    if (rs !== null && rs.AllowWithdrawUnrealizedProfit(pos.ProductType, pos.Account) === false) {
                        uBal.OnlyProfitForWithdrawalAvailable += onlyProfit;
                    }

                    uBal.LossForUnsettled += MarginCounterLocal.GetProfitLossWithUnsettledProfitProperties(pos, profit < 0 ? profit : 0);

                    uBal.ProfitBase += pos.getNetPnL(false);

                    const netProfitInBaseCur = pos.getNetPnL(false);
                    if (netProfitInBaseCur !== null && uBal.ProfitNetBase !== null) {
                        uBal.ProfitNetBase += netProfitInBaseCur;
                    } else {
                        uBal.ProfitNetBase = null;
                    }

                    uBal.OptionValue += pos.DataCache.CalculateOptionValue(pos.Instrument, pos.Amount, pos.BuySell, pos.CurPriceClose, pos.Account.assetBalanceDefault.Asset.Name);
                    uBal.OptionPremium += pos.CalcultateOptionPremium();
                }
                uBal.PositionsValue += pos.getPositionValue(true);
                if (!pos.isTradePosition) {
                    uBal.OpenPosNumber += 1;
                } // not counting trades number (FIFO) #80010

                uBal.OpenPosExposition += pos.getExpositionValue(true);
                uBal.OpenPosAmount += pos.Amount;

                if (pos.Instrument != null && (pos.Instrument.InstrType == InstrumentTypes.CORPORATE || pos.Instrument.InstrType == InstrumentTypes.EQUITIES || pos.Instrument.InstrType == InstrumentTypes.ETF) && !pos.Instrument.CFD) // #38604
                {
                    uBal.BlockedForStocksAccountCurrency += pos.OpenPrice * pos.Amount * pos.Instrument.LotSize * pos.OpenCrossPrice;
                }

                const rp = pos.Account.RiskPlan;
                if (pos.Instrument !== null && rp !== null) {
                    const item = rp.GetRisksForInstrument(pos.Instrument.GetInteriorID());

                    let marginTypes = -1;
                    if (item !== null && item.cache[pos.ProductType] !== null && item.cache[pos.ProductType] !== undefined) {
                        marginTypes = item.cache[pos.ProductType].CalculateMarginType;
                    }

                    // #82717
                    if (marginTypes == MarginTypes.MARGIN_CUSTOM_COEF_WITH_FIXED_VALUE) {
                        let value = pos.OpenPrice * pos.Amount * pos.Instrument.LotSize * pos.OpenCrossPrice;
                        if (pos.BuySell == OperationType.Sell) {
                            value = -value;
                        }

                        uBal.BlockedForStocks += value;
                    } else if (marginTypes === MarginTypes.MARGIN_STOCK) {
                    // Бабаев: #62999
                    // Blocked for stocks должен расчитываться только для открытых Long позиций с типом маржи Stocks pre-paid. Раньше не проявлялось потому что не было возможности открыть шорт позицию.
                        if (pos.BuySell !== OperationType.Sell) {
                            uBal.BlockedForStocks += pos.OpenPrice * pos.Amount * pos.Instrument.LotSize * pos.OpenCrossPrice;
                        }

                        // Бабаев: #62868 - для Stocks pre-paid считаем только buy-позиции
                        if (pos.BuySell !== OperationType.Sell) {
                            uBal.StockValue += pos.CurPriceClose * pos.Amount * pos.Instrument.LotSize * (this.CrossRateCache.GetCrossPriceExp1Exp2(pos.Instrument.Exp2, pos.Account.BaseCurrency) - Math.abs(pos.GetMarkup()));
                        }
                    }
                }

                //
                // +++ Asset support
                //
                if (pos.Account.AccountType == AccountType.MultiAsset && pos.Instrument != null) {
                    const assetName = Instrument.GetAssetNameCorrect(pos.Instrument, pos.BuySell == OperationType.Buy);

                    let userBalanceField = null;
                    userBalanceField = uBal.AssetBalances[assetName];
                    if (!userBalanceField) {
                        userBalanceField = new UserBalancesAssetBalanceFields();
                        uBal.AssetBalances[assetName] = userBalanceField;
                    }

                    // info
                    userBalanceField.OpenPosAmount += pos.Amount;
                    userBalanceField.OpenPosNumber += 1;
                }

                // PositionsValue
                if (pos.Instrument.LastQuote !== null && pos.Account !== null) {
                    const sp = this.GetSpreadPlan(pos.Account);
                    const lastPrice = (pos.BuySell == OperationType.Buy ? pos.Instrument.LastQuote.BidSpread_SP_Ins(sp, pos.Instrument) : pos.Instrument.LastQuote.AskSpread_SP_Ins(sp, pos.Instrument));
                    const posVal = pos.Amount * pos.Instrument.LotSize * lastPrice * pos.getCrossPrice() * pos.Account.getCrossPrice();

                    let instrVal = 0;
                    if (uBal.DictPosInstrVal.hasOwnProperty(pos.Instrument)) {
                        instrVal = uBal.DictPosInstrVal[pos.Instrument];
                    }

                    if (pos.BuySell == OperationType.Buy) {
                        instrVal += posVal;
                    } else {
                        instrVal -= posVal;
                    }

                    uBal.DictPosInstrVal[pos.Instrument] = instrVal;
                }

                uBal.positions.push(pos);

                uBal.totalPositionAmount += pos.Amount;
                uBal.averagePositionPrice += pos.Price;
                uBal.NotIsOptionCount += 1;
            }

            pBal.Add(pos);

            if (pos.Account !== null && pos.Amount !== 0 && !pos.isTradePosition) // not counting trades amount (FIFO) #80010 #85827
            {
                const accNumber = pos.Account.AcctNumber;
                // Total position amount
                let totalPositionAmount = 0;
                if (pBal.AccountTotalPositionAmount.has(accNumber)) {
                    totalPositionAmount = pBal.AccountTotalPositionAmount.get(accNumber);
                }

                if (pos.BuySell === OperationType.Buy) {
                    totalPositionAmount += pos.Amount;
                } else if (pos.BuySell === OperationType.Sell) {
                    totalPositionAmount -= pos.Amount;
                }
                pBal.AccountTotalPositionAmount.set(accNumber, totalPositionAmount);

                // Total profit/loss
                let totalProfitLoss = 0;
                if (pBal.AccountTotalProfitLoss.has(accNumber)) {
                    totalProfitLoss = pBal.AccountTotalProfitLoss.get(accNumber);
                }

                // TODO PnL
                const val = pos.getGrossPnL(true);
                if (!isNaN(val) && isFinite(val)) {
                    totalProfitLoss += val;
                }

                pBal.AccountTotalProfitLoss.set(accNumber, totalProfitLoss);

                if (pos.BuySell === OperationType.Buy) {
                    let accountPositionPriceMultiplyAmountBuy = 0;
                    let accountPositionAmountBuy = 0;

                    if (pBal.AccountPositionPriceMultiplyAmountBuy.has(accNumber)) {
                        accountPositionPriceMultiplyAmountBuy = pBal.AccountPositionPriceMultiplyAmountBuy.get(accNumber);
                    }

                    if (pBal.AccountPositionAmountBuy.has(accNumber)) {
                        accountPositionAmountBuy = pBal.AccountPositionAmountBuy.get(accNumber);
                    }

                    accountPositionPriceMultiplyAmountBuy += (pos.Price * pos.Amount);
                    accountPositionAmountBuy += pos.Amount;

                    pBal.AccountPositionPriceMultiplyAmountBuy.set(accNumber, accountPositionPriceMultiplyAmountBuy);
                    pBal.AccountPositionAmountBuy.set(accNumber, accountPositionAmountBuy);
                }

                if (pos.BuySell === OperationType.Sell) {
                    let accountPositionPriceMultiplyAmountSell = 0;
                    let accountPositionAmountSell = 0;

                    if (pBal.AccountPositionPriceMultiplyAmountSell.has(accNumber)) {
                        accountPositionPriceMultiplyAmountSell = pBal.AccountPositionPriceMultiplyAmountSell.get(accNumber);
                    }

                    if (pBal.AccountPositionAmountSell.has(accNumber)) {
                        accountPositionAmountSell = pBal.AccountPositionAmountSell.get(accNumber);
                    }

                    accountPositionPriceMultiplyAmountSell += (pos.Price * pos.Amount);
                    accountPositionAmountSell += pos.Amount;

                    pBal.AccountPositionPriceMultiplyAmountSell.set(accNumber, accountPositionPriceMultiplyAmountSell);
                    pBal.AccountPositionAmountSell.set(accNumber, accountPositionAmountSell);
                }

                // qty для ордеров на sell
                let amountSellOrders = 0;
                for (const key in orderDict) {
                    const order = orderDict[key];
                    if (
                        order.Active &&
                    order.BuySell === OperationType.Sell &&
                    order.Instrument === pos.Instrument &&
                    order.Account === pos.Account) {
                        amountSellOrders += order.AmountRemaining;
                    }
                }
                // TODO. Refactor.
                pos.AvailableForSell = amountSellOrders;
            }

            pos.RecalcAmounts();
        }

        // https://tp.traderevolution.com/entity/105525
        for (const pk in this.PositionDictCorporateAction) {
            this.PositionDictCorporateAction[pk].RecalcAmounts();
        }

        //
        // Orders
        //
        const ordersKeys = Object.keys(this.OrderDict);
        for (let i = 0; i < ordersKeys.length; i++) {
            const ord = this.OrderDict[ordersKeys[i]];

            if (ord.Account != null && (ord.Active || (ord.BindTo && ord.BindTo != '-1') || (ord.PositionId && ord.PositionId != '-1'))) {
                let uBal = userBalanserTable[ord.Account.AcctNumber];
                if (!uBal) {
                    uBal = new UserBalancer(ord.Account);
                    userBalanserTable[ord.Account.AcctNumber] = uBal;
                }

                uBal.OpenOrdAmount += ord.Amount;
                uBal.OpenOrdNumber += 1;

                //
                // +++ Asset support
                //
                if (ord.Account.AccountType == AccountType.MultiAsset && ord.Instrument != null) {
                    const assetName = Instrument.GetAssetNameCorrect(ord.Instrument, ord.BuySell == OperationType.Buy);

                    let userBalanceField = uBal.AssetBalances[assetName];
                    if (!userBalanceField) {
                        userBalanceField = new UserBalancesAssetBalanceFields();
                        uBal.AssetBalances[assetName] = userBalanceField;
                    }

                    // info
                    userBalanceField.OpenOrdAmount += ord.Amount;
                    userBalanceField.OpenOrdNumber += 1;
                }

                if ((ord.Instrument.InstrType == InstrumentTypes.CORPORATE || ord.Instrument.InstrType == InstrumentTypes.EQUITIES || ord.Instrument.InstrType == InstrumentTypes.ETF) && !ord.Instrument.CFD) // #38604
                {
                    uBal.BlockedForStocksAccountCurrency += ord.Price * ord.Amount * ord.getCrossPrice() * ord.Account.getCrossPrice();
                }
            }

            if (ord.Account != null) {
                if (ord.Active && ord.Status !== OrderStatus.PENDING_NEW) {
                    const InsID = ord.Instrument.GetInteriorID();
                    let pBal = this.InstrumentBalancerTable[InsID];
                    if (!pBal) {
                        pBal = new InstrumentBalancer(ord.Instrument);
                        this.InstrumentBalancerTable[InsID] = pBal;
                    }
                }
            }
        }

        try {
            //
            // AlexB Optimization: внутри getTotalMargin()
            // каждый раз запрашивается список позиций. Получим его один раз и передадим как параметр
            //
            for (const acc in this.Accounts) {
                const account: any = this.Accounts[acc];

                // this rules should be got for current user -u
                const marginWarn = account.WarningLevel; // RuleSet.getRuleFloatValue(RulesSet.VALUE_MARGINCALL, null);
                // const marginTrading = account.TradingLevel; // RuleSet.getRuleFloatValue(RulesSet.VALUE_MARGINTRADING, null);
                const stopOut = account.MarginLevel; // RuleSet.getRuleFloatValue(RulesSet.VALUE_STOPOUT, null);

                let uBal = userBalanserTable[account.AcctNumber];

                // AlexB optimization: не будем создавать лишний объект, используем готовый с теми же нулевыми значениями
                if (uBal == null) {
                    uBal = this.EmptyUserBalancer;
                }
                //    userBalanserTable[account.AccountNumber] = (uBal = new UserBalancer(account));

                account.Profit = uBal.Profit;
                account.LossForUnsettled = uBal.LossForUnsettled;
                account.ProfitBase = uBal.ProfitBase;
                account.ProfitNet = uBal.ProfitNet;
                account.ProfitForMargin = uBal.ProfitForMargin;
                account.ProfitForUnsettled = uBal.ProfitForUnsettled;
                account.OnlyProfitForWithdrawalAvailable = uBal.OnlyProfitForWithdrawalAvailable;
                account.ProfitNetBase = uBal.ProfitNetBase;
                account.OpenOrdAmount = uBal.OpenOrdAmount;
                account.OpenPosAmount = uBal.OpenPosAmount;
                account.OpenPosNumber = uBal.OpenPosNumber;
                account.OpenOrdNumber = uBal.OpenOrdNumber;
                account.BlockedForStocksAccountCurrency = uBal.BlockedForStocksAccountCurrency;
                account.BlockedForStocks = uBal.BlockedForStocks;
                account.StockValue = uBal.StockValue;
                account.OptionValue = uBal.OptionValue;
                account.OptionPremium = uBal.OptionPremium;
                account.PositionsValue = uBal.PositionsValue;

                //
                // Asset support
                //
                if (account.AccountType == AccountType.MultiAsset) {
                    for (const assBal in account.assetBalances) {
                        const assBalance = account.assetBalances[assBal];
                        //
                        // !assBalance.IsEmpty - для оптимизации
                        // Андрей сказал, что это признак того, что нет ордеров/позиций
                        //
                        const userBalancesAssetFields = uBal.AssetBalances[assBalance.Asset.Name];
                        if (!assBalance.IsEmpty && userBalancesAssetFields) {
                            assBalance.OpenOrdAmount = userBalancesAssetFields.OpenOrdAmount;
                            assBalance.OpenPosAmount = userBalancesAssetFields.OpenPosAmount;
                            assBalance.OpenPosNumber = userBalancesAssetFields.OpenPosNumber;
                            assBalance.OpenOrdNumber = userBalancesAssetFields.OpenOrdNumber;
                        } else {
                            assBalance.OpenOrdAmount = 0;
                            assBalance.OpenPosAmount = 0;
                            assBalance.OpenPosNumber = 0;
                            assBalance.OpenOrdNumber = 0;
                        }
                    }
                }

                account.OpenPosExposition = uBal.OpenPosExposition;

                // let posVal = 0;
                // for (const instr in uBal.DictPosInstrVal.Keys) {
                //     posVal += Math.abs(uBal.DictPosInstrVal[instr]);
                // }

                // account.PositionsValue = posVal;

                // +++ antonV
                account.averagePositionPrice = uBal.averagePositionPrice / uBal.NotIsOptionCount;
                account.totalPositionAmount = uBal.totalPositionAmount;

                uBal.MarginWarning = ((account.MinMargin + account.BlockedForOrders) / marginWarn * 100);
                uBal.StopOut = (((account.MinMargin + account.BlockedForOrders) / stopOut) * 100);

                account.MarginWarning = uBal.MarginWarning;

                const common = account.Balance + uBal.ProfitForMargin;
                if (common != 0) {
                    account.CurrMargin = (((account.MinMargin + account.BlockedForOrders) / (common)) * 100);
                } else {
                    account.CurrMargin = 0;
                }

                //
                // +++
                //
                if (account.User != null) {
                    account.User.marginUsed += account.MinMargin;
                    account.User.curBalance += account.Balance + account.Profit;
                }

                let profitForWA = account.Profit;
                if (profitForWA > 0) {
                    profitForWA = 0;
                }

                // +++ Пересчитать today balance
                if (uBal.positions.length > 0) {
                    account.StockValueByBasis = 0;// GetStockValue(account, StockValueMode.BeginBalance, uBal.positions);
                    account.StockValueWithoutPnl = 0;// GetStockValue(account, StockValueMode.StockValueWithoutPnl, uBal.positions);
                    account.StockValueDiscount = 0;// GetStockValue(account, StockValueMode.StockValueDiscount, uBal.positions);
                } else {
                    account.StockValueByBasis = 0;
                    account.StockValueWithoutPnl = 0;
                    account.StockValueDiscount = 0;
                }
                uBal.positions.length = 0;

            // users[count++] = account;
            }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
        }

        // clear  prevTable
        if (prevTable) {
            const IBKeys = Object.keys(prevTable);

            for (let i = 0, len = IBKeys.length; i < len; i++) {
                const bal = prevTable[IBKeys[i]];
                const instrument = bal.instrument;

                instrument.PositionsTotalInfo.Clear();
            }
        }

        const IBKeys = Object.keys(this.InstrumentBalancerTable);

        for (let i = 0, len = IBKeys.length; i < len; i++) {
            const bal = this.InstrumentBalancerTable[IBKeys[i]];
            const instrument: Instrument = bal.instrument;

            instrument.PositionsTotalInfo.accountTotalPositionAmount = bal.AccountTotalPositionAmount;
            instrument.PositionsTotalInfo.accountTotalProfitLoss = bal.AccountTotalProfitLoss;
            instrument.PositionsTotalInfo.accountAveragePositionPriceWithAmountBuy = bal.AccountPositionPriceMultiplyAmountBuy;
            instrument.PositionsTotalInfo.accountAveragePositionPriceWithAmountSell = bal.AccountPositionPriceMultiplyAmountSell;
            instrument.PositionsTotalInfo.accountPositionAmountBuy = bal.AccountPositionAmountBuy;
            instrument.PositionsTotalInfo.accountPositionAmountSell = bal.AccountPositionAmountSell;
        }

        this.OnRecalculatedBalances.Raise();
    }

    public CalculateOptionValue (instr, quantity, buySell, currentPrice, assetName): number {
        if (!instr?.isOptionSymbol || (instr.OptionTradingStyle != OptionTradingStyle.Premium && instr.OptionTradingStyle != OptionTradingStyle.MarkToMarket)) {
            return 0;
        }

        const amount = (buySell === OperationType.Buy ? 1 : -1) * quantity * instr.LotSize * instr.GetTickCost();

        return currentPrice * amount * this.CrossRateCache.GetCrossPriceExp1Exp2(instr.Exp2, assetName);
    }

    public AccountOperationRequest (data): any {
        return Connection.AccountOperationRequest(data);
    }

    public TryGetCrossratesPlan (id): CrossratesPlan {
        return this.CrossratesPlans.GetPlanById(id);
    }

    public TryGetRiskPlan (id): RiskPlan {
        return this.riskPlanDict[id] || null;
    }

    public TryGetSpreadPlan (id): SpreadPlan {
        return this.SpreadPlans[id] || null;
    }

    public TryGetCommissionPlan (id): CommissionPlan {
        return this.CommissionPlans[id] || null;
    }

    public TryGetSwapPlan (id): any {
        return null; /* TODO */
    }

    public TryGetChargingPlan (id): any {
        return null; /* TODO */
    }

    // Метод мусорка ебаная
    public FindUnderlier (instrumentId: string, route: number, underlierTradableId: string): Instrument {
        let instruments = this.GetInstrumentsByInstrumentIdRouteId(instrumentId, route);
        if (instruments.length === 0) {
            instruments = this.GetInstrumentsByInstrumentId(instrumentId);
        }
        if (instruments.length === 0) {
            return null;
        }

        if (isValidString(underlierTradableId)) {
            instruments = instruments.filter(instrument => instrument.InstrumentTradableID.toString() === underlierTradableId && !instrument.IsContiniousContract);
            if (instruments.length === 0) {
                return null;
            }
        }

        const firstInstrument = instruments[0];
        instruments = instruments.filter(instrument => instrument.RouteIsTradable() && !instrument.IsContiniousContract);
        if (instruments.length > 0) {
            return instruments[0];
        } else {
            return firstInstrument;
        }
    }

    public GetInstrumentsByInstrumentId (id: string): Instrument[] {
        const instruments = this.InstrumentsByIdCache.get(id);
        if (isNullOrUndefined(instruments)) {
            return [];
        }
        return instruments;
    }

    public GetInstrumentsByInstrumentIdRouteId (id: string, route: number): Instrument[] {
        return this.GetInstrumentsByInstrumentId(id).filter(instrument => instrument.Route === route);
    }

    public GetInstrumentsByContractID (id): any // return insArr where ins.NeedToHide == false && ins.ContractID == id, where id is method argument
    {
        if (this._cachedInsListByContractID[id]) {
            return this._cachedInsListByContractID[id];
        }

        const allIns = this.Instruments;
        for (const insID in allIns) {
            const ins = allIns[insID];
            if (ins.ContractID == id && !ins.NeedToHide()) {
                if (!this._cachedInsListByContractID[id]) {
                    this._cachedInsListByContractID[id] = [];
                }

                this._cachedInsListByContractID[id].push(ins);
            }
        }

        return this._cachedInsListByContractID[id];
    }

    public userHasSameCommissionAndSwapPlanOnAllAccounts (): boolean {
        if (this._userHasSameCommissionAndSwapPlanOnAllAccounts === null) {
            const accArr = Object.values(this.Accounts);
            const commissionPlanIds = accArr.map((account: Account) => account.CommisionId);
            const swapPlanIds = accArr.map((account: Account) => account.SwapPlanId);

            this._userHasSameCommissionAndSwapPlanOnAllAccounts =
                ArrayUtils.HasUniformValue(commissionPlanIds) && ArrayUtils.HasUniformValue(swapPlanIds);
        }

        return this._userHasSameCommissionAndSwapPlanOnAllAccounts;
    }

    public userHasSameCurrencyOnAllAccounts (): any {
        return this._userHasSameCurrencyOnAllAccounts;
    }

    public cacheUserHasSameCurrencyOnAllAccounts (): void {
        const baseCurrencies = Object.values(this.Accounts).map((account: Account) => account.BaseCurrency);
        const uniqueCurrencies = new Set(baseCurrencies);

        this._userHasSameCurrencyOnAllAccounts = uniqueCurrencies.size === 1;
    }

    public getUserBaseCurrency (): string // правила получения валюты аккаунта: https://ibb.co/HVtD0gw
    {
        const accs = this.Accounts;
        const accKeys = Object.keys(accs);
        let currency = '';

        if (accKeys.length == 1 && accs[accKeys[0]].AccountType === AccountType.MultiAsset) {
            return this.baseCurrency;
        } // пункт 2 выполняется тут

        for (let i = 0; i < accKeys.length; i++) {
            const accCurrency = accs[accKeys[i]].BaseCurrency;
            if (currency.length && accCurrency != currency) // у юзера несколько аккаунтов с разными валютами
            {
                const positions = this.PositionDict;
                const posKeys = Object.keys(positions);

                currency = '';

                for (let j = 0; j < posKeys.length; j++) {
                    const positionCurrency = positions[posKeys[j]].Account.BaseCurrency;
                    if (currency.length && positionCurrency != currency) {
                        return this.baseCurrency;
                    } // пункт 4 (у юзера несколько аккаунтов с разными валютами и позиции открыты в разных валютах -> вовзвращем валюту сервера)
                    else {
                        currency = positionCurrency;
                    }
                }
                // на случай отсутствия позиций
                return currency || this.baseCurrency; // пункт 5 выполняется тут (все позиции открыты в одной валюте ее и возвращаем)
            } else {
                currency = accCurrency;
            }
        }

        return currency; // пункт 1,3 выполняется тут
    }

    public getFilledOrdersCurrency (): string // правила получения валюты аккаунта: https://ibb.co/HVtD0gw
    {
        const accs = this.Accounts;
        const accKeys = Object.keys(accs);
        let currency = '';

        if (accKeys.length == 1 && accs[accKeys[0]].AccountType === AccountType.MultiAsset) {
            return this.baseCurrency;
        } // пункт 2 выполняется тут

        let accsMask = {};

        if (accKeys.length > 1) {
            let len = 0;
            let keys = null;
            let useArray = true;
            if (this.filledOrdersArray.length > 0) {
                len = this.filledOrdersArray.length;
            } else {
                keys = Object.keys(this.newTradesDict);
                useArray = false;
                len = keys.length;
            }

            for (let j = 0; j < len; j++) {
                const obj = useArray ? this.filledOrdersArray[j] : this.newTradesDict[keys[j]];
                const filledOrder = obj.Account.BaseCurrency;
                accsMask[filledOrder] = true;
            }

            keys = Object.keys(accsMask);
            if (keys.length > 1) {
                return this.baseCurrency;
            } // пункт 4 (у юзера несколько аккаунтов с разными валютами и позиции открыты в разных валютах -> вовзвращем валюту сервера)
            else if (keys.length) {
                currency = keys[0];
            } // пункт 5 выполняется тут (все позиции открыты в одной валюте ее и возвращаем)
            else {
                accsMask = {}; // 3 выполняется тут (все позиции открыты в одной валюте ее и возвращаем)
                for (let i = 0; i < accKeys.length; i++) {
                    accsMask[accs[accKeys[i]].BaseCurrency] = true;
                }

                const keys = Object.keys(accsMask);
                if (keys.length > 1) {
                    return this.baseCurrency;
                } else if (keys.length > 0) {
                    currency = keys[0];
                }
            }
        } else {
            currency = accs[accKeys[0]].BaseCurrency;
        } // пункт 1 выполняется тут

        return currency;
    }

    public ProcessBrokerMessage (msg): void {
        if (!msg) return;

        const histBM = new HistoricalBM();
        histBM.UpdateByBM(msg);

        this.OnProcessBrokerMessage.Raise(histBM);

        if (this.Loaded && msg.ShowPopUp === false) {
            this.OnBMCounterMsgReceived.Raise(msg.ID, NotificationsCounterChangedEvent.AddOne);
            return; // Приходит 51 месседж с POP_UP = false, сразу же кладем его в панель Notifications как непрочитанный и увеличиваем счетчик. TerceraBrokerMessageScreen - не отображаем
        }

        if (msg.ShowPopUp !== false) {
            brokerMessageScreenHandler.Show(msg);
        }
    }

    public SendChangeExerciseStatusRequest (positionID, status): any {
        Connection.vendor.SendExerciseRequest(positionID, status);
    }

    public ExerciseOptionsByPosition (position, placedFrom): any {
        if (!position?.PositionId) return;

        this.GenerateExerciseRequestMessageDealTicket(position, false, placedFrom);

        return this.SendChangeExerciseStatusRequest(position.PositionId, Position.OPTION_EXERCISE_STATUS.PENDING_EXERCISE);
    }

    public CancelExerciseByPosition (position, placedFrom): any {
        if (!position?.PositionId) return;

        this.GenerateExerciseRequestMessageDealTicket(position, true, placedFrom);

        return this.SendChangeExerciseStatusRequest(position.PositionId, Position.OPTION_EXERCISE_STATUS.EXERCISED);
    }

    public SendMarginRequest (parameters: MarginInfoParameters): any {
        const params = {
            accountId: parameters.account.userPin,
            instrumentID: parameters.instrument.InstrumentTradableID,
            routeID: parameters.instrument.Route,
            operation: undefined,
            amount: parameters.amountLots,
            price: parameters.limitPrice,
            stopPrice: parameters.stopPrice,
            orderType: parameters.orderType,
            productType: parameters.productType,
            leverage: parameters.leverageValue
        };
        return Connection.vendor.SendMarginRequest(params);
    }

    public async SendMultiMarginRequestAsync (parametersArray: MarginInfoParameters[], type: number = 0): Promise<IMarginResponse | undefined | null> {
        const paramsArray = [];
        for (let i = 0; i < parametersArray.length; i++) {
            const parameters = parametersArray[i];
            const params = {
                accountId: parameters.account.userPin,
                instrumentID: parameters.instrument.InstrumentTradableID,
                routeID: parameters.instrument.Route,
                operation: parameters.isLong ? 1 : 2,
                amount: parameters.amountLots,
                price: parameters.limitPrice,
                stopPrice: parameters.stopPrice,
                orderType: parameters.orderType,
                productType: parameters.productType,
                leverage: parameters.leverageValue
            };
            paramsArray.push(params);
        }
        const results = await Connection.vendor.SendMultiMarginRequestAsync(paramsArray, type);
        if (isValidArray(results)) {
            return results[results.length - 1];
        } else {
            return undefined;
        }
    }

    public SubscribeToNewsIfNeed (caller = null): boolean // Подписка на новости происходит при: 1) Открытии панели на воркспейсе. 2) Логине(перелогине) - если панель уже есть на воркспейсе.
    {
        if (this.FirstNewsSubscription) {
            this.FirstNewsSubscription.push(caller);
        }

        if (this.SubscribedToNewsRoute) {
            return false;
        } // чтобы заново подписаться нужно сперва выполнить отписку

        this.SubscribedToNewsRoute = {};
        this.FirstNewsSubscription = [caller];
        this.SendNewsSubscribeMessage(SubscribeType.SUBSCRIBE);

        return true;
    }

    public UnsubscribeToNewsIfNeed (fromClosePanel): void {
        if (!this.SubscribedToNewsRoute) {
            return;
        } // подписки нет - отписываться не от чего

        if (!fromClosePanel || workspaceManagerHandler.numberOfPanelByType(PanelNames.NewsPanel) == 1) // Отписка от новостей происходит при: 1) Логаут из приложения (fromClosePanel == false) 2) Закрытии последней открытой панели News (в процессе dispose потому кол-во NewsPanel еще равно единице)
        {
            this.SendNewsSubscribeMessage(SubscribeType.UNSUBSCRIBE);
            this.SubscribedToNewsRoute = null;
            this.News = new Object(); // эта очистка необходима при переходе между Workspace-ами
            this.FirstNewsSubscription = null;
        }
    }

    public SendNewsSubscribeMessage (subscribeType): void {
        const newsRoutesIDs = this.getNewsRoutesIDs();

        for (let i = 0; i < newsRoutesIDs.length; i++) {
            Connection.vendor.SendNewsSubscribeMessage([newsRoutesIDs[i]], subscribeType);
        }
    }

    public ProcessNewsSubscribeResponse (msg): void {
        if (!msg?.RouteIdsArr?.length) {
            return;
        }

        const routeID = msg.RouteIdsArr[0];
        let newSubscribe = false;

        if (this.SubscribedToNewsRoute) {
            this.SubscribedToNewsRoute[routeID] = !this.SubscribedToNewsRoute[routeID];
            if (this.SubscribedToNewsRoute[routeID]) { newSubscribe = true; }
        }

        if (newSubscribe) {
            this.clearNewsCache();
            this.SendNewsRequestMessage(routeID)
                .then((result) => {
                    if (this.FirstNewsSubscription) {
                        for (const c of this.FirstNewsSubscription) { c(); }
                    }
                    return true;
                });
        }
    }

    public static NEWS_REQUEST_NUMBER = 1000; // Запрашиваемое количество новостей в панеле = 1000 штук по каждому новостному роуту - хардкод. Каждая последующая новость которая приходит в клиент в реал-тайм, перезаписывает более старую.

    public SendNewsRequestMessage (routeID, from?: Date, to?: Date): any {
        const newsNumber = _DataCache.NEWS_REQUEST_NUMBER;
        const startTime = from || new Date();
        const endTime = to || new Date();

        if (!from) {
            startTime.setHours(0); // Запрашиваются новости для Web приложения за сегодня
            startTime.setMinutes(0);
            startTime.setSeconds(0);
            startTime.setMilliseconds(0);
        }

        return Connection.vendor.SendNewsRequestMessage(routeID, newsNumber, startTime, endTime)
            .then((msg) => {
                // this.News = new Object();
                this.ProcessNewsMessage(msg, true, true);
                return true;
            });
    }

    public clearNewsCache (): void {
        this.News = new Object();
    }

    public ProcessNewsMessage (msg, needClear = false, skipEvent = false): void {
        if (!msg?.NewsGroupsArr?.length) {
            return;
        }

        const news = msg.NewsGroupsArr;
        for (let i = news.length - 1; i >= 0; i--) {
            const newsIt = news[i];
            const newsID = newsIt.getNewsID();
            const routeID = newsIt.getRouteID();

            let needCreate = false;

            if (needClear) {
                this.News[routeID] = null;
                needClear = false;
            }

            if (!this.News[routeID]) {
                this.News[routeID] = [];
                this.News[routeID].Cash = {};
                needCreate = true;
            }
            const newsStorItem = this.News[routeID];
            let newsObj = newsStorItem.Cash[newsID];

            if (!needCreate && newsObj) {
                newsObj.UpdateByMsg(newsIt);
                continue;
            }

            newsObj = new News(newsIt);

            if (newsStorItem.length == _DataCache.NEWS_REQUEST_NUMBER) {
                const removedNews = newsStorItem.splice(0, 1)[0];
                this.OnRemoveNewsEvent.Raise(removedNews.GetID());
            }

            newsStorItem.Cash[newsID] = newsObj;
            newsStorItem.push(newsObj);

            if (!skipEvent) {
                this.OnAddNewsEvent.Raise(newsObj);
            }
        }
    }

    public SendTradingSignalRequest (): any {
        this.OnTradingSignalRequestSend.Raise(); // для обновления счетчика идей если перешли на юзера где идей нет

        Connection.vendor.SendTradingSignalRequest();
    }

    public ProcessTradingSignalMessage (msg): void {
        if (!msg) {
            return;
        }

        this.getInstrumentByInstrumentTradableID_NFL(msg.TradableInstrumentId, msg.RouteId).then(
            function (ins) {
                const trSystem = this.tradingSystems[msg.TradingSystemId];
                const idea = new Idea(msg, ins, trSystem);
                const ideaID = idea.tradingSignalId;

                if (ins && !this.tradingIdeas[ideaID]) {
                // this.GenerateDealTicketFromTradingIdea(idea)    // comment out due to #112700

                    this.tradingIdeas[ideaID] = idea;
                    this.OnTradingIdeaAdd.Raise(idea);
                }
            }.bind(this));
    }

    public ProcessTradingSignalCancelMessage (msg): void {
        if (!msg) return;

        const ideaID = msg.TradingSignalId;
        const idea = this.tradingIdeas[ideaID];

        if (idea) {
            this.OnTradingIdeaCancel.Raise(idea);

            delete this.tradingIdeas[ideaID];
        }
    }

    public ProcessTradingSystemMessage (msg): void {
        if (!msg) {
            return;
        }

        const tradingSystem = new TradingSystem(msg);
        const id = tradingSystem.tradingSystemID;

        let needUpdateRiskDisclosureText = false; // для уже подтвержденных или новых тор.систем придет SubscriptionStrategyMessage по которому произойдет обновление текста, но в случае изменения текста RD неподтвержденной системы нужно обновить текст
        if (this.tradingSystems[id]) {
            const oldTradingSystemData = this.tradingSystems[id];
            needUpdateRiskDisclosureText = !oldTradingSystemData.accepted && oldTradingSystemData.riskDisclosureText != tradingSystem.riskDisclosureText;
        }

        this.tradingSystems[id] = tradingSystem;

        // this.GenerateDealTicketFromTradingIdea(tradingSystem, true)  // commented out due to #116976

        if (needUpdateRiskDisclosureText) {
            this.tradingSystems[id].accepted = false;

            this.OnTradingSystemsChange.Raise(id);
        }
    }

    public ProcessSubscriptionStrategyMessage (msg): void {
        if (!msg) {
            return;
        }

        const tradingSystems = this.tradingSystems;
        const trSystemID = msg.TradingSystemID;
        const trSystemStatusAccepted = msg.StrategySubscriptionStatus == StrategySubscriptionStatus.Accepted;
        const trSystemStatusDeleted = msg.StrategySubscriptionStatus == StrategySubscriptionStatus.Deleted;

        if (!tradingSystems?.[trSystemID]) {
            return;
        }

        if (trSystemStatusDeleted) {
            this.DeleteTradingSystem(trSystemID);
        } else {
            tradingSystems[trSystemID].accepted = trSystemStatusAccepted;

            if (!trSystemStatusAccepted) // #116976
            {
                this.GenerateDealTicketFromTradingIdea(tradingSystems[trSystemID], true);
            }
        }

        this.OnTradingSystemsChange.Raise();
    }

    public ProcessTradingSystemRemoveMessage (msg): void {
        if (!msg || msg.ID === null) {
            return;
        }

        this.DeleteTradingSystem(msg.ID);
    }

    public DeleteTradingSystem (trSystemID): void {
        const tradingSystems = this.tradingSystems;

        if (!tradingSystems?.[trSystemID]) {
            return;
        }

        this.GenerateDealTicketFromTradingIdea(tradingSystems[trSystemID], true, true);

        delete this.tradingSystems[trSystemID];

        this.deleteIdeasForTradingSystem(trSystemID);

        this.OnTradingSystemUnsubscribe.Raise(trSystemID);
    }

    public ProcessCustodialPlanMessage (msg): void {
        this.CustodialPlans.Add(msg);
    }

    public ProcessCrossratesPlanMessage (msg): void {
        this.CrossratesPlans.Add(msg);
    }

    public ProcessFundingRateMarkupPlanMessage (msg): void {
        this.FundingRateMarkupPlans.Add(msg);
    }

    public ProcessRiskRuleWarningMessge (msg): void {
        const header = DirectRiskWarningMessage.RiskRuleWarningHeader;
        const userTitle = Resources.getResource('dealticket.RiskRuleWarning.UserName');
        const accTitle = Resources.getResource('dealticket.RiskRuleWarning.AccountName');
        const message = Resources.getResource('AdditionalProperty.Message');
        if (!msg.AccountID) {
            return;
        }

        const acc = this.GetAccountByNumber(msg.AccountID.toString());
        const newMsg = new DirectReportMessage();
        newMsg.Name = header;
        if (acc !== null) {
            newMsg.Data = new Array(3);
            newMsg.Data[0] = [userTitle, acc.userLogin];
            newMsg.Data[1] = [accTitle, acc.FullAccString];
        }

        newMsg.Data[2] = [message, msg.Text];
        newMsg.TypeId = msg.RiskRuleType;

        if (newMsg.TypeId === DirectRiskWarningMessage.PositionLossLimitRiskRuleType) {
            const strs = msg.Text.match(/\d+/);
            let posId = '';
            if (strs?.length) {
                posId = strs[0];
            }

            msg.Text = Resources.getResource('AdditionalProperty.Position closed because the loss limit was reached').replace('{0}', posId);
            newMsg.Data[2] = [message, msg.Text];

            riskWarningMessageScreenHandler.Show(newMsg);
        }

        if (newMsg.TypeId === DirectRiskWarningMessage.NegativeProjectedBalanceRiskRuleType) {
            const msgText = Resources.getResource('RiskRuleType.NegativeProjectedBalance').replace('{0}', acc.userLogin).replace('{1}', acc.FullAccString);
            newMsg.Data[2][1] = msgText;
        }

        this.HandleReportMessageDirect(newMsg);
    }

    public deleteIdeasForTradingSystem (trSystemID): void // удаление идей по ID торговой системы (для удаления идей при отписке от системы)
    {
        const ideas = this.tradingIdeas;
        const ideaIDs = Object.keys(ideas);

        for (let i = 0; i < ideaIDs.length; i++) {
            const id = ideaIDs[i];
            const idea = ideas[id];

            if (idea.tradingSystemId == trSystemID) {
                delete this.tradingIdeas[id];
            }
        }
    }

    public SendSubscriptionStrategyRequest (tradingSystemID): any {
        Connection.vendor.SendSubscriptionStrategyRequest(tradingSystemID);
    }

    public SendTradingSignalSeenRequest (tradingSignalId): any {
        this.OnTradingIdeaFirstOpen.Raise();

        Connection.vendor.SendTradingSignalSeenRequest(tradingSignalId);
    }

    public ProcessTradingSignalSeenMessage (msg): void // когда идею открывают на другом клиенте
    {
        if (!msg) return;

        const ideaID = msg.TradingSignalId;
        const idea = this.tradingIdeas[ideaID];

        if (idea) {
            this.OnTradingIdeaFirstOpen.Raise();

            idea.wasNotRead = false;
        }
    }

    public ProcesSubscribeResponseMessage (msg): void {
        if (!msg) return;

        const id = msg.InstrumentId;
        const route = msg.Route;
        const ins = id && route ? this.getInstrument(String(id), route) : null;

        if (ins) {
            const delay = msg.Delay;
            const canGetSnapshot = msg.CanGetSnapshot;
            const lvl1Subscription = msg.Service === DirectQuoteMessage.QUOTE_LEVEL1;
            const hasLvl1SubscriptionErrorCode = msg.ErrorCode !== null;

            if (ins.CanGetSnapshot() === null || lvl1Subscription) {
                ins.updateDelayAndCanGetSnapshot(delay, canGetSnapshot, hasLvl1SubscriptionErrorCode);

                const subscriptionItem = this.FQuoteCache.getSubscriptionItem(ins);
                if (subscriptionItem?.HasContiniousContractItem) {
                    const continiousContractItem = subscriptionItem.InstrumentContiniousContractItem;
                    if (continiousContractItem?.instrument) {
                        continiousContractItem.instrument.updateDelayAndCanGetSnapshot(delay, canGetSnapshot, hasLvl1SubscriptionErrorCode);
                    } // #100762 comment
                }
            }
        }
    }

    public SendSnapshotRequestMessage (tradableID, routeID): any {
        Connection.vendor.SendSnapshotRequestMessage(tradableID, routeID);
    }

    public ProcessSnapshotResponse (msg): void {
        if (!msg) {
            return;
        }

        const tId = String(msg.TradableId);
        const routeID = msg.RouteId;
        const ins = this.getInstrument(tId, routeID);

        this.OnSnapshotResponseReceived.Raise();

        if (ins) {
            snapshotScreenHandler.show(ins, msg);
        }
    }

    public getAvailableNumberOfSnapshotRequests (): number {
        return this.getRuleNumberValueForAccount(RulesSet.AVAILABLE_NUMBER_OF_SNAPSHOT_REQUESTS, null, 0);
    }

    public ProcessCommissionPlanMessage (msg): void {
        const commissionPlan = new CommissionPlan(this);
        commissionPlan.Update(msg);

        this.CommissionPlans[msg.Id] = commissionPlan;

        this.UpdateAccountsCommissionPlan();

        this.OnCommissionPlanUpdate.Raise(msg.Id);
    }

    public SendBrokerMessageReport (inputMessage): any // закоментовано, згодом відновлено згідно з #110904 (для urgent BM було додано логіювання у #84587, судячи з усього зараз ця логіка вже є застарілою і непотрібною)
    {
        Connection.vendor.SendBrokerMessageReport(inputMessage, TextMessageType.STATUS_CHANGE);
    }

    public SendBrokerResponseMessage (brokerMsgID, value, userID, userName, responseType = null, clusterNode): any {
        if (responseType === BrokerMessageResponseType.WAS_READ) {
            this.OnBMCounterMsgReceived.Raise(brokerMsgID, NotificationsCounterChangedEvent.RemoveOne);
        }

        return Connection.vendor.SendBrokerResponseMessage(brokerMsgID, value, userID, userName, responseType, clusterNode);
    }

    public SendBrokerMessageHistoryRequest (userID): any {
        return Connection.vendor.SendBrokerMessageHistoryRequest(userID);
    }

    public ProcessBMCounterMessage (msg): void {
        if (msg && !MathUtils.IsNullOrUndefined(msg.UnreadIDs)) {
            this.OnBMCounterMsgReceived.Raise(msg.UnreadIDs);
        }
    }

    public ProcessPriceLimitsMessage (msg): void {
        if (!msg) {
            return;
        }

        const insArr = this.getInstrumentsByTradableID(msg.TradableId);
        for (let i = 0; i < insArr.length; i++) {
            const ins = insArr[i];
            if (msg.HighLimit !== null) {
                ins.Limits.HightLimit = msg.HighLimit;
            }

            if (msg.LowLimit !== null) {
                ins.Limits.LowLimit = msg.LowLimit;
            }
        }
    }

    public SendInformationMessage (storage): void {
        for (let i = 0; i < storage.length; i++) {
            if (Connection?.vendor) {
                Connection.vendor.SendInformationMessage(storage[i]);
            }
        }
    }

    public handleOrderHistoryMessage (msg): void {
        this.FOrderHistoryCache.newMessage(msg);
    }

    public RemovedTradeHistory (ordId): void {
        const arr = this.filledOrdersArray;
        let i = 0;
        while (arr[i]) {
            if (arr[i].OrderId === ordId) {
                arr.splice(i, 1);
            } else {
                i++;
            }
        }
    }

    public getOrderHistoryArray (): OrderHistory[] {
        return this.FOrderHistoryCache.getOrderHistoryArray();
    }

    public ReSubscribe (): void {
        const ok = Object.keys(this.OrderDict);
        let l = ok.length;
        for (let i = 0; i < l; i++) {
            this.OrderDict[ok[i]].SubscribeToQuotes();
        }

        let pk = Object.keys(this.PositionDict);
        l = pk.length;
        for (let i = 0; i < l; i++) {
            this.PositionDict[pk[i]].SubscribeToQuotes();
        }
        // https://tp.traderevolution.com/entity/105525
        pk = Object.keys(this.PositionDictCorporateAction);
        l = pk.length;
        for (let i = 0; i < l; i++) {
            this.PositionDictCorporateAction[pk[i]].SubscribeToQuotes();
        }
    }

    public async getReportOrderBook (startTimeTicks: number, endTimeTicks: number, login?: string) {
        const isAddDaily = DateTimeUtils.IsCurrentTimeInInterval(startTimeTicks, endTimeTicks);
        await this.getReport(AllowedReport.REPORT_ORDERBOOK, startTimeTicks.toString(), endTimeTicks.toString(), isAddDaily, login);
        return this.FOrderHistoryCache.getOrderHistoryArray();
    }

    public async getReport (key, startTimeTicks, endTimeTicks, isAddDaily?: boolean, login?: string): Promise<any[]> {
        if (key == AllowedReport.REPORT_ORDERBOOK && this.OrderHistoryReportCompleted) {
            return await Promise.resolve([]);
        }

        if (key == AllowedReport.REPORT_TRADES && this.FilledOrdersReportCompleted) {
            return await Promise.resolve(this.filledOrdersArray);
        }

        if (key == AllowedReport.REPORT_STATEMENT && this.StatementReportCompleted) {
            return await Promise.resolve(this.StatementArray);
        }

        if (key == AllowedReport.REPORT_MAMACCOUNTSUMMARY && this.MamSummaryReportCompleted) {
            return await Promise.resolve(this.MamSummaryArray);
        }

        const report = this.reportDict[AllowedReport.REPORT_KEY_PREFIX + key] || this.reportDict[AllowedReport.TECHNICAL_REPORT_KEY_PREFIX + key];
        if (!report) {
            return await Promise.resolve([]);
        }

        const pTable: any = {};
        pTable.reportTypeObj = {};
        pTable.mainParamDict = {};
        pTable.reportTypeObj = report;
        // pTable.locale = App.Resources.currentLang;        // https://tp.traderevolution.com/entity/88828

        pTable.mainParamDict.instruments = '';
        pTable.mainParamDict.login = !isNullOrUndefined(login) ? login : '';
        pTable.mainParamDict.fromDate = startTimeTicks;
        pTable.mainParamDict.toDate = endTimeTicks;

        let IsShowLots = 'true';
        if (key == AllowedReport.REPORT_STATEMENT) {
            const inLots = GeneralSettings.View.displayAmountInLots();
            IsShowLots = inLots ? 'true' : 'false';
        }

        pTable.mainParamDict.IsShowLots = IsShowLots;

        pTable.IsTechnical = true;
        try {
            const ansver = await Connection.vendor.GenerateReportPromise(report.keyForServer, pTable);
            return await this.parseReport(key, isAddDaily, ansver);
        } catch {
            const ex = new CustomErrorClass('DataCache error', 'DataCache.getReport', 'getReport -> GenerateReportPromise');
            ErrorInformationStorage.GetException(ex);

            console.error('Report fetch error.');
            return [];
        }
    }

    public async parseReport (key, isAddDaily, msg): Promise<any[]> {
        if (!msg) {
            return [];
        }

        const data = msg.Code === Message.CODE_REPORT_GROUP_RESPONSE
            ? this.getRowsAndColumnsFromReportResponse(msg) // new scheme from DirectMsg
            : this.getRowsAndColumnsFromOldXMLMessage(msg); // old scheme from msg with xmlString

        const rows = data.rows;
        const columns = data.columns;
        const objArr = [];

        const needReqIns = key === AllowedReport.REPORT_ORDERBOOK || key === AllowedReport.REPORT_TRADES || key === AllowedReport.REPORT_MAMACCOUNTSUMMARY;
        let hasEmptyInstr = false;

        for (let i = 0, len = rows.length; i < len; i++) {
            const r = rows[i];
            const C = r.Cells;
            if (C[0].Label === AllowedReport.TOTAL_ROW_LABEL) {
                continue;
            }

            let item = null;

            switch (key) {
            case AllowedReport.REPORT_ORDERBOOK:
                item = new OrderHistory();
                break;
            case AllowedReport.REPORT_TRADES:
                item = new NewTrade(this, null);
                break;
            case AllowedReport.REPORT_STATEMENT:
                item = new Statement();
                break;
            case AllowedReport.REPORT_MAMACCOUNTSUMMARY:
                item = new AccountOperationReport();
                break;
            }

            const eDay = 0; const eMonth = 0; const eYear = 0; const putCall = -1; const strikeprice = -1;
            let InsName = '';
            for (let j = 0, len_j = columns.length; j < len_j; j++) {
                const Label = C[j].Label.trim();
                const Value = C[j].Value;
                switch (columns[j].Name) {
                //                |
                // Orders history |
                //                V
                case 'order_book_account':
                    let account = this.GetAccountByIdOrName(Label);
                    if (account == null) { account = this.GetAccountByNumber(Label); }
                    item.Account = account;
                    break;
                case 'order_book_symbol': InsName = Label; break;
                case 'order_book_orderid': item.OrderNumber = Label; break;
                case 'order_book_route': item.Route = Label; break;
                case 'order_book_modified_by': item.TraderId = Label; break;
                case 'order_book_event': item.eventTypeRaw = AllowedReport.EventFromString(Label); break;
                case 'order_book_quantity': item.Quantity = Value; break;
                case 'order_book_price': item.Price = Value; break;
                case 'order_book_stop_price': item.StopLimit = Value; break;
                case 'order_book_disclosed_qty': item.DisclosedQty = Value; break;
                case 'order_book_datetime': item.Date = item.Time = new Date(parseInt(Label)); break;
                case 'order_book_side': item.BuySell = OrderExecutor.getBuySell(Label); break;
                case 'order_book_type': item.OrderType = OrderExecutor.getOrderTypeByName(Label); break;
                case 'order_book_tif':
                    const tifParts = Label.split(' ');
                    const tifType = OrderTifMap[tifParts[0]];
                    const date = tifParts[1] ? tifParts[1] : null;
                    const tif = new TIF(tifType, date);

                    item.TimeInForce = tif;
                    break;
                case 'tradingmode': item.ProductType = ProductType[Label]; break;
                case 'external_order_id': item.ExternalOrderId = Label; break; // TODO 88232 заменил item.ExternalId на item.ExternalOrderId
                case 'expday': item.ExpDay = Label || eDay; break;
                case 'expmonth': item.ExpMonth = Label || eMonth; break;
                case 'expyear': item.ExpYear = Label || eYear; break;
                case 'derivativetype': item.PutCall = Label || putCall; break;
                case 'strikeprice': item.StrikePrice = Label || strikeprice; break;
                case 'order_book_symbol_type': item.SymbolType = Label; break;

                //               |
                // Filled orders |
                //               V
                case 'account':
                    let acc = this.GetAccountByIdOrName(Label);
                    if (acc == null) { acc = this.GetAccountByNumber(Label); }
                    item.Account = acc;
                    break;
                case 'sender': item.Login = Label; break;
                case 'instrumentname': InsName = Label; break;
                case 'orderid': item.OrderId = Label; break;
                case 'routename': item.Route = Label; break;
                case 'amount': item.Amount = Value; break;
                case 'price': item.Price = Value; break;
                case 'crossprice': item.OpenCrossPrise = Value; break;
                case 'time': item.Time = new Date(parseInt(Label)); ; break;
                case 'profit': item.PnL = Value; break;
                case 'type': item.OrderType = OrderExecutor.getOrderTypeByName(Label); break;
                case 'executionfee': item.Comission = -Value; break; // #89723
                case 'extprice': item.ExternalPrice = Value; break;
                case 'tradevolume': item.TradeVolume = Value; break; // ???????????????
                case 'tradableinstrumentid': item.InstrumentTradableID = Value; break;
                case 'operation': item.BuySell = OrderExecutor.getBuySell(Label); break;
                case 'externalid': item.ExternalTradeId = Label; break; // TODO 88232 заменил item.ExtId на item.ExternalTradeId
                case 'rebates': item.Rebates = Value; break;
                case 'execvenue': item.ExecutionVenue = Label; break;
                case 'exchange': item.TradingExchange = Label; break;
                case 'symbol_type': item.SymbolType = Label; break;
                //            |
                // MAM Summary|
                //            V
                case 'accountid': item.AccountId = Value; break;
                case 'account_name': item.AccountName = Value; break;
                case 'date': item.Date = Value; break;
                case 'operationtypeid': item.OperationTypeId = parseInt(Value); break;
                case 'operationtype_name': item.OperationTypeName = Value; break;
                case 'tradableinstrument_name': item.TradableInstrumentName = Value; break;
                case 'open_price': item.OpenPrice = Value; break;
                case 'close_price': item.ClosePrice = Value; break;
                case 'currency_name': item.CurrencyName = Label; break;
                case 'side': item.IsBuy = Value === 'Buy'; break;
                //            |
                // Common     |
                //            V
                case 'leverage': item.Leverage = Value; break;
                case 'description': item.Description = Label; break;
                // OR
                case 'Description': item.Description = Label; break;
                //            |
                // Statement  |
                //            V
                case 'createdat': item.Date = new Date(parseInt(Label)); break;
                case 'operationtype': item.OperationType = Label; break;
                case 'accountoperationid': item.OperationID = Label; break;
                case 'openprice': item.OpenPrice = Label; break;
                case 'closeprice': item.ClosePrice = Label; break;
                case 'opentime': item.TradeOpeningDate = new Date(parseInt(Label)); break;
                case 'quantity': item.Quantity = Value; break;
                case 'currency': item.Currency = Label; break;
                case 'crossprice': item.FXrate = Label; break;
                case 'positionid': item.PositionID = Label; break;

                case 'order_book_bought': item.boughtString = Label; break;
                case 'order_book_sold': item.soldString = Label; break;

                case 'trades_bought': item.boughtString = Label; break;
                case 'trades_sold': item.soldString = Label; break;

                //                              |
                // Statement and  Filled orders |
                //                              V
                case 'tradeid': item.TradeId = Label; break;

                // default: console.log(columns[j].Name + "    " + Label);
                }
            }
            const _Route = this.getRouteByTextName(item.Route);

            const insStringName = InsName;

            const trId = item.InstrumentTradableID ? item.InstrumentTradableID : InsName;
            const rId = _Route ? _Route.RouteId : item.Route;
            if (rId) {
                InsName = InstrumentUtils.GetFullName(trId.toString(), rId.toString());
            }

            item.Instrument = this.getInstrumentByName(InsName);
            if (item.Instrument == null) {
                if (insStringName) {
                    item.InstrumentStr = insStringName;
                }
                hasEmptyInstr = true;
            }

            if (key === AllowedReport.REPORT_TRADES) {
                if (item.OpenCrossPrise !== 0) {
                    item.PnL = item.PnL / item.OpenCrossPrise;
                    item.Comission = item.Comission / item.OpenCrossPrise;
                // rebates
                }
            }

            if (item.BuySell === -1) {
                continue;
            }

            objArr.push(item);
        }

        objArr.sort(function (a, b) { return a.Date > b.Date ? 1 : -1; });

        const insPromises = [];
        const reqsObjArr = {};

        const finishFunc = (key, objArr) => {
            if (key == AllowedReport.REPORT_ORDERBOOK) {
                this.FOrderHistoryCache.addReportHistory(objArr, isAddDaily);
                this.OrderHistoryReportCompleted = true;
            } else if (key == AllowedReport.REPORT_TRADES) {
                this.filledOrdersArray = objArr;
                this.FilledOrdersReportCompleted = true;
                this.filledOrdersCurrency = this.getFilledOrdersCurrency();
            } else if (key == AllowedReport.REPORT_STATEMENT) {
                this.StatementArray = objArr;
                this.StatementReportCompleted = true;
            } else if (key == AllowedReport.REPORT_MAMACCOUNTSUMMARY) {
                this.MamSummaryArray = objArr;
                this.MamSummaryReportCompleted = true;
            }
        };

        if (needReqIns && hasEmptyInstr) {
            for (let i = 0; i < objArr.length; i++) {
                const item = objArr[i];
                if (item.Instrument) {
                    continue;
                }

                const trId = item.InstrumentTradableID;
                const _Route = this.getRouteByTextName(item.Route);
                const rId = _Route ? _Route.RouteId : item.Route;
                if (!trId) {
                    continue;
                }

                const InsName = InstrumentUtils.GetFullName(trId.toString(), rId?.toString());

                const exist = !!reqsObjArr[InsName];
                if (!exist) {
                    reqsObjArr[InsName] = [];
                }

                const arr = reqsObjArr[InsName];
                arr.push(item);

                reqsObjArr[trId] = arr;

                if (exist) {
                    continue;
                }

                const tmpR = this.getInstrumentByInstrumentTradableID_NFL(trId, rId, null, true);
                insPromises.push(tmpR);
            }

            return await Promise.all(insPromises).then(function (instruments) {
                for (let i = 0; i < instruments.length; i++) {
                    const ins = instruments[i];
                    if (!ins) {
                        continue;
                    }

                    const key = ins.GetInteriorID();

                    const arrI = reqsObjArr[key] || reqsObjArr[ins.InstrumentTradableID];
                    if (!arrI) {
                        continue;
                    }

                    for (let j = 0; j < arrI.length; j++) {
                        arrI[j].Instrument = ins;
                    }
                }

                finishFunc(key, objArr);

                return objArr;
            });
        }

        finishFunc(key, objArr);

        return objArr;
    }

    public getRowsAndColumnsFromOldXMLMessage (reportResponseMessage) // From XMLString
    {
        if (!reportResponseMessage) {
            return { rows: [], columns: [] };
        }

        const parser = new DOMParser();
        const xml = parser.parseFromString(reportResponseMessage.xmlString, 'text/xml');
        const table = xml.querySelector('table');
        const th = table.querySelector('th');
        const trArr = table.querySelectorAll('tr');

        // TODO. Remove jQuery.
        // const xml = $.parseXML(reportResponseMessage.xmlString);
        // const table = xml.childNodes[0];
        // const th = table.getElementsByTagName('th')[0];
        // const trArr = table.getElementsByTagName('tr');

        if (!th) {
            return { rows: [], columns: [] };
        }

        const columns = [];

        const thChildren = th.childNodes;
        for (let i = 0, len = thChildren.length; i < len; i++) {
            const headerItem = thChildren[i] as Element;
            let type = null;
            if (!headerItem.getAttribute) {
                continue;
            }

            switch (headerItem.getAttribute('type')) {
            case 'FLOAT':
            case 'NUMBER':
                type = QuickTableComparingType.Double;
                break;
            case 'INTEGER':
                type = QuickTableComparingType.Int;
                break;
            case 'DATE':
                type = QuickTableComparingType.DateTime;
                break;
            default:
                type = QuickTableComparingType.String;
            }

            columns.push({
                Type: type,
                Name: headerItem.textContent
            });
        }

        const rows = [];

        for (let i = 0, len = trArr.length; i < len; i++) {
            const tr = trArr[i];
            const cells = [];
            rows.push({ Cells: cells });

            const tdArr = tr.childNodes;
            for (let j = 0, j_len = tdArr.length; j < j_len; j++) {
                const td = tdArr[j];
                const rawVal = td.textContent;
                const val = this.getReportValueByType(rawVal, columns[j].Type);

                cells.push({ Label: rawVal.toString(), Value: val });
            }
        }

        return {
            rows,
            columns
        };
    }

    public getRowsAndColumnsFromReportResponse (msg) {
        const table = msg.Tables?.length ? msg.Tables[0] : null;

        if (!table) return null;

        const columns: any[] = table.ReportColumns;
        const rows = [];
        const reportRows = table.ReportRows;

        for (let i = 0, len = reportRows.length; i < len; i++) {
            const reportRow = reportRows[i];
            const cells = [];
            rows.push({ Cells: cells });

            for (let j = 0; j < reportRow.length; j++) {
                const rawVal = reportRow[j];
                const val = this.getReportValueByType(rawVal, columns[j].Type);

                cells.push({ Label: rawVal.toString(), Value: val });
            }
        }

        return {
            rows,
            columns
        };
    }

    public getReportValueByType (rawVal, type): number {
        let val = null;

        switch (type) {
        case QuickTableComparingType.Double:
            val = parseFloat(rawVal);
            break;
        case QuickTableComparingType.Int:
        case QuickTableComparingType.Long:
            val = parseInt(rawVal);
            break;
        default:
            val = rawVal;
        }

        return val;
    }

    public IsSLTP (order: Order): boolean {
        return !order.Active || (order.BoundTo != null && this.getPositionByBindTo(order.BoundTo) != null);
    }

    /// <summary>
    /// Поиск ассетбаланса, правильный его пользовать везде
    /// </summary>
    public GetAssetBalanceCorrect (account: Account | null, instrument: Instrument | null, isBuy): AssetBalance | null {
        if (account === null) {
            return null;
        }

        return account.GetAssetBalanceCorrect(instrument, isBuy);
    }

    /// <summary>
    /// найти parent позицию для сл или тп, основываясь на ссылке bindto
    /// для ван позишн - нужно искать через getPositionByOpenOrderId.
    /// для мультипозиций - можно искать getPositionByOpenOrderId и через positions[]
    /// </summary>
    public getPositionByBindTo (BoundTo): Position | null {
        if (!BoundTo || BoundTo == '-1') {
            return null;
        }
        /// Почему я не использую getPositionByOpenOrderId вместо этого метода
        /// 1. чтобы не сломать логику поиска ордеров/позиций
        /// 2. тут positions[] отрабатывает быстрее чем getPositionByOpenOrderId, а у нас большинство поз - мульти.
        const pos: Position = this.PositionDict[BoundTo];
        // if (!pos)
        //     pos = this.getPositionByOpenOrderId(BoundTo);
        return pos || null;
    }
    // TODO
    // DataCache.prototype.getPositionByOpenOrderId (key)
    // {
    //     Position pos = null;
    //     positionsByOpenOrderID.TryGetValue(key, out pos);
    //     return pos;
    // }

    // positionsByOpenOrderID это наш PositionDict

    public ProcessSourceMappingMessage (msg): void {
        this.SourceCollection.UpdateByMessage(msg);
    }

    public GetSourceName (id): string {
        return this.SourceCollection.GetSourceName(id);
    }

    public GetDataSourceInstrument (instrument: Instrument | null): Instrument | null {
        if (!instrument) {
            return null;
        }

        if (instrument.DataSourceInstrument) {
            return instrument.DataSourceInstrument;
        }

        const InstrumentId = instrument.InstrumentTradableID;
        const route = instrument.Route;
        let dataSourceTrInsID = instrument.GetDataSourceTradableId();
        let dataSourceRouteID = instrument.GetDataSourceRouteId();

        if (dataSourceTrInsID == -1) {
            dataSourceTrInsID = InstrumentId;
        }

        if (dataSourceRouteID == -1) {
            dataSourceRouteID = route;
        }

        const dsInteriorID = dataSourceTrInsID + InstrumentUtils.SEPARATOR + dataSourceRouteID;
        const dsIns: Instrument | null = this.Instruments[dsInteriorID] || null;

        // if (dsIns && dsIns.NeedToHide())    // подписываемся на котировки по видимому инструменту
        //     dsIns = this.Instruments[dataSourceTrInsID + DataCache.SEPARATOR + dsIns.TradableRoutesArray[0]]

        if (dsIns) {
            instrument.DataSourceInstrument = dsIns;
        } // кэшируем

        return dsIns;
    }

    public GetInstrumentsByDataSource (instrument: Instrument | null): any {
        if (!instrument) {
            return null;
        }

        const interiorID = instrument.GetInteriorID();

        return this.InstrumentsBySourceRouteIdTradableId[interiorID] || [];
    }

    public SendDataSourceSubscriptionMessage (instrument: Instrument | null, toSubscribe, quoteType): void {
        if (!instrument?.IsDataSourceEnable()) {
            return;
        }

        const dsIns = this.GetDataSourceInstrument(instrument);

        const InstrumentId = instrument.InstrumentTradableID;
        const dataSourceTrInsID = dsIns.InstrumentTradableID;
        const dataSourceRouteID = dsIns.Route;
        const subscriptionActionType = toSubscribe ? SubscribeType.SUBSCRIBE : SubscribeType.UNSUBSCRIBE;

        if (Connection.vendor) // эта проверка необходима для работы Unit-тестов
        {
            Connection.vendor.SendDataSourceSubscriptionMessage(dataSourceTrInsID, dataSourceRouteID, quoteType, subscriptionActionType, InstrumentId);
        }
    }

    public IsPortfolioAllowed (account: Account | null): boolean {
        if (!account) {
            return false;
        }

        return this.isAllowedForAccount(RulesSet.FUNCTION_PORTFOLIO_MANAGEMENT, account);
    }

    public IsPortfolioAllowedForAnyAccount (): boolean {
        const pCache = this.PortfolioCache;
        const accs = this.getAccounts();

        for (const accID in accs) {
            if (this.IsPortfolioAllowed(accs[accID]) && pCache.IsInvestorAccount(accID)) {
                return true;
            }
        }

        return false;
    }

    public AllAccountsAreInvestors (): boolean {
        const pCache = this.PortfolioCache;
        const accs = this.getAccounts();

        if (Object.keys(accs).length === 0) {
            return false;
        }

        for (const accID in accs) {
            if (!this.IsPortfolioAllowed(accs[accID]) || !pCache.IsInvestorAccount(accID)) {
                return false;
            }
        }

        return true;
    }

    public NeedRedirectToPortfolio (): boolean // возвращает true или false в зависимости от того произошел ли редирект на портфолио
    {
        if (!ApplicationInfo.myportfolio && this.AllAccountsAreInvestors()) {
            LocalStorage.setTokenForPortfolio(Connection.vendor.loginParams.accessToken);
            window.location.href = window.location.href + 'myportfolio';

            return true;
        }

        return false;
    }

    public AllowCustomSLTPTriggerPriceForUser (): boolean // #109798
    {
        return this.isAllowedForMyUser(RulesSet.FUNCTION_USER_ALLOW_CUSTOM_SLTP_TRIGGER_PRICE);
    }

    public AllowCustomSLTPTriggerPriceForInstrumentType (insType = null): boolean // #109798
    {
        if (insType == null) {
            return false;
        }

        const instrumentTypesList = [];
        this.FindInstrumentTypes(insType, instrumentTypesList);

        for (const key in this.Instruments) {
            const ins = this.Instruments[key];
            if (ins.IsAllowCustomSLTPTriggerPrice && instrumentTypesList.includes(ins.InstrType)) {
                return true;
            }
        }

        return false;
    }

    public AllowLoggingChanges (): boolean // #111072
    {
        return this.isAllowedForMyUser(RulesSet.ALLOW_CHANGES_LOGGING);
    }

    public GetAllInstrumentsExceptExpired (): any // return object like this.Instruments but with no options whose expiration date is in the past of all  #113208
    {
        const result = {};
        const allIns = this.Instruments;

        for (const id in allIns) {
            const ins = allIns[id];
            if (!ins.ExpDate || ins.ExpDateReal.getTime() >= Date.now()) {
                result[id] = ins;
            }
        }

        return result;
    }

    public GenerateDealTicketFromTradingIdea (data, isTradingSystem, isUnsubscribe?): void {
        if (!data) // data - идея или система
        {
            return;
        }

        isTradingSystem = !!isTradingSystem;
        isUnsubscribe = !!isUnsubscribe;

        const textMsg = Resources.getResource(isTradingSystem
            ? (isUnsubscribe ? 'panel.Ideas.UnsubscribeDealTicketText' : 'panel.Ideas.SubscribeDealTicketText')
            : 'panel.Ideas.DealTicketText') + data.name; // и у идеи и у системы есть name

        const message = { Label: 'Message', Value: textMsg };
        const header = Resources.getResource('panel.Ideas.DealTicketHeader');
        this.NewMessage(new DirectReportMessage(header, [[message.Label, message.Value]]));
    }

    public GenerateExerciseRequestMessageDealTicket (pos, isCancelRequest, placedFrom) {
        if (!pos) {
            return;
        }

        const account = {
            Label: Resources.getResource('reports.Account'),
            Value: pos.Account ? pos.Account.FullAccString : null
        };

        const ins = {
            Label: Resources.getResource('InstrumentDetailsPanel.Symbol'),
            Value: pos.Instrument ? pos.Instrument.DisplayName() : null
        };

        const posID = {
            Label: Resources.getResource('reports.posId'),
            Value: pos.PositionId ? pos.PositionId : null
        };

        // let date = {
        //     Label: Resources.getResource('screen.reports.reportHeaders.date'),
        //     Value: Resources.getResource('reports.' + Utils.getKeyByValue(OrderExecutor.PlacedFrom, placedFrom))
        // }

        const placedFromData = {
            Label: Resources.getResource('reports.Send from'),
            Value: Resources.getResource('reports.' + PlacedFrom[placedFrom])
        };

        const msgRows = [account, ins, posID, placedFromData];
        const len = msgRows.length;
        const resultDataArr = [];

        for (let i = 0; i < len; i++) {
            const lbl = msgRows[i];

            if (lbl && (lbl.Value || lbl.Value === 0)) {
                resultDataArr.push([lbl.Label, lbl.Value]);
            }
        }

        const header = Resources.getResource(isCancelRequest ? 'screen.PositionExerciseCancelDealticket.header' : 'screen.PositionExerciseDealticket.header');

        const reportMessage = new DirectReportMessage(header, resultDataArr);
        reportMessage.SkipOnTicketViewer = true; // чтобы не показывать в TicketViewer

        this.NewMessage(reportMessage);
    }

    public GetYouTubeExternalLinks (): any[] | null // #114288
    {
        return this.ExternalLinksCache ? this.ExternalLinksCache.getYouTubeExternalLinks() : null;
    }

    public isAllowedMutualCloseByPosIdArr (positionsIDArray): boolean // #115483 Mutual closing can be carried out when two positions for the same instrument are selected in the Positions panel.
    {
        if (!positionsIDArray || positionsIDArray.length != 2) {
            return false;
        } // Two positions must be selected;

        const pos1 = this.getPositionById(positionsIDArray[0]);
        const pos2 = this.getPositionById(positionsIDArray[1]);

        if (pos1.Instrument != pos2.Instrument) {
            return false;
        } // Positions to close must be selected for the same instrument;

        if (pos1.BuySell == pos2.BuySell) {
            return false;
        } // These positions must be oppositely directed (i.e. both Long and Short positions must be selected);

        if (pos1.Account != pos2.Account) {
            return false;
        } // All positions must belong to the same account.

        const partialCloseIsForbidden = !this.isAllowedForAccount(RulesSet.FUNCTION_PARTIAL_CLOSE, pos1.Account);
        if (partialCloseIsForbidden && pos1.Amount != pos2.Amount) {
            return false;
        } // In case the partial close is forbidden for this account, then the amount on both sides (Long and Short) must be equal.

        return true;
    }

    public PositionExerciseStatusChanged (posId): void {
        if (messageBoxHandler?.Show) {
            const position = this.getPositionById(posId);
            let infoText = Resources.getResource('screen.PositionExerciseComplete.text');

            infoText = infoText.replace('{1}', position.PositionId).replace('{2}', position.Account.FullAccString);

            const addData = { cantActionBeforeClose: true, hideCloseBtn: true, okText: Resources.getResource('general.messageBox.ok') }; // #94234

            messageBoxHandler.Show(
                Resources.getResource('screen.PositionExerciseComplete.header'),
                infoText,
                messageBoxHandler.msgType.Info, null, null, false, true, null, addData);
        } else {
            this.OnPositionExerciseStatusChanged.Raise(posId);
        }
    }

    public SendCloseAccountRequestMessage (): any {
        return Connection.vendor.SendCloseAccountRequestMessage();
    }

    public SendCloseAccountCancelRequestMessage (closeAccountRequestID): any {
        return Connection.vendor.SendCloseAccountCancelRequestMessage(closeAccountRequestID);
    }

    public ProcessCloseAccountResponseMessage (msg): void {
        const oldCloseAccountRequestID = this.closeAccountRequestID;

        switch (msg.status) {
        case CloseAccountResponseStatus.New:
            this.closeAccountRequestID = msg.closeAccountRequestID;
            break;
        case CloseAccountResponseStatus.Approve:
            this.closeAccountApproved = true;
        case CloseAccountResponseStatus.Rejected:
        case CloseAccountResponseStatus.Cancelled:
            if (msg.closeAccountRequestID === this.closeAccountRequestID) {
                this.closeAccountRequestID = null;
            }
            break;
        }

        if (oldCloseAccountRequestID != this.closeAccountRequestID && this.Loaded) {
            this.OnChangeCloseAccountRequestID.Raise(msg);
        }
    }

    // Rule from Users\User groups->General->Service information added in #118235, logic in #119101
    public EnableForceLinkingByAccount (): boolean { return this.isAllowedForMyUser(RulesSet.ACCOUNT_LINKED_WORKSPACE); }

    public allAccountsIsOwnedByUser (): boolean {
        const accounts = this.getAccounts();
        let IsOwnedByUser = true;
        for (const accID in accounts) {
            const account: Account = accounts[accID];
            IsOwnedByUser = IsOwnedByUser && account.IsOwnedByUser;
        }

        return IsOwnedByUser;
    }
}

export const DataCache = new _DataCache();
