// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectReportGroupResponse extends DirectMessageBaseClass {
    public Tables: any[];

    constructor () {
        super(Message.CODE_REPORT_GROUP_RESPONSE);

        this.Tables = [];
    }
}
