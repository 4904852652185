// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectTradingSystemRemoveMessage } from '../../../../Utils/DirectMessages/DirectTradingSystemRemoveMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class TradingSystemRemoveMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): DirectTradingSystemRemoveMessage[] {
        const message = new DirectTradingSystemRemoveMessage();

        message.ID = fieldSet.GetValue(FieldsFactory.FIELD_TRADING_SYSTEM_ID);

        return [message];
    }
}
