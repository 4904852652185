// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { ClusterNodeGroup, type PFixFieldSet } from '../Groups/GroupsImport';
import { DirectRouteMessage } from '../../../../Utils/DirectMessages/DirectRouteMessage';
import { OrderType } from '../../../../Utils/Trading/OrderType';

export class RouteMessageProcessor extends MessageProcessorBase {
    public decoder: any;

    constructor (parentDecoder) {
        super();

        this.decoder = parentDecoder;
    }

    public override decode (fieldSet: PFixFieldSet): DirectRouteMessage[] {
        const message = new DirectRouteMessage();

        message.RouteStatus = fieldSet.GetValue(FieldsFactory.FIELD_ACTIVE_FLAG);
        message.Name = fieldSet.GetValue(FieldsFactory.FIELD_NAME);
        message.Holidays = fieldSet.GetValue(FieldsFactory.FIELD_HOLIDAYS);
        message.QuoteServerAddress = fieldSet.GetValue(FieldsFactory.FIELD_SERVER_IDS);
        message.TradeStatus = fieldSet.GetValue(FieldsFactory.FIELD_TRADE_MODE);

        const groups = fieldSet.GetGroups(FieldsFactory.CLUSTER_NODE_GROUP, ClusterNodeGroup);
        // TODO. Remove?
        // ------------------------
        // контроль уникальности адресов. Дима обещал сделать сам,
        // но я тут со своей стороны подстраховываю
        const list = [];
        for (let i = 0, len = groups.length; i < len; i++) {
            const group = groups[i];
            if (list.includes(group.getNodeID())) {
                groups.splice(i--, 1);
            } else {
                list.push(group.getNodeID());
            }
        }
        // ------------------------
        const gr = groups[0];
        if (gr) {
            message.QuoteServerInfo = {
                NodeId: gr.GetValue(FieldsFactory.FIELD_NODE_ID),
                IsHostNode: gr.GetValue(FieldsFactory.FIELD_IS_HOST_NODE)
            };
        }// groups

        message.RouteId = fieldSet.GetValue(FieldsFactory.FIELD_ROUTE_ID);

        message.AllowTypeModify = fieldSet.GetValue(FieldsFactory.FIELD_TYPE_MODIFICATION);
        message.AllowAmountModify = fieldSet.GetValue(FieldsFactory.FIELD_AMOUNT_MODIFICATION);
        message.AllowTifModify = fieldSet.GetValue(FieldsFactory.FIELD_TIF_MODIFICATION);
        message.AllowPriceModify = fieldSet.GetValue(FieldsFactory.FIELD_PRICE_MODIFICATION);
        message.AllowOperationTypeModify = fieldSet.GetValue(FieldsFactory.FIELD_OPERAIONTYPE_MODIFICATION);
        message.AllowExpirationDateModify = fieldSet.GetValue(FieldsFactory.FIELD_EXPIRATION_DATE_MODIFICATION);

        message.QuoteDelay = fieldSet.GetValue(FieldsFactory.FIELD_QUOTE_DELAY);
        message.IsNewsRoute = fieldSet.GetValue(FieldsFactory.FIELD_IS_NEWS_ROUTE);

        const showMode = fieldSet.GetValue(FieldsFactory.FIELD_CLIENT_SHOW_MODE);
        message.ClientShowMode = showMode || 0;

        const IDBMUpdateMode = fieldSet.GetValue(FieldsFactory.FIELD_DAY_BAR_UPDATE_MODE);
        message.InstrumentDayBarMessageUpdateMode = IDBMUpdateMode === 1;

        message.DictOrderTypeTifs = this.getTifs(fieldSet);

        if (message.Name) {
            this.decoder.RouteDecodeMap[message.RouteId] = message.Name;
            this.decoder.IdRouteDecodeMap[message.Name] = message.RouteId;
        }

        const fieldQuoteRouteId = fieldSet.GetValue(FieldsFactory.FIELD_QUOTE_ROUTE_ID);
        if (fieldQuoteRouteId !== null) {
            message.QuoteRouteId = fieldQuoteRouteId;
            this.decoder.QuoteRouteMap[message.RouteId] = fieldQuoteRouteId;
        }

        return [message];
    }

    // TODO. Refactor.
    private getTifs (fieldSet): any {
        const DictOrderTypeTifs = {};

        const stTifsForOrderTypes = fieldSet.GetValue(FieldsFactory.FIELD_TIFS_FOR_ORDERTYPES);

        if (!stTifsForOrderTypes) {
            return DictOrderTypeTifs;
        }

        const arOrderTypes = stTifsForOrderTypes.split(';');
        for (let i = 0, len = arOrderTypes.length; i < len; i++) {
            const stPair = arOrderTypes[i];
            const indexSeparator = stPair.indexOf(':');
            if (indexSeparator >= 0) {
                const stOrderType = stPair.substr(0, indexSeparator);
                const orderType = parseInt(stOrderType);
                if (!isNaN(orderType)) {
                    const clientOrderType = orderType;

                    let listTif = null;
                    if (DictOrderTypeTifs.hasOwnProperty(clientOrderType)) {
                        listTif = DictOrderTypeTifs[clientOrderType];
                    } else {
                        listTif = [];
                        DictOrderTypeTifs[clientOrderType] = listTif;
                    }

                    const stVal = stPair.substr(
                        indexSeparator + 1, stPair.length - (indexSeparator + 1));

                    const arTifs = stVal.split(',');
                    for (let j = 0, j_len = arTifs.length; j < j_len; j++) {
                        const tif = parseInt(arTifs[j]);
                        if (!isNaN(tif)) {
                            const clientTif = tif;
                            listTif.push(clientTif);
                        }
                    }
                }
            }
        }

        // Для OCO берем пересечение
        this.GetTifForOCOOrderType(DictOrderTypeTifs);

        return DictOrderTypeTifs;
    }

    private GetTifForOCOOrderType (orderTypeTifDict): void {
        const ordTypes = OrderType;
        const limitTifs = orderTypeTifDict[ordTypes.Limit];
        const stopTifs = orderTypeTifDict[ordTypes.Stop];

        if (!limitTifs || !stopTifs) {
            return;
        }

        const ocoTifs = arraysIntersection(limitTifs, stopTifs);
        orderTypeTifDict[ordTypes.OCO] = ocoTifs;
    }
}
