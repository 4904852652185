import { type Greeks } from '../Greeks';

export class AnalyzerInfoData {
    public readonly intrinsicPnL: number;
    public readonly PnL: number;
    public readonly greeks: Greeks;
    public readonly whatIf: number[];

    constructor (intrinsicPnL: number, PnL: number, greeks: Greeks, whatIf: number[]) {
        this.intrinsicPnL = intrinsicPnL;
        this.PnL = PnL;
        this.greeks = greeks;
        this.whatIf = whatIf;
    }
}
