// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { BrowserUtils } from './UtilsClasses/BrowserUtils';

class _ErrorInformationStorage {
    public Storage: any[] = [];
    public FilterStorageObj: any = {}; // для фильтрации
    public timer: number;
    public w_BWD: any = null;
    public DCache: any = null;
    public msgType: string = 'error report';
    public versionBr: any;
    public buildVersion: string;
    public id: any;

    constructor () {
        this.timer = 5 * 60 * 1000;
        this.versionBr = BrowserUtils.getBrowserVersion();
    }

    public GetException (ex): void {
        if (ex.message in this.FilterStorageObj) {
            return;
        }

        const item = {
            msgType: this.msgType,
            timestamp: new Date(),
            content: {
                version: 'Build version: ' + this.buildVersion + ' Browser: ' + this.versionBr,
                code: ex.message,
                header: ex.name,
                msgBody: ex.stack
            }
        };
        this.FilterStorageObj[ex.message] = ex.message;
        this.Storage.push(item);

        if (!this.id) {
            this.id = setInterval(this.SendStorage.bind(this), this.timer, this.Storage);
        }
    }

    public SendStorage (Storage): void {
        const kakxochesh = Storage;
        this.DCache.SendInformationMessage(kakxochesh);
        kakxochesh.length = 0;
    }

    public SetDataCache (dataCache): void {
        this.DCache = dataCache;
    }

    public SetTimerValue (timeMinut): void {
        this.timer = timeMinut * 60 * 1000;
    }

    public SetBuildVersion (buildVersion): void {
        this.w_BWD = buildVersion;

        if (!buildVersion) {
            return;
        }

        this.buildVersion = this.w_BWD.version + '.' + this.w_BWD.build + '.' + this.w_BWD.build_version;
        if (this.w_BWD.developer_version && this.w_BWD.developer_version !== '0') {
            this.buildVersion += ('-' + this.w_BWD.developer_version);
        }
    }
}

export class CustomErrorClass {
    public message: any;
    public name: any;
    public stack: any;

    constructor (mess, name, stack) {
        this.message = mess;
        this.name = name;
        this.stack = stack;
    }
};

export const ErrorInformationStorage = new _ErrorInformationStorage();
