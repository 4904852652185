// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { FieldsFactory } from '../../../Factory/FieldsFactory';
import { PFixFieldSet } from '../../GroupsImport';

export class AlgorithmGainersLosersRangeGroup extends PFixFieldSet {
    constructor (pfixFieldSet: any) {
        super(FieldsFactory.ALGORITHM_HISTORICAL_GAINER_LOSER_TIME_RANGE_GROUP, null, pfixFieldSet);
    }

    get RangeType (): any /* AlgorithmRangeType */ { return super.GetValue(FieldsFactory.FIELD_TIME_RANGE_TYPE); }
    get RangeValue (): number { return super.GetValue(FieldsFactory.FIELD_TIME_RANGE_NUMBER); }
}
