// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

// #region  Rectangle
export class Rectangle {
    public X: number;
    public Y: number;
    public Width: number;
    public Height: number;

    constructor (newX?: number, newY?: number, newWidth?: number, newHeight?: number) {
        this.X = newX || 0;
        this.Y = newY || 0;
        this.Width = newWidth || 0;
        this.Height = newHeight || 0;
    }

    public Right (): number {
        return this.X + this.Width;
    }

    public Left (): number {
        return this.X;
    }

    public Bottom (): number {
        return this.Y + this.Height;
    }

    public Top (): number {
        return this.Y;
    }

    public IsEmpty (): boolean {
        return this.X === 0 && this.Y === 0 && this.Width === 0 && this.Height === 0;
    }

    public Empty (): void {
        this.X = 0;
        this.Y = 0;
        this.Width = 0;
        this.Height = 0;
    }

    public Inflate (width: number, height: number): void {
        this.Width += width;
        this.Height += height;
    }

    get Location (): Point { return new Point(this.X, this.Y); }
    set Location (value: Point) {
        this.X = value.X;
        this.Y = value.Y;
    }

    public static Empty (): Rectangle {
        const res = new Rectangle();
        res.Empty();
        return res;
    }

    public Contains (testX: number, testY: number): boolean {
        if (this.IsEmpty()) {
            return false;
        }

        return this.X <= testX && testX < this.X + this.Width &&
        this.Y <= testY && testY < this.Y + this.Height;
    }

    public ContainsRectangle (rect: Rectangle): boolean {
        if (this.IsEmpty() || !rect || rect.IsEmpty()) {
            return false;
        }

        return this.Contains(rect.Left(), rect.Top()) &&
        this.Contains(rect.Right(), rect.Bottom());
    }

    // TODO. Rename.
    public CanFit (w: number, h: number): boolean {
        if (this.IsEmpty()) {
            return;
        }

        return this.Width >= w && this.Height >= h;
    }

    public Equals (rect: Rectangle): boolean {
        return this === rect ||
        (this.X === rect.X &&
            this.Y === rect.Y &&
            this.Width === rect.Width &&
            this.Height === rect.Height);
    }

    public toString (): string {
        return '{ ' +
        'X:' + this.X + ' ' +
        'Y:' + this.Y + ' ' +
        'Width:' + this.Width + ' ' +
        'Height:' + this.Height +
        ' }';
    }

    public copy (): Rectangle {
        return new Rectangle(
            this.X,
            this.Y,
            this.Width,
            this.Height);
    }
}

// #endregion  Rectangle

// #region  Point

export class Point {
    public X: number;
    public Y: number;

    constructor (x?: number, y?: number) {
        this.X = x || 0;
        this.Y = y || 0;
    }

    public IsEmpty (): boolean {
        return this.X === 0 && this.Y === 0;
    }

    public Empty (): void {
        this.X = 0;
        this.Y = 0;
    }

    public Equals (p: Point): boolean {
        return this.X === p.X && this.Y === p.Y;
    }

    // static
    public static Empty (): Point {
        const res = new Point();
        res.X = 0;
        res.Y = 0;
        return res;
    }
}

// #endregion

// -----------------

// #region Size

export class Size {
    public Width: number;
    public Height: number;

    constructor (width?: number, height?: number) {
        this.Width = width || 0;
        this.Height = height || 0;
    }
}

// #endregion
