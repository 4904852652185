// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { type TerceraChartBase } from '../../TerceraChartBase';
import { TerceraChartRendererDrawingAdvancedParamsEnum, TerceraChartToolRendererDrawingType } from '../../Utils/ChartConstants';
import { LayersEnum, TerceraChartBaseRenderer } from '../TerceraChartBaseRenderer';

/**
   * A class to store indicators in one place.
   */
export class TerceraChartIndicatorStorageRenderer extends TerceraChartBaseRenderer {
    private readonly Indicators: any[];
    private window: any;
    private readonly mainWindow: boolean;

    constructor (chart: TerceraChartBase, mainWindow: boolean = false) {
        super(chart);

        this.Indicators = [];
        this.window = null;
        this.mainWindow = mainWindow;
        this.SetClassName('TerceraChartIndicatorStorageRenderer');
    }

    IsNeedDraw (numberOfLayer: number): boolean {
        return this.assignLayer === numberOfLayer || LayersEnum.Quotes === numberOfLayer;
    }

    get UseInAutoscale (): boolean {
        const window = this.window;
        return window ? window.indicatorRendererSettings.UseInAutoscale : false;
    }

    set UseInAutoscale (value: boolean) {
        this.UseInAutoscale = value;
        const window = this.window;
        if (window) window.indicatorRendererSettings.UseInAutoscale = value;
    }

    Draw (gr: any, window: any, windowsContainer: any, advParams: any | null = null): void {
        if (window !== this.window) this.window = window;

        const param = advParams;
        let curDrawing = TerceraChartToolRendererDrawingType.Foreground;

        if (param.Tag[TerceraChartRendererDrawingAdvancedParamsEnum.CurrentDrawingType] !== undefined) {
            curDrawing = param.Tag[TerceraChartRendererDrawingAdvancedParamsEnum.CurrentDrawingType];
        }

        const ql = advParams.layerId === LayersEnum.Quotes;

        for (let i = 0; i < this.Indicators.length; i++) {
            const indRenderer = this.Indicators[i];

            if (this.mainWindow && (!indRenderer.OnTop && curDrawing == TerceraChartToolRendererDrawingType.Foreground || indRenderer.OnTop && curDrawing == TerceraChartToolRendererDrawingType.Background)) { continue; }

            if (this.mainWindow && !indRenderer.OnTop && ql) continue;

            if (this.mainWindow && indRenderer.OnTop && !ql) continue;

            indRenderer.Draw(gr, window, windowsContainer, advParams);
        }
    }

    FindMinMax (out_minMax: any, window: any): boolean {
        let result = true;

        out_minMax.tMin = Number.MAX_VALUE;
        out_minMax.tMax = -Number.MAX_VALUE;

        for (let i = 0; i < this.Indicators.length; i++) {
            const renderer = this.Indicators[i];
            const minMaxObj = { tMin: Number.MAX_VALUE, tMax: -Number.MAX_VALUE };

            if (!renderer.InvalidState && renderer.FindMinMax(minMaxObj, window)) {
                result = true;
                if (minMaxObj.tMin < out_minMax.tMin) out_minMax.tMin = minMaxObj.tMin;
                if (minMaxObj.tMax > out_minMax.tMax) out_minMax.tMax = minMaxObj.tMax;
            }
        }

        return result;
    }

    Dispose (): void {
        this.chart = null;
        super.Dispose();
    }
}
