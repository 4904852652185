// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { ContainerControl } from '../../ContainerControl_ts';
import { VideoWidgetItemSideBarTemplate } from '../../../../templates.js';
import { ControlsTypes } from '../../../UtilsClasses/FactoryConstants';
import { DateTimeUtils } from '../../../../Utils/Time/DateTimeUtils';
import { UserWebStorageInstance } from '../../../../user-web-storage';
import { Resources } from '../../../../Commons/properties/Resources';
import { CustomEvent } from '../../../../Utils/CustomEvents';
import { ExternalLink } from '../../../../Commons/cache/ExternalLink';
import { externalScreenHandler } from '../../../../Utils/AppHandlers';
import { VideoWidgetUtils } from '../../../panels/VideoWidget/VideoWidgetUtils';

export class VideoWidgetItemSideBar extends ContainerControl {
    private readonly externalScreenInitWidth = 545; // Defines the width of the default screen that opens when a card is clicked, if the associated external link uses the internal browser
    private readonly externalScreenInitHeight = 343; // Defines the height of the default screen that opens when a card is clicked, if the associated external link uses the internal browser
    private readonly locKeyOfLIVELabel = 'panel.VideoWidget.TimeDateLabelTextForLIVE'; // Key of text's localization that is showing near red LIVE icon when stream is broadcasting
    public gotFetchedResponse = false; // was parsing completed
    public PublishingDate = null; // Sort cards' order by this Date field
    public OnFullDataFetched = new CustomEvent(); // CustomEvent .Raise(card) when card received whole need data from API
    public OnIsLIVEChanged = new CustomEvent();
    public static OnWidgetCardEvents = new CustomEvent();

    constructor () {
        super();
    }

    oncomplete (): void {
        super.oncomplete();

        this.localize();
        const videoWidget = this.parent;
        if (!isNullOrUndefined(videoWidget)) {
            VideoWidgetItemSideBar.OnWidgetCardEvents.Raise(this, true);
        }

        this.on('onCardClick', this.onCardClick);
        this.observe('externalLink', this.updateByExternalLink);
        this.observe('expired', (isExpired) => {
            if (isExpired) {
                this.updateLIVE();
            }
        });
    }

    public getType (): ControlsTypes {
        return ControlsTypes.VideoWidgetItemSideBar;
    }

    private localize (): void {
        void this.set('localizedLIVE', Resources.getResource(this.locKeyOfLIVELabel));
    }

    private updateByExternalLink (eL?, oldEL?, key?, onLinkUpdate = false): void { // This method retrieves video information such as the title, channel name, publishing date, etc. by utilizing either the YouTube Data API or a proxy
        if (oldEL && oldEL != eL && !onLinkUpdate) {
            void this.set('externalLink', oldEL);
            return;
        }

        const URL = ExternalLink.IsYouTubeLink(eL) ? eL.url : null;
        if (!URL) {
            this.ParsedSuccessfully = false;
            return;
        }

        const oldLinkValue = this.Link;
        if (!oldLinkValue || onLinkUpdate) {
            this.Link = URL;

            this.ExternalLinkName = eL.name;
            this.ExternalLinkID = eL.id;
            this.UseInternalBrowser = eL.useInternalBrowser;
            this.ExternalLinkAvailableUntil = eL.availableUntil;
            this.IsLiveFromBO = eL.liveStream;
        }
    }

    private onCardClick (): void { // This method retrieves video information such as the title, channel name, publishing date, etc. by utilizing either the YouTube Data API or a proxy
        if (this.UseInternalBrowser) {
            const screenInitData = {
                ClientUseInternalBrowser: this.UseInternalBrowser,
                Text: VideoWidgetUtils.GetEmbedYouTubeLinkByVideoID(this.VideoID),
                Tool: this.get('externalLink'),
                ResizableScreen: true,
                ScreenWidth: this.externalScreenInitWidth,
                ScreenHeight: this.externalScreenInitHeight,
                ScreenName: this.Title
            };

            const scr = externalScreenHandler.Show(screenInitData, true);
        } else {
            window.open(this.Link);
        }
    }

    private fetchVideoData (): void { // This method retrieves video information such as the title, channel name, publishing date, etc. by utilizing either the YouTube Data API or a proxy
        UserWebStorageInstance.youtubePageParser.post(this.Link, this.VideoID).then((data) => { this.fetchResponseProcess(data); });
    }

    private fetchResponseProcess (resp): void {
        if (!resp) {
            return;
        }

        const data = resp.data;

        this.Title = data.title;
        this.PreviewImageSource = data.thumbnail; // One more way to get Video image

        this.ChannelID = data.channelId;
        this.ChannelName = data.author;
        this.ChannelPic = data.authorThumbnail;

        this.IsStream = data.isStream;
        this.StreamStartDate = data.streamStartDateTimeString;
        this.StreamEndDate = data.streamEndDateTimeString;

        const upcoming = data.streamHasNotStartedYet;
        this.StreamHasNotStartedYet = upcoming;

        if (upcoming && Date.now() > this.get('streamStartDate')) { // #114917
            this.StreamHasNotStartedYet = false;
            data.isLIVE = true;
        }

        this.StreamHasBeenAlreadyEnded = data.streamHasBeenAlreadyEnded;
        this.ParsedSuccessfully = !!data.title;
        this.PublishingDate = data.publishedAt; // Note: This also initializes the PublishingDateFormatted property. This variable is initialized with a string argument that is suitable for the new Date(<argument>) constructor. However, accessing this variable as a property will return a Date object.

        this.updateLIVE(data.isLIVE);

        this.gotFetchedResponse = true;
        this.OnFullDataFetched.Raise(this);
    }
    /**
     * Update the value of IsLIVE with the provided argument and raise the OnIsLIVEChanged event if necessary.
     *
     * @param {boolean} [newIsLIVE=false] - The new value for IsLIVE. Default is false.
     */

    private updateLIVE (newIsLIVE = false): void {
        const oldIsLIVE = Boolean(this.IsLIVE);
        this.IsLIVE = Boolean(newIsLIVE);

        if (this.IsLIVE !== oldIsLIVE) {
            this.OnIsLIVEChanged.Raise(this);
        }
    }

    onteardown (): void {
        const videoWidget = this.parent;
        if (!isNullOrUndefined(videoWidget)) {
            VideoWidgetItemSideBar.OnWidgetCardEvents.Raise(this, false);

            const cards = videoWidget.get('cards');
            cards[this.ExternalLinkID] = null;
            void videoWidget.set('cards', cards);
        }
    }

    get ChannelID (): any { return this.get('channelID'); }
    set ChannelID (value) { void this.set('channelID', value); } // Only by request to the YouTube Data API or via parsing.

    get ChannelName (): any { return this.get('channelName'); }
    set ChannelName (value) { void this.set('channelName', value); } // Set by making a request to the YouTube Data API or via parsing or the noembed.com proxy.

    get ChannelPic (): any { return this.get('channelPic'); }
    set ChannelPic (value) { void this.set('channelPic', value); } // Can be set only by making a request to the YouTube Data API or via parsing

    get Title (): any { return this.get('title'); }
    set Title (value) { void this.set('title', value); } // Set by making a request to the YouTube Data API or the noembed.com proxy or via parsing.

    // The URL of the YouTube video assigned to the ExternalLink object in the BackOffice.
    get Link (): any { return this.get('videoLink'); }
    set Link (value) {
        if (value) {
            void this.set('videoLink', value);
            this.VideoID = VideoWidgetUtils.GetVideoIDFromYouTubeLink(value);
        }
    }

    get VideoID (): any { return this.get('videoID'); }
    set VideoID (value) {
        if (value) {
            void this.set('videoID', value);
            this.fetchVideoData();
        }
    }

    get PreviewImageSource (): any { return this.get('videoPreviewURL'); }
    set PreviewImageSource (value) { void this.set('videoPreviewURL', value); }

    // data from parsing
    get ParsedSuccessfully (): any { return this.get('parsedSuccessfully'); }
    set ParsedSuccessfully (value) { void this.set('parsedSuccessfully', value); }

    // is it is a stream (maybe StreamHasNotStartedYet)
    get IsStream (): any { return this.get('isStream'); }
    set IsStream (value) { void this.set('isStream', value); }

    // get: string formatted value, set: by string that suits Date() constructor, data from parsing
    get StreamStartDate (): any { return this.get('streamStartDateFormatted'); }
    set StreamStartDate (value) {
        if (value) {
            const date = new Date(value);
            void this.set({ streamStartDate: date, streamStartDateFormatted: DateTimeUtils.formatDate(date, 'DD.MM.YYYY HH:mm') });
        }
    }

    // end of the live stream - Date() object
    get StreamEndDate (): any { return this.get('streamEndDate'); }
    set StreamEndDate (value) { void this.set('streamEndDate', value); }

    // data from parsing
    get StreamHasNotStartedYet (): any { return this.get('streamHasNotStartedYet'); }
    set StreamHasNotStartedYet (value) { void this.set('streamHasNotStartedYet', value); }

    // data from parsing
    get StreamHasBeenAlreadyEnded (): any { return this.get('streamHasBeenAlreadyEnded'); }
    set StreamHasBeenAlreadyEnded (value) { void this.set('streamHasBeenAlreadyEnded', value); }

    // is broadcasting LIVE on air right now
    get IsLIVE (): any { return this.get('isLive'); }
    set IsLIVE (value) { void this.set('isLive', value); }

    // bool from Back Office
    get IsLiveFromBO (): any { return this.get('isLiveFromBO'); }
    set IsLiveFromBO (value) { void this.set('isLiveFromBO', value); }

    // purpose remains unclear
    get ExternalLinkName (): any { return this.get('externalLinkName'); }
    set ExternalLinkName (value) { void this.set('externalLinkName', value); }

    // purpose remains unclear
    get ExternalLinkID (): any { return this.get('externalLinkID'); }
    set ExternalLinkID (value) { void this.set('externalLinkID', value); }

    // purpose remains unclear
    get ExternalLinkAvailableUntil (): any { return this.get('externalLinkAvailableUntil'); }
    set ExternalLinkAvailableUntil (value) { void this.set('externalLinkAvailableUntil', value); }

    get UseInternalBrowser (): any { return this.get('useInternalBrowser'); }
    set UseInternalBrowser (value) { void this.set('useInternalBrowser', value); }
};

ContainerControl.extendWith(VideoWidgetItemSideBar,
    {
        template: VideoWidgetItemSideBarTemplate,
        data: function () {
            return {
                visible: false,
                externalLink: null, // This variable holds a reference to the ExternalLink object created in the BackOffice and sent to the client through the CODE_PFIX_EXTERNAL_LINK_MESSAGE[492] message
                title: null, // The name or title of the YouTube video in the 'Link *' field of the BackOffice external link can be obtained using either the YouTube Data API or a noembed.com proxy. (!)This is important to conserve the daily quota of YouTube Data API requests

                videoLink: null, // YouTube video URL string, e.g. https://youtu.be/j4W5EY2S6X4
                videoID: null, // A string with Video's ID, e.g. j4W5EY2S6X4
                videoPreviewURL: null, // A string with URL of a video preview image

                channelName: null, // Author - the name of the YouTube channel that owns the video in the 'Link *' field of the BackOffice external link. The available methods to obtain this information are both YouTube Data API or a noembed.com proxy
                channelID: null, // The YouTube Author's ID. Please note that this information can only be obtained using the YouTube Data API, and we require it to obtain the channelPic.
                channelPic: null, // A string representing the URL to the YouTube channel's user picture. Please note that this information can only be obtained using the YouTube Data API

                isLive: null, // This variable indicates whether the video is currently being broadcasted live or not. The value is obtained by checking with the YouTube Data API based on the data sent by the server
                isLiveFromBO: null, // isLive bool value from Back Office
                externalLinkName: null, // Tool name from Back Office's External Link creation
                externalLinkID: null, // And some more fields from the BO with status "purpose remains unclear"
                useInternalBrowser: null,
                externalLinkAvailableUntil: null,
                expired: null, // = Date.now() > externalLinkAvailableUntil

                isStream: null, // The boolean value 'true' determines whether the video is an upcoming, live, or already ended stream, while 'false' identifies it as a regular uploaded YouTube video.
                streamStartDate: null, // Available only if isStream == true
                streamEndDate: null, // Available if isStream == true && streamHasBeenAlreadyEnded == true
                streamHasNotStartedYet: null, // The video is an upcoming stream
                streamHasBeenAlreadyEnded: null, // The video is an already ended stream
                parsedSuccessfully: null // Got at least title => link is valid and video still exists
            };
        }
    });
