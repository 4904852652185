import { OptionAnalyzerPanelTemplate } from '../../../templates';
import { Resources } from '../../../Commons/properties/Resources';
import { PanelNames } from '../../UtilsClasses/FactoryConstants';
import { ColorStyleWidth, DynProperty, DynPropertyRelationType, PairColor } from '../../../Commons/DynProperty';
import { type OptionTrader } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionTrader';
import { SimulationModel } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionAnalyzer/SimulationModel';
import { NumericUtils } from '../../../Utils/NumericUtils';
import { ProbabilityScenario } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionAnalyzer/ProbabilityScenario';
import { AnalyzerColoringMehod } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionAnalyzer/AnalyzerColoringMehod';
import { HistoricalVolatilityPriceType } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionChain/HistoricalVolatilityPriceType';
import { ThemeManager } from '../../misc/ThemeManager';
import { ApplicationPanelWithTable } from '../ApplicationPanelWithTable';
import { AnalyzerChartType } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionAnalyzer/AnalyzerChartType';
import { AnalyzerLinesType } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionAnalyzer/AnalyzerLinesType';
import { type AnalyzerVolatilityLine } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionAnalyzer/AnalyzerVolatilityLine';
import { AnalyzerProbabilityCalculationMode } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionAnalyzer/AnalyzerProbabilityCalculationMode';
import { type QuickTable } from '../../elements/QuickTable/QuickTable';
import { type QuickTableRactive } from '../../elements/QuickTable/QuickTableRactive';
import { OptionAnalyzerLineTableItem } from '../../cache/OptionMaster/OptionAnalyzerLineTableItem';
import { SessionSettings } from '../../../Commons/SessionSettings';
import { type OptionAnalyzerChartRactive } from '../../elements/TerceraChartRactive/OptionAnalyzerChartRactive';
import { OptionCalculatorScreen } from '../../screen/OptionCalculatorScreen';

export class OptionAnalyzerPanel extends ApplicationPanelWithTable<any> {
    private _isInitialized: boolean = false;
    private _optionTrader: OptionTrader;
    private _quickTable: QuickTable;
    private _chartRactive: OptionAnalyzerChartRactive;

    constructor () {
        super();
        this.Name = 'OptionAnalyzerPanel';
        this.headerLocaleKey = 'panel.optionAnalyzer';
        this.NeedCalculateRowCount = false;
    }

    // #region Overrides

    public override oncomplete (): void {
        super.oncomplete();
        this.on('expandCollapsSettings_btnClick', this.onExpandCollapseButtonClicked);
        this.on('refreshProbability_btnClick', this.onRefreshProbabilityButtonClicked);
        this.on('calculator_btnClick', this.onCalculatorButtonClicked);

        this.observe('visible', this.onVisibleChanged);
        this.observe('selectedChartTypeItem', this.onSelectedChartTypeItemChanged);
        this.observe('selectedLinesTypeItem', this.selectedLinesTypeItemChanged);
        this.observe('volatilityChange', this.onVolatilityChangeChanged);
        this.observe('probabilityCalculationDays', this.onProbabilityCalculationDaysChanged);
        this.observe('selectedProbabilityCalculationModeItem', this.onSelectedProbabilityCalculationModeItemChanged);
    }

    public override onteardown (): void {
        if (!isNullOrUndefined(this._quickTable)) {
            this._quickTable.AfterEditItem.UnSubscribe(this.onAfterEditItem, this);
        }
        this.off('expandCollapsSettings_btnClick', this.onExpandCollapseButtonClicked);
        this.off('refreshProbability_btnClick', this.onRefreshProbabilityButtonClicked);
        this.off('calculator_btnClick', this.onCalculatorButtonClicked);
        super.onteardown();
    }

    public override getType (): PanelNames { return PanelNames.OptionAnalyzerPanel; }
    public override jbInit (): void {
        const quickTableRactive = super.findAllComponents('quickTableRactive')[0] as QuickTableRactive;
        this._quickTable = quickTableRactive.quickTable;
        this._chartRactive = super.findAllComponents('optionAnalyzerChartRactive')[0] as OptionAnalyzerChartRactive;
        super.jbInit();
        this._quickTable.showHeader = false;
        this._quickTable.InitializeDirect(new OptionAnalyzerLineTableItem(null, null, null));
        this._quickTable.UpdateSortedColumns();
        this._quickTable.AfterEditItem.Subscribe(this.onAfterEditItem, this);
    }

    public override localize (): void {
        super.localize();
        this.localizeVolatilityLabels();
        this.localizeComboboxItems();
        void this.set({
            settingsLabel: Resources.getResource('panel.optionAnalyzer.settingsLabel'),
            chartTypeLabel: Resources.getResource('panel.optionAnalyzer.chartTypeLabel'),
            linesLabel: Resources.getResource('panel.optionAnalyzer.linesLabel'),
            probabilityCalculationLabel: Resources.getResource('property.ProbabilityCalculation'),
            daysLabel: Resources.getResource('panel.optionAnalyzer.daysLabel'),
            modeLabel: Resources.getResource('panel.optionAnalyzer.modeLabel'),
            buttonCalculatorText: Resources.getResource('property.Calculator')
        });
        this._quickTable.localize();
        this._chartRactive.localize();
    }

    public override themeChange (): void {
        super.themeChange();
        const optionAnalyzer = this._optionTrader.optionAnalyzer;
        const currentTheme = ThemeManager.CurrentTheme;
        optionAnalyzer.intrinsicColor = currentTheme.OptionMasterAnalyzerIntrinsicColor;
        optionAnalyzer.timeLineColor = currentTheme.OptionMasterAnalyzerTimeLineColor;
        optionAnalyzer.basePriceLineColor = currentTheme.OptionMasterAnalyzerZeroLineColor;
        optionAnalyzer.zeroLineColor = currentTheme.OptionMasterAnalyzerZeroLineColor;
        optionAnalyzer.underlierPriceColor = currentTheme.OptionMasterAnalyzerUnderlierPriceColor;
        optionAnalyzer.standardDeviationForegroundColor = currentTheme.OptionMasterAnalyzerStandardDeviationForegroundColor;
        optionAnalyzer.standardDeviationBackgroundColor = currentTheme.OptionMasterAnalyzerStandardDeviationBackgroundColor;
        optionAnalyzer.secondStandardDeviationForegroundColor = currentTheme.OptionMasterAnalyzerSecondStandardDeviationForegroundColor;
        optionAnalyzer.secondStandardDeviationBackgroundColor = currentTheme.OptionMasterAnalyzerSecondStandardDeviationBackgroundColor;
        this.themeChangeLines();
        this._quickTable.themeChange();
        this._chartRactive.themeChange();
    }

    public override Properties (): DynProperty[] {
        const optionAnalyzer = this._optionTrader.optionAnalyzer;
        const properties = super.Properties();
        let index = 0;
        let separatorGroup = '#0#' + Resources.getResource('property.appearance');
        let dp = new DynProperty('property.Intrinsic', new ColorStyleWidth(optionAnalyzer.intrinsicColor, optionAnalyzer.intrinsicStyle, optionAnalyzer.intrinsicWidth), DynProperty.COLOR_STYLE_WIDTH, DynProperty.ANALYZER_GROUP);
        dp.sortIndex = index++;
        dp.separatorGroup = separatorGroup;
        properties.push(dp);
        dp = new DynProperty('property.TimeLine', new ColorStyleWidth(optionAnalyzer.timeLineColor, optionAnalyzer.timeLineStyle, optionAnalyzer.timeLineWidth), DynProperty.COLOR_STYLE_WIDTH, DynProperty.ANALYZER_GROUP);
        dp.sortIndex = index++;
        dp.separatorGroup = separatorGroup;
        properties.push(dp);
        dp = new DynProperty('property.ZeroLine', new ColorStyleWidth(optionAnalyzer.zeroLineColor, optionAnalyzer.zeroLineStyle, optionAnalyzer.zeroLineWidth), DynProperty.COLOR_STYLE_WIDTH, DynProperty.ANALYZER_GROUP);
        dp.sortIndex = index++;
        dp.separatorGroup = separatorGroup;
        properties.push(dp);
        dp = new DynProperty('property.UnderlierPrice', new ColorStyleWidth(optionAnalyzer.underlierPriceColor, optionAnalyzer.underlierPriceStyle, optionAnalyzer.underlierPriceWidth), DynProperty.COLOR_STYLE_WIDTH, DynProperty.ANALYZER_GROUP);
        dp.sortIndex = index++;
        dp.separatorGroup = separatorGroup;
        properties.push(dp);

        separatorGroup = '#1#' + Resources.getResource('property.ProbabilitySimulations');
        dp = new DynProperty('property.SimulationModel', optionAnalyzer.simulationModel, DynProperty.COMBOBOX, DynProperty.ANALYZER_GROUP);
        dp.sortIndex = index++;
        dp.separatorGroup = separatorGroup;
        dp.COMBOBOX_TYPE = DynProperty.INTEGER;
        dp.objectVariants = [
            { text: Resources.getResource('property.SimulationModel.ByAbsolutePrices'), value: SimulationModel.ByAbsolutePrices },
            { text: Resources.getResource('property.SimulationModel.ByRelativePrices'), value: SimulationModel.ByRelativePrices },
            { text: Resources.getResource('property.SimulationModel.ByLogarithmicPrices'), value: SimulationModel.ByLogarithmicPrices }
        ];
        properties.push(dp);
        dp = new DynProperty('property.SimulationExamples', optionAnalyzer.simulationExamples, DynProperty.INTEGER, DynProperty.ANALYZER_GROUP);
        dp.sortIndex = index++;
        dp.separatorGroup = separatorGroup;
        dp.minimalValue = 100;
        dp.maximalValue = NumericUtils.MAXVALUE;
        dp.increment = 1;
        dp.decimalPlaces = 0;
        properties.push(dp);
        dp = new DynProperty('property.ProbabilityScenario', optionAnalyzer.probabilityScenario, DynProperty.COMBOBOX, DynProperty.ANALYZER_GROUP);
        dp.sortIndex = index++;
        dp.separatorGroup = separatorGroup;
        dp.COMBOBOX_TYPE = DynProperty.INTEGER;
        dp.objectVariants = [
            { text: Resources.getResource('property.ProbabilityScenario.OneTouch'), value: ProbabilityScenario.OneTouch },
            { text: Resources.getResource('property.ProbabilityScenario.OutOfRange'), value: ProbabilityScenario.OutOfRange }
        ];
        properties.push(dp);
        dp = new DynProperty('property.HistoryPeriodYear', optionAnalyzer.historyPeriodYear, DynProperty.INTEGER, DynProperty.ANALYZER_GROUP);
        dp.sortIndex = index++;
        dp.separatorGroup = separatorGroup;
        dp.minimalValue = 1;
        dp.maximalValue = NumericUtils.MAXVALUE;
        dp.increment = 1;
        dp.decimalPlaces = 0;
        properties.push(dp);

        separatorGroup = '#2#' + Resources.getResource('property.View');
        dp = new DynProperty('property.ColoringMethod', optionAnalyzer.coloringMehod, DynProperty.COMBOBOX, DynProperty.ANALYZER_GROUP);
        dp.sortIndex = index++;
        dp.separatorGroup = separatorGroup;
        dp.objectVariants = [
            { text: Resources.getResource('property.ColoringMethod.HistoricalVolatility'), value: AnalyzerColoringMehod.HistoricalVolatility },
            { text: Resources.getResource('property.ColoringMethod.None'), value: AnalyzerColoringMehod.None }
        ];
        dp.COMBOBOX_TYPE = DynProperty.INTEGER;
        dp.assignedProperty = ['property.CurrentHV', 'property.Price', 'property.StandardDeviationColor', 'property.SecondStandardDeviationColor'];
        dp.DynPropertyRelationType = DynPropertyRelationType.Visibility;
        properties.push(dp);
        dp = new DynProperty('property.CurrentHV', optionAnalyzer.currentHVPercent, DynProperty.DOUBLE_WITH_REFRESH_BUTTON, DynProperty.ANALYZER_GROUP);
        dp.minimalValue = 0;
        dp.maximalValue = 1000;
        dp.increment = 0.1;
        dp.decimalPlaces = 1;
        dp.callback = async () => await this._optionTrader.optionChain.CalculateHistoricalVolatilityAsync(this._optionTrader.account, this._optionTrader.underlier);
        dp.sortIndex = index++;
        dp.separatorGroup = separatorGroup;
        dp.enabilityValue = AnalyzerColoringMehod.HistoricalVolatility;
        properties.push(dp);

        dp = new DynProperty('property.Price', optionAnalyzer.historicalVolatilityPriceType, DynProperty.COMBOBOX, DynProperty.ANALYZER_GROUP);
        dp.sortIndex = index++;
        dp.separatorGroup = separatorGroup;
        dp.objectVariants = [
            { text: Resources.getResource('property.Last'), value: HistoricalVolatilityPriceType.Last },
            { text: Resources.getResource('property.BidAskAverage'), value: HistoricalVolatilityPriceType.BidAsk },
            { text: Resources.getResource('property.OHLCAverage'), value: HistoricalVolatilityPriceType.OHLC },
            { text: Resources.getResource('property.OHLAverage'), value: HistoricalVolatilityPriceType.OHL }
        ];
        dp.enabilityValue = AnalyzerColoringMehod.HistoricalVolatility;
        properties.push(dp);

        dp = new DynProperty('property.StandardDeviationColor', new PairColor(optionAnalyzer.standardDeviationForegroundColor, optionAnalyzer.standardDeviationBackgroundColor), DynProperty.PAIR_COLOR, DynProperty.ANALYZER_GROUP);
        dp.sortIndex = index++;
        dp.separatorGroup = separatorGroup;
        dp.enabilityValue = AnalyzerColoringMehod.HistoricalVolatility;
        properties.push(dp);

        dp = new DynProperty('property.SecondStandardDeviationColor', new PairColor(optionAnalyzer.secondStandardDeviationForegroundColor, optionAnalyzer.secondStandardDeviationBackgroundColor), DynProperty.PAIR_COLOR, DynProperty.ANALYZER_GROUP);
        dp.sortIndex = index++;
        dp.separatorGroup = separatorGroup;
        dp.enabilityValue = AnalyzerColoringMehod.HistoricalVolatility;
        properties.push(dp);

        dp = new DynProperty('property.ChartType', optionAnalyzer.chartType, DynProperty.COMBOBOX, DynProperty.HIDDEN_GROUP);
        dp.sortIndex = index++;
        dp.COMBOBOX_TYPE = DynProperty.INTEGER;
        properties.push(dp);

        dp = new DynProperty('property.Lines', optionAnalyzer.linesType, DynProperty.COMBOBOX, DynProperty.HIDDEN_GROUP);
        dp.sortIndex = index++;
        dp.COMBOBOX_TYPE = DynProperty.INTEGER;
        properties.push(dp);

        dp = new DynProperty('property.VolatilityChange', optionAnalyzer.volatilityChange, DynProperty.INTEGER, DynProperty.HIDDEN_GROUP);
        dp.sortIndex = index++;
        properties.push(dp);

        dp = new DynProperty('property.Volatility', JSON.stringify(optionAnalyzer.volatilityLines), DynProperty.STRING, DynProperty.HIDDEN_GROUP);
        dp.sortIndex = index++;
        properties.push(dp);

        dp = new DynProperty('property.Days', optionAnalyzer.probabilityCalculationDays, DynProperty.INTEGER, DynProperty.HIDDEN_GROUP);
        dp.sortIndex = index++;
        properties.push(dp);

        dp = new DynProperty('property.ProbabilityCalculation', optionAnalyzer.probabilityCalculationMode, DynProperty.COMBOBOX, DynProperty.HIDDEN_GROUP);
        dp.sortIndex = index++;
        dp.COMBOBOX_TYPE = DynProperty.INTEGER;
        properties.push(dp);

        properties.push(...this._chartRactive.Properties());

        return properties;
    }

    public override callBack (newProperties: any): void {
        super.callBack(newProperties);
        const optionAnalyzer = this._optionTrader.optionAnalyzer;
        let dp = DynProperty.getPropertyByGroupAndName(newProperties, DynProperty.ANALYZER_GROUP, 'property.Intrinsic');
        if (!isNullOrUndefined(dp?.value)) {
            const colorStyleWidth = dp.value as ColorStyleWidth;
            optionAnalyzer.intrinsicColor = colorStyleWidth.Color;
            optionAnalyzer.intrinsicStyle = colorStyleWidth.Style;
            optionAnalyzer.intrinsicWidth = colorStyleWidth.Width;
        }
        dp = DynProperty.getPropertyByGroupAndName(newProperties, DynProperty.ANALYZER_GROUP, 'property.TimeLine');
        if (!isNullOrUndefined(dp?.value)) {
            const colorStyleWidth = dp.value as ColorStyleWidth;
            optionAnalyzer.timeLineColor = colorStyleWidth.Color;
            optionAnalyzer.timeLineStyle = colorStyleWidth.Style;
            optionAnalyzer.timeLineWidth = colorStyleWidth.Width;
        }
        dp = DynProperty.getPropertyByGroupAndName(newProperties, DynProperty.ANALYZER_GROUP, 'property.ZeroLine');
        if (!isNullOrUndefined(dp?.value)) {
            const colorStyleWidth = dp.value as ColorStyleWidth;
            optionAnalyzer.zeroLineColor = colorStyleWidth.Color;
            optionAnalyzer.zeroLineStyle = colorStyleWidth.Style;
            optionAnalyzer.zeroLineWidth = colorStyleWidth.Width;
        }
        dp = DynProperty.getPropertyByGroupAndName(newProperties, DynProperty.ANALYZER_GROUP, 'property.UnderlierPrice');
        if (!isNullOrUndefined(dp?.value)) {
            const colorStyleWidth = dp.value as ColorStyleWidth;
            optionAnalyzer.underlierPriceColor = colorStyleWidth.Color;
            optionAnalyzer.underlierPriceStyle = colorStyleWidth.Style;
            optionAnalyzer.underlierPriceWidth = colorStyleWidth.Width;
        }
        dp = DynProperty.getPropertyByGroupAndName(newProperties, DynProperty.ANALYZER_GROUP, 'property.SimulationModel');
        if (!isNullOrUndefined(dp?.value)) {
            optionAnalyzer.simulationModel = dp.value as SimulationModel;
        }
        dp = DynProperty.getPropertyByGroupAndName(newProperties, DynProperty.ANALYZER_GROUP, 'property.SimulationExamples');
        if (!isNullOrUndefined(dp?.value)) {
            optionAnalyzer.simulationExamples = dp.value as number;
        }
        dp = DynProperty.getPropertyByGroupAndName(newProperties, DynProperty.ANALYZER_GROUP, 'property.ProbabilityScenario');
        if (!isNullOrUndefined(dp?.value)) {
            optionAnalyzer.probabilityScenario = dp.value as ProbabilityScenario;
        }
        dp = DynProperty.getPropertyByGroupAndName(newProperties, DynProperty.ANALYZER_GROUP, 'property.HistoryPeriodYear');
        if (!isNullOrUndefined(dp?.value)) {
            optionAnalyzer.historyPeriodYear = dp.value as number;
        }
        dp = DynProperty.getPropertyByGroupAndName(newProperties, DynProperty.ANALYZER_GROUP, 'property.ColoringMethod');
        if (!isNullOrUndefined(dp?.value)) {
            optionAnalyzer.coloringMehod = dp.value as AnalyzerColoringMehod;
        }
        dp = DynProperty.getPropertyByGroupAndName(newProperties, DynProperty.ANALYZER_GROUP, 'property.CurrentHV');
        if (!isNullOrUndefined(dp?.value)) {
            optionAnalyzer.currentHVPercent = dp.value as number;
        }
        dp = DynProperty.getPropertyByGroupAndName(newProperties, DynProperty.ANALYZER_GROUP, 'property.Price');
        if (!isNullOrUndefined(dp?.value)) {
            optionAnalyzer.historicalVolatilityPriceType = dp.value as HistoricalVolatilityPriceType;
        }
        dp = DynProperty.getPropertyByGroupAndName(newProperties, DynProperty.ANALYZER_GROUP, 'property.StandardDeviationColor');
        if (!isNullOrUndefined(dp?.value)) {
            const pairColor = dp.value as PairColor;
            optionAnalyzer.standardDeviationForegroundColor = pairColor.Color1;
            optionAnalyzer.standardDeviationBackgroundColor = pairColor.Color2;
        }
        dp = DynProperty.getPropertyByGroupAndName(newProperties, DynProperty.ANALYZER_GROUP, 'property.SecondStandardDeviationColor');
        if (!isNullOrUndefined(dp?.value)) {
            const pairColor = dp.value as PairColor;
            optionAnalyzer.secondStandardDeviationForegroundColor = pairColor.Color1;
            optionAnalyzer.secondStandardDeviationBackgroundColor = pairColor.Color2;
        }
        dp = DynProperty.getPropertyByGroupAndName(newProperties, DynProperty.HIDDEN_GROUP, 'property.ChartType');
        if (!isNullOrUndefined(dp?.value)) {
            optionAnalyzer.chartType = dp.value as AnalyzerChartType;
        }
        dp = DynProperty.getPropertyByGroupAndName(newProperties, DynProperty.HIDDEN_GROUP, 'property.Lines');
        if (!isNullOrUndefined(dp?.value)) {
            optionAnalyzer.linesType = dp.value as AnalyzerLinesType;
        }
        dp = DynProperty.getPropertyByGroupAndName(newProperties, DynProperty.HIDDEN_GROUP, 'property.VolatilityChange');
        if (!isNullOrUndefined(dp?.value)) {
            optionAnalyzer.volatilityChange = dp.value as number;
        }
        dp = DynProperty.getPropertyByGroupAndName(newProperties, DynProperty.HIDDEN_GROUP, 'property.Volatility');
        if (!isNullOrUndefined(dp?.value)) {
            const analyzerVolatilityLines = JSON.parse(dp.value as string) as AnalyzerVolatilityLine[];
            if (analyzerVolatilityLines.length === optionAnalyzer.volatilityLines.length) {
                for (let i = 0; i < analyzerVolatilityLines.length; i++) {
                    optionAnalyzer.volatilityLines[i].isActive = analyzerVolatilityLines[i].isActive;
                    optionAnalyzer.volatilityLines[i].value = analyzerVolatilityLines[i].value;
                    optionAnalyzer.volatilityLines[i].width = analyzerVolatilityLines[i].width;
                    optionAnalyzer.volatilityLines[i].color = analyzerVolatilityLines[i].color;
                }
            }
        }
        dp = DynProperty.getPropertyByGroupAndName(newProperties, DynProperty.HIDDEN_GROUP, 'property.Days');
        if (!isNullOrUndefined(dp?.value)) {
            optionAnalyzer.probabilityCalculationDays = dp.value as number;
        }
        dp = DynProperty.getPropertyByGroupAndName(newProperties, DynProperty.HIDDEN_GROUP, 'property.ProbabilityCalculation');
        if (!isNullOrUndefined(dp?.value)) {
            optionAnalyzer.probabilityCalculationMode = dp.value as AnalyzerProbabilityCalculationMode;
        }

        this._chartRactive.callBack(newProperties);
        this.repopulate();
    }

    public override TickAsync (): void {
        super.TickAsync();
        const isVisible: boolean = super.get('visible');
        if (!isVisible) {
            return;
        }
        const chart = this._chartRactive.terceraChart;
        if (chart.needRedraw) {
            chart.needRedraw = false;
            chart.Draw();
            chart.AddForceUpdate();
        }
        this._quickTable.updateRowsCellsValues();
        this._quickTable.Draw();
        this._quickTable.needRedraw = false;
        this._quickTable.needRedrawBackground = true;
    }

    public override layoutTable (): void {
        super.layoutTable();
        this.layoutTableResize(this._chartRactive);
        for (const table of this.findAllComponents('quickTableRactive')) {
            this.layoutTableResize(table);
        }
    }

    public override repopulate (): void {
        super.repopulate();
        const optionAnalyzer = this._optionTrader.optionAnalyzer;
        const chartTypeItems: Array<{ text: string, value: number }> = this.get('chartTypeItems');
        const linesTypeItems: Array<{ text: string, value: number }> = this.get('linesTypeItems');
        const probabliltyCalculationModeItems: Array<{ text: string, value: number }> = this.get('probabilityCalculationModeItems');
        void this.set({
            selectedChartTypeItem: chartTypeItems.find(x => x.value === optionAnalyzer.chartType),
            selectedLinesTypeItem: linesTypeItems.find(x => x.value === optionAnalyzer.linesType),
            volatilityChange: optionAnalyzer.volatilityChange,
            volatilityLines: optionAnalyzer.volatilityLines,
            probabilityCalculationDays: optionAnalyzer.probabilityCalculationDays,
            selectedProbabilityCalculationModeItem: probabliltyCalculationModeItems.find(x => x.value === optionAnalyzer.probabilityCalculationMode)
        });
        this.localizeVolatilityLabels();
        this.repopulateTable();
    }
    // #endregion

    // #region Eventhandlers

    private onVisibleChanged (): void {
        this.layoutTable();
    }

    private async onExpandCollapseButtonClicked (): Promise<void> {
        const isExpanded: boolean = this.get('isSettingsExpanded');
        await this.set('isSettingsExpanded', !isExpanded);
        this.layoutTable();
    }

    private onRefreshProbabilityButtonClicked (): void {
        this._chartRactive.refreshProbabilityValues();
    }

    private onCalculatorButtonClicked (): void {
        OptionCalculatorScreen.Show(this._optionTrader.getCalculator());
    }

    private onSelectedChartTypeItemChanged (): void {
        if (!this._isInitialized) {
            return;
        }
        const selectedChartTypeItem = this.get('selectedChartTypeItem');
        this._optionTrader.optionAnalyzer.chartType = selectedChartTypeItem.value;
        this._chartRactive.refreshChart();
    }

    private selectedLinesTypeItemChanged (value, oldValue): void {
        if (!this._isInitialized) {
            return;
        }
        if (value === oldValue) {
            return;
        }
        const optionAnalyzer = this._optionTrader.optionAnalyzer;
        const selectedLinesTypeItem = this.get('selectedLinesTypeItem');
        const linesType = selectedLinesTypeItem.value;
        optionAnalyzer.linesType = linesType;
        optionAnalyzer.resetVolatityLinesToDefault();
        void this.set({
            volatilityChangeMinValue: linesType === AnalyzerLinesType.VolatilityChange ? 0 : NumericUtils.MINVALUE,
            volatilityChangeStep: linesType === AnalyzerLinesType.VolatilityChange ? 1 : 0.01,
            volatilityChangeDecimalPlaces: linesType === AnalyzerLinesType.VolatilityChange ? 0 : 2
        });
        this.localizeVolatilityLabels();
        this.themeChangeLines();
        this.repopulateTable();
        this._chartRactive.refreshChart();
    }

    private onVolatilityChangeChanged (): void {
        if (!this._isInitialized) {
            return;
        }
        const volatilityChange = this.get('volatilityChange');
        this._optionTrader.optionAnalyzer.volatilityChange = volatilityChange;
        this._chartRactive.refreshChart();
    }

    private onProbabilityCalculationDaysChanged (): void {
        if (!this._isInitialized) {
            return;
        }
        const probabilityCalculationDays = this.get('probabilityCalculationDays');
        this._optionTrader.optionAnalyzer.probabilityCalculationDays = probabilityCalculationDays;
    }

    private onSelectedProbabilityCalculationModeItemChanged (): void {
        if (!this._isInitialized) {
            return;
        }
        const selectedProbabilityCalculationModeItem = this.get('selectedProbabilityCalculationModeItem');
        this._optionTrader.optionAnalyzer.probabilityCalculationMode = selectedProbabilityCalculationModeItem.value;
        this._chartRactive.refreshProbabilityLines();
    }

    private onAfterEditItem (data): void {
        let isRefreshChart = false;
        const tableItem: OptionAnalyzerLineTableItem = data.row.item;
        switch (data.realColumnIndex) {
        case OptionAnalyzerLineTableItem.ACTIVE_COL_INDEX:
            tableItem.analyzerLine.isActive = !tableItem.analyzerLine.isActive;
            break;
        case OptionAnalyzerLineTableItem.VALUE_COL_INDEX:
            tableItem.analyzerLine.value = data.newValue;
            isRefreshChart = true;
            break;
        case OptionAnalyzerLineTableItem.WIDTH_COL_INDEX:
            tableItem.analyzerLine.width = data.newValue;
            break;
        case OptionAnalyzerLineTableItem.COLOR_COL_INDEX:
            tableItem.analyzerLine.color = data.newValue;
            break;
        }
        data.row.FillRowByItem(tableItem, true);
        if (isRefreshChart) {
            this._chartRactive.refreshChart();
        } else {
            this._chartRactive.refreshVolatilityLines();
        }
    }
    // #endregion

    public setOptionTrader (optionTrader: OptionTrader): void {
        this._optionTrader = optionTrader;
        this._chartRactive.setOptionTrader(optionTrader);
        this._isInitialized = true;
    }

    public applyCursor (cursor: string): void {
        if (!isNullOrUndefined(this._chartRactive)) {
            this._chartRactive.setCursor(cursor);
        }
    }

    private localizeVolatilityLabels (): void {
        if (!this._isInitialized) {
            return;
        }
        const optionAnalyzer = this._optionTrader.optionAnalyzer;
        const isVolatilityChange = optionAnalyzer.linesType === AnalyzerLinesType.VolatilityChange;
        void this.set({
            volatilityNumericLabel: isVolatilityChange ? Resources.getResource('panel.optionAnalyzer.tPlusLabel') : Resources.getResource('panel.optionAnalyzer.volatityLabel'),
            volatilityTableLabel: isVolatilityChange ? Resources.getResource('panel.optionAnalyzer.volatityLabel') : Resources.getResource('panel.optionAnalyzer.tPlusLabel')
        });
    }

    private localizeComboboxItems (): void {
        const chartTypeItems = this.get('chartTypeItems');
        for (let i = 0; i < chartTypeItems.length; i++) {
            const chartTypeItem = chartTypeItems[i];
            switch (chartTypeItem.value) {
            case AnalyzerChartType.PL:
                chartTypeItem.text = Resources.getResource('property.PNL');
                break;
            case AnalyzerChartType.Delta:
                chartTypeItem.text = Resources.getResource('panel.optionChain.delta');
                break;
            case AnalyzerChartType.Gamma:
                chartTypeItem.text = Resources.getResource('panel.optionChain.gamma');
                break;
            case AnalyzerChartType.Vega:
                chartTypeItem.text = Resources.getResource('panel.optionChain.vega');
                break;
            case AnalyzerChartType.Theta:
                chartTypeItem.text = Resources.getResource('panel.optionChain.theta');
                break;
            case AnalyzerChartType.Rho:
                chartTypeItem.text = Resources.getResource('panel.optionChain.rho');
                break;
            }
        }
        const linesTypeItems = this.get('linesTypeItems');
        for (let i = 0; i < linesTypeItems.length; i++) {
            const linesTypeItem = linesTypeItems[i];
            switch (linesTypeItem.value) {
            case AnalyzerLinesType.VolatilityChange:
                linesTypeItem.text = Resources.getResource('property.VolatilityChange');
                break;
            case AnalyzerLinesType.TimeChange:
                linesTypeItem.text = Resources.getResource('property.TimeChange');
                break;
            }
        }
        const probabliltyCalculationModeItems = this.get('probabilityCalculationModeItems');
        for (let i = 0; i < probabliltyCalculationModeItems.length; i++) {
            const probabliltyCalculationModeItem = probabliltyCalculationModeItems[i];
            switch (probabliltyCalculationModeItem.value) {
            case AnalyzerProbabilityCalculationMode.None:
                probabliltyCalculationModeItem.text = Resources.getResource('property.ProbabilityCalculation.None');
                break;
            case AnalyzerProbabilityCalculationMode.Single:
                probabliltyCalculationModeItem.text = Resources.getResource('property.ProbabilityCalculation.Single');
                break;
            case AnalyzerProbabilityCalculationMode.OR:
                probabliltyCalculationModeItem.text = Resources.getResource('property.ProbabilityCalculation.OR');
                break;
            case AnalyzerProbabilityCalculationMode.AND:
                probabliltyCalculationModeItem.text = Resources.getResource('property.ProbabilityCalculation.AND');
                break;
            }
        }
        void this.update();
    }

    private themeChangeLines (): void {
        const optionAnalyzer = this._optionTrader.optionAnalyzer;
        for (let i = 0; i < optionAnalyzer.volatilityLines.length; i++) {
            const volatilityLine = optionAnalyzer.volatilityLines[i];
            if (!isValidString(volatilityLine.color)) {
                optionAnalyzer.volatilityLines[i].color = ThemeManager.CurrentTheme.OptionMasterAnalyzerVolatilityLineColor;
            }
        }
    }

    private repopulateTable (): void {
        const optionAnalyzer = this._optionTrader.optionAnalyzer;
        const volatilityLines = optionAnalyzer.volatilityLines;
        this._quickTable.ClearAll();
        for (let i = 0; i < volatilityLines.length; i++) {
            const volatilityLine = volatilityLines[i];
            this._quickTable.AddItem(new OptionAnalyzerLineTableItem(volatilityLine, optionAnalyzer.linesType, SessionSettings));
        }
    }
}

ApplicationPanelWithTable.extendWith(OptionAnalyzerPanel, {
    template: OptionAnalyzerPanelTemplate,
    data: function () {
        return {
            settingsLabel: '',
            chartTypeLabel: '',
            linesLabel: '',
            volatilityNumericLabel: '',
            volatilityTableLabel: '',
            probabilityCalculationLabel: '',
            daysLabel: '',
            modeLabel: '',
            buttonCalculatorText: '',

            isSettingsExpanded: true,
            chartTypeItems: [
                { text: '', value: AnalyzerChartType.PL },
                { text: '', value: AnalyzerChartType.Delta },
                { text: '', value: AnalyzerChartType.Gamma },
                { text: '', value: AnalyzerChartType.Vega },
                { text: '', value: AnalyzerChartType.Theta },
                { text: '', value: AnalyzerChartType.Rho }
            ],
            selectedChartTypeItem: undefined,
            linesTypeItems: [
                { text: '', value: AnalyzerLinesType.VolatilityChange },
                { text: '', value: AnalyzerLinesType.TimeChange }
            ],
            selectedLinesTypeItem: undefined,
            volatilityChangeMinValue: 0,
            volatilityChangeMaxValue: NumericUtils.MAXVALUE,
            volatilityChangeStep: 1,
            volatilityChangeDecimalPlaces: 0,
            volatilityChange: 0,
            volatilityLines: [],
            probabilityCalculationDaysMinValue: 1,
            probabilityCalculationDaysMaxValue: 99999,
            probabilityCalculationDaysStep: 1,
            probabilityCalculationDays: 10,
            probabilityCalculationModeItems: [
                { text: '', value: AnalyzerProbabilityCalculationMode.None },
                { text: '', value: AnalyzerProbabilityCalculationMode.Single },
                { text: '', value: AnalyzerProbabilityCalculationMode.OR },
                { text: '', value: AnalyzerProbabilityCalculationMode.AND }
            ],
            selectedProbabilityCalculationModeItem: undefined
        };
    },
    computed: {
        terceraChartPanelContext: {
            get: function () { return this; },
            set: function (value) { }
        }
    }
});
