// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from "../../Utils/CustomEvents.ts";
import { TimeSpanPeriods } from "../../Utils/Time/TimeSpan.ts";
import { Resources } from "../../Commons/properties/Resources.ts";
import { RangeSelectPanelTemplate } from "../../templates.js";
import { TerceraPanel } from "../elements/TerceraPanel.js";


export let RangeSelectPanel = TerceraPanel.extend({
    template: RangeSelectPanelTemplate,
    data: function ()
    {
        return {
            top: 5,
            left: 5,
            visible: true,
            cbTooltip: 'panel.rangeSelect.tooltip',
            dateRangeLookupVisibility: false,
            refreshBtnVisibility: false,
            refreshBtnTT: null,
            dateFrom: null,
            dateTo: null
        };
    }
});

RangeSelectPanel.prototype.getType = function () { return 'RangeSelectPanel' };

RangeSelectPanel.prototype.oninit = function ()
{
    TerceraPanel.prototype.oninit.apply(this);

    this.getReportEvent = new CustomEvent()
}

RangeSelectPanel.prototype.oncomplete = function ()
{
    TerceraPanel.prototype.oncomplete.call(this)

    var comboBox = this.Controls.rangeComboBox

    comboBox.observe('selectedItem', function (newValue) { this.selectRange(newValue) }.bind(this));

    let keys = RangeSelectPanel.rangeKeysArray, items = []
    keys.forEach(function (key)
    {
        if (!Resources.isHidden(key))
            items.push({ text: Resources.getResource(key), value: key });
    })
    comboBox.set({ items: items });

    this.on("applyBtnClick", function () { this.selectRange({ value: RangeSelectPanel.KEY_RANGE }) })
};

RangeSelectPanel.prototype.selectRange = function (selectedItem)
{
    if (!selectedItem)
        return

    this.set('dateRangeLookupVisibility', selectedItem.value == RangeSelectPanel.KEY_RANGE)

    let startTime, finishTime

    let nowTime = new Date();

    let Y = nowTime.getFullYear(), M = nowTime.getMonth(), D = nowTime.getDate();
    let nowMS = nowTime.getTime();

    switch (selectedItem.value)
    {
        case RangeSelectPanel.KEY_DAILY:
            startTime = new Date(Y, M, D, 0, 0, 0).getTime();
            finishTime = new Date(Y, M, D, 23, 59, 59).getTime();
            break;
        case RangeSelectPanel.KEY_24H:
            startTime = nowMS - TimeSpanPeriods.TicksPerDay;
            finishTime = nowMS;
            break;
        case RangeSelectPanel.KEY_7D:
            startTime = nowMS - TimeSpanPeriods.TicksPerDay * 7;
            finishTime = nowMS;
            break;
        case RangeSelectPanel.KEY_30D:
            startTime = nowMS - TimeSpanPeriods.TicksPerDay * 30;
            finishTime = nowMS;
            break;
        case RangeSelectPanel.KEY_90D:
            startTime = nowMS - TimeSpanPeriods.TicksPerDay * 90;
            finishTime = nowMS;
            break;
        case RangeSelectPanel.KEY_RANGE:
            let dateFrom = this.get('dateFrom') || new Date(),
                dateTo = this.get('dateTo') || new Date()

            dateFrom.setHours(0); dateFrom.setMinutes(0); dateFrom.setSeconds(0); dateFrom.setMilliseconds(0);
            dateTo.setHours(23); dateTo.setMinutes(59); dateTo.setSeconds(59);

            startTime = dateFrom.getTime()
            finishTime = dateTo.getTime()
            break;
    }

    this.getReportEvent.Raise(startTime, finishTime)
}

RangeSelectPanel.prototype.updateSelectedRange = function ()
{
    this.selectRange(this.Controls.rangeComboBox.get('selectedItem'));
}

RangeSelectPanel.prototype.isDaily = function ()
{
    let key = RangeSelectPanel.KEY_DAILY;
    let item = null;
    if (this.Controls.rangeComboBox)
        item = this.Controls.rangeComboBox.get("selectedItem")
    if (item)
        key = item.value;

    return key === RangeSelectPanel.KEY_DAILY
}

RangeSelectPanel.KEY_DAILY = "panel.rangeSelect.daily";
RangeSelectPanel.KEY_24H = "panel.rangeSelect.24h";
RangeSelectPanel.KEY_7D = "panel.rangeSelect.7d";
RangeSelectPanel.KEY_30D = "panel.rangeSelect.30d";
RangeSelectPanel.KEY_90D = "panel.rangeSelect.90d";
RangeSelectPanel.KEY_RANGE = "panel.rangeSelect.range";

RangeSelectPanel.rangeKeysArray = [RangeSelectPanel.KEY_DAILY, RangeSelectPanel.KEY_24H, RangeSelectPanel.KEY_7D, RangeSelectPanel.KEY_30D, RangeSelectPanel.KEY_90D, RangeSelectPanel.KEY_RANGE]