// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PanelNames } from "../UtilsClasses/FactoryConstants.ts";
import { PortfolioSummaryItem } from "../../Portfolio/PortfolioSummaryItem.js";
import { AlertItem } from "../cache/AlertItem.ts";
import { MarketConsensusPanelItem } from "../cache/MarketConsensusPanelItem.ts";
import { AssetBalanceItem } from "../cache/AssetBalanceItem.ts";
import { AssetsItem } from "../cache/AssetsItem.ts";
import { BaseInformer } from "../cache/BaseInformer.ts";
import { EntitlementProductsItem } from "../cache/EntitlementProductsItem.ts";
import { EventItem } from "../cache/EventItem.ts";
import { FilledOrderItem } from "../cache/FilledOrderItem.ts";
import { FullLogsItem } from "../cache/FullLogsItem.ts";
import { HolidayItem } from "../cache/HolidayItem.ts";
import { Level2ItemQ } from "../cache/Level2ItemQ.ts";
import { NewsItem } from "../cache/NewsItem.ts";
import { OrderHistoryItem } from "../cache/OrderHistoryItem.ts";
import { OrderItem } from "../cache/OrderItem.ts";
import { PortfolioReturnsItem } from "../cache/PortfolioReturnsItem.ts";
import { PositionItem } from "../cache/PositionItem.ts";
import { PositionsBalanceItem } from "../cache/PositionsBalanceItem.ts";
import { StatementItem } from "../cache/StatementItem.ts";
import { TimeSalesItem } from "../cache/TimeSalesItem.ts";
import { TradingCentralItem } from "../cache/TradingCentralItem.ts";
import { NewVerticalPanelTableItem } from "../cache/VerticalPanel/NewVerticalPanelTableItem.ts";
import { MultiTimeSalesItem } from "../Custom/MultiTimeSalesPanel/MultiTimeSalesItem.ts";
import { ClosePositionsMutuallyItem } from "../cache/ClosePositionsMutuallyItem.ts";
import { SavedOrderItem } from "../cache/SavedOrderItem.ts";
import { GainersLosersItem } from "../cache/GainersLosersItem.ts";
import { HighLowItem } from "../cache/HighLowItem.ts";
import { OptionPositionItem } from "../cache/OptionMaster/OptionPositionItem.ts";

class _PanelItemsFactory
{
    constructor()
    {
        this.PanelsInfoDirect = {};
        this.PanelsInfoDirect[PanelNames.AssetBalancesPanel] = AssetBalanceItem;
        this.PanelsInfoDirect[PanelNames.PositionsPanel] = PositionItem;
        this.PanelsInfoDirect[PanelNames.OptionPositionsPanel] = OptionPositionItem;
        this.PanelsInfoDirect[PanelNames.OrdersPanel] = OrderItem;
        this.PanelsInfoDirect[PanelNames.OptionOrdersPanel] = OrderItem;
        this.PanelsInfoDirect[PanelNames.EventsPanel] = EventItem;
        this.PanelsInfoDirect[PanelNames.InformerPanel] = BaseInformer;
        this.PanelsInfoDirect[PanelNames.AlertPanel] = AlertItem;
        this.PanelsInfoDirect[PanelNames.FilledOrdersPanel] = FilledOrderItem;
        this.PanelsInfoDirect[PanelNames.OrderHistoryPanel] = OrderHistoryItem;
        this.PanelsInfoDirect[PanelNames.TSPanel] = TimeSalesItem;
        this.PanelsInfoDirect[PanelNames.SymbolInfoPanel] = NewVerticalPanelTableItem;
        this.PanelsInfoDirect[PanelNames.AccountDetailsPanel] = NewVerticalPanelTableItem;
        this.PanelsInfoDirect[PanelNames.MarginOEControl] = NewVerticalPanelTableItem;
        this.PanelsInfoDirect[PanelNames.MarketDepthPanel] = Level2ItemQ;
        this.PanelsInfoDirect[PanelNames.InstrumentHolidaysScreen] = HolidayItem;
        this.PanelsInfoDirect[PanelNames.StatementPanel] = StatementItem;
        this.PanelsInfoDirect[PanelNames.MultiTimeSalesPanel] = MultiTimeSalesItem;
        this.PanelsInfoDirect[PanelNames.AssetsPanel] = AssetsItem;
        this.PanelsInfoDirect[PanelNames.PositionsBalancePanel] = PositionsBalanceItem;
        this.PanelsInfoDirect[PanelNames.NewsPanel] = NewsItem;

        this.PanelsInfoDirect[PanelNames.TradingCentral] = TradingCentralItem;
        this.PanelsInfoDirect[PanelNames.FullLogsPanel] = FullLogsItem;
        this.PanelsInfoDirect[PanelNames.ProductsPanel] = EntitlementProductsItem;

        this.PanelsInfoDirect[PanelNames.PortfolioReturns] = PortfolioReturnsItem;
        this.PanelsInfoDirect[PanelNames.PortfolioSummary] = PortfolioSummaryItem;

        this.PanelsInfoDirect[PanelNames.ClosePositionsMutually] = ClosePositionsMutuallyItem;

        // Algorithm
        this.PanelsInfoDirect[PanelNames.MarketConsensusPanel] = MarketConsensusPanelItem;
        this.PanelsInfoDirect[PanelNames.GainersLosersPanel] = GainersLosersItem;
        this.PanelsInfoDirect[PanelNames.HighLowPanel] = HighLowItem;

        this.PanelsInfoDirect[PanelNames.SavedOrdersPanel] = SavedOrderItem;
    }

    GetPanelItem (panelName) 
    {
        return this.PanelsInfoDirect[panelName];
    }
}

export const PanelItemsFactory = new _PanelItemsFactory();