// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { MODE_MAIN, MODE_SIGNAL } from "../../IndicatorConstants.ts";
import { ExpandDoubleVector } from "../../DoubleMatrix.ts";
import { IndicatorFunctions } from "../../IndicatorFunctions.ts";
import { iBuildInIndicator } from "../../iBuildInIndicator.ts";


export let iOsMA = function (fastMAPeriod, slowMaPeriod, signalPeriod, priceType)
{
    this.FFastMaPeriod = fastMAPeriod;
    this.FSlowMaPeriod = slowMaPeriod;
    this.FSignalMaPeriod = signalPeriod;
    this.FPriceType = priceType;
    iBuildInIndicator.call(this, 1)
    this.macd = new ExpandDoubleVector();
    this.ema1 = new ExpandDoubleVector();
    this.ema2 = new ExpandDoubleVector();
}
iOsMA.prototype = Object.create(iBuildInIndicator.prototype)

Object.defineProperty(iOsMA.prototype, 'Name',
    {
        get: function () { return 'iOsMA' }
    })

Object.defineProperty(iOsMA.prototype, 'Key',
    {
        get: function () { return this.DefaultKey }
    })

iOsMA.prototype.NextBar = function (callBound)
{
    iBuildInIndicator.prototype.NextBar.call(this, callBound)
    this.macd.Add(0.0);
    this.ema1.Add(0.0);
    this.ema2.Add(0.0);
}

iOsMA.prototype.Refresh = function (count, newThread)
{
    this.macd.Dispose()
    this.macd = new ExpandDoubleVector();
    this.ema1.Dispose()
    this.ema1 = new ExpandDoubleVector();
    this.ema2.Dispose()
    this.ema2 =new ExpandDoubleVector();
    iBuildInIndicator.prototype.Refresh.call(this, count, newThread)
}

iOsMA.prototype.OnQuote = function (ci, callBound, callFromRefresh)
{
    this.macd[this.macd.Length - 1] = 0;
    let price = this.GetPrice(this.FPriceType, 0);
    this.ema1[this.ema1.Length - 1] = price;
    this.ema2[this.ema2.Length - 1] = price;
    let Parent = this.Parent
    if (!Parent)
        return
    let start = Math.max(this.FFastMaPeriod, this.FSlowMaPeriod);
    if (start > this.Count - 1)
        return;
    let macdline = IndicatorFunctions.MACDFunction(this.ema1, this.ema2, MODE_MAIN, this.FFastMaPeriod, this.FSlowMaPeriod, this.FSignalMaPeriod, this.macd);
    let signalline = IndicatorFunctions.MACDFunction(this.ema1, this.ema2, MODE_SIGNAL, this.FFastMaPeriod, this.FSlowMaPeriod, this.FSignalMaPeriod, this.macd);
    this.SetValue(0, 0, macdline - signalline);
}