// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { TradingToolViewBase } from '../../Chart/Tools/TradingTools/TradingToolViewBase.ts';
import { Graphics } from '../../Commons/Graphics.ts';
import { ContainerControl } from '../../Controls/elements/ContainerControl.js';
import { TradingViewBaseToolTemplate } from '../../templates.js';

export class TradingViewBaseTool extends ContainerControl.extend(
    {
        Name: 'TradingViewChartTool',
        data: function ()
        {
            return {
                className: '',

                chartTradingCoreRef: null,
                dataObject: null,   // type of <Position,Order,Alert>

                collapseByClick: false,    // for Position and Order tools

                uncollapsed: false,
                hovered: false,
                movable: true,             // available to move
                dragging: false,           // is moving now

                useTextForAction: true,    // text description with quantity for Order, PnL for Position but image (useTextForAction=false) for action section in Alert tools 
                actionSectionText: '',     // ^^^^ 

                mainImageHovered: false,
                actionImageHovered: false,
                cancelImageHovered: false,

                priceTextColor: '#fff',
                priceLineColor: '#fff',
                pricePlateColor: '#fff',

                mainImg: null,
                actionImg: null,
                cancelImg: null,

                mainSrc: null,
                actionSrc: null,
                cancelSrc: null,

                mainCursor: 'ns-resize',
                actionCursor: 'pointer',
                closeCursor: 'pointer'
            }
        },
        toolsContainerRef: null,                    // The TradingViewToolsContainer reference that created this tool
        template: TradingViewBaseToolTemplate
    })
{
    getType () { return 'TradingViewBaseTool'; }

    getPrice () { return null; }

    id () { return null; }  // AlertId, PositionID or OrderId

    getInstrument ()
    {
        const dataObject = this.get('dataObject');
        return dataObject ? dataObject.Instrument : null;
    }

    getSelfHeightOffset ()
    {
        const h = this.get('height');
        return h ? h * 1.5 : 0;
    }

    oninit ()
    {
        ContainerControl.prototype.oninit.apply(this);

        this.observe('top', (tV) =>
        {
            this.set('visible', tV >= 35 && (!this.getClientPanel() || tV <= this.getClientPanel().clientHeight - 50));
        })

        this.observe('mainImageHovered actionImageHovered cancelImageHovered', () =>
        {
            if (this.get('collapseByClick'))
                return;     // for Position and Order tools

            this.set('uncollapsed', this.get('mainImageHovered') || this.get('actionImageHovered') || this.get('cancelImageHovered'))
        });

        this.observe('pricePlateColor', (nC) =>
        {
            if (nC)
                this.set('priceTextColor', Graphics.GetContrastColor(nC));
        });

        this.observe('dataObject', () =>
        {
            const p = this.getPrice();
            this.set({ price: p, priceValue: p });
        });

        this.observe('uncollapsed mainSrc actionSrc cancelSrc', (uncVal) =>
        {
            const mImg = this.get('mainImg');
            let w = mImg ? mImg.width : 0;

            const uncollapsed = this.get('uncollapsed');
            if (uncollapsed)
            {
                const actionImg = this.get('actionImg');
                if (actionImg)
                    w += this.get('actionImg')?.width + this.get('cancelImg')?.width;
                else if (this.append)
                    w = this.find(`.${this.get('className')}`)?.clientWidth;   // for tools with text action part which has dynamic width
            }

            this.set('width', w);
        });

        this.on('componentOver', (event) =>
        {
            const draggingID = this.toolsContainerRef?.currentDraggingToolID;
            if (draggingID && draggingID != this.id())
                return;

            document.getElementsByClassName('trading-view-tools-container')[0].style.pointerEvents = 'all'
            this.set('hovered', true);
        });

        this.on('componentOut', (event) =>
        {
            this.set('hovered', false);

            const draggingID = this.toolsContainerRef?.currentDraggingToolID;
            if (draggingID && draggingID == this.id() && this.get('dragging'))
                return;

            if (!this.toolsContainerRef?.currentDraggingToolID)
                document.getElementsByClassName('trading-view-tools-container')[0].style.pointerEvents = 'none';
        });

        this.on('dragstart', (event) =>
        {
            if (!this.get('movable'))
                return;

            if (!this.toolsContainerRef || this.toolsContainerRef.currentDraggingToolID)
                return; // id of moving tool is not empty, can't start move another one

            this.set('dragging', true);

            this.toolsContainerRef.currentDraggingToolID = this.id();
        });

        this.on('dragend', (event) =>
        {
            const draggingID = this.toolsContainerRef?.currentDraggingToolID;
            if (draggingID && draggingID != this.id())
                return;

            this.set('dragging', false);

            if (this.toolsContainerRef)
            {
                this.toolsContainerRef.currentDraggingToolID = null;
                if (!this.get('hovered'))
                    document.getElementsByClassName('trading-view-tools-container')[0].style.pointerEvents = 'none';
            }
        });

        this.on('onMouseOverMain', (event) => { this.set('mainImageHovered', true); });
        this.on('onMouseOutMain', (event) => { this.set('mainImageHovered', false); });

        this.on('onMouseOverAction', (event) => { this.set('actionImageHovered', true); });
        this.on('onMouseOutAction', (event) => { this.set('actionImageHovered', false); });

        this.on('onMouseOverCancel', (event) => { this.set('cancelImageHovered', true); });
        this.on('onMouseOutCancel', (event) => { this.set('cancelImageHovered', false); });

        this.on('handleMainImageClick', (event) => { });
        this.on('handleActionImageClick', (event) => { });
        this.on('handleCancelImageClick', (event) => { });
    }
}