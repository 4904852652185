// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Rectangle } from "../../Commons/Geometry.ts";
import { TerceraTextBoxTemplate } from "../../templates.js";
import { Control } from "./Control.js";
import { ControlsTypes } from '../../Controls/UtilsClasses/FactoryConstants.ts';

export let TerceraTextBox = Control.extend({
    template: TerceraTextBoxTemplate,
    data: function ()
    {
        return {
            maxLength: 200,                          // максимальная длинна
            isReadOnly: false,                       // редактируемое поле
            placeholder: '',                         // текст внутри поля формы, который исчезает при получении фокуса
            text: '',                                // значение
            inputPatern: null,                       // патерн допустимых элементов g - флаг не использовать!  пример new RegExp('[1-9]') 
            haveError: false,
            autofocus: false,
            inputType: 'text',                       // input element's type attribute (text/password)
            elementStyle: "js-input-container",
            additionalClass: ""
        };
    },
    inputElement: null                               // ref to <input> html element
});

TerceraTextBox.prototype.getType = function () { return ControlsTypes.TerceraTextBox; };

TerceraTextBox.prototype.oninit = function ()
{
    Control.prototype.oninit.apply(this);
    this.on('focus', this.private_Focus);
    this.on('blur', this.private_Blur);
    this.observe('text', this.private_textChange);
};

TerceraTextBox.prototype.oncomplete = function ()
{
    Control.prototype.oncomplete.apply(this);
    this.observe('focused', this.onFocusedChanged); //uncomment 83805
};

TerceraTextBox.prototype.getInputElement = function (newVal)
{
    if (!this.inputElement)
    {
        const arr = $(this.find('div input'));
        if (arr) this.inputElement = arr[0];
    }

    return this.inputElement;
};

TerceraTextBox.prototype.onFocusedChanged = function (newVal)
{
    var $input = this.getInputElement();
    if (newVal) $input.focus();
    else $input.blur();
};

TerceraTextBox.prototype.getAbsoluteRectangle = function ()
{
    let $root = $(this.find('div'))
    let $window = $(window)
    let offset = $root.offset()

    return new Rectangle(
        offset.left - $window.scrollLeft(),
        offset.top - $window.scrollTop(),
        $root.width(),
        $root.height())
}

TerceraTextBox.prototype.private_textChange = function (newText, oldText)
{
    var result = this.validateValue(newText);
    if (!result) //отменить  изменение и всплытие события если символы недопустимы!!!
    {
        this.set({ text: oldText });
    }
    // ! надо сохранять последний текст? !
    this.fire(TerceraTextBox.Events.TextChanged, this.get('text'));
};

TerceraTextBox.prototype.private_Focus = function (e)
{
    if (!this.get('enabled')) return;
    if (this.get('skipfocus'))
    {
        this.set('skipfocus', false);
        e.node.setSelectionRange(e.node.value.length, e.node.value.length);
        return;
    }
    // Cross-browser hack for the different "input.select()" behaviours after focusing.
    // https://www.impressivewebs.com/input-select-correct-behaviour/
    var inputEl = e.node;
    //setTimeout(function () { inputEl.select(); }, 0);
    inputEl.select();

    this.set('focused', true);
};

TerceraTextBox.prototype.private_Blur = function (e)
{
    this.set('focused', false);
};

TerceraTextBox.prototype.selectAll = function ()
{
    this.getInputElement().select();
};

TerceraTextBox.prototype.validateValue = function (newText)
{
    var regexp = this.get('inputPatern');
    if (regexp) return regexp.test(newText);

    return true;
};

TerceraTextBox.Events =
{
    KeyDown: 'keyDown',
    TextChanged: 'TextChanged',
    KeyUp: "keyUp"
};
Object.freeze(TerceraTextBox.Events);