// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectSnapshotMessage } from '../../../../Utils/DirectMessages/DirectSnapshotMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class SnapshotResponseMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): DirectSnapshotMessage[] {
        const message = new DirectSnapshotMessage();

        const len = fieldSet.Count();

        for (let i = 0; i < len; i++) {
            const fixField = fieldSet.fieldSet[i];
            const fieldID = fixField.FieldId;

            if (fieldID === 0) {
                continue;
            }

            const value = fixField.Value;

            if (fieldID === FieldsFactory.FIELD_USER_ID) {
                message.UserId = value;
            } else if (fieldID === FieldsFactory.FIELD_ROUTE_ID) {
                message.RouteId = value;
            } else if (fieldID === FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID) {
                message.TradableId = value;
            } else if (fieldID === FieldsFactory.FIELD_BID) {
                message.Bid = value;
            } else if (fieldID === FieldsFactory.FIELD_BID_VOLUME) {
                message.BidSize = value;
            } else if (fieldID === FieldsFactory.FIELD_ASK) {
                message.Ask = value;
            } else if (fieldID === FieldsFactory.FIELD_ASK_VOLUME) {
                message.AskSize = value;
            } else if (fieldID === FieldsFactory.FIELD_LAST_PRICE) {
                message.Last = value;
            } else if (fieldID === FieldsFactory.FIELD_LAST_SIZE) {
                message.LastSize = value;
            } else if (fieldID === FieldsFactory.FIELD_HIGH) {
                message.High = value;
            } else if (fieldID === FieldsFactory.FIELD_LOW) {
                message.Low = value;
            } else if (fieldID === FieldsFactory.FIELD_VOLUME) {
                message.Volume = value;
            } else if (fieldID === FieldsFactory.FIELD_CLOSE_PRICE) {
                message.Close = value;
            }
        }

        return [message];
    }
}
