// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
export let de =
{
    "AdditionalProperty.Account": "Konto",
    "AdditionalProperty.Reverse": "Reverse",
    "accountDetailsWidget.DropdownButton": "Konto",
    "accountDetailsWidget.DropdownButton.Tooltip": "Konto-Menü",
    "additional.verification": "Zusätzliche Passwortüberprüfung erforderlich.",
    "Alligator": "Alligator",
    "Average Directional Index": "Average directional index",
    "Average True Range": "Durchschnittlicher True Range",
    "Bollinger Band": "Bollinger Band",
    "Bollinger Bands": "Bollinger bands",
    "bonds.couponCycle.1m": "1 Monat",
    "bonds.couponCycle.1y": "1 Jahr",
    "bonds.couponCycle.3m": "3 monate",
    "bonds.couponCycle.6m": "6 monate",
    "BrokerMessage.Cancel": "Stornieren",
    "BrokerMessage.OK": "OK",
    "BrokerMessage.Text": "Broker Nachricht",
    "BusinessRejectMessage.28": "Falsches aktuelles Passwort",
    "Chande Momentum Oscillator": "Ändern Sie den Momentum Oscillator",
    "chart.agregationType.Button.ToolTip": "Verwenden Sie vordefinierte Aggregationstypen",
    "chart.AllowedPeriods.15M": "15m",
    "chart.AllowedPeriods.1D": "1D",
    "chart.AllowedPeriods.1H": "1H",
    "chart.AllowedPeriods.1M": "1m",
    "chart.AllowedPeriods.1S": "1S",
    "chart.AllowedPeriods.1T": "1 tick",
    "chart.AllowedPeriods.1W": "1W",
    "chart.AllowedPeriods.1Y": "1Y",
    "chart.AllowedPeriods.30M": "30m",
    "chart.AllowedPeriods.4H": "4H",
    "chart.AllowedPeriods.5M": "5m",
    "chart.AllowedPeriods.Month": "1M",
    "chart.AllowedPeriods.Range": "Umfang",
    "chart.AllowedPeriods.Ticks": "Ticks",
    "chart.ChartPanel.accountLookup.ToolTip": "Konto auswählen",
    "chart.ChartPanel.symbolLookup.ToolTip": "Symbol auswählen",
    "chart.chartScroll.toBegin": "zur letzten Quotierung",
    "chart.chartScroll.zoomInButton": "Zoom rein",
    "chart.chartScroll.zoomOutButton": "Zoom raus",
    "chart.ChartSymbolSelector.addIndicator.ButtonToolTip": "Indikatoren hinzufügen",
    "chart.ChartSymbolSelector.drawingButton.ToolTip": "Zeichnungssymbolleiste ein- / ausblenden",
    "chart.ChartSymbolSelector.mouseTradeButton.ToolTip": "Mouse Handel",
    "chart.ChartSymbolSelector.oeButton": "Auftragserfassung",
    "chart.ChartSymbolSelector.oeButton.ToolTip": "Chart Ordereller Hatdee",
    "chart.ChartSymbolSelector.OverlayTooltip": "Overlay hinzufügen",
    "Chart.chartWindow.auto": "Auto",
    "Chart.chartWindow.collapsed": "Eingestürzt",
    "Chart.chartWindow.manual": "Handbuch",
    "chart.chartWindowManager.SetAuto": "Zur automatischen Preisskala wechseln",
    "chart.chartWindowManager.SetManual": "Zur manuellen Preisskala wechseln",
    "chart.head.addOverlay": "Overlay hinzufügen...",
    "chart.head.charttype": "Stil",
    "chart.head.line": "Zeichnungswerkzeuge",
    "chart.head.overlayRemoveAll": "Alles entfernen",
    "chart.head.overlays": "Überlagerungen",
    "chart.indicator": "Indikator",
    "chart.indicatorAdd": "Indikatoren hinzufügen",
    "chart.indicatorRemoveAll": "Alles entfernen",
    "chart.indicators": "Indikatoren",
    "chart.Infoline.days": "tage",
    "chart.Infoline.hours": "stunden",
    "chart.Infoline.min": "min",
    "chart.Infoline.price": "preis",
    "chart.Infoline.sec": "sec",
    "chart.Infoline.time": "zeit",
    "chart.infoWindow.Items.Additionalinfo": "Zusätzliche Information",
    "chart.infoWindow.Items.Ask": "Ask:",
    "chart.infoWindow.Items.Bars": "#Bars:",
    "chart.infoWindow.Items.Bid": "Bid:",
    "chart.infoWindow.Items.Change": "Änderung,%",
    "chart.infoWindow.Items.Close": "Schließen:",
    "chart.infoWindow.Items.Date": "Daten:",
    "chart.infoWindow.Items.High": "Hoch:",
    "chart.infoWindow.Items.Low": "Niedrig:",
    "chart.infoWindow.Items.Open": "Öffnen:",
    "chart.infoWindow.Items.Ticks": "Ticks:",
    "chart.infoWindow.Items.Time": "Zeit:",
    "chart.infoWindow.Items.Volume": "Volumen:",
    "chart.instrumentsLookup": "Symbolsuche",
    "chart.layerManager": "Einstellungen...",
    "chart.loading": "Wird geladen...",
    "chart.loadingData": "Daten für werden geladen",
    "chart.mainlayer.gridPrice": "Gitter-Preis",
    "chart.mainlayer.gridTime": "Gitter-Zeit",
    "chart.mainlayer.volume": "Volumen Balken",
    "chart.menu.cancelByAccount": "Stonieren Sie alle nach Konto",
    "chart.menu.cancelByInstrument": "Stornieren aller nach Symbol",
    "chart.menu.cancelOrder": "Order stornieren",
    "chart.menu.close": "Position schließen",
    "chart.menu.closeall": "Schließe alle Positionen...",
    "chart.menu.InfoWindowDock": "Andocken",
    "chart.menu.InfoWindowHide": "verbergen",
    "chart.menu.InfoWindowSettings": "Einstellungen",
    "chart.menu.InfoWindowShortMode": "Short-Modus",
    "chart.menu.modify": "ändern",
    "chart.menu.PlateIndicatorDublicate": "Doppeltes Indikator",
    "chart.menu.PlateIndicatorRemove": "Schließen",
    "chart.menu.PlateIndicatorSettings": "Einstellungen",
    "chart.menu.PlateIndicatorVisible": "Sichtbar",
    "chart.menu.PlateOverlay.Overlay": "Überlagerung",
    "chart.menu.PlateOverlayRemove": "Schließen",
    "chart.menu.PlateOverlaySettings": "Einstellungen",
    "chart.menu.PlateOverlayVisible": "Sichtbar",
    "chart.menu.rollback": "Rollback-Position",
    "chart.noAvailableData": "Keine verfügbaren Daten für",
    "chart.NoData": "Keine Daten",
    "chart.priceScaleContextMenu.AutoScale": "Auto",
    "chart.priceScaleContextMenu.FitDay": "Fit Tag High/Low",
    "chart.priceScaleContextMenu.FitDrawings": "Zeichnungen anpassen",
    "chart.priceScaleContextMenu.FitIndicators": "Fit Indikatoren",
    "chart.priceScaleContextMenu.FitOrdersPositions": "Fit Aufträge / Positionen",
    "chart.priceScaleContextMenu.ManualScale": "Handbuch",
    "chart.refresh": "Aktualisierung",
    "chart.Remove": "Entfernen",
    "chart.shortPeriodName.m": "m",
    "chart.shortPeriodName.ticks": "ticks",
    "chart.ShowHighLow.Close": "Schließen",
    "chart.ShowHighLow.High": "Hoch",
    "chart.ShowHighLow.Low": "Niedrig",
    "chart.StyleButton.ToolTip": "Stil",
    "chart.tic.short.15min.name": "15m",
    "chart.tic.short.1day.name": "1D",
    "chart.tic.short.1hour.name": "1H",
    "chart.tic.short.1min.name": "1m",
    "chart.tic.short.1tic.name": "Tick",
    "chart.tic.short.30min.name": "30m",
    "chart.tic.short.4hour.name": "4H",
    "chart.tic.short.5min.name": "5m",
    "chart.tic.short.month.name": "1M",
    "chart.tic.short.range.name": "Umfang",
    "chart.tic.short.second.name": "s",
    "chart.tic.short.week.name": "1W",
    "chart.tic.short.year.name": "1Y",
    "chart.timeframeselector.rangeButton.ToolTip": "Zeitraum der Datenanzeige auswählen",
    "chart.timeframeselector.time": "Zeit",
    "chart.timeScaleContextMenu.ShowAllSessions": "Erweiterte Sitzungen anzeigen",
    "chart.timeScaleContextMenu.ShowDaySeparator": "Trennzeichen der Tage anzeigen",
    "chart.timeScaleContextMenu.ShowEmptyBars": "Lücken in der Historie anzeigen",
    "chart.timeScaleContextMenu.ShowMonthSeparator": "Monatstrennzeichen anzeigen",
    "chart.timeScaleContextMenu.ShowTimePeriodsSeparator": "Zeittrennzeichen anzeigen",
    "chart.timeScaleContextMenu.ShowWeeksSeparator": "Trennzeichen der Wochen anzeigen",
    "chart.timeScaleContextMenu.ShowYearSeparator": "Jahrestrennzeichen anzeigen",
    "chart.tool.bar": "Balken",
    "chart.tool.candle": "Kerze",
    "chart.tool.clone": "Klon",
    "chart.tool.Dash": "Dash",
    "chart.tool.delete": "Еntfernen",
    "chart.tool.deleteAll": "Alles entfernen",
    "chart.tool.dot": "Punkte",
    "chart.tool.dotline": "Punktierte Linie",
    "chart.tool.DottedLine": "Punktierte Linie",
    "chart.tool.forest": "Histogramm",
    "chart.tool.IsoDot": "Iso Punkt",
    "chart.tool.line": "Linie",
    "chart.tool.properties": "Einstellungen",
    "chart.tool.ShapedLine": "Shaped line",
    "chart.tool.solid": "Bereich",
    "chart.tools.ABCPatern": "ABC-Muster",
    "chart.tools.AndrewPitchfork": "Andrew's pitchfork",
    "chart.tools.backcolor": "Farbe des Hintergrundes",
    "chart.tools.ButterflyGartley": "Gartley Schmetterling",
    "chart.tools.Circle": "Kreis",
    "chart.tools.default": "Zeiger",
    "chart.tools.Ellipse": "Ellipse",
    "chart.tools.eraser": "Löscher",
    "chart.tools.FibArc": "Fibonacci Arc",
    "chart.tools.FibEllipses": "Fibonacci Ellipsen",
    "chart.tools.FibExp": "Fibonacci Erweiterung",
    "chart.tools.FibFan": "Fibonacci fans",
    "chart.tools.FibPhiChannel": "Fibonacci Phi-Kanal",
    "chart.tools.Fibretr": "Fibonacci retracement",
    "chart.tools.FibSpiral": "Fibonacci Spirale",
    "chart.tools.FibTga": "Fibonacci Zeitzielanalyse",
    "chart.tools.FibTz": "Fibonacci Zeitzone",
    "chart.tools.GannFan": "Gann fan",
    "chart.tools.GannGrid": "Gann Gitter",
    "chart.tools.GannLine": "Gann Linie",
    "chart.tools.horizontal": "Horizontale Linie",
    "chart.tools.infoline": "Ruler",
    "chart.tools.Line": "Linie",
    "chart.tools.mirrored": "Mirror-Aussicht aktivieren",
    "chart.tools.points": "Punktzahl",
    "chart.tools.Polygon": "Polygon",
    "chart.tools.PPZ": "PPZ(Pricepivotzone)",
    "chart.tools.PriceChannel": "Preiskanal",
    "chart.tools.Rectangle": "Rechteck",
    "chart.tools.RegressionChanel": "Regressionskanal",
    "chart.tools.ScalingByRect": "Skalierung durch Rechteck",
    "chart.tools.selection": "Auswahl",
    "chart.tools.snapToCandle": "Snapto Kerze",
    "chart.tools.StayDrawingMode": "Im Zeichenmodus bleiben",
    "chart.tools.symbol": "Mark",
    "chart.tools.text": "Kommentare",
    "chart.tools.TopBottomPattern": "Oben/Unten-Modell",
    "chart.tools.Triangle": "Dreieck",
    "chart.tools.vertical": "Vertikale Linie",
    "chart.view": "Ansicht",
    "chart.view.analyseGroupMenu": "Analyseinstrumente",
    "chart.view.analysetoolbar": "Analyseinstrumente",
    "chart.view.barsToRight": "Balken rechts...",
    "chart.view.colors": "Einstellungen",
    "chart.view.dataInfo": "Informationsfenster",
    "chart.view.Day_HighLow": "Tag hoch / tief",
    "chart.view.drawingtoolbar": "Zeichnungs-Panel",
    "chart.view.font": "Axis font",
    "chart.view.landscapequestion": "Papier Gestaltung im Querformat verwenden?",
    "chart.view.merge": "Fortlaufenden Linien",
    "chart.view.orderEntry": "Chart Ordereingabe",
    "chart.view.periods": "Abstände...",
    "chart.view.previousCloseLine": "Vorherige schließen",
    "chart.view.PrevSettlementPriceLine": "Vorherige Abrechnungspreis",
    "chart.view.scroller": "Scrollleiste",
    "chart.view.setOrder": "Reihenfolge festlegen",
    "chart.view.SettlementPriceLine": "Settlement price",
    "chart.view.show_cross": "Cursor verfolgen",
    "chart.view.show_history": "Handelshistorie anzeigen",
    "chart.view.show_set_zero": "Leere Balken anzeigen",
    "chart.view.showEvents": "Orders anzeigen",
    "chart.view.showNews": "News anzeigen",
    "chart.view.showOrders": "Orders anzeigen",
    "chart.view.showPositions": "Positionen anzeigen",
    "chart.view.source": "Datentyp",
    "chart.view.source0": "Voreinstellung durch Symbol",
    "chart.view.source1": "Bid",
    "chart.view.source1000": "Ask",
    "chart.view.source1001": "Bid/Ask im Durchschnitt",
    "chart.view.source1002": "min (Bid) and max (Ask)",
    "chart.view.source4": "zuletzt",
    "chart.view.sourceBID/ASK": "Nach Bid/Ask",
    "chart.view.sourceTick1": "Nach Bid/Ask",
    "chart.view.TimeToNextBar": "Zeit bis zur nächsten Kerze",
    "chart.view.toolbar": "Werkzeugleiste",
    "chart.view.volume": "Volumen anzeigen",
    "chart.visualTrading.Invalid price": "Ungültiger Preis",
    "chart.volumeColoringMode.ByBar": "Nach Balken",
    "chart.volumeColoringMode.ByDifference": "Nach Differenz",
    "chart.volumeColoringMode.Fixed": "Fest",
    
    "chart.quickTrading.Buy limit": "Buy Limit",
    "chart.quickTrading.Buy stop": "Buy Stop",
    "chart.quickTrading.Invalid price": "ungültiger preis",
    "chart.quickTrading.Sell limit": "Sell Limit",
    "chart.quickTrading.Sell stop": "Sell Stop",
    "Commodity Channel Index": "Commodity channel index",
    "connect.connection.connect.error": "Falsche Verbindungsparameter",
    "connect.connection.unknown.error": "Unbekannter Fehler!",
    "Custom": "Benutzerdefiniert ",
    "Day": "Tag",
    "dealticket.RiskRuleWarning.Header": "Warnung vor Risikoregeln",
    "Deutsch": "#hidden#",
    "enum.AccountType.ClosedFund": "Geschlossene Fonds",
    "enum.AccountType.MultiAsset": "Multi asset",
    "enum.AccountType.OpenedFund": "Eröffneter Fonds",
    "enum.AccountType.SingleCCY": "Einzelne Währung ",
    "enum.AssetType.COMMODITIES": "Rohstoffe",
    "enum.AssetType.CRYPTO_CCY": "Krypto-Währung",
    "enum.AssetType.CURRENCY": "Währung",
    "enum.AssetType.SHARES": "Anteile",
    "ExchangeId.All": "Alle Börsen",
    "Exponential Moving Average": "Exponentieller gleitender Durchschnitt",
    "Externalpanels.MultiTimeAndSales.Header": "Multi Time & Sales",
    "FOK": "FOK",
    "editableComboBox.defaultList": "Standardliste",
    "editableComboBox.CreateNew": "Erstelle neu",
    "FullLogs.Cluster": "Server",
    "FullLogs.DateTime": "Zeit",
    "FullLogs.Fields": "Felder",
    "FullLogs.Message name": "Nachricht Namen",
    "FullLogs.Session": "Session",
    "general.Calculating": "Berechnung. . .",
    "general.connection.restErrorMsg": "Aktuell gibt es ein Problem beim Speichern von Einstellungen und Arbeitsbereichen. Bitte wenden Sie sich an die Support-Abteilung",
    "general.CUSTOM": "Benutzerdefiniert ",
    "general.Friday": "Freitag",
    "general.messageBox.cancel": "Stornieren",
    "general.messageBox.no": "Nein",
    "general.messageBox.ok": "OK",
    "general.messageBox.showNextTime": "Nächste Zeit anzeigen",
    "general.messageBox.yes": "Ja",
    "general.Monday": "Montag",
    "general.day1.Mo": "Mo",
    "general.day2.Tu": "Tu",
    "general.day3.We": "Wir",
    "general.day4.Th": "Th",
    "general.day5.Fr": "Fr",
    "general.day6.Sa": "Sa",
    "general.day7.Su": "Su",
    "general.month.1": "Jan",
    "general.month.10": "Oct",
    "general.month.11": "Nov",
    "general.month.12": "Dez",
    "general.month.2": "Feb",
    "general.month.3": "Mar",
    "general.month.4": "Apr",
    "general.month.5": "Mai",
    "general.month.6": "Jun",
    "general.month.7": "Jul",
    "general.month.8": "Aug",
    "general.month.9": "Sep",
    "general.monthFullName.1": "Januar",
    "general.monthFullName.10": "October",
    "general.monthFullName.11": "November",
    "general.monthFullName.12": "Dezember",
    "general.monthFullName.2": "Februar",
    "general.monthFullName.3": "März",
    "general.monthFullName.4": "April",
    "general.monthFullName.5": "Mai",
    "general.monthFullName.6": "Juni",
    "general.monthFullName.7": "Juli",
    "general.monthFullName.8": "August",
    "general.monthFullName.9": "September",
    "general.N_A": "N/A",
    "general.OvernightMarginNotificationMessage": "Übernachtungsbenachrichtigung für die Margin anzeigen",
    "general.Saturday": "Samstag",
    "general.Settings": "Einstellungen",
    "general.Sunday": "Sonntag",
    "general.Thursday": "Donnerstag",
    "general.trading.at": "zu",
    "general.trading.Buy": "Buy",
    "general.trading.confirmation": "Bestätigung",
    "general.trading.confirmation.prefix": "",
    "general.trading.confirmationString": "{1} {2} {3} {4} {5} {6} {7} {8} {9} {10} {14} {13} {14} {15} {11} {12} {16}",
    "general.trading.for": "für",
    "general.trading.limitBuyLessAsk": "Limit Kaufauftragspreis übersteigt Marktpreis, Auftrag wird sofort ausgeführt.",
    "general.trading.limitSellMoreBid": "Limit Der Verkaufspreis der Bestellung liegt unter dem Marktpreis. Die Bestellung wird sofort ausgeführt.",
    "general.trading.marketCloseFor": "Marktschluss für",
    "general.trading.pips": "ticks",
    "general.trading.points": "Punkte",
    "general.trading.position": "Position",
    "general.trading.position.Buy": "Lang",
    "general.trading.position.Sell": "Short",
    "general.trading.priceNotAvailable": "Nicht verfügbar",
    "general.trading.refused": "Verweigert",
    "general.trading.Sell": "Verkaufen",
    "general.trading.stopBuyMoreAsk": "Stop Buy Orderpreis liegt unter dem Ask Marktpreis  Auftrag wird sofort gefüllt.",
    "general.trading.stopSellLessBid": "Stop Sell Auftragspreis ist mehr als Market Bid Preis , Auftrag wird sofort erfüllt",
    "general.trading.with offset": "mit Abstand",
    "general.Tuesday": "Dienstag",
    "property.general.WarnIfWrongOrderParam": "Warnen, wenn die Order falsch ist",
    "general.Wednesday": "Mittwoch",
    "GTC": "GTC",
    "helpPage.link": "https://guide.traderevolution.com/project/",
    "homePage.link": "http://traderevolution.com/",
    "Ichimoku": "Ichimoku",
    "Instrument.Futures.contract": "Kontrakt",
    "Instrument.Level1.ExtendedFields.NormalMarketSize": "Normale Marktgröße",
    "Instrument.Spreadbet.bet": "Wetten",
    "InstrumentDetailsPanel.(Indicative symbol)": "(Indikatives Symbol)",
    "InstrumentDetailsPanel.1.GeneralInfo": "Allgemeine Information",
    "InstrumentDetailsPanel.2.TradingInfo": "Handelsinfo",
    "InstrumentDetailsPanel.3.MarginReg": "Marginanforderungen",
    "InstrumentDetailsPanel.4.Fees": "Gebühren",
    "InstrumentDetailsPanel.5.Rebates": "Rabatte",
    "InstrumentDetailsPanel.6.SessionInfo": "Vorläufiger Zeitplan für den Markt",
    "InstrumentDetailsPanel.7.Plans": "Pläne",
    "InstrumentDetailsPanel.AccruedInterest": "Aufgelaufener Zins",
    "InstrumentDetailsPanel.AddLiqudityRebateOrderPerLot": "Fügen Sie Liquidität-Rabattorder pro Lot hinzu",
    "InstrumentDetailsPanel.AddLiqudityRebatePerLot": "Fügen Sie Liquidität-Rabatt pro Lot hinzu",
    "InstrumentDetailsPanel.AddLiqudityRebatePerVolume": "Fügen Sie einen Liquiditätsrabatt pro Volumen hinzu",
    "InstrumentDetailsPanel.AFTER_MARKET": "Nach dem Markt",
    "InstrumentDetailsPanel.Allow short positions": "Ja",
    "InstrumentDetailsPanel.AllowedOperations": "zugelassene Operationen",
    "InstrumentDetailsPanel.AllowedOrderTypes": "Zulässige Orderarten",
    "InstrumentDetailsPanel.AllowShortPositions": "Alle Sell-Positionen zulassen",
    "InstrumentDetailsPanel.Amount": "Menge",
    "InstrumentDetailsPanel.AssetClass": "Symboltyp",
    "InstrumentDetailsPanel.BEFORE_MARKET": "Vor dem Markt",
    "InstrumentDetailsPanel.Cash": "Bargeld",
    "InstrumentDetailsPanel.Close": "Schließen",
    "InstrumentDetailsPanel.Closed": "Geschlossen",
    "InstrumentDetailsPanel.CloseOutDeadline": "Fristablauf",
    "InstrumentDetailsPanel.Commisions": "Kommissionen",
    "InstrumentDetailsPanel.ContactMonth": "Monatlicher Kontrakt",
    "InstrumentDetailsPanel.ContractMultiplier": "#hidden#",
    "InstrumentDetailsPanel.ContractSize": "Kontrakt-Größe",
    "InstrumentDetailsPanel.CouponCycle": "Zinssatz Zyklos",
    "InstrumentDetailsPanel.CouponRate": "Zinssatz, %",
    "InstrumentDetailsPanel.CurrentSession": "Aktuelle Sitzung",
    "InstrumentDetailsPanel.DeliveryMethod": "Lieferungsmethode",
    "InstrumentDetailsPanel.DeliveryStatus": "Lieferstatus",
    "InstrumentDetailsPanel.Depend from account": "Vom Konto abhängig",
    "InstrumentDetailsPanel.Description": "Beschreibung",
    "InstrumentDetailsPanel.Exchange": "Exchange",
    "InstrumentDetailsPanel.ExchangeTradeSession": "Exchange- Sitzung ",
    "InstrumentDetailsPanel.Exp1": "Anlage",
    "InstrumentDetailsPanel.FaceValue": "Nennwert",
    "InstrumentDetailsPanel.FirstTradeDate": "erster Handelstag",
    "InstrumentDetailsPanel.Futures": "Futures",
    "InstrumentDetailsPanel.FuturesClass": "Futurklasse",
    "InstrumentDetailsPanel.HiLimit": "Hoch-Limit ",
    "InstrumentDetailsPanel.Holiday": "Feiertag",
    "InstrumentDetailsPanel.HolidayDate": "Daten",
    "InstrumentDetailsPanel.HolidayName": "Feiertagsname",
    "InstrumentDetailsPanel.Holidays list": "Feiertagsübersicht",
    "InstrumentDetailsPanel.HolidayType": "Zeitplan",
    "InstrumentDetailsPanel.immediate": "sofort",
    "InstrumentDetailsPanel.KEYNextHoliday": "Nächster Feiertag",
    "InstrumentDetailsPanel.LastTradeDate": "Letztes Orderdatum",
    "InstrumentDetailsPanel.LotSize": "Lotsgröße",
    "InstrumentDetailsPanel.LotStep": "Lot-Schritt",
    "InstrumentDetailsPanel.LowLimit": "Unteres Limit",
    "InstrumentDetailsPanel.MAIN": "Main",
    "InstrumentDetailsPanel.Margin": "Marginanforderung",
    "InstrumentDetailsPanel.Margin_ByAccount": "Margin pro Konto",
    "InstrumentDetailsPanel.MarginBuy": "Buy",
    "InstrumentDetailsPanel.MarginDay": "Tag",
    "InstrumentDetailsPanel.MarginDayBuy": "Tageskauf",
    "InstrumentDetailsPanel.MarginDaySell": "Tagesverkauf",
    "InstrumentDetailsPanel.MarginInAccountCurrency": "#hidden#",
    "InstrumentDetailsPanel.MarginOvernight": "Über Nacht",
    "InstrumentDetailsPanel.MarginOvernightBuy": "Übernacht kaufen",
    "InstrumentDetailsPanel.MarginOvernightSell": "Über Nacht verkaufen",
    "InstrumentDetailsPanel.MarginSell": "Verkaufen",
    "InstrumentDetailsPanel.MaturityDate": "Fälligkeitstag",
    "InstrumentDetailsPanel.MaximumLot": "Maximale Lot",
    "InstrumentDetailsPanel.MaxPositionQtyPerSymbol": "maximale Anzahl von Positionen",
    "InstrumentDetailsPanel.MinimalLot": "Minimale Lot",
    "InstrumentDetailsPanel.NextPaymentDate": "Nächste Zinszahlung",
    "InstrumentDetailsPanel.No": "Nein",
    "InstrumentDetailsPanel.non trading day": "Schließen",
    "InstrumentDetailsPanel.None": "Kein",
    "InstrumentDetailsPanel.NormalMarketSize": "#hidden#",
    "InstrumentDetailsPanel.Not allow short positions": "Nein",
    "InstrumentDetailsPanel.NoticeDate": "Kündigungsdatum",
    "InstrumentDetailsPanel.onlyCloseOrders": "Nur geschlossene Aufträge",
    "InstrumentDetailsPanel.Open": "Öffnen",
    "InstrumentDetailsPanel.OrderPerLot": "Auftrag pro Lot",
    "InstrumentDetailsPanel.PerFill": "Per fill",
    "InstrumentDetailsPanel.PerLot": "pro Lot füllen",
    "InstrumentDetailsPanel.PerOrder": "Pro Auftrag",
    "InstrumentDetailsPanel.PerOrderVolume": "Auftragsvolumen %",
    "InstrumentDetailsPanel.PerPhoneTransaction": "Per Telefonbuchung",
    "InstrumentDetailsPanel.PerTransaction": "Pro Transaktion",
    "InstrumentDetailsPanel.PerVolume": "Volumen füllen, %",
    "InstrumentDetailsPanel.Physically": "Physikalisch",
    "InstrumentDetailsPanel.POSTCLOSE": "Post-Markt",
    "InstrumentDetailsPanel.PREOPEN": "Pre-Markt",
    "InstrumentDetailsPanel.PreviousPaymentDate": "Bisherige Ertragszahlung",
    "InstrumentDetailsPanel.Price": "Preis",
    "InstrumentDetailsPanel.ProductType": "Produktart",
    "InstrumentDetailsPanel.QuotiongCurrency": "Quoting Währung",
    "InstrumentDetailsPanel.RemoveLiqudityRebateOrderPerLot": "Entfernen Sie die Liquiditätsrabattorder pro Lot",
    "InstrumentDetailsPanel.RemoveLiqudityRebatePerLot": "Entfernen Sie den Liquiditätsrabatt pro Lot",
    "InstrumentDetailsPanel.RemoveLiqudityRebatePerVolume": "Entfernen Sie den Liquiditätsrabatt pro Volumen",
    "InstrumentDetailsPanel.SessionStatus": "Sitzungsstatus",
    "InstrumentDetailsPanel.SettlementDate": "Settlement date",
    "InstrumentDetailsPanel.shortened": "verkürzerter Tag",
    "InstrumentDetailsPanel.StrikePrice": "Strike price",
    "InstrumentDetailsPanel.SwapBuy": "Long swap",
    "InstrumentDetailsPanel.SwapSell": "Short swap",
    "InstrumentDetailsPanel.Symbol": "Symbol",
    "InstrumentDetailsPanel.T+0": "T + 0 (heute)",
    "InstrumentDetailsPanel.TickCoast": "Tick Preis",
    "InstrumentDetailsPanel.TickSize": "Tick Größe",
    "InstrumentDetailsPanel.TradindBlockedOnSession": "Handel auf Sitzung blockiert",
    "InstrumentDetailsPanel.TradingBalance": "Settlement system",
    "InstrumentDetailsPanel.TradingHalt": "Handel halt",
    "InstrumentDetailsPanel.TradingStatus": "Symbolstatus",
    "InstrumentDetailsPanel.Underlier": "Underlier",
    "InstrumentDetailsPanel.VAT": "MwSt.",
    "InstrumentDetailsPanel.Volume": "Volumen füllen",
    "InstrumentDetailsPanel.VolumeWithMinPD": "Füllvolumen %, mit min p / d",
    "InstrumentDetailsPanel.working": "Öffnen",
    "InstrumentDetailsPanel.Yes": "Ja",
    "InstrumentDetailsPanel.Yield": "Ertrag",
    "InstrumentDetailsPanel.YTM": "Verfallrendite (YTM), %",
    "InstrumentDetailsPanel.YTM.RefreshLink": "Aktualisierung",
    "IOC": "IOC",
    "IsAllowedResponceReason.ExecuteAtMarketNotAllowedForBindOrders": "Als Market ausführen ist nicht für gebundene Aufträge erlaubt",
    "IsAllowedResponceReason.ExecuteAtMarketNotAllowedForSlTp": "Marktausführung aufgrund SL/TP nicht erlaubt ",
    "IsAllowedResponceReason.ExecuteAtMarketNotAllowedOrderType": "Eine Änderung der Orderart oder der Marktorderart ist nicht zulässig",
    "IsAllowedResponceReason.InstrumentForAccountDelivery": "Instrument zur Kontoauslieferung",
    "IsAllowedResponceReason.InstrumentTradingMode": "Handeln von Instrumenten ist nicht erlaubt",
    "IsAllowedResponceReason.LockTrading": "Handel ist in der Plattform gesperrt",
    "IsAllowedResponceReason.LockTradingByPassword": "Bitte geben Sie das Trading-Passwort am unteren Rand des Terminals ein",
    "IsAllowedResponceReason.NoLastQuote": "Keine letze Quotierung",
    "IsAllowedResponceReason.NotAllowedByOrderStaus": "Vorgänge sind nach Auftragsstatus nicht zulässig",
    "IsAllowedResponceReason.NotAllowedRouteOrderType": "Die Auftrag kann nicht erstellt werden, da das ausgewählte validity und der Auftragstyp nicht unterstützt werden.",
    "IsAllowedResponceReason.NotClosablePosition": "Nicht schließbare Position",
    "IsAllowedResponceReason.NotLoadCache": "Cache wurde nicht geladen",
    "IsAllowedResponceReason.NotVisibleInstrumentRoute": "kein sichtbares Instrument oder Route",
    "IsAllowedResponceReason.OperationNotAllowedByOrderType": "Der Vorgang ist nach Auftragsart nicht zulässig",
    "IsAllowedResponceReason.OperationNotAllowedBySession": "Die Operation ist für die Sitzung nicht zulässig",
    "IsAllowedResponceReason.OrderTypeNotAllowedBySession": "Der Auftragstyp ist für die Sitzung nicht zulässig",
    "IsAllowedResponceReason.RouteNotRunning": "Handeln von Instrumenten ist nicht erlaubt.",
    "IsAllowedResponceReason.RuleAdministration": "Regelverwaltung",
    "IsAllowedResponceReason.RuleClosePositionDisable": "Das Schließen von Positionen ist nach den Regel nicht zulässig",
    "IsAllowedResponceReason.RuleFunctionSelfTrading": "Regel Funktionsselbsthandel",
    "IsAllowedResponceReason.RuleFunctionTrading": "Handel ist nach Regel nicht erlaubt",
    "IsAllowedResponceReason.RuleOCO": "OCO Regel",
    "IsAllowedResponceReason.RuleSlTpNotAllowed": "SL / TP ist nach Regel nicht zulässig",
    "IsAllowedResponceReason.RuleTrStop": "Regel Tr.stop",
    "IsAllowedResponceReason.Unknown": "Unbekannter Grund",
    "Keltner Channel": "Keltner-Kannal",
    "Limit": "Grenze",
    "Linear Weighted Moving Average": "Linear gewichteter gleitender Durchschnitt",
    "LOC": "LOC",
    "LOO": "LOO",
    "Manual": "Handbuch",
    "Market": "Market",
    "MarketDepthPanel.ColoringMethod.ByPriceLVL": "nach Preisniveau",
    "MarketDepthPanel.ColoringMethod.RelativeToVolume": "Bezogen auf das Volumen",
    "MarketDepthPanel.ColoringMethod.SizeHistogram": "Größe des Histogramms",
    "McGinley Dynamic": "McGinley Dynamic",
    "MDLotsMode.General": "Allgemeine Standardeinstellungen ({1})",
    "MDLotsMode.Lots": "Lots",
    "MDLotsMode.RealSize": "Wirkliche Größe",
    "MessageBox.ThemeChanged.Title": "Warnung",
    "MOC": "MOC",
    "Modified Moving Average": "Moving average geändert",
    "Momentum": "Schwung",
    "MOO": "MOO",
    "Moving Average Envelope": "Moving average envelope",
    "Moving Average/Convergence Divergence": "Moving average/Convergence Divergence",
    "NetTrader.SaveWorkspace.Save": "Arbeitsbereich speichern",
    "NumericComboCtrl.Edit": "Set defaults",
    "OCO": "OCO",
    "On Balance Volume": "On Balance volume",
    "OnClose": "On Close",
    "OnOpen": "Beim Öffnen",
    "Order.ExecutionType.ACCEPTED": "Akzeptiert",
    "Order.ExecutionType.ACTIVATED": "Aktiviert",
    "Order.ExecutionType.CANCELED": "Storniert",
    "Order.ExecutionType.FILLED": "Gefüllt",
    "Order.ExecutionType.NEW": "Erstellt",
    "Order.ExecutionType.PART_FILLED": "Teilweise gefüllt",
    "Order.ExecutionType.REFUSED": "Abgelehnt",
    "Order.ExecutionType.REPLACED": "Geändert",
    "Order.ExecutionType.RESTATED": "Neu Formuliert",
    "Order.ExecutionType.UNKNOWN": "Unbekannt",
    "OrderEntry.InfoBlock.After trade funds": "Kapital nach Trade",
    "OrderEntry.InfoBlock.Availible funds": "Verfügbare Mittel",
    "OrderEntry.InfoBlock.Balance": "Bilanz",
    "OrderEntry.InfoBlock.Fee": "Gebühr",
    "OrderEntry.InfoBlock.Gross": "Brutto",
    "OrderEntry.InfoBlock.Impact on portfolio": "Auswirkungen auf das Portfolio",
    "OrderEntry.InfoBlock.Init. margin": "Drin. Margenanforderung",
    "OrderEntry.InfoBlock.Maint. margin": "Maint. Margenanforderung",
    "OrderEntry.InfoBlock.Margin availible": "Freie Margin",
    "OrderEntry.InfoBlock.Net": "Netto",
    "OrderEntry.InfoBlock.Spread in loss": "Spread init. loss",
    "OverlayScreen.barsBodyLabel": "Body",
    "OverlayScreen.barsBorderLabel": "Leiste",
    "OverlayScreen.barsHiLowLabel": "Linie",
    "OverlayScreen.cancelButton": "Stornieren",
    "OverlayScreen.dataLabel": "Datentyp",
    "OverlayScreen.dojiLabel": "Doji",
    "OverlayScreen.histogramLineLabel": "Histogramlinie",
    "OverlayScreen.instrumentLabel": "Symbol",
    "OverlayScreen.Main scale": "Hauptskala",
    "OverlayScreen.name": "Überlagerung",
    "OverlayScreen.numberOverlaysError": "Sie können nicht mehr als 5 Überlagerungen hinzufügen.",
    "OverlayScreen.okButton": "OK",
    "OverlayScreen.Overlays": "Überlagerungen",
    "OverlayScreen.priceLabel": "Preisstaffelung",
    "OverlayScreen.priceMarkerCheckbox": "Preismarkeirung anzeigen",
    "OverlayScreen.Separate scale": "Separate Skale ",
    "OverlayScreen.solidPriceLabel": "Bereich",
    "OverlayScreen.styleLabel": "Stil",
    "OverlayScreen.wickLabel": "Docht",
    "panel.accountDetails.Groups.1.General": "Allgemeines",
    "panel.accountDetails.Groups.2.Margin": "Margin",
    "panel.accountDetails.Groups.3.AccountActivity": "Kontobewegung",
    "panel.accountDetails.Groups.4.TodayResults": "Tagesergebniss",
    "panel.accountDetails.Groups.5.RiskManagement": "Risikomanagement",
    "panel.accountDetails.Groups.55.Plans": "Pläne",
    "panel.accountDetails.Groups.6.Info": "Info",
    "panel.AccountDetailsPanel": "Kontodaten",
    "panel.AccountDetailsPanel.InfoTooltip": "Ändern Sie die Felder im Widget per Drag & Drop aus der Tabelle",
    "panel.AccountLink.SymbolLink.ToolTip": "Symbol link",
    "panel.AccountLink.ToolTip": "Konto-Verknüpfung",
    "panel.accounts": "Konten",
    "panel.accounts.AccountDescription": "#hidden#",
    "panel.accounts.accountDetails.RiskManagement.Trading.Daily loss limit reached": "Das tägliche Verlustlimit wurde erreicht.",
    "panel.accounts.accountDetails.RiskManagement.Trading.Max day volume  reached": "maximale Volumen pro Tag wurde erreicht",
    "panel.accounts.accountDetails.RiskManagement.Trading.Order Flow Limit(per day)": "Bestellflusslimit (pro Tag)",
    "panel.accounts.accountDetails.RiskManagement.Trading.State.Disabled": "deaktiviert",
    "panel.accounts.accountDetails.RiskManagement.Trading.State.Enabled": "Aktiviert",
    "panel.accounts.accountDetails.RiskManagement.TradingStatus.Active": "Aktiv",
    "panel.accounts.accountDetails.RiskManagement.TradingStatus.Auto closed": "Auto Schließung",
    "panel.accounts.accountDetails.RiskManagement.TradingStatus.Closed": "Geschlossen",
    "panel.accounts.accountDetails.RiskManagement.TradingStatus.Disabled by risk rules": "Handel durch Risikoregeln deaktiviert",
    "panel.accounts.accountDetails.RiskManagement.TradingStatus.MarginWarning": "Warnung vor einem möglichen Stopout",
    "panel.accounts.accountDetails.RiskManagement.TradingStatus.Max trailing drawdown was reached": "Maximaler Trailing Drawdown wurde erreicht.",
    "panel.accounts.accountDetails.RiskManagement.TradingStatus.Suspend": "Aussetzen",
    "panel.accounts.accountDetails.RiskManagement.TradingStatus.Unrealized loss limit reached": "Das nicht realisierte Verlustlimit erreicht",
    "panel.accounts.accountDetails.RiskManagement.TradingStatus.Weekly lost limit reached": "Wöchentliche Verlustgrenze wurder erreicht",
    "panel.accounts.AccountMode": "Konto-Modus",
    "panel.accounts.AccountType": "Kontotyp",
    "panel.accounts.AccountValue": "Kontowert",
    "panel.accounts.AllowOvernightTrading": "Handel über Nacht zulassen",
    "panel.accounts.AllowTradingPrePost": "Handel an Pre / Post-Märkten",
    "panel.accounts.Asset": "Anlage",
    "panel.accounts.AssetDescription": "Anlagebeschreibung",
    "panel.accounts.AssetType": "Assetklasse",
    "panel.accounts.AvailableMargin": "Verfügbare Mittel",
    "panel.accounts.Balance": "Bilanz",
    "panel.accounts.BalancePlusAllRisks": "Bilanz & Alle Risiken",
    "panel.accounts.BeginBalance": "Tages Netto",
    "panel.accounts.BlockedByOrders": "Margin für Orders verwendet",
    "panel.accounts.BlockedByPamm": "Investiertes Fondskapital",
    "panel.accounts.BlockedForStocks": "#hidden#",
    "panel.accounts.BlockedForStocksNew": "Hinterlegt für Aktien",
    "panel.accounts.CashBalance": "Bargeldsaldo",
    "panel.accounts.ClientType": "Terminaltyp",
    "panel.accounts.Commissions": "Kommissionen",
    "panel.accounts.CreditValue": "Kreditwert",
    "panel.accounts.CurBalance": "Geplannte Bilanz",
    "panel.accounts.CurMargin": "Abfangsmarginanforderung, %",
    "panel.accounts.CurrentDailyLoss": "Täglicher Verlust",
    "panel.accounts.CurrentFundCapital": "Aktuelles Fondskapital",
    "panel.accounts.CurrentStatus": "Handelsstatus",
    "panel.accounts.CurrentWeeklyLoss": "Wöchentlicher Verlust",
    "panel.accounts.Email": "Email",
    "panel.accounts.Estimate value": "Schätzwert",
    "panel.accounts.FundCapitalGain": "Kapitalgewinn des Fonds",
    "panel.accounts.FundsUsed": "Mittel verwendet",
    "panel.accounts.FundsUsedPercent": "Mittel verwendet, %",
    "panel.accounts.GrossPNL": "Tages Brutto",
    "panel.accounts.InsertOnBalance": "Im Gleichgewicht einsetzen",
    "panel.accounts.InterestRate": "Zinssatz, %",
    "panel.accounts.IntradayRiskManagement": "Risikomanagement-Status",
    "panel.accounts.Leverage": "Hebel",
    "panel.accounts.LockedBalance": "Hinterlegte Bilanz",
    "panel.accounts.LockedBy": "verschlossen",
    "panel.accounts.Login": "Konto",
    "panel.accounts.MaintMargin": "Maint. Margenanforderung, %",
    "panel.accounts.MarginAndRisks": "Margin und Risiken",
    "panel.accounts.MarginAvailableReal": "freie Margin",
    "panel.accounts.MarginDeficiency": "Margin-Mangel",
    "panel.accounts.MarginForOthers": "#hidden#",
    "panel.accounts.MarginRequirement": "Pos. maint. req.",
    "panel.accounts.MarginStopOut": "Maint. Margenanforderung",
    "panel.accounts.MarginStopTrade": "Der Handel stoppen",
    "panel.accounts.MarginSurplus": "Margenüberschuss",
    "panel.accounts.MarginUsedNew": "Abfangsmarginanforderung",
    "panel.accounts.MarginWarning": "Warnung Margin",
    "panel.accounts.marginWarnPercent": "Margin-Warnstufe",
    "panel.accounts.MarketDataCharging": "Marktdaten werden abgerechnet",
    "panel.accounts.MaxDailyLossLimit": "Tägliches Verlustlimit",
    "panel.accounts.MaxDailyProfit": "Tägliches Gewinnziel",
    "panel.accounts.MaxDayVolume": "#hidden#",
    "panel.accounts.MaximumLot": "maximale Lot",
    "panel.accounts.MaxOrderAmount": "Maximale Anzahl von Positionen",
    "panel.accounts.MaxOrderCapital": "Max. Auftragskapital",
    "panel.accounts.MaxOrdersPerDay": "# Bestellflusslimit (pro Tag)",
    "panel.accounts.MaxPendingOrders": "# Maximale Pending-Orders",
    "panel.accounts.MaxPendingOrdersValue": "Max. Auftragswert",
    "panel.accounts.MaxPositionQtyPerSymbol": "maximale Anzahl pro Sybol",
    "panel.accounts.MaxPositions": "# Maximale Positionen",
    "panel.accounts.MaxWeekLostLimit": "Wöchentliche Verlustgrenze",
    "panel.accounts.MinMargin": "Margin für Positionen verwendet",
    "panel.accounts.Murkups": "Markups",
    "panel.accounts.N/A_TelephoneNumber": "N/A",
    "panel.accounts.OptionPremiumReq": "Optionsprämie erforderlich",
    "panel.accounts.OptionValue": "Optionswert",
    "panel.accounts.Orders": "# Orders",
    "panel.accounts.Owner": "Inhaber",
    "panel.accounts.Positions": "# Positionen",
    "panel.accounts.PowerOfAttorney": "Vollmacht",
    "panel.accounts.Profit": "Offener brutto G/V",
    "panel.accounts.ProfitNet": "Offener netto G/V",
    "panel.accounts.Role": "Fondstyp",
    "panel.accounts.Spreads": "Spreads",
    "panel.accounts.Status": "Status",
    "panel.accounts.Collateral": "#hidden#",
    "panel.accounts.StocksLiquidity": "Liquidität der Aktien",
    "panel.accounts.StocksOrdersReq": "Aktien Order angefragt",
    "panel.accounts.StocksValue": "Aktienwert",
    "panel.accounts.stopOutPercent": "Stop out level",
    "panel.accounts.stopTradingPercent": "Stop trading level",
    "panel.accounts.Swap": "Swap",
    "panel.accounts.TelephoneNumber": "Telefonnummer",
    "panel.accounts.TodayFees": "Tagesgebühren",
    "panel.accounts.TodayRebates": "Tagesrabatte",
    "panel.accounts.TodaySwaps": "#hidden#",
    "panel.accounts.TodayTrades": "# Tages Trades",
    "panel.accounts.TodayTurnover": "Tagesumsatz",
    "panel.accounts.TodayVolume": "Tages Volumen",
    "panel.accounts.TotalMaxPositionsQty": "Gesamte maximale Positionsanzahl",
    "panel.accounts.TrailingDrawdownLevel": "Trailing Drawdown Level",
    "panel.accounts.UnrealizedLoss": "Nicht realisierter Verlust",
    "panel.accounts.UnrealizedLossLimit": "Nicht realisiertes Verlustlimit",
    "panel.accounts.UnsettledCash": "nicht abgewickeltes Bargeld",
    "panel.accounts.UserGroup": "Benutzergruppe",
    "panel.accounts.UserID": "Benutzer ID",
    "panel.accounts.UserLogin": "Benutzer-Login",
    "panel.accounts.VolumeLimitForEquities": "Equities Volumenlimit",
    "panel.accounts.VolumeLimitForFutures": "Volumenlimit für Futures",
    "panel.accounts.VolumeLimitForOptions": "Optionsvolumebegrenzung",
    "panel.accounts.WithdrawalAvailable": "mögliche Auszahlung",
    "panel.assetBalances": "Konten",
    "panel.backoffice": "Backoffice",
    "panel.backoffice.You need to add connection!": "Sie müssen eine Verbindung hinzufügen!",
    "panel.positionsBalance": "Positionsbilanz",
    "panel.caption.NotAllowedMode": "Das aktuelle Bedienfeld ist in den Vorschriften nicht zulässig",
    "panel.caption.showHideToolbar": "Symbolleiste anzeigen",
    "panel.chart": "Chart",
    "panel.close": "Schließen",
    "panel.closePosition.Gross_P/L_ToClose": "Brutto PnL zum schließen",
    "panel.closePosition.QunatityToClose": "Menge zu schließen",
    "panel.copypanel": "doppeltes Panel ",
    "panel.detail": "Detailangebot",
    "panel.detail.AuctionEndTime": "#hidden#",
    "panel.detail.CBAuctionStart": "#hidden#",
    "panel.detail.change": "Änderung",
    "panel.detail.changePr": "Änderung, %",
    "panel.detail.CurrentSession": "#hidden#",
    "panel.detail.high": "Hoch",
    "panel.detail.last": "Zuletzt",
    "panel.detail.Limit.High": "#hidden#",
    "panel.detail.Limit.Low": "#hidden#",
    "panel.detail.low": "Niedrig",
    "panel.detail.NormalMarketSize": "#hidden#",
    "panel.detail.OffExchangeValue": "#hidden#",
    "panel.detail.OffExchangeVolume": "#hidden#",
    "panel.detail.open": "Öffnen",
    "panel.detail.TradedValue": "#hidden#",
    "panel.detail.TradingStatus": "#hidden#",
    "panel.detail.volume": "Volumen",
    "panel.EventLog.Action": "Veranstaltung",
    "panel.EventLog.Date": "Daten",
    "panel.EventLog.eventType.alerts": "Warnungen",
    "screen.Alerts.Ask": "Ask",
    "screen.Alerts.Bid": "Bid",
    "screen.Alerts.Last": "Zuletzt",
    "screen.Alerts.ChangePr": "Änderung, %",
    "screen.Alerts.Volume": "Volumen",
    "panel.EventLog.eventType.comment": "Kommentar",
    "panel.EventLog.eventType.disconnect": "Trennen",
    "panel.EventLog.eventType.exception": "Ausnahme",
    "panel.EventLog.eventType.info": "Info",
    "panel.EventLog.eventType.none": "Kein",
    "panel.EventLog.eventType.system": "System",
    "panel.EventLog.eventType.trading": "Handel",
    "panel.EventLog.eventType.updater": "Updater",
    "panel.EventLog.menu.Clear": "deutlich",
    "panel.EventLog.Message": "Detaile",
    "panel.EventLog.NamePanel": "Veranstaltung log",
    "panel.EventLog.Roundtrip": "Roundtrip, ms",
    "panel.EventLog.Type": "Typ",
    "panel.GridPanel.listButton.ToolTip": "Symbol für das Panel auswählen",
    "panel.GridPanel.refreshAllCharts.ToolTip": "Aktualisieren Sie alle Diagramme",
    "panel.GridPanel.resetMI.Text": "Panelgröße zurücksetzen",
    "panel.informer": "Watchlist",
    "panel.instrumentInfo.AvgPrice": "Durchschnittlicher Preis:",
    "panel.instrumentInfo.P/L": "G/V:",
    "panel.instrumentInfo.Qty": "Menge:",
    "panel.instruments": "Symbole",
    "panel.instrumentsInfo": "Symbole Info",
    "panel.knowledgebase": "Wissenbasis",
    "panel.level2": "Markttiefe",
    "panel.Level2.Age": "#hidden#",
    "panel.level2.Auction": " AUC",
    "panel.Level2.AvgPrice": "Durchschnittlicher Preis",
    "panel.Level2.Contr. CCY Total value": "Contr.CCY Gesamtwert",
    "panel.Level2.Contr. CCY value": "Contr CCY-Wert",
    "panel.Level2.DayTradeVolume": "Volumen",
    "panel.Level2.menu.View": "Ansicht",
    "panel.Level2.menu.View.InstrInfo": "Positionsleiste",
    "panel.Level2.menu.View.Level1": "Level1 bar",
    "panel.Level2.menu.View.Order Entry": "Auftragserfassung",
    "panel.Level2.MPID": "MPID",
    "panel.Level2.Number": "Order",
    "panel.Level2.oe.BuyButton": "Kaufen",
    "panel.Level2.oe.SellButton": "Verkaufen",
    "panel.Level2.Price": "Preis",
    "panel.Level2.Size": "Größe",
    "panel.Level2.Source": "Quelle",
    "panel.Level2.Time": "Zeit",
    "panel.Level2.TotalVol": "Gesamtgröße",
    "panel.log": "Veranstaltung log",
    "panel.menu.GroupBy": "Gruppe bei",
    "panel.menu.new": "Neu",
    "panel.menu.Remove": "Entfernen",
    "panel.menu.resetToDefault": "Auf Standardeinstellung zurücksetzen",
    "panel.neworder": "Auftragserfassung",
    "panel.newOrderEntry.accountLabel": "Konto",
    "panel.newOrderEntry.AddAskButton": "Sell Ask",
    "panel.newOrderEntry.AddBitButton": "Buy Bid",
    "panel.newOrderEntry.amountLabel": "Menge",
    "panel.newOrderEntry.BuyAskButton": "Buy Ask",
    "panel.newOrderEntry.BuyMarketButton": "Buy Market",
    "panel.newOrderEntry.buySideCheckBox": "Buy",
    "panel.newOrderEntry.instrumentLabel": "Symbol",
    "panel.newOrderEntry.limitPricePanel": "Limitpreis",
    "panel.newOrderEntry.placeOrderButton": "Auftrag platzieren",
    "panel.newOrderEntry.ProductType": "Produktart",
    "panel.newOrderEntry.RefreshMarginReq": "Klicken Sie hier, um die Marginwerte zu aktualisieren, oder warten Sie 30 Sekunden.",
    "panel.newOrderEntry.riskDetailsLabel": "Margin",
    "panel.newOrderEntry.SellBidButton": "Sell Bid",
    "panel.newOrderEntry.SellMarketButton": "Sell Market",
    "panel.newOrderEntry.sellSideCheckBox": "Sell",
    "panel.newOrderEntry.Side": "Seite",
    "panel.newOrderEntry.singleOrderRadio": "Einzeln",
    "panel.newOrderEntry.slPriceRisk": "SL Preis",
    "panel.newOrderEntry.slPriceRiskOffset": "SL Abstand",
    "panel.newOrderEntry.stopLossCheckBox": "SL Preis",
    "panel.newOrderEntry.stopLossCheckBox.offset": "SL Abstand",
    "panel.newOrderEntry.stopPricePanel": "Stop Preis",
    "panel.newOrderEntry.takeProfitCheckBox": "TP Kurs",
    "panel.newOrderEntry.takeProfitCheckBox.offset": "TP Abstand",
    "panel.newOrderEntry.tifPanel": "Validity",
    "panel.newOrderEntry.tpPriceRisk": "TP Kurs",
    "panel.newOrderEntry.tpPriceRiskOffset": "TP Abstand",
    "panel.newOrderEntry.trstopOffset": "Tr.stop Abstand",
    "panel.newOrderEntry.typePanel": "Autragstyp",
    "panel.NewTrades.Account": "Konto",
    "panel.NewTrades.Amount": "Menge",
    "panel.NewTrades.Bought": "Gekauft",
    "panel.NewTrades.Commission": "Ausführungsgebühr",
    "panel.NewTrades.Commission.InInstrumentQuotingCurrency": "#hidden#",
    "panel.NewTrades.Date": "Daten/Zeit",
    "panel.NewTrades.Exchange": "#hidden#",
    "panel.NewTrades.ExpirationDate": "Exp. Datum",
    "panel.NewTrades.ExternalOrderId": "#hidden#",
    "panel.NewTrades.ExternalPrice": "Externe Preis",
    "panel.NewTrades.ExternalTradeId": "ID extern",
    "panel.NewTrades.instrType": "Symb.Typ",
    "panel.NewTrades.Instrument": "Symbol",
    "panel.NewTrades.Login": "Login",
    "panel.NewTrades.Net_PL": "Netto G/V",
    "panel.NewTrades.Net_PL.InInstrumentQuotingCurrency": "#hidden#",
    "panel.NewTrades.Operation": "Seite",
    "panel.NewTrades.OrderId": "Auftrag ID",
    "panel.NewTrades.OrderType": "Autragstyp",
    "panel.NewTrades.Price": "Preis",
    "panel.NewTrades.ProductType": "Produktart",
    "panel.NewTrades.PurchasePrice": "Exposition",
    "panel.NewTrades.RealizedProfitLoss": "Brutto-PnL",
    "panel.NewTrades.RealizedProfitLoss.InInstrumentQuotingCurrency": "#hidden#",
    "panel.NewTrades.Rebates": "Rabatte",
    "panel.NewTrades.Route": "Route",
    "panel.NewTrades.Sold": "Verkauft",
    "panel.NewTrades.StrikePrice": "Strike price",
    "panel.NewTrades.Time": "Zeit",
    "panel.NewTrades.TradeID": "Trade ID",
    "panel.NewTrades.TradeIDLabel": "#hidden#",
    "panel.OrderBook.Account": "Konto",
    "panel.OrderBook.Action": "Seite",
    "panel.OrderBook.Bought": "Gekauft",
    "panel.OrderBook.Date": "Daten/Zeit",
    "panel.OrderBook.DislocedQty": "#hidden#",
    "panel.OrderBook.Event": "Veranstaltung",
    "panel.OrderBook.ExpirationDate": "exp. Tag",
    "panel.OrderBook.ExternalOrderID": "#hidden#",
    "panel.OrderBook.FilledQuantity": "#hidden#",
    "panel.OrderBook.instrType": "Symb.Typ",
    "panel.OrderBook.Instrument": "Symbol",
    "panel.OrderBook.Login": "Login",
    "panel.OrderBook.Message": "Nachricht",
    "panel.OrderBook.OrderNumber": "Auftrag ID",
    "panel.OrderBook.Price": "Preis",
    "panel.OrderBook.ProductType": "Produktart",
    "panel.OrderBook.Quantity": "Menge",
    "panel.OrderBook.ReminingQuantity": "#hidden#",
    "panel.OrderBook.RemoteID": "Remote ID",
    "panel.OrderBook.Route": "Route",
    "panel.OrderBook.Sold": "Verkauft",
    "panel.OrderBook.StopPrice": "Stop Preis",
    "panel.OrderBook.StrikePrice": "Strike Preis",
    "panel.OrderBook.TIF": "Validity",
    "panel.OrderBook.Time": "Zeit",
    "panel.OrderBook.TraderID": "Geändert vom Nutzer",
    "panel.OrderBook.Type": "Typ",
    "panel.orderEntry": "Auftragserfassung",
    "panel.orderHistory": "Auftragshistorie",
    "panel.orders": "Offene Aufträge",
    "panel.orders.Account": "Konto",
    "panel.orders.action": "Seite",
    "panel.orders.AvgFilledPrice": "#hidden#",
    "panel.orders.BindTo": "Verpflichtet zu",
    "panel.orders.Cancel": "Stornieren",
    "panel.orders.Comment": "#hidden#",
    "panel.orders.Comments": "Kommentare",
    "panel.orders.Ct_Ahead": "Ct. ahead",
    "panel.orders.CurrentPrice": "Aktueller Preis",
    "panel.orders.date": "Daten",
    "panel.orders.date_time": "Daten/Zeit",
    "panel.orders.disclosedQty": "#hidden#",
    "panel.orders.Event": "Veranstaltung",
    "panel.orders.Exchange": "Exchange",
    "panel.orders.ExpirationDate": "exp. Datum",
    "panel.orders.ExternalOrderId": "#hidden#",
    "panel.orders.ExternalOrderStatus": "#hidden#",
    "panel.orders.GroupId": "MAM Gruppe ID",
    "panel.orders.InitReq": "Anfangsanforderung",
    "panel.orders.Instr_Type": "Symb. typ",
    "panel.orders.Login": "Login",
    "panel.orders.Market": "Market",
    "panel.orders.menu.CancelAllByInstrument": "Stonieren Sie alle nach ausgewähltem Symbol",
    "panel.orders.menu.CancelAllForAccount": "Alles für das ausgewählte Konto stornieren",
    "panel.orders.menu.CancelAllOrders": "Alle Orders stornieren",
    "panel.orders.menu.CancelOrder": "Order stornieren",
    "panel.orders.menu.ExecuteAsMarket": "Wechsel zum Markt",
    "panel.orders.menu.ExecuteAsMarket.question": "Möchten Sie den Auftrag wirklich zum Marktpreis ausführen?",
    "panel.orders.menu.ModifyOrder": "Order ändern...",
    "panel.orders.menu.Remove": "Order stornieren",
    "panel.orders.OCOGrouID": "OCO group ID",
    "panel.orders.price": "Preis",
    "panel.orders.ProductType": "Produktart",
    "panel.orders.quantity": "Menge",
    "panel.orders.QuantityFilled": "Menge gefüllt",
    "panel.orders.QuantityRemaining": "verbleibende Menge",
    "panel.orders.Reference": "Auftrag ID",
    "panel.orders.RemoteID": "Remote ID",
    "panel.orders.Route": "Route",
    "panel.orders.SL": "SL",
    "panel.orders.SL_Offset": "SL Abstand",
    "panel.orders.SL_Price": "SL Preis",
    "panel.orders.Status": "Status",
    "panel.orders.StopPrice": "Stop Preis",
    "panel.orders.StrikePrice": "Strike Preis",
    "panel.orders.symbol": "Symbol",
    "panel.orders.symbolDescription": "Symbolbeschreibung",
    "panel.orders.TIF": "Validity",
    "panel.orders.time": "Zeit",
    "panel.orders.Token": "Auftrag ID",
    "panel.orders.TP": "TP",
    "panel.orders.TP_Offset": "TP Abstand",
    "panel.orders.TP_Price": "TP Kurs",
    "panel.orders.TraderID": "Trader ID",
    "panel.orders.type": "Typ",
    "panel.orders.UserGroup": "Benutzergruppe",
    "panel.positions": "Positions",
    "panel.positions.Account": "Konto",
    "panel.positions.AvailableForSell": "#hidden#",
    "panel.positions.Basis": "Aufstiegkurs",
    "panel.positions.Close": "Schließen",
    "panel.positions.comments": "Kommentare",
    "panel.positions.commissions": "Gebühr",
    "panel.positions.commissions.InstrumentCurrency": "#hidden#",
    "panel.positions.confirmation.Reverse": "umgekehrt",
    "panel.positions.confirmation.ReverseAllPositions": "Möchten Sie wirklich alle Positionen umkehren?",
    "panel.positions.confirmation.ReverseByInstrument": "Möchten Sie wirklich alle Positionen rückgängig machen?",
    "panel.positions.confirmation.ReverseSelectedPositions": "Möchten Sie die ausgewählten Positionen wirklich rückgängig machen?",
    "panel.positions.cur_price": "Aktueller Preis",
    "panel.positions.date_time": "Daten/Zeit",
    "panel.positions.Delta": "Delta",
    "panel.positions.ExpDate": "Exp. Datum",
    "panel.positions.exposition_usd": "Pos. exposure",
    "panel.positions.Gamma": "Gamma",
    "panel.positions.GroupId": "MAM Gruppe ID",
    "panel.positions.InitReq": "Anfangsanforderung",
    "panel.positions.IV": "IV",
    "panel.positions.Login": "Login",
    "panel.positions.MaintReq": "Wartungsbedarf",
    "panel.positions.MarginSuplus": "Surpl./Def. margin",
    "panel.positions.menu.Close": "Position schließen",
    "panel.positions.menu.Modify": "Position ändern",
    "panel.positions.menu.ShowToolbar": "Symbolleiste anzeigen",
    "panel.positions.menu.View": "Ansicht",
    "panel.positions.Net_PL": "Netto G/V",
    "panel.positions.Net_PL.InstrumentCurrency": "#hidden#",
    "panel.positions.Operation": "Seite",
    "panel.positions.PL_ticks": "G/V, Abstand ",
    "panel.positions.PL_ticks.points": "Punkte",
    "panel.positions.PL_ticks.ticks": "ticks",
    "panel.positions.pos_number": "Position ID",
    "panel.positions.PositionValue": "Pos. Wert",
    "panel.positions.ProductType": "Produktart",
    "panel.positions.profit_usd": "Brutto-PnL",
    "panel.positions.profit_usd.InstrumentCurrency": "#hidden#",
    "panel.positions.Qunatity": "Menge",
    "panel.positions.RealizedPnL": "#hidden#",
    "panel.positions.RealizedPnL.InstrumentCurrency": "#hidden#",
    "panel.positions.Rho": "Rho",
    "panel.positions.route": "Route",
    "panel.positions.SL": "SL Preis",
    "panel.positions.SL_Value": "SL, Wert",
    "panel.positions.strike": "Strike price",
    "panel.positions.swaps": "Swaps",
    "panel.positions.Symbol": "Symbol",
    "panel.positions.SymbolDescription": "Symbolbeschreibung",
    "panel.positions.Theta": "Theta",
    "panel.positions.TP": "TP Kurs",
    "panel.positions.type": "Symb. typ",
    "panel.positions.UsedMargin": "Gebrauchte Margin",
    "panel.positions.UserGroup": "Benutzergruppe",
    "panel.positions.Vega": "Vega",
    "panel.settings.Information": "Information",
    "panel.settings.saveDefault": "Erfolgreich gespeichert!",
    "panel.terceraChart": "Chart",
    "panel.TerceraSymbolLookupDropDownForm.Add": "Hinzufügen",
    "panel.TerceraSymbolLookupDropDownForm.All": "Alle",
    "panel.TerceraSymbolLookupDropDownForm.Bonds": "Bonds",
    "panel.TerceraSymbolLookupDropDownForm.Cancel": "Stornieren",
    "panel.TerceraSymbolLookupDropDownForm.CFDs": "CFDs",
    "panel.TerceraSymbolLookupDropDownForm.collapceAllButton": "Alle ausblenden",
    "panel.TerceraSymbolLookupDropDownForm.crypto": "Krypto CCY",
    "panel.TerceraSymbolLookupDropDownForm.Equities": "Equities",
    "panel.TerceraSymbolLookupDropDownForm.ETFs": "ETFs",
    "panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.All exchanges": "Alle Börsen",
    "panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.exchanges": "Börsen",
    "panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.No exchanges": "Keine Börsen",
    "panel.TerceraSymbolLookupDropDownForm.expandExchangesButton": "Börsen erweitern",
    "panel.TerceraSymbolLookupDropDownForm.expandTypesButton": "Typen erweitern",
    "panel.TerceraSymbolLookupDropDownForm.Forex": "Devisen",
    "panel.TerceraSymbolLookupDropDownForm.Forward": "weiterleiten",
    "panel.TerceraSymbolLookupDropDownForm.Futures": "Futures",
    "panel.TerceraSymbolLookupDropDownForm.indices": "Indizes",
    "panel.TerceraSymbolLookupDropDownForm.Options": "Options",
    "panel.TerceraSymbolLookupDropDownForm.Portfolio": "Synthetik",
    "panel.TerceraSymbolLookupDropDownForm.Select": "Wählen",
    "panel.TerceraSymbolLookupDropDownForm.Select all": "Wählen Sie Alle",
    "panel.TerceraSymbolLookupDropDownForm.Show additionl info": "Zusätzliche Info anzeigen",
    "panel.TerceraSymbolLookupDropDownForm.Spot": "Spot",
    "panel.TerceraSymbolLookupDropDownForm.Spreadbet": "Spread Wette",
    "panel.TerceraSymbolLookupDropDownForm.TBill": "T-Rechnung",
    "panel.TerceraSymbolLookupDropDownForm.typeComboBox.All types": "Alle Arten",
    "panel.TerceraSymbolLookupDropDownForm.typeComboBox.No types": "Keine Typen",
    "panel.TerceraSymbolLookupDropDownForm.typeComboBox.types": "Typen",
    "panel.timeSales": "Zeit & Sales",
    "panel.timeSales.Agressor flag": "Seite",
    "panel.timeSales.AgressorFlag.Auction": "Auktion",
    "panel.timeSales.AgressorFlag.Buy": "Buy",
    "panel.timeSales.AgressorFlag.CrossTrade": "Cross-Handel",
    "panel.timeSales.AgressorFlag.None": "Kein",
    "panel.timeSales.AgressorFlag.Sell": "Verkaufen",
    "panel.timeSales.ask": "Ask",
    "panel.timeSales.AskBidSize": "BidxAsk size",
    "panel.timeSales.AskSource": "Asks MPID",
    "panel.timeSales.bid": "Bid",
    "panel.timeSales.BidSource": "Bids MPID",
    "panel.timeSales.BuyerSource": "Käufer",
    "panel.timeSales.Exchange": "Quelle",
    "panel.timeSales.Instrument": "Symbol",
    "panel.timeSales.MenuName": "Zeit & Sales",
    "panel.timeSales.Price": "Preis",
    "panel.timeSales.SellerSource": "Verkäufer",
    "panel.timeSales.Size": "Größe",
    "panel.timeSales.Time": "Zeit",
    "panel.timeSales.Type": "Symb. typ",
    "panel.tooltiper.System": "System",
    "panel.trades": "Füllorders",
    "panel.watchlist": "Watchlist",
    "panel.watchlist.Ask": "Ask",
    "panel.watchlist.AskSize": "Ask size",
    "panel.watchlist.AuctionEndTime": "#hidden#",
    "panel.watchlist.Bid": "Bid",
    "panel.watchlist.BidSize": "Bid size",
    "panel.watchlist.Buy": "Buy",
    "panel.watchlist.CBAuctionStart": "#hidden#",
    "panel.watchlist.cell.cancel": "Stornieren",
    "panel.watchlist.cell.modify": "Änderung",
    "panel.watchlist.cell.Not allow": "Nicht zugelassen",
    "panel.watchlist.cell.sending": "Neu",
    "panel.watchlist.cell.ticks": "ticks",
    "panel.watchlist.Change": "Änderung",
    "panel.watchlist.ChangeFromOpen": "Wechsel aus Open",
    "panel.watchlist.ChangeFromOpen%": "Wechsel aus Open, %",
    "panel.watchlist.ChangePr": "Änderung, %",
    "panel.watchlist.Close": "Schließen",
    "panel.watchlist.ClosePostMarket": "Postmarkt schließen",
    "panel.watchlist.Comment": "Kommentar",
    "panel.watchlist.Company": "Unternehmen",
    "panel.watchlist.ContractSize": "Lotsgröße",
    "panel.watchlist.CurrentSession": "Aktuelle Sitzung",
    "panel.watchlist.Date": "letztes Datum",
    "panel.watchlist.Descr": "Beschreibung",
    "panel.watchlist.Exchange": "Exchange",
    "panel.watchlist.ExpDate": "Exp. Datum",
    "panel.watchlist.High": "Hoch",
    "panel.watchlist.High13": "#hidden#",
    "panel.watchlist.High26": "#hidden#",
    "panel.watchlist.High52": "#hidden#",
    "panel.watchlist.IndicativeAuctionPrice": "Ind. auction pr.",
    "panel.watchlist.Instrument": "Symbol",
    "panel.watchlist.Last": "Zuletzt",
    "panel.watchlist.LastSize": "Letzte Größe",
    "panel.watchlist.Limit.High": "#hidden#",
    "panel.watchlist.Limit.Low": "#hidden#",
    "panel.watchlist.Low": "Niedrig",
    "panel.watchlist.Low13": "#hidden#",
    "panel.watchlist.Low26": "#hidden#",
    "panel.watchlist.Low52": "#hidden#",
    "panel.watchlist.Main Volume": "Hauptvolumen",
    "panel.watchlist.Market": "Market",
    "panel.watchlist.MarketCap": "Marktkappe",
    "panel.watchlist.Match Volume": "Volumen anpassen",
    "panel.watchlist.menu.AddSymbol": "Symbole hinzufügen...",
    "panel.watchlist.menu.clearAll": "Alle löschen",
    "panel.watchlist.menu.RemoveInstrument": "Ausgewählte Symbole entfernen",
    "panel.watchlist.menu.SymbolInfo": "Symbol info",
    "panel.watchlist.NA": "N/A",
    "panel.watchlist.noQuotes": "letzte Aktualisierung",
    "panel.watchlist.NormalMarketSize": "#hidden#",
    "panel.watchlist.OffExchangeValue": "#hidden#",
    "panel.watchlist.OffExchangeVolume": "#hidden#",
    "panel.watchlist.Open": "Öffnen",
    "panel.watchlist.OpenInterest": "#hidden#",
    "panel.watchlist.OpenPreMarket": "Pre market geöffnet",
    "panel.watchlist.PreMarketChange": "Pre market Wechsel",
    "panel.watchlist.PreMarketChange%": "Pre market Wechsel, %",
    "panel.watchlist.PrevClose": "Vorherige schließen",
    "panel.watchlist.PrevSettlementPrice": "Vorherige Abrechnungspreis",
    "panel.watchlist.ReferencePrice": "Referenzpreis",
    "panel.watchlist.Sell": "Sell",
    "panel.watchlist.SettlementPrice": "Settlement price",
    "panel.watchlist.Spread": "Spread",
    "panel.watchlist.SpreadPr": "Spread, %",
    "panel.watchlist.Ticks": "Ticks",
    "panel.watchlist.TicksPostMarket": "Post market ticks",
    "panel.watchlist.TicksPreMarket": "Pre market ticks",
    "panel.watchlist.Time": "letzte Zeit",
    "panel.watchlist.TradedValue": "#hidden#",
    "panel.watchlist.Type": "Symb. typ",
    "panel.watchlist.Volume": "Volumen",
    "panel.watchlist.VolumePostMarket": "Post market volumen",
    "panel.watchlist.VolumePreMarket": "Pre market volumen",
    "Panels.MarketDepth.CancelAll": "Alle stornieren",
    "panels.orders.buttons.AsMarket": "Wechsel zum Markt",
    "panels.orders.buttons.CancelAllButton": "Alle stornieren",
    "panels.orders.buttons.CancelBuyButton": "Buy stornieren",
    "panels.orders.buttons.CancelByInstrumentButton": "Stornieren",
    "panels.orders.buttons.CancelDayButton": "Tage stornieren",
    "panels.orders.buttons.CancelGTCButton": "GTCs stornieren",
    "panels.orders.buttons.CancelLimitsButton": "Limits stornieren",
    "panels.orders.buttons.CancelSelectedButton": "Stornieren auswählen",
    "panels.orders.buttons.CancelSellButton": "Sell stornieren",
    "panels.orders.buttons.CancelStopsButton": "Stops löschen",
    "panels.orders.buttons.ModifyOrder": "Order ändern",
    "panels.positions.buttons.closeAll": "Alle schließen",
    "panels.positions.buttons.closeForInstrument": "Schließen",
    "panels.positions.buttons.closeLong": "Long Schließen",
    "panels.positions.buttons.closeNegative": "Negativ schließen",
    "panels.positions.buttons.closePositive": "Im Plus schließen",
    "panels.positions.buttons.closeSelected": "Ausgewählte Schließen",
    "panels.positions.buttons.closeShort": "Short schließen",
    "panels.positions.buttons.clxAll": "CLX all",
    "panels.positions.buttons.clxForInstrument": "CLX",
    "panels.positions.ButtonsPanel.helpLabel": "Klicken Sie mit der rechten Maustaste, um die Schaltflächen anzuzeigen / auszublenden",
    "PanelSettingsScreen.Text": "- Einstellungen",
    "PanelSettingsScreen.TextSmall": "- einstellungen",
    "Parabolic Time/Price System": "Parabolisches Zeit- / Preissystem ",
    "period.Day": "Tag",
    "period.Days": "Tage",
    "period.Hour": "Stunde",
    "period.Hours": "Stunden",
    "period.Min": "Min",
    "period.Mins": "Mins",
    "period.Month": "Monat",
    "period.Months": "Monate",
    "period.Week": "Woche",
    "period.Weeks": "Wochen",
    "period.Year": "Jahr",
    "period.Years": "Jahre",
    "Pivot Point Moving Average": "Drehpunkt gleitender Durchschnitt",
    "PivotPoint": "Drehpunkt",
    "PopupMultiCkeckBox.All": "Alle",
    "Português": "Português (Br)",
    "Price Channel": "Preiskanal ",
    "Price Oscillator": "Preisoszillator",
    "ProductType.CarryForward": "fahren Sie fort",
    "ProductType.Delivery": "Lieferung",
    "ProductType.General": "Allgemeines",
    "ProductType.Intraday": "Intraday",
    "property.%": "%",
    "property.Additional": "Zusätzlich",
    "property.AllPrice": "Preisstatistik",
    "property.appearance": "Auftreten",
    "property.Ask": "Ask",
    "property.Author": "Autor",
    "property.autosize": "Auto Spaltengröße",
    "property.autoYScale.Auto": "Auto Skalierung",
    "property.autoYScale.Manual": "Manuelle Skalierung",
    "property.Average": "Durchschnittlich",
    "property.Backgr": "Hintergrundfarbe",
    "property.Backgr. top": "Hintergrundfarbe",
    "property.BarPattern": "Balkenmuster",
    "property.barsHiLowColor": "Linie",
    "property.BarStatistics": "Balkenstatistik",
    "property.Bid": "Bid",
    "property.bodyColor": "Body",
    "property.borderColor": "Leiste",
    "property.BottomCenter": "unten in der Mitte",
    "property.BottomLeft": "unten links",
    "property.BottomRight": "unten rechts",
    "property.Browser": "Browser",
    "property.Buy": "Buy",
    "property.BuyColor": "Buy",
    "property.cAsk": "Ask Größe",
    "property.cASK": "zum Ask",
    "property.cASKHI": "Über Ask",
    "property.cBid": "Bid size",
    "property.cBID": "zum Bid",
    "property.cBIDLO": "Unter dem Bid",
    "property.cellscin.useSellBuy": "Reverse-Taste Ordnung",
    "property.Chart": "Chart",
    "property.Chart OE": "Chart Ordereller Hatdee",
    "property.chart.isBackground": "In den Hintergrund bewegen",
    "property.chart.line": "Linie",
    "property.chart.ray": "RAY",
    "property.chart.rayStyle": "Ray stil",
    "property.chart.showAngle": "Ecke anzeigen",
    "property.chart.showingOE": "Sichtbar",
    "property.chart.stickTo": "Halten an",
    "property.chart.tools.back": "Zeichnung füllen",
    "property.chart.tools.fibretr.font": "Etikettenschriftart",
    "property.chart.tools.fibretr.fontcolor": "Schriftfarbe",
    "property.ChartAnalyseToolsToolBar": "Analyse-Werkzeugleiste",
    "property.ChartDrawingToolsToolBar": "Zeichnungs-Panel",
    "property.cLimit": "Limit-Order",
    "property.Close": "Schließen",
    "property.Cluster.ClusterUpDownColors": "Cluster",
    "property.Cluster.ColorScheme": "Farbschema",
    "property.Cluster.ColorScheme.ByDelta": "Im Delta",
    "property.Cluster.ColorScheme.ByDelta.Colors": "Farbe",
    "property.Cluster.ColorScheme.ByDelta.Colors.BuyColor": "Buy",
    "property.Cluster.ColorScheme.ByDelta.Colors.SellColor": "Sell",
    "property.Cluster.ColorScheme.ByTrades": "Nach Trades",
    "property.Cluster.ColorScheme.ByVolume": "Nach Volumen",
    "property.Cluster.ColorScheme.Color": "Farbe",
    "property.Cluster.ColorScheme.None": "Kein",
    "property.Cluster.CustomStep": "Benutzerdefinierter Schritt",
    "property.Cluster.Down": "Unter",
    "property.Cluster.FilterValue": "Filterwert",
    "property.Cluster.Font": "Schriftart",
    "property.Cluster.FontColor": "Schriftfarbe",
    "property.Cluster.HighlightMax": "Maximum markieren",
    "property.Cluster.HighlightMax.Colors": "Farben",
    "property.Cluster.Operator": "Betreiber",
    "property.Cluster.PriceStep": "Preisstufe",
    "property.Cluster.PriceStep.Custom": "Benutzerdefiniert ",
    "property.Cluster.PriceStep.MinTick": "Minimum tick",
    "property.Cluster.ShowOnlySimilarDelta": "Nur ähnliches Delta anzeigen",
    "property.Cluster.ShowValueFilter": "Wertfilter",
    "property.Cluster.Up": "Auf",
    "property.cMIDLO": "Zwischen dem Spread",
    "property.Color": "Farbe",
    "property.ColoringMode": "Farbmodus",
    "property.Colors": "Farben",
    "property.colors_param": "Farben",
    "property.colorScheme": "Stil",
    "property.ColorsSeparatorGroup": "Farben",
    "property.ColouringMode": "Farbmodus",
    "property.Comments": "Kommentare",
    "property.Company": "Unternehmen",
    "property.Confirmations": "Bestätigungen",
    "property.connection": "Anschluss",
    "property.Connection": "Anschluss",
    "property.ContentAlignment": "Ausrichtung",
    "property.Copyrights": "Urheberrechte",
    "property.cQUOTE": "Quotes",
    "property.CreateColor": "Erstellen",
    "property.crossHairFontColor": "Schriftfarbe",
    "property.cStop": "Stop Order",
    "property.currency": "Währung",
    "property.CustomYMarkingValue": "min. Gridschritt",
    "property.Data Box": "Informationsfenster",
    "property.Data Style": "Datenstil",
    "property.Day": "Tag",
    "property.DaySeparatorsVisibility": "Tagesteiler",
    "property.DefaultProductType": "Standardprodukttyp",
    "property.Defaults": "Trading Standardeinstellungen",
    "property.deviation": "Abweichung",
    "property.dK": "dK",
    "property.Doji": "Doji",
    "property.Downband deviation in %": "Downband-Abweichung %",
    "property.email": "E-Mail-Adressen \\ n (Komma oder Semikolon verwenden)",
    "property.Emulator": "Emulator",
    "property.enable": "Aktivieren",
    "property.Enable Sounds": "Ton aktivieren",
    "property.Exponential": "Exponentiell",
    "property.fib.Arc": "Fib. Arc",
    "property.Fibonacci": "Fibonacci",
    "property.Filters": "Filtern",
    "property.FitDrawings": "Zeichnungen anpassen",
    "property.FitHighLow": "Fit Tag High/Low",
    "property.FitIndicator": "Fit Indikatoren",
    "property.FitOrders": "Fit Aufträge / Positionen",
    "property.FOK": "FOK",
    "property.Font": "Schriftart",
    "property.Forex": "Forex",
    "property.Forwards": "Vorwärts",
    "property.Futures": "Futures",
    "property.FX Cell": "FX cell",
    "property.G": "G",
    "property.General": "Allgemeines",
    "property.general.defaultSymbol": "Symbol",
    "property.general.OvernightMarginNotificationMessage": "#hidden#",
    "property.general.showLotsFX": "Menge in Lots anzeigen lassen",
    "property.gr_type": "Stil",
    "property.Gradient": "Gradient",
    "property.GraphicType.Absolute": "Absolute",
    "property.GraphicType.Log": "Logarithmisch",
    "property.GraphicType.Relative": "Relative",
    "property.GridPrice": "Preis (horizontal)",
    "property.GridPriceHighLight": "Markieren Sie die Gitter-Farbe",
    "property.GridTime": "Zeit (vertikal)",
    "property.GTC": "GTC",
    "property.GTD": "GTD",
    "property.HeaderFont": "Schriftart",
    "property.HeaderText": "Überschrift",
    "property.High": "Hoch",
    "property.HighlightMarkingsStep": "Markieren Sie den Rasterschritt",
    "property.HistogramLineColor": "Histogramlinie",
    "property.HistoryType": "Daten typ:",
    "property.HorizontalLine.LeftRay": "nach links ausdehnen",
    "property.hostName": "Host name",
    "property.hotkeyManager.ShowVolume": "Volumen anzeigen",
    "property.infowindow_font": "Schriftart",
    "property.infoWindow_fore_back_color": "Farbe",
    "property.infowindow_visible": "Info-Fenster anzeigen",
    "property.Instrument Types": "Standardmäßiger Auftrag nach Typ",
    "property.Intrinsic": "Wesenhaft",
    "property.IOC": "IOC",
    "property.IsBackground": "ist Hintergrund",
    "property.IsBarsVisible": "#Bars",
    "property.IsChangeVisible": "Änderung, %",
    "property.IsCloseVisible": "Schließen",
    "property.IsDateVisible": "Daten",
    "property.IsHighVisible": "Hoch",
    "property.IsLowVisible": "Niedrig",
    "property.IsOpenVisible": "Öffnen",
    "property.IsRoundedAvgPrice": "Gerundeter durchschnittlicher offener Preis",
    "property.isShowToolTips": "Werkzeug Hinweise",
    "property.isShowToolTipsGrop": "Werkzeug Hinweise",
    "property.IsSyncActive": "Mit Charts synchronisieren",
    "property.IsTicksVisible": "Ticks",
    "property.IsTimeVisible": "Zeit",
    "property.IsVolumeVisible": "Volumen",
    "property.LabelColors": "Etikettenfarbe",
    "property.Language": "Sprache",
    "property.Last": "Zuletzt",
    "property.lastPrice_AskIndicatorColor": "Ask",
    "property.lastPrice_BidIndicatorColor": "Bid",
    "property.lastPrice_LastIndicatorColor": "Zuletzt",
    "property.lastPrice_SpreadIndicatorColor": "Farbenbereich verteilen",
    "property.Left ray": "nach links ausdehnen",
    "property.Level": "Niveau",
    "property.Limit": "Grenze",
    "property.Line": "Linie",
    "property.line.lineName": "Zeilenname",
    "property.line.plateVisible": "Zeile {0} -Marker anzeigen",
    "property.line.timeShift": "Zeitverschiebung:",
    "property.line.value": "Wert",
    "property.line.Visible": "Sichtbar",
    "property.line.yFactor": "Y faktor",
    "property.Linear Weighted": "Linear gewichtet",
    "property.lineProperties": "Eigenschaften",
    "property.LOC": "LOC",
    "property.logBase": "Protokollbasis",
    "property.LOO": "LOO",
    "property.LotsMode": "Größe anzeigen",
    "property.Low": "Niedrig",
    "property.Mail Server": "Mail server",
    "property.Manual": "Handbuch",
    "property.Market": "Market",
    "property.MarketDepth.ColoringMethod": "Färbemethode",
    "property.MarketProfile.ColorScheme": "Farbschema",
    "property.MarketProfile.ColorScheme.Color": "Farbe",
    "property.MarketProfile.ColorScheme.GradientColor": "Bereich",
    "property.MarketProfile.ColorScheme.PairColor.Color": "Farbe",
    "property.MarketProfile.ColorScheme.VolumeColor": "Farbe",
    "property.MarketProfile.Font": "Schriftart",
    "property.MarketProfile.PointOfControl": "Kontrollpunkt",
    "property.MarketProfile.PointOfControlStyle": "Point-of-Control-Stil",
    "property.MarketProfile.ShowSingles": "Einzelne Einstellungen anzeigen",
    "property.MarketProfile.SinglesStyle": "Single Stil",
    "property.MarketProfile.ValueArea": "Wertebereich",
    "property.MarketProfile.ValueAreaStyleHorisontal": "Horizontale Linienart",
    "property.MarketProfile.ValueAreaStyleVertical": "Vertikaler Linienstil",
    "property.MarketProfile.ValuePercents": "Wert, %",
    "property.Medium": "Medium",
    "property.message": "Nachricht",
    "property.method": "methode",
    "property.MirrorShow": "Mirror-Ansicht",
    "property.MOC": "MOC",
    "property.Mode": "Modus",
    "property.Mode.Trades": "Trades",
    "property.Modified": "Geändert",
    "property.MonthSeparatorsVisibility": "Monatstrennzeichen",
    "property.MOO": "MOO",
    "property.name": "Name",
    "property.Name": "Name",
    "property.News": "Nachrichten",
    "property.None": "Kein",
    "property.Note": "Hinweis",
    "property.NotSet": "Nicht eingestellt",
    "property.Number": "Anzahl",
    "property.OCO": "OCO",
    "property.OE_AddBidAddAsk": "Buy Bid/Sell Ask",
    "property.OE_ShowBuyMarketSellMarket": "Buy Market/Sell Market",
    "property.OE_ShowSellBidBuyASk": "Sell Bid/Buy Ask",
    "property.OE_ShowSLTP": "Order schließen (SL/TP)",
    "property.OE_Small": "Order eingrenzen",
    "property.OE_Small.ToolTip": "Wenn diese Option aktiviert ist, wird bei der Order eine Pfeilansicht angezeigt",
    "property.oe.confirmClosePosition": "Positionsschließung bestätigen",
    "property.oe.confirmOrderCancel": "Bestätigen Sie die Stornierung Ihrer Bestellung",
    "property.oe.confirmOrderChange": "Bestätigen Order/Modifizierung von Positionen",
    "property.oe.confirmOrderCreate": "Bestätigen Sie Ordererteilung",
    "property.oe.confirmReversePosition": "Position rückgängig machen bestätigen",
    "property.OffsetMode": "Abstand anzeigen in",
    "property.OnClose": "Beim Schließen",
    "property.OnOpen": "Beim Öffnen",
    "property.Open": "Öffnen",
    "property.Options": "Allgemeines",
    "property.Options.ToolTip": "Generale Einstellungen ",
    "property.Order Entry": "Auftragserfassung",
    "property.OrderBuyStyleWidth": "Buy",
    "property.OrderSellStyleWidth": "Sell",
    "property.OverlayVisible": "Überlagerung sichtbar",
    "property.Panel name": "Panel name",
    "property.panel.accountDetails.Groups.1.General": "Allgemeines",
    "property.panel.accountDetails.Groups.2.Margin": "Margin",
    "property.panel.accountDetails.Groups.3.AccountActivity": "Kontobewegung",
    "property.panel.accountDetails.Groups.4.TodayResults": "Tagesergebniss",
    "property.panel.accountDetails.Groups.5.RiskManagement": "Risikomanagement",
    "property.panel.accountDetails.Groups.6.Info": "Info",
    "property.Panels": "Panels",
    "property.Parameters": "Parameters",
    "property.password": "Passwort",
    "property.period": "Zeitraum",
    "property.Period of indicator": "Anzeigeperiode",
    "property.Period of indicator:": "Anzeigeperiode",
    "property.Period of Linear Regression": "Zeitraum der Linienregression",
    "property.Period of MA for envelopes": "Zeitraum der MA-Umschläge",
    "property.Period of MA1": "Zeitraum von MA1",
    "property.Period of MA2": "Zeitraum von MA2",
    "property.Period of momentum:": "Periode des Momentums",
    "property.Period of Moving Average": "Periode des gleitenden Durchschnitts",
    "property.Period of price channel": "Zeitraum des Preiskanals",
    "property.Period of Simple Moving Average": "Periode des einfachen gleitenden Durchschnitts",
    "property.Period of Smoothed Moving Average": "Periode des geglätteten gleitenden Durchschnitts",
    "property.Periods": "Perioden",
    "property.pin": "PIN",
    "property.pips": "Pips",
    "property.POCTypes.Line": "Linie",
    "property.POCTypes.Marker": "Marker",
    "property.POCTypes.None": "Kein",
    "property.points": "Punkte",
    "property.Points": "Punkte",
    "property.PositionBuyStyleWidth": "Long",
    "property.PositionSellStyleWidth": "Short",
    "property.Previous": "Vorherige",
    "property.price": "preis",
    "property.Price Scale": "Preisstaffel",
    "property.PriceIndicatorDrawingType": "Preisanzeige",
    "property.PriceIndicatorDrawingType.None": "Kein",
    "property.PriceIndicatorDrawingType.ScaleMarker": "Skalenmarkierung",
    "property.PriceIndicatorDrawingType.ScaleMarkerWithLine": "Skalenmarkierung mit Linie",
    "property.PriceScaleAxisColor": "Mittellinie",
    "property.PriceScaleBackColor": "Mittellinie Hintergrund",
    "property.PriceScaleFont": "Schriftart",
    "property.PriceScaleTextColor": "Textfarbe",
    "property.PriceType": "Preistyp",
    "property.ProfileColoringTypes.DeltaBars": "Delta Balken",
    "property.ProfileColoringTypes.DeltaPrice": "Delta Preis",
    "property.ProfileColoringTypes.DeltaProfile": "Delta profil",
    "property.ProfileColoringTypes.Single": "Einfarbig",
    "property.ProfileColoringTypes.UpdownBars": "Auf / Ab Balken",
    "property.ProfileColoringTypes.UpdownProfile": "Auf / Ab profil",
    "property.ProfileColoringTypes.VolumeBars": "Volumen Balken",
    "property.ProfileColoringTypes.VolumePrice": "Volumen Kurs",
    "property.protrader.showOrders": "Orders",
    "property.Range": "Umfang",
    "property.Ray style": "Ray Stil",
    "property.Relative": "Relative",
    "property.relativeCalcType": "Anfangskurs",
    "property.RelativeCalculationType.BeginOfTheData": "Beginn der Daten",
    "property.RelativeCalculationType.BeginOfTheDay": "Beginn des Tages",
    "property.RelativeCalculationType.BeginOfTheScreen": "Beginn des Bildschirms",
    "property.relativeScaleType": "Scale type",
    "property.Reserve": "Rücklage",
    "property.Reverse": "Rücklage",
    "property.Right ray": "Nach rechts ausdehnen",
    "property.routing.account": "Konto",
    "property.routing.orderType": "Autragstyp",
    "property.RSI Method:": "RSI Methode",
    "property.RSI Period:": "RSI Periode",
    "property.SCRIPTS_SUBGROUP": "Skripte",
    "property.ScrollBar": "Scrollleiste",
    "property.secure": "Sichern",
    "property.Secure": "Sichern",
    "property.Selection": "Auswahl",
    "property.Sell": "Sell",
    "property.SellColor": "Sell",
    "property.SeparatorGroup.ActiveOrders": "Offene Aufträge",
    "property.SeparatorGroup.additional": "Zusätzlich",
    "property.SeparatorGroup.Additional": "Zusätzlich",
    "property.SeparatorGroup.Appearance": "Auftreten",
    "property.SeparatorGroup.CrossHair": "Fadenkreuz",
    "property.SeparatorGroup.ExecutedOrders": "Füllorders ",
    "property.SeparatorGroup.Grid": "Gitter",
    "property.SeparatorGroup.HighlightDate": "Markieren Sie das Datum",
    "property.SeparatorGroup.HighlightPrices": "Preise markieren",
    "property.SeparatorGroup.OpenPositions": "Offene Positionen",
    "property.SeparatorGroup.ScaleMarkings": "Skalenmarkierungen",
    "property.SeparatorGroup.ScaleType": "Skalen typ",
    "property.SeparatorGroup.SeparatorOEWidth": "Ansicht",
    "property.SeparatorGroup.Show": "Anzeigen",
    "property.SeparatorGroup.TimeToNextBar": "Zeit bis zur nächsten Kerze",
    "property.SeparatorGroup.WindowColors": "Hintergrundfarben",
    "property.SeparatorGroup.Zoom": "Zoom",
    "property.SeparatorViewButtons": "Schneller Handel",
    "property.Sesonality": "Saisonalität",
    "property.shift": "Verschiebung",
    "property.showAllSessions": "Erweiterte Sitzung anzeigen",
    "property.ShowEmptyBars": "Löcher in der Geschichte",
    "property.showEvents": "Sichtbar",
    "property.ShowFullArc": "Volle arc anzeigen",
    "property.ShowHighLow": "Tag hoch / tief",
    "property.showOrders": "Sichtbar",
    "property.showPositions": "Sichtbar",
    "property.ShowPrevCloseLine": "Vorherige schließen",
    "property.ShowTimeToNextBar": "Sichtbar",
    "property.ShowTotal": "Gesamtsumme anzeigen",
    "property.ShowVisualTradingOnLeftSide": "Aktive Positionen / Aufträge nach links binden",
    "property.showVolume": "Sichtbar",
    "property.showVolumeMarker": "Wertemarkierung auf der Skala anzeigen",
    "property.Simple": "Einfach",
    "property.sltpOffsetMode": "SL / TP Werte in Abstand einstellen",
    "property.Smoothing": "Glättung",
    "property.solidPriceColor": "Bereich",
    "property.sounds_param": "Töne",
    "property.Sources prices for MA": "Einkaufspreise für MA",
    "property.Specific": "konkret",
    "property.SpreadType": "Spread typ",
    "property.SpreadType.Area": "Bereich",
    "property.SpreadType.Line": "Linie",
    "property.SpreadType.LineWithLabel": "Linie mit Preis",
    "property.SpreadType.None": "Kein",
    "property.Step of parabolic SAR system": "Schritt des parabolischen SAR-Systems",
    "property.Stick to": "halten an",
    "property.stickTo": "halten an:",
    "property.Stocks": "Equities",
    "property.Stop": "Stopp",
    "property.Stop Limit": "Stop limit",
    "property.Stop-loss": "Stop loss",
    "property.style": "Stil:",
    "property.Sum": "Sum",
    "property.Symbols": "Symbols",
    "property.T": "T",
    "property.Take-profit": "Take profit",
    "property.text": "Text",
    "property.TIF": "Market validity",
    "property.TIF_Limit_And_Stop_Limit": "Limit validity (Stop limit validity)",
    "property.TIF_Stop": "Stop validity",
    "property.Time Scale": "Zeitskala",
    "property.TimeLine": "Zeitachse",
    "property.TimelinePreview": "Scrollleiste",
    "property.TimeScaleAxisColor": "Mittellinie",
    "property.TimeScaleBackColor": "Mittellinie Hintergrund",
    "property.TimeScaleFont": "Schriftart",
    "property.TimeScaleImportentFont": "Wichtige Daten Schriftart",
    "property.TimeScaleTextColor": "Schriftfarbe",
    "property.TimeToNextBarColor": "Farbe",
    "property.TimeToNextBarFont": "Schriftart",
    "property.TopCenter": "Oben in der Mitte",
    "property.TopLeft": "Oben links",
    "property.TopRight": "Oben rechts",
    "property.trackShowCrossHair": "Sichtbar",
    "property.Trading": "Handel",
    "property.Trading Defaults": "Handel",
    "property.Type of moving average": "Typ von moving Average",
    "property.Type of Moving Average": "Typ von Moving Average",
    "property.Typical": "Typisch",
    "property.Upband deviation in %": "Aufwärtsband Aabweichung in %",
    "property.Update": "Update",
    "property.UseCustomYMarkings": "benutzerdefiniertes Grid",
    "property.user": "Login",
    "property.useSSL": "Sichern",
    "property.Value of confidence interval": "Wert des Konfidenzintervalls",
    "property.ValueColors": "Wert Farben",
    "property.VATypes.Horizontal": "Horizontale",
    "property.VATypes.None": "Kein",
    "property.VATypes.ToNextProfile": "Zum nächsten Profil",
    "property.VATypes.Vertical": "Vertikal",
    "property.vendorName": "Lieferant",
    "property.View": "Ansicht",
    "property.ViewSeparatorGroup": "Ansicht",
    "property.Visibility": "Sichtbarkeit",
    "property.VisibilitySeparatorGroup": "Sichtbarkeit",
    "property.Visible": "Sichtbar",
    "property.Visual Trading": "Visueller Handel",
    "property.Volume": "Volumen Balken",
    "property.Volume.Font": "Schriftart",
    "property.Volume.FontColor": "Schriftfarbe",
    "property.Volume.UseTicksData": "Verwenden Sie echtes Tick-Volumen",
    "property.VolumeColoringMode": "Größe des Farbschemas",
    "property.VolumeDownColor": "Negativ",
    "property.VolumeFixedColor": "Gleich",
    "property.VolumeHeightPercent": "Höhe, %",
    "property.VolumeMode.BuyVolume": "Buy Volumen",
    "property.VolumeMode.Delta": "Delta",
    "property.VolumeMode.MaxTickVolume": "maximale Tick-Volumen",
    "property.VolumeMode.SellVolume": "Verkaufsvolumen",
    "property.VolumeMode.TotalVolume": "Gesamtvolumen",
    "property.VolumeMode.Trades": "Trades",
    "property.VolumeOpacityPercent": "Transparenz",
    "property.VolumeProfile": "Volumen Profil",
    "property.VolumeUpColor": "Positiv",
    "property.VWAP": "VWAP",
    "property.Warnings": "Warnungen",
    "property.WatermarkColor": "Wasserzeichen Farbe",
    "property.WeekSeparatorsVisibility": "Wochentrennzeichen",
    "property.Weighted": "Gewichtet",
    "property.wickColor": "Docht",
    "property.width": "Breite:",
    "property.Win num": "Win num on chart for indicator",
    "property.Win num.addWin": "Unterfenster",
    "property.Win num.main": "Main",
    "property.Y factor": "Y faktor",
    "property.YearSeparatorsVisibility": "Jahre Trennzeichen",
    "property.ZeroLine": "Null Linie",
    "property.ZoomValue": "Schritt skalieren",
    "propery.MainPriceRenderer.Down": "Unter",
    "propery.MainPriceRenderer.Negative": "Negativ",
    "propery.MainPriceRenderer.Positive": "Positiv",
    "propery.MainPriceRenderer.Up": "Auf",
    "property.dealTickets.openOnWorkspace": "Offene Geschäftsabschluss im Arbeitsbereich",
    "Relative Strength Index": "Relative-Stärke Index",
    "Reserve": "Rücklage",
    "ribbon.enviroment": "Umgebung",
    "ribbon.enviroment.help": "Hilfe",
    "ribbon.enviroment.logout": "Ausloggen",
    "ribbon.enviroment.New": "Neuer Arbeitsbereich",
    "ribbon.enviroment.NotificationWindow.lockTrading": "Handel gesperrt",
    "ribbon.enviroment.NotificationWindow.UnlockTrading": "Handel freigeschaltet",
    "ribbon.enviroment.Save": "Arbeitsbereich speichern",
    "ribbon.enviroment.settings": "Einstellungen",
    "ribbon.help.about": "Über",
    "ribbon.help.help": "Mehr",
    "ribbon.help.home": "Startseite",
    "ribbon.linking.linkBlue": "Blau",
    "ribbon.linking.linkEmpty": "Kein",
    "ribbon.linking.linkGreen": "Grün",
    "ribbon.linking.linkOrange": "Orange",
    "ribbon.linking.linkPurple": "Lila",
    "ribbon.linking.linkRed": "Rot",
    "ribbon.linking.linkYellow": "Gelb",
    "ribbon.tools": "Werkzeuge",
    "ribbon.tools.full_logs": "Vollständige Protokollierung von Servermeldungen",
    "ribbon.tools.grid": "Gitter",
    "ribbon.tools.withdrawal": "Rückzug",
    "ribbon.tools.reports": "Berichte",
    "ribbon.tradingTerminal": "Terminal",
    "RiskManagement.Messages.DailyLimit": "Das tägliche Verlustlimit wurde erreicht.",
    "RiskManagement.Messages.MaxDailyProfit": "Das tägliche Gewinn wurde erreicht.",
    "RiskManagement.Messages.MaxTrailingLimit": "Maximaler Trailing Drawdown wurde erreicht.",
    "RiskManagement.Messages.MaxUnrealizedLossLimit": "Das nicht realisierte Verlustlimit wurde erreicht",
    "RiskManagement.Messages.WeeklyLimit": "Wöchentliche Verlustgrenze wurder erreicht",
    "screen.about.copyright": "© 2017-2024 TraderEvolution Global LTD.",
    "screen.about.dateBuild": "Erstellungsdatum:",
    "screen.about.title": "Über",
    "screen.about.titleWeb": "Über TraderEvolution",
    "screen.about.versionWeb": "Build version",
    "screen.additionalVerification.comment": "Für die Verbindung ({1}) ist eine zusätzliche Verifizierung erforderlich:",
    "screen.additionalVerification.Need activation": "Need activation",
    "screen.additionalVerification.Need activation.comment": "Bitte geben Sie den Aktivierungscode ein (an Ihre E-Mail gesendet)",
    "screen.additionalVerification.Need activation.watermark": "<Geben Sie den Aktivierungscode>",
    "screen.additionalVerification.title": "zusätzliche Prüfung",
    "screen.additionalVerification.title.Email": "Zusätzliche Verifikation (E-Mail-timepass)",
    "screen.additionalVerification.title.Fixed": "Zusätzliche Verifikation (fixedpass)",
    "screen.additionalVerification.title.Sms": "Zusätzliche Verifikation(SMS one-timepass)",
    "screen.additionalVerification.watermark": "<Geben Sie ein zusätzliches Passwort ein>",
    "screen.changepass.cancel": "Stornieren",
    "screen.changepass.changed": "das Passwort wurde erfolgreich geändert",
    "screen.changepass.confirm": "Das neue Passwort bestätigen",
    "screen.changepass.new": "Neues Passwort",
    "screen.changepass.ok": "OK",
    "screen.changepass.old": "Aktuelles Passwort",
    "screen.changepass.title": "Passwort ändern",
    "screen.changeTradingPass.title": "Handelspasswort ändern",
    "screen.changepass.watermark_confirm": "<Passwort bestätigen>",
    "screen.changepass.watermark_new": "<Neues Passwort>",
    "screen.changepass.watermark_old": "<Altes Passwort>",
    "screen.closePosition.cancel": "Stornieren",
    "screen.closePosition.ok": "Schließen",
    "screen.closePosition.title": "Position schließen",
    "screen.error.title": "Fehler!",
    "screen.licensing.emptyFieldErrors.password": "Das Passwortfeld ist leer",
    "screen.licensing.statusMessages.connecting": "Anschließe...",
    "screen.login.change_pass": "Das Passwort wurde zurückgesetzt oder ist abgelaufen und muss geändert werden",
    "screen.lookup.scripts.noitems": "Keine passenden Artikel",
    "screen.lookup.scripts.showing": "{1} Objekte werden angezeigt",
    "screen.lookup.scripts.watermark": "<Suche>",
    "screen.modifyOrder.amount": "Menge:",
    "screen.modifyOrder.bind_to": "Verpflichtet zu:",
    "screen.modifyOrder.Change order": "Order ändern",
    "screen.modifyOrder.created": "Daten/Zeit:",
    "screen.modifyOrder.instrument": "Symbol:",
    "screen.modifyOrder.market_price": "Aktueller Preis:",
    "screen.modifyOrder.modify": "ändern",
    "screen.modifyOrder.modifyPosition": "Position ändern",
    "screen.modifyOrder.number": "Position ID:",
    "screen.modifyOrder.operation": "Seite:",
    "screen.modifyOrder.orderNumber": "Auftrag ID:",
    "screen.modifyOrder.ordertype": "Autragstyp:",
    "screen.modifyOrder.price": "Aufstiegkurs:",
    "screen.modifyOrder.route": "Route:",
    "screen.properties.cancel": "Schließen",
    "screen.properties.cancel.ToolTip": "Schließen",
    "screen.properties.common": "Eigenschaften",
    "screen.properties.ok": "OK",
    "screen.properties.ok.ToolTip": "OK",
    "screen.properties.title": "Generelle Einstellungen",
    "screen.properties.unsavedChanges": "Sie haben nicht gespeicherte Änderungen. Beenden und Änderungen verlieren?",
    "screen.LoginScreen.Connecting": "Anschließe...",
    "screen.LoginScreen.Error.loginIdIsEmpty": "Das Anmelde-ID-Feld ist leer",
    "screen.LoginScreen.Error.passwordIdIsEmpty": "Das Passwortfeld ist leer",
    "screen.LoginScreen.ForgotLinkLabel": "Passwort vergessen",
    "screen.LoginScreen.ForgotLinkLabel.ToolTip": "Passwort-Wiederherstellung",
    "screen.LoginScreen.LoadingUserData": "Benutzerdaten werden geladen...",
    "screen.LoginScreen.LoginButtonText": "Anmeldung",
    "screen.LoginScreen.LoginButtonTooltip": "Melden Sie sich bei der Plattform an",
    "screen.LoginScreen.LoginIDLabel": "Anmeldung",
    "screen.LoginScreen.LoginIDTextBox.ToolTip": "Benutzer-Login",
    "screen.LoginScreen.PasswordLabel": "Passwort",
    "screen.LoginScreen.PasswordTextBox.ToolTip": "Benutzer-Passwort",
    "screen.LoginScreen.RegistrationLinkLabel": "Demo Konto erstellen",
    "screen.LoginScreen.RegistrationLinkLabel.ToolTip": "Demokonto anmeldungo",
    "screen.LoginScreen.SavePassCheckBox": "Passwort speichern",
    "screen.LoginScreen.SavePassCheckBox.ToolTip": "Anmeldeinformationen merken",
    "screen.LoginScreen.Settings.LanguageLabel": "Sprache",
    "screen.recconection.attemptLabelLabel": "Versuche:",
    "screen.recconection.reconnecting": "Erneut verbinden...",
    "screen.recconection.seconds": "sekunden...",
    "screen.recconection.statusLabelLabel": "Status:",
    "screen.recconection.stop": "Stop",
    "screen.recconection.title": "Manager für die erneute Verbindung",
    "screen.recconection.tryNow": "Versuchen Sie jetzt",
    "screen.recconection.waiting": "Warten ",
    "screen.RecoveryPassForm.Cancel": "Stornieren",
    "screen.RecoveryPassForm.Confirm": "Passwort bestätigen",
    "screen.RecoveryPassForm.Email": "Email:",
    "screen.RecoveryPassForm.HaveCode": "Ich habe einen Schlüssel",
    "screen.RecoveryPassForm.Key": "Schlüssel",
    "screen.RecoveryPassForm.Login": "Login:",
    "screen.RecoveryPassForm.New password": "Neues Passwort ",
    "screen.RecoveryPassForm.New password contains prohibited symbols": "Neues Passwort enthält verbotene Symbole",
    "screen.RecoveryPassForm.Send": "wiederherrstellen ",
    "screen.RecoveryPassForm.Title": "Passwort Wiederherstellung",
    "screen.registrationWizard.confirmPassword": "*Passwort bestätigen:",
    "screen.registrationWizard.confirmPassword.watermark": "<bestätigen Sie Ihr Passwort>",
    "screen.registrationWizard.email": "*Email:",
    "screen.registrationWizard.email.watermark": "<Email>",
    "screen.registrationWizard.Country": "Land:",
    "screen.registrationWizard.Password": "*Passwort:",
    "screen.registrationWizard.Balance": "Bilanz:",
    "screen.registrationWizard.firstName": "*Vorname:",
    "screen.registrationWizard.firstName.watermark": "<min 2 chars>",
    "screen.registrationWizard.lastName": "*Familienname:",
    "screen.registrationWizard.lastName.watermark": "<min 2 chars>",
    "screen.registrationWizard.login": "Login:",
    "screen.registrationWizard.login.watermark": "<Leave empty for auto-generation>",
    "screen.registrationWizard.password.watermark": "<Geben Sie Ihr Passwort ein>",
    "screen.registrationWizard.phone": "Telefonnummer :",
    "screen.registrationWizard.phoneNumber.watermark": "<(Landesvorwahl) telefonnummer >",
    "screen.registrationWizard.registrationStatus": "Registrationsstatus",
    "screen.registrationWizard.successMessage": "Die Registrierung ist erfolgreich!\nAnmeldung: {1}\nPasswort: {2}",
    "screen.registrationWizard.Header": "Demo Konto erstellen",
    "screen.registrationWizard.Register": "Register",
    "screen.registrationWizard.Cancel": "Stornieren",
    "screen.renameScreen.cancel": "Stornieren",
    "screen.renameScreen.illegalName": "Illegaler Name!",
    "screen.renameScreen.ok": "OK",
    "screen.ReportMessageTooltip.Account": "Konto",
    "screen.ReportMessageTooltip.CreatedAt": "Erstellt in",
    "screen.ReportMessageTooltip.FilledAt": "gefüllt um",
    "screen.ReportMessageTooltip.MarginCall": "Margin call",
    "screen.ReportMessageTooltip.Market": "Market",
    "screen.ReportMessageTooltip.ModifiedAt": "geändert am",
    "screen.ReportMessageTooltip.RemovedAt": "entfernt um",
    "screen.reports.actions_print": "Drucken",
    "screen.reports.address": "Adresse",
    "screen.reports.beginDatePicker.ButtonDropDown.ToolTip": "Klicken Sie hier, um den Kalender zu öffnen",
    "screen.reports.beginDatePicker.ToolTip": "Datum / Uhrzeit auswählen",
    "screen.reports.brokerzip": "Postleitzahl",
    "screen.reports.button_printer_setup": "Konfiguration",
    "screen.reports.byGroup": "nach Gruppe",
    "screen.reports.byInstrumentGroup": "nach Gruppe",
    "screen.reports.byInstruments": "nach Instrumenten",
    "screen.reports.byUser": "nach Konto",
    "screen.reports.byUserGroup": "nach Gruppe",
    "screen.reports.cell_data": "",
    "screen.reports.column.inner_text": "",
    "screen.reports.columns_maneger": "Spaltenmanager...",
    "screen.reports.country": "Land",
    "screen.reports.csv": "Im CSV exportieren...",
    "screen.reports.customerzip": "Stadtreißverschluss",
    "screen.reports.dataCoverage": "Datenabdeckung",
    "screen.reports.errSaving": "Beim Speichern der Datei ist ein Fehler aufgetreten.",
    "screen.reports.excel": "Im Excel exportieren...",
    "screen.reports.exportToButton": "Exportieren nach...",
    "screen.reports.filter": "Filtermanager...",
    "screen.reports.historyFor": "Historie für",
    "screen.reports.html": "Im HTML exportieren...",
    "screen.reports.info": "Information",
    "screen.reports.error": "Fehler",
    "screen.reports.Reports": "Berichte",
    "screen.reports.InstrumentAndGroupLookup.byGroupCombobox.ToolTip": "Symbolgruppe auswählen",
    "screen.reports.InstrumentAndGroupLookup.instrunentLookup.ToolTip": "Symbol auswählen",
    "screen.reports.license": "Lizenznummer",
    "screen.reports.listViewEx.itemAll": "Alle",
    "screen.reports.manager": "Manager",
    "screen.reports.minimizeButton.Hide reports parameters": "Berichtsparameter ausblenden",
    "screen.reports.minimizeButton.Show reports parameters": "Berichtsparameter anzeigen",
    "screen.reports.name": "Name",
    "screen.reports.news.daily": "Tägliche",
    "screen.reports.news.from": "Von",
    "screen.reports.news.hideACriterias": "Erweiterte Kriterien ausblenden",
    "screen.reports.news.invalidDateTime": "Bitte wählen Sie ein gültiges 'von' und 'bis' Datum / Uhrzeit",
    "screen.reports.news.monthToDate": "diesen Monat",
    "screen.reports.news.range": "Umfang",
    "screen.reports.news.showACriterias": "Erweiterte Kriterien anzeigen",
    "screen.reports.news.sinceInception": "Seit Beginn",
    "screen.reports.news.till": "Bis",
    "screen.reports.news.yearToDate": "seit Jahresbeginn bis heute",
    "screen.reports.noData": "keine Angaben vorhanden",
    "screen.reports.order": "Order",
    "screen.reports.param.Name": "Name",
    "screen.reports.paramOfReport": "Parameter des Berichts",
    "screen.reports.pdf": "Im PDF exportieren...",
    "screen.reports.phone": "Telefonnummer",
    "screen.reports.pos": "Position",
    "screen.reports.president": "Name des Präsidenten",
    "screen.reports.print": "Drucken...",
    "screen.reports.processingRequset": "Stornieren",
    "screen.reports.rangeError": "\" Von\" Datum darf nicht höher sein als \"Bis\" Datum!",
    "screen.reports.report.button_printer_setup": "Konfiguration",
    "screen.reports.reportHeader.accnumber": "Kontonummer",
    "screen.reports.reportHeader.from": "Von",
    "screen.reports.reportHeader.Text": "Kunden- und Brokerinformation",
    "screen.reports.reportHeaders.account": "Konto:",
    "screen.reports.reportHeaders.currancy": "Währung:",
    "screen.reports.reportHeaders.date": "Daten:",
    "screen.reports.reportHeaders.from": "Von:",
    "screen.reports.reportHeaders.owner": "Inhaber:",
    "screen.reports.reportHeaders.to": "Zu:",
    "screen.reports.reportHeaderText": "Kunden- und Brokerinformation",
    "screen.reports.reportParameters": "Berichtsparameter",
    "screen.reports.reports.Canceled": "Vorgang wurde abgebrochen",
    "screen.reports.reports.ExpingToCSV": "Daten für CSV Datei exportieren...",
    "screen.reports.reports.ExpingToExcel": " Daten für Excel Datei exportieren...",
    "screen.reports.reports.ExpingToHTML": "Daten für HTML Datei exportieren...",
    "screen.reports.reports.ExpingToPDF": "Daten für PDF Datei exportieren...",
    "screen.reports.reports.ExportingPrint": "Daten für Nachdruck exportieren...",
    "screen.reports.reports.ExpPrint": "Daten wurden zum Drucken erfolgreich exportiert.",
    "screen.reports.reports.ExpPrintErr": "Während dem Exportieren zum Drucken ist ein Fehler aufgetreten!",
    "screen.reports.reports.ExpToCSV": "Daten wurden erfolgreich nach CSV exportiert.",
    "screen.reports.reports.ExpToCSVErr": "Während dem Exportieren in CSV ist ein Fehler aufgetreten!",
    "screen.reports.reports.ExpToExcel": "Daten wurden erfolgreich nach Excel exportiert.",
    "screen.reports.reports.ExpToExcelErr": "Während dem Exportieren in Excel ist ein Fehler aufgetreten!",
    "screen.reports.reports.ExpToHTML": "Daten wurden erfolgreich nach HTML exportiert.",
    "screen.reports.reports.ExpToHTMLErr": "Während dem Exportieren in HTML-Dateien ist ein Fehler aufgetreten!",
    "screen.reports.reports.ExpToPDF": "Daten wurden erfolgreich nach PDF exportiert.",
    "screen.reports.reports.ExpToPDFErr": "Während dem Exportieren in PDF-Dateien ist ein Fehler aufgetreten!",
    "screen.reports.reports.getReport": "Bericht abrufen",
    "screen.reports.reports.in_separate_window": "Der Bericht wurde in einem separaten Fenster geöffnet",
    "screen.reports.reports.inCurrentWindow": "Im aktuellen Fenster",
    "screen.reports.reports.info": "Verwenden Sie den Berichts-Manager, um Berichte zur Kontoaktivität anzuzeigen und auszudrucken",
    "screen.reports.reports.inSeparateWindow": "In separatem Fenster",
    "screen.reports.reports.loading": "Daten für Bericht werden geladen...",
    "screen.reports.reports.noData": "Leider sind für diese Anfrage keine Daten verfügbar. Versuchen Sie, andere Parameter des Berichts zu verwenden.",
    "screen.reports.reports.noItemAvailable": "Kein Artikel verfügbar",
    "screen.reports.reportType": "Berichtstyp",
    "screen.reports.reportTypeCombobox.ToolTip": "Wählen Sie den Berichtstyp",
    "screen.reports.save": "Speichern",
    "screen.reports.showSummary": "Übersicht anzeigen",
    "screen.reports.sinceInception": "Seit Beginn",
    "screen.reports.summary": "Zusammenfassung",
    "screen.reports.table": "Tabelle",
    "screen.reports.title": "Berichtsmanager",
    "screen.reports.to": "Zu",
    "screen.reports.trades": "Trades",
    "screen.reports.UserAndGroupLookup.byGroupCombobox.ToolTip": "Wählen Sie die Kontogruppe aus",
    "screen.reports.UserAndGroupLookup.usersLookup.ToolTip": "Konto auswählen",
    "screen.TimeZoneSelector.title": "Zeitzonen auswählen",
    "screen.UnlockTradingScreen.lockedLabel": "Handel gesperrt",
    "screen.UnlockTradingScreen.unlockButton": "Entsperren",
    "screen.withdrawal.account": "Vom Konto",
    "screen.withdrawal.Asset": "Aktiv",
    "screen.withdrawal.Availible funds": "Verfügbar:",
    "screen.withdrawal.avaliableNumeric.ToolTip": "Legen Sie das verbleibende Kapital fest",
    "screen.withdrawal.Cancel": "Stornieren",
    "screen.withdrawal.error.not_allowed": "Kontobetrieb mit der Art \"Auszahlung\" ist für Ihren Benutzer nicht zulässig. Wenden Sie sich an Ihren Broker",
    "screen.withdrawal.error.not_enough_balance": "Nicht genug Gleichgewicht",
    "screen.withdrawal.error.not_enough_blocked": "Ungültiger Betrag (kann nicht mehr als gesperrt entsperren)",
    "screen.withdrawal.error.not_enough_margin": "Nicht genügend Spielraum",
    "screen.withdrawal.error.UnableToBlock": "Fehlerfall! Blockieren nicht möglich.",
    "screen.withdrawal.error.WrongSum": "Fehlerfall: falsche Summe.",
    "screen.withdrawal.errorLabel": "Nicht genug Geld zum Abheben",
    "screen.withdrawal.Fee": "Abhebegebühr:",
    "screen.withdrawal.from": "von",
    "screen.withdrawal.Remains": "Überreste",
    "screen.withdrawal.RequiredAmount": "Erforderliche Menge:",
    "screen.withdrawal.ReservedWithdrawal": "Rückzug",
    "screen.withdrawal.usersLookup.ToolTip": "Konto auswählen",
    "screen.withdrawal.Withdraw": "Abheben",
    "screen.withdrawal.Withdrawal": "Rückzug",
    "screen.withdrawal.withdrawalNumeric.ToolTip": "Stellen Sie das abhebende Kapital ein",
    "screen.withdrawal.Amount": "Betrag",
    "reports.accounts": "Betrag(s)",
    "reports.instruments": "Instrument(s)",
    "reports.fromDate": "Von",
    "reports.toDate": "Zu",
    "reports.asset": "Aktiv",
    "Simple Moving Average": "Einfacher gleitende Durschnitt",
    "SixMonth": "6 Monate",
    "Smoothed Moving Average": "Geglättete gleitende Durchschnitt",
    "Standard Deviation": "Standardabweichung",
    "statusBar.passwordpanel.DetalTicket.Text": "Erlaubter Zugang zu Handelsaktivitäten",
    "statusBar.passwordPanel.passwordBox.invalidPass": "Das Handelskennwort ist ungültig.",
    "Stochastic": "Stochastisch",
    "Stochastic Slow": "Stochastisch Slow",
    "Stop": "Stopp",
    "Stop Limit": "Stop limit",
    "Stop-loss": "Stop loss",
    "Suomi": "#hidden#",
    "Swing Index": "Swing index",
    "Take-profit": "Take profit",
    "TerceraAccountLookup.AccountNotExist": "Das Konto existiert nicht",
    "TerceraAccountLookup.IncorrectAccount": "Falsches Konto",
    "TerceraAccountLookup.Linked accounts": "Verbundene Konten",
    "TerceraAccountLookup.Multy select": "Mehrfachauswahl",
    "TerceraAccountLookup.Select": "Wählen",
    "TerceraAccountLookup.Select All": "Wählen Sie Alle",
    "textBoxWithValidating.ErrorText.NameContainsInvalidChar": "Name beinhaltet ungültige Zeichen",
    "textBoxWithValidating.ErrorText.NameExist": "Dieser Name existiert bereits",
    "ThreeMonth": "3 Monate",
    "Tr. stop": "Trailing stop",
    "TradeDefaultManager.Set defaults": "Set defaults",
    "TradeDefaultManager.Types manager": "Manager Typen",
    "TradeTool.Amount of order": "Ordermenge",
    "TradeTool.Cancel order": "Orders stornieren",
    "TradeTool.Cancel SL": "SL löschen",
    "TradeTool.Cancel TP": "TP löschen",
    "TradeTool.Close position": "Position schließen",
    "TradeTool.Drag to set SL order": "Hinziehen um SL zu stellen",
    "TradeTool.Drag to set TP order": "Hinziehen um TP zu stellen",
    "TradeTool.Order": "Order",
    "TradeTool.Position": "Position",
    "TradeTool.Position P/L": "Brutto P/L",
    "TradeTool.Tick and P/L": "Ticks und P/L",
    "TradeTool.Trailing Stop": "Trailing stop",
    "TradeTool.View more actions": "Weitere Aktionen anzeigen",
    "TTO": "TTO",
    "TypesManagerScreen.DefaultLotsSetup": "Standardeinstellung für Lot",
    "TypesManagerScreen.NumericLabel.Price increment (arrow), ticks": "Preiserhöhung (Pfeil) ankreuzen",
    "TypesManagerScreen.NumericLabel.SL default offset, ticks": "SL-Standarabstand, Ticks",
    "TypesManagerScreen.NumericLabel.TP default offset, ticks": "TP-Standarabstand, ticks",
    "TypesManagerScreen.NumericLabel.User quantity coefficient": "Benutzermengenkoeffizient",
    "TypesManagerScreen.QuickTable.Column.Default lots": "Standardlot",
    "TypesManagerScreen.QuickTable.Column.Enable": "Aktivieren",
    "TypesManagerScreen.Types Manager": "Manager Typen",
    "UserControl.TerceraNumeric.ValueGreaterMax": "Wert ist größer als Maximum",
    "UserControl.TerceraNumeric.ValueIsEmpty": "Wert ist leer",
    "UserControl.TerceraNumeric.ValueLessMin": "Wert ist kleiner als Minimum",
    "UserControl.TerceraNumeric.ValueNotMultiple": "Wert ist nicht mehrfach bis zur minimalen Änderung",
    "UserControl.TerceraNumeric.ValueNotNumber": "Wert ist keine Zahl",
    "Volume": "Volumen",
    "Volume Weighted Average Price": "Volumengewichteter DurchschnittsKurs",
    "VWAP": "VWAP",
    "allowCreatePanelsFromPanel": "",
    "allowLinkSystem": "",
    "allowWorkspaceContextMenu": "",
    "allowWorkspaceEdit": "",
    "orders.confirm.all.question1": "Alle stornieren (",
    "orders.confirm.all.question2": ") orders?",
    "orders.confirm.all.title": "Alle Orders stornieren",
    "orders.confirm.cancel.question1": "Alle Orders nach ausgewähltem Symbol stornieren?",
    "positions.confirm.mutual.question1": "Möchten Sie das Ausgewählte miteinander schließen(",
    "positions.confirm.mutual.question2": ") positions?",
    "Rows.firstPart": "Das Limit der verfügbaren Symbole / Zeilen in",
    "Rows.secondPart": "wurde erreicht. Bitte wenden Sie sich an Ihr Support-Team, um detaillierte Informationen zu erhalten",
    "general.messageBox.cancel.ToolTip": "Änderungen absagen und schließen",
    "property.Confirmations.ToolTip": "Bestätigungseinstellungen",
    "general.messageBox.ok.ToolTip": "Änderungen übernehmen und schließen",
    "property.Defaults.ToolTip": "Trading Standardeinstellungen",
    "property.View.ToolTip": "Einstellungen anzeigen",
    "property.Warnings.ToolTip": "Warnung Einstellungen",
    "Throtling.Count.firstPart": "Das Limit der verfügbaren",
    "Throtling.Count.secondPart": "Panels wurde erreicht. Bitte wenden Sie sich an Ihr Support-Team, um detaillierte Informationen zu erhalten",
    "workspace.At first you should unlock workspace to rename it": "Zuerst sollten Sie den Arbeitsbereich entsperren, um ihn umzubennenen.",
    "workspace.Clear": "Deutlich",
    "workspace.clear_ask": "Arbeitsbereich löschen?",
    "workspace.clear_wsp": "Arbeitsbereich löschen",
    "workspace.Close": "Schließen",
    "workspace.close_wsp": "Arbeitsplatz schließen",
    "workspace.DefaultNameSpace": "Wrk#",
    "workspace.errorSavingFile": "Beim Speichern der Datei ist ein Fehler aufgetreten.",
    "workspace.information": "Information",
    "workspace.saved.Message": "Arbeitsplatz gespeichert",
    "workspace.Lock": "Sperren",
    "workspace.Lock.Message": "Arbeitsplatz gesperrt",
    "workspace.menu.Clone": "Klon",
    "workspace.menu.Close": "Schließen",
    "workspace.menu.CloseAllButThis": "Schließe alles außer diesen",
    "workspace.menu.Lock": "Sperren",
    "workspace.newWorkspaceName": "Name von neuem Arbeitsplatz eingeben",
    "workspace.numberOfWorkspace": "Die Anzahl der offenen Arbeitsplätze überschreitet die maximal zulässige Anzahl",
    "workspace.Rename": "Umbenennen",
    "workspace.Unlock": "Entsperren",
    "workspace.Unlock.Message": "Arbeitsplatz freigeschaltet",
    "InstrumentDetailsPanel.ISIN": "ISIN",
    "IndicatorGroup.Moving Average": "Moving Average",
    "MD": "MD",
    "REGRESSION": "REGRESSION",
    "Regression": "Regression",
    "SMMA": "SMMA",
    "PPMA": "PPMA",
    "SMA": "SMA",
    "EMA": "EMA",
    "IndicatorGroup.Oscillators": "Oscillators",
    "MACD": "MACD",
    "BB": "BB",
    "BBF": "BBF",
    "IndicatorGroup.Volume": "Volume",
    "RSI": "RSI",
    "PO": "PO",
    "CCI": "CCI",
    "Aroon": "Aroon",
    "KRI": "KRI",
    "Kairi Relative Index": "Kairi Relative Index",
    "PPO": "PPO",
    "Percentage Price Oscillator": "Percentage Price Oscillator",
    "RLW": "RLW",
    "%R Larry Williams": "%R Larry Williams",
    "ROC": "ROC",
    "Rate of Change": "Rate of Change",
    "QStick": "QStick",
    "TSI": "TSI",
    "True Strength Index": "True Strength Index",
    "Pivot point": "Pivot point",
    "SI": "SI",
    "ALLIGATOR": "ALLIGATOR",
    "MMA": "MMA",
    "IndicatorGroup.Trend": "Trend",
    "STOCHASTIC": "STOCHASTIC",
    "OBV": "OBV",
    "IndicatorGroup.Volatility": "Volatility",
    "ATR": "ATR",
    "CMO": "CMO",
    "SD": "SD",
    "SAR": "SAR",
    "ADX": "ADX",
    "IndicatorGroup.Channels": "Channels",
    "KELTNER": "KELTNER",
    "CHANNEL": "CHANNEL",
    "MAE": "MAE",
    "LWMA": "LWMA",
    "MAS3": "MAS3",
    "IndicatorGroup.Extra": "Extra",
    "Didi": "Didi",
    "HiloEscandina": "HiloEscandina",
    "StopATR": "StopATR",
    "Fractals3": "Fractals3",
    "Fractals5": "Fractals5",
    "Kaufman adaptive moving average": "Kaufman adaptive moving average",
    "Multi EMA": "Multi EMA",
    "Self-Adjusting Alpha with Fractals Energy": "RSI Self-Adjusting Alpha with Fractals Energy",

    "Web.mobile.active.WorkingOrders": "Offene",
    "Web.mobile.active.WorkingOrders.EmptyPlaceholder": "Keine Aufträge",
    "Web.mobile.active.FilledOrders": "Gefüllt",
    "Web.mobile.active.HistoryOrders": "Historie",
    "Web.mobile.active.Positions": "Positionen",
    "Web.mobile.active.Positions.EmptyPlaceholder": "Keine Positionen",
    "Web.mobile.active.Assets": "Vermögenswerte",
    "Web.mobile.active.Orders": "Aufträge",
    "Web.mobile.active.More": "Mehr",
    "Web.mobile.active.MamSummary": "MAM-Zusammenfassung",
    "Web.mobile.active.OptionChain": "Optionskette",
    "Web.mobile.active.Alerts.NoAlerts": "Keine Warnungen",
    "Web.mobile.active.EventLog.NoEvents": "Keine Ereignisse",
    "Web.mobile.active.EventLog.DateTime": "Datum & Uhrzeit",
    "Web.mobile.active.MamSummary.Column.OperationType": "Typ der Operation",
    "Web.mobile.active.MamSummary.Column.Amount": "Menge",
    "Web.mobile.active.More.Header": "Mehr",
    "Web.mobile.active.Settings.Defaults": "Standardeinstellungen",
    "Web.mobile.active.Settings.QuantityInLots": "Menge in Lots",
    "Web.mobile.active.Settings.SetSlTpInOffset": "SL/TP im Offset festlegen",
    "Web.mobile.active.Settings.OrderSending": "Auftragsversand",
    "Web.mobile.active.Settings.OrderModifying": "Auftragsänderung",
    "Web.mobile.active.Settings.OrderExecuting": "Auftragausführung",
    "Web.mobile.active.Settings.OrderCancelling": "Auftragsstornierung",
    "Web.mobile.active.Settings.PositionModifying": "Position ändern",
    "Web.mobile.active.Table.Total.Profit": "Gesamter Gewinn",
    "Web.mobile.active.NewTrades.PnL": "Gewinn/Verlust",
    "Web.mobile.active.OrderBook.ExecutionType": "Ausführungstyp",
    "Web.mobile.active.Assets.EmptyPlaceholder": "Keine Vermögenswerte",
    "Web.mobile.active.Assets.DefSymbol": "Standard-Symbol",
    "Web.mobile.active.Assets.SODQuantity": "SOD Menge",
    "Web.mobile.active.Assets.CurrentValueForSale": "Aktueller Wert zum Verkauf",
    "Web.mobile.active.Positions.Profit": "P/L",
    "Web.mobile.active.AccountDetails.LinkingTooltip": "Diese Funktion filtert Ihre Positionen und Aufträge nach Konto. Das Konto wird in allen Panelen synchronisiert.",
    "Web.mobile.active.Watchlist.EmptyPlaceholder": "Keine Symbole",
    "Web.mobile.active.Watchlist.ListNamePlaceholder": "Hier den Namen eingeben",
    "Web.mobile.active.Watchlist.Added": "Zur Watchlist hinzugefügt",
    "Web.mobile.active.Watchlist.Removed": "Von der Watchlist entfernt",
    "Web.mobile.active.Watchlist.MaxReached": "Maximale Anzahl erreicht",
    "Web.mobile.active.ExchangesFilter.Filter": "Filter",
    "Web.mobile.active.OrderEntry.Send": "Auftrag senden",
    "Web.mobile.active.OrderEntry.Price": "Preis",
    "Web.mobile.active.OrderEntry.TrStop": "Tr. Stopp",
    "Web.mobile.active.OrderEntry.SlPrice": "Stop-Loss-Preis",
    "Web.mobile.active.OrderEntry.SlOffset": "Stop-Loss-Offset",
    "Web.mobile.active.OrderEntry.TrSlOffset": "Trailing SL-Offset",
    "Web.mobile.active.OrderEntry.SllPrice": "Stop-Loss-Limit-Preis",
    "Web.mobile.active.OrderEntry.SllOffset": "Stop-Loss-Limit-Offset",
    "Web.mobile.active.OrderEntry.TpPrice": "Take-Profit-Preis",
    "Web.mobile.active.OrderEntry.TpOffset": "Take-Profit-Offset",
    "Web.mobile.active.Login.ExploreTheApp": "Die App erkunden",
    "Web.mobile.active.InstrumentInfo.Price": "Preis",
    "Web.mobile.active.InstrumentInfo.BreakEven": "Break-even",
    "Web.mobile.active.Asset.SellExchange": "Börse",
    "Web.mobile.active.Asset.StartDayQtyMargin": "SOD Menge für MA",
    "Web.mobile.active.Asset.LiquidityRate": "Liquiditätsrate, %",
    "Web.mobile.active.Asset.TodayTradedQty": "Intraday-Menge",
    "Web.mobile.active.Asset.AvailableQtySell": "Verfügbare Menge",
    "Web.mobile.active.FilledOrder.TradeVolume": "Handelsvolumen",
    "Web.mobile.active.FilledOrder.Date": "Datum",
    "Web.mobile.active.FilledOrder.GrossPnL": "Gross P/L",
    "Web.mobile.active.HistoryOrder.Date": "Datum",
    "Web.mobile.active.MamSummary.EmptyPlaceholder": "Sie haben keine Kontohistorie für diesen Zeitraum",
    "Web.mobile.active.MamSummary.Account": "Konto",
    "Web.mobile.active.MamSummary.OperationId": "Operation ID",
    "Web.mobile.active.MamSummary.InstrumentName": "Instrumentenname",
    "Web.mobile.active.MamSummary.OpenPrice": "Eröffnungspreis",
    "Web.mobile.active.MamSummary.ClosePrice": "Schlusskurs",
    "Web.mobile.active.MamSummary.Side": "Seite",
    "Web.mobile.active.MamSummary.Quantity": "Menge",
    "Web.mobile.active.MamSummary.Buy": "Kaufen",
    "Web.mobile.active.MamSummary.Sell": "Verkaufen",
    "Web.mobile.active.Position.StopLoss": "Stop-Loss",
    "Web.mobile.active.Position.TakeProfit": "Take-Profit",
    "Web.mobile.active.Position.Date": "Datum",
    "Web.mobile.active.Order.StopLoss": "Stop-Loss",
    "Web.mobile.active.Order.TrStopOffset": "Trailing SL offset",
    "Web.mobile.active.Order.TrStopLoss": "SL Trailing stop",
    "Web.mobile.active.Order.TakeProfit": "Take-Profit",
    "Web.mobile.active.Order.QuantityFilled": "Qty. filled",
    "Web.mobile.active.Search": "Suchen",
    "Web.mobile.active.CalendarPeriod.Today": "Heute",
    "Web.mobile.active.CalendarPeriod.LastWeek": "Letzte Woche",
    "Web.mobile.active.CalendarPeriod.Last1M": "Letzter Monat",
    "Web.mobile.active.CalendarPeriod.Last3M": "Letzte 3 Monate",
    "Web.mobile.active.CalendarPeriod.Last6M": "Letzte 6 Monate",
    "Web.mobile.active.InstrumentDetails.6.SessionInfo": "Zeitplan",
    "Web.mobile.active.OrderModify.Confirmation.Title.Modify": "Auftrag ändern",
    "Web.mobile.active.OrderModify.Confirmation.Title.Cancel": "Auftrag stornieren",
    "Web.mobile.active.OrderModify.Confirmation.Title.ExecuteAtMarket": "Auftrag am Markt ausführen?",
    "Web.mobile.active.OrderModify.Error.SLSmallerMin": "Stop-Loss ist kleiner als der minimale mögliche Wert",
    "Web.mobile.active.OrderModify.Error.SLGreaterMax": "Stop-Loss ist größer als der maximale mögliche Wert",
    "Web.mobile.active.OrderModify.Error.TPSmallerMin": "Take-Profit ist kleiner als der minimale mögliche Wert",
    "Web.mobile.active.OrderModify.Error.TPGreaterMax": "Take-Profit ist größer als der maximale mögliche Wert",
    "Web.mobile.active.PositionModify.Confirmation.Title": "Position ändern",
    "Web.mobile.active.PositionClose.Confirmation.Lots": "Lot(s)",
    "Web.mobile.active.Lists": "Listen",
    "Web.mobile.active.AccountDetails.Groups.1.AccountSummary": "Kontozusammenfassung",
    "Web.mobile.active.AccountDetails.Groups.4.TodayResult": "Tagesergebnis",
    "Web.mobile.active.AccountDetails.MarginUsedPercent": "Anfangsmarginbedarf %",
    "Web.mobile.active.AccountDetails.OptionPremiumReq": "Optionsprämienbedarf",
    "Web.mobile.active.AccountDetails.OpenGrossPL": "Offener Bruttogewinn/Verlust",
    "Web.mobile.active.AccountDetails.OpenPostionsNumber": "Offene Positionen",
    "Web.mobile.active.AccountDetails.OpenOrdersNumber": "Arbeitsaufträge",
    "Web.mobile.active.AccountDetails.TodaysNetProfit": "Heutiger Nettogewinn",
    "Web.mobile.active.Chart.IndicatorsMenu.AddedIndicators": "Hinzugefügte Indikatoren",
    "Web.mobile.active.Chart.Menu.ChartSyle": "Chart-Stil",
    "Web.mobile.active.Chart.Menu.Settings": "Einstellungen",
    "Web.mobile.active.Chart.NoDataAvailable": "Keine Daten verfügbar",
    "Web.mobile.active.TrailingStopLoss": "Tr.",
    "Web.mobile.active.Position.GrossPnL": "Gross P/L",
    "Web.mobile.active.signIn.error.emptyLogin": "Login-Feld ist leer",
    "Web.mobile.active.signIn.error.emptyPassword": "Das Passwortfeld ist leer",
    "Web.mobile.active.Table.Total.GrossPnL": "Total Gross P/L",
}