// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../Commons/properties/Resources.ts";
import { TerceraButton } from "../Controls/elements/TerceraButton.ts";
import { TerceraWindowBase } from "../Controls/screen/TerceraWindowBase.js";
import { PanelNames } from "../Controls/UtilsClasses/FactoryConstants.ts";
import { CustomizeMetricsBarPanelTemplate } from "../templates.js";
import { TerceraButtonStyle } from "../Utils/Enums/ButtonEnums.ts";

export let CustomizeMetricsBarPanel = TerceraWindowBase.extend(
    {
        Name: 'CustomizeMetricsBarPanel',
        data: function ()
        {
            return {
                zIndex: 90000,
                width: 370,
                height: 420,

                movable: true,
                resizable: false,
                showHeader: true,
                showFooter: false,
                focused: true,

                btnAddStyle: TerceraButtonStyle.Standard,
                style_addition_header: 'CustomizeMetricsBar-AdditionalHeader',
            };
        },
        partials: {
            bodyPartial: CustomizeMetricsBarPanelTemplate,
        },
        onAddClicked: null,
        selectedItems: [],
        CustomizeMetricsBarPanel: null
    });

CustomizeMetricsBarPanel.prototype.getType = function () { return PanelNames.CustomizeMetricsBarPanel };

CustomizeMetricsBarPanel.prototype.oncomplete = function ()
{
    TerceraWindowBase.prototype.oncomplete.apply(this);

    this.center();
    this.on("okBtnClick", this.OkClicked)
    this.on("closeBtnClick", this.close)
    this.localize();
};

CustomizeMetricsBarPanel.prototype.OkClicked = function ()
{
    if (this.OkClickedCallBack)
        this.OkClickedCallBack(this.get("metricsData"), this.get("tapeRollingChecked"))

    this.close();
};

CustomizeMetricsBarPanel.prototype.close = function ()
{
    if (this.CloseClickedCallBack)
        this.CloseClickedCallBack()

    TerceraWindowBase.prototype.close.apply(this);
};

CustomizeMetricsBarPanel.prototype.localize = function ()
{
    this.set({
        header: Resources.getResource('portfolio.metrics.CustomizeBarPanel.header'),
        tapeRollingText: Resources.getResource('portfolio.metrics.TapeRolling'),
        okBtnText: Resources.getResource('portfolio.metrics.CustomizeBarPanel.okBtn'),
        closeBtnText: Resources.getResource('portfolio.metrics.CustomizeBarPanel.closeBtn')
    });
};