// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { GridPickerTemplate } from '../../templates.js';
import { Control } from './Control_ts';

export class GridPicker extends Control {
    constructor () {
        super();
    }

    public override getType (): string { return 'GridPicker'; }
    public override oninit (): void {
        super.oninit();
        this.gridItemMouseEnter = this.gridItemMouseEnter.bind(this);
        this.gridItemMouseClick = this.gridItemMouseClick.bind(this);
        this.restoreDefaultSelection = this.restoreDefaultSelection.bind(this);
    }

    public gridItemMouseEnter (row: number, column: number): void {
        void super.set({
            rowsSelected: row,
            columnsSelected: column
        });
    }

    public gridItemMouseClick (): void {
        super.fire(GridPicker.Events.Selected,
            super.get('rowsSelected'),
            super.get('columnsSelected'));
    }

    public restoreDefaultSelection (): void {
        void super.set({
            rowsSelected: super.get('defaultRowsSelected'),
            columnsSelected: super.get('defaultColumnsSelected')
        });
    };

    static Events = {
        Selected: 'selected'
    };
}

Control.extendWith(GridPicker, {
    template: GridPickerTemplate,
    data: function () {
        return {
            grid: null,

            rows: [1, 2, 3, 4, 5],
            columns: [1, 2, 3, 4, 5, 6],

            defaultRowsSelected: 1,
            defaultColumnsSelected: 1,

            rowsSelected: 1,
            columnsSelected: 1
        };
    }
});
