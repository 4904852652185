// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Connection, ConntionInfo } from "../../Commons/Connection.ts";
import { ConectionResultEnum, ConnectionStates, RegistrationMode } from "../../Commons/ConnectionEnums.ts";
import { Resources } from "../../Commons/properties/Resources.ts";
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";
import { KeyCode } from "../../Commons/KeyEventProcessor.ts";
import { TerceraBaseWindowTemplate, TerceraLoginScreenTemplate } from "../../templates.js";
import { TerceraButton } from "../elements/TerceraButton.ts";
import { AdditionalVerificationScreen } from "./AdditionalVerificationScreen.js";
import { TerceraRecoverScreen } from "../screen/TerceraRecoverScreen.js";
import { TerceraWindowBase } from "./TerceraWindowBase.js";
import { ThemeManager } from "../misc/ThemeManager.ts";
import { DataCache } from "../../Commons/DataCache.ts";
import { BrowserUtils } from "../../Commons/UtilsClasses/BrowserUtils.ts";
import { NewNetTraderMode } from "./NewNetTraderMode.ts";
import { WorkSpaceManager } from "../WorkSpace/WorkSpaceManager.ts";
import { TerceraChangePasswordScreen } from "./TerceraChangePasswordScreen.js";
import { ApplicationInfo } from "../../Commons/ApplicationInfo.ts";
import { LocalStorage } from "../../Commons/LocalStorage.ts";
import { UserWebStorageInstance } from "../../user-web-storage.ts";
import { ApplicationLoader } from "../../Commons/ApplicationLoader.ts";
import { BrandingUtils } from "../../Commons/UtilsClasses/BrandingUtils.ts";
import { AuthenticationStateStorage } from '../../Utils/ThirdPartyAuthentication/AuthenticationStateStorage.ts';
import { AuthenticationFactory } from '../../Utils/ThirdPartyAuthentication/AuthenticationFactory.ts';
import { AuthenticationResponseType } from '../../Utils/ThirdPartyAuthentication/AuthenticationResponseType.ts';
import { decryptWithDerivedKey, encryptAndDeriveKey } from "../../Utils/EncryptionUtils.ts";
import { TerceraButtonEvents } from "../../Utils/Enums/ButtonEnums.ts";

export let TerceraLoginScreen = TerceraWindowBase.extend({
    template: TerceraBaseWindowTemplate,
    data: function ()
    {
        return {
            movable: false,
            showHeader: false,
            showFooter: false,
            resizable: false,
            canSelect: false,
            alwaysMiddle: true,
            showFullscreenCloud: false,

            textLoginLabel: '',
            textPasswordLabel: '',
            textSavePassword: '',
            textRecover: '',
            textExplore: '',
            textButton: '',
            textCreateDemoAccount: '',

            tooltipLogin: '',
            tooltipPassword: '',
            savePassText: '',
            tooltipSavePassword: '',
            tooltipRecover: '',
            tooltipExplore: '',
            tooltipLoginBtn: '',
            tooltipCreateDemoAccount: '',
            langCBLabel: '',
            chatLinkText: '',

            style_addition_body: 'login-screen-additional',

            placeholderLogin: 'Login',
            placeholderPassword: 'Password',

            savePasswordChecked: false,
            savePasswordCheckedFromConnectionSettings: false,
            passwordTextBoxFocused: false,

            pictureUrl: ThemeManager.getImageFullUrlNew('g_login/login_branding.png'),
            comboItems: [{ value: 'Lang1', text: 'Lang1' }, { value: 'Lang2', text: 'Lang2' }, { value: 'Lang3', text: 'Lang3' }],
            textErrorLabel: '',
            connectionParams:
            {
                login: '',
                pass: '',
                connectionName: ''
            },
            reconnectConnectionParams: null,
            connectionInfo: [],
            connectionInfoMenuItems: [],
            selectedConnectionInfoMenuItem: null,

            isDisconnect: true,
            closeBtnVisible: false,

            needLogin: false,
            needPassword: false,
            needCustomerNo: false,

            allowPasswordRecovery: true,
            allowExplore: false,
            createDemoAccountAllowed: true,
            AllowCustomerNo: false,
            customerNoIndent: 0,

            loginTBTop: 0,
            passTBTop: 0,
            languageCBTop: 0,
            recoverLinkTop: 0,
            chatLinkTop: 0,
            createDemoAccTop: 0,
            loginBtnTop: 0,
            errorTextTop: 0,

            RegistrationModeEnum: RegistrationMode,
            login_language_selector: false,
            langItems: [],
            inputMode: true,
            ICFL: false,//IsCorrectFogotLink
            ICFRPL: false,//IsCorrectRecoveryPassLink,

            thirdPartyProvider: undefined
        };
    },
    partials: { bodyPartial: TerceraLoginScreenTemplate },
    wasPaste: false,
    forceCloseOnLogout: false
});

TerceraLoginScreen.prototype.getType = function () { return 'TerceraLoginScreen' };

TerceraLoginScreen.prototype.oninit = function ()
{
    TerceraWindowBase.prototype.oninit.apply(this);

    this.set('allowPasswordRecovery', !Resources.isHidden('screen.LoginScreen.PasswordRecovery'));
    this.set('createDemoAccountAllowed', !Resources.isHidden('screen.LoginScreen.CreateDemoAccount'));

    this.observe('connectionInfo', this.onConnectionInfoChanged);
    this.observe('selectedConnectionInfoMenuItem', this.onSelectedConnectionInfoMenuItemChanged);

    this.observe('needLogin', this.PlaceholderLoginChanged)
    this.observe('needPassword', this.PlaceholderPasswordChanged)
    this.observe('needCustomerNo', this.PlaceholderCustomerNoChanged)

    this.observe('inputMode', this.onInputModeChanged)

    this.on('recoverClick', this.onRecoverClicked);
    this.on('exploreClick', this.onExploreClicked);
    this.on('onThirdPartyLoginBtnClick', this.onThirdPartyLoginBtnClicked)
    this.observe('AllowCustomerNo', this.onAllowCustomerNoChanged)

    this.observe('connectionParams.login', this.onConnectionParamsLoginChanged);
    this.observe('connectionParams.pass', this.onConnectionParamsPassChanged);
    this.observe('connectionParams.customerNo', this.onConnectionParamsCustomerNoChanged);
    this.observe('passwordTextBoxFocused', this.onPasswordFocused);

    this.loadCredentials();

    let findSelectedLangItem = function ()
    {
        let items = Resources.getLanguagesCBItems();
        let cLang = Resources.GetCurrentAppLang()
        for (let i = 0; i < items.length; i++)
            if (items[i].value === cLang)
            {
                this.set("selectedlangItem", items[i])
                break;
            }
    }.bind(this);

    this.observe('visible', function (visible)
    {
        if (!visible)
            return;
        if (ApplicationInfo.login_language_selector)
            findSelectedLangItem();
        this.localize();
    });

    this.set('login_language_selector', ApplicationInfo.login_language_selector);
    if (ApplicationInfo.login_language_selector)
    {
        this.set('height', this.get('height'));
        let items = Resources.getLanguagesCBItems();
        this.set('langItems', items);
        findSelectedLangItem();
        this.observe('selectedlangItem', this.onSelectedlangItemChanged);
    }
    this.localize();

    ThemeManager.onThemeChange.Subscribe(this.onThemeChanged, this);
};

TerceraLoginScreen.prototype.getLoginScreenLanguage = function ()
{
    if (!ApplicationInfo.login_language_selector)
        return ""

    let langItem = this.get("selectedlangItem");
    return langItem ? langItem.value : "";
}

TerceraLoginScreen.prototype.onInputModeChanged = function (inputMode)
{
    //     // if (!inputMode)
    //     //     this.set({ height: 156, style_addition_body: 'login-screen-additional-Token' });
    //     // else
    //     //     this.set({ height: 440, style_addition_body: 'login-screen-additional-Full' });
}

TerceraLoginScreen.prototype.onAllowCustomerNoChanged = function (isAllow)
{
    this.set({ customerNoIndent: (isAllow ? 47 : 0) })
}

TerceraLoginScreen.prototype.onSelectedlangItemChanged = function (n, o)
{
    if (n && n === o)
        return;
    Resources.setLocale(n.value);
}

TerceraLoginScreen.prototype.onConnectionInfoChanged = function (connectionInfo)
{
    if (!connectionInfo || !connectionInfo.length)
        this.set('connectionInfoMenuItems', []);

    var lastConnectionName = LocalStorage.getLastConnectionName() || '';
    var lastConnection = null;

    var menuItems = [];
    var len = connectionInfo.length;
    for (var i = 0; i < len; i++)
    {
        var connInfo = connectionInfo[i];
        var name = connInfo.Name;
        var newItem = { text: name, value: connInfo };
        if (lastConnectionName === name)
            lastConnection = newItem;
        menuItems.push(newItem);
    }
    this.set('connectionInfoMenuItems', menuItems);
    if (lastConnection)
    {
        this.set('selectedConnectionInfoMenuItem', lastConnection);
        this.setCredentialsByConnName(lastConnectionName);
    }
};

TerceraLoginScreen.prototype.onSelectedConnectionInfoMenuItemChanged = function (selectedMenuItem)
{
    if (selectedMenuItem)
    {
        const selectedConnectionData = selectedMenuItem.value;
        const serverURL = selectedConnectionData.ServerAdress;
        this.onConnectionParamsCustomerIpURL(selectedConnectionData.ipURL).then((res) => 
        {
            if (this.get('selectedConnectionInfoMenuItem') != selectedMenuItem)
            {
                return;
            }

            this.set("connectionParams.ipURL", res);

            const isSSL = selectedConnectionData.isSSL;
            const brKey = selectedConnectionData.brandingKey;
            const regLink = selectedConnectionData.registrationLink;
            const fogotLink = selectedConnectionData.forgotURL;

            this.set('connectionParams.connectionName', selectedConnectionData.Name);
            this.set('connectionParams.ServerAdress', serverURL);
            this.set('connectionParams.isSSL', isSSL);
            this.set('connectionParams.forgotURL', fogotLink);
            this.set('connectionParams.registrationLink', regLink);
            this.set('connectionParams.brandingKey', brKey);
            this.set({
                ICFL: fogotLink != -1, ICFRPL: regLink != -1 && regLink,
                AllowCustomerNo: selectedConnectionData.AllowCustomerNo,
                needCustomerNo: false,
                placeholderCustomerNo: Resources.getResource('screen.LoginScreen.CustomerNo')
            })
            this.set('allowExplore', !!selectedConnectionData.exploreTheAppUser);
            this.set('connectionParams.exploreTheAppUser', selectedConnectionData.exploreTheAppUser);
            this.set('connectionParams.exploreTheAppPassword', selectedConnectionData.exploreTheAppPassword);
            this.set('connectionParams.exploreTheAppLink', selectedConnectionData.exploreTheAppLink);
            this.set('connectionParams.allowPreviewPassword', selectedConnectionData.allowPasswordPreview);
            this.set('thirdPartyProvider', AuthenticationFactory.create(selectedConnectionData.BrandingExternalAuthenticationType, selectedConnectionData.BrandingExternalAuthenticationUrl, selectedConnectionData.BrandingExternalAuthenticationWebClientId));

            this.setCredentialsByConnName(selectedConnectionData.Name)

            this.setChatLinkIfNeed()    // #113542

            this.setTopForSomeControls()

            const mainWindow = MainWindowManager.MainWindow;
            const isInvesting = mainWindow.getType() == 'Investing';  // NewNetTrader or Investing (no Header logo for Active trading Mobile Web)
            const header = isInvesting ? selectedConnectionData.BrandingInvestingWebHeader : selectedConnectionData.BrandingWebHeader
            mainWindow.setBrandingWebHeader(header, brKey);

            BrandingUtils.SetWebPageTitle(selectedConnectionData.BrandingWebName);
            const faviconPropName = isInvesting ? 'BrandingInvestingFavicon' : 'BrandingWebFavicon';
            BrandingUtils.SetWebFavicon(selectedConnectionData[faviconPropName], faviconPropName, brKey);

            this.SetSplashScreen(selectedConnectionData);
            const bannerPropName = isInvesting ? 'BrandingInvestingLoginBanner' : 'BrandingWebLoginBanner';
            this.SetWebLoginBanner(selectedConnectionData[bannerPropName], bannerPropName, brKey);
            this.SetBrandingLocales(selectedConnectionData.Name);

            if (isInvesting)
                this.SetInvestingCustomScheme(selectedConnectionData.Name);
        });
    }
};

TerceraLoginScreen.prototype.SetInvestingCustomScheme = function (connData)
{
    // implemented only for InvestingLoginScreen
}

TerceraLoginScreen.prototype.SetSplashScreen = function (connData)
{
    if (connData)
        BrandingUtils.SetWebSplashScreen(connData.BrandingWebSplashScreen, connData.brandingKey);
}

TerceraLoginScreen.prototype.SetBrandingLocales = function (conName)
{
    return ApplicationLoader(conName);
}

TerceraLoginScreen.prototype.SetWebLoginBanner = function (relativeImgPath, propName, brandingKey)
{
    this.set({
        'connectionParams.webLoginBanner': relativeImgPath,        // <- to not replace branding banner with default banner in onThemeChanged
        'pictureUrl': BrandingUtils.GetWebLoginBanner(relativeImgPath, this.get('pictureUrl'), propName, brandingKey)   // caching in BrandingUtils
    });
}

TerceraLoginScreen.prototype.onConnectionParamsLoginChanged = function (newVal)
{
    if (this.get('needLogin') && newVal)
        this.set('needLogin', false);
};

TerceraLoginScreen.prototype.onConnectionParamsPassChanged = function (newVal)
{
    if (this.get('needPassword') && newVal)
        this.set('needPassword', false);

    this.set('savePasswordCheckedFromConnectionSettings', false);
};

TerceraLoginScreen.prototype.onConnectionParamsCustomerNoChanged = function (newVal)
{
    if (this.get('needCustomerNo') && newVal)
        this.set('needCustomerNo', false);
};

TerceraLoginScreen.prototype.onConnectionParamsCustomerIpURL = function (url)
{
    return new Promise((resolve, reject) =>
    {
        if (!url)
            return resolve(null);

        let xhr = new XMLHttpRequest()
        xhr.open('GET', url, true)
        xhr.onreadystatechange = function ()
        {
            if (this.readyState !== 4)
                return;

            if (this.status !== 200)
                return resolve(null);

            resolve(this.responseText.trim());
        }
        xhr.send()
    })
};

TerceraLoginScreen.prototype.PlaceholderLoginChanged = function (newVal)
{
    if (!newVal)
        this.set('placeholderLogin', Resources.getResource('screen.LoginScreen.LoginIDLabel'))
    else
        this.set('placeholderLogin', Resources.getResource('screen.LoginScreen.Error.loginIdIsEmpty'))
};

TerceraLoginScreen.prototype.PlaceholderPasswordChanged = function (newVal)
{
    if (!newVal)
        this.set('placeholderPassword', Resources.getResource('screen.LoginScreen.PasswordLabel'))
    else
        this.set('placeholderPassword', Resources.getResource('screen.LoginScreen.Error.passwordIdIsEmpty'))
};

TerceraLoginScreen.prototype.PlaceholderCustomerNoChanged = function (newVal)
{
    if (!newVal)
        this.set('placeholderCustomerNo', Resources.getResource('screen.LoginScreen.CustomerNo'))
    else
        this.set('placeholderCustomerNo', Resources.getResource('screen.LoginScreen.Error.CustomerNoIsEmpty'))
};

TerceraLoginScreen.prototype.oncomplete = function ()
{
    Connection.onConnectStateChange.Subscribe(this.connectionStateChange, this)
    var netTrForm = this.get('parentContainerControl')
    netTrForm.onNetTraderModeChange.Subscribe(function (newState)
    {
        if (newState === NewNetTraderMode.WorkMode)
            this.set({ textErrorLabel: ' ' });

        BrandingUtils.NewNetTraderModeChanged(newState === NewNetTraderMode.WorkMode)
    }, this)
    this.Controls.loginBtn.on(TerceraButtonEvents.onClick, this.startLogin.bind(this))
    // this.Controls.closeLoginScr.on(TerceraButtonEvents.onClick, this.closeLoginScrClick.bind(this))

    this.Controls.tbLogin.on('keyDown', this.onLoginKeyDown.bind(this))
    this.Controls.tbPwd.on('keyDown', this.onPasswordKeyDown.bind(this))

    this.Controls.tbLogin.getInputElement().addEventListener('input', this.trimStringIfWasPaste.bind(this));
    this.Controls.tbPwd.getInputElement().addEventListener('input', this.trimStringIfWasPaste.bind(this));

    // TODO. Ugly. Refactor.
    this.set('connectionInfo', ConntionInfo.ConnectionInfoArray)


    this.set({ inputMode: ApplicationInfo.LoginScreenType === 0 });
    let parsedURL = BrowserUtils.getUrlVars(location.href);
    let tokenFromURL = (parsedURL && parsedURL.hasOwnProperty("token")) ? parsedURL.token : null
    let tokenToSend = ApplicationInfo.LoginToken || tokenFromURL
    const restoredConnection = AuthenticationStateStorage.restoreConnection(window.location.search);
    if (isValidString(restoredConnection))
    {
        const connectionInfoMenuItems = this.get('connectionInfoMenuItems');
        for (let i = 0; i < connectionInfoMenuItems.length; i++)
        {
            if (connectionInfoMenuItems[i].text === restoredConnection)
            {
                this.set('selectedConnectionInfoMenuItem', connectionInfoMenuItems[i]).then(() =>
                {
                    this.processThirdPartyLogin();
                }).bind(this);
                break;
            }
        }
    } else if (tokenToSend && ApplicationInfo.ExternalResourse !== -1)
    {
        this.set({ inputMode: false });
        this.onConnectionParamsCustomerIpURL(this.get("selectedConnectionInfoMenuItem").value.ipURL).then((res) =>
        {
            this.set("connectionParams.ipURL", res)
            this.StartConnect(null, tokenToSend)
        });
    }

    this.portfolioAutoLogin()
};

TerceraLoginScreen.prototype.onLoginKeyDown = function (e)
{
    if (!this.get('inputMode'))
        return

    const keyDownEvent = e.original;
    if (keyDownEvent.ctrlKey && keyDownEvent.keyCode === KeyCode.V) {
        this.wasPaste = true;
    } else if (keyDownEvent.keyCode === KeyCode.ENTER)
    {
        if (this.get('savePasswordCheckedFromConnectionSettings')) {
            this.startLogin();
            return;
        }
        // TODO. Make it work. this.Controls.tbPwd.setFocus();
        // So instead of using the method above:
        this.Controls.tbPwd.set('focused', true);
    }
};

TerceraLoginScreen.prototype.onPasswordKeyDown = function (e)
{
    if (!this.get('inputMode'))
        return

    const keyDownEvent = e.original;
    if (keyDownEvent.ctrlKey && keyDownEvent.keyCode === KeyCode.V) {
        this.wasPaste = true;
    } else if (keyDownEvent.keyCode === KeyCode.ENTER) {
        this.startLogin();
    }
};

TerceraLoginScreen.prototype.startLogin = function (context, verificationPassword, isExploreMode)
{
    ApplicationInfo.isExploreMode = !!isExploreMode;
    ApplicationInfo.exploreTheAppLink = ApplicationInfo.isExploreMode ? this.get('connectionParams.exploreTheAppLink') : '';

    let noLogin = !this.get('connectionParams.login') && !ApplicationInfo.isExploreMode;
    let noPass = !this.get('connectionParams.pass') && !ApplicationInfo.isExploreMode;
    let noCustomerNo = !this.get('connectionParams.customerNo') && !ApplicationInfo.isExploreMode;

    this.set({
        needLogin: noLogin,
        needPassword: noPass,
        needCustomerNo: noCustomerNo
    });

    let AllowCustomerNo = this.get('AllowCustomerNo');

    if (noLogin || noPass || (noCustomerNo && AllowCustomerNo))
        return;

    if (!AllowCustomerNo)
        this.set('connectionParams.customerNo', "");

    var state = Connection.getState();
    switch (state)
    {
        case ConnectionStates.CONNECTED:
            Connection.disconnect();
            this.set({ textErrorLabel: 'Disconnect...', isDisconnect: true });
            break;
        case ConnectionStates.DISCONNECTED:
        case ConnectionStates.CONNECTION_LOST:
            this.StartConnect(verificationPassword);
            break;
        case ConnectionStates.CONNECTING:
            break;
        default:
            break;
    }
};

TerceraLoginScreen.prototype.StartConnect = function (verificationPassword, token)
{
    var connectData = this.get('connectionParams');
    if (verificationPassword && verificationPassword.getType === undefined)
        connectData.verificationPassword = verificationPassword;
    else
        connectData.verificationPassword = "";
    this.set({ textErrorLabel: Resources.getResource("screen.LoginScreen.Connecting"), isDisconnect: false });
    connectData.ServerAdressesArray = connectData.ServerAdress.split(",");
    for (let i = 0, len = connectData.ServerAdressesArray.length; i < len; i++)
        if (connectData.ServerAdressesArray[i])
            connectData.ServerAdressesArray[i] = connectData.ServerAdressesArray[i].trim();
    connectData.reconnect = false;
    if (ApplicationInfo.BuildVersionData)
        connectData.build_date_day = ApplicationInfo.BuildVersionData.date_day;


    if (token)
    {
        const thirdPartyProvider = this.get('thirdPartyProvider');
        if (!isNullOrUndefined(thirdPartyProvider))
        {
            connectData.login = "";
            connectData.TokenID = token;
            connectData.ExternalResourse = thirdPartyProvider.authenticationType;
        } else if (ApplicationInfo.ExternalResourse !== -1)
        {
            connectData.login = "";         // Do not use user from local storage https://tp.traderevolution.com/entity/89399
            connectData.TokenID = token;
            connectData.ExternalResourse = ApplicationInfo.ExternalResourse; //ExternalResource.HUB;//null;
        }
    }

    if (!ApplicationInfo.myportfolio)
        connectData.accessToken = "";

    if (ApplicationInfo.isExploreMode) 
    {
        connectData.login = connectData.exploreTheAppUser;
        connectData.pass = connectData.exploreTheAppPassword;
    }

    Connection.connect(connectData);
}

TerceraLoginScreen.prototype.connectionStateChange = function ()
{
    var state = Connection.getState();
    var lastConnectionAttemptResponse = Connection.lastConnectionAttemptResponse;
    var btnText;
    switch (state)
    {
        case ConnectionStates.CONNECTED:
            btnText = Resources.getResource('screen.LoginScreen.LoginButtonText');
            this.set({ textButton: btnText, textErrorLabel: Resources.getResource('screen.LoginScreen.LoadingUserData') });
            this.handleCredentialsAfterConnect();
            break;
        case ConnectionStates.DISCONNECTED:
            this.set('reconnectConnectionParams', null);
            var savedPass = this.get('connectionParams.pass');

            const savePasswordCh = this.get('savePasswordChecked');
            if (!savePasswordCh) {
                this.set('connectionParams.pass', '');
            }

            btnText = Resources.getResource('screen.LoginScreen.LoginButtonText');
            this.set({ textButton: btnText, isDisconnect: true });
            var err = Connection.getLocalizedConnectionErrorString(this.get('connectionParams.login'));
            if (err)
                this.set('textErrorLabel', err);
            else {
                this.set('textErrorLabel', "");
                this.Controls.tbPwd.set('passwordHided', true);
            }

            this.set('savePasswordCheckedFromConnectionSettings', savePasswordCh && !err); // to prevent the password preview from being hidden when "Save Password" is checked but the entered password is incorrect

            var lastResp = Connection.lastConnectionAttemptResponse;
            if (lastResp.connectReason === ConectionResultEnum.NeedActivation || lastResp.connectReason === ConectionResultEnum.AdditionalVerification)
            {
                var okCallBack = function (verificPass) { this.startLogin(undefined, verificPass) }.bind(this);
                var cancelCallBack = function () { this.set('textErrorLabel', ''); this.set('isDisconnect', true); this.set('connectionParams.pass', ''); }.bind(this);
                this.set('connectionParams.pass', savedPass);
                this.set('isDisconnect', false);
                AdditionalVerificationScreen.Show(lastResp, okCallBack, cancelCallBack, this.get("connectionParams"));
            }
            if (lastResp.connectReason === ConectionResultEnum.NeedChangePassword)
                this.set('textErrorLabel', Resources.getResource("screen.login.change_pass"));

            if (lastResp.connectReason === ConectionResultEnum.InvalidBrandingKey)
                this.set('textErrorLabel', Resources.getResource("screen.login.bad_branding_key"));

            break;
        case ConnectionStates.CONNECTION_LOST:
            WorkSpaceManager.needRestoreWS = true;
            break;
        case ConnectionStates.CONNECTING:
            break;

        case ConnectionStates.HALFCONNECTED:
            var lastResp = Connection.lastConnectionAttemptResponse;
            let lastConnectionParams = this.get('connectionParams');

            TerceraChangePasswordScreen.show(lastResp.UserID, lastConnectionParams.pass, function ()
            {
                Connection.disconnect();
            }.bind(this));
            break;

        default:
            btnText = 'Default';
            this.set({ textButton: btnText, textErrorLabel: '' });
            break;
    }
};

TerceraLoginScreen.prototype.handleCredentialsAfterConnect = async function () {
    var connectionParams = this.get('connectionParams');
    if (!this.get('reconnectConnectionParams')) {
        this.set('reconnectConnectionParams', {
            login: connectionParams.login,
            pass: connectionParams.pass,
            allowPasswordPreview: connectionParams.allowPreviewPassword,
            connectionName: connectionParams.connectionName,
            customerNo: connectionParams.customerNo
        });
    }

    const key = ConnectionStates.CONNECTED + connectionParams.login;
    const encryptedPassword = this.get('savePasswordChecked') ? await encryptAndDeriveKey(connectionParams.pass, key) : null;
    LocalStorage.setConnectionSettings(connectionParams.connectionName,
        connectionParams.login,
        encryptedPassword,
        connectionParams.customerNo);
    LocalStorage.setLastConnectionName(connectionParams.connectionName);
};

TerceraLoginScreen.prototype.loadCredentials = function ()
{
    LocalStorage.loadConnectionSettings();
};

TerceraLoginScreen.prototype.setCredentialsByConnName = async function (name) 
{
    const [u, p, cN] = LocalStorage.getConnectionSettings(name);

    const allowSavePassword = this.get('selectedConnectionInfoMenuItem.value.allowSavePassword');
    const savePasswordChecked = p != null && allowSavePassword;
    const key = ConnectionStates.CONNECTED + u;
    const decryptedPassword = savePasswordChecked ? await decryptWithDerivedKey(p, key) : '';

    this.Controls.tbPwd?.set('passwordHided', true);
    this.set({
        'connectionParams.login': u,
        'connectionParams.customerNo': cN,
        'connectionParams.pass': decryptedPassword,
        savePasswordChecked
    }).then(() => {
        this.set('savePasswordCheckedFromConnectionSettings', decryptedPassword != '')
    });
}

// TerceraLoginScreen.prototype.closeLoginScrClick = function ()
// {
//     var netTrForm = this.get('parentContainerControl');
//     netTrForm.set({ netTraderState: NewNetTrader.Mode.WorkMode });
// };

TerceraLoginScreen.prototype.onRecoverClicked = function ()
{
    const connectionParams = this.get('connectionParams');
    let forgotURL = connectionParams.forgotURL;

    if (forgotURL === '')
    {
        var terceraRecoverScreen = new TerceraRecoverScreen();
        terceraRecoverScreen.setBounds(100, 100, 240, 370);
        terceraRecoverScreen.set({
            connectionName: connectionParams.connectionName,
            valueLogin: connectionParams.login, 
            allowPasswordPreview: connectionParams.allowPreviewPassword
        });
        MainWindowManager.MainWindow.Controls.loginWindowsContainer.addControl(terceraRecoverScreen);
    }
    else
        window.open(forgotURL)          //#83944
};
TerceraLoginScreen.prototype.onExploreClicked = function ()
{
    this.startLogin(undefined, undefined, true);
}

TerceraLoginScreen.prototype.onThirdPartyLoginBtnClicked = function ()
{
    const thirdPartyProvider = this.get('thirdPartyProvider');
    AuthenticationStateStorage.saveConnection(thirdPartyProvider.uuid, this.get('connectionParams.connectionName'));
    window.location.href = thirdPartyProvider.authenticationAddress;
}

TerceraLoginScreen.prototype.processThirdPartyLogin = function ()
{
    const service = this.get('thirdPartyProvider');
    const search = window.location.search;
    if (isNullOrUndefined(service) || !isValidString(search))
    {
        return;
    }
    const response = service.processResponse(search);
    switch (response.ResponseType)
    {
        case AuthenticationResponseType.Success:
            this.StartConnect(undefined, response.Result);
            break;
        case AuthenticationResponseType.Error:
            this.set('textErrorLabel', response.Result);
            break;
    }
}

TerceraLoginScreen.prototype.setChatLinkIfNeed = function ()    // #113542 will return true if chat link was set
{
    let chatLink = !Resources.isHidden('screen.LoginScreen.ChatLink.visible') ?
        Resources.getResource('screen.LoginScreen.ChatLink.url') : null

    this.set('connectionParams.chatLink', chatLink)
    return !!chatLink
}

TerceraLoginScreen.prototype.setTopForSomeControls = function ()
{
    let data = this.get(),
        cNI = data.customerNoIndent,
        inputMode = data.inputMode,
        chatLinkVisible = data.connectionParams ? !!data.connectionParams.chatLink : false,
        forgotVisible = data.allowPasswordRecovery && inputMode && data.ICFL,
        exploreVisible = data.allowExplore && inputMode,
        regLinkVisible = data.createDemoAccountAllowed && data.ICFRPL

    let loginTBTop = cNI + 172,                     // login text box top
        passTBTop = cNI + 219,                      // password text box top
        langSelTop = cNI + 266,                     // language (combobox) selector top
        forgotLinkTop = 395,                        // forgot password link(btn) top
        exploreLinkTop = 395,                       // explore link(top) top
        regLinkTop = 397,                           // demo registration link top
        chatLinkTop = chatLinkVisible ? 395 : 0,    // live chat link top #113542
        loginBtnTop = regLinkVisible ? 334 : 354,   // log in button's top
        errorTextTop = inputMode ? 413 : 128        // text area of connection status/errors top 

    if (chatLinkVisible) 
    {
        if (forgotVisible)
        {
            forgotLinkTop -= 10
            chatLinkTop += 5
            regLinkTop -= 5
        }
        else
            regLinkTop -= 10
    }

    if (regLinkVisible)
    {
        forgotLinkTop -= 20
        if (chatLinkVisible)
            regLinkTop -= 10
    }

    if (exploreVisible)
    {
        if (forgotVisible)
            forgotLinkTop -= 10;
        exploreLinkTop = forgotVisible ? forgotLinkTop + 17 : forgotLinkTop;
        regLinkTop = regLinkVisible ? exploreLinkTop + 17 : regLinkTop;
    }

    this.set({
        loginTBTop: loginTBTop,
        passTBTop: passTBTop,
        languageCBTop: langSelTop,
        recoverLinkTop: forgotLinkTop,
        exploreLinkTop: exploreLinkTop,
        chatLinkTop: chatLinkVisible ? chatLinkTop : 0,
        createDemoAccTop: regLinkTop,
        loginBtnTop: loginBtnTop,
        errorTextTop: errorTextTop
    })
}

TerceraLoginScreen.prototype.localize = function ()
{
    this.set({
        textCreateDemoAccount: Resources.getResource('screen.LoginScreen.RegistrationLinkLabel'),
        textSavePassword: Resources.getResource('screen.LoginScreen.SavePassCheckBox'),
        textRecover: Resources.getResource('screen.LoginScreen.ForgotLinkLabel'),
        textExplore: Resources.getResource('screen.LoginScreen.ExploreLinkLabel'),
        textButton: Resources.getResource('screen.LoginScreen.LoginButtonText'),

        chatLinkText: Resources.getResource('screen.LoginScreen.ChatLink.label'),

        tooltipLogin: Resources.getResource('screen.LoginScreen.LoginIDTextBox.ToolTip'),
        tooltipPassword: Resources.getResource('screen.LoginScreen.PasswordTextBox.ToolTip'),
        tooltipSavePassword: Resources.getResource('screen.LoginScreen.SavePassCheckBox.ToolTip'),
        tooltipRecover: Resources.getResource('screen.LoginScreen.ForgotLinkLabel.ToolTip'),
        tooltipExplore: Resources.getResource('screen.LoginScreen.ExploreLinkLabel.ToolTip'),
        tooltipLoginBtn: Resources.getResource('screen.LoginScreen.LoginButtonTooltip'),
        tooltipCreateDemoAccount: Resources.getResource('screen.LoginScreen.RegistrationLinkLabel.ToolTip'),
        tooltipCustomerNo: Resources.getResource('screen.LoginScreen.CustomerNo'),

        placeholderLogin: Resources.getResource('screen.LoginScreen.LoginIDLabel'),
        placeholderPassword: Resources.getResource('screen.LoginScreen.PasswordLabel'),
        savePassText: Resources.getResource('screen.LoginScreen.SavePassCheckBox'),
        placeholderCustomerNo: Resources.getResource('screen.LoginScreen.CustomerNo'),

        langCBLabel: Resources.getResource("screen.LoginScreen.Settings.LanguageLabel")
    });
};

TerceraLoginScreen.prototype.trimStringIfWasPaste = function (event)
{
    const input = event.target;
    const insertFromPaste = this.wasPaste || event.inputType === 'insertFromPaste';
    input.value = insertFromPaste ? input.value.trim() : input.value;
    this.wasPaste = false;
};

TerceraLoginScreen.prototype.onPasswordFocused  = function (focused)
{
    if (focused && this.get('savePasswordCheckedFromConnectionSettings')) {
        this.set('connectionParams.pass', '');
        this.set('savePasswordCheckedFromConnectionSettings', false);
    }
};

TerceraLoginScreen.prototype.onThemeChanged = function ()
{
    let connectionParams = this.get('connectionParams');

    if (!connectionParams.webLoginBanner)
        this.set('pictureUrl', ThemeManager.getImageFullUrlNew('g_login/login_branding.png'));
};

TerceraLoginScreen.prototype.portfolioAutoLogin = function ()
{
    if (!ApplicationInfo.myportfolio)
        return

    let token = LocalStorage.getTokenForPortfolio();
    if (token)
    {
        let connectionParams = this.get('connectionParams')
        connectionParams.accessToken = token

        this.set('connectionParams', connectionParams)
        this.set('visible', false)

        LocalStorage.removeTokenForPortfolio();

        this.StartConnect()
    }
}

function SubmitAsPost (token, newURL)
{
    if (!newURL)
        return;

    var postForm = document.createElement('form');
    postForm.action = newURL + "/token";
    postForm.method = 'post';
    var bodyTag = document.getElementsByTagName('body')[0];

    var inputToken = document.createElement('input');
    inputToken.setAttribute("name", "token")
    inputToken.setAttribute("type", "text")
    inputToken.setAttribute("value", token)

    postForm.appendChild(inputToken);
    bodyTag.appendChild(postForm);

    postForm.submit();
    bodyTag.removeChild(postForm);
}

function SubmitAsPostOnNewPage (token, newURL)
{
    if (!newURL)
        return;

    let myWindow = window.open()

    myWindow.SubmitAsPost = function (token)
    {
        var postForm = this.document.createElement('form');
        postForm.action = newURL + "/token";
        postForm.method = 'post';
        var bodyTag = this.document.getElementsByTagName('body')[0];

        var inputToken = this.document.createElement('input');
        inputToken.setAttribute("name", "token")
        inputToken.setAttribute("type", "text")
        inputToken.setAttribute("value", token)

        postForm.appendChild(inputToken);
        bodyTag.appendChild(postForm);

        postForm.submit();
        bodyTag.removeChild(postForm);
    }
    myWindow.SubmitAsPost(token)
}