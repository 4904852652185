// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Connection } from "../../Commons/Connection.ts";
import { Resources } from "../../Commons/properties/Resources.ts";
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";
import { TerceraBaseWindowTemplate, TerceraRecoverScreenTemplate } from "../../templates.js";
import { TerceraButton } from "../elements/TerceraButton.ts";
import { TerceraMessageBox } from "./TerceraMessageBox.js";
import { TerceraWindowBase } from "./TerceraWindowBase.js";
import { BrowserUtils } from "../../Commons/UtilsClasses/BrowserUtils.ts";
import { ChangePasswordStatusEnum } from "./TerceraChangePasswordScreen.js";
import { TerceraButtonStyle } from "../../Utils/Enums/ButtonEnums.ts";
import { KeyCode } from "../../Commons/KeyEventProcessor.ts";

export let TerceraRecoverScreen = TerceraWindowBase.extend({
    template: TerceraBaseWindowTemplate,
    data: function ()
    {
        return {
            movable: false,
            showFooter: false,
            resizable: false,
            alwaysMiddle: true,
            focused: true,

            btnRecoverStyle: TerceraButtonStyle.Blue,
            btnCancelStyle: TerceraButtonStyle.Standard,

            textLoginLabel: "Login",
            textEmailLabel: "Email",
            textKeyLabel: "Key",
            textNewPasswordLabel: "New Password",
            textConfirmLabel: "Confirm",
            textIHaveKey: "I have key",
            recoverText: "Recovery",
            cancelText: "Cancel",
            header: "Password recovery",

            allowPasswordPreview: false,

            valueLogin: "",
            valueEmail: "",
            valueKey: "",
            valueNewPassword: "",
            valueConfirm: "",

            iHaveKey: false,

            valueEmailHaveError: true,

            connectionName: ''
        };
    },
    wasPaste: false,
    partials: { bodyPartial: TerceraRecoverScreenTemplate }
});

TerceraRecoverScreen.prototype.oninit = function ()
{
    TerceraWindowBase.prototype.oninit.apply(this);

    this.on('cancelClick', this.close);
    this.on('recoverClick', this.recover);
    this.observe('valueEmail', function (newValue)
    {
        this.set({ valueEmailHaveError: !BrowserUtils.isEmail(newValue) });
    });
    this.observe('iHaveKey', function (newValue)
    {
        this.set({ recoverText: newValue ? "Change" : "Recovery", });
    });

    this.observe('visible', function (visible)
    {
        if (visible) this.localize();
    });
    this.localize();
};

TerceraRecoverScreen.prototype.oncomplete = function ()
{
    TerceraWindowBase.prototype.oncomplete.apply(this);
    const loginTextBox = this.Controls.loginTextBox;
    loginTextBox.on('keyDown', this.onKeyDown.bind(this));
    this.trimOnPasteAddEventListener(loginTextBox.getInputElement());

    const keyTextBox = this.Controls.keyTextBox;
    keyTextBox.on('keyDown', this.onKeyDown.bind(this));
    this.trimOnPasteAddEventListener(keyTextBox.getInputElement());
}

TerceraRecoverScreen.prototype.onKeyDown = function (context)
{
    const ev = context.event;
    if (ev.ctrlKey === true && ev.keyCode === KeyCode.V) {
        this.wasPaste = true;
    }
}

TerceraRecoverScreen.prototype.trimOnPasteAddEventListener = function (input /* : HTMLInputElement */)
{
    if (input == null) { return; }
    input.addEventListener('input', () => {
        if (this.wasPaste) { input.value = input.value.trim(); }
        this.wasPaste = false;
    });
}

TerceraRecoverScreen.prototype.recover = function ()
{
    // var pwd = this.get('valueNewPassword');
    // if (pwd && pwd.indexOf('&') > -1)
    // {
    //     TerceraMessageBox.Show(
    //         this.get('header'),
    //         Resources.getResource("screen.RecoveryPassForm.New password contains prohibited symbols"),
    //         TerceraMessageBox.msgType.Warning,
    //         null, null, false, true);

    //     this.set({ valueNewPassword: '', valueConfirm: '' });
    //     return;
    // }

    // var iHaveKey = this.get('iHaveKey');
    // if (!iHaveKey)
    //     this.RecoverPass();
    // else
    //     this.SendCodePass();

    this.RecoverPassProcess();
};

TerceraRecoverScreen.prototype.RecoverPassProcess = function ()
{
    let result = "Recovery failed";
    let currentConnection = MainWindowManager.MainWindow.Controls.loginScreen.get("connectionParams");
    var iHaveKey = this.get('iHaveKey');
    let request = null;
    if (!iHaveKey)
    {
        let login = this.get('valueLogin');
        let email = this.get('valueEmail');

        request = {}
        request.msgType = 'SendRequestRecoveryPassword';
        request.dataForMsg = { login: login, email: email }
    }
    else
    {
        let login = this.get('valueLogin');
        let newPass = this.get('valueNewPassword');
        let key = this.get('valueKey');

        request = {}
        request.msgType = 'SendPasswordRecoveryChangeRequest'
        request.dataForMsg = { login: login, newPassword: newPass, key: key, accessToken: currentConnection.accessToken }
    }
    let connectServers = [];
    let splitedConnections = currentConnection.ServerAdress.split(",");
    for (let i = 0, len = splitedConnections.length; i < len; i++)
        if (splitedConnections[i])
            connectServers.push(splitedConnections[i].trim());

    this.SendMessagePromise(request, connectServers, currentConnection, 0).then(this.SendMessageFinish.bind(this))
};

TerceraRecoverScreen.prototype.SendMessageFinish = function (result)
{
    if (result === null || result === "Bad Arguments")
    {
        TerceraMessageBox.Show(this.get('header'), Resources.getResource("Bad request arguments"), TerceraMessageBox.msgType.Warning, null, null, false, true);
        return;
    }

    // new Logic
    let status = result.status;
    switch (status)
    {
        case ChangePasswordStatusEnum.STATUS_ACCEPTED:
            if (this.get('iHaveKey'))
            {
                TerceraMessageBox.Show(this.get('header'), Resources.getResource("screen.changepass.changed.login"), TerceraMessageBox.msgType.Warning, null, null, false, true);
                this.close();
            }
            else
            {
                TerceraMessageBox.Show(this.get('header'), Resources.getResource("screen.RecoveryPassForm.Your request for password recovering has sent successfully. Please check your email!"), TerceraMessageBox.msgType.Warning, null, null, false, true);
                this.set('iHaveKey', true);
            }
            return;

        case ChangePasswordStatusEnum.STATUS_STRONG_PAS_LOW:
            this.showMSG(Resources.getResource("system.ChangePassLow").replace("{0}", result.minLength), () =>
                this.set({ valueNewPassword: "", valueConfirm: "" }));
            return;

        case ChangePasswordStatusEnum.STATUS_STRONG_PAS_BASE:
            this.showMSG(Resources.getResource("system.ChangePassBase").replace("{0}", result.minLength), () =>
                this.set({ valueNewPassword: "", valueConfirm: "" }));
            return;

        case ChangePasswordStatusEnum.STATUS_STRONG_PAS_HIGH:
            this.showMSG(Resources.getResource("system.ChangePassHigh").replace("{0}", result.minLength), () =>
                this.set({ valueNewPassword: "", valueConfirm: "" }));
            return;

        case ChangePasswordStatusEnum.STATUS_LOCKED_AFTER_BRUT_FORCE:
            this.showMSG(Resources.getResource("screen.changepass.lockedAfterBrut"), () =>
                this.set({ valueEmail: "", valueLogin: "", valueKey: "", valueNewPassword: "", valueConfirm: "" }));
            return;

        case ChangePasswordStatusEnum.STATUS_WRONG_OLD_OR_NEW_PASSWORD:
            this.showMSG(Resources.getResource("screen.changepass.wrongOldOrNewPassword"), () =>
                this.set({ valueNewPassword: "", valueConfirm: "" }));
            return;

        case ChangePasswordStatusEnum.STATUS_WRONG_KEY:
            this.showMSG(Resources.getResource("screen.changepass.wrongKey"), () =>
                this.set({ valueKey: "", valueNewPassword: "", valueConfirm: "" }));
            return;

        case ChangePasswordStatusEnum.STATUS_PWD_MUST_DE_DIFFERENT:
            this.showMSG(Resources.getResource("screen.changepass.pwdMustBeDifferent"), () =>
                this.set({ valueNewPassword: "", valueConfirm: "" }));
            return;

        case ChangePasswordStatusEnum.STATUS_ERROR_EMAIL:
            this.showMSG(Resources.getResource("screen.changepass.errorEmail"), () =>
                this.set({ valueEmail: "" }));
            return;

        case ChangePasswordStatusEnum.STATUS_WRONG_LOGIN:
            this.showMSG(Resources.getResource("screen.changepass.wrongLogin"), () =>
                this.set({ valueEmail: "", valueLogin: "" }));
            return;

        case ChangePasswordStatusEnum.STATUS_WRONG_MAX_LENGTH:
            this.showMSG(Resources.getResource("system.TooLongPass").replace("{0}", result.MaxLength), () =>
                this.set({ valueNewPassword: "", valueConfirm: "" }));
            return;

        case ChangePasswordStatusEnum.STATUS_PWD_IS_TOO_EASY:
            this.showMSG(Resources.getResource("screen.changepass.tooeasy"), () =>
                this.set({ valueNewPassword: "", valueConfirm: "" }));
            return;
    }

    //oldLogic
    this.OldLogicNotification(result.BusinessRejectCode, result.errText)
};

TerceraRecoverScreen.prototype.showMSG = function (body, closeHandler)
{
    if (!closeHandler)
        closeHandler = null;
    TerceraMessageBox.Show(this.get('header'), body, TerceraMessageBox.msgType.Warning, closeHandler, closeHandler, false, true);
}

TerceraRecoverScreen.prototype.OldLogicNotification = function (errCode, errText)
{
    switch (errCode)
    {
        case 381:
            TerceraMessageBox.Show(this.get('header'), Resources.getResource("screen.RecoveryPassForm.invalid_login_or_email"), TerceraMessageBox.msgType.Warning, null, null, false, true);
            return;
        case 384:
            TerceraMessageBox.Show(this.get('header'), Resources.getResource("screen.RecoveryPassForm.invalid_login"), TerceraMessageBox.msgType.Warning, null, null, false, true);
            return;
        case 31:
            TerceraMessageBox.Show(this.get('header'), Resources.getResource("reports.New password must differ from several previous passwords."), TerceraMessageBox.msgType.Warning, null, null, false, true);
            return;

        default:
            if (Resources.IsResourcePresentEN("BusinessRejectMessage." + errCode))
                TerceraMessageBox.Show(this.get('header'), Resources.getResource("BusinessRejectMessage." + errCode), TerceraMessageBox.msgType.Warning, null, null, false, true);
            else
                TerceraMessageBox.Show(this.get('header'), errText /*+ "\n" + errCode*/, TerceraMessageBox.msgType.Warning, null, null, false, true);
            return
    }
}

TerceraRecoverScreen.prototype.SendMessagePromise = function (request, connectServers, currentConnection, index)
{
    if (connectServers.length < index + 1)
        return Promise.resolve("Bad Arguments");

    return Connection.SendMessageToVendor(connectServers[index], currentConnection.isSSL, request.msgType, request.dataForMsg)
        .then((responce) =>
        {
            if (responce === null)
                return this.SendMessagePromise(request, connectServers, currentConnection, ++index);

            return responce;
        })
};

TerceraRecoverScreen.prototype.localize = function ()
{
    this.set({
        textLoginLabel: Resources.getResource('screen.RecoveryPassForm.Login'),
        textEmailLabel: Resources.getResource('screen.RecoveryPassForm.Email'),
        textKeyLabel: Resources.getResource('screen.RecoveryPassForm.Key'),
        textNewPasswordLabel: Resources.getResource('screen.RecoveryPassForm.New password'),
        textConfirmLabel: Resources.getResource('screen.RecoveryPassForm.Confirm'),
        textIHaveKey: Resources.getResource('screen.RecoveryPassForm.HaveCode'),
        recoverText: Resources.getResource('screen.RecoveryPassForm.Send'),
        cancelText: Resources.getResource('screen.RecoveryPassForm.Cancel'),
        header: Resources.getResource('screen.RecoveryPassForm.Title'),

    });
};