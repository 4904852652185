// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectReportTypeMessage extends DirectMessageBaseClass {
    public name: string | null = null;
    public id = 0;
    public accessType = 0;
    public parameters = [];

    constructor () {
        super(Message.CODE_REPORT_TYPE_MESSAGE);
    }
}
