// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
'use strict';
import { PFixFieldSet } from '../Fields/FieldsImport';
import { FieldsFactory } from '../Factory/FieldsFactory';

export class OrderIdGroup extends PFixFieldSet {
    constructor (pfixFieldSet?: PFixFieldSet) {
        super(FieldsFactory.ORDER_ID_GROUP, null, pfixFieldSet);
    }

    public setOrderId (orderId: bigint): void {
        this.SetField(FieldsFactory.FIELD_ORDER_ID, orderId);
    }
}
