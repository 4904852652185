// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
export enum TradingOrderStatus {
    None,
    Placing,
    Error,
    CancelledByUser,
    IllegalParameters,
    Opened,
    Filled
}
