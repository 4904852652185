// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Connection } from "../../Commons/Connection.ts"
import { CustomErrorClass, ErrorInformationStorage } from "../../Commons/ErrorInformationStorage.ts"
import { Resources, CurrentLang } from "../../Commons/properties/Resources.ts"
import { LinkedSystem } from "../misc/LinkedSystem.ts"
import { ControlsUtils } from "../UtilsClasses/ControlsUtils.ts"
import { FilesHelper } from "../../Utils/FilesHelper.ts"
import { TerceraReportsPanelTemplate } from "../../templates.js";
import { TerceraDateTimePicker } from "../elements/DateTimePicker/TerceraDateTimePicker.js"
import { TerceraAssetComboBox } from "../elements/TerceraAssetComboBox.ts";
import { TerceraCheckBox } from "../elements/TerceraCheckBox.js"
import { TerceraCheckBoxGroup } from "../elements/TerceraCheckBoxGroup.js";
import { TerceraLabel } from "../elements/TerceraLabel.js"
import { TerceraTextBox } from "../elements/TerceraTextBox.js"
import { TerceraMessageBox } from "../screen/TerceraMessageBox.js"
import { PanelNames } from "../UtilsClasses/FactoryConstants.ts"
import { ApplicationPanelNew } from "./ApplicationPanelNew.js";
import { Message } from "../../Utils/DirectMessages/DirectMessagesImport.ts"
import { DateTimeUtils } from "../../Utils/Time/DateTimeUtils.ts"
import { QuickTableComparingType } from "../../Utils/QuickTableMisc/QuickTableComparingType.ts"
import { AllowedReport } from "../../Commons/cache/AllowedReport.ts"
import { ParamType } from "../../Commons/cache/AllowedReportConstants.ts"
import { DataCache } from "../../Commons/DataCache.ts"
import { TerceraAccountLookup } from "../elements/Lookup/TerceraAccountLookup.ts"
import { TerceraInstrumentLookup } from "../elements/Lookup/TerceraInstrumentLookup.ts"
import { TerceraGroupPanel } from "../elements/TerceraGroupPanel.js";

export let ReportsPanel = ApplicationPanelNew.extend({
    Name: 'ReportsPanel',
    partials:
    {
        bodyPartial: TerceraReportsPanelTemplate
    },
    data: function ()
    {
        return {
            resizable: false,
            reportsComboBoxList: [],
            selectedReportComboItem: undefined,
            dockablePanel: false,
            showHeader: true,
            showFooter: false,
            canLinkByAccount: true,
            reportTypeString: '',
            account: null,

            loading: false,
            reportResponseMessage: null,

            exportFormat: null,
            exportToTitle: '',
            exportToTitleItem: null,
            exportToCBItems: [],

            noDataErrorText: '',
            noDataErrorVisibility: false,
            cancelBtnText: '',
            style_addition_header: 'js-ExportScreen-AdditionalHeader'
        }
    },
    reportParamProcessDict: null,
    paramControlArr: null,
    accountControl: null,
    generateReportPromise: null
})

ReportsPanel.prototype.getType = function ()
{
    return PanelNames.ReportsPanel
}

ReportsPanel.prototype.oninit = function ()
{
    ApplicationPanelNew.prototype.oninit.call(this)

    if (DataCache.EnableForceLinkingByAccount())
    { // https://tp.traderevolution.com/entity/119990
        this.set('canLinkByAccount', false);
    }

    this.paramControlArr = []

    const paramTypes = ParamType

    let procDict = {}

    procDict[paramTypes.TextBox] = this.processTextBoxReportType
    procDict[paramTypes.DateBox] = this.processDateBoxReportType

    if (DataCache.getNumberOfAccounts() !== 1)
        procDict[paramTypes.AccountLookup] = this.processAccountLookupReportType

    procDict[paramTypes.InstrumentsLookup] = this.processInstrumentsLookupReportType
    procDict[paramTypes.CheckBox] = this.processCheckBoxReportType
    procDict[paramTypes.CheckBoxGroup] = this.processCheckBoxGroupReportType
    procDict[paramTypes.AssetComboBox] = this.processAssetComboBoxReportType
    procDict[paramTypes.AssetComboBoxWithoutAll] = this.processAssetComboBoxWithoutAllReportType

    this.reportParamProcessDict = procDict
}

ReportsPanel.prototype.oncomplete = function ()
{
    ApplicationPanelNew.prototype.oncomplete.call(this)

    this.on('onExportToClick', this.onExportToClick.bind(this))
    this.on('cancelPromise', this.cancelReportPromise.bind(this))

    this.observe('selectedReportComboItem', this.onSelectedReportComboItemChanged)

    this.localize()
    this.center()
}

ReportsPanel.prototype.clearParamControlsArr = function ()
{
    var paramControlArr = this.paramControlArr
    if (!paramControlArr) return

    var i = paramControlArr.length
    while (i--)
        this.Controls.paramsPanel.removeControl(paramControlArr[i])

    this.paramControlArr = []
}

ReportsPanel.prototype.localize = function ()
{
    ApplicationPanelNew.prototype.localize.call(this)
    this.set({
        header: Resources.getResource('screen.reports.Reports'),
        reportTypeString: Resources.getResource('screen.reports.reportType'),
        noDataErrorText: Resources.getResource('screen.reports.reports.noData'),
        cancelBtnText: Resources.getResource('screen.export.Cancel')
    })

    var items = []

    ReportsPanel.exportFormats.forEach(function (el) { items.push({ text: Resources.getResource(el), value: el }) })

    this.set('exportToCBItems', items)

    this.set('exportToTitle', Resources.getResource('screen.reports.exportToButton'))
    this.set('exportToTitleItem', { text: this.get('exportToTitle') })
}

ReportsPanel.prototype.onMouseDown = function (event, isHeader)
{
    ApplicationPanelNew.prototype.onMouseDown.apply(this, [event, isHeader]);

    this.set('noDataErrorVisibility', false)
};

ReportsPanel.prototype.populate = function ()
{
    ApplicationPanelNew.prototype.populate.call(this, true)

    let reportArr = []

    let reportDict = DataCache.getAllowedReportDict()
    for (let key in reportDict)
    {
        let report = reportDict[key]
        if (report.key.indexOf(AllowedReport.TECHNICAL_REPORT_KEY_PREFIX) == -1)
            reportArr.push(report)
    }

    reportArr.sort(function (r1, r2)
    {
        return r1.toString().localeCompare(r2.toString())
    })

    let maxReportNameLength = 0,
        cbItemArr = reportArr.map(function (report)
        {
            let reportName = report.toString(),
                textW = ControlsUtils.GetTextWidth(reportName, ReportsPanel.REPORT_LIST_FONT) + ReportsPanel.REPORT_LIST_PADDING

            maxReportNameLength = Math.max(maxReportNameLength, textW)      // #102705

            return {
                text: reportName,
                value: report
            }
        })

    let reportListWidth = Math.max(ReportsPanel.REPORT_LIST_MIN_WIDTH, Math.min(ReportsPanel.REPORT_LIST_MAX_WIDTH, maxReportNameLength))

    this.set({
        reportsComboBoxList: cbItemArr,
        reportsComboBoxListItemsWidth: reportListWidth
    })
}

ReportsPanel.prototype.onSelectedReportComboItemChanged = function (item)
{
    if (!item)
        return

    this.populateParamsControls(item.value)
}

ReportsPanel.prototype.populateParamsControls = function (report)
{
    this.clearParamControlsArr()

    var paramControlArr = this.paramControlArr

    var procDict = this.reportParamProcessDict

    var reportParams = report.params.slice()
    reportParams.sort(ReportsPanel.compareReportParams)

    var len = reportParams.length;

    for (var i = 0; i < len; i++)
    {
        var rp = reportParams[i]

        if (rp.type != ParamType.DateBox)     // #93255 <- скрываем все фильтры кроме фильтра по дате
            if (rp.type != ParamType.AccountLookup)   // #99187 <- показываем аккаунты
                continue

        var handler = procDict[rp.type]
        if (!handler) continue

        var res = handler.call(this, rp)
        if (!res) continue

        var groupControl = new TerceraGroupPanel();
        this.paramControlArr.push(groupControl);
        this.Controls.paramsPanel.addControl(groupControl);

        var labelControl = res.labelControl;
        if (labelControl)
        {
            groupControl.addControl(labelControl);
        }

        var mainControl = res.mainControl;
        if (mainControl)
        {
            groupControl.paramType = ParamType.GroupPanel;
            groupControl.ReportParamName = rp.name;
            groupControl.addControl(mainControl, true);
        }
    }
}

//#region Process Report Controls

ReportsPanel.prototype.processTextBoxReportType = function (reportParam)
{
    var label = new TerceraLabel();
    label.set({
        text: reportParam.toString()
    });

    var tBox = new TerceraTextBox();
    tBox.paramType = ParamType.TextBox;
    tBox.set('text', '');

    return {
        labelControl: label,
        mainControl: tBox
    };
};

ReportsPanel.prototype.processCheckBoxReportType = function (reportParam)
{
    var checkBox = new TerceraCheckBox();
    checkBox.paramType = ParamType.CheckBox;
    checkBox.set({
        text: reportParam.toString(),
        checked: false
    });

    return {
        mainControl: checkBox
    };
};

ReportsPanel.prototype.processCheckBoxGroupReportType = function (reportParam)
{
    var label = new TerceraLabel();
    label.set({
        text: reportParam.toString()
    });

    var cboxGroup = new TerceraCheckBoxGroup();
    cboxGroup.paramType = ParamType.CheckBoxGroup;

    var dataArray = [];
    var values = reportParam.options;
    var len = values ? values.length : 0;
    for (var i = 0; i < len; i++)
    {
        var val = values[i];
        dataArray.push({
            text: ReportsPanel.getParamText(val.text),
            value: val.value,
            checked: true
        });
    }
    cboxGroup.set('dataArray', dataArray);

    return {
        labelControl: label,
        mainControl: cboxGroup
    };
};

ReportsPanel.prototype.processDateBoxReportType = function (reportParam)
{
    let dateFromType = reportParam.name.indexOf('from') > -1

    var label = new TerceraLabel();
    label.set({
        text: reportParam.toString()
    });

    var dtPicker = new TerceraDateTimePicker();
    dtPicker.paramType = ParamType.DateBox;
    dtPicker.set('dateFromStyle', dateFromType)

    var dt = new Date();
    dt.setUTCMilliseconds(0);
    if (reportParam.name === 'toDate')
    {
        dt.setHours(23);
        dt.setMinutes(59);
        dt.setSeconds(59);
    }
    else
    {
        dt.setHours(0);
        dt.setMinutes(0);
        dt.setSeconds(0);
    }

    dtPicker.set('dateTime', dt);

    return {
        labelControl: label,
        mainControl: dtPicker
    };
};

ReportsPanel.prototype.processAccountLookupReportType = function (reportParam)
{
    var label = new TerceraLabel();
    label.set({
        text: reportParam.toString()
    });

    var accLookup = new TerceraAccountLookup();
    accLookup.oninit();
    accLookup.IsMultiSelect = true;
    accLookup.isMultiSelectMode = true;
    accLookup.paramType = ParamType.AccountLookup;
    accLookup.observe('selectedItem', this.onAccountChanged.bind(this))

    let selItem = null
    if (this.accountControl)
        selItem = this.accountControl.get('selectedItem')

    accLookup.set('ignoreForceLinkingByAccount', true);

    if (selItem)
        accLookup.set('selectedItem', selItem)

    this.accountControl = accLookup

    return {
        labelControl: label,
        mainControl: accLookup
    };
};

ReportsPanel.prototype.processInstrumentsLookupReportType = function (reportParam)
{
    var label = new TerceraLabel();
    label.set({
        text: reportParam.toString()
    });

    var insLookup = new TerceraInstrumentLookup();
    insLookup.oninit();
    insLookup.IsMultiSelect = true;
    // insLookup.isMultiSelectMode = true;
    insLookup.paramType = ParamType.InstrumentsLookup;

    return {
        labelControl: label,
        mainControl: insLookup
    };
};

ReportsPanel.prototype.processAssetComboBoxReportType = function (reportParam)
{
    var label = new TerceraLabel();
    label.set({
        text: reportParam.toString()
    });

    var assetComboBox = new TerceraAssetComboBox();
    assetComboBox.paramType = ParamType.AssetComboBox;

    return {
        labelControl: label,
        mainControl: assetComboBox
    };
};

ReportsPanel.prototype.processAssetComboBoxWithoutAllReportType = function (reportParam)
{
    var res = this.processAssetComboBoxReportType(reportParam);
    res.mainControl.set('allItemVisible', false);
    return res;
};

//#endregion

ReportsPanel.prototype.getReport = function ()
{
    if (!this.checkTimeParameters())
        return TerceraMessageBox.Show(
            Resources.getResource('screen.reports.error'),
            Resources.getResource('screen.reports.rangeError'),
            TerceraMessageBox.msgType.Error,
            null, null, false, true)

    // TODO. Ugly.
    let reportTypeObj = this.get('selectedReportComboItem.value')
    let paramDict = this.getAdvancedParamDict(reportTypeObj)

    this.set('reportResponseMessage', null)

    this.set('loading', true)

    let self = this

    this.generateReportPromise = Connection.vendor.GenerateReportPromise(reportTypeObj.keyForServer, paramDict)
        .then(function (reportResponseMessage)
        {
            self.set('noDataErrorVisibility', false)
            self.set('reportResponseMessage', reportResponseMessage)
            let success = self.parseReportResponceMessage()

            if (!success)
                self.set('noDataErrorVisibility', true)
        })
        .catch(function () 
        {
            self.set('noDataErrorVisibility', true)
            let ex = new CustomErrorClass("ReportsPanel error", "ReportsPanel.getReport", "getReport -> GenerateReportPromise");
            ErrorInformationStorage.GetException(ex);

            //console.error('Report fetch error.')
        })
        .then(function () { self.restoreAfterLoading() })
}

ReportsPanel.prototype.getAdvancedParamDict = function (reportTypeObj)
{
    var controls = this.paramControlArr
    if (!controls) return ''

    var mainParamDict = {}

    var len = controls.length;
    for (var i = 0; i < len; i++)
    {
        var control = controls[i];
        var paramName = control.ReportParamName;
        var paramType = control.paramType;

        if (paramType === ParamType.GroupPanel) {
            const groupControls = control.Controls;
            for (let p in groupControls)
                if (groupControls[p].paramType != null) {
                    control = groupControls[p];
                    continue;
                }
            paramType = control ? control.paramType : null;
        }

        if (!paramType) continue


        var value = ''

        switch (paramType)
        {
            case ParamType.TextBox:
                value = control.get('text')
                break
            case ParamType.DateBox:
                value = control.get('dateTime').getTime().toString()
                break
            case ParamType.InstrumentsLookup:
                value = control.get('selectedItem').FullName
                if (value === 'All') value = ''
                break
            case ParamType.AccountLookup:
                value = control.get('selectedItem').FullAccString || control.get('selectedItem').AcctNumber
                if (value === 'All') value = ''
                break
            case ParamType.AssetComboBox:
                value = control.get('selectedItem').value.toString()
                if (value === 'All') value = ''
                break
            case ParamType.CheckBox:
                value = control.get('checked').toString()
                break
            case ParamType.CheckBoxGroup:
                var dataArray = control.get('dataArray')
                if (dataArray && dataArray.length)
                {
                    var len = dataArray.length;
                    for (var i = 0; i < len; i++)
                    {
                        var item = dataArray[i];
                        var checked = item.checked;
                        var val = item.value;
                        if (checked && val !== undefined) value += val + ',';
                    }
                    if (value) value = value.slice(0, -1)
                }
                break
        }

        mainParamDict[paramName] = value
    }

    // TODO.
    return {
        locale: CurrentLang,
        reportTypeObj: reportTypeObj,
        mainParamDict: mainParamDict
    }
}

ReportsPanel.prototype.checkTimeParameters = function ()
{
    var controls = this.paramControlArr;
    if (!controls) return false;

    var to;
    var from;

    var len = controls.length;
    for (var i = 0; i < len; i++)
    {
        var control = controls[i];
        var paramType = control.paramType;

        if (!paramType || paramType !== 2)
            continue;

        if (control.ReportParamName === 'toDate')
            to = control.get('dateTime');
        else
            from = control.get('dateTime');
    }

    return to && from ? to > from : true;
};

ReportsPanel.prototype.onExportToClick = function (context, newVal)
{
    this.set('exportFormat', newVal.tag)

    this.getReport()

    //this.set('exportToTitleItem', {text: this.get('exportToTitle') })
};

ReportsPanel.prototype.parseXMLStringForTables = function ()
{
    let reportResponseMessage = this.get('reportResponseMessage')

    let resultArr = reportResponseMessage.xmlString.split('</table>')

    resultArr.splice(resultArr.length - 1, 1)

    for (let i = 0; i < resultArr.length; i++)
        resultArr[i] += '</table>'

    return resultArr
}

ReportsPanel.prototype.parseReportResponceMessage = function ()
{
    let msg = this.get('reportResponseMessage')

    if (!msg) return false

    let isNewPFIXmessage = msg.Code === Message.CODE_REPORT_GROUP_RESPONSE

    let msgTables = isNewPFIXmessage ? msg.Tables : this.parseXMLStringForTables(),
        parser = isNewPFIXmessage ? null : new DOMParser(),
        resultTables = [],
        atLeastOneSuccess = false

    for (let tableI = 0; tableI < msgTables.length; tableI++)
    {
        let tableParseResult = isNewPFIXmessage ? this.parseOneTable(msgTables[tableI]) : this.parseOneTableFromXML(parser, msgTables[tableI])

        if (tableParseResult.success)
            atLeastOneSuccess = true

        resultTables.push(tableParseResult)
    }

    if (atLeastOneSuccess)
        this.createReportFile(resultTables)

    return atLeastOneSuccess
}

ReportsPanel.prototype.parseReportResponceMessageXML = function ()      // old scheme
{
    let xmlStringTables = this.parseXMLStringForTables(),
        parser = new DOMParser(),
        resultTables = [],
        atLeastOneSuccess = false

    for (let tableI = 0; tableI < xmlStringTables.length; tableI++)
    {
        let tableParseResult = this.parseOneTableFromXML(parser, xmlStringTables[tableI])

        if (tableParseResult.success)
            atLeastOneSuccess = true

        resultTables.push(tableParseResult)
    }

    if (atLeastOneSuccess)
        this.createReportFile(resultTables)

    return atLeastOneSuccess
}

ReportsPanel.prototype.parseOneTable = function (table)
{
    let result = {
        success: false,
        tableName: table.ReportName,
        cols: [],
        rows: []
    }

    let columns = []

    for (let i = 0, len = table.ReportColumns.length; i < len; i++)
    {
        let column = table.ReportColumns[i]

        columns.push({
            Type: column.Type,
            Name: column.Name
        })
    }

    let rows = []

    for (let i = 0, len = table.ReportRows.length; i < len; i++)
    {
        let tr = table.ReportRows[i]
        let cells = []
        rows.push({ Cells: cells })

        for (let j = 0; j < tr.length; j++)
        {
            let td = tr[j]
            let rawVal = td.trim()
            let val = this.getValueFromRawValue(rawVal, columns[j].Type)

            cells.push({ Label: rawVal.toString(), Value: val })
        }
    }

    result.success = true
    result.cols = columns
    result.rows = rows

    return result
}

ReportsPanel.prototype.parseOneTableFromXML = function (parser, xmlStringTable)
{
    let xml = parser.parseFromString(xmlStringTable, "text/xml")

    let table = xml.childNodes[0]

    let th = table.getElementsByTagName('th')[0]
    let trArr = table.getElementsByTagName('tr')

    let result = {
        success: false,
        tableName: table.getAttribute('name'),
        cols: [],
        rows: []
    }

    let columns = []

    if (!th)
    {
        result.success = false

        return result
    }

    let thChildren = th.childNodes
    for (let i = 0, len = thChildren.length; i < len; i++)
    {
        let headerItem = thChildren[i]
        let type = null

        if (!headerItem.getAttribute)
            continue

        switch (headerItem.getAttribute('type'))
        {
            case 'FLOAT':
            case 'NUMBER':
                type = QuickTableComparingType.Double
                break
            case 'INTEGER':
                type = QuickTableComparingType.Int
                break
            case 'DATE':
                type = QuickTableComparingType.DateTime
                break
            default:
                type = QuickTableComparingType.String
        }
        columns.push({
            Type: type,
            Name: headerItem.textContent
        })
    }

    let rows = []

    for (let i = 0, len = trArr.length; i < len; i++)
    {
        let tr = trArr[i]
        let cells = []
        rows.push({ Cells: cells })

        let tdArr = tr.childNodes
        for (let j = 0, j_len = tdArr.length; j < j_len; j++)
        {
            let td = tdArr[j]
            let rawVal = td.textContent.trim()
            let val = this.getValueFromRawValue(rawVal, columns[j].Type)

            cells.push({ Label: rawVal.toString(), Value: val })
        }
    }

    result.success = true
    result.cols = columns
    result.rows = rows

    return result
}

ReportsPanel.prototype.getValueFromRawValue = function (rawVal, type)
{
    let val = null

    switch (type)
    {
        case QuickTableComparingType.Double:
            val = rawVal === '' ? '' : parseFloat(rawVal)
            break

        case QuickTableComparingType.Int:
            val = rawVal === '' ? '' : parseInt(rawVal)
            break

        //case QuickTableComparingType.Long:
        case QuickTableComparingType.DateTime:
            val = parseInt(rawVal) ? DateTimeUtils.formatDate(new Date(parseInt(rawVal)), 'DD.MM.YYYY HH:mm:ss') : ''
            break

        default:
            val = rawVal
    }

    if (rawVal == 'N/A')
        val = rawVal

    if (isNaN(val) && rawVal == 'Total')        // 93089 какого хера Total приходит с типом 1 = INT????? 
        val = rawVal

    return val
}

ReportsPanel.prototype.createReportFile = function (tables)
{
    let content = '', filename = '',
        selectedReportComboItem = this.get('selectedReportComboItem')

    if (selectedReportComboItem && selectedReportComboItem.text)
        filename = selectedReportComboItem.text + ' '
    filename += DateTimeUtils.formatDate(new Date(), "DD.MM.YYYY HH-mm-ss")

    let formatType = this.get('exportFormat')

    if (formatType == ReportsPanel.EXCEL)   // thanks to 93681
    {
        this.createXLSFile(filename, tables)
        return
    }

    for (let tableI = 0; tableI < tables.length; tableI++)
    {
        let tableData = tables[tableI],
            notEmptyTable = tableData.success,
            reportName = tableData.tableName,
            rows = tableData.rows,
            columns = tableData.cols

        content += formatType == ReportsPanel.HTML ?
            this.createHTMLTable(reportName, columns, rows) :
            "\uFEFF" + this.createCSVTable(reportName, columns, rows)   // #103325 specify as UTF8 with BOM for correct polish characters
    }

    switch (formatType)
    {
        case ReportsPanel.HTML:
            filename += '.html'
            break
        case ReportsPanel.CSV:
            filename += '.csv'
            break
    }

    FilesHelper.saveFile(filename, content)
}

ReportsPanel.prototype.createHTMLTable = function (tableTitle, cols, rows)
{
    let tableHTML = '';

    tableHTML += '<table border="1" width="100%" cellpadding="5"> <caption><h2>' + tableTitle + '</h2></caption><tr>'
    for (let i = 0; i < cols.length; i++)
    {
        let curColumn = cols[i];
        tableHTML += '<th>' + curColumn.Name + '</th>'
    }

    if (!cols.length)
        tableHTML += '<th>' + Resources.getResource('screen.reports.reports.noItemAvailable') + '</th>'

    tableHTML += '</tr>'

    for (let i = 0; i < rows.length; i++)
    {
        let row = rows[i], cells = row.Cells

        tableHTML += '<tr>'
        for (let j = 0; j < cells.length; j++)
            tableHTML += '<td>' + cells[j].Value.toString().trim() + '</td>'
        tableHTML += '</tr>'
    }
    tableHTML += '</table>'

    return tableHTML
}

ReportsPanel.prototype.createCSVTable = function (tableTitle, columns, rows)
{
    let formatType = this.get('exportFormat')

    let content = tableTitle + "\r\n"

    if (!columns.length)
        content += Resources.getResource('screen.reports.reports.noItemAvailable')

    let separator = formatType == ReportsPanel.CSV ? ';' : '\t'

    for (let j = 0; j < columns.length; j++)
        content += columns[j].Name + separator
    content += "\r\n"

    for (let i = 0; i < rows.length; i++)
    {
        let row = rows[i], cells = row.Cells
        for (let j = 0; j < cells.length; j++)
            content += cells[j].Value.toString().trim() + separator
        content += "\r\n"
    }

    return content + "\r\n"
}

ReportsPanel.prototype.createXLSFile = function (filename, tables)
{
    let preparedTables = []

    for (let tableI = 0; tableI < tables.length; tableI++)
    {
        let table = tables[tableI]

        preparedTables.push({
            Data: this.createXLSTable(table.cols, table.rows),
            TableName: table.tableName
        })
    }

    FilesHelper.saveExcelFile(filename, preparedTables)
}

ReportsPanel.prototype.createXLSTable = function (columns, rows)
{
    let sheetData = [],
        rowToAdd = []

    if (!columns.length)
        sheetData.push([Resources.getResource('screen.reports.reports.noItemAvailable')])

    for (let j = 0; j < columns.length; j++)
        rowToAdd.push(columns[j].Name)

    sheetData.push(rowToAdd)

    for (let i = 0; i < rows.length; i++)
    {
        let row = rows[i], cells = row.Cells, rowToAdd = []
        for (let j = 0; j < cells.length; j++)
            rowToAdd.push(cells[j].Value.toString().trim())

        sheetData.push(rowToAdd)
    }

    return sheetData
}

ReportsPanel.prototype.cancelReportPromise = function ()
{
    var promise = this.generateReportPromise;

    if (!promise)
        return

    promise.cancel()
    this.restoreAfterLoading()

    console.log('promise canceled')
}

ReportsPanel.prototype.restoreAfterLoading = function ()
{
    this.set('loading', false)
    this.set('exportToTitleItem', { text: this.get('exportToTitle') })
}

ReportsPanel.prototype.accountLink_In = function (accountId, fromLinkedSystem)
{
    if (fromLinkedSystem && !this.OnAccountLinking()) { return; }
    if (DataCache.EnableForceLinkingByAccount()) { return; }
    if (!accountId) { return; }

    let acc = DataCache.Accounts[accountId]

    if (acc)
    {
        this.set('account', acc)
        if (this.accountControl)
            this.accountControl.set('selectedItem', acc)
    }

    this.localizeAccountLinkTooltip()
};

ReportsPanel.prototype.accountLink_Out = function (newSubscriber, account)
{
    if (DataCache.EnableForceLinkingByAccount()) { return; }

    if (!account || (account.AcctNumber && account.AcctNumber == 'All'))
    {
        var acc = this.get('account');
        if (!acc) return;
        account = acc;
    }

    if (account.arraySelected)
        account = account.arraySelected[0]

    ApplicationPanelNew.prototype.accountLink_Out.apply(this, [newSubscriber, account]);
};

ReportsPanel.prototype.onAccountChanged = function (newAcc, oldAcc)
{
    if (!newAcc || newAcc === oldAcc)
        return;

    this.accountLink_Out(false, newAcc);
};

ReportsPanel.prototype.getXmlSettingsTemplate = function () { return ''; };

ReportsPanel.prototype.setXmlSettingsTemplate = function (value) { };

ReportsPanel.compareReportParams = function (rp0, rp1)
{
    return ReportsPanel.getReportParamOrderNumber(rp0) -
        ReportsPanel.getReportParamOrderNumber(rp1)
}

ReportsPanel.getReportParamOrderNumber = function (rp)
{
    if (rp.type === ParamType.InstrumentsLookup ||
        rp.type === ParamType.UsersLookup ||
        rp.type === ParamType.AccountLookup)
        return 1;

    if (rp.type === ParamType.AssetComboBox)
        return 2;

    if (rp.type === ParamType.DateBox)
    {
        if (rp.name.indexOf('from') !== -1)
            return 5;
        else
            return 6;
    }

    if (rp.type === ParamType.CheckBox)
        return 10;

    return 100;
}

ReportsPanel.getParamText = function (key)       // #88497 возвращает значения ключа, в случае отсутствия ключа в файлах локализации возвращает суффикс после точки (reports.TaskType->TaskType)
{
    let cbText = Resources.getResource(key)
    if (!Resources.IsResourcePresent(key))
    {
        let splitArr = key.split('.')
        if (splitArr.length > 1)
            cbText = splitArr[1]
    }

    return cbText
}

ReportsPanel.CSV = "screen.reports.csv";
ReportsPanel.EXCEL = "screen.reports.excel";
ReportsPanel.HTML = "screen.reports.html";

ReportsPanel.exportFormats = [ReportsPanel.CSV, ReportsPanel.EXCEL, ReportsPanel.HTML];

ReportsPanel.REPORT_LIST_PADDING = 20;
ReportsPanel.REPORT_LIST_MIN_WIDTH = 200;
ReportsPanel.REPORT_LIST_MAX_WIDTH = 400;
ReportsPanel.REPORT_LIST_FONT = '12px \"Trebuchet MS\"';