// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { GeneralSettings } from '../../../Utils/GeneralSettings/GeneralSettings';
import { NumericUtils } from '../../../Utils/NumericUtils';
import { TIF } from '../../../Utils/Trading/OrderTif';
import { OrderType } from '../../../Utils/Trading/OrderType';
import { Quantity } from '../../../Utils/Trading/Quantity';
import { DynProperty } from '../../DynProperty';
import { IsAllowed } from '../../IsAllowed';
import { OrderExecutorUtils } from '../../Trading/OrderExecutorUtils';
import { Resources } from '../../properties/Resources';
import { InstrumentUtils } from '../../../Utils/Instruments/InstrumentUtils';
import { SLTPTriggerUtils } from './SLTPTriggerUtils';
import { OrderEditUpdateData } from '../../../Utils/Trading/OrderEditUpdateData';
import { PositionSLTPEdit } from './PositionSLTPEdit';
import { SLTPEdit } from './SLTPEdit';
import { OrderEditBaseUtils, type OrderEditRequestData } from '../../../Utils/Trading/OrderEditBaseUtils';
import { OrderEditBase } from './order-edit/OrderEditBase';
import { DataCache } from '../../DataCache';

// TODO. Refactor.
export class PositionEdit extends OrderEditBase {
    public canEditSLOrTrailingStop: boolean;
    public canEditTP: boolean;
    public mqData: any;
    public UseSkipNoChange: boolean;

    constructor (data) {
        super(data);

        this.position = null;

        this.sltp = new PositionSLTPEdit(data.dataCache);
        this.slBasePriceGetter = this.slBasePriceGetter.bind(this);
        this.tpBasePriceGetter = this.tpBasePriceGetter.bind(this);

        // TODO. Ugly.
        this.canEditSLOrTrailingStop = true;
        this.canEditTP = true;

        const position = data.position;

        this.mqData =
        {
            newPrType: null,
            modQT: null,
            maxQT: null,
            enabled: false,
            errVisible: false,
            hasError_bound: false,
            hasError_StartOfDay: false,
            hasError_dp: false
        };

        // TODO. Refactor.
        this.updateParameters(new OrderEditUpdateData(
            null,
            {
                instrument: position.Instrument,
                account: position.Account,
                side: position.BuySell,
                position,
                quantity: new Quantity(position.Amount, true),
                tif: new TIF(position.TimeInForce, new Date(position.ExpireAt)),
                productType: position.ProductType,
                placedFrom: data.placedFrom
            }
        ));

        DataCache.OnAddSLOrderToPosition.Subscribe(this.updateSLWhenActivate, this); // #93646

        // TMP FLAG TODO REMOVE
        // SKIP NO CHANGE
        this.UseSkipNoChange = false;
    }

    public override getOrderTypeId (): OrderType {
        return OrderType.PositionEdit;
    }

    public override tradingAllowed (): boolean {
        return IsAllowed.IsPositionModifyingAllowed(this.position).Allowed;
    }

    // public override getOrderTypeIdValidation (): OrderType {
    //     return OrderType.Market;
    // }

    public getParameterNameArray (): string[] {
        return [
            OrderEditBaseUtils.PRODUCT_TYPE_AND_MQ,
            OrderEditBaseUtils.ATTENTION_MSG,
            OrderEditBaseUtils.SLTP_PARAM
        ];
    }

    // TODO. Refactor.
    public override setTradingData (tradingDataDict): any {
        const hasPos = tradingDataDict && 'position' in tradingDataDict;
        if (hasPos) {
            this.position = tradingDataDict.position;
        }

        const newTradingDataDict =
        super.setTradingData(tradingDataDict);

        if (!newTradingDataDict) {
            return newTradingDataDict;
        }

        if (hasPos) {
            newTradingDataDict.position = this.position;
        }

        return newTradingDataDict;
    }

    // TODO. Refactor base class, trading data. Ugly.
    public override getTradingData (): any {
        const tradingDataDict = super.getTradingData();
        tradingDataDict.position = this.position;
        return tradingDataDict;
    }

    // TODO. Refactor base class, trading data. Ugly.
    public override getDataForRequest (): OrderEditRequestData {
        const reqData = super.getDataForRequest();
        if (reqData) {
            reqData.position = this.position;
            reqData.canEditSLOrTrailingStop = this.canEditSLOrTrailingStop;
            reqData.canEditTP = this.canEditTP;
            reqData.isSLchanged = this.oldAndNewSLdiffer();
            reqData.isTPchanged = this.oldAndNewTPdiffer();
            reqData.placedFrom = this.placedFrom;

            // TODO REMOVE
            reqData.UseSkipNoChange = this.UseSkipNoChange;
        }
        return reqData;
    }

    public IsQuantityModified (): any {
        return this.mqData.enabled;
    }

    public getQuantity (): any {
        return this.mqData.modQT;
    }

    public getNewProducType (): any {
        return this.mqData.newPrType;
    }

    public override dispose (): void {
        DataCache.OnAddSLOrderToPosition.UnSubscribe(this.updateSLWhenActivate, this);
        this.position = null;

        super.dispose();
    }

    public updateSLWhenActivate (): void // #93646
    {
        this.ParametersChanged.Raise({ sltp: this.toDynProperty_sltp() });
    }

    public override valid (): boolean {
        return super.valid() && this.validMQ();
    }

    public validMQ (): boolean {
        if (this.IsQuantityModified()) {
            return !this.mqData.hasError_bound && !this.mqData.hasError_dp && !this.mqData.hasError_StartOfDay;
        }

        return true;
    }

    public override update_sltp (updateData): any {
        let updated = this.sltp.update(updateData, this.getTradingData());
        if (this.mqData.enabled && (this.sltp.sl.enabled || this.sltp.tp.enabled)) {
            updated = true;
            const dp = this.toDynProperty_ModifyQuantity();
            dp.enabled = false;
            this.updateParameters(new OrderEditUpdateData({ ModifyQuantity: dp }));
            if (this.position.SLOrder !== null || this.position.TPOrder !== null) {
                const dp = this.toDynProperty_attention();
                dp.visible = false;
                this.updateParameters(new OrderEditUpdateData({ attention: dp }));
            }
        }
        return updated;
    }

    public update_ModifyQuantity (updateData): boolean {
        let updated = false;
        const dp = updateData.dp;

        if (!dp?.visible) {
            return false;
        }

        updated = this.mqData.enabled !== dp.enabled || updated;
        this.mqData.enabled = dp.enabled;

        if (dp.enabled) {
            this.sltp.sl.enabled = false;
            this.sltp.tp.enabled = false;
            if (this.position.SLOrder !== null || this.position.TPOrder !== null) {
                const dp = this.toDynProperty_attention();
                dp.visible = true;
                this.updateParameters(new OrderEditUpdateData({ attention: dp }));
            }

            if (dp.selectedValue) {
                const val = parseInt(dp.selectedValue.value);
                updated = this.mqData.newPrType !== val || updated;
                this.mqData.newPrType = val;
            }
            updated = this.mqData.modQT !== dp.value || updated;
            this.mqData.modQT = dp.value;
            const qty = dp.value;
            const errBound = qty > this.mqData.maxQT;
            const errStartOfDay = this.position.Amount <= this.position.StartOfDayAmount;
            updated = this.mqData.hasError_bound !== errBound || updated;
            this.mqData.hasError_bound = errBound;
            updated = this.mqData.hasError_StartOfDay !== errStartOfDay || updated;
            this.mqData.hasError_StartOfDay = errStartOfDay;
            updated = this.mqData.hasError_dp !== dp.hasError || updated;
            this.mqData.hasError_dp = dp.hasError;
        }

        return !!updated;
    }

    public toDynProperty_ModifyQuantity (): DynProperty {
        const dp = new DynProperty();
        dp.type = DynProperty.PRODUCT_TYPE_AND_MQ;

        dp.visible = IsAllowed.IsModifyProductTypeAllowed(this.position).Allowed;

        if (!dp.visible) {
            return dp;
        }

        const instrument = this.instrument;
        const inLots = GeneralSettings.View.DisplayQuantityInLots;
        const settings = Quantity.getQuantityNumericSettings(instrument, inLots, this.position.Account);
        const baseValue = Quantity.convertQuantityValue(new Quantity(this.position.Amount - (this.position.StartOfDayAmount || 0), true), instrument, inLots);
        if (this.mqData.modQT) {
            dp.value = this.mqData.modQT;
        } else {
            dp.value = baseValue;
        }

        if (dp.value < 0) { dp.value = 0; };

        this.mqData.maxQT = baseValue;

        dp.minimalValue = settings.minValue;
        dp.maximalValue = NumericUtils.MAXVALUE;
        dp.decimalPlaces = settings.decimalPrecision;
        dp.increment = settings.step;

        dp.productType = this.position.ProductType;

        const dict = InstrumentUtils.getAllowedProductTypeDict(instrument).filter((pt) => { return parseInt(pt) !== dp.productType; });
        const items = dict.map(function (productType) {
            return {
                value: productType,
                text: InstrumentUtils.GetLocalizedProductType(instrument, productType)
            };
        });
        dp.objectVariants = items;
        dp.selectedValue = items[0];
        dp.enabled = this.mqData.enabled;
        dp.hasError = this.mqData.hasError_dp || this.mqData.hasError_StartOfDay;
        dp.customLess = false;
        if (this.mqData.hasError_bound) {
            dp.hasError = this.mqData.hasError_bound;
            dp.customError = Resources.getResource('general.trading.qtyMoreTodayTradedValue');
        }

        if (this.mqData.hasError_StartOfDay) {
            dp.hasError = this.mqData.hasError_StartOfDay;
            dp.customError = Resources.getResource('general.trading.noQuantityAvailable');
            dp.customLess = true;
        }

        dp.localizationKey_type = 'screen.modifyOrder.modifyPosition.TargetProductType';
        dp.localizationKey_qty = 'screen.modifyOrder.modifyPosition.ModifyQuantity';
        return dp;
    }

    public toRawValue_ModifyQuantity (): any {
        const resData = { quantity: null, newPrType: null };
        if (this.mqData.enabled) {
            resData.quantity = this.mqData.modQT;
            resData.newPrType = this.mqData.newPrType;
        }
        return resData;
    }

    public update_attention (updateData): boolean {
        let updated = false;
        const dp = updateData.dp;

        if (!dp) {
            return false;
        }

        updated = this.mqData.errVisible !== dp.visible || updated;
        this.mqData.errVisible = dp.visible;

        return updated;
    }

    public toDynProperty_attention (): DynProperty {
        const dp = new DynProperty();
        dp.type = DynProperty.ATTENTION_MSG;
        dp.visible = this.mqData.errVisible;
        dp.localizationKey = 'screen.modifyOrder.modifyPosition.ModifyQuantity.SlTpInfo';
        return dp;
    }

    public toRawValue_attention (): string {
        return '';
    }

    public validateParameters (): any {
        const updatedParamNameDict = {};

        if (this.sltp.validate(
            this.slBasePriceGetter.bind(this),
            this.tpBasePriceGetter.bind(this),
            this.getTradingData())) {
            updatedParamNameDict[OrderEditBaseUtils.SLTP_PARAM] = true;
        }

        return updatedParamNameDict;
    }

    // #region SLTP Price Comparers

    public override getBasePrice (): any {
        return this.position ? this.position.Price : null;
    };

    public sltpBasePriceGetter (isSL): number {
        const acc = this.account; const pos = this.position;
        const ins = this.instrument; const quote = this.quote;
        const isBuy = this.buy();

        return PositionEdit.SlTpPriceGetter(isSL, isBuy, pos, ins, acc, quote);
    }

    public slBasePriceGetter (): number {
        return this.sltpBasePriceGetter(true);
    }

    public tpBasePriceGetter (val = false): number {
    // todo modify screen must use parametr in future
    // https://tp.traderevolution.com/entity/114272
        return this.sltpBasePriceGetter(val);
    }

    // #endregion SLTP Price Comparers

    // TODO. UGLY. Refactor. details are at the top OrderEditBase.ts
    public setSLTP (sltpHolder): void {
        const dp = this.sltp.createDynPropertyForRawSLTP(
            sltpHolder,
            this.getTradingData(),
            this.getBasePrice.bind(this), //  #111068
            this.getBasePrice.bind(this));

        this.updateParameters(new OrderEditUpdateData({ sltp: dp }));
    }

    // TODO. Ugly.
    public setCanEditSLOrTrailingStop (canEdit): void {
        this.canEditSLOrTrailingStop = canEdit;
    }

    public setCanEditTP (canEdit): void {
        this.canEditTP = canEdit;
    }

    public override getConfirmationText (onlyParams = false): string {
        const confrimationText = this.sltp.getConfirmationText(this.getTradingData());
        if (onlyParams) {
            return confrimationText;
        } else {
            return OrderExecutorUtils.buildPositionEditConfirmationText(
                this.position,
                confrimationText
            );
        }
    }

    public oldAndNewSLdiffer (): boolean {
        if (!this.position?.SLOrder) return true;

        const slOrder = this.position.SLOrder;

        if (this.sltp.trailingStop.enabled || !this.sltp.trailingStop.enabled && slOrder.OrderType === OrderType.TrailingStop) {
            if (this.sltp.trailingStop.enabled && slOrder.OrderType === OrderType.TrailingStop) {
            // Need normalize calculation of this.sltp.trailingStop.value. it used not correct values, not enough parameters at PositionSLTPEdit.prototype.update
                const value = this.sltp.trailingStop.visibleValue || this.sltp.trailingStop.value;
                if (this.sltp.trailingStop.visibleValue) {
                    const newPice = this.instrument.RoundPriceToNearestPointSize(this.instrument.CalculatePrice(this.position.OpenPrice, (this.position.BuySell ? 1 : -1) * value));
                    return slOrder.Price != this.instrument.roundPrice(newPice);
                }
                return slOrder.TrStopOffset != value;
            } else {
                return true;
            } // slOrder.TrStopOffset != this.sltp.trailingStop.value     // есть tr.stop сравниваем только его
        } else
            if (slOrder.getPriceForStopLoss() == this.sltp.sl.value) {
                if (this.sltp.sl.sllValue !== null) {
                    return slOrder.Price != this.sltp.sl.sllValue;
                } else {
                    return false;
                } // sll нет, slPrice равны
            }

        return true;
    }

    public oldAndNewTPdiffer (): boolean {
        if (this.position?.TPOrder && this.position.TPOrder.Price == this.sltp.tp.value) {
            return false;
        }

        return true;
    }

    public override isPosition (): boolean {
        return true;
    }

    public static SlTpPriceGetter (isSL, isBuy, position, instrument, account, quote): number {
        let result = 0;
        let askOrBid = /* isSL ? */
            (isBuy ? 'BidSpread_SP_Ins' : 'AskSpread_SP_Ins'); // :
        // (isBuy ? 'AskSpread_SP_Ins' : 'BidSpread_SP_Ins')  // согласно последнему комменту аналитика в https://tp.traderevolution.com/entity/101728

        const short = SLTPTriggerUtils.GetShortByOrder(position);
        const bySLTPtrigger = SLTPEdit.GetStrByShort(short, isSL, isBuy); // picking trigger price #109798 docs(3.3.2) Trading logic)
        if (bySLTPtrigger) {
            askOrBid = bySLTPtrigger + 'Spread_SP_Ins';
        }

        if (quote) {
            result = quote[askOrBid](DataCache.GetSpreadPlan(account), instrument);
        }

        return result;
    }
}
