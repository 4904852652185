// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { MathUtils } from '../../Utils/MathUtils';
import { InstrumentTypes } from '../../Utils/Instruments/InstrumentTypes';
import { CustomEvent } from '../../Utils/CustomEvents';
import { ErrorInformationStorage } from '../ErrorInformationStorage';
import { SLTPTriggerUtils } from './OrderParams/SLTPTriggerUtils';
import { GeneralSettings } from '../../Utils/GeneralSettings/GeneralSettings';
import { Instrument } from './Instrument';
import { DataCache } from '../DataCache';
import { SettingsLoggingManager } from '../SettingsLoggingManager';

// #region InsDefSettingsStorage

// Storage of InsDefSettings instance.
export class InsDefSettingsStorage {
    public settings = new InsDefSettings();
    public DefaultSettingsChanged = new CustomEvent();

    public FireOnDefaultSettingsChanged (): void {
        this.DefaultSettingsChanged.Raise();
    }

    // Use this method to get actual instrument settings.
    public GetInstrumentSettings (instrument: Instrument | null) {
        if (!instrument) {
            return null;
        }

        return this.settings.GetOriginSettings(instrument);
    }

    // #region For Managing Default Settings

    public SetSettings (newSettings) {
        this.settings = newSettings;
        this.FireOnDefaultSettingsChanged();
    }

    public GetSettingsCopy () {
        return this.settings.Copy();
    }

    // #endregion

    // #region Serialization

    public ToXML () {
        return this.settings.ToXML();
    }

    public LoadFromXML (xml) {
        this.settings.LoadFromXML(xml);
        this.SetSettings(this.settings);
    }

    // #endregion

// #endregion
}

// #region InsDefSettings

export class InsDefSettings {
    instrumentsDefaults: any = {};

    constructor () {
        const insDefaults = {};

        const insTypeKey = InsDefSettings.INSTRUMENT_TYPE;
        const insTypes = InstrumentTypes;

        insDefaults[insTypeKey + insTypes.FOREX] = new InsDefItem(DefaultLots.Forex, DefaultLots.Forex[0], 2, insTypes.FOREX);
        insDefaults[insTypeKey + insTypes.EQUITIES] = new InsDefItem(DefaultLots.Equities, DefaultLots.Equities[0], 2, insTypes.EQUITIES);
        insDefaults[insTypeKey + insTypes.FUTURES] = new InsDefItem(DefaultLots.Futures, DefaultLots.Futures[0], 2, insTypes.FUTURES);
        insDefaults[insTypeKey + insTypes.OPTIONS] = new InsDefItem(DefaultLots.Options, DefaultLots.Options[0], 2, insTypes.OPTIONS);
        insDefaults[insTypeKey + insTypes.PORTFOLIO] = new InsDefItem(DefaultLots.Portfolio, DefaultLots.Portfolio[0], 2, insTypes.PORTFOLIO);
        insDefaults[insTypeKey + insTypes.EQUITIES_CFD] = new InsDefItem(DefaultLots.CFD, DefaultLots.CFD[0], 2, insTypes.EQUITIES_CFD);
        insDefaults[insTypeKey + insTypes.CRYPTO] = new InsDefItem(DefaultLots.Crypto, DefaultLots.Crypto[0], 8, insTypes.CRYPTO);
        insDefaults[insTypeKey + insTypes.SPREADBET] = new InsDefItem(DefaultLots.Spreadbet, 0.01, 2, insTypes.SPREADBET);
        insDefaults[insTypeKey + insTypes.BOND] = new InsDefItem(DefaultLots.Bond, DefaultLots.Bond[0], 2, insTypes.BOND);
        insDefaults[insTypeKey + insTypes.ETF] = new InsDefItem(DefaultLots.ETF, DefaultLots.ETF[0], 2, insTypes.ETF);
        insDefaults[insTypeKey + insTypes.TBILL] = new InsDefItem(DefaultLots.TBill, DefaultLots.TBill[0], 2, insTypes.TBILL);
        insDefaults[insTypeKey + insTypes.SPOT] = new InsDefItem(DefaultLots.Spot, DefaultLots.Spot[0], 2, insTypes.SPOT);
        insDefaults[insTypeKey + insTypes.FORWARD] = new InsDefItem(DefaultLots.Forward, DefaultLots.Forward[0], 2, insTypes.FORWARD);
        insDefaults[insTypeKey + insTypes.CORPORATE] = new InsDefItem(DefaultLots.Corporate, DefaultLots.Corporate[0], 0, insTypes.CORPORATE);
        this.instrumentsDefaults = insDefaults;
    }

    // Returns actual origin settings without any processing, copying etc.
    public GetOriginSettings (instrument: Instrument): InsDefItem | null {
        const key = InsDefSettings.GenerateKey(instrument);
        const type = instrument.CFD ? InstrumentTypes.EQUITIES_CFD : instrument.InstrType;

        return this.instrumentsDefaults[key] || this.GetTypeSettings(type);
    }

    // #region For Managing Default Settings

    public static GenerateKey (instrument: Instrument): string {
        return instrument.GetInteriorID();
    }

    public static UpdateSelectiveLots (forInstrument, insDefItem): void {
        if (!forInstrument) {
            return;
        }

        const lotStep = forInstrument.LotStep;
        const digits = forInstrument.getLotStepPrecision();

        const defaultLots = insDefItem.DefaultLots;
        const len = defaultLots.length;
        for (let i = 0; i < len; i++) {
            const lot = defaultLots[i];

            if (lotStep > 0) {
                lot.Increment = lotStep;
            }

            lot.Digits = digits;
        }
    }

    public GetInstrumentSettings (instrument): InsDefItem | null {
        const key = InsDefSettings.GenerateKey(instrument);

        return this.instrumentsDefaults[key] || null;
    }

    public GetTypeSettings (insType): InsDefItem | null {
        const key = InsDefSettings.INSTRUMENT_TYPE + insType;

        return this.instrumentsDefaults[key] || null;
    }

    public RemoveInstrumentSettings (instrument: Instrument): void {
        const key = InsDefSettings.GenerateKey(instrument);
        delete this.instrumentsDefaults[key];
    }

    public CreateInstrumentSettingsIfNotExist (instrument: Instrument): void {
        const key = InsDefSettings.GenerateKey(instrument);
        if (this.instrumentsDefaults[key]) {
            return;
        }

        // Generating default settings for instrument from its type.
        let defSettings = this.GetTypeSettings(instrument.CFD ? InstrumentTypes.EQUITIES_CFD : instrument.InstrType);
        defSettings.sltpTriggerShort = null;
        defSettings = defSettings.Copy();
        defSettings.InstrumentName = instrument.GetInteriorID();
        this.instrumentsDefaults[key] = defSettings;
        InsDefSettings.UpdateSelectiveLots(instrument, defSettings);
    }

    // #endregion

    // #region Serialization

    public ToXML (): string {
        return JSON.stringify(this.instrumentsDefaults);
    }

    public LoadFromXML (src): void {
        if (!src) return;

        let savedInstrJson = null;
        try {
            savedInstrJson = JSON.parse(src);
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
        }

        if (savedInstrJson) {
            const instrumentsDefaults = {};

            for (const key in savedInstrJson) {
                const insDefItemJson = savedInstrJson[key];
                const insDefaultItem = this.instrumentsDefaults[key];

                const defLotsArr = [];
                const defLotsJson = insDefItemJson.DefaultLots;
                const len = defLotsJson.length;
                for (let i = 0; i < len; i++) {
                    const defLotJson = defLotsJson[i];
                    const defLot = insDefaultItem ? insDefaultItem.DefaultLots[i] : null;
                    defLotsArr.push(new SelectiveLot(
                        defLotJson.Selected,
                        defLotJson.Lots,
                        defLot && defLot.Increment < defLotJson.Increment ? defLot.Increment : defLotJson.Increment,
                        defLotJson.Digits));
                }

                const insDefItem = new InsDefItem();

                insDefItem.DefaultLots = defLotsArr;

                insDefItem.InstrumentType = insDefItemJson.InstrumentType;
                insDefItem.SLDefaultOffsetTicksDecimal = insDefItemJson.SLDefaultOffsetTicksDecimal;
                insDefItem.TPDefaultOffsetTicksDecimal = insDefItemJson.TPDefaultOffsetTicksDecimal;
                insDefItem.UserQuantityCoef = insDefItemJson.UserQuantityCoef;
                insDefItem.PriceIncrementTicks = insDefItemJson.PriceIncrementTicks;
                insDefItem.InstrumentName = insDefItemJson.InstrumentName;

                if (insDefItemJson.sltpTriggerShort !== null) {
                    insDefItem.sltpTriggerShort = insDefItemJson.sltpTriggerShort;
                }

                instrumentsDefaults[key] = insDefItem;
            };

            this.instrumentsDefaults = instrumentsDefaults;
            this.FixNotLoadedFields();
        }
    }

    public FixNotLoadedFields (): void {
        const insDefaults = this.instrumentsDefaults;
        if (!insDefaults) {
            return;
        }

        const CRYPTO_CCY = InstrumentTypes.CRYPTO.toString();

        const insTypeKey = InsDefSettings.INSTRUMENT_TYPE;
        const insTypeKeyLen = insTypeKey.length;

        for (const key in insDefaults) {
            if (key.indexOf(insTypeKey) !== 0) {
                continue;
            }

            const insDefItem = insDefaults[key];
            // TODO.
            const insTypeId = key.slice(-(key.length - insTypeKeyLen));
            if (insTypeId) {
                const digits = insTypeId === CRYPTO_CCY ? 8 : 2;

                const defLots = insDefItem.DefaultLots;
                const defLotsLen = defLots.length;

                for (let i = 0; i < defLotsLen; i++) {
                    const lotItem = defLots[i];
                    if (lotItem.Digits <= 0) {
                        lotItem.Digits = digits;
                    }
                }
            }
        }
    }

    // #endregion

    public Copy (): InsDefSettings {
        const cpy = new InsDefSettings();
        const insDefaults = this.instrumentsDefaults;
        for (const key in insDefaults) {
            const insDefItem = insDefaults[key];
            cpy.instrumentsDefaults[key] = insDefItem.Copy();
        }
        return cpy;
    }

    get InstrumentsNames (): any {
        const resArr = [];
        const insDefaults = this.instrumentsDefaults;
        for (const key in insDefaults) {
            const insDefItem = insDefaults[key];
            const insName = insDefItem.InstrumentName;
            if (insName) {
                resArr.push(insName);
            }
        }
        return resArr;
    }

    public GetDiffer (insDefSettings, isSnapshot = false): any[] {
        let result = [];

        if (!insDefSettings) {
            return result;
        }

        const insDefaultsBase = this.instrumentsDefaults;
        const insDefaults = insDefSettings.instrumentsDefaults;
        for (const key in insDefaultsBase) {
            const insDefItem = insDefaultsBase[key];
            result = result.concat(insDefItem.GetDiffer(insDefaults[key], SettingsLoggingManager.ACTIONS.ADDED, isSnapshot));
        }

        for (const key in insDefaults) {
            if (!insDefaultsBase[key]) {
                const insDefItem = insDefaults[key];
                result = result.concat(insDefItem.GetDiffer(null, SettingsLoggingManager.ACTIONS.REMOVED, isSnapshot));
            }
        }

        return result;
    }

    public static INSTRUMENT_TYPE = 'instrument_type';

// #endregion
}

// #region InsDefItem

export class InsDefItem {
    public DefaultLots: any[];
    public InstrumentType: any;

    public SLDefaultOffsetTicksDecimal = 1;
    public TPDefaultOffsetTicksDecimal = 1;

    // public VisibleSLDefaultOffsetTicksDecimal = 1;
    // public VisibleTPDefaultOffsetTicksDecimal = 1;

    public UserQuantityCoef = 1;
    public PriceIncrementTicks = 1;

    public sltpTriggerShort: any = null; // #109798 число кодирующее состояние радиобатонов для тригеров в Types manager
    // Contains empty string if InsDefItem belongs to Instrument Type.
    public InstrumentName: any;

    constructor (defaultLots?, increment?, digits?, type?) {
        const DefaultLots = [];
        this.DefaultLots = DefaultLots;
        const len = defaultLots ? defaultLots.length : 0;
        for (let i = 0; i < len; i++) {
            const defLot = defaultLots[i];
            DefaultLots.push(new SelectiveLot(i < 5, defLot, increment, digits));
        }
        this.InstrumentType = type || 0;
    }

    public GetDiffer (insDefItem, action, isSnapshot = true): any[] {
        const added = !insDefItem;
        const CHANGED = SettingsLoggingManager.ACTIONS.CHANGED;
        action = added ? action : CHANGED;

        let res = [];
        const addedOrRemoved = action != CHANGED || isSnapshot;
        const ins = DataCache.getInstrument(this.InstrumentName);
        const entity = this.InstrumentName ? (ins ? ins.DisplayName() : this.InstrumentName) : Instrument.getTypeString(this.InstrumentType);

        const getProp = (key, val) => {
            return {
                localizationKey: key,
                value: val,
                action: isSnapshot ? SettingsLoggingManager.ACTIONS.ADDED : action,
                entity
            };
        };

        if (addedOrRemoved || this.SLDefaultOffsetTicksDecimal != insDefItem.SLDefaultOffsetTicksDecimal) {
            res.push(getProp('TypesManagerScreen.NumericLabel.SL default offset, ticks', this.SLDefaultOffsetTicksDecimal));
        }

        if (addedOrRemoved || this.TPDefaultOffsetTicksDecimal != insDefItem.TPDefaultOffsetTicksDecimal) {
            res.push(getProp('TypesManagerScreen.NumericLabel.TP default offset, ticks', this.TPDefaultOffsetTicksDecimal));
        }

        if (addedOrRemoved || this.UserQuantityCoef != insDefItem.UserQuantityCoef) {
            res.push(getProp('TypesManagerScreen.NumericLabel.User quantity coefficient', this.UserQuantityCoef));
        }

        if (addedOrRemoved || this.PriceIncrementTicks != insDefItem.PriceIncrementTicks) {
            res.push(getProp('TypesManagerScreen.NumericLabel.Price increment (arrow), ticks', this.PriceIncrementTicks));
        }

        const defLots = this.GetDefaultLotsString();
        if (addedOrRemoved || defLots != insDefItem.GetDefaultLotsString()) {
            res.push(getProp('TypesManagerScreen.DefaultLotsSetup', defLots));
        }

        if (addedOrRemoved || this.sltpTriggerShort != insDefItem.sltpTriggerShort) {
            res = res.concat(SLTPTriggerUtils.GetPropsForLogging(this.sltpTriggerShort, insDefItem, action, entity, isSnapshot));
        }

        return res;
    }

    public GetDefaultLotsString (): string {
        const dL = this.DefaultLots; let res = '';

        if (!dL) {
            return res;
        }

        for (let i = 0; i < dL.length; i++) {
            res += (res.length ? '; ' : '') + dL[i].Selected.toString() + '|' + dL[i].Lots;
        }

        return res;
    }

    get VisibleTPDefaultOffsetTicksDecimal (): number {
        if (this.InstrumentType === InstrumentTypes.FOREX && GeneralSettings.TradingDefaults.IsTicksFractionalForForex()) {
            return this.TPDefaultOffsetTicksDecimal / 10;
        } else {
            return this.TPDefaultOffsetTicksDecimal;
        }
    }

    set VisibleTPDefaultOffsetTicksDecimal (value) {
        if (this.InstrumentType === InstrumentTypes.FOREX && GeneralSettings.TradingDefaults.IsTicksFractionalForForex()) {
            this.TPDefaultOffsetTicksDecimal = value * 10;
        } else {
            this.TPDefaultOffsetTicksDecimal = value;
        }
    }

    get VisibleSLDefaultOffsetTicksDecimal (): number {
        if (this.InstrumentType === InstrumentTypes.FOREX && GeneralSettings.TradingDefaults.IsTicksFractionalForForex()) {
            return this.SLDefaultOffsetTicksDecimal / 10;
        } else {
            return this.SLDefaultOffsetTicksDecimal;
        }
    }

    set VisibleSLDefaultOffsetTicksDecimal (value) {
        if (this.InstrumentType === InstrumentTypes.FOREX && GeneralSettings.TradingDefaults.IsTicksFractionalForForex()) {
            this.SLDefaultOffsetTicksDecimal = value * 10;
        } else {
            this.SLDefaultOffsetTicksDecimal = value;
        }
    }

    public Copy (): InsDefItem {
        const cpy_InsDefItem = new InsDefItem();

        cpy_InsDefItem.SLDefaultOffsetTicksDecimal = this.SLDefaultOffsetTicksDecimal;
        cpy_InsDefItem.TPDefaultOffsetTicksDecimal = this.TPDefaultOffsetTicksDecimal;
        cpy_InsDefItem.UserQuantityCoef = this.UserQuantityCoef;
        cpy_InsDefItem.PriceIncrementTicks = this.PriceIncrementTicks;
        cpy_InsDefItem.InstrumentName = this.InstrumentName;
        cpy_InsDefItem.InstrumentType = this.InstrumentType;

        const cpy_defLots = [];
        cpy_InsDefItem.DefaultLots = cpy_defLots;

        const DefaultLots = this.DefaultLots;
        const len = DefaultLots.length;
        for (let i = 0; i < len; i++) {
            const defLot = DefaultLots[i];

            cpy_defLots.push(new SelectiveLot(
                defLot.Selected,
                defLot.Lots,
                defLot.Increment,
                defLot.Digits));
        }

        if (this.sltpTriggerShort !== null) { cpy_InsDefItem.sltpTriggerShort = this.sltpTriggerShort; }

        return cpy_InsDefItem;
    };
}

// #endregion

// #region SelectiveLot

class SelectiveLot {
    public Selected: any;
    public Lots: any;
    public Increment: any;
    public Digits: any;

    constructor (selected, lots, increment, digits) {
        this.Selected = selected === undefined ? false : selected;
        this.Lots = lots === undefined ? 0 : MathUtils.formatValueWithEps(lots);
        this.Increment = increment === undefined ? 0 : increment;
        this.Digits = digits === undefined ? 0 : digits;
    }
};

// #endregion

// #region DefaultLots

const DefaultLots =
{
    Forex: [0.01, 0.02, 0.03, 0.04, 0.05, 0.06, 0.07, 0.08, 0.09, 0.1],
    Crypto: [0.00000001, 0.00000002, 0.00000003, 0.00000004, 0.00000005, 0.00000006, 0.00000007, 0.00000008, 0.00000009, 0.0000001],
    CFD: [0.01, 0.02, 0.03, 0.04, 0.05, 0.06, 0.07, 0.08, 0.09, 0.1],
    Equities: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    Futures: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    Options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    Portfolio: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    Spreadbet: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    Bond: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    ETF: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    TBill: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    Spot: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    Forward: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    Corporate: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
};

// #endregion
