// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { MainWindowManager } from "../../UtilsClasses/MainWindowManager.ts";
import { PammBase } from "../../../Commons/cache/PammAccount.ts";
import { Resources } from "../../../Commons/properties/Resources.ts";
import { QuickTreeNode } from "../QuickTree/QuickTreeNode.ts";
import { TerceraQuickTree } from "../QuickTree/TerceraQuickTree.ts";
import { TerceraCheckBox } from "../TerceraCheckBox.js";
import { TerceraTextBox } from "../TerceraTextBox.js";
import { TerceraLookupDropDownForm } from "./TerceraLookupDropDownForm.js";
import { ThemeManager } from "../../misc/ThemeManager.ts";
import { RulesSet } from "../../../Utils/Rules/RulesSet.ts";
import { TerceraAccountLookupDropDownFormTemplate } from "../../../templates.js";
import { DataCache } from "../../../Commons/DataCache.ts";
export let TerceraAccountLookupDropDownForm = TerceraLookupDropDownForm.extend({
    partials: { bodyPartial: TerceraAccountLookupDropDownFormTemplate },
    iconUser: null,
    iconUserChecked: null,
    iconUserAccount: null,
    iconUserAccountChecked: null,
    iconUserLinkedAccounts: null,
    iconUserLinkedAccountsChecked: null,
    iconFundOpen: null,
    iconFundOpenChecked: null,
    iconFundClosed: null,
    iconFundClosedChecked: null,
    noRaiseSelectAll: false,
    modBtnText: 'Select'
});

TerceraAccountLookupDropDownForm.prototype.getType = function () { return 'TerceraAccountLookupDropDownForm' };

TerceraAccountLookupDropDownForm.prototype.oninit = function ()
{
    TerceraLookupDropDownForm.prototype.oninit.call(this);
    this.on("onCheckedStateChange", this.onCheckAllStateChange);
};

TerceraAccountLookupDropDownForm.prototype.initializationComponents = function ()
{
    this.textBox = this.Controls.textBox;
    this.chBoxSelectAll = this.Controls.chBoxSelectAll;

    let qtr = this.Controls.quickTree;
    if (qtr && qtr.quickTree)
        this.quickTree = qtr;
};

TerceraAccountLookupDropDownForm.ShowForm = async function (parametersStruct)
{
    if (MainWindowManager.TerceraAccountLookupDropDownForm == null)
    {
        MainWindowManager.TerceraAccountLookupDropDownForm = await TerceraAccountLookupDropDownForm.createInstance();
    }

    MainWindowManager.TerceraAccountLookupDropDownForm.showForm(parametersStruct);
};

TerceraAccountLookupDropDownForm.prototype.fillTree = function ()
{
    var iconUser = this.iconUser;
    var iconUserChecked = this.iconUserChecked;
    var iconUserAccount = this.iconUserAccount;
    var iconUserAccountChecked = this.iconUserAccountChecked;
    var iconUserLinkedAccounts = this.iconUserLinkedAccounts;
    var iconUserLinkedAccountsChecked = this.iconUserLinkedAccountsChecked;
    var iconFundOpen = this.iconFundOpen;
    var iconFundOpenChecked = this.iconFundOpenChecked;
    var iconFundClosed = this.iconFundClosed;
    var iconFundClosedChecked = this.iconFundClosedChecked;
    var iconUserGroup = this.iconUserGroup;
    var iconUserGroupChecked = this.iconUserGroupChecked;

    var items = this.items;
    var selectedItem = [];
    if (Array.isArray(this.selectedItem))
        selectedItem = this.selectedItem;
    else
        selectedItem.push(this.selectedItem);

    var selectedNodes = [];

    var nodeArr = [];
    var groupNodeDict = {};
    var userNodeDict = {};

    var loggedInUser = DataCache.UserLogin;

    var linkedAccountsNode = new QuickTreeNode(
        Resources.getResource("TerceraAccountLookup.Linked accounts"));
    linkedAccountsNode.collapsed = false;
    //#region Design Stuff
    linkedAccountsNode.defaultImage = iconUserLinkedAccounts;
    linkedAccountsNode.selectedImage = iconUserLinkedAccountsChecked;
    //#endregion

    var notLinkedFont = ThemeManager.CurrentTheme.quickTreeNodeFont.copy();
    notLinkedFont.FontWeight = 'bold';

    var isAdmin = RulesSet.TERMINAL_HTML;

    var len = items.length;
    for (var i = 0; i < len; i++)
    {
        var account = items[i];
        if (!this.showMAM && account.isMAM)
            continue;
        var userLogin = account.UserLogin;
        var groupName = account.UserGroupName;
        var accountToString = account.toString(true);

        // Filtering.
        if (this.currentNameFilter !== null)
        {
            var curItemFindStr = accountToString.toLowerCase();
            var filterString = this.currentNameFilter.toLowerCase();
            if (curItemFindStr.indexOf(filterString) === -1)
                continue;
        }

        var accountNode = new QuickTreeNode(accountToString);

        let isAccountItemAll = selectedItem[0] && selectedItem[0]._AccountItemAll
        if (selectedItem.indexOf(account) !== -1 || isAccountItemAll)
            selectedNodes.push(accountNode);

        accountNode.tag = account;
        accountNode.visible = !account.isHide;
        //#region Design Stuff
        var fundType = account.fundType;
        if (fundType === PammBase.FUND_TYPE_OPEN)
        {
            accountNode.defaultImage = iconFundOpen;
            accountNode.selectedImage = iconFundOpenChecked;
        }
        else if (fundType === PammBase.FUND_TYPE_CLOSE)
        {
            accountNode.defaultImage = iconFundClosed;
            accountNode.selectedImage = iconFundClosedChecked;
        }
        else
        {
            accountNode.defaultImage = iconUserAccount;
            accountNode.selectedImage = iconUserAccountChecked;
        }
        if (!account.isLinked && userLogin === loggedInUser)
        {
            accountNode.font = notLinkedFont;
            accountNode.fontColor = ThemeManager.CurrentTheme.TreeSelection_Fill;
        }
        //#endregion

        var groupNode = null;
        if (isAdmin && groupName)
        {
            groupNode = groupNodeDict[groupName];
            if (!groupNode)
            {
                groupNode = new QuickTreeNode(groupName);
                groupNode.collapsed = false;
                groupNode.defaultImage = iconUserGroup;
                groupNode.selectedImage = iconUserGroupChecked;

                groupNodeDict[groupName] = groupNode;
                nodeArr.push(groupNode);
            }
        }

        if (account.isLinked)
        {
            linkedAccountsNode.AddChildNode(accountNode);
        }
        else if (userLogin)
        {
            var userNode = userNodeDict[userLogin];
            if (!userNode)
            {
                userNode = new QuickTreeNode(userLogin);
                userNode.collapsed = false;

                userNodeDict[userLogin] = userNode;
                if (groupNode)
                    groupNode.AddChildNode(userNode);
                else
                    nodeArr.push(userNode);

                //#region Design Stuff
                if (userLogin === loggedInUser)
                {
                    userNode.font = notLinkedFont;
                    userNode.fontColor = ThemeManager.CurrentTheme.TreeSelection_Fill;
                    userNode.defaultImage = iconUser;
                    userNode.selectedImage = iconUserChecked;
                }
                //#endregion
            }

            userNode.AddChildNode(accountNode);
        }
        else
        {
            nodeArr.push(accountNode);
        }
    }

    TerceraAccountLookupDropDownForm.sortNodes(nodeArr);

    if (linkedAccountsNode.childNodes.length)
        nodeArr.push(linkedAccountsNode);

    this.quickTree.quickTree.Clear();
    if (nodeArr.length === 1)
        this.quickTree.quickTree.AddNodeRange(nodeArr[0].childNodes);
    else
        this.quickTree.quickTree.AddNodeRange(nodeArr);

    let ims = this.quickTree.quickTree.isMultiSelectCheckboxUsing;
    for (var i = 0; i < selectedNodes.length; i++)
        if (ims)
            this.quickTree.quickTree.setCheckedNode(selectedNodes[i], true);
        else
            this.quickTree.quickTree.setSelectedNode(selectedNodes[i], true);

    this.quickTree.quickTree.goToSelectedNode();
    this.quickTree.setSizes();
};

TerceraAccountLookupDropDownForm.sortNodes = function (nodes)
{
    if (!nodes) return;

    nodes.sort(TerceraAccountLookupDropDownForm.sortItems);

    var len = nodes.length;
    for (var i = 0; i < len; i++)
        TerceraAccountLookupDropDownForm.sortNodes(nodes[i].childNodes);
};

TerceraAccountLookupDropDownForm.sortItems = function (i1, i2)
{
    var acc1 = i1.tag;
    var acc2 = i2.tag;

    if (!acc1 || !acc2)
        return i1.nodeText.toLowerCase().localeCompare(i2.nodeText.toLowerCase());

    if (acc1.isLinked && !acc2.isLinked)
        return 1;
    else if (!acc1.isLinked && acc2.isLinked)
        return -1;
    else
        return acc1.FullAccString.toLowerCase().localeCompare(acc2.FullAccString.toLowerCase());
};

TerceraAccountLookupDropDownForm.prototype.localize = function ()
{
    TerceraLookupDropDownForm.prototype.localize.call(this);
    this.modBtnText = Resources.getResource('TerceraAccountLookup.Select');
};

TerceraAccountLookupDropDownForm.prototype.onCheckAllStateChange = function (sender, value)
{
    if (this.noRaiseSelectAll)
    {
        this.noRaiseSelectAll = false
        return
    }

    if (this.quickTree)
        if (this.quickTree.quickTree.isMultiSelectCheckboxUsing)
            this.quickTree.quickTree.CheckUnCheckAllMultiCheckbox(value);
        else
            this.quickTree.quickTree.CheckUnCheckAll(value);
};

TerceraAccountLookupDropDownForm.createInstance = async function ()
{
    var lookup = new TerceraAccountLookupDropDownForm();
    lookup.setBounds(0, 0, 320, 350);
    lookup.set('visible', false);
    const currentMainWindow = MainWindowManager.MainWindow;
    currentMainWindow.addControl(lookup);

    lookup.observe('isMultiSelect', function (newVal)
    {
        this.set({ isShowFooterBtn: newVal }).then(() =>
        {
            this.resizeOnFooterChangeVisibility();
        });

        //Part of dark magic
        if (this.quickTree && this.quickTree.quickTree)
            this.quickTree.quickTree.isMultiSelectCheckboxUsing = newVal;
        if (this.caller)
        {
            this.caller.isMultiSelect = newVal;
            this.caller.items = this.caller.multiSelectDropDownItems
            this.items = this.caller.multiSelectDropDownItems
        }
        if (this.items)
            this.fillTree()
    });

    return lookup.OnCompliteWaiter;
};

TerceraAccountLookupDropDownForm.prototype.resizeOnFooterChangeVisibility = function ()
{
    if (!this.quickTree)
        return

    this.quickTree.resetSizes().then(() => { this.quickTree.setSizes() });
};

TerceraAccountLookupDropDownForm.prototype.selectionChanged = function ()
{
    TerceraLookupDropDownForm.prototype.selectionChanged.call(this);

    let qt = this.quickTree.quickTree

    if (!this.items || !qt)
        return

    let selectedNum = Object.keys(qt.selectedNodesDict).length,
        selectedAll = selectedNum === this.items.length;

    let selectAllCB = this.chBoxSelectAll
    if (selectAllCB)
    {
        if (this.get('checkedSelectedAll') !== selectedAll)  // select all cb checked state changed
            this.noRaiseSelectAll = true

        this.set({ checkedSelectedAll: selectedAll });
    }
};

TerceraAccountLookupDropDownForm.prototype.themeChange = function ()
{
    this.iconUser = ThemeManager.CurrentTheme.iconUser;
    this.iconUserChecked = ThemeManager.CurrentTheme.iconUserChecked;
    this.iconUserAccount = ThemeManager.CurrentTheme.iconUserAccount;
    this.iconUserAccountChecked = ThemeManager.CurrentTheme.iconUserAccountChecked;
    this.iconUserLinkedAccounts = ThemeManager.CurrentTheme.iconUserLinkedAccounts;
    this.iconUserLinkedAccountsChecked = ThemeManager.CurrentTheme.iconUserLinkedAccountsChecked;
    this.iconFundOpen = ThemeManager.CurrentTheme.iconFundOpen;
    this.iconFundOpenChecked = ThemeManager.CurrentTheme.iconFundOpenChecked;
    this.iconFundClosed = ThemeManager.CurrentTheme.iconFundClosed;
    this.iconFundClosedChecked = ThemeManager.CurrentTheme.iconFundClosedChecked;
    this.iconUserGroup = ThemeManager.CurrentTheme.iconUserGroup;
    this.iconUserGroupChecked = ThemeManager.CurrentTheme.iconUserGroupChecked;
};