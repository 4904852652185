// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

/// <summary>
/// Буфер маркеров для серий индикаторов
/// </summary>
export class MarkersVector {
    public markers: Record<number, any> = {};// new Dictionary<int, Color>();
    public counter = 0;

    /// <summary>
    /// Добавление пустого маркера в буфер
    /// </summary>
    public Add (): void {
        this.counter++;
    }

    /// <summary>
    /// Очистка буфера
    /// </summary>
    public Clear (): void {
        this.markers = {};
        // подумать
        this.counter = 0;
    }

    public SetMarker (offset: number, color): void {
        this.markers[offset] = color;
    }

    public RemoveMarker (offset: number): void {
        this.markers[offset] = null;
    }

    public ClearMarkers (): void {
        this.markers = {};
        // подумать
        this.counter = 0;
    }

    public getColor (index: number): any {
        const color = this.markers[index];
        if (color) {
            return color;
        } else {
            return null;
        }
    }

    get Count (): number { return this.counter; }
}
