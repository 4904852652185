// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DataCacheToolType } from '../DataCacheToolEnums';
import { BaseFibonacciDataCacheTool } from './BaseFibonacciDataCacheTool';

export class FibonacciTimeZoneDataCacheTool extends BaseFibonacciDataCacheTool {
    /**
     * Constructor for FibonacciTimeZoneDataCacheTool
     */
    constructor () {
        super();
        this.ToolType = DataCacheToolType.FibonacciTimeZone;
        this.InitPoints(1);
    }

    /**
     * Initializes the block for the tool
     */
    InitBlock (): void {
        super.InitBlock();
        this.fibLevel = [0, 1, 2, 3, 5, 8, 13, 21, 34];
    }

    /**
     * Gets the maximum points count required for the tool
     * @returns {number} - Maximum points count
     */
    MaxPointsCount (): number {
        return 1;
    }

    /**
     * Gets the minimum points count required for the tool
     * @returns {number} - Minimum points count
     */
    MinPointsCount (): number {
        return 1;
    }
}
