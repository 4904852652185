// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PFixField } from './PFixField';

export class PFixDerivativeContractDateField extends PFixField {
    constructor (fieldId, buffer?) {
        super(fieldId, buffer);

        this.Value = 0;
    }

    public static typeMask = 127; // typeMask = Convert.ToInt32("01111111", 2);
    public static dayMask = 31; // dayMask = Convert.ToInt32("00011111", 2);
    public static monthMask = 15; // monthMask = Convert.ToInt32("00001111", 2);

    public override ReadValue (buffer): void {
        const packed = buffer.ReadInt32();
        const year = (packed >> 16);

        const type = ((packed >> 9) & PFixDerivativeContractDateField.typeMask);
        const day = ((packed >> 4) & PFixDerivativeContractDateField.dayMask);
        const month = (packed & PFixDerivativeContractDateField.monthMask);

        this.Value = new DerivativeContractDate(year, type, day, month);
    }

    public override Write (buffer): void {
        const value = this.Value;
        if (value != null) {
            buffer.Write(this.FieldId);
            buffer.Write(value.Year << 16 | value.Type << 9 | value.Day << 4 | value.Month);
        }
    }
}

// #region Utils
class DerivativeContractDate {
    public Year: any;
    public Type: any;
    public Day: any;
    public Month: any;

    constructor (year, type, day, month) {
        this.Year = year;
        this.Type = type;
        this.Day = day;
        this.Month = month;
    }

    public toString (): string {
        return 'Y: ' + this.Year + '; T: ' + this.Type + '; D: ' + this.Day + '; M: ' + this.Month;
    }
}
// #endregion
