// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { AlertUtils } from "../../../Utils/Alert/AlertUtils.ts";
import { AlertAction } from "../../../Utils/Alert/AlertConstants.ts";
import { CreateAlertActionSelectorTemplate } from "../../../templates.js";
import { ContainerControl } from "../../elements/ContainerControl.js";
import { Resources } from "../../../Commons/properties/Resources.ts";

export let CreateAlertActionSelector = ContainerControl.extend({
    template: CreateAlertActionSelectorTemplate,
    data: function ()
    {
        return {
            label: '',
            instrument: null,

            items: null,
            selectedItem: null,
            defaultValue: null,
            visible: true,

            width: 198
        }
    }
})

CreateAlertActionSelector.prototype.getType = function () { return 'CreateAlertActionSelector' };

CreateAlertActionSelector.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.call(this)

    this.repopulate();
    this.observe('selectedItem', this.updateAlertAction);
    this.observe('value', this.updateAlertActionValue);

    Resources.onLocaleChange.Subscribe(this.localize, this);
    this.localize();
}

CreateAlertActionSelector.prototype.updateAlertAction = function (selectedItem)
{
    if (selectedItem)
        this.set({ value: selectedItem.value })
}

CreateAlertActionSelector.prototype.updateAlertActionValue = function (newV, oldV)
{
    let items = this.get("items")
    if (!items)
        return;

    for (let i = 0; i < items.length; i++)
        if (items[i].value === newV)
        {
            this.set({ selectedItem: items[i] })
            break;
        }
}

CreateAlertActionSelector.prototype.dispose = function ()
{
    Resources.onLocaleChange.UnSubscribe(this.localize, this)

    ContainerControl.prototype.dispose.call(this)
}

CreateAlertActionSelector.prototype.repopulate = function ()
{
    let item = AlertUtils.GetAlertActionItems();
    this.set({ items: item, selectedItem: item[AlertAction.NOTIFY], visible: item.length > 1 });
}

CreateAlertActionSelector.prototype.localize = function ()
{
    this.set({
        label: Resources.getResource('screen.Alerts.Label.Action'),
    });
}