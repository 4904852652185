// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type Instrument } from '../../Commons/cache/Instrument';
import { type IHistoryBuisnesObject } from '../../Commons/cache/interface/IHistoryBuisnesObject';
import { Resources } from '../../Commons/properties/Resources';
import { OperationType } from '../Trading/OperationType';
import { InstrumentTypes } from './InstrumentTypes';
import { QuotingType } from './QuotingType';

export class BoughtSoldUtils {
    private static IsLong (item: IHistoryBuisnesObject): boolean {
        return item.BuySell === OperationType.Buy;
    }

    public static Bought (item: IHistoryBuisnesObject): number {
        const instrument = item.Instrument;
        if (instrument !== null) {
            const isLong = this.IsLong(item);
            const quantity = item.getAmount();
            const price = item.Price;
            return this.CalculateBoughtSold(isLong, quantity, price, instrument, true);
        } else {
            return 0;
        }
    }

    public static BoughtStr (item: IHistoryBuisnesObject): string {
        const instrument: Instrument | null | undefined = item.Instrument;
        if (item.Price === 0 || instrument === null || instrument === undefined) {
            return item.boughtString ?? '-';
        }

        const value = this.Bought(item);
        const isLong = this.IsLong(item);
        return this.FormatTextBoughtSold(instrument, value, isLong, true);
    }

    public static Sold (item: IHistoryBuisnesObject): number {
        const instrument: Instrument | null | undefined = item.Instrument;
        if (instrument !== null && instrument !== undefined) {
            const isLong = this.IsLong(item);
            const quantity = item.getAmount();
            const price = item.Price;
            return this.CalculateBoughtSold(isLong, quantity, price, instrument, false);
        } else {
            return 0;
        }
    }

    public static SoldStr (item: IHistoryBuisnesObject): string {
        const instrument: Instrument | null | undefined = item.Instrument;
        if (item.Price === 0 || instrument === null || instrument === undefined) {
            return item.soldString ?? '-';
        }

        const value = this.Sold(item);
        const isLong = this.IsLong(item);
        return this.FormatTextBoughtSold(instrument, value, isLong, false);
    }

    private static FormatTextBoughtSold (instrument: Instrument, value: number, isLong: boolean, isBought: boolean): string {
        const exp1Asset = instrument.DataCache.GetAssetByName(instrument.Exp1);
        const exp2Asset = instrument.DataCache.GetAssetByName(instrument.Exp2);

        if ((isLong && isBought) || (!isLong && !isBought)) {
            if (instrument.isFutureOrOption() ||
                instrument.InstrType === InstrumentTypes.FUTURES ||
                instrument.InstrType === InstrumentTypes.CFD_FUTURES ||
                instrument.InstrType === InstrumentTypes.OPTIONS) {
                return value.toString() + ' ' + Resources.getResource('Instrument.Futures.contract');
            } else if (instrument.InstrType === InstrumentTypes.SPREADBET) {
                return value.toString() + ' ' + Resources.getResource('Instrument.Spreadbet.bet');
            } else if (instrument.InstrType === InstrumentTypes.EQUITIES || instrument.InstrType === InstrumentTypes.ETF) {
                return value.toString() + ' ' + instrument.ShortName;
            } else if (instrument.InstrType === InstrumentTypes.CORPORATE) {
                return value.toString() + ' ' + Resources.getResource('Instrument.Corporate.Securities');
            } else {
                return exp1Asset !== null ? exp1Asset.formatPrice(value) : (value.toString() + ' ' + instrument.Exp1);
            }
        } else if ((!isLong && isBought) || (isLong && !isBought)) {
            return exp2Asset !== null ? exp2Asset.formatPrice(value) : (value.toString() + ' ' + instrument.Exp2);
        }
    }

    private static CalculateBoughtSold (isLong: boolean, quantity: number, price: number, instrument: Instrument, isBought: boolean): number {
        if ((isLong && isBought) || (!isLong && !isBought)) {
            if (this.IS_FOREX_CRYPTO_EQUITIES_EQUITIESCFD(instrument)) {
                return quantity * this.GetLotSizeQuotingTypeBase(instrument);
            }

            if (this.IS_FUTURES_OPTIONS_CFDFUTURES_SPREADBET(instrument)) {
                return quantity;
            }
        } else if ((!isLong && isBought) || (isLong && !isBought)) {
            if (this.IS_FOREX_CRYPTO_EQUITIES_EQUITIESCFD(instrument)) {
                return quantity * price * this.GetLotSizeQuotingTypeBase(instrument);
            }

            if (this.IS_FUTURES_OPTIONS_CFDFUTURES_SPREADBET(instrument)) {
                return quantity * price * this.GetLotSizeQuotingTypeBase(instrument, true);
            }
        }

        return quantity;
    }

    private static GetLotSizeQuotingTypeBase (instrument: Instrument, tickCost: boolean = false): number {
        return instrument.QuotingType === QuotingType.LotSize ? instrument.LotSize : (tickCost ? instrument.GetTickCost() : 1);
    }

    private static IS_FUTURES_OPTIONS_CFDFUTURES_SPREADBET (instrument: Instrument): boolean {
        return instrument.InstrType === InstrumentTypes.FUTURES ||
        instrument.InstrType === InstrumentTypes.OPTIONS ||
        instrument.InstrType === InstrumentTypes.CFD_FUTURES ||
        instrument.InstrType === InstrumentTypes.SPREADBET;
    }

    private static IS_FOREX_CRYPTO_EQUITIES_EQUITIESCFD (instrument: Instrument): boolean {
        return instrument.InstrType === InstrumentTypes.FOREX ||
        instrument.InstrType === InstrumentTypes.CRYPTO ||
        instrument.InstrType === InstrumentTypes.EQUITIES ||
        instrument.InstrType === InstrumentTypes.EQUITIES_CFD ||
        instrument.InstrType === InstrumentTypes.CORPORATE;
    }
}
