// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Connection } from "../../../Commons/Connection.ts"
import { ConnectionStates } from "../../../Commons/ConnectionEnums.ts"
import { Resources } from "../../../Commons/properties/Resources.ts"
import { TerceraLookupDropDownForm } from "./TerceraLookupDropDownForm.js"
import { MainWindowManager } from "../../UtilsClasses/MainWindowManager.ts"
import { TerceraTextBox } from "../TerceraTextBox.js"
import { TerceraMultiComboBox } from "../TerceraMultiComboBox.js"
import { TerceraQuickTree } from "../QuickTree/TerceraQuickTree.ts"
import { TerceraButton } from "../TerceraButton.ts"
import { TerceraCheckBox } from "../TerceraCheckBox.js"
import { InstrumentLookupManager } from "./InstrumentLookupManager.js"
import { InstrumentTypes } from "../../../Utils/Instruments/InstrumentTypes.ts"

import { BOTTOM_WINDOWS_MARGIN, TOP_WINDOWS_MARGIN } from "../../UtilsClasses/SizeConstants.ts"
import { ThemeManager } from "../../misc/ThemeManager.ts"
import { Instrument } from "../../../Commons/cache/Instrument.ts"
import { TerceraInstrumentLookupDropDownFormTemplate } from "../../../templates.js"
import { InstrumentUtils } from "../../../Utils/Instruments/InstrumentUtils.ts"
import { DataCache } from "../../../Commons/DataCache.ts"
import { InstrumentLookupComparer } from "../../../Utils/Instruments/InstrumentLookupComparer.ts"
import { InstrumentLookupInstrumentType } from "../../../Utils/Instruments/InstrumentLookupInstrumentType.ts"

export let TerceraInstrumentLookupDropDownForm = TerceraLookupDropDownForm.extend({
    partials: { bodyPartial: TerceraInstrumentLookupDropDownFormTemplate },
    types: null,
    exchanges: null
});

TerceraInstrumentLookupDropDownForm.prototype.getType = function ()
{
    return 'TerceraInstrumentLookupDropDownForm'
}

TerceraInstrumentLookupDropDownForm.prototype.initializationComponents = function ()
{
    this.textBox = this.Controls.textBox;
    this.exchanges = this.Controls.exchanges;
    this.types = this.Controls.types;
    this.collapseAllBtn = this.Controls.collapseAllBtn;
    this.expandExchangesBtn = this.Controls.expandExchangesBtn;
    this.expandTypesBtn = this.Controls.expandTypesBtn;

    let qtr = this.Controls.quickTree;
    if (qtr && qtr.quickTree)
    {
        this.quickTree = qtr;
        qtr.quickTree.rowHeigth = 35;
        qtr.quickTree.ClearColor = "#262c3b";
        qtr.quickTree.reInitScrollHeigth();
    }
};

TerceraInstrumentLookupDropDownForm.expandNodes = function (nodes, expandNodeLevel, currentNodeLevel)
{
    if (!nodes)
        return

    let len = nodes.length
    if (!len)
        return

    currentNodeLevel = currentNodeLevel === undefined ? 0 : currentNodeLevel

    let collapse = currentNodeLevel > expandNodeLevel
    let nextNodeLevel = currentNodeLevel + 1

    for (let i = 0; i < len; i++)
    {
        let node = nodes[i]
        if (node.childNodes.length)
            node.collapsed = collapse
        TerceraInstrumentLookupDropDownForm.expandNodes(node.childNodes, expandNodeLevel, nextNodeLevel)
    }
}

TerceraInstrumentLookupDropDownForm.prototype.collapseAll = function ()
{
    let qt = this.quickTree.quickTree
    let nodeCollection = qt.nodeCollection
    TerceraInstrumentLookupDropDownForm.expandNodes(nodeCollection, 0, 1)
    qt.Draw(true)
}

TerceraInstrumentLookupDropDownForm.prototype.clickExchange = function (bool)
{
    let type = TerceraInstrumentLookupDropDownForm.NodeType.InstrumentType;
    if (bool)
        type = TerceraInstrumentLookupDropDownForm.NodeType.Exchange;

    this.expandNodesByType(type);
}

TerceraInstrumentLookupDropDownForm.prototype.expandNodesByType = function (type)
{
    let qt = this.quickTree.quickTree
    let nodeCollection = qt.nodeCollection

    if (!this.needShowExchanges())  // https://tp.traderevolution.com/entity/84756 (3rd issue)
        type = type == TerceraInstrumentLookupDropDownForm.NodeType.Exchange
            ? TerceraInstrumentLookupDropDownForm.NodeType.InstrumentType
            : TerceraInstrumentLookupDropDownForm.NodeType.InstrumentGroup

    let level = TerceraInstrumentLookupDropDownForm.findNodeTypeTreeLevel(nodeCollection, type)

    if (level === -1)
        return

    TerceraInstrumentLookupDropDownForm.expandNodes(nodeCollection, level)
    qt.Draw(true)
}

TerceraInstrumentLookupDropDownForm.NodeType =
{
    Exchange: 0,
    InstrumentType: 1,
    InstrumentGroup: 2,
    Instrument: 3,
    Other: 4
}

TerceraInstrumentLookupDropDownForm.getNodeType = function (node)
{
    if (!node)
        return null

    let NodeType = TerceraInstrumentLookupDropDownForm.NodeType
    let tag = node.tag

    if (tag === 'exchange')
        return NodeType.Exchange

    if (typeof tag === 'number')
        return NodeType.InstrumentType

    if (tag === 'group')
        return NodeType.InstrumentGroup

    if (tag instanceof Instrument)
        return NodeType.Instrument

    return NodeType.Other
}

// TODO. Optimize if necessary.
TerceraInstrumentLookupDropDownForm.findNodeTypeTreeLevel = function (
    nodeArray, nodeType, currentNodeLevel)
{
    if (!nodeArray || !nodeArray.length)
        return -1

    currentNodeLevel = currentNodeLevel === undefined ? 0 : currentNodeLevel

    // TODO. Remove loop?
    for (let i = 0, len = nodeArray.length; i < len; i++)
    {
        let node = nodeArray[i]
        if (TerceraInstrumentLookupDropDownForm.getNodeType(node) === nodeType)
            return currentNodeLevel

        let foundNodeLevel = TerceraInstrumentLookupDropDownForm.findNodeTypeTreeLevel(
            node.childNodes, nodeType, currentNodeLevel + 1)

        if (foundNodeLevel !== -1)
            return foundNodeLevel
    }

    return -1
}

TerceraInstrumentLookupDropDownForm.prototype.selectFirstTopNode = function ()
{
    var qt = this.quickTree.quickTree
    var plainArray = qt.plainArray
    if (plainArray.length)
    {
        qt.CheckUnCheckAll(false);
        qt.setSelectedNode(plainArray[0])
    }
}

TerceraInstrumentLookupDropDownForm.prototype.arrangeControls = function ()
{
    let exchangesCB = this.exchanges;
    let showExchanges = this.needShowExchanges();
    exchangesCB.set('visible', showExchanges);

    let qTree = this.quickTree;
    qTree.setSizes();
};

TerceraInstrumentLookupDropDownForm.prototype.needShowExchanges = function ()
{
    let exchanges = this.exchanges
    if (!exchanges) return false

    let items = exchanges.get('items')
    return !!(items && items.length > 1)
}

TerceraInstrumentLookupDropDownForm.prototype.needShowTypes = function ()
{
    var types = this.types
    if (!types) return false

    let items = types.get('items')
    return !!(items && items.length > 1)
}

TerceraInstrumentLookupDropDownForm.prototype.getInstrumentFromSelectedNode = function ()
{
    var selectedNode = this.quickTree.quickTree.selectedNode;
    var tag = selectedNode ? selectedNode.tag : null;
    return tag instanceof Instrument ? tag : null;
};

TerceraInstrumentLookupDropDownForm.createInstance = function ()
{
    let lookup = new TerceraInstrumentLookupDropDownForm();
    lookup.setBounds(0, 0, 535, 580);
    lookup.set('visible', false);
    MainWindowManager.MainWindow.addControl(lookup);

    Connection.onConnectStateChange.Subscribe(
        lookup.onConnectionStateChange,
        lookup);

    return lookup.OnCompliteWaiter;
};

TerceraInstrumentLookupDropDownForm.ShowForm = function (parametersStruct)
{
    let instanceWaiter = null
    if (!MainWindowManager.TerceraInstrumentLookupDropDownForm)
        instanceWaiter = TerceraInstrumentLookupDropDownForm.createInstance().then((instance) =>
        {
            MainWindowManager.TerceraInstrumentLookupDropDownForm = instance
            return instance;
        })
    else
        instanceWaiter = Promise.resolve(MainWindowManager.TerceraInstrumentLookupDropDownForm)

    // TODO. Ugly. Refactor.
    // Resetting stuff.
    // These setters don't invoke fillTree() due to unsubscribing before.
    // Thus, combo boxes will be populated only once.
    instanceWaiter.then((instance) =>
    {
        instance.unsubscribeCBValueChanged()
        instance.types.set('items', null)
        instance.exchanges.set('items', null)
        instance.currentNameFilter = instance.oldNameFilter = null

        instance.showForm(parametersStruct)
    })
}

TerceraInstrumentLookupDropDownForm.prototype.subscribeCBValueChanged = function ()
{
    let typesCB = this.types
    let exchangesCB = this.exchanges

    let boundFillTree = this.fillTree.bind(this)

    this.typesCBValueChangeHandler =
        typesCB.on(TerceraMultiComboBox.Events.ValueChange, boundFillTree)

    this.exchangesCBValueChangeHandler =
        exchangesCB.on(TerceraMultiComboBox.Events.ValueChange, boundFillTree)
}

TerceraInstrumentLookupDropDownForm.prototype.unsubscribeCBValueChanged = function ()
{
    if (this.typesCBValueChangeHandler)
    {
        this.typesCBValueChangeHandler.cancel()
        this.typesCBValueChangeHandler = null
    }

    if (this.exchangesCBValueChangeHandler)
    {
        this.exchangesCBValueChangeHandler.cancel()
        this.exchangesCBValueChangeHandler = null
    }
}

TerceraInstrumentLookupDropDownForm.prototype.onConnectionStateChange = function ()
{
    var connectionStates = ConnectionStates;
    var connectionState = Connection.connectionState;
    if (connectionState === connectionStates.DISCONNECTED ||
        connectionState === connectionStates.CONNECTION_LOST)
    {
        MainWindowManager.TerceraInstrumentLookupDropDownForm = null;

        Connection.onConnectStateChange.UnSubscribe(
            this.onConnectionStateChange,
            this);

        this.dispose();
    }
};

// TODO. UGLY. Refactor.
TerceraInstrumentLookupDropDownForm.prototype.fillTree = function (updateCB)
{
    this.unsubscribeCBValueChanged()

    let exchangeFilterSet = this.exchangeFilterSet || this.getCurrentExchangeFilterSet(updateCB)
    let typeFilterSet = this.getCurrentTypeFilterSet()

    let out_availableExchangesSet = {}
    let out_allTypesDict = {}

    this.items = InstrumentLookupComparer.sortInstrumentList(this.items, this.currentNameFilter || '')

    InstrumentLookupManager.fillTree({
        items: this.items,
        selectedItem: this.selectedItem,
        selectedInstrumentType: null,
        curTypesFilter: typeFilterSet,
        curExchangeFilter: exchangeFilterSet,
        currentNameFilter: this.currentNameFilter,
        isInstrumentsDefaultsMode: false,
        existingInstrumentTypes: null,
        quickTree: this.quickTree.quickTree,
        out_allExchanges: out_availableExchangesSet,
        out_allTypes: out_allTypesDict,
        dataProvider: this.dataProvider
    })

    // Populating once after showing form.
    this.populateExchangesComboBox(this.exchangeFilterSet || out_availableExchangesSet)
    this.populateTypesComboBox(out_allTypesDict)

    this.subscribeCBValueChanged()

    this.arrangeControls()
}


//#region TODO. Refactoring.

// TODO. UGLY. Refactor. Optimize.
TerceraInstrumentLookupDropDownForm.prototype.getCurrentExchangeFilterSet = function (updateCB)
{
    let resSet = TerceraInstrumentLookupDropDownForm.getFilterSet(this.exchanges)
    let newNFRuleset = {};

    // if (DataCache.NonFixedList)
    newNFRuleset = TerceraInstrumentLookupDropDownForm.getNonFixedListExchangeIdSet();

    if (!resSet)
        return newNFRuleset

    let obkNF = Object.keys(newNFRuleset)
    let obkCB = Object.keys(resSet)
    if (resSet._InternalHasUnchecked && obkNF.length !== obkCB.length)
        for (let i = 0; i < obkNF.length; i++)
            newNFRuleset[obkNF[i]] = !!resSet[obkNF[i]];

    return newNFRuleset;
}

// TODO. UGLY. Refactor. Optimize.
TerceraInstrumentLookupDropDownForm.prototype.getCurrentTypeFilterSet = function ()
{
    let resSet = TerceraInstrumentLookupDropDownForm.getFilterSet(this.types)
    if (resSet)
        return resSet

    return DataCache.NonFixedList
        ? InstrumentLookupInstrumentType.getNonFixedListTypeSet()
        : null
}

TerceraInstrumentLookupDropDownForm.prototype.getCurrentTypesFilter = function ()
{
    let set = this.getCurrentTypeFilterSet(),
        filterTypes = [],
        instrumentTypes = [
            InstrumentTypes.EQUITIES_CFD,
            InstrumentTypes.FUTURES,
            InstrumentTypes.FOREX,
            InstrumentTypes.INDICIES,
            InstrumentTypes.CRYPTO,
            InstrumentTypes.SPREADBET,
            InstrumentTypes.EQUITIES,
            InstrumentTypes.CFD_FUTURES,
            InstrumentTypes.BOND,
            InstrumentTypes.ETF,
            InstrumentTypes.TBILL,
            InstrumentTypes.SPOT,
            InstrumentTypes.OPTIONS,
            InstrumentTypes.FORWARD,
            InstrumentTypes.CORPORATE
        ];

    for (let i = 0; i < instrumentTypes.length; i++)
        if (set[instrumentTypes[i]])
            filterTypes.push(instrumentTypes[i])

    return filterTypes
}

TerceraInstrumentLookupDropDownForm.prototype.populateExchangesComboBox = function (availableExchangesSet)
{
    let exchangesCB = this.exchanges
    if (!exchangesCB)
        return

    let oldItemsFilterSet = TerceraInstrumentLookupDropDownForm.getFilterSet(exchangesCB)

    let exchangeSet =
        DataCache.NonFixedList
            ? TerceraInstrumentLookupDropDownForm.getNonFixedListExchangeIdSet()
            : availableExchangesSet

    exchangeSet = this.exchangeFilterSet || exchangeSet;
    let newItems = []
    for (let key in exchangeSet)
    {
        let exchangeName = key;

        let itemText = key;

        newItems.push({
            value: exchangeName,
            text: itemText,
            checked:
                oldItemsFilterSet &&
                    oldItemsFilterSet.hasOwnProperty(exchangeName)
                    ? oldItemsFilterSet[exchangeName]
                    : true
        })
    }

    newItems.sort(TerceraInstrumentLookupDropDownForm.sortFilterItems)

    exchangesCB.set('items', newItems)
}

TerceraInstrumentLookupDropDownForm.prototype.populateTypesComboBox = function (availableTypesDict)
{
    let typesCB = this.types
    if (!typesCB)
        return

    let oldItemsFilterSet = TerceraInstrumentLookupDropDownForm.getFilterSet(typesCB)

    let typeSet =
        DataCache.NonFixedList
            ? InstrumentLookupInstrumentType.getNonFixedListTypeSet()
            : availableTypesDict

    let newItems = []
    for (let key in typeSet)
    {
        let insType = parseInt(key)
        newItems.push({
            value: insType,
            text: InstrumentUtils.getInstrumentTypeStringLocalized(insType, false),
            checked:
                oldItemsFilterSet &&
                    oldItemsFilterSet.hasOwnProperty(insType)
                    ? oldItemsFilterSet[insType]
                    : true
        })
    }

    newItems.sort(TerceraInstrumentLookupDropDownForm.sortFilterItems)

    typesCB.set('items', newItems)
}

// TODO.
TerceraInstrumentLookupDropDownForm.tryUpdateTypesComboBoxCounts = function (typesCB, typesInfoDict)
{
    if (DataCache.NonFixedList)
        return;

    var items = typesCB.get('items');
    if (!items) return;

    var len = items.length;
    for (var i = 0; i < len; i++)
    {
        var item = items[i];
        var insType = item.value;

        var visibleCount = 0;
        var totalCount = 0;

        var insTypeInfo = typesInfoDict[insType];
        if (insTypeInfo)
        {
            visibleCount = insTypeInfo.visibleCount;
            totalCount = insTypeInfo.totalCount;
        }

        var countStr = ' (' + visibleCount;

        if (totalCount !== visibleCount)
            countStr += '/' + totalCount;

        countStr += ')';

        item.text =
            InstrumentUtils.getInstrumentTypeStringLocalized(item.value, false) +
            countStr;
    };

    typesCB.update('items');
};

//#endregion

TerceraInstrumentLookupDropDownForm.getNonFixedListExchangeIdSet = function ()
{
    let exchanges = DataCache.TradingExchanges;
    if (TerceraInstrumentLookupDropDownForm.nonFixedListExchangesSet &&
        exchanges.length === Object.keys(TerceraInstrumentLookupDropDownForm.nonFixedListExchangesSet))
        return TerceraInstrumentLookupDropDownForm.nonFixedListExchangesSet

    let resSet = {}
    for (let i = 0; i < exchanges.length; i++)
    {
        let ex = exchanges[i];
        if (ex)
            resSet[ex] = true;
    }
    TerceraInstrumentLookupDropDownForm.nonFixedListExchangesSet =
        Object.keys(resSet).length
            ? resSet
            : null

    return TerceraInstrumentLookupDropDownForm.nonFixedListExchangesSet
}
TerceraInstrumentLookupDropDownForm.nonFixedListExchangesSet = null

TerceraInstrumentLookupDropDownForm.getFilterSet = function (comboBox)
{
    if (!comboBox)
        return null

    let items = comboBox.get('items')
    if (!items || !items.length)
        return null

    let filterSet = {}

    let _InternalHasUnchecked = true

    for (let i = 0, len = items.length; i < len; i++)
    {
        let item = items[i]
        filterSet[item.value] = item.checked
        _InternalHasUnchecked &= item.checked;
    }

    filterSet._InternalHasUnchecked = !_InternalHasUnchecked;

    return filterSet
}

TerceraInstrumentLookupDropDownForm.prototype.populateAsync = function ()
{
    clearTimeout(this.asyncPopulateInterval)
    this.asyncPopulateInterval = setTimeout(this.beforePopulate.bind(this), 300)
}

TerceraInstrumentLookupDropDownForm.prototype.beforePopulate = function (list)
{
    if (this.currentNameFilter === this.oldNameFilter)
    {
        if (list)
            this.items = list;

        this.fillTree(true)
        return
    }

    let me = this;
    this.oldNameFilter = this.currentNameFilter;

    if (this.dataProvider && this.currentNameFilter)
    {
        let typeFilterSet = this.getCurrentTypesFilter()
        this.dataProvider.getInstrumentsList(this.currentNameFilter, null, typeFilterSet, true)
            .then(function (instruments)
            {
                me.beforePopulate(instruments);
            });
    }
    else if (this.cachedItems)
    {
        this.items = this.cachedItems;
        this.fillTree();
    }

};

TerceraInstrumentLookupDropDownForm.prototype.themeChange = function ()
{
    TerceraLookupDropDownForm.prototype.themeChange.apply(this);

    var theme = ThemeManager;

    var collapseAllBtn = this.collapseAllBtn;
    if (collapseAllBtn)
        collapseAllBtn.set('terceraButtonStyle', "js-AllSymbol-Icon");

    var expandExchangesBtn = this.expandExchangesBtn;
    if (expandExchangesBtn)
        expandExchangesBtn.set('terceraButtonStyle', "js-NodeExchanges-Icon");

    var expandTypesBtn = this.expandTypesBtn;
    if (expandTypesBtn)
        expandTypesBtn.set('terceraButtonStyle', "js-NodeTypes-Icon");
};

TerceraInstrumentLookupDropDownForm.prototype.localize = function ()
{
    TerceraLookupDropDownForm.prototype.localize.apply(this);

    var collapseAllBtn = this.collapseAllBtn;
    if (collapseAllBtn)
        collapseAllBtn.set('tooltip',
            Resources.getResource('panel.TerceraSymbolLookupDropDownForm.collapceAllButton'));

    var expandExchangesBtn = this.expandExchangesBtn,
        expandExchangesTooltip = this.needShowExchanges() ? Resources.getResource('panel.TerceraSymbolLookupDropDownForm.expandExchangesButton')
            : Resources.getResource('panel.TerceraSymbolLookupDropDownForm.expandTypesButton') //#85255   
    if (expandExchangesBtn)
        expandExchangesBtn.set('tooltip', expandExchangesTooltip);

    var expandTypesBtn = this.expandTypesBtn,
        expandTypesTooltip = this.needShowExchanges() ? Resources.getResource('panel.TerceraSymbolLookupDropDownForm.expandTypesButton')
            : Resources.getResource('panel.TerceraSymbolLookupDropDownForm.expandGroupsButton') //#85255
    if (expandTypesBtn)
        expandTypesBtn.set('tooltip', expandTypesTooltip);

    var exchangesCB = this.exchanges;
    if (exchangesCB)
    {
        exchangesCB.set({
            allItemsSelectedText: Resources.getResource('panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.All exchanges'),
            noItemsSelectedText: Resources.getResource('panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.No exchanges'),
            fewItemsSelectedPostfixText: Resources.getResource('panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.exchanges')
        });
    }
    var typesCB = this.types;
    if (typesCB)
    {
        typesCB.set({
            allItemsSelectedText: Resources.getResource('panel.TerceraSymbolLookupDropDownForm.typeComboBox.All types'),
            noItemsSelectedText: Resources.getResource('panel.TerceraSymbolLookupDropDownForm.typeComboBox.No types'),
            fewItemsSelectedPostfixText: Resources.getResource('panel.TerceraSymbolLookupDropDownForm.typeComboBox.types')
        });
    }
};

TerceraInstrumentLookupDropDownForm.prototype.isButtonAddEnable = function ()
{
    let qt = this.quickTree.quickTree;
    const result = TerceraLookupDropDownForm.prototype.isButtonAddEnable.apply(this);
    if (this.get('isMultiSelect'))
    {
        let hasNonNullInstrumentTradableID = Object.values(qt.selectedNodesDict).some(
            (ins) => !isNullOrUndefined(ins.tag?.InstrumentTradableID)
        );
    
        return result && hasNonNullInstrumentTradableID;
    }
    else {
        return result && !isNullOrUndefined(qt.selectedNode?.tag?.InstrumentTradableID);
    }
};

TerceraInstrumentLookupDropDownForm.sortFilterItems = function (i1, i2)
{
    return i1.text.toLowerCase().localeCompare(i2.text.toLowerCase());
};