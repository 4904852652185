// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../../Commons/properties/Resources.ts"
import { TerceraButton } from "../../elements/TerceraButton.ts"
import { OperationType } from "../../../Utils/Trading/OperationType.ts"
import { TerceraButtonStyle } from "../../../Utils/Enums/ButtonEnums.ts";

export let OEPlaceOrderButton = TerceraButton.extend({
    data: function ()
    {
        return {
            width: 296,
            height: 25,

            side: null,
            textLocalizationKey: '',
            tradingAllowed: false,
            tradingForbiddenReason: ''
        }
    }
})

OEPlaceOrderButton.prototype.oninit = function ()
{
    TerceraButton.prototype.oninit.call(this)

    this.observe('textLocalizationKey', this.localize)
    this.observe('side', this.onSideChanged)
    this.observe('tradingAllowed tradingForbiddenReason', this.onTradingRulesChanged)

    Resources.onLocaleChange.Subscribe(this.localize, this)
    this.localize()
}

OEPlaceOrderButton.prototype.dispose = function ()
{
    TerceraButton.prototype.dispose.call(this)
    Resources.onLocaleChange.UnSubscribe(this.localize, this)
}

OEPlaceOrderButton.prototype.localize = function ()
{
    const textLocalizationKey = this.get('textLocalizationKey');
    if (isValidString(textLocalizationKey))
        this.set('text', Resources.getResource(textLocalizationKey));
    else
        this.set('text', Resources.getResource('panel.newOrderEntry.placeOrderButton'));
}

OEPlaceOrderButton.prototype.onSideChanged = function (side)
{
    this.set('terceraButtonStyle',
        side === OperationType.Buy
            ? TerceraButtonStyle.Buy
            : TerceraButtonStyle.Sell
    )
}

OEPlaceOrderButton.prototype.onTradingRulesChanged = function ()
{
    let tradingAllowed = this.get('tradingAllowed')
    let tradingForbiddenReason = this.get('tradingForbiddenReason')

    this.set({
        enabled: tradingAllowed,
        tooltip:
            tradingAllowed || !tradingForbiddenReason
                ? ''
                : tradingForbiddenReason
    })
}