// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../Commons/properties/Resources';
import { LinkedSystem } from '../misc/LinkedSystem';
import { StatementItem } from '../cache/StatementItem';
import { ColouringModes } from '../elements/QuickTable/QuickTableColumn';
import { ExportScreen } from '../screen/ExportScreen';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { ApplicationPanelNew } from './ApplicationPanelNew';
import { type RangeSelectPanel } from './RangeSelectPanel';
import { GeneralSettings } from '../../Utils/GeneralSettings/GeneralSettings';
import { AllowedReport } from '../../Commons/cache/AllowedReport';
import { DataCache } from '../../Commons/DataCache';
import { SessionSettings } from '../../Commons/SessionSettings';
import { StatementPanelTemplate } from '../../templates';
import { type QuickTable } from '../elements/QuickTable/QuickTable';

export class StatementPanel extends ApplicationPanelNew.extend({
    Name: 'StatementPanel',
    partials: { bodyPartial: StatementPanelTemplate },
    headerLocaleKey: 'panel.statement',

    data: function () {
        return {
            isAccountLinkShow: true,
            isSymbolLinkShow: false,
            isDataExist: true
        };
    }
}) {
    Controls: any;
    rangeSelectPanel: RangeSelectPanel;
    selectedStartTime: number | null = null;
    selectedFinishTime: number | null = null;
    displayAmountInLots: boolean;

    getType (): string { return PanelNames.StatementPanel; }

    oncomplete (): void {
        super.oncomplete();

        this.addRangeSelectPanel();

        const noDataLabel = this.Controls.noDataLabel;
        noDataLabel.set({
            text: Resources.getResource('panel.statement.noData')
        });

        this.populateTableContextMenu();

        this.displayAmountInLots = GeneralSettings.View.displayAmountInLots();
        GeneralSettings.SettingsChanged.Subscribe(this.settingsChanged, this);

        super.getQuickTable()?.setGroupByColumnIdx(StatementItem.POSITION_ID_COL_INDEX);
    }

    addRangeSelectPanel (): void {
        const topPanel = this.Controls.statementPanelRangeSelect;

        topPanel.set({
            refreshBtnTT: 'panel.statement.searchBtn.tt'
        });
        topPanel.getReportEvent.Subscribe(this.getStatementReport, this);
        topPanel.on('refreshClick', this.refreshStatementReport.bind(this));
        topPanel.updateSelectedRange();
        this.rangeSelectPanel = topPanel;
    }

    dispose (): void {
        this.rangeSelectPanel.getReportEvent.UnSubscribe(this.getStatementReport, this);

        GeneralSettings.SettingsChanged.UnSubscribe(this.settingsChanged, this);

        super.dispose();
    }

    refreshStatementReport (): void {
        this.getStatementReport(this.selectedStartTime, this.selectedFinishTime);
    }

    populateTableContextMenu (): void {
        const items = [];

        if (!Resources.isHidden('screen.export.visibility')) {
            items.push(
                {
                    text: Resources.getResource('screen.export.contextMenu'),
                    event: ExportScreen.show.bind(null, this)
                }
            );
        }

        if (!Resources.isHidden('panel.statement.print')) {
            items.push(
                {
                    text: Resources.getResource('screen.reports.print'),
                    event: super.printTable.bind(this)
                }
            );
        }

        if (!Resources.isHidden('contextMenu.Totals.visibility')) {
            items.push(
                {
                    text: Resources.getResource('panel.positions.menu.View'),
                    tag: 'View',
                    enabled: true,
                    subitems: [
                        {
                            text: Resources.getResource('panel.positions.menu.ShowTotals'),
                            tag: 'ShowTotals',
                            checked: false,
                            enabled: true,
                            canCheck: true,
                            event: super.ShowTotalsStateChange.bind(this)
                        }
                    ]
                }
            );
        }

        items.push({ separator: true });
        super.getQuickTable()?.setTableContextMenuItems(items);
    }

    getStatementReport (startTime: number, finishTime: number): void {
        this.selectedStartTime = startTime;
        this.selectedFinishTime = finishTime;

        void super.set('loading', true);

        DataCache.StatementReportCompleted = false;
        void DataCache.getReport(AllowedReport.REPORT_STATEMENT, startTime.toString(), finishTime.toString()).then((statements) => {
            const qt = super.getQuickTable();
            if (qt == null) return;

            qt.ClearRows();
            const len = statements.length;
            for (let i = 0; i < len; i++) { qt.AddItem(new StatementItem(statements[i], SessionSettings)); }

            void super.set({ isDataExist: len > 0 });

            if (LinkedSystem.accLinkingActive === true) { qt.filterByAccountNumber(LinkedSystem.getAccount()); }
        }).finally(() => {
            void super.set('loading', false);
        });
    }

    settingsChanged (): void {
        const newSettingsInLots = GeneralSettings.View.displayAmountInLots();
        if (this.displayAmountInLots === newSettingsInLots) { return; }

        this.displayAmountInLots = newSettingsInLots;
        this.refreshStatementReport();
    }

    SetColumnsColouringMode (table: QuickTable): void {
        super.ProcessSetColumnsColouringMode(table, [5], ColouringModes.Signed);
    }
}
