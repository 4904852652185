// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type CustodialData } from '../Instruments/CustodialData';
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectCustodialPlanMessage extends DirectMessageBaseClass {
    public Id = 0;
    public Name: string | null = null;
    public Data: CustodialData[] = [];
    constructor () {
        super(Message.CODE_PFIX_CUSTODIAL_PLAN_MESSAGE);
    }
}
