// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { ChangeTradingPasswordMessage } from '../Messages/ChangeTradingPasswordMessage';
import { MessageProcessorBase } from './MessageProcessorBase';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class ChangeTradingPasswordMessageProcessor extends MessageProcessorBase {
    public decoder: any;

    constructor (parentDecoder?) {
        super();

        this.decoder = parentDecoder;
    }

    public override decode (fieldSet: PFixFieldSet): ChangeTradingPasswordMessage[] {
        const message = new ChangeTradingPasswordMessage(fieldSet);
        return [message];
    }
}
