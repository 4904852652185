// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Rectangle } from '../Commons/Geometry';
import { Cursors } from '../Commons/Cursors';
import { LayersEnum, type TerceraChartBaseRenderer } from './Renderers/TerceraChartBaseRenderer';
import { Pen, SolidBrush } from '../Commons/Graphics';
import { TerceraChartScrollerRenderer } from './Renderers/TerceraChartScrollerRenderer';
import { type TerceraChartZoomRenderer } from './Renderers/TerceraChartZoomRenderer';
import { CustomEvent } from '../Utils/CustomEvents';
import { ThemeManager } from '../Controls/misc/ThemeManager';
import { type TerceraChartBaseScaleRenderer } from './Renderers/Scales/TerceraChartBaseScaleRenderer';
import { type TerceraChartWindowBase } from './Windows/TerceraChartWindowBase';
import { type TerceraChartAdvancedParams } from './Utils/TerceraChartAdvancedParams';

export class TerceraChartWindowsContainer {
    // #region Properties
    public Rectangle = new Rectangle();
    public Renderers: TerceraChartBaseRenderer[] = [];
    public Windows: TerceraChartWindowBase[] = [];
    public headerBackBrush: any = null;
    // #endregion

    public lastTotalH = 0;
    public lastTotalKoef = 0;

    public crosshairHeight = 0;

    public resizeWindows = false;
    public lastMouseMoveLocation: any = null;
    public resizeWinNumber = -1;

    public scrollerRenderer: TerceraChartScrollerRenderer;
    public xScaleRenderer: TerceraChartBaseScaleRenderer;
    public zoomRenderer: TerceraChartZoomRenderer;

    public PriceScaleAxisPen: any = null;
    public IndicatorBackBrush: any = null;

    public collapserWindowImage: any = null;
    public expanderWindowImage: any = null;

    public AfterResizeWindows = new CustomEvent();
    public collapserWindowImageHover: any;
    public expanderWindowImageHover: any;

    constructor (scrollerRenderer: TerceraChartScrollerRenderer, xScaleRenderer: TerceraChartBaseScaleRenderer, zoomRenderer: TerceraChartZoomRenderer) {
        this.scrollerRenderer = scrollerRenderer;
        this.Renderers.push(this.scrollerRenderer);
        this.xScaleRenderer = xScaleRenderer;
        this.Renderers.push(this.xScaleRenderer);
        this.zoomRenderer = zoomRenderer;
        this.Renderers.push(this.zoomRenderer);
        this.ThemeChanged();
    }

    public ThemeChanged (): void {
        this.headerBackBrush = new SolidBrush(ThemeManager.CurrentTheme.Chart_WindowContainerHeaderBackColor);
        this.PriceScaleAxisPen = new Pen(ThemeManager.CurrentTheme.Chart_PriceScaleAxisColor, 1);
        this.IndicatorBackBrush = new SolidBrush(ThemeManager.CurrentTheme.Chart_IndicatorBack);

        this.collapserWindowImage = ThemeManager.CurrentTheme.arrowUpDefColaps;
        this.expanderWindowImage = ThemeManager.CurrentTheme.arrowDownDefColaps;
        this.collapserWindowImageHover = ThemeManager.CurrentTheme.arrowUpHoverColaps;
        this.expanderWindowImageHover = ThemeManager.CurrentTheme.arrowDownHoverColaps;
    }

    public Localize (): void {
        const wcRenderers = this.Renderers;
        let len = wcRenderers.length;
        for (let i = 0; i < len; i++) {
            const wcRenderer = wcRenderers[i];
            if (wcRenderer.Localize) {
                wcRenderer.Localize();
            }
        }

        const windows = this.Windows;
        len = windows.length;
        for (let i = 0; i < len; i++) {
            const allRenderers = windows[i].GetAllRenderers();
            const r_len = allRenderers.length;
            for (let j = 0; j < r_len; j++) {
                const renderer = allRenderers[j];
                if (renderer.Localize) {
                    renderer.Localize();
                }
            }
        }
    }

    // Отрисовка контейнера: внутренние рендереры + рендереры окон
    // gr - canvas context.
    public Draw (gr: CanvasRenderingContext2D, advParams: TerceraChartAdvancedParams): void {
        const Renderers = this.Renderers;
        const Windows = this.Windows;
        const mainWindow = advParams.TerceraChart.mainWindow;

        const curLayer = advParams.layerId;
        // First draw container renderes
        const renderersLen = Renderers.length;
        for (let i = 0; i < renderersLen; i++) {
            const curRend = Renderers[i];
            if (curRend.IsNeedDraw(curLayer)) {
                curRend.Draw(gr, mainWindow, this, advParams);
            }
        }

        // Then windows
        const windowsLen = Windows.length;
        for (let i = 0; i < windowsLen; i++) {
            const ww = Windows[i];
            if (ww.HeaderVisible && curLayer === LayersEnum.Main) {
                let windowHeaderRectangle = new Rectangle();
                let collapserRectangle = new Rectangle();
                const result = ww.GetWindowHeaderRectangle(windowHeaderRectangle, collapserRectangle);

                windowHeaderRectangle = result.WindowHeaderRectangle;
                collapserRectangle = result.collapserRectangle;
                // gr.DrawRect(this.PriceScaleAxisPen, windowHeaderRectangle.X, windowHeaderRectangle.Y, windowHeaderRectangle.Width, windowHeaderRectangle.Height);

                if (!ww.IsMainWindow) // у главного окна немного другое поведение
                {
                    gr.FillRect(this.IndicatorBackBrush, windowHeaderRectangle.X, windowHeaderRectangle.Y, windowHeaderRectangle.Width, windowHeaderRectangle.Height);
                    const img = ww.Collapsed ? this.collapserWindowImage : this.expanderWindowImage;
                    if (img) {
                        gr.drawImage(img, collapserRectangle.X, collapserRectangle.Y + 7);
                    }
                }
            }
            ww.Draw(gr, this, advParams);
        }
    }

    // Определить расположение окон
    public LayoutWindows (): void {
        const TerceraChartZoomRendererZOOM_WIDTH = 49;

        // scroller
        if (this.scrollerRenderer) {
            this.scrollerRenderer.Rectangle.X = 0;
            this.scrollerRenderer.Rectangle.Y = this.Rectangle.Height - TerceraChartScrollerRenderer.SCROLLER_HEIGHT;
            this.scrollerRenderer.Rectangle.Width = this.Rectangle.Width - TerceraChartZoomRendererZOOM_WIDTH;
            this.scrollerRenderer.Rectangle.Height = TerceraChartScrollerRenderer.SCROLLER_HEIGHT;
            this.scrollerRenderer.Visible = true;
        }

        // zoom
        if (this.zoomRenderer) {
            this.zoomRenderer.Rectangle.X = this.Rectangle.Width - TerceraChartZoomRendererZOOM_WIDTH;
            this.zoomRenderer.Rectangle.Y = this.Rectangle.Height - TerceraChartScrollerRenderer.SCROLLER_HEIGHT;
            this.zoomRenderer.Rectangle.Width = TerceraChartZoomRendererZOOM_WIDTH;
            this.zoomRenderer.Rectangle.Height = TerceraChartScrollerRenderer.SCROLLER_HEIGHT;
            this.zoomRenderer.Visible = this.scrollerRenderer.Visible;
        }

        // time scale
        if (this.xScaleRenderer) {
            this.xScaleRenderer.Rectangle.X = 0;
            this.xScaleRenderer.Rectangle.Y = this.Rectangle.Height -
                (this.scrollerRenderer.Visible ? this.scrollerRenderer.Rectangle.Height : 0) -
                // - timeScaleRenderer.TIMESCALE_HEIGHT;
                this.xScaleRenderer.GetPreferredHeight();
            this.xScaleRenderer.Rectangle.Width = this.Rectangle.Width;
            this.xScaleRenderer.Rectangle.Height = this.xScaleRenderer.GetPreferredHeight();// timeScaleRenderer.TIMESCALE_HEIGHT;
        }

        const totalH = this.Rectangle.Height - (this.scrollerRenderer.Visible ? this.scrollerRenderer.Rectangle.Height : 0) - this.xScaleRenderer.Rectangle.Height;
        this.ResizeWindows(totalH);
        this.crosshairHeight = totalH;
    }

    public CalculateMinMax (): void {
        const Windows = this.Windows;
        const len = Windows.length;
        for (let i = 0; i < len; i++) {
            Windows[i].CalculateMinMax();
        }
    }

    public ResizeWindows (totalH): void {
        const Windows = this.Windows;
        const Rectangle = this.Rectangle;

        // сначала считаем справедливые коэф. независимо от видимости
        let totalHKoef = 0;

        const w_len = Windows.length;

        for (let i = 0; i < w_len; i++) {
            totalHKoef += Windows[i].HeightKoef;
        }

        // затем с учетом видимости пересчитаем коэф.
        let totalHKoefVisible = 0;

        for (let i = 0; i < w_len; i++) {
            const ww = Windows[i];

            // значит на хэдер нужно место, зарезервируем
            if (ww.HeaderVisible) {
                totalH -= ww.HeaderHeight;
            }

            if (!ww.Collapsed) {
                totalHKoefVisible += ww.HeightKoef;
            }
        }

        let curY = 0;
        const AlfaVisible = totalHKoefVisible / totalH;

        for (let i = 0; i < w_len; i++) {
            const ww = Windows[i];
            const wRect = ww.Rectangle;

            wRect.X = 0;
            wRect.Y = curY;
            wRect.Width = Rectangle.Width;
            wRect.Height =
                (!ww.Collapsed ? Math.round(ww.HeightKoef / AlfaVisible) : 0) + // не коллапсед
                (ww.HeaderVisible ? ww.HeaderHeight : 0); // и добавим место на хэдер

            curY += wRect.Height;

            ww.OnLayout();
        }

        this.lastTotalH = totalH;
        this.lastTotalKoef = totalHKoefVisible;

        if (this.AfterResizeWindows != null) {
            this.AfterResizeWindows.Raise();
        }
    }

    // #region Process mouse events

    public ProcessMouseMove (e): boolean {
        const Renderers = this.Renderers;
        const r_len = Renderers.length;

        const Windows = this.Windows;
        const ww_len = Windows.length;

        let resizeWindows = this.resizeWindows;
        const resizeWinNumber = this.resizeWinNumber;

        const lastTotalH = this.lastTotalH;
        const lastMouseMoveLocation = this.lastMouseMoveLocation;
        const lastTotalKoef = this.lastTotalKoef;

        try {
            let res = false;

            for (let i = 0; i < r_len; i++) {
                res = Renderers[i].ProcessMouseMove(e);
                if (res) {
                    return res;
                }
            }

            for (let i = 0; i < ww_len; i++) {
                const windowRenderers = Windows[i].GetAllRenderers();
                const wr_len = windowRenderers.length;
                for (let wr_i = 0; wr_i < wr_len; wr_i++) {
                    res = windowRenderers[wr_i].ProcessMouseMove(e);
                    if (res) {
                        return res;
                    }
                }

                // +++ resize windows
                if (resizeWindows && ww_len > 1 && resizeWinNumber > 0) {
                    // delta absolut
                    const yDeltaPy = e.Location.Y - lastMouseMoveLocation.Y;
                    const deltaKoef = (lastTotalKoef * (yDeltaPy / lastTotalH)) / (ww_len);

                    // search uncollapsed windows to resize them
                    let lastWin = resizeWinNumber;
                    let firstWin = resizeWinNumber - 1;

                    while (ww_len > lastWin && Windows[lastWin].Collapsed) {
                        lastWin++;
                    }

                    while (Windows[firstWin].Collapsed) {
                        firstWin--;
                    }

                    if (lastWin === ww_len || lastWin === firstWin) {
                        return true;
                    }

                    // resize 1
                    const lastWinWindow = Windows[lastWin];

                    lastWinWindow.HeightKoef -= deltaKoef;
                    if (lastWinWindow.HeightKoef < 10) {
                        // коллапсим окно
                        lastWinWindow.HeightKoef = 10;
                        lastWinWindow.Collapsed = true;
                    }

                    // resize 2
                    const firstWinWindow = Windows[firstWin];

                    firstWinWindow.HeightKoef += deltaKoef;
                    if (firstWinWindow.HeightKoef < 10) {
                        if (!firstWinWindow.IsMainWindow) {
                            // коллапсим окно
                            firstWinWindow.HeightKoef = 10;
                            firstWinWindow.Collapsed = true;
                        } else {
                            // усли ктото удумал сжать мейн окно меньше минимума откатываем последний ресайз и убираем изменение размеров
                            lastWinWindow.HeightKoef += deltaKoef;
                            firstWinWindow.HeightKoef -= deltaKoef;

                            resizeWindows = false;
                            this.resizeWindows = resizeWindows;
                        }
                    }

                    this.LayoutWindows();

                    e.NeedRedraw = true;
                }
            }
        } finally {
            this.lastMouseMoveLocation = e.Location;
        }

        return false;
    }

    public ProcessMouseDown (e): boolean {
        const Renderers = this.Renderers;
        const r_len = Renderers.length;

        const Windows = this.Windows;
        const ww_len = Windows.length;

        let res = false;

        for (let i = 0; i < r_len; i++) {
            res = Renderers[i].ProcessMouseDown(e);
            if (res) return res;
        }

        for (let i = 0; i < ww_len; i++) {
            const ww = Windows[i];

            const windowRenderers = ww.GetAllRenderers();
            const wr_len = windowRenderers.length;
            for (let wr_i = 0; wr_i < wr_len; wr_i++) {
                res = windowRenderers[wr_i].ProcessMouseDown(e);
                if (res) return res;
            }

            // для мейн никаких ресайзов, коллапсеров
            if (!ww.IsMainWindow) {
                let topWindowPaddingRectangle = new Rectangle();
                let collapserRectangle = new Rectangle();

                const result = ww.GetWindowHeaderRectangle(topWindowPaddingRectangle, collapserRectangle);
                topWindowPaddingRectangle = result.WindowHeaderRectangle;
                collapserRectangle = result.collapserRectangle;
                // if (collapserRectangle.Contains(e.Location.X, e.Location.Y))
                // {

                //     ww.Collapsed = !ww.Collapsed;
                //     this.LayoutWindows();
                //     ww.UpdateClientRectangle();

                // } else
                if (topWindowPaddingRectangle.Contains(e.Location.X, e.Location.Y) ||
                    Math.abs(e.Location.Y - topWindowPaddingRectangle.Y) <= 4) {
                    this.lastMouseMoveLocation = e.Location;
                    this.resizeWindows = true;
                    this.resizeWinNumber = i;

                    return true;
                }
            }
        }

        return res;
    }

    public ProcessMouseUp (e): boolean {
        let res = false;

        this.resizeWindows = false;

        const Renderers = this.Renderers;
        const r_len = Renderers.length;

        const Windows = this.Windows;
        const ww_len = Windows.length;

        // Container renderes
        for (let i = 0; i < r_len; i++) {
            res = Renderers[i].ProcessMouseUp(e);
            if (res) return res;
        }

        for (let i = 0; i < ww_len; i++) {
            const ww = Windows[i];
            const windowRenderers = ww.GetAllRenderers();
            const wr_len = windowRenderers.length;
            for (let wr_i = 0; wr_i < wr_len; wr_i++) {
                res = windowRenderers[wr_i].ProcessMouseUp(e);
                if (res) return res;
            }

            if (!ww.IsMainWindow) {
                let topWindowPaddingRectangle = new Rectangle();
                let collapserRectangle = new Rectangle();

                const result = ww.GetWindowHeaderRectangle(topWindowPaddingRectangle, collapserRectangle);
                topWindowPaddingRectangle = result.WindowHeaderRectangle;
                collapserRectangle = result.collapserRectangle;
                if (collapserRectangle.Contains(e.Location.X, e.Location.Y)) {
                    ww.Collapsed = !ww.Collapsed;
                    this.LayoutWindows();
                    ww.UpdateClientRectangle();
                }
            }
        }

        return res;
    }

    public OnKeyDown (e): void {
        this.resizeWindows = false;

        const Renderers = this.Renderers;
        const r_len = Renderers.length;

        const Windows = this.Windows;
        const ww_len = Windows.length;

        // Container renderes
        for (let i = 0; i < r_len; i++) {
            Renderers[i].OnKeyDown(e);
        }

        // Windows renderers
        for (let i = 0; i < ww_len; i++) {
            const windowRenderers = Windows[i].GetAllRenderers();
            const wr_len = windowRenderers.length;
            for (let wr_i = 0; wr_i < wr_len; wr_i++) {
                windowRenderers[wr_i].OnKeyDown(e);
            }
        }
    }

    public OnKeyUp (e): void {
        this.resizeWindows = false;

        const Renderers = this.Renderers;
        const r_len = Renderers.length;

        const Windows = this.Windows;
        const ww_len = Windows.length;

        // Container renderes
        for (let i = 0; i < r_len; i++) {
            Renderers[i].OnKeyUp(e);
        }

        // Windows renderers
        for (let i = 0; i < ww_len; i++) {
            const windowRenderers = Windows[i].GetAllRenderers();
            const wr_len = windowRenderers.length;
            for (let wr_i = 0; wr_i < wr_len; wr_i++) {
                windowRenderers[wr_i].OnKeyUp(e);
            }
        }
    }

    public ProcessMouseDoubleClick (e): boolean {
        const Renderers = this.Renderers;
        const r_len = Renderers.length;

        const Windows = this.Windows;
        const ww_len = Windows.length;

        // Container renderes
        let res = false;

        for (let i = 0; i < r_len; i++) {
            res = Renderers[i].ProcessMouseDoubleClick(e);
            if (res) return res;
        }

        // Windows renderers
        for (let i = 0; i < ww_len; i++) {
            const ww = Windows[i];

            const windowRenderers = ww.GetAllRenderers();
            const wr_len = windowRenderers.length;
            for (let wr_i = 0; wr_i < wr_len; wr_i++) {
                res = windowRenderers[wr_i].ProcessMouseDoubleClick(e);
                if (res) return res;
            }

            // для мейн никаких ресайзов, коллапсеров
            if (!ww.IsMainWindow) {
                let topWindowPaddingRectangle = new Rectangle();
                let collapserRectangle = new Rectangle();

                const result = ww.GetWindowHeaderRectangle(topWindowPaddingRectangle, collapserRectangle);

                topWindowPaddingRectangle = result.WindowHeaderRectangle;
                collapserRectangle = result.collapserRectangle;

                if (collapserRectangle.Contains(e.Location.X, e.Location.Y)) {
                    return true;
                } else if (
                    topWindowPaddingRectangle.Contains(e.Location.X, e.Location.Y) ||
                    Math.abs(e.Location.Y - topWindowPaddingRectangle.Y) <= 4) {
                    ww.Collapsed = !ww.Collapsed;
                    this.LayoutWindows();
                }
            }
        }

        return res;
    }

    public ProcessMouseClick (e): boolean {
        const Renderers = this.Renderers;
        const r_len = Renderers.length;

        const Windows = this.Windows;
        const ww_len = Windows.length;
        // Container renderes
        let res = false;
        for (let i = 0; i < r_len; i++) {
            res = Renderers[i].ProcessMouseClick(e);
            if (res) {
                return res;
            }
        }

        // Windows renderers
        for (let i = 0; i < ww_len; i++) {
            const ww = Windows[i];

            const windowRenderers = ww.GetAllRenderers();
            const wr_len = windowRenderers.length;

            for (let wr_i = 0; wr_i < wr_len; wr_i++) {
                res = windowRenderers[wr_i].ProcessMouseClick(e);
                if (res) {
                    return res;
                }
            }
        }
        return res;
    }

    public ProcessMouseWheel (e): boolean {
        const Renderers = this.Renderers;
        const r_len = Renderers.length;

        const Windows = this.Windows;
        const ww_len = Windows.length;
        // Container renderes
        let res = false;
        for (let i = 0; i < r_len; i++) {
            res = Renderers[i].ProcessMouseWheel(e);
            if (res) {
                return res;
            }
        }

        // Windows renderers
        for (let i = 0; i < ww_len; i++) {
            const ww = Windows[i];

            const windowRenderers = ww.GetAllRenderers();
            const wr_len = windowRenderers.length;

            for (let wr_i = 0; wr_i < wr_len; wr_i++) {
                res = windowRenderers[wr_i].ProcessMouseWheel(e);
                if (res) {
                    return res;
                }
            }
        }
        return res;
    }

    public ProcessMouseEnter (e): void {
        const Renderers = this.Renderers;
        const r_len = Renderers.length;

        const Windows = this.Windows;
        const ww_len = Windows.length;

        // Container renderes
        for (let i = 0; i < r_len; i++) {
            Renderers[i].ProcessMouseEnter(e);
        }

        // Windows renderers
        for (let i = 0; i < ww_len; i++) {
            const ww = Windows[i];

            const windowRenderers = ww.GetAllRenderers();
            const wr_len = windowRenderers.length;

            let res;
            for (let wr_i = 0; wr_i < wr_len; wr_i++) {
                res = windowRenderers[wr_i].ProcessMouseEnter(e);
            }
        }
    }

    public ProcessMouseLeave (e): void {
        const Renderers = this.Renderers;
        const r_len = Renderers.length;

        const Windows = this.Windows;
        const ww_len = Windows.length;

        // Container renderes
        for (let i = 0; i < r_len; i++) {
            Renderers[i].ProcessMouseLeave(e);
        }

        // Windows renderers
        for (let i = 0; i < ww_len; i++) {
            const ww = Windows[i];

            const windowRenderers = ww.GetAllRenderers();
            const wr_len = windowRenderers.length;

            let res;
            for (let wr_i = 0; wr_i < wr_len; wr_i++) {
                res = windowRenderers[wr_i].ProcessMouseLeave(e);
            }
        }
    }

    // #endregion

    // --------------------------C#

    public GetContextMenu (e, chart): any {
        let contextMenu = null;

        for (let i = 0; i < this.Renderers.length; i++) {
            contextMenu = this.Renderers[i].GetContextMenu(e, chart);
            if (contextMenu !== null) {
                return contextMenu;
            }
        }

        let i_w;
        const len_w = this.Windows.length;
        for (let i_w = 0; i_w < len_w; i_w++) {
            const curWindow = this.Windows[i_w];
            let i_r;
            const len_r = curWindow.Renderers.length;
            for (i_r = 0; i_r < len_r; i_r++) {
                contextMenu = curWindow.Renderers[i_r].GetContextMenu(e, chart);
                if (contextMenu != null) {
                    return contextMenu;
                }
            }
        }

        return contextMenu;
    }

    //    /// <summary>
    //    /// Вернуть курсор для чарта
    //    /// </summary>
    public GetCursor (e): Cursors {
        let newCursor = null;

        for (let i = 0; i < this.Windows.length; i++) {
            let j;
            const renderers = this.Windows[i].GetAllRenderers();
            const len_j = renderers.length;

            for (j = 0; j < len_j; j++) {
                newCursor = renderers[j].GetCursor(e);
                if (newCursor != null) {
                    return newCursor;
                }
            }

            if (!this.Windows[i].IsMainWindow) // у главного окна нет коллапсера
            {
                let topWindowPaddingRectangle = new Rectangle();
                let collapserRectangle = new Rectangle();
                const result = this.Windows[i].GetWindowHeaderRectangle(topWindowPaddingRectangle, collapserRectangle);
                topWindowPaddingRectangle = result.WindowHeaderRectangle;
                collapserRectangle = result.collapserRectangle;

                if (collapserRectangle.Contains(e.Location.X, e.Location.Y)) {
                    return Cursors.Hand;
                } else if (topWindowPaddingRectangle.Contains(e.Location.X, e.Location.Y) || Math.abs(e.Location.Y - topWindowPaddingRectangle.Y) <= 4) {
                    return Cursors.HSplit;
                }
            }
        }

        for (let i = 0; i < this.Renderers.length; i++) {
            newCursor = this.Renderers[i].GetCursor(e);
            if (newCursor != null) {
                return newCursor;
            }
        }

        return newCursor;
    }

    /// <summary>
    /// Вернуть тултип для чарта
    /// </summary>
    public GetTooltip (e): string | null {
        let newTooltip = null;

        for (let i = 0; i < this.Renderers.length; i++) {
            newTooltip = this.Renderers[i].GetTooltip(e);
            if (newTooltip != null) {
                return newTooltip;
            }
        }

        for (let i = 0; i < this.Windows.length; i++) {
            const window = this.Windows[i];
            const renderers = window.GetAllRenderers();

            for (let j = 0; j < renderers.length; j++) {
                const renderer = renderers[j];
                newTooltip = renderer.GetTooltip(e);
                if (newTooltip != null) {
                    return newTooltip;
                }
            }

            if (!window.IsMainWindow) // у главного окна нет коллапсера
            {
                let topWindowPaddingRectangle = new Rectangle();
                let collapserRectangle = new Rectangle();

                const result = window.GetWindowHeaderRectangle(topWindowPaddingRectangle, collapserRectangle);
                topWindowPaddingRectangle = result.WindowHeaderRectangle;
                collapserRectangle = result.collapserRectangle;

                if (collapserRectangle.Contains(e.Location.X, e.Location.Y)) {
                    return window.Collapsed ? 'Click to expand window' : 'Click to collapse window';
                } else if (topWindowPaddingRectangle.Contains(e.Location.X, e.Location.Y) || Math.abs(e.Location.Y - topWindowPaddingRectangle.Y) <= 4) {
                    return window.Collapsed ? 'Expande or double click to resize window' : 'Drag to resize window';
                }
            }
        }

        return newTooltip;
    }
}
