import { TradingPositionType } from '../../Utils/Instruments/TradingPositionType';
import { type Account } from './Account';
import { type Instrument } from './Instrument';

export class PositionInfo {
    public pLByCurrency: number | null;
    public netQty: number | null;
    public longQTY: number | null;
    public shortQTY: number | null;
    public averageOpenPrice: number | null;
    public breakEven: number | null;
    public instrument: Instrument;
    public account: Account;
    public allInShort: boolean;
    public allInLong: boolean;

    public Recalculate (instr: Instrument, acc: Account): void {
        this.instrument = instr;
        this.account = acc;

        this.ResetValues();

        if (isNullOrUndefined(this.instrument) || isNullOrUndefined(this.account)) {
            return;
        }

        let positionAmount: number = null;

        const accountTotalPositionAmount: number = this.instrument.PositionsTotalInfo.accountTotalPositionAmount.get(this.account.userPin);

        if (isValidNumber(accountTotalPositionAmount)) {
            positionAmount = accountTotalPositionAmount;
        }

        if (isValidNumber(positionAmount)) {
            this.netQty = positionAmount;
            if (this.instrument.TradingPositionType === TradingPositionType.MultiPosition) {
                const accountPositionAmountBuy: number | null = this.instrument.PositionsTotalInfo.accountPositionAmountBuy.get(this.account.userPin);
                const accountPositionAmountSell: number | null = this.instrument.PositionsTotalInfo.accountPositionAmountSell.get(this.account.userPin);

                const longQty: number = isValidNumber(accountPositionAmountBuy) ? Math.abs(accountPositionAmountBuy) : 0;
                const shortQty: number = isValidNumber(accountPositionAmountSell) ? Math.abs(accountPositionAmountSell) : 0;

                this.allInShort = shortQty > 0 && longQty === 0;
                this.allInLong = longQty > 0 && shortQty === 0;

                this.shortQTY = shortQty;
                this.longQTY = longQty;
            } else {
                this.allInShort = positionAmount < 0;
                this.allInLong = positionAmount > 0;
            }
        }

        const accountTotalProfitLoss = this.instrument.PositionsTotalInfo.accountTotalProfitLoss.get(this.account.userPin);
        if (isValidNumber(accountTotalProfitLoss)) {
            this.pLByCurrency = accountTotalProfitLoss;
        }

        const AvgPosPriceWithAmount: number = this.GetTotalAveragePositionPriceWithAmount(this.instrument, this.account);
        if (isValidNumber(AvgPosPriceWithAmount)) {
            this.averageOpenPrice = AvgPosPriceWithAmount;
        }

        if (isValidNumber(this.netQty)) {
            if (this.netQty !== 0) {
                const priceWithAmountSell = this.instrument.PositionsTotalInfo.accountAveragePositionPriceWithAmountSell.get(this.account.userPin) ?? 0;
                const priceWithAmountBuy = this.instrument.PositionsTotalInfo.accountAveragePositionPriceWithAmountBuy.get(this.account.userPin) ?? 0;
                this.breakEven = (priceWithAmountBuy - priceWithAmountSell) / this.netQty;
            } else {
                this.breakEven = null;
            }
        }
    }

    private ResetValues (): void {
        this.pLByCurrency = null;
        this.netQty = null;
        this.longQTY = null;
        this.shortQTY = null;
        this.averageOpenPrice = null;
        this.breakEven = null;

        this.allInShort = false;
        this.allInLong = false;
    }

    /// <summary>
    /// #42447
    /// </summary>
    public GetTotalAveragePositionPriceWithAmount (instrument: Instrument, account: Account): number {
        if (isNullOrUndefined(instrument) || isNullOrUndefined(account)) {
            return null;
        }

        let totalAveragePositionPriceWithAmount: number = null;

        let positionAmount = 0;
        const accountTotalPositionAmount = instrument.PositionsTotalInfo.accountTotalPositionAmount.get(account.userPin);

        if (isValidNumber(accountTotalPositionAmount)) {
            positionAmount = accountTotalPositionAmount;
        }

        if (positionAmount > 0) {
            let positionPriceWithAmountBuy = 0;
            let positionAmountBuy = 0;

            const accountAveragePositionPriceWithAmountBuy = instrument.PositionsTotalInfo.accountAveragePositionPriceWithAmountBuy.get(account.userPin);
            if (isValidNumber(accountAveragePositionPriceWithAmountBuy)) {
                positionPriceWithAmountBuy = accountAveragePositionPriceWithAmountBuy;
            }

            const accountPositionAmountBuy = instrument.PositionsTotalInfo.accountPositionAmountBuy.get(account.userPin);
            if (isValidNumber(accountPositionAmountBuy)) {
                positionAmountBuy = accountPositionAmountBuy;
            }

            if (positionAmountBuy !== 0) {
                totalAveragePositionPriceWithAmount = positionPriceWithAmountBuy / positionAmountBuy;
            } else {
                positionAmountBuy = 0;
            }
        }

        if (positionAmount < 0) {
            let positionPriceWithAmountSell = 0;
            let positionAmountSell = 0;

            const accountAveragePositionPriceWithAmountSell = instrument.PositionsTotalInfo.accountAveragePositionPriceWithAmountSell.get(account.userPin);
            if (isValidNumber(accountAveragePositionPriceWithAmountSell)) {
                positionPriceWithAmountSell = accountAveragePositionPriceWithAmountSell;
            }

            const accountPositionAmountSell = instrument.PositionsTotalInfo.accountPositionAmountSell.get(account.userPin);
            if (isValidNumber(accountPositionAmountSell)) {
                positionAmountSell = accountPositionAmountSell;
            }

            if (positionAmountSell !== 0) {
                totalAveragePositionPriceWithAmount = positionPriceWithAmountSell / positionAmountSell;
            } else {
                positionAmountSell = 0;
            }
        }

        return totalAveragePositionPriceWithAmount;
    }
}
