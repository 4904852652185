// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectSnapshotMessage extends DirectMessageBaseClass {
    public UserId = NaN;
    public RouteId = NaN;
    public TradableId = NaN;
    public Bid = NaN;
    public BidSize = NaN;
    public Ask = NaN;
    public AskSize = NaN;
    public Last = NaN;
    public LastSize = NaN;
    public High = NaN;
    public Low = NaN;
    public Close = NaN;
    public Volume = NaN;

    public ReceivedAt = new Date(); // дата создания сообщения

    constructor () {
        super(Message.CODE_GET_SNAPSHOT_RESPONSE);
    }
}
