// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
// main question what we need to do if chart have aggregated history?

import { type TerceraChart } from './TerceraChart';

// how to append new data to chart?
export class TerceraChartAutoLoadHistoryManager {
    public chart: TerceraChart;
    public allowAutoLoad: boolean;
    public wasDragged: boolean;
    public prevPaddingBarsCount: number;
    public isLoadingHistoryNow: boolean;
    public isNeedToLoadHistory: boolean;

    constructor (chart: TerceraChart) {
        this.chart = chart;
        this.allowAutoLoad = false;
        this.wasDragged = false;
        this.prevPaddingBarsCount = 0;
        this.isLoadingHistoryNow = false;
        this.isNeedToLoadHistory = false;
    }

    // chart was changed by zooming or resizing
    public resetCurrentView (): any {
        // need reload history
    }

    public dispose (): void {
        // need stop load history
        this.stopLoading();
    }

    public stopLoading (): any {

    }

    public processMouseDown (): void {
        if (!this.allowAutoLoad) {
            return;
        }

        this.wasDragged = false;

        this.prevPaddingBarsCount = this.chart.MainCashItemSeries()?.paddingBarsCount ?? 0;
    }

    public processMouseClick (): boolean {
        if (!this.allowAutoLoad) {
            return false;
        }

        if (!this.wasDragged) {
            return false;
        }

        this.wasDragged = false;

        void this.loadHistory();

        return true;

        // if loading stop loading
        // load history
        // how many bars need to load?
        // insert empty bars into CashItemSeries
    }

    public update (): void {
        // check if need to addiditionaly load history
        // for example view was changed by zooming or resizing
        void this.loadHistory();
    }

    public processDragLeft (newI1: number): void {
        if (!this.allowAutoLoad) {
            return;
        }

        const chart = this.chart;

        if (!chart.HasCorrectData()) {
            return;
        }

        const cashItemSeries = chart.MainCashItemSeries();

        if (!cashItemSeries) {
            return;
        }

        this.wasDragged = true;

        let needToLoadBars = Math.ceil(chart.mainWindow.im() - newI1);
        needToLoadBars += this.prevPaddingBarsCount;

        cashItemSeries.setPaddingBarsCount(needToLoadBars);

        const len = chart.Overlays.length;
        for (let i = 0; i < len; i++) {
            const ser = chart.Overlays[i].mainPriceRenderer.Series;
            if (ser) {
                ser.setPaddingBarsCount(needToLoadBars);
            }
        }
    }

    public async loadHistory (): Promise<void> {
        if (this.isLoadingHistoryNow) {
            this.isNeedToLoadHistory = true;
            return;
        }

        const cashItemSeries = this.chart.MainCashItemSeries();
        if (!cashItemSeries) {
            return;
        }

        const barsToLoad = cashItemSeries.paddingBarsCount;
        if (barsToLoad == 0) {
            return;
        }

        const chart = this.chart;

        this.isNeedToLoadHistory = false;
        this.isLoadingHistoryNow = true;

        const historyParams = chart.GetHistoryParams();

        const propogatedRightBorder = cashItemSeries.RealLeftRelativeBorderForPaddingBars;
        const rightDate = new Date(propogatedRightBorder);
        const leftDate = new Date(rightDate);
        leftDate.setFullYear(leftDate.getFullYear() - 10); // not important, wait for Khomenko

        // correct right date border, beacuse this border include existing bar
        const rightDateInMillis = rightDate.getTime();
        rightDate.setTime(rightDateInMillis - 1);

        historyParams.FromTime = leftDate;
        historyParams.ToTime = rightDate;
        historyParams.count = barsToLoad;

        const cashItemFromChart = cashItemSeries._cashItem;

        const intervalsFromServer = await cashItemFromChart.InsertPaddingHistory(historyParams);
        chart.LoadHistoryFinish(cashItemFromChart, true);

        // TODO: validate if there is no history on server
        this.isLoadingHistoryNow = false;
    }
}
