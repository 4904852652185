// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from "../../Utils/CustomEvents.ts";
import { Resources } from "../../Commons/properties/Resources.ts";
import { OrderTypeBase } from "../../Commons/cache/OrderParams/order-type/OrderTypeBase.ts";
import { OrderTypeBaseParameters } from "../../Commons/cache/OrderParams/order-type/OrderTypeBaseParameters.ts";
import { TerceraVisualTradingTemplate } from "../../templates.js";
import { OETIFSelector } from "../trading/OE/OETIFSelector.js";
import { ContainerControl } from "./ContainerControl.js";
import { OrderType } from "../../Utils/Trading/OrderType.ts";
import { OCOOrderType } from "../../Commons/cache/OrderParams/order-type/OCOOrderType.ts";

export let TerceraVisualTradingWidget = ContainerControl.extend({
    template: TerceraVisualTradingTemplate,
    data: function ()
    {
        return {
            top: 0,
            left: 0,
            height: 29,
            movable: true,
            visible: false,
            ocoText: '',
            singleText: '',
            single_checked: true,
            OCO_checked: false,
            instrumentItem: null,
            accountItem: null,
            showOCObtns: true,
            showLeverage: false,
            tif: null,
            items: null,
            gtdDate: null,
            orderTypeId: null,
            selectedItem: null,

            // TODO. Remove. Ugly.
            defaultTif: null
        };
    },
    showChartSetting: null,
    OnVisualTradingOrderTypeChange: null,
});

TerceraVisualTradingWidget.prototype.getType = function () { return 'TerceraVisualTradingWidget' };

TerceraVisualTradingWidget.prototype.oncomplete = function ()
{
    ContainerControl.prototype.oncomplete.apply(this);
    this.Controls.OCO.btnInGroup = true;
    this.Controls.single.btnInGroup = true;
    this.Controls.qtyNumeric.settingsUpdate()
    this.localize();
    this.setWidth();
};

TerceraVisualTradingWidget.prototype.setWidth = function (isVisible)
{
    if (!isVisible)
        return

    return this.set({ width: 0 }).then(() =>
    {
        let el = this.find("*");
        let w = el.offsetWidth;

        return this.set({ width: w });
    });

};

TerceraVisualTradingWidget.prototype.oninit = function (options)
{
    this.OnVisualTradingOrderTypeChange = new CustomEvent();
    this.on('single_click', function ()
    {
        this.set({ OCO_checked: false });
        this.OnVisualTradingOrderTypeChange.Raise(true);
    });
    this.on('OCO_click', function ()
    {
        this.set({ single_checked: false });
        this.OnVisualTradingOrderTypeChange.Raise(false);
    });
    this.on('settings_click', function () { this.showSetting(); });

    this.observe('instrumentItem defaultTif', this.repopulateTIFComboBox)
    this.observe('selectedItem gtdDate', this.updateTIF)

    this.observe('accountItem instrumentItem', function ()
    {
        let ins = this.get("instrumentItem");
        let acc = this.get("accountItem");

        if (!ins || !acc || ins.IsEmpty)
            return;

        if (this.Controls)
            this.Controls.qtyNumeric.settingsUpdate()

        let oco = new OCOOrderType();

        let supportedParamObj = new OrderTypeBaseParameters(ins, acc);

        let supportOCO = oco.IsSupported(supportedParamObj);
        let supportLeverage = this.get('leverageVisible');

        this.set({ showOCObtns: supportOCO }).then(() => { this.setWidth(this.get("visible")); });
    });
};

TerceraVisualTradingWidget.prototype.repopulateTIFComboBox = function ()
{
    OETIFSelector.GetAdvancedComboBoxItems([OrderType.Limit, OrderType.Stop], this)
};

TerceraVisualTradingWidget.prototype.updateTIF = function ()
{
    OETIFSelector.GetAdvancedUpdateTIF(this);
};

TerceraVisualTradingWidget.prototype.localize = function ()
{
    this.set({
        singleText: Resources.getResource('panel.newOrderEntry.singleOrderRadio'),
        ocoText: Resources.getResource('OCO'),
    });
};

TerceraVisualTradingWidget.prototype.showSetting = function ()
{
    if (this.showChartSetting)
        this.showChartSetting();
};

TerceraVisualTradingWidget.prototype.show = function (isVisible)
{
    return this.set({ visible: isVisible }).then(() => 
    {
        return this.setWidth(isVisible);
    });
};