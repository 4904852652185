// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { AlertAction, AlertActions, AlertSubImage } from '../../Utils/Alert/AlertConstants';
import { DataCache } from '../DataCache';
import { type Alert } from '../cache/Alert';
import { Resources } from '../properties/Resources';
import { EventEmitter } from 'events';

export class AlertSideBarController {
    private readonly _eventEmitter: EventEmitter;

    constructor () {
        this._eventEmitter = new EventEmitter();
    }

    // subscribe
    public subscribeToAdd (callback: (businessObject: Alert) => void): void { this._eventEmitter.on('add', callback); }
    public subscribeToUpdate (callback: (businessObject: Alert) => void): void { this._eventEmitter.on('update', callback); }
    public subscribeToRemove (callback: (businessObject: Alert) => void): void { this._eventEmitter.on('remove', callback); }
    public subscribeToEdit (callback: (businessObject: Alert) => void): void { this._eventEmitter.on('edit', callback); }

    // unsubscribe
    public unsubscribeToAdd (callback: (businessObject: Alert) => void): void { this._eventEmitter.off('add', callback); }
    public unsubscribeToUpdate (callback: (businessObject: Alert) => void): void { this._eventEmitter.off('update', callback); }
    public unsubscribeToRemove (callback: (businessObject: Alert) => void): void { this._eventEmitter.off('remove', callback); }
    public unsubscribeToEdit (callback: (businessObject: Alert) => void): void { this._eventEmitter.off('edit', callback); }

    public subscribe (): void {
        DataCache.AlertManager.OnAlertAdded.Subscribe(this.alertAdded, this);
        DataCache.AlertManager.OnAlertRemoved.Subscribe(this.alertRemoved, this);
        DataCache.AlertManager.OnAlertUpdated.Subscribe(this.alertUpdated, this);
    }

    public unsubscribe (): void {
        DataCache.AlertManager.OnAlertAdded.UnSubscribe(this.alertAdded, this);
        DataCache.AlertManager.OnAlertRemoved.UnSubscribe(this.alertRemoved, this);
        DataCache.AlertManager.OnAlertUpdated.UnSubscribe(this.alertUpdated, this);
    }

    private alertAdded (alert: Alert): void {
        this._eventEmitter.emit('add', alert);
    }

    private alertRemoved (alert: Alert): void {
        this._eventEmitter.emit('remove', alert);
    }

    private alertUpdated (alert: Alert): void {
        this._eventEmitter.emit('update', alert);
    }

    public getAlerts (): Alert[] {
        const alertsArray: Alert[] = [];
        const alerts = DataCache.AlertManager.GetAllAlerts();
        const hideOE = Resources.isHidden('screen.Alerts.Action.PlaceOrder');
        for (let i = 0; i < alerts.length; i++) {
            const alert = alerts[i];
            const skipAdding = alert.Action === AlertAction.PLACE_ORDER_NOTIFY && hideOE;
            if (skipAdding) { continue; }

            alertsArray.push(alert);
        }

        alertsArray.sort(DataCache.AlertManager.SortAlertToId);
        return alertsArray;
    };

    public onAlertActionButtonClick (alert: Alert, action: AlertSubImage): void {
        if (action === AlertSubImage.PLAY_STOP) {
            DataCache.AlertManager.MakeAlertAction(alert, (alert.Active ? AlertActions.STOP : AlertActions.START));
        }

        if (action === AlertSubImage.UPDATE) {
            this._eventEmitter.emit('edit', alert);
        }

        if (action === AlertSubImage.CLOSE) {
            DataCache.AlertManager.RemoveAlertProcess(alert);
        }

        return null;
    }

    public static getCountAlerts (): number {
        return DataCache.AlertManager.GetActiveCount();
    }

    public sortAlerts (array): Alert[] {
        return array.sort(DataCache.AlertManager.SortAlertToId);
    }
}
