// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { PFixFieldSet } from '../Fields/FieldsImport';
import { FieldsFactory } from '../Factory/FieldsFactory';
export class UrlGroup extends PFixFieldSet {
    constructor (pfixFieldSet) {
        super(FieldsFactory.SERVER_URL_GROUP, null, pfixFieldSet);
    }

    public getProtocol (): any {
        return this.GetValue(FieldsFactory.FIELD_ADDRESS_PROTOCOL);
    }

    public getValue (): string {
        return this.GetValue(FieldsFactory.FIELD_VALUE);
    }
}
