// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from "../../Utils/CustomEvents.ts";
import { TerceraBoolNumericTemplate } from "../../templates.js";
import { ContainerControl } from "./ContainerControl.js";

export let TerceraBoolNumeric = ContainerControl.extend({
    data: function ()
    {
        return {
            labelText: '',
            boolNumericData: null,
        };
    },
    template: TerceraBoolNumericTemplate
});

TerceraBoolNumeric.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.apply(this);

    this.BoolNumericDataChanged = new CustomEvent();
    this.observe('boolNumericData', this.onBoolNumericDataChanged);
};

TerceraBoolNumeric.prototype.onBoolNumericDataChanged = function (newVal)
{
    this.BoolNumericDataChanged.Raise(this, newVal);
};