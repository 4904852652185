// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DataCacheToolRayType } from '../../Commons/cache/DataCacheToolEnums';
import { ToolView } from './ToolView';
import { SelectionState } from './Selection';

export class FibonacciTimeZoneToolView extends ToolView {
    public override IsSelectCheck (x: number, y: number): boolean {
        return Math.abs(this.screenPoints[0][0] - x) <= ToolView.TOOL_DX;
    }

    // BAR INDEX IS REQUIRED
    public DrawFibTZ (gr, ww, highlight): void {
        const clientRect = ww.ClientRectangle;

        const clientRectX = clientRect.X;
        const clientRectY = clientRect.Y;
        const right = clientRect.X + clientRect.Width;
        const bottom = clientRect.Y + clientRect.Height;

        const dataCacheTool = this.dataCacheTool;
        const points = dataCacheTool.Points;
        const pen = highlight ? dataCacheTool.PenHighlight : dataCacheTool.Pen;

        const ii = Math.round(ww.PointsConverter.GetBarIndex(points[0][0]));

        const i1 = ww.i1;
        const xScale = ww.XScale;

        let ini = 1;
        let oldi = 1;

        while (ini + ii <= i1) {
            const cx = right - (i1 - (ini + ii) + 2) * xScale;
            if (cx > clientRectX && cx < right) {
                const yy1 = clientRectY + 2;
                const yy2 = bottom - 1;
                if (yy2 > yy1) {
                    ToolView.DrawLineWithRay(gr, ww, cx, yy1, cx, yy2, DataCacheToolRayType.NoRay, pen);
                }
            }
            ini = ini + oldi;
            oldi = ini - oldi;
        }
    }

    public override Draw (gr, ww, param): void {
        if (this.CurrentSelection.CurrentState !== SelectionState.None) {
            this.DrawFibTZ(gr, ww, true);
        } else {
            this.DrawFibTZ(gr, ww, false);
        }
    }

    public override DrawSelection (gr, ww): void { };
}
