export class Layer {
    public canvas: any;
    public context: CanvasRenderingContext2D;
    public isDirty: boolean;

    constructor (newCanvas) {
        this.canvas = newCanvas;
        this.context = newCanvas.getContext('2d');
        this.isDirty = true;
    }
}
