// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type CommissionTypes } from '../Commission/CommissionEnums';
import { type IMarginResponse } from '../Interfaces/Response/IMarginResponse';
import { type OperationType } from '../Trading/OperationType';
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectMarginMessage extends DirectMessageBaseClass implements IMarginResponse {
    public BuyingPowerName: string | null = null;
    public CommissionsByOperationMode: Record<string, CommissionGroup[]> = {}; // Record<CommissionOperationType, CommissionGroup[]>

    public OperationMode: any = null;

    public InitMarginBuy: number | null = null;
    public MaintMarginBuy: number | null = null;
    public WarnMarginBuy: number | null = null;

    public InitMarginSell: number | null = null;
    public MaintMarginSell: number | null = null;
    public WarnMarginSell: number | null = null;

    public AfterTradeFundsBuy: any = null;
    public AfterTradeFundsSell: any = null;

    public SwapBuy: number | null = null;
    public SwapSell: number | null = null;

    public StocksOrdersReqBuy: number | null = null;
    public StocksOrdersReqSell: number | null = null;

    constructor () {
        super(Message.CODE_MARGIN_RESP);
    }
}

export class CommissionGroup {
    public CommissionType: CommissionTypes | null = null;
    public OperationType: OperationType | null = null;
    public Amount: number | null = null;
    public CurrencyId: number | null = null;
}
