// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DataBuffer } from '../DataBuffer';
import { PFixField } from './PFixField';
import { PFixFieldSet } from './FieldsImport';

export class PFixGroupField extends PFixField {
    constructor (fieldId, buffer?) {
        super(fieldId, buffer);

        this.Value = new PFixFieldSet();
    }

    public override ReadValue (buffer): void {
        const id = buffer.ReadInt32();
        const fieldSet = new PFixFieldSet(id);

        const size = buffer.ReadShort();
        const groupBuffer = buffer.ReadBuffer(size);

        fieldSet.Read(groupBuffer);
        this.Value = fieldSet;
    }

    public override Write (buffer, maxServerNumber): void {
        const fieldSet = this.Value;

        buffer.WriteShort(this.FieldId);
        buffer.WriteInt32(fieldSet.GroupId);// write group name

        const groupBuffer = new DataBuffer();
        fieldSet.Write(groupBuffer, maxServerNumber);

        buffer.WriteShort(groupBuffer.Length());
        buffer.WriteBuffer(groupBuffer);
    }
}
