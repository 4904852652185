// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { type Account } from './cache/Account';
import { type Instrument } from './cache/Instrument';

export class InstrumentBalancer {
    public prices: number[] = [];
    public accountListPrices: any = {};

    public AccountTotalPositionAmount: Map<string, number> = new Map<string, number>();
    public AccountPositionPriceMultiplyAmountBuy: Map<string, number> = new Map<string, number>();
    public AccountPositionPriceMultiplyAmountSell: Map<string, number> = new Map<string, number>();
    public AccountPositionAmountBuy: Map<string, number> = new Map<string, number>();
    public AccountPositionAmountSell: Map<string, number> = new Map<string, number>();
    public AccountTotalProfitLoss: Map<string, number> = new Map<string, number>();
    public instrument: Instrument;

    constructor (inst: Instrument) {
        this.instrument = inst;
    }

    public Add (pos): void {
        if (pos != null) {
            this.prices.push(pos.Price);

            if (pos.Account?.AcctNumber) {
                let accPrices = [];
                if (this.accountListPrices[pos.Account.AcctNumber] != null && Array.isArray(this.accountListPrices[pos.Account.AcctNumber])) {
                    accPrices = this.accountListPrices[pos.Account.AcctNumber];
                } else {
                    this.accountListPrices[pos.Account.AcctNumber] = accPrices;
                }

                accPrices.push(pos.Price);
            }
        }
    }
}

export class UserBalancer {
    public AssetBalances = {};// new Dictionary<string, UserBalancesAssetBalanceFields>();

    public Profit = 0;
    public ProfitNet = 0;
    public ProfitForMargin = 0;
    public ProfitForUnsettled = 0;
    public OnlyProfitForWithdrawalAvailable = 0;
    public LossForUnsettled = 0;

    // Валюта сервера
    public ProfitBase = 0;
    public ProfitNetBase = 0;

    public OpenPosAmount = 0.0;
    public OpenOrdAmount = 0.0;

    public DictPosInstrVal = {};// new Dictionary<IInstrument, double>();

    public OpenPosNumber = 0;
    public OpenOrdNumber = 0;
    public OpenPosExposition = 0.0;
    public CurrMargin = 0.0;

    public averagePositionPrice = 0;
    public totalPositionAmount = 0;
    public NotIsOptionCount = 0;

    public MarginWarning = 0.0;
    public StopOut = 0.0;
    public WithdrawalAvailable = 0.0;

    public BlockedForStocksAccountCurrency = 0.0;
    public BlockedForStocks = 0.0;

    public StockValue = 0;

    public OptionValue = 0;
    public OptionPremium = 0;
    public PositionsValue = 0;
    public positions: any[] = [];
    public Account: Account;

    constructor (account: Account) {
        this.Account = account;
    }
}

export class UserBalancesAssetBalanceFields {
    public OpenOrdAmount = 0;
    public OpenPosAmount = 0;
    public OpenPosNumber = 0;
    public OpenOrdNumber = 0;
}
