// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { Message } from '../../../../Utils/DirectMessages/Message';
import { RevenuePlanMessageType, RevenueCommissionType } from '../../../../Utils/Enums/Constants';
import { DirectMessageBaseClass } from '../../../../Utils/DirectMessages/DirectMessageBase';
import { type SpreadMeasureMode } from '../../../../Commons/cache/SpreadPlan';

export class RevenuePlanMessage extends DirectMessageBaseClass {
    public RevenuePlanMessageType = RevenuePlanMessageType.Comission;
    public Id: number;
    public Name: string;
    public Description: string;

    public CommisionForTransfer: number;
    public CounterAccountID: number;

    public TransferCommisionType = RevenueCommissionType.None;
    public WithdrawalCommisionType = RevenueCommissionType.None;
    public TransferPercentage: number;
    public WithdrawalPercentage: number;
    public TransferFixedFee: number;
    public WithdrawalFixedFee: number;
    public TransferMinFee: number;
    public WithdrawalMinFee: number;
    public TransferMaxFee: number;
    public WithdrawalMaxFee: number;

    public TransferCurrencyId: number;
    public WithdrawalCurrencyId: number;
    public TransferAccountId: number;
    public WithdrawalAccountId: number;

    public CommissionItems: any[] = [];
    public SpreadItems: RevenueSpreadItem[] = [];

    constructor () {
        super(Message.CODE_COMMISSION_PLAN_MESSAGE);
    }
}
// #region RevenueSpreadItem
export class RevenueSpreadItem {
    public Id: number;
    public InstrumentTypeId: number;
    public InstrumentId: string;
    public Spread: number;
    public BidShift: number;
    public AskShift: number;
    public SpreadMode: number;
    public SpreadMeasure: SpreadMeasureMode;

    get TypeId (): number {
        return this.InstrumentTypeId;
    }
}

// #endregion RevenueSpreadItem
