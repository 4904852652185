// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts"
import { HistoryType } from "../../Utils/History/HistoryType.ts"
import { LinkedSystem } from "../misc/LinkedSystem.ts"
import { TSPanelTemplate } from "../../templates.js";
import { TimeSalesItem, TSItemType } from "../cache/TimeSalesItem.ts"
import { TerceraLinkControlConstants } from "../UtilsClasses/TerceraLinkControlConstants.ts";
import { PanelNames } from "../UtilsClasses/FactoryConstants.ts"
import { ApplicationPanelNew } from "./ApplicationPanelNew.js";
import { ThemeManager } from "../misc/ThemeManager.ts"
import { AgressorFlagType } from "../../Utils/Enums/Constants.ts"
import { RouteUtils } from "../../Commons/cache/RouteUtils.ts"
import { DynProperty, PairColor } from "../../Commons/DynProperty.ts"
import { QuoteValid } from "../../Utils/Quotes/QuoteValid.ts"
import { GeneralSettings } from "../../Utils/GeneralSettings/GeneralSettings.ts"
import { Quantity } from "../../Utils/Trading/Quantity.ts"
import { DataCache } from "../../Commons/DataCache.ts";
import { SessionSettings } from "../../Commons/SessionSettings.ts";

export let TSPanel = ApplicationPanelNew.extend(
    {
        Name: 'TSPanel',
        topPanelHeight: 25,
        data: function ()
        {
            return {
                instrument: null,
                account: null,
                isSymbolLinkShow: true,
                isAccountLinkShow: true,
                canFilterByAccount: false
            }
        },

        partials: {
            bodyPartial: TSPanelTemplate
        },

        NeedCalculateRowCount: false,
        headerLocaleKey: 'panel.timeSales',

        isSeparatedSourceMode: null,
        rowIdCounter: 0,

        cMIDLO: null,
        cMIDHI: null,
        cBIDLO: null,
        cBID: null,
        cASKHI: null,
        cASK: null,
        cQUOTE: null,
        cASKHIbackground: null,
        cASKbackground: null,
        cBIDbackground: null,
        cBIDLObackground: null,
        cMIDLObackground: null,
        cQUOTEbackground: null
    })

TSPanel.MAX_ROW_COUNT = 500

TSPanel.prototype.getType = function ()
{
    return PanelNames.TSPanel
}

TSPanel.prototype.oninit = function ()
{
    ApplicationPanelNew.prototype.oninit.call(this)

    this.observe('instrument', this.changeInstrument)
}

TSPanel.prototype.updatePanelHeader = function ()
{
    let ins = this.get('instrument')
    this.set({
        header:
            Resources.getResource(this.headerLocaleKey) +
            (ins && !ins.IsEmpty ? (' ' + ins.DisplayName()) : '')
    })
}

TSPanel.prototype.changeInstrument = function (instrument, lastInstrument)
{
    if (!instrument || instrument === lastInstrument || !this.completed)
        return

    this.isSeparatedSourceMode = RouteUtils.isSeparatedSourceMode(instrument)
    this.unsubscribeInstrument(lastInstrument);
    this.clearTable();
    this.subscribeInstrument();
    this.symbolLink_Out(false, instrument)
    this.updatePanelHeader()
}

//#region ICaller

TSPanel.prototype.callBack = function (properties)
{
    ApplicationPanelNew.prototype.callBack.call(this, properties)
    this.set('instrument', this.getCallBackInstrument(properties, 'symbol'));
}

TSPanel.prototype.Properties = function ()
{
    var properties = ApplicationPanelNew.prototype.Properties.call(this);
    var ins = this.get('instrument');
    if (ins) properties.push(new DynProperty("symbol", ins.GetInteriorID(), DynProperty.STRING, DynProperty.HIDDEN_GROUP));

    return properties;
}

//#endregion

TSPanel.prototype.subscribeInstrument = function ()
{
    let value = this.get('instrument');

    if (value != null && value.DataCache != null && !value.IsEmpty)
    {
        value.DataCache.FQuoteCache.addListener(value, this, HistoryType.QUOTE_LEVEL1);
        value.DataCache.FQuoteCache.addListener(value, this, HistoryType.QUOTE_TRADES);
    }
}

TSPanel.prototype.unsubscribeInstrument = function (lastInstrument)
{
    if (lastInstrument == null || lastInstrument.DataCache == null || lastInstrument.IsEmpty)
        return;

    lastInstrument.DataCache.FQuoteCache.removeListener(lastInstrument, this, HistoryType.QUOTE_LEVEL1)
    lastInstrument.DataCache.FQuoteCache.removeListener(lastInstrument, this, HistoryType.QUOTE_TRADES)
}

TSPanel.prototype.newQuote = function (tradeMessage)
{
    if (tradeMessage.Type !== HistoryType.QUOTE_TRADES)
        return

    let ins = this.get('instrument');
    if (!ins)
        return

    if (!this.quickTableRactive)
        return

    // Fix for continious contracts.
    if (ins.GetInteriorID() !== tradeMessage.TargetInstrumentName)
        return

    let size = tradeMessage.Size
    let inLots = GeneralSettings.View.displayAmountInLots()
    if (inLots)
        size = Quantity.convertQuantityValue(new Quantity(size, false), ins, inLots)

    let lastQuote = ins.GetLastQuote(QuoteValid.Last)

    if (!lastQuote)
        return;

    let res = TSPanel.getTimeSalesItemData(
        tradeMessage,
        lastQuote.Bid,
        lastQuote.Ask,
        true)

    let item = new TimeSalesItem(SessionSettings)
    item.ItemId = this.rowIdCounter++
    item.instrument = ins
    item.isSeparatedSourceMode = this.isSeparatedSourceMode
    item.aggressorFlag = res.correctAggressorFlag
    item.size = size
    item.colorValue = res.colorValue
    item.itemType = res.tsItemType
    item.time = new Date(tradeMessage.cTime)
    item.price = tradeMessage.Price
    item.exchange = tradeMessage.Exchange
    item.lastVenue = tradeMessage.LastVenue
    item.buyerSource = tradeMessage.BuyerSource
    item.sellerSource = tradeMessage.SellerSource

    let qt = this.getQuickTable()
    let row = qt.AddItem(item)
    if (row)
    {
        // TODO. Ugly.
        let colors = this.getRowColors(item.itemType)
        row.ForeColor = colors.Color1
        row.BackColor = colors.Color2
    }

    // TODO. Removing the oldest item.
    if (qt.rowsArray.length > TSPanel.MAX_ROW_COUNT)
        qt.RemoveItem(this.rowIdCounter - 1 - TSPanel.MAX_ROW_COUNT)

    // TODO. Ugly.
    qt.needRedrawBackground = true
    qt.needRedraw = true
}

TSPanel.prototype.oncomplete = function ()
{
    ApplicationPanelNew.prototype.oncomplete.call(this)
    this.themeChange()
    this.changeInstrument(this.get('instrument'), null);
};

TSPanel.prototype.dispose = function ()
{
    ApplicationPanelNew.prototype.dispose.call(this)
    this.unsubscribeInstrument(this.get('instrument'))
}

TSPanel.prototype.jbInit = function ()
{
    ApplicationPanelNew.prototype.jbInit.call(this)
    this.getQuickTable().AddToEnd = false
    this.getQuickTable().lockManualSorting = true
    this.updateHiddenColumns()
}

TSPanel.prototype.updateHiddenColumns = function ()
{
    let qt = this.getQuickTable()
    if (!qt) return

    let isSeparatedSourceMode = this.isSeparatedSourceMode

    qt.setHiddenColumnsData({
        /*Exchange*/4: (isSeparatedSourceMode || Resources.isHidden(qt.columns[4].headerKey)),
        /*Buyer*/12: (!isSeparatedSourceMode || Resources.isHidden(qt.columns[12].headerKey)),
        /*Seller*/13: (!isSeparatedSourceMode || Resources.isHidden(qt.columns[13].headerKey))
    })
}

TSPanel.prototype.symbolLink_Out = function (newSubscriber, instrument)
{
    if (!instrument)
    {
        let ins = this.get('instrument')
        if (!ins) return
        instrument = ins
    }

    let color = this.get('symbolLinkValue')
    if (color !== TerceraLinkControlConstants.STATE_NONE)
        LinkedSystem.setSymbol(color, instrument.GetInteriorID(), newSubscriber)
}

TSPanel.prototype.getInstrument = function ()
{
    return this.get("instrument");
}

TSPanel.prototype.symbolLink_In = function (symbolName)
{
    let newInstr = DataCache.getInstrumentByName(symbolName)
    if (newInstr)
        this.set('instrument', newInstr)
}

TSPanel.prototype.repopulate = function ()
{
    ApplicationPanelNew.prototype.repopulate.call(this)
    this.set('instrument', this.get('instrument'))
}

TSPanel.prototype.getRowColors = function (tsItemType)
{
    switch (tsItemType)
    {
        case TSItemType.AboveAsk:
            return new PairColor(this.cASKHI, this.cASKHIbackground)
        case TSItemType.AtAsk:
            return new PairColor(this.cASK, this.cASKbackground)
        case TSItemType.AtBid:
            return new PairColor(this.cBID, this.cBIDbackground)
        case TSItemType.BelowBid:
            return new PairColor(this.cBIDLO, this.cBIDLObackground)
        case TSItemType.BetweenSpread:
            return new PairColor(this.cMIDLO, this.cMIDLObackground)
        case TSItemType.Quotes:
            return new PairColor(this.cQUOTE, this.cQUOTEbackground)
        default:
            return null
    }
}

TSPanel.prototype.themeChange = function ()
{
    ApplicationPanelNew.prototype.themeChange.call(this)

    let theme = ThemeManager.CurrentTheme

    this.cMIDLO = theme.TS_cMIDLO
    this.cMIDHI = theme.TS_cMIDHI

    this.cBIDLO = theme.TS_cBIDLO
    this.cBID = theme.TS_cBID

    this.cASKHI = theme.TS_cASKHI
    this.cASK = theme.TS_cASK

    this.cQUOTE = theme.TS_cQUOTE

    this.cASKHIbackground = theme.TS_cASKHIbackground
    this.cASKbackground = theme.TS_cASKbackground
    this.cBIDbackground = theme.TS_cBIDbackground
    this.cBIDLObackground = theme.TS_cBIDLObackground

    this.cMIDLObackground = theme.TS_cMIDLObackground
    this.cQUOTEbackground = theme.TS_cQUOTEbackground

    let qtRactive = this.quickTableRactive
    if (!qtRactive) return

    let qt = qtRactive.quickTable
    if (!qt) return

    let rowDict = qt.rows
    if (!rowDict) return

    for (let key in rowDict)
    {
        let item = rowDict[key].item
        if (!item) continue

        let colors = this.getRowColors(item.itemType)
        let row = qt.rows[item.ItemId]
        if (colors)
        {
            row.ForeColor = colors.Color1
            row.BackColor = colors.Color2
        }
    }

    // TODO. Ugly.
    qt.needRedrawBackground = true
    qt.needRedraw = true
}

// TODO. Rename.
// Returns colorValue, agressorFlag, TSItemType.
TSPanel.getTimeSalesItemData = function (msg, bid, ask, realTime)
{
    let correctAggressorFlag = msg.LType
    let tsItemType = null
    let colorValue = null

    let aggressorFlagTypes = AgressorFlagType
    // CrossTrade & Auction
    if (correctAggressorFlag === aggressorFlagTypes.CrossTrade ||
        correctAggressorFlag === aggressorFlagTypes.Auction)
    {
        if (msg.Price < bid)
        {
            colorValue = 1
            tsItemType = TSItemType.BelowBid
        }
        else if (msg.Price == bid)
        {
            colorValue = 2
            tsItemType = TSItemType.AtBid
        }
        else if (msg.Price > ask)
        {
            colorValue = 3
            tsItemType = TSItemType.AboveAsk
        }
        else if (msg.Price == ask)
        {
            colorValue = 4
            tsItemType = TSItemType.AtAsk
        }
        else
        {
            colorValue = 0
            tsItemType = TSItemType.BetweenSpread
        }
    }
    // Bid >= Ask - красим по агрессору, или серым
    else if (bid === 0 || bid >= ask || !realTime)
    {
        // если есть агрессор флаг красим по нему
        if (correctAggressorFlag === aggressorFlagTypes.Ask)
        {
            colorValue = 4
            tsItemType = TSItemType.AtAsk
        }
        else if (correctAggressorFlag === aggressorFlagTypes.Bid)
        {
            colorValue = 2
            tsItemType = TSItemType.AtBid
        }
        else
        {
            colorValue = 0
            tsItemType = TSItemType.BetweenSpread
        }
    }
    // below bid
    else if (msg.Price < bid)
    {
        // совпадает с агрессор флаг - класс, нет возврящаем цвет по аггрессор флагу
        if (correctAggressorFlag === aggressorFlagTypes.Bid ||
            correctAggressorFlag === aggressorFlagTypes.None ||
            correctAggressorFlag === aggressorFlagTypes.NotSet)
        {
            correctAggressorFlag = aggressorFlagTypes.Bid
            colorValue = 1
            tsItemType = TSItemType.BelowBid
        }
        else
        {
            correctAggressorFlag = aggressorFlagTypes.Ask
            colorValue = 4
            tsItemType = TSItemType.AtAsk
        }
    }
    // at bid
    else if (msg.Price === bid)
    {
        // совпадает с агрессор флаг - класс, нет возврящаем цвет по аггрессор флагу
        if (correctAggressorFlag === aggressorFlagTypes.Bid ||
            correctAggressorFlag === aggressorFlagTypes.None ||
            correctAggressorFlag === aggressorFlagTypes.NotSet)
        {
            correctAggressorFlag = aggressorFlagTypes.Bid
            colorValue = 2
            tsItemType = TSItemType.AtBid
        }
        else
        {
            correctAggressorFlag = aggressorFlagTypes.Ask
            colorValue = 4
            tsItemType = TSItemType.AtAsk
        }
    }
    // above ask
    else if (msg.Price > ask)
    {
        // совпадает с агрессор флаг - класс, нет возврящаем цвет по аггрессор флагу
        if (correctAggressorFlag === aggressorFlagTypes.Ask ||
            correctAggressorFlag === aggressorFlagTypes.None ||
            correctAggressorFlag === aggressorFlagTypes.NotSet)
        {
            correctAggressorFlag = aggressorFlagTypes.Ask
            colorValue = 3
            tsItemType = TSItemType.AboveAsk
        }
        else
        {
            correctAggressorFlag = aggressorFlagTypes.Bid
            colorValue = 2
            tsItemType = TSItemType.AtBid
        }
    }
    // at ask
    else if (msg.Price === ask)
    {
        // совпадает с агрессор флаг - класс, нет возврящаем цвет по аггрессор флагу
        if (correctAggressorFlag === aggressorFlagTypes.Ask ||
            correctAggressorFlag === aggressorFlagTypes.None ||
            correctAggressorFlag === aggressorFlagTypes.NotSet)
        {
            correctAggressorFlag = aggressorFlagTypes.Ask
            colorValue = 4
            tsItemType = TSItemType.AtAsk
        }
        else
        {
            correctAggressorFlag = aggressorFlagTypes.Bid
            colorValue = 2
            tsItemType = TSItemType.AtBid
        }
    }
    // спред
    else
    {
        // если есть агрессор флаг красим по нему
        if (correctAggressorFlag === aggressorFlagTypes.Ask)
        {
            colorValue = 4
            tsItemType = TSItemType.AtAsk
        }
        else if (correctAggressorFlag === aggressorFlagTypes.Bid)
        {
            colorValue = 2
            tsItemType = TSItemType.AtBid
        }
        // если нет флага то серым
        else
        {
            // Price is in between the bid and ask. Calc if closer to bid or to ask.
            let dSpread = ask - bid
            let dMid = bid + dSpread / 2
            if (msg.Price < dMid)
            {
                colorValue = 5
                tsItemType = TSItemType.BetweenSpread
            }
            else
            {
                colorValue = 6
                tsItemType = TSItemType.BetweenSpread
            }
        }
    }

    return {
        colorValue: colorValue,
        tsItemType: tsItemType,
        correctAggressorFlag: correctAggressorFlag
    }
}