// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { ThemeManager } from '../Controls/misc/ThemeManager';
import { UserWebStorageInstance } from '../user-web-storage';
import { ConntionInfo } from './Connection';
import { CustomErrorClass, ErrorInformationStorage } from './ErrorInformationStorage';
import { LocalStorage } from './LocalStorage';
import { ChangeValidityToTIFDict, Localization, Resources } from './properties/Resources';

// Maybe rename this file to LanguageResourceMerger ? (and AgainConnectionsGetter...)

let beforeMergeLocaleKeys = null; // caching old keys values for 2 or more connections

// TODO. Refactor. Rename vars.
function mergeLocales (localeDict, localeOverridenFromBO): void {
    if (!localeDict) {
        return;
    }

    roolbackPreviousMerge(); // in case we have more than two connections with different localization files

    const localization = Localization;
    for (const locale in localeDict) {
        const keyDestDict = localization[locale] || {};
        localization[locale] = keyDestDict;

        if (localeOverridenFromBO) {
            beforeMergeLocaleKeys[locale] = {};
        }

        const keys = localeDict[locale];
        for (const key in keys) {
            if (localeOverridenFromBO) {
                beforeMergeLocaleKeys[locale][key] = keyDestDict[key];
            }

            keyDestDict[key] = keys[key];
        }
    }
}

function roolbackPreviousMerge (): void {
    for (const locale in beforeMergeLocaleKeys) {
        const byLang = beforeMergeLocaleKeys[locale];
        for (const key in byLang) {
            const oldValue = byLang[key];
            if (oldValue === undefined) {
                delete Localization[locale][key];
            } else {
                Localization[locale][key] = oldValue;
            }
        }
    }

    beforeMergeLocaleKeys = {};
}

function ChangeValidityTiTif (): void {
    const dict = ChangeValidityToTIFDict;
    for (const keyL in dict) {
        const dictLang = dict[keyL];
        const keyDestDict = Localization[keyL] || {};
        for (const key in dictLang) {
            keyDestDict[key] = dictLang[key];
        }
    }
}

export function ApplicationLoader (conName = null): any {
    // let self = this
    return UserWebStorageInstance.branding.locales(conName)
        .then((jsonLocales) => {
            Resources.setLocalizationWithoutBranding();
            mergeLocales(jsonLocales, conName !== null);
        })
        .catch(function (e) {
            const ex = new CustomErrorClass('catch1 error', 'Application.InitializeWebtrader', 'InitializeWebtrader -> UserWebStorage.branding');
            ErrorInformationStorage.GetException(ex);

            // console.error(e)
        })
        .finally(() => {
            if (!Resources.isHidden('ChangeValidityToTIF')) { ChangeValidityTiTif(); }

            Resources.setNeedForceUpdateLocale();
            Resources.InitStartLanguage();
        })
        .then(function () {
            return UserWebStorageInstance.connections.get();
        })
        .then(function (jsonConnections) {
            // TODO. Ugly. Refactor.
            if (JSON.stringify(ConntionInfo.ConnectionInfoArray) !== JSON.stringify(jsonConnections.clientConnectionJsonArr)) {
                ConntionInfo.ConnectionInfoArray = jsonConnections.clientConnectionJsonArr;
                ConntionInfo.OnConnectionInfoArrayChanged.Raise();
            }
            // self.e = jsonConnections.e
        })
        .catch(function (e) {
            const ex = new CustomErrorClass('catch2 error', 'Application.InitializeWebtrader', 'InitializeWebtrader -> UserWebStorage.branding');
            ErrorInformationStorage.GetException(ex);

            alert(Resources.getResource('panel.backoffice.You need to add connection!'));
        })
        .then(async function () {
            ThemeManager.overrideThemeColors();
            await Promise.resolve();
        })
        .catch(function (e) {
            const ex = new CustomErrorClass('catch3 error', 'Application.InitializeWebtrader', 'InitializeWebtrader -> UserWebStorage.branding');
            ErrorInformationStorage.GetException(ex);

            ThemeManager.setTheme(LocalStorage.getThemeName() || 'dark');
        });
}

export function InvestingCustomSchemeLoader (conName): any {
    return UserWebStorageInstance.branding.investingCustomScheme(conName);
}
