// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { ApplicationInfo } from "../../Commons/ApplicationInfo.ts";
import { Resources } from "../../Commons/properties/Resources.ts";
import { TerceraBaseWindowTemplate, TerceraAboutScreenTemplate } from "../../templates.js";
import { TerceraWindowBase } from "./TerceraWindowBase.js";


// constructor
export let TerceraAboutScreen = TerceraWindowBase.extend({
    template: TerceraBaseWindowTemplate,
    data: function ()
    {
        return {
            movable: true,
            showHeader: true,
            showFooter: false,
            resizable: false,
            canSelect: false,
            // alwaysMiddle: true,  // #89762
            focused: true,
            showFullscreenCloud: true,
            width: 309,
            height: 439,
            versionText: 'Build version: 3.1312',
            buildDateText: 'Date of build: 13.12.2013 11:12:13',
            copyrightText: '2017-2024 TraderEvolution Global LTD.',
            style_addition_body: 'about-screen-addition-body',
            style_addition_header: 'about-screen-addition-header'
        };
    },
    partials: { bodyPartial: TerceraAboutScreenTemplate },
});

TerceraAboutScreen.prototype.getType = function () { return 'TerceraAboutScreen' };

TerceraAboutScreen.prototype.oncomplete = function ()
{
    TerceraWindowBase.prototype.oncomplete.apply(this);
    this.updateBrandingImage();
    this.localize();
    this.center();
};

TerceraAboutScreen.prototype.localize = function ()
{
    let version = "Not found";
    let date = "Not found";
    if (ApplicationInfo.BuildVersionData)
    {
        let w_BWD = ApplicationInfo.BuildVersionData;
        version = w_BWD.version + "." + w_BWD.build + "." + w_BWD.build_version;
        if (w_BWD.developer_version && w_BWD.developer_version !== "0")
            version += ("-" + w_BWD.developer_version);
        date = w_BWD.date_day;
    }
    this.set({
        header: document.title == 'web' ? Resources.getResource('screen.about.titleWeb') : Resources.getResource('screen.about.title') + ' ' + document.title,
        copyrightText: Resources.getResource('screen.about.copyright'),
        versionText: Resources.getResource('screen.about.versionWeb') + ' ' + version,
        buildDateText: Resources.getResource('screen.about.dateBuild') + ' ' + date
    });
};