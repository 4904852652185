import { DateTimeUtils } from '../../../../Utils/Time/DateTimeUtils';
import { type Instrument } from '../../Instrument';

class _OptionTraderUtils {
    public getDaysToExpirationForInstrument (instrument: Instrument): number {
        return this.getDaysToExpirationForDate(instrument.ContractMonthDate);
    }

    public getDaysToExpirationForDate (date: Date): number {
        const timeDifference = date.getTime() - DateTimeUtils.DateTimeNow().getTime();
        const daysToExpire = Math.floor(timeDifference / (1000 * 3600 * 24));
        return daysToExpire + 1;
    }

    public formatExpirationDate (date: Date): string {
        const day = date.getDay();
        const month = date.toLocaleString('en-US', { month: 'short' });
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
    }
}

export const OptionTraderUtils = new _OptionTraderUtils();
