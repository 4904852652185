// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

export class UrlUtils {
    public static ParseTextForURL (text, allowShortURL): any[] // при allowShortURL = true строки вида google.com или www.google.com также считаются URL
    {
        const arr = text.split(UrlUtils.urlRegex);
        const result = [];
        for (let i = 0; i < arr.length; i++) {
            const it = arr[i];
            const isUrl = allowShortURL ? UrlUtils.isUrl(it) : !!it.match(UrlUtils.urlRegex);
            result.push({ isText: !isUrl, value: it });
        }
        return result;
    }

    public static urlRegex = /(https?:\/\/[^\s]+)/g;

    public static isUrl (str): boolean {
        const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    }

    public static openFixedURL (url): Window {
        if (!url) {
            return;
        }

        if (url.indexOf('http') != 0) // #99499
        {
            url = 'http://' + url;
        }

        return window.open(url);
    }
}
