// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { TimeSpan, TimeSpanPeriods } from "../Utils/Time/TimeSpan.ts";
import { Resources } from "../Commons/properties/Resources.ts";
import { HistoryType } from "../Utils/History/HistoryType.ts";
import { LayersEnum } from '../Chart/Renderers/TerceraChartBaseRenderer.ts';
import { Pen, SolidBrush } from "../Commons/Graphics.ts";
import { TerceraChartDrawingType } from "../Chart/Utils/ChartConstants.ts";
import { TercaraChartPriceIndicatorType } from "../Chart/Renderers/TerceraChartSpreadRenderer.ts";
import { TerceraChartZoomRenderer_ButtonType } from '../Chart/Renderers/TerceraChartZoomRenderer.ts';
import { TerceraChartAction, TerceraChartActionEnum } from "../Chart/TerceraChartAction.ts";
import { ModelDataType, TerceraChartMVCCommand } from "../Chart/TerceraChartMVC.ts";
import { MainWindowManager } from "../Controls/UtilsClasses/MainWindowManager.ts";
import { MathUtils } from "../Utils/MathUtils.ts";
import { MouseButtons } from "../Controls/UtilsClasses/ControlsUtils.ts";
import { TerceraChartUtils } from "../Chart/TerceraChartUtils.ts";
import { contextMenuHandler } from "../Utils/AppHandlers.js";
import { PortfolioReturnsItem } from "../Controls/cache/PortfolioReturnsItem.ts";
import { ApplicationPanelNew } from "../Controls/panels/ApplicationPanelNew.js";
import { TerceraMessageBox } from "../Controls/screen/TerceraMessageBox.js";
import { PanelNames } from "../Controls/UtilsClasses/FactoryConstants.ts";
import { PortfolioReturnsTemplate } from "../templates.js";
import { ThemeManager } from "../Controls/misc/ThemeManager.ts";
import { Periods, TFInfo } from "../Utils/History/TFInfo.ts";
import { PortfolioRequestType } from "../Utils/Portfolio/PortfolioRequestType.ts";
import { DateTimeUtils } from "../Utils/Time/DateTimeUtils.ts";
import { BaseInterval } from "../Utils/History/BaseInterval.ts";
import { CashItem } from "../Commons/cache/History/CashItem.ts";
import { DynProperty } from "../Commons/DynProperty.ts";
import { Instrument } from "../Commons/cache/Instrument.ts";
import { DataCache } from "../Commons/DataCache.ts";
import { SessionSettings } from "../Commons/SessionSettings.ts";
import { PanelSettingsScreen } from "../Controls/screen/PanelSettingsScreen.js";
import { BaseIntervalInputParams } from "../Utils/History/BaseIntervalInputParams.ts";

export let PortfolioReturns = ApplicationPanelNew.extend(
    {
        data: function ()
        {
            return {
                panelHeight: 400,

                monthTableViewMode: true,   // instrument table view if false -> selectedMonthID != null
                selectedMonthID: null,      // e.g. Sep, 2020

                monthReturns: [],           // table view data of each month in user prefer order
                isTableSortedByBackWardDirection: false,    // если true, то нижняя таблица отсортирована по убыванию выбраного значения (по умолчанию от более старой к более новой дате сверху вниз) 
                sortedByDate: true,
                sortedByReturnPercent: false,
                sortedByBalance: false,
                instrumentReturnsBySelectedMonth: [],       // table view data of each instrument

                blockTitle: '',
                tableHeaderDate: '',
                tableHeaderReturn: '',
                tableHeaderBalance: '',
                tableHeaderPortfolio: '',   // Текст подписи 'Portfolio' Month Mode View -> 2nd column's header
                monthPortfolioPercent: '',  // Значение после ^ двоеточия monthReturns[selectedMonth] - to show in the header

                // for info window:
                isMonthHovered: false,                  //находятся ли под hover-ом совокупность баров по месяцу
                instrumentReturnsByHoveredMonth: null,  // данные для info window
                dateHeaderTitle: '',                    // header info window, надпись - Date: 

                infoWindowTextColor: null,
                infoWindowBackColor: null,

                splitterX: null,                        // положение разделителя
                splitterDrag: false,
                chartWidth: null,                       // ширина первой половины Returns

                noDataToDisplay: true,
                zIndex: 100
            };
        },
        partials: {
            bodyPartial: PortfolioReturnsTemplate
        },
        computed: {
            terceraChartPanelContext: {
                get: function () { return this; },
                set: function (value) { }
            }
        },

        chartInited: false,
        terceraChartRactive: null,

        // PortfolioReturns' properties: (остальные обрабатываются в своих рендерах)
        chartBackGroundColor: null,        // также является идентификатором - загружены ли дефолтовые настройки чарта
        chartAxisColor: null,
        assetReturnIncreaseColor: '#FFB300',    // <- by Asset return table value colors
        assetReturnDecreaseColor: '#FFB300',
        infoWindowMode: true
    });

PortfolioReturns.prototype.getType = function () { return PanelNames.PortfolioReturns };

PortfolioReturns.prototype.oninit = function ()
{
    ApplicationPanelNew.prototype.oninit.apply(this);

    DataCache.OnReintialize.Subscribe(this.OnReintialize, this);
    // DataCache.PortfolioCache.OnUpdate.Subscribe(this.prepareMonthData, this);

    this.on('monthClick', this.monthSelect)
    // this.on('closeBtnClick', this.closeBtnClick)

    // this.on('onInstrumentHover', this.onInstrumentHover)
    // this.on('onInstrumentHoverLeave', this.onInstrumentHoverLeave)

    // this.on('onMonthHover', this.onMonthHover)
    // this.on('onMonthHoverLeave', this.onMonthHoverLeave)

    this.on('onMouseUp', this.onMouseUp);
    this.on('onMouseMove', this.onMouseMove);

    this.on('splitterMouseDown', this.splitterMouseDown);
    this.on('splitterMouseUp', this.splitterMouseUp);
    this.on('splitterMouseMove', this.splitterMouseMove);

    this.on('onStatisticSwitcherButtonClick', this.onStatisticSwitcherButtonClick);

    Control.Ticker.Subscribe(this.TickAsync, this);
};

PortfolioReturns.prototype.oncomplete = function ()
{
    ApplicationPanelNew.prototype.oncomplete.apply(this);

    let qt = this.getQuickTable()
    if (!qt) return
    qt.onTableMouseDown.Subscribe(this.onTableMouseDown, this);

    SessionSettings.PortfolioPropertiesHandlers.push(this.Properties.bind(this));
    SessionSettings.PortfolioCallBacksHandlers.push(this.callBack.bind(this));

    this.localize()
    $(window).on('resize', this.layoutTable.bind(this));
    if (this.el)
        this.el.addEventListener("mouseup", (e) => this.onMouseUp(e))
};

PortfolioReturns.prototype.layoutTable = function ()
{
    this.setPanelSize()
    this.setChartPosition()

    this.set('splitterX', Math.max(this.get('width') - this.get('chartWidth'), PortfolioReturns.TABLE_MIN_WIDTH))

    if (this.quickTableRactive === null)
        return;

    var margin = this.margin;
    var scrWidth = this.get('splitterX');
    var bottomMargin = margin + 60;
    var top = margin + 31;

    var scrHeight = this.ContentContainerHeight();

    this.quickTableRactive.setBounds(
        margin,
        top,
        Math.abs(scrWidth - 2 * margin),
        Math.abs(scrHeight - margin - bottomMargin));
}

PortfolioReturns.prototype.dispose = function ()
{
    DataCache.OnReintialize.UnSubscribe(this.OnReintialize, this);

    let qt = this.getQuickTable()
    if (!qt) return
    qt.onTableMouseDown.UnSubscribe(this.onTableMouseDown, this);

    // DataCache.PortfolioCache.OnUpdate.UnSubscribe(this.prepareMonthData, this);

    this.unsubscribeRendererEvents()

    Control.Ticker.UnSubscribe(this.TickAsync, this);

    ApplicationPanelNew.prototype.dispose.apply(this);
};

PortfolioReturns.prototype.localize = function ()
{
    let blockTitleLocalKey = 'portfolio.returns.title',
        tableHeaderDateLocalKey = 'portfolio.returns.tableHeader.date',
        tableHeaderReturnLocalKey = 'portfolio.returns.tableHeader.return',
        tableHeaderBalanceLocalKey = 'portfolio.returns.tableHeader.balance',
        tableHeaderPortfolioLocalKey = 'portfolio.returns.tableHeader.monthPercent',
        infoWindowHeaderDateLocalKey = 'portfolio.returns.infoWindow.dateHeader';

    this.set('blockTitle', Resources.getResource(blockTitleLocalKey))
    this.set('tableHeaderDate', Resources.getResource(tableHeaderDateLocalKey))
    this.set('tableHeaderReturn', Resources.getResource(tableHeaderReturnLocalKey))
    this.set('tableHeaderBalance', Resources.getResource(tableHeaderBalanceLocalKey))
    this.set('tableHeaderPortfolio', Resources.getResource(tableHeaderPortfolioLocalKey))
    this.set('dateHeaderTitle', Resources.getResource(infoWindowHeaderDateLocalKey))

    let qt = this.getQuickTable()
    if (qt) qt.localize()
};

PortfolioReturns.prototype.onTableMouseDown = function (hittestInfo)
{
    let qt = this.getQuickTable()
    let rowID = hittestInfo.rowIndex
    // let columnID = qt.sortedColumns[hittestInfo.columnIndex].PRIVATE.index

    if (rowID >= 0)
    {
        let row = qt.rowsArray[rowID]
        this.monthSelect(null, row.id - 1)
    }
}

PortfolioReturns.prototype.prepareMonthData = function ()   
{
    let qt = this.getQuickTable()
    if (!qt) return

    let monthReturns = [].concat(DataCache.PortfolioCache.monthReturns)

    for (let i = 0; i < monthReturns.length; i++)
    {
        let row = qt.AddItem(new PortfolioReturnsItem(monthReturns[i], SessionSettings));

    }

    this.set('monthReturns', monthReturns)
};

PortfolioReturns.prototype.monthSelect = function (event, indexInView, fromChart)   
{
    fromChart = fromChart || false

    let monthReturns = DataCache.PortfolioCache.monthReturns

    if (!monthReturns || !monthReturns[indexInView]) return

    let dateInd = monthReturns[indexInView].date ? DateTimeUtils.formatDate(monthReturns[indexInView].date, 'MMM, YYYY') : null

    let instrumentReturns = null

    if (dateInd && DataCache.PortfolioCache.instrumentReturnsByMonth)
        instrumentReturns = DataCache.PortfolioCache.instrumentReturnsByMonth[dateInd]

    if (!instrumentReturns) return

    for (let i = 0; i < instrumentReturns.length; i++)
    {
        let insReturn = instrumentReturns[i]
        insReturn.TableValueColor = insReturn.percent > 0 ? this.assetReturnIncreaseColor : this.assetReturnDecreaseColor
    }

    this.set('instrumentReturnsBySelectedMonth', instrumentReturns)

    this.set('selectedMonthID', dateInd)
    this.set('monthTableViewMode', false)

    let monthPortfolioPercent = monthReturns[indexInView].Percent
    this.set('monthPortfolioPercent', monthPortfolioPercent)

    let chart = this.terceraChartRactive.terceraChart
    if (!fromChart && chart)
    {
        chart.setPortfolioReturnsRendererProperty('barsClusterHoveredIndex', null)
        chart.setPortfolioReturnsRendererProperty('barsClusterSelectedIndex', indexInView)

        chart.moveScreenByX(indexInView + 1)
        chart.IsDirty();

        this.hoverMonthBarsIndexChanged(null)
    }

};

// PortfolioReturns.prototype.closeBtnClick = function (e, fromChart)   
// {
//     fromChart = fromChart || false

//     this.set('selectedMonthID', null)
//     this.set('monthTableViewMode', true)

//     let chart = this.terceraChartRactive.terceraChart
//     if (!fromChart && chart)
//     {
//         chart.setPortfolioReturnsRendererProperty('barsClusterSelectedIndex', null)
//         chart.IsDirty(LayersEnum.Tools);
//     }

// }

PortfolioReturns.prototype.OnReintialize = function ()
{
    let me = this,
        pCache = DataCache.PortfolioCache

    pCache.SendPortfolioStatisticsRequest(null, PortfolioRequestType.MONTHLY_PORTFOLIO_RETURN)
    pCache.SendPortfolioStatisticsRequest(null, PortfolioRequestType.MONTHLY_INSTRUMENT_RETURN)  // Table by month rows
    pCache.SendPortfolioStatisticsRequest(null, PortfolioRequestType.MONTHLY_PORTFOLIO_RETURN)   // Table by month -> Portfolio:(value) = second column header
        .then(function ()
        {
            me.setPanelSize()
            let noDataToDisplay = !pCache.monthReturns || !pCache.monthReturns.length
            me.set('noDataToDisplay', noDataToDisplay)

            if (!noDataToDisplay)
            {
                me.initChart()
                me.GetCashItems()
                me.prepareMonthData()
            }
        })
}


// Chart region

PortfolioReturns.prototype.subscribeRendererEvents = function ()
{
    if (!this.terceraChartRactive) return

    let chart = this.terceraChartRactive.terceraChart

    if (!chart) return

    let renderer = chart.PortfolioChartReturnsRenderer

    if (!renderer) return

    renderer.barsClusterHoverIndexChanged.Subscribe(this.hoverMonthBarsIndexChanged, this)
    renderer.barsClusterSelectedIndexChanged.Subscribe(this.selectedMonthBarsIndexChanged, this)
}

PortfolioReturns.prototype.unsubscribeRendererEvents = function ()
{
    if (!this.terceraChartRactive) return

    let chart = this.terceraChartRactive.terceraChart

    if (!chart) return

    let renderer = chart.PortfolioChartReturnsRenderer

    if (!renderer) return

    renderer.barsClusterHoverIndexChanged.UnSubscribe(this.hoverMonthBarsIndexChanged, this)
    renderer.barsClusterSelectedIndexChanged.UnSubscribe(this.selectedMonthBarsIndexChanged, this)
}


PortfolioReturns.prototype.initChart = function ()
{
    if (this.terceraChartRactive === null)
        this.terceraChartRactive = this.Controls.chart;

    var terceraChart = this.terceraChartRactive.terceraChart
    if (!terceraChart)
    {
        this.terceraChartRactive.oncomplete()
        terceraChart = this.terceraChartRactive.terceraChart
    }

    var controller = terceraChart.chartController,
        anyIns = DataCache.getAnyInstrument(),
        instrument = new Instrument(DataCache, anyIns.CreateInstrumentMessage(), anyIns.Route),
        tfInfo = new TFInfo()

    var pCache = DataCache.PortfolioCache
    instrument.Level1.GetLastPrice = pCache.GetLastMonthReturn.bind(pCache);

    controller.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.TFI), tfInfo.Copy({ period: Periods.MONTH, historyType: HistoryType.LAST }));
    controller.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.Instrument), instrument);
    controller.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.Account), DataCache.getPrimaryAccount());

    if (!this.chartInited)
    {
        terceraChart.GetContextMenu = function () { }

        this.initDefaultChartTheme()

        this.setChartPosition()
        this.layoutTable()

        this.chartInited = true
    }
};

PortfolioReturns.prototype.applyDefaultSettings = function ()
{
    let theme = ThemeManager.CurrentTheme

    this.chartBackGroundColor = theme.PortfolioReturnsChartBackgroundColor   // default chart background set
    this.chartAxisColor = theme.scrollBackgroundColorChart  // main color (blue) almost all

    this.set('infoWindowBackColor', theme.PortfolioReturnsChartInfoWindowColorDefault)  // info window
    this.set('infoWindowTextColor', theme.PortfolioReturnsChartInfoWindowTextColor)
}

PortfolioReturns.prototype.initDefaultChartTheme = function ()
{
    if (!this.terceraChartRactive || !this.terceraChartRactive.terceraChart) return

    let terceraChart = this.terceraChartRactive.terceraChart

    let theme = ThemeManager.CurrentTheme

    if (!this.chartInited)     // Need to set default settings
        this.applyDefaultSettings()

    let chartBackGroundColor = this.chartBackGroundColor,
        bgColor = this.chartAxisColor,
        yScaleRendererSettings = terceraChart.yScaleRendererSettings
    if (yScaleRendererSettings)
    {
        yScaleRendererSettings.ScaleGridColor = theme.Chart_GridPriceColor;
        yScaleRendererSettings.ScaleGridStyle = Pen.csIsoDotChart;
        yScaleRendererSettings.ScaleGridWidth = 1;
        yScaleRendererSettings.ScaleGridVisibility = true
        yScaleRendererSettings.ScaleBackColor = bgColor
        yScaleRendererSettings.scaleAxisPen.Color = bgColor
        yScaleRendererSettings.ScaleTextColor = theme.PortfolioReturnsChartMainLineColor
        yScaleRendererSettings.AutoScaleSwitcherVisible = true
        yScaleRendererSettings.PercentView = true
    }

    let xScaleRendererSettings = terceraChart.xScaleRendererSettings
    if (xScaleRendererSettings)
    {
        xScaleRendererSettings.ScaleGridColor = theme.Chart_GridPriceColor;
        xScaleRendererSettings.ScaleGridStyle = Pen.csIsoDotChart;
        xScaleRendererSettings.ScaleGridWidth = 1;
        xScaleRendererSettings.ScaleGridVisibility = true
        xScaleRendererSettings.ScaleBackColor = bgColor
        xScaleRendererSettings.scaleAxisPen.Color = bgColor
        xScaleRendererSettings.ScaleTextColor = theme.PortfolioReturnsChartMainLineColor
    }

    let watermarkR = terceraChart.TerceraChartWatermarkRenderer
    if (watermarkR)
        watermarkR.Visible = false

    let tradingToolsR = terceraChart.TerceraChartTradingToolsRenderer
    if (tradingToolsR)
    {
        tradingToolsR.ShowOrders = false;
        tradingToolsR.ShowPositions = false;
        tradingToolsR.ShowVisualTradingOnLeftSide = false;
        tradingToolsR.ShowEvents = false;
        tradingToolsR.ShowAlerts = false;
    }

    let bordersR = terceraChart.TerceraChartBordersRenderer
    if (bordersR)
    {
        bordersR.axisBackPen = new Pen(bgColor, 1)
        bordersR.timeScaleAxisPen = bordersR.priceScaleAxisPen = new Pen(bgColor, 1)
    }

    let mainPriceR = terceraChart.mainPriceRenderer
    if (mainPriceR)
    {
        terceraChart.model.SetChartDrawingType(TerceraChartDrawingType.DotLine) // Line style
        mainPriceR.Visible = true
        mainPriceR.barsUpBorderColorPen = new Pen(theme.PortfolioReturnsChartMainLineColor, 1)
        mainPriceR.barsDownBorderColorPen = new Pen(theme.PortfolioReturnsChartMainLineColor, 1)
        mainPriceR.barsUpColorBrush = new SolidBrush(theme.PortfolioReturnsChartMainLineColor)
        mainPriceR.barsDownColorBrush = new SolidBrush(theme.PortfolioReturnsChartMainLineColor)
    }

    let crossHairR = terceraChart.TerceraChartCrossHairRenderer
    if (crossHairR)
    {
        crossHairR.TrackPriceColor = theme.Chart_TrackCursorBackColor
        crossHairR.TrackPriceStyle = 0
        crossHairR.TrackPriceWidth = 1
        crossHairR.ShowTrackVisibleLine = true
        crossHairR.TrackCursorBackColorBrush = new SolidBrush(theme.Chart_TrackCursorBackColor)
        crossHairR.TrackCursorFontBrush = new SolidBrush(theme.Chart_TrackCursorFontColor)
    }

    let infoWindowR = terceraChart.TerceraChartInfoWindowRenderer
    if (infoWindowR)
        infoWindowR.Visible = false

    let newAlertR = terceraChart.TerceraChartNewAlertRenderer
    if (newAlertR)
        newAlertR.Visible = false

    let scrollerR = terceraChart.windowsContainer ? terceraChart.windowsContainer.scrollerRenderer : null
    if (scrollerR)
    {
        scrollerR.Visible = true
        scrollerR.backBrushChartScroll = new SolidBrush(bgColor)
    }

    let zoomR = terceraChart.windowsContainer ? terceraChart.windowsContainer.zoomRenderer : null
    if (zoomR)
    {
        zoomR.Visible = true
        zoomR.ThemeChanged()
    }

    let spreadR = terceraChart.SpreadRenderer
    if (spreadR)
    {
        spreadR.percentView = true
        spreadR.PriceIndicatorDrawingType = TercaraChartPriceIndicatorType.ScaleMarkerWithLine
        spreadR.LastPrice_LastIndicatorColor = theme.Chart_lastPrice_LastIndicatorColor
    }

    let portfolioReturnsR = terceraChart.PortfolioChartReturnsRenderer
    if (portfolioReturnsR)
    {
        portfolioReturnsR.UseInProperties = true
        portfolioReturnsR.Visible = true
        this.subscribeRendererEvents()
    }

    let timeToNextR = terceraChart.TerceraChartTimeToNextBarRenderer
    if (timeToNextR)
    {
        timeToNextR.Visible = false
        if (timeToNextR.refreshTimerID)
            clearInterval(timeToNextR.refreshTimerID)
    }

    if (terceraChart.mainWindow)
        terceraChart.mainWindow.AutoScale = true

    terceraChart.TerceraChartFont.Height = 10
    this.terceraChartRactive.set('backgroundColorBottom', chartBackGroundColor)

    terceraChart.IsDirty()
}

PortfolioReturns.prototype.setPanelSize = function ()
{
    let width = MainWindowManager.MainWindow.width,/// 3,
        tableHeaderHeight = 37,
        metricsBarHeight = 38,
        height = this.el.clientHeight;

    this.set({ width: width, height: height, panelHeight: height - 108 })
}

PortfolioReturns.prototype.setChartPosition = function ()
{
    if (!this.terceraChartRactive || !this.terceraChartRactive.terceraChart)
        return

    let cW = this.get('chartWidth')
    if (!cW)
    {
        cW = MainWindowManager.MainWindow.width / 2 - 25
        this.set('chartWidth', cW)
        this.set('splitterX', this.get('width') - cW)
    }

    var left = 8, top = 35, width = cW - 25, height = this.get('height') - 34

    this.terceraChartRactive.setBounds(left, top, width, height);
    this.terceraChartRactive.terceraChart.OnResize(Math.abs(width), Math.abs(height));
}

PortfolioReturns.prototype.TickAsync = function ()
{
    ApplicationPanelNew.prototype.TickAsync.apply(this)

    if (this.terceraChartRactive && this.terceraChartRactive.terceraChart && this.terceraChartRactive.terceraChart.needRedraw)
    {
        this.terceraChartRactive.terceraChart.needRedraw = false;
        this.terceraChartRactive.terceraChart.Draw();
    }
}

PortfolioReturns.prototype.applyCursor = function (cursor)
{
    if (this.terceraChartRactive)
        this.terceraChartRactive.setCursor(cursor);
};

PortfolioReturns.prototype.GetCashItems = function ()  // line graphic data prepare (replace mainPriceRenderer data with PortfolioCache.monthReturns)
{
    if (!this.terceraChartRactive || !this.terceraChartRactive.terceraChart)
        return

    let chart = this.terceraChartRactive.terceraChart

    var monthData = DataCache.PortfolioCache.monthReturns

    if (!monthData || !monthData.length) return

    let startDate = new Date(monthData[0].date)

    startDate.setDate(0)

    const biInput = new BaseIntervalInputParams();
    biInput.LeftTimeTicks = startDate - TimeSpanPeriods.TicksPerDay * 30;
    biInput.Open = 0;
    biInput.Close = 0;
    biInput.High = 0;
    biInput.Low = 0;
    let zeroInterval = new BaseInterval(biInput, Periods.MONTH),
        intervals = [zeroInterval]  // нужно чтоб был нулевой пустой элемент иначе реальный нулевой (первый) бар не помещается в график при определенных скейлах

    let prevClose = 0

    for (let i = 0; i < monthData.length; i++)
    {
        let m = monthData[i],
            monthReturn = m.percent,
            monthDate = new Date(m.date),
            val = parseFloat(monthReturn)

        if (!val) val = MathUtils.MATH_ROUND_EPSILON    // иначе месяц c нулевым % не рисует :(

        const dataObj = new BaseIntervalInputParams();
        dataObj.Open = prevClose;
        dataObj.Close = val;
        dataObj.High = Math.max(prevClose, val);
        dataObj.Low = Math.min(prevClose, val);
        dataObj.LeftTimeTicks = monthDate;

        let bI = new BaseInterval(dataObj, Periods.MONTH)

        intervals.push(bI)

        prevClose = val
    }

    let tf = new TFInfo(),
        cI = new CashItem(null, null, tf.Copy({ period: Periods.MONTH + 1, historyType: HistoryType.LAST }))

    cI.historyLoaded(intervals)

    chart.model.HistoryFrom = startDate
    chart.LoadHistoryFinish(cI)
}

// Chart endregion

PortfolioReturns.prototype.onInstrumentHover = function (e, index)
{
    let chart = this.terceraChartRactive ? this.terceraChartRactive.terceraChart : null
    if (chart)
    {
        let instrumentReturnsBySelectedMonth = this.get('instrumentReturnsBySelectedMonth'),
            insKey = instrumentReturnsBySelectedMonth[index].interiorID

        chart.setPortfolioReturnsRendererProperty('oneInstrumentBarHoveredIndex', insKey)
        chart.IsDirty(LayersEnum.Tools);
    }
}

PortfolioReturns.prototype.onInstrumentHoverLeave = function (e)
{
    let chart = this.terceraChartRactive ? this.terceraChartRactive.terceraChart : null
    if (chart)
    {
        chart.setPortfolioReturnsRendererProperty('oneInstrumentBarHoveredIndex', null)
        chart.IsDirty(LayersEnum.Tools);
    }
}

PortfolioReturns.prototype.onMonthHover = function (e, index)
{
    let currentOrderMonthReturns = this.get('monthReturns'),
        selectedMonthReturn = currentOrderMonthReturns[index]

    index = selectedMonthReturn ? selectedMonthReturn.TimeSortIndex : null

    let chart = this.terceraChartRactive ? this.terceraChartRactive.terceraChart : null
    if (chart)
    {
        chart.setPortfolioReturnsRendererProperty('barsClusterHoveredIndex', index)
        chart.IsDirty(LayersEnum.Tools);

        this.hoverMonthBarsIndexChanged(index)
    }
}

PortfolioReturns.prototype.onMonthHoverLeave = function (e)
{
    let chart = this.terceraChartRactive ? this.terceraChartRactive.terceraChart : null
    if (chart)
    {
        chart.setPortfolioReturnsRendererProperty('barsClusterHoveredIndex', null)
        chart.IsDirty(LayersEnum.Tools);

        this.hoverMonthBarsIndexChanged(null)
    }
}


PortfolioReturns.prototype.selectedMonthBarsIndexChanged = function (index)
{
    let qt = this.getQuickTable()
    if (!qt) return

    let arrToSelect = []
    if (index !== null)
        arrToSelect.push(index + 1)

    qt.selectedRowIds = arrToSelect
    qt.afterSelectionChanged(false)
}

PortfolioReturns.prototype.hoverMonthBarsIndexChanged = function (index)
{
    if (!this.infoWindowMode)
        return

    let pCache = DataCache.PortfolioCache

    this.set('isMonthHovered', index != null)

    if (index === null) return

    let dateInd = pCache.GetKeyByMonthReturnsIndex(index)

    let instrumentReturns = pCache.instrumentReturnsByMonth[dateInd]

    if (!instrumentReturns)
    {
        this.set('isMonthHovered', false)
        return
    }

    this.set('instrumentReturnsByHoveredMonth', instrumentReturns)
    this.set('monthHoveredTitle', dateInd)
};

PortfolioReturns.prototype.GetContextMenu = function (e)
{
    var properties = this.Properties(),
        pGridTime = DynProperty.getPropertyByName(properties, "PortfolioReturns.GridTime"),
        pGridPercent = DynProperty.getPropertyByName(properties, "PortfolioReturns.GridPrice"),
        pScrollbar = DynProperty.getPropertyByName(properties, "PortfolioReturns.TimelinePreview")

    var menuItems = [
        {
            text: Resources.getResource('portfolio.returns.contextMenu.View'),
            tag: 'View',
            enabled: true,
            subitems: [
                {
                    text: Resources.getResource('portfolio.returns.contextMenu.GridTime'),
                    tag: "GridTime",
                    checked: pGridTime ? pGridTime.value.Checked : false,
                    enabled: true,
                    canCheck: true,
                    event: function ()
                    {
                        if (pGridTime)
                        {
                            pGridTime.value.Checked = !pGridTime.value.Checked
                            this.callBack([pGridTime])
                        }
                    }.bind(this)
                },
                {
                    text: Resources.getResource('portfolio.returns.contextMenu.GridPercent'),
                    tag: "GridPercent",
                    checked: pGridPercent ? pGridPercent.value.Checked : false,
                    enabled: true,
                    canCheck: true,
                    event: function ()
                    {
                        if (pGridPercent)
                        {
                            pGridPercent.value.Checked = !pGridPercent.value.Checked
                            this.callBack([pGridPercent])
                        }
                    }.bind(this)
                }, {
                    text: Resources.getResource('portfolio.returns.contextMenu.Scrollbar'),
                    tag: "Scrollbar",
                    checked: pScrollbar ? pScrollbar.value : false,
                    enabled: true,
                    canCheck: true,
                    event: function ()
                    {
                        if (pScrollbar)
                        {
                            pScrollbar.value = !pScrollbar.value
                            this.callBack([pScrollbar])
                        }
                    }.bind(this)
                }
            ]
        },
        {
            text: Resources.getResource('portfolio.returns.contextMenu.Refresh'),
            // event: this.ShowTotalsStateChange.bind(this)
        },
        {
            text: Resources.getResource('portfolio.returns.contextMenu.Settings'),
            event: this.OpenSettings.bind(this)
        },
    ];

    return menuItems
}


PortfolioReturns.prototype.OpenSettings = function (e)
{
    PanelSettingsScreen.EditProperties(this, null, Resources.getResource('portfolio.returns.contextMenu.Settings'));
}

PortfolioReturns.PropertyPrefix = 'PortfolioReturns.';

PortfolioReturns.prototype.callBack = function (properties)
{
    // ApplicationPanelNew.prototype.callBack.call(this, properties);

    let p = DynProperty.getPropertyByName(properties, "PortfolioReturns.AssetReturnIncreaseColor");
    if (p) this.assetReturnIncreaseColor = p.value

    p = DynProperty.getPropertyByName(properties, "PortfolioReturns.AssetReturnDecreaseColor");
    if (p) this.assetReturnDecreaseColor = p.value

    // Info window
    p = DynProperty.getPropertyByName(properties, "PortfolioReturns.InfoWindowColors");
    if (p)
    {
        var pairColor = p.value;

        this.set('infoWindowTextColor', pairColor.Color1)
        this.set('infoWindowBackColor', pairColor.Color2)
    }

    p = DynProperty.getPropertyByName(properties, "PortfolioReturns.InfoWindowMode");
    if (p) this.infoWindowMode = p.value;


    let chartR = this.terceraChartRactive

    let propsForChart = [],
        ourPropertyPrefix = PortfolioReturns.PropertyPrefix
    for (let i = 0; i < properties.length; i++)
    {
        let prop = properties[i],
            isPortfolioReturnProperty = prop && prop.name && prop.name.indexOf(ourPropertyPrefix) == 0

        if (isPortfolioReturnProperty)
        {
            prop.name = prop.name.substr(ourPropertyPrefix.length)
            propsForChart.push(prop)
        }
    }


    if (chartR)
        chartR.callBack(propsForChart)

    SessionSettings.savePortfolioSettings(properties)
}

PortfolioReturns.prototype.Properties = function ()
{
    let allChartProperties = []

    if (this.terceraChartRactive)
        allChartProperties = this.terceraChartRactive.Properties()

    var properties = []

    let showGroupInTimeScale = "#2#" + Resources.getResource("property.SeparatorGroup.Show")

    for (let i = 0; i < allChartProperties.length; i++)
    {
        let prop = allChartProperties[i],
            needToAdd = false

        if (!prop) continue

        if (prop.name === 'MainPriceVisibility')   // Portfolio returns Visible
        {
            prop.group = DynProperty.PORTFOLIO_RETURN_GROUP
            prop.localizationKey = 'property.PortfolioReturns.PortfolioReturnVisibility'
            needToAdd = true
        }
        else if (prop.name === 'FitMinMax')  
        {
            prop.localizationKey = 'property.PortfolioReturns.FitMinMax'
            needToAdd = true
        }
        else if (prop.group === DynProperty.VIEW_GROUP) 
        {
            let separGroup = prop.separatorGroup
            if (separGroup) 
            {       // Background colors                // Crosshair                                // Grid
                if ((separGroup.indexOf("#-1#") == 0) || (separGroup.indexOf("#-3#") == 0) || (separGroup.indexOf("#-4#") == 0))
                    needToAdd = true
                // Scroll
                if (separGroup.indexOf("#3#") == 0 && prop.name == "ScrollBar")
                {
                    needToAdd = true
                    prop.localizationKey = 'property.PortfolioReturns.ScrollbarVisibility'
                }
                if (separGroup.indexOf("#-4#") == 0 && prop.name == 'GridPrice')
                    prop.localizationKey = 'property.PortfolioReturns.GridPrice'
            }
        }
        else if (prop.group === DynProperty.TIME_SCALE_GROUP && prop.separatorGroup != showGroupInTimeScale)    // переиспользуем все, кроме раздела Show
            needToAdd = true
        else if (prop.group === DynProperty.PRICE_SCALE_GROUP)
        {
            let separGroup = prop.separatorGroup
            if (separGroup) 
            {
                if (separGroup.indexOf('#1#') == 0 && prop.name == 'autoYScale')    // zoom
                    needToAdd = true
                if (separGroup.indexOf('#3#') == 0)                                 // appereance
                    needToAdd = true
                //highlight percent
                if (separGroup.indexOf('#4#') == 0 && (prop.name == 'PriceIndicatorDrawingType' || prop.name == 'lastPrice_LastIndicatorColor'))
                {
                    prop.localizationKey = 'property.PortfolioReturns.' + (prop.name == 'PriceIndicatorDrawingType' ? 'PercentIndicatorDrawingType' : 'CurrentPercentColor')
                    prop.separatorGroup = '#4#' + Resources.getResource('property.SeparatorGroup.HighlightPercent')
                    needToAdd = true
                }

                if (needToAdd)
                    prop.group = DynProperty.PERCENT_SCALE_GROUP
            }
        }
        else if (prop.group === DynProperty.ASSET_RETURN_GROUP)
            needToAdd = true
        else if (prop.group === DynProperty.DATA_STYLE_GROUP)
        {
            if (prop.name == 'gr_type')
            {
                var objVariants = [];
                var allowedStyles = [TerceraChartDrawingType.Line, TerceraChartDrawingType.Dot, TerceraChartDrawingType.DotLine, TerceraChartDrawingType.Forest, TerceraChartDrawingType.Solid]
                var allowedStyles_len = allowedStyles.length;
                for (var j = 0; j < allowedStyles_len; j++)
                {
                    var allowedStyle = allowedStyles[j];
                    objVariants.push({
                        text: TerceraChartUtils.ToLocalizedTerceraChartDrawingType(allowedStyle),
                        value: allowedStyle
                    });
                }
                prop.objectVariants = objVariants;
                if (prop.assignedProperty)
                    for (var j = 0; j < prop.assignedProperty.length; j++)
                        prop.assignedProperty[j] = PortfolioReturns.PropertyPrefix + prop.assignedProperty[j]
            }
            if (prop.name != 'HistoryType')
            {
                prop.group = DynProperty.PORTFOLIO_RETURN_GROUP;
                needToAdd = true
            }
            if (prop.name == 'bodyColor')
                prop.localizationKey = 'property.PortfolioReturns.PortfolioReturnIncreaseDecreaseColor'
        }

        if (needToAdd)
        {
            if (prop.name.indexOf(PortfolioReturns.PropertyPrefix) == -1)
                prop.name = PortfolioReturns.PropertyPrefix + prop.name
            properties.push(prop)
        }
    }

    properties = properties.concat(this.getAssetReturnProperties(), this.getInfoWindowProperties())

    return properties;
}

PortfolioReturns.prototype.getAssetReturnProperties = function ()
{
    let properties = []

    let prop = new DynProperty("PortfolioReturns.AssetReturnIncreaseColor", this.assetReturnIncreaseColor, DynProperty.COLOR, DynProperty.ASSET_RETURN_GROUP);
    prop.sortIndex = 1;
    properties.push(prop);

    prop = new DynProperty("PortfolioReturns.AssetReturnDecreaseColor", this.assetReturnDecreaseColor, DynProperty.COLOR, DynProperty.ASSET_RETURN_GROUP);
    prop.sortIndex = 2;
    properties.push(prop);

    return properties
}

PortfolioReturns.prototype.getInfoWindowProperties = function ()
{
    let properties = []

    //visibility
    let SeparatorGroup = "#0#" + Resources.getResource("property.VisibilitySeparatorGroup");
    let prop = new DynProperty("PortfolioReturns.InfoWindowMode", this.infoWindowMode, DynProperty.COMBOBOX, DynProperty.DATA_BOX_GROUP);
    prop.sortIndex = 0;
    prop.objectVariants = this.GetAllowedInfoWindowModes();
    prop.separatorGroup = SeparatorGroup;

    properties.push(prop);

    //Colors
    SeparatorGroup = "#1#" + Resources.getResource("property.ColorsSeparatorGroup");
    prop = new DynProperty("PortfolioReturns.InfoWindowColors", new PairColor(this.get('infoWindowTextColor'), this.get('infoWindowBackColor'), 'Text', 'Back'), DynProperty.PAIR_COLOR, DynProperty.DATA_BOX_GROUP);
    prop.sortIndex = 1;
    prop.separatorGroup = SeparatorGroup;
    properties.push(prop);

    return properties;
}

PortfolioReturns.prototype.GetAllowedInfoWindowModes = function ()
{
    let comboBoxItems = [{
        text: Resources.getResource('portfolioReturns.InfoWindowMode.SeparateWindow'),
        value: true
    },
    {
        text: Resources.getResource('portfolioReturns.InfoWindowMode.None'),
        value: false
    }]

    return comboBoxItems
}

// Splitter region

PortfolioReturns.TABLE_MIN_WIDTH = 400;

PortfolioReturns.prototype.splitterMouseDown = function (e)
{
    this.set('splitterDrag', true);
    this.splitterDragStartX = this.get('splitterX')
}
PortfolioReturns.prototype.splitterMouseMove = function (e)
{
    if (!this.get('splitterDrag'))
        return

    let splitterX = Math.min(Math.max(e.original.x, PortfolioReturns.TABLE_MIN_WIDTH), MainWindowManager.MainWindow.width - 110)

    this.set({
        splitterX: splitterX,
        chartWidth: this.get('width') - splitterX
    })

    this.layoutTable()
}
PortfolioReturns.prototype.splitterMouseUp = function (e)
{
    if (!this.get('splitterDrag'))
        return

    this.set({
        splitterDrag: false,
        chartWidth: this.get('width') - this.get('splitterX')
    })

    this.layoutTable()
}

PortfolioReturns.prototype.onMouseMove = function (e)
{
    this.splitterMouseMove(e)
}

PortfolioReturns.prototype.onMouseUp = function (e)
{
    if (this.get('splitterDrag'))
        this.splitterMouseUp()

    if (e.original && e.original.button === MouseButtons.Right)
    {
        var contextMenuItems = this.GetContextMenu(e, this);
        if (contextMenuItems !== null)
            contextMenuHandler.Show(contextMenuItems, e.original.clientX, e.original.clientY);
    }
}

PortfolioReturns.prototype.getPortfolioAssetButtonMenu = function ()
{
    let menuItems = []

    var terceraChart = this.terceraChartRactive.terceraChart
    if (!terceraChart)
        return

    menuItems.push({
        text: Resources.getResource('portfolio.returns.StatisticSwitcher.Portfolio'),
        // event: callback,
        canCheck: true,
        checked: terceraChart.PortfolioChartReturnsRenderer.Visible,
    });

    menuItems.push({
        text: Resources.getResource('portfolio.returns.StatisticSwitcher.Asset'),
        event: this.onAssetStatisticSelect.bind(this),
        canCheck: true,
        // style: 'js-PortfolioReturns-AssetStatistic-Warning',
        checked: terceraChart.PortfolioAssetReturnVisible,
    });

    return menuItems
}

PortfolioReturns.prototype.onAssetStatisticSelect = function ()
{
    var terceraChart = this.terceraChartRactive.terceraChart
    if (!terceraChart)
        return

    let state = terceraChart.PortfolioAssetReturnVisible = !terceraChart.PortfolioAssetReturnVisible,
        updateChart = function ()
        {
            terceraChart.needRedraw = true
            terceraChart.needRedrawAllLayers()
        }

    let okCallBack = function ()
    {
        if (state)
            while (terceraChart.mainWindow.CanZoomIn())
                terceraChart.TerceraChartActionProcessor.ProcessTerceraChartAction(TerceraChartAction.Create(TerceraChartActionEnum.Zoom, TerceraChartZoomRenderer_ButtonType.ZoomIn));

        updateChart()
    }.bind(this)

    if (state)
    {
        if (terceraChart.mainWindow.CanZoomIn())
        {
            let addData = {
                okText: Resources.getResource('portfolio.returns.StatisticSwitcher.Asset.Zoom'),
                cancelText: Resources.getResource('general.messageBox.cancel')
            }

            TerceraMessageBox.Show(Resources.getResource("portfolio.returns.StatisticSwitcher.Asset"),
                Resources.getResource("portfolio.returns.StatisticSwitcher.Asset.ConfirmText"),
                TerceraMessageBox.msgType.Info, okCallBack, null, false, false, "", addData);
        }
        else
            updateChart()
    }
    else
        updateChart()
}

PortfolioReturns.prototype.onStatisticSwitcherButtonClick = function (e)
{
    contextMenuHandler.Show(this.getPortfolioAssetButtonMenu(), e.lastMouseClickX, e.lastMouseClickY, { width: 220 })
}