import { Point, type Rectangle } from '../../../Commons/Geometry';
import { MouseButtons } from '../../../Controls/UtilsClasses/ControlsUtils';
import { TerceraChartAction, TerceraChartActionEnum } from '../../TerceraChartAction';
import { type TerceraChartBase } from '../../TerceraChartBase';
import { type TerceraChartWindow } from '../../Windows/TerceraChartWindow';
import { TerceraChartNumberScaleRenderer } from './TerceraChartNumberScaleRenderer';

export class TerceraChartNumberHorizontalScaleRenderer extends TerceraChartNumberScaleRenderer<TerceraChartBase> {
    private _isMoving: boolean = false;
    private _lastMousePosition: Point = Point.Empty();

    public override FindMinMax (minMax: { tMin: number, tMax: number }, window: TerceraChartWindow): boolean {
        minMax.tMin = window.PointsConverter.GetDataX(window.Rectangle.Left());
        minMax.tMax = window.PointsConverter.GetDataX(window.Rectangle.Right() - 10);
        return true;
    }

    protected GetStepPoints (minV: number, maxV: number, gr: CanvasRenderingContext2D): number[] {
        const maxC = this.Rectangle.X + this.Rectangle.Width;
        const minC = this.Rectangle.X;
        const width = gr.GetTextWidth(minV.toString(), this.settings.ScaleFont);
        const step = this.calcStep(maxV, minV, maxC, minC, (width * 3.5));
        const start = this.calcStart(step, minV);
        const points: number[] = [];
        for (let vi = start; vi <= (maxV + 3 * step); vi += step) {
            points.push(vi);
        }
        return points;
    }

    public override Draw (gr: CanvasRenderingContext2D, window: TerceraChartWindow, windowsContainer: any, advParams: any): void {
        if (!this.Visible) {
            return;
        }

        const scaleRect: Rectangle = this.Rectangle;
        const windowClientRect: Rectangle = window.ClientRectangle;

        gr.FillRectWithRect(this.settings.scaleBackBrush, scaleRect);
        gr.DrawLine(this.settings.scaleAxisPen, windowClientRect.X - window.PaddingLeft, scaleRect.Y, windowClientRect.Right, scaleRect.Y);

        if (this.chart.BarsCount() === 0) {
            return;
        }

        const minMax = { tMin: 0, tMax: 0 };
        if (!this.FindMinMax(minMax, window)) {
            return;
        }
        let lastTextX = 0;
        const points = this.GetStepPoints(minMax.tMin, minMax.tMax, gr);
        for (let i = 0; i < points.length; i++) {
            const vi = points[i];

            const tx = window.PointsConverter.GetScreenX(vi);

            if (tx > scaleRect.Left() && tx < scaleRect.Right()) {
                const formatedValue = this.FormatValue(vi);
                const textWidth = gr.GetTextWidth(formatedValue, this.settings.ScaleFont);
                const curX = tx - textWidth / 2;
                if (lastTextX < curX && (curX >= windowClientRect.Left() - window.PaddingLeft && curX + textWidth <= windowClientRect.Right() + textWidth)) {
                    gr.DrawString(formatedValue, this.settings.ScaleFont, this.settings.scaleTextBrush, curX, scaleRect.Y + 4);
                    lastTextX = tx + textWidth;
                }
                if (this.settings.ScaleGridVisibility) {
                    gr.DrawLine(this.settings.scaleGridPen, tx, windowClientRect.Y, tx, windowClientRect.Y + windowClientRect.Height);
                }
                gr.DrawLine(this.settings.scaleAxisPen, tx, scaleRect.Y, tx, scaleRect.Y + 3);
            }
        }
    }

    public override ProcessMouseDown (e: any): boolean {
        const mouseDownLocation = e.Location;
        if (e.Button === MouseButtons.Left && this.Rectangle.Contains(mouseDownLocation.X, mouseDownLocation.Y)) {
            this._lastMousePosition = mouseDownLocation;
            this._isMoving = true;
            return true;
        } else {
            this._isMoving = false;
            return super.ProcessMouseDown(e);
        }
    }

    public override ProcessMouseUp (e: any): boolean {
        this._isMoving = false;
        return super.ProcessMouseUp(e);
    }

    public override ProcessMouseMove (e: any): boolean {
        const mouseMoveLocation: Point = e.Location;
        if (this._isMoving && this.Rectangle.Contains(mouseMoveLocation.X, mouseMoveLocation.Y)) {
            const delta = -((mouseMoveLocation.X - this._lastMousePosition.X) / this.Rectangle.Width);
            this.chart.TerceraChartActionProcessor.ProcessTerceraChartAction(TerceraChartAction.Create(TerceraChartActionEnum.ZoomX, delta));
            e.NeedRedraw = true;
            this._lastMousePosition = mouseMoveLocation;
            return true;
        } else {
            this._lastMousePosition = mouseMoveLocation;
            return super.ProcessMouseMove(e);
        }
    }

    public override ProcessMouseWheel (e: any): boolean {
        if (!isNullOrUndefined(this.window) && this.Rectangle.Contains(e.offsetX, e.offsetY)) {
            const delta = -e.Delta / this.Rectangle.Width;
            this.chart.TerceraChartActionProcessor.ProcessTerceraChartAction(TerceraChartAction.Create(TerceraChartActionEnum.ZoomX, delta));
            e.NeedRedraw = true;
            return true;
        } else {
            return super.ProcessMouseWheel(e);
        }
    }
}
