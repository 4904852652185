import { type IOptionCalculator } from '../../OptionCalculator/IOptionCalculator';
import { type AnalyzerChartDataPaperPositionInfo } from './AnalyzerChartDataInputParameters';

export class AnalyzerInfoDataInputParameters {
    public readonly dataX: number;
    public readonly interestRate: number;
    public readonly paperPositionsInfo: AnalyzerChartDataPaperPositionInfo[];
    public readonly optionCalculator: IOptionCalculator;

    constructor (dataX: number, interestRate: number, paperPositions: AnalyzerChartDataPaperPositionInfo[], optionCalculator: IOptionCalculator) {
        this.dataX = dataX;
        this.interestRate = interestRate;
        this.paperPositionsInfo = paperPositions;
        this.optionCalculator = optionCalculator;
    }
}
