import { Rectangle } from '../../Geometry';
import { type HeatmapData } from '../Models/HeatmapData';
import { type ITreemapItem } from '../TreeMap/ITreemapItem';
import { type IVisibleMap } from './IVisibleMap';

export class SymbolMapItem implements ITreemapItem, IVisibleMap {
    private readonly symbolMap: HeatmapData;
    public readonly parentIndustryMap: /* IndustryMap */any;

    constructor (parentIndustryMap: /* IndustryMap */any, data: HeatmapData) {
        this.parentIndustryMap = parentIndustryMap;
        this.symbolMap = data;
    }

    public get ParentIndustryMap (): /* IndustryMap */any {
        return this.parentIndustryMap;
    }

    // Свойство HeatmapData
    public get heatmapData (): HeatmapData {
        return this.symbolMap;
    }

    public Visible: boolean = true;

    public getSymbolBackgroundColor (): string {
        return this.parentIndustryMap.getSymbolBackgroundColor(this.symbolMap.changePercent);
    }

    public get Weight (): number {
        return this.symbolMap.Weight;
    }

    public Rectangle: Rectangle = Rectangle.Empty();
}
