export class _AuthenticationStateStorage {
    saveConnection (uuid: string, connectionName: string): void {
        let cookie = document.cookie;
        if (!isNullOrUndefined(cookie)) {
            cookie += ';';
        }
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 1);
        document.cookie += `${uuid}=${connectionName}; expires=${expirationDate.toUTCString()}; path=/`;
    }

    restoreConnection (search: string): string {
        if (!isValidString(search)) {
            return '';
        }

        const queryParams = new URLSearchParams(search);
        const state = queryParams.get('state');
        if (isValidString(state)) {
            const stateCookie = document.cookie.split('; ').find(row => row.startsWith(`${state}=`));
            const stateValue = isValidString(stateCookie) ? stateCookie.split('=')[1] : null;
            if (isValidString(stateValue)) {
                return stateValue;
            }
        }

        return '';
    }
}

export const AuthenticationStateStorage = new _AuthenticationStateStorage();
