// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PFixFieldSet } from '../Fields/FieldsImport';
import { FieldsFactory } from '../Factory/FieldsFactory';
import { type BasisType, EntitlmentProductDefinition } from '../../../../Commons/cache/Entitlement/EntitlmentProductDefinition';

export class EntitlementProductDefinitionGroup extends PFixFieldSet {
    constructor (pfixFieldSet) {
        super(FieldsFactory.PRODUCT_DEFINITION_GROUP, null, pfixFieldSet);
    }

    get InstrumentIds (): number[] {
        const fieldValue = super.GetValue(FieldsFactory.FIELD_INSTRUMENT_IDS);
        return fieldValue !== null ? fieldValue : [];
    }

    get InstrumentGroups (): number[] {
        const fieldValue = super.GetValue(FieldsFactory.FIELD_INSTRUMENT_GROUP_IDS);
        return fieldValue !== null ? fieldValue : [];
    }

    get TradingExchanges (): string[] {
        const fieldValue = super.GetValue(FieldsFactory.FIELD_TRADING_EXCHANGES);
        return fieldValue !== null ? fieldValue : [];
    }

    get BasisType (): BasisType { return super.GetValue(FieldsFactory.FIELD_PRODUCT_BASIS_TYPE); }
    get IsDataSource (): boolean { return super.GetValue(FieldsFactory.FIELD_IS_DATA_SOURCE); }
    get IsDelay (): boolean { return super.GetValue(FieldsFactory.FIELD_IS_DELAY); }
    get IsSnapshot (): boolean { return super.GetValue(FieldsFactory.FIELD_IS_SNAPSHOT); }
    get IsL1 (): boolean { return super.GetValue(FieldsFactory.FIELD_IS_L1); }
    get IsMD (): boolean { return super.GetValue(FieldsFactory.FIELD_IS_MD); }
    get IsL3 (): boolean { return super.GetValue(FieldsFactory.FIELD_IS_L3); }

    GetProductDefinition (): EntitlmentProductDefinition {
        const def = new EntitlmentProductDefinition();
        def.Type = this.BasisType;
        def.InstrumentIds = this.InstrumentIds;
        def.InstrumentGroups = this.InstrumentGroups;
        def.TradingExchanges = this.TradingExchanges;
        def.IsDataSource = this.IsDataSource;
        def.IsDelay = this.IsDelay;
        def.IsSnapshot = this.IsSnapshot;
        def.IsL1 = this.IsL1;
        def.IsMD = this.IsMD;
        def.IsL3 = this.IsL3;

        return def;
    }
}
