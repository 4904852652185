// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts";
import { TerceraQuickTree, TerceraQuickTreeEvents } from "../elements/QuickTree/TerceraQuickTree.ts";
import { NewsPanelTemplate } from "../../templates.js";
import { NewsItem } from "../cache/NewsItem.ts";
import { TerceraMenu } from "../elements/TerceraMenu.ts";
import { ExportScreen } from "../screen/ExportScreen.js";
import { PanelNames } from "../UtilsClasses/FactoryConstants.ts";
import { ApplicationPanelNew } from "./ApplicationPanelNew.js";
import { RangeSelectPanel } from "./RangeSelectPanel.js";
import { UrlUtils } from "../../Utils/UrlUtils.ts";
import { DynProperty } from "../../Commons/DynProperty.ts";
import { DataCache } from "../../Commons/DataCache.ts";


export let NewsPanel = ApplicationPanelNew.extend(
    {
        partials: { bodyPartial: NewsPanelTemplate },
        data: function ()
        {
            return {
                noData: true,
                noDataText: '',
                canLinkByAccount: false,
                isAccountLinkShow: false,
                isSymbolLinkShow: false
            };
        },

        unviewedNewsCount: 0,       // количество непросмотренных новостей для хедера панели
        toolbarVisible: true,       // видимость лейбла и комбобокса выбора Feed (новостного роута)
        selectedFeedValue: -1,      // routeID выбранный в feedsComboBox
        deletedNewsByRoute: null,   // сохраняем id новостей удаленных пользователем 
        callBackWas: false,
        initialLoadingStarted: false,   // произошла ли начальная загрузка новостей (запустился ли LoadAllNews())
        feedsComboBoxTooltipLocalKey: 'panel.news.feedComboBox.tt',
        feedLabelLocalKey: 'panel.news.feed',
        headerLocaleKey: 'panel.news',
        rangeSelectPanel: null
    });

NewsPanel.prototype.getType = function () { return PanelNames.NewsPanel };

NewsPanel.prototype.oncomplete = function ()
{
    ApplicationPanelNew.prototype.oncomplete.apply(this);

    this.addRangeSelectPanel()
    this.populateTableContextMenu()

    this.createToolbar()
    this.subscribeToEvents()

    this.LoadAllNews()

    this.localize()
};

NewsPanel.prototype.jbInit = function ()
{
    ApplicationPanelNew.prototype.jbInit.apply(this);

    let qt = this.getQuickTable()
    if (!qt) return

    qt.AddToEnd = false

    if (!this.callBackWas)  // если панель совсем новая, а не пришла из MongoDB
    {
        qt.sortIndex = 0    // по умолчанию сортируем по нулевой колонке (Received - дата получения)
        qt.asc = false      // порядок сортировки по убыванию
    }
}

NewsPanel.prototype.addRangeSelectPanel = function ()
{
    let topPanel = this.Controls.newsPanelRangeSelect;
    topPanel.set({ name: 'topPanel' });
    topPanel.getReportEvent.Subscribe(this.getNewsReport, this);
    this.rangeSelectPanel = topPanel;
    topPanel.updateSelectedRange();
}

NewsPanel.prototype.subscribeToEvents = function ()
{
    DataCache.OnAddNewsEvent.Subscribe(this.AddNewsEvent, this)
    DataCache.OnRemoveNewsEvent.Subscribe(this.RemoveNewsEvent, this)

    let caller = () =>
    {
        this.AfterLoad();
        this.layoutTable();
        // clearTimeout(this.asyncI);
        // this.asyncI = null;
        // this.asyncI = setTimeout(() => { this.LoadAllNews() }, 700);
    }
    DataCache.SubscribeToNewsIfNeed(caller)

    if (this.quickTableRactive)
    {
        const qt = this.getQuickTable();
        if (qt) {
            qt.AfterEditItem.Subscribe(this.onLinkInContentClick, this);
        } 

        this.quickTableRactive.on(TerceraQuickTreeEvents.DoubleClick, this.onDoubleClicked.bind(this))
    }
}

NewsPanel.prototype.unsubscribeToEvents = function (fromClosePanel)
{
    DataCache.OnAddNewsEvent.UnSubscribe(this.AddNewsEvent, this)
    DataCache.OnRemoveNewsEvent.UnSubscribe(this.RemoveNewsEvent, this)

    let qt = this.getQuickTable()
    if (qt)
        qt.AfterEditItem.UnSubscribe(this.onLinkInContentClick, this);

    DataCache.UnsubscribeToNewsIfNeed(fromClosePanel)
}

NewsPanel.prototype.LoadAllNews = function ()
{
    let news = DataCache.News
    if (!news) return

    this.initialLoadingStarted = true       // не добавляем айтемы раньше вызова LoadAllNews, чтоб была возможность использовать этот метод как для панели, что совершает подписку так и для последующих без лишних созданий айтемов #99505

    // for (let routeID in news)
    // {
    let newsToAdd = this.prepareNews(NewsPanel.ALL_FEEDS_CB_ITEM_VALUE);
    for (let i = 0; i < newsToAdd.length; i++)
        this.AddNewsEvent(newsToAdd[i], true)
    // }

    let qt = this.getQuickTable()
    if (qt)
        qt.sortRowsArray()      // #99511
}

NewsPanel.prototype.AfterLoad = function (startTime, finishTime)
{
    let qtRactive = this.quickTableRactive
    let qt = qtRactive ? qtRactive.quickTable : null
    if (!qt) return

    qt.ClearAll();
    this.unviewedNewsCount = 0
    this.LoadAllNews()
}

NewsPanel.prototype.getNewsReport = function (startTime, finishTime)
{
    let news = DataCache.getNewsRoutesIDs()
    if (!Object.keys(news).length) return

    this.set('loading', true)
    DataCache.clearNewsCache();
    for (let routeID of news)
    {
        DataCache.SendNewsRequestMessage(routeID, new Date(startTime), new Date(finishTime))
            .then(() => { this.AfterLoad(); })
            .finally(() =>
            {
                this.set('loading', false)
            })
    }
}

NewsPanel.prototype.AddNewsEvent = function (news, noNeedToSort)
{
    if (!news || !this.initialLoadingStarted) return

    let qt = this.getQuickTable()
    if (!qt) return

    let newsID = news.GetID(),
        routeID = news.GetRouteID(),
        rowIsVisible = this.selectedFeedValue == NewsPanel.ALL_FEEDS_CB_ITEM_VALUE || this.selectedFeedValue == routeID,
        newsWasDeleted = this.checkIfNewsWasDeleted(routeID, newsID),
        newsWasViewed = DataCache.IsNewsViewed(newsID)

    if (rowIsVisible && !newsWasDeleted)
    {
        if (!noNeedToSort)
        {
            let isDaily = this.rangeSelectPanel.isDaily()
            let arr = DataCache.News[routeID]
            let news = null;
            if (isDaily && arr.length > 100)
                news = arr[arr.length - 101]
            if (news)
                this.RemoveNewsEvent(news.newsID)
        }

        qt.AddItem(new NewsItem(news, newsWasViewed))

        if (!noNeedToSort)
            qt.sortRowsArray()      // #99511

        if (!newsWasViewed)
            this.unviewedNewsCount++
    }

    if (this.get('noData') && qt.rowsArray.length > 0) // я хз как нужно, но так ломается панель
        this.set('noData', false)
}

NewsPanel.prototype.RemoveNewsEvent = function (newsID)     // это удаление происходит, когда новая новость вытесняет старую по их максимальному кол-ву на роут
{
    let qt = this.getQuickTable()
    if (!qt) return

    let rowsArray = qt.rowsArray
    if (rowsArray)
        for (let i = 0; i < rowsArray.length; i++)
        {
            let row = rowsArray[i],
                item = row ? row.item : null

            if (item && item.getNewsID() == newsID)
            {
                qt.RemoveItem(item.ItemId)

                if (!item.Disabled)
                    this.unviewedNewsCount--

                return
            }
        }
}

NewsPanel.prototype.close = function ()
{
    let fromClosePanel = true
    this.dispose(fromClosePanel)
}

NewsPanel.prototype.dispose = function (fromClosePanel)
{
    fromClosePanel = fromClosePanel || false

    this.rangeSelectPanel.getReportEvent.UnSubscribe(this.getNewsReport, this)
    this.unsubscribeToEvents(fromClosePanel)

    ApplicationPanelNew.prototype.dispose.apply(this);
};

NewsPanel.prototype.updatePanelHeader = function ()
{
    let qt = this.getQuickTable()
    if (!qt) return

    this.set({ header: Resources.getResource(this.headerLocaleKey) + '  (' + this.unviewedNewsCount + ')' });
};

NewsPanel.prototype.onLinkInContentClick = function (info)
{
    let url = null,
        item = info && info.row ? info.row.item : null
    if (item)
        url = item.getLinkInContent()

    UrlUtils.openFixedURL(url)
}

NewsPanel.prototype.onSelectedFeedChange = function (el, cbItem)
{
    let qt = this.getQuickTable()
    if (!qt || !cbItem) return

    qt.ClearAll()

    let value = cbItem.value ? cbItem.value : cbItem,
        allNews = DataCache.News,
        newsToAdd = []

    this.selectedFeedValue = value
    this.unviewedNewsCount = 0
    this.set('noData', true)

    newsToAdd = this.prepareNews(value);

    if (newsToAdd)
    {
        for (let i = 0; i < newsToAdd.length; i++)
            this.AddNewsEvent(newsToAdd[i], true)

        qt.sortRowsArray()
    }
}

NewsPanel.prototype.prepareNews = function (value)
{
    let newsToAdd = [];
    let allNews = DataCache.News;
    let isDaily = true;
    if (this.rangeSelectPanel)
        isDaily = this.rangeSelectPanel.isDaily()
    if (value == NewsPanel.ALL_FEEDS_CB_ITEM_VALUE)
        for (let routeID in allNews)
            newsToAdd = newsToAdd.concat(this.limitateNewsNumber(isDaily, allNews[routeID]))
    else
        newsToAdd = this.limitateNewsNumber(isDaily, allNews[value])

    return newsToAdd;
}

NewsPanel.prototype.limitateNewsNumber = function (isDaily, arr = [])
{
    if (isDaily && arr.length > 100)
        return arr.slice(arr.length - 100)

    return arr
}

NewsPanel.prototype.createToolbar = function ()
{
    this.fillFeedsComboBox()
    this.on('onSelectedFeedChange', this.onSelectedFeedChange)

    this.ShowToolbarStateUpdate(this.toolbarVisible)
    this.layoutTable()
};

NewsPanel.prototype.fillFeedsComboBox = function ()
{
    let dc = DataCache,
        newsRoutesIDs = dc.getNewsRoutesIDs()
    if (!newsRoutesIDs.length)
        return

    let item_AllFeeds = { text: Resources.getResource('panel.news.all'), value: NewsPanel.ALL_FEEDS_CB_ITEM_VALUE },
        selectedCBItem = this.selectedFeedValue == item_AllFeeds.value ? item_AllFeeds : null,
        items = [];
    for (let i = 0; i < newsRoutesIDs.length; i++)
    {
        let routeID = newsRoutesIDs[i],
            route = dc.getRouteById(routeID)

        if (route)
        {
            let item = { text: route.Name, value: routeID }
            if (this.selectedFeedValue == item.value)
                selectedCBItem = item

            items.push(item);
        }
    }

    let feedsComboBox = this.Controls ? this.Controls.feedsComboBox : null
    if (!feedsComboBox) return

    items.sort(function (a, b)
    {             // #99501
        let s1 = a.text.toUpperCase(),
            s2 = b.text.toUpperCase()
        if (s1 == s2) return 0
        return s1 > s2 ? 1 : -1
    })

    items = [item_AllFeeds].concat(items)

    feedsComboBox.set({ items: items });

    if (selectedCBItem)
        feedsComboBox.set('selectedItem', selectedCBItem)
}

NewsPanel.prototype.localize = function ()
{
    ApplicationPanelNew.prototype.localize.apply(this);

    this.set('noDataText', Resources.getResource('panel.news.noData'))

    let feedsLabel = this.Controls ? this.Controls.feedsLabel : null
    if (feedsLabel)
        feedsLabel.set('text', Resources.getResource(this.feedLabelLocalKey))

    let feedsComboBox = this.Controls ? this.Controls.feedsComboBox : null
    if (feedsComboBox)
        feedsComboBox.set('tooltip', Resources.getResource(this.feedsComboBoxTooltipLocalKey))

    if (!this.quickTableRactive)
        return;
    var contextItems = this.getQuickTable().tableContextMenuItems;

    if (!contextItems || contextItems.length === 0)
        return;

    for (let i = 0; i < contextItems.length; i++)
    {
        let item = contextItems[i]
        if (item)
        {
            if (item.locKey)
                item.text = Resources.getResource(item.locKey)

            if (item.subitems)
                for (let j = 0; j < item.subitems.length; j++)
                {
                    let subitem = item.subitems[j]
                    if (subitem.locKey)
                        subitem.text = Resources.getResource(subitem.locKey)
                }
        }
    }
}

NewsPanel.prototype.onDoubleClicked = function ()
{
    let qt = this.getQuickTable()
    if (!qt) return
    let selectedOrderIdArr = qt.selectedRowIds

    let qtRow = qt.getItemById(selectedOrderIdArr[0]),
        item = qtRow ? qtRow.item : null

    if (item)
    {
        if (!item.Disabled)     // новость не была прежде прочитана -> следует обновить header
        {
            this.unviewedNewsCount--
            this.updatePanelHeader()
        }

        item.Disabled = true
        DataCache.setNewsAsViewed(item.getNewsID())

        item.OnNewsDoubleClicked()
    }
};

NewsPanel.prototype.onRemoveAll = function ()       // удаление пользователем через пункт контекстного меню Clear table
{
    let qt = this.getQuickTable()
    if (!qt) return

    this.unviewedNewsCount = 0
    let rows = qt.rowsArray
    for (let i = 0; i < rows.length; i++)
        this.onRowWasDeletedByUser(rows[i])

    qt.ClearAll()

    if (qt.rowsArray.length === 0)// я хз как нужно, но так ломается панель
        this.set('noData', true)
};

NewsPanel.prototype.onRemoveSelected = function ()  // удаление пользователем через пункт контекстного меню Remove
{
    let qt = this.getQuickTable()
    if (!qt) return

    let selectedRowIds = qt.selectedRowIds.slice()
    for (let i = 0; i < selectedRowIds.length; i++)
    {
        let rowID = selectedRowIds[i],
            row = qt.getItemById(rowID)

        if (row && row.item && !row.item.Disabled)
            this.unviewedNewsCount--

        this.onRowWasDeletedByUser(row)
        qt.RemoveRow(rowID)
    }

    if (qt.rowsArray.length === 0)// я хз как нужно, но так ломается панель
        this.set('noData', true)
}

NewsPanel.prototype.onRowWasDeletedByUser = function (row)
{
    if (!row || !row.item)
        return

    let item = row.item
    let newsID = item.getNewsID(),
        routeID = item.getRouteID()

    if (!this.deletedNewsByRoute)
        this.deletedNewsByRoute = {}

    if (!this.deletedNewsByRoute[routeID])
        this.deletedNewsByRoute[routeID] = []

    this.deletedNewsByRoute[routeID].push(newsID)
}

NewsPanel.prototype.checkIfNewsWasDeleted = function (routeID, newsID)      // была ли новость удалена пользователем из таблицы
{
    if (!this.deletedNewsByRoute)
        return false

    let deletedNewsIDs = this.deletedNewsByRoute[routeID]

    return deletedNewsIDs && deletedNewsIDs.indexOf(newsID) != -1
}

NewsPanel.prototype.preparePopup = function ()
{
    ApplicationPanelNew.prototype.preparePopup.apply(this);

    let qt = this.getQuickTable()
    if (!qt) return

    this.menuTagDict[NewsPanel.CLEAR_TABLE_CONTEXT_MENU_TAG].enabled = qt.visibleRowCount() > 0;

    let selectedRowIds = qt.selectedRowIds
    this.menuTagDict[NewsPanel.REMOVE_ONE_MENU_TAG].enabled = selectedRowIds && selectedRowIds.length > 0;
};

NewsPanel.prototype.populateTableContextMenu = function ()
{
    var items = [],
        moreThanOnFeed = DataCache.getNewsRoutesIDs().length > 1

    if (!Resources.isHidden('screen.export.visibility'))
        items.push(
            {
                locKey: 'screen.export.contextMenu',
                event: ExportScreen.show.bind(null, this)
            }
        );

    items.push(
        {
            locKey: 'panel.positions.menu.View',
            tag: 'View',
            enabled: true,
            subitems: [
                {
                    locKey: 'panel.news.menu.ShowToolbar',
                    checked: this.toolbarVisible,
                    visible: !Resources.isHidden('panel.news.menu.ShowToolbar'),
                    enabled: moreThanOnFeed,
                    canCheck: true,
                    event: this.ShowToolbarStateChange.bind(this)
                },
            ]
        }
    );

    items.push({
        text: Resources.getResource('panel.news.menu.clearAll'),
        event: this.onRemoveAll.bind(this),
        enabled: true,
        tag: NewsPanel.CLEAR_TABLE_CONTEXT_MENU_TAG
    })

    items.push({
        text: Resources.getResource('panel.news.menu.removeOneRow'),
        event: this.onRemoveSelected.bind(this),
        enabled: false,
        tag: NewsPanel.REMOVE_ONE_MENU_TAG
    })

    this.menuTagDict = TerceraMenu.createTagDictionary(items);
    this.getQuickTable().setTableContextMenuItems(items);
};

NewsPanel.prototype.ShowToolbarStateChange = function (menuItem)
{
    this.toolbarVisible = menuItem.checked

    this.ShowToolbarStateUpdate(this.toolbarVisible);
}

NewsPanel.prototype.ShowToolbarStateUpdate = function (state)
{
    let feedsLabel = this.Controls.feedsLabel,
        feedsComboBox = this.Controls.feedsComboBox

    let routes = feedsComboBox ? feedsComboBox.get('items') : null,
        routesNum = routes ? routes.length : 0

    if (routesNum < 3)  // ALL + one route -> need to hide
        state = false;

    feedsComboBox.set("visible", state);
    feedsLabel.set("visible", state);

    let leftRangePanel = state ? feedsComboBox.get("left") + feedsComboBox.get("width") + 5 : 5;
    let rangePanel = this.rangeSelectPanel;
    rangePanel.set({ left: leftRangePanel });

    this.layoutTable();
}

NewsPanel.prototype.Properties = function ()
{
    var properties = ApplicationPanelNew.prototype.Properties.call(this);

    properties.push(new DynProperty("toolbarVisible", this.toolbarVisible, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));
    properties.push(new DynProperty("selectedFeed", this.selectedFeedValue, DynProperty.INTEGER, DynProperty.HIDDEN_GROUP));
    properties.push(new DynProperty("deletedNewsByRoute", JSON.stringify(this.deletedNewsByRoute), DynProperty.STRING, DynProperty.HIDDEN_GROUP));

    return properties;
};

NewsPanel.prototype.callBack = function (newProperties)
{
    ApplicationPanelNew.prototype.callBack.call(this, newProperties);

    this.toolbarVisible = DynProperty.getPropValue(newProperties, "toolbarVisible");
    this.selectedFeedValue = DynProperty.getPropValue(newProperties, "selectedFeed");

    let deletedNewsByRouteProp = DynProperty.getPropValue(newProperties, "deletedNewsByRoute")
    if (deletedNewsByRouteProp)
        this.deletedNewsByRoute = JSON.parse(deletedNewsByRouteProp);

    this.callBackWas = true
};

NewsPanel.CLEAR_TABLE_CONTEXT_MENU_TAG = 'REMOVE_ALL_MENU_TAG';
NewsPanel.REMOVE_ONE_MENU_TAG = 'REMOVE_ONE_MENU_TAG';
NewsPanel.ALL_FEEDS_CB_ITEM_VALUE = -1;