// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts";
import { PanelNames } from "../UtilsClasses/FactoryConstants.ts";
import { ApplicationPanelNew } from "./ApplicationPanelNew.js";
import { GeneralSettings } from "../../Utils/GeneralSettings/GeneralSettings.ts";
import { ClosePositionsMutuallyTemplate } from "../../templates.js";
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";
import { positionsMutualCloseScreenHandler } from "../../Utils/AppHandlers.js";
import { DataCache } from "../../Commons/DataCache.ts";
import { ClosePositionsMutuallyItem } from "../cache/ClosePositionsMutuallyItem.ts";
import { PositionItem } from "../cache/PositionItem.ts";
import { KeyCode, KeyEventProcessor } from "../../Commons/KeyEventProcessor.ts";
import { Quantity } from "../../Utils/Trading/Quantity.ts";
import { PositionFormatter } from "../../Commons/cache/Formatters/PositionFormatter.ts";
import { ThemeManager } from "../misc/ThemeManager.ts";

export let ClosePositionsMutually = ApplicationPanelNew.extend({
    partials: { bodyPartial: ClosePositionsMutuallyTemplate },
    data: function ()
    {
        return {
            dockablePanel: false,
            isAccountLinkShow: false,
            isSymbolLinkShow: false,

            showFooter: false,
            showHeader: true,
            style_addition_header: 'js-MutualClose-AdditionalHeader',

            symbolValueStyle: 'color: ' + ThemeManager.CurrentTheme.TableGoldColor + ';',
            totalValueStyle: '',
            symbolLabel: 'Symbol:',             // example values
            symbolName: 'AUD/CAD',              // <
            totalLabel: 'Total: 0',             // <
            totalValueFormatted: '0.00',        // <
            closePosBtnText: 'Close',           // <
            cancelBtnText: 'Cancel'             // example values 
        };
    },
    positionsIdArray: [],
    instrument: null,
    totalValue: null,
    totalLongColor: ThemeManager.CurrentTheme.TableLongBuyColor,
    totalShortColor: ThemeManager.CurrentTheme.TableShortSellColor,
    placedFrom: null,
    displayAmountInLots: null,
    closingFunctionReference: null,
    skipOnSave: true,
    NeedCalculateRowCount: false,
    headerLocaleKey: 'panel.ClosePositionsMutually',
    Name: PanelNames.ClosePositionsMutually
});

ClosePositionsMutually.prototype.getType = function () { return PanelNames.ClosePositionsMutually };

ClosePositionsMutually.prototype.oninit = function ()
{
    ApplicationPanelNew.prototype.oninit.call(this);

    this.on('modifyBtnClick', this.mutualPositionsClosing);
    this.on('cancelBtnClick', this.close);

    KeyEventProcessor.OnKeyDown.Subscribe(this.onGlobalKeyDown, this);
    GeneralSettings.SettingsChanged.Subscribe(this.settingsChanged, this);
    Resources.onLocaleChange.Subscribe(this.localize, this);
};

ClosePositionsMutually.prototype.oncomplete = function ()
{
    ApplicationPanelNew.prototype.oncomplete.apply(this);

    this.displayAmountInLots = GeneralSettings.View.displayAmountInLots();

    this.localize();
    this.initByPositions();

    this.center();
};

ClosePositionsMutually.prototype.localize = function ()
{
    ApplicationPanelNew.prototype.localize.apply(this);

    const totalValueSign = this.totalValue ? Math.sign(this.totalValue.value) : 0;
    const totalValuePrefix = totalValueSign == 0 ? '' :
        (totalValueSign > 0 ? Resources.getResource("general.trading.position.Buy") : Resources.getResource("general.trading.position.Sell"));

    this.set({
        symbolLabel: Resources.getResource('panel.ClosePositionsMutually.Symbol'),
        totalValuePrefix: totalValuePrefix,
        totalLabel: Resources.getResource('panel.ClosePositionsMutually.Total'),
        closePosBtnText: Resources.getResource('panel.ClosePositionsMutually.CloseBtn'),
        cancelBtnText: Resources.getResource('panel.ClosePositionsMutually.CancelBtn')
    });
};

ClosePositionsMutually.prototype.mutualPositionsClosing = function ()
{
    if (!this.positionsIdArray || !this.positionsIdArray.length)
        return;

    const posDict = DataCache.getPositionsById(this.positionsIdArray);

    const p0ID = this.positionsIdArray[0];
    const p1ID = this.positionsIdArray[1];

    const p0 = posDict[p0ID];
    const p1 = posDict[p1ID];

    const pos0DateTime = PositionFormatter.DateTime(p0);
    const pos1DateTime = PositionFormatter.DateTime(p1);

    let mutualPositionsIds = [];
    if (pos0DateTime.getTime() < pos1DateTime.getTime())
        mutualPositionsIds = [p0ID, p1ID]
    else
        mutualPositionsIds = [p1ID, p0ID]

    if (this.closingFunctionReference)
        this.closingFunctionReference(posDict, this.placedFrom, mutualPositionsIds)

    this.close();
};

ClosePositionsMutually.prototype.initByPositions = function ()
{
    const qt = this.getQuickTable();

    if (!qt)
        return;

    qt.ClearRows();

    let totalValue = 0, symbol = null;
    for (let i = 0; i < this.positionsIdArray.length; i++)
    {
        const pos = DataCache.getPositionById(this.positionsIdArray[i]);

        symbol = pos.Instrument;
        totalValue += PositionItem.GetPositionAmount(pos) * PositionItem.GetPositionSign(pos);

        qt.AddItem(new ClosePositionsMutuallyItem(pos));
    }

    this.instrument = symbol;
    this.totalValue = new Quantity(totalValue, this.displayAmountInLots)

    const totalValuePrefix = totalValue == 0 ? '' :
        (totalValue > 0 ? Resources.getResource("general.trading.position.Buy") : Resources.getResource("general.trading.position.Sell"));

    this.set({
        symbolName: symbol ? symbol.DisplayName() : '',
        totalValueStyle: 'color: ' + (totalValue == 0 ? 'none' : (totalValue > 0 ? this.totalLongColor : this.totalShortColor)),
        totalValuePrefix: totalValuePrefix,
        totalValueFormatted: DataCache.formatVolume(symbol, Math.abs(totalValue), this.displayAmountInLots)
    });
};

ClosePositionsMutually.prototype.onGlobalKeyDown = function ()
{
    if (KeyEventProcessor.currentButton === KeyCode.ESC)   // This window can be closed using ESC button as well. 
        this.set('visible', false);     // this.close();
}

ClosePositionsMutually.prototype.dispose = function ()
{
    GeneralSettings.SettingsChanged.UnSubscribe(this.settingsChanged, this);
    Resources.onLocaleChange.UnSubscribe(this.localize, this);
    KeyEventProcessor.OnKeyDown.UnSubscribe(this.onGlobalKeyDown, this);

    ApplicationPanelNew.prototype.dispose.apply(this);
};

ClosePositionsMutually.prototype.layoutTable = function ()
{
    const qtR = this.quickTableRactive;
    if (!qtR) return;

    qtR.setSizes();
};

ClosePositionsMutually.prototype.settingsChanged = function ()
{
    let newSettingsInLots = GeneralSettings.View.displayAmountInLots();
    if (this.displayAmountInLots == newSettingsInLots)
        return;

    this.displayAmountInLots = newSettingsInLots;

    const ins = this.instrument;
    if (ins)
        this.set('totalValueFormatted', DataCache.formatVolume(ins,
            Quantity.convertQuantityValue(this.totalValue, ins, newSettingsInLots),
            newSettingsInLots));
};

ClosePositionsMutually.show = function (positionsIdArray, placedFrom, closeFunction) 
{
    var panel = MainWindowManager.Factory.addPanel(PanelNames.ClosePositionsMutually);

    panel.placedFrom = placedFrom;
    panel.positionsIdArray = positionsIdArray;
    panel.closingFunctionReference = closeFunction

    return panel;
};

let handl = positionsMutualCloseScreenHandler;
handl.show = ClosePositionsMutually.show;