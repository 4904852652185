// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';
import { type EntitlementProductRequestType } from '../../../../Commons/cache/Entitlement/EntitlementPrimitives';

export class PFixEntitlementSubscriptionRequestMessage extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_PFIX_PRODUCT_SUBSCRIPTION_MANAGEMENT_REQ, fieldSet);
    }

    public getProductId (): number {
        return this.getFieldValue(FieldsFactory.FIELD_PRODUCT_ID);
    }

    public setProductId (value: number): void {
        this.setFieldValue(FieldsFactory.FIELD_PRODUCT_ID, value);
    }

    public getRequestType (): EntitlementProductRequestType {
        return this.getFieldValue(FieldsFactory.FIELD_PRODUCT_REQUEST_TYPE);
    }

    public setRequestType (value: EntitlementProductRequestType): void {
        this.setFieldValue(FieldsFactory.FIELD_PRODUCT_REQUEST_TYPE, value);
    }

    public AddDocument (documentGroup): void {
        this.FieldSet.AddGroup(documentGroup);
    }
}
