// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { PriceLimitMeasure } from './PriceLimitMeasure';
import { type StrikePriceSettings } from './StrikePriceSettings';
import { TradingMode } from './TradingMode';
import { type VariableTick } from './VariableTick';

export class InstrDateSettings {
    public DeliveryStatus = 0;
    // синтетически сгенерированная дата.
    // Обычно равна MaturityDate. Для фьючей с показом дня
    // считается по формуле (день_экспирации + месяц_матурити + год_матурити)
    // по ней осуществляется построение тикера, подписка, торговля, запрос истории...
    public ContractMonthDate: Date | null = null;
    // Дата экспирации фьюча (новое поле, для старых серверов=ContractMonthDate)
    public ExpirationDate: Date | null = null;
    // Видимая дата фьюча (новое поле, для старых серверов=ContractMonthDate)
    public MaturityDate: Date | null = null;
    public LastTradeDate: Date | null = null;
    public SettlementDate: Date | null = null;
    public NoticeDate: Date | null = null;
    public FirstTradeDate: Date | null = null;
    public AutoCloseDate: Date | null = null;
    public IsContinious = false;
    public ShowDayForFutures = false;
    public LowLimit = NaN;
    public HightLimit = NaN;
    public PriceLimitMeasure = PriceLimitMeasure.DISABLE;
    public TradingMode = TradingMode.FullyClosed;
    // дата контракта у фьючерсного андерлаера
    public UnderlierDate: Date | null = null;
    // отстутствует - выкл.; присутствует, от 0 до ?
    // в процентах (т.е. значение 100 - это 100%)
    public CheckIntrinsic: number | null = null;
    public StrikePricesList: StrikePriceSettings[] | null = null;
    // For NonFixed list
    public ContractID = 0;
    public minLot: number | null = null;
    public maxLot: number | null = null;
    public LotSize: number | null = null;
    public PointSize: number | null = null;
    public LotStep: number | null = null;
    public FuturesTickCoast: number | null = null;
    public VariableTicks: VariableTick[] | null = null;
    // #57266 - когда имя фьючерса (и каждого страйка) может присылать сервер
    public FutureAliasName: string | null = null;
    public useAliasName = false;
    public Description: string | null = null;
    public AdditionalFields: any = null;
    public TradeSessionStatusId: number | null = null;
    public ExchangeSessionName: string | null = null;

    // #67137 - используется в NonFixedInstumentStrikesResponseMessage,
    // нам здесь присылают алиас андерлаера для страйка
    public Underlier: string | null = null;
    public InstrumentTradableID: number | null = null;
    public ISIN = '';
    public LogoAddress: string | null = null;
    public MarketCap: number;

    public IsHighLimitFrontEndValidationEnabled: boolean;
    public IsLowLimitFrontEndValidationEnabled: boolean;

    public UnderlierTradableId: number;

    public StrikePricesListDict: Record<string, StrikePriceSettings>;
}
