// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
export class OrderEditUpdateData {
    public dpDict: any;
    public tradingDataDict: any;
    public sessionSettingsChanged: any;
    public placedFrom: any;

    constructor (dpDict: any = null, tradingDataDict: any = null, sessionSettingsChanged: any = null, placedFrom: any = null) {
        this.dpDict = dpDict;
        this.tradingDataDict = tradingDataDict;
        this.sessionSettingsChanged = sessionSettingsChanged;
        this.placedFrom = placedFrom;
    }
}
