// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

export let LookupDropDownShowParams = function ()
{
    this.x = 0;
    this.y = 0;
    this.items = [];
    this.callBack = null;
    this.closeCallback = null;
    this.isMultiSelect = false;
    this.selectedItem = null;
    this.isCentered = false;
    this.autoClose = true;
    this.dataProvider = null;
};


LookupDropDownShowParams.CreateInstrumentsItems = function ()
{

}