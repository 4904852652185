// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DateTimeUtils } from '../../../../Utils/Time/DateTimeUtils';
import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { ChartDataType } from '../../../../Chart/Utils/ChartConstants';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { HistoricalDataRequest } from '../HistoricalDataRequest';
import { Period } from '../IndicatorEnums';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';
import { InputParameterRangeControl } from '../InputParamaterClasses/InputParameterRangeControl';

export class VWAP extends IndicatorScriptBase {
    public HideLineTimeShift: boolean;
    public VWPeriod: number;
    public RangeValue: any;
    public SourcePrice: number;
    public RecalcHandler: any;
    public RecalcTimeoutValue: number;
    public RecalcTimeoutHandler: any;
    public ReadyToWork: boolean;
    public endTicks: number;
    public EndDifference: number;
    public revertedTime: boolean;
    public ResultsMap: any;
    public LoadedHistory: any;
    public lastSumPrices: number;
    public lastSumVolume: any;

    constructor () {
        super();
        this.ProjectName = 'Volume Weighted Average Price';
        this.Comments = 'Weighted Moving Average where Volume is used as weights';
        this.SetIndicatorLine('Line', Color.Orange2, 3, LineStyle.SimpleChart);
        this.SeparateWindow = false;
        // #97150
        this.HideLineTimeShift = true;

        this.VWPeriod = 1;
        super.InputParameter(new InputParameterInteger('VWPeriod', 'VWAP.DaysNumber', 0, 1, 366));

        this.RangeValue = this.CreateDefaultTimeObject(0, 0, 23, 59);
        super.InputParameter(new InputParameterRangeControl('RangeValue', 'VWAP.StartEndDayTime', 1));

        // #97150
        this.SourcePrice = PriceType.Typical;

        this.RecalcHandler = this.CalculateLastBar.bind(this);
        this.RecalcTimeoutValue = 10000;
        this.RecalcTimeoutHandler = undefined;
        this.ReadyToWork = false;
        this.endTicks = 0;
        this.EndDifference = 0;
        this.revertedTime = false;
        this.LoadedHistory = null;

        this.ResultsMap = {};
    }

    public Init (): void {
        this.IndicatorShortName('VWAP(' + this.VWPeriod + ')');
    };

    public CreateDefaultTimeObject (startHours, startMins, endHours, endMins): any {
        const tmp = {
            StartHours: startHours,
            StartMins: startMins,
            EndHours: endHours,
            EndMins: endMins
        };

        return tmp;
    };

    public override GetIndicatorShortName (): string {
        return 'VWAP(' + this.VWPeriod + ')';
    };

    public canWork (): boolean {
        return !!(this.CurrentData && this.CurrentData.Period !== Period.Tick);
    };

    public async RefreshPromise (): Promise<any> {
        this.ReadyToWork = false;
        clearTimeout(this.RecalcTimeoutHandler);

        if (!this.canWork()) { await Promise.resolve(); return; }

        await super.RefreshPromise()
            .then(async () => {
                const cd = this.CurrentData;
                const LoadPeriod = Period.Min;
                return await this.GetHistoricalDataPromise(new HistoricalDataRequest({
                    Instrument: cd.Instrument,
                    Period: LoadPeriod,
                    DataType: cd.DataType,
                    fromUTC: new Date(cd.FirstBarOpenTime),
                    toUTC: new Date(DateTimeUtils.DateTimeUtcNow().getTime())
                }));
            })
            .then((LoadedHistory) => {
                this.LoadedHistory = LoadedHistory;
            });
    };

    public async AfterRefreshPromise (): Promise<void> {
        this.ProcessResults();
    };

    public ProcessResults (): void {
        this.ResultsMap = {};
        if (!this.canWork()) { return; };

        const len = this.LoadedHistory.HistoryCount;
        const lh = this.LoadedHistory;
        const cd = this.CurrentData;
        const sum = 0;

        // let initData = this.getCycleStartBar(new Date(), this.VWPeriod)
        // let startIndex = initData.startIndex;
        const startIndex = len - 1;

        const rv = this.RangeValue;

        const evm = rv.EndHours * 60 + rv.EndMins + 1;
        const svm = rv.StartHours * 60 + rv.StartMins;

        this.revertedTime = svm >= evm;

        this.EndDifference = 86400000 - (this.revertedTime ? svm : (evm - 1)) * 1000 * 60;

        let sum1 = 0;
        let sum2 = 0;

        const StartDay = this.GetLeftBarTime(startIndex, lh);
        this.endTicks = 0;
        if (StartDay !== -1) { this.endTicks = this.GetNewCyclePeriodTicks(StartDay); };

        for (let i = startIndex; i >= 0; i--) {
            const filteringTime = this.GetLeftBarTime(i, lh);
            const dif = this.endTicks - filteringTime;
            if (dif <= 0) {
                this.endTicks = this.GetNewCyclePeriodTicks(filteringTime);
                sum1 = 0;
                sum2 = 0;
            }
            const badTimeRange = !this.FilterByTime(filteringTime);
            if (badTimeRange) {
                if (sum2 && dif - this.EndDifference >= 0) { this.ResultsMap[this.GetTime(i, lh)] = sum1 / sum2; };
                continue;
            }
            const volume = lh.Volume(i);
            const val = this.getPriceByTypeAndOffset(this.SourcePrice, i);
            sum1 += val * volume;
            sum2 += volume;

            // this.SetValue(0, i, sum1 / sum2)
            this.ResultsMap[this.GetTime(i, lh)] = sum1 / sum2;
        }

        // let start = Math.trunc(startIndex / cd.Period);
        const start = cd?.HistoryCount - 1;
        const needSearch = cd?.Period > Period.Min;
        const minDifTicks = 60000;

        for (let i = start; i >= 0; i--) {
            const r_time = this.GetTime(i, cd);
            let val = this.ResultsMap[r_time];
            if (val) { this.SetValue(0, i, val); };
            if (!val && needSearch) {
                // Поиск последнего валидного значения в агрегированном интервале
                const curLtTicks = +this.GetLeftBarTime(i, cd);
                let prewBarTime = r_time - minDifTicks;
                while (!val && prewBarTime >= curLtTicks) {
                    val = this.ResultsMap[prewBarTime];
                    prewBarTime = prewBarTime - minDifTicks;
                }
                if (val) { this.SetValue(0, i, val); };
            }
        }
        this.lastSumVolume = sum2;
        this.lastSumPrices = sum1;

        this.ReadyToWork = true;
        clearTimeout(this.RecalcTimeoutHandler);
        this.RecalcTimeoutHandler = setTimeout(this.RecalcHandler, 0);
    };

    public FilterByTime (time): boolean {
        if (time === -1) { return false; }

        const rv = this.RangeValue;
        const min = time.getMinutes();
        const hour = time.getHours();

        const vm = hour * 60 + min;
        const evm = rv.EndHours * 60 + rv.EndMins;
        const svm = rv.StartHours * 60 + rv.StartMins;

        if (!this.revertedTime && svm <= vm && vm <= evm) { return true; }

        if (this.revertedTime && !(evm <= vm && vm <= svm)) { return true; }

        return false;
    };

    public GetNewCyclePeriodTicks (time): number {
        const StartDay = new Date(time);
        StartDay.setHours(0);
        StartDay.setMinutes(0);
        const result = StartDay.setDate(StartDay.getDate() + this.VWPeriod);
        return result;
    };

    public CalculateLastBar (): void {
        clearTimeout(this.RecalcTimeoutHandler);
        this.RecalcTimeoutHandler = setTimeout(this.RecalcHandler, this.RecalcTimeoutValue);

        const filteringTime = this.GetLeftBarTime(0, this.LoadedHistory);
        if (!this.FilterByTime(filteringTime)) { return; }

        const volume = this.LoadedHistory.Volume(0);
        const val = this.getPriceByTypeAndOffset(this.SourcePrice, 0);
        this.SetValue(0, 0, (this.lastSumPrices + val * volume) / (this.lastSumVolume + volume));
    };

    public NextBar (): void {
        super.NextBar();
        if (!this.ReadyToWork) { return; };

        let filteringTime = this.GetLeftBarTime(1, this.LoadedHistory);
        if (this.FilterByTime(filteringTime)) {
            const volume = this.LoadedHistory.Volume(1);
            const val = this.getPriceByTypeAndOffset(this.SourcePrice, 1);
            this.lastSumPrices += val * volume;
            this.lastSumVolume += volume;
            this.SetValue(0, 1, this.lastSumPrices / this.lastSumVolume);
        }

        filteringTime = this.GetLeftBarTime(0, this.LoadedHistory);
        const dif = this.endTicks - filteringTime;
        if (dif <= 0) {
            this.endTicks = this.GetNewCyclePeriodTicks(filteringTime);
            this.lastSumPrices = 0;
            this.lastSumVolume = 0;
        }
        const badTimeRange = !this.FilterByTime(filteringTime);
        if (badTimeRange) {
            if (this.lastSumVolume && dif - this.EndDifference >= 0) { this.SetValue(0, 0, this.lastSumPrices / this.lastSumVolume); };
            return;
        }

        const volume = this.LoadedHistory.Volume(0);
        const val = this.getPriceByTypeAndOffset(this.SourcePrice, 0);
        this.SetValue(0, 0, (this.lastSumPrices + val * volume) / (this.lastSumVolume + volume));
    };

    public GetTime (index, history): any {
        const ci = history.cashItem;
        const count = ci.FNonEmptyCashArrayCount;
        const bi = ci.FNonEmptyCashArray[count - 1 - index];
        if (!bi) { return -1; }
        return bi.FRightTimeTicks;
    };

    public GetLeftBarTime (index, history): any {
        const ci = history.cashItem;
        const count = ci.FNonEmptyCashArrayCount;
        const bi = ci.FNonEmptyCashArray[count - 1 - index];
        if (!bi) { return -1; }
        return bi.LeftTime;
    };

    // Hack
    public getPriceByTypeAndOffset (priceType, offset): number {
        const ci = this.LoadedHistory.cashItem;
        const count = this.LoadedHistory.BarsCountAny(ci);
        if (offset < 0 || offset >= count) { throw new Error('offset ' + offset + ' should be non-negative and smaller than Count'); };

        return ci.GetByType(
            count - 1 - offset,
            ci.Period !== 0 || ci.ChartDataType !== ChartDataType.Default ? priceType : 2/* bid */);
    };

    public Dispose (): void {
        this.ReadyToWork = false;
        clearTimeout(this.RecalcTimeoutHandler);
        super.Dispose();
    };
}
