// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DirectAlgorithmMessageBase } from './DirectAlgorithmMessageBase';
import { Message } from '../Message';
import { type GainerLoserData } from '../../../Commons/cache/Algorithm/GainersLosers/GainerLoserData';
import { type AlgorithmGainersLosersDelayDataGroup, type AlgorithmGainersLosersExchangeGroup, type AlgorithmGainersLosersInstrumentGroup } from '../../../Vendors/PFSVendor/WebFix/Groups/GroupsImport';

export class DirectAlgorithmGainersLosersMessage extends DirectAlgorithmMessageBase {
    constructor () {
        super(Message.CODE_PFIX_ALGORITHM_GAINERS_LOSERS_MESSAGE);
    }

    // this.AlgorithmId = -1;                               // 694 - creating in DirectAlgorithmMessageBase
    // this.AlgorithmName = null;                           // 696 - creating in DirectAlgorithmMessageBase
    AlgorithmTop: number; // 700 - (int) - указывает сколько максимально может быть losers и gainers.

    AlgorithmGainersLosersExchangeGroups: AlgorithmGainersLosersExchangeGroup[]; //  67 - группа гейнеров и лузеров по конкретной бирже с RT датой.Содержит филд FIELD_MD_EXCHANGE_NAME = 698 (String)
    AlgorithmGainersLosersInstrumentGroups: AlgorithmGainersLosersInstrumentGroup[]; //  68 - список лидеров с  идентификатором лидер роста или же лидер падения.
    AlgorithmGainersLosersDelayDataGroups: AlgorithmGainersLosersDelayDataGroup[]; // 100 - группа гейнеров и лузеров по конкретной бирже с Delay датой. Содержит группы: ALGORITHM_GAINERS_LOSERS_EXCHANGE_GROUP = 67, ALGORITHM_GAINERS_LOSERS_INSTRUMENT_GROUP = 68

    GainersLosersData: GainerLoserData[] = [];
}
