// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DirectReportMessage } from '../../../../Utils/DirectMessages/DirectMessagesImport';
import { FieldsFactory } from '../Factory/FieldsFactory';
import { BusinessRejectMessage } from '../Messages/BusinessRejectMessage';
import { MessageProcessorBase } from './MessageProcessorBase';
import { type PFixFieldSet } from '../Fields/FieldsImport';
import { BussinessRejectErrorDictionary } from '../../../../Commons/BuisnesRejectUtils/BuisnesRejectUtils';
import { ReportMessageSource } from '../../../../Utils/Enums/Constants';

export class BusinessRejectMessageProcessor extends MessageProcessorBase {
// TODO. WIP. Refactor. Business reject should be treated differently.
    // Here's workaround for now: creating of report message from business reject message.
    public override decode (fieldSet: PFixFieldSet): DirectReportMessage[] {
        const rejectMsg = new BusinessRejectMessage(fieldSet);

        const reportMessage = new DirectReportMessage();
        const errorCode = fieldSet.GetValue(FieldsFactory.FIELD_ERROR_CODE);
        reportMessage.Name = this.getMessageName(errorCode);
        reportMessage.BusinessRejectCode = errorCode;
        reportMessage.source = ReportMessageSource.BusinessRejectMessage;

        let comment = rejectMsg.getComment();
        const tmp_comment = BussinessRejectErrorDictionary[reportMessage.BusinessRejectCode];
        if (tmp_comment) {
            comment = tmp_comment;
        }

        reportMessage.Data = [['Error', comment]];
        reportMessage.reject = true;
        return [reportMessage];
    }

    private getMessageName (errorCode: number): string {
        if (errorCode === BusinessRejectMessage.OPTION_EXERCISE_ONLY_ITM) {
            return 'general.trading.PositionExerciseReject';
        }
        return 'general.trading.refused';
    }
}
