// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../Commons/properties/Resources.ts";
import { MainWindowManager } from "./UtilsClasses/MainWindowManager.ts";
import { PanelLocKeys } from "./UtilsClasses/FactoryConstants.ts";
import { AUTO_HIDE_AVALIABLE_PANELS_COUNT, AUTO_HIDE_BLOCK_WIDTH, BOTTOM_WINDOWS_MARGIN, PADDING_OFFSET_LEFT, SIDE_BAR_PANEL, TOP_BOTTOM_PADDING_OFFSET, TOP_WINDOWS_MARGIN } from "./UtilsClasses/SizeConstants.ts";

export let DockSystem = function ()
{
    this.dockManager = null;
    this.documentNode = null;
    this.divDockManager = null;
    this.emptyState = null;
    this.parentNodeID = null;
    this.parentNodeDOM = null;
    this.currentPageDockedWindowsForRestore = {};
    this.currentPageDockedWindowsNeedFocusAfterRestore = [];
    this.procPool = {};
    this.AutoHidePanelsPool = {};
    this.AutoHide = false;
    this.AutoHideContainer = null;
    this.DivForFloatWindow = null;
    this.HiddenPanelsContainer = null;
};


DockSystem.InitSystem = function (parentNode)
{
    var parentNodeDOM = document.getElementById(parentNode);
    var innerContainer = document.createElement('div');
    innerContainer.style.display = "inline-block";

    // TODO fix exception when /myportfolio is loading
    if (!parentNodeDOM) return

    var divDockManager = parentNodeDOM.appendChild(innerContainer);
    var dockManager = new dockspawn.DockManager(divDockManager);
    dockManager.initialize();

    var DS = DockSystemInstance;


    DS.dockManager = dockManager;
    DS.divDockManager = divDockManager;
    DS.documentNode = dockManager.context.model.documentManagerNode;
    //DS.emptyState = DockSystemInstance.getState();
    DS.parentNodeID = parentNode;
    DS.parentNodeDOM = parentNodeDOM;

    window.onresize = function () { DockSystemInstance.procPool["resize"] = DockSystemInstance.resizeDockSystem.bind(DockSystemInstance); };
    window.onresize();
    DockSystem.DivForFloatWindow = DS.DivForFloatWindow = document.getElementById("AttachedPanelsContainer");
    DockSystem.HiddenPanelsContainer = DS.HiddenPanelsContainer = document.getElementById("HiddenPanelsContainer");
};

DockSystem.prototype.resizeDockSystem = function ()
{
    if (this.divDockManager && this.dockManager)
    {
        let w = window.innerWidth - (this.divDockManager.clientLeft + 2 * PADDING_OFFSET_LEFT + SIDE_BAR_PANEL) - (this.AutoHide ? AUTO_HIDE_BLOCK_WIDTH : 0);
        let h = window.innerHeight - (this.divDockManager.clientTop + TOP_WINDOWS_MARGIN + BOTTOM_WINDOWS_MARGIN + 2 * TOP_BOTTOM_PADDING_OFFSET);
        this.dockManager.resize(w, h);
        if (this.AutoHideContainer)
            this.AutoHideContainer.style.height = h + "px";
    }

};

DockSystem.prototype.proccess = function ()
{
    if (this.procPool["resize"])
    {
        this.procPool["resize"]();
        delete this.procPool["resize"];
    }
};

DockSystem.prototype.addPanelOnDock = function (panel, dockPlace, lockedWorkspace)
{
    var newDOM_Element = panel.find('*');
    newDOM_Element._ractiveObject = panel;
    var newPanel = new dockspawn.PanelContainer(newDOM_Element, this.dockManager, panel.get('header'));
    panel.setTitle = newPanel.setTitle.bind(newPanel);
    panel.updateLinkVisible = newPanel.updateLinkVisible.bind(newPanel);
    panel.containerSetFocus = newPanel.setFocus.bind(newPanel);
    panel.PanelContainer = newPanel;

    var documentManagerNode = this.dockManager.context.model.documentManagerNode;
    var newDockedPanelNode = null;

    var referenceNode = this.findReferenceNodeForDocking(
        this.dockManager.context.model.rootNode,
        null,
        this.getDockPlaceInfo(dockPlace));

    newPanel.dockPlace = dockPlace

    if (referenceNode)
    {
        var newNode = this.dockManager.CreateDockNode(newPanel);
        documentManagerNode = referenceNode
    }
    else
    {
        if (dockPlace === DockSystem.DockPlaceConst.Middle &&                                    // #88009
            documentManagerNode.container &&
            !documentManagerNode.container.isDocumentManagerContainer)                           // if need correction
        {
            this.documentManagerContainerWasFound = false
            this.correctDocumentManagerContainer(this.dockManager.context.model.rootNode)
            documentManagerNode = this.dockManager.context.model.documentManagerNode;            // update after correction
        }
    }

    let referenceNodeHasSameDockPlace = referenceNode && referenceNode.container.dockPlace == dockPlace
    if (referenceNodeHasSameDockPlace)
        dockPlace = DockSystem.DockPlaceConst.Middle

    switch (dockPlace)
    {
        case DockSystem.DockPlaceConst.Middle:
            newDockedPanelNode = this.dockManager.dockFill(documentManagerNode, newPanel);
            break;
        case DockSystem.DockPlaceConst.Up:
            newDockedPanelNode = this.dockManager.dockUp(documentManagerNode, newPanel, 0.3);
            break;
        case DockSystem.DockPlaceConst.Down:
            newDockedPanelNode = this.dockManager.dockDown(documentManagerNode, newPanel, 0.3);
            break;
        case DockSystem.DockPlaceConst.Left:
            newDockedPanelNode = this.dockManager.dockLeft(documentManagerNode, newPanel, 0.3);
            break;
        case DockSystem.DockPlaceConst.Right:
            newDockedPanelNode = this.dockManager.dockRight(documentManagerNode, newPanel, 0.3);
            break;
    }
    this.tryPermanentlyHideCloseButton(newPanel, panel);
};

DockSystem.prototype.addAutoHideContainer = function ()
{
    let newNode = document.createElement('div');
    newNode.classList.add("oe_vertical_autohide_container")
    this.AutoHideContainer = newNode;
    this.parentNodeDOM.appendChild(newNode)
}

DockSystem.prototype.RegisterPanel = function (panel)
{
    this.AutoHidePanelsPool[panel._guid] = panel;
}

DockSystem.prototype.UnRegisterPanel = function (panel)
{
    delete this.AutoHidePanelsPool[panel._guid]
}

DockSystem.prototype.UpdatePanelsAutoHideSate = function ()
{
    let isAv = this.isAutoHideAvaliable();
    for (let panel_g in this.AutoHidePanelsPool)
    {
        let panel = this.AutoHidePanelsPool[panel_g];
        if (panel && panel.UpdateAutoHideSate)
            panel.UpdateAutoHideSate(isAv);
    }
}

DockSystem.prototype.ForcedHideOthers = function (iniPanel)
{
    let panel_ID = iniPanel._guid;
    for (let panel_g in this.AutoHidePanelsPool)
    {
        if (panel_g === panel_ID)
            continue;

        let panel = this.AutoHidePanelsPool[panel_g];
        if (panel && panel.ForcedAutoHide)
            panel.ForcedAutoHide();
    }
}

DockSystem.prototype.isAutoHideAvaliable = function ()
{
    if (!this.AutoHideContainer)
        return true

    return DockSystemInstance.AutoHideContainer.childElementCount < AUTO_HIDE_AVALIABLE_PANELS_COUNT
}

DockSystem.prototype.addToAutoHide = function (panel)
{
    let newNode = document.createElement('div');
    newNode.classList.add("oe_vertical_autohide")
    newNode.innerText = panel.get("header");

    this.AutoHide = true;
    if (!this.AutoHideContainer)
        this.addAutoHideContainer()
    this.AutoHideContainer.appendChild(newNode)

    panel._myNodeAutoHide = newNode;
    panel.AutoHideHeaderUpdateHandler = function (headerText) { this._myNodeAutoHide.innerText = headerText };
    let me = this;
    newNode.addEventListener("mouseenter", function (event)
    {
        panel.onMouseEnter();
        me.ForcedHideOthers(panel);
    })
    newNode.addEventListener("mouseleave", function (event)
    {
        panel.onMouseLeave();
    })
    window.onresize()
    this.UpdatePanelsAutoHideSate();
}

DockSystem.prototype.removeFromAutoHide = function (panel)
{
    let node = panel._myNodeAutoHide
    panel.AutoHideHeaderUpdateHandler = null;
    if (node)
        node.parentNode.removeChild(node)
    if (!this.AutoHideContainer)
        this.AutoHide = false;
    if (this.AutoHideContainer && this.AutoHideContainer.childElementCount === 0)
    {
        this.AutoHide = false;
        this.AutoHideContainer.parentNode.removeChild(this.AutoHideContainer)
        this.AutoHideContainer = null;
    }

    window.onresize()
    this.UpdatePanelsAutoHideSate();
}

DockSystem.prototype.tryPermanentlyHideCloseButton = function (panelContainer, panel)
{
    // +++
    var panelType = panel.getType();
    if (panelType)
    {
        var panelTypeLocKey = PanelLocKeys[panelType];
        if (panelTypeLocKey && Resources.isHidden(panelTypeLocKey + '.allowPanelCloseFromButton'))
        {
            panel.set('isCloseButtonAlwaysHidden', true);
            panelContainer.hideCloseButton(true);
        }
    }
};

DockSystem.prototype.addToArrRestore = function (panel)
{
    var newDOM_Element = panel.find('*');
    newDOM_Element._ractiveObject = panel;

    var id = panel.get('workSpaceId');
    if (id !== null)
        this.currentPageDockedWindowsForRestore[id] = newDOM_Element;
};

DockSystem.prototype.clearDocking = function ()
{
    $(this.parentNodeDOM).empty();
    $(DockSystem.DivForFloatWindow).empty()
    DockSystem.InitSystem(this.parentNodeID);
    this.currentPageDockedWindowsForRestore = {};


    this.AutoHidePanelsPool = {};
    this.AutoHide = false;
    this.AutoHideContainer = null;
};

DockSystem.prototype.getState = function ()
{
    var state = this.dockManager.saveState();
    //console.log(state);
    return state;
};

DockSystem.prototype.refresh = function ()
{
    this.dockManager.rebuildLayout(this.dockManager.context.model.documentManagerNode);
};

DockSystem.prototype.setState = function (state)
{
    if (state)
    {
        this.dockManager.loadState(state);
        this.MigationProcess();
    }
};

DockSystem.prototype.getPanelForDocking = function (id)
{
    var panel = this.currentPageDockedWindowsForRestore[id];
    if (!panel)
        return null;
    delete this.currentPageDockedWindowsForRestore[id];
    return panel;
};

DockSystem.prototype.goInProcces = function (panel, defaultDockPlace)
{
    if (!panel) return;

    panel.dockPlace = defaultDockPlace
    panel.append = true;
    panel.render(DockSystem.HiddenPanelsContainer);
    MainWindowManager.MainWindow.registerControl(panel);
}

DockSystem.prototype.setPlace = function (id, dockPlace)
{
    var panel = this.getPanelForDocking(id);

    var toRestoreContainer = this.dockManager.restoredHashContainers[id];
    if (!panel || !panel._ractiveObject || !toRestoreContainer)
        return;
    panel._ractiveObject.setSize(toRestoreContainer.elementContentHost.clientWidth, toRestoreContainer.elementContentHost.clientHeight);
    panel._ractiveObject.setTitle = toRestoreContainer.setTitle.bind(toRestoreContainer);
    panel._ractiveObject.containerSetFocus = toRestoreContainer.setFocus.bind(toRestoreContainer);
    panel._ractiveObject.setTitle(panel._ractiveObject.get('header'));
    panel._ractiveObject.updateLinkVisible = toRestoreContainer.updateLinkVisible.bind(toRestoreContainer);
    toRestoreContainer.elementContent.parentElement.replaceChild(panel, toRestoreContainer.elementContent);

    toRestoreContainer.elementContent = panel;
    toRestoreContainer.dockPlace = dockPlace;
    toRestoreContainer.createHeaderMenuButton();
    toRestoreContainer.createFullscreenModeButton();
    toRestoreContainer.createLinkElements();

    this.tryPermanentlyHideCloseButton(toRestoreContainer, panel._ractiveObject);

    if (toRestoreContainer.restoredActiveFlag)
        this.currentPageDockedWindowsNeedFocusAfterRestore.push(panel._ractiveObject);

    if (Object.keys(this.currentPageDockedWindowsForRestore).length === 0)
    {
        var i = 0;
        var len = this.currentPageDockedWindowsNeedFocusAfterRestore.length;
        for (i = 0; i < len; i++)
            this.currentPageDockedWindowsNeedFocusAfterRestore[i].setFocus();
        this.currentPageDockedWindowsNeedFocusAfterRestore = [];
    }
}

// Ignores newPanelNode.
DockSystem.prototype.findReferenceNodeForDocking = function (startNode, newPanelNode, dockPlaceInfo)
{
    if (startNode === newPanelNode || !dockPlaceInfo)
        return null;

    var children = startNode.children;
    var len = children ? children.length : 0;

    var containerType = startNode.container.containerType;
    var useFirstMatchedNode = dockPlaceInfo.useFirstMatchedNode;
    var chosenContainerType = dockPlaceInfo.containerType;
    if (chosenContainerType === containerType)
    {
        if (len)
        {
            return this.getFirstPanel(
                children[useFirstMatchedNode ? 0 : len - 1],
                newPanelNode);
        }
        else return null;
    }
    else
    {
        for (var i = useFirstMatchedNode ? 0 : len - 1;
            useFirstMatchedNode ? i < len : i >= 0;
            useFirstMatchedNode ? i++ : i--)
        {
            var result = this.findReferenceNodeForDocking(
                children[i],
                newPanelNode,
                dockPlaceInfo);

            if (result) return result;
        }
    }

    return null;
};

// Ignores newPanelNode.
DockSystem.prototype.getFirstPanel = function (node, newPanelNode)
{
    if (!node || node === newPanelNode)
        return null;

    if (node.container.containerType === 'panel' || (node.container.containerType === 'fill') && !node.children.length)
        return node;

    var children = node.children;
    var len = children ? children.length : 0;
    for (var i = 0; i < len; i++)
    {
        var result = this.getFirstPanel(children[i], newPanelNode);
        if (result) return result;
    }

    return null;
};

DockSystem.prototype.getDockPlaceInfo = function (dockPlace)
{
    switch (dockPlace)
    {
        case DockSystem.DockPlaceConst.Middle:
            return null;
        case DockSystem.DockPlaceConst.Up:
            return { containerType: 'vertical', useFirstMatchedNode: true };
        case DockSystem.DockPlaceConst.Down:
            return { containerType: 'vertical', useFirstMatchedNode: false };
        case DockSystem.DockPlaceConst.Left:
            return { containerType: 'horizontal', useFirstMatchedNode: true };
        case DockSystem.DockPlaceConst.Right:
            return { containerType: 'horizontal', useFirstMatchedNode: false };
    }

    return null;
};

DockSystem.prototype.correctDocumentManagerContainer = function (curNode)                    // #88009
{
    if (this.documentManagerContainerWasFound)
        return

    if (curNode.container && curNode.container.isDocumentManagerContainer)
    {
        this.dockManager.context.model.documentManagerNode = curNode
        this.documentManagerContainerWasFound = true
        return
    }

    let childs = curNode.children

    for (let i = 0; i < childs.length; i++)
        this.correctDocumentManagerContainer(childs[i])
}

DockSystem.prototype.deletePanelFromDockSystemState = function (id)
{
    if (!this.toRemoveHash)
        this.toRemoveHash = []
    this.toRemoveHash.push(id);
}

DockSystem.prototype.MigationProcess = function ()
{
    if (!this.toRemoveHash)
        return;

    for (let i = 0; i < this.toRemoveHash.length; i++)
    {
        let id = this.toRemoveHash[i];
        var toRestoreContainer = this.dockManager.restoredHashContainers[id];
        if (!toRestoreContainer)
            return;
        toRestoreContainer.onCloseButtonClicked()
    }
    this.toRemoveHash.length = 0;
}

DockSystem.DockPlaceConst =
{
    Middle: 0,
    Up: 1,
    Down: 2,
    Left: 3,
    Right: 4
};
Object.freeze(DockSystem.DockPlaceConst);

DockSystem.DivForFloatWindow;

//need forTest Dock system
var hierarhyTest = function ()
{
    DockSystemInstance.dockManager.layoutEngine.logHierarhy(DockSystemInstance.dockManager.context.model.rootNode, 0);
}



export let DockSystemInstance = new DockSystem();
//window HACK
window.DockSystem = DockSystemInstance