// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts";
import { EventItem } from "../cache/EventItem.ts";
import { TerceraMenu } from "../elements/TerceraMenu.ts";
import { ReportMessageTooltip } from "../../Utils/ReportMessageTooltip.ts";
import { TerceraTicketScreen } from "../screen/TerceraTicketScreen.js";
import { PanelNames } from "../UtilsClasses/FactoryConstants.ts";
import { ApplicationPanelNew } from "./ApplicationPanelNew.js";
import { EventType } from "../../Commons/cache/EventConstants.ts";
import { EventCache } from "../../Commons/cache/EventCache.ts"
import { DataCache } from "../../Commons/DataCache.ts";
import { SessionSettings } from "../../Commons/SessionSettings.ts";


export let EventsPanel = ApplicationPanelNew.extend(
    {
        data: function ()
        {
            return {
                zIndex: 1300,
                showHeader: true,
                showFooter: false,
                dockablePanel: false,
                resizable: true,
                width: 680,
                height: 300,
                closeBtnVisible: false,
                movable: false,
                canLinkByAccount: false,
            }
        },
        headerLocaleKey: 'panel.EventLog.NamePanel',
        skipOnSave: true,
        NeedCalculateRowCount: false,
        allowResizers: {
            left: true,
            top: true,
            right: false,
            bottom: false
        }
    });

EventsPanel.prototype.getType = function () { return PanelNames.EventsPanel };

EventsPanel.prototype.oncomplete = function ()
{
    ApplicationPanelNew.prototype.oncomplete.apply(this);

    this.localize();
    const qt = this.getQuickTable();
    qt.AddToEnd = false;
    qt.isMoveColumnAllowed = false;
    qt.canShowHeaderMenu = false;        // #88411

    this.populateTableContextMenu();

    qt.OnPaintedPictureButtonClick.Subscribe(this.getEventTicket, this);

    let evtCache = EventCache;
    evtCache.OnAddEvent.Subscribe(this.OnAddEvent, this);
    evtCache.OnRemoveEvent.Subscribe(this.OnRemoveEvent, this);
    evtCache.OnClearEvents.Subscribe(this.OnClearEvents, this);
};

EventsPanel.prototype.dispose = function ()
{
    ApplicationPanelNew.prototype.dispose.call(this);

    let evtCache = EventCache;

    evtCache.OnAddEvent.UnSubscribe(this.OnAddEvent, this);
    evtCache.OnRemoveEvent.UnSubscribe(this.OnRemoveEvent, this);
    evtCache.OnClearEvents.UnSubscribe(this.OnClearEvents, this);
};

EventsPanel.prototype.localize = function ()
{
    ApplicationPanelNew.prototype.localize.apply(this);
};

EventsPanel.prototype.populateItemsDirect = function ()
{
    ApplicationPanelNew.prototype.populateItemsDirect.apply(this);

    let eventArr = EventCache.GetEvents();
    let len = eventArr.length
    for (let i = 0; i < len; i++)
        this.OnAddEvent(eventArr[i]);
};

EventsPanel.prototype.OnAddEvent = function (ev)
{
    let qt = this.getQuickTable();

    let colors = ev.GetColorRow(this.EventColoring);

    let row = qt.AddItem(new EventItem(ev, SessionSettings, DataCache, colors.ForeColor, colors.BackColor));
};

EventsPanel.prototype.OnRemoveEvent = function (id)
{
    let qt = this.getQuickTable();
    qt.RemoveItem(id);
};

EventsPanel.prototype.OnClearEvents = function ()
{
    let qt = this.getQuickTable();
    if (qt)
        qt.ClearAll();
};

EventsPanel.prototype.getEventTicket = function (data)
{
    let qt = this.getQuickTable();

    let row = data.row;
    if (!row) return;

    let event = row.item.ev;

    let ticket = TerceraTicketScreen.TicketFromEvent(event)
    // TODO. UGLY.
    if (event.EventType === EventType.Trading)
        ReportMessageTooltip.fixEntries(
            ticket.sortedEntries,
            SessionSettings,
            event.Instrument)

    ReportMessageTooltip.localizeEntries(ticket.sortedEntries)
    TerceraTicketScreen.show(ticket)
};

EventsPanel.prototype.populateTableContextMenu = function ()
{
    var items = [
        {
            text: Resources.getResource('panel.EventLog.menu.Clear'),
            event: this.onRemoveAll.bind(this),
            enabled: false,
            tag: EventsPanel.REMOVE_ALL
        },
    ];

    this.menuTagDict = TerceraMenu.createTagDictionary(items);
    this.getQuickTable().setTableContextMenuItems(items);
};

EventsPanel.REMOVE_ALL = 'REMOVE_ALL';

EventsPanel.prototype.onRemoveAll = function ()
{
    let qtRactive = this.quickTableRactive;
    let qt = qtRactive ? qtRactive.quickTable : null;
    if (!qt) return;

    qt.ClearRows();
};

EventsPanel.prototype.preparePopup = function ()
{
    ApplicationPanelNew.prototype.preparePopup.apply(this);

    this.menuTagDict[EventsPanel.REMOVE_ALL].enabled =
        this.getQuickTable().rowsArray.length > 0;
};
