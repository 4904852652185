// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Point } from '../../Commons/Geometry';
import { DataCacheToolRayType, DataCacheToolType } from '../../Commons/cache/DataCacheToolEnums';
import { type ElliotWavesDataCacheTool } from '../../Commons/cache/Tools/ElliotWavesDataCacheTool';
import { ToolView } from './ToolView';
import { SelectionState } from './Selection';

export class ElliotWaveToolView extends ToolView<ElliotWavesDataCacheTool> {
    constructor (tool: ElliotWavesDataCacheTool, fromRepopulate) {
        super(tool);

        this.InitPointLabels(tool);

        if (!fromRepopulate) {
            this.SelectTool();
        }
    }

    public override Draw (gr, ww, param): void {
        param.isNeedConnectFirstAndLastPoints = false; // first comment in #115784

        super.Draw(gr, ww, param);
        this.DrawElliot(gr, ww, false);
    }

    public DrawElliot (gr, ww, highlight): void {
        if (this.isDisposed || gr == null || ww == null || this.dataCacheTool == null) {
            return;
        }

        const cacheTool = this.dataCacheTool;
        // const pen = highlight ? cacheTool.PenHighlight : cacheTool.Pen;
        // const clientRect = ww.ClientRectangle;
        const screenPoints = this.screenPoints;

        const pointsCount = /* this.CurrentMovement.CreationState ? dataCacheTool.PointsInitialized : */ cacheTool.PointLevel();
        if (pointsCount < 2) {
            return;
        }
        // Draw Lines
        for (let i = 0; i < pointsCount - 1; i++) {
            const start = new Point(screenPoints[i][0], screenPoints[i][1]);
            const end = new Point(screenPoints[i + 1][0], screenPoints[i + 1][1]);
            ToolView.DrawLineWithRay(gr, ww, start.X, start.Y, end.X, end.Y, DataCacheToolRayType.NoRay, cacheTool.Pen);
        }
        // Draw labels

        const labelsDrawNumber = cacheTool.InitedPointsNumber;

        for (let i = 0; i < pointsCount - 1; i++) {
            const start = new Point(screenPoints[i][0], screenPoints[i][1]);
            const end = new Point(screenPoints[i + 1][0], screenPoints[i + 1][1]);

            if (i < labelsDrawNumber) {
                if (i === 0) // Draw first label
                {
                    const drawFirstLabel = this.CurrentSelection.CurrentState === SelectionState.Selected || !cacheTool.completed;
                    if (drawFirstLabel) {
                        this.DrawLabel(gr, i, start, start.Y > end.Y);
                    }
                }

                this.DrawLabel(gr, i + 1, end, end.Y > start.Y);
            }
        }
    }

    public DrawLabel (gr, index, point, isTop): void {
        const tool = this.dataCacheTool;
        // const scrP = this.screenPoints;
        // const curM = this.CurrentMovement;

        const label = this.GetPointLabel(index);

        if (!tool || !label) {
            return;
        }

        const labelTextWidth = gr.GetTextWidth(label, tool.font);
        const labelSize = labelTextWidth + ElliotWaveToolView.LABEL_PADDING;
        // const offset = ElliotWaveToolView.LABEL_OFFSET;
        // const bgColor = tool.labelBackgroundColor;
        const x = point.X - labelSize / 2;
        const y = isTop ? point.Y + ElliotWaveToolView.UPPER_LABEL_Y_OFFSET : point.Y - ElliotWaveToolView.LOWER_LABEL_Y_OFFSET;
        // const labelRect = new Rectangle(x + 4, y - labelSize + (isTop ? 6 : 0), labelSize - 9, labelSize);

        // let coloredLabelBorder = this.CurrentSelection.CurrentState == SelectionState.Selected,
        //     pen = coloredLabelBorder ? tool.Pen : null
        // // Draw label's plate
        // // gr.FillEllipse(tool.labelBackgroundBrush, x - labelSize / 3 , y - labelSize, labelSize , 2 * labelSize )
        // if (coloredLabelBorder)
        //     gr.RoundRectangleGradient(labelRect, ElliotWaveToolView.LABEL_BORDER_RADIUS, tool.Color, bgColor, tool.Pen) //, true, x - labelSize / 3, y - labelSize, labelSize, 2 * labelSize)

        gr.SimpleRoundRectangle(x, y - labelSize, labelSize, 1.5 * labelSize, ElliotWaveToolView.LABEL_BORDER_RADIUS, tool.labelBackgroundBrush);//, pen)

        // Draw label's text
        gr.DrawString(label, tool.font, tool.FontBrush, point.X - labelTextWidth / 2, y - 0.6 * labelSize); // ToolView.DrawTextByAngle(gr, label, tool.font, tool.FontBrush, x - labelSize, y - 0.75 * labelSize, x + labelSize, y + labelSize, 0/*,rotation */)
    }

    public InitPointLabels (tool): void {
        if (!tool) {
            return;
        }

        this.AddPointLabel(0, '0');

        switch (tool.ToolType) {
        case DataCacheToolType.ElliottImpulse:
            this.AddPointLabel(1, '1');
            this.AddPointLabel(2, '2');
            this.AddPointLabel(3, '3');
            this.AddPointLabel(4, '4');
            this.AddPointLabel(5, '5');
            break;
        case DataCacheToolType.ElliottCorrection:
            this.AddPointLabel(1, 'A');
            this.AddPointLabel(2, 'B');
            this.AddPointLabel(3, 'C');
            break;
        case DataCacheToolType.ElliottTriangle:
            this.AddPointLabel(1, 'A');
            this.AddPointLabel(2, 'B');
            this.AddPointLabel(3, 'C');
            this.AddPointLabel(4, 'D');
            this.AddPointLabel(5, 'E');
            break;
        case DataCacheToolType.ElliottTripleCombo:
            this.AddPointLabel(1, 'W');
            this.AddPointLabel(2, 'X');
            this.AddPointLabel(3, 'Y');
            this.AddPointLabel(4, 'X');
            this.AddPointLabel(5, 'Z');
            break;
        case DataCacheToolType.ElliottDoubleCombo:
            this.AddPointLabel(1, 'W');
            this.AddPointLabel(2, 'X');
            this.AddPointLabel(3, 'Y');
            break;
        }

    // const cacheTool = this.dataCacheTool;
    // if (cacheTool)
    //     cacheTool.InitPointLabels()
    }

    public static readonly LABEL_BORDER_RADIUS = [4]; // all corners with 4px border-radius
    public static readonly LABEL_PADDING = 8;
    public static readonly LABEL_SIZE = 14;
    public static readonly LABEL_OFFSET = 10;
    public static readonly UPPER_LABEL_Y_OFFSET = (() => {
        return ElliotWaveToolView.LABEL_OFFSET + ElliotWaveToolView.LABEL_SIZE;
    })();

    public static readonly LOWER_LABEL_Y_OFFSET = (() => {
        return ElliotWaveToolView.LABEL_OFFSET + 0.5 * ElliotWaveToolView.LABEL_SIZE;
    })();
}
