import { QuickTableComparingType } from '../../../Utils/QuickTableMisc/QuickTableComparingType';
import { QuickTableColumnType, QuickTableEditingInfo } from '../../elements/QuickTable/QuickTableMisc';
import { BaseItem, ColumnData } from '../BaseItem';
import { ColumnParams } from '../ColumnParams';
import { type SessionSettings } from '../../../Commons/SessionSettings';
import { type AnalyzerVolatilityLine } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionAnalyzer/AnalyzerVolatilityLine';
import { DynProperty } from '../../../Commons/DynProperty';
import { AnalyzerLinesType } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionAnalyzer/AnalyzerLinesType';
import { NumericUtils } from '../../../Utils/NumericUtils';

export class OptionAnalyzerLineTableItem extends BaseItem {
    public readonly analyzerLine: AnalyzerVolatilityLine;
    constructor (analyzerLine: AnalyzerVolatilityLine, lineType: AnalyzerLinesType, sessionSettings: typeof SessionSettings) {
        super(sessionSettings);
        this.analyzerLine = analyzerLine;
        this.QuickTableEditingInfoMap[OptionAnalyzerLineTableItem.ACTIVE_COL_INDEX] = new QuickTableEditingInfo(DynProperty.BOOLEAN);
        const widthEditingInfo = new QuickTableEditingInfo(DynProperty.DOUBLE);
        widthEditingInfo.Min = 1;
        widthEditingInfo.Max = 10;
        widthEditingInfo.Inc = 1;
        widthEditingInfo.DecimalPlaces = 0;
        this.QuickTableEditingInfoMap[OptionAnalyzerLineTableItem.WIDTH_COL_INDEX] = widthEditingInfo;
        this.QuickTableEditingInfoMap[OptionAnalyzerLineTableItem.COLOR_COL_INDEX] = new QuickTableEditingInfo(DynProperty.COLOR);

        const valueEditingInfo = new QuickTableEditingInfo(DynProperty.DOUBLE);
        if (lineType === AnalyzerLinesType.TimeChange) {
            valueEditingInfo.Min = 0;
            valueEditingInfo.Max = NumericUtils.MAXVALUE;
            valueEditingInfo.Inc = 1;
            valueEditingInfo.DecimalPlaces = 0;
        } else {
            valueEditingInfo.Min = NumericUtils.MINVALUE;
            valueEditingInfo.Max = NumericUtils.MAXVALUE;
            valueEditingInfo.Inc = 0.01;
            valueEditingInfo.DecimalPlaces = 2;
        }
        this.QuickTableEditingInfoMap[OptionAnalyzerLineTableItem.VALUE_COL_INDEX] = valueEditingInfo;
    }

    public override getColumnData (column: number): ColumnData {
        const value = this.getColumnValue(column);
        return new ColumnData(value, value.toString());
    }

    public override getColumnValue (column: number): any {
        switch (column) {
        case OptionAnalyzerLineTableItem.ACTIVE_COL_INDEX:
            return this.analyzerLine.isActive;
        case OptionAnalyzerLineTableItem.VALUE_COL_INDEX:
            return this.analyzerLine.value;
        case OptionAnalyzerLineTableItem.WIDTH_COL_INDEX:
            return this.analyzerLine.width;
        case OptionAnalyzerLineTableItem.COLOR_COL_INDEX:
            return this.analyzerLine.color;
        default:
            return undefined;
        }
    }

    public override ColumnCount (): number {
        return OptionAnalyzerLineTableItem.columnsParams.length;
    }

    public override GetColumnParams (i: number): any {
        return OptionAnalyzerLineTableItem.columnsParams[i];
    }

    public override GetDataType (columnIndex: number): number {
        return OptionAnalyzerLineTableItem.dataTypes[columnIndex];
    }

    static readonly ACTIVE_COL_INDEX = 0;
    static readonly VALUE_COL_INDEX = 1;
    static readonly WIDTH_COL_INDEX = 2;
    static readonly COLOR_COL_INDEX = 3;
    static columnsParams: ColumnParams[] =
        [
            new ColumnParams('panel.optionAnalyzer.active', 20, QuickTableColumnType.COL_DEFAULT, false, true, false),
            new ColumnParams('panel.optionAnalyzer.value', 40, QuickTableColumnType.COL_DEFAULT, false, true, false),
            new ColumnParams('panel.optionAnalyzer.width', 20, QuickTableColumnType.COL_DEFAULT, false, true, false),
            new ColumnParams('panel.optionAnalyzer.color', 25, QuickTableColumnType.COL_DEFAULT, false, true, false)
        ];

    private static readonly dataTypes = [
        QuickTableComparingType.String,
        QuickTableComparingType.Int,
        QuickTableComparingType.Int,
        QuickTableComparingType.String
    ];
}
