// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts";
import { Rectangle } from "../../Commons/Geometry.ts";
import { TerceraMenu } from "../elements/TerceraMenu.ts";
import { BOTTOM_WINDOWS_MARGIN } from "../UtilsClasses/SizeConstants.ts";
import { GeneralSettings } from "../../Utils/GeneralSettings/GeneralSettings.ts";
import { tooltipHandler } from "../../Utils/AppHandlers.js";
import Ractive from "ractive";

export let RactiveTooltip = {};

RactiveTooltip.getTooltip = function ()
{
    if (!this.$tooltip)
        this.$tooltip = $('#tooltipContainer div');

    return this.$tooltip;
};


RactiveTooltip.X_OFFSET = 5
RactiveTooltip.Y_OFFSET = 20//pointer height
RactiveTooltip.showTooltip = function (text)
{
    text = Resources.getResource(text);
    text = text.replace("\n", "<br>");
    var $tooltip = this.getTooltip();
    $tooltip.html(text);
    let w = $tooltip.outerWidth();
    let h = $tooltip.outerHeight()
    var newCoord = TerceraMenu.CorrectPopupLocation(new Rectangle(this.lastMouseMoveX - RactiveTooltip.X_OFFSET, this.lastMouseMoveY + RactiveTooltip.Y_OFFSET, w, h), null);

    if (window.innerHeight - RactiveTooltip.Y_OFFSET > h + newCoord.newY + BOTTOM_WINDOWS_MARGIN)
        $tooltip.addClass("ractive-tooltip-upper");
    else
    {
        $tooltip.addClass("ractive-tooltip-lower");
        newCoord.newY = this.lastMouseMoveY - h - RactiveTooltip.Y_OFFSET
    }

    $tooltip.css({
        left: newCoord.newX,
        top: newCoord.newY
    });

    this.setTooltipVisibility(true);
};

RactiveTooltip.setTooltipVisibility = function (visible)
{
    var $tooltip = this.getTooltip();
    $tooltip.css('display', visible ? 'block' : 'none');
    if (!visible)
    {
        $tooltip.removeClass("ractive-tooltip-upper")
        $tooltip.removeClass("ractive-tooltip-lower")
    }
};

RactiveTooltip.onDocumentMouseMove = function (event)
{
    RactiveTooltip.lastMouseMoveX = event.clientX;
    RactiveTooltip.lastMouseMoveY = event.clientY;
};
document.addEventListener('mousemove', RactiveTooltip.onDocumentMouseMove);

RactiveTooltip.decorator = function (node, text)
{
    var $node = $(node);
    var timeout = null;

    var mouseover = function (event)
    {

        if (event.currentTarget !== event.target ||
            !text ||
            !GeneralSettings.View.Tooltips)
            return;

        timeout = setTimeout(function (text)
        {
            RactiveTooltip.showTooltip(text);
        }, 500, text);
    };

    var mouseout = function ()
    {
        if (timeout) clearTimeout(timeout);
        RactiveTooltip.setTooltipVisibility(false);
    };

    $node.on('mouseenter', mouseover);
    $node.on('mouseleave', mouseout);
    $node.on('click', mouseout);

    return {
        teardown: function ()
        {
            $node.off('mouseenter', mouseover);
            $node.off('mouseleave', mouseout);
        }
    }
};

Ractive.decorators.tooltip = RactiveTooltip.decorator;

let handler = tooltipHandler;
handler.showTooltip = RactiveTooltip.showTooltip.bind(RactiveTooltip);
handler.setTooltipVisibility = RactiveTooltip.setTooltipVisibility.bind(RactiveTooltip);