// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PFixFieldSet } from '../Fields/FieldsImport';
import { FieldsFactory } from '../Factory/FieldsFactory';

export class AliasesGroup extends PFixFieldSet {
    public Data: any;

    constructor (pfixFieldSet) {
        super(FieldsFactory.I18N_NAMES_GROUP, null, pfixFieldSet);

        this.Data = AliasesGroup.GetFromFieldSet(pfixFieldSet);
    }

    public static GetFromFieldSet (fieldSet, onlyNameInResult = false): any {
        const aliasesGroups = fieldSet.GetGroups(FieldsFactory.I18N_NAMES_GROUP);

        if (aliasesGroups.length == 0) {
            return null;
        }

        const aliases = Object();

        for (let i = 0; i < aliasesGroups.length; i++) {
            const alias = aliasesGroups[i];
            const lang = alias.GetValue(FieldsFactory.FIELD_LANG);
            const name = alias.GetValue(FieldsFactory.FIELD_NAME);
            const description = alias.GetValue(FieldsFactory.FIELD_DESCRIPTION);

            const result = onlyNameInResult ? name : { Name: name, Description: description };
            aliases[lang] = result;
        }

        return aliases;
    }
}
