// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
export * from "./ControlsImport.js";
export * from "./ScreensImport.js";
export * from "./Extensions.ts";
import { App } from "./Commons/Application.ts"
import { logs } from "./Controls/panels/FullLogsPanel.js";
import { Resources } from "./Commons/properties/Resources.ts";
export * from "./Controls/MadeRactiveGlobal.ts";

window.addEventListener('load', function ()
{
    let me = document.getElementById("build_time");
    App.Initialize()

    window.ShowAllLocalizationKeys = (isShow) => { Resources.ShowAllLocalizationKeys(isShow) };
    window.logs = logs;
})