// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class BrandingRulesRequestMessage extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_PFIX_BRANDING_RULES_REQ, fieldSet);
    }

    public setBrandingKey (brandingKey: string): void {
        this.setFieldValue(FieldsFactory.FIELD_KEY, brandingKey);
    }
}
