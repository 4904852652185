// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from "../../Utils/CustomEvents.ts";
import { ControlsTypes } from "../UtilsClasses/FactoryConstants.ts";
import { TerceraRadioButtonComponent } from "./TerceraRadioButtonComponent.js";

export let TerceraRadioComboItemGroup = TerceraRadioButtonComponent.extend({});

TerceraRadioComboItemGroup.prototype.getType = function () { return ControlsTypes.TerceraRadioComboItemGroup; }

TerceraRadioComboItemGroup.prototype.oninit = function ()
{
    TerceraRadioButtonComponent.prototype.oninit.apply(this);

    this.SelectedValueChanged = new CustomEvent();
    this.on(TerceraRadioButtonComponent.Events.StateChange, this.onStateChanged);
};

TerceraRadioComboItemGroup.prototype.onStateChanged = function (sender, selectedRBIdx)
{
    this.SelectedValueChanged.Raise(this, this.get('radioItems')[selectedRBIdx].value);
};

TerceraRadioComboItemGroup.prototype.setItems = function (selectedValue, items)
{
    if (!items) return;

    var len = items.length;
    for (var i = 0; i < len; i++)
    {
        var item = items[i];
        if (item.value === selectedValue)
        {
            item.checked = true;
            break;
        }
    }

    this.set('radioItems', items);
}