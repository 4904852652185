// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { type AndrewsPitchforkDataCacheTool } from '../../Commons/cache/Tools/AndrewsPitchforkDataCacheTool';
import { ChartMath } from '../Utils/ChartMath';
import { ToolView } from './ToolView';

export class AndrewsPitchforkToolView extends ToolView<AndrewsPitchforkDataCacheTool> {
    public override IsSelectCheck (x: number, y: number): boolean {
        const TOOL_DX = ToolView.TOOL_DX;
        const screenPoints = this.screenPoints;
        const p0 = screenPoints[0];
        const p1 = screenPoints[1];
        const p2 = screenPoints[2];

        if ((ChartMath.CalcDistanceFromPointToSection(p0, p1, x, y) <= TOOL_DX) ||
            (ChartMath.CalcDistanceFromPointToSection(p0, p2, x, y) <= TOOL_DX)) {
            return true;
        }

        return false;
    }

    public override Draw (gr, ww, param): void {
        const dataCacheTool = this.dataCacheTool;

        const pen = dataCacheTool.Pen;
        const penRay = dataCacheTool.PenRay;
        const ray = dataCacheTool.RayType;

        const screenPoints = this.screenPoints;

        const p0 = screenPoints[0];
        const x0 = p0[0];
        const y0 = p0[1];

        const p1 = screenPoints[1];
        const x1 = p1[0];
        const y1 = p1[1];

        const p2 = screenPoints[2];
        const x2 = p2[0];
        const y2 = p2[1];

        const yc = (y2 + y1) / 2;
        const xc = (x2 + x1) / 2;
        const dx = xc - x0;
        const dy = yc - y0;

        ToolView.DrawLineWithRay(gr, ww, x0, y0, xc, yc, ray, pen, penRay);
        ToolView.DrawLineWithRay(gr, ww, x1, y1, x1 + dx, y1 + dy, ray, pen, penRay);
        ToolView.DrawLineWithRay(gr, ww, x2, y2, x2 + dx, y2 + dy, ray, pen, penRay);

        super.Draw(gr, ww, param);
    }

    public override DrawSelection (gr, ww): void {
        const dataCacheTool = this.dataCacheTool;

        const pen = dataCacheTool.Pen;
        const penRay = dataCacheTool.PenRay;
        const rayType = dataCacheTool.RayType;

        const screenPoints = this.screenPoints;

        const p0 = screenPoints[0];
        const x0 = p0[0];
        const y0 = p0[1];

        const p1 = screenPoints[1];
        const x1 = p1[0];
        const y1 = p1[1];

        const p2 = screenPoints[2];
        const x2 = p2[0];
        const y2 = p2[1];

        ToolView.DrawLineWithRay(gr, ww, x0, y0, x1, y1, rayType, pen, penRay);
        ToolView.DrawLineWithRay(gr, ww, x0, y0, x2, y2, rayType, pen, penRay);

        this.DrawSelectedPoint(gr, ww, x0, y0);
        this.DrawSelectedPoint(gr, ww, x1, y1);
        this.DrawSelectedPoint(gr, ww, x2, y2);

        this.DrawSelectedLine(gr, ww, x0, y0, x1, y1);
        this.DrawSelectedLine(gr, ww, x0, y0, x2, y2);
    }
}
