// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { Resources } from '../../../Commons/properties/Resources';
import { Rectangle, type Point } from '../../../Commons/Geometry';
import { Cursors } from '../../../Commons/Cursors';
import { TerceraChartCashItemSeriesDataType } from '../../Series/TerceraChartCashItemSeriesEnums';
import { Line, PolyLine, PolyText, PolyTextItem, SolidBrush } from '../../../Commons/Graphics';
import { MathUtils } from '../../../Utils/MathUtils';
import { MouseButtons } from '../../../Controls/UtilsClasses/ControlsUtils';
import { TerceraChartAction, TerceraChartActionEnum } from '../../TerceraChartAction';
import { TerceraChartNumberScaleRenderer, TerceraChartNumberScaleRendererSettings } from './TerceraChartNumberScaleRenderer';
import { ThemeManager } from '../../../Controls/misc/ThemeManager';
import { DynProperty, BoolNumeric, ColorStyleWidth } from '../../../Commons/DynProperty';
import { PriceFormatter } from '../../../Utils/Instruments/PriceFormatter';
import { type TerceraChart } from '../../TerceraChart';
import { type TerceraChartBaseScaleRendererSettings } from './TerceraChartBaseScaleRenderer';

export class TerceraChartPriceScaleRenderer extends TerceraChartNumberScaleRenderer<TerceraChart> {
    public maxC: number | null = null;
    public minC: number | null = null;

    public mouseState: TerceraChartPriceScaleRendererMouseState = TerceraChartPriceScaleRendererMouseState.None;
    public lastMouseDownPt: Point | null = null;
    public lastMouseDownMaxY: number | null = null;
    public lastMouseDownMinY: number | null = null;

    public autoText: string | null = null;
    public manualText: string | null = null;
    public collapsedText: string | null = null;

    public Rectangle: Rectangle;
    public Aligment: number = TerceraChartPriceScaleRendererAligment.Right;
    public AutoScaleSwitcherHovered: boolean;
    public AutoScaleSwitcherRectangle: Rectangle;
    public WindowType: TerceraChartPriceScaleRendererWindowType;
    public DigitsBasedOnIndicator: number = -1;
    public assetReturnText: any;
    public assetReturnBrush: any;
    public resetMouseDown: boolean | null = null;
    public yDeltaPx: number | null = null;
    public yDeltaPrice: number | null = null;
    public priceScaleRenderer: boolean;

    constructor (
        terceraChart: TerceraChart,
        settings: TerceraChartBaseScaleRendererSettings,
        windowType: TerceraChartPriceScaleRendererWindowType = TerceraChartPriceScaleRendererWindowType.Main
    ) {
        super(settings, terceraChart);

        this.priceScaleRenderer = true;
        this.WindowType = windowType;

        this.Rectangle = new Rectangle();
        this.AutoScaleRect = new Rectangle();
        this.AutoScaleSwitcherRectangle = new Rectangle();

        this.Localize();
        this.SetClassName('TerceraChartPriceScaleRenderer');
    }

    static MIN_WIDTH: number = 56;

    Draw (gr: any, window: any, windowsContainer: any, advParams: any = null): void {
        if (!this.Visible) return;

        const R: Rectangle = this.Rectangle;
        const RX: number = R.X;
        const RY: number = R.Y;
        const RW: number = R.Width;
        const RH: number = R.Height;
        const RR: number = RX + RW;
        const RB: number = RY + RH;

        // For this renderer, its own Rectangle is already set. No need to take window.ClientRectangle
        // This is for drawing indicator panels and overlays in the upper part of the window, details (alexb, Zhenya)
        const AutoScaleRect = new Rectangle(RX, RY, RW, this.GetScaleFontHeight() + 32);
        this.AutoScaleRect = AutoScaleRect;

        const advParamsObj = advParams;

        gr.fillStyle = this.settings.scaleBackBrush.Color;
        gr.fillRect(RX, RY, RW, RH);
        // gr.strokeStyle = this.settings.scaleAxisPen;
        // gr.strokeRect(RX, RY, RW - 1, RH);

        const minV = window.FminFloatY;
        const maxV = window.FmaxFloatY;

        if (minV === Number.MAX_VALUE || maxV === -Number.MAX_VALUE || minV === maxV) return;

        if (window.Collapsed === true) {
            return;
        }

        const windowClientRect = window.ClientRectangle;

        gr.save();
        gr.beginPath();
        if (this.Aligment === TerceraChartPriceScaleRendererAligment.Right) {
            gr.rect(windowClientRect.X, windowClientRect.Y, windowClientRect.Width + RW, windowClientRect.Height);
        } else {
            gr.rect(0, windowClientRect.Y, windowClientRect.Width + RW, windowClientRect.Height);
        }
        gr.clip();

        const FontHeightPixels = this.GetScaleFontHeight();

        this.maxC = RB;
        this.minC = RY;
        let step = this.calcStep(maxV, minV, this.maxC, this.minC, FontHeightPixels + 40);

        const instrument = advParamsObj.Instrument;

        const tickSize = instrument != null ? (instrument.letiableTickList?.length > 0 ? instrument.letiableTickList[0].PointSize : instrument.PointSize) : 0;
        const activeInstrumentPipSize = instrument != null ? instrument.GetPipsSize(tickSize, true) : 0;
        const activeInstrumentPrecision = instrument != null ? instrument.Precision : 0;
        const activeInstrumentPrecisionMultiplier = Math.pow(10, activeInstrumentPrecision);

        const seriesDataType = TerceraChartCashItemSeriesDataType.Absolute;

        step = this.CorrectStep(step, instrument, activeInstrumentPipSize, seriesDataType);
        const start = this.calcStart(step, minV);

        let iterCount = 0;

        const dashPolyLines = new PolyLine();
        const gridPolyLines = new PolyLine();
        const highlightMarkingsPolyLines = new PolyLine();
        const gridPolyText = new PolyText();

        const highlightMarkingsStep = this.settings.HighlightMarkingsStep;
        const showHighlightMarkings = this.settings.HighlightMarkings;

        const pointsConverter = window.PointsConverter;

        const finish = (maxV + 3 * step);

        let pipSizeWithStep = activeInstrumentPipSize * highlightMarkingsStep;
        pipSizeWithStep = MathUtils.RoundDouble(pipSizeWithStep, activeInstrumentPrecision);

        for (let vi = start; vi <= finish; vi += step) {
            // Protection against looping
            if (iterCount > 1000) return;

            iterCount++;

            const ty = this.maxC + Math.round(pointsConverter.GetScreenY(vi)) - RB;

            // Beyond visible y scale.
            if (ty <= RY || ty >= RB) { continue; }

            if (this.Aligment === TerceraChartPriceScaleRendererAligment.Right && this.settings.ScaleGridVisibility && window.PaddingTop < ty) {
                // определяем кратность Highlight markings step
                let highlightMarkings = false;
                if (showHighlightMarkings === true && pipSizeWithStep !== 0) {
                    const newVI = Math.round(MathUtils.RoundDouble(vi, activeInstrumentPrecision) * activeInstrumentPrecisionMultiplier);
                    const newPips = Math.round(pipSizeWithStep * activeInstrumentPrecisionMultiplier);

                    highlightMarkings = newVI % newPips === 0;
                }

                if (highlightMarkings) { highlightMarkingsPolyLines.lines.push(new Line(windowClientRect.X + 1, ty, RX, ty)); } else { gridPolyLines.lines.push(new Line(windowClientRect.X + 1, ty, RX, ty)); }
            }

            if (this.Aligment === TerceraChartPriceScaleRendererAligment.Right) { dashPolyLines.lines.push(new Line(RX, ty, RX + 3, ty)); } else { dashPolyLines.lines.push(new Line(RR - 1, ty, RR - 4, ty)); }

            let seriesDataTypeForFormat = seriesDataType;
            let formattedValue: string | null = null;

            if (this.settings.PercentView === true) { seriesDataTypeForFormat = TerceraChartCashItemSeriesDataType.Relative; } else if (this.settings.Asset != null) { formattedValue = this.settings.Asset.formatPrice(vi); }

            if (formattedValue === null) { formattedValue = TerceraChartPriceScaleRenderer.FormatPrice(vi, advParamsObj, seriesDataTypeForFormat, this); }

            gridPolyText.textArray.push(new PolyTextItem(formattedValue, RX + 4, Math.floor(ty - FontHeightPixels / 2 - 1)));
        }

        gr.DrawPolyLine(this.settings.scaleAxisPen, dashPolyLines);
        gr.DrawPolyLine(this.settings.scaleGridPen, gridPolyLines);
        if (showHighlightMarkings === true) { gr.DrawPolyLine(this.settings.gridPriceHLPen, highlightMarkingsPolyLines); }

        gr.DrawPolyText(gridPolyText, this.GetScaleFont(), this.settings.scaleTextBrush);

        if (this.chart.PortfolioAssetReturnVisible) { this.DrawPortfolioAssetReturnText(gr, windowClientRect); }

        // Reset clip.
        gr.restore();

        //
        // Draw autoscale
        //
        gr.save();
        gr.beginPath();
        gr.rect(RX, RY, RW, RH);
        gr.clip();

        const ASRX = AutoScaleRect.X;
        const ASRY = AutoScaleRect.Y;
        const ASRW = AutoScaleRect.Width;
        const ASRH = AutoScaleRect.Height;

        if (this.settings.AutoScaleSwitcherVisible) {
            // Draw the autoscale switcher buttons
            gr.fillStyle = this.settings.scaleBackBrush.Color;
            gr.fillRect(ASRX, ASRY, ASRW, ASRH); // Fill the background of the autoscale switcher area with the scaleBackBrush color
            gr.strokeStyle = this.settings.scaleAxisPen;
            gr.strokeRect(ASRX, ASRH, ASRW, ASRH); // Draw a border around the autoscale switcher area with the scaleAxisPen color

            // Determine the text to display on the button based on the current autoscale state
            const buttonText = window.AutoScale === true ? this.autoText : this.manualText;

            // Draw the button text using the ScaleFont and scaleTextBrush
            gr.DrawString(
                buttonText,
                this.settings.ScaleFont,
                this.settings.scaleTextBrush,
                ASRX + ASRW / 2,
                ASRY + ASRH / 4,
                'center', 'middle'
            );
        }
        // Reset clip.
        gr.restore();

        if (this.settings.AutoScaleSwitcherVisible) {
            const img = window.AutoScale === true
                ? (this.AutoScaleSwitcherHovered ? ThemeManager.CurrentTheme.switcherHoverLeftImage : ThemeManager.CurrentTheme.switcherDefaultLeftImage)
                : (this.AutoScaleSwitcherHovered ? ThemeManager.CurrentTheme.switcherHoverRightImage : ThemeManager.CurrentTheme.switcherDefaultRightImage);
            if (img != null) {
                const btnX = ASRX + ASRW / 2 - 16;
                const btnY = ASRY + 17;
                this.AutoScaleSwitcherRectangle = new Rectangle(btnX, btnY, img.width, img.height);
                gr.drawImage(img, btnX, btnY);
            }
        }

        // Draw shadow
        if ((this.WindowType === TerceraChartPriceScaleRendererWindowType.Main && this.chart.FocusedOverlay != null) ||
            (this.WindowType === TerceraChartPriceScaleRendererWindowType.Overlay && this.chart.FocusedOverlay == null)) {
            gr.FillRect(this.settings.scaleBackBrushShadow, RX, RY, RW, RH);
        }
    }
    // CalcStep(maxV: any, minV: any, maxC: number, minC: number, arg4: number)
    // {
    //     throw new Error('Method not implemented.');
    // }
    // CalcStart(step: any, minV: any)
    // {
    //     throw new Error('Method not implemented.');
    // }

    static FormatPrice (vi: number, advParamsObj: any, seriesDataType: number, scaleRenderer: TerceraChartPriceScaleRenderer): string {
        const instrument = advParamsObj.Instrument;

        let formattedValue: string = '';
        if (scaleRenderer.WindowType === TerceraChartPriceScaleRendererWindowType.Indicator) {
            if (scaleRenderer.DigitsBasedOnIndicator !== -1) {
                formattedValue = PriceFormatter.formatPrice(vi, scaleRenderer.DigitsBasedOnIndicator);
            } else if (instrument != null) {
                formattedValue = instrument.formatPrice(vi);
            }
        } else if (seriesDataType === TerceraChartCashItemSeriesDataType.Relative) {
            formattedValue = vi.toFixed(2) + '%';
        } else {
            if (instrument != null) {
                formattedValue = instrument.formatPrice(vi, false);
            } else {
                formattedValue = vi.toString();
            }
        }
        return formattedValue;
    }

    public CorrectStep (step: number, instrument: any, activeInstrumentPipSize: number, seriesDataType: number): number {
        if (instrument != null && seriesDataType === TerceraChartCashItemSeriesDataType.Absolute) {
            // 1. Шаг не меньше, чем MinDelta цены инструмента
            const MinDelta = instrument.MinDelta;
            if (step < MinDelta) step = MinDelta;
            // 2. Для форексных инструментов, не меньше чем PipSize
            if (instrument.isForexSymbol() === true) {
                if (activeInstrumentPipSize != 0 && step < activeInstrumentPipSize) step = activeInstrumentPipSize;
                // +++ round to pip size
                if (activeInstrumentPipSize != 0) step = MathUtils.RoundTo(step, activeInstrumentPipSize);
            }
        }

        // +++ Custom Y scale
        if (this.settings.UseCustomYMarkings === true) {
            const customStep = this.settings.CustomYMarkingValue * activeInstrumentPipSize;

            if (step / customStep >= 2) {
                // слишком часто
            } else {
                step = customStep;
            }
        }

        return step;
    }

    GetContextMenu (e, chart): any {
        const window = this.window;
        const location = e.Location;

        if (!this.Visible || window.Collapsed === true || !this.Rectangle.Contains(location.X, location.Y)) { return null; }

        const menuItems = [
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.AutoScale, window) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.ManualScale, window) },
            { separator: true }
        ];

        const WindowType = this.WindowType;

        if (WindowType !== TerceraChartPriceScaleRendererWindowType.Overlay) { menuItems.push({ tag: TerceraChartAction.Create(TerceraChartActionEnum.FitIndicators, window) }); }

        if (WindowType === TerceraChartPriceScaleRendererWindowType.Main) {
            menuItems.push({ tag: TerceraChartAction.Create(TerceraChartActionEnum.FitOrders) });
            menuItems.push({ tag: TerceraChartAction.Create(TerceraChartActionEnum.FitDayHighLow) });
        }

        menuItems.push({ tag: TerceraChartAction.Create(TerceraChartActionEnum.FitDrawings, window) });

        if (WindowType === TerceraChartPriceScaleRendererWindowType.Main) { menuItems.push({ tag: TerceraChartAction.Create(TerceraChartActionEnum.FitAlerts) }); }

        if (WindowType === TerceraChartPriceScaleRendererWindowType.Main ||
            WindowType === TerceraChartPriceScaleRendererWindowType.Overlay) {
            const overlays = this.chart.Overlays;
            const len = overlays.length;
            if (this.Aligment === TerceraChartPriceScaleRendererAligment.Left && len > 1) {
                menuItems.push({ separator: true });

                for (let i = 0; i < len; i++) { menuItems.push({ tag: TerceraChartAction.Create(TerceraChartActionEnum.MainSymbol, overlays[i]) }); }
            }
        }

        return chart.TerceraChartActionProcessor.CreateMenu(menuItems);
    }

    Localize (): void {
        super.Localize();
        this.autoText = Resources.getResource('Chart.chartWindow.auto');
        this.manualText = Resources.getResource('Chart.chartWindow.manual');
        this.collapsedText = Resources.getResource('Chart.chartWindow.collapsed');
    }

    DrawPortfolioAssetReturnText (gr, windowClientRect): void {
        if (this.chart.mainWindow.CanZoomIn()) { return; }

        if (this.assetReturnText == null) { this.assetReturnText = Resources.getResource('portfolioReturns.AssetReturnText'); }
        if (this.assetReturnBrush == null) { this.assetReturnBrush = new SolidBrush(ThemeManager.CurrentTheme.PortfolioReturnsChartAssetReturnTextBackground); }

        const w = windowClientRect.Width;
        const h = windowClientRect.Height;
        const text = this.assetReturnText; const margin = 6; const rectHeight = 14;
        const textW = gr.GetTextWidth(text, this.GetScaleFont()) + margin * 2;

        gr.FillRect(this.assetReturnBrush, w, h - rectHeight, textW, rectHeight - 1);
        gr.DrawRect(this.settings.scaleAxisPen, w, h - rectHeight, textW, rectHeight - 1);
        gr.DrawString(text, this.GetScaleFont(), this.settings.scaleTextBrush, windowClientRect.Width + margin, windowClientRect.Height - margin * 2);//, 'center', 'middle');
    }

    // #region Process mouse events

    /// <summary>
    ///
    /// </summary>
    ProcessMouseDown (e): boolean {
        // reset overlay
        if (this.WindowType === TerceraChartPriceScaleRendererWindowType.Main && this.Rectangle.Contains(e.Location.X, e.Location.Y)) { this.chart.FocusedOverlay = null; }
        // activate overlay
        else if (this.Visible && this.WindowType === TerceraChartPriceScaleRendererWindowType.Overlay && this.Rectangle.Contains(e.Location.X, e.Location.Y)) { this.chart.FocusedOverlay = this.window.Overlay; }

        if (!this.Visible || e.Button !== MouseButtons.Left || this.window.Collapsed === true) { return false; }

        this.lastMouseDownPt = e.Location;

        this.mouseState = TerceraChartPriceScaleRendererMouseState.None;

        if (this.AutoScaleRect.Contains(e.Location.X, e.Location.Y) && e.window != null && e.window.Collapsed === false) {
            const newValue = this.window.AutoScale !== true;
            if (this.WindowType === TerceraChartPriceScaleRendererWindowType.Overlay) {
                for (let i = 0; i < this.chart.Overlays.length; i++) { this.chart.Overlays[i].window.AutoScale = newValue; }
            } else { this.window.AutoScale = newValue; }

            return true;
        } else if (this.Rectangle.Contains(e.Location.X, e.Location.Y)) {
            this.lastMouseDownMinY = this.window.FminFloatY;
            this.lastMouseDownMaxY = this.window.FmaxFloatY;
            this.mouseState = TerceraChartPriceScaleRendererMouseState.Moving;

            if (this.WindowType === TerceraChartPriceScaleRendererWindowType.Overlay) {
                this.chart.FocusedOverlay = this.window.Overlay;
            } else if (this.WindowType === TerceraChartPriceScaleRendererWindowType.Main) {
                this.chart.FocusedOverlay = null;
            }

            return true;
        }

        return false;
    }

    /// <summary>
    ///
    /// </summary>
    ProcessMouseMove (e): boolean {
        if (!this.Visible) { return false; }

        if (this.resetMouseDown) {
            // если выводим за шкалу мышку, то в момент ввода ее назад будем считать, что точка ввода - начальная
            // чтобы не было резких скачков относительно старого значения lastMouseDownPt
            this.lastMouseDownPt = e.Location;
            this.lastMouseDownMinY = this.window.FminFloatY;
            this.lastMouseDownMaxY = this.window.FmaxFloatY;
            this.resetMouseDown = false;
        }

        const Rectangle = this.Rectangle;
        const RX = Rectangle.X;
        const RY = Rectangle.Y;
        const RH = Rectangle.Height;

        if (this.mouseState === TerceraChartPriceScaleRendererMouseState.Moving &&
            e.Location.X > RX && e.Location.X < RX + Rectangle.Width &&/* по ширине */
            e.Location.Y > RY && e.Location.Y < RY + RH)/* по высоте */ {
            this.yDeltaPx = (e.Location.Y - this.lastMouseDownPt?.Y) / RH;// коефициент в процентах относительно пикселей

            if (Math.abs(this.yDeltaPx) > 0.01)// начинаем двигать без маленьких движений в начале
            {
                this.yDeltaPrice = this.yDeltaPx * (this.window.FmaxFloatY - this.window.FminFloatY) / 2;// коефициент в процентах относительно цены

                this.window.AutoScale = false;
                // check
                if (this.lastMouseDownMinY && this.lastMouseDownMaxY) {
                    this.window.SetMinMaxYScale(this.lastMouseDownMaxY + this.yDeltaPrice, this.lastMouseDownMinY - this.yDeltaPrice, /* this.AssignedCashItemSeriesSettings.DataType == TerceraChartCashItemSeriesDataType.Log ? this.AssignedCashItemSeriesSettings.logDataConverter : */null);
                }

                e.NeedRedraw = true;
            }
        } else {
            this.resetMouseDown = true;
        }

        if (this.AutoScaleRect.Contains(e.Location.X, e.Location.Y)) { e.NeedRedraw = true; }

        const newSwitcherState = this.AutoScaleSwitcherRectangle.Contains(e.Location.X, e.Location.Y);
        if (newSwitcherState !== this.AutoScaleSwitcherHovered) { e.NeedRedraw = true; }
        this.AutoScaleSwitcherHovered = newSwitcherState;

        return super.ProcessMouseMove(e); // return TerceraChartNumberScaleRenderer.prototype.ProcessMouseMove.call(this, e);
    }

    ProcessMouseUp (e): boolean {
        super.ProcessMouseUp(e);

        this.resetMouseDown = false;

        this.mouseState = TerceraChartPriceScaleRendererMouseState.None;

        return false;
    }

    ProcessMouseEnter (e): void {
        super.ProcessMouseEnter(e);

        if (this.mouseState === TerceraChartPriceScaleRendererMouseState.Moving) { this.resetMouseDown = true; }
    }

    ProcessMouseDoubleClick (e): boolean {
        if (this.window.Collapsed === false && this.Rectangle.Contains(e.Location.X, e.Location.Y)) {
            this.window.AutoFit();
            return true;
        } else { return false; }
    }

    // #endregion

    /// /#region Misc

    AutoScale_ON (e): void {
        this.chart.TerceraChartActionProcessor.ProcessTerceraChartAction(TerceraChartAction.Create(TerceraChartActionEnum.AutoScale, this.window));
    }

    Dispose (): void {
        this.window = null;
        super.Dispose();
    }

    GetCursor (e): any {
        if (this.window.Collapsed === true) { return super.GetCursor(e); }

        if (this.mouseState === TerceraChartPriceScaleRendererMouseState.Moving && this.Rectangle.Contains(e.Location.X, e.Location.Y)) { return Cursors.SizeNS; }
        if (this.AutoScaleSwitcherRectangle.Contains(e.Location.X, e.Location.Y)) { return Cursors.Hand; }

        return null;
    }
}

export class TerceraChartPriceScaleRendererSettings extends TerceraChartNumberScaleRendererSettings {
    GridPriceHLColor: any;
    GridPriceHLStyle: any;
    GridPriceHLWidth: any;
    UseCustomXMarkings: boolean;
    CustomXMarkingValue: number;
    protected relativeCalculationType: RelativeCalculationType = RelativeCalculationType.BeginOfTheScreen;
    public get RelativeCalculationType (): RelativeCalculationType {
        return this.relativeCalculationType;
    }

    public set RelativeCalculationType (value: RelativeCalculationType) {
        this.relativeCalculationType = value;
    }

    constructor () {
        super();

        this.UseCustomYMarkings = false;
        this.UseCustomXMarkings = false;

        this.CustomYMarkingValue = 1;
        this.CustomXMarkingValue = 10;

        this.HighlightMarkingsStep = 100;
        this.HighlightMarkings = false;

        this.AutoScaleSwitcherVisible = true;

        this.PercentView = false;
    }

    ThemeChanged (): void {
        super.ThemeChanged();
        this.GridPriceHLColor = ThemeManager.CurrentTheme.Chart_GridPriceColor;
    }

    Properties (): any[] {
        const properties: any = [];

        // Grid
        let SeparatorGroup = '#-4#' + Resources.getResource('property.SeparatorGroup.Grid');

        let colorStyleWidth = new ColorStyleWidth(this.ScaleGridColor, this.ScaleGridStyle, this.ScaleGridWidth);
        colorStyleWidth.CheckBoxVisible = true;
        colorStyleWidth.Checked = this.ScaleGridVisibility;
        // colorStyleWidth.DisableWidth = true;

        let prop = new DynProperty('GridPrice', colorStyleWidth, DynProperty.COLOR_STYLE_WIDTH, DynProperty.VISUAL_GROUP);
        prop.separatorGroup = SeparatorGroup;
        prop.sortIndex = 0;
        properties.push(prop);

        // Appearance
        SeparatorGroup = '#3#' + Resources.getResource('property.SeparatorGroup.Appearance');
        prop = new DynProperty('PriceScaleTextColor', this.ScaleTextColor, DynProperty.COLOR, DynProperty.PRICE_SCALE_GROUP);
        prop.sortIndex = 1;
        prop.separatorGroup = SeparatorGroup;
        properties.push(prop);

        prop = new DynProperty('PriceScaleAxisColor', this.ScaleAxisColor, DynProperty.COLOR, DynProperty.PRICE_SCALE_GROUP);
        prop.sortIndex = 2;
        prop.separatorGroup = SeparatorGroup;
        properties.push(prop);

        prop = new DynProperty('PriceScaleBackColor', this.ScaleBackColor, DynProperty.COLOR, DynProperty.PRICE_SCALE_GROUP);
        prop.sortIndex = 3;
        prop.separatorGroup = SeparatorGroup;
        properties.push(prop);

        // Scale markings
        SeparatorGroup = '#2#' + Resources.getResource('property.SeparatorGroup.ScaleMarkings');

        prop = new DynProperty('UseCustomYMarkings', this.UseCustomYMarkings, DynProperty.BOOLEAN, DynProperty.PRICE_SCALE_GROUP);
        prop.sortIndex = 0;
        prop.separatorGroup = SeparatorGroup;
        prop.assignedProperty = ['CustomYMarkingValue'];
        properties.push(prop);

        prop = new DynProperty('CustomYMarkingValue', this.CustomYMarkingValue, DynProperty.DOUBLE, DynProperty.PRICE_SCALE_GROUP);
        prop.sortIndex = 1;
        prop.enabled = this.UseCustomYMarkings;
        prop.minimalValue = 0;
        prop.decimalPlaces = 1;
        prop.increment = 1;
        prop.separatorGroup = SeparatorGroup;
        properties.push(prop);

        prop = new DynProperty('HighlightMarkingsStep', new BoolNumeric(this.HighlightMarkings, this.HighlightMarkingsStep), DynProperty.BOOL_NUMERIC, DynProperty.PRICE_SCALE_GROUP);
        prop.sortIndex = 2;
        prop.decimalPlaces = 0;
        prop.increment = 1;
        prop.minimalValue = 0;
        prop.assignedProperty = ['GridPriceHighLight'];
        prop.separatorGroup = SeparatorGroup;
        properties.push(prop);

        colorStyleWidth = new ColorStyleWidth(this.GridPriceHLColor, this.GridPriceHLStyle, this.GridPriceHLWidth);
        colorStyleWidth.CheckBoxVisible = false;
        // colorStyleWidth.DisableWidth = true;

        prop = new DynProperty('GridPriceHighLight', colorStyleWidth, DynProperty.COLOR_STYLE_WIDTH, DynProperty.PRICE_SCALE_GROUP);
        prop.separatorGroup = SeparatorGroup;
        prop.enabled = this.HighlightMarkings;
        prop.sortIndex = 3;
        properties.push(prop);

        return properties;
    }

    public override callBack (properties): void {
        let dp = DynProperty.getPropertyByName(properties, 'relativeCalcType');
        if (dp != null) this.RelativeCalculationType = dp.value;

        dp = DynProperty.getPropertyByName(properties, 'UseCustomYMarkings');
        if (dp != null) this.UseCustomYMarkings = dp.value;

        dp = DynProperty.getPropertyByName(properties, 'UseCustomXMarkings');
        if (dp != null) this.UseCustomXMarkings = dp.value;

        dp = DynProperty.getPropertyByName(properties, 'CustomYMarkingValue');
        if (dp != null) this.CustomYMarkingValue = dp.value;

        dp = DynProperty.getPropertyByName(properties, 'CustomXMarkingValue');
        if (dp != null) this.CustomXMarkingValue = dp.value;

        dp = DynProperty.getPropertyByName(properties, 'HighlightMarkingsStep');
        if (dp != null) {
            const val = dp.value;
            this.HighlightMarkings = val.Checked;
            this.HighlightMarkingsStep = val.Value;
        }

        dp = DynProperty.getPropertyByName(properties, 'GridPriceHighLight');
        if (dp != null) {
            const csw = dp.value;
            this.GridPriceHLColor = csw.Color;
            this.GridPriceHLStyle = csw.Style;
            this.GridPriceHLWidth = csw.Width;
        }

        dp = DynProperty.getPropertyByName(properties, 'GridPrice');
        if (dp != null) {
            const csw = dp.value;
            this.ScaleGridColor = csw.Color;
            this.ScaleGridStyle = csw.Style;
            this.ScaleGridWidth = csw.Width;
            this.ScaleGridVisibility = csw.Checked;
        }

        dp = DynProperty.getPropertyByName(properties, 'PriceScaleAxisColor');
        if (dp != null) this.ScaleAxisColor = dp.value;

        dp = DynProperty.getPropertyByName(properties, 'PriceScaleBackColor');
        if (dp != null) this.ScaleBackColor = dp.value;

        dp = DynProperty.getPropertyByName(properties, 'PriceScaleTextColor');
        if (dp != null) this.ScaleTextColor = dp.value;
    }
}

export enum TerceraChartPriceScaleRendererMouseState { None, Moving, ZoomIn, ZoomOut }

export enum TerceraChartPriceScaleRendererWindowType { Main, Overlay, Indicator, IndicatorOverlay }

export enum RelativeCalculationType {
    BeginOfTheScreen,
    BeginOfTheData,
    BeginOfTheDay,
    CustomValue,
    CustomDate
}

export enum TerceraChartPriceScaleRendererAligment {
    Right,
    Left
}
