// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectNewsSubscribeResponseMessage extends DirectMessageBaseClass {
    public RouteIdsArr: number[] = [];

    constructor () {
        super(Message.CODE_NEWS_SUBSCRIBE_RESPONSE);
    }
}
