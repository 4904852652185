// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
export enum AccountDetailsGroupEnum {
    General,
    Margin,
    AccountActivity,
    TodayResult,
    RiskManagement
}

export enum GeneralGroupEnum {
    Balance,
    CurBalance,
    BalancePlusAllRisks,
    AccountValue,
    CreditValue,
    AvailableMargin,
    LockedBalance,
    CashBalance,
    WithdrawalAvailable,
    InterestRate,
    EstimateValue,
    UnsettledCash,
    IncomingFunds,
    StocksValue,
    OptionValue,
    StocksLiquidity,
    Collateral,
    BuyingPower,
    GivenLeverage,
    OpenBalance,
    TodaysPnLPercent,
}

export enum MarginGroupEnum {
    FundsUsed,
    FundsUsedPercent,
    MarginUsedNew,
    stopOutPercent,
    CurMargin,
    MaintMargin,
    Leverage,
    MarginAvailableReal,
    marginWarnPercent,
    MarginWarning,
    MarginRequirement,
    MinMargin,
    BlockedByOrders,
    MarginStopOut,
    StocksOrdersReq,
    MarginDeficiency,
    MarginSurplus,
    BlockedForStocks,
    MarginForOthers,
    BlockedForStocksNew,
    OptionPremiumReq,
    WaivedMargin,
    WarningMarginReq,
    WarningMarginReqPercent,
    MarginBeforeWarning,
    InitialMarginWithoutWaived,
    StopOut
}

export enum AccountActivityGroupEnum {
    CurrentFundCapital,
    FundCapitalGain,
    Profit,
    ProfitNet,
    Positions,
    Orders,
    BlockedByPamm
}

export enum TodayResultsGroupEnum {
    GrossPNL,
    BeginBalance,
    TodayFees,
    TodayVolume,
    TodayTrades,
    TodayTurnover,
    TodayRebates,
    TodaySwaps
}

export enum RiskManagementGroupEnum {
    CurrentStatus,
    AllowTradingPrePost,
    MaxDailyLossLimit,
    MaxOrdersPerDay,
    MaxPositions,
    MaxPendingOrders,
    MaxPendingOrdersValue,
    MaxOrderCapital,
    MaximumLot,
    MaxPositionQtyPerSymbol,
    AllowOvernightTrading,
    TrailingDrawdownLevel,
    TotalMaxPositionsQty,
    MaxWeekLostLimit,
    CurrentDailyLoss,
    CurrentWeeklyLoss,
    UnrealizedLoss,
    UnrealizedLossLimit,
    MaxDailyProfit,
    VolumeLimitForALL,
    VolumeLimitForFOREX,
    VolumeLimitForEQUITIES,
    VolumeLimitForFUTURES,
    VolumeLimitForOPTIONS,
    VolumeLimitForEQUITIES_CFD,
    VolumeLimitForFORWARD,
    VolumeLimitForCFD_FUTURES,
    VolumeLimitForINDICIES,
    VolumeLimitForCRYPTO,
    VolumeLimitForSPREADBET,
    VolumeLimitForBOND,
    VolumeLimitForETF,
    VolumeLimitForTBILL,
    VolumeLimitForSPOT,
    VolumeLimitForCORPORATE,
    MaxOrderAmount,
    DayTraderPatternProtection,
    AvailableDayTrades,
    MaxRelativeDrawDownLevel,
    LossLimitPerTrade
}
