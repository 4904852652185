// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../Commons/properties/Resources';
import { LinkedSystem } from '../misc/LinkedSystem';
import { type PositionsBalance } from '../../Commons/cache/PositionsBalance';
import { PositionsBalanceItem } from '../cache/PositionsBalanceItem';
import { ColouringModes } from '../elements/QuickTable/QuickTableColumn';
import { TerceraLinkControlConstants } from '../UtilsClasses/TerceraLinkControlConstants';
import { TerceraMenu } from '../elements/TerceraMenu';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { DynProperty } from '../../Commons/DynProperty';
import { DataCache } from '../../Commons/DataCache';
import { ApplicationInfo } from '../../Commons/ApplicationInfo';
import { PositionsBalancePanelTemplate } from '../../templates.js';
import { ApplicationPanelWithTable } from './ApplicationPanelWithTable';
import { SessionSettings } from '../../Commons/SessionSettings';
import { PositionsBalanceManager } from '../../Utils/Managers/PositionsBalanceManager';
import { _AccountItemAll } from '../elements/Lookup/TerceraAccountLookup';

export class PositionsBalancePanel extends ApplicationPanelWithTable<PositionsBalanceItem> {
    public topPanelHeight: any = 27;
    public ToolbarVisible: boolean = true;
    public ShowTotals: boolean = false;
    public accLookup: any = null;
    public delayAccounts: any = null;
    public inited: boolean = false;
    private readonly _balanceManager = new PositionsBalanceManager();

    constructor () {
        super();

        this.moreThanOneTTKey = 'panel.positionsBalance.menu.MoreOneSymbolInfoDisabled.tt'; // #95439
        this.noOneTTKey = 'panel.positionsBalance.menu.NoSymbolInfoDisabled.tt';
        this.headerLocaleKey = 'panel.positionsBalance';
    }

    public override getType (): PanelNames { return PanelNames.PositionsBalancePanel; }

    public override oncomplete (): void {
        super.oncomplete();

        this.DCEventsSubscribe();
        this.populateTableContextMenu();
        this.localize();

        this.UpdateShowTotalsStateChange(!!this.ShowTotals);
    }

    private AddAllRows (): void {
        const qt = this.getQuickTable();
        if (isNullOrUndefined(qt)) return;

        qt.ClearAll();
        const accInUse = this.getAccInUse();
        this._balanceManager.filter(Object.keys(accInUse));
        this._balanceManager.run();
    };

    private readonly OnAddPositionsBalance = (positionsBalance: PositionsBalance): void => {
        const qt = this.getQuickTable();
        if (isNullOrUndefined(qt)) return;

        const newItem = new PositionsBalanceItem(positionsBalance, SessionSettings);
        newItem.ItemId = positionsBalance.positionBalanceID.toString();
        const row = qt.AddItem(newItem);
        row.id = positionsBalance.positionBalanceID.toString();

        this.updatePanelHeader();
        qt.needRedrawBackground = true;
    };

    private readonly OnRemovePositionsBalance = (positionsBalance: PositionsBalance): void => {
        const qt = this.getQuickTable();
        if (isNullOrUndefined(qt)) return;

        const id = positionsBalance.positionBalanceID.toString();
        qt.RemoveItem(id);

        this.updatePanelHeader();
        qt.needRedrawBackground = true;
    };

    private readonly OnUpdatePositionsBalance = (positionsBalance: PositionsBalance): void => {
        const qt = this.getQuickTable();
        if (isNullOrUndefined(qt)) return;

        const id = positionsBalance.positionBalanceID.toString();
        const item = qt.getItemById(id);
        if (!isNullOrUndefined(item)) { item.item.Recalc(); }

        this.updatePanelHeader();
        qt.needRedrawBackground = true;
    };

    private getAccInUse (): any {
        if (!this.accLookup) { return null; }

        let accArr = [];
        const selectedAccs = this.accLookup.get('selectedItem');
        if (selectedAccs._AccountItemAll) {
            const accounts = DataCache.getAccounts();
            for (const accID in accounts) {
                accArr.push(accounts[accID]);
            }
        } else if (selectedAccs.arraySelected) {
            accArr = selectedAccs.arraySelected;
        } else { accArr = [selectedAccs]; }

        const accUse = {};
        for (let i = 0; i < accArr.length; i++) { accUse[accArr[i].AcctNumber] = true; }

        return accUse;
    }

    public override updatePanelHeader (): void {
        const qt = this.getQuickTable();
        if (!qt) { return; }

        const rowNumSt = '  (' + qt.visibleRowCount() + ')';
        const newHeader = Resources.getResource(this.headerLocaleKey) + rowNumSt;

        this.set('header', newHeader);
    };

    private DCEventsSubscribe (): void {
        this._balanceManager.subscribeToAdd(this.OnAddPositionsBalance);
        this._balanceManager.subscribeToRemove(this.OnRemovePositionsBalance);
        this._balanceManager.subscribeToUpdate(this.OnUpdatePositionsBalance);
        this._balanceManager.run();

        DataCache.OnRecalculatedBalances.Subscribe(this.RecalculatedBalances, this);
    }

    private DCEventsUnSubscribe (): void {
        this._balanceManager.stop();
        this._balanceManager.unsubscribeToAdd(this.OnAddPositionsBalance);
        this._balanceManager.unsubscribeToRemove(this.OnRemovePositionsBalance);
        this._balanceManager.unsubscribeToUpdate(this.OnUpdatePositionsBalance);

        DataCache.OnRecalculatedBalances.UnSubscribe(this.RecalculatedBalances, this);
    }

    public override jbInit (): void {
        if (DataCache.getNumberOfAccounts() === 1) { this.topPanelHeight = 0; }
        super.jbInit();

        this.createAccountLookup();
        this.getQuickTable().OnSelectionChanged.Subscribe(this.selectionChange, this);
    };

    public override dispose (): void {
        this.getQuickTable().OnSelectionChanged.UnSubscribe(this.selectionChange, this);
        this.DCEventsUnSubscribe();

        super.dispose();
    };

    public override SetColumnsColouringMode (table): void {
        super.ProcessSetColumnsColouringMode(table, [3], ColouringModes.Previous, undefined, undefined);
        super.ProcessSetColumnsColouringMode(table, [4], ColouringModes.Signed, undefined, undefined);
        super.ProcessSetColumnsColouringMode(table, [10], ColouringModes.Signed, undefined, undefined);
    };

    private createAccountLookup (): void {
        const accountsLookup = this.Controls.accountSelector.getLookup();
        accountsLookup.onChangeValue.Subscribe(this.AddAllRows, this);
        accountsLookup.IsMultiSelect = true;
        accountsLookup.isMultiSelectMode = true;
        this.accLookup = accountsLookup;

        if (this.delayAccounts) {
            const accs = DataCache.Accounts;
            const accIDArr = this.delayAccounts.split(',');
            const accArr = [];

            for (let i = 0; i < accIDArr.length; i++) {
                if (accs[accIDArr[i]] || accIDArr[i] === 'All') { accArr.push(accs[accIDArr[i]]); }
            }

            accountsLookup.dropDownFormCallBack(accArr);
            this.delayAccounts = null;
        } else {
            let accToInitWith: any = new _AccountItemAll();
            if (DataCache.EnableForceLinkingByAccount()) {
                const accID = LinkedSystem.getAccount();
                accToInitWith = [DataCache.GetAccountById(accID)];
            }

            accountsLookup.dropDownFormCallBack(accToInitWith);
        }

        this.ShowToolbarStateUpdate(this.ToolbarVisible);
    }

    private selectionChange (): void {
        const qt = this.getQuickTable();
        const selectedRowsId = qt.selectedRowIds;
        if (!selectedRowsId) return;

        let insId = '';
        if (selectedRowsId.length > 0) {
            const row = qt.rows[selectedRowsId[0]];
            const item = row ? row.item : null;
            if (item) {
                const ins = item.GetCurrentInstrument();
                if (ins) insId = ins.GetInteriorID();
            }
        }

        this.symbolLink_Out(false, insId);
    };

    public override accountLink_In (accId, fromLinkedSystem: boolean): boolean {
        if (fromLinkedSystem && !this.OnAccountLinking()) { return false; }

        const acc = DataCache.Accounts[accId];
        if (acc && this.accLookup) { this.accLookup.set('selectedItem', acc); }
        this.AddAllRows();

        return true;
    };

    public override symbolLink_Out (newSubscriber, instrument): void {
        if (!instrument) { return; }

        const color = this.get('symbolLinkValue');
        if (color !== TerceraLinkControlConstants.STATE_NONE) { LinkedSystem.setSymbol(color, instrument, newSubscriber); }
    };

    public override localize (): void {
        super.localize();

        if (!this.quickTableRactive) { return; }
        const contextItems = this.getQuickTable().tableContextMenuItems;

        if (!contextItems || contextItems.length === 0) { return; }

        for (let i = 0; i < contextItems.length; i++) {
            const item = contextItems[i];
            if (item) {
                if (item.locKey) { item.text = Resources.getResource(item.locKey); }

                if (item.subitems) {
                    for (let j = 0; j < item.subitems.length; j++) {
                        const subitem = item.subitems[j];
                        if (subitem.locKey) { subitem.text = Resources.getResource(subitem.locKey); }
                    }
                }
            }
        }
    }

    private populateTableContextMenu (): void {
        const items = [];
        const moreThanOnAcc = DataCache.getNumberOfAccounts() > 1;

        items.push(
            {
                locKey: 'panel.positions.menu.View',
                tag: 'View',
                enabled: true,
                subitems: [
                    {
                        locKey: 'panel.positions.menu.ShowTotals',
                        tag: 'ShowTotals',
                        checked: this.ShowTotals,
                        visible: !Resources.isHidden('contextMenu.Totals.visibility'),
                        enabled: true,
                        canCheck: true,
                        event: this.ShowTotalsStateChange.bind(this)
                    },
                    {
                        locKey: 'panel.positionsBalance.menu.ShowToolbar',
                        tag: 'ShowToolbar',
                        checked: this.ToolbarVisible,
                        visible: !Resources.isHidden('panel.positionsBalance.menu.ShowToolbar') && moreThanOnAcc,
                        enabled: true,
                        canCheck: true,
                        event: this.ShowToolbarStateChange.bind(this)
                    }
                ]
            }
        );

        this.AddSymbolInfoContextMenuItemIfNeed(items, false);

        this.menuTagDict = TerceraMenu.createTagDictionary(items);
        this.getQuickTable().setTableContextMenuItems(items);
    };

    private ShowToolbarStateChange (menuItem): void {
        this.ToolbarVisible = menuItem.checked;

        this.ShowToolbarStateUpdate(this.ToolbarVisible);
    }

    private ShowToolbarStateUpdate (state): void {
        if (DataCache.getNumberOfAccounts() === 1) { state = false; }
        this.accLookup.set('visible', state);
        this.topPanelHeight = state ? 27 : 0;
        this.layoutTable();
    }

    public override disableAccountLinking (): void {
        this.updateAccountLinkVisible(false);
    };

    public override Properties (): DynProperty[] {
        const properties = super.Properties();
        const acc = this.accLookup.get('selectedItem');

        if (!acc) { return properties; }
        let accNumber = '';
        if (acc._AccountItemAll || acc._AccountItemSelected) { accNumber = acc.GetDataForSave(); } else { accNumber = acc.AcctNumber; }

        properties.push(new DynProperty('account', accNumber, DynProperty.STRING, DynProperty.HIDDEN_GROUP));
        properties.push(new DynProperty('ShowTotals', this.getQuickTable().ShowTotals, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));
        properties.push(new DynProperty('ToolbarVisible', this.ToolbarVisible, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));

        return properties;
    }

    public override callBack (newProperties): void {
        super.callBack(newProperties);

        const dp = DynProperty.getPropertyByName(newProperties, 'account');
        if (dp?.value) { this.delayAccounts = dp.value; }

        this.ShowTotals = DynProperty.getPropValue(newProperties, 'ShowTotals');
        this.ToolbarVisible = DynProperty.getPropValue(newProperties, 'ToolbarVisible');
    }

    private RecalculatedBalances (): void {
        const qt = this.getQuickTable();
        if (!qt) return;
        const rows = qt.rows;
        for (const r in rows) { rows[r].item.Recalc(); }
    };
}

ApplicationPanelWithTable.extendWith(PositionsBalancePanel,
    {
        data: function () {
            return {
                isAccountLinkShow: true,
                isSymbolLinkShow: true,
                isShowExploreTheAppLink: ApplicationInfo.isExploreMode
            };
        },

        partials: {
            bodyPartial: PositionsBalancePanelTemplate
        }
    }
);
