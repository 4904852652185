// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from '../../../Utils/CustomEvents';
import { Rectangle } from '../../../Commons/Geometry';
import { KeyCode, KeyEventProcessor } from '../../../Commons/KeyEventProcessor';
import { QuickTableUtils } from '../QuickTable/QuickTableUtils';
import { Scroll } from '../Scroll';
import { ThemeManager } from '../../misc/ThemeManager';
import { ScrollUtils } from '../../UtilsClasses/ScrollUtils';
import { type QuickTreeNode } from './QuickTreeNode';
import { InstrumentLookupQuickTreeNode } from './InstrumentLookupQuickTreeNode';

export class QuickTree {
    public DrawingContext: CanvasRenderingContext2D;
    public nodeCollection: QuickTreeNode[] = [];
    public selectedNode: QuickTreeNode | null = null;
    public lastSelectionNode: QuickTreeNode | null = null;

    public plainArray: Array<QuickTreeNode | InstrumentLookupQuickTreeNode> = [];
    public width = 100;
    public height = 100;
    public ClearColor = ThemeManager.CurrentTheme.quickTreeBackColor;

    public rowHeigth = QuickTree.NODE_HEIGHT;

    public scroll: Scroll;

    public workWithScroll = false;

    public isMultiSelect = false;
    public isMultiSelectCheckboxUsing = false;

    public selectedNodesDict: Record<string, QuickTreeNode> = {};

    public objNode = {};

    public boldRootNode = true;

    public SelectionChanged = new CustomEvent();
    public EnterPressed = new CustomEvent();
    public hoveredNode: InstrumentLookupQuickTreeNode | QuickTreeNode;
    // public OnUnCollaps = new CustomEvent();

    constructor (context: CanvasRenderingContext2D) {
        this.DrawingContext = context;

        this.DrawingContext.fillStyle = this.ClearColor;
        this.DrawingContext.fillRect(0, 0, this.width, this.height);

        this.scroll = new Scroll(this.width - Scroll.SCROLL_WIDTH, 0, Scroll.SCROLL_WIDTH, this.height, 0, 0, this.DrawingContext, this.rowHeigth);
        this.scroll.OnValueChange.Subscribe(this.Draw.bind(this), this);
    }

    public setSize (newWidth: number, newHeight: number): void {
        this.width = newWidth;
        this.height = newHeight;
        this.scroll.setBounds(new Rectangle(
            this.width - Scroll.SCROLL_WIDTH,
            0,
            Scroll.SCROLL_WIDTH,
            this.height));
    }

    public reInitScrollHeigth (): void {
        this.scroll.elementHeight = this.rowHeigth;
    }

    public onResize (): void {
        this.setSize(this.width, this.height);
        this.Draw(true);
    }

    public AddNode (newNode: QuickTreeNode): void {
        this.nodeCollection.push(newNode);
        this.Draw(true);
    }

    public AddNodeRange (newNodesAraay: QuickTreeNode[]): void {
        this.nodeCollection = this.nodeCollection.concat(newNodesAraay);
        this.Draw(true);
    }

    public RemoveNode (index: number): void {
        this.nodeCollection.splice(index, 1);
        this.Draw(true);
    }

    public Clear (): void {
        this.nodeCollection = [];

        const ctx = this.DrawingContext;

        // clear place
        ctx.fillStyle = this.ClearColor;
        ctx.fillRect(0, 0, this.width, this.height);

        this.SelectionChanged.Raise();
    }

    public Draw (needUpdatePlainArray: boolean = false): void {
        const ctx = this.DrawingContext;

        // clear place
        ctx.fillStyle = this.ClearColor;
        ctx.fillRect(0, 0, this.width, this.height);

        if (needUpdatePlainArray) {
            this.plainArray = this.createPlainArray();
        }

        const pl = this.plainArray;

        const len = pl.length;

        let curY = 4;
        const maxHeight = this.height;

        // let indexHovered = this.plainArray.indexOf(this.hoveredNode)

        if (needUpdatePlainArray) {
            this.scroll.setScrollElementsCount(len);
        }

        for (let i = this.scroll.scrollIndex; i < len; i++) {
            if (!pl[i].visible) {
                continue;
            }
            const node = pl[i];
            if (node instanceof InstrumentLookupQuickTreeNode) {
                node.Draw(ctx, curY, this.width, node === this.hoveredNode, this.scroll.Visible);
            } else {
                this.DrawNode(ctx, node, curY);
            }

            curY = curY + this.rowHeigth;
            if (maxHeight < curY) {
                break;
            }
        }

        this.scroll.Draw();
    }

    public DrawNode (ctx: CanvasRenderingContext2D, node: QuickTreeNode, curY: number): void {
        const curFont = ThemeManager.CurrentTheme.quickTreeNodeFont.copy();
        curFont.FontWeight = this.boldRootNode && node.nodeLevel === 1 ? 'bold' : 'normal';
        ctx.font = curFont.toString();

        let leftOffset = node.nodeLevel * QuickTree.Level_PADDING - 12;

        let dispRectX;
        let dispRectWidth = 0;

        let withCollapse = true;
        if (node.hasVisibleChild && node.HasChildNodes()) {
            if (dispRectX === undefined) {
                dispRectX = leftOffset;
            }

            node.CollapseRectangle.X = leftOffset + 4;
            node.CollapseRectangle.Y = curY + 3;
            node.CollapseRectangle.Width = QuickTree.COLLAPSE_ICON_SIZE;
            node.CollapseRectangle.Height = QuickTree.COLLAPSE_ICON_SIZE;

            dispRectWidth += node.CollapseRectangle.Width;
        } else {
            node.CollapseRectangle.Empty();
            withCollapse = false;
        }

        leftOffset += QuickTree.COLLAPSE_ICON_SIZE;

        if (this.isMultiSelectCheckboxUsing) {
            if (dispRectX === undefined) {
                dispRectX = leftOffset;
            }

            leftOffset += 3;

            node.CheckBoxRectangle.X = leftOffset;
            node.CheckBoxRectangle.Y = curY + 1;
            node.CheckBoxRectangle.Width = QuickTree.CHECKBOX_SIZE;
            node.CheckBoxRectangle.Height = QuickTree.CHECKBOX_SIZE;

            leftOffset += node.CheckBoxRectangle.Width;

            dispRectWidth += 3 + node.CheckBoxRectangle.Width;
        } else {
            node.CheckBoxRectangle.Empty();
        }

        if (node.defaultImage !== null && node.selectedImage !== null) {
            if (dispRectX === undefined) {
                dispRectX = leftOffset;
            }

            leftOffset += 3;

            node.ImgRectangle.X = leftOffset + 3;
            node.ImgRectangle.Y = curY + 6;
            node.ImgRectangle.Width = QuickTree.IMG_WIDTH;
            node.ImgRectangle.Height = QuickTree.IMG_HEIGHT;

            leftOffset += node.ImgRectangle.Width;

            dispRectWidth += 3 + node.ImgRectangle.Width;
        } else {
            node.ImgRectangle.Empty();
        }

        if (dispRectX === undefined) {
            dispRectX = leftOffset;
        }

        leftOffset += 3;

        node.TextRectangle.X = leftOffset;
        node.TextRectangle.Y = curY + 5;
        node.TextRectangle.Width = ctx.GetTextWidth(node.nodeText, node.font || curFont) + 8;
        node.TextRectangle.Height = QuickTree.NODE_HEIGHT;

        dispRectWidth += 3 + node.TextRectangle.Width;

        node.DisplayRectangle.X = dispRectX;
        node.DisplayRectangle.Y = curY;
        node.DisplayRectangle.Width = dispRectWidth;
        node.DisplayRectangle.Height = QuickTree.NODE_HEIGHT;

        if (node.selected) {
            const selectionRectangle = node.TextRectangle.copy();
            let newX = selectionRectangle.X;
            if (!node.CheckBoxRectangle.IsEmpty()) {
                newX = node.CheckBoxRectangle.X;
            } else if (!node.ImgRectangle.IsEmpty()) {
                newX = node.ImgRectangle.X;
            }
            selectionRectangle.Width = selectionRectangle.Width + selectionRectangle.X - newX;
            selectionRectangle.X = newX;
            // Utils.roundedRect(ctx, selectionRectangle, 2, App.ThemeManager.CurrentTheme.TreeSelection_Fill);
            ctx.FillRect(ThemeManager.CurrentTheme.TreeSelectionBrush, 0, node.DisplayRectangle.Y, this.width, node.DisplayRectangle.Height);
        }

        if (this.isMultiSelectCheckboxUsing) {
            const img = node.checked
                ? ThemeManager.CurrentTheme.checkBoxCheckedImg
                : ThemeManager.CurrentTheme.checkBoxUncheckedImg;

            if (img) {
                ctx.drawImage(img, node.CheckBoxRectangle.X + 1, node.CheckBoxRectangle.Y + 1);
            }
        }

        if (node.defaultImage !== null && node.selectedImage !== null) {
            const img = node.selected ? node.selectedImage : node.defaultImage;
            if (img) {
                ctx.drawImage(img, node.ImgRectangle.X, node.ImgRectangle.Y);
            }
        }

        if (withCollapse) {
            this.drawCollapseIcon(ctx, node, node === this.hoveredNode);
        }

        ctx.fillStyle = node.selected
            ? ThemeManager.CurrentTheme.Tree_TextSelectColor
            : (node.fontColor || ThemeManager.CurrentTheme.Tree_TextColor);

        if (isValidString(node.textPattern)) {
            const patternW = QuickTableUtils.GetWidth(ctx, node.textPattern) + 2;
            const indexT = node.nodeText.toLowerCase().indexOf(node.textPattern.toLowerCase());
            if (indexT !== -1) {
                const textOffset = QuickTableUtils.GetWidth(ctx, node.nodeText.substring(0, indexT));
                const oldFillStyle = ctx.fillStyle;
                ctx.fillStyle = ThemeManager.CurrentTheme.qtTextPatternColor;
                ctx.fillRect(node.TextRectangle.X + 4 + textOffset, node.TextRectangle.Y, patternW, node.TextRectangle.Height - 10);
                ctx.fillStyle = oldFillStyle;
            }
        }

        ctx.textBaseline = 'top';
        if (isValidString(node.font)) {
            ctx.font = node.font;
        }

        ctx.fillText(node.nodeText, node.TextRectangle.X + 4, node.TextRectangle.Y + 3);
    }

    public drawCollapseIcon (ctx: CanvasRenderingContext2D, node: QuickTreeNode, hovered: boolean): void {
        if (isNullOrUndefined(node)) return;

        const theme = ThemeManager.CurrentTheme;

        let img = null;
        if (node.collapsed) { img = theme[hovered ? 'treeCloseHoverImage' : 'treeCloseDefaultImage']; } else { img = theme[hovered ? 'treeOpenHoverImage' : 'treeOpenDefaultImage']; }

        if (!img) return;

        ctx.drawImage(
            img,
            node.CollapseRectangle.X + 4,
            node.CollapseRectangle.Y + (node.collapsed ? 4 : 6));
    }

    public createPlainArray (): Array<QuickTreeNode | InstrumentLookupQuickTreeNode> {
        const newPlainArray = [];
        const curLevel = 1;
        const myNodes = this.nodeCollection;
        const len = myNodes.length;
        for (let i = 0; i < len; i++) {
            this.createPlain(myNodes[i], newPlainArray, curLevel);
        }

        return newPlainArray;
    }

    public createPlain (curNode: QuickTreeNode | InstrumentLookupQuickTreeNode, array: Array<QuickTreeNode | InstrumentLookupQuickTreeNode>, level: number): void {
        curNode.nodeLevel = level;
        array.push(curNode);

        if (curNode.collapsed) {
            this.processNode(curNode);
            return;
        }

        level++;

        curNode.hasVisibleChild = false;

        const curNodeNodes = curNode.childNodes;
        const len = curNodeNodes.length;
        for (let i = 0; i < len; i++) {
            this.createPlain(curNodeNodes[i], array, level);

            if (curNodeNodes[i].visible) {
                curNode.hasVisibleChild = true;
            }
        }
        curNode.visible = curNode.hasVisibleChild;
        level--;
    }

    public processNode (curNode: QuickTreeNode): void {
        const curNodeNodes = curNode.childNodes;
        const len = curNodeNodes.length;
        curNode.hasVisibleChild = false;
        for (let i = 0; i < len; i++) {
            this.processNode(curNodeNodes[i]);
            if (curNodeNodes[i].visible) {
                curNode.hasVisibleChild = true;
            }
        }
    }

    public onMouseDown (event): void {
        if (this.scroll.containerRectangle.Contains(event.offsetX, event.offsetY)) {
            this.scroll.mouseDown(event);
            this.workWithScroll = true;
            return;
        }

        const curNode = this.findNode(event.offsetX, event.offsetY);
        if (isNullOrUndefined(curNode)) {
            return;
        }

        if (curNode.CollapseRectangle.Contains(event.offsetX, event.offsetY)) {
            curNode.DoCollapse();
            this.Draw(true);
            return;
        }

        if (this.isMultiSelect && KeyEventProcessor.ShiftPressed && (!isNullOrUndefined(this.lastSelectionNode?.parentNode) && !isNullOrUndefined(curNode.parentNode))) {
        // var objNode = {};
            const objNodeTmp = this.addNodeToDictShift(curNode, this.objNode);
            for (let i = 0; i < objNodeTmp.nodes.length; i++) {
                this.addNodeToDict(objNodeTmp.nodes[i], false);
            }

            objNodeTmp.nodes = [];

            let selCurNode = null;
            const indexNode = curNode.parentNode.childNodes.indexOf(curNode);
            const indexLastNode = this.lastSelectionNode.parentNode.childNodes.indexOf(this.lastSelectionNode);
            const minIndex = Math.min(indexNode, indexLastNode);
            const maxIndex = Math.max(indexNode, indexLastNode);
            for (let i = minIndex; i <= maxIndex; i++) {
            // selCurNode = curNode.parentNode.childNodes[i];
            // objNode = this.addNodeToDictShift(selCurNode);
            // this.addNodeToDict(selCurNode, !selCurNode.selected)
                selCurNode = curNode.parentNode.childNodes[i];
                objNodeTmp[i] = selCurNode;
                objNodeTmp.nodes.push(selCurNode);
                this.addNodeToDict(selCurNode, true);
            }
            this.SelectionChanged.Raise();
            this.Draw(true);
            // this.lastSelectionNode = curNode;
            return;
        }

        // TODO. Refactor. "Check mark" selection.
        if (this.isMultiSelect && KeyEventProcessor.ControlPressed) {
            if (Object.keys(this.objNode).length > 0) {
                const k = Object.keys(this.objNode);
                for (let i = 0; i < k.length; i++) {
                    this.clearDict(this.objNode[k[i]]);
                }
                this.objNode = {};
                this.CheckUnCheckAll(false);
            }

            this.addNodeToDict(curNode, !curNode.selected);
            this.SelectionChanged.Raise();
            this.Draw(true);
            this.lastSelectionNode = curNode;
            return;
        }

        if (this.isMultiSelectCheckboxUsing && curNode.CheckBoxRectangle.Contains(event.offsetX, event.offsetY)) {
            curNode.checked = !curNode.checked;
            this.addNodeToDict(curNode, curNode.checked);
            this.Draw(true);
            this.SelectionChanged.Raise();
            return;
        }

        if (Object.keys(this.objNode).length > 0) {
            const k = Object.keys(this.objNode);
            for (let i = 0; i < k.length; i++) {
                this.clearDict(this.objNode[k[i]]);
            }
            this.objNode = {};
            this.CheckUnCheckAll(false);
        }

        // Check mark + node selection.
        this.setSelectedNode(curNode);
        this.lastSelectionNode = curNode;
    }

    public findNode (x: number, y: number): QuickTreeNode | InstrumentLookupQuickTreeNode {
        const pl = this.plainArray;
        const startFind = this.scroll.scrollIndex;
        const endFind = startFind + this.scroll.getVisibleCount();
        const plLen = pl.length;
        for (let i = startFind; i < endFind && i < plLen; i++) {
            const node = pl[i];
            if (node.DisplayRectangle.Contains(x, y)) {
                return node;
            }
        }
    }

    public clearDict (dict): void {
        const k = Object.keys(dict);
        if (!k.includes('nodes')) {
            return;
        }

        for (let i = 0; i < k.length; i++) {
            if (k[i] === 'nodes') {
                continue;
            }

            this.clearDict(dict[k[i]]);
            if (!Array.isArray(dict[k[i]]) && !Object.keys(dict[k[i]]).includes('nodes')) {
                this.addNodeToDict(dict[k[i]], false);
            }
        }
    }

    public onMouseMove (event): void {
        this.hoveredNode = this.findNode(event.offsetX, event.offsetY);
        this.Draw();

        // For scroll hovering.
        this.scroll.onMouseMove(event);
        if (this.workWithScroll) {
        // For scroll moving.
            this.scroll.mouseMove(event);
        }
    }

    public onMouseUp (event): void {
        if (this.workWithScroll) {
            this.workWithScroll = false;
            this.scroll.mouseUp(event);
        }
    }

    public onMouseWheel (event): void {
        this.scroll.mouseWheel(ScrollUtils.IsScrollUp(event.deltaY));
    }

    public onDoubleClick (event): boolean {
        const curNode = this.findNode(event.offsetX, event.offsetY);
        if (isNullOrUndefined(curNode)) {
            return false;
        }

        if (curNode.childNodes.length > 0) {
            curNode.collapsed = !curNode.collapsed;
            this.Draw(true);
            return false;
        }
        this.selectedNode = curNode;
        this.SelectionChanged.Raise();
        return true;
    }

    // TODO. Optimize if necessary.
    public setSelectedNode (node: QuickTreeNode, skipUnSelect = false): void {
        if (isNullOrUndefined(node)) {
            return;
        }

        this.CheckUnCheckAll(false);

        if (!isNullOrUndefined(this.selectedNode)) {
            this.selectedNode.selected = false;
        }

        if (this.isMultiSelect) {
            node.setCheck(true);
            if (!skipUnSelect && !isNullOrUndefined(this.selectedNode)) {
                this.selectedNode.setCheck(false);
                this.addNodeToDict(this.selectedNode, false);
            }
            this.addNodeToDict(node, true);
        }

        node.selected = true;
        this.selectedNode = node;
        this.SelectionChanged.Raise();

        this.Draw(true);
    }

    public setCheckedNode (node: QuickTreeNode): void {
        node.checked = true;
        this.addNodeToDict(node, node.checked);
        this.SelectionChanged.Raise();
    }

    public goToSelectedNode (): void {
        this.unCollapseAllParents(this.selectedNode);
        this.Draw(true);
        const index = this.plainArray.indexOf(this.selectedNode);
        this.scroll.trailingScroll(index);
    }

    // при true пропускаем те что помечены missAtUnCollapse
    public unCollapseAllPosible (considerMissed: boolean): void {
        const arr = this.nodeCollection;
        const len = arr.length;
        for (let i = 0; i < len; i++) {
            arr[i].collapsed = false;
            this.unCollapseAllPosibleWork(arr[i].childNodes, considerMissed);
        }

        this.Draw(true);
        this.scroll.moveScrollToElement(0);
    }

    public unCollapseAllPosibleWork (workArr: any[], considerMissed: boolean): void {
        const len = workArr.length;
        for (let i = 0; i < len; i++) {
            if (considerMissed && workArr[i].missAtUnCollapse || workArr[i].childNodes.length === 0) {
                continue;
            }

            workArr[i].collapsed = false;
            this.unCollapseAllPosibleWork(workArr[i].childNodes, considerMissed);
        }
    }

    public CheckUnCheckAll (checkAction): void {
        if (this.isMultiSelectCheckboxUsing) {
            return;
        }
        const len = this.nodeCollection.length;
        for (let i = 0; i < len; i++) {
            this.addNodeToDict(this.nodeCollection[i], checkAction);
        }

        this.SelectionChanged.Raise();
        this.Draw();
    }

    public CheckUnCheckAllMultiCheckbox (checkAction: boolean): void {
        const len = this.nodeCollection.length;
        for (let i = 0; i < len; i++) {
            this.nodeCollection[i].checked = checkAction;
            this.addNodeToDict(this.nodeCollection[i], checkAction);
        }

        this.SelectionChanged.Raise();
        this.Draw();
    }

    public addNodeToDict (newNode: QuickTreeNode, checkAction: boolean): void {
        if (isNullOrUndefined(newNode)) {
            return;
        }

        const fullPath = newNode.getFullPath();
        const childCount = newNode.childNodes.length;
        const hasChild = childCount > 0;
        if (!this.isMultiSelectCheckboxUsing) {
            newNode.selected = checkAction;
        }
        if (hasChild) {
            const len = childCount;
            for (let i = 0; i < len; i++) {
                this.addNodeToDict(newNode.childNodes[i], checkAction);
            }
        } else {
            if (checkAction) {
                this.selectedNodesDict[fullPath] = newNode;
            } else {
                delete this.selectedNodesDict[fullPath];
            }
        }
    // newNode.setCheck(selectedAction);
    }

    public addNodeToDictShift (curNode: QuickTreeNode, objectNode): any {
        if (isNullOrUndefined(curNode)) {
            return;
        }

        // var objectNode = {};

        const curN = this.getParrentForNode(curNode, objectNode, 0);
        curN[curNode.parentNode.childNodes.indexOf(curNode)] = curNode;

        return curN;
    }

    public getParrentForNode (node, objNodeTree, level: number): any {
        let index = -1;
        const parent = node.parentNode;
        let obj = null;
        if (parent !== null) {
            obj = this.getParrentForNode(parent, objNodeTree, ++level);
            if (level === 1) {
                return obj;
            }

            index = node.parentNode.childNodes.indexOf(node);
            if (index === -1) {
                return obj;
            }

            obj = this.addToObjNodeTree(obj, index);
        } else {
            index = this.nodeCollection.indexOf(node);
            obj = this.addToObjNodeTree(objNodeTree, index);
        }

        return obj;
    }

    public addToObjNodeTree (objNodeTree, indexNew: number): any {
        if (isNullOrUndefined(objNodeTree[indexNew])) {
            objNodeTree[indexNew] = {};
        }

        if (isNullOrUndefined(objNodeTree[indexNew].nodes)) {
            objNodeTree[indexNew].nodes = [];
        }

        return objNodeTree[indexNew];
    }

    public clearNodeDict (): void {
        this.selectedNodesDict = {};
    }

    public onKeyUp (): void { }

    public onKeyDown (): void {
        const keyProc = KeyEventProcessor;
        const currentButton = keyProc.currentButton;

        if (currentButton === KeyCode.ENTER) {
            this.EnterPressed.Raise();
            return;
        }

        if (currentButton !== KeyCode.UP &&
        currentButton !== KeyCode.DOWN &&
        currentButton !== KeyCode.LEFT &&
        currentButton !== KeyCode.RIGHT) {
            return;
        }

        if (currentButton === KeyCode.UP || currentButton === KeyCode.DOWN) {
            this.trySelectUpDown(keyProc.currentButton === KeyCode.UP);
        }

        if (currentButton === KeyCode.LEFT || currentButton === KeyCode.RIGHT) {
            this.trySelectLeftRight(keyProc.currentButton === KeyCode.LEFT);
        }
    }

    public trySelectUpDown (up: boolean): void {
        const plainArray = this.plainArray;

        const plainArrLength = plainArray.length;
        if (plainArrLength === 0) {
            return;
        }

        const selectedNode = this.selectedNode;
        const selectedIndex = plainArray.indexOf(selectedNode);

        if ((selectedIndex === 0 && up) || (selectedIndex === (plainArrLength - 1) && !up)) {
            return;
        }

        if (up) {
            this.setSelectedNode(plainArray[selectedIndex - 1]);
        } else {
            this.setSelectedNode(plainArray[selectedIndex + 1]);
        }

        this.goToSelectedNode();
    }

    public trySelectLeftRight (left: boolean): void {
        const plainArray = this.plainArray;

        const plainArrLength = plainArray.length;
        if (plainArrLength === 0) {
            return;
        }

        const selectedNode = this.selectedNode;

        const parrent = selectedNode.parentNode;

        if (left) {
            if (!selectedNode.collapsed) {
                selectedNode.collapsed = true;
            } else if (parrent !== null) {
                this.setSelectedNode(parrent);
                this.goToSelectedNode();
            }
        } else {
            if (!selectedNode.collapsed && selectedNode.childNodes.length !== 0) {
                this.setSelectedNode(selectedNode.childNodes[0]);
                this.goToSelectedNode();
            }
            if (selectedNode.collapsed && selectedNode.childNodes.length !== 0) {
                selectedNode.DoCollapse();
            }
        }
        this.Draw(true);
    }

    public unCollapseAllParents (node: QuickTreeNode): void {
        if (isNullOrUndefined(node)) {
            return;
        }

        let parentNode = node.parentNode;
        while (parentNode !== null) {
            parentNode.collapsed = false;
            parentNode = parentNode.parentNode;
        }
    }

    public themeChange (): void {
        this.Draw();
    }

    public static readonly PADDING_TOP = 5;
    public static readonly PADDING_LEFT = 5;
    public static readonly NODE_HEIGHT = 25;
    public static readonly Level_PADDING = 15;
    public static readonly COLLAPSER_WIDTH = 25;

    public static readonly COLLAPSE_ICON_SIZE = 16;
    public static readonly CHECKBOX_SIZE = 16;

    public static readonly IMG_WIDTH = 28;
    public static readonly IMG_HEIGHT = 12;
}
