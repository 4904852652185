// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { type Account } from '../../Account';
import { type Instrument } from '../../Instrument';

export class OrderTypeBaseParameters {
    public instrument: Instrument | null;
    public account: Account | null;
    public checkSession: boolean;
    public checkRuleForTrailingStopOrder: boolean;

    constructor (instrument: Instrument | null = null, account: Account | null = null, checkSession = true, checkRuleForTrailingStopOrder = true) {
        this.instrument = instrument;
        this.account = account;
        this.checkSession = checkSession;
        this.checkRuleForTrailingStopOrder = checkRuleForTrailingStopOrder;
    }

    public setInstrument (instrument): void {
        this.instrument = instrument;
    }

    public setAccount (account): void {
        this.account = account;
    }

    public setCheckSession (checkSession): void {
        this.checkSession = checkSession;
    }
}
