// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type PFixFieldSet } from '../Fields/FieldsImport';
import { PasswordRecoveryChangeResponse } from '../Messages/PasswordRecoveryChangeResponse';
import { MessageProcessorBase } from './MessageProcessorBase';

export class PasswordRecoveryChangeMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): PasswordRecoveryChangeResponse[] {
        const message = new PasswordRecoveryChangeResponse(fieldSet);
        return [message];
    }
}
