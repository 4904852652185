// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

export enum TradeStatusEnum {
    Increase = 0,
     Decrease = 1,
     Open = 2,
     Close = 3,
     Reverce = 4
}

export enum TradeSideEnum {
    Long = 0,
    Short = 1,
    None = 255
}
