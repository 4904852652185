// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { MainWindowManager } from '../../UtilsClasses/MainWindowManager';
import { Resources } from '../../../Commons/properties/Resources';
import { ControlEvents } from '../Control_ts';
import { TerceraDateSelectorDropDown } from './TerceraDateSelectorDropDown.js';
import { DateTimeUtils } from '../../../Utils/Time/DateTimeUtils';
import { TerceraLookup } from './TerceraLookup';

export class TerceraDateSelectorLookup extends TerceraLookup {
    constructor () {
        super();
    }

    public override getType (): string { return 'TerceraDateSelectorLookup'; }

    public override showForm (): void {
        const dropDownForm = new TerceraDateSelectorDropDown();
        dropDownForm.setCorrectLocation(this.getX(), this.getY());
        MainWindowManager.MainWindow.addControl(dropDownForm);

        dropDownForm.on('timeSelectorLookupClosed', function () {
            this.fire('timeSelectorLookupClosed');
        }.bind(this));
        dropDownForm.observe('date', function (newD, oldD) {
            if (newD) {
                this.set('expirationTime', newD);
            }
        }.bind(this), { init: false });

        void dropDownForm.set('date', this.get('expirationTime'));
        this.fire('timeSelectorLookupOpened');
    }

    public override oninit (): void {
        super.oninit();

        this.repopulate();
    }

    public override oncomplete (): void {
        super.oncomplete();
        this.observe('expirationTime', (newValue) => {
            this.repopulate(newValue);
        });
        this.textBox.on(ControlEvents.GotFocus, function () { this.showForm(); }.bind(this));
    }

    public override getButtonTooltip (): string {
        return Resources.getResource('screen.Alerts.Label.Expiration.Tooltip');
    }

    public repopulate (newD?): void {
        if (!isNullOrUndefined(newD)) {
            this.setItems({ text: DateTimeUtils.formatDate(newD, 'DD.MM.YYYY HH:mm') });
        } else {
            this.setItems({ text: 'No Data' });
        }

        this.setSelectValue(this.items[0]);
    }
}

TerceraLookup.extendWith(TerceraDateSelectorLookup, {
    data: function () {
        return {
            btnClass: 'js-lookup-datetime',
            btnName: 'dateTimeLookup',
            name: 'TerceraDateSelectorLookup',
            expirationTime: new Date(),
            isReadOnly: true,
            canSelect: false
        };
    },
    selectedDate: null
});
