import { DataCache } from '../../DataCache';
import { Instrument } from '../../cache/Instrument';
import { type InstrumentGroup } from '../../cache/InstrumentGroup';
import { Resources } from '../../properties/Resources';
import { TvSymbolConvertor } from '../Convertors/TvSymbolConvertor';
import { type DatafeedSymbolType } from '../charting_library';

class _TvInstrumentTypesManager {
    public readonly AllTypes = 'all';

    public getInstrumentTypes (): DatafeedSymbolType[] {
        let instrumentTypes: number[] = DataCache.NonFixedList ? this.getInstrumentTypesByInstrumentGroup() : [];
        if (instrumentTypes.length === 0) {
            instrumentTypes = this.getInstrumentTypesByOldScheme();
        }

        if (instrumentTypes.length === 0) {
            return [];
        }

        const symbolTypes: DatafeedSymbolType[] = [];

        for (const instType of instrumentTypes) {
            const symbolTypeValue = TvSymbolConvertor.getSymbolType(instType);
            const exists = symbolTypes.some(symbolType => symbolType.value === symbolTypeValue);
            if (exists) { // for CFDs
                continue;
            }

            const tvSymbolType: DatafeedSymbolType = {
                name: Instrument.getTypeString(instType),
                value: symbolTypeValue
            };
            symbolTypes.push(tvSymbolType);
        }
        symbolTypes.sort(this.compareTo);

        const allSymbolType: DatafeedSymbolType = {
            name: Resources.getResource('panel.TerceraSymbolLookupDropDownForm.typeComboBox.All types'),
            value: this.AllTypes
        };
        symbolTypes.unshift(allSymbolType);

        return symbolTypes;
    }

    private getInstrumentTypesByInstrumentGroup (): number[] {
        const instrumentTypes: number[] = [];
        for (const type in DataCache.instrTypes) {
            const instrumentType: InstrumentGroup = DataCache.instrTypes[type];
            const instType: number | null = instrumentType.InstrumentType;
            if (!isNullOrUndefined(instType) && !instrumentTypes.includes(instType)) {
                instrumentTypes.push(instType);
            }
        }
        return instrumentTypes;
    }

    /*
    * This method is used for compatibility with the old scheme.
    */
    private getInstrumentTypesByOldScheme (): number[] {
        const instrumentTypes: number[] = [];
        if (DataCache.NonFixedList) {
            return [];
        }

        for (const instrumentType in DataCache.ExistingInstrumentTypesSet) {
            const instType = parseInt(instrumentType);
            if (!isNullOrUndefined(instType) && !instrumentTypes.includes(instType)) {
                instrumentTypes.push(instType);
            }
        }
        return instrumentTypes;
    }

    private compareTo (a, b): number {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0; // a и b равны
    }
}

export const TvInstrumentTypesManager = new _TvInstrumentTypesManager();
