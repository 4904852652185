// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { CashItem } from '../../Commons/cache/History/CashItem';
import { type TerceraChartCashItemSeries } from '../../Chart/Series/TerceraChartCashItemSeries';
import { type MovingAverageMapType, type IVolumeBarsMovingAverage, MovingAverageMap } from './IVolumeBarsMovingAverage';

export class VolumeBarsMovingAverage implements IVolumeBarsMovingAverage {
    setMovingAveragePeriod (period: number): void { this.movingAveragePeriod = period; }

    async calculateMovingAverage (cashItemSeries: TerceraChartCashItemSeries, signal: AbortSignal | null): Promise<MovingAverageMapType> {
        return await new Promise((resolve) => {
            if (signal?.aborted) { resolve(null); } else { resolve(this.calculateMovingAverageMapProcess(cashItemSeries)); }
        });
    }

    private movingAveragePeriod: number = 21;
    private readonly sumVolume: number = 0;

    private calculateMovingAverageMapProcess (cashItemSeries: TerceraChartCashItemSeries): MovingAverageMapType {
        const volumes: Map<number, number> = new Map<number, number>();

        const count = cashItemSeries.Count();

        for (let i = 0; i < count; i++) {
            const index = cashItemSeries.GetIndex(i);
            const volume = cashItemSeries.GetValue(index, CashItem.VOLUME_INDEX);

            if (!volumes.has(index)) {
                volumes.set(index, volume);
            } else {
            // Return or handle the case where volumes already has the current index
            // Depending on your logic, you might want to log an error or handle it differently
                return MovingAverageMap(); // or throw an error, etc.
            }
        }

        const map: MovingAverageMapType = MovingAverageMap();
        for (let i = this.movingAveragePeriod - 1; i < count; i++) {
            let sum = 0;
            for (let j = i - (this.movingAveragePeriod - 1); j <= i; j++) {
                const index = cashItemSeries.GetIndex(j);
                sum += volumes.get(index) ?? 0;
            }

            map?.set(cashItemSeries.GetIndex(i), sum / this.movingAveragePeriod);
        }

        return map;
    }
}
