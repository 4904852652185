// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PFixField } from './PFixField';
import { inflate } from 'pako';
import * as fzstd from 'fzstd';

export class PFixCompressedByteArrayField extends PFixField {
    constructor (fieldId, buffer?) {
        super(fieldId, buffer);

        this.Value = [];
    }

    public override ReadValue (buffer): void {
        const len = buffer.ReadInt32();
        const compressionType = buffer.ReadByte();
        const data = buffer.ReadBuffer(len).ReadAllBytes();

        if (compressionType == 0) // zip
        {
            this.Value = this.uncompress(data);
        } else if (compressionType == 1) {
            this.Value = fzstd.decompress(new Uint8Array(data));
        } else // данные не сжимались
        {
            this.Value = data;
        }
    }

    public override Write (buffer): void {
    // byte[] compressed = com.pfsoft.proftrading.util.Utils.ZipLibStreamCompressBuffer(Value);
    // int len = compressed.Length;

    // buffer.Write((short)FieldId); //записываем номер филда
    // buffer.Write(len); //записываем длину
    // buffer.Write(0); //тип архивации 0 - zip
    // buffer.Write(compressed);
    }

    private uncompress (data): any {
        return [].slice.call(inflate(new Uint8Array(data)));
    }
}
