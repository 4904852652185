// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { WebTraderTemplate } from '../../templates';
import { TerceraAccountLookupDropDownForm } from '../elements/Lookup/TerceraAccountLookupDropDownForm';
import { TerceraInstrumentLookupDropDownForm } from '../elements/Lookup/TerceraInstrumentLookupDropDownForm';
import { ControlsUtils } from './ControlsUtils';

const mainWindowElementID = 'mainWindow'; // Do we need to unify IDs between WebTraderTemplate and 'el = '#mainWindow'' in NewNetTrader/PortfolioManagement?
class _FullScreenManager {
    public AccountLookupDropDownForm: null | TerceraAccountLookupDropDownForm = null;
    public InstrumentLookupDropDownForm: null | TerceraInstrumentLookupDropDownForm = null;
    private showHeaderBackup: boolean = null;
    private readonly cachedTemplateElements = new Map<string, Element>();

    public HandleFullscreenChange (event: Event): void {
        if (this.IsFullScreenMode) {
            this.handleFullscreenEnter();
        } else {
            this.handleFullscreenExit(event);
        }
    }

    public get IsFullScreenMode (): boolean {
        return document.fullscreenElement != null;
    }

    // Event triggered upon entering Full Screen Mode: document.fullscreenElement contains the div of the Full Screen Mode panel
    private handleFullscreenEnter (): void {
        const fullscreenElement: HTMLElement = document.fullscreenElement as HTMLElement;
        const mainWindow: HTMLElement = document.getElementById(mainWindowElementID);

        if (fullscreenElement != null && mainWindow != null) {
            this.movePopupContainersToFullscreenPanel(fullscreenElement, mainWindow);
            void this.createDropDownFormContainers();
            const fullscreenElementRactive = ControlsUtils.getRactiveFromHTMLElement(fullscreenElement);
            this.showHeaderBackup = fullscreenElementRactive == null ? null : fullscreenElementRactive.get('showHeader');
            if (fullscreenElementRactive != null) {
                this.showHeaderBackup = fullscreenElementRactive.get('showHeader');
                void fullscreenElementRactive.set('showHeader', false);
            }
            else {
                this.showHeaderBackup = null;
            }
        }
    }

    // Event triggered upon exiting Full Screen Mode: document.fullscreenElement === null already
    private handleFullscreenExit (event: Event): void {
        const fullscreenElement: HTMLElement = event.target as HTMLElement;
        const mainWindow: HTMLElement = document.getElementById(mainWindowElementID);

        if (fullscreenElement != null && mainWindow != null) {
            this.removeContainersFromFullscreenPanel(fullscreenElement, mainWindow);
            this.removeDropDownFormContainers();
            if (this.showHeaderBackup !== null) {
                const fullscreenElementRactive = ControlsUtils.getRactiveFromHTMLElement(fullscreenElement);
                if (fullscreenElementRactive?.set != null) {
                    void fullscreenElementRactive.set('showHeader', this.showHeaderBackup);
                }
            }
        }
    }

    // Adding to panel that is in FullScreenMode 'containers' for account/instrument/etc. dropDownForms
    private async createDropDownFormContainers (): Promise<void> {
        this.AccountLookupDropDownForm = await TerceraAccountLookupDropDownForm.createInstance();
        this.InstrumentLookupDropDownForm = await TerceraInstrumentLookupDropDownForm.createInstance();
    }

    // Adding all (such as tooltipContainer, contextMenuContainer etc.) from WebTraderTemplate.html exclude <div id="mainWindow">
    private movePopupContainersToFullscreenPanel (fullscreenElement: HTMLElement, mainWindow: HTMLElement): void {
        const popupContainers: string = WebTraderTemplate;

        const tempContainer: HTMLDivElement = document.createElement('div');
        tempContainer.innerHTML = popupContainers;

        const tempChildren: Element[] = Array.from(tempContainer.children);
        const mainContainer: HTMLElement = mainWindow.parentElement;

        tempChildren.forEach((child: Element) => {
            const id: string = child.id;
            if (id === mainWindowElementID || id === '') { return; }
            const existingElement: HTMLElement = mainContainer.querySelector(`#${id}`);
            if (existingElement != null) {
                const element: HTMLElement = fullscreenElement.appendChild(existingElement);
                this.cachedTemplateElements.set(id, element);
            }
        });
    }

    private removeContainersFromFullscreenPanel (fullscreenElement: HTMLElement, mainWindow: HTMLElement): void {
        const mainContainer: HTMLElement = mainWindow.parentElement;
        Array.from(this.cachedTemplateElements.keys()).forEach((id: string) => {
            const existingElement: HTMLElement = fullscreenElement.querySelector(`#${id}`);
            if (existingElement != null && mainContainer != null) {
                mainContainer.appendChild(existingElement); // Move existing container of contextMenus/tooltips, etc. back to document.body
                this.cachedTemplateElements.delete(id);// //  After moving, clearing it from cache goes
            }
        });
    }

    private removeDropDownFormContainers (): void {
        if (this.AccountLookupDropDownForm != null) {
            this.AccountLookupDropDownForm.dispose();
        }
        if (this.InstrumentLookupDropDownForm != null) {
            this.InstrumentLookupDropDownForm.dispose();
        }
    }
}

export const FullScreenManager = new _FullScreenManager();
