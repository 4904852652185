// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Point, Rectangle } from '../../Commons/Geometry';
import { Cursors } from '../../Commons/Cursors';
import { LayersEnum, TerceraChartBaseRenderer } from './TerceraChartBaseRenderer';
import { TerceraChartCashItemSeriesDataType } from '../Series/TerceraChartCashItemSeriesEnums';
import { SolidBrush } from '../../Commons/Graphics';
import { MouseButtons } from '../../Controls/UtilsClasses/ControlsUtils';
import { TerceraChartAction, TerceraChartActionEnum } from '../TerceraChartAction';
import { TerceraChartPriceScaleRendererMouseState } from '../Renderers/Scales/TerceraChartPriceScaleRenderer';
import { BrowserUtils } from '../../Commons/UtilsClasses/UtilsImport';
import { Resources } from '../../Commons/properties/Resources';
import { ThemeManager } from '../../Controls/misc/ThemeManager';
import { DateTimeUtils } from '../../Utils/Time/DateTimeUtils';
import { CashItem } from '../../Commons/cache/History/CashItem';
import { DynProperty, PairColor } from '../../Commons/DynProperty';
import { DateTimeConvertor } from '../../Utils/Time/DateTimeConvertor';
import { Periods } from '../../Utils/History/TFInfo';
import { type TerceraChartBase } from '../TerceraChartBase';
import { type TerceraChartWindowBase } from '../Windows/TerceraChartWindowBase';
import { InfoWindowDockingType } from './InfoWindow/InfoWindowDockingType';
// #region Nested in class help items

export enum InfoWindowShowingType {
    None = -1,
    IntradayAbsoluteChart = 0,
    TicksAbsoluteChart = 1,
    PortfolioGrowth = 2
}

enum InfoWindowItemType {
    None = -1,
    Bars = 0,
    Date = 1,
    Time = 2,
    Additionalinfo = 3,
    Open = 4,
    High = 5,
    Low = 6,
    Close = 7,
    HighLow = 8,
    Change = 9,
    Ticks = 10,
    Volume = 11,
    Ask = 12,
    Bid = 13,
    PortfolioGrowth = 14,
    Funding = 15,
    Countdown = 16
    /*, Price, Last, Value */
}

// --------------------------

class InfoWindowDescriptionItem {
    public Label = '';
    public Value = '';
    public Font: any = null;
    public Visible = true;
    public Allowed = true;
    public curLabelLabelWidth = 0;
    public curValueWidth = 0;
    // по этому айтему будет происходить коллапс
    public CollapserItem = false;

    get LabelWidth (): number {
        return this.curLabelLabelWidth;
    }

    get ValueWidth (): number {
        return this.curValueWidth;
    }

    public SetValueWidth (newValueWidth, forceReset?: boolean): number {
        forceReset = forceReset || false;

        let w = Math.max(newValueWidth, 10); // min is 10 px
        w = Math.min(w, 300); // max is 300

        if (this.curValueWidth < w || forceReset) {
            this.curValueWidth = w;
        }

        // как угодно можно ширину выставить
        if (this.CollapserItem) {
            this.curValueWidth = newValueWidth;
        }

        return this.curValueWidth;
    }

    public SetLabelWidth (newLabelWidth, forceReset?: boolean): number {
        forceReset = forceReset || true;
        let w = Math.max(newLabelWidth, 10); // min is 10 px
        w = Math.min(w, 300); // max is 300

        if (this.curLabelLabelWidth < w || forceReset) {
            this.curLabelLabelWidth = w;
        }

        return this.curLabelLabelWidth;
    }
}

// #endregion

export class TerceraChartInfoWindowRenderer extends TerceraChartBaseRenderer {
    public window: TerceraChartWindowBase;
    public DictItems: Record<number, InfoWindowDescriptionItem>;
    public forceInfoWindowShowingType: any = null; // Для установки особого типа - например для Portfolio growth
    public lastInfoWindowShowingType: number;

    // #region Rectangles, width, mouse
    // Прямоугольник, который содержит координаты окна, в которых оно отображается
    public LastBounds = new Rectangle();

    public rectExpandButton = new Rectangle(0, 0, 16, 16);
    public rectCloseButton = new Rectangle(0, 0, 16, 16);

    public curLabelWidthMax = 0;
    public curValueWidthMax = 0;
    public curTotalWidth = 0;

    public Docked: number;

    // Определяет находится ли сейчас мышка в окне
    public IsMouseIn = false;
    public lastMouseMoveLocation = new Point();
    public mouseState = TerceraChartPriceScaleRendererMouseState.None;

    public mouseLeave = false;
    // #endregion

    // #region Font, Color, Images, ...

    public closeButtonBmp: any = null;
    public chartCollapseBmp: any = null;
    public chartExpandBmp: any = null;

    public FFontBold: any = null;

    public rowHeight = 0;

    public FCurrentBrush: SolidBrush | null = null;

    public FCurrentForeBrush: SolidBrush | null = null;
    // #endregion

    public lastDrawCount = 0;
    public expanderWidth = 0;

    public assignLayer = LayersEnum.CrossHair;

    public browserOffset = BrowserUtils.isEdgeOrIEBrowser ? 1 : 3;

    private shortMode = false;
    private expanded = false;

    private FFont: any = null;
    private FBackColor: any = null;
    private FForeColor: any = null;

    private instrument: any = null;
    private account: any = null;

    constructor (chart: TerceraChartBase, chartWindow: TerceraChartWindowBase) {
        super(chart);

        this.window = chartWindow;

        // #region InfoWindowShowingType

        // Список всех возхможных item'ов для отображения
        const dict = {};

        dict[InfoWindowItemType.Bars] = new InfoWindowDescriptionItem();
        dict[InfoWindowItemType.Date] = new InfoWindowDescriptionItem();
        dict[InfoWindowItemType.Time] = new InfoWindowDescriptionItem();

        const addInfoDescrItem = new InfoWindowDescriptionItem();
        addInfoDescrItem.CollapserItem = true;
        dict[InfoWindowItemType.Additionalinfo] = addInfoDescrItem;

        dict[InfoWindowItemType.Open] = new InfoWindowDescriptionItem();
        dict[InfoWindowItemType.Close] = new InfoWindowDescriptionItem();
        dict[InfoWindowItemType.High] = new InfoWindowDescriptionItem();
        dict[InfoWindowItemType.Low] = new InfoWindowDescriptionItem();
        dict[InfoWindowItemType.HighLow] = new InfoWindowDescriptionItem();
        dict[InfoWindowItemType.Change] = new InfoWindowDescriptionItem();
        dict[InfoWindowItemType.Ticks] = new InfoWindowDescriptionItem();
        dict[InfoWindowItemType.Volume] = new InfoWindowDescriptionItem();
        dict[InfoWindowItemType.Ask] = new InfoWindowDescriptionItem();
        dict[InfoWindowItemType.Bid] = new InfoWindowDescriptionItem();
        dict[InfoWindowItemType.PortfolioGrowth] = new InfoWindowDescriptionItem();
        dict[InfoWindowItemType.Funding] = new InfoWindowDescriptionItem();
        dict[InfoWindowItemType.Countdown] = new InfoWindowDescriptionItem();

        this.DictItems = dict;
        for (const key in dict) {
            const item = dict[key];
            const locKey = 'chart.infoWindow.Items.' + TerceraChartInfoWindowRenderer.infoWindowItemTypeToString(key);
            item.Allowed = !Resources.isHidden(locKey);
            item.Visible = !Resources.isHidden(locKey + '.VisibilityByDefault');
        }

        // Последний использорванный тип отображения item'ов
        this.lastInfoWindowShowingType = InfoWindowShowingType.IntradayAbsoluteChart;

        // #endregion

        this.Docked = InfoWindowDockingType.Top;

        this.Font = ThemeManager.Fonts.Font_11F25_regular.copy();

        this.Localize();
        this.ThemeChanged();

        this.SetClassName('TerceraChartInfoWindowRenderer');
    }

    public IsNeedForceUpdate (): boolean {
        let result = this.expanded;
        const item = this.DictItems[InfoWindowItemType.Countdown];
        if (item) {
            result = result && item.Visible;
        }

        return result;
    }

    get GetUndockedWidth (): number {
        return Math.max(this.expanderWidth, this.curValueWidthMax + this.curLabelWidthMax) +
        this.rectCloseButton.Width;
    }

    get Expanded (): boolean { return this.expanded; }
    set Expanded (value) { this.expanded = value; }

    get Font (): any { return this.FFont; }
    set Font (value) {
        this.FFont = value;
        this.FFontBold = value.copy();
        this.FFontBold.FontWeight = 'bold';

        const DictItems = this.DictItems;
        const keys = Object.keys(DictItems);
        const len = keys.length;
        for (let i = 0; i < len; i++) {
            DictItems[keys[i]].Font = this.FFont;
        }
        if (DictItems.hasOwnProperty(InfoWindowItemType.Additionalinfo)) {
            DictItems[InfoWindowItemType.Additionalinfo].Font = this.FFontBold;
        }

        this.rowHeight = this.FFont.Height + 2;
        if (this.rowHeight < this.rectCloseButton.Height) {
            this.rowHeight = this.rectCloseButton.Height;
        }
    }

    get BackColor (): any { return this.FBackColor; }
    set BackColor (value) {
        this.FBackColor = value;
        this.FCurrentBrush = new SolidBrush(value);
    }

    get ForeColor (): any { return this.FForeColor; }
    set ForeColor (value) {
        this.FForeColor = value;
        this.FCurrentForeBrush = new SolidBrush(value);
    }

    get Instrument () { return this.instrument; }
    set Instrument (value) {
        this.instrument = value;
        this.UpdateText(-1, null);
    }

    get Account () { return this.account; }
    set Account (value) {
        this.account = value;
        this.UpdateText(-1, null);
    }

    get ShortMode (): boolean { return this.shortMode; }
    set ShortMode (value) {
        this.shortMode = value;
        this.Localize();
    }

    // Рисование окна InfoWindow tool
    public Draw (gr: CanvasRenderingContext2D, window, windowsContainer, advParams): void {
        if (!this.Visible || !window) {
            return;
        }

        const param = advParams;

        const cashItemSeries = param.mainPriceRenderer.Series;
        if (!cashItemSeries) {
            return;
        }

        const LastBounds = this.LastBounds;
        const rectCloseButton = this.rectCloseButton;
        const rectExpandButton = this.rectExpandButton;
        const Docked = this.Docked;
        const IsMouseIn = this.IsMouseIn;
        const FCurrentBrush = this.FCurrentBrush;

        this.lastInfoWindowShowingType = cashItemSeries.CashItem.FPeriod <= 0
            ? InfoWindowShowingType.TicksAbsoluteChart
            : InfoWindowShowingType.IntradayAbsoluteChart;

        if (this.forceInfoWindowShowingType) {
            this.lastInfoWindowShowingType = this.forceInfoWindowShowingType;
        }

        let barIndex = cashItemSeries.Count() - 1; // последний бар если мыша вне
        if (!this.mouseLeave) {
            barIndex = Math.floor(window.PointsConverter.GetDataIndexByX(this.lastMouseMoveLocation.X));
        }

        // update text for items
        this.UpdateText(barIndex, cashItemSeries);
        // update total size for all items, and get items to draw
        const listToDraw = this.UpdateClientRect(gr, window);

        gr.save();
        gr.beginPath();
        gr.rect(LastBounds.X, LastBounds.Y, LastBounds.Width, LastBounds.Height);
        gr.clip();

        gr.FillRect(FCurrentBrush, LastBounds.X, LastBounds.Y, LastBounds.Width, LastBounds.Height);

        let curX = LastBounds.X + TerceraChartInfoWindowRenderer.leftIndent;
        let curY = LastBounds.Y + this.browserOffset;

        // temp rect for drawing item
        const rect = new Rectangle(curX, curY, 0, this.rowHeight);

        const listToDraw_len = listToDraw.length;
        for (let i = 0; i < listToDraw_len; i++) {
            const res = this.DrawItem(gr, listToDraw[i], curX, curY, rect);
            curX = res.curX;
            curY = res.curY;
        }

        // Close button
        rectCloseButton.X = Docked !== InfoWindowDockingType.None
            ? LastBounds.X + LastBounds.Width - rectCloseButton.Width
            : rectExpandButton.X;

        // проверяем, чтобы крестик рисовался и в том случае, когда окно InfoWindow больше по размеру, чем chart
        if (Docked !== InfoWindowDockingType.None && IsMouseIn && curX + rectExpandButton.Width > LastBounds.X + LastBounds.Width) {
            gr.FillRect(FCurrentBrush, rectCloseButton.X, rectCloseButton.Y, rectCloseButton.Width, rectCloseButton.Height);
        }

        rectCloseButton.Y = LastBounds.Y;

        if (IsMouseIn) {
            const closeButtonBmp = this.closeButtonBmp;
            if (closeButtonBmp) {
                gr.drawImage(closeButtonBmp,
                    rectCloseButton.X,
                    rectCloseButton.Y,
                    rectCloseButton.Width,
                    rectCloseButton.Height);
            }
        }
        gr.restore();
    }

    // Рисование отдельного пункта Label + Value
    public DrawItem (gr: CanvasRenderingContext2D, item, curX, curY, rect): { curX: number, curY: number } {
        const chartWindow = this.window;
        const ClientRectangle = chartWindow.ClientRectangle;
        const FCurrentForeBrush = this.FCurrentForeBrush;
        const Docked = this.Docked;
        const curTotalWidth = this.curTotalWidth;
        const curLabelWidthMax = this.curLabelWidthMax;
        const expanded = this.expanded;
        const rectExpandButton = this.rectExpandButton;
        const LastBounds = this.LastBounds;
        const FFont = this.FFont;
        const rowHeight = this.rowHeight;

        const xCached = curX;

        rect.Y = curY;
        rect.X = curX;

        rect.Width = item.CollapserItem && Docked === InfoWindowDockingType.None
            ? curTotalWidth
            : item.LabelWidth;

        const CRR = ClientRectangle.X + ClientRectangle.Width;

        if (rect.X >= CRR) {
            return { curX, curY };
        } else if (rect.X + rect.Width > CRR) {
            rect.Width = CRR - rect.X;
        }

        gr.DrawString(item.Label, item.Font, FCurrentForeBrush, rect.X, rect.Y, undefined, undefined);

        curX += Docked !== InfoWindowDockingType.None ? item.LabelWidth : curLabelWidthMax;

        rect.X = curX;
        rect.Width = item.ValueWidth;

        if (rect.X >= CRR) {
            return { curX, curY };
        } else if (rect.X + rect.Width > CRR) {
            rect.Width = CRR - rect.X;
        }

        if (item.CollapserItem) {
        // Collapse-expand button
            rectExpandButton.X =
            item.CollapserItem && Docked === InfoWindowDockingType.None
                ? LastBounds.X + LastBounds.Width - rectExpandButton.Width
                : curX - 5;

            rectExpandButton.Y = curY - this.browserOffset;

            const img = expanded
                ? this.chartCollapseBmp
                : this.chartExpandBmp;

            if (img) {
                gr.drawImage(img,
                    rectExpandButton.X,
                    rectExpandButton.Y,
                    rectExpandButton.Width,
                    rectExpandButton.Height);
            }
        } else {
            gr.DrawString(
                item.Value || TerceraChartInfoWindowRenderer.emptyString,
                FFont,
                FCurrentForeBrush,
                rect.X,
                rect.Y,
                undefined,
                undefined);
        }

        curX += item.ValueWidth;

        curY += Docked !== InfoWindowDockingType.None ? 0 : rowHeight;
        curX = Docked !== InfoWindowDockingType.None ? curX : xCached;

        return { curX, curY };
    }

    // items text
    public UpdateText (barIndex: number, cashItemSeries): void {
        const emptyBar = barIndex < 0 || barIndex >= cashItemSeries.Count();
        if (emptyBar) barIndex = 0;

        const DictItems = this.DictItems;
        const DictItems_keys = Object.keys(DictItems);
        const DictItems_len = DictItems_keys.length;

        const lastInfoWindowShowingType = this.lastInfoWindowShowingType;
        const DictItemsShowingType = TerceraChartInfoWindowRenderer.DictItemsShowingType;

        const emptyString = TerceraChartInfoWindowRenderer.emptyString;

        const expanded = this.expanded;

        const instrument = this.instrument;

        if (!instrument || !cashItemSeries) {
        // force reset text for other instrument
            for (let i = 0; i < DictItems_len; i++) {
                const it = DictItems[DictItems_keys[i]];
                it.Value = emptyString;
                it.SetValueWidth(0, true);
            }
            return;
        }

        const period = cashItemSeries.CashItem.FPeriod;

        const dataType = cashItemSeries.settings.DataType;

        const lastShowingTypeArr = DictItemsShowingType[lastInfoWindowShowingType];
        const lastShowingTypeArr_len = lastShowingTypeArr.length;

        for (let i = 0; i < lastShowingTypeArr_len; i++) {
            const type = lastShowingTypeArr[i];

            if (type === InfoWindowItemType.Additionalinfo && !expanded) {
                break;
            } // остальне не видно

            if (!DictItems[type].Visible) {
                continue;
            } // пропускаем

            if (emptyBar && type !== InfoWindowItemType.Funding && type !== InfoWindowItemType.Countdown) {
                DictItems[type].Value = emptyString;
                continue;
            }

            let formatValue = '';
            let value = 0;

            switch (type) {
            case InfoWindowItemType.None:
                break;
            case InfoWindowItemType.Bars:{
                const barFromLast = cashItemSeries.Count() - 1 - barIndex;
                DictItems[type].Value = barFromLast.toString();
                break;
            }
            // FIX.
            case InfoWindowItemType.Date:{
                let dayTicks;
                const dayFromBar = cashItemSeries.GetValue(barIndex, CashItem.TIME_INDEX);
                if (this.IsBigPeriod(period)) {
                    dayTicks = Periods.ConvertToDisplayedTimeForDayAggregations(new Date(dayFromBar), instrument, period);
                } else {
                    dayTicks = DateTimeConvertor.getLocalTimeQuick(dayFromBar);
                }

                DictItems[type].Value = DateTimeUtils.formatDate(dayTicks, 'DD.MM.YYYY');
                break;
            }
            // FIX.
            case InfoWindowItemType.Time:
                if (this.IsBigPeriod(period)) {
                    DictItems[type].Value = '---';
                } else {
                    const ticks = DateTimeConvertor.getLocalTimeQuick(cashItemSeries.GetValue(barIndex, CashItem.TIME_INDEX));
                    DictItems[type].Value = DateTimeUtils.formatDate(ticks, 'HH:mm:ss');
                }

                break;

            case InfoWindowItemType.Open:
            case InfoWindowItemType.Close:
            case InfoWindowItemType.High:
            case InfoWindowItemType.Low:
            case InfoWindowItemType.HighLow: {
            // Get value
                switch (type) {
                case InfoWindowItemType.Open:
                    value = cashItemSeries.GetValue(barIndex, CashItem.OPEN_INDEX);
                    break;
                case InfoWindowItemType.Close:
                    value = cashItemSeries.GetValue(barIndex, CashItem.CLOSE_INDEX);
                    break;
                case InfoWindowItemType.High:
                    value = cashItemSeries.GetValue(barIndex, CashItem.HIGH_INDEX);
                    break;
                case InfoWindowItemType.Low:
                    value = cashItemSeries.GetValue(barIndex, CashItem.LOW_INDEX);
                    break;
                case InfoWindowItemType.HighLow:
                    value = cashItemSeries.GetValue(barIndex, CashItem.HIGH_INDEX) - cashItemSeries.GetValue(barIndex, CashItem.LOW_INDEX);
                    break;
                }
                // Specific format depends on DataType
                switch (dataType) {
                case TerceraChartCashItemSeriesDataType.Absolute:
                    formatValue = instrument.formatPrice(value);
                    break;
                case TerceraChartCashItemSeriesDataType.Relative:
                // FIX.
                    formatValue = /* value.toString("N2") */ value.toFixed(2) + '%';
                    break;
                case TerceraChartCashItemSeriesDataType.Log:
                    formatValue = instrument.formatPrice(instrument.roundPrice(cashItemSeries.settings.logDataConverter.Revert(value)));
                    break;
                }

                DictItems[type].Value = formatValue;
                break;
            }

            case InfoWindowItemType.Change: {
                let open = cashItemSeries.GetValue(barIndex, CashItem.OPEN_INDEX);
                let close = cashItemSeries.GetValue(barIndex, CashItem.CLOSE_INDEX);

                if (dataType === TerceraChartCashItemSeriesDataType.Log) {
                    open = cashItemSeries.settings.logDataConverter.Revert(open);
                    close = cashItemSeries.settings.logDataConverter.Revert(close);
                }

                const change = /* Math.round( */ ((close - open) / Math.abs(open)) * 100;
                let changeStr;
                if (open === 0) {
                    changeStr = emptyString;
                } else {
                // http://tp.pfsoft.net/entity/55604
                    if (change === 0) {
                        changeStr = (0).toFixed(2);
                    } else {
                        changeStr = change.toFixed(2);
                    }
                }
                DictItems[type].Value = changeStr;
                break;
            }

            case InfoWindowItemType.Volume:
            case InfoWindowItemType.Ticks: {
                const obj = cashItemSeries.CashItem.GetVolumeAndTicks(cashItemSeries.GetIndex(barIndex));
                const volume = obj.volume;
                const ticks = obj.ticks;
                if (type === InfoWindowItemType.Volume) {
                    DictItems[type].Value = volume === null ? '--' : volume.toString();
                } else if (type === InfoWindowItemType.Ticks) {
                    DictItems[type].Value = ticks === null ? '--' : ticks.toString();
                }

                break;
            }

            case InfoWindowItemType.Ask:
                DictItems[type].Value = instrument.formatPrice(cashItemSeries.GetValue(barIndex, 1));
                break;

            case InfoWindowItemType.Bid:
                DictItems[type].Value = instrument.formatPrice(cashItemSeries.GetValue(barIndex, 2));
                break;

            case InfoWindowItemType.PortfolioGrowth:
                DictItems[type].Value = this.Account.formatPrice(cashItemSeries.GetValue(barIndex, CashItem.CLOSE_INDEX));
                break;

            case InfoWindowItemType.Funding:
                DictItems[type].Value = instrument.Level1.GetSpreadedFundingRateValueFormatted(this.Account, '---');
                break;
            case InfoWindowItemType.Countdown:
                DictItems[type].Value = instrument.Level1.StrNextFundingSettlement('---');
                break;
            }
        }
    }

    public IsBigPeriod (period): boolean {
        return period !== 0 && (period % Periods.YEAR === 0 || period % Periods.DAY === 0);
    }

    // total width, height
    public UpdateClientRect (gr: CanvasRenderingContext2D, window): InfoWindowDescriptionItem[] {
        const leftIndent = TerceraChartInfoWindowRenderer.leftIndent;
        const rightIndent = TerceraChartInfoWindowRenderer.rightIndent;

        // добавляем в список на рисование и обмериваем
        const listToDraw = [];

        this.curLabelWidthMax = this.curValueWidthMax = 10;
        this.curTotalWidth = 0;

        const lastShowingTypeArr = TerceraChartInfoWindowRenderer.DictItemsShowingType[this.lastInfoWindowShowingType];
        const lastShowingTypeArr_len = lastShowingTypeArr.length;
        for (let i = 0; i < lastShowingTypeArr_len; i++) {
            const it = this.DictItems[lastShowingTypeArr[i]];
            if (!it.Visible || !it.Allowed) continue;

            listToDraw.push(it);
            const labWidth = it.SetLabelWidth(gr.GetTextWidth(it.Label, it.Font) + leftIndent);

            let valWidth = this.rectExpandButton.Width;
            if (!it.CollapserItem) {
                valWidth = gr.GetTextWidth(it.Value, it.Font) + leftIndent + rightIndent;
            }

            valWidth = it.SetValueWidth(valWidth);

            // все айтемы кроме коллапсера влияют на общую ширину
            if (!it.CollapserItem || this.Docked !== InfoWindowDockingType.None) {
                this.curLabelWidthMax = Math.max(this.curLabelWidthMax, labWidth);
                this.curValueWidthMax = Math.max(this.curValueWidthMax, valWidth);
            } else {
                this.expanderWidth = labWidth;
            }

            if (this.Docked !== InfoWindowDockingType.None) {
                this.curTotalWidth += labWidth + valWidth;
            }

            // это был последний айтем! остальные сколлапсены
            if (!this.expanded && it.CollapserItem) {
                break;
            }
        }

        const CR = window.ClientRectangle;
        const CRX = CR.X;
        const CRW = CR.Width;
        const CRH = CR.Height;
        const CRB = CR.Y + CRH;

        const LBR = this.LastBounds;

        if (this.Docked !== InfoWindowDockingType.None) {
            this.curTotalWidth += this.rectCloseButton.Width;

            LBR.Height = this.rowHeight;
            LBR.Width = Math.min(CRW, this.curTotalWidth);

            if (this.Docked === InfoWindowDockingType.Top) {
                LBR.Location = CR.Location;
            } else {
                LBR.X = CRX;
                LBR.Y = CRB - this.rowHeight;
            }
        } else {
            this.curTotalWidth = this.GetUndockedWidth;

            LBR.Height = this.rowHeight * listToDraw.length;
            LBR.Width = this.curTotalWidth;

            // less bottom
            if (LBR.Y + LBR.Height > CRB) {
                LBR.X = CRX;
                if (CRH >= LBR.Height) {
                    LBR.Y = CRB - LBR.Height;
                } else {
                    this.Docked = InfoWindowDockingType.Top;
                }
            }

            if (LBR.X < CRX) {
                LBR.X = CRX;
            }
        }

        this.lastDrawCount = listToDraw.length;
        return listToDraw;
    }

    // #region Process mouse events

    public override ProcessMouseDown (e): boolean {
        if (!this.Visible || e.Button !== MouseButtons.Left) {
            return super.ProcessMouseDown(e);
        }

        this.mouseState = TerceraChartPriceScaleRendererMouseState.None;

        if (this.IsMouseIn &&
        this.LastBounds.Contains(e.Location.X, e.Location.Y) &&
        !this.rectCloseButton.Contains(e.Location.X, e.Location.Y)) {
            this.lastMouseMoveLocation = e.Location;
            this.mouseState = TerceraChartPriceScaleRendererMouseState.Moving;
            return true;
        }

        return super.ProcessMouseDown(e);
    }

    public override ProcessMouseMove (e): boolean {
        if (!this.Visible || isNullOrUndefined(this.window)) {
            return false;
        }

        const eLocation = e.Location;
        const eLocationX = eLocation.X;
        const eLocationY = eLocation.Y;

        const CR = this.window.ClientRectangle;
        const CRX = CR.X;
        const CRY = CR.Y;
        const CRW = CR.Width;
        const CRH = CR.Height;
        const CRR = CRX + CRW;
        const CRB = CRY + CRH;

        const LBR = this.LastBounds;

        let res = false;
        if (this.mouseState === TerceraChartPriceScaleRendererMouseState.Moving) {
            const newX = this.lastMouseMoveLocation.X;
            const xDeltaPx = eLocationX - newX;
            LBR.X += xDeltaPx;

            const newY = this.lastMouseMoveLocation.Y;
            const yDeltaPy = eLocationY - newY;
            LBR.Y += yDeltaPy;

            const unDockedWidth = this.GetUndockedWidth;
            const unDockedHeight = this.lastDrawCount * this.rowHeight;
            //
            // Y
            //
            // special case - not enought heigth to undock
            if (yDeltaPy > 0 && this.Docked === InfoWindowDockingType.Top && unDockedHeight >= CRH) {
                this.Docked = InfoWindowDockingType.Bottom;
            } else if (yDeltaPy < 0 && this.Docked === InfoWindowDockingType.Bottom && unDockedHeight >= CRH) {
                this.Docked = InfoWindowDockingType.Top;
            } // more top
            else if (eLocationY < CRY || LBR.Y < CRY) {
                this.Docked = InfoWindowDockingType.Top;
                LBR.Location = CR.Location;
            } // less bottom
            else if (
                (yDeltaPy > 0 || eLocationY > CRB - this.rowHeight) &&
            this.Docked === InfoWindowDockingType.Bottom ||
            LBR.Y + LBR.Height > CRB) {
                this.Docked = InfoWindowDockingType.Bottom;
                LBR.X = CRX;
                LBR.Y = CRB - this.rowHeight;
            } // на андок и на вход обратно в пределы нычанаем тягать за левый угол
            else if (
                (this.Docked === InfoWindowDockingType.Top && LBR.Y > CRY) ||
            (!this.IsMouseIn && CR.Contains(eLocationX, eLocationY) && this.mouseLeave)) {
                this.Docked = InfoWindowDockingType.None;
                LBR.Location = this.lastMouseMoveLocation = eLocation;
            } // на андок и на вход обратно в пределы нычанаем тягать за левый нижний угол
            else if (this.Docked === InfoWindowDockingType.Bottom && yDeltaPy < 0) {
                this.Docked = InfoWindowDockingType.None;
                this.lastMouseMoveLocation = eLocation;

                LBR.Location = new Point(
                    this.lastMouseMoveLocation.X,
                    this.lastMouseMoveLocation.Y - unDockedHeight);
            }
            //
            // X
            //
            else if (this.Docked !== InfoWindowDockingType.None) {
                LBR.X = CRX;
            }
            // out left edge
            else if (eLocationX < CRX || LBR.X < CRX) {
                LBR.X = CRX;
            }
            // out right edge
            else if (eLocationX > CRR - unDockedWidth) {
                LBR.X = CRR - unDockedWidth;
            }

            res = true;
        }

        this.lastMouseMoveLocation = eLocation;
        //
        // при тягании покунули пределы экрана
        //
        if (!CR.Contains(eLocationX, eLocationY) &&
        this.mouseState === TerceraChartPriceScaleRendererMouseState.Moving) {
            this.ProcessMouseLeave(e);
        } else {
            this.mouseLeave = false;
            this.IsMouseIn = LBR.Contains(eLocationX, eLocationY);
        }

        if (this.IsMouseIn) {
            e.NeedRedraw = LayersEnum.CrossHair;
        }

        if (!res) {
            return super.ProcessMouseMove(e);
        } else {
            return true;
        }
    }

    public override ProcessMouseUp (e): boolean {
        if (!this.Visible || e.Button !== MouseButtons.Left)
        // TODO. Mouse down?
        {
            return super.ProcessMouseDown(e);
        }

        this.mouseState = TerceraChartPriceScaleRendererMouseState.None;

        if (this.rectExpandButton.Contains(e.Location.X, e.Location.Y)) {
            this.Expanded = !this.Expanded;
        } else if (this.rectCloseButton.Contains(e.Location.X, e.Location.Y)) {
            this.Visible = false;
        } else
        // TODO. Mouse down?
        {
            return super.ProcessMouseDown(e);
        }

        return true;
    }

    public override ProcessMouseLeave (e): void {
        this.mouseLeave = true;
        this.IsMouseIn = false;
    }

    // #endregion

    // #region overriden

    public Localize (): void {
        const DictItems = this.DictItems;

        DictItems[InfoWindowItemType.Additionalinfo].Label = Resources.getResource('chart.infoWindow.Items.Additionalinfo');
        DictItems[InfoWindowItemType.Bars].Label = Resources.getResource('chart.infoWindow.Items.Bars');
        DictItems[InfoWindowItemType.Date].Label = Resources.getResource('chart.infoWindow.Items.Date');
        DictItems[InfoWindowItemType.Time].Label = Resources.getResource('chart.infoWindow.Items.Time');
        DictItems[InfoWindowItemType.Open].Label = Resources.getResource('chart.infoWindow.Items.Open');
        DictItems[InfoWindowItemType.High].Label = Resources.getResource('chart.infoWindow.Items.High');
        DictItems[InfoWindowItemType.Low].Label = Resources.getResource('chart.infoWindow.Items.Low');
        DictItems[InfoWindowItemType.Close].Label = Resources.getResource('chart.infoWindow.Items.Close');
        DictItems[InfoWindowItemType.Change].Label = Resources.getResource('chart.infoWindow.Items.Change');
        DictItems[InfoWindowItemType.Ticks].Label = Resources.getResource('chart.infoWindow.Items.Ticks');
        DictItems[InfoWindowItemType.Volume].Label = Resources.getResource('chart.infoWindow.Items.Volume');
        DictItems[InfoWindowItemType.Bid].Label = Resources.getResource('chart.infoWindow.Items.Bid');
        DictItems[InfoWindowItemType.Ask].Label = Resources.getResource('chart.infoWindow.Items.Ask');
        DictItems[InfoWindowItemType.PortfolioGrowth].Label = Resources.getResource('chart.infoWindow.Items.PortfolioGrowth');
        DictItems[InfoWindowItemType.Funding].Label = Resources.getResource('chart.infoWindow.Items.Funding');
        DictItems[InfoWindowItemType.Countdown].Label = Resources.getResource('chart.infoWindow.Items.Countdown');

        DictItems[InfoWindowItemType.Ticks].Visible = !Resources.isHidden(DictItems[InfoWindowItemType.Ticks].Label);
        DictItems[InfoWindowItemType.Volume].Visible = !Resources.isHidden(DictItems[InfoWindowItemType.Volume].Label);

        const highLabel = DictItems[InfoWindowItemType.High].Label; // дабы не добавлять переводы во все языки для HighLow совмещаем локализации High и Low
        const highPart = highLabel.substr(0, highLabel.length - 1); // отрезаем двоеточие в конце ключа High
        DictItems[InfoWindowItemType.HighLow].Label = highPart + ' - ' + DictItems[InfoWindowItemType.Low].Label;

        if (this.shortMode) {
            const keys = Object.keys(DictItems);
            const len = keys.length;
            for (let i = 0; i < len; i++) {
                const item = DictItems[keys[i]];
                item.Label = item.Label.charAt(0) + ':';
            }

            DictItems[InfoWindowItemType.HighLow].Label = DictItems[InfoWindowItemType.High].Label.charAt(0) + '-' + DictItems[InfoWindowItemType.Low].Label.charAt(0) + ':';
        }
    }

    public override ThemeChanged (): void {
    // if (Utils.ResetLayouts) {

        this.BackColor = ThemeManager.CurrentTheme.Chart_InfoWindowBackColor;
        this.ForeColor = ThemeManager.CurrentTheme.Chart_InfoWindowForeColor;

        // }

        // TODO. To static if necessary.
        this.closeButtonBmp = ThemeManager.CurrentTheme.infoWindowIconCloseImage;
        this.chartCollapseBmp = ThemeManager.CurrentTheme.infoWindowIconMinimizeDefaultImage;
        this.chartExpandBmp = ThemeManager.CurrentTheme.infoWindowIconMaximizeDefaultImage;
    }

    // Если рендерер переопределяет курсор то надо вернуть иначе нулл
    public override GetCursor (e): Cursors {
    // FIX.
        if (this.IsMouseIn && this.Visible) {
            return Cursors.Hand;
        }

        return null;
    }

    public override GetContextMenu (e, chart): any {
        if (!this.Visible || !this.IsMouseIn) {
            return super.GetContextMenu(e, chart);
        }

        const menuItems = [
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.InfoWindowHide, this) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.InfoWindowDock, this) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.InfoWindowShortMode, this) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.InfoWindowSettings, this) }
        ];

        // Create items using common functions
        return chart.TerceraChartActionProcessor.CreateMenu(menuItems);
    }

    // #endregion

    // #region ICaller

    public callBack (properties: DynProperty[]): void {
        let p = DynProperty.getPropertyByName(properties, 'infoWindow_fore_back_color');
        if (p) {
            const pairColor = p.value;

            this.ForeColor = pairColor.Color1;
            this.BackColor = pairColor.Color2;
        }

        p = DynProperty.getPropertyByName(properties, 'infowindow_visible');
        if (!isNullOrUndefined(p)) this.Visible = p.value;

        p = DynProperty.getPropertyByName(properties, 'infowindow_Docked');
        if (!isNullOrUndefined(p)) this.Docked = p.value;

        let temp = DynProperty.getPropertyByName(properties, 'infowindow_fx');
        if (!isNullOrUndefined(temp)) this.LastBounds.X = temp.value;

        temp = DynProperty.getPropertyByName(properties, 'infowindow_fy');
        if (!isNullOrUndefined(temp)) this.LastBounds.Y = temp.value;

        // Шрифт
        temp = DynProperty.getPropertyByName(properties, 'infowindow_font');
        if (!isNullOrUndefined(temp)) this.Font = temp.value;

        // Visibility
        temp = DynProperty.getPropertyByName(properties, 'IsBarsVisible');
        if (!isNullOrUndefined(temp)) this.DictItems[InfoWindowItemType.Bars].Visible = temp.value;

        temp = DynProperty.getPropertyByName(properties, 'IsDateVisible');
        if (!isNullOrUndefined(temp)) this.DictItems[InfoWindowItemType.Date].Visible = temp.value;

        temp = DynProperty.getPropertyByName(properties, 'IsTimeVisible');
        if (!isNullOrUndefined(temp)) this.DictItems[InfoWindowItemType.Time].Visible = temp.value;

        temp = DynProperty.getPropertyByName(properties, 'IsOpenVisible');
        if (!isNullOrUndefined(temp)) this.DictItems[InfoWindowItemType.Open].Visible = temp.value;

        temp = DynProperty.getPropertyByName(properties, 'IsCloseVisible');
        if (!isNullOrUndefined(temp)) this.DictItems[InfoWindowItemType.Close].Visible = temp.value;

        temp = DynProperty.getPropertyByName(properties, 'IsHighVisible');
        if (!isNullOrUndefined(temp)) this.DictItems[InfoWindowItemType.High].Visible = temp.value;

        temp = DynProperty.getPropertyByName(properties, 'IsLowVisible');
        if (!isNullOrUndefined(temp)) this.DictItems[InfoWindowItemType.Low].Visible = temp.value;

        temp = DynProperty.getPropertyByName(properties, 'IsHighLowVisible');
        if (!isNullOrUndefined(temp)) this.DictItems[InfoWindowItemType.HighLow].Visible = temp.value;

        temp = DynProperty.getPropertyByName(properties, 'IsChangeVisible');
        if (!isNullOrUndefined(temp)) this.DictItems[InfoWindowItemType.Change].Visible = temp.value;

        temp = DynProperty.getPropertyByName(properties, 'IsTicksVisible');
        if (!isNullOrUndefined(temp)) this.DictItems[InfoWindowItemType.Ticks].Visible = temp.value;

        temp = DynProperty.getPropertyByName(properties, 'IsVolumeVisible');
        if (!isNullOrUndefined(temp)) this.DictItems[InfoWindowItemType.Volume].Visible = temp.value;

        temp = DynProperty.getPropertyByName(properties, 'IsCountdownVisible');
        if (!isNullOrUndefined(temp)) this.DictItems[InfoWindowItemType.Countdown].Visible = temp.value;

        temp = DynProperty.getPropertyByName(properties, 'IsFundingVisible');
        if (!isNullOrUndefined(temp)) this.DictItems[InfoWindowItemType.Funding].Visible = temp.value;

        temp = DynProperty.getPropertyByName(properties, 'IsPortfolioGrowthVisible');
        if (!isNullOrUndefined(temp)) this.DictItems[InfoWindowItemType.PortfolioGrowth].Visible = temp.value;

        temp = DynProperty.getPropertyByName(properties, 'infowindow_expanded');
        if (!isNullOrUndefined(temp)) this.Expanded = temp.value;

        // force reset text for other instrument
        const DictItems = this.DictItems;
        const keys = Object.keys(this.DictItems);
        const len = keys.length;
        for (let i = 0; i < len; i++) {
            const it = DictItems[keys[i]];
            it.Value = TerceraChartInfoWindowRenderer.emptyString;
            it.SetValueWidth(0, true);
        }
    }

    public Properties (): DynProperty[] {
        const Visible = this.Visible;
        const ForeColor = this.ForeColor;
        const BackColor = this.BackColor;
        const Text1 = 'Text';
        const Text2 = 'Back';

        let properties: DynProperty[] = [];
        let prop = null;

        // #region hidden

        properties.push(new DynProperty('oldColor', this.ForeColor, DynProperty.COLOR, DynProperty.HIDDEN_GROUP));
        properties.push(new DynProperty('infowindow_Docked', this.Docked, DynProperty.INTEGER, DynProperty.HIDDEN_GROUP));
        properties.push(new DynProperty('infowindow_fx', this.LastBounds.X, DynProperty.INTEGER, DynProperty.HIDDEN_GROUP));
        properties.push(new DynProperty('infowindow_fy', this.LastBounds.Y, DynProperty.INTEGER, DynProperty.HIDDEN_GROUP));
        properties.push(new DynProperty('infowindow_expanded', this.Expanded, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));

        // #endregion

        // visibility
        let SeparatorGroup = '#0#' + Resources.getResource('property.VisibilitySeparatorGroup');
        prop = new DynProperty('infowindow_visible', this.Visible, DynProperty.BOOLEAN, DynProperty.DATA_BOX_GROUP);
        prop.sortIndex = 0;
        prop.separatorGroup = SeparatorGroup;
        prop.assignedProperty = [
            'IsBarsVisible',
            'IsDateVisible',
            'IsTimeVisible',
            'IsOpenVisible',
            'IsCloseVisible',
            'IsHighVisible',
            'IsLowVisible',
            'IsHighLowVisible',
            'IsChangeVisible',
            'IsTicksVisible',
            'IsVolumeVisible',
            'IsPortfolioGrowthVisible',
            'IsCountdownVisible',
            'IsFundingVisible',

            'infowindow_font',
            'infoWindow_fore_back_color'
        ];
        properties.push(prop);

        // View
        properties = properties.concat(this.getViewProperties(
            DynProperty.DATA_BOX_GROUP,
            '#1#' + Resources.getResource('property.ViewSeparatorGroup')));

        // Colors
        SeparatorGroup = '#2#' + Resources.getResource('property.ColorsSeparatorGroup');

        /* if (FFont)
    {
        // Шрифт
        prop = new DynProperty("infowindow_font", FFont.copy(), DynProperty.FONT, DynProperty.DATA_BOX_GROUP);
        prop.sortIndex = 0;
        prop.separatorGroup = SeparatorGroup;
        prop.enabled = Visible;
        properties.push(prop);
    } */

        prop = new DynProperty('infoWindow_fore_back_color', new PairColor(ForeColor, BackColor, Text1, Text2), DynProperty.PAIR_COLOR, DynProperty.DATA_BOX_GROUP);
        prop.sortIndex = 1;
        prop.separatorGroup = SeparatorGroup;
        prop.enabled = Visible;
        properties.push(prop);

        return properties;
    }

    public getViewProperties (group, separatorGroup): DynProperty[] {
        const properties: DynProperty[] = [];
        const dictItems = this.DictItems;
        let sortIndex = 1;

        for (const key in dictItems) {
            const item = dictItems[key];
            if (!item.Allowed ||
            key === InfoWindowItemType.Ask.toString() ||
            key === InfoWindowItemType.Bid.toString() ||
            key === InfoWindowItemType.Additionalinfo.toString()) {
                continue;
            }

            const prop = new DynProperty(
                'Is' +
            TerceraChartInfoWindowRenderer.infoWindowItemTypeToString(key) +
            'Visible',
                dictItems[key].Visible,
                DynProperty.BOOLEAN,
                group);

            prop.sortIndex = sortIndex++;
            prop.separatorGroup = separatorGroup;
            prop.enabled = this.Visible;
            properties.push(prop);
        }

        return properties;
    }

    // #endregion

    public static leftIndent = 6;
    public static rightIndent = 5;

    public static emptyString = '---';

    public static infoWindowItemTypeToString (type): string {
        type = parseInt(type);
        if (isNaN(type)) return '';

        switch (type) {
        case InfoWindowItemType.Additionalinfo: return 'Additionalinfo';
        case InfoWindowItemType.Bars: return 'Bars';
        case InfoWindowItemType.Date: return 'Date';
        case InfoWindowItemType.Time: return 'Time';
        case InfoWindowItemType.Open: return 'Open';
        case InfoWindowItemType.High: return 'High';
        case InfoWindowItemType.Low: return 'Low';
        case InfoWindowItemType.HighLow: return 'HighLow';
        case InfoWindowItemType.Close: return 'Close';
        case InfoWindowItemType.Change: return 'Change';
        case InfoWindowItemType.Ticks: return 'Ticks';
        case InfoWindowItemType.Volume: return 'Volume';
        case InfoWindowItemType.Bid: return 'Bid';
        case InfoWindowItemType.Ask: return 'Ask';
        case InfoWindowItemType.PortfolioGrowth: return 'PortfolioGrowth';
        case InfoWindowItemType.Funding: return 'Funding';
        case InfoWindowItemType.Countdown: return 'Countdown';
        }

        return '';
    }

    // --------------------------

    // Возможные комбинация отображения групп item'ов
    public static DictItemsShowingType = {
        [InfoWindowShowingType.IntradayAbsoluteChart]: [
            InfoWindowItemType.Open,
            InfoWindowItemType.High,
            InfoWindowItemType.Low,
            InfoWindowItemType.Close,
            InfoWindowItemType.HighLow,
            InfoWindowItemType.Additionalinfo,
            InfoWindowItemType.Bars,
            InfoWindowItemType.Date,
            InfoWindowItemType.Time,
            InfoWindowItemType.Change,
            InfoWindowItemType.Ticks,
            InfoWindowItemType.Volume,
            InfoWindowItemType.Funding,
            InfoWindowItemType.Countdown
        ],
        [InfoWindowShowingType.TicksAbsoluteChart]: [
            InfoWindowItemType.Ask,
            InfoWindowItemType.Bid,
            InfoWindowItemType.Additionalinfo,
            InfoWindowItemType.Bars,
            InfoWindowItemType.Date,
            InfoWindowItemType.Time
        ],
        [InfoWindowShowingType.PortfolioGrowth]: [
            InfoWindowItemType.Date,
            InfoWindowItemType.PortfolioGrowth
        ],
        [InfoWindowShowingType.None]: []
    };
}
