// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { Color } from '../../../Graphics';
import { MAMode } from '../IndicatorConstants';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { type iBuildInIndicator } from '../iBuildInIndicator';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';
import { InputParameterDouble } from '../InputParamaterClasses/InputParameterDouble';
import { InputParameterCombobox } from '../InputParamaterClasses/InputParameterCombobox';

export class BBF extends IndicatorScriptBase {
    constructor () {
        super();
        this.ProjectName = 'Bollinger Bands Flat';
        this.Comments = 'Provides the same data as BB, but drawn in separate field and easier to recognize whether price is in our out of band';
        super.SetIndicatorLine('Line 1', Color.Aqua, 1, LineStyle.SimpleChart);
        super.SetIndicatorLine('Line 2', Color.Red, 1, LineStyle.SimpleChart);
        super.SetIndicatorLine('Line 3', Color.Red, 1, LineStyle.SimpleChart);
        super.SetIndicatorLine('Line 4', Color.Blue, 1, LineStyle.SimpleChart);
        this.SeparateWindow = true;

        this.BBPeriod = 9;
        super.InputParameter(new InputParameterInteger('BBPeriod', 'BBF Period', 0, 1, 9999));

        this.Shift = 0;
        super.InputParameter(new InputParameterInteger('Shift', 'BBF Bar Shift', 1, -2147483647, 2147483647, 1));

        this.MAType = MAMode.SMA;
        super.InputParameter(new InputParameterCombobox('MAType', 'Type of Moving Average', 2,
            [
                { Simple: MAMode.SMA },
                { Exponential: MAMode.EMA },
                { Modified: MAMode.SMMA },
                { 'Linear Weighted': MAMode.LWMA }
            ]));

        this.SourcePrices = PriceType.Close;
        super.InputParameter(new InputParameterCombobox('SourcePrices', 'Sources prices for MA', 3,
            [
                { Close: PriceType.Close },
                { Open: PriceType.Open },
                { High: PriceType.High },
                { Low: PriceType.Low },
                { Typical: PriceType.Typical },
                { Medium: PriceType.Medium },
                { Weighted: PriceType.Weighted }
            ]));

        this.Deviation = 1.5;
        super.InputParameter(new InputParameterDouble('Deviation', 'Deviation', 4, -2147483647, 2147483647, 1, 0.1));
    }

    public Deviation: number;

    public Shift: number;

    public BBPeriod: number;

    public MAType: number;

    public SourcePrices: number;

    private ma: iBuildInIndicator;

    private stdDev: iBuildInIndicator;

    public Init (): void {
        super.Init();
        super.IndicatorShortName(this.GetIndicatorShortName());
    }

    public GetIndicatorShortName (): string {
        return 'BBF(' + this.BBPeriod + ';' + this.Deviation + ')';
    }

    public override createInternalIndicators (): iBuildInIndicator[] {
        this.ma = this.Indicators.iMA(this.CurrentData, this.BBPeriod, this.MAType, 0, this.SourcePrices) as iBuildInIndicator;
        this.stdDev = this.Indicators.iStdDev(this.CurrentData, this.BBPeriod, this.MAType, this.Shift, this.SourcePrices) as iBuildInIndicator;
        super.IndicatorShortName(this.GetIndicatorShortName());
        return [this.ma, this.stdDev];
    }

    public OnQuote (): void {
        super.OnQuote();
        const std = this.Deviation * this.stdDev.GetValue();
        const CurrentData = this.CurrentData;
        super.SetValue(0, 0);
        super.SetValue(1, std);
        super.SetValue(2, -std);
        super.SetValue(3, CurrentData.GetPrice(PriceType.Close) - this.ma.GetValue());
    }
}
