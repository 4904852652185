// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DateTimeUtils } from './DateTimeUtils';
import { TimeZoneInfo } from './TimeZoneInfo';
import { TimeZoneInfoSet } from './TimeZoneInfoSet.js';

class _DateTimeConvertor {
    public currentTimeZoneInfoId: { value: string };

    constructor () {
        this.currentTimeZoneInfoId = { value: TimeZoneInfo.LOCAL };
    }

    // TODO. More of a hack than a local time.
    public ConvertUTCTimeToSelectedTimeZone (date): Date {
        if (!date) {
            return null;
        }

        return new Date(date.getTime());

        // hsa: закомментировал код, потому что неверно учитывается офсет
        // при конвертации времени в прошлом

        // let currentTimeZoneId = this.currentTimeZoneInfoId.value;
        // let tzInfo = TimeZoneInfoSet[currentTimeZoneId];
        // if (!tzInfo) return date;

        // let millisPerMin = 60 * 1000;
        // let dateUTC0DifferenceInMillis = date.getTimezoneOffset() * millisPerMin;

    // return new Date(
    //     date.getTime() + dateUTC0DifferenceInMillis +
    //     tzInfo.baseUtcOffset.totalMinutes() * millisPerMin);
    }

    public getLocalTimeQuick (utcMillis): Date {
        const localDate = this.ConvertUTCTimeToSelectedTimeZone(new Date(utcMillis));

        return localDate;
    }

    public getTimeStringInCurrentOffset (date, time): string // (Date(obj), 'HH:mm')(UTC) -> 'DD.MM.YYYY HH:mm' (UTC+[currentTimeZoneOffset])
    {
        const currentTZInfoId = this.currentTimeZoneInfoId.value;

        const tzInfo = TimeZoneInfoSet[currentTZInfoId];
        if (!tzInfo) return;

        const timeA = time.split(':');
        const userUtcOffset = tzInfo.baseUtcOffset;

        date.setHours(timeA[0]);
        date.setMinutes(parseInt(timeA[1]) + userUtcOffset.totalMinutes());

        return DateTimeUtils.formatDate(date, 'DD.MM.YYYY HH:mm');
    }

    public getClientTimeZoneOffset (): string // CurrentTimeZone to hour offset. Example: currentClientTimeZone UTC+02:00 -> result "2" UTC-02:00 -> "-2" TODO minutes
    {
        const currentTZInfoId = this.currentTimeZoneInfoId.value;

        const tzInfo = TimeZoneInfoSet[currentTZInfoId];
        if (!tzInfo) return;

        const userUtcOffset = tzInfo.baseUtcOffset;
        if (!userUtcOffset) return;

        const result = (userUtcOffset.positive ? '' : '-') + userUtcOffset.hours; // + todo minutes #89751

        return result;
    }
}

export const DateTimeConvertor = new _DateTimeConvertor();
