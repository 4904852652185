// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectInstrumentPricesMessage } from '../../../../Utils/DirectMessages/DirectInstrumentPricesMessage';
import { DateTimeUtils } from '../../../../Utils/Time/DateTimeUtils';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class InstrumentPricesMessageProcessor extends MessageProcessorBase {
    public decoder: any;

    constructor (parentDecoder) {
        super();

        this.decoder = parentDecoder;
    }

    public override decode (fieldSet: PFixFieldSet): DirectInstrumentPricesMessage[] {
        const msg = new DirectInstrumentPricesMessage();
        const fArr = fieldSet.fieldSet;
        const len = fieldSet.Count();
        for (let i = 0; i < len; i++) {
            this.processField(msg, fArr[i]);
        }
        return [msg];
    }

    private processField (msg: DirectInstrumentPricesMessage, field): void {
        if (field.FieldId === 0) {
            return;
        }

        if (field.FieldId === FieldsFactory.FIELD_ROUTE_ID) {
            msg.Route = field.Value;
        } else if (field.FieldId === FieldsFactory.FIELD_INDICATIVE_AUCTION_PRICE) {
            msg.IndicativeAuctionPrice = field.Value;
        } else if (field.FieldId === FieldsFactory.FIELD_INDICATIVE_AUCTION_VOLUME) {
            msg.IndicativeAuctionVolume = field.Value;
        } else if (field.FieldId === FieldsFactory.FIELD_DATE) {
            msg.cTime = field.Value;
        } else if (field.FieldId === FieldsFactory.FIELD_LAST_TRADED_TIME) {
            msg.LastTradedTime = new Date(field.Value + DateTimeUtils.TIME_UTC_OFFSET);
        } else if (field.FieldId === FieldsFactory.FIELD_AVG_TRADED_PRICE) {
            msg.AvgTradedPrice = field.Value;
        } else if (field.FieldId === FieldsFactory.FIELD_TOTAL_BUY_QTY) {
            msg.TotalBuyQuantity = field.Value;
        } else if (field.FieldId === FieldsFactory.FIELD_TOTAL_SELL_QTY) {
            msg.TotalSellQuantity = field.Value;
        } else if (field.FieldId === FieldsFactory.FIELD_AUCTION_NUMBER) {
            msg.AuctionNumber = field.Value;
        } else if (field.FieldId === FieldsFactory.FIELD_AUCTION_STATUS) {
            msg.AuctionStatus = field.Value;
        } else if (field.FieldId === FieldsFactory.FIELD_INITIATOR_TYPE) {
            msg.InitiatorType = field.Value;
        } else if (field.FieldId === FieldsFactory.FIELD_INITIATOR_PRICE) {
            msg.InitiatorPrice = field.Value;
        } else if (field.FieldId === FieldsFactory.FIELD_INITIATOR_QTY) {
            msg.InitiatorQuantity = field.Value;
        } else if (field.FieldId === FieldsFactory.FIELD_HI_OPEN_INTEREST) {
            msg.HighOpenInterest = field.Value;
        } else if (field.FieldId === FieldsFactory.FIELD_LOW_OPEN_INTEREST) {
            msg.LowOpenInterest = field.Value;
        } else if (field.FieldId === FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID) {
            msg.InstrumentTradableID = field.Value;
        } else if (field.FieldId === FieldsFactory.FIELD_TRADED_VALUE) {
            msg.TradedValue = field.Value;
        } else if (field.FieldId === FieldsFactory.FIELD_OFF_EXCHANGE_VOLUME) {
            msg.OffExchangeVolume = field.Value;
        } else if (field.FieldId === FieldsFactory.FIELD_OFF_EXCHANGE_VALUE) {
            msg.OffExchangeValue = field.Value;
        } else if (field.FieldId === FieldsFactory.FIELD_AUCTION_END_TIME) {
            msg.AuctionEndTime = field.Value;
        } else if (field.FieldId === FieldsFactory.FIELD_REMAINING_QTY) {
            msg.RemainingQty = field.Value;
        } else if (field.FieldId === FieldsFactory.FIELD_REMAINING_QTY_SIDE) {
            msg.RemainingQtySide = field.Value;
        } else if (field.FieldId === FieldsFactory.FIELD_PRICE_LOW_LIMIT) {
            msg.LPPLow = field.Value;
        } else if (field.FieldId === FieldsFactory.FIELD_PRICE_HI_LIMIT) {
            msg.LPPHigh = field.Value;
        } else if (field.FieldId === FieldsFactory.FIELD_FUNDING_RATE_VALUE) {
            msg.FundingRateValue = field.Value;
        } else if (field.FieldId === FieldsFactory.FIELD_MARK_PRICE) {
            msg.MarkPrice = field.Value;
        } else if (field.FieldId === FieldsFactory.FIELD_NEXT_FUNDING_TIME) {
            msg.NextFundingTimeUTC = new Date(field.Value);
        }
    }
}
