// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Point } from '../../Commons/Geometry';
import { type FibonacciSpiralDataCacheTool } from '../../Commons/cache/Tools/FibonacciSpiralDataCacheTool';
import { type TerceraChartCashItemSeries } from '../Series/TerceraChartCashItemSeries';
import { TerceraChartCashItemSeriesDataType } from '../Series/TerceraChartCashItemSeriesEnums';
import { CurveToolView } from './CurveToolView';

export class FibonacciSpiralToolView extends CurveToolView<FibonacciSpiralDataCacheTool> {
    public override Draw (gr, ww, param): void {
        const lastCurvePoints = this.lastCurvePoints;
        if (!lastCurvePoints) return;

        const screenPoints = this.screenPoints;

        const scrP0 = screenPoints[0];
        const scrP0X = scrP0[0];
        const scrP0Y = scrP0[1];

        const scrP1 = screenPoints[1];
        const scrP1X = scrP1[0];
        const scrP1Y = scrP1[1];

        const dataCacheTool = this.dataCacheTool;
        const penHighlight = dataCacheTool.PenHighlight;

        const pc = ww.PointsConverter;

        const CurrentSelection = this.CurrentSelection;
        //
        // блок для сброса искажения. рисеут эллипс каким он станет в случае сброса масштаба, в случае смещения точки
        // выделена точка, рисуем контур модифая!
        if (CurrentSelection.SelectedPointNo >= 0) {
            gr.DrawLine(penHighlight, scrP0X, scrP0Y, scrP1X, scrP1Y);

            const newLocationPoints = this.getCirclePoints(ww, param.mainPriceRenderer.Series);
            const newLocationPoints_len = newLocationPoints.length;

            const afterModifyPoints = [];

            for (let t = 0; t < newLocationPoints_len; t++) {
                const newLocP = newLocationPoints[t];
                afterModifyPoints.push(new Point(pc.GetScreenXbyTime(newLocP[0]), pc.GetScreenY(newLocP[1])));
            }
            // рисуем эллипс по точкам
            if (afterModifyPoints.length > 4) {
                gr.DrawCurve(penHighlight, afterModifyPoints);
            }
        }

        /* TODO.
    if (lastCurvePoints[0].X >= ProMath.infinity || lastCurvePoints[0].Y >= ProMath.infinity || lastCurvePoints[0].X <= ProMath.infinityMinus || lastCurvePoints[0].Y <= ProMath.infinityMinus)
    { }  // #41849
    else */
        // рисуем эллипс по точкам
        if (lastCurvePoints.length > 2) {
            gr.DrawCurve(dataCacheTool.Pen, lastCurvePoints);
        }

        super.Draw(gr, ww, param);
        //
        if (CurrentSelection.SelectedPointNo >= 0) {
            gr.DrawLine(penHighlight, scrP0X, scrP0Y, scrP1X, scrP1Y);
        }
    }

    public override UpdateAdditionalPoints (ww, movePointNo, cashItemSeries: TerceraChartCashItemSeries): void {
        const dataCacheTool = this.dataCacheTool;
        // только 2 базовые точки
        this.UpdateScreenPointsALL(ww, cashItemSeries, true);

        const newPts = this.getCirclePoints(ww, cashItemSeries);
        const newPts_len = newPts.length;

        const temp = dataCacheTool.CopyPoints();
        const temp_len = temp.length;

        for (let i = 0; i < newPts_len && temp_len > 2 + i; i++) {
            temp[2 + i] = newPts[i];
        }

        dataCacheTool.Points = temp;

        this.UpdateScreenPointsALL(ww, cashItemSeries, false);
    }

    public getCirclePoints (ww, cashItemSeries: TerceraChartCashItemSeries): number[][] {
        let newAddPoints: number[][] = [];

        const dataCacheTool = this.dataCacheTool;

        const screenPoints = this.screenPoints;

        const scrP0 = screenPoints[0];
        const scrP0X = scrP0[0];
        const scrP0Y = scrP0[1];

        const scrP1 = screenPoints[1];
        const scrP1X = scrP1[0];
        const scrP1Y = scrP1[1];

        const dY = scrP1Y - scrP0Y;
        const dX = scrP1X - scrP0X;

        if (!dY && !dX) return newAddPoints;

        let radius = Math.sqrt(dX * dX + dY * dY);
        radius = radius / 8.412; // число подбирал сам, чтобы конец хвостика всегда находился на спирали!

        if (!radius) return newAddPoints;

        let centerX = scrP0X;
        let centerY = scrP0Y;

        //
        // REVERCE
        const reverce = scrP0X < scrP1X;

        let w = Math.atan(dY / Math.abs(dX));
        if (!reverce) w += Math.PI / 2;

        let rdx = radius * Math.sin(w);
        let rdy = radius * Math.cos(w);
        let start = -2 * w;
        let finish = 0;
        let incr = (-5.5 * Math.PI) / dataCacheTool.AdditionalPointsCount() - 0.0000001; // даблы при сложении теряют точность тут надбавка чтоб цыклы верно отрабатывали
        let direction = -Math.PI / 2;

        //
        if (reverce) {
            rdx *= -1;
            rdy *= -1;
            incr *= -1;
            direction *= -1;
            start *= -1;
            start += 2 * direction;
            w = Math.atan(dX / dY);
            if (dY < 0) w += Math.PI;
        }
        //
        // 1
        //
        // start = start;
        finish = start + direction;
        if (!reverce) {
            centerX = scrP0X - rdy;
            centerY = scrP0Y + rdx;
        } else {
            centerX = scrP0X + rdx;
            centerY = scrP0Y - rdy;
        }
        newAddPoints = newAddPoints.concat(this.getArc(centerX, centerY, w, radius, start, finish, incr, ww, cashItemSeries));
        //
        // 2
        //
        if (!reverce) {
            centerX = centerX + rdx;
            centerY = centerY + rdy;
        } else {
            centerX = centerX + rdy;
            centerY = centerY + rdx;
        }
        start = finish;
        finish = start + direction;
        newAddPoints = newAddPoints.concat(this.getArc(centerX, centerY, w, 2 * radius, start, finish, incr, ww, cashItemSeries));
        //
        // 3
        //
        if (!reverce) {
            centerX = centerX + rdy;
            centerY = centerY - rdx;
        } else {
            centerX = centerX - rdx;
            centerY = centerY + rdy;
        }
        start = finish;
        finish = start + direction;
        newAddPoints = newAddPoints.concat(this.getArc(centerX, centerY, w, 3 * radius, start, finish, incr, ww, cashItemSeries));
        //
        // 5
        //
        if (!reverce) {
            centerX = centerX - 2 * rdx;
            centerY = centerY - 2 * rdy;
        } else {
            centerX = centerX - 2 * rdy;
            centerY = centerY - 2 * rdx;
        }
        start = finish;
        finish = start + direction;
        newAddPoints = newAddPoints.concat(this.getArc(centerX, centerY, w, 5 * radius, start, finish, incr, ww, cashItemSeries));
        //
        // 8
        //
        if (!reverce) {
            centerX = centerX - 3 * rdy;
            centerY = centerY + 3 * rdx;
        } else {
            centerX = centerX + 3 * rdx;
            centerY = centerY - 3 * rdy;
        }
        start = finish;
        finish = start + direction;
        newAddPoints = newAddPoints.concat(this.getArc(centerX, centerY, w, 8 * radius, start, finish, incr, ww, cashItemSeries));
        //
        // 13
        //
        if (!reverce) {
            centerX = centerX + 5 * rdx;
            centerY = centerY + 5 * rdy;
        } else {
            centerX = centerX + 5 * rdy;
            centerY = centerY + 5 * rdx;
        }
        start = finish;
        finish = start + direction;
        newAddPoints = newAddPoints.concat(this.getArc(centerX, centerY, w, 13 * radius, start, finish, incr, ww, cashItemSeries));
        //
        // 21
        //
        if (!reverce) {
            centerX = centerX + 8 * rdy;
            centerY = centerY - 8 * rdx;
        } else {
            centerX = centerX - 8 * rdx;
            centerY = centerY + 8 * rdy;
        }
        start = finish;
        finish = start + direction;
        newAddPoints = newAddPoints.concat(this.getArc(centerX, centerY, w, 21 * radius, start, finish, incr, ww, cashItemSeries));
        //
        // 34
        //
        if (!reverce) {
            centerX = centerX - 13 * rdx;
            centerY = centerY - 13 * rdy;
        } else {
            centerX = centerX - 13 * rdy;
            centerY = centerY - 13 * rdx;
        }
        start = finish;
        finish = start + direction;
        newAddPoints = newAddPoints.concat(this.getArc(centerX, centerY, w, 34 * radius, start, finish, incr, ww, cashItemSeries));
        //
        // 55
        //
        if (!reverce) {
            centerX = centerX - 21 * rdy;
            centerY = centerY + 21 * rdx;
        } else {
            centerX = centerX + 21 * rdx;
            centerY = centerY - 21 * rdy;
        }
        start = finish;
        finish = start + direction;
        newAddPoints = newAddPoints.concat(this.getArc(centerX, centerY, w, 55 * radius, start, finish, incr, ww, cashItemSeries));
        //
        // 89
        //
        if (!reverce) {
            centerX = centerX + 34 * rdx;
            centerY = centerY + 34 * rdy;
        } else {
            centerX = centerX + 34 * rdy;
            centerY = centerY + 34 * rdx;
        }
        start = finish;
        finish = start + direction;
        newAddPoints = newAddPoints.concat(this.getArc(centerX, centerY, w, 89 * radius, start, finish, incr, ww, cashItemSeries));
        //
        // 144
        //
        if (!reverce) {
            centerX = centerX + 55 * rdy;
            centerY = centerY - 55 * rdx;
        } else {
            centerX = centerX - 55 * rdx;
            centerY = centerY + 55 * rdy;
        }
        start = finish;
        finish = start + direction;
        newAddPoints = newAddPoints.concat(this.getArc(centerX, centerY, w, 144 * radius, start, finish, incr, ww, cashItemSeries));
        //
        return newAddPoints;
    }

    public getArc (centerX: number, centerY: number, w: number, radius: number, fromAngle: number, toAngle: number, increment: number, ww, cashItemSeries: TerceraChartCashItemSeries): number[][] {
        const newAddPoints: number[][] = [];
        const a = radius;
        const b = radius;

        if (!increment) return newAddPoints;

        const pc = ww.PointsConverter;

        for (let t = fromAngle; (increment < 0 && t > toAngle) || (increment > 0 && t < toAngle); t += increment) {
            const xt = a * Math.cos(t) * Math.cos(w) - b * Math.sin(t) * Math.sin(w);
            const yt = b * Math.cos(w) * Math.sin(t) + a * Math.cos(t) * Math.sin(w);

            // relative
            if (cashItemSeries && cashItemSeries.settings.DataType === TerceraChartCashItemSeriesDataType.Relative) {
                newAddPoints.push([
                    pc.GetDataX(xt + centerX),
                    cashItemSeries.settings.relativeDataConverter.Revert(pc.GetDataY(centerY + yt))
                ]);
            }
            // log
            else if (cashItemSeries && cashItemSeries.settings.DataType === TerceraChartCashItemSeriesDataType.Log) {
                newAddPoints.push([
                    pc.GetDataX(xt + centerX),
                    cashItemSeries.settings.logDataConverter.Revert(pc.GetDataY(centerY + yt))
                ]);
            }
            // absolute
            else {
                newAddPoints.push([pc.GetDataX(centerX + xt), pc.GetDataY(centerY + yt)]);
            }
        }

        return newAddPoints;
    }
}
