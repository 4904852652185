// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

export class PFixField {
    public FieldId: number;
    public Value: any;

    constructor (fieldId?, buffer?) {
        this.FieldId = fieldId;
        if (buffer && buffer !== null) {
            this.ReadValue(buffer);
        }
    }

    public ReadValue (buffer): void {
        // virtual
    }

    public Write (buffer, maxServerNumber?): void {
        // virtual
    }

    public toString (): string {
        if (/* this.FieldId === -1 || */ this.Value === null) {
            return '<empty>';
        }

        return '#' + this.FieldId + '=' + this.Value.toString();
    }

    public SerializeValue (): string {
        if (this.Value !== null && this.Value !== undefined) {
            return this.Value.toString();
        } else {
            return '';
        }
    }
}
