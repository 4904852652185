// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { ConectionResultEnum } from "../../Commons/ConnectionEnums.ts";
import { Resources } from "../../Commons/properties/Resources.ts";
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";
import { KeyCode, KeyEventProcessor } from "../../Commons/KeyEventProcessor.ts";
import { TerceraBaseWindowTemplate, AdditionalVerificationScreenTemplate } from "../../templates.js";
import { TerceraButton } from "../elements/TerceraButton.ts";
import { TerceraWindowBase } from "./TerceraWindowBase.js";
import { TerceraButtonStyle } from "../../Utils/Enums/ButtonEnums.ts";


export let AdditionalVerificationScreen = TerceraWindowBase.extend({
    // template
    template: TerceraBaseWindowTemplate,
    data: function ()
    {
        return {
            verificationValue: '',
            zIndex: 300,
            height: 145,
            width: 445,

            resizable: false,
            showFooter: false,

            okText: "Ok",
            cancelText: "Cancel",
            btnStyle: TerceraButtonStyle.Standard,

            verificationValue: '',
            verificationValuePlaceholder: '<Enter activation code from E-mail>',
            header: "Need activation",
            reasonText: "Please enter activation code (sent to your mail)",
            isNeedActivation: true,
            allowPreviewPassword: false,

            okCallBack: null,
            cancelCallBack: null
        };
    },
    partials: { bodyPartial: AdditionalVerificationScreenTemplate },
    forceCloseOnLogout: false
});

AdditionalVerificationScreen.prototype.oninit = function ()
{
    TerceraWindowBase.prototype.oninit.apply(this);

    this.on('okClick', this.okClick);
    this.on('cancelClick', this.cancelClick);
    //this.set({ pictureUrl: App.ThemeManager.getImageFullUrl(imgUrl) });
};

AdditionalVerificationScreen.prototype.oncomplete = function ()
{
    TerceraWindowBase.prototype.oncomplete.apply(this);
    this.center();
};

AdditionalVerificationScreen.prototype.okClick = function ()
{
    var okCallBack = this.get('okCallBack');
    if (okCallBack)
        okCallBack(this.get('verificationValue'));
    this.close();
};

AdditionalVerificationScreen.prototype.cancelClick = function ()
{
    var cancelCallBack = this.get('cancelCallBack');
    if (cancelCallBack)
        cancelCallBack();
    this.close();
};

// Close button (cross icon).
AdditionalVerificationScreen.prototype.onCloseButtonClick = function ()
{
    if (this.canCloseFromButton())
        this.cancelClick();
};

AdditionalVerificationScreen.Show = function (initRespons, okCB, cancelCB, connectionParams)
{
    var isNeedActivation = !!(initRespons && initRespons.connectReason === ConectionResultEnum.NeedActivation);

    var headerText = Resources.getResource("screen.additionalVerification.title");
    if (initRespons)
        switch (initRespons.verificationModeCode)
        {
            case AdditionalVerificationScreen.VerificationMode.Sms:
                headerText = Resources.getResource("screen.additionalVerification.title.Sms");
                break;
            case AdditionalVerificationScreen.VerificationMode.Email:
                headerText = Resources.getResource("screen.additionalVerification.title.Email");
                break;
            case AdditionalVerificationScreen.VerificationMode.Fixed:
                headerText = Resources.getResource("screen.additionalVerification.title.Fixed");
                break;
            case AdditionalVerificationScreen.VerificationMode.SmsPlusEmailOneTimePass:
                headerText = Resources.getResource("screen.additionalVerification.title.SmsPlusEmailOneTimePass");
                break;
            case AdditionalVerificationScreen.VerificationMode.DailySmsPlusEmailOneTimePass:
                headerText = Resources.getResource("screen.additionalVerification.title.DailySmsPlusEmailOneTimePass");
                break;
        }
    headerText = isNeedActivation ? Resources.getResource("screen.additionalVerification.Need activation") : headerText;
    var innerText = isNeedActivation ? Resources.getResource("screen.additionalVerification.Need activation.comment") : (connectionParams ? Resources.getResource("screen.additionalVerification.comment").replace("{1}", connectionParams.connectionName) : headerText);//"Connection ({1}) required additional verification:"
    var plHolder = isNeedActivation ? Resources.getResource("screen.additionalVerification.Need activation.watermark") : Resources.getResource("screen.additionalVerification.watermark");

    var verScreen = new AdditionalVerificationScreen();
    verScreen.set({
        allowPreviewPassword: connectionParams?.allowPreviewPassword,
        isNeedActivation: isNeedActivation,
        okCallBack: okCB,
        cancelCallBack: cancelCB,
        header: headerText,
        reasonText: innerText,
        verificationValuePlaceholder: plHolder,
    });

    MainWindowManager.MainWindow.addControl(verScreen);

    var keyProc = KeyEventProcessor;
    keyProc.OnKeyDown.Subscribe(verScreen.onGlobalKeyDown, verScreen);

};

AdditionalVerificationScreen.prototype.onGlobalKeyDown = function (code)
{
    if (this.get('focused'))
    {
        if (code === KeyCode.ESC)
            this.cancelClick();
        if (code === KeyCode.ENTER)
            this.okClick();
    }
};

AdditionalVerificationScreen.prototype.onteardown = function ()
{
    var keyProc = KeyEventProcessor;
    keyProc.OnKeyDown.UnSubscribe(this.onGlobalKeyDown, this);
}


AdditionalVerificationScreen.VerificationMode = { None: 0, Sms: 1, Email: 2, Fixed: 3, Token: 4, SmsPlusEmailOneTimePass: 5, DailySmsPlusEmailOneTimePass: 6 };
