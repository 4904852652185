// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectEntitlementProductSubscriptionResponseMessage } from '../../../../Utils/DirectMessages/DirectMessagesImport';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class EntitlementProductSubscriptionResponseMessageProcessor extends MessageProcessorBase {
    public override decode (fs: PFixFieldSet): DirectEntitlementProductSubscriptionResponseMessage[] {
        const msg = new DirectEntitlementProductSubscriptionResponseMessage();

        msg.Id = fs.GetValue(FieldsFactory.FIELD_PRODUCT_ID);
        msg.ReqStatus = fs.GetValue(FieldsFactory.FIELD_PRODUCT_REQUEST_STATUS);
        msg.ReqType = fs.GetValue(FieldsFactory.FIELD_PRODUCT_REQUEST_TYPE);
        msg.ReqTime = fs.GetValue(FieldsFactory.FIELD_PRODUCT_REQUEST_TIME);
        msg.ResponseEnd = fs.GetValue(FieldsFactory.FIELD_RESPONCE_END);
        msg.ErrorCode = fs.GetValue(FieldsFactory.FIELD_ERROR_CODE);
        msg.Comment = fs.GetValue(FieldsFactory.FIELD_COMMENT);

        return [msg];
    }
}
