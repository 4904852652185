// TODO. UGLY. It is used only in chart so far.
// Use already defined StopOrderEdit, StopLimitOrderEdit, LimitOrderEdit instances.
// Supports different stop/limit, buy/sell bound order pairs.
// TODO. Doesn't support StopLimit.
// NOTE:
// stopPrice - first order's price.

import { OperationType } from '../../../../Utils/Trading/OperationType';
import { type OrderEditRequestData } from '../../../../Utils/Trading/OrderEditBaseUtils';
import { OrderType } from '../../../../Utils/Trading/OrderType';
import { OCOOrderEdit } from './OCOOrderEdit';

// limitPrice - second order's price.
export class OCOCustomOrdersEdit extends OCOOrderEdit {
    constructor (data) {
        super(data);

        this.firstOrderType = null;
        this.secondOrderType = null;

        this.firstOrderTif = null;
        this.secondOrderTif = null;

        this.firstOrderSide = null;
        this.secondOrderSide = null;
    }

    public setOrdersTypes (firstOrderType: OrderType | undefined | null, secondOrderType: OrderType | undefined | null): void {
        this.firstOrderType = firstOrderType;
        this.secondOrderType = secondOrderType;
    }

    public setOrdersTifs (firstOrderTif, secondOrderTif): void {
        this.firstOrderTif = firstOrderTif;
        this.secondOrderTif = secondOrderTif;
    }

    public setOrdersSides (firstOrderSide, secondOrderSide): void {
        this.firstOrderSide = firstOrderSide;
        this.secondOrderSide = secondOrderSide;
    }

    public setOrdersPrices (firstOrderPrice, secondOrderPrice): void {
        this.stopPrice = secondOrderPrice;
        this.limitPrice = firstOrderPrice;

        // TODO. Validate.... Whatever. Details are at the top of OCOCustomOrdersEdit.
    }

    public override getDataForRequest (): OrderEditRequestData {
        const data = super.getDataForRequest();

        data.ocoCustomOrdersData = {
            firstOrderType: this.firstOrderType,
            secondOrderType: this.secondOrderType,

            firstOrderTif: this.firstOrderTif,
            secondOrderTif: this.secondOrderTif,

            firstOrderSide: this.firstOrderSide,
            secondOrderSide: this.secondOrderSide,

            firstOrderLimitPriceForStopLimit: this.firstOrderType === OrderType.StopLimit ? this.getLimitPriceWithDefaultOffset(this.limitPrice, this.firstOrderSide === OperationType.Buy) : null,
            secondOrderLimitPriceForStopLimit: this.secondOrderType === OrderType.StopLimit ? this.getLimitPriceWithDefaultOffset(this.stopPrice, this.secondOrderSide === OperationType.Buy) : null
        };

        return data;
    }
}
