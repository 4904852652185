import { PanelNames } from '../../UtilsClasses/FactoryConstants';
import { OptionPositionItem } from '../../cache/OptionMaster/OptionPositionItem';
import { type OptionTrader } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionTrader';
import { type Position } from '../../../Commons/cache/Position';
import { SessionSettings } from '../../../Commons/SessionSettings';
import { PositionsPanel } from '../PositionsPanel';
import { type PositionItem } from '../../cache/PositionItem';

export class OptionPositionsPanel extends PositionsPanel {
    private _optionTrader: OptionTrader;

    constructor () {
        super();
        this.Name = 'OptionPositionsPanel';
        this.headerLocaleKey = 'panel.optionPositions';
        this.AddPositionEvent = this.AddPositionEvent.bind(this);
        this.RemovePositionEvent = this.RemovePositionEvent.bind(this);
        this.populateItemsDirect = this.populateItemsDirect.bind(this);
    }

    public override oninit (): void {
        super.oninit();
        void super.set('isRelativeStyle', true);
        void super.set('isAllowPadding', false);
        super.observe('visible', this.onVisibleChanged);
    }

    public override getType (): PanelNames { return PanelNames.OptionPositionsPanel; }

    public override repopulate (): void {
        super.repopulate();
        this.populateItemsDirect();
    }

    public override populateItemsDirect (): void {
        const quickTable = this.getQuickTable();
        quickTable.ClearAll();
        if (isNullOrUndefined(quickTable)) {
            return;
        }
        if (isNullOrUndefined(this._optionTrader)) {
            return;
        }
        const positionsById = this._optionTrader.positions.reduce((obj, position) => {
            obj[position.PositionId] = position;
            return obj;
        }, {});
        this.populateItems(positionsById);
    }

    public override TickAsync (): void {
        super.TickAsync();
        this.getQuickTable().needRedrawBackground = true;
    }

    public override createPositionItem (position: Position): PositionItem {
        return new OptionPositionItem(this._optionTrader, position, SessionSettings);
    }

    public override DCEventsSubscribe (): void {
        if (isNullOrUndefined(this._optionTrader)) {
            return;
        }
        this._optionTrader.subscribeOnAddPosition(this.AddPositionEvent);
        this._optionTrader.subscribeOnRemovePosition(this.RemovePositionEvent);
        this._optionTrader.subscribeOnAccountChanged(this.populateItemsDirect);
        this._optionTrader.subscribeOnInstrumentChanged(this.populateItemsDirect);
    }

    public override DCEventsUnSubscribe (): void {
        if (isNullOrUndefined(this._optionTrader)) {
            return;
        }
        this._optionTrader.unsubscribeOnAddPosition(this.AddPositionEvent);
        this._optionTrader.unsubscribeOnRemovePosition(this.RemovePositionEvent);
        this._optionTrader.unsubscribeOnAccountChanged(this.populateItemsDirect);
        this._optionTrader.unsubscribeOnInstrumentChanged(this.populateItemsDirect);
    }

    public setOptionTrader (optionTrader: OptionTrader): void {
        this._optionTrader = optionTrader;
        this.DCEventsSubscribe();
    }

    private onVisibleChanged (): void {
        this.layoutTable();
    }
}
