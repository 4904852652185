// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { RevenuePlanMessage, RevenueSpreadItem } from '../Messages/RevenuePlanMessage';
import { RevenuePlanMessageType } from '../../../../Utils/Enums/Constants';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { RevenueCommissionValueItem } from '../../../../Commons/cache/Commissions/RevenueCommissionValueItem';
import { RevenueCommissionValueItemForFillVolumeWithMinPD } from '../../../../Commons/cache/Commissions/RevenueCommissionValueItemForFillVolumeWithMinPD';
import { RevenueCommissionValueItemForPerFill } from '../../../../Commons/cache/Commissions/RevenueCommissionValueItemForPerFill';
import { RevenueCommissionItem } from '../../../../Commons/cache/Commissions/RevenueCommissionItem';
import { RevenueCommissionEntryItem } from '../../../../Commons/cache/Commissions/RevenueCommissionEntryItem';
import { CommissionTypes } from '../../../../Utils/Commission/CommissionEnums';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class RevenuePlanMessageProcessor extends MessageProcessorBase {
    public decoder: any;

    constructor (parentDecoder?) {
        super();

        this.decoder = parentDecoder;
    }

    public override decode (fieldSet: PFixFieldSet): RevenuePlanMessage[] {
        const cpm = new RevenuePlanMessage();

        const Id = fieldSet.GetValue(FieldsFactory.FIELD_COMMISION_PLAN_ID);
        if (Id !== null) {
            cpm.Id = Id;
        } else {
            cpm.Id = fieldSet.GetValue(FieldsFactory.FIELD_ID);
        }

        cpm.Name = fieldSet.GetValue(FieldsFactory.FIELD_NAME);
        cpm.Description = fieldSet.GetValue(FieldsFactory.FIELD_DESCRIPTION);
        cpm.CommisionForTransfer = fieldSet.GetValue(FieldsFactory.FIELD_COMMISSION_FOR_TRANSFER_VALUE); // была лажа спутали номер поля.
        cpm.CounterAccountID = fieldSet.GetValue(FieldsFactory.FIELD_COUNTER_ACCOUNT_ID);

        cpm.TransferCommisionType = fieldSet.GetValue(FieldsFactory.FIELD_COMMISSION_TYPE_FOR_TRANSFER);
        cpm.WithdrawalCommisionType = fieldSet.GetValue(FieldsFactory.FIELD_COMMISSION_TYPE_FOR_WITHDRAWAL);
        cpm.TransferPercentage = fieldSet.GetValue(FieldsFactory.FIELD_TRANSFER_COMMISSION_PERCENTAGE);
        cpm.WithdrawalPercentage = fieldSet.GetValue(FieldsFactory.FIELD_WITHDRAWAL_COMMISSION_PERCENTAGE);
        cpm.TransferFixedFee = fieldSet.GetValue(FieldsFactory.FIELD_TRANSFER_COMMISSION_FIXED_FEE);
        cpm.WithdrawalFixedFee = fieldSet.GetValue(FieldsFactory.FIELD_WITHDRAWAL_COMMISSION_FIXED_FEE);
        cpm.TransferMinFee = fieldSet.GetValue(FieldsFactory.FIELD_TRANSFER_COMMISSION_MIN_FEE);
        cpm.WithdrawalMinFee = fieldSet.GetValue(FieldsFactory.FIELD_WITHDRAWAL_COMMISSION_MIN_FEE);
        cpm.TransferMaxFee = fieldSet.GetValue(FieldsFactory.FIELD_TRANSFER_COMMISSION_MAX_FEE);
        cpm.WithdrawalMaxFee = fieldSet.GetValue(FieldsFactory.FIELD_WITHDRAWAL_COMMISSION_MAX_FEE);

        cpm.TransferCurrencyId = fieldSet.GetValue(FieldsFactory.FIELD_TRANSFER_COMMISSION_CURRENCY_ID);
        cpm.WithdrawalCurrencyId = fieldSet.GetValue(FieldsFactory.FIELD_WITHDRAWAL_COMMISSION_CURRENCY_ID);
        cpm.TransferAccountId = fieldSet.GetValue(FieldsFactory.FIELD_TRANSFER_COMM_COUNTER_ACCOUNT_ID);
        cpm.WithdrawalAccountId = fieldSet.GetValue(FieldsFactory.FIELD_WITHDRAWAL_COMM_COUNTER_ACCOUNT_ID);

        const groups = fieldSet.GetGroups(FieldsFactory.SPREAD_LEVEL_GROUP);

        let len = groups.length;
        for (let i = 0; i < len; i++) {
            const group = groups[i];
            const item = new RevenueSpreadItem();
            item.Id = group.GetValue(FieldsFactory.FIELD_ID);
            item.InstrumentTypeId = group.GetValue(FieldsFactory.FIELD_INSTRUMENTTYPE_ID);
            item.Spread = group.GetValue(FieldsFactory.FIELD_SPREAD);
            item.BidShift = group.GetValue(FieldsFactory.FIELD_BIDSHIFT);
            item.AskShift = group.GetValue(FieldsFactory.FIELD_ASKSHIFT);
            item.SpreadMode = group.GetValue(FieldsFactory.FIELD_SPREAD_MODE);
            item.SpreadMeasure = group.GetValue(FieldsFactory.FIELD_SPREAD_MEASURE);

            const instrId = group.GetValue(FieldsFactory.FIELD_INSTRUMENT_ID);
            if (instrId != -1) {
                item.InstrumentId = instrId.toString();
            }

            cpm.SpreadItems.push(item);
        }

        const commissionLevelGroups = fieldSet.GetGroups(FieldsFactory.COMMISSION_LEVEL_GROUP);
        len = commissionLevelGroups.length;
        for (let i = 0; i < len; i++) {
            const commissionLevel = commissionLevelGroups[i];
            const entry = new RevenueCommissionEntryItem();
            entry.Type = commissionLevel.GetValue(FieldsFactory.FIELD_TYPE);
            entry.Name = commissionLevel.GetValue(FieldsFactory.FIELD_NAME);
            entry.ShortCommissionInfo = commissionLevel.GetValue(FieldsFactory.FIELD_DESCRIPTION);

            entry.Currency = commissionLevel.GetValue(FieldsFactory.FIELD_CURRENCY);

            entry.AssetID = commissionLevel.GetValue(FieldsFactory.FIELD_ASSET_ID);

            entry.InstrumentTypeId = commissionLevel.GetValue(FieldsFactory.FIELD_INSTRUMENTTYPE_ID);

            const instrId = commissionLevel.GetValue(FieldsFactory.FIELD_INSTRUMENT_ID);
            if (instrId != -1) {
                entry.InstrumentId = instrId.toString();
            }

            entry.UseInVAT = commissionLevel.GetValue(FieldsFactory.FIELD_USE_IN_VAT);

            const commissionGroups = commissionLevel.GetGroups(FieldsFactory.COMMISSION_GROUP);
            const len_j = commissionGroups.length;
            for (let j = 0; j < len_j; j++) {
                const commissionGroup = commissionGroups[j];
                const item = new RevenueCommissionItem();
                item.Type = commissionGroup.GetValue(FieldsFactory.FIELD_COMMISSION_TYPE);

                item.PaymentType = commissionGroup.GetValue(FieldsFactory.FIELD_COMMISSION_PAYMENT_TYPE);
                item.CounterAccountID = commissionGroup.GetValue(FieldsFactory.FIELD_COUNTER_ACCOUNT_ID);
                item.IsSetIB = commissionGroup.GetValue(FieldsFactory.FIELD_COMMISSION_ACTIVATEIB);
                item.ApplyOperationType = commissionGroup.GetValue(FieldsFactory.FIELD_APPLY_OPERTIONTYPE);

                const commissionValues = commissionGroup.GetGroups(FieldsFactory.COMMISSION_VALUE_GROUP);
                const len_k = commissionValues.length;
                for (let k = 0; k < len_k; k++) {
                    const commissionValue = commissionValues[k];
                    let value: RevenueCommissionValueItem = null;
                    let NV: number | null = null;
                    switch (item.Type) {
                    case CommissionTypes.VolumeWithMinPD:
                        const minPDItem = new RevenueCommissionValueItemForFillVolumeWithMinPD();
                        NV = commissionValue.GetValue(FieldsFactory.FIELD_COMMISSION_FINAL_VALUE);
                        if (NV !== null) {
                            minPDItem.FinalValue = NV;
                        }

                        NV = commissionValue.GetValue(FieldsFactory.FIELD_COMMISSION_MIN_TRADED_VALUE);
                        if (NV !== null) {
                            minPDItem.MinTradedValue = NV;
                        }
                        value = minPDItem;
                        break;
                    case CommissionTypes.PerFill:
                        const perFillItem = new RevenueCommissionValueItemForPerFill();
                        NV = commissionValue.GetValue(FieldsFactory.FIELD_COMMISSION_MIN_TRADED_VALUE);
                        if (NV !== null) {
                            perFillItem.MinTradedValue = NV;
                        }

                        value = perFillItem;
                        break;
                    default:
                        value = new RevenueCommissionValueItem();
                        break;
                    }
                    value.OperationType = commissionValue.GetValue(FieldsFactory.FIELD_OPERATION_TYPE);
                    value.FromAmount = commissionValue.GetValue(FieldsFactory.FIELD_FROM_AMOUNT);
                    value.ToAmount = commissionValue.GetValue(FieldsFactory.FIELD_TO_AMOUNT);

                    value.MinValue = commissionValue.GetValue(FieldsFactory.FIELD_COMMISSION_MIN_VALUE);
                    value.MaxValue = commissionValue.GetValue(FieldsFactory.FIELD_COMMISSION_MAX_VALUE);
                    value.Value = commissionValue.GetValue(FieldsFactory.FIELD_COMMISSION_VALUE);
                    item.CommissionValueItems.push(value);
                }

                entry.CommissionItems.push(item);
            }

            // сначала приоритетен символ, потом - инструментгруппа
            if (entry.InstrumentId) {
                cpm.CommissionItems.splice(0, 0, entry);
            } else {
                cpm.CommissionItems.push(entry);
            }
        }

        // контролирую, чтобы дефолтовый entry был последним
        // if (cpm.CommissionItems.length > 1) {
        //     const lastEntry = cpm.CommissionItems[cpm.CommissionItems.length - 1];
        //     if (lastEntry.InstrumentId != null || lastEntry.InstrumentTypeId != -1) {
        //     // TODO implement
        //     /// /последний - не default, ишу его
        //     // let defaultEntry = cpm.CommissionItems.Find(x => x.InstrumentId == null && x.InstrumentTypeId == -1);
        //     // if (defaultEntry != null)
        //     // {
        //     //    cpm.CommissionItems.Remove(defaultEntry);
        //     //    cpm.CommissionItems.push(defaultEntry);
        //     // }
        //     }
        // }

        //
        // Похоже когда разделяли месадж CommisionPlan, и решили использовать общий процессор, забыли добавить
        // какой нибудь признак-различие. В датакеше в итоге всё в кучу ложилось.
        //
        const code = fieldSet.GetValue(FieldsFactory.FIELD_MSG_TYPE);
        if (code == Message.CODE_COMMISSION_PLAN_MESSAGE) {
            cpm.RevenuePlanMessageType = RevenuePlanMessageType.Comission;
        } else if (code == Message.CODE_SPREAD_PLAN_MESSAGE) {
            cpm.RevenuePlanMessageType = RevenuePlanMessageType.Spread;
            cpm.Code = Message.CODE_SPREAD_PLAN_MESSAGE;
        } else {
        // wtf
        }

        return [cpm];
    }
}
