// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DataCache } from '../Commons/DataCache';
import { SolidBrush } from '../Commons/Graphics';
import { type Instrument } from '../Commons/cache/Instrument';
import { ThemeManager } from '../Controls/misc/ThemeManager';
import { HistoryType } from '../Utils/History/HistoryType';
import { TerceraChartPriceScaleRenderer, TerceraChartPriceScaleRendererAligment, TerceraChartPriceScaleRendererWindowType } from './Renderers/Scales/TerceraChartPriceScaleRenderer';
import { TerceraChartMainPriceRenderer } from './Renderers/TerceraChartMainPriceRenderer';
import { TerceraChartCashItemSeriesSettings } from './Series/TerceraChartCashItemSeriesSettings';
import { type TerceraChart } from './TerceraChart';
import { TerceraChartOverlayWindow } from './TerceraChartOverlayWindow';
import { CashItemPointsCoverter } from './Utils/PointsConverter/CashItemPointsConverter';

export class TerceraChartOverlay {
    public window: TerceraChartOverlayWindow;
    public mainPriceRenderer: TerceraChartMainPriceRenderer;
    public PriceScaleRenderer: TerceraChartPriceScaleRenderer;
    public cashItemSeriesSettings: TerceraChartCashItemSeriesSettings;
    public chart: TerceraChart;
    public separateScale = true;
    public instrument: Instrument;

    /// <summary>
    /// Рисовать маркеры или нет
    /// </summary>
    public ShowMarker = true;
    public Selected;
    public ActiveInstrument: Instrument | null = null;
    public HistoryType = HistoryType.BID;
    public MarkerBrush;
    public PlateValueBrush;
    public BidOverlayBrush: SolidBrush;
    public AskOverlayBrush: SolidBrush;
    public LastOverlayBrush: SolidBrush;

    constructor (chart: TerceraChart) {
        this.chart = chart;
        this.mainPriceRenderer = new TerceraChartMainPriceRenderer(this.chart);
        this.PriceScaleRenderer = new TerceraChartPriceScaleRenderer(chart, chart.yScaleRendererSettings, TerceraChartPriceScaleRendererWindowType.Overlay);
        this.PriceScaleRenderer.Aligment = TerceraChartPriceScaleRendererAligment.Left;
        this.window = new TerceraChartOverlayWindow(null, this.PriceScaleRenderer);
        this.window.Overlay = this;
        this.window.PointsConverter = new CashItemPointsCoverter(this.window, chart.mainPriceRenderer.Series);
        this.window.Renderers.push(this.mainPriceRenderer);
        this.PriceScaleRenderer.window = this.window;
        this.cashItemSeriesSettings = new TerceraChartCashItemSeriesSettings(chart.model);

        this.BidOverlayBrush = new SolidBrush(ThemeManager.CurrentTheme.Chart_BidOverlayColor);
        this.AskOverlayBrush = new SolidBrush(ThemeManager.CurrentTheme.Chart_AskOverlayColor);
        this.LastOverlayBrush = new SolidBrush(ThemeManager.CurrentTheme.Chart_LastOverlayColor);

        this.ThemeChanged();
    }

    public SeparateScaleGet (): boolean {
        return this.separateScale;
    }

    public SeparateScaleSet (value): void {
        if (value != this.separateScale) {
            this.separateScale = value;

            if (this.separateScale) {
                this.window.AutoFit();
            }
        }
    }

    /// <summary>
    /// Параметры оверлея
    /// </summary>
    get Instrument (): Instrument { return this.instrument; }
    set Instrument (fullInstrumentName) {
        this.Unsubscribe();

        this.instrument = fullInstrumentName;
        // TODO. Non fixed list?
        this.ActiveInstrument = DataCache.getInstrumentByName(fullInstrumentName);

        this.window.FmaxFloatY = this.window.FminFloatY = 0;

        this.Subscribe();
    }

    public Subscribe (): void {
        const ins = this.ActiveInstrument;
        if (!ins) return;

        DataCache.FQuoteCache.addListener(
            ins,
            this,
            ins.DefaultChartHistoryType === HistoryType.QUOTE_TRADES
                ? HistoryType.QUOTE_TRADES
                : HistoryType.QUOTE_LEVEL1);
    }

    /// <summary>
    /// Style to draw chart
    /// </summary>
    public ChartDrawingTypeGet (): any {
        return this.mainPriceRenderer.ChartDrawingType;
    }

    public ChartDrawingTypeSet (value): void {
        this.mainPriceRenderer.ChartDrawingType = value;
    }

    public Unsubscribe (): void {
        const ins = this.ActiveInstrument;
        if (!ins) return;

        DataCache.FQuoteCache.removeListener(
            ins,
            this,
            ins.DefaultChartHistoryType === HistoryType.QUOTE_TRADES
                ? HistoryType.QUOTE_TRADES
                : HistoryType.QUOTE_LEVEL1);
    }

    public MarkerAndLineColorGet (): any {
        return this.mainPriceRenderer.barsLineColorPen.Color;
    }

    public MarkerAndLineColorSet (value): void {
        this.mainPriceRenderer.barsLineColorPen.Color = this.MarkerBrush.Color = value;
    }

    public Dispose (): void {
        this.chart = null;
        this.Instrument = null;
    }

    public newQuote (message): any {

    }

    public ThemeChanged (): void {
        this.mainPriceRenderer.ThemeChanged();

        this.MarkerBrush = new SolidBrush(this.mainPriceRenderer.barsLineColorPen.Color);

        this.PlateValueBrush = new SolidBrush(this.mainPriceRenderer.barsLineColorPen.Color);
    }

    // TODO. Refactor.
    public recalculateIndices (): void {
        const series = this.mainPriceRenderer.Series;
        if (series) {
            series.RecalcIndices();
        }
    }
}
