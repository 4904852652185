// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

export enum AccountTradeStatus {
    ACTIVE_STATUS = 0,
    CLOSED_STATUS = 1,
    SUSPEND_STATUS = 2,
    TRADING_DISABLED_RISK_STATUS = 3,
    FINRA_DAY_TRADER_PATTERN_STATUS = 4,
    LIQUIDATION_ONLY_STATUS = 5
}
