// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

export enum ChartDataType {
    Default = 0,
    Renco = 1,
    HeikinAshi = 2,
    Kagi = 3,
    ThreeLinesBreak = 4,
    TicTac = 5,
    RencoNew = 6,
    VolumeRange = 7
}

export enum ChartHistoryType {
    Default = 0,
    ByBid = 1,
    ByBidAsk = 2,
    ByTrades = 3,
    ByASk = 4,
    BY_BID_ASK_AVERAGE = 5
};

export enum TerceraChartDrawingType {
    Line = 0,
    Bar = 1,
    Candle = 2,
    Dot = 3,
    DotLine = 4,
    Forest = 5,
    Solid = 6,
    // EquiVolume  = 7,
    // CandleVolume  = 8,
    TicTac = 9,
    Kagi = 10,
    // искусственные
    LinesBreak = 11,
    Renko = 12,
    Cluster = 13,
    Profile = 14
};

/// <summary>
/// обмен данными между рендерерами
/// </summary>
export enum TerceraChartRendererDrawingAdvancedParamsEnum {
    /// <summary>
    /// фоновые тулзы или поверх мейн прайс рендерера
    /// </summary>
    CurrentDrawingType = 0
}

export enum TerceraChartToolRendererDrawingType {
    Background = 0,
    Foreground = 1
}

/// <summary>
/// Визуальная торговля с чарта
/// </summary>
export enum TerceraChartTradingOperation {
    /// <summary>
    /// undefined
    /// </summary>
    None = 0,
    /// <summary>
    /// Размещение нового ордера
    /// </summary>
    PlaceNewOrder = 1,
    /// <summary>
    /// Отмена ордера, сл-ордера, тп-ордера
    /// </summary>
    CancelOrder = 2,
    /// <summary>
    /// Отмена ордера, сл-ордера, тп-ордера
    /// </summary>
    CancelOrdersAccount = 3,
    /// <summary>
    /// Отмена ордера, сл-ордера, тп-ордера
    /// </summary>
    CancelOrdersSymbol = 4,
    /// <summary>
    /// исполнить как маркет
    /// </summary>
    ChangeToMarket = 5,
    /// <summary>
    /// был перетащен основной ордер и его сл/тп сместятся также
    /// </summary>
    MoveOrder = 6,
    /// <summary>
    /// ModifyOrderScreen показываем для переданного ордера, ппозиции
    /// </summary>
    ModifyOrderPosition = 7,
    /// <summary>
    /// Закрытие ппозиции
    /// </summary>
    ClosePosition = 8,
    /// <summary>
    /// Закрытие All
    /// </summary>
    CloseAllPosition = 9,
    /// <summary>
    /// Rollback ппозиции
    /// </summary>
    RollbackPosition = 10,
    /// <summary>
    /// Добавить сл для ордера, позиции
    /// </summary>
    PlaceSL = 11,
    /// <summary>
    /// Добавить сл для ордера, позиции
    /// </summary>
    PlaceTP = 12,
    /// <summary>
    /// Изменение цены сл-ордера
    /// </summary>
    ModifySL = 13,
    /// <summary>
    /// Изменение цены тп-ордера
    /// </summary>
    ModifyTP = 14,
    /// <summary>
    /// удалить сл
    /// </summary>
    RemoveSL = 15,
    /// <summary>
    /// удалить тп
    /// </summary>
    RemoveTP = 16,
    /// <summary>
    /// show menu
    /// </summary>
    ActionButton = 17,
    /// alert
    AlertReplacement = 18,
    EditAlert = 19,
    RemoveAlert = 20,
    ChartNewAlert = 21,
    PlaceSLL = 22, /// Добавить StopLossLimit #91413
    ModifySLL = 23, /// Изменение цены StopLossLimit #91413
    RemoveSLL = 24, /// удалить StopLossLimit #91413
    ModifyOrderQuantity = 25,
}
