// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { InstrumentTypes, InstrumentTypesColor, InstrumentTypesShortName } from '../../../../../Utils/Instruments/InstrumentTypes';
import { type Instrument } from '../../../../../Commons/cache/Instrument';
import { InstrumentUtils } from '../../../../../Utils/Instruments/InstrumentUtils';

export class WMSymbolLookupViewItemHelper {
    static getInstrumentTypeShortForInstrument (instrument: Instrument): string {
        return WMSymbolLookupViewItemHelper.getInstrumentTypeShort(instrument.InstrType);
    }

    static getInstrumentTypeShort (type: any): string {
        return type === InstrumentTypes.SPOT ? InstrumentUtils.UseFuturesInsteadSpotShortName() : InstrumentTypesShortName[type];
    }

    static getInstrumentTypeColorForInstrument (instrument: Instrument): string {
        return WMSymbolLookupViewItemHelper.getInstrumentTypeColor(instrument.InstrType);
    }

    static getInstrumentTypeColor (type: any): string {
        return type === InstrumentTypes.SPOT ? InstrumentUtils.UseFuturesInsteadSpotColor() : InstrumentTypesColor[type];
    }
}
