// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PriceType } from "../../../../../Utils/History/CashItemUtils.ts";
import { MODE_UPPER, MODE_LOWER } from "../../IndicatorConstants.ts";
import { iBuildInIndicator } from "../../iBuildInIndicator.ts";

export let iFractals = function ()
{
    iBuildInIndicator.call(this, 2)
}
iFractals.prototype = Object.create(iBuildInIndicator.prototype)

Object.defineProperty(iFractals.prototype, 'Name',
    {
        get: function () { return 'iFractals' }
    })

iFractals.prototype.OnQuote = function (ci, callBound, callFromRefresh)
{
    this.SetValue(MODE_UPPER, 0, 0);
    this.SetValue(MODE_LOWER, 0, 0);
    let Parent = this.Parent
    if (!Parent || this.FCount < 5)
        return

    let high = this.GetPrice(PriceType.High, 2);
    if (high > this.GetPrice(PriceType.High, 4) && high > this.GetPrice(PriceType.High, 3) && high > this.GetPrice(PriceType.High, 1) && high > this.GetPrice(PriceType.High, 0))
        this.SetValue(MODE_UPPER, 2, high);

    let low = this.GetPrice(PriceType.Low, 2);
    if (low < this.GetPrice(PriceType.Low, 4) && low < this.GetPrice(PriceType.Low, 3) && low < this.GetPrice(PriceType.Low, 1) && low < this.GetPrice(PriceType.Low, 0))
        this.SetValue(MODE_LOWER, 2, low);
}