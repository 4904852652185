// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { ErrorInformationStorage } from '../../../Commons/ErrorInformationStorage';
import { Resources } from '../../../Commons/properties/Resources';
import { NewVerticalPanelTableItem } from './NewVerticalPanelTableItem';
import { GeneralSettings } from '../../../Utils/GeneralSettings/GeneralSettings';

export class VerticalPanelDataProvider {
    sett: any;
    /// <summary>
    /// Провайдер итемы - описание данных:
    /// ид, текст, видимость. цвета
    /// </summary>
    items: any[] = [];// new List<VerticalPanelDataProviderItem>()

    /// <summary>
    /// Сгенерированные итемы для таблицы (NewVerticalPanelTableItem)
    /// </summary>
    generatedTableItems: any[] = [];// new List<NewVerticalPanelTableItem>();
    source = null;
    dependency = null; // parameter that may affect data (e.g. Account for Commision in Symbol Info)

    /// <summary>
    /// Ссылки на необходимые методы для работы с
    /// </summary>
    GetVerticalPanelValueHandler = null;
    GetItemsIDHandler = null;
    DisposeHandler = null;
    GetDynamicItemsHandler = null;

    hideGroupHeaders = false;

    constructor (sett) {
        this.sett = sett;
    }

    /// <summary>
    /// Текущий выделенный объект
    /// </summary>
    get Source () { return this.source; }

    set Source (value) { // Dispose previous data
        if (this.source !== null) { this.DisposeHandler(this); }

        // Set new source
        this.source = value;
    }

    /// <summary>
    /// Создание итемов для таблицы на основании выделенного объекта (Source) и провайдер итемов
    /// </summary>
    GenerateItems (VerticalPanelDataProvider, sett): void {
        this.generatedTableItems = [];// .Clear();
        try {
        //
        // Use items from appropriate group
        //
            let groupItems = this.items;
            const itemsForGroup = this.GetItemsIDHandler(VerticalPanelDataProvider, sett);
            if (itemsForGroup != null) {
                groupItems = [];
                for (let i = 0; i < this.items.length; i++) {
                    if (itemsForGroup.Contains(this.items[i].Id)) { groupItems.push(this.items[i]); }
                }

                const dynamicItems = this.GetDynamicItemsHandler(VerticalPanelDataProvider, sett);
                if (dynamicItems != null) {
                    for (let i = 0; i < dynamicItems.length; i++) {
                        if (itemsForGroup.Contains(dynamicItems[i].Id)) { groupItems.push(dynamicItems[i]); }
                    }
                }
            }

            const noneString = Resources.getResource('InstrumentDetailsPanel.None');

            groupItems.sort(function (a, b) {
                return a.SortIndex - b.SortIndex;
            });

            for (let i = 0; i < groupItems.length; i++) {
                const dataItem = groupItems[i];

                if (Resources.isHidden(dataItem.LocalizationKey) || Resources.isHidden(dataItem.Group) || !dataItem.visible) { continue; }

                const dataItemValue = this.GetVerticalPanelValueHandler(dataItem.Id, this, sett);
                if (dataItemValue == noneString) { continue; } // скрываем отображение, если отсутствует значение ("None") https://tp.traderevolution.com/entity/83651

                const tableItem = new NewVerticalPanelTableItem(dataItem.Id, dataItem.LocalizationKey, dataItemValue, dataItem.Group, dataItem.SortIndex, sett);

                tableItem.ToolTipKey = dataItem.tooltipKey;
                tableItem.CellsColor[0] = dataItem.HeaderBackColor;
                tableItem.CellsForeColor[0] = dataItem.HeaderForeColor;
                tableItem.CellsColor[1] = dataItem.ValueBackColor;
                tableItem.CellsForeColor[1] = dataItem.ValueForeColor;
                tableItem.DynPropertyControltype = dataItem.DynPropertyControltype;

                if (dataItem.GroupIndex) { tableItem.GroupIndex = dataItem.GroupIndex; }
                this.generatedTableItems.push(tableItem);
            }
            this.Localize();
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
        }
    };

    /// <summary>
    /// Обновление итемов
    /// </summary>
    UpdateItems (): void {
        const destItemArr = this.generatedTableItems;
        const sett = this.sett;

        for (let i = 0, len = destItemArr.length; i < len; i++) {
            const destItem = destItemArr[i];
            destItem.RowValue = this.GetVerticalPanelValueHandler(destItem.itemId, this, sett, destItem.ItemId);
        }
    };

    Localize (): void {
        const destItemArr = this.generatedTableItems;

        for (let i = 0, len = destItemArr.length; i < len; i++) {
            const destItem = destItemArr[i];
            destItem.RowKey_Localized = Resources.getResource(destItem.RowKey);
        }
    };
}
