// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectTradeMessage } from '../../../../Utils/DirectMessages/DirectTradeMessage';
import { OperationType } from '../../../../Utils/Trading/OperationType';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class TradeMessageProcessor extends MessageProcessorBase {
    public decoder: any;

    constructor (parentDecoder) {
        super();

        this.decoder = parentDecoder;
    }

    public override decode (fs: PFixFieldSet): DirectTradeMessage[] {
        const msg = new DirectTradeMessage();

        msg.OpenCrossPrise = fs.GetValue(FieldsFactory.FIELD_CROSS_PRICE);
        const cross = msg.OpenCrossPrise !== 0 ? msg.OpenCrossPrise : 1;

        msg.TradeId = fs.GetValue(FieldsFactory.FIELD_TRADE_ID).toString();
        msg.OrderId = fs.GetValue(FieldsFactory.FIELD_ORDER_ID).toString();

        msg.AccountId = fs.GetValue(FieldsFactory.FIELD_ACCOUNT_ID).toString();
        msg.Amount = fs.GetValue(FieldsFactory.FIELD_AMOUNT);
        msg.Price = fs.GetValue(FieldsFactory.FIELD_PRICE);
        msg.Comission = fs.GetValue(FieldsFactory.FIELD_COMMISION);
        msg.Rebates = fs.GetValue(FieldsFactory.FIELD_REBATES) / cross;
        msg.PnL = fs.GetValue(FieldsFactory.FIELD_PNL) / cross;
        msg.Time = fs.GetValue(FieldsFactory.FIELD_DATE);
        msg.ExternalTradeId = fs.GetValue(FieldsFactory.FIELD_EXT_ID);
        msg.BuySell = fs.GetValue(FieldsFactory.FIELD_IS_BUY) ? OperationType.Buy : OperationType.Sell;
        msg.Login = fs.GetValue(FieldsFactory.FIELD_CREATOR_NAME);

        const routeId = fs.GetValue(FieldsFactory.FIELD_ROUTE_ID);
        msg.Route = routeId;// decoder.GetRouteById(routeId)

        msg.ExternalOrderId = fs.GetValue(FieldsFactory.FIELD_EXTERNAL_ORDER_ID);
        msg.ExternalPrice = fs.GetValue(FieldsFactory.FIELD_EXTERNAL_PRICE);
        msg.Exchange = fs.GetValue(FieldsFactory.FIELD_EXCHANGE);

        const orderType = fs.GetValue(FieldsFactory.FIELD_ORDER_TYPE);
        msg.OrderType = orderType;

        msg.PositionID = fs.GetValue(FieldsFactory.FIELD_POSITION_ID).toString();
        msg.LastPositionAmount = fs.GetValue(FieldsFactory.FIELD_FILLED_AMOUNT);
        msg.PositionSide = fs.GetValue(FieldsFactory.FIELD_SIDE);

        msg.InstrumentTradableID = fs.GetValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID);
        msg.ProductType = fs.GetValue(FieldsFactory.FIELD_PRODUCT_TYPE);
        msg.ExecutionVenue = fs.GetValue(FieldsFactory.FIELD_EXECUTION_VENUE);
        msg.Leverage = fs.GetValue(FieldsFactory.FIELD_LEVERAGE_SHORT);

        const fieldCounterOrderId = fs.GetValue(FieldsFactory.FIELD_COUNTER_ORDER_ID);
        msg.CounterOrderID =
        fieldCounterOrderId === null
            ? ''
            : fieldCounterOrderId.toString();

        return [msg];
    }
}
