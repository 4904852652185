// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { Resources } from '../Commons/properties/Resources';
import { GeneralSettings } from './GeneralSettings/GeneralSettings';

export class SoundManager {
    private static readonly SoundDict =
        {
            welcome: 'sounds/mp3/intro.mp3',
            warning: 'sounds/mp3/alert.mp3',
            positionClose: 'sounds/mp3/position.mp3',
            orderReplaced: 'sounds/mp3/order.mp3',
            orderRejected: 'sounds/mp3/reject.mp3',
            orderCreated: 'sounds/mp3/order.mp3',
            orderFilled: 'sounds/mp3/order.mp3',
            orderCanceled: 'sounds/mp3/order.mp3',
            modify: 'sounds/mp3/modify.mp3',
            news: 'sounds/mp3/update.mp3',
            marginWarning: 'sounds/mp3/alert.mp3',
            marginCall: 'sounds/mp3/confirmation.mp3',
            conectionLost: 'sounds/mp3/connectionLost.mp3',
            confirmation: 'sounds/mp3/confirmation.mp3',
            lock: 'sounds/mp3/lock.mp3',
            unlock: 'sounds/mp3/unlock.mp3',
            finish: 'sounds/mp3/connectionFinished.mp3',
            alertHigh: 'sounds/mp3/alertHigh.mp3',
            alertMedium: 'sounds/mp3/alertMedium.mp3',
            alertLow: 'sounds/mp3/alertLow.mp3'
        };

    private static readonly SequentialAudioPlayer =
        {
            _lastAudioObj: null,

            play: function (url) {
                if (this.lastAudioObj) {
                    this.lastAudioObj.pause();
                    this.lastAudioObj = null;
                }
                this.lastAudioObj = new Audio(url);
                this.lastAudioObj.autoplay = true;
            }
        };

    private static IsSoundEnableByKey (key): boolean {
        const settings = GeneralSettings.Sound;
        const settingByKey = settings['EnableSound_' + key];

        if (settingByKey === undefined || Resources.isHidden('property.DisableStartEndSounds')) {
            return true;
        } // такой настройки нет, значит её нельзя скрыть отдельно -> подчиняется только скрытию через EnableSounds

        return settingByKey;
    }

    public static readonly SoundKeys =
        {
            Welcome: 'welcome',
            Warning: 'warning',
            PostionClose: 'positionClose',
            OrderReplaced: 'orderReplaced',
            OrderRejected: 'orderRejected',
            OrderCreated: 'orderCreated',
            OrderFilled: 'orderFilled',
            OrderCanceled: 'orderCanceled',
            Modify: 'modify',
            News: 'news',
            MarginWarning: 'marginWarning',
            MarginCall: 'marginCall',
            ConnectionLost: 'conectionLost',
            Confirmation: 'confirmation',
            Lock: 'lock',
            Unlock: 'unlock',
            Finish: 'finish',
            AlertHigh: 'alertHigh',
            AlertMedium: 'alertMedium',
            AlertLow: 'alertLow'
        };

    public static tryPlaySound (key, forcePlay = false): void {
        if (forcePlay || GeneralSettings.Sound.EnableSounds) {
            if (this.IsSoundEnableByKey(key)) // #92803 звуки login-а и logout-а можно скрыть настройкой DisableStartEndSounds
            {
                this.SequentialAudioPlayer.play(this.SoundDict[key]);
            }
        }
    }
}
