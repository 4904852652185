// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
export enum StopOutType {
    // имена дебильные специально - это ключи локализации
    // #41858, changed by tsiganoff

    enum_StopOutType_FIFO = 0,
    enum_StopOutType_CloseAllPositions = 1,
    enum_StopOutType_LIFO = 2,
    enum_StopOutType_LargestFirst = 3,
    enum_StopOutType_DoNotClosePositions = 4,
    enum_StopOutType_ClosePositionsByMarginExcess = 5,
    enum_StopOutType_AutoClose = 6,
    enum_StopOutType_CloseAllByInstruments = 7,
    enum_StopOutType_CloseLagestLoss = 8,
    enum_StopOutType_ExternalLossLimit = 11
}

export const StopOutTypeMap = (function () {
    const result = {};

    const k = Object.keys(StopOutType);
    for (let i = 0; i < k.length; i++) {
        result[StopOutType[k[i]]] = k[i];
    }

    return result;
})();
