// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { TerceraDatePickerCalendarComponentTemplate } from "../../../templates.js";
import { Resources } from "../../../Commons/properties/Resources.ts";
import { Control } from "../Control.js";

export let TerceraDatePickerCalendarComponent = Control.extend({
    template: TerceraDatePickerCalendarComponentTemplate,
    data: function ()
    {
        return {
            visible: true,
        };
    },
    picker: null
});

TerceraDatePickerCalendarComponent.prototype.oninit = function ()
{
    Control.prototype.oninit.apply(this);
    this.onDateChanged = this.onDateChanged.bind(this);
};


TerceraDatePickerCalendarComponent.prototype.oncomplete = function ()
{
    Control.prototype.oncomplete.apply(this);

    this.picker = $(this.find('div')).datepicker({
        prevHtml: ' ',
        nextHtml: ' ',
        maxDate: new Date(),
        onSelect: this.onDateChanged,
        toggleSelected: false
    }).data('datepicker');

    this.localize();

    this.picker.show();
};

TerceraDatePickerCalendarComponent.prototype.onDateChanged = function (formattedDate, date, inst)
{
    if (this.callback) this.callback(date);
    //this.Hide();
};

TerceraDatePickerCalendarComponent.prototype.localize = function ()
{
    if (!this.picker) return;
    this.picker.update('language',
        {
            days: [
                Resources.getResource('general.Sunday'),
                Resources.getResource('general.Monday'),
                Resources.getResource('general.Tuesday'),
                Resources.getResource('general.Wednesday'),
                Resources.getResource('general.Thursday'),
                Resources.getResource('general.Friday'),
                Resources.getResource('general.Saturday')
            ],
            daysShort: [
                Resources.getResource('general.day7.Su'),
                Resources.getResource('general.day1.Mo'),
                Resources.getResource('general.day2.Tu'),
                Resources.getResource('general.day3.We'),
                Resources.getResource('general.day4.Th'),
                Resources.getResource('general.day5.Fr'),
                Resources.getResource('general.day6.Sa')
            ],
            daysMin: [
                Resources.getResource('general.day7.Su'),
                Resources.getResource('general.day1.Mo'),
                Resources.getResource('general.day2.Tu'),
                Resources.getResource('general.day3.We'),
                Resources.getResource('general.day4.Th'),
                Resources.getResource('general.day5.Fr'),
                Resources.getResource('general.day6.Sa')
            ],
            months: [
                Resources.getResource('general.monthFullName.1'),
                Resources.getResource('general.monthFullName.2'),
                Resources.getResource('general.monthFullName.3'),
                Resources.getResource('general.monthFullName.4'),
                Resources.getResource('general.monthFullName.5'),
                Resources.getResource('general.monthFullName.6'),
                Resources.getResource('general.monthFullName.7'),
                Resources.getResource('general.monthFullName.8'),
                Resources.getResource('general.monthFullName.9'),
                Resources.getResource('general.monthFullName.10'),
                Resources.getResource('general.monthFullName.11'),
                Resources.getResource('general.monthFullName.12')
            ],
            monthsShort: [
                Resources.getResource('general.month.1'),
                Resources.getResource('general.month.2'),
                Resources.getResource('general.month.3'),
                Resources.getResource('general.month.4'),
                Resources.getResource('general.month.5'),
                Resources.getResource('general.month.6'),
                Resources.getResource('general.month.7'),
                Resources.getResource('general.month.8'),
                Resources.getResource('general.month.9'),
                Resources.getResource('general.month.10'),
                Resources.getResource('general.month.11'),
                Resources.getResource('general.month.12')
            ],
            dateFormat: 'dd.mm.yyyy',
            firstDay: 0
        });
};

TerceraDatePickerCalendarComponent.prototype.getType = function () { return 'TerceraDatePickerCalendarComponent' }