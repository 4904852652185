// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
/* Контроль и оповешение наличия торговых нумериков с ошибкой (https://tp.traderevolution.com/entity/98089 появился в связи с возможностью отправить ордер с пустыми торговыми нумериками (которые используют предыдущее валидное значение) )
                          |
       Вызов строчки ниже v вернет true и вызовет отображения окна с errorText в случае наличия торговых нумериков с hasError = true,enabled=true  */
// TradingNumericErrorChecker.HasErrors(parent /*родительский контрол нумерики которого надо проверить в .Controls*/)
/*      Для оптимизации работы подписывается на TerceraNumeric.OnHasErrorsChanged запоминая были ли в нумериках ошибки, а также были ли они позже исправлены, подсчитывает общее количество неисправленных ошибок для каждой родительской панели где случались ошибки
 Для использования вызвать TradingNumericErrorChecker.HasErrors(<родительский контрол нумерики которого надо проверить>) перед попыткой отправить ордер */

import { messageBoxHandler, terceraNumericHandler } from '../../Utils/AppHandlers.js';
import { Resources } from '../properties/Resources';

export class _TradingNumericErrorChecker {
    public errorsNumber = 0; // общее кол-во неисправленных ошибок - если ноль при проверке, сразу выходим (иначе могут быть варианты: панель закрыли, другая панель, другой врк и т.д)
    public cachedTradingNumericsWithErrors: any = {}; // запоминаем нумерики в которых были допущены ошибки
    public cachedByContainerID: Record<string, number> = {}; // для быстрого доступа кол-во неисправностей по guid контейнера

    constructor () {
        terceraNumericHandler.OnHasErrorsChanged.Subscribe(this.OnHasErrorsChanged, this);
    }

    public OnHasErrorsChanged (numeric, hasError): void // процесс кэширования нумериков с ошибкой или ее исправлением
    {
        if (!numeric?._guid) {
            return;
        }

        const id = numeric._guid;
        const parent = _TradingNumericErrorChecker.getZeroParentByNumeric(numeric);
        const parentID = parent._guid;

        if (hasError) {
            if (!this.cachedTradingNumericsWithErrors[id]) {
                if (!this.cachedByContainerID[parentID]) {
                    this.cachedByContainerID[parentID] = 0;
                }

                this.cachedByContainerID[parentID]++; // увеличиваем общее кол-во неисправленных ошибок по панеле (контейнеру содержащиму нумерик)
                this.cachedTradingNumericsWithErrors[id] = numeric; // запоминаем нумерик с ошибкой + parent._guid
            }
        } else if (this.cachedTradingNumericsWithErrors[id]) {
            this.cachedTradingNumericsWithErrors[id] = null; // нумерик с ошибкой исправился
            this.cachedByContainerID[parentID]--;
        }
    }

    public HasErrors (parent): boolean {
        const zeroParent = _TradingNumericErrorChecker.getZeroParentByNumeric(parent);
        const parentID = zeroParent._guid;
        const hasErrorsInThisContainer = this.cachedByContainerID[parentID] > 0;

        if (!hasErrorsInThisContainer) // если неисправленных ошибок нет - сразу выходим
        {
            return false;
        }

        for (const id in this.cachedTradingNumericsWithErrors) {
            const numeric = this.cachedTradingNumericsWithErrors[id];
            const par = _TradingNumericErrorChecker.getZeroParentByNumeric(numeric);
            const parID = par ? par._guid : null;
            if (parID == parentID && numeric.get('hasError') && numeric.get('enabled')) {
                const errorText = numeric.get('customErrorText') || numeric.get('errorText');

                messageBoxHandler.Show(Resources.getResource('screen.error.title'), errorText, messageBoxHandler.msgType.Error, null, null, false, true);
                return true;
            }
        }

        return false;
    }

    public static getZeroParentByNumeric (control): any {
        if (!control) return null;
        const parent = control.parent;

        if (!parent) {
            return control;
        }

        return _TradingNumericErrorChecker.getZeroParentByNumeric(parent);
    }
}

export const TradingNumericErrorChecker = new _TradingNumericErrorChecker();
