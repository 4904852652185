// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from "../../Utils/CustomEvents.ts";
import { TerceraFontPickerTemplate } from "../../templates.js";
import { ContainerControl } from "./ContainerControl.js";

export let TerceraFontPicker = ContainerControl.extend({
    data: function ()
    {
        return {
            font: null,
            height: 20
        };
    },

    computed: {
        bold: {
            get: function ()
            {
                var font = this.get('font');
                return font ? font.FontWeight === 'bold' : false;
            },
            set: function (bold)
            {
                if (this.get('font'))
                    this.set('font.FontWeight', bold ? 'bold' : '');
            },
        }
    },

    template: TerceraFontPickerTemplate
});

TerceraFontPicker.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.apply(this);
    this.FontChanged = new CustomEvent();
    this.observe('font', this.onFontChanged);
};

TerceraFontPicker.prototype.onFontChanged = function (newFont)
{
    this.FontChanged.Raise(this, newFont);
};