// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectReplaceAlertMessage } from '../../../../Utils/DirectMessages/DirectReplaceAlertMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class ReplaceAlertMessageProcessor extends MessageProcessorBase {
    public override decode (fs: PFixFieldSet): DirectReplaceAlertMessage[] {
        const msg = new DirectReplaceAlertMessage();

        msg.AlertId = fs.GetValue(FieldsFactory.FIELD_ALERT_ID);
        msg.InstrumentTradableID = fs.GetValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID);
        msg.RouteId = fs.GetValue(FieldsFactory.FIELD_ROUTE_ID);
        msg.AlertType = fs.GetValue(FieldsFactory.FIELD_ALERT_TYPE);
        msg.AlertCondition = fs.GetValue(FieldsFactory.FIELD_ALERT_CONDITION);
        msg.AlertValue = fs.GetValue(FieldsFactory.FIELD_ALERT_VALUE);
        msg.AlertImportance = fs.GetValue(FieldsFactory.FIELD_ALERT_IMPORTANCE);
        msg.Date = fs.GetValue(FieldsFactory.FIELD_DATE);
        msg.AlertAfterExecute = fs.GetValue(FieldsFactory.FIELD_ALERT_AFTER_EXECUTE);
        msg.AlertAction = fs.GetValue(FieldsFactory.FIELD_ALERT_ACTION);
        msg.AlertNotification = fs.GetValue(FieldsFactory.FIELD_ALERT_NOTIFICATION);
        msg.Text = fs.GetValue(FieldsFactory.FIELD_TEXT);

        return [msg];
    }
}
