// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Rectangle } from "../../../Commons/Geometry.ts";
import { datePickerDropDownHandler } from "../../../Utils/AppHandlers.js";
import { TerceraDatePickerDropDownTemplate } from "../../../templates.js";
import { Resources } from "../../../Commons/properties/Resources.ts";
import { ContainerControl } from "../ContainerControl.js";
import { TerceraMenu } from "../TerceraMenu.ts";

let datePickerDropDown = datePickerDropDownHandler

export let TerceraDatePickerDropDown = ContainerControl.extend({
    el: '#datePickerDropDownContainer',
    template: TerceraDatePickerDropDownTemplate,
    data: function () { return { visible: false }; },
    calendar: null
});

TerceraDatePickerDropDown.prototype.oncomplete = function ()
{
    ContainerControl.prototype.oncomplete.apply(this);
    this.onDateChanged = this.onDateChanged.bind(this);

    this.calendar = this.Controls.dtpicker;
};

TerceraDatePickerDropDown.prototype.onDateChanged = function (formattedDate, date, inst)
{
    if (this.callback) this.callback(date);
    this.Hide();
};

TerceraDatePickerDropDown.prototype.Show = function (date, callback, x, y, additionalParams)
{
    var picker = this.calendar.picker;

    picker.view = 'days';

    var dateCpy = new Date();
    dateCpy.setTime(date.getTime());

    picker.date = dateCpy;
    picker.selectDate(dateCpy);

    picker.update('minDate',
        additionalParams && additionalParams.minDate ?
            additionalParams.minDate : '');

    picker.update('maxDate',
        additionalParams && additionalParams.maxDate ?
            additionalParams.maxDate : '');

    this.callback = callback;
    picker.update('onSelect', this.onDateChanged);

    picker.show();

    var newCoord = TerceraMenu.CorrectPopupLocation(new Rectangle(x, y, 200, 200), null);

    this.set({
        left: newCoord.newX,
        top: newCoord.newY,
        visible: true
    });
};

TerceraDatePickerDropDown.prototype.localize = function ()
{
    if (!this.calendar) return;
    this.calendar.picker.update('language',
        {
            days: [
                Resources.getResource('general.Sunday'),
                Resources.getResource('general.Monday'),
                Resources.getResource('general.Tuesday'),
                Resources.getResource('general.Wednesday'),
                Resources.getResource('general.Thursday'),
                Resources.getResource('general.Friday'),
                Resources.getResource('general.Saturday')
            ],
            daysShort: [
                Resources.getResource('general.day7.Su'),
                Resources.getResource('general.day1.Mo'),
                Resources.getResource('general.day2.Tu'),
                Resources.getResource('general.day3.We'),
                Resources.getResource('general.day4.Th'),
                Resources.getResource('general.day5.Fr'),
                Resources.getResource('general.day6.Sa')
            ],
            daysMin: [
                Resources.getResource('general.day7.Su'),
                Resources.getResource('general.day1.Mo'),
                Resources.getResource('general.day2.Tu'),
                Resources.getResource('general.day3.We'),
                Resources.getResource('general.day4.Th'),
                Resources.getResource('general.day5.Fr'),
                Resources.getResource('general.day6.Sa')
            ],
            months: [
                Resources.getResource('general.monthFullName.1'),
                Resources.getResource('general.monthFullName.2'),
                Resources.getResource('general.monthFullName.3'),
                Resources.getResource('general.monthFullName.4'),
                Resources.getResource('general.monthFullName.5'),
                Resources.getResource('general.monthFullName.6'),
                Resources.getResource('general.monthFullName.7'),
                Resources.getResource('general.monthFullName.8'),
                Resources.getResource('general.monthFullName.9'),
                Resources.getResource('general.monthFullName.10'),
                Resources.getResource('general.monthFullName.11'),
                Resources.getResource('general.monthFullName.12')
            ],
            monthsShort: [
                Resources.getResource('general.month.1'),
                Resources.getResource('general.month.2'),
                Resources.getResource('general.month.3'),
                Resources.getResource('general.month.4'),
                Resources.getResource('general.month.5'),
                Resources.getResource('general.month.6'),
                Resources.getResource('general.month.7'),
                Resources.getResource('general.month.8'),
                Resources.getResource('general.month.9'),
                Resources.getResource('general.month.10'),
                Resources.getResource('general.month.11'),
                Resources.getResource('general.month.12')
            ],
            dateFormat: 'dd.mm.yyyy',
            firstDay: 1
        });
};

TerceraDatePickerDropDown.prototype.Hide = function ()
{
    this.set('visible', false);
    this.calendar.picker.update('onSelect', null);
    delete this.callback;
    this.calendar.picker.hide();
};

TerceraDatePickerDropDown.prototype.getType = function () { return 'TerceraDatePickerDropDown' };

datePickerDropDownHandler.Show = function (date, callback, x, y, additionalParams) { }
datePickerDropDown.get = () => { return false }
datePickerDropDown.Hide = () => { return false }
datePickerDropDown.localize = () => { return false }
datePickerDropDown.Init = () =>
{
    let _datePickerDropDown = new TerceraDatePickerDropDown()
    datePickerDropDown.get = (key) => { return _datePickerDropDown.get(key) }
    datePickerDropDown.Hide = _datePickerDropDown.Hide.bind(_datePickerDropDown)
    datePickerDropDown.Show = _datePickerDropDown.Show.bind(_datePickerDropDown)
    datePickerDropDown.localize = _datePickerDropDown.localize.bind(_datePickerDropDown)
}