// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { InputParameterBase } from './InputParameterBase';
import { InputParameterType } from './InputParameterEnum';

export class InputParameterDouble extends InputParameterBase {
    public Minimum: number;
    public Maximum: number;
    public Precision: number;
    public Step: number;

    constructor (variableName = 'Empty', comment = '', inputNumber = 0, minimum = 1, maximum = 9999, precision = 0, step = 1) {
        super(variableName, InputParameterType.DOUBLE, comment, inputNumber);
        this.Minimum = minimum;
        this.Maximum = maximum;
        this.Precision = precision;
        this.Step = step;
    }
}
