// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type PFixFieldSet } from '../Fields/FieldsImport';
import { PfixOneTimePasswordMessage } from '../Messages/PfixOneTimePasswordMessage';
import { MessageProcessorBase } from './MessageProcessorBase';

export class OneTimePasswordMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): PfixOneTimePasswordMessage[] {
        const msg = new PfixOneTimePasswordMessage(fieldSet);
        return [msg];
    }
}
