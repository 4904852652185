// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Point } from '../../Commons/Geometry';
import { ChartMath } from '../Utils/ChartMath';
import { TerceraChartCashItemSeriesDataType } from '../Series/TerceraChartCashItemSeriesEnums';
import { ToolView } from './ToolView';
import { type TerceraChartCashItemSeries } from '../Series/TerceraChartCashItemSeries';
import { type DataCacheTool } from '../../Commons/cache/DataCacheTool';

export class CurveToolView<TDataCacheTool extends DataCacheTool> extends ToolView<TDataCacheTool> {
    public lastCurvePoints: Point[];

    public override UpdateScreenPoints (ww, cashItemSeries: TerceraChartCashItemSeries): void {
        const dataCacheTool = this.dataCacheTool;
        //
        // callback need recalculate points
        //
        if (dataCacheTool.forceUpdateAdditionalPoints) {
            dataCacheTool.forceUpdateAdditionalPoints = false;
            this.UpdateAdditionalPoints(ww, -1, cashItemSeries);
        }
        this.UpdateScreenPointsALL(ww, cashItemSeries, false);

        const dataType = cashItemSeries?.settings
            ? cashItemSeries.settings.DataType
            : TerceraChartCashItemSeriesDataType.Absolute;

        const tmp = [];
        const pc = ww.PointsConverter;

        const scrPoints_len = this.screenPoints.length;
        for (let i = dataCacheTool.PointLevel(); i < scrPoints_len; i++) {
            const p = dataCacheTool.Points[i];
            const x = pc.GetScreenXbyTime(p[0]);
            let y = 0;
            switch (dataType) {
            case TerceraChartCashItemSeriesDataType.Absolute:
                y = pc.GetScreenY(p[1]);
                break;

            case TerceraChartCashItemSeriesDataType.Relative:
                y = pc.GetScreenY(cashItemSeries.settings.relativeDataConverter.Calculate(p[1]));
                break;
            case TerceraChartCashItemSeriesDataType.Log:
                y = pc.GetScreenY(cashItemSeries.settings.logDataConverter.Calculate(p[1]));
                break;
            }

            tmp.push(new Point(x, y));
        }

        this.lastCurvePoints = tmp;
    }

    public override IsSelectCheck (x: number, y: number): boolean {
        const lastCurvePoints = this.lastCurvePoints;
        if (lastCurvePoints) {
            const TOOL_DX = ToolView.TOOL_DX;
            const len = lastCurvePoints.length;
            // весь контур
            for (let i = 1; i < len; i++) {
                if (ChartMath.CalcDistanceSqrFromPointToSection([lastCurvePoints[i - 1].X, lastCurvePoints[i - 1].Y], [lastCurvePoints[i].X, lastCurvePoints[i].Y], x, y) <= TOOL_DX) {
                    return true;
                }
            }
        }

        return super.IsSelectCheck(x, y);
    }
}
