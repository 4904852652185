// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

class _TerceraLinkControlConstants {
    public STATE_NONE: number;
    public STATE_ALL: number;
    public LinkDataArray: Array<{ constKey: string, value: number, style: string, textKey: string }>;
    public ColorConstants: any;

    constructor () {
        this.STATE_NONE = -1;
        this.STATE_ALL = -2;

        this.LinkDataArray = [
            { constKey: 'NONE', value: this.STATE_NONE, style: 'js-link-color-none', textKey: 'ribbon.linking.linkEmpty' },
            { constKey: 'YELLOW', value: 3, style: 'js-link-color-yellow', textKey: 'ribbon.linking.linkYellow' },
            { constKey: 'RED', value: 1, style: 'js-link-color-red', textKey: 'ribbon.linking.linkRed' },
            { constKey: 'GREEN', value: 4, style: 'js-link-color-green', textKey: 'ribbon.linking.linkGreen' },
            { constKey: 'ORANGE', value: 2, style: 'js-link-color-orange', textKey: 'ribbon.linking.linkOrange' },
            { constKey: 'BLUE', value: 5, style: 'js-link-color-blue', textKey: 'ribbon.linking.linkBlue' },
            { constKey: 'PURPLE', value: 6, style: 'js-link-color-purpure', textKey: 'ribbon.linking.linkPurple' }
        ];

        this.ColorConstants = this.GetColorConstants(this.LinkDataArray);
        Object.freeze(this.ColorConstants);
    }

    private GetColorConstants (linkDataArray): any {
        const result = {};
        const len = linkDataArray.length;
        for (let i = 0; i < len; i++) {
            const curData = linkDataArray[i];
            result[curData.constKey] = curData.value;
        }
        return result;
    }
}

export const TerceraLinkControlConstants = new _TerceraLinkControlConstants();
