// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectQuote2Message } from '../../../../Utils/DirectMessages/DirectQuote2Message';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class Quote2MessageProcessor extends MessageProcessorBase {
    public decoder: any;

    constructor (parentDecoder) {
        super();

        this.decoder = parentDecoder;
    }

    public override decode (fieldSet: PFixFieldSet): DirectQuote2Message[] {
        const message = new DirectQuote2Message();

        // bstrSymbol
        const tickId = fieldSet.GetValue(FieldsFactory.FIELD_INSTRUMENT_ID);
        const symDecodeMap = this.decoder.InstrumentDecodeMap;
        message.Symbol = symDecodeMap[tickId] || '';

        const routeId = fieldSet.GetValue(FieldsFactory.FIELD_ROUTE_ID);
        message.Route = routeId;// decoder.GetRouteById(routeId)

        message.Price = fieldSet.GetValue(FieldsFactory.FIELD_PRICE);
        message.Side = fieldSet.GetValue(FieldsFactory.FIELD_SIDE);

        const fieldVolume = fieldSet.GetValue(FieldsFactory.FIELD_VOLUME);
        message.Size = fieldVolume === null
            ? fieldSet.GetValue(FieldsFactory.FIELD_SIZE)
            : fieldVolume;

        const numberOfOrders = fieldSet.GetValue(FieldsFactory.FIELD_NUMBERS_OF_ORDERS);
        message.NumberOfOrders = numberOfOrders || 0;

        const sourceId = fieldSet.GetValue(FieldsFactory.FIELD_SOURCE_ID);
        message.Source = sourceId === null
            ? fieldSet.GetValue(FieldsFactory.FIELD_SOURCE)
            : this.decoder.GetSourceById(routeId, sourceId);

        message.cTime = fieldSet.GetValue(FieldsFactory.FIELD_DATE);
        message.MMID = fieldSet.GetValue(FieldsFactory.FIELD_QUOTE_ID);

        // bClosed
        // #55803: клиент должен опеределять сообщение как удаляющее котировку,
        // если в нем нет time, price and size.
        // IsClosed больше не приходит
        message.Closed =
        !fieldSet.IsSetField(FieldsFactory.FIELD_SIZE) &&
        !fieldSet.IsSetField(FieldsFactory.FIELD_PRICE) &&
        !fieldSet.IsSetField(FieldsFactory.FIELD_DATE);

        message.InstrumentTradableID = fieldSet.GetValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID);

        return [message];
    }
}
