// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from '../../Utils/CustomEvents';
import { ErrorInformationStorage } from '../../Commons/ErrorInformationStorage';
import { TimeSpanPeriods } from '../../Utils/Time/TimeSpan';
import { TerceraChartCashItemSeriesCacheScreenDataDateChangeType } from '../Series/TerceraChartCashItemSeriesEnums';
import { ChartDataType } from '../Utils/ChartConstants';
import { type ISeries } from './ISeries';
import { Periods } from '../../Utils/History/TFInfo';
import { TradingSessionBase } from '../../Utils/Session/Sessions';
import { BaseInterval } from '../../Utils/History/BaseInterval';
import { CashItem } from '../../Commons/cache/History/CashItem';
import { DateTimeConvertor } from '../../Utils/Time/DateTimeConvertor';
import { type TerceraChartCashItemSeriesSettings } from './TerceraChartCashItemSeriesSettings';
import { type Instrument } from '../../Commons/cache/Instrument';

/// <summary>
/// Серия данных на основе кешитема (аналог DataExtender)
/// Назначение:
/// - индексация баров с учётом опции "Показывать дырки"; RecalcIndices(), FIndexList
/// - кеширование данных, отображаемых в текущий момент на экране. CacheScreenData()
/// </summary>

export class TerceraChartCashItemSeries implements ISeries {
    public _cashItem: CashItem | null = null;

    public _TicksInOneBar = 1;

    public FIndexList = [];

    /// <summary>
    /// Расчитанное время для дырок
    /// </summary>
    public ZeroTimes = [];

    /// <summary>
    /// Временные границы данных. (UTC)
    /// </summary>
    public From = null;
    public To = null;

    /// <summary>
    /// Вспомогательные
    /// </summary>
    public _DayBasedData = false;
    public NonLinearTimeline = false;
    public RelativePeriodBar = 1;
    public _BarDuration = 1;
    public LeftRelativeBorder = 0;
    public RightRelativeBorder = 0;
    public RealLeftRelativeBorderForPaddingBars = 0;
    public IsCustomRange = false;
    public dataBlocks = []; // List<TerceraChartCashItemSeriesDataBlock>();

    /// <summary>
    /// Кешированные данные по текущему отображаемому экрану
    /// </summary>
    public ChartScreenData = null;// = new TerceraChartCashItemSeriesCacheScreenDataStorage();

    public settings = null; // TerceraChartCashItemSeriesSettings

    public SyncronizedSessionFlag = null; // #100139

    public HistoryExpanded = new CustomEvent();

    public paddingBarsCount = 0;

    constructor (newCashItem: CashItem, from: Date, to: Date, settings: TerceraChartCashItemSeriesSettings) {
        this.settings = settings;
        this.From = from;
        this.To = to;

        this.CashItem = newCashItem;
        this.ChartScreenData = new TerceraChartCashItemSeriesCacheScreenDataStorage();
    }

    get CashItem (): CashItem {
        return this._cashItem;
    }

    set CashItem (value) {
        // unsubscribe
        if (this._cashItem != null) {
            this._cashItem.HistoryExpanded.UnSubscribe(this.value_HistoryExpanded, this);
            this._cashItem.HistoryReload.UnSubscribe(this.cashItem_HistoryReload, this);
            this._cashItem.QuoteProcessed.UnSubscribe(this.cashItem_QuoteProcessed, this);
        }

        this._cashItem = value;

        // subscribe
        if (this._cashItem != null) {
            this._cashItem.HistoryExpanded.Subscribe(this.value_HistoryExpanded, this);
            this._cashItem.HistoryReload.Subscribe(this.cashItem_HistoryReload, this);
            this._cashItem.QuoteProcessed.Subscribe(this.cashItem_QuoteProcessed, this);
        }

        if (this._cashItem != null) {
            this.RelativePeriodBar = BaseInterval.GetIntervalLength(this._cashItem.FPeriod);
            this._BarDuration = this.GetBarDuration(this._cashItem);
            this.NonLinearTimeline = this.RelativePeriodBar == 0 || (this._cashItem.ChartDataType != ChartDataType.Default) || this._cashItem.FPeriod % Periods.RANGE == 0;
            this._DayBasedData = !this.NonLinearTimeline && this._cashItem.FPeriod >= Periods.DAY && (this._cashItem.FPeriod % Periods.SECOND != 0);

            //
            this.RecalcIndices();
        }

        if (this._cashItem == null || this._cashItem.FPeriod === Periods.TIC) {
            this._TicksInOneBar = 1;
        } else {
            this._TicksInOneBar = value.FPeriod * 60 * TimeSpanPeriods.TicksPerSecond;
        }
    }

    // #region CashItem events
    public value_HistoryExpanded (cashItem, oldLength, newLength): void {
        this.RecalcIndices();

        this.HistoryExpanded.Raise(true);
    }

    public cashItem_HistoryReload (sender): void {
        this.RecalcIndices();
    }

    public cashItem_QuoteProcessed (cashItem, quote): void {
        this.HistoryExpanded.Raise(false);
    }

    // #endregion

    public Count (): number {
        return this.FIndexList.length;
    }

    public RecalcIndices (): void {
        try {
        //
        // Свои расчёты
        //
        // if (cashItem is CashItemFile)
        // {
        //    RecalcIndicesForFileCashItem();
        //    return;
        // }

            const bufferList = [];// new List<int>();
            const bufferZero = [];// new List<long>();
            bufferZero.push(0);
            const bufferBlocks = [];// new List<TerceraChartCashItemSeriesDataBlock>();
            let leftBorder = 0;
            let rightBorder = 0;
            const maxBarsCount = TerceraChartCashItemSeries.GetMaxZeroBars(this._cashItem);

            const startTicks = this.From.getTime();
            const endTicks = this.To.getTime();

            /// /
            /// / Понадобилось в алголстудии, подумть
            /// / корректируем date-интервал
            /// /
            // Utils.ProcessDateInterval(ref startTicks, ref endTicks, cashItem.Symbol, cashItem, null, false, false);

            const bi = this._cashItem.GetInterval(0);
            const barLength = (bi != null) ? bi.FRightTimeTicks - bi.FLeftTimeTicks : 0;
            let curBlock = null;
            let addedIndex = 0;
            const ins = this._cashItem.Instrument;
            const period = this._cashItem.FPeriod;

            const paddingBarsCount = this.paddingBarsCount;

            // insert fake bars for autoload history
            if (paddingBarsCount > 0) {
                const fakeBlock = new TerceraChartCashItemSeriesDataBlock(0, 0);
                bufferBlocks.push(fakeBlock);

                for (let i = 0; i < paddingBarsCount; i++) {
                    bufferList.push(FAKE_PADDING_BAR_INDEX);
                }
                addedIndex += paddingBarsCount;
            }

            //
            // Показывать дырки
            //
            if (this.settings.ShowEmptyBars) {
            // #region
                let prevBi = null;
                const array = this._cashItem.FNonEmptyCashArray;
                let i;
                const len = array.length;
                for (i = 0; i < len; i++) {
                    const curBi = array[i];

                    // Проверка верменного диапазона
                    if (curBi.FLeftTimeTicks < startTicks || curBi.FLeftTimeTicks >= endTicks && this.IsCustomRange) {
                        continue;
                    }

                    // Remember time borders
                    if (leftBorder == 0) {
                        leftBorder = curBi.FLeftTimeTicks;
                    }

                    rightBorder = curBi.FRightTimeTicks;

                    // Дырка?
                    if (prevBi != null && prevBi.FRightTimeTicks < curBi.FLeftTimeTicks) {
                        const zeroes = prevBi.CalculateNextInterv(curBi.LeftTime, 0, period);

                        // Слишком большие дырки не добавляем
                        const controlBigHole = true;
                        if (zeroes < maxBarsCount || !controlBigHole) {
                            if (zeroes > 0) {
                                curBlock = new TerceraChartCashItemSeriesDataBlock(addedIndex, curBi.FLeftTimeTicks);
                                bufferBlocks.push(curBlock);
                                let j;
                                for (j = 0; j < zeroes; j++) {
                                    const zeroBarTime = prevBi.FRightTimeTicks + j * barLength;

                                    bufferZero.push(zeroBarTime);
                                    bufferList.push((bufferZero.length - 1) * -1);
                                    addedIndex++;
                                }
                                curBlock = null;
                            } else if (this._DayBasedData) {
                            // Create block for hole
                                curBlock = new TerceraChartCashItemSeriesDataBlock(addedIndex - 1, prevBi.FRightTimeTicks);
                                curBlock.IsHole = true;
                                bufferBlocks.push(curBlock);
                                curBlock = null;
                            }
                        } else {
                        //
                        // http://tp.pfsoft.lan/Project/QA/Bug/View.aspx?BugID=19540&acid=74FA48A38CBEE17F6445F76C9BEC721B ???
                        //
                        // Create block for hole
                        // curBlock = new DataBlock(addedIndex-1, prevBi.FRightTimeTicks);
                            curBlock = new TerceraChartCashItemSeriesDataBlock(addedIndex, prevBi.FRightTimeTicks);
                            curBlock.IsHole = true;
                            bufferBlocks.push(curBlock);
                            curBlock = null;
                        }
                    }
                    // В нахлёст бары лежат - начинаем новый блок
                    else if (this._DayBasedData && prevBi != null && prevBi.FRightTimeTicks > curBi.FLeftTimeTicks) {
                        curBlock = null;
                    }

                    // Create new block for data
                    if (curBlock == null) {
                        curBlock = new TerceraChartCashItemSeriesDataBlock(addedIndex, curBi.FLeftTimeTicks);
                        bufferBlocks.push(curBlock);
                    }

                    bufferList.push(i);
                    addedIndex++;
                    prevBi = curBi;
                }
            // #endregion
            }
            //
            // Без дырок
            //
            else {
                let prevBi = null;
                const array = this._cashItem.FNonEmptyCashArray;

                let i;
                const len = array.length;
                for (i = 0; i < len; i++) {
                    const curBi = array[i];

                    // Проверка верменного диапазона
                    // if (curBi.FLeftTimeTicks < startTicks || (curBi.FLeftTimeTicks >= endTicks && this.IsCustomRange))
                    //    continue;

                    // Remember time borders
                    if (leftBorder == 0) {
                        leftBorder = curBi.FLeftTimeTicks;
                    }

                    rightBorder = curBi.FRightTimeTicks;

                    // Дырка?
                    if (prevBi != null && prevBi.FRightTimeTicks < curBi.FLeftTimeTicks && this.RelativePeriodBar != 0) {
                    // Create block for hole
                        curBlock = new TerceraChartCashItemSeriesDataBlock(addedIndex - 1, prevBi.FRightTimeTicks);
                        curBlock.IsHole = true;
                        bufferBlocks.push(curBlock);
                        curBlock = null;
                    }
                    // В нахлёст бары лежат - начинаем новый блок
                    else if (this._DayBasedData && prevBi != null && prevBi.FRightTimeTicks > curBi.FLeftTimeTicks) {
                        curBlock = null;
                    }

                    // Create new block for data
                    if (curBlock == null) {
                        curBlock = new TerceraChartCashItemSeriesDataBlock(addedIndex, curBi.FLeftTimeTicks);
                        bufferBlocks.push(curBlock);
                    }

                    bufferList.push(i);
                    addedIndex++;
                    prevBi = curBi;
                }
            }

            if (paddingBarsCount > 0) {
                this.RealLeftRelativeBorderForPaddingBars = leftBorder;
                const fakeTimeDuration = paddingBarsCount * this._BarDuration;
                const fakeLeftBorder = leftBorder - fakeTimeDuration;
                bufferBlocks[0].LeftTime = fakeLeftBorder;
                leftBorder = fakeLeftBorder;
            }

            //
            // Сначала во временный массив, иначе лажает иногда на промежуточных этапах
            //
            this.FIndexList = bufferList;
            this.ZeroTimes = bufferZero;

            this.LeftRelativeBorder = leftBorder;
            this.RightRelativeBorder = rightBorder;
            this.dataBlocks = bufferBlocks;
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
        }
    }

    public GetValue (index: number, level: number): number {
        let res = 0;
        if (this._cashItem === null) {
            return res;
        }

        //
        // Обращение ко времени - дополнительная логика
        // для дырок, прошлого и будущего
        //
        if (level === CashItem.TIME_INDEX || level === CashItem.TIME_CLOSE_INDEX) {
            const length = this.FIndexList.length;
            // если это доступ к времени, то вычисляем время бара в будущем
            if (index < 0) {
                res = 0.0;
            } else if (index >= 0 && index < length) {
                index = this.GetIndex(index);
                if (index < 0) {
                    index *= -1;
                    return index < this.ZeroTimes.length ? this.ZeroTimes[index] : 0;
                } else {
                    res = this._cashItem.GetByConst(index, level);
                }
            } else {
                const lastIndex = this.GetIndex(length - 1);
                res = this._cashItem.GetByConst(lastIndex, level) + this.GetTime(index - length + 1);
            }
        } else {
            // Price
            index = this.GetIndex(index);
            if (index < 0) {
                res = 0;
            } else {
                res = this._cashItem.GetByConst(index, level);
            }
        }

        return res;
    }

    /// <summary>
    /// Получить реальный индекс в кеш итеме по индексу екстендера
    /// !!! Может быть отрицательным - значит там дырка!
    /// </summary>
    public GetIndex (index: number): number {
        return index >= 0 && index < this.FIndexList.length ? this.FIndexList[index] : -1;
    }

    /// <summary>
    /// Кешировать данные для указаного промежутка (обычно видимые на экране)
    /// </summary>
    public CacheScreenData (start, end, ins: Instrument): void {
        const _cashItem = this._cashItem;

        const period = _cashItem ? _cashItem.FPeriod : Periods.MIN;
        const dataType = _cashItem ? _cashItem.ChartDataType : ChartDataType.Default;
        // var info = (period == Periods.TIC) ? new VolumeInfo() : null;

        // TODO. log, relative scale.
        let dayHigh = _cashItem.dayHigh || 0;
        let dayLow = _cashItem.dayLow || Infinity;
        const tradingDaySessionStartLocalMillis = ins.CurrentTradingSession ? ins.CurrentTradingSession.ServerBeginDayDate.getTime() : null;

        const tempChartScreenData = new TerceraChartCashItemSeriesCacheScreenDataStorage();

        let YearFlag = false;
        let MonthFlag = false;

        // optimization: avoid DateTime.Get...() functions
        let lastCs = new TerceraChartCashItemSeriesCacheScreenData();

        const utcMillis = this.GetValue(start > 0 ? start - 1 : start, CashItem.TIME_INDEX);
        lastCs.DateTime = DateTimeConvertor.getLocalTimeQuick(utcMillis);

        lastCs.Year = lastCs.DateTime.getFullYear();
        lastCs.Day = lastCs.DateTime.getDate();
        lastCs.Hour = lastCs.DateTime.getHours();
        lastCs.Month = lastCs.DateTime.getMonth();
        // var lastDateTime = new Date(this.GetValue(start > 0 ? start - 1 : start, CashItem.TIME_INDEX));

        /// /
        /// / Обновлять надо до основного цикла, данные зависятот базового значения
        /// /
        // UpdateBasisValue(start, ins);

        const from = Math.max(0, start);

        // var subItemStartIndex = ProfileFindInterval(from);

        //    var customInstrSessions = false;
        //    var revertedSession = false; // значит начало > конца (например с 20:00 до 5:00)
        //    TimeSpan ts1 = TimeSpan.Zero, ts2 = TimeSpan.Zero;
        //    if (ins is InstrumentHistory && ((InstrumentHistory)ins).IsSessionTimeSettings)
        // {
        //                ts1 = ((InstrumentHistory)ins).BeginDayDate;
        //    ts2 = ((InstrumentHistory)ins).EndDayDate;
        //    customInstrSessions = ts1 != ts2;
        //    revertedSession = ts1 > ts2;
        // }

        for (let i = from; i <= end; i++) {
            const cs = new TerceraChartCashItemSeriesCacheScreenData();

            // IsMain session
            const biIndex = this.GetIndex(i);

            if (biIndex === FAKE_PADDING_BAR_INDEX) {
                continue;
            }

            cs.Time = this.GetValue(i, CashItem.TIME_INDEX);
            // mark barstoright hole
            if (i < 0) {
                cs.Hole = true;
            }

            // mark barstoright hole
            if (biIndex < 0) {
                cs.Hole = true;
            }

            cs.BaseIntervalIndex = biIndex;

            if (i < 0 || biIndex === -1) {
                cs.Hole = true;
            }

            // Add correct time for holes
            if (i < 0) {
                cs.Time = this.LeftRelativeBorder + i * this._BarDuration;
            } else if (i >= this.FIndexList.length) {
                cs.Time = this.RightRelativeBorder + (i - this.FIndexList.length) * this._BarDuration;
            }

            if (period == Periods.TIC && dataType == ChartDataType.Default) {
                cs.Open = this.GetValue(i, CashItem.OPEN_INDEX);
                cs.Close = this.GetValue(i, CashItem.CLOSE_INDEX);
                cs.Volume = this.GetValue(i, CashItem.VOLUME_INDEX);
            } else {
                cs.Open = this.GetValue(i, CashItem.OPEN_INDEX);
                cs.High = this.GetValue(i, CashItem.HIGH_INDEX);
                cs.Low = this.GetValue(i, CashItem.LOW_INDEX);
                cs.Close = this.GetValue(i, CashItem.CLOSE_INDEX);
                cs.Volume = this.GetValue(i, CashItem.VOLUME_INDEX);
            }

            cs.DateTime = DateTimeConvertor.getLocalTimeQuick(cs.Time);
            cs.Year = cs.DateTime.getFullYear();
            cs.Day = cs.DateTime.getDate();
            cs.Hour = cs.DateTime.getHours();
            cs.Month = cs.DateTime.getMonth();

            if (ins != null) {
                cs.DateTimeInSessionTimeZoneWithOffset =
                Periods.ConvertToDisplayedTimeForDayAggregations(
                    new Date(cs.Time),
                    ins,
                    period);
            } else {
                cs.DateTimeInSessionTimeZoneWithOffset = cs.DateTime;
            }

            //
            // Control min/max
            //
            let low = cs.Low;
            let high = cs.High;
            const isNotHole = !cs.Hole;
            if (period === Periods.TIC) {
                low = Math.min(cs.Open, cs.Close);
                high = Math.max(cs.Open, cs.Close);
            }

            if (isNotHole && low < dayLow && cs.Time >= tradingDaySessionStartLocalMillis) {
                dayLow = low;
                tempChartScreenData.MinDayLowIndex = i - from;
            }

            if (isNotHole && high > dayHigh && cs.Time >= tradingDaySessionStartLocalMillis) {
                dayHigh = high;
                tempChartScreenData.MaxDayHighIndex = i - from;
            }

            if (isNotHole && low < tempChartScreenData.MinLow) { tempChartScreenData.MinLow = low; }

            if (isNotHole && high > tempChartScreenData.MaxHigh) { tempChartScreenData.MaxHigh = high; }

            //
            // Change day/month/year
            //
            let daySeparate = cs.Separator;
            const isRange = period % Periods.RANGE == 0;

            // сервер сейчас криво даёт эту инфу, сами пока
            daySeparate = lastCs.Day != cs.Day;

            // Days separator
            if (period <= Periods.HOUR4 || isRange) {
            // для 1 минуты присылает сервер признак, иначе определяем самостоятельно
                if (period != Periods.MIN) {
                    daySeparate = lastCs.Day != cs.Day;
                }

                if (daySeparate) { cs.DateChangeType = TerceraChartCashItemSeriesCacheScreenDataDateChangeType.Day; }
            }

            /// / Week separator
            // if (period < Periods.DAY || isRange)
            // {
            //    var changeWeek = lastCs.Year != cs.Year;

            //    if (changeWeek)
            //        WeekFlag = true;

            //    // set flags
            //    if (WeekFlag && daySeparate)
            //    {
            //        WeekFlag = false;
            //        cs.DateChangeType = TerceraChartCashItemSeriesCacheScreenDataDateChangeType.Week;
            //    }
            // }

            // Month separator
            if (period < Periods.MONTH) {
                const changeMonth = lastCs.Month != cs.Month;
                if (changeMonth) { MonthFlag = true; }

                // set flags
                if (MonthFlag && daySeparate) {
                    MonthFlag = false;
                    cs.DateChangeType = TerceraChartCashItemSeriesCacheScreenDataDateChangeType.Month;
                }
            }

            // year separator
            if (period < Periods.YEAR) {
                const changeYear = lastCs.Year != cs.Year;
                if (changeYear) { YearFlag = true; }

                // set flags
                if (YearFlag/* && daySeparate */) {
                    YearFlag = false;
                    cs.DateChangeType = TerceraChartCashItemSeriesCacheScreenDataDateChangeType.Year;
                }
            }

            const bbi = _cashItem.GetInterval(biIndex);
            if (bbi) {
                cs.IsMainSession = TradingSessionBase.IsMainType(bbi.SessionType) || period >= Periods.DAY;

                if (!cs.IsMainSession && !this.settings.chartMVCModel.showAllSessions) {
                    cs.NotShowExtendedSession = true;
                }
            }

            lastCs = cs;

            tempChartScreenData.Storage.push(cs);
        }

        // this.SyncronizedSessionFlag = _cashItem.SyncronizedSessionFlag;

        // Replace
        this.ChartScreenData = tempChartScreenData;
    }

    public Dispose (): void {
    // var volumeCache = GetVolumeCache();
    // if (volumeCache != null)
    // {
    //    volumeCache.RemoveListener(this, cashItem);
    //    if (profileSubItem != null)
    //        volumeCache.RemoveListener(this, profileSubItem);
    // }

        // profileSubItem = null;
        if (this._cashItem) {
            this._cashItem.Dispose();
        }

        this.CashItem = null;
        this.settings = null;
    }

    // public void DisableCashItemEvents()
    // {
    //    if (cashItem != null)
    //    {
    //        cashItem.HistoryExpanded -= value_HistoryExpanded;
    //        cashItem.HistoryReload -= cashItem_HistoryReload;
    //        cashItem.QuoteProcessed -= cashItem_QuoteProcessed;
    //    }
    // }

    public GetTimePeriodCount (period, count): number {
        if (period < Periods.TIC) {
            return (count * (-period)) * TimeSpanPeriods.TicksPerSecond;
        } else if (period == Periods.TIC || (period % Periods.SECOND == 0)) {
            return count * TimeSpanPeriods.TicksPerSecond;
        } else {
            return count * period * TimeSpanPeriods.TicksPerMinute;
        }
    }

    public GetTime (count): number {
        return this.GetTimePeriodCount(this._cashItem.FPeriod, count);
    }

    public GetTimeLTRT (index, ref_lt_rt): void {
    // Получаем нерасширенную длину истории
        const length = this.FIndexList.length; // +++напрямую получаем

        // если это доступ к времени, то вычисляем время бара в будущем
        if (index < 0) {
            ref_lt_rt.lt = 0;
            ref_lt_rt.rt = 0;
        } else if (index >= 0 && index < length) {
            const realIndex = index;
            index = this.GetIndex(index);

            if (index === FAKE_PADDING_BAR_INDEX) {
                this.GetPaddingTimeLTRT(realIndex, ref_lt_rt);
            } else if (index < 0) {
                ref_lt_rt.lt = this.ZeroTimes[index * -1];
                ref_lt_rt.rt = ref_lt_rt.lt + this.GetTime(1);
            } else {
                ref_lt_rt.lt = this._cashItem.GetOpenTime(index);
                ref_lt_rt.rt = this._cashItem.GetCloseTime(index);
            }
        } else {
            const lastIndex = this.GetIndex(length - 1);
            ref_lt_rt.lt = this._cashItem.GetOpenTime(lastIndex) + this.GetTime(index - length + 1);
            ref_lt_rt.rt = ref_lt_rt.lt + this.GetTime(1);
        }
    }

    public GetPaddingTimeLTRT (realIndex, ref_lt_rt): void {
        if (this.paddingBarsCount > 0) {
            const realfirstBarLeftTime = this.RealLeftRelativeBorderForPaddingBars;
            const fakePaddingLeftTime = realfirstBarLeftTime + (realIndex - this.paddingBarsCount) * this._BarDuration;
            ref_lt_rt.lt = fakePaddingLeftTime;
            ref_lt_rt.rt = fakePaddingLeftTime + this._BarDuration;
        } else {
            ref_lt_rt.lt = this.LeftRelativeBorder;
            ref_lt_rt.rt = this.LeftRelativeBorder + this._BarDuration;
        }
    }

    /// // <summary>
    /// // Поиск индекса бара по времени.
    /// // Старый способ - бинарный поиск, используем для нелинейных шкал
    /// // </summary>
    public FindIntervalBinary (time): number {
    // Оптимизируем
        let to = this.FIndexList.length - 1;
        let from = 0;
        let middle = 0;
        const ref_lt_rt = { lt: 0, rt: 0 };
        // Бинарный поиск
        while ((to - from) > 1) {
            middle = Math.floor(from + (to - from) / 2);
            this.GetTimeLTRT(middle, ref_lt_rt);
            if (!(time < ref_lt_rt.lt)) {
                from = middle;
            } else {
                to = middle;
            }
        }

        // Анализ результата
        if ((to - from) == 0) {
            this.GetTimeLTRT(from, ref_lt_rt);
            if (!(time < ref_lt_rt.lt) && (time < ref_lt_rt.rt)) {
                return from;
            } else if (from > 0) {
                return from - 1;
            } else {
                return -1;
            }
        } else if ((to - from) == 1) {
            this.GetTimeLTRT(from, ref_lt_rt);

            const ref_lt_rt1 = { lt: 0, rt: 0 };
            this.GetTimeLTRT(to, ref_lt_rt1);

            if (!(time < ref_lt_rt.lt) && (time < ref_lt_rt.rt)) {
                return from;
            } else if (!(time < ref_lt_rt1.lt) && (time < ref_lt_rt1.rt)) {
                return to;
            }
            // возможно между интервалами дырка, тогда предположим, что дырка - продлжжение левого интервала
            else if (time > ref_lt_rt.lt && time < ref_lt_rt1.lt) {
                return from;
            } else if (this._cashItem != null && (this._cashItem.FPeriod < Periods.TIC && (to == (this._cashItem.Count() - 1)))) {
                return to;
            } else if (time > ref_lt_rt.rt) {
                return -1;
            } else if (from > 0) {
                return from - 1;
            } else {
                return ((time - this.GetValue(0, CashItem.TIME_INDEX)) / this._TicksInOneBar);
            }
        }

        return -1;
    }

    /// <summary>
    /// Поиск индекса по блокам - оптимизировано для линейных шкал
    /// </summary>
    public FindBlockBinary (time, settings): number // PointConverterSettings
    {
        if (this.dataBlocks.length == 0) {
            return 0;
        }

        let from = 0;
        let to = this.dataBlocks.length - 1;

        // Бинарный поиск
        while ((to - from) > 1) {
            const middle = Math.floor(from + (to - from) / 2);
            const leftTime = this.dataBlocks[middle].LeftTime;
            if (!(time < leftTime)) {
                from = middle;
            } else {
                to = middle;
            }
        }

        // берем блок
        let db = null;
        if (to == from) {
            db = this.dataBlocks[to];
        } else if ((to - from) == 1) {
            const rightDb = this.dataBlocks[to];
            db = (rightDb.LeftTime <= time) ? rightDb : this.dataBlocks[from];
        }

        if (db.IsHole) {
            if (settings != null && this.settings.showRightBorder) {
                return Math.max(this.dataBlocks[to].LeftIndex, this.dataBlocks[from].LeftIndex);
            } else {
                return db.LeftIndex;
            }
        } else {
            return db.LeftIndex + (time - db.LeftTime) / this.RelativePeriodBar;
        }
    }

    /// /длина бара для расчетов времени в будущем и в прошлом
    public GetBarDuration (cashItem): TimeSpanPeriods {
    // не влияет на движение тулзовин.
    // влият на шкалу времен и на получение времени, например, функциями рисования .нет индикаторов
        const period = cashItem.FPeriod;

        // 1 тик и N тиков - 1 секунда
        if (period <= Periods.TIC) {
            return TimeSpanPeriods.TicksPerSecond;
        }

        // рейндж - 1сек
        if (period % Periods.RANGE == 0) {
            return TimeSpanPeriods.TicksPerSecond;
        }

        // ренко - интервал оригинального айтема берется
        const rc = cashItem;
        if (rc?.BaseItem != null) {
            return this.GetBarDuration(rc.BaseItem);
        }

        // фиксированная длина бара
        return BaseInterval.GetIntervalLength(cashItem.FPeriod);
    }

    /// // <summary>
    /// // Вычисление индекса бара (в т.ч. дробного) для указанного времени
    /// // </summary>
    public FindIntervalExactly (time/* PointConverterSettings settings = null */): number {
    // look into the past
        if (time < this.LeftRelativeBorder) {
            const e = (time - this.LeftRelativeBorder) / this._BarDuration;
            return e;
        }

        // look into the future...
        if (time >= this.RightRelativeBorder) {
            const count = this._cashItem != null && this._cashItem.FPeriod == Periods.TIC ? this.Count() - 1 : this.Count();
            const e = count + (time - this.RightRelativeBorder) / this._BarDuration;
            return e;
        }

        //
        // Ticks
        //
        if (this._cashItem != null && this._cashItem.FPeriod == 0) {
            const i = this.FindIntervalBinary(time);

            const curTime = this.GetValue(i, CashItem.TIME_INDEX);
            const nextTime = this.GetValue(i + 1, CashItem.TIME_INDEX);
            if (nextTime == curTime) // если 2 тика имеют одно и то же время (бывает на смарт-роуте иногда)
            { return i; }

            const left = time - curTime;
            return i + left / (nextTime - curTime);
        }
        //
        // Non linear
        //
        else if (this.NonLinearTimeline) {
            const i = this.FindIntervalBinary(time);

            const openBarTime = this.GetValue(i, CashItem.TIME_INDEX);
            const closeBarTime = this.GetValue(i, CashItem.TIME_CLOSE_INDEX);
            const left = time - openBarTime;
            return i + left / (closeBarTime - openBarTime);
        }
        //
        // Linear timeline
        //
        else {
            return this.FindBlockBinary(time, this.settings);
        }
    }

    /// // <summary>
    /// // Вычисление времени по указанному индексу (в т.ч. дробному)
    /// // </summary>
    public FindTimeExactly (barIndex: number): number {
        let mlt = this._BarDuration;
        const count = this._cashItem != null && this._cashItem.FPeriod == Periods.TIC ? this.Count() - 1 : this.Count();

        // Look into the past...
        if (barIndex < 0) {
            var delta = this.LeftRelativeBorder + barIndex * mlt;
            return delta;
        }

        // Look into the future...
        else if (barIndex >= count) {
            var delta = barIndex - count;
            return delta * mlt + this.RightRelativeBorder;
        }

        // inside the cashitem
        else {
            const iVal = Math.floor(barIndex);
            const openTime = this.GetValue(iVal, CashItem.TIME_INDEX);

            if (this._cashItem != null && this._cashItem.FPeriod == 0) {
                mlt = this.GetValue(iVal + 1, CashItem.TIME_INDEX) - openTime;
            } // для тиков берем расстояние между барами
            else if (this.NonLinearTimeline) {
                mlt = this.GetValue(iVal, CashItem.TIME_CLOSE_INDEX) - openTime;
            } // для нелинейной шкалы длина бара = close-open

            return openTime + (barIndex - iVal) * mlt;
        }
    }

    public setPaddingBarsCount (paddingCount): void {
        this.paddingBarsCount = paddingCount;
        this.RecalcIndices();
    }

    public static GetMaxZeroBars (FCashItem): number {
        if (FCashItem == null) {
            return 0;
        }

        const period = FCashItem.FPeriod;
        if (period > Periods.TIC) {
            if (period < Periods.DAY)
            // пустиых баров не более 12 часов
            {
                return 24 * Periods.HOUR / period;
            } else if (period < Periods.MONTH)
            // день и неделя - макс длина дыры = 1 месяц
            {
                return Periods.MONTH / period;
            } else
            // для месяцев - максимальная дыра = 1 год
            {
                return 12;
            }
        } else {
        // Для тик-интервальных и тиковых баров дырок не бывает
            return 0;
        }
    }
}

const FAKE_PADDING_BAR_INDEX = -100; // -1 is busy as invalid index

class TerceraChartCashItemSeriesCacheScreenData {
    public Time;

    public Open;
    public High;
    public Low;
    public Close;
    public Volume;

    public DateTime;
    public DateTimeInSessionTimeZoneWithOffset = new Date();
    public Year;
    public Day;
    public Hour;
    public Month;

    public Hole = false;
    public NotShowExtendedSession = false;

    public isDayHigh = false;
    public isDayLow = false;

    /// <summary>
    /// true by default
    /// </summary>
    public IsMainSession = true;
    public Separator = false;
    public DateChangeType = TerceraChartCashItemSeriesCacheScreenDataDateChangeType.None;
    public BaseIntervalIndex;
}
// #endregion

// #region TerceraChartCashItemSeriesCacheScreenDataStorage
class TerceraChartCashItemSeriesCacheScreenDataStorage// List<TerceraChartCashItemSeriesCacheScreenData>
{
    public MinLow = Number.MAX_VALUE;
    public MaxHigh = -Number.MAX_VALUE;
    public MinDayLowIndex: any = null;
    public MaxDayHighIndex: any = null;
    public Storage = [];
}

/// <summary>
/// Cache some additional info
/// </summary>

// #endregion

// #region TerceraChartCashItemSeriesDataBlock
class TerceraChartCashItemSeriesDataBlock {
    public LeftIndex: any;
    public LeftTime: any;
    public IsHole: boolean;

    constructor (index, time) {
        this.LeftIndex = index;
        this.LeftTime = time;
        this.IsHole = false;
    }
}
// #endregion
