// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { MAMode } from '../IndicatorConstants';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { type iBuildInIndicator } from '../iBuildInIndicator';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';

export class KRI extends IndicatorScriptBase {
    public KRIPeriod: number;

    private ma: iBuildInIndicator;

    constructor () {
        super();
        this.ProjectName = 'Kairi Relative Index';
        this.Comments = 'Kairi Relative Index';
        this.SetIndicatorLine('KRI', Color.Red, 1, LineStyle.SimpleChart);
        this.SetLevelLine('Zero', 0, Color.Gray, 1, LineStyle.SimpleChart);
        this.SeparateWindow = true;

        this.KRIPeriod = 13;
        super.InputParameter(new InputParameterInteger('KRIPeriod', 'KRIPeriod', 1, 1, 99999));
    }

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
    };

    public GetIndicatorShortName (): string {
        return 'KRI(' + this.KRIPeriod + ')';
    };

    public createInternalIndicators (): iBuildInIndicator[] {
        this.ma = this.Indicators.iMA(this.CurrentData, this.KRIPeriod, MAMode.SMA, 0, PriceType.Close);
        return [this.ma];
    };

    public OnQuote (): void {
        super.OnQuote();
        if (this.CurrentData.Count <= this.KRIPeriod) { return; }

        const val = this.ma.GetValue();
        this.SetValue(0, 0, (this.CurrentData.GetPrice(PriceType.Close) - val) / val * 100);
    };
}
