// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { ContainerControlTemplate } from "../../templates.js";
import { Control } from "./Control.js";

export let ContainerControl = Control.extend({
    template: ContainerControlTemplate,
    data: function ()
    {
        return {
            activeControl: null
        };
    },
    lostFocusOnOutsideClick: false
});

ContainerControl.prototype.getType = function () { return 'ContainerControl' };

// Раскладываем контролы по name
// при овверайде вызов бэйс - обязательно!
ContainerControl.prototype.onrender = function ()
{
    // TODO. oninit, onrender.
    this.Controls = this.Controls || {};
    this.registerAllControl();
};

ContainerControl.prototype.registerAllControl = function ()
{
    const allRactiveObject = this.findAllComponents();
    // если чтото есть, или надо перезатереть контролы которых не стало
    const len = allRactiveObject.length;
    for (let i = 0; i < len; i++)
    {
        const ractiveObj = allRactiveObject[i];
        if (ractiveObj.parent === this) // только своих детей
        {
            this.registerControl(ractiveObj);
        }
    }
}

ContainerControl.prototype.oninit = function ()
{
    Control.prototype.oninit.apply(this);

    // TODO. oninit, onrender.
    this.Controls = this.Controls || {};
};

// управление фокусом на контейнере
ContainerControl.prototype.processFocus = function (control)
{
    var myActiveControl = this.get('activeControl');
    if (control === myActiveControl)
        return;

    if (myActiveControl)
        myActiveControl.lostFocus();

    this.set('activeControl', control);

    if (control)
    {
        if (!control.get('canSelect') && !control.get('canFocus'))
            return;

        control.gotFocus();

        var parentElement = this.get('parentContainerControl');
        if (parentElement) parentElement.processFocus(this);
    }

    //вдруг при потере фокуса нам нужно вернуться в панель определённую( только часть АДА !!! ахахахахахаха)
    if (myActiveControl)
    {
        if (myActiveControl.AfterLostFocusWaiting)
            myActiveControl.AfterLostFocusWaiting.setFocus();

        myActiveControl.AfterLostFocusWaiting = null;
        // console.log(myActiveControl.getType());
    }
};

ContainerControl.prototype.isActiveControlClicked = function (event)
{
    let activeControl = this.get('activeControl');
    if (!activeControl)
    {
        return false;
    }

    if (!activeControl.lostFocusOnOutsideClick)
    {
        while (activeControl instanceof ContainerControl)
        {
            const childControl = activeControl.get('activeControl');
            if (isNullOrUndefined(childControl))
            {
                break;
            }
            activeControl = childControl;
        }
    }
    // TODO: activeControl may be instanceof DocumentFragment in some cases
    if (activeControl instanceof Control && activeControl.el instanceof Element)
    {
        const controlRect = activeControl.el.getBoundingClientRect();
        const controlX = controlRect.left;
        const controlY = controlRect.top;
        const controlWidth = controlRect.width;
        const controlHeight = controlRect.height;

        const clickX = event.original.clientX;
        const clickY = event.original.clientY;

        if (clickX >= controlX && clickX <= controlX + controlWidth &&
            clickY >= controlY && clickY <= controlY + controlHeight)
        {
            return true; // Intersection detected
        } else
        {
            return false; // No intersection
        }

    } else
    {
        return false;
    }
}

ContainerControl.prototype.lostFocus = function ()
{
    Control.prototype.lostFocus.apply(this);
    this.processFocus(null);
};

ContainerControl.prototype.resetFocus = function ()
{
    this.processFocus(null);
};

// динамическое добавление контролов
ContainerControl.prototype.addControl = function (control, customPlace = false)
{
    if (!control) return;

    control.append = true;
    control.render(this.getClientPanel(customPlace));
    this.registerControl(control);
};

ContainerControl.prototype.removeControl = function (control, name)
{
    if (!control) return;

    var key = name;
    if (key === undefined) key = control.get('name');
    if (key === '') key = control._guid;

    var controls = this.Controls;
    if (!controls.hasOwnProperty(key)) return;

    delete controls[key];
    control.dispose();
};

ContainerControl.prototype.removeAllControls = function (exceptKeyDict)
{
    exceptKeyDict = exceptKeyDict || {};
    var controls = this.Controls;
    for (var key in controls)
    {
        if (exceptKeyDict[key]) continue;
        this.removeControl(controls[key], key);
    }
};

ContainerControl.prototype.removeActiveControl = function ()
{
    this.set('activeControl', null);
};

ContainerControl.prototype.removeParentActiveControl = function ()
{
    var parentElement = this.get('parentContainerControl');
    if (parentElement)
    {
        parentElement.removeActiveControl();
    }
};


// часть контейнера куда будет добавляться контрол
ContainerControl.prototype.getClientPanel = function ()
{
    return this.el;
};

ContainerControl.prototype.registerControl = function (control)
{
    var name = control.get('name');
    var key = name || name === 0 ? name : control._guid;
    var controls = this.Controls;
    controls[key] = control;
    control.set('parentContainerControl', this);
    control.on('teardown', function () { delete controls[key]; });
};

ContainerControl.prototype.onResizeBegin = function ()
{
    var controls = this.Controls;
    for (var key in controls)
    {
        var control = controls[key];
        if (control.onResizeBegin)
            control.onResizeBegin();
    }
};

ContainerControl.prototype.onResizeEnd = function ()
{
    var controls = this.Controls;
    for (var key in controls)
    {
        var control = controls[key];
        if (control.onResizeEnd)
            control.onResizeEnd();
    }
};

ContainerControl.prototype.onMouseDown = function (event, isHeader)
{
    if (Control.IsEventProcessed(event))
        return;

    if (!this.isActiveControlClicked(event))
    {
        // Reset current active control
        this.processFocus(null);
    }
    // Call base method
    Control.prototype.onMouseDown.apply(this, [event, isHeader]);

    if (isHeader)
    {
        if (this.get('movable') && this.get('focused'))
        {
            var myParams = {};
            myParams.startMoveCoordX = event.original.pageX;
            myParams.startMoveCoordY = event.original.pageY;
            myParams.startMyX = this.get('left') || 0;
            myParams.startMyY = this.get('top') || 0;
            this.set('onProcessMove', true);
            this.movingParameters = myParams;
        }
    }
    if (event.get('resizable') && this.get('onProcessMove') === false)
    {
        var myResizeParams = {};
        myResizeParams.rect = this.getElementRect(event.node);
        myResizeParams.down = {
            x: this.getPageX(event.original),
            y: this.getPageY(event.original)
        };
        myResizeParams.resizer = this.getResizer(event.original, event.node);
        this.resizeParameters = myResizeParams;
        this.onProcessResize = true;
        // Inform controls about start resizing
        this.onResizeBegin();
    }
};

ContainerControl.prototype.dispose = function ()
{
    var controls = this.Controls;
    if (controls)
    {
        for (var key in controls)
        {
            var control = controls[key];
            if (control.dispose)
                control.dispose();
        }
    }
    // Object to prevent null reference exceptions.
    this.Controls = {};
    this.teardown();
};