// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
export class BrandingUtils // #113904: https://docs.google.com/document/d/1EU6Viz-hJM4K2qPjijk9f0EMScjZkwluXHfN6-XNjIQ/edit#heading=h.7hqfzj122bvh
{
    public static backupSplashURL: any = null; // for NewNetTrader, details: #116177

    public static backupDefaultWebHeader: any = null; // for NewNetTrader or for Investing
    public static backupDefaultPageTitle: any = null;

    public static backupDefaultFaviconHref: any = null; // for NewNetTrader or for Investing or for Web Mobile

    public static backupDefaultWebMobileBG: any = null;
    public static backupDefaultWebMobileBG_srcset: any = null;

    public static backupDefaultWebLoginBanner: any = null; // for NewNetTrader or for Investing or for Web Mobile

    public static lastWebLoginBanner: any = null; // for AboutScreen and BrokerInfoScreen

    public static IsWebHeaderFromBranding (): boolean {
        return !!this.backupDefaultWebHeader;
    }

    public static getLocalFileName (path, propName: string, brandingKey: string): string {
        const directoryToCreate = `brandingImgs/${brandingKey}/`;
        return `${directoryToCreate}${propName}_${path.match(/\/([^/]+\.\w+)$/)[1]}`;
    }

    public static GetBrandingWebHeader (brandingWebHeaderURL, valueForBackup, brandingKey: string): any {
        const webHeader = brandingWebHeaderURL
            ? BrandingUtils.getLocalFileName(brandingWebHeaderURL, 'BrandingWebHeader', brandingKey)
            : (this.backupDefaultWebHeader ? this.backupDefaultWebHeader : null);

        if (webHeader) {
            if (!this.backupDefaultWebHeader) {
                this.backupDefaultWebHeader = valueForBackup;
            }

            return webHeader;
        }

        return null;
    }

    public static SetWebPageTitle (newTitleText, valueForBackup): void // web page title (text in tab), if no in branding return from backup if exist #115286
    {
        const title = document.getElementsByTagName('title')[0];
        if (title) {
            let result = null;

            if (!newTitleText && this.backupDefaultPageTitle) {
                result = this.backupDefaultPageTitle;
            } else if (newTitleText) {
                if (!this.backupDefaultPageTitle) {
                    this.backupDefaultPageTitle = valueForBackup;
                }
                result = newTitleText;
            }

            title.innerHTML = result;
        }
    }

    public static SetWebFavicon (relativeFaviconPath, propName: string, brandingKey: string): void // web page title (text in tab) #115286
    {
        const favicon = document.getElementById('favicon') as HTMLLinkElement;
        if (favicon) {
            let newHref = '/favicon.ico';
            if (!relativeFaviconPath && this.backupDefaultFaviconHref) {
                newHref = this.backupDefaultFaviconHref;
            } else if (relativeFaviconPath) {
                if (!this.backupDefaultFaviconHref) {
                    this.backupDefaultFaviconHref = favicon.href;
                }
                newHref = BrandingUtils.getLocalFileName(relativeFaviconPath, propName, brandingKey);
            }

            const newLink = document.createElement('link');

            newLink.id = 'favicon';
            newLink.rel = 'icon';
            newLink.href = newHref;
            newLink.type = 'image/x-icon';

            // Remove the existing favicon link and insert the new one
            favicon.parentNode.replaceChild(newLink, favicon);
        }
    }

    public static SetWebSplashScreen (relativeSplashPath, brandingKey: string): void // web page image instead of Earth by default on login screen #115286
    {
        const localFileName = relativeSplashPath ? BrandingUtils.getLocalFileName(relativeSplashPath, 'BrandingWebSplashScreen', brandingKey) : '';
        const element = document.getElementsByClassName('js-main')[0] as HTMLElement;
        if (element != null) {
            element.style.backgroundImage = relativeSplashPath ? 'url("' + localFileName + '")' : '';
        }

        this.backupSplashURL = relativeSplashPath ? localFileName : null;
    }

    public static NewNetTraderModeChanged (isLoggedIn: boolean): void {
        const element = document.getElementsByClassName('js-main')[0] as HTMLElement;
        if (element != null) {
            element.style.backgroundImage = this.backupSplashURL && !isLoggedIn ? 'url("' + this.backupSplashURL + '")' : '';
        }
    }

    public static GetLoginBackgroundData (relativeBGPath, valueForBackup, srcsetValueForBackup, brandingKey: string) // Web Mobile background on login screen #115286
    {
        const localFileName = relativeBGPath ? BrandingUtils.getLocalFileName(relativeBGPath, 'BrandingWMBackgroundImage', brandingKey) : '';

        const bg = localFileName || (this.backupDefaultWebMobileBG ? this.backupDefaultWebMobileBG : null);

        const bg_srcset = localFileName || (this.backupDefaultWebMobileBG_srcset ? this.backupDefaultWebMobileBG_srcset : null);

        if (bg) {
            if (!this.backupDefaultWebMobileBG) {
                this.backupDefaultWebMobileBG = valueForBackup;
                this.backupDefaultWebMobileBG_srcset = srcsetValueForBackup;
            }

            return {
                backgroundSrc: bg,
                backgroundSrcSet: bg_srcset
            };
        }

        return null;
    }

    public static GetWebLoginBanner (relativeImgPath, valueForBackup: string, propName: string, brandingKey: string) {
        const url = relativeImgPath
            ? BrandingUtils.getLocalFileName(relativeImgPath, propName, brandingKey)
            : (this.backupDefaultWebLoginBanner ? this.backupDefaultWebLoginBanner : valueForBackup);

        if (!this.backupDefaultWebLoginBanner && valueForBackup) {
            this.backupDefaultWebLoginBanner = valueForBackup;
        }

        this.lastWebLoginBanner = url;

        return url;
    }

    public static GetLastWebLoginBanner (): any {
        return this.lastWebLoginBanner;
    }
}
