// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectPortfolioSystemMessage extends DirectMessageBaseClass {
    public PortfolioManagerLogin: string | null = null; // PFixStringField FIELD_LOGIN = new PFixIntField(3); - логин Portfolio manager-a
    public PortfolioSystemID: number | null = null; // PFixIntField FIELD_PORTFOLIO_SYSTEM_ID = new PFixIntField(727); - идентификатор портфолио системы
    public PortfolioSystemName: string | null = null; // PFixStringField FIELD_NAME = new PFixIntField(22); - строковое название системы
    public StatusOut: number | null = null; // PFixIntField FIELD_STATUS = new PFixIntField(708);

    constructor () {
        super(Message.CODE_PFIX_PORTFOLIO_SYSTEM_MESSAGE);
    }
}
