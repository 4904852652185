// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts";
import { TerceraFileUploaderTemplate } from "../../templates.js";
import { ContainerControl } from "./ContainerControl.js";
import { ThemeManager } from "../misc/ThemeManager.ts";

export let TerceraFileUploader = ContainerControl.extend({
    data: function ()
    {
        return {
            width: 180,
            height: 20,
            btnImg: '',
            filter: '',
            file: null
        };
    },
    computed: {
        message: function ()
        {
            var file = this.get('file');
            return file ? file.name : Resources.getResource('Select a file');
        }
    },
    template: TerceraFileUploaderTemplate
});

TerceraFileUploader.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.apply(this);
    this.on('selectFileClicked', this.onSelectFileClicked);
    this.on('newFileSelected', this.onNewFileSelected);
    this.set('btnImg', ThemeManager.getImageFullUrl('j_algostudio/16x16_icon_browse.png'));
};

TerceraFileUploader.prototype.onSelectFileClicked = function ()
{
    $(this.find('input')).click();
};

TerceraFileUploader.prototype.onNewFileSelected = function (e)
{
    var file = e.original.target.files[0];
    if (!file)
    {
        this.set('file', null);
        return;
    }

    if (!file.type.match(this.get('filter')))
    {
        this.set('file', null);
        alert(Resources.getResource('Wrong file type'));
        return;
    }

    this.set('file', file);
};