// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { TerceraDateRangeDropDownFormTemplate } from "../../../templates.js";
import { Resources } from "../../../Commons/properties/Resources.ts";
import { TerceraWindowBase } from "../../screen/TerceraWindowBase.js";
import { BOTTOM_WINDOWS_MARGIN } from "../../UtilsClasses/SizeConstants.ts";
import { Control } from "../Control.js";
export let TerceraDateRangeDropDownForm = TerceraWindowBase.extend({
    data: function ()
    {
        return {
            resizable: false,
            width: 435,
            height: 291,
            showHeader: false,
            showFooter: false,
            dateFrom: null,
            dateTo: null,
            applyBtnText: Resources.getResource('screen.MonthCalendarPopupForm.Apply'),
            labelTextFrom: Resources.getResource('screen.reports.reportHeaders.from'),
            labelTextTo: Resources.getResource('screen.reports.reportHeaders.to')
        };
    },
    partials: { bodyPartial: TerceraDateRangeDropDownFormTemplate },
});

TerceraDateRangeDropDownForm.prototype.oncomplete = function ()
{
    TerceraWindowBase.prototype.oncomplete.apply(this);

    let pickerFrom = this.Controls.dtPickerFrom.picker,
        pickerTo = this.Controls.dtPickerTo.picker

    pickerFrom.selectDate(this.get('dateFrom'));
    pickerTo.selectDate(this.get('dateTo'));

    this.on("applyBtnClick", this.close)

    pickerFrom.update('onRenderCell', this.datePickerOnRenderCell.bind(this))
    pickerFrom.update('onSelect', this.onSelectDateFrom.bind(this))

    pickerTo.update('onRenderCell', this.datePickerOnRenderCell.bind(this))
    pickerTo.update('onSelect', this.onSelectDateTo.bind(this))

    this.on(Control.Events.LostFocus, function () { this.close(); }.bind(this));
    this.setFocus();
};

TerceraDateRangeDropDownForm.prototype.onSelectDateFrom = function ()
{
    let dtPickerFrom = this.Controls.dtPickerFrom.picker,
        dateFromArr = dtPickerFrom.selectedDates,
        dateFrom = dateFromArr.length ? dateFromArr[0] : dtPickerFrom.currentDate,
        dateTo = this.get('dateTo')

    this.setDateFrom(dateFrom)

    if (dateFrom > dateTo)
        this.Controls.dtPickerTo.picker.selectDate(dateFrom);
}

TerceraDateRangeDropDownForm.prototype.lostFocus = function ()
{
    TerceraWindowBase.prototype.lostFocus.apply(this);

};

TerceraDateRangeDropDownForm.prototype.onSelectDateTo = function ()
{
    let dtPickerTo = this.Controls.dtPickerTo.picker,
        dateToArr = dtPickerTo.selectedDates,
        dateTo = dateToArr.length ? dateToArr[0] : dtPickerTo.currentDate,
        dateFrom = this.get('dateFrom')

    this.setDateTo(dateTo)

    if (dateTo < dateFrom)
        this.Controls.dtPickerFrom.picker.selectDate(dateTo);
}

TerceraDateRangeDropDownForm.prototype.setDateFrom = function (date)
{
    this.set('dateFrom', date)
}

TerceraDateRangeDropDownForm.prototype.setDateTo = function (date)
{
    this.set('dateTo', date)
}

TerceraDateRangeDropDownForm.prototype.datePickerOnRenderCell = function (date, cellType)
{
    if (cellType == 'day' && date >= this.get('dateFrom') && date <= this.get('dateTo'))
        return { classes: 'datepicker--days-range' }
}

TerceraDateRangeDropDownForm.prototype.setCorrectLocation = function (x, y)
{
    let width = this.get("width"), height = this.get("height");

    if (x + width > window.innerWidth)
        x = window.innerWidth - width - 2;
    if (y + height > window.innerHeight - BOTTOM_WINDOWS_MARGIN)
        y = window.innerHeight - BOTTOM_WINDOWS_MARGIN - height - 2;

    this.set({ left: x, top: y })
}