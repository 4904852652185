// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { MessageProcessorBase } from './MessageProcessorBase';
import { LogonMessage } from '../Messages/LogonMessage';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class LogonMessageProcessor extends MessageProcessorBase {
    public decoder: any;

    constructor (parentDecoder) {
        super();

        this.decoder = parentDecoder;
    }

    public override decode (fieldSet: PFixFieldSet): LogonMessage[] {
        const logon = new LogonMessage(fieldSet);

        // this.decoder.TimeZone = logon.getTimeZone();
        // this.decoder.TimeZoneId = logon.getTimeZoneId();

        return [logon];
    }
}
