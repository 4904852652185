// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources, LOCALE_EN } from "../../Commons/properties/Resources.ts";
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";
import { AccountOperationInfo } from "../../Commons/cache/AccountOperationInfo.ts";
import { MathUtils } from "../../Utils/MathUtils.ts";
import { WithdrawalScreenTemplate } from "../../templates.js";
import { Control } from "../elements/Control.js";
import { TerceraButton } from "../elements/TerceraButton.ts";
import { TerceraWindowBase } from "./TerceraWindowBase.js";
import { RevenueCommissionType } from "../../Utils/Enums/Constants.ts";
import { AccountType } from "../../Utils/Account/AccountType.ts";
import { RulesSet } from "../../Utils/Rules/RulesSet.ts";
import { DataCache } from "../../Commons/DataCache.ts";
import { ScreensNames } from "../UtilsClasses/FactoryConstants.ts";
import { WithdrawalCalculator } from '../../Commons/cache/WithdrawalCalculator.ts';
import { TerceraButtonStyle } from "../../Utils/Enums/ButtonEnums.ts";
import { AccountMenuItemsHelper } from "../../Commons/AccountWidget/AccountMenuItemsHelper.ts";

export let WithdrawalScreen = TerceraWindowBase.extend({
    data: function ()
    {
        return {
            movable: true,
            resizable: false,
            showFooter: false,
            focused: true,

            width: 322,
            zIndex: 290, //Ниже TerceraLookupDropDownForm === 300 
            accountItem: null,
            userLogin: '', // текстовый представление юзера для отображения когда лукап скрыт (1 аккаунт)
            selectedAsset: null,
            hasAssets: false,
            canWithdrawal: true,
            enoughAmount: true, // для блокировки кнопки если выбрано слишком много
            hasFee: true,

            avaliableValue: 123456789,
            amountValue: 0,
            remainsValue: 0,
            minValue: 0,
            maxValue: 0,
            step: 0.01,
            decimalPrecision: 2,

            btnOKStyle: TerceraButtonStyle.Blue,
            btnCancelStyle: TerceraButtonStyle.Standard,
            style_addition_header: 'js-Withdrawal-AdditionalHeader',


            feeTitleLabel: "Withdrawal fee:",
            requiredAmountTitleLabel: "Required amount:",

            feeText: 0,
            requiredAmountText: 0,
            singleAccount: true
        };
    },
    partials: { bodyPartial: WithdrawalScreenTemplate },
    CommissionPlan: null
});

let instance = null;

WithdrawalScreen.prototype.getType = function () { return ScreensNames.WithdrawalScreen; }

WithdrawalScreen.prototype.oninit = function ()
{
    TerceraWindowBase.prototype.oninit.apply(this);

    this.observe('accountItem', this.OnAccountItemChanged);
    this.observe('selectedAsset', this.OnAssetChangedChanged);
    this.observe('amountValue', this.OnAmountValueChanged);
    this.observe('remainsValue', this.OnRemainsValueChanged);
    this.observe('avaliableValue', this.OnAvaliableValueChanged)
    this.on('cancelClick', this.close.bind(this));
    this.on('okClick', this.okClick.bind(this));

    this.localize();
};

WithdrawalScreen.prototype.localize = function ()
{
    this.set({
        header: Resources.getResource('screen.withdrawal.ReservedWithdrawal'),

        fromAccount: Resources.getResource('screen.withdrawal.account'),
        asset: Resources.getResource('screen.withdrawal.Asset'),
        avaliable: Resources.getResource('screen.withdrawal.Availible funds'),
        amount: Resources.getResource('screen.withdrawal.Amount'),
        remains: Resources.getResource('screen.withdrawal.Remains'),
        okText: Resources.getResource('screen.withdrawal.Withdrawal'),
        cancelText: Resources.getResource('screen.withdrawal.Cancel'),
        feeTitleLabel: Resources.getResource("screen.withdrawal.Fee"),
        requiredAmountTitleLabel: Resources.getResource("screen.withdrawal.RequiredAmount")
    });
};

WithdrawalScreen.prototype.oncomplete = function ()
{
    TerceraWindowBase.prototype.oncomplete.apply(this);

    if (DataCache)
    {
        const accs = DataCache.getOwnedAccounts();
        const primaryAcc = DataCache.getPrimaryAccount();
        this.set({ userLogin: primaryAcc.FullAccString });

        const acc = this.get('accountItem');
        if (acc != null && accs[acc.BstrAccount] == null)
        {
            this.set('accountItem', primaryAcc);    // #119826
        }
    }

    Control.Ticker.Subscribe(this.TickAsync, this)

    this.center();
    this.updateValues(true);
};

WithdrawalScreen.prototype.okClick = function ()
{
    let data = {};
    let sum = this.get("amountValue")
    let SelectedAsset = this.get("selectedAsset");
    if (SelectedAsset)
        SelectedAsset = SelectedAsset.value;
    let SelectedAccount = this.get("accountItem");

    data.operationType = AccountOperationInfo.TYPE_WITHDRAW;
    data.accountId = SelectedAccount.AcctNumber;
    data.amount = 0 - sum;
    data.comment = Resources.getResourceLang("screen.withdrawal.Withdrawal", LOCALE_EN) + " "
        + (SelectedAsset != null ? SelectedAsset.formatPrice((sum)) : SelectedAccount.formatPrice((sum)))
        + " " + Resources.getResourceLang("screen.withdrawal.from", LOCALE_EN) + " " + SelectedAccount.toString();

    if (SelectedAsset != null && SelectedAccount.AccountType == AccountType.MultiAsset)
        data.assetId = SelectedAsset.Id;

    DataCache.AccountOperationRequest(data).then(function ()
    {
        this.set("amountValue", 0);
    }.bind(this))
}

WithdrawalScreen.prototype.OnAmountValueChanged = function (newValue, oldValue)
{
    if (Math.abs(newValue - oldValue) < MathUtils.MATH_ROUND_EPSILON)
        return

    this.updateValues(true);
};

WithdrawalScreen.prototype.OnRemainsValueChanged = function (newValue, oldValue)
{
    if (Math.abs(newValue - oldValue) < MathUtils.MATH_ROUND_EPSILON)
        return

    // let avaliableValue = this.get("avaliableValue");
    // let newAmountValue = avaliableValue - newValue;
    // this.set({ amountValue: newAmountValue });
    this.updateValues(false);
};

WithdrawalScreen.prototype.updateValues = function (isAmount)
{
    if (this.Flock)
        return;

    this.Flock = true;

    let account = this.get("accountItem");
    let avaliableValue = this.get("avaliableValue");
    let amountValue = this.get("amountValue");
    let remainsValue = this.get("remainsValue");
    let newAmountValue = amountValue;
    let newRemainsValue = remainsValue;

    let feeText = "";
    let requiredAmountLabel = "";
    let fee = WithdrawalCalculator.CalculateFeeByWithdraw(account, this.CommissionPlan, amountValue);

    if (isAmount)
        newRemainsValue = avaliableValue - newAmountValue - fee;
    else
    {
        newAmountValue = WithdrawalCalculator.GetAmountByRemain(account, this.CommissionPlan, avaliableValue, newRemainsValue);
        fee = WithdrawalCalculator.CalculateFeeByWithdraw(account, this.CommissionPlan, newAmountValue);
    }

    if (isAmount)
    {
        if (newRemainsValue < 0)
            newRemainsValue = 0

        this.set('remainsValue', newRemainsValue)
    }
    else
        this.set('amountValue', newAmountValue)

    this.set('enoughAmount', newAmountValue + fee <= avaliableValue)

    let selAsset = this.get('selectedAsset')
    if (selAsset && selAsset.value)
    {
        let asset = selAsset.value
        feeText = asset.formatPrice(fee);
        requiredAmountLabel = asset.formatPrice(newAmountValue + fee);
    }

    this.set({
        feeText: feeText,
        requiredAmountText: requiredAmountLabel
    }).then(function ()
    {
        this.Flock = false;
    }.bind(this));

}

WithdrawalScreen.prototype.OnAccountItemChanged = function (newValue, oldValue, key)
{
    if (!newValue)
        return;

    let items = [];
    let assets = Object.keys(newValue.assetBalances);

    let hasAssets = Object.keys(assets).length > 1;

    // if (hasAssets)
    items = assets.map(function (x)
    {
        return newValue.assetBalances[x].Asset;
    })

    let avaliableValue = newValue.WithdrawalAvailable;

    let hasFee = false;
    if (newValue.CommissionPlan !== null)
        hasFee = newValue.CommissionPlan.WithdrawalCommisionType != RevenueCommissionType.None;

    this.CommissionPlan = newValue.CommissionPlan;

    this.set(
        {
            hasAssets: hasAssets,
            avaliableValueText: newValue.formatPrice(avaliableValue),
            amountValue: 0,
            maxValue: avaliableValue,
            remainsValue: avaliableValue,
            avaliableValue: avaliableValue,
            hasFee: hasFee
        });

    if (hasAssets)
        this.set({
            assetsItems: this.toComboBoxItems(items)
        })
    else if (assets.length)
    {
        this.set('selectedAsset', { 'text': assets[0], 'value': items[0] })
    }
}

WithdrawalScreen.prototype.OnAssetChangedChanged = function (newValue, oldValue, key)
{
    if (!newValue)
        return;

    let accountItem = this.get("accountItem");

    if (!accountItem)
        return;

    let assetBalance = accountItem.assetBalances[newValue.text],
        asset = assetBalance.Asset,
        step = asset ? asset.MinChange : this.get('step'),
        decimalPrecision = asset ? asset.Point : this.get('decimalPrecision'),
        avaliableValue = accountItem.AccountType === AccountType.MultiAsset ? assetBalance.WithdrawalAvaliable(accountItem) : accountItem.WithdrawalAvailable;

    this.set(
        {
            avaliableValueText: asset.formatPrice(avaliableValue),
            amountValue: 0,
            step: step,
            decimalPrecision: decimalPrecision,
            maxValue: avaliableValue,
            remainsValue: avaliableValue,
            avaliableValue: avaliableValue
        });
}

WithdrawalScreen.prototype.OnAvaliableValueChanged = function (newVal, oldVal)
{
    if (!MathUtils.IsNullOrUndefined(newVal) && newVal !== oldVal)
        this.updateValues(true)
}

WithdrawalScreen.prototype.TickAsync = function ()
{
    this.getWithdrawalAvailable();
}

WithdrawalScreen.prototype.getWithdrawalAvailable = function ()
{
    let account = this.get("accountItem");

    if (!account)
        return;

    let avaliableValue = account.WithdrawalAvailable;
    let asset = null;
    if (account.AccountType === AccountType.MultiAsset)
    {
        asset = this.get("selectedAsset");
        if (asset)
        {
            asset = account.assetBalances[asset.text];
            avaliableValue = asset.WithdrawalAvaliable(account);
        }
    }

    let currentAmount = this.get("amountValue");
    let remainsAmount = avaliableValue - currentAmount;

    this.set(
        {
            avaliableValueText: asset ? asset.formatPrice(avaliableValue) : account.formatPrice(avaliableValue),
            maxValue: avaliableValue,
            avaliableValue: avaliableValue,
            //remainsValue: remainsAmount,
            canWithdrawal: DataCache.isAllowedForMainAccount(RulesSet.FUNCTION_RESERVER_WITHDRAWAL)
        });
}

WithdrawalScreen.prototype.toComboBoxItems = function (assetIds)
{
    var arr = [];
    var assets = DataCache.assetCollection;
    for (var i = 0; i < assetIds.length; i++)
    {
        var asset = assetIds[i];
        arr.push({ text: asset.Name, value: asset });
    }
    var funcSort = function (a, b)
    {
        if (a.text.toLowerCase() > b.text.toLowerCase())
            return 1;
        else
            return -1;
    }
    arr.sort(funcSort);

    return arr;
};

WithdrawalScreen.prototype.dispose = function ()
{
    Control.Ticker.UnSubscribe(this.TickAsync, this)
    TerceraWindowBase.prototype.dispose.call(this)
}

WithdrawalScreen.show = function ()
{
    if (!instance)
    {
        instance = new WithdrawalScreen();
        instance.on('teardown', function ()
        {
            instance = null;
        });
        MainWindowManager.MainWindow.addControl(instance);
    }

    instance.setFocus();
};

// localization-key
// screen.withdrawal.avaliableNumeric.ToolTip=Set remaining capital
// screen.withdrawal.error.not_allowed=Account Operation with type Withdrawal is not allowed for your user, please contact your broker
// screen.withdrawal.error.not_enough_balance=Not enough balance
// screen.withdrawal.error.not_enough_blocked=Invalid amount(cannot unblock more than blocked)
// screen.withdrawal.error.not_enough_margin=Not enough margin
// screen.withdrawal.error.UnableToBlock=Error! Unable to block.
// screen.withdrawal.error.WrongSum=Error: wrong sum.
// screen.withdrawal.errorLabel=Not enough money for withdrawal
// screen.withdrawal.Fee=Withdrawal fee:
// screen.withdrawal.from=from
// screen.withdrawal.RequiredAmount=Required amount:
// screen.withdrawal.ReservedWithdrawal=Withdrawal
// screen.withdrawal.usersLookup.ToolTip=Select account
// screen.withdrawal.Withdraw=Withdraw
// screen.withdrawal.withdrawalNumeric.ToolTip=Set withdrawing capital