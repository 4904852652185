// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type AlertAction, type AlertAfterExecute, type AlertCondition, type AlertEvent, type AlertImportance, type AlertNotification, type AlertStatus, type AlertType } from '../Alert/AlertConstants';
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectAlertMessage extends DirectMessageBaseClass {
    public AccountId = -1;
    public UserId = -1;
    public InstrumentTradableID = -1;
    public RouteId: number | null = null;

    public AlertId = -1;
    public AlertType: AlertType = null;
    public AlertCondition: AlertCondition = null;
    public AlertValue: number | null = null;
    public AlertImportance: AlertImportance = null;
    public Date: Date | null = null;
    public AlertAfterExecute: AlertAfterExecute = null;
    public AlertAction: AlertAction = null;
    public AlertNotification: AlertNotification = null;
    public Text: string | null = null;

    public AlertEvent: AlertEvent = null;
    public AlertStatus: AlertStatus = null;
    public OrderPlaceParametrs: any = null; // Attributes locate in the AlertMessageProcessor.ts

    constructor () {
        super(Message.CODE_ALERT_MESSAGE);
    }
}
