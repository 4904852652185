// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts"
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts"
import { TerceraBrokerMessageScreenTemplate, TerceraBrokerMessageScreenFooterTemplate } from "../../templates.js";
import { ScreensNames } from "../UtilsClasses/FactoryConstants.ts";
import { TerceraOnboardingScreen } from "./TerceraOnboardingScreen.ts"
import { TerceraWindowBase } from "./TerceraWindowBase.js";
import { BrokerMessageResponseType } from "../../Utils/Notifications/BrokerMessageResponseType.ts"
import { UrlUtils } from "../../Utils/UrlUtils.ts"
import { ThemeManager } from "../misc/ThemeManager.ts"
import { brokerMessageScreenHandler } from "../../Utils/AppHandlers.js"
import { TextMessageType } from "../../Utils/Notifications/TextMessageType.ts";
import { DataCache } from "../../Commons/DataCache.ts";
import { SessionSettings } from "../../Commons/SessionSettings.ts";
import { Connection } from "../../Commons/Connection.ts";


export let TerceraBrokerMessageScreen = TerceraWindowBase.extend({
    data: function ()
    {
        return {
            style_addition_footer: '',
            width: 500,
            height: 300,
            minWidth: 300,
            minHeight: 300,
            showFooter: true,
            showModalMask: true,
            resizable: true,
            brokerMsgScreenID: null,
            messageInfo: null,
            noHtmlMessage: '',
            customButtonWidth: 280,
            brokerMSGContentWidth: 500,
            brokerMSGContentHeight: 233,
            customButtons: [],
            okButtonVisible: true,
            cancelButtonVisible: true,
            closeBtnVisible: false
        }
    },
    partials: {
        bodyPartial: TerceraBrokerMessageScreenTemplate,
        footerPartial: TerceraBrokerMessageScreenFooterTemplate
    },
    CloseHandler: null,
    CustomButtonsXCloseHandler: null
})

TerceraBrokerMessageScreen.prototype.getType = function () { return ScreensNames.BrokerMessageScreen }

TerceraBrokerMessageScreen.prototype.oninit = function ()
{
    TerceraWindowBase.prototype.oninit.apply(this)

    this.on('onOKClick', this.onOKClick)
    this.on('onCancelClick', this.onCancelClick)
    this.on('onIframeLoad', this.onIframeLoad)

    this.observe('messageInfo', this.onMessageInfoChange)

    this.localize()
}

TerceraBrokerMessageScreen.prototype.onIframeLoad = function ()
{
    let elID = 'iframe_' + this.get('brokerMsgScreenID'),
        el = document.getElementById(elID),
        messageInfo = this.get('messageInfo'),
        HTMLdisplayMethod = messageInfo ? messageInfo.IsHTMLDisplayMethod() : null,  // #114121 цвет текста меняем для DisplayMethod == TextDisplayMethod.Text
        changeIFrameTextColor = el && !HTMLdisplayMethod

    if (changeIFrameTextColor)     // пока не нашел css способа поменять цвет текста внутри iframe
        el.contentWindow.document.body.style.color = ThemeManager.CurrentTheme.TableAllHistogramTextColor;
}

TerceraBrokerMessageScreen.prototype.oncomplete = function ()
{
    TerceraWindowBase.prototype.oncomplete.apply(this)

    this.center()
    this.addScroll()

    this.OnResize.Subscribe(this.onResize, this)
    this.onResize() // otherwise -> #110897
}

TerceraBrokerMessageScreen.prototype.dispose = function ()
{
    this.OnResize.UnSubscribe(this.onResize, this)

    TerceraWindowBase.prototype.dispose.apply(this)
}

TerceraBrokerMessageScreen.prototype.localize = function ()
{
    this.set({
        header: Resources.getResource('BrokerMessage.Text'),
        noHtmlMessage: Resources.getResource('chart.NoData'),
        okButtonText: Resources.getResource('BrokerMessage.OK'),
        cancelButtonText: Resources.getResource('BrokerMessage.Cancel')
    })
}

TerceraBrokerMessageScreen.prototype.onOKClick = function ()
{
    let msgInfo = this.get('messageInfo')

    if (msgInfo.TextMessageType === TextMessageType.BROKER_MESSAGE_URGENT_TYPE && !msgInfo.ResponseCases)
    {
        msgInfo.Date = new Date()
        DataCache.SendBrokerMessageReport(msgInfo)  // закоментовано, згодом відновлено згідно з #110904 (для urgent BM було додано логіювання у #84587, судячи з усього зараз ця логіка вже є застарілою і непотрібною) 
    }

    this.processResponseCasesIfNeed()      // #92056 send answer if msg has cases

    this.close()
}

TerceraBrokerMessageScreen.prototype.onCancelClick = function ()
{
    this.close()
    Connection.disconnect()
}

TerceraBrokerMessageScreen.prototype.close = function (fromCloseBTN = false)
{
    TerceraWindowBase.prototype.close.apply(this)

    this.processNotificationWasRead()

    if (this.CloseHandler)
        this.CloseHandler()

    if (this.CustomButtonsXCloseHandler && fromCloseBTN)
        this.CustomButtonsXCloseHandler()
}

TerceraBrokerMessageScreen.prototype.onMessageInfoChange = function (messageInfo)
{
    if (!messageInfo)
        return

    this.set('style_addition_footer', '');

    let msgType = messageInfo.TextMessageType

    if (msgType === TextMessageType.BROKER_MESSAGE_URGENT_TYPE ||
        msgType === TextMessageType.BROKER_MESSAGE_DAILY_WELCOME_TYPE ||
        msgType === TextMessageType.BROKER_MESSAGE_NOTIFICATION_TYPE)   // если в (51) месседже не передано доп инфо ни для одной кнопки, то логика будет текущей, просто показываем стандартный брокер месседж с кнопкой ОК
    {
        this.set({
            cancelButtonVisible: false
        })
    }

    if (msgType === TextMessageType.BROKER_MESSAGE_NOTIFICATION_TYPE)
    {
        let cBtns = messageInfo.CustomButtons
        if (cBtns && cBtns.length)
        {
            let m = TerceraBrokerMessageScreen.BTN_MARGIN,
                fH = m + (TerceraBrokerMessageScreen.BTN_HEIGHT + m) * cBtns.length

            this.set({
                okButtonVisible: false,
                closeBtnVisible: true,
                footer_height: fH,
                style_addition_footer: 'js-TerceraBrokerMessageScreen-AdditionalFooter',
                customButtons: cBtns
            })

            this.createCustomButtonsClickEvents()

            this.CustomButtonsXCloseHandler = () => this.processResponseCustomButtons(TerceraBrokerMessageScreen.BM_CLOSED)
        }
    }
}

TerceraBrokerMessageScreen.prototype.createCustomButtonsClickEvents = function ()
{
    let clickEvent = function (url, customBtnTitle)
    {
        UrlUtils.openFixedURL(url)
        this.processResponseCustomButtons(customBtnTitle)
        this.close()
    }

    let cbs = this.get('customButtons')
    for (let i = 0; i < cbs.length; i++)
        this.on('onCustomButton' + i + 'Click', clickEvent.bind(this, cbs[i].URL, cbs[i].TitleForResponse))
}

TerceraBrokerMessageScreen.prototype.processResponseCasesIfNeed = function ()       // https://tp.traderevolution.com/entity/92056 send BrokerResponseMessage if need
{
    let msgInfo = this.get('messageInfo')
    if (!msgInfo) return

    if (msgInfo.ResponseCases)
    {
        let userID = DataCache.getUserID(),
            userName = DataCache.getUserName(),
            brokerMsgID = msgInfo.ID,
            screenID = TerceraBrokerMessageScreen.ID_CONST_PREFIX + brokerMsgID,
            iframeContainer = document.getElementById(screenID),                // iframeContainer
            iframe = iframeContainer ? iframeContainer.childNodes[0] : null,    // iframe
            htmlContent = iframe ? iframe.contentWindow.document : null         

        if (htmlContent)
        {
            let inputArr = htmlContent.getElementsByTagName('input')
            for (let i = 0; i < inputArr.length; i++)
            {
                let inp = inputArr[i],
                    checked = inp.checked,
                    value = inp.getAttribute('value')

                if (checked)
                {
                    value = (value == 'yes').toString()     // 'true' or 'false'
                    DataCache.SendBrokerResponseMessage(brokerMsgID, value, userID, userName, BrokerMessageResponseType.RADIO_BTNS, msgInfo.ClusterNode)

                    return // на десктопе предусмотрено и для checkBox-ов, но в доке явно сказано о radioButton-ах
                }
            }
        }
    }
}

TerceraBrokerMessageScreen.prototype.processResponseCustomButtons = function (cBtnTitle)       // https://tp.traderevolution.com/entity/109652 send BrokerResponseMessage when custom buttons clicked
{
    let msgInfo = this.get('messageInfo')
    if (!msgInfo) return

    let userID = DataCache.getUserID(),
        userName = DataCache.getUserName(),
        brokerMsgID = msgInfo.ID,
        value = cBtnTitle.toString()

    DataCache.SendBrokerResponseMessage(brokerMsgID, value, userID, userName, BrokerMessageResponseType.CUSTOM_BTNS, msgInfo.ClusterNode)
}

TerceraBrokerMessageScreen.prototype.processNotificationWasRead = function ()
{
    let msgInfo = this.get('messageInfo')
    if (!msgInfo) return

    if (msgInfo.TextMessageType !== TextMessageType.BROKER_MESSAGE_NOTIFICATION_TYPE || msgInfo.ShowPopUp === false)
        return

    let userID = DataCache.getUserID(),
        userName = DataCache.getUserName(),
        brokerMsgID = msgInfo.ID

    DataCache.SendBrokerResponseMessage(brokerMsgID, '', userID, userName, BrokerMessageResponseType.WAS_READ, msgInfo.ClusterNode)
}

TerceraBrokerMessageScreen.prototype.onResize = function ()
{
    let width = this.get('width'),
        height = this.get('height'),
        fHeight = this.get('footer_height')

    this.set({
        brokerMSGContentWidth: width,
        brokerMSGContentHeight: height - fHeight - TerceraBrokerMessageScreen.HEADER
    })
}

TerceraBrokerMessageScreen.prototype.addScroll = function ()
{
    return // reworked with iframe in 114020 -> no need to add scroll (iframe has its own internal one) 

    // var div = this.find('.js-broker-message-content')
    // if (div) HtmlScroll.addScroll(div)
}

TerceraBrokerMessageScreen.Show = function (messageInfo)
{
    if (!SessionSettings.OnBoardingWasShown())
    {   // #110922 если нужно показать OnBoarding то BrokerMessage покажем после его завершения 
        TerceraOnboardingScreen.AddOnShownSubscribe(TerceraBrokerMessageScreen.Show.bind(null, messageInfo))
        return
    }

    var scr = new TerceraBrokerMessageScreen()
    scr.set({
        messageInfo: messageInfo,
        brokerMsgScreenID: messageInfo ? TerceraBrokerMessageScreen.ID_CONST_PREFIX + messageInfo.ID : null
    })

    if (messageInfo.Name)
        scr.set({ header: messageInfo.Name })

    MainWindowManager.MainWindow.addControl(scr)
    scr.setFocus()
    scr.AsyncClosePromise = new Promise(function (resolve) { this.CloseHandler = resolve }.bind(scr))
    MainWindowManager.AsyncCloseObject = scr
}

TerceraBrokerMessageScreen.ID_CONST_PREFIX = 'BrokerScreenID='
TerceraBrokerMessageScreen.BM_CLOSED = "BM closed"
TerceraBrokerMessageScreen.HEIGHTFOOTER = 47
TerceraBrokerMessageScreen.BTN_HEIGHT = 25
TerceraBrokerMessageScreen.BTN_MARGIN = 10
TerceraBrokerMessageScreen.HEADER = 25

let brokerScrHanl = brokerMessageScreenHandler;
brokerScrHanl.Show = TerceraBrokerMessageScreen.Show;