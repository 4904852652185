// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { Resources } from '../../../Commons/properties/Resources';
import { VideoWidgetPanelSideBar } from '../../elements/SideBar/SideBarPanels/VideoWidgetPanelSideBar';
import { TerceraSideBarButton } from '../../elements/SideBar/TerceraSideBarButton';
import { ControlsTypes } from '../../UtilsClasses/FactoryConstants';

export class VideoWidgetButton extends TerceraSideBarButton {
    private readonly defaultBtnTextKey: string = 'panel.VideoWidget';
    private readonly tooltipKey: string = 'panel.VideoWidget.tt';

    constructor () {
        super();
    }

    getType (): ControlsTypes {
        return ControlsTypes.VideoWidgetButton;
    }

    oncomplete (): void {
        super.oncomplete();

        VideoWidgetPanelSideBar.OnChangeBtnStateEvents.Subscribe(this.ChangeBlinkStatus, this);
        this.observe('liveStreamOnAir', this.onLiveStreamOnAirChanged);
        this.localize();
    }

    localize (): void {
        void this.set({ text: Resources.getResource(this.defaultBtnTextKey) });
    }

    onLiveStreamOnAirChanged (value: boolean): void {
        void this.set({
            svgStyle: value ? 'videoWidgetBtnLive' : 'videoWidgetBtn',
            text: Resources.getResource(this.defaultBtnTextKey)
        });
    }

    ChangeBlinkStatus (liveStreamOnAir): void {
        void this.set('liveStreamOnAir', liveStreamOnAir);
    }

    onteardown (): void {
        VideoWidgetPanelSideBar.OnChangeBtnStateEvents.UnSubscribe(this.ChangeBlinkStatus, this);
    }
}

TerceraSideBarButton.extendWith(VideoWidgetButton,
    {
        data: function () {
            return {
                text: '',
                liveStreamOnAir: false, // If there are live streams now, then the button will have a different text and view: js-button-video-widget.live
                svgStyle: 'videoWidgetBtn'
            };
        }
    });
