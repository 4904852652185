// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { MainWindowManager } from "../../UtilsClasses/MainWindowManager.ts";
import { Resources } from "../../../Commons/properties/Resources.ts";
import { QuickTreeNode } from "../QuickTree/QuickTreeNode.ts";
import { TerceraQuickTree } from "../QuickTree/TerceraQuickTree.ts";
import { TerceraButton } from "../TerceraButton.ts";
import { TerceraLabel } from "../TerceraLabel.js";
import { TerceraTextBox } from "../TerceraTextBox.js";
import { TerceraLookupDropDownForm } from "./TerceraLookupDropDownForm.js";
import { IndicatorManager } from "../../../Commons/cache/indicators/IndicatorManager.ts";
import { TerceraIndicatorLookupDropDownFormTemplate } from "../../../templates.js";

export let TerceraIndicatorLookupDropDownForm = TerceraLookupDropDownForm.extend({
    partials: { bodyPartial: TerceraIndicatorLookupDropDownFormTemplate }
});

TerceraIndicatorLookupDropDownForm.prototype.initializationComponents = function ()
{
    this.textBox = this.Controls.textBox;

    let qtr = this.Controls.quickTree;
    if (qtr && qtr.quickTree)
        this.quickTree = qtr;
}

TerceraIndicatorLookupDropDownForm.prototype.getType = function () { return 'TerceraIndicatorLookupDropDownForm' };

TerceraIndicatorLookupDropDownForm.prototype.fillTree = function ()
{
    var startNode = new QuickTreeNode();;

    var filter = this.currentNameFilter;
    var procs = this.items;

    if (filter)
        filter.toLowerCase();

    var visibleIndicatorCount = 0;

    var len = procs.length;
    let map = {};
    for (var i = 0; i < len; i++)
    {
        var handler = procs[i];

        if (filter)
            if (handler.ShortName.toLowerCase().indexOf(filter) === -1 && handler.FullName.toLowerCase().indexOf(filter) === -1)
                continue;

        visibleIndicatorCount++;

        let parentNode = map[handler.DisplayGroup];
        if (!parentNode)
        {
            parentNode = new QuickTreeNode();
            parentNode.nodeText = Resources.getResource("IndicatorGroup." + handler.DisplayGroup);
            parentNode.tag = null;
            parentNode.collapsed = false;
            map[handler.DisplayGroup] = parentNode;

            startNode.AddChildNode(parentNode);
        }

        var subNode = new QuickTreeNode();
        subNode.nodeText = this.getNodeText(handler);
        subNode.tag = handler;

        parentNode.AddChildNode(subNode);
    }

    var qt = this.quickTree.quickTree;

    qt.Clear();
    qt.AddNodeRange(startNode.childNodes);
    this.quickTree.setSizes();

    this.updateVisibleIndicatorsCount(visibleIndicatorCount);
};

TerceraIndicatorLookupDropDownForm.prototype.getNodeText = function (item)
{
    const shortName =  Resources.getResource(item.ShortNameLocKey != null ? item.ShortNameLocKey : item.ShortName);
    const fullName =  Resources.getResource(item.FullNameLocKey != null ? item.FullNameLocKey : item.FullName);

    return `${shortName} (${fullName})`;
};

TerceraIndicatorLookupDropDownForm.prototype.updateVisibleIndicatorsCount = function (visibleIndicatorsCount)
{
    this.set('labelCountText', visibleIndicatorsCount ?
        Resources.getResource('screen.lookup.scripts.showing').replace('{1}', visibleIndicatorsCount) :
        Resources.getResource('screen.lookup.scripts.noitems'));
};

TerceraIndicatorLookupDropDownForm.prototype.onAddIndicatorBtnClicked = function (visibleIndicatorsCount)
{
    if (this.quickTree && this.quickTree.quickTree)
    {
        let selectedNode = this.quickTree.quickTree.selectedNode;
        if (selectedNode) this.callBack(selectedNode.tag);
        this.close();
    }
};

TerceraIndicatorLookupDropDownForm.PopulateWithFolderNodes = function (handler, curNode)
{
    let folderNode = new QuickTreeNode();
    folderNode.nodeText = handler.DisplayGroup;
    folderNode.tag = true;
    curNode.AddChildNode(folderNode);
    return folderNode;
};

TerceraIndicatorLookupDropDownForm.ShowForm = function (params)
{
    let instanceWaiter = null
    if (!MainWindowManager.TerceraIndicatorLookupDropDownForm)
        instanceWaiter = TerceraIndicatorLookupDropDownForm.createInstance().then((instance) =>
        {
            MainWindowManager.TerceraIndicatorLookupDropDownForm = instance
            return instance;
        })
    else
        instanceWaiter = Promise.resolve(MainWindowManager.TerceraIndicatorLookupDropDownForm)

    instanceWaiter.then((instance) =>
    {
        instance.textBox.set('text', '');
        params.isMultiSelect = false;
        params.items = IndicatorManager.GetIndicatorItemsList();
        params.isCentered = true;
        instance.showForm(params)
    })
};

TerceraIndicatorLookupDropDownForm.createInstance = function ()
{
    let lookup = new TerceraIndicatorLookupDropDownForm();
    lookup.setBounds(0, 0, 410, 485);
    lookup.set('visible', false);
    MainWindowManager.MainWindow.addControl(lookup);

    return lookup.OnCompliteWaiter;;
};