// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts";
import { TerceraProductsComponent } from "../../templates.js";
import { EntitlementSubscribeDocumentScreen } from "../screen/EntitlementSubscribeDocumentScreen.js";
import { TerceraMessageBox } from "../screen/TerceraMessageBox.js";
import { Control } from "./Control.js";
import { ThemeManager } from "../misc/ThemeManager.ts";
import { RulesSet } from "../../Utils/Rules/RulesSet.ts";
import { ConfirmationTypesEnum } from "../../Utils/Trading/ConfirmationTypesEnum.ts";
import { GeneralSettings } from "../../Utils/GeneralSettings/GeneralSettings.ts";
import { EntitlementManager } from "../../Commons/cache/Entitlement/EntitlementManager.ts";
import { DataCache } from "../../Commons/DataCache.ts";
import { ApplicationInfo } from "../../Commons/ApplicationInfo.ts";

export let ProductsComponent = Control.extend({
    template: TerceraProductsComponent,
    data: function ()
    {
        return {
            isActive: false,
            isProcessing: false,
            visibleInfoBtn: false,
            nameProductText: '',
            labelProductText: '',
            valueProductTextBig: '',
            valueProductTextSmall: '',
            pictureUrl: ThemeManager.getImageFullUrlNew('EntitlementSystem/defaultImg.png'),
            webkitMod: false,
            reqTimeCreated: null,                       // same field as in this.ProductData but for tooltip's reactively update in computed RequestInfoTooltip
            // isDoublePrice: false
            IsUnsubscribeAllowed: true //hide unsubsbtn
        };
    },
    computed: {
        RequestInfoTooltip: function ()
        {
            if (ApplicationInfo.isExploreMode) {
                return Resources.getResource('IsAllowedResponceReason.NotAllowedByExploreMode'); 
            }
            let dateObj = this.get('reqTimeCreated')    // эта строка тут для того, чтобы при изменении reqTimeCreated вызывался этот метод и тултип обновлялся
            return EntitlementManager.GetReplacedStringWithProductData(this.ProductData, 'screen.products.CancelSubscribeRequestButton.tt')
        },
        RequestButtonsTooltip: function () {
            if (ApplicationInfo.isExploreMode) {
                return Resources.getResource('IsAllowedResponceReason.NotAllowedByExploreMode'); 
            }
            return '';
        }
    },
    ProductData: null,
    DefaultImage: null,
    myMsgBox: null,
    myDocumentScreen: null
});

ProductsComponent.prototype.getType = function () { return 'ProductsComponent' };

ProductsComponent.prototype.oncomplete = function ()
{
    Control.prototype.oncomplete.apply(this);
    this.DefaultImage = ThemeManager.getImageFullUrlNew('EntitlementSystem/defaultImg.png')

    this.on('subscribeClick', this.subscribeClick);
    this.on('unsubscribeClick', this.unsubscribeClick);
    this.on('cancelSubscribeRequest', this.cancelSubscribeRequestClick);
    this.observe("propertiesProduct", this.getPropertiesProduct);
    Resources.onLocaleChange.Subscribe(this.localize, this)
    this.localize();
};

ProductsComponent.prototype.dispose = function ()
{
    Resources.onLocaleChange.UnSubscribe(this.localize, this)

    Control.prototype.dispose.call(this)
}

ProductsComponent.prototype.getPropertiesProduct = function (newData)
{
    if (!newData)
        return

    this.ProductData = newData;
    if (newData.Description)
        this.set({ visibleInfoBtn: true, infoBtnTooltip: newData.Description });
    else
        this.set({ visibleInfoBtn: false, infoBtnTooltip: "" });

    this.set({ nameProductText: newData.Name })
    this.set({
        isProcessing: newData.isWaitForUnsubscribe || newData.isWaitForSubscribe,           // SAME AS BELOW!
        isActive: newData.isSubscribeActionDone,
        reqTimeCreated: newData.ReqTimeCreated,
        IsUnsubscribeAllowed: newData.IsUnsubscribeAllowed                                              // for tooltip's reactively update in computed RequestInfoTooltip
    })

    // this.ProductData.StatusUpdateHandler = function ()
    // {
    //     let pr = this.ProductData;
    this.set({ isProcessing: newData.isWaitForUnsubscribe || newData.isWaitForSubscribe })  // SAME AS ABOVE!
    // }.bind(this)

    if (newData.Image)
        this.set({ pictureUrl: newData.Image })
    else
        this.set({ pictureUrl: this.DefaultImage })

    this.correctWidthName(newData.Name)
    this.applySubscriberStatus();
};

ProductsComponent.prototype.correctWidthName = function (Name)
{
    //100 - крайняя граница первой строки
    //190 - крайняя граница второй строки
    let len = Name.length
    let width = len * 6;

    this.set({ webkitMod: false })
    if (width > 100)
        this.set({ webkitMod: true })

    if (width > 190)
        this.set({ nameProductTextTooltip: Name })
};

ProductsComponent.prototype.subscribeClick = function ()
{
    if (ApplicationInfo.isExploreMode) {
        return;
    }

    if (!this.ProductData)
        return

    let OkCb = function (showNextTime)
    {
        GeneralSettings.Confirmations.updateConfirmation(ConfirmationTypesEnum.SubscriptionsAndUnsubscriptions, showNextTime)

        let data = this.ProductData
        if (data.HasDocument)
            EntitlementManager.GetDocuments(data.Id).then(this.ShowDocumentsSubscr.bind(this))
        else
            EntitlementManager.ProductSubscribeRequest(data.Id, null)
    }.bind(this)

    if (GeneralSettings.Confirmations.useConfirmation(ConfirmationTypesEnum.SubscriptionsAndUnsubscriptions))
    {
        let msgBox = TerceraMessageBox.Show(
            Resources.getResource('screen.products.SubscribeRequestConfirmScreen.header'),
            Resources.getResource('screen.products.SubscribeRequestConfirmScreen.text'),
            TerceraMessageBox.msgType.Info, OkCb, null, true, true, null,
            { okText: Resources.getResource('screen.products.SubscribeRequestConfirmScreen.OkBtnText') }
        );

        this.myMsgBox = msgBox
        msgBox.customDisposeHandler = function () { this.myMsgBox = null }.bind(this)
    }
    else
        OkCb()
};

ProductsComponent.prototype.unsubscribeClick = function ()
{
    if (ApplicationInfo.isExploreMode) {
        return;
    }

    let data = this.ProductData;
    if (!data)
        return

    if (this.myMsgBox)
        return

    if (!data.IsUnsubscribeAllowed)
        return

    let Id = data.Id
    let OkCb = function (showNextTime)
    {
        GeneralSettings.Confirmations.updateConfirmation(ConfirmationTypesEnum.SubscriptionsAndUnsubscriptions, showNextTime)
        EntitlementManager.ProductUnSubscribeRequest(Id)
    }
    let NoCb = function (showNextTime) { GeneralSettings.Confirmations.updateConfirmation(ConfirmationTypesEnum.SubscriptionsAndUnsubscriptions, showNextTime) }

    if (GeneralSettings.Confirmations.useConfirmation(ConfirmationTypesEnum.SubscriptionsAndUnsubscriptions))
    {
        let msg = Resources.getResource('panel.Products.Unsubscribe.PopupMessage')//.replace("{0}", data.Name)  // #108197
        let msgBox = TerceraMessageBox.Show(
            Resources.getResource('AdditionalProperty.Unsubscribe'),
            msg,
            TerceraMessageBox.msgType.Question,
            OkCb,
            NoCb,
            true,
            null,
            Resources.getResource('screen.products.ButtonUnsubscribe.Click')
        );

        this.myMsgBox = msgBox
        msgBox.customDisposeHandler = function () { this.myMsgBox = null }.bind(this)
    }
    else
        EntitlementManager.ProductUnSubscribeRequest(Id)
};

ProductsComponent.prototype.cancelSubscribeRequestClick = function ()
{
    if (ApplicationInfo.isExploreMode) {
        return;
    }

    let data = this.ProductData;
    if (!data)
        return

    if (this.myMsgBox)
        return

    this.myMsgBox = EntitlementManager.GetMessageBoxForProductCancelRequest(this, data);
};

ProductsComponent.prototype.ShowDocumentsSubscr = function (documents)
{
    if (!documents)
        return

    if (this.myDocumentScreen)
        return

    let documentScreen = EntitlementSubscribeDocumentScreen.Show(this.ProductData.Id, documents);
    this.myDocumentScreen = documentScreen;
    documentScreen.customDisposeHandler = function () { this.myDocumentScreen = null }.bind(this);
}

ProductsComponent.prototype.localize = function ()
{
    this.set({
        buttonSubscribeText: Resources.getResource('screen.products.ButtonSubscribe'),
        buttonSubscribedText: Resources.getResource('screen.products.ButtonSubscribed'),
        buttonCancelSubscribeRequestText: Resources.getResource('screen.products.CancelSubscribeRequestButton.text'),
        reqTimeCreated: this.get('reqTimeCreated'), // for localizing  tooltip's reactively update in computed RequestInfoTooltip
        buttonUnsubscribeText: Resources.getResource('screen.products.ButtonUnsubscribe')
    });
};

ProductsComponent.prototype.applySubscriberStatus = function ()
{
    let priceText = "";
    let product = this.ProductData;
    if (!product)
        return

    if (isNaN(product.ProPrice) && isNaN(product.NonProPrice))
    {
        this.set({
            valueProductTextBig: ProductsComponent.FREE_PRODUCT_VALUE, valueProductTextSmall: "",
            valueProductTextBig2: "", valueProductTextSmall2: "", labelProductText: ""      // #108192
        })
        return
    }

    let status = parseInt(DataCache.getRuleNumberValueForMyUser(RulesSet.VALUE_MARKET_DATA_SUBSCRIBER_STATUS, 0), 10);
    let asset = DataCache.GetAssetById(product.CurrencyId);
    switch (status)
    {
        case SubscriberStatus.NotDefined:   //нужно отображать обе цены за использование продукта
            let nonProPriceText = isNaN(product.NonProPrice) ? ProductsComponent.FREE_PRODUCT_VALUE : product.NonProPrice;
            let proPriceText = isNaN(product.ProPrice) ? ProductsComponent.FREE_PRODUCT_VALUE : product.ProPrice;

            let nonProPriceLabelText = nonProPriceText === ProductsComponent.FREE_PRODUCT_VALUE ? ProductsComponent.FREE_PRODUCT_VALUE : asset.formatPrice(nonProPriceText);
            let proPriceLabelText = proPriceText === ProductsComponent.FREE_PRODUCT_VALUE ? ProductsComponent.FREE_PRODUCT_VALUE : asset.formatPrice(proPriceText);

            let labelText = "Non-pro price: " + nonProPriceLabelText + "\n Pro price: " + proPriceLabelText;
            this.set({ labelProductText: labelText });

            this.setFormattedValues(nonProPriceText, proPriceText, asset);
            break;
        case SubscriberStatus.NotRequired:  //отображаем Non-pro price.
        case SubscriberStatus.NonPro:       //отображаем Non-pro price.
            if (isNaN(product.NonProPrice))
                priceText = ProductsComponent.FREE_PRODUCT_VALUE;
            else
                priceText = product.NonProPrice;

            this.setFormattedValues(priceText, null, asset);
            break;
        case SubscriberStatus.Pro:          //отображаем значение из Pro price
            if (isNaN(product.ProPrice))
                priceText = ProductsComponent.FREE_PRODUCT_VALUE;
            else
                priceText = product.ProPrice;

            this.setFormattedValues(priceText, null, asset);
            break;
        default:                            //по умолчанию тоже Non-pro price
            if (isNaN(product.NonProPrice))
                priceText = ProductsComponent.FREE_PRODUCT_VALUE;
            else
                priceText = product.NonProPrice;

            this.setFormattedValues(priceText, null, asset);
            break;
    };
};

ProductsComponent.prototype.setFormattedValues = function (price1, price2, asset)
{
    if (price1 === ProductsComponent.FREE_PRODUCT_VALUE)
    {
        this.set({ valueProductTextBig: ProductsComponent.FREE_PRODUCT_VALUE, valueProductTextSmall: "" })
    }
    else
    {
        let noCurrency = price2 ? true : false;
        let bigValue = Math.trunc(price1);
        let smallValue = price1 - bigValue;

        let bigValueFormat = ProductsComponent.Zero_formater.format(bigValue);
        let smallValueFormat = asset.formatPrice(smallValue, noCurrency).substring(1);

        if (price2 === ProductsComponent.FREE_PRODUCT_VALUE)
            smallValueFormat = asset.formatPrice(smallValue, false).substring(1);

        this.set({
            valueProductTextBig: bigValueFormat,
            valueProductTextSmall: smallValueFormat
        })
    }

    if (!price2)
        return

    if (price2 === ProductsComponent.FREE_PRODUCT_VALUE)
    {
        this.set({ valueProductTextBig2: "/" + ProductsComponent.FREE_PRODUCT_VALUE, valueProductTextSmall2: "" })
    }
    else
    {
        let bigValue = Math.trunc(price2);
        let smallValue = price2 - bigValue;

        let bigValueFormat = "/" + ProductsComponent.Zero_formater.format(bigValue);
        let smallValueFormat = asset.formatPrice(smallValue).substring(1);

        this.set({
            valueProductTextBig2: bigValueFormat,
            valueProductTextSmall2: smallValueFormat
        })
    }
};

let SubscriberStatus =
{
    /// <summary>
    /// статус не определен
    /// </summary>
    NotDefined: 0,
    /// <summary>
    /// статус подписки не имеет значения
    /// </summary>
    NotRequired: 1,
    /// <summary>
    /// состояние определяет стоимость подписки на продукт
    /// </summary>
    NonPro: 2,
    /// <summary>
    /// состояние определяет стоимость подписки на продукт
    /// </summary>
    Pro: 3
};

ProductsComponent.Zero_formater = new Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
ProductsComponent.FREE_PRODUCT_VALUE = "FREE";