// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { TerceraPictureBoxTemplate } from "../../templates.js";
import { Control } from "./Control.js";

export let TerceraPictureBox = Control.extend({
    template: TerceraPictureBoxTemplate,
    data: function ()
    {
        return {
            imageUrl: '',
            useCustomSize: true,
            imageLoaded: false,
            component_style: 'js-picture-box'
        };
    }
});

TerceraPictureBox.prototype.oninit = function ()
{
    Control.prototype.oninit.apply(this);
    this.on('imageLoaded', this.onImageLoaded);
    this.on('imageNotLoaded', this.onImageNotLoaded);
    this.observe('width height', this.onSizeChanged);
};

TerceraPictureBox.prototype.onSizeChanged = function ()
{
    this.fire(TerceraPictureBox.Events.SizeChanged);
};

TerceraPictureBox.prototype.onImageLoaded = function ()
{
    this.set('imageLoaded', true);

    if (this.get('useCustomSize')) return;

    var img = this.find('img');
    if (!img) return;

    this.set({
        width: img.naturalWidth,
        height: img.naturalHeight
    });
};

TerceraPictureBox.prototype.onImageNotLoaded = function ()
{
    this.set('imageLoaded', false);
};

TerceraPictureBox.Events = {
    SizeChanged: 'sizeChanged'
};