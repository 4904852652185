// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../../Commons/properties/Resources';
import { DataCacheToolRayType, DataCacheToolType } from '../DataCacheToolEnums';
import { Color } from '../../../Commons/Graphics';
import { DataCacheTool } from '../DataCacheTool';
import { BaseFibonacciDataCacheTool } from './BaseFibonacciDataCacheTool';
import { DynProperty, ColorStyleWidth } from '../../DynProperty';

export class FibonacciPhiChannelDataCacheTool extends BaseFibonacciDataCacheTool {
    /**
     * Constructor for FibonacciPhiChannelDataCacheTool
     */
    constructor () {
        super();
        this.ToolType = DataCacheToolType.FibonacciPhiChannel;
        this.InitPoints(3);
    }

    /**
     * Initializes the block for the tool
     */
    protected InitBlock (): void {
        super.InitBlock();
        this.fibLevel = [0, 0.61, 1, 1.61, 2.61, 4.23, 6.85];
    }

    /**
     * Gets the maximum points count required for the tool
     * @returns {number} - Maximum points count
     */
    MaxPointsCount (): number {
        return 3;
    }

    /**
     * Gets the minimum points count required for the tool
     * @returns {number} - Minimum points count
     */
    MinPointsCount (): number {
        return 3;
    }

    /**
     * Properties for FibonacciPhiChannelDataCacheTool
     * @returns {DynProperty[]} - Array of dynamic properties
     */
    Properties (): DynProperty[] {
        const props = super.Properties();

        props.push(new DynProperty(Resources.getResource('EnableNegativeFibonacciValue'),
            true,
            DynProperty.BOOLEAN,
            /* DynProperty.FIBONACCI_PARAMETERS_GROUP */DynProperty.HIDDEN_GROUP));

        let p = new DynProperty('Left ray',
            this.RayType === DataCacheToolRayType.LeftRay || this.RayType === DataCacheToolRayType.DoubleRay,
            DynProperty.BOOLEAN,
            DynProperty.VISUAL_GROUP);
        p.sortIndex = 0;
        props.push(p);

        p = new DynProperty('Right ray',
            this.RayType === DataCacheToolRayType.RightRay || this.RayType === DataCacheToolRayType.DoubleRay,
            DynProperty.BOOLEAN,
            DynProperty.VISUAL_GROUP);
        p.sortIndex = 1;
        props.push(p);

        const colorStyleWidthRays = new ColorStyleWidth(Color.Empty, this.styleRay, 1);
        colorStyleWidthRays.enableStyleOnly();
        props.push(new DynProperty('Ray style', colorStyleWidthRays, DynProperty.COLOR_STYLE_WIDTH, DynProperty.VISUAL_GROUP));

        return props;
    }

    /**
     * Callback function for handling dynamic property changes
     * @param {DynProperty[]} properties - Array of dynamic properties
     */
    callBack (properties: DynProperty[]): void {
        super.callBack(properties);

        const prop = DynProperty.getPropertyByName(properties, 'Ray style');
        if (prop != null) this.StyleRay = prop.value.Style;

        this.RayType = DataCacheTool.GetRayType(properties);
    }
}
