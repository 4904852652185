// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { ErrorInformationStorage } from '../../../../Commons/ErrorInformationStorage';
import { Resources } from '../../../../Commons/properties/Resources';
import { type DataBuffer } from '../DataBuffer';
import { FieldsFactory } from '../Factory/FieldsFactory';
import { PFixField } from './PFixField';

// TODO. Params object.
export class PFixFieldSet {
    public GroupId = -1;
    public factory: FieldsFactory;
    public fieldSet = [];

    constructor (groupId?, buffer?, pfixFieldSet?) {
        if (groupId && groupId !== null) {
            this.GroupId = groupId;
        }

        this.factory = FieldsFactory.getInstance();

        if (buffer && buffer !== null) {
            this.Read(buffer);
        }

        // TODO. Ok? Don't want to copy entire fieldset.
        if (pfixFieldSet) {
            this.fieldSet = pfixFieldSet.fieldSet;
        }
    }

    public Count (): number {
        return this.fieldSet.length;
    }

    public Read (buffer): void {
        while (!buffer.EndOfBuffer()) {
            const fieldId = buffer.ReadShort();

            const field = this.factory.getFieldById(fieldId);
            if (field != null) {
                field.ReadValue(buffer);
                this.SetField(field);
            } else {
                break;
            }
        }
    }

    public Write (buffer: DataBuffer, maxServerNumber: number): void {
        const fieldSet = this.fieldSet;
        const count = fieldSet.length;
        for (let i = count - 1; i >= 0; i--) {
            const field = fieldSet[i];
            if (field.FieldId <= maxServerNumber) {
                field.Write(buffer, maxServerNumber);
            }
        }
    }

    public GetField (field): PFixField | null {
    // field может быть как самим филдом, так и номером
        if (typeof (field) !== 'number') {
            field = field.FieldId;
        }

        const fieldSet = this.fieldSet;
        const count = fieldSet.length;
        for (let i = count - 1; i >= 0; i--) {
            const fixField = fieldSet[i];
            if (fixField.FieldId === field) {
                return fixField;
            }
        }
        return null;
    }

    public GetGroups (groupId, groupCtor?): any[] {
        const res = [];

        const fieldSet = this.fieldSet;
        const len = fieldSet.length;
        for (let i = 0; i < len; i++) {
            const fixField = fieldSet[i];
            if (fixField.FieldId === FieldsFactory.FIELD_GROUP) {
                const fields = fixField.Value;
                if (fields.GroupId === groupId) {
                    res.push(groupCtor ? new groupCtor(fields) : fields);
                }
            }
        }
        return res;
    }

    public GetGroup (groupId): any {
        const fieldSet = this.fieldSet;
        const len = fieldSet.length;
        for (let i = 0; i < len; i++) {
            const field = fieldSet[i];
            if (field.FieldId !== FieldsFactory.FIELD_GROUP) {
                continue;
            }

            const fields = field.Value;
            if (fields.GroupId === groupId) {
                return fields;
            }
        }
        return null;
    }

    public IsSetField (field): boolean {
        return (this.GetField(field) !== null);
    }

    public GetValue (field): any {
        const fixField = this.GetField(field);
        return fixField !== null ? fixField.Value : null;
    }

    public SetField (field, value?): void {
    // field может быть как самим филдом, так и номером
        if (typeof (field) !== 'number') {
            value = field.Value;
            field = field.FieldId;
        }

        let fixField = field !== FieldsFactory.FIELD_GROUP ? this.GetField(field) : null;
        if (fixField != null) // групп может быть много, на каждую создаем свое поле
        {
            fixField.Value = value;
        } else {
            fixField = this.factory.getFieldById(field);
            fixField.Value = value;
            this.fieldSet.push(fixField);
        }
    }

    public SetFields (fields): void {
        const fieldSet = fields.fieldSet;
        const count = fieldSet.length;
        for (let i = count - 1; i >= 0; i--) {
            this.fieldSet.push(fieldSet[i]);
        }
    }

    public AddGroup (value): void {
        this.SetField(FieldsFactory.FIELD_GROUP, value);
    }

    public static SerealizeForWorkerStatic (fieldSet): any[] {
        const result = [];

        let i = 0;
        const len = fieldSet.length;
        for (i; i < len; i++) {
            const fs = fieldSet[i];
            if (fs.FieldId === 74) {
                result.push({ FieldId: fs.FieldId, Value: PFixFieldSet.SerealizeForWorkerStatic(fs.Value.fieldSet), GroupId: fs.Value.GroupId });
            } else {
                result.push({ FieldId: fs.FieldId, Value: fs.Value });
            }
        }

        return result;
    }

    public static DeSerealizeForWorkerStatic (fieldSet): PFixFieldSet {
        const result = new PFixFieldSet();

        let i = 0;
        const len = fieldSet.length;
        for (i; i < len; i++) {
            const fs = fieldSet[i];
            const field = new PFixField();
            if (fs.FieldId === 74) {
                field.FieldId = fs.FieldId;
                field.Value = PFixFieldSet.DeSerealizeForWorkerStatic(fs.Value);
                field.Value.GroupId = fs.GroupId;
            } else {
                field.FieldId = fs.FieldId;
                field.Value = fs.Value;
            }
            result.fieldSet.push(field);
        }

        return result;
    }

    public toString (): string {
        try {
            if (this.fieldSet == null) {
                return '[Empty]';
            }

            let sb = '';
            for (let i = 0; i < this.fieldSet.length; i++) {
                const field = this.fieldSet[i];
                sb += field.FieldId;
                sb += '=';
                if (field.FieldId === FieldsFactory.FIELD_COMPRESSED_DATA) {
                    sb += field.Value !== null ? '[]' : '';
                } else {
                    sb += field.Value !== null ? field.SerializeValue() : '';
                }
                sb += ';';
                sb += ' ';
            }
            return sb;
        } catch (ex) {
        // ErrorInformationStorage.GetException(ex);
            console.log(ex + '  Critical error in FieldSet::toString');
            return '[Empty]';
        }
    }

    public ToStringView (): string {
        try {
            if (this.fieldSet == null) {
                return '[Empty]';
            }

            let sb = '';

            const code = this.GetValue(FieldsFactory.FIELD_MSG_TYPE);
            sb += "Code='" + FieldsFactory.GetMsgTypeName(code) + "' ";

            for (let i = 0; i < this.fieldSet.length; i++) {
                const field = this.fieldSet[i];
                if (field.FieldId == FieldsFactory.FIELD_MSG_TYPE) {
                    continue;
                }
                sb += FieldsFactory.GetFieldName(field.FieldId);
                sb += '=';

                if (field.FieldId == FieldsFactory.FIELD_GROUP) {
                    sb += '{';
                    sb += field.Value.ToStringView();
                    sb += '}';
                } else if (field.FieldId == FieldsFactory.FIELD_PASSWORD || field.FieldId == FieldsFactory.FIELD_VERIFICATION_PASSWORD || field.FieldId == FieldsFactory.FIELD_BRANDING_KEY) {
                // для пассворда подменяю содержимое на *, по требованию trade.tradeall.com:80, да и в принципе, логично.
                    sb += '\'';
                    sb += field.Value != null ? '*'/* new string('*', field.Value.toString().Length) */ : 'null';
                    sb += '\'';
                } else if (field.FieldId == FieldsFactory.FIELD_CLIENT_PANEL_ID || field.FieldId == FieldsFactory.FIELD_CLIENT_PANEL) {
                    sb += '\'';
                    sb += field.Value != null ? Resources.getResource('reports.' /* + Enum.GetName(typeof (PlacedFrom), */ + field.Value) : '';
                    sb += '\'';
                } else if (field.FieldId == FieldsFactory.FIELD_TIMESTAMP) {
                    sb += '\'';
                    sb += field.Value != null ? field.SerializeValue() : '';
                    sb += '\'';
                } else if (field.FieldId === FieldsFactory.FIELD_COMPRESSED_DATA) {
                    sb += '\'';
                    sb += '[]';
                    sb += '\'';
                } else {
                    sb += '\'';
                    sb += field.Value;
                    sb += '\'';
                }

                sb += ' ';
            }
            return sb;
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            console.log(ex + '  Critical error in FieldSet::toString');
            return '[Empty]';
        }
    }

    public GetData (): any[] {
        const array = [];

        const code = this.GetValue(FieldsFactory.FIELD_MSG_TYPE);

        array.push("Code='" + FieldsFactory.GetMsgTypeName(code) + "' ");
        array.push(this.GetDataRecursive());

        return array;
    }

    public GetDataRecursive (): any[] {
        const array = [];

        for (let i = 0; i < this.fieldSet.length; i++) {
            const field = this.fieldSet[i];
            if (field.FieldId == FieldsFactory.FIELD_MSG_TYPE) {
                continue;
            }

            if (field.FieldId == FieldsFactory.FIELD_GROUP) {
                const val = field.Value;

                array.push("Group='" + FieldsFactory.GetGroupName(val.GroupId) + "' ");
                array.push(val.GetDataRecursive());
            } else {
                let node = FieldsFactory.GetFieldName(field.FieldId) + '=';

                if (field.FieldId == FieldsFactory.FIELD_PASSWORD || field.FieldId == FieldsFactory.FIELD_VERIFICATION_PASSWORD) {
                // для пассворда подменяю содержимое на *, по требованию trade.tradeall.com:80, да и в принципе, логично.
                    node += "\'" + (field.Value != null ? /* new string('*', field.Value.toString().Length) */ '*' : 'null') + "\'";
                } else if (field.FieldId == FieldsFactory.FIELD_CLIENT_PANEL_ID || field.FieldId == FieldsFactory.FIELD_CLIENT_PANEL) {
                    node += "\'" + (field.Value != null ? Resources.getResource('reports.' + /* PlacedFrom */ field.Value) : '') + "\'";
                } else if (field.FieldId == FieldsFactory.FIELD_TIMESTAMP) {
                    node += "\'" + (field.Value != null ? field.SerializeValue() : '') + "\'";
                } else if (field.FieldId == FieldsFactory.FIELD_MESSAGE_CODES) {
                    node += "\'" + (field.Value != null ? field.toString() : '') + "\'";
                } else if (field.FieldId === FieldsFactory.FIELD_COMPRESSED_DATA) {
                    node += "\'" + (field.Value != null ? '[]' : '') + "\'";
                } else {
                    node += field.Value ? ("\'" + field.Value.toString()) + "\'" : ("\'" + field.Value + "\'");
                }

                array.push(node);
            }
        }

        return array;
    }

    public GetSendableObject (): any {
        const result: any = {};
        const length = this.fieldSet.length;
        result.GroupId = this.GroupId;
        result.fieldSet = [];
        for (let i = 0; i < length; i++) {
            const field = this.fieldSet[i];
            if (field.FieldId === 74) {
                result.fieldSet.push(this.GetSendableObjectFromField(field));
            } else {
                result.fieldSet.push(field);
            }
        }
        return result;
    }

    public GetSendableObjectFromField (field): any {
        const result: any = {};
        const length = field.Value.fieldSet.length;
        result.FieldId = field.FieldId;
        result.Value = {};
        const value = result.Value;
        value.GroupId = field.Value.GroupId;
        value.fieldSet = [];
        for (let i = 0; i < length; i++) {
            const fieldIn = field.Value.fieldSet[i];
            if (fieldIn.FieldId === 74) {
                value.fieldSet.push(this.GetSendableObjectFromField(fieldIn));
            } else {
                value.fieldSet.push(fieldIn);
            }
        }

        return result;
    }
}
