import { Control } from './Control_ts';
import { PanelsContainerControlTemplate } from '../../templates';
import { TerceraWindowBase } from '../screen/TerceraWindowBase_ts';

class PanelsContainerControlTabItem {
    public text: string;
    public panel: TerceraWindowBase;

    constructor (text: string, panel: TerceraWindowBase) {
        this.text = text;
        this.panel = panel;
    }
}

export class PanelsContainerControl extends Control {
    constructor () {
        super();
        this.onTabItemClicked = this.onTabItemClicked.bind(this);
        this.onCollapseButtonClicked = this.onCollapseButtonClicked.bind(this);
        this.onCollapsedChanged = this.onCollapsedChanged.bind(this);
        this.onSelectedTabItemChanged = this.onSelectedTabItemChanged.bind(this);
        this.onPanelHeaderChanged = this.onPanelHeaderChanged.bind(this);
    }

    public override oninit (): void {
        super.oninit();
        super.on('onTabItemClick', this.onTabItemClicked);
        super.on('onCollapseButtonClick', this.onCollapseButtonClicked);
        super.observe('isCollapsed', this.onCollapsedChanged);
        super.observe('selectedTabItem', this.onSelectedTabItemChanged);
    }

    public override async oncomplete (): Promise<void> {
        super.oncomplete();
        const panels = super.findAllComponents().filter(component => component instanceof TerceraWindowBase) as TerceraWindowBase[];
        for (let i = 0; i < panels.length; i++) {
            panels[i].observe('header', this.onPanelHeaderChanged);
        }
        const tabItems = [];
        for (let i = 0; i < panels.length; i++) {
            tabItems.push({
                text: panels[i].get('header'),
                panel: panels[i]
            });
        }
        await super.set('tabItems', tabItems);
        await this.onTabItemClicked(null, tabItems[0]);
    }

    private async onPanelHeaderChanged (): Promise<void> {
        const tabItems = super.get('tabItems');
        for (let i = 0; i < tabItems.length; i++) {
            const panel = tabItems[i].panel;
            tabItems[i].text = panel.get('header');
        }
        await super.set('tabItems', tabItems);
    }

    private async onTabItemClicked (event: any, tabItem: PanelsContainerControlTabItem): Promise<void> {
        void super.set('selectedTabItem', tabItem);
    }

    private async onSelectedTabItemChanged (): Promise<void> {
        const tabItems = super.get('tabItems');
        if (!isValidArray(tabItems)) {
            return;
        }
        const selectedTabItem = super.get('selectedTabItem');
        for (let i = 0; i < tabItems.length; i++) {
            if (tabItems[i] !== selectedTabItem) {
                await tabItems[i].panel.set('visible', false);
            }
        }
        if (!isNullOrUndefined(selectedTabItem)) {
            await selectedTabItem.panel.set('visible', true);
        }
        this.fire('activePanelChange');
    }

    private onCollapseButtonClicked (): void {
        const isCollapsed: boolean = super.get('isCollapsed');
        void super.set('isCollapsed', !isCollapsed);
    }

    private onCollapsedChanged (): void {
        const isCollapsed: boolean = super.get('isCollapsed');
        const selectedTabItem = super.get('selectedTabItem');
        if (!isNullOrUndefined(selectedTabItem)) {
            selectedTabItem.panel.set('visible', !isCollapsed);
        }
        this.fire('collapsedChange', isCollapsed);
    }
}

Control.extendWith(PanelsContainerControl, {
    template: PanelsContainerControlTemplate,
    data () {
        return {
            isShowCollapsedButton: false,
            isCollapsed: false,
            selectedTabItem: null,
            tabItems: []
        };
    }
});
