// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts";
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";
import { TerceraRiskWarningMessageScreenTemplate } from "../../templates.js";
import { TerceraButton } from "../elements/TerceraButton.ts";
import { TerceraWindowBase } from "./TerceraWindowBase.js";
import { riskWarningMessageScreenHandler } from "../../Utils/AppHandlers.js";
import { TerceraButtonStyle } from "../../Utils/Enums/ButtonEnums.ts";

export let TerceraRiskWarningMessageScreen = TerceraWindowBase.extend({
    data: function ()
    {
        return {
            movable: false,
            showHeader: true,
            showFooter: false,
            resizable: false,
            showFullscreenCloud: true,
            closeBtnVisible: false,

            zIndex: 300,
            width: 400,
            height: 245,
            errorTop: 0,

            header: "",
            okText: "",
            UserName: "",
            AccName: "",
            msgText: "",

            btnStyle: TerceraButtonStyle.Standard,
        };
    },
    partials: { bodyPartial: TerceraRiskWarningMessageScreenTemplate }
});

TerceraRiskWarningMessageScreen.prototype.getType = function () { return 'TerceraRiskWarningMessageScreen' };

TerceraRiskWarningMessageScreen.prototype.oninit = function ()
{
    TerceraWindowBase.prototype.oninit.apply(this);
    this.on('okClick', this.okClick);
};

TerceraRiskWarningMessageScreen.prototype.oncomplete = function ()
{
    TerceraWindowBase.prototype.oncomplete.apply(this);
    this.center();
};

TerceraRiskWarningMessageScreen.prototype.okClick = function ()
{
    this.close();
};

TerceraRiskWarningMessageScreen.Show = function (messageInfo)
{
    let scr = new TerceraRiskWarningMessageScreen();

    let userNameText = Resources.getResource(messageInfo.Data[0][0]) + ": " + messageInfo.Data[0][1];
    let accountNameText = Resources.getResource(messageInfo.Data[1][0]) + ": " + messageInfo.Data[1][1];
    let msgText = messageInfo.Data[2][1];

    scr.set({
        header: Resources.getResource(messageInfo.Name),
        okText: Resources.getResource('general.messageBox.ok'),
        UserName: userNameText,
        AccName: accountNameText,
        msgText: msgText,
    });

    MainWindowManager.MainWindow.addControl(scr);
    scr.setFocus();
};

let riskWarnHanl = riskWarningMessageScreenHandler;
riskWarnHanl.Show = TerceraRiskWarningMessageScreen.Show;