// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { MessageProcessorBase } from './MessageProcessorBase';
import { InitDataRequest } from '../Messages/InitDataRequest';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class InputInitDataRequstMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): InitDataRequest[] {
        return [new InitDataRequest(fieldSet)];
    }
}
