import { type TerceraXYChart } from '../TerceraXYChart';
import { type TerceraChartWindowBase } from '../Windows/TerceraChartWindowBase';
import { TerceraChartScrollerRenderer } from './TerceraChartScrollerRenderer';

export class TerceraChartXYScrollerRenderer extends TerceraChartScrollerRenderer<TerceraXYChart> {
    public override CalcScrollPosition (window: TerceraChartWindowBase, outSubj: { rightX: number, leftX: number }): boolean {
        const xyWindow = this.chart.mainWindow;
        if (xyWindow.FminFloatX === 0 && xyWindow.FmaxFloatX === 0) {
            outSubj.leftX = outSubj.rightX = 0;
            return false;
        }
        outSubj.leftX = Math.floor((this.insideRect.X + this.insideRect.Width * (xyWindow.FminFloatX - xyWindow.FminXTotal) / (xyWindow.FmaxXTotal - xyWindow.FminXTotal)));
        outSubj.rightX = Math.floor((this.insideRect.X + this.insideRect.Width * (xyWindow.FmaxFloatX - xyWindow.FminXTotal) / (xyWindow.FmaxXTotal - xyWindow.FminXTotal)));
        return true;
    }

    public override calci1 (x: number): number {
        return this.chart.mainWindow.CalculateMovingXOffset(x, this.insideRect.Width);
    }

    public override calcShiftByArrow (leftArrowClick: boolean): number {
        const xyWindow = this.chart.mainWindow;
        const dx = (xyWindow.FmaxFloatX - xyWindow.FminFloatX) / 30.0;
        return xyWindow.FmaxFloatX + (leftArrowClick ? -dx : dx);
    }

    public override calcShiftByWheel (delta: number): number {
        const xyWindow = this.chart.mainWindow;
        const dx = (xyWindow.FmaxFloatX - xyWindow.FminFloatX) * delta / 120.0;
        return xyWindow.FmaxFloatX + dx;
    }

    public override ProcessMouseWheel (e: any): boolean {
        return false;
    }
}
