// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class TradingSignalSeenRequestMessage extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_TRADING_SIGNAL_REQ_SEEN_MESSAGE, fieldSet);
    }

    public setTradingSignalId (tradingSignalId: number): void {
        this.setFieldValue(FieldsFactory.FIELD_TRADING_SIGNAL_ID, tradingSignalId);
    }
}
