// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts"
import { DPSLTPControlTemplate } from "../../templates.js";
import { DynPropertyControl } from "../elements/DynPropertyControl.js";

// TODO. Add 'set' functions to 'get-only' computed properties.
export let DPSLTPControl = DynPropertyControl.extend({
    template: DPSLTPControlTemplate,
    data: function ()
    {
        return {
            slLabel: '',
            tpLabel: '',
            slLabelOffset: '',
            sllLabel: '',        // #91413
            sllLabelTooltip: '', // #91413
            tpLabelOffset: '',
            slTooltip: '',
            tpTooltip: '',
            SLNumericLink: null,
            TPNumericLink: null,
            side: null,
            additionalClassName: '',
            SLTPParams:
            {
                width: 200,
                left: 100,
                margin: 6
            }
        }
    },
    computed: {
        slValue: {
            get: function ()
            {
                return this.get(
                    this.get('dp.trailingStop.enabled')
                        ? 'dp.trailingStop.value'
                        : 'dp.sl.value')
            },
            set: function (value)
            {
                this.set(
                    this.get('dp.trailingStop.enabled')
                        ? 'dp.trailingStop.value'
                        : 'dp.sl.value',
                    value)
            }
        },

        slMin: function ()
        {
            return this.get(
                this.get('dp.trailingStop.enabled')
                    ? 'dp.trailingStop.minimalValue'
                    : 'dp.sl.minimalValue')
        },

        slMax: function ()
        {
            return this.get(
                this.get('dp.trailingStop.enabled')
                    ? 'dp.trailingStop.maximalValue'
                    : 'dp.sl.maximalValue')
        },

        slStep: {
            get: function ()
            {
                return this.get(
                    this.get('dp.trailingStop.enabled')
                        ? 'dp.trailingStop.increment'
                        : 'dp.sl.increment')
            },
            set: function (value)
            {
                this.set(
                    this.get('dp.trailingStop.enabled')
                        ? 'dp.trailingStop.increment'
                        : 'dp.sl.increment',
                    value)
            }
        },

        slPrecision: {
            get: function ()
            {
                return this.get(
                    this.get('dp.trailingStop.enabled')
                        ? 'dp.trailingStop.decimalPlaces'
                        : 'dp.sl.decimalPlaces')
            },
            set: function (value)
            {
                this.set(
                    this.get('dp.trailingStop.enabled')
                        ? 'dp.trailingStop.decimalPlaces'
                        : 'dp.sl.decimalPlaces',
                    value)
            }
        },

        slCustomError: function ()
        {
            return this.get(
                this.get('dp.trailingStop.enabled')
                    ? 'dp.trailingStop.error'
                    : 'dp.sl.error')
        },

        sllMin: function ()
        {
            return this.get('dp.sl.inOffset') ? -this.get('dp.sl.maximalValue') : this.get('dp.sl.minimalValue')
        },
        sllValue: {         // #91413
            get: function ()
            {
                let value = this.get('dp.sl.sllValue')
                if (isNaN(value) || value === null) 
                {
                    value = this.get('dp.sl.value')
                    if (isNaN(value) || value === null)
                        value = 0
                }
                return value
            },
            set: function (value)
            {
                if (this.get('dp.sllValueEnabled'))
                    this.set('dp.sl.sllValue', value)
            }
        },
        sllMax: function ()
        {
            return this.get('dp.sl.maximalValue')
        },
        sllPrecision: {
            get: function ()
            {
                return this.get('dp.sl.decimalPlaces')
            },
            set: function (value)
            {
                if (this.get('dp.trailingStop.enabled'))
                    return;
                this.set('dp.sl.decimalPlaces', value)
            }
        },
        sllStep: {
            get: function ()
            {
                return this.get('dp.sl.increment')
            },
            set: function (value)
            {
                if (this.get('dp.trailingStop.enabled'))
                    return;
                this.set('dp.sl.increment', value)
            }
        },

        sllTopOffset: function ()
        {
            return this.get('dp.sl.enabled') && this.get('dp.sllValueEnabled') && !this.get('dp.trailingStop.enabled') ? 25 : 0
        },

        slTriggerTopOffset: function ()
        {
            return this.get('sllTopOffset') + this.get('top') + (this.get('dp').SLTriggerVisible() ? 70 : 0)
        },

        tpTopOffset: function ()
        {
            let dp = this.get('dp'),
                result = this.get('top') + this.get('sllTopOffset')

            if (!dp) return result

            let slTriggerOffset = dp.SLTriggerVisible() ? 110 : 70,
                slOffset = dp.sl.enabled ? slTriggerOffset : 37

            result += dp.allowedSL ? slOffset : 4
            return result
        },

        sltpTriggerShortValue: {         // #109798
            get: function ()
            {
                return this.get('dp.sltpTriggerShort')
            },
            set: function (value)
            {
                this.set('dp.sltpTriggerShort', value)
            }
        },
    }
})

DPSLTPControl.prototype.getType = function () { return 'DPSLTPControl' };

DPSLTPControl.prototype.oninit = function ()
{
    DynPropertyControl.prototype.oninit.call(this)

    this.observe(
        'dp.sl.inOffset dp.tp.inOffset dp.trailingStop.enabled',
        this.localize)
}

DPSLTPControl.prototype.oncomplete = function () 
{
    DynPropertyControl.prototype.oncomplete.call(this)
    this.set("SLNumericLink", this.Controls.SLNumeric);
    this.set("TPNumericLink", this.Controls.TPNumeric);
}

DPSLTPControl.prototype.dispose = function ()
{

    DynPropertyControl.prototype.dispose.call(this)
}

DPSLTPControl.prototype.localize = function ()
{
    DynPropertyControl.prototype.localize.call(this)
    let slLocKey =
        this.get('dp.sl.inOffset') ||
            this.get('dp.trailingStop.enabled')
            ? 'panel.newOrderEntry.stopLossCheckBox.offset'
            : 'panel.newOrderEntry.stopLossCheckBox'

    let sllLocKey =
        this.get('dp.sl.inOffset')
            ? 'panel.newOrderEntry.stopLimitLimitOffset'
            : 'panel.newOrderEntry.stopLimitLimitPrice'

    let tpLocKey =
        this.get('dp.tp.inOffset')
            ? 'panel.newOrderEntry.takeProfitCheckBox.offset'
            : 'panel.newOrderEntry.takeProfitCheckBox'

    let slLocKeyOffset =
        this.get('dp.sl.inOffset') ||
            this.get('dp.trailingStop.enabled')
            ? 'panel.newOrderEntry.stopLossCheckBox'
            : 'panel.newOrderEntry.stopLossCheckBox.offset'

    let tpLocKeyOffset =
        this.get('dp.tp.inOffset')
            ? 'panel.newOrderEntry.takeProfitCheckBox'
            : 'panel.newOrderEntry.takeProfitCheckBox.offset'

    let slTooltipKey =
        this.get('dp.tp.inOffset')
            ? 'panel.newOrderEntry.stopLossNumeric.ToolTip.offset'
            : 'panel.newOrderEntry.stopLossNumeric.ToolTip'

    let tpTooltipKey =
        this.get('dp.tp.inOffset')
            ? 'panel.newOrderEntry.takeProfitNumeric.ToolTip.offset'
            : 'panel.newOrderEntry.takeProfitNumeric.ToolTip'

    this.set({
        slLabel: Resources.getResource(slLocKey),
        sllLabel: Resources.getResource(sllLocKey),
        sllLabelTooltip: Resources.getResource(sllLocKey + '.tt'),
        tpLabel: Resources.getResource(tpLocKey),
        slLabelOffset: Resources.getResource(slLocKeyOffset),
        tpLabelOffset: Resources.getResource(tpLocKeyOffset),
        slTooltip: Resources.getResource(slTooltipKey),
        tpTooltip: Resources.getResource(tpTooltipKey)
    })
}