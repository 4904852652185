// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DataCache } from '../../Commons/DataCache';
import { type Position } from '../../Commons/cache/Position';
import { PositionBalanceID, PositionsBalance } from '../../Commons/cache/PositionsBalance';
import { EventEmitter } from 'events';

export enum PositionNotifyType {
    Add = 0,
    Remove = 1,
    Update = 2
};

export class PositionsBalanceManager {
    private readonly _eventEmitter: EventEmitter;
    private _positionsBalanceDict: Record<string, PositionsBalance | null> = {};
    private _accountIDs: string[] = [];

    constructor () {
        this._eventEmitter = new EventEmitter();
    }

    public subscribeToAdd (callback: (positionsBalance: PositionsBalance) => void): void {
        this._eventEmitter.on('add', callback);
    }

    public subscribeToRemove (callback: (positionsBalance: PositionsBalance) => void): void {
        this._eventEmitter.on('remove', callback);
    }

    public subscribeToUpdate (callback: (positionsBalance: PositionsBalance) => void): void {
        this._eventEmitter.on('update', callback);
    }

    public unsubscribeToAdd (callback: (positionsBalance: PositionsBalance) => void): void {
        this._eventEmitter.off('add', callback);
    }

    public unsubscribeToRemove (callback: (positionsBalance: PositionsBalance) => void): void {
        this._eventEmitter.off('remove', callback);
    }

    public unsubscribeToUpdate (callback: (positionsBalance: PositionsBalance) => void): void {
        this._eventEmitter.off('update', callback);
    }

    public filter (accountIDs: string[]): void {
        this._accountIDs = accountIDs.filter(id => !isNullOrUndefined(id));
    }

    public run (): void {
        this._positionsBalanceDict = {};

        this.populate();
        this.subscribe();
    }

    public stop (): void {
        this.unsubscribe();
    }

    private populate (): void {
        const positions = DataCache.getAllPositions();
        for (const posId in positions) {
            const pos = positions[posId];
            this.addPositionEvent(pos);
        }
        const positionsCorporateAction = DataCache.getAllPositionsCorporateAction();
        for (const posId in positionsCorporateAction) {
            const pos = positionsCorporateAction[posId];
            this.addPositionEvent(pos);
        }
    }

    private subscribe (): void {
        DataCache.OnAddPosition.Subscribe(this.addPositionEvent, this);
        DataCache.OnUpdatePosition.Subscribe(this.updatePositionEvent, this);
        DataCache.OnRemovePosition.Subscribe(this.removePositionEvent, this);

        // CorporateAction
        DataCache.OnAddCorporateAction.Subscribe(this.addPositionEvent, this);
        DataCache.OnUpdateCorporateAction.Subscribe(this.updatePositionEvent, this);
        DataCache.OnRemoveCorporateAction.Subscribe(this.removePositionEvent, this);
    }

    private unsubscribe (): void {
        DataCache.OnAddPosition.UnSubscribe(this.addPositionEvent, this);
        DataCache.OnUpdatePosition.UnSubscribe(this.updatePositionEvent, this);
        DataCache.OnRemovePosition.UnSubscribe(this.removePositionEvent, this);

        // CorporateAction
        DataCache.OnAddCorporateAction.UnSubscribe(this.addPositionEvent, this);
        DataCache.OnUpdateCorporateAction.UnSubscribe(this.updatePositionEvent, this);
        DataCache.OnRemoveCorporateAction.UnSubscribe(this.removePositionEvent, this);
    }

    // #region Methods
    private addPositionsBalance (positionsBalance): void { this._eventEmitter.emit('add', positionsBalance); }
    private removePositionsBalance (positionsBalance): void { this._eventEmitter.emit('remove', positionsBalance); }
    private updatePositionsBalance (positionsBalance): void { this._eventEmitter.emit('update', positionsBalance); }
    // #endregion

    // #region Events
    private addPositionEvent (position: Position): void { this.updatePositionBalance(position, PositionNotifyType.Add); }
    private removePositionEvent (position: Position): void { this.updatePositionBalance(position, PositionNotifyType.Remove); }
    private updatePositionEvent (position: Position): void { this.updatePositionBalance(position, PositionNotifyType.Update); }
    // #endregion

    private updatePositionBalance (position: Position, state: PositionNotifyType): void {
        if (this._accountIDs.length > 0 && !this._accountIDs.includes(position.Account.AcctNumber)) {
            return;
        }

        const pbID = new PositionBalanceID(position.Instrument?.InstrumentTradableID, position.ProductType);
        let positionBalance = this._positionsBalanceDict[pbID.toString()];

        switch (state) {
        case PositionNotifyType.Add:
            if (isNullOrUndefined(positionBalance)) {
                positionBalance = new PositionsBalance();
                positionBalance.addPosition(position);
                this._positionsBalanceDict[pbID.toString()] = positionBalance;
                this.addPositionsBalance(positionBalance);
            } else {
                positionBalance.addPosition(position);
                this.updatePositionsBalance(positionBalance);
            }
            break;
        case PositionNotifyType.Remove:
            if (!isNullOrUndefined(positionBalance)) {
                positionBalance.removePosition(position);
                if (Object.values(positionBalance.Positions).length === 0) {
                    this.removePositionsBalance(positionBalance);
                    delete this._positionsBalanceDict[pbID.toString()];
                } else {
                    this.updatePositionsBalance(positionBalance);
                }
            }
            break;
        case PositionNotifyType.Update:
            this.updatePositionsBalance(positionBalance);
            break;
        }
    }
}
