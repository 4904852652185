import { Resources } from '../../properties/Resources';

// TODO. Base class?
export class SLTPError {
    public locKey: any;
    public price: any;

    constructor (locKey, price) {
        this.locKey = locKey;
        this.price = price;
    }

    public equals (sltpError): boolean {
        if (this === sltpError ||
            (this.locKey === sltpError.locKey &&
                this.price === sltpError.price)) {
            return true;
        }
        return false;
    }

    public toString (): string {
        return Resources.getResource(this.locKey) + this.price;
    }
}
