// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import $ from 'jquery';
import 'jquery-mousewheel';
import 'malihu-custom-scrollbar-plugin';

export let HtmlScroll = {
    addScroll: function (node)
    {
        return $(node).mCustomScrollbar({
            theme: "tercera",
            scrollButtons: {
                enable: true,
                scrollType: "stepless"
            },
            mouseWheel: { enable: true },
            keyboard: { enable: true },
            advanced: { updateOnContentResize: true }
        });
    },
    removeScroll: function (node)
    {
        $(node).mCustomScrollbar("destroy");
    },
    updateScroll: function (node)
    {
        $(node).mCustomScrollbar("update");
    }
};

export let HtmlScrollXY = {
    addScroll: function (node, axis)
    {
        $(node).mCustomScrollbar({
            axis: axis,
            theme: "terceraXY",
            scrollButtons: {
                enable: true,
                scrollType: "stepless"
            },
            mouseWheel: { enable: true },
            keyboard: { enable: true },
            advanced: { updateOnContentResize: true }
        });
    },
    removeScroll: function (node)
    {
        $(node).mCustomScrollbar("destroy");
    },
    updateScroll: function (node)
    {
        $(node).mCustomScrollbar("update");
    }
};