// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts";
import { HtmlScroll } from "../../Commons/HtmlScroll.js";
import { TerceraClosePositionBodyTemplate, TerceraClosePositionScreenTemplate, TerceraClosePositionFooterTemplate } from "../../templates.js";
import { Control } from "../elements/Control.js";
import { ScreensNames } from "../UtilsClasses/FactoryConstants.ts";
import { OrderEditViewBase } from "../panels/OrderEditViewBase.js";
import { TerceraWindowBase } from "./TerceraWindowBase.js";
import { ThemeManager } from "../misc/ThemeManager.ts";
import { OperationType } from "../../Utils/Trading/OperationType.ts";
import { OrderType } from "../../Utils/Trading/OrderType.ts";
import { GeneralSettings } from "../../Utils/GeneralSettings/GeneralSettings.ts";
import { InstrumentUtils } from "../../Utils/Instruments/InstrumentUtils.ts";
import { IsAllowed } from "../../Commons/IsAllowed.ts";
import { Quantity } from "../../Utils/Trading/Quantity.ts";
import { ConfirmationTypesEnum } from "../../Utils/Trading/ConfirmationTypesEnum.ts";
import { OrderUtils } from "../../Utils/Trading/OrderUtils.ts";
import { DataCache } from "../../Commons/DataCache.ts";
import { SessionSettings } from "../../Commons/SessionSettings.ts";
import { TradingNumericErrorChecker } from "../../Commons/Trading/TradingNumericErrorChecker.ts";
import { MathUtils } from "../../Utils/MathUtils.ts";
import moment from 'moment';

// constructor
export let TerceraClosePositionScreen = TerceraWindowBase.extend({
    data: function ()
    {
        return {
            resizable: false,
            position: null,
            InstrumentName: "N/A",
            Route: "N/A",
            OperationType: "N/A",
            productType: "N/A",
            Amount: 0.01,
            OpenPrice: "N/A",
            CurrentPrice: "N/A",
            CloseGrossPL: "N/A",
            AccountName: "N/A",
            OrderType: "Market",
            Time: "N/A",
            width: 395,
            // height: 330,
            insName: null,
            AmountToClose: null,
            lotStep: 0.01,
            amountPrec: 0.01,
            maxAmountToClose: 0.1,
            minAmountToClose: 0.1,
            partialCloseAllowed: false,
            numericsErrors: {},
            showRoute: true,
            showProductType: false,
            showHeader: false,
            showBorder: false,
            isFIFOPosition: false,
            placedFrom: null,
            // showFullscreenCloud: true, //TODO 83829
            errorTop: 7,
            errorMode: false,
            errorText: "",
            pictureErrorUrl: "",

            header: "Close position",
            positionIdText: 'Position ID:',
            symbolText: 'Symbol:',
            routeText: 'Route:',
            sideText: "Side:",
            leverageText: "Leverage:",
            productTypeText: "Product type:",
            quantityText: "Quantity to close:",
            orderTypeText: "Order type:",
            openPriceText: "Open price:",
            currentPriceText: "Current price:",
            grossPLText: "Gross P/L to close:",
            accountText: "Account:",
            additionalInfo: "",
            dateTimeText: "Date/Time:"
        };
    },
    computed: {
        formattedCurrentPrice: {
            get: function ()
            {
                var ins = DataCache.getInstrumentByName(this.get('insName'));
                var curPrice = this.get('CurrentPrice');
                return ins ? ins.formatPrice(curPrice) : curPrice;
            },
            set: function () { }
        },

        sideStyle: function ()
        {
            var color = ThemeManager.CurrentTheme[this.get('isBuy') ? 'BuyColor' : 'SellColor'];
            return 'color:' + color + ';';
        },
        grossPLStyle: function ()
        {
            var grossPLStr = this.get('CloseGrossPL');
            var color = ThemeManager.CurrentTheme[
                !grossPLStr || grossPLStr.indexOf('-') === 0 ?
                    'TableValueDownForeColor' :
                    'TableValueUpForeColor'
            ];
            return 'color:' + color + ';';
        }
    },
    template: TerceraClosePositionScreenTemplate,    
    partials: {
        bodyPartial: TerceraClosePositionBodyTemplate,
        footerPartial: TerceraClosePositionFooterTemplate
    },
    FPosition: null
});

TerceraClosePositionScreen.prototype.getType = function () { return ScreensNames.ClosePositionScreen };

// TerceraClosePositionScreen.show = function (positionId, placedFrom)     // old and useless -> check EditPositionScreen.js
// {
//     var screen = Factory.addScreen(ScreensNames.ClosePositionScreen);
//     screen.set("PositionID", positionId);
//     screen.set("placedFrom", placedFrom);
// };

TerceraClosePositionScreen.prototype.oncomplete = function ()
{
    this.FPosition = null;
    TerceraWindowBase.prototype.oncomplete.apply(this);
    this.onrender();

    this.on('closeClick', this.closeClick);
    this.on('cancelClick', this.cancelClick);
    this.set("checkedShowNextTime", GeneralSettings.Confirmations.useConfirmation(ConfirmationTypesEnum.PositionClose));

    var position = this.get("position");
    this.fillByPosition(position);
    this.FPosition = position;
    Control.Ticker.Subscribe(this.TickAsync, this);
};

TerceraClosePositionScreen.prototype.oninit = function ()
{
    TerceraWindowBase.prototype.oninit.apply(this);

    this.set('numericsErrors', {});

    this.localize();
};

TerceraClosePositionScreen.prototype.closeClick = function ()
{
    this.closePos();

    let parentContainerControl = this.get('parentContainerControl')

    if (parentContainerControl)
        parentContainerControl.close()
};

TerceraClosePositionScreen.prototype.cancelClick = function ()
{
    let parentContainerControl = this.get('parentContainerControl')

    if (parentContainerControl)
        parentContainerControl.close()
};

TerceraClosePositionScreen.prototype.fillByPosition = function (position)
{
    var isBuy = position.BuySell === OperationType.Buy;
    var instrument = position.Instrument;
    var account = position.Account;
    this.set({
        InstrumentName: instrument.DisplayName(),

        isBuy: isBuy,
        OperationType: Resources.getResource(isBuy ? 'general.trading.position.Buy' : 'general.trading.position.Sell'),

        OpenPrice: instrument.formatPrice(position.OpenPrice),
        CurrentPrice: instrument.formatPrice(position.CurPriceClose),
        CloseGrossPL: account.formatPrice(position.getGrossPnL()),
        AccountName: position.Account.FullAccString,
        OrderType: Resources.getResource('property.' + OrderUtils.getOrderTypeLocalizationKey(OrderType.Market)),
        Time: moment(position.OpenTime).format('DD.MM.YYYY HH:mm:ss'),
        insName: instrument.GetInteriorID(),
        leverage: OrderUtils.GetFormattedLeverage(position.Leverage),
        productType: InstrumentUtils.GetLocalizedProductType(instrument, position.ProductType),
        showProductType: instrument.isProductTypeVisible()
    });

    var inLots = GeneralSettings.View.DisplayQuantityInLots;
    const lotSize = instrument.getLotSize();
    var amountMultiplier = inLots ? 1 : lotSize;

    var amountPrecision = instrument.getAmountPrecision();

    var convertedAmount = inLots ?
        position.Amount :
        position.Amount * amountMultiplier;

    let SkipValidationForMaxValue = false;

    if (MathUtils.mod(convertedAmount, instrument.LotStep) != 0) {    // #118192: Close Positions with Qty < LotSize
        amountPrecision = MathUtils.getPrecision(convertedAmount);
        SkipValidationForMaxValue = true;
    }

    if (!inLots) {
        convertedAmount = parseFloat(convertedAmount.toFixed(amountPrecision))
    }

    this.set('Amount', convertedAmount);

    if (!instrument.isHideRouteMode)
        this.set({ Route: position.Route, showRoute: true });
    else
        this.set({ showRoute: false });

    let routeOffset = instrument.isHideRouteMode ? 0 : 20,                          // calcOffsets
        leverageOffset = routeOffset + (this.get('leverage') ? 20 : 0),               // delete after UI_Refactoring TODO
        productTypeOffset = leverageOffset + (this.get('showProductType') ? 20 : 0)
    this.set({
        routeOffset: routeOffset,
        leverageOffset: leverageOffset,                                             // delete after UI_Refactoring TODO
        productTypeOffset: productTypeOffset
    })                                                                              // calcOffsets end

    var step = instrument.LotStep * amountMultiplier;
    step = parseFloat(step.toFixed(amountPrecision));

    const minLot = instrument.getMinLot(position.ProductType, position.Account);
    if (convertedAmount < minLot) {
        step = convertedAmount;  // to disable numeric for #118192: Close Positions with Qty < LotSize
    }

    this.set({
        amountPrec: amountPrecision,
        lotStep: step,
        minAmountToClose: step,
        maxAmountToClose: convertedAmount,
        AmountToClose: convertedAmount,
        SkipValidationForMaxValue: SkipValidationForMaxValue,
        allowAmountEdit: step !== convertedAmount
    });

    this.updateQtyToCloseMoreThenMaxLotWarning(position)

    this.addAdditionalInfo(instrument, account)

    //TODO
    this.set({ partialCloseAllowed: true/*updatePackage.modifyRules[TerceraModifyScreen.AllowedModifyOrderRules.allowPartialClose] */ });
};

TerceraClosePositionScreen.prototype.addAdditionalInfo = function (instrument, account)
{
    let additionalInfo = account.GetCommissionInfo(instrument)
    if (!additionalInfo)
        return

    this.set('additionalInfo', additionalInfo)
    let el = this.find(".confirmFeeInfoContainer")
    if (el)
        HtmlScroll.addScroll(el)
}

TerceraClosePositionScreen.prototype.closePos = function ()
{
    if (TradingNumericErrorChecker.HasErrors(this))
        return

    // TODO. When numeric is disabled, it throws 'Value is empty' error.
    if (this.get('allowAmountEdit') &&
        !OrderEditViewBase.CheckNumericsErrors(this.get('numericsErrors')))
        return

    let dc = DataCache
    let sess = SessionSettings

    let qty = new Quantity(
        this.get('AmountToClose'),
        GeneralSettings.View.DisplayQuantityInLots)

    dc.FOrderExecutor.closePositionQuantity(
        this.get('position'),
        qty,
        this.get('placedFrom')
    )

}

TerceraClosePositionScreen.prototype.localize = function ()
{
    this.set({
        header: Resources.getResource('screen.closePosition.title'),
        positionIdText: Resources.getResource('panel.positions.pos_number') + ':',
        symbolText: Resources.getResource('panel.positions.Symbol') + ':',
        routeText: Resources.getResource('panel.positions.route') + ':',
        sideText: Resources.getResource('panel.positions.Operation') + ':',
        leverageText: Resources.getResource('panel.positions.Leverage') + ':',
        quantityText: Resources.getResource('panel.closePosition.QunatityToClose') + ':',
        orderTypeText: Resources.getResource('panel.positions.OrderType') + ':',
        openPriceText: Resources.getResource('panel.positions.Basis') + ':',
        currentPriceText: Resources.getResource('panel.positions.cur_price') + ':',
        grossPLText: Resources.getResource('panel.closePosition.Gross_P/L_ToClose') + ':',
        accountText: Resources.getResource('panel.positions.Account') + ':',
        dateTimeText: Resources.getResource('panel.positions.date_time') + ':',
        errorText: Resources.getResource('screen.closePosition.maxLotWarning'),
        productTypeText: Resources.getResource('panel.closePosition.productTypeText') + ':',
        showNextTimeText: Resources.getResource('general.messageBox.showNextTime'),
        closeText: Resources.getResource('screen.closePosition.ok'),
        cancelText: Resources.getResource('screen.closePosition.cancel')
    });
};

TerceraClosePositionScreen.prototype.TickAsync = function ()
{
    let pos = this.FPosition;
    let amount = this.get('AmountToClose')
    if (!GeneralSettings.View.DisplayQuantityInLots)
        amount = amount / pos.Instrument.LotSize

    this.updateQtyToCloseMoreThenMaxLotWarning(pos, amount)

    this.set({
        OpenPrice: pos.Instrument.formatPrice(pos.OpenPrice),
        CurrentPrice: pos.Instrument.formatPrice(pos.CurPriceClose),
        CloseGrossPL: pos.Account.formatPrice(pos.PnLCalculator.CalculateAccNetPLByQty(amount))
    });
}

TerceraClosePositionScreen.prototype.dispose = function ()
{
    GeneralSettings.Confirmations.updateConfirmation(ConfirmationTypesEnum.PositionClose, this.get('checkedShowNextTime'));
    Control.Ticker.UnSubscribe(this.TickAsync, this);
    TerceraWindowBase.prototype.dispose.apply(this);
};

TerceraClosePositionScreen.prototype.updateQtyToCloseMoreThenMaxLotWarning = function (position)
{
    if (GeneralSettings.Warnings.WarnIfQtyToCloseMoreThanMaxLot)
    {
        let oldErrorMode = this.get('errorMode'),
            qtyToClose = this.get('AmountToClose'),
            newErrorMode = IsAllowed.IsMaxLotHasBeenReached(position, qtyToClose)
        this.set({ 'errorMode': newErrorMode, 'pictureErrorUrl': ThemeManager.getImageFullUrl('i_messagebox/32x32_icon_warning.png') })
    }
};