// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts";
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";
import { MathUtils } from "../../Utils/MathUtils.ts";
import { SnapshotScreenTemplate } from "../../templates.js";
import { TerceraWindowBase } from "./TerceraWindowBase.js";
import { DateTimeUtils } from "../../Utils/Time/DateTimeUtils.ts";
import { Account } from "../../Commons/cache/Account.ts";
import { GeneralSettings } from "../../Utils/GeneralSettings/GeneralSettings.ts";
import { snapshotScreenHandler } from "../../Utils/AppHandlers.js";
import { DataCache } from "../../Commons/DataCache.ts";
import { InstrumentUtils } from "../../Utils/Instruments/InstrumentUtils.ts";


export let SnapshotScreen = TerceraWindowBase.extend({
    partials: { bodyPartial: SnapshotScreenTemplate },
    data: function ()
    {
        return {
            name: 'snapshotScreen',
            movable: true,
            resizable: false,
            showFooter: false,
            focused: true,
            zIndex: 300,

            width: 329,
            height: 259,

            lastLabelText: 'Last',
            bidLabelText: 'Bid',
            askLabelText: 'Ask',
            priceLabelText: 'Price:',
            sizeLabelText: 'Size:',
            changeLabelText: 'Change:',
            highLabelText: 'Today high:',
            lowLabelText: 'Today low:',
            changePercentLabelText: 'Change, %:',
            volumeLabelText: 'Today volume:',

            strNA: '---',                // если какое-то значение NaN - вместо него отображается эта локализированная строка
            displayAmountInLots: null,   // отображаем Volume и size-ы в зависимости от настройки Display quantity in lots 

            getSnapshotBtnText: 'Get snapshot (99)',
            getSnapshotBtnTooltip: 'Snapshot requests limit has been reached',
            getSnapshotBtnDisabled: false,
            closeBtnText: 'Close'
        };
    },
    computed: {
        Ask: function ()
        {
            return InstrumentUtils.getFormatPrice(this.instrument, this.message.Ask, this.get('strNA'))
        },
        AskSize: function ()
        {
            return InstrumentUtils.getFormatVolume(this.instrument, this.message.AskSize, this.get('displayAmountInLots'), this.get('strNA'))
        },
        Bid: function ()
        {
            return InstrumentUtils.getFormatPrice(this.instrument, this.message.Bid, this.get('strNA'))
        },
        BidSize: function ()
        {
            return InstrumentUtils.getFormatVolume(this.instrument, this.message.BidSize, this.get('displayAmountInLots'), this.get('strNA'))
        },
        Change: function ()
        {
            let last = this.message.Last, close = this.message.Close
            if (isNaN(last) || isNaN(close))
                return this.get('strNA')

            return InstrumentUtils.getFormatPrice(this.instrument, last - close, this.get('strNA'))
        },
        ChangePercent: function ()
        {
            let last = this.message.Last, close = this.message.Close
            if (isNaN(last) || isNaN(close))
                return this.get('strNA')

            let value = (last - close) / close * 100
            return InstrumentUtils.getFormatPrice(this.instrument, value, this.get('strNA'))
        },
        ChangeStyle: function ()
        {
            let last = this.message.Last, close = this.message.Close
            if (isNaN(last) || isNaN(close) || MathUtils.equalToEpsilon(last, close))
                return ''

            let color = last > close ? '#49BE49' : '#FF453A'// todo colors
            return 'color:' + color + ';';
        },
        High: function ()
        {
            return InstrumentUtils.getFormatPrice(this.instrument, this.message.High, this.get('strNA'))
        },
        Last: function ()
        {
            return InstrumentUtils.getFormatPrice(this.instrument, this.message.Last, this.get('strNA'))
        },
        LastSize: function ()
        {
            return InstrumentUtils.getFormatVolume(this.instrument, this.message.LastSize, this.get('displayAmountInLots'), this.get('strNA'))
        },
        Low: function ()
        {
            return InstrumentUtils.getFormatPrice(this.instrument, this.message.Low, this.get('strNA'))
        },
        Volume: function ()
        {
            return InstrumentUtils.getFormatVolume(this.instrument, this.message.Volume, false, this.get('strNA'))
        }
    },
    message: null,                                      // отображаемые на скрине данные храним в DirectSnapshotMessage и красиво достаем через defineProperty-сы
    instrument: null,                                   // используем для SnapshotRequest (tradableID+routeID), а также для корректного отображения Volume и size-ов в зависимости от настройки Display quantity in lots 
    needCenterLocation: false,                          // нужно ли центрировать экран после создания
    headerAdditionalColor: '#3EA7DE'                    // цвет строки с временем получения снэпшота в хедере
});

let instance = null;

SnapshotScreen.prototype.getType = function () { return 'SnapshotScreen' };

SnapshotScreen.prototype.oninit = function ()
{
    TerceraWindowBase.prototype.oninit.apply(this);

    Resources.onLocaleChange.Subscribe(this.localize, this)

    this.set('displayAmountInLots', GeneralSettings.View.displayAmountInLots())
    GeneralSettings.SettingsChanged.Subscribe(this.settingsChanged, this)

    DataCache.OnSnapshotResponseReceived.Subscribe(this.updateSnapshotBtn, this)     // обновление enability кнопок Get snapshot если на клиенте совершили Get snapshot request 
    Account.TradeStatusChanged.Subscribe(this.updateSnapshotBtn, this);     // обновление enability кнопок Get snapshot при изменениях значения на сервере
};

SnapshotScreen.prototype.oncomplete = function ()
{
    TerceraWindowBase.prototype.oncomplete.apply(this);

    this.on('getSnapshotBtnClick', this.getSnapshotBtnClick.bind(this));
    this.on('closeBtnClick', this.closeBtnClick.bind(this));

    this.IncreaseWidthIfNeed()
    this.localize()

    if (this.needCenterLocation)
        this.center();
};

SnapshotScreen.prototype.dispose = function ()
{
    Resources.onLocaleChange.UnSubscribe(this.localize, this)
    GeneralSettings.SettingsChanged.UnSubscribe(this.settingsChanged, this)

    DataCache.OnSnapshotResponseReceived.UnSubscribe(this.updateSnapshotBtn, this)
    Account.TradeStatusChanged.UnSubscribe(this.updateSnapshotBtn, this);

    TerceraWindowBase.prototype.dispose.apply(this);
}

SnapshotScreen.prototype.localize = function ()
{
    this.updateSnapshotBtn()

    this.set({
        header: Resources.getResource('screen.snapshot.header') + ' ' + SnapshotScreen.getColoredTimeForHeader(this.ReceivedAt, this.headerAdditionalColor),
        closeBtnText: Resources.getResource('screen.snapshot.closeBtnText'),
        strNA: Resources.getResource('general.N_A'),
        lastLabelText: Resources.getResource('screen.snapshot.lastLabel'),
        bidLabelText: Resources.getResource('screen.snapshot.bidLabel'),
        askLabelText: Resources.getResource('screen.snapshot.askLabel'),
        priceLabelText: Resources.getResource('screen.snapshot.priceLabel'),
        sizeLabelText: Resources.getResource('screen.snapshot.sizeLabel'),
        changeLabelText: Resources.getResource('screen.snapshot.changeLabel'),
        highLabelText: Resources.getResource('screen.snapshot.highLabel'),
        lowLabelText: Resources.getResource('screen.snapshot.lowLabel'),
        changePercentLabelText: Resources.getResource('screen.snapshot.changePercentLabel'),
        volumeLabelText: Resources.getResource('screen.snapshot.volumeLabel')
    });
};

SnapshotScreen.prototype.getSnapshotBtnClick = function ()
{
    if (this.get('getSnapshotBtnDisabled'))
        return

    SnapshotScreen.SendSnapshotRequestMessage(this.instrument);
}

SnapshotScreen.prototype.closeBtnClick = function ()
{
    this.close()
}

SnapshotScreen.prototype.updateSnapshotBtn = function ()   // обновить текст, тултип, и disabled состояние кнопки Get snapshot
{
    let text = Resources.getResource('screen.snapshot.getSnapshotBtnText'), tt = ''

    let availableNum = DataCache.getAvailableNumberOfSnapshotRequests()
    if (availableNum >= 0)
    {
        text += ' (' + availableNum + ')'
        tt = Resources.getResource('screen.snapshot.getSnapshotBtnText.' + (availableNum > 0 ? 'moreZero' : 'Zero') + '.tt')
    }
    else
        tt = Resources.getResource('screen.snapshot.getSnapshotBtnText.minusOne.tt')

    this.set({
        getSnapshotBtnText: text,
        getSnapshotBtnTooltip: tt,
        getSnapshotBtnDisabled: availableNum == 0
    })
}

SnapshotScreen.prototype.IncreaseWidthIfNeed = function ()                                  // расширить ширину скрина если значения большие и не влазят (грубая оценка) 
{
    let message = this.message
    if (!message) return

    let extraSymbolsNum = this.MaxStringLength - SnapshotScreen.MAX_STANDARD_VALUE_LENGTH       // количество неучтенных символов отображаемых значений из-за которых необходимо увеличить ширину скрина
    if (extraSymbolsNum > 0)
        this.set('width', SnapshotScreen.STANDARD_SCREEN_WIDTH + extraSymbolsNum * SnapshotScreen.EXTRA_SYMBOL_WIDTH)
}

SnapshotScreen.prototype.settingsChanged = function ()
{
    let newSettingsInLots = GeneralSettings.View.displayAmountInLots()
    if (this.get('displayAmountInLots') == newSettingsInLots)
        return

    this.set('displayAmountInLots', newSettingsInLots)
}

SnapshotScreen.STANDARD_SCREEN_WIDTH = 329;     // стандартная ширина скрина (в пикселях)
SnapshotScreen.EXTRA_SYMBOL_WIDTH = 16;         // ширина на которую увеличиваем за каждый экстра символ (берем самый большой шрифт)
SnapshotScreen.MAX_STANDARD_VALUE_LENGTH = 9;   // максимальная длина строки отображаемых значений при которой сохраняются стандартные размеры скрина

SnapshotScreen.show = function (ins, msg)
{
    let x = null, y, oldScreen = instance
    if (oldScreen)
    {
        x = oldScreen.getX()
        y = oldScreen.getY()
        oldScreen.close()
    }
    let needSetLocation = x !== null

    instance = new SnapshotScreen({ instrument: ins, message: msg, needCenterLocation: !needSetLocation });
    instance.on('teardown', function () { instance = null; });
    MainWindowManager.MainWindow.addControl(instance);

    if (needSetLocation)
        instance.setLocation(x, y);

    instance.setFocus();
};

SnapshotScreen.SendSnapshotRequestMessage = function (ins)
{
    if (!ins) return
    let subsRoute = DataCache.getRouteById(ins.Route);
    let route = subsRoute ? subsRoute.QuoteRouteId : null;

    if (route)
        DataCache.SendSnapshotRequestMessage(ins.InstrumentTradableID, route)
}

SnapshotScreen.getColoredTimeForHeader = function (dateObj, color)
{
    let timeFormatted = DateTimeUtils.formatDate(dateObj, 'HH:mm:ss')

    if (!color)
        return timeFormatted

    return '<span style="color:' + color + '">' + timeFormatted + '</span>'
}

SnapshotScreen.VALUES_NAMES_LIST = ['Ask', 'AskSize', 'Bid', 'BidSize', 'Change', 'ChangePercent', 'High', 'Last', 'LastSize', 'Low', 'Volume'];

Object.defineProperty(SnapshotScreen.prototype, 'MaxStringLength',      // максимальное количество символов у отображаемых значений (для грубой оценки насколько нужно увеличить ширину скрина)
    {
        get: function ()
        {
            let maxLen = 0, properties = SnapshotScreen.VALUES_NAMES_LIST;
            for (let i = 0; i < properties.length; i++)
            {
                let stringValue = String(this.get(properties[i]))
                if (stringValue)
                    maxLen = Math.max(maxLen, stringValue.length)
            }
            return maxLen
        }
    });
Object.defineProperty(SnapshotScreen.prototype, 'ReceivedAt',           // время получения SnapshotRespons-a
    {
        get: function () { return this.message ? this.message.ReceivedAt : this.get('strNA'); }
    });

    let snapShotHanl = snapshotScreenHandler;
    snapShotHanl.show = SnapshotScreen.show;