// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectBrandingRulesMessage extends DirectMessageBaseClass {
    public Tools: string | null = null;
    public CompanyName: string | null = null;
    public BrokerInformation = null;
    public PoweredByName: string | null = null;
    public PoweredByURL: string | null = null;
    public BrandingClientSessionTimeoutInMinutes = -1;
    public BrandingDemoregistrationURL = '';
    public BrandingForgotpwdURL = '';
    public BrandingIpServicesURL = '';
    public BrandingWebName = '';
    public BrandingWebLoginBanner = '';
    public BrandingWebSplashScreen = '';
    public BrandingWebHeader = '';
    public BrandingWebFavicon = '';
    public BrandingExternalAuthenticationType = '';
    public BrandingExternalAuthenticationUrl = '';
    public BrandingExternalAuthenticationWebClientId = '';
    public BrandingExternalAuthenticationWebMobileClientId = '';
    public BrandingWMLoginBanner = '';
    public BrandingWMBackgroundImage = '';
    public BrandingWMFavicon = '';
    public BrandingInvestingCustomScheme = '';
    public BrandingInvestingFavicon = '';
    public BrandingInvestingSplashScreen = '';
    public BrandingInvestingLoginBanner = '';
    public BrandingInvestingWebHeader = '';
    public BrandingWebLangFiles = '';
    public BrandingVisibleInstrumentLogo: string | null;
    public BrandingServerURL: string | null;
    public BrandingAllowPasswordPreview = '';
    public BrandingAllowSavePassword = '';

    constructor () {
        super(Message.CODE_PFIX_BRANDING_RULES_RESP);
    }
}
