import { QuickTableZone } from '../QuickTable';

export class HitTestInfo {
    public zone = QuickTableZone.Table;
    public rowIndex = -1;
    public columnIndex = -1;
    public realColumnIndex = -1;
    public x: number;
    public y: number;
    public lastVisibleSortedColumn: boolean;

    constructor (x: number, y: number) {
        this.x = x;
        this.y = y;
    }
}
