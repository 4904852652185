// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { TerceraChartCashItemSeriesDataType } from '../Series/TerceraChartCashItemSeriesEnums';
import { DataCacheToolRayType } from '../../Commons/cache/DataCacheToolEnums';
import { ToolView } from './ToolView';
import { ToolViewUtils } from './Utils/ToolViewUtils';
import { type FibonacciRetracementDataCacheTool } from '../../Commons/cache/Tools/FibonacciRetracementDataCacheTool';

export class FibonacciRetracementToolView extends ToolView<FibonacciRetracementDataCacheTool> {
    public DrawFib (gr, ww, highlight): void {
        const dataCacheTool = this.dataCacheTool;

        const pc = ww.PointsConverter;
        const clientRect = ww.ClientRectangle;

        const instrument = dataCacheTool.Instrument;

        const fibLevel = dataCacheTool.fibLevel;
        const levelsVisibility = dataCacheTool.levelsVisibility;
        const points = dataCacheTool.Points;

        const ray = dataCacheTool.RayType;
        const pen = highlight ? dataCacheTool.PenHighlight : dataCacheTool.Pen;
        const penRay = dataCacheTool.PenRay;
        const font = dataCacheTool.font;
        const fontColor = dataCacheTool.FontColor;

        const screenPoints = this.screenPoints;

        const scrP0 = screenPoints[0];
        const scrP0X = scrP0[0];
        const scrP0Y = scrP0[1];

        const scrP1 = screenPoints[1];
        const scrP1X = scrP1[0];
        const scrP1Y = scrP1[1];

        const p0 = points[0];
        // const p0X = p0[0];
        const p0Y = p0[1];

        const p1 = points[1];
        // const p1X = p1[0];
        const p1Y = p1[1];

        const dY = p1Y - p0Y;
        const dX = Math.abs(scrP1X - scrP0X);
        const minScrPX = Math.min(scrP0X, scrP1X);
        const right = Math.min(dX * 5 + minScrPX, clientRect.X + clientRect.Width);

        gr.DrawLine(pen, scrP0X, scrP0Y, scrP1X, scrP1Y);

        const levelsVisibility_len = levelsVisibility ? levelsVisibility.length : 0;
        const fibLevel_len = fibLevel.length;
        for (let i = 0; i < fibLevel_len; i++) {
            if (levelsVisibility_len > i && !levelsVisibility[i]) {
                continue;
            }

            const fibLvl = fibLevel[i];

            let cY = 0;
            // from 2 by 21 direction
            cY = p1Y - dY * fibLvl / 100;

            const cYdraw = pc.GetScreenY(cY);

            if (cYdraw > clientRect.Y && cYdraw < clientRect.Y + clientRect.Height) {
                ToolView.DrawLineWithRay(gr, ww, minScrPX, cYdraw, right, cYdraw, ray, pen, penRay);
            }

            // #18801. Drawings - мелкие доработки - показываем  цену каждого уровня слева
            let minPoint = minScrPX;
            minPoint = minPoint > clientRect.X ? minPoint : (clientRect.X < right ? clientRect.X : right);
            // To prevent text-on-text overlay.
            if (!highlight && scrP0Y !== scrP1Y) {
                const price = ToolViewUtils.FormatPrice(instrument, cY);
                // #25534  FIbonacci retracement & expansions - подписи для линий скрывать когда данные тулзы уходят из поля зрения, т.е когда двигаем чарт в право
                if (minPoint < clientRect.X + clientRect.Width) {
                    const f_txt = ToolViewUtils.FormatFibonacciText(fibLvl, points, instrument) + '% (' + price + ')';
                    const wid = gr.GetTextWidth(f_txt, font);

                    if (ray === DataCacheToolRayType.LeftRay && (dX * 5 + minScrPX - wid) > clientRect.X + clientRect.Width) {
                        ToolViewUtils.DrawFibonacciText(gr, ww, 1, cYdraw, f_txt, font, fontColor);
                    } else {
                        ToolViewUtils.DrawFibonacciText(gr, ww, (right - wid), cYdraw, f_txt, font, fontColor);
                    }
                }
            }
        }
    }

    public override Draw (gr, ww, param): void {
        this.DrawFib(gr, ww, false);
        super.Draw(gr, ww, param);
    }

    public override DrawSelection (gr, ww): void {
        this.DrawFib(gr, ww, true);
        super.DrawSelection(gr, ww);
    }
}
