// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DynProperty } from '../../Commons/DynProperty';
import { type SessionSettingsType } from '../../Commons/SessionSettings';
import { type Instrument } from '../../Commons/cache/Instrument';
import { type MarketConsensusData } from '../../Utils/DirectMessages/DirectMessagesImport';
import { MathUtils } from '../../Utils/MathUtils';
import { QuickTableEditingInfo } from '../elements/QuickTable/QuickTableMisc';
import { BaseItem, ColumnData } from './BaseItem';
import { ColumnParams } from './ColumnParams';

export class MarketConsensusPanelItem extends BaseItem {
    public Instrument: Instrument;
    public IsLong: boolean;
    public Color: string;
    public Name: string;
    public Value: number;
    public IsExpertChoice: boolean;

    constructor (item: MarketConsensusData, isLong: boolean, instrument: Instrument, color: string, settings: SessionSettingsType) {
        super(settings);

        this.Instrument = instrument;
        this.IsLong = isLong;
        this.Color = color;

        if (!isNullOrUndefined(instrument)) {
            this.Name = instrument.DisplayName();
        }

        if (!isNullOrUndefined(item)) {
            if (isNullOrUndefined(instrument)) {
                this.Name = item.InstrumentName;
            }

            this.Value = item.Percent;
            this.IsExpertChoice = item.IsExpertChoice;
        }

        this.QuickTableEditingInfoMap[0] = (function () {
            return new QuickTableEditingInfo(DynProperty.MARKET_CONSENSUS);
        })();
    }

    public override ColumnCount (): number {
        return MarketConsensusPanelItem.columnsParams.length;
    }

    public override GetCurrentInstrument (): Instrument {
        return this.Instrument;
    }

    public UpdateItemData (item: MarketConsensusData, color: string): void {
        if (!isNullOrUndefined(item)) {
            if (isNullOrUndefined(this.Instrument)) {
                this.Name = item.InstrumentName;
            }

            this.Value = item.Percent;
            this.isNeedFireUpdate = this.IsExpertChoice !== item.IsExpertChoice;
            this.IsExpertChoice = item.IsExpertChoice;
        }
        this.isNeedFireUpdate = this.isNeedFireUpdate || this.Color !== color;
        this.Color = color;
    }

    public override InstrumentName (saveMode): string {
        return this.Instrument.GetInteriorID(saveMode);
    }

    public override getColumnData (column): ColumnData {
        const value = this.getColumnValue(column);
        let formattedValue: any = null;
        const tooltip = this.IsExpertChoice ? MarketConsensusPanelItem.Tooltip : null;

        switch (column) {
        case 0:
            if (!isNullOrUndefined(value)) {
                formattedValue = {
                    IsLong: this.IsLong,
                    Value: MathUtils.TruncateDouble(this.Value, 2),
                    Name: this.Name,
                    IsExpertChoice: this.IsExpertChoice,
                    tooltip,
                    Color: this.Color
                };
            }
            break;

        default:
            formattedValue = null;
        }

        return new ColumnData(value, formattedValue);
    }

    public getColumnValue (column: number): string | null {
        switch (column) {
        case 0:
            return this.Name + this.Value;
        default:
            return null;
        }
    }

    public GetColumnParams (column: number): ColumnParams {
        const result = MarketConsensusPanelItem.columnsParams[column];
        return result;
    }

    public static columnsParams = [
        new ColumnParams('panel.AlgorithmMarketConsensusPanel.TopLong', 100, 0, false, true, false)
    ];

    public static ListMenuIndex = [
        'panel.AlgorithmMarketConsensusPanel.TopLong'
    ];

    public static readonly Tooltip = 'Expert choice';
}
