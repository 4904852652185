// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { TerceraChartCashItemSeriesDataType } from '../Series/TerceraChartCashItemSeriesEnums';
import { DataCacheToolRayType } from '../../Commons/cache/DataCacheToolEnums';
import { ToolView } from './ToolView';
import { ToolViewUtils } from './Utils/ToolViewUtils';
import { type HorizontalLineDataCacheTool } from '../../Commons/cache/Tools/HorizontalLineDataCacheTool';

export class HorizontalLineToolView extends ToolView<HorizontalLineDataCacheTool> {
    public override IsSelectCheck (x: number, y: number): boolean {
        const scrP = this.screenPoints[0];
        const pX = scrP[0];
        const pY = scrP[1];
        const TOOL_DX_HALF = ToolView.TOOL_DX / 2;

        const yTop = pY - TOOL_DX_HALF;
        const yBot = pY + TOOL_DX_HALF;
        return y >= yTop && y <= yBot && x > pX - TOOL_DX_HALF;
    }

    public DrawHorizontalLine (gr, ww, highlight): void {
        const dataCacheTool = this.dataCacheTool;

        const instrument = dataCacheTool.Instrument;

        const pY = dataCacheTool.Points[0][1];

        const scrP = this.screenPoints[0];
        const scrPX = scrP[0];
        const scrPY = scrP[1];

        const pen = highlight ? dataCacheTool.PenHighlight : dataCacheTool.Pen;
        const clientRect = ww.ClientRectangle;
        const clientRectRight = clientRect.X + clientRect.Width;
        const text = ToolViewUtils.FormatPrice(instrument, pY);

        ToolView.DrawLineWithRay(gr, ww,
            scrPX, scrPY, clientRectRight, scrPY,
            dataCacheTool.LeftRay ? DataCacheToolRayType.LeftRay : DataCacheToolRayType.NoRay,
            pen);

        if (!highlight) {
            gr.DrawString(text, dataCacheTool.font, dataCacheTool.FontBrush,
                clientRectRight, scrPY, 'right', 'bottom');
        }
    }

    public override Draw (gr, ww, param): void {
        this.DrawHorizontalLine(gr, ww, false);
        super.Draw(gr, ww, param);
    }

    public override DrawSelection (gr, ww): void {
        const clientRect = ww.ClientRectangle;

        const screenPoints = this.screenPoints;
        const scrP = screenPoints[0];
        const x = scrP[0];
        const y = scrP[1];

        this.DrawHorizontalLine(gr, ww, true);
        this.DrawSelectedLine(gr, ww, x, y, clientRect.X + clientRect.Width, y);
        this.DrawSelectedPoint(gr, ww, x, y);
    }
}
