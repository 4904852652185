// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
export enum TextMessageType {
    CHAT_MESSGE = 1,
    LINK_CHANGE = 2,
    STATUS_CHANGE = 3,
    SIGNAL_MESSGE = 4,
    FILE_MESSGE = 5,
    GROUP_MESSGE = 6,
    RANK_MESSGE = 7,
    BROKER_MESSAGE_URGENT_TYPE = 8,
    BROKER_MESSAGE_WELCOME_TYPE = 9,
    BROKER_MESSAGE_PERIODIC_TYPE = 10,
    BROKER_MESSAGE_DAILY_WELCOME_TYPE = 11,
    BROKER_MESSAGE_NOTIFICATION_TYPE = 12
}
