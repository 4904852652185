// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { SessionOperations } from '../../Utils/Enums/Constants';
import { Message } from './Message';

export class DirectTradeSessionStatusMessage extends DirectMessageBaseClass {
    public Id = 0;
    public Name: string | null = null;
    public AllowedOrderTypes: number[] = [];
    public AllowedOperations = SessionOperations.None;
    public IsDecreaseOnlyPositionCount = false;

    constructor () {
        super(Message.CODE_TRADE_SESSION_STATUS_MESSAGE);
    }
}
