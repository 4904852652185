import { type StatisticType } from '../../../../Utils/Enums/StatisticType';
import { FieldsFactory } from '../Factory/FieldsFactory';
import { PFixFieldSet } from '../Fields/PFixFieldSet';
import { AccountOperationStatisticGroup } from './AccountOperationStatisticGroup';

export class StatisticsGroup extends PFixFieldSet {
    constructor (pfixFieldSet?: PFixFieldSet) {
        super(FieldsFactory.STATISTICS_GROUP, null, pfixFieldSet);
    }

    public getType (): StatisticType {
        return this.GetValue(FieldsFactory.FIELD_TYPE);
    }

    public getBeginProjectedBalance (): number {
        return this.GetValue(FieldsFactory.FIELD_BEGIN_PROJECTED_BALANCE);
    }

    public getAccountOperationStatisticGroups (): AccountOperationStatisticGroup[] {
        return this.GetGroups(FieldsFactory.ACCOUNT_OPERATION_STATISTIC_GROUP, AccountOperationStatisticGroup);
    }
}
