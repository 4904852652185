export class WorkSpaceMetaData {
    public ws_id: string = '';
    public name: string = '';
    public updateTimeSpan: number = 0;
    public fromServer: boolean = false;
    public needLoad: boolean = false;
    public isActive: boolean = false;

    public FillByDataFromServer (data: any, isActive: boolean): void {
        this.ws_id = data.ws_id;
        this.name = data.name;
        this.updateTimeSpan = data.updateTimeSpan;
        this.isActive = isActive;
    }
}
