// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { InstrumentUtils } from '../../Utils/Instruments/InstrumentUtils';
import { type ProductType } from '../../Utils/Instruments/ProductType';
import { MathUtils } from '../../Utils/MathUtils';
import { OperationType } from '../../Utils/Trading/OperationType';
import { type Account } from './Account';
import { type Instrument } from './Instrument';
import { MarkupFormula } from './MarkupFormula';
import { type Position } from './Position';
;
export class ProfitCalculator {
    public Position: Position;
    public IsBuy: boolean;
    public NetPL = 0;
    public AccNetPL = 0;
    public AccGrossPL = 0;
    public NetGrossPL = 0;

    constructor (positon) {
        this.Position = positon;
        this.IsBuy = positon.BuySell === OperationType.Buy;// islong
    }
    // Long position P/L = Qty * Lot size * Cross price * (Current price  - Open price)
    // Short position P/L = Qty * Lot size * Cross price * (Open price  - Current price)

    public Calculate (): void {
    // Type 1
        if (isNaN(this.Position.CurPriceClose)) {
        // TODO  Костылище!!!!!!!!!!!!!
            const last = this.Position.Instrument.Level1.GetLastPrice(this.Position.Account);
            if (!isNaN(last) && last !== this.Position.CurPriceClose) {
                this.Position.CurPriceClose = last;
                this.Position.lastPriceUpdated = true;
            }
        }

        // this.AccNetPL = this.CalculateAccNetPLByQty(this.Position.Amount)

        // let crossInstrumentUSD = DataCache.CrossRateCache.GetCrossPriceExp1(this.Position.Account.assetBalanceDefault.Asset.Name);

        // this.NetPL = this.AccNetPL * crossInstrumentUSD;

        // this.AccGrossPL = this.AccNetPL;
        // this.NetGrossPL = this.AccNetPL * crossInstrumentUSD;   // #87945

        // this.AccNetPL += this.Position.Swaps - this.Position.Commission;    // #88118

        const result = ProfitCalculator.GetPnLByPosition(this.Position);
        this.AccNetPL = result.netProfitAcc;
        this.NetPL = result.netProfitUSD;
        this.AccGrossPL = result.profitAcc;
        this.NetGrossPL = result.profitUSD;
    }

    public CalculateAccNetPLByQty (Qty) {
        return ProfitCalculator.GetGrossPLByPositionAndCustomQuantity(this.Position, Qty);
    }

    public static CalculateSLTP (Qty, CurPriceClose, OpenPrice, ins: Instrument, account: Account, isBuy, OpenCrossPrice, productType: ProductType | null | undefined) {
        const side = OperationType[isBuy ? 'Buy' : 'Sell'];
        const curProductType: ProductType | null = !isNullOrUndefined(productType) ? productType : null;
        const pnl = ProfitCalculator.GetPnL(new ProfitInputParams(ins, account, side, Qty, OpenPrice, OpenCrossPrice, CurPriceClose, null, null, curProductType));
        return pnl.profitAcc;
    }
    // Long position P/L = Qty * Lot size * (Current price * Current cross price - Open price * Open cross price)
    // Short position P/L = Qty * Lot size * (Open price * Open cross price - Current price * Current cross price)

    public static GetUnitVolume (amount, lotSize): number {
        return amount * lotSize;
    }

    public static GetAmountExp1 (side, amount, lotSize): number {
        return ProfitCalculator.GetUnitVolume(amount, lotSize) * (side == OperationType.Buy ? 1 : -1);
    }

    public static GetAmountExp1ByPosition (pos: Position): number {
        return ProfitCalculator.GetAmountExp1(pos.BuySell, pos.Amount, pos.Instrument.LotSize);
    }

    public static GetAmountExp2Close (ins: Instrument | null, side, qty, closePrice): number {
        const roundClosePrice = ins ? ins.roundPrice(closePrice) : closePrice;
        const lotSize = ins ? ins.LotSize : 1;
        return ProfitCalculator.GetAmountExp1(side, qty, lotSize) * roundClosePrice;
    }

    public static GetAmountExp2CloseByPosition (pos: Position): number {
        return ProfitCalculator.GetAmountExp2Close(pos.Instrument, pos.BuySell, pos.Amount, MathUtils.ProcessNaN(pos.CurPriceClose));
    }

    public static GetAmountExp2Open (side, qty, lotSize, openPrice): number {
        const roundOpenPrice = MathUtils.TruncateDouble(openPrice, InstrumentUtils.MAX_PRECISION_ON_SERVER);
        return -ProfitCalculator.GetAmountExp1(side, qty, lotSize) * roundOpenPrice;
    }

    public static GetAmountExp2OpenByPosition (pos: Position): number {
        return ProfitCalculator.GetAmountExp2Open(pos.BuySell, pos.Amount, pos.Instrument.LotSize, pos.Price);
    }

    public static GetPnL (inputParams: ProfitInputParams) {
        const account = inputParams.Account;
        const instrument = inputParams.Instrument;
        const dataCache = instrument.DataCache;
        const productType = inputParams.ProductType;
        const side = inputParams.Side;
        const crossInstrumentAccDouble = dataCache.CrossRateCache.GetCrossPriceExp1Exp2(instrument.Exp2, account.BaseCurrency);
        const crossInstrumentAccDecimal = crossInstrumentAccDouble;
        const openCrossPriceDecimal = inputParams.OpenCrossPrice;
        const crossAccountUSD = dataCache.CrossRateCache.GetCrossPriceExp1(account.BaseCurrency);
        const crossInstrumentUSD = dataCache.CrossRateCache.GetCrossPriceIns(instrument);
        const ticCost = instrument.GetTickCost();
        const amount = inputParams.Amount;

        const lotSize = instrument ? instrument.LotSize : 1;
        const amountExp2OpenDecimal = ProfitCalculator.GetAmountExp2Open(side, amount, lotSize, inputParams.OpenPrice);
        const amountExp2CloseDecimal = ProfitCalculator.GetAmountExp2Close(instrument, side, amount, inputParams.ClosePrice);

        const markupObj = account.CrossratesPlan && instrument ? account.CrossratesPlan.GetMarkup(instrument.Exp2, account.BaseCurrency, crossInstrumentAccDouble, instrument, side == OperationType.Buy) : { markup: 0, formula: MarkupFormula.None };
        const markupInstrumentAcc = markupObj.markup;
        const formula = markupObj.formula;

        let profitUSD: number, profitAcc: number;
        let netProfitUSD: number, netProfitAcc: number;

        const rps = account.RiskPlan.riskPlanCache.getRiskPlanSettings(instrument, productType, 'UseSameCrossPriceForOpenClose');
        const UseSameCrossPriceForOpenClose = rps.getValue(productType, 'UseSameCrossPriceForOpenClose');

        if (UseSameCrossPriceForOpenClose || openCrossPriceDecimal == 0) {
        // профит в серверной валюте, без маркапа (разница цен * кросс)
            profitUSD = ((amountExp2OpenDecimal + amountExp2CloseDecimal) * crossInstrumentUSD * ticCost);

            if (formula == MarkupFormula.OpenAndClose) {
            // 1я формула, маркап применяется к обоим кроссам открытия и закрытия
                profitAcc = ((amountExp2OpenDecimal * (crossInstrumentAccDecimal + markupInstrumentAcc) + amountExp2CloseDecimal * (crossInstrumentAccDecimal - markupInstrumentAcc)) * ticCost);
            } else if (formula == MarkupFormula.Pnl || formula == MarkupFormula.MarginOpenAndClose) {
            // знак перед маркапом определяется знаком текущего пнл
                const pnl = amountExp2OpenDecimal + amountExp2CloseDecimal;
                profitAcc = (pnl * (crossInstrumentAccDecimal - Math.sign(pnl) * Math.abs(markupInstrumentAcc)) * ticCost);
            } else {
            // без маркапа (разница цен * кросс)
                profitAcc = (amountExp2OpenDecimal + amountExp2CloseDecimal) * crossInstrumentAccDecimal * ticCost;
            }
        } else {
        // профит в серверной валюте, без маркапа (открыте*кросс1 + закрыте*кросс2 )
            profitUSD = (amountExp2OpenDecimal * openCrossPriceDecimal * crossAccountUSD + amountExp2CloseDecimal * crossInstrumentUSD) * ticCost;

            if (formula == MarkupFormula.OpenAndClose) {
            // маркапим кросс закрытия, открытие уже сервер промаркапил
                profitAcc = (amountExp2OpenDecimal * openCrossPriceDecimal + amountExp2CloseDecimal * (crossInstrumentAccDecimal - markupInstrumentAcc)) * ticCost;
            } else {
            // для всех других случаев - без маркапа
                profitAcc = (amountExp2OpenDecimal * openCrossPriceDecimal + amountExp2CloseDecimal * crossInstrumentAccDecimal) * ticCost;
            }
        }

        // профит (как положительный так и отрицательный) - это базис
        // если комиссия положительна, это деньги, которые СНИМУТСЯ со счета (если отриц. - добавятся) потому "-комиссия"
        // свопы наоборот, положителен, если наутро деньги будут прибавлены, поэтому "+своп"
        const swaps = inputParams.Swaps || 0;
        const commissions = inputParams.Commissions || 0;

        netProfitUSD = profitUSD + ((swaps - commissions) * crossAccountUSD);
        netProfitAcc = profitAcc + (swaps - commissions);

        return { profitUSD, profitAcc, netProfitUSD, netProfitAcc };
    }

    public static GetPnLByPosition (position: Position) {
        return ProfitCalculator.GetPnL(ProfitInputParams.CreateFromPosition(position));
    }

    public static GetGrossPLByPositionAndCustomQuantity (position: Position, qty): number {
        const inputParams = ProfitInputParams.CreateFromPosition(position);
        inputParams.Amount = qty;

        const pnlFull = ProfitCalculator.GetPnL(inputParams);

        return pnlFull.profitAcc;
    }
}

export class ProfitInputParams {
    Instrument: Instrument;
    Account: Account;
    Side: any;
    Amount: any;
    OpenPrice: any;
    OpenCrossPrice: any;
    ClosePrice: any;
    Swaps: any;
    Commissions: any;
    ProductType: ProductType;

    constructor (instrument, account, side, amount, openPrice, openCrossPrice, closePrice, swaps, commissions, productType) {
        this.Instrument = instrument;
        this.Account = account;
        this.Side = side;
        this.Amount = amount;
        this.OpenPrice = openPrice;
        this.OpenCrossPrice = openCrossPrice;
        this.ClosePrice = closePrice;
        this.Swaps = swaps;
        this.Commissions = commissions;
        this.ProductType = productType;
    }

    public static CreateFromPosition (pos: Position): ProfitInputParams {
        return new ProfitInputParams(pos.Instrument, pos.Account, pos.BuySell, pos.Amount, pos.Price, pos.OpenCrossPrice, pos.CurPriceCloseNotRounded, pos.Swaps, pos.Commission, pos.ProductType);
    }
}
