// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectSubscriptionStrategyMessage } from '../../../../Utils/DirectMessages/DirectSubscriptionStrategyMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class SubscriptionStrategyMessageProccesor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): DirectSubscriptionStrategyMessage[] {
        const message = new DirectSubscriptionStrategyMessage();

        const len = fieldSet.Count();

        for (let i = 0; i < len; i++) {
            const fixField = fieldSet.fieldSet[i];
            if (fixField.FieldId === 0) {
                continue;
            }

            if (fixField.FieldId === FieldsFactory.FIELD_TRADING_SYSTEM_ID) {
                message.TradingSystemID = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_TRADING_STRATEGY_SUBSCRIPTION_STATUS) {
                message.StrategySubscriptionStatus = fixField.Value;
            }
        }

        return [message];
    }
}
