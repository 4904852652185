// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { MainWindowManager } from "../Controls/UtilsClasses/MainWindowManager.ts";
import { Connection } from "../Commons/Connection.ts";
import { ConnectionStates } from "../Commons/ConnectionEnums.ts";
import { CustomEvent } from "../Utils/CustomEvents.ts";
import { ErrorInformationStorage } from "../Commons/ErrorInformationStorage.ts";
import { CurrentLang, Resources } from "../Commons/properties/Resources.ts";
import { ThemeManager } from "../Controls/misc/ThemeManager.ts";
import { contextMenuHandler } from "../Utils/AppHandlers.js";
import { BOTTOM_WINDOWS_MARGIN, TOP_WINDOWS_MARGIN, WINDOW_SIDE_BORDERS_WIDTH } from "../Controls/UtilsClasses/SizeConstants.ts";
import { MainWindowBase } from "../Controls/screen/MainWindowBase.ts";
import { PortfolioManagementTemplate } from "../templates.js";
import { SoundManager } from "../Utils/SoundManager.ts";
import { TradingLockUtils } from "../Utils/TradingLockUtils.ts";
import { TradingToolViewBase } from "../Chart/Tools/TradingTools/TradingToolViewBase.ts";
import { UserWebStorage, UserWebStorageInstance } from "../user-web-storage.ts";
import { DataCache } from "../Commons/DataCache.ts";
import { SessionSettings } from "../Commons/SessionSettings.ts";
import { TerceraInfoPlusMessageForm } from "../Controls/screen/TerceraInfoPlusMessageForm.js";
import { LinkedSystem } from "../Controls/misc/LinkedSystem.ts";
import { TerceraTimeoutMessageBox } from "../Controls/screen/TerceraTimeoutMessageBox.js";
import { PropertySetupScreen } from "../Controls/screen/PropertySetupScreen.js";
import { TerceraReconnectScreen } from "../Controls/screen/TerceraReconnectScreen.js";
import { GlobalAnimationFrameHandler } from "../Commons/GlobalAnimationFrameHandler.ts";
import { LocalStorage } from "../Commons/LocalStorage.ts";

export let PortfolioManagement = MainWindowBase.extend({
    el: '#mainWindow',
    template: PortfolioManagementTemplate,
    data: function ()
    {
        return {
            movable: false,
            showHeader: false,
            showFooter: false,
            resizable: false,
            canSelect: false,
            leftPictureUrl: '',
            rightPictureUrl: '',
            netTraderState: 'LM',
            inValue: 0,
            outValue: 0,
            currentTime: '10:57:38',
            currentTimeZoneOffset: '(UTC+02:00)',
            maxWorkspaceCount: 0,
            connectedStatus: true,
            pingText: "...",
            lockTradingBtnStyle: "js-button-locktrading",
            isHelpVisible: true,
            isReturnTabActive: false,

            leftTools: 0,
            widthTools: 0,
            isToolsVisible: true,

            isTopMetricsBar: true,
            isTopPreview: false,
            isBottomPreview: false
        };
    },
    activePanelRootDiv: null,
    onNetTraderModeChange: new CustomEvent(),
    toolsMenu: [],
    TimeoutCounterCycle: null,
    TimeoutCounterCycleTime: -1,
    ExternalScreenStorage: null
});

PortfolioManagement.prototype.getType = function () { return 'PortfolioManagement' };

//
// Вызывается один раз
//
PortfolioManagement.prototype.Initialize = function ()
{
    // Pens, brushes init except images.
    TradingToolViewBase.ThemeChanged();
    ThemeManager.preloadImagesAsync(PortfolioManagement.preloadImagesAsyncCallback);

    DataCache.OnReintialize.Subscribe(PortfolioManagement.Reinitialize);

    this.InitilizeThread();

    this.on('onTabClick', this.onTabClick)

    Utils.TradingLock.TradingLockChanged.Subscribe(this.updateTradingLockState, this);
};

PortfolioManagement.preloadImagesAsyncCallback = function ()
{
    TradingToolViewBase.ThemeChanged();
    MainWindowManager.MainWindow.themeChange();
};

PortfolioManagement.prototype.onTabClick = function (isReturnTabSelected)
{
    this.set('isReturnTabActive', isReturnTabSelected)
}

PortfolioManagement.prototype.onDragDrop = function (e)
{
    MainWindowManager.MainWindow.Controls.MetricsPanel.set('needPause', true);

    let moveAt = (e) =>
    {
        tmpdiv.style.left = e.pageX + 2 + 'px';
        tmpdiv.style.top = e.pageY + 2 + 'px';
    };

    let isTop = this.get("isTopMetricsBar");
    let whoPreview = isTop ? "isBottomPreview" : "isTopPreview";
    this.set(whoPreview, true)

    let tmpdiv = document.createElement("div");
    tmpdiv.className = 'drag-drop-metrics';
    document.body.appendChild(tmpdiv);
    moveAt(e.original);

    let mouseupAt = (e) =>
    {
        let metricPanel = document.getElementsByClassName("metrics_row")[0];
        let mainBody = document.getElementsByClassName("js-main-body")[0];
        let metricPage = document.getElementsByClassName("block-metrics " + whoPreview)[0];

        let hitTop = e.pageY > TOP_WINDOWS_MARGIN && e.pageY < TOP_WINDOWS_MARGIN + 44;
        let hitBot = e.pageY > mainBody.clientHeight + TOP_WINDOWS_MARGIN - 44 && e.pageY < mainBody.clientHeight + TOP_WINDOWS_MARGIN;
        let wherePut = isTop ? hitBot : hitTop;

        if (wherePut)
        {
            metricPage.appendChild(metricPanel);
            this.set("isTopMetricsBar", !isTop)
        }

        this.set(whoPreview, false)
        tmpdiv.remove();
        document.removeEventListener('mousemove', moveAt)
        document.removeEventListener('mouseup', mouseupAt)

        MainWindowManager.MainWindow.Controls.MetricsPanel.switchingRolling();
    };

    tmpdiv.ondragstart = function ()
    {
        return false;
    };
    document.addEventListener('mousemove', moveAt)
    document.addEventListener('mouseup', mouseupAt)
};

PortfolioManagement.prototype.updateTradingLockState = function ()
{
    var mainWindow = MainWindowManager.MainWindow;
    var tradingLocked = Utils.TradingLock.tradingLocked;

    mainWindow.set({ lockTradingBtnStyle: tradingLocked ? 'js-button-locktrading-active' : 'js-button-locktrading' })

    //mainWindow.Controls.popupBaloon.show(
    //    tradingLocked ?
    //    TerceraPopupBaloon.States.TradingLocked :
    //    TerceraPopupBaloon.States.TradingUnlocked);
};

//
// Вызывается каждый раз после релогина
//
PortfolioManagement.Reinitialize = function ()
{
    try
    {
        var self = MainWindowManager.MainWindow;

        self.updateToolstrip();
        SoundManager.tryPlaySound(SoundManager.SoundKeys.Welcome);
    }
    catch (ex)
    {
        ErrorInformationStorage.GetException(ex);
    }
};

PortfolioManagement.prototype.oninit = function ()
{
    MainWindowBase.prototype.oninit.apply(this);

    $(window).on('resize', this.updateSize.bind(this));
    this.updateSize();

    this.observe('activeControl', this.onActiveControlChanged);

    this.on('leftPictureSizeChanged', this.updateToolstrip);
};

PortfolioManagement.prototype.baseResizibleBTN = function ()
{
    let leftPicture = this.Controls.leftPicture;
    let tools = this.Controls.Tools;
    let leftTools = 0, widthTools = 0;

    let leftPictureData = null;
    if (leftPicture)
        leftPictureData = leftPicture.get();

    if (tools)
    {
        leftTools = leftPictureData ? leftPictureData.left + leftPictureData.width + 10 : 5;
        widthTools = tools.get("text").length * 8 + 10;
        this.set({
            leftTools: leftTools,
            widthTools: widthTools
        })
    }
};

PortfolioManagement.prototype.createMainMenuActions = function ()
{
    this.on("btnClickTerminal", function ()
    {
        let focusedTerminal = function ()
        {
            contextMenuHandler.off(Control.Events.LostFocus, focusedTerminal);
            MainWindowManager.MainWindow.Controls.Terminal.set("focused", false);
        }
        contextMenuHandler.on(Control.Events.LostFocus, focusedTerminal);
        contextMenuHandler.Show(this.terminalMenu, MainWindowManager.MainWindow.Controls.Terminal.get("left"), 45)
        MainWindowManager.MainWindow.Controls.Terminal.set("focused", true);
    })
    this.on("btnClickTools", function ()
    {
        let focusedTools = function ()
        {
            contextMenuHandler.off(Control.Events.LostFocus, focusedTools);
            MainWindowManager.MainWindow.Controls.Tools.set("focused", false);
        }
        contextMenuHandler.on(Control.Events.LostFocus, focusedTools);
        contextMenuHandler.Show(this.toolsMenu, MainWindowManager.MainWindow.Controls.Tools.get("left"), 45)
        MainWindowManager.MainWindow.Controls.Tools.set("focused", true);
    })
    // this.on("btnClickMore", function ()
    // {
    //     let focusedMore = function ()
    //     {
    //         contextMenuHandler.off(Control.Events.LostFocus, focusedMore);
    //         MainWindowManager.MainWindow.Controls.More.set("focused", false);
    //     }
    //     contextMenuHandler.on(Control.Events.LostFocus, focusedMore);
    //     contextMenuHandler.Show(this.moreMenu, MainWindowManager.MainWindow.Controls.More.get("left"), 45);
    //     MainWindowManager.MainWindow.Controls.More.set("focused", true);
    // })

    this.on("helpClick", function ()
    {
        this.showHelp();
    })

    this.on("disconnectClick", function ()
    {
        this.disconnectClick();
    })
};

PortfolioManagement.prototype.oncomplete = function ()
{
    //Only event from MainWindowBase
    this.OnResize = new CustomEvent();

    this.ExternalScreenStorage = new ExternalScreenStorage();

    this.set({ netTraderState: PortfolioManagement.Mode.LoginMode });
    // this.set({ netTraderState: PortfolioManagement.Mode.WorkMode });
    this.observe('netTraderState', this.netTraderStateChange)
    Connection.onConnectStateChange.Subscribe(this.netTraderConnectChange, this);

    // В этот метод перенести всю инициализацию??
    this.Initialize();

    Resources.onLocaleChange.Subscribe(this.localize, this);
    ThemeManager.onThemeChange.Subscribe(this.themeChange, this);

    this.mouseMoveHandler = this.mouseMoveHandler.bind(this);
    this.baseResizibleBTN();
    this.createMainMenuActions();
};

PortfolioManagement.prototype.onActiveControlChanged = function (newVal)
{
    this.activePanelRootDiv = newVal ? newVal.find('div') : null;
};

PortfolioManagement.prototype.onMouseUp = function (event)
{
    var activeControl = this.get('activeControl');
    if (!activeControl) return;

    if (activeControl.get('movable'))
    {
        //TODO MB
        let activeControlactiveControl = activeControl.get('activeControl');
        if (activeControlactiveControl)
            activeControlactiveControl.set('onProcessMove', false);

        activeControl.set('onProcessMove', false);
        delete activeControl.movingParameters;

        var lastMouseMoveX = this.lastMouseMoveX;
        var lastMouseMoveY = this.lastMouseMoveY;

        if (lastMouseMoveX !== undefined && lastMouseMoveY !== undefined)
        {
            activeControl.setLocation(lastMouseMoveX, lastMouseMoveY);
            delete this.lastMouseMoveX;
            delete this.lastMouseMoveY;
            delete this.lastMouseMoveEvent;
        }
    }

    if (activeControl.get('resizable'))
    {
        activeControl.onProcessResize = false;
        delete activeControl.onProcessResize;
        // Inform controls about end resizing
        this.onResizeEnd();
    }
};

PortfolioManagement.prototype.onMouseMove = function (event)
{
    this.lastMouseMoveEvent = event;
    // In order to increase smoothness of panel's movement.
    cancelAnimationFrame(this.mouseMoveAnimationFrameId);
    this.mouseMoveAnimationFrameId = requestAnimationFrame(this.mouseMoveHandler);
};

PortfolioManagement.prototype.mouseMoveHandler = function ()
{
    if (!this.get)
        return;
    var activeControl = this.get('activeControl');
    if (!activeControl) return;

    var event = this.lastMouseMoveEvent;
    if (!event) return;

    if (activeControl.get('onProcessMove') === true)
    {
        var myParams = activeControl.movingParameters;
        var ev = event.original;
        var X_Shift = myParams.startMoveCoordX - ev.pageX;
        var Y_Shift = myParams.startMoveCoordY - ev.pageY;

        var lastMouseMoveX = myParams.startMyX - X_Shift;
        var lastMouseMoveY = myParams.startMyY - Y_Shift;

        var windowSideBordersW = WINDOW_SIDE_BORDERS_WIDTH;
        // New window left.
        var activeControlW = activeControl.get('width') + windowSideBordersW;
        var mainWindowW = this.width;

        if (lastMouseMoveX + activeControlW > mainWindowW)
            lastMouseMoveX = mainWindowW - activeControlW;

        if (lastMouseMoveX < 0)
            lastMouseMoveX = 0;

        // New window top.
        var activeControlH = activeControl.get('height') + windowSideBordersW;
        var topWindowsMargin = TOP_WINDOWS_MARGIN;
        var bottomWindowsMargin = this.height - BOTTOM_WINDOWS_MARGIN;

        if (lastMouseMoveY + activeControlH > bottomWindowsMargin)
            lastMouseMoveY = bottomWindowsMargin - activeControlH;

        if (lastMouseMoveY < topWindowsMargin)
            lastMouseMoveY = topWindowsMargin;

        this.lastMouseMoveX = lastMouseMoveX;
        this.lastMouseMoveY = lastMouseMoveY;

        // Workaround to avoid "heavy" ractive code.
        var rootPanelDiv = this.activePanelRootDiv;
        rootPanelDiv.style.left = lastMouseMoveX + 'px';
        rootPanelDiv.style.top = lastMouseMoveY + 'px';
    }

    if (activeControl.onProcessResize)
        activeControl.onResizing(event);
};

PortfolioManagement.prototype.updateSize = function ()
{
    var $window = $(window);
    // I don't use ractive.set here for quicker reading in future.
    this.width = $window.outerWidth();
    this.height = $window.outerHeight();

    this.updateLeftToolStripWidth();
};

PortfolioManagement.prototype.netTraderStateChange = function (newState, oldState, key)
{
    this.onNetTraderModeChange.Raise(newState);
    this.localize();
};

PortfolioManagement.prototype.netTraderConnectChange = function (newState, oldState, key)
{
    var newMode = this.get('netTraderState');
    switch (newState.connectionState)
    {
        case ConnectionStates.CONNECTED:

            // TODO. Refactor all this nightmare. So many entry points...
            let vendor = Connection.vendor;
            UserWebStorageInstance.reinit(vendor.GetLogin(), this.Controls.loginScreen.get('connectionParams.connectionName'));
            this.set({ connectedStatus: true });

            // Wait for finishblocktransfer
            let langValue = this.Controls.loginScreen.getLoginScreenLanguage();
            this.LoadingStarted(langValue);

            break;
        case ConnectionStates.DISCONNECTED:
            DataCache.cleanup()
            UserWebStorage.RestApiNotWork = false;
            SoundManager.tryPlaySound(SoundManager.SoundKeys.Finish);
            this.StopTimeoutCounter();
            let finishFunction = function ()
            {
                var controls = this.Controls;
                var len = controls.length;
                for (var key in controls)
                {
                    var control = controls[key];
                    if (control.forceCloseOnLogout)
                        this.removeControl(control);
                }

                // if (App.WorkSpaceManager.currentWorkspace)
                //     App.WorkSpaceManager.clearCurrentWorkSpace();

                newMode = PortfolioManagement.Mode.LoginMode;
                this.set({ netTraderState: newMode });
            }.bind(this);

            // Closing of dialog screens.
            if (MainWindowManager.AsyncCloseObject && MainWindowManager.AsyncCloseObject.AsyncClosePromise)
                MainWindowManager.AsyncCloseObject.AsyncClosePromise.then(finishFunction);
            else
                finishFunction();

            // if (App.WorkSpaceManager.currentWorkspace)
            // {
            //     // App.WorkSpaceManager.clearCurrentWorkSpace();
            //     if (Utils.TradingLock.LockTradingByPassword)
            //     {
            //         Utils.TradingLock.LockTradingByPassword = false;
            //         Utils.TradingLock.updateTradingLock(false);
            //     }
            //     Resources.setNeedForceUpdateLocale();
            //     App.WorkSpaceManager.needRestoreWS = false;
            //     App.WorkSpaceManager.stopSaveLoop();
            //     MainWindowManager.MainWindow.StopPingLoop();
            //     App.UserWebStorage.clear();
            // }
            TradingLockUtils.IsUseLockTradingByPassword = false    // #89340 & #94446
            this.ExternalScreenStorage.Clear();
            break;
        case ConnectionStates.CONNECTION_LOST:
            DataCache.Loaded = false;
            DataCache.cleanup();
            UserWebStorage.RestApiNotWork = false;
            newMode = PortfolioManagement.Mode.WorkMode;
            this.NeedRestoreLockTradingByPassword = true;
            Resources.setNeedForceUpdateLocale();
            SoundManager.tryPlaySound(SoundManager.SoundKeys.ConnectionLost);
            this.set({ connectedStatus: false });
            TerceraReconnectScreen.startReconnection();
            // App.WorkSpaceManager.stopSaveLoop();
            this.StopPingLoop();
            MainWindowManager.MainWindow.set({ pingText: "..." });
            break;
    }

    this.set({ netTraderState: newMode });
};

PortfolioManagement.prototype.getClientPanel = function ()
{
    return this.Controls.topWindowBody.getClientPanel();
};

// PortfolioManagement.prototype.setToolstripItems = function (itemsLeft, itemsRight)
// {
//     var leftToolstrip = this.Controls.leftToolStip.Controls.leftToolStip;
//     if (leftToolstrip && itemsLeft)
//     {
//         leftToolstrip.set('toolStipItems', itemsLeft);
//         this.updateToolstrip();
//     }

//     var rightToolstrip = this.Controls.rigthToolStip.Controls.rigthToolStip;
//     if (rightToolstrip && itemsRight)
//         rightToolstrip.set('toolStipItems', itemsRight);
// };

// PortfolioManagement.prototype.getAllowedTerminalMenu = function ()
// {
//     let tMenu = [],
//         panelsVisibility = Factory.GetPanelsVisibility(),
//         clickItemTerminal = function (sender) { Factory.addPanel(sender.type); };

//     if (panelsVisibility[PanelNames.ChartPanel] && !Resources.isHidden("ribbon.tradingTerminal.terceraChart"))
//         tMenu.push({ style: "js-list-chart", name: "chart", locKey: "panel.terceraChart", event: clickItemTerminal, type: PanelNames.ChartPanel });
//     if (panelsVisibility[PanelNames.InformerPanel] && !Resources.isHidden("ribbon.tradingTerminal.watchlist"))
//         tMenu.push({ style: "js-list-watchlist", name: "watchlist", locKey: "panel.watchlist", event: clickItemTerminal, type: PanelNames.InformerPanel });
//     if (panelsVisibility[PanelNames.MarketDepthPanel] && !Resources.isHidden("ribbon.tradingTerminal.level2"))
//         tMenu.push({ style: "js-list-marketdepth", name: "marketdepth", locKey: "panel.level2", event: clickItemTerminal, type: PanelNames.MarketDepthPanel });
//     if (panelsVisibility[PanelNames.TSPanel] && !Resources.isHidden("ribbon.tradingTerminal.timeSales"))
//         tMenu.push({ style: "js-list-timeandsale", name: "timeandsale", locKey: "panel.timeSales.MenuName", event: clickItemTerminal, type: PanelNames.TSPanel });
//     if (!Resources.isHidden("ribbon.tradingTerminal.neworder"))
//     {
//         tMenu.push({ separator: true });
//         tMenu.push({ style: "js-list-orderentry", name: "orderentry", locKey: "panel.neworder", event: clickItemTerminal, type: PanelNames.AdvancedOrderEntry });
//         tMenu.push({ separator: true });
//     }
//     if (panelsVisibility[PanelNames.PositionsPanel] && !Resources.isHidden("ribbon.tradingTerminal.positions"))
//         tMenu.push({ style: "js-list-positions", name: "positions", locKey: "panel.positions", event: clickItemTerminal, type: PanelNames.PositionsPanel });
//     if (panelsVisibility[PanelNames.OrdersPanel] && !Resources.isHidden("ribbon.tradingTerminal.orders"))
//         tMenu.push({ style: "js-list-worckingorders", name: "worckingorders", locKey: "panel.orders", event: clickItemTerminal, type: PanelNames.OrdersPanel });
//     if (panelsVisibility[PanelNames.OrderHistoryPanel] && !Resources.isHidden("ribbon.tradingTerminal.orderHistory"))
//         tMenu.push({ style: "js-list-ordershistory", name: "ordershistory", locKey: "panel.orderHistory", event: clickItemTerminal, type: PanelNames.OrderHistoryPanel });
//     if (!Resources.isHidden("ribbon.tradingTerminal.statement"))
//         tMenu.push({ style: "js-list-statement", name: "statement", locKey: "panel.statement", event: clickItemTerminal, type: PanelNames.StatementPanel });
//     if (panelsVisibility[PanelNames.FilledOrdersPanel] && !Resources.isHidden("ribbon.tradingTerminal.trades"))
//         tMenu.push({ style: "js-list-filledorders", name: "filledorders", locKey: "panel.trades", event: clickItemTerminal, type: PanelNames.FilledOrdersPanel });
//     if (panelsVisibility[PanelNames.AssetsPanel] && !Resources.isHidden("ribbon.tradingTerminal.positions"))
//         tMenu.push({ style: "js-list-assets", name: "assets", locKey: "panel.assets", event: clickItemTerminal, type: PanelNames.AssetsPanel });

//     this.terminalMenu = tMenu;
// };

PortfolioManagement.prototype.updateToolstrip = function ()
{
    if (!this.toolsMenu.length)
        this.set("isToolsVisible", false);
};

PortfolioManagement.prototype.getLeftToolStripX = function ()
{
    var leftPic = this.Controls.leftPicture;
    if (!leftPic) return 0;

    return (leftPic.get('left') || 0) + (leftPic.get('width') || 0) + 10;
};

PortfolioManagement.prototype.updateLeftToolStripWidth = function ()
{
    var Controls = this.Controls;
    if (!Controls.leftToolStip || !Controls.leftToolStip.Controls.leftToolStip)
        return;

    var ts = Controls.leftToolStip.Controls.leftToolStip;
    var leftPic = Controls.leftPicture;
    var tsLeft = this.getLeftToolStripX();

    var tsMarginRight = 440;

    // Taken from css class .js-main-top.
    var minWindowW = 1024;
    var windowW = this.width < minWindowW ? minWindowW : this.width;

    var tsW = windowW - tsLeft - tsMarginRight;
    if (tsW < 0) tsW = 0;
    ts.set('width', tsW);
};

// PortfolioManagement.prototype.restoreToolstripWorkMode = function ()
// {
//     // var itemsObj = this.generateToolstripWorkModeItems();
//     this.setToolstripItems(
//         itemsObj.itemsLeft,
//         itemsObj.itemsRight);

//     // http://tp.pfsoft.lan/entity/56946
//     var rightToolstrip = this.Controls.rigthToolStip.Controls.rigthToolStip;
//     rightToolstrip.set('left', 360);
// };

PortfolioManagement.Mode = {
    LoginMode: 'LM',
    WorkMode: 'WM',
    ReloginMode: 'RM',
    ASPAdminMode: 'AM'
};
Object.freeze(PortfolioManagement.Mode);

PortfolioManagement.prototype.disconnectClick = function ()
{
    this.disconnect();
};

PortfolioManagement.prototype.disconnect = function (isNoNActivity)
{
    this.set({ netTraderState: PortfolioManagement.Mode.LoginMode });
    let finalPart = function ()
    {
        Resources.setNeedForceUpdateLocale();
        MainWindowManager.MainWindow.StopPingLoop();
        Connection.disconnect();
        UserWebStorageInstance.clear();
        LinkedSystem.Clear();
    }.bind(this)

    // if (!SessionSettings.executeAfterSaveEnd(finalPart))
    finalPart()     // дожидаемся окончания SessionSettings.save() (подробности в обсуждении https://tp.traderevolution.com/entity/90138)
};

PortfolioManagement.prototype.aspAdminDisconnect = function ()
{
    this.set('netTraderState', PortfolioManagement.Mode.LoginMode);
    // this.restoreToolstripWorkMode();
};

PortfolioManagement.prototype.onChangePassLogout = function ()
{
    Connection.disconnect();
    // App.WorkSpaceManager.clearCurrentWorkSpace();

    this.set('netTraderState', PortfolioManagement.Mode.LoginMode);
    this.Controls.loginScreen.loadCredentials();
};

// PortfolioManagement.prototype.onDisconnectCancelCallback = function (showNextTime)
// {
//     SessionSettings.updateWarningOnApplicationClose(showNextTime);
// };

PortfolioManagement.prototype.localize = function ()
{
    if (this.Controls)
    {
        this.Controls.loginScreen.localize();

        // if (this.Controls.accountWidget && this.Controls.accountWidget.myAccountDetailsPanel)
        //     this.Controls.accountWidget.myAccountDetailsPanel.localize()
    }

    // App.WorkSpaceManager.localize();
    // App.datePickerDropDown.localize();

    this.set({
        headerLabelMyPortfolio: Resources.getResource('potrfolio.management.headerLabel.MyPortfolio'),
        localkeyTerminal: Resources.getResource('ribbon.tradingTerminal'),
        localkeyTools: Resources.getResource('ribbon.tools'),
        // localkeyMore: Resources.getResource('ribbon.help.help'),
        localkeyHelp: Resources.getResource('ribbon.enviroment.help'),
        localkeySettings: Resources.getResource('ribbon.enviroment.settings'),
        localkeyLogout: Resources.getResource('ribbon.enviroment.logout'),
        isHelpVisible: !Resources.isHidden("ribbon.enviroment.help")
    });

    this.baseResizibleBTN();

    // let toolMenu = this.toolsMenu
    // for (let i = 0; i < toolMenu.length; i++)
    //     this.localizeExternalLinkMenuItem(toolMenu[i])
};

// PortfolioManagement.prototype.localizeExternalLinkMenuItem = function (menuItem)
// {
//     let menuText = this.getExternalLocalize(menuItem)

//     menuItem.text = menuText || Resources.getResource(menuItem.locKey)

//     if (menuItem.icotxt)
//         menuItem.icotxt = this.getIcoTxtFromToolName(menuItem.text)
// }

PortfolioManagement.prototype.getExternalLocalize = function (menuItem, isTabName)
{
    let currentLang = CurrentLang,
        menuText = null

    if (isTabName)
    {
        for (let j = 0; j < menuItem.length; j++)
        {
            let item = menuItem[j]
            if (item.aliases)
            {
                let aliases = item.aliases
                for (let j = 0; j < aliases.length; j++)
                    if (aliases[j].lang === currentLang)
                        return aliases[j].tabName
            }
        }
    }

    if (menuItem.aliases)
    {
        let aliases = menuItem.aliases
        for (let j = 0; j < aliases.length; j++)
            if (aliases[j].lang === currentLang)
                menuText = aliases[j].toolName
    }

    return menuText
}

PortfolioManagement.prototype.themeChange = function ()
{
    // App.WorkSpaceManager.themeChange();

    this.set({
        leftPictureUrl: ThemeManager.getImageFullUrl('darkNew/b_toolbar/logo.png'),
    });
};

PortfolioManagement.prototype.showGeneralSetting = function ()
{
    PropertySetupScreen.editProperty(SessionSettings, 'screen.properties.title');
};

//#endregion

PortfolioManagement.prototype.showHomePage = function ()
{
    window.open(Resources.getResource('homePage.link'))
};

PortfolioManagement.prototype.openLiveAccount = function ()
{
    window.open(Resources.getResource('ribbon.liveAccount.link'))
};

PortfolioManagement.prototype.showHelp = function ()
{
    window.open(Resources.getResource('helpPage.link'))
};

PortfolioManagement.prototype.recreateMainMenu = function ()
{
    this.createToolsMenu()
};

PortfolioManagement.prototype.navigateToKnowledgeBase = function ()
{
    let kbDiv = document.getElementById('kb')
    if (!kbDiv) return

    let kbUrl = kbDiv.dataset.kb
    if (!kbUrl) return

    let win = window.open(kbUrl, '_blank')
    if (win) win.focus()
};

PortfolioManagement.prototype.InitilizeThread = function ()
{
    GlobalAnimationFrameHandler();
};

PortfolioManagement.prototype.onContextMenuOpen = function (event)
{
    if (this.get('netTraderState') !== PortfolioManagement.Mode.WorkMode)
        return false;
    if (!event.original.path)
        return false;
    var firstNodeInEventPath = event.original.path[0];
    if (!firstNodeInEventPath || firstNodeInEventPath.className !== 'tab-content')
        return false;

    //PortfolioManagement.showWorkSpaceMenu(event);
    return false;
};

PortfolioManagement.OnAddOvernightMarginNotificationMessage = function (messData)
{
    if (SessionSettings.warning.overnightMarginNotificationMessage)
    {
        TerceraInfoPlusMessageForm.Show(
            messData.lst,
            messData.redText,
            messData.message,
            messData.caption);

            SoundManager.tryPlaySound(SoundManager.SoundKeys.Warning);
    }
};

PortfolioManagement.prototype.addExternalMenuItems = function ()
{
    let x = 0;
}

PortfolioManagement.prototype.LoadingStarted = function (loginScreenLang)
{
    //TODO  84288 84326
    //Подумать над правильным удалением панелей и чистке предыдущих кешей

    // let brandingKey = App.Connection.vendor.loginParams.brandingKey     //  #86122 #85820 #86776 #87660 #87238
    // this.recreateMainMenu()
    // if (brandingKey)
    //     DataCache.GetBrandingRules(brandingKey)
    //         .then(function (logoutTime)
    //         {
    //             this.StartTimeoutCounter(logoutTime)
    //         }.bind(this))


    DataCache.StartLoadingWaiting()
        .then(function ()
        {
            MainWindowManager.MainWindow.set('netTraderState', PortfolioManagement.Mode.WorkMode);
            MainWindowManager.MainWindow.StartPingLoop();
            return Promise.resolve()
        }.bind(this))
        .then(function ()
        {
            return SessionSettings.loadPortfolioSettings()
                .then(function ()
                {
                    // return SessionSettings.RestoreSettings(loginScreenLang);  //TODO settings
                    return Promise.resolve()
                })

        })
        .then(() => // Место запуска всего говна после логина
        {
            MainWindowManager.MainWindow.Controls.MetricsPanel.startWorkMetrics();
            MainWindowManager.MainWindow.Controls.PortfolioSummary.startWorkSummary();
            MainWindowManager.MainWindow.Controls.PortfolioPerformance.onResize();
            MainWindowManager.MainWindow.Controls.PortfolioReturns.layoutTable();

            // PortfolioPerformance.startWorkPerformance();
            // PortfolioReturns.startWorkReturns();
        })
}

PortfolioManagement.prototype.StartPingLoop = function ()
{
    let loop = function ()
    {
        Connection.SendPing().then(function (value)
        {
            MainWindowManager.MainWindow.set({ pingText: value.toFixed(0) + " ms" });
        });
    }
    loop();
    this.pingInterval = setInterval(loop, 10000);
}

PortfolioManagement.prototype.StopPingLoop = function ()
{
    clearInterval(this.pingInterval);
}

PortfolioManagement.prototype.createToolsMenu = function ()
{
    this.toolsMenu = []
}

// PortfolioManagement.prototype.getIcoTxtFromToolName = function (toolName)    //#87369
// {
//     let result = ''

//     if (!toolName)
//         return result

//     let splitArr = toolName.split(' '),
//         words = []

//     for (let i = 0; i < splitArr.length; i++)       // защита от дебилов
//         if (splitArr[i])
//             words.push(splitArr[i])

//     if (words.length == 1)
//     {
//         result = words[0].substr(0, 2).toUpperCase()
//     }
//     else if (words.length >= 2)
//     {
//         result = (words[0].substr(0, 1) + words[1].substr(0, 1)).toUpperCase()
//     }

//     return result
// }

PortfolioManagement.prototype.TimeoutEventsHandler = function ()
{
    let w = MainWindowManager.MainWindow;
    if (w.TimeoutCounterCycle)
    {
        clearTimeout(w.TimeoutCounterCycle);
        if (w.TimeoutCounterCycleTime !== -1)
            w.TimeoutCounterCycle = setTimeout(w.TimeoutMessage.bind(w), w.TimeoutCounterCycleTime);
    }
}

PortfolioManagement.prototype.TimeoutMessage = function ()
{
    let scr = TerceraTimeoutMessageBox.Show()
    scr.OK_Handler.then(this.disconnect.bind(this, true))
    scr.NO_Handler.then(this.TimeoutEventsHandler.bind(this))
    this.NonFocusedAttraction();
}

PortfolioManagement.prototype.StartTimeoutCounter = function (time)
{
    if (time === -1 || isNaN(time) || !time)
        return;

    document.addEventListener("keydown", this.TimeoutEventsHandler)
    document.addEventListener("mousedown", this.TimeoutEventsHandler)

    this.TimeoutCounterCycleTime = 1000 * 60 * time
    this.TimeoutCounterCycle = setTimeout(this.TimeoutMessage.bind(this), this.TimeoutCounterCycleTime);
}

PortfolioManagement.prototype.StopTimeoutCounter = function ()
{
    document.removeEventListener("keydown", this.TimeoutEventsHandler)
    document.removeEventListener("mousedown", this.TimeoutEventsHandler)

    this.TimeoutCounterCycleTime = -1;
    if (this.TimeoutCounterCycle)
        clearTimeout(this.TimeoutCounterCycle);
}

PortfolioManagement.prototype.NonFocusedAttraction = function ()
{
    let title = document.title;
    let symbol = '\u200E';
    function z ()
    {
        if (document.hasFocus())
        {
            document.title = title;
            if (this.NonFocusedAttractionLoop)
                clearInterval(this.NonFocusedAttractionLoop);
            return;
        }

        if (document.title !== symbol)
            document.title = symbol;
        else
            document.title = title;
    }
    this.NonFocusedAttractionLoop = setInterval(z.bind(this), 300);
}

PortfolioManagement.LogInPortfolio = function (window, loginParams)         // для вызова вкладки с портфолио с основного приложения из меню Account
{
    if (!loginParams)
        return

    LocalStorage.setTokenForPortfolio(loginParams.accessToken);

    let url = window.location.href + 'myportfolio'

    const portfolioWindow = window.open(url)
    MainWindowManager.portfolioWindow = portfolioWindow        // сохраняем чтоб отработать необходимое поведение в случае дисконнекта на основной вкладке
}

PortfolioManagement.RecalculateCounter = 0;