// Copyright TraderEvolution Global LTD. © 2017-2023. All rights reserved.

import { TerceraGroupPanelTemplate } from "../../templates.js";
import { ContainerControl } from "./ContainerControl.js";
import Ractive from "ractive";

export let TerceraGroupPanel = ContainerControl.extend({
    template: TerceraGroupPanelTemplate
});

TerceraGroupPanel.prototype.getType = function () { return 'TerceraGroupPanel' };

TerceraGroupPanel.prototype.getClientPanel = function (customPlace)
{
    this.set("showRightContainer", customPlace);

    if (customPlace)
        return this.find(".js-group-panel-right-container");
    else
        return this.find(".js-group-panel-left-container");
};

Ractive.components.terceraGroupPanel = TerceraGroupPanel;