// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from "../../Utils/CustomEvents.ts";
import { Resources } from "../../Commons/properties/Resources.ts";
import { LinkedSystem } from "../misc/LinkedSystem.ts";
import { KeyCode, KeyEventProcessor } from "../../Commons/KeyEventProcessor.ts";
import { InformerPanelTopTemplate } from "../../templates.js";
import { Infor } from "../cache/BaseInformer.ts";
import { TerceraInstrumentLookupDropDownForm } from "../elements/Lookup/TerceraInstrumentLookupDropDownForm.js";
import { ColouringModes } from "../elements/QuickTable/QuickTableColumn.ts";
import { TerceraQuickTree, TerceraQuickTreeEvents } from "../elements/QuickTree/TerceraQuickTree.ts";
import { TerceraButton } from "../elements/TerceraButton.ts";
import { TerceraLinkControlConstants } from "../UtilsClasses/TerceraLinkControlConstants.ts";
import { TerceraMenu } from "../elements/TerceraMenu.ts";
import { ExternalScreen } from "../screen/ExternalScreen.ts";
import { TerceraMessageBox } from "../screen/TerceraMessageBox.js";
import { TerceraQuickInstrumentLookupScreen } from "../screen/TerceraQuickInstrumentLookupScreen.js";
import { PanelLocKeys, PanelNames } from "../UtilsClasses/FactoryConstants.ts";
import { LookupDropDownShowParams } from "../UtilsClasses/LookupDropDownShowParams.js";
import { ApplicationPanelNew } from "./ApplicationPanelNew.js";
import { OrderType } from "../../Utils/Trading/OrderType.ts";
import { DynProperty } from "../../Commons/DynProperty.ts";
import { InterTraderBtnStatus } from "../../Utils/Instruments/InterTraderBtnStatus.ts";
import { DataCache } from "../../Commons/DataCache.ts";
import { TerceraSymbolLookupBaseDataProvider } from "../../Commons/NoNFixedListCore.ts";
import { SessionSettings } from "../../Commons/SessionSettings.ts";
import { CustomerAccess } from "../../Commons/CustomerAccess/CustomerAccess.ts";
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";
import { DateTimeConvertor } from "../../Utils/Time/DateTimeConvertor.ts";
import { TerceraLookup } from "../elements/Lookup/TerceraLookup.ts";
import { ApplicationInfo } from "../../Commons/ApplicationInfo.ts";
import { IsAllowed, IsAllowedResponce } from "../../Commons/IsAllowed.ts";
import { IsAllowedResponceReason } from "../../Commons/IsAllowedResponceReason.ts";
import { Md5 } from "ts-md5";

export let InformerPanel = ApplicationPanelNew.extend(
    {
        data: function ()
        {
            return {
                isSymbolLinkShow: true,
                isAccountLinkShow: true,
                canFilterByAccount: false,
                isEnabledCombobox: true,
                editableCBtooltip: 'editableComboBox.tooltip',
                items: []
            };
        },
        partials: {
            bodyPartial: InformerPanelTopTemplate
        },
        headerLocaleKey: 'panel.informer',
        SymbolInfoPanelAllowed: false,
        OnInstrumentChange: null,

        moreThanOneTTKey: 'panel.watchlist.menu.MoreThanOneSymbolSelected.tt',          // #95439
        noOneTTKey: 'panel.watchlist.menu.NoSymbolSelected.tt',

        quickInstrumentSelector: null,
        promiseHandler: null
    });

InformerPanel.prototype.getType = function () { return PanelNames.InformerPanel };

InformerPanel.prototype.oncomplete = function ()
{
    if (ApplicationPanelNew.prototype.oncomplete)
        ApplicationPanelNew.prototype.oncomplete.apply(this);

    this.SymbolInfoPanelAllowed = CustomerAccess.panelAllowed(PanelNames.SymbolInfoPanel);
    this.populateTableContextMenu();

    const qt = this.getQuickTable();
    qt.OnSelectionChanged.Subscribe(this.selectionChange, this);
    qt.OnPaintedPictureButtonClick.Subscribe(this.onPaintedPictureButtonClick, this);
    this.quickTableRactive.on(TerceraQuickTreeEvents.DoubleClick, this.onDoubleClick.bind(this));

    let editCB = this.Controls.editableCB
    editCB.OnSelectList.Subscribe(this.selectInstrumentList, this)
    editCB.observe('items checkedMenuItem', this.updateSymbolLists.bind(this))        // context menu's lists update 

    KeyEventProcessor.OnKeyDown.Subscribe(this.onGlobalKeyDown, this);
    this.quickInstrumentSelector = null;

    editCB.InitByDefaultOrFirst()
    if (this.deferredCallbackProps)
    {
        this.callBack(this.deferredCallbackProps);
        delete this.deferredCallbackProps;
    }
};

InformerPanel.prototype.oninit = function ()
{
    ApplicationPanelNew.prototype.oninit.apply(this);
    this.dataProvider = new TerceraSymbolLookupBaseDataProvider();
    this.OnInstrumentChange = new CustomEvent();
    this.on('onAdd', this.onAdd);
    this.observe('isAccountLinkShow', (link) => { if (!link) this.set('account', DataCache.getPrimaryAccount()); });
};

InformerPanel.prototype.repopulate = function ()
{
    const qt = this.getQuickTable();
    this.visibleColumnChanged(qt.getVisibleColumn());

    var i;
    var rows = qt.rowsArray;
    var len = rows.length;
    var resArr = "";
    for (i = 0; i < len; i++)
        resArr = resArr + rows[i].item.InstrumentName() + ";";
    qt.ClearAll();
    this.addInstrumentsFromString(resArr);
};

InformerPanel.prototype.onGlobalKeyDown = function ()
{
    if (!this.get('focused')) return;

    var keyProc = KeyEventProcessor;
    if (keyProc.currentButton !== KeyCode.DELETE ||
        keyProc.WithModButtons())
        return;

    this.onRemoveSelected();
};

InformerPanel.prototype.dispose = function ()
{
    KeyEventProcessor.OnKeyDown.UnSubscribe(this.onGlobalKeyDown, this);

    this.Controls.editableCB.OnSelectList.UnSubscribe(this.selectInstrumentList, this)

    ApplicationPanelNew.prototype.dispose.apply(this);
};

InformerPanel.prototype.onAdd = function ()
{
    var obj = {};
    (TerceraLookup.prototype.setItems.bind(obj))(DataCache.Instruments);

    var params = new LookupDropDownShowParams();
    //SET ITEMS HERE
    params.items = obj.items;
    params.callBack = this.instrumentsDropDownCallback.bind(this);
    params.isMultiSelect = true;
    // params.isMultiSelectMode = true;
    params.isCentered = true;
    params.autoClose = false;
    params.parentPanel = this;
    params.dataProvider = this.dataProvider;
    TerceraInstrumentLookupDropDownForm.ShowForm(params);
};

InformerPanel.prototype.preparePopup = function ()
{
    ApplicationPanelNew.prototype.preparePopup.apply(this);
    const removeAllMenuItem = this.menuTagDict[InformerPanel.REMOVE_ALL];
    removeAllMenuItem.enabled = this.getQuickTable().rowsArray.length > 0;
};

InformerPanel.prototype.updatePanelHeader = function ()
{
    this.set({ header: Resources.getResource(this.headerLocaleKey) + (this.NeedCalculateRowCount ? ('  (' + this.rowCount + ')') : '') });
};

InformerPanel.prototype.AddInstrumentToTable = function (instrument)
{
    let qtRactive = this.quickTableRactive
    let qt = qtRactive ? qtRactive.quickTable : null
    if (!qt)
        return

    let dc = DataCache
    let sess = SessionSettings
    let rowDict = qt.rows

    let insFullName = instrument.GetInteriorID()
    let row = rowDict[insFullName]
    if (!row)
    {
        let item = new Infor(insFullName, dc, sess, false, this)
        qt.AddItem(item)
    }
}

InformerPanel.prototype.OnQuickTableEditingInfoMapChanged = function (itemID, newQuickTableEditingInfo)
{
    let qt = this.getQuickTable(),
        row = qt ? qt.getItemById(itemID) : null

    let cell = row && row.cells.length ? row.cells[0] : null, // ячейка нулевой колонки - Symbol для родительской строки текущего айтема
        controlTypeChanged = cell && cell.ReadOnly === !!newQuickTableEditingInfo
    if (controlTypeChanged)
    {
        cell.QuickTableEditingInfo = newQuickTableEditingInfo

        let item = row.item,
            ins = item ? item.GetCurrentInstrument() : null
        if (cell.QuickTableEditingInfo && ins)
            cell.QuickTableEditingInfo.GetDataHandler = ins.GetDelayedIconTooltip.bind(ins)

        cell.ReadOnly = !newQuickTableEditingInfo
        cell.isDirty = true
    }
}

InformerPanel.prototype.selectInstrumentList = function (InstrStr)
{
    this.onRemoveAll(true);
    this.addInstrumentsFromString(InstrStr)
}

InformerPanel.prototype.onInstrumentListChange = function ()
{
    let editableCB = this.Controls.editableCB
    let rows = this.getQuickTable().rowsArray;
    if (!editableCB || !editableCB.get('selectedItem'))
        return

    let currentList = editableCB.get('selectedItem'),
        allLists = editableCB.get('valueOfLists'),
        list = allLists[currentList.value]

    list.itemsStr = ''
    for (let i = 0; i < rows.length; i++)
        if (rows[i].item)
        {
            list.itemsStr += rows[i].item.InstrumentName(true)
            if (i + 1 < rows.length)
                list.itemsStr += ';'
        }

    editableCB.updateListsValue(null, false)
}

InformerPanel.prototype.onRemoveSelected = function ()
{
    const removeMenuItem = this.menuTagDict[InformerPanel.REMOVESYMBOL];

    let qtRactive = this.quickTableRactive;
    let qt = qtRactive ? qtRactive.quickTable : null;
    if (qt) qt.RemoveSelectedItems();

    this.onInstrumentListChange();
};

InformerPanel.prototype.getSelectedInstrumentID = function ()
{
    var ins = this.getInstrument(),
        result = ''

    if (ins)
        result = ins.GetInteriorID()

    return result
};

InformerPanel.prototype.onRemoveAll = function (skipUpdate)
{
    let qtRactive = this.quickTableRactive;
    let qt = qtRactive ? qtRactive.quickTable : null;
    if (!qt) return;

    qt.ClearRows();
    this.updatePanelHeader();

    if (!skipUpdate)
        this.onInstrumentListChange();
};

InformerPanel.prototype.onDoubleClick = function ()
{
    var ins = this.getSelectedInstrumentID();
    if (ins)
        // Open OE.
        MainWindowManager.Factory.addPanel(PanelNames.AdvancedOrderEntry, null, function (panel) { panel.symbolLink_In(ins); });
    // Open Lookup.
    else this.onAdd();
};

InformerPanel.prototype.updateSymbolLists = function (newItems)
{
    let editableCB = this.Controls.editableCB,
        newMenuItems = editableCB.createMenuItems()

    const symbolListsMenuItem = this.menuTagDict[InformerPanel.SYMBOL_LISTS];
    symbolListsMenuItem.subitems = newMenuItems
}

InformerPanel.prototype.populateTableContextMenu = function ()
{
    var items = [];

    items.push({
        locKey: 'panel.watchlist.menu.AddSymbol',
        event: this.onAdd.bind(this)
    });

    this.AddSymbolInfoContextMenuItemIfNeed(items, false)

    items = items.concat([
        {
            locKey: 'panel.watchlist.menu.RemoveInstrument',
            enabled: false,
            event: this.onRemoveSelected.bind(this),
            tag: InformerPanel.REMOVESYMBOL
        },
        {
            locKey: 'panel.watchlist.menu.clearAll',
            event: this.onRemoveAll.bind(this, false),
            enabled: false,
            tag: InformerPanel.REMOVE_ALL
        },
        { separator: true },
        {
            locKey: 'panel.watchlist.menu.SymbolLists',
            subitems: this.Controls.editableCB.menuItems,
            advParams: { isComboboxMenu: true, isEditableListComboBoxMenu: true },
            tag: InformerPanel.SYMBOL_LISTS
        }
    ]);

    items = this.AddOpeningPanelsCM(items);

    this.menuTagDict = TerceraMenu.createTagDictionary(items);
    this.getQuickTable().setTableContextMenuItems(items);

    this.localize()
};

InformerPanel.prototype.GetOpeningPanelsCMLocKeysSet = function ()
{
    let keysObj = {};

    keysObj[ApplicationPanelNew.OPEN_CHART] = 'panel.watchlist.menu.Chart';
    keysObj[ApplicationPanelNew.OPEN_MD] = 'panel.watchlist.menu.MarketDepth';
    keysObj[ApplicationPanelNew.OPEN_TS] = 'panel.watchlist.menu.TimeSales';
    keysObj[ApplicationPanelNew.OPEN_OE] = 'panel.watchlist.menu.OrderEntry';

    return keysObj;
};

InformerPanel.REMOVESYMBOL = 'removeSymbol';
InformerPanel.REMOVE_ALL = 'REMOVE_ALL';
InformerPanel.SYMBOL_LISTS = 'SYMBOL_LISTS';

InformerPanel.prototype.themeChange = function ()
{
    if (this.quickTableRactive)
        this.quickTableRactive.themeChange();
};

InformerPanel.prototype.localize = function ()
{
    ApplicationPanelNew.prototype.localize.apply(this);

    const qt = this.getQuickTable();
    var contextItems = qt.tableContextMenuItems;

    if (!contextItems || contextItems.length === 0)
        return;

    for (let i = 0; i < contextItems.length; i++)
    {
        let item = contextItems[i]
        if (item && item.locKey)
            item.text = Resources.getResource(item.locKey)
    }

    qt.tableContextMenuItems = contextItems;

    const symbolListsMenuItem = this.menuTagDict[InformerPanel.SYMBOL_LISTS];
    const subItems = symbolListsMenuItem.subitems;
    subItems[subItems.length - 1].text = Resources.getResource('editableComboBox.CreateNew');
    this.Controls.editableCB.localize()
};

InformerPanel.prototype.selectionChange = function ()
{
    const qt = this.getQuickTable();
    var selectedRowsId = qt.selectedRowIds;
    if (!selectedRowsId)
        return;

    var ins = "";
    var ids = selectedRowsId;
    if (ids.length > 0)
    {
        var row = qt.rows[ids[0]];
        ins = row.item.InstrumentName();
    }
    this.symbolLink_Out(this.isNewSubscriber(), ins);

    let enabled = !!ins,
        menuTagDict = this.menuTagDict

    const removeMenuItem = menuTagDict[InformerPanel.REMOVESYMBOL];
    removeMenuItem.enabled = enabled;
    this.SetOpeningPanelsCMEnability(menuTagDict, enabled);
};

InformerPanel.prototype.onPaintedPictureButtonClick = function (data, event)
{
    // if (data.controlType === DynProperty.DELAYED_PICTURE_RIGHT_AND_TEXT)    // #112155 -> при клики на delayed icon пока никаких действий, только отображение тултипа по ховеру
    // {
    // //     // App.mainWindow.ShowSymbolInfo(data.row.item.instrument, { X: event.pageX, Y: event.pageY })

    //     return
    // }

    if (data.controlType === DynProperty.ITCHART_ADVANCED)
    {
        let link = this.getITChartAdvancedLink()

        if (link)
        {
            if (DataCache.ExternalLinksCache.ITChartAdvancedExternalTool.useInternalBrowser)
                ExternalScreen.Show({ Text: link, GetRefreshURL: this.getITChartAdvancedLink.bind(this), ScreenName: 'ITChart Advanced', Aliases: null, Tool: DataCache.ExternalLinksCache.ITChartAdvancedExternalTool })
            else
                window.open(link)
        }

        return
    }

    if (data.controlType === DynProperty.ORDER_BUTTON || data.controlType === DynProperty.TRADE_BUTTON)
    {
        var ins = this.getInstrument(),
            insStr = ins ? ins.GetInteriorID() : '',
            isTrade = data.controlType === DynProperty.TRADE_BUTTON

        if (ins && ins.GetInterTraderButtonStatus(isTrade).status !== InterTraderBtnStatus.Open)
            return

        var orderType = OrderType[isTrade ? 'Market' : 'Limit']

        MainWindowManager.Factory.addPanel(PanelNames.AdvancedOrderEntry, null, function (panel)
        {
            panel.symbolLink_In(insStr);
            panel.selectOrderType(orderType)
        });

        return
    }
}

InformerPanel.prototype.isNewSubscriber = function ()
{
    var color = this.get('symbolLinkValue');
    if (color === TerceraLinkControlConstants.STATE_NONE) return false;

    return !LinkedSystem.getSymbol(color);
};

InformerPanel.prototype.symbolLink_Out = function (newSubscriber, instrument)
{
    if (!instrument)
        return;

    var color = this.get('symbolLinkValue');
    if (color !== TerceraLinkControlConstants.STATE_NONE)
        LinkedSystem.setSymbol(color, instrument, newSubscriber);

    this.OnInstrumentChange.Raise(instrument);
};

InformerPanel.prototype.callBack = function (newProperties)
{
    ApplicationPanelNew.prototype.callBack.call(this, newProperties);

    if (!this.deferredCallbackProps)
    {
        this.deferredCallbackProps = newProperties;
        return
    }

    let dp = DynProperty.getPropertyByName(newProperties, "selectedTemplate");
    let cb = this.Controls.editableCB;
    if (dp && dp.value && cb)
        cb.restoreCorrectSelectItem(dp.value);
};

InformerPanel.prototype.Properties = function ()
{
    let properties = ApplicationPanelNew.prototype.Properties.call(this);

    let cb = this.Controls.editableCB;
    if (cb)
    {
        let tmpItem = cb.get("selectedItem");
        let tmp = tmpItem ? tmpItem.text : null;
        if (tmp) properties.push(new DynProperty("selectedTemplate", tmp, DynProperty.STRING, DynProperty.HIDDEN_GROUP));
    }

    return properties;
}

InformerPanel.prototype.handleGetInstrumentPromises = async function (promises, skipEventRaiseParameter)
{
    if (this.promiseHandler) { this.promiseHandler.cancel(); }

    this.promiseHandler = Promise.all(promises).then((instruments) => this.responseFillRows(skipEventRaiseParameter, instruments));
}

InformerPanel.prototype.addInstrumentsFromString = async function (instrumentsString)
{
    if (isNullOrUndefined(instrumentsString)) { return; }

    const instruments = instrumentsString.split(';').filter(Boolean);
    this.handleGetInstrumentPromises(instruments.map(insName => this.dataProvider.getInstrumentByName(insName)).filter(Boolean), true);
}

InformerPanel.prototype.instrumentsDropDownCallback = async function (list)
{
    if (!Array.isArray(list)) { return; }

    this.handleGetInstrumentPromises(list.map(insName => this.dataProvider.getInstrument(insName)), false);
}

InformerPanel.prototype.responseFillRows = function (skip, instruments)
{
    const qt = this.getQuickTable();
    if (!qt) { return; }

    const allowedRowsNum = MainWindowManager.Factory.GetThrottlingOperationValue(this.getType(), true).rowsNumber;
    let alreadyAddedNum = qt.rowsArray ? qt.rowsArray.length : 0;

    for (let i = 0; i < instruments.length; i++)
    {
        const ins = instruments[i];
        if (ins && !ins.NeedToHide())
        {
            if (alreadyAddedNum >= allowedRowsNum && allowedRowsNum != -1)
            {
                return TerceraMessageBox.Show(Resources.getResource('workspace.information'),
                    Resources.getResource('Rows.firstPart') + ' ' + Resources.getResource(PanelLocKeys[this.getType()]) + ' ' + Resources.getResource('Rows.secondPart'),
                    TerceraMessageBox.msgType.Info, null, null, false, true);
            }

            if (!qt.rows[ins.GetInteriorID()])
                alreadyAddedNum++;

            this.AddInstrumentToTable(ins);
        }
    }

    if (!skip)
        this.onInstrumentListChange();

    this.promiseHandler = null;
}

InformerPanel.prototype.workWithToolBar = function (showHide)
{
    this.set({ showHide: showHide })
    this.layoutTable();
}

InformerPanel.prototype._onKeyDownProcess = function (event)
{
    var DOMElement = $(event.node.parentNode.parentNode);
    var w = DOMElement.width();
    var h = DOMElement.height();
    var pos = DOMElement.position();
    var posX = w / 2 + pos.left - TerceraQuickInstrumentLookupScreen.WIDTH / 2;
    posX = posX < 5 ? 5 : posX;
    var posY = h / 2 + pos.top - TerceraQuickInstrumentLookupScreen.HEIGHT / 2;
    this.quickInstrumentSelector = TerceraQuickInstrumentLookupScreen.Show(posX, posY, event, this.instrumentsDropDownCallback.bind(this), this.onAdd.bind(this), this);

    TerceraQuickInstrumentLookupScreen.WIDTH;
    TerceraQuickInstrumentLookupScreen.HEIGHT;

};

InformerPanel.prototype.getITChartAdvancedLink = function ()
{
    let tool = DataCache.ExternalLinksCache.ITChartAdvancedExternalTool

    if (!tool)
        return null

    let ins = this.getInstrument()

    if (!ins)
        return null

    let insTradableID = ins.InstrumentTradableID,
        acc = DataCache.getUserByLogin(DataCache.UserLogin).Accounts[0],
        userID = acc.ExtendedFields && acc.ExtendedFields.ITChartAdvanced ? acc.ExtendedFields.ITChartAdvanced : acc.userID,
        locale = 'en_GB',
        MD5Key = Md5.hashStr(tool.privateKey + userID + locale + insTradableID),
        link = tool.url + '?uid=' + userID + '&key=' + MD5Key + '&locale=' + locale + '&id=' + insTradableID + '&gmt=' + DateTimeConvertor.getClientTimeZoneOffset() + '&theme=night'

    return link
}

InformerPanel.prototype.SetColumnsDefaultDisplayIndex = function (table)
{
    table.columns[13].displayedIndex = 1;
}
InformerPanel.prototype.SetColumnsColouringMode = function (table)
{
    this.ProcessSetColumnsColouringMode(table, [3], ColouringModes.Previous);
    table.columnsIndexWithColoringByPrevValue = [3];
    this.ProcessSetColumnsColouringMode(table, [12, 13], ColouringModes.Signed);
}
//​​Symbol+
//Change+
//Change%+
//Last +