// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type TradingSession } from '../../../Utils/Session/Sessions';
import { TvUtcTimeZone } from '../TradingViewPrimitives/TvConstants';

export class TvTimeZoneHelper {
    public static getTimeZoneForTV (sessionPeriod: TradingSession): string {
        const UTC = TvUtcTimeZone;
        let result = sessionPeriod?.TimeZoneID ?? UTC;

        if (result === 'GMT') { result = UTC; }

        if (result === 'Europe/Kiev') { return 'Europe/Kyiv'; }

        return result;
    }
}
