// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { AccountType } from '../../Utils/Account/AccountType';
import { MathUtils } from '../../Utils/MathUtils';
import { DataCache } from '../DataCache';

export class AccountOperationInfo {
    public RequestId: any;
    public AccountId: any;
    public CounterId: any;
    public AssetId: any;
    public Comment: any;

    public LastUpdateTime: any;
    public OperationType: any;
    public Mode: any;
    public Amount: any;
    public Сounter_Amount: number;
    public CrossPrice: any;

    public UpdateByMessage (accOperMess): void {
        if (accOperMess == null) {
            return;
        }

        this.RequestId = accOperMess.RequestId;
        this.AccountId = accOperMess.AccountId;
        if (accOperMess.CounterId != null) {
            this.CounterId = accOperMess.CounterId;
        } else {
            this.CounterId = '';
        }

        this.LastUpdateTime = accOperMess.LastUpdateTime;
        this.OperationType = accOperMess.OperationType;
        this.Mode = accOperMess.Mode;
        this.Amount = accOperMess.Amount;
        if (accOperMess.CrossPrice !== null) {
            this.CrossPrice = accOperMess.CrossPrice;
        }

        this.Comment = accOperMess.Comment;
        this.AssetId = accOperMess.AssetId;

        const account = DataCache.GetAccountByNumber(this.CounterId);
        const asset = account == null || account.AccountType == AccountType.MultiAsset || account.assetBalanceDefault == null ? DataCache.GetAssetById(this.AssetId) : account.assetBalanceDefault.Asset;

        if (this.CrossPrice != 0) {
            this.Сounter_Amount = Math.abs(this.Amount * this.CrossPrice);
        } else {
            this.Сounter_Amount = Math.abs(this.Amount);
        }

        if (asset != null) // TODO
        {
            this.Сounter_Amount = MathUtils.Round(this.Сounter_Amount, asset.Point);
        }
    }

    public static TYPE_WITHDRAW = 3;
    public static TYPE_BLOCKING = 8;
    public static TYPE_TRANSFER = 17;

    public static MODE_PENDING = 0;
    public static MODE_APPROVE = 1;
    public static MODE_CANCEL = 2;
}
