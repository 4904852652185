// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
export enum LineStyle {
    SimpleChart = 0,
    DotChart = 1,
    DotLineChart = 2,
    IsoDotChart = 3,
    ShapedChart = 4,
    ShapedDotChart = 5,
    ShapedDoubleDotChart = 6,
    HistogrammChart = 7,
    ArrowChart = 8,
    HorizontalLine = 9,
    Ladder = 10
}
