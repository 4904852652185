// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { Resources } from '../../Commons/properties/Resources';
import { LayersEnum, TerceraChartBaseRenderer } from './TerceraChartBaseRenderer';
import { SolidBrush } from '../../Commons/Graphics';
import { TerceraChartAction, TerceraChartActionEnum, TerceraChartToolbarsEnum } from '../TerceraChartAction';
import { ThemeManager } from '../../Controls/misc/ThemeManager';
import { DynProperty } from '../../Commons/DynProperty';
import { type TerceraChart } from '../TerceraChart';

export class TerceraChartTimeToNextBarRenderer extends TerceraChartBaseRenderer<TerceraChart> {
    TimeToNextBarFont: any;
    TimeToNextBarBrush: SolidBrush;
    refreshTimerID: NodeJS.Timeout | null;

    constructor (chart: TerceraChart) {
        super(chart);

        this.TimeToNextBarFont = null;
        this.TimeToNextBarBrush = new SolidBrush(ThemeManager.CurrentTheme.Chart_TimeToNextBar);
        this.assignLayer = LayersEnum.Quotes;

        this.refreshTimerID = setInterval(() => {
            chart.IsDirty(LayersEnum.Quotes);
        }, 666);
        this.SetClassName('TerceraChartTimeToNextBarRenderer');
    }

    Draw (gr: any, window: any, windowsContainer: any, advParams: any) {
        if (!this.Visible) return;

        const p = advParams;
        if (!p?.mainPriceRenderer) return;

        const cashItemSeries = p.mainPriceRenderer.Series;
        if (!cashItemSeries) return;

        const timeOfEndBar = cashItemSeries.CashItem.GetTimeToNext();
        if (!timeOfEndBar) return;

        const r = window.ClientRectangle;
        const rRight = r.X + r.Width;
        const font = this.TimeToNextBarFont;

        const widthOfText = gr.GetTextWidth(timeOfEndBar, font);
        const scX = window.XScale;
        const curX = rRight - scX / 2;
        const position = curX + widthOfText / 2 > rRight
            ? rRight - widthOfText
            : curX;

        gr.DrawString(
            timeOfEndBar,
            font,
            this.TimeToNextBarBrush,
            position,
            r.Y + r.Height - font.Height,
            'center',
            'bottom'
        );
    }

    ThemeChanged () {
        super.ThemeChanged();

        this.TimeToNextBarFont = ThemeManager.Fonts.Font_12F_bold.copy();
        this.TimeToNextBarBrush = new SolidBrush(ThemeManager.CurrentTheme.Chart_TimeToNextBar);
    }

    Dispose () {
        if (this.refreshTimerID) { clearInterval(this.refreshTimerID); }

        this.chart = null;

        super.Dispose();
    }

    Properties () {
        const properties: DynProperty[] = [];

        const state = this.chart.TerceraChartActionProcessor.GetTerceraChartActionState(
            TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.TimeToNextBar)
        );

        if (!state.Visible) return properties;

        const SeparatorGroup = '#2#' + Resources.getResource('property.SeparatorGroup.TimeToNextBar');

        let prop = new DynProperty('ShowTimeToNextBar', this.Visible, DynProperty.BOOLEAN, DynProperty.VISUAL_GROUP);
        prop.separatorGroup = SeparatorGroup;
        prop.assignedProperty = ['TimeToNextBarColor', 'TimeToNextBarFont'];
        prop.sortIndex = 0;
        properties.push(prop);

        /* if (this.TimeToNextBarFont) {
                prop = new DynProperty("TimeToNextBarFont", this.TimeToNextBarFont.copy(), DynProperty.FONT, DynProperty.VISUAL_GROUP);
                prop.separatorGroup = SeparatorGroup;
                prop.sortIndex = 1;
                prop.enabled = this.Visible;
                properties.push(prop);
            } */

        if (this.TimeToNextBarBrush) {
            prop = new DynProperty('TimeToNextBarColor', this.TimeToNextBarBrush.Color, DynProperty.COLOR, DynProperty.VISUAL_GROUP);
            prop.separatorGroup = SeparatorGroup;
            prop.sortIndex = 2;
            prop.enabled = this.Visible;
            properties.push(prop);
        }

        return properties;
    }

    callBack (properties: DynProperty[]) {
        const dp = DynProperty.getPropertyByName(properties, 'ShowTimeToNextBar');
        if (dp) this.Visible = dp.value;

        const dpFont = DynProperty.getPropertyByName(properties, 'TimeToNextBarFont');
        if (dpFont?.value) this.TimeToNextBarFont = dpFont.value;

        if (this.TimeToNextBarBrush) {
            const dpColor = DynProperty.getPropertyByName(properties, 'TimeToNextBarColor');
            if (dpColor) this.TimeToNextBarBrush.Color = dpColor.value;
        }
    }
}
