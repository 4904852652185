// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type HistoryMergerInputParams } from '../../../../Utils/History/HistoryMergerInputParams';
import { type DateTime } from 'luxon';
import { HistoryMergerBasedByMin } from './HistoryMergerBasedByMin';
import { Interval } from '../../../../Utils/History/Interval';

export class HistoryMergerIntradayByDayStart extends HistoryMergerBasedByMin {
    constructor (inputParams: HistoryMergerInputParams) {
        super(inputParams);
    }

    protected override GetStartPoint (date: Date): Interval {
        const sessionOffset = this.defaultPeriod.TimeOffset;
        // аггрегация от начала дня, минус оффсет сессии
        const sessionStart: DateTime = this.defaultPeriod.ConvertTimeUtcToSessionDateTime(date).startOf('day');
        const startSessionDate = sessionStart.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        const sessionStartDateWithOffcet = startSessionDate.plus({ minutes: sessionOffset });
        const maxEnd = sessionStartDateWithOffcet.plus({ hours: 24 }); // интрадей агрегация не должна попадать в следующий день

        const interval: Interval = new Interval();
        interval.From = sessionStartDateWithOffcet.toJSDate();
        interval.To = maxEnd.toJSDate();
        return interval;
    }
}
