import { type VerticalPanelDataProvider } from '../../Controls/cache/VerticalPanel/VerticalPanelDataProvider';
import { CustomEvent } from '../../Utils/CustomEvents';
import { type Account } from '../cache/Account';

class _AccountWidgetAccountDetailsPanelController {
    public AccountDetailsPanel = null;
    public HideAccountDetailsPanel: CustomEvent = new CustomEvent();

    public HasPanel (): boolean {
        return !isNullOrUndefined(this.AccountDetailsPanel);
    }

    public doHideAccountDetailsPanel (): void {
        this.HideAccountDetailsPanel.Raise();
    }

    getDataProvider (): VerticalPanelDataProvider {
        return this.AccountDetailsPanel.VerticalPanelDataProvider;
    }

    public accountLinkOut (newSubscriber: boolean, account?: Account, sameAccs?: boolean): void {
        this.onAccountLinkOut.Raise(newSubscriber, account, sameAccs);
    }

    public onAccountLinkOut: CustomEvent = new CustomEvent();
    public onRePopulatedPanel: CustomEvent = new CustomEvent();
    public onAssetSet: CustomEvent = new CustomEvent();

    public onTableMouseUP: (hittestInfo) => void;
    public onTableMouseDown: (hittestInfo) => void;
    public onTableMouseMove: (hittestInfo) => void;
    public onPanelOpen: () => void = () => {};
    public onPanelClose: () => void = () => {};

    public raiseonRePopulatedPanel (): void {
        this.onRePopulatedPanel.Raise();
    }

    public raiseonAssetSet (id: number): void {
        this.onRePopulatedPanel.Raise(id);
    }
}

export const AccountWidgetAccountDetailsPanelController = new _AccountWidgetAccountDetailsPanelController();
