// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { OETIFSelectorTemplate } from "../../../templates.js";
import { ContainerControl } from "../../elements/ContainerControl.js";
import { Resources } from "../../../Commons/properties/Resources.ts";
import { TIF } from "../../../Utils/Trading/OrderTif.ts";
import { OrderTif, OrderTifMap } from "../../../Utils/Trading/OrderTifEnum.ts";
import { DataCache } from "../../../Commons/DataCache.ts";
import { SessionSettings } from "../../../Commons/SessionSettings.ts";
import { OrderExecutorUtils } from "../../../Commons/Trading/OrderExecutorUtils.ts";

// TODO. Move logic to TerceraTIFComboBox?
// TODO. Fix last selected + default TIF.
export let OETIFSelector = ContainerControl.extend({
    template: OETIFSelectorTemplate,
    data: function ()
    {
        return {
            label: '',

            orderType: null,
            instrument: null,
            items: null,

            orderTypeId: null,

            gtdDate: null,
            selectedItem: null,

            tif: null,

            // TODO. Remove. Ugly.
            defaultTif: null,

            showLabel: true,

            width: 198
        }
    }
})

OETIFSelector.prototype.getType = function () { return 'OETIFSelector' };

OETIFSelector.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.call(this)
    this.observe('orderType instrument defaultTif', this.repopulate)
    this.observe('selectedItem gtdDate', this.updateTIF)
    Resources.onLocaleChange.Subscribe(this.localize, this)
    this.localize()
}

OETIFSelector.prototype.dispose = function ()
{
    ContainerControl.prototype.dispose.call(this)
    Resources.onLocaleChange.Subscribe(this.localize, this)
}

OETIFSelector.prototype.localize = function ()
{
    this.set('label', Resources.getResource('panel.newOrderEntry.tifPanel'))

    let items = this.get('items')
    let changes = {}

    for (let i = 0, len = items ? items.length : 0; i < len; i++)
    {
        changes['items.' + i + '.text'] =
            Resources.getResource(OrderTifMap[items[i].value])
    }

    this.set(changes)
}

OETIFSelector.prototype.updateTIF = function ()
{
    OETIFSelector.GetAdvancedUpdateTIF(this);
}

OETIFSelector.prototype.repopulate = function ()
{
    let orderType = this.get('orderType')
    let orderTypeId = orderType ? orderType.id() : null

    OETIFSelector.GetAdvancedComboBoxItems([orderTypeId], this)
}

OETIFSelector.GetAdvancedComboBoxItems = function (orderTypesArray, panel)
{
    let instrument = panel.get('instrumentItem') || panel.get('instrument')
    let orderTypeId = orderTypesArray[0];

    panel.set('orderTypeId', orderTypeId)

    let items = null
    if (orderTypeId && instrument)
    {
        let nums = [];
        for (let i = 0, len = orderTypesArray.length; i < len; i++)
            nums = nums.concat(OrderExecutorUtils.getAllowedTIFArray(orderTypesArray[i], instrument));

        let object = {};

        for (let i = 0; i < nums.length; i++)
            object[nums[i]] = nums[i]

        // TODO. Sorting of allowedTIFArr.
        // ...
        items = TIF.createVariantsForTifs(Object.keys(object))
    }

    panel.set('items', items)

    // TODO. Remove. Ugly.
    let defaultTif = SessionSettings.getDefaultTifForOrderType(orderTypeId);
    if (!defaultTif || !items)
        return

    let orderTif = panel.get('defaultTif');
    if (orderTif)
        defaultTif = orderTif.type;

    for (let i = 0, len = items.length; i < len; i++)
    {
        let item = items[i]
        if (item.value === defaultTif)
        {
            panel.set('selectedItem', item)
            let expTime = defaultTif === OrderTif.GTD ?
                ((orderTif ? orderTif.expirationTime : null) || TIF.getDefaultExpireTimeForOrderType(orderTypeId, instrument)) : new Date()
            if (expTime)
                panel.set('gtdDate', expTime)

            return
        }
    }
};

OETIFSelector.GetAdvancedUpdateTIF = function (panel)
{
    let result = null

    let selectedItem = panel.get('selectedItem')
    if (selectedItem)
    {
        let tif = selectedItem.value
        let isGTD = tif === OrderTif.GTD

        result = new TIF(tif, isGTD ? panel.get('gtdDate') : null)
    }

    panel.set('tif', result)
};