// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

// Котировка считается Invalid, если у нее Bid == double.Nan или Ask == double.NaN
// Enum используется для того чтобы мы понимали, какую котировку необходимо брать в настоящий момент
// Last - берем любую последнюю котировку, неважно, валидная она или нет
// Valid - берем последнюю валиднуюю котировку (Bid > 0 и Ask > 0 )
// Invalid - последняя невалидная котировка (Bid == double.NaN или Ask == double.NaN)
export enum QuoteValid {
    Last = 0,
    Valid = 1,
    Invalid = 2,
    ValidForMarket = 3
}
