// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

export enum InstrumentTypes {
    // внутренние типы
    ALL = -100,
    INS_HISTORY = -99,
    NONE = -1,
    GENERAL = 0,
    // эти типы инструментов совпадают с серверными
    FOREX = 1,
    EQUITIES = 2,
    FUTURES = 3,
    OPTIONS = 4,
    EQUITIES_CFD = 5,
    FORWARD = 6,
    CFD_FUTURES = 7,
    INDICIES = 8,
    PORTFOLIO = 9,
    CRYPTO = 10,
    SPREADBET = 11,
    BOND = 12,
    ETF = 13, // Exchange traded fund
    TBILL = 14,
    SPOT = 15,
    CORPORATE = 16
};

// The 'returned' string is the ID of the associated insType image in the sprite
export const InstrumentTypesImageFileNameMap: { [key in InstrumentTypes]: string } = {
    [InstrumentTypes.ALL]: '',
    [InstrumentTypes.INS_HISTORY]: '',
    [InstrumentTypes.GENERAL]: '',
    [InstrumentTypes.NONE]: '',
    [InstrumentTypes.FOREX]: 'FOREX',
    [InstrumentTypes.EQUITIES]: 'EQUITIES',
    [InstrumentTypes.FUTURES]: 'FUTURES',
    [InstrumentTypes.OPTIONS]: 'OPTIONS',
    [InstrumentTypes.EQUITIES_CFD]: 'CFD',
    [InstrumentTypes.FORWARD]: 'FORWARD',
    [InstrumentTypes.CFD_FUTURES]: 'CFD',
    [InstrumentTypes.INDICIES]: 'INDICIES',
    [InstrumentTypes.PORTFOLIO]: 'PORTFOLIO', // image is absent
    [InstrumentTypes.CRYPTO]: 'CRYPTO',
    [InstrumentTypes.SPREADBET]: 'SPREADBET',
    [InstrumentTypes.BOND]: 'BOND',
    [InstrumentTypes.ETF]: 'ETF',
    [InstrumentTypes.TBILL]: 'TBILL',
    [InstrumentTypes.SPOT]: 'SPOT',
    [InstrumentTypes.CORPORATE]: 'CORPORATE'
};

export const InstrumentTypesColor: { [key in InstrumentTypes]: string } =
{
    [InstrumentTypes.ALL]: '',
    [InstrumentTypes.INS_HISTORY]: '',
    [InstrumentTypes.GENERAL]: '',
    [InstrumentTypes.NONE]: '',
    [InstrumentTypes.FOREX]: '#329AE1',
    [InstrumentTypes.EQUITIES]: '#FF6767',
    [InstrumentTypes.FUTURES]: '#FFA531',
    [InstrumentTypes.OPTIONS]: '#FFFFFF',
    [InstrumentTypes.CFD_FUTURES]: '#DA80FF',
    [InstrumentTypes.EQUITIES_CFD]: '#DA80FF',
    [InstrumentTypes.INDICIES]: '#FFFD3B',
    [InstrumentTypes.PORTFOLIO]: 'FFFD3B',
    [InstrumentTypes.CRYPTO]: '#55EFDB',
    [InstrumentTypes.SPREADBET]: '#A8DF00',
    [InstrumentTypes.BOND]: '#3DA646',
    [InstrumentTypes.ETF]: '#96D3FF',
    [InstrumentTypes.TBILL]: '#D0D0D0',
    [InstrumentTypes.SPOT]: '#26A69A',
    [InstrumentTypes.FORWARD]: '#0ED92B',
    [InstrumentTypes.CORPORATE]: '#5677FC'
};

export const InstrumentTypesShortName: { [key in InstrumentTypes]: string } =
{
    [InstrumentTypes.ALL]: 'ALL',
    [InstrumentTypes.INS_HISTORY]: 'CUS',
    [InstrumentTypes.GENERAL]: 'GEN',
    [InstrumentTypes.NONE]: '',
    [InstrumentTypes.FOREX]: 'FX',
    [InstrumentTypes.EQUITIES]: 'EQ',
    [InstrumentTypes.FUTURES]: 'FUT',
    [InstrumentTypes.OPTIONS]: 'OPT',
    [InstrumentTypes.CFD_FUTURES]: 'CFD',
    [InstrumentTypes.EQUITIES_CFD]: 'CFD',
    [InstrumentTypes.INDICIES]: 'IND',
    [InstrumentTypes.PORTFOLIO]: 'SI',
    [InstrumentTypes.CRYPTO]: 'CRY',
    [InstrumentTypes.SPREADBET]: 'SB',
    [InstrumentTypes.BOND]: 'BND',
    [InstrumentTypes.ETF]: 'ETF',
    [InstrumentTypes.TBILL]: 'T-B',
    [InstrumentTypes.SPOT]: 'SPT',
    [InstrumentTypes.FORWARD]: 'FW',
    [InstrumentTypes.CORPORATE]: 'COR'
};
