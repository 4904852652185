// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PriceType } from "../../../../../Utils/History/CashItemUtils.ts";
import { iBuildInIndicator } from "../../iBuildInIndicator.ts";

export let iBWMFI = function ()
{
    iBuildInIndicator.call(this, 1)
}
iBWMFI.prototype = Object.create(iBuildInIndicator.prototype)

Object.defineProperty(iBWMFI.prototype, 'Name',
    {
        get: function () { return 'iBWMFI' }
    })

iBWMFI.prototype.OnQuote = function (ci, callBound, callFromRefresh)
{
    let Parent = this.Parent
    if (!Parent)
        return

    if (Parent != null)
    {
        let volume = Parent.GetVolume(0);
        if (volume != 0)
            this.SetValue((this.GetPrice(PriceType.High, 0) - this.GetPrice(PriceType.Low, 0)) / (volume * this.FParent.Instrument.PointSize));
    }
}