// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../Commons/properties/Resources.ts';
import { Rectangle } from '../../Commons/Geometry.ts';
import { HistoryType } from '../../Utils/History/HistoryType.ts';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager.ts';
import { AlertUtils } from '../../Utils/Alert/AlertUtils.ts';
import { AlertAction } from '../../Utils/Alert/AlertConstants.ts';
import { AlertSendToServerItem } from '../../Commons/cache/Alert.ts';
import { ControlsUtils } from '../UtilsClasses/ControlsUtils.ts';
import { CreateAlertTemplate } from '../../templates.js';
import { TerceraMenu } from '../elements/TerceraMenu.ts';
import { PanelNames } from '../UtilsClasses/FactoryConstants.ts';
import { AdvancedOrderEntry } from './AdvancedOrderEntry.js';
import { ApplicationPanelNew } from './ApplicationPanelNew.js';
import { PlacedFrom } from '../../Utils/Trading/PlacedFrom.ts';
import { AlertType } from '../../Utils/Alert/AlertConstants.ts';
import { AlertImportance } from '../../Utils/Alert/AlertConstants.ts';
import { Quantity } from '../../Utils/Trading/Quantity.ts';
import { OrderEditUpdateData } from '../../Utils/Trading/OrderEditUpdateData.ts';
import { DataCache } from '../../Commons/DataCache.ts';
import { PriceLimitValidation } from '../../Utils/Instruments/PriceLimitValidation.ts';

export let CreateAlertPanel = ApplicationPanelNew.extend({
    data: function ()
    {
        return {
            width: 335,
            showFooter: false,
            resizable: false,
            dockablePanel: false,
            showHeader: true,
            account: null,
            instrument: null,
            isUpdate: false,
            movable: false,
            closeBtnVisible: false,
            showOrderParametrs: false,
            visibleInfoBtn: false,
            style_addition_header: 'js-PropertySetupScreen-AdditionalHeader',
            style_addition_jsWin: 'create-alert-additional-jsWin',
            OrderEditForPlace: null,
            enabledCreateAlert: true,
            infoBtnTooltip: '',
            createBtnTooltip: '',
            expirationTime: new Date(),
            isAlertActionSelectorVisible: true,
            isSymbolInfoOpened: false,
            canFilterByAccount: false,
            canLinkByAccount: false
        }
    },
    partials: {
        bodyPartial: CreateAlertTemplate
    },
    headerLocaleKey: 'screen.Alerts.Header.Create',
    myAlert: null,
    NeedCalculateRowCount: false,
    skipOnSave: true,
    CallerPanel: null,
    MyTimeLookupOpened: false,
    afterSetFocus: null,
    OnCloseHandler: null
})

CreateAlertPanel.prototype.getType = function () { return PanelNames.CreateAlertPanel }

CreateAlertPanel.prototype.oninit = function ()
{
    ApplicationPanelNew.prototype.oninit.call(this)

    this.on('btnCreateOrApplyClick', this.onCreateOrApplyClick);
    this.on('btnCancelClick', this.onCancelClick);
    this.on('showOrderEntryEvent', this.showOrderEntry);
    this.on('timeLookupOpened', function () { this.MyTimeLookupOpened = true; })
    this.on('timeLookupClosed', function () { this.MyTimeLookupOpened = false; })
    this.observe('alertActionValue', this.showOrderParametrs);
    this.observe('alertImportanceValue', this.setButtonStyle);
    this.observe('OrderEditForPlace', this.orderParamAndText);
    this.observe('messageText', this.ValidationMessageText);
    this.observe('alertTypeValue', this.ValidateBTN);
    this.observe('expirationTime', this.ValidateBTN);
    this.observe('account', this.onAccountChanged);
    this.observe('instrument', this.onInstrumentChanged, { init: false });
    this.observe('isSymbolInfoOpened', (opened) => { if (!opened) this.setFocus() }, { init: false });
    this.getAlertType();
}

CreateAlertPanel.prototype.oncomplete = function ()
{
    ApplicationPanelNew.prototype.oncomplete.apply(this)

    this.setDefaultProperties();

    if (this.myAlert != null)
    { // to fix replacing acc by linking
        this.set('account', this.myAlert.Account);
    }
}

CreateAlertPanel.prototype.onInstrumentChanged = function (n, o)
{
    this.NeedQuote = false;
    if (o)
        this.unsubscribeQuotes(o)

    if (this.QuoteSkip)
        return;

    if (n)
        this.subscribeQuotes(n)
    this.NeedQuote = true;
    this.ValidateBTN();
}

CreateAlertPanel.prototype.onAccountChanged = function (account)
{
    if (!account)
        return

    this.updateValue();
    this.accountLink_Out(false, account);
}

CreateAlertPanel.prototype.setDefaultProperties = function ()
{
    if (this.myAlert)
        return;

    let props = this.CallerPanel.getProperties()
    this.set('alertNotifyValue', props.NotificationVariable)
    this.set('alertTypeValue', props.AlertTypeVariable)
    this.set('alertConditionValue', props.ConditionVariable)
    this.set('alertImportanceValue', props.ImportanceVariable)
    this.set('alertExecuteValue', props.AfterExecuteVariable)
}

CreateAlertPanel.prototype.showOrderParametrs = function (newValue)
{
    if (newValue === AlertAction.PLACE_ORDER_NOTIFY)
    {
        this.set({ showOrderParametrs: true, visibleInfoBtn: true });
        if (this.get('OrderEditForPlace'))
            this.set('enabled', true)
    }
    else
        this.set({ showOrderParametrs: false, visibleInfoBtn: false });
    this.ValidateBTN();
}

CreateAlertPanel.prototype.ValidationMessageText = function (newValue)
{
    // let tooltip = Resources.getResource('screen.Alerts.Button.Create.Tooltip')
    // if (!newValue.length)
    //     this.set({ enabledCreateAlert: false, createBtnTooltip: tooltip })
    // else
    //     this.set({ enabledCreateAlert: true, createBtnTooltip: '' })
    this.ValidateBTN();
}

CreateAlertPanel.prototype.orderParamAndText = function (newValue)
{
    if (newValue)
    {
        this.CorrectingPieceOfShit(newValue);
        let text = newValue.getConfirmationText()
        //remove fucking ' ?'
        text = text.replace(' ?', '')
        this.set({ infoBtnTooltip: text })
    }
    else
        this.set({ infoBtnTooltip: '' })
    this.ValidateBTN();
}

CreateAlertPanel.prototype.ValidateBTN = function ()
{
    let btnEnabled = this.getBtnEnability()
    this.set({
        enabledCreateAlert: btnEnabled,
        createBtnTooltip: this.getBtnToolTip()
    })

    return btnEnabled;
}

CreateAlertPanel.prototype.getBtnEnability = function ()
{
    let dataOE = this.get('OrderEditForPlace');
    let dataText = this.get('messageText');
    let alertType = this.get('alertTypeValue');
    let instrument = this.get('instrument');
    let expirationTime = this.get('expirationTime');

    if (this.get('alertActionValue') === AlertAction.PLACE_ORDER_NOTIFY)
        if (!dataOE || dataText.isEmpty())
            return false;

    if (dataText.isEmpty())
        return false;

    if (AlertType.VOLUME === alertType && instrument && instrument.isForexSymbol())
        return false;

    if (expirationTime <= new Date())
        return false;

    return true;
}

CreateAlertPanel.prototype.getBtnToolTip = function ()
{
    let dataOE = this.get('OrderEditForPlace');
    let dataText = this.get('messageText');
    let alertType = this.get('alertTypeValue');
    let instrument = this.get('instrument');
    let expirationTime = this.get('expirationTime');


    if (this.get('alertActionValue') === AlertAction.PLACE_ORDER_NOTIFY && !dataOE)
        return Resources.getResource('alert.specifyOrder.Error');

    if (dataText.isEmpty())
        return Resources.getResource('screen.Alerts.Button.Create.Tooltip');

    if (AlertType.VOLUME === alertType && instrument && instrument.isForexSymbol())
        return Resources.getResource('alert.specifyAlertType.Error');

    if (expirationTime <= new Date())
        return Resources.getResource('alert.specifyExpirationTime.Error');

    return ''
}

CreateAlertPanel.prototype.showOrderEntry = function ()
{
    let me = this;
    if (me.isOE_Opened)
        return
    let myData = this.get('OrderEditForPlace')
    let myOrderEntryPanel = MainWindowManager.Factory.addPanel(PanelNames.AdvancedOrderEntry, null, function (panel)
    {
        let location = ControlsUtils.getAbsoluteLocation(me.Controls.CreateAlertOrderParametrsSelector);
        panel.observe('height', function ()
        {
            let h = this.find('*').offsetHeight;
            var newLoc = TerceraMenu.CorrectPopupLocation(
                new Rectangle(location.X + 181, location.Y + 30, panel.get('width'), h));
            panel.setLocation(newLoc.newX, newLoc.newY);
        })
        panel.set({
            accountVisible: false,
            canLinkByAccount: false,
            isCreateOrderParmeters: true,
            placeButtonLocalizationKey: 'panel.newOrderEntry.AddOrder',
            placeButtonAdditionalClass: 'alertStyle',
            movable: false,
            showCustomFullscreenCloud: true,
            zIndex: 290
        })
        if (!myData) 
        {
            panel.set({ instrument: me.get('instrument') })
            return
        }
        let data = me.PieceOfShit(myData);
        me.CorrectingPieceOfShit(data);
        panel.set({ instrument: data.instrument }).then(function ()
        {

            let orderTypeDict = DataCache.OrderParameterContainer.OrderTypes
            let orderTypeObj = orderTypeDict[data.getOrderTypeId()]

            // panel.setOrderEdit(data)
            panel.selectOrderType(data.getOrderTypeId())
            panel.setOrderEdit(data)

            let leverage = data.leverageValue
            panel.set({
                side: data.side,
                defaultTif: data.tif,
                productType: data.productType,
                // defaultQuantity: new Quantity(data.quantity.value, data.quantity.inLots)
            }).then(() => { panel.set('leverageValue', leverage) }) // product type needs to be set first 

            let OEQty = panel.Controls ? panel.Controls.OEQuantity : null
            if (OEQty)
                OEQty.set('defaultQuantity', new Quantity(data.quantity.value, data.quantity.inLots))
        })
    });
    myOrderEntryPanel.skipOnSave = true;

    me.isOE_Opened = true;
    myOrderEntryPanel.close = function ()
    {
        // FOCUS MAGIC
        AdvancedOrderEntry.prototype.close.call(this);
        me.isOE_Opened = false;
        me.setFocus();
    }

    myOrderEntryPanel.OnComplete.Subscribe(this.onOrderEntryComplete.bind(this, myOrderEntryPanel), this);

    myOrderEntryPanel.NewParametrsHandler = function (orderEdit)
    {
        // параметры ОЕ дальше сетим где нужно
        // нам нужен для всех параметров orderEdit
        // я его сюда докинул, из него текст заебашить в i и данные для сохранения, а также этот обьект хранить в алерте OrderEditForPlace 
        // там его серелиализуем для хранения на сервере и в обраточку заебашим для плейса при загрузке. изи катка)
        // из него будем ОЕ заполнять при переоткрытии как в assets panel

        // потом просто вызываешь плейс и всё
        // DataCache.FOrderExecutor.placeOrderPromise(orderEdit)
        if (PriceLimitValidation.SendRejectIfNotValidateLimitsByOrderEdit(orderEdit))  // #112368
            return

        let myEdit = me.PieceOfShit(orderEdit);
        me.set({ OrderEditForPlace: myEdit })
    }
}

CreateAlertPanel.prototype.setFocus = function ()
{
    ApplicationPanelNew.prototype.setFocus.call(this)
    if (this.afterSetFocus)
        this.afterSetFocus();
}

CreateAlertPanel.prototype.onOrderEntryComplete = function (oe)
{
    oe.OnComplete.UnSubscribe(this.onOrderEntryComplete, this);

    const acc = this.get('account');
    if (oe && acc)
    {
        oe.set('account', acc);
    }

}

// CreateAlertPanel.prototype.onOrderEntryClose = function (oe)
// {
//     oe.OnClose.UnSubscribe(this.onOrderEntryClose, this);
//     this.isOE_Opened = false;
//     this.setFocus();
// }

//GetCopy
CreateAlertPanel.prototype.PieceOfShit = function (orderEdit)
{
    let newEdit = DataCache.OrderParameterContainer.OrderTypes[orderEdit.getOrderTypeId()].createOrderEditObject({
        dataCache: DataCache
    })

    //TODO КОСТЫЛИЩЕ!!!! ЕБАНОЕ        
    let edit = new OrderEditUpdateData(null, orderEdit.getTradingData())
    newEdit.placedFrom = PlacedFrom.WEB_ALERT;
    newEdit.updateParameters(edit);

    let copiedSLTP = JSON.parse(JSON.stringify(orderEdit.sltp))
    newEdit.sltp.sl = copiedSLTP.sl
    newEdit.sltp.tp = copiedSLTP.tp
    newEdit.sltp.trailingStop = copiedSLTP.trailingStop

    newEdit.limitPrice = orderEdit.limitPrice;
    newEdit.stopPrice = orderEdit.stopPrice;
    newEdit.trailingStop = orderEdit.trailingStop;
    newEdit.AlerEdit = true;

    return newEdit;
}

//Корректирует значения слтп
CreateAlertPanel.prototype.CorrectingPieceOfShit = function (orderEdit)
{
    let hold = orderEdit.getRawSLTP()
    orderEdit.sltp.updateOffsetTypes()
    orderEdit.sltp.updateAllowedRules(orderEdit.instrument, orderEdit.account)
    orderEdit.setSLTP(hold)
}

CreateAlertPanel.prototype.dispose = function ()
{
    ApplicationPanelNew.prototype.dispose.call(this)
}

CreateAlertPanel.prototype.setButtonStyle = function ()
{
    let importance = this.get('alertImportanceValue')

    if (importance === AlertImportance.HIGH)
        this.set('advancedButtonStyle', 'js-button-create-alert-red')
    if (importance === AlertImportance.MEDIUM)
        this.set('advancedButtonStyle', 'js-button-create-alert-yellow')
    if (importance === AlertImportance.LOW)
        this.set('advancedButtonStyle', 'js-button-create-alert-green')
}

CreateAlertPanel.prototype.getAlertType = function ()
{
    this.set({ alertTypeValue: AlertType.BID })
}

CreateAlertPanel.prototype.onCancelClick = function ()
{
    this.close();
}

CreateAlertPanel.prototype.close = function ()
{
    const insDDF = MainWindowManager.TerceraInstrumentLookupDropDownForm;
    const accDDF = MainWindowManager.TerceraAccountLookupDropDownForm;
    if ((insDDF && insDDF.get('visible')) ||
        (accDDF && accDDF.get('visible')))  // #119762
        return;

    // if (this.CallerPanel)
    //     this.CallerPanel.set('visible', true).
    //         then(function ()
    //         {
    //             this.CallerPanel.setFocus();
    //         }.bind(this));

    ApplicationPanelNew.prototype.close.call(this)
    if (this.OnCloseHandler)
        this.OnCloseHandler();
}

CreateAlertPanel.prototype.lostFocus = function ()
{
    ApplicationPanelNew.prototype.lostFocus.apply(this)
    // FOCUS MAGIC
    if (this.checkPossibilityClosing())
        this.close();
}

CreateAlertPanel.prototype.checkPossibilityClosing = function ()
{
    return !this.isOE_Opened && !this.MyTimeLookupOpened && !this.get('isSymbolInfoOpened');
}

CreateAlertPanel.prototype.onCreateOrApplyClick = function ()
{
    let enabled = this.ValidateBTN();
    if (!enabled)
        return

    let paramDataToSend = new AlertSendToServerItem();
    paramDataToSend.Account = this.get('account');
    paramDataToSend.Instrument = this.get('instrument');
    paramDataToSend.AlertType = this.get('alertTypeValue');
    paramDataToSend.Condition = this.get('alertConditionValue');
    paramDataToSend.Value = this.get('alertValue');
    paramDataToSend.Importance = this.get('alertImportanceValue');
    paramDataToSend.AfterExecute = this.get('alertExecuteValue');
    paramDataToSend.Action = this.get('alertActionValue');
    paramDataToSend.Notification = this.get('alertNotifyValue');
    paramDataToSend.Expiration = this.get('expirationTime');
    paramDataToSend.MessageText = this.get('messageText');
    paramDataToSend.MessageText = paramDataToSend.MessageText.trim()
    paramDataToSend.OrderPlaceParametrs = null;
    paramDataToSend.Active = true;

    let okClick = function ()
    {
        if (this.myAlert)
            paramDataToSend.AlertId = this.myAlert.AlertId;
        DataCache.AlertManager.SendAlertToServer(paramDataToSend)
        this.close()
    }.bind(this);

    let cancelClick = function ()
    {
        this.isOE_Opened = false;
        this.setFocus();
    }.bind(this)
    if (paramDataToSend.Action === AlertAction.PLACE_ORDER_NOTIFY)
    {
        let OrderPlaceParametrs = this.get('OrderEditForPlace');
        paramDataToSend.OrderPlaceParametrs = OrderPlaceParametrs.getDataForRequest();
        this.isOE_Opened = true;
        okClick();
    }
    else
        okClick();
}

CreateAlertPanel.prototype.setUpdateParams = function (alert)
{
    this.QuoteSkip = true;
    this.set('account', alert.Account).then(() =>
    {
        this.set('instrument', alert.Instrument).then(() =>
        {
            this.set('alertNotifyValue', alert.Notification)
            this.set('alertTypeValue', alert.AlertType)
            this.set('alertConditionValue', alert.Condition)
            this.set('alertValue', alert.Value)
            this.set('alertImportanceValue', alert.Importance)
            this.set('alertExecuteValue', alert.AfterExecute)

            let action = alert.Action
            let allowPlace = AlertUtils.AllowPlaceOrder();
            if (action === AlertAction.PLACE_ORDER_NOTIFY && !allowPlace)
                action = AlertAction.NOTIFY;

            this.set('alertActionValue', action)
            this.set('messageText', alert.MessageText)
            this.set('expirationTime', alert.Expiration)

            this.set('isUpdate', true);
            this.set('header', Resources.getResource('screen.Alerts.Header.Edit'));
            this.QuoteSkip = false;
            if (allowPlace)
                this.set({ OrderEditForPlace: alert.OrderPlaceParametrs })
        })
    })

    this.myAlert = alert;
}

CreateAlertPanel.prototype.localize = function ()
{
    ApplicationPanelNew.prototype.localize.apply(this);

    this.set('buttonCreateText', Resources.getResource('screen.Alerts.Button.Create'))
    this.set('buttonApplyText', Resources.getResource('screen.PanelSettingsScreen.applyButton'))
    this.set('buttonCancelText', Resources.getResource('general.messageBox.cancel'))
};

CreateAlertPanel.prototype.subscribeQuotes = function (instrument)
{
    if (!instrument)
        return

    this.newQuote(instrument.LastQuote)
    DataCache.FQuoteCache.addListener(instrument, this, HistoryType.QUOTE_LEVEL1)
    DataCache.FQuoteCache.addListener(instrument, this, HistoryType.QUOTE_TRADES)
}

CreateAlertPanel.prototype.unsubscribeQuotes = function (instrument)
{
    if (!instrument)
        return

    DataCache.FQuoteCache.removeListener(instrument, this, HistoryType.QUOTE_LEVEL1)
    DataCache.FQuoteCache.removeListener(instrument, this, HistoryType.QUOTE_TRADES)
}

CreateAlertPanel.prototype.newQuote = function (quote)
{
    if (quote && quote.Type === HistoryType.QUOTE_INSTRUMENT_DAY_BAR)
        return;
    if (this.NeedQuote)
    {
        const instrument = this.get('instrument');
        const value = this.getAlertValue();

        this.NeedQuote = false;
        this.unsubscribeQuotes(instrument);
        if (!isNaN(value) && value !== null)
            this.set({ alertValue: value });
    }
}

CreateAlertPanel.prototype.updateValue = function ()
{
    const val = this.getAlertValue();
    if (val != null) { this.set('alertValue', val); }
}

CreateAlertPanel.prototype.getAlertValue = function ()
{
    const instrument = this.get('instrument');
    const account = this.get('account');

    if (instrument == null || account == null) { return null; }

    const Level1 = instrument.Level1;
    const alertTypeValue = this.get('alertTypeValue');
    let value = 0;
    switch (alertTypeValue)
    {
        case AlertType.BID:
            value = Level1.GetBid(account);
            break;
        case AlertType.ASK:
            value = Level1.GetAsk(account);
            break;
        case AlertType.LAST:
            value = Level1.GetLastPrice(account);
            break;
        case AlertType.CHANGE:
            value = Level1.GetChangePercent(account);
            break;
        case AlertType.VOLUME:
            value = Level1.GetVolume();
            break;
    }
    return value;
}

CreateAlertPanel.prototype.accountLink_In = function () { }
CreateAlertPanel.prototype.accountLink_Out = function () { }