// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { CustomInstrumentListTypeEnum } from '../CustomInstrumentList/CustomInstrumentListTypeEnum';
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectCustomListMessage extends DirectMessageBaseClass {
    public Id: number = -1;
    public Name: string = '';
    public Description: string = '';
    public ListType: CustomInstrumentListTypeEnum = CustomInstrumentListTypeEnum.None;
    public RawBytes: number[] | null = null; // image
    public TradableInstrumentId: number[] | null = null; // список инструментов передается в таком же порядке как они были добавлены в БО.
    public Aliases: any = null; // 63  - languageAlias LANG = 642 Name = 22 DESCRIPTION = 28

    constructor () {
        super(Message.CUSTOM_LIST_MESSAGE);
    }
}
