// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { OEAccountLookupTemplate } from "../../../templates.js";
import { ContainerControl } from "../../elements/ContainerControl.js";
import { Resources } from "../../../Commons/properties/Resources.ts";
import { ControlsTypes } from "../../UtilsClasses/FactoryConstants.ts";
import { AccountMenuItemsHelper } from "../../../Commons/AccountWidget/AccountMenuItemsHelper.ts";

export let OEAccountLookup = ContainerControl.extend({
    template: OEAccountLookupTemplate,
    data: function ()
    {
        return {
            label: '',
            name: ControlsTypes.OEAccountLookup,
            account: null
        }
    }
})

OEAccountLookup.prototype.getType = function () { return ControlsTypes.OEAccountLookup; };

OEAccountLookup.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.call(this)
    Resources.onLocaleChange.Subscribe(this.localize, this)
    this.localize()
}

OEAccountLookup.prototype.dispose = function ()
{
    Resources.onLocaleChange.UnSubscribe(this.localize, this)

    ContainerControl.prototype.dispose.call(this)
}

OEAccountLookup.prototype.setFocus = function ()
{
    const enabled = this.get('enabled');
    if (!enabled)
    {
        return;
    }
    ContainerControl.prototype.setFocus.call(this)
    if (this.Controls.TerceraAccountLookup)
        this.Controls.TerceraAccountLookup.setFocus()
}

OEAccountLookup.prototype.localize = function ()
{
    this.set('label', Resources.getResource('panel.newOrderEntry.accountLabel'))
}