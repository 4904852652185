// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { MODE_UPPER, MODE_LOWER } from "../../IndicatorConstants.ts";
import { ExpandDoubleVector } from "../../DoubleMatrix.ts";
import { IndicatorFunctions } from "../../IndicatorFunctions.ts";
import { iBuildInIndicator } from "../../iBuildInIndicator.ts";

export let iEnvelopes = function (maPeriod, maMethod, ma_shift, deviation, priceType)
{
    iBuildInIndicator.call(this, 3);
    this.FPriceType = priceType;
    this.FMaPeriod = maPeriod;
    this.FMaMethod = maMethod;
    this.FDeviation = deviation;
    this.ma = new ExpandDoubleVector();
    this.FData.FArray[0].FShift = ma_shift;
    this.FData.FArray[1].FShift = ma_shift;
    this.FData.FArray[2].FShift = ma_shift;
}

iEnvelopes.prototype = Object.create(iBuildInIndicator.prototype)

Object.defineProperty(iEnvelopes.prototype, 'Name',
    {
        get: function () { return 'iEnvelopes' }
    })

Object.defineProperty(iEnvelopes.prototype, 'Key',
    {
        get: function () { return this.DefaultKey + this.MaPeriod + this.MaMethod + this.Deviation + this.FPriceType; }
    })

iEnvelopes.prototype.NextBar = function (callBound)
{
    iBuildInIndicator.prototype.NextBar.call(this, callBound)
    this.ma.Add(0.0);
}
iEnvelopes.prototype.Refresh = function (count, newThread)
{
    this.ma.Dispose()
    this.ma = new ExpandDoubleVector();
    iBuildInIndicator.prototype.Refresh.call(this, count, newThread)
}
iEnvelopes.prototype.OnQuote = function (ci, callBound, callFromRefresh)
{
    let price = this.GetPrice(this.FPriceType, 0);
    this.ma[this.ma.Length - 1] = price;
    let Parent = this.Parent
    if (!Parent || this.FCount < this.FMaPeriod || this.FMaPeriod == 0)
        return;

    let maValue = IndicatorFunctions.CallMovingFunction(this.FMaMethod, this.ma, this.FMaPeriod, 1);

    // Setting value
    this.SetValue(MODE_UPPER, 0, (1.0 + this.FDeviation * 0.01) * maValue);
    this.SetValue(MODE_LOWER, 0, (1.0 - this.FDeviation * 0.01) * maValue);
    this.SetValue(2, 0, maValue);
}