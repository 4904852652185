// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { AlertCondition, AlertAfterExecute, AlertImportance, AlertNotification, AlertType } from '../Alert/AlertConstants';

export class AlertSett {
    public NotificationVariable = AlertNotification.SHOW_POP_UP + AlertNotification.SOUND;
    public AlertTypeVariable = AlertType.LAST;
    public ConditionVariable = AlertCondition.MORE;
    public ImportanceVariable = AlertImportance.MEDIUM;
    public AfterExecuteVariable = AlertAfterExecute.REMOVE;
}
