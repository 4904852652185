import { type Account } from '../../Commons/cache/Account';
import { type Instrument } from '../../Commons/cache/Instrument';
import { type ProductType } from '../Instruments/ProductType';

// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
export class OrderEditBaseUtils {
    public static LIMIT_PRICE_PARAM = 'limitPrice';
    public static STOP_PRICE_PARAM = 'stopPrice';
    public static SLTP_PARAM = 'sltp';
    public static TRAILING_STOP_PARAM = 'trailingStop';
    public static POSITION_SIZING_PARAM = 'positionSizing';
    public static REAL_TRSTOP_PRICE = 'RealTrStopPrice';
    public static PRODUCT_TYPE_AND_MQ = 'ModifyQuantity'; // Target product type + Modify quantity
    public static ATTENTION_MSG = 'attention';
    public static LIMIT_PRICE_PARAM_CHANGE_FROM_LAST_PRICE = 'limitPriceChangeFromLast'; // https://tp.traderevolution.com/entity/108864
    public static SLTP_TRIGGER = 'sltpTrigger'; // for position with sltp & triggers modify #109386
};

export class OrderEditRequestData {
    public orderTypeId: any;
    public instrument: Instrument | null = null;
    public account: Account | null = null;
    public quote: any = null;
    public side: any = null;
    public tif: any = null;
    public quantity: any = null;
    // TODO. Rename.
    public parameterDict: any = {};
    public placedFrom: any = null;
    public disclosedQuantity: any = null;
    public productType: ProductType | null = null;
    public leverageValue: any = null;
    public comment: any = null;
    public order: any = null;
    public useStopLimitInsteadStop: any = null;
    public limitPriceForStopLimitOrder: any = null;
    public ocoCustomOrdersData: any = null;

    // Тільки в PositionEdit використовує:
    public position: any = null;
    public canEditSLOrTrailingStop: any = null;
    public canEditTP: any = null;
    public isSLchanged: any = null;
    public isTPchanged: any = null;
    public UseSkipNoChange: any = null;
}
