// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { ChartMath } from '../Utils/ChartMath';
import { DataCacheToolRayType } from '../../Commons/cache/DataCacheToolEnums';
import { ToolView } from './ToolView';
import { ToolViewUtils } from './Utils/ToolViewUtils';
import { type FibonacciPhiChannelDataCacheTool } from '../../Commons/cache/Tools/FibonacciPhiChannelDataCacheTool';

export class FibonacciPhiChannelToolView extends ToolView<FibonacciPhiChannelDataCacheTool> {
    public override IsSelectCheck (x: number, y: number): boolean {
        const screenPoints = this.screenPoints;
        const screenPoints_len = screenPoints.length;

        const TOOL_DX = ToolView.TOOL_DX;

        for (let i = 0; i < screenPoints_len; i++) {
            const secIdx = i + 1 < screenPoints_len ? i + 1 : 0;
            if (ChartMath.CalcDistanceFromPointToSection(screenPoints[i], screenPoints[secIdx], x, y) <= TOOL_DX) {
                return true;
            }
        }

        return super.IsSelectCheck(x, y);
    }

    public DrawFibPhiChannel (gr, ww, highlight): void {
        const dataCacheTool = this.dataCacheTool;
        const fibLevel = dataCacheTool.fibLevel;
        const pen = highlight ? dataCacheTool.PenHighlight : dataCacheTool.Pen;
        const penRay = dataCacheTool.PenRay;
        const brush = highlight ? dataCacheTool.FontBrushHighlight : dataCacheTool.FontBrush;
        const font = dataCacheTool.font;
        const ray = dataCacheTool.RayType;

        const instrument = dataCacheTool.Instrument;

        const screenPoints = this.screenPoints;

        const p0 = screenPoints[0];
        const x0 = p0[0];
        const y0 = p0[1];

        const p1 = screenPoints[1];
        const x1 = p1[0];
        const y1 = p1[1];

        const p2 = screenPoints[2];
        const x2 = p2[0];
        const y2 = p2[1];

        const alfa = Math.acos(Math.abs((x1 - x0) / Math.sqrt(Math.pow(x1 - x0, 2) + Math.pow(y1 - y0, 2)))) * 180 / Math.PI * (y0 < y1 ? 1 : -1);

        ToolView.DrawLineWithRay(gr, ww, x0, y0, x1, y1, ray, pen, penRay);
        ToolView.DrawLineWithRay(gr, ww, x0, y0, x2, y2, DataCacheToolRayType.NoRay, pen);

        const r20 = Math.sqrt((y2 - y0) * (y2 - y0) + (x2 - x0) * (x2 - x0));
        const r21 = Math.sqrt((y2 - y1) * (y2 - y1) + (x2 - x1) * (x2 - x1));
        const r = Math.sqrt((y0 - y1) * (y0 - y1) + (x0 - x1) * (x0 - x1));
        let a = (r * r + r20 * r20 - r21 * r21) / (2 * r);
        let b = r - a;
        const xc = ((a * x1 + b * x0) / (a + b));
        const yc = ((a * y1 + b * y0) / (a + b));
        const xcc = x0 - (xc - x2);
        const ycc = y0 - (yc - y2);

        if (Math.abs(xc - x2) > 0.000001 || Math.abs(yc - y2) > 0.000001) {
        // #30348
            const rr = Math.sqrt((xcc - x0) * (xcc - x0) + (ycc - y0) * (ycc - y0));
            const fibLevel_len = fibLevel.length;
            for (let i = 0; i < fibLevel_len; i++) {
                const fibLvl = fibLevel[i];

                a = rr * fibLvl;
                b = rr - a;

                let xk0 = 0;
                let yk0 = 0;
                if (a + b) {
                    xk0 = ((a * xcc + b * x0) / (a + b));
                    yk0 = ((a * ycc + b * y0) / (a + b));
                }

                const xk1 = xk0 + (x1 - x0);

                const yk1 = (x1 - x0)
                    ? yk0 + (xk1 - xk0) * (y1 - y0) / (x1 - x0)
                    : 0;

                const dy = y0 - ycc;
                const dx = x0 - xcc;

                ToolView.DrawLineWithRay(gr, ww, (xk0 - dx), (yk0 - dy), (xk1 - dx), (yk1 - dy), ray, pen, penRay);

                const f_txt = ToolViewUtils.FormatFibonacciText(fibLvl, dataCacheTool.Points, instrument) + '% ';

                const textX = xk1 - dx;
                const textY = yk1 - dy;

                ToolView.DrawTextByAngle(gr, f_txt, font, brush,
                    textX,
                    textY,
                    textX,
                    textY,
                    alfa);
            }
        }
    }

    public override Draw (gr, ww, param): void {
        this.DrawFibPhiChannel(gr, ww, false);
        super.Draw(gr, ww, param);
    }

    public override DrawSelection (gr, ww): void {
        const screenPoints = this.screenPoints;

        const scrP0 = screenPoints[0];
        const scrP0X = scrP0[0];
        const scrP0Y = scrP0[1];

        const scrP1 = screenPoints[1];
        const scrP1X = scrP1[0];
        const scrP1Y = scrP1[1];

        const scrP2 = screenPoints[2];
        const scrP2X = scrP2[0];
        const scrP2Y = scrP2[1];

        this.DrawFibPhiChannel(gr, ww, true);

        this.DrawSelectedLine(gr, ww, scrP0X, scrP0Y, scrP1X, scrP1Y);
        this.DrawSelectedLine(gr, ww, scrP0X, scrP0Y, scrP2X, scrP2Y);

        this.DrawSelectedPoint(gr, ww, scrP0X, scrP0Y);
        this.DrawSelectedPoint(gr, ww, scrP1X, scrP1Y);
        this.DrawSelectedPoint(gr, ww, scrP2X, scrP2Y);
    }
}
