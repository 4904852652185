// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { type TerceraChartCashItemSeries } from '../../Chart/Series/TerceraChartCashItemSeries';

export interface IVolumeBarsMovingAverage {
    setMovingAveragePeriod: (period: number) => void
    calculateMovingAverage: (cashItemSeries: TerceraChartCashItemSeries, signal: AbortSignal | null) => Promise<MovingAverageMapType>
}

export type MovingAverageMapType = Map<number, number> | null;

export const MovingAverageMap = (): MovingAverageMapType => new Map<number, number>();
