import { OperationType } from '../../../../../Utils/Trading/OperationType';
import { OrderType } from '../../../../../Utils/Trading/OrderType';
import { TradingUtils } from '../../../../../Utils/Trading/TradingUtils';
import { UUID } from '../../../../../Utils/UUID';
import { type Account } from '../../../Account';
import { type Instrument } from '../../../Instrument';
import { PaperPosition } from './PaperPosition';

export class PaperPositionsCache {
    private readonly _paperPositionsByAccountAndUnderlier = new Map<string, Map<string, PaperPosition>>();
    public getPaperPositions (account: Account, underlier: Instrument): PaperPosition[] {
        if (isNullOrUndefined(account) || isNullOrUndefined(underlier)) {
            return [];
        }
        const key = this.getKey(account, underlier);
        const paperPositionsByUnderlierId = this._paperPositionsByAccountAndUnderlier.get(key);
        if (isNullOrUndefined(paperPositionsByUnderlierId)) {
            return [];
        }
        return Array.from(paperPositionsByUnderlierId.values());
    }

    public getPaperPosition (account: Account, underlier: Instrument, option: Instrument): PaperPosition {
        if (isNullOrUndefined(account) || isNullOrUndefined(underlier) || isNullOrUndefined(option)) {
            return undefined;
        }
        const key = this.getKey(account, underlier);
        const paperPositionsByUnderlierId = this._paperPositionsByAccountAndUnderlier.get(key);
        if (!isNullOrUndefined(paperPositionsByUnderlierId)) {
            return paperPositionsByUnderlierId.get(option.GetInteriorID());
        }
        return undefined;
    }

    public createPaperPosition (account: Account, underlier: Instrument, option: Instrument): PaperPosition {
        if (isNullOrUndefined(account) || isNullOrUndefined(underlier) || isNullOrUndefined(option)) {
            return undefined;
        }
        const key = this.getKey(account, underlier);
        const optionId = option.GetInteriorID();
        let paperPositionsByUnderlierId = this._paperPositionsByAccountAndUnderlier.get(key);
        if (isNullOrUndefined(paperPositionsByUnderlierId)) {
            paperPositionsByUnderlierId = new Map<string, PaperPosition>();
            this._paperPositionsByAccountAndUnderlier.set(key, paperPositionsByUnderlierId);
        }
        const paperPosition = this.initializePaperPosition(account, option);
        paperPositionsByUnderlierId.set(optionId, paperPosition);
        return paperPosition;
    }

    public removePaperPosition (account: Account, underlier: Instrument, option: Instrument): PaperPosition {
        if (isNullOrUndefined(account) || isNullOrUndefined(underlier) || isNullOrUndefined(option)) {
            return undefined;
        }
        const key = this.getKey(account, underlier);
        const optionId = option.GetInteriorID();
        const paperPositionsByUnderlierId = this._paperPositionsByAccountAndUnderlier.get(key);
        if (isNullOrUndefined(paperPositionsByUnderlierId)) {
            return undefined;
        }
        const paperPosition = paperPositionsByUnderlierId.get(optionId);
        paperPositionsByUnderlierId.delete(optionId);
        if (paperPositionsByUnderlierId.size === 0) {
            this._paperPositionsByAccountAndUnderlier.delete(key);
        }
        return paperPosition;
    }

    public clearPaperPositions (account: Account, underlier: Instrument): void {
        if (isNullOrUndefined(account) || isNullOrUndefined(underlier)) {
            return;
        }

        const key = this.getKey(account, underlier);
        const paperPositionsByUnderlierId = this._paperPositionsByAccountAndUnderlier.get(key);
        if (isNullOrUndefined(paperPositionsByUnderlierId)) {
            return;
        }
        paperPositionsByUnderlierId.clear();
        this._paperPositionsByAccountAndUnderlier.delete(key);
    }

    public changeInstrumentForPaperPosition (account: Account, underlier: Instrument, oldValue: Instrument, newValue: Instrument): PaperPosition {
        if (isNullOrUndefined(account) || isNullOrUndefined(underlier) || isNullOrUndefined(oldValue) || isNullOrUndefined(newValue)) {
            return undefined;
        }
        const paperPosition = this.removePaperPosition(account, underlier, oldValue);
        if (isNullOrUndefined(paperPosition)) {
            return undefined;
        }
        paperPosition.Instrument = newValue;
        const key = this.getKey(account, underlier);
        const optionId = paperPosition.Instrument.GetInteriorID();
        let paperPositionsByUnderlierId = this._paperPositionsByAccountAndUnderlier.get(key);
        if (isNullOrUndefined(paperPositionsByUnderlierId)) {
            paperPositionsByUnderlierId = new Map<string, PaperPosition>();
            this._paperPositionsByAccountAndUnderlier.set(key, paperPositionsByUnderlierId);
        }
        paperPositionsByUnderlierId.set(optionId, paperPosition);
        return paperPosition;
    }

    private getKey (account: Account, underlier: Instrument): string {
        return `${account.userPin}:${underlier.GetInteriorID()}`;
    }

    private initializePaperPosition (account: Account, option: Instrument): PaperPosition {
        const paperPosition = new PaperPosition(UUID.generateUUIDv5());
        paperPosition.Analyse = true;
        paperPosition.Active = true;
        paperPosition.Account = account;
        paperPosition.Instrument = option;
        paperPosition.Operation = OperationType.Buy;
        const orderTypes = TradingUtils.getAllowedOrderTypesForTrading(account, option);
        if (orderTypes.Contains(OrderType.Limit)) {
            paperPosition.OrderType = OrderType.Limit;
        } else {
            paperPosition.OrderType = orderTypes[0];
        }
        paperPosition.TIF = TradingUtils.getAllowedTifsForTrading(option, paperPosition.OrderType)[0];
        paperPosition.ProductType = TradingUtils.getAllowedProductTypesForTrading(account, option)[0];
        return paperPosition;
    }
}
