// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { type SessionSettingsType } from '../../../Commons/SessionSettings';
import { type Instrument } from '../../../Commons/cache/Instrument';
import { GeneralSettings } from '../../../Utils/GeneralSettings/GeneralSettings';
import { DateTimeConvertor } from '../../../Utils/Time/DateTimeConvertor';
import { DateTimeUtils } from '../../../Utils/Time/DateTimeUtils';
import { BaseItem, ColumnData } from '../../cache/BaseItem';
import { ColumnParams } from '../../cache/ColumnParams';
import { QuickTableColumnType } from '../../elements/QuickTable/QuickTableMisc';

export class MultiTimeSalesItem extends BaseItem {
    public instrument: Instrument | null = null;
    public price: any = null;
    public time: any = null;
    public size: any = null;

    constructor (sessionSettings: SessionSettingsType) {
        super(sessionSettings);
        this.itemId = null;
    }

    public ColumnCount (): number {
        return MultiTimeSalesItem.columnsParams.length;
    }

    public getColumnData (column: number): ColumnData {
        const val = this.getColumnValue(column);
        let fVal = null;
        const ins = this.instrument;

        switch (column) {
        case 1:
            fVal = DateTimeUtils.formatDate(val, 'HH:mm:ss');
            break;
        case 2:
            fVal = ins?.formatPrice(val);
            break;
        case 3:
            fVal = ins?.DataCache.formatVolume(ins, val, GeneralSettings.View.displayAmountInLots());
            break;
        default:
            fVal = val === null || val === undefined ? '' : val.toString();
        }

        return new ColumnData(val, fVal);
    }

    public getColumnValue (column: number): any {
        const ins = this.instrument;

        switch (column) {
        case 0:
            return ins.DisplayName();
        case 1:
            return DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(this.time);
        case 2:
            return this.price;
        case 3:
            return this.size;
        default:
            return '';
        }
    }

    public GetColumnParams (column: number): ColumnParams {
        return MultiTimeSalesItem.columnsParams[column];
    }

    public static readonly columnsParams = [
    /* 0 */new ColumnParams('panel.timeSales.Instrument', 70, 0, false, true, false),
        /* 1 */new ColumnParams('panel.timeSales.Time', 70, QuickTableColumnType.COL_DATE_SORT, false, true, false),
        /* 2 */new ColumnParams('panel.timeSales.Price', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false),
        /* 3 */new ColumnParams('panel.timeSales.Size', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false)
    ];
}
