// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PFixField } from './PFixField';

export class PFixByteArrayField extends PFixField {
    constructor (fieldId, buffer?) {
        super(fieldId, buffer);
        this.Value = [];
    }

    public override ReadValue (buffer): void {
        const len = buffer.ReadInt32();
        this.Value = buffer.ReadBuffer(len).ReadAllBytes();
    }

    public override Write (buffer): void {
        buffer.WriteShort(this.FieldId);
        const len = this.Value.length;
        buffer.WriteInt32(len);
        buffer.WriteAllBytes(this.Value);
    }
}
