// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { AlertUtils } from "../../../Utils/Alert/AlertUtils.ts";
import { CreateAlertImportanceSelectorTemplate } from "../../../templates.js";
import { ContainerControl } from "../../elements/ContainerControl.js";
import { Resources } from "../../../Commons/properties/Resources.ts";

export let CreateAlertImportanceSelector = ContainerControl.extend({
    template: CreateAlertImportanceSelectorTemplate,
    data: function ()
    {
        return {
            label: '',
            instrument: null,

            items: null,
            selectedItem: null,
            defaultValue: null,

            width: 198
        }
    }
})

CreateAlertImportanceSelector.prototype.getType = function () { return 'CreateAlertImportanceSelector' };

CreateAlertImportanceSelector.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.call(this)

    this.repopulate();
    this.observe('selectedItem', this.updateAlertImportance);
    this.observe('value', this.updateAlertImportanceValue);

    Resources.onLocaleChange.Subscribe(this.localize, this);
    this.localize();
}

CreateAlertImportanceSelector.prototype.updateAlertImportance = function (selectedItem)
{
    if (selectedItem)
        this.set({ value: selectedItem.value })
}

CreateAlertImportanceSelector.prototype.updateAlertImportanceValue = function (newV, oldV)
{
    let items = this.get("items")
    if (!items)
        return;

    for (let i = 0; i < items.length; i++)
        if (items[i].value === newV)
        {
            this.set({ selectedItem: items[i] })
            break;
        }
}

CreateAlertImportanceSelector.prototype.dispose = function ()
{
    Resources.onLocaleChange.UnSubscribe(this.localize, this)

    ContainerControl.prototype.dispose.call(this)
}

CreateAlertImportanceSelector.prototype.repopulate = function ()
{
    let item = AlertUtils.GetAlertImportanceItems();
    this.set({ items: item, selectedItem: item[1] });   //1 = AlertImportance.MEDIUM
}

CreateAlertImportanceSelector.prototype.localize = function ()
{
    this.set('label', Resources.getResource('screen.Alerts.Label.Importance'))
}