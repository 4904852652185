import { GeneralSettings } from '../../Utils/GeneralSettings/GeneralSettings';
import { DataCache } from '../DataCache';
import { type PositionInfo } from './PositionInfo';
import { PositionInfoType } from './PositionInfoType';

export interface IPositionInfoBackColorClasses {
    None: string
    Red: string
    Blue: string
    Purple: string
    Green: string
}

export interface IPositionInfoItem {
    BackColorClass: string
    Label: string
}

export class PositionInfoItem implements IPositionInfoItem {
    public static readonly NA = 'N/A';
    public static readonly EMPTY_STR = '---';

    public Type: PositionInfoType;
    public positionInfo: PositionInfo;
    public BackColorClass: string;
    public Label: string;

    private readonly backColorClasses: IPositionInfoBackColorClasses;

    constructor (type: PositionInfoType, calculator: PositionInfo, backColorClasses: IPositionInfoBackColorClasses) {
        this.Type = type;

        this.positionInfo = calculator;

        this.backColorClasses = backColorClasses;
    }

    public Update (): void {
        const newValue = this.getValue();
        this.UpdateLabel(newValue);
        this.UpdateBackColor(newValue);
    }

    public getValue (): number {
        switch (this.Type) {
        case PositionInfoType.PLByCurrency:
            return this.positionInfo.pLByCurrency;
        case PositionInfoType.AverageOpenPrice:
            return this.positionInfo.averageOpenPrice;
        case PositionInfoType.BreakEven:
            return this.positionInfo.breakEven;
        case PositionInfoType.NetQTY:
            return this.positionInfo.netQty;
        case PositionInfoType.LongQty:
            return this.positionInfo.longQTY;
        case PositionInfoType.ShortQty:
            return this.positionInfo.shortQTY;
        default:
            return 0;
        }
    }

    public UpdateLabel (val: number): void {
        if (!isValidNumber(val)) {
            if (isValidNumber(this.positionInfo.netQty) && this.Type !== PositionInfoType.BreakEven) {
                this.Label = PositionInfoItem.NA;
            } else {
                this.Label = PositionInfoItem.EMPTY_STR;
            }
            return;
        }

        if (isNullOrUndefined(this.positionInfo.instrument) || isNullOrUndefined(this.positionInfo.account)) {
            this.Label = val.toString();
            return;
        }

        switch (this.Type) {
        case PositionInfoType.PLByCurrency:
            this.Label = this.positionInfo.account.formatPrice(val);
            break;
        case PositionInfoType.AverageOpenPrice:
            this.Label = this.positionInfo.instrument.formatPricePrecision(val);
            break;
        case PositionInfoType.BreakEven:
            if (val >= 0) {
                this.Label = this.positionInfo.instrument.formatPricePrecision(val);
            } else {
                this.Label = PositionInfoItem.EMPTY_STR;
            }
            break;
        case PositionInfoType.LongQty:
        case PositionInfoType.ShortQty:
        case PositionInfoType.NetQTY:
        {
            let valueStr: string;
            if (val === 0) {
                valueStr = PositionInfoItem.EMPTY_STR;
            } else {
                const ShowLots = GeneralSettings.View.displayAmountInLots();
                val = ShowLots
                    ? val
                    : val * this.positionInfo.instrument.LotSize;

                valueStr = DataCache.formatVolume(this.positionInfo.instrument, val, ShowLots);
            }

            this.Label = valueStr;
            break;
        }
        default:
            this.Label = PositionInfoItem.EMPTY_STR;
        }
    }

    public UpdateBackColor (value: number): void {
        switch (this.Type) {
        case PositionInfoType.PLByCurrency:
            if (value > 0) {
                this.BackColorClass = this.backColorClasses.Green;
            } else if (value < 0) {
                this.BackColorClass = this.backColorClasses.Red;
            } else {
                this.BackColorClass = this.backColorClasses.None;
            }
            break;
        case PositionInfoType.BreakEven:
            if (this.positionInfo.longQTY > 0 && this.positionInfo.shortQTY > 0) {
                this.BackColorClass = this.backColorClasses.Purple;
            } else if (this.positionInfo.longQTY === 0 && this.positionInfo.shortQTY > 0) {
                this.BackColorClass = this.backColorClasses.Red;
            } else if (this.positionInfo.longQTY > 0 && this.positionInfo.shortQTY === 0) {
                this.BackColorClass = this.backColorClasses.Blue;
            } else {
                this.BackColorClass = this.backColorClasses.None;
            }
            break;
        case PositionInfoType.NetQTY:
            if (value > 0) {
                this.BackColorClass = this.backColorClasses.Blue;
            } else if (value < 0) {
                this.BackColorClass = this.backColorClasses.Red;
            } else {
                this.BackColorClass = this.backColorClasses.None;
            }
            break;
        case PositionInfoType.AverageOpenPrice:
            if (this.positionInfo.allInLong) {
                this.BackColorClass = this.backColorClasses.Blue;
            } else if (this.positionInfo.allInShort) {
                this.BackColorClass = this.backColorClasses.Red;
            } else {
                this.BackColorClass = this.backColorClasses.None;
            }
            break;

        case PositionInfoType.LongQty:
            this.BackColorClass = this.backColorClasses.Blue;
            break;
        case PositionInfoType.ShortQty:
            this.BackColorClass = this.backColorClasses.Red;
            break;
        default:
            this.BackColorClass = this.backColorClasses.None;
            break;
        }
    }
}
