// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
export class CustodialData {
    public InstrumentGroupId: number | null = null;
    public InstrumentId = '';
    public Fee = 0;
    public Measure = 0;
    // this.FormattedValue = ""

    public FormattedValue (): string {
        return this.Fee + ' ' + CustodialMeasure.descriotionMap[this.Measure];
    }
}

export class CustodialMeasure {
    public static descriotionMap = { 0: 'bp' };

    public BasisPoint: 0; // bp
}
