// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
// привел константы к серверному виду, чтобы не делать лишнюю конвертацию

import { CreateMapByObj } from '../CreateMapByObjectUtils';

export enum OrderType {
    Market = 1,
    Stop = 2,
    Limit = 3,
    StopLimit = 4,
    TrailingStop = 5,

    Manual = 100, // Ручное открытие
    SLTPLimit = 101, // Защитный лимит приказ
    SLTPStop = 102, // Защитный стоп приказ
    TRStop = 105, // Защитный тс-приказ

    OCO = 250,

    PositionEdit = 1001 // specific internal type for modification calculating
}

// для мапинга ключей локализации
export const OrderTypeMap = CreateMapByObj(OrderType);

Object.freeze(OrderTypeMap);

export function getSortStr (orderType: OrderType): string {
    let textOrder = 'LMT';
    switch (orderType) {
    case OrderType.Market:
        textOrder = 'MKT';
        break;
    case OrderType.Stop:
        textOrder = 'STP';
        break;
    case OrderType.Limit:
        textOrder = 'LMT';
        break;
    case OrderType.StopLimit:
        textOrder = 'STS';
        break;
    case OrderType.TrailingStop:
        textOrder = 'Tr-s';
        break;
    }
    return textOrder;
}

export function getFullStr (orderType: OrderType): string {
    let textOrder = 'Market';
    switch (orderType) {
    case OrderType.Market:
        textOrder = 'Market';
        break;
    case OrderType.Stop:
        textOrder = 'Stop';
        break;
    case OrderType.Limit:
        textOrder = 'Limit';
        break;
    case OrderType.StopLimit:
        textOrder = 'StopLimit';
        break;
    case OrderType.TrailingStop:
        textOrder = 'TrailingStop';
        break;
    }
    return textOrder;
}

export function sortOrderTypes (orderTypes): any[] {
    const sortedOrderTypes = [...orderTypes];
    const stop = sortedOrderTypes.indexOf(OrderType.Stop);
    const limit = sortedOrderTypes.indexOf(OrderType.Limit);
    if (stop > -1 && limit > -1) {
        const temp = sortedOrderTypes[stop];
        sortedOrderTypes[stop] = sortedOrderTypes[limit];
        sortedOrderTypes[limit] = temp;
    }
    return sortedOrderTypes;
}
