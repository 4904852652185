// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from "../Utils/CustomEvents.ts";
import { ContainerControl } from "../Controls/elements/ContainerControl.js";
import { RadialDiagramTemplate } from "../templates.js";
import { DataCache } from "../Commons/DataCache.ts";
import { ControlsUtils } from "../Controls/UtilsClasses/ControlsUtils.ts";

export let RadialDiagram = ContainerControl.extend(
    {
        data: function ()
        {
            return {
                // movable: false,
                // showHeader: false,
                // showFooter: false,
                // resizable: false,
                // canSelect: false,

                width: 426,     // размеры по умолчанию ! меняются при изменении размеров контэйнера
                height: 329,
                sectorRadius: 127,
                smallCircleRadius: 97,

                data: [],    // array of objects: {value (=percent/100), text} (ВАЖНО!!!) сумма data[i].value всех элементов массива должна быть равны единице

                sectorSVGforData: [],       // сектора находящиеся под центральной меньшей окружностью 
                lineNearTextSVG: [],        // прилегающая к секторам сноска с подписью значения процента 
                sectorTextCoords: [],       // массив координат подписей секторов
                cachedSectorsOptions: [],   // кэшируем данные по которым строятся сектора, чтоб потом подравнять подписи
      
                sectorsFillOpacity: [],     // значение fill-opacity для каждого сектора в данный момент (для выделения выбранного)

                instrumentColor: [],        // = DataCache.PortfolioCache.instrumentColor
            };
        },

        hoveredSector: null,
        selectedSector: null,                 // индекс выбранного сектора (null если никакой не выбран)
        onSelectedSectorChanged: null,
        wasCorrectedSectorTextsAndLines: false,
        template: RadialDiagramTemplate
    });

RadialDiagram.prototype.getType = function () { return 'RadialDiagram' };

RadialDiagram.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.apply(this);

    this.observe('width height', this.createDataSectors)

    this.on('radialDiagramClick', this.onDiagramClick)
    this.on('onMouseMove', this.onMouseMove)

    this.observe('data', this.onDataChange);

    this.onSelectedSectorChanged = new CustomEvent()
};

RadialDiagram.prototype.oncomplete = function ()
{
    ContainerControl.prototype.oncomplete.apply(this);

    let pCache = DataCache.PortfolioCache
    this.set('instrumentColor', pCache.instrumentColor)

    this.localize()

    this.randomData()
};

RadialDiagram.prototype.onMouseMove = function (e)
{
    if (!this.wasCorrectedSectorTextsAndLines)
    {
        this.correctSectorTextsAndLines()       // только после создания элементов можем узнать точную ширину текста, правим с учетом них координаты текстов и линии
        this.wasCorrectedSectorTextsAndLines = true
    }

    
    let hoveredSector = document.querySelector('path.js-RadialDiagram-oneSector:hover'),
        hoveredSectorID = hoveredSector ? hoveredSector.id : null
    if (hoveredSectorID !== this.hoveredSector)
        this.onHoveredSectorChanged(hoveredSectorID)
}

RadialDiagram.prototype.onHoveredSectorChanged = function (newHoveredSector)
{
    let textLines = document.getElementsByClassName('js-RadialDiagram-sectorTitleTextLines'),
        texts = document.getElementsByClassName('js-RadialDiagram-sectorTitleText')

    if (this.hoveredSector !== null)
    {
        let hoveredIsSelected = this.hoveredSector == this.selectedSector,
            newStyle = 'opacity:0.25;visibility:' + (hoveredIsSelected ? 'visible' : 'hidden') + ';'

        texts[this.hoveredSector].style = textLines[this.hoveredSector].style = newStyle
    }

    if (newHoveredSector != null)
        texts[newHoveredSector].style = textLines[newHoveredSector].style = "visibility: visible; opacity: 1;"

    this.hoveredSector = newHoveredSector
}

RadialDiagram.prototype.onDiagramClick = function (event)
{
    let x = event.original.offsetX, // координаты точки клика
        y = event.original.offsetY,
        newSelectedSectorIndex = this.getSectorIndexByCoords(x, y)

    if (newSelectedSectorIndex === null)
        this.randomData()       // for test TODO delete after all

    if (this.selectedSector != newSelectedSectorIndex)
        this.selectedSectorChanged(newSelectedSectorIndex)
    else
        if (newSelectedSectorIndex >= 0)
            this.selectedSectorChanged(null)      // повторное нажатие на выбранный сектор скидывает select
}

RadialDiagram.prototype.selectedSectorChanged = function (newSelectedSectorIndex, noNeedToFireEvent)
{
    if (this.selectedSector !== null)
        this.forceHoverEnd(this.selectedSector, true)

    if (newSelectedSectorIndex !== null)
        this.forceHover(newSelectedSectorIndex)

    this.selectedSector = newSelectedSectorIndex

    let sectorsFillOpacity = this.get('sectorsFillOpacity')
    for (let i = 0; i < sectorsFillOpacity.length; i++)
        sectorsFillOpacity[i] = newSelectedSectorIndex !== null && newSelectedSectorIndex !== i ? 0.25 : 1
    this.set('sectorsFillOpacity', sectorsFillOpacity)

    if (!noNeedToFireEvent)
        this.onSelectedSectorChanged.Raise(newSelectedSectorIndex);
}

RadialDiagram.prototype.getSectorIndexByCoords = function (x, y)
{
    let dataArr = this.get('cachedSectorsOptions')
    if (!dataArr || !dataArr.length) return -1

    let sectorData = dataArr[0],
        cx = sectorData.cx,                // центральная точка выделенного под бублик места
        cy = sectorData.cy,
        dx = x - cx,
        dy = cy - y,
        d = Math.sqrt(dx*dx + dy*dy),      // гипотенуза
        R = this.get('sectorRadius'),      // big radius without shadow,
        r = this.get('smallCircleRadius')  //97  // радиус малого внутреннего круга 

    if (r <= d && d <= R)
    {
        let sinA = dx / d,
            cosA = dy / d,
            acos = Math.acos(cosA) * 180 / Math.PI,
            angleInDegree = sinA > 0 ? acos : 360 - acos

        for (let i = 0; i < dataArr.length; i++)
        {
            sectorData = dataArr[i]
            let degreeStart = sectorData.start_angle,
                degreeEnd = sectorData.end_angle

            if (degreeStart <= angleInDegree && angleInDegree <= degreeEnd)
                return i
        }
    }

    return -1
}

RadialDiagram.prototype.onDataChange = function ()
{
    // let data = this.get('data')
    // data.sort(function (a, b) { return b.value - a.value })    // сортируем по убыванию процента

    this.createDataSectors()
}

RadialDiagram.prototype.createDataSectors = function ()
{
    let width = this.get('width'),
        height = this.get('height'),
        data = this.get('data')

    if (!width || !height || !data)
        return

    this.selectedSectorChanged(-1)

    let svgPathSectors = [],        // сектора находящиеся под центральной меньшей окружностью 
        svgPathArrows = [],         // прилегающая к секторам сноска с подписью значения процента 
        sectorTextCoords = [],      // массив координат подписей секторов
        cachedSectorsOpt = [],      // кэшируем данные по которым строятся сектора, чтоб потом подравнять подписи
        sectorsFillOpacity = [],    // значение fill-opacity для каждого сектора (для выделения выбранного)
        prevStartDeg = 0

    let cx = width / 2, // центральная точка выделенного под бублик места
        cy = height / 2,
        R = RadialDiagram.getSectorRadiusByContainerCenter(cx, cy) //

    this.set({
        sectorRadius: R,
        smallCircleRadius: Math.round(R * RadialDiagram.SMALL_CIRCLE_RADIUS_COEF)
    })

    for (let i = 0; i < data.length; i++)
    {
        if (!data[i]) continue

        sectorsFillOpacity.push(1)  // по умолчанию у всех секторов прозрачность отсутствует

        let newDeg = data[i].value * 360

        if (data.length == 1)
            newDeg = 359

        let opts = {      // sector
            cx: cx,        // <-- center x
            cy: cy,        // <-- center y
            radius: R, //RadialDiagram.SECTOR_RADIUS,    // <-- circle radius
            start_angle: prevStartDeg, // <-- start angle in degrees
            end_angle: prevStartDeg + newDeg, // <-- end angle in degrees
        };

        cachedSectorsOpt.push(opts)
        svgPathSectors.push(ControlsUtils.createSVGPathSector(opts))

        let text = data[i].text

        if (text)
        {
            let raughTextWidth = RadialDiagram.getRaughTextLineWidth(text.length)
            RadialDiagram.createSVGTextAndHolders(opts, width, height, raughTextWidth, sectorTextCoords, svgPathArrows)    
        }     

        prevStartDeg += newDeg
    }

    this.wasCorrectedSectorTextsAndLines = false

    this.set({
        sectorSVGforData: svgPathSectors,
        lineNearTextSVG: svgPathArrows,
        sectorTextCoords: sectorTextCoords,
        cachedSectorsOptions: cachedSectorsOpt,
        sectorsFillOpacity: sectorsFillOpacity
    })
}

RadialDiagram.prototype.correctSectorTextsAndLines = function()    // до создания элементов не удалось узнать ширину текста, потому приходится подкорректировать расположение текста и его линий после создания svg элементов  
{
    let width = this.get('width'),
        height = this.get('height'),
        svgPathArrows = this.get('lineNearTextSVG'),
        sectorTextCoords = this.get('sectorTextCoords'),
        sectorOptions = this.get('cachedSectorsOptions'),
        newSvgPathArrows = [],
        newSectorTextCoords = []

    let texts = document.getElementsByClassName('js-RadialDiagram-sectorTitleText')
    for (let i = 0; i < texts.length; i++)
    {
        let opts = sectorOptions[i],
            textRect = texts[i].getBBox(),
            textRealWidth = textRect.width

        RadialDiagram.createSVGTextAndHolders(opts, width, height, textRealWidth, newSectorTextCoords, newSvgPathArrows)
    }

    this.set({
        lineNearTextSVG: newSvgPathArrows,
        sectorTextCoords: newSectorTextCoords
    })
}

RadialDiagram.createSVGTextAndHolders = function (opts, width, height, textWidth, sectorTextCoordsOut, svgPathArrowsOut)   // создаем svg параметры для текста и линий около него
{
    let sectorCenterDegree = RadialDiagram.getMoreSuitableAngleForText(opts)    // смещаем угол сноски с центра сектора к более выгодному концу

    let angleInRadians = sectorCenterDegree * Math.PI / 180,                // перевод с градусов в радианы
        sinSign = Math.sign(Math.sin(angleInRadians)),
        cosSign = Math.sign(Math.cos(angleInRadians))

    let centerPoint = ControlsUtils.polarToCartesian(opts.cx, opts.cy, RadialDiagram.getSectorWidthShadowRadius(opts.radius), sectorCenterDegree),
        H = RadialDiagram.NEAR_TEXT_LINES_HEIGHT * (opts.radius < 90 ? 0.25 : 1),   //уменьшаем длину коротенькой черточки к сектору если диаграмма уменьшилась до критичного размера
        secondPoint = { // second point may have offset to the top or to the bottom and to the right or to the left
            x: centerPoint.X + sinSign * H,
            y: centerPoint.Y - cosSign * H
        }

    let textCoords = RadialDiagram.getSectorTitleTextCoords(textWidth, secondPoint, sinSign, cosSign, width, height)

    sectorTextCoordsOut.push(textCoords)    // кэшируем координаты самого текста 
    svgPathArrowsOut.push(RadialDiagram.createSVGPathNearTextLines(centerPoint, secondPoint, sinSign, textWidth)) // кэшируем svg пути линий около текста подписи сектора
}


RadialDiagram.prototype.forceHover = function (index)
{
    if (this.lastForceHoveredIndex == index)
        return

    let texts = document.getElementsByClassName('js-RadialDiagram-sectorTitleText'),
        lines = document.getElementsByClassName('js-RadialDiagram-sectorTitleTextLines'),
        style = "visibility: visible; opacity: 1"

    if (texts[index])
        texts[index].style = style
    if (lines[index])
        lines[index].style = style

    this.lastForceHoveredIndex = index
}
RadialDiagram.prototype.forceHoverEnd = function (index, selectedSectorChanged)
{
    let newStyle = ''                                               // затрет перезаписанный style, по умолчанию в css задано visibility: hidden;
    if (this.selectedSector == index && !selectedSectorChanged)       
        newStyle = 'opacity:0.5; visibility: visible'               // чтобы не убиралась подпись сектора, а только затемнялась, если он выбран, а мы шурудим по таблице

    let texts = document.getElementsByClassName('js-RadialDiagram-sectorTitleText'),
        lines = document.getElementsByClassName('js-RadialDiagram-sectorTitleTextLines')

    if (texts[index])
        texts[index].style = newStyle
    if (lines[index])
        lines[index].style = newStyle

    this.lastForceHoveredIndex = null
}

RadialDiagram.SECTOR_RADIUS_COEF = 0.75;
RadialDiagram.SMALL_CIRCLE_RADIUS_COEF = 0.8;
RadialDiagram.SECTOR_WITH_SHADOW_RADIUS_COEF = 1.05;   // <- this coef * sectorRadius = <радиус сектора с тенью>
RadialDiagram.NEAR_TEXT_LINES_HEIGHT = 13;
RadialDiagram.SECTOR_TITLE_TEXT_HEIGHT = 14;
RadialDiagram.MIN_TEXT_X = 3;

RadialDiagram.getSectorRadiusByContainerCenter = function(cx, cy)
{   
    if (cx <= 0 || cy <= 0) return 0

    return Math.round(Math.min(cx, cy) * RadialDiagram.SECTOR_RADIUS_COEF)
}

RadialDiagram.getSectorWidthShadowRadius = function (sectorRadius)
{
    return Math.round(sectorRadius * RadialDiagram.SECTOR_WITH_SHADOW_RADIUS_COEF)
}

RadialDiagram.getRaughTextLineWidth = function(textLen)  
{
    let result = textLen * 10

    if (textLen < 10)
        result += (10 - textLen) * 3
    else
        result -= (textLen - 10) * 1.8

    return result
}

RadialDiagram.createSVGPathNearTextLines = function (centerPoint, secondPoint, sinSign, textWidth)
{
    let thirdPointX = secondPoint.x + sinSign * textWidth, // second point to the top or to the bottom
        thirdPointY = secondPoint.y

    if (thirdPointX < 0)
        thirdPointX = RadialDiagram.MIN_TEXT_X

    return 'M ' + centerPoint.x + ' ' + centerPoint.y + ' L ' + secondPoint.x + ' ' + secondPoint.y + ' L ' + thirdPointX + ' ' + thirdPointY
}

RadialDiagram.getSectorTitleTextCoords = function (textWidth, secondPoint, sinSign, cosSign, width, height)
{
    let h = RadialDiagram.SECTOR_TITLE_TEXT_HEIGHT,
        w = textWidth,
        textX = secondPoint.x - (sinSign > 0 ? 0 : textWidth),
        textY = secondPoint.y - cosSign * h + (cosSign > 0 ? 8 : 0)

    let xEnd = textX + textWidth

    let horizontalFit = true, verticalFit = true   // влазит ли надпись по осям
    if (textX < 0)
    {
        horizontalFit = false
        textX = RadialDiagram.MIN_TEXT_X
    }
    if (xEnd > width)
    {
        horizontalFit = false
        textX = textX - (xEnd - width)
    }

    if (textY < h * 0.35)
    {
        verticalFit = false
        textY = secondPoint.y + h * 0.65
    }
    if (textY > height)
    {
        verticalFit = false
        textY = secondPoint.y - h * 0.35
    }


    return {
        x: textX,
        y: textY,
        horizontalFit: horizontalFit,
        verticalFit: verticalFit
    }
}

RadialDiagram.getMoreSuitableAngleForText = function (opts)
{
    let startA = opts.start_angle, endA = opts.end_angle,
        sectorCenterDegree = (startA + endA) / 2       // угол в градусах являющийся биссектрисой сектора
        
    sectorCenterDegree = RadialDiagram.changeAngleIfNeed(sectorCenterDegree, startA, endA, opts)
    
    return sectorCenterDegree
} 

RadialDiagram.changeAngleIfNeed = function (sectorCenterDegree, startA, endA, opts)    
{
    let cx = opts.cx,
        cy = opts.cy,
        r = RadialDiagram.getSectorWidthShadowRadius(opts.radius),
        rightSide = sectorCenterDegree < 180,
        minAngleDeg = rightSide ? 25 : 250,
        maxAngleDeg = rightSide ? 135: 290 
        
    if (sectorCenterDegree > minAngleDeg && sectorCenterDegree < maxAngleDeg)
    {
        let newPointStart = ControlsUtils.polarToCartesian(cx, cy, r, startA),
            newPointEnd = ControlsUtils.polarToCartesian(cx, cy, r, endA),
            offset = Math.round(Math.abs(endA - startA) / 4)    // допустимое отклонение линии с текстом от центрального угла дуги (/ 2 -> только центр, /10 -> 10% дуги макс(мин))

        if (!rightSide)
        {
            let t = startA; 
            startA = endA; 
            endA = t; 
            offset *= -1;
        }

        sectorCenterDegree = newPointStart.X < newPointEnd.X ? startA + offset : endA - offset

        if (sectorCenterDegree < minAngleDeg)
            sectorCenterDegree = minAngleDeg
        if (sectorCenterDegree > maxAngleDeg)
            sectorCenterDegree = maxAngleDeg

    }

    return sectorCenterDegree
}

RadialDiagram.prototype.dispose = function ()
{


    ContainerControl.prototype.dispose.apply(this);
};

RadialDiagram.prototype.localize = function ()
{

};


// ----- RANDOMIZE (for debug) DIAGRAM DATA REGION

function randomInteger (min, max)           // получить случайное число от (min-0.5) до (max+0.5)
{
    let rand = min - 0.5 + Math.random() * (max - min + 1);
    return Math.round(rand);
}

RadialDiagram.prototype.getRandomText = function (IND)    // random text generation  for debugging
{
    let words = ['Cash', 'IBM', 'ALPHABET', 'AAPL', 'BRENT', 'AMZN',  'FB', 'GOLD', 'NFLX', 'SBUX', 'TSLA', 'XRX', 'MD', 'GM', 'TWI'],
        ind = IND === undefined ? randomInteger(0, words.length - 1) : IND
    return words[ind]


    let alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
        // len = randomInteger(4, 10),     
        len = randomInteger(25, 25), // <-bad case 
        result = ''

    for (let i = 0; i < len; i++)
    {
        let charI = randomInteger(0, alphabet.length - 1)
        result += alphabet[charI]
    }

    return result
}

RadialDiagram.prototype.randomData = function ()    // random data generation for debugging
{
    // let maxLen = this.maxLen
    // this.maxLen = maxLen > 0 ? (maxLen + 1) % 13 : 2 

    let leftPercent = 100,
        data = [],
        len = randomInteger(3, 15)   // = this.maxLen

    for (let i = 0; i < len; i++)
    {
        let max = Math.max(leftPercent - 15 * (len - i), 1),
            percent = randomInteger(8, max)
        data.push({ value: percent / 100, text: this.getRandomText(i) })
        leftPercent -= percent
    }
    if (leftPercent > 0)
        data.push({ value: leftPercent / 100, text: this.getRandomText(len) })

    // for (let i = 0; i < data.length * 22; i++) // перемешаем значения
    // {
    //     let index1 = randomInteger(0, data.length - 1),
    //         index2 = randomInteger(0, data.length - 1),
    //         temp = data[index1]

    //     data[index1] = data[index2]
    //     data[index2] = temp
    // }

    this.set('data', data)
}

// ----- RANDOMIZE DIAGRAM DATA REGION END