// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts";
import { Rectangle } from "../../Commons/Geometry.ts";
import { KeyCode } from "../../Commons/KeyEventProcessor.ts";
import { MathUtils } from "../../Utils/MathUtils.ts";
import { UpDownType } from "../../Utils/Instruments/Intervals.ts";
import { popupErrorHandler, terceraNumericHandler } from "../../Utils/AppHandlers.js";
import { TerceraNumericTemplate } from "../../templates.js";
import { Control } from "./Control.js";
import { CustomEvent } from "../../Utils/CustomEvents.ts";
import { ScrollUtils } from "../UtilsClasses/ScrollUtils.ts";
import { ControlsTypes } from "../UtilsClasses/FactoryConstants.ts";

// TODO. Refactor.
export let TerceraNumeric = Control.extend({
    template: TerceraNumericTemplate,
    data: function ()
    {
        return {
            name: 'num',
            minValue: 1,                    //Минимальное значение
            maxValue: 100000,               //Максимальное значение
            step: 1,                        //Шаг изменения

            // из-за внедрения интервалов возникла необходимость ввести коэффициент,
            // с помощью которого мы будем откладывать количество интервалов (количество тиков)
            incrementCoefficient: 1,

            decimalPrecision: 0,            //Количество знаков после decimalseparator
            value: 0,                     //реальное значение
            formattedValue: '',              //видимое значения
            defaultValue: 0.000001,
            showArrows: true,
            tradingNumeric: false,
            hasError: false,
            errorText: '',
            customError: null,
            validationStateInfo: null,
            SkipValidationForMaxValue: false,
            Intervals: [],
            zIndex: 0,
            allowMinus: true,
            valueLessMinLocalKey: 'UserControl.TerceraNumeric.ValueLessMin',
            valueGreaterMaxLocalKey: 'UserControl.TerceraNumeric.ValueGreaterMax',
            DynPropertyData: {},
            onlyMouseWheel: false,
            isPosAbsolute: false
            // showTradingText: false
        }
    },
    currentMoveType: null
});

TerceraNumeric.prototype.getType = function () { return ControlsTypes.TerceraNumeric; };

TerceraNumeric.prototype.private_arrowMousedown = function (sender, isUp)
{
    if (!this.get('enabled')) return;

    this.loopValueChange(isUp, true);
};

TerceraNumeric.prototype.private_arrowMouseup = function (sender, isUp)
{
    if (!this.get('enabled')) return;

    this.loopValueChange(isUp, false);
};

TerceraNumeric.prototype.private_arrowMouseout = function (sender, isUp)
{
    if (!this.get('enabled')) return;

    this.loopValueChange(isUp, false);
};

TerceraNumeric.prototype.onMouseDown = function (event)
{
    Control.prototype.onMouseDown.call(this, event);
    this.fire("TerceraNumericClicked")      // #86098
};
// TODO. Ugly.
TerceraNumeric.prototype.processEnterKeyDown = function ()
{
    this.updateNumeric(this.digitValue(this.get('formattedValue')));

    if (!this.get('hasError') || !this.get('tradingNumeric'))
        return;

    var minValue = this.get('minValue');
    var maxValue = this.get('maxValue');
    var value = this.get('value');

    var newValue = value;

    if (value > maxValue)
    {
        newValue = maxValue;
    }
    else if (value < minValue)
    {
        newValue = minValue;
    }
    else
    {
        // TODO. Duplicate of TryParseValue.
        var interval = this.GetInterval(newValue, true);
        var step = 0;
        var stepPrecision = 0;
        if (interval)
        {
            step = interval.Increment;
            stepPrecision = interval.DecimalPlaces;
        }
        else
        {
            step = this.get('step');
            stepPrecision = MathUtils.getPrecision(step);
        }

        if (step && !MathUtils.isValueMultipleToStep(newValue, step, stepPrecision))
        {
            newValue = parseFloat(MathUtils.RoundToIncrement(newValue, step).toFixed(stepPrecision));
            // TODO. Ugly. For a case when minValue < step.
            if (newValue < step) newValue = step;
        }
    }

    this.set('value', newValue);
};

TerceraNumeric.prototype.private_KeyDown = function (event)
{
    if (!this.get('enabled')) return;

    if (event.original.keyCode == KeyCode.UP)
        this.loopValueChange(true, true);
    if (event.original.keyCode == KeyCode.DOWN)
        this.loopValueChange(false, true);

    if (event.original.keyCode == KeyCode.ENTER)
        this.processEnterKeyDown();
};

TerceraNumeric.prototype.private_KeyUp = function (event)
{
    if (!this.get('enabled')) return;

    if (event.original.keyCode == KeyCode.UP)
        this.loopValueChange(true, false);
    if (event.original.keyCode == KeyCode.DOWN)
        this.loopValueChange(false, false);
};

TerceraNumeric.prototype.private_KeyPress = function (sender, event)
{
    if (!this.get('enabled')) return;

    var result = this.validateNumericValue(event);
    if (!result) //отменить  изменение и всплытие события если символы недопустимы!!!
    {
        event.stopPropagation();
        event.preventDefault();
        return false;
    }
};

TerceraNumeric.prototype.private_Focus = function (e)
{
    if (!this.get('enabled')) return;

    if (this.get("tradingNumeric"))
        if (this.get('hasError'))
            this.showPopupError(this.get('errorText'))

    // Cross-browser hack for the different "input.select()" behaviours after focusing.
    // https://www.impressivewebs.com/input-select-correct-behaviour/
    var inputEl = e.node;
    //setTimeout(function () { inputEl.select(); }, 0);
    inputEl.select();
};

TerceraNumeric.prototype.private_MouseWheel = function (context)
{
    let event = context.event;
    if ((!this.get('enabled') || !this.get('focused') || this.find('input') !== document.activeElement) && !this.get("onlyMouseWheel"))
        return;
    var isUp = ScrollUtils.IsScrollUp(event.deltaY);
    this.currentMoveType = isUp ? UpDownType.Up : UpDownType.Down;
    this.valueUpDownChangeProcess(isUp);
    return false;
};

TerceraNumeric.prototype.setFocus = function (forceEnable)
{
    Control.prototype.setFocus.apply(this);

    if (forceEnable)
        this.set('enabled', true)

    if (!this.get('enabled'))
        return;

    var inputEl = this.find('input');
    if (inputEl) inputEl.focus();
};

TerceraNumeric.prototype.onLostFocus = function ()
{
    if (this.isRequiredIntervalStart)
    {
        clearInterval(this.requiredInterval);
        this.isRequiredIntervalStart = false;
    }

    if (this.get("tradingNumeric"))
    {
        if (popupErrorHandler.isShowed())
        {        // #85040
            let strValue = this.get('formattedValue')
            if (strValue != '')
            {
                let step = this.get('step'), minValue = this.get('minValue'), maxValue = this.get('maxValue')
                let newValue = parseFloat(strValue)
                let stepPrecision = this.get("decimalPrecision")
                if (isNaN(newValue))    // #92426
                    newValue = 0
                newValue = parseFloat(MathUtils.RoundToIncrement(newValue, step).toFixed(stepPrecision));
                if (newValue < minValue)
                    newValue = minValue
                if (newValue > maxValue)
                    newValue = maxValue
                this.set('value', newValue);
                this.set('formattedValue', newValue.toFixed(stepPrecision));
            }
        }
        popupErrorHandler.Hide(this);
        return;
    }

    this.updateNumeric(this.getValue());
};

TerceraNumeric.prototype.decimalseparator = '.';
TerceraNumeric.prototype.commaseparator = ',';
TerceraNumeric.prototype.regExp;

TerceraNumeric.prototype.updateRegExp = function (allowMinus)
{
    var regStr = '0-9';
    if (allowMinus) regStr += '\-';

    this.regExp = new RegExp('[' + regStr + this.decimalseparator + ']');
};

TerceraNumeric.prototype.requiredInterval = undefined;     //Интервал для повторов valueUpDownChangeProcess
TerceraNumeric.prototype.isRequiredIntervalStart = false;  //запущен ли интервал

TerceraNumeric.prototype.loopValueChange = function (isUp, start)
{
    this.currentMoveType = isUp ? UpDownType.Up : UpDownType.Down;
    if (start)
    {
        if (!this.isRequiredIntervalStart)
        {
            this.isRequiredIntervalStart = true;
            this.requiredInterval = setInterval(this.valueUpDownChangeProcess.bind(this), 150, isUp);
        }
    }
    else
    {
        if (this.isRequiredIntervalStart)
        {
            clearInterval(this.requiredInterval);
            this.isRequiredIntervalStart = false;
        }
        return;
    }
    this.valueUpDownChangeProcess(isUp);
};

TerceraNumeric.prototype.valueUpDownChangeProcess = function (isUp)
{
    var me = this;

    var oldVal = me.get('value');

    var interval = me.GetInterval(oldVal);
    var step = me.get('step');
    var incrementCoefficient = me.get('incrementCoefficient');
    var decimalPrecision = me.get('decimalPrecision');
    if (interval)
    {
        if (step !== interval.Increment)
        {
            me.set({ step: interval.Increment });
            step = interval.Increment;
        }
        if (decimalPrecision !== interval.DecimalPlaces)
        {
            me.set({ decimalPrecision: interval.DecimalPlaces });
            decimalPrecision = interval.DecimalPlaces;
        }
    }

    step *= incrementCoefficient;

    var newValue = 0;
    if (isUp)
        newValue = (oldVal + step);
    else
        newValue = (oldVal - step);

    var res = me.validateValue(
        oldVal,
        newValue,
        me.get('minValue'),
        me.get('maxValue'),
        decimalPrecision,
        true);

    me.set({ value: res });
};

TerceraNumeric.prototype.oninit = function ()
{
    Control.prototype.oninit.apply(this);

    this.updateRegExp(this.get('allowMinus'));

    this.currentMoveType = UpDownType.None;

    this.on('focus', this.private_Focus);
    this.on('mousewheel', this.private_MouseWheel);
    this.on('keyDown', this.private_KeyDown);
    this.on('keyUp', this.private_KeyUp);
    this.on(Control.Events.LostFocus, this.onLostFocus);

    this.observe('visible', this.onVisibleChanged);
    // ! надо сохранить числовое значение и текстовое паралельно !
    this.observe('value', this.updateNumeric);
    this.observe('formattedValue', this.onFormattedValueChanged, { init: false });
    this.observe('minValue maxValue customError', this.checkErrors, { init: false });
    this.observe('decimalPrecision', this.onDecimalPrecisionChanged, { init: false });
    this.observe('allowMinus', function () { this.updateRegExp(this.get('allowMinus')); }, { init: false });

    this.observe('enabled errorText', this.onNeedUpdateValidationStateInfo, { init: false });
    this.observe('hasError', function (newValue) { TerceraNumeric.OnHasErrorsChanged.Raise(this, newValue) }, { init: false });

    this.observe('DynPropertyData', this.onDynPropertyDataChanged, { init: false });

    this.setInitialValue();
};

TerceraNumeric.prototype.setInitialValue = function ()
{
    const initialValue = this.getValue() ?? this.get('defaultValue');
    this.set('value', initialValue);
};

TerceraNumeric.prototype.onDynPropertyDataChanged = function (newValue, oldValue)
{
    if (!newValue)
        return;
    this.set({
        value: newValue.value,
        min: newValue.minimalValue,
        max: newValue.maximalValue,
        step: newValue.increment,
        numericPrecision: newValue.decimalPlaces,
        incrementCoefficient: 1,
        validationStateInfo: 1,
    });
};

TerceraNumeric.OnHasErrorsChanged = new CustomEvent();

TerceraNumeric.prototype.onteardown = function ()
{
    popupErrorHandler.Hide(this);
};

TerceraNumeric.prototype.onNeedUpdateValidationStateInfo = function ()
{
    this.TryParseValue(this.digitValue(this.get('formattedValue')));
    this.set('validationStateInfo', {
        enabled: this.get('enabled'),
        errorText: this.get('errorText')
    });
};

TerceraNumeric.prototype.onVisibleChanged = function (newValue, oldValue)
{
    if (!newValue)
        this.set({ hasError: false, errorText: '' });
    else
        this.checkErrors();
};

TerceraNumeric.prototype.checkErrors = function ()
{
    if (!this.get('tradingNumeric'))
        return;

    var val = this.get('value');
    var fVal = this.get('formattedValue');

    if (fVal)
        this.TryParseValue(fVal);
    else
        this.updateNumeric(val);
};

TerceraNumeric.prototype.onDecimalPrecisionChanged = function ()
{
    this.onFormattedValueChanged(this.get('formattedValue'));
};

TerceraNumeric.prototype.updateNumeric = function (newValue)
{
    var isCorrect = true;
    if (isNaN(newValue))
        newValue = this.get('minValue');

    var interval = this.GetInterval(newValue, true);
    var decimalPrecision = this.get('decimalPrecision');
    if (interval)
    {
        var step = this.get('step');
        if (step !== interval.Increment)
            this.set({ step: interval.Increment });
        if (decimalPrecision !== interval.DecimalPlaces)
        {
            this.set({ decimalPrecision: interval.DecimalPlaces });
            decimalPrecision = interval.DecimalPlaces;
        }
    }
    var res = this.validateValue(newValue, newValue, this.get('minValue'), this.get('maxValue'), decimalPrecision);
    if (this.get("tradingNumeric"))
        isCorrect = this.TryParseValue(res);
    var newFormatedValue = this.valueToString(res, interval);
    this.set({ formattedValue: newFormatedValue, value: parseFloat(newFormatedValue) });

    var prop = this.get('DynPropertyData');
    if (prop.value)
    {
        prop.value = parseFloat(newFormatedValue);
        this.set({ DynPropertyData: prop });
    }


    this.fire(TerceraNumeric.Events.ValueChanged, this.getValue());
};

TerceraNumeric.prototype.onFormattedValueChanged = function (newValue)
{
    var isCorrect = newValue === "-" ? false : true;

    if (newValue.indexOf(this.commaseparator) >= 0)     // #115398
        newValue = newValue.replace(this.commaseparator, this.decimalseparator)

    if (this.get("tradingNumeric"))
        isCorrect = this.TryParseValue(newValue);

    if (!newValue || !isCorrect)
        return;

    this.setValue(newValue);
    this.updateNumeric(this.getValue());
};

// TODO. Refactor. Ugly.
TerceraNumeric.prototype.TryParseValue = function (inputValue)
{
    var textValue = inputValue || inputValue === 0 ? inputValue.toString() : '';
    var correctValue = false;
    var errorTextForNumeric = '';

    if (textValue === '')
    {
        errorTextForNumeric = Resources.getResource('UserControl.TerceraNumeric.ValueIsEmpty')
    }
    else
    {
        var parsedValue = this.digitValue(textValue);

        if (isNaN(parsedValue) || textValue.split(TerceraNumeric.prototype.decimalseparator).length > 2)
            errorTextForNumeric = Resources.getResource('UserControl.TerceraNumeric.ValueNotNumber');
        else
        {
            var interval = this.GetInterval(parsedValue, true);
            var step = 0;
            var stepPrecision = 0;
            if (interval)
            {
                step = interval.Increment;
                stepPrecision = interval.DecimalPlaces;
            }
            else
            {
                step = this.get('step');
                stepPrecision = MathUtils.getPrecision(step);
            }

            var formatMin = MathUtils.formatValueWithEps(this.get('minValue')); //https://tp.traderevolution.com/entity/101800   добавим говна))
            var formatMax = MathUtils.formatValueWithEps(this.get('maxValue')); //https://tp.traderevolution.com/entity/101800

            if (parsedValue < formatMin)
            {
                let key = this.get('valueLessMinLocalKey')
                let standartKey = "UserControl.TerceraNumeric.ValueLessMin" === key;
                errorTextForNumeric = Resources.getResource(key);
                if (standartKey)
                    errorTextForNumeric += formatMin
            }
            else if (parsedValue > formatMax)
            {
                //TODO think 
                let key = this.get('valueGreaterMaxLocalKey')
                let standartKey = "UserControl.TerceraNumeric.ValueGreaterMax" === key;
                errorTextForNumeric = Resources.getResource(key);
                if (standartKey)
                    errorTextForNumeric += formatMax
            }
            else if (step && !MathUtils.isValueMultipleToStep(parsedValue, step, stepPrecision) && (!this.get('SkipValidationForMaxValue') || parsedValue != formatMax))
                errorTextForNumeric = Resources.getResource('UserControl.TerceraNumeric.ValueNotMultiple') + MathUtils.formatDoubleValueUseFactor(step, step, stepPrecision);
            else
                correctValue = true;
        }
    }

    // TODO. Refactor. Ugly.
    let customError = this.get('customError')
    if (correctValue && customError)
    {
        errorTextForNumeric = customError.toString()
    }

    if (this.get('enabled') && this.get('visible'))
        this.set({
            hasError: !correctValue || customError,
            errorText: errorTextForNumeric
        });

    // TODO. Ugly.
    if (correctValue && !customError)
        popupErrorHandler.Hide(this);
    else
        this.showPopupError(errorTextForNumeric)

    return correctValue;
};

TerceraNumeric.prototype.showPopupError = function (errorTextForNumeric)
{
    if (!this.get('enabled') || !this.get('focused') || !errorTextForNumeric)
        return

    popupErrorHandler.Show(this, errorTextForNumeric, '')
}

TerceraNumeric.prototype.getAbsoluteRectangle = function ()
{
    let $root = $(this.find('div'))
    let $window = $(window)
    let offset = $root.offset()

    return new Rectangle(
        offset.left - $window.scrollLeft(),
        offset.top - $window.scrollTop(),
        $root.width(),
        $root.height())
}

TerceraNumeric.prototype.validateValue = function (oldVal, newVal, min, max, prec, valueFromUpDown)
{
    var tmpNewVal = parseFloat(newVal);
    var tmpMin = parseFloat(min);
    var tmpMax = parseFloat(max);

    var result = tmpNewVal;

    if (!this.get('tradingNumeric') || valueFromUpDown)
    {
        if (min !== undefined && tmpMin > tmpNewVal)
            result = tmpMin;
        else if (max !== undefined && tmpMax < tmpNewVal)
            result = tmpMax;
    }

    if (typeof result !== 'undefined' && !isNaN(result))
    {
        result = parseFloat(result.toFixed(prec));
        return result;
    }
    else
        return oldVal;
};

TerceraNumeric.prototype.digitValue = function (value)
{
    if (!value || isNaN(value)) return null;
    return parseFloat(value);
};

TerceraNumeric.prototype.valueToString = function (value, interval)
{
    var prec = this.get('decimalPrecision');
    if (interval)
        prec = interval.DecimalPlaces;
    value = parseFloat(value);
    value = value.toFixed(prec);
    var res = value.replace(/[.]/g, TerceraNumeric.prototype.decimalseparator);
    return res;//value.toLocaleString();
};

TerceraNumeric.prototype.validateNumericValue = function (event)
{
    var regexp = this.regExp;
    var e = event || window.event;
    var target = e.target || e.srcElement;
    var isIE = document.all;
    var code = isIE ? e.keyCode : e.which;
    if (code < 32 || e.ctrlKey || e.altKey) return true;
    var char = String.fromCharCode(code);

    return regexp.test(char);
};

TerceraNumeric.prototype.setValue = function (value)
{
    this.set('value', this.digitValue(value));
};

TerceraNumeric.prototype.getValue = function ()
{
    return this.get('value');
};

TerceraNumeric.prototype.getStringValue = function ()
{
    return this.get('value');
};

TerceraNumeric.prototype.GetInterval = function (value, forDecimalPlaces)
{
    if (forDecimalPlaces === undefined)
        forDecimalPlaces = false

    var Intervals = this.get('Intervals');

    if (Intervals == null || Intervals.length == 0)
        return null;

    for (var i = 0; i < Intervals.length; i++)
        if (!forDecimalPlaces ? Intervals[i].ValueInIntervalUpDown(value, this.currentMoveType) : Intervals[i].ValueInInterval(value, this.currentMoveType))
            return Intervals[i];

    if (value >= Intervals[Intervals.length - 1].RightValue)
        return Intervals[Intervals.length - 1];

    if (value <= Intervals[0].LeftValue)
        return Intervals[0];

    return null;
};


TerceraNumeric.Events =
{
    ValueChanged: 'ValueChanged'
};
Object.freeze(TerceraNumeric.Events);

TerceraNumeric.NUMERIC_MAXVALUE = 99999999;

let handler = terceraNumericHandler;
handler.OnHasErrorsChanged = TerceraNumeric.OnHasErrorsChanged;