// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { Resources } from '../../Commons/properties/Resources';
import { InstrumentTypes } from './InstrumentTypes';
import { InstrumentUtils } from './InstrumentUtils';

export class InstrumentLookupInstrumentType {
    private static nonFixedListTypeSet;

    public static getNonFixedListTypeSet (): Record<any, boolean> | null {
        if (this.nonFixedListTypeSet) {
            return this.nonFixedListTypeSet;
        }

        const resSet: Record<any, boolean> = {};

        // если не фиксированный список - показываем все эксченжи и типы инструментов, кроме #hidden# и запрещенных dataProvider
        if (!Resources.isHidden('panel.TerceraSymbolLookupDropDownForm.CFDs')) {
            resSet[InstrumentTypes.EQUITIES_CFD] = true;
            // resSet[Instrument.CFD_FUTURES] = true
        }
        if (!Resources.isHidden('panel.TerceraSymbolLookupDropDownForm.Futures') && !InstrumentUtils.UseFuturesInsteadSpot()) {
            resSet[InstrumentTypes.FUTURES] = true;
        }

        if (!Resources.isHidden('panel.TerceraSymbolLookupDropDownForm.Forex')) {
            resSet[InstrumentTypes.FOREX] = true;
        }

        if (!Resources.isHidden('panel.TerceraSymbolLookupDropDownForm.Equities')) {
            resSet[InstrumentTypes.EQUITIES] = true;
        }

        if (!Resources.isHidden('panel.TerceraSymbolLookupDropDownForm.Options')) {
            resSet[InstrumentTypes.OPTIONS] = true;
        }

        if (!Resources.isHidden('panel.TerceraSymbolLookupDropDownForm.indices')) {
            resSet[InstrumentTypes.INDICIES] = true;
        }

        if (!Resources.isHidden('panel.TerceraSymbolLookupDropDownForm.crypto')) {
            resSet[InstrumentTypes.CRYPTO] = true;
        }

        if (!Resources.isHidden('panel.TerceraSymbolLookupDropDownForm.Spreadbet')) {
            resSet[InstrumentTypes.SPREADBET] = true;
        }

        if (!Resources.isHidden('panel.TerceraSymbolLookupDropDownForm.Bonds')) {
            resSet[InstrumentTypes.BOND] = true;
        }

        if (!Resources.isHidden('panel.TerceraSymbolLookupDropDownForm.ETFs')) {
            resSet[InstrumentTypes.ETF] = true;
        }

        if (!Resources.isHidden('panel.TerceraSymbolLookupDropDownForm.TBill')) {
            resSet[InstrumentTypes.TBILL] = true;
        }

        if (!Resources.isHidden('panel.TerceraSymbolLookupDropDownForm.Spot')) {
            resSet[InstrumentTypes.SPOT] = true;
        }

        if (!Resources.isHidden('panel.TerceraSymbolLookupDropDownForm.Forward')) {
            resSet[InstrumentTypes.FORWARD] = true;
        }

        if (!Resources.isHidden('panel.TerceraSymbolLookupDropDownForm.Corporate')) {
            resSet[InstrumentTypes.CORPORATE] = true;
        }

        this.nonFixedListTypeSet =
            (Object.keys(resSet).length > 0)
                ? resSet
                : null;

        return this.nonFixedListTypeSet;
    }
}
