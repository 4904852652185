// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PFixFieldSet, AlgorithmHighLowInstrumentGroup } from '../../GroupsImport';
import { FieldsFactory } from '../../../Factory/FieldsFactory';

export class AlgorithmHighLowDelayDataGroup extends PFixFieldSet {
    constructor (pfixFieldSet: PFixFieldSet) {
        super(FieldsFactory.ALGORITHM_HIGH_LOW_DELAY_DATA_GROUP, null, pfixFieldSet);
        this.InstrumentGroups = pfixFieldSet.GetGroups(FieldsFactory.ALGORITHM_HIGH_LOW_INSTRUMENT_GROUP, AlgorithmHighLowInstrumentGroup);
    }

    InstrumentGroups: AlgorithmHighLowInstrumentGroup[];
}
