// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { ProductType } from '../Instruments/ProductType';
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

// Мессадж форекс-клиента
export class DirectOrderDialogMessage extends DirectMessageBaseClass {
    public ProductType = ProductType.General;
    public Leverage: number;

    private dialogId = 0;
    private orderId: string | null = null;
    private boundToOrderId: string | null = null;
    private price = 0;
    private comment: string | null = null;
    private orderType_Renamed_Field = 0;
    private closeTime: any = null;
    private cancelTime: Date | null = null;
    private FAccount: string | null = null;
    private orderStatus: number = 0;
    private clientOrderId: string = '';
    private quantity = 0;
    private lastAmount: number | null = null;
    private disClosedQuantity: number | null = null;
    private sharesFilled = 0;

    constructor () {
        super(Message.CODE_CANCEL_ORDER_MESSAGE);
    }

    get DialogId (): number {
        return this.dialogId;
    }

    set DialogId (val) {
        this.dialogId = val;
    }

    get OrderId (): string | null {
        return this.orderId;
    }

    set OrderId (val) {
        this.orderId = val;
    }

    get BoundToOrderId (): string | null {
        return this.boundToOrderId;
    }

    set BoundToOrderId (val) {
        this.boundToOrderId = val;
    }

    get Price (): number {
        return this.price;
    }

    set Price (val) {
        this.price = val;
    }

    get Comment (): string | null {
        return this.comment;
    }

    set Comment (val) {
        this.comment = val;
    }

    get OrderType (): number {
        return this.orderType_Renamed_Field;
    }

    set OrderType (val) {
        this.orderType_Renamed_Field = val;
    }

    get CloseTime (): any {
        return this.closeTime;
    }

    set CloseTime (val) {
        this.closeTime = val;
    }

    get CancelTime (): Date | null {
        return this.cancelTime;
    }

    set CancelTime (val) {
        this.cancelTime = val;
    }

    get Account (): string | null {
        return this.FAccount;
    }

    set Account (val) {
        this.FAccount = val;
    }

    get OrderStatus (): number {
        return this.orderStatus;
    }

    set OrderStatus (val: number) {
        this.orderStatus = val;
    }

    get ClientOrderId (): string {
        return this.clientOrderId;
    }

    set ClientOrderId (val: string) {
        this.clientOrderId = val;
    }

    get Quantity (): number { return this.quantity; }
    set Quantity (val: number) { this.quantity = val; }

    get LastAmount (): number { return this.lastAmount; }
    set LastAmount (val: number) { this.lastAmount = val; }

    get DisClosedQuantity (): number { return this.disClosedQuantity; }
    set DisClosedQuantity (val: number) { this.disClosedQuantity = val; }

    get SharesFilled (): number { return this.sharesFilled; }
    set SharesFilled (val: number) { this.sharesFilled = val; }
}
