// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { VideoWidgetTemplate } from "../../../templates.js"
import { Resources } from "../../../Commons/properties/Resources.ts"
import { KeyCode, KeyEventProcessor } from "../../../Commons/KeyEventProcessor.ts"
import { HtmlScroll } from "../../../Commons/HtmlScroll.js"
import { PanelNames } from "../../UtilsClasses/FactoryConstants.ts"
import { MainWindowManager } from "../../UtilsClasses/MainWindowManager.ts"
import { ApplicationPanelNew } from "../ApplicationPanelNew.js"
import { VideoWidgetCard } from "./VideoWidgetCard.js"

export let VideoWidget = ApplicationPanelNew.extend({
    data: function ()
    {
        return {

            externalLinks: [],
            visibleCardsNum: null,                  // number of visible external links (videos) in widget
            cards: {},
            loaded: false,                          // Determines whether the loading of data from the API or MongoDB has fully completed yet

            noDataText: '',                         // localized text of noData info
            isLoadingText: '',                      // localized text of IsLoading info   

            callerBtn: null,                        // reference to header opening button => changing blink state
            cacheReference: null,                   // reference to DataCache.ExternalLinksCache

            width: 509,
            height: 348,
            isAccountLinkShow: false,
            isSymbolLinkShow: false,
            dockablePanel: false,
            showHeader: false,
            showFooter: false,
            resizable: false,
            movable: false,
            closeBtnVisible: false,

            style_addition_header: 'js-PropertySetupScreen-AdditionalHeader'
        }
    },

    broadcastingLiveNowNumber: 0,                   // number of LIVE streams in the widget at the moment

    headerLocaleKey: '',
    NeedCalculateRowCount: false,
    skipOnSave: true,
    partials: { bodyPartial: VideoWidgetTemplate }
})

VideoWidget.prototype.getType = function () { return PanelNames.VideoWidget; }

VideoWidget.prototype.oninit = function ()
{
    ApplicationPanelNew.prototype.oninit.apply(this);

    this.GlobalEventsSubscribe();
    this.observe('visible', this.onVisibilityChanged);
    this.observe('callerBtn', this.updateCallerBtnState);
    this.observe('cacheReference', this.onCacheReferenceChanged);
    this.observe('externalLinks', this.onExternalLinksSet);
}

VideoWidget.prototype.updateCallerBtnState = function ()
{
    let btn = this.get('callerBtn');
    if (btn)
        btn.ChangeBlinkStatus(this.broadcastingLiveNowNumber);
}

VideoWidget.prototype.dispose = function ()
{
    this.GlobalEventsUnSubscribe();
    this.allCardsEventsUnSubscribe();

    this.set('cacheReference', null);   // To unsubscribe from the UpdateAllLinks process

    ApplicationPanelNew.prototype.dispose.apply(this);
}

VideoWidget.prototype.UpdateAllLinks = function ()
{
    if (this.torndown)   //#115375
        return

    this.visibilityBeforeTorndown = this.get('visible');

    this.dispose();

    const btn = this.get('callerBtn');
    if (btn)
        btn.fire('componentClick');
}

VideoWidget.prototype.onCardFullDataFetched = function (card)
{
    if (card)
    {
        let cards = this.get('cards');
        let eL = card.get('externalLink');

        const needUpdateOnStreamStart = eL.IsUpcomingStream && eL.IsLiveStream === false && card.StreamHasNotStartedYet === false;
        const needUpdateOnStreamEnd = eL.IsEndedStream === false && eL.IsLiveStream && card.StreamHasBeenAlreadyEnded;

        if (eL)
        {
            if (!eL.DateForSorting || needUpdateOnStreamStart || needUpdateOnStreamEnd)
            {
                let cacheReference = this.get('cacheReference');
                let cache = cacheReference ? cacheReference.CachedYoutubeResponseData : null;

                if (needUpdateOnStreamStart)
                {
                    cache[card.VideoID].isLIVE = eL.IsLiveStream = true;
                    cache[card.VideoID].streamHasNotStartedYet = eL.IsUpcomingStream = false;
                }

                if (needUpdateOnStreamEnd)
                {
                    cache[card.VideoID].streamHasBeenAlreadyEnded = eL.IsEndedStream = true;
                    cache[card.VideoID].isLIVE = eL.IsLiveStream = eL.liveStream = false;
                }

                return this.UpdateAllLinks();
            }

            if (eL.id)
            {
                cards[eL.id] = card;
                this.set('cards', cards);
            }
        }
    }

    if (this.allCardsFetched())
        this.finishLoadingProcess();
}

VideoWidget.prototype.finishLoadingProcess = function ()
{
    const loaded = this.allCardsFetched();
    let cards = this.get('cards');
    let visibleCardsNum = 0;
    let liveNum = 0;
    for (let id in cards)
    {
        let card = cards[id];
        const visibleCard = card && card.gotFetchedResponse && !card.get('expired') && card.ParsedSuccessfully;
        if (visibleCard)
        {
            if (card.IsLIVE)
                liveNum++;

            card.set('visible', loaded);
            visibleCardsNum++;
        }
    }

    if (liveNum != this.broadcastingLiveNowNumber)
    {
        this.broadcastingLiveNowNumber = liveNum;
        this.updateCallerBtnState();
    }

    this.set({
        visibleCardsNum: visibleCardsNum,
        loaded: loaded
    });

    if (loaded)
        this.allVideosScrollAdd();
}

VideoWidget.prototype.onExternalLinksSet = function (newValue)
{
    this.externalLinksArrayLengthWasSet = newValue ? newValue.length : 0;

    this.set({
        visibleCardsNum: this.externalLinksArrayLengthWasSet,
        loaded: !newValue || !newValue.length
    });

    if (this.allCardsFetched())
        this.finishLoadingProcess();
}

VideoWidget.prototype.allCardsFetched = function (card)
{
    return this.getCardsFetchedNum() == this.externalLinksArrayLengthWasSet;
}

VideoWidget.prototype.getCardsFetchedNum = function ()
{
    let cards = this.get('cards');
    let fetchedNum = 0;
    for (let id in cards)
    {
        let card = cards[id];
        if (card && card.gotFetchedResponse)
            fetchedNum++;
    }

    return fetchedNum;
}

VideoWidget.prototype.OnCardIsLIVEStatusChanged = function (card)
{
    this.broadcastingLiveNowNumber += card.IsLIVE ? 1 : -1;

    const callerBtn = this.get('callerBtn');
    if (callerBtn && (!this.broadcastingLiveNowNumber || this.broadcastingLiveNowNumber == 1))                 // if need turn blink OFF or ON
        callerBtn.ChangeBlinkStatus(this.broadcastingLiveNowNumber);
}

VideoWidget.prototype.onLinkAvailableUntilExpired = function (expiredExternalLink)
{
    let id = expiredExternalLink ? expiredExternalLink.id : null;
    let cards = this.get('cards');
    let expiredCard = cards[id];
    if (expiredCard)
        expiredCard.set({ 'expired': true, 'visible': false });

    let links = this.get('externalLinks');
    if (links)
    {
        links.splice(links.indexOf(expiredExternalLink), 1);
        this.set('externalLinks', links).then(() => { this.finishLoadingProcess() })
    }
}

VideoWidget.prototype.allCardsEventsUnSubscribe = function ()
{
    let cards = this.get('cards');
    for (let id in cards)
    {
        let card = cards[id];
        this.unsubscribeWidgetFromCardEvents(card);
    }
}

VideoWidget.prototype.subscribeWidgetToCardEvents = function (card)
{
    if (!card)
        return;

    card.OnFullDataFetched.Subscribe(this.onCardFullDataFetched, this);
    card.OnIsLIVEChanged.Subscribe(this.OnCardIsLIVEStatusChanged, this);

    const extLink = card.get('externalLink');
    if (extLink)
    {
        extLink.onAvailableUntilExpired.Subscribe(this.onLinkAvailableUntilExpired, this);
        extLink.onStreamNeedUpdate.Subscribe(this.UpdateAllLinks, this);
    }
}

VideoWidget.prototype.unsubscribeWidgetFromCardEvents = function (card)
{
    if (!card)
        return;

    card.OnFullDataFetched.UnSubscribe(this.onCardFullDataFetched, this);
    card.OnIsLIVEChanged.UnSubscribe(this.OnCardIsLIVEStatusChanged, this);

    const extLink = card.get('externalLink');
    if (extLink)
    {
        extLink.onAvailableUntilExpired.UnSubscribe(this.onLinkAvailableUntilExpired, this);
        extLink.onStreamNeedUpdate.UnSubscribe(this.UpdateAllLinks, this);
    }
}

/**
 * Handle the change in the external links cache subscription.
 * 
 * @param {null|undefined|import('../../../Commons/cache/ExternalLinksCache.ts').ExternalLinksCache} cacheReference - The new cache reference.
 * @param {null|undefined|import('../../../Commons/cache/ExternalLinksCache.ts').ExternalLinksCache} oldCacheReference - The old cache reference for unsubscription.
 */
VideoWidget.prototype.onCacheReferenceChanged = function (cacheReference, oldCacheReference)
{
    if (cacheReference)
    {
        cacheReference.OnAddLink.Subscribe(this.UpdateAllLinks, this);
        cacheReference.OnUpdateLink.Subscribe(this.UpdateAllLinks, this);
        cacheReference.OnDeleteLink.Subscribe(this.UpdateAllLinks, this);
    }
    else if (oldCacheReference)
    {
        oldCacheReference.OnAddLink.UnSubscribe(this.UpdateAllLinks, this);
        oldCacheReference.OnUpdateLink.UnSubscribe(this.UpdateAllLinks, this);
        oldCacheReference.OnDeleteLink.UnSubscribe(this.UpdateAllLinks, this);
    }
}

VideoWidget.prototype.GlobalEventsSubscribe = function ()
{
    KeyEventProcessor.OnKeyDown.Subscribe(this.onGlobalKeyDown, this);
}

VideoWidget.prototype.GlobalEventsUnSubscribe = function ()
{
    KeyEventProcessor.OnKeyDown.UnSubscribe(this.onGlobalKeyDown, this);
}

VideoWidget.prototype.onGlobalKeyDown = function ()
{
    var keyProc = KeyEventProcessor;
    if (keyProc.currentButton === KeyCode.ESC)   // #103388
        this.set('visible', false);
}

VideoWidget.prototype.lostFocus = function ()
{
    ApplicationPanelNew.prototype.lostFocus.apply(this);

    this.close();
}
VideoWidget.prototype.onVisibilityChanged = function (value)
{
    let videoWidgetBtn = this.get('callerBtn');
    if (!videoWidgetBtn)
        return;

    videoWidgetBtn.set("focused", value);

    if (value)
        this.allVideosScrollAdd();
}

VideoWidget.prototype.close = function ()
{
    if (MainWindowManager.TerceraAccountLookupDropDownForm && MainWindowManager.TerceraAccountLookupDropDownForm.get('visible'))
        return;

    this.set({ visible: false });
}

VideoWidget.prototype.allVideosScrollAdd = function ()
{
    let allVideos = document.getElementsByClassName('videoWidget-allVideosContainer')[0];
    if (allVideos)
    {
        HtmlScroll.removeScroll(allVideos);
        allVideos.style.height = "100%";
        HtmlScroll.addScroll(allVideos);
    }
}

VideoWidget.prototype.allVideosScrollTo = function ()
{
    let allVideos = document.getElementsByClassName('videoWidget-allVideosContainer')[0];
    if (allVideos)
    {
        HtmlScroll.addScroll(allVideos);
        $(allVideos).mCustomScrollbar("scrollTo", "#opened");
    }
}

VideoWidget.prototype.localize = function ()
{
    ApplicationPanelNew.prototype.localize.apply(this);

    this.set({
        noDataText: Resources.getResource('panel.VideoWidget.noDataText'),
        isLoadingText: Resources.getResource('panel.VideoWidget.isLoadingText')
    });
}