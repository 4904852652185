// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectInstrumentUpdateMessage extends DirectMessageBaseClass {
    public Name: string | null = null;
    public InstrumentID: number | null = null;
    public ContractID: number | null = null;
    public TradeSessionStatusId: number | null = null;
    public ExchangeSessionName: string | null = null;
    public InstrumentTradableID: number;

    constructor () {
        super(Message.CODE_PFIX_TRADE_INSTRUMENT);
    }
}
