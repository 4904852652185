// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class Message {
    static CODE_QUOTE = 1;
    static CODE_INSTRUMENT = 2;
    static CODE_MARGINCALL = 13;
    static CODE_ACCOUNTSTATUS = 14;
    static CODE_OPENPOSITION = 17;
    static CODE_CLOSEPOSITION = 18;
    static CODE_OPENORDER = 27;
    static CODE_INSTRUMENT_TYPE = 31;
    static CODE_PFIX_LOGON_MESSAGE = 42;
    static CODE_USER_GROUP = 41;
    static CODE_CANCEL_ORDER_MESSAGE = 46;
    static CODE_REPLACE_ORDER_MESSAGE = 47;
    static CODE_BUSINESS_REJECT_MESSAGE = 49;
    static CODE_TRADE = 50;
    static CODE_TEXT_MESSAGE = 51;
    static CODE_NEWS_MESSAGE = 52;
    static CODE_NEWS_REQUEST_MESSAGE = 53;
    static CODE_PFIX_LOGOUT_MESSAGE = 56;
    static CODE_PFIX_PING_MESSAGE = 57;
    static CODE_PFIX_CHANGE_PASSWORD_REQUEST = 64;
    static CODE_PFIX_PROPERTY_MESSAGE = 68;
    static CODE_PFIX_REPORT_REQUEST_MESSAGE = 69;
    static CODE_SUBSCRIBE_MESSAGE = 71;
    static CODE_PFIX_XML_MESSAGE = 72;
    static CODE_NEW_ORDER_MESSAGE = 73;
    static CODE_PFIX_HELLO_MESSAGE = 75;
    static CODE_ACCOUNT_OPERATION_REQUEST_MESSAGE = 76;
    static CODE_ACCOUNT_OPERATION_CONFIRM_MESSAGE = 77;
    static CODE_ACCOUNT_OPERATION_REQUEST_INFO_MESSAGE = 78;
    static CODE_SUBSCRIBE_RESPONSE_MESSAGE = 84;
    static CODE_SESSION_MESSAGE = 85;
    static CODE_CROSSRATE_MESSAGE = 87;
    static CODE_ACCOUNOPERATION_MESSAGE = 88;
    static CODE_NEWS_SUBSCRIBE_MESSAGE = 89;
    static CODE_NEWS_SUBSCRIBE_RESPONSE = 90;
    static CODE_SOURCE_LIST = 91;
    static CODE_REPORT_TYPE_MESSAGE = 92;
    static CODE_TRADING_SIGNALS_REQ_MESSAGE = 94;
    static CODE_CANCEL_TRADING_SIGNAL_MESSAGE = 95;
    static CODE_TRADING_SIGNAL_MESSAGE = 96;
    static CODE_SUBSCRIPTION_STRATEGY_MESSAGE = 97;
    static CODE_SUBSCRIPTION_STRATEGY_REQUEST_MESSAGE = 98;
    static CODE_TRADING_SYSTEM_MESSAGE = 99;
    static CODE_TRADING_SYSTEM_REMOVE_MESSAGE = 100;
    static CODE_TRADING_SIGNAL_REQ_SEEN_MESSAGE = 101;
    static CODE_QUOTE2 = 102;
    static CODE_QUOTE3 = 103;
    static CODE_QUOTE4 = 104;
    static CODE_ROUTE = 105;
    static CODE_ORDER_HISTORY = 106;
    static CODE_PFIX_QUOTE2_AGGREGATED = 111;
    static CODE_INSTRUMENT_PRICES = 113;
    static CODE_INSTRUMENT_DAY_BAR = 114;
    static CODE_TRADE_SESSION_STATUS_MESSAGE = 119;
    static CODE_PFIX_MODIFY_POSITION = 121;
    static CODE_PFIX_BRANDING_RULES_REQ = 122;
    static CODE_PFIX_BRANDING_RULES_RESP = 123;
    static CODE_PFIX_TRADE_SESSION_SWITCH = 124;
    static CODE_PFIX_PRODUCT_MESSAGE = 125;
    static CODE_PFIX_PRODUCT_DOCUMENT_REQ = 126;
    static CODE_PFIX_PRODUCT_DOCUMENT_RESP = 127;
    static CODE_PFIX_PRODUCT_SUBSCRIPTION_MANAGEMENT_REQ = 128;
    static CODE_PFIX_PRODUCT_SUBSCRIPTION_MANAGEMENT_RESP = 129;
    static CODE_PFIX_PRODUCT_SUBSCRIPTION_HISTORY_REQ = 130;
    static CODE_PFIX_SOAP_REQ = 401;
    static CODE_PFIX_SOAP_RESP = 402;
    static CODE_PFIX_HISTORY_REQ = 403;
    static CODE_PFIX_HISTORY_RESP = 404;
    static CODE_COMMISSION_PLAN_MESSAGE = 409;
    static CODE_MARGIN_REQ = 410;
    static CODE_MARGIN_RESP = 411;
    static CODE_SPREAD_PLAN_MESSAGE = 413;
    static CODE_ASSET_TYPE_MESSAGE = 414;
    static CODE_CROSSRATES_PLAN_MESSAGE = 415;
    static CODE_PFIX_MAM_GROUP_MESSAGE = 416;
    static CODE_PFIX_RISK_PLAN_MESSAGE = 417;
    static CODE_INSTRUMENT_LIST_REQUEST = 418;
    static CODE_INSTRUMENT_LIST_RESPONSE = 419;
    static CODE_INSTRUMENT_STRIKES_REQUEST = 420;
    static CODE_INSTRUMENT_STRIKES_RESPONSE = 421;
    static CODE_INSTRUMENT_REQUEST = 422;
    static CODE_INIT_DATA_REQUEST = 428;
    static CODE_SWAP_PLAN = 430;
    static CODE_PFIX_COMPRESSED_MESSAGE = 432;
    static CODE_SUBSCRIBE_FOR_CROSSES_MESSAGE = 433;
    static CODE_YTM_RESPONSE = 435;
    static CODE_YTM_REQUEST = 436;
    static CODE_TRADING_UNLOCK_MESSAGE = 438;
    static CODE_TRADING_UNLOCK_MESSAGE_RESPONSE = 439;
    static CODE_PFIX_CHANGE_TRADING_PASSWORD_REQUEST = 440;
    static CODE_PFIX_TRADE_INSTRUMENT = 441;
    static CODE_ONETIME_PASSWORD_MESSAGE = 442;
    static CODE_RISK_WARN_MESSAGE = 443;
    static CODE_INFORMATION_MESSAGE = 450;
    static CODE_PFIX_REFRESH_TOKEN = 451;
    static CODE_PFIX_ALGORITHM_SUBSCRIBE_MESSAGE = 453;
    static CODE_PFIX_ALGORITHMS_AVAILABLE_MESSAGE = 454;
    static CODE_PFIX_ALGORITHM_GAINERS_LOSERS_MESSAGE = 455;
    static CODE_PFIX_ALGORITHM_HIGH_LOW_MESSAGE = 456;
    static CODE_REQUEST_RECOVERY_PASSWORD_MESSAGE = 460;
    static CODE_RECOVERY_CHANGE_REQUEST = 461;
    static CODE_RECOVERY_CHANGE_RESPONSE = 462;
    static CODE_PFIX_PORTFOLIO_SYSTEM_MESSAGE = 463;
    static CODE_PFIX_PORTFOLIO_INVESTOR_MESSAGE = 464;
    static CODE_PFIX_PORTFOLIO_MODEL_REQUEST_MESSAGE = 466;
    static CODE_PFIX_PORTFOLIO_MODEL_MESSAGE = 467;
    static CODE_PFIX_BROKER_RESPONSE_MESSAGE = 470;
    static CODE_PFIX_BROKER_MESSAGE_RESPONSE_CASE_RESPONSE = 471;
    static CODE_REPORT_GROUP_REQUEST = 472;
    static CODE_REPORT_GROUP_RESPONSE = 473;
    static CODE_PFIX_CUSTODIAL_PLAN_MESSAGE = 477;
    static CODE_PFIX_EXERCISE_REQUEST_MESSAGE = 478;
    static CODE_PFIX_PORTFOLIO_STATISTICS_REQUEST = 479;
    static CODE_PFIX_PORTFOLIO_STATISTICS_RESPONSE = 480;
    static CODE_PFIX_PORTFOLIO_REALTIME_STATISTICS = 481;
    static CODE_ALERT_MESSAGE = 483;
    static CODE_CREATE_ALERT_MESSAGE = 484;
    static CODE_REPLACE_ALERT_MESSAGE = 485;
    static CODE_CONTROL_ALERT_COMMAND_MESSAGE = 486;
    static CODE_PFIX_TOKEN_VERIFY_REQUEST = 487;
    static CODE_PFIX_TOKEN_VERIFY_RESPONSE = 488;
    static CODE_GET_SNAPSHOT_REQUEST = 489;
    static CODE_GET_SNAPSHOT_RESPONSE = 490;
    static CODE_PFIX_EXTERNAL_LINK_MESSAGE = 492;
    static CODE_PFIX_EXTERNAL_LINK_REMOVE_MESSAGE = 493;
    static CUSTOM_LIST_MESSAGE = 494;
    static CODE_PFIX_ALGORITHM_MARKET_CONSENSUS_MESSAGE = 501;
    static CODE_PFIX_ALGORITHM_TOP_VOLUME_MESSAGE = 503;
    static CODE_PFIX_ALGORITHM_HISTORICAL_GAINER_LOSER_TOPS_MESSAGE = 505;
    static BM_HISTORY_REQUEST = 507;
    static BM_HISTORY_RESPONSE = 508;
    static BM_COUNTER_MESSAGE = 509;
    static CODE_PFIX_ALGORITHM_HEATMAP_MESSAGE = 510;
    static CODE_PRICE_LIMITS_MESSAGE = 511;
    static CODE_PFIX_FUNDING_RATE_MARKUP_PLAN_MESSAGE = 513;
    static CODE_PFIX_CLOSE_ACCOUNT_REQUEST_MESSAGE = 514;
    static CODE_PFIX_CLOSE_ACCOUNT_RESPONSE_MESSAGE = 515;
    static CODE_PFIX_CLOSE_ACCOUNT_CANCEL_REQUEST_MESSAGE = 516;
    static CODE_PFIX_ACCEPTED_MESSAGE = 4001;

    static CodesString: Record<string, string> = (() => {
        const result: Record<string, string> = {};
        const keys = Object.keys(Message);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            if (typeof Message[key] === 'number') { result[Message[key]] = key; } else { continue; }
        }
        return result;
    })();
}
