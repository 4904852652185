// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PriceType } from "../../../../../Utils/History/CashItemUtils.ts";
import { IchimokuMode } from "../../IndicatorConstants.ts";
import { iBuildInIndicator } from "../../iBuildInIndicator.ts";

export let iIchimoku = function (tenkanSen, kijunSen, senkouSpanB)
{
    iBuildInIndicator.call(this, 5)

    this.FTenkanSen = tenkanSen;
    this.FKijunSen = kijunSen;
    this.FSenkouSpanB = senkouSpanB;

    this.FData.FArray[2].FShift = this.FKijunSen;
    this.FData.FArray[3].FShift = this.FKijunSen;

    this.GetAverage = function (period, offset)
    {
        let high = this.GetPrice(PriceType.High, offset);
        let low = this.GetPrice(PriceType.Low, offset);
        let price = 0.0;
        for (let i = offset + 1; i < offset + period; i++)
        {
            price = this.GetPrice(PriceType.High, i);
            if (high < price)
                high = price;
            price = this.GetPrice(PriceType.Low, i);
            if (low > price)
                low = price;
        }
        return (high + low) / 2.0;
    }
}
iIchimoku.prototype = Object.create(iBuildInIndicator.prototype)

Object.defineProperty(iIchimoku.prototype, 'Name',
    {
        get: function () { return 'iIchimoku' }
    })

Object.defineProperty(iIchimoku.prototype, 'Key',
    {
        get: function () { return this.DefaultKey + this.FTenkanSen + this.FKijunSen + this.FSenkouSpanB }
    })

iIchimoku.prototype.OnQuote = function (ci, callBound, callFromRefresh)
{

    if (this.FCount >= this.FTenkanSen)
    {
        //1 line
        this.SetValue(IchimokuMode.Tenkansen, 0, this.GetAverage(this.FTenkanSen, 0));
    }

    if (this.FCount >= this.FKijunSen)
    {
        //2 line
        this.SetValue(IchimokuMode.Kijusen, 0, this.GetAverage(this.FKijunSen, 0));
        //5 line
        this.SetValue(IchimokuMode.Chinkouspan, this.FKijunSen, this.GetPrice(PriceType.Close, 0));
    }

    if (this.FCount >= this.FTenkanSen && this.FCount >= this.FKijunSen)
    {
        //3 line
        let senkouSpanA = (this.GetValue(IchimokuMode.Tenkansen, 0) + this.GetValue(IchimokuMode.Kijusen, 0)) / 2;
        this.SetValue(IchimokuMode.Senkouspana, 0, senkouSpanA);
    }

    if (this.FCount >= this.SenkouSpanB)
    {
        //4 line
        this.SetValue(IchimokuMode.Senkouspanb, 0, this.GetAverage(this.SenkouSpanB, 0));
    }
}