// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
// Контрол отображения счётчика обратного отсчета в панеле Trading Ideas

import { Resources } from "../../Commons/properties/Resources.ts";
import { TimeSpanPeriods } from "../../Utils/Time/TimeSpan.ts";
import { DateTimeLabelTemplate } from "../../templates.js";
import { Control } from "./Control.js";
import { DateTimeUtils } from "../../Utils/Time/DateTimeUtils.ts";

export let DateTimeLabel = Control.extend({
    template: DateTimeLabelTemplate,
    data: function ()
    {
        return {
            centered: false,    // необходимо ли центрирование
            marginTop: 0,       // отступ по высоте для открытой идеи
            ticks: 0,           // время в тиках для отображения
            expired: false,     // при окончании времени надпись Lifetime окрашивается в красный цвет

            days: NaN,          // отображаемое кол-во дней
            hours: NaN,         // отображаемое кол-во часов
            minutes: NaN,       // отображаемое кол-во минут
            seconds: NaN,       // отображаемое кол-во секунд  Задал им NaN, а не null потому что null >= 0 и при открытии идеи на секунду показывается кружочек секунд 

            lifeTimeLabel: '',  // Lifetime надпись
            lifeTimeExpiredLabel: '',
            daysLabel: '',      // надпись - days
            hoursLabel: '',     // надпись - hours
            minutesLabel: '',   // надпись - minutes
            secondsLabel: ''    // надпись - seconds
        };
    },

    daysLocKey: 'dayTimeLabel.days',    // ключи локализации надписей plural
    hoursLocKey: 'dayTimeLabel.hours',
    minutesLocKey: 'dayTimeLabel.minutes',
    secondsLocKey: 'dayTimeLabel.seconds',

    dayLocKey: 'dayTimeLabel.day',      // ключи локализации в единственном числе 
    hourLocKey: 'dayTimeLabel.hour',
    minuteLocKey: 'dayTimeLabel.minute',
    secondLocKey: 'dayTimeLabel.second',

    daysLabelLocalized: '',             // Resources.getResource вызываем в localize и храним потом переводы в этих переменных
    hoursLabelLocalized: '',
    minutesLabelLocalized: '',
    secondsLabelLocalized: '',

    dayLabelLocalized: '',
    hourLabelLocalized: '',
    minuteLabelLocalized: '',
    secondLabelLocalized: '',
    
    lifeTimeLocKey: 'dayTimeLabel.lifeTime',
    lifeTimeExpiredLocKey: 'dayTimeLabel.lifeTimeExpired'
});

DateTimeLabel.prototype.oninit = function ()
{
    Control.prototype.oninit.apply(this);

    this.observe('ticks', this.onTicksChanged, { init: false })
    this.localize()
};

DateTimeLabel.prototype.localize = function ()
{
    this.daysLabelLocalized = Resources.getResource(this.daysLocKey)
    this.hoursLabelLocalized = Resources.getResource(this.hoursLocKey)
    this.minutesLabelLocalized = Resources.getResource(this.minutesLocKey)
    this.secondsLabelLocalized = Resources.getResource(this.secondsLocKey)
    
    this.dayLabelLocalized = Resources.getResource(this.dayLocKey)
    this.hourLabelLocalized = Resources.getResource(this.hourLocKey)
    this.minuteLabelLocalized = Resources.getResource(this.minuteLocKey)
    this.secondLabelLocalized = Resources.getResource(this.secondLocKey)

    this.set({
        lifeTimeLabel: Resources.getResource(this.lifeTimeLocKey),
        lifeTimeExpiredLabel: Resources.getResource(this.lifeTimeExpiredLocKey),
        daysLabel: this.daysLabelLocalized,
        hoursLabel: this.hoursLabelLocalized,
        minutesLabel: this.minutesLabelLocalized,
        secondsLabel: this.secondsLabelLocalized
    })
};

DateTimeLabel.prototype.onTicksChanged = function (ticks)
{
    if (ticks && ticks.length) return   // #94294

    let difTime = new Date(ticks),
        utsShiftTime = difTime.getTimezoneOffset() * 60000,
        resultTime = new Date(ticks + utsShiftTime),
        timeLeft = ticks > TimeSpanPeriods.TicksPerSecond

    if (timeLeft)
    {
        let dateTimeStr = DateTimeUtils.formatDate(resultTime, "DD:HH:mm:ss"),
            dateTimeArr = dateTimeStr.split(':')
        
        let days = parseInt(dateTimeArr[0]) - 1,
            hours = parseInt(dateTimeArr[1]),
            minutes = parseInt(dateTimeArr[2]),
            seconds = parseInt(dateTimeArr[3])

        this.correctLabelEndings(days, hours, minutes, seconds)

        this.set({ days: days, hours: hours, minutes: minutes, seconds: seconds })
    } 

    this.set('expired', !timeLeft)
};

DateTimeLabel.prototype.correctLabelEndings = function (days, hours, minutes, seconds)
{
    if (days < 2)
        this.set('daysLabel', days == 1 ? this.dayLabelLocalized : this.daysLabelLocalized)

    if (hours < 2)
        this.set('hoursLabel', hours == 1 ? this.hourLabelLocalized : this.hoursLabelLocalized)

    if (minutes < 2)
        this.set('minutesLabel', minutes == 1 ? this.minuteLabelLocalized : this.minutesLabelLocalized)

    if (seconds < 2)
        this.set('secondsLabel', seconds == 1 ? this.secondLabelLocalized : this.secondsLabelLocalized)
};