// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts";
import { TerceraCollapseLinkTemplate } from "../../templates.js";
import { Control } from "./Control.js";
import { ThemeManager } from "../misc/ThemeManager.ts";

export let TerceraCollapseLink = Control.extend({
	data: function () {
		return {
			width:0,
			height: 16,
			collapsed: true,
			hover: false,
			color: '',
			text: '',
			image: '',
			locKey: ''
		}
	},
	template: TerceraCollapseLinkTemplate
});

TerceraCollapseLink.prototype.oninit = function ()
{
	Control.prototype.oninit.apply(this);

	this.on('click', this.onClick);
	this.on('mouseOver', this.onMouseOver);
	this.on('mouseLeave', this.onMouseLeave);

	this.observe('hover', this.updateColor);
	this.observe('collapsed', this.updateImage);
	this.observe('locKey', this.updateText);

	ThemeManager.onThemeChange.Subscribe(this.themeChange, this);
	Resources.onLocaleChange.Subscribe(this.localize, this);
};

TerceraCollapseLink.prototype.dispose = function ()
{
	ThemeManager.onThemeChange.UnSubscribe(this.themeChange, this);
	Resources.onLocaleChange.UnSubscribe(this.localize, this);

    Control.prototype.dispose.apply(this);
};

TerceraCollapseLink.prototype.onMouseOver = function ()
{
	this.set('hover', true);
	return false;
};

TerceraCollapseLink.prototype.onMouseLeave = function ()
{
	this.set('hover', false);
};

TerceraCollapseLink.prototype.onClick = function ()
{
	this.toggle('collapsed');
	return false;
};

TerceraCollapseLink.prototype.updateImage = function ()
{
	var image = ThemeManager.getImageFullUrl(
		this.get('collapsed') ?
		'common_icons/16x16_chevron_horizontal_show_hover.png':
		'common_icons/16x16_chevron_horizontal_hide_default.png'
	);

	this.set('image', image);
};

TerceraCollapseLink.prototype.updateColor = function ()
{
	var color = ThemeManager.CurrentTheme[
		this.get('hover') ?
		"OE_HoveredLabelTextColor" :
		"OE_RegularLabelTextColor"
	];

	this.set('color', color);
};

TerceraCollapseLink.prototype.updateText = function ()
{
	this.set('text', Resources.getResource(this.get('locKey')));
};

TerceraCollapseLink.prototype.themeChange = function ()
{
	this.updateImage();
	this.updateColor();
};

TerceraCollapseLink.prototype.localize = function ()
{
	this.updateText();
};