// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts";
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";
import { HtmlScroll } from "../../Commons/HtmlScroll.js";
import { FilesHelper } from "../../Utils/FilesHelper.ts";
import { ExportScreenTemplate } from "../../templates.js";
import { TerceraButton } from "../elements/TerceraButton.ts";
import { TerceraCheckBox } from "../elements/TerceraCheckBox.js";
import { PanelLocKeys } from "../UtilsClasses/FactoryConstants.ts";
import { TerceraWindowBase } from "./TerceraWindowBase.js";
import { DateTimeUtils } from "../../Utils/Time/DateTimeUtils.ts";
import { TerceraButtonStyle } from "../../Utils/Enums/ButtonEnums.ts";

export let ExportScreen = TerceraWindowBase.extend({
    data: function ()
    {
        return {
            movable: true,
            resizable: false,
            showFooter: false,
            focused: true,

            Checked: false,

            width: 273,
            height: 425,
            zIndex: 300,

            cbItems: [],

            btnOKStyle: TerceraButtonStyle.Blue,
            btnCancelStyle: TerceraButtonStyle.Standard,
            style_addition_header: 'js-ExportScreen-AdditionalHeader',

            exportType: ExportScreen.CSV,
            saveFileName: '',
            cbFormatTooltip: 'screen.export.selectFormat.tt',
            checkAllText: Resources.getResource('screen.export.checkAllText')
        };
    },
    partials: { bodyPartial: ExportScreenTemplate },
    chbArray: null
});

let instance = null;

ExportScreen.prototype.getType = function () { return 'ExportScreen' };

ExportScreen.prototype.oninit = function ()
{
    TerceraWindowBase.prototype.oninit.apply(this);

    this.chbArray = new Array();

    this.localize();
};

ExportScreen.prototype.localize = function ()
{
    this.set({
        header: Resources.getResource('screen.export.header'),

        okText: Resources.getResource('screen.export.Export'),
        cancelText: Resources.getResource('screen.export.Cancel'),
    });
};

ExportScreen.prototype.oncomplete = function ()
{
    TerceraWindowBase.prototype.oncomplete.apply(this);

    this.on('cancelClick', this.close.bind(this));
    this.on('okClick', this.okClick.bind(this));
    this.on('ExportTypeChanged', this.exportTypeChanged.bind(this));
    this.on("onCheckedStateChange", this.onCheckAllStateChange.bind(this));

    let keys = ExportScreen.exportTo, items = [];
    keys.forEach(function (key)
    {
        if (!Resources.isHidden(key))
            items.push({ text: Resources.getResource(key), value: key });
    })
    this.set({ cbItems: items });
    this.center();
};

ExportScreen.prototype.okClick = function ()
{
    this.exportToFile()
    this.close()
}

ExportScreen.prototype.exportToFile = function ()
{
    var data = this.getDataToExport(),
        filename = this.get('saveFileName')

    switch (this.get('exportType'))
    {
        case ExportScreen.CSV:
            this.exportToCSVFile(filename, data)
            break
        case ExportScreen.EXCEL:
            this.exportToExcelFile(filename, data)
            break
    }
}

ExportScreen.prototype.exportToCSVFile = function (filename, data)
{
    let preparedForCSV = this.prepareForCSVExport(data)

    FilesHelper.saveFile(filename, preparedForCSV)
}

ExportScreen.prototype.exportToExcelFile = function (filename, data)
{
    if (!data || !data.length)
        return

    let table = [{
        Data: data,
        TableName: filename
    }]

    FilesHelper.saveExcelFile(filename, table)
}

ExportScreen.prototype.getDataToExport = function ()    // return Array of Arrays
{
    let qt = this.panel.quickTableRactive.quickTable,
        rows = qt.rowsArray,
        cols = qt.sortedColumns,
        allCols = qt.sortedColumns,
        colsID = this.getColsIDToExport(),
        result = []

    if (!colsID.length)
        return result

    let headers = []
    for (let j = 0; j < colsID.length; j++)
        headers.push(cols[colsID[j]].headerText)
    result.push(headers)

    let fuckingReplaceSymbol = String.fromCharCode(160);// не переносимый пробел нужно заметь на обычный
    let normalSymbol = String.fromCharCode(32);// не переносимый пробел нужно заметь на обычный
    for (let i = 0; i < rows.length; i++)
    {
        let row = rows[i]
        if (row.visible)
        {
            let rowToExport = []
            for (let j = 0; j < colsID.length; j++)
            {
                let ind = cols[colsID[j]].PRIVATE.index,
                    cell = row.cells[ind]
                rowToExport.push(cell.formattedValue.replaceAll(fuckingReplaceSymbol, normalSymbol))
            }
            result.push(rowToExport)
        }
    }

    return result
}

ExportScreen.prototype.prepareForCSVExport = function (arrOfArrays)
{
    let result = ''

    if (!arrOfArrays.length)
        return result

    for (let i = 0; i < arrOfArrays.length; i++)
    {
        let row = arrOfArrays[i]
        for (let j = 0; j < row.length; j++)
            result += row[j] + ';'
        result += "\r\n"
    }

    return result
}

ExportScreen.prototype.getColsIDToExport = function ()
{
    let colsToExport = [];
    let cbList = this.chbArray;

    for (let i = 0; i < cbList.length; i++)
    {
        let cb = cbList[i]
        if (cb.get('checked'))
            colsToExport.push(cb.get('tag'))
    }

    return colsToExport
}

ExportScreen.prototype.populateCheckBoxList = function (table)
{
    let cols = table.sortedColumns

    let cbListPanel = this.Controls.checkBoxList

    let cbNum = 0
    let chCount = 0
    let f = this.oneCheck.bind(this);

    for (let i = 0; i < cols.length; i++)
        if (!cols[i].hidden)
        {
            let colCheckBox = new TerceraCheckBox();

            colCheckBox.set({
                top: 3 + cbNum * 24,
                left: 12,
                text: cols[i].headerText,
                checked: cols[i].visible,
                tag: i
            });

            if (cols[i].visible)
                chCount++

            cbListPanel.addControl(colCheckBox)
            this.chbArray.push(colCheckBox);
            colCheckBox.on("CheckedStateChange", f)

            cbNum++
        }


    this.setInternalAllChecked(cbNum === chCount);

    let div = this.find('.js-checkboxlist-panel')
    if (div) HtmlScroll.addScroll(div);
}

ExportScreen.prototype.setInternalAllChecked = function (value)
{
    //TODO to think
    this.internalChange = true;
    this.set("Checked", value).then(function () { this.internalChange = false; }.bind(this))
}

ExportScreen.prototype.oneCheck = function (sender)
{
    if (this.internalChange)
        return;

    let cbList = this.chbArray;
    let res = true;

    for (let i = 0; i < cbList.length; i++)
        res = res && cbList[i].get('checked')

    if (this.get("Checked") !== res)
        this.setInternalAllChecked(res)
}

ExportScreen.prototype.onCheckAllStateChange = function (context, value)      // Check all CheckBox's logic
{
    //TODO to think
    if (this.internalChange)
        return;

    let cbList = this.chbArray;

    this.internalChange = true;

    for (let i = 0; i < cbList.length; i++)
        cbList[i].set('checked', value)

    this.internalChange = false;

}

ExportScreen.prototype.exportTypeChanged = function (context, value)
{
    this.set('exportType', value)
    this.set('saveFileName', this.generateFileName())
}

ExportScreen.prototype.updateControls = function (panel)
{
    this.panel = panel

    let qtr = panel.quickTableRactive                                                                   // Check box list                                          
    if (qtr && qtr.quickTable)
        this.populateCheckBoxList(qtr.quickTable);

    this.set('saveFileName', this.generateFileName())
}

ExportScreen.prototype.generateFileName = function ()
{
    let dateStr = DateTimeUtils.formatDate(new Date(), 'DD.MM.YYYY_HH-mm-ss'),
        fileType = this.get('exportType') == ExportScreen.CSV ? '.csv' : '', // adding extension for Excel in FilesHelper.saveExcelFile
        fileName = Resources.getResource(PanelLocKeys[this.panel.getType()]) + '_' + dateStr + fileType

    return fileName
}

ExportScreen.show = function (panel)
{
    if (instance)
        instance.close()

    instance = new ExportScreen();
    instance.on('teardown', function ()
    {
        instance = null;
    });
    MainWindowManager.MainWindow.addControl(instance);

    instance.updateControls(panel);
    instance.setFocus();
};

ExportScreen.CSV = "screen.export.CSV";
ExportScreen.EXCEL = "screen.export.Excel";

ExportScreen.exportTo = [ExportScreen.CSV, ExportScreen.EXCEL]