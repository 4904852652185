// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../Commons/properties/Resources.ts';
import { PanelSettingsScreen } from '../screen/PanelSettingsScreen.js';
import { AlertAction, AlertActions, AlertSubImage } from '../../Utils/Alert/AlertConstants.ts';
import { AlertPanelTopTemplate } from '../../templates.js';
import { AlertItem } from '../cache/AlertItem.ts';
import { PanelNames } from '../UtilsClasses/FactoryConstants.ts';
import { ApplicationPanelNew } from './ApplicationPanelNew.js';
import { TerceraMenu } from '../elements/TerceraMenu.ts';
import { GeneralSettings } from '../../Utils/GeneralSettings/GeneralSettings.ts';
import { DataCache } from '../../Commons/DataCache.ts';
import { SessionSettings } from '../../Commons/SessionSettings.ts';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager.ts';
import { RulesSet } from '../../Utils/Rules/RulesSet.ts';

export let AlertPanel = ApplicationPanelNew.extend(
    {
        topPanelHeight: 25,
        data: function ()
        {
            return {
                width: 390,
                height: 533,
                isAccountLinkShow: false,
                isSymbolLinkShow: false,
                dockablePanel: false,
                showHeader: true,
                showFooter: false,
                resizable: false,
                movable: false,
                canAddAlert: true,
                closeBtnVisible: false,
                style_addition_header: 'js-PropertySetupScreen-AdditionalHeader',
                btnText: '',
                btnTooltip: '',
                btnDisabledTooltip: '',
                canLinkByAccount: false
            }
        },
        partials: {
            bodyPartial: AlertPanelTopTemplate
        },
        headerLocaleKey: 'screen.Alerts',
        rangeSelectPanel: null,
        skipOnSave: true,
        AlertBTN: null
    });

AlertPanel.prototype.getType = function () { return PanelNames.AlertPanel };

AlertPanel.prototype.accountLink_In = function () { };  // Remove account link logic for alerts
AlertPanel.prototype.accountLink_Out = function () { }; // https://tp.traderevolution.com/entity/119860
AlertPanel.prototype.hasAccountLookup = function () { return true; };  // it is not true but for overriding ApplicationPanelNew.prototype.hasAccountLookup

AlertPanel.prototype.oncomplete = function ()
{
    ApplicationPanelNew.prototype.oncomplete.apply(this)

    this.AlertBTN = MainWindowManager.MainWindow.Controls.alerts;
    this.on('onAddClick', this.onAdd);
    this.observe('visible', function (val) { this.AlertBTN.set('focused', val); });
    this.populateItems();

    DataCache.AlertManager.OnAlertAdded.Subscribe(this.alertsAdded, this);
    DataCache.AlertManager.OnAlertRemoved.Subscribe(this.alertsRemoved, this);

    const qt = this.getQuickTable();

    qt.OnPaintedPictureButtonClick.Subscribe(this.onPaintedPictureButtonClick, this);
    qt.lockManualSorting = true;

    this.populateTableContextMenu();
}


AlertPanel.prototype.populateTableContextMenu = function ()
{
    var items = [];

    items.push(
        {
            text: Resources.getResource('screen.Alerts.Settings.ContextMenu'),
            event: this.ShowProperties.bind(this)
        }
    );

    this.getQuickTable().setTableContextMenuItems(items);
    this.menuTagDict = TerceraMenu.createTagDictionary(items);
};

AlertPanel.prototype.ShowProperties = function ()
{
    PanelSettingsScreen.EditProperties(this, null, Resources.getResource('screen.Alerts.Settings.Screen'))
}

AlertPanel.prototype.Properties = function ()
{
    // let properties = ApplicationPanelNew.prototype.Properties.call(this);
    // properties = properties.concat(SessionSettings.GetAlertPanelProperties())
    return SessionSettings.GetAlertPanelProperties();
};

AlertPanel.prototype.callBack = function (newProperties)
{
    // ApplicationPanelNew.prototype.callBack.call(this, newProperties);
    SessionSettings.AlertPanelCallBack(newProperties)
    SessionSettings.save();
};

AlertPanel.prototype.alertsAdded = function (alert)
{
    let alertItem = new AlertItem(alert);
    this.getQuickTable().AddItem(alertItem);
}

AlertPanel.prototype.alertsRemoved = function (alert)
{
    this.getQuickTable().RemoveRow(alert.AlertId)
}

AlertPanel.prototype.populateItems = function (alert)
{
    const qt = this.getQuickTable();
    const alerts = DataCache.AlertManager.GetAllAlerts();
    const hideOE = Resources.isHidden('screen.Alerts.Action.PlaceOrder');
    for (let i = 0; i < alerts.length; i++)
    {
        const alert = alerts[i];
        const skipAdding = alert.Action === AlertAction.PLACE_ORDER_NOTIFY && hideOE;
        if (skipAdding) { continue; }

        const alertItem = new AlertItem(alert);
        qt.AddItem(alertItem);
    }
}

AlertPanel.prototype.canAddRow = function ()
{
    const count = DataCache.getRuleNumberValueForMyUser(RulesSet.VALUE_TOTAL_MAX_ALERTS);
    if (count === -1) { return true; }

    if (count <= this.rowCount) { return false; }

    return true;
}

AlertPanel.prototype.updatePanelHeader = function ()
{
    ApplicationPanelNew.prototype.updatePanelHeader.apply(this);

    this.set('canAddAlert', this.canAddRow());
}

AlertPanel.prototype.populateItemsDirect = function ()
{
    ApplicationPanelNew.prototype.populateItemsDirect.apply(this);

    const trades = DataCache.newTradesDict;
    const qt = this.getQuickTable();

    Object.values(trades).forEach(trade => qt.AddItem(new AlertItem(trade)));
}

AlertPanel.prototype.onAdd = function ()
{
    let panel = MainWindowManager.Factory.addPanel(PanelNames.CreateAlertPanel);
    panel.set({
        left: this.get('left'),
        top: this.get('top'),
    });
    panel.CallerPanel = this;
    panel.setFocus();
    this.AlertBTN.set('focused', true);
}

AlertPanel.prototype.onPaintedPictureButtonClick = function (data, event)
{
    let alert = data.row.item.alert;

    if (data.subItemIndex === AlertSubImage.PLAY_STOP)
        DataCache.AlertManager.MakeAlertAction(alert, (alert.Active ? AlertActions.STOP : AlertActions.START))

    if (data.subItemIndex === AlertSubImage.UPDATE)
    {
        let panel = MainWindowManager.Factory.addPanel(PanelNames.CreateAlertPanel);
        panel.set({ left: this.get('left'), top: this.get('top') });
        panel.setUpdateParams(alert);
        panel.CallerPanel = this;
        panel.setFocus();
        this.AlertBTN.set('focused', true);
    }

    if (data.subItemIndex === AlertSubImage.CLOSE)
    {
        DataCache.AlertManager.RemoveAlertProcess(alert, this.setFocus.bind(this));

        this.set({ visible: true });
    }

    return null;
}

AlertPanel.prototype.dispose = function ()
{
    DataCache.AlertManager.OnAlertAdded.UnSubscribe(this.alertsAdded, this);
    DataCache.AlertManager.OnAlertRemoved.UnSubscribe(this.alertsRemoved, this);
    // DataCache.AlertManager.OnAlertUpdated.UnSubscribe(this.alertsUpdated, this);
    ApplicationPanelNew.prototype.dispose.apply(this);
}

AlertPanel.prototype.localize = function ()
{
    ApplicationPanelNew.prototype.localize.apply(this);

    this.set({
        btnText: Resources.getResource('screen.Alerts.AddAlert'),
        btnTooltip: Resources.getResource('screen.Alerts.AddAlert.Tooltip'),
        btnDisabledTooltip: Resources.getResource('screen.Alerts.AddAlert.Disabled.Tooltip')
    });
}

AlertPanel.prototype.SetColumnsDefaultDisplayIndex = function (table)
{
    table.columns[AlertItem.INSTRUMENT_COL_INDEX].displayedIndex = 1;
    table.columns[AlertItem.CONDITION_COL_INDEX].displayedIndex = 2;
    table.columns[AlertItem.ACCOUNT_COL_INDEX].displayedIndex = 3;
    table.columns[AlertItem.EDIT_BUTTONS_COL_INDEX].displayedIndex = 4;
}

AlertPanel.prototype.lostFocus = function ()
{
    ApplicationPanelNew.prototype.lostFocus.apply(this)
    this.close();
}

AlertPanel.prototype.close = function ()
{
    this.set({ visible: false });
}

AlertPanel.prototype.getProperties = function ()
{
    const { NotificationVariable, AlertTypeVariable, ConditionVariable, ImportanceVariable, AfterExecuteVariable } = GeneralSettings.Alert;
    return { NotificationVariable, AlertTypeVariable, ConditionVariable, ImportanceVariable, AfterExecuteVariable };
}