import { type TerceraChartNumberScaleRenderer } from '../Renderers/Scales/TerceraChartNumberScaleRenderer';
import { XYPointsConverter } from '../Utils/PointsConverter/XYPointsConverter';
import { TerceraChartWindowBase } from './TerceraChartWindowBase';

export class TerceraChartXYWindow extends TerceraChartWindowBase {
    public FminFloatX: number;
    public FmaxFloatX: number;

    public FminXTotal: number;
    public FmaxXTotal: number;

    constructor (rightYScaleRenderer: TerceraChartNumberScaleRenderer, leftYScaleRenderer: TerceraChartNumberScaleRenderer) {
        super(rightYScaleRenderer, leftYScaleRenderer);
        this.PointsConverter = new XYPointsConverter(this);
        this.rightYScaleRenderer.window = this;
        this.leftYScaleRenderer.window = this;
        this.IsMainWindow = true;
    }

    public override CalcScales (): boolean {
        this.CalcXScale();
        return super.CalcScales();
    }

    public override ZoomIn (): boolean {
        if (!this.CanZoomIn()) {
            return;
        }
        const dx = (this.FmaxFloatX - this.FminFloatX) / 4.0;
        const dy = (this.FmaxFloatY - this.FminFloatY) / 4.0;
        this.ProcessZoomIn(dx, dy);
        this.CalcXScale();
    }

    public override CanZoomIn (): boolean {
        return Math.ceil((this.FmaxXTotal - this.FminXTotal) / (this.FmaxFloatX - this.FminFloatX)) < 20;
    }

    public override ZoomOut (): void {
        if (!this.CanZoomOut()) {
            return;
        }
        const dx = (this.FmaxFloatX - this.FminFloatX) / 2.0;
        const dy = (this.FmaxFloatY - this.FminFloatY) / 2.0;
        this.ProcessZoomOut(dx, dy);
        this.CalcXScale();
    }

    public override CanZoomOut (): boolean {
        return (this.FmaxFloatX - this.FminFloatX) < (this.FmaxXTotal - this.FminXTotal);
    }

    public override ZoomX (delta: number): boolean {
        if (delta === 0) {
            return false;
        }
        const isZoomIn = delta < 0;
        const absDelta = Math.abs(delta * (this.FmaxFloatX - this.FminFloatX) / 2);
        if (isZoomIn) {
            if (this.CanZoomIn()) {
                this.ProcessZoomIn(absDelta, 0);
            }
            return true;
        } else {
            if (this.CanZoomOut()) {
                this.ProcessZoomOut(absDelta, 0);
            }
            return true;
        }
    }

    public override ZoomY (delta: number): boolean {
        if (delta === 0) {
            return false;
        }
        this.AutoScale = false;
        const isZoomIn = delta < 0;
        const absDelta = Math.abs(delta * (this.FmaxFloatY - this.FminFloatY) / 2);
        if (isZoomIn) {
            this.ProcessZoomIn(0, absDelta);
        } else {
            this.ProcessZoomOut(0, absDelta);
        }
        return true;
    }

    public override MoveX (delta: number): boolean {
        delta /= this.XScale;

        if (this.FminFloatX + delta < this.FminXTotal || this.FmaxFloatX + delta > this.FmaxXTotal) {
            return false;
        }

        if (this.FminFloatX + delta < this.FminXTotal) {
            delta = this.FminXTotal - this.FminFloatX;
        }
        this.FminFloatX = this.FminFloatX + delta;
        this.FmaxFloatX = this.FmaxFloatX + delta;
        return true;
    }

    public override SetMinMaxYScale (newFmaxFloatY: number, newFminFloatY: number, converter: any = undefined): void {
        super.SetMinMaxYScale(newFmaxFloatY, newFminFloatY, converter);
    }

    public SetMinMaxXScale (newFminFloatX: number, newFmaxFloatX: number): void {
        this.FminXTotal = this.FminFloatX = newFminFloatX;
        this.FmaxXTotal = this.FmaxFloatX = newFmaxFloatX;
    }

    private CalcXScale (): void {
        if (this.FmaxFloatX === this.FminFloatX) {
            this.XScale = 1;
        } else {
            this.XScale = this.ClientRectangle.Width / (this.FmaxFloatX - this.FminFloatX);
        }
    }

    private ProcessZoomIn (dx: number, dy: number): void {
        const newMinFloatX = this.FminFloatX + dx;
        const newMaxFloatX = this.FmaxFloatX - dx;
        const correctedMinMax = this.CorrectMinMaxXForZoomIn(newMinFloatX, newMaxFloatX);
        this.FminFloatX = correctedMinMax.newMinFloatX;
        this.FmaxFloatX = correctedMinMax.newMaxFloatX;
        this.SetMinMaxYScale(this.FmaxFloatY - dy, this.FminFloatY + dy);
    }

    private ProcessZoomOut (dx: number, dy: number): void {
        const newMinFloatX = this.FminFloatX - dx;
        const newMaxFloatX = this.FmaxFloatX + dx;
        const correctedMinMax = this.CorrectMinMaxXForZoomOut(newMinFloatX, newMaxFloatX);
        this.FminFloatX = correctedMinMax.newMinFloatX;
        this.FmaxFloatX = correctedMinMax.newMaxFloatX;
        this.SetMinMaxYScale(this.FmaxFloatY + dy, this.FminFloatY - dy);
    }

    private CorrectMinMaxXForZoomOut (newMinFloatX: number, newMaxFloatX: number): { newMinFloatX: number, newMaxFloatX: number } {
        let dx = 0;
        let minX = newMinFloatX;
        let maxX = newMaxFloatX;

        if (newMinFloatX < this.FminXTotal) {
            dx = this.FminXTotal - minX;
            minX += dx;
            maxX = Math.min(maxX + dx, this.FmaxXTotal);
        }
        if (newMaxFloatX > this.FmaxXTotal) {
            dx = maxX - this.FmaxXTotal;
            maxX -= dx;
            minX = Math.max(minX - dx, this.FminXTotal);
        }
        return {
            newMinFloatX: minX,
            newMaxFloatX: maxX
        };
    }

    private CorrectMinMaxXForZoomIn (newMinFloatX: number, newMaxFloatX: number): { newMinFloatX: number, newMaxFloatX: number } {
        return {
            newMinFloatX: Math.min(newMinFloatX, this.FmaxFloatX),
            newMaxFloatX: Math.max(newMaxFloatX, this.FminFloatX)
        };
    }

    public CalculateMovingXOffset (movingDeltaX: number, width: number): number {
        return movingDeltaX * (this.FmaxXTotal - this.FminXTotal) / width + this.FminXTotal;
    }
}
