// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DateTimeUtils } from '../../Utils/Time/DateTimeUtils';
import { SoundManager } from '../../Utils/SoundManager';
import { AlertUtils } from '../../Utils/Alert/AlertUtils';
import { AlertAction, AlertActions, AlertEvent, AlertStatus, AlertNotification, AlertTypeMap, AlertCondition, AlertConditionMap, AlertImportance, AlertAfterExecute } from '../../Utils/Alert/AlertConstants';
import { Alert, AlertSendToServerItem } from './Alert';
import { Resources } from '../properties/Resources';
import { CustomEvent } from '../../Utils/CustomEvents';
import { Connection } from '../Connection';
import { Message } from '../../Utils/DirectMessages/DirectMessagesImport';
import { OrderType } from '../../Utils/Trading/OrderType';
import { SlTpPriceType } from '../../Utils/Enums/Constants';
import { PlacedFrom } from '../../Utils/Trading/PlacedFrom';
import { Event } from './Event';
import { EventType } from './EventConstants';
import { EventCache } from './EventCache';
import { TIF } from '../../Utils/Trading/OrderTif';
import { GeneralSettings } from '../../Utils/GeneralSettings/GeneralSettings';
import { OrderUtils } from '../../Utils/Trading/OrderUtils';
import { messageBoxHandler, alertMessageScreenHandler } from '../../Utils/AppHandlers.js';
import { Quantity } from '../../Utils/Trading/Quantity';
import { ConfirmationTypesEnum } from '../../Utils/Trading/ConfirmationTypesEnum';
import { SlTpHolder } from '../../Utils/Trading/SlTpHolder';
import { OrderEditUpdateData } from '../../Utils/Trading/OrderEditUpdateData';
import { Instrument } from './Instrument';
import { Account } from './Account';

export class AlertManager {
    public DataCache: any;
    public AlertsArray: Alert[] = [];
    public AlertsDict = {};
    public ActiveAlertsDict = {}; // instrument tradebleid -> routeid -> alertsArray
    public OnAlertAdded = new CustomEvent();
    public OnAlertRemoved = new CustomEvent();
    public OnAlertUpdated = new CustomEvent();
    public OnAlertExecuted = new CustomEvent();
    public AlertsVisible = true;
    public deferMsg = [];

    constructor (dataCache) {
        this.DataCache = dataCache;
        AlertUtils.DataCache = dataCache;
    }

    // 100929
    // AlertManager.MAX_ALERT = 5; //id:99983, id:99971 пункт 2

    public GetFilteredAlerts (instrument: Instrument, account: Account): Alert[] {
        if (!this.AlertsVisible) { return []; }

        return this.GetAllAlerts().filter((alert) =>
            alert.Active && Account.IsEqualAccount(account, alert.Account) && Instrument.IsEqualInstrument(instrument, alert.Instrument)
        );
    }

    public Clear (): void {
        this.AlertsArray.forEach(function (alert) {
            alert.dispose();
        }); // прежде чем удалить ссылки на алерты нужно отписаться от котировок, на которые подписался OrderEdit когда происходило создание alert.OrderPlaceParametrs
        this.AlertsArray = [];

        this.AlertsDict = {};
        this.ActiveAlertsDict = {};
    }

    public GetAllAlerts (): Alert[] {
        return this.AlertsArray;
    }

    public AddAlert (alert: Alert): void {
        this.AlertsArray.push(alert);
        this.AlertsDict[alert.AlertId] = alert;
        this.OnAlertAdded.Raise(alert);
    }

    public MakeAlertAction (alert: Alert, alertCommand): void {
        const action = { AlertId: alert.AlertId, Command: alertCommand, Account: alert.Account };
        Connection.vendor.MakeAlertAction(action);
    }

    public ChartNewAlert (param): void {
        if (!param) { return; }

        const instrument = param.instrument;
        if (!instrument) { return; }

        const Level1 = instrument.Level1;
        if (!Level1) { return; }

        const alertType = AlertUtils.GetDefaultAlertTypeByInstrument(instrument);
        const valueFromInstrument = AlertUtils.GetCorrectPriceForAlertByInstrument(instrument, param.historyType);
        const condition = param.value > valueFromInstrument ? AlertCondition.MORE_EQUAL : AlertCondition.LESS_EQUAL;

        const messageText = AlertUtils.GetMessageText(instrument, alertType, condition, instrument.formatPrice(param.value), param.Account);

        const DataTime = new Date();
        DataTime.setHours(23);
        DataTime.setMinutes(59);
        DataTime.setSeconds(0);
        DataTime.setDate(DataTime.getDate() + 30);

        const dataToSend = new AlertSendToServerItem();
        dataToSend.Account = param.Account;
        dataToSend.Instrument = instrument;
        dataToSend.AlertType = alertType;
        dataToSend.Condition = condition;
        dataToSend.Value = param.value;
        dataToSend.Importance = param.Importance;
        dataToSend.AfterExecute = param.AfterExecute;
        dataToSend.Action = AlertAction.NOTIFY;
        dataToSend.Notification = param.Notification;
        dataToSend.Expiration = DataTime;
        dataToSend.MessageText = messageText;
        dataToSend.Active = true;
        dataToSend.leverageValue = param.leverageValue;

        this.SendAlertToServer(dataToSend);
    }

    public ReplaceAlertFromChart (alert: Alert, data): void {
    // ссаные глиномесы не могут определиться ++13th
    // +++ реально подзаебали
    // #98678
        let condition = data.Condition;
        if (condition === null) {
            condition = alert.Condition;
        }

        alert.MessageText = AlertUtils.GetMessageText(alert.Instrument, alert.AlertType, condition, alert.Instrument.formatPrice(data.Value), alert.Account);

        const dataToSend = alert.GetDataToSend();
        dataToSend.Value = data.Value;
        dataToSend.Condition = condition;
        this.SendAlertToServer(dataToSend);
    }

    public RemoveAlert (alert: Alert): void {
        const index = this.AlertsArray.indexOf(alert);
        this.AlertsArray.splice(index, 1);
        this.AlertsDict[alert.AlertId] = null;
        this.OnAlertRemoved.Raise(alert);
    }

    public RemoveAlertProcess (alert: Alert, afterExecutingCB = null, confirm = true): void {
        afterExecutingCB = afterExecutingCB || null;
        const doEvt = function () {
            this.MakeAlertAction(alert, AlertActions.DELETE);
            if (afterExecutingCB) {
                afterExecutingCB();
            }
        }.bind(this);

        const OkCb = function (showNextTime): void {
            GeneralSettings.Confirmations.updateConfirmation(confirmationType, showNextTime);
            doEvt();
        };

        const confirmationType = ConfirmationTypesEnum.AlertCancel;
        if (confirm && GeneralSettings.Confirmations.useConfirmation(confirmationType)) {
            messageBoxHandler.Show(
                Resources.getResource('screen.Alerts.Alert'),
                Resources.getResource('screen.Alerts.RemoveAlertMessage'),
                messageBoxHandler.msgType.Question,
                OkCb,
                afterExecutingCB,
                true,
                false,
                '');
        } else {
            doEvt();
        }
    }

    public NewMessage (message): void {
        if (!this.DataCache.Loaded) {
            this.deferMsg.push(message);
            return;
        }

        switch (message.Code) {
        case Message.CODE_ALERT_MESSAGE:
        case Message.CODE_REPLACE_ALERT_MESSAGE:
            this.ProcessNewOrReplaceAlertMessage(message);
            break;
        case Message.CODE_CONTROL_ALERT_COMMAND_MESSAGE:
            this.ProcessControlComandMessage(message);
            break;
        }
    }

    public ProcessDeferredMessages (): void {
        for (let i = 0; i < this.deferMsg.length; i++) {
            this.NewMessage(this.deferMsg[i]);
        }

        this.deferMsg.length = 0;
    }

    public ProcessNewOrReplaceAlertMessage (message): void {
        const instrumentRequest = this.DataCache.getInstrumentByInstrumentTradableID_NFL(message.InstrumentTradableID, message.RouteId);
        instrumentRequest.then(function (instrument) {
            if (!instrument) {
                return;
            }

            const myAlert = this.GetAlert(message.AlertId);

            const paramObj = {
                Instrument: instrument,
                Account: message.AccountId != null ? this.DataCache.GetAccountById(message.AccountId.toString()) : this.DataCache.getPrimaryAccount(),
                AlertId: message.AlertId,
                AlertType: message.AlertType,
                Condition: message.AlertCondition,
                Value: message.AlertValue,
                Importance: message.AlertImportance,
                Expiration: message.Date,
                AfterExecute: message.AlertAfterExecute,
                Action: message.AlertAction,
                Notification: message.AlertNotification,
                MessageText: message.Text,
                Event: message.AlertEvent,
                IsActive: message.AlertStatus === AlertStatus.ACTIVE
            };

            this.CreateAlertEvent(message.AlertId, message.AlertEvent, message.Text);

            if (message.AlertEvent === AlertEvent.EXECUTED) {
                let messageText = message.Text;
                if (message.AlertAction === AlertAction.PLACE_ORDER_NOTIFY) {
                    let orderText = myAlert.OrderPlaceParametrs.getConfirmationText();
                    orderText = '&lt' + orderText.replace(' ?', '') + '&gt';
                    messageText = messageText + '; ' + Resources.getResource('screen.Alerts.SendOeAlert').replace('{0}', orderText);
                }

                this.AlertExecuted(message.AlertNotification, messageText, message.AlertImportance);
            }

            if (myAlert) {
                if (message.AlertEvent === AlertEvent.DELETED) { this.RemoveAlert(myAlert); } else {
                    if (myAlert.Active && !paramObj.IsActive) {
                        const EventText = Resources.getResource('alert.event.text.stopped').replace('{0}', paramObj.AlertId).replace('{1}', paramObj.MessageText);
                        const ActionText = 'alert.event.header.stopped';

                        const ev = new Event({
                            DateTime: DateTimeUtils.DateTimeUtcNow(),
                            Action: ActionText,
                            Description: EventText,
                            EventType: EventType.System
                        });

                        EventCache.Add(ev);
                    }
                    this.UpDateAlert(myAlert, paramObj);
                    if (message.OrderPlaceParametrs) {
                        const me = this;
                        this.OrderPlaceParametrsMsgRestore(message.OrderPlaceParametrs).then(function (params) {
                            myAlert.OrderPlaceParametrs = params;
                            me.OnAlertUpdated.Raise(myAlert);
                        });
                    }
                }
            } else {
                const alert = new Alert(this.DataCache, paramObj);
                this.AddAlert(alert);
                if (message.OrderPlaceParametrs) {
                    const me = this;
                    this.OrderPlaceParametrsMsgRestore(message.OrderPlaceParametrs).then(function (params) {
                        alert.OrderPlaceParametrs = params;
                        me.OnAlertUpdated.Raise(alert);
                    });
                }
            }
        }.bind(this));
    }

    public CreateAlertEvent (AlertId, alertEvent, MessageText): void {
        let EventText = '';
        let ActionText = '';
        if (alertEvent === null) {
            return;
        }

        switch (alertEvent) {
        case AlertEvent.DELETED:
            EventText = Resources.getResource('alert.event.text.deleted').replace('{0}', AlertId).replace('{1}', MessageText);
            ActionText = 'alert.event.header.deleted';
            break;
        case AlertEvent.EXECUTED:
            EventText = Resources.getResource('alert.event.text.executed').replace('{0}', AlertId).replace('{1}', MessageText);
            ActionText = 'alert.event.header.executed';
            break;
        case AlertEvent.EXPIRED:
            EventText = Resources.getResource('alert.event.text.expired').replace('{0}', AlertId).replace('{1}', MessageText);
            ActionText = 'alert.event.header.expired';
            break;
        case AlertEvent.CREATED:
            EventText = Resources.getResource('alert.event.text.created').replace('{0}', AlertId).replace('{1}', MessageText);
            ActionText = 'alert.event.header.created';
            break;
        case AlertEvent.MODIFIED:
            EventText = Resources.getResource('alert.event.text.modified').replace('{0}', AlertId).replace('{1}', MessageText);
            ActionText = 'alert.event.header.modified';
            break;
        case AlertEvent.RESTARTED:
            EventText = Resources.getResource('alert.event.text.restarted').replace('{0}', AlertId).replace('{1}', MessageText);
            ActionText = 'alert.event.header.restarted';
            break;
        }

        const ev = new Event({
            DateTime: DateTimeUtils.DateTimeUtcNow(),
            Action: ActionText,
            Description: EventText,
            EventType: EventType.System
        });

        EventCache.Add(ev);
    }

    public ProcessControlComandMessage (message): void {
        const myAlert = this.GetAlert(message.AlertId);

        if (message.AlertCommand === AlertAfterExecute.STOP) {
            this.UpDateAlert(myAlert, { Active: false });
        }

        if (message.AlertCommand === AlertAfterExecute.REMOVE) {
            this.RemoveAlert(myAlert);
        }
    }

    public GetAlert (alertId): Alert | null {
        return this.AlertsDict[alertId] || null;
    }

    public UpDateAlert (alert: Alert, data): void {
        if (!alert || !data) {
            return;
        }

        if (data.AlertType !== null && data.AlertType !== undefined) {
            alert.AlertType = data.AlertType;
        }

        if (data.Condition !== null && data.Condition !== undefined) {
            alert.Condition = data.Condition;
        }

        if (data.Notification !== null && data.Notification !== undefined) {
            alert.Notification = data.Notification;
        }

        if (data.Expiration !== null && data.Expiration !== undefined) {
            alert.Expiration = data.Expiration;
        }

        if (data.Value !== null && data.Value !== undefined) {
            alert.Value = data.Value;
        }

        if (data.Importance !== null && data.Importance !== undefined) {
            alert.Importance = data.Importance;
        }

        if (data.AfterExecute !== null && data.AfterExecute !== undefined) {
            alert.AfterExecute = data.AfterExecute;
        }

        if (data.Account !== null && data.Account !== undefined) {
            alert.Account = data.Account;
        }

        if (data.Action !== null && data.Action !== undefined) {
            alert.Action = data.Action;
        }

        if (data.MessageText !== null && data.MessageText !== undefined) {
            alert.MessageText = data.MessageText;
        }

        // ссаные глиномесы не могут определиться ++13th
        // #95043
        // if (data.MoveChart)
        //     alert.MessageText = alert.Instrument.DisplayName() + ' ' +
        //         Resources.getResource('panel.watchlist.' + AlertTypeMap[alert.AlertType]) + ' ' +
        //         Resources.getResource('screen.Alerts.Condition.' + AlertConditionMap[alert.Condition]) + ' ' +
        //         alert.Instrument.formatPrice(data.Value)

        if (data.OrderPlaceParametrs) {
            alert.OrderPlaceParametrs = data.OrderPlaceParametrs;
        }

        if (data.Instrument !== null && data.Instrument !== undefined) {
            alert.Instrument = data.Instrument;
        }

        if (data.IsActive !== null && data.IsActive !== undefined) {
            alert.Active = data.IsActive;
        }

        this.OnAlertUpdated.Raise(alert);
    }

    // migration boolshit
    public createAlertFromSaveItem (alertSaveItem): AlertSendToServerItem {
        if (alertSaveItem.Condition === 4 || alertSaveItem.Condition === 5) // EQUAL || NOT_EQUAL
        {
            return null;
        }

        if (!alertSaveItem.MessageText) {
            return null;
        }

        switch (alertSaveItem.Importance) {
        case 0:// HIGH
            alertSaveItem.Importance = AlertImportance.HIGH;
            break;
        case 1:// MEDIUM
            alertSaveItem.Importance = AlertImportance.MEDIUM;
            break;
        case 2:// LOW
            alertSaveItem.Importance = AlertImportance.LOW;
            break;
        }
        switch (alertSaveItem.Action) {
        case 3:// PLACE_ORDER: 3
            alertSaveItem.Action = AlertAction.PLACE_ORDER_NOTIFY;
            break;
        default:
            alertSaveItem.Action = AlertAction.NOTIFY;
        }

        const DataTime = new Date();
        DataTime.setSeconds(0);
        DataTime.setDate(DataTime.getDate() + 30);
        alertSaveItem.Notification = AlertNotification.SHOW_POP_UP | AlertNotification.SOUND;
        alertSaveItem.Expiration = DataTime;

        const dataToSend = new AlertSendToServerItem();
        dataToSend.Instrument = this.DataCache.getInstrumentByName(alertSaveItem.Instrument);
        dataToSend.Account = alertSaveItem.Account;
        dataToSend.AlertType = alertSaveItem.AlertType;
        dataToSend.Condition = alertSaveItem.Condition;
        dataToSend.Value = alertSaveItem.Value;
        dataToSend.Importance = alertSaveItem.Importance;
        dataToSend.AfterExecute = alertSaveItem.AfterExecute;
        dataToSend.Action = alertSaveItem.Action;
        dataToSend.Notification = alertSaveItem.Notification;
        dataToSend.Expiration = alertSaveItem.Expiration;
        dataToSend.MessageText = alertSaveItem.MessageText;

        if (alertSaveItem.PlaceOrderData) {
            dataToSend.OrderPlaceParametrs = this.OrderPlaceParametrsDeSerialization(alertSaveItem.PlaceOrderData).getDataForRequest();
        }

        return dataToSend;
    }

    public AddAlertMigration (alertData): void {
    // TMP TODO
    // this.AddAlert(alertData);

        this.SendAlertToServer(alertData);
    }

    public OrderPlaceParametrsDeSerialization (OrderPlaceParametrs): any {
        const myEdit = this.DataCache.OrderParameterContainer.OrderTypes[OrderPlaceParametrs.OrderType].createOrderEditObject({
            dataCache: this.DataCache
        });
        // TODO КОСТЫЛИЩЕ!!!! ЕБАНОЕ
        OrderPlaceParametrs.instrument = this.DataCache.getInstrumentByName(OrderPlaceParametrs.InstrumentId);
        OrderPlaceParametrs.account = this.DataCache.GetAccountByIdOrName(OrderPlaceParametrs.AccountId);
        const ed = new OrderEditUpdateData(null, OrderPlaceParametrs);
        myEdit.placedFrom = PlacedFrom.WEB_ALERT;
        myEdit.updateParameters(ed);
        myEdit.needSetDefaultStopPrice = false;
        myEdit.needSetDefaultLimitPrice = false;
        myEdit.needSetDefaultPrice = false;
        myEdit.sltp.needSetDefaultSLTP = false;
        myEdit.setSLTP(OrderPlaceParametrs.sltpHolder);
        myEdit.limitPrice = OrderPlaceParametrs.limitPrice;
        myEdit.stopPrice = OrderPlaceParametrs.stopPrice;
        myEdit.trailingStop = OrderPlaceParametrs.trailingStop;
        myEdit.AlerEdit = true;

        return myEdit;
    }

    public OrderPlaceParametrsMsgRestore (OrderPlaceParametrs): any {
        if (!OrderPlaceParametrs) {
            return Promise.resolve(null);
        }

        const instrumentRequest = this.DataCache.getInstrumentByInstrumentTradableID_NFL(OrderPlaceParametrs.tradableInstrumentId, OrderPlaceParametrs.routeId);
        return instrumentRequest.then((instrument) => {
            OrderPlaceParametrs.account = this.DataCache.GetAccountByIdOrName(OrderPlaceParametrs.accountId);
            OrderPlaceParametrs.instrument = instrument;

            // let inLots = SessionSettings.displayAmountInLots();
            // Всегда с сервера в лотах
            OrderPlaceParametrs.quantity = new Quantity(OrderPlaceParametrs.quantity, true);
            const myEdit = this.DataCache.OrderParameterContainer.OrderTypes[OrderPlaceParametrs.OrderType].createOrderEditObject({
                dataCache: this.DataCache
            });

            const ed = new OrderEditUpdateData(null, OrderPlaceParametrs);
            myEdit.placedFrom = PlacedFrom.WEB_ALERT;
            myEdit.updateParameters(ed);
            myEdit.needSetDefaultStopPrice = false;
            myEdit.needSetDefaultLimitPrice = false;
            myEdit.needSetDefaultPrice = false;

            const holder = new SlTpHolder();
            myEdit.sltp.needSetDefaultSLTP = false;
            holder.StopLossLimitPriceValue = OrderPlaceParametrs.slLimitPrice;
            if (OrderPlaceParametrs.slType !== null) {
                holder.StopLossPriceType = OrderPlaceParametrs.slType;
                holder.StopLossPriceValue = OrderPlaceParametrs.slPrice || NaN;
                myEdit.skipSessionSettingsChanged = true;
                if (GeneralSettings.TradingDefaults.SetSlTpValuesInOffset || SlTpPriceType.Absolute !== OrderPlaceParametrs.slType) {
                    myEdit.sltp.sl.offsetType = GeneralSettings.TradingDefaults.ShowOffsetIn;
                    myEdit.sltp.tp.offsetType = GeneralSettings.TradingDefaults.ShowOffsetIn;
                }
            }
            if (OrderPlaceParametrs.tpType !== null) {
                holder.TakeProfitPriceType = OrderPlaceParametrs.tpType;
                holder.TakeProfitPriceValue = OrderPlaceParametrs.tpPrice || NaN;
                myEdit.skipSessionSettingsChanged = true;
                if (GeneralSettings.TradingDefaults.SetSlTpValuesInOffset || SlTpPriceType.Absolute !== OrderPlaceParametrs.tpType) {
                    myEdit.sltp.sl.offsetType = GeneralSettings.TradingDefaults.ShowOffsetIn;
                    myEdit.sltp.tp.offsetType = GeneralSettings.TradingDefaults.ShowOffsetIn;
                }
            }

            myEdit.setSLTP(holder);

            if (OrderType.Stop === OrderPlaceParametrs.OrderType) { myEdit.stopPrice = OrderPlaceParametrs.limitPrice; } else if (OrderType.TrailingStop === OrderPlaceParametrs.OrderType) { myEdit.trailingStop.value = OrderUtils.ConvertTickOffset(instrument, myEdit.trailingStop.offsetViewMode, null, OrderPlaceParametrs.trailingStopOffset); } else {
                myEdit.limitPrice = OrderPlaceParametrs.limitPrice;
                myEdit.stopPrice = OrderPlaceParametrs.stopPrice;
            }

            myEdit.tif = new TIF(OrderPlaceParametrs.tif, OrderPlaceParametrs.expire_at);
            myEdit.AlerEdit = true;

            return myEdit;
        });
    }

    public AlertExecuted (Notification, MessageText, Importance): void {
        if (Notification & AlertNotification.SHOW_POP_UP) {
            if (alertMessageScreenHandler?.Show) {
                alertMessageScreenHandler.Show(
                    Resources.getResource('screen.Alerts.Alert'),
                    MessageText,
                    Importance);
            }

            const executedObj = { Title: Resources.getResource('screen.Alerts.Alert'), AlertMessageText: MessageText, Importance };
            this.OnAlertExecuted.Raise(executedObj);
        }

        if (Notification & AlertNotification.SOUND) {
            this.PlaySound(Importance);
        }
    }

    public PlaySound (type: AlertImportance): void {
        let soundKey = null;
        switch (type) {
        case AlertImportance.HIGH:
            soundKey = SoundManager.SoundKeys.AlertHigh;
            break;
        case AlertImportance.MEDIUM:
            soundKey = SoundManager.SoundKeys.AlertMedium;
            break;
        case AlertImportance.LOW:
            soundKey = SoundManager.SoundKeys.AlertLow;
            break;
        }
        SoundManager.tryPlaySound(soundKey);
    }

    public GetActiveCount (): number {
        let counter = 0;
        const alertArray = this.GetAllAlerts();
        for (let i = 0; i < alertArray.length; i++) {
            if (alertArray[i].Active) {
                counter++;
            }
        }

        return counter;
    }

    public SendAlertToServer (alertData): void {
        Connection.vendor.SendAlertToServer(alertData);
    }

    public SortAlertToId (a, b): number {
        return b.AlertId - a.AlertId;
    }
}

export const AlertSourceMetodMap = {
    0: 'GetAsk',
    1: 'GetBid',
    2: 'GetLastPrice',
    3: 'GetChangePercent',
    4: 'GetVolume'
};
