// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';
import { type InformationMessageType } from '../../../../Utils/Enums/Constants';

export class InformationMessage extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_INFORMATION_MESSAGE, fieldSet);
    }

    public setMsgType (msgtype: string): void {
        this.setFieldValue(FieldsFactory.FIELD_INFO_MSG_TYPE, msgtype);
    }

    public setMsgTypeId (msgtype: InformationMessageType): void {
        this.setFieldValue(FieldsFactory.FIELD_INFO_MSG_TYPE_ID, msgtype);
    }

    public setContent (content: string[]): void {
        this.setFieldValue(FieldsFactory.FIELD_CONTENT, content);
    }

    public setTimeSpan (timespan: Date): void {
        this.setFieldValue(FieldsFactory.FIELD_TIMESTAMP, timespan);
    }
}
