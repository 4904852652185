// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

export let TicketData = function ()
{
    this.screenHeader = ''
    this.text = ''
    this.header = ''
    this.sortedEntries = []
    this.isTrade = false
    this.soundKey = "";
}