// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { TimeSpanPeriods } from '../../Utils/Time/TimeSpan';
import { DataCache } from '../DataCache';
import { BrowserUtils } from '../UtilsClasses/BrowserUtils';

export class HistoricalBM {
    public ID: any = null;
    public TimeStamp: any = null; // new Date() object
    public ProductID: any = null;
    public Subject: any = null;
    public Message: any = null;
    public IsUnread: any = null;
    public IsSelected: any = null;
    public ShowPopUp: any = null;
    public ResponseCases: any = null;
    public CustomButtons: any = null;
    public ClusterNode: any = null; // #111311
    public DisplayMethod: any = null;
    public colorHEXstr: any = null;
    private _category: any;

    public UpdateByBM (msg): void // arg is DirectBrokerMessage come from CODE_TEXT_MESSAGE (51)
    {
        if (!msg) {
            return;
        }

        this.ID = msg.ID;
        this.TimeStamp = msg.Date;
        this.ProductID = msg.ProductID;
        this.Subject = msg.Name;
        this.Message = msg.Text;
        this.IsUnread = msg.IsUnread;
        this.ShowPopUp = msg.ShowPopUp;
        this.ResponseCases = msg.ResponseCases;
        this.DisplayMethod = msg.DisplayMethod;
        this.CustomButtons = msg.CustomButtons;
        this.ClusterNode = msg.ClusterNode;
    }

    public UpdateByInfo (info): void // arg is HistoricalBrokerMessageInfo
    {
        if (!info) {
            return;
        }

        this.ID = info.ID;
        this.TimeStamp = info.TimeStamp;
        this.ProductID = info.ProductID;
        this.Subject = info.Subject;
        this.Message = info.Message;
        this.IsUnread = info.IsUnread;
        this.ShowPopUp = info.ShowPopUp;
        this.ResponseCases = info.ResponseCases;
        this.DisplayMethod = info.DisplayMethod;
        this.CustomButtons = info.CustomButtons;
        this.ClusterNode = info.ClusterNode;
    }

    get Date (): string {
        let result = '';
        const ts = this.TimeStamp;
        if (ts) {
            const today = new Date();
            if (ts.getDate() == today.getDate() && Math.abs(ts.getTime() - today.getTime()) <= TimeSpanPeriods.TicksPerDay) {
                result = ts.toLocaleTimeString();
            } else {
                result = ts.toLocaleDateString();
            }
        }

        return result;
    }

    get Title (): any {
        return this.Subject;
    }

    get Text (): any {
        return this.Message;
    }

    get OnlyText (): any {
        return BrowserUtils.RemoveHTMLTags(this.Message);
    }

    get Category (): any {
        if (this._category) {
            return this._category;
        }

        const product = DataCache.EntitlementManager.ProductsCache[this.ProductID];
        if (product) {
            this._category = product.Name;
            return this._category;
        }

        return '';
    }

    get InstanceOfHistoricalBM (): boolean { return true; }
}
