// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { CustomEvent } from '../../Utils/CustomEvents';
import { TerceraButton } from './TerceraButton';
import { ThemeManager } from '../misc/ThemeManager';
import { TerceraButtonEvents, TerceraButtonStyle } from '../../Utils/Enums/ButtonEnums';
import { ColorPickerScreen } from '../screen/ColorPickerScreen';

export class TerceraButtonColorPicker extends TerceraButton {
    ColorChanged = new CustomEvent();
    transparentMask: string = ThemeManager.getImageFullUrl('darkNew/web_only/colorPicker/transparent_color.png');
    transparentMaskNoColor: string = ThemeManager.getImageFullUrl('darkNew/web_only/colorPicker/transparent_color_canselled.png');

    constructor () {
        super();
    }

    oninit (): void {
        super.oninit();

        this.on(TerceraButtonEvents.onClick, this.onBtnPikerClick);
        this.observe('coloringButtonColor', this.coloringButtonColor);
    };

    coloringButtonColor (val): void {
        if (val) {
            if (val.indexOf('rgba(') !== -1) {
                const valueA = parseFloat(val.split(',')[3]);
                if (valueA === 0) {
                    void this.set({ coloringButtonImage: this.transparentMaskNoColor });
                } else {
                    void this.set({ coloringButtonImage: this.transparentMask });
                }
            } else
                if (val === 'transparent') {
                    void this.set({ coloringButtonImage: this.transparentMaskNoColor });
                } else {
                    void this.set({ coloringButtonImage: this.transparentMask });
                }
        }
    }

    onBtnPikerClick (): void {
        ColorPickerScreen.ShowScreen(
            this.lastMouseClickX,
            this.lastMouseClickY,
            this.get('coloringButtonColor'),
            this.onColorChanged.bind(this));
    };

    onColorChanged (newColor): void {
        void this.set('coloringButtonColor', newColor);
        this.fire(TerceraButtonEvents.onColorChanged, newColor);
        if (this.ColorChanged) {
            this.ColorChanged.Raise(this, newColor);
        }
    };
}

TerceraButton.extendWith(TerceraButtonColorPicker, {
    data: function () {
        return {
            terceraButtonStyle: TerceraButtonStyle.ColorPicker,
            isColoringButton: true,
            coloringButtonColor: 'transparent',
            BackText: '',
            coloringButtonImage: null
        };
    }
});
