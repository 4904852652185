// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Connection } from "../../Commons/Connection.ts";
import { Resources } from "../../Commons/properties/Resources.ts";
import { KeyCode } from "../../Commons/KeyEventProcessor.ts";
import { TerceraBaseWindowTemplate, TerceraTradingPasswordTemplate } from "../../templates.js";
import { BOTTOM_WINDOWS_MARGIN } from "../UtilsClasses/SizeConstants.ts";
import { TerceraWindowBase } from "./TerceraWindowBase.js";
import { TradingLockUtils } from "../../Utils/TradingLockUtils.ts";
import { ApplicationInfo } from "../../Commons/ApplicationInfo.ts";
import { DataCache } from "../../Commons/DataCache.ts";

// constructor
export let TerceraTradingPassword = TerceraWindowBase.extend({
    template: TerceraBaseWindowTemplate,
    data: function ()
    {
        return {
            width: 310,
            height: 190,
            left: 100,
            top: 100,
            movable: false,
            showFooter: false,
            resizable: false,
            focused: true,
            showFullscreenCloud: true,
            visibilityError: false,
            textButton: '',
            textPasswordLabel: '',
            textErrorLabel: '',
            allowPreviewPassword: false,
            tradePass: '',
            placeholderPassword: '',
            style_addition_body: 'tradingPassword-AdditionBody',
            style_addition_header: 'tradingPassword-AdditionHeader'
        };
    },
    partials: { bodyPartial: TerceraTradingPasswordTemplate },
});

TerceraTradingPassword.prototype.getType = function () { return 'TerceraTradingPassword' };

TerceraTradingPassword.prototype.oncomplete = function ()
{
    TerceraWindowBase.prototype.oncomplete.apply(this);

    this.set({ allowPreviewPassword: DataCache.AllowPreviewPassword });

    this.localize();
    this.setCorrectPlace();
    this.on("UnlockClick", this.UnlockClick);
    this.Controls.tbPwd.on("keyDown", this.OnPasswordKeyDown.bind(this));
    $(window).on('resize', this.setCorrectPlace.bind(this));
};

TerceraTradingPassword.prototype.setCorrectPlace = function ()
{
    let mWindow = $(window);
    var wWidth = mWindow.width();
    var wHeight = mWindow.height();
    let l = wWidth - 310 - 3;
    l = l > 0 ? l : 0;
    let t = wHeight - 190 - BOTTOM_WINDOWS_MARGIN - 5;
    t = t > 0 ? t : 0;
    this.set({ left: l, top: t });
}

TerceraTradingPassword.prototype.UnlockClick = function ()
{
    let pass = this.get("tradePass");

    if (!pass)
    {
        this.set({ visibilityError: true, textErrorLabel: Resources.getResource("screen.licensing.emptyFieldErrors.password") });
        return;
    }

    let ExternalResourse = null;
    if (ApplicationInfo.ExternalResourse !== -1)
        ExternalResourse = ApplicationInfo.ExternalResourse;

    Connection.vendor.sendTradePassword(pass, ExternalResourse).then(function (answer)
    {
        if (answer)
        {
            this.set({ visibilityError: false })
            TradingLockUtils.TradingLock.LockTradingByPassword = false;
            TradingLockUtils.TradingLock.updateTradingLock(false);
            this.dispose();
        }
        else
            this.set({ visibilityError: true, textErrorLabel: Resources.getResource("statusBar.passwordPanel.passwordBox.invalidPass") })
    }.bind(this));
}

TerceraTradingPassword.prototype.localize = function ()
{
    this.set({
        placeholderPassword: Resources.getResource('screen.LoginScreen.PasswordLabel'),
        textPasswordLabel: Resources.getResource('screen.UnlockTradingScreen.lockedLabel'),
        textButton: Resources.getResource('screen.UnlockTradingScreen.unlockButton'),
        textErrorLabel: Resources.getResource("statusBar.passwordPanel.passwordBox.invalidPass"),
    });
};

TerceraTradingPassword.prototype.OnPasswordKeyDown = function (e)
{
    if (e.original.keyCode === KeyCode.ENTER)
        this.UnlockClick();
};