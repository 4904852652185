import { type IHeatmapViewSettings } from '../../../../Commons/Heatmap/Settings/IHeatmapViewSettings';
import { HeatmapBaseRenderer } from './HeatmapBaseRenderer';
import { type Rectangle } from '../../../../Commons/Geometry';

export abstract class HeatmapAreaBaseRenderer extends HeatmapBaseRenderer {
    protected static readonly SELECTOR_SIGN: string = '>';
    protected areaName: string;
    protected settings: IHeatmapViewSettings;
    protected selectorSignRectangle: Rectangle;
    protected headerTextRectangle: Rectangle;
}
