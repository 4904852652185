import { Instrument } from './Instrument';

export class OptionsCache {
    private readonly _dataCache: any;
    private readonly _optionsByInstrumentIdRouteId = new Map<string, Map<number, Instrument>>();

    constructor (dataCache) {
        this._dataCache = dataCache;
    }

    public add (mess, route, underlier): void {
        const instrumentID = mess.Id;
        let instrumentsByInstrumentId = this._optionsByInstrumentIdRouteId.get(instrumentID);
        if (isNullOrUndefined(instrumentsByInstrumentId)) {
            instrumentsByInstrumentId = new Map<number, Instrument>();
            this._optionsByInstrumentIdRouteId.set(instrumentID, instrumentsByInstrumentId);
        }
        let instrument = instrumentsByInstrumentId.get(route);
        if (isNullOrUndefined(instrument)) {
            instrument = new Instrument(this._dataCache, mess, route);
            instrument.ForwardBaseInstrument = underlier;
            instrument.isFakeOption = true;
            instrumentsByInstrumentId.set(route, instrument);
        } else {
            instrument.UpdateByMessage(mess);
        }
        instrument.SourceName = mess.Name;
    }

    public clear (): void {
        this._optionsByInstrumentIdRouteId.clear();
    }

    public getOptionInstruments (): object {
        const optionInstruments = {};
        for (const instrumentId of this._optionsByInstrumentIdRouteId.keys()) {
            const instrumentsByRouteId = this._optionsByInstrumentIdRouteId.get(instrumentId);
            for (const route of instrumentsByRouteId.keys()) {
                const instrument = instrumentsByRouteId.get(route);
                optionInstruments[instrument.GetInteriorID()] = instrument;
            }
        }
        return optionInstruments;
    }

    public getFirstOptionInstrument (): Instrument {
        const firstInstrumentIdKey = this._optionsByInstrumentIdRouteId.keys()[0];
        if (isNullOrUndefined(firstInstrumentIdKey)) {
            return undefined;
        }
        const firstRouteIdKey = this._optionsByInstrumentIdRouteId.get(firstInstrumentIdKey).keys()[0];
        if (isNullOrUndefined(firstRouteIdKey)) {
            return undefined;
        }
        return this._optionsByInstrumentIdRouteId.get(firstInstrumentIdKey).get(firstRouteIdKey);
    }
}
