// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { BaseInterval } from '../../../../Utils/History/BaseInterval';
import { BaseIntervalInputParams } from '../../../../Utils/History/BaseIntervalInputParams';
import { type Interval } from '../../../../Utils/History/Interval';
import { type IHistoryMerger } from '../../../../Utils/History/IHistoryMerger';
import { type HistoryMergerInputParams } from '../../../../Utils/History/HistoryMergerInputParams';
import { type Instrument } from '../../Instrument';
import { type TradingSession } from '../../../../Utils/Session/Sessions';
import { Periods } from '../../../../Utils/History/TFInfo';
import { type DateTime } from 'luxon';

export abstract class HistoryMerger implements IHistoryMerger {
    protected baseHistory: BaseInterval[];
    protected newPeriod: number;
    protected instrumentTimeZone: number;
    protected instrument: Instrument;
    protected defaultPeriod: TradingSession;

    constructor (inputParams: HistoryMergerInputParams) {
        this.baseHistory = inputParams.BaseHistory;
        this.newPeriod = inputParams.NewPeriod;
        this.instrument = inputParams.Instrument;
        this.defaultPeriod = this.instrument.TradingSessionsList[0];
    }

    MergeHistory (): BaseInterval[] {
        const newHistory: BaseInterval[] = [];
        const isBasedByDayBars: boolean = this.newPeriod % Periods.DAY === 0 ||
            this.newPeriod % Periods.YEAR === 0;

        for (let i = 0; i < this.baseHistory.length;) {
            const baseBar: BaseInterval = this.baseHistory[i];
            // hsa: суть проблемы в том что если бары агрегируются по дневкам
            // то надо брать середину бара как базовую точку
            // потому что исторические бары могут не совпадать с настройками сессии
            // и мы берем середину бара чтобы понимать в какой день сессии попала большая часть бара
            const baseFromUtc: Date = isBasedByDayBars ? this.GetBarMiddleTimeUtc(baseBar) : baseBar.LeftTime;

            // возвращает границы агрегированного бара по времени базового бара
            const interval: Interval = this.FindIntervalBorders(baseFromUtc);

            const aggFromUtc: Date = interval.From;
            const aggToUtc: Date = interval.To;

            // кейс когда бар есть а сессии в этот день для него нет
            // например в выходные или в праздник
            // в теории такого быть не должно, на QA иногда заливает историю как попало
            if (aggFromUtc.getTime() === 0 || aggToUtc.getTime() === 0) {
                i++;
                continue;
            }

            const biInputParams = new BaseIntervalInputParams();
            biInputParams.LeftTimeTicks = aggFromUtc.getTime();
            biInputParams.correctStartTime = false;
            const aggregatedBar: BaseInterval = new BaseInterval(biInputParams, this.newPeriod);
            aggregatedBar.SessionType = baseBar.SessionType;
            aggregatedBar.FLeftTimeTicks = aggFromUtc.getTime();
            aggregatedBar.FRightTimeTicks = aggToUtc.getTime();
            aggregatedBar.LeftTime = aggFromUtc;
            aggregatedBar.RightTime = aggToUtc;

            while (i < this.baseHistory.length && this.baseHistory[i].LeftTime < aggregatedBar.RightTime) {
                aggregatedBar.MergeWith(this.baseHistory[i++], -100); // hsa: history type нужен только для агрегации тиков
            }

            newHistory.push(aggregatedBar);
        }

        return newHistory;
    }

    public abstract FindIntervalBorders (date: Date): Interval;

    private GetBarMiddleTimeUtc (bar: BaseInterval): Date {
        const leftBorder: Date = bar.LeftTime;
        const rightBorder: Date = bar.RightTime;

        const midllePoint = (leftBorder.getTime() + rightBorder.getTime()) / 2;
        return new Date(midllePoint);
    }
}
