import { type Account } from '../cache/Account';
import { EventEmitter } from 'events';

class _AccountWidgetAccountsController {
    private _Account: Account = null;
    private readonly _eventEmitter: EventEmitter = new EventEmitter();

    set Account (account: Account) {
        const prevAcc = this._Account;
        this._Account = account;
        this.OnAccountChangedEmit(account, prevAcc);
    }

    get Account (): Account {
        return this._Account;
    }

    public subscribeOnAccountChanged (callback: (accountNew: Account, accountPrew: Account) => void): void {
        this._eventEmitter.on('OnAccountChanged', callback);
    }

    public unsubscribeOnAccountChanged (callback: (accountNew: Account, accountPrew: Account) => void): void {
        this._eventEmitter.off('OnAccountChanged', callback);
    }

    public OnAccountChangedEmit (valueN: Account, valueO: Account): void {
        this._eventEmitter.emit('OnAccountChanged', valueN, valueO);
    }
}
export const AccountWidgetAccountsController = new _AccountWidgetAccountsController();
