// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

export class TerceraChartAction {
    public Type: TerceraChartActionEnum = null;
    public Details: any = null;
    // нужно ли закрывать все контекстное меню при клике на ноде 2 уровня
    public CloseOnClick = true;

    // STATIC.
    public static Create (type: TerceraChartActionEnum, details?, usualClosing?): TerceraChartAction {
        const action = new TerceraChartAction();

        action.Type = type;
        action.Details = details;
        if (usualClosing !== undefined) {
            action.CloseOnClick = usualClosing;
        }

        return action;
    }
}

export enum TerceraChartActionEnum {
    // visual trading
    CancelOrders = 0,
    CancelOrderID = 1,
    CancelOrdersSymbol = 2,
    CancelOrdersAccount = 3,
    ClosePosition = 4,
    CloseAllPosition = 5,
    RollbackPosition = 6,
    ModifyOrder = 7,
    ModifyPosition = 8,
    ChangeToMarket = 9,
    // other actions
    Cancel = 10,
    Eraser = 11,
    ScalingByRect = 12,
    CreationTool = 13,
    ToolSelector = 14,
    Settings = 15,
    Separator = 16,
    DeleteTool = 17,
    CloneTool = 18,
    DeleteHoverAndSelectedTools = 19,
    HoverAndSelectedToolSettings = 20,
    SymbolsLookup = 21,
    Style = 22,
    Agregation = 23,
    NewPanel = 24,
    Layouts = 25,
    Zoom = 26,
    AutoScale = 27,
    ManualScale = 28,
    ToolColor = 29,
    ToolLineWidth = 30,
    SetScaleDataType = 31,
    DataType = 32,
    Indicator = 33,
    View = 34,
    InfoWindowHide = 35,
    InfoWindowShortMode = 36,
    InfoWindowSettings = 37,
    InfoWindowDock = 38,
    PlateOverlayVisible = 39,
    PlateOverlayRemove = 40,
    PlateOverlaySettings = 41,
    PlateIndicatorVisible = 42,
    PlateIndicatorDublicate = 43,
    PlateIndicatorRemove = 44,
    PlateIndicatorEdit = 45,
    PlateIndicatorSettings = 46,
    Overlay = 47,
    Export = 48,
    TimeSeparators = 49,
    ShowHoles = 50,
    ShowExtendedSession = 51,
    SnapToCandle = 52,
    SetStayInDrawingMode = 53,
    HistoryManager = 54,
    DeleteAllTools = 55,
    DeleteToolsByType = 56,
    PriceIndicator = 57,
    DayHighLow = 58,
    PrevClose = 59,
    EditBars = 60,
    // Settings
    FitIndicators = 61,
    FitOrders = 62,
    FitDayHighLow = 63,
    FitDrawings = 64,
    ObjectManager = 65,
    Refresh = 66,
    // Bar statistics
    BarStatisticsColumnVisibility = 67,
    AllPriceVisibility = 68,
    AllPriceSettings = 69,
    VolumeProfileVisibility = 70,
    VolumeProfileSettings = 71,
    BarStatisticsVisibility = 72,
    BarStatisticsSettings = 73,
    SelectedToolSettings = 74,
    DeleteSelectedTools = 75,
    VolumeMarker = 76,
    VolumeBarsVisibility = 77,
    VolumeBarsSettings = 78,
    SesonalitySettings = 79,
    SesonalityVisibility = 80,
    TimeAndSalesBarAnalysisVisibility = 81,
    TimeAndSalesBarAnalysisSettings = 82,
    AnalyseToolsGroup = 83,
    BarPatternVisibility = 84,
    BarPatternSettings = 85,
    MainSymbol = 86,
    OrderEntry = 87,
    ChartTrading = 88,
    CancelOrderSLTP = 89,
    FitAlerts = 90,
    DrawingToolsGroup = 91,
    ZoomX = 92,
    ZoomY = 93,
    ToBegin = 94
}

export enum TerceraChartToolbarsEnum {
    PreviousCloseLine = 0,
    TimeToNextBar = 1,
    DayHighLow = 2,
    InfoVindow = 3,
    Volume = 4,
    GridTime = 5,
    GridPrice = 6,
    DrawingToolBar = 7,
    MainToolBar = 8,
    ScrollBar = 9,
    OrderEntry = 10,
    AnalyseToolBar = 11,
    AllPricePanel = 12,
    TimeAndSalesPanel = 13,
    ChartTrading = 14
};

export enum CancelActionContext {
    Esc = 0,
    RightButton = 1,
    Unknown = 2
}
