// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { GeneralSettings } from '../../Utils/GeneralSettings/GeneralSettings';
import { SessionSettings } from '../../Commons/SessionSettings';
import { type Instrument } from '../../Commons/cache/Instrument';
import { OffsetModeViewEnum } from '../../Utils/Trading/OffsetModeViewEnum';
import { type OrderTif } from '../../Utils/Trading/OrderTifEnum';
import { type OrderType } from '../../Utils/Trading/OrderType';
import { type AlertCondition, type AlertType, type AlertImportance, type AlertAfterExecute, type AlertNotification } from '../../Utils/Alert/AlertConstants';
import { type Account } from '../../Commons/cache/Account';
import { type ProductType } from '../../Utils/Instruments/ProductType';

export class GeneralSettingsWrapper {
    static get alertNotification (): AlertNotification { return GeneralSettings.Alert.NotificationVariable; }
    static set alertNotification (value: AlertNotification) {
        GeneralSettings.Alert.NotificationVariable = value;
        this.OnSettingsChanged();
    }

    static get alertType (): AlertType { return GeneralSettings.Alert.AlertTypeVariable; }
    static set alertType (value: AlertType) {
        GeneralSettings.Alert.AlertTypeVariable = value;
        this.OnSettingsChanged();
    }

    static get alertCondition (): AlertCondition { return GeneralSettings.Alert.ConditionVariable; }
    static set alertCondition (value: AlertCondition) {
        GeneralSettings.Alert.ConditionVariable = value;
        this.OnSettingsChanged();
    }

    static get alertImportance (): AlertImportance { return GeneralSettings.Alert.ImportanceVariable; }
    static set alertImportance (value: AlertImportance) {
        GeneralSettings.Alert.ImportanceVariable = value;
        this.OnSettingsChanged();
    }

    static get alertAfterExecute (): AlertAfterExecute { return GeneralSettings.Alert.AfterExecuteVariable; }
    static set alertAfterExecute (value: AlertAfterExecute) {
        GeneralSettings.Alert.AfterExecuteVariable = value;
        this.OnSettingsChanged();
    }

    static get orderTif (): OrderTif { return GeneralSettings.TradingDefaults.MarketValidity; }
    static set orderTif (value: OrderTif) {
        GeneralSettings.TradingDefaults.MarketValidity = value;
        this.OnSettingsChanged();
    }

    static get orderType (): OrderType { return GeneralSettings.TradingDefaults.OrderType; }
    static set orderType (value: OrderType) {
        GeneralSettings.TradingDefaults.OrderType = value;
        this.OnSettingsChanged();
    }

    static get productType (): ProductType { return GeneralSettings.TradingDefaults.ProductType; }
    static set productType (value: ProductType) {
        GeneralSettings.TradingDefaults.ProductType = value;
        this.OnSettingsChanged();
    }

    static get isQuantityInLots (): boolean { return GeneralSettings.View.DisplayQuantityInLots; }
    static set isQuantityInLots (value: boolean) {
        GeneralSettings.View.DisplayQuantityInLots = value;
        this.OnSettingsChanged();
    }

    static get isSlTpOffsetMode (): boolean { return GeneralSettings.TradingDefaults.SetSlTpValuesInOffset; }
    static set isSlTpOffsetMode (value: boolean) {
        GeneralSettings.TradingDefaults.SetSlTpValuesInOffset = value;
        this.OnSettingsChanged();
    }

    static get offsetType (): OffsetModeViewEnum {
        let type = GeneralSettings.TradingDefaults.ShowOffsetIn;
        // Fractional not supported
        if (type === OffsetModeViewEnum.TicksFractionalForForex) {
            type = OffsetModeViewEnum.Ticks;
        }
        return type;
    }

    static set offsetType (value: OffsetModeViewEnum) {
        GeneralSettings.TradingDefaults.ShowOffsetIn = value;
        this.OnSettingsChanged();
    }

    static get isShowOrderSendConfrimations (): boolean { return GeneralSettings.Confirmations.WMConfrimOrderSending; }
    static set isShowOrderSendConfrimations (value: boolean) {
        GeneralSettings.Confirmations.WMConfrimOrderSending = value;
        this.OnSettingsChanged();
    }

    static get isShowOrderModifyConfrimations (): boolean { return GeneralSettings.Confirmations.WMConfirmOrderModification; }
    static set isShowOrderModifyConfrimations (value: boolean) {
        GeneralSettings.Confirmations.WMConfirmOrderModification = value;
        this.OnSettingsChanged();
    }

    static get isShowOrderExecuteConfrimations (): boolean { return GeneralSettings.Confirmations.WMConfirmOrderExecuting; }
    static set isShowOrderExecuteConfrimations (value: boolean) {
        GeneralSettings.Confirmations.WMConfirmOrderExecuting = value;
        this.OnSettingsChanged();
    }

    static get isShowOrderCancelConfrimations (): boolean { return GeneralSettings.Confirmations.WMConfrimOrderCancel; }
    static set isShowOrderCancelConfrimations (value: boolean) {
        GeneralSettings.Confirmations.WMConfrimOrderCancel = value;
        this.OnSettingsChanged();
    }

    static get isShowPositionModifyConfrimations (): boolean { return GeneralSettings.Confirmations.WMConfirmPositionModification; }
    static set isShowPositionModifyConfrimations (value: boolean) {
        GeneralSettings.Confirmations.WMConfirmPositionModification = value;
        this.OnSettingsChanged();
    }

    static get isShowPositionClosingConfrimations (): boolean { return GeneralSettings.Confirmations.WMConfirmPositionClosing; }
    static set isShowPositionClosingConfrimations (value: boolean) {
        GeneralSettings.Confirmations.WMConfirmPositionClosing = value;
        this.OnSettingsChanged();
    }

    static get defaultAccount (): Account { return SessionSettings.getDefaultAccount(); }
    static get defaultInstrument (): Instrument { return SessionSettings.getDefaultInstrument(); }

    private static OnSettingsChanged (): void {
        SessionSettings.save();
    }
}
