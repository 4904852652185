// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { TerceraDateSelectorDropDownTemplate } from "../../../templates.js";
import { Resources } from "../../../Commons/properties/Resources.ts";
import { TerceraWindowBase } from "../../screen/TerceraWindowBase.js";
import { BOTTOM_WINDOWS_MARGIN } from "../../UtilsClasses/SizeConstants.ts";
import { Control } from "../Control.js";
export let TerceraDateSelectorDropDown = TerceraWindowBase.extend({
    data: function ()
    {
        return {
            resizable: false,
            width: 216,
            height: 302,
            showHeader: false,
            showFooter: false,
            ShowDate: true,

            buttonDateText: '',
            buttonTimeText: '',
            applyBtnText: '',

            hours: 0,
            minutes: 0,

            pointH: 0,
            pointM: 0,



            date: new Date(),
            selectedDate: new Date()
        };
    },
    partials: { bodyPartial: TerceraDateSelectorDropDownTemplate },
});
TerceraDateSelectorDropDown.prototype.getType = function () { return 'TerceraDateSelectorDropDown' };

TerceraDateSelectorDropDown.prototype.oncomplete = function ()
{
    TerceraWindowBase.prototype.oncomplete.apply(this);

    this.on("dateBtnClick", function () { this.set({ ShowDate: true }) })
    this.on("timeBtnClick", function () { this.set({ ShowDate: false }) })
    this.on("applyBtnClick", this.applyClick)
    this.setFocus();

    let picker = this.Controls.dtPicker.picker
    picker.update('maxDate', '');
    picker.update('minDate', new Date());
    this.HoursSlider = new TimeSlider(this, true, "hours")
    this.MinutesSlider = new TimeSlider(this, false, "minutes")
    this.localize();

    this.observe("date", function (newValue)
    {
        if (newValue)
        {
            this.Controls.dtPicker.picker.selectDate(newValue)
            let h = newValue.getHours();
            let m = newValue.getMinutes();
            this.set({ hours: h, minutes: m, selectDate: newValue })
        }

    })
    picker.update('onRenderCell', this.datePickerOnRenderCell.bind(this))
    picker.update('onSelect', this.onSelectDateTo.bind(this))
    // this.on(Control.Events.LostFocus, function () { this.close(); }.bind(this));
    this.on(Control.Events.LostFocus, this.close)
};

TerceraDateSelectorDropDown.prototype.datePickerOnRenderCell = function (date, cellType)
{
    let nowDate = new Date()
    if (date.getDate() === nowDate.getDate() && date.getMonth() === nowDate.getMonth())
        return { classes: 'datepicker--days-custom' }

    let selectedDate = this.get('selectDate');
    if (date.getDate() === selectedDate.getDate() && date.getMonth() === selectedDate.getMonth())
        return { classes: 'datepicker--days-custom' }

    if (cellType == 'day' && date >= nowDate && date <= selectedDate)
        return { classes: 'datepicker--days-range' }
}

TerceraDateSelectorDropDown.prototype.onSelectDateTo = function ()
{
    let picker = this.Controls.dtPicker.picker,
        pickerArr = picker.selectedDates,
        selectDate = pickerArr.length ? pickerArr[0] : pickerArr.currentDate
    this.set({ selectDate: selectDate })
}

TerceraDateSelectorDropDown.prototype.close = function ()
{
    //Дикая херня!!!
    if (this.torndown)
        return
    //

    TerceraWindowBase.prototype.close.apply(this);
    this.fire("timeSelectorLookupClosed");
};

TerceraDateSelectorDropDown.prototype.applyClick = function ()
{
    let picker = this.Controls.dtPicker.picker
    let dateFromArr = picker.selectedDates,
        dateFrom = dateFromArr.length ? dateFromArr[0] : picker.currentDate

    let h = this.get("hours");
    let m = this.get("minutes");
    dateFrom.setHours(h);
    dateFrom.setMinutes(m);
    this.set({ date: dateFrom })

    this.close();
}


TerceraDateSelectorDropDown.prototype.setCorrectLocation = function (x, y)
{
    let width = this.get("width"), height = this.get("height");

    if (x + width > window.innerWidth)
        x = window.innerWidth - width - 2;
    if (y + height > window.innerHeight - BOTTOM_WINDOWS_MARGIN)
        y = window.innerHeight - BOTTOM_WINDOWS_MARGIN - height - 2;

    this.set({ left: x, top: y })
};

TerceraDateSelectorDropDown.prototype.localize = function ()
{
    this.set({
        buttonDateText: Resources.getResource('property.IsDateVisible'),
        buttonTimeText: Resources.getResource('property.IsTimeVisible'),
        applyBtnText: Resources.getResource('screen.MonthCalendarPopupForm.Apply')
    });
};

export let TimeSlider = function (parent, isH, parentLiteral)
{
    let HorM = "H"
    this.ParentLiteralValue = parentLiteral;
    this.step = 1;
    if (isH)
    {
        this.ParentLiteralPoint = "pointH"
        this.maxValue = 23;
        this.step = 8;
    }
    else
    {
        this.ParentLiteralPoint = "pointM"
        this.maxValue = 59;
        HorM = "M";
        this.step = 3.18;
    }

    parent.on("onMouseDown" + HorM, this.onMouseDown.bind(this));
    parent.on("onMouseUp" + HorM, this.onMouseUp.bind(this));
    parent.on("onMouseMove" + HorM, this.onMouseMove.bind(this));
    parent.on("onMouseEnter" + HorM, this.MouseEntre.bind(this));
    parent.on("onMouseLeave" + HorM, this.MouseLeave.bind(this));
    parent.on("onMouseWheel" + HorM, this.MouseWheel.bind(this));

    this.parent = parent;
    this.width = 190
    this.MovingNow = false;

    parent.observe(parentLiteral, function (n)
    {
        let value = this.step * n
        if (n >= this.maxValue)
            value = this.width
        if (n <= 0)
            value = 0
        this.parent.set(this.ParentLiteralPoint, value - TimeSlider.POINTHALFWIDTH)
    }.bind(this))
}


TimeSlider.prototype.onMouseDown = function (evt)
{
    this.MovingNow = true;
    this.processEVT(evt);
}
TimeSlider.prototype.onMouseUp = function (evt)
{
    this.MovingNow = false;
    this.processEVT(evt);
}
TimeSlider.prototype.onMouseMove = function (evt)
{
    if (!this.MovingNow)
        return
    this.processEVT(evt);
}
TimeSlider.prototype.MouseEntre = function (evt)
{
    //TODO MB
    this.MovingNow = false;
}
TimeSlider.prototype.MouseLeave = function (evt)
{
    //TODO MB
    this.MovingNow = false;
}
TimeSlider.prototype.MouseWheel = function (context)
{
    //TODO MB
    let evt = context.event;
    var isUp = ScrollUtils.IsScrollUp(event.deltaY);
    this.currentMoveType = isUp ? 1 : -1;
    let value = this.parent.get(this.ParentLiteralValue) + this.currentMoveType
    this.parent.set(this.ParentLiteralValue, value)
}

TimeSlider.prototype.processEVT = function (evt)
{
    let ev = evt.original
    if (ev.currentTarget !== ev.target)
        return
    this.setScrPointValue(ev.offsetX);
}

TimeSlider.prototype.setScrPointValue = function (X)
{
    let value = Math.round(X / this.step);
    this.parent.set(this.ParentLiteralValue, value)
}
TimeSlider.POINTHALFWIDTH = 4;