// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PriceType } from "../../../../../Utils/History/CashItemUtils.ts";
import { MAMode } from "../../IndicatorConstants.ts";
import { ExpandDoubleVector } from "../../DoubleMatrix.ts";
import { IndicatorFunctions } from "../../IndicatorFunctions.ts";
import { iBuildInIndicator } from "../../iBuildInIndicator.ts";

export let iAC = function ()
{
    iBuildInIndicator.call(this, 1)
    this.ao = new ExpandDoubleVector();
    this.ma = new ExpandDoubleVector();
}
iAC.prototype = Object.create(iBuildInIndicator.prototype)

Object.defineProperty(iAC.prototype, 'Name',
    {
        get: function () { return 'iAC' }
    })

Object.defineProperty(iAC.prototype, 'Key',
    {
        get: function () { return this.DefaultKey }
    })

iAC.prototype.NextBar = function (callBound)
{
    iBuildInIndicator.prototype.NextBar.call(this, callBound)
    this.ma.Add(0.0);
    this.ao.Add(0.0);
}

iAC.prototype.Refresh = function (count, newThread)
{
    this.ma.Dispose()
    this.ao.Dispose()
    this.ma = new ExpandDoubleVector();
    this.ao = new ExpandDoubleVector();
    iBuildInIndicator.prototype.Refresh.call(this, count, newThread)
}

iAC.prototype.OnQuote = function (ci, callBound, callFromRefresh)
{
    let Parent = this.Parent
    if (!Parent)
        return
    let close = this.GetPrice(PriceType.Close, 0)
    this.ao[this.ao.Length - 1] = close;
    if (this.Count >= 39)
    {
        this.ma[this.ma.Length - 1] = IndicatorFunctions.AOFunction(this.ao);
        this.SetValue(0, 0, this.ma[this.ma.Length - 1] - IndicatorFunctions.CallMovingFunction(MAMode.SMA, this.ma, 5, 1));
    }
}