// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DataCacheToolType } from '../DataCacheToolEnums';
import { DataCacheTool } from '../DataCacheTool';

export class FibonacciSpiralDataCacheTool extends DataCacheTool {
    direction: number;
    k_spiral: number;

    /**
     * Constructor for FibonacciSpiralDataCacheTool
     */
    constructor () {
        super();
        this.direction = 0;
        this.k_spiral = 0;
        this.ToolType = DataCacheToolType.FibonacciSpiral;
        this.InitPoints(222);
    }

    /**
     * Initializes the block for the tool
     */
    protected InitBlock (): void {
        super.InitBlock();
        this.direction = 1;
        this.k_spiral = 1.618;
    }

    /**
     * Gets the maximum points count required for the tool
     * @returns {number} - Maximum points count
     */
    MaxPointsCount (): number {
        return 2;
    }

    /**
     * Gets the minimum points count required for the tool
     * @returns {number} - Minimum points count
     */
    MinPointsCount (): number {
        return 2;
    }

    /**
     * Gets the additional points count required for the tool
     * @returns {number} - Additional points count
     */
    AdditionalPointsCount (): number {
        return 220;
    }
}
