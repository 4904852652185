// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

// Calculation modes of MA indicator
export enum MAMode {
    // Simple moving average calculation
    SMA = 0,
    // Exponential moving average calculation
    EMA = 1,
    // Smoothed moving average calculation
    SMMA = 2,
    // Linear weighted moving average calculation
    LWMA = 3,
}

export const MAModeMap = Object.fromEntries(Object.entries(MAMode).map(([k, v]) => [v, k]));
Object.freeze(MAModeMap);

export enum GradientMode {
    // Simple moving average RSI calculation
    None = 0,
    // Exponential RSI calculation
    Simple = 1,
    // Wilder`s moving average
    Linear = 2,
}

export enum RSIMode {
    // Simple moving average RSI calculation
    Simple = 0,
    // Exponential RSI calculation
    Exponential = 1,
    // Wilder`s moving average
    WMA = 2,
}

export enum AlligatorMode {
    Jaw = 0,
    Teeth = 1,
    Lips = 2
}

export enum IchimokuMode {
    Tenkansen = 0,
    Kijusen = 1,
    Senkouspana = 2,
    Senkouspanb = 3,
    Chinkouspan = 4
}

export const MODE_MAIN = 0;
export const MODE_SIGNAL = 1;
export const MODE_MINUSDI = 1;
export const MODE_PLUSDI = 2;

export const MODE_UPPER = 0;
export const MODE_LOWER = 1;

export enum FractalsAggregationType {
    Current = 0,
    Custom = 1
}

export enum FractalsPeriod {
    Second = 0,
    Minute = 1,
    Hour = 2,
    Day = 3,
    Week = 4,
    Month = 5,
    Year = 6
}
