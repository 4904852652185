// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PriceType } from "../../../../../Utils/History/CashItemUtils.ts";
import { MAMode } from "../../IndicatorConstants.ts";
import { ExpandDoubleVector } from "../../DoubleMatrix.ts";
import { IndicatorFunctions } from "../../IndicatorFunctions.ts";
import { iBuildInIndicator } from "../../iBuildInIndicator.ts";

export let iAO = function () 
{
    iBuildInIndicator.call(this, 1)
    this.Signal = new ExpandDoubleVector();
}

iAO.prototype = Object.create(iBuildInIndicator.prototype)

Object.defineProperty(iAO.prototype, 'Name',
    {
        get: function () { return 'iAO' }
    })

iAO.prototype.Refresh = function (count, newThread)
{
    this.Signal.Dispose()
    this.Signal = new ExpandDoubleVector();
    iBuildInIndicator.prototype.Refresh.call(this, count, newThread)
}

iAO.prototype.NextBar = function (callBound)
{
    this.Signal.Add(0.0)
    iBuildInIndicator.prototype.NextBar.call(this, callBound)
}

iAO.prototype.OnQuote = function (ci, callBound, callFromRefresh)
{

    let Parent = this.Parent
    if (!Parent)
        return

    let close = this.GetPrice(PriceType.Close, 0)
    this.Signal[this.Signal.Length - 1] = close;
    if (this.Signal.Length >= 35)
    {
        // Пересчитываем текущее значение индикатора
        this.SetValue(0, 0, handler(this.Signal, 0, 5, 1) - IndicatorFunctions.CallMovingFunction(MAMode.SMA, this.Signal, 34, 1));
    }
}