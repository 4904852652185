// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class BMHistoryRequestMessage extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.BM_HISTORY_REQUEST, fieldSet);
    }

    public setUserID (id: number): void {
        this.setFieldValue(FieldsFactory.FIELD_USER_ID, id);
    }
}
