// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { MathUtils } from '../MathUtils';

export enum IntervalType {
    Closed = 0,
    RightOpen = 1,
    LeftOpen = 2,
    Open = 3
}

export enum UpDownType {
    Up = 0,
    Down = 1,
    None = 2
}

export class Intervals {
    public LeftValue: any;
    public RightValue: any;
    public Increment: any;
    public DecimalPlaces: any;
    public IntervalType: IntervalType;

    constructor (leftValue, rightValue, increment, decimalPlaces, intervalType: IntervalType) {
        // if right value less the left value
        if (rightValue < leftValue) {
            const tmp = leftValue;
            leftValue = rightValue;
            rightValue = tmp;
        }

        this.LeftValue = leftValue;
        this.RightValue = rightValue;
        this.Increment = increment;
        this.DecimalPlaces = decimalPlaces;
        this.IntervalType = intervalType;
    }

    public CompareTo (interval): number {
        if (interval == null) {
            return -1;
        }

        this.LeftValue.CompareTo(interval.LeftValue);

        if (this.LeftValue > interval.LeftValue) {
            return 1;
        } else if (this.LeftValue < interval.LeftValue) {
            return -1;
        } else {
            return 0;
        }
    }

    public ValueInIntervalUpDown (value, upDown: UpDownType): boolean {
        if (upDown === undefined) {
            upDown = UpDownType.None;
        }

        switch (upDown) {
        case UpDownType.Up:
            return (value >= this.LeftValue && value < this.RightValue);
        case UpDownType.Down:
            return (value > this.LeftValue && value <= this.RightValue);
        default:
            return (value >= this.LeftValue && value < this.RightValue); // по дефолту правую границу не включаем #48057
        }
    }

    public ValueInInterval (value): boolean {
        switch (this.IntervalType) {
        case IntervalType.Closed:
            return (value >= this.LeftValue && value <= this.RightValue);
        case IntervalType.RightOpen:
            return (value >= this.LeftValue && value < this.RightValue);
        case IntervalType.LeftOpen:
            return (value > this.LeftValue && value <= this.RightValue);
        case IntervalType.Open:
            return (value > this.LeftValue && value < this.RightValue);
        default:
            return false;
        }
    }

    /// <summary>
    /// Метод завел для того чтобы в таблице не было объекта инструмент, в том случае когда используем объект нумерик
    /// maximum - это максимум нумерика, т.к. правая граница у нас должна быть конечным числом
    /// </summary>
    public static GetIntervalsFromInstrument (instrument, maximum): Intervals[] | null {
        const inst = instrument;
        if (inst?.VariableTickList == null || inst.VariableTickList.length <= 1) {
            return null;
        }

        const intervals: Intervals[] = [];

        for (let i = 1; i < inst.VariableTickList.length; i++) {
            const vt = inst.VariableTickList[i];
            const leftBorder = vt.LeftBorder != 0 ? vt.LeftBorder : vt.PointSize;
            const rightBorder = (i + 1) != inst.VariableTickList.length ? vt.RightBorder : maximum;
            const pointSize = vt.PointSize;
            const type = IntervalType.LeftOpen;

            const decimalPlaces = MathUtils.GetDecimalPlaces(pointSize, 20);
            intervals.push(new Intervals(leftBorder, rightBorder, pointSize, decimalPlaces, type));
        }
        return intervals;
    }
}
