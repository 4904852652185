// Copyright TraderEvolution Global LTD. © 2017-2023. All rights reserved.
import { IdeasItemSideBarTemplate } from '../../../../templates';
import { type Idea } from '../../../../Commons/cache/Idea';
import { ThemeManager } from '../../../misc/ThemeManager';
import { CustomErrorClass, ErrorInformationStorage } from '../../../../Commons/ErrorInformationStorage';
import { Resources } from '../../../../Commons/properties/Resources';
import { Pen, SolidBrush } from '../../../../Commons/Graphics';
import { ModelDataType, TerceraChartMVCCommand } from '../../../../Chart/TerceraChartMVC';
import { DataCache } from '../../../../Commons/DataCache';
import { HistoryType } from '../../../../Utils/History/HistoryType';
import { Periods } from '../../../../Utils/History/TFInfo';
import { TerceraChartDrawingType } from '../../../../Chart/Utils/ChartConstants';
import { SlTpPriceType } from '../../../../Utils/Enums/Constants';
import { OrderUtils } from '../../../../Utils/Trading/OrderUtils';
import { GeneralSettings } from '../../../../Utils/GeneralSettings/GeneralSettings';
import { ProductType } from '../../../../Utils/Instruments/ProductType';
import { InstrumentUtils } from '../../../../Utils/Instruments/InstrumentUtils';
import { IsAllowed } from '../../../../Commons/IsAllowed';
import { OrderType } from '../../../../Utils/Trading/OrderType';
import { ProfitCalculator } from '../../../../Commons/cache/ProfitCalculator';
import { Quantity } from '../../../../Utils/Trading/Quantity';
import { SlTpHolder } from '../../../../Utils/Trading/SlTpHolder';
import { PlacedFrom } from '../../../../Utils/Trading/PlacedFrom';
import { SessionSettings } from '../../../../Commons/SessionSettings';
import { TIF } from '../../../../Utils/Trading/OrderTif';
import { OrderEditUpdateData } from '../../../../Utils/Trading/OrderEditUpdateData';
import { TradingNumericErrorChecker } from '../../../../Commons/Trading/TradingNumericErrorChecker';
import { ContainerControl } from '../../ContainerControl_ts';
import { type Instrument } from '../../../../Commons/cache/Instrument';
import { ControlsTypes } from '../../../UtilsClasses/FactoryConstants';
import { Control } from '../../Control_ts';

export class IdeasItemSideBar extends ContainerControl {
    private chartInited: boolean = false;
    private terceraChartRactive: any;
    private lifeTimeIntervalId = null;

    public getType (): ControlsTypes {
        return ControlsTypes.IdeasItemSideBar;
    }

    oninit (): void {
        super.oninit();
        Control.Ticker.Subscribe(this.TickAsync, this);
    }

    oncomplete (): void {
        super.oncomplete();
        this.setDataIdea();
        this.localize();
        this.initLifeTimeTimer();
        this.onInstrumentChanged(this.get('instrumentItem'), null);

        this.on('ideaClick', this.ideaSelect);
        this.on('ideaTopHeaderClick', this.ideaHide);
        this.on('ideaClose', this.ideaHide);
        this.on('placeOrder', this.placeOrder);

        this.on('fileClick', this.attachmentClick.bind(this, false));
        this.on('screenClick', this.attachmentClick.bind(this, true));
        this.on('timeFrameComboBox_ComboItemClicked', this.onTimeFrameComboBox_ComboItemClicked);

        this.observe('productType', this.onProductTypeChanged);
        this.observe('instrumentItem', this.onInstrumentChanged);
        this.observe('account', this.onAccountChanged);
        this.observe('quantity', this.recountLossAndProfit);

        void this.set('srcFromLogoBytes', (logoBytes) => { return this.srcFromLogoBytes(logoBytes); });
    }

    onteardown (): void {
        Control.Ticker.UnSubscribe(this.TickAsync, this);

        const instrument = this.get('instrumentItem');
        if (instrument) {
            instrument.RiskSettingsUpdated.UnSubscribe(this.instrumentSettingsUpdate, this);
            this.unsubscribe(instrument);
        }

        const ractiveChart = this.terceraChartRactive;
        if (ractiveChart?.terceraChart) {
            ractiveChart.terceraChart.Dispose();
        }
    }

    private onInstrumentChanged (instrument, lastInstrument): void {
        if (isNullOrUndefined(instrument) || instrument === lastInstrument) {
            return;
        }

        this.subscribeRiskSettingsUpdate(instrument, lastInstrument);
        this.instrumentSettingsUpdate();
    }

    private subscribeRiskSettingsUpdate (instrument, lastInstrument): void {
        if (lastInstrument) {
            lastInstrument.RiskSettingsUpdated.UnSubscribe(this.instrumentSettingsUpdate, this);
        }

        if (instrument) {
            instrument.RiskSettingsUpdated.Subscribe(this.instrumentSettingsUpdate, this);
        }
    }

    private attachmentClick (isScreenShot): void {
        const idea = this.get('idea');
        if (isNullOrUndefined(idea)) {
            return;
        };

        const url = isScreenShot ? idea.AttachmentScreenPath : idea.AttachmentFilePath;
        if (url) {
            window.open(url);
        }
    }

    private instrumentSettingsUpdate (): void {
        const instrument = this.get('instrumentItem');
        const account = this.get('account');

        if (isNullOrUndefined(instrument)) {
            return;
        }

        if (!isNullOrUndefined(account)) {
            void this.set('tradingAllowed', IsAllowed.IsTradingAllowed([account], instrument, OrderType.Limit).Allowed);
        }
    }

    private readonly reOpenIdeaAfterNoConfirmClick = (): void => {
        this.ideaSelect();
    };

    private recountLossAndProfit (): void {
        const qtyObj = this.get('quantity');
        if (!qtyObj?.value) {
            return;
        }

        const idea = this.get('idea');
        if (isNullOrUndefined(idea)) {
            return;
        };

        const price = idea.limitPrice;
        let slPrice = idea.slPrice;
        let sllPrice = idea.sllPrice;
        let tpPrice = idea.tpPrice;
        const ins = idea.instrument;
        const acc = this.get('account') ?? DataCache.getPrimaryAccount();
        const isBuy = idea.buySide;
        const crossPrice = DataCache.CrossRateCache.GetCrossPriceExp1Exp2(ins.Exp2, acc.assetBalanceDefault.Asset.Name);
        const offsetMode = GeneralSettings.TradingDefaults.ShowOffsetIn;

        if (GeneralSettings.TradingDefaults.IsTicksFractionalForForex()) {
            const slInOffset = idea.slPriceType !== SlTpPriceType.Absolute;
            const tpInOffset = idea.tpPriceType !== SlTpPriceType.Absolute;

            slPrice = slInOffset ? OrderUtils.ConvertTickOffset(ins, offsetMode, null, slPrice) : slPrice;
            sllPrice = slInOffset ? OrderUtils.ConvertTickOffset(ins, offsetMode, null, sllPrice) * Math.sign(sllPrice) : sllPrice;
            tpPrice = tpInOffset ? OrderUtils.ConvertTickOffset(ins, offsetMode, null, tpPrice) : tpPrice;
        }

        if (idea.slPriceType !== SlTpPriceType.Absolute) {
            if (sllPrice != null) { slPrice += sllPrice; } //* (isBuy ? 1 : -1)

            const slvalue = slPrice * (isBuy ? -1 : 1);

            // if (offsetMode == Utils.OffsetModeViewEnum.Points)       // убрал в связи с 92654, возможно можно удалить
            //     slvalue = ins.CalculateTicks(0, slvalue) * (isBuy ? -1 : 1)

            slPrice = ins.CalculatePrice(price, slvalue);
        } else
            if (sllPrice != null) { slPrice = sllPrice; }

        if (idea.tpPriceType !== SlTpPriceType.Absolute) {
            const tpvalue = tpPrice * (isBuy ? 1 : -1);

            // if (offsetMode == Utils.OffsetModeViewEnum.Points)       // убрал в связи с 92654, возможно можно удалить
            //     tpvalue = ins.CalculateTicks(0, tpvalue) * (isBuy ? 1 : -1)

            tpPrice = ins.CalculatePrice(price, tpvalue);
        }

        const qtyInLots = Quantity.toLots(qtyObj, ins);

        const slProfit = acc.formatPrice(ProfitCalculator.CalculateSLTP(qtyInLots, slPrice, price, ins, acc, isBuy, crossPrice, null));
        const tpProfit = acc.formatPrice(ProfitCalculator.CalculateSLTP(qtyInLots, tpPrice, price, ins, acc, isBuy, crossPrice, null));

        idea.slProfit = slProfit;
        idea.tpProfit = tpProfit;

        void this.set('idea', idea);
        this.setDataIdea();
    }

    private onAccountChanged (acc, lastAcc): void {
        if (!acc || acc === lastAcc) {
            return;
        }

        this.instrumentSettingsUpdate();
        const ins = this.get('instrumentItem');
        if (!isNullOrUndefined(ins)) {
            void this.set('productTypeShow', ins.isProductTypeVisible(acc));
        }

        if (this.terceraChartRactive?.terceraChart) {
            this.terceraChartRactive.terceraChart.chartController.ExecuteCommand(
                new TerceraChartMVCCommand(ModelDataType.Account),
                acc);
        }
    }

    private onProductTypeChanged (newValue, oldValue): void {
        if (!oldValue || !newValue) { return; }

        const idea = this.get('idea');
        if (isNullOrUndefined(idea)) {
            return;
        };

        const ideaProductType = idea.productTypeAnal;
        const availableProductTypes = InstrumentUtils.getAllowedProductTypeDict(GeneralSettings.TradingDefaults.Symbol);

        if (!ideaProductType || !availableProductTypes.includes(ideaProductType.toString())) {
            return;
        };

        if (ideaProductType !== newValue) {
            const self = this;
            const msgText = Resources.getResource(newValue === ProductType.Intraday ? 'panel.Ideas.productTypeChangeToIntraday' : 'panel.Ideas.productTypeChangeToDelivery');

            // this.productTypeChangeConfirmationIsInFocus = true;

            // TerceraMessageBox.Show(
            //     Resources.getResource('general.trading.confirmation'),
            //     msgText,
            //     TerceraMessageBox.msgType.Question,
            //     function () { // Yes CALLBACK
            //         self.productTypeChangeConfirmationIsInFocus = false;
            //         self.setFocus();
            //     },
            //     function () { // No CALLBACK
            //         self.set('productType', oldValue);
            //         self.productTypeChangeConfirmationIsInFocus = false;
            //         self.setFocus();
            //     });
        }
    }

    private setDataIdea (): void {
        const idea: Idea = this.get('idea');
        if (isNullOrUndefined(idea)) {
            return null;
        }

        void this.set({
            name: idea.name,
            title: idea.title,
            companyName: idea.companyName,
            instrumentLabel: idea.instrumentLabel,
            buySide: idea.buySide,
            logoSrcBytes: idea.logoSrcBytes,
            lifeTime: idea.lifeTime,
            descriptionParsedToHTML: idea.descriptionParsedToHTML,
            tpPriceToShowFormatted: idea.tpPriceToShowFormatted,
            tpProfit: idea.tpProfit,
            limitPriceToShow: idea.limitPriceToShow,
            slPriceToShowFormatted: idea.slPriceToShowFormatted,
            slProfit: idea.slProfit,
            hasAttachments: idea.hasAttachments,
            AttachmentFilePath: idea.AttachmentFilePath,
            attachExtension: idea.attachExtension,
            AttachmentScreenPath: idea.AttachmentScreenPath,
            side: idea.side,
            wasNotRead: idea.wasNotRead
        });
    }

    private initLifeTimeTimer (onlyClear: boolean = false): void {
        if (this.lifeTimeIntervalId) {
            clearInterval(this.lifeTimeIntervalId);
        }

        if (onlyClear) {
            return;
        }

        this.ideasLifeTimeUpdate(); // чтобы при открытии уже были правильные лайфтаймы, а не через секунду
        this.lifeTimeIntervalId = setInterval(this.ideasLifeTimeUpdate.bind(this), 1000);
    }

    private ideasLifeTimeUpdate (): void {
        const idea = this.get('idea');
        if (isNullOrUndefined(idea)) {
            return;
        };

        const isOpenIdea = this.get('isOpenIdea');
        const lifeTimeTicks = idea.expireTime - Date.now();
        idea.lifeTime = lifeTimeTicks;

        if (isOpenIdea && lifeTimeTicks <= 0) {
            void this.set('tradingAllowed', false);
            void this.set('tradingForbiddenReason', 'Lifetime expired');
        }

        void this.set('idea', idea);
        this.setDataIdea();
    }

    private async ideaSelect (): Promise<void> {
        if (this.get('isOpenIdea')) {
            return;
        }

        const idea = this.get('idea');
        if (isNullOrUndefined(idea)) {
            return;
        };

        if (idea.wasNotRead) {
            idea.wasNotRead = false;
            const tradingSignalId = idea.tradingSignalId;

            DataCache.SendTradingSignalSeenRequest(tradingSignalId);
        }

        void this.set('idea', idea);
        this.setDataIdea();

        const ins = idea.instrument;
        const productType = idea.productTypeAnal;
        if (productType) {
            void this.set('productType', productType);
        }

        const acc = this.get('account');
        void this.set({
            instrumentItem: ins,
            productTypeShow: ins.isProductTypeVisible(acc), // && productType,  // есть баг видимости productTypeSelector для кейса : аналитику доступен только General, а подписчику Intraday/Delivery, так что тут судя по всему лучше устанавливать видимость только по инструменту
            tradingAllowed: true
        });

        this.setPricesToShow();
        await this.set('isOpenIdea', true);
        this.onrender();
        this.initChart(ins);
    }

    private ideaHide (context): void {
        const idea = this.get('idea');
        if (isNullOrUndefined(idea)) {
            return;
        };

        if (!this.get('isOpenIdea')) {
            return;
        }

        this.unsubscribe(this.get('instrumentItem'));
        void this.set({
            isOpenIdea: false,
            descriptionIsOpen: false,
            dataSourceOpen: false // #103389
        });

        context.event.stopPropagation();
    }

    private setPricesToShow (): void {
        const tradingSettings = GeneralSettings.TradingDefaults;
        const offsetMode = tradingSettings.ShowOffsetIn;
        const sltpOffsetMode = tradingSettings.SetSlTpValuesInOffset;
        const idea = this.get('idea');
        const ins = idea.instrument;
        const isBuy = idea.buySide;
        const price = idea.limitPrice;
        const slPrice = idea.slPrice;
        const sllPrice = idea.sllPrice;
        const slType = idea.slPriceType;
        const tpPrice = idea.tpPrice;
        const tpType = idea.tpPriceType;

        let slPriceToShow = slPrice;
        let sllPriceToShow = sllPrice;
        let tpPriceToShow = tpPrice;

        const slInOffset = slType !== SlTpPriceType.Absolute;
        const tpInOffset = tpType !== SlTpPriceType.Absolute;
        let slNeedToConvert = slInOffset !== sltpOffsetMode;
        const tpNeedToConvert = tpInOffset !== sltpOffsetMode;

        if (slType === SlTpPriceType.TrOffset) { slNeedToConvert = false; }

        if (slNeedToConvert) {
            const slvalue = slPrice * (isBuy ? -1 : 1);
            const sllvalue = sllPrice ? (sllPrice + slPrice) * (isBuy ? -1 : 1) : null;

            slPriceToShow = slInOffset ? ins.CalculatePrice(price, slvalue) : ins.CalculateTicks(price, slPrice - price);
            if (sllPrice != null) { sllPriceToShow = slInOffset ? ins.CalculatePrice(price, sllvalue) : ins.CalculateTicks(price, sllPrice - price); }
        }

        if (tpNeedToConvert) {
            const tpvalue = tpPrice * (isBuy ? 1 : -1);

            tpPriceToShow = tpInOffset ? ins.CalculatePrice(price, tpvalue) : ins.CalculateTicks(price, tpPrice - price);
        }

        if (GeneralSettings.TradingDefaults.IsTicksFractionalForForex()) {
            slPriceToShow = slInOffset ? OrderUtils.ConvertTickOffset(ins, offsetMode, null, slPriceToShow) : slPriceToShow;
            sllPriceToShow = slInOffset ? OrderUtils.ConvertTickOffset(ins, offsetMode, null, sllPriceToShow) * Math.sign(sllPriceToShow) : sllPriceToShow;
            tpPriceToShow = tpInOffset ? OrderUtils.ConvertTickOffset(ins, offsetMode, null, tpPriceToShow) : tpPriceToShow;
        }

        idea.slPriceToShow = idea.slPriceToShowFormatted = slPriceToShow;
        idea.tpPriceToShow = idea.tpPriceToShowFormatted = tpPriceToShow;
        idea.sllPriceToShow = sllPriceToShow;

        if (sltpOffsetMode) {
            idea.slPriceToShowFormatted = ins.formatOffset(slPriceToShow);

            if (sllPrice != null) { idea.slPriceToShowFormatted += '/' + ins.formatOffset(sllPriceToShow); }

            idea.tpPriceToShowFormatted = ins.formatOffset(tpPriceToShow);
        } else {
            idea.slPriceToShowFormatted = slType === SlTpPriceType.TrOffset ? ins.formatOffset(slPriceToShow) : ins.formatPrice(slPriceToShow);

            if (sllPrice != null && slType !== SlTpPriceType.TrOffset) { idea.slPriceToShowFormatted += '/' + ins.formatPrice(sllPriceToShow); }

            idea.tpPriceToShowFormatted = ins.formatPrice(tpPriceToShow);
        }

        idea.limitPriceToShow = ins.formatPrice(price);

        const locKey = idea.sllPriceToShow != null ? 'property.Stop-loss-SLL' : 'property.Stop-loss';

        void this.set('stopLossTitle', Resources.getResource(locKey) + ':');
        void this.set('idea', idea);
        this.setDataIdea();
    }

    // #region Chart
    private subscribe (instrument): void {
        if (!instrument) {
            return;
        }

        const qc = DataCache.FQuoteCache;
        const chart = this.terceraChartRactive.terceraChart;

        qc.addListener(instrument, chart, HistoryType.QUOTE_LEVEL1);
    }

    private unsubscribe (instrument: Instrument): void {
        if (isNullOrUndefined(instrument)) {
            return;
        }

        const qc = DataCache.FQuoteCache;
        const chart = this.terceraChartRactive.terceraChart;

        qc.removeListener(instrument, chart, HistoryType.QUOTE_LEVEL1);
        chart.unsubscribeTrades();
        chart.unsubscribeCashItemFromInstrument();
    }

    public TickAsync (): void {
        if (this.terceraChartRactive?.terceraChart?.needRedraw != null) {
            this.terceraChartRactive.terceraChart.needRedraw = false;
            this.terceraChartRactive.terceraChart.Draw();
        }
    }

    private initChart (instrument): void {
        if (isNullOrUndefined(this.terceraChartRactive)) {
            this.terceraChartRactive = this.Controls.chart;
        }

        const terceraChart = this.terceraChartRactive.terceraChart;
        const controller = terceraChart.chartController;
        if (!isNullOrUndefined(terceraChart)) {
            const oldIns = this.get('instrumentItem');
            if (!isNullOrUndefined(oldIns)) {
                this.unsubscribe(oldIns);
            }
        }

        this.subscribe(instrument);
        controller.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.Instrument), instrument);
        const acc = this.get('account') ?? DataCache.getPrimaryAccount();
        controller.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.Account), acc);
        terceraChart.chartController.SuspendRefreshChart = false;
        terceraChart.RefreshChart();

        if (!this.chartInited) {
            terceraChart.GetContextMenu = function () { };
            this.initChartTheme();
            this.setChartSizes();
            this.chartInited = true;
        }

        this.updateTimeFrameComboBox();
    };

    private initChartTheme (): void {
        if (!this.terceraChartRactive?.terceraChart) return;

        const theme = ThemeManager.CurrentTheme;
        const terceraChart = this.terceraChartRactive.terceraChart;
        const controller = terceraChart.chartController;

        controller.terceraChart.model.SetChartDrawingType(TerceraChartDrawingType.Solid); // Line style

        terceraChart.TerceraChartWatermarkRenderer.ForeBrush = new SolidBrush(theme.ChartWatermarkColor);

        terceraChart.TerceraChartFont.Height = 10;

        const tradingToolsRenderer = terceraChart.TerceraChartTradingToolsRenderer;

        if (tradingToolsRenderer) {
            tradingToolsRenderer.ShowOrders = false;
            tradingToolsRenderer.ShowPositions = false;
            tradingToolsRenderer.ShowVisualTradingOnLeftSide = false;
            tradingToolsRenderer.ShowEvents = false;
            tradingToolsRenderer.ShowAlerts = false;
        }

        const yScaleRendererSettings = terceraChart.yScaleRendererSettings;
        const readIdeaBackGroundColor = theme.scrollBackgroundColorRGB;

        if (yScaleRendererSettings) {
            yScaleRendererSettings.ScaleGridVisibility = false;
            yScaleRendererSettings.scaleTextBrush = new SolidBrush(readIdeaBackGroundColor);
            yScaleRendererSettings.ScaleBackColor = readIdeaBackGroundColor;
            yScaleRendererSettings.scaleAxisPen.Color = readIdeaBackGroundColor;
            yScaleRendererSettings.AutoScaleSwitcherVisible = false;
        }

        const xScaleRendererSettings = terceraChart.xScaleRendererSettings;

        if (xScaleRendererSettings) {
            xScaleRendererSettings.ScaleGridVisibility = false;
            xScaleRendererSettings.scaleTextBrush = new SolidBrush(readIdeaBackGroundColor);
            xScaleRendererSettings.ScaleBackColor = readIdeaBackGroundColor;
            xScaleRendererSettings.scaleAxisPen.Color = readIdeaBackGroundColor;
        }

        if (terceraChart.TerceraChartBordersRenderer) { terceraChart.TerceraChartBordersRenderer.timeScaleAxisPen = terceraChart.TerceraChartBordersRenderer.priceScaleAxisPen = new Pen(readIdeaBackGroundColor, 1); }
        if (terceraChart.TerceraChartInfoWindowRenderer) { terceraChart.TerceraChartInfoWindowRenderer.Visible = false; }
        if (terceraChart.TerceraChartNewAlertRenderer) { terceraChart.TerceraChartNewAlertRenderer.Visible = false; }
        if (terceraChart.windowsContainer?.scrollerRenderer) { terceraChart.windowsContainer.scrollerRenderer.Visible = false; }

        this.terceraChartRactive.set('backgroundColorBottom', readIdeaBackGroundColor);
    }

    private setChartSizes (): void {
        this.terceraChartRactive.setSizes();
    }

    private updateTimeFrameComboBox (): void {
        if (!this.terceraChartRactive?.terceraChart) {
            return;
        }

        const cBox = this.Controls.timeFrameComboBox;
        const newItems = [
            { text: Periods.ToLocalizedShortPeriod(Periods.MIN), value: Periods.MIN },
            { text: Periods.ToLocalizedShortPeriod(Periods.MIN5), value: Periods.MIN5 },
            { text: Periods.ToLocalizedShortPeriod(Periods.MIN15), value: Periods.MIN15 },
            { text: Periods.ToLocalizedShortPeriod(Periods.MIN30), value: Periods.MIN30 },
            { text: Periods.ToLocalizedShortPeriod(Periods.HOUR), value: Periods.HOUR },
            { text: Periods.ToLocalizedShortPeriod(Periods.HOUR4), value: Periods.HOUR4 },
            { text: Periods.ToLocalizedShortPeriod(Periods.DAY), value: Periods.DAY },
            { text: Periods.ToLocalizedShortPeriod(Periods.WEEK), value: Periods.WEEK },
            { text: Periods.ToLocalizedShortPeriod(Periods.MONTH), value: Periods.MONTH }
        ];

        if (Resources.isHidden('chart.AllowedPeriods.1T')) {
            newItems.splice(0, 1);
        }

        cBox.set('items', newItems);
        const tf = this.terceraChartRactive.terceraChart.model.GetTimeFrameInfo();
        if (tf) {
            cBox.setItembyValue(tf.Periods);
        }
    }

    public applyCursor (cursor): void {
        if (this.terceraChartRactive) { this.terceraChartRactive.setCursor(cursor); }
    };
    // #endregion chart

    private srcFromLogoBytes (logoBytes): string {
        let logo = ThemeManager.CurrentTheme.IdeasPanelDefaultAnalystLogo;// DEFAULT IMG
        try {
            if (logoBytes.length) { logo = 'data:image/png;base64,' + btoa(String.fromCharCode.apply(null, new Uint8Array(logoBytes))); } // IMG SRC FROM BYTES
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
        }

        return logo;
    }

    private localize (): void {
        void this.set({
            textAgreeButton: Resources.getResource('panel.Ideas.riskDisclosure.agreeBtnTxt'),
            quantityLabel: Resources.getResource('panel.newOrderEntry.amountLabel') + ':',
            descriptionOpenedTitle: Resources.getResource('InstrumentDetailsPanel.Description') + ':',
            takeProfitTitle: Resources.getResource('property.Take-profit') + ':',
            limitPriceTitle: Resources.getResource('panel.newOrderEntry.limitPricePanel') + ':',
            stopLossTitle: Resources.getResource('property.Stop-loss') + ':',
            attachmentsFile: Resources.getResource('panel.Ideas.AttachmentFile') + ': ',
            attachmentsScreen: Resources.getResource('panel.Ideas.AttachmentScreen') + ': ',
            orderButtonTextBuy: Resources.getResource('panel.Ideas.orderButtonTextBuy'),
            orderButtonTextSell: Resources.getResource('panel.Ideas.orderButtonTextSell')
        });

        const timeFrameCB = this.Controls.timeFrameComboBox;
        if (timeFrameCB) {
            timeFrameCB.set('tooltip', Resources.getResource('chart.agregationType.Button.ToolTip'));
        }

        this.updateTimeFrameComboBox();
    }

    private onTimeFrameComboBox_ComboItemClicked (context, newVal): void {
        const model = this.terceraChartRactive.terceraChart.model;
        const oldTFInfo = model.GetTimeFrameInfo();
        const controller = this.terceraChartRactive.terceraChart.chartController;
        controller.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.TFI), oldTFInfo.Copy({ period: newVal.tag }));
    };

    async placeOrder () {
        if (TradingNumericErrorChecker.HasErrors(this)) {
            return;
        }

        const idea = this.get('idea');
        const quantity = this.get('quantity');

        if (!idea || !quantity) {
            return;
        };

        const orderType = OrderType.Limit;
        const orderTypeObj = DataCache.OrderParameterContainer.OrderTypes[orderType];
        const orderEditCtorData = {
            dataCache: DataCache,
            forceSLTPOffset: false
        };

        const orderEdit = orderTypeObj.createOrderEditObject(orderEditCtorData);
        const instrument = this.get('instrumentItem');

        orderEdit.updateParameters(new OrderEditUpdateData(
            null,
            {
                account: this.get('account'),
                instrument,
                side: idea.side,
                quantity,
                tif: new TIF(idea.tif, idea.tifExpireAt),
                productType: this.get('productType'),
                leverageValue: idea.leverageValue,
                placedFrom: PlacedFrom.TRADING_IDEA_CARD
            }, SessionSettings));

        orderEdit.setLimitPrice(idea.limitPrice);
        const sltpHolder = new SlTpHolder();
        const offsetType = GeneralSettings.TradingDefaults.SetSlTpValuesInOffset ? SlTpPriceType.Offset : SlTpPriceType.Absolute;

        sltpHolder.StopLossPriceValue = idea.slPriceToShow;
        sltpHolder.StopLossPriceType = idea.slPriceType === SlTpPriceType.TrOffset ? idea.slPriceType : offsetType;
        if (idea.sllPriceToShow != null) {
            sltpHolder.StopLossLimitPriceValue = idea.sllPriceToShow;
        }

        sltpHolder.TakeProfitPriceValue = idea.tpPriceToShow;
        sltpHolder.TakeProfitPriceType = offsetType;
        orderEdit.setSLTP(sltpHolder);

        return await DataCache.FOrderExecutor.placeOrderPromise(orderEdit, null, null, this.reOpenIdeaAfterNoConfirmClick.bind(this))
            .catch(function () {
                const ex = new CustomErrorClass('IdeasPanel error', 'IdeasPanel.placeOrder', 'placeOrder -> placeOrderPromise');
                ErrorInformationStorage.GetException(ex);
            })
            .finally(function () {
            // TODO. Refactor.
                orderEdit.dispose();
            });
    };

    public onMouseDown (event): void {
        super.onMouseDown(event, false);
    }
};

ContainerControl.extendWith(IdeasItemSideBar,
    {
        template: IdeasItemSideBarTemplate,
        data: function () {
            return {
                idea: null,
                name: '',
                title: '',
                companyName: '',
                instrumentLabel: null,
                buySide: null,
                logoSrcBytes: null,
                lifeTime: null,
                descriptionParsedToHTML: '',
                tpPriceToShowFormatted: null,
                tpProfit: null,
                limitPriceToShow: null,
                slPriceToShowFormatted: null,
                slProfit: null,
                hasAttachments: null,
                AttachmentFilePath: null,
                attachExtension: null,
                AttachmentScreenPath: null,
                side: null,

                quantity: null,
                quantityLabel: '',
                descriptionOpenedTitle: '',
                descriptionIsOpen: false,

                instrumentItem: null,
                account: null,
                singleAccount: true,
                productType: null,
                productTypeLabel: '',
                productTypeShow: false,
                leverageShow: false,
                tradingAllowed: true,
                tradingForbiddenReason: '',

                takeProfitTitle: '',
                limitPriceTitle: '',
                stopLossTitle: '',
                descrBtnVisible: false,

                dataSourceOpen: false,
                dataSourceVisible: false,

                attachmentsFile: '',
                attachmentsScreen: '',
                orderButtonTextBuy: '',
                orderButtonTextSell: '',

                isOpenIdea: false
            };
        },
        computed: {
            terceraChartPanelContext: {
                get: function () { return this; },
                set: function (value) { }
            },
            visibleButtonsBarState: {
                get: function (): string {
                    if (isNullOrUndefined(this.get('idea'))) { return 'hidden'; }
                    return '';
                },
                set: function (value: string) { }
            }
        }

    });
