// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { ErrorInformationStorage } from "../../Commons/ErrorInformationStorage.ts";
import { Resources } from "../../Commons/properties/Resources.ts";
import { CommissionItem } from "../../Commons/cache/Commissions/CommissionItem.ts";
import { CommissionTypes } from '../../Utils/Commission/CommissionEnums.ts';
import { MarginOEControlTemplate } from "../../templates.js";
import { VerticalApplicationPanelNew } from "../cache/VerticalPanel/VerticalApplicationPanelNew.ts";
import { VerticalPanelDataProvider } from "../cache/VerticalPanel/VerticalPanelDataProvider.ts";
import { VerticalPanelDataProviderItem } from "../cache/VerticalPanel/VerticalPanelDataProviderItem.ts";
import { Control } from "../elements/Control.js";
import { QuickTableRow } from "../elements/QuickTable/QuickTableRow.ts";
import { PanelNames } from "../UtilsClasses/FactoryConstants.ts";
import { AccountDetailsPanel } from "./AccountDetailsPanel.ts";
import { QuickTableEditingInfo } from "../elements/QuickTable/QuickTableMisc.ts";
import { ThemeManager } from "../misc/ThemeManager.ts";
import { OperationType } from "../../Utils/Trading/OperationType.ts";
import { OrderType } from "../../Utils/Trading/OrderType.ts";
import { CommissionOperationType } from "../../Utils/Commission/CommissionEnums.ts";
import { QuotingType } from "../../Utils/Instruments/QuotingType.ts";
import { InstrumentTypes } from "../../Utils/Instruments/InstrumentTypes.ts";
import { CommissionPlan } from "../../Commons/cache/Commissions/CommissionPlan.ts";
import { PriceFormatter } from "../../Utils/Instruments/PriceFormatter.ts";
import { DynProperty } from "../../Commons/DynProperty.ts";
import { QuoteValid } from "../../Utils/Quotes/QuoteValid.ts";
import { OrderUtils } from "../../Utils/Trading/OrderUtils.ts";
import { GeneralSettings } from "../../Utils/GeneralSettings/GeneralSettings.ts";
import { Quantity } from "../../Utils/Trading/Quantity.ts";
import { OrderEditBaseUtils } from "../../Utils/Trading/OrderEditBaseUtils.ts";
import { DataCache } from "../../Commons/DataCache.ts";
import { SessionSettings } from "../../Commons/SessionSettings.ts";
import { ControlsUtils } from "../UtilsClasses/ControlsUtils.ts";
import { SymbolInfoPanel } from "./SymbolInfoPanel.js";
import { MarginInfoParameters } from "../../Commons/UtilsClasses/MarginInfo/MarginInfoParameters.ts";

export let MarginOEControl = VerticalApplicationPanelNew.extend({
    data: function ()
    {
        return {
            account: null,
            instrument: null,
            operation: null,
            orderType: null,
            tif: null,
            price: null,
            stopPrice: null,
            amount: null,
            productType: null,
            leverageValue: null,
            dockablePanel: false,
            orderEditParameterArray: null,
            refreshBtnTooltip: '',
            refreshCountdownSvgPath: ''
        };
    },
    template: MarginOEControlTemplate,
    settings: null,
    lastReceivedResponseMessage: null,

    commissionPlan: null,
    allowedCommissionTypes: null,
    instrumentWasChanged: false,
    emptyMode: false,

    refreshTick: 0,                  // current tick
    refreshCycleFullTime: 30000,     // full time in ms
    refreshTickTime: 1000,           // timer timeout duration in ms
    refreshIntervalHandler: null,    // setInterval handler

    lastLimitPrice: null,            // кэшируем во избежание избыточного запроса при изменении orderEditParameterArray
    lastStopPrice: null,             // ^_^ -!-

    msgTimeoutHandler: null,         // задержка перед отправкой запроса на сервер
    msgTimeoutDuration: 200          // длительность задержки в мс
});

MarginOEControl.prototype.oninit = function ()
{
    VerticalApplicationPanelNew.prototype.oninit.apply(this);

    this.settings = SessionSettings

    Control.Ticker.Subscribe(this.UpdateData, this);

    this.on('refreshBtnClicked', this.RefreshByMarginRequest)
    this.observe('account', this.onAccountChange)
    this.observe('instrument', this.onInstrumentChange)
    this.observe('operation orderType amount leverageValue', this.RefreshByMarginRequest, { init: false })
    this.observe('orderEditParameterArray', this.onOrderEditParameterArrayChange)
    this.observe('visible', this.onVisibleChange)
};

MarginOEControl.prototype.oncomplete = function ()
{
    VerticalApplicationPanelNew.prototype.oncomplete.apply(this);

    this.InitTable()
};

MarginOEControl.prototype.localize = function ()
{
    VerticalApplicationPanelNew.prototype.localize.call(this)

    if (this.VerticalPanelDataProvider)
        this.VerticalPanelDataProvider.Localize()

    this.set('refreshBtnTooltip', Resources.getResource('OrderEntry.InfoBlock.refreshBtnTooltip'))
}

MarginOEControl.prototype.InitializeDataProvider = function (force)
{
    this.VerticalPanelDataProvider = new VerticalPanelDataProvider(this.settings)
    // Data items
    this.VerticalPanelDataProvider.items = MarginOEControl.getDataItems.bind(this)(this.VerticalPanelDataProvider, force || this.IsFeesGroupEnabled())

    this.VerticalPanelDataProvider.GetVerticalPanelValueHandler = MarginOEControl.GetDataValue.bind(this)
    this.VerticalPanelDataProvider.GetItemsIDHandler = MarginOEControl.GetItemsID.bind(this)

    this.VerticalPanelDataProvider.GetDynamicItemsHandler = this.GetDynamicItems.bind(this);
    this.VerticalPanelDataProvider.DisposeHandler = MarginOEControl.DisposeSource.bind(this)

    let account = this.get('account')
    if (account)
        this.SetSource(DataCache, account.BstrAccount);
}

MarginOEControl.prototype.RefreshByMarginRequest = function ()
{
    if (!this.get('visible'))
        return

    if (this.msgTimeoutHandler)
        clearTimeout(this.msgTimeoutHandler)

    this.msgTimeoutHandler = setTimeout(function ()
    {
        this.refreshTick = 0
        clearTimeout(this.msgTimeoutHandler)
        let ins = this.get('instrument'),
            acc = this.get('account'),
            productType = this.get('productType'),
            leverageValue = this.get('leverageValue'),
            quantity = this.get('amount'),
            orderType = this.get('orderType'),
            amount = quantity ? Quantity.toLots(quantity, ins) : null

        if (!ins || !acc) return

        const price = this.GetLimitPrice();
        const stopPrice = this.GetStopPrice();
        const orderTypeID = orderType ? orderType.id() : null

        if (orderTypeID && orderTypeID != OrderType.Market && !price && !stopPrice)
        {
            this.emptyMode = true;
            this.repopulate(true);
            this.UpdateTable()
            return
        }
        const parameters = new MarginInfoParameters();
        parameters.account = acc,
            parameters.instrument = ins;
        parameters.orderType = orderTypeID;
        parameters.amountLots = amount;
        parameters.limitPrice = price;
        parameters.stopPrice = stopPrice;
        parameters.productType = productType;
        parameters.leverageValue = leverageValue;
        DataCache.SendMarginRequest(parameters)
            .then(function (msgArr)
            {
                if (msgArr && msgArr.length)
                {
                    this.lastReceivedResponseMessage = msgArr[msgArr.length - 1]

                    if (this.instrumentWasChanged)
                    {
                        this.emptyMode = false;
                        this.instrumentWasChanged = false
                        this.repopulate()
                    }

                    this.UpdateTable()
                }
            }.bind(this))

    }.bind(this), this.msgTimeoutDuration)
}

MarginOEControl.prototype.onOrderEditParameterArrayChange = function ()
{
    let newLimitPrice = this.GetLimitPrice(),
        newStopPrice = this.GetStopPrice()

    if (this.lastLimitPrice != newLimitPrice || this.lastStopPrice != newStopPrice)
        this.RefreshByMarginRequest()

    this.lastLimitPrice = newLimitPrice
    this.lastStopPrice = newStopPrice
}

MarginOEControl.prototype.InitTable = function ()
{
    let qtR = this.quickTableRactive
    if (!qtR) return

    qtR.setShowColumnHeaders(false)

    let qt = qtR.quickTable
    if (!qt) return

    qt.allowGroupBy = false
    qt.useVerticalSeparatorForResizing = true
    qt.FormatGroup = this.quickTable_FormatGroup.bind(this);
    // qt.OnPaintedPictureButtonClick.Subscribe(this.onPaintedPictureButtonClick, this);
};

MarginOEControl.prototype.UpdateTable = function ()
{
    this.PopulateTable()
    this.layoutTable()
    this.UpdateData()
};

MarginOEControl.prototype.UpdateData = function ()
{
    var qtRactive = this.quickTableRactive;
    var qt = qtRactive ? qtRactive.quickTable : null;
    var needRedraw = qt ? qt.needRedraw : null

    if (this.VerticalPanelDataProvider)
    {
        this.VerticalPanelDataProvider.UpdateItems()
        // this.hideGroupsIfEmpty()

        needRedraw = true
    }

    if (needRedraw != null)
    {
        qt.needRedraw = false;
        qt.Draw();
    }
};

MarginOEControl.prototype.layoutTable = function ()
{
    VerticalApplicationPanelNew.prototype.layoutTable.apply(this);

    let qt = this.getQuickTable();
    if (!qt) return

    if (qt && qt.columns[0] && qt.columns[1])
    {  // установка ширин колонок - todo подумать как сделать это лучше 
        qt.columns[0].width = 150
        qt.columns[1].width = 150
    }
}

MarginOEControl.prototype.quickTable_FormatGroup = function (groupValue)
{
    let acc = this.get('account'),
        currency = acc ? ' ' + acc.BaseCurrency : ''

    return Resources.getResource(groupValue, this.settings.Locale) + currency;
}

MarginOEControl.prototype.dispose = function ()
{
    this.lastReceivedResponseMessage = null;
    this.commissionPlan = null;

    if (this.refreshIntervalHandler)
        clearInterval(this.refreshIntervalHandler)

    // if (this.getQuickTable())
    //     this.getQuickTable().OnPaintedPictureButtonClick.UnSubscribe(this.onPaintedPictureButtonClick, this);

    Control.Ticker.UnSubscribe(this.UpdateData, this);

    VerticalApplicationPanelNew.prototype.dispose.apply(this);
};

MarginOEControl.prototype.onAccountChange = function (account)
{
    if (!account) return

    if (account.CommissionPlan)
    {
        this.commissionPlan = account.CommissionPlan;
        this.allowedCommissionTypes = this.GetAllowedCommissionTypes()
    }

    this.SetSource(DataCache, account.BstrAccount);

    this.RefreshByMarginRequest()
}

MarginOEControl.prototype.onInstrumentChange = function (ins)
{
    if (!ins) return

    this.instrumentWasChanged = true

    this.allowedCommissionTypes = this.GetAllowedCommissionTypes(ins)

    this.RefreshByMarginRequest()
}

MarginOEControl.prototype.onVisibleChange = function (visible)
{
    if (visible)
    {
        this.refreshTick = this.refreshCycleFullTime
        this.RefreshBtnIntervalTick()
        this.InitRefreshBtnTimer()
    }
    else
        if (this.refreshIntervalHandler)
            clearInterval(this.refreshIntervalHandler)
}

MarginOEControl.getDataItems = function (VerticalPanelDataProvider, feesGroupEnabled)
{
    let items = [],
        keys = MarginOEControl.GetItemsID.call(this)

    for (let i = 0; i < keys.length; i++)
    {
        let k = keys[i]
        let group = MarginOEControl.GetGroupForKey(k);

        if (group === MarginOEControl.KEY_GROUP_FEES)      // вот так пока не добавляю поле из fees если их нет
        {
            if (this.allowedCommissionTypes)
            {
                let cType = MarginOEControl.KeyToCommissionType(k);
                if (!this.allowedCommissionTypes[cType] && cType !== null)
                    continue;
            }
            if (!feesGroupEnabled)
                continue;
        }

        if (group)
        {
            let locKey = k,
                newItem = new VerticalPanelDataProviderItem();
            newItem.Id = locKey;
            newItem.DynPropertyControltype = this ? this.GetDynPropertyControlTypeForKey(k) : DynProperty.STRING
            newItem.SortIndex = MarginOEControl.GetSortIndex(k)
            newItem.LocalizationKey = locKey;
            // newItem.tooltipKey = Resources.GetToolTipKey(columnParams.HeaderKey);
            newItem.Group = group;

            items.push(newItem);
        }
    }

    return items;
}

MarginOEControl.GetDataValue = function (key, VerticalPanelDataProvider, sett, rowId)
{
    try
    {
        let ins = this.get('instrument');
        let productType = this.get('productType');
        const qt = this.getQuickTable();
        let row = qt.rows[rowId];
        let accItems = VerticalPanelDataProvider.Source;
        let useAssetBalanceItem = true;
        let isColoredField = MarginOEControl.isColoredField(key)
        let id = MarginOEControl.KEYS_MAP[key]
        if (isNaN(id) || id === null)
            useAssetBalanceItem = false;

        if (accItems !== null && accItems.length > 0)
        {
            let assetBalanceItem = accItems[0];
            let formattedValue = "";
            if (this.emptyMode)
                ;
            else if (useAssetBalanceItem)
                formattedValue = assetBalanceItem.getColumnData(id).FormattedValue;
            else
            {
                let side = this.get('operation'),
                    operationMode = side + 1

                switch (key) 
                {
                    case MarginOEControl.KEY_INIT_MARGIN:
                        formattedValue = this.GetMarginParamFormattedByPropName('InitMargin', side)
                        break
                    case MarginOEControl.KEY_MAINT_MARGIN:
                        formattedValue = this.GetMarginParamFormattedByPropName('MaintMargin', side)
                        break
                    case MarginOEControl.KEY_WARN_MARGIN:
                        formattedValue = this.GetMarginParamFormattedByPropName('WarnMargin', side)
                        break
                    case MarginOEControl.KEY_IMPACT_ON_PORTFOLIO:
                        formattedValue = this.GetImpactOnPortfolioFormatted(assetBalanceItem, side)
                        break
                    case MarginOEControl.KEY_AFTER_TRADE_FUNDS:
                        formattedValue = this.GetAfterTradeFundsFormatted(side, operationMode)
                        break
                    case MarginOEControl.KEY_SPREAD_INIT_LOSS:
                        formattedValue = this.GetSpreadInitLossFormatted()
                        break;
                    case MarginOEControl.KEY_PL_PER_TICK:
                        formattedValue = this.GetPLPerTickFormatted()
                        break;
                    case MarginOEControl.KEY_ALLOW_SHORT_POSITIONS:
                        formattedValue = SymbolInfoPanel.getLocalizedAllowShortPosition(ins.IsAllowShortPositions(productType))
                        break;

                    case MarginOEControl.KEY_FILL_PER_LOT:
                        formattedValue = this.GetFillPerLotFeeFormatted(operationMode)
                        break;
                    case MarginOEControl.KEY_ORDER_PER_LOT:
                        formattedValue = this.GetOrderPerLotFeeFormatted(operationMode)
                        break;
                    case MarginOEControl.KEY_PER_FILL:
                        formattedValue = this.GetPerFillFeeFormatted(operationMode)
                        break;
                    case MarginOEControl.KEY_PER_TRANSACTION:
                        formattedValue = this.GetPerTransactionFeeFormatted(operationMode)
                        break;
                    case MarginOEControl.KEY_PER_PHONE_TRANSACTION:
                        formattedValue = this.GetPerPhoneTransactionFeeFormatted(operationMode)
                        break;
                    case MarginOEControl.KEY_VAT:
                        formattedValue = this.GetVatFeeFormatted(operationMode)
                        break;
                    case MarginOEControl.KEY_FILL_VOLUME:
                        formattedValue = this.GetFillVolumeFeeFormatted(operationMode)
                        break;
                    case MarginOEControl.KEY_ORDER_VOLUME:
                        formattedValue = this.GetOrderVolumeFeeFormatted(operationMode)
                        break;
                    case MarginOEControl.KEY_FILL_VOLUME_WITH_MIN_PD:
                        formattedValue = this.GetFillVolumeWithMinPDFeeFormatted(operationMode)
                        break;
                    case MarginOEControl.KEY_LONG_SWAP:
                        formattedValue = this.GetLongSwap()
                        break;
                    case MarginOEControl.KEY_SHORT_SWAP:
                        formattedValue = this.GetShortSwap()
                        break;
                    case MarginOEControl.KEY_TOTAL_FEES:
                        formattedValue = this.IsTotalFeeRowVisible() ? this.GetTotalFeeFormatted(operationMode) : ''
                        break;
                }

                let addInfo = assetBalanceItem.AssetBalance.AssetAdditionalInfo
                if (addInfo && addInfo[key])
                {
                    isColoredField = true
                    formattedValue = assetBalanceItem.AssetBalance.formatPriceExactly(addInfo[key].Value)       // custom available funds #106952 
                }
            }

            if (row)
            {
                if (this.emptyMode)
                    row.visible = true

                else if (!this.instrumentWasChanged && formattedValue === "")
                    row.visible = false     // скрываем пустые строки

                let val = parseFloat(formattedValue.replace(',', '.'))
                if (isColoredField && !isNaN(val) && val != null)   // красим пока только это поле
                    row.cells[1].ForeColor = QuickTableRow.ColorBySign(row.cells[1], row.table.columns[1], val).newForeColor;

            }

            if (this.emptyMode)
                return ""

            return formattedValue;
        }
    }
    catch (ex) { ErrorInformationStorage.GetException(ex); }

    return "";
}

MarginOEControl.GetItemsID = function ()
{
    let result = MarginOEControl.ALL_FIELDS

    let account = this.get('account')
    if (account)
        result = result.concat(Object.keys(account.GetAssetBalanceCorrect().AssetAdditionalInfo))

    return result
}

MarginOEControl.prototype.GetMarginParamValueByPropName = function (paramName, operationMode, withoutBuySellStr)   // for Init, Maint, Warn Margin fields
{
    let msg = this.lastReceivedResponseMessage,
        value = null,
        propName = paramName + (withoutBuySellStr ? '' : OrderUtils.getBuySellStr(operationMode))

    if (msg && msg.hasOwnProperty(propName))
        value = parseFloat(msg[propName])

    return value
}

MarginOEControl.prototype.GetMarginParamFormattedByPropName = function (paramName, operationMode, withoutBuySellStr, withoutCurrency)   // for Init, Maint, Warn Margin fields
{
    let value = this.GetMarginParamValueByPropName(paramName, operationMode, withoutBuySellStr),
        formattedValue = ''

    withoutCurrency = withoutCurrency || false

    if (value !== null)
        formattedValue = this.formatPrice(value, withoutCurrency)

    return formattedValue
}

MarginOEControl.TICKCOST_INS_TYPES = [InstrumentTypes.FUTURES, InstrumentTypes.CFD_FUTURES, InstrumentTypes.OPTIONS, InstrumentTypes.SPREADBET];

MarginOEControl.prototype.GetSpreadInitLossFormatted = function ()
{
    let ins = this.get('instrument'),
        acc = this.get('account'),
        qty = this.get('amount'),
        sp = DataCache.GetSpreadPlan(acc)

    if (!acc || !ins || !qty || !ins.LastQuote) return MarginOEControl.EMPTY_STRING

    let crossPrice = DataCache.CrossRateCache.GetCrossPriceExp1Exp2(ins.Exp2, acc.BaseCurrency),   // постоянно меняющаяся формула из доки https://docs.google.com/document/d/1-Dq-3OjRZUbWq-ppOJI0cpmqOxCP-qUCQYv20ZjTYF0 
        ask = ins.LastQuote.AskSpread_SP_Ins(sp, ins),
        bid = ins.LastQuote.BidSpread_SP_Ins(sp, ins),
        amount = Quantity.toLots(qty, ins),
        result = -(ask - bid) * amount * crossPrice;
    result *= ins.getLotSize();
    result *= ins.GetTickCost();

    return result !== 0 ? acc.formatPrice(result) : MarginOEControl.EMPTY_STRING
}

MarginOEControl.prototype.GetPLPerTickFormatted = function ()   
{
    let acc = this.get('account'),
        ins = this.get('instrument'),
        qty = this.get('amount'),
        result = null

    if (!acc || !ins || !qty) return null

    let crossPrice = DataCache.CrossRateCache.GetCrossPriceExp1Exp2(ins.Exp2, acc.BaseCurrency),
        amount = Quantity.toLots(qty, ins);

    if (MarginOEControl.TICKCOST_INS_TYPES.indexOf(ins.InstrType) != -1 &&
        ins.QuotingType === QuotingType.TickCost_TickSize)
    {
        result = ins.FuturesTickCoast * amount * crossPrice
    }
    else
    {
        let price = this.GetLimitPrice() || this.GetStopPrice()
        if (!price)
        {
            let quote = ins.GetLastQuote(QuoteValid.Valid),
                isBuy = this.get('operation') == OperationType.Buy

            if (quote)
                price = isBuy ? quote.Ask : quote.Bid
        }

        let tickSize = ins.getLotSize() * ins.GetPointSize(price);

        result = tickSize * amount * crossPrice
    }

    return acc.formatPrice(result)
}

MarginOEControl.prototype.GetImpactOnPortfolioFormatted = function (assetBalanceItem, side)
{
    let afterTradeFunds = this.GetMarginParamValueByPropName('AfterTradeFunds', side),
        availableFunds = this.GetAvailableFundsDependsOnBuyingPowerName(assetBalanceItem)

    if (afterTradeFunds === null)   //#99902
        return ''

    return this.formatPrice(afterTradeFunds - availableFunds)
}

MarginOEControl.prototype.GetAvailableFundsDependsOnBuyingPowerName = function (assetBalanceItem) // #106952+
{
    if (!assetBalanceItem)
        return null

    let assetAdditionalInfo = assetBalanceItem.AssetBalance ? assetBalanceItem.AssetBalance.AssetAdditionalInfo : null,
        buyingPowerName = this.GetBuyingPowerName()

    if (assetAdditionalInfo && assetAdditionalInfo[buyingPowerName])
        return assetAdditionalInfo[buyingPowerName].Value

    return assetBalanceItem.getColumnData(MarginOEControl.KEYS_MAP[MarginOEControl.KEY_AVAILABLE_FUNDS]).Value;
}

MarginOEControl.prototype.GetAfterTradeFundsFormatted = function (side, operationMode)   // #99556
{
    let afterTradeFunds = this.GetMarginParamValueByPropName('AfterTradeFunds', side),
        totalFee = Math.abs(this.GetTotalFee(operationMode));

    if (afterTradeFunds === null)   //#99902
        return ''

    return this.formatPrice(afterTradeFunds - totalFee)
}

MarginOEControl.prototype.formatPrice = function (price, withoutAsset)
{
    if (withoutAsset)
        return PriceFormatter.formatPrice(price, 2)

    let acc = this.get('account')

    if (acc)
        return acc.formatPrice(price)

    return price
}

MarginOEControl.prototype.SearchForFeeValueByType = function (commissionType)
{
    let ins = this.get('instrument'),
        acc = this.get('account'),
        commissionPlan = this.commissionPlan
    if (!commissionPlan || !ins || !acc)
        return null

    let key = MarginOEControl.CommissionTypeToKey(commissionType),
        feeCurrency = CommissionItem.GetCommissionCurrency(acc, ins)

    if (!key || !feeCurrency)
        return null

    let comResult = CommissionPlan.TryGetCommissionDataValue(feeCurrency, key, ins, acc);
    if (comResult)
        return comResult

    return null
}

MarginOEControl.prototype.GetCommissionGroups = function (commissionType, operationMode)
{
    let msg = this.lastReceivedResponseMessage
    if (!msg || !msg.CommissionsByOperationMode)
        return null

    let commissionGroups = msg.CommissionsByOperationMode[operationMode],
        resultArr = []
    if (commissionGroups)
        for (let i = 0; i < commissionGroups.length; i++)
        {
            let commissionGroup = commissionGroups[i]
            if (commissionGroup.CommissionType == commissionType)
                resultArr.push(commissionGroup)
        }

    return resultArr
}

MarginOEControl.prototype.GetFeeAmountFormatted = function (commissionType, operationMode)
{
    let value = this.GetFeeAmount(commissionType, operationMode)
    if (!value)
        return ''

    return this.formatPrice(Math.abs(value), true)
}

MarginOEControl.prototype.GetFeeAmount = function (commissionType, operationMode)
{
    let commissionGroups = this.GetCommissionGroups(commissionType, operationMode)
    if (!commissionGroups || !commissionGroups.length)
        return null

    let sumByOperationMode = 0
    for (let i = 0; i < commissionGroups.length; i++)
        sumByOperationMode += commissionGroups[i].Amount

    return sumByOperationMode
}

MarginOEControl.prototype.GetFeeCurrencyID = function (commissionType, operationMode)
{
    let commissionGroups = this.GetCommissionGroups(commissionType, operationMode)
    if (commissionGroups && commissionGroups.length)
        return commissionGroups[0].CurrencyId           // судя по настройкам сервера комиссия в разных группах не модет отличаться -> берем из первой группы

    return null
}

MarginOEControl.prototype.GetFillPerLotFeeFormatted = function (operationMode)
{
    return this.GetFeeAmountFormatted(CommissionTypes.PerLot, operationMode)
}

MarginOEControl.prototype.GetOrderPerLotFeeFormatted = function (operationMode)
{
    return this.GetFeeAmountFormatted(CommissionTypes.OrderPerLot, operationMode)
}

MarginOEControl.prototype.GetPerFillFeeFormatted = function (operationMode)
{
    return this.GetFeeAmountFormatted(CommissionTypes.PerFill, operationMode)
}

MarginOEControl.prototype.GetPerTransactionFeeFormatted = function (operationMode)
{
    return this.GetFeeAmountFormatted(CommissionTypes.PerTransaction, operationMode)
}

MarginOEControl.prototype.GetPerPhoneTransactionFeeFormatted = function (operationMode)
{
    return ''   // TODO выяснить, вероятно устарело, десктоп не показывает в margin OE
}

MarginOEControl.prototype.GetVatFeeFormatted = function (operationMode)
{
    return this.GetFeeAmountFormatted(CommissionTypes.VAT, operationMode)
}

MarginOEControl.prototype.GetFillVolumeFeeFormatted = function (operationMode)
{
    return this.GetFeeAmountFormatted(CommissionTypes.PerVolume, operationMode)
}

MarginOEControl.prototype.GetOrderVolumeFeeFormatted = function (operationMode)
{
    return this.GetFeeAmountFormatted(CommissionTypes.PerOrderVolume, operationMode)
}

MarginOEControl.prototype.GetFillVolumeWithMinPDFeeFormatted = function (operationMode)
{
    return this.GetFeeAmountFormatted(CommissionTypes.VolumeWithMinPD, operationMode)
}

MarginOEControl.prototype.GetLongSwap = function ()
{
    return this.GetSwap(true)
}

MarginOEControl.prototype.GetShortSwap = function ()
{
    return this.GetSwap(false)
}

MarginOEControl.prototype.GetSwap = function (forBuy)
{
    let res = this.GetMarginParamFormattedByPropName('Swap' + (forBuy ? 'Buy' : 'Sell'), null, true, true)
    if (!res)
        res = MarginOEControl.EMPTY_STRING

    return res
}

MarginOEControl.prototype.GetTotalFee = function (operationMode)
{
    let totalFee = 0

    for (let commissionType in CommissionTypes)
    {
        let feeType = CommissionTypes[commissionType],
            value = this.GetFeeAmount(feeType, operationMode)

        if (value)
            totalFee += value
    }

    return totalFee
}

MarginOEControl.prototype.GetTotalFeeFormatted = function (operationMode)
{
    let totalFeeValue = this.GetTotalFee(operationMode)

    if (!totalFeeValue)
        return '' //MarginOEControl.EMPTY_STRING

    return this.formatPrice(Math.abs(totalFeeValue), true)
}

MarginOEControl.prototype.IsFeesGroupEnabled = function ()
{
    let msg = this.lastReceivedResponseMessage
    if (msg)
    {
        if (!Resources.isHidden(MarginOEControl.KEY_LONG_SWAP) || !Resources.isHidden(MarginOEControl.KEY_SHORT_SWAP))  // Свопы всегда отображаем в рисках ОЕ (за исключением случая, когда они скрыты ключами).
            return true
    }

    return this.GetTotalFee(CommissionOperationType.BUY) != 0
}

MarginOEControl.prototype.IsTotalFeeRowVisible = function ()
{
    let operationMode = CommissionOperationType.BUY,
        sum = 0

    for (let commissionType in CommissionTypes)
    {
        let feeType = CommissionTypes[commissionType],
            value = this.GetFeeAmount(feeType, operationMode)

        if (value)
        {
            if (sum)
                return true

            sum += value
        }
    }

    return false
}

// groups
MarginOEControl.KEY_GROUP_RISKS = 'OrderEntry.InfoBlock.1.Risks'
MarginOEControl.KEY_GROUP_FEES = 'OrderEntry.InfoBlock.2.Fees'

// fields' localization keys
// 1.Risks
MarginOEControl.KEY_BALANCE = "OrderEntry.InfoBlock.Balance";
MarginOEControl.KEY_AVAILABLE_FUNDS = "OrderEntry.InfoBlock.Availible funds";
MarginOEControl.KEY_INCOMING_FUNDS = "OrderEntry.InfoBlock.Incoming funds";
MarginOEControl.KEY_MARGIN_AVAILABLE = "OrderEntry.InfoBlock.Margin availible";
MarginOEControl.KEY_INIT_MARGIN = "OrderEntry.InfoBlock.Init. margin";
MarginOEControl.KEY_MAINT_MARGIN = "OrderEntry.InfoBlock.Maint. margin";
MarginOEControl.KEY_WARN_MARGIN = "OrderEntry.InfoBlock.Warn. margin";
MarginOEControl.KEY_IMPACT_ON_PORTFOLIO = "OrderEntry.InfoBlock.Impact on portfolio";
MarginOEControl.KEY_AFTER_TRADE_FUNDS = "OrderEntry.InfoBlock.After trade funds";
MarginOEControl.KEY_BLOCKED_FOR_STOCKS = "OrderEntry.InfoBlock.Blocked for Stocks";
MarginOEControl.KEY_SPREAD_INIT_LOSS = "OrderEntry.InfoBlock.Spread in loss";
MarginOEControl.KEY_PL_PER_TICK = "OrderEntry.InfoBlock.P/L per Tick";
MarginOEControl.KEY_ALLOW_SHORT_POSITIONS = "OrderEntry.InfoBlock.Allow short positions";
// 2.Fees
MarginOEControl.KEY_FILL_PER_LOT = "OrderEntry.InfoBlock.FillPerLot";
MarginOEControl.KEY_ORDER_PER_LOT = "OrderEntry.InfoBlock.OrderPerLot";
MarginOEControl.KEY_FILL_VOLUME = "OrderEntry.InfoBlock.FillVolume";
MarginOEControl.KEY_FILL_VOLUME_WITH_MIN_PD = "OrderEntry.InfoBlock.FillVolumeWithMinPD";
MarginOEControl.KEY_PER_FILL = "OrderEntry.InfoBlock.PerFill";
MarginOEControl.KEY_PER_TRANSACTION = "OrderEntry.InfoBlock.PerTransaction";
MarginOEControl.KEY_PER_PHONE_TRANSACTION = "OrderEntry.InfoBlock.PerPhoneTransaction";
MarginOEControl.KEY_VAT = "OrderEntry.InfoBlock.VAT";
MarginOEControl.KEY_ORDER_VOLUME = "OrderEntry.InfoBlock.OrderVolume";
MarginOEControl.KEY_LONG_SWAP = "OrderEntry.InfoBlock.LongSwap";
MarginOEControl.KEY_SHORT_SWAP = "OrderEntry.InfoBlock.ShortSwap";
MarginOEControl.KEY_TOTAL_FEES = 'OrderEntry.InfoBlock.TotalFee';

// fields and their column_id from AssetBalanceItem where it possible
MarginOEControl.KEYS_MAP = (function ()
{
    var keys = {}           // тут также задается в каком порядке идут строки
    // 1.Risks
    keys[MarginOEControl.KEY_BALANCE] = 2;
    keys[MarginOEControl.KEY_AVAILABLE_FUNDS] = 20;
    keys[MarginOEControl.KEY_INCOMING_FUNDS] = 117;
    keys[MarginOEControl.KEY_MARGIN_AVAILABLE] = 58;
    keys[MarginOEControl.KEY_INIT_MARGIN] = null;
    keys[MarginOEControl.KEY_MAINT_MARGIN] = null;
    keys[MarginOEControl.KEY_WARN_MARGIN] = null;
    keys[MarginOEControl.KEY_IMPACT_ON_PORTFOLIO] = null;
    keys[MarginOEControl.KEY_AFTER_TRADE_FUNDS] = null;
    keys[MarginOEControl.KEY_BLOCKED_FOR_STOCKS] = 74;
    keys[MarginOEControl.KEY_SPREAD_INIT_LOSS] = null;
    keys[MarginOEControl.KEY_PL_PER_TICK] = null;
    keys[MarginOEControl.KEY_ALLOW_SHORT_POSITIONS] = null;
    // 2.Fees
    keys[MarginOEControl.KEY_FILL_PER_LOT] = null;
    keys[MarginOEControl.KEY_ORDER_PER_LOT] = null;
    keys[MarginOEControl.KEY_FILL_VOLUME] = null;
    keys[MarginOEControl.KEY_ORDER_VOLUME] = null;
    keys[MarginOEControl.KEY_PER_FILL] = null;
    keys[MarginOEControl.KEY_PER_TRANSACTION] = null;
    keys[MarginOEControl.KEY_PER_PHONE_TRANSACTION] = null;
    keys[MarginOEControl.KEY_FILL_VOLUME_WITH_MIN_PD] = null;
    keys[MarginOEControl.KEY_VAT] = null;
    keys[MarginOEControl.KEY_LONG_SWAP] = null;
    keys[MarginOEControl.KEY_SHORT_SWAP] = null;
    keys[MarginOEControl.KEY_TOTAL_FEES] = null;

    return keys
}());

// array of all available keys = fields
MarginOEControl.ALL_FIELDS = (function ()   
{
    return Object.keys(MarginOEControl.KEYS_MAP)
}());

MarginOEControl.EMPTY_STRING = '---'


MarginOEControl.GetSortIndex = function (key)
{
    switch (key)
    {
        case MarginOEControl.KEY_BALANCE:
            return 0
        case MarginOEControl.KEY_AVAILABLE_FUNDS:
            return 1
    }

    if (MarginOEControl.ALL_FIELDS.indexOf(key) < 0)
        return 2

    return 100
}

MarginOEControl.isColoredField = function (key)     // тут перечислены подкрашиваемые поля
{
    switch (key)
    {
        case MarginOEControl.KEY_BALANCE:
        case MarginOEControl.KEY_AVAILABLE_FUNDS:
        case MarginOEControl.KEY_INCOMING_FUNDS:
        case MarginOEControl.KEY_MARGIN_AVAILABLE:
        case MarginOEControl.KEY_INIT_MARGIN:
        case MarginOEControl.KEY_MAINT_MARGIN:
        case MarginOEControl.KEY_WARN_MARGIN:
        case MarginOEControl.KEY_AFTER_TRADE_FUNDS:
        case MarginOEControl.KEY_IMPACT_ON_PORTFOLIO:
        case MarginOEControl.KEY_BLOCKED_FOR_STOCKS:
        case MarginOEControl.KEY_SPREAD_INIT_LOSS:
        case MarginOEControl.KEY_PL_PER_TICK:
            return true
    }

    return false
};

MarginOEControl.CommissionTypeToKey = function (type)
{
    switch (type)
    {
        case CommissionTypes.PerLot:
            return SymbolInfoPanel.KEY_FILLPERLOT;
        case CommissionTypes.OrderPerLot:
            return SymbolInfoPanel.KEY_ORDERPERLOT;
        case CommissionTypes.PerVolume:
            return SymbolInfoPanel.KEY_PERVOLUME;
        case CommissionTypes.VolumeWithMinPD:
            return SymbolInfoPanel.KEY_VOLUME_WITH_MIN_PD;
        case CommissionTypes.PerFill:
            return SymbolInfoPanel.KEY_PERFILL;
        case CommissionTypes.PerTransaction:
            return SymbolInfoPanel.KEY_PERTRANSACTION;
        case CommissionTypes.PerPhoneTransaction:
            return SymbolInfoPanel.KEY_PERPHONETRANSACTION;
        case CommissionTypes.VAT:
            return SymbolInfoPanel.KEY_VAT;
        case CommissionTypes.PerOrderVolume:
            return SymbolInfoPanel.KEY_ORDER_VOLUME;
    }

    return null
}

//метод наоборот
MarginOEControl.KeyToCommissionType = function (key)
{
    switch (key)
    {
        case MarginOEControl.KEY_FILL_PER_LOT:
            return CommissionTypes.PerLot;
        case MarginOEControl.KEY_ORDER_PER_LOT:
            return CommissionTypes.OrderPerLot;
        case MarginOEControl.KEY_FILL_VOLUME:
            return CommissionTypes.PerVolume;
        case MarginOEControl.KEY_FILL_VOLUME_WITH_MIN_PD:
            return CommissionTypes.VolumeWithMinPD;
        case MarginOEControl.KEY_PER_FILL:
            return CommissionTypes.PerFill;
        case MarginOEControl.KEY_PER_TRANSACTION:
            return CommissionTypes.PerTransaction;
        case MarginOEControl.KEY_PER_PHONE_TRANSACTION:
            return CommissionTypes.PerPhoneTransaction;
        case MarginOEControl.KEY_VAT:
            return CommissionTypes.VAT;
        case MarginOEControl.KEY_ORDER_VOLUME:
            return CommissionTypes.PerOrderVolume;
        case MarginOEControl.KEY_LONG_SWAP:
            return CommissionTypes.SwapBuy;
        case MarginOEControl.KEY_SHORT_SWAP:
            return CommissionTypes.SwapSell;
    }
    return null
}

MarginOEControl.GetGroupForKey = function (headerKey)
{
    switch (headerKey)
    {
        case MarginOEControl.KEY_BALANCE:
        case MarginOEControl.KEY_AVAILABLE_FUNDS:
        case MarginOEControl.KEY_INCOMING_FUNDS:
        case MarginOEControl.KEY_MARGIN_AVAILABLE:
        case MarginOEControl.KEY_INIT_MARGIN:
        case MarginOEControl.KEY_MAINT_MARGIN:
        case MarginOEControl.KEY_WARN_MARGIN:
        case MarginOEControl.KEY_IMPACT_ON_PORTFOLIO:
        case MarginOEControl.KEY_AFTER_TRADE_FUNDS:
        case MarginOEControl.KEY_BLOCKED_FOR_STOCKS:
        case MarginOEControl.KEY_SPREAD_INIT_LOSS:
        case MarginOEControl.KEY_PL_PER_TICK:
        case MarginOEControl.KEY_ALLOW_SHORT_POSITIONS:
            return MarginOEControl.KEY_GROUP_RISKS

        case MarginOEControl.KEY_FILL_PER_LOT:
        case MarginOEControl.KEY_ORDER_PER_LOT:
        case MarginOEControl.KEY_FILL_VOLUME:
        case MarginOEControl.KEY_FILL_VOLUME_WITH_MIN_PD:
        case MarginOEControl.KEY_PER_FILL:
        case MarginOEControl.KEY_PER_TRANSACTION:
        case MarginOEControl.KEY_PER_PHONE_TRANSACTION:
        case MarginOEControl.KEY_VAT:
        case MarginOEControl.KEY_ORDER_VOLUME:
        case MarginOEControl.KEY_LONG_SWAP:
        case MarginOEControl.KEY_SHORT_SWAP:
        case MarginOEControl.KEY_TOTAL_FEES:
            return MarginOEControl.KEY_GROUP_FEES

        default:    // for custom available funds #106952
            return MarginOEControl.KEY_GROUP_RISKS
    }
}

MarginOEControl.prototype.GetAllowedCommissionTypes = function (instrument)
{
    let ins = instrument || this.get("instrument"),
        result = {}

    result[CommissionTypes.SwapBuy] = true
    result[CommissionTypes.SwapSell] = true

    if (!this.commissionPlan)
        return result

    return Object.assign(result, this.commissionPlan.GetAllowedCommissionTypes(ins))
}

MarginOEControl.prototype.SetColumnsColouringMode = function (table)
{
    let up = ThemeManager.CurrentTheme.TableValueUpForeColor;
    let down = ThemeManager.CurrentTheme.TableValueDownForeColor;
    let column = table.columns[1];
    column.ValueUpForeColor = up;
    column.ValueDownForeColor = down;
}

MarginOEControl.prototype.GetPriceByName = function (paramName)
{
    let params = this.get('orderEditParameterArray')

    if (!params) return

    for (let i = 0; i < params.length; i++)
    {
        let param = params[i]
        if (param && param.name == paramName)
            return param.value
    }

    return null
}

MarginOEControl.prototype.GetLimitPrice = function ()
{
    let orderType = this.get('orderType'),
        isStop = orderType && orderType.id() === OrderType.Stop,
        isTrStop = orderType && orderType.id() === OrderType.TrailingStop

    if (isStop)
        return this.GetStopPrice()

    if (isTrStop)
        return this.GetTrailingStopPrice()

    return this.GetPriceByName(OrderEditBaseUtils.LIMIT_PRICE_PARAM)
}

MarginOEControl.prototype.GetStopPrice = function ()
{
    return this.GetPriceByName(OrderEditBaseUtils.STOP_PRICE_PARAM)
}

MarginOEControl.prototype.GetTrailingStopPrice = function ()
{
    let offsetTrStop = this.GetPriceByName(OrderEditBaseUtils.TRAILING_STOP_PARAM),
        ins = this.get('instrument'),
        isBuy = this.get('operation') == OperationType.Buy

    let lastQuote = ins ? ins.GetLastQuote(QuoteValid.Last) : null

    if (!lastQuote)
        return offsetTrStop;

    let price = isBuy ? lastQuote.Ask : lastQuote.Bid,
        sign = isBuy ? 1 : -1

    let rawTicks = OrderUtils.toRawTicks(offsetTrStop, GeneralSettings.TradingDefaults.ShowOffsetIn, ins)

    return OrderUtils.ConvertTickOffset(ins, null, price, rawTicks * sign)
}

MarginOEControl.prototype.GetDynamicItems = function ()
{
    return []
}

MarginOEControl.prototype.getClientPanel = function ()
{
    return this.find('div');
};

MarginOEControl.prototype.getType = function ()
{
    return PanelNames.MarginOEControl;
};

MarginOEControl.prototype.repopulate = function (force)
{
    VerticalApplicationPanelNew.prototype.repopulate.apply(this);

    this.InitializeDataProvider(force);
};

MarginOEControl.prototype.GetDynPropertyControlTypeForKey = function (key)
{
    if (!this.GetBuyingPowerName())
        return DynProperty.STRING

    switch (key)
    {
        case MarginOEControl.KEY_AFTER_TRADE_FUNDS:
            return DynProperty.INFO_PICTURE_RIGHT_AND_TEXT
        default:
            return DynProperty.STRING
    }
}

MarginOEControl.prototype.SetEditingInfo = function (row, controlType)
{
    VerticalApplicationPanelNew.prototype.SetEditingInfo.apply(this, [row, controlType]);

    if (controlType != DynProperty.STRING)
    {
        let cells = row.cells,
            cellLabel = cells[0],
            cellValue = cells[1]

        cellLabel.ReadOnly = false;
        cellLabel.QuickTableEditingInfo = new QuickTableEditingInfo(controlType);
        cellLabel.QuickTableEditingInfo.GetDataHandler = this.GetBuyingPowerName.bind(this)

        cellValue.ReadOnly = true
        cellValue.QuickTableEditingInfo = new QuickTableEditingInfo(DynProperty.STRING);
    }
}

MarginOEControl.prototype.GetBuyingPowerName = function () //#106952
{
    let msg = this.lastReceivedResponseMessage
    if (!msg || !msg.BuyingPowerName)
        return null

    return msg.BuyingPowerName
}

MarginOEControl.prototype.onPaintedPictureButtonClick = function (data, event)
{
    // if (data.controlType === DynProperty.INFO_PICTURE_RIGHT_AND_TEXT)
    // {
    //     let text = this.GetBuyingPowerName()
    //     RactiveTooltip.showTooltip();
    //     return
    // }
}

MarginOEControl.DisposeSource = function ()
{
    /// на смену Source отписка и пр.
}





// Refresh Button Region

MarginOEControl.prototype.InitRefreshBtnTimer = function ()
{
    if (this.refreshIntervalHandler)
    {
        clearInterval(this.refreshIntervalHandler)

        this.refreshIntervalHandler = null
    }

    this.refreshIntervalHandler = setInterval(this.RefreshBtnIntervalTick.bind(this), this.refreshTickTime)
}

MarginOEControl.prototype.RefreshBtnIntervalTick = function ()
{
    if (this.refreshTick * this.refreshTickTime > this.refreshCycleFullTime)
        this.RefreshByMarginRequest()

    let tick = (this.refreshTick++) * this.refreshTickTime

    let deg = (tick / this.refreshCycleFullTime * 360) //% 360 

    this.drawTimerSector(deg)
}

MarginOEControl.prototype.drawTimerSector = function (deg)
{
    let opts = {      // sector
        cx: 8,        // <-- center x
        cy: 8,        // <-- center y
        radius: 8,    // <-- circle radius
        start_angle: 0, // <-- start angle in degrees
        end_angle: deg, // <-- end angle in degrees
    };

    let path = ControlsUtils.createSVGPathSector(opts)

    this.set('refreshCountdownSvgPath', path)
}