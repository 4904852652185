// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from '../../Utils/CustomEvents';
import { ControlTemplate, PositionAndVisibleTemplate, VisibleTemplate, VisualRulesTemplate } from '../../templates.js';
import { buttonsPopupHandler, contextMenuHandler, datePickerDropDownHandler, multiComboBoxPopupMenuHandler } from '../../Utils/AppHandlers.js';
import Ractive from 'ractive';

export class Control extends Ractive {
    public static Ticker = new CustomEvent();

    public AfterLostFocusWaiting: any = null;
    public el: HTMLElement;

    public _guid: any;

    public Controls: any;

    public getType (): string { return 'Control'; }

    // Описание public метода черех прототип (так нет дублирования в каждом экземпляре)
    public oninit (): void {

    }

    public oncomplete (): void { }

    public onteardown (): void { }

    public setLocation (x: number, y: number): void {
        void this.set({ left: x, top: y });
    }

    public setBounds (x: number, y: number, width: number, height: number): void {
        void this.set({
            left: x,
            top: y,
            width,
            height
        });
    }

    public getBounds (): any {
        const all = this.get();
        return {
            left: all.left,
            top: all.top,
            width: all.width,
            height: all.height
        };
    }

    public lostFocus (): void {
        void this.set('focused', false);
        this.fire(ControlEvents.LostFocus);
    }

    public gotFocus (): void {
        void this.set('focused', true);
        // if (this.el)
        //    this.el.focus();
        this.fire(ControlEvents.GotFocus);
    }

    public setFocus (): void {
        const parentControl = this.get('parentContainerControl');
        if (parentControl) {
            parentControl.processFocus(this);
        }
    }

    public onMouseDown (event, isHeader?): void {
        if (Control.IsEventProcessed(event)) {
            return;
        }

        // Inform parent control about changes
        const parentControl = this.get('parentContainerControl');
        if (parentControl !== null) {
            parentControl.processFocus(this);
        }

        if (!isNullOrUndefined(contextMenuHandler?.isShowed) &&
            contextMenuHandler.isShowed() === true) {
            contextMenuHandler.Hide();
        }

        if (!isNullOrUndefined(multiComboBoxPopupMenuHandler?.isShowed) &&
            multiComboBoxPopupMenuHandler.isShowed()) {
            multiComboBoxPopupMenuHandler.ProcessOnMouseDown(this);
        }

        if (datePickerDropDownHandler.get('visible')) {
            datePickerDropDownHandler.Hide();
        }

        if (!isNullOrUndefined(buttonsPopupHandler) && !isNullOrUndefined(buttonsPopupHandler.get) &&
            buttonsPopupHandler.get('visible') === true) {
            buttonsPopupHandler.Hide();
        }

        // Event was processed - skip next time
        Control.ProcessEvent(event);
    }

    public onMouseMove (event): any { }

    public onMouseUp (event): any { }

    public onMouseWheel (event): any { }

    public onDoubleClick (event): any { }

    public onClick (event): any { }

    public onMouseEnter (event): any { }

    public onMouseLeave (event): any { }

    public onContextMenuOpen (event): void {
        if (Control.IsEventProcessed(event)) {
            return;
        }

        const myContextMenu = this.get('contextMenu');
        const ev = event.original;
        if (myContextMenu) {
            myContextMenu.show(this, ev.pageX, ev.pageY);
        }

        Control.ProcessEvent(event);
    }

    public getContextMenuItem () {
        return {
            text: this.get('contextMenuItemText'),
            enabled: true,
            canCheck: false,
            checked: false,
            tag: null,
            style: '',
            event: null
        };
    }

    public getX (): number {
        let result = this.get('left');
        const parentContainerControl = this.get('parentContainerControl');
        if (parentContainerControl) {
            result += parentContainerControl.get('left');
        }

        const tmpleft = (this.el).getBoundingClientRect().left;
        return (result > tmpleft ? result : tmpleft);
    }

    public getY (): number {
        let result = this.get('top');
        const parentContainerControl = this.get('parentContainerControl');
        if (parentContainerControl) {
            result += parentContainerControl.get('top');
        }

        const tmptop = (this.el).getBoundingClientRect().top;
        return (result > tmptop ? result : tmptop);
    }

    public dispose (): void {
        void this.teardown();
    }

    public getAbsoluteRectangle (): any {
        throw new Error('Not implemented');
    }

    // Processing events
    // События прокидываются от контрола наверх, чтобы избежать повторной обработки - устанавливаем флаг.
    // Пока оставляю механизм через статику, по возможности придумать как просто через event передавать данные.
    // AlexB
    public static processedEvents = [];

    public static ProcessEvent (event): void {
        const processedEvents = Control.processedEvents;
        const ev = event.original;
        // remember event
        processedEvents.push(ev.type + ev.timeStamp);
        // control size
        if (processedEvents.length > 50) {
            processedEvents.splice(0, processedEvents.length - 10);
        }
    }

    public static IsEventProcessed (event): boolean {
        const ev = event.original;
        return Control.processedEvents.includes(ev.type + ev.timeStamp);
    }
}

export enum ControlEvents {
    LostFocus = 'LostFocus',
    GotFocus = 'GotFocus'
}

Ractive.extendWith(Control, {
    template: ControlTemplate,
    data: function () {
        return {
            // у всех наследников
            additionalClass: '',
            width: '',
            height: '',
            top: '',
            bottom: '',
            left: '',
            right: '',
            borderWidth: '', //
            visible: true, // видимость элемента элемента
            enabled: true, // возможность выделения элемента
            name: '', // имя элемента внутри контрола родителя - задавать обязательно! по нему рактив контрол попадет в Controls.
            tooltip: '', // всплывающая подсказка
            contextMenuItemText: '', // text for getContextMenuItem
            canSelect: true, // может ли контрол принимать фокус
            canFocus: true,
            parentContainerControl: null, // ссылка на родительский контейнер
            focused: false, // состояние фокуса
            onProcessMove: false,
            movable: false,
            resizable: false,
            withOutBorder: false,
            readonly: false,
            PRIVATE: {}
        };
    },
    partials: {
        position: PositionAndVisibleTemplate,
        visibility: VisibleTemplate,
        visualparameters: VisualRulesTemplate
    }
});
