// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Rectangle } from '../../Commons/Geometry';
import { LayersEnum, TerceraChartBaseRenderer } from './TerceraChartBaseRenderer';
import { TerceraChartCashItemSeriesDataType } from '../Series/TerceraChartCashItemSeriesEnums';
import { Graphics, Pen, SolidBrush, RectangleEdgeFilter } from '../../Commons/Graphics';
import { MouseButtons } from '../../Controls/UtilsClasses/ControlsUtils';
import { ThemeManager } from '../../Controls/misc/ThemeManager';
import { CashItem } from '../../Commons/cache/History/CashItem';
import { Periods } from '../../Utils/History/TFInfo';
import { DateTimeConvertor } from '../../Utils/Time/DateTimeConvertor';
import { DateTimeUtils } from '../../Utils/Time/DateTimeUtils';
import { type TerceraChartBase } from '../TerceraChartBase';
import { type TerceraChartWindowsContainer } from '../TerceraChartWindowsContainer';

export class TerceraChartCrossHairRenderer extends TerceraChartBaseRenderer {
    public prevCrosshairWidth: number;
    public prevCrosshairHeight: number;
    public ShowTrackVisibleLine: boolean;
    public yScaleBitmap: HTMLImageElement | null;
    public yScaleBitmapHighlight: HTMLImageElement | null;
    public xScaleBitmap: HTMLImageElement | null;
    public timeTrackBitmap: HTMLImageElement | null;
    public priceTrackBitmap: HTMLImageElement | null;
    public gridPricePen: Pen;
    public gridPriceHLPen: Pen;
    public trackPricePen: Pen;
    public trackTimePen: Pen;
    public trackPriceBoredrPen: Pen;
    public trackTimeBoredrPen: Pen;
    public gridTimePen: Pen;
    public TrackCursorBackColorBrush: SolidBrush;
    public TrackCursorFontBrush: SolidBrush;
    public trackPriceStyle: number;
    public trackPriceWidth: number;
    public trackPriceColor: string;

    constructor (chart: TerceraChartBase) {
        super(chart);

        this.prevCrosshairWidth = 0;
        this.prevCrosshairHeight = 0;
        this.ShowTrackVisibleLine = true;
        this.yScaleBitmap = null;
        this.yScaleBitmapHighlight = null;
        this.xScaleBitmap = null;
        this.timeTrackBitmap = null;
        this.priceTrackBitmap = null;
        this.gridPricePen = new Pen('blue');
        this.gridPriceHLPen = new Pen('blue');
        this.trackPricePen = new Pen('');
        this.trackTimePen = new Pen('');
        this.trackPriceBoredrPen = new Pen('');
        this.trackTimeBoredrPen = new Pen('');
        this.gridTimePen = new Pen('blue');
        this.TrackCursorBackColorBrush = new SolidBrush(ThemeManager.CurrentTheme.Chart_TrackCursorBackColor);
        this.TrackCursorFontBrush = new SolidBrush(ThemeManager.CurrentTheme.Chart_TrackCursorFontColor);
        this.trackPriceStyle = 0;
        this.trackPriceWidth = 1;
        this.trackPriceColor = ThemeManager.CurrentTheme.Chart_TrackCursorBackColor;
        this.chart = chart;
        this.ThemeChanged();
        this.InitializePens();
        this.assignLayer = LayersEnum.CrossHair;
        this.SetClassName('TerceraChartCrossHairRenderer');
    }

    get TrackPriceStyle () {
        return this.trackPriceStyle;
    }

    set TrackPriceStyle (value) {
        this.trackPriceStyle = value;
        this.UpdatePricePen();
        this.resetSizeData();
    }

    get TrackPriceWidth () {
        return this.trackPriceWidth;
    }

    set TrackPriceWidth (value) {
        this.trackPriceWidth = value;
        this.UpdatePricePen();
        this.resetSizeData();
    }

    get TrackPriceColor () {
        return this.trackPriceColor;
    }

    set TrackPriceColor (value) {
        this.trackPriceColor = value;
        this.UpdatePricePen();
        this.resetSizeData();
    }

    public UpdatePricePen () {
        this.trackPricePen.Color = this.trackPriceColor;
        this.trackPricePen.Width = this.trackPriceWidth;
        this.trackPriceBoredrPen.Color = this.trackPriceColor;
        this.TrackCursorBackColorBrush = new SolidBrush(this.trackPriceColor);
        Pen.ProcessPen(this.trackPricePen, this.trackPriceStyle);
    }

    public InitializePens () {
        Pen.ProcessPen(this.trackPriceBoredrPen, 0);
        Pen.ProcessPen(this.trackTimeBoredrPen, 0);
        this.TrackPriceStyle = 4;
    }

    Draw (gr: any, window: any, windowsContainer: TerceraChartWindowsContainer, advParams: any = null) {
        const p = advParams;
        if (!p) return;

        let pHoverWindowXValue = p.HoverWindowXValue;
        const terceraChart = p.TerceraChart;
        const cursorPosition = p.CursorPosition;
        window = terceraChart.HoverWindow;

        const chartCursorPosition = terceraChart.CursorPosition;
        const timeScaleRenderer = terceraChart.windowsContainer.xScaleRenderer;
        const windows = terceraChart.windowsContainer.Windows;

        if (chartCursorPosition && !window && timeScaleRenderer.Rectangle.Contains(chartCursorPosition.X, chartCursorPosition.Y)) {
            window = windows[windows.length - 1];
            pHoverWindowXValue = window.PointsConverter.GetDataX(chartCursorPosition.X);
        }

        if (!window || !this.Visible) return;

        if (!terceraChart.HasCorrectData() || !p.Instrument ||
            pHoverWindowXValue <= 0 ||
            !terceraChart.HasCorrectData() ||
            this.TradingToolHovered(p.TerceraChart)) { return; }

        const instrument = p.Instrument;
        const clientRect = window.ClientRectangle;

        this.UpdateRectangle(clientRect, windowsContainer);

        if (!cursorPosition || cursorPosition.IsEmpty()) { return; }

        this.DrawHLine(gr, clientRect, cursorPosition.Y);

        const topDelta = window === terceraChart.mainWindow
            ? (window.GetMainThreadRenderer('TerceraChartPlateRenderer')?.IsEmpty ? window.rightYScaleRenderer.AutoScaleRect.Height : 0)
            : 0;

        const formattedValue = TerceraChartCrossHairRenderer.GetPriceText(window, p, instrument);

        const ScaleFont = this.chart.yScaleRendererSettings.ScaleFont;
        const lastPriceRect = new Rectangle(
            clientRect.X + clientRect.Width + 1,
            cursorPosition.Y - (ScaleFont.Height / 2) - 3,
            window.PaddingRight - 2,
            ScaleFont.Height + 6);

        let pricePlateIntersectsWithBarStatistics = false;
        const barStatistics = terceraChart.BarStatistics;
        if (barStatistics?.Visible) {
            const BSR = barStatistics.Rectangle;
            const BSRY = BSR.Y;

            pricePlateIntersectsWithBarStatistics =
                chartCursorPosition.Y >= BSRY &&
                chartCursorPosition.Y <= BSRY + BSR.Height;
        }

        if (window.rightYScaleRenderer && !pricePlateIntersectsWithBarStatistics && !window.rightYScaleRenderer.AutoScaleRect.Contains(cursorPosition.X, cursorPosition.Y)) { this.DrawPricePlate(gr, clientRect, lastPriceRect, topDelta, formattedValue); }

        this.DrawVLine(gr, windowsContainer, clientRect, cursorPosition.X);

        if (!window) return;

        const barIndex = Math.floor(window.PointsConverter.GetBarIndex(pHoverWindowXValue));
        const time = DateTimeConvertor.getLocalTimeQuick(terceraChart.MainCashItemSeries().GetValue(barIndex, CashItem.TIME_INDEX));
        const timeFormatted = time.getFullYear() > DateTimeUtils.StartDateTimeJavascriptYear ? Periods.PeriodsFormatWithConvert(time, terceraChart.TimeFrameInfo().Periods, instrument) : '-';

        this.DrawTimePlate(gr, clientRect, cursorPosition.X, timeFormatted);
    }

    ProcessMouseDown (e: any) {
        if (e.Button === MouseButtons.Middle) {
            this.ShowTrackVisibleLine = !this.ShowTrackVisibleLine;
        }

        return super.ProcessMouseDown(e);
    }

    private UpdateRectangle (clientRect: any, windowsContainer: any) {
        const newH = windowsContainer.crosshairHeight;
        const newW = clientRect.Width;

        if (newW === this.prevCrosshairWidth &&
            newH === this.prevCrosshairHeight) { return; }

        this.prevCrosshairWidth = newW;
        this.prevCrosshairHeight = newH;

        this.CacheBitmap(
            newW,
            newH,
            CacheBitmapType.CommonBitmaps);
    }

    InitUpdateRectangle (): void {
        this.UpdateRectangle(this.chart.mainWindow.ClientRectangle, this.chart.windowsContainer);
    }

    private DrawHLine (gr: any, clientRect: any, y: number): void {
        const priceTrackBitmap = this.priceTrackBitmap;
        if (!priceTrackBitmap) return;

        const clientRectY = clientRect.Y;

        if (y > clientRectY && y < clientRectY + clientRect.Height) {
            if (this.ShowTrackVisibleLine) { gr.drawImage(priceTrackBitmap, clientRect.X, y); }
        }
    }

    private DrawVLine (gr: any, windowsContainer: any, clientRect: any, x: number): void {
        const timeTrackBitmap = this.timeTrackBitmap;
        if (!timeTrackBitmap) return;

        const crX = clientRect.X;
        if (this.ShowTrackVisibleLine && this.prevCrosshairHeight &&
            x > crX && x < crX + clientRect.Width) {
            if (this.ShowTrackVisibleLine) { gr.drawImage(timeTrackBitmap, x, 0); }
        }
    }

    private DrawTimePlate (gr: any, clientRect: any, x: number, timeFormated: string): void {
        const ScaleFont = this.chart.yScaleRendererSettings.ScaleFont;
        const timseScaleRect = this.chart.XScaleRect();
        let textWidth = gr.GetTextWidth(timeFormated, ScaleFont);
        textWidth += 5;
        const LastTimeRect = new Rectangle(x - textWidth / 2, timseScaleRect.Y, textWidth, timseScaleRect.Height);
        LastTimeRect.Inflate(0, -1);

        const clientRectX = clientRect.X;
        const LastTimeRectX = LastTimeRect.X;

        const TimePlateX = LastTimeRect.X;
        const TimePlateY = LastTimeRect.Y;
        const TimePlateWidth = LastTimeRect.Width;
        const TimePlateHeight = LastTimeRect.Height;

        if (LastTimeRectX > clientRectX && LastTimeRectX + LastTimeRect.Width < clientRectX + clientRect.Width) {
            gr.RoundRectangle(LastTimeRect, 2, this.TrackCursorBackColorBrush, this.trackPriceBoredrPen, false, RectangleEdgeFilter.All);
            LastTimeRect.Y++;

            gr.DrawString(timeFormated,
                ScaleFont,
                this.TrackCursorFontBrush,
                LastTimeRect.X + LastTimeRect.Width / 2,
                LastTimeRect.Y + LastTimeRect.Height / 2,
                'center',
                'middle');
        }
    }

    private DrawPricePlate (gr: any, clientRect: any, lastPriceRect: any, topDelta: number, formattedValue: string): void {
        const clientRectY = clientRect.Y;
        let lastPriceRectY = lastPriceRect.Y;
        const lastPriceRectH = lastPriceRect.Height;

        const PricePlateX = lastPriceRect.X;
        const PricePlateY = lastPriceRect.Y;
        const PricePlateWidth = lastPriceRect.Width;
        const PricePlateHeight = lastPriceRect.Height;

        if (lastPriceRectY > clientRectY + topDelta &&
            lastPriceRectY + lastPriceRectH < clientRectY + clientRect.Height) {
            gr.FillRect(this.TrackCursorBackColorBrush, PricePlateX, PricePlateY, PricePlateWidth, PricePlateHeight);

            lastPriceRectY++;
            lastPriceRect.Y = lastPriceRectY;
            const ScaleFont = this.chart.yScaleRendererSettings.ScaleFont;
            gr.DrawString(formattedValue,
                ScaleFont,
                this.TrackCursorFontBrush,
                lastPriceRect.X + lastPriceRect.Width / 2,
                lastPriceRectY + lastPriceRectH / 2,
                'center',
                'middle');
        }
    }

    Dispose () {
        super.Dispose();
        this.chart = null;
    }

    ThemeChanged (): void {
        super.ThemeChanged();

        this.TrackPriceColor = ThemeManager.CurrentTheme.Chart_TrackCursorBackColor;
        this.TrackCursorFontBrush = ThemeManager.CurrentTheme.Chart_TrackCursorFontBrush;
        this.TrackCursorBackColorBrush = ThemeManager.CurrentTheme.Chart_TrackCursorBackColorBrush;
        this.resetSizeData();
    }

    CacheBitmap = function (width: number, height: number, cacheBitmapType): void {
        const w = width;
        const h = height;

        if (!this.gridPricePen) return;

        const canvas = Graphics.getAdditionalCanvas();
        const gr = canvas.getContext('2d');
        gr.Clear();

        if (cacheBitmapType === CacheBitmapType.CommonBitmaps) {
            canvas.width = w;
            canvas.height = this.trackPriceWidth;
            gr.DrawLine(this.gridPricePen, 0, 0, 2 * w, this.trackPriceWidth);
            this.yScaleBitmap = gr.ToImage();

            gr.Clear();

            canvas.width = w;
            canvas.height = this.trackPriceWidth;
            gr.DrawLine(this.gridPriceHLPen, 0, 0, 2 * w, this.trackPriceWidth);
            this.yScaleBitmapHighlight = gr.ToImage();

            gr.Clear();

            canvas.width = this.trackPriceWidth;
            canvas.height = h;
            gr.DrawLine(this.gridTimePen, 0, 0, 0, h - 13); // '- 13' не рисуем на самой шкале, только а пределах окна графика
            this.xScaleBitmap = gr.ToImage();

            gr.Clear();

            canvas.width = w;
            canvas.height = this.trackPriceWidth;
            gr.DrawLine(this.trackPricePen, 0, 0, 2 * w, this.trackPriceWidth);
            this.priceTrackBitmap = gr.ToImage();
            this.priceTrackBitmap.onload = () => { this.chart.IsDirty(); };
            gr.Clear();

            canvas.width = this.trackPriceWidth;
            canvas.height = h;
            gr.DrawLine(this.trackPricePen, 0, 0, 0, h);
            this.timeTrackBitmap = gr.ToImage();
            this.timeTrackBitmap.onload = () => { this.chart.IsDirty(); };

            gr.Clear();
        } else if (cacheBitmapType === CacheBitmapType.WindowSpecific) {
            canvas.width = this.trackPriceWidth;
            canvas.height = h;
            gr.DrawLine(this.trackPricePen, 0, 0, 0, h);
            this.timeTrackBitmap = gr.ToImage();

            gr.Clear();
        }
    };

    resetSizeData (): void {
        this.prevCrosshairWidth = 0;
        this.prevCrosshairHeight = 0;
    }

    static GetPriceText (window: any, p: any, instrument: any): string {
        let formatcurPriceValue = '';

        const dataType = window === p.TerceraChart.mainWindow
            ? p.TerceraChart.cashItemSeriesSettings.DataType
            : TerceraChartCashItemSeriesDataType.Absolute;

        const cashItemSeries = p.mainPriceRenderer.Series;

        switch (dataType) {
        case TerceraChartCashItemSeriesDataType.Absolute:
            const roundedPrice = instrument.RoundPriceToNearestPointSize(p.HoverWindowYValue);
            formatcurPriceValue = instrument.formatPrice(roundedPrice);
            break;

        case TerceraChartCashItemSeriesDataType.Relative:
            formatcurPriceValue = p.HoverWindowYValue.toFixed(2)/* toString("N2") */ + '%';
            break;

        case TerceraChartCashItemSeriesDataType.Log:
            formatcurPriceValue = instrument.formatPrice(
                instrument.roundPrice(cashItemSeries.settings.logDataConverter.Revert(p.HoverWindowYValue)));
            break;
        }

        return formatcurPriceValue;
    }

    TradingToolHovered (chart: any): boolean {
        if (chart?.TerceraChartTradingToolsRenderer != null) {
            const curTool = chart.TerceraChartTradingToolsRenderer.hoverTool;
            if (curTool && !curTool.newTrade) { return true; }
        }
        return false;
    }
}

export enum CacheBitmapType {
    CommonBitmaps,
    WindowSpecific
};
