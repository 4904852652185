// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type AlertAction, type AlertAfterExecute, type AlertNotification, type AlertCondition, type AlertImportance, type AlertType } from '../Alert/AlertConstants';
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectReplaceAlertMessage extends DirectMessageBaseClass {
    public InstrumentTradableID = -1;
    public RouteId: number | null = null;

    public AlertId = -1;
    public AlertType: AlertType | null = null;
    public AlertCondition: AlertCondition | null = null;
    public AlertValue: number | null = null;
    public AlertImportance: AlertImportance | null = null;
    public Date: Date | null = null;
    public AlertAfterExecute: AlertAfterExecute | null = null;
    public AlertAction: AlertAction | null = null;
    public AlertNotification: AlertNotification | null = null;
    public Text: string | null = null;

    constructor () {
        super(Message.CODE_REPLACE_ALERT_MESSAGE);
    }
}
