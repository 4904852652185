// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../Commons/properties/Resources';
import { LinkedSystem } from '../misc/LinkedSystem';
import { contextMenuHandler } from '../../Utils/AppHandlers';
import { TerceraLinkControlTemplate } from '../../templates';
import { ContainerControl } from './ContainerControl_ts';
import { TerceraLinkControlConstants } from '../UtilsClasses/TerceraLinkControlConstants';

export class TerceraLinkControl extends ContainerControl {
    private _menuitems: TerceraLinkMenuItem[] = [];
    public completed: boolean = false;

    constructor () { super(); }

    public getType (): string { return 'TerceraLinkControl'; };

    public oninit (): void {
        super.oninit();

        this.on('linkClick', () => {
            if (!(this.get('linkByAccount') as boolean)) // https://tp.traderevolution.com/entity/83015
            { contextMenuHandler.Show(this.menuitems, this.getX(), this.getY() + 12); }
        });
        this.observe('selectedLinkValue', (newVal) => {
            this.setDataByValue(newVal);
        });

        this.observe('linkByAccount', (newVal: boolean) => {
            void this.set({ btnClass: (newVal ? 'js-link-color-none-acc' : 'js-link-color-none') });
        });
    };

    get menuitems (): TerceraLinkMenuItem[] {
        if (isNullOrUndefined(this._menuitems) || this._menuitems?.length === 0) { this.initContextMenu(); }
        return this._menuitems;
    };

    set menuitems (value: TerceraLinkMenuItem[]) {
        this._menuitems = value;
    };

    public oncomplete (): void {
        super.oncomplete();
        this.initContextMenu();
        Resources.onLocaleChange.Subscribe(this.localize, this);
        this.completed = true;
    };

    public dispose (): void {
        Resources.onLocaleChange.UnSubscribe(this.localize, this);

        super.dispose();
    };

    public localize (): void {
        const menuitems = this.menuitems;
        if (isNullOrUndefined(menuitems)) return;

        for (const mi of this.menuitems) {
            mi.text = Resources.getResource(mi.textKey);
        }
    };

    public setDataByValue (value): void {
        const menuitems = this.menuitems;
        if (isNullOrUndefined(menuitems) || menuitems?.length === 0) return;
        const linkByAccount = this.get('linkByAccount') as boolean;
        const skipLinkingCircle = this.get('skipLinkingCircle') as boolean;
        for (const newData of menuitems) {
            if (skipLinkingCircle) { break; }
            if (newData.tag.value === value) {
                if (linkByAccount) {
                    void this.set({
                        btnClass: 'js-link-color-active',
                        selectedLinkValue: newData.tag.value
                    });
                } else {
                    void this.set({
                        btnClass: newData.tag.style,
                        selectedLinkValue: newData.tag.value
                    });
                }
                break;
            }
        }
        if (this.completed) { LinkedSystem.updateDictState(linkByAccount); }
    };

    public initContextMenu (): void {
        const linkByAccount: boolean = this.get('linkByAccount');
        const clickItemEvent = (curItem: TerceraLinkMenuItem): void => {
            const tag = curItem.tag;
            void this.set({
                btnClass: tag.style,
                selectedLinkValue: tag.value
            });
        };

        if (linkByAccount) { this.menuitems = this.createAccLinkItems(clickItemEvent); } else { this.menuitems = this.createLinkItems(clickItemEvent); }

        this.setDataByValue(this.get('selectedLinkValue'));
    };

    private createLinkItems (clickItemEvent: (curItem: TerceraLinkMenuItem) => void): TerceraLinkMenuItem[] {
        const items = [];
        for (const curData of TerceraLinkControlConstants.LinkDataArray) {
            const newItem = new TerceraLinkMenuItem();
            newItem.text = Resources.getResource(curData.textKey);
            newItem.canCheck = false;
            newItem.enabled = true;
            newItem.checked = true;

            newItem.event = clickItemEvent;
            newItem.style = curData.style;
            newItem.textKey = curData.textKey;
            newItem.tag = { value: curData.value, style: curData.style };
            items.push(newItem);
        }

        return items;
    }

    private createAccLinkItems (clickItemEvent: (curItem: TerceraLinkMenuItem) => void): TerceraLinkMenuItem[] {
        const items = [];

        const newItemNone = new TerceraLinkMenuItem();
        newItemNone.canCheck = false;
        newItemNone.enabled = true;
        newItemNone.checked = true;
        newItemNone.event = clickItemEvent;
        newItemNone.style = 'js-link-color-none-acc';
        newItemNone.tag = { value: TerceraLinkControlConstants.STATE_NONE, style: 'js-link-color-none-acc' };
        items.push(newItemNone);

        const newItemAll = new TerceraLinkMenuItem();
        newItemAll.canCheck = false;
        newItemAll.enabled = true;
        newItemAll.checked = true;
        newItemAll.event = clickItemEvent;
        newItemAll.style = 'js-link-color-active';
        newItemAll.tag = { value: TerceraLinkControlConstants.STATE_ALL, style: 'js-link-color-active' };
        items.push(newItemAll);
        return items;
    }
}

class TerceraLinkMenuItem {
    public text: string;
    public canCheck: boolean;
    public enabled: boolean;
    public checked: boolean;
    public event: any;
    public style: string;
    public tag: any;
    public textKey: string = '';
}

ContainerControl.extendWith(TerceraLinkControl, {
    template: TerceraLinkControlTemplate,
    data: function () {
        return {
            btnClass: '',
            selectedLinkValue: TerceraLinkControlConstants.STATE_NONE,
            linkByAccount: false,
            skipLinkingCircle: false
        };
    },
    menuitems: null
});

// window HACK
declare global {
    interface Window {
        TerceraLinkControl: any
    }
}

window.TerceraLinkControl = TerceraLinkControl;
