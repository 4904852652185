// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Rectangle } from '../../Commons/Geometry';
import { ThemeManager } from '../../Controls/misc/ThemeManager';
import { Pen, SolidBrush, RectangleEdgeFilter, type Font } from '../../Commons/Graphics';
import { tooltipHandler } from '../../Utils/AppHandlers.js';

export class PlateBase {
    public clientRectangle = new Rectangle();
    public closeRectangle = new Rectangle();
    public editRectangle = new Rectangle();
    public ellipseRectangle = new Rectangle();
    public visibilityRectangle = new Rectangle();
    public plateBackRect = new Rectangle();
    public lastWidth = 0;
    public lastParametersWidth = 0;

    public width = 0;
    public lastBtnsX = 0;
    public lastClientRectangleX = 0;
    public PlateVisible = true;

    public lastLinesCount = 0;

    public tooltip = null;
    public lastHover = PlateHoverEnum.None;
    public isOverlay: boolean;

    constructor () {
        PlateBase.ThemeChanged();

        ThemeManager.onThemeChange.Subscribe(PlateBase.ThemeChanged);
    }

    public GetHover (): any {};
    public SetHover (value): any {};

    public GetSelected (): any {};
    public SetSelected (): any {};

    public GetVisible (): any {};

    public static font: Font;
    public static sfNearCenter;// StringFormat
    public static pen: Pen;
    public static closeDef; // Image
    public static closeHover; // Image
    public static visibilityDef; // Image
    public static visibilityHover; // Image
    public static nonVisibilityDef; // Image
    public static nonVisibilityHover; // Image

    public static ellipsisDef; // Image
    public static ellipsisHover; // Image

    public static brush;
    public static PlateDefaultBrush: SolidBrush;// = new SolidBrush(App.ThemeManager.CurrentTheme.Chart_DefaultPlateColor);        //SolidBrush
    public static PlateHoverBrush: SolidBrush;//= new SolidBrush(App.ThemeManager.CurrentTheme.Chart_HoverPlateColor);            //SolidBrush
    public static PlateSelectedBrush: SolidBrush;//= new SolidBrush(App.ThemeManager.CurrentTheme.Chart_SelectedPlateColor);      //SolidBrush
    public static PlateSelectedPen: Pen;//= new Pen(App.ThemeManager.CurrentTheme.Chart_SelectedPlatePenColor, 1);                   //Pen
    public static ButtonsDividerPen: Pen;//= new Pen(App.ThemeManager.CurrentTheme.Chart_DeviderButtonsColor, 1);                    //Pen
    public static ButtonsHoverDividerPen: SolidBrush;// = new SolidBrush(App.ThemeManager.CurrentTheme.Chart_DeviderHoverButtonsColor);
    public static BorderPlateColorPen: Pen;

    get Width (): number {
        const w = Math.max(this.lastWidth, 80); // min is 80 px
        // w = Math.min(w, 300); // max is 300

        if (this.width < w) {
            this.width = w;
        }

        // запоминаем макс размер
        return this.width;
    }

    public Draw (gr, window, cashItemSeries, lastMouseHoverBarIndex, mouseDownButtonRect, lastMouseMovePt): void {
        const clientRectangle = this.clientRectangle;
        if (clientRectangle.IsEmpty()) return;

        let newRealWidth = 0;
        let width = 0;
        let curX = clientRectangle.X;

        if (this.GetHover()) {
            gr.FillRect(PlateBase.PlateHoverBrush, clientRectangle.X + 1, clientRectangle.Y, clientRectangle.Width, clientRectangle.Height);
        } else {
            gr.FillRect(PlateBase.PlateDefaultBrush, clientRectangle.X + 1, clientRectangle.Y, clientRectangle.Width, clientRectangle.Height);
        }

        this.ellipseRectangle = new Rectangle(clientRectangle.X + 6, clientRectangle.Y + 6, clientRectangle.Height - 12, clientRectangle.Height - 12);
        if (this.isOverlay) {
            if (this.GetSelected()) {
                gr.FillEllipse(PlateBase.PlateSelectedBrush, clientRectangle.X + 6, clientRectangle.Y + 6, clientRectangle.Height - 12, clientRectangle.Height - 12);
            }
            gr.DrawEllipse(PlateBase.ButtonsDividerPen, clientRectangle.X + 6, clientRectangle.Y + 6, clientRectangle.Height - 12, clientRectangle.Height - 12);
            newRealWidth += 21;
            curX += 21;
            if (this.ellipseRectangle.Contains(lastMouseMovePt.X, lastMouseMovePt.Y) && !this.GetSelected()) {
                gr.DrawEllipse(PlateBase.ButtonsHoverDividerPen, clientRectangle.X + 6, clientRectangle.Y + 6, clientRectangle.Height - 12, clientRectangle.Height - 12);
            }
        } else {
            newRealWidth += 8;
            curX += 8;
        }
        // name
        const name = this.getTextName();
        width = gr.GetTextWidth(name, PlateBase.font) + 2;

        gr.DrawString(name, PlateBase.font, PlateBase.brush, curX + width / 2, clientRectangle.Y + clientRectangle.Height / 2, 'center', 'middle');
        newRealWidth += width + 6;
        curX += width + 6;

        // all lines
        const newLinesCount = this.getLinesCount();
        for (let i = 0; i < newLinesCount; i++) {
            const text = this.getTextValue(lastMouseHoverBarIndex, i, cashItemSeries != null ? cashItemSeries.settings : null);
            if (!text) {
                continue;
            }

            width = gr.GetTextWidth(text, PlateBase.font) + 8;

            this.plateBackRect = new Rectangle(curX, clientRectangle.Y + 3, width, clientRectangle.Height - 8);
            gr.RoundRectangle(this.plateBackRect, 2, this.getBrush(i), PlateBase.BorderPlateColorPen, false, RectangleEdgeFilter.All);
            gr.DrawString(text, PlateBase.font, PlateBase.brush, curX + 1 + width / 2, clientRectangle.Y + clientRectangle.Height / 2, 'center', 'middle');
            newRealWidth += width + 5;
            curX += width + 5;
        }

        if (curX > this.lastBtnsX || this.lastClientRectangleX != clientRectangle.X) {
            this.lastBtnsX = curX;
        }

        this.lastClientRectangleX = clientRectangle.X;
        if (clientRectangle.Width > 30) {
            curX = this.lastBtnsX;
            this.visibilityRectangle = new Rectangle(curX + 5, clientRectangle.Y, 16, 16);
            newRealWidth += this.visibilityRectangle.Width + 3;
            curX += this.visibilityRectangle.Width + 3;

            this.closeRectangle = new Rectangle(curX + 5, clientRectangle.Y, 16, 16);
            newRealWidth += this.closeRectangle.Width + 5;

            this.editRectangle = new Rectangle(clientRectangle.X + this.ellipseRectangle.Width + 6, clientRectangle.Y,
                clientRectangle.Width - this.closeRectangle.Width - this.visibilityRectangle.Width - 21, clientRectangle.Height);

            let visibilityIm = PlateBase.visibilityDef;
            if (!this.GetVisible()) {
                visibilityIm = PlateBase.nonVisibilityDef;
            }
            // hovered state
            if (this.visibilityRectangle.Contains(lastMouseMovePt.X, lastMouseMovePt.Y)) {
                if (this.GetVisible()) {
                    visibilityIm = PlateBase.visibilityHover;
                } else {
                    visibilityIm = PlateBase.nonVisibilityHover;
                }
            }

            let closeIm = PlateBase.closeDef;
            // hovered state
            if (this.closeRectangle.Contains(lastMouseMovePt.X, lastMouseMovePt.Y)) {
                closeIm = PlateBase.closeHover;
            }

            if (visibilityIm) {
                gr.drawImage(visibilityIm, this.visibilityRectangle.X, this.visibilityRectangle.Y + 3);
            }
            if (closeIm) {
                gr.drawImage(closeIm, this.closeRectangle.X, this.closeRectangle.Y + 3);
            }
        } else {
            this.closeRectangle = this.visibilityRectangle = Rectangle.Empty();
        }

        if (this.lastWidth < newRealWidth + 2) {
            this.lastWidth = newRealWidth + 2;
        }

        // сбросим
        if (this.lastLinesCount != newLinesCount) {
            this.width = 0;
        }
    }

    public ProcessMouseMove (X: number, Y: number): void {
        if (this.closeRectangle.Contains(X, Y)) {
            if (this.lastHover !== PlateHoverEnum.Close) {
                this._ProcessMouseOverInnerElements(this.getCloseTT());
                this.lastHover = PlateHoverEnum.Close;
            }
        } else if (this.visibilityRectangle.Contains(X, Y)) {
            if (this.lastHover !== PlateHoverEnum.Visibility) {
                this._ProcessMouseOverInnerElements(this.getVisibilityTT());
                this.lastHover = PlateHoverEnum.Visibility;
            }
        } else {
            if (this.lastHover !== PlateHoverEnum.Body) {
                this._ProcessMouseOverInnerElements(this.getTypeName());
                this.lastHover = PlateHoverEnum.Body;
            }
        }
    }

    public _ProcessMouseOverInnerElements (text): void {
        clearInterval(this.tooltip);
        this.tooltip = null;
        this.tooltip = setTimeout(function (text) {
            if (tooltipHandler.showTooltip) {
                tooltipHandler.showTooltip(text);
            }
        }, 500, text);
    }

    public ProcessMouseLeave (): void {
        clearInterval(this.tooltip);
        this.tooltip = null;
        this.lastHover = PlateHoverEnum.None;
        if (tooltipHandler.setTooltipVisibility) {
            tooltipHandler.setTooltipVisibility(false);
        }
    }

    public getTypeName (): string {
        return '';
    }

    public getCloseTT (): string {
        return '';
    }

    public getVisibilityTT (): string {
        return '';
    }

    public getPropertiesText (): string {
        return '';
    }

    public getCloseText (): string {
        return '';
    }

    public getVisibilityText (): string {
        return '';
    }

    public getTextName (): any {}
    public getTextValue (lastMouseHoverBarIndex, lineNumber, seriesSettings): any {}
    public getBrush (lineNumber): any {}
    public getLinesCount (): any {}
    public Dispose (): void {}

    public static ThemeChanged (): void {
        PlateBase.font = ThemeManager.Fonts.Font_12F_regular;
        PlateBase.closeDef = ThemeManager.CurrentTheme.closeDef;
        PlateBase.closeHover = ThemeManager.CurrentTheme.closeHover;
        PlateBase.visibilityDef = ThemeManager.CurrentTheme.visibilityDef;
        PlateBase.visibilityHover = ThemeManager.CurrentTheme.visibilityHover;
        PlateBase.nonVisibilityDef = ThemeManager.CurrentTheme.nonVisibilityDef;
        PlateBase.nonVisibilityHover = ThemeManager.CurrentTheme.nonVisibilityHover;
        PlateBase.ellipsisDef = ThemeManager.CurrentTheme.ellipsisDef;
        PlateBase.ellipsisHover = ThemeManager.CurrentTheme.ellipsisHover;
        PlateBase.pen = new Pen(ThemeManager.CurrentTheme.Chart_PlateTextColors, 1);
        PlateBase.brush = new SolidBrush(ThemeManager.CurrentTheme.Chart_PlateTextColor);
        PlateBase.PlateHoverBrush = new SolidBrush(ThemeManager.CurrentTheme.Chart_HoverPlateColor);
        PlateBase.PlateSelectedBrush = new SolidBrush(ThemeManager.CurrentTheme.Chart_SelectedPlateColor);
        PlateBase.ButtonsDividerPen = new Pen(ThemeManager.CurrentTheme.Chart_DeviderButtonsColor);
        PlateBase.BorderPlateColorPen = new Pen(ThemeManager.CurrentTheme.Chart_BorderPlateColor);
        PlateBase.ButtonsHoverDividerPen = new Pen(ThemeManager.CurrentTheme.Chart_DeviderHoverButtonsColor);
        PlateBase.PlateSelectedPen = new Pen(ThemeManager.CurrentTheme.Chart_SelectedPlatePenColor);
        PlateBase.PlateDefaultBrush = new SolidBrush(ThemeManager.CurrentTheme.Chart_DefaultPlateColor);
    }

    public ResetPlateSize (): void {
        this.lastParametersWidth = 0;
        this.lastWidth = 0;
    }
}

export enum PlateHoverEnum {
    None = 0,
    Body = 1,
    Close = 2,
    Edit = 3,
    Visibility = 4
}
