// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { NO_BIND_TO } from '../../../Utils/Trading/OrderConstants';
import { DynProperty } from '../../DynProperty';
import { Resources } from '../../properties/Resources';

export class BoundToEdit {
    public boundToOrderId: any = NO_BIND_TO;
    public currentOrderId: any = NO_BIND_TO;
    public bindToOrderIdArr: any[] = [];

    public getDynProperty (): DynProperty {
        const dp = new DynProperty();
        dp.type = DynProperty.COMBOBOX_COMBOITEM;
        dp.value = this.boundToOrderId;
        dp.localizationKey = 'screen.modifyOrder.bind_to';
        const objectVariants = dp.objectVariants = [];

        // TODO. Localization here?
        objectVariants.push({
            text: Resources.getResource('property.None'),
            value: NO_BIND_TO
        });

        const orderIdArr = this.bindToOrderIdArr;
        for (let i = 0, len = orderIdArr.length; i < len; i++) {
            const orderId = orderIdArr[i];
            objectVariants.push({
                value: orderId,
                text: orderId
            });
        }

        dp.COMBOBOX_TYPE = DynProperty.STRING;

        return dp;
    }

    public update (updateData): boolean {
        let parameterChanged = false;

        const dp = updateData.dp;
        if (dp) {
            parameterChanged = this.updateFromDynProperty(dp) || parameterChanged;
        }

        const newTradingDataDict = updateData.newTradingDataDict;
        if (!newTradingDataDict) {
            return parameterChanged;
        }

        const order = newTradingDataDict.order;
        if (!order) {
            return parameterChanged;
        }

        this.currentOrderId = order.OrderNumber;
        this.boundToOrderId = order.BoundTo;
        this.bindToOrderIdArr = order.DataCache.getBindToOrderIdArray(order);

        parameterChanged = true || parameterChanged;

        return parameterChanged;
    }

    public updateFromDynProperty (dp): boolean {
        let parameterChanged = false;

        const newVal = dp.value;
        if (newVal !== this.boundToOrderId) {
            this.boundToOrderId = newVal;
            parameterChanged = true || parameterChanged;
        }

        return parameterChanged;
    }

    public getRawValue (): any {
        return this.boundToOrderId;
    }
}
