// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { Sha256 } from '@aws-crypto/sha256-js';

class _ImagesManager {
    public static readonly DB_NAME = 'InstrumentImages';
    public static readonly DB_STORE_NAME = 'images';

    private db: any = null;

    constructor () {
        // this.ImgsCache = {};
        void this.InitDB().then((db) => { this.db = db; });
    }

    public async GetSvg (url): Promise<any> {
        try {
            const sha_name = await this.getSHA256(url);
            const data = await this.DB_Get(this.db, sha_name);
            let imgSVG = data.imgData;
            if (!data.success || !imgSVG) {
                imgSVG = await this.LoadIMG(url);
                if (imgSVG) {
                    await this.DB_Add(this.db, sha_name, imgSVG);
                }
            }
            return imgSVG;
        } catch (err) {
            return null;
        }
    }

    public async GetImg (url, w = 24, h = 24, a = 1): Promise<any> {
        let img = new Image();
        if (!this.db) { return null; } // empty IMG

        try {
            const imgSVG = await this.GetSvg(url);

            if (!imgSVG) { return null; }
            img = await this.SVGtoIMG(imgSVG, w, h, a);
        } catch (err) {
            return null;
        }

        return await new Promise((resolve, reject) => {
            resolve(img);
        });
    }

    public async SVGtoIMG (imgSVG, w = 24, h = 24, a = 1): Promise<any> {
        const aim_W = w;
        const aim_H = h;
        const globalAlpha = a;
        return await new Promise((resolve, reject) => {
            const div = document.createElement('div');
            div.innerHTML = imgSVG.trim();
            const svgarr = div.getElementsByTagName('svg');
            const svgEL = svgarr[0];
            const width = svgEL.getAttribute('width');
            const height = svgEL.getAttribute('height');
            svgEL.setAttribute('width', aim_W.toString());
            svgEL.setAttribute('height', aim_H.toString());
            const viewBoxStr = `0 0 ${parseInt(width)} ${parseInt(height)}`;

            svgEL.setAttribute('viewBox', viewBoxStr);

            const svg = svgEL;
            const xml = new XMLSerializer().serializeToString(svg);

            // make it base64
            const svg64 = btoa(xml);
            const b64Start = 'data:image/svg+xml;base64,';

            // prepend a "header"
            const image64 = b64Start + svg64;
            const canvas = document.createElement('canvas');

            canvas.width = aim_W + 4; // add some place for img rounding
            canvas.height = aim_H + 4;// add some place for img rounding
            // set it as the source of the img element
            const img = new Image();
            img.src = image64;
            img.onload = function () {
                const ctx = canvas.getContext('2d');
                ctx.globalAlpha = globalAlpha;
                ctx.drawImage(img, 2, 2); // add some place for img rounding
                const img_png = new Image();
                img_png.src = canvas.toDataURL('image/png', 1); // best png quolity
                img_png.onload = () => { resolve(img_png); };
            };
        });
    }

    public async LoadIMG (url): Promise<any> {
        return await new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.onreadystatechange = function () {
                if (this.readyState !== 4) { return; }

                if (this.status !== 200) { resolve(null); return; }

                resolve(this.responseText.trim());
            };
            xhr.send();
        });
    }

    public async InitDB (): Promise<any> {
        return await new Promise((resolve, reject) => {
            const openRequest = indexedDB.open(_ImagesManager.DB_NAME, 1);
            openRequest.onerror = function (event) {
                resolve(null);
            // console.log("Error", openRequest.error);
            };
            openRequest.onsuccess = function (event) {
            // console.log("Opened");
                resolve((event.target as IDBOpenDBRequest).result);
            };
            openRequest.onupgradeneeded = function (event) {
            // console.log("onupgradeneeded");
                const db = (event.target as IDBOpenDBRequest).result;
                if (!db.objectStoreNames.contains(_ImagesManager.DB_STORE_NAME)) { // если хранилище "images" не существует
                    db.createObjectStore(_ImagesManager.DB_STORE_NAME, { keyPath: 'id' }); // создаем хранилище
                }
                resolve(db);
            };
        // продолжить работу с базой данных, используя объект db
        });
    }

    public async DB_Add (db, key, value): Promise<any> {
        const transaction = db.transaction(_ImagesManager.DB_STORE_NAME, 'readwrite');
        const images = transaction.objectStore(_ImagesManager.DB_STORE_NAME);
        const image = {
            id: key,
            value
        };
        return await new Promise((resolve, reject) => {
            const request = images.add(image);
            request.onsuccess = function () {
                resolve(null);
            // console.log("картинка добавлена в хранилище", request.result);
            };

            request.onerror = function (event) {
                if (event.target.error.name === 'ConstraintError') {
                    resolve(null);
                } else {
                    reject('DB_Add Error');
                }

            // console.log("Помилка додавання:", request.error);
            };
        });
    }

    public async DB_Get (db, key): Promise<any> {
        const transaction = db.transaction(_ImagesManager.DB_STORE_NAME, 'readwrite');
        const images = transaction.objectStore(_ImagesManager.DB_STORE_NAME);
        const data = { success: false, imgData: null };

        return await new Promise((resolve, reject) => {
            const request = images.get(key);
            request.onsuccess = function () {
                if (request.result !== undefined) {
                    data.success = true;
                    data.imgData = request.result.value;
                    resolve(data);
                // console.log("yes", request.result);
                } else {
                    resolve(data);
                // console.log("no");
                }
            };
            request.onerror = function () {
                reject('DB_Get Error');
            // console.log("Ошибка ебаная", request.error);
            };
        });
    }

    public async getSHA256 (imgName): Promise<string> {
        const hex = await this.digestMessage(imgName);
        return hex;
    }

    public async digestMessage (message): Promise<string> {
        const hash = new Sha256();
        hash.update(message);
        const hashBuffer = await hash.digest();

        // Перетворення буфера хеш-значення в шістнадцятковий рядок
        // const msgUint8 = new TextEncoder().encode(message);                           // encode as (utf-8) Uint8Array
        // const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);           // hash the message
        const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
        return hashHex;
    }
}

export const ImagesManager = new _ImagesManager();
