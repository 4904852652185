import { type HeatmapData } from '../Models/HeatmapData';
import { SectorMap } from './SectorMap';
import { Rectangle } from '../../Geometry';
import { TreemapCalculator } from '../TreeMap/TreemapCalculator';
import { type PartialIntervalManager } from '../PartialIntervals/PartialIntervalManager';
import { type IndustryMap } from './IndustryMap';
import { type SymbolMapItem } from './SymbolMapItem';

export class MapContainer {
    private readonly _sectorMapList: SectorMap [] = [];
    private _data: HeatmapData[] = [];
    private readonly _partialIntervalManager: PartialIntervalManager;

    get isEmpty (): boolean {
        return this._sectorMapList.length === 0;
    }

    get isVisible (): boolean {
        return this._sectorMapList.some(sector => sector.Visible);
    }

    constructor (partialIntervalManager: PartialIntervalManager) {
        this._partialIntervalManager = partialIntervalManager;
    }

    public Populate (heatmapData: HeatmapData[]): void {
        this.clearMap();
        this._data = heatmapData;

        const grouped = this._data.reduce<Record<string, HeatmapData[]>>((accumulator, currentValue) => {
            (accumulator[currentValue.sector] = accumulator[currentValue.sector] || []).push(currentValue);
            return accumulator;
        }, {});

        for (const group in grouped) {
            this._sectorMapList.push(new SectorMap(this, group, grouped[group]));
        }
    }

    public getData (): HeatmapData[] {
        return this._data;
    }

    public getSectorMapList (): SectorMap[] {
        return this._sectorMapList;
    }

    public getIndustryMapList (): IndustryMap[] {
        const list = [];
        for (const sector of this.getSectorMapList()) {
            for (const industry of sector.items) {
                list.push(industry);
            }
        }
        return list;
    }

    public getSymbolMapList (): SymbolMapItem[] {
        const list = [];
        for (const industry of this.getIndustryMapList()) {
            for (const symbol of industry.items) {
                list.push(symbol);
            }
        }
        return list;
    }

    public getSymbolBackgroundColor (changePerWeight: number): string {
        let weight = changePerWeight;
        if (isNullOrUndefined(changePerWeight)) {
            weight = 0;
        }

        if (this._partialIntervalManager.count === 0 || weight === 0) {
            return this._partialIntervalManager.getDefaultColor();
        }

        for (const interval of this._partialIntervalManager.getIntervals()) {
            if (interval.contains(weight)) {
                return interval.LeftColor;
            }
        }
        return this._partialIntervalManager.getDefaultColor();
    }

    public updateMap (width: number, height: number): void {
        this.updateVisibility();
        this.recalculateArea(new Rectangle(0, 0, width, height));
    }

    private clearMap (): void {
        this._sectorMapList.length = 0;
        this._data.length = 0;
    }

    public selectSector (sector: SectorMap): void {
        for (const sectorMap of this._sectorMapList) {
            sectorMap.isSelected = sectorMap === sector;
            sectorMap.isHidden = sectorMap !== sector;
        }
    }

    public selectIndustry (industry: IndustryMap): void {
        for (const sectorMap of this._sectorMapList) {
            for (const industryMap of sectorMap.items) {
                industryMap.isSelected = industryMap === industry;
                industryMap.isHidden = industryMap !== industry;
            }
        }
    }

    public clearSelection (): void {
        for (const sectorMap of this._sectorMapList) {
            sectorMap.isSelected = false;
            sectorMap.isHidden = false;
            for (const industryMap of sectorMap.items) {
                industryMap.isSelected = false;
                industryMap.isHidden = false;
            }
        }
    }

    private updateVisibility (): void {
        const allIntervals = this._partialIntervalManager.getIntervals();
        const selectedIntervals = this._partialIntervalManager.getSelectedIntervals();
        const isAllInvisible = selectedIntervals.length === 0;
        const isAllVisible = allIntervals.length === selectedIntervals.length;
        const sectorMap = this._sectorMapList;
        for (let sectorIndex = 0; sectorIndex < sectorMap.length; sectorIndex++) {
            const sector = sectorMap[sectorIndex];
            for (let industryIndex = 0; industryIndex < sector.items.length; industryIndex++) {
                const industry = sector.items[industryIndex];
                for (let symbolIndex = 0; symbolIndex < industry.items.length; symbolIndex++) {
                    const symbol = industry.items[symbolIndex];

                    if (isNullOrUndefined(symbol.heatmapData) || isNullOrUndefined(symbol.heatmapData.changePercent)) {
                        continue;
                    }

                    if (isAllInvisible || sector.isHidden || industry.isHidden) {
                        symbol.Visible = false;
                        continue;
                    }
                    if (isAllVisible) {
                        symbol.Visible = true;
                        continue;
                    }

                    let isVisible: boolean = false;
                    for (let selectedIntervalIndex = 0; selectedIntervalIndex < selectedIntervals.length; selectedIntervalIndex++) {
                        const interval = selectedIntervals[selectedIntervalIndex];
                        if (interval.contains(symbol.heatmapData.changePercent)) {
                            isVisible = true;
                            break;
                        }
                    }
                    symbol.Visible = isVisible;
                }
            }
        }
    }

    private recalculateArea (areaRectangle: Rectangle): void {
        const treemapContainer = new TreemapCalculator();
        const list = this._sectorMapList;
        const visibleList = list.filter(y => y.Visible);
        treemapContainer.recalculate(visibleList, areaRectangle);
        for (const sector of visibleList) {
            sector.recalculateArea();
        }
    }
}
