// Copyright TraderEvolution Global LTD. © 2017-2023. All rights reserved.
import { SymbolSvgLogoManger } from '../../../../Commons/UtilsClasses/SymbolSvgLogoManger';
import { type Alert } from '../../../../Commons/cache/Alert';
import { type Instrument } from '../../../../Commons/cache/Instrument';
import { Resources } from '../../../../Commons/properties/Resources';
import { AlertSubImage, AlertSymbolConditionMap, AlertType, AlertTypeMap } from '../../../../Utils/Alert/AlertConstants';
import { AlertItemSideBarTemplate } from '../../../../templates';
import { ControlsTypes } from '../../../UtilsClasses/FactoryConstants';
import { Control } from '../../Control_ts';

export class AlertItemSideBar extends Control {
    public Alert: Alert = null;

    oncomplete (): void {
        super.oncomplete();
        this.on('onItemClick', this.onItemClick);
        this.observe('alert', this.updateAlertData);
    }

    private setDataAlert (): void {
        const alert: Alert = this.get('alert');
        this.Alert = alert;
        if (isNullOrUndefined(alert)) { return; }

        const instrument: Instrument = alert.Instrument;
        const symbolName = instrument.DisplayName();
        const symbolDescription = instrument.DescriptionValue();
        const conditionText = this.getConditionValue(alert, instrument);

        this.setActiveState(alert);
        void this.set({ symbolName, symbolDescription, conditionText });
    };

    private updateAlertData (newValue: Alert, oldValue: Alert): void {
        if (!oldValue || newValue.AlertId !== oldValue.AlertId) {
            this.setDefaultLogo(newValue.Instrument);
            void this.getImg(newValue.Instrument);
        }

        this.setDataAlert();
    }

    public getType (): ControlsTypes {
        return ControlsTypes.AlertItemSideBar;
    }

    private setActiveState (alert: Alert): void {
        const activeAlert = alert.Active;
        let activeText = '';

        if (activeAlert) {
            activeText = Resources.getResource('screen.Alerts.item.active');
        } else {
            activeText = Resources.getResource('screen.Alerts.item.stopped');
        }

        void this.set({ activeAlert, activeText });
    }

    private getConditionValue (alert: Alert, instrument: Instrument): string {
        let formattedValue = '';

        if (alert.AlertType === AlertType.CHANGE) {
            formattedValue = alert.Value.toFixed(2);
        } else if (alert.AlertType === AlertType.VOLUME) {
            formattedValue = alert.Value.toString();
        } else {
            formattedValue = instrument.formatPrice(alert.Value);
        }

        const comment = alert.MessageText;
        const alertTypeText = Resources.getResource('panel.watchlist.' + AlertTypeMap[alert.AlertType]);
        const alertSymbolCondition = AlertSymbolConditionMap[alert.Condition];

        let value = `${alertTypeText} ${alertSymbolCondition} ${formattedValue}`;
        if (isValidString(comment)) {
            value += ` | ${comment}`;
        }

        return value;
    }

    private setDefaultLogo (instrument): void {
        const symbolLogoStr = instrument.LogoName();
        const svgLogo = SymbolSvgLogoManger.getDefaultImage(symbolLogoStr);
        void this.set({ svgLogo });
    }

    private async getImg (instrument): Promise<void> {
        const svgImage = await SymbolSvgLogoManger.LoadSvgImage(instrument.LogoAddress);
        if (svgImage) {
            void this.set('svgLogo', svgImage);
        }
    }

    onActionButtonClick (context, action): void {
        this.fire(AlertEvents.ActionClick, this, action);
        context.event.stopPropagation();
    }

    onItemClick (): void {
        this.fire(AlertEvents.ActionClick, this, AlertSubImage.UPDATE);
    }
}

Control.extendWith(AlertItemSideBar,
    {
        template: AlertItemSideBarTemplate,
        data: function () {
            return {
                alert: null,
                symbolName: '',
                symbolDescription: '',
                svgLogo: '',
                conditionText: '',
                activeAlert: false,
                activeText: '',
                playStopAction: AlertSubImage.PLAY_STOP,
                editAction: AlertSubImage.UPDATE,
                closeAction: AlertSubImage.CLOSE
            };
        }
    });

const enum AlertEvents {
    ActionClick = 'actionClick'
}
