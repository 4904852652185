// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

export const BussinessRejectErrorDictionary =
{
    419: 'BusinessRejectMessage.419',
    417: 'Resources.Property.Opening market orders are forbidden',
    103: 'reports.Order TIF is forbidden by trade session',
    371: 'Session has expired. Please login.',
    432: 'Product request cannot be processed.',
    433: 'The total limit of alerts has been reached.',
    434: 'Alert cannot be created due to wrong node.',
    435: 'Bid/Ask prices are not available for the instrument.',
    436: 'Product subscription failure: Unable to subscribe because there are no active accounts for charging on product.',
    437: 'Product subscription failure: Not enough balance to pay fee.',
    446: 'Product is no longer relevant for use',
    453: 'BusinessRejectMessage.453'
    // "Alert has not been created, the maximum number of alerts for the user has been reached."  //ожидаю код id:99971
};

export const BussinessRejectTextCodeDict =
{
    'RiskManagement.Messages.WeeklyLimit': 1001,
    'RiskManagement.Messages.MaxTrailingLimit': 1002,
    'RiskManagement.Messages.DailyLimit': 1003,
    'RiskManagement.Messages.MaxUnrealizedLossLimit': 1004,
    'RiskManagement.Messages.MaxDailyProfit': 1005,
    'RiskManagement.Messages.MaxOrdersCountPerDay': 1006,
    'RiskManagement.Messages.MaxRelativeDrawDownLevel': 1007,
    'RiskManagement.Messages.EODTrailingDrawDownLevel': 1008
};

export const BussinessRejectMessagesKey = {
    WeeklyLimit: 'RiskManagement.Messages.WeeklyLimit',
    MaxTrailingLimit: 'RiskManagement.Messages.MaxTrailingLimit',
    DailyLimit: 'RiskManagement.Messages.DailyLimit',
    MaxUnrealizedLossLimit: 'RiskManagement.Messages.MaxUnrealizedLossLimit',
    MaxDailyProfit: 'RiskManagement.Messages.MaxDailyProfit',
    MaxOrdersCountPerDay: 'RiskManagement.Messages.MaxOrdersCountPerDay',
    MaxRelativeDrawDownLevel: 'RiskManagement.Messages.MaxRelativeDrawDownLevel',
    EODTrailingDrawDownLevel: 'RiskManagement.Messages.EODTrailingDrawDownLevel'
};
