// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DirectAssetMessage } from '../../../../Utils/DirectMessages/DirectMessagesImport';
import { FieldsFactory } from '../Factory/FieldsFactory';
import { type PFixFieldSet } from '../Fields/FieldsImport';
import { MessageProcessorBase } from './MessageProcessorBase';

export class AssetTypeMessageProcessor extends MessageProcessorBase {
    public override decode (fs: PFixFieldSet): DirectAssetMessage[] {
        const msg = new DirectAssetMessage();

        msg.Id = fs.GetValue(FieldsFactory.FIELD_ID);
        msg.Type = fs.GetValue(FieldsFactory.FIELD_TYPE);
        // Assets feature
        // if (msg.Type === AssetType.SHARES)
        // {
        msg.RouteId = fs.GetValue(FieldsFactory.FIELD_ROUTE_ID);
        msg.InstrumentTradableID = fs.GetValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID);
        msg.CreditForSell = fs.GetValue(FieldsFactory.FIELD_CREDIT_FOR_SELL);
        msg.LiquidityRate = fs.GetValue(FieldsFactory.FIELD_LIQUIDITY_RATE);
        // }

        msg.Name = fs.GetValue(FieldsFactory.FIELD_NAME);
        msg.Description = fs.GetValue(FieldsFactory.FIELD_DESCRIPTION);
        msg.MinChange = fs.GetValue(FieldsFactory.FIELD_MIN_CHANGE);

        return [msg];
    }
}
