// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { ProductType } from '../Instruments/ProductType';
import { OperationType } from '../Trading/OperationType';
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectTradeMessage extends DirectMessageBaseClass {
    public TradeId: string | null = null;
    public OrderId: string | null = null;
    public Symbol: any = null;
    // Под нефикс список, для прочих - бери Symbol и не парься
    public InstrumentID = 0;
    public AccountId: string | null = null;
    public Amount = 0;
    public Price = 0;
    public Comission = 0;
    public Rebates = 0;
    public PnL = 0;
    public Time: Date | null = null;
    public BuySell = OperationType.Buy;
    public Login: string | null = null;
    public Route: number | null = null;
    public ExternalPrice = 0;
    public Exchange = '';
    public OrderType = 0;
    public PositionID: string | null = null;
    public LastPositionAmount = 0;
    public PositionSide = 0;
    public OpenCrossPrise = 0;
    public ExternalTradeId: string | null = null;
    public ExternalOrderId: string | null = null;
    // пока только для бтлдера, но надеюсь серв тоже как-нибудь сможет такое слать
    public IsClosing = false;
    public CounterOrderID = '';
    public TradeIdLabel: any = null;
    public ComissionInInstrumentQuotingCurrency: any = null;
    public PnLInInstrumentQuotingCurrency: any = null;
    public AdvancedTradeParams: any = null;
    // Используется для начисления свопа не на баланс
    // а на pnl (без необходимости не трогать)
    public Swap = 0;
    public ProductType = ProductType.General;
    public InstrumentTradableID: number | null = null;
    public ExecutionVenue: string | null = null;
    public Leverage: number | null = null;

    constructor () {
        super(Message.CODE_TRADE);
    }

    get ComissionCrossPrice (): number {
        return this.Comission * this.OpenCrossPrise;
    }

    get RebatesCrossPrice (): number {
        return this.Rebates * this.OpenCrossPrise;
    }

    get PnLCrossPrice (): number {
        return this.PnL * this.OpenCrossPrise;
    }
}

// для дополнительных параметров, которые присылает вендор
export class AdvancedTradeParams {
    public CommissionAssetID: any;

    constructor () {
    // для случая когда коммиссия приходит в различной валюте
    // (обычно либо в валюте аккаунта, либо в валюте котирования)
        this.CommissionAssetID = null;
    }
}
