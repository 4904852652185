// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { PFixFieldSet } from '../Fields/FieldsImport';
import { FieldsFactory } from '../Factory/FieldsFactory';
import { LineGroup } from './GroupsImport';
import { UrlUtils } from '../../../../Utils/UrlUtils';

export class NewsGroup extends PFixFieldSet {
    constructor (pfixFieldSet) {
        super(FieldsFactory.NEWS_GROUP, null, pfixFieldSet);
    }

    public getContent (): any {
        let text = this.getText();
        let URL = null;

        if (text) {
            text = text.trim();
        } // #99472

        if (text && text.length > 0) {
            const urls = UrlUtils.ParseTextForURL(text, true);
            let hasText = false;
            for (let i = 0; i < urls.length; i++) {
                const url = urls[i];
                if (!url.value.length) {
                    continue;
                }

                if (url.isText) {
                    hasText = true;
                } else {
                    URL = url.value;
                }
            }

            if (hasText) {
                return text;
            } // Заполняем из тега TEXT[115] если в данном теге пришел текст или текст вместе с ссылкой.
        }

        const line0 = this.getAllLines(); // this.getContentByLineNumber(0)    // <- #99562
        if (line0 && line0.length > 0) {
            return line0;
        } // Если в теге TEXT[115] ничего не пришло, либо пришла только ссылка, то заполняем контент из тега TEXT[115] с LINE_NUMBER[118]=0

        if (URL) {
            return {
                isUrl: true,
                url: URL // если в этом теге нет данных но есть ссылка в TEXT[115] то в качестве контента отображаем кликабельную ссылку.
            };
        }

        return '';
    }

    public getContentByLineNumber (lineNumber): any {
        const groups = this.GetGroups(FieldsFactory.LINE_GROUP_NAME);
        for (let i = 0; i < groups.length; i++) {
            const line = new LineGroup(groups[i]);
            if (line.getLineNum() == lineNumber) {
                return line.getContent();
            }
        }

        return null;
    }

    public getAllLines (): string {
        return LineGroup.getLongText(this);
    }

    public getText (): string {
        return this.GetValue(FieldsFactory.FIELD_TEXT);
    }

    public setText (text: string): void {
        this.SetField(FieldsFactory.FIELD_TEXT, text);
    }

    public getTheme (): string {
        return this.GetValue(FieldsFactory.FIELD_THEME);
    }

    public setTheme (theme: string): void {
        this.SetField(FieldsFactory.FIELD_THEME, theme);
    }

    public getRouteID (): number {
        return this.GetValue(FieldsFactory.FIELD_ROUTE_ID);
    }

    public setRouteID (routeID: number): void {
        this.SetField(FieldsFactory.FIELD_ROUTE_ID, routeID);
    }

    public getSource (): string {
        return this.GetValue(FieldsFactory.FIELD_SOURCE);
    }

    public setSource (source: string): void {
        this.SetField(FieldsFactory.FIELD_SOURCE, source);
    }

    public getNewsID (): number {
        return this.GetValue(FieldsFactory.FIELD_NEWS_ID);
    }

    public setNewsID (newsID: number): void {
        this.SetField(FieldsFactory.FIELD_NEWS_ID, newsID);
    }

    public getSymbol (): string {
        return this.GetValue(FieldsFactory.FIELD_SYMBOL);
    }

    public setSymbol (symbol: string): void {
        this.SetField(FieldsFactory.FIELD_SYMBOL, symbol);
    }

    public getCategory (): string {
        return this.GetValue(FieldsFactory.FIELD_NEWS_CATEGORY);
    }

    public setCategory (category: string): void {
        this.SetField(FieldsFactory.FIELD_NEWS_CATEGORY, category);
    }

    public getPriority (): number {
        return this.GetValue(FieldsFactory.FIELD_PRIORITY);
    }

    public setPriority (priority: number): void {
        this.SetField(FieldsFactory.FIELD_PRIORITY, priority);
    }

    public getDate (): Date | null {
        return this.GetValue(FieldsFactory.FIELD_DATE);
    }

    public setDate (date: Date): void {
        this.SetField(FieldsFactory.FIELD_DATE, date);
    }
}
