// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type IHistoryAggregationInfo } from './HistoryInterfaces';

export class BaseIntervalInputParams {
    public LeftTimeTicks: number;
    public Open: number;
    public High: number;
    public Low: number;
    public Close: number;
    public Volume: number;
    public Ticks: number;

    // public FRightTimeTicks: number;
    public correctStartTime: boolean;
    public onlyMainSession: boolean; // ?????
    public instrument: IHistoryAggregationInfo; // ?????
}
