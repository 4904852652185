// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomErrorClass, ErrorInformationStorage } from "../../Commons/ErrorInformationStorage.ts";
import { Resources } from "../../Commons/properties/Resources.ts";
import { ModifyPositionScreenTemplate, OrderEditControlsTemplate, OrderEditParameterVisibilityTemplate } from "../../templates.js";
import { Control } from "../elements/Control.js";
import { TerceraButton } from "../elements/TerceraButton.ts";
import { ScreensNames } from "../UtilsClasses/FactoryConstants.ts";
import { OrderEditViewBase } from "../panels/OrderEditViewBase.js";
import { Quantity } from "../../Utils/Trading/Quantity.ts";
import { DataCache } from "../../Commons/DataCache.ts";
import { PositionEdit } from "../../Commons/cache/OrderParams/PositionEdit.ts";
import { TradingNumericErrorChecker } from "../../Commons/Trading/TradingNumericErrorChecker.ts";

// TODO. Refactor. Duplicate code of ModifyOrderScreen.
export let ModifyPositionScreen = OrderEditViewBase.extend({
    data: function () 
    {
        return {
            resizable: false,
            width: 340,
            // height: 500,
            dockablePanel: false,
            showHeader: false,
            showFooter: true,
            header: '',
            showBorder: false,
            infoViewBottom: 0,
            // showFullscreenCloud: true, //TODO 83829

            position: null,
            quantity: null,
            SLNumericLink: null,
            TPNumericLink: null,
            MQNumericLink: null,
            qSLTPParams:
            {
                width: 170,
                left: 130,
                margin: 30
            }
        }
    },
    template: ModifyPositionScreenTemplate,
    partials: {
        // TODO. Refactor.
        orderEditControls: OrderEditControlsTemplate,
        orderEditParameterVisibility: OrderEditParameterVisibilityTemplate
    }
})

ModifyPositionScreen.prototype.getType = function ()
{
    return ScreensNames.ModifyPositionScreen
}

ModifyPositionScreen.prototype.oninit = function ()
{
    OrderEditViewBase.prototype.oninit.call(this)
    this.observe('position', this.onPosition)
    this.observe('tradingAllowed', this.onTradingAllowed)
    // TODO. Refactor. 
    // TickAsync/Localize doesn't work for panels added with addScreen method.
    Resources.onLocaleChange.Subscribe(this.localize, this)
    Control.Ticker.Subscribe(this.TickAsync, this)
}

// TODO. Refactor.
ModifyPositionScreen.prototype.oncomplete = function ()
{
    OrderEditViewBase.prototype.oncomplete.call(this)

    this.onTradingAllowed(this.get('tradingAllowed'))
    this.localize()
}

ModifyPositionScreen.prototype.dispose = function ()
{
    Resources.onLocaleChange.UnSubscribe(this.localize, this)
    Control.Ticker.UnSubscribe(this.TickAsync, this)

    OrderEditViewBase.prototype.dispose.call(this)
}

ModifyPositionScreen.prototype.localize = function ()
{
    this.updatePanelHeader();
    this.set({
        modifyBtnText: Resources.getResource('screen.modifyOrder.modify'),
        cancelBtnText: Resources.getResource('general.messageBox.cancel')
    });
}

ModifyPositionScreen.prototype.onPosition = function (position)
{
    if (!position)
        return

    this.setOrderEdit(new PositionEdit({
        dataCache: DataCache,
        position: position,
        quantity: new Quantity(position.Amount, true),
    }))

    this.updatePanelHeader()
}

ModifyPositionScreen.prototype.onTradingAllowed = function (allowed)
{
    let footerContainer = this.Controls.windowPanelFooter
    if (!footerContainer)
        return

    let modifyBtn = footerContainer.Controls['modifyBtn']
    if (modifyBtn) modifyBtn.set('enabled', allowed)
}

ModifyPositionScreen.prototype.updatePanelHeader = function ()
{
    let pos = this.get('position')
    if (!pos) return

    this.set('header',
        Resources.getResource('screen.modifyOrder.modifyPosition') +
        ' (' +
        pos.PositionId +
        ')')
}

ModifyPositionScreen.prototype.layout = function ()
{
    // tODO. Refactor.
    this.updateOrderEditParametersVisibility();
}

ModifyPositionScreen.prototype.modify = function ()
{
    if (TradingNumericErrorChecker.HasErrors(this))
        return

    let oEd = this.orderEdit;
    oEd.placedFrom = this.get('placedFrom')
    oEd.UseSkipNoChange = true;
    let ordExec = DataCache.FOrderExecutor
    let handler = () => ordExec.modifyPositionPromise(oEd)
    if (oEd.IsQuantityModified && oEd.IsQuantityModified())
        handler = () => ordExec.modifyPositionProductType(oEd);
    handler()
        .then((confirmed) =>
        {
            if (confirmed) this.closeScreen()
        })
        .catch((err) =>
        {
            if (err === "cancell clicked")
                return

            let ex = new CustomErrorClass("ModifyPositionScreen error", "ModifyPositionScreen.modify", "modify -> modifyPositionPromise");
            ErrorInformationStorage.GetException(ex);
        })
}

ModifyPositionScreen.prototype.closeScreen = function ()
{
    let parentContainerControl = this.get('parentContainerControl')

    if (parentContainerControl)
        parentContainerControl.close()
}

// ModifyPositionScreen.show = function (positionId, placedFrom, numericLinkName)  // old and useless -> check EditPositionScreen.js
// {
//     let screen = Factory.addScreen(ScreensNames.ModifyPositionScreen)
//     screen.set('position', DataCache.getPositionById(positionId))

//     if (numericLinkName)
//         screen.activateNumeric(numericLinkName)
// }