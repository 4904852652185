import { PriceFormatter } from '../../../../Utils/Instruments/PriceFormatter';
import { MathUtils } from '../../../../Utils/MathUtils';

class _GreeksFormatter {
    private readonly NOT_AVAILABLE = '---';

    formatGreek (greek: number): string {
        if (isNaN(greek)) {
            return this.NOT_AVAILABLE;
        }
        const prec = MathUtils.GetValuePrecision(greek, 2, 5);
        return PriceFormatter.formatPrice(greek, prec);
    }

    formatIVPercent (iv: number): string {
        if (isNaN(iv)) {
            return this.NOT_AVAILABLE;
        }
        return `${this.formatIVValue(iv)} %`;
    }

    formatIVValue (iv: number): string {
        return PriceFormatter.formatPrice(iv * 100, 3);
    }
}

export const GreeksFormatter = new _GreeksFormatter();
