// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomErrorClass, ErrorInformationStorage } from "../../Commons/ErrorInformationStorage.ts";
import { Resources } from "../../Commons/properties/Resources.ts";
import { OrderTypeBase } from "../../Commons/cache/OrderParams/order-type/OrderTypeBase.ts";
import { AdvancedChartOrderEntryTemplate, OrderEntryChartSellButtonsTemplate, OrderEntryChartBuyButtonsTemplate } from "../../templates.js";
import { ContainerControl } from "../elements/ContainerControl.js";
import { OrderEntryBase } from "../OrderEntryBase.js";
import { OETIFSelector } from "../trading/OE/OETIFSelector.js";
import { SlTpPriceType } from "../../Utils/Enums/Constants.ts";
import { OperationType } from "../../Utils/Trading/OperationType.ts";
import { OrderType } from "../../Utils/Trading/OrderType.ts";
import { PlacedFrom } from "../../Utils/Trading/PlacedFrom.ts";
import { Account } from "../../Commons/cache/Account.ts";
import { TIF } from "../../Utils/Trading/OrderTif.ts";
import { GeneralSettings } from "../../Utils/GeneralSettings/GeneralSettings.ts";
import { OrderUtils } from "../../Utils/Trading/OrderUtils.ts";
import { NumericUtils } from "../../Utils/NumericUtils.ts";
import { TradingLockUtils } from "../../Utils/TradingLockUtils.ts";
import { IsAllowed } from "../../Commons/IsAllowed.ts";
import { Quantity } from "../../Utils/Trading/Quantity.ts";
import { SlTpHolder } from "../../Utils/Trading/SlTpHolder.ts";
import { OrderEditUpdateData } from "../../Utils/Trading/OrderEditUpdateData.ts";
import { SLTPTriggerUtils } from "../../Commons/cache/OrderParams/SLTPTriggerUtils.ts";
import { OCOCustomOrdersEdit } from "../../Commons/cache/OrderParams/order-edit/OCOCustomOrdersEdit.ts";
import { DataCache } from "../../Commons/DataCache.ts";
import { SessionSettings } from "../../Commons/SessionSettings.ts";
import { TradingNumericErrorChecker } from "../../Commons/Trading/TradingNumericErrorChecker.ts";
import { ControlsTypes } from "../UtilsClasses/FactoryConstants.ts";

export let AdvancedChartOrderEntry = ContainerControl.extend({
    template: AdvancedChartOrderEntryTemplate,
    partials: {
        chartSellButtons: OrderEntryChartSellButtonsTemplate,
        chartBuyButtons: OrderEntryChartBuyButtonsTemplate
    },
    data: function ()
    {
        return {
            accountItem: null,
            instrumentItem: null,

            oeSmall: true,
            reverseButtons: false,
            enabledSL: false,
            enabledTP: false,

            isSlTPVisible: true,
            isSlVisible: true,
            isTPVisible: true,
            isSLTPheight: true,
            isSLLVisible: false,

            priceSL: 0.1,
            priceSLL: 0.1,  // #91413
            priceTP: 0.1,
            minSLPrice: 0.1,
            maxSLPrice: 99999999,
            minSLLPrice: -99999999,
            minTPPrice: 0.1,
            maxTPPrice: 99999999,
            slNumericStep: 0.00001,
            tpNumericStep: 0.00001,
            slNumericPrecision: 2,
            tpNumericPrecision: 2,

            trStopChecked: false,
            trStopAllowed: true,
            numericsErrors: {},

            sellMarketButtonOnSmallOE: true,
            sellBidButtonOnSmallOE: true,
            addAskButtonOnSmallOE: true,

            _userPreferredShowBuySellMarket: true,
            _userPreferredShowSellBidBuyAsk: true,
            _userPreferredShowAddBidAsk: true,

            addAskText: 'Add Ask',
            sellBidText: 'Sell Bid',
            sellMarketText: 'Sell Market',
            buyMarketText: 'Buy Market',
            buyAskText: 'Buy Ask',
            addBidText: 'Add Bid',
            sllLabelTooltip: '',

            tif: null,
            items: null,
            gtdDate: null,
            orderTypeId: null,
            selectedItem: null,
            productType: null,
            productTypeShow: false,
            isLeverageVisible: false,
            slTopOffset: null,
            sllTopOffset: null,
            tpTopOffset: null,
            sltpTopOffset: null,

            // TODO. Remove. Ugly.
            defaultTif: null
        };
    },
});

AdvancedChartOrderEntry.prototype.getType = function () { return ControlsTypes.AdvancedChartOrderEntry; }

// http://tp.pfsoft.net/entity/57421
// TODO.
AdvancedChartOrderEntry.prototype.oncomplete = function ()
{
    ContainerControl.prototype.oncomplete.apply(this);
    this.set({
        accountItem: this.get('accountItem'),
        instrumentItem: this.get('instrumentItem')
    });
    this.observe('instrumentItem', this.onInstrumentItemChanged);
    this.observe('accountItem', function (newItem, oldItem, key) { });
    this.observe('showSLTP', function (newValue, oldVal)
    {
        this.showHideSLTP(newValue);
        // this.onrender();
    });
    this.observe('enabledSL trStopChecked', this.sLLVisibleCheck)

    this.localize();
};


AdvancedChartOrderEntry.prototype.showHideSLTP = function (isShow)
{
    if (isShow)
    {
        var myHeigth = 500;
        this.set({ height: myHeigth });
        //разместить размер с вдимыми слтп
    }
    else
    {
        this.set({ height: myHeigth - 85 });
        //разместить размер с не вдимыми слтп
    }
};

AdvancedChartOrderEntry.prototype.oninit = function (options)
{
    ContainerControl.prototype.oninit.apply(this, [options]);

    this.set('numericsErrors', {});

    this.set('reverseButtons', GeneralSettings.View.ReverseButtonsOrder);

    this.observe('accountItem instrumentItem', this.updateTradingAllowedStuff)
    this.observe('instrumentItem defaultTif', this.repopulateTIFComboBox)
    this.observe('selectedItem gtdDate', this.updateTIF)
    this.observe('productType', this.onProductTypeChanged)

    TradingLockUtils.TradingLock.TradingLockChanged.Subscribe(this.updateTradingAllowedStuff, this);
    Account.TradeStatusChanged.Subscribe(this.updateTradingAllowedStuff, this);

    GeneralSettings.InsDefSettingsStorage.DefaultSettingsChanged.Subscribe(this.onDefaultSettingsChanged, this);
    DataCache.OnUpdateInstrument.Subscribe(this.onUpdateInstrument, this);

    this.on('add_Ask', this.addAskButton_Click);
    this.on('sell_Bid', this.sellBidButton_Click);
    this.on('sell_market', this.sellMarketButton_Click);
    this.on('buy_market', this.buyMarketButton_Click);
    this.on('buy_Ask', this.buyAskButton_Click);
    this.on('add_Bid', this.addBidButton_Click);
};

AdvancedChartOrderEntry.prototype.recalcOffsets = function ()
{
    let top = this.get('top') || 0,
        isProductTypeVisible = this.get('productTypeShow'),
        isLeverageVisible = this.get('isLeverageVisible'),
        isSlVisible = this.get('isSlVisible'),
        isTPVisible = this.get('isTPVisible'),
        isSLLVisible = this.get('isSLLVisible'),
        isSlTPVisible = this.get('isSlTPVisible'),
        isSLTPheight = this.get('isSLTPheight')

    let dTop = 33
    let sltpTopOffset = (isSlTPVisible ? (isSLTPheight ? 133 : 97) : 67) + (isProductTypeVisible ? 33 : 0) + (isSLLVisible ? 33 : 0)
    let slTopOffset = isProductTypeVisible ? 99 : 66
    let sllTopOffset = slTopOffset + 33
    let tpTopOffset = (isSlVisible ? (isProductTypeVisible ? 132 : 99) : (isProductTypeVisible ? 99 : 66)) + (isSLLVisible ? 33 : 0)

    if (isLeverageVisible)
    {
        sltpTopOffset += dTop
        tpTopOffset += dTop
        slTopOffset += dTop
    }

    this.set({ 'sltpTopOffset': sltpTopOffset, 'slTopOffset': slTopOffset, 'sllTopOffset': sllTopOffset, 'tpTopOffset': tpTopOffset })
}

AdvancedChartOrderEntry.prototype.dispose = function ()
{
    TradingLockUtils.TradingLock.TradingLockChanged.UnSubscribe(this.updateTradingAllowedStuff, this);
    Account.TradeStatusChanged.UnSubscribe(this.updateTradingAllowedStuff, this);
    GeneralSettings.InsDefSettingsStorage.DefaultSettingsChanged.UnSubscribe(this.onDefaultSettingsChanged, this);
    DataCache.OnUpdateInstrument.UnSubscribe(this.onUpdateInstrument, this);

    let instrument = this.get('instrumentItem');
    if (instrument && !instrument.IsEmpty)
        instrument.RiskSettingsUpdated.UnSubscribe(this.updateTradingAllowedStuff, this)

    ContainerControl.prototype.dispose.call(this);
};

AdvancedChartOrderEntry.prototype.updateSettings = function ()
{
    // ApplicationPanelNew.prototype.updateSettings.apply(this);
    this.set('reverseButtons', GeneralSettings.View.ReverseButtonsOrder);

    this.onInstrumentItemChanged(this.get('instrumentItem'));

    this.sLLVisibleCheck()
};

AdvancedChartOrderEntry.prototype.onInstrumentItemChanged = function (newItem, prewItem)
{
    if (!newItem)
        return

    this.subscribeRiskSettingsUpdate(newItem, prewItem)

    this.clearNumerics();
    var settings = NumericUtils.getNumericsOffsetModeViewParams(newItem, true);
    this.set({
        tpNumericStep: settings.step,
        slNumericStep: settings.step,
        tpNumericPrecision: settings.numericsPrec,
        slNumericPrecision: settings.numericsPrec,
        minSLPrice: settings.step,
        minTPPrice: settings.step,
        priceSL: settings.step,
        priceSLL: settings.step,
        priceTP: settings.step,

    });

    this.setDefaultSLTP()

    // this.set('productTypeShow', newItem.isProductTypeVisible(this.get('accountItem')))
    this.recalcOffsets()
};

AdvancedChartOrderEntry.prototype.subscribeRiskSettingsUpdate = function (instrument, lastInstrument)
{
    if (lastInstrument)
        lastInstrument.RiskSettingsUpdated.UnSubscribe(this.updateTradingAllowedStuff, this)

    if (instrument)
        instrument.RiskSettingsUpdated.Subscribe(this.updateTradingAllowedStuff, this)
}

AdvancedChartOrderEntry.prototype.onUpdateInstrument = function (instrument)
{
    let myInstrument = this.get('instrumentItem');
    if (!isNullOrUndefined(myInstrument) && myInstrument === instrument)
    {
        this.updateTradingAllowedStuff();
    }
}

AdvancedChartOrderEntry.prototype.updateTradingAllowedStuff = function ()
{
    let acc = this.get('accountItem')
    let ins = this.get('instrumentItem')
    if (!acc || !ins || ins.IsEmpty)
        return

    this.PopulateOrderTypes();

    let tradingMarketAllowed = IsAllowed.IsTradingAllowed([acc], ins, OrderType.Market);
    let tradingLimitAllowed = OrderTypeBase.IsSupported(OrderType.Limit, ins);
    let tradingLimitAllowed_s = IsAllowed.IsTradingAllowed([acc], ins, OrderType.Limit);

    let slVisible = IsAllowed.IsSLTPAllowed(ins, acc, true).Allowed;
    let tpVisible = IsAllowed.IsSLTPAllowed(ins, acc, false).Allowed;
    let sltpVisible = slVisible || tpVisible;
    let sltpHeight = slVisible && tpVisible;

    let newProductTypeShow = ins.isProductTypeVisible(acc)
    if (this.get('productTypeShow') != newProductTypeShow)
    {
        this.set('productTypeShow', newProductTypeShow)
        this.recalcOffsets()
    }

    this.set({
        isSlVisible: slVisible,
        isTPVisible: tpVisible,
        isSlTPVisible: sltpVisible,
        isSLTPheight: sltpHeight,
        tradingMarketAllowed: tradingMarketAllowed.Allowed,
        tradingMarketAllowedReason: tradingMarketAllowed.ReasonText,
        tradingLimitAllowed: tradingLimitAllowed_s.Allowed && tradingLimitAllowed,
        tradingLimitAllowedReason: tradingLimitAllowed_s.ReasonText,
        trStopAllowed: IsAllowed.IsTrailingStopForSLAllowed(ins, acc).Allowed
    })
};

AdvancedChartOrderEntry.prototype.PopulateOrderTypes = function ()
{
    var ins = this.get('instrumentItem');
    if (!ins || ins.IsEmpty) return;

    if (DataCache.OrderParameterContainer === null)
        return;

    let orderTypes = OrderEntryBase.PopulateOrderTypes(this.get('accountItem'), ins, null, GeneralSettings.TradingDefaults.OrderType, false);

    var marketAllow = orderTypes.indexOf(OrderType.Market) > -1;
    var limitAllow = orderTypes.indexOf(OrderType.Limit) > -1;
    let ShowLimitsBtns = !Resources.isHidden("panel.newOrderEntry.LimitOrderButtons.Visibility");
    this.set({
        buyMarketButtonOnSmallOE: marketAllow,
        buyAskButtonOnSmallOE: limitAllow && ShowLimitsBtns,
        addBidButtonOnSmallOE: limitAllow && ShowLimitsBtns,
        sellMarketButtonOnSmallOE: marketAllow,
        sellBidButtonOnSmallOE: limitAllow && ShowLimitsBtns,
        addAskButtonOnSmallOE: limitAllow && ShowLimitsBtns,
    });
};

AdvancedChartOrderEntry.prototype.clearNumerics = function ()
{
    this.set({
        tpNumericStep: 0,
        slNumericStep: 0,
        tpNumericPrecision: 0,
        slNumericPrecision: 0,
        minSLPrice: 0,
        minTPPrice: 0,
        priceSL: 0,
        priceTP: 0,
    });
};

AdvancedChartOrderEntry.prototype.localize = function ()
{
    this.set({
        addAskText: Resources.getResource('panel.newOrderEntry.AddAskButton'),
        sellBidText: Resources.getResource('panel.newOrderEntry.SellBidButton'),
        sellMarketText: Resources.getResource('panel.newOrderEntry.SellMarketButton'),
        buyMarketText: Resources.getResource('panel.newOrderEntry.BuyMarketButton'),
        buyAskText: Resources.getResource('panel.newOrderEntry.BuyAskButton'),
        addBidText: Resources.getResource('panel.newOrderEntry.AddBitButton'),
        sllLabelTooltip: 'panel.newOrderEntry.stopLimitLimitOffset.tt'
    });

};

AdvancedChartOrderEntry.prototype.onProductTypeChanged = function (productType)
{
    let ins = this.get('instrumentItem'),
        acc = this.get('accountItem')

    this.set('isLeverageVisible', ins ? ins.isLeverageVisible(acc, productType) : false)
}

AdvancedChartOrderEntry.prototype.onDefaultSettingsChanged = function ()
{
    this.update('instrumentItem');
};

AdvancedChartOrderEntry.prototype.addBidButton_Click = function ()
{
    let ins = this.get("instrumentItem")
    this.placeFromSmallBtn(OrderType.Limit, OperationType.Buy, ins.Level1.GetBid());
};

AdvancedChartOrderEntry.prototype.addAskButton_Click = function ()
{
    let ins = this.get("instrumentItem")
    this.placeFromSmallBtn(OrderType.Limit, OperationType.Sell, ins.Level1.GetAsk());
};

AdvancedChartOrderEntry.prototype.buyAskButton_Click = function ()
{
    let ins = this.get("instrumentItem")
    this.placeFromSmallBtn(OrderType.Limit, OperationType.Buy, ins.Level1.GetAsk());
};

AdvancedChartOrderEntry.prototype.sellBidButton_Click = function ()
{
    let ins = this.get("instrumentItem")
    this.placeFromSmallBtn(OrderType.Limit, OperationType.Sell, ins.Level1.GetBid());
};

AdvancedChartOrderEntry.prototype.buyMarketButton_Click = function ()
{
    let ins = this.get("instrumentItem")
    this.placeFromSmallBtn(OrderType.Market, OperationType.Buy);
};

AdvancedChartOrderEntry.prototype.sellMarketButton_Click = function ()
{
    let ins = this.get("instrumentItem")
    this.placeFromSmallBtn(OrderType.Market, OperationType.Sell);
};

// TODO. UGLY.
AdvancedChartOrderEntry.prototype.placeFromSmallBtn = function (type, side, price)
{
    let params = {
        dPrice: price,
        dStopPrice: price,
        lOrdType: type,
        lBuySell: side,
        lTif: this.get('selectedItem').value,
        expireTime: this.get('gtdDate'),
        productType: this.get('productType'),
        leverageValue: this.get('leverageValue'),
        placedFrom: PlacedFrom.WEB_CHART_OE
    }
    this.OrderParamsSet(params)
};

AdvancedChartOrderEntry.prototype.repopulateTIFComboBox = function ()
{
    OETIFSelector.GetAdvancedComboBoxItems([OrderType.Limit, OrderType.Market], this);
};

AdvancedChartOrderEntry.prototype.updateTIF = function ()
{
    OETIFSelector.GetAdvancedUpdateTIF(this);
};

AdvancedChartOrderEntry.prototype.OrderParamsSet = function (orderParams)
{
    if (TradingNumericErrorChecker.HasErrors(this))
        return

    if (!orderParams)
        return

    let orderType = orderParams.lOrdType
    let orderTypeObj = DataCache.OrderParameterContainer.OrderTypes[orderType]

    let orderEditCtorData = {
        dataCache: DataCache,
        // TODO. UGLY. Details are in OrderEditBase.ts
        forceSLTPOffset: true
    }

    // TODO. Ugly.
    let orderEdit = orderType === OrderType.OCO
        ? new OCOCustomOrdersEdit(orderEditCtorData)
        : orderTypeObj.createOrderEditObject(orderEditCtorData)

    orderEdit.updateParameters(new OrderEditUpdateData(
        null,
        {
            account: this.get('accountItem'),
            instrument: this.get('instrumentItem'),
            side: orderParams.lBuySell,

            quantity: new Quantity(
                orderParams.quantity || this.get('quantity'),
                GeneralSettings.View.displayAmountInLots()),

            tif: new TIF(orderParams.lTif, orderParams.expireTime),
            productType: orderParams.productType,
            leverageValue: orderParams.leverageValue,
            placedFrom: orderParams.placedFrom
        }, SessionSettings))

    let chartSLTPHolder = this.getChartSLTPHolder()

    // TODO. Ugly.
    switch (orderType)
    {
        case OrderType.Limit:
            orderEdit.setLimitPrice(orderParams.dPrice)
            break
        case OrderType.Stop:
            orderEdit.setStopPrice(orderParams.dStopPrice)
            break
        case OrderType.StopLimit:
            orderEdit.setBasePrice(orderParams.dStopPrice)
            break
        case OrderType.OCO:
            this.setOCOOrderEdit(orderEdit, orderParams)
            break
    }

    // TODO. Ugly.
    orderEdit.setSLTP(chartSLTPHolder)

    return DataCache.FOrderExecutor.placeOrderPromise(orderEdit)
        .catch(function () 
        {
            let ex = new CustomErrorClass("AdvancedChartOrderEntry error", "AdvancedChartOrderEntry.OrderParamsSet", "OrderParamsSet -> placeOrderPromise");
            ErrorInformationStorage.GetException(ex);
        })
        .finally(function ()
        {
            // TODO. Refactor.
            orderEdit.dispose()
        })
};

AdvancedChartOrderEntry.prototype.setOCOOrderEdit = function (orderEdit, orderParams)
{
    orderEdit.setOrdersTypes(
        orderParams.OCO_firstOrder_OrderType,
        orderParams.OCO_secondOrder_OrderType)

    orderEdit.setOrdersSides(
        orderParams.lBuySell,
        orderParams.OCO_secondOrder_Operation)

    // TODO.
    orderEdit.setOrdersPrices(
        orderParams.dPrice,
        orderParams.dStopPrice)

    let firstTif, secondTif;

    if (orderParams.lTif)
    {
        firstTif = secondTif = new TIF(orderParams.lTif, orderParams.expireTime)
    }
    else
    {
        let ins = this.get('instrumentItem')

        firstTif = new TIF(
            SessionSettings.getDefaultTifForOrderType(orderParams.OCO_firstOrder_OrderType),
            TIF.getDefaultExpireTimeForOrderType(orderParams.OCO_firstOrder_OrderType, ins))

        secondTif = new TIF(
            SessionSettings.getDefaultTifForOrderType(orderParams.OCO_secondOrder_OrderType),
            TIF.getDefaultExpireTimeForOrderType(orderParams.OCO_secondOrder_OrderType, ins))
    }

    orderEdit.setOrdersTifs(firstTif, secondTif)
};

AdvancedChartOrderEntry.prototype.getChartSLTPHolder = function ()
{
    // Chart SL/TP are always offset.
    let offsetType = GeneralSettings.TradingDefaults.ShowOffsetIn
    let instrument = this.get('instrumentItem')

    let sltpHolder = new SlTpHolder()

    let slEnabled = this.get('enabledSL') && !this.get('trStopChecked')
    let trStopEnabled = this.get('enabledSL') && this.get('trStopChecked')

    if (slEnabled || trStopEnabled)
    {
        sltpHolder.StopLossPriceValue =
            OrderUtils.toRawTicks(this.get('priceSL'), offsetType, instrument)

        sltpHolder.StopLossPriceType = trStopEnabled
            ? SlTpPriceType.TrOffset
            : SlTpPriceType.Offset

        if (this.get('isSLLVisible'))
            sltpHolder.StopLossLimitPriceValue =
                OrderUtils.toRawTicks(this.get('priceSLL'), offsetType, instrument)
    }

    let tpEnabled = this.get('enabledTP')
    if (tpEnabled)
    {
        sltpHolder.TakeProfitPriceValue =
            OrderUtils.toRawTicks(this.get('priceTP'), offsetType, instrument)

        sltpHolder.TakeProfitPriceType = SlTpPriceType.Offset
    }

    let anyEnabled = slEnabled || trStopEnabled || tpEnabled
    if (anyEnabled)     // #110841
        sltpHolder.SLTPTriggerShortValue = SLTPTriggerUtils.GetTriggersShortForInstrument(instrument)

    return sltpHolder
};

AdvancedChartOrderEntry.prototype.sLLVisibleCheck = function ()
{
    let slEnabled = this.get('enabledSL'),
        trStopChecked = this.get('trStopChecked'),
        useStopLimitInsteadStop = GeneralSettings.TradingDefaults.UseStopLimitInsteadStop

    let sllOldVisibility = this.get('isSLLVisible'),
        sllNewVisibility = slEnabled && !trStopChecked && useStopLimitInsteadStop,
        sllChanged = sllOldVisibility != sllNewVisibility

    this.set('isSLLVisible', sllNewVisibility)

    if (sllChanged)
        this.recalcOffsets()
}

AdvancedChartOrderEntry.prototype.setDefaultSLTP = function ()
{
    let sess = SessionSettings
    let offsetType = GeneralSettings.TradingDefaults.ShowOffsetIn    // Chart SL/TP are always offset.

    let instrument = this.get('instrumentItem')
    if (!instrument) return

    let insDefSettings = GeneralSettings.InsDefSettingsStorage.GetInstrumentSettings(instrument)
    let basePrice = null

    let slTickOffset = insDefSettings ? insDefSettings.SLDefaultOffsetTicksDecimal : 1
    let defaultSL = OrderUtils.ConvertTickOffset(instrument, offsetType, basePrice, slTickOffset)

    let tpTickOffset = insDefSettings ? insDefSettings.TPDefaultOffsetTicksDecimal : 1
    let defaultTP = OrderUtils.ConvertTickOffset(instrument, offsetType, basePrice, tpTickOffset)

    this.set({
        priceSL: defaultSL,
        priceSLL: defaultSL,
        priceTP: defaultTP
    });
}