// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from "../../Utils/CustomEvents.ts";
import { TerceraButtonEvents } from "../../Utils/Enums/ButtonEnums.ts";
import { PairColorPickerTemplate } from "../../templates.js";
import { ContainerControl } from "./ContainerControl.js";

export let PairColorPicker = ContainerControl.extend({
    data: function ()
    {
        return {
            pairColor: null
        };
    },
    template: PairColorPickerTemplate
});

PairColorPicker.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.apply(this);
    this.PairColorChanged = new CustomEvent();
    this.observe('pairColor', this.onPairColorChanged);
};

PairColorPicker.prototype.onPairColorChanged = function (newPairColor)
{
    this.fire(TerceraButtonEvents.onPairColorChanged, newPairColor);
    if (this.PairColorChanged) this.PairColorChanged.Raise(this, newPairColor);
};