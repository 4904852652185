// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { EventSource, EventType } from "../../Commons/cache/EventConstants.ts";
import { Resources } from "../../Commons/properties/Resources.ts";
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";
import { TerceraBaseWindowTemplate, TerceraTicketScreenTemplate, TerceraTicketScreenFooterTemplate } from "../../templates.js";
import { TicketData } from "../UtilsClasses/TicketData.js";
import { TerceraWindowBase } from "./TerceraWindowBase.js";
import { GeneralSettings } from "../../Utils/GeneralSettings/GeneralSettings.ts";
import { ConfirmationTypesEnum } from "../../Utils/Trading/ConfirmationTypesEnum.ts";


export let TerceraTicketScreen = TerceraWindowBase.extend({
    template: TerceraBaseWindowTemplate,
    data: function ()
    {
        return {
            resizable: false,
            showFooter: false,
            entries: [],
            style_addition_body: 'js-ticket-screen-additional-body',
            style_addition_header: 'js-ticket-screen-additional-header',
            style_addition_footer: 'js-ticket-screen-additional-footer',
        };
    },
    partials: {
        bodyPartial: TerceraTicketScreenTemplate,
        footerPartial: TerceraTicketScreenFooterTemplate
    }
});

TerceraTicketScreen.prototype.getType = function () { return 'TerceraTicketScreen' };

TerceraTicketScreen.prototype.oncomplete = function ()
{
    TerceraWindowBase.prototype.oncomplete.apply(this);

    this.observe("checked", function (newVal)
    {
        GeneralSettings.Confirmations.updateConfirmation(ConfirmationTypesEnum.DealTickets, newVal);
    }, { init: false });

    GeneralSettings.SettingsChanged.Subscribe(this.updateChecked, this)
};

TerceraTicketScreen.prototype.dispose = function ()
{
    GeneralSettings.SettingsChanged.UnSubscribe(this.updateChecked, this);
    let index = TerceraTicketScreen.ScreensSet.indexOf(this);
    if (index != -1)
        TerceraTicketScreen.ScreensSet.splice(index, 1);
    TerceraWindowBase.prototype.dispose.apply(this);
};

TerceraTicketScreen.prototype.updateChecked = function ()
{
    this.set('checked', GeneralSettings.Confirmations.OpenDialTicketsOnWorkspace)
}

// TODO. UGLY. Refactor.
TerceraTicketScreen.show = function (ticket)
{
    var screen = new TerceraTicketScreen();
    var visibleFooter = ticket.isTrade;
    var heightFooter = visibleFooter ? 47 : 0;

    screen.set('header', ticket.screenHeader || ticket.header)

    if (ticket.sortedEntries.length === 1)
    {
        let singleText = ticket.text || ticket.sortedEntries[0].text || ticket.sortedEntries[0].caption;
        screen.set('singleRowText', singleText)
    }
    else
        screen.set('entries', ticket.sortedEntries)

    screen.set({
        checked: ticket.showNextTime,
        showFooter: visibleFooter,
        text: Resources.getResource('general.messageBox.showNextTime')
    });

    screen.on('complete', function ()
    {
        var $root = $(this.find('.js-ticket-screen-additional-body'));
        this.set('height', $root.height() + 24 + heightFooter);
        this.set('width', $root.width());
        screen.center();
        if (TerceraTicketScreen.ScreensSet.length > 1)
        {
            let prew = TerceraTicketScreen.ScreensSet[TerceraTicketScreen.ScreensSet.length - 2]
            let l = prew.get('left')
            let t = prew.get('top')
            if (MainWindowManager.MainWindow.height - t < 300 || MainWindowManager.MainWindow.width - l < 300)
            {
                t = l = 50;
            }
            this.set({ left: l + 25, top: t + 25 })
        }
    });

    TerceraTicketScreen.ScreensSet.push(screen);

    MainWindowManager.MainWindow.addControl(screen);
}

TerceraTicketScreen.ScreensSet = [];

// #region New Direct Methods

TerceraTicketScreen.TicketFromEvent = function (ev)
{
    let reportLocKey = 'reports.' + ev.Action;

    let title = Resources.hasResource(reportLocKey) ?
        Resources.getResource(reportLocKey) :
        Resources.getResource(ev.Action);

    let instrument = ev.Instrument;

    let entries = [];

    let descriptionItems = ev.DescriptionItems;
    let len = descriptionItems.length;
    for (let i = 0; i < len; i++)
    {
        let item = descriptionItems[i]
        entries.push({
            caption: item.Header,
            text: item.Value
        })
    }

    let ticket = new TicketData()
    ticket.header = title
    ticket.sortedEntries = entries

    if (ev.EventType === EventType.Trading && ev.Source !== EventSource.Refused)
        ticket.isTrade = true;

    return ticket
}

// #endregion New Direct Methods