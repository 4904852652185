// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type Account } from '../../../Commons/cache/Account';
import { ProductType } from '../../Instruments/ProductType';
import { SLTPTrigger } from '../../SlTpTrigger';
import { OffsetModeViewEnum } from '../../Trading/OffsetModeViewEnum';
import { OrderTif } from '../../Trading/OrderTifEnum';
import { OrderType } from '../../Trading/OrderType';

export class TradingDefaultsSett {
    public Account: Account | null = null;
    public Symbol: any = null;

    public SetSlTpValuesInOffset = false;
    public ShowOffsetIn = OffsetModeViewEnum.Ticks;

    public UseStopLimitInsteadStop = false;
    public LimitOffsetTicks = 1000;

    public OrderType = OrderType.Market;
    public ProductType = ProductType.General;
    public MarketValidity = OrderTif.IOC;
    public LimitAndStopLimitValidity = OrderTif.GTC;
    public StopValidity = OrderTif.GTC;
    public TypesManagerSettings: any = null;
    public SlTpTriggers = new SLTPTrigger(10);

    public IsTicksFractionalForForex (): boolean {
        return this.ShowOffsetIn === OffsetModeViewEnum.TicksFractionalForForex;
    }
}
