// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../../Commons/properties/Resources';
import { Cursors } from '../../../Commons/Cursors';
import { PositionEdit } from '../../../Commons/cache/OrderParams/PositionEdit';
import { TerceraChartTradingOperation } from '../../Utils/ChartConstants';
import { OrderToolView } from './OrderToolView';
import { TradingToolViewType } from './TradingToolViewBase';
import { ToolSelectionType } from '../Selection';
import { OperationType } from '../../../Utils/Trading/OperationType';
import { PlacedFrom } from '../../../Utils/Trading/PlacedFrom';
import { TradingLockUtils } from '../../../Utils/TradingLockUtils';
import { IsAllowed } from '../../../Commons/IsAllowed';
import { type Position } from '../../../Commons/cache/Position';

export class PositionToolView extends OrderToolView {
    declare Order: Position | null;

    constructor (order, getToolViewByOrderId, terceraChartPanelContext, renderer) {
        super(order, getToolViewByOrderId, terceraChartPanelContext, renderer);

        this.isOrderToolView = false;
        this.ToolViewType = TradingToolViewType.PositionToolView;
    }

    public override AllowCancelOrder (): boolean {
        return false;
    }

    public override AllowModify (): boolean {
        return false;
    }

    public override AllowClosePosition (): boolean {
        return IsAllowed.IsPositionClosingAllowed(this.Order).Allowed;
    }

    public SlTpComparePrice (isForSL): number {
        const pos = this.Order; const acc = pos.Account;
        const ins = pos.Instrument; const quote = ins.LastQuote;
        const isBuy = pos.BuySell === OperationType.Buy;

        return PositionEdit.SlTpPriceGetter(isForSL, isBuy, pos, ins, acc, quote);
    }

    public override SlComparePrice (): number {
        return this.SlTpComparePrice(true);
    }

    public override TpComparePrice (): number {
        return this.SlTpComparePrice(false);
    }

    override get ID (): string {
        return this.Order != null ? this.Order.PositionId : '-2';
    }

    public override DescriptionText (): string {
        const pos = this.Order;

        const profitValue = pos.getNetPnL(true);
        return pos.Account.formatPrice(profitValue);
    }

    /// <summary>
    /// Order Draw
    /// </summary>
    public override Draw (gr, window, param): void {
        if (!this.myRenderer.ShowPositions) {
            return;
        }

        super.Draw(gr, window, param);
    }

    // public override Dispose (): void {
    //     super.Dispose();
    // // ++
    // }

    public override CheckMinDistanceForStartMoving (x: number, y: number): boolean {
        const mouseDownHover = this.CurrentSelection.MouseDownHover;
        if (mouseDownHover === ToolSelectionType.SL || mouseDownHover === ToolSelectionType.TP) {
            return Math.abs(this.CurrentMovement.PrevY - y) >= this.MinDistanceForStartMoving();
        }

        return false;
    }

    // mouse up
    public override ProcessClick (): void {
        if (!this.TradeWasModified) // click
        {
            switch (this.CurrentSelection.MouseDownHover) {
            case ToolSelectionType.Icon:
            case ToolSelectionType.Info:
            case ToolSelectionType.Image:
            case ToolSelectionType.Leverage:
                this.Collapsed = !this.Collapsed;
                this.ResetMouse();
                break;
            // case ToolSelectionType.Action:
            //     if (!Utils.TradingLock.tradingLocked)
            //         this.terceraChartPanelContext.ChartVisualTrading(this.Order, { action: TerceraChartTradingOperation.ActionButton, isPosition: true });
            //     // show menu
            //     break;
            case ToolSelectionType.Remove:
                if (!TradingLockUtils.TradingLock.tradingLocked) { this.terceraChartPanelContext.ChartVisualTrading(this.Order, { action: TerceraChartTradingOperation.ClosePosition, sendFrom: PlacedFrom.WEB_CHART_VISUAL }); }
                break;

            case ToolSelectionType.Line:
            case ToolSelectionType.None:
            case ToolSelectionType.SL:
            case ToolSelectionType.TP:
            case ToolSelectionType.Trailing:
            default:
                break;
            }
        }
    }

    public override GetCursor (e): Cursors {
        const curElement = this.CurrentSelection.MouseDownHover === ToolSelectionType.None ? this.CurrentSelection.CurrentHover : this.CurrentSelection.MouseDownHover;

        switch (curElement) {
        case ToolSelectionType.SL:
        case ToolSelectionType.TP:
            return this.AllowSLTP() ? (this.errorMode ? Cursors.No : Cursors.NoMoveVert) : null;
        case ToolSelectionType.Image:
            return null;
        case ToolSelectionType.Info:
        case ToolSelectionType.Action:
        case ToolSelectionType.Remove:
        case ToolSelectionType.Line:
        case ToolSelectionType.None:
        case ToolSelectionType.Trailing:
        default:
            return null;
        }
    }

    public override GetTooltip (e): string | null {
        let res = '';
        switch (this.CurrentSelection.CurrentHover) {
        case ToolSelectionType.Image:
            res = Resources.getResource('TradeTool.Position');
            break;
        case ToolSelectionType.Info:
            res = Resources.getResource('TradeTool.Position P/L');
            break;
        case ToolSelectionType.Remove:
            res = Resources.getResource('TradeTool.Close position');
            break;
        default:
            res = null;
            break;
        }
        return res || super.GetTooltip(e);
    }
}
