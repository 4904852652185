// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { CustomEvent } from "../../Utils/CustomEvents.ts";
import { Resources } from "../../Commons/properties/Resources.ts";
import { ControlsUtils } from "../UtilsClasses/ControlsUtils.ts";
import { TerceraRangeControlTemplate } from "../../templates.js";
import { ContainerControl } from "./ContainerControl.js";


export let TerceraRangeControl = ContainerControl.extend({
    data: function ()
    {
        return {
            width: 200,
            height: 10,

            slid_position_start: 0,
            slid_position_end: 198,

            triangleType_start: "",
            triangleType_end: "",

            isRevers: false,
            focusedStart: false,
            focusedEnd: false,

            startDayTooltip: Resources.getResource('RangeControl.StartDaySlide.Tooltip'),
            endDayTooltip: Resources.getResource('RangeControl.EndDaySlide.Tooltip')
        };
    },
    objStartValue: null,
    objEndValue: null,
    template: TerceraRangeControlTemplate
});

TerceraRangeControl.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.apply(this);
    this.RangeValueChanged = new CustomEvent();

    this.on("onMouseDownMain", this.onMouseDownMain);
    this.on("onMouseDownStart", this.onMouseDownStart);
    this.on("onMouseDownEnd", this.onMouseDownEnd);
    this.on("onMouseUpStart", this.onMouseUp);
    this.on("onMouseUpEnd", this.onMouseUp);
    this.observe('valueClockStart valueClockEnd', this.onRangeValueChanged);

    this.ReverseStyle();
    this.TriangleCorrection();
    this.ClockCorrection();
    this.setValue();
};

TerceraRangeControl.prototype.onRangeValueChanged = function ()
{
    if (!this.objStartValue)
        return
    if (!this.objEndValue)
        return

    let rangeControlValue = new TerceraRangeControlRangeData();
    rangeControlValue.StartHours = this.objStartValue.Hours;
    rangeControlValue.StartMins = this.objStartValue.Mins;
    rangeControlValue.EndHours = this.objEndValue.Hours;
    rangeControlValue.EndMins = this.objEndValue.Mins;

    this.RangeValueChanged.Raise(this, rangeControlValue);
};

TerceraRangeControl.prototype.setInitValue = function (propValue)
{
    if (!propValue)
        return

    let start = this.getInitPosition(propValue.StartHours, propValue.StartMins);
    let end = this.getInitPosition(propValue.EndHours, propValue.EndMins);

    if (isNaN(start) || isNaN(end))
        return

    this.set("slid_position_start", start);
    this.set("slid_position_end", end);

    this.ReverseStyle();
    this.TriangleCorrection();
    this.ClockCorrection();
    this.setValue();
};

TerceraRangeControl.prototype.onMouseDownMain = function (ev)
{
    if (!ev.original.notSkip)
        return

    this.MovingNow;
    if (!this.MovingNow)
        ControlsUtils.Capture.SetCapture(this, ev.original);

    this.MovingNow = true
};

TerceraRangeControl.prototype.onMouseDownStart = function (ev)
{
    this.PressedElement = TerceraRangeControl.START;
    this.switchFocus(true);
    this.onMouseDown(ev);
};

TerceraRangeControl.prototype.onMouseDownEnd = function (ev)
{
    this.PressedElement = TerceraRangeControl.END;
    this.switchFocus(false);
    this.onMouseDown(ev);
};

TerceraRangeControl.prototype.switchFocus = function (focus)
{
    this.set("focusedStart", focus);
    this.set("focusedEnd", !focus);
};

TerceraRangeControl.prototype.onMouseDown = function (ev)
{
    let event = new Event('mousedown');
    let variableName = "slid_position_" + this.PressedElement;
    this.MovingNow = false;

    let evO = ev.original;
    event.pageX = evO.pageX;
    event.offsetX = this.get(variableName);
    event.pageY = evO.pageY;
    event.offsetY = evO.offsetY;
    event.notSkip = true;
    ev.original.currentTarget.parentElement.dispatchEvent(event);
};

TerceraRangeControl.prototype.onMouseUp = function (ev)
{
    this.MovingNow = false;
};

TerceraRangeControl.prototype.onMouseMove = function (ev)
{
    if (!this.MovingNow)
        return

    this.processEVT(ev);
};

TerceraRangeControl.prototype.processEVT = function (ev)
{
    if (this.captured && ev.original)
        return;

    let variableName = "slid_position_" + this.PressedElement;
    let oldV = this.get(variableName);
    let newV = ev.offsetX;

    if (newV <= 0)
        newV = 0
    if (newV >= TerceraRangeControl.RIGHT_BORDER)
        newV = TerceraRangeControl.RIGHT_BORDER

    if (oldV !== 0 && oldV !== TerceraRangeControl.RIGHT_BORDER && newV !== 0 && newV !== TerceraRangeControl.RIGHT_BORDER)
        oldV = newV

    this.set(variableName, newV);

    if (oldV !== newV)
        this.TriangleCorrection();

    this.ReverseStyle();
    this.ClockCorrection();
    this.setValue();
};

TerceraRangeControl.prototype.TriangleCorrection = function ()
{
    let position_start = this.get("slid_position_start");
    let position_end = this.get("slid_position_end");

    let arrElem = [TerceraRangeControl.START, TerceraRangeControl.END];
    let arrNum = [position_start, position_end];

    for (let i = 0; i < arrElem.length; i++)
    {
        let elem = arrElem[i];
        let num = arrNum[i];
        let variableName = "triangleType_" + elem;

        if (num > 0 && num < TerceraRangeControl.RIGHT_BORDER)
            this.set(variableName, "down")
        if (num === 0)
            this.set(variableName, "left")
        if (num === TerceraRangeControl.RIGHT_BORDER)
            this.set(variableName, "right")
    }
};

TerceraRangeControl.prototype.ClockCorrection = function ()
{
    let revers = this.get("isRevers");

    let positionStart = revers ? this.get("slid_position_end") : this.get("slid_position_start");
    let positionEnd = revers ? this.get("slid_position_start") : this.get("slid_position_end");

    let typeStartClock = revers ? "clock_position_end" : "clock_position_start";
    let typeEndClock = revers ? "clock_position_start" : "clock_position_end";

    this.CalculatePositionClock(positionStart, typeStartClock);
    this.CalculatePositionClock(positionEnd, typeEndClock);
};

TerceraRangeControl.prototype.CalculatePositionClock = function (position, typeClock)
{
    let edgeLeft = 16;
    let edgeRight = 182;
    let halfClock = -16;

    let positionClock = TerceraRangeControl.RIGHT_BORDER - position;
    let indent = 2 * halfClock + positionClock;

    if (position < edgeLeft)
        this.set(typeClock, -position)
    else if (position > edgeRight)
        this.set(typeClock, indent)
    else
        this.set(typeClock, halfClock)
};

TerceraRangeControl.prototype.ReverseStyle = function ()
{
    let pos_start = this.get("slid_position_start");
    let pos_end = this.get("slid_position_end");

    let isRevers = false;
    if (pos_start >= pos_end)
        isRevers = true;

    this.set("isRevers", isRevers)
};

TerceraRangeControl.prototype.setValue = function ()
{
    let pos_start = this.get("slid_position_start");
    let pos_end = this.get("slid_position_end");

    this.objStartValue = this.getFormattedValue(pos_start);
    this.objEndValue = this.getFormattedValue(pos_end);

    if (this.objStartValue)
        this.set("valueClockStart", this.objStartValue.FormatedValue)

    if (this.objEndValue)
        this.set("valueClockEnd", this.objEndValue.FormatedValue)
};

TerceraRangeControl.prototype.getFormattedValue = function (left)
{
    let mins = 0;
    let hours = 0;

    /* кусок черной магии
    9 отступ слева для расчёта по 3 пикселя на 15 минут
    196 отступ справа для расчёта до этого значения по 2 пикселя на 15 минут
    197 граница после которой время ставим 23:59
    всё из-за гавна с размером в 198 пикселей
    192 пикселя было б топычем! и без магии */

    if (left < 9 && left % 3 === 0)
    {
        mins = left / 3 % 4 * 15;
        hours = Math.trunc(left / 3 / 4);
    }
    else if (left >= 9 && left < 196 && left % 2 === 0)
    {
        mins = left / 2 % 4 * 15 + 30;
        if (mins >= 60)
        {
            mins = mins - 60;
            hours = 1;
        }
        hours += Math.trunc(left / 2 / 4) - 1;
    }
    else if (left > 197)
    {
        mins = 59;
        hours = 23;
    }
    else
        return null;

    let objValue = new Object();
    objValue.Hours = hours;
    objValue.Mins = mins;
    objValue.FormatedValue = this.FormatClockValue(hours, mins);

    return objValue;
};

TerceraRangeControl.prototype.getInitPosition = function (hours, mins)
{
    // еще один кусок черной магии
    let plus = mins / 15
    if (hours === 0 && mins > 30 || hours !== 0)
        plus = 4
    let res = (hours * 4 + mins / 15) * 2 + plus;
    if (hours === 23 && mins === 59)
        res = 198

    return res;
};

TerceraRangeControl.prototype.FormatClockValue = function (hours, mins)
{
    if (hours < 10)
        hours = "0" + hours;

    if (!mins)
        mins = mins + "0";

    let resultValue = hours + ":" + mins;

    return resultValue;
};

TerceraRangeControl.RIGHT_BORDER = 198;
TerceraRangeControl.START = "start";
TerceraRangeControl.END = "end";

export let TerceraRangeControlRangeData = function (startHours, startMins, endHours, endMins)
{
    this.StartHours = startHours || 0;
    this.StartMins = startMins || 0;
    this.EndHours = endHours === 0 ? 0 : (endHours || 23);
    this.EndMins = endMins === 0 ? 0 : (endMins || 59);
}