// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../Commons/properties/Resources.ts";
import { ThemeManager } from "../Controls/misc/ThemeManager.ts";
import { BaseItem } from "../Controls/cache/BaseItem.ts";
import { ColumnParams } from "../Controls/cache/ColumnParams.ts";
import { PositionItem } from "../Controls/cache/PositionItem.ts";
import { QuickTableEditingInfo } from "../Controls/elements/QuickTable/QuickTableMisc.ts";
import { DynProperty } from "../Commons/DynProperty.ts";
import { InstrumentUtils } from "../Utils/Instruments/InstrumentUtils.ts";

export let PortfolioSummaryItem = function (instrument, account, sessionSettings, dailyInstrumentReturn, insColor, panelSettings)
{
    BaseItem.call(this, sessionSettings);

    this.instrument = instrument;

    this.NoIgnoreForceUpdateMapIndex[0] = true;
    if (instrument && instrument.LogoAddress)
    {
        ThemeManager.ImagesManager.GetImg(instrument.LogoAddress, 16, 16).then(function (image)
        {
            this.logoIMG = image;
            this.isNeedFireUpdate = true;
        }.bind(this))
    }

    this.account = account;

    this.showTotalsByColumn = {};
    this.insColor = insColor;

    this.sparkLine = [];
    this.sparkLinePointNum = 25;
    this.initSparkLines(dailyInstrumentReturn)

    this.QuickTableEditingInfoMap[0] = (function ()
    {
        return new QuickTableEditingInfo(DynProperty.SYMBOL_WITH_IMGS);
    })();
    this.QuickTableEditingInfoMap[13] = (function ()
    {
        return new QuickTableEditingInfo(DynProperty.SPARKLINE);
    })();

    this.initByPanelSettings(panelSettings)
}
PortfolioSummaryItem.prototype = Object.create(BaseItem.prototype);

PortfolioSummaryItem.prototype.ColumnCount = function ()
{
    return PortfolioSummaryItem.columnsParams.length;
}

PortfolioSummaryItem.prototype.getColumnData = function (column)
{
    let value = null;
    let formattedValue = null;

    if (this.instrument === null)
    {
        value = null;
        formattedValue = "";
    }

    value = this.getColumnValue(column);

    var acc = this.account;

    switch (column)
    {
        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
            formattedValue = acc.formatPrice(value)
            break;

        default:
            formattedValue = value ? value.toString() : ''
            break;
    }

    return { Value: value, FormattedValue: formattedValue };
}

PortfolioSummaryItem.prototype.getColumnValue = function (column)
{
    let instrument = this.instrument

    if (instrument === null)
        return null;

    var sess = this.sessionSettings,
        positions = instrument.DataCache.getPositionsByInstrument(instrument),
        result = 0,
        pos = null
    for (let posID in positions)
    {
        pos = positions[posID]
        break
    }

    switch (column)
    {
        case 0:
            return instrument.DisplayName()

        case 1:
            return instrument.DescriptionValue()

        case 2:
            return InstrumentUtils.getInstrumentTypeStringLocalized(instrument.InstrType, instrument.CFD)

        case 3:
            return instrument.TradingExchange

        case 4:
            return pos ? pos.Price : NaN;

        case 5:
            return pos ? (pos.lastPriceUpdated && !isNaN(pos.CurPriceClose) ? pos.CurPriceClose : NaN) : NaN;

        case 6:
            for (let posID in positions)
            {
                pos = positions[posID]
                let sign = PositionItem.GetPositionSign(pos, false);  // NetQty check
                result += PositionItem.GetPositionAmount(pos) * sign;
            }
            return result

        case 7:
            for (let posID in positions)
            {
                pos = positions[posID]
                result += pos.getExpositionValue(PositionItem.UseAccCurrency(pos))
            }
            return result

        case 8:
            for (let posID in positions)
            {
                pos = positions[posID]
                result += pos.getPositionValue(PositionItem.UseAccCurrency(pos))
            }
            return result

        case 9:
            for (let posID in positions)
            {
                pos = positions[posID]
                result += pos.lastPriceUpdated ? pos.getGrossPnL(PositionItem.UseAccCurrency(pos)) : 0
            }
            return result

        case 10:
            return null

        case 11:
            for (let posID in positions)
            {
                pos = positions[posID]
                result += -pos.GetCommissionFromSettlement(PositionItem.UseAccCurrency(pos))
            }
            return result

        case 13:
            return this.sparkLine

        default:
            return null;
    }
}

PortfolioSummaryItem.prototype.GetCurrentAccount = function ()
{
    return this.instrument !== null ? this.instrument.Account : null
}

PortfolioSummaryItem.prototype.GetCurrentInstrument = function ()
{
    return this.instrument;
}

PortfolioSummaryItem.prototype.getFormatTotalValue = function (column, value, precisionMode = false)
{
    let formattedValue = "";
    var acc = this.account;

    if (this.showTotalsByColumn[column] === false)
        return formattedValue

    switch (column)
    {
        case 6:
            if (precisionMode)
                return 2;
            formattedValue = value.toFixed(2)
            break;
        default:
            if (precisionMode)
                return 2;
            formattedValue = acc.formatPrice(value)
            break;
    }

    return formattedValue;
}

PortfolioSummaryItem.prototype.initSparkLines = function (dailyInstrumentReturn)
{
    if (dailyInstrumentReturn)
    {
        let len = dailyInstrumentReturn.length,
            arr = []
        for (let i = 0; i < len; i++)
            arr.push(dailyInstrumentReturn[i].Percent)
        this.sparkLine = arr
    }
    else    // build random sparkline (only for test -> todo remove) 
    {
        // let len = randomInteger(18, 26),
        //     arr = []
        // for (let i = 0; i < len; i++)
        //     arr.push(randomInteger(-100, 100))
        // this.sparkLine = arr
    }
}

PortfolioSummaryItem.prototype.initByPanelSettings = function (panelSettings)
{
    if (panelSettings)
    {
        this.CellForeColorMap = panelSettings.columnTextColor
        this.CellBackColorMap = panelSettings.columnBackColor

        this.showTotalsByColumn = panelSettings.showTotalsByColumn
        this.sparkLinePointNum = panelSettings.sparkLinePointNum

        this.showLogos = panelSettings.showLogos
    }
}

PortfolioSummaryItem.prototype.GetColumnParams = function (column)
{
    let result = PortfolioSummaryItem.columnsParams[column];
    var isHidden = Resources.isHidden(result.HeaderKey);
    result.Hidden = isHidden;
    result.MenuIndex = this.GetMenuIndex(result.HeaderKey);

    return result;
}

/// <summary>
/// Здесь хранятся настройки колонок (один экземпляр на класс)
/// </summary>
PortfolioSummaryItem.columnsParams = [
/*0*/   new ColumnParams("panel.summary.Symbol", 75, 0, false, true, false),
    new ColumnParams("panel.summary.SymbolDescription", 75, 0, false, true, false),
    new ColumnParams("panel.summary.SymbolType", 75, 0, false, false, false),
    new ColumnParams("panel.summary.TradingExchange", 75, 0, false, false, false),
    new ColumnParams("panel.summary.OpenPrice", 75, 0, false, false, false),
    /*5*/   new ColumnParams("panel.summary.CurrentPrice", 75, 3, false, false, false),
    new ColumnParams("panel.summary.Quantity", 75, 3, true, false, false),
    new ColumnParams("panel.summary.PosExposure", 75, 3, true, true, false),
    new ColumnParams("panel.summary.PosValue", 75, 3, true, true, false),
    new ColumnParams("panel.summary.GrossPL", 75, 3, true, false, false),
    /*10*/  new ColumnParams("panel.summary.NetPL", 75, 3, true, false, false),
    new ColumnParams("panel.summary.Fee", 75, 3, true, false, false),
    new ColumnParams("panel.summary.ReturnPercent", 75, 3, false, true, false),
    new ColumnParams("panel.summary.Sparkline", 75, 3, false, true, false),
    new ColumnParams("panel.summary.PortfolioWeight", 75, 3, false, true, false),
    new ColumnParams("panel.summary.ModelAllocation", 75, 3, false, true, false),
];

PortfolioSummaryItem.prototype.GetMenuIndex = function (headerKey)
{
    if (PortfolioSummaryItem.ListMenuIndex.Contains(headerKey))
        return PortfolioSummaryItem.ListMenuIndex.indexOf(headerKey);
    else
        return 1000;
}

PortfolioSummaryItem.ListMenuIndex =
    [
        "panel.summary.Symbol",
        "panel.summary.SymbolDescription",
        "panel.summary.SymbolType",
        "panel.summary.TradingExchange",
        "panel.summary.OpenPrice",
        "panel.summary.CurrentPrice",
        "panel.summary.Quantity",
        "panel.summary.PosExposure",
        "panel.summary.PosValue",
        "panel.summary.GrossPL",
        "panel.summary.NetPL",
        "panel.summary.Fee",
        "panel.summary.ReturnPercent",
        "panel.summary.Sparkline",
        "panel.summary.PortfolioWeight",
        "panel.summary.ModelAllocation",
    ];