import { EventEmitter } from 'events';
import { type Account } from '../../Account';
import { type Instrument } from '../../Instrument';
import { OptionCalculatorModel } from '../OptionCalculator/OptionCalculatorModel';
import { IVCalculationPrice } from './IVCalculationPrice';
import { OptionChain } from './OptionChain/OptionChain';
import { DataCache } from '../../../DataCache';
import { HistoryType } from '../../../../Utils/History/HistoryType';
import { Greeks } from './Greeks';
import { OptionTraderUtils } from './OptionTraderUtils';
import { OptionCalculator } from '../OptionCalculator/OptionCalculator';
import { PaperPositionsCache } from './OptionPaperPosition/PaperPositionsCache';
import { type OptionPutCall } from '../../../../Utils/Instruments/OptionPutCall';
import { type PaperPosition } from './OptionPaperPosition/PaperPosition';
import { type Order } from '../../Order';
import { type Position } from '../../Position';
import { type StrikePriceSettings } from '../../../../Utils/Instruments/StrikePriceSettings';
import { SavedOrdersController } from '../../SavedOrders/SavedOrdersController';
import { type OrderEditBase } from '../../OrderParams/order-edit/OrderEditBase';
import { Option } from './Option';
import { OptionAnalyzer } from './OptionAnalyzer/OptionAnalyzer';
import { type AnalyzerChartData } from './OptionAnalyzer/AnalyzerChartData';
import { AnalyzerChartDataInputParameters, AnalyzerChartDataPaperPositionInfo } from './OptionAnalyzer/AnalyzerChartDataInputParameters';
import { type AnalyzerInfoData } from './OptionAnalyzer/AnalyzerInfoData';
import { AnalyzerInfoDataInputParameters } from './OptionAnalyzer/AnalyzerInfoDataInputParameters';
import { type BaseInterval } from '../../../../Utils/History/BaseInterval';
import { DateTimeUtils } from '../../../../Utils/Time/DateTimeUtils';
import { Periods, TFInfo } from '../../../../Utils/History/TFInfo';
import { SessionInfo } from '../../../../Utils/History/SessionInfo';
import { ReloadHistoryParams } from '../../../../Utils/History/ReloadHistoryParams';
import { CashItem } from '../../History/CashItem';
import { type IOptionCalculator } from '../OptionCalculator/IOptionCalculator';

export class OptionTrader {
    private readonly _eventEmitter = new EventEmitter();
    private readonly _paperPositionsCache: PaperPositionsCache = new PaperPositionsCache();

    private _account: Account;
    private _fakeOption: Instrument;
    private _seriesDate: Date;

    private _lastProbabilityHistory: BaseInterval[];
    private _lastProbabilityHistoryPeriodYear: number = -1;

    public pricingModel: OptionCalculatorModel = OptionCalculatorModel.BlackSholes;
    public interestRate: number = 0;
    public ivCalculationPrice: IVCalculationPrice = IVCalculationPrice.Ask;

    public readonly optionChain: OptionChain = new OptionChain();
    public readonly optionAnalyzer: OptionAnalyzer = new OptionAnalyzer();

    private readonly _greeksCache = new Map<string, Greeks>();

    public get isShowPortfolio (): boolean {
        return this.optionChain.isShowPortfolio;
    }

    public set isShowPortfolio (value: boolean) {
        this.optionChain.isShowPortfolio = value;
        this._eventEmitter.emit('onShowPortfolioChanged', value);
    }

    public get account (): Account {
        return this._account;
    }

    public set account (value: Account) {
        this._account = value;
        this._greeksCache.clear();
        this._eventEmitter.emit('onAccountChanged', value);
    }

    public get fakeOption (): Instrument {
        return this._fakeOption;
    }

    public set fakeOption (value: Instrument) {
        let underlier = this.underlier;
        if (!isNullOrUndefined(this.underlier)) {
            DataCache.FQuoteCache.removeListener(underlier, this, HistoryType.QUOTE_LEVEL1);
            DataCache.FQuoteCache.removeListener(underlier, this, HistoryType.QUOTE_TRADES);
            DataCache.FQuoteCache.removeListener(underlier, this, HistoryType.QUOTE_INSTRUMENT_DAY_BAR);
        }
        this._fakeOption = value;
        underlier = this.underlier;
        if (!isNullOrUndefined(underlier)) {
            DataCache.FQuoteCache.addListener(underlier, this, HistoryType.QUOTE_LEVEL1);
            DataCache.FQuoteCache.addListener(underlier, this, HistoryType.QUOTE_TRADES);
            DataCache.FQuoteCache.addListener(underlier, this, HistoryType.QUOTE_INSTRUMENT_DAY_BAR);
        }
        this._greeksCache.clear();
        this._eventEmitter.emit('onInstrumentChanged', value);
    }

    public get underlier (): Instrument {
        if (isNullOrUndefined(this._fakeOption)) {
            return undefined;
        } else {
            return this._fakeOption.ForwardBaseInstrument;
        }
    }

    public get seriesDate (): Date {
        return this._seriesDate;
    }

    public set seriesDate (value: Date) {
        this._seriesDate = value;
        this._eventEmitter.emit('onSeriesDateChanged');
    }

    public get strikePriceSettings (): StrikePriceSettings[] {
        if (isNullOrUndefined(this.fakeOption) || isNullOrUndefined(this.seriesDate)) {
            return [];
        }
        const instrDateSettings = this.fakeOption.InstrDateSettingsList.filter((x) => x.ContractMonthDate === this.seriesDate)[0];
        if (!isNullOrUndefined(instrDateSettings)) {
            return [...instrDateSettings.StrikePricesList].sort((a, b) => a.StrikePrice - b.StrikePrice);
        } else {
            return [];
        }
    }

    public get options (): Option[] {
        const options = [];

        const fakeOption = this.fakeOption;
        const sortedStrikePriceSettingsList = this.strikePriceSettings;

        for (let i = 0; i < sortedStrikePriceSettingsList.length; i++) {
            const strikePriceSettings: StrikePriceSettings = sortedStrikePriceSettingsList[i];
            const putInstrument = DataCache.getInstrumentByTradable_ID(strikePriceSettings.InstrumentTradableIDPut, fakeOption.Route);
            const callInstrument = DataCache.getInstrumentByTradable_ID(strikePriceSettings.InstrumentTradableIDCall, fakeOption.Route);
            options.push(new Option(strikePriceSettings.StrikePrice, putInstrument, callInstrument));
        }
        return options;
    }

    public get positions (): Position[] {
        const result = [];
        const positions = DataCache.getAllPositions();
        for (const posId in positions) {
            const pos = positions[posId];
            if (pos.Account === this.account && this.isAllowInstrument(pos.Instrument)) {
                result.push(pos);
            }
        }
        return result;
    }

    public get orders (): Order[] {
        const result = [];
        const orders = DataCache.getAllOrders();
        for (const orderId in orders) {
            const order = orders[orderId];
            if (order.Account === this.account && this.isAllowInstrument(order.Instrument)) {
                result.push(order);
            }
        }
        return result;
    }

    constructor () {
        DataCache.OnAddOrder.Subscribe(this.onAddOrder, this);
        DataCache.OnRemoveOrder.Subscribe(this.onRemoveOrder, this);
        DataCache.OnUpdateOrder.Subscribe(this.onUpdateOrder, this);
        DataCache.OnAddPosition.Subscribe(this.onAddPosition, this);
        DataCache.OnRemovePosition.Subscribe(this.onRemovePosition, this);
        DataCache.OnUpdatePosition.Subscribe(this.onUpdatePosition, this);
        DataCache.OnAddSLOrderToPosition.Subscribe(this.onAddSLOrderToPosition, this);
        DataCache.OnAddTPOrderToPosition.Subscribe(this.onAddTPOrderToPosition, this);
        DataCache.OnRemoveSLOrderFromPosition.Subscribe(this.onRemoveSLOrderFromPosition, this);
        DataCache.OnRemoveTPOrderFromPosition.Subscribe(this.onRemoveTPOrderFromPosition, this);
    }

    dispose (): void {
        DataCache.OnAddOrder.UnSubscribe(this.onAddOrder, this);
        DataCache.OnRemoveOrder.UnSubscribe(this.onRemoveOrder, this);
        DataCache.OnUpdateOrder.UnSubscribe(this.onUpdateOrder, this);
        DataCache.OnAddPosition.UnSubscribe(this.onAddPosition, this);
        DataCache.OnRemovePosition.UnSubscribe(this.onRemovePosition, this);
        DataCache.OnUpdatePosition.UnSubscribe(this.onUpdatePosition, this);
        DataCache.OnAddSLOrderToPosition.UnSubscribe(this.onAddSLOrderToPosition, this);
        DataCache.OnAddTPOrderToPosition.UnSubscribe(this.onAddTPOrderToPosition, this);
        DataCache.OnRemoveSLOrderFromPosition.UnSubscribe(this.onRemoveSLOrderFromPosition, this);
        DataCache.OnRemoveTPOrderFromPosition.UnSubscribe(this.onRemoveTPOrderFromPosition, this);
    }

    public getCalculator (): IOptionCalculator {
        return OptionCalculator.getCalculator(this.pricingModel);
    }

    public getGreeks (putCall: OptionPutCall, optionInstrument: Instrument, price: number = NaN): Greeks {
        let greeks = new Greeks();

        const underlierPrice = this.getUnderlierPrice();
        const optionPrice = isNaN(price) ? this.getOptionPrice(optionInstrument) : price;
        const strike = optionInstrument.StrikePrice;
        const daysToExpiration = OptionTraderUtils.getDaysToExpirationForInstrument(optionInstrument) / 365;
        const intRate = this.interestRate;

        if (isNaN(underlierPrice) || isNaN(optionPrice)) {
            return greeks;
        }

        const model = this.getCalculator();

        if (isNullOrUndefined(model)) {
            return greeks;
        }

        const cacheKey = `${putCall}${optionPrice}${underlierPrice}${strike}${daysToExpiration}${intRate}`;
        const cachedGreeks = this._greeksCache.get(cacheKey);
        if (!isNullOrUndefined(cachedGreeks)) {
            return cachedGreeks;
        }
        greeks = new Greeks();
        greeks.iv = model.iv(putCall, optionPrice, underlierPrice, strike, daysToExpiration, intRate);
        greeks.delta = model.delta(putCall, underlierPrice, strike, daysToExpiration, intRate, greeks.iv);
        greeks.theta = model.theta(putCall, underlierPrice, strike, daysToExpiration, intRate, greeks.iv);
        greeks.rho = model.rho(putCall, underlierPrice, strike, daysToExpiration, intRate, greeks.iv);
        greeks.vega = model.vega(underlierPrice, strike, daysToExpiration, intRate, greeks.iv);
        greeks.gamma = model.gamma(underlierPrice, strike, daysToExpiration, intRate, greeks.iv);

        this._greeksCache.set(cacheKey, greeks);

        return greeks;
    }

    public getPaperPositions (): PaperPosition[] {
        return this._paperPositionsCache.getPaperPositions(this.account, this.fakeOption);
    }

    public getPaperPosition (option: Instrument): PaperPosition {
        return this._paperPositionsCache.getPaperPosition(this.account, this.fakeOption, option);
    }

    public createPaperPosition (option: Instrument): void {
        const paperPosition = this._paperPositionsCache.createPaperPosition(this.account, this.fakeOption, option);
        this._eventEmitter.emit('onCreatePaperPosition', paperPosition);
    }

    public createPaperPositionFromOrderEdit (orderEdit: OrderEditBase): PaperPosition {
        const paperPosition = this._paperPositionsCache.createPaperPosition(orderEdit.account, this.fakeOption, orderEdit.instrument);
        SavedOrdersController.updateByOrderEdit(paperPosition, orderEdit);
        return paperPosition;
    }

    public removePaperPosition (option: Instrument): void {
        const paperPosition = this._paperPositionsCache.removePaperPosition(this.account, this.fakeOption, option);
        this._eventEmitter.emit('onRemovePaperPosition', paperPosition);
    }

    public updatePaperPosition (option: Instrument): void {
        const paperPosition = this._paperPositionsCache.getPaperPosition(this.account, this.fakeOption, option);
        this._eventEmitter.emit('onUpdatePaperPosition', paperPosition);
    }

    public changeInstrumentForPaperPosition (oldValue: Instrument, newValue: Instrument): void {
        const paperPosition = this._paperPositionsCache.changeInstrumentForPaperPosition(this.account, this.fakeOption, oldValue, newValue);
        this._eventEmitter.emit('onChangeInstrumentForPaperPosition', paperPosition);
    }

    public clearPaperPositions (): void {
        this._paperPositionsCache.clearPaperPositions(this.account, this.fakeOption);
        this._eventEmitter.emit('onClearPaperPositions');
    }

    public getOptionAnalyzerChartData (): AnalyzerChartData {
        const underlierPrice = this.getUnderlierPrice();
        const interestRate = this.interestRate;
        const paperPositionsInfo: AnalyzerChartDataPaperPositionInfo[] = [];
        const activePaperPositions = this._paperPositionsCache.getPaperPositions(this.account, this.fakeOption).filter(pos => pos.Active);
        for (let i = 0; i < activePaperPositions.length; i++) {
            const greeks = this.getGreeks(activePaperPositions[i].Instrument.PutCall, activePaperPositions[i].Instrument, activePaperPositions[i].Price);
            paperPositionsInfo.push(new AnalyzerChartDataPaperPositionInfo(activePaperPositions[i], greeks));
        }
        const model = this.getCalculator();
        const inputParams = new AnalyzerChartDataInputParameters(underlierPrice, interestRate, paperPositionsInfo, model);
        return this.optionAnalyzer.getOptionAnalyzerChartData(inputParams);
    }

    public getOptionAnalyzerInfoData (dataX: number): AnalyzerInfoData {
        const paperPositionsInfo: AnalyzerChartDataPaperPositionInfo[] = [];
        const activePaperPositions = this._paperPositionsCache.getPaperPositions(this.account, this.fakeOption).filter(pos => pos.Active);
        for (let i = 0; i < activePaperPositions.length; i++) {
            const greeks = this.getGreeks(activePaperPositions[i].Instrument.PutCall, activePaperPositions[i].Instrument, activePaperPositions[i].Price);
            paperPositionsInfo.push(new AnalyzerChartDataPaperPositionInfo(activePaperPositions[i], greeks));
        }
        const model = this.getCalculator();
        return this.optionAnalyzer.getOptionAnalyzerInfoData(new AnalyzerInfoDataInputParameters(dataX, this.interestRate, paperPositionsInfo, model));
    }

    public async getOptionAnalyzerProbabilityDataAsync (leftBorder: number, rightBorder: number): Promise<{ p1: number, p2: number }> {
        const account = this.account;
        const instrument = this.underlier;
        const optionAnalyzer = this.optionAnalyzer;
        const lastPrice = instrument.Level1.GetLastPrice(account);

        if (!isValidArray(this._lastProbabilityHistory) || this._lastProbabilityHistoryPeriodYear !== optionAnalyzer.historyPeriodYear) {
            const to = new Date(DateTimeUtils.DateTimeUtcNow().getTime());
            const from = new Date(DateTimeUtils.DateTimeUtcNow().getTime());
            from.setFullYear(from.getFullYear() - optionAnalyzer.historyPeriodYear);
            const tfInfo = new TFInfo();
            tfInfo.Periods = Periods.DAY;
            tfInfo.HistoryType = instrument.HistoryType;
            tfInfo.SessionInfo = new SessionInfo(false);
            tfInfo.Plan = DataCache.GetSpreadPlan(account);
            const historyParams = new ReloadHistoryParams();
            historyParams.updateWithProps({
                account,
                instrument,
                TimeFrameInfo: tfInfo,
                FromTime: from,
                ToTime: to,
                UseDefaultInstrumentHistoryType: false
            });
            const cashItem: CashItem = await CashItem.CreateWithHistory(instrument, historyParams);
            if (!isNullOrUndefined(cashItem)) {
                this._lastProbabilityHistory = cashItem.FNonEmptyCashArray;
                this._lastProbabilityHistoryPeriodYear = optionAnalyzer.historyPeriodYear;
            }
        }
        return this.optionAnalyzer.getOptionAnalyzerProbabilityData(this._lastProbabilityHistory, lastPrice, leftBorder, rightBorder);
    }

    // #region Subscriptions

    public subscribeOnAccountChanged (callback: (account: Account) => void): void {
        this._eventEmitter.on('onAccountChanged', callback);
    }

    public unsubscribeOnAccountChanged (callback: (account: Account) => void): void {
        this._eventEmitter.off('onAccountChanged', callback);
    }

    public subscribeOnInstrumentChanged (callback: (instrument: Instrument) => void): void {
        this._eventEmitter.on('onInstrumentChanged', callback);
    }

    public unsubscribeOnInstrumentChanged (callback: (instrument: Instrument) => void): void {
        this._eventEmitter.off('onInstrumentChanged', callback);
    }

    public subscribeOnSeriesDateChanged (callback: () => void): void {
        this._eventEmitter.on('onSeriesDateChanged', callback);
    }

    public unsubscribeOnSeriesDateChanged (callback: () => void): void {
        this._eventEmitter.off('onSeriesDateChanged', callback);
    }

    public subscribeOnCreatePaperPosition (callback: (paperPosition: PaperPosition) => void): void {
        this._eventEmitter.on('onCreatePaperPosition', callback);
    }

    public unsubscribeOnCreatePaperPosition (callback: (paperPosition: PaperPosition) => void): void {
        this._eventEmitter.off('onCreatePaperPosition', callback);
    }

    public subscribeOnUpdatePaperPosition (callback: (paperPosition: PaperPosition) => void): void {
        this._eventEmitter.on('onUpdatePaperPosition', callback);
    }

    public unsubscribeOnUpdatePaperPosition (callback: (paperPosition: PaperPosition) => void): void {
        this._eventEmitter.off('onUpdatePaperPosition', callback);
    }

    public subscribeOnChangeInstrumentForPaperPosition (callback: (paperPosition: PaperPosition) => void): void {
        this._eventEmitter.on('onChangeInstrumentForPaperPosition', callback);
    }

    public unsubscribeOnChangeInstrumentForPaperPosition (callback: (paperPosition: PaperPosition) => void): void {
        this._eventEmitter.off('onChangeInstrumentForPaperPosition', callback);
    }

    public subscribeOnRemovePaperPosition (callback: (paperPosition: PaperPosition) => void): void {
        this._eventEmitter.on('onRemovePaperPosition', callback);
    }

    public unsubscribeOnRemovePaperPosition (callback: (paperPosition: PaperPosition) => void): void {
        this._eventEmitter.off('onRemovePaperPosition', callback);
    }

    public subscribeOnClearPaperPositions (callback: () => void): void {
        this._eventEmitter.on('onClearPaperPositions', callback);
    }

    public unsubscribeOnClearPaperPositions (callback: () => void): void {
        this._eventEmitter.off('onClearPaperPositions', callback);
    }

    public subscribeOnQuoteChanged (callback: () => void): void {
        this._eventEmitter.on('onQuoteChanged', callback);
    }

    public unsubscribeOnQuoteChanged (callback: () => void): void {
        this._eventEmitter.off('onQuoteChanged', callback);
    }

    public subscribeOnAddOrder (callback: (order: Order) => void): void {
        this._eventEmitter.on('onAddOrder', callback);
    }

    public unsubscribeOnAddOrder (callback: (order: Order) => void): void {
        this._eventEmitter.off('onAddOrder', callback);
    }

    public subscribeOnRemoveOrder (callback: (order: Order) => void): void {
        this._eventEmitter.on('onRemoveOrder', callback);
    }

    public unsubscribeOnRemoveOrder (callback: (order: Order) => void): void {
        this._eventEmitter.off('onRemoveOrder', callback);
    }

    public subscribeOnUpdateOrder (callback: (order: Order) => void): void {
        this._eventEmitter.on('onUpdateOrder', callback);
    }

    public unsubscribeOnUpdateOrder (callback: (order: Order) => void): void {
        this._eventEmitter.off('onUpdateOrder', callback);
    }

    public subscribeOnAddPosition (callback: (position: Position) => void): void {
        this._eventEmitter.on('onAddPosition', callback);
    }

    public unsubscribeOnAddPosition (callback: (position: Position) => void): void {
        this._eventEmitter.off('onAddPosition', callback);
    }

    public subscribeOnRemovePosition (callback: (position: Position) => void): void {
        this._eventEmitter.on('onRemovePosition', callback);
    }

    public unsubscribeOnRemovePosition (callback: (position: Position) => void): void {
        this._eventEmitter.off('onRemovePosition', callback);
    }

    public subscribeOnShowPortfolioChanged (callback: (isShowPorfolio: boolean) => void): void {
        this._eventEmitter.on('onShowPortfolioChanged', callback);
    }

    public unsubscribeOnShowPortfolioChanged (callback: (isShowPorfolio: boolean) => void): void {
        this._eventEmitter.off('onShowPortfolioChanged', callback);
    }
    // #endregion

    // #region  Eventhandlers
    private readonly newQuote = (quote): void => { this._eventEmitter.emit('onQuoteChanged', quote); };

    private onAddOrder (order: Order): void {
        if (!this.isAllowInstrument(order.Instrument)) {
            return;
        }
        const paperPosition = this.getPaperPositionForOrder(order);
        if (!isNullOrUndefined(paperPosition)) {
            this.removePaperPosition(paperPosition.Instrument);
        }
        this._eventEmitter.emit('onAddOrder', order);
    }

    private onRemoveOrder (order: Order): void {
        if (!this.isAllowInstrument(order.Instrument)) {
            return;
        }
        this._eventEmitter.emit('onRemoveOrder', order);
    }

    private onUpdateOrder (order: Order): void {
        // TODO
    }

    private onAddPosition (position: Position): void {
        if (!this.isAllowInstrument(position.Instrument)) {
            return;
        }
        const paperPosition = this.getPaperPositionForPosition(position);
        if (!isNullOrUndefined(paperPosition)) {
            paperPosition.attachPosition(position);
            this._eventEmitter.emit('onUpdatePaperPosition', paperPosition);
        } else {
            const paperPosition = this.createPaperPositionForPosition(position);
            this._eventEmitter.emit('onCreatePaperPosition', paperPosition);
        }
        this._eventEmitter.emit('onAddPosition', position);
    }

    private onRemovePosition (position: Position): void {
        if (!this.isAllowInstrument(position.Instrument)) {
            return;
        }
        const paperPosition = this.getPaperPositionForPosition(position);
        if (!isNullOrUndefined(paperPosition) && paperPosition.isAttachedPosition(position)) {
            this.removePaperPosition(paperPosition.Instrument);
        }
        this._eventEmitter.emit('onRemovePosition', position);
    }

    private onUpdatePosition (position: Position): void {
        if (!this.isAllowInstrument(position.Instrument)) {
            return;
        }
        const paperPosition = this.getPaperPositionForPosition(position);
        if (!isNullOrUndefined(paperPosition)) {
            if (paperPosition.isAttachedPosition(position)) {
                paperPosition.updatePosition();
            } else {
                paperPosition.attachPosition(position);
            }
            this._eventEmitter.emit('onUpdatePaperPosition', paperPosition);
        } else {
            const paperPosition = this.createPaperPositionForPosition(position);
            this._eventEmitter.emit('onCreatePaperPosition', paperPosition);
        }
        this._eventEmitter.emit('onUpdatePosition', position);
    }

    private onAddSLOrderToPosition (position: Position): void {
        if (!this.isAllowInstrument(position.Instrument)) {
            return;
        }
        this.onUpdatePosition(position);
    }

    private onRemoveSLOrderFromPosition (position: Position): void {
        if (!this.isAllowInstrument(position.Instrument)) {
            return;
        }
        this.onUpdatePosition(position);
    }

    private onAddTPOrderToPosition (position: Position): void {
        if (!this.isAllowInstrument(position.Instrument)) {
            return;
        }
        this.onUpdatePosition(position);
    }

    private onRemoveTPOrderFromPosition (position: Position): void {
        if (!this.isAllowInstrument(position.Instrument)) {
            return;
        }
        this.onUpdatePosition(position);
    }
    // #endregion

    private getUnderlierPrice (): number {
        const account: Account = this.account;
        const underlier: Instrument = this.underlier;
        let underlierPrice: number = NaN;
        switch (underlier.HistoryType) {
        case HistoryType.QUOTE_LEVEL1:
            underlierPrice = underlier.Level1.GetBid(account);
            break;
        case HistoryType.QUOTE_ASK:
            underlierPrice = underlier.Level1.GetAsk(account);
            break;
        default:
            underlierPrice = underlier.Level1.GetLastPrice(account);
            break;
        }

        if (!isValidNumber(underlierPrice) || underlierPrice <= 0) {
            return NaN;
        } else {
            return underlierPrice;
        }
    }

    private getOptionPrice (spotInstrument: Instrument): number {
        const account = this.account;
        const ivCalculationPrice = this.ivCalculationPrice;

        let spotPrice = NaN;
        switch (ivCalculationPrice) {
        case IVCalculationPrice.Ask:
            spotPrice = spotInstrument.Level1.GetAsk(account);
            break;
        case IVCalculationPrice.BidAsk:
            spotPrice = (spotInstrument.Level1.GetAsk(account) + spotInstrument.Level1.GetBid(account)) / 2;
            break;
        case IVCalculationPrice.Bid:
            spotPrice = spotInstrument.Level1.GetBid(account);
            break;
        case IVCalculationPrice.Last:
            spotPrice = spotInstrument.Level1.GetLastPrice(account);
            break;
        }

        if (!isValidNumber(spotPrice) || spotPrice <= 0) {
            return NaN;
        } else {
            return spotPrice;
        }
    }

    private getPaperPositionForOrder (order: Order): PaperPosition {
        if (isNullOrUndefined(order) || !isValidString(order.Comment) || !order.Comment.startsWith('SavedOrder.')) {
            return undefined;
        }
        const id = order.Comment.substring('SavedOrder.'.length);
        const paperPosition = this._paperPositionsCache.getPaperPosition(order.Account, this.fakeOption, order.Instrument);
        if (!isNullOrUndefined(paperPosition) && paperPosition.Id === id) {
            return paperPosition;
        } else {
            return undefined;
        }
    }

    private getPaperPositionForPosition (position: Position): PaperPosition {
        if (isNullOrUndefined(position)) {
            return undefined;
        }
        const paperPosition = this._paperPositionsCache.getPaperPosition(position.Account, this.fakeOption, position.Instrument);
        if (isNullOrUndefined(paperPosition)) {
            return undefined;
        }
        if (paperPosition.isAttachedPosition(position)) {
            return paperPosition;
        } else if (isValidString(position.Comment) && position.Comment.startsWith('SavedOrder.') && position.Comment.substring('SavedOrder.'.length) === paperPosition.Id) {
            return paperPosition;
        } else {
            return undefined;
        }
    }

    private createPaperPositionForPosition (position: Position): PaperPosition {
        const paperPosition = this._paperPositionsCache.createPaperPosition(this.account, this.fakeOption, position.Instrument);
        paperPosition.attachPosition(position);
        return paperPosition;
    }

    private isAllowInstrument (instrument: Instrument): boolean {
        if (isNullOrUndefined(this.fakeOption) || !isValidArray(this.strikePriceSettings)) {
            return false;
        }
        if (this.fakeOption.Route !== instrument.Route) {
            return false;
        }
        for (let i = 0; i < this.strikePriceSettings.length; i++) {
            const settings = this.strikePriceSettings[i];
            if (settings.InstrumentTradableIDPut === instrument.InstrumentTradableID ||
                settings.InstrumentTradableIDCall === instrument.InstrumentTradableID) {
                return true;
            }
        }
    }
}
