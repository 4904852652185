// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts";
import { contextMenuHandler } from "../../Utils/AppHandlers.js";
import { TerceraHeaderMenuControlTemplate } from "../../templates.js";
import { ContainerControl } from "./ContainerControl.js";


export let TerceraHeaderMenuControl = ContainerControl.extend({

    template: TerceraHeaderMenuControlTemplate,
    data: function ()
    {
        return {
            btnClass: '',
            attach: true
        };
    },
    menuitems: null,
});

TerceraHeaderMenuControl.prototype.getType = function () { return 'TerceraHeaderMenuControl' };

TerceraHeaderMenuControl.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.apply(this);

    this.on('btnClick', function ()
    {
        contextMenuHandler.Show(this.menuitems, this.getX(), this.getY() + 24);
    });
    this.observe('attach', function (newVal, oldVal, key) { this.initContextMenu(); }, { init: false });
    this.observe('tooltip', function (newVal, oldVal, key)
    {
        var x = 0;
    });
};

TerceraHeaderMenuControl.prototype.oncomplete = function ()
{
    ContainerControl.prototype.oncomplete.apply(this);
    this.initContextMenu();

    Resources.onLocaleChange.Subscribe(this.localize, this);
    this.completed = true;
};

TerceraHeaderMenuControl.prototype.dispose = function ()
{
    Resources.onLocaleChange.UnSubscribe(this.localize, this);

    ContainerControl.prototype.dispose.apply(this);
};

TerceraHeaderMenuControl.prototype.localize = function ()
{
    let menuitems = this.menuitems;
    if (!menuitems) return;

    let HeaderMenuDataArray = TerceraHeaderMenuControl.HeaderMenuDataArray;
    let len = HeaderMenuDataArray.length;
    for (let i = 0; i < len; i++)
    {
        let mi = menuitems[i];
        let textKey = HeaderMenuDataArray[i].textKey;
        mi.text = Resources.getResource(textKey);
    }
};

TerceraHeaderMenuControl.prototype.initContextMenu = function ()
{
    var clickItemEvent = function (curItem)
    {
        //второй параметр итем по которому кликнули
        this.fire("MenuClicked", curItem)
    }.bind(this);

    let length = TerceraHeaderMenuControl.HeaderMenuDataArray.length;
    let items = [];
    let attach = this.get("attach");
    for (let i = 0; i < length; i++)
    {
        let curData = TerceraHeaderMenuControl.HeaderMenuDataArray[i];

        // if (attach && curData.type === TerceraHeaderMenuControl.DETTACH || !attach && curData.type === TerceraHeaderMenuControl.ATTACH)
        //     continue;

        let newItem = {};
        newItem.text = Resources.getResource(curData.textKey);
        newItem.event = clickItemEvent;
        newItem.type = curData.type;
        newItem.visible = !(attach && curData.type === TerceraHeaderMenuControl.DETTACH || !attach && curData.type === TerceraHeaderMenuControl.ATTACH)
        items.push(newItem);
    }
    this.menuitems = items;
};

TerceraHeaderMenuControl.ATTACH = 1;
TerceraHeaderMenuControl.DETTACH = 2;
TerceraHeaderMenuControl.HeaderMenuDataArray = [
    { textKey: 'panel.external.Attach', type: TerceraHeaderMenuControl.ATTACH },
    { textKey: 'panel.external.Dettach', type: TerceraHeaderMenuControl.DETTACH }
];