// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PFixFieldSet } from '../Fields/FieldsImport';
import { FieldsFactory } from '../Factory/FieldsFactory';

export class LineGroup extends PFixFieldSet {
    constructor (fields?) {
        super(FieldsFactory.LINE_GROUP_NAME, null, fields);
    }

    public getContent (): string {
        return this.GetValue(FieldsFactory.FIELD_TEXT);
    }

    public setContent (content: string): void {
        this.SetField(FieldsFactory.FIELD_TEXT, content);
    }

    public getLineNum (): number {
        return this.GetValue(FieldsFactory.FIELD_LINE_NUMBER);
    }

    public setLineNum (lineNumber: number): void {
        this.SetField(FieldsFactory.FIELD_LINE_NUMBER, lineNumber);
    }

    public static MAX_LENGTH = 10000;

    public static setLongText (text): LineGroup[] {
        const groups: LineGroup[] = [];
        let number = 0;
        let index = 0;
        const length = text.length;
        while (index < length) {
            const len = Math.min(length - index, this.MAX_LENGTH);
            const curString = text.substring(index, index + len);

            index += len;

            const group = new LineGroup();
            group.setContent(curString);
            group.setLineNum(number);
            groups[number] = group;

            number += 1;
        }

        return groups;
    }

    public static CompareTo (a, b): number {
        const an = a.getLineNum();
        const bn = b.getLineNum();
        if (an < bn) {
            return -1;
        } else if (an > bn) {
            return 1;
        } else {
            return 0;
        }
    }

    public static getLongText (fieldSet): string {
        const groups = fieldSet.GetGroups(FieldsFactory.LINE_GROUP_NAME);
        const lines = [];

        let i;
        const count = groups.length;
        for (i = 0; i < count; i++) {
            lines[i] = new LineGroup(groups[i]);
        }

        lines.sort(this.CompareTo);

        let sb = '';
        for (i = 0; i < count; i++) {
            sb += lines[i].getContent();
        }

        return sb;
    }
}
