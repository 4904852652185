import { AccountWidgetDragAndDropTemplate } from '../../../templates';
import { Control } from './../Control_ts';

export class AccountWidgetDragAndDrop extends Control {
    private readonly LEFTTOPOFFSET: number = 5;

    constructor () {
        super();
    }

    public getType (): string { return 'AccountWidgetDragAndDrop'; };

    public setData (Header: string, Value: string, Color: string): void {
        void this.set({
            HeaderText: Header,
            ValueText: Value,
            ValueColor: Color
        });
    }

    public updatePosition (event): void {
        const evt = event.event;

        this.MoveTo(evt.clientX + this.LEFTTOPOFFSET, evt.clientY + this.LEFTTOPOFFSET);
    }

    private MoveTo (x: number, y: number): void {
        void this.set({
            left: x,
            top: y
        });
    }
}

Control.extendWith(AccountWidgetDragAndDrop, {
    template: AccountWidgetDragAndDropTemplate,
    data: function () {
        return {
            HeaderText: '',
            ValueText: ''
        };
    }
});
