// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

export class Statement {
    public Account: any = null;
    public InstrumentStr: any = null;
    public Date: any = null;
    public OperationType: any = null;
    public OperationID: any = null;
    public Amount: any = null;
    public OpenPrice: any = null;
    public ClosePrice: any = null;
    public Quantity: any = null;
    public Currency: any = null;
    public TradeOpeningDate: any = null;
    public OpenCrossPrise: any = null;
    public PositionID: string = null;
    public TradeId: string = null;

    public Dispose (): void {
        this.Account = null;
        this.InstrumentStr = null;
    }
}
