// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { Resources } from '../../../Commons/properties/Resources';

export class ViewSett {
    public RoundedAverageOpenPrice = true;
    public DisplayQuantityInLots = true;
    public ReverseButtonsOrder = false;
    public Tooltips = true;

    // TODO. Lame. Remove?
    public displayAmountInLots (): boolean {
        if (!Resources.isHidden(DISPLAY_QUANTITY_IN_LOTS_V)) {
            return this.DisplayQuantityInLots;
        }

        return false;
    }
}

export const DISPLAY_QUANTITY_IN_LOTS_V = 'property.general.showLotsFX';
