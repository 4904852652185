// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { OESideSelectorTemplate } from "../../../templates.js";
import { ContainerControl } from "../../elements/ContainerControl.js";
import { Resources } from "../../../Commons/properties/Resources.ts";
import { OperationType } from "../../../Utils/Trading/OperationType.ts";
import { GeneralSettings } from "../../../Utils/GeneralSettings/GeneralSettings.ts";

export let OESideSelector = ContainerControl.extend({
    template: OESideSelectorTemplate,
    data: function ()
    {
        return {
            label: null,
            items: null,
            side: null
        }
    }
})
OESideSelector.prototype.getType = function () { return 'OESideSelector' };

OESideSelector.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.call(this)

    let buyItem = {
        type: 'buy',
        value: OperationType.Buy,
        checked: true
    }

    let sellItem = {
        type: 'sell',
        value: OperationType.Sell,
        checked: false
    }

    this.set({
        items: [buyItem, sellItem],
        side: OperationType.Buy
    })

    this.orderItems()
    this.localize()

    this.on('sideChanged', this.onSideChanged)
    this.observe('side', function (n, o, k)
    {
        let rB = GeneralSettings.View.ReverseButtonsOrder
        let nV = n;
        let oV = o;
        if (rB)
            if (n)
            {
                nV = 0;
                oV = 1;
            }
            else 
            {
                nV = 1;
                oV = 0;
            }

        if (o !== undefined)
            this.set('items.' + oV + '.checked', false);

        let newStr = "items." + nV + ".checked";
        this.set(newStr, true);
    })
    GeneralSettings.SettingsChanged.Subscribe(this.orderItems, this)
    Resources.onLocaleChange.Subscribe(this.localize, this)
}

OESideSelector.prototype.dispose = function ()
{
    ContainerControl.prototype.dispose.call(this)

    this.off('sideChanged')
    GeneralSettings.SettingsChanged.UnSubscribe(this.orderItems, this)
    Resources.onLocaleChange.UnSubscribe(this.localize, this)
}

OESideSelector.prototype.onSideChanged = function (context, idx)
{
    this.set('side', this.get('items.' + idx + '.value'))
}

OESideSelector.prototype.orderItems = function ()
{
    let items = this.get('items')
    let buy = OperationType.Buy
    // Meh.
    let buyItem = items[0].value === buy ? items[0] : items[1]
    let sellItem = items[0].value !== buy ? items[0] : items[1]

    this.set('items',
        GeneralSettings.View.ReverseButtonsOrder
            ? [sellItem, buyItem]
            : [buyItem, sellItem]
    )
}

OESideSelector.prototype.localize = function ()
{
    this.set('label', Resources.getResource('panel.newOrderEntry.Side'))

    this.get('items').forEach(function (item, idx)
    {
        item.text = Resources.getResource(
            item.value === OperationType.Buy
                ? 'panel.newOrderEntry.buySideCheckBox'
                : 'panel.newOrderEntry.sellSideCheckBox'
        )
    })

    this.update('items')
}