// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

export enum EncrTypes {
    STANDART = 0,
    AES_GCM_NoPadding = 2
}

// Due to security restrictions, the SubtleCrypto API (crypto.subtle) is not available in non-secure contexts such as HTTP. 
// As a fallback mechanism for environments where crypto.subtle is unavailable, 
// we employ simplified encryption methods.
// For more information on this limitation, please refer to the warning issued by MDN Web Docs: 
// https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto

async function makeKey (key: string): Promise<CryptoKey | string> {
    const enc = new TextEncoder().encode(key);
    if (crypto.subtle != null) {
        return await crypto.subtle.importKey(
            'raw',
            enc,
            'AES-GCM',
            false, // whether the key is extractable (i.e. can be used in exportKey)
            ['encrypt', 'decrypt'] // can "encrypt", "decrypt", "wrapKey", or "unwrapKey"
        );
    } else {
        return key; // Fallback: Return the key itself
    }
}

export async function EncodeKey_AES_GCM_NoPadding (string: string, key: string): Promise<string> {
    const keyNew = await makeKey(key);
    if (crypto.subtle != null && typeof keyNew !== 'string') {
        const encrypted = await encrypt(string, keyNew);
        return btoa(String.fromCharCode(...new Uint8Array(encrypted.encrypted)));
    } else {
        // Fallback: Use a simplified encryption method
        return btoa(simpleEncrypt(string, key));
    }
}

export class ProXOREncryptionUtility {
    public static async Encode (dataInput, keyInput): Promise<any> {
        return await new Promise((resolve, reject) => {
            const data = ProXOREncryptionUtility.GetBytes(dataInput);
            const key = ProXOREncryptionUtility.GetBytes(keyInput);
            const cipher = new Array(data.length);
            const realKey = new Array(data.length);
            const mask = [0x49, 0x32, 0x76, 0x61, 0x6e, 0x20, 0x11, 0x4d, 0x65, 0x64, 0x76, 0x65, 0x64, 0x65, 0x60, 0x76];

            for (let i = 0; i < realKey.length; i++) {
                realKey[i] = (key[i % key.length] ^ mask[i % mask.length]);
            }

            for (let i = 0; i < data.length; i++) {
                cipher[i] = (data[i] ^ realKey[i]);
            }

            const u8 = new Uint8Array(cipher);
            resolve(btoa(String.fromCharCode.apply(null, u8)));
        });
    }

    public static GetBytes (data): number[] {
        const result = [];
        for (let i = 0; i < data.length; i++) {
            result.push(data.charCodeAt(i));
        }
        return result;
    }
}

export async function makeKeyWithDerivation (key: string): Promise<CryptoKey | string> {
    if (crypto.subtle != null) {
        // Convert passphrase to an ArrayBuffer using TextEncoder
        const enc = new TextEncoder().encode(key);

        // Derive a key from the passphrase using PBKDF2
        const derivedKey = await crypto.subtle.importKey(
            'raw',
            enc,
            { name: 'PBKDF2' },
            false,
            ['deriveKey']
        );

        // Derive a key of suitable length (128, 192, or 256 bits) using the derived key and appropriate parameters
        return await crypto.subtle.deriveKey(
            {
                name: 'PBKDF2',
                salt: new Uint8Array(16), // Generate a random salt of suitable length
                iterations: 100000, // Adjust iterations as per your security requirements
                hash: 'SHA-256' // Use a suitable hash algorithm
            },
            derivedKey,
            { name: 'AES-GCM', length: 256 }, // Ensure the key length is appropriate (128, 192, or 256)
            false,
            ['encrypt', 'decrypt']
        );
    } else {
        return key; // Fallback: Return the key itself
    }
}

const IV = new Uint8Array([0x49, 0x32, 0x76, 0x61, 0x6e, 0x20, 0x11, 0x4d, 0x65, 0x64, 0x76, 0x65, 0x64, 0x65, 0x60, 0x76]);

export async function encrypt (string: string, key: CryptoKey | string): Promise<{ encrypted: ArrayBuffer, iv: Uint8Array }> {
    if (crypto.subtle != null && typeof key !== 'string') {
        const encoded = new TextEncoder().encode(string);
        const iv = IV;
        const encrypted = await crypto.subtle.encrypt(
            {
                name: 'AES-GCM',
                iv
            // tagLength: 128, //can be 32, 64, 96, 104, 112, 120 or 128 (default)
            },
            key, // from generateKey or importKey above
            encoded // ArrayBuffer of data you want to encrypt
        );

        return { encrypted, iv };
    } else {
        // Fallback: Use a simplified encryption method
        const encrypted = simpleEncrypt(string, key as string);
        const encoded = new TextEncoder().encode(encrypted);
        return { encrypted: encoded.buffer, iv: IV };
    }
}

export async function decrypt (encrypted: ArrayBuffer, key: CryptoKey | string): Promise<string> {
    if (crypto.subtle != null && typeof key !== 'string') {
        const iv = IV;
        const decrypted = await crypto.subtle.decrypt(
            {
                name: 'AES-GCM',
                iv
            },
            key,
            encrypted
        );

        return new TextDecoder().decode(decrypted);
    } else {
        // Fallback: Use a simplified decryption method
        const decoded = new TextDecoder().decode(encrypted);
        return simpleDecrypt(decoded, key as string);
    }
}

export function encodeArrayBufferToString (buffer: ArrayBuffer): string {
    return btoa(String.fromCharCode(...new Uint8Array(buffer)));
}

export function decodeStringToArrayBuffer (str: string): ArrayBuffer {
    return new Uint8Array(atob(str).split('').map(char => char.charCodeAt(0))).buffer;
}

export async function encryptAndDeriveKey (string: string, keyString: string): Promise<string> {
    const key = await makeKeyWithDerivation(keyString); // Encrypt the password using AES-GCM
    if (crypto.subtle != null && typeof key !== 'string') {
        const { encrypted } = await encrypt(string, key);
        return encodeArrayBufferToString(encrypted);
    } else {
        // Fallback: Use a simplified encryption method
        return btoa(simpleEncrypt(string, key as string));
    }
}

export async function decryptWithDerivedKey (encryptedPassword: string, keyString: string): Promise<string> {
    const key = await makeKeyWithDerivation(keyString); // Derive the key using AES-GCM
    if (crypto.subtle != null && typeof key !== 'string') {
        return await decrypt(decodeStringToArrayBuffer(encryptedPassword), key);
    } else {
        // Fallback: Use a simplified decryption method
        return simpleDecrypt(atob(encryptedPassword), key as string);
    }
}

// Function to generate a key for XOR encryption
function generateXORKey (key: string): string {
    // Reverse the key
    return key.split('').reverse().join('');
}

// Function to encrypt a string using XOR encryption
function simpleEncrypt (string: string, key: string): string {
    const xorKey = generateXORKey(key);
    let result = '';
    for (let i = 0; i < string.length; i++) {
        const charCode = string.charCodeAt(i) ^ xorKey.charCodeAt(i % xorKey.length);
        result += String.fromCharCode(charCode);
    }
    return result;
}

// Function to decrypt a string encrypted with XOR encryption
function simpleDecrypt (encrypted: string, key: string): string {
    return simpleEncrypt(encrypted, key); // XOR encryption is its own inverse
}
