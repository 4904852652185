// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { AgressorFlagType } from '../../Utils/Enums/Constants';
import { Message } from './Message';
import { DirectQuoteMessage } from './DirectQuoteMessage';

export class DirectQuote3Message extends DirectQuoteMessage {
    public Exchange: string | null = null;
    public Price = Number.NaN;
    public Size = Number.NaN;
    public OpenInterest: number | null = null;
    public BuyerSource: any = null;
    public SellerSource: any = null;
    public BuyerSourceId = 0;
    public SellerSourceId = 0;
    public LastVenue: number | null = null;
    // оно же аггрессор флаг -1-роут не ставит, 0-бай, 1-селл
    public LType = AgressorFlagType.NotSet;

    constructor () {
        super(Message.CODE_QUOTE3);

        this.Type = DirectQuoteMessage.QUOTE_TRADES;
    }
}
