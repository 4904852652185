// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class LogoutMessage extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_PFIX_LOGOUT_MESSAGE, fieldSet);
    }

    public getText (): string {
        return this.getFieldValue(FieldsFactory.FIELD_TEXT);
    }

    public setText (text: string): void {
        this.setFieldValue(FieldsFactory.FIELD_TEXT, text);
    }

    public getSessionId (): string {
        return this.getFieldValue(FieldsFactory.FIELD_SESSIONID);
    }

    public setSessionId (sessionId: string): void {
        this.setFieldValue(FieldsFactory.FIELD_SESSIONID, sessionId);
    }

    /// <summary>
    /// для брокера, выбивающего трейдера
    /// </summary>
    public setUserId (userId: number): void {
        this.setFieldValue(FieldsFactory.FIELD_USER_ID, userId);
    }

    public getErrorCode (): number {
        return this.getFieldValue(FieldsFactory.FIELD_ERROR_CODE);
    }

    public getLogoutType (): any {
        return this.getFieldValue(FieldsFactory.FIELD_TYPE);
    }
}
