// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";
import { QuickTreeNode } from "../elements/QuickTree/QuickTreeNode.ts";
import { TerceraQuickTree } from "../elements/QuickTree/TerceraQuickTree.ts";
import { TerceraWindowBase } from "./TerceraWindowBase.js";

export let QuickTreeViewScreen = TerceraWindowBase.extend({
    data: function ()
    {
        return {
            zIndex: 400,
        };
    },
    quickTree: null
});

QuickTreeViewScreen.prototype.getType = function () { return 'QuickTreeViewScreen' };

QuickTreeViewScreen.prototype.oninit = function ()
{
    TerceraWindowBase.prototype.oninit.apply(this);
};

QuickTreeViewScreen.prototype.oncomplete = function ()
{
    TerceraWindowBase.prototype.oncomplete.apply(this);
    this.OnResize.Subscribe(this.onResize, this);
};

QuickTreeViewScreen.prototype.onResize = function ()
{
    this.quickTree.set({ width: this.get("width"), height: this.get("height") - 65 });
}

QuickTreeViewScreen.prototype.Populate = function (data)
{
    if (data == null)
        return;

    for (let i = 0 ; i < data.length; i++)
    {
        var nodeText = data[i];
        if (!Array.isArray(nodeText))
        {
            let node = new QuickTreeNode(nodeText);
            this.quickTree.quickTree.AddNode(node);
        }
        else
            this.AddNodesRcursive(data[i], this.quickTree.quickTree.nodeCollection[this.quickTree.quickTree.nodeCollection.length - 1]);
    }
    this.quickTree.quickTree.Draw(true);
}

QuickTreeViewScreen.prototype.AddNodesRcursive = function (data, parentNode)
{
    parentNode.collapsed = false;
    for (let i = 0 ; i < data.length; i++)
    {
        var nodeText = data[i];
        if (!Array.isArray(nodeText))
        {
            let node = new QuickTreeNode(nodeText);
            parentNode.AddChildNode(node);
        }
        else
            this.AddNodesRcursive(data[i], parentNode.childNodes[parentNode.childNodes.length - 1]);
    }
}

QuickTreeViewScreen.prototype.dispose = function ()
{
    this.OnResize.UnSubscribe(this.onResize, this);

    TerceraWindowBase.prototype.dispose.apply(this);
}

QuickTreeViewScreen.Show = function (data)
{
    var treeView = new QuickTreeViewScreen();
    MainWindowManager.MainWindow.addControl(treeView);
    treeView.setBounds(50, 50, 390, 330);


    var quickTree = new TerceraQuickTree();
    quickTree.setBounds(0, 0, 390, 330 - 65);
    treeView.addControl(quickTree);
    treeView.quickTree = quickTree;

    treeView.Populate(data);
}