// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Point } from '../../Commons/Geometry';
import { ChartMath } from '../Utils/ChartMath';
import { ToolView } from './ToolView';
import { EllipseToolView } from './EllipseToolView';
import { type PriceChannelDataCacheTool } from '../../Commons/cache/Tools/PriceChannelDataCacheTool';

export class PriceChannelToolView extends EllipseToolView<PriceChannelDataCacheTool> {
    public addP1 = new Point();
    public addP2 = new Point();

    public override Draw (gr, ww, param): void {
        const dataCacheTool = this.dataCacheTool;
        const rayType = dataCacheTool.RayType;
        const pen = dataCacheTool.Pen;
        const penRay = dataCacheTool.PenRay;

        const screenPoints = this.screenPoints;

        const p0 = screenPoints[0];
        const x0 = p0[0];
        const y0 = p0[1];

        const p1 = screenPoints[1];
        const x1 = p1[0];
        const y1 = p1[1];

        const p2 = screenPoints[2];
        const x2 = p2[0];
        const y2 = p2[1];

        ToolView.DrawLineWithRay(gr, ww, x0, y0, x1, y1, rayType, pen, penRay);

        const r20 = Math.sqrt((y2 - y0) * (y2 - y0) + (x2 - x0) * (x2 - x0));
        const r21 = Math.sqrt((y2 - y1) * (y2 - y1) + (x2 - x1) * (x2 - x1));
        const r = Math.sqrt((y0 - y1) * (y0 - y1) + (x0 - x1) * (x0 - x1));
        let a = (r * r + r20 * r20 - r21 * r21) / (2 * r);
        let b = r - a;
        const xc = (a * x1 + b * x0) / (a + b);
        const yc = (a * y1 + b * y0) / (a + b);

        if (Math.abs(xc - x2) > 0.000001 || Math.abs(yc - y2) > 0.000001) {
            const xcc = x0 - (xc - x2);
            const ycc = y0 - (yc - y2);

            const rr = Math.sqrt((xcc - x0) * (xcc - x0) + (ycc - y0) * (ycc - y0));

            a = 0;
            b = rr - a;
            const xk0 = (a * xcc + b * x0) / (a + b);
            const yk0 = (a * ycc + b * y0) / (a + b);
            const xk1 = xk0 + (x1 - x0);

            let yk1 = yk0 + (xk1 - xk0) * (y1 - y0) / (x1 - x0);
            const dy = y0 - ycc;
            const dx = x0 - xcc;

            if (isNaN(yk1) || yk1 < ww.ClientRectangle.Y) { yk1 = y1; }

            this.addP1 = new Point(xk0 - dx, yk0 - dy);
            this.addP2 = new Point(xk1 - dx, yk1 - dy);

            ToolView.DrawLineWithRay(gr, ww,
                this.addP1.X, this.addP1.Y, this.addP2.X, this.addP2.Y,
                rayType, pen, penRay);
        }

        super.Draw(gr, ww, param);
    }

    public override DrawSelection (gr, ww): void {
        const screenPoints = this.screenPoints;

        const p0 = screenPoints[0];
        const x0 = p0[0];
        const y0 = p0[1];

        const p1 = screenPoints[1];
        const x1 = p1[0];
        const y1 = p1[1];

        const p2 = screenPoints[2];
        const x2 = p2[0];
        const y2 = p2[1];

        this.DrawSelectedPoint(gr, ww, x0, y0);
        this.DrawSelectedPoint(gr, ww, x1, y1);
        this.DrawSelectedPoint(gr, ww, x2, y2);
        this.DrawSelectedLine(gr, ww, x0, y0, x1, y1);
    }

    public override FindSelectedPoint (x: number, y: number): number {
        const screenPoints = this.screenPoints;
        const POINT_DX = ToolView.POINT_DX;
        for (let i = 0; i < 2; i++) {
            const p = screenPoints[i];

            const resX = p[0] - x;
            const resY = p[1] - y;

            if (resX < POINT_DX && resX > -POINT_DX && resY < POINT_DX && resY > -POINT_DX) {
                return i;
            }
        }
        if (ChartMath.CalcDistanceSqrFromPointToSection([this.addP1.X, this.addP1.Y], [this.addP2.X, this.addP2.Y], x, y) <= ToolView.TOOL_DX) {
            return 2;
        }

        return -1;
    }
}
