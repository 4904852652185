// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { ContainerControl } from '../../elements/ContainerControl_ts';
import { NewAccountDetailsGroupTemplate } from '../../../templates';
import { ControlsTypes } from '../../UtilsClasses/FactoryConstants';
import { GeneralGroupController } from './AccountDetailsGroupControllers/GeneralGroupController';

export class NewAccountDetailsGroup extends ContainerControl {
    public getType (): ControlsTypes {
        return ControlsTypes.NewAccountDetailsGroup;
    };

    constructor () {
        super();
    }

    oncomplete (): void {
        super.oncomplete();
        const account = this.get('account');
        const group = new GeneralGroupController(account, account.assetBalanceDefault);
    }
}

ContainerControl.extendWith(NewAccountDetailsGroup,
    {
        template: NewAccountDetailsGroupTemplate,
        data: function () {
            return {

            };
        }
    });
