export class OptionDataSeries {
    public readonly x: number;
    public readonly y: number;
    public readonly yStr: string;

    constructor (x: number = 0, y: number = 0, yStr: string = '') {
        this.x = x;
        this.y = y;
        this.yStr = yStr;
    }

    public isEmpty (): boolean {
        return this.x === 0 && this.y === 0;
    }
}
