// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectTokenVerifyMessage extends DirectMessageBaseClass {
    public IsExpired = false;
    public Login: string | null = null;
    public RequestID: number | null;

    constructor () {
        super(Message.CODE_PFIX_TOKEN_VERIFY_RESPONSE);
    }
}
