// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { OrderEditBaseUtils } from '../../../../Utils/Trading/OrderEditBaseUtils';
import { OrderEditUpdateData } from '../../../../Utils/Trading/OrderEditUpdateData';
import { OrderType } from '../../../../Utils/Trading/OrderType';
import { OrderExecutorUtils } from '../../../Trading/OrderExecutorUtils';
import { SLTPEdit } from '../SLTPEdit';
import { OrderEditBase } from './OrderEditBase';

export class MarketOrderEdit extends OrderEditBase {
    constructor (data) {
        super(data);

        this.sltp = new SLTPEdit(data.dataCache, data.forceSLTPOffset);
        this.slBasePriceGetter = this.slBasePriceGetter.bind(this);
        this.tpBasePriceGetter = this.tpBasePriceGetter.bind(this);
    }

    public override getParameterNameArray (): string[] {
        return [
            OrderEditBaseUtils.POSITION_SIZING_PARAM,
            OrderEditBaseUtils.SLTP_PARAM
        ];
    }

    // TODO. Refactor. Same as OrderTypeBase.Id function.
    public override getOrderTypeId (): OrderType {
        return OrderType.Market;
    }

    // TODO. Refactor. Use error/warning dicts for sltp.
    // public valid () {
    //   return OrderEditBase.prototype.valid.call(this) &&
    //     this.sltp.valid()
    // }

    public validateParameters (): any {
        const updatedParamNameDict = {};

        if (this.sltp.validate(
            this.slBasePriceGetter,
            this.tpBasePriceGetter,
            this.getTradingData())) {
            updatedParamNameDict[OrderEditBaseUtils.SLTP_PARAM] = true;
        }

        updatedParamNameDict[OrderEditBaseUtils.POSITION_SIZING_PARAM] = true;

        return updatedParamNameDict;
    }

    // #region SLTP Price Comparers

    public getBasePrice (): number {
        let result = 1;

        const quote = this.quote;
        if (quote) {
            result = quote[this.buy() ? 'AskSpread_SP_Ins' : 'BidSpread_SP_Ins'](this.instrument?.DataCache.GetSpreadPlan(this.account), this.instrument);
        }

        return result;
    }

    public slBasePriceGetter (): number {
        let result = 1;
        const isBuy = this.buy();
        let askOrBid = isBuy ? 'BidSpread_SP_Ins' : 'AskSpread_SP_Ins'; // согласно последнему комменту аналитика в https://tp.traderevolution.com/entity/101728

        const bySLTPtrigger = this.sltp.getSLTPTriggerStr(true, isBuy); // picking trigger price #109798 docs(3.3.2) Trading logic)
        if (bySLTPtrigger) {
            askOrBid = bySLTPtrigger + 'Spread_SP_Ins';
        }

        const quote = this.quote;
        if (quote) {
            result = quote[askOrBid](this.instrument?.DataCache.GetSpreadPlan(this.account), this.instrument);
        }

        return result;
    }

    public tpBasePriceGetter (): number {
        let result = 1;
        const isBuy = this.buy();
        let askOrBid = isBuy ? 'AskSpread_SP_Ins' : 'BidSpread_SP_Ins'; // согласно последнему комменту аналитика в https://tp.traderevolution.com/entity/101728

        const bySLTPtrigger = this.sltp.getSLTPTriggerStr(false, isBuy); // picking trigger price #109798 docs(3.3.2) Trading logic)
        if (bySLTPtrigger) {
            askOrBid = bySLTPtrigger + 'Spread_SP_Ins';
        };

        const quote = this.quote;
        if (quote) {
            result = quote[askOrBid](this.instrument?.DataCache.GetSpreadPlan(this.account), this.instrument);
        }

        return result;
    }

    // #endregion SLTP Price Comparers

    public override getConfirmationText (): string {
        const quote = this.quote;
        const buy = this.buy();

        const sp = this.instrument?.DataCache.GetSpreadPlan(this.account);

        return OrderExecutorUtils.buildOrderEditConfirmationText(
            'panel.orders.Market', // TODO.
            this.tif,
            this.account,
            this.instrument,
            this.quantity,
            buy,
            quote ? ([buy ? quote.AskSpread_SP_Ins(sp, this.instrument) : quote.BidSpread_SP_Ins(sp, this.instrument)]) : [],
            this.sltp.getConfirmationText(this.getTradingData()),
            null,
            this.productType,
            OrderType.Market,
            null,
            this.getLeverageValue());
    }

    // TODO. UGLY. Refactor. details are at the top OrderEditBase.ts
    public setSLTP (sltpHolder): void {
        const dp = this.sltp.createDynPropertyForRawSLTP(
            sltpHolder,
            this.getTradingData(),
            this.slBasePriceGetter,
            this.tpBasePriceGetter);

        this.updateParameters(new OrderEditUpdateData({ sltp: dp }));
    }
}
