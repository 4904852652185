// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

export class InstrumentLookupComparer {
    public static sortInstrumentList (instrumentList: any[], pattern): any[] {
        const instrumentSample = pattern.toLowerCase();

        instrumentList.sort(InstrumentLookupComparer.compareInstruments.bind(null, instrumentSample));

        return instrumentList;
    }

    public static compareInstruments (instrumentSample: string, a, b): number {
        let result = String.CompareOrdinal(a.isOptionSymbol, b.isOptionSymbol);

        if (!result) {
            if (a.isOptionSymbol && b.isOptionSymbol) {
                if (a.ForwardBaseInstrument != null && b.ForwardBaseInstrument != null) {
                    result = String.CompareOrdinal(a.ForwardBaseInstrument.DisplayName().toLowerCase(), b.ForwardBaseInstrument.DisplayName().toLowerCase());
                }

                if (!result) {
                    if (a.ExpDate != b.ExpDate) {
                        result = a.ExpDate > b.ExpDate ? 1 : -1;
                    }
                }

                if (!result) {
                    if (a.StrikePrice != b.StrikePrice) {
                        result = a.StrikePrice > b.StrikePrice ? 1 : -1;
                    } // #113023
                    else {
                        result = a.PutCall > b.PutCall ? 1 : -1;
                    }
                } // #113012
            } else if (a.isFuturesSymbol && b.isFuturesSymbol) {
                result = String.CompareOrdinal(a.InstrumentSpecificType, b.InstrumentSpecificType);

                if (!result) {
                    if (a.ExpDate != b.ExpDate) {
                        result = a.ExpDate > b.ExpDate ? 1 : -1;
                    }
                }
            } else {
                if (instrumentSample.length) {
                    const aDisplayName = a.DisplayName().toLowerCase(); const bDisplayName = b.DisplayName().toLowerCase();
                    const aDescription = a.Description.toLowerCase(); const bDescription = b.Description.toLowerCase();

                    const resultObj = { value: result };

                    if (InstrumentLookupComparer.TryCompareByFullMatch(aDisplayName, bDisplayName, instrumentSample, resultObj)) {
                        result = resultObj.value;
                        if (result == 0) {
                            result = String.CompareOrdinal(aDescription, bDescription);
                        }

                        return result;
                    } else if (InstrumentLookupComparer.TryCompareByPartialMatch(aDisplayName, bDisplayName, instrumentSample, resultObj)) {
                        return resultObj.value;
                    } else if (InstrumentLookupComparer.TryCompareByFullMatch(aDescription, bDescription, instrumentSample, resultObj)) {
                        return resultObj.value;
                    } else if (InstrumentLookupComparer.TryCompareByPartialMatch(aDescription, bDescription, instrumentSample, resultObj)) {
                        return resultObj.value;
                    } else {
                        result = String.CompareOrdinal(aDisplayName, bDisplayName);
                    }
                } else {
                    result = String.CompareOrdinal(a.DisplayName().toLowerCase(), b.DisplayName().toLowerCase());
                }
            }
        }

        return result;
    }

    public static TryCompareByFullMatch (a, b, instrumentSample, result): boolean {
        // Priority 1 Full match with pattern
        const aIsFullMatch = a == instrumentSample;
        const bIsFullMatch = b == instrumentSample;

        result.value = booleanCompare(bIsFullMatch, aIsFullMatch);

        // Both have full match
        if (result.value == 0 && aIsFullMatch) {
            return true;
        }

        return result.value != 0;
    }

    public static TryCompareByPartialMatch (a, b, instrumentSample, result): boolean {
        // Priority 2  Start with
        const aIsStartWith = a.indexOf(instrumentSample) == 0;
        const bIsStartWith = b.indexOf(instrumentSample) == 0;
        result.value = booleanCompare(bIsStartWith, aIsStartWith);

        // Both starts with pattern
        if (result.value == 0 && aIsStartWith) {
            result.value = String.CompareOrdinal(a, b);
            return true;
        }

        // Priority 3 Contains pattern
        let aIsContains = false;
        if (result.value == 0) {
            aIsContains = a.indexOf(instrumentSample) >= 0;
            const bIsContains = b.indexOf(instrumentSample) >= 0;
            result.value = booleanCompare(bIsContains, aIsContains);
        }

        // Both contains pattern
        if (result.value == 0 && aIsContains) {
            result.value = String.CompareOrdinal(a, b);
            return true;
        }

        return result.value != 0;
    }

    public static sortName (first: string, second: string, searchText: string): number {
        if (first === second) {
            return 0;
        } else if (first === searchText) {
            return -1;
        } else if (second === searchText) {
            return 1;
        }

        const firstIncludes = first.includes(searchText);
        const secondIncludes = second.includes(searchText);
        if (firstIncludes && !secondIncludes) {
            return -1;
        } else if (!firstIncludes && secondIncludes) {
            return 1;
        } else if (firstIncludes && secondIncludes) {
            const firstStartsWith = first.startsWith(searchText);
            const secondStartsWith = second.startsWith(searchText);
            if (firstStartsWith && !secondStartsWith) {
                return -1;
            } else if (!firstStartsWith && secondStartsWith) {
                return 1;
            } else {
                return first.localeCompare(second);
            }
        } else {
            return 0;
        }
    }

    public static sortBySymbolDescription (firstSymbolName: string,
        secondSymbolName: string,
        firstSymbolDescription: string,
        secondSymbolDescription: string,
        searchText): number {
        const symbolNameSortResult = InstrumentLookupComparer.sortName(firstSymbolName, secondSymbolName, searchText);
        if (symbolNameSortResult < 0) {
            return -1;
        } else if
        (symbolNameSortResult > 0) {
            return 1;
        } else {
            const descriptionSortResult = InstrumentLookupComparer.sortName(firstSymbolDescription, secondSymbolDescription, searchText);
            if (descriptionSortResult < 0) {
                return -1;
            } else if (descriptionSortResult > 0) {
                return 1;
            } else {
                return 0;
            }
        }
    }
}

function booleanCompare (bool1: boolean, bool2: boolean): number {
    if (bool1 && bool2) {
        return 0;
    } else if (bool1) {
        return 1;
    } else return -1;
}
