// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Connection } from "../../Commons/Connection.ts";
import { CustomEvent } from "../../Utils/CustomEvents.ts";
import { ErrorInformationStorage } from "../../Commons/ErrorInformationStorage.ts";
import { Resources } from "../../Commons/properties/Resources.ts";
import { DialogResultsStuct } from "../../Commons/UtilsClasses/DialogResultsStuct.ts";
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";
import { TerceraBaseWindowTemplate, PropertySetupScreenTemplate, PropertySetupScreenFooterTemplate } from "../../templates.js";
import { ChartWindow } from "../elements/ChartWindow.js";
import { PairColorPicker } from "../elements/PairColorPicker.js";
import { TrioColorPicker } from "../elements/TrioColorPicker.ts";
import { TrioColorGradient } from "../elements/TrioColorGradient.ts";
import { TerceraBoolNumeric } from "../elements/TerceraBoolNumeric.js";
import { TerceraButton } from "../elements/TerceraButton.ts";
import { TerceraButtonColorPicker } from "../elements/TerceraButtonColorPicker.ts";
import { TerceraCheckBox } from "../elements/TerceraCheckBox.js";
import { TerceraCheckBoxEvent } from "../elements/TerceraCheckBoxEvent.js";
import { TerceraColorStyleWidthPicker } from "../elements/TerceraColorStyleWidthPicker.js";
import { TerceraComboBox, TerceraComboBoxEvents } from "../elements/TerceraComboBox.ts";
import { TerceraFontPicker } from "../elements/TerceraFontPicker.js";
import { TerceraImageUploader } from "../elements/TerceraImageUploader.js";
import { TerceraLabel } from "../elements/TerceraLabel.js";
import { TerceraLineStyleComboBox } from "../elements/TerceraLineStyleComboBox.ts";
import { TerceraNumeric } from "../elements/TerceraNumeric.js";
import { TerceraPropertyGroupSeparator } from "../elements/TerceraPropertyGroupSeparator.js";
import { TerceraRadioComboItemGroup } from "../elements/TerceraRadioComboItemGroup.js";
import { TerceraRangeControl } from "../elements/TerceraRangeControl.js";
import { TerceraSeparator } from "../elements/TerceraSeparator.js";
import { TerceraTextBox } from "../elements/TerceraTextBox.js";
import { TerceraTIFComboBox } from "../elements/TerceraTIFComboBox.ts";
import { TerceraWidthComboBox } from "../elements/TerceraWidthComboBox.ts";
import { CreateAlertNotificationSelector } from "../trading/alerts/CreateAlertNotificationSelector.js";
import { TerceraMessageBox } from "./TerceraMessageBox.js";
import { TerceraWindowBase } from "./TerceraWindowBase.js";
import { TypesManagerScreen } from "./TypesManagerScreen.js";
import { HtmlScroll } from "../../Commons/HtmlScroll.js";
import { TerceraGroupPanel } from "../elements/TerceraGroupPanel.js";
import { DynProperty } from "../../Commons/DynProperty.ts";
import { SoundManager } from "../../Utils/SoundManager.ts";
import { DataCache } from "../../Commons/DataCache.ts";
import { InsDefSettings } from "../../Commons/cache/InstrumentDefaults.ts";
import { propertySetupScreenForIndicatorHandler } from "../../Utils/AppHandlers.js";
import { TerceraLookup, TerceraLookupEvents } from "../elements/Lookup/TerceraLookup.ts";
import { AccountSelector } from "../elements/AccountSelector.ts";
import { TerceraInstrumentLookup } from "../elements/Lookup/TerceraInstrumentLookup.ts";
import { TerceraNumericWithRefreshButton } from '../elements/TerceraNumericWithRefreshButton.ts';
import { TerceraButtonEvents } from "../../Utils/Enums/ButtonEnums.ts";

export let PropertySetupScreen = TerceraWindowBase.extend({
    data: function ()
    {
        return {
            properties: [],
            callback: null,
            width: 653,
            height: 480,
            resizable: false,
            style_addition_header: 'js-PropertySetupScreen-AdditionalHeader',
            style_addition_body: 'js-PropertySetupScreen-AdditionalBody',
            style_addition_footer: 'js-PropertySetupScreen-AdditionalFooter'
        };
    },
    template: TerceraBaseWindowTemplate,
    partials: {
        bodyPartial: PropertySetupScreenTemplate,
        footerPartial: PropertySetupScreenFooterTemplate
    },

    lastSelectedGroup: null,
    visibleControls: [],
    sortedProperties: null,
    loggingAllowed: null,
    propertiesChanged: false
});

PropertySetupScreen.prototype.getType = function () { return 'PropertySetupScreen' };

PropertySetupScreen.prototype.oncomplete = function ()
{
    TerceraWindowBase.prototype.oncomplete.apply(this);
    HtmlScroll.addScroll(this.find('.js-right-panel'));

    Connection.onConnectStateChange.Subscribe(this.onConnectionStateChange, this);

    this.localize();
    this.center();
    this.loggingAllowed = DataCache.AllowLoggingChanges()
};

PropertySetupScreen.prototype.localize = function ()
{
    this.set({
        okBtnText: Resources.getResource('screen.properties.ok'),
        okBtnTooltip: Resources.getResource('screen.properties.ok.ToolTip'),
        cancelBtnText: Resources.getResource('screen.properties.cancel'),
        cancelBtnTooltip: Resources.getResource('screen.properties.cancel.ToolTip')
    });
};

PropertySetupScreen.prototype.PopulateProperties = function (properties)
{
    try
    {
        if (properties === null)
            return;

        //Клонируем свойтсва
        this.arrayProperties = new Object;
        this.arrayProperties = DynProperty.CloneDynProperties(properties);
        this.arrayProperties.sort(DynPropertyGroupComparer.Compare);
        //Заполняем дерево
        this.FillTree();
        // Обновляем связи для всех проперти
        for (var i = 0, max = this.arrayProperties.length; i < max; i++)
        {
            var p = this.arrayProperties[i];

            if (p.assignedProperty)
                DynProperty.UpdatePropertyRelation(this.arrayProperties, p);
        }
    }
    catch (ex) 
    {
        ErrorInformationStorage.GetException(ex);
    }
};

PropertySetupScreen.prototype.FillTree = function ()
{
    var properties = this.arrayProperties;
    var len = properties.length;

    var groups = {};

    for (var i = 0; i < len; i++)
    {
        //sorting
        var curProp = properties[i];
        var curPropGroup = curProp.group;
        if (curPropGroup === '')
            curPropGroup = DynProperty.PARAM_GROUP

        if (groups[curPropGroup] !== undefined)
            groups[curPropGroup].push(curProp);
        else
        {
            groups[curPropGroup] = [];
            groups[curPropGroup].push(curProp);
        };
    }

    var groupNames = Object.keys(groups);
    // Removes 'hidden' group from visible groups.
    var hiddenGr = groupNames.indexOf(DynProperty.HIDDEN_GROUP);
    if (hiddenGr !== -1)
        groupNames.splice(hiddenGr, 1);

    var len = groupNames.length;

    var evt = new CustomEvent();
    evt.Subscribe(this.switchBtnClick, this);

    for (var i = 0; i < len; i++)
    {
        var groupName = groupNames[i];
        if (groupName === DynProperty.HIDDEN_GROUP)
            continue;

        var groupLocStrings = DynProperty.getLocalizedGroupStrings(groupName);

        if (Resources.isHidden(groupLocStrings.name))
            continue;

        if (groupName === DynProperty.SEPARATOR_GROUP1 || groupName === DynProperty.SEPARATOR_GROUP2)
        {
            let separator = new TerceraSeparator();
            separator.set({ additionalClass: "separator-leftpanel" });
            this.Controls.leftPanel.addControl(separator);
            continue;
        }

        var btn = new TerceraButton();
        btn.set({
            text: groupLocStrings.name,
            tooltip: groupLocStrings.tooltip,
            terceraButtonStyle: "js-settings-switch",
            canCheck: true,
            name: groupName
        });

        btn.onBtnClick = evt;
        btn.btnInGroup = true;

        this.Controls.leftPanel.addControl(btn);
    };

    this.sortedProperties = groups;
    this.selectGroup(groupNames[0]);
    this.initLastBtn(groupNames[0]);

    // Hides left panel.
    //this.set('oneGroup', groupNames.length === 1);
};

// Основной метод: заполнение правой панели отфильтрованными свойствами
PropertySetupScreen.prototype.FillGrid = function (properties)
{
    // clear
    for (var i = 0; i < this.visibleControls.length; i++)
    {
        this.visibleControls[i].dispose();
    }
    this.visibleControls = [];

    var usedSeparatorsDict = {};

    var leftX = 16;
    var rightX = 199;

    let isConnectionCorrect = Connection.isConnectionCorrect()
    let notCorrectConnection_TT = Resources.getResource("dynProperty.inactive.tooltip");

    var gr;
    var label;
    var ractiveControl;
    for (var max = properties.length, i = 0; i < max; i += 1)
    {
        var prop = properties[i];

        if (!prop.visible || Resources.isHidden(prop.localizationKey)) continue;

        gr = null;
        label = null;
        ractiveControl = null;

        // Adding separator groups.
        var separatorGroup = prop.separatorGroup.replace(/#.*#/, '');
        if (separatorGroup && !usedSeparatorsDict[separatorGroup])
        {
            usedSeparatorsDict[separatorGroup] = true;
            var separator = new TerceraPropertyGroupSeparator();
            separator.set({ text: separatorGroup });
            this.Controls.rightPanel.addControl(separator);
            this.visibleControls.push(separator);
        }

        switch (prop.type)
        {
            case DynProperty.SEPARATOR:
                var separator = new TerceraSeparator();
                this.Controls.rightPanel.addControl(separator);
                this.visibleControls.push(separator);
                continue;

            case DynProperty.GROUP_SEPARATOR:
                var separator = new TerceraPropertyGroupSeparator();
                separator.set({ text: prop.GetLocalizedName() });
                this.Controls.rightPanel.addControl(separator);
                this.visibleControls.push(separator);
                continue;

            case DynProperty.BUTTON:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);

                ractiveControl = new TerceraButton();
                ractiveControl.set({
                    terceraButtonStyle: 'js-toolbarbutton-gray21',
                    text: Resources.getResource(prop.value.locKey)
                });
                ractiveControl.on(TerceraButtonEvents.onClick, prop.value.callback);
                gr.addControl(ractiveControl, true);
                break;

            case DynProperty.INSTRUMENTS_DEFAULTS:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: Resources.getResource('TradeDefaultManager.Types manager')
                });
                gr.addControl(label);

                ractiveControl = new TerceraButton();
                ractiveControl.set({
                    terceraButtonStyle: 'js-button-gray25',
                    text: Resources.getResource('TradeDefaultManager.Set defaults')
                });
                ractiveControl.on(TerceraButtonEvents.onClick, PropertySetupScreen.onInstrumentDefaultsButtonClick);
                ractiveControl.Tag = prop;
                gr.addControl(ractiveControl, true);
                break;

            case DynProperty.STRING:
            case DynProperty.PASSWORD:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);

                var isPassword = prop.type === DynProperty.PASSWORD;
                ractiveControl = new TerceraTextBox();
                ractiveControl.set({
                    text: prop.value,
                    inputType: 'password'
                });
                ractiveControl.on(TerceraTextBox.Events.TextChanged, this.private_ControlValueChanged);
                ractiveControl.Tag = prop;
                gr.addControl(ractiveControl, true);
                break;

            case DynProperty.BOOLEAN:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                ractiveControl = new TerceraCheckBox();
                ractiveControl.set({
                    text: prop.GetLocalizedName(),
                    checked: prop.value,
                    enabled: prop.enabled
                });
                ractiveControl.on(TerceraCheckBox.Events.CheckedStateChange, this.private_ControlValueChanged);
                ractiveControl.Tag = prop;
                gr.addControl(ractiveControl);
                break;

            case DynProperty.BOOLEAN_EVT:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                ractiveControl = new TerceraCheckBoxEvent();
                ractiveControl.set({
                    text: prop.GetLocalizedName(),
                    checked: prop.value,
                    enabled: prop.enabled
                });
                ractiveControl.on(TerceraCheckBox.Events.CheckedStateChange, this.private_ControlValueChanged);
                ractiveControl.Tag = prop;
                ractiveControl.BeforeChangeEvent = prop.BeforeChangeEvent;
                gr.addControl(ractiveControl);
                break;

            case DynProperty.RADIOBUTTON_COMBOITEM:
                label = null;
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                ractiveControl = new TerceraRadioComboItemGroup();
                ractiveControl.set({
                    isHorisontal: true,
                    spaceBetweenItems: true
                });
                ractiveControl.setItems(prop.value, prop.objectVariants);
                ractiveControl.SelectedValueChanged.Subscribe(this.private_ControlValueChanged);
                ractiveControl.Tag = prop;
                gr.addControl(ractiveControl);
                break;

            case DynProperty.RADIOBUTTON_COMBOITEM_WITH_LABEL:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: Resources.getResource(prop.localizationKey)
                });
                gr.addControl(label);

                ractiveControl = new TerceraRadioComboItemGroup();
                ractiveControl.set({
                    isHorisontal: true,
                    spaceBetweenItems: true
                });
                ractiveControl.setItems(prop.value, prop.objectVariants);
                ractiveControl.SelectedValueChanged.Subscribe(this.private_ControlValueChanged);
                ractiveControl.Tag = prop;
                gr.addControl(ractiveControl, true);
                break;

            case DynProperty.BOOL_NUMERIC:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = null;
                ractiveControl = new TerceraBoolNumeric();
                ractiveControl.set({
                    boolNumericData: prop.value,
                    labelText: prop.GetLocalizedName()
                });
                ractiveControl.BoolNumericDataChanged.Subscribe(this.private_ControlValueChanged);
                ractiveControl.Tag = prop;
                gr.addControl(ractiveControl);
                break;

            case DynProperty.INTEGER:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);

                ractiveControl = new TerceraNumeric();
                ractiveControl.set({
                    minValue: prop.minimalValue,
                    maxValue: prop.maximalValue,
                    step: 1,
                    decimalPrecision: 0
                });
                ractiveControl.set({ value: prop.value });
                ractiveControl.on(TerceraNumeric.Events.ValueChanged, this.private_ControlValueChanged);
                ractiveControl.Tag = prop;
                gr.addControl(ractiveControl, true);
                break;

            case DynProperty.DOUBLE:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);

                ractiveControl = new TerceraNumeric();
                ractiveControl.set({
                    minValue: prop.minimalValue,
                    maxValue: prop.maximalValue,
                    step: prop.increment,
                    decimalPrecision: prop.decimalPlaces
                });
                ractiveControl.set({ value: prop.value });
                ractiveControl.on(TerceraNumeric.Events.ValueChanged, this.private_ControlValueChanged);
                ractiveControl.Tag = prop;
                gr.addControl(ractiveControl, true);
                break;

            case DynProperty.COMBOBOX_COMBOITEM:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);

                ractiveControl = new TerceraComboBox();
                ractiveControl.set({
                    text: prop.GetLocalizedName(),
                    items: prop.objectVariants
                });
                ractiveControl.Tag = prop;
                ractiveControl.setItembyValue(prop.value);
                ractiveControl.on(TerceraComboBoxEvents.ComboItemClicked, this.private_ControlItemChanged.bind(this));
                gr.addControl(ractiveControl, true);
                break;

            case DynProperty.COMBOBOX_COMBOITEM_TIF:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);

                ractiveControl = new TerceraTIFComboBox();
                ractiveControl.set({
                    text: prop.GetLocalizedName(),
                    items: prop.objectVariants
                });
                ractiveControl.Tag = prop;
                ractiveControl.setItembyValue(prop.value.tif.value);
                ractiveControl.set({ gtdDate: prop.value.date });
                ractiveControl.on(TerceraComboBoxEvents.ValueChange, this.private_ControlValueChanged.bind(this));
                //ractiveControl.dateChanged.Subscribe(this.private_ControlValueChanged, this);
                gr.addControl(ractiveControl, true);
                break;

            case DynProperty.COMBOBOX:
            case DynProperty.CSSTYLE:
            case DynProperty.LINEWIDTH:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);

                if (DynProperty.LINEWIDTH === prop.type)
                    ractiveControl = new TerceraWidthComboBox();
                else if (DynProperty.CSSTYLE === prop.type)
                    ractiveControl = new TerceraLineStyleComboBox();
                else
                {
                    ractiveControl = new TerceraComboBox();
                    ractiveControl.set('items', prop.objectVariants);
                }
                ractiveControl.set({
                    text: prop.GetLocalizedName()
                });
                ractiveControl.Tag = prop;
                ractiveControl.setItembyValue(prop.value);
                ractiveControl.on(TerceraComboBoxEvents.ComboItemClicked, this.private_ControlItemChanged.bind(this));
                gr.addControl(ractiveControl, true);
                break;

            case DynProperty.ALERT_NOTIFICATION_SELECTOR:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                ractiveControl = new CreateAlertNotificationSelector();
                ractiveControl.set({
                    //впадлу чёт думать
                    additionalLeftCB: 21,
                    selectedValue: prop.value
                });
                ractiveControl.Tag = prop;
                ractiveControl.ValueChanged.Subscribe(this.private_ControlValueChanged);
                gr.addControl(ractiveControl);
                break;

            case DynProperty.BOOLEAN_SOUND:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                ractiveControl = new TerceraCheckBox();
                ractiveControl.set({
                    checked: prop.value,
                    enabled: prop.enabled
                });
                ractiveControl.on(TerceraCheckBox.Events.CheckedStateChange, this.private_ControlValueChanged);
                ractiveControl.Tag = prop;
                gr.addControl(ractiveControl);

                var sound = new TerceraButton();
                sound.set({
                    terceraButtonStyle: "js-sound-test",
                    enabled: prop.enabled
                });
                sound.on(TerceraButtonEvents.onClick, this.private_OnSoundPlay);
                sound.Tag = prop;
                gr.addControl(sound);
                this.visibleControls.push(sound);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);
                // #51504
                ractiveControl.observe(
                    'enabled',
                    this.onBooleanSoundControlEnabledChanged,
                    { context: sound });
                break;

            case DynProperty.INSTRUMENT:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);

                ractiveControl = new TerceraInstrumentLookup();
                ractiveControl.oninit();
                ractiveControl.set({
                    mayContainTrees: true,
                });
                ractiveControl.Tag = prop;
                ractiveControl.selectItem(prop.value);
                ractiveControl.on(TerceraLookupEvents.SelectedItemChanged, this.private_InstrumentValueChanged);
                gr.addControl(ractiveControl, true);
                break;

            case DynProperty.ACCOUNT:
                if (DataCache.EnableForceLinkingByAccount())
                    continue;
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);

                ractiveControl = new AccountSelector();
                ractiveControl.oninit();
                ractiveControl.Tag = prop;
                ractiveControl.on(TerceraLookupEvents.SelectedItemChanged, this.private_AccountValueChanged);
                gr.addControl(ractiveControl, true);
                break;

            case DynProperty.COLOR:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);

                ractiveControl = new TerceraButtonColorPicker();
                ractiveControl.set({
                    coloringButtonColor: prop.value
                });
                ractiveControl.ColorChanged.Subscribe(this.private_ControlValueChanged);
                ractiveControl.Tag = prop;
                gr.addControl(ractiveControl, true);
                break;

            case DynProperty.PAIR_COLOR:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);

                ractiveControl = new PairColorPicker();
                ractiveControl.set({
                    pairColor: prop.value
                });
                ractiveControl.PairColorChanged.Subscribe(this.private_ControlValueChanged);
                ractiveControl.Tag = prop;
                gr.addControl(ractiveControl, true);
                break;

            case DynProperty.TRIO_COLOR:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);

                ractiveControl = new TrioColorPicker();
                ractiveControl.set({
                    trioColor: prop.value
                });
                ractiveControl.TrioColorChanged.Subscribe(this.private_ControlValueChanged);
                ractiveControl.Tag = prop;
                gr.addControl(ractiveControl, true);
                break;

            case DynProperty.TRIO_COLOR_GRADIENT:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);

                ractiveControl = new TrioColorGradient();
                ractiveControl.set({
                    value: prop.value
                });
                ractiveControl.Tag = prop;
                gr.addControl(ractiveControl, true);
                break;

            case DynProperty.COLOR_STYLE_WIDTH:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = null;
                ractiveControl = new TerceraColorStyleWidthPicker();
                ractiveControl.set({
                    styleItems: prop.objectVariants || [],
                    useStyleProperties: prop.objectVariants ? !!prop.objectVariants.useStyleProperties : false,
                    colorStyleWidth: prop.value,
                    labelText: prop.GetLocalizedName()
                });
                ractiveControl.ColorStyleWidthChanged.Subscribe(this.private_ControlValueChanged);
                ractiveControl.Tag = prop;
                gr.addControl(ractiveControl);
                break;

            case DynProperty.FONT:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);

                ractiveControl = new TerceraFontPicker();
                ractiveControl.set({
                    font: prop.value
                });
                ractiveControl.FontChanged.Subscribe(this.private_ControlValueChanged);
                ractiveControl.Tag = prop;
                gr.addControl(ractiveControl);
                break;

            case DynProperty.CHART_WINDOW:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);

                ractiveControl = new ChartWindow();
                ractiveControl.SetMinimum(prop.minimalValue);
                ractiveControl.SetMaximum(prop.maximalValue);
                ractiveControl.SetValue(prop.value);
                ractiveControl.ValueChanged.Subscribe(this.private_ControlValueChanged, this);
                var setValue = ractiveControl.GetValue();
                if (prop.value !== setValue)
                    ractiveControl.SetValue(setValue);

                ractiveControl.Tag = prop;
                gr.addControl(ractiveControl, true);
                break;

            case DynProperty.IMAGE_WEB:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);

                ractiveControl = new TerceraImageUploader();
                ractiveControl.set({
                    defaultImageBase64: prop.value
                });
                ractiveControl.CurrentImageBase64Changed.Subscribe(this.private_ControlValueChanged, this);
                ractiveControl.Tag = prop;
                gr.addControl(ractiveControl, true);
                break;

            case DynProperty.RANGECONTROL:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);

                ractiveControl = new TerceraRangeControl();
                ractiveControl.Tag = prop;
                ractiveControl.setInitValue(prop.value);
                ractiveControl.RangeValueChanged.Subscribe(this.private_ControlValueChanged, this);
                gr.addControl(ractiveControl, true);
                break;
            case DynProperty.DOUBLE_WITH_REFRESH_BUTTON:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);

                ractiveControl = new TerceraNumericWithRefreshButton();
                ractiveControl.set({
                    minValue: prop.minimalValue,
                    maxValue: prop.maximalValue,
                    step: prop.increment,
                    decimalPrecision: prop.decimalPlaces,
                    refreshCallback: prop.callback
                });
                ractiveControl.set({ value: prop.value });
                ractiveControl.on(TerceraNumericWithRefreshButton.Events.ValueChanged, this.private_ControlValueChanged);
                ractiveControl.Tag = prop;
                gr.addControl(ractiveControl, true);
                break;

            default:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);
                this.visibleControls.push(label);
                curY += label.get('height') + 7;

                continue;
                throw new Error('Not supported DynProperty type: ' + prop.type);
        }

        // update property relation
        if (ractiveControl)
            ractiveControl.ownerPanel = this;

        if (label)
            this.visibleControls.push(label);
        if (gr)
            this.visibleControls.push(gr);
        if (ractiveControl)
        {
            // TODO. Remove previous enabled assignments.
            ractiveControl.set('enabled', prop.enabled);
            this.visibleControls.push(ractiveControl);
        }
        if (prop.tooltip)
            ractiveControl.set({ tooltip: prop.tooltip });

        if (!isConnectionCorrect)
            ractiveControl.set({ tooltip: notCorrectConnection_TT, enabled: false });

        prop.AssignedControl = ractiveControl;
    }
};

//#region DynProperty value changed region

PropertySetupScreen.prototype.private_UpdatePropertyRelation = function (sender, tag)
{
    var scr = sender.ownerPanel;
    scr.propertiesChanged = true;

    if (tag.assignedProperty && tag.assignedProperty.length)
    {
        DynProperty.UpdatePropertyRelation(scr.arrayProperties, tag);
        scr.selectGroup(scr.lastSelectedGroup.get("name"));
    }
};

PropertySetupScreen.prototype.private_ControlValueChanged = function (context, value)
{
    let sender = context.ractive;
    if (isNullOrUndefined(sender))
    {
        sender = context;
    }

    var tag = sender.Tag;
    tag.value = value;

    sender.ownerPanel.private_UpdatePropertyRelation(sender, tag);
};

PropertySetupScreen.prototype.private_ControlItemChanged = function (context, item)
{
    this.private_ControlValueChanged(context, item.tag)
};

PropertySetupScreen.prototype.private_AccountValueChanged = function (context, value)
{
    let sender = context.ractive;
    if (isNullOrUndefined(sender))
    {
        sender = context;
    }

    var tag = sender.Tag;
    tag.value = value.AcctNumber;

    sender.ownerPanel.private_UpdatePropertyRelation(sender, tag);
};

PropertySetupScreen.prototype.private_InstrumentValueChanged = function (context, value)
{
    let sender = context.ractive;
    if (isNullOrUndefined(sender))
    {
        sender = context;
    }

    var tag = sender.Tag;
    tag.value = value.GetInteriorID ? value.GetInteriorID() : value;

    sender.ownerPanel.private_UpdatePropertyRelation(sender, tag);
};

PropertySetupScreen.prototype.private_OnSoundPlay = function ()
{
    SoundManager.tryPlaySound(this.Tag.localizationKey, true);
};

PropertySetupScreen.prototype.onBooleanSoundControlEnabledChanged = function (newVal)
{
    var soundBtn = this;
    soundBtn.set('enabled', newVal);
};

//#region Instruments Defaults

PropertySetupScreen.onInstrumentDefaultsButtonClick = function (context)
{
    let sender = context.ractive;
    var prop = sender.Tag;
    // For TypesManagerScreen.
    sender.getAllInstrumentsDefaultSettings =
        sender.getAllInstrumentsDefaultSettings ||
        function () 
        {
            var insDefSettings = new InsDefSettings();
            insDefSettings.LoadFromXML(this.Tag.value);
            return insDefSettings;
        };

    sender.typesManagerScreenCallBack =
        sender.typesManagerScreenCallBack ||
        function (settings)
        {
            if (!settings)
                return;

            var sender = this;
            var prop = sender.Tag;
            prop.value = settings.ToXML();
            var scr = sender.ownerPanel;
            scr.propertiesChanged = true;
        };

    TypesManagerScreen.show(
        sender,
        null,
        sender.typesManagerScreenCallBack.bind(sender));
};

//#endregion

//#endregion

// For public use.
PropertySetupScreen.prototype.selectPage = function (name)
{
    if (this.get('oneGroup')) return;

    if (!name)
    {
        var lastSelectedBtn = this.lastSelectedGroup;
        if (lastSelectedBtn) this.selectGroup(lastSelectedBtn.get('name'));
        return;
    }

    var leftPanel = this.Controls.leftPanel;
    if (!leftPanel) return;

    var lastSelectedBtn = this.lastSelectedGroup;
    if (lastSelectedBtn) lastSelectedBtn.set('checked', false);

    var newSelectedBtn = leftPanel.Controls[name];
    if (newSelectedBtn)
    {
        newSelectedBtn.set('checked', true);
        this.lastSelectedGroup = newSelectedBtn;
        this.selectGroup(name);
    }
};

PropertySetupScreen.prototype.switchBtnClick = function (sender, btn)
{
    if (this.lastSelectedGroup && this.lastSelectedGroup !== btn)
        this.lastSelectedGroup.set({ checked: false });
    this.lastSelectedGroup = btn;

    this.selectGroup(btn.get('name'));
};

PropertySetupScreen.prototype.selectGroup = function (name)
{
    this.FillGrid(this.sortedProperties[name]);
};

PropertySetupScreen.prototype.initLastBtn = function (name)
{
    var btn = this.Controls.leftPanel.Controls[name];
    if (btn)
    {
        btn.set({ checked: true });
        this.lastSelectedGroup = btn;
    }
};

PropertySetupScreen.prototype.setOnClosedCallback = function (onClosedCallback)
{
    this.onClosedCallback = onClosedCallback;
    this.on('teardown', function ()
    {
        if (this.onClosedCallback)
            this.onClosedCallback();

        delete this.onClosedCallback;
    });
};

PropertySetupScreen.prototype.okClick = function ()
{
    var callback = this.get('callback');
    if (callback)
    {
        callback(this.arrayProperties, this.propertiesChanged);
    }
    this.close();
};

PropertySetupScreen.prototype.closeClick = function ()
{
    if (this.propertiesChanged)
    {
        var self = this;
        TerceraMessageBox.Show(
            Resources.getResource('screen.properties.title'),
            Resources.getResource('screen.properties.unsavedChanges'),
            TerceraMessageBox.msgType.Question,
            function () { self.close(); });
    }
    else this.close();
};

// Close button (cross icon).
PropertySetupScreen.prototype.canCloseFromButton = function ()
{
    if (this.propertiesChanged)
    {
        this.closeClick();
        return false;
    }
    return true;
};

PropertySetupScreen.prototype.onConnectionStateChange = function ()
{
    let normal = Connection.isConnectionCorrect()
    let len = this.visibleControls.length;
    let tt = Resources.getResource("dynProperty.inactive.tooltip");
    for (let i = 0; i < len; i++)
    {
        let control = this.visibleControls[i]
        let mytt = "";
        if (control.tag && control.tag.tooltip)
            mytt = control.tag.tooltip;
        if (control && control.set)
            control.set({
                enabled: normal,
                tooltip: normal ? mytt : tt
            })
    }

    if (this.loggingAllowed)
    {
        this.set({ enabledBtn: normal })     //#111602
    }
}

PropertySetupScreen.prototype.dispose = function ()
{
    Connection.onConnectStateChange.UnSubscribe(this.onConnectionStateChange, this);
    TerceraWindowBase.prototype.dispose.apply(this);
};
PropertySetupScreen.editProperty = function (caller, headerKey, parentPanel)
{
    if (!caller || !caller.Properties)
        return;

    var propScreen = caller.propertiesScreen;
    if (propScreen)
    {
        propScreen.set('focused', true);
        return;
    }

    var scr = new PropertySetupScreen();

    MainWindowManager.MainWindow.addControl(scr);
    scr.PopulateProperties(caller.Properties(caller.skipSomeProperties));        //#86776 аргумент true передается для TradingCentral, указывая что нужно получить Properties только активной таблицы 
    scr.set({
        callback: caller.callBack.bind(caller),
        name: scr._guid,
        header: Resources.getResource(headerKey),
        focused: true,
    });

    scr.setOnClosedCallback(function ()
    {
        delete caller.propertiesScreen;
    });

    caller.propertiesScreen = scr;
    scr.setParentPanel(parentPanel || caller);
};

let DynPropertyGroupComparer = {

    Compare: function (obj1, obj2)
    {
        if (!obj1 || !obj2) return 0;

        //Сначала сортируем по группе
        var result =
            DynPropertyGroupComparer.GetGroupIndex(obj1.group) -
            DynPropertyGroupComparer.GetGroupIndex(obj2.group);

        if (result) return result;
        else result = String.CompareOrdinal(obj1.group, obj2.group);

        // по sep group
        if (result) return result;
        else result = String.CompareOrdinal(obj1.separatorGroup, obj2.separatorGroup);

        // по индексу
        if (result) return result;
        else result = obj1.sortIndex - obj2.sortIndex;

        //Потом по типу
        if (result) return result;
        else result =
            DynPropertyGroupComparer.GetPropertyIndex(obj1.type) -
            DynPropertyGroupComparer.GetPropertyIndex(obj2.type);

        return result;
    },

    // Индексы для определнных групп - чтобы сортировать
    GetGroupIndex: function (group)
    {
        switch (group)
        {
            case DynProperty.SCRIPT_PARAMETERS_GROUP:
                return -1;
            case DynProperty.PARAM_GROUP:
                return 0;

            case DynProperty.GENERAL_OPTIONS_GROUP:
                return 5;
            case DynProperty.DATA_STYLE_GROUP:
                return 6;
            case DynProperty.VIEW_GROUP:
                return 7;
            case DynProperty.SOUNDS_GROUP:
                return 10;

            case DynProperty.PORTFOLIO_RETURN_GROUP:
                return 14;
            case DynProperty.ASSET_RETURN_GROUP:
                return 15;
            case DynProperty.PERCENT_SCALE_GROUP:
                return 16;

            case DynProperty.HOTKEY_GROUP:
                return 20;
            case DynProperty.TIME_SCALE_GROUP:
                return 21;
            case DynProperty.PORTFOLIO_BALANCE_SCALE:
            case DynProperty.PRICE_SCALE_GROUP:
                return 22;
            case DynProperty.ALERTS_GROUP:
                return 23;
            case DynProperty.VISUAL_TRADING_GROUP:
                return 24;
            case DynProperty.CHART_OE_GROUP:
                return 25;
            case DynProperty.SEPARATOR_GROUP1:
                return 26;
            case DynProperty.DATA_BOX_GROUP:
                return 27;

            case DynProperty.TRADING_DEFAULTS_GROUP:
                return 30;
            case DynProperty.CONFIRMATIONS_GROUP:
                return 40;
            case DynProperty.WARNINGS_GROUP:
                return 50;

            case DynProperty.SEPARATOR_GROUP2:
                return 55;
            case DynProperty.NOTIFICATIONS_GROUP:
                return 56;

            case DynProperty.CHART_GROUP:
                return 60;
            case DynProperty.FXCELL_GROUP:
                return 70;
            case DynProperty.SCRIPTS_SUBGROUP:
                return 80;

            case DynProperty.SEPARATOR_GROUP3:
                return 85;

            case DynProperty.CONNECTION_GROUP:
                return 90;
            case DynProperty.PROXY_GROUP:
                return 100;


            case DynProperty.LAYOUT_GROUP:
                return 110;

            case DynProperty.TRADING_GROUP:
                return 140;

            case DynProperty.INSTRUMENT_GROUP:
                return 150;

            case DynProperty.EMULATOR_GROUP:
                return 170;

            case DynProperty.VISUAL_GROUP:
                return 180;

            case DynProperty.ORDER_GROUP:
                return 190;

            case DynProperty.INFORMER_GROUP:
                return 200;

            case DynProperty.PANELS_GROUP:
                return 210;

            case DynProperty.OPTION_CHAIN_GROUP:
                return 220;

            case DynProperty.ANALYZER_GROUP:
                return 230;

            case DynProperty.ANALYZER_INFO_WINDOW_GROUP:
                return 240;


            default:
                return 1000;
        }
    },

    // Индексы для определнных типов проперти - чтобы сортировать
    GetPropertyIndex: function (type)
    {
        switch (type)
        {
            case DynProperty.BOOLEAN:
                return 0;

            case DynProperty.FONT:
                return 2;

            case DynProperty.FILENAME:
            case DynProperty.FOLDERNAME:
                return 40;

            case DynProperty.COLOR:
                return 3;

            default:
                return 30;
        }
    }
};

//##############################################
let PropertySetupScreenForIndicator = PropertySetupScreen.extend({


});

PropertySetupScreenForIndicator.prototype.okClick = function ()
{
    var callback = this.get('callback');
    if (callback)
    {
        var res = new DialogResultsStuct()
        res.Data = this.arrayProperties;
        res.Button = DialogResultsStuct.CallBackInitiator.okClick;
        callback(res);
    }
    this.close(true);
};

PropertySetupScreenForIndicator.prototype.closeClick = function ()
{
    var callback = this.get('callback');
    if (callback)
    {
        var res = new DialogResultsStuct()
        res.Button = DialogResultsStuct.CallBackInitiator.cancelClick;
        callback(res);
    }
    this.close(true);
};

PropertySetupScreenForIndicator.prototype.close = function (fromBtn)
{
    HtmlScroll.removeScroll(this.find('.js-right-panel'));
    if (!fromBtn)
    {
        var callback = this.get('callback');
        if (callback)
        {
            var res = new DialogResultsStuct()
            res.Button = DialogResultsStuct.CallBackInitiator.closeClick;
            callback(res);
        }
    }
    TerceraWindowBase.prototype.close.apply(this, [fromBtn]);
}

PropertySetupScreenForIndicator.editProperty = function (caller, callBack, headerKey)
{
    if (!caller || !caller.Properties)
        return;

    var propScreen = caller.propertiesScreen;
    if (propScreen)
    {
        propScreen.set('focused', true);
        return;
    }

    var scr = new PropertySetupScreenForIndicator();

    MainWindowManager.MainWindow.addControl(scr);
    scr.PopulateProperties(caller.Properties());
    scr.set({
        callback: callBack.bind(caller),
        name: scr._guid,
        header: Resources.getResource(headerKey),
        focused: true,
    });

    scr.setOnClosedCallback(function ()
    {
        delete caller.propertiesScreen;
    });

    caller.propertiesScreen = scr;
    scr.setParentPanel(caller.chart.terceraChartPanelContext);
};

let scrHanlder = propertySetupScreenForIndicatorHandler;
scrHanlder.editProperty = PropertySetupScreenForIndicator.editProperty;