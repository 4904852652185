// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { MAMode } from "./IndicatorConstants.ts"
import { iAC } from "./indicators/built-in/iAC.js"
import { iAD } from "./indicators/built-in/iAD.js"
import { iADX, iADXEma, iADXEx } from "./indicators/built-in/iADX.js"
import { iAlligator } from "./indicators/built-in/iAlligator.ts"
import { iAO } from "./indicators/built-in/iAO.js"
import { iATR } from "./indicators/built-in/iATR.ts"
import { iBands, iBandsEx } from "./indicators/built-in/iBands.js"
import { iBearsPower } from "./indicators/built-in/iBearsPower.js"
import { iBullsPower } from "./indicators/built-in/iBullsPower.js"
import { iBWMFI } from "./indicators/built-in/iBWMFI.js"
import { iCCI, iCCIEx } from "./indicators/built-in/iCCI.js"
import { iDeMarker } from "./indicators/built-in/iDeMarker.js"
import { iEnvelopes } from "./indicators/built-in/iEnvelopes.js"
import { iForce } from "./indicators/built-in/iForce.js"
import { iFractals } from "./indicators/built-in/iFractals.js"
import { iGator } from "./indicators/built-in/iGator.js"
import { iIchimoku } from "./indicators/built-in/iIchimoku.js"
import { iMA } from "./indicators/built-in/iMA.ts"
import { iMACD, iMACDEx } from "./indicators/built-in/iMACD.ts"
import { iMAEx } from "./indicators/built-in/iMAEx.ts"
import { iMom, iMomEx } from "./indicators/built-in/iMom.js"
import { iOBV } from "./indicators/built-in/iOBV.ts"
import { iOsMA } from "./indicators/built-in/iOsMA.js"
import { iRVI } from "./indicators/built-in/iRVI.js"
import { iSAR } from "./indicators/built-in/iSAR.js"
import { iStdDevEx } from "./indicators/built-in/iStdDevEx.ts"
import { iStdDev } from "./indicators/built-in/iStdDev.ts"
import { iSTO } from "./indicators/built-in/iSTO.js"
import { iWPR } from "./indicators/built-in/iWPR.js"

export let iBuildInIndicatorFactory = function() { }

iBuildInIndicatorFactory.CreateIndicator = function (name, args)
{
    switch (name)
    {
        case 'iAC':
            return new iAC()

        case 'iAD':
            return new iAD()

        case 'iADX':
            {
                if (args.length === 1)
                    return new iADX(args[0], MAMode.SMA)

                let mode = args[1]
                return mode === 1
                    ? new iADXEma(args[0], PriceType.Close)
                    : new iADXEx(args[0], PriceType.Close, mode)
            }

        case 'iADXEx':
            {
                if (args.length === 2)
                    return new iADXEx(args[0], args[1], MAMode.SMA)

                let mode = args[2]
                return mode === 1
                    ? new iADXEma(args[0], args[1])
                    : new iADXEx(args[0], args[1], args[2])
            }

        case 'iAlligator':
            return new iAlligator(
                args[0], args[1],
                args[2], args[3],
                args[4], args[5],
                args[6], args[7])

        case 'iAO':
            return new iAO()

        case 'iATR':
            return new iATR(
                args[0],
                args.length === 1 ? MAMode.SMA : args[1])

        case 'iBands':
            return args.length === 2
                ? new iBands(args[0], MAMode.SMA, args[1], 0)
                : new iBands(args[0], args[1], args[2], args[3])

        case 'iBandsEx':
            return args.length === 4
                ? new iBandsEx(args[0], MAMode.SMA, args[1], args[2], args[3])
                : new iBandsEx(args[0], args[1], args[2], args[3], args[4])

        case 'iBearsPower':
            return new iBearsPower(args[0], args[1])

        case 'iBullsPower':
            return new iBullsPower(args[0], args[1])

        case 'iBWMFI':
            return new iBWMFI()

        // добавочный агумент int maMode
        case 'iCCI':
            return args.length === 1
                ? new iCCI(args[0], MAMode.SMA)
                : new iCCI(args[0], args[1])

        // Добавочный аргумент int maMode
        case 'iCCIEx':
            return args.length === 2
                ? new iCCIEx(args[0], MAMode.SMA, args[1])
                : new iCCIEx(args[0], args[2], args[1])

        case 'iDeMarker':
            return new iDeMarker(args[0])

        case 'iEnvelopes':
            return args.length === 4
                ? new iEnvelopes(args[0], args[1], 0, args[2], args[3])
                : new iEnvelopes(args[0], args[1], args[2], args[3], args[4])

        case 'iForce':
            return new iForce(args[0], args[1], args[2])

        case 'iFractals':
            return new iFractals()

        case 'iGator':
            return new iGator(
                args[0], args[1],
                args[2], args[3],
                args[4], args[5],
                args[6], args[7])

        case 'iIchimoku':
            return new iIchimoku(args[0], args[1], args[2])

        case 'iMA':
            return new iMA(args[0], args[1])

        case 'iMAEx':
            return new iMAEx(args[0], args[1], args[2], args[3])

        case 'iMACD':
            return new iMACD(args[0], args[1], args[2])

        case 'iMACDEx':
            return new iMACDEx(args[0], args[1], args[2], args[3])

        case 'iMFI':
            return new iBWMFI(args[0])

        case 'iMom':
            return new iMom(args[0])

        case 'iMomEx':
            return new iMomEx(args[0], args[1])

        case 'iOBV':
            return new iOBV(args[0])

        case 'iOsMA':
            return new iOsMA(args[0], args[1], args[2], args[3])

        // // Дополнительные три параметра - int maMode, int maPeriod, int rsiMethod
        // case 'iRSI':
        //     // Е. Бабаев: #63476 - в конструктор мы таки передаем method
        //     return args.length === 1
        //         ? new iRSI(args[0], iBuildInIndicator.MODE_RSI_SIMPLE)
        //         : new iRSI(args[0], args[1])

        // // дополнительынй параметр rsi_mode
        // case 'iRSIEx':
        //     // может лажать, порефакторил, проверь число параметров!
        //     return new iRSIEx(args[0], args[1], args[2])

        // дополнитеьный параметр int maMode
        case 'iRVI':
            return args.length === 1
                ? new iRVI(args[0], MAMode.SMA)
                : new iRVI(args[0], args[1])

        // дополнительынй параметр
        case 'iSAR':
            return args.length === 1
                ? new iSAR(args[0], 0.2)
                : new iSAR(args[0], args[1])

        case 'iStdDev':
            return args.length === 4
                ? new iStdDev(args[0], args[1], args[2], args[3])
                : new iStdDev(args[0], args[1], args[2], PriceType.Close)

        case 'iStdDevEx':
            return args.Length === 4
                ? new iStdDevEx(args[0], args[1], args[2], args[3])
                : new iStdDevEx(args[0], args[1], args[2], PriceType.Close)

        case 'iSTO':
            return args.length === 4
                ? new iSTO(args[0], args[1], args[2], args[3], 0)
                : new iSTO(args[0], args[1], args[2], args[3], args[4])

        case 'iWPR':
            return new iWPR(args[0])
    }
}