// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
export class Brokers {
    public BuyerId = 0;
    public SellerId = 0;
    public fieldSourceId: number;

    constructor (fieldSourceId: number) {
        this.fieldSourceId = fieldSourceId;
        // #68916 нет информации
        if (fieldSourceId === -1 || fieldSourceId === 0) {
            this.BuyerId = this.SellerId = fieldSourceId;
        } else {
            // старшие 2 байта - buyer id, младшие 2 байта - seller id
            this.BuyerId = fieldSourceId >> 16;
            this.SellerId = fieldSourceId & 0xffff;
        }
    }

    public GetBuyerSellerId (): number {
        return this.fieldSourceId;
    }
}
