// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { TerceraCheckBox } from "./TerceraCheckBox.js";

export let TerceraCheckBoxEvent = TerceraCheckBox.extend({
    data: function ()
    {
        return {
            checked: false,
            text: ''
        };
    },
    BeforeChangeEvent: null
});

TerceraCheckBoxEvent.prototype.getType = function () { return 'TerceraCheckBoxEvent' };

TerceraCheckBoxEvent.prototype.private_CheckBoxClick = function (sender)
{
    if (!this.get('enabled'))
        return;

    return new Promise(function (resolve, reject)
    {
        if (this.BeforeChangeEvent)
            this.BeforeChangeEvent(!this.get('checked'), resolve)
        else
            this.toggle('checked');

    }.bind(this))
        .then(function (ans)
        {
            this.set('checked', !!ans);
        }.bind(this))
};