// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts";
import { TerceraEventLogTemplate } from "../../templates.js";
import { PanelNames } from "../UtilsClasses/FactoryConstants.ts";
import { WINDOW_SIDE_BORDERS_WIDTH } from "../UtilsClasses/SizeConstants.ts";
import { Control } from "./Control.js";
import { ContainerControl } from "./ContainerControl.js";
import { PanelDisplayMode } from "../../Commons/CustomerAccess/PanelDisplayMode.ts";
import { CustomerAccess } from "../../Commons/CustomerAccess/CustomerAccess.ts";
import { Factory } from "../panels/Factory.js";
import { WorkSpaceManager } from "../WorkSpace/WorkSpaceManager.ts";


export let TerceraEventLog = ContainerControl.extend({
    template: TerceraEventLogTemplate,
    data: function ()
    {
        return {
            tooltip: Resources.getResource('panel.log')
        };
    },
    eventLogOpen: false,
    visible: true
});

TerceraEventLog.prototype.getType = function () { return 'TerceraEventLog' };

TerceraEventLog.prototype.oncomplete = function ()
{
    ContainerControl.prototype.oncomplete.apply(this);

    WorkSpaceManager.OnWorkSpaceChanged.Subscribe(this.eventLogReinit, this);

    this.on("eventLogClick", function ()
    {
        if (!this.myEventLog || this.myEventLog && this.myEventLog.torndown)
        {
            this.myEventLog = Factory.createPanel(PanelNames.EventsPanel);
            this.addControl(this.myEventLog);
            this.setEventPanelPos();
            Control.Ticker.Subscribe(this.myEventLog.TickAsync, this.myEventLog);
        }

        if (!this.eventLogOpen)
        {
            this.myEventLog.set({ visible: true });
            this.setFocus();
            this.eventLogOpen = true;
            this.checkSize();
        }
        else
        {
            this.eventLogOpen = false;
        }
    });

    this.observe('this.activeControl', function (newValue, oldValue, keypath)
    {
        var activeControl = this.get('activeControl');
        if (newValue != null)
            this.eventLogOpen = false
    });

    $(window).on('resize', this.checkSize.bind(this));

    this.completed = true;
};

TerceraEventLog.prototype.lostFocus = function ()
{
    this.myEventLog.set({ visible: false });
}

TerceraEventLog.prototype.eventLogReinit = function ()
{
    this.set({ visible: CustomerAccess.GetDisplayMode(PanelNames.EventsPanel) == PanelDisplayMode.Allowed })
}

TerceraEventLog.prototype.setEventPanelPos = function ()
{
    if (!this.panelOriginalWidth)
    {       // разовое запоминание оригинальных размеров Event Log Panel
        this.panelOriginalWidth = this.myEventLog.get("width");
        this.panelOriginalHeight = this.myEventLog.get("height");
    }
    let panel = this.find("*");
    let w1 = panel.offsetWidth;
    let h1 = panel.offsetHeight;
    let m = WINDOW_SIDE_BORDERS_WIDTH,
        w = panel.offsetLeft + panel.offsetWidth - this.myEventLog.get("width") + m / 2,
        h = panel.offsetTop - this.myEventLog.get("height") + m / 2;
    this.myEventLog.set({ left: w, top: h });
}

TerceraEventLog.prototype.setOriginalSize = function ()
{
    if (!this.myEventLog)
        return;
    this.myEventLog.set("width", this.panelOriginalWidth);
    this.myEventLog.set("height", this.panelOriginalHeight);
    this.setEventPanelPos();
}

TerceraEventLog.prototype.checkSize = function ()
{
    if (!this.myEventLog)
        return;

    this.setOriginalSize();
    let top = this.myEventLog.get("top");
    let left = this.myEventLog.get("left");
    let m = WINDOW_SIDE_BORDERS_WIDTH;
    let qt = this.myEventLog.quickTableRactive?.quickTable;

    let newWidth = this.myEventLog.get("width");
    let newHeight = this.myEventLog.get("height");

    if (left < 0){
        newWidth += left - m / 2;
        this.myEventLog.set("width", newWidth);
    }

    if (top < 0){
        newHeight += top - m / 2;
        this.myEventLog.set("height", newHeight);
    }

    if(qt){
        qt.width = newWidth;
        qt.height = newHeight;

        qt.onResize();
        this.myEventLog.quickTableRactive.setSizes();
    }

    this.setEventPanelPos();
}