// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { InstrumentSpecificType } from '../Utils/Instruments/InstrumentSpecificType';
import { InstrumentTypes } from '../Utils/Instruments/InstrumentTypes';
import { CustomEvent } from '../Utils/CustomEvents';
import { CurrentLang } from './properties/Resources';
import { InstrumentUtils } from '../Utils/Instruments/InstrumentUtils';
import { Instrument } from './cache/Instrument';
import { DataCache } from './DataCache';

export class TerceraSymbolLookupBaseDataProvider {
    public SearchHelper = tmp_class;

    public async getInstrumentsList (patern, exchangeIDs = null, instrumentTypes: InstrumentTypes[] | null = null, generateStrikes = false, searchInDescription = true, oldType = false): Promise<Instrument[]> {
        if (!instrumentTypes) {
            instrumentTypes = [
                InstrumentTypes.EQUITIES_CFD,
                InstrumentTypes.FUTURES,
                InstrumentTypes.FOREX,
                InstrumentTypes.INDICIES,
                InstrumentTypes.CRYPTO,
                InstrumentTypes.SPREADBET,
                InstrumentTypes.EQUITIES,
                InstrumentTypes.CFD_FUTURES,
                InstrumentTypes.BOND,
                InstrumentTypes.ETF,
                InstrumentTypes.TBILL,
                InstrumentTypes.SPOT,
                InstrumentTypes.OPTIONS,
                InstrumentTypes.FORWARD,
                InstrumentTypes.CORPORATE
            ];
        }

        if (instrumentTypes.includes(InstrumentTypes.EQUITIES_CFD) && !instrumentTypes.includes(InstrumentTypes.CFD_FUTURES)) {
            instrumentTypes.push(InstrumentTypes.CFD_FUTURES);
        }

        const aliasLanguage = CurrentLang;

        return await DataCache.GetInstrumentList(patern, exchangeIDs, instrumentTypes, generateStrikes, searchInDescription, aliasLanguage, oldType)
            .then(function (IInstrumentsList) {
                return IInstrumentsList;
            });
    }

    public async getInstrument (instrumentItem: tmp_class): Promise<Instrument> {
        if (instrumentItem.isHide) {
            return await Promise.resolve(null);
        }

        let newIns = DataCache.getInstrumentByName(instrumentItem.GetInteriorID());
        const me = this;
        if (newIns) {
            return await Promise.resolve(newIns);
        } else {
        // For ContiniousContract searching
            if (instrumentItem.Id && instrumentItem.InstrumentSpecificType === InstrumentSpecificType.ContiniousContract) {
            // try fixedList
                newIns = DataCache.getInstrumentByInstrumentIdAndRoute(instrumentItem.Id, instrumentItem.Route);

                if (!newIns) // ok non fixedlist
                {
                    return await me.getContiniousInstrumentById(instrumentItem.Id, instrumentItem.Route);
                }

                return newIns;
            } else {
                return await me.getInstrumentByInstrumentTradableID_NFL(instrumentItem.InstrumentTradableID, instrumentItem.Route, instrumentItem.GetInteriorID());
            }
        }
    }

    // TODO. Refactor.
    public async getInstrumentByName (instrumentName: string): Promise<Instrument> {
        return await this.getInstrument(new this.SearchHelper(instrumentName));
    }

    public async getInstrumentByNameNFL (instrumentName): Promise<Instrument> {
        return await DataCache.getInstrumentByNameNFL(instrumentName)
            .then(function (IInstrument) {
                return IInstrument;
            });
    }

    public async getContiniousInstrumentById (Id: number, Route: number): Promise<Instrument | null> {
        return await DataCache.getInstrumentById(Id)
            .then(function (found) {
                if (found) {
                    return DataCache.getInstrumentByInstrumentIdAndRoute(Id, Route);
                }

                return null;
            });
    }

    public async getInstrumentByInstrumentTradableID_NFL (InstrumentTradableID: number, Route: number, interiorID: string): Promise<Instrument | null> {
        return await DataCache.getInstrumentByInstrumentTradableID_NFL(InstrumentTradableID, Route, interiorID);
    }

    public async GetNonFixedInstrumentStrikes (instrument: Instrument): Promise<Instrument[]> {
        return await DataCache.GetNonFixedInstrumentStrikes(instrument)
            .then(function (IInstrumentsList) {
                return IInstrumentsList;
            });
    }

    public dispose (): void {

    }
}

class tmp_class {
    public FullName: string;
    public InstrumentTradableID: number | null;
    public Route: number | null;
    public Id: number | null;
    public InstrumentSpecificType: InstrumentSpecificType | null;
    public isHide: boolean;

    constructor (FullName: string | null) {
        if (!FullName) {
            return;
        }

        this.FullName = FullName;
        const splitedName = FullName.split(InstrumentUtils.SEPARATOR);
        if (splitedName.length !== 2) {
            return;
        }

        this.InstrumentTradableID = parseInt(splitedName[0].split(Instrument.CONTINIOUS_CONTRACT_POSTFIX)[0]);
        this.Route = parseInt(splitedName[1]);

        const continiousSplit = splitedName[1].split('|');
        if (continiousSplit.length > 1) {
            this.Id = parseInt(continiousSplit[1]);
            this.InstrumentSpecificType = InstrumentSpecificType.ContiniousContract;
            this.FullName = this.FullName.replace('|' + this.Id, '');
        }
    }

    public GetInteriorID (): string {
        return this.FullName;
    }
}
