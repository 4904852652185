// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

export enum TerceraButtonStyle {
    Standard = 'js-button-gray25',
    Toolbar = 'js-toolbarbutton-gray21',
    Blue = 'js-button-blue25',
    BlueClose = 'js-button-blueClose',
    Buy = 'js-button-buy25',
    Sell = 'js-button-sell25',

    BorderLess = 'js-borderless-imagesbutton',

    /// used in new Style
    Terminal = 'js-button-terminal',
    ColorPicker = 'js-colorpickerbutton'
};

export enum TerceraButtonEvents {
    onClick = 'btnClick',
    onHover = 'btnHover',
    onColorChanged = 'onColorChanged',
    onPairColorChanged = 'onPairColorChanged'
};
