// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { OEInstrumentLookupTemplate } from "../../../templates.js";
import { ContainerControl } from "../../elements/ContainerControl.js";
import { Resources } from "../../../Commons/properties/Resources.ts";

export let OEInstrumentLookup = ContainerControl.extend({
    template: OEInstrumentLookupTemplate,
    data: function ()
    {
        return {
            label: '',
            account: null,
            instrument: null
        }
    }
})

OEInstrumentLookup.prototype.getType = function () { return 'OEInstrumentLookup' };

OEInstrumentLookup.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.call(this)
    Resources.onLocaleChange.Subscribe(this.localize, this)
    this.localize()
}

OEInstrumentLookup.prototype.dispose = function ()
{
    Resources.onLocaleChange.UnSubscribe(this.localize, this)

    ContainerControl.prototype.dispose.call(this)
}

OEInstrumentLookup.prototype.setFocus = function ()
{
    const enabled = this.get('enabled');
    if (!enabled) {
        return;
    }
    ContainerControl.prototype.setFocus.call(this)
    if (this.Controls.TerceraInstrumentLookup)
        this.Controls.TerceraInstrumentLookup.setFocus()
}

OEInstrumentLookup.prototype.localize = function ()
{
    this.set('label', Resources.getResource('panel.newOrderEntry.instrumentLabel'))
}