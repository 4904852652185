// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';

export class AROON extends IndicatorScriptBase {
    public IndPeriod: number;

    constructor () {
        super();
        this.ProjectName = 'Aroon';
        this.Comments = 'Reveals the beginning of a new trend';
        super.SetIndicatorLine('Aroon_high', Color.Lime, 1, LineStyle.SimpleChart);
        super.SetIndicatorLine('Aroon_low', Color.Teal, 1, LineStyle.IsoDotChart);
        this.SetLevelLine('up', 70, Color.Red, 1, LineStyle.SimpleChart);
        this.SetLevelLine('down', 30, Color.Red, 1, LineStyle.IsoDotChart);
        this.SeparateWindow = true;

        this.IndPeriod = 8;
        super.InputParameter(new InputParameterInteger('IndPeriod', 'Period of averaging', 1, 1, 99999));
    }

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
    };

    public override GetIndicatorShortName (): string {
        return 'AROON(' + this.IndPeriod + ')';
    };

    public OnQuote (): void {
        super.OnQuote();
        if (this.CurrentData.Count < this.IndPeriod) { return; }

        let high = this.CurrentData.GetPrice(PriceType.High);
        let low = this.CurrentData.GetPrice(PriceType.Low);
        let perHigh = 0;
        let perLow = 0;
        for (let i = 0; i < this.IndPeriod; i++) {
            let price = this.CurrentData.GetPrice(PriceType.High, i);
            if (price > high) {
                high = price;
                perHigh = i;
            }
            price = this.CurrentData.GetPrice(PriceType.Low, i);
            if (price < low) {
                low = price;
                perLow = i;
            }
        }
        this.SetValue(0, 0, (1.0 - perHigh / this.IndPeriod) * 100);
        this.SetValue(1, 0, (1.0 - perLow / this.IndPeriod) * 100);
    };
}
