// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts";
import { AssetsItem } from "../cache/AssetsItem.ts";
import { TerceraAccountLookup, _AccountItemAll } from "../elements/Lookup/TerceraAccountLookup.ts";
import { QuickTableEditingInfo } from "../elements/QuickTable/QuickTableMisc.ts";
import { TerceraQuickTree, TerceraQuickTreeEvents } from "../elements/QuickTree/TerceraQuickTree.ts";
import { TerceraLabel } from "../elements/TerceraLabel.js";
import { TerceraMenu } from "../elements/TerceraMenu.ts";
import { ExportScreen } from "../screen/ExportScreen.js";
import { PanelNames } from "../UtilsClasses/FactoryConstants.ts";
import { ApplicationPanelNew } from "./ApplicationPanelNew.js";
import { OperationType } from "../../Utils/Trading/OperationType.ts";
import { ProductType } from "../../Utils/Instruments/ProductType.ts";
import { DynProperty } from "../../Commons/DynProperty.ts";
import { InstrumentUtils } from "../../Utils/Instruments/InstrumentUtils.ts";
import { GeneralSettings } from "../../Utils/GeneralSettings/GeneralSettings.ts";
import { Quantity } from "../../Utils/Trading/Quantity.ts";
import { DataCache } from "../../Commons/DataCache.ts";
import { SessionSettings } from "../../Commons/SessionSettings.ts";
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";
import { AssetsPanelTemplate } from "../../templates.js";
import { LinkedSystem } from "../misc/LinkedSystem.ts";

export let AssetsPanel = ApplicationPanelNew.extend(
    {
        Name: 'AssetsPanel',
        topPanelHeight: 25,
        data: function ()
        {
            return {
                isAccountLinkShow: true,
                isSymbolLinkShow: true,
                noDataLabelVisible: false
            };
        },

        partials: {
            bodyPartial: AssetsPanelTemplate
        },

        myAccount: null,
        accLookup: null,
        headerLocaleKey: 'panel.assets',
        ToolbarVisible: true,
        InitShowTotals: false,
        staticItems: null,
        noDataLabel: null
    });

AssetsPanel.prototype.getType = function () { return PanelNames.AssetsPanel };

AssetsPanel.prototype.localize = function ()
{
    ApplicationPanelNew.prototype.localize.apply(this);
};

AssetsPanel.prototype.oncomplete = function ()
{
    ApplicationPanelNew.prototype.oncomplete.call(this)

    let menuItems = this.createMenuItems()
    this.staticItems = menuItems;
    this.menuTagDict = TerceraMenu.createTagDictionary(menuItems)
    this.getQuickTable().setTableContextMenuItems(menuItems)

    this.selectionChange()
    this.UpdateShowTotalsStateChange(!!this.InitShowTotals);
    this.ShowToolbarStateUpdate(!!this.ToolbarVisible);
    if (this.menuTagDict)
    {
        if (this.menuTagDict["ShowTotals"])
            this.menuTagDict["ShowTotals"].checked = !!this.InitShowTotals
        if (this.menuTagDict["ToolbarVisible"])
            this.menuTagDict["ToolbarVisible"].checked = !!this.ToolbarVisible
    }

    this.layoutTable()
    this.localize();
}


AssetsPanel.prototype.getAssetsAccountsLookup = function ()
{
    return this.Controls.accountSelector.getLookup();
}


AssetsPanel.prototype.jbInit = function ()
{
    if (DataCache.getNumberOfAccounts() === 1)
        this.topPanelHeight = 0;
    ApplicationPanelNew.prototype.jbInit.apply(this);

    let noDataLabel = this.Controls.noDataLabel;
    this.noDataLabel = noDataLabel;

    let accountsLookup = this.getAssetsAccountsLookup();
    accountsLookup.onChangeValue.Subscribe(this.accountsLookupOnChangeValue, this);
    accountsLookup.IsMultiSelect = true;
    accountsLookup.isMultiSelectMode = true;
    this.accLookup = accountsLookup;
    if (this.delayAccounts)
    {
        var i;
        var delayAccountsArrayString = this.delayAccounts.split(',');
        var delayAccountsArrayAccount = [];
        var len = delayAccountsArrayString.length;
        for (i = 0; i < len; i++)
            if (DataCache.Accounts[delayAccountsArrayString[i]] || delayAccountsArrayString[i] === 'All')
                delayAccountsArrayAccount.push(DataCache.Accounts[delayAccountsArrayString[i]]);
        accountsLookup.dropDownFormCallBack(delayAccountsArrayAccount);
        delete this.delayAccounts;
    }
    else
    {
        let accToInitWith = accountsLookup.ItemAll;
        if (DataCache.EnableForceLinkingByAccount())
        {
            const accID = LinkedSystem.getAccount();
            accToInitWith = [DataCache.GetAccountById(accID)];
        }

        accountsLookup.dropDownFormCallBack(accToInitWith);
    }

    this.getQuickTable().OnSelectionChanged.Subscribe(this.selectionChange, this);

    this.getQuickTable().OnPaintedPictureButtonClick.Subscribe(this.onPaintedPictureButtonClick, this);
    this.getQuickTable().AfterEditItem.Subscribe(this.onAfterEditItem, this);

    this.quickTableRactive.on(TerceraQuickTreeEvents.DoubleClick, this.onDoubleClicked.bind(this))

    DataCache.OnUpdateAccount.Subscribe(this.UpdateAccount, this)
};

AssetsPanel.prototype.accountLink_In = function (accId, fromLinkedSystem = false)
{
    ApplicationPanelNew.prototype.accountLink_In.call(this, accId, fromLinkedSystem);
    if (DataCache.EnableForceLinkingByAccount())
    {
        let acc = DataCache.GetAccountById(accId);
        let accountsLookup = this.getAssetsAccountsLookup();
        accountsLookup?.dropDownFormCallBack([acc]);
        this.UpdateAccount(acc)
    }

}

AssetsPanel.prototype.UpdateAccount = function (account)
{
    this.FillRowsByAcc(account);
    let len = this.getQuickTable().rowsArray.length;
    if (this.noDataLabel)
        this.set('noDataLabelVisible', len ? false : true)
}

AssetsPanel.prototype.FillRowsByAcc = function (account)
{
    if (!account)
        return;

    let toAdd = new Array();
    let sharesNames = account.AssetSharesNames

    let qt = this.getQuickTable();
    let processingRows = new Array();
    for (let i = 0; i < qt.rowsArray.length; i++)
    {
        let row = qt.rowsArray[i];
        let item = row.item;
        if (!item)
            continue;
        let secondQuantor = !sharesNames || sharesNames && sharesNames.indexOf(item.Asset.Name) === -1;
        if (item.Account === account)
        {
            if (secondQuantor)
                qt.RemoveItem(item.ItemId);
            else
                processingRows.push(row);
        }
    }

    if (sharesNames)
        for (var i = 0; i < sharesNames.length; i++)
        {
            var name = sharesNames[i];
            var assetData = account.GetAssetDataInfoByName(name);
            if (assetData == null)
                continue;
            var asset = DataCache.GetAssetByName(name)
            if (asset == null)
                continue;
            let found = false;

            for (let i = 0; i < processingRows.length; i++)
            {
                let row = processingRows[i]
                let item = row.item;
                if (!item)
                    continue;

                if (item.Account === account && item.Asset === asset)
                {
                    item.UpdatInfoData();
                    item.fireUpdate();
                    found = true;
                    processingRows.splice(i, 1)
                    break;
                }
            }

            if (!found)
            {
                var row = this.getQuickTable().AddItem(new AssetsItem(account, asset, SessionSettings));
                this.ProcessEditingItem(row);
            }
        }
}

AssetsPanel.prototype.ProcessEditingItem = function (row)
{
    let lotValueCell = row.cells[AssetsItem.COL_SELL_EXCHANGE]
    if (!lotValueCell.QuickTableEditingInfo)
    {
        let info = new QuickTableEditingInfo(DynProperty.COMBOBOX);
        info.GetDataHandler = this.GetCBDataForRow;
        lotValueCell.QuickTableEditingInfo = info
        lotValueCell.ReadOnly = false
    }
}

AssetsPanel.prototype.GetCBDataForRow = function (row)
{
    const comboboxItems = [];
    if (!row.item.SellExchanges)
    {
        return comboboxItems;
    }
    for (let i = 0; i < row.item.SellExchanges.length; i++)
    {
        comboboxItems.push({ text: row.item.SellExchanges[i], value: row.item.SellExchanges[i] });
    }
    return comboboxItems;
}

AssetsPanel.prototype.onAfterEditItem = function (params)
{
    let row = params.row
    if (!row) return

    row.item.SetSellExchange(params.newValue)
}

AssetsPanel.prototype.onPaintedPictureButtonClick = function (data, event)
{
    if (!data || !data.row || !data.row.item)
        return;
    this.OpenOE(data.row.item, data.row.item.GetSellExchange());
};

AssetsPanel.prototype.OpenOE = function (item, preferExchange)
{
    if (!item || !item.Asset)
        return;
    let instrument = null;
    let asset = item.Asset

    let exchange = preferExchange;
    instrument = asset.Instrument;
    let insPromise = null;

    if (exchange && instrument && instrument.TradingExchange !== exchange || !instrument)
    {
        let exch = exchange || item.GetSellExchange()
        let InstrumentInterriorId = asset.GetPreferedInstrumentInterriorIdByExchange(exch);

        if (InstrumentInterriorId)
        {
            let sp = InstrumentInterriorId.split(InstrumentUtils.SEPARATOR)
            insPromise = DataCache.getInstrumentByInstrumentTradableID_NFL(sp[0], sp[1], InstrumentInterriorId);
        }
        else
            insPromise = (function () { return Promise.resolve(DataCache.FindInstrumentByTradingExchange(exch)) })()
    }
    else
        insPromise = (function () { return Promise.resolve(instrument) })()

    if (!insPromise)
        return;

    insPromise.then(function (instrument)
    {
        if (!instrument)
            return

        let acc = item.Account;
        let assetInfo = acc.GetAssetDataInfoByName(asset.Name);
        let lotSize = instrument.LotSize;

        // Open OE.
        let openOEMethod = function ()
        {
            MainWindowManager.Factory.addPanel(PanelNames.AdvancedOrderEntry, null, function (panel)
            {
                // panel.symbolLink_In(instrument.GetInteriorID());
                let inLots = GeneralSettings.View.displayAmountInLots();
                let convertedValue = Quantity.convertQuantityValue(new Quantity(assetInfo.GetAvailableQty() / lotSize, true), instrument, inLots);
                let quantity = new Quantity(convertedValue, inLots)
                panel.set({ instrument: instrument }).then(function ()
                {
                    panel.set({
                        side: OperationType.Sell,
                        account: acc,
                        defaultQuantity: quantity
                    })
                    if (instrument.isProductTypeVisible())
                        panel.set({ productType: ProductType.Delivery });

                    panel.setDisclosedQuantityParams("quantity");
                })
            });
        }
        // setFocus Hack
        setTimeout(openOEMethod, 0)
    });
}

AssetsPanel.prototype.onDoubleClicked = function ()
{
    let qt = this.getQuickTable();
    let selectedRowIds = qt.selectedRowIds;
    if (selectedRowIds && selectedRowIds.length)
    {
        let row = qt.rows[selectedRowIds[0]];
        if (row)
            this.OpenOE(row.item)
    }

}

AssetsPanel.prototype.accountsLookupOnChangeValue = function (accountsResult)
{
    if (!this.getQuickTable())
        return;
    this.getQuickTable().ClearRows();

    if (accountsResult._AccountItemAll)
    {
        let accounts = DataCache.getAccounts();
        let keys = Object.keys(accounts)
        for (let i = 0; i < keys.length; i++)
            this.FillRowsByAcc(accounts[keys[i]]);
    }
    else if (accountsResult.arraySelected)
        for (let i = 0; i < accountsResult.arraySelected.length; i++)
            this.FillRowsByAcc(accountsResult.arraySelected[i]);
    else
        this.FillRowsByAcc(accountsResult);

    let len = this.getQuickTable().rowsArray.length;
    if (this.noDataLabel)
        this.set('noDataLabelVisible', len ? false : true)
};

AssetsPanel.prototype.createMenuItems = function ()
{
    let items = [];

    items.push(
        {
            locKey: 'screen.export.contextMenu',
            text: Resources.getResource('screen.export.contextMenu'),
            event: ExportScreen.show.bind(null, this)
        }
    );

    if (!Resources.isHidden('panel.trades.print'))
        items.push(
            {
                locKey: 'screen.reports.print',
                text: Resources.getResource('screen.reports.print'),
                event: this.printTable.bind(this)
            }
        );

    let view =
    {
        locKey: 'panel.positions.menu.View',
        tag: 'View',
        enabled: true,
        subitems: []
    }

    if (DataCache.getNumberOfAccounts() !== 1)
        view.subitems.push({
            locKey: 'panel.Assets.Menu.ShowToolbar',
            tag: "ShowToolbar",
            checked: this.ToolbarVisible,
            visible: !Resources.isHidden('panel.Assets.Menu.ShowToolbar'),
            enabled: true,
            canCheck: true,
            event: this.ShowToolbarStateChange.bind(this)
        })

    view.subitems.push({
        locKey: 'panel.positions.menu.ShowTotals',
        tag: "ShowTotals",
        checked: false,
        enabled: true,
        canCheck: true,
        event: this.ShowTotalsStateChange.bind(this)
    })
    items.push(view);

    return items;
}

AssetsPanel.prototype.ShowToolbarStateChange = function (menuItem)
{
    this.ShowToolbarStateUpdate(menuItem.checked);
}

AssetsPanel.prototype.ShowToolbarStateUpdate = function (state)
{
    if (DataCache.getNumberOfAccounts() === 1)
        state = false;
    this.accLookup.set("visible", state);
    this.topPanelHeight = state ? 25 : 0;
    this.layoutTable();
}

AssetsPanel.prototype.localize = function ()
{
    ApplicationPanelNew.prototype.localize.call(this)

    let menuTagDict = this.menuTagDict
    if (!menuTagDict) return

    for (let key in menuTagDict)
    {
        let menuItem = menuTagDict[key]
        menuItem.text = Resources.getResource(menuItem.locKey)
    }

    this.set({
        noAssetsAvailableText: Resources.getResource('panel.Assets.NoAssetsAvailable'),
    });
}

AssetsPanel.prototype.SetColumnsDefaultDisplayIndex = function (table)
{
    table.columns[0].displayedIndex = 1;
    table.columns[1].displayedIndex = 2;
    table.columns[2].displayedIndex = 3;
    table.columns[3].displayedIndex = 4;
    table.columns[4].displayedIndex = 5;
    table.columns[5].displayedIndex = 6;
    table.columns[6].displayedIndex = 7;
    table.columns[7].displayedIndex = 8;
    table.columns[8].displayedIndex = 9;
    table.columns[9].displayedIndex = 10;
    table.columns[10].displayedIndex = 11;
    table.columns[11].displayedIndex = 15;
    table.columns[14].displayedIndex = 14;
}

AssetsPanel.prototype.selectionChange = function ()
{
    let qt = this.getQuickTable();
    var selectedRowIds = qt.selectedRowIds;
    if (!selectedRowIds)
        return;

    let items = [];

    if (!selectedRowIds.length)
        items.push(
            {
                locKey: 'panel.Assets.Menu.SellAsset',
                tooltip: Resources.getResource('IsAllowedResponceReason.NoAssetsSelected'),
                text: Resources.getResource("panel.Assets.Menu.SellAsset"),
                enabled: false,
                style: "js-context-menu-error",
                event: function () { }
            }
        );
    else if (selectedRowIds.length > 1)
        items.push(
            {
                locKey: 'panel.Assets.Menu.SellAsset',
                tooltip: Resources.getResource('IsAllowedResponceReason.MoreThanOneAssetIsSelected'),
                text: Resources.getResource("panel.Assets.Menu.SellAsset"),
                enabled: false,
                style: "js-context-menu-error",
                event: function () { }
            }
        );
    else
    {
        let event = function () { };
        let row = qt.rows[selectedRowIds[0]];
        if (row)
        {
            let exchArr = this.GetCBDataForRow(row);
            for (let i = 0; i < exchArr.length; i++)
                items.push(
                    {
                        text: Resources.getResource("panel.Assets.Menu.SellAssetOn") + " " + exchArr[i].text,
                        event: this.OpenOE.bind(this, row.item, exchArr[i].text)
                    })
        }

    }

    let menuItems = items.concat(this.staticItems);
    this.menuTagDict = TerceraMenu.createTagDictionary(menuItems)
    qt.setTableContextMenuItems(menuItems)

    // panel.Assets.Menu.SellAsset=Sell asset
    // panel.Assets.Menu.SellAssetOn=Sell asset on
    //IsAllowedResponceReason.NoAssetsSelected =No assets selected
    // IsAllowedResponceReason.MoreThanOneAssetIsSelected=More than one asset is selected
};

AssetsPanel.prototype.Properties = function ()
{
    var properties = ApplicationPanelNew.prototype.Properties.call(this);
    var acc = this.accLookup.get('selectedItem')

    if (!acc)
        return properties;
    let accNumber = ""
    if (acc._AccountItemAll || acc._AccountItemSelected)
        accNumber = acc.GetDataForSave()
    else
        accNumber = acc.AcctNumber

    properties.push(new DynProperty("account", accNumber, DynProperty.STRING, DynProperty.HIDDEN_GROUP));

    properties.push(new DynProperty("ShowTotals", this.getQuickTable().ShowTotals, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));
    properties.push(new DynProperty("ToolbarVisible", this.ToolbarVisible, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));

    return properties;
};

AssetsPanel.prototype.callBack = function (newProperties)
{
    ApplicationPanelNew.prototype.callBack.call(this, newProperties);
    var dp = DynProperty.getPropertyByName(newProperties, 'account');
    if (dp && dp.value)
        this.delayAccounts = dp.value;

    this.InitShowTotals = DynProperty.getPropValue(newProperties, "ShowTotals");
    this.ToolbarVisible = DynProperty.getPropValue(newProperties, "ToolbarVisible");
};

AssetsPanel.prototype.repopulate = function ()
{
    ApplicationPanelNew.prototype.repopulate.apply(this);
    if (this.accLookup)
        this.accountsLookupOnChangeValue(this.accLookup.get('selectedItem'));
};

AssetsPanel.prototype.dispose = function ()
{
    DataCache.OnUpdateAccount.UnSubscribe(this.OnUpdateAccount, this)

    ApplicationPanelNew.prototype.dispose.apply(this);
};