// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { HistoryType } from "../../../Utils/History/HistoryType.ts";
import { TerceraInstrumentLookup, _InstrumentItemSelected } from "../../elements/Lookup/TerceraInstrumentLookup.ts";
import { TerceraPanel } from "../../../Controls/elements/TerceraPanel.js";
import { ApplicationPanelNew } from "../../panels/ApplicationPanelNew.js";
import { TSPanel } from "../../panels/TSPanel.js";
import { PanelNames } from "../../../Controls/UtilsClasses/FactoryConstants.ts";
import { MultiTimeSalesItem } from "./MultiTimeSalesItem.ts";
import { ReloadHistoryParams } from "../../../Utils/History/ReloadHistoryParams.ts";
import { Periods, TFInfo } from "../../../Utils/History/TFInfo.ts";
import { DirectQuote3Message } from "../../../Utils/DirectMessages/DirectMessagesImport.ts";
import { DynProperty } from "../../../Commons/DynProperty.ts";
import { GeneralSettings } from "../../../Utils/GeneralSettings/GeneralSettings.ts";
import { Quantity } from "../../../Utils/Trading/Quantity.ts";
import { DataCache } from "../../../Commons/DataCache.ts";
import { SessionSettings } from "../../../Commons/SessionSettings.ts";
import { CashItem } from "../../../Commons/cache/History/CashItem.ts";

export let MultiTimeSalesPanel = TSPanel.extend(
    {
        Name: 'MultiTimeSalesPanel',
        topPanelHeight: 25,
        data: function ()
        {
            return {
                isSymbolLinkShow: false
            }
        },
        headerLocaleKey: 'panel.timeSales',
        delayQuotes: new Array(),
        loading: false,
        loadingPrommise: null,
        lastQuote: null
    })

MultiTimeSalesPanel.MAX_ROW_COUNT = 100

MultiTimeSalesPanel.prototype.getType = function ()
{
    return PanelNames.MultiTimeSalesPanel
}


MultiTimeSalesPanel.prototype.createActionControls = function ()
{
    this.lastQuote = null;
    this.loading = false;
    this.loadingPrommise = null;
    let rightError = 6 + 10

    let topPanel = new TerceraPanel()
    topPanel.set({ name: 'topPanel' })
    this.addControl(topPanel)
    topPanel.setBounds(5, 0, this.get('width') - rightError, 30)

    let insLookup = new TerceraInstrumentLookup()
    insLookup.oninit();
    insLookup.isMultiSelect = true;
    // insLookup.isMultiSelectMode = true;
    insLookup.isShowSelectAll = true;
    topPanel.addControl(insLookup)
    insLookup.set({ top: 5, left: 5 })

    this.changeInstrument(GeneralSettings.TradingDefaults.Symbol)
    insLookup.onChangeValue.Subscribe(this.changeInstrument, this)

    this.mySymbolLookup = insLookup
    this.mySymbolLookup.exchangeFilterSet = { 76: true };
    if (this.deferredInstrumentLinkIn)
    {
        insLookup.on('complete', function ()
        {
            this.mySymbolLookup.setSelectValue(this.deferredInstrumentLinkIn);
            delete self.deferredInstrumentLinkIn;
        }.bind(this))
    }
}
MultiTimeSalesPanel.prototype.changeInstrument = function (instrument)
{
    if (!this.quickTableRactive)
        return

    this.unsubscribeInstrument(this.myIns)
    this.myIns = instrument

    let qt = this.getQuickTable()
    if (qt) qt.ClearRows()

    this.updateHiddenColumns()

    this.subscribeInstrument(instrument)
}

MultiTimeSalesPanel.prototype.updateHiddenColumns = function (instrument)
{

}

MultiTimeSalesPanel.prototype.subscribeInstrument = function (instrument)
{
    if (!instrument)
        return;
    let array = new Array();
    if (instrument.arraySelected)
        array = instrument.arraySelected;
    else
        array = [instrument];

    if (this.loadingPrommise)
        this.loadingPrommise.cancel();

    this.loadingPrommise = this.LoadHistoryStart(array).then(function (quotes)
    {
        for (let i = 0; i < quotes.length; i++)
            this.processQuote(quotes[i]);
        this.loading = false;
        for (let i = 0; i < this.delayQuotes.length; i++)
            this.processQuote(this.delayQuotes[i]);
        this.delayQuotes.length = 0;
        this.loadingPrommise = null;
    }.bind(this));

    for (let i = 0; i < array.length; i++)
    {
        let ins = array[i];
        ins.DataCache.FQuoteCache.addListener(ins, this, HistoryType.QUOTE_TRADES);
    }
}

MultiTimeSalesPanel.prototype.unsubscribeInstrument = function (instrument)
{
    if (!instrument)
        return;
    let array = new Array();
    if (instrument.arraySelected)
        array = instrument.arraySelected;
    else
        array = [instrument];

    for (let i = 0; i < array.length; i++)
    {
        let ins = array[i];
        ins.DataCache.FQuoteCache.removeListener(ins, this, HistoryType.QUOTE_TRADES);
    }
}

MultiTimeSalesPanel.prototype.newQuote = function (tradeMessage)
{
    if (this.loading)
        this.delayQuotes.push(tradeMessage)
    else
        this.processQuote(tradeMessage);
}

MultiTimeSalesPanel.prototype.processQuote = function (tradeMessage)
{
    if (tradeMessage.Type !== HistoryType.QUOTE_TRADES)
        return
    let qt = this.getQuickTable();
    let needUpdate = false;

    tradeMessage = tradeMessage.Clone();

    if (this.lastQuote)
        needUpdate = this.TryMergeQutes(tradeMessage, this.lastQuote)

    let ins = DataCache.getInstrumentByTradable_ID(tradeMessage.InstrumentTradableID, tradeMessage.FRoute);

    let size = tradeMessage.Size
    let inLots = GeneralSettings.View.displayAmountInLots()
    if (inLots)
        size = Quantity.convertQuantityValue(new Quantity(size, false), ins, inLots)

    let item = null
    if (needUpdate)
        item = qt.rowsArray[0].item;
    else
        item = new MultiTimeSalesItem(SessionSettings)
    if (!needUpdate)
        item.ItemId = this.rowIdCounter++

    item.instrument = ins
    item.size = size
    item.time = tradeMessage.cTime;
    item.price = tradeMessage.Price

    let row = qt.AddItem(item)

    // TODO. Removing the oldest item.
    if (qt.rowsArray.length > MultiTimeSalesPanel.MAX_ROW_COUNT)
        qt.RemoveItem(this.rowIdCounter - 1 - MultiTimeSalesPanel.MAX_ROW_COUNT)

    this.lastQuote = tradeMessage;
    // TODO. Ugly.
    qt.needRedrawBackground = true
    qt.needRedraw = true
}

MultiTimeSalesPanel.prototype.LoadHistoryStart = function (instrumentsArray)
{
    this.loading = true;
    this.delayQuotes.length = 0;
    let Accs = DataCache.Accounts

    //TODO берём первый
    let account = Accs[Object.keys(Accs)[0]];

    let length = instrumentsArray.length;

    let AllInstrumentsPromises = new Array();
    for (let i = 0; i < length; i++)
    {
        let instrument = instrumentsArray[0];
        let historyParams = this.CreateReloadHistoryParams(instrument, account)

        AllInstrumentsPromises.push(CashItem.CreateWithHistory(instrument, historyParams, null));
    }
    return Promise.all(AllInstrumentsPromises)
        .then(function (data)
        {
            let quotesStorage = new Array();
            for (let ci_i = 0; ci_i < data.length; ci_i++)
            {
                let ci = data[ci_i];
                let history = ci.FNonEmptyCashArray;
                for (let i = 0; i < history.length; i++)
                {
                    let bi = history[i];

                    let price = bi.Data[1];
                    let size = bi.Volume;
                    let quote = new DirectQuote3Message();

                    quote.cTime = bi.LeftTime;
                    quote.Price = price;
                    quote.Size = size;
                    quote.InstrumentTradableID = ci.Instrument.InstrumentTradableID;
                    quote.Route = ci.Instrument.Route;

                    quotesStorage.push(quote);
                }
            }
            return quotesStorage;
        }.bind(this))
}

MultiTimeSalesPanel.prototype.TryMergeQutes = function (prevQ, newQ)
{
    if (prevQ.FRoute === newQ.FRoute &&
        prevQ.InstrumentTradableID === newQ.InstrumentTradableID &&
        prevQ.Price === newQ.Price &&
        prevQ.cTime.getTime() === newQ.cTime.getTime())
    {
        prevQ.Size += newQ.Size;
        return true;
    }
    else
        return false;
}

MultiTimeSalesPanel.prototype.CreateReloadHistoryParams = function (instrument, account)
{
    let historyParams = new ReloadHistoryParams();
    let TimeFrameInfo = new TFInfo();
    TimeFrameInfo.HistoryType = HistoryType.QUOTE_TRADES;
    TimeFrameInfo.Periods = Periods.TIC;

    let to = new Date();
    let from = new Date(to.getFullYear(), (to.getMonth()), to.getDate(), 0, 0, 0, 0);
    
    historyParams.updateWithProps({
        account,
        instrument,
        TimeFrameInfo,
        FromTime: from,
        ToTime: to,
        count: 100,
        UseDefaultInstrumentHistoryType: false
    });

    return historyParams;
}


//#region ICaller

MultiTimeSalesPanel.prototype.callBack = function (properties)
{
    ApplicationPanelNew.prototype.callBack.call(this, properties)

    let dp = DynProperty.getPropertyByName(properties, 'symbol')
    if (dp && dp.value)
    {
        let strArr = dp.value.split(";");
        let prArr = new Array();
        for (let i = 0; i < strArr.length; i++)
            prArr.push(DataCache.getInstrumentByNameNFL(strArr[i]));

        Promise.all(prArr).then(function (instruments)
        {
            let newInstrumentsArray = [];
            for (let i = 0; i < instruments.length; i++)
                if (instruments[i].ExchangeId === 76)
                    newInstrumentsArray.push(instruments[i]);
            if (this.mySymbolLookup)
                this.mySymbolLookup.setSelectValue(new _InstrumentItemSelected(newInstrumentsArray))
            else
                this.deferredInstrumentLinkIn = new _InstrumentItemSelected(newInstrumentsArray)
        }.bind(this));
    }
}

MultiTimeSalesPanel.prototype.Properties = function ()
{
    let properties = ApplicationPanelNew.prototype.Properties.call(this)

    let ins = this.get('instrument');
    let insArr;
    if (ins.arraySelected)
        insArr = ins.arraySelected;
    else
        insArr = [ins];

    let insStr = "";

    for (let i = 0; i < insArr.length; i++)
        insStr += insArr[i].GetInteriorID() + ";";

    if (insStr.length)
        insStr = insStr.substring(0, insStr.length - 1);

    properties.push(new DynProperty('symbol', insStr, DynProperty.STRING, DynProperty.HIDDEN_GROUP));
    return properties
}

//#endregion
