// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
// TODO. Refactor. Duplicate of PositionSLTPEdit.
import { SlTpPriceType } from '../../../Utils/Enums/Constants';
import { GeneralSettings } from '../../../Utils/GeneralSettings/GeneralSettings';
import { OrderUtils } from '../../../Utils/Trading/OrderUtils';
import { SLTPEdit } from './SLTPEdit';

export class OrderSLTPEdit extends SLTPEdit {
    constructor (tradingData, forceOffset: any = undefined) {
        super(tradingData.dataCache, forceOffset);

        this.updateOffsetTypes(tradingData);
    }

    // TODO. Refactor. Ugly.
    public override updateOffsetTypes (tradingData: any = null): void {
        super.updateOffsetTypes();

        if (!tradingData) return;

        const order = tradingData.order;
        if (!order) return;

        const slVal = order.StopLossPriceValue;
        const tpVal = order.TakeProfitPriceValue;

        const isSLTrailingStop = order.StopLossPriceType === SlTpPriceType.TrOffset;
        const hasSL = !isNaN(slVal) && !isSLTrailingStop;
        const hasTP = !isNaN(tpVal);

        if (!hasSL && !hasTP) {
            return;
        }

        const offsetViewMode = GeneralSettings.TradingDefaults.ShowOffsetIn;

        let resultOffsetType = null;
        if (hasSL) {
            resultOffsetType =
            order.StopLossPriceType === SlTpPriceType.Absolute
                ? null
                : offsetViewMode;
        } else if (hasTP) {
            resultOffsetType =
            order.TakeProfitPriceType === SlTpPriceType.Absolute
                ? null
                : offsetViewMode;
        }

        this.sl.offsetType = this.tp.offsetType = resultOffsetType;
    }

    // TODO. Refactor. Ugly.
    public override update (updateData, tradingData): boolean {
        let parameterChanged = super.update(updateData, tradingData);

        const newTradingDataDict = updateData.newTradingDataDict;
        if (!newTradingDataDict) {
            return parameterChanged;
        }

        // Init order sl/tp values.
        const order = newTradingDataDict.order;
        if (!order) {
            return parameterChanged;
        }

        const tpVal = order.TakeProfitPriceValue;
        if (!isNaN(tpVal)) {
            this.tp.enabled = true;
            this.tp.value = this.tp.offsetType === null
                ? tpVal
                : OrderUtils.ConvertTickOffset(
                    order.Instrument,
                    this.tp.offsetType,
                    0,
                    tpVal);

            parameterChanged = true || parameterChanged;
        }

        const slVal = order.StopLossPriceValue;
        if (isNaN(slVal)) {
            return parameterChanged;
        }

        // TODO. Ugly.
        this.sl.enabled = true;

        if (order.StopLossPriceType === SlTpPriceType.TrOffset) {
            this.trailingStop.enabled = true;
            this.trailingStop.value = OrderUtils.ConvertTickOffset(
                order.Instrument,
                this.trailingStop.offsetType,
                0,
                slVal);
        } else {
            const realPrice = this.sl.offsetType === null;
            const ticks = OrderUtils.ConvertTickOffset(
                order.Instrument,
                this.sl.offsetType,
                0,
                slVal);

            this.sl.value = realPrice ? slVal : ticks;

            // инициализация для синхронизации пересчётов
            if (!this.trailingStop.enabled && this.trailingStop.value === null && !realPrice) {
                this.trailingStop.value = ticks;
            }

            if (order.StopLossLimitPriceValue != null && order.StopLossPriceType !== SlTpPriceType.TrOffset) {
                const sllVal = order.StopLossLimitPriceValue;

                this.sl.sllValue = this.sl.offsetType === null
                    ? sllVal
                    : OrderUtils.ConvertTickOffset(order.Instrument, this.sl.offsetType, 0, sllVal) * Math.sign(sllVal);
            }

            this.sllValueEnabled = this.sl.sllValue != null;
        }

        parameterChanged = true || parameterChanged;

        return parameterChanged;
    }

    // TODO. Refactor. Ugly.
    public override setDefaultSLTP (tradingData): boolean {
        const order = tradingData.order;
        if (!order) {
            return super.setDefaultSLTP(tradingData);
        }

        const slVal = order.StopLossPriceValue;
        const tpVal = order.TakeProfitPriceValue;

        const isTrailingStop = order.StopLossPriceType === SlTpPriceType.TrOffset;
        const hasSL = !isNaN(slVal) && !isTrailingStop;
        const hasTP = !isNaN(tpVal);

        return super.setDefaultSLTP(
            tradingData,
            !hasSL,
            !hasTP,
            !isTrailingStop);
    }
}
