// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectSessionSwitchMessage } from '../../../../Utils/DirectMessages/DirectSessionSwitchMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class SessionSwitchMessageProcessor extends MessageProcessorBase {
    public override decode (fs: PFixFieldSet): DirectSessionSwitchMessage[] {
        const msg = new DirectSessionSwitchMessage();

        msg.SessionId = fs.GetValue(FieldsFactory.FIELD_TRADE_SESSION_ID);
        msg.TradeSessionStatusId = fs.GetValue(FieldsFactory.FIELD_TRADE_SESSION_STATUS_ID);
        msg.ExchangeSessionName = fs.GetValue(FieldsFactory.FIELD_EXCHANGE_SESSION_NAME);

        return [msg];
    }
}
