// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { MAMode } from '../IndicatorConstants';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';
import { InputParameterCombobox } from '../InputParamaterClasses/InputParameterCombobox';
import { type iBuildInIndicator } from '../iBuildInIndicator';

export class Didi extends IndicatorScriptBase {
    public ExpirationDate: number;
    public Password: string;
    public Fast: number;
    public Slow: number;
    public Media: number;
    public MAType: number;

    private short_handle: iBuildInIndicator;
    private average_handle: iBuildInIndicator;
    private long_handle: iBuildInIndicator;

    constructor () {
        super();
        // #region Initialization
        this.Copyrights = '(c) TraderEvolution';
        this.DateOfCreation = '02.05.2020';
        this.ExpirationDate = 0;
        this.Version = '';
        this.Password = '66b4a6416f59370e942d353f08a9ae36';
        this.ProjectName = 'Didi';
        // #endregion

        super.SetIndicatorLine('Fast Line', Color.Lime, 2, LineStyle.SimpleChart);
        super.SetIndicatorLine('Slow Line', Color.Yellow, 2, LineStyle.SimpleChart);
        this.SetLevelLine('Media Line', 1, Color.Red, 1, LineStyle.DotChart);
        this.SeparateWindow = true;

        this.Fast = 3;
        super.InputParameter(new InputParameterInteger('Fast', 'Fast line', 0, 1, 99999));

        this.Slow = 20;
        super.InputParameter(new InputParameterInteger('Slow', 'Slow line', 1, 1, 99999));

        this.Media = 8;
        super.InputParameter(new InputParameterInteger('Media', 'Media line', 2, 1, 99999));

        this.MAType = MAMode.SMA;
        super.InputParameter(new InputParameterCombobox('MAType', 'Type of Moving Average for Signal', 3,
            [
                { SMA: MAMode.SMA },
                { EMA: MAMode.EMA },
                { SMMA: MAMode.SMMA },
                { LWMA: MAMode.LWMA }
            ]));
    }

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
    }

    public override GetIndicatorShortName (): string {
        return 'Didi(fast: (' + this.Fast + '), slow: (' + this.Slow + ')';
    }

    public override createInternalIndicators (): iBuildInIndicator[] {
        this.long_handle = this.Indicators.iMA(this.CurrentData, this.Fast, this.MAType, 0, PriceType.Close);
        this.average_handle = this.Indicators.iMA(this.CurrentData, this.Slow, this.MAType, 0, PriceType.Close);
        this.short_handle = this.Indicators.iMA(this.CurrentData, this.Media, this.MAType, 0, PriceType.Close);
        return [this.long_handle, this.average_handle, this.short_handle];
    }

    public OnQuote (): void {
        super.OnQuote();
        if (this.CurrentData.Count <= this.Media) {
            this.SetValue(0, 1);
        } else {
            this.SetValue(0, this.short_handle.GetValue(1) / this.average_handle.GetValue(1));
            this.SetValue(1, this.CurrentData.Count >= this.Slow ? this.long_handle.GetValue(1) / this.average_handle.GetValue(1) : 1);
        }
    }
}
