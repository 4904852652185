// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DirectQuoteMessage } from './DirectQuoteMessage';
import { HistoryType } from '../../Utils/History/HistoryType';
import { DirectQuote3Message } from './DirectQuote3Message';
import { Message } from './Message';
import { DirectQuote1Message } from './DirectQuote1Message';

export class DirectInstrumentDayBarMessage extends DirectQuoteMessage {
    public Type = HistoryType.QUOTE_INSTRUMENT_DAY_BAR;

    public Open: number | null = null;
    public High: number | null = null;
    public Low: number | null = null;
    // ахтунг! на сервере сказали,
    // что этот филд используется только в старой схеме,
    // когда приходит только один InstrumentDayBarMessage
    public TodayClosePrice: number | null = null;
    public PreviousClosePrice: number | null = null;
    public Ticks: number | null = null;
    public Volume: number | null = null;
    public OpenInterest: number | null = null;
    public SettlementPrice: number | null = null;
    public PrevSettlementPrice: number | null = null;
    public LastPrice: number | null = null;
    public LastSize: number | null = null;
    public LastVenue: number | null = null;
    public LastTime: Date | null = null;

    public AskOpen: number | null = null;
    public AskHigh: number | null = null;
    public AskLow: number | null = null;
    public AskTodayClosePrice: number | null = null;
    public AskPreviousClosePrice: number | null = null;

    public FiftyTwoWeekHighPrice: number | null = null;
    public FiftyTwoWeekLowPrice: number | null = null;

    public High13Week: any = null;
    public Low13Week: any = null;
    public High26Week: any = null;
    public Low26Week: any = null;
    public Delay: any = null;
    // Иногда значение Change,
    // которое приходит с вендора не совпадает с расчетным на клиенте,
    // в этом случае здаем сhange в это поле
    public Change: any = null;
    // см. Change
    public ChangeInPercent: any = null;
    // #46480, #46585 - для какого инструмента пришел мессадж
    public InstrumentBarType: number | null = null;
    // #71250
    public ReferencePrice: number | null = null;

    public InstrumentTradableID: number | null = null;

    public TradableId: any = undefined;
    public QuoteRouteId: any = undefined;
    public Time: any = undefined; // TODO можна видалити цей рядок
    public TargetInstrumentName: any = undefined;

    constructor () {
        super(Message.CODE_INSTRUMENT_DAY_BAR);
    }

    public GenerateQuoteMessage (historyType): any {
        if (this.InstrumentBarType && this.InstrumentBarType != historyType) {
            return null;
        }

        if (historyType == DirectQuoteMessage.QUOTE_TRADES) {
            return this.GenerateQuote3Message();
        } else {
            return this.GenerateQuote1Message(historyType);
        }
    }

    public GenerateQuote1Message (historyType): DirectQuote1Message | null {
        if (this.TodayClosePrice === null || !this.LastTime) // нельзя сгенерировать
        { return null; }

        const q1m = new DirectQuote1Message();
        this.CopyQuoteMsg(q1m);
        q1m.Code = Message.CODE_QUOTE;
        q1m.Type = historyType;
        q1m.Ask = this.TodayClosePrice;
        q1m.Bid = this.TodayClosePrice;
        q1m.TotalVolume = this.Volume;
        q1m.Time = this.LastTime;

        return q1m;
    }

    public GenerateQuote3Message (): DirectQuote3Message | null {
        if (!this.LastPrice || !this.Volume || !this.LastTime) // нельзя сгенерировать
        { return null; }

        const q3m = new DirectQuote3Message();
        this.CopyQuoteMsg(q3m);
        q3m.Code = Message.CODE_QUOTE3;
        q3m.Type = DirectQuoteMessage.QUOTE_TRADES;
        q3m.Price = this.LastPrice;
        q3m.Size = this.Volume;
        q3m.Time = this.LastTime;
        // hsa: остальные филды мне в BaseInterval не нужны

        return q3m;
    }
}
