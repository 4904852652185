// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { TerceraWarningMessageTemplate } from "../../templates.js";
import { Control } from "./Control.js";

export let TerceraWarningMessage = Control.extend({

    template: TerceraWarningMessageTemplate,
    data: function ()
    {
        return {
            warningType: 'warning',
            message: 'Warning!!!'
        };
	}
});

TerceraWarningMessage.prototype.getType = function () { return 'TerceraWarningMessage' };