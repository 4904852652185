// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { RouteIDGroup } from '../Groups/GroupsImport';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';

export class NewsRequestMessage extends PFixMessage {
    constructor () {
        super(Message.CODE_NEWS_REQUEST_MESSAGE);
    }

    public setRouteId (routeID: number): void {
        const group = new RouteIDGroup();
        group.setRouteId(routeID);

        this.addGroup(group);
    }

    public setNumber (number: number): void {
        this.setFieldValue(FieldsFactory.FIELD_NUMBER, number);
    }

    public setStartTime (startTime: Date): void {
        this.setFieldValue(FieldsFactory.FIELD_START_TIME, startTime);
    }

    public setEndTime (endTime: Date): void {
        this.setFieldValue(FieldsFactory.FIELD_END_DAY_DATE, endTime);
    }

    public setSymbol (value: string): void {
        this.setFieldValue(FieldsFactory.FIELD_SYMBOL, value);
    }

    public setSource (value: string): void {
        this.setFieldValue(FieldsFactory.FIELD_SOURCE, value);
    }

    public setNewsCategory (value: string): void {
        this.setFieldValue(FieldsFactory.FIELD_NEWS_CATEGORY, value);
    }

    public setPriority (value: number): void {
        this.setFieldValue(FieldsFactory.FIELD_PRIORITY, value);
    }

    public setTheme (value: string): void {
        this.setFieldValue(FieldsFactory.FIELD_THEME, value);
    }

    public setIsUpperCaseSearch (value: boolean): void {
        this.setFieldValue(FieldsFactory.FIELD_IS_UPPRCASE_SEARCH, value);
    }

    public setIsContentSearch (value: boolean): void {
        this.setFieldValue(FieldsFactory.FIELD_IS_CONTENT_SEARCH, value);
    }

    public setIsEventSearch (value: boolean): void {
        this.setFieldValue(FieldsFactory.FIELD_IS_EVENT_SEARCH, value);
    }
}
