import { ApplicationPanel } from '../ApplicationPanel';
import { PanelNames } from '../../UtilsClasses/FactoryConstants';
import { OptionMasterPanelTemplate } from '../../../templates';
import { OptionTrader } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionTrader';
import { type OptionChainPanel } from './OptionChainPanel';
import { type Instrument } from '../../../Commons/cache/Instrument';
import { type Account } from '../../../Commons/cache/Account';
import { Level1ItemType } from '../../elements/TerceraLevel1Panel';
import { type OptionPositionsPanel } from './OptionPositionsPanel';
import { type OptionOrdersPanel } from './OptionOrdersPanel';
import { type OptionPaperPositionsPanel } from './OptionPaperPositionsPanel';
import { DynProperty } from '../../../Commons/DynProperty';
import { OptionCalculatorModel } from '../../../Commons/cache/OptionMaster/OptionCalculator/OptionCalculatorModel';
import { Resources } from '../../../Commons/properties/Resources';
import { IVCalculationPrice } from '../../../Commons/cache/OptionMaster/OptionTrader/IVCalculationPrice';
import { PanelSettingsScreen } from '../../screen/PanelSettingsScreen';
import { type OptionAnalyzerPanel } from './OptionAnalyzerPanel';
import { AccountMenuItemsHelper } from '../../../Commons/AccountWidget/AccountMenuItemsHelper';

export class OptionMasterPanel extends ApplicationPanel {
    private _isInitialized: boolean = false;
    private _optionChainPanel: OptionChainPanel;
    private _optionAnalyzerPanel: OptionAnalyzerPanel;
    private _optionPositionsPanel: OptionPositionsPanel;
    private _optionOrdersPanel: OptionOrdersPanel;
    private _optionPaperPositionsPanel: OptionPaperPositionsPanel;
    private _optionTrader: OptionTrader;
    private _delayedProperties: DynProperty[];

    constructor () {
        super();
        this.Name = 'OptionMasterPanel';
        this.headerLocaleKey = 'panel.optionMaster';
    }

    getType (): PanelNames { return PanelNames.OptionMasterPanel; }

    oninit (): void {
        super.oninit();
        this._optionTrader = new OptionTrader();
        super.observe('instrument', this.onInstrumentChanged);
        super.observe('account', this.onAccountChanged);
        super.on('onInfoButtonClick', this.onInfoButtonClicked);
        super.on('onSettingsButtonClick', this.onSettingsButtonClicked);
        super.on('onSplitterResize', this.onSplitterResized);
        super.on('onActivePanelChange', this.onActivePanelChanged);
        super.on('onCollapsedChange', this.onCollapsedChanged);
    }

    onteardown (): void {
        this._optionTrader.unsubscribeOnShowPortfolioChanged(this.onShowPortfolioChanged);
        this._optionTrader.dispose();
        super.onteardown();
    }

    oncomplete (): void {
        super.oncomplete();
        this.initializeVariables();
        if (isValidArray(this._delayedProperties)) {
            this.callBack(this._delayedProperties);
            this._delayedProperties = undefined;
        }
        this.layoutTable();
        this.themeChange();
        this.localize();
        this.repopulate();
    }

    localize (): void {
        super.localize();
        if (!this._isInitialized) {
            return;
        }
        this._optionChainPanel.localize();
        this._optionAnalyzerPanel.localize();
        this._optionPositionsPanel.localize();
        this._optionOrdersPanel.localize();
        this._optionPaperPositionsPanel.localize();
    }

    themeChange (): void {
        // super.themeChange();
        if (!this._isInitialized) {
            return;
        }
        this._optionChainPanel.themeChange();
        this._optionAnalyzerPanel.themeChange();
        this._optionPositionsPanel.themeChange();
        this._optionOrdersPanel.themeChange();
        this._optionPaperPositionsPanel.themeChange();
    }

    layoutTable (): void {
        if (!this._isInitialized) {
            return;
        }
        this._optionChainPanel.layoutTable();
        this._optionAnalyzerPanel.layoutTable();
        this._optionPositionsPanel.layoutTable();
        this._optionOrdersPanel.layoutTable();
        this._optionPaperPositionsPanel.layoutTable();
    }

    public override setSize (w: number, h: number): void {
        super.setSize(w, h);

        this.layoutTable();
    }

    TickAsync (): void {
        if (!this._isInitialized) {
            return;
        }
        this._optionChainPanel.TickAsync();
        this._optionAnalyzerPanel.TickAsync();
        this._optionPositionsPanel.TickAsync();
        this._optionOrdersPanel.TickAsync();
        this._optionPaperPositionsPanel.TickAsync();
    }

    populate (): void {
        this.repopulate();
    }

    repopulate (): void {
        if (!this._isInitialized) {
            return;
        }
        this._optionTrader.account = super.get('account');
        this._optionTrader.fakeOption = super.get('instrument');
        this._optionChainPanel.repopulate();
        this._optionAnalyzerPanel.repopulate();
        this._optionPositionsPanel.repopulate();
        this._optionOrdersPanel.repopulate();
        this._optionPaperPositionsPanel.repopulate();
        super.set('underlier', this._optionTrader.fakeOption?.ForwardBaseInstrument);
    }

    updateSettings (): void {
        super.updateSettings();
        if (!this._isInitialized) {
            return;
        }
        this._optionChainPanel.updateSettings();
        this._optionAnalyzerPanel.updateSettings();
    }

    public Properties (): DynProperty[] {
        const properties: DynProperty[] = super.Properties();

        let index: number = 0;
        const separatorGroup = '#0#' + Resources.getResource('property.Calculation');
        let dp = new DynProperty('property.PricingModel', this._optionTrader.pricingModel, DynProperty.COMBOBOX, DynProperty.GENERAL_GROUP);
        dp.sortIndex = index++;
        dp.separatorGroup = separatorGroup;
        dp.objectVariants = [
            { text: Resources.getResource('property.PricingModel.BlackScholes'), value: OptionCalculatorModel.BlackSholes }
        ];
        dp.COMBOBOX_TYPE = DynProperty.INTEGER;
        properties.push(dp);

        dp = new DynProperty('property.InterestRate', this._optionTrader.interestRate * 100, DynProperty.DOUBLE, DynProperty.GENERAL_GROUP);
        dp.sortIndex = index++;
        dp.minimal = 0;
        dp.increment = 0.01;
        dp.decimalPlaces = 2;
        dp.separatorGroup = separatorGroup;
        properties.push(dp);

        dp = new DynProperty('property.IVCalculationPrice', this._optionTrader.ivCalculationPrice, DynProperty.COMBOBOX, DynProperty.GENERAL_GROUP);
        dp.sortIndex = index++;
        dp.separatorGroup = separatorGroup;
        dp.objectVariants = [
            { text: Resources.getResource('property.Ask'), value: IVCalculationPrice.Ask },
            { text: Resources.getResource('property.Bid'), value: IVCalculationPrice.Bid },
            { text: Resources.getResource('property.AskBid/2'), value: IVCalculationPrice.BidAsk },
            { text: Resources.getResource('property.Last'), value: IVCalculationPrice.Last }
        ];
        dp.COMBOBOX_TYPE = DynProperty.INTEGER;
        properties.push(dp);

        dp = new DynProperty('property.ShowToolbar', super.get('isShowToolbar'), DynProperty.BOOLEAN, DynProperty.GENERAL_GROUP);
        dp.sortIndex = index++;
        dp.separatorGroup = separatorGroup;
        properties.push(dp);

        properties.push(...this._optionChainPanel.Properties());
        properties.push(...this._optionAnalyzerPanel.Properties());

        return properties;
    }

    public callBack (properties: DynProperty[]): void {
        super.callBack(properties);
        if (!this._isInitialized) {
            this._delayedProperties = properties;
            return;
        }
        const group = DynProperty.GENERAL_GROUP;
        let dp = DynProperty.getPropertyByGroupAndName(properties, group, 'property.PricingModel');
        if (!isNullOrUndefined(dp?.value)) {
            this._optionTrader.pricingModel = dp.value;
        }
        dp = DynProperty.getPropertyByGroupAndName(properties, group, 'property.InterestRate');
        if (!isNullOrUndefined(dp?.value)) {
            this._optionTrader.interestRate = dp.value / 100;
        }
        dp = DynProperty.getPropertyByGroupAndName(properties, group, 'property.IVCalculationPrice');
        if (!isNullOrUndefined(dp?.value)) {
            this._optionTrader.ivCalculationPrice = dp.value;
        }
        dp = DynProperty.getPropertyByGroupAndName(properties, group, 'property.ShowToolbar');
        if (!isNullOrUndefined(dp?.value)) {
            super.set('isShowToolbar', dp.value);
        }

        this._optionChainPanel.callBack(properties);
        this._optionAnalyzerPanel.callBack(properties);
    }

    private onInstrumentChanged (instrument: Instrument): void {
        if (!this._isInitialized) {
            return;
        }
        this._optionTrader.fakeOption = instrument;
        super.set('underlier', instrument?.ForwardBaseInstrument);
    }

    private onAccountChanged (account: Account): void {
        if (!this._isInitialized) {
            return;
        }
        this._optionTrader.account = account;
    }

    private onInfoButtonClicked (): void {
        this._optionTrader.isShowPortfolio = !this._optionTrader.isShowPortfolio;
    }

    private onSettingsButtonClicked (): void {
        PanelSettingsScreen.EditProperties(this, null, Resources.getResource('panel.optionMaster.settingsHeader'));
    }

    private onShowPortfolioChanged (isShowPorfolio: boolean): void {
        super.set('isInfoButtonChecked', isShowPorfolio);
    }

    private onSplitterResized (): void {
        this.layoutTable();
    }

    private onActivePanelChanged (): void {
        this.layoutTable();
    }

    private async onCollapsedChanged (context: any, isCollapsed: boolean): Promise<void> {
        void super.set('isCollapsedBottom', isCollapsed);
    }

    // #region Heper methods
    private initializeVariables (): void {
        this._optionChainPanel = super.findAllComponents('optionChainPanel')[0] as OptionChainPanel;
        this._optionAnalyzerPanel = super.findAllComponents('optionAnalyzerPanel')[0] as OptionAnalyzerPanel;
        this._optionPositionsPanel = super.findAllComponents('optionPositionsPanel')[0] as OptionPositionsPanel;
        this._optionOrdersPanel = super.findAllComponents('optionOrdersPanel')[0] as OptionOrdersPanel;
        this._optionPaperPositionsPanel = super.findAllComponents('optionPaperPositionsPanel')[0] as OptionPaperPositionsPanel;
        this._optionChainPanel.setOptionTrader(this._optionTrader);
        this._optionAnalyzerPanel.setOptionTrader(this._optionTrader);
        this._optionPositionsPanel.setOptionTrader(this._optionTrader);
        this._optionOrdersPanel.setOptionTrader(this._optionTrader);
        this._optionPaperPositionsPanel.setOptionTrader(this._optionTrader);
        this._isInitialized = true;
    }
    // #endregion
}

ApplicationPanel.extendWith(OptionMasterPanel,
    {
        data: function () {
            return {
                showFullscreenModeButton: true,
                isShowToolbar: true,
                isInfoButtonChecked: false,
                instrument: null,
                account: null,
                underlier: null,
                level1Items: [
                    Level1ItemType.Last,
                    Level1ItemType.Ask,
                    Level1ItemType.Bid,
                    Level1ItemType.Change,
                    Level1ItemType.Volume,
                    Level1ItemType.Open,
                    Level1ItemType.High,
                    Level1ItemType.Low,
                    Level1ItemType.PrevClose
                ],
                isCollapsedBottom: false
            };
        },
        partials: {
            bodyPartial: OptionMasterPanelTemplate
        }
    }
);
