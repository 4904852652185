import { type Rectangle } from '../../Commons/Geometry';
import { type Account } from '../../Commons/cache/Account';
import { AccountsComboBox } from './AccountsComboBox';

export class QuickTableAccountsComboBox extends AccountsComboBox {
    private readonly _isProcessedCallback: boolean = false;
    tag: any;
    callBack: (value: Account | undefined, tag: any) => void;
    public override getType (): string { return 'QuickTableAccountsComboBox'; }

    constructor (data, callback: (value: Account | undefined, tag: any) => void, rect: Rectangle) {
        super();

        this.tag = data;
        this.callBack = callback;
        this.setBounds(rect.X, rect.Y, rect.Width, rect.Height);
        void this.set('isPosAbsolute', true);
    }

    protected _mouseDown (event): void {
        void this.set({ listLeft: 0, listTop: 25, showItems: true });
    }

    public async oncomplete (): Promise<void> {
        await this.set('selectedItem', this.tag.row.item.GetCurrentAccount());
        super.oncomplete();
    }

    public lostFocus (): void {
        super.lostFocus();
        const selectedItem = this.get('selectedItem');
        if (isNullOrUndefined(selectedItem) || selectedItem.toString() === this.tag.cell.value) {
            this.callBack(null, this.tag);
        } else {
            this.callBack(selectedItem, this.tag);
        }
    }
}
