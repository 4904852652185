// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { MainWindowManager } from "../../UtilsClasses/MainWindowManager.ts";
import { TerceraLookup } from "./TerceraLookup.ts"
import { InsDefItem, InsDefSettings } from "../../../Commons/cache/InstrumentDefaults.ts";
import { InstrumentsDefaultsLookupTemplate } from "../../../templates.js";
import { Resources } from "../../../Commons/properties/Resources.ts";
import { ContainerControl } from "../ContainerControl.js";
import { TerceraInstrumentLookupDropDownForm } from "./TerceraInstrumentLookupDropDownForm.js";
import { LookupDropDownShowParams  } from "../../UtilsClasses/LookupDropDownShowParams.js";
import { contextMenuHandler } from "../../../Utils/AppHandlers.js";
import { InstrumentLookupManager } from "./InstrumentLookupManager.js";
import { InstrumentTypes } from "../../../Utils/Instruments/InstrumentTypes.ts";
import { GeneralSettings } from "../../../Utils/GeneralSettings/GeneralSettings.ts";
import { Instrument } from "../../../Commons/cache/Instrument.ts";
import { DataCache } from "../../../Commons/DataCache.ts";
import { TerceraSymbolLookupBaseDataProvider } from "../../../Commons/NoNFixedListCore.ts";
export let InstrumentsDefaultsLookup = ContainerControl.extend({
    data: function ()
    {
        return {
            allSettings: null,
            itemSettings: null,
            searchText: ''
        };
    },
    viewedInstruments: null,

    instrumentToSelect: null,
    instrumentTypeToSelect: null,

    existingInstrumentTypes: {},
    lastTimeoutHandlerId: null,

    template: InstrumentsDefaultsLookupTemplate
});

InstrumentsDefaultsLookup.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.apply(this);

    this.dataProvider = new TerceraSymbolLookupBaseDataProvider();

    this.timeoutCallBack = this.timeoutCallBack.bind(this);
    this.instrumentsDropDownCallback = this.instrumentsDropDownCallback.bind(this);
    this.removeSelectedInstrument = this.removeSelectedInstrument.bind(this);

    var existingInstrumentTypes = {};
    this.existingInstrumentTypes = existingInstrumentTypes;
    var ExistingInstrumentTypesSet = DataCache.ExistingInstrumentTypesSet;
    for (var existingInstrumentType in ExistingInstrumentTypesSet)
        existingInstrumentTypes[existingInstrumentType] = true;

    this.set('itemSettings', InstrumentsDefaultsLookup.createInsDefItemNullStub());

    this.observe('searchText', this.onSearchTextChanged);
    this.observe('allSettings', this.onAllSettingsChanged);

    this.on('instrumentSelection', this.onInstrumentSelection);
    this.on('openInstrumentLookup', this.openInstrumentLookup);
    this.on('quickTreeAfterMouseDown', this.quickTreeAfterMouseDown);
};

InstrumentsDefaultsLookup.prototype.oncomplete = function ()
{
    ContainerControl.prototype.oncomplete.apply(this);

    let qtr = this.Controls.quickTreeRactive;
    if (qtr && qtr.quickTree)
    {
        qtr.quickTree.rowHeigth = 35;
        qtr.quickTree.reInitScrollHeigth();
    }
};

InstrumentsDefaultsLookup.prototype.removeSelectedInstrument = function (menuItem)
{
    var nodeToDelete = menuItem.tag;
    if (!nodeToDelete) return;

    var instrument = nodeToDelete.tag;

    delete this.viewedInstruments[instrument.GetInteriorID()];
    this.instrumentTypeToSelect = null;
    if (this.instrumentToSelect === instrument)
        this.instrumentToSelect = null;

    this.get('allSettings').RemoveInstrumentSettings(instrument);
    this.set('itemSettings', InstrumentsDefaultsLookup.createInsDefItemNullStub());

    this.Repopulate();
};

InstrumentsDefaultsLookup.prototype.quickTreeAfterMouseDown = function (event)
{
    var e = event.original;
    if (e.button !== 2) return;

    var qt = this.Controls.quickTreeRactive.quickTree;

    var selectedNode = qt.selectedNode;
    if (!selectedNode) return;

    var instrument = selectedNode.tag;
    if (!(instrument instanceof Instrument))
        return;

    contextMenuHandler.Show([{
        text: Resources.getResource('panel.menu.Remove'),
        event: this.removeSelectedInstrument,
        tag: selectedNode
    }],
        e.clientX, e.clientY);
};

InstrumentsDefaultsLookup.prototype.onInstrumentSelection = function ()
{
    var selectedNode = this.Controls.quickTreeRactive.quickTree.selectedNode;
    if (!selectedNode) return;

    this.instrumentToSelect = null;
    this.instrumentTypeToSelect = null;

    this.set('itemSettings', InstrumentsDefaultsLookup.createInsDefItemNullStub());

    var tag = selectedNode.tag;
    if (!tag)
        return;
    if (tag instanceof Instrument)
    {
        this.instrumentToSelect = tag;

        this.UpdateNumeric(tag.InstrType);

        this.set('itemSettings',
            this.get('allSettings').GetInstrumentSettings(tag) ||
            InstrumentsDefaultsLookup.createInsDefItemNullStub());
    }
    else
    {
        var instrTypeString = InsDefSettings.INSTRUMENT_TYPE;
        if (!tag.indexOf || tag.indexOf(instrTypeString) !== 0)
            return;

        var type = tag.slice(-(tag.length - instrTypeString.length));
        this.instrumentTypeToSelect = type;

        this.UpdateNumeric(parseInt(type));

        this.set('itemSettings',
            this.get('allSettings').GetTypeSettings(type) ||
            InstrumentsDefaultsLookup.createInsDefItemNullStub());
    }
};
InstrumentsDefaultsLookup.prototype.UpdateNumeric = function (type)
{
    let step = 1;
    let minVal = 1;
    let decPrec = 0;
    if (type === InstrumentTypes.FOREX && GeneralSettings.TradingDefaults.IsTicksFractionalForForex())
    {
        step = 0.1;
        minVal = 0.1;
        decPrec = 1;
    }
    if (MainWindowManager.TypesManagerScreen)
        MainWindowManager.TypesManagerScreen.set({
            sltpStep: step,
            sltpMinValue: minVal,
            sltpDecimalPrecision: decPrec
        });
}

InstrumentsDefaultsLookup.prototype.onAllSettingsChanged = function (newSettings)
{
    if (!newSettings)
        return;

    this.viewedInstruments = {};

    this.instrumentToSelect = null;
    this.instrumentTypeToSelect = null;

    this.set('searchText', '');
    this.AddInstrumentsPrommise(newSettings.InstrumentsNames);
};

InstrumentsDefaultsLookup.prototype.onSearchTextChanged = function (searchText)
{
    clearTimeout(this.lastTimeoutHandlerId);
    setTimeout(this.timeoutCallBack, 300);
};

InstrumentsDefaultsLookup.prototype.timeoutCallBack = function ()
{
    this.Repopulate();
};

InstrumentsDefaultsLookup.prototype.openInstrumentLookup = function ()
{
    var obj = {};
    (TerceraLookup.prototype.setItems.bind(obj))(DataCache.Instruments);

    var params = new LookupDropDownShowParams();
    params.items = obj.items;
    params.callBack = this.instrumentsDropDownCallback;
    params.isMultiSelect = true;
    // params.isMultiSelectMode = true;
    params.isCentered = true;
    params.autoClose = false;
    params.parentPanel = this;
    params.dataProvider = this.dataProvider;
    TerceraInstrumentLookupDropDownForm.ShowForm(params);
};

InstrumentsDefaultsLookup.prototype.AddInstrumentsPrommise = function (InstrumentsNames)
{
    let instrumentsNamesResult = [];
    var len = InstrumentsNames.length;
    for (var i = 0; i < len; i++)
    {
        let instrumentItem = new this.dataProvider.SearchHelper(InstrumentsNames[i]);
        instrumentsNamesResult.push(DataCache.getInstrumentByInstrumentTradableID_NFL(instrumentItem.InstrumentTradableID, instrumentItem.Route, instrumentItem.GetInteriorID()));
    }

    Promise.all(instrumentsNamesResult).then(function (instruments)
    {
        //TODO ебала конченая 
        //for (let i = 0; i < instruments.length; i++)
        //    this.AddInstruments([instruments[i].GetInteriorID()]);
        this.AddInstruments(instruments);
    }.bind(this));
}

InstrumentsDefaultsLookup.prototype.instrumentsDropDownCallback = function (instruments)
{
    let instrumentsNamesResult = [];
    var len = instruments.length;
    for (var i = 0; i < len; i++)
    {
        let instrumentItem = instruments[i];
        instrumentsNamesResult.push(DataCache.getInstrumentByInstrumentTradableID_NFL(instrumentItem.InstrumentTradableID, instrumentItem.Route, instrumentItem.GetInteriorID()));
    }

    Promise.all(instrumentsNamesResult).then(function (instruments)
    {
        //TODO ебала конченая 
        //for (let i = 0; i < instruments.length; i++)
        //    this.AddInstruments([instruments[i].GetInteriorID()]);
        this.AddInstruments(instruments);
    }.bind(this));
};

// TODO. Non fixed list.
InstrumentsDefaultsLookup.prototype.AddInstruments = function (instruments)
{
    var firstInstr = true;
    var len = instruments.length;
    for (var i = 0; i < len; i++)
    {
        var instrument = instruments[i];
        var instrument = this.TryToAddInstrument(instrument);
        if (instrument && firstInstr)
        {
            this.instrumentToSelect = instrument;
            firstInstr = false;
        }
    }

    if (this.instrumentToSelect)
        this.instrumentTypeToSelect = null;

    this.Repopulate();
};

InstrumentsDefaultsLookup.prototype.AddInstrument = function (instrument)
{
    this.instrumentToSelect = this.TryToAddInstrument(instrument);
    this.instrumentTypeToSelect = null;
    this.Repopulate();
}

InstrumentsDefaultsLookup.prototype.TryToAddInstrument = function (instrument)
{
    //var instrument = DataCache.getInstrumentByName(name);

    if (!instrument)
        return instrument;

    var viewedInstruments = this.viewedInstruments;
    let Id = instrument.GetInteriorID();
    if (instrument && !viewedInstruments.hasOwnProperty(Id))
    {
        viewedInstruments[Id] = instrument;
        this.get('allSettings').CreateInstrumentSettingsIfNotExist(instrument);
    }

    return instrument;
};

InstrumentsDefaultsLookup.prototype.Repopulate = function ()
{
    var instruments = this.GetSortedViewedInstruments();
    if (!instruments) return;

    var qtr = this.Controls.quickTreeRactive;

    InstrumentLookupManager.fillTree({
        items: instruments,
        selectedItem: this.instrumentToSelect,
        selectedInstrumentType: this.instrumentTypeToSelect,
        curTypesFilter: null, curExchangeFilter: null,
        currentNameFilter: this.get('searchText') || null,
        isInstrumentsDefaultsMode: true,
        existingInstrumentTypes: this.existingInstrumentTypes,
        quickTree: qtr.quickTree
    })

    qtr.setSizes();

    // TEST.
    qtr.quickTree.Draw(true);
};

InstrumentsDefaultsLookup.prototype.GetSortedViewedInstruments = function ()
{
    var viewedInstruments = this.viewedInstruments;
    if (!viewedInstruments) return null;

    var instruments = [];
    for (var key in viewedInstruments)
        instruments.push(viewedInstruments[key]);

    //instruments.sort();
    return instruments;
};

InstrumentsDefaultsLookup.prototype.themeChange = function ()
{
    var addBtn = this.Controls.add;
    if (addBtn)
        addBtn.set('terceraButtonStyle', "js-addBtn-Instruments");

    var qt = this.Controls.quickTreeRactive;
    if (qt) qt.themeChange();
};

// HACK. Ractive doesn't like keypaths like ".itemSettings.SLDefaultOffsetTicksDecimal"
// when itemSettings is null and two-way binding is used.
InstrumentsDefaultsLookup.createInsDefItemNullStub = function ()
{
    var insDefItem = new InsDefItem();
    insDefItem.readOnly = true;
    return insDefItem;
};