// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { ErrorInformationStorage } from "../../Commons/ErrorInformationStorage.ts"
import { Resources } from "../../Commons/properties/Resources.ts"
import { TimeSpanPeriods } from "../../Utils/Time/TimeSpan.ts"
import { DateTimeUtils } from "../../Utils/Time/DateTimeUtils.ts"
import { HistoryType } from "../../Utils/History/HistoryType.ts"
import { InstrumentHolidaysScreen } from "./InstrumentHolidaysScreen.js"
import { OrderTypeBase } from "../../Commons/cache/OrderParams/order-type/OrderTypeBase.ts"
import { OrderTypeBaseParameters } from "../../Commons/cache/OrderParams/order-type/OrderTypeBaseParameters.ts"
import { CommissionItem } from "../../Commons/cache/Commissions/CommissionItem.ts"
import { KeyCode, KeyEventProcessor } from "../../Commons/KeyEventProcessor.ts"
import { MathUtils } from "../../Utils/MathUtils.ts"
import { ControlsUtils } from "../UtilsClasses/ControlsUtils.ts"
import { VerticalApplicationPanelNew } from "../cache/VerticalPanel/VerticalApplicationPanelNew.ts"
import { VerticalPanelDataProvider } from "../cache/VerticalPanel/VerticalPanelDataProvider.ts"
import { VerticalPanelDataProviderItem } from "../cache/VerticalPanel/VerticalPanelDataProviderItem.ts"
import { QuickTableUtils } from "../elements/QuickTable/QuickTableUtils.ts"
import { PanelNames } from "../UtilsClasses/FactoryConstants.ts"
import { ApplicationPanelNew } from "./ApplicationPanelNew.js";
import { ProductType } from "../../Utils/Instruments/ProductType.ts"
import { MarginTypes } from "../../Utils/Instruments/MarginTypes.ts"
import { TradingMode } from "../../Utils/Instruments/TradingMode.ts"
import { PriceLimitMeasure } from "../../Utils/Instruments/PriceLimitMeasure.ts"
import { CommissionOperationType } from "../../Utils/Commission/CommissionEnums.ts"
import { SwapType } from "../../Utils/Instruments/SwapType.ts"
import { QuotingType } from "../../Utils/Instruments/QuotingType.ts"
import { SessionOperations } from "../../Utils/Enums/Constants.ts"
import { InstrumentTypes } from "../../Utils/Instruments/InstrumentTypes.ts"
import { KEY_CustodialFee, KEY_FILLPERLOT, KEY_ORDERPERLOT, KEY_ORDER_VOLUME, KEY_OptionExercise, KEY_PERFILL, KEY_PERORDERVOLUME, KEY_PERPHONETRANSACTION, KEY_PERTRANSACTION, KEY_PERVOLUME, KEY_ShortCommissionInfo, KEY_ShortPositionInterest, KEY_VAT, KEY_VOLUME, KEY_VOLUME_WITH_MIN_PD } from "../../Utils/Trading/FeeTextConstants.ts"
import { commisionSplitter, commisionSplitterArr } from "../../Utils/Commission/CommisionSplitter.ts"
import { CommissionPlan } from "../../Commons/cache/Commissions/CommissionPlan.ts"
import { InstrumentTradingBalance } from "../../Utils/Instruments/InstrumentTradingBalance.ts"
import { DayPeriods, DayType, SubTypes } from "../../Utils/Session/Sessions.ts"
import { DynProperty } from "../../Commons/DynProperty.ts"
import { GeneralSettings } from "../../Utils/GeneralSettings/GeneralSettings.ts"
import { RiskPlan } from "../../Commons/cache/RiskPlan.ts"
import { Level1Calculator } from "../../Commons/cache/Level1Calculator.ts"
import { NumericUtils } from "../../Utils/NumericUtils.ts"
import { Instrument } from "../../Commons/cache/Instrument.ts"
import { InstrumentUtils } from "../../Utils/Instruments/InstrumentUtils.ts"
import { DataCache } from "../../Commons/DataCache.ts"
import { DateTimeConvertor } from "../../Utils/Time/DateTimeConvertor.ts"
import { OrderUtils } from "../../Utils/Trading/OrderUtils.ts"
import { LinkedSystem } from "../misc/LinkedSystem.ts"
import { InstrumentFeatureHelper } from '../../Utils/Instruments/InstrumentFeatureHelper.ts';
import { GeneralSymbolInfoFeatureEnum } from "../../Utils/Instruments/InstrumentFeatureEnum.ts"
import { Enum } from '../../Utils/Enum.ts';

export let SymbolInfoPanel = VerticalApplicationPanelNew.extend(
    {
        Name: 'SymbolInfoPanel',
        //topPanelHeight: 25,
        data: function ()
        {
            return {
                isSymbolLinkShow: true,
                isAccountLinkShow: true,
                canLinkByAccount: false,
                canFilterByAccount: false,
                zIndex: 1300,
                showHeader: true,
                showFooter: false,
                dockablePanel: false,
                resizable: false,
                account: null,
                width: SymbolInfoPanel.PANEL_WIDTH,
                height: SymbolInfoPanel.PANEL_HEIGHT,
                closeBtnVisible: true,
            }
        },
        myIns: null,
        NeedCalculateRowCount: false,
        headerLocaleKey: 'panel.instrumentsInfo',
        skipOnSave: true
    })

SymbolInfoPanel.prototype.getType = function ()
{
    return PanelNames.SymbolInfoPanel
}

SymbolInfoPanel.prototype.oninit = function ()
{
    VerticalApplicationPanelNew.prototype.oninit.call(this);

    this.observe('account', this.addAccountInfo, { init: false });
}

SymbolInfoPanel.prototype.oncomplete = function ()
{
    // Для общих настроек по типам сессии
    this.sessionTypesItem = new Object()
    // Для настроек по типам маржи
    this.marginTypesItem = new Object()
    // Для настроек по типам fee
    this.feeTypesItem = new Object()
    // Для настроек по типам tickSizeCost
    this.tickSizeCostTypesItem = new Object()
    // Для настроек по типам rebate
    this.rebateTypesItem = new Object()

    this.dynamicTypesItem = new Object()

    VerticalApplicationPanelNew.prototype.oncomplete.call(this)

    this.quickTableRactive.setShowColumnHeaders(false)

    let qt = this.getQuickTable()
    qt.allowGroupBy = false
    qt.useVerticalSeparatorForResizing = true
    qt.OnDrawCell.Subscribe(SymbolInfoPanel.onDrawCell, this)
    qt.AfterEditItem.Subscribe(this.onQuickTableAfterEditItem, this)
    qt.FormatGroup = this.quickTable_FormatGroup.bind(this)

    KeyEventProcessor.OnKeyDown.Subscribe(this.onGlobalKeyDown, this);

    //this.on(Control.Events.LostFocus, this.LostFocus);
    DataCache.OnCommissionPlanUpdate.Subscribe(this.PopulateTable, this);
    GeneralSettings.SettingsChanged.Subscribe(this.PopulateTable, this);

    this.changeInstrument(this.initLinkInSymbol);
}

SymbolInfoPanel.prototype.onGlobalKeyDown = function (key)
{
    if (key === KeyCode.ESC)
        this.LostFocus();
}

SymbolInfoPanel.prototype.accountLink_In = function (accId)
{
    if (!LinkedSystem.accLinkingActive)
    {
        return;
    }

    if (DataCache.Accounts[accId])
        this.set('account', DataCache.Accounts[accId])

    this.localizeAccountLinkTooltip()
}

SymbolInfoPanel.prototype.accountLink_Out = function (newSubscriber, account)
{
    if (!account)
    {
        let acc = this.get('account')
        if (!acc) return {}
        account = acc
    }

    if (LinkedSystem.accLinkingActive)
        this.completed ?
            LinkedSystem.setAccount(LinkedSystemAccLinkingValue, account.BstrAccount, newSubscriber) :
            this.accountLink_In(LinkedSystem.getAccount(LinkedSystemAccLinkingValue))
}

SymbolInfoPanel.prototype.PopulateTable = function (needAddAccountInfo = true)
{
    VerticalApplicationPanelNew.prototype.PopulateTable.call(this);

    if (needAddAccountInfo) 
    {
        this.addAccountInfo();
    }
}

SymbolInfoPanel.prototype.addAccountInfo = function ()
{
    if (this.get('account') == null)
    {
        this.set('account', DataCache.getPrimaryAccount());
    }

    if (this.VerticalPanelDataProvider != null)
    {
        this.VerticalPanelDataProvider.dependency = this.get('account');
    }

    this.updateAccountLinkVisible(LinkedSystem.accLinkingActive, true); // #120034

    if (!DataCache.userHasSameCommissionAndSwapPlanOnAllAccounts()) 
    {   // #120126 <- sometimes need to repopulate Symbol info on account change 
        this.PopulateTable(false);
    }

    if (this.needAddInfoIconToFeesGroupRow()) { this.addInfoIconToGroupRow(SymbolInfoPanel.KEY_fees); }
    else { this.removeInfoIconFromGroupRow(SymbolInfoPanel.KEY_fees); }
}

SymbolInfoPanel.prototype.needAddInfoIconToFeesGroupRow = function ()
{
    const acc = this.get('account');
    const primaryAcc = DataCache.getPrimaryAccount();

    return !DataCache.userHasSameCommissionAndSwapPlanOnAllAccounts() &&
        (!LinkedSystem.accLinkingActive || acc?.BstrAccount === primaryAcc?.BstrAccount);
}

SymbolInfoPanel.prototype.addInfoIconToGroupRow = function (groupKey)
{
    const qt = this.getQuickTable();
    if (qt?.groupStorageDict[groupKey] != null)
    {
        const groupRow = qt.groupStorageDict[groupKey].groupHeaderRow;
        const account = this.get('account');
        if (groupRow != null && account != null)
        {
            const tt = Resources.getResource(groupKey + '.InfoIconTooltip');
            groupRow.infoIconTooltip = tt.replace('{0}', account.toString());
            qt.redraw();
        }
    }
}

SymbolInfoPanel.prototype.removeInfoIconFromGroupRow = function (groupKey)
{
    const qt = this.getQuickTable();
    if (qt?.groupStorageDict[groupKey] != null)
    {
        const groupRow = qt.groupStorageDict[groupKey].groupHeaderRow;
        if (groupRow != null && groupRow.infoIconTooltip !== '')
        {
            groupRow.infoIconTooltip = '';
            qt.redraw();
        }
    }
}

SymbolInfoPanel.prototype.LostFocus = function ()
{
    this.set({ visible: false });
    this.OnClose.Raise();
}

SymbolInfoPanel.onDrawCell = function (gr, e)
{
    let cell = e.cell
    let formattedValue = cell.formattedValue
    let splits = formattedValue.split(SymbolInfoPanel.commisionSplitter)

    let splits_len = splits.length
    if (splits_len < 2)
        return

    let x = Math.round(e.x)
    let y = e.y
    let w = e.width
    let h = e.height

    let bottom = y + h
    let quarterW = w / 4
    let splitCellWArr = (new Array(splits_len)).fill(w / splits_len)    // доп. колонки имеют одинаковую ширину в общем случае

    if (splits_len == 2)                // #96044
    {
        splitCellWArr[0] *= (4 / 3)     // #96044 для частного случая 2х доп колонок делаем ширину 2:1 (66.(6)% : 33.(3)%) 
        splitCellWArr[1] *= (2 / 3)
    }

    let gridColor = e.table.gridColor

    for (let i = 0; i < splits_len; i++)
    {
        let splitCellW = splitCellWArr[i]

        gr.fillStyle = e.BackColor
        gr.fillRect(x, y, splitCellW, h)

        if (i)
        {
            gr.beginPath()
            gr.lineWidth = 1
            gr.strokeStyle = gridColor
            gr.moveTo(x, y)
            gr.lineTo(x, bottom)
            gr.stroke()
        }

        let text = splits[i]

        gr.font = e.font
        gr.fillStyle = e.ForeColor

        QuickTableUtils.DrawTextInRect(gr, text, x, y, splitCellW, h, 5, 3)

        x += splitCellW
    }

    e.handled = true
}

SymbolInfoPanel.prototype.onQuickTableAfterEditItem = function (params)
{
    if (!params)
        return

    let instrument = this.myIns
    if (!instrument)
        return

    let table = params.table
    let row = params.row
    let cell = params.cell
    let columnIndex = params.columnIndex

    if (row && row.item.RowKey === SymbolInfoPanel.KEY_NextHoliday && cell && cell.value)
    {
        let scrLocation = ControlsUtils.getAbsoluteLocation(this)

        let sortedColumns = table.sortedColumns
        let len = sortedColumns.length
        let columnLeft = scrLocation.X
        for (let i = 0; i < len; i++)
        {
            if (i === columnIndex)
                break

            columnLeft += sortedColumns[i].width
        }

        InstrumentHolidaysScreen.show(instrument, columnLeft - 40, scrLocation.Y + row.displayRectangle.Bottom())
    }

    if (row && (row.item.RowKey === SymbolInfoPanel.KEY_Yield || row.item.RowKey === SymbolInfoPanel.KEY_YTM))
        DataCache.GetYieldRate(instrument)

    if (row && row.item.RowKey === SymbolInfoPanel.KEY_ACCRUED_INTEREST)
        DataCache.updateInstrumentById(instrument.Id)
}

SymbolInfoPanel.prototype.dispose = function ()
{
    this.changeInstrument(null)

    let qt = this.getQuickTable()
    if (qt)
    {
        qt.OnDrawCell.UnSubscribe(SymbolInfoPanel.onDrawCell, this)
        qt.AfterEditItem.UnSubscribe(this.onQuickTableAfterEditItem, this)
    }

    KeyEventProcessor.OnKeyDown.UnSubscribe(this.onGlobalKeyDown, this);

    DataCache.OnCommissionPlanUpdate.UnSubscribe(this.PopulateTable, this);
    GeneralSettings.SettingsChanged.UnSubscribe(this.PopulateTable, this);

    ApplicationPanelNew.prototype.dispose.call(this)
}

SymbolInfoPanel.prototype.changeInstrument = function (instrument)
{
    let oldInstrument = this.VerticalPanelDataProvider.Source
    this.VerticalPanelDataProvider.Source = instrument

    // hsa: #73351 подписка и отписка нужны для Reference Price.
    if (oldInstrument)
        DataCache.FQuoteCache.removeListener(oldInstrument, this, HistoryType.QUOTE_LEVEL1)

    if (!instrument)
        return

    this.myIns = instrument;

    // hsa: #73351 подписка и отписка нужны для Reference Price.
    DataCache.FQuoteCache.addListener(instrument, this, HistoryType.QUOTE_LEVEL1)

    this.PopulateTable()
    this.updatePanelHeader()

    this.set({ visible: true });
    this.setFocus();
}

SymbolInfoPanel.prototype.newQuote = function (msg) { }

SymbolInfoPanel.prototype.symbolLink_In = function (symbolName)
{
    let newInstr = DataCache.getInstrumentByName(symbolName)
    if (this.completed)
        this.changeInstrument(newInstr);
    else
        this.initLinkInSymbol = newInstr;
};


SymbolInfoPanel.prototype.getInstrument = function ()
{
    if (!this.myIns)
        return null;
    return this.myIns;
}

SymbolInfoPanel.prototype.updatePanelHeader = function ()
{
    var headerStr = Resources.getResource(this.headerLocaleKey);
    var instrument = this.myIns;
    if (instrument && !instrument.IsEmpty)
    {
        headerStr += ' ' + instrument.DisplayName();
    }

    this.set('header', headerStr);
};

SymbolInfoPanel.prototype.InitializeDataProvider = function ()
{
    this.VerticalPanelDataProvider = new VerticalPanelDataProvider(this.sett)
    // Data items
    let dataItems = SymbolInfoPanel.getDataItems()
    this.VerticalPanelDataProvider.items = dataItems

    // dynamic tickSizeCost
    let tickSizeCost = SymbolInfoPanel.tickSizeCost
    let tickSizeCostTypesItem = this.tickSizeCostTypesItem = {}
    for (let i = 0; i < tickSizeCost.length; i++)
    {
        let key = tickSizeCost[i]
        tickSizeCostTypesItem[key] = SymbolInfoPanel.createItem(SymbolInfoPanel.KEY_tradingInfo, 90 + i, key, key)
    }

    // +++ dynamic margins
    let margins = SymbolInfoPanel.margins
    let marginTypesItem = this.marginTypesItem = {};
    for (let i = 0; i < margins.length; i++)
    {
        let key = margins[i]
        marginTypesItem[key] = SymbolInfoPanel.createItem(SymbolInfoPanel.KEY_marginReg, 70 + i, key, key);
    }

    // +++ dynamic fees
    let fees = SymbolInfoPanel.fees
    let feeTypesItem = this.feeTypesItem = {};
    for (let i = 0; i < fees.length; i++)
    {
        let key = fees[i]
        feeTypesItem[key] = SymbolInfoPanel.createItem(SymbolInfoPanel.KEY_fees, 109 + i, key, key);
    }

    this.VerticalPanelDataProvider.GetVerticalPanelValueHandler = SymbolInfoPanel.GetDataValue.bind(this)
    this.VerticalPanelDataProvider.GetItemsIDHandler = SymbolInfoPanel.GetItemsID.bind(this)
    this.VerticalPanelDataProvider.GetDynamicItemsHandler = this.GetDynamicItems.bind(this)
    // на смену Source отписка и пр.
    this.VerticalPanelDataProvider.DisposeHandler = SymbolInfoPanel.DisposeSource.bind(this)
}

SymbolInfoPanel.prototype.quickTable_FormatGroup = function (groupValue)
{
    if (groupValue == SymbolInfoPanel.KEY_sessionInfo)
        return SymbolInfoPanel.LocalizeSessionGroup(this.VerticalPanelDataProvider, this.sett);

    return Resources.getResource(groupValue, GeneralSettings.General.Language);
}


SymbolInfoPanel.PRODUCT_TYPE_INTRADAY = 'Intraday'//ProductType.Intraday;
SymbolInfoPanel.PRODUCT_TYPE_DELIVERY = 'Delivery'//ProductType.Delivery;
SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR = '.'

SymbolInfoPanel.MARGIN_TIER_SEPARATOR = '#'
// #region localized keys

// Groups
SymbolInfoPanel.KEY_generalInfo = 'InstrumentDetailsPanel.1.GeneralInfo'
SymbolInfoPanel.KEY_tradingInfo = 'InstrumentDetailsPanel.2.TradingInfo'
SymbolInfoPanel.KEY_marginReg = 'InstrumentDetailsPanel.3.MarginReg'
SymbolInfoPanel.KEY_fees = 'InstrumentDetailsPanel.4.Fees'
SymbolInfoPanel.KEY_rebates = 'InstrumentDetailsPanel.5.Rebates'
SymbolInfoPanel.KEY_sessionInfo = 'InstrumentDetailsPanel.6.SessionInfo'
SymbolInfoPanel.KEY_plans = 'InstrumentDetailsPanel.7.Plans'

// 1. General info
SymbolInfoPanel.KEY_Symbol = 'InstrumentDetailsPanel.Symbol'
SymbolInfoPanel.KEY_Description = 'InstrumentDetailsPanel.Description'
SymbolInfoPanel.KEY_FuturesClass = 'InstrumentDetailsPanel.FuturesClass'
SymbolInfoPanel.KEY_ExchangeTrading = 'InstrumentDetailsPanel.ExchangeTrading'
SymbolInfoPanel.KEY_ExchangeMarketData = "InstrumentDetailsPanel.ExchangeMarketData";
SymbolInfoPanel.KEY_AssetClass = 'InstrumentDetailsPanel.AssetClass'
SymbolInfoPanel.KEY_Underlier = 'InstrumentDetailsPanel.Underlier'
SymbolInfoPanel.KEY_DeliveryMethod = 'InstrumentDetailsPanel.DeliveryMethod'
SymbolInfoPanel.KEY_ContactMonth = 'InstrumentDetailsPanel.ContactMonth'
SymbolInfoPanel.KEY_StrikePrice = 'InstrumentDetailsPanel.StrikePrice'
SymbolInfoPanel.KEY_FirstTradeDate = 'InstrumentDetailsPanel.FirstTradeDate'
SymbolInfoPanel.KEY_LastTradeDate = 'InstrumentDetailsPanel.LastTradeDate'
SymbolInfoPanel.KEY_NoticeDate = 'InstrumentDetailsPanel.NoticeDate'
SymbolInfoPanel.KEY_CloseOutDeadline = 'InstrumentDetailsPanel.CloseOutDeadline'
SymbolInfoPanel.KEY_TradingBalance = 'InstrumentDetailsPanel.TradingBalance'

SymbolInfoPanel.KEY_MaturityDate = 'InstrumentDetailsPanel.MaturityDate'
SymbolInfoPanel.KEY_FaceValue = 'InstrumentDetailsPanel.FaceValue'
SymbolInfoPanel.KEY_CouponRate = 'InstrumentDetailsPanel.CouponRate'
SymbolInfoPanel.KEY_CouponCycle = 'InstrumentDetailsPanel.CouponCycle'
SymbolInfoPanel.KEY_YTM = 'InstrumentDetailsPanel.YTM'
SymbolInfoPanel.KEY_ACCRUED_INTEREST = 'InstrumentDetailsPanel.AccruedInterest'
SymbolInfoPanel.KEY_PREV_PAYMENT_DATE = 'InstrumentDetailsPanel.PreviousPaymentDate'
SymbolInfoPanel.KEY_NEXT_PAYMENT_DATE = 'InstrumentDetailsPanel.NextPaymentDate'
SymbolInfoPanel.KEY_Exp1 = 'InstrumentDetailsPanel.Exp1'
SymbolInfoPanel.KEY_Yield = 'InstrumentDetailsPanel.Yield'
SymbolInfoPanel.KEY_ISIN = 'InstrumentDetailsPanel.ISIN'
SymbolInfoPanel.KEY_Industry = 'InstrumentDetailsPanel.Industry'
SymbolInfoPanel.KEY_Sector = 'InstrumentDetailsPanel.Sector'
SymbolInfoPanel.KEY_ExerciseStyle = 'InstrumentDetailsPanel.ExerciseStyle'
SymbolInfoPanel.KEY_DAYS_PER_MONTH = 'InstrumentDetailsPanel.BondDaysPerMonth'
SymbolInfoPanel.KEY_DAYS_PER_YEAR = 'InstrumentDetailsPanel.BondDaysPerYear'

// 2. Trading info
SymbolInfoPanel.KEY_TradingStatus = 'InstrumentDetailsPanel.TradingStatus'
SymbolInfoPanel.KEY_AllowedOperations = 'InstrumentDetailsPanel.AllowedOperations'
SymbolInfoPanel.KEY_AllowedOrderTypes = 'InstrumentDetailsPanel.AllowedOrderTypes'
SymbolInfoPanel.KEY_ProductType = 'InstrumentDetailsPanel.ProductType'
SymbolInfoPanel.KEY_DeliveryStatus = 'InstrumentDetailsPanel.DeliveryStatus'
SymbolInfoPanel.KEY_AllowShortPositions = 'InstrumentDetailsPanel.AllowShortPositions'
SymbolInfoPanel.KEY_AllowShortPositionsIntraday = 'InstrumentDetailsPanel.AllowShortPositions' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_INTRADAY
SymbolInfoPanel.KEY_AllowShortPositionsDelivery = 'InstrumentDetailsPanel.AllowShortPositions' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_DELIVERY
SymbolInfoPanel.KEY_AllowOvernightTrading = 'InstrumentDetailsPanel.AllowOvernightTrading'
//SymbolInfoPanel.KEY_PrevSettlement = 'InstrumentDetailsPanel.PrevSettlement'
SymbolInfoPanel.KEY_CurrentSession = 'InstrumentDetailsPanel.CurrentSession'
SymbolInfoPanel.KEY_ETB = "InstrumentDetailsPanel.ETB";
SymbolInfoPanel.KEY_SessionStatus = 'InstrumentDetailsPanel.SessionStatus'
SymbolInfoPanel.KEY_NextHoliday = 'InstrumentDetailsPanel.KEYNextHoliday'
SymbolInfoPanel.KEY_QuotiongCurrency = 'InstrumentDetailsPanel.QuotiongCurrency'
SymbolInfoPanel.KEY_LotSize = 'InstrumentDetailsPanel.LotSize'
SymbolInfoPanel.KEY_ContractMultiplier = 'InstrumentDetailsPanel.ContractMultiplier'
// SymbolInfoPanel.KEY_ContractSize = 'InstrumentDetailsPanel.ContractSize'
SymbolInfoPanel.KEY_TickSize = 'InstrumentDetailsPanel.TickSize'
SymbolInfoPanel.KEY_TickCoast = 'InstrumentDetailsPanel.TickCoast'
SymbolInfoPanel.KEY_MinimalLot = 'InstrumentDetailsPanel.MinimalLot'
SymbolInfoPanel.KEY_MinimalLotIntraday = 'InstrumentDetailsPanel.MinimalLot' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_INTRADAY
SymbolInfoPanel.KEY_MinimalLotDelivery = 'InstrumentDetailsPanel.MinimalLot' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_DELIVERY
SymbolInfoPanel.KEY_LotStep = 'InstrumentDetailsPanel.LotStep'
SymbolInfoPanel.KEY_HiLimit = 'InstrumentDetailsPanel.HiLimit'
SymbolInfoPanel.KEY_LowLimit = 'InstrumentDetailsPanel.LowLimit'
SymbolInfoPanel.KEY_MaximumLot = 'InstrumentDetailsPanel.MaximumLot'
SymbolInfoPanel.KEY_MaximumLotIntraday = 'InstrumentDetailsPanel.MaximumLot' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_INTRADAY
SymbolInfoPanel.KEY_MaximumLotDelivery = 'InstrumentDetailsPanel.MaximumLot' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_DELIVERY
SymbolInfoPanel.KEY_MaxPositionQty = 'InstrumentDetailsPanel.MaxPositionQtyPerSymbol'
SymbolInfoPanel.KEY_MaxPositionQtyIntraday = 'InstrumentDetailsPanel.MaxPositionQtyPerSymbol' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_INTRADAY
SymbolInfoPanel.KEY_MaxPositionQtyDelivery = 'InstrumentDetailsPanel.MaxPositionQtyPerSymbol' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_DELIVERY
SymbolInfoPanel.KEY_TradindBlockedOnSession = 'InstrumentDetailsPanel.TradindBlockedOnSession'
SymbolInfoPanel.KEY_ExchangeTradeSession = 'InstrumentDetailsPanel.ExchangeTradeSession'
SymbolInfoPanel.KEY_NormalMarketSize = 'InstrumentDetailsPanel.NormalMarketSize'
SymbolInfoPanel.KEY_HighLimitWarning = 'InstrumentDetailsPanel.HighLimitWarning'
SymbolInfoPanel.KEY_LowLimitWarning = 'InstrumentDetailsPanel.LowLimitWarning'
// SymbolInfoPanel.KEY_HiLimitFromPriceLimitsMsg = 'InstrumentDetailsPanel.HiLimitFromPriceLimitsMsg'
// SymbolInfoPanel.KEY_LowLimitFromPriceLimitsMsg = 'InstrumentDetailsPanel.LowLimitFromPriceLimitsMsg'

// 3. Margin reg
SymbolInfoPanel.KEY_Margin_ByAccount = 'InstrumentDetailsPanel.Margin_ByAccount'
SymbolInfoPanel.KEY_Margin = 'InstrumentDetailsPanel.Margin'
SymbolInfoPanel.KEY_MarginBuy = 'InstrumentDetailsPanel.MarginBuy'
SymbolInfoPanel.KEY_MarginSell = 'InstrumentDetailsPanel.MarginSell'
SymbolInfoPanel.KEY_MarginOvernight = 'InstrumentDetailsPanel.MarginOvernight'
SymbolInfoPanel.KEY_MarginDay = 'InstrumentDetailsPanel.MarginDay'
SymbolInfoPanel.KEY_MarginDayBuy = 'InstrumentDetailsPanel.MarginDayBuy'
SymbolInfoPanel.KEY_MarginDaySell = 'InstrumentDetailsPanel.MarginDaySell'
SymbolInfoPanel.KEY_MarginOvernightBuy = 'InstrumentDetailsPanel.MarginOvernightBuy'
SymbolInfoPanel.KEY_MarginOvernightSell = 'InstrumentDetailsPanel.MarginOvernightSell'
SymbolInfoPanel.KEY_MarginInAccountCurrency = 'InstrumentDetailsPanel.MarginInAccountCurrency'

SymbolInfoPanel.KEY_Leverage = 'InstrumentDetailsPanel.Leverage'    // #112886
SymbolInfoPanel.KEY_LeverageIntraday = SymbolInfoPanel.KEY_Leverage + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_INTRADAY
SymbolInfoPanel.KEY_LeverageDelivery = SymbolInfoPanel.KEY_Leverage + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_DELIVERY

SymbolInfoPanel.KEY_MarginIntraday = 'InstrumentDetailsPanel.Margin' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_INTRADAY
SymbolInfoPanel.KEY_MarginBuyIntraday = 'InstrumentDetailsPanel.MarginBuy' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_INTRADAY
SymbolInfoPanel.KEY_MarginSellIntraday = 'InstrumentDetailsPanel.MarginSell' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_INTRADAY
SymbolInfoPanel.KEY_MarginOvernightIntraday = 'InstrumentDetailsPanel.MarginOvernight' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_INTRADAY
SymbolInfoPanel.KEY_MarginDayIntraday = 'InstrumentDetailsPanel.MarginDay' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_INTRADAY
SymbolInfoPanel.KEY_MarginDayBuyIntraday = 'InstrumentDetailsPanel.MarginDayBuy' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_INTRADAY
SymbolInfoPanel.KEY_MarginDaySellIntraday = 'InstrumentDetailsPanel.MarginDaySell' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_INTRADAY
SymbolInfoPanel.KEY_MarginOvernightBuyIntraday = 'InstrumentDetailsPanel.MarginOvernightBuy' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_INTRADAY
SymbolInfoPanel.KEY_MarginOvernightSellIntraday = 'InstrumentDetailsPanel.MarginOvernightSell' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_INTRADAY
SymbolInfoPanel.KEY_MarginInAccountCurrencyIntraday = 'InstrumentDetailsPanel.MarginInAccountCurrency' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_INTRADAY

SymbolInfoPanel.KEY_MarginDelivery = 'InstrumentDetailsPanel.Margin' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_DELIVERY
SymbolInfoPanel.KEY_MarginBuyDelivery = 'InstrumentDetailsPanel.MarginBuy' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_DELIVERY
SymbolInfoPanel.KEY_MarginSellDelivery = 'InstrumentDetailsPanel.MarginSell' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_DELIVERY
SymbolInfoPanel.KEY_MarginOvernightDelivery = 'InstrumentDetailsPanel.MarginOvernight' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_DELIVERY
SymbolInfoPanel.KEY_MarginDayDelivery = 'InstrumentDetailsPanel.MarginDay' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_DELIVERY
SymbolInfoPanel.KEY_MarginDayBuyDelivery = 'InstrumentDetailsPanel.MarginDayBuy' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_DELIVERY
SymbolInfoPanel.KEY_MarginDaySellDelivery = 'InstrumentDetailsPanel.MarginDaySell' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_DELIVERY
SymbolInfoPanel.KEY_MarginOvernightBuyDelivery = 'InstrumentDetailsPanel.MarginOvernightBuy' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_DELIVERY
SymbolInfoPanel.KEY_MarginOvernightSellDelivery = 'InstrumentDetailsPanel.MarginOvernightSell' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_DELIVERY
SymbolInfoPanel.KEY_MarginInAccountCurrencyDelivery = 'InstrumentDetailsPanel.MarginInAccountCurrency' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_DELIVERY

// 4. Fees

SymbolInfoPanel.KEY_ShortCommissionInfo = KEY_ShortCommissionInfo;
SymbolInfoPanel.KEY_OptionExercise = KEY_OptionExercise;
SymbolInfoPanel.KEY_CustodialFee = KEY_CustodialFee;
SymbolInfoPanel.KEY_ShortPositionInterest = KEY_ShortPositionInterest;
SymbolInfoPanel.KEY_FILLPERLOT = KEY_FILLPERLOT;
SymbolInfoPanel.KEY_ORDERPERLOT = KEY_ORDERPERLOT;
SymbolInfoPanel.KEY_PERFILL = KEY_PERFILL;
SymbolInfoPanel.KEY_PERTRANSACTION = KEY_PERTRANSACTION;
SymbolInfoPanel.KEY_PERPHONETRANSACTION = KEY_PERPHONETRANSACTION;
SymbolInfoPanel.KEY_PERVOLUME = KEY_PERVOLUME;
SymbolInfoPanel.KEY_PERORDERVOLUME = KEY_PERORDERVOLUME;
SymbolInfoPanel.KEY_VAT = KEY_VAT;
SymbolInfoPanel.KEY_VOLUME_WITH_MIN_PD = KEY_VOLUME_WITH_MIN_PD;
SymbolInfoPanel.KEY_VOLUME = KEY_VOLUME;
SymbolInfoPanel.KEY_ORDER_VOLUME = KEY_ORDER_VOLUME;

SymbolInfoPanel.KEY_SwapBuy = 'InstrumentDetailsPanel.SwapBuy'
SymbolInfoPanel.KEY_SwapSell = 'InstrumentDetailsPanel.SwapSell'

SymbolInfoPanel.KEY_Historical_LongSwap = "InstrumentDetailsPanel.Latest historical long swap";
SymbolInfoPanel.KEY_Historical_ShortSwap = "InstrumentDetailsPanel.Latest historical short swap";
SymbolInfoPanel.KEY_Historical_LastSwap = "InstrumentDetailsPanel.Historical swap last updated on";

SymbolInfoPanel.KEY_Historical_LongSwap_shortKey = "Latest historical long swap";
SymbolInfoPanel.KEY_Historical_ShortSwap_shortKey = "Latest historical short swap";
SymbolInfoPanel.KEY_Historical_LastSwap_shortKey = "Historical swap last updated on";
// 5. Session info
// ...
SymbolInfoPanel.KEY_SettlementDate = 'InstrumentDetailsPanel.SettlementDate'

SymbolInfoPanel.SESSION_DAY_PERIOD_PREOPEN = 'InstrumentDetailsPanel.PREOPEN'
SymbolInfoPanel.SESSION_DAY_PERIOD_MAIN = 'InstrumentDetailsPanel.MAIN'
SymbolInfoPanel.SESSION_DAY_PERIOD_POST_CLOSE = 'InstrumentDetailsPanel.POSTCLOSE'
SymbolInfoPanel.SESSION_DAY_PERIOD_BEFORE_MARKET = 'InstrumentDetailsPanel.BEFORE_MARKET'
SymbolInfoPanel.SESSION_DAY_PERIOD_AFTER_MARKET = 'InstrumentDetailsPanel.AFTER_MARKET'

// 7. Plans info
SymbolInfoPanel.KEY_CommissionInfo = 'InstrumentDetailsPanel.Commisions'

// 8. ExtField
SymbolInfoPanel.KEY_EXTFIELD_TRADINGUNIT = 'InstrumentDetailsPanel.ExtField.TradingUnit'
SymbolInfoPanel.KEY_EXTFIELD_PRICEQUOTEUNIT = 'InstrumentDetailsPanel.ExtField.PriceQuoteUnit'
SymbolInfoPanel.KEY_EXTFIELD_DELIVERYUNIT = 'InstrumentDetailsPanel.ExtField.DeliveryUnit'
SymbolInfoPanel.KEY_EXTFIELD_LOTSIZE = 'InstrumentDetailsPanel.ExtField.LotSize'
SymbolInfoPanel.KEY_EXTFIELD_TENDERPERIODSTARTDATE = 'InstrumentDetailsPanel.ExtField.TenderPeriodStartDate'
SymbolInfoPanel.KEY_EXTFIELD_TENDERPERIODENDDATE = 'InstrumentDetailsPanel.ExtField.TenderPeriodEndDate'
SymbolInfoPanel.KEY_SMFLAG_PREFIX = 'InstrumentDetailsPanel.ExtField.SMFlag.'
SymbolInfoPanel.KEY_ASMFLAG_SHORT = 'InstrumentDetailsPanel.ExtField.SMFlag.Short term ASM Flag'
SymbolInfoPanel.KEY_ASMFLAG_LONG = 'InstrumentDetailsPanel.ExtField.SMFlag.Long term ASM Flag'
SymbolInfoPanel.KEY_GSMFLAG = 'InstrumentDetailsPanel.ExtField.SMFlag.GSM Flag'
SymbolInfoPanel.KEY_ESMFLAG = 'InstrumentDetailsPanel.ExtField.SMFlag.ESM Flag'

// #endregion localized keys

SymbolInfoPanel.tickSizeCost =
    [
        SymbolInfoPanel.KEY_TickSize,
        SymbolInfoPanel.KEY_TickCoast,
        SymbolInfoPanel.KEY_LotSize
    ]

SymbolInfoPanel.margins =
    [
        SymbolInfoPanel.KEY_Margin_ByAccount,
        SymbolInfoPanel.KEY_Margin,
        SymbolInfoPanel.KEY_MarginIntraday,
        SymbolInfoPanel.KEY_MarginDelivery,
        SymbolInfoPanel.KEY_MarginBuy,
        SymbolInfoPanel.KEY_MarginBuyIntraday,
        SymbolInfoPanel.KEY_MarginBuyDelivery,
        SymbolInfoPanel.KEY_MarginSell,
        SymbolInfoPanel.KEY_MarginSellIntraday,
        SymbolInfoPanel.KEY_MarginSellDelivery,
        SymbolInfoPanel.KEY_MarginDay,
        SymbolInfoPanel.KEY_MarginDayIntraday,
        SymbolInfoPanel.KEY_MarginDayDelivery,
        SymbolInfoPanel.KEY_MarginOvernight,
        SymbolInfoPanel.KEY_MarginOvernightIntraday,
        SymbolInfoPanel.KEY_MarginOvernightDelivery,
        SymbolInfoPanel.KEY_MarginDayBuy,
        SymbolInfoPanel.KEY_MarginDayBuyIntraday,
        SymbolInfoPanel.KEY_MarginDayBuyDelivery,
        SymbolInfoPanel.KEY_MarginDaySell,
        SymbolInfoPanel.KEY_MarginDaySellIntraday,
        SymbolInfoPanel.KEY_MarginDaySellDelivery,
        SymbolInfoPanel.KEY_MarginOvernightBuy,
        SymbolInfoPanel.KEY_MarginOvernightBuyIntraday,
        SymbolInfoPanel.KEY_MarginOvernightBuyDelivery,
        SymbolInfoPanel.KEY_MarginOvernightSell,
        SymbolInfoPanel.KEY_MarginOvernightSellIntraday,
        SymbolInfoPanel.KEY_MarginOvernightSellDelivery,
        SymbolInfoPanel.KEY_MarginInAccountCurrency,
        SymbolInfoPanel.KEY_Leverage,
        SymbolInfoPanel.KEY_LeverageIntraday,
        SymbolInfoPanel.KEY_LeverageDelivery
    ]

SymbolInfoPanel.fees =
    [
        SymbolInfoPanel.KEY_CustodialFee,
        SymbolInfoPanel.KEY_ShortPositionInterest,
        SymbolInfoPanel.KEY_Historical_LongSwap,
        SymbolInfoPanel.KEY_Historical_ShortSwap,
        SymbolInfoPanel.KEY_Historical_LastSwap
    ]


SymbolInfoPanel.createItem = function (Group, SortIndex, LocalizationKey, Id, DynPropertyControltype)
{
    let item = new VerticalPanelDataProviderItem();
    item.Group = Group;
    item.SortIndex = SortIndex;
    item.LocalizationKey = LocalizationKey;
    item.Id = Id;
    if (DynPropertyControltype !== undefined)
        item.DynPropertyControltype = DynPropertyControltype;
    return item;
}

//static public List<VerticalPanelDataProviderItem>
SymbolInfoPanel.getDataItems = function ()
{
    let temp = new Array();// List<VerticalPanelDataProviderItem>();
    try
    {
        let generalInfo = SymbolInfoPanel.KEY_generalInfo;
        let tradingInfo = SymbolInfoPanel.KEY_tradingInfo;
        let plansInfo = SymbolInfoPanel.KEY_plans;

        let i = 0;
        // 1. General info            
        temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_Symbol, SymbolInfoPanel.KEY_Symbol));
        temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_Description, SymbolInfoPanel.KEY_Description));
        //temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_FuturesClass, SymbolInfoPanel.KEY_FuturesClass));
        temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_ExchangeTrading, SymbolInfoPanel.KEY_ExchangeTrading));
        temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_ExchangeMarketData, SymbolInfoPanel.KEY_ExchangeMarketData));
        temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_AssetClass, SymbolInfoPanel.KEY_AssetClass));
        temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_Underlier, SymbolInfoPanel.KEY_Underlier));
        temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_DeliveryMethod, SymbolInfoPanel.KEY_DeliveryMethod));
        temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_ContactMonth, SymbolInfoPanel.KEY_ContactMonth));
        temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_FirstTradeDate, SymbolInfoPanel.KEY_FirstTradeDate));
        temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_LastTradeDate, SymbolInfoPanel.KEY_LastTradeDate));
        temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_NoticeDate, SymbolInfoPanel.KEY_NoticeDate));//10
        temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_SettlementDate, SymbolInfoPanel.KEY_SettlementDate));
        //temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_CloseOutDeadline, SymbolInfoPanel.KEY_CloseOutDeadline));
        temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_StrikePrice, SymbolInfoPanel.KEY_StrikePrice));
        temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_TradingBalance, SymbolInfoPanel.KEY_TradingBalance));

        temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_MaturityDate, SymbolInfoPanel.KEY_MaturityDate));
        temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_FaceValue, SymbolInfoPanel.KEY_FaceValue));
        temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_CouponRate, SymbolInfoPanel.KEY_CouponRate));
        temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_CouponCycle, SymbolInfoPanel.KEY_CouponCycle));
        temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_YTM, SymbolInfoPanel.KEY_YTM, DynProperty.LINK_WITH_TEXT));
        temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_ACCRUED_INTEREST, SymbolInfoPanel.KEY_ACCRUED_INTEREST, DynProperty.LINK_WITH_TEXT));
        temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_PREV_PAYMENT_DATE, SymbolInfoPanel.KEY_PREV_PAYMENT_DATE));
        temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_NEXT_PAYMENT_DATE, SymbolInfoPanel.KEY_NEXT_PAYMENT_DATE));
        temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_Exp1, SymbolInfoPanel.KEY_Exp1));
        temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_Yield, SymbolInfoPanel.KEY_Yield, DynProperty.LINK_WITH_TEXT));
        temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_ISIN, SymbolInfoPanel.KEY_ISIN));
        temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_Industry, SymbolInfoPanel.KEY_Industry));
        temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_Sector, SymbolInfoPanel.KEY_Sector));
        temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_ExerciseStyle, SymbolInfoPanel.KEY_ExerciseStyle));
        temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_DAYS_PER_MONTH, SymbolInfoPanel.KEY_DAYS_PER_MONTH));
        temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_DAYS_PER_YEAR, SymbolInfoPanel.KEY_DAYS_PER_YEAR));
        //#114474
        temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_GSMFLAG, SymbolInfoPanel.KEY_GSMFLAG));
        temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_ESMFLAG, SymbolInfoPanel.KEY_ESMFLAG));
        temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_ASMFLAG_LONG, SymbolInfoPanel.KEY_ASMFLAG_LONG));
        temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_ASMFLAG_SHORT, SymbolInfoPanel.KEY_ASMFLAG_SHORT));
        // 2. Trading info
        temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_TradingStatus, SymbolInfoPanel.KEY_TradingStatus));
        temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_AllowedOperations, SymbolInfoPanel.KEY_AllowedOperations));
        temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_AllowedOrderTypes, SymbolInfoPanel.KEY_AllowedOrderTypes));
        temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_ProductType, SymbolInfoPanel.KEY_ProductType));
        temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_DeliveryStatus, SymbolInfoPanel.KEY_DeliveryStatus));
        temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_CurrentSession, SymbolInfoPanel.KEY_CurrentSession));
        temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_SessionStatus, SymbolInfoPanel.KEY_SessionStatus));
        temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_NextHoliday, SymbolInfoPanel.KEY_NextHoliday, DynProperty.LINK));
        temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_QuotiongCurrency, SymbolInfoPanel.KEY_QuotiongCurrency));
        //temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_LotSize, SymbolInfoPanel.KEY_LotSize));
        temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_ContractMultiplier, SymbolInfoPanel.KEY_ContractMultiplier));

        // #32476 (Symbol info) перменный тик сайз/кост
        // temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_ContractSize, SymbolInfoPanel.KEY_ContractSize));
        //temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_TickCoast, SymbolInfoPanel.KEY_TickCoast));
        //temp.push(SymbolInfoPanel.createItem(tradingInfo, 55, SymbolInfoPanel.KEY_TickSize, SymbolInfoPanel.KEY_TickSize));//20
        temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_MinimalLot, SymbolInfoPanel.KEY_MinimalLot));
        temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_MinimalLotIntraday, SymbolInfoPanel.KEY_MinimalLotIntraday));
        temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_MinimalLotDelivery, SymbolInfoPanel.KEY_MinimalLotDelivery));
        //#36418
        temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_MaximumLot, SymbolInfoPanel.KEY_MaximumLot));
        temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_MaximumLotIntraday, SymbolInfoPanel.KEY_MaximumLotIntraday));
        temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_MaximumLotDelivery, SymbolInfoPanel.KEY_MaximumLotDelivery));
        temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_MaxPositionQty, SymbolInfoPanel.KEY_MaxPositionQty));
        temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_MaxPositionQtyIntraday, SymbolInfoPanel.KEY_MaxPositionQtyIntraday));
        temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_MaxPositionQtyDelivery, SymbolInfoPanel.KEY_MaxPositionQtyDelivery));

        temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_LotStep, SymbolInfoPanel.KEY_LotStep));
        //+++ #23263  Price limits
        temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_HiLimit, SymbolInfoPanel.KEY_HiLimit));
        temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_LowLimit, SymbolInfoPanel.KEY_LowLimit));
        // temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_HiLimitFromPriceLimitsMsg, SymbolInfoPanel.KEY_HiLimitFromPriceLimitsMsg));  //#111427
        // temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_LowLimitFromPriceLimitsMsg, SymbolInfoPanel.KEY_LowLimitFromPriceLimitsMsg));
        SymbolInfoPanel.KEY_LowLimit_SortIndex = i
        i += 20   // поскольку KEY_HighLimitWarning и KEY_LowLimitWarning динамические поля и создаются позже запоминаем SortIndex и резервируем для них 20 строк из расчета что не будет диапазонов больше чем на 10 строк
        ////+++ #30591  SwapBuy Sell  #31570 -> Fees
        //temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_SwapBuy, SymbolInfoPanel.KEY_SwapBuy));
        //temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_SwapSell, SymbolInfoPanel.KEY_SwapSell));
        //+++ #30783
        temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_AllowShortPositions, SymbolInfoPanel.KEY_AllowShortPositions))
        temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_ETB, SymbolInfoPanel.KEY_ETB));
        temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_AllowShortPositionsIntraday, SymbolInfoPanel.KEY_AllowShortPositionsIntraday))
        temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_AllowShortPositionsDelivery, SymbolInfoPanel.KEY_AllowShortPositionsDelivery))
        temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_AllowOvernightTrading, SymbolInfoPanel.KEY_AllowOvernightTrading))
        temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_NormalMarketSize, SymbolInfoPanel.KEY_NormalMarketSize))

        temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_EXTFIELD_TRADINGUNIT, SymbolInfoPanel.KEY_EXTFIELD_TRADINGUNIT))
        temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_EXTFIELD_PRICEQUOTEUNIT, SymbolInfoPanel.KEY_EXTFIELD_PRICEQUOTEUNIT))
        temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_EXTFIELD_DELIVERYUNIT, SymbolInfoPanel.KEY_EXTFIELD_DELIVERYUNIT))
        temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_EXTFIELD_LOTSIZE, SymbolInfoPanel.KEY_EXTFIELD_LOTSIZE))
        temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_EXTFIELD_TENDERPERIODSTARTDATE, SymbolInfoPanel.KEY_EXTFIELD_TENDERPERIODSTARTDATE))
        temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_EXTFIELD_TENDERPERIODENDDATE, SymbolInfoPanel.KEY_EXTFIELD_TENDERPERIODENDDATE))
        // add Plan (AdminMode)
        // let AdminMode = SymbolInfoPanel.createItem(plansInfo, i++, SymbolInfoPanel.KEY_CommissionInfo, SymbolInfoPanel.KEY_CommissionInfo);
        // AdminMode.Hidden = !Utils.AdminMode;
        // temp.push(AdminMode);

        // 3. Margin reg
        // + dynamic items

        // 4. Fees
        // + dynamic items
        // X. rebates
        // + dynamic items

        // 5. Session info 
        // + dynamic items
        //foreach (var item in temp)
        //item.tooltipKey = Resources.GetToolTipKey(item.localizationKey);

        return temp;
    }
    catch (ex)
    {
        ErrorInformationStorage.GetException(ex);
        return new Array();
    }

}


/// <summary>
/// по ключу строки отдаем значение строки
/// </summary>        
SymbolInfoPanel.GetDataValue = function (key, VerticalPanelDataProvider, sett)
{
    if (key.endsWith(SymbolInfoPanel.PRODUCT_TYPE_INTRADAY))
    {
        key = key.substring(0, key.lastIndexOf(SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR));
        return SymbolInfoPanel.GetDataValueProductType(key, VerticalPanelDataProvider, sett, ProductType.Intraday);
    }
    else if (key.endsWith(SymbolInfoPanel.PRODUCT_TYPE_DELIVERY))
    {
        key = key.substring(0, key.lastIndexOf(SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR));
        return SymbolInfoPanel.GetDataValueProductType(key, VerticalPanelDataProvider, sett, ProductType.Delivery);
    }
    else
    {
        var availableProductTypes = VerticalPanelDataProvider.Source.RiskSettings.RiskPlanSettings.availableProductTypes;
        return SymbolInfoPanel.GetDataValueProductType(key, VerticalPanelDataProvider, sett, availableProductTypes[0]);
    }
}

SymbolInfoPanel.ConvertToBoolean = function (value)
{
    return value == 'true' ? true : false
}

SymbolInfoPanel.GetDataValueProductType = function (key, VerticalPanelDataProvider, sett, productType)
{
    try
    {
        let currentInstrument = VerticalPanelDataProvider.Source;

        // Женя: закомментил. 
        // 1. вся инфа есть в инструменте. 2. инструмент может быть нулл. 3. генерить из инструмента мессадж чтоб с него взять то что есть в инструменте не очень. + вызывается метод отдельно на апдейт каждой из строк. 
        // InstrumentMessage mess = currentInstrument.CreateInstrumentMessage();

        //#region // 1. General info
        if (key == SymbolInfoPanel.KEY_Symbol) return currentInstrument.DisplayName(); // прячем роут когда надо
        if (key == SymbolInfoPanel.KEY_Description) return currentInstrument.DescriptionValue(); // mess.Descr;
        if (key == SymbolInfoPanel.KEY_FuturesClass) return currentInstrument.SourceName;
        if (key == SymbolInfoPanel.KEY_ExchangeTrading) return currentInstrument.TradingExchange; // Никита: сделал нормальное отображение биржи (возможно стоит закэшировать)
        if (key == SymbolInfoPanel.KEY_ExchangeMarketData) return currentInstrument.MarketDataExchange;
        if (key == SymbolInfoPanel.KEY_AssetClass) return InstrumentUtils.getInstrumentTypeStringLocalized(currentInstrument.InstrType, currentInstrument.CFD); // localized instrument type
        if (key == SymbolInfoPanel.KEY_Underlier) return currentInstrument.ForwardBaseInstrument ? currentInstrument.ForwardBaseInstrument.ShortName : "";
        if (key == SymbolInfoPanel.KEY_DeliveryMethod) return currentInstrument.DeliveryMethod === Instrument.Delivery_Physical ? Resources.getResource("InstrumentDetailsPanel.Physically", GeneralSettings.General.Language) : Resources.getResource("InstrumentDetailsPanel.Cash", GeneralSettings.General.Language);

        if (key == SymbolInfoPanel.KEY_ContactMonth)
            return DateTimeUtils.formatDate(currentInstrument.ContractMonthDate, 'DD.MM.YYYY');

        if (key == SymbolInfoPanel.KEY_FirstTradeDate)
        {
            if (currentInstrument.FirstTradeDate == DateTimeUtils._ZeroTime)
                return "";

            return DateTimeUtils.formatDate(currentInstrument.FirstTradeDate, 'DD.MM.YYYY');
        }
        if (key == SymbolInfoPanel.KEY_LastTradeDate)
            return DateTimeUtils.formatDate(currentInstrument.LastTradeDate, 'DD.MM.YYYY');
        if (key == SymbolInfoPanel.KEY_NoticeDate)
        {
            if (currentInstrument.NoticeDate == DateTimeUtils._ZeroTime)
                return "";

            return DateTimeUtils.formatDate(currentInstrument.NoticeDate, 'DD.MM.YYYY');
        }
        if (key == SymbolInfoPanel.KEY_SettlementDate)
        {
            if (!currentInstrument.SettlementDate)  // #46089 ===null
                return Resources.getResource("InstrumentDetailsPanel.None", GeneralSettings.General.Language);
            else
                return DateTimeUtils.formatDate(currentInstrument.SettlementDate, 'DD.MM.YYYY');
        }
        if (key == SymbolInfoPanel.KEY_CloseOutDeadline)
            return DateTimeUtils.formatDate(currentInstrument.AutoCloseDate, 'DD.MM.YYYY');

        if (key === SymbolInfoPanel.KEY_StrikePrice)
            return currentInstrument.StrikePrice.toString()

        if (key === SymbolInfoPanel.KEY_TradingBalance)
        {
            if (currentInstrument.TradingBalance === InstrumentTradingBalance.SETTLEMENT_IMMEDIATE)
                return Resources.getResource('InstrumentDetailsPanel.immediate')
            else if (currentInstrument.TradingBalance === InstrumentTradingBalance.SETTLEMENT_T_PLUS)
                return Resources.getResource('InstrumentDetailsPanel.T+0')
            else
                return ('T + ' + currentInstrument.TradingBalance.toString())
        }

        if (key == SymbolInfoPanel.KEY_MaturityDate)
            return currentInstrument.MaturityDate ? DateTimeUtils.formatDate(DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(currentInstrument.MaturityDate, currentInstrument.DataCache), 'DD.MM.YYYY') : "---";
        if (key == SymbolInfoPanel.KEY_FaceValue)
            return currentInstrument.FaceValue.toString();
        if (key == SymbolInfoPanel.KEY_CouponRate)
            return currentInstrument.CouponRate + " %";
        if (key == SymbolInfoPanel.KEY_CouponCycle)
            return Instrument.FormatCouponCycle(currentInstrument.CouponCycle);
        if (key == SymbolInfoPanel.KEY_YTM)
            return currentInstrument.YieldRate ? currentInstrument.YieldRate + " %" : "0 %";
        if (key == SymbolInfoPanel.KEY_ACCRUED_INTEREST)
            return currentInstrument.formatPrice(currentInstrument.AccruedInterest) + " " + currentInstrument.Exp2;
        if (key == SymbolInfoPanel.KEY_DAYS_PER_MONTH)
            return InstrumentFeatureHelper.getDaysPerFormatted(currentInstrument.DaysPerMonth, GeneralSymbolInfoFeatureEnum.DaysPerMonth);
        if (key == SymbolInfoPanel.KEY_DAYS_PER_YEAR)
            return InstrumentFeatureHelper.getDaysPerFormatted(currentInstrument.DaysPerYear, GeneralSymbolInfoFeatureEnum.DaysPerYear);
        if (key == SymbolInfoPanel.KEY_PREV_PAYMENT_DATE)
            return DateTimeUtils.formatDate(DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(currentInstrument.PrevPaymentDate, currentInstrument.DataCache), 'DD.MM.YYYY');
        if (key == SymbolInfoPanel.KEY_NEXT_PAYMENT_DATE)
            return DateTimeUtils.formatDate(currentInstrument.NextPaymentDate, 'DD.MM.YYYY');
        if (key == SymbolInfoPanel.KEY_Exp1)
            return currentInstrument.Exp1;
        if (key == SymbolInfoPanel.KEY_Yield)
            return currentInstrument.YieldRate ? currentInstrument.YieldRate + " %" : "0 %";
        if (key == SymbolInfoPanel.KEY_ISIN)
            return currentInstrument.ISIN;
        if (key == SymbolInfoPanel.KEY_Industry)
            return currentInstrument.Industry ? currentInstrument.Industry
                : (currentInstrument.ForwardBaseInstrument ? currentInstrument.ForwardBaseInstrument.Industry : '')
        if (key == SymbolInfoPanel.KEY_Sector)
            return currentInstrument.Sector ? currentInstrument.Sector
                : (currentInstrument.ForwardBaseInstrument ? currentInstrument.ForwardBaseInstrument.Sector : '')
        if (key == SymbolInfoPanel.KEY_ExerciseStyle)
            return currentInstrument.GetLocalizedExerciseStyle()

        //#endregion

        //#region // 2. Trading info
        if (key == SymbolInfoPanel.KEY_TradingStatus)
        {
            return InstrumentUtils.GetTradingStatus(currentInstrument)
        }
        if (key == SymbolInfoPanel.KEY_AllowedOperations)
        {
            let allow = SymbolInfoPanel.GetAllowedOperations(currentInstrument, sett);
            if ((allow))
                return allow;
            else
                return Resources.getResource("panel.watchlist.cell.Not allow", GeneralSettings.General.Language);
        }

        if (key == SymbolInfoPanel.KEY_AllowedOrderTypes)
        {
            let allow = SymbolInfoPanel.GetAllowedOrderTypes(currentInstrument, sett);
            if ((allow))
                return allow;
            else
                return Resources.getResource("panel.watchlist.cell.Not allow", GeneralSettings.General.Language);
        }
        if (key == SymbolInfoPanel.KEY_ProductType)
        {
            var availableProductTypes = currentInstrument.RiskSettings.RiskPlanSettings.availableProductTypes;

            if (availableProductTypes.length == 2)
                return Resources.getResource("ProductType.Intraday") + "/" + Resources.getResource("ProductType.Delivery");
            else

                return availableProductTypes.length > 0 ? InstrumentUtils.GetLocalizedProductType(currentInstrument, availableProductTypes[0]) : "";

        }

        if (key == SymbolInfoPanel.KEY_DeliveryStatus)
            return currentInstrument.LastTradeDate < DateTimeUtils.DateTimeUtcNow
                ? GetDeliveryStatus(currentInstrument.DeliveryStatus, sett)
                : '---'

        if (key === SymbolInfoPanel.KEY_CurrentSession)
            return currentInstrument.ExchangeSessionName

        if (key === SymbolInfoPanel.KEY_SessionStatus)
        {
            var tradeSessionStatus = DataCache.GetTradeSessionStatus(currentInstrument.GetTradeSessionStatusId())

            return tradeSessionStatus ? tradeSessionStatus.Name : ''
        }

        if (key == SymbolInfoPanel.KEY_TradindBlockedOnSession)
        {
            if (currentInstrument.BlockTradingBySession || currentInstrument.CurrentTradingSession == null)
                return Resources.getResource("InstrumentDetailsPanel.Yes");
            else
                return Resources.getResource("InstrumentDetailsPanel.No");
        }
        if (key == SymbolInfoPanel.KEY_NextHoliday)
        {
            let holiday = currentInstrument.GetNextHoliday();
            return holiday ? DateTimeUtils.formatDate(holiday.Date, "DD.MM.YYYY") + " (" + holiday.Name + ")" : "";
        }
        if (key == SymbolInfoPanel.KEY_QuotiongCurrency) return currentInstrument.Exp2;

        if (key == SymbolInfoPanel.KEY_LotSize || /*key == SymbolInfoPanel.KEY_ContractSize ||*/ key.indexOf(SymbolInfoPanel.KEY_TickCoast) !== -1)
        {
            if (currentInstrument.InstrType == InstrumentTypes.FUTURES || currentInstrument.InstrType == InstrumentTypes.CFD_FUTURES || currentInstrument.InstrType == InstrumentTypes.OPTIONS || (currentInstrument.InstrType == InstrumentTypes.SPREADBET && key.indexOf(SymbolInfoPanel.KEY_TickCoast) !== -1))
            {
                let tickCoast = currentInstrument.FuturesTickCoast;
                if (tickCoast < 0)
                    tickCoast = currentInstrument.LotSize * currentInstrument.PointSize;
                if (key.indexOf(SymbolInfoPanel.KEY_TickCoast) !== -1)
                {
                    let commisionKey = key.split(SymbolInfoPanel.commisionSplitter);
                    if (commisionKey.length == 6)
                    {
                        return SymbolInfoPanel.GetFeeString(commisionKey[1], commisionKey[2],
                            commisionKey[3], SymbolInfoPanel.ConvertToBoolean(commisionKey[4]), SymbolInfoPanel.ConvertToBoolean(commisionKey[5]), sett, SymbolInfoPanel.FeeStringVariable.Price);
                    }
                    return tickCoast.toString();
                }
                // if (key == SymbolInfoPanel.KEY_ContractSize)
                //     return currentInstrument.ContractSize.toString() + (currentInstrument.ForwardBaseInstrument != null ? (" " + currentInstrument.ForwardBaseInstrument.Exp1) : "");

                if (key == SymbolInfoPanel.KEY_LotSize && currentInstrument.QuotingType == QuotingType.LotSize)
                    return currentInstrument.LotSize.toString() + (currentInstrument.InstrType == InstrumentTypes.FOREX ? " " + currentInstrument.Exp2 : "");

                return "";
            }
            else
            {
                let stLotSize = currentInstrument.LotSize.toString();
                if (currentInstrument.isForexSymbol())
                    stLotSize += " " + currentInstrument.Exp1;
                if (key == SymbolInfoPanel.KEY_LotSize)
                    return stLotSize;

                //х.з. зачем так делать, почему не прислать в мессадже тик кост если нужен
                //double tickCoast = currentInstrument.LotSize * (double)currentInstrument.PointSize;
                let tickCoast = currentInstrument.FuturesTickCoast;
                if (key.indexOf(SymbolInfoPanel.KEY_TickCoast) !== -1)
                    return tickCoast.toString();
            }
        }

        if (key == SymbolInfoPanel.KEY_ContractMultiplier)
            return currentInstrument.ContractMultiplier.toString() + " " + currentInstrument.Exp1;

        if (key.indexOf(SymbolInfoPanel.KEY_TickSize) !== -1)
        {
            let commissionKey = key.split(SymbolInfoPanel.commisionSplitter)
            // имя которое отображается в таблице составное:
            let tickSize = currentInstrument.PointSize
            if (commissionKey.length === 6)
            {
                return SymbolInfoPanel.GetFeeString(
                    commissionKey[1],
                    commissionKey[2],
                    commissionKey[3],
                    commissionKey[4] === 'true',
                    commissionKey[5] === 'true',
                    sett,
                    SymbolInfoPanel.FeeStringVariable.Price)
            }
            return MathUtils.formatValueWithEps(tickSize)
        }

        if (key == SymbolInfoPanel.KEY_MinimalLot)
        {
            let minLot = currentInstrument.getMinLot(productType);
            return MathUtils.formatValueWithEps(minLot);
        }
        if (key == SymbolInfoPanel.KEY_MaximumLot)
        {
            let maxLot = currentInstrument.getMaxLot(productType);
            if (maxLot > 0 && maxLot != NumericUtils.MAXVALUE)
                return MathUtils.formatValueWithEps(maxLot);
            else
                return Resources.getResource("InstrumentDetailsPanel.None", GeneralSettings.General.Language);
        }
        if (key == SymbolInfoPanel.KEY_MaxPositionQty)
        {
            let currenRiskPlanItem = currentInstrument.RiskSettings.RiskPlanSettings.cache[productType];
            if (currenRiskPlanItem != null && currenRiskPlanItem.MaxPositionQtyPerSymbol != null && currenRiskPlanItem.MaxPositionQtyPerSymbol != -1)
                return currenRiskPlanItem.MaxPositionQtyPerSymbol.toString();
            else
                return Resources.getResource("InstrumentDetailsPanel.None", GeneralSettings.General.Language);
        }
        if (key == SymbolInfoPanel.KEY_LotStep)
            return MathUtils.formatValueWithEps(currentInstrument.LotStep);

        if (key === SymbolInfoPanel.KEY_HiLimit)
            return currentInstrument.Limits.HighLimitPriceByMeasureString(sett)
        if (key === SymbolInfoPanel.KEY_LowLimit)
            return currentInstrument.Limits.LowLimitPriceByMeasureString(sett)

        // if (key === SymbolInfoPanel.KEY_HiLimitFromPriceLimitsMsg)
        //     return currentInstrument.Limits.GetHighLimitFromPriceLimitsMessage()
        // if (key === SymbolInfoPanel.KEY_LowLimitFromPriceLimitsMsg)
        //     return currentInstrument.Limits.GetLowLimitFromPriceLimitsMessage()

        if (key == SymbolInfoPanel.KEY_SwapBuy)
        {
            if (currentInstrument.SwapBuy != 0)
                return SymbolInfoPanel.getSwapBuySell(currentInstrument.SwapBuy, currentInstrument.SwapType, sett);
            else
                return Resources.getResource("InstrumentDetailsPanel.None", GeneralSettings.General.Language);
        }
        if (key == SymbolInfoPanel.KEY_SwapSell)
        {
            if (currentInstrument.SwapSell != 0)
                return SymbolInfoPanel.getSwapBuySell(currentInstrument.SwapSell, currentInstrument.SwapType, sett);
            else
                return Resources.getResource("InstrumentDetailsPanel.None", GeneralSettings.General.Language);
        }
        if (key == SymbolInfoPanel.KEY_AllowShortPositions)
            return SymbolInfoPanel.getLocalizedAllowShortPosition(currentInstrument.IsAllowShortPositions(productType))
        if (key == SymbolInfoPanel.KEY_AllowOvernightTrading)
            return SymbolInfoPanel.getLocalizedAllowShortPosition(currentInstrument.RiskSettings.isOvernightTradingsAllowed())
        if (key === SymbolInfoPanel.KEY_NormalMarketSize)
            return currentInstrument.Level1.getNormalMarketSize
        if (key === SymbolInfoPanel.KEY_ETB)
            return currentInstrument.Level1.GetETB();

        if (key.indexOf(SymbolInfoPanel.KEY_HighLimitWarning) != -1)
            return SymbolInfoPanel.AddHighLowLimitWarningRowValueByKey(key)
        if (key.indexOf(SymbolInfoPanel.KEY_LowLimitWarning) != -1)
            return SymbolInfoPanel.AddHighLowLimitWarningRowValueByKey(key)

        if (key === SymbolInfoPanel.KEY_EXTFIELD_TRADINGUNIT)
            return currentInstrument.GetExtTradingUnit();
        if (key === SymbolInfoPanel.KEY_EXTFIELD_PRICEQUOTEUNIT)
            return currentInstrument.GetExtPriceQuoteUnit();
        if (key === SymbolInfoPanel.KEY_EXTFIELD_DELIVERYUNIT)
            return currentInstrument.GetExtDeliveryUnit();
        if (key === SymbolInfoPanel.KEY_EXTFIELD_LOTSIZE)
            return currentInstrument.GetExtLotSize();
        if (key === SymbolInfoPanel.KEY_EXTFIELD_TENDERPERIODSTARTDATE)
            return currentInstrument.GetExtTenderPeriodStartDate();
        if (key === SymbolInfoPanel.KEY_EXTFIELD_TENDERPERIODENDDATE)
            return currentInstrument.GetExtTenderPeriodEndDate();

        if (key.indexOf(SymbolInfoPanel.KEY_SMFLAG_PREFIX) != -1)           // #114474
            return currentInstrument.GetExtSMFlagByKey(key);
        //#endregion

        //#region // 3. Margin reg

        let tierData = SymbolInfoPanel.GetTierNumberFromKey(key);
        let tier = tierData.tier
        key = tierData.keyWithoutTier

        let margin = RiskPlan.GetMargin(currentInstrument, productType, tier);
        let overnightMargin = RiskPlan.GetOvernightMargin(currentInstrument, productType, tier);
        let shortMargin = RiskPlan.GetShortMargin(currentInstrument, productType, tier);
        let overnightShortMargin = RiskPlan.GetOvernightShortMargin(currentInstrument, productType, tier);
        //теперь присылает сервер,больше не считаем
        if (key === SymbolInfoPanel.KEY_Margin_ByAccount)
            return Resources.getResource("InstrumentDetailsPanel.Depend from account", GeneralSettings.General.Language);
        // case 1
        if (key === SymbolInfoPanel.KEY_Margin)
            return SymbolInfoPanel.FormatMargin(margin.Initial, margin.Warning, margin.Maintenance, VerticalPanelDataProvider, sett, productType);
        // case 2
        if (key === SymbolInfoPanel.KEY_MarginDay)
            return SymbolInfoPanel.FormatMargin(margin.Initial, margin.Warning, margin.Maintenance, VerticalPanelDataProvider, sett, productType);
        if (key === SymbolInfoPanel.KEY_MarginOvernight)
            return SymbolInfoPanel.FormatMargin(overnightMargin.Initial, overnightMargin.Warning, overnightMargin.Maintenance, VerticalPanelDataProvider, sett, productType, true);
        // case 3
        if (key === SymbolInfoPanel.KEY_MarginBuy)
            return SymbolInfoPanel.FormatMargin(margin.Initial, margin.Warning, margin.Maintenance, VerticalPanelDataProvider, sett, productType);
        if (key === SymbolInfoPanel.KEY_MarginSell)
            return SymbolInfoPanel.FormatMargin(shortMargin.Initial, shortMargin.Warning, shortMargin.Maintenance, VerticalPanelDataProvider, sett, productType);
        // case 4
        if (key === SymbolInfoPanel.KEY_MarginOvernightBuy)
            return SymbolInfoPanel.FormatMargin(overnightMargin.Initial, overnightMargin.Warning, overnightMargin.Maintenance, VerticalPanelDataProvider, sett, productType, true);
        if (key === SymbolInfoPanel.KEY_MarginOvernightSell)
            return SymbolInfoPanel.FormatMargin(overnightShortMargin.Initial, overnightShortMargin.Warning, overnightShortMargin.Maintenance, VerticalPanelDataProvider, sett, productType, true);
        if (key === SymbolInfoPanel.KEY_MarginDayBuy)
            return SymbolInfoPanel.FormatMargin(margin.Initial, margin.Warning, margin.Maintenance, VerticalPanelDataProvider, sett, productType);
        if (key === SymbolInfoPanel.KEY_MarginDaySell)
            return SymbolInfoPanel.FormatMargin(shortMargin.Initial, shortMargin.Warning, shortMargin.Maintenance, VerticalPanelDataProvider, sett, productType);
        if (key === SymbolInfoPanel.KEY_MarginInAccountCurrency)
        {
            var res = "";
            if (DataCache != null
                && DataCache.LoginUser != null
                && DataCache.LoginUser.Account != null)
                res = DataCache.LoginUser.Account.BaseCurrency;

            return currentInstrument.RiskSettings.GetInitMargin(productType).toString() + " " + res;
        }

        if (key === SymbolInfoPanel.KEY_Leverage)
            return OrderUtils.GetFormattedLeverage(margin.Leverage)

        //#endregion

        //#region // 4. Fees 
        let account = VerticalPanelDataProvider.dependency || DataCache.MainAccountNew
        let feeCurrency = CommissionItem.GetCommissionCurrency(account, currentInstrument)
        let comResult = CommissionPlan.TryGetCommissionDataValue(feeCurrency, key, currentInstrument, account);
        if (comResult)
            return comResult || "";

        if (key === SymbolInfoPanel.KEY_CustodialFee)
        {
            let data = currentInstrument.DataCache.CustodialPlans.GetCustodialDataByInstrument(account.CustodialPlanId, currentInstrument)
            return data ? data.FormattedValue() : "";
        }

        if (key === SymbolInfoPanel.KEY_ShortPositionInterest)
        {
            let interest = currentInstrument.ShortPositionInterest
            return interest ? interest + ' %'
                : Resources.getResource("InstrumentDetailsPanel.None")
        }

        if (key === SymbolInfoPanel.KEY_Historical_LongSwap)
        {
            let value = SymbolInfoPanel.getHistoricalSwap(currentInstrument, SymbolInfoPanel.KEY_Historical_LongSwap_shortKey)
            return value ? value + ' ' + Resources.getResource("InstrumentDetailsPanel.HistoricalSwap.points")
                : Resources.getResource("InstrumentDetailsPanel.None")
        }

        if (key === SymbolInfoPanel.KEY_Historical_ShortSwap)
        {
            let value = SymbolInfoPanel.getHistoricalSwap(currentInstrument, SymbolInfoPanel.KEY_Historical_ShortSwap_shortKey)
            return value ? value + ' ' + Resources.getResource("InstrumentDetailsPanel.HistoricalSwap.points")
                : Resources.getResource("InstrumentDetailsPanel.None")
        }

        if (key === SymbolInfoPanel.KEY_Historical_LastSwap)
        {
            let value = SymbolInfoPanel.getHistoricalSwap(currentInstrument, SymbolInfoPanel.KEY_Historical_LastSwap_shortKey)
            return value ? value
                : Resources.getResource("InstrumentDetailsPanel.None")
        }

        //#endregion

        //#region // 5. Custom additional info
        if (currentInstrument.InstrumentAdditionalInfo != null)
        {
            let item = currentInstrument.InstrumentAdditionalInfo[key];
            if (item)
                return item.Value.toString();
        }
        //#endregion

        // 6. Session info
        return SymbolInfoPanel.TradeSessionValue(key, VerticalPanelDataProvider, sett, true, false)
    }
    catch (ex)
    {
        ErrorInformationStorage.GetException(ex);
        //Utils.log(ex);
        return "";
    }
}
SymbolInfoPanel.commisionSplitter = commisionSplitter;
SymbolInfoPanel.commisionSplitterArr = commisionSplitterArr;

SymbolInfoPanel.FormatMargin = function (init, warn, maint, VerticalPanelDataProvider, sett, productType, isOvernight)
{
    if (!isOvernight)
        isOvernight = false

    let isShowAllKoef = init !== maint || warn !== maint;
    let currentInstrument = VerticalPanelDataProvider.Source;

    if (currentInstrument === null)
        return SymbolInfoPanel.FormatMargin_5ARG(init, warn, maint, "", isShowAllKoef);

    let exp2 = currentInstrument.Exp2;
    let marginType = RiskPlan.GetCalculateMarginTypeNullable(currentInstrument, productType);

    if (marginType === MarginTypes.MARGIN_FIXED)
        return SymbolInfoPanel.FormatMargin_5ARG(init, warn, maint, exp2, isShowAllKoef);

    // instrument CCY

    if (marginType === MarginTypes.MARGIN_FULL || marginType === MarginTypes.MARGIN_STOCK)
        return "100 %";

    if (marginType === MarginTypes.MARGIN_PRICE_LIMIT) // Limit based. 
    {
        //#28806   MD: futures margin coeficients
        //Alex Khomenko: при типе маржи MARGIN_SETLEMENT / 7 /  инит маржин должна браться не из объекта маржи, а из лимитов, тоесть нужно складывать значения полей 400 399 (*Андрей*)

        init = currentInstrument.Limits.HightLimit + currentInstrument.Limits.LowLimit;
        // #26758 Symbol info и тип маржи Limit based.
        // сервер: значение константы FIELD_PRICE_LIMIT_MESURE:  byte PERCENTEGE = 0; byte OFFSET = 1; byte DISABLE = -1;   

        let lotSize = currentInstrument.LotSize;
        if (currentInstrument.isFutureOrOption())
            lotSize = currentInstrument.FuturesTickCoast / currentInstrument.PointSize;

        if (currentInstrument.Limits.PriceLimitMeasure === PriceLimitMeasure.PERCENTAGE_SETTL_PRICE)
        {
            let k = lotSize * currentInstrument.PrevSettlementPrice * 0.01;
            return SymbolInfoPanel.FormatMargin_5ARG(init * k, warn * k, init * /*Возможно это опечатка*/maint * k, exp2, isShowAllKoef);
        }

        if (currentInstrument.Limits.PriceLimitMeasure === PriceLimitMeasure.OFFSET_SETTL_PRICE)
        {
            let k = lotSize * currentInstrument.PointSize;
            return SymbolInfoPanel.FormatMargin_5ARG(init * k, warn * k, maint * k, exp2, isShowAllKoef);
        }

        return Resources.getResource("InstrumentDetailsPanel.None");
    }

    if (marginType === MarginTypes.MARGIN_PT_RISK_MODULE)
        return Resources.getResource("InstrumentDetailsPanel.PTRM");

    if (marginType === MarginTypes.MARGIN_NSE_VAR)
    {
        if (isOvernight && RiskPlan.GetUseVarOnlyIntraday(currentInstrument, productType))
            return SymbolInfoPanel.FormatMargin_3ARG(init, warn, maint);

        let varKoef = RiskPlan.GetVarCoeffNullable(currentInstrument, productType);

        if (RiskPlan.GetVarCoeffApplyingNullable(currentInstrument, productType) === 0)
            return SymbolInfoPanel.FormatMargin_3ARG(varKoef * init, varKoef * warn, varKoef * maint);

        return SymbolInfoPanel.FormatMargin_3ARG(Math.max(init, varKoef), Math.max(warn, varKoef), Math.max(maint, varKoef));
    }

    if (marginType === MarginTypes.MARGIN_NONE)
        return "-";

    if (marginType === MarginTypes.LONG_OPTIONS_FULLY_PAID_MARGIN)
        return currentInstrument.isOptionSymbol ? SymbolInfoPanel.FormatMarginPercentage_3ARG(1, 0, 0) : SymbolInfoPanel.FormatMarginPercentage_3ARG(0, 0, 0)

    if (marginType === MarginTypes.MARGIN_CUSTOM_FIXED)
    {
        const currencyStr = RiskPlan.GetCustomCurrencyStr(currentInstrument, productType);
        return SymbolInfoPanel.FormatMargin_5ARG(init, warn, maint, currencyStr, isShowAllKoef);
    }

    let precisions = [MathUtils.getPrecision(init) - 2, MathUtils.getPrecision(warn) - 2, MathUtils.getPrecision(maint) - 2]    //  95646 запоминаем исходную точность после умножения на 100 (= -2 знака ), чтобы точность не менялась в процессе арифметической операции (JS 1 <3)
    return SymbolInfoPanel.FormatMargin_5ARG(init * 100, warn * 100, maint * 100, "%", isShowAllKoef, precisions);
}

SymbolInfoPanel.FormatMargin_5ARG = function (init, warn, maint, sign, isShowAllKoef, precisions)
{
    precisions = precisions || []

    let initFormat = MathUtils.TruncateDouble(init, precisions[0] || 2);
    let warnFormat = MathUtils.TruncateDouble(warn, precisions[1] || 2);
    let maintFormat = MathUtils.TruncateDouble(maint, precisions[2] || 2);
    if (isShowAllKoef)
    {
        if (sign)
            return initFormat + " " + sign + " / " + warnFormat + " " + sign + " / " + maintFormat + " " + sign;
        else
            return initFormat + "/" + warnFormat + "/" + maintFormat
    }
    return sign ? initFormat + " " + sign : initFormat.toString();
}

SymbolInfoPanel.FormatMargin_3ARG = function (init, warn, maint)
{
    return MathUtils.TruncateDouble(init, 2) + "% / " + MathUtils.TruncateDouble(warn, 2) + "% / " + MathUtils.TruncateDouble(maint, 2) + "%";
}

SymbolInfoPanel.FormatMarginPercentage_3ARG = function (init, warn, maint)
{
    if (init !== maint || warn !== maint)
        return MathUtils.TruncateDouble(init * 100, 2) + "% / " + MathUtils.TruncateDouble(warn * 100, 2) + "% / " + MathUtils.TruncateDouble(maint * 100, 2) + "%";

    return MathUtils.TruncateDouble(init * 100, 2) + "%";
}

SymbolInfoPanel.GetItemsID = function (VerticalPanelDataProvider, sett)
{
    let sip = SymbolInfoPanel
    let currentItems = null
    let currentInstrument = VerticalPanelDataProvider.Source

    if (currentInstrument.InstrType === InstrumentTypes.FUTURES ||
        currentInstrument.InstrType === InstrumentTypes.CFD_FUTURES ||
        currentInstrument.InstrType === InstrumentTypes.FORWARD)
    {
        // Futures.
        if (currentInstrument.InstrType === InstrumentTypes.FUTURES ||
            currentInstrument.InstrType === InstrumentTypes.CFD_FUTURES)
        {
            currentItems =
                [
                    sip.KEY_Symbol, sip.KEY_Description, sip.KEY_ExchangeTrading, sip.KEY_ExchangeMarketData,
                    sip.KEY_AssetClass, sip.KEY_Underlier, sip.KEY_DeliveryMethod,
                    sip.KEY_ContactMonth, sip.KEY_FirstTradeDate, sip.KEY_LastTradeDate,
                    sip.KEY_NoticeDate, sip.KEY_SettlementDate, sip.KEY_TradingStatus,
                    sip.KEY_AllowedOperations, sip.KEY_AllowedOrderTypes, sip.KEY_ProductType,
                    sip.KEY_DeliveryStatus, sip.KEY_CurrentSession, sip.KEY_SessionStatus, sip.KEY_NextHoliday,
                    sip.KEY_QuotiongCurrency, /*sip.KEY_ContractSize,*/ sip.KEY_LotStep,
                    sip.KEY_HiLimit, sip.KEY_LowLimit,
                    // sip.KEY_HiLimitFromPriceLimitsMsg, sip.KEY_LowLimitFromPriceLimitsMsg,
                    sip.KEY_TradingBalance,
                    sip.KEY_TradindBlockedOnSession, sip.KEY_NormalMarketSize
                ]
        }
        // Forward.
        else
        {
            currentItems =
                [
                    sip.KEY_Symbol, sip.KEY_Description, sip.KEY_ExchangeTrading, sip.KEY_ExchangeMarketData,
                    sip.KEY_AssetClass, sip.KEY_DeliveryMethod, sip.KEY_MaturityDate,
                    sip.KEY_LastTradeDate, sip.KEY_TradingStatus, sip.KEY_AllowedOperations,
                    sip.KEY_AllowedOrderTypes, sip.KEY_ProductType, sip.KEY_CurrentSession, sip.KEY_SessionStatus,
                    sip.KEY_NextHoliday, sip.KEY_QuotiongCurrency, //sip.KEY_ContractSize,
                    sip.KEY_LotStep, sip.KEY_HiLimit, sip.KEY_LowLimit,
                    // sip.KEY_HiLimitFromPriceLimitsMsg, sip.KEY_LowLimitFromPriceLimitsMsg,
                    sip.KEY_TradingBalance, sip.KEY_TradindBlockedOnSession, sip.KEY_NormalMarketSize
                ]
        }
        if (currentInstrument.ISIN)
            currentItems.push(sip.KEY_ISIN);

        SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MinimalLot, currentInstrument, currentItems)
        SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MaximumLot, currentInstrument, currentItems)
        SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MaxPositionQty, currentInstrument, currentItems)
        SymbolInfoPanel.ProcessItemByProductType(sip.KEY_AllowShortPositions, currentInstrument, currentItems)

        SymbolInfoPanel.AddTickSizeCostItems(currentItems, VerticalPanelDataProvider, sett)
        SymbolInfoPanel.AddTradeSessionItems(currentItems, VerticalPanelDataProvider, sett)
        SymbolInfoPanel.AddMaintenanceItems(currentItems, VerticalPanelDataProvider)
        SymbolInfoPanel.AddFeesItems(currentItems, VerticalPanelDataProvider, sett)
        SymbolInfoPanel.AddRebatesItems(currentItems, VerticalPanelDataProvider, sett)
    }
    else if (currentInstrument.InstrType === InstrumentTypes.OPTIONS)
    {
        // Options.
        currentItems =
            [
                sip.KEY_Symbol, sip.KEY_Description, sip.KEY_ExchangeTrading, sip.KEY_ExchangeMarketData,
                sip.KEY_AssetClass, sip.KEY_Underlier, sip.KEY_ContactMonth,
                sip.KEY_FirstTradeDate, sip.KEY_LastTradeDate, sip.KEY_SettlementDate,
                sip.KEY_StrikePrice, sip.KEY_TradingStatus, sip.KEY_AllowedOperations,
                sip.KEY_AllowedOrderTypes, sip.KEY_ProductType, sip.KEY_DeliveryStatus,
                sip.KEY_CurrentSession, sip.KEY_SessionStatus, sip.KEY_NextHoliday, sip.KEY_QuotiongCurrency,
                // sip.KEY_ContractSize, 
                sip.KEY_LotStep, sip.KEY_HiLimit, sip.KEY_LowLimit,
                // sip.KEY_HiLimitFromPriceLimitsMsg, sip.KEY_LowLimitFromPriceLimitsMsg,
                sip.KEY_TradingBalance, sip.KEY_TradindBlockedOnSession,
                sip.KEY_NormalMarketSize,
                sip.KEY_ExerciseStyle
            ]
        if (currentInstrument.ISIN)
            currentItems.push(sip.KEY_ISIN);

        SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MinimalLot, currentInstrument, currentItems)
        SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MaximumLot, currentInstrument, currentItems)
        SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MaxPositionQty, currentInstrument, currentItems)
        SymbolInfoPanel.ProcessItemByProductType(sip.KEY_AllowShortPositions, currentInstrument, currentItems)

        SymbolInfoPanel.AddTickSizeCostItems(currentItems, VerticalPanelDataProvider, sett)
        SymbolInfoPanel.AddTradeSessionItems(currentItems, VerticalPanelDataProvider, sett)
        SymbolInfoPanel.AddMaintenanceItems(currentItems, VerticalPanelDataProvider)
        SymbolInfoPanel.AddFeesItems(currentItems, VerticalPanelDataProvider, sett)
        SymbolInfoPanel.AddRebatesItems(currentItems, VerticalPanelDataProvider, sett)
    }
    else if (currentInstrument.InstrType === InstrumentTypes.BOND)
    {
        // для бондов пока используем поля, отображаемые для акций + специфичные для бондов
        currentItems =
            [
                sip.KEY_Symbol, sip.KEY_Description, sip.KEY_ExchangeTrading, sip.KEY_ExchangeMarketData,
                sip.KEY_AssetClass, sip.KEY_TradingStatus, sip.KEY_AllowedOperations,
                sip.KEY_AllowedOrderTypes, sip.KEY_ProductType, sip.KEY_CurrentSession, sip.KEY_SessionStatus,
                sip.KEY_NextHoliday, sip.KEY_QuotiongCurrency, sip.KEY_LotSize,
                sip.KEY_ContractMultiplier, sip.KEY_LotStep,
                sip.KEY_HiLimit, sip.KEY_LowLimit,
                // sip.KEY_HiLimitFromPriceLimitsMsg, sip.KEY_LowLimitFromPriceLimitsMsg,
                sip.KEY_TradingBalance, sip.KEY_SettlementDate,
                sip.KEY_MaturityDate, sip.KEY_FaceValue, sip.KEY_CouponCycle,
                sip.KEY_CouponRate, sip.KEY_YTM, sip.KEY_ACCRUED_INTEREST,
                sip.KEY_PREV_PAYMENT_DATE, sip.KEY_NEXT_PAYMENT_DATE, sip.KEY_TradindBlockedOnSession,
                sip.KEY_NormalMarketSize, sip.KEY_DAYS_PER_MONTH, sip.KEY_DAYS_PER_YEAR
            ]
        if (currentInstrument.ISIN)
            currentItems.push(sip.KEY_ISIN);

        SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MinimalLot, currentInstrument, currentItems)
        SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MaximumLot, currentInstrument, currentItems)
        SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MaxPositionQty, currentInstrument, currentItems)
        SymbolInfoPanel.ProcessItemByProductType(sip.KEY_AllowShortPositions, currentInstrument, currentItems)

        SymbolInfoPanel.AddTickSizeCostItems(currentItems, VerticalPanelDataProvider, sett)
        SymbolInfoPanel.AddTradeSessionItems(currentItems, VerticalPanelDataProvider, sett)
        SymbolInfoPanel.AddMaintenanceItems(currentItems, VerticalPanelDataProvider)
        SymbolInfoPanel.AddFeesItems(currentItems, VerticalPanelDataProvider, sett)
        SymbolInfoPanel.AddRebatesItems(currentItems, VerticalPanelDataProvider, sett)
    }
    else if (currentInstrument.InstrType === InstrumentTypes.TBILL)
    {
        currentItems =
            [
                // для TBILL пока используем поля, отображаемые для акций
                sip.KEY_Symbol, sip.KEY_Description, sip.KEY_ExchangeTrading, sip.KEY_ExchangeMarketData,
                sip.KEY_AssetClass, sip.KEY_TradingStatus, sip.KEY_AllowedOperations,
                sip.KEY_AllowedOrderTypes, sip.KEY_ProductType, sip.KEY_CurrentSession, sip.KEY_SessionStatus,
                sip.KEY_NextHoliday, sip.KEY_QuotiongCurrency, sip.KEY_LotSize,
                sip.KEY_ContractMultiplier, sip.KEY_LotStep, sip.KEY_HiLimit, sip.KEY_LowLimit,
                // sip.KEY_HiLimitFromPriceLimitsMsg, sip.KEY_LowLimitFromPriceLimitsMsg,
                sip.KEY_TradingBalance, sip.KEY_SettlementDate,
                // специфичные для TBill
                sip.KEY_MaturityDate, sip.KEY_FaceValue, sip.KEY_Yield,
                sip.KEY_TradindBlockedOnSession, sip.KEY_NormalMarketSize
            ]
        if (currentInstrument.ISIN)
            currentItems.push(sip.KEY_ISIN);

        SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MinimalLot, currentInstrument, currentItems)
        SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MaximumLot, currentInstrument, currentItems)
        SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MaxPositionQty, currentInstrument, currentItems)
        SymbolInfoPanel.ProcessItemByProductType(sip.KEY_AllowShortPositions, currentInstrument, currentItems)

        SymbolInfoPanel.AddTickSizeCostItems(currentItems, VerticalPanelDataProvider, sett)
        SymbolInfoPanel.AddTradeSessionItems(currentItems, VerticalPanelDataProvider, sett)
        SymbolInfoPanel.AddMaintenanceItems(currentItems, VerticalPanelDataProvider)
        SymbolInfoPanel.AddFeesItems(currentItems, VerticalPanelDataProvider, sett)
        SymbolInfoPanel.AddRebatesItems(currentItems, VerticalPanelDataProvider, sett)
    }
    else if (currentInstrument.InstrType === InstrumentTypes.SPOT)
    {
        currentItems =
            [
                sip.KEY_Symbol, sip.KEY_Description, sip.KEY_ExchangeTrading, sip.KEY_ExchangeMarketData,
                sip.KEY_AssetClass, sip.KEY_DeliveryMethod, sip.KEY_TradingStatus,
                sip.KEY_AllowedOperations, sip.KEY_AllowedOrderTypes, sip.KEY_ProductType,
                sip.KEY_TradingBalance, sip.KEY_MaturityDate, sip.KEY_DeliveryStatus,
                sip.KEY_CurrentSession, sip.KEY_SessionStatus, sip.KEY_NextHoliday, sip.KEY_QuotiongCurrency,
                // sip.KEY_ContractSize, 
                sip.KEY_LotStep, sip.KEY_HiLimit, sip.KEY_LowLimit,
                // sip.KEY_HiLimitFromPriceLimitsMsg, sip.KEY_LowLimitFromPriceLimitsMsg,
                sip.KEY_TradingBalance, sip.KEY_TradindBlockedOnSession,
                sip.KEY_NormalMarketSize
            ]
        if (currentInstrument.ISIN)
            currentItems.push(sip.KEY_ISIN);

        SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MinimalLot, currentInstrument, currentItems)
        SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MaximumLot, currentInstrument, currentItems)
        SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MaxPositionQty, currentInstrument, currentItems)
        SymbolInfoPanel.ProcessItemByProductType(sip.KEY_AllowShortPositions, currentInstrument, currentItems)

        SymbolInfoPanel.AddTickSizeCostItems(currentItems, VerticalPanelDataProvider, sett)
        SymbolInfoPanel.AddTradeSessionItems(currentItems, VerticalPanelDataProvider, sett)
        SymbolInfoPanel.AddMaintenanceItems(currentItems, VerticalPanelDataProvider)
        SymbolInfoPanel.AddFeesItems(currentItems, VerticalPanelDataProvider, sett)
        SymbolInfoPanel.AddRebatesItems(currentItems, VerticalPanelDataProvider, sett)
    }
    else if (currentInstrument.InstrType == InstrumentTypes.CORPORATE)
    {
        currentItems = [
            sip.KEY_Symbol,
            sip.KEY_Description,
            sip.KEY_ExchangeMarketData,
            sip.KEY_ExchangeTrading,
            sip.KEY_AssetClass,
            sip.KEY_TradingStatus,
            sip.KEY_AllowedOperations,
            sip.KEY_AllowedOrderTypes,
            sip.KEY_ProductType,
            sip.KEY_CurrentSession,
            sip.KEY_SessionStatus,
            sip.KEY_NextHoliday,
            sip.KEY_QuotiongCurrency,
            sip.KEY_ContractMultiplier,
            sip.KEY_LotStep,
            sip.KEY_HiLimit,
            sip.KEY_LowLimit,
            // sip.KEY_HiLimitFromPriceLimitsMsg, 
            // sip.KEY_LowLimitFromPriceLimitsMsg,
            sip.KEY_TradingBalance,
            sip.KEY_SettlementDate,
            sip.KEY_TradindBlockedOnSession,
            sip.KEY_NormalMarketSize,
            //sip.KEY_Exp1
        ];

        SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MinimalLot, currentInstrument, currentItems);
        SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MaximumLot, currentInstrument, currentItems);
        SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MaxPositionQty, currentInstrument, currentItems);
        SymbolInfoPanel.ProcessItemByProductType(sip.KEY_AllowShortPositions, currentInstrument, currentItems);

        SymbolInfoPanel.AddTickSizeCostItems(currentItems, VerticalPanelDataProvider, sett);
        SymbolInfoPanel.AddTradeSessionItems(currentItems, VerticalPanelDataProvider, sett);
        SymbolInfoPanel.AddMaintenanceItems(currentItems, VerticalPanelDataProvider);
        SymbolInfoPanel.AddFeesItems(currentItems, VerticalPanelDataProvider, sett);
        SymbolInfoPanel.AddRebatesItems(currentItems, VerticalPanelDataProvider, sett);
        if (currentInstrument.ISIN)
            currentItems.push(sip.KEY_ISIN);

        if (currentInstrument.MaturityDate)
            currentItems.push(sip.KEY_MaturityDate);
    }
    else
    {
        // "Forex-CFD-Equitis-Stock-Forward-Index-ETF-N_A";
        currentItems =
            [
                sip.KEY_Symbol, sip.KEY_Description, sip.KEY_ExchangeTrading, sip.KEY_ExchangeMarketData,
                sip.KEY_AssetClass, sip.KEY_TradingStatus, sip.KEY_AllowedOperations,
                sip.KEY_AllowedOrderTypes, sip.KEY_ProductType, sip.KEY_CurrentSession, sip.KEY_SessionStatus,
                sip.KEY_NextHoliday, sip.KEY_QuotiongCurrency, sip.KEY_ContractMultiplier,
                sip.KEY_LotStep, sip.KEY_HiLimit, sip.KEY_LowLimit,
                // sip.KEY_HiLimitFromPriceLimitsMsg, sip.KEY_LowLimitFromPriceLimitsMsg,
                sip.KEY_TradingBalance, sip.KEY_SettlementDate, sip.KEY_TradindBlockedOnSession,
                sip.KEY_NormalMarketSize
            ]

        SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MinimalLot, currentInstrument, currentItems)
        SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MaximumLot, currentInstrument, currentItems)
        SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MaxPositionQty, currentInstrument, currentItems)
        SymbolInfoPanel.ProcessItemByProductType(sip.KEY_AllowShortPositions, currentInstrument, currentItems)

        if (currentInstrument.InstrType === InstrumentTypes.SPREADBET)
        {
            let index = currentItems.indexOf(sip.KEY_LotSize)
            if (index !== -1)
                currentItems.splice(index, 1, sip.KEY_TickCoast)
        }

        if (currentInstrument.InstrType === InstrumentTypes.INDICIES)
        {
            let tradingBalanceIdx = currentItems.indexOf(sip.KEY_TradingBalance)
            if (tradingBalanceIdx !== -1)
                currentItems.splice(tradingBalanceIdx, 1)

            let lotSizeIdx = currentItems.indexOf(sip.KEY_LotSize)
            if (lotSizeIdx !== -1)
                currentItems.splice(lotSizeIdx, 1)

            let tradingBlockedOnSessionIdx = currentItems.indexOf(sip.KEY_TradindBlockedOnSession)
            if (tradingBlockedOnSessionIdx !== -1)
                currentItems.splice(tradingBlockedOnSessionIdx, 1)
        }

        // #59361 В раздел General добавляем поле Asset для инструментов
        // с типами Equities/Equities CFD выводим значение из настройки Asset на инструменте.
        // ETF + 
        if ((currentInstrument.InstrType === InstrumentTypes.EQUITIES || currentInstrument.InstrType == InstrumentTypes.ETF) &&
            !currentInstrument.HideAsset &&
            currentInstrument.Exp1 != currentInstrument.ShortName)
            currentItems.push(sip.KEY_Exp1)

        SymbolInfoPanel.AddTickSizeCostItems(currentItems, VerticalPanelDataProvider, sett)
        SymbolInfoPanel.AddTradeSessionItems(currentItems, VerticalPanelDataProvider, sett)
        SymbolInfoPanel.AddMaintenanceItems(currentItems, VerticalPanelDataProvider)
        SymbolInfoPanel.AddFeesItems(currentItems, VerticalPanelDataProvider, sett)
        SymbolInfoPanel.AddRebatesItems(currentItems, VerticalPanelDataProvider, sett)

        if (currentInstrument.InstrType === InstrumentTypes.INDICIES)
        {
            let idx = currentItems.indexOf(sip.KEY_TradingBalance)
            if (idx !== -1)
                currentItems.splice(idx, 1)

            idx = currentItems.indexOf(sip.KEY_LotSize)
            if (idx !== -1)
                currentItems.splice(idx, 1)
        }
        if (currentInstrument.ISIN)
            currentItems.push(sip.KEY_ISIN);
    }

    SymbolInfoPanel.AddHighLowLimitWarning(currentItems, currentInstrument, true)   // https://tp.traderevolution.com/entity/108864

    if (currentInstrument.Industry ||
        (currentInstrument.ForwardBaseInstrument && currentInstrument.ForwardBaseInstrument.Industry))
        currentItems.push(sip.KEY_Industry);

    if (currentInstrument.Sector ||
        (currentInstrument.ForwardBaseInstrument && currentInstrument.ForwardBaseInstrument.Sector))
        currentItems.push(sip.KEY_Sector);

    if (currentInstrument.RiskSettings.isOvernightTradingsAllowed() !== null)
        currentItems.push(sip.KEY_AllowOvernightTrading);

    // custom instrument information
    let insAddInfo = currentInstrument.InstrumentAdditionalInfo
    for (let key in insAddInfo)
        currentItems.push(insAddInfo[key].NameKey)

    if (currentInstrument.Level1.GetETB() !== Level1Calculator.NAString)
        currentItems.push(SymbolInfoPanel.KEY_ETB);

    if (currentInstrument.GetExtTradingUnit())
        currentItems.push(SymbolInfoPanel.KEY_EXTFIELD_TRADINGUNIT);

    if (currentInstrument.GetExtPriceQuoteUnit())
        currentItems.push(SymbolInfoPanel.KEY_EXTFIELD_PRICEQUOTEUNIT);

    if (currentInstrument.GetExtDeliveryUnit())
        currentItems.push(SymbolInfoPanel.KEY_EXTFIELD_DELIVERYUNIT);

    if (currentInstrument.GetExtLotSize())
        currentItems.push(SymbolInfoPanel.KEY_EXTFIELD_LOTSIZE);

    if (currentInstrument.GetExtTenderPeriodStartDate())
        currentItems.push(SymbolInfoPanel.KEY_EXTFIELD_TENDERPERIODSTARTDATE);

    if (currentInstrument.GetExtTenderPeriodEndDate())
        currentItems.push(SymbolInfoPanel.KEY_EXTFIELD_TENDERPERIODENDDATE);

    let SMFlags = currentInstrument.GetExtSMFlags();    // #114474
    for (let flag in SMFlags)
        currentItems.push(SymbolInfoPanel.KEY_SMFLAG_PREFIX + flag);

    return SymbolInfoPanel.filterByInstrumentTradableItemsID(
        currentItems,
        VerticalPanelDataProvider,
        sett)
}

SymbolInfoPanel.prototype.GetDynamicItems = function (VerticalPanelDataProvider, sett)
{
    return SymbolInfoPanel.GetDynamicItemsStaticFull(VerticalPanelDataProvider, sett, this.sessionTypesItem, this.marginTypesItem, this.feeTypesItem, this.tickSizeCostTypesItem, this.rebateTypesItem, this.dynamicTypesItem);
}

/// <summary>
/// описание айтемов которые добавляются динамически, просится при смене инструмента
/// </summary>
/// <returns>добавляются к getDataItems - которое просится 1 раз при прогрузке панели</returns>
SymbolInfoPanel.GetDynamicItemsStaticFull = function (VerticalPanelDataProvider, sett,
    sessionTypesItem,
    marginTypesItem,
    feeTypesItem,
    tickSizeCostTypesItem,
    rebateTypesItem,
    dynamicTypesItem)
{
    // здесь возвращаем ВСЕ которые будут отображаться
    let sessionInfo
    let res = []

    let ins = VerticalPanelDataProvider.Source
    if (!ins) return null

    let exFeeTypeName = null
    let displayFeeTypeName = null

    SymbolInfoPanel.AddHighLowLimitWarning(res, ins)    // https://tp.traderevolution.com/entity/108864

    // #region tickCostSizeItems

    // добавляем описание на те которые будут отображаться
    let tickCostSizeItems = []
    SymbolInfoPanel.AddTickSizeCostItems(tickCostSizeItems, VerticalPanelDataProvider, sett)

    for (let i = 0, len = tickCostSizeItems.length; i < len; i++)
    {
        let tickCostSizeItem = tickCostSizeItems[i]
        let key = tickCostSizeItem.split(SymbolInfoPanel.commisionSplitterArr)[0]
        // имя которое отображается в таблице составное:
        let locKey = displayFeeTypeName = key
        // если у предыдущей строки имя такое же не показываем
        if (exFeeTypeName === locKey)
            displayFeeTypeName = ''

        let item = SymbolInfoPanel.createItem(SymbolInfoPanel.KEY_tradingInfo, i + 150, displayFeeTypeName, tickCostSizeItem)
        // copy properties from feeTypesItem 
        if (tickSizeCostTypesItem)
        {
            let tickCostSizePropItem = tickSizeCostTypesItem[locKey]
            if (tickCostSizePropItem)
                item.Apply(tickCostSizePropItem)
        }
        res.push(item)
        exFeeTypeName = locKey
    }

    // #endregion tickCostSizeItems

    // #region margin 
    let createItem = function (localizationKey, id, sortIndex)
    {
        return SymbolInfoPanel.createItem(SymbolInfoPanel.KEY_marginReg, sortIndex + 70, localizationKey, id)
    }

    let tierLocalized = Resources.getResource('InstrumentDetailsPanel.Tier') + ' '
    for (let i = 0; i < SymbolInfoPanel.margins.length; i++)
    {
        let staticMI = SymbolInfoPanel.margins[i];

        if (SymbolInfoPanel.IsMarginRequirements(staticMI))
        {
            let ids = SymbolInfoPanel.GetMarginRequirementsItemsFromKey(ins, staticMI),
                productType = SymbolInfoPanel.GetProductTypeFromMarginKey(staticMI),
                isTierMargin = RiskPlan.IsTierMargin(ins, productType),
                isLeverage = SymbolInfoPanel.IsLeverageKey(staticMI),
                locKey = isTierMargin ? ' ' + Resources.getResource(staticMI) : (isLeverage ? SymbolInfoPanel.GetLeverageKeyResource(ins, staticMI) : staticMI),
                needSkip = SymbolInfoPanel.NeedSkipEmptyMargin(ins, productType, locKey)     // #113777

            for (let j = 0; j < ids.length; j++)
            {
                let id = needSkip ? '' : ids[j],
                    locKeyFinally = locKey
                if (isLeverage)
                    locKeyFinally = locKey + ' ' + (ids.length > 1 ? (j + 1) : '')
                else
                    if (isTierMargin)
                        locKeyFinally = tierLocalized + (j + 1) + locKey

                res.push(createItem(locKeyFinally, id, i)); // ids[j] ай-ди = ключ локализации + productType + tier#
            }

        }
        else
        {
            let item = createItem(staticMI, staticMI, i); // staticMI - ай-ди - ключ локализации

            // copy properties from marginTypesItem 
            if (marginTypesItem && marginTypesItem[staticMI])
                item.Apply(marginTypesItem[staticMI]);
            res.push(item);
        }
    }
    // #endregion

    // #region fees
    let itemsForFeesIds = [];
    SymbolInfoPanel.AddFeesItems(itemsForFeesIds, VerticalPanelDataProvider, sett);
    exFeeTypeName = null;
    displayFeeTypeName = null;

    for (let i = 0; i < itemsForFeesIds.length; i++)
    {
        let key = itemsForFeesIds[i].split(SymbolInfoPanel.commisionSplitterArr),
            allS = CommissionOperationType.ALL,
            suff = CommissionItem.OperationTypeSuffix,
            locKey = displayFeeTypeName = (key.length == 4 && key[1] != allS) ? key[0] + suff[key[1]] : key[0];   // имя которое отображается в таблице составное: имя + CommissionOperationType, если тип ALL то скипаем вторую часть, иначе добавляем

        if (exFeeTypeName == locKey)
            displayFeeTypeName = ""; // если у предыдущей строки имя такое же не показываем

        let item = SymbolInfoPanel.createItem(SymbolInfoPanel.KEY_fees, i + 90, displayFeeTypeName, itemsForFeesIds[i])
        res.push(item);

        exFeeTypeName = locKey;
    }
    //#endregion

    // #region Sessions

    let tradingSessionsList = ins.GetTradingSessionsList()
    for (let i = 0, len = tradingSessionsList.length; i < len; i++)
    {
        let session = tradingSessionsList[i]
        let sessTypeName = SymbolInfoPanel.GetTradeSessionTypeName(session.DayPeriod)

        let item = new VerticalPanelDataProviderItem()
        item.Group = SymbolInfoPanel.KEY_sessionInfo
        item.SortIndex = SymbolInfoPanel.GetSessionSortIndex(session.DayPeriod)
        // андрюшин бред, что выводится имя один раз - hsa - уже такого нет
        item.LocalizationKey = SymbolInfoPanel.TradeSessionValue(i.toString() + '.' + sessTypeName, VerticalPanelDataProvider, sett, false, true)
        item.Id = i.toString() + '.' + sessTypeName, // ай-ди сессии
            item.tooltipKey = Resources.GetToolTipKey(sessTypeName)

        // copy properties from session group item
        if (sessionTypesItem && sessionTypesItem[sessTypeName])
            item.Apply(sessionTypesItem[sessTypeName])

        res.push(item)
    }

    // #endregion Sessions

    //#region Custom information

    let customRes = []
    for (var instrumentAdditionalInfoItem in ins.InstrumentAdditionalInfo)
    {
        instrumentAdditionalInfoItem = ins.InstrumentAdditionalInfo[instrumentAdditionalInfoItem];
        let item = new VerticalPanelDataProviderItem();
        item.Group = instrumentAdditionalInfoItem.GroupInfo;
        item.SortIndex = instrumentAdditionalInfoItem.SortIndex; // sort index inside group
        item.LocalizationKey = instrumentAdditionalInfoItem.NameKey;
        item.Id = instrumentAdditionalInfoItem.NameKey;
        item.tooltipKey = instrumentAdditionalInfoItem.ToolTipKey;
        item.visible = instrumentAdditionalInfoItem.Visible;
        let dynamicItem = null;
        if (dynamicTypesItem != null)
        {
            dynamicItem = dynamicTypesItem[instrumentAdditionalInfoItem.NameKey];
            if (dynamicItem)
                item.Apply(dynamicItem);
            dynamicTypesItem[instrumentAdditionalInfoItem.NameKey] = item;
        }
        customRes.push(item);
    }
    res = res.concat(customRes)

    //#endregion Custom information

    return res
}

SymbolInfoPanel.GetTierNumberFromKey = function (key)
{
    let tier = null

    let keyParts = key.split(SymbolInfoPanel.MARGIN_TIER_SEPARATOR)
    if (keyParts.length > 1)
        tier = parseInt(keyParts[1])

    key = keyParts[0]       // tier из ключа убираем

    return {
        tier: tier,
        keyWithoutTier: key
    }
}

SymbolInfoPanel.IsMarginRequirements = function (key)
{
    let marginFields =
        [SymbolInfoPanel.KEY_Margin,
        SymbolInfoPanel.KEY_MarginIntraday,
        SymbolInfoPanel.KEY_MarginDelivery,
        SymbolInfoPanel.KEY_Leverage,
        SymbolInfoPanel.KEY_LeverageIntraday,
        SymbolInfoPanel.KEY_LeverageDelivery,
        SymbolInfoPanel.KEY_MarginDay,
        SymbolInfoPanel.KEY_MarginOvernight]

    return key && marginFields.indexOf(key) !== -1
}

SymbolInfoPanel.SessionSubTypeToString = function (sessionSubType)
{
    switch (sessionSubType)
    {
        case SubTypes.AUCTION_PRE_CROSS:
            return 'AUCTION_PRE_CROSS'
        case SubTypes.AUCTION_FREEZE:
            return 'AUCTION_FREEZE'
        case SubTypes.CONTINUOUS:
            return 'CONTINUOUS'
        case SubTypes.IN_BETWEEN_CLEARING:
            return 'IN_BETWEEN_CLEARING'
        case SubTypes.SETTLEMENT_CLEARING:
            return 'SETTLEMENT_CLEARING'
    }

    throw new Error();
}

// TODO. Refactor this bullshit: showSessionTime, showSessionName.
SymbolInfoPanel.TradeSessionValue = function (key, VerticalPanelDataProvider, sett, showSessionTime, showSessionName)
{
    let currentInstrument = VerticalPanelDataProvider.Source
    let TradingSessionsList = currentInstrument.GetTradingSessionsList()

    // флаг - если только одна сессия дефалтовая(0:0 - 23:59)
    let isMainDefault = false

    if (TradingSessionsList.length === 1)
    {
        let firstSess = TradingSessionsList[0]
        let beginDate = new Date(firstSess.Begin)

        if (firstSess.End - firstSess.Begin === TimeSpanPeriods.MillisPerDay &&
            // TODO. OK? Local hours/minutes?
            beginDate.getHours() === 0 && beginDate.getMinutes() === 0)
            isMainDefault = true
    }

    let holiday = currentInstrument.GetTodayHoliday()
    for (let i = 0, len = TradingSessionsList.length; i < len; i++)
    {
        let ts = TradingSessionsList[i]

        if (showSessionTime && holiday && holiday.HolidayType === DayType.NOT_WORKING)
            return Resources.getResource('InstrumentDetailsPanel.non trading day')

        if (key.indexOf('.') === -1 || key.split('.')[0] != i)
            continue

        let ServerTodayDate = ts.ServerTodayDate
        let shortDay = holiday && holiday.IsShorted()

        let val = ''

        if (showSessionTime)
        {
            if (isMainDefault)
            {
                val =
                    DateTimeUtils.FormatToTime(new Date(1970, 0, 1, 0, 0), true) +
                    ' - ' +
                    DateTimeUtils.FormatToTime(new Date(1970, 0, 1, 23, 59), true)
            }
            else
            {
                let dc = currentInstrument.DataCache

                let from = ts.GetBegin(ServerTodayDate.getDay())
                let to = ts.GetEnd(ServerTodayDate.getDay())

                let dt = new Date(from).getTimezoneOffset() * 60000 - DateTimeUtils.TIME_UTC_OFFSET;
                from += dt;
                to += dt;

                val = DateTimeUtils.FormatToTime(new Date(from), true) + ' - ' + DateTimeUtils.FormatToTime(new Date(to), true)
            }
        }

        if (showSessionName)
        {
            val +=
                ts.Description ||
                Resources.getResource('InstrumentDetailsPanel.' + SymbolInfoPanel.SessionSubTypeToString(ts.SubType))

            if (holiday && shortDay)
                val += ' (' + Resources.getResource('InstrumentDetailsPanel.shortened') + ')'
        }

        return val
    }

    return null
}

SymbolInfoPanel.GetSessionSortIndex = function (dayPeriod)
{
    switch (dayPeriod)
    {
        case DayPeriods.PRE_OPEN:
            return 200
        case DayPeriods.MAIN:
            return 300
        case DayPeriods.POST_CLOSE:
            return 400
        case DayPeriods.BEFORE_MARKET:
            return 100
        case DayPeriods.AFTER_MARKET:
            return 500
        default:
            return -1
    }
}

/// <summary>
/// на смену Source отписка и пр.
/// </summary>
SymbolInfoPanel.DisposeSource = function (VerticalPanelDataProvider)
{
    // отписка на смену и прочее. // пока не надо
}

/// определяем какие айтемы отображать (для айтемов, количество которых зависит от ProductType)
SymbolInfoPanel.ProcessItemByProductType = function (key, instrument, currentItems)
{
    if (instrument == null || instrument.RiskSettings.RiskPlanSettings.availableProductTypes.length <= 1)
        currentItems.push(key)
    else
    {
        let aPT = instrument.RiskSettings.RiskPlanSettings.availableProductTypes
        for (let i = 0; i < aPT.length; i++)
        {
            var newKey = key;
            var suffix = SymbolInfoPanel.GetProductTypeSuffix(aPT[i]);
            if (suffix)
                newKey = key + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + suffix;
            currentItems.push(newKey);
        }
    }
}

SymbolInfoPanel.getLocalizedAllowShortPosition = function (isAllowed)
{
    return Resources.getResource(isAllowed ?
        'InstrumentDetailsPanel.Allow short positions' :
        'InstrumentDetailsPanel.Not allow short positions')
}

SymbolInfoPanel.GetProductTypeSuffix = function (type)
{
    const productTypeKeys = Enum.TakeKeysFromEnum(ProductType);
    for (let key of productTypeKeys)
        if (ProductType[key] == type)
            return key
}

/// <summary>
/// Adds keys for TickSizeCost Items, которые показываются СЕЙЧАС
/// </summary>
SymbolInfoPanel.AddTickSizeCostItems = function (itemsForTable, VerticalPanelDataProvider, sett)
{
    let instr = VerticalPanelDataProvider.Source;
    if (instr.VariableTickList == null || instr.VariableTickList.length <= 1)
    {
        if (instr.QuotingType == QuotingType.TickCost_TickSize)
            itemsForTable.push(SymbolInfoPanel.KEY_TickCoast);
        itemsForTable.push(SymbolInfoPanel.KEY_TickSize);
    }
    else
    {
        if (instr.QuotingType != QuotingType.LotSize)
            SymbolInfoPanel.PrepareTickSizeCostItems(itemsForTable, instr, SymbolInfoPanel.KEY_TickCoast);
        SymbolInfoPanel.PrepareTickSizeCostItems(itemsForTable, instr, SymbolInfoPanel.KEY_TickSize);
    }
    if (instr.QuotingType == QuotingType.LotSize)
        itemsForTable.push(SymbolInfoPanel.KEY_LotSize);
}


/// <summary>
/// Adds keys for sessions, которые показываются СЕЙЧАС
/// </summary>
SymbolInfoPanel.AddTradeSessionItems = function (itemsForTable, VerticalPanelDataProvider, sett)
{
    // здесь возвращаем только те что СЕЙЧАС отображаем
    let currentInstrument = VerticalPanelDataProvider.Source;

    let tradingSessionsList = currentInstrument.GetTradingSessionsList()

    for (let i = 0; i < tradingSessionsList.length; i++)
    {
        let ts = tradingSessionsList[i];
        let descr = SymbolInfoPanel.GetTradeSessionTypeName(ts.DayPeriod);

        itemsForTable.push(i.toString() + "." + descr); // значение
    }
}

/// <summary>
/// Adds keys for Maintenance Items, которые показываются СЕЙЧАС
/// </summary>
SymbolInfoPanel.AddMaintenanceItems = function (itemsForTable, VerticalPanelDataProvider)
{
    // здесь возвращаем только те что СЕЙЧАС отображаем

    let currentInstrument = VerticalPanelDataProvider.Source;
    RiskPlan.GetAvailableProductTypes(currentInstrument).map(function (type)
    {
        SymbolInfoPanel.AddMaintenanceItems_(itemsForTable, currentInstrument, type);
    })
    // for (let type in RiskPlan.GetAvailableProductTypes(currentInstrument))
    //     SymbolInfoPanel.AddMaintenanceItems_(itemsForTable, currentInstrument, type);
}

SymbolInfoPanel.GetLeverageItemsFromProductType = function (instrument, productType)
{
    let items = [],
        itemsN = SymbolInfoPanel.GetLeverageLengthFromProductType(instrument, productType)

    for (let i = 0; i < itemsN; i++)
        items.push(SymbolInfoPanel.KEY_Leverage + SymbolInfoPanel.MARGIN_TIER_SEPARATOR + i)

    return items
}

SymbolInfoPanel.GetLeverageLengthFromProductType = function (instrument, productType)
{
    let items = [],
        isHedgingNettingType = instrument.isHedgingNettingType(),
        leverages = RiskPlan.GetLeverages(instrument, productType),
        itemsN = leverages ? (isHedgingNettingType || !leverages.length ? leverages.length : 1) : 0

    return itemsN
}

SymbolInfoPanel.GetLeverageKeyResource = function (instrument, key)
{
    let onlyOne = RiskPlan.AvailableOnlyOneProductType(instrument),
        keyFinally = onlyOne ? SymbolInfoPanel.KEY_Leverage : key

    return Resources.getResource(keyFinally)
}

SymbolInfoPanel.GetMarginRequirementsItemsFromProductType = function (key, instrument, productType)
{
    let items = [],
        itemsN = RiskPlan.GetMarginCoeficientListLength(instrument, productType)

    for (let i = 0; i < itemsN; i++)
        items.push(key + SymbolInfoPanel.MARGIN_TIER_SEPARATOR + i)

    return items
}

SymbolInfoPanel.GetMarginRequirementsItemsFromKey = function (instrument, key)
{
    let productType = SymbolInfoPanel.GetProductTypeFromMarginKey(key)

    if (!RiskPlan.IsProductTypeAvailableForInstrument(instrument, productType.toString()))
        return []

    let productTypeSuffix = productType != ProductType.General ? (SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + (key == SymbolInfoPanel.KEY_MarginIntraday ? SymbolInfoPanel.PRODUCT_TYPE_INTRADAY : SymbolInfoPanel.PRODUCT_TYPE_DELIVERY)) : '',
        items = [],
        itemsN = RiskPlan.GetMarginCoeficientListLength(instrument, productType)

    if (SymbolInfoPanel.IsLeverageKey(key))
    {
        key = SymbolInfoPanel.KEY_Leverage
        itemsN = SymbolInfoPanel.GetLeverageLengthFromProductType(instrument, productType)

        if (RiskPlan.GetAvailableProductTypes(instrument).length == 1)
            productTypeSuffix = ''

        if (productTypeSuffix)
            productTypeSuffix = SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.ConvertProductTypeToSymbolInfoSuffix(productType)
    }
    else
        if (productType != ProductType.General)
            key = SymbolInfoPanel.KEY_Margin

    for (let i = 0; i < itemsN; i++)
    {
        let keyWithProductType = key + SymbolInfoPanel.MARGIN_TIER_SEPARATOR + i + productTypeSuffix

        items.push(keyWithProductType);
    }

    return items
}

SymbolInfoPanel.ProductTypeFromMarginKeyMap = (function ()
{
    let res = {};
    res[SymbolInfoPanel.KEY_MarginIntraday] = res[SymbolInfoPanel.KEY_LeverageIntraday] = ProductType.Intraday
    res[SymbolInfoPanel.KEY_MarginDelivery] = res[SymbolInfoPanel.KEY_LeverageDelivery] = ProductType.Delivery

    return res;
})()

SymbolInfoPanel.GetProductTypeFromMarginKey = function (key)
{
    return SymbolInfoPanel.ProductTypeFromMarginKeyMap[key] || ProductType.General;
}

SymbolInfoPanel.ConvertProductTypeToSymbolInfoSuffix = function (productType)
{
    if (productType == ProductType.Intraday)
        return SymbolInfoPanel.PRODUCT_TYPE_INTRADAY
    else if (productType == ProductType.Delivery)
        return SymbolInfoPanel.PRODUCT_TYPE_DELIVERY

    return ''
}

SymbolInfoPanel.IsLeverageKey = function (key)
{
    return key ? key.indexOf(SymbolInfoPanel.KEY_Leverage) != -1 : false
}

// SymbolInfoPanel.IsLeverage = function (key, instrument, productType)
// {
//     let isPriceBasedMultipleCoefMarginType = RiskPlan.IsPriceBasedMultipleCoefMarginType(instrument, productType)

//     return isPriceBasedMultipleCoefMarginType && SymbolInfoPanel.IsLeverageKey(key)
// }

SymbolInfoPanel.AddMaintenanceItems_ = function (itemsForTable, instrument, productType)
{
    let suffix = RiskPlan.GetAvailableProductTypes(instrument).length > 1 ? SymbolInfoPanel.GetProductTypeSuffix(productType) : "";
    let items = new Array();

    if (!RiskPlan.UseOvernightMargin(instrument, productType) && !RiskPlan.UseLongShortMargin(instrument, productType))
    {
        items = items.concat(SymbolInfoPanel.GetMarginRequirementsItemsFromProductType(SymbolInfoPanel.KEY_Margin, instrument, productType))
    }
    else if (RiskPlan.UseOvernightMargin(instrument, productType) && !RiskPlan.UseLongShortMargin(instrument, productType))
    {
        items = items.concat(SymbolInfoPanel.GetMarginRequirementsItemsFromProductType(SymbolInfoPanel.KEY_MarginDay, instrument, productType))
        items = items.concat(SymbolInfoPanel.GetMarginRequirementsItemsFromProductType(SymbolInfoPanel.KEY_MarginOvernight, instrument, productType))
    }
    else if (!RiskPlan.UseOvernightMargin(instrument, productType) && RiskPlan.UseLongShortMargin(instrument, productType))
    {
        items.push(SymbolInfoPanel.KEY_MarginBuy);
        items.push(SymbolInfoPanel.KEY_MarginSell);

    }
    else if (RiskPlan.UseOvernightMargin(instrument, productType) && RiskPlan.UseLongShortMargin(instrument, productType))
    {
        if (RiskPlan.GetCalculateMarginTypeNullable(instrument, productType) !== MarginTypes.MARGIN_FULL)
        {
            items.push(SymbolInfoPanel.KEY_MarginDayBuy);
            items.push(SymbolInfoPanel.KEY_MarginDaySell);
            items.push(SymbolInfoPanel.KEY_MarginOvernightBuy);
            items.push(SymbolInfoPanel.KEY_MarginOvernightSell);
        }
        else
            items = items.concat(SymbolInfoPanel.GetMarginRequirementsItemsFromProductType(SymbolInfoPanel.KEY_Margin, instrument, productType))
    }

    items = items.concat(SymbolInfoPanel.GetLeverageItemsFromProductType(instrument, productType))

    // var accounts = BaseApplication.App.MultiDataCache.getSortedAccounts();
    // if (accounts.Count > 0)
    // {
    //     var res = true;
    //     if (accounts.Count > 1)
    //     {
    //         var cur = accounts[0].BaseCurrency;
    //         for (int i = 0; i < accounts.Count; i++)
    //         {

    //             res &= cur == accounts[i].BaseCurrency;

    //         }
    //     }
    //     if (res)
    //         items.Add(KEY_MarginInAccountCurrency);
    // }

    items.map(function (item)
    {
        if (suffix)
            itemsForTable.push(item + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + suffix);
        else
            itemsForTable.push(item);
    })
}


/// <summary>
/// Adds keys for Fees Items, которые показываются СЕЙЧАС
/// </summary>
SymbolInfoPanel.AddFeesItems = function (itemsForTable, VerticalPanelDataProvider, sett)
{
    if (itemsForTable == null || VerticalPanelDataProvider == null || sett == null)
        return;

    let ins = VerticalPanelDataProvider.Source,
        acc = VerticalPanelDataProvider.dependency || DataCache.MainAccountNew

    if (acc.CommissionPlan)
        acc.CommissionPlan.AddFeeItems(itemsForTable, ins)

    itemsForTable.push(SymbolInfoPanel.KEY_SwapBuy);
    itemsForTable.push(SymbolInfoPanel.KEY_SwapSell);

    let CustodialFeeData = DataCache.CustodialPlans.GetCustodialDataByInstrument(acc.CustodialPlanId, ins)
    if (CustodialFeeData !== null)
        itemsForTable.push(SymbolInfoPanel.KEY_CustodialFee);

    if (ins.ShortPositionInterest != null)
        itemsForTable.push(SymbolInfoPanel.KEY_ShortPositionInterest);

    let historical = SymbolInfoPanel.getHistoricalSwap(ins, SymbolInfoPanel.KEY_Historical_LongSwap_shortKey)
    if (historical !== null)
        itemsForTable.push(SymbolInfoPanel.KEY_Historical_LongSwap);

    historical = SymbolInfoPanel.getHistoricalSwap(ins, SymbolInfoPanel.KEY_Historical_ShortSwap_shortKey)
    if (historical !== null)
        itemsForTable.push(SymbolInfoPanel.KEY_Historical_ShortSwap);

    historical = SymbolInfoPanel.getHistoricalSwap(ins, SymbolInfoPanel.KEY_Historical_LastSwap_shortKey)
    if (historical !== null)
        itemsForTable.push(SymbolInfoPanel.KEY_Historical_LastSwap);
}

/// <summary>
/// Adds keys for rebate Items, которые показываются СЕЙЧАС
/// </summary>
SymbolInfoPanel.AddRebatesItems = function (itemsForTable, VerticalPanelDataProvider, sett)
{
    // здесь возвращаем только те что СЕЙЧАС отображаем

    //            Account mainAcc = sett.MainAccount;
    //IInstrument currentInstrument = (IInstrument)VerticalPanelDataProvider.Source;
    //Route route = currentInstrument == null ? null : BaseApplication.App.MultiDataCache.getRouteByName(currentInstrument.getRoute());

    //// сначала проверка на видимость всего блока комисий
    //if (!CheckNeedFilter(VerticalPanelDataProvider) && mainAcc != null)
    //{
    //    CommissionItem commissionItem = null;
    //    if (mainAcc.CommissionPlan != null)
    //        commissionItem = mainAcc.CommissionPlan.GetCommissionItem(VerticalPanelDataProvider.Source as IInstrument);

    //    if (commissionItem != null)
    //    {
    //        // внутри 'commissionItem' список по типам, и по каждому типу - подтипы с значениями (по кол-ву лотов)
    //        // а отображаем сгрупировано по подтипам  #26435  (Конструктор комиссий) - доработка
    //        #region add liq rebate
    //        //
    //        // 1. определяем какие из типов присутствуют.
    //        //
    //        bool perLot = false;
    //        bool orderPerLot = false;
    //        bool perVolume = false;

    //        // строки в блок без скобочек (и надобности) не добавлять!
    //        // здесь определяем видимость 1.
    //        RevenueCommissionEntryItem entry = commissionItem.AddLiquidityRebate;
    //        if (entry != null)
    //        {
    //            if (entry.CommissionItems != null)
    //                foreach (var item in entry.CommissionItems)
    //            if (item.Type == CommissionTypes.FillPerLot)
    //            {
    //                perLot = true;
    //            }
    //            else if (item.Type == CommissionTypes.OrderPerLot)
    //            {
    //                orderPerLot = true;
    //            }
    //            else if (item.Type == CommissionTypes.PerVolume)
    //            {
    //                perVolume = true;
    //            }
    //        }
    //        // 3. идём по всем типам которые надо показывать и для каждого из типов бьем еще по количеству лотов, (с учётом, конечно, пункта 2)
    //        // для упрощения восприятия и сопровождения разбито на блоки.
    //        if (perLot)
    //        {
    //            var ids = LotsListForType(new List<RevenueCommissionEntryItem>() { entry }, CommissionTypes.FillPerLot, CommissionOperationType.ALL);
    //        for (int i = 0; i < ids.length; i++)
    //            itemsForTable.Add(KEY_AddLiqudityRebatePerLot + SymbolInfoPanel.commisionSplitter + CommissionOperationType.ALL + SymbolInfoPanel.commisionSplitter + ids[i] + SymbolInfoPanel.commisionSplitter + (i + 1 < ids.length ? ids[i + 1] : -1));
    //        }
    //    if (orderPerLot)
    //    {
    //        var ids = LotsListForType(new List<RevenueCommissionEntryItem>() { entry }, CommissionTypes.OrderPerLot, CommissionOperationType.ALL);
    //    for (int i = 0; i < ids.length; i++)
    //        itemsForTable.Add(KEY_AddLiqudityRebateOrderPerLot + SymbolInfoPanel.commisionSplitter + CommissionOperationType.ALL + SymbolInfoPanel.commisionSplitter + ids[i] + SymbolInfoPanel.commisionSplitter + (i + 1 < ids.length ? ids[i + 1] : -1));
    //    }
    //if (perVolume)
    //{
    //    var ids = LotsListForType(new List<RevenueCommissionEntryItem>() { entry }, CommissionTypes.PerVolume, CommissionOperationType.ALL);
    //for (int i = 0; i < ids.length; i++)
    //    itemsForTable.Add(KEY_AddLiqudityRebatePerVolume + SymbolInfoPanel.commisionSplitter + CommissionOperationType.ALL + SymbolInfoPanel.commisionSplitter + ids[i] + SymbolInfoPanel.commisionSplitter + (i + 1 < ids.length ? ids[i + 1] : -1));
    //}

    //#endregion

    //#region remove liq rebate
    //    //
    //    // 1. определяем какие из типов присутствуют.
    //    //
    //perLot = false;
    //orderPerLot = false;
    //perVolume = false;

    //    // строки в блок без скобочек (и надобности) не добавлять!
    //    // здесь определяем видимость 1.
    //entry = commissionItem.RemoveLiquidityRebate;
    //if (entry != null)
    //{
    //if (entry.CommissionItems != null)
    //    foreach (var item in entry.CommissionItems)
    //        if (item.Type == CommissionTypes.FillPerLot)
    //{
    //            perLot = true;
    //}
    //else if (item.Type == CommissionTypes.PerVolume)
    //{
    //            perVolume = true;
    //}
    //}
    //    // 3. идём по всем типам которые надо показывать и для каждого из типов бьем еще по количеству лотов, (с учётом, конечно, пункта 2)
    //    // для упрощения восприятия и сопровождения разбито на блоки.
    //if (perLot)
    //{
    //var ids = LotsListForType(new List<RevenueCommissionEntryItem>() { entry }, CommissionTypes.FillPerLot, CommissionOperationType.ALL);
    //for (int i = 0; i < ids.length; i++)
    //    itemsForTable.Add(KEY_RemoveLiqudityRebatePerLot + SymbolInfoPanel.commisionSplitter + CommissionOperationType.ALL + SymbolInfoPanel.commisionSplitter + ids[i] + SymbolInfoPanel.commisionSplitter + (i + 1 < ids.length ? ids[i + 1] : -1));
    //}
    //if (orderPerLot)
    //{
    //var ids = LotsListForType(new List<RevenueCommissionEntryItem>() { entry }, CommissionTypes.OrderPerLot, CommissionOperationType.ALL);
    //for (int i = 0; i < ids.length; i++)
    //    itemsForTable.Add(KEY_RemoveLiqudityRebateOrderPerLot + SymbolInfoPanel.commisionSplitter + CommissionOperationType.ALL + SymbolInfoPanel.commisionSplitter + ids[i] + SymbolInfoPanel.commisionSplitter + (i + 1 < ids.length ? ids[i + 1] : -1));
    //}
    //if (perVolume)
    //{
    //var ids = LotsListForType(new List<RevenueCommissionEntryItem>() { entry }, CommissionTypes.PerVolume, CommissionOperationType.ALL);
    //for (int i = 0; i < ids.length; i++)
    //    itemsForTable.Add(KEY_RemoveLiqudityRebatePerVolume + SymbolInfoPanel.commisionSplitter + CommissionOperationType.ALL + SymbolInfoPanel.commisionSplitter + ids[i] + SymbolInfoPanel.commisionSplitter + (i + 1 < ids.length ? ids[i + 1] : -1));
    //}
    //#endregion
    //}
    //}
}

SymbolInfoPanel.AddHighLowLimitWarning = function (arrToAdd, ins, needAddOnlyKeys = false)
{
    if (!arrToAdd || !ins)
        return;

    if (ins.WarningForChangeFromLastPrice)
    {
        let sP = SymbolInfoPanel.commisionSplitter,
            group = SymbolInfoPanel.KEY_tradingInfo,
            ranges = ins.WarningForChangeFromLastPrice.ranges,
            sortIndex = SymbolInfoPanel.KEY_LowLimit_SortIndex

        let createItem = function (arrToAdd, key, ranges, lastChangePrefix)
        {
            for (let i = 0; i < ranges.length; i++)
            {
                let chVal = lastChangePrefix + ranges[i].LastChange,
                    lowR = ranges[i].LastPrice,
                    highR = i + 1 < ranges.length ? ranges[i + 1].LastPrice : -1,
                    id = key + sP + chVal + sP + lowR + sP + highR

                arrToAdd.push(needAddOnlyKeys ? id : SymbolInfoPanel.createItem(group, ++sortIndex, i ? '' : key, id));
            }
        }

        createItem(arrToAdd, SymbolInfoPanel.KEY_HighLimitWarning, ranges, '+')
        createItem(arrToAdd, SymbolInfoPanel.KEY_LowLimitWarning, ranges, '-')
    }
}

SymbolInfoPanel.AddHighLowLimitWarningRowValueByKey = function (key)
{
    if (!key)
        return;

    let sP = SymbolInfoPanel.commisionSplitter,
        splitArr = key.split(sP),
        value = splitArr[1] + ' %',
        leftBorder = splitArr[2],
        wordBetween = 'Last',
        rightBorder = splitArr[3] === '-1' ? '\u221e'/*∞*/ : splitArr[3]

    return leftBorder + ' <= ' + wordBetween + '  < ' + rightBorder + sP + value
}

SymbolInfoPanel.filterByInstrumentTradableItemsID = function (list, VerticalPanelDataProvider, sett)
{
    let currentInstrument = VerticalPanelDataProvider.Source;
    //let route = currentInstrument == null ? null : BaseApplication.App.MultiDataCache.getRouteByName(currentInstrument.getRoute());

    let forbidden = new Array();
    if (SymbolInfoPanel.CheckNeedFilter(VerticalPanelDataProvider))
    {
        // для типоув Индекс и если не трейдабл убираем из списка те что ниже.
        forbidden = forbidden.concat([
            SymbolInfoPanel.KEY_AllowedOperations, SymbolInfoPanel.KEY_AllowedOrderTypes, SymbolInfoPanel.KEY_ProductType, SymbolInfoPanel.KEY_DeliveryStatus, SymbolInfoPanel.KEY_NextHoliday, SymbolInfoPanel.KEY_TickCoast,
            SymbolInfoPanel.KEY_MinimalLot, SymbolInfoPanel.KEY_MinimalLotIntraday, SymbolInfoPanel.KEY_MinimalLotDelivery, SymbolInfoPanel.KEY_MaximumLot, SymbolInfoPanel.KEY_MaximumLotIntraday, SymbolInfoPanel.KEY_MaximumLotDelivery, SymbolInfoPanel.KEY_MaxPositionQty, SymbolInfoPanel.KEY_MaxPositionQtyIntraday, SymbolInfoPanel.KEY_MaxPositionQtyDelivery, SymbolInfoPanel.KEY_LotStep, SymbolInfoPanel.KEY_HiLimit, SymbolInfoPanel.KEY_LowLimit,
            SymbolInfoPanel.KEY_SwapBuy, SymbolInfoPanel.KEY_SwapSell, SymbolInfoPanel.KEY_AllowShortPositions, SymbolInfoPanel.KEY_AllowShortPositionsIntraday, SymbolInfoPanel.KEY_AllowShortPositionsDelivery,
            // fee при добавлении id-шек
            SymbolInfoPanel.KEY_Margin, SymbolInfoPanel.KEY_MarginOvernight, SymbolInfoPanel.KEY_MarginDay, SymbolInfoPanel.KEY_MarginBuy, SymbolInfoPanel.KEY_MarginSell, SymbolInfoPanel.KEY_MarginDayBuy, SymbolInfoPanel.KEY_MarginDaySell, SymbolInfoPanel.KEY_MarginOvernightBuy, SymbolInfoPanel.KEY_MarginOvernightSell,
            SymbolInfoPanel.KEY_MarginIntraday, SymbolInfoPanel.KEY_MarginOvernightIntraday, SymbolInfoPanel.KEY_MarginDayIntraday, SymbolInfoPanel.KEY_MarginBuyIntraday, SymbolInfoPanel.KEY_MarginSellIntraday, SymbolInfoPanel.KEY_MarginDayBuyIntraday, SymbolInfoPanel.KEY_MarginDaySellIntraday, SymbolInfoPanel.KEY_MarginOvernightBuyIntraday, SymbolInfoPanel.KEY_MarginOvernightSellIntraday,
            SymbolInfoPanel.KEY_MarginDelivery, SymbolInfoPanel.KEY_MarginOvernightDelivery, SymbolInfoPanel.KEY_MarginDayDelivery, SymbolInfoPanel.KEY_MarginBuyDelivery, SymbolInfoPanel.KEY_MarginSellDelivery, SymbolInfoPanel.KEY_MarginDayBuyDelivery, SymbolInfoPanel.KEY_MarginDaySellDelivery, SymbolInfoPanel.KEY_MarginOvernightBuyDelivery, SymbolInfoPanel.KEY_MarginOvernightSellDelivery
        ]);
    }
    //Symbol info - для инструментов с settlement system = immediate прятать блок настроек Margin req
    else if (currentInstrument.TradingBalance == InstrumentTradingBalance.SETTLEMENT_IMMEDIATE)
    {
        // для типоув Индекс и если не трейдабл убираем из списка те что ниже.
        forbidden = forbidden.concat([
            SymbolInfoPanel.KEY_Margin, SymbolInfoPanel.KEY_MarginOvernight, SymbolInfoPanel.KEY_MarginDay, SymbolInfoPanel.KEY_MarginBuy, SymbolInfoPanel.KEY_MarginSell, SymbolInfoPanel.KEY_MarginDayBuy, SymbolInfoPanel.KEY_MarginDaySell, SymbolInfoPanel.KEY_MarginOvernightBuy, SymbolInfoPanel.KEY_MarginOvernightSell,
            SymbolInfoPanel.KEY_MarginIntraday, SymbolInfoPanel.KEY_MarginOvernightIntraday, SymbolInfoPanel.KEY_MarginDayIntraday, SymbolInfoPanel.KEY_MarginBuyIntraday, SymbolInfoPanel.KEY_MarginSellIntraday, SymbolInfoPanel.KEY_MarginDayBuyIntraday, SymbolInfoPanel.KEY_MarginDaySellIntraday, SymbolInfoPanel.KEY_MarginOvernightBuyIntraday, SymbolInfoPanel.KEY_MarginOvernightSellIntraday,
            SymbolInfoPanel.KEY_MarginDelivery, SymbolInfoPanel.KEY_MarginOvernightDelivery, SymbolInfoPanel.KEY_MarginDayDelivery, SymbolInfoPanel.KEY_MarginBuyDelivery, SymbolInfoPanel.KEY_MarginSellDelivery, SymbolInfoPanel.KEY_MarginDayBuyDelivery, SymbolInfoPanel.KEY_MarginDaySellDelivery, SymbolInfoPanel.KEY_MarginOvernightBuyDelivery, SymbolInfoPanel.KEY_MarginOvernightSellDelivery,
            SymbolInfoPanel.KEY_SwapBuy, SymbolInfoPanel.KEY_SwapSell
        ]);
    }

    // special hiding if "0" #31602
    if (!isNaN(currentInstrument.HightLimit))
        forbidden.push(SymbolInfoPanel.KEY_HiLimit);
    if (!isNaN(currentInstrument.LowLimit))
        forbidden.push(SymbolInfoPanel.KEY_LowLimit);

    // if (Utils.IsNullOrUndefined(currentInstrument.Limits.GetHighLimitFromPriceLimitsMessage()))
    //     list.splice(list.indexOf(SymbolInfoPanel.KEY_HiLimitFromPriceLimitsMsg), 1)
    // if (Utils.IsNullOrUndefined(currentInstrument.Limits.GetLowLimitFromPriceLimitsMessage()))
    //     list.splice(list.indexOf(SymbolInfoPanel.KEY_LowLimitFromPriceLimitsMsg), 1)
    if (currentInstrument.SwapBuy == 0)
        forbidden.push(SymbolInfoPanel.KEY_SwapBuy);
    if (currentInstrument.SwapSell == 0)
        forbidden.push(SymbolInfoPanel.KEY_SwapSell);

    //TODO
    //if (!BaseApplication.App.MultiDataCache.DCache.IsSharedAsset(currentInstrument.Exp1))
    //    forbidden.push(SymbolInfoPanel.KEY_Exp1);

    SymbolInfoPanel.ProcessForbiddenByProductType(currentInstrument, forbidden);

    let apt = currentInstrument.RiskSettings.RiskPlanSettings.availableProductTypes
    if (apt.length == 1 && apt[0] == ProductType.General)
    {
        let index = list.indexOf(SymbolInfoPanel.KEY_ProductType)
        if (index >= 0)     // Den: не стал восстанавливать логику скрытия через forbidden так как не понятно не сломаю ли что-то, пока скрывается только ProductType
            list.splice(index, 1)

        //forbidden.push(SymbolInfoPanel.KEY_ProductType);
    }

    //TODO
    //if (!Utils.UseProductType || (currentInstrument.RiskSettings.RiskPlanSettings.AvailableProductTypes.length == 1 && currentInstrument.RiskSettings.RiskPlanSettings.AvailableProductTypes[0] == ProductType.General))
    //    forbidden.push(SymbolInfoPanel.KEY_ProductType);

    //if (list != null)
    //    foreach (var forb in forbidden)
    //if (list.Contains(forb))
    //    list.Remove(forb);

    return list;
}

/// <summary>
/// определяем какие айтемы скрывать (для айтемов, количество которых зависит от ProductType)
/// </summary>
/// <param name="instrument"></param>
/// <param name="forbidden"></param>
SymbolInfoPanel.ProcessForbiddenByProductType = function (instrument, forbidden)
{
    //var availableProductTypes = instrument.RiskSettings.RiskPlanSettings.AvailableProductTypes;
    //foreach (var type in availableProductTypes)
    //{
    //    var keys = new List<string>();

    //    if (instrument.getMaxLot(type) <= 0)
    //        keys.Add(KEY_MaximumLot);

    //    RiskPlanItem currenRiskPlanItem = instrument.RiskSettings[type];
    //    if (currenRiskPlanItem == null || currenRiskPlanItem.MaxPositionQtyPerSymbol == null || currenRiskPlanItem.MaxPositionQtyPerSymbol == -1)
    //        keys.Add(KEY_MaxPositionQty);

    //    var suffix = availableProductTypes.length > 1 ? GetProductTypeSuffix(type) : "";
    //    foreach (var key in keys)
    //    {
    //        if ((suffix))
    //            forbidden.Add(key + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + suffix);
    //        else
    //            forbidden.Add(key);
    //    }
    //}
}

SymbolInfoPanel.CheckNeedFilter = function (VerticalPanelDataProvider)
{
    let currentInstrument = VerticalPanelDataProvider.Source;
    //Route route = currentInstrument == null ? null : BaseApplication.App.MultiDataCache.getRouteByName(currentInstrument.getRoute());

    return (/*(route != null && !route.IsTradable) || route == null || */currentInstrument.InstrType == InstrumentTypes.INDICIES);
}

// возвращает полное описание текущей сессии для AllowedOperations SymbolImfo && Watchlist
SymbolInfoPanel.GetAllowedOperations = function (currentInstrument)
{
    let comma = ', '
    let allow = ''

    if (!currentInstrument)
        return allow

    let tradingMode = currentInstrument.TradingMode;
    if (tradingMode === TradingMode.FullyOpen || tradingMode === TradingMode.LiquidationOnly)
    {
        let allowedOperations = Instrument.GetAllowedOperations(currentInstrument)
        let decreaseOnlyPositionCount = Instrument.IsDecreaseOnlyPositionCount(currentInstrument)

        if (allowedOperations & SessionOperations.Open &&
            SymbolInfoPanel.GetAllowedOrderTypes(currentInstrument))
            allow += Resources.getResource('panel.watchlist.cell.sending') + comma

        if (allowedOperations & SessionOperations.Modify)
            allow += Resources.getResource('panel.watchlist.cell.modify') + comma

        if (allowedOperations & SessionOperations.Cancel)
            allow += Resources.getResource('panel.watchlist.cell.cancel') + comma

        if (decreaseOnlyPositionCount)
            allow += Resources.getResource('InstrumentDetailsPanel.onlyCloseOrders') + comma
    }
    else if (tradingMode === TradingMode.TradingHalt)
        allow = Resources.getResource('panel.watchlist.cell.cancel')

    if (allow.endsWith(comma))
        allow = allow.substring(0, allow.length - comma.length)

    return allow
}

// возвращает полное описание текущей сессии для AllowedOrderTypes SymbolImfo
SymbolInfoPanel.GetAllowedOrderTypes = function (instrument)
{
    let comma = ', '
    let allow = ''
    let tradingMode = instrument.TradingMode;
    if (tradingMode === TradingMode.FullyOpen ||
        tradingMode === TradingMode.TradingHalt ||
        tradingMode === TradingMode.LiquidationOnly)
    {
        let supportedParamObj = new OrderTypeBaseParameters(instrument);

        let OrderTypes = DataCache.OrderParameterContainer.OrderTypes
        for (let orderType in OrderTypes)
        {
            let oT = OrderTypes[orderType]
            if (oT.IsSupported(supportedParamObj))
                allow += Resources.getResource(oT.localizationKey()) + comma
        }
    }

    if (allow.endsWith(comma))
        allow = allow.substring(0, allow.length - comma.length)

    return allow
}

SymbolInfoPanel.dayOfWeekAsString = function (dayOfWeek)
{
    switch (dayOfWeek)
    {
        case 0:
            return 'Sunday'
        case 1:
            return 'Monday'
        case 2:
            return 'Tuesday'
        case 3:
            return 'Wednesday'
        case 4:
            return 'Thursday'
        case 5:
            return 'Friday'
        case 6:
            return 'Saturday'
    }
}

SymbolInfoPanel.LocalizeSessionGroup = function (VerticalPanelDataProvider, sett)
{
    let KEY_sessionInfo = SymbolInfoPanel.KEY_sessionInfo
    let instrument = VerticalPanelDataProvider.Source
    // Андрюша просил добавить в группу текущую дату и если праздник то шортен или нон-воркинг 
    let additionSessInfo = '',
        tradingSession = instrument ? instrument.GetAnyTradingSession() : null
    if (tradingSession)
    {
        let nowSessionDay = tradingSession.ServerTodayDate
        additionSessInfo =
            ' - ' +
            Resources.getResource('general.' + SymbolInfoPanel.dayOfWeekAsString(nowSessionDay.getDay())) + ' ' +
            nowSessionDay.getDate()

        let holiday = instrument.GetTodayHoliday()

        if (holiday)
        {
            if (holiday.IsNotWorking())
                additionSessInfo += ' (' + Resources.getResource('InstrumentDetailsPanel.non trading day') + ')'
            else if (holiday.IsShorted())
                additionSessInfo += ' (' + Resources.getResource('InstrumentDetailsPanel.shortened') + ')'
            else
                additionSessInfo += ' (' + Resources.getResource('InstrumentDetailsPanel.working') + ')'
        }
    }

    return Resources.getResource(KEY_sessionInfo) + additionSessInfo
}


SymbolInfoPanel.PrepareTickSizeCostItems = function (itemsForTable, instrument, key)
{
    for (let i = 1; i < instrument.VariableTickList.length; i++)
    {
        let rightBorder = instrument.VariableTickList[i].RightBorder;
        let includeRightBorder = i < instrument.VariableTickList.length - 1 ? !instrument.VariableTickList[i + 1].IncludeLeftBorder : false;
        if (!isFinite(rightBorder))
            rightBorder = -1

        if (key == SymbolInfoPanel.KEY_TickCoast && instrument.isFutureOrOption())
            itemsForTable.push(SymbolInfoPanel.KEY_TickCoast + SymbolInfoPanel.commisionSplitter + instrument.VariableTickList[i].LeftBorder + SymbolInfoPanel.commisionSplitter + rightBorder + SymbolInfoPanel.commisionSplitter + instrument.VariableTickList[i].TickCost + SymbolInfoPanel.commisionSplitter + instrument.VariableTickList[i].IncludeLeftBorder + SymbolInfoPanel.commisionSplitter + includeRightBorder);

        if (key == SymbolInfoPanel.KEY_TickSize)
        {
            let pointSize = instrument.VariableTickList[i].PointSize;
            let point = (pointSize < 1 ? parseFloat(pointSize.toFixed(10)) : pointSize).toString()
            itemsForTable.push(SymbolInfoPanel.KEY_TickSize + SymbolInfoPanel.commisionSplitter + instrument.VariableTickList[i].LeftBorder + SymbolInfoPanel.commisionSplitter + rightBorder + SymbolInfoPanel.commisionSplitter + point + SymbolInfoPanel.commisionSplitter + instrument.VariableTickList[i].IncludeLeftBorder + SymbolInfoPanel.commisionSplitter + includeRightBorder);
        }
    }
}

/// <summary>
/// GetTradeSessionTypeName
/// </summary>
SymbolInfoPanel.GetTradeSessionTypeName = function (dayPeriod)
{
    switch (dayPeriod)
    {
        case DayPeriods.PRE_OPEN:
            return SymbolInfoPanel.SESSION_DAY_PERIOD_PREOPEN
        case DayPeriods.MAIN:
            return SymbolInfoPanel.SESSION_DAY_PERIOD_MAIN
        case DayPeriods.POST_CLOSE:
            return SymbolInfoPanel.SESSION_DAY_PERIOD_POST_CLOSE
        case DayPeriods.BEFORE_MARKET:
            return SymbolInfoPanel.SESSION_DAY_PERIOD_BEFORE_MARKET
        case DayPeriods.AFTER_MARKET:
            return SymbolInfoPanel.SESSION_DAY_PERIOD_AFTER_MARKET
    }
    return "";
}


//TODO FOR VENDOR!!!!!!!
SymbolInfoPanel.GetFeeString = function (lotsFrom, lotsTo, value, allowLimit, allowHighLimit, sess, feeVar)
{
    feeVar = feeVar || SymbolInfoPanel.FeeStringVariable.Amount

    let lf = parseFloat(lotsFrom)
    let lt = parseFloat(lotsTo)
    if (lf === 0 && lt === -1)
        return value

    let localizeKey = Resources.getResource(
        feeVar === SymbolInfoPanel.FeeStringVariable.Price
            ? 'InstrumentDetailsPanel.Price'
            : 'InstrumentDetailsPanel.Amount')

    return lf +
        (allowLimit ? ' <= ' : ' < ') +
        localizeKey +
        (allowHighLimit ? ' <= ' : ' < ') +
        (lt === -1 ? '\u221e'/*∞*/ : lt.toString()) +
        SymbolInfoPanel.commisionSplitter +
        value
}

SymbolInfoPanel.getSwapBuySell = function (price, swapType, sett)
{
    switch (swapType)
    {
        case SwapType.SWAP_IN_CURRENCY:
            return price + " " + DataCache.baseCurrency;
        case SwapType.SWAP_IN_PERCENT:
            return price + " %";
        case SwapType.SWAP_IN_PIPS:
            return price + " " + Resources.getResource("general.trading.pips", GeneralSettings.General.Language);
        default:
            return "";
    }
}

SymbolInfoPanel.getHistoricalSwap = function (instrument, shortKey)
{
    if (!instrument)
        return null;

    if (!instrument.InstrumentAdditionalInfo)
        return null;

    if (instrument.InstrumentAdditionalInfo.hasOwnProperty(shortKey))
        return instrument.InstrumentAdditionalInfo[shortKey]

    return null;
}

SymbolInfoPanel.NeedSkipEmptyMargin = function (instrument, productType, key)    // #113777, #113782
{
    let margin = RiskPlan.GetMargin(instrument, productType, 0),
        marginIsEmpty = !margin.Warning && !margin.Maintenance && !margin.Initial

    return !key.indexOf(SymbolInfoPanel.KEY_Margin) && marginIsEmpty
}

SymbolInfoPanel.FeeStringVariable = { Amount: 0, Price: 1 }

SymbolInfoPanel.PANEL_WIDTH = 321;
SymbolInfoPanel.PANEL_HEIGHT = 378;