// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { TimeSpanPeriods } from "../Utils/Time/TimeSpan.ts";
import { Resources } from "../Commons/properties/Resources.ts";
import { HistoryType } from "../Utils/History/HistoryType.ts";
import { Pen, SolidBrush } from "../Commons/Graphics.ts";
import { TerceraChartDrawingType } from "../Chart/Utils/ChartConstants.ts";
import { TercaraChartPriceIndicatorType } from "../Chart/Renderers/TerceraChartSpreadRenderer.ts";
import { ModelDataType, TerceraChartMVCCommand } from "../Chart/TerceraChartMVC.ts";
import { MainWindowManager } from "../Controls/UtilsClasses/MainWindowManager.ts";
import { MathUtils } from "../Utils/MathUtils.ts";
import { MouseButtons } from "../Controls/UtilsClasses/ControlsUtils.ts";
import { TerceraChartUtils } from "../Chart/TerceraChartUtils.ts";
import { contextMenuHandler } from "../Utils/AppHandlers.js";
import { Control } from "../Controls/elements/Control.js";
import { ContainerControl } from "../Controls/elements/ContainerControl.js";
import { WINDOW_SIDE_BORDERS_WIDTH } from "../Controls/UtilsClasses/SizeConstants.ts";
import { PortfolioPerformanceTemplate } from "../templates.js";
import { ThemeManager } from "../Controls/misc/ThemeManager.ts";
import { Periods, TFInfo } from "../Utils/History/TFInfo.ts";
import { PortfolioRequestType } from "../Utils/Portfolio/PortfolioRequestType.ts";
import { Account } from "../Commons/cache/Account.ts";
import { AccountFeature } from '../Utils/Account/AccountFeature.ts';
import { BaseInterval } from "../Utils/History/BaseInterval.ts";
import { CashItem } from "../Commons/cache/History/CashItem.ts";
import { DynProperty } from "../Commons/DynProperty.ts";
import { GeneralSettings } from "../Utils/GeneralSettings/GeneralSettings.ts";
import { Instrument } from "../Commons/cache/Instrument.ts";
import { DataCache } from "../Commons/DataCache.ts";
import { SessionSettings } from "../Commons/SessionSettings.ts";
import { InfoWindowShowingType } from "../Chart/Renderers/TerceraChartInfoWindowRenderer.ts";
import { PanelSettingsScreen } from "../Controls/screen/PanelSettingsScreen.js";
import { BaseIntervalInputParams } from "../Utils/History/BaseIntervalInputParams.ts";
import { PanelNames } from "../Controls/UtilsClasses/FactoryConstants.ts";

export let PortfolioPerformance = ContainerControl.extend(
    {
        data: function ()
        {
            return {
                noDataToDisplay: true,
                zIndex: 100,

                selectedAccount: null,      // выбранный аккаунт в лукапе

                diagramWidth: 0,
                diagramHeight: 0,
                diagramDataArray: [],       // данные для секторов диаграммы

                diagramInsideTextsContainerTop: 0,  // top in px of js-PortfolioPerformance-DiagramPercentContainer
                diagramInsideBigFontSize: 24,       // размер шрифта центральной надписи внутри диаграммы может уменьшаться если не будет влазить в диаграмму
                diagramInsideSmallFontSize: 18,     // процент и валюта имеют шрифт diagramInsideBigFontSize * 0.562 

                diagramLabelPercent: 14.88,
                diagramLabelBalance: '',
                diagramLabelCurrency: 'USD',

                diagramBackGroundColor: '#101720',
                diagramLabelPercentVisible: true,
                diagramPercentColorUp: '#00A32C',
                diagramPercentColorDown: '#FB563C',

                diagramLabelBalanceVisible: true,
                diagramLabelBalanceColor: '#FC9F13',

                tableDataArray: [],         // данные для таблицы 
                tableHeaderLeft: 'Asset',
                tableHeaderRight: 'Value in portfolio',

                isPortfolioAllocationSelected: true,     // состояние переключателя Portfolio/Model allocation
                portfolioAllocationLabel: '',            // подпись слева от переключателя
                modelAllocationLabel: '',                // подпись справа от переключателя

                splitterX: null,                        // положение разделителя
                splitterDrag: false,
                chartWidth: null,

                allocationComboBoxItems: null,           // элементы комбобокса 
            };
        },
        template: PortfolioPerformanceTemplate,
        computed: {
            terceraChartPanelContext: {
                get: function () { return this; },
                set: function (value) { }
            }
        },

        chartInited: false,
        wasRecalculateBalances: false,
        portfolioDiagramSettings: null,
        terceraChartRactive: null,
        openedTableItemIndex: null
    });

PortfolioPerformance.prototype.getType = function () { return PanelNames.PortfolioPerformance; };

PortfolioPerformance.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.apply(this);

    this.portfolioDiagramSettings = new PortfolioDiagramSettings(this)

    this.observe('selectedAccount', this.onAccountChanged)
    this.observe('height', this.onResize)
    this.observe('isPortfolioAllocationSelected', this.onIsPortfolioAllocationSelectedChanged)
};

PortfolioPerformance.prototype.oncomplete = function ()
{
    ContainerControl.prototype.oncomplete.apply(this);

    $(window).on('resize', this.onResize.bind(this));

    this.on('onMouseUp', this.onMouseUp);
    this.on('onMouseMove', this.onMouseMove);

    this.on('splitterMouseDown', this.splitterMouseDown);
    this.on('splitterMouseUp', this.splitterMouseUp);
    this.on('splitterMouseMove', this.splitterMouseMove);

    this.subscribeEvents()

    SessionSettings.PortfolioPropertiesHandlers.push(this.Properties.bind(this));
    SessionSettings.PortfolioCallBacksHandlers.push(this.callBack.bind(this));

    this.onResize()
    this.localize()
};

PortfolioPerformance.prototype.dispose = function ()
{
    this.unsubscribeEvents()

    this.wasRecalculateBalances = false

    ContainerControl.prototype.dispose.apply(this);
};

PortfolioPerformance.prototype.onAccountChanged = function ()
{
    this.updateCurrentAccountTotalReturn()
    this.updateCurrentAccountProjectedBalance()
    this.updateGrowth()
}

PortfolioPerformance.prototype.localize = function ()
{
    let blockTitleAlocation = 'portfolio.performance.allocation.title',
        portfolioAllocationLocalKey = 'portfolio.performance.portfolioAllocationLabel',
        modelAllocationLocalKey = 'portfolio.performance.modelAllocationLabel',
        blockTitleGrowth = 'portfolio.performance.growth.title';

    this.set('blockTitleAlocation', Resources.getResource(blockTitleAlocation))
    this.set('portfolioAllocationLabel', Resources.getResource(portfolioAllocationLocalKey))
    this.set('modelAllocationLabel', Resources.getResource(modelAllocationLocalKey))
    this.set('blockTitleGrowth', Resources.getResource(blockTitleGrowth))
};

PortfolioPerformance.prototype.subscribeEvents = function ()
{
    DataCache.OnRecalculatedBalances.Subscribe(this.OnRecalculatedBalances, this)
    DataCache.OnReintialize.Subscribe(this.OnReintialize, this);
    DataCache.PortfolioCache.OnAllocationUpdate.Subscribe(this.updateDiagramData, this);
    DataCache.PortfolioCache.OnModelUpdate.Subscribe(this.updateDiagramData, this);
    DataCache.PortfolioCache.OnTotalReturnUpdate.Subscribe(this.updateTotalReturn, this);
    Control.Ticker.Subscribe(this.TickAsync, this);

    let rD = this.Controls.RadialDiagram
    if (rD)
        rD.onSelectedSectorChanged.Subscribe(this.selectedSectorChanged, this)

    this.on('updateTableData', this.updateTableData)    //for test

    this.on('onTableItemClick', this.onTableItemClick)
    this.on('onTableItemHover', this.onTableItemHover)
    this.on('onTableItemHoverEnd', this.onTableItemHoverEnd)

    this.on('onAllocationSwitcherClick', this.onAllocationSwitcherClick)
}

PortfolioPerformance.prototype.unsubscribeEvents = function ()
{
    DataCache.OnRecalculatedBalances.UnSubscribe(this.OnRecalculatedBalances, this)
    DataCache.OnReintialize.UnSubscribe(this.OnReintialize, this);
    DataCache.PortfolioCache.OnAllocationUpdate.UnSubscribe(this.updateDiagramData, this);
    DataCache.PortfolioCache.OnModelUpdate.UnSubscribe(this.updateDiagramData, this);
    DataCache.PortfolioCache.OnTotalReturnUpdate.UnSubscribe(this.updateTotalReturn, this);
    Control.Ticker.UnSubscribe(this.TickAsync, this);

    let rD = this.Controls.RadialDiagram
    if (rD)
        rD.onSelectedSectorChanged.UnSubscribe(this.selectedSectorChanged, this)
}

PortfolioPerformance.prototype.selectedSectorChanged = function (newSelectedIndex)
{
    if (newSelectedIndex >= 0)
        this.onTableItemClick(null, newSelectedIndex, true)
    else if (this.openedTableItemIndex !== null)
        this.onTableItemClick(null, this.openedTableItemIndex, true)
}

PortfolioPerformance.prototype.OnReintialize = function ()
{
    let pCache = DataCache.PortfolioCache

    pCache.SendPortfolioModelRequest()

    GeneralSettings.TradingDefaults.Account = DataCache.getPrimaryAccount();

    let accLookup = this.Controls.accountLookup
    if (accLookup)
        accLookup.repopulate()  // т.к. accLookup инитится до логина, когда аккаунтов еще нет

}

PortfolioPerformance.prototype.updateGrowth = function ()
{
    let acc = this.get('selectedAccount')
    if (!acc) return

    let me = this,
        pCache = DataCache.PortfolioCache,
        accID = acc.AcctNumber

    pCache.SendPortfolioStatisticsRequest(accID, PortfolioRequestType.PORTFOLIO_GROWTH)
        .then(function ()
        {
            let noDataToDisplay = !pCache.portfolioGrowth || !pCache.portfolioGrowth.length
            me.set('noDataToDisplay', noDataToDisplay)

            if (!noDataToDisplay)
            {
                me.initChart()
                me.GetCashItems()
            }
        })
}

PortfolioPerformance.prototype.updateTotalReturn = function (accID)
{
    let acc = this.get('selectedAccount')
    if (!acc) return

    if (acc.AcctNumber == accID)
        this.updateCurrentAccountTotalReturn()
}

PortfolioPerformance.prototype.updateCurrentAccountTotalReturn = function ()
{
    let acc = this.get('selectedAccount')
    if (!acc) return

    let totalReturn = DataCache.PortfolioCache.GetTotalReturn(acc) || Resources.getResource("general.N_A")
    this.set('diagramLabelPercent', totalReturn)
}

PortfolioPerformance.prototype.OnRecalculatedBalances = function ()
{
    this.wasRecalculateBalances = true
}

PortfolioPerformance.prototype.updateCurrentAccountProjectedBalance = function ()
{
    let acc = this.get('selectedAccount')
    if (!acc) return

    if (!this.wasRecalculateBalances)
        return

    let assetBalance = acc.GetAssetBalanceCorrect(),
        asset = assetBalance.Asset,
        assetString = asset ? asset.toString() : '',
        projBalVal = Account.GetAccountFeature(AccountFeature.ProjectedBalance, acc, assetBalance),
        projBalFormatted = asset.formatPrice(projBalVal, true)

    this.set({
        diagramLabelBalance: projBalFormatted,
        diagramLabelCurrency: assetString
    })
}

PortfolioPerformance.prototype.updateDiagramData = function (id)
{
    if (!id)
        return

    let isPortfolioAllocationSelected = this.get('isPortfolioAllocationSelected'),
        acc = this.get('selectedAccount'),
        accID = acc ? acc.AcctNumber : null,
        methodName = 'GetDataForDiagram' + (isPortfolioAllocationSelected ? 'PortfolioAllocation' : 'ModelAllocation'),
        objArr = DataCache.PortfolioCache[methodName](accID)

    let data = []
    for (let i = 0; i < objArr.length; i++)
    {
        let dataObj = {
            value: objArr[i].value,
            text: objArr[i].text
        }

        data.push(dataObj)
    }

    if (!data.length)
        data.push({ text: 'Cash:', value: 1 })

    this.set('diagramDataArray', data)

    this.updateTableData()
}

PortfolioPerformance.prototype.updateDiagramDataForTest = function ()
{
    let rD = this.Controls.RadialDiagram
    if (!rD) return

    let rDData = rD.get('data'),
        data = []
    for (let i = 0; i < rDData.length; i++)
    {
        let dataObj = {
            value: rDData[i].value,
            text: rDData[i].text
        }

        data.push(dataObj)
    }

    this.set('diagramDataArray', data)

    this.updateTableData()
}


PortfolioPerformance.prototype.updateTableData = function ()
{
    if (this.openedTableItemIndex !== null) return  // for test

    let tableData = [],
        diagramData = this.get('diagramDataArray')

    if (!diagramData || !diagramData.length)
        return

    for (let i = 0; i < diagramData.length; i++)
    {
        let tableRow = {
            isOpen: false,
            name: diagramData[i].text,
            color: DataCache.PortfolioCache.instrumentColor[i],
            contentHeight: 100                                          // высота карточки
        }
        tableData.push(tableRow)
    }

    this.set('tableDataArray', tableData)
    this.tableScrollUpdate()
}

PortfolioPerformance.prototype.onTableItemClick = function (event, index, fromEventRaise)
{
    let isOpen = this.get('tableDataArray[' + index + '].isOpen')

    if (!isOpen && this.openedTableItemIndex !== null)                                  // сделал как в идеях - одновременно открыта может быть только одна карточка
        this.set('tableDataArray[' + this.openedTableItemIndex + '].isOpen', false)     // поэтому закроем старую, если открываем новую при какой-то открытой

    this.set('tableDataArray[' + index + '].isOpen', !isOpen)

    this.openedTableItemIndex = isOpen ? null : index

    let rD = this.Controls.RadialDiagram
    if (rD && !fromEventRaise)
        rD.selectedSectorChanged(isOpen ? -1 : index, true)

    this.tableScrollUpdate()
}

PortfolioPerformance.prototype.onTableItemHover = function (event, index)
{
    let rD = this.Controls ? this.Controls.RadialDiagram : null
    if (!rD) return

    rD.forceHover(index)
}

PortfolioPerformance.prototype.onTableItemHoverEnd = function (event, index)
{
    let rD = this.Controls ? this.Controls.RadialDiagram : null
    if (!rD) return

    rD.forceHoverEnd(index)
}

PortfolioPerformance.prototype.tableScrollUpdate = function ()
{
    let allRows = document.getElementsByClassName('js-PortfolioPerformance-tableRows')[0]
    if (allRows)
    {
        PortfolioPerformance.Scroll.removeScroll(allRows)
        allRows.style.height = "94%"
        PortfolioPerformance.Scroll.addScroll(allRows)
    }
}

PortfolioPerformance.Scroll = {
    addScroll: function (node)
    {
        $(node).mCustomScrollbar({
            theme: "tercera",
            scrollButtons: {
                enable: true,
                scrollType: "stepless"
            },
            mouseWheel: { enable: true },
            keyboard: { enable: true },
            advanced: { updateOnContentResize: true }
        });
    },
    removeScroll: function (node)
    {
        $(node).mCustomScrollbar("destroy");
    }
};


PortfolioPerformance.prototype.onIsPortfolioAllocationSelectedChanged = function ()
{
    this.updateDiagramData()

    SessionSettings.savePortfolioSettings()
}

PortfolioPerformance.prototype.onAllocationSwitcherClick = function ()
{
    let isPortfolioAllocationSelected = this.get('isPortfolioAllocationSelected')

    this.set('isPortfolioAllocationSelected', !isPortfolioAllocationSelected)
}

// PortfolioPerformance.prototype.createAllocationComboBoxItems = function ()
// {
//     let items = [],
//         keys = PortfolioPerformance.AllocationComboBoxItemsKeys

//     for(let i = 0; i < keys.length; i++)
//         items.push({
//             text: Resources.getResource(keys[i]),
//             value: i
//         })

//     this.set('allocationComboBoxItems', items)
// }

// PortfolioPerformance.AllocationComboBoxItemsKeys = ['portfolio.performance.allocationCB.Symbol', 'portfolio.performance.allocationCB.Symbol type', 'portfolio.performance.allocationCB.Country', 'portfolio.performance.allocationCB.Sector', 'portfolio.performance.allocationCB.Industry', 'portfolio.performance.allocationCB.Currency']

// Chart region

PortfolioPerformance.prototype.subscribeRendererEvents = function ()
{
    if (!this.terceraChartRactive) return

    let chart = this.terceraChartRactive.terceraChart

    if (!chart) return

    // let renderer = chart.PortfolioChartReturnsRenderer

    // if (!renderer) return

    // renderer.barsClusterHoverIndexChanged.Subscribe(this.hoverMonthBarsIndexChanged, this)
    // renderer.barsClusterSelectedIndexChanged.Subscribe(this.selectedMonthBarsIndexChanged, this)
}

PortfolioPerformance.prototype.unsubscribeRendererEvents = function ()
{
    if (!this.terceraChartRactive) return

    let chart = this.terceraChartRactive.terceraChart

    if (!chart) return

}


PortfolioPerformance.prototype.initChart = function ()
{
    if (this.terceraChartRactive === null)
        this.terceraChartRactive = this.Controls.chart;

    var terceraChart = this.terceraChartRactive.terceraChart
    if (!terceraChart)
    {
        this.terceraChartRactive.oncomplete()
        terceraChart = this.terceraChartRactive.terceraChart
    }

    var controller = terceraChart.chartController,
        anyIns = DataCache.getAnyInstrument(),
        instrument = new Instrument(DataCache, anyIns.CreateInstrumentMessage(), anyIns.Route),
        acc = this.get('selectedAccount') || DataCache.getPrimaryAccount(),
        tfInfo = new TFInfo()

    var pCache = DataCache.PortfolioCache
    instrument.Level1.GetLastPrice = pCache.GetLastGrowth.bind(pCache);

    controller.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.TFI), tfInfo.Copy({ period: Periods.MONTH, historyType: HistoryType.LAST }));
    controller.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.Instrument), instrument);
    controller.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.Account), acc);

    if (!this.chartInited)
    {
        terceraChart.GetContextMenu = this.GetChartContextMenu.bind(this)

        this.initDefaultChartTheme()

        this.setChartPosition()

        this.chartInited = true
    }

    this.updateChartAsset()
};

PortfolioPerformance.prototype.applyDefaultSettings = function ()
{
    let theme = ThemeManager.CurrentTheme

    this.chartBackGroundColor = theme.PortfolioReturnsChartBackgroundColor   // default chart background set
    this.axisColor = theme.scrollBackgroundColorChart

    this.set('infoWindowBackColor', theme.PortfolioReturnsChartInfoWindowColorDefault)  // info window
    this.set('infoWindowTextColor', theme.PortfolioReturnsChartInfoWindowTextColor)
}

PortfolioPerformance.prototype.initDefaultChartTheme = function ()
{
    if (!this.terceraChartRactive || !this.terceraChartRactive.terceraChart) return

    let terceraChart = this.terceraChartRactive.terceraChart

    let theme = ThemeManager.CurrentTheme

    if (!this.chartInited)     // Need to set default settings
        this.applyDefaultSettings()

    let chartBackGroundColor = this.chartBackGroundColor,
        axisColor = this.axisColor,
        yScaleRendererSettings = terceraChart.yScaleRendererSettings
    if (yScaleRendererSettings)
    {
        yScaleRendererSettings.ScaleGridColor = theme.Chart_GridPriceColor;
        yScaleRendererSettings.ScaleGridStyle = Pen.csIsoDotChart;
        yScaleRendererSettings.ScaleGridWidth = 1;
        yScaleRendererSettings.ScaleGridVisibility = true
        yScaleRendererSettings.ScaleBackColor = axisColor
        yScaleRendererSettings.scaleAxisPen.Color = axisColor
        yScaleRendererSettings.ScaleTextColor = theme.PortfolioReturnsChartMainLineColor
        yScaleRendererSettings.AutoScaleSwitcherVisible = true
        yScaleRendererSettings.PercentView = false
    }

    let xScaleRendererSettings = terceraChart.xScaleRendererSettings
    if (xScaleRendererSettings)
    {
        xScaleRendererSettings.ScaleGridColor = theme.Chart_GridPriceColor;
        xScaleRendererSettings.ScaleGridStyle = Pen.csIsoDotChart;
        xScaleRendererSettings.ScaleGridWidth = 1;
        xScaleRendererSettings.ScaleGridVisibility = true
        xScaleRendererSettings.ScaleBackColor = axisColor
        xScaleRendererSettings.scaleAxisPen.Color = axisColor
        xScaleRendererSettings.ScaleTextColor = theme.PortfolioReturnsChartMainLineColor
    }

    let watermarkR = terceraChart.TerceraChartWatermarkRenderer
    if (watermarkR)
        watermarkR.Visible = false

    let tradingToolsR = terceraChart.TerceraChartTradingToolsRenderer
    if (tradingToolsR)
    {
        tradingToolsR.ShowOrders = false;
        tradingToolsR.ShowPositions = false;
        tradingToolsR.ShowVisualTradingOnLeftSide = false;
        tradingToolsR.ShowEvents = false;
        tradingToolsR.ShowAlerts = false;
    }

    let bordersR = terceraChart.TerceraChartBordersRenderer
    if (bordersR)
    {
        bordersR.axisBackPen = new Pen(axisColor, 1)
        bordersR.timeScaleAxisPen = bordersR.priceScaleAxisPen = new Pen(axisColor, 1)
    }

    let mainPriceR = terceraChart.mainPriceRenderer
    if (mainPriceR)
    {
        terceraChart.model.SetChartDrawingType(TerceraChartDrawingType.Solid) // Line style
        mainPriceR.Visible = true
        mainPriceR.barsUpBorderColorPen = new Pen(theme.PortfolioReturnsChartMainLineColor, 1)
        mainPriceR.barsDownBorderColorPen = new Pen(theme.PortfolioReturnsChartMainLineColor, 1)
        mainPriceR.barsUpColorBrush = new SolidBrush(theme.PortfolioReturnsChartMainLineColor)
        mainPriceR.barsDownColorBrush = new SolidBrush(theme.PortfolioReturnsChartMainLineColor)
    }

    let crossHairR = terceraChart.TerceraChartCrossHairRenderer
    if (crossHairR)
    {
        crossHairR.TrackPriceColor = theme.Chart_TrackCursorBackColor
        crossHairR.TrackPriceStyle = 0
        crossHairR.TrackPriceWidth = 1
        crossHairR.ShowTrackVisibleLine = this.chartCrossHairVisibility
        crossHairR.TrackCursorBackColorBrush = new SolidBrush(theme.Chart_TrackCursorBackColor)
        crossHairR.TrackCursorFontBrush = new SolidBrush(theme.Chart_TrackCursorFontColor)
    }

    let infoWindowR = terceraChart.TerceraChartInfoWindowRenderer
    if (infoWindowR)
    {
        infoWindowR.Visible = true
        infoWindowR.forceInfoWindowShowingType = InfoWindowShowingType.PortfolioGrowth;
        infoWindowR.rectCloseButton = new Rectangle()
    }

    let newAlertR = terceraChart.TerceraChartNewAlertRenderer
    if (newAlertR)
        newAlertR.Visible = false

    let scrollerR = terceraChart.windowsContainer ? terceraChart.windowsContainer.scrollerRenderer : null
    if (scrollerR)
    {
        scrollerR.Visible = true
        scrollerR.backBrushChartScroll = new SolidBrush(axisColor)
    }

    let zoomR = terceraChart.windowsContainer ? terceraChart.windowsContainer.zoomRenderer : null
    if (zoomR)
    {
        zoomR.Visible = true
        zoomR.ThemeChanged()
    }

    let spreadR = terceraChart.SpreadRenderer
    if (spreadR)
    {
        spreadR.withAsset = true
        spreadR.PriceIndicatorDrawingType = TercaraChartPriceIndicatorType.ScaleMarkerWithLine
        spreadR.LastPrice_LastIndicatorColor = theme.Chart_lastPrice_LastIndicatorColor
    }

    let timeToNextR = terceraChart.TerceraChartTimeToNextBarRenderer
    if (timeToNextR)
    {
        timeToNextR.Visible = false
        if (timeToNextR.refreshTimerID)
            clearInterval(timeToNextR.refreshTimerID)
    }

    if (terceraChart.mainWindow)
        terceraChart.mainWindow.AutoScale = true

    terceraChart.TerceraChartFont.Height = 10
    this.terceraChartRactive.set('backgroundColorBottom', chartBackGroundColor)

    terceraChart.IsDirty()
}

PortfolioPerformance.prototype.updateChartAsset = function ()
{
    if (!this.terceraChartRactive || !this.terceraChartRactive.terceraChart) return

    let terceraChart = this.terceraChartRactive.terceraChart,
        acc = terceraChart.Account(),
        assetBalance = acc.GetAssetBalanceCorrect(),
        asset = assetBalance.Asset,
        yScaleRendererSettings = terceraChart.yScaleRendererSettings

    if (yScaleRendererSettings)
        yScaleRendererSettings.Asset = asset
}


PortfolioPerformance.prototype.GetCashItems = function ()  // line graphic data prepare (replace mainPriceRenderer data with PortfolioCache.portfolioGrowth)
{
    if (!this.terceraChartRactive || !this.terceraChartRactive.terceraChart)
        return

    let chart = this.terceraChartRactive.terceraChart

    var growth = DataCache.PortfolioCache.portfolioGrowth

    if (!growth || !growth.length) return

    let startDate = new Date(growth[0].Date)

    startDate.setDate(0)

    const biInput = new BaseIntervalInputParams();
    biInput.LeftTimeTicks = startDate - TimeSpanPeriods.TicksPerDay;
    biInput.Open = 0;
    biInput.Close = 0;
    biInput.High = 0;
    biInput.Low = 0;

    let zeroInterval = new BaseInterval(biInput, Periods.DAY),
        intervals = [zeroInterval]  // нужно чтоб был нулевой пустой элемент иначе реальный нулевой (первый) бар не помещается в график при определенных скейлах

    let prevClose = 0

    for (let i = 0; i < growth.length; i++)
    {
        let g = growth[i],
            val = g.AbsoluteAmount,
            date = g.Date

        if (!val) val = MathUtils.MATH_ROUND_EPSILON    // иначе месяц c нулевым % не рисует :(

        let biInput = new BaseIntervalInputParams();
        biInput.Open = prevClose;
        biInput.Close = val;
        biInput.High = Math.max(prevClose, val);
        biInput.Low = Math.min(prevClose, val);
        biInput.LeftTimeTicks = date;

        let bI = new BaseInterval(biInput, Periods.DAY)

        intervals.push(bI)

        prevClose = val
    }

    let tf = new TFInfo(),
        cI = new CashItem(null, null, tf.Copy({ period: Periods.DAY, historyType: HistoryType.LAST }))

    cI.historyLoaded(intervals)

    chart.model.HistoryFrom = startDate
    chart.LoadHistoryFinish(cI)
}



PortfolioPerformance.prototype.onResize = function ()
{
    this.setPanelSize()
    this.setChartPosition()

    this.set('splitterX', this.get('width') - this.get('chartWidth'))
}

PortfolioPerformance.prototype.setPanelSize = function ()
{
    if (!MainWindowManager.MainWindow)
        return

    let width = MainWindowManager.MainWindow.width,
        height = this.get('height') || MainWindowManager.MainWindow.height
    height = this.el.clientHeight - 80

    let cW = this.get('chartWidth')
    if (!cW)
    {
        cW = width / 2 - 25
        height = MainWindowManager.MainWindow.height * 0.45 - 113
        this.set('chartWidth', cW)
        this.set('splitterX', width - cW)
    }


    this.set({ width: width, height: height })

    this.setDiagramSize(width - cW, height)// - 113)
}


PortfolioPerformance.prototype.setDiagramSize = function (width, height)    // container sizes
{
    let cx = width / 2, cy = height / 2, size = Math.min(cx, cy),
        diagramWidth = width,
        // diagramHeight = Math.round(height * 0.45) - 67,      // .graphics-block.height = 45% , Title + switcher = 67
        diagramHeight = Math.round(height) - 67,
        diagramSize = Math.min(diagramWidth, diagramHeight)

    if (diagramHeight > 0)
    {
        let switcherTop = 12, switcherH = 30, switcherOffset = switcherTop + switcherH,
            fontSize = diagramSize < 224 ? diagramSize / 10 : 24,
            smallFontSize = fontSize * PortfolioPerformance.SMALL_FONT_TO_BIG_COEF,
            diagramInsideTitlesTop = (switcherOffset - height) / 2 - fontSize * 1.5 + WINDOW_SIDE_BORDERS_WIDTH

        this.set({
            diagramWidth: diagramWidth, diagramHeight: diagramHeight,
            diagramInsideTextsContainerTop: diagramInsideTitlesTop,
            diagramInsideBigFontSize: fontSize,
            diagramInsideSmallFontSize: smallFontSize,
            switcherTop: switcherTop
        })
    }

}

PortfolioPerformance.SMALL_FONT_TO_BIG_COEF = 0.5625;        // отношение меньшего шрифта к большему для надписей внутри диаграммы

PortfolioPerformance.prototype.setChartPosition = function ()
{
    if (!this.terceraChartRactive || !this.terceraChartRactive.terceraChart)
        return

    var left = 0,
        top = 0,
        width = this.get('chartWidth') - 30,
        height = this.get('height') + 25

    this.terceraChartRactive.setBounds(left, top, width, height);
    this.terceraChartRactive.terceraChart.OnResize(Math.abs(width), Math.abs(height));
}

PortfolioPerformance.prototype.TickAsync = function ()
{
    if (this.terceraChartRactive && this.terceraChartRactive.terceraChart && this.terceraChartRactive.terceraChart.needRedraw)
    {
        this.terceraChartRactive.terceraChart.needRedraw = false;
        this.terceraChartRactive.terceraChart.Draw();
    }
}

PortfolioPerformance.prototype.applyCursor = function (cursor)
{
    if (this.terceraChartRactive)
        this.terceraChartRactive.setCursor(cursor);
};

PortfolioPerformance.prototype.GetChartContextMenu = function (e)
{
    var properties = this.Properties(),
        pGridTime = DynProperty.getPropertyByName(properties, "PortfolioPerformance.GridTime"),
        pGridPercent = DynProperty.getPropertyByName(properties, "PortfolioPerformance.GridPrice"),
        pScrollbar = DynProperty.getPropertyByName(properties, "PortfolioPerformance.ScrollBar"),
        pInfoWindow = DynProperty.getPropertyByName(properties, "PortfolioPerformance.infowindow_visible")

    var menuItems = [
        {
            text: Resources.getResource('portfolio.performance.growth.contextMenu.Style'),
            tag: 'Style',
            enabled: true,
            subitems: [
                { text: Resources.getResource("chart.tool.line"), tag: TerceraChartDrawingType.Line, enabled: true, canCheck: true },
                { text: Resources.getResource("chart.tool.dot"), tag: TerceraChartDrawingType.Dot, enabled: true, canCheck: true },
                { text: Resources.getResource("chart.tool.dotline"), tag: TerceraChartDrawingType.DotLine, enabled: true, canCheck: true },
                { text: Resources.getResource("chart.tool.forest"), tag: TerceraChartDrawingType.Forest, enabled: true, canCheck: true },
                { text: Resources.getResource("chart.tool.solid"), tag: TerceraChartDrawingType.Solid, enabled: true, canCheck: true }
            ]
        },
        {
            text: Resources.getResource('portfolio.performance.growth.contextMenu.InfoWindow'),
            tag: 'InfoWindow',
            enabled: true,
            subitems: [
                {
                    text: Resources.getResource('portfolio.performance.growth.contextMenu.InfoWindow.Separate'),
                    tag: "Separate",
                    checked: pInfoWindow ? pInfoWindow.value : false,
                    enabled: true,
                    canCheck: true,
                    event: function ()
                    {
                        if (pInfoWindow)
                        {
                            pInfoWindow.value = !pInfoWindow.value
                            this.callBack([pInfoWindow])
                        }
                    }.bind(this)
                },
                {
                    text: Resources.getResource('portfolio.performance.growth.contextMenu.InfoWindow.Hidden'),
                    tag: "Hidden",
                    checked: pInfoWindow ? !pInfoWindow.value : false,
                    enabled: true,
                    canCheck: true,
                    event: function ()
                    {
                        if (pInfoWindow)
                        {
                            pInfoWindow.value = !pInfoWindow.value
                            this.callBack([pInfoWindow])
                        }
                    }.bind(this)
                }
            ]
        },
        { separator: true },
        {
            text: Resources.getResource('portfolio.returns.contextMenu.View'),
            tag: 'View',
            enabled: true,
            subitems: [
                {
                    text: Resources.getResource('portfolio.returns.contextMenu.GridTime'),
                    tag: "GridTime",
                    checked: pGridTime ? pGridTime.value.Checked : false,
                    enabled: true,
                    canCheck: true,
                    event: function ()
                    {
                        if (pGridTime)
                        {
                            pGridTime.value.Checked = !pGridTime.value.Checked
                            this.callBack([pGridTime])
                        }
                    }.bind(this)
                },
                {
                    text: Resources.getResource('portfolio.returns.contextMenu.GridPercent'),
                    tag: "GridPercent",
                    checked: pGridPercent ? pGridPercent.value.Checked : false,
                    enabled: true,
                    canCheck: true,
                    event: function ()
                    {
                        if (pGridPercent)
                        {
                            pGridPercent.value.Checked = !pGridPercent.value.Checked
                            this.callBack([pGridPercent])
                        }
                    }.bind(this)
                }, {
                    text: Resources.getResource('portfolio.returns.contextMenu.Scrollbar'),
                    tag: "Scrollbar",
                    checked: pScrollbar ? pScrollbar.value : false,
                    enabled: true,
                    canCheck: true,
                    event: function ()
                    {
                        if (pScrollbar)
                        {
                            pScrollbar.value = !pScrollbar.value
                            this.callBack([pScrollbar])
                        }
                    }.bind(this)
                }
            ]
        },
        { separator: true },
        {
            text: Resources.getResource('portfolio.performance.growth.contextMenu.Refresh'),
            tag: "Refresh",
            enabled: true,
            event: function () { this.updateGrowth() }.bind(this)
        },
        {
            text: Resources.getResource('portfolio.performance.growth.contextMenu.Settings'),
            tag: "Settings",
            enabled: true,
            event: this.OpenChartSettings.bind(this)
        }
    ];

    let style = DynProperty.getPropertyByName(properties, "PortfolioPerformance.gr_type"),
        styleMenuItem = menuItems[0]
    if (styleMenuItem)
    {
        let chart = this.terceraChartRactive ? this.terceraChartRactive.terceraChart : null
        for (let i = 0; i < styleMenuItem.subitems.length; i++)
        {
            let subitem = styleMenuItem.subitems[i]
            subitem.event = function ()
            {
                if (chart)
                    chart.chartController.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.Style), subitem.tag);
            }

            if (subitem.tag == style.value)
                subitem.checked = true
        }
    }

    return menuItems
}

PortfolioPerformance.prototype.OpenChartSettings = function (e)
{
    PanelSettingsScreen.EditProperties(this, null, Resources.getResource('portfolio.returns.contextMenu.Settings'));
}

PortfolioPerformance.PropertyPrefix = 'PortfolioPerformance.';

PortfolioPerformance.prototype.callBack = function (properties)
{
    let p = DynProperty.getPropertyByName(properties, "PortfolioPerformance.AcctNumber"), acc = null;
    if (p)
        acc = DataCache.Accounts[p.value]
    if (acc)
        this.set('selectedAccount', acc)

    p = DynProperty.getPropertyByName(properties, "PortfolioPerformance.isPortfolioAllocationSelected");
    if (p) this.set('isPortfolioAllocationSelected', p.value)

    let chartR = this.terceraChartRactive

    let propsForChart = [],
        ourPropertyPrefix = PortfolioPerformance.PropertyPrefix
    for (let i = 0; i < properties.length; i++)
    {
        let prop = properties[i],
            isPortfolioReturnProperty = prop && prop.name && prop.name.indexOf(ourPropertyPrefix) == 0

        if (isPortfolioReturnProperty)
        {
            prop.name = prop.name.substr(ourPropertyPrefix.length)
            propsForChart.push(prop)
        }
    }

    if (this.portfolioDiagramSettings)
        this.portfolioDiagramSettings.callBack(properties)

    if (chartR)
        chartR.callBack(propsForChart)

    SessionSettings.savePortfolioSettings(properties)
}

PortfolioPerformance.prototype.Properties = function ()
{
    let allChartProperties = []

    if (this.terceraChartRactive)
        allChartProperties = this.terceraChartRactive.Properties()

    var properties = []

    let acc = this.get('selectedAccount')
    if (acc)
    {
        let prop = new DynProperty("PortfolioPerformance.AcctNumber", acc.AcctNumber, DynProperty.STRING, DynProperty.HIDDEN_GROUP);
        properties.push(prop);
    }

    let switcherLeft = this.get('isPortfolioAllocationSelected')
    if (acc)
    {
        let prop = new DynProperty("PortfolioPerformance.isPortfolioAllocationSelected", switcherLeft, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP);
        properties.push(prop);
    }

    let showGroupInTimeScale = "#2#" + Resources.getResource("property.SeparatorGroup.Show"),
        viewInInfoWindow = '#1#' + Resources.getResource('property.ViewSeparatorGroup')

    for (let i = 0; i < allChartProperties.length; i++)
    {
        let prop = allChartProperties[i],
            needToAdd = false

        if (!prop) continue

        if (prop.group === DynProperty.VIEW_GROUP) 
        {
            let separGroup = prop.separatorGroup
            if (separGroup) 
            {       // Background colors                // Crosshair                                // Grid
                if ((separGroup.indexOf("#-1#") == 0) || (separGroup.indexOf("#-3#") == 0) || (separGroup.indexOf("#-4#") == 0))
                    needToAdd = true
                // Scroll
                if (separGroup.indexOf("#3#") == 0 && prop.name == "ScrollBar")
                {
                    needToAdd = true
                    prop.localizationKey = 'property.PortfolioReturns.ScrollbarVisibility'
                }
                if (separGroup.indexOf("#-4#") == 0 && prop.name == 'GridPrice')
                    prop.localizationKey = 'property.PortfolioPerformance.GridPrice'
            }
        }
        else if (prop.group === DynProperty.TIME_SCALE_GROUP && prop.separatorGroup != showGroupInTimeScale)    // переиспользуем все, кроме раздела Show
            needToAdd = true
        else if (prop.group === DynProperty.PRICE_SCALE_GROUP)
        {
            let separGroup = prop.separatorGroup
            if (separGroup) 
            {
                if (separGroup.indexOf('#1#') == 0 && prop.name == 'autoYScale')    // zoom
                    needToAdd = true
                if (separGroup.indexOf('#3#') == 0)                                 // appereance
                    needToAdd = true
                //highlight percent
                if (separGroup.indexOf('#4#') == 0 && (prop.name == 'PriceIndicatorDrawingType' || prop.name == 'lastPrice_LastIndicatorColor'))
                {
                    prop.localizationKey = 'property.PortfolioPerformance.' + (prop.name == 'PriceIndicatorDrawingType' ? 'PercentIndicatorDrawingType' : 'CurrentPercentColor')
                    prop.separatorGroup = '#4#' + Resources.getResource('property.SeparatorGroup.HighlightPortfolioBalance')
                    needToAdd = true
                }

                if (needToAdd)
                    prop.group = DynProperty.PORTFOLIO_BALANCE_SCALE
            }
        }
        else if (prop.group === DynProperty.DATA_STYLE_GROUP)
        {
            if (prop.name == 'gr_type')
            {
                var objVariants = [];
                var allowedStyles = [TerceraChartDrawingType.Line, TerceraChartDrawingType.Dot, TerceraChartDrawingType.DotLine, TerceraChartDrawingType.Forest, TerceraChartDrawingType.Solid]
                var allowedStyles_len = allowedStyles.length;
                for (var j = 0; j < allowedStyles_len; j++)
                {
                    var allowedStyle = allowedStyles[j];
                    objVariants.push({
                        text: TerceraChartUtils.ToLocalizedTerceraChartDrawingType(allowedStyle),
                        value: allowedStyle
                    });
                }
                prop.objectVariants = objVariants;
                if (prop.assignedProperty)
                    for (var j = 0; j < prop.assignedProperty.length; j++)
                        prop.assignedProperty[j] = PortfolioPerformance.PropertyPrefix + prop.assignedProperty[j]
            }
            if (prop.name != 'HistoryType')
            {
                prop.group = DynProperty.DATA_STYLE_GROUP;
                needToAdd = true
            }
        }
        else if (prop.group === DynProperty.DATA_BOX_GROUP)
        {
            if (prop.separatorGroup == viewInInfoWindow && prop.name != 'IsPortfolioGrowthVisible' && prop.name != 'IsDateVisible')
                continue

            needToAdd = true
        }

        if (needToAdd)
        {
            if (prop.name.indexOf(PortfolioPerformance.PropertyPrefix) == -1)
                prop.name = PortfolioPerformance.PropertyPrefix + prop.name
            properties.push(prop)
        }
    }

    if (this.portfolioDiagramSettings)
        properties = properties.concat(this.portfolioDiagramSettings.Properties())

    return properties;
}

// Splitter region

PortfolioPerformance.prototype.splitterMouseDown = function (e)
{
    this.set('splitterDrag', true);
    this.splitterDragStartX = this.get('splitterX')
}
PortfolioPerformance.prototype.splitterMouseMove = function (e)
{
    if (!this.get('splitterDrag'))
        return

    let splitterX = Math.min(Math.max(e.original.x, PortfolioPerformance.DIAGRAM_CONTAINER_MIN_WIDTH), MainWindowManager.MainWindow.width - 110)

    this.set({
        splitterX: splitterX,
        chartWidth: this.get('width') - splitterX
    })

    this.onResize()
}

PortfolioPerformance.DIAGRAM_CONTAINER_MIN_WIDTH = 250;

PortfolioPerformance.prototype.splitterMouseUp = function (e)
{
    if (!this.get('splitterDrag'))
        return
    this.set('splitterDrag', false);
    this.set('chartWidth', this.get('width') - this.get('splitterX'))
    this.onResize()
}

PortfolioPerformance.prototype.onMouseMove = function (e)
{
    this.splitterMouseMove(e)
}
PortfolioPerformance.prototype.onMouseUp = function (e)
{
    if (this.get('splitterDrag'))
        this.splitterMouseUp()

    if (e.original && e.original.button === MouseButtons.Right)
    {
        var contextMenuItems = this.GetContextMenu(e, this);
        if (contextMenuItems !== null)
            contextMenuHandler.Show(contextMenuItems, e.original.clientX, e.original.clientY);
    }
}

PortfolioPerformance.Scroll = {
    addScroll: function (node)
    {
        $(node).mCustomScrollbar({
            theme: "tercera",
            scrollButtons: {
                enable: true,
                scrollType: "stepless"
            },
            mouseWheel: { enable: true },
            keyboard: { enable: true },
            advanced: { updateOnContentResize: true }
        });
    },
    removeScroll: function (node)
    {
        $(node).mCustomScrollbar("destroy");
    }
};

PortfolioPerformance.prototype.GetContextMenu = function (e)    // for radial diagram
{
    var isAllocationPortfolio = this.get('isPortfolioAllocationSelected')

    var diagramSettings = this.portfolioDiagramSettings,
        portfolioDiagramSettingsProps = diagramSettings.Properties(),
        percentProp = DynProperty.getPropertyByName(portfolioDiagramSettingsProps, "PortfolioPerformanceTotalReturnVisible"),
        balanceProp = DynProperty.getPropertyByName(portfolioDiagramSettingsProps, "PortfolioPerformancePortfolioBalanceVisible")

    var menuItems = [
        {
            text: Resources.getResource('portfolio.performance.contextMenu.AllocationType'),
            enabled: true,
            subitems: [
                { text: Resources.getResource("portfolio.performance.contextMenu.AllocationType.Portfolio"), enabled: true, canCheck: true, checked: isAllocationPortfolio, event: this.onAllocationSwitcherClick.bind(this) },
                { text: Resources.getResource("portfolio.performance.contextMenu.AllocationType.Model"), enabled: true, canCheck: true, checked: !isAllocationPortfolio, event: this.onAllocationSwitcherClick.bind(this) },
            ]
        },
        {
            text: Resources.getResource('portfolio.returns.contextMenu.View'),
            tag: 'View',
            enabled: true,
            subitems: [
                {
                    text: Resources.getResource('portfolio.performance.contextMenu.View.Total'),
                    tag: "TotalReturn",
                    checked: percentProp.value,
                    enabled: true,
                    canCheck: true,
                    event: function ()
                    {
                        percentProp.value = !percentProp.value
                        diagramSettings.callBack([percentProp])
                    }
                }, {
                    text: Resources.getResource('portfolio.performance.contextMenu.View.PortfolioBalance'),
                    tag: "PortfolioBalance",
                    checked: balanceProp.value,
                    enabled: true,
                    canCheck: true,
                    event: function ()
                    {
                        balanceProp.value = !balanceProp.value
                        diagramSettings.callBack([balanceProp])
                    }
                }
            ]
        },
        { separator: true },
        {
            text: Resources.getResource('portfolio.performance.growth.contextMenu.Settings'),
            tag: "Settings",
            enabled: true,
            event: this.OpenSettings.bind(this)
        }
    ]

    return menuItems
}

PortfolioPerformance.prototype.OpenSettings = function (e)
{
    PanelSettingsScreen.EditProperties(this.portfolioDiagramSettings, null, Resources.getResource('portfolio.DiagramSettings.header'));
}

let PortfolioDiagramSettings = function (parent)
{
    this.parent = parent
}

PortfolioDiagramSettings.prototype.Properties = function ()
{
    var properties = []

    var performancePanel = this.parent
    if (!performancePanel) return properties

    var separatorGroup = '#1# Background colors'
    var prop = new DynProperty("PortfolioPerformanceDiagramBackColor", performancePanel.get('diagramBackGroundColor'), DynProperty.COLOR, DynProperty.VIEW_GROUP);
    prop.separatorGroup = separatorGroup
    properties.push(prop);

    separatorGroup = '#2# Total return'
    prop = new DynProperty("PortfolioPerformanceTotalReturnVisible", performancePanel.get('diagramLabelPercentVisible'), DynProperty.BOOLEAN, DynProperty.VIEW_GROUP);
    prop.separatorGroup = separatorGroup
    properties.push(prop);

    var posColor = performancePanel.get('diagramPercentColorUp'),
        negColor = performancePanel.get('diagramPercentColorDown'),
        prop = new DynProperty("PortfolioPerformanceTotalReturnColors", new PairColor(posColor, negColor, 'Pos', 'Neg'), DynProperty.PAIR_COLOR, DynProperty.VIEW_GROUP);
    prop.separatorGroup = separatorGroup
    properties.push(prop);

    separatorGroup = '#3# Portfolio balance'
    prop = new DynProperty("PortfolioPerformancePortfolioBalanceVisible", performancePanel.get('diagramLabelBalanceVisible'), DynProperty.BOOLEAN, DynProperty.VIEW_GROUP);
    prop.separatorGroup = separatorGroup
    properties.push(prop);

    var prop = new DynProperty("PortfolioPerformancePortfolioBalanceColor", performancePanel.get('diagramLabelBalanceColor'), DynProperty.COLOR, DynProperty.VIEW_GROUP);
    prop.separatorGroup = separatorGroup
    properties.push(prop);

    return properties
}

PortfolioDiagramSettings.prototype.callBack = function (properties)
{
    var performancePanel = this.parent
    if (!performancePanel) return

    var p = DynProperty.getPropertyByName(properties, "PortfolioPerformanceDiagramBackColor");
    if (p) performancePanel.set('diagramBackGroundColor', p.value)

    var p = DynProperty.getPropertyByName(properties, "PortfolioPerformanceTotalReturnVisible");
    if (p) performancePanel.set('diagramLabelPercentVisible', p.value)

    var p = DynProperty.getPropertyByName(properties, "PortfolioPerformanceTotalReturnColors");
    if (p)
    {
        let pairColor = p.value
        performancePanel.set('diagramPercentColorUp', pairColor.Color1)
        performancePanel.set('diagramPercentColorDown', pairColor.Color2)
    }

    var p = DynProperty.getPropertyByName(properties, "PortfolioPerformancePortfolioBalanceVisible");
    if (p) performancePanel.set('diagramLabelBalanceVisible', p.value)

    var p = DynProperty.getPropertyByName(properties, "PortfolioPerformancePortfolioBalanceColor");
    if (p) performancePanel.set('diagramLabelBalanceColor', p.value)
}