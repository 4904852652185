import { Account } from '../../../../Commons/cache/Account';
import { type AssetBalance } from '../../../../Commons/cache/AssetBalance';
import { Resources } from '../../../../Commons/properties/Resources';
import { AccountFeature } from '../../../../Utils/Account/AccountFeature';
import { GeneralGroupEnum } from '../../../../Utils/Enums/AccountDetailsEnum';
import { type IGroupController } from './IGroupController';

export class GeneralGroupController implements IGroupController {
    _account: Account = null;
    _assetBalance: AssetBalance = null;

    constructor (account: Account, assetBalance: AssetBalance) {
        this._account = account;
        this._assetBalance = assetBalance;
    }

    getItemName (type: GeneralGroupEnum): string {
        const itemNameKey = this.getItemNameKey(type);

        if (isNullOrUndefined(itemNameKey)) {
            return 'use getItemName to add new item name';
        }

        return Resources.getResource(itemNameKey);
    }

    getItemValue (type: GeneralGroupEnum): number {
        const curAccount = this._account;
        const assetBalance = this._assetBalance;
        const UseAccCurrency = true;

        switch (type) {
        case GeneralGroupEnum.Balance:
            return Account.GetAccountFeature(AccountFeature.Balance, curAccount, assetBalance);
        case GeneralGroupEnum.CurBalance:
            return Account.GetAccountFeature(AccountFeature.ProjectedBalance, curAccount, assetBalance);
        case GeneralGroupEnum.BalancePlusAllRisks:
            return Account.GetAccountFeature(AccountFeature.BalancePlusAllRisks, curAccount, assetBalance);
        case GeneralGroupEnum.AccountValue:
            return NaN;
        case GeneralGroupEnum.CreditValue:
            return Account.GetAccountFeature(AccountFeature.CreditValue, curAccount, assetBalance);
        case GeneralGroupEnum.AvailableMargin:
            return Account.GetAccountFeature(AccountFeature.AvailableFunds, curAccount, assetBalance);
        case GeneralGroupEnum.LockedBalance:
            return Account.GetAccountFeature(AccountFeature.BlockedBalance, curAccount, assetBalance);
        case GeneralGroupEnum.CashBalance:
            return Account.GetAccountFeature(AccountFeature.CashBalance, curAccount, assetBalance);
        case GeneralGroupEnum.WithdrawalAvailable:
            return Account.GetAccountFeature(AccountFeature.WithdrawalAvailable, curAccount, assetBalance);
        case GeneralGroupEnum.InterestRate:
            return Account.GetAccountFeature(AccountFeature.InterestRatePercent, curAccount, assetBalance);
        case GeneralGroupEnum.EstimateValue:
            return NaN;
        case GeneralGroupEnum.UnsettledCash:
            return Account.GetAccountFeature(AccountFeature.UnsettledCash, curAccount, assetBalance);
        case GeneralGroupEnum.IncomingFunds:
            return Account.GetAccountFeature(AccountFeature.IncomingFunds, curAccount, assetBalance);
        case GeneralGroupEnum.StocksValue:
            return UseAccCurrency ? curAccount.StockValue : curAccount.StockValueCrossPrice;
        case GeneralGroupEnum.OptionValue:
            return Account.GetAccountFeature(AccountFeature.OptionValue, curAccount, assetBalance);
        case GeneralGroupEnum.StocksLiquidity:
            return Account.GetAccountFeature(AccountFeature.StocksLiquidity, curAccount, assetBalance);
        case GeneralGroupEnum.Collateral:
            return assetBalance.Collateral;
        case GeneralGroupEnum.BuyingPower:
            return Account.GetAccountFeature(AccountFeature.BuyingPower, curAccount, assetBalance);
        case GeneralGroupEnum.GivenLeverage:
            return Account.GetAccountFeature(AccountFeature.GivenLeverage, curAccount, assetBalance);
        case GeneralGroupEnum.OpenBalance:
            return Account.GetAccountFeature(AccountFeature.OpenBalance, curAccount, assetBalance);
        case GeneralGroupEnum.TodaysPnLPercent:
            return Account.GetAccountFeature(AccountFeature.TodaysPnLPercent, curAccount, assetBalance);
        }
    }

    getItemFormattedValue (type: GeneralGroupEnum): string {
        const curAccount = this._account;
        const assetBalance = this._assetBalance;
        const value = this.getItemValue(type);

        switch (type) {
        case GeneralGroupEnum.Balance:
            return Account.GetAccountFeatureString(value, AccountFeature.Balance, curAccount, this._assetBalance);
        case GeneralGroupEnum.CurBalance:
            return Account.GetAccountFeatureString(value, AccountFeature.ProjectedBalance, curAccount, this._assetBalance);
        case GeneralGroupEnum.BalancePlusAllRisks:
            return Account.GetAccountFeatureString(value, AccountFeature.BalancePlusAllRisks, curAccount, this._assetBalance);
        case GeneralGroupEnum.AccountValue:
            return '';
        case GeneralGroupEnum.CreditValue:
            return Account.GetAccountFeatureString(value, AccountFeature.CreditValue, curAccount, this._assetBalance);
        case GeneralGroupEnum.AvailableMargin:
            return Account.GetAccountFeatureString(value, AccountFeature.AvailableFunds, curAccount, this._assetBalance);
        case GeneralGroupEnum.LockedBalance:
            return Account.GetAccountFeatureString(value, AccountFeature.BlockedBalance, curAccount, assetBalance);
        case GeneralGroupEnum.CashBalance:
            return Account.GetAccountFeatureString(value, AccountFeature.CashBalance, curAccount, assetBalance);
        case GeneralGroupEnum.WithdrawalAvailable:
            return Account.GetAccountFeatureString(value, AccountFeature.WithdrawalAvailable, curAccount, assetBalance);
        case GeneralGroupEnum.InterestRate:
            return Account.GetAccountFeatureString(value, AccountFeature.InterestRatePercent, curAccount, assetBalance);
        case GeneralGroupEnum.EstimateValue:
            return '';
        case GeneralGroupEnum.UnsettledCash:
            return Account.GetAccountFeatureString(value, AccountFeature.UnsettledCash, curAccount, assetBalance);
        case GeneralGroupEnum.IncomingFunds:
            return Account.GetAccountFeatureString(value, AccountFeature.IncomingFunds, curAccount, assetBalance);
        case GeneralGroupEnum.StocksValue:
            return assetBalance.formatPriceExactly(value);
        case GeneralGroupEnum.OptionValue:
            return Account.GetAccountFeatureString(value, AccountFeature.OptionValue, curAccount, assetBalance);
        case GeneralGroupEnum.StocksLiquidity:
            return Account.GetAccountFeatureString(value, AccountFeature.StocksLiquidity, curAccount, assetBalance);
        case GeneralGroupEnum.Collateral:
            return assetBalance.formatPrice(assetBalance.Collateral);
        case GeneralGroupEnum.BuyingPower:
            return Account.GetAccountFeatureString(value, AccountFeature.BuyingPower, curAccount, assetBalance);
        case GeneralGroupEnum.GivenLeverage:
            return Account.GetAccountFeatureString(value, AccountFeature.GivenLeverage, curAccount, assetBalance);
        case GeneralGroupEnum.OpenBalance:
            return Account.GetAccountFeatureString(value, AccountFeature.OpenBalance, curAccount, assetBalance);
        case GeneralGroupEnum.TodaysPnLPercent:
            return Account.GetAccountFeatureString(value, AccountFeature.TodaysPnLPercent, curAccount, assetBalance);
        }
    }

    getItemNameKey (type: GeneralGroupEnum): string {
        switch (type) {
        case GeneralGroupEnum.Balance:
            return 'panel.accounts.Balance';
        case GeneralGroupEnum.CurBalance:
            return 'panel.accounts.CurBalance';
        case GeneralGroupEnum.BalancePlusAllRisks:
            return 'panel.accounts.BalancePlusAllRisks';
        case GeneralGroupEnum.AccountValue:
            return 'panel.accounts.AccountValue';
        case GeneralGroupEnum.CreditValue:
            return 'panel.accounts.CreditValue';
        case GeneralGroupEnum.AvailableMargin:
            return 'panel.accounts.AvailableMargin';
        case GeneralGroupEnum.LockedBalance:
            return 'panel.accounts.LockedBalance';
        case GeneralGroupEnum.CashBalance:
            return 'panel.accounts.CashBalance';
        case GeneralGroupEnum.WithdrawalAvailable:
            return 'panel.accounts.WithdrawalAvailable';
        case GeneralGroupEnum.InterestRate:
            return 'panel.accounts.InterestRate';
        case GeneralGroupEnum.EstimateValue:
            return 'panel.accounts.Estimate value';
        case GeneralGroupEnum.UnsettledCash:
            return 'panel.accounts.UnsettledCash';
        case GeneralGroupEnum.IncomingFunds:
            return 'panel.accounts.IncomingFunds';
        case GeneralGroupEnum.StocksValue:
            return 'panel.accounts.StocksValue';
        case GeneralGroupEnum.OptionValue:
            return 'panel.accounts.OptionValue';
        case GeneralGroupEnum.StocksLiquidity:
            return 'panel.accounts.StocksLiquidity';
        case GeneralGroupEnum.Collateral:
            return 'panel.accounts.Collateral';
        case GeneralGroupEnum.BuyingPower:
            return 'panel.accounts.BuyingPower';
        case GeneralGroupEnum.GivenLeverage:
            return 'panel.accounts.GivenLeverage';
        case GeneralGroupEnum.OpenBalance:
            return 'panel.accounts.OpenBalance';
        };
    }
}
