// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class PasswordRecoveryChangeResponse extends PFixMessage {
    constructor (fieldSet: PFixFieldSet) {
        super(Message.CODE_RECOVERY_CHANGE_RESPONSE, fieldSet);
    }

    public getStatus (): any {
        return this.getFieldValue(FieldsFactory.FIELD_CHANGE_PWD_STATUS);
    }

    public getMinLength (): number {
        return this.getFieldValue(FieldsFactory.FIELD_MIN_PASSWORD_LENGTH);
    }
}
