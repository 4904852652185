// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomErrorClass, ErrorInformationStorage } from "../../Commons/ErrorInformationStorage.ts"
import { Resources } from "../../Commons/properties/Resources.ts"
import { Rectangle } from "../../Commons/Geometry.ts"
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts"
import { LinkedSystem, LinkedSystemAccLinkingValue } from "../misc/LinkedSystem.ts"
import { RiskCalculatorTemplate, OrderEditControlsTemplate } from "../../templates.js";
import { DockSystemInstance } from "../DockSystem.js"
import { Control } from "../elements/Control.js"
import { TerceraLinkControlConstants } from "../UtilsClasses/TerceraLinkControlConstants.ts"
import { TerceraMenu } from "../elements/TerceraMenu.ts"
import { OEOrderTypeSelector } from "../trading/OE/OEOrderTypeSelector.js"
import { PanelNames } from "../UtilsClasses/FactoryConstants.ts"
import { OrderEditViewBase } from "./OrderEditViewBase.js"
import { OrderType } from "../../Utils/Trading/OrderType.ts"
import { PlacedFrom } from "../../Utils/Trading/PlacedFrom.ts"
import { DynProperty } from "../../Commons/DynProperty.ts"
import { GeneralSettings } from "../../Utils/GeneralSettings/GeneralSettings.ts"
import { Instrument } from "../../Commons/cache/Instrument.ts"
import { IsAllowed } from "../../Commons/IsAllowed.ts"
import { Quantity } from "../../Utils/Trading/Quantity.ts"
import { OrderEditUpdateData } from "../../Utils/Trading/OrderEditUpdateData.ts"
import { DataCache } from "../../Commons/DataCache.ts"
import { SessionSettings } from "../../Commons/SessionSettings.ts"
import { TradingNumericErrorChecker } from "../../Commons/Trading/TradingNumericErrorChecker.ts"
import { ApplicationPanelNew } from "./ApplicationPanelNew.js"
import { OrderExecutorUtils } from "../../Commons/Trading/OrderExecutorUtils.ts";

export let RiskCalculator = OrderEditViewBase.extend({
    partials: {
        bodyPartial: RiskCalculatorTemplate,
        orderEditControls: OrderEditControlsTemplate,
    },
    data: function ()
    {
        return {
            showFooter: false,
            resizable: false,
            minWidth: 570,
            dockablePanel: false,
            showHeader: true,
            isAccountLinkShow: true,
            isSymbolLinkShow: true,

            instrument: null,
            account: null,
            quantity: null, // QuantityObject
            side: null,
            tif: null, // TIF object
            orderType: null,
            productType: null,
            productTypeShow: false,

            singleAccount: false,
            canFilterByAccount: false,

            selOrderTypeCBItem: null,
            isAlert: false,

            marginOEAllowed: false,
            marginOEVisible: false,
            // isHeaderMenuButtonShow: true,
            showAttachHeaderButton: true,
            style_addition_header_button: "js-button-attach style-width-100",
            attached: false
        }
    },
    headerLocaleKey: 'panel.riskCalculator',
    NewParametrsHandler: null,
    closeTimeout: null,
    AutoHideHeaderUpdateHandler: null,
    IsResized: false
})

RiskCalculator.prototype.updatePanelHeader = function ()
{
    let ins = this.get('instrument')
    let header = Resources.getResource(this.headerLocaleKey) + (ins && !ins.IsEmpty ? (' ' + ins.DisplayName()) : '')
    this.set({ header: header })
    if (this.AutoHideHeaderUpdateHandler)
        this.AutoHideHeaderUpdateHandler(header)
}

RiskCalculator.prototype.onInstrumentChanged = function (instrument, lastInstrument)
{
    if (!instrument || instrument === lastInstrument || !this.completed)
        return

    this.subscribeRiskSettingsUpdate(instrument, lastInstrument)

    this.instrumentSettingsUpdate()

    this.symbolLink_Out(false, instrument)
    this.updatePanelHeader()
    this.deferLayout()
}

RiskCalculator.prototype.onAccountChanged = function (account, lastAccount)
{
    if (!account)
        return

    this.accountLink_Out(false, account);
}

RiskCalculator.prototype.getType = function ()
{
    return PanelNames.RiskCalculator
}

RiskCalculator.prototype.oninit = function ()
{
    OrderEditViewBase.prototype.oninit.call(this)

    this.observe('instrument', this.onInstrumentChanged)
    this.observe('account', this.onAccountChanged)

    this.set(
        'singleAccount',
        DataCache.getNumberOfAccounts() === 1)

    this.observe(
        'orderType',
        this.onOrderTypeChanged)

    this.observe(
        'instrument account quantity side tif productType',
        this.onTradingDataChanged)

    this.on("placeOrderClick", this.onPlaceOrderClick);
    this.on("btnCancelClick", function () { this.close(); });

    // if (!Resources.isHidden('OrderEntry.InfoBlock.Visibility'))
    // {
    this.set('marginOEAllowed', true)
    this.on('onMarginOEVisibleStateChanged', this.onMarginOEVisibleStateChanged)
    // }

    // this.observe('delayedVisible snapshotVisible dataSourceVisible', this.deferLayout)
    // this.observe('dataSourceOpen', this.onDataSourceOpen)
    // DataCache.OnSnapshotResponseReceived.Subscribe(this.updateGetSnapshotBtn, this)     // обновление enability кнопок Get snapshot если на клиенте совершили Get snapshot request 
    // Account.TradeStatusChanged.Subscribe(this.updateGetSnapshotBtn, this);               // обновление enability кнопок Get snapshot при изменениях значения на сервере

    DataCache.OnUpdateAccount.Subscribe(this.onUpdateAccount, this)

    this.on("attachClick", this.AttachClick);
}

RiskCalculator.prototype.UpdateAutoHideSate = function (state)
{
    if (this.get("attached") || this.get("isAlert"))
        return;
    this.set("showAttachHeaderButton", state);
}

RiskCalculator.prototype.ForcedAutoHide = function ()
{
    if (!this.get("attached"))
        return;

    clearTimeout(this.closeTimeout);
    this.set({ visible: false });
    MainWindowManager.MainWindow.resetFocus();
}

RiskCalculator.prototype.AttachClick = function ()
{
    let attached = this.get("attached");
    if (attached)
    {
        attached = !attached
        clearTimeout(this.closeTimeout);
        this.set({ visible: true, movable: true, attached: attached })
        DockSystemInstance.removeFromAutoHide(this)
    }
    else
    {
        attached = !attached
        this.IsResized = false
        this.set({ visible: false, movable: false, attached: attached })
        DockSystemInstance.addToAutoHide(this);
    }
    this.layout();

    if (!attached)
        this.center();

    MainWindowManager.MainWindow.resetFocus();

    if (this.get("marginOEVisible") && this.Controls.marginOEControl)
        this.Controls.marginOEControl.layoutTable()

    let btnattachText = attached ? Resources.getResource('panel.external.Dettach') : Resources.getResource('panel.external.Attach');
    this.set('header_button_text', btnattachText);
}

RiskCalculator.prototype.oncomplete = function ()
{
    OrderEditViewBase.prototype.oncomplete.call(this)
    this.set({ showAttachHeaderButton: DockSystemInstance.isAutoHideAvaliable() })
    DockSystemInstance.RegisterPanel(this)

    if (this.get("isAlert"))
        this.set({ showAttachHeaderButton: false, isAccountLinkShow: false, isSymbolLinkShow: false, canLinkByAccount: false })

    if (this.get("attached"))
    {
        this.set({ visible: false, movable: false })
        DockSystemInstance.addToAutoHide(this);
        MainWindowManager.MainWindow.resetFocus();
    }
    this.onInstrumentChanged(this.get('instrument'), null)

    this.SetFirstElement()
    this.center()
};

RiskCalculator.prototype.AutoHideHover = function ()
{
    clearTimeout(this.closeTimeout);
    let visible = this.get("visible")
    if (visible)
        return
    this.set({ visible: true })
    this.ChangeAutohideLocation();
}

RiskCalculator.prototype.AutoHideLeave = function ()
{
    if (this.get("attached"))
        this.MakeMeHidden()
}

RiskCalculator.prototype.MakeMeHidden = function ()
{
    clearTimeout(this.closeTimeout);
    this.closeTimeout = setTimeout(function ()
    {
        if (!this.get("focused"))
            this.set({ visible: false })
        clearTimeout(this.closeTimeout);
    }.bind(this), RiskCalculator.TIME_TO_HIDE);
}
RiskCalculator.TIME_TO_HIDE = 1000;

RiskCalculator.prototype.ChangeAutohideLocation = function ()
{
    var newCoord = TerceraMenu.CorrectPopupLocation(new Rectangle(
        2000,
        52,
        this.get("width"),
        this.get("height")))

    this.setLocation(newCoord.newX - 27, newCoord.newY);
}

RiskCalculator.prototype.lostFocus = function ()
{
    ApplicationPanelNew.prototype.lostFocus.apply(this);
    if (!this.get("attached"))
        return

    if (MainWindowManager.TerceraInstrumentLookupDropDownForm && MainWindowManager.TerceraInstrumentLookupDropDownForm.get("visible"))
    {
        MainWindowManager.TerceraInstrumentLookupDropDownForm.AfterLostFocusWaiting = this;
        return;
    }

    if (MainWindowManager.TerceraAccountLookupDropDownForm && MainWindowManager.TerceraAccountLookupDropDownForm.get("visible"))
    {
        MainWindowManager.TerceraAccountLookupDropDownForm.AfterLostFocusWaiting = this;
        return;
    }
    this.MakeMeHidden()
};

RiskCalculator.prototype.onMouseEnter = function (event)
{
    this.AutoHideHover();
};

RiskCalculator.prototype.onMouseLeave = function (event)
{
    this.AutoHideLeave();
};

RiskCalculator.prototype.gotFocus = function ()
{
    Control.prototype.gotFocus.apply(this);
    clearTimeout(this.closeTimeout);
};

RiskCalculator.prototype.dispose = function ()
{
    if (this.get("attached"))
        DockSystemInstance.removeFromAutoHide(this)
    DockSystemInstance.UnRegisterPanel(this)

    DataCache.OnUpdateAccount.UnSubscribe(this.onUpdateAccount, this)

    let instrument = this.get('instrument')
    if (instrument)
        instrument.RiskSettingsUpdated.UnSubscribe(this.updateTradingAllowedStuff, this)

    OrderEditViewBase.prototype.dispose.call(this)
};

RiskCalculator.prototype.SetFirstElement = function ()
{
    if (this.get('singleAccount'))
        this.Controls.OEInstrumentLookup.setFocus()
    else
        this.Controls.OEAccountLookup.setFocus()
}

RiskCalculator.prototype.onPlaceOrderClick = function ()
{
    if (this.get("isAlert"))
        this.saveOrderParam();
    else
    {
        if (TradingNumericErrorChecker.HasErrors(this))
            return

        this.placeOrder(this.orderEdit)
    }
}

RiskCalculator.prototype.selectOrderType = function (newOrderType)
{
    let orderTypeDict = OrderExecutorUtils.getAllowedOrderTypeDict(this.get('account'), this.get('instrument'), null, GeneralSettings.TradingDefaults.OrderType, false)

    let orderType = orderTypeDict[newOrderType] !== undefined ? orderTypeDict[newOrderType] : orderTypeDict[OrderType.Market]

    let selectedItem = { value: orderType, text: Resources.getResource(orderType.localizationKey()) }

    this.set('selOrderTypeCBItem', selectedItem)
}

RiskCalculator.prototype.onOrderTypeChanged = function (newOrderType, oldOrderType)
{
    if (!newOrderType || newOrderType === oldOrderType)
        return

    let orderEdit = newOrderType.createOrderEditObject({
        dataCache: DataCache
    })

    this.setOrderEdit(orderEdit)

    orderEdit.updateParameters(new OrderEditUpdateData(
        null,
        this.getAllTradingDataDict()))
}

RiskCalculator.prototype.onTradingDataChanged = function (newVal, oldVal, key)
{
    let orderEdit = this.orderEdit
    if (!orderEdit) return

    let tradingDataDict = {}
    tradingDataDict[key] = newVal

    tradingDataDict.completed = this.completed

    orderEdit.updateParameters(new OrderEditUpdateData(
        null,
        tradingDataDict))

    this.deferLayout()
}

RiskCalculator.prototype.getAllTradingDataDict = function ()
{
    let tradingDataDict = {}

    let instrument = this.get('instrument')
    if (instrument) tradingDataDict.instrument = instrument

    let account = this.get('account')
    if (account) tradingDataDict.account = account

    let quantity = this.get('quantity')
    if (quantity) tradingDataDict.quantity = quantity

    let tif = this.get('tif')
    if (tif) tradingDataDict.tif = tif

    let side = this.get('side')
    if (side !== null) tradingDataDict.side = side

    let productType = this.get('productType')
    if (productType !== null) tradingDataDict.productType = productType

    tradingDataDict.completed = this.completed

    return tradingDataDict
}

RiskCalculator.prototype.layout = function ()
{
    this.updateOrderEditParametersVisibility();

    if (this.get("attached"))
        this.set('height', MainWindowManager.MainWindow.GetAvaliableHeight());
    else
    {
        this.set('height', null);
    }

    if (this.get("marginOEVisible") && this.Controls.marginOEControl)
        this.Controls.marginOEControl.layoutTable();
}

RiskCalculator.prototype.saveOrderParam = function ()
{
    if (this.NewParametrsHandler)
        this.NewParametrsHandler(this.orderEdit)
}

RiskCalculator.prototype.placeOrder = function (orderEdit)
{
    orderEdit.placedFrom = PlacedFrom.WEB_OE;
    DataCache.FOrderExecutor.placeOrderPromise(orderEdit).catch(function () 
    {
        let ex = new CustomErrorClass("RiskCalculator error", "RiskCalculator.placeOrder", "placeOrder -> placeOrderPromise");
        ErrorInformationStorage.GetException(ex);
    })
}

RiskCalculator.prototype.updateSettings = function ()
{
    let ins = this.get('instrument'), acc = this.get('account')
    if (!ins || !acc)
        return
    this.updateTradingAllowedAndReasonTooltip()
}

RiskCalculator.prototype.callBack = function (properties)
{
    ApplicationPanelNew.prototype.callBack.call(this, properties);

    this.set('instrument', this.getCallBackInstrument(properties, 'symbol'));

    let dp = DynProperty.getPropertyByName(properties, 'account');
    if (dp && dp.value) this.set('account', SessionSettings.getDefValueFromObj(DataCache.Accounts[dp.value], DataCache.Accounts));

    dp = DynProperty.getPropertyByName(properties, 'marginOEVisible');
    if (dp) this.set('marginOEVisible', dp.value);

    // dp = DynProperty.getPropertyByName(properties, 'dataSourceOpen');
    // if (dp) this.set('dataSourceOpen', dp.value);

    let attached = DynProperty.getPropValue(properties, "attached");
    this.set({ attached: attached })
};

RiskCalculator.prototype.Properties = function ()
{
    var properties = ApplicationPanelNew.prototype.Properties.call(this);
    var ins = this.get('instrument');
    if (ins) properties.push(new DynProperty("symbol", ins.GetInteriorID(), DynProperty.STRING, DynProperty.HIDDEN_GROUP));

    var acc = this.get('account');
    if (acc) properties.push(new DynProperty("account", acc.AcctNumber, DynProperty.STRING, DynProperty.HIDDEN_GROUP));

    var marginOEVisible = this.get('marginOEVisible');
    properties.push(new DynProperty("marginOEVisible", marginOEVisible, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));

    // var dataSourceOpen = this.get('dataSourceOpen');
    // properties.push(new DynProperty("dataSourceOpen", dataSourceOpen, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));

    properties.push(new DynProperty("attached", this.get("attached"), DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));

    return properties;
};

//#endregion

//#region Link

RiskCalculator.prototype.symbolLink_Out = function (newSubscriber, instrument)
{
    if (!instrument)
    {
        let ins = this.get('instrument')
        if (!ins) return
        instrument = ins
    }

    let color = this.get('symbolLinkValue')
    if (color !== TerceraLinkControlConstants.STATE_NONE)
        LinkedSystem.setSymbol(color, instrument.GetInteriorID(), newSubscriber)
}

RiskCalculator.prototype.symbolLink_In = function (symbolName)
{
    let newInstr = DataCache.getInstrumentByName(symbolName)
    if (newInstr)
        this.set('instrument', newInstr)
}

//#endregion Link

RiskCalculator.prototype.localize = function ()
{
    OrderEditViewBase.prototype.localize.call(this);

    this.set('buttonCancelText', Resources.getResource('general.messageBox.cancel'))

    let btnattachText = this.get("attached") ? Resources.getResource('panel.external.Dettach') : Resources.getResource('panel.external.Attach');
    this.set('header_button_text', btnattachText);
};

RiskCalculator.prototype.onUpdateAccount = function (account)
{
    let orderEdit = this.orderEdit
    if (!orderEdit) return

    orderEdit.onUpdateAccount(account)
}

RiskCalculator.prototype.instrumentSettingsUpdate = function ()
{
    let instrument = this.get('instrument'),
        account = this.get('account');

    if (!Instrument.IsWorkingInstrument(instrument))
        return

    if (account)
        this.updateTradingAllowedAndReasonTooltip()

    this.set('productTypeShow', instrument.isProductTypeVisible())
}

RiskCalculator.prototype.updateTradingAllowedAndReasonTooltip = function ()
{
    let instrument = this.get('instrument'),
        account = this.get('account');



    let ordertype = this.get("orderType")
    let ordT = -1;
    if (ordertype)
        ordT = this.get("orderType").id()

    let tradingAllowed = IsAllowed.IsTradingAllowed([account], instrument, ordT),
        tradingForbiddenReason = tradingAllowed.Allowed ? '' : tradingAllowed.ReasonText;

    if (tradingAllowed.Allowed && ordT === -1)
    {
        tradingForbiddenReason = Resources.getResource("Not selected order type");
        tradingAllowed.Allowed = false;
    }

    this.set({
        isAllowedResponce: tradingAllowed,
        tradingAllowed: tradingAllowed.Allowed,
        tradingForbiddenReason: tradingForbiddenReason
    })
}

RiskCalculator.prototype.subscribeRiskSettingsUpdate = function (instrument, lastInstrument)
{
    if (lastInstrument)
        lastInstrument.RiskSettingsUpdated.UnSubscribe(this.instrumentSettingsUpdate, this)

    if (instrument && !instrument.IsEmpty)
        instrument.RiskSettingsUpdated.Subscribe(this.instrumentSettingsUpdate, this)
}

RiskCalculator.prototype.onMarginOEVisibleStateChanged = function ()
{
    let oldState = this.get('marginOEVisible'),
        newState = !oldState

    this.set('marginOEVisible', newState)
    this.set('width', newState ? 685 : 350)

    if (!this.get("attached"))
        this.set('resizable', newState)

    if (!newState)
    {
        this.IsResized = false;
        this.layout();
    }

    if (this.get("attached"))
        this.ChangeAutohideLocation();
}

RiskCalculator.prototype.onQuantityChanged = function (qtyValue)
{
    let orderEdit = this.orderEdit
    if (!orderEdit) return

    let ins = orderEdit.instrument
    if (!ins) return

    let OEQty = this.Controls ? this.Controls.OEQuantity : null,
        acc = orderEdit.account, pt = orderEdit.productType,
        isAllowedResponce = this.get('isAllowedResponce')

    if (isAllowedResponce && isAllowedResponce.Allowed)     // доп валидацию по qty посчитанную position sizing-ом можно применить если торговля разрешена в IsAllowed.IsTradingAllowed 
    {
        let inLots = orderEdit.sessionSettings ? GeneralSettings.View.displayAmountInLots() : null,
            minLot = Quantity.convertQuantityValue(new Quantity(ins.getMinLot(pt, acc), true), ins, inLots),
            maxLot = Quantity.convertQuantityValue(new Quantity(ins.getMaxLot(pt, acc), true), ins, inLots)

        let tradingAllowed = qtyValue >= minLot && qtyValue <= maxLot,
            reasonLocKey = tradingAllowed ? '' :
                ('positionSizeCalculator.OEButtonDisabled.' + (qtyValue < minLot ? 'less' : 'more'))

        let reasonText = Resources.getResource(reasonLocKey)
        if (!tradingAllowed)
            reasonText = reasonText.replace('{0}', qtyValue < minLot ? minLot : maxLot)

        this.set({
            tradingAllowed: tradingAllowed,
            tradingForbiddenReason: reasonText
        })
    }

    if (OEQty)
    {
        OEQty.set('value', qtyValue)
        OEQty.updateQuantity()///// TODO !!!!! TODO !!!!!!!!!!!!!!!!!!!!!!!!!
    }

}


RiskCalculator.prototype.enableSL = function ()
{
    let orderEdit = this.orderEdit
    if (!orderEdit || !orderEdit.enableSL) return
    orderEdit.enableSL()
}


RiskCalculator.prototype.onDataSourceOpen = function () 
{
    this.deferLayout()

    let marginOE = this.Controls.marginOEControl
    if (marginOE)
    {
        let updateMarginCaller = marginOE.UpdateTable.bind(marginOE)
        setTimeout(updateMarginCaller, 100)
    }
}
