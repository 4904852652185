// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { MAMode } from '../IndicatorConstants';
import { ExpandDoubleVector } from '../DoubleMatrix';
import { IndicatorFunctions } from '../IndicatorFunctions';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';
import { InputParameterCombobox } from '../InputParamaterClasses/InputParameterCombobox';
// import { iBuildInIndicator } from '../iBuildInIndicator';

export class KAMA extends IndicatorScriptBase {
    public periodAMA: number;
    public nfast: number;
    public nslow: number;
    public G: number;
    public dK: number;
    public SourcePrice: number;
    public AMA_Trend_Type: number;

    private AMA2: any[];
    private SumAMA: any[];
    private Signal: ExpandDoubleVector;
    constructor () {
        super();
        this.ProjectName = 'Kaufman adaptive moving average';
        this.Comments = 'Kaufman Adaptive Moving Average';
        this.SetIndicatorLine('kama', Color.Gray, 1, LineStyle.SimpleChart);
        this.SetIndicatorLine('upTrendMarkers', Color.Blue, 5, LineStyle.HorizontalLine);
        this.SetIndicatorLine('downTrendMarkers', Color.Red, 5, LineStyle.HorizontalLine);
        this.SeparateWindow = false;

        this.periodAMA = 10;
        super.InputParameter(new InputParameterInteger('periodAMA', 'Period of KAMA', 1, 1, 9999));

        this.nfast = 2.0;
        super.InputParameter(new InputParameterInteger('nfast', '#Fast', 2, 1, 9999));

        this.nslow = 30.0;
        super.InputParameter(new InputParameterInteger('nslow', '#Slow', 3, 1, 9999));

        this.G = 2.0;
        super.InputParameter(new InputParameterInteger('G', undefined, 4, 1, 9999));

        this.dK = 2.0;
        super.InputParameter(new InputParameterInteger('dK', undefined, 5, 1, 9999));

        this.SourcePrice = PriceType.Close;
        super.InputParameter(new InputParameterCombobox('SourcePrice', 'Sources prices for MA', 6,
            [
                { Close: PriceType.Close },
                { Open: PriceType.Open },
                { High: PriceType.High },
                { Low: PriceType.Low },
                { Typical: PriceType.Typical },
                { Medium: PriceType.Medium },
                { Weighted: PriceType.Weighted }
            ]));

        this.AMA_Trend_Type = AmaTrendType.Fixed;
        super.InputParameter(new InputParameterCombobox('AMA_Trend_Type', 'KAMA Trend Type', 7,
            [
                { Fixed: AmaTrendType.Fixed },
                { Average: AmaTrendType.Average }
            ]));

        this.AMA2 = [];
        this.SumAMA = [];
        this.Signal = new ExpandDoubleVector();
    }

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
        this.SetIndexDrawBegin(0, this.periodAMA);
    }

    public Refresh (count, newThread): void {
        this.Signal.Dispose();
        this.Signal = new ExpandDoubleVector();
        // iBuildInIndicator.prototype.Refresh.call(this, count, newThread);
    }

    public override UpdateIndexDrawBegin (): void {
        this.SetIndexDrawBegin(0, this.periodAMA);
    }

    public override GetIndicatorShortName (): string {
        return 'KAMA(' + this.periodAMA + ';' + this.nfast + ';' + this.nslow + ';' + this.G + ';' + this.dK + ')';
    }

    public getPrice (): void {
        if (this.CurrentData.Count <= 1) {
            this.Signal[this.Signal.Length - 1] = 0;
            return;
        }

        this.Signal[this.Signal.Length - 1] = Math.abs(this.CurrentData.GetPrice(this.SourcePrice) - this.CurrentData.GetPrice(this.SourcePrice, 1));
    }

    public NextBar (): void {
        super.NextBar();

        this.Signal.Add(0.0);

        if (this.AMA_Trend_Type === AmaTrendType.Average) {
            this.AMA2.unshift(0);
            this.SumAMA.unshift(0);
        }
    }

    public OnQuote (): void {
        super.OnQuote();

        this.getPrice();

        if (this.CurrentData.Count <= this.periodAMA) {
            this.SetValue(0, this.CurrentData.GetPrice(this.SourcePrice));
            return;
        }

        const slowSC = (2.0 / (this.nslow + 1));
        const fastSC = (2.0 / (this.nfast + 1));
        const dFS = fastSC - slowSC;

        const Noise = IndicatorFunctions.CallMovingFunction(MAMode.SMA, this.Signal, this.periodAMA) * this.periodAMA;
        const ER = (Noise !== 0) ? Math.abs(this.CurrentData.GetPrice(this.SourcePrice) - this.CurrentData.GetPrice(this.SourcePrice, this.periodAMA)) / Noise : 0;
        const SSC = Math.min(Math.pow(ER * dFS + slowSC, this.G), 1);

        const prevAma = this.GetValue(0, 1);

        const ama = this.CurrentData.GetPrice(this.SourcePrice) * SSC + prevAma * (1 - SSC);
        this.SetValue(0, ama);

        let level = 0;
        if (this.AMA_Trend_Type === AmaTrendType.Fixed) {
            level = this.dK * this.CurrentData.Instrument.PointSize;
        } else {
            this.AMA2[0] = ama * ama + this.AMA2[1];
            this.SumAMA[0] = ama + this.SumAMA[1];

            const SredneeAMA = (this.SumAMA[0] - this.SumAMA[this.periodAMA]) / this.periodAMA;
            const SumKvadratAMA = this.AMA2[0] - this.AMA2[this.periodAMA];
            const dispersion = SumKvadratAMA / this.periodAMA - SredneeAMA * SredneeAMA;

            const StdAMA = (dispersion >= 0) ? Math.sqrt(dispersion) : 0;

            level = this.dK * StdAMA;
        }

        if (Math.abs(ama - prevAma) > level) {
            if (ama - prevAma > 0) {
                this.SetValue(1, ama - 15 * this.CurrentData.Instrument.PointSize);
            } else {
                this.SetValue(2, ama + 15 * this.CurrentData.Instrument.PointSize);
            }
        }
    }
}

export enum AmaTrendType {
    Fixed = 0,
    Average = 1
}
