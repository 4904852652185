// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class PFixEntitlementProductDocumentRequestMessage extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_PFIX_PRODUCT_DOCUMENT_REQ, fieldSet);
    }

    public getProductId (): number {
        return this.getFieldValue(FieldsFactory.FIELD_PRODUCT_ID);
    }

    public setProductId (value: number): void {
        this.setFieldValue(FieldsFactory.FIELD_PRODUCT_ID, value);
    }
}
