// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
export enum PositionActionEnum {
    Unspecified = 0,
    All = 1,
    CLXAll = 2,
    CLXByAccountAndInstrument = 3,
    ByAccountAndInstrument = 4,
    ByIds = 5,
    Positive = 6,
    Negative = 7,
    Long = 8,
    Short = 9,
    ReverseByIds = 10,
    ReverseByInstrument = 11,
    ReverseAll = 12,
    Mutual = 13,
    PositionModifying = 14,
    ModifyProductType = 15,
    ExerciseOption = 16,
    ExerciseCancelVisibility = 17,
    // return ExerciseCancelAllowStruct
    ExerciseCancel = 18
}
