import { TerceraWindowBase } from './TerceraWindowBase_ts';
import { OptionCalculatorScreenTemplate } from '../../templates';
import { Resources } from '../../Commons/properties/Resources';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { TerceraButtonInfo } from '../elements/TerceraGroupButtons';
import { type IOptionCalculator } from '../../Commons/cache/OptionMaster/OptionCalculator/IOptionCalculator';
import { OptionPutCall } from '../../Utils/Instruments/OptionPutCall';
import { GreeksFormatter } from '../../Commons/cache/OptionMaster/OptionTrader/GreeksFormatter';

export class OptionCalculatorScreen extends TerceraWindowBase {
    private static _screen: OptionCalculatorScreen;
    private static _optionCalculator: IOptionCalculator;

    constructor () {
        super();
    }

    public override oninit (): void {
        super.oninit();
        this.on('inputParametersGroupButtons_btnClick', this.inputParametersGroupButtons_btnClicked);
        this.on('calculateBtnClick', this.onCalculateBtnClicked);
        this.on('resetBtnClick', this.onResetBtnClicked);
    }

    public override onteardown (): void {
        this.off('inputParametersGroupButtons_btnClick', this.inputParametersGroupButtons_btnClicked);
        this.off('calculateBtnClick', this.onCalculateBtnClicked);
        this.off('resetBtnClick', this.onResetBtnClicked);
        super.onteardown();
    }

    public override localize (): void {
        super.localize();
        void this.set({
            header: Resources.getResource('screen.OptionCalculator'),
            inputParametersLabel: Resources.getResource('property.InputParameters'),
            outputParametersLabel: Resources.getResource('property.OutputParameters'),
            impliedVolatilityInputLabel: Resources.getResource('property.ImpliedVolatility'),
            theoreticalPriceInputLabel: Resources.getResource('property.TheoreticalOptionPrice'),
            strikePriceLabel: Resources.getResource('property.StrikePrice'),
            underlyingPriceLabel: Resources.getResource('property.UnderlyingPrice'),
            impliedVolatilityLabel: Resources.getResource('property.ImpliedVolatilityPercent'),
            optionPriceLabel: Resources.getResource('property.OptionPrice'),
            interestRateLabel: Resources.getResource('property.InterestRate'),
            daysTillExpirationLabel: Resources.getResource('property.DaysTillExpiration'),
            calculateButtonText: Resources.getResource('property.Calculate'),
            resetButtonText: 'C',
            putLabel: Resources.getResource('property.Put'),
            callLabel: Resources.getResource('property.Call'),
            deltaLabel: Resources.getResource('property.Delta'),
            gammaLabel: Resources.getResource('property.Gamma'),
            vegaLabel: Resources.getResource('property.Vega'),
            thetaLabel: Resources.getResource('property.Theta'),
            rhoLabel: Resources.getResource('property.Rho'),
            theoreticalPriceLabel: Resources.getResource('property.TheoreticalPrice')
        });
    }

    public override close (): void {
        super.close();
        OptionCalculatorScreen._screen = null;
        OptionCalculatorScreen._optionCalculator = null;
    }

    public static Show (optionCalculator: IOptionCalculator): void {
        if (!isNullOrUndefined(OptionCalculatorScreen._screen)) {
            OptionCalculatorScreen._screen.setFocus();
            return;
        }
        const screen = new OptionCalculatorScreen();
        screen.oninit();
        screen.localize();
        OptionCalculatorScreen._screen = screen;
        OptionCalculatorScreen._optionCalculator = optionCalculator;
        MainWindowManager.MainWindow.addControl(OptionCalculatorScreen._screen);
        screen.center();
    }

    private inputParametersGroupButtons_btnClicked (context, sender, buttonInfo: TerceraButtonInfo): void {
        void super.set('isIVChecked', buttonInfo.name === 'IV');
    }

    private onCalculateBtnClicked (): void {
        const optionCalculator = OptionCalculatorScreen._optionCalculator;
        if (isNullOrUndefined(optionCalculator)) {
            this.onResetBtnClicked();
            return;
        }

        const isIVChecked: boolean = this.get('isIVChecked');
        let theoreticalPricePut = 0.0;
        let theoreticalPriceCall = 0.0;
        let impliedVolatilityPut = 0.0;
        let impliedVolatilityCall = 0.0;
        let deltaPut = 0.0;
        let deltaCall = 0.0;
        let gammaPut = 0.0;
        let gammaCall = 0.0;
        let vegaPut = 0.0;
        let vegaCall = 0.0;
        let thetaPut = 0.0;
        let thetaCall = 0.0;
        let rhoPut = 0.0;
        let rhoCall = 0.0;
        const interestRate = this.get('interestRate') / 100;
        const expiration = this.get('daysTillExpiration') / 365;

        if (isIVChecked) {
            theoreticalPricePut = theoreticalPriceCall = this.get('optionPrice');
            impliedVolatilityPut = optionCalculator.iv(OptionPutCall.OPTION_PUT_VANILLA, this.get('optionPrice'), this.get('underlyingPrice'), this.get('strikePrice'), expiration, interestRate);
            impliedVolatilityCall = optionCalculator.iv(OptionPutCall.OPTION_CALL_VANILLA, this.get('optionPrice'), this.get('underlyingPrice'), this.get('strikePrice'), expiration, interestRate);
        } else {
            impliedVolatilityPut = impliedVolatilityCall = this.get('impliedVolatility') / 100;
            theoreticalPricePut = optionCalculator.theoreticalPrice(OptionPutCall.OPTION_PUT_VANILLA, this.get('underlyingPrice'), this.get('strikePrice'), expiration, interestRate, impliedVolatilityPut);
            theoreticalPriceCall = optionCalculator.theoreticalPrice(OptionPutCall.OPTION_CALL_VANILLA, this.get('underlyingPrice'), this.get('strikePrice'), expiration, interestRate, impliedVolatilityCall);
        }

        deltaPut = optionCalculator.delta(OptionPutCall.OPTION_PUT_VANILLA, this.get('underlyingPrice'), this.get('strikePrice'), expiration, interestRate, impliedVolatilityPut);
        deltaCall = optionCalculator.delta(OptionPutCall.OPTION_CALL_VANILLA, this.get('underlyingPrice'), this.get('strikePrice'), expiration, interestRate, impliedVolatilityCall);

        gammaPut = optionCalculator.gamma(this.get('underlyingPrice'), this.get('strikePrice'), expiration, interestRate, impliedVolatilityPut);
        gammaCall = optionCalculator.gamma(this.get('underlyingPrice'), this.get('strikePrice'), expiration, interestRate, impliedVolatilityCall);

        vegaPut = optionCalculator.vega(this.get('underlyingPrice'), this.get('strikePrice'), expiration, interestRate, impliedVolatilityPut);
        vegaCall = optionCalculator.vega(this.get('underlyingPrice'), this.get('strikePrice'), expiration, interestRate, impliedVolatilityCall);

        thetaPut = optionCalculator.theta(OptionPutCall.OPTION_PUT_VANILLA, this.get('underlyingPrice'), this.get('strikePrice'), expiration, interestRate, impliedVolatilityPut);
        thetaCall = optionCalculator.theta(OptionPutCall.OPTION_CALL_VANILLA, this.get('underlyingPrice'), this.get('strikePrice'), expiration, interestRate, impliedVolatilityCall);

        rhoPut = optionCalculator.rho(OptionPutCall.OPTION_PUT_VANILLA, this.get('underlyingPrice'), this.get('strikePrice'), expiration, interestRate, impliedVolatilityPut);
        rhoCall = optionCalculator.rho(OptionPutCall.OPTION_CALL_VANILLA, this.get('underlyingPrice'), this.get('strikePrice'), expiration, interestRate, impliedVolatilityCall);

        void this.set({
            putDelta: GreeksFormatter.formatGreek(deltaPut),
            callDelta: GreeksFormatter.formatGreek(deltaCall),
            putGamma: GreeksFormatter.formatGreek(gammaPut),
            callGamma: GreeksFormatter.formatGreek(gammaCall),
            putVega: GreeksFormatter.formatGreek(vegaPut),
            callVega: GreeksFormatter.formatGreek(vegaCall),
            putTheta: GreeksFormatter.formatGreek(thetaPut),
            callTheta: GreeksFormatter.formatGreek(thetaCall),
            putRho: GreeksFormatter.formatGreek(rhoPut),
            callRho: GreeksFormatter.formatGreek(rhoCall),
            putImpliedVolatility: GreeksFormatter.formatIVValue(impliedVolatilityPut),
            callImpliedVolatility: GreeksFormatter.formatIVValue(impliedVolatilityCall),
            putTheoreticalPrice: theoreticalPricePut.toString(),
            callTheoreticalPrice: theoreticalPriceCall.toString()
        });
    }

    private onResetBtnClicked (): void {
        void this.set({
            putDelta: '',
            callDelta: '',
            putGamma: '',
            callGamma: '',
            putVega: '',
            callVega: '',
            putImpliedVolatility: '',
            callImpliedVolatility: '',
            putTheta: '',
            callTheta: '',
            putRho: '',
            callRho: '',
            putTheoreticalPrice: '',
            callTheoreticalPrice: ''
        });
    }
}

TerceraWindowBase.extendWith(OptionCalculatorScreen, {
    data: function () {
        return {
            showFooter: false,
            resizable: false,
            isIVChecked: true,

            inputParametersLabel: '',
            outputParametersLabel: '',
            impliedVolatilityInputLabel: '',
            theoreticalPriceInputLabel: '',
            strikePriceLabel: '',
            underlyingPriceLabel: '',
            impliedVolatilityLabel: '',
            optionPriceLabel: '',
            interestRateLabel: '',
            daysTillExpirationLabel: '',
            calculateButtonText: '',
            resetButtonText: '',
            putLabel: '',
            callLabel: '',
            deltaLabel: '',
            putDelta: '',
            callDelta: '',
            gammaLabel: '',
            putGamma: '',
            callGamma: '',
            vegaLabel: '',
            putVega: '',
            callVega: '',
            putImpliedVolatility: '',
            callImpliedVolatility: '',
            thetaLabel: '',
            putTheta: '',
            callTheta: '',
            rhoLabel: '',
            putRho: '',
            callRho: '',
            theoreticalPriceLabel: '',
            putTheoreticalPrice: '',
            callTheoreticalPrice: '',

            inputParametersGroupButtonsInfo: [
                new TerceraButtonInfo('OP', 'OP', false),
                new TerceraButtonInfo('IV', 'IV', true)
            ],

            strikePrice: 0.00001,
            underlyingPrice: 0.00001,
            impliedVolatility: 0.00001,
            optionPrice: 0.00001,
            interestRate: 0.00001,
            daysTillExpiration: 1
        };
    },
    partials: { bodyPartial: OptionCalculatorScreenTemplate }
});
