// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources, LOCALE_ES, LOCALE_EN, CurrentLang } from "../../Commons/properties/Resources.ts";
import { HistoryType } from "../../Utils/History/HistoryType.ts";
import { TerceraChartAction, TerceraChartActionEnum } from "../../Chart/TerceraChartAction.ts";
import { LinkedSystem } from "../misc/LinkedSystem.ts";
import { ModelDataType, TerceraChartMVCCommand } from "../../Chart/TerceraChartMVC.ts";
import { TradingCentralTemplate } from "../../templates.js";
import { TradingCentralItem } from "../cache/TradingCentralItem.ts";
import { TerceraLinkControlConstants } from "../UtilsClasses/TerceraLinkControlConstants.ts";
import { TradingToolsScreen } from "../screen/TradingToolsScreen.js";
import { PanelNames } from "../UtilsClasses/FactoryConstants.ts";
import { LookupDropDownShowParams } from "../UtilsClasses/LookupDropDownShowParams.js";
import { ApplicationPanelNew } from "./ApplicationPanelNew.js";
import { PeriodDesc, Periods } from "../../Utils/History/TFInfo.ts";
import { DynProperty } from "../../Commons/DynProperty.ts";
import { IndicatorManager } from "../../Commons/cache/indicators/IndicatorManager.ts";
import { GeneralSettings } from "../../Utils/GeneralSettings/GeneralSettings.ts";
import { UserWebStorageInstance } from "../../user-web-storage.ts";
import { DataCache } from "../../Commons/DataCache.ts";
import { PanelItemsFactory } from "./PanelItemsFactory.js";
import { DateTimeConvertor } from "../../Utils/Time/DateTimeConvertor.ts";
import { DateTimeUtils } from "../../Utils/Time/DateTimeUtils.ts";
import { TerceraIndicatorLookupDropDownForm } from "../elements/Lookup/TerceraIndicatorLookupDropDownForm.js";
import { PanelSettingsScreen } from "../screen/PanelSettingsScreen.js";

export let TradingCentral = ApplicationPanelNew.extend(
    {
        partials: { bodyPartial: TradingCentralTemplate },
        data: function ()
        {
            return {
                canLinkByAccount: false,
                isSymbolLinkShow: true,
                instrument: null,
                showChart: false,                                                               // если false - отображается screen c img
                showToolsPanel: false,
                showSwitcher: !Resources.isHidden('panel.tradingCentral.ScreenButton'),         // есть возможность скрыть переключатель между чартом и скрином через ключ          
                splitterDrag: false,
                screenImgURL: null,
                noData: false,
                noDataInTable: false,
                loading: false,
                tabHeaderKeys: TradingCentral.tabHeaderKeys,
                initialChartSize: 0.52,                                                         // пропорции размеров чарта и вкладок 
                topContainerHeight: null,
                screenChartHeight: null,
                screenBtnText: Resources.getResource('panel.tradingCentral.screenBtn.text'),
                chartBtnText: Resources.getResource('panel.tradingCentral.chartBtn.text'),
                screenBtnStyle: 'js-button-switchOn',
                chartBtnStyle: 'js-button-switchOff',
            };
        },
        computed: {
            terceraChartPanelContext: {
                get: function () { return this; },
                set: function (value) { }
            }
        },

        TradingCentralData:
        {
            technicalAnalysis: null,
            alerts: null,
            candlestick: null
        },

        splitterY: null,
        splitterDragY: null,
        splitterDragStartY: null,
        settings: null,
        activeTab: null,
        language: null,
        heightBeforeResize: null,
        previousTermSetting: null,
        terceraChartRactive: null,
        NeedCalculateRowCount: false,
        skipSomeProperties: true,
        headerLocaleKey: 'panel.tradingCentral'
    });

TradingCentral.prototype.getType = function () { return PanelNames.TradingCentral };

TradingCentral.prototype.oninit = function ()
{
    ApplicationPanelNew.prototype.oninit.apply(this);

    this.on('indicatorButton_btnClick', this.onIndicatorBtnClick);

    this.observe('instrument', this.onInstrumentChanged);
    this.observe('width', this.onWidthChanged);
    this.observe('showToolsPanel', this.layoutTable, { oninit: false });
};

TradingCentral.prototype.oncomplete = function ()
{
    ApplicationPanelNew.prototype.oncomplete.apply(this);

    let initSize = this.get('height') * this.get('initialChartSize')

    this.set('topContainerHeight', initSize)
    this.set('splitterY', initSize)

    this.initChart()
    this.themeChange()
    this.jbInit()
    this.populateTableContextMenu()

    if (!this.settings)
        this.applyDefaultTableSettings()

    this.on('screenBtnClick', this.onScreenBtnClick);
    this.on('chartBtnClick', this.onChartBtnClick);
    this.on('splitterMouseDown', this.splitterMouseDown);
    this.on('splitterMouseUp', this.splitterMouseUp);
    this.on('splitterMouseMove', this.splitterMouseMove);
    this.on('noDataMouseDown', this.noDataMouseDown);

    if (this.Controls.tabs)
    {
        let tabs = this.Controls.tabs
        tabs.onTabChange.Subscribe(this.selectTab, this);
        tabs.onCBItemClickEvent.Subscribe(this.selectDate, this);

        tabs.observe('selectedItem', function (newVal) { this.activeTab = newVal }.bind(this))
    }

    GeneralSettings.SettingsChanged.Subscribe(this.applicationSettingsChanged, this)
    DataCache.ExternalLinksCache.TradingCentralExternalToolChanged.Subscribe(this.updateRequest, this)

    this.language = CurrentLang === LOCALE_ES ? LOCALE_ES : LOCALE_EN

    this.onInstrumentChanged(this.get('instrument'), null)  // this.infoRequest(TradingCentral.technicalAnalysis)
};

TradingCentral.prototype.dispose = function ()
{
    if (this.Controls.tabs)
    {
        let tabs = this.Controls.tabs
        tabs.onTabChange.UnSubscribe(this.selectTab, this);
        tabs.onCBItemClickEvent.UnSubscribe(this.selectDate, this);
    }

    DataCache.ExternalLinksCache.TradingCentralExternalToolChanged.UnSubscribe(this.updateRequest, this)
    GeneralSettings.SettingsChanged.UnSubscribe(this.applicationSettingsChanged, this)

    ApplicationPanelNew.prototype.dispose.apply(this);
};

TradingCentral.prototype.layoutTable = function ()
{
    if (this.terceraChartRactive === null)
        return;

    var height = this.get('height')

    if (!height)
        return

    if (this.heightBeforeResize && this.heightBeforeResize != height)
    {
        let newVal = (this.get('topContainerHeight') * height) / this.heightBeforeResize
        this.set('topContainerHeight', newVal)
        this.set('splitterY', newVal)
    }

    this.set('screenChartHeight', this.get('topContainerHeight') - this.margin * 6)

    var topPanelHeight = 30;

    var margin = this.margin;
    var width = this.get('width');
    var topMargin = topPanelHeight + margin;
    var scrWidth = width - margin * 2;
    var scrHeight = this.get('screenChartHeight')
    var widthToSet = scrWidth - 2 * margin
    var heightToSet = scrHeight - margin - topMargin

    this.terceraChartRactive.setBounds(margin + 4, topMargin, widthToSet, heightToSet);

    if (this.terceraChartRactive.terceraChart)
        this.terceraChartRactive.terceraChart.OnResize(Math.abs(widthToSet), Math.abs(heightToSet));

    var imgBox = this.Controls.screenIMG
    if (imgBox)
    {
        var img = imgBox.find('img');
        if (img)
        {
            let left = widthToSet / 2 - img.naturalWidth / 2,
                top = heightToSet / 2 - img.naturalHeight / 2
            imgBox.set({
                left: left,
                top: (top < margin ? margin : top)
            })
        }
    }

    this.heightBeforeResize = height

    var tabs = this.Controls.tabs
    if (tabs)
    {
        let len = tabs.get('items').length
        tabs.set('tabWidth', width / len)
    }

    var qt = this.Controls.quickTableRactive
    if (qt)
        qt.quickTable.onResize()
};

TradingCentral.prototype.selectDate = function (selectedItem)
{
    if (!this.Controls.quickTableRactive)
        return

    var qt = this.Controls.quickTableRactive.quickTable

    qt.ClearRows()

    if (selectedItem.length !== undefined)
    {
        if (selectedItem.length)
            selectedItem = selectedItem[0]
        else
            return
    }

    var imgURL

    if (selectedItem.tag && selectedItem.tag.imgURL)
        imgURL = selectedItem.tag.imgURL;

    if (imgURL)
    {
        this.set('screenImgURL', imgURL)

        if (this.Controls.screenIMG)
        {
            let img = this.Controls.screenIMG.find('img')
            if (img)
                img.onload = function () { this.layoutTable() }.bind(this)
        }
    }

    if (selectedItem.tag && selectedItem.tag.period && !selectedItem.tag.indicators)  // indicators exist -> technical alalysis -> need change chart settings
        this.onChartTermChanged(selectedItem.tag.period)

    var item = JSON.parse(JSON.stringify(selectedItem.tag))

    item.imgURL = null;

    if (item.indicators)
    {
        let indicators = item.indicators
        for (let i = 0; i < indicators.length; i++)
        {
            let ind = indicators[i]
            item['indicator_' + ind.name] = ind.name + ': ' + ind.trend
        }

        item.indicators = null;
    }

    var chartData
    if (item.chartData)
    {
        chartData = JSON.parse(JSON.stringify(item.chartData))
        item.chartData = null;
    }

    for (let key in item)
        if (item[key] !== null)
        {
            item.activeKey = key

            let rows = TradingCentralItem.lineBreak(qt.width, qt.context, item[key], key)

            for (let i = 0; i < rows.length; i++)
                qt.AddItem(new TradingCentralItem(rows[i], qt.context))
        }

    if (qt.needRedraw || qt.rowCountChanged)
    {
        qt.Draw()
        qt.needRedraw = false;
        qt.rowCountChanged = false;
    }


    let chart = this.terceraChartRactive ? this.terceraChartRactive.terceraChart : null;
    if (chart)
        chart.setDataForTradingCentralRenderer(chartData)
}

TradingCentral.prototype.selectTab = function (selectedTab)
{
    let tabs = TradingCentral.tabHeaderKeys

    var qt = this.Controls.quickTableRactive.quickTable
    qt.ClearRows()
    this.set('noDataInTable', true)

    switch (selectedTab)
    {
        case tabs[TradingCentral.technicalAnalysis]:
            this.parseTechnicalAnalysisResponse()
            break;

        case tabs[TradingCentral.alerts]:
            this.parseAlertsResponse()
            break;

        case tabs[TradingCentral.candlestick]:
            this.parseCandlestickResponse()
            break;
    }
}

// Requests & Response region

TradingCentral.prototype.infoRequest = function (tableType)                             // tableType - 0,1,2 в соответствии с константами TradingCentral.technicalAnalysis (alerts,candlestick)
{
    let tool = DataCache.ExternalLinksCache.TradingCentralExternalTool,
        settings = this.settings

    if (!tool || !settings)
    {
        this.set('noData', true)
        return
    }

    this.set('loading', true)

    let term = TradingCentral.TermRequestValue[TradingCentral.Term.indexOf(settings.technicalAnalysis.term)]

    let culture = CurrentLang === LOCALE_ES ? 'es-ES' : 'en-US'

    let ins = this.get('instrument')

    if (!ins || !ins.InstrumentAdditionalInfo['Ticker for TC'])
    {
        this.set({
            loading: false,
            noData: true
        })
        return
    }
    else
        this.set('noData', false)

    let product = ins.InstrumentAdditionalInfo['Ticker for TC'],
        me = this

    let technicalAnalysisURLPart = tool.technicalAnalysis + (tool.technicalAnalysis.indexOf('?') == -1 ? '?' : ''),     // #97257
        alertsURLPart = tool.alerts + (tool.alerts.indexOf('?') == -1 ? '?' : ''),
        candlestickURLPart = tool.candlestick + (tool.candlestick.indexOf('?') == -1 ? '?' : '')

    let technicalAnalysisURL = technicalAnalysisURLPart + 'culture=' + culture + '&type_product=null&product=' + product + '&term=' + term + '&days=' + settings.technicalAnalysis.days + '&last_ta=' + settings.technicalAnalysis.lastOnly.toString() + '&partner=' + tool.partnerID + '&token=' + tool.token,
        alertsURL = alertsURLPart + 'culture=' + culture + '&type_product=null&product=' + product + '&days=' + settings.alerts.days + '&last_only=' + settings.alerts.lastOnly.toString() + '&partner=' + tool.partnerID + '&token=' + tool.token,
        candlestickURL = candlestickURLPart + 'culture=' + culture + '&type_product=null&product=' + product + '&days=' + settings.candlestick.days + '&last_only=' + settings.candlestick.lastOnly.toString() + '&partner=' + tool.partnerID + '&token=' + tool.token

    if (tableType == TradingCentral.technicalAnalysis)
    {
        UserWebStorageInstance.externalResourse.post(technicalAnalysisURL).then(function (res)
        {
            me.TradingCentralData.technicalAnalysis = res;
            me.parseTechnicalAnalysisResponse()
        })
            .finally(function ()
            {
                me.set('loading', false)
            })

        UserWebStorageInstance.externalResourse.post(alertsURL).then(function (res) { me.TradingCentralData.alerts = res; })
        UserWebStorageInstance.externalResourse.post(candlestickURL).then(function (res) { me.TradingCentralData.candlestick = res; })
    }

    if (tableType == TradingCentral.alerts)
    {
        UserWebStorageInstance.externalResourse.post(alertsURL).then(function (res)
        {
            me.TradingCentralData.alerts = res;
            me.parseAlertsResponse()
        })
            .finally(function ()
            {
                me.set('loading', false)
            })

        UserWebStorageInstance.externalResourse.post(technicalAnalysisURL).then(function (res)
        {
            me.TradingCentralData.technicalAnalysis = res;
            me.set('screenImgURL', me.parseForImage())
        })
        UserWebStorageInstance.externalResourse.post(candlestickURL).then(function (res) { me.TradingCentralData.candlestick = res; })
    }

    if (tableType == TradingCentral.candlestick)
    {
        UserWebStorageInstance.externalResourse.post(candlestickURL).then(function (res)
        {
            me.TradingCentralData.candlestick = res;
            me.parseCandlestickResponse()
        })
            .finally(function ()
            {
                me.set('loading', false)
            })

        UserWebStorageInstance.externalResourse.post(technicalAnalysisURL).then(function (res) 
        {
            me.TradingCentralData.technicalAnalysis = res;
            me.set('screenImgURL', me.parseForImage())
        })
        UserWebStorageInstance.externalResourse.post(alertsURL).then(function (res) { me.TradingCentralData.alerts = res; })
    }
}

TradingCentral.prototype.updateRequest = function ()
{
    if (this.activeTab)
    {
        let tab = this.activeTab.data

        this.infoRequest(TradingCentral.tabHeaderKeys.indexOf(tab))
    }
}

TradingCentral.prototype.parseTechnicalAnalysisResponse = function ()
{
    let res = this.TradingCentralData.technicalAnalysis

    if (!res)
        return

    let xml = res.Text,
        parser = new DOMParser(),
        xmlDoc = parser.parseFromString(xml, "text/xml");

    let table = xmlDoc.childNodes[0]

    let articles = table.getElementsByTagName('article')

    let technicalAnalysis = [], alreadyAdded = {}

    for (let i = 0; i < articles.length; i++)
    {
        let art = articles[i]
        let title = art.getElementsByTagName('title')[0].textContent,
            dateS = DateTimeConvertor.getTimeStringInCurrentOffset(DateTimeUtils.getDateFromYYYYMMDD(art.getElementsByTagName('date')[0].textContent), art.getElementsByTagName('hour')[0].textContent),
            period = art.getElementsByTagName('term')[0].textContent,
            ourPreference, alternativeScenario, comment,
            expectedMove1 = art.getElementsByTagName('expectedmove1_percent')[0].textContent,
            expectedMove2 = art.getElementsByTagName('expectedmove2_percent')[0].textContent,
            pivot = parseFloat(art.getElementsByTagName('pivot')[0].textContent),
            last = art.getElementsByTagName('last')[0].textContent,
            resistance1 = parseFloat(art.getElementsByTagName('resistance1')[0].textContent),
            resistance2 = parseFloat(art.getElementsByTagName('resistance2')[0].textContent),
            resistance3 = parseFloat(art.getElementsByTagName('resistance3')[0].textContent),
            support1 = parseFloat(art.getElementsByTagName('support1')[0].textContent),
            support2 = parseFloat(art.getElementsByTagName('support2')[0].textContent),
            support3 = parseFloat(art.getElementsByTagName('support3')[0].textContent)

        let imgURL = art.getElementsByTagName('media')[0].textContent

        let paragraphes = art.getElementsByTagName('paragraph')
        for (let j = 0; j < paragraphes.length; j++)
        {
            let par = paragraphes[j].textContent
            if (par.indexOf('Our preference') >= 0)
                ourPreference = par
            else
                if (par.indexOf('Alternative scenario') >= 0)
                    alternativeScenario = par
                else if (par.indexOf('Comment') >= 0)
                    comment = par
        }

        let item = {
            imgURL: imgURL,
            title: 'Title: ' + title,
            date: 'Date: ' + dateS,
            period: 'Period: ' + period,
            ourPreference: ourPreference,
            alternativeScenario: alternativeScenario,
            comment: comment,
            //expectedMove: 'Expected moves: E1 ' + expectedMove1 + '; E2 ' + expectedMove2 + ';',
            expectedMove1: 'Expected move 1: ' + expectedMove1,
            expectedMove2: 'Expected move 2: ' + expectedMove2,
            pivot: 'Pivot: ' + pivot,
            last: 'Last: ' + last,
            //resistance: 'Resistances: R1 ' + resistance1 + '; R2 ' + resistance2 + '; R3 ' + resistance3 + ';',
            resistance1: 'Resistance 1: ' + resistance1,
            resistance2: 'Resistance 2: ' + resistance2,
            resistance3: 'Resistance 3: ' + resistance3,
            //support: 'Supports: S1 ' + support1 + '; S2 ' + support2 + '; S3 ' + support3 + ';' 
            support1: 'Supports 1: ' + support1,
            support2: 'Supports 2: ' + support2,
            support3: 'Supports 3: ' + support3,
            chartData: {
                resistanceValues: [resistance1, resistance2, resistance3],
                supportValues: [support1, support2, support3],
                pivotValue: pivot
            }
        }

        let cbItem = {
            value: item,
            text: dateS
        }


        if (!alreadyAdded[item.date])               // скипаю если уже есть данные за эту дату
        {
            technicalAnalysis.push(cbItem)
            alreadyAdded[item.date] = true
        }

    }

    let tabs = this.Controls.tabs
    if (tabs)
    {
        let cbItems = tabs.get('cbItems')

        cbItems[TradingCentral.technicalAnalysis] = technicalAnalysis

        tabs.set('cbItems', cbItems)

        this.set('noDataInTable', technicalAnalysis.length === 0)

        tabs.onComboItemClicked(TradingCentral.technicalAnalysis, null, technicalAnalysis)
    }
};

TradingCentral.prototype.parseAlertsResponse = function ()
{
    let res = this.TradingCentralData.alerts

    if (!res)
        return

    let xml = res.Text,
        parser = new DOMParser(),
        xmlDoc = parser.parseFromString(xml, "text/xml");

    let table = xmlDoc.childNodes[0]

    let articles = table.getElementsByTagName('article')

    let alerts = []

    for (let i = 0; i < articles.length; i++)
    {
        let art = articles[i]
        let dateS = DateTimeConvertor.getTimeStringInCurrentOffset(DateTimeUtils.getDateFromYYYYMMDD(art.getElementsByTagName('date')[0].textContent), art.getElementsByTagName('hour')[0].textContent),
            period = art.getElementsByTagName('period_unit')[0].textContent + ' ' + art.getElementsByTagName('period_unit_count')[0].textContent,
            indicators = []

        let trends = art.getElementsByTagName('trend')
        for (let j = 0; j < trends.length; j++)
        {
            let trend = trends[j]
            if (trend.textContent != 0)
            {
                let ind = {
                    name: trend.attributes["type"].value,
                    trend: trend.textContent
                }
                indicators.push(ind)
            }
        }

        let volatilities = art.getElementsByTagName('volatility')
        for (let j = 0; j < volatilities.length; j++)
        {
            let volatility = volatilities[j]
            if (volatility.textContent != 0)
            {
                let ind = {
                    name: volatility.attributes["type"].value,
                    trend: volatility.textContent
                }
                indicators.push(ind)
            }
        }

        let momentums = art.getElementsByTagName('momentum')
        for (let j = 0; j < momentums.length; j++)
        {
            let momentum = momentums[j]
            if (momentum.textContent != 0)
            {
                let ind = {
                    name: momentum.attributes["type"].value,
                    trend: momentum.textContent
                }
                indicators.push(ind)
            }
        }

        let strengths = art.getElementsByTagName('strength')
        for (let j = 0; j < strengths.length; j++)
        {
            let strength = strengths[j]
            if (strength.textContent != 0)
            {
                let ind = {
                    name: strength.attributes["type"].value,
                    trend: strength.textContent
                }
                indicators.push(ind)
            }
        }

        let item = {
            date: 'Date: ' + dateS,
            period: 'Period: ' + period,
            indicators: indicators
        }

        let cbItem = {
            value: item,
            text: dateS
        }
        alerts.push(cbItem)
    }

    let tabs = this.Controls.tabs
    if (tabs)
    {
        let cbItems = tabs.get('cbItems')

        cbItems[TradingCentral.alerts] = alerts

        tabs.set('cbItems', cbItems)

        this.set('noDataInTable', alerts.length === 0)

        tabs.onComboItemClicked(TradingCentral.alerts, null, alerts)
    }
};

TradingCentral.prototype.parseCandlestickResponse = function ()
{
    let res = this.TradingCentralData.candlestick

    if (!res)
        return

    let xml = res.Text,
        parser = new DOMParser(),
        xmlDoc = parser.parseFromString(xml, "text/xml");

    let table = xmlDoc.childNodes[0]

    let articles = table.getElementsByTagName('article')

    let candlestick = []

    for (let i = 0; i < articles.length; i++)
    {
        let art = articles[i]
        let title = art.getElementsByTagName('title')[0].textContent,
            dateS = DateTimeConvertor.getTimeStringInCurrentOffset(DateTimeUtils.getDateFromYYYYMMDD(art.getElementsByTagName('date')[0].textContent), art.getElementsByTagName('hour')[0].textContent),
            candlestickName = art.getElementsByTagName('candlestick')[0].textContent,
            lastPrice = art.getElementsByTagName('last')[0].textContent,
            invalidationPrice = art.getElementsByTagName('invalidation')[0].textContent,
            trend = art.getElementsByTagName('watch')[0].textContent,
            indicators = [{ name: 'Trend', trend: trend }]

        let item = {
            title: 'Title: ' + title,
            date: 'Date: ' + dateS,
            candlestickName: 'Candlestick name: ' + candlestickName,
            indicators: indicators,
            lastPrice: 'Last price: ' + lastPrice,
            invalidationPrice: 'Invalidation price: ' + invalidationPrice
        }

        let cbItem = {
            value: item,
            text: dateS
        }
        candlestick.push(cbItem)
    }

    let tabs = this.Controls.tabs
    if (tabs)
    {
        let cbItems = tabs.get('cbItems')

        cbItems[TradingCentral.candlestick] = candlestick

        tabs.set('cbItems', cbItems)

        this.set('noDataInTable', candlestick.length === 0)

        tabs.onComboItemClicked(TradingCentral.candlestick, null, candlestick)
    }
};

TradingCentral.prototype.parseForImage = function ()
{
    let res = this.TradingCentralData.technicalAnalysis

    if (!res)
        return

    let xml = res.Text,
        parser = new DOMParser(),
        xmlDoc = parser.parseFromString(xml, "text/xml");

    let table = xmlDoc.childNodes[0]

    let articles = table.getElementsByTagName('article')

    for (let i = 0; i < articles.length; i++)
    {
        let art = articles[i]

        let imgURL = art.getElementsByTagName('media')[0].textContent

        if (imgURL)
            return imgURL
    }

    return null
};

TradingCentral.prototype.onWidthChanged = function (newVal, oldVal)
{
    if (!newVal || newVal == oldVal)
        return

    let tabs = this.Controls.tabs
    if (tabs && newVal > 350)
    {
        let selected = tabs.get('selectedCBItem')
        if (selected)
            this.selectDate(selected)
    }
}

TradingCentral.prototype.populateTableContextMenu = function ()
{
    var items = [];

    items.push(
        {
            text: Resources.getResource('panel.tradingCentral.settings'),
            event: function ()
            {
                let header = ''
                let tabs = this.Controls.tabs
                if (tabs)
                {
                    let selected = tabs.get('selectedItem')
                    if (selected && selected.data)
                        header = selected.data
                }
                PanelSettingsScreen.EditProperties.call(this, this, DynProperty.DATA_STYLE_GROUP, header)
            }.bind(this)
        }
    );

    this.Controls.quickTableRactive.quickTable.setTableContextMenuItems(items);
};

TradingCentral.prototype.applyDefaultTableSettings = function ()
{
    let sett = {                                                                            // настройки таблиц по умолчанию
        technicalAnalysis: {
            term: TradingCentral.Term[0],
            days: 7,
            lastOnly: false
        },
        alerts: {
            days: 14,
            lastOnly: false
        },
        candlestick: {
            days: 14,
            lastOnly: false
        }
    }

    this.settings = sett
}

TradingCentral.prototype.Properties = function (forActiveTableOnly)
{
    let properties = ApplicationPanelNew.prototype.Properties.call(this);

    let tabs = TradingCentral.tabHeaderKeys

    for (let i = 0; i < tabs.length; i++)
    {
        let dp, currentTab = tabs[i]

        if (forActiveTableOnly && this.activeTab)                   // для получения настроек только активной таблицы
            currentTab = this.activeTab.data

        let isTechnicalAnalysisTab = currentTab === tabs[TradingCentral.technicalAnalysis],
            settings, settingsName = 'panel.tradingCentral.settings'

        switch (currentTab)
        {
            case tabs[TradingCentral.technicalAnalysis]:
                settingsName += '.technicalAnalysis';
                settings = this.settings.technicalAnalysis;
                break;
            case tabs[TradingCentral.alerts]:
                settingsName += '.alerts';
                settings = this.settings.alerts;
                break;
            case tabs[TradingCentral.candlestick]:
                settingsName += '.candlestick';
                settings = this.settings.candlestick;
                break;
        }

        if (settings.term)
        {
            dp = new DynProperty(settingsName + '.term', settings.term, DynProperty.COMBOBOX_COMBOITEM, DynProperty.PARAM_GROUP)
            dp.objectVariants = [];

            let terms = TradingCentral.Term;
            let len = terms.length;
            for (let i = 0; i < len; i++)
            {
                let l = terms[i];
                dp.objectVariants.push({
                    value: l,
                    text: Resources.getResource(l)
                });
            }

            dp.COMBOBOX_TYPE = DynProperty.STRING;
            dp.sortIndex = 1;
            properties.push(dp);
        }

        dp = new DynProperty(settingsName + '.days', settings.days, DynProperty.INTEGER, DynProperty.PARAM_GROUP)
        dp.minimalValue = 1;
        dp.maximalValue = isTechnicalAnalysisTab ? 7 : 14
        dp.sortIndex = 2;
        properties.push(dp);

        properties.push(new DynProperty(settingsName + '.lastOnly', settings.lastOnly, DynProperty.BOOLEAN, DynProperty.PARAM_GROUP));


        if (forActiveTableOnly)                 // для получения настроек только активной таблицы
            return properties;
    }

    return properties;
};

TradingCentral.prototype.callBack = function (properties, settingsWasChanged)
{
    ApplicationPanelNew.prototype.callBack.call(this, properties);

    if (!this.settings)
        this.applyDefaultTableSettings()

    if (settingsWasChanged === false)
        return

    let settings = this.settings

    let dp = DynProperty.getPropertyByName(properties, 'panel.tradingCentral.settings.technicalAnalysis.term')
    if (dp && dp.value)
        settings.technicalAnalysis.term = dp.value

    dp = DynProperty.getPropertyByName(properties, 'panel.tradingCentral.settings.technicalAnalysis.lastOnly')
    if (dp && dp.value !== undefined && dp.value !== null)
        settings.technicalAnalysis.lastOnly = dp.value

    dp = DynProperty.getPropertyByName(properties, 'panel.tradingCentral.settings.technicalAnalysis.days')
    if (dp && dp.value)
        settings.technicalAnalysis.days = dp.value

    dp = DynProperty.getPropertyByName(properties, 'panel.tradingCentral.settings.alerts.lastOnly')
    if (dp && dp.value !== undefined && dp.value !== null)
        settings.alerts.lastOnly = dp.value

    dp = DynProperty.getPropertyByName(properties, 'panel.tradingCentral.settings.alerts.days')
    if (dp && dp.value)
        settings.alerts.days = dp.value

    dp = DynProperty.getPropertyByName(properties, 'panel.tradingCentral.settings.candlestick.lastOnly')
    if (dp && dp.value !== undefined && dp.value !== null)
        settings.candlestick.lastOnly = dp.value

    dp = DynProperty.getPropertyByName(properties, 'panel.tradingCentral.settings.candlestick.days')
    if (dp && dp.value)
        settings.candlestick.days = dp.value

    if (this.activeTab)
        this.infoRequest(TradingCentral.tabHeaderKeys.indexOf(this.activeTab.data))
};

TradingCentral.prototype.applicationSettingsChanged = function ()
{
    let newLang = CurrentLang === LOCALE_ES ? LOCALE_ES : LOCALE_EN
    if (this.language != newLang && this.activeTab)
    {
        this.language = newLang
        this.infoRequest(TradingCentral.tabHeaderKeys.indexOf(this.activeTab.data))
    }
}

TradingCentral.prototype.onInstrumentChanged = function (newIns, oldIns)
{
    if (!newIns || newIns === oldIns || !this.completed)
        return;

    if (this.terceraChartRactive && this.terceraChartRactive.terceraChart)
    {
        this.terceraChartRactive.terceraChart.chartController.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.Instrument), newIns);
        this.unsubscribe(oldIns)
        this.subscribe(newIns)
    }
    this.symbolLink_Out(false, newIns);

    if (this.activeTab)
    {
        let tab = this.activeTab.data

        this.infoRequest(TradingCentral.tabHeaderKeys.indexOf(tab))
    }
};

TradingCentral.prototype.TickAsync = function ()
{
    if (this.terceraChartRactive && this.terceraChartRactive.terceraChart && this.terceraChartRactive.terceraChart.needRedraw)
    {
        this.terceraChartRactive.terceraChart.needRedraw = false;
        this.terceraChartRactive.terceraChart.Draw();
    }

    if (!this.Controls.quickTableRactive)
        return

    let qt = this.Controls.quickTableRactive.quickTable
    if (qt.needRedraw || qt.rowCountChanged)
    {
        qt.Draw()
        qt.needRedraw = false;
        qt.rowCountChanged = false;
    }
}

TradingCentral.prototype.jbInit = function ()
{
    var me = this

    let qt = this.Controls.quickTableRactive.quickTable
    var panelInfo = PanelItemsFactory.GetPanelItem(me.getType());
    qt.showHeader = false
    qt.InitializeDirect(new panelInfo());

    qt.UpdateSortedColumns();

    me.OnResize.Subscribe(function () { me.layoutTable(); }, me);

    me.layoutTable()
    me.localize()
};

// Chart region
TradingCentral.prototype.subscribe = function (instrument)
{
    if (!instrument)
        return

    let qc = DataCache.FQuoteCache
    let chart = this.terceraChartRactive.terceraChart

    qc.addListener(instrument, chart, HistoryType.QUOTE_LEVEL1)
    qc.addListener(instrument, chart, HistoryType.QUOTE_TRADES)
}

TradingCentral.prototype.unsubscribe = function (instrument)
{
    if (!instrument)
        return

    let qc = DataCache.FQuoteCache
    let chart = this.terceraChartRactive.terceraChart

    qc.removeListener(instrument, chart, HistoryType.QUOTE_LEVEL1)
    qc.removeListener(instrument, chart, HistoryType.QUOTE_TRADES)
}

TradingCentral.prototype.themeChange = function ()
{
    var Controls = this.Controls;

    if (this.terceraChartRactive)
        this.terceraChartRactive.themeChange();

    var toolsPanel = Controls.toolsPanel;
    if (toolsPanel) toolsPanel.themeChange();
};

TradingCentral.prototype.initChart = function ()
{
    this.terceraChartRactive = this.Controls.chart;

    if (!this.terceraChartRactive || !this.terceraChartRactive.terceraChart) return

    var terceraChart = this.terceraChartRactive.terceraChart,
        controller = terceraChart.chartController;

    controller.ExecuteCommand(
        new TerceraChartMVCCommand(ModelDataType.Instrument),
        this.get('instrument'));

    controller.ExecuteCommand(
        new TerceraChartMVCCommand(ModelDataType.Account),
        DataCache.getPrimaryAccount());

    this.Controls.toolsPanel.set('actionProcessor', terceraChart.TerceraChartActionProcessor);

    if (terceraChart.TradingCentralRenderer)
        terceraChart.TradingCentralRenderer.Visible = true

    terceraChart.GetContextMenu = function () { }
    controller.SuspendRefreshChart = false;
    terceraChart.RefreshChart();

    terceraChart.TerceraChartActionProcessor.ProcessTerceraChartAction(TerceraChartAction.Create(
        TerceraChartActionEnum.AutoScale, terceraChart.mainWindow))
};

TradingCentral.prototype.onChartTermChanged = function (termStr)
{
    if (!this.terceraChartRactive)
        return

    termStr = termStr.substr(termStr.indexOf(':') + 2)

    if (this.previousTermSetting !== termStr)
    {
        let termInt, period

        switch (termStr) 
        {
            case 'INTRADAY':
                termInt = 30
                period = new PeriodDesc(3, Periods.MONTH)
                break;
            case 'ST':
                termInt = 1440
                period = new PeriodDesc(1, Periods.YEAR)
                break;
            case 'MT':
                termInt = 10080
                period = new PeriodDesc(3, Periods.YEAR)
                break;
        }

        var model = this.terceraChartRactive.terceraChart.model;
        var oldTFInfo = model.GetTimeFrameInfo();
        var controller = this.terceraChartRactive.terceraChart.chartController;
        controller.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.TFI), oldTFInfo.Copy({ period: termInt }));
        controller.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.Range), period);
    }

    this.previousTermSetting = termStr
}

TradingCentral.prototype.applyCursor = function (cursor)
{
    if (this.terceraChartRactive)
        this.terceraChartRactive.setCursor(cursor);
};

TradingCentral.prototype.onIndicatorBtnClick = function ()
{
    var params = new LookupDropDownShowParams();
    params.callBack = this.indicatorLookupCB.bind(this);
    TerceraIndicatorLookupDropDownForm.ShowForm(params);
};
TradingCentral.prototype.indicatorLookupCB = function (selectedItem)
{
    if (typeof selectedItem === 'object' && selectedItem.FullName)
        this.terceraChartRactive.terceraChart.AddIndicator(IndicatorManager.GetIndicator(selectedItem.FullName));
};

TradingCentral.prototype.onScreenBtnClick = function ()
{
    this.set({
        screenBtnStyle: 'js-button-switchOn',
        chartBtnStyle: 'js-button-switchOff',
        showChart: false
    })
};

TradingCentral.prototype.onChartBtnClick = function ()
{
    this.set({
        screenBtnStyle: 'js-button-switchOff',
        chartBtnStyle: 'js-button-switchOn',
        showChart: true
    })
};

TradingCentral.prototype.symbolLink_In = function (symbolName)
{
    var currentInstrument = this.get('instrument');

    let newInstr = DataCache.getInstrumentByName(symbolName);
    if (newInstr)
    {
        //убираем не нужный апдейт
        if (currentInstrument && currentInstrument.GetInteriorID() === symbolName)
            return;
        this.set('instrument', newInstr);
    }
};

TradingCentral.prototype.symbolLink_Out = function (newSubscriber, instrument)
{
    if (!instrument)
    {
        var ins = this.get('instrument');
        if (!ins) return;
        instrument = ins;
    }
    var color = this.get('symbolLinkValue');
    if (color !== TerceraLinkControlConstants.STATE_NONE)
        LinkedSystem.setSymbol(color, instrument.GetInteriorID(), newSubscriber);
};

// Splitter region

TradingCentral.prototype.splitterMouseDown = function (e)
{
    this.set('splitterDrag', true);
    this.splitterDragStartY = this.get('splitterY')
    this.splitterDragY = e.original.y
}
TradingCentral.prototype.splitterMouseMove = function (e)
{
    if (!this.get('splitterDrag'))
        return

    let oldH = this.get('splitterY')
    this.set('splitterY', oldH + e.original.y - this.splitterDragY)
    this.splitterDragY = e.original.y
}
TradingCentral.prototype.splitterMouseUp = function (e)
{
    if (!this.get('splitterDrag'))
        return
    this.set('splitterDrag', false);

    this.set('topContainerHeight', this.get('splitterY'))
    this.splitterDragY = null
    this.layoutTable()
}
TradingCentral.prototype.splitterMouseLeave = function (event) 
{
    if (!this.get('splitterDrag'))
        return

    this.set('splitterY', this.splitterDragStartY)
    this.splitterMouseUp()
};
TradingCentral.prototype.noDataMouseDown = function (event) 
{
    window.open(TradingToolsScreen.getTradingCentralLink())
};

TradingCentral.technicalAnalysis = 0;
TradingCentral.alerts = 1;
TradingCentral.candlestick = 2;
TradingCentral.tabHeaderKeys = ["Technical analysis", "Technical alerts", "Candlestick patterns"]
TradingCentral.Term = ['panel.tradingCentral.term.All', 'panel.tradingCentral.term.Intraday', 'panel.tradingCentral.term.Short term', 'panel.tradingCentral.term.Middle term']
TradingCentral.TermRequestValue = ['null', 'Intraday', 'ST', 'MT']
