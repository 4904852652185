// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../properties/Resources';
import { CustomEvent } from '../../Utils/CustomEvents';
import { OperationType } from '../../Utils/Trading/OperationType';
import { OrderType } from '../../Utils/Trading/OrderType';
import { ProductType } from '../../Utils/Instruments/ProductType';
import { CurPriceSource } from '../../Utils/Instruments/CurPriceSource';
import { SlTpPriceType } from '../../Utils/Enums/Constants';
import { NO_BIND_TO } from '../../Utils/Trading/OrderConstants';
import { HistoryType } from '../../Utils/History/HistoryType';
import { RulesSet } from '../../Utils/Rules/RulesSet';
import { QuoteValid } from '../../Utils/Quotes/QuoteValid';
import { OrderUtils } from '../../Utils/Trading/OrderUtils';
import { GeneralSettings } from '../../Utils/GeneralSettings/GeneralSettings';
import { type Instrument } from './Instrument';
import { type Account } from './Account';
import { type OrderExecutionType } from '../../Utils/Trading/OrderExecutionType';

export class Order {
    public DataCache: any;
    public QuoteController: any;

    public OrderNumber: string | null;
    public Route: any;
    public Instrument: Instrument | null;
    public Account: Account | null;
    public OrderType: OrderType;
    public Amount: number;
    public DisclosedQty: any;
    public Price: number;
    public BuySell: any;
    public TimeInForce: any;
    public UTCDateTime: any;

    public _prT = ProductType.General;
    public ProductType: ProductType;

    public StopLimit = NaN;
    public ServerCalculation: any;

    public Active: any;

    public BoundTo = NO_BIND_TO;
    public RealActive = false; // TODO. Huh? Quote: "Из за неправильно логики с Active !!!".
    public ExpireAt: any = null;

    public SharesFilled = 0;
    public EverageFilledPrice = NaN;

    // Originally, type used to be long.
    public OrderGroupId: any = null;
    public PositionId: any = null;
    public SuperPositionId: any = null; // (FIFO) #80010
    public Exchange: any = null;
    public TrStopOffset = 0;
    public Status = -1;

    public FStrikePrice = 0;

    public StopLossPriceValue = NaN;
    public TakeProfitPriceValue = NaN;
    public StopLossLimitPriceValue: any = null; // #91413
    public TakeProfitPriceType = SlTpPriceType.Absolute;
    public StopLossPriceType = SlTpPriceType.Absolute;

    public TriggerSL: any = null; // #109798
    public TriggerTP: any = null;
    public TriggerSLTP: any = null;

    public Replaceable = false;

    public isActivated = false; // #92963

    public CurPriceOpen = NaN;
    public CurPriceClose = NaN;
    public _curPriceCloseNotRounded = NaN; // #116267

    public LastQuote: any = null;

    public Leverage: any;

    public OnUpdate = new CustomEvent();
    public OnPriceChanged = new CustomEvent();

    // TODO. For position only. Move to position.
    public SLOrder: any = null;
    public TPOrder: any = null;

    public isPosition = false;
    public lastPriceUpdated = false;

    public Comment: string | null = null;

    public side: OperationType;

    public ExternalOrderId: any;
    public ExternalStatus: any;
    public LastUpdateTime: Date;
    public OCOGroupID: any;
    public IsGroupPart: boolean;
    public OrderCreatedByUser: string;
    public RemoteID: string;

    static localizeSubscribed: any;

    public ExecutionType: OrderExecutionType | null = null;

    constructor (dataCache, quoteController, paramObj) {
        this.DataCache = dataCache;
        this.QuoteController = quoteController;

        this.OrderNumber = paramObj.OrderNumber;
        this.Route = paramObj.Route;
        this.Instrument = paramObj.Instrument;
        this.Account = paramObj.Account;
        this.OrderType = paramObj.OrderType;
        this.Amount = paramObj.Amount;
        this.DisclosedQty = paramObj.DisclosedQty;
        this.Price = paramObj.Price;
        this.BuySell = paramObj.BuySell;
        this.TimeInForce = paramObj.TimeInForce;
        this.UTCDateTime = paramObj.UTCDateTime;

        this.ProductType = paramObj.ProductType;

        // TODO. Move field to Position.
        this.ServerCalculation = paramObj.ServerCalculation;

        this.Active = paramObj.Active;

        this.Leverage = paramObj.Leverage || null;

        this.SubscribeToQuotes();

        // TODO. Remove. Ugly.
        Order.TrySubscribeLocalizeStatic();
    }

    get IsActivated (): boolean {
        return this.isActivated;
    }

    set IsActivated (value) {
        this.isActivated = value;
    }

    get isActive (): boolean {
        return false;
    }

    public isClosingOrder (): boolean {
        return this.PositionId && this.PositionId !== '-1';
    }

    public SubscribeToQuotes (): void {
        this.QuoteController.addListener(this.Instrument, this, HistoryType.QUOTE_LEVEL1);
        if (this.AllowSubscribeOnTrades()) {
            this.QuoteController.addListener(this.Instrument, this, HistoryType.QUOTE_TRADES);
        }

        this.Instrument.NewQuote.Subscribe(this.newQuote, this);
    }

    public UnsubscribeFromQuotes (): void {
        this.QuoteController.removeListener(this.Instrument, this, HistoryType.QUOTE_LEVEL1);
        if (this.AllowSubscribeOnTrades()) {
            this.QuoteController.removeListener(this.Instrument, this, HistoryType.QUOTE_TRADES);
        }

        this.Instrument.NewQuote.UnSubscribe(this.newQuote, this);
    }

    public AllowSubscribeOnTrades (): boolean {
        if (this.Instrument == null) {
            return false;
        }

        return (this.Instrument.CurPriceSource == CurPriceSource.Trades || this.Instrument.CurPriceSource == CurPriceSource.Level1MainOnly);
    }

    public Dispose (): void {
        this.UnsubscribeFromQuotes();
    }

    public newQuote (newQuoteMsg): void {
        let quote1Msg = null;
        if (newQuoteMsg.Type === HistoryType.QUOTE_LEVEL1) {
            quote1Msg = newQuoteMsg;
        } else if (
            // #43020 если пришла невалидная котировка Quote1, то за ней может прийти IDPM или Quote3 и обновить last, поэтому мы должны в этом случае сделать перерасчет CurPriceOpen и CurPriceClose
            newQuoteMsg.Type === HistoryType.QUOTE_INSTRUMENT_DAY_BAR ||
            newQuoteMsg.Type === HistoryType.QUOTE_TRADES) {
            quote1Msg = this.Instrument.GetLastQuote(QuoteValid.Last);
        }

        if (!quote1Msg) {
            return;
        }

        const lastPrice = this.Instrument.Level1.GetLastPrice(this.Account);
        // TODO negativ
        // if (lastPrice < 0) lastPrice = 0;

        const sp = this.DataCache.GetSpreadPlan(this.Account);
        const bid = !isNaN(quote1Msg.Bid) ? quote1Msg.BidSpread_SP_Ins(sp, this.Instrument) : lastPrice;
        const ask = !isNaN(quote1Msg.Ask) ? quote1Msg.AskSpread_SP_Ins(sp, this.Instrument) : lastPrice;

        this.CurPriceOpen =
            this.BuySell === OperationType.Buy
                ? ask
                : bid;

        if (isNaN(this.CurPriceOpen)) {
            this.CurPriceOpen = lastPrice;
        }

        if (!this.Instrument.CalcPriceOnTrade()) {
            this.CurPriceClose = this.CurPriceCloseNotRounded = this.BuySell === OperationType.Buy ? bid : ask;
            this.lastPriceUpdated = true;
            if (isNaN(this.CurPriceClose)) {
                this.CurPriceClose = this.CurPriceCloseNotRounded = lastPrice;
            }
        } else if (!isNaN(lastPrice)) {
            this.CurPriceClose = this.CurPriceCloseNotRounded = lastPrice;
            this.lastPriceUpdated = true;
        }

        this.LastQuote = quote1Msg;

        // TODO.
        // Самостоятельно следим за ценами TS
        // (пока актуально для оанды, планируем и на наш сервер перенести)
        if (this.DataCache.ManualControlPriceTStopOrders &&
            this.OrderType === OrderType.TrailingStop &&
            this.RealActive &&
            (this.BoundTo !== NO_BIND_TO || this.PositionId !== NO_BIND_TO)) {
            let newPrice = NaN;
            let setNewPrice = false;

            if (this.BuySell === OperationType.Buy) {
                newPrice = this.CurPriceOpen + this.TrStopOffset;
                setNewPrice = newPrice < this.Price || !this.Price && !!newPrice;
            } else {
                newPrice = this.CurPriceOpen - this.TrStopOffset;
                setNewPrice = newPrice > this.Price || !this.Price && !!newPrice;
            }

            if (setNewPrice) {
                this.Price = newPrice;
                this.OnPriceChanged.Raise();
            }
        }

        // TODO. For position only. Move to position.
        if (this.SLOrder) {
            this.SLOrder.newQuote(newQuoteMsg);
        }
        if (this.TPOrder) {
            this.TPOrder.newQuote(newQuoteMsg);
        }

        this.OnUpdate.Raise(this);
    }

    public GetFullOperationName (isPositionShortLongWords: boolean | undefined): string | null {
        isPositionShortLongWords = isPositionShortLongWords || false;
        const operationType = OperationType;

        let operationName = null;

        switch (this.BuySell) {
        case operationType.Buy:
            operationName = isPositionShortLongWords
                ? Order.generaltradingShort
                : Order.generaltradingBuy;
            break;
        case operationType.Sell:
            operationName = isPositionShortLongWords
                ? Order.generaltradingLong
                : Order.generaltradingSell;
            break;
        }
        return operationName;
    }

    public getInitMargin (useAccountCurr): number {
        // if (useAccountCurr || Account == null)
        //    return initMargin;
        // else
        //    return initMargin / Account.getCrossPrice();
        return 1;
    }

    public GetStopLossInPriceValue (): number {
        const offsetMode = GeneralSettings.TradingDefaults.ShowOffsetIn;
        const ins = this.Instrument;
        let slVal = this.StopLossPriceValue;
        const inOffset = this.StopLossPriceType !== SlTpPriceType.Absolute;

        if (inOffset && GeneralSettings.TradingDefaults.IsTicksFractionalForForex()) {
            slVal = OrderUtils.ConvertTickOffset(ins, offsetMode, this.Price, slVal);
        }

        if (!isNaN(slVal)) {
            return (inOffset ? ins.CalculatePrice(this.Price, slVal * (this.BuySell == OperationType.Buy ? -1 : 1)) : slVal);
        } else {
            return 0;
        }
    }

    public GetStopLossLimitInPriceValue (): number {
        const offsetMode = GeneralSettings.TradingDefaults.ShowOffsetIn;
        const ins = this.Instrument;
        let sllVal = this.StopLossLimitPriceValue;
        const slPrice = this.GetStopLossInPriceValue();
        const inOffset = this.StopLossPriceType !== SlTpPriceType.Absolute;

        if (inOffset && GeneralSettings.TradingDefaults.IsTicksFractionalForForex()) {
            sllVal = OrderUtils.ConvertTickOffset(ins, offsetMode, slPrice, sllVal) * Math.sign(sllVal);
        }

        if (sllVal != null) {
            return (inOffset ? ins.CalculatePrice(slPrice, sllVal * (this.BuySell == OperationType.Buy ? -1 : 1)) : sllVal);
        } else {
            return 0;
        }
    }

    public GetStopLossLimitInOffsetValue (): number {
        const offsetMode = GeneralSettings.TradingDefaults.ShowOffsetIn;
        const ins = this.Instrument;
        const price = this.Price;
        let sllVal = this.StopLossLimitPriceValue;
        const inOffset = this.StopLossPriceType !== SlTpPriceType.Absolute;

        if (inOffset && GeneralSettings.TradingDefaults.IsTicksFractionalForForex()) {
            sllVal = OrderUtils.ConvertTickOffset(ins, offsetMode, price, sllVal) * Math.sign(sllVal);
        }

        if (sllVal != null) {
            return (inOffset ? sllVal : ins.CalculateTicks(this.GetStopLossInPriceValue(), this.GetStopLossInPriceValue() - sllVal));
        } else {
            return 0;
        }
    }

    public GetTakeProfitInPriceValue (): number {
        const offsetMode = GeneralSettings.TradingDefaults.ShowOffsetIn;
        const ins = this.Instrument;
        let tpVal = this.TakeProfitPriceValue;
        const inAbsolute = this.TakeProfitPriceType === SlTpPriceType.Absolute;

        if (!inAbsolute && GeneralSettings.TradingDefaults.IsTicksFractionalForForex()) {
            tpVal = OrderUtils.ConvertTickOffset(ins, offsetMode, this.Price, tpVal);
        }

        if (!isNaN(tpVal)) {
            return (inAbsolute ? tpVal : ins.CalculatePrice(this.Price, tpVal * (this.BuySell == OperationType.Buy ? 1 : -1)));
        } else {
            return 0;
        }
    }

    public GetStopLossInOffsetValue (): number {
        const offsetMode = GeneralSettings.TradingDefaults.ShowOffsetIn;
        const ins = this.Instrument;
        const price = this.Price;
        let slVal = this.StopLossPriceValue;
        const inOffset = this.StopLossPriceType !== SlTpPriceType.Absolute;

        if (inOffset && GeneralSettings.TradingDefaults.IsTicksFractionalForForex()) {
            slVal = OrderUtils.ConvertTickOffset(ins, offsetMode, price, slVal);
        }

        let cPrice = Math.abs(slVal - price);
        cPrice *= (this.BuySell == OperationType.Buy ? -1 : 1);

        if (!isNaN(this.StopLossPriceValue)) {
            return (inOffset ? slVal : ins.CalculateTicks(price, cPrice));
        } else {
            return 0;
        }
    }

    public GetTakeProfitInOffsetValue (): number {
        const offsetMode = GeneralSettings.TradingDefaults.ShowOffsetIn;
        const ins = this.Instrument;
        const price = this.Price;
        let tpVal = this.TakeProfitPriceValue;
        const inOffset = this.TakeProfitPriceType !== SlTpPriceType.Absolute;

        if (inOffset && GeneralSettings.TradingDefaults.IsTicksFractionalForForex()) {
            tpVal = OrderUtils.ConvertTickOffset(ins, offsetMode, price, tpVal);
        }

        let cPrice = Math.abs(tpVal - price);
        cPrice *= (this.BuySell == OperationType.Buy ? 1 : -1);

        if (!isNaN(this.TakeProfitPriceValue)) {
            return (inOffset ? tpVal : ins.CalculateTicks(price, cPrice));
        } else {
            return 0;
        }
    }

    public isOption (): boolean {
        return this.StrikePrice > 0;
    }

    public getCrossPrice (): number {
        return this.DataCache.CrossRateCache.GetCrossPriceIns(this.Instrument);
    }

    // Если у позиции SL это StopLossLimit, то надо брать StopLimit иначе Price. Подробности -> https://tp.traderevolution.com/entity/91413
    public getPriceForStopLoss (): number {
        return this.StopLimit && this.OrderType !== OrderType.TrailingStop ? this.StopLimit : this.Price;
    }

    public getRealTrStopPrice (): any {
        if (!this.DataCache.isAllowedForMainAccount(RulesSet.FUNCTION_TRAILING_STOP_BY_PRICE)) {
            return null;
        }

        return this.Price;
        // похоже считать ненужно, он же есть у нас уже
        // let openPrice = this.getQuotePriceForTrStopCalculatingOffset()

        // let trStop = this.TrStopOffset
        // let sideSign = this.BuySell === OperationType.Buy ? 1 : -1;
        // //Посчитали истинные тики, соответсвенно потом в расчёте цены мы игнорим фракционы
        // // let ticks = Utils.toRawTicks(trStop, trStop.offsetViewMode, this.Instrument) * sideSign;
        // let ticks = trStop * sideSign;
        // return this.Instrument.roundPrice(this.Instrument.CalculatePrice(openPrice, ticks, true))
    }

    public getQuotePriceForTrStopCalculatingOffset (): number {
        const quote = this.LastQuote;
        if (!quote) {
            return 0;
        }

        const sp = this.DataCache.GetSpreadPlan(this.Account);
        return this.side === OperationType.Buy
            ? quote.AskSpread_SP_Ins(sp, this.Instrument)
            : quote.BidSpread_SP_Ins(sp, this.Instrument);
    }

    get AmountRemaining (): number {
        return this.Amount - this.SharesFilled;
    }

    get StrikePrice (): number {
        const ins = this.Instrument;
        return !this.FStrikePrice && ins && ins.StrikePrice > 0
            ? ins.StrikePrice
            : this.FStrikePrice;
    }

    set StrikePrice (value) {
        this.FStrikePrice = value;
    }

    get CurPriceCloseNotRounded (): number {
        return !isNaN(this._curPriceCloseNotRounded) ? this._curPriceCloseNotRounded : this.CurPriceClose;
    }

    set CurPriceCloseNotRounded (value) {
        this._curPriceCloseNotRounded = value;
    }

    // // https://tp.traderevolution.com/entity/105525
    // // id:106295 + id:106297 убрал
    // Object.defineProperty(Order.prototype, 'ProductType', {
    //     get: function ()
    //     {
    //         let val = this._prT
    //         if (val === ProductType.CorporateAction)
    //             val = ProductType.Delivery
    //         return val;
    //     },
    //     set: function (value)
    //     {
    //         this._prT = value
    //     }
    // })

    public static generaltradingBuy = '';
    public static generaltradingSell = '';
    public static generaltradingShort = '';
    public static generaltradingLong = '';

    // TODO. Ugly. Remove.
    public static TrySubscribeLocalizeStatic (): void {
        if (Order.localizeSubscribed) {
            return;
        }

        Resources.onLocaleChange.Subscribe(Order.Localize, Order);
        Order.localizeSubscribed = true;
        Order.Localize();
    }

    public static Localize (): void {
        const operType = OperationType;
        Order.generaltradingBuy = Resources.getResource('general.trading.' + OrderUtils.getBuySellStr(operType.Buy));
        Order.generaltradingSell = Resources.getResource('general.trading.' + OrderUtils.getBuySellStr(operType.Sell));
        Order.generaltradingShort = Resources.getResource('general.trading.position.' + OrderUtils.getBuySellStr(operType.Buy));
        Order.generaltradingLong = Resources.getResource('general.trading.position.' + OrderUtils.getBuySellStr(operType.Sell));
    }
}
