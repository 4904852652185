// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';

export class RefreshTokenMesage extends PFixMessage {
    constructor (fieldSet?) {
        super(Message.CODE_PFIX_REFRESH_TOKEN, fieldSet);
    }

    public SetRefreshToken (token: string): void {
        this.setFieldValue(FieldsFactory.FIELD_REFRESH_TOKEN, token);
    }

    public GetRefreshToken (): string {
        return this.getFieldValue(FieldsFactory.FIELD_REFRESH_TOKEN);
    }

    public GetAccessToken (): string {
        return this.getFieldValue(FieldsFactory.FIELD_ACCESS_TOKEN);
    }

    public GetAccessTokenLifeTime (): number {
        return this.getFieldValue(FieldsFactory.FIELD_ACCESS_TOKEN_LIFE_TIME);
    }

    public GetRefreshTokenLifeTime (): number {
        return this.getFieldValue(FieldsFactory.FIELD_REFRESH_TOKEN_LIFE_TIME);
    }
}
