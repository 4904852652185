// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { TerceraPanelTemplate } from "../../templates.js";
import { ContainerControl } from "./ContainerControl.js";


export let TerceraPanel = ContainerControl.extend({
    template: TerceraPanelTemplate,
});

TerceraPanel.prototype.getType = function () { return 'TerceraPanel' };

TerceraPanel.prototype.getClientPanel = function ()
{
    return this.el;
};