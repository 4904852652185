// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Behavior, type GainerLoserData } from '../../Commons/cache/Algorithm/GainersLosers/GainerLoserData';
import { BaseItem, ColumnData } from './BaseItem';
import { QuickTableComparingType } from '../../Utils/QuickTableMisc/QuickTableComparingType';
import { ColumnParams } from './ColumnParams';
import { QuickTableColumnType } from '../elements/QuickTable/QuickTableMisc';
import { Resources } from '../../Commons/properties/Resources';
import { ThemeManager } from '../misc/ThemeManager';

export class GainersLosersItem extends BaseItem {
    private static StaticId: number = 0;
    private readonly Id: number;
    public readonly Data: GainerLoserData;
    public readonly CellForeColorMap: {};

    constructor (data: GainerLoserData) {
        super(null);
        this.Id = GainersLosersItem.StaticId;
        GainersLosersItem.StaticId++;

        this.Data = data;

        this.CellForeColorMap[0] = data.Behavior === Behavior.Gainer
            ? ThemeManager.CurrentTheme.TableValueUpForeColor
            : ThemeManager.CurrentTheme.TableValueDownForeColor;
    }

    public GetItemId (): number { return this.Id; } // public ItemId (): number { return this.Id; }

    public getColumnValue (column: number): any {
        switch (column) {
        case 0:
            return this.Data.InstrumentName;
        case 1:
            return this.Data.InterestChange;
        case 2:
            return this.Data.Volume ?? NaN;
        case 3:
            return this.Data.MarketDataExchange;
        case 4:
            return this.Data.LastPrice ?? NaN;
        }
        return '';
    }

    public getColumnData (column: number): ColumnData {
        const value: any = this.getColumnValue(column);
        let formattedValue = '';
        switch (column) {
        case 2:
            formattedValue = isNaN(value) ? Resources.getResource('general.N_A') : value.toFixed();
            break;
        case 4:
            formattedValue = isNaN(value) ? Resources.getResource('general.N_A') : value.toString();
            break;
        default:
            formattedValue = value?.toString();
        }
        return new ColumnData(value, formattedValue);
    }

    // ITableItem methods
    public addItemListener (listener: any /* IItemListener */): void { }
    public removeItemListener (listener: any /* IItemListener */): void { }
    public ColumnCount (): number {
        return GainersLosersItem.columnsParams.length;
    }

    public GetColumnParams (i: number): any { /* ColumnParams | null */
        return GainersLosersItem.columnsParams[i];
    }

    public GetDataType (columnIndex: number): number { /* QuickTableComparingType */
        return GainersLosersItem.dataTypes[columnIndex];
    }
    // ITableItem methods end

    // Static Columns
    private static readonly dataTypes: any /* QuickTableComparingType[] */ = [
        QuickTableComparingType.String,
        QuickTableComparingType.Double,
        QuickTableComparingType.Double,
        QuickTableComparingType.String,
        QuickTableComparingType.Double
    ];

    public static columnsParams: ColumnParams[] = [
        new ColumnParams('panel.GainersLosersPanel.Item.Symbol', 40, QuickTableColumnType.COL_DEFAULT, false, true, false),
        new ColumnParams('panel.GainersLosersPanel.Item.Change', 40, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false),
        new ColumnParams('panel.GainersLosersPanel.Item.Volume', 40, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
        new ColumnParams('InstrumentDetailsPanel.Exchange', 40, QuickTableColumnType.COL_DEFAULT, false, false, false, true),
        new ColumnParams('panel.GainersLosersPanel.Item.Last', 40, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false)
    ];
    // Static Columns end
}
