// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from "../../../Utils/CustomEvents.ts";
import { datePickerDropDownHandler } from "../../../Utils/AppHandlers.js";
import { KeyCode, GetDigit } from "../../../Commons/KeyEventProcessor.ts";
import { TerceraDateTimePickerTemplate } from "../../../templates.js";
import { Control } from "../Control.js";
import { ScrollUtils } from "../../UtilsClasses/ScrollUtils.ts";
import moment from 'moment';

export let TerceraDateTimePicker = Control.extend({

    template: TerceraDateTimePickerTemplate,
    data: function ()
    {
        return {
            height: 20,
            dateTime: null,
            dateFromStyle: false,
            focusedDateTimePartName: null
        };
    },
    computed: {
        // For view.
        day: function () { return moment(this.get('dateTime')).format('DD'); },
        month: function () { return moment(this.get('dateTime')).format('MM'); },
        year: function () { return moment(this.get('dateTime')).format('YYYY'); },
        hours: function () { return moment(this.get('dateTime')).format('HH'); },
        minutes: function () { return moment(this.get('dateTime')).format('mm'); },
        seconds: function () { return moment(this.get('dateTime')).format('ss'); }
    },

    dateTimePartGetSetDict: null,
    dateTimePartsViewOrder: ['day', 'month', 'year', 'hours', 'minutes', 'seconds']
});

TerceraDateTimePicker.prototype.oninit = function ()
{
    Control.prototype.oninit.call(this);

    var dateTimePartGetSetDict = {};
    dateTimePartGetSetDict['day'] = { get: this.getDay, set: this.setDay };
    dateTimePartGetSetDict['month'] = { get: this.getMonth, set: this.setMonth };
    dateTimePartGetSetDict['year'] = { get: this.getYear, set: this.setYear };
    dateTimePartGetSetDict['hours'] = { get: this.getHours, set: this.setHours };
    dateTimePartGetSetDict['minutes'] = { get: this.getMinutes, set: this.setMinutes };
    dateTimePartGetSetDict['seconds'] = { get: this.getSeconds, set: this.setSeconds };
    this.dateTimePartGetSetDict = dateTimePartGetSetDict;

    this.DateTimeChanged = new CustomEvent();
    this.observe('dateTime', this.onDateTimeChanged);
    this.on('onClick', this.showDateDropDown);
    this.on('containerKeyDown', this.onContainerKeyDown);
    this.on('keyDown', this.onDateTimePartKeyDown);
    this.on('mouseWheel', this.onMouseWheel);
    this.on('containerFocus', this.onContainerFocus);
    this.on('focus', this.onDateTimePartFocus);
    this.on('blur', this.onDateTimePartBlur);
    this.onDateChangedFromDropDown = this.onDateChangedFromDropDown.bind(this);
};

TerceraDateTimePicker.prototype.showDateDropDown = function ()
{
    var $root = $(this.find('div'));
    var $window = $(window);

    var offset = $root.offset();
    var posY = offset.top - $window.scrollTop() + 19;
    var posX = offset.left - $window.scrollLeft();

    datePickerDropDownHandler.Show(
        this.get('dateTime'),
        this.onDateChangedFromDropDown,
        posX,
        posY);
};

TerceraDateTimePicker.prototype.onDateChangedFromDropDown = function (date)
{
    var curDT = this.get('dateTime');
    curDT.setFullYear(date.getFullYear());
    curDT.setMonth(date.getMonth());
    curDT.setDate(date.getDate());

    this.set('dateTime', curDT);
};

TerceraDateTimePicker.prototype.onDateTimeChanged = function (newVal)
{
    this.DateTimeChanged.Raise(this, newVal);
};

TerceraDateTimePicker.prototype.onContainerFocus = function (event)
{
    this.setFocus();
};

TerceraDateTimePicker.prototype.onDateTimePartFocus = function (event, dateTimePartName)
{
    this.set('focusedDateTimePartName', dateTimePartName);
    this.setFocus();
};

TerceraDateTimePicker.prototype.onDateTimePartBlur = function (event, dateTimePartName)
{
    this.set('focusedDateTimePartName', null);

    if (dateTimePartName === 'year')
    {
        // Validation of keyboard input.
        this.setYear(this.getYear());
    }
};

TerceraDateTimePicker.prototype.processDateTimePartsNavigation = function (keyCode)
{
    if (keyCode !== KeyCode.LEFT && keyCode !== KeyCode.RIGHT)
        return false;

    var dateTimePartsViewOrder = this.dateTimePartsViewOrder;
    var resultDateTimePartName = dateTimePartsViewOrder[0];

    var focusedDateTimePartName = this.get('focusedDateTimePartName');
    if (focusedDateTimePartName)
    {
        var idx = dateTimePartsViewOrder.indexOf(focusedDateTimePartName);
        idx = idx + (keyCode === KeyCode.LEFT ? -1 : 1);

        var maxIdx = dateTimePartsViewOrder.length - 1;

        if (idx > maxIdx) idx = 0;
        else if (idx < 0) idx = maxIdx;

        resultDateTimePartName = dateTimePartsViewOrder[idx];
    }

    var div = this.find('.' + resultDateTimePartName);
    div.focus();

    return true;
};

TerceraDateTimePicker.prototype.onContainerKeyDown = function (event)
{
    this.processDateTimePartsNavigation(event.original.which);
    return false;
};

TerceraDateTimePicker.prototype.onDateTimePartKeyDown = function (event, dateTimePartName)
{
    var keyCode = event.original.which;

    if (this.processDateTimePartsNavigation(keyCode))
        return false;

    if (keyCode === KeyCode.UP || keyCode === KeyCode.DOWN)
    {
        this.setScrollValue(dateTimePartName, keyCode === KeyCode.UP);
        return false;
    }

    var digit = GetDigit(keyCode);
    if (isNaN(digit)) return false;

    var dtPartGetSetObj = this.dateTimePartGetSetDict[dateTimePartName];
    var curDTPartValue = dtPartGetSetObj.get.call(this);
    var newDTPartValue = parseInt(curDTPartValue.toString() + digit.toString());

    // TODO. 
    var options = {
        setOnlyIfValidValue: true,
        valueFromKeyboardInput: true
    };

    var dtPartSetFunc = dtPartGetSetObj.set;
    var valueWithinBounds = dtPartSetFunc.call(this, newDTPartValue, options);
    if (!valueWithinBounds) dtPartSetFunc.call(this, digit, options);

    return false;
};

TerceraDateTimePicker.prototype.onMouseWheel = function (context, dateTimePartName)
{
    let event = context.event;
    if (this.get('focusedDateTimePartName') !== dateTimePartName)
        return false;

    this.setScrollValue(dateTimePartName, ScrollUtils.IsScrollUp(event.deltaY));
    return false;
};

TerceraDateTimePicker.prototype.setScrollValue = function (dateTimePartName, setNextValue)
{
    var getSetObj = this.dateTimePartGetSetDict[dateTimePartName];

    var val = getSetObj.get.call(this);

    val += setNextValue ? 1 : -1;

    getSetObj.set.call(this, val);
};

//#region dateTime GET/SET Methods

TerceraDateTimePicker.prototype.getDay = function ()
{
    return this.get('dateTime').getDate();
};

TerceraDateTimePicker.prototype.setDay = function (value, options)
{
    var dt = this.get('dateTime');

    var daysInMonth = moment(dt).endOf('month').date();

    var valueWithinLimits = true;
    if (value < 1)
    {
        value = daysInMonth;
        valueWithinLimits = false;
    }
    else if (value > daysInMonth)
    {
        value = 1;
        valueWithinLimits = false;
    }

    if (options && options.setOnlyIfValidValue && !valueWithinLimits)
        return valueWithinLimits;

    dt.setDate(value);
    this.update('dateTime');

    return valueWithinLimits;
};

TerceraDateTimePicker.prototype.getMonth = function ()
{
    return this.get('dateTime').getMonth() + 1;
};

TerceraDateTimePicker.prototype.setMonth = function (value, options)
{
    var valueWithinLimits = true;

    if (value < 1)
    {
        value = 12;
        valueWithinLimits = false;
    }
    else if (value > 12)
    {
        value = 1;
        valueWithinLimits = false;
    }

    if (options && options.setOnlyIfValidValue && !valueWithinLimits)
        return valueWithinLimits;

    this.get('dateTime').setMonth(value - 1);
    this.update('dateTime');

    return valueWithinLimits;
};

TerceraDateTimePicker.prototype.getYear = function ()
{
    return this.get('dateTime').getFullYear();
};

TerceraDateTimePicker.prototype.setYear = function (value, options)
{
    var minYear;
    var maxYear;

    var valueFromKeyboardInput = options && options.valueFromKeyboardInput;
    if (valueFromKeyboardInput)
    {
        minYear = 1;
        maxYear = 9999;
    }
    else
    {
        minYear = 1900;
        maxYear = 2099;
    }

    var valueWithinLimits = true;

    if (value < minYear)
    {
        value = maxYear;
        valueWithinLimits = false;
    }
    else if (value > maxYear)
    {
        value = minYear;
        valueWithinLimits = false;
    }

    if (options && options.setOnlyIfValidValue && !valueWithinLimits)
        return valueWithinLimits;

    this.get('dateTime').setFullYear(value);
    this.update('dateTime');

    return valueWithinLimits;
};

TerceraDateTimePicker.prototype.getHours = function ()
{
    return this.get('dateTime').getHours();
};

TerceraDateTimePicker.prototype.setHours = function (value, options)
{
    var valueWithinLimits = true;

    if (value < 0)
    {
        value = 23;
        valueWithinLimits = false;
    }
    else if (value > 23)
    {
        value = 0;
        valueWithinLimits = false;
    }

    if (options && options.setOnlyIfValidValue && !valueWithinLimits)
        return valueWithinLimits;

    this.get('dateTime').setHours(value);
    this.update('dateTime');

    return valueWithinLimits;
};

TerceraDateTimePicker.prototype.getMinutes = function ()
{
    return this.get('dateTime').getMinutes();
};

TerceraDateTimePicker.prototype.setMinutes = function (value, options)
{
    var valueWithinLimits = true;

    if (value < 0)
    {
        value = 59;
        valueWithinLimits = false;
    }
    else if (value > 59)
    {
        value = 0;
        valueWithinLimits = false;
    }

    if (options && options.setOnlyIfValidValue && !valueWithinLimits)
        return valueWithinLimits;

    this.get('dateTime').setMinutes(value);
    this.update('dateTime');

    return valueWithinLimits;
};

TerceraDateTimePicker.prototype.getSeconds = function ()
{
    return this.get('dateTime').getSeconds();
};

TerceraDateTimePicker.prototype.setSeconds = function (value, options)
{
    var valueWithinLimits = true;

    if (value < 0)
    {
        value = 59;
        valueWithinLimits = false;
    }
    else if (value > 59)
    {
        value = 0;
        valueWithinLimits = false;
    }

    if (options && options.setOnlyIfValidValue && !valueWithinLimits)
        return valueWithinLimits;

    this.get('dateTime').setSeconds(value);
    this.update('dateTime');

    return valueWithinLimits;
};

//#endregion

TerceraDateTimePicker.prototype.getType = function () { return 'TerceraDateTimePicker' };