// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { PriceType } from "../../../../../Utils/History/CashItemUtils";
import { iMAEx } from "./iMAEx";

export class iMA extends iMAEx
{
    constructor(period, method){
        super(period, method, 0, PriceType.Close);
    }

    public get Name(): string
    {
        return 'iMA'
    }
}