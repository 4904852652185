// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectRouteMessage extends DirectMessageBaseClass {
    // 0 bit == 1 -> active
    // 1 bit == 1 -> !active
    // 2 bit == 1 -> started
    // 3 bit == 1 -> !started
    public RouteStatus: any = null;
    public Name: string | null = null;
    public Exchanges: any = null;
    public Holidays: string | null = null;
    public QuoteServerAddress: string | null = null;
    public QuoteServerInfo: any = null;
    public TradeStatus = 0;
    public RouteId = 0;
    public QuoteRouteId = 0;
    public AllowTypeModify = false;
    public AllowAmountModify = false;
    public AllowTifModify = false;
    public AllowPriceModify = false;
    public AllowOperationTypeModify = false;
    public AllowExpirationDateModify = false;

    public DictOrderTypeTifs = {};

    public IsNewsRoute: boolean | null = null;
    public QuoteDelay: number | null = null;
    // #53234 - признак того, как должны обновляться OHLC и Volume/Ticks
    public InstrumentDayBarMessageUpdateMode = false;
    // В каком виде будет отображаться источник в Time&Sales
    public ClientShowMode = 0;

    constructor () {
        super(Message.CODE_ROUTE);
    }

    get IsInfo (): boolean {
        return (this.TradeStatus & 0x01) === 1;
    }

    get IsTradable (): boolean {
        return (this.TradeStatus & 0x02) === 2;
    }
}
