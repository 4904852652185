// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { Resources } from "../../../Commons/properties/Resources.ts"
import { InstrumentLookupQuickTreeNode } from "../QuickTree/InstrumentLookupQuickTreeNode.ts";
import { QuickTableUtils } from "../QuickTable/QuickTableUtils.ts"
import { InstrumentTypes, InstrumentTypesImageFileNameMap } from "../../../Utils/Instruments/InstrumentTypes.ts"
import { DateTimeUtils } from "../../../Utils/Time/DateTimeUtils.ts"
import { ThemeManager } from "../../misc/ThemeManager.ts"
import { Instrument } from "../../../Commons/cache/Instrument.ts";
import { InstrumentUtils } from "../../../Utils/Instruments/InstrumentUtils.ts";
import { DataCache } from "../../../Commons/DataCache.ts";
import { InsDefSettings } from "../../../Commons/cache/InstrumentDefaults.ts";
import { InstrumentLookupComparer } from "../../../Utils/Instruments/InstrumentLookupComparer.ts";

export var InstrumentLookupManager = function () { }

InstrumentLookupManager.GetNodeLVL = function (node, lvl)
{
    if (node.parentNode)
        lvl = InstrumentLookupManager.GetNodeLVL(node.parentNode, ++lvl)

    return lvl
}

InstrumentLookupManager.IsSmallLookup = function (context)   // Объединил две затычки в одну :) Теперь можно подумать над улучшением правками данного метода
{                                                            // FYI: на существование context,context.canvas, context.canvas.width проверок не добавлял в соответствии с первоисточником и чтоб не увеличивать время выполнения, добавлением доп. проверок ...                               
    return context.canvas.width <= 200                       // TODO затычка подумать.
}

InstrumentLookupManager.PopulateNode = function (node, context, instrumentItem)
{
    let small = InstrumentLookupManager.IsSmallLookup(context)
    let nodeLVL = InstrumentLookupManager.GetNodeLVL(node, 0)

    let IsFuckingHieroglyph = Resources.isHieroglyph()

    if (!IsFuckingHieroglyph)
        node.InstrumentDrawingFont = node.InstrumentFontBold

    context.font = node.InstrumentDrawingFont
    node.InstrumentNameText = instrumentItem.DisplayName()
    node.InstrumentNameTextWidth = Math.floor(QuickTableUtils.GetWidth(context, node.InstrumentNameText))

    if (!small)
    {
        node.InstrumentTradingExchangeText = instrumentItem.TradingExchange
        node.InstrumentTradingExchangeTextWidth = Math.floor(QuickTableUtils.GetWidth(context, node.InstrumentTradingExchangeText))
        node.FlagImg = DataCache.CountryCache.GetFlagImage(instrumentItem.CountryId)
    }

    let descr = instrumentItem.DescriptionValue()
    if (!descr && node.parentNode)
        descr = node.parentNode.InstrumentDescriptionText   // #113025 for big 

    node.InstrumentDescriptionText = descr
    if (node.InstrumentDescriptionText)
    {
        context.font = node.InstrumentDescriptionFont
        node.InstrumentDescriptionTextWidth = Math.floor(QuickTableUtils.GetWidth(context, node.InstrumentDescriptionText))
    }
    node.InstrumentTypeImg = InstrumentLookupManager.getInstrumentTypeImage(instrumentItem.InstrType, instrumentItem.CFD)

    if (small)
    {                       // I suppose, here we can replace 4 next rows with 1: 
        InstrumentLookupManager.PopulateSmallNode(node, context, nodeLVL)   // ~ return InstrumentLookupManager.PopulateSmallNode(node, context, nodeLVL)
        return              // but PopulateSmallNode doesn't return any value, that is not pretty good. Neither does current method(InstrumentLookupManager.PopulateNode) 
    }

    let max_name_descr_width = 200 + 17 * (7 - nodeLVL)
    let max_exchange_width = 150


    let max_textWidth = node.InstrumentNameTextWidth

    let final_name_textWidth = max_textWidth
    let final_exchange_textWidth = node.InstrumentTradingExchangeTextWidth
    let toRemoveLetersCount = 5
    let points_length = 8.3 // померял

    if (max_textWidth > max_name_descr_width)
    {
        let available_size = 0
        if (final_exchange_textWidth < max_exchange_width)
            available_size = max_exchange_width - final_exchange_textWidth

        let final_available = available_size + max_name_descr_width

        if (max_textWidth > final_available)
        {
            InstrumentLookupManager._function1_NameDescr(node, context, final_available, toRemoveLetersCount)
            final_available = node.InstrumentNameTextWidth
        }

    }
    else if (node.InstrumentDescriptionTextWidth > final_name_textWidth)
    {
        let final_available = max_name_descr_width
        if (node.InstrumentNameTextWidth < max_name_descr_width && final_exchange_textWidth > max_exchange_width)
        {
            let avaliblefromtext = max_name_descr_width - node.InstrumentNameTextWidth
            let needForExchange = final_exchange_textWidth - max_exchange_width

            if (avaliblefromtext > needForExchange)
                final_available = avaliblefromtext - needForExchange + node.InstrumentNameTextWidth
            else
                final_available = node.InstrumentNameTextWidth
        }
        else if (final_exchange_textWidth < max_exchange_width)
            final_available = max_exchange_width - final_exchange_textWidth + max_name_descr_width

        if (node.InstrumentDescriptionTextWidth > final_available)
            InstrumentLookupManager._function3_DescrCut(node, context, final_available, toRemoveLetersCount)
    }

    if (final_exchange_textWidth > max_exchange_width)
    {
        let max_exchange_textWidth = final_exchange_textWidth
        let max_Name_Part_TextWidth = node.InstrumentNameTextWidth

        let available_size = 0
        if (max_Name_Part_TextWidth < max_name_descr_width)
            available_size = max_name_descr_width - max_Name_Part_TextWidth

        let final_available = available_size + max_exchange_width
        if (max_exchange_textWidth > final_available)
        {
            InstrumentLookupManager._function4_Excange(node, context, final_exchange_textWidth, final_available, toRemoveLetersCount)
            final_available = node.InstrumentTradingExchangeTextWidth
        }
        final_exchange_textWidth = final_available
    }

    if (node.textPattern)
        InstrumentLookupManager._function2_Pattern(node, context)
}

InstrumentLookupManager.PopulateNodeFutureOptionGroupNode = function (node, context, name, description, instrumentItem)
{
    let small = InstrumentLookupManager.IsSmallLookup(context)
    let nodeLVL = InstrumentLookupManager.GetNodeLVL(node, 0)

    context.font = node.InstrumentDrawingFont
    node.InstrumentNameText = name
    node.InstrumentNameTextWidth = Math.floor(QuickTableUtils.GetWidth(context, node.InstrumentNameText))

    node.InstrumentDescriptionText = description
    if (node.InstrumentDescriptionText)
    {
        context.font = node.InstrumentDescriptionFont
        node.InstrumentDescriptionTextWidth = Math.floor(QuickTableUtils.GetWidth(context, node.InstrumentDescriptionText))
    }
    node.InstrumentTypeImg = InstrumentLookupManager.getInstrumentTypeImage(instrumentItem.InstrType, instrumentItem.CFD)

    let baseIns = instrumentItem.ForwardBaseInstrument
    if (baseIns)
    {
        node.InstrumentTradingExchangeText = baseIns.TradingExchange
        node.InstrumentTradingExchangeTextWidth = Math.floor(QuickTableUtils.GetWidth(context, node.InstrumentTradingExchangeText)) + 12
        node.FlagImg = DataCache.CountryCache.GetFlagImage(baseIns.CountryId)
    }

    let max_name_descr_width = (small ? 54 : 200) + 15 * (6 - nodeLVL)
    let max_textWidth = node.InstrumentNameTextWidth

    let toRemoveLetersCount = 5
    if (max_textWidth > max_name_descr_width)
    {
        let available_size = 0
        let final_available = available_size + max_name_descr_width

        if (max_textWidth > final_available)
            InstrumentLookupManager._function1_NameDescr(node, context, final_available, toRemoveLetersCount)
    }
}

InstrumentLookupManager._function1_NameDescr = function (node, context, final_available, toRemoveLetersCount)
{
    let points_length = 8.3 // померял
    let descrTxt = node.InstrumentDescriptionText || ''
    //кофициенты размера букв
    let n_c = Math.round(node.InstrumentNameTextWidth / node.InstrumentNameText.length)
    let d_c = Math.round(node.InstrumentDescriptionTextWidth / descrTxt.length)

    //количество букв - toRemoveLetersCount для точек
    let n_l_c = Math.floor(final_available / n_c) - toRemoveLetersCount
    let d_l_c = Math.floor(final_available / d_c) - toRemoveLetersCount

    let new_name_text = node.InstrumentNameTextWidth < final_available ? node.InstrumentNameText : node.InstrumentNameText.substring(0, n_l_c) + "..."
    let new_descr_text = node.InstrumentDescriptionTextWidth < final_available ? descrTxt : descrTxt.substring(0, d_l_c) + "..."

    let new_name_width = points_length + n_l_c * n_c
    let new_descr_width = points_length + d_l_c * d_c

    node.InstrumentNameText = new_name_text
    context.font = node.InstrumentDrawingFont
    node.InstrumentNameTextWidth = Math.floor(QuickTableUtils.GetWidth(context, node.InstrumentNameText))

    node.InstrumentDescriptionText = new_descr_text
    context.font = node.InstrumentDescriptionFont
    node.InstrumentDescriptionTextWidth = Math.floor(QuickTableUtils.GetWidth(context, node.InstrumentDescriptionText))
}

InstrumentLookupManager._function2_Pattern = function (node, context)
{
    let upN = node.InstrumentNameText.toUpperCase()
    let upD = node.InstrumentDescriptionText.toUpperCase()
    let upF = node.textPattern.toUpperCase()

    let indexN = upN.indexOf(upF)
    let indexD = upD.indexOf(upF)
    if (indexN != -1)
    {
        let substrN = node.InstrumentNameText.substring(0, indexN)
        context.font = node.InstrumentDrawingFont
        node.FilterNamePos = QuickTableUtils.GetWidth(context, substrN)
        node.FilterNameText = node.InstrumentNameText.substring(indexN, indexN + node.textPattern.length)
    }
    if (indexD != -1)
    {
        let substrD = node.InstrumentDescriptionText.substring(0, indexD)
        context.font = node.InstrumentDescriptionFont
        node.FilterDescrPos = QuickTableUtils.GetWidth(context, substrD)
        node.FilterDescrText = node.InstrumentDescriptionText.substring(indexD, indexD + node.textPattern.length)
    }
}

InstrumentLookupManager._function3_DescrCut = function (node, context, final_available, toRemoveLetersCount)
{
    let points_length = 8.3 // померял
    let d_c = Math.round(node.InstrumentDescriptionTextWidth / node.InstrumentDescriptionText.length)
    let d_l_c = Math.floor(final_available / d_c) - toRemoveLetersCount + 3

    let new_descr_width = points_length + d_l_c * d_c
    let new_descr_text = node.InstrumentDescriptionText.substring(0, d_l_c) + "..."

    node.InstrumentDescriptionText = new_descr_text
    context.font = node.InstrumentDescriptionFont
    node.InstrumentDescriptionTextWidth = Math.floor(QuickTableUtils.GetWidth(context, node.InstrumentDescriptionText)) //new_descr_width
}

InstrumentLookupManager._function4_Excange = function (node, context, final_exchange_textWidth, final_available, toRemoveLetersCount)
{
    //кофициенты размера букв
    let e_c = Math.round(final_exchange_textWidth / node.InstrumentTradingExchangeText.length)
    //количество букв - toRemoveLetersCount для точек
    let e_l_c = Math.floor(final_available / e_c) - toRemoveLetersCount

    let new_exchange_text = node.InstrumentTradingExchangeText.substring(0, e_l_c) + "..."
    let points_length = 8.3 // померял

    let new_exchange_width = points_length + e_l_c * e_c

    node.InstrumentTradingExchangeText = new_exchange_text
    context.font = node.InstrumentFont
    node.InstrumentTradingExchangeTextWidth = Math.floor(QuickTableUtils.GetWidth(context, node.InstrumentTradingExchangeText))
    if (node.InstrumentTradingExchangeTextWidth > final_available)
    {
        let dif = node.InstrumentTradingExchangeTextWidth - final_available
        let count = Math.round(dif / e_c) + 1
        new_exchange_text = node.InstrumentTradingExchangeText.substring(0, node.InstrumentTradingExchangeText.length - (count + 5)) + "..."
        node.InstrumentTradingExchangeText = new_exchange_text
        node.InstrumentTradingExchangeTextWidth = Math.floor(QuickTableUtils.GetWidth(context, node.InstrumentTradingExchangeText))
    }
}

InstrumentLookupManager.PopulateNormalSmallNode = function (node, context)
{
    let lvl = InstrumentLookupManager.GetNodeLVL(node, 0)
    let max_name_descr_width = 54 + 15 * (6 - lvl)
    context.font = node.InstrumentDrawingFont
    let max_textWidth = Math.floor(QuickTableUtils.GetWidth(context, node.nodeText))
    let toRemoveLetersCount = 5
    let points_length = 8.3 // померял

    if (max_textWidth > max_name_descr_width)
    {
        let available_size = 0
        let final_available = available_size + max_name_descr_width

        if (max_textWidth > final_available)
        {
            //кофициенты размера букв
            let n_c = Math.round(max_textWidth / node.nodeText.length)
            //количество букв - toRemoveLetersCount для точек
            let n_l_c = Math.floor(final_available / n_c) - toRemoveLetersCount
            let new_name_text = max_textWidth < final_available ? node.nodeText : node.nodeText.substring(0, n_l_c) + "..."
            let new_name_width = points_length + n_l_c * n_c
            node.nodeText = new_name_text
        }
    }
}

InstrumentLookupManager.PopulateSmallNode = function (node, context, lvl)
{
    let max_name_descr_width = 54 + 15 * (6 - lvl)
    let max_textWidth = node.InstrumentNameTextWidth
    let toRemoveLetersCount = 5

    if (max_textWidth > max_name_descr_width)
    {
        let available_size = 0
        let final_available = available_size + max_name_descr_width

        if (max_textWidth > final_available)
            InstrumentLookupManager._function1_NameDescr(node, context, final_available, toRemoveLetersCount)
    }
}

InstrumentLookupManager.PopulateNodeByStrikes = function (parentNode, cachedList, currentNameFilter, quickTree) {
    //TODO Refactoring UGLY ILACQTR.addChildNodesByInstrumentsArray
    if (parentNode.addChildNodesByInstrumentsArray)
        return parentNode.addChildNodesByInstrumentsArray(cachedList, currentNameFilter, quickTree);

    cachedList = InstrumentLookupComparer.sortInstrumentList(cachedList, "");
    parentNode.RemoveAllChildNodes();
    let childNodes = [];
    for (let i = 0, len = cachedList.length; i < len; i++)
    {
        let curIt = cachedList[i];

        if (curIt.NeedToHide())
            continue;

        let node = new InstrumentLookupQuickTreeNode(curIt.GetInteriorID());
        node.tag = curIt;
        
        if (quickTree.isMultiSelect && (parentNode.checked || parentNode.selected)) //#113317
            node.checked = node.selected = true
        if (currentNameFilter)
            node.textPattern = currentNameFilter

        InstrumentLookupManager.PopulateNode(node, quickTree.DrawingContext, curIt)

        if (!node.InstrumentDescriptionText)
            node.InstrumentDescriptionText = parentNode.InstrumentDescriptionText     // 113025+  

        if (!currentNameFilter || node.FilterNameText || node.FilterDescrText)  // 113013 big
            childNodes.push(node)
    }

    parentNode.AddChildNodeRange(childNodes);
    parentNode.collapsed = !parentNode.collapsed
    parentNode.skipAdding = true
    quickTree.Draw(true)

    return true;
}

InstrumentLookupManager.OptionProcess = function (props)
{
    let curItem = props.curItem, insTypeImg = props.insTypeImg, currentWorkNode = props.currentWorkNode, quickTree = props.quickTree, dataProvider = props.dataProvider, isInstrumentsDefaultsMode = props.isInstrumentsDefaultsMode, hasFilter = props.hasFilter, currentNameFilter = props.currentNameFilter
    if (curItem.isOptionSymbol && !curItem.isFakeOption && curItem.ForwardBaseInstrument || curItem.isFuturesSymbol)
    {
        var data = InstrumentLookupManager.getDescription(curItem), curItemforwardBaseInstrument = data.curItemforwardBaseInstrument, description = data.description, curInsNameForvardBaseInstr = data.curInsNameForvardBaseInstr
        var newNode = InstrumentLookupManager.checkCreateAddNode(currentWorkNode, curInsNameForvardBaseInstr)
        if (newNode)
        {
            newNode.tag = "group"
            newNode.sortingStop = true
            newNode.InstrumentTypeImg = insTypeImg
            if (isInstrumentsDefaultsMode)
                InstrumentLookupManager.PopulateNormalSmallNode(newNode, quickTree.DrawingContext)
            else
                InstrumentLookupManager.PopulateNodeFutureOptionGroupNode(newNode, quickTree.DrawingContext, curInsNameForvardBaseInstr, description, curItem)
        }
        //спускаемся ниже ExchangeName->InstrType->instrGroup->(Future || Option) parentInstrument
        currentWorkNode = currentWorkNode[curInsNameForvardBaseInstr]
        // гадаю, задля підвищення якості коду (збільшуючи його компактність) можна об'єднати попередній і наступний рядок в один
        if (curItem.isOptionSymbol)
            return InstrumentLookupManager.AddOptionEvent({
                curItem: curItem,
                curItemforwardBaseInstrument: curItemforwardBaseInstrument,
                insTypeImg: insTypeImg,
                currentWorkNode: currentWorkNode,
                quickTree: quickTree,
                dataProvider: dataProvider,
                description: description,
                isInstrumentsDefaultsMode: isInstrumentsDefaultsMode,
                currentNameFilter: currentNameFilter,
                isFakeQT: props.isFakeQT,
                hasFilter: hasFilter
            })
        // але тоді треба перевірити чи коректний буде порядок присвоєння і за підсумком чи отримаємо у currentWorkNode бажаний результат + тоді можна видалити (якщо не помиляюсь) останній в цьому методі return currentWorkNode
    }

    return currentWorkNode
}

InstrumentLookupManager.AddOptionEvent = function (props)
{
    let curItemforwardBaseInstrument = props.curItemforwardBaseInstrument, insTypeImg = props.insTypeImg,
        description = props.description, currentWorkNode = props.currentWorkNode, quickTree = props.quickTree, curItem = props.curItem,
        dataProvider = props.dataProvider, hasFilter = props.hasFilter, currentNameFilter = props.currentNameFilter, isInstrumentsDefaultsMode = props.isInstrumentsDefaultsMode

    //если у опциона родитель фьючерс
    if (curItemforwardBaseInstrument && (curItemforwardBaseInstrument.isFuturesSymbol || curItemforwardBaseInstrument.IsFakeNonFixed()))
    {
        var nodeName = curItemforwardBaseInstrument.DisplayName()
        description = curItemforwardBaseInstrument.DescriptionValue()
        //существует ли он в дереве?
        if (nodeName != currentWorkNode.node.nodeText)
        {
            let node = InstrumentLookupManager.checkCreateAddNode(currentWorkNode, nodeName)
            if (node)
            {
                node.InstrumentTypeImg = insTypeImg
                node.sortingStop = true
                if (isInstrumentsDefaultsMode)
                    InstrumentLookupManager.PopulateNormalSmallNode(node, quickTree.DrawingContext)
                else
                    InstrumentLookupManager.PopulateNodeFutureOptionGroupNode(node, quickTree.DrawingContext, nodeName, description, curItem)
            }
            //спускаемся ниже ExchangeName->InstrType->instrGroup->Option parentInstrument -> Option parentInstrument (Future)
            currentWorkNode = currentWorkNode[nodeName]
        }
    }

    var date = DateTimeUtils.formatDate(curItem.ExpDate, 'DD.MM.YYYY')
    //теперь дата экспирациив
    var newNode = InstrumentLookupManager.checkCreateAddNode(currentWorkNode, date)
    if (newNode)
    {
        newNode.missAtUnCollapse = true
        newNode.sortingStop = true
        newNode.InstrumentTypeImg = insTypeImg
        if (isInstrumentsDefaultsMode)
            InstrumentLookupManager.PopulateNormalSmallNode(newNode, quickTree.DrawingContext)
        else
            InstrumentLookupManager.PopulateNodeFutureOptionGroupNode(newNode, quickTree.DrawingContext, date, curItem.SeriesDescription, curItem)

        newNode.InstrumentTradingExchangeText = newNode.FlagImg = null  // не нужен флаг и exchng поскольку серия - неторговая сущность #113027
    }
    //спускаемся ниже ExchangeName->InstrType->instrGroup->Option parentInstrument -> (parentInstrument.isFuturesSymbol? Option parentInstrument(Future) : "without")->ExpDate
    currentWorkNode = currentWorkNode[date]
    let needOpenStrike = false  // #113021
    if (newNode)
    {
        let cachedList = InstrumentLookupManager.tryGetCachedStrikeList(curItem)
        if (cachedList && !props.isFakeQT)
            needOpenStrike = InstrumentLookupManager.PopulateNodeByStrikes(newNode, cachedList, currentNameFilter, quickTree);

        newNode.DoCollapse = function ()
        {
            let me = this   //TODO check: #93065 !me.childNodes.length  //#92878 !this.SkipRequest 
            if (me.collapsed && dataProvider && DataCache.NonFixedList && !me.SkipRequest)
            {
                let ins = me.tag,
                    cachedList = InstrumentLookupManager.tryGetCachedStrikeList(ins)

                if (cachedList)
                    InstrumentLookupManager.PopulateNodeByStrikes(me, cachedList, currentNameFilter, quickTree);
                else
                {
                    dataProvider.GetNonFixedInstrumentStrikes(ins).then((list) =>
                    {
                        InstrumentLookupManager.cacheStrikeList(list, ins);
                        InstrumentLookupManager.PopulateNodeByStrikes(me, list, currentNameFilter, quickTree);
                    })
                }

            }
            else
                me.collapsed = !me.collapsed
        }

        newNode.tag = curItem
    }

    let isLoadStrikesNode = (curItem.StrikePrice === -1 || curItem.StrikePrice === 0) && curItem.IsFakeNonFixed() && !!curItem.InstrDateSettingsList.length,
        needOpen = (hasFilter && !isLoadStrikesNode) || needOpenStrike

    currentWorkNode.node.collapsed = !needOpen
    currentWorkNode.node.SkipRequest = needOpen

    return currentWorkNode
}

InstrumentLookupManager._cachedStrikeList = {}

InstrumentLookupManager.tryGetCachedStrikeList = function (ins)
{
    let ID = ins ? ins.ContractID : null,
        res = InstrumentLookupManager._cachedStrikeList[ID] || null

    res = res || DataCache.GetInstrumentsByContractID(ID)      // #113518,#113548

    return res
}

InstrumentLookupManager.cacheStrikeList = function (InsList, seriaIns)
{
    let success = false, DC = DataCache, ID = seriaIns ? seriaIns.ContractID : null
    if (ID)
        success = !!(InstrumentLookupManager._cachedStrikeList[ID] = InsList)

    for (let i = 0; i < InsList.length; i++)
    {
        let ins = InsList[i];

        if (!DC.getInstrumentByName(ins.GetInteriorID()))
            DC.HandleInstrumentMessageDirect(ins.CreateInstrumentMessage())
    }

    return success
}

InstrumentLookupManager.fillTree = function (props)
{
    let items = props.items || [],
        selectedItem = props.selectedItem || null,
        selectedInstrumentType = props.selectedInstrumentType || null,
        curTypesFilter = props.curTypesFilter || null,
        curExchangeFilter = props.curExchangeFilter || null,
        currentNameFilter = props.currentNameFilter || null,
        isInstrumentsDefaultsMode = props.isInstrumentsDefaultsMode || null,
        existingInstrumentTypes = props.existingInstrumentTypes || null,
        quickTree = props.quickTree,
        out_allExchanges = props.out_allExchanges || null,
        out_allTypes = props.out_allTypes || null,
        dataProvider = props.dataProvider

    if (!items)
        return

    let selectedItemsMap = new Object()
    let selectedItemsIsArray = selectedItem instanceof Array
    if (selectedItemsIsArray)
    {
        for (let i = 0; i < selectedItem.length; i++)
            selectedItemsMap[selectedItem[i].GetInteriorID()] = selectedItem[i]
    }

    let selectedNode = null
    let nodeRangeTree = {}

    // exchange и его ид (для комбобокса)
    // [name] = exchangeId
    let allExchanges = out_allExchanges || {}
    // тип и количество нод в нём  и его ид  (для комбобокса)
    // [name] = count; [name].filterId = curItem.InstrType
    let allTypes = out_allTypes || {}

    let hasFilter = currentNameFilter !== null

    let filterString = ''
    if (hasFilter)
        filterString = currentNameFilter.toLowerCase()

    let insTypeNodeArr = []

    var allExchangeIdText = Resources.getResource('ExchangeId.All')
    if (isInstrumentsDefaultsMode)
    {
        var allExchangesNode = new InstrumentLookupQuickTreeNode(allExchangeIdText)
        allExchangesNode.collapsed = false
        var allExchangesNodeTreeObj = { node: allExchangesNode }

        nodeRangeTree[allExchangeIdText] = allExchangesNodeTreeObj

        for (var key in existingInstrumentTypes)
        {
            var intKey = parseInt(key)

            var insTypeText = Instrument.getTypeString(intKey)
            var insTypeNode = new InstrumentLookupQuickTreeNode(insTypeText)
            var insTypeImg = InstrumentLookupManager.getInstrumentTypeImage(intKey)
            insTypeNode.tag = InsDefSettings.INSTRUMENT_TYPE + intKey
            insTypeNode.InstrumentTypeImg = insTypeImg

            allExchangesNodeTreeObj[insTypeText] = {
                node: insTypeNode, groups: {}
            }
            allExchangesNode.AddChildNode(insTypeNode)

            if (key === selectedInstrumentType)
                selectedNode = insTypeNode

            insTypeNodeArr.push(insTypeNode)
        }
    }

    var len = items.length
    for (var i = 0; i < len; i++)
    {
        var curItem = items[i]

        if (curItem.NeedToHide())
            continue

        //instrGroup
        var instrGroup = curItem.getInstrumentTypeName()
        //ExchangeName   TradingExchange
        var curItemExchange_Value = isInstrumentsDefaultsMode ? allExchangeIdText : curItem.TradingExchange
        //InstrType
        var curItemInstrType_Value = curItem.CFD ? InstrumentTypes.EQUITIES_CFD : curItem.InstrType

        //localize //TODO ?
        let curItemExchangeName = curItemExchange_Value

        var curItemInstrTypeText = curItem.getTypeString()

        //#region filter
        if (!allExchanges.hasOwnProperty(curItemExchange_Value))
            allExchanges[curItemExchange_Value] = true

        if (!allTypes.hasOwnProperty(curItemInstrType_Value))
        {
            if (!(InstrumentTypes.FUTURES === curItemInstrType_Value && InstrumentUtils.UseFuturesInsteadSpot()))
                allTypes[curItemInstrType_Value] = { totalCount: 1, visibleCount: 0 }
        }
        else
            allTypes[curItemInstrType_Value].totalCount++

        if (curExchangeFilter && !curExchangeFilter[curItemExchange_Value])
            continue

        if (curTypesFilter && !curTypesFilter[curItemInstrType_Value])
            continue

        let curItemText = curItem.DisplayName()
        let descr = curItem.DescriptionValue()
        if (descr)
            curItemText += (" (" + descr + ")")
        if (hasFilter && (!DataCache.NonFixedList || isInstrumentsDefaultsMode))
        {
            var curItemFindStr = curItemText.toLowerCase()
            if (curItemFindStr.indexOf(filterString) === -1)
                continue
        }

        //todo blizzard sc2
        let full_contains = curItem.DisplayShortName().toLocaleLowerCase() === filterString

        if (allTypes[curItemInstrType_Value])
            allTypes[curItemInstrType_Value].visibleCount++

        //нода в которую закинем инструмент
        var currentWorkNode = null
        //проверяем ExchangeName ,если нет добавляем
        InstrumentLookupManager.checkCreateAddNode(nodeRangeTree, curItemExchangeName, true)

        //сейчас это ExchangeName нода
        currentWorkNode = nodeRangeTree[curItemExchangeName]
        currentWorkNode.node.tag = 'exchange'

        //проверяем InstrType ,если нет добавляем

        var insTypeImg = InstrumentLookupManager.getInstrumentTypeImage(curItem.InstrType, curItem.CFD)

        var newNode = null
        newNode = InstrumentLookupManager.checkCreateAddNode(currentWorkNode, curItemInstrTypeText)
        if (newNode)
        {
            //set images
            newNode.InstrumentTypeImg = insTypeImg
            newNode.tag = curItemInstrType_Value
            if (isInstrumentsDefaultsMode)
                InstrumentLookupManager.PopulateNormalSmallNode(newNode, quickTree.DrawingContext)
            // заполняем массив всех InstrType
            insTypeNodeArr.push(newNode)
        }

        //спускаемся ниже ExchangeName->InstrType
        var currentWorkNode = currentWorkNode[curItemInstrTypeText]

        //смотрим группу. нету? добавляем.
        var newNode = InstrumentLookupManager.checkCreateAddNode(currentWorkNode, instrGroup)
        if (newNode)
        {
            if (full_contains)
                newNode.FullContainsCount++
            newNode.tag = "group"
            newNode.SpecificTag = "instgroup"
            newNode.InstrumentTypeImg = insTypeImg
            newNode.sortingStop = true
            if (isInstrumentsDefaultsMode)
                InstrumentLookupManager.PopulateNormalSmallNode(newNode, quickTree.DrawingContext)
        }
        else 
        {
            let tmp_node = currentWorkNode[instrGroup]
            if (tmp_node && full_contains)
                tmp_node.node.FullContainsCount++
        }

        //спускаемся ниже ExchangeName->InstrType->instrGroup
        currentWorkNode = currentWorkNode[instrGroup]
        // гадаю, для підвищення якості коду (збільшуючи його компактність) можна об'єднати попередній і наступний рядок в один з двома '=' присвоєннями, 
        currentWorkNode = InstrumentLookupManager.OptionProcess({   // але тоді треба перевірити чи коректний буде порядок присвоєння і за підсумком чи отримаємо у currentWorkNode бажаний результат 
            curItem: curItem,
            insTypeImg: insTypeImg,
            currentWorkNode: currentWorkNode,
            quickTree: quickTree,
            currentNameFilter: currentNameFilter,
            dataProvider: dataProvider,
            isInstrumentsDefaultsMode: isInstrumentsDefaultsMode,
            isFakeQT: props.isFakeQT,
            hasFilter: hasFilter
        })

        //создаём ноду инструмента
        var interiorID = curItem.GetInteriorID(),
            node = null

        let parentNode = currentWorkNode.node,
            alreadyIndex = parentNode.childNodes.findIndex((e) => e.nodeText == interiorID)     // #113551
        if (alreadyIndex < 0)
        {
            node = new InstrumentLookupQuickTreeNode(interiorID)
            if (currentNameFilter)
                node.textPattern = currentNameFilter
            node.tag = curItem
            //добавляем её в ноду которую (нашли||сформировали)
            if (!parentNode.skipAdding || node.nodeText.indexOf('null') == -1)   // if тут для того чтоб не добавлять времен. опцион. инстр. когда в кеше уже имеем список страйков из [421]
                parentNode.AddChildNode(node)

            InstrumentLookupManager.PopulateNode(node, quickTree.DrawingContext, curItem)
        }
        else
            node = parentNode.childNodes[alreadyIndex]

        if (selectedItemsIsArray)
        {
            if (selectedItemsMap[interiorID])
            {
                quickTree.unCollapseAllParents(node)
                quickTree.addNodeToDict(node, true)
            }
        }
        else
            if (selectedItem === curItem)
            {
                selectedNode = node
                node.selected = true
            }
    }

    var keys = Object.keys(nodeRangeTree)
    var arrToWork = []

    for (var i = 0; i < keys.length; i++)
    {
        let node = nodeRangeTree[keys[i]].node
        node.sortingtoLowerCaseKey = node.nodeText.toLowerCase()

        if (!node.nodeLevel || !currentNameFilter || node.FilterDescrText || node.FilterNameText)
            arrToWork.push(node)
    }
    arrToWork.sort(function (a, b)
    {
        return a.sortingtoLowerCaseKey > b.sortingtoLowerCaseKey ? 1 : -1
    })

    InstrumentLookupManager.sortInstrumentTypeChildNodes(arrToWork)

    // TODO check after all
    // if (!isInstrumentsDefaultsMode)
    InstrumentLookupManager.removeUnnecessaryGroupChildNodes(insTypeNodeArr)

    quickTree.Clear()
    quickTree.AddNodeRange(arrToWork)

    if (!selectedItemsIsArray)
        if (hasFilter)
            quickTree.unCollapseAllPosible(DataCache.NonFixedList)
        else
        {
            quickTree.setSelectedNode(selectedNode)
            quickTree.goToSelectedNode()
        }
}

// InstrumentLookupManager.sortFutures = function (insTypeNodeArr)
// {
//     for (var i = 0; i < insTypeNodeArr.length; i++)
//         if (insTypeNodeArr[i].tag === Instrument.FUTURES || insTypeNodeArr[i].tag === Instrument.CFD_INDEX || insTypeNodeArr[i].tag === Instrument.CFD_FUTURES)
//             InstrumentLookupManager.sortFuturesFirstPart(insTypeNodeArr[i])
// }

// InstrumentLookupManager.sortFuturesFirstPart = function (futuresNode)
// {
//     var sortF = function (a1, a2)
//     {
//         if (a1.tag.continuous)
//             return -1
//         if (a2.tag.continuous)
//             return 1

//         if (a1.tag.ExpDate > a2.tag.ExpDate)
//             return 1
//         else if (a1.tag.ExpDate < a2.tag.ExpDate)
//             return -1
//         else
//         {

//         }

//     }

//     var childNodes = futuresNode.childNodes
//     for (var i = 0; i < childNodes.length; i++)
//     {
//         var node = childNodes[i]
//         if (node.tag === "group")
//         {
//             for (var j = 0; j < node.childNodes.length; j++)
//             {
//                 var nodeIn = node.childNodes[j]
//                 nodeIn.childNodes.sort(sortF)
//             }
//         }
//         else
//             node.childNodes.sort(sortF)
//     }
// }

InstrumentLookupManager.getDescription = function (curItem)
{
    var curInsNameForvardBaseInstr = "" // имя базового инструмента (для родительской ноды)
    var curInsDescriptionForvardBaseInstr = "" // descr (для родительской ноды)
    let curItemforwardBaseInstrument = null // сам базовый инструмент
    let description = ""
    if (curItem.ForwardBaseInstrument
        && curItem.ForwardBaseInstrument.UnderlierDescription !== null
        && curItem.ForwardBaseInstrument.UnderlierDescription !== undefined)
        description = curItem.ForwardBaseInstrument.UnderlierDescription
    // else
    //     description = curItem.UnderlierDescription
    if (curItem.isOptionSymbol)
    {
        // для опционов базовый фьюч или базовый инструмент
        var curItemforwardBaseInstrumentName = curItem.ForwardBaseInstrument.GetInteriorID()
        if (curItemforwardBaseInstrumentName)
            curItemforwardBaseInstrument = DataCache.getInstrumentByName(curItemforwardBaseInstrumentName) || curItem.ForwardBaseInstrument
        if (curItemforwardBaseInstrument)
        {
            curInsNameForvardBaseInstr = curItemforwardBaseInstrument.SourceName || curItemforwardBaseInstrument.DisplayName()
            let descr = curItemforwardBaseInstrument.SourceDescription || ""
            if (descr)
                description = descr
        }
        else
            return
    }
    // futures
    else
    {
        // базовый фьюч
        curInsNameForvardBaseInstr = curItem.SourceName
        description = curItem.SourceDescription ? curItem.SourceDescription : ""
    }

    return { curItemforwardBaseInstrument: curItemforwardBaseInstrument, curInsNameForvardBaseInstr: curInsNameForvardBaseInstr, description: description }
}

InstrumentLookupManager.checkCreateAddNode = function (dictionary, key, skipAdd)
{
    var newNode = null
    if (!dictionary[key])
    {
        dictionary[key] = {}
        newNode = new InstrumentLookupQuickTreeNode(key)
        dictionary[key].node = newNode
        if (!skipAdd)
            dictionary.node.AddChildNode(newNode)
    }
    return newNode
}

InstrumentLookupManager.removeUnnecessaryGroupChildNodes = function (insTypeNodes)
{
    var len = insTypeNodes ? insTypeNodes.length : 0
    for (var i = 0; i < len; i++)
    {
        var insTypeNode = insTypeNodes[i]
        var groupNodes = insTypeNode.childNodes

        if (groupNodes.length === 1)
        {
            var instrumentNodes = groupNodes[0].childNodes
            insTypeNode.childNodes = instrumentNodes
        }
    }
}

InstrumentLookupManager.sortInstrumentTypeChildNodes = function (exchangeNodes)
{
    var len = exchangeNodes ? exchangeNodes.length : 0
    for (var i = 0; i < len; i++)
    {
        var childNodes = exchangeNodes[i].childNodes,
            firstChild = childNodes.length ? childNodes[0] : null

        if (firstChild)
        {
            childNodes.sort(InstrumentLookupManager[firstChild.SpecificTag ?
                'instrumentSubGroupsNodeComparator' :
                'instrumentTypeNodeComparator'])

            if (!firstChild.sortingStop)
                InstrumentLookupManager.sortInstrumentTypeChildNodes(childNodes)
        }
    }
}

InstrumentLookupManager.instrumentSubGroupsNodeComparator = function (n1, n2)
{
    if (n1.FullContainsCount === n2.FullContainsCount)
        return n1.nodeText > n2.nodeText ? 1 : -1                   // Check! Perhaps better use String.prototype.localeCompare() ? Not sure cuz lots of differences: 'a' > 'A' == true => return 1, but 'a'.localeCompare('A') == -1;     'a' > 'A' == true(1), but 'a'.localeCompare('A') == -1;    'Aa' > 'aA' == false(in old way such returns -1), but 'Aa'.localeCompare('aA') == 1;        'a' > 'a' == false (=> returns -1), but 'a'.localeCompare('a') == 0 (however, no matter here, I think);         'aa' > 'aAa' == true(returns 1), but 'aa'.localeCompare('aAa') == -1  etc.        Despite it has lots of cases with equal results such as 'Aaa'.localeCompare('Aa') == 1 == 'Aaa' > 'Aa' also returns 1;             'a'.localeCompare('b') == -1 == ('a' > 'b' ? 1 : -1)    etc.  

    return n1.FullContainsCount > n2.FullContainsCount ? -1 : 1     // May replace for Math.sign(n2.FullContainsCount - n1.FullContainsCount) ? see example below
}
// InstrumentLookupManager.instrumentSubGroupsNodeComparator = function (n1, n2)
// {
//     let sign = Math.sign(n2.FullContainsCount - n1.FullContainsCount)
//     if (!sign)
//         return n1.nodeText > n2.nodeText ? 1 : -1                // check, perhaps better use String.prototype.localeCompare() ? Not sure cuz lots of differences: 'a' > 'A' == true => return 1, but 'a'.localeCompare('A') == -1;     'a' > 'A' == true(1), but 'a'.localeCompare('A') == -1;    'Aa' > 'aA' == false(in old way such returns -1), but 'Aa'.localeCompare('aA') == 1;        'a' > 'a' == false (=> returns -1), but 'a'.localeCompare('a') == 0 (however, no matter here, I think);         'aa' > 'aAa' == true(returns 1), but 'aa'.localeCompare('aAa') == -1  etc.        Despite it has lots of cases with equal results such as 'Aaa'.localeCompare('Aa') == 1 == 'Aaa' > 'Aa' also returns 1;             'a'.localeCompare('b') == -1 == ('a' > 'b' ? 1 : -1)    etc.  

//     return sign
// }

InstrumentLookupManager.instrumentTypeNodeComparator = function (n1, n2) { return n1.nodeText > n2.nodeText ? 1 : -1 }

InstrumentLookupManager.getInstrumentTypeImageFileName = function (instrumentType, cfd) 
{
    const IDsInSprite = InstrumentTypesImageFileNameMap;

    if (cfd) { return IDsInSprite[InstrumentTypes.EQUITIES_CFD]; }

    if (instrumentType === InstrumentTypes.SPOT) {
        return IDsInSprite[InstrumentUtils.UseFuturesInsteadSpot() ? InstrumentTypes.FUTURES : InstrumentTypes.SPOT];
    } else {
        return IDsInSprite[instrumentType];
    }

    return '';
}

InstrumentLookupManager.getInstrumentTypeImage = function (instrumentType, cfd) 
{
    const fileName = InstrumentLookupManager.getInstrumentTypeImageFileName(instrumentType, cfd);
    return ThemeManager.CurrentTheme[fileName];
}