// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DataCacheToolType } from '../../../Commons/cache/DataCacheToolEnums';
import { AndrewsPitchforkToolView } from '../AndrewsPitchforkToolView';
import { CircleToolView } from '../CircleToolView';
import { ElliotWaveToolView } from '../ElliotWaveToolView';
import { EllipseToolView } from '../EllipseToolView';
import { FibonacciArcToolView } from '../FibonacciArcToolView';
import { FibonacciEllipseToolView } from '../FibonacciEllipseToolView';
import { FibonacciExpansionToolView } from '../FibonacciExpansionToolView';
import { FibonacciFansToolView } from '../FibonacciFansToolView';
import { FibonacciPhiChannelToolView } from '../FibonacciPhiChannelToolView';
import { FibonacciRetracementToolView } from '../FibonacciRetracementToolView';
import { FibonacciSpiralToolView } from '../FibonacciSpiralToolView';
import { FibonacciTimeGoalToolView } from '../FibonacciTimeGoalToolView';
import { FibonacciTimeZoneToolView } from '../FibonacciTimeZoneToolView';
import { GannFanToolView } from '../GannFanToolView';
import { GannGridToolView } from '../GannGridToolView';
import { GannLineToolView } from '../GannLineToolView';
import { HorizontalLineToolView } from '../HorizontalLineToolView';
import { InfoLineToolView } from '../InfoLineToolView';
import { LineToolView } from '../LineToolView';
import { PolygonToolView } from '../PolygonToolView';
import { PriceChannelToolView } from '../PriceChannelToolView';
import { RectangleToolView } from '../RectangleToolView';
import { SymbolToolView } from '../SymbolToolView';
import { type ToolView } from '../ToolView';
import { VerticalLineToolView } from '../VerticalLineToolView';

/// <summary>
/// Factory
/// </summary>
export class ToolViewFactory {
    public static CreateToolView (dataCacheTool, fromRepopulate = false): ToolView {
        if (dataCacheTool == null) {
            return null;
        }

        let tool = null; ;
        switch (dataCacheTool.ToolType) {
        // case DataCacheToolType.Text:
        //     return new TextToolView(dataCacheTool);
        // case DataCacheToolType.Arrow:
        //     return new ArrowToolView(dataCacheTool);
        case DataCacheToolType.Symbol:
            return new SymbolToolView(dataCacheTool);
        case DataCacheToolType.InfoLine:
            return new InfoLineToolView(dataCacheTool);
        case DataCacheToolType.GannGrid:
            tool = new GannGridToolView(dataCacheTool);
            break;
        case DataCacheToolType.GannFan:
            tool = new GannFanToolView(dataCacheTool);
            break;
        case DataCacheToolType.GannLine:
            tool = new GannLineToolView(dataCacheTool);
            break;
        case DataCacheToolType.ElliottImpulse:
        case DataCacheToolType.ElliottCorrection:
        case DataCacheToolType.ElliottTriangle:
        case DataCacheToolType.ElliottTripleCombo:
        case DataCacheToolType.ElliottDoubleCombo:
            tool = new ElliotWaveToolView(dataCacheTool, fromRepopulate);
            break;
        case DataCacheToolType.FibonacciTimeZone:
            tool = new FibonacciTimeZoneToolView(dataCacheTool);
            break;
        case DataCacheToolType.FibonacciTimeGoal:
            tool = new FibonacciTimeGoalToolView(dataCacheTool);
            break;
        case DataCacheToolType.FibonacciPhiChannel:
            tool = new FibonacciPhiChannelToolView(dataCacheTool);
            break;
        case DataCacheToolType.FibonacciFans:
            tool = new FibonacciFansToolView(dataCacheTool);
            break;
        case DataCacheToolType.FibonacciEllipse:
            tool = new FibonacciEllipseToolView(dataCacheTool);
            break;
        case DataCacheToolType.FibonacciSpiral:
            tool = new FibonacciSpiralToolView(dataCacheTool);
            break;
        case DataCacheToolType.FibonacciArc:
            tool = new FibonacciArcToolView(dataCacheTool);
            break;
        case DataCacheToolType.FibonacciExpansion:
            tool = new FibonacciExpansionToolView(dataCacheTool);
            break;
        case DataCacheToolType.FibonacciRetracement:
            tool = new FibonacciRetracementToolView(dataCacheTool);
            break;
        case DataCacheToolType.PriceChannel:
            tool = new PriceChannelToolView(dataCacheTool);
            break;
        case DataCacheToolType.AndrewsPitchFork:
            tool = new AndrewsPitchforkToolView(dataCacheTool);
            break;
        case DataCacheToolType.Ellipse:
            tool = new EllipseToolView(dataCacheTool);
            break;
        case DataCacheToolType.Circle:
            tool = new CircleToolView(dataCacheTool);
            break;
        case DataCacheToolType.VerticalLine:
            tool = new VerticalLineToolView(dataCacheTool);
            break;
        case DataCacheToolType.HorizontalLine:
            tool = new HorizontalLineToolView(dataCacheTool);
            break;
        case DataCacheToolType.Line:
            tool = new LineToolView(dataCacheTool);
            break;
        case DataCacheToolType.Rectangle:
            tool = new RectangleToolView(dataCacheTool);
            break;
        case DataCacheToolType.Polygon:
            tool = new PolygonToolView(dataCacheTool);
            break;
        case DataCacheToolType.Triangle:
            tool = new PolygonToolView(dataCacheTool);
            break;
            // case DataCacheToolType.LineByAngle:
            //     tool = new LineAngleToolView(dataCacheTool);
            //     break;
            // case DataCacheToolType.Label:
            //     tool = new LabelToolView(dataCacheTool);
            //     break;

            // case DataCacheToolType.RegressionChanel:
            //     tool = new RegressionChanelToolView(dataCacheTool);
            //     break;

            // case DataCacheToolType.ABCPatern:
            //     tool = new ABCPaternToolView(dataCacheTool);
            //     break;
            // case DataCacheToolType.PPZ:
            //     tool = new PPZToolView(dataCacheTool);
            //     break;
            // case DataCacheToolType.TopBottomPattern:
            //     tool = new PPZToolView(dataCacheTool);
            //     break;
            // case DataCacheToolType.ButterflyGartley:
            //     tool = new ButterflyGartleyToolView(dataCacheTool);
            //     break;

            // case DataCacheToolType.TradingTool: // не лежат в кеше тулз, создаются из обьектов дата кеша
            //     throw new NotSupportedException("Attempt to create trading tool in wrong way.");
        }

        dataCacheTool.InitCompleted();

        return tool;
    }
}
