// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

export class VideoWidgetUtils {
    constructor () {}

    public static GetEmbedYouTubeLinkByVideoID (videoId): string {
        const linkPrefix = 'https://www.youtube.com/embed/';
        return linkPrefix + videoId;
    }

    public static GetVideoIDFromYouTubeLink (url): string {
        if (!url) {
            return null;
        }

        const videoIdRegex = /(?:v=|\/)([\w-]{11})(?:[\?&][\w\.-=]*)?/;
        let match = url.match(videoIdRegex);
        if (match) {
            return match[1];
        }

        const liveIdRegex = /\/live\/(\w+)/;
        match = url.match(liveIdRegex);
        if (match) {
            return match[1];
        }

        return null;
    }

    public static SortYouTubeExternalLinks (links) { // #114288
        const liveStreams = [];
        let upcomingStreams = [];
        const completedStreamsAndRegularVideos = [];
        for (let i = 0; i < links.length; i++) {
            const link = links[i];
            if (link.DateForSorting && isNaN(link.DateForSorting.getTime())) {
                continue;
            }

            if (link.IsUpcomingStream) { upcomingStreams.push(link); } else if (link.IsLiveStream || (link.liveStream && !link.IsEndedStream)) {
                liveStreams.push(link);
            } else {
                completedStreamsAndRegularVideos.push(link);
            }
        }

        const dateCompare = (a, b) => {
            if (!a || !b) {
                return 0;
            };

            const aDate = a.DateForSorting;
            const bDate = b.DateForSorting;

            const aTime = aDate ? aDate.getTime() : 0;
            const bTime = bDate ? bDate.getTime() : 0;

            const result = bTime - aTime;

            return result;
        };

        liveStreams.sort(dateCompare);

        upcomingStreams.sort(dateCompare);
        upcomingStreams = upcomingStreams.reverse(); // #114790

        completedStreamsAndRegularVideos.sort(dateCompare);

        links = [].concat(liveStreams, upcomingStreams, completedStreamsAndRegularVideos);

        return links;
    }
};
