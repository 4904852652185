// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { OCOOrderEdit } from '../order-edit/OCOOrderEdit';
import { OrderTypeBase } from './OrderTypeBase';
import { OrderTif } from '../../../../Utils/Trading/OrderTifEnum';
import { OrderType } from '../../../../Utils/Trading/OrderType';
import { RulesSet } from '../../../../Utils/Rules/RulesSet';
import { GeneralSettings } from '../../../../Utils/GeneralSettings/GeneralSettings';

export class OCOOrderType extends OrderTypeBase {
    constructor () {
        super(OCOOrderEdit, null);
    }

    public override id (): OrderType {
        return OrderType.OCO;
    }

    public IsSupported (params): boolean {
        const ins = params.instrument;
        const acc = params.account;

        const route = ins.DataCache.getRouteByName(ins.getRoute());
        const ordType = this.id();

        const tifs = OrderTif;
        let allowedByTif = false;
        if (ins && acc && route) {
            for (const key in tifs) {
                if (route.IsAllowableTif(tifs[key], ordType)) {
                    allowedByTif = true;
                    break;
                }
            }
        }

        return allowedByTif &&
            // TODO. REfactor. Ugly.
            OrderTypeBase.IsSupported(OrderType[GeneralSettings.TradingDefaults.UseStopLimitInsteadStop ? 'StopLimit' : 'Stop'], ins) &&
            OrderTypeBase.IsSupported(OrderType.Limit, ins) &&
            this.checkRuleForAccount(acc, RulesSet.FUNCTION_BINDEDORDERS, ins);
    }
}
