// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
/// Настройки комиссии, для конкретного инструмента, группы или дефолтового

import { commisionSplitter, commisionSplitterArr } from '../../../Utils/Commission/CommisionSplitter';
import { CommissionOperationType, CommissionTypes } from '../../../Utils/Commission/CommissionEnums';
import { CommissionDefaultAsset } from '../../../Utils/Enums/Constants';
import { KEY_FILLPERLOT, KEY_OptionExercise, KEY_ORDERPERLOT, KEY_PERFILL, KEY_PERORDERVOLUME, KEY_PERPHONETRANSACTION, KEY_PERTRANSACTION, KEY_PERVOLUME, KEY_ShortCommissionInfo, KEY_VAT, KEY_VOLUME_WITH_MIN_PD } from '../../../Utils/Trading/FeeTextConstants';
import { Resources } from '../../properties/Resources';
import { type Account } from '../Account';
import { type Instrument } from '../Instrument';
import { type CommissionItemParams } from './CommissionItemParams';

export class CommissionItem // RevenueCommissionEntryItem - тип передаваемого аргумента
{
    public Entry: any[] = [];

    constructor (data) {
        if (data) {
            this.Entry.push(data);
        }
    }

    get AssetID (): any {
        if (this.Entry.length > 0) {
            return this.Entry[0].AssetID;
        }
        // if (addLiquidityRebate != null)
        //     return addLiquidityRebate.AssetID;
        // if (removeLiquidityRebate != null)
        //     return removeLiquidityRebate.AssetID;
        return CommissionDefaultAsset.INSTRUMENT_BASE_CCY;
    }

    get Currency (): any {
        if (this.Entry.length > 0) {
            return this.Entry[0].Currency;
        }
        return '';
    }

    get ShortCommissionInfo (): any {
        if (this.Entry.length > 0) {
            return this.Entry[0].ShortCommissionInfo;
        }
        return '';
    }

    public GetCommission (params): number {
        const items = this.Entry;
        const typeToFind = params.CommissionType; // на этом этапе отсекаем по CommissionTypes
        let result = 0;

        for (let i = 0; i < items.length; i++) {
            const revenueItem = items[i];
            if (revenueItem.Type === typeToFind) {
                result += revenueItem.GetCommission(params);
            }
        }

        return result;
    }

    public Add (revenueCommissionEntryItem): void {
        const item = revenueCommissionEntryItem;
        // if (item.Type == 31)
        //     addLiquidityRebate = item;
        // else if (item.Type == 32)
        //     removeLiquidityRebate = item;
        // else
        this.Entry.push(item);
    }

    public AddFeeItems (itemsForTable): void {
        if (this.ShortCommissionInfo) {
            itemsForTable.push(KEY_ShortCommissionInfo);
            return; // <-коммент в #100313
        }
        const _feeTypesInShowOrder = [
            CommissionItem.CommissionTypesKeySuffix[CommissionTypes.PerLot],
            CommissionItem.CommissionTypesKeySuffix[CommissionTypes.OrderPerLot],
            CommissionItem.CommissionTypesKeySuffix[CommissionTypes.PerVolume],
            CommissionItem.CommissionTypesKeySuffix[CommissionTypes.PerOrderVolume],
            CommissionItem.CommissionTypesKeySuffix[CommissionTypes.VolumeWithMinPD],
            CommissionItem.CommissionTypesKeySuffix[CommissionTypes.PerFill],
            CommissionItem.CommissionTypesKeySuffix[CommissionTypes.PerTransaction],
            CommissionItem.CommissionTypesKeySuffix[CommissionTypes.PerPhoneTransaction],
            CommissionItem.CommissionTypesKeySuffix[CommissionTypes.OptionExercise],
            CommissionItem.CommissionTypesKeySuffix[CommissionTypes.VAT]];

        for (let i = 0; i < _feeTypesInShowOrder.length; i++) {
            const key = _feeTypesInShowOrder[i];

            const commissionType = CommissionTypes[key];

            if (this.ContainsCommission(commissionType)) {
                const showFormat = this.GetShowFormat(commissionType);

                const localKey = 'InstrumentDetailsPanel.' + key;

                switch (commissionType) {
                case CommissionTypes.PerLot:
                case CommissionTypes.OrderPerLot:
                case CommissionTypes.PerVolume:
                case CommissionTypes.PerOrderVolume:
                case CommissionTypes.VolumeWithMinPD:
                case CommissionTypes.OptionExercise:
                    if (!this.IsDifficult(commissionType)) {
                        this.AddToTableNotDifficult(itemsForTable, commissionType, showFormat, localKey);
                    } else // сложный кейс
                    {
                        this.AddToTableDifficult(itemsForTable, commissionType, showFormat.containsShort, localKey);
                    }
                    break;

                case CommissionTypes.PerFill:
                case CommissionTypes.PerTransaction:
                case CommissionTypes.PerPhoneTransaction:
                    this.AddToTable(itemsForTable, commissionType, localKey, CommissionOperationType.ALL);
                    break;

                case CommissionTypes.VAT:
                    itemsForTable.push(localKey);
                    break;
                }
            }
        }
    }

    public ContainsCommission (type): boolean {
        const items = this.Entry;
        if (!items?.length) return false;

        for (let i = 0; i < items.length; i++) {
            if (items[i].ContainsCommission(type)) {
                return true;
            }
        };

        return false;
    }

    public GetShowFormat (type): any {
        const isSingleField = !this.Entry.some(x => x.CommissionItems.some(y => y.Type == type && !y.GetShowFormat().isSingleField));
        const ShowOpenClose = this.Entry.some(x => x.CommissionItems.some(y => y.Type == type && y.GetShowFormat().isShowOpenClose));
        const ContainsShort = this.Entry.some(x => x.CommissionItems.some(y => y.Type == type && y.ContainsShort()));

        return {
            isSingleField,
            showOpenClose: ShowOpenClose,
            containsShort: ContainsShort
        };
    }

    public IsDifficult (commissionType: CommissionTypes): boolean {
        return this.Entry.some(x => x.CommissionItems.some(y => y.Type == commissionType && y.CommissionValueItems.some(m => m.IsBuySellShort)) && this.Entry.some(x => x.CommissionItems.some(y => y.Type == commissionType && y.CommissionValueItems.some(m => m.IsOpenOrClose))));
    }

    public IsSingleField (type): boolean {
        for (let i = 0; i < this.Entry.length; i++) {
            if (!this.Entry[i].IsSingleField(type)) {
                return false;
            }
        };

        return true;
    }

    public ShowOpenClose (type): boolean {
        for (let i = 0; i < this.Entry.length; i++) {
            if (this.Entry[i].ShowOpenClose(type)) {
                return true;
            }
        }

        return this.Entry.some(x => x.CommissionItems.some(y => y.Type == type && y.CommissionValueItems.some(m => m.IsBuySellShort)));
    }

    public ContainsShort (type): boolean {
        for (let i = 0; i < this.Entry.length; i++) {
            if (this.Entry[i].ContainsShort(type)) {
                return true;
            }
        }

        return false;
    }

    public AddToTableDifficult (itemsForTable, type, ContainsShort, key): void {
        this.AddToTable(itemsForTable, type, key, CommissionOperationType.OPENBUY);
        this.AddToTable(itemsForTable, type, key, CommissionOperationType.OPENSELL);

        if (ContainsShort) {
            this.AddToTable(itemsForTable, type, key, CommissionOperationType.OPENSHORT);
        }

        this.AddToTable(itemsForTable, type, key, CommissionOperationType.CLOSEBUY);
        this.AddToTable(itemsForTable, type, key, CommissionOperationType.CLOSESELL);
    }

    public AddToTableNotDifficult (itemsForTable, type, showformat, key): void {
        if (showformat.isSingleField) {
            this.AddToTable(itemsForTable, type, key, CommissionOperationType.ALL);
            return;
        }

        if (!showformat.showOpenClose) {
            this.AddToTable(itemsForTable, type, key, CommissionOperationType.BUY);
            this.AddToTable(itemsForTable, type, key, CommissionOperationType.SELL);
        } else {
            this.AddToTable(itemsForTable, type, key, CommissionOperationType.OPEN);
            this.AddToTable(itemsForTable, type, key, CommissionOperationType.CLOSE);
        }

        if (showformat.containsShort) // short - #37630 - отсутсвует для Per order volume fee
        {
            this.AddToTable(itemsForTable, type, key, CommissionOperationType.SHORT);
        }
    }

    public AddToTable (itemsForTable, type, key, operType): void {
        const sP = commisionSplitter;
        const ids = this.LotsListForType(type, operType);

        for (let i = 0; i < ids.length; i++) {
            itemsForTable.push(key + sP + operType + sP + ids[i] + sP + (i + 1 < ids.length ? ids[i + 1] : -1));
        }
    }

    public LotsListForType (commissionType: CommissionTypes, operationType: CommissionOperationType): any[] {
        let allLots = []; // для всех всегда есть диапазон который начинается в 0

        if ( // #38989 сложный кейс
            operationType === CommissionOperationType.OPENBUY ||
        operationType === CommissionOperationType.OPENSELL ||
        operationType === CommissionOperationType.OPENSHORT ||
        operationType === CommissionOperationType.CLOSEBUY ||
        operationType === CommissionOperationType.CLOSESELL
        ) {
            const operType = this.GetComissionOperationTypes(operationType);

            allLots = this.GetLotsListForType(commissionType, operType.type1);
            const tmpLots = this.GetLotsListForType(commissionType, operType.type2);

            for (let i = 0; i < tmpLots.length; i++) {
                const lot = tmpLots[i];
                if (!allLots.includes(lot)) {
                    allLots.push(lot);
                }
            }
        } else // простой кейс
        {
            allLots = this.GetLotsListForType(commissionType, operationType);
        }

        allLots.sort(function (a, b) { return a - b; });

        return allLots;
    }

    public GetComissionOperationTypes (commissionOpearationType: CommissionOperationType): any {
        switch (commissionOpearationType) {
        case CommissionOperationType.OPENBUY:
            return { type1: CommissionOperationType.OPEN, type2: CommissionOperationType.BUY };

        case CommissionOperationType.OPENSELL:
            return { type1: CommissionOperationType.OPEN, type2: CommissionOperationType.SELL };

        case CommissionOperationType.OPENSHORT:
            return { type1: CommissionOperationType.OPEN, type2: CommissionOperationType.SHORT };

        case CommissionOperationType.CLOSEBUY:
            return { type1: CommissionOperationType.CLOSE, type2: CommissionOperationType.BUY };

        default:
            return { type1: CommissionOperationType.CLOSE, type2: CommissionOperationType.SELL };
        }
    }

    public GetLotsListForType (commisionType: CommissionTypes, commissionOperationType: CommissionOperationType): any[] {
    // по-любому должно добавляться число 0. если не добавляется то сервер не правильно шлёт диапозоны в которых стоит значение
        const allLots = [];

        const entryItems = this.Entry;
        for (let i = 0; i < entryItems.length; i++) {
            const entryItem = entryItems[i];
            const commItems = entryItem.CommissionItems.filter(x => x.Type == commisionType);
            for (let j = 0; j < commItems.length; j++) {
                const commItem = commItems[j];
                const valItem = commItem.CommissionValueItems.filter(y => commissionOperationType == CommissionOperationType.ALL || y.OperationType == CommissionOperationType.ALL || y.OperationType == commissionOperationType);
                for (let k = 0; k < valItem.length; k++) {
                    if (!allLots.includes(valItem[k].FromAmount)) {
                        allLots.push(valItem[k].FromAmount);
                    }
                }
            }
        }
        // for (let i = 0; i < this.Entry.length; i++)
        //     this.Entry[i].GetLotsListForType(allLots, commissionType, operationType)

        return allLots;
    }

    public GetCommissionDataValue (feeCurrency, key): any {
        const items = this.Entry;
        if (!items?.length) return null;

        if (key.indexOf(KEY_FILLPERLOT) != -1) {
            return this.getCommissionDataValue(feeCurrency, key, CommissionTypes.PerLot);
        }

        if (key.indexOf(KEY_ORDERPERLOT) != -1) {
            return this.getCommissionDataValue(feeCurrency, key, CommissionTypes.OrderPerLot);
        }

        if (key.indexOf(KEY_PERFILL) != -1) {
            return this.getCommissionDataValue(feeCurrency, key, CommissionTypes.PerFill);
        }

        if (key.indexOf(KEY_PERVOLUME) != -1) {
            return this.getCommissionDataValue(feeCurrency, key, CommissionTypes.PerVolume);
        }

        if (key.indexOf(KEY_PERORDERVOLUME) != -1) {
            return this.getCommissionDataValue(feeCurrency, key, CommissionTypes.PerOrderVolume);
        }

        if (key.indexOf(KEY_PERTRANSACTION) != -1) {
            return this.getCommissionDataValue(feeCurrency, key, CommissionTypes.PerTransaction);
        }

        if (key.indexOf(KEY_PERPHONETRANSACTION) != -1) {
            return this.getCommissionDataValue(feeCurrency, key, CommissionTypes.PerPhoneTransaction);
        }

        if (key.indexOf(KEY_VAT) != -1) {
            return this.GetCommissionStringFormat(feeCurrency, CommissionTypes.VAT, CommissionOperationType.ALL, 0);
        }

        if (key.indexOf(KEY_VOLUME_WITH_MIN_PD) != -1) {
            return this.GetCommissionStringFormat(feeCurrency, CommissionTypes.VolumeWithMinPD, CommissionOperationType.ALL, 0);
        }

        if (key.indexOf(KEY_OptionExercise) != -1) {
            return this.getCommissionDataValue(feeCurrency, key, CommissionTypes.OptionExercise);
        }

        if (key.indexOf(KEY_ShortCommissionInfo) != -1) {
            return this.ShortCommissionInfo;
        }

        return null;
    }

    public getCommissionDataValue (feeCurrency, key, type) {
        const commisionKey = key.split(commisionSplitterArr);

        // имя которое отображается в таблице составное: имя + CommissionOperationType, если тип ALL то скипаем вторую часть, иначе добавляем
        if (commisionKey.length == 4) {
            const operationType = parseFloat(commisionKey[1]);
            const fromLots = parseFloat(commisionKey[2]);
            const lotsTo = parseFloat(commisionKey[3]);

            return this.GetFeeString(fromLots, lotsTo,
                this.GetCommissionStringFormat(feeCurrency, type, operationType, fromLots), true, false);
        }
        return null;
    }

    public GetFeeString (lotsFrom, lotsTo, value, allowLimit, allowHighLimit, feeVar: FeeStringVariable | undefined = undefined): string {
        feeVar = feeVar || FeeStringVariable.Amount;

        if (lotsFrom === 0 && lotsTo === -1) {
            return value;
        }

        const localizeKey = Resources.getResource(
            feeVar === FeeStringVariable.Price
                ? 'InstrumentDetailsPanel.Price'
                : 'InstrumentDetailsPanel.Amount');

        return lotsFrom +
        (allowLimit ? ' <= ' : ' < ') +
        localizeKey +
        (allowHighLimit ? ' <= ' : ' < ') +
        (lotsTo === -1 ? '\u221e'/* ∞ */ : parseFloat(lotsTo).toString()) +
        commisionSplitter +
        value;
    }

    public GetCommissionValue (commissionType, commissionOperationType, fromLots, isRebates): number {
        isRebates = isRebates || false;
        const param: CommissionItemParams = {
            CommissionType: commissionType,
            OperationType: commissionOperationType,
            Entry: this.Entry,
            Lots: fromLots,
            IgnoreMinMaxCorrection: true
        };

        return CommissionItem.GetCommissionForType(param) * (isRebates ? -1 : 1);
    }

    /// string representation of commision in selected Currency or %
    public GetCommissionStringFormat (feeCurrency, commissionType, commissionOperationType, fromLots, isRebates: boolean | undefined = undefined): any {
        const value = this.GetCommissionValue(commissionType, commissionOperationType, fromLots, isRebates);
        if (!value && !this.ContainsCommission(commissionType)) {
            return;
        }

        switch (commissionType) {
        case CommissionTypes.VolumeWithMinPD:
        case CommissionTypes.VAT:
        case CommissionTypes.PerVolume:
        case CommissionTypes.PerOrderVolume:
            return value + ' %';

        default: // если тип комисии по-инструменту то Currency не приходит с сервера, попробуем взять валюту котирования инструмента.
            return value + ' ' + feeCurrency;
        }
    }

    public GetCommissionInfo (): any {
        return this.ShortCommissionInfo;
    }

    public static GetCommissionOperationTypes (operationType: CommissionOperationType): CommissionOperationType[] {
        switch (operationType) {
        case CommissionOperationType.OPENBUY:
            return [CommissionOperationType.OPEN, CommissionOperationType.BUY];

        case CommissionOperationType.OPENSELL:
            return [CommissionOperationType.OPEN, CommissionOperationType.SELL];

        case CommissionOperationType.OPENSHORT:
            return [CommissionOperationType.OPEN, CommissionOperationType.SHORT];

        case CommissionOperationType.CLOSEBUY:
            return [CommissionOperationType.CLOSE, CommissionOperationType.BUY];

        default:
            return [CommissionOperationType.CLOSE, CommissionOperationType.SELL];
        }
    }

    public static GetCommissionForType (param): number {
        if (param.CommissionType == CommissionTypes.VAT) {
            return CommissionItem.GetVatCommission(param);
        }

        const operationType = param.OperationType;

        if (operationType == CommissionOperationType.OPENBUY ||
        operationType == CommissionOperationType.OPENSELL ||
        operationType == CommissionOperationType.OPENSHORT ||
        operationType == CommissionOperationType.CLOSEBUY ||
        operationType == CommissionOperationType.CLOSESELL) {
            const operType = CommissionItem.GetCommissionOperationTypes(operationType);
            let value = 0;

            param.OperationType = operType[0];
            value += CommissionItem.CalculateCommissionForType(param);

            param.IsNotIgnoreAll = false;
            param.OperationType = operType[1];
            value += CommissionItem.CalculateCommissionForType(param); // не учитываем операции All, ранее они уже были посчитаны
            return value;
        }

        return CommissionItem.CalculateCommissionForType(param);
    }

    public static GetVatCommission (param): number {
    // foreach(var entry in Entry.Where(x => x.UseInVAT))
    // foreach(var item in entry.CommissionItems.Where(y => y.Type == CommissionTypes.VAT))
    // return item.CommissionValueItems[0].Value;
        return CommissionItem.CalculateCommissionForType(param);
    }

    public static CalculateCommissionForType (param): number {
        let value = 0;
        for (let i = 0; i < param.Entry.length; i++) {
            const entry = param.Entry[i];
            // Для total и vat расчета проверяем флаг UseInVat
            if ((!param.IgnoreUseInVat && !entry.UseInVAT) || entry.IsEmpty) {
                continue;
            }

            value += entry.GetCommission(param);
        }
        return value;
    }

    public static GetCommissionCurrency (account: Account, instrument: Instrument): any {
        const item = account.CommissionPlan.GetCommissionItem(instrument);
        if (!item) return null;

        const dCache = instrument.DataCache;
        if (!dCache) {
            return item.Currency;
        }

        if (item.AssetID >= 0) {
            const asset = dCache.GetAssetById(item.AssetID);
            if (asset != null) {
                return asset.Name;
            }
        }
        if (item.AssetID == CommissionDefaultAsset.INSTRUMENT_BASE_CCY) {
            return instrument.Exp1;
        }
        if (item.AssetID == CommissionDefaultAsset.INSTRUMENT_QUOTING_CCY) {
            return instrument.Exp2;
        }

        return item.Currency;
    }

    public static CommissionTypesKeySuffix = Object.fromEntries(Object.entries(CommissionTypes).map(([k, v]) => [v, k]));
    public static OperationTypeSuffix = Object.keys(CommissionOperationType);
}

enum FeeStringVariable {
    Amount = 0,
    Price = 1
}
