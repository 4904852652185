// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectTradeSessionStatusMessage } from '../../../../Utils/DirectMessages/DirectTradeSessionStatusMessage';
import { SessionOperations } from '../../../../Utils/Enums/Constants';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class TradeSessionStatusMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): DirectTradeSessionStatusMessage[] {
        const msg = new DirectTradeSessionStatusMessage();
        msg.Id = fieldSet.GetValue(FieldsFactory.FIELD_ID);
        msg.Name = fieldSet.GetValue(FieldsFactory.FIELD_NAME);
        msg.IsDecreaseOnlyPositionCount = fieldSet.GetValue(FieldsFactory.FIELD_IS_DECREASE_ONLY_POSITION_COUNT);

        const operations: string[] = fieldSet.GetValue(FieldsFactory.FIELD_SESSION_ALLOWED_OPERATIONS)
            .split(';')
            .filter(function (el) { return el.length > 0; });

        if (operations.length > 0) {
            if (operations[0] !== '0') {
                msg.AllowedOperations |= SessionOperations.Open;
            }
            if (operations[1] !== '0') {
                msg.AllowedOperations |= SessionOperations.Modify;
            }
            if (operations[2] !== '0') {
                msg.AllowedOperations |= SessionOperations.Cancel;
            }
        }

        const types = fieldSet.GetValue(FieldsFactory.FIELD_SESSION_ALLOWED_ORDER_TYPES)
            .split(';')
            .filter(function (el) { return el.length > 0; });

        const len = types.length;
        for (let i = 0; i < len; i++) {
            if (types[i] !== '0') {
                msg.AllowedOrderTypes.push(i);
            }
        }

        return [msg];
    }
}
