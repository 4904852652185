import { Resources } from '../../../../properties/Resources';
import { type Order } from '../../../Order';
import { type Position } from '../../../Position';
import { SavedOrder } from '../../../SavedOrders/SavedOrder';
import { LinkedPriceType } from '../../../SavedOrders/SavedOrderEnums';

export class PaperPosition extends SavedOrder {
    private _position: Position = undefined;

    public Analyse: boolean = false;

    public get PaperPositionType (): string {
        return !isNullOrUndefined(this._position) ? Resources.getResource('panel.optionPaperPositions.Position') : Resources.getResource('panel.optionPaperPositions.PaperPosition');
    }

    public get CurrentClosePrice (): number {
        return !isNullOrUndefined(this._position) && this._position.lastPriceUpdated ? this._position.CurPriceClose : NaN;
    }

    public get Fee (): number {
        return !isNullOrUndefined(this._position) ? this._position.GetCommissionFromSettlement(true) : 0;
    }

    public attachPosition (position: Position): void {
        this._position = position;
        this.Price = NaN;
        this.StopPrice = NaN;
        this.LinkedPriceOffset = 0;
        this.LinkedPriceType = LinkedPriceType.None;
        this.updatePosition();
    }

    public isAttachedPosition (position: Position): boolean {
        return this._position === position;
    }

    public updatePosition (): void {
        const position = this._position;
        if (isNullOrUndefined(position)) {
            return;
        }
        this.Operation = position.BuySell;
        this.TIF = position.TimeInForce;
        this.OrderType = position.OrderType;
        this.ProductType = position.ProductType;
        this.Leverage = position.Leverage;
        const slOrder: Order = position.SLOrder;
        if (!isNullOrUndefined(slOrder)) {
            this.SLTPHolder.StopLossPriceType = slOrder.StopLossPriceType;
            this.SLTPHolder.StopLossPriceValue = slOrder.StopLossPriceValue;
            this.SLTPHolder.StopLossLimitPriceValue = slOrder.StopLossLimitPriceValue;
        } else {
            this.SLTPHolder.StopLossPriceValue = NaN;
            this.SLTPHolder.StopLossLimitPriceValue = NaN;
        }
        const tpOrder: Order = position.TPOrder;
        if (!isNullOrUndefined(tpOrder)) {
            this.SLTPHolder.TakeProfitPriceType = tpOrder.TakeProfitPriceType;
            this.SLTPHolder.TakeProfitPriceValue = tpOrder.TakeProfitPriceValue;
        } else {
            this.SLTPHolder.TakeProfitPriceValue = NaN;
        }
    }
}
