// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts"
import { MathUtils } from "../../Utils/MathUtils.ts"
import { InstrumentUtils } from "../../Utils/Instruments/InstrumentUtils.ts"
import { contextMenuHandler } from "../../Utils/AppHandlers.js"
import { TerceraQuantityNumericTemplate } from "../../templates.js"
import { TypesManagerScreen } from "../screen/TypesManagerScreen.js"
import { ContainerControl } from "./ContainerControl.js"
import { TerceraNumeric } from "./TerceraNumeric.js"
import { ProductType } from "../../Utils/Instruments/ProductType.ts"
import { GeneralSettings } from "../../Utils/GeneralSettings/GeneralSettings.ts"
import { Quantity } from "../../Utils/Trading/Quantity.ts"
import { SessionSettings } from "../../Commons/SessionSettings.ts"
import { ControlsTypes } from "../UtilsClasses/FactoryConstants.ts"

export let TerceraQuantityNumeric = ContainerControl.extend({
    template: TerceraQuantityNumericTemplate,
    data: function ()
    {
        return {
            isPosAbsolute: false,
            account: null,
            instrument: null,
            inLots: false,
            height: '',
            // TODO. Ugly. Remove.
            defaultQuantity: null,
            noArrowCB: false,
            showArrows: true
        }
    },
    addedInsValuesLots: null
})

TerceraQuantityNumeric.prototype.getType = function () { return ControlsTypes.TerceraQuantityNumeric; }

TerceraQuantityNumeric.prototype.oninit = function ()
{
    ContainerControl.prototype.oninit.call(this)

    this.addedInsValuesLots = {}

    this.on('openQuantityMenu', this.openQuantityMenu)
    // TODO. Ugly. Remove defaultQuantity.
    this.observe('productType', this.UpdateProductType)
    this.observe('defaultQuantity', this.UpdateDefaultQuantity)
    this.observe('instrument', this.UpdateInstrument)
    this.observe('account', this.UpdateAccount)
    this.observe('value', this.onValueChanged)

    GeneralSettings.SettingsChanged.Subscribe(this.settingsUpdate, this)
    GeneralSettings.InsDefSettingsStorage.DefaultSettingsChanged.Subscribe(this.settingsUpdate, this)
}

TerceraQuantityNumeric.prototype.dispose = function ()
{
    ContainerControl.prototype.dispose.call(this)

    GeneralSettings.SettingsChanged.UnSubscribe(this.UpdateRiskSettings, this)
    GeneralSettings.InsDefSettingsStorage.DefaultSettingsChanged.UnSubscribe(this.UpdateRiskSettings, this)

    let ins = this.get('instrument')
    if (ins && !ins.IsEmpty)
        ins.RiskSettingsUpdated.UnSubscribe(this.UpdateRiskSettings, this)
}

TerceraQuantityNumeric.prototype.UpdateInstrument = function (n, o, k)
{
    if (o && !o.IsEmpty)
        o.RiskSettingsUpdated.UnSubscribe(this.UpdateRiskSettings, this)

    if (n && !n.IsEmpty)
        n.RiskSettingsUpdated.Subscribe(this.UpdateRiskSettings, this)
    this.settingsUpdate(true);
}

TerceraQuantityNumeric.prototype.UpdateDefaultQuantity = function ()
{
    this.settingsUpdate();
}

TerceraQuantityNumeric.prototype.UpdateRiskSettings = function ()
{
    this.settingsUpdate(true);
}

TerceraQuantityNumeric.prototype.UpdateProductType = function ()
{
    this.settingsUpdate(true);
}

TerceraQuantityNumeric.prototype.UpdateAccount = function ()
{
    this.settingsUpdate(true);
}

TerceraQuantityNumeric.prototype.settingsUpdate = function (restoreQty = false)
{
    let ins = this.get('instrument')
    if (!ins)
        return

    let inLots = GeneralSettings.View.displayAmountInLots()
    this.set('inLots', inLots)

    let selectedProductType = this.get('productType');
    let pT = 0;
    if (selectedProductType && selectedProductType == ProductType.Delivery && Object.keys(ins.RiskSettings.RiskPlanSettings.cache).length > 1)
        pT = 1

    let qtyNumericSettings = Quantity.getQuantityNumericSettings(ins, inLots, pT, this.get('account'))

    // TODO. Ugly. Remove.
    let defaultQuantity = this.get('defaultQuantity')
    if (defaultQuantity)
    {
        qtyNumericSettings.value = Quantity.convertQuantityValue(
            defaultQuantity, ins, inLots)
    }

    if (restoreQty)
    {
        qtyNumericSettings.value = Quantity.convertQuantityValue(
            new Quantity(this.get('value'), inLots), ins, inLots)
    }

    this.set(qtyNumericSettings)
    this.createDefaultLotsDropDown()
}

TerceraQuantityNumeric.prototype.onValueChanged = function (value)
{
    this.fire(TerceraNumeric.Events.ValueChanged, value)
}

TerceraQuantityNumeric.prototype.openQuantityMenu = function (context)
{
    if (!this.get('enabled'))
        return

    let control = $(this.find('.js-popup-button'));
    let pos = control.offset();
    let height = control.outerHeight();
    contextMenuHandler.Show(this.menuItems, pos.left, pos.top + height);
    this.setFocus();
}

TerceraQuantityNumeric.prototype.setFocus = function ()
{
    ContainerControl.prototype.setFocus.apply(this);
    this.Controls.QTYNumeric.setFocus()
};

TerceraQuantityNumeric.prototype.createDefaultLotsDropDown = function ()
{
    let menuItems = []
    let sessionSettings = SessionSettings

    let ins = this.get('instrument')
    // Getting instrument's defaults.
    let insDefaults = GeneralSettings.InsDefSettingsStorage.GetInstrumentSettings(ins)
    if (!insDefaults)
    {
        return
    }
    // Populating dropdown by instrument's default lots values.
    let sortedLots = []
    // Set of doubles.
    let lotValues = {}

    let defLots = insDefaults.DefaultLots
    let defLots_len = defLots.length
    for (let i = 0; i < defLots_len; i++)
    {
        let selectiveLot = defLots[i]
        let selectiveLotVal = selectiveLot.Lots

        if (!selectiveLot.Selected || lotValues[selectiveLotVal])
        {
            continue
        }

        lotValues[selectiveLotVal] = true
        sortedLots.push(selectiveLotVal)
    }
    sortedLots.sort(TerceraQuantityNumeric.sortLots)

    let strItemsList = []
    let decimalPrecision = this.get('decimalPrecision')
    let divider = Math.pow(10, decimalPrecision)

    let callback = this.onDropdownValueSelected.bind(this)
    // #37103 - возможна такая ситуация, когда значения default lots меньше чем Lot step,
    // поэтому мы здесь удаляем дубликаты, если необходимо
    let showLots = GeneralSettings.View.DisplayQuantityInLots;
    let sortedLots_len = sortedLots.length
    for (let i = 0; i < sortedLots_len; i++)
    {
        let convValue = sortedLots[i]
        if (!showLots)
        {
            convValue *= ins.LotSize
        }

        // обрезаем без округления дробную часть числа, которая выходит за пределы DecimalPlaces
        let dConvValue = MathUtils.trunc(convValue * divider) / divider
        // Js float calc fix.
        dConvValue = parseFloat(dConvValue.toFixed(decimalPrecision))
        let strConvValue = MathUtils.formatValueWithEps(InstrumentUtils.formatAmountValue(convValue, ins))

        if (strItemsList.indexOf(strConvValue) === -1 && dConvValue !== 0)
        {
            strItemsList.push(strConvValue)
            menuItems.push({
                text: strConvValue,
                tag: dConvValue,
                event: callback
            })
        }
    }

    // Populating dropdown by added values.
    let filteredAddedInsValuesLots = this.addedInsValuesLots[ins.GetInteriorID()]
    if (filteredAddedInsValuesLots && filteredAddedInsValuesLots.count())
    {
        if (menuItems.length)
        {
            menuItems.push({ separator: true })
        }

        let insertPos = menuItems.length
        let userLots_len = filteredAddedInsValuesLots.count()
        for (let i = 0; i < userLots_len; i++)
        {
            let convValue = filteredAddedInsValuesLots.value(i)

            if (!showLots)
            {
                convValue *= ins.LotSize
            }

            menuItems.splice(insertPos, 0, {
                text: convValue.toFixed(decimalPrecision),
                tag: convValue,
                event: callback
            })
        }
    }

    // Adding "Edit" button.
    if (menuItems.length)
    {
        menuItems.push({ separator: true })
    }

    menuItems.push({
        text: Resources.getResource('NumericComboCtrl.Edit'),
        event: this.onEditClicked.bind(this)
    })

    this.menuItems = menuItems
}

TerceraQuantityNumeric.prototype.onDropdownValueSelected = function (menuItem)
{
    let value = menuItem.tag
    this.set('value', value)
}

TerceraQuantityNumeric.prototype.onEditClicked = function ()
{
    TypesManagerScreen.show(
        this,
        this.get('instrument'),
        TerceraQuantityNumeric.typesManagerScreenCallBack)
}

TerceraQuantityNumeric.typesManagerScreenCallBack = function (settings)
{
    SessionSettings.updateInstrumentsDefaultSettings(settings)
}

// For TypesManagerScreen.
TerceraQuantityNumeric.prototype.getAllInstrumentsDefaultSettings = function ()
{
    return GeneralSettings.InsDefSettingsStorage.GetSettingsCopy()
}

TerceraQuantityNumeric.prototype.addValue = function (val)
{
    let ins = this.get('instrument')
    if (!ins)
    {
        return
    }

    let instrumentID = ins.GetInteriorID()
    let addedInsValuesLots = this.addedInsValuesLots
    if (!addedInsValuesLots.hasOwnProperty(instrumentID))
    {
        addedInsValuesLots[instrumentID] =
            new UniqueLimitedQueue(UniqueLimitedQueue.DROPDOWN_ADDED_VALUES_LIMIT)
    }

    if (!GeneralSettings.View.DisplayQuantityInLots)
    {
        val /= ins.LotSize
    }

    // пересоздаем список значений
    if (addedInsValuesLots[instrumentID].tryEnqueue(val))
    {
        this.createDefaultLotsDropDown()
    }
}

TerceraQuantityNumeric.sortLots = function (a, b) { return a - b }

// #region UniqueLimitedQueue

let UniqueLimitedQueue = function (limit)
{
    this.queue = []
    this.limit = limit
}

UniqueLimitedQueue.prototype.tryEnqueue = function (obj)
{
    let queue = this.queue
    if (queue.indexOf(obj) !== -1)
    {
        return false
    }

    queue.push(obj)

    let limit = this.limit
    while (queue.length > limit)
    {
        queue.shift()
    }

    return true
}

UniqueLimitedQueue.prototype.value = function (idx)
{
    return this.queue[idx]
}

UniqueLimitedQueue.prototype.clear = function ()
{
    this.queue = []
}

UniqueLimitedQueue.prototype.count = function ()
{
    return this.queue.length
}

UniqueLimitedQueue.DROPDOWN_ADDED_VALUES_LIMIT = 3

// #endregion
