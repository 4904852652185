// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../../Commons/properties/Resources.ts";
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";
import { AdvancedOrderEntry } from "./AdvancedOrderEntry.js";
import { AlertPanel } from "./AlertPanel.js";
import { MarketConsensusPanel } from "./MarketConsensusPanel.ts";
import { AssetBalancesPanel } from "./AssetBalancesPanel.js";
import { CreateAlertPanel } from "./CreateAlertPanel.js";
import { EventsPanel } from "./EventsPanel.js";
import { FilledOrdersPanel } from "./FilledOrdersPanel.js";
import { InformerPanel } from "./InformerPanel.js";
import { OrderHistoryPanel } from "./OrderHistoryPanel.ts";
import { OrdersPanel } from "./OrdersPanel.ts";
import { PositionsPanel } from "./PositionsPanel.ts";
import { ProductsPanel } from "./ProductsPanel.js";
import { ReportsPanel } from "./ReportsPanel.js";
import { RiskCalculator } from "./RiskCalculator.js";
import { TSPanel } from "./TSPanel.js";
import { AccountDetailsPanel } from "./AccountDetailsPanel.ts";
import { SymbolInfoPanel } from "./SymbolInfoPanel.js";
import { MarginOEControl } from "./MarginOEControl.js";
import { MarketDepthPanel } from "./MarketDepthPanel.js";
import { ChartPanel } from "./ChartPanel.js";
import { GridPanel } from "./GridPanel.ts";
import { FullLogsPanel } from "./FullLogsPanel.js";
import { MultiTimeSalesPanel } from "../Custom/MultiTimeSalesPanel/MultiTimeSalesPanel.js";
import { InstrumentHolidaysScreen } from "./InstrumentHolidaysScreen.js";
import { SavedOrdersPanel } from "./SavedOrdersPanel.ts";
import { StatementPanel } from "./StatementPanel.ts";
import { TradingCentral } from "./TradingCentral.js";
import { IdeasPanel } from "./IdeasPanel.js";
import { NotificationsPanel } from "./NotificationsPanel.js";
import { ExternalScreen } from "../screen/ExternalScreen.ts";
import { AssetsPanel } from "./AssetsPanel.js";
import { PositionsBalancePanel } from "./PositionsBalancePanel.ts";
import { NewsPanel } from "./NewsPanel.js";
import { TerceraClosePositionScreen } from "../screen/TerceraClosePositionScreen.js";
import { ModifyPositionScreen } from "../screen/ModifyPositionScreen.js";
import { ModifyOrderScreen } from "../screen/ModifyOrderScreen.js";
import { EditPositionScreen } from "../screen/EditPositionScreen.js";
import { PositionExerciseRequestScreen } from "../screen/PositionExerciseRequestScreen.js";
import { DockSystem, DockSystemInstance } from "../DockSystem.js";
import { VideoWidget } from "./VideoWidget/VideoWidget.js";
import { TerceraMessageBox } from "../screen/TerceraMessageBox.js";
import { PanelLocKeys, PanelNames, ScreensNames } from "../UtilsClasses/FactoryConstants.ts";
import { RulesSet } from "../../Utils/Rules/RulesSet.ts";
import { DataCache } from "../../Commons/DataCache.ts";
import { WorkSpaceManager } from "../WorkSpace/WorkSpaceManager.ts";
import { ClosePositionsMutually } from "./ClosePositionsMutually.js";
import { TradingViewChartPanel } from "./TradingViewChartPanel.ts";
import { GainersLosersPanel } from "./GainersLosersPanel.ts";
import { HighLowPanel } from "./HighLowPanel.ts";
import { HeatmapPanel } from "./HeatmapPanel.ts";
import { OptionMasterPanel } from './OptionMaster/OptionMasterPanel.ts'


let _Factory = function () { };

//
// Создание всех панелей
//
_Factory.prototype.addPanel = function (type, from, afterCreationCallback)
{
    if (!WorkSpaceManager.currentWorkspace)
        return;

    var fromRestore = from === FactoryAddPanelFrom.Restore,
        value = this.GetThrottlingOperationValue(type)

    if (!fromRestore && value.throttlingOperation != ThrottlingOperation.CanAddPanel)
        return

    var newPanel = this.createPanel(type);

    var skipLock = type === PanelNames.ReportsPanel || type === PanelNames.OrderEntry
        || type === PanelNames.AdvancedOrderEntry || type === PanelNames.RiskCalculator || type === PanelNames.AccountDetailsPanel || type === PanelNames.SymbolInfoPanel;

    if (WorkSpaceManager.currentWorkspace.locked && !skipLock && !fromRestore)
    {
        WorkSpaceManager.processUndock()
        return;
    }

    this.dockSystemOperations(newPanel, fromRestore);

    this.addPanelToWs(newPanel);

    if (afterCreationCallback)
        afterCreationCallback(newPanel);

    return newPanel;
};

_Factory.prototype.createPanel = function (type)
{
    var newPanel;
    switch (type)
    {
        case PanelNames.PositionsPanel:
            newPanel = new PositionsPanel();
            break;

        case PanelNames.OrdersPanel:
            newPanel = new OrdersPanel();
            break;

        case PanelNames.EventsPanel:
            newPanel = new EventsPanel();
            break;

        case PanelNames.ApplicationPanel:
            //и кому нужна базовая таблица?
            break;

        case PanelNames.OrderHistoryPanel:
            newPanel = new OrderHistoryPanel();
            break;

        case PanelNames.FilledOrdersPanel:
            newPanel = new FilledOrdersPanel();
            break;

        case PanelNames.AssetBalancesPanel:
            newPanel = new AssetBalancesPanel();
            break;

        case PanelNames.InformerPanel:
            newPanel = new InformerPanel();
            break;

        case PanelNames.MarketConsensusPanel:
            newPanel = new MarketConsensusPanel();
            break;

        case PanelNames.GainersLosersPanel:
            newPanel = new GainersLosersPanel();
            break;

        case PanelNames.HighLowPanel:
            newPanel = new HighLowPanel();
            break;

        // case PanelNames.OrderEntry:
        //     newPanel = new OrderEntryPanel();
        //     break;

        case PanelNames.AlertPanel:
            newPanel = new AlertPanel();
            break;

        case PanelNames.CreateAlertPanel:
            newPanel = new CreateAlertPanel();
            break;

        case PanelNames.AdvancedOrderEntry:
            newPanel = new AdvancedOrderEntry();
            break;

        case PanelNames.RiskCalculator:
            newPanel = new RiskCalculator();
            break;

        case PanelNames.TSPanel:
            newPanel = new TSPanel();
            break;

        case PanelNames.ReportsPanel:
            newPanel = new ReportsPanel();
            break;

        case PanelNames.ProductsPanel:
            newPanel = new ProductsPanel();
            break;

        case PanelNames.AccountDetailsPanel:
            newPanel = new AccountDetailsPanel();
            break;

        case PanelNames.SymbolInfoPanel:
            newPanel = new SymbolInfoPanel();
            break;

        case PanelNames.MarginOEControl:
            newPanel = new MarginOEControl();
            break;

        case PanelNames.MarketDepthPanel:
            newPanel = new MarketDepthPanel();
            break;

        case PanelNames.ChartPanel:
            newPanel = new ChartPanel();
            break;

        case PanelNames.TradingViewChartPanel:
            newPanel = new TradingViewChartPanel();
            break;

        case PanelNames.GridPanel:
            newPanel = new GridPanel();
            break;

        case PanelNames.FullLogsPanel:
            newPanel = new FullLogsPanel();
            break;

        case PanelNames.MultiTimeSalesPanel:
            newPanel = new MultiTimeSalesPanel();
            break;

        case PanelNames.InstrumentHolidaysScreen:
            newPanel = new InstrumentHolidaysScreen();
            break;

        case PanelNames.SavedOrdersPanel:
            newPanel = new SavedOrdersPanel();
            break;

        case PanelNames.StatementPanel:
            newPanel = new StatementPanel();
            break;

        case PanelNames.TradingCentral:
            newPanel = new TradingCentral();
            break;

        case PanelNames.IdeasPanel:
            newPanel = new IdeasPanel();
            break;

        case PanelNames.NotificationsPanel:
            newPanel = new NotificationsPanel();
            break;

        case PanelNames.VideoWidget:
            newPanel = new VideoWidget();
            break;

        case PanelNames.ExternalScreen:
            newPanel = new ExternalScreen();
            break;

        case PanelNames.AssetsPanel:
            newPanel = new AssetsPanel();
            break;

        case PanelNames.PositionsBalancePanel:
            newPanel = new PositionsBalancePanel();
            break;

        case PanelNames.NewsPanel:
            newPanel = new NewsPanel();
            break;

        case PanelNames.ClosePositionsMutually:
            newPanel = new ClosePositionsMutually();
            break;

        case PanelNames.HeatmapPanel:
            newPanel = new HeatmapPanel();
            break;

        case PanelNames.OptionMasterPanel:
            newPanel = new OptionMasterPanel();
            break;

        default:
            {
                //мы это не создаём пока или type не указан
                break;
            }
    }

    return newPanel;
};

_Factory.prototype.dockSystemOperations = function (newPanel, fromRestore)
{
    newPanel.CustomPreparation();

    let dockPlace = this.getDefaultPlace(newPanel.getType())
    if (!fromRestore || !newPanel.get('dockablePanel'))
        MainWindowManager.MainWindow.addControl(newPanel);
    else
    {
        DockSystemInstance.goInProcces(newPanel, dockPlace);
        newPanel.on('complete', function ()
        {
            if (this.get('workSpaceId') !== null)
                DockSystemInstance.setPlace(this.get('workSpaceId'), dockPlace);
        })
    }

    if (!fromRestore && newPanel.get('dockablePanel'))
    {

        DockSystemInstance.addPanelOnDock(
            newPanel,
            dockPlace,
            WorkSpaceManager.currentWorkspace.locked);
    }
};

_Factory.prototype.addPanelToWs = function (newPanel)
{
    // save
    WorkSpaceManager.currentWorkspace.addPanel(newPanel);
};


_Factory.prototype.addScreen = function (type)
{
    let newControl = null
    switch (type)
    {
        // case ScreensNames.ModifyScreen:
        //     newControl = new TerceraModifyScreen()
        //     break
        case ScreensNames.ClosePositionScreen:
            newControl = new TerceraClosePositionScreen()
            break
        case ScreensNames.ModifyPositionScreen:
            newControl = new ModifyPositionScreen()
            break
        case ScreensNames.ModifyOrderScreen:
            newControl = new ModifyOrderScreen()
            break
        case ScreensNames.EditPositionScreen:
            newControl = new EditPositionScreen()
            break
        case ScreensNames.PositionExerciseRequestScreen:
            newControl = new PositionExerciseRequestScreen()
            break
    }

    if (type !== ScreensNames.ModifyPositionScreen && type !== ScreensNames.ClosePositionScreen)    // this screens are attached to EditPositionScreen 
        MainWindowManager.MainWindow.addControl(newControl)

    return newControl
}

_Factory.prototype.getDefaultPlace = function (type)
{
    return _Factory.PanelPlaces[type];
};

// тут указываем кто куда по умолчанию дочится
_Factory.PanelPlacesMask = [
    {
        place: DockSystem.DockPlaceConst.Middle,
        panels: [
            PanelNames.ChartPanel,
            PanelNames.TradingViewChartPanel,
            PanelNames.TradingCentral,
            PanelNames.GridPanel,
            PanelNames.HeatmapPanel,
            PanelNames.OptionMasterPanel
        ]
    },
    {
        place: DockSystem.DockPlaceConst.Up,
        panels: [

        ]
    },
    {
        place: DockSystem.DockPlaceConst.Down,
        panels: [
            PanelNames.OrderHistoryPanel,
            PanelNames.PositionsPanel,
            PanelNames.OrdersPanel,
            PanelNames.EventsPanel,
            PanelNames.AssetBalancesPanel,
            PanelNames.FilledOrdersPanel,
            PanelNames.SavedOrdersPanel,
            PanelNames.StatementPanel,
            PanelNames.FullLogsPanel,
            PanelNames.PositionsBalancePanel,
            PanelNames.NewsPanel,
            PanelNames.AssetsPanel,
            PanelNames.ProductsPanel
        ]
    },
    {
        place: DockSystem.DockPlaceConst.Left,
        panels: [
            PanelNames.OrderEntry,
            PanelNames.AdvancedOrderEntry,
            PanelNames.RiskCalculator,
            PanelNames.ReportsPanel,
            PanelNames.AccountDetailsPanel,
            PanelNames.InformerPanel,
            PanelNames.SymbolInfoPanel,
            PanelNames.MarketConsensusPanel,
            PanelNames.GainersLosersPanel,
            PanelNames.HighLowPanel
        ]
    },
    {
        place: DockSystem.DockPlaceConst.Right,
        panels: [
            PanelNames.TSPanel,
            PanelNames.MultiTimeSalesPanel,
            PanelNames.MarketDepthPanel,
            PanelNames.ExternalScreen
        ]
    }];

_Factory.PanelPlaces = (function ()
{
    var result = {};
    var i;
    var len = _Factory.PanelPlacesMask.length;
    for (i = 0; i < len; i++)
    {
        var curPlaceObj = _Factory.PanelPlacesMask[i];
        var curPlace = curPlaceObj.place;
        var curPanels = curPlaceObj.panels;
        var j;
        var panelsLen = curPanels.length;
        for (j = 0; j < panelsLen; j++)
        {
            result[curPanels[j]] = curPlace;
        }
    }

    return result;
})();
Object.freeze(_Factory.PanelPlaces);

export let FactoryAddPanelFrom = {
    Restore: 0,
    Throttling: 1
};

// create instance


export let UpdatePackageInfo = function ()
{
    this.addedRemovedRows = [];
    this.updatedRows = [];
    this.additionalRowsData = [];
    this.additionalTableData = null;
};
//
// Static field
//
UpdatePackageInfo.ADD_ROW = 0;
UpdatePackageInfo.REMOVE_ROW = 1;

_Factory.prototype.GetThrottlingOperationValue = function (panelType, aboutRows)
{
    let result = { throttlingOperation: ThrottlingOperation.CanAddPanel, rowsNumber: -1 }, ruleStringValue
    switch (panelType)
    {
        case PanelNames.ChartPanel:
            ruleStringValue = DataCache.getRuleNumberValueForMyUser(RulesSet.VALUE_THROTLING_PANEL_LIMIT_CHART_COUNT, -1);
            break
        case PanelNames.MarketDepthPanel:
            ruleStringValue = DataCache.getRuleNumberValueForMyUser(RulesSet.VALUE_THROTLING_PANEL_LIMIT_MARKETDEPTH_COUNT, -1)
            break
        case PanelNames.TSPanel:
            ruleStringValue = DataCache.getRuleNumberValueForMyUser(RulesSet.VALUE_THROTLING_PANEL_LIMIT_TIMEANDSALES_COUNT, -1)
            break
        case PanelNames.InformerPanel:
            ruleStringValue = DataCache.getRuleNumberValueForMyUser(aboutRows ? RulesSet.VALUE_THROTLING_PANEL_LIMIT_WATCHLIST_ROWS : RulesSet.VALUE_THROTLING_PANEL_LIMIT_WATCHLIST_COUNT, -1)
            break
        case PanelNames.SavedOrdersPanel:
            ruleStringValue = DataCache.getRuleNumberValueForMyUser(aboutRows ? RulesSet.VALUE_THROTLING_PANEL_LIMIT_SAVEDORDERS_ROWS : RulesSet.VALUE_THROTLING_PANEL_LIMIT_SAVEDORDERS_COUNT, -1);
            break;
        case PanelNames.TradingViewChartPanel:
            ruleStringValue = DataCache.getRuleNumberValueForMyUser(RulesSet.VALUE_PANEL_LIMIT_CHART_TV_COUNT, -1);
            break;
        case PanelNames.GridPanel:
            ruleStringValue = DataCache.getRuleNumberValueForMyUser(RulesSet.VALUE_THROTLING_PANEL_LIMIT_GRID_COUNT, -1);
            break;
    }

    if (aboutRows)
    {
        result.throttlingOperation = ThrottlingOperation.CanAddRows
        result.rowsNumber = ruleStringValue
        ruleStringValue = -1
    }

    let panelNum = WorkSpaceManager.currentWorkspace.numberOfPanelByType(panelType)

    if (ruleStringValue != -1 && panelNum >= ruleStringValue)
    {
        result.throttlingOperation = ThrottlingOperation.Reject

        TerceraMessageBox.Show(Resources.getResource('workspace.information'), Resources.getResource('Throtling.Count.firstPart') + ' ' + Resources.getResource(PanelLocKeys[panelType]) + ' ' + Resources.getResource('Throtling.Count.secondPart'), TerceraMessageBox.msgType.Info, null, null, false, true)
    }

    return result
}
export const ThrottlingOperation =
{
    CanAddPanel: 0,
    Reject: 1,
    ForbidPanel: 2,
    CanAddRows: 3
};

Object.freeze(ThrottlingOperation);

export const Factory = new _Factory();
