// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { MAMode } from '../IndicatorConstants';
import { ExpandDoubleVector } from '../DoubleMatrix';
import { IndicatorFunctions } from '../IndicatorFunctions';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterCombobox } from '../InputParamaterClasses/InputParameterCombobox';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';
// import { iBuildInIndicator } from '../iBuildInIndicator';

export class QSTICK extends IndicatorScriptBase {
    public MAType: any;
    public MAPeriod: number;

    private ma: ExpandDoubleVector;

    constructor () {
        super();
        // #region Initialization
        this.ProjectName = 'QStick';
        this.Comments = 'Moving average that shows the difference between the prices at which an issue opens and closes';
        // #endregion

        this.SetIndicatorLine('QSTICK', Color.Blue, 1, LineStyle.SimpleChart);
        this.SetLevelLine('Zero', 0, Color.Gray, 1, LineStyle.SimpleChart);
        this.SeparateWindow = true;

        this.ma = new ExpandDoubleVector();

        this.MAType = MAMode.SMA;
        super.InputParameter(new InputParameterCombobox('MAType', 'Type of moving average', 1,
            [
                { Simple: MAMode.SMA },
                { Exponential: MAMode.EMA },
                { Modified: MAMode.SMMA },
                { 'Linear Weighted': MAMode.LWMA }
            ]));

        this.MAPeriod = 20;
        super.InputParameter(new InputParameterInteger('MAPeriod', 'Period of MA', 2, 1, 9999));
    }

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
    };

    public GetIndicatorShortName (): string {
        return 'QSTICK(' + this.MAPeriod + ')';
    };

    public Refresh (count, newThread): void {
        this.ma.Dispose();
        this.ma = new ExpandDoubleVector();
        // iBuildInIndicator.prototype.Refresh.call(this, count, newThread);
    };

    public NextBar (): void {
        super.NextBar();
        this.ma.Add(0.0);
    };

    public OnQuote (): void {
        super.OnQuote();

        if (this.CurrentData.Count <= this.MAPeriod) {
            if (this.MAType === MAMode.EMA) { this.SetValue(0, 0, this.ma[this.ma.Length - 1]); }
            return;
        }

        this.getSourceForIndicator();
        this.SetValue(0, 0, IndicatorFunctions.CallMovingFunction(this.MAType, this.ma, this.MAPeriod, 1));
    };

    public getSourceForIndicator (): void {
        this.ma[this.ma.Length - 1] = this.CurrentData.GetPrice(PriceType.Close) -
            this.CurrentData.GetPrice(PriceType.Open);
    };
}
