// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../Commons/properties/Resources.ts";
import { MainWindowManager } from "../Controls/UtilsClasses/MainWindowManager.ts";
import { contextMenuHandler } from "../Utils/AppHandlers.js";
import { ContainerControl } from "../Controls/elements/ContainerControl.js";
import { PortfolioMetricsPanelTemplate } from "../templates.js";
import { CustomizeMetricsBarPanel } from "./CustomizeMetricsBarPanel.js";
import { PortfolioRequestType } from "../Utils/Portfolio/PortfolioRequestType.ts";
import { Account } from "../Commons/cache/Account.ts";
import { AccountFeature } from '../Utils/Account/AccountFeature.ts';
import { MouseButtons } from "../Controls/UtilsClasses/ControlsUtils.ts";
import { DynProperty } from "../Commons/DynProperty.ts";
import { DataCache } from "../Commons/DataCache.ts";
import { SessionSettings } from "../Commons/SessionSettings.ts";

export let PortfolioMetricsPanel = ContainerControl.extend({
    template: PortfolioMetricsPanelTemplate,
    data: function ()
    {
        return {
            metricsData: null,
            panelHeight: 36,
            offsetOriginal: 0,
            offsetBefore: 0,
            offsetAfter: 0,
            selectedAccount: null
        };
    },

    arrayMetrics: [],
    arrayVisible: [],
    RollingState: false,
    CustomizeMetricsBar: null,
    AnimationElement: null,
    offsetBeforeStartVal: 0,
    started: false
});

PortfolioMetricsPanel.prototype.getType = function () { return 'PortfolioMetricsPanel' };

PortfolioMetricsPanel.prototype.oncomplete = function ()
{
    ContainerControl.prototype.oncomplete.call(this)

    this.arrayVisible = this.defaultArrayVisible();

    contextMenuHandler.CloseMenu.Subscribe(this.switchingRolling, this)
    SessionSettings.PortfolioPropertiesHandlers.push(this.Properties.bind(this));
    SessionSettings.PortfolioCallBacksHandlers.push(this.callBack.bind(this));

    this.AnimationElement = document.getElementsByClassName("marquee")[0];
    $(window).on('resize', () =>
    {
        this.offsetBeforeStartVal = 0;
        if (DataCache.Loaded)
            this.MouseWheel_Process(false, true)
    })

    this.observe("selectedAccount", this.updateAccount)
};

PortfolioMetricsPanel.prototype.switchingRolling = function ()
{
    let element = this.AnimationElement;
    if (this.RollingState)
        this.set('needPause', false);
    else
        this.set('needPause', true);
};

PortfolioMetricsPanel.prototype.onMouseWheel = function (context)
{
    let e = context.event;
    this.MouseWheel_Process(e && e.deltaY > 0)
};

PortfolioMetricsPanel.prototype.MouseWheel_Process = function (isUp, needRecalc)
{
    let step = needRecalc ? 0 : 10;
    let marquee = this.AnimationElement;

    if (this.offsetBeforeStartVal === 0)
        this.offsetBeforeStartVal = this.AnimationElement.children[1].getBoundingClientRect().width

    if (isUp)
    {
        let offset = this.get("offsetOriginal")
        offset += step;
        this.set({
            offsetOriginal: offset,
            offsetBefore: -this.offsetBeforeStartVal + offset,
            offsetAfter: offset,
        })

        let metrics_row_item_duplicate = marquee.lastElementChild;
        let metrics_base_item = metrics_row_item_duplicate.firstElementChild
        let metrics_row_item = marquee.children[1]
        let bounditem = metrics_row_item.getBoundingClientRect()
        let bound = metrics_row_item.lastElementChild.getBoundingClientRect()

        if (bound.right > MainWindowManager.MainWindow.width + 100)
        {
            let bound = metrics_row_item.getBoundingClientRect()
            let add = 0;
            if (marquee.getBoundingClientRect().left < 0)
                add = Math.abs(marquee.getBoundingClientRect().left)
            offset = bound.left - MainWindowManager.MainWindow.width + add + 10;
            this.set({
                offsetOriginal: offset,
                offsetBefore: -this.offsetBeforeStartVal + offset,
                offsetAfter: offset,
            })
        }
    }
    else
    {
        let offset = this.get("offsetOriginal")
        offset -= step;
        this.set({
            offsetOriginal: offset,
            offsetBefore: -this.offsetBeforeStartVal + offset,
            offsetAfter: offset,
        })

        let metrics_row_item_duplicate = marquee.lastElementChild
        let metrics_base_item = metrics_row_item_duplicate.firstElementChild
        let metrics_row_item = marquee.children[1]
        let bounditem = metrics_row_item.getBoundingClientRect()
        let bound = metrics_row_item.firstElementChild.getBoundingClientRect()

        if (bound.right < 0)
        {
            let bound = metrics_base_item.getBoundingClientRect()
            let add = 0;
            if (marquee.getBoundingClientRect().left < 0)
                add = Math.abs(marquee.getBoundingClientRect().left)
            offset = bound.left + add - 10
            this.set({
                offsetOriginal: offset,
                offsetBefore: -this.offsetBeforeStartVal + offset,
                offsetAfter: offset,
            })
        }
    }
}

PortfolioMetricsPanel.prototype.updateAccount = function (newA, oldA)
{
    if (!newA || newA === oldA)
        return;
    this.createMetrics();
};

PortfolioMetricsPanel.prototype.repopulateMetrics = function ()
{
    this.set({ metricsData: this.arrayMetrics })
};

PortfolioMetricsPanel.prototype.GetContextMenu = function ()
{
    let items = [];
    items = [
        {
            locKey: 'portfolio.metrics.TapeRolling',
            text: Resources.getResource('portfolio.metrics.TapeRolling'),
            checked: this.RollingState,
            enabled: true,
            canCheck: true,
            event: this.stateChangeRolling.bind(this)
        },
        {
            separator: true
        },
        {
            locKey: 'portfolio.metrics.contextMenu.Customize',
            text: Resources.getResource('portfolio.metrics.contextMenu.Customize'),
            event: this.createCustomizeMetricsBarPanel.bind(this)
        }
    ]

    return items;
};

PortfolioMetricsPanel.prototype.stateChangeRolling = function (state)
{
    this.RollingState = state.checked;
    this.switchingRolling();
    SessionSettings.savePortfolioSettings();
}

PortfolioMetricsPanel.prototype.onMouseUp = function (e)
{
    if (e.original && e.original.button === MouseButtons.Right)
    {
        var contextMenuItems = this.GetContextMenu();
        if (contextMenuItems !== null)
        {
            contextMenuHandler.Show(contextMenuItems, e.original.clientX, e.original.clientY);
            this.set("needPause", true);
        }
    }
};

PortfolioMetricsPanel.prototype.createCustomizeMetricsBarPanel = function (menuItem)
{
    if (this.CustomizeMetricsBar && !this.CustomizeMetricsBar.torndown)
        return

    let newPanel = new CustomizeMetricsBarPanel();
    // newPanel.setBounds(0, 0, 370, 420);
    MainWindowManager.MainWindow.addControl(newPanel);

    newPanel.set({
        metricsData: this.arrayMetrics,
        tapeRollingChecked: this.RollingState
    })

    newPanel.OkClickedCallBack = (newArrayMetrics, stateRolling) =>
    {
        this.arrayMetrics = newArrayMetrics;
        this.RollingState = stateRolling;
        this.updateArrayVisible();
        this.repopulateMetrics();
        this.MouseWheel_Process(true, true)
        SessionSettings.savePortfolioSettings();
    }

    newPanel.CloseClickedCallBack = () =>
    {
        this.switchingRolling();
    }

    this.CustomizeMetricsBar = newPanel;
    this.set("needPause", true);
};

PortfolioMetricsPanel.prototype.createMetrics = function ()
{
    if (!this.started)
        return

    var keys = Object.keys(PortfolioMetrics);
    var len = keys.length;
    let promiseArr = [];
    let acc = this.get("selectedAccount")
    for (var i = 0; i < len; i++)
    {
        let key = PortfolioMetrics[keys[i]];
        let metric = new MetricContext(key, PortfolioMetricsMap[key], this.arrayVisible[key], acc);
        promiseArr.push(metric.Populate());
    }
    Promise.all(promiseArr).then((metrics) =>
    {
        this.arrayMetrics = metrics;
        this.repopulateMetrics()
    })
};

PortfolioMetricsPanel.prototype.defaultArrayVisible = function ()
{
    let array = [
        true,   //InvestedAmount
        false,  //MaxDrawdownPercent
        true,   //TotalReturn
        false,  //BestMonth
        false,  //BestMonthPercent
        false,  //WorstMonth
        false,  //WorstMonthPercent
        false,  //ArithmeticMean
        false,  //ArithmeticMeanPercent
        true    //PortfolioBalance
    ]

    return array;
};

PortfolioMetricsPanel.prototype.updateArrayVisible = function ()
{
    let visibleArray = [];
    for (var i = 0; i < this.arrayMetrics.length; i++)
        visibleArray.push(this.arrayMetrics[i].Visible)

    this.arrayVisible = visibleArray;
};

PortfolioMetricsPanel.prototype.Properties = function ()
{
    let properties = [];
    let prop = new DynProperty("PortfolioMetricsPanel.ArrayVisible", this.arrayVisible, DynProperty.STRING, DynProperty.HIDDEN_GROUP);
    properties.push(prop);

    prop = new DynProperty("PortfolioMetricsPanel.TapeRolling", this.RollingState, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP);
    properties.push(prop);

    return properties;
};

PortfolioMetricsPanel.prototype.callBack = function (properties)
{
    let dp = DynProperty.getPropertyByName(properties, "PortfolioMetricsPanel.ArrayVisible");
    if (dp)
        this.arrayVisible = dp.value;

    dp = DynProperty.getPropertyByName(properties, "PortfolioMetricsPanel.TapeRolling");
    if (dp)
    {
        this.RollingState = dp.value;
        this.switchingRolling();
    }
};

PortfolioMetricsPanel.startWorkMetrics = function ()
{
    MainWindowManager.MainWindow.Controls.MetricsPanel.started = true;
    MainWindowManager.MainWindow.Controls.MetricsPanel.createMetrics();
    MainWindowManager.MainWindow.Controls.MetricsPanel.repopulateMetrics();
};

let PortfolioMetrics = {
    InvestedAmount: 0,  //INVESTED_AMOUNT
    MaxDrawdownPercent: 1,  //MAX_DRAWDOWN
    TotalReturn: 2, //TOTAL_RETURN
    BestMonth: 3,   //MONTHLY_STATISTICS
    BestMonthPercent: 4,//MONTHLY_STATISTICS
    WorstMonth: 5,//MONTHLY_STATISTICS
    WorstMonthPercent: 6,//MONTHLY_STATISTICS
    ArithmeticMean: 7,//MONTHLY_STATISTICS
    ArithmeticMeanPercent: 8,//MONTHLY_STATISTICS
    PortfolioBalance: 9
}

let PortfolioMetricsMap = {
    0: "Invested amount",
    1: "Max drawdown, %",
    2: "Total return",
    3: "Best month",
    4: "Best month, %",
    5: "Worst month",
    6: "Worst month, %",
    7: "Arithmetic mean (monthly)",
    8: "Arithmetic mean (monthly), %",
    9: "Portfolio balance"
}

let MetricContext = function (type, name, visible, account)
{
    this.Type = type;
    this.Name = name;
    this.Visible = visible;
    this.Value = null;
    this.Account = account || null;
}

MetricContext.prototype.Populate = function ()
{
    let pCache = DataCache.PortfolioCache;
    let AccountId = null;
    if (this.Account)
        AccountId = this.Account.AcctNumber;
    switch (this.Type)
    {
        case PortfolioMetrics.InvestedAmount:
            return pCache.SendPortfolioStatisticsRequest(AccountId, PortfolioRequestType.INVESTED_AMOUNT).then((msg) =>
            {
                let messageData = msg[0].DataArray;
                this.DataCoordination(messageData, null, false)
                return this;
            })
            break;
        case PortfolioMetrics.MaxDrawdownPercent:
            return pCache.SendPortfolioStatisticsRequest(AccountId, PortfolioRequestType.MAX_DRAWDOWN).then((msg) =>
            {
                let messageData = msg[0].DataArray;
                this.DataCoordination(messageData, null, true)

                return this;
            })
            break;
        case PortfolioMetrics.TotalReturn:
            let totalReturn = DataCache.PortfolioCache.GetTotalReturn(this.Account);
            if (totalReturn)
                this.Value = totalReturn;
            return this;
            break;
        case PortfolioMetrics.BestMonth:
            return pCache.SendPortfolioStatisticsRequest(AccountId, PortfolioRequestType.MONTHLY_STATISTICS).then((msg) =>
            {
                let messageData = msg[0].DataArray;
                this.DataCoordination(messageData, true, false)
                return this;
            })
            break;
        case PortfolioMetrics.BestMonthPercent:
            return pCache.SendPortfolioStatisticsRequest(AccountId, PortfolioRequestType.MONTHLY_STATISTICS).then((msg) =>
            {
                let messageData = msg[0].DataArray;
                this.DataCoordination(messageData, true, true)
                return this;
            })
            break;
        case PortfolioMetrics.WorstMonth:
            return pCache.SendPortfolioStatisticsRequest(AccountId, PortfolioRequestType.MONTHLY_STATISTICS).then((msg) =>
            {
                let messageData = msg[0].DataArray;
                this.DataCoordination(messageData, false, false)
                return this;
            })
            break;
        case PortfolioMetrics.WorstMonthPercent:
            return pCache.SendPortfolioStatisticsRequest(AccountId, PortfolioRequestType.MONTHLY_STATISTICS).then((msg) =>
            {
                let messageData = msg[0].DataArray;
                this.DataCoordination(messageData, false, true)
                return this;
            })
            break;
        case PortfolioMetrics.ArithmeticMean:
            return pCache.SendPortfolioStatisticsRequest(AccountId, PortfolioRequestType.MONTHLY_STATISTICS).then((msg) =>
            {
                let messageData = msg[0].DataArray;
                this.DataCoordination(messageData, null, false)
                return this;
            })
            break;
        case PortfolioMetrics.ArithmeticMeanPercent:
            return pCache.SendPortfolioStatisticsRequest(AccountId, PortfolioRequestType.MONTHLY_STATISTICS).then((msg) =>
            {
                let messageData = msg[0].DataArray;
                this.DataCoordination(messageData, null, true)
                return this;
            })
            break;
        case PortfolioMetrics.PortfolioBalance:
            if (this.Account)
            {
                let asset = this.Account.GetAssetBalanceCorrect();
                let value = Account.GetAccountFeature(AccountFeature.ProjectedBalance, this.Account, asset)
                this.Value = asset.formatPrice(value, true);
            }
            return this;
            break;

        default:
            return this;
    }
}

MetricContext.prototype.DataCoordination = function (dataArray, isBest, percent)
{
    for (let i = 0; i < dataArray.length; i++)
    {
        let data = dataArray[i];
        if (data && data.IsBest === isBest)
        {
            if (percent)
                this.Value = data.Percent;
            else
                this.Value = data.AbsoluteAmount;
        }
    }
}