// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DirectMamGroupMessage, MamSubAccount } from '../../../../Utils/DirectMessages/DirectMamGroupMessage';
import { FieldsFactory } from '../Factory/FieldsFactory';
import { type PFixFieldSet } from '../Fields/FieldsImport';
import { MessageProcessorBase } from './MessageProcessorBase';

export class MamGroupProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): DirectMamGroupMessage[] {
        const factory = FieldsFactory;

        const message = new DirectMamGroupMessage();
        // User PIN
        message.mamGroupID = fieldSet.GetValue(factory.FIELD_ID)?.toString();
        message.userID = fieldSet.GetValue(factory.FIELD_USER_ID)?.toString();
        message.assetID = fieldSet.GetValue(factory.FIELD_ASSET_ID);
        message.counterAccountID = fieldSet.GetValue(factory.FIELD_COUNTER_ACCOUNT_ID)?.toString();
        message.activeateMamFee = fieldSet.GetValue(factory.FIELD_ACTIVATE_MAM_FEE);

        const groups = fieldSet.GetGroups(FieldsFactory.MAM_SUBACCOUNT_GROUP);
        for (const group of groups) {
            const subA = new MamSubAccount();
            subA.accountNumb = group.GetValue(factory.FIELD_ACCOUNT_ID)?.toString();
            subA.EMamManagementFeeType = group.GetValue(factory.FIELD_MAM_ACCOUNT_MANAGEMENT_FEE_TYPE);
            subA.managementFee = group.GetValue(factory.FIELD_MAM_ACCOUNT_MANAGEMENT_FEE);
            subA.performanceFee = group.GetValue(factory.FIELD_MAM_ACCOUNT_PERFORMANCE_FEE);
            subA.feeAssetID = message.assetID;
            message.subAccounts.push(subA);
        }

        return [message];
    }
}
