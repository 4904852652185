import { PartialInterval } from './PartialInterval';
import { Decimal } from 'decimal.js';

export class _PartialIntervalCalculator {
    public recalculate (minColor: string, midColor: string, maxColor: string, stepMultiplier: number): PartialInterval[] {
        const intervalCount = 7;
        const maxValue = new Decimal(2.5).mul(stepMultiplier).toNumber();
        const minValue = new Decimal(-2.5).mul(stepMultiplier).toNumber();

        const intervalLength = stepMultiplier;

        let rightBorder = minValue;

        const list: PartialInterval[] = [];
        list.push(new PartialInterval(Number.NEGATIVE_INFINITY, rightBorder));
        for (let i = 1; i < intervalCount - 1; i++) {
            const leftBorder = rightBorder;
            rightBorder = new Decimal(leftBorder).add(intervalLength).toNumber();
            list.push(new PartialInterval(leftBorder, rightBorder));
        }
        list.push(new PartialInterval(maxValue, Number.POSITIVE_INFINITY));
        this.populateColors(list, minColor, midColor, maxColor);
        return list;
    }

    public populateColors (partialIntervals: PartialInterval[], minColor: string, midColor: string, maxColor: string): void {
        const intervalCount = partialIntervals.length;

        if (intervalCount === 0 || intervalCount % 2 === 0) {
            return;
        }

        const sideIntervalCount = intervalCount / 2.0;
        const sideIntervalCountInt = Math.round(sideIntervalCount);

        // min - mid
        const minColorRGB = this.parseColorToRgb(minColor);
        const midColorRGB = this.parseColorToRgb(midColor);

        const { r: rMin, g: gMin, b: bMin } = minColorRGB;
        const { r: rMid, g: gMid, b: bMid } = midColorRGB;

        let rLength = (rMid - rMin) / sideIntervalCount;
        let gLength = (gMid - gMin) / sideIntervalCount;
        let bLength = (bMid - bMin) / sideIntervalCount;

        for (let i = 1, multiplier = 1; i < sideIntervalCountInt; i++, multiplier++) {
            const r = Math.round(rMin + rLength * multiplier);
            const g = Math.round(gMin + gLength * multiplier);
            const b = Math.round(bMin + bLength * multiplier);
            partialIntervals[i].LeftColor = this.rgbToHex(r, g, b);
        }

        // mid - max
        const maxColorRGB = this.parseColorToRgb(maxColor);

        const { r: rMax, g: gMax, b: bMax } = maxColorRGB;

        rLength = (rMax - rMid) / sideIntervalCount;
        gLength = (gMax - gMid) / sideIntervalCount;
        bLength = (bMax - bMid) / sideIntervalCount;

        for (let i = sideIntervalCountInt, multiplier = 1; i < intervalCount; i++, multiplier++) {
            const r = Math.round(rMid + rLength * multiplier);
            const g = Math.round(gMid + gLength * multiplier);
            const b = Math.round(bMid + bLength * multiplier);
            partialIntervals[i].LeftColor = this.rgbToHex(r, g, b);
        }

        partialIntervals[0].LeftColor = minColor;
        partialIntervals[sideIntervalCountInt - 1].LeftColor = midColor;
        partialIntervals[intervalCount - 1].LeftColor = maxColor;
    }

    private readonly parseColorToRgb = (colorStr: string): { r: number, g: number, b: number } => {
        if (colorStr.startsWith('#')) {
            const hex = colorStr.substring(1);
            const bigint = parseInt(hex, 16);
            const r = (bigint >> 16) & 255;
            const g = (bigint >> 8) & 255;
            const b = bigint & 255;
            return { r, g, b };
        } else {
            const rgb = colorStr.match(/\d+/g);
            return rgb ? { r: parseInt(rgb[0]), g: parseInt(rgb[1]), b: parseInt(rgb[2]) } : { r: 0, g: 0, b: 0 };
        }
    };

    private readonly rgbToHex = (r: number, g: number, b: number): string => {
        return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    };
}

export const PartialIntervalCalculator = new _PartialIntervalCalculator();
