// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
'use strict'

import { CustomErrorClass, ErrorInformationStorage } from "../../Commons/ErrorInformationStorage.ts";
import { Resources } from "../../Commons/properties/Resources.ts";
import { ModifyOrderScreenTemplate, OrderEditControlsTemplate,  ModifyOrderScreenFooterTemplate } from "../../templates.js";
import { Control } from "../elements/Control.js";
import { ScreensNames } from "../UtilsClasses/FactoryConstants.ts";
import { OrderEditViewBase } from "../panels/OrderEditViewBase.js";
import { TIF } from "../../Utils/Trading/OrderTif.ts";
import { Quantity } from "../../Utils/Trading/Quantity.ts";
import { modifyOrderScreenHandler } from "../../Utils/AppHandlers.js";
import { OrderEditUpdateData } from "../../Utils/Trading/OrderEditUpdateData.ts";
import { DataCache } from "../../Commons/DataCache.ts";
import { SessionSettings } from "../../Commons/SessionSettings.ts";
import { TradingNumericErrorChecker } from "../../Commons/Trading/TradingNumericErrorChecker.ts";
import { MainWindowManager } from "../UtilsClasses/MainWindowManager.ts";

// TODO. Refactor. Duplicate code of ModifyPositionScreen.
export let ModifyOrderScreen = OrderEditViewBase.extend({
    data: function ()
    {
        return {
            resizable: false,
            width: 340,
            dockablePanel: false,
            showHeader: true,
            showFooter: true,
            header: '',
            order: null,
            tif: null,
            // TODO. Remove. Ugly.
            defaultTif: null,
            quantity: null,
            // TODO. Remove. Ugly.
            defaultQuantity: null,
            instrument: null,
            account: null,
            side: null,
            orderType: null,

            disclosedQuantityShow: false,
            disclosedQuantity: null,
            dqMinValue: null,
            dqMaxValue: null,
            dqTooltip: 'panel.newOrderEntry.disclosedLabel.tt',
            dqLessMinLocalKey: 'UserControl.TerceraDQNumeric.ValueLessMin',
            dqGreaterMaxLocalKey: 'UserControl.TerceraDQNumeric.ValueGreaterMax',
            style_addition_footer: 'modify-order-screen-footer',

            productType: null,
            disableProductType: null,
            SLNumericLink: null,
            TPNumericLink: null,
            PriceNumericLink: null,
            StopPriceNumericLink: null,
            QNumericLink: null,
            // showFullscreenCloud: true //TODO 83829
        }
    },
    partials: {
        // TODO. Refactor.
        bodyPartial: ModifyOrderScreenTemplate,
        orderEditControls: OrderEditControlsTemplate,
        footerPartial: ModifyOrderScreenFooterTemplate
    }
})

ModifyOrderScreen.prototype.getType = function ()
{
    return ScreensNames.ModifyOrderScreen
}

ModifyOrderScreen.prototype.oninit = function ()
{
    OrderEditViewBase.prototype.oninit.call(this)

    this.on('modifyBtnClick', this.modify);
    this.on('cancelBtnClick', this.close);
    this.on('onBreakevenClick', this.setBreakevenPrice);

    this.observe('order', this.onOrder)
    this.observe('tradingAllowed', this.onTradingAllowed)
    this.observe('quantity tif disclosedQuantity', this.onTradingDataChanged)
    // TODO. Refactor. 
    // TickAsync/Localize doesn't work for panels added with addScreen method.
    Resources.onLocaleChange.Subscribe(this.localize, this)
    Control.Ticker.Subscribe(this.TickAsync, this)
}

// TODO. Refactor.
ModifyOrderScreen.prototype.oncomplete = function ()
{
    OrderEditViewBase.prototype.oncomplete.call(this)

    this.onTradingAllowed(this.get('tradingAllowed'))
    this.set("QNumericLink", this.Controls.quantity);
    this.localize()
    this.center()
}

ModifyOrderScreen.prototype.dispose = function ()
{
    Resources.onLocaleChange.UnSubscribe(this.localize, this)
    Control.Ticker.UnSubscribe(this.TickAsync, this)

    OrderEditViewBase.prototype.dispose.call(this)
}

ModifyOrderScreen.prototype.localize = function ()
{
    OrderEditViewBase.prototype.localize.call(this);

    this.updatePanelHeader()
    this.set({
        disclosedLabel: Resources.getResource('panel.newOrderEntry.disclosedLabel'),
        modifyBtnText: Resources.getResource('screen.modifyOrder.modify'),
        cancelBtnText: Resources.getResource('general.messageBox.cancel')
    });
}

// TODO. UGLY.
ModifyOrderScreen.prototype.onOrder = function (order)
{
    if (!order)
        return

    let orderTypeDict = DataCache.OrderParameterContainer.OrderTypes
    let orderTypeObj = orderTypeDict[order.OrderType]

    let sess = SessionSettings

    this.setOrderEdit(orderTypeObj.createModifyOrderObject({
        dataCache: DataCache,
        order: order
    }))

    this.set({ account: order.Account, instrument: order.Instrument }).then(function ()
    {
        // TEST.
        this.set({
            side: order.BuySell,
            orderType: orderTypeObj,
            productType: order.ProductType,
            // TODO. Remove. Ugly.
            defaultTif: new TIF(order.TimeInForce, new Date(order.ExpireAt)),
            // TODO. Remove. Ugly.
            defaultQuantity: new Quantity(order.Amount, true)
        })
    }.bind(this))

    this.updatePanelHeader()
}

ModifyOrderScreen.prototype.onTradingDataChanged = function (newVal, oldVal, key)
{
    let orderEdit = this.orderEdit
    if (!orderEdit) return

    let tradingDataDict = {}
    tradingDataDict[key] = newVal

    this.addDisclosedQuantityIfNeed(key)

    orderEdit.updateParameters(new OrderEditUpdateData(
        null,
        tradingDataDict))
}

ModifyOrderScreen.prototype.onTradingAllowed = function (allowed)
{
    this.set("enabledBtn", allowed);
}

ModifyOrderScreen.prototype.setBreakevenPrice = function (context, price)
{
    let orderEdit = this.orderEdit
    if (orderEdit)
        orderEdit.setBreakevenPrice(price)
}

ModifyOrderScreen.prototype.updatePanelHeader = function ()
{
    let ord = this.get('order')
    if (!ord) return

    this.set('header',
        Resources.getResource('screen.modifyOrder.Change order') +
        ' (' +
        ord.OrderNumber +
        ')')
}

ModifyOrderScreen.prototype.layout = function ()
{
    this.updateOrderEditParametersVisibility();
}

ModifyOrderScreen.prototype.modify = function ()
{
    if (TradingNumericErrorChecker.HasErrors(this))
        return

    this.orderEdit.placedFrom = this.get('placedFrom')

    let self = this
    DataCache.FOrderExecutor
        .modifyOrderPromise(this.orderEdit, self.close.bind(self), self.focusWarningNumeric.bind(self))
        .then(function () { })
        .catch(function () 
        {
            let ex = new CustomErrorClass("ModifyOrderScreen error", "ModifyOrderScreen.modify", "modify -> modifyOrderPromise");
            ErrorInformationStorage.GetException(ex);
        })
}

ModifyOrderScreen.getScreenByOrderID = function (orderID)
{
    let keys = Object.keys(MainWindowManager.MainWindow.Controls),
        ModifyOrderScreenType = ScreensNames.ModifyOrderScreen

    for (let i = keys.length - 1; i >= 0; i--)
    {
        let control = MainWindowManager.MainWindow.Controls[keys[i]]

        if (control.getType() == ModifyOrderScreenType)
        {
            let order = control.get('order')

            if (order && order.OrderNumber == orderID)
                return control
        }
    }

    return null
}

ModifyOrderScreen.show = function (orderId, placedFrom, numericLinkName)
{
    let alreadyOpenedScreen = ModifyOrderScreen.getScreenByOrderID(orderId),
        oldLocation = null, notSkipNextTime = false

    if (alreadyOpenedScreen)
    {
        oldLocation = { x: alreadyOpenedScreen.get('left'), y: alreadyOpenedScreen.get('top') }
        notSkipNextTime = alreadyOpenedScreen.NotSkipNextTime;
        alreadyOpenedScreen.close()
    }

    let screen = MainWindowManager.Factory.addScreen(ScreensNames.ModifyOrderScreen)
    screen.set({
        order: DataCache.getOrderById(orderId),
        placedFrom: placedFrom
    })

    if (numericLinkName)
        screen.activateNumeric(numericLinkName)

    if (oldLocation)
        screen.setLocation(oldLocation.x, oldLocation.y)

    screen.NotSkipNextTime = notSkipNextTime        // TODO костыль ебаный
    screen.set('disableProductType', true)
}

let handl = modifyOrderScreenHandler;
handl.show = ModifyOrderScreen.show;