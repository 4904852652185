// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from "../Commons/properties/Resources.ts";
import { ThemeManager } from "../Controls/misc/ThemeManager.ts";
import { ColouringModes } from "../Controls/elements/QuickTable/QuickTableColumn.ts";
import { PortfolioSummaryItem } from "./PortfolioSummaryItem.js";
import { MainWindowManager } from "../Controls/UtilsClasses/MainWindowManager.ts";
import { Control } from "../Controls/elements/Control.js";
import { QuickTable, GridVisibilityType } from "../Controls/elements/QuickTable/QuickTable.ts";
import { ApplicationPanelNew } from "../Controls/panels/ApplicationPanelNew.js";
import { ExportScreen } from "../Controls/screen/ExportScreen.js";
import { PanelNames } from "../Controls/UtilsClasses/FactoryConstants.ts";
import { PortfolioRequestType } from "../Utils/Portfolio/PortfolioRequestType.ts";
import { DynProperty, PairColor } from "../Commons/DynProperty.ts";
import { GeneralSettings } from "../Utils/GeneralSettings/GeneralSettings.ts";
import { DataCache } from "../Commons/DataCache.ts";
import { SessionSettings } from "../Commons/SessionSettings.ts";
import { PanelSettingsScreen } from "../Controls/screen/PanelSettingsScreen.js";

export let PortfolioSummary = ApplicationPanelNew.extend(
    {
        data: function ()
        {
            return {
                showHeader: true,
                showFooter: false,
                showBorder: false,
                movable: false,
                account: null,
                closeBtnVisible: false,
                style_addition_header: "portfolio-summary-panel-AdditionalHeader"
            }
        },
        headerLocaleKey: 'panel.portfolioSummary',
        NeedCalculateRowCount: false,
        displayAmountInLots: null,
        gridVisibilityType: null,       // видимость рамок таблицы
        columnBackColor: {},            // фоновый цвет ячейки
        columnTextColor: {},            // цвет текста ячейки
        coloringModes: {},
        increaseColors: {},
        decreaseColors: {},
        showTotalsByColumn: {},
        sparkLinePointNum: 25,          // количество точек для рисования sparkLine
        showLogos: true,                // видимость логотипов инстурментов
        showTotalsState: false,
        noDataLabel: null
    });

PortfolioSummary.prototype.getType = function () { return PanelNames.PortfolioSummary };

PortfolioSummary.prototype.oninit = function ()
{
    ApplicationPanelNew.prototype.oninit.apply(this);

    DataCache.OnReintialize.Subscribe(this.SubscribeEvents, this);
    this.observe('account', this.updateDailyReturns)
};

PortfolioSummary.prototype.oncomplete = function ()
{
    ApplicationPanelNew.prototype.oncomplete.apply(this);

    SessionSettings.PortfolioPropertiesHandlers.push(this.Properties.bind(this));
    SessionSettings.PortfolioCallBacksHandlers.push(this.callBack.bind(this));

    this.localize()
    this.populateTableContextMenu()
    this.setPanelSize()
};

PortfolioSummary.prototype.updateDailyReturns = function ()
{
    let acc = this.get('account')
    if (!acc) return

    let me = this,
        pCache = DataCache.PortfolioCache,
        insIDs = pCache.allInstrumentUsedInPortfolio,
        accID = acc.AcctNumber

    // for (let i = 0; i < insIDs.length; i++)
    // {
    //     let a = insIDs[i].split(':'),
    //         trId = a.length ? a[0] : null,
    //         route = a.length > 1 ? a[1] : null
    pCache.SendPortfolioStatisticsRequest(accID, PortfolioRequestType.DAILY_INSTRUMENT_RETURN)//, null, null, route, trId)
    // }
    pCache.SendPortfolioStatisticsRequest(accID, PortfolioRequestType.DAILY_CASH_RETURN)
}

PortfolioSummary.prototype.addRows = function ()   
{
    this.repopulate()

    let qt = this.getQuickTable()
    if (!qt) return

    let dCache = DataCache,
        pCache = dCache.PortfolioCache

    let interiorIDs = [].concat(pCache.allInstrumentUsedInPortfolio)
    let acc = this.get('account') || dCache.getPrimaryAccount()
    for (let id of interiorIDs)
    {
        let ins = dCache.getInstrument(id),
            color = pCache.GetColorByInteriorID(id),
            dailyInstrumentReturn = pCache.dailyInstrumentReturn[id]

        if (this.HasPositionsByIns(ins))    // #106250
            qt.AddItem(new PortfolioSummaryItem(ins, acc, SessionSettings, dailyInstrumentReturn, color, this.GetPanelSettingsForItem()));
    }
};

PortfolioSummary.prototype.HasPositionsByIns = function (instrument)
{
    var positions = DataCache.getPositionsByInstrument(instrument)
    for (let posID in positions)
        return posID

    return null
}

PortfolioSummary.prototype.layoutTable = function ()
{
    if (this.quickTableRactive === null)
        return;

    var margin = this.margin;
    var topMargin = this.topPanelHeight + margin;
    var scrWidth = this.get('width');

    var scrHeight = this.ContentContainerHeight();

    this.quickTableRactive.setBounds(
        margin,
        topMargin,
        Math.abs(scrWidth - 2 * margin),
        Math.abs(scrHeight - margin - topMargin - 20));
}

PortfolioSummary.prototype.SubscribeEvents = function ()
{
    // this.getQuickTable().OnSelectionChanged.UnSubscribe(this.selectionChange, this);

    $(window).on('resize', this.setPanelSize.bind(this));

    Control.Ticker.Subscribe(this.TickAsync, this)
    DataCache.PortfolioCache.OnReturnsUpdate.Subscribe(this.addRows, this)
    GeneralSettings.SettingsChanged.Subscribe(this.settingsChanged, this)

    if (!MainWindowManager.MainWindow.Controls.PortfolioSummary)
        MainWindowManager.MainWindow.Controls.PortfolioSummary = this
};

PortfolioSummary.prototype.dispose = function ()
{
    // this.getQuickTable().OnSelectionChanged.UnSubscribe(this.selectionChange, this);

    Control.Ticker.UnSubscribe(this.TickAsync, this)
    DataCache.PortfolioCache.OnReturnsUpdate.UnSubscribe(this.addRows, this)
    GeneralSettings.SettingsChanged.UnSubscribe(this.settingsChanged, this)

    // ApplicationPanelNew.prototype.dispose.apply(this);
};


PortfolioSummary.prototype.setPanelSize = function ()
{
    let rect = document.getElementsByClassName("block-summary-part")[0].getClientRects();
    if (!rect.length)
        return

    let width = rect[0].width;
    let height = rect[0].height - 5;    //5px нижний отступ

    this.setSize(width, height)
}

PortfolioSummary.prototype.populateTableContextMenu = function ()
{
    var items = [];

    if (!Resources.isHidden('screen.export.visibility'))
        items.push(
            {
                text: Resources.getResource('screen.export.contextMenu'),
                event: ExportScreen.show.bind(null, this)
            }
        );

    if (!Resources.isHidden('panel.statement.print'))
        items.push(
            {
                text: Resources.getResource('screen.reports.print'),
                event: this.printTable.bind(this)
            }
        );

    items.push({ separator: true });

    items.push(
        {
            text: Resources.getResource('panel.portfolioSummary.Settings'),
            tag: 'Settings',
            enabled: true,
            event: this.ShowSettings.bind(this)
        }
    );

    this.getQuickTable().setTableContextMenuItems(items);
};

PortfolioSummary.prototype.settingsChanged = function ()
{
    let newSettingsInLots = GeneralSettings.View.displayAmountInLots()
    if (this.displayAmountInLots == newSettingsInLots)
        return

    this.displayAmountInLots = newSettingsInLots
}

PortfolioSummary.prototype.GetPanelSettingsForItem = function ()
{
    let panelSettings = {
        columnTextColor: this.columnTextColor,
        columnBackColor: this.columnBackColor,
        showTotalsByColumn: this.showTotalsByColumn,
        sparkLinePointNum: this.sparkLinePointNum,
        showLogos: this.showLogos
    }

    return panelSettings
}

PortfolioSummary.prototype.UpdateGridVisibility = function (gridVisibilityType)
{
    let qt = this.getQuickTable()
    if (!qt) return

    // commented out due to the need for a refactored grid in QuickTable
    // let gridTypes = GridVisibilityType,
    //     gridHorizontalVisible = gridVisibilityType == gridTypes.Horizontal || gridVisibilityType == gridTypes.Both,
    //     gridVerticalVisible = gridVisibilityType == gridTypes.Vertical || gridVisibilityType == gridTypes.Both

    // qt.gridHorizontalVisible = gridHorizontalVisible
    // qt.gridVerticalVisible = gridVerticalVisible

    this.gridVisibilityType = gridVisibilityType
}

PortfolioSummary.prototype.ShowSettings = function ()
{
    PanelSettingsScreen.EditProperties(this, null, Resources.getResource('panel.portfolioSummary.Settings'));
}

PortfolioSummary.prototype.callBack = function (properties)
{
    let acc, p, qt = this.getQuickTable()
    if (!qt) return

    p = DynProperty.getPropertyByName(properties, "panel.portfolioSummary.Settings.General.View.Grid");
    if (p)
        this.UpdateGridVisibility(p.value)

    p = DynProperty.getPropertyByName(properties, "panel.portfolioSummary.Settings.General.View.AutoSizeColumns");
    if (p)
        this.AutoSizeColumns(p.value)

    p = DynProperty.getPropertyByName(properties, "panel.portfolioSummary.Settings.General.Colors.Row");
    if (p)
    {
        let pair = p.value
        qt.ForeColor = pair.Color1;
        qt.rowBackColor = pair.Color2;
    }

    p = DynProperty.getPropertyByName(properties, "panel.portfolioSummary.Settings.General.Colors.AlternativeRow");
    if (p)
    {
        let pair = p.value
        qt.alternatingRowForeColor = pair.Color1;
        qt.alternatingRowBackColor = pair.Color2;
    }

    p = DynProperty.getPropertyByName(properties, "panel.portfolioSummary.Settings.General.Colors.SelectedRow");
    if (p)
    {
        let pair = p.value
        qt.selectedForeColor = pair.Color1;
        qt.selectedBackColor = pair.Color2;
    }

    p = DynProperty.getPropertyByName(properties, "panel.portfolioSummary.Settings.General.Colors.HeaderRow");
    if (p)
    {
        let pair = p.value
        qt.headerForeColor = pair.Color1;
        qt.headerBackColor = pair.Color2;
    }

    p = DynProperty.getPropertyByName(properties, "panel.portfolioSummary.Settings.General.Colors.TotalRow");
    if (p)
    {
        let pair = p.value
        qt.totalsForeColor = pair.Color1;
        qt.totalsBackColor = pair.Color2;
    }

    p = DynProperty.getPropertyByName(properties, 'Number of points');
    if (p)
        this.sparkLinePointNum = p.value

    p = DynProperty.getPropertyByName(properties, 'Show logos');
    if (p)
        this.showLogos = p.value

    for (let i = 0; i < PortfolioSummaryItem.columnsParams.length; i++)
    {
        p = DynProperty.getPropertyByName(properties, 'SummaryColorsForColumn' + i);
        let pair = p ? p.value : null
        if (pair)
        {
            if (pair.Color1)
                this.columnTextColor[i] = pair.Color1 != 'transparent' ? pair.Color1 : null;
            if (pair.Color2)
                this.columnBackColor[i] = pair.Color2 != 'transparent' ? pair.Color2 : null;
        }

        p = DynProperty.getPropertyByName(properties, 'SummaryShowTotalForColumn' + i);
        if (p)
            this.showTotalsByColumn[i] = p.value

        p = DynProperty.getPropertyByName(properties, "Coloring mode" + i);
        let increaseProp = DynProperty.getPropertyByName(properties, "Increase color" + i),
            decreaseProp = DynProperty.getPropertyByName(properties, "Decrease color" + i),
            coloringMode = null, increaseColor = null, decreaseColor = null;

        if (increaseProp)
        {
            increaseColor = increaseProp.value
            this.increaseColors[i] = increaseColor
        }
        if (decreaseProp)
        {
            decreaseColor = decreaseProp.value
            this.decreaseColors[i] = decreaseColor
        }
        if (p)
        {
            coloringMode = this.coloringModes[i] = p.value
            this.ProcessSetColumnsColouringMode(qt, [i], coloringMode, increaseColor, decreaseColor);
        }
    }

    this.addRows(null, properties)

    p = DynProperty.getPropertyByName(properties, "PortfolioPerformance.AcctNumber");
    if (p)
        acc = DataCache.Accounts[p.value]
    if (acc)
        this.set('selectedAccount', acc)

    p = DynProperty.getPropertyByName(properties, "PortfolioPerformance.isPortfolioAllocationSelected");
    if (p) this.set('isPortfolioAllocationSelected', p.value)

    p = DynProperty.getPropertyByName(properties, "panel.portfolioSummary.Settings.General.View.ShowTotals");
    if (p) this.showTotalsState = p.value
    this.UpdateShowTotalsStateChange(this.showTotalsState)
}

PortfolioSummary.prototype.Properties = function ()
{
    let qt = this.getQuickTable()
    if (!qt) return

    var properties = []

    let GeneralViewSeparatorGroup = '#1#' + Resources.getResource('property.ViewSeparatorGroup'),
        GeneralColorsSeparatorGroup = '#2#' + Resources.getResource('panel.portfolioSummary.Settings.ColorsSeparatorGroup'),
        SeparatorGroup2 = "#2#" + Resources.getResource("property.SeparatorGroup.Show")

    // General -> View

    let prop = new DynProperty("panel.portfolioSummary.Settings.General.View.Grid", this.gridVisibilityType || GridVisibilityType.Vertical, DynProperty.COMBOBOX_COMBOITEM, DynProperty.PARAM_GROUP);
    prop.separatorGroup = GeneralViewSeparatorGroup;
    prop.objectVariants = PortfolioSummary.GetGridVisibilityVariants()
    prop.COMBOBOX_TYPE = DynProperty.INTEGER;
    prop.sortIndex = 1;
    properties.push(prop);

    prop = new DynProperty("panel.portfolioSummary.Settings.General.View.AutoSizeColumns", this.AutoSizeColumns(), DynProperty.BOOLEAN, DynProperty.PARAM_GROUP);
    prop.separatorGroup = GeneralViewSeparatorGroup;
    prop.sortIndex = 3;
    properties.push(prop);

    prop = new DynProperty("panel.portfolioSummary.Settings.General.View.ShowTotals", this.showTotalsState, DynProperty.BOOLEAN, DynProperty.PARAM_GROUP);
    prop.separatorGroup = GeneralViewSeparatorGroup;
    prop.sortIndex = 3;
    properties.push(prop);

    // General -> Colors
    let pair

    pair = new PairColor(qt.ForeColor, qt.rowBackColor, 'Text', 'Back')
    prop = new DynProperty("panel.portfolioSummary.Settings.General.Colors.Row", pair, DynProperty.PAIR_COLOR, DynProperty.PARAM_GROUP);
    prop.separatorGroup = GeneralColorsSeparatorGroup;
    prop.sortIndex = 3;
    properties.push(prop);

    pair = new PairColor(qt.alternatingRowForeColor, qt.alternatingRowBackColor, 'Text', 'Back')
    prop = new DynProperty("panel.portfolioSummary.Settings.General.Colors.AlternativeRow", pair, DynProperty.PAIR_COLOR, DynProperty.PARAM_GROUP);
    prop.separatorGroup = GeneralColorsSeparatorGroup;
    prop.sortIndex = 4;
    properties.push(prop);

    pair = new PairColor(qt.selectedForeColor, qt.selectedBackColor, 'Text', 'Back')
    prop = new DynProperty("panel.portfolioSummary.Settings.General.Colors.SelectedRow", pair, DynProperty.PAIR_COLOR, DynProperty.PARAM_GROUP);
    prop.separatorGroup = GeneralColorsSeparatorGroup;
    prop.sortIndex = 5;
    properties.push(prop);

    pair = new PairColor(qt.headerForeColor, qt.headerBackColor, 'Text', 'Back')
    prop = new DynProperty("panel.portfolioSummary.Settings.General.Colors.HeaderRow", pair, DynProperty.PAIR_COLOR, DynProperty.PARAM_GROUP);
    prop.separatorGroup = GeneralColorsSeparatorGroup;
    prop.sortIndex = 6;
    properties.push(prop);

    pair = new PairColor(qt.totalsForeColor, qt.totalsBackColor, 'Text', 'Back')
    prop = new DynProperty("panel.portfolioSummary.Settings.General.Colors.TotalRow", pair, DynProperty.PAIR_COLOR, DynProperty.PARAM_GROUP);
    prop.separatorGroup = GeneralColorsSeparatorGroup;
    prop.sortIndex = 7;
    properties.push(prop);


    for (let i = 0, len = PortfolioSummaryItem.columnsParams.length; i < len; i++)
        properties = properties.concat(this.PropertiesByColumn(i))


    return properties;
}

PortfolioSummary.prototype.PropertiesByColumn = function (column)
{
    let key = PortfolioSummaryItem.columnsParams[column] ? PortfolioSummaryItem.columnsParams[column].HeaderKey : null
    let SeparatorGroup = '#' + (column < 10 ? '0' : '') + column + '#' + Resources.getResource(key)
    let properties = [], prop;

    // Text and Back Colors (for all columns)
    let CellBackColor = this.columnBackColor[column] || 'transparent',
        CellForeColor = this.columnTextColor[column] || 'transparent',
        pair = CellBackColor || CellForeColor ? new PairColor(CellForeColor, CellBackColor, 'Text', 'Back') : new PairColor();
    prop = new DynProperty('SummaryColorsForColumn' + column, pair, DynProperty.PAIR_COLOR, DynProperty.COLUMNS);
    prop.separatorGroup = SeparatorGroup;
    prop.sortIndex = 1;
    properties.push(prop);

    switch (column)
    {
        case 0:
            prop = new DynProperty('Show logos', this.showLogos, DynProperty.BOOLEAN, DynProperty.COLUMNS);
            prop.separatorGroup = SeparatorGroup;
            prop.sortIndex = 2;
            properties.push(prop);
            break;

        case 13:    // sparkline
            prop = new DynProperty('Number of points', this.sparkLinePointNum, DynProperty.INTEGER, DynProperty.COLUMNS);
            prop.separatorGroup = SeparatorGroup;
            prop.sortIndex = 2;
            prop.minimalValue = 5;
            prop.maximalValue = 50;
            properties.push(prop);

            // prop = new DynProperty('Data style', 1, DynProperty.COMBOBOX, DynProperty.COLUMNS);
            // prop.separatorGroup = SeparatorGroup;
            // prop.sortIndex = 3;
            // properties.push(prop);

            // prop = new DynProperty('Show', 1, DynProperty.COMBOBOX, DynProperty.COLUMNS);
            // prop.separatorGroup = SeparatorGroup;
            // prop.sortIndex = 3;
            // properties.push(prop);
            break;

        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
        case 12:
        case 14:
        case 15:
            let coloringMode = this.coloringModes[column] ? this.coloringModes[column] : ColouringModes.None
            prop = new DynProperty('Coloring mode' + column, coloringMode, DynProperty.COMBOBOX_COMBOITEM, DynProperty.COLUMNS);
            prop.separatorGroup = SeparatorGroup;
            prop.objectVariants = PortfolioSummary.GetColoringModeVariants()
            prop.COMBOBOX_TYPE = DynProperty.INTEGER;
            prop.sortIndex = 2;
            properties.push(prop);

            let increaseColor = this.increaseColors[column] ? this.increaseColors[column] : ThemeManager.CurrentTheme.TableValueUpForeColor
            prop = new DynProperty('Increase color' + column, increaseColor, DynProperty.COLOR, DynProperty.COLUMNS);
            prop.separatorGroup = SeparatorGroup;
            prop.sortIndex = 3;
            properties.push(prop);

            let decreaseColor = this.decreaseColors[column] ? this.decreaseColors[column] : ThemeManager.CurrentTheme.TableValueDownForeColor
            prop = new DynProperty('Decrease color' + column, decreaseColor, DynProperty.COLOR, DynProperty.COLUMNS);
            prop.separatorGroup = SeparatorGroup;
            prop.sortIndex = 4;
            properties.push(prop);

            if (column != 4 && column != 5)
            {
                prop = new DynProperty('SummaryShowTotalForColumn' + column, this.showTotalsByColumn[column] || false, DynProperty.BOOLEAN, DynProperty.COLUMNS);
                prop.separatorGroup = SeparatorGroup;
                prop.sortIndex = 5;
                properties.push(prop);

                // prop = new DynProperty('Calculation method', 0, DynProperty.COMBOBOX_COMBOITEM, DynProperty.COLUMNS);
                // prop.separatorGroup = SeparatorGroup;
                // prop.sortIndex = 6;
                // prop.objectVariants = PortfolioSummary.GetTotalCalcMethodVariants()
                // prop.COMBOBOX_TYPE = DynProperty.INTEGER;
                // properties.push(prop);
            }

            break;
    }

    return properties
}

PortfolioSummary.prototype.AutoSizeColumns = function (doAutosize)  // если без аргументов - работает как getter, если передаем флажок true - работает как set true 
{
    let qt = this.getQuickTable()
    if (!qt) return

    let columns = qt.columns

    for (let i = 0, len = PortfolioSummaryItem.columnsParams.length; i < len; i++)
    {
        if (!doAutosize && columns[i].width != PortfolioSummaryItem.columnsParams[i].Width)
            return false

        if (doAutosize)
            columns[i].width = PortfolioSummaryItem.columnsParams[i].Width
    }

    return true
}

PortfolioSummary.GetGridVisibilityVariants = function ()
{
    let gridTypes = GridVisibilityType
    return [{
        text: Resources.getResource("property.SummaryGrid.None"),
        value: gridTypes.None
    },
    {
        text: Resources.getResource("property.SummaryGrid.Vertical"),
        value: gridTypes.Vertical
    },
    {
        text: Resources.getResource("property.SummaryGrid.Horizontal"),
        value: gridTypes.Horizontal
    },
    {
        text: Resources.getResource("property.SummaryGrid.HorVer"),
        value: gridTypes.Both
    }];
}

PortfolioSummary.GetColoringModeVariants = function ()
{
    return [{
        text: Resources.getResource("property.Coloring mode.None"),
        value: ColouringModes.None
    },
    {
        text: Resources.getResource("property.Coloring mode.By difference"),
        value: ColouringModes.Previous
    },
    {
        text: Resources.getResource("property.Coloring mode.By sign"),
        value: ColouringModes.Signed
    }];
}

PortfolioSummary.GetTotalCalcMethodVariants = function ()
{
    return [{
        text: Resources.getResource("property.Calculation method.Net Qty"),
        value: 0
    },
    {
        text: Resources.getResource("property.Calculation method.Average"),
        value: 1
    },
    {
        text: Resources.getResource("property.Calculation method.Count"),
        value: 2
    },
    {
        text: Resources.getResource("property.Calculation method.Absolute sum"),
        value: 3
    }]
}

PortfolioSummary.startWorkSummary = function ()
{
    MainWindowManager.MainWindow.Controls.PortfolioSummary.setPanelSize();
}